import React, { useState } from "react";
import { useSidebar } from "../Reusable";
import { useSidebarStyles } from "../Styles/SidebarStyles";
import { DirectionsBusOutlined, MenuBookOutlined } from "@material-ui/icons";
import SidebarTitle from "../Reusable/Titles/SidebarTitle";

const TransportSidebar = (props: any) => {
  const classes = useSidebarStyles();
  const [routes] = useState(props.routes);
  const [testRoutes] = useState(props.testRoutes);
  const [live_bus_location] = useState(props.live_bus_location);
  const [bus_user] = useState(props.bus_user);
  const [vehicles] = useState(props.vehicles);
  const [studentBusStopMapping] = useState(props.studentBusStopMapping);
  const [busWiseDetail] = useState(props.busWiseDetail);
  const [location] = useState(props.location);

  const dataSet = [
    {
      text: "Bus Info",
      url: "vehicles",
      activeStatus: vehicles,
    },
    {
      text: "Routes",
      url: "routes",
      activeStatus: routes,
    },
    {
      text: "Location",
      url: "location",
      activeStatus: location,
    },
    // {
    //   text: 'Student Bus Stop Mapping',
    //   url: 'student-bus-stop-mapping',
    //   activeStatus: studentBusStopMapping,
    // },
    // {
    //   text: 'Bus Wise Details',
    //   url: 'bus-wise-details',
    //   activeStatus: busWiseDetail,
    // },
    // {
    //   text: 'Test Routes',
    //   url: 'test-routes',
    //   activeStatus: testRoutes,
    // },
    // {
    //   text: 'Bus User',
    //   url: 'bus-user',
    //   activeStatus: bus_user,
    // },
  ];
  const { CustomSidebar } = useSidebar(dataSet, "FINANCE");
  return (
    <div className={classes.Sidebar}>
      <div className={classes.sidebarContent}>
        <SidebarTitle icon={<DirectionsBusOutlined />}>Transport</SidebarTitle>
        <CustomSidebar />
      </div>
    </div>
  );
};

export default TransportSidebar;
