import React from 'react';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../store';

interface AccessPermissionI {
  children: React.ReactNode;
  administrator?: boolean;
  accountant?: boolean;
}

/*
 * AccessPermission helps us to display component with role
 * By default it shows administrator behavior
 */
const AccessPermission = (props: AccessPermissionI) => {
  const { children, administrator = true, accountant } = props;
  const authState = useSelector((state: RootStore) => state.auth);

  const { role = 'Administrator' } = authState;

  if (administrator && role === 'Administrator') return <>{children}</>;
  if (accountant && role === 'Accountant') return <>{children}</>;

  return <React.Fragment></React.Fragment>;
};

export default AccessPermission;
