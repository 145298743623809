//---------------------------- <START> module imports start ----------------------------------//
import { Button, Grid, InputLabel, Paper, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import SearchIcon from "@material-ui/icons/Search";

import { useForm } from "react-hook-form";
import { Autocomplete } from "@material-ui/lab";
import { useFormStyles } from "../../Styles/FormStyles";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import { GetClasses } from "../../../actions/Academics/Class/ClassAction";
import { GetExams } from "../../../actions/Examination/Exam/ExamAction";
import { ExamTypeI } from "../../../actions/Examination/Exam/ExamActionTypes";
import { ClassTypeI } from "../../../actions/Academics/Class/ClassActionTypes";
import { SectionTypeI } from "../../../actions/Academics/Section/SectionActionTypes";
import { GetResult } from "../../../actions/Examination/Result/ResultAction";
import { FormResetButton } from "../../Reusable/Buttons/FormButton";
import { GetStudentByClass } from "../../../actions/Student/Student/StudentAction";
import { InputBox } from "../../Reusable/Inputs/Select";
import { ResetButton, SubmitButton } from "../../Reusable/Buttons/SearchButton";
import { GetResultLedger } from "../../../actions/Reports/IdCard/ResultLedger/ResultLedgerAction";
//----------------------------<END> module imports end ----------------------------------//

// -----------------<start> Interfaces <start>-----------------------//
interface PropsI {
  onSearch: (value: SearchTitleI) => void;
}

export interface SearchTitleI {
  exam_info: string;
  class: string;
  section: string;
  grade: string;
  section_id: string;
}
// -----------------<end> Interfaces <end>-----------------------//

const SearchResult = () => {
  //   const { onSearch } = props;
  const classes = useFormStyles();
  const { register, handleSubmit, errors, setValue } = useForm();

  //======================== > STATES < ===========================================//

  const [examType, setExamType] = useState<ExamTypeI | null>(null);
  const [examTypeChoices, setExamTypeChoices] = useState<ExamTypeI[]>([]);
  const [selectedClass, setSelectedClass] = useState<ClassTypeI | null>(null);
  const [classChoices, setClassChoices] = useState<ClassTypeI[]>([]);
  const [selectedSection, setSelectedSection] = useState<SectionTypeI | null>(
    null
  );
  const [sectionChoices, setSectionChoices] = useState<SectionTypeI[] | []>([]);
  const [sectionDisabler, setSectionDisabler] = useState<boolean>(true);

  ///////////////////////////////////////////////////////////////////////////////////////

  //======================================== <START> REACT HOOKS <START> ============================//

  const dispatch = useDispatch();
  const classSelector = useSelector((state: RootStore) => state.class);
  const examSelector = useSelector((state: RootStore) => state.exam);

  useEffect(() => {
    dispatch(GetClasses());
    dispatch(GetExams());
  }, []);

  useEffect(() => {
    setClassChoices(classSelector.classes);
    classSelector.classes && setSelectedClass(classSelector.classes[0]);
  }, [classSelector]);

  useEffect(() => {
    setExamTypeChoices(examSelector.exams);
    examSelector.exams && setExamType(examSelector.exams[0]);
  }, [examSelector]);


  useEffect(() => {
    onSubmit();
  }, [selectedClass, examType]);

  //======================================== <END> REACT HOOKS <END> ============================//

  //======================================== <START> EVENT HANDLERS <START> ============================//

  const handleClassChange = (value: ClassTypeI | null) => {
    setSelectedClass(value);
    setSelectedSection(null);
    value != null
      ? populateSectionChoices(value.section)
      : setSectionDisabler(true);
  };

  const populateSectionChoices = (sections: SectionTypeI[] | []) => {
    setSectionDisabler(false);
    sections.length > 0
      ? setSectionChoices(sections)
      : setSectionDisabler(true);
  };

  const onSubmit = () => {
    if (examType && selectedClass) {
      dispatch(
        GetResult(
          examType.id,
          selectedClass.grade,
          selectedSection ? selectedSection.id : ""
        )
      );
      dispatch(
        GetStudentByClass(
          selectedClass.grade,
          selectedSection ? selectedSection.id : ""
        )
      );
      // const data = {
      //   exam_info: examType.id,
      //   class: selectedClass.grade_name,
      //   section: selectedSection ? selectedSection.name : "",
      //   grade: selectedClass.grade,
      //   section_id: selectedSection ? selectedSection.id : "",
      // };
      dispatch(GetResultLedger(examType.id, selectedClass.grade));
    }
  };


  const handleFilter = () => {
    setSelectedClass(null);
    setExamType(null);
    setSelectedSection(null);
    // dispatch(GetResult("", "", ""));
  };

  //======================================== <END> EVENT HANDLERS <END> ============================//

  return (
    <>
      <Paper className={classes.pageContentBox}>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item className={classes.formTitle}>
              <span>Search Marksheet Ledger</span>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={3} className={classes.formWrapperRow}>
              <InputBox>Exam</InputBox>
              <Autocomplete
                classes={{
                  input: classes.smallfont,
                  option: classes.smallfont,
                }}
                fullWidth
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: ExamTypeI | null
                ) => setExamType(value)}
                value={examType}
                options={examTypeChoices}
                getOptionLabel={(option) => option.exam_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Label"
                    name="academic_year"
                    inputRef={register({ required: true })}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={3} className={classes.formWrapperRow}>
              <InputBox>Class</InputBox>
              <Autocomplete
                classes={{
                  input: classes.smallfont,
                  option: classes.smallfont,
                }}
                fullWidth
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: ClassTypeI | null
                ) => handleClassChange(value)}
                options={classChoices}
                value={selectedClass}
                getOptionLabel={(option) => option.grade_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Label"
                    name="class_name"
                    inputRef={register({ required: true })}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            {/* <Grid item xs={3} className={classes.formWrapperRow}>
              <InputBox>Section</InputBox>
              <Autocomplete
                classes={{
                  input: classes.smallfont,
                  option: classes.smallfont,
                }}
                fullWidth
                value={selectedSection}
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: SectionTypeI | null
                ) => setSelectedSection(value)}
                options={sectionChoices}
                getOptionLabel={(option) => option.name}
                disabled={sectionDisabler}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Label"
                    name="session_name"
                    variant="outlined"
                  />
                )}
              />
            </Grid> */}
            <SubmitButton style={{ marginTop: "5px" }} />
            <ResetButton style={{ marginTop: "5px" }} onClick={handleFilter} />
          </Grid>
        </form>
      </Paper>
    </>
  );
};

export default SearchResult;
