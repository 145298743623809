import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { HRsidebar } from '../../components';
import BackDropLoader from '../../components/Reusable/BackDropLoader';
import SubModuleLayout from '../../components/Reusable/Layouts/SubModuleLayout';
import { RootStore } from '../../store';
import LeaveTypeSelect from '../../components/HumanResource/ApplyLeave/LeaveTypeSelect';
import StaffSelectTable from '../../components/HumanResource/ApplyLeave/StaffSelectTable';
import { ApplyLeaveTypeToStaff } from '../../actions/HumanResource/Leave/LeaveAction';

export default function ApplyLeaveTypePage() {
  const dispatch = useDispatch();
  const nav = <HRsidebar ApplyLeaveType />;

  const [leaves, setLeaves] = useState<string[]>([]);
  const [staffs, setStaffs] = useState<string[]>([]);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false)

  const leaveSelector = useSelector((state: RootStore) => state.leave_type);
  const leaveLoading = useSelector((state: RootStore) => state.leaves.applyLeaveLoading);
  const staffSelector = useSelector((state: RootStore) => state.staff);
  const loading = leaveSelector.loading || staffSelector.loading;

  console.log('leaveLoading', leaveLoading)

  useEffect(() => {
    if (!leaveLoading) {
      setIsBtnLoading(false);
    }
  }, [leaveLoading])


  const handleLeaveChange = (leaves: string[]) => {
    setLeaves(leaves);
  };

  const handleStaffChange = (staffs: string[]) => {
    setStaffs(staffs);
  };

  const handleApplyLeave = () => {
    if (leaves.length > 0 && staffs.length > 0) {
      const data = { leave_types: leaves, staffs: staffs };
      setIsBtnLoading(true);
      dispatch(ApplyLeaveTypeToStaff(data));
    }
  };

  return (
    <SubModuleLayout sideNav={nav}>
      <Grid container>
        <Grid item xs={4}>
          <LeaveTypeSelect onLeaveChange={handleLeaveChange} />
        </Grid>

        <Grid item xs={8}>
          <StaffSelectTable
            onStaffChange={handleStaffChange}
            handleApplyLeave={handleApplyLeave}
            isBtnLoading={isBtnLoading}
            setIsBtnLoading={setIsBtnLoading}
          />
        </Grid>
      </Grid>
      <BackDropLoader open={loading} />
    </SubModuleLayout>
  );
}
