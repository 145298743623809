export const GET_SYSTEM_ROLES_LOADING = "GET_SYSTEM_ROLES_LOADING";
export const GET_SYSTEM_ROLES_ERROR = "GET_SYSTEM_ROLES_ERROR";
export const GET_SYSTEM_ROLES_SUCCESS = "GET_SYSTEM_ROLES_SUCCESS";

export const ADD_SYSTEM_ROLES_LOADING = "ADD_SYSTEM_ROLES_LOADING";
export const ADD_SYSTEM_ROLES_ERROR = "ADD_SYSTEM_ROLES_ERROR";
export const ADD_SYSTEM_ROLES_SUCCESS = "ADD_SYSTEM_ROLES_SUCCESS";

export const DELETE_SYSTEM_ROLES_LOADING = "DELETE_SYSTEM_ROLES_LOADING";
export const DELETE_SYSTEM_ROLES_ERROR = "DELETE_SYSTEM_ROLES_ERROR";
export const DELETE_SYSTEM_ROLES_SUCCESS = "DELETE_SYSTEM_ROLES_SUCCESS";

export const SET_SYSTEM_ROLES_LOADING = "SET_SYSTEM_ROLES_LOADING";
export const SET_SYSTEM_ROLES_ERROR = "SET_SYSTEM_ROLES_ERROR";
export const SET_SYSTEM_ROLES_SUCCESS = "SET_SYSTEM_ROLES_SUCCESS";

export const SET_USER_PASSWORD_LOADING = "SET_USER_PASSWORD_LOADING";
export const SET_USER_PASSWORD_ERROR = "SET_USER_PASSWORD_ERROR";
export const SET_USER_PASSWORD_SUCCESS = "SET_USER_PASSWORD_SUCCESS";

export const SEND_USER_CREDENTIALS_LOADING = "SEND_USER_CREDENTIALS_LOADING";
export const SEND_USER_CREDENTIALS_SUCCESS = "SEND_USER_CREDENTIALS_SUCCESS";
export const SEND_USER_CREDENTIALS_ERROR = "SEND_USER_CREDENTIALS_ERROR";

export type SystemRolesTypeI = {
  id: string;
  name: string;
  permissions: number[];
};

//===============================<START>GET SYSTEM ROLES<START>======================//

export interface GetSystemRolesLoading {
  type: typeof GET_SYSTEM_ROLES_LOADING;
}

export interface GetSystemRolesError {
  type: typeof GET_SYSTEM_ROLES_ERROR;
}

export interface GetSystemRolesSuccess {
  type: typeof GET_SYSTEM_ROLES_SUCCESS;
  payload: SystemRolesTypeI[];
}

//===============================<START>ADD SYSTEM ROLES<START>======================//
export interface AddSystemRolesLoading {
  type: typeof ADD_SYSTEM_ROLES_LOADING;
}

export interface AddSystemRolesError {
  type: typeof ADD_SYSTEM_ROLES_ERROR;
  payload: any;
}

export interface AddSystemRolesSuccess {
  type: typeof ADD_SYSTEM_ROLES_SUCCESS;
  payload: any;
}
//===============================<END>ADD SYSTEM ROLES<END>======================//

export interface SetSystemRolesLoading {
  type: typeof SET_SYSTEM_ROLES_LOADING;
}

export interface SetSystemRolesError {
  type: typeof SET_SYSTEM_ROLES_ERROR;
}

export interface SetSystemRolesSuccess {
  type: typeof SET_SYSTEM_ROLES_SUCCESS;
  payload: SystemRolesTypeI[];
}

export interface SetUserPasswordLoading {
  type: typeof SET_USER_PASSWORD_LOADING;
}

export interface SetUserPasswordError {
  type: typeof SET_USER_PASSWORD_ERROR;
}

export interface SetUserPasswordSuccess {
  type: typeof SET_USER_PASSWORD_SUCCESS;
}

//===============================<START>ADD SYSTEM ROLES<START>======================//
export interface DeleteSystemRolesLoading {
  type: typeof DELETE_SYSTEM_ROLES_LOADING;
}

export interface DeleteSystemRolesError {
  type: typeof DELETE_SYSTEM_ROLES_ERROR;
}

export interface DeleteSystemRolesSuccess {
  type: typeof DELETE_SYSTEM_ROLES_SUCCESS;
  payload: { id: string };
}
//===============================<START>ADD SYSTEM ROLES<START>======================//

export interface SendUserCredentialsLoading {
  type: typeof SEND_USER_CREDENTIALS_LOADING;
}

export interface SendUserCredentialsError {
  type: typeof SEND_USER_CREDENTIALS_ERROR;
}

export interface SendUserCredentialsSuccess {
  type: typeof SEND_USER_CREDENTIALS_SUCCESS;
}

export type SystemRolesDispatchTypes =
  | GetSystemRolesLoading
  | GetSystemRolesError
  | GetSystemRolesSuccess
  | AddSystemRolesLoading
  | AddSystemRolesError
  | AddSystemRolesSuccess
  | DeleteSystemRolesLoading
  | DeleteSystemRolesError
  | DeleteSystemRolesSuccess
  | SetSystemRolesLoading
  | SetSystemRolesError
  | SetSystemRolesSuccess
  | SetUserPasswordLoading
  | SetUserPasswordError
  | SetUserPasswordSuccess
  | SendUserCredentialsLoading
  | SendUserCredentialsError
  | SendUserCredentialsSuccess;
