import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  CircularProgress,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  Theme,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { useFormStyles } from '../../../Styles/FormStyles';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../../store';
import {
  InvoiceDiscountAppliedI,
  InvoiceFineAppliedI,
  InvoiceScholarshipAppliedI,
} from '../../../../actions/Fees/CollectFees/CollectFeesActionTypes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    labels: {
      fontSize: '14px',
      margin: theme.spacing(0.5, 0),
    },
  })
);

interface PropsI {
  modalState: boolean;
  handleClose: (val: false) => void;
}

export const getScholarshipAmount = (
  scholarships: InvoiceScholarshipAppliedI[],
  fee_amount: number
): number => {
  let scholarship_amount = 0;

  scholarships.map((item: InvoiceScholarshipAppliedI) => {
    if (item.scholarship.scholarship_in === 'P') {
      const amt = (item.scholarship.applicable_scholarship / 100) * fee_amount;
      scholarship_amount = scholarship_amount + amt;
    } else if (item.scholarship.scholarship_in === 'A') {
      scholarship_amount =
        scholarship_amount + item.scholarship.applicable_scholarship;
    }
  });

  return scholarship_amount;
};

export const getDiscountAmount = (
  discounts: InvoiceDiscountAppliedI[],
  fee_amount: number
): number => {
  let discount_amount = 0;

  discounts.map((item: InvoiceDiscountAppliedI) => {
    if (item.discount.discount_in === 'P') {
      try {
        const amt =
          (Number(item.discount.discount_applicable) / 100) * fee_amount;
        discount_amount = discount_amount + amt;
      } catch (err) {}
    } else if (item.discount.discount_in === 'A') {
      try {
        discount_amount =
          discount_amount + Number(item.discount.discount_applicable);
      } catch (err) {}
    }
  });

  return discount_amount;
};

export const getFineAmount = (
  fines: InvoiceFineAppliedI[],
  fee_amount: number
): number => {
  let fine_amount = 0;

  fines.map((item: InvoiceFineAppliedI) => {
    if (item.fine.fine_in === 'P') {
      try {
        const amt = (Number(item.fine.fine_applicable) / 100) * fee_amount;
        fine_amount = fine_amount + amt;
      } catch (err) {
        console.log(err);
      }
    } else if (item.fine.fine_in === 'A') {
      try {
        fine_amount = fine_amount + Number(item.fine.fine_applicable);
      } catch (err) {
        console.log(err);
      }
    }
  });

  return fine_amount;
};

const StatementDetailModal = (props: PropsI) => {
  const textStyles = useStyles();
  const { modalState, handleClose } = props;
  const classes = useFormStyles();

  const [state, setState] = useState({
    discountAmount: 0,
    fineAmount: 0,
    scholarshipAmount: 0,
    payingAmount: 0,
  });

  const statementSelector = useSelector(
    (state: RootStore) => state.collect_fees.statement_details
  );
  const loading = useSelector((state: RootStore) => state.collect_fees.loading);

  const closeDialog = () => {
    handleClose(false);
  };

  useEffect(() => {
    const data = statementSelector;

    if (data) {
      const scholarshipAmt = getScholarshipAmount(
        data.invoice_scholarship_applied,
        data.amount_to_pay
      );
      const discountAmt = getDiscountAmount(
        data.invoice_discount_applied,
        data.amount_to_pay
      );
      const fineAmt = getFineAmount(
        data.invoice_fine_applied,
        data.amount_to_pay
      );

      const payingAmt =
        data.amount_to_pay + fineAmt - discountAmt - scholarshipAmt;

      setState({
        ...state,
        discountAmount: discountAmt,
        fineAmount: fineAmt,
        scholarshipAmount: scholarshipAmt,
        payingAmount: payingAmt,
      });
    }
  }, [statementSelector]);

  return (
    <>
      <Dialog
        open={modalState}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Grid container justifyContent="space-between">
          <DialogTitle id="alert-dialog-title">
            {'Statement Details'}
          </DialogTitle>
          <Grid item>
            <IconButton
              aria-label="close"
              onClick={closeDialog}
              className={classes.closeBtn}
            >
              <Close />
            </IconButton>
          </Grid>
        </Grid>

        <DialogContent>
          {loading ? (
            <CircularProgress style={{ width: '25px' }} />
          ) : statementSelector ? (
            <Grid container>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                  <Typography className={textStyles.labels}>
                    Fee Description:
                  </Typography>
                  <Typography className={textStyles.labels}>
                    {statementSelector.fee_type} {statementSelector.month}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                  <Typography className={textStyles.labels}>
                    Total Fee Amount:
                  </Typography>
                  <Typography className={textStyles.labels}>
                    Rs. {statementSelector.amount_to_pay}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                  <Typography className={textStyles.labels}>
                    Discount:
                  </Typography>
                  <Typography className={textStyles.labels}>
                    Rs. {state.discountAmount}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                  <Typography className={textStyles.labels}>Fine:</Typography>
                  <Typography className={textStyles.labels}>
                    Rs. {state.fineAmount}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                  <Typography className={textStyles.labels}>
                    Scholarship:
                  </Typography>
                  <Typography className={textStyles.labels}>
                    Rs. {state.scholarshipAmount}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                  <Typography className={textStyles.labels}>
                    Paying Amount:
                  </Typography>
                  <Typography className={textStyles.labels}>
                    Rs. {state.payingAmount}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                  <Typography className={textStyles.labels}>
                    Paid Amount:
                  </Typography>
                  <Typography className={textStyles.labels}>
                    Rs. {statementSelector.paid_amount}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                  <Typography className={textStyles.labels}>
                    Due Amount:
                  </Typography>
                  <Typography className={textStyles.labels}>
                    Rs. {statementSelector.due_amount}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                  <Typography className={textStyles.labels}>
                    Advance Amount:
                  </Typography>
                  <Typography className={textStyles.labels}>
                    Rs. {statementSelector.advance_amount}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StatementDetailModal;
