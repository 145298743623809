// -----------------<START> import modules starts----------------------//
import React, { useState } from 'react';
import {
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Add, Search, SettingsBackupRestore } from '@material-ui/icons';
import { useFormStyles } from '../../Styles/FormStyles';
import Popups from '../../Reusable/Dialogs/Popups';
import 'nepali-datepicker-reactjs/dist/index.css';
import MeetingRequestModal from './MeetingRequestModal';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { GetSearchedMeetings } from '../../../actions/HumanResource/Meeting/MeetingAction';
import CustomizedNepaliDatePicker from '../../Reusable/Inputs/NepaliDatePicker';
import AddNewButton from '../../Reusable/Buttons/AddNewButton.test';
import FormButton from '../../Reusable/Buttons/FormButton';
import CustomizedSelect from '../../Reusable/Inputs/Select';
import { Tupple } from '../../../actions';
import { RootStore } from '../../../store';
import { dateConverterBsToAd } from '../../utils/dateConverter';
import { CUR_NEPALI_DATE } from '../../utils/nepaliDateUtils';

// -----------------<END> imports module ends -----------------------//

// -----------------<START> Meeting Search component starts-----------------------//
const MeetingSearch: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useFormStyles();
  const { handleSubmit } = useForm();

  const [fromDate, setFromDate] = useState<string | null>(CUR_NEPALI_DATE);
  const [toDate, setToDate] = useState<string | null>(CUR_NEPALI_DATE);
  const [openPopup, setOpenPopup] = useState(false);
  const [meetingType, setMeetingType] = useState<Tupple | null>(null);

  const handleClose = (value: boolean) => {
    setOpenPopup(value);
  };

  const onSubmit = (data: any) => {
    const query: any = {
      meetingType: meetingType ? meetingType.key : '',
      date_first: (fromDate && dateConverterBsToAd(fromDate)) || '',
      date_second: (toDate && dateConverterBsToAd(toDate)) || '',
    };

    dispatch(GetSearchedMeetings(query));
  };

  const meetingTypeState = useSelector(
    (state: RootStore) => state.meeting_type
  );

  const MEETING_TYPE_CHOICES = meetingTypeState.meeting_types.map((item) => ({
    key: item.id.toString(),
    value: item.name,
  }));

  const handleReset = () => {
    setFromDate('');
    setToDate('');
    setMeetingType(null);

    const query: any = {
      meetingType: '',
      date_first: '',
      date_second: '',
    };

    dispatch(GetSearchedMeetings(query));
  };

  return (
    <>
      <Paper className={classes.pageAttachContent}>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container justifyContent="space-between">
            <Grid item xs={6} className={classes.formTitle}>
              <Typography variant="h6">Meeting Requests</Typography>
            </Grid>

            <Grid container item xs={6}>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                  <Tooltip title="Clear Search Query">
                    <IconButton
                      aria-label="delete"
                      style={{ padding: '2px' }}
                      onClick={handleReset}
                    >
                      <SettingsBackupRestore />
                    </IconButton>
                  </Tooltip>
                </Grid>

                {/* <Grid item>
                  <AddNewButton
                    title="Request New Meeting"
                    type="button"
                    color="primary"
                    variant="outlined"
                    startIcon={<Add />}
                    onClick={() => setOpenPopup(true)}
                  >
                    Request New Meeting
                  </AddNewButton>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={3} className={classes.formWrapper}>
              <CustomizedNepaliDatePicker
                label="From"
                value={fromDate}
                setValue={setFromDate}
              />
            </Grid>

            <Grid item xs={3} className={classes.formWrapper}>
              <CustomizedNepaliDatePicker
                label="To"
                value={toDate}
                setValue={setToDate}
              />
            </Grid>

            <Grid item xs={3} className={classes.formWrapper}>
              <CustomizedSelect
                label="Meeting Type"
                name="meetType"
                options={MEETING_TYPE_CHOICES}
                value={meetingType}
                setValue={setMeetingType}
              />
            </Grid>

            <Grid item xs={3} className={classes.formWrapper}>
              <FormButton
                startIcon={<Search />}
                className={classes.searchBoxButton}
              >
                Search
              </FormButton>
            </Grid>
          </Grid>
        </form>

        <Popups
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          onClose={handleClose}
          title="Request Meeting"
          maxWidth="sm"
        >
          <MeetingRequestModal onClose={handleClose} />
        </Popups>
      </Paper>
    </>
  );
};

//-----------------<ENDS> Meeting Search component ends-----------------------//
export default MeetingSearch;
