import {
  EMAIL_SETTING_LOADING,
  EMAIL_SETTING_SUCCESS,
  EMAIL_SETTING_ERROR,
  ADD_EMAIL_SETTING_LOADING,
  ADD_EMAIL_SETTING_ERROR,
  ADD_EMAIL_SETTING_SUCCESS,
  UPDATE_EMAIL_SETTING_LOADING,
  UPDATE_EMAIL_SETTING_ERROR,
  UPDATE_EMAIL_SETTING_SUCCESS,
} from "../../actions/GeneralSettings/EmailSetting/EmailSettingActionTypes";

interface InitialStateI {
  loading: boolean;
  email_setting?: any;
}

interface EmailSettingI {
  id: number;
  smtp_host: string;
  smtp_email: string;
  smtp_password: string;
  smtp_port: number | string;
  smtp_display_name: string;
  smtp_use_tls: boolean | string;
}

const initialState: InitialStateI = {
  loading: false,
  email_setting: null,
};

const EmailSettingReducer = (
  state: InitialStateI = initialState,
  action: any
): InitialStateI => {
  switch (action.type) {
    case EMAIL_SETTING_LOADING:
      return {
        loading: true,
      };

    case EMAIL_SETTING_SUCCESS:
      return {
        loading: false,
        email_setting: action.payload,
      };

    case EMAIL_SETTING_ERROR:
      return {
        loading: false,
      };
    case ADD_EMAIL_SETTING_LOADING:
      return {
        loading: true,
        email_setting: state.email_setting,
      };

    case ADD_EMAIL_SETTING_ERROR:
      return {
        loading: false,
        email_setting: state.email_setting,
      };

    case ADD_EMAIL_SETTING_SUCCESS:
      return {
        loading: false,
        email_setting: [...state.email_setting, action.payload],
      };

    case UPDATE_EMAIL_SETTING_LOADING:
      return {
        loading: true,
        email_setting: state.email_setting,
      };

    case UPDATE_EMAIL_SETTING_ERROR:
      return {
        loading: false,
        email_setting: state.email_setting,
      };

    case UPDATE_EMAIL_SETTING_SUCCESS:
      const current_data: EmailSettingI[] = state.email_setting;
      const updated_data: EmailSettingI[] = [];

      current_data.map((element: EmailSettingI) => {
        element.id === action.payload.id
          ? updated_data.push({
              id: action.payload.id,
              smtp_host: action.payload.smtp_host,
              smtp_email: action.payload.smtp_email,
              smtp_password: action.payload.smtp_password,
              smtp_port: action.payload.smtp_port,
              smtp_display_name: action.payload.smtp_display_name,
              smtp_use_tls: action.payload.smtp_use_tls,
            })
          : updated_data.push(element);
      });

      return {
        loading: false,
        email_setting: updated_data,
      };

    default:
      return state;
  }
};

export default EmailSettingReducer;
