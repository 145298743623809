import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setSnackbar } from "../../../actions/SnackbarAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

export const deleteAppliedStudentFee = createAsyncThunk(
  "deleteAppliedStudentFee",
  async (
    data: {
      student: string | null;
      invoice_id: string | null;
      fee_type: string | null;
      month: string | null;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      await axios.post(`${ADMIN_API_URL}/update-invoice/delete/`, data);
      dispatch(setSnackbar(true, "success", "Applied Fees Modified"));
      return { id: data.invoice_id };
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      dispatch(setSnackbar(true, "error", error.response.data.error[0]));
      return rejectWithValue(error.response.data);
    }
  }
);
