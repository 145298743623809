import { useEffect } from 'react';
import { Paper, Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import BoardHeader from '../../../components/Dashboard/Calendar/BoardHeader';
import NepaliCalendarPage from './NepaliCalendar';

const Container = styled(Paper)`
  padding: 20px 24px 24px 24px;
`;

const ContainerCalendar = styled(Box)`
  padding: 8px 0;
`;

const CalendarEvents = () => {
  const dispatch = useDispatch();
  const engDate = moment().format('MMM YYYY');

  return (
    <Container elevation={1}>
      <BoardHeader title="Event Calendar" date={engDate} to="/events" />

      <ContainerCalendar>
        <NepaliCalendarPage />
      </ContainerCalendar>
    </Container>
  );
};

export default CalendarEvents;
