export const GET_CHILDREN_FEE_INVOICE_LOADING =
  "GET_CHILDREN_FEE_INVOICE_LOADING";
export const GET_CHILDREN_FEE_INVOICE_ERROR = "GET_CHILDREN_FEE_INVOICE_ERROR";
export const GET_CHILDREN_FEE_INVOICE_SUCCESS =
  "GET_CHILDREN_FEE_INVOICE_SUCCESS";

export const GET_CHILDREN_FEE_PAID_INVOICE_LOADING =
  "GET_CHILDREN_FEE_PAID_INVOICE_LOADING";
export const GET_CHILDREN_FEE_PAID_INVOICE_ERROR = "GET_CHILDREN_FEE_PAID_INVOICE_ERROR";
export const GET_CHILDREN_FEE_PAID_INVOICE_SUCCESS =
  "GET_CHILDREN_FEE_PAID_INVOICE_SUCCESS";

//===============================<START>GET Children Fee Invoice<START>======================//

export interface GetChildrenFeeInvoiceLoading {
  type: typeof GET_CHILDREN_FEE_INVOICE_LOADING;
}

export interface GetChildrenFeeInvoiceError {
  type: typeof GET_CHILDREN_FEE_INVOICE_ERROR;
}

export interface GetChildrenFeeInvoiceSuccess {
  type: typeof GET_CHILDREN_FEE_INVOICE_SUCCESS;
  payload: any;
}
//===============================<END>GET Children Fee Invoice<END>======================//

//===============================<START>GET Children Fee Paid Invoice<START>======================//

export interface GetChildrenFeePaidInvoiceLoading {
  type: typeof GET_CHILDREN_FEE_PAID_INVOICE_LOADING;
}

export interface GetChildrenFeePaidInvoiceError {
  type: typeof GET_CHILDREN_FEE_PAID_INVOICE_ERROR;
}

export interface GetChildrenFeePaidInvoiceSuccess {
  type: typeof GET_CHILDREN_FEE_PAID_INVOICE_SUCCESS;
  payload: any;
}
//===============================<END>GET Children Fee Invoice<END>======================//

export type ChildrenFeeDispatchTypes =
  | GetChildrenFeeInvoiceLoading
  | GetChildrenFeeInvoiceError
  | GetChildrenFeeInvoiceSuccess
  | GetChildrenFeePaidInvoiceLoading
  | GetChildrenFeePaidInvoiceError
  | GetChildrenFeePaidInvoiceSuccess;