export const GET_EXAM_LOADING = "GET_EXAM_LOADING";
export const GET_EXAM_ERROR = "GET_EXAM_ERROR";
export const GET_EXAM_SUCCESS = "GET_EXAM_SUCCESS";

export const GET_EXAM_BY_ID_LOADING = "GET_EXAM_BY_ID_LOADING";
export const GET_EXAM_BY_ID_ERROR = "GET_EXAM_BY_ID_ERROR";
export const GET_EXAM_BY_ID_SUCCESS = "GET_EXAM_BY_ID_SUCCESS";

export const ADD_EXAM_LOADING = "ADD_EXAM_LOADING";
export const ADD_EXAM_ERROR = "ADD_EXAM_ERROR";
export const ADD_EXAM_SUCCESS = "ADD_EXAM_SUCCESS";

export const UPDATE_EXAM_LOADING = "UPDATE_EXAM_LOADING";
export const UPDATE_EXAM_ERROR = "UPDATE_EXAM_ERROR";
export const UPDATE_EXAM_SUCCESS = "UPDATE_EXAM_SUCCESS";

export const DELETE_EXAM_LOADING = "DELETE_EXAM_LOADING";
export const DELETE_EXAM_ERROR = "DELETE_EXAM_ERROR";
export const DELETE_EXAM_SUCCESS = "DELETE_EXAM_SUCCESS";

export type ExamTypeI = {
  id: string;
  exam_name: string;
  start_date: string;
  end_date: string;
  session: string;
  result_publish_date: string;
  has_weightage: boolean;
  weightage: number | null;
  count: number;
};

//===============================<START>GET EXAM<START>======================//

export interface GetExamLoading {
  type: typeof GET_EXAM_LOADING;
}

export interface GetExamError {
  type: typeof GET_EXAM_ERROR;
}

export interface GetExamSuccess {
  type: typeof GET_EXAM_SUCCESS;
  payload: ExamTypeI[];
}

//===============================<END>GET EXAM<END>======================//

//===============================<START>GET EXAM<START>======================//

export interface GetExamByIdLoading {
  type: typeof GET_EXAM_BY_ID_LOADING;
}

export interface GetExamByIdError {
  type: typeof GET_EXAM_BY_ID_ERROR;
}

export interface GetExamByIdSuccess {
  type: typeof GET_EXAM_BY_ID_SUCCESS;
  payload: ExamTypeI;
}

//===============================<END>GET EXAM<END>======================//

//===============================<START>ADD EXAM<START>======================//

export interface AddExamLoading {
  type: typeof ADD_EXAM_LOADING;
}

export interface AddExamError {
  type: typeof ADD_EXAM_ERROR;
  payload: any;
}

export interface AddExamSuccess {
  type: typeof ADD_EXAM_SUCCESS;
  payload: ExamTypeI;
}

//===============================<END>ADD EXAM<END>======================//

//===============================<START>UPDATE EXAM<START>======================//

export interface UpdateExamLoading {
  type: typeof UPDATE_EXAM_LOADING;
}

export interface UpdateExamError {
  type: typeof UPDATE_EXAM_ERROR;
  payload: any;
}

export interface UpdateExamSuccess {
  type: typeof UPDATE_EXAM_SUCCESS;
  payload: ExamTypeI;
}

//===============================<END>UPDATE EXAM<END>======================//

//===============================<START>DELETE EXAM<START>======================//

export interface DeleteExamLoading {
  type: typeof DELETE_EXAM_LOADING;
}

export interface DeleteExamError {
  type: typeof DELETE_EXAM_ERROR;
}

export interface DeleteExamSuccess {
  type: typeof DELETE_EXAM_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE EXAM<END>======================//

export type ExamDispatchTypes =
  | GetExamLoading
  | GetExamError
  | GetExamSuccess
  | GetExamByIdLoading
  | GetExamByIdError
  | GetExamByIdSuccess
  | AddExamLoading
  | AddExamError
  | AddExamSuccess
  | UpdateExamLoading
  | UpdateExamError
  | UpdateExamSuccess
  | DeleteExamLoading
  | DeleteExamError
  | DeleteExamSuccess;
