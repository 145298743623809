import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { useStyles } from "../../Styles/FormStyles";
import { FormSaveLoadingButton } from "../../Reusable/Buttons/FormButton";
import Spinner from "../../../common/spinnerSVG/Spinner";
import styled from "styled-components";
import { RootStore } from "../../../store";
import { useCardStyles } from "../../Styles/CardStyles";
import { useParams } from "react-router-dom";
import { syncEmployeesToDevice } from "../../../rtk/features/attendanceDevice/attendanceDeviceThunk";

const ModalBody = styled.div`
  padding: 0 20px;
  min-width: 600px;
  max-width: 720px;
`;

const TableCap = styled.div`
  max-height: 50vh;
`;

const NoDataCell = styled.td`
  padding: 4rem 0;
  background-color: #f2f2f2;
  text-align: center;
`;

const SelectStaffsModal = (props: any) => {
  const { deviceId, configId } = useParams<{
    deviceId: string;
    configId: string;
  }>();
  const classes = useCardStyles();

  // Custom Styles
  const { handleModalClose } = props;

  // Dispatch Variable
  const dispatch = useDispatch();

  // State Declaration
  const [checkedRows, setCheckedRows] = useState<string[]>([]);

  const { loading, actionPerformed, employeesToSync } = useSelector(
    (state: RootStore) => state.attendanceDevice
  );

  const handleCheckAll = () => {
    if (allChecked) {
      setCheckedRows([]);
    } else {
      setCheckedRows(employeesToSync.map((el) => el.id));
    }
  };

  const handleCheckRow = (id: string) => {
    const currentRows = [...checkedRows];

    if (currentRows.includes(id)) {
      const newRows = currentRows.filter((el) => el !== id);
      setCheckedRows(newRows);
    } else {
      currentRows.push(id);
      setCheckedRows(currentRows);
    }
  };

  const handleSyncClick = () => {
    if (checkedRows.length) {
      dispatch(
        syncEmployeesToDevice({
          config: configId,
          device: deviceId,
          syncable_employees: checkedRows,
        })
      );
    }
  };

  const allChecked = employeesToSync.length
    ? employeesToSync.length === checkedRows.length
    : false;

  return (
    <ModalBody>
      <Grid container>
        <TableContainer className={classes.roota}>
          <TableCap>
            <Table
              size="small"
              className={classes.table}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox checked={allChecked} onClick={handleCheckAll} />
                  </TableCell>
                  <TableCell>S.N</TableCell>
                  <TableCell>Staff Name</TableCell>
                  <TableCell>Department</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {employeesToSync.length ? (
                  employeesToSync.map((row, index) => (
                    <TableRow key={index + 1}>
                      <TableCell>
                        <Checkbox
                          onChange={() => handleCheckRow(row.id)}
                          checked={checkedRows.includes(row.id)}
                        />
                      </TableCell>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        {row.user__first_name} {row.user__last_name}
                      </TableCell>
                      <TableCell>
                        {row.staff_faculty_info__department__name}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <NoDataCell colSpan={5}>
                      {loading ? "Loading..." : "No Employees To Sync."}
                    </NoDataCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableCap>
        </TableContainer>
      </Grid>

      <Grid
        container
        justifyContent="flex-start"
        style={{ marginBottom: "1em" }}
      >
        <FormSaveLoadingButton
          type="submit"
          icon={false ? <Spinner /> : <SaveIcon />}
          disabled={!checkedRows.length ? true : false}
          text={false ? "Saving..." : "Save to Device"}
          onClick={handleSyncClick}
        />
      </Grid>
    </ModalBody>
  );
};

export default SelectStaffsModal;
