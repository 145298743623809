import {
  GET_CLASS_TEACHER_LOADING,
  GET_CLASS_TEACHER_ERROR,
  GET_CLASS_TEACHER_SUCCESS,
  ClassTeacherDispatchTypes,
  ADD_CLASS_TEACHER_LOADING,
  ADD_CLASS_TEACHER_ERROR,
  ADD_CLASS_TEACHER_SUCCESS,
  DELETE_CLASS_TEACHER_LOADING,
  DELETE_CLASS_TEACHER_ERROR,
  DELETE_CLASS_TEACHER_SUCCESS,
  UPDATE_CLASS_TEACHER_LOADING,
  UPDATE_CLASS_TEACHER_ERROR,
  UPDATE_CLASS_TEACHER_SUCCESS,
  ClassTeacherTypeI,
} from "../../actions/Academics/ClassTeacher/ClassTeacherActionTypes";

interface InitialStateI {
  loading: boolean;
  classTeachers: ClassTeacherTypeI[];
  errors?: any;
  recent?: boolean;
  actionPerformed: boolean;
  add_or_update: boolean;
}

const initialState: InitialStateI = {
  loading: false,
  classTeachers: [],
  errors: null,
  recent: false,
  actionPerformed: false,
  add_or_update: false,
};

const ClassTeacherReducer = (
  state: InitialStateI = initialState,
  action: ClassTeacherDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_CLASS_TEACHER_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_CLASS_TEACHER_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_CLASS_TEACHER_SUCCESS:
      return {
        ...state,
        loading: false,
        classTeachers: action.payload,
        errors: null,
        recent: false,
        actionPerformed: false,
      };

    case ADD_CLASS_TEACHER_LOADING:
      return {
        ...state,
        loading: true,
        actionPerformed: false,
        add_or_update: false,
      };

    case ADD_CLASS_TEACHER_ERROR:
      return {
        ...state,
        loading: false,
        recent: false,
        add_or_update: true,
      };

    case ADD_CLASS_TEACHER_SUCCESS:
      return {
        ...state,
        loading: false,
        classTeachers: [...state.classTeachers, action.payload],
        errors: null,
        recent: true,
        actionPerformed: true,
        add_or_update: true,
      };

    case UPDATE_CLASS_TEACHER_LOADING:
      return {
        ...state,
        loading: true,
        actionPerformed: false,
        add_or_update: false,
      };

    case UPDATE_CLASS_TEACHER_ERROR:
      return {
        ...state,
        loading: false,
        recent: false,
        add_or_update: true,
      };

    case UPDATE_CLASS_TEACHER_SUCCESS:
      const current_data: ClassTeacherTypeI[] = state.classTeachers;
      const index: number = current_data.findIndex(
        (element) => element.id === action.payload.id
      );
      current_data[index] = action.payload;

      return {
        ...state,
        loading: false,
        classTeachers: current_data,
        errors: null,
        recent: true,
        actionPerformed: true,
        add_or_update: true,
      };

    case DELETE_CLASS_TEACHER_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_CLASS_TEACHER_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_CLASS_TEACHER_SUCCESS:
      const new_data: ClassTeacherTypeI[] = state.classTeachers;
      const item_index = new_data.findIndex(
        (element) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return {
        ...state,
        loading: false,
        classTeachers: new_data,
      };

    default:
      return state;
  }
};

export default ClassTeacherReducer;
