import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setSnackbar } from "../../../actions/SnackbarAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

export const getPrayer = createAsyncThunk(
  "getPrayer",
  async (undefined, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.get(`${ADMIN_API_URL}/prayer`);
      return res.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      dispatch(setSnackbar(true, "success", "Failed to get prayer"));
      return rejectWithValue(null);
    }
  }
);

export const updatePrayer = createAsyncThunk(
  "updatePrayer",
  async (data: any, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.post(`${ADMIN_API_URL}/prayer/`, data);
      dispatch(setSnackbar(true, "success", "Prayer updated"));
      return res.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      dispatch(setSnackbar(true, "error", "Failed to update prayer"));
      return rejectWithValue(null);
    }
  }
);
