import {
  makeStyles,
  Theme,
  createStyles,
  List,
  ListItem,
  Avatar,
  ListItemText,
  Grid,
  Typography,
  ButtonBase,
  Box,
} from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { GetTeacherList } from "../../../actions/Classroom/TeacherList/TeacherListAction";
import { TeacherListTypeI } from "../../../actions/Classroom/TeacherList/TeacherListActionTypes";
import { RootStore } from "../../../store";
import checkImageUrl from "../../utils/checkImageUrl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "90%",
      padding: "1rem",
      margin: theme.spacing(0, 2, 0, 2),
      transition: "transform 1s, color 1s",
      "&:hover": {
        transform: "scale(1.05)",
      },
    },
    inline: {
      display: "inline",
    },
  })
);

const TeacherRoom = (props: any) => {
  const classes = useStyles();
  const { classItem } = props;
  const color = ["rgba(134,122,233,0.5)", "#ACE6E8", "#5FEF84"];
  const dispatch = useDispatch();
  const history = useHistory();

  const teacherSelector = useSelector(
    (state: RootStore) => state.teacher_lists.teacher_list
  );

  function getRandomInt(max: number) {
    return Math.floor(Math.random() * max);
  }

  const handleLink = () => {
    history.push("teacher");
  };

  useEffect(() => {
    // classItem && dispatch(GetTeacherList(classItem.grade, null));
  }, [classItem]);

  return (
    <>
      {/* <Typography variant="h6">Teacher Wise</Typography> */}
      <List>
        <ButtonBase
          focusRipple
          onClick={handleLink}
          className={classes.root}
          style={{
            backgroundColor: `${color[getRandomInt(3)]}`,
            border: "1px solid",
            borderColor: "#fff",
          }}
        >
          <ListItem alignItems="flex-start" disableGutters>
            <ListItemText
              primary={
                <>
                  <Grid container>
                    <Box fontSize={14} fontWeight={450}>
                      Teachers of Class {classItem?.grade_name}
                    </Box>
                  </Grid>
                </>
              }
              secondary={
                <Grid container direction="column">
                  <AvatarGroup max={3}>
                    {teacherSelector.length > 0 &&
                      teacherSelector.map((teacher: TeacherListTypeI) => {
                        return (
                          <Avatar
                            key={teacher.id}
                            alt=""
                            src={checkImageUrl(teacher.photo)}
                          />
                        );
                      })}
                  </AvatarGroup>

                  <Typography
                    style={{ marginTop: "10px" }}
                    component="span"
                    variant="body2"
                    color="textPrimary"
                  >
                    Tuesday 07, June
                  </Typography>
                </Grid>
              }
            />
          </ListItem>
        </ButtonBase>
      </List>
    </>
  );
};

export default TeacherRoom;
