import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getTermsConditions,
  updateTermsConditions,
} from "./termsconditionsApi";

interface PayloadI {
  id: string;
  tac_field: string;
}

interface InitialStateI {
  loading: boolean;
  actionPerformed: boolean;
  termsConditions: {
    id: string;
    tac_field: string;
  } | null;
}

const initialState: InitialStateI = {
  loading: false,
  termsConditions: null,
  actionPerformed: false,
};

const termsConditionsSlice = createSlice({
  name: "termsConditions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTermsConditions.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(
      getTermsConditions.fulfilled,
      (state, { payload }: PayloadAction<PayloadI>) => {
        state.loading = false;
        if (Object.keys(payload).length) {
          state.termsConditions = payload;
        } else {
          state.termsConditions = null;
        }
      }
    );
    builder.addCase(getTermsConditions.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateTermsConditions.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(updateTermsConditions.fulfilled, (state) => {
      state.loading = false;
      state.actionPerformed = true;
    });
    builder.addCase(updateTermsConditions.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default termsConditionsSlice.reducer;
