import React from "react";
import { CommunicationSidebar } from "../../components";
import AnnoucementForm from "../../components/Communication/Annoucement/AddAnnoucement/AnnouncementForm";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const AnnoucementFormPage = () => {
  const nav = <CommunicationSidebar announcement />;
  return (
    <SubModuleLayout communicationMenuActive sideNav={nav}>
      <AnnoucementForm />
    </SubModuleLayout>
  );
};

export default AnnoucementFormPage;
