import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { EventsForm, EventsList } from "../../components";
import EventSidebar from "../../components/Event/EventSidebar/EventSideBar";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

interface EditDataI {
  id: number;
  name: string;
  count: number;
}

export default function EventsPage() {
  const [count, setCount] = useState<number>(1);
  const [formData, setFormData] = useState<EditDataI | null>(null);
  const [edit, setEdit] = useState<any>();

  const fetchEditData = (data: EditDataI) => {
    setFormData({ ...data, count: count });
    setCount(count + 1);
  };

  const onEditCheck = (value: boolean) => {
    setEdit({ check: value, count: count });
  };

  const nav = <EventSidebar events />;
  return (
    <SubModuleLayout sideNav={nav}>
      <Grid container>
        <Grid item xs={3}>
          <EventsForm onEditCheck={onEditCheck} editData={formData} />
        </Grid>
        <Grid item xs={9}>
          <EventsList editCheck={edit} onEditData={fetchEditData} />
        </Grid>
      </Grid>
    </SubModuleLayout>
  );
}
