export const GET_ONLINE_CLASS_LOADING = "GET_ONLINE_CLASS_LOADING";
export const GET_ONLINE_CLASS_ERROR = "GET_ONLINE_CLASS_ERROR";
export const GET_ONLINE_CLASS_SUCCESS = "GET_ONLINE_CLASS_SUCCESS";

export const GET_ONLINE_CLASS_LOG_LOADING = "GET_ONLINE_CLASS_LOG_LOADING";
export const GET_ONLINE_CLASS_LOG_ERROR = "GET_ONLINE_CLASS_LOG_ERROR";
export const GET_ONLINE_CLASS_LOG_SUCCESS = "GET_ONLINE_CLASS_LOG_SUCCESS";


export interface AdminOnlineClassTypeI {
  id?: number;
  subject: number;
  title: string;
  teacher_first_name?: string;
  teacher_last_name?: string;
  subject_name?: string;
  class_date: string;
  start_time: string;
  end_time: string;
  grade: number;
  section: number;
  academic_session?: number;
  link: string;
  is_completed?: boolean;
  is_upcomming?: boolean;
  is_ongoing?: boolean;
}

//===============================<START>GET ONLINE CLASSES<START>======================//

export interface GetOnlineClassLoading {
  type: typeof GET_ONLINE_CLASS_LOADING;
}

export interface GetOnlineClassError {
  type: typeof GET_ONLINE_CLASS_ERROR;
}

export interface GetOnlineClassSuccess {
  type: typeof GET_ONLINE_CLASS_SUCCESS;
  payload: AdminOnlineClassTypeI[];
}

//===============================<END>GET ONLINE CLASSES<END>======================//

//===============================<START>GET ONLINE CLASSES<START>======================//

export interface GetOnlineClassLogLoading {
  type: typeof GET_ONLINE_CLASS_LOG_LOADING;
}

export interface GetOnlineClassLogError {
  type: typeof GET_ONLINE_CLASS_LOG_ERROR;
}

export interface GetOnlineClassLogSuccess {
  type: typeof GET_ONLINE_CLASS_LOG_SUCCESS;
  payload: any;
}

//===============================<END>GET ONLINE CLASSES<END>======================//
export type OnlineClassDispatchTypes =
  | GetOnlineClassLoading
  | GetOnlineClassError
  | GetOnlineClassSuccess
  | GetOnlineClassLogLoading
  | GetOnlineClassLogError
  | GetOnlineClassLogSuccess;
