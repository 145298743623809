import React from "react";
import {
  Radio,
  makeStyles,
  FormControlLabel,
  Grid,
  RadioGroup,
  InputLabel,
} from "@material-ui/core";
import { checkedIcon, icon } from "../../Styles/ReusableStyles";
import clsx from "clsx";
import { useFormStyles } from "../../Styles/FormStyles";

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    ...icon,
  },
  checkedIcon: {
    ...checkedIcon,
  },
});

interface PropsI {
  items: any;
  value: string | number;
  setValue: any;
  label: string;
  required: any;
  onValue: boolean;
}

const CusRadioGroup = (props: any) => {
  const { items, value, setValue, label, required, onValue, row } = props;
  const classes = useFormStyles();
  return (
    <Grid container alignItems="center">
      <Grid item xs={12} className={classes.formWrapper}>
        <InputLabel>
          {props.label}
          {required && <span style={{ color: "red" }}>*</span>}
        </InputLabel>
        <RadioGroup
          row={props.row}
          className={classes.radioGroup}
          value={value}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            onValue == true
              ? setValue(parseInt(event.target.value))
              : setValue(event.target.value);
          }}
        >
          {items.map(({ id, name }: any, index: number) => (
            <Grid item key={index}>
              <CusRadio value={id} label={name} />
            </Grid>
          ))}
        </RadioGroup>
      </Grid>
    </Grid>
  );
};

const CusRadioGroupInt = (props: any) => {
  const { items, value, setValue, label, required, onValue, row } = props;
  const classes = useFormStyles();
  return (
    <Grid container alignItems="center">
      <Grid item xs={12} className={classes.formWrapper}>
        <InputLabel>
          {props.label}
          {required && <span style={{ color: "red" }}>*</span>}
        </InputLabel>
        <RadioGroup
          row={props.row}
          className={classes.radioGroup}
          value={value}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            onValue == true
              ? setValue(event.target.value)
              : setValue(parseInt(event.target.value));
          }}
        >
          {items.map(({ id, name }: any, index: number) => (
            <Grid item key={index}>
              <CusRadio value={id} label={name} />
            </Grid>
          ))}
        </RadioGroup>
      </Grid>
    </Grid>
  );
};

const CusRadio = (props: any) => {
  return <FormControlLabel control={<CusRadioEl />} {...props} />;
};

const CusRadioEl = (props: any) => {
  const { label, ...rest } = props;
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      size="medium"
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
};

export default CusRadioEl;
export { CusRadio, CusRadioGroup, CusRadioGroupInt };
