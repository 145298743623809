import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { EnoteCategoryTypeI } from "../../actions/Academics/EnoteCategory/EnoteCategoryActionTypes";
import { DesignationTypeI } from "../../actions/HumanResource/Designation/DesignationActionTypes";
import {
  AcademicSidebar,
  DesignationForm,
  DesignationTable,
  HRsidebar,
} from "../../components";
import EnoteCategoryForm from "../../components/Academic/EnoteCategory/EnoteCategoryForm";
import EnoteCategoryTable from "../../components/Academic/EnoteCategory/EnoteCategoryTable";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const EnoteCategoryPage = () => {
  const [formData, setFormData] = useState<EnoteCategoryTypeI | null>(null);
  const [count, setCount] = useState<number | undefined>(0);
  const [edit, setEdit] = useState<any>();

  const fetchEditData = (data: EnoteCategoryTypeI) => {
    setFormData({ ...data, count });
    count && setCount(count + 1);
  };

  const onEditMode = (value: boolean) => {
    setEdit({ value, count });
  };

  const nav = <AcademicSidebar enoteCategory />;
  return (
    <SubModuleLayout HumanMenuActive sideNav={nav}>
      <Grid container>
        <Grid item xs={3}>
          <EnoteCategoryForm onEditMode={onEditMode} editData={formData} />
        </Grid>
        <Grid item xs={9}>
          <EnoteCategoryTable edit={edit} onEditData={fetchEditData} />
        </Grid>
      </Grid>
    </SubModuleLayout>
  );
};

export default EnoteCategoryPage;
