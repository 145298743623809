import { Button, Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import { useFormStyles } from "../../Styles/FormStyles";

import { Clear, Send } from "@material-ui/icons";

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { GenerateResultSms } from "../../../actions/Communication/SendResult/SendResultAction";

interface PropsI {
  actionConfirmed: (value: Object) => void;
  studentIds: string[];
}

const GenerateResultModalView = (props: PropsI) => {
  const { actionConfirmed, studentIds } = props;
  const classes = useFormStyles();

  const [state, setState] = React.useState({
    gpa: false,
    percentage: false,
    position: false,
    all_grades: false,
  });

  // ================ <START> REACT HOOKS <START> ================== //
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (studentIds.length && examInfo) {
  //     dispatch(
  //       GenerateResultSms({ exam_info: examInfo, students: studentIds })
  //     );
  //   }
  // }, [studentIds, examInfo]);

  //================ <END> REACT HOOKS <END> ================== //

  const handleClick = () => {
    actionConfirmed(state);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <React.Fragment>
      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={3} className={classes.formRow}>
          <FormControlLabel
            labelPlacement="end"
            control={
              <Checkbox
                size="small"
                checked={state.gpa}
                onChange={handleChange}
                name="gpa"
                color="primary"
              />
            }
            label="GPA"
          />
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={state.percentage}
                onChange={handleChange}
                name="percentage"
                color="primary"
              />
            }
            label="Percentage"
          />
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={state.position}
                onChange={handleChange}
                name="position"
                color="primary"
              />
            }
            label="Position"
          />
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={state.all_grades}
                onChange={handleChange}
                name="all_grades"
                color="primary"
              />
            }
            label="All Grades"
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        style={{ marginTop: "15px", marginBottom: "5px" }}
      >
        <Grid item style={{ marginLeft: "auto" }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Send />}
            onClick={() => handleClick()}
          >
            Send
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default GenerateResultModalView;
