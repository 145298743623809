// ---------------- <START> module import ------------------//
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import {
  AddDepartment,
  UpdateDepartment,
} from "../../../actions/HumanResource/Department/DepartmentAction";
import FormLayout from "../../Reusable/Layouts/Form.Layout";
import CustomizedTextField from "../../Reusable/Inputs/TextField";
import { DepartmentTypeI } from "../../../actions/HumanResource/Department/DepartmentActionTypes";
import useLoading from "../../../useHook/useLoading";
// ---------------- <END> module import ------------------//

// ---------------- <START> Interface ------------------//
interface DepartmentFormProps {
  editData: DepartmentTypeI | null;
}
// ---------------- <END> Interface ------------------//

// ---------------- <START> Department Form Component ------------------//
const DepartmentForm = (props: DepartmentFormProps) => {
  const { editData } = props;
  const dispatch = useDispatch();

  const [editMode, setEditMode] = useState<boolean>(false);
  const [editID, setEditID] = useState<string | null>(null);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const { register, handleSubmit, setValue, reset, errors } = useForm();

  const departmentState = useSelector((state: RootStore) => state.department);
  const { loading } = useLoading(departmentState.loading);
  const add_or_update = useSelector((state: RootStore) => state.department.add_or_update)

  useEffect(() => {
    if (add_or_update) {
      setIsBtnLoading(false)
      handleReset();
    }
  }, [add_or_update])

  useEffect(() => {
    editData != null && handleEditTableData(editData);
  }, [editData]);

  const handleEditTableData = (data: DepartmentTypeI) => {
    setEditMode(true);
    setValue("name", data.name);
    setEditID(data.id);
  };

  const onSubmit = (data: DepartmentTypeI) => {
    setIsBtnLoading(true);
    if (editMode) {
      editID != null && dispatch(UpdateDepartment(editID, data));
    } else {
      dispatch(AddDepartment(data));
    }
  };

  const handleReset = () => {
    reset();
    setEditMode(false);
    setEditID(null);
  };

  return (
    <FormLayout
      title="Add Department"
      onSubmit={handleSubmit(onSubmit)}
      editMode={editMode}
      loading={loading}
      onClick={!isBtnLoading && handleReset}
      add_or_update={isBtnLoading}
    >
      <CustomizedTextField
        label="Name"
        placeholder="Label"
        name="name"
        required
        error={errors["name"] ? "Required Field." : ""}
        inputRef={register({ required: true })}
      />
    </FormLayout>
  );
};
// ---------------- <END> Department Form Component ------------------//

export default DepartmentForm;
