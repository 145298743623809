//---------------------------<START> import modules starts-----------------------//
import React, { useEffect, useState } from 'react';
import { Grid, Paper } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useFormStyles } from '../../Styles/FormStyles';
import CustomizedTextField from '../../Reusable/Inputs/TextField';
import FormButton from '../../Reusable/Buttons/FormButton';
import CustomizedSelect from '../../Reusable/Inputs/Select';
import { useDispatch } from 'react-redux';
import {
  AddSmsTemplate,
  UpdateSmsTemplate,
} from '../../../actions/Communication/SmsTemplate/SmsTemplateAction';
import { SmsTemplateTypeI } from '../../../actions/Communication/SmsTemplate/SmsTemplateActionTypes';
import SmsTips from '../SMS/SmsTips';
//-------------------------<END> import modules ends ----------------------------//

interface PropsI {
  mode: 'Add' | 'Edit';
  initData: SmsTemplateTypeI | null;
  onClose: () => void;
}

interface Tupple {
  key: number;
  value: string;
}
//--------------------------<START> Add Annoucement Modal components starts------------------------------//
const SmsTemplateModal = (props: PropsI) => {
  const { mode, onClose, initData } = props;
  const classes = useFormStyles();
  const { register, handleSubmit, errors, setValue } = useForm();
  const dispatch = useDispatch();
  const [smsType, setSmsType] = useState<Tupple | null>(null);

  const SMS_CATEGORY_CHOICES = [
    { key: 1, value: 'Fees' },
    { key: 2, value: 'Result' },
    { key: 3, value: 'Announcement' },
    { key: 4, value: 'Examination' },
  ];

  // ====================== <START> REACT HOOK <START> ====================== //
  useEffect(() => {
    initData != null && populateInitData(initData);
  }, [initData]);
  // ====================== <END> REACT HOOK <END> ====================== //

  const onSubmit = (data: any) => {
    if (data && data.title && data.description && smsType) {
      const requiredData = {
        title: data.title,
        text: data.description,
        sms_category: smsType.value,
      };
      if (mode === 'Add') {
        dispatch(AddSmsTemplate(requiredData));
      } else {
        initData && dispatch(UpdateSmsTemplate(initData.id, requiredData));
      }
    }

    onClose();
  };

  const populateInitData = (data: SmsTemplateTypeI) => {
    setValue('title', data.title);
    setValue('description', data.text);

    const sms_cat = SMS_CATEGORY_CHOICES.find(
      (element) => element.value === data.sms_category
    );
    sms_cat && setSmsType(sms_cat);
  };

  return (
    <Paper elevation={0}>
      <div className={classes.modalContent}>
        <Grid container spacing={1}>
          <Grid item xs={7}>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
              <Grid container>
                <Grid item xs={12} className={classes.formWrapper}>
                  <CustomizedTextField
                    label="Title"
                    placeholder="Title"
                    name="title"
                    required
                    error={errors['title'] ? 'Title is required' : ''}
                    inputRef={register({ required: true })}
                  />
                </Grid>

                <Grid item xs={12} className={classes.formWrapper}>
                  <CustomizedSelect
                    label="Sms Category"
                    name="sms_category"
                    options={SMS_CATEGORY_CHOICES}
                    value={smsType}
                    setValue={setSmsType}
                    error={
                      errors['sms_category']
                        ? 'Sms category must be selected'
                        : ''
                    }
                    inputRef={register({ required: true })}
                  />
                </Grid>

                <Grid item xs={12} className={classes.formWrapper}>
                  <CustomizedTextField
                    label="Description"
                    placeholder="Label"
                    name="description"
                    required
                    multiline
                    rows={4}
                    error={errors['description'] ? 'Required Field.' : ''}
                    inputRef={register({ required: true })}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                justifyContent="flex-end"
                style={{ paddingBottom: '8px' }}
              >
                <FormButton>
                  {mode === 'Add' ? 'Add Template' : 'Edit Template'}
                </FormButton>
              </Grid>
            </form>
          </Grid>
          <Grid item xs={5}>
            <SmsTips />
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
};
//---------------------------<END> Add Annoucement Modal components end ---------------------------//
export default SmsTemplateModal;
