export const GET_STUDENT_HOUSE_LOADING = "GET_STUDENT_HOUSE_LOADING";
export const GET_STUDENT_HOUSE_ERROR = "GET_STUDENT_HOUSE_ERROR";
export const GET_STUDENT_HOUSE_SUCCESS = "GET_STUDENT_HOUSE_SUCCESS";

export const ADD_STUDENT_HOUSE_LOADING = "ADD_STUDENT_HOUSE_LOADING";
export const ADD_STUDENT_HOUSE_ERROR = "ADD_STUDENT_HOUSE_ERROR";
export const ADD_STUDENT_HOUSE_SUCCESS = "ADD_STUDENT_HOUSE_SUCCESS";

export const UPDATE_STUDENT_HOUSE_LOADING = "UPDATE_STUDENT_HOUSE_LOADING";
export const UPDATE_STUDENT_HOUSE_ERROR = "UPDATE_STUDENT_HOUSE_ERROR";
export const UPDATE_STUDENT_HOUSE_SUCCESS = "UPDATE_STUDENT_HOUSE_SUCCESS";

export const DELETE_STUDENT_HOUSE_LOADING = "DELETE_STUDENT_HOUSE_LOADING";
export const DELETE_STUDENT_HOUSE_ERROR = "DELETE_STUDENT_HOUSE_ERROR";
export const DELETE_STUDENT_HOUSE_SUCCESS = "DELETE_STUDENT_HOUSE_SUCCESS";

export type StudentHouseTypeI = {
  id: string;
  name: string;
};

//===============================<START>GET STUDENT_HOUSE<START>======================//

export interface GetStudentHouseLoading {
  type: typeof GET_STUDENT_HOUSE_LOADING;
}

export interface GetStudentHouseError {
  type: typeof GET_STUDENT_HOUSE_ERROR;
}

export interface GetStudentHouseSuccess {
  type: typeof GET_STUDENT_HOUSE_SUCCESS;
  payload: StudentHouseTypeI[];
}

//===============================<END>GET STUDENT_HOUSE<END>======================//

//===============================<START>ADD STUDENT_HOUSE<START>======================//

export interface AddStudentHouseLoading {
  type: typeof ADD_STUDENT_HOUSE_LOADING;
}

export interface AddStudentHouseError {
  type: typeof ADD_STUDENT_HOUSE_ERROR;
}

export interface AddStudentHouseSuccess {
  type: typeof ADD_STUDENT_HOUSE_SUCCESS;
  payload: StudentHouseTypeI;
}

//===============================<END>ADD STUDENT_HOUSE<END>======================//

//===============================<START>UPDATE STUDENT_HOUSE<START>======================//

export interface UpdateStudentHouseLoading {
  type: typeof UPDATE_STUDENT_HOUSE_LOADING;
}

export interface UpdateStudentHouseError {
  type: typeof UPDATE_STUDENT_HOUSE_ERROR;
}

export interface UpdateStudentHouseSuccess {
  type: typeof UPDATE_STUDENT_HOUSE_SUCCESS;
  payload: StudentHouseTypeI;
}

//===============================<END>UPDATE STUDENT_HOUSE<END>======================//

//===============================<START>DELETE STUDENT_HOUSE<START>======================//

export interface DeleteStudentHouseLoading {
  type: typeof DELETE_STUDENT_HOUSE_LOADING;
}

export interface DeleteStudentHouseError {
  type: typeof DELETE_STUDENT_HOUSE_ERROR;
}

export interface DeleteStudentHouseSuccess {
  type: typeof DELETE_STUDENT_HOUSE_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE STUDENT_HOUSE<END>======================//

export type StudentHouseDispatchTypes =
  | GetStudentHouseLoading
  | GetStudentHouseError
  | GetStudentHouseSuccess
  | AddStudentHouseLoading
  | AddStudentHouseError
  | AddStudentHouseSuccess
  | UpdateStudentHouseLoading
  | UpdateStudentHouseError
  | UpdateStudentHouseSuccess
  | DeleteStudentHouseLoading
  | DeleteStudentHouseError
  | DeleteStudentHouseSuccess;
