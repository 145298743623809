//-------------------<Start> module imports starts ----------------------//
import React, { useEffect, useState, useRef } from "react";
import { useTableStyles } from "../../../../Styles/TableStyles";
import { LinearProgress } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../../../store";
import styled from "styled-components";
import { getTeacherTimeTable } from "../../../../../rtk/features/timetable/timetableApi";

// -------------------<END> module imports end ----------------------//

//---------------------------- <START> interface starts ----------------------------------//

const PreviewContainer = styled.div`
  overflow: scroll;
`;

const Row = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
`;

const IndexCell = styled.div`
  width: 100%;
  min-height: 100px;
  padding: 10px;
  margin: 2px;
  background-color: #00000026;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SubjectDetail = styled.div`
  background-color: #3d3d3d15;
  width: 100%;
  min-height: 100px;
  padding: 10px;
  margin: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > h3 {
    font-size: 16px;
    font-weight: 500;
    margin: 2px 0;
  }

  & > p {
    font-size: 12px;
    font-weight: 500;
    margin: 2px 0;
  }
`;

//---------------------------- <END> interface endss ----------------------------------//

// ---------------<START> Timetable Schedule component starts -----------------//

interface PropsI {
  staffId: string;
}

const StaffTimeTable = (props: PropsI) => {
  const classes = useTableStyles();
  const dispatch = useDispatch();

  const { staffId } = props;
  // States declaration

  const { teacherTimetable, loading } = useSelector(
    (state: RootStore) => state.timetable
  );

  //================================ <START> REDUX CYCLE HOOKS <START> ========================//
  useEffect(() => {
    dispatch(getTeacherTimeTable(staffId));
  }, [dispatch]);

  //================================ <END> REDUX CYCLE HOOKS <END> ========================//

  const returnSubjectDetail = (
    gradeId: string,
    section: null | string,
    day: string
  ) => {
    const timetable = section
      ? teacherTimetable.find(
        (item) =>
          item.classes.grade === gradeId &&
          item.classes.section?.id === section
      )
      : teacherTimetable.find(
        (item) =>
          item.classes.grade === gradeId && item.classes.section === null
      );

    if (timetable) {
      const byDay = timetable.timetable.find((item) => item.day === day);

      if (byDay) {
        return (
          <SubjectDetail>
            <h3>{byDay.subject.name}</h3>
            <p>{byDay.period.name}</p>
            <p>
              {byDay.period.start_time} - {byDay.period.end_time}
            </p>
          </SubjectDetail>
        );
      }
    }
    return (
      <SubjectDetail>
        <h3>--</h3>
        <p>--</p>
        <p>--</p>
      </SubjectDetail>
    );
  };

  const scheduleDays = [
    { id: "7", name: "Sunday" },
    { id: "1", name: "Monday" },
    { id: "2", name: "Tuesday" },
    { id: "3", name: "Wednesday" },
    { id: "4", name: "Thursday" },
    { id: "5", name: "Friday" },
  ];

  return (
    <>
      {loading && (
        <LinearProgress style={{ margin: "-24px -24px 24px -24px" }} />
      )}

      <PreviewContainer>
        <Row>
          <IndexCell>Classes</IndexCell>
          {scheduleDays.map((item) => {
            return <IndexCell key={item.id}>{item.name}</IndexCell>;
          })}
        </Row>
        {teacherTimetable.map((item, index) => {
          return (
            <Row key={index + 1}>
              <IndexCell>
                {item.classes.grade_name}{" "}
                {item.classes.section ? `- ${item.classes.section.name}` : ""}
              </IndexCell>
              {scheduleDays.map((elem, index) => {
                return returnSubjectDetail(
                  item.classes.grade,
                  item.classes.section?.id || null,
                  elem.id
                );
              })}
            </Row>
          );
        })}
      </PreviewContainer>
    </>
  );
};
// --------------------<ENDS> Time table Schedule ends ----------------------//
export default StaffTimeTable;
