import { Paper, Grid } from "@material-ui/core";
import React, { useState } from "react";
import { ClassTypeI } from "../../actions/Academics/Class/ClassActionTypes";
import AdminAssignmentTable from "../../components/ClassRoom/AdminAssignmentTable";
import ClassLayout from "../../components/ClassRoom/Layout/ClassLayout";
import ClassRoomSidebar from "../../components/ClassRoom/Layout/ClassRoomSidebar";
import SearchAssignment from "../../components/ClassRoom/SearchAssignment";

const AssignmentPage = () => {
  const [classItem, setClassItem] = useState<ClassTypeI | null>(null);

  const onClassItem = (value: ClassTypeI) => {
    setClassItem(value);
  };

  const nav = <ClassRoomSidebar onClassItem={onClassItem} />;

  return (
    <div
      style={{ marginTop: "110px", paddingRight: "20px", minHeight: "80vh" }}
    >
      <ClassLayout sideNav={nav}>
        <SearchAssignment classItem={classItem} />
        <AdminAssignmentTable />
      </ClassLayout>
    </div>
  );
};
export default AssignmentPage;
