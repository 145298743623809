import {
  GET_ANNOUNCEMENT_LOADING,
  GET_ANNOUNCEMENT_ERROR,
  GET_ANNOUNCEMENT_SUCCESS,
  GET_ANNOUNCEMENT_DRAFT_LOADING,
  GET_ANNOUNCEMENT_DRAFT_ERROR,
  GET_ANNOUNCEMENT_DRAFT_SUCCESS,
  SAVE_ANNOUNCEMENT_DRAFT_LOADING,
  SAVE_ANNOUNCEMENT_DRAFT_ERROR,
  SAVE_ANNOUNCEMENT_DRAFT_SUCCESS,
  UPDATE_ANNOUNCEMENT_DRAFT_LOADING,
  UPDATE_ANNOUNCEMENT_DRAFT_ERROR,
  UPDATE_ANNOUNCEMENT_DRAFT_SUCCESS,
  GET_ANNOUNCEMENT_DRAFT_BY_ID_LOADING,
  GET_ANNOUNCEMENT_DRAFT_BY_ID_ERROR,
  GET_ANNOUNCEMENT_DRAFT_BY_ID_SUCCESS,
  AnnouncementDispatchTypes,
  ADD_ANNOUNCEMENT_LOADING,
  ADD_ANNOUNCEMENT_ERROR,
  ADD_ANNOUNCEMENT_SUCCESS,
  DELETE_ANNOUNCEMENT_LOADING,
  DELETE_ANNOUNCEMENT_ERROR,
  DELETE_ANNOUNCEMENT_SUCCESS,
  UPDATE_ANNOUNCEMENT_LOADING,
  UPDATE_ANNOUNCEMENT_ERROR,
  UPDATE_ANNOUNCEMENT_SUCCESS,
  AnnouncementTypeI,
} from "../../actions/Communication/Announcement/AnnouncementActionTypes";

interface InitialStateI {
  loading: boolean;
  announcements: AnnouncementTypeI[];
  announcementDrafts: AnnouncementTypeI[];
  announcementDraft: AnnouncementTypeI | null;
  actionPerformed: boolean;
  getFailed: boolean;
  draftSaved: boolean;
  refresh: boolean;
}

const initialState: InitialStateI = {
  loading: false,
  announcements: [],
  announcementDrafts: [],
  announcementDraft: null,
  actionPerformed: false,
  getFailed: false,
  draftSaved: false,
  refresh: false,
};

const AnnouncementReducer = (
  state: InitialStateI = initialState,
  action: AnnouncementDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_ANNOUNCEMENT_LOADING:
      return {
        ...state,
        loading: true,
        actionPerformed: false,
      };

    case GET_ANNOUNCEMENT_ERROR:
      return {
        ...state,
        loading: false,
        actionPerformed: false,
      };

    case GET_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        announcements: action.payload.results,
        actionPerformed: false,
      };

    case GET_ANNOUNCEMENT_DRAFT_SUCCESS:
      return {
        ...state,
        loading: false,
        announcementDrafts: action.payload.results,
        actionPerformed: false,
        draftSaved: false,
      };

    case GET_ANNOUNCEMENT_DRAFT_LOADING:
      return {
        ...state,
        loading: true,
        actionPerformed: false,
        draftSaved: false,
        announcementDraft: null,
        refresh: false,
      };

    case GET_ANNOUNCEMENT_DRAFT_ERROR:
      return {
        ...state,
        loading: false,
        actionPerformed: false,
        draftSaved: false,
      };

    case SAVE_ANNOUNCEMENT_DRAFT_SUCCESS:
      return {
        ...state,
        loading: false,
        draftSaved: false,
      };

    case SAVE_ANNOUNCEMENT_DRAFT_LOADING:
      return {
        ...state,
        loading: true,
        draftSaved: true,
      };

    case SAVE_ANNOUNCEMENT_DRAFT_ERROR:
      return {
        ...state,
        loading: false,
        actionPerformed: false,
      };

    case UPDATE_ANNOUNCEMENT_DRAFT_SUCCESS:
      return {
        ...state,
        loading: false,
        draftSaved: false,
      };

    case UPDATE_ANNOUNCEMENT_DRAFT_LOADING:
      return {
        ...state,
        loading: true,
        draftSaved: true,
      };

    case UPDATE_ANNOUNCEMENT_DRAFT_ERROR:
      return {
        ...state,
        loading: false,
        actionPerformed: false,
      };

    case GET_ANNOUNCEMENT_DRAFT_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        announcementDraft: action.payload,
        actionPerformed: false,
      };

    case GET_ANNOUNCEMENT_DRAFT_BY_ID_LOADING:
      return {
        ...state,
        loading: true,
        actionPerformed: false,
        getFailed: false,
      };

    case GET_ANNOUNCEMENT_DRAFT_BY_ID_ERROR:
      return {
        ...state,
        loading: false,
        announcementDraft: null,
        actionPerformed: false,
        getFailed: true,
      };

    case ADD_ANNOUNCEMENT_LOADING:
      return {
        ...state,
        loading: true,
        actionPerformed: false,
      };

    case ADD_ANNOUNCEMENT_ERROR:
      return {
        ...state,
        loading: false,
        actionPerformed: false,
      };

    case ADD_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        actionPerformed: true,
        refresh: true,
      };

    case UPDATE_ANNOUNCEMENT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_ANNOUNCEMENT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case UPDATE_ANNOUNCEMENT_SUCCESS:
      const current_data: AnnouncementTypeI[] = state.announcements;
      const index: number = current_data.findIndex(
        (element) => element.id === action.payload.id
      );
      current_data[index] = action.payload;

      return {
        ...state,
        loading: false,
        announcements: current_data,
      };

    case DELETE_ANNOUNCEMENT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_ANNOUNCEMENT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_ANNOUNCEMENT_SUCCESS:
      const new_data: AnnouncementTypeI[] = state.announcements;
      const item_index = new_data.findIndex(
        (element) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return {
        ...state,
        loading: false,
        announcements: new_data,
        refresh: true,
      };

    default:
      return state;
  }
};

export default AnnouncementReducer;
