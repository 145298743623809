import XLSX from "xlsx";
import {} from "xlsx";

const ExportToExcel: Function = (label = "Hamro Academy") => {
  // const SheetNames: string[] = [];
  // const Sheets = {};
  // const tableEl = document.getElementById("export-table")!;
  // const wb = { SheetNames, Sheets };
  // const ws9 = XLSX.utils.table_to_sheet(tableEl, { raw: true });
  // // Get total number of columns
  // const totalColumns = tableEl.querySelectorAll("th").length;
  // // Hide last columns ~ actions buttons
  // ws9["!cols"] = [];
  // ws9["!cols"][totalColumns - 1] = { hidden: true };
  // wb.SheetNames.push("Excel Sheet");
  // wb.Sheets["Excel Sheet"] = ws9;
  // XLSX.writeFile(wb, `${label}.xlsx`, { cellStyles: true });
};

export default ExportToExcel;
