import React from 'react'
import { IdCardList, ReportSideBar, SearchIdCard } from "../../../../components";
import BrowseTemplates from '../../../../components/Reports/Templates/IdCard/BrowseTemplates';
import SubModuleLayout from '../../../../components/Reusable/Layouts/SubModuleLayout';

const TemplateBrowsePage = () => {
    const nav = <ReportSideBar id_card_template />;
  return (
    <SubModuleLayout reportMenuActive sideNav={nav}>
      <BrowseTemplates />
    </SubModuleLayout>
  );
}

export default TemplateBrowsePage
