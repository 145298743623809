import React from 'react';
import { CommunicationSidebar } from '../../components';
import AnnoucementList from '../../components/Communication/Annoucement/AnnoucementList';
import StudentMappingList from '../../components/Transport/StudentBusStopMapping/StudentMappingList';
import SubModuleLayout from '../../components/Reusable/Layouts/SubModuleLayout';
import TransportSidebar from '../../components/Transport/TransportSidebar';

const StudentBusStopMappingPage = () => {
  const nav = <TransportSidebar studentBusStopMapping />;
  return (
    <SubModuleLayout sideNav={nav}>
      <StudentMappingList />
    </SubModuleLayout>
  );
};

export default StudentBusStopMappingPage;
