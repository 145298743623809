import { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import FormLayout from "../../../Reusable/Layouts/Form.Layout";
import CustomizedTextField from "../../../Reusable/Inputs/TextField";
import { RootStore } from "../../../../store";
import { RubricCategoryTypeI } from "../../../../actions/Academics/RubricCategory/RubricCategoryActionTypes"
import { AddRubricSubCategory, UpdateRubricSubCategory } from "../../../../actions/Academics/RubricCategory/RubricSubCategory/RubricSubCategoryAction"

interface RubricCategoryFormProps {
    editData: RubricCategoryTypeI | null;
    onEditMode: (value: boolean) => void;
    id: string | null;
}

const RubricSubCategoryForm = (props: RubricCategoryFormProps) => {
    const { editData, onEditMode, id } = props;
    const dispatch = useDispatch();
    const { register, handleSubmit, setValue, reset, errors } = useForm({
        mode: "onChange",
    });
    const [editMode, setEditMode] = useState<boolean>(false);
    const [editID, setEditID] = useState<string | null>(null);
    const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
    const { loading, add_or_update } = useSelector((state: RootStore) => state.rubric_sub_category);

    useEffect(() => {
        add_or_update && setIsBtnLoading(false)
        setEditMode(false);
        setEditID(null);
    }, [add_or_update])
    useEffect(() => {
        editData != null && handleEditTableData(editData);
    }, [editData]);
    const handleEditTableData = (data: RubricCategoryTypeI) => {
        setEditMode(true);
        setValue("name", data.name);
        setEditID(data.id);
    };

    const onSubmit = (data: RubricCategoryTypeI) => {
        const submitData = {
            name: data.name,
            category: id,
        }
        setIsBtnLoading(true);
        if (editMode) {
            editID != null && dispatch(UpdateRubricSubCategory(editID, submitData));
        } else {
            dispatch(AddRubricSubCategory(submitData));
        }
        handleReset();
    };

    const handleReset = () => {
        reset();
        setEditMode(false);
        onEditMode(false);
        setEditID(null);
    };


    return (
        <FormLayout
            title="Add Rubric Sub-Category"
            onSubmit={handleSubmit(onSubmit)}
            editMode={editMode}
            loading={loading}
            onClick={!isBtnLoading && handleReset}
            add_or_update={isBtnLoading}
        >
            <CustomizedTextField
                label="Name"
                placeholder="Label"
                name="name"
                required
                error={errors["name"] ? "Required Field." : ""}
                inputRef={register({ required: true })}
            />
        </FormLayout>
    )
}

export default RubricSubCategoryForm