import React, { useState, useEffect } from 'react';
import { Card, Grid, Box, CardMedia, CardContent } from '@material-ui/core';
import clsx from 'clsx';
import { useAdmitCardStyles } from './AdmitCardStyles';
import { useDispatch, useSelector } from 'react-redux';
import { GetReportSetting } from '../../../actions/GeneralSettings/ReportSetting/ReportSettingAction';
import { RootStore } from '../../../store';
import checkActiveSignature from '../../utils/checkActiveSignature';
import { AnyPtrRecord } from 'dns';
import checkImageUrl from '../../utils/checkImageUrl';
import { dateConverterAdToBs } from '../../utils/dateConverter';
interface InfoPropsI {
  value: string | number;
  properties: string;
}

const dummyData = {
  school_name: 'St.Xavier Secondary School',
  school_address: 'Maitighar, kathmandu, Nepal',
  school_phone: '+977-01-4412323',
  slogan: 'We aim for excellence',
  student_name: 'K.K Adhikari',
  student_photo:
    'https://www.pngitem.com/pimgs/m/334-3341344_mens-dress-png-men-icon-png-transparent-png.png',
  admission_no: '000045',
  father_name: 'M.B. Adhikari',
  address: 'Nepal',
  dob: '2048-03-12',
  blood_group: 'B+',
  phone_number: '1234567890',
};

export const StudentInfo = (props: any) => {
  const { value, properties } = props;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '2px 10px',
      }}
    >
      <Box fontSize={14} fontWeight="fontWeightRegular" textAlign="center">
        {properties}
      </Box>
      <Box fontSize={14} fontWeight="fontWeightRegular" textAlign="center">
        {value}
      </Box>
    </div>
  );
};

const AdmitCardSample2 = (props: any) => {
  const { data } = props;
  const dispatch = useDispatch();

  const [item, setItem] = useState<any>(null);

  const classes = useAdmitCardStyles();

  const general_info = useSelector(
    (state: RootStore) => state.auth?.general_info
  );

  const report_setting = useSelector(
    (state: RootStore) => state.report_setting?.reportsetting
  );

  useEffect(() => {
    props.content != null && setItem(props.content);
  }, [props.content]);

  const [url, setUrl] = useState<string>('');

  useEffect(() => {
    const n = window.location.href.lastIndexOf('/');
    setUrl(window.location.href.substring(n + 1));
    dispatch(GetReportSetting());
  }, []);

  const renderValue = (real: any, fake: any) => {
    if (url === 'customize' || url === 'update') {
      return fake;
    } else {
      return real;
    }
  };

  return (
    <>
      <Card
        className={classes.root}
        style={{
          backgroundColor: item?.back_ground_color[1].value,
          position: 'relative',
        }}
      >
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          className={clsx(classes.landContent)}
        >
          <Grid item xs={8}>
            <Grid container alignItems="center">
              <Grid style={{ padding: '0 10px' }} item xs={3}>
                <CardMedia
                  className={classes.logoImage}
                  image={general_info?.logo}
                  title=""
                />
              </Grid>
              <Grid item xs={9}>
                <div style={{ marginLeft: '0.35rem' }}>
                  <Box
                    fontSize={18}
                    fontWeight="fontWeightBold"
                    textAlign="center"
                    className={classes.mainTitle}
                    style={{ color: item?.text_color[0].value }}
                  >
                    {general_info?.school_name}
                  </Box>
                  <Box
                    lineHeight={2}
                    fontSize={12}
                    fontWeight="fontWeightRegular"
                    textAlign="center"
                  >
                    {general_info?.address}
                  </Box>
                </div>
              </Grid>
            </Grid>
            <div
              style={{
                color: item?.text_color[2].value,
              }}
            >
              <CardContent className={clsx(classes.content, classes.column)}>
                <div
                  className={clsx(classes.cardBg, classes.center)}
                  // style={{
                  //   backgroundImage:
                  //     "url(https://upload.wikimedia.org/wikipedia/commons/thumb/f/f8/Bavarian_Intl_School_Logo.png/1200px-Bavarian_Intl_School_Logo.png)",
                  // }}
                ></div>
                <StudentInfo
                  properties="Student Name:"
                  value={renderValue(
                    data?.student_name,
                    dummyData?.student_name
                  )}
                />
                <StudentInfo
                  properties="Admission No.:"
                  value={renderValue(
                    data?.admission_no,
                    dummyData?.admission_no
                  )}
                />
                <StudentInfo
                  properties="Father's Name:"
                  value={renderValue(data?.father_name, dummyData?.father_name)}
                />
                <StudentInfo
                  properties="Address:"
                  value={renderValue(data?.address, dummyData?.address)}
                />
                <StudentInfo
                  properties="Phone Number"
                  value={renderValue(
                    data?.phone_number,
                    dummyData?.phone_number
                  )}
                />
                <StudentInfo
                  properties="Signature"
                  value={
                    <img
                      src={checkActiveSignature(report_setting)}
                      alt=""
                      height="40px"
                      width="100px"
                    />
                  }
                />
              </CardContent>
            </div>
          </Grid>
          <Grid container justifyContent="center" item xs={4}>
            {/* <Box
              className={classes.title}
              fontSize={24}
              fontWeight="fontWeightBold"
              textAlign="center"
              mb={1}
              style={{
                backgroundColor: item?.back_ground_color[2].value,
                color: item?.text_color[3].value,
              }}
            >
              Teacher
            </Box> */}
            <Box
              className={classes.title}
              fontSize={13}
              fontWeight="fontWeightBold"
              textAlign="center"
              mb={1}
              style={{
                backgroundColor: item?.back_ground_color[2].value,
                color: item?.text_color[3].value,
              }}
            >
              <span style={{ padding: '4px' }}>
                {props.examType?.exam_name
                  ? props.examType.exam_name
                  : 'FIRST TERMINAL'}
                {props.examType?.start_date
                  ? dateConverterAdToBs(props.examType?.start_date).slice(0, 4)
                  : '2078'}
              </span>
            </Box>
            <CardMedia
              className={classes.landIdImage}
              image={renderValue(
                checkImageUrl(data?.student_photo),
                dummyData?.student_photo
              )}
              title=""
            />
          </Grid>
        </Grid>
        <div
          className={clsx(classes.bgColor, classes.footer)}
          style={{
            backgroundColor: item?.back_ground_color[0].value,
          }}
        >
          <Box
            fontSize={12}
            fontWeight="fontWeightRegular"
            textAlign="center"
            style={{
              color: item?.text_color[1].value,
            }}
          >
            {general_info?.slogan}
          </Box>
        </div>
      </Card>
    </>
  );
};

export default AdmitCardSample2;
