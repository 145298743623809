import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getReceiptSettings, postReceiptSettings } from "./receiptSettingsApi";

interface ReceiptSettingsI {
  prefix: string;
  start_from: number | null;
  footer_text: string;
}

const initialState: {
  loading: boolean;
  receiptSettings: ReceiptSettingsI | null;
} = {
  loading: false,
  receiptSettings: null,
};

const receiptSlice = createSlice({
  name: "receiptSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getReceiptSettings.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getReceiptSettings.fulfilled,
      (state, { payload }: PayloadAction<ReceiptSettingsI>) => {
        state.loading = false;
        state.receiptSettings = payload;
      }
    );
    builder.addCase(getReceiptSettings.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(postReceiptSettings.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      postReceiptSettings.fulfilled,
      (
        state,
        {
          payload,
        }: PayloadAction<{
          prefix: string;
          start_from: number;
          footer_text: string;
        }>
      ) => {
        state.loading = false;
        state.receiptSettings = { ...state.receiptSettings, ...payload };
      }
    );
    builder.addCase(postReceiptSettings.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default receiptSlice.reducer;
