import React, { useState } from "react";
import { useSidebar } from "../../Reusable";
import { useSidebarStyles } from "../../Styles/SidebarStyles";
import { WcOutlined } from "@material-ui/icons";
import SidebarTitle from "../../Reusable/Titles/SidebarTitle";

interface propsI {
  Designation?: boolean;
  Department?: boolean;
  Staff?: boolean;
  AddStaff?: boolean;
  LeaveType?: boolean;
  Leave?: boolean;
  ApplyLeaveType?: boolean;
  IdCard?: boolean;
  staffAttendance?: boolean;
  staffAttendanceReport?: boolean;
  disabledStaff?: boolean;
  attendance?: boolean;
  attendanceReport?: boolean;
}

const HRsidebar = (props: propsI) => {
  const classes = useSidebarStyles();

  const [designation] = useState(props.Designation);
  const [department] = useState(props.Department);
  const [staff] = useState(props.Staff);
  const [addStaff] = useState(props.AddStaff);
  const [leaveType] = useState(props.LeaveType);
  const [leave] = useState(props.Leave);
  const [applyLeaveType] = useState(props.ApplyLeaveType);
  const [idCard] = useState(props.IdCard);
  const [staffAttendance] = useState(props.staffAttendance);
  const [staffAttendanceReport] = useState(props.staffAttendanceReport);
  const [disabledStaff] = useState(props.disabledStaff);
  const [attendance] = useState(props.attendance);
  const [attendanceReport] = useState(props.attendanceReport);

  const dataSet = [
    { text: "Designation", url: "designation", activeStatus: designation },
    { text: "Department", url: "department", activeStatus: department },
    // { text: "Add Staff", url: "add-staff", activeStatus: addStaff },
    { text: "Staff List", url: "staff", activeStatus: staff },
    {
      text: "Disabled Staffs",
      url: "disabled-staffs",
      activeStatus: disabledStaff,
    },
    // {
    //   text: "Take Attendance",
    //   url: "staff-attendance",
    //   activeStatus: staffAttendance,
    // },
    // {
    //   text: "Attendance Report",
    //   url: "staff-attendance-report",
    //   activeStatus: staffAttendanceReport,
    // },
    { text: "Leave Type", url: "leave-type", activeStatus: leaveType },
    { text: "Leave", url: "leave", activeStatus: leave },
    {
      text: "Apply Leave",
      url: "apply-leave-type",
      activeStatus: applyLeaveType,
    },
    {
      text: "Attendance Today",
      url: "attendance-today",
      activeStatus: attendance,
    },
    {
      text: "Attendance Report",
      url: "attendance-report",
      activeStatus: attendanceReport,
    },
    // { text: 'Staff ID Card', url: 'staff-id-card', activeStatus: idCard },
  ];
  const { CustomSidebar } = useSidebar(dataSet, "FINANCE");
  return (
    <div className={classes.Sidebar}>
      <div className={classes.sidebarContent}>
        <SidebarTitle icon={<WcOutlined />}>HR</SidebarTitle>
        <CustomSidebar />
      </div>
    </div>
  );
};

export default HRsidebar;
