export const GET_LOCATION_LOADING = 'GET_LOCATION_LOADING';
export const GET_LOCATION_ERROR = 'GET_LOCATION_ERROR';
export const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS';

export const ADD_LOCATION_LOADING = 'ADD_LOCATION_LOADING';
export const ADD_LOCATION_ERROR = 'ADD_LOCATION_ERROR';
export const ADD_LOCATION_SUCCESS = 'ADD_LOCATION_SUCCESS';

export const UPDATE_LOCATION_LOADING = 'UPDATE_LOCATION_LOADING';
export const UPDATE_LOCATION_ERROR = 'UPDATE_LOCATION_ERROR';
export const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS';

export const DELETE_LOCATION_LOADING = 'DELETE_LOCATION_LOADING';
export const DELETE_LOCATION_ERROR = 'DELETE_LOCATION_ERROR';
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS';

export type LocationTypeI = {
  id: string;
  name: string;
  one_way_cost: string;
  two_way_cost: string;
  pick_up_time: string;
  drop_time: string;
  longitude: string;
  latitude: string;
};

export interface LocationFormDataTypeI {
  name: string;
  one_way_cost: string;
  two_way_cost: string;
  pick_up_time: string;
  drop_time: string;
  longitude: string;
  latitude: string;
}

//===============================<START>GET Location<START>======================//

export interface GetLocationLoading {
  type: typeof GET_LOCATION_LOADING;
}

export interface GetLocationError {
  type: typeof GET_LOCATION_ERROR;
}

export interface GetLocationSuccess {
  type: typeof GET_LOCATION_SUCCESS;
  payload: LocationTypeI[];
}

//===============================<END>GET Location<END>======================//

//===============================<START>ADD Location<START>======================//

export interface AddLocationLoading {
  type: typeof ADD_LOCATION_LOADING;
}

export interface AddLocationError {
  type: typeof ADD_LOCATION_ERROR;
  payload: any;
}

export interface AddLocationSuccess {
  type: typeof ADD_LOCATION_SUCCESS;
  payload: LocationTypeI;
}

//===============================<END>ADD Location<END>======================//

//===============================<START>UPDATE Location<START>======================//

export interface UpdateLocationLoading {
  type: typeof UPDATE_LOCATION_LOADING;
}

export interface UpdateLocationError {
  type: typeof UPDATE_LOCATION_ERROR;
  payload: any;
}

export interface UpdateLocationSuccess {
  type: typeof UPDATE_LOCATION_SUCCESS;
  payload: LocationTypeI;
}

//===============================<END>UPDATE Location<END>======================//

//===============================<START>DELETE Location<START>======================//

export interface DeleteLocationLoading {
  type: typeof DELETE_LOCATION_LOADING;
}

export interface DeleteLocationError {
  type: typeof DELETE_LOCATION_ERROR;
}

export interface DeleteLocationSuccess {
  type: typeof DELETE_LOCATION_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE Location<END>======================//

export type LocationDispatchTypes =
  | GetLocationLoading
  | GetLocationError
  | GetLocationSuccess
  | AddLocationLoading
  | AddLocationError
  | AddLocationSuccess
  | UpdateLocationLoading
  | UpdateLocationError
  | UpdateLocationSuccess
  | DeleteLocationLoading
  | DeleteLocationError
  | DeleteLocationSuccess;
