import React from "react";
import { ReportSideBar } from "../../../../components";
import MyIdCards from "../../../../components/Reports/Templates/IdCard/MyIdCards";
import SubModuleLayout from "../../../../components/Reusable/Layouts/SubModuleLayout";

const IdCardTemplatePage = () => {
  const nav = <ReportSideBar id_card_template />;
  return (
    <SubModuleLayout reportMenuActive sideNav={nav}>
      <MyIdCards />
    </SubModuleLayout>
  );
};

export default IdCardTemplatePage;
