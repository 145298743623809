import React, { useState } from "react";
import { ReportSideBar, MarksheetForm, MarksheetTable } from "../../components";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

export interface SearchTitleI {
  exam_info: number;
  class: string;
  section: string;
  grade: number;
  section_id: number | string;
}

export interface SearchI {
  class: string;
  section: string;
}

const MarksheetPage = () => {
  const [searchData, setSearchData] = useState<SearchI | null>(null);
  const nav = <ReportSideBar marksheet />;

  const searchMarksheet = (data: SearchI) => {
    setSearchData(data);
  };
  return (
    <SubModuleLayout reportMenuActive sideNav={nav}>
      <MarksheetForm onSearch={searchMarksheet} />
      <MarksheetTable searchTitle={searchData} />
    </SubModuleLayout>
  );
};

export default MarksheetPage;
