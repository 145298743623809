import {
  GET_DAY_BOOK_ERROR,
  GET_DAY_BOOK_LOADING,
  GET_DAY_BOOK_SUCCESS,
  GET_INVOICE_DETAIL_ERROR,
  GET_INVOICE_DETAIL_LOADING,
  GET_INVOICE_DETAIL_SUCCESS,
  CLEAR_INVOICE,
  DaybookDispatchTypes,
} from "../../actions/Fees/Daybook/DaybookActionTypes";

interface InitialStateI {
  loading: boolean;
  day_books: any;
  invoice_detail: any
}

const initialState: InitialStateI = {
  loading: false,
  day_books: [],
  invoice_detail: null
};

const DaybookReducer = (
  state: InitialStateI = initialState,
  action: DaybookDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case CLEAR_INVOICE:
      return {
        loading: false,
        day_books: [],
        invoice_detail: null
      }
    case GET_DAY_BOOK_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_DAY_BOOK_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_DAY_BOOK_SUCCESS:
      return {
        ...state,
        loading: false,
        day_books: action.payload,
      };

    case GET_INVOICE_DETAIL_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_INVOICE_DETAIL_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_INVOICE_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        invoice_detail: action.payload,
      };

    default:
      return state;
  }
};

export default DaybookReducer;