export const GET_PAYMENTMETHOD_LOADING = "GET_PAYMENTMETHOD_LOADING";
export const GET_PAYMENTMETHOD_ERROR = "GET_PAYMENTMETHOD_ERROR";
export const GET_PAYMENTMETHOD_SUCCESS = "GET_PAYMENTMETHOD_SUCCESS";

export const ADD_PAYMENTMETHOD_LOADING = "ADD_PAYMENTMETHOD_LOADING";
export const ADD_PAYMENTMETHOD_ERROR = "ADD_PAYMENTMETHOD_ERROR";
export const ADD_PAYMENTMETHOD_SUCCESS = "ADD_PAYMENTMETHOD_SUCCESS";

export const UPDATE_PAYMENTMETHOD_LOADING = "UPDATE_PAYMENTMETHOD_LOADING";
export const UPDATE_PAYMENTMETHOD_ERROR = "UPDATE_PAYMENTMETHOD_ERROR";
export const UPDATE_PAYMENTMETHOD_SUCCESS = "UPDATE_PAYMENTMETHOD_SUCCESS";

export const DELETE_PAYMENTMETHOD_LOADING = "DELETE_PAYMENTMETHOD_LOADING";
export const DELETE_PAYMENTMETHOD_ERROR = "DELETE_PAYMENTMETHOD_ERROR";
export const DELETE_PAYMENTMETHOD_SUCCESS = "DELETE_PAYMENTMETHOD_SUCCESS";

export type PaymentMethodTypeI = {
  id: string;
  name: string;
  count: number;
};

//===============================<START>GET FINE<START>======================//

export interface GetPaymentMethodLoading {
  type: typeof GET_PAYMENTMETHOD_LOADING;
}

export interface GetPaymentMethodError {
  type: typeof GET_PAYMENTMETHOD_ERROR;
}

export interface GetPaymentMethodSuccess {
  type: typeof GET_PAYMENTMETHOD_SUCCESS;
  payload: PaymentMethodTypeI[];
}

//===============================<END>GET FINE<END>======================//

//===============================<START>ADD FINE<START>======================//

export interface AddPaymentMethodLoading {
  type: typeof ADD_PAYMENTMETHOD_LOADING;
}

export interface AddPaymentMethodError {
  type: typeof ADD_PAYMENTMETHOD_ERROR;
  payload: any;
}

export interface AddPaymentMethodSuccess {
  type: typeof ADD_PAYMENTMETHOD_SUCCESS;
  payload: PaymentMethodTypeI;
}

//===============================<END>ADD FINE<END>======================//

//===============================<START>UPDATE FINE<START>======================//

export interface UpdatePaymentMethodLoading {
  type: typeof UPDATE_PAYMENTMETHOD_LOADING;
}

export interface UpdatePaymentMethodError {
  type: typeof UPDATE_PAYMENTMETHOD_ERROR;
  payload: any;
}

export interface UpdatePaymentMethodSuccess {
  type: typeof UPDATE_PAYMENTMETHOD_SUCCESS;
  payload: PaymentMethodTypeI;
}

//===============================<END>UPDATE FINE<END>======================//

//===============================<START>DELETE FINE<START>======================//

export interface DeletePaymentMethodLoading {
  type: typeof DELETE_PAYMENTMETHOD_LOADING;
}

export interface DeletePaymentMethodError {
  type: typeof DELETE_PAYMENTMETHOD_ERROR;
}

export interface DeletePaymentMethodSuccess {
  type: typeof DELETE_PAYMENTMETHOD_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE FINE<END>======================//

export type PaymentMethodDispatchTypes =
  | GetPaymentMethodLoading
  | GetPaymentMethodError
  | GetPaymentMethodSuccess
  | AddPaymentMethodLoading
  | AddPaymentMethodError
  | AddPaymentMethodSuccess
  | UpdatePaymentMethodLoading
  | UpdatePaymentMethodError
  | UpdatePaymentMethodSuccess
  | DeletePaymentMethodLoading
  | DeletePaymentMethodError
  | DeletePaymentMethodSuccess;
