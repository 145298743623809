import React from "react";
import { useSelector } from "react-redux";
import {
  ExamSubjectI,
  MarkSheetI,
  MarksLedgerI,
} from "../../../rtk/features/exam/marksLedger/marksLedger";
import { RootState } from "../../../rtk/store";
import { RootStore } from "../../../store";
import {
  Sheet,
  SheetWrapper,
  Header,
  StudentInfo,
  Main,
  Footer,
  LogoContainer,
  SchoolInfoDiv,
  KeyValue,
  TableFooter,
  BorderedTableData,
  EmptyRow,
  FooterTop,
  FooterBottom,
  FooterDate,
  DaysSection,
  SchoolSeal,
  ContactInfo,
  SloganText,
  SchoolNameText,
  SchoolAddressText,
  ExamNameText,
  GradeSheetText,
  ClassNameText,
} from "./GradeSheetPreranaStyles";
import { dateConverterAdToBs } from "../../utils/dateConverter";
import { GradeTypeI } from "../../../actions/Examination/Grade/GradeActionTypes";

interface GeneralInfoI {
  id: number;
  logo: string;
  school_name: string;
  address: string;
  phone_number: string;
  email: string;
  slogan: string;
}

interface PropsI {
  schoolInfo: {
    name: string;
    address: string;
    phone: string;
    logo: string;
  };
  gradeInfo: {
    grade: string;
    totalStudents: number;
  } | null;
  subjects: ExamSubjectI[];
  marksheet: MarksLedgerI;
  grades: GradeTypeI[];
  examName: string;
}

const MarkSheetSingle = (props: PropsI) => {
  const getMarksBySubject = (subjectMarksInfo: MarkSheetI) => {
    // const subjectMarksInfo = props.marksheet.obtain_subject_marks.find(
    //   (el) => el.subject_id === subjectId
    // );
    const subjectInfo = props.subjects.find(
      (el) => el.id === subjectMarksInfo.subject_id
    );

    if (subjectInfo) {
      console.log(subjectMarksInfo);
      return {
        subjectName: subjectInfo.subject__name,
        creditHourTheory: subjectMarksInfo.credit_hour_theory,
        creditHourPractical: subjectMarksInfo.credit_hour_practical,
        theory: subjectMarksInfo.obtain_marks_theory || "NG",
        practical: subjectMarksInfo.obtain_marks_practical || "-",
        totalGrade: subjectMarksInfo.total_obtain_marks || "NG",
        gpa: subjectMarksInfo.average_grade_name || "0",
        remarks: subjectMarksInfo.remarks || "",
      };
    }

    // if (subjectMarksInfo) {
    //   return {
    //     theory: subjectMarksInfo.obtain_grade_theory || "NG",
    //     practical: subjectMarksInfo.obtain_grade_practical || "NG",
    //     totalGrade: subjectMarksInfo.average_grade_name || "NG",
    //     gpa: subjectMarksInfo.average_grade_point || "0",
    //     remarks: subjectMarksInfo.remarks || "",
    //   };
    // }

    return {
      subjectName: "-",
      creditHourTheory: "-",
      creditHourPractical: "-",
      theory: "-",
      practical: "-",
      totalGrade: "-",
      gpa: "-",
      remarks: "",
    };
  };

  return (
    <Sheet>
      <SheetWrapper>
        <Header>
          <LogoContainer>
            <img src={props.schoolInfo.logo || ""} alt="logo" />
          </LogoContainer>
          <SchoolInfoDiv>
            <SloganText>Slogan Goes Here</SloganText>
            <SchoolNameText>{props.schoolInfo.name}</SchoolNameText>
            <SchoolAddressText>{props.schoolInfo.address}</SchoolAddressText>
            <ExamNameText>{props.examName}</ExamNameText>
            <GradeSheetText>Mark Sheet</GradeSheetText>
            <ClassNameText>Class - {props.gradeInfo?.grade}</ClassNameText>
          </SchoolInfoDiv>
          <ContactInfo></ContactInfo>
        </Header>
        <StudentInfo>
          <div>
            <KeyValue>
              <span>Name of Student:</span>
              <span>
                {props.marksheet.student_first_name}{" "}
                {props.marksheet.student_last_name}
              </span>
            </KeyValue>
            <KeyValue>
              <span>Guardian Name:</span>
              <span>
                {props.marksheet.student_parent_first_name}{" "}
                {props.marksheet.student_parent_last_name}
              </span>
            </KeyValue>
          </div>
          <div>
            <KeyValue>
              <span>Roll Number:</span>
              <span>{props.marksheet.student_roll_number}</span>
            </KeyValue>
            <KeyValue>
              <span>Address:</span>
              <span>{props.marksheet.student_address}</span>
            </KeyValue>
          </div>
        </StudentInfo>
        <Main>
          <table>
            <thead>
              <tr>
                <th rowSpan={2}>S.N</th>
                <th rowSpan={2}>SUBJECTS</th>
                <th colSpan={2}>
                  CREDIT <br /> HOUR
                </th>
                <th colSpan={2}>Marks Obtained</th>
                <th rowSpan={2}>
                  Total <br /> Marks Obtained
                </th>
                <th rowSpan={2}>
                  GRADE <br /> POINT
                </th>
              </tr>
              <tr>
                <th>TH</th>
                <th>PR</th>
                <th>THEORY</th>
                <th>PRACTICAL</th>
              </tr>
            </thead>
            <tbody>
              {props.marksheet.obtain_subject_marks.map((el, index) => (
                <tr key={index + 1}>
                  <td align="center">{index + 1}</td>
                  <td align="left">{getMarksBySubject(el).subjectName}</td>
                  <td align="center">
                    {getMarksBySubject(el).creditHourTheory}
                  </td>
                  <td align="center">
                    {getMarksBySubject(el).creditHourPractical}
                  </td>
                  <td align="center">{getMarksBySubject(el).theory}</td>
                  <td align="center">{getMarksBySubject(el).practical}</td>
                  <td align="center">{getMarksBySubject(el).totalGrade}</td>
                  <td align="center">{getMarksBySubject(el).gpa}</td>
                </tr>
              ))}
              {/* <EmptyRow>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </EmptyRow> */}
              <tr>
                <BorderedTableData align="left" colSpan={7}>
                  Grade Point Average
                </BorderedTableData>
                {/* <BorderedTableData align="center">
                  {props.marksheet.obtain_grade || "NG"}
                </BorderedTableData> */}
                <BorderedTableData align="center">
                  {props.marksheet.obtain_gpa || "0"}
                </BorderedTableData>
                {/* <BorderedTableData align="center"></BorderedTableData> */}
              </tr>
            </tbody>
          </table>
        </Main>
        <Footer>
          <FooterTop>
            <div>
              <h3>Details of Grade Sheet</h3>
              <table>
                <thead>
                  <tr>
                    <th align="left">S.N</th>
                    <th align="left">Percentage Interval</th>
                    <th align="left">Grade</th>
                    <th align="left">Desription</th>
                    <th align="left">Grade Point</th>
                  </tr>
                </thead>
                <tbody>
                  {props.grades.map((el, index) => (
                    <tr key={el.id}>
                      <td align="left">{index + 1}</td>
                      <td align="left">
                        {el.marks_from} To {el.marks_to}
                      </td>
                      <td align="left">{el.grade_name}</td>
                      <td align="left">{el.description!}</td>
                      <td align="left">{el.grade_point}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </FooterTop>
          <FooterBottom>
            <span>Class Teacher</span>
            <FooterDate>
              <span>2080/01/01</span>
              <span>Date</span>
            </FooterDate>
            <span>Principal</span>
          </FooterBottom>
        </Footer>
      </SheetWrapper>
    </Sheet>
  );
};

export default MarkSheetSingle;
