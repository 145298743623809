import React from "react";
import { ReportSideBar } from "../../../../components";
import CustomizeMarksheet from "../../../../components/Reports/Templates/Marksheet/CustomizeMarksheet";
import SubModuleLayout from "../../../../components/Reusable/Layouts/SubModuleLayout";

const CustomizeMarksheetPage = () => {
  const nav = <ReportSideBar marksheet_template />;
  return (
    <SubModuleLayout reportMenuActive sideNav={nav}>
      <CustomizeMarksheet />
    </SubModuleLayout>
  );
};

export default CustomizeMarksheetPage;
