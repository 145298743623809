import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  StateI,
  SubjectTeacher,
  SubjectTeacherPayload,
} from "./subjectTeacher";
import {
  getSubjectTeachers,
  updateSubjectTeachers,
} from "./subjectTeacherActions";

const initialState: StateI = {
  loading: false,
  subjectTeachers: [],
  completed: false,
};

const SubjectTeacherSlice = createSlice({
  name: "subjectTeacher",
  initialState,
  reducers: {
    clearFields: (state) => {
      state.loading = false;
      state.subjectTeachers = [];
    },
    changeTeachers: (
      state,
      {
        payload,
      }: PayloadAction<{
        subjectId: string;
        teachers: any;
      }>
    ) => {
      const targetSubject = state.subjectTeachers.find(
        (el) => el.subject.id === payload.subjectId
      );

      if (targetSubject) {
        targetSubject.teachers = payload.teachers;
        targetSubject.edited = true;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSubjectTeachers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getSubjectTeachers.fulfilled,
      (
        state,
        {
          payload,
        }: PayloadAction<{ data: { subject: SubjectTeacherPayload[] } }>
      ) => {
        state.loading = false;
        state.subjectTeachers = payload.data.subject.map((el) => ({
          subject: { id: el.id, name: el.name },
          teachers: el.teacher.map((val) => ({
            value: val.id,
            label: val.first_name + " " + val.last_name,
          })),
          edited: false,
        }));
      }
    );
    builder.addCase(getSubjectTeachers.rejected, (state) => {
      state.loading = false;
      state.subjectTeachers = [];
    });

    builder.addCase(updateSubjectTeachers.pending, (state) => {
      state.loading = true;
      state.completed = false;
    });
    builder.addCase(
      updateSubjectTeachers.fulfilled,
      (state, { payload }: PayloadAction<{ editedSubject: string }>) => {
        state.loading = false;
        state.completed = true;

        const targetSubject = state.subjectTeachers.find(
          (el) => el.subject.id === payload.editedSubject
        );

        if (targetSubject) targetSubject.edited = false;
      }
    );
    builder.addCase(updateSubjectTeachers.rejected, (state) => {
      state.loading = false;
      state.completed = false;
    });
  },
});

export const { clearFields, changeTeachers } = SubjectTeacherSlice.actions;

export default SubjectTeacherSlice.reducer;
