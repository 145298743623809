import axios from "axios";
import { Dispatch } from "redux";
import { ADMIN_API_URL } from "../../../../config/SERVER_API_URLS";
import { HeaderConfig } from "../../../Auth/Login/LoginAction";

import {
  GET_RESULT_LEDGER_LOADING,
  GET_RESULT_LEDGER_ERROR,
  GET_RESULT_LEDGER_SUCCESS,
  ResultLedgerDispatchTypes,
} from "./ResultLedgerActionTypes";

export const GetResultLedger =
  (exam: string, grade: string) =>
  async (dispatch: Dispatch<ResultLedgerDispatchTypes>) => {
    try {
      dispatch({
        type: GET_RESULT_LEDGER_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/student_marks_ledger?grade=${grade}&exam_info=${exam}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_RESULT_LEDGER_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_RESULT_LEDGER_ERROR,
      });
    }
  };
