import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_GRADE_FEE_LOADING,
  GET_GRADE_FEE_ERROR,
  GET_GRADE_FEE_SUCCESS,
  GET_STUDENT_FEE_LOADING,
  GET_STUDENT_FEE_ERROR,
  GET_STUDENT_FEE_SUCCESS,
  FeeDispatchTypes,
  POST_GRADE_FEE_LOADING,
  POST_GRADE_FEE_SUCCESS,
  POST_GRADE_FEE_ERROR,
  GradeFeePostRequestI,
  APPLY_FEES_TO_STUDENTS_SUCCESS,
  APPLY_FEES_TO_STUDENTS_LOADING,
  APPLY_FEES_TO_STUDENTS_ERROR,
} from "./FeeActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

export interface postFeeTypeI {
  id: string;
  months: Array<string>;
}
export interface ApplyFeeRequestI {
  students: string[];
  fee_type: postFeeTypeI[];
}

export const GetGradeFee =
  () => async (dispatch: Dispatch<FeeDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_GRADE_FEE_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/fee_detail/`,
        HeaderConfig()
      );

      dispatch({
        type: GET_GRADE_FEE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_GRADE_FEE_ERROR,
      });
    }
  };

export const GetStudentFee =
  (id: number) =>
  async (dispatch: Dispatch<FeeDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_STUDENT_FEE_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/student_info/get_student_bill/?student=${id}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_GRADE_FEE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_STUDENT_FEE_ERROR,
      });
    }
  };

export const PostGradeFee =
  (data: GradeFeePostRequestI[]) =>
  async (dispatch: Dispatch<FeeDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: POST_GRADE_FEE_LOADING,
      });

      await axios.post(`${ADMIN_API_URL}/fee_detail/`, data, HeaderConfig());

      dispatch({
        type: POST_GRADE_FEE_SUCCESS,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/fee_detail/`,
        HeaderConfig()
      );

      dispatch({
        type: GET_GRADE_FEE_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Grade Fees Updated Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: POST_GRADE_FEE_ERROR,
        payload: error.response && error.response?.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "warning",
          snackbarMessage: "Grade Fees Update Failed",
        },
      });
    }
  };

export const ApplyFeeToStudents =
  (data: ApplyFeeRequestI, func: any) =>
  async (dispatch: Dispatch<FeeDispatchTypes | SetSnackBarI>) => {
    dispatch({
      type: APPLY_FEES_TO_STUDENTS_LOADING,
    });
    try {
      await axios.post(
        `${ADMIN_API_URL}/invoice_generate/?grade=74fd6568-b190-4a37-86ff-23612dd05497`,
        data,
        HeaderConfig()
      );
      dispatch({
        type: APPLY_FEES_TO_STUDENTS_SUCCESS,
      });
      func();
      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Fees Applied Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: APPLY_FEES_TO_STUDENTS_ERROR,
      });
      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "warning",
          snackbarMessage: "Failed to Apply Fee",
        },
      });
    }
  };
