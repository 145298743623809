import React from "react";
import SubModuleLayout from "../../../Reusable/Layouts/SubModuleLayout";
import StudentTable from "../../StudentDetail/StudentTable";
import StudentSidebar from "../../StudentSidebar/StudentSidebar";

const StudentListPage = () => {
  const nav = <StudentSidebar studentCategories />;
  return (
    <SubModuleLayout sideNav={nav}>
      <StudentTable />
    </SubModuleLayout>
  );
};

export default StudentListPage;
