//---------------------<START> import modules starts-----------------------//
import {
  Button,
  Checkbox,
  createStyles,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableHead,
  TextField,
  Theme,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import React, { useState, useEffect } from "react";
import { useTable } from "../../../../Reusable";
import { Autocomplete } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { SubjectTypeI } from "../../../../../actions/Academics/Subject/SubjectActionTypes";
import { RootStore } from "../../../../../store";

import { setSnackbar } from "../../../../../actions/SnackbarAction";
import { GetAllTeachers } from "../../../../../actions/HumanResource/Staff/StaffAction";
import { GetSubjectGroupsByClass } from "../../../../../actions/Academics/Subject/SubjectGroupAction";
import SelectDaysDialog from "./SelectDaysDialog";
import { DaysI } from "./SelectDaysDialog";
import { Cancel, Edit } from "@material-ui/icons";
import {
  PeriodsI,
  resetPeriodStateAction,
} from "../../../../../rtk/features/timetable/periodSlices";
import { updateTimetableByDayAction } from "../../../../../rtk/features/timetable/timetableSlices";
import { getTimePeriodByGrade } from "../../../../../rtk/features/timetable/periodApi";
import BackDropLoader from "../../../../Reusable/BackDropLoader";
import {
  copyTimeTable,
  getTimeTableByDay,
  updateTimeTable,
  getTimeTableByClass,
} from "../../../../../rtk/features/timetable/timetableApi";
import {
  ItemAddButton,
  ItemDeleteButton,
} from "../../../../Reusable/Buttons/TableButton";
import { getSubjectTeachers } from "../../../../../rtk/features/subjectTeachers/subjectTeacherActions";
import moment from "moment";
//---------------------<END> import modules ends -----------------------//

//= ====================== <START> Styles <START> ===================================//

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containedButton: {
      width: "90%",
      margin: theme.spacing(2, 0),
    },
    deleteIcon: {
      color: "#E96C5A",
    },
    tableSection: {
      "& .MuiOutlinedInput-input": {
        padding: theme.spacing(1.5, 2),
      },
      "& .MuiAutocomplete-inputRoot": {
        padding: "2px",
      },
      "& .MuiFormControl-fullWidth": {
        width: "256px",
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  })
);

//======================= <END> Styles <END> ===================================//
//-------------------<START> interface starts-----------------------------------------------//

interface PropsI {
  classChoice: {
    gradeId: string | null;
    section: string | null;
    classId: string | null;
    hasSection: boolean;
  };
  tab_day: number;
}

interface HeadCellsI {
  id: string;
  label: string;
}

// -----------------<END> interface ends --------------------------------------//

// ---------------------<START> Table Heading Data starts ----------------------------//
const headCells: HeadCellsI[] = [
  { id: "period", label: "Period" },
  { id: "subject", label: "Subject" },
  { id: "teacher", label: "Teacher" },
  { id: "start_time", label: "Start Time" },
  { id: "end_time", label: "End Time" },
  { id: "action", label: "Action" },
];
//----------------------<END> Table Heading Data ends ---------------------------------//
//------------------------<START> Sunday Component starts ------------------------------//
const Sunday = (props: PropsI) => {
  const { classChoice, tab_day } = props;
  const classes = useStyles();
  //Retrieving re-usable components from useTable
  const {
    TblContainer,
    TblHead,
    StyledTableCell,
    StyledTableRow,
    TableContainer,
  } = useTable(headCells);

  //======================= <START> Component States <START> ===================================//

  const [daysDialog, setDaysDialog] = useState<boolean>(false);

  const { subjectGroupByClass } = useSelector(
    (state: RootStore) => state.subject_group
  );
  const { all_teachers } = useSelector((state: RootStore) => state.staff);

  const teacherLoading = useSelector((state: RootStore) => state.staff.loading);
  const subjectLoading = useSelector(
    (state: RootStore) => state.subject.loading
  );
  const { subjectTeachers } = useSelector(
    (state: RootStore) => state.subjectTeacher
  );

  //======================= <END> Component States <END> ===================================//
  //======================= <START> REDUX HOOKS <START> ===================================//
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetAllTeachers(""));
  }, []);

  useEffect(() => {
    if (classChoice.gradeId) {
      dispatch(
        GetSubjectGroupsByClass(classChoice.gradeId, classChoice.section)
      );
    }
  }, [classChoice]);

  // ======= Component States =========== //
  const [tableEdit, setTableEdit] = useState<boolean>(false);
  const [checkedRows, setCheckedRows] = useState<string[]>([]);
  const [allChecked, setAllChecked] = useState<boolean>(false);

  const { periodsByClass } = useSelector((state: RootStore) => state.period);
  const { timeTableByDayInit, timeTableByDay, actionPerformed, loading } =
    useSelector((state: RootStore) => state.timetable);
  /////////////////////////////////////////

  // ============== Hooks =============== //
  useEffect(() => {
    if (actionPerformed) {
      setTableEdit(false);
      setCheckedRows([]);
      setDaysDialog(false);
      if (classChoice.gradeId) {
        dispatch(
          getTimeTableByDay({
            grade_id: classChoice.gradeId,
            section_id: classChoice.section,
            day: tab_day,
          })
        );
        dispatch(
          getTimeTableByClass({
            grade_id: classChoice.gradeId,
            section_id: classChoice.section,
          })
        );
      }
    }
  }, [actionPerformed]);

  useEffect(() => {
    if (classChoice.gradeId) {
      if (classChoice.hasSection) {
        if (classChoice.section) {
          dispatch(
            getSubjectTeachers({
              grade: classChoice.gradeId,
              section: classChoice.section,
            })
          );
        }
      } else {
        dispatch(
          getSubjectTeachers({ grade: classChoice.gradeId, section: "" })
        );
      }
    }
  }, [classChoice]);

  useEffect(() => {
    if (classChoice.gradeId) {
      if (classChoice.hasSection) {
        if (classChoice.section) {
          dispatch(getTimePeriodByGrade(classChoice.gradeId));
          dispatch(
            getTimeTableByDay({
              grade_id: classChoice.gradeId,
              section_id: classChoice.section,
              day: tab_day,
            })
          );
          dispatch(
            getTimeTableByClass({
              grade_id: classChoice.gradeId,
              section_id: classChoice.section,
            })
          );
        } else {
          dispatch(resetPeriodStateAction());
        }
      } else {
        dispatch(getTimePeriodByGrade(classChoice.gradeId));
        dispatch(
          getTimeTableByDay({
            grade_id: classChoice.gradeId,
            section_id: classChoice.section,
            day: tab_day,
          })
        );
        dispatch(
          getTimeTableByClass({
            grade_id: classChoice.gradeId,
            section_id: classChoice.section,
          })
        );
      }
    } else {
      dispatch(resetPeriodStateAction());
    }

    setTableEdit(false);
    setCheckedRows([]);
  }, [classChoice, tab_day]);

  useEffect(() => {
    if (periodsByClass) {
      periodsByClass.periods.length === checkedRows.length
        ? setAllChecked(true)
        : setAllChecked(false);
    } else {
      setAllChecked(false);
    }
  }, [checkedRows, periodsByClass]);
  //////////////////////////////////////////

  const getSubjectTeachersByPeriod = (
    period: PeriodsI
  ): {
    id: string;
    class_type: number | null;
    subject: { id: string; name: string } | null;
    teacher: { id: string; first_name: string; last_name: string } | null;
  }[] => {
    const timeTablePeriod = timeTableByDay?.timetable.find(
      (el) => el.period.id === period.id
    );

    if (timeTablePeriod) {
      return timeTablePeriod.subject_teacher;
    } else {
      dispatch(
        updateTimetableByDayAction({
          period: period,
          subjectTeacherId: null,
          field: "subject",
          class_type_value: null,
          subject_value: null,
          teacher_value: null,
        })
      );
    }
    return [];
  };

  const getTeacherOptions = (
    subjectId: string
  ): { id: string; first_name: string; last_name: string }[] => {
    const targetSubject = subjectTeachers.find(
      (res) => res.subject.id === subjectId
    );

    if (targetSubject) {
      return targetSubject.teachers.map(
        (el: { value: string; label: string }) => ({
          id: el.value,
          first_name: el.label.split(" ")[0],
          last_name: el.label.split(" ")[1],
        })
      );
    }
    return [];
  };

  const deleteAllowed = (periodId: string): boolean => {
    const timeTablePeriod = timeTableByDay?.timetable.find(
      (el) => el.period.id === periodId
    );

    if (timeTablePeriod) {
      if (timeTablePeriod.subject_teacher.length > 1) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  };

  // ======= Event Handler ============== //

  const handleRowCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setCheckedRows([...checkedRows, event.target.value]);
    } else {
      const newCheckedRows = checkedRows.filter(
        (item) => item !== event.target.value
      );
      setCheckedRows([...newCheckedRows]);
    }
  };

  const handleRowCheckAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const allRows = periodsByClass?.periods.map((item) => item.id);
      allRows && setCheckedRows([...allRows]);
    } else {
      setCheckedRows([]);
    }
  };

  const handleChangeInputs = (
    period: PeriodsI,
    subject_teacher_id: string | null,
    field: "teacher" | "subject" | "classType",
    class_type_value: number | null,
    subject_value: { id: string; name: string } | null,
    teacher_value: { id: string; first_name: string; last_name: string } | null
  ) => {
    if (classChoice.gradeId) {
      dispatch(
        updateTimetableByDayAction({
          period: period,
          subjectTeacherId: subject_teacher_id,
          field,
          class_type_value,
          subject_value,
          teacher_value,
        })
      );
    }
  };

  const handleDiscardChanges = () => {
    setTableEdit(false);

    if (classChoice.gradeId) {
      dispatch(
        getTimeTableByDay({
          grade_id: classChoice.gradeId,
          section_id: classChoice.section,
          day: tab_day,
        })
      );
    }
  };

  const handleSaveChanges = () => {
    setTableEdit(true);
    if (tableEdit) {
      let valid = true;
      const counterData = timeTableByDay?.timetable.filter(
        (el) => !el.period.name.startsWith("Break")
      );
      const data = timeTableByDay?.timetable;

      counterData?.forEach((el) => {
        const emptySubjectTeacher = el.subject_teacher.find(
          (res) => res.subject !== null && res.teacher === null
        );

        if (emptySubjectTeacher) {
          valid = false;
        }
      });

      if (!valid) {
        dispatch(
          setSnackbar(
            true,
            "warning",
            "Cannot update with no teacher data for assigned subjects."
          )
        );
      } else {
        const postTimetable: any = [];
        data?.forEach((el) => {
          if (el.period.name.startsWith("Break")) {
            postTimetable.push({
              period: el.period.id,
              subject: [],
            });
          } else {
            postTimetable.push({
              period: el.period.id,
              subject: el.subject_teacher.map((st) => ({
                class_type: st.class_type,
                subject: st.subject?.id || null,
                teacher: st.teacher?.id || null,
              })),
            });
          }
        });
        const post_data = {
          grade: classChoice.gradeId,
          section: classChoice.section,
          day: tab_day,
          timetable: postTimetable,
        };

        dispatch(updateTimeTable(post_data));
      }
    }
  };

  const applyToDays = (val: DaysI[]) => {
    const daysToBeApplied = val.map((item) => item.id);
    if (daysToBeApplied.length && checkedRows.length) {
      if (classChoice.gradeId) {
        dispatch(
          copyTimeTable({
            grade_id: classChoice.gradeId,
            section_id: classChoice.section,
            from_day: tab_day,
            to_days: daysToBeApplied,
            periods: checkedRows,
          })
        );
      }
    }
  };
  //////////////////////////////////////////

  return (
    <div>
      <Grid container className={classes.tableSection}>
        <Grid container>
          <Grid container justifyContent="space-between">
            <Button
              onClick={() => {
                setDaysDialog(true);
              }}
              color="primary"
              variant="outlined"
              disabled={!Boolean(checkedRows.length)}
              startIcon={<CheckOutlinedIcon />}
            >
              Apply To
            </Button>
          </Grid>
        </Grid>
        <Table style={{ marginTop: "10px" }}>
          <TableHead>
            <StyledTableRow>
              {!tableEdit && (
                <StyledTableCell align="left">
                  <Checkbox
                    color="default"
                    onChange={handleRowCheckAll}
                    checked={allChecked}
                  />
                </StyledTableCell>
              )}
              <StyledTableCell align="left">Period</StyledTableCell>
              <StyledTableCell align="left">Time</StyledTableCell>
              <StyledTableCell align="left">Subject - Teacher</StyledTableCell>
              {}
              {tableEdit && (
                <StyledTableCell align="left">Action</StyledTableCell>
              )}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {periodsByClass?.periods.map((item) => (
              <StyledTableRow key={item.id}>
                {!tableEdit && (
                  <StyledTableCell align="left">
                    <Checkbox
                      color="default"
                      checked={checkedRows.includes(item.id)}
                      value={item.id}
                      onChange={handleRowCheck}
                    />
                  </StyledTableCell>
                )}
                <StyledTableCell align="left">{item.name}</StyledTableCell>
                <StyledTableCell align="left">
                  {moment(item.start_time, "hh:mm A").format("hh:mm A") +
                    " - " +
                    moment(item.end_time, "hh:mm A").format("hh:mm A")}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {tableEdit ? (
                    <>
                      {getSubjectTeachersByPeriod(item).map((st, index) => (
                        <div
                          key={index + 1}
                          style={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          <Autocomplete
                            fullWidth={false}
                            value={
                              [
                                { name: "Theory", id: 1 },
                                { name: "Practical", id: 2 },
                                { name: "ECA", id: 3 },
                              ].find((opt) => opt.id === st.class_type) || null
                            }
                            onChange={(
                              event: React.ChangeEvent<{}>,
                              value: { id: number; name: string } | null
                            ) => {
                              handleChangeInputs(
                                item,
                                st.id || null,
                                "classType",
                                value?.id || null,
                                null,
                                null
                              );
                            }}
                            options={[
                              { name: "Theory", id: 1 },
                              { name: "Practical", id: 2 },
                              { name: "ECA", id: 3 },
                            ]}
                            getOptionLabel={(option) => option.name}
                            disabled={item.name.startsWith("Break")}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Period Type"
                                name="period"
                                variant="outlined"
                                style={{ width: "160px" }}
                              />
                            )}
                          />
                          <Autocomplete
                            value={st.subject}
                            onChange={(
                              event: React.ChangeEvent<{}>,
                              value: { id: string; name: string } | null
                            ) => {
                              handleChangeInputs(
                                item,
                                st.id || null,
                                "subject",
                                null,
                                value,
                                null
                              );
                            }}
                            options={subjectTeachers.map((item) => ({
                              id: item.subject.id,
                              name: item.subject.name,
                            }))}
                            getOptionLabel={(option) => option.name}
                            disabled={item.name.startsWith("Break")}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Select Subject"
                                name="period"
                                variant="outlined"
                              />
                            )}
                          />
                          <Autocomplete
                            value={st.teacher}
                            onChange={(
                              event: React.ChangeEvent<{}>,
                              value: {
                                id: string;
                                first_name: string;
                                last_name: string;
                              } | null
                            ) => {
                              handleChangeInputs(
                                item,
                                st.id || null,
                                "teacher",
                                null,
                                null,
                                value
                              );
                            }}
                            options={getTeacherOptions(st.subject?.id || "")}
                            getOptionLabel={(option) =>
                              `${option.first_name || ""} ${
                                option.last_name || ""
                              }`
                            }
                            disabled={item.name.startsWith("Break")}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Select Teacher"
                                name="period"
                                variant="outlined"
                              />
                            )}
                          />
                          <ItemDeleteButton
                            disabled={!deleteAllowed(item.id)}
                            onClick={() =>
                              dispatch(
                                updateTimetableByDayAction({
                                  period: item,
                                  subjectTeacherId: st.id,
                                  class_type_value: null,
                                  field: "subject",
                                  subject_value: null,
                                  teacher_value: null,
                                  delete: true,
                                })
                              )
                            }
                          />
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      {getSubjectTeachersByPeriod(item).length
                        ? getSubjectTeachersByPeriod(item).map((st, index) => (
                            <div key={index + 1}>
                              {`${st.subject?.name || ""} - ${
                                st.teacher?.first_name || "-"
                              } ${st.teacher?.last_name || ""}`}
                            </div>
                          ))
                        : "--"}
                    </>
                  )}
                </StyledTableCell>
                {tableEdit && (
                  <StyledTableCell align="left">
                    <ItemAddButton
                      title="Add subject"
                      onClick={() =>
                        dispatch(
                          updateTimetableByDayAction({
                            period: item,
                            subjectTeacherId: null,
                            field: "subject",
                            class_type_value: null,
                            subject_value: null,
                            teacher_value: null,
                          })
                        )
                      }
                    />
                  </StyledTableCell>
                )}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        <Grid container justifyContent="flex-end">
          <Grid item lg={12}>
            <Grid container justifyContent="flex-end">
              <Button
                onClick={handleDiscardChanges}
                disabled={!tableEdit}
                className={classes.containedButton}
                color="primary"
                variant="contained"
                startIcon={<Cancel />}
                style={{ marginRight: "10px" }}
              >
                Discard
              </Button>
              <Button
                onClick={handleSaveChanges}
                className={classes.containedButton}
                color="primary"
                variant="contained"
                startIcon={tableEdit ? <SaveIcon /> : <Edit />}
                disabled={!Boolean(periodsByClass?.periods.length)}
              >
                {tableEdit ? "Save" : "Edit"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <SelectDaysDialog
        modalState={daysDialog}
        handleClose={setDaysDialog}
        currentDay={tab_day}
        handleApply={applyToDays}
      />
      <BackDropLoader open={subjectLoading || teacherLoading || loading} />
    </div>
  );
};
// ------------------------------<END> Sunday Components ends----------------------------------------------//
export default Sunday;
