import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setSnackbar } from "../../../actions/SnackbarAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

export const getRollNum = createAsyncThunk(
  "getRollNum",
  async (
    data: { grade: string; section: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.get(
        `${ADMIN_API_URL}/student_info/?grade=${data.grade}&section=${data.section}&order_by=alphabetically&per_page=1000`
      );
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(null);
    }
  }
);

export const updateRollNum = createAsyncThunk(
  "updateRollNum",
  async (data: any, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.post(`${ADMIN_API_URL}/assign-roll-no/`, data);
      dispatch(setSnackbar(true, "success", "Roll No updated Successfully"));
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      dispatch(setSnackbar(true, "error", error.response.data.error[0]));

      return rejectWithValue(null);
    }
  }
);
