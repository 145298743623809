import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setSnackbar } from "../../../../actions/SnackbarAction";
import { ADMIN_API_URL } from "../../../../config/SERVER_API_URLS";

export const getExamSchedule = createAsyncThunk(
  "getExamSchedule",
  async (
    data: { examId: string; gradeId: string; sectionId?: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.get(
        `${ADMIN_API_URL}/exam-subject/?exam_info=${data.examId}&grade=${
          data.gradeId
        }&section=${data.sectionId || ""}`
      );
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(null);
    }
  }
);

export const updateExamSchedule = createAsyncThunk(
  "updateExamSchedule",
  async (
    data: { examId: string; gradeId: string; post_data: any },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.post(
        `${ADMIN_API_URL}/exam-subject/save_bulk_exam_subject_info/?exam_info=${data.examId}&grade=${data.gradeId}`,
        data.post_data
      );
      dispatch(
        setSnackbar(true, "success", "Exam Schedule Updated Successfully")
      );
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      dispatch(setSnackbar(true, "warning", error.response.data.error));
      return rejectWithValue(null);
    }
  }
);
