import React, { useState, useEffect, useRef } from "react";
import { useFormStyles } from "../../Styles/FormStyles";
import { PaymentMethodTypeI } from "../../../actions/Fees/PaymentMethod/PaymentMethodActionTypes";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import { useForm } from "react-hook-form";
import {
  AddPaymentMethod,
  UpdatePaymentMethod,
} from "../../../actions/Fees/PaymentMethod/PaymentMethodAction";
import FormLayout from "../../Reusable/Layouts/Form.Layout";
import CustomizedTextField from "../../Reusable/Inputs/TextField";
import CustomizedSelect from "../../Reusable/Inputs/Select";
import { paymentOptions } from "../../../common/json.constant";
// -----------------<start> Styling <start>-----------------------//
// -----------------<end> Styling <end>-----------------------//

// -----------------<start> Interfaces <start>-----------------------//
interface PropsI {
  editData: PaymentMethodTypeI | null;
  onEditCheck: (value: boolean) => void;
}
// -----------------<end> Interfaces <end>-----------------------//

// -----------------<start> States <start>-----------------------//

// -----------------<end> States <end>-----------------------//

const PaymentMethodForm = (props: PropsI) => {
  const classes = useFormStyles();
  const { editData, onEditCheck } = props;

  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [editID, setEditID] = useState<string>("");
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const [payment, setPayment] = React.useState<any>(null);

  const dispatch = useDispatch();
  const paymentMethodLoading = useSelector(
    (state: RootStore) => state.payment_method.loading
  );
  const add_or_update = useSelector((state: RootStore) => state.payment_method.add_or_update)



  useEffect(() => {
    setLoading(paymentMethodLoading);
  }, [paymentMethodLoading]);

  useEffect(() => {
    editData != null && handleEditTableData(editData);
  }, [editData]);

  const handleReset = () => {
    reset();
    setPayment(null)
    setEditMode(false);
    onEditCheck(false);
  };

  useEffect(() => {
    if (add_or_update) {
      setIsBtnLoading(false)
      handleReset();
    }
  }, [add_or_update])

  const handleEditTableData = (data: PaymentMethodTypeI) => {
    const edit_data = paymentOptions.find(
      (element: any) => element.value === String(data.name)
    );
    // console.log(data)
    setEditMode(true);
    setValue("name", data.name);
    setPayment(edit_data)
    setEditID(data.id);
  };
  const { register, clearErrors, handleSubmit, setValue, reset, setError, errors } = useForm(
    {
      mode: "onChange",
    }
  );

  const onFormSubmit = (data: PaymentMethodTypeI) => {
    setIsBtnLoading(true);
    const new_data = {
      name: payment.value,
    };
    if (editMode) {
      editID != null && dispatch(UpdatePaymentMethod(editID, new_data, handleReset));
    } else {
      dispatch(AddPaymentMethod(new_data, handleReset));
    }
  };



  const handlePaymentChange = (value: any) => {
    setPayment(value);
    clearErrors("name");
  };

  //------------------ERROR HANDLING ------------------------//
  const [serverErrors, setServerErrors] = useState<any>(null);
  const paymentSelector = useSelector(
    (state: RootStore) => state.payment_method
  );
  const errorsData = paymentSelector.errors;
  const initialErrorsData = useRef(errorsData);

  useEffect(() => {
    if (initialErrorsData.current === errorsData) {
      initialErrorsData.current = errorsData; // Do not set initial errors
    } else {
      if (errorsData?.error != null) {
        const keys = Object.keys(errorsData?.error);
        keys.map((elem: any) => {
          setError(elem, {
            type: "serverSideError",
            message: errorsData.error[elem] && errorsData.error[elem][0],
          });
        });
      }
      setServerErrors(errorsData);
    }
  }, [errorsData]);

  useEffect(() => {
    if (paymentSelector.recent) {
      handleReset();
    }
  }, [paymentSelector.recent]);

  return (
    <>
      <FormLayout
        title="Add Payment Method"
        onSubmit={handleSubmit(onFormSubmit)}
        editMode={editMode}
        loading={loading}
        onClick={!isBtnLoading && handleReset}
        add_or_update={isBtnLoading}
      >
        <CustomizedSelect
          label="Select Payment Option"
          name="name"
          required
          options={paymentOptions}
          value={payment}
          setValue={handlePaymentChange}
          inputRef={register({ required: "Payment must be selected" })}
        />
        <span className={classes.validationErrorInfo}>
          {errors.name?.type === "required" && errors.name.message}
        </span>
        <span className={classes.validationErrorInfo}>
          {errors.name?.type === "serverSideError" && errors.name.message}
        </span>
      </FormLayout>
    </>
  );
};

export default PaymentMethodForm;