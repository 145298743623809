// ---------------- <START> module import ------------------//
import React, { useState, useEffect } from "react";
import { Paper, Typography } from "@material-ui/core";
import { useTable } from "../../Reusable";
import { useTableStyles } from "../../Styles/TableStyles";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import {
  GetMeetingTypes,
  DeleteMeetingType,
} from "../../../actions/HumanResource/MeetingType/MeetingTypeAction";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import { MeetingTypeTypeI } from "../../../actions/HumanResource/MeetingType/MeetingTypeActionTypes";
import { HeadCellsI } from "../../../actions";
import {
  ItemDeleteButton,
  ItemEditButton,
} from "../../Reusable/Buttons/TableButton";
// ---------------- <END> module import ------------------//

// ---------------- <START> Interface ------------------//
interface MeetingTypeTableProps {
  onEditData: (value: MeetingTypeTypeI) => void;
}

// ---------------- <END> Interface ------------------//

// ---------------- <START> Head Cells ------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N" },
  { id: "name", label: "Meeting Type" },
  { id: "action", label: "Action" },
];
// ---------------- <START> Head Cells ------------------//

// ---------------- <START> MeetingType Table Component ------------------//
const MeetingTypeTable = (props: MeetingTypeTableProps) => {
  const { onEditData } = props;
  const classes = useTableStyles();
  const dispatch = useDispatch();
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  const [tableData, setTableData] = useState<MeetingTypeTypeI[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);

  const leaveTypeState = useSelector((state: RootStore) => state.meeting_type);

  useEffect(() => {
    dispatch(GetMeetingTypes());
  }, []);

  useEffect(() => {
    setLoading(leaveTypeState.loading);

    const items: MeetingTypeTypeI[] = leaveTypeState.meeting_types.map(
      (meeting_type) => ({
        id: meeting_type.id,
        name: meeting_type.name,
      })
    );

    setTableData(items);
  }, [leaveTypeState]);

  const handleEditClicked = (data: MeetingTypeTypeI) => {
    onEditData(data);
  };

  const handleDeleteModal = (value: boolean, id: string) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteMeetingType(itemId));
    setDeleteModalOpen(false);
  };

  const getCustomTableRow = (item: any, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.name}
        </StyledTableCell>

        <StyledTableCell align="center" className={classes.cell}>
          <ItemEditButton onClick={() => handleEditClicked(item)} />
          <ItemDeleteButton onClick={() => handleDeleteModal(true, item.id)} />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <Paper className={classes.root}>
      <Typography variant="h6">Meeting Type List</Typography>

      <TableContainer
        label="Meeting Type List"
        loading={loading}
        items={tableData}
        headCells={headCells}
        getCustomTableRow={getCustomTableRow}
        search_param="name"
      />

      {/* Delete modal dialog */}
      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
      />
    </Paper>
  );
};
// ---------------- <START> MeetingType Table Component ------------------//

export default MeetingTypeTable;
