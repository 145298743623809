import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Grid, Typography } from "@material-ui/core";
import { Add } from '@material-ui/icons';
import { useTableStyles } from "../../Styles/TableStyles";
import { useTable } from "../../Reusable";
import { HeadCellsI } from "../../../actions";
import { RootStore } from "../../../store";
import { ItemViewButton } from "../../Reusable/Buttons/TableButton";
import CustomizedPopUp from "../../Reusable/Dialogs/CustomizedPopUp"
import AssignRubricModal from "./AssignRubricModal"
import AddNewButton from "../../Reusable/Buttons/AddNewButton"
import { GetRubricCategory } from "../../../actions/Academics/RubricCategory/RubricCategoryAction"
import { GetApplyRubricCategory } from "../../../actions/Academics/AssignRubric/AssignRubricAction"
import { RubricStaffI, IDNameI } from "../../../actions/Academics/AssignRubric/AssignRubricActionTypes"

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
    { id: "sn", label: "S.N" },
    { id: "staff_name", label: "Staff Name", filter: true },
    { id: "designation", label: "Designation" },
    { id: "rubric", label: "Rubric Categories" },
    { id: "action", label: "Action" },
];

// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

const AssignRubricTable = () => {
    const dispatch = useDispatch()
    const classes = useTableStyles();
    const [tableData, setTableData] = useState<RubricStaffI[]>([]);
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [editData, setEditData] = useState<RubricStaffI | null>(null)
    const [editMode, setEditMode] = useState<boolean>(false);
    const { action_performed, rubric_staff, loading, add_or_update } = useSelector((state: RootStore) => state.assign_rubric)
    // Retrieving re-usable components from useTable
    const { StyledTableCell, StyledTableRow, TableContainer } = useTable(headCells);

    useEffect(() => {
        add_or_update && dispatch(GetApplyRubricCategory());
    }, [add_or_update]);
    useEffect(() => {
        action_performed && setModalOpen(false)
    }, [action_performed])
    useEffect(() => {
        dispatch(GetApplyRubricCategory());
        dispatch(GetRubricCategory())
    }, []);

    useEffect(() => {
        setTableData(rubric_staff);
    }, [rubric_staff]);

    // Modal Functions
    const handleViewClicked = (data: RubricStaffI) => {
        setModalOpen(true)
        setEditData(data)
        setEditMode(true)
    }
    const handleAssignClicked = () => {
        setModalOpen(true)
        setEditMode(false)
    }
    const handleModalClose = (val: boolean) => {
        setModalOpen(val);
    }
    const handleAssign = () => {
        // TODO:
    }

    const getCustomTableRow = (item: RubricStaffI, index: number) => {
        return (
            <StyledTableRow key={item.id}>
                <StyledTableCell align="center" className={classes.cell}>
                    {index}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.cell}>
                    {item?.staff?.full_name}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.cell}>
                    {item?.staff?.designation?.name}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.cell}>
                    {item.applicable_rubric_category.map((temp: IDNameI, index: number) => index === item.applicable_rubric_category.length - 1 ? temp.name : temp.name.concat(", "))}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.cell}>
                    <ItemViewButton onClick={() => handleViewClicked(item)} />
                </StyledTableCell>
            </StyledTableRow>
        );
    };

    return (
        <Paper className={classes.rootTableBox}>
            <Grid container justifyContent="space-between">
                <Typography variant="h6">Staff List</Typography>
                <Grid item>
                    <AddNewButton
                        title="Assign Rubric to New Staff"
                        type="button"
                        color="primary"
                        variant="outlined"
                        startIcon={<Add />}
                        onClick={handleAssignClicked}
                    >
                        Assign Rubric
                    </AddNewButton>
                </Grid>
            </Grid>
            <TableContainer
                label="Staff List"
                loading={loading}
                items={tableData}
                headCells={headCells}
                getCustomTableRow={getCustomTableRow}
                search_name="staff_name"
            />
            <CustomizedPopUp
                title="Assign Rubric"
                open={modalOpen}
                onClose={handleModalClose}
                actionConfirmed={handleAssign}
                dialogTitle="Assign Rubric to staff"
                okText={false}
                cancel={false}
            >
                <AssignRubricModal editMode={editMode} data={editData} />
            </CustomizedPopUp>
        </Paper>
    )
}

export default AssignRubricTable