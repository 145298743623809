import {
  MenuList,
  MenuItem,
  Divider,
  List,
  ListSubheader,
  ListItem,
  ListItemProps,
  makeStyles,
  createStyles,
  Paper,
  Theme,
} from '@material-ui/core';
import { LibraryBooksOutlined } from '@material-ui/icons';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetClasses } from '../../../actions/Academics/Class/ClassAction';
import { ClassTypeI } from '../../../actions/Academics/Class/ClassActionTypes';
import { SectionTypeI } from '../../../actions/Academics/Section/SectionActionTypes';
import { RootStore } from '../../../store';
import { useSidebar } from '../../Reusable';
import SidebarTitle from '../../Reusable/Titles/SidebarTitle';
import { useSidebarStyles } from '../../Styles/SidebarStyles';
import { MenuStyles } from './MenuStyles';

export function ListItemLink(props: ListItemProps<'a', { button?: true }>) {
  return <ListItem button component="a" {...props} />;
}

const useClassStyles = makeStyles((theme: Theme) =>
  createStyles({
    Sidebar: {
      margin: '0 0 0 20px',
      display: 'flex',
      flexDirection: 'column',
      position: 'fixed',
      left: '0px',
      zIndex: 10,
      overflowY: 'auto',
      borderRadius: '2px',
      maxHeight: 'calc(100vh - 150px)',
      border: '2px solid #ddd',
    },
    sectionBar: {
      margin: '0 0 0 235px',
      display: 'flex',
      flexDirection: 'column',
      position: 'fixed',
      left: '0px',
      zIndex: 10,
      overflowY: 'auto',
      borderRadius: '16px',
      maxHeight: 'calc(100vh - 180px)',
    },
    sectionContent: {
      padding: '0.5rem 0.5rem',
      minWidth: '150px',
      maxWidth: '150px',
      overflowY: 'auto',
      '& .MuiListItem-gutters': {
        padding: '5px 15px',
        marginBottom: '5px',
      },
      '& .MuiListItemText-primary': {
        fontSize: '14px',
      },
      '& .MuiListItemText-root': {
        margin: 0,
      },
    },
    sidebarContent: {
      padding: '0.5rem 1rem',
      overflowY: 'auto',
      '& .MuiListItem-gutters': {
        padding: '5px 30px',
      },
      '& .MuiListItemText-primary': {
        fontSize: '14px',
      },
      '& .MuiListItemText-root': {
        margin: 0,
      },
    },
  })
);

const ClassRoomSidebar = (props: any) => {
  const { onClassItem } = props;
  const classX = useClassStyles();
  const classes = MenuStyles();
  const dispatch = useDispatch();

  const [selectedIndex, setSelectedIndex] = React.useState<number>(0);
  const [classItem, setClassItem] = useState<ClassTypeI | null>(null);

  const classSelector = useSelector((state: RootStore) => state.class.classes);

  useEffect(() => {
    dispatch(GetClasses());
  }, []);

  useEffect(() => {
    if (classItem == null) {
      setClassItem(classSelector[0]);
    }
  }, [classSelector]);

  useEffect(() => {
    if (classItem) {
      onClassItem(classItem);
    }
  }, [classItem, selectedIndex]);

  const handleListItemClick = (elem: ClassTypeI, index: number) => {
    setSelectedIndex(index);
    setClassItem(elem);
  };

  return (
    <>
      <Paper elevation={0} className={classX.Sidebar}>
        <div className={classX.sidebarContent}>
          <List component="div">
            {classSelector.length &&
              classSelector.map((elem: ClassTypeI, index: number) => {
                return (
                  <>
                    <ListItemLink
                      key={elem.id}
                      selected={selectedIndex === index}
                      onClick={(event) => handleListItemClick(elem, index)}
                      className={clsx(
                        classes.dropdownItem,
                        classes.noLiPadding,
                        classes.primaryHover
                      )}
                    >
                      Class {elem.grade_name}
                    </ListItemLink>
                    <Divider className={classes.dropdownDividerItem} />
                  </>
                );
              })}
          </List>
        </div>
      </Paper>
    </>
  );
};

export default ClassRoomSidebar;
