import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ScheduledSmsPayloadI, SmsLogI, SmsLogPayloadI } from "./sms";
import { getScheduledSms, getSmsLog, sendSms } from "./smsApi";

interface InitialState {
  loading: boolean;
  actionPerformed: boolean;
  sms_log: SmsLogPayloadI;
  scheduled_sms: ScheduledSmsPayloadI;
}

const initialState: InitialState = {
  loading: false,
  actionPerformed: false,
  sms_log: {
    current_page: 1,
    total: 0,
    per_page: 10,
    results: [],
  },
  scheduled_sms: {
    current_page: 1,
    total: 0,
    per_page: 10,
    results: [],
  },
};

const smsSlice = createSlice({
  name: "smsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSmsLog.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getSmsLog.fulfilled,
      (state, { payload }: PayloadAction<SmsLogPayloadI>) => {
        state.loading = false;
        state.sms_log = {
          ...state.sms_log,
          current_page: payload.current_page,
          total: payload.total,
          per_page: payload.per_page,
          results: payload.results,
        };
      }
    );
    builder.addCase(getSmsLog.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getScheduledSms.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getScheduledSms.fulfilled,
      (state, { payload }: PayloadAction<ScheduledSmsPayloadI>) => {
        state.loading = false;
        state.scheduled_sms = {
          ...state.sms_log,
          current_page: payload.current_page,
          total: payload.total,
          per_page: payload.per_page,
          results: payload.results,
        };
      }
    );
    builder.addCase(getScheduledSms.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(sendSms.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(sendSms.fulfilled, (state) => {
      state.loading = false;
      state.actionPerformed = true;
    });
    builder.addCase(sendSms.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default smsSlice.reducer;
