// ---------------------------- <START> module imports start ----------------------------------//
import React, { useState } from "react";
import {
  TableBody,
  TextField,
  Grid,
  Typography,
  Paper,
} from "@material-ui/core";
import {
  VisibilityOutlined,
  EditOutlined,
  DeleteForeverOutlined,
} from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import { useTable } from "../../Reusable";
import { useTableStyles } from "../../Styles/TableStyles";
import ActionButton from "../../Reusable/Buttons/ActionButton";
// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//
interface TableDataI {
  id: string;
  class: string;
  section: string;
  subject_group: string;
}

interface HeadCellsI {
  id: string;
  label: string;
}
// ---------------------------- <END> interface ends ----------------------------------//

// ---------------------------- <START> Table Dummy starts ----------------------------------//
const dummyData: TableDataI[] = [
  {
    id: "1",
    class: "03",
    section: "A",
    subject_group: "Class 1st subject group",
  },
  {
    id: "2",
    class: "04",
    section: "B",
    subject_group: "Class 2nd subject group",
  },
  {
    id: "3",
    class: "03",
    section: "A",
    subject_group: "Class 1st subject group",
  },
  {
    id: "4",
    class: "04",
    section: "B",
    subject_group: "Class 2nd subject group",
  },
  {
    id: "5",
    class: "03",
    section: "A",
    subject_group: "Class 1st subject group",
  },
  {
    id: "6",
    class: "04",
    section: "B",
    subject_group: "Class 2nd subject group",
  },

  {
    id: "7",
    class: "04",
    section: "A",
    subject_group: "Class 1st subject group",
  },
  {
    id: "8",
    class: "03",
    section: "B",
    subject_group: "Class 1st subject group",
  },
  {
    id: "9",
    class: "04",
    section: "A",
    subject_group: "Class 2nd subject group",
  },
  {
    id: "10",
    class: "03",
    section: "B",
    subject_group: "Class 1st subject group",
  },
  {
    id: "A",
    class: "04",
    section: "A",
    subject_group: "Class 1st subject group",
  },
  {
    id: "12",
    class: "04",
    section: "B",
    subject_group: "Class 2nd subject group",
  },
  {
    id: "13",
    class: "04",
    section: "A",
    subject_group: "Class 1st subject group",
  },
  {
    id: "14",
    class: "03",
    section: "B",
    subject_group: "Class 2nd subject group",
  },
  {
    id: "15",
    class: "04",
    section: "A",
    subject_group: "Class 1st subject group",
  },
  {
    id: "16",
    class: "03",
    section: "B",
    subject_group: "Class 1st subject group",
  },
  {
    id: "17",
    class: "04",
    section: "A",
    subject_group: "Class 2nd subject group",
  },
  {
    id: "18",
    class: "03",
    section: "B",
    subject_group: "Class 1st subject group",
  },
  {
    id: "19",
    class: "04",
    section: "A",
    subject_group: "Class 1st subject group",
  },
  {
    id: "20",
    class: "03",
    section: "B",
    subject_group: "Class 2nd subject group",
  },
  {
    id: "21",
    class: "04",
    section: "A",
    subject_group: "Class 1st subject group",
  },
];
// ---------------------------- <END> Table Dummy Data ends ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "class", label: "Class" },
  { id: "section", label: "Section" },
  { id: "subject_group", label: "Subject Group" },
  { id: "action", label: "Action" },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Time Table List Component Starts ---------------------------//
const TimeTableList: React.FC = () => {
  const classes = useTableStyles();

  // States declaration
  const [tableData, setTableData] = useState<TableDataI[]>(dummyData);
  const [tempTableData] = useState<TableDataI[]>(dummyData);

  // ---------------------------- <START> Pagination States and Functions  Starts---------------------------//
  const [totalRecord, setTotalRecord] = useState(tempTableData.length);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [pageCount, setPageCount] = useState(
    Math.ceil(totalRecord / rowsPerPage)
  );
  const [page, setPage] = useState(1);

  const [recordFrom, setRecordFrom] = useState(1);
  const [recordTo, setRecordTo] = useState(7);

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
    setRecordFrom(newPage === 1 ? newPage : (newPage - 1) * rowsPerPage + 1);
    setRecordTo(newPage === 1 ? newPage * rowsPerPage : newPage * rowsPerPage);
  };

  // ---------------------------- <END> Pagination States and Functions Ends ---------------------------//

  // Retrieving re-usable components from useTable
  const { TblContainer, TblHead, StyledTableCell, StyledTableRow } =
    useTable(headCells);

  // Event function for table searching by class
  const handleTableSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const search_data = tempTableData.filter((data) =>
      data.class.toUpperCase().includes(e.target.value.toUpperCase())
    );
    setTotalRecord(search_data.length);
    setPageCount(Math.ceil(search_data.length / rowsPerPage));
    setPage(1);
    setRecordFrom(1);
    setRecordTo(rowsPerPage);
    setTableData(search_data);
  };

  // Function for handling action icons click event
  const handleActionClicked = () => {
    alert("Action Clicked!");
  };

  return (
    <>
      <Paper className={classes.roottable}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" style={{ color: "#4D4F5C" }}>
              Time Table List
            </Typography>
          </Grid>
        </Grid>
        <div className={classes.tableWrapper}>
          <TextField
            className={classes.searchInput}
            autoComplete="off"
            autoFocus={false}
            // focused={false}
            variant="outlined"
            name="search"
            placeholder="Name ..."
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleTableSearch(e)
            }
          />
          <TblContainer>
            <TblHead />
            <TableBody>
              {tableData
                .slice(
                  (page - 1) * rowsPerPage,
                  (page - 1) * rowsPerPage + rowsPerPage
                )
                .map((item: TableDataI) => (
                  <StyledTableRow key={item.id}>
                    <StyledTableCell align="center" className={classes.cell}>
                      {item.class}
                    </StyledTableCell>
                    <StyledTableCell align="center" className={classes.cell}>
                      {item.section}
                    </StyledTableCell>
                    <StyledTableCell align="center" className={classes.cell}>
                      {item.subject_group}
                    </StyledTableCell>
                    <StyledTableCell align="center" className={classes.cell}>
                      <ActionButton title="View">
                        <VisibilityOutlined className={classes.viewIcon} />
                      </ActionButton>
                      <ActionButton title="Edit">
                        <EditOutlined className={classes.editIcon} />
                      </ActionButton>
                      <ActionButton title="Delete">
                        <DeleteForeverOutlined className={classes.deleteIcon} />
                      </ActionButton>
                      {/* <a className={classes.Btn} onClick={handleActionClicked}>
                        <VisibilityOutlined className={classes.viewIcon} />
                      </a>
                      <a className={classes.Btn} onClick={handleActionClicked}>
                        <EditOutlined className={classes.editIcon} />
                      </a>
                      <a className={classes.Btn} onClick={handleActionClicked}>
                        <DeleteForeverOutlined className={classes.deleteIcon} />
                      </a> */}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </TblContainer>
          <div className={classes.tableBottom}>
            {totalRecord > 0 ? (
              <Typography>
                Showing {recordFrom} to{" "}
                {recordTo > totalRecord ? totalRecord : recordTo} of{" "}
                {totalRecord}
              </Typography>
            ) : (
              <Typography>No records found</Typography>
            )}
            <div className={classes.pageNavigation}>
              <Pagination
                count={pageCount}
                page={page}
                onChange={handlePageChange}
                shape="rounded"
                color="primary"
              />
            </div>
          </div>
        </div>
      </Paper>
    </>
  );
};
// ---------------------------- <END> Time Table List Component Ends -------------------------------//

export default TimeTableList;
