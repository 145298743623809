import React, { useEffect, useState } from 'react';
import {
  Paper,
  Grid,
  Tooltip,
  IconButton,
  TextField,
  Button,
  makeStyles,
  createStyles,
  Theme,
  Box,
  Divider,
} from '@material-ui/core';
import { SettingsBackupRestore, Search } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../store';
import AddNewButton from '../Reusable/Buttons/AddNewButton';
import CustomizedNepaliDatePicker, {
  RowNepaliDatePicker,
} from '../Reusable/Inputs/NepaliDatePicker';
import CustomizedSelect, { RowSelect } from '../Reusable/Inputs/Select';
import { useFormStyles } from '../Styles/FormStyles';
import { CUR_NEPALI_DATE } from '../utils/nepaliDateUtils';
import clsx from 'clsx';
import AdminAssignmentTable from './AdminAssignmentTable';
import { SectionTypeI } from '../../actions/Academics/Section/SectionActionTypes';
import { Autocomplete } from '@material-ui/lab';
import { GetAdminAssignment } from '../../actions/Classroom/AdminAssignment/AdminAssignmentAction';
import { useForm } from 'react-hook-form';
import { SubjectGroupTypeI } from '../../actions/Academics/Subject/SubjectGroupActionTypes';
import { SubjectTypeI } from '../../actions/Academics/Subject/SubjectActionTypes';
import { GetSubjectGroupsByClass } from '../../actions/Academics/Subject/SubjectGroupAction';
import { GetTeacherList } from '../../actions/Classroom/TeacherList/TeacherListAction';
import { TeacherListTypeI } from '../../actions/Classroom/TeacherList/TeacherListActionTypes';
import getFullName from '../utils/getFullName';
import { GetSubjectsByTeacher } from '../../actions/Academics/Subject/SubjectAction';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // background:
      //   "linear-gradient(90deg, rgba(6,15,241,0.01) 0%, rgba(157,39,235,0.15) 100%)",
      marginTop: '0px',
      // minHeight: "50vh",
      padding: theme.spacing(2),
    },
  })
);

const SearchAssignment = (props: any) => {
  const classes = useFormStyles();
  const dispatch = useDispatch();
  const classZ = useStyles();
  const { classItem } = props;

  const { register, handleSubmit, errors, reset, setValue } = useForm<any>({
    mode: 'onChange',
  });

  const [selectedSubject, setSelectedSubject] = useState<TeacherListTypeI | null>(null);
  const [sectionChoices, setSectionChoices] = useState<any>([]);
  const [selectedSection, setSelectedSection] = useState<SectionTypeI | null>(
    null
  );
  const [teacherChoices, setTeacherChoices] = useState<TeacherListTypeI[]>([]);
  const [selectedTeacher, setSelectedTeacher] =
    useState<TeacherListTypeI | null>(null);

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [flag, setFlag] = useState<boolean>(false);

  const teacherSelector = useSelector(
    (state: RootStore) => state.teacher_lists.teacher_list
  );

  useEffect(() => {
    const section = selectedSection?.id ? selectedSection?.id : ""
    classItem && flag && dispatch(
      GetAdminAssignment(
        classItem?.grade,
        section,
        "",
        "",
        "",
        ""
      )
    );
    setFlag(false);
  }, [classItem, flag])

  useEffect(() => {
    setTeacherChoices(teacherSelector);
  }, [teacherSelector]);

  useEffect(() => {
    if (classItem) {
      setSectionChoices(classItem.section);
      setFlag(true)
    }
  }, [classItem]);

  useEffect(() => {
    handleSectionChange(sectionChoices[0]);
  }, [sectionChoices]);

  useEffect(() => {
    const section = selectedSection?.id ? selectedSection.id : ""
    classItem && dispatch(GetTeacherList(classItem.grade, section, "", ""));
  }, [classItem, selectedSection]);

  const onSubmit = (data: any) => {
    const section = selectedSection?.id ? selectedSection?.id : ""
    const subject = selectedSubject?.id ? selectedSubject?.id : ""
    const teacher = selectedTeacher?.teacher_id ? selectedTeacher?.teacher_id : ""
    dispatch(
      GetAdminAssignment(
        classItem?.grade,
        section,
        teacher,
        subject,
        fromDate ? fromDate : "",
        toDate ? toDate : ""
      )
    );
  };

  const handleReset = () => {
    const section = classItem?.section[0]
    setSelectedSection(section);
    setSelectedTeacher(null)
    setSelectedSubject(null)
    setToDate("");
    setFromDate("");
    dispatch(
      GetAdminAssignment(
        classItem?.grade,
        section?.id,
        "",
        "",
        "",
        ""
      )
    );
  };

  useEffect(() => {
    if (classItem) {
      handleReset();
    }
  }, [classItem]);

  const handleSectionChange = (value: SectionTypeI | null) => {
    setSelectedTeacher(null)
    setSelectedSubject(null);
    if (value && classItem) {
      setSelectedSection(value);
      dispatch(GetTeacherList(classItem.grade, value.id, "", ""));
    }
  };

  const handleTeacherChange = (value: TeacherListTypeI | null) => {
    const section = selectedSection?.id ? selectedSection?.id : ""
    const subject = selectedSubject?.id ? selectedSubject?.id : ""
    if (value) {
      dispatch(GetTeacherList(classItem.grade, section, subject, value?.teacher_id));
      setSelectedTeacher(value);
    }
  };

  const handleSubjectChange = (value: any) => {
    const section = selectedSection?.id ? selectedSection?.id : ""
    const teacher = selectedTeacher?.teacher_id ? selectedTeacher?.teacher_id : ""
    if (value) {
      dispatch(GetTeacherList(classItem.grade, section, value.id, teacher));
      setSelectedSubject(value);
    }
  }

  return (
    <>
      <Paper elevation={0} className={clsx(classZ.root)}>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={6} className={classes.formTitle}>
              <span>Assignment Search</span>
            </Grid>
            <Grid container item xs={6}>
              <Grid container justifyContent="flex-end" spacing={1}>
              </Grid>
            </Grid>
          </Grid>
          <Divider />
          <Grid container spacing={1}>
            <Grid
              item
              xs={3}
              className={classes.formWrapperRow}
              style={{ maxWidth: '13%' }}
            >
              <Box color="text.primary" mr={0.5}>
                Section
              </Box>
              <Autocomplete
                classes={{
                  input: classes.smallfont,
                  option: classes.smallfont,
                }}
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: SectionTypeI | null
                ) => handleSectionChange(value)}
                options={sectionChoices}
                value={selectedSection}
                fullWidth
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Label"
                    name="section"
                    inputRef={register({ required: true })}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid
              style={{ maxWidth: '20%' }}
              item
              xs={3}
              className={classes.formWrapperRow}
            >
              <Box color="text.primary" mr={0.5}>
                Teacher
              </Box>
              <Autocomplete
                classes={{
                  input: classes.smallfont,
                  option: classes.smallfont,
                }}
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: TeacherListTypeI | null
                ) => handleTeacherChange(value)}
                options={teacherChoices}
                fullWidth
                value={selectedTeacher}
                getOptionLabel={(option: any) => getFullName(option.teacher_first_name, option.teacher_last_name)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Label"
                    name="teacher"
                    inputRef={register}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid
              style={{ maxWidth: '23%' }}
              item
              xs={3}
              className={classes.formWrapperRow}
            >
              <Box color="text.primary" mr={0.5}>
                Subject
              </Box>
              <Autocomplete
                classes={{
                  input: classes.smallfont,
                  option: classes.smallfont,
                }}
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: TeacherListTypeI | null
                ) => handleSubjectChange(value)}
                options={teacherChoices}
                fullWidth
                value={selectedSubject}
                getOptionLabel={(option: any) => option.subject_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Label"
                    name="subject"
                    inputRef={register}
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            <Grid style={{ maxWidth: '20%' }} item xs={3}>
              <RowNepaliDatePicker
                label="From"
                value={fromDate}
                setValue={setFromDate}
              />
            </Grid>
            <Grid style={{ maxWidth: '20%' }} item xs={3}>
              <RowNepaliDatePicker
                label="To"
                value={toDate}
                setValue={setToDate}
              />
            </Grid>

            <Grid
              style={{ maxWidth: '14%' }}
              item
              xs={2}
              className={classes.formWrapperRow}
            >
              <Button
                style={{
                  marginTop: '0px',
                  backgroundColor: '#3FC581',
                  color: '#fff',
                }}
                type="submit"
                variant="contained"
                startIcon={<Search />}
              >
                Search
              </Button>
            </Grid>
            <Grid item xs={1}>
              <Tooltip title="Clear Search Query">
                <IconButton
                  aria-label="delete"
                  style={{
                    padding: '4px',
                    marginTop: '0px',
                    background: 'rgba(0,0,0,0.05)',
                  }}
                  onClick={handleReset}
                >
                  <SettingsBackupRestore />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </form>
        <Divider style={{ marginTop: '8px' }} />
      </Paper>
    </>
  );
};

export default SearchAssignment;
