const tablePrint = (label = "Hamro academy", hiddenCols: number[] = []) => {
  const tableToPrint = document.getElementById("export-table")!.outerHTML;

  const hiddenColumnsStyle = hiddenCols.reduce((total, hiddenCol) => {
    total += `
      table tr td:nth-child(${hiddenCol}),
      table tr th:nth-child(${hiddenCol}) {
          display: none;
      }`;
    return total;
  }, "");

  let htmlToPrint = `
      <style>
        table * {
          color: black;
          background: transparent!important;
        }

        table {
          text-align: left;
          border: 1px solid #000;
          border-width: 1px 1px 1px 1px !important;
          border-collapse: collapse;
        }

        th, td {
          border: 1px solid #000;
          border-width: 0 1px 1px 0 !important;
          padding: 0.5rem;
        }
        .hidden-school-name {
          display: block !important;
        }

        /* Hide the last column by default */
        ${
          hiddenCols && hiddenCols.length > 0
            ? hiddenColumnsStyle
            : `table tr td:last-child,
               table tr th:last-child {
                 display: none;
               }`
        }

        td *{
          display: inline-block;
          margin-right: 5px;
        }
        
        
        td img {
          width: 40px;
          display: none,
        }
        
        .MuiAvatar-root {
          display: none;
        }

        input, textarea,
        button, svg{
          display: none!important;
        }

        @media print{    
          .no-print, .no-print *{
            display: none !important;
          }
        }

      </style>
    `;

  htmlToPrint += tableToPrint;
  const a = window.open("", "", "height=1024, width=1024")!;
  a.document.write(htmlToPrint);
  a.document.title = label;
  a.print();
};

export default tablePrint;
