import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setSnackbar } from "../../../actions/SnackbarAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

export const getSubjectTeachers = createAsyncThunk(
  "subjectTeacher/getsubjectTeachers",
  async (
    { grade, section }: { grade: string; section: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.get(
        `${ADMIN_API_URL}/assign-subject-teacher/?grade=${grade}&section=${section}`
      );

      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(null);
    }
  }
);

export const updateSubjectTeachers = createAsyncThunk(
  "subjectTeacher/updatesubjectTeachers",
  async (
    data: {
      grade: string;
      section: string;
      subject: string;
      teacher: string[];
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      await axios.post(`${ADMIN_API_URL}/assign-subject-teacher/`, data);

      dispatch(
        setSnackbar(true, "success", "Subject Teachers updated successfully.")
      );

      return { editedSubject: data.subject };
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(null);
    }
  }
);
