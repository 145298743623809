import {
  GET_GUARDIAN_LOADING,
  GET_GUARDIAN_ERROR,
  GET_GUARDIAN_SUCCESS,
  ADD_GUARDIAN_LOADING,
  ADD_GUARDIAN_ERROR,
  ADD_GUARDIAN_SUCCESS,
  DELETE_GUARDIAN_LOADING,
  DELETE_GUARDIAN_ERROR,
  DELETE_GUARDIAN_SUCCESS,
  UPDATE_GUARDIAN_LOADING,
  UPDATE_GUARDIAN_ERROR,
  UPDATE_GUARDIAN_SUCCESS,
  GUARDIAN_BY_ID_LOADING,
  GUARDIAN_BY_ID_ERROR,
  GUARDIAN_BY_ID_SUCCESS,
  GET_CHILDREN_LOADING,
  GET_CHILDREN_ERROR,
  GET_CHILDREN_SUCCESS,
  GuardianTypeI,
  GuardianDispatchTypes,
  UPDATE_GUARDIAN_USERNAME_LOADING,
  UPDATE_GUARDIAN_USERNAME_ERROR,
  UPDATE_GUARDIAN_USERNAME_SUCCESS,
} from "../../../actions/Student/Guardian/GuardianActionTypes";

import {
  SET_SYSTEM_ROLES_SUCCESS,
  SystemRolesDispatchTypes,
  SystemRolesTypeI,
} from "../../../actions/HumanResource/StaffRole/StaffRoleActionTypes";

interface InitialStateI {
  loading: boolean;
  guardians: any;
  recently_added: any;
  children: any;
  errors: any;
  guardian_by_id?: any;
  add_or_update?: boolean;
  guardian_roles?: any;
  actionPerformed: boolean;
}

const initialState: InitialStateI = {
  loading: false,
  guardians: null,
  recently_added: null,
  children: null,
  errors: null,
  guardian_by_id: null,
  add_or_update: false,
  guardian_roles: [],
  actionPerformed: false,
};

const GuardianReducer = (
  state = initialState,
  action: GuardianDispatchTypes | SystemRolesDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_GUARDIAN_LOADING:
      return {
        ...state,
        loading: true,
        actionPerformed: false,
      };

    case GET_GUARDIAN_ERROR:
      return {
        ...state,
        loading: false,
        add_or_update: false,
      };

    case GET_GUARDIAN_SUCCESS:
      return {
        ...state,
        loading: false,
        guardians: action.payload,
        recently_added: null,
        errors: null,
        guardian_by_id: null,
        add_or_update: false,
      };

    case GET_CHILDREN_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_CHILDREN_ERROR:
      return {
        ...state,
        loading: false,
        add_or_update: false,
      };

    case GET_CHILDREN_SUCCESS:
      return {
        ...state,
        loading: false,
        children: action.payload,
        recently_added: null,
        errors: null,
        guardian_by_id: null,
        add_or_update: false,
      };

    case GUARDIAN_BY_ID_LOADING:
      return {
        ...state,
        loading: true,
        actionPerformed: false,
      };

    case GUARDIAN_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        guardian_by_id: action.payload,
        errors: null,
        guardian_roles: action.payload.guardian_user.groups,
      };

    case GUARDIAN_BY_ID_ERROR:
      return {
        ...state,
        loading: false,
      };

    case ADD_GUARDIAN_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case ADD_GUARDIAN_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        add_or_update: false,
      };

    case ADD_GUARDIAN_SUCCESS:
      return {
        ...state,
        loading: false,
        guardians:
          state.guardians != null
            ? [...state.guardians.results, action.payload]
            : [...state.guardians, action.payload],
        recently_added: action.payload,
        errors: null,
        add_or_update: true,
      };

    case UPDATE_GUARDIAN_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case UPDATE_GUARDIAN_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        add_or_update: false,
      };

    case UPDATE_GUARDIAN_SUCCESS:
      const current_data: any = state.guardians;
      const index: number = current_data.results.findIndex(
        (element: any) => element.id === action.payload.id
      );
      current_data[index] = action.payload;

      return {
        ...state,
        loading: false,
        guardians: current_data,
        errors: null,
        add_or_update: true,
      };

    case DELETE_GUARDIAN_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_GUARDIAN_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_GUARDIAN_SUCCESS:
      const new_data: any = state.guardians;
      const item_index = new_data.results.findIndex(
        (element: any) => element.id === action.payload.id
      );
      new_data.results.splice(item_index, 1);

      return {
        ...state,
        loading: false,
        guardians: new_data,
        errors: null,
      };

    case UPDATE_GUARDIAN_USERNAME_LOADING:
      return {
        ...state,
        loading: true,
        actionPerformed: false,
      };

    case UPDATE_GUARDIAN_USERNAME_SUCCESS:
      return {
        ...state,
        loading: false,
        actionPerformed: true,
      };

    case UPDATE_GUARDIAN_USERNAME_ERROR:
      return {
        ...state,
        loading: false,
        actionPerformed: false,
      };

    case SET_SYSTEM_ROLES_SUCCESS:
      return {
        ...state,
        guardian_roles: action.payload,
        actionPerformed: true,
      };

    default:
      return state;
  }
};

export default GuardianReducer;
