import {
  GET_CHECKIN_CHECKOUT_LOADING,
  GET_CHECKIN_CHECKOUT_ERROR,
  GET_CHECKIN_CHECKOUT_SUCCESS,
  CheckinCheckoutDispatchTypes,
} from "../../actions/HumanResource/Attendance/AttendanceActionTypes";

interface InitialStateI {
  loading: boolean;
  teacher_attendance: any;
}

const initialState: InitialStateI = {
  loading: false,
  teacher_attendance: [],
};

const AttendanceReducer = (
  state: InitialStateI = initialState,
  action: CheckinCheckoutDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_CHECKIN_CHECKOUT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_CHECKIN_CHECKOUT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_CHECKIN_CHECKOUT_SUCCESS:
      return {
        loading: false,
        teacher_attendance: action.payload,
      };

    default:
      return state;
  }
};

export default AttendanceReducer;
