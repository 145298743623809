import {
  APPLY_RUBRIC_CATEGORY_ERROR,
  APPLY_RUBRIC_CATEGORY_LOADING,
  APPLY_RUBRIC_CATEGORY_SUCCESS,
  GET_APPLY_RUBRIC_CATEGORY_ERROR,
  GET_APPLY_RUBRIC_CATEGORY_LOADING,
  GET_APPLY_RUBRIC_CATEGORY_SUCCESS,
  AssignRubricDispatchTypes,
  RubricStaffI,
} from "../../actions/Academics/AssignRubric/AssignRubricActionTypes";

interface InitialStateI {
  loading: boolean;
  errors?: any;
  recent?: boolean;
  add_or_update: boolean;
  rubric_staff: RubricStaffI[];
  action_performed: boolean;
}

const initialState: InitialStateI = {
  loading: false,
  errors: null,
  recent: false,
  add_or_update: false,
  rubric_staff: [],
  action_performed: false,
};

const AssignRubricReducer = (
  state: InitialStateI = initialState,
  action: AssignRubricDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case APPLY_RUBRIC_CATEGORY_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
        action_performed: false,
      };

    case APPLY_RUBRIC_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
        recent: false,
        add_or_update: true,
        action_performed: false,
      };

    case APPLY_RUBRIC_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: null,
        recent: true,
        add_or_update: true,
        action_performed: true,
      };

    case GET_APPLY_RUBRIC_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
        action_performed: false,
      }
    case GET_APPLY_RUBRIC_CATEGORY_LOADING:
      return {
        ...state,
        loading: true,
        action_performed: false,
      }
    case GET_APPLY_RUBRIC_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        action_performed: false,
        rubric_staff: action.payload.results,
      }

    default:
      return state;
  }
}

export default AssignRubricReducer