// ---------------------------- <START> module imports start ----------------------------------//
import React, { useEffect, useState } from "react";
import {
  TextField,
  Box,
  Grid,
  InputLabel,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import { useTableStyles } from "../../../../Styles/TableStyles";
import { useTableWithPagination } from "../../../../Reusable";
import { Autocomplete } from "@material-ui/lab";
import { useFormStyles } from "../../../../Styles/FormStyles";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../../../store";
import getFullName from "../../../../utils/getFullName";
import { GetClasses } from "../../../../../actions/Academics/Class/ClassAction";
import { GetStudentTransportList } from "../../../../../actions/Transport/Routes/RoutesModal/StudentRouteAction";
import { ClassTypeI } from "../../../../../actions/Academics/Class/ClassActionTypes";
import { SectionTypeI } from "../../../../../actions/Academics/Section/SectionActionTypes";
import { useForm } from "react-hook-form";
import { FormResetButton } from "../../../../Reusable/Buttons/FormButton";
import { ItemAddButton } from "../../../../Reusable/Buttons/TableButton";
import TransportAddConfirm from "../../../../Reusable/Dialogs/TransportAddConfirm";
import getFullClassName from "../../../../utils/getFullClassName";
import { MONTH_CHOICES } from "../../../../../common/json.constant";
import { Tupple } from "../../../../../actions";
// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//
interface TableDataI {
  id: string;
  student_name: string;
  class: string;
  section: string;
  transport: number | null;
  academic_id: string;
}

interface FormI {
  class: number | null;
  section: string | null;
  gradeID: string | null;
  sectionID: string | null;
  grade: string;
}

interface HeadCellsI {
  id: string;
  label: string;
}

// ---------------------------- <END> interface ends ----------------------------------//
// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "student_name", label: "Student Name" },
  { id: "class", label: "Class" },
  { id: "month", label: "Month(s)" },
  { id: "action", label: "Action" },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Add Student Component Starts ---------------------------//
const AddStudent = (props: any) => {
  const classes = useTableStyles();
  const classess = useFormStyles();
  const { studentData } = props;

  const { register, handleSubmit, errors, setValue } = useForm<FormI>();

  const [selectedClass, setSelectedClass] = useState<ClassTypeI | null>(null);
  const [classChoices, setClassChoices] = useState<ClassTypeI[]>([]);
  const [selectedSection, setSelectedSection] = useState<SectionTypeI | null>(
    null
  );
  const [sectionChoices, setSectionChoices] = useState<SectionTypeI[] | []>([]);
  const [appliedTransport, setAppliedTransport] = useState<Array<string>>([]);
  const [sectionDisabler, setSectionDisabler] = useState<boolean>(true);

  const [confirmDialog, setConfirmDialog] = useState<boolean>(false);
  const [paginationIndex, setPaginationIndex] = useState<number>(1);

  // States declaration
  const [tableData, setTableData] = useState<any>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [studentID, setStudentID] = useState<string>("");

  // Retrieving re-usable components from useTable
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTableWithPagination(headCells);

  //============================ <START> CYCLE HOOKS <START> =======================//
  const dispatch = useDispatch();
  const studentListSelector = useSelector(
    (state: RootStore) => state.student_route.student_list
  );
  console.log({ studentListSelector });
  const classSelector = useSelector((state: RootStore) => state.class);
  const studentRouteSelector = useSelector(
    (state: RootStore) => state.student_route.student_route
  );
  const CLASS_CHOICES = classSelector.classes.map((item) => ({
    id: item.id,
    value: item.grade,
    grade: item.grade,
    section: item.section,
  }));

  useEffect(() => {
    dispatch(GetClasses());
    dispatch(GetStudentTransportList(1, "", ""));
  }, []);

  const handlePaginationChange = (paginationIndex: number) => {
    setPaginationIndex(paginationIndex);
    dispatch(
      GetStudentTransportList(
        paginationIndex,
        selectedClass?.grade || "",
        selectedSection?.id || ""
      )
    );
  };

  const student_list_data = studentRouteSelector.length;

  useEffect(() => {
    const classes = classSelector.classes;
    setLoading(classSelector.loading);
    setClassChoices(classes);
  }, [classSelector]);

  const handleTransportId = (id: string, transport: Array<string>) => {
    setConfirmDialog(true);
    setStudentID(id);
    setAppliedTransport(transport);
  };

  function comparer(otherArray: any) {
    return function (current: any) {
      return (
        otherArray.filter(function (other: any) {
          return other.student_id === current.id;
        }).length === 0
      );
    };
  }
  useEffect(() => {
    const data: any = [];
    if (studentListSelector?.results?.length > 0) {
      studentListSelector?.results?.map((elem: any, index: number) => {
        data.push({
          key: elem.id,
          id: elem.id,
          transport: elem.student_transport_info?.applied_months || [],
          transport_id: elem.student_transport_info?.transport_info,
          student_name: getFullName(
            elem?.student?.student_user?.first_name,
            elem?.student?.student_user?.last_name
          ),
          academic_id: elem.id,
          class: elem?.grade?.name,
          section: elem?.section?.name,
        });
      });
    }
    setTableData(data);
  }, [studentListSelector]);

  console.log({ studentListSelector });

  const handleClassChange = (value: ClassTypeI | null) => {
    if (value !== null) {
      setSelectedClass(value);
      setSelectedSection(null);
      populateSectionChoices(value.section);
      value.grade && dispatch(GetStudentTransportList(1, value.grade, ""));
    } else {
      setSelectedClass(null);
      setSelectedSection(null);
      dispatch(GetStudentTransportList(1, "", ""));
    }
  };

  const handleSectionChange = (value: SectionTypeI | null) => {
    setSelectedSection(value);

    if (value) {
      if (selectedClass != null) {
        dispatch(
          GetStudentTransportList(
            1,
            selectedClass?.grade.toString(),
            value.id.toString()
          )
        );
      }
    } else {
      setSelectedSection(null);
      if (selectedClass != null) {
        dispatch(
          GetStudentTransportList(1, selectedClass?.grade.toString(), "")
        );
      }
    }
  };

  const populateSectionChoices = (sections: SectionTypeI[] | []) => {
    setSectionDisabler(false);
    sections.length > 0
      ? setSectionChoices(sections)
      : setSectionDisabler(true);
  };

  const handleFilter = () => {
    setSelectedClass(null);
    setSelectedSection(null);
    dispatch(GetStudentTransportList(1, "", ""));
  };

  const handleDialogClose = (value: boolean) => {
    setConfirmDialog(value);
  };

  const ToolTipSubjectViewComponent = (props: any) => {
    const { items } = props;

    return (
      <Grid direction="column" container>
        <Grid>
          <div>
            <List dense>
              {items.map((item: any, index: number) => {
                const single = MONTH_CHOICES.find(
                  (mKey: Tupple) => mKey.key === item
                );
                return (
                  <ListItem key={index}>
                    <ListItemText
                      primary={
                        <Typography variant="subtitle2">
                          {single?.value}
                        </Typography>
                      }
                    />
                  </ListItem>
                );
              })}
            </List>
          </div>
        </Grid>
      </Grid>
    );
  };

  const getCustomTableRow = (item: any) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {item.student_name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {getFullClassName(item.class, item.section || null)}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item?.transport && (
            <Tooltip
              arrow
              title={
                item?.transport?.length ? (
                  <ToolTipSubjectViewComponent items={item.transport} />
                ) : (
                  ""
                )
              }
            >
              <div>
                {`${
                  item.transport && item.transport[0]
                    ? MONTH_CHOICES.find(
                        (mKey: Tupple) => mKey.key === item.transport[0]
                      )?.value
                    : "N/A"
                } ${
                  item.transport?.length
                    ? `+${item.transport?.length - 1} more`
                    : ""
                }`}
              </div>
            </Tooltip>
          )}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ItemAddButton
            onClick={() => handleTransportId(item.academic_id, item.transport)}
          />
          {/* <ItemAddButton onClick={() => setConfirmDialog(true)} /> */}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  const onConfirmed = (confirmed: boolean) => {
    console.log("abc");
  };

  return (
    <>
      <Box
        className={classes.rootTableBox}
        style={{ margin: 0, padding: "0px 0px 16px 0" }}
      />
      <Grid container spacing={2}>
        <Grid item xs={3} className={classess.formWrapper}>
          <InputLabel>Select Class</InputLabel>
          <Autocomplete
            onChange={(
              event: React.ChangeEvent<{}>,
              value: ClassTypeI | null
            ) => handleClassChange(value)}
            options={classChoices}
            value={selectedClass}
            getOptionLabel={(option) => option.grade_name}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Label"
                name="class_name"
                inputRef={register({ required: true })}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={3} className={classess.formWrapper}>
          <InputLabel>
            Select Section
            {!sectionDisabler}
          </InputLabel>
          <Autocomplete
            onChange={(
              event: React.ChangeEvent<{}>,
              value: SectionTypeI | null
              // ) => setSelectedSection(value ? value : null)}
            ) => handleSectionChange(value)}
            options={sectionChoices}
            getOptionLabel={(option) => option.name}
            disabled={sectionDisabler}
            value={selectedSection}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Label"
                name="session_name"
                inputRef={register({ required: true })}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={3} style={{ marginTop: "23px" }}>
          <FormResetButton onClick={handleFilter} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="flex-end">
          {/* <Typography>
            Available Seats : {props.SeatCapacity - student_list_data}
          </Typography> */}
        </Grid>
      </Grid>

      <Divider style={{ margin: "10px 0px 15px 0px" }} />
      <Box
        className={classes.rootTableBox}
        style={{ margin: 0, padding: "0px 0px 16px 0" }}
      >
        <TableContainer
          items={tableData}
          headCells={headCells}
          getCustomTableRow={getCustomTableRow}
          forPagination={studentListSelector || []}
          handlePaginationChange={handlePaginationChange}
        />
      </Box>
      <TransportAddConfirm
        open={confirmDialog}
        onClose={handleDialogClose}
        deleteConfirmed={onConfirmed}
        transport={props.routeId}
        student={studentID}
        appliedTransport={appliedTransport}
        paginationIndex={paginationIndex}
        grade={selectedClass?.grade.toString() || ""}
        section={selectedSection?.id || ""}
      />
    </>
  );
};
// ---------------------------- <END> Route List Component Ends -------------------------------//

export default AddStudent;
