import { Paper } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { RootStore } from "../../../store";
import { useTableStyles } from "../../Styles/TableStyles";

const DaysRow = styled.div`
  display: grid;
  grid-template-columns: 60px repeat(6, 1fr);
  gap: 10px;

  margin-bottom: 12px;
`;

const DaysCard = styled.div`
  font-size: 18px;
  font-weight: 500;

  color: #9a9a9a;
`;

const TimetableGrid = styled.div`
  display: grid;
  grid-template-columns: 60px repeat(6, 1fr);

  gap: 10px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const PeriodCard = styled.div`
  position: relative;

  display: flex;
  gap: 5px;
  flex-direction: column;

  height: 100%;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-image: url("/stripes.jpg");
    background-size: cover;
    background-position: center;

    z-index: 0;
    opacity: 0.05;
  }
`;

const PeriodDetailCard = styled.div`
  background-color: #fff;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  span {
    font-size: 12px;
    font-weight: 400;
    color: #a7a7a7;
  }
`;

const BreakCard = styled.div`
  min-height: 90px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 1;

  span {
    font-size: 22px;
    font-weight: 500;
    color: #8b8b8b;
  }
`;

const SubjectTeacherCard = styled.div<{ colorIndex?: string }>`
  background-color: #fff;
  width: 100%;

  padding: 10px;

  border-radius: 5px;
  box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.4);

  z-index: 1;

  h4 {
    color: #32aaff;
    margin: 0 !important;
  }

  p {
    color: #92959b;
    margin: 0 0 10px 0 !important;
  }

  span {
    background-color: ${(props) =>
      props.colorIndex === "1"
        ? "#3fc581"
        : props.colorIndex === "2"
        ? "#132E98"
        : "#F2E82C"};
    color: ${(props) =>
      props.colorIndex === "1"
        ? "#FFF"
        : props.colorIndex === "2"
        ? "#FFF"
        : "#646466"};
    padding: 5px;
    font-size: 11px;
    font-weight: 500;

    border-radius: 5px;
  }
`;

const days = [7, 1, 2, 3, 4, 5];

const NewTimeTablePreview = () => {
  const classes = useTableStyles();
  const { timetableByClass } = useSelector(
    (state: RootStore) => state.timetable
  );
  const { periodsByClass } = useSelector((state: RootStore) => state.period);

  const getTimeTableCard = ({
    day,
    period,
  }: {
    day: number;
    period: string;
  }) => {
    if (timetableByClass) {
      const byDay = timetableByClass.timeTable.find((el) => el.day === day);

      if (byDay) {
        const targetPeriod = byDay.periods.find(
          (el) => el.period.id === period
        );

        if (targetPeriod) {
          if (targetPeriod.period.name.startsWith("Break")) {
            return (
              <PeriodCard>
                <BreakCard>
                  <span>BREAK</span>
                </BreakCard>
              </PeriodCard>
            );
          } else {
            const cards = targetPeriod.subject_teacher.map((el, index) => (
              <SubjectTeacherCard
                key={index + 1}
                colorIndex={String(el.class_type || 3)}
              >
                <h4>{el.subject?.name || ""}</h4>
                <p>
                  {el.teacher
                    ? `${el.teacher.first_name} ${el.teacher.last_name}`
                    : ""}
                </p>
                <span>
                  {el.class_type === 1
                    ? "Theory"
                    : el.class_type === 2
                    ? "Practical"
                    : "ECA"}
                </span>
              </SubjectTeacherCard>
            ));
            return cards;
          }
        }
      }
    }
    return (
      <PeriodCard>
        <BreakCard>
          <span></span>
        </BreakCard>
      </PeriodCard>
    );
  };

  return (
    <Paper className={classes.rootTableBox}>
      <DaysRow>
        <DaysCard></DaysCard>
        <DaysCard>Sunday</DaysCard>
        <DaysCard>Monday</DaysCard>
        <DaysCard>Tuesday</DaysCard>
        <DaysCard>Wednesday</DaysCard>
        <DaysCard>Thursday</DaysCard>
        <DaysCard>Friday</DaysCard>
      </DaysRow>

      {periodsByClass
        ? periodsByClass.periods.map((el, index) => (
            <TimetableGrid key={index + 1}>
              {days.map((day, ind) => {
                if (ind === 0) {
                  return (
                    <>
                      <PeriodDetailCard>
                        <span>
                          {moment(el.start_time, "HH:mm").format("h:mm A")}
                        </span>
                        <span>
                          {moment(el.end_time, "HH:mm").format("h:mm A")}
                        </span>
                      </PeriodDetailCard>
                      <PeriodCard>
                        {getTimeTableCard({ day, period: el.id })}
                      </PeriodCard>
                    </>
                  );
                } else {
                  return (
                    <PeriodCard key={ind + 1}>
                      {getTimeTableCard({ day, period: el.id })}
                    </PeriodCard>
                  );
                }
              })}
            </TimetableGrid>
          ))
        : null}
    </Paper>
  );
};

export default NewTimeTablePreview;
