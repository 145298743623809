import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Grid, InputLabel, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import SaveIcon from "@material-ui/icons/Save";
import { RootStore } from "../../../../../../store";
import { useStyles } from "../../../../../Styles/FormStyles";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormSaveLoadingButton } from "../../../../../Reusable/Buttons/FormButton";
import Spinner from "../../../../../../common/spinnerSVG/Spinner";
import styled from "styled-components";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import CustomizedNepaliDatePicker from "../../../../../Reusable/Inputs/NepaliDatePicker";
import { updateStaffPersonal } from "../../../../../../rtk/features/humanResource/humanResourceThunk";
import {
  dateConverterAdToBs,
  dateConverterBsToAd,
} from "../../../../../utils/dateConverter";
import { useParams } from "react-router-dom";

const ModalForm = styled.form`
  padding: 0 20px;
  max-width: 720px;
`;

const ImageDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const ImageDisplay = styled.label`
  cursor: pointer;
  & > img {
    display: block;
    width: 120px;
    height: 120px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 50%;

    object-fit: cover;
    object-position: center;
  }

  & > div {
    width: 120px;
    height: 120px;
  }
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 11px;
  margin-left: 10px;
`;

interface StaffFormProps {
  address: string;
  dob: string;
  citizenship_no: string;
  cast: string;
  father_name: string;
  mother_name: string;
  phone_number_alt: string;
  marital_status: { label: string; value: string } | null;
  blood_group: { label: string; value: string } | null;
  religion: { label: string; value: string } | null;
}

// const returnImgUrl = (img: File) => {
//   return URL.createObjectURL(img);
// };

export const bloodGroupOptions = [
  { value: "1", label: "A +ve" },
  { value: "4", label: "A -ve" },
  { value: "2", label: "B +ve" },
  { value: "5", label: "B -ve" },
  { value: "7", label: "AB +ve" },
  { value: "8", label: "AB -ve" },
  { value: "3", label: "O +ve" },
  { value: "6", label: "O -ve" },
];

export const religionOptions = [
  { value: "H", label: "Hinduism" },
  { value: "M", label: "Islam" },
  { value: "B", label: "Buddhism" },
  { value: "C", label: "Christianity" },
  { value: "O", label: "Others" },
];

const PersonalInfoModal = (props: any) => {
  // Custom Styles
  const classes = useStyles();
  const { handleModalClose } = props;
  const { staffID } = useParams<{ staffID: string }>();
  const { loading, actionCompleted, staffDetail } = useSelector(
    (state: RootStore) => state.humanResource
  );

  // Destructuring react-form-hook methods
  const { register, handleSubmit, errors, control, setValue } =
    useForm<StaffFormProps>({
      mode: "onChange",
    });

  // const [staffImg, setStaffImg] = useState<File | null>(null);

  // Dispatch Variable
  const dispatch = useDispatch();

  const [dob, setDob] = useState<string | null>(null);

  useEffect(() => {
    if (actionCompleted) {
      handleModalClose();
    }
  }, [actionCompleted]);

  useEffect(() => {
    if (staffDetail) {
      setValue("address", staffDetail.address || "");
      setValue("citizenship_no", staffDetail.citizenship_number || "");
      setValue("cast", staffDetail.cast || "");
      setValue("father_name", staffDetail.father_name || "");
      setValue("mother_name", staffDetail.mother_name || "");
      setValue("phone_number_alt", staffDetail.alter_phone || "");

      staffDetail.date_of_birth
        ? setDob(dateConverterAdToBs(staffDetail.date_of_birth))
        : setDob(null);

      staffDetail.marital_status
        ? setValue("marital_status", {
            label: staffDetail.marital_status,
            value: staffDetail.marital_status,
          })
        : setValue("marital_status", null);

      staffDetail.blood_group
        ? setValue(
            "blood_group",
            bloodGroupOptions.find((el) => el.value === staffDetail.blood_group)
          )
        : setValue("blood_group", null);

      staffDetail.religion
        ? setValue(
            "religion",
            religionOptions.find((el) => el.value === staffDetail.religion)
          )
        : setValue("religion", null);
    }
  }, [staffDetail]);

  const onSubmitModal: SubmitHandler<StaffFormProps> = (data) => {
    dispatch(
      updateStaffPersonal({
        id: staffID,
        phone: staffDetail?.phone || "",
        gender: staffDetail?.gender || "",
        address: data.address,
        alter_phone: data.phone_number_alt || null,
        date_of_birth: dob ? dateConverterBsToAd(dob) : null,
        marital_status: data.marital_status ? data.marital_status.value : null,
        religion: data.religion ? data.religion.value : null,
        blood_group: data.blood_group ? data.blood_group.value : null,
        citizenship_number: data.citizenship_no,
        cast: data.cast,
        father_name: data.father_name,
        mother_name: data.mother_name,
      })
    );
  };

  // const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   if (e.target.files) {
  //     if (e.target.files.length) {
  //       setStaffImg(e.target.files[0]);
  //     } else {
  //       setStaffImg(null);
  //     }
  //   } else {
  //     setStaffImg(null);
  //   }
  // };

  return (
    <ModalForm onSubmit={handleSubmit(onSubmitModal)}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={6}
          className={classes.formWrapper}
          style={{ marginBottom: "5px" }}
        >
          <InputLabel>
            Address <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <TextField
            variant="outlined"
            name="address"
            placeholder="Address"
            inputRef={register({
              required: { value: true, message: "Address is required." },
            })}
          />
          <ErrorMessage>{errors.address?.message}</ErrorMessage>
        </Grid>

        <Grid
          item
          xs={6}
          className={classes.formWrapper}
          style={{ marginBottom: "5px" }}
        >
          <InputLabel>DOB</InputLabel>
          <CustomizedNepaliDatePicker
            label=""
            value={dob}
            setValue={(val: any) => setDob(val)}
          />
          <ErrorMessage>{errors.dob?.message}</ErrorMessage>
        </Grid>

        <Grid
          item
          xs={6}
          className={classes.formWrapper}
          style={{ marginBottom: "5px" }}
        >
          <InputLabel>
            Citizenship No <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <TextField
            variant="outlined"
            name="citizenship_no"
            placeholder="Citizenship Number"
            inputRef={register({
              required: { value: true, message: "Citizenship no is required." },
            })}
          />
          <ErrorMessage>{errors.citizenship_no?.message}</ErrorMessage>
        </Grid>

        <Grid
          item
          xs={6}
          className={classes.formWrapper}
          style={{ marginBottom: "5px" }}
        >
          <InputLabel>Marital Status</InputLabel>
          <Controller
            name="marital_status"
            control={control}
            render={({ onChange }) => (
              <Autocomplete
                onChange={(_, data) => onChange(data)}
                options={[
                  { value: "Married", label: "Married" },
                  { value: "Unmarried", label: "Unmarried" },
                ]}
                defaultValue={
                  staffDetail?.marital_status
                    ? {
                        label: staffDetail.marital_status,
                        value: staffDetail.marital_status,
                      }
                    : null
                }
                getOptionLabel={(option) => option.label}
                openOnFocus={true}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="marital_status"
                    placeholder="Select Marital Status"
                    variant="outlined"
                  />
                )}
              />
            )}
          />
          <ErrorMessage>{errors.blood_group?.message}</ErrorMessage>
        </Grid>

        <Grid
          item
          xs={6}
          className={classes.formWrapper}
          style={{ marginBottom: "5px" }}
        >
          <InputLabel>Cast</InputLabel>
          <TextField
            variant="outlined"
            name="cast"
            placeholder="Cast/Ethnicity"
            inputRef={register}
          />
          <ErrorMessage>{errors.cast?.message}</ErrorMessage>
        </Grid>

        <Grid
          item
          xs={6}
          className={classes.formWrapper}
          style={{ marginBottom: "5px" }}
        >
          <InputLabel>Blood Group</InputLabel>
          <Controller
            name="blood_group"
            control={control}
            render={({ onChange }) => (
              <Autocomplete
                onChange={(_, data) => onChange(data)}
                options={bloodGroupOptions}
                defaultValue={
                  staffDetail?.blood_group
                    ? bloodGroupOptions.find(
                        (el) => el.value === staffDetail.blood_group
                      )
                    : null
                }
                getOptionLabel={(option) => option.label}
                openOnFocus={true}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="blood_group"
                    placeholder="Select Blood Group"
                    variant="outlined"
                  />
                )}
              />
            )}
          />
          <ErrorMessage>{errors.blood_group?.message}</ErrorMessage>
        </Grid>

        <Grid
          item
          xs={6}
          className={classes.formWrapper}
          style={{ marginBottom: "5px" }}
        >
          <InputLabel>Religion</InputLabel>
          <Controller
            name="religion"
            control={control}
            render={({ onChange }) => (
              <Autocomplete
                onChange={(_, data) => onChange(data)}
                options={religionOptions}
                defaultValue={
                  staffDetail?.religion
                    ? religionOptions.find(
                        (el) => el.value === staffDetail.religion
                      )
                    : null
                }
                getOptionLabel={(option) => option.label}
                openOnFocus={true}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="religion"
                    placeholder="Select Religion"
                    variant="outlined"
                  />
                )}
              />
            )}
          />
          <ErrorMessage>{errors.blood_group?.message}</ErrorMessage>
        </Grid>

        <Grid
          item
          xs={6}
          className={classes.formWrapper}
          style={{ marginBottom: "5px" }}
        >
          <InputLabel>Father's Name</InputLabel>
          <TextField
            variant="outlined"
            name="father_name"
            placeholder="Father's Name"
            inputRef={register}
          />
          <ErrorMessage>{errors.father_name?.message}</ErrorMessage>
        </Grid>

        <Grid
          item
          xs={6}
          className={classes.formWrapper}
          style={{ marginBottom: "5px" }}
        >
          <InputLabel>Mother's Name</InputLabel>
          <TextField
            variant="outlined"
            name="mother_name"
            placeholder="Mother's Name"
            inputRef={register}
          />
          <ErrorMessage>{errors.mother_name?.message}</ErrorMessage>
        </Grid>

        <Grid
          item
          xs={6}
          className={classes.formWrapper}
          style={{ marginBottom: "1rem" }}
        >
          <InputLabel>Phone Number 2</InputLabel>
          <TextField
            variant="outlined"
            name="phone_number_alt"
            placeholder="Alternative Phone Number"
            inputRef={register}
          />
          <ErrorMessage>{errors.phone_number_alt?.message}</ErrorMessage>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="flex-start"
        style={{ marginBottom: "1em" }}
      >
        <FormSaveLoadingButton
          type="submit"
          icon={loading ? <Spinner /> : <SaveIcon />}
          disabled={loading ? true : false}
          text={loading ? "Saving..." : "Save"}
        />
      </Grid>
    </ModalForm>
  );
};

export default PersonalInfoModal;
