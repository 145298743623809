// ---------------- <START> module import ------------------//
import React, { useState, useEffect } from "react";
import { Box, Dialog, Grid, Paper, Typography } from "@material-ui/core";
import { useTable, useTableWithPagination } from "../../Reusable";
import { useTableStyles } from "../../Styles/TableStyles";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import {
  GetDepartments,
  DeleteDepartment,
} from "../../../actions/HumanResource/Department/DepartmentAction";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import { DepartmentTypeI } from "../../../actions/HumanResource/Department/DepartmentActionTypes";
import { HeadCellsI } from "../../../actions";
import {
  ItemDeleteButton,
  ItemEditButton,
  ItemPrintButton,
  ItemViewButton,
} from "../../Reusable/Buttons/TableButton";
import useLoading from "../../../useHook/useLoading";
import {
  GetDaybook,
  GetInvoiceDetail,
} from "../../../actions/Fees/Daybook/DaybookAction";
import getFullName from "../../utils/getFullName";
import getFullClassName from "../../utils/getFullClassName";
import FeeInvoice from "../CollectFees/FeeInvoice";
import { GetGeneralInfoData } from "../../../actions/GeneralSettings/GeneralInfo/GeneralInfoAction";
import { GetDiscountSummary } from "../../../actions/Fees/DiscountSummary/DiscountSummaryAction";
import {
  dateConverterBsToAd,
  dateConverterAdToBs,
} from "../../utils/dateConverter";
// ---------------- <END> module import ------------------//

// ---------------- <START> Interface ------------------//

// ---------------- <END> Interface ------------------//

// ---------------- <START> Head Cells ------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N." },
  { id: "issue_date", label: "Issue Date" },
  { id: "invoice_no", label: "Receipt No" },
  { id: "student_name", label: "Student Name" },
  { id: "class", label: "Class" },
  { id: "discount_amount", label: "Discount Amount" },
];
// ---------------- <START> Head Cells ------------------//

// ---------------- <START> Department Table Component ------------------//
const DiscountSummaryReportTable = (props: any) => {
  const classes = useTableStyles();
  const dispatch = useDispatch();
  const { grade, section, startDate, endDate } = props;

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTableWithPagination(headCells);

  const [tableData, setTableData] = useState<DepartmentTypeI[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);
  const departmentState = useSelector((state: RootStore) => state.department);
  const discountSummaryState = useSelector(
    (state: RootStore) => state.discount_summary
  );
  const [openInvoice, setOpenInvoice] = useState(false);

  const generalInfoData = useSelector(
    (state: RootStore) => state.general_info?.general_info
  );
  const authData = useSelector((state: RootStore) => state.auth);

  useEffect(() => {
    dispatch(GetDiscountSummary(1, "", "", "", ""));
  }, []);

  useEffect(() => {
    if (itemId !== null) {
      dispatch(GetInvoiceDetail(itemId));
    }
  }, [itemId]);

  // useEffect(() => {
  //     if (authData.general_info != null) {
  //         dispatch(GetGeneralInfoData(authData.general_info.id));
  //     }
  // }, [authData]);

  useEffect(() => {
    if (discountSummaryState?.discount_summary !== []) {
      setTableData(discountSummaryState?.discount_summary?.results?.discounts);
    }
  }, [discountSummaryState]);

  const handleCloseInvoice = () => {
    setOpenInvoice(false);
  };

  const handleViewModal = (id: string) => {
    setItemId(id);
    setOpenInvoice(true);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteDepartment(itemId));
    setDeleteModalOpen(false);
  };

  const handlePaginationChange = (paginationIndex: number) => {
    dispatch(
      GetDiscountSummary(
        paginationIndex,
        grade?.grade || "",
        section?.id || "",
        (startDate && dateConverterBsToAd(startDate)) || "",
        (endDate && dateConverterBsToAd(endDate)) || ""
      )
    );
  };

  const getCustomTableRow = (item: any, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {dateConverterAdToBs(item.issued_date)}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.receipt_id}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {getFullName(
            item.student?.student?.student_user?.first_name,
            item.student?.student?.student_user?.last_name
          )}
        </StyledTableCell>{" "}
        <StyledTableCell align="center" className={classes.cell}>
          {getFullClassName(
            item.student?.grade?.name,
            item.student?.section?.name
          )}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          Rs. {item.discount || 0}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <div style={{ padding: "15px" }}>
      <Typography variant="h6">Discount Summary List</Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        style={{
          padding: "10px",
          backgroundColor: "#e4e3e3",
          marginBottom: "10px",
          color: "#3f3f3f",
        }}
      >
        <div style={{ margin: "auto 0px" }}>
          <b style={{ fontSize: "25px" }}>
            For{" "}
            {
              discountSummaryState?.discount_summary?.results?.summary[0]
                ?.total_student_count
            }{" "}
            STUDENTS
          </b>
          <br />
          <b>
            Secured In Total of{" "}
            {
              discountSummaryState?.discount_summary?.results?.summary[0]
                ?.out_of_total_student
            }
          </b>
        </div>
        <div style={{ margin: "auto 0px" }}>
          <span>Total Discount Given</span>
          <br />
          <b style={{ paddingRight: "10px" }}>
            Rs.{" "}
            {
              discountSummaryState?.discount_summary?.results?.summary[0]
                ?.total_discount
            }{" "}
            /-
          </b>
          <br />
        </div>
      </Box>
      <TableContainer
        label="Invoice"
        loading={departmentState.loading}
        items={tableData}
        headCells={headCells}
        getCustomTableRow={getCustomTableRow}
        disableSearch
        forPagination={discountSummaryState?.discount_summary}
        handlePaginationChange={handlePaginationChange}
      />
      <Dialog
        open={openInvoice}
        onClose={handleCloseInvoice}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
        // className={classes.modalBox}
      >
        <FeeInvoice
          generalInfoData={generalInfoData}
          student=""
          invoiceID={itemId}
        />
      </Dialog>
    </div>
  );
};
// ---------------- <START> Department Table Component ------------------//

export default DiscountSummaryReportTable;
