import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Typography } from "@material-ui/core";
import { RootStore } from "../../../store";
import { useTable } from "../../Reusable";
import { useTableStyles } from "../../Styles/TableStyles";
import { HeadCellsI } from "../../../actions";
import { RubricCategoryTypeI } from "../../../actions/Academics/RubricCategory/RubricCategoryActionTypes";
import { GetRubricCategory, DeleteRubricCategory } from "../../../actions/Academics/RubricCategory/RubricCategoryAction";
import {
    ItemDeleteButton,
    ItemEditButton,
    ItemViewButton
} from "../../Reusable/Buttons/TableButton";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import CustomizedPopUp from "../../Reusable/Dialogs/CustomizedPopUp"
import RubricSubCategoryPage from "./RubricSubCategory/RubricSubCategoryPage"

// ---------------- <START> Interface ------------------//
interface RubricCategoryTableProps {
    onEditData: (value: RubricCategoryTypeI) => void;
    edit: { value: boolean };
}
// ---------------- <END> Interface ------------------//
// ---------------- <START> Head Cells ------------------//
const headCells: HeadCellsI[] = [
    { id: "sn", label: "S.N" },
    { id: "name", label: "Category" },
    { id: "action", label: "Action" },
];
// ---------------- <START> Head Cells ------------------//

const RubricCategoryTable = (props: RubricCategoryTableProps) => {
    const { onEditData, edit } = props;
    const classes = useTableStyles();
    const dispatch = useDispatch();
    const { StyledTableCell, StyledTableRow, TableContainer } =
        useTable(headCells);

    const [tableData, setTableData] = useState<RubricCategoryTypeI[]>([]);
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
    const [itemId, setItemId] = useState<string | null>(null);
    const [editMode, setEditMode] = useState(false);


    const { loading, rubric_categories } = useSelector((state: RootStore) => state.rubric_category)

    useEffect(() => {
        dispatch(GetRubricCategory());
    }, []);
    useEffect(() => {
        edit && setEditMode(edit.value);
    }, [edit]);
    useEffect(() => {
        setTableData(rubric_categories);
    }, [rubric_categories]);

    //    Update Action
    const handleEditClicked = (data: RubricCategoryTypeI) => {
        onEditData(data);
        setEditMode(true);
    };

    // Delete Action
    const handleDeleteModal = (value: boolean, id: string) => {
        setItemId(id);
        setDeleteModalOpen(value);
    };
    const handleModalClose = (value: boolean) => {
        setDeleteModalOpen(value);
    };
    const onDeleteConfirmed = (confirmed: boolean) => {
        confirmed && itemId != null && dispatch(DeleteRubricCategory(itemId));
        setDeleteModalOpen(false);
    };

    // View Action
    const handleViewModal = (value: boolean, id: string) => {
        setItemId(id);
        setAddModalOpen(value);
    };
    const handleViewModalClose = (value: boolean) => {
        setAddModalOpen(value)
    }
    const handleAddSubCategory = () => {
        // TODO: sub-category add api
    }

    const getCustomTableRow = (item: RubricCategoryTypeI, index: number) => {
        return (
            <StyledTableRow key={item.id}>
                <StyledTableCell align="center" className={classes.cell}>
                    {index}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.cell}>
                    {item.name}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.cell}>
                    <ItemViewButton
                        disabled={editMode}
                        onClick={() => handleViewModal(true, item.id)}
                    />
                    <ItemEditButton onClick={() => handleEditClicked(item)} />
                    <ItemDeleteButton
                        disabled={editMode}
                        onClick={() => handleDeleteModal(true, item.id)}
                    />
                </StyledTableCell>
            </StyledTableRow>
        );
    };

    return (
        <Paper className={classes.root}>
            <Typography variant="h6">Rubric Category List</Typography>

            <TableContainer
                label="Rubric Category List"
                loading={loading}
                items={tableData}
                headCells={headCells}
                getCustomTableRow={getCustomTableRow}
                search_param="name"
            />
            <CustomizedDialogs
                open={deleteModalOpen}
                onClose={handleModalClose}
                deleteConfirmed={onDeleteConfirmed}
            />
            <CustomizedPopUp
                title="Add Rubric sub-category"
                open={addModalOpen}
                onClose={handleViewModalClose}
                actionConfirmed={handleAddSubCategory}
                dialogTitle="Rubric sub-category"
                okText={false}
                cancel={false}
                fullWidth
                maxWidth="xl"
            >
                <RubricSubCategoryPage id={itemId} />
            </CustomizedPopUp>
        </Paper>
    )
}

export default RubricCategoryTable

// state = {{ subCategory, setSubCategory }} 