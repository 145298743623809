// ---------------------------- <START> module imports start ----------------------------------//
import React, { useEffect, useState } from 'react';
import { Paper, Grid, Typography } from '@material-ui/core';
import { useTableStyles } from '../../Styles/TableStyles';
import { useTable } from '../../Reusable';
import { Link } from 'react-router-dom';
import {
  GetCategory,
  DeleteCategory,
} from '../../../actions/Student/StudentCategory/CategoryAction';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import CustomizedDialogs from '../../Reusable/Dialogs/DeleteDialog';
import {
  ItemDeleteButton,
  ItemEditButton,
  ItemViewButton,
} from '../../Reusable/Buttons/TableButton';
import AccessPermission from '../StudentDetail/AccessPermission';
// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//
interface TableDataI {
  id: string;
  name: string;
  description: string;
  category_id: number;
  count: number;
}
interface PropsI {
  onEditData: (value: TableDataI) => void;
}

interface HeadCellsI {
  id: string;
  label: string;
}
// ---------------------------- <END> interface ends ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: 'sn', label: 'S.N' },
  { id: 'name', label: 'Category Name' },
  // { id: "description", label: "Description" },
  // { id: "category_id", label: "Category Id" },
  { id: 'action', label: 'Action' },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Student Category Table Component Starts ---------------------------//
const StudentCategoryTable = (props: PropsI) => {
  const classes = useTableStyles();
  const { onEditData } = props;

  // States declaration
  const [tableData, setTableData] = useState<TableDataI[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);

  const dispatch = useDispatch();
  const categoryData = useSelector((state: RootStore) => state.categories);

  useEffect(() => {
    dispatch(GetCategory());
  }, []);

  const categoryLoading = useSelector(
    (state: RootStore) => state.categories.loading
  );

  useEffect(() => {
    setLoading(categoryLoading);
  }, [categoryLoading]);

  useEffect(() => {
    const data: any = [];
    if (categoryData.categories != null) {
      categoryData.categories.map((elem: any, index: number) => {
        data.push({
          key: elem.id,
          id: elem.id,
          name: elem?.name,
          description: elem?.description,
        });
      });
    }
    setTableData(data);
  }, [categoryData]);

  // Delete record modal
  const handleDeleteModal = (value: boolean, id: string) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteCategory(itemId));
    setDeleteModalOpen(false);
    dispatch(GetCategory());
  };

  // -----------------<START> Pagination states and functions <START>-----------------------//

  // -----------------<END> Pagination states and functions <END>-----------------------//

  // Retrieving re-usable components from useTable
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  // Edit record
  const handleEditClicked = (data: TableDataI) => {
    onEditData(data);
  };

  console.log(tableData);

  const getCustomTableRow = (item: TableDataI, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.name}
        </StyledTableCell>
        {/* <StyledTableCell align="center" className={classes.cell}>
          {item.description ? item.description : "-"}
        </StyledTableCell> */}
        {/* <StyledTableCell align="center" className={classes.cell}>
          {item.id}
        </StyledTableCell> */}
        <StyledTableCell align="center" className={classes.cell}>
          <AccessPermission>
            {/* <Link to="student-list-table">
            <ItemViewButton />
          </Link> */}
            <ItemEditButton onClick={() => handleEditClicked(item)} />
            <ItemDeleteButton
              onClick={() => handleDeleteModal(true, item.id)}
            />
          </AccessPermission>
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <Paper className={classes.root}>
      <Typography variant="h6">Category List</Typography>
      <TableContainer
        loading={loading}
        items={tableData}
        getCustomTableRow={getCustomTableRow}
        headCells={headCells}
        search_param="name"
      />
      {/* Delete modal dialog */}
      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
      />
    </Paper>
  );
};
// ---------------------------- <END> Student House Table Component Ends -------------------------------//
export default StudentCategoryTable;
