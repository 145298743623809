// ---------------------------- <START> module imports start ----------------------------------//
import React, { useEffect, useState } from "react";
import { TableBody, makeStyles, Theme } from "@material-ui/core";
import { PrintTableStyles } from "../../Styles/PrintTableStyles";
import { useTablePrint } from "../../Reusable";
import { useSelector } from "react-redux";
import { RootStore } from "../../../store";
// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//

interface HeadCellsI {
  id: string;
  label: string;
}
// ---------------------------- <END> interface ends ----------------------------------//

interface HeadCellsI {
  id: string;
  label: string;
}

// -------------------- <START> Table Headings Data ---------------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N" },
  { id: "interval", label: "Interval in %" },
  { id: "grade", label: "Grade" },
  { id: "grade_point", label: "Grade Point" },
  { id: "description", label: "Description" },
];
// ---------------------<END> Table Headings Data Ends --------------------------//

const useStyles = makeStyles((theme: Theme) => ({
  tableCell: {
    padding: "0px 5px !important",
    borderBottom: "1px solid #D6D6D6",
    borderRight: "1px solid #D6D6D6",
  },
}));

interface propsI {
  editMode: boolean;
}

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const GradePointTable = (props: propsI) => {
  const classes = PrintTableStyles();

  // States declaration
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any>([]);

  const examGrades = useSelector((state: RootStore) => state.exam_grade);

  useEffect(() => {
    if (props.editMode) {
      setTableData(dummyData);
    } else {
      const data: any = [];
      if (examGrades.grades != null) {
        examGrades.grades.map((elem: any, index: number) => {
          data.push({
            key: index + 1,
            interval: "90 to 100",
            grade: "A+",
            grade_point: "4.0",
            description: "Outstanding",
          });
        });
        setTableData(examGrades.grades);
      }
    }
  }, [examGrades]);

  // Retrieving re-usable components from useTable
  const {
    TblContainer,
    TblHead,
    StyledTableCell,
    StyledTableRow,
    exportToExcel,
    tablePrint,
  } = useTablePrint(headCells);

  // ============================ <START> REACT HOOKS <START> ============================== //

  const dummyData = [
    {
      marks_from: "90",
      marks_to: "100",
      grade_name: "A+",
      grade_point: "4.0",
      description: "Outstanding",
    },
    {
      marks_from: "90",
      marks_to: "100",
      grade_name: "A+",
      grade_point: "4.0",
      description: "Best",
    },
    {
      marks_from: "90",
      marks_to: "100",
      grade_name: "A+",
      grade_point: "4.0",
      description: "Good",
    },
    {
      marks_from: "90",
      marks_to: "100",
      grade_name: "A+",
      grade_point: "4.0",
      description: "Nice",
    },
    {
      marks_from: "90",
      marks_to: "100",
      grade_name: "A+",
      grade_point: "4.0",
      description: "Keep it up",
    },
    {
      marks_from: "90",
      marks_to: "100",
      grade_name: "A+",
      grade_point: "4.0",
      description: "Try Hard",
    },
    {
      marks_from: "90",
      marks_to: "100",
      grade_name: "A+",
      grade_point: "4.0",
      description: "Good",
    },
    {
      marks_from: "90",
      marks_to: "100",
      grade_name: "A+",
      grade_point: "4.0",
      description: "Try Hard",
    },
    {
      marks_from: "90",
      marks_to: "100",
      grade_name: "A+",
      grade_point: "4.0",
      description: "Outstanding",
    },
  ];
  // ============================ <END> REACT HOOKS <END> ============================== //

  // ============================ <START> EVENT HANDLERS <START> ============================== //

  // Event function for table searching by name
  const handleTableSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    return;
  };

  // ============================ <END> EVENT HANDLERS <END> ============================== //

  return (
    <>
      <div className={classes.tableWrapper} style={{ marginTop: "15px" }}>
        <TblContainer size="small">
          <TblHead />
          <TableBody>
            {tableData.map((item: any, index: number) => (
              <StyledTableRow key={index}>
                <StyledTableCell align="center" className={classes.cell}>
                  {index + 1}
                </StyledTableCell>
                <StyledTableCell align="left" className={classes.cell}>
                  {item.marks_from + " to " + item.marks_to}
                </StyledTableCell>
                <StyledTableCell align="left" className={classes.cell}>
                  {item.grade_name}
                </StyledTableCell>
                <StyledTableCell align="left" className={classes.cell}>
                  {item.grade_point}
                </StyledTableCell>
                <StyledTableCell align="left" className={classes.cell}>
                  {item.description}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </TblContainer>
      </div>
    </>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default GradePointTable;
