import {
  Button,
  Grid,
  InputLabel,
  Paper,
  TextField,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';

import { useForm } from 'react-hook-form';
import { Autocomplete } from '@material-ui/lab';
import { useFormStyles } from '../../Styles/FormStyles';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import { GetExams } from '../../../actions/Examination/Exam/ExamAction';
import { ExamTypeI } from '../../../actions/Examination/Exam/ExamActionTypes';
import { GetClasses } from '../../../actions/Academics/Class/ClassAction';
import { ClassTypeI } from '../../../actions/Academics/Class/ClassActionTypes';
import { SectionTypeI } from '../../../actions/Academics/Section/SectionActionTypes';
import { GetSearchedStudentList } from '../../../actions/Student/Student/StudentAction';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
// -----------------<start> Interfaces <start>-----------------------//

interface DataI {
  academic_year: string;
  class: string | null | undefined;
  section: string | null | undefined;
}
// -----------------<end> Interfaces <end>-----------------------//

const AdmitCardSearch = (props: any) => {
  const classes = useFormStyles();

  const { register, handleSubmit, errors, setValue } = useForm();
  //======================== > STATES < ===========================================//

  const [examType, setExamType] = useState<ExamTypeI | null>(null);
  const [examTypeChoices, setExamTypeChoices] = useState<ExamTypeI[]>([]);
  const [selectedClass, setSelectedClass] = useState<ClassTypeI | null>(null);
  const [classChoices, setClassChoices] = useState<ClassTypeI[]>([]);
  const [selectedSection, setSelectedSection] = useState<SectionTypeI | null>(
    null
  );
  const [sectionChoices, setSectionChoices] = useState<SectionTypeI[] | []>([]);
  const [sectionDisabler, setSectionDisabler] = useState<boolean>(true);

  ///////////////////////////////////////////////////////////////////////////////////////

  //======================================== <START> REACT HOOKS <START> ============================//

  const dispatch = useDispatch();
  const classSelector = useSelector((state: RootStore) => state.class);
  const examSelector = useSelector((state: RootStore) => state.exam);

  useEffect(() => {
    dispatch(GetClasses());
    dispatch(GetExams());
  }, []);

  useEffect(() => {
    setClassChoices(classSelector.classes);
  }, [classSelector]);

  useEffect(() => {
    setExamTypeChoices(examSelector.exams);
  }, [examSelector]);

  //======================================== <END> REACT HOOKS <END> ============================//

  //======================================== <START> EVENT HANDLERS <START> ============================//

  const handleClassChange = (value: ClassTypeI | null) => {
    setSelectedClass(value);
    setSelectedSection(null);
    value != null
      ? populateSectionChoices(value.section)
      : setSectionDisabler(true);
  };

  const populateSectionChoices = (sections: SectionTypeI[] | []) => {
    setSectionDisabler(false);
    sections.length > 0
      ? setSectionChoices(sections)
      : setSectionDisabler(true);
  };

  const onSubmit = () => {
    props.passExamInfo(examType);
    const form_data: DataI = {
      academic_year: '',
      class: selectedClass?.grade,
      section: selectedSection?.id,
    };
    dispatch(GetSearchedStudentList(form_data));
  };

  const handleReset = () => {
    setExamType(null);
    setSelectedSection(null);
    setSelectedClass(null);
    const form_data: DataI = {
      academic_year: '',
      class: '',
      section: '',
    };
    dispatch(GetSearchedStudentList(form_data));
  };

  //======================================== <END> EVENT HANDLERS <END> ============================//

  return (
    <>
      <Paper className={classes.pageContentBox}>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={9} className={classes.formTitle}>
              <span>Search Admit Card</span>
            </Grid>
            <Grid container item xs={3}>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                  <Tooltip title="Clear Search Query">
                    <IconButton
                      aria-label="delete"
                      style={{ padding: '2px' }}
                      onClick={handleReset}
                    >
                      <SettingsBackupRestoreIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3} className={classes.formWrapper}>
              <InputLabel>Exam Type</InputLabel>
              <Autocomplete
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: ExamTypeI | null
                ) => setExamType(value)}
                value={examType}
                options={examTypeChoices}
                getOptionLabel={(option) => option.exam_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Label"
                    name="academic_year"
                    inputRef={register({ required: true })}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={2} className={classes.formWrapper}>
              <InputLabel>Select Class</InputLabel>
              <Autocomplete
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: ClassTypeI | null
                ) => handleClassChange(value)}
                value={selectedClass}
                options={classChoices}
                getOptionLabel={(option) => option.grade_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Label"
                    name="class_name"
                    inputRef={register({ required: true })}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={2} className={classes.formWrapper}>
              <InputLabel>
                Select Section{' '}
                {!sectionDisabler && <span style={{ color: 'red' }}>*</span>}
              </InputLabel>
              <Autocomplete
                value={selectedSection}
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: SectionTypeI | null
                ) => setSelectedSection(value)}
                options={sectionChoices}
                getOptionLabel={(option) => option.name}
                disabled={sectionDisabler}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Label"
                    name="session_name"
                    inputRef={register({ required: true })}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Grid container justifyContent="center">
                <Button
                  className={classes.searchBoxButton}
                  type="submit"
                  color="primary"
                  variant="contained"
                  startIcon={<SearchIcon />}
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
};

export default AdmitCardSearch;
