// -----------------<START> import modules starts----------------------------------------------//
import React, { useEffect, useState } from "react";
import {
    makeStyles,
    Tabs,
    Tab,
    Typography,
    Box,
    Theme,
    createStyles,
    Paper,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetStudentProfile } from "../../../../../actions/Student/StudentProfile/StudentProfileAction";
import { RootStore } from "../../../../../store";
import Assignment from "./Assignment"
import Attendance from "./Attendance"
import ExamPerformance from "./ExamPerformance"
import Feedback from "./Feedback"

export const useTabStyles = makeStyles({
    root: {
        paddingLeft: "16px",
        "& .MuiTab-labelIcon": {
            minHeight: "40px",
            paddingTop: "6px!important",
        },
        "& .MuiTab-root": {
            fontSize: "14px",
            textTransform: "none",
            minWidth: "80px",
            maxWidth: "200px",
            height: "30px",
            letterSpacing: "0.01071em",
        },
        "& .MuiTabs-flexContainer": {
            borderBottom: "1px solid #ddd",
        },
        "& .MuiBox-root": {
            padding: "10px 0px 0px 0px",
        },
    },
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: theme.spacing(2),
            maxHeight: "70vh",
        },
    })
)


//----------------------<END> styled ends ---------------------------------------------------//
// ----------------------<STARTS> interface starts -------------------------------------------------------//
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
//------------------------<ENDS> interface ends -------------------------------------------------------//

export function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
// -----------------<START> Time table tabs component starts---------------------//
export default function MyProgress(props: any) {
    const classes = useTabStyles();
    const styles = useStyles();
    const [value, setValue] = React.useState(0);

    const { studentID } = useParams<{ studentID: string }>();

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Paper className={styles.container} elevation={1}>
            <div className={classes.root}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {/* <Tab label="Attendance" /> */}
                    <Tab label="Assignment" />
                    {/* <Tab label="Exam Performance" /> */}
                    <Tab label="Feedback" />
                </Tabs>
                {/* <TabPanel value={value} index={0}>
                    <Attendance />
                </TabPanel> */}
                <TabPanel value={value} index={0}>
                    <Assignment />
                </TabPanel>
                {/* <TabPanel value={value} index={2}>
                    <ExamPerformance />
                </TabPanel> */}
                <TabPanel value={value} index={1}>
                    <Feedback />
                </TabPanel>
            </div>
        </Paper>
    );
}
//---------------<END> Routes tabs component ends ----------------------------//