import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_DISCOUNT_LOADING,
  GET_DISCOUNT_ERROR,
  GET_DISCOUNT_SUCCESS,
  DiscountDispatchTypes,
  ADD_DISCOUNT_LOADING,
  ADD_DISCOUNT_SUCCESS,
  ADD_DISCOUNT_ERROR,
  DELETE_DISCOUNT_SUCCESS,
  DELETE_DISCOUNT_ERROR,
  DELETE_DISCOUNT_LOADING,
  UPDATE_DISCOUNT_LOADING,
  UPDATE_DISCOUNT_SUCCESS,
  UPDATE_DISCOUNT_ERROR,
} from "./DiscountActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { CategoryTypeI } from "../../Student/StudentCategory/CategoryActionTypes";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

interface RequestDataI {
  name: string;
  discount_in: string;
  discount_applicable: number;
}

export const GetDiscount =
  () => async (dispatch: Dispatch<DiscountDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_DISCOUNT_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/fee_discount/`,
        HeaderConfig()
      );

      dispatch({
        type: GET_DISCOUNT_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_DISCOUNT_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "Discount Type Loading Failed",
      //   },
      // });
    }
  };
export const AddDiscount =
  (data: RequestDataI) =>
  async (dispatch: Dispatch<DiscountDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_DISCOUNT_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/fee_discount/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_DISCOUNT_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Discount Type Added Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: ADD_DISCOUNT_ERROR,
        payload: error.response && error.response?.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Discount Type Add Failed",
        },
      });
    } finally {
      try {
        dispatch({
          type: GET_DISCOUNT_LOADING,
        });

        const res = await axios.get(
          `${ADMIN_API_URL}/fee_discount/`,
          HeaderConfig()
        );

        dispatch({
          type: GET_DISCOUNT_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: GET_DISCOUNT_ERROR,
        });
      }
    }
  };

export const UpdateDiscount =
  (id: string, data: RequestDataI) =>
  async (dispatch: Dispatch<DiscountDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_DISCOUNT_LOADING,
      });

      const res = await axios.put(
        `${ADMIN_API_URL}/fee_discount/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_DISCOUNT_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Discount Type Updated Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: UPDATE_DISCOUNT_ERROR,
        payload: error.response && error.response?.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Discount Type Update Failed",
        },
      });
    } finally {
      try {
        dispatch({
          type: GET_DISCOUNT_LOADING,
        });

        const res = await axios.get(
          `${ADMIN_API_URL}/fee_discount/`,
          HeaderConfig()
        );

        dispatch({
          type: GET_DISCOUNT_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: GET_DISCOUNT_ERROR,
        });
      }
    }
  };

export const DeleteDiscount =
  (id: string) =>
  async (dispatch: Dispatch<DiscountDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_DISCOUNT_LOADING,
      });

      const res = await axios.delete(
        `${ADMIN_API_URL}/fee_discount/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_DISCOUNT_SUCCESS,
        payload: { id: id },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Discount Type Deleted Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_DISCOUNT_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Discount Type Delete Failed",
        },
      });
    }
  };
