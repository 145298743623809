import { Grid } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { GetGuardianByID } from "../../../../actions/Student/Guardian/GuardianAction";
import { RootStore } from "../../../../store";
import CardLayout from "../../../Reusable/Layouts/CardLayout";
import SubModuleLayout from "../../../Reusable/Layouts/SubModuleLayout";
import getFullName from "../../../utils/getFullName";
import StudentSidebar from "../../StudentSidebar/StudentSidebar";
import GuardianTab from "./GuardianTab";

const GuardianProfile = () => {
  const nav = <StudentSidebar guardianDetails />;
  const { guardianID } = useParams<{ guardianID: string }>();
  const dispatch = useDispatch();

  React.useEffect(() => {
    guardianID != undefined && dispatch(GetGuardianByID(guardianID));
  }, []);

  const guardianState = useSelector(
    (state: RootStore) => state.guardians.guardian_by_id
  );

  const headData = [
    { id: 1, headCells: "Address::", cellData: guardianState?.address },
    {
      id: 2,
      headCells: "Email:",
      cellData: guardianState?.guardian_user?.email,
    },
    {
      id: 3,
      headCells: "Phone No.:",
      cellData: guardianState?.phone_number ? guardianState?.phone_number : "-",
    },
    {
      id: 4,
      headCells: "Occupation:",
      cellData: guardianState?.occupation ? guardianState.occupation : "-",
    },
  ];

  return (
    <>
      <SubModuleLayout studentDetails sideNav={nav}>
        <Grid container>
          <Grid item md={3}>
            <CardLayout
              profileImg={guardianState?.photo}
              fullName={getFullName(
                guardianState?.guardian_user?.first_name,
                guardianState?.guardian_user?.last_name
              )}
              headData={headData}
            />
          </Grid>
          <Grid item md={9}>
            <GuardianTab />
          </Grid>
        </Grid>
      </SubModuleLayout>
    </>
  );
};

export default GuardianProfile;
