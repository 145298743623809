// ---------------- <START> module import ------------------//
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import {
  AddDesignation,
  UpdateDesignation,
} from "../../../actions/HumanResource/Designation/DesignationAction";
import FormLayout from "../../Reusable/Layouts/Form.Layout";
import CustomizedTextField from "../../Reusable/Inputs/TextField";
import { DesignationTypeI } from "../../../actions/HumanResource/Designation/DesignationActionTypes";
// ---------------- <END> module import ------------------//

// ---------------- <START> Interface ------------------//
interface DesignationFormProps {
  editData: DesignationTypeI | null;
}
// ---------------- <END> Interface ------------------//

// ---------------- <START> Designation Form Component ------------------//
const DesignationForm = (props: DesignationFormProps) => {
  const { editData } = props;
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue, reset, errors } = useForm({
    mode: "onChange",
  });

  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [editID, setEditID] = useState<string | null>(null);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);

  const designationLoading: boolean = useSelector(
    (state: RootStore) => state.designation.loading
  );
  const add_or_update = useSelector((state: RootStore) => state.designation.add_or_update)

  useEffect(() => {
    if (add_or_update) {
      setIsBtnLoading(false)
      handleReset();
    }
  }, [add_or_update])

  useEffect(() => {
    setLoading(designationLoading);
  }, [designationLoading]);

  useEffect(() => {
    editData != null && handleEditTableData(editData);
  }, [editData]);

  const handleEditTableData = (data: DesignationTypeI) => {
    setEditMode(true);
    setValue("name", data.name);
    setEditID(data.id);
  };

  const onSubmit = (data: DesignationTypeI) => {
    setIsBtnLoading(true);
    if (editMode) {
      editID != null && dispatch(UpdateDesignation(editID, data));
    } else {
      dispatch(AddDesignation(data));
    }
  };

  const handleReset = () => {
    reset();
    setEditMode(false);
    setEditID(null);
  };

  return (
    <FormLayout
      title="Add Designation"
      onSubmit={handleSubmit(onSubmit)}
      editMode={editMode}
      loading={loading}
      onClick={!isBtnLoading && handleReset}
      add_or_update={isBtnLoading}
    >
      <CustomizedTextField
        label="Name"
        placeholder="Label"
        name="name"
        required
        error={errors["name"] ? "Required Field." : ""}
        inputRef={register({ required: true })}
      />
    </FormLayout>
  );
};
// ---------------- <END> Designation Form Component ------------------//

export default DesignationForm;
