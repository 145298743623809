import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from '../../store';
import { Link } from "react-router-dom";
import { Grid, Typography, Button } from "@material-ui/core";
import { GetApplyRubricCategory } from "../../actions/Academics/AssignRubric/AssignRubricAction"
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";
import { AssignRubricTable, AcademicSidebar } from "../../components";
import { getRubricSettings } from '../../rtk/features/miscellaneous/rubricSettingsApi';
import AddNewButton from "../../components/Reusable/Buttons/AddNewButton"

const AssignRubricPage = () => {
    const dispatch = useDispatch()
    const [formData, setFormData] = useState<any>(null);
    const [count, setCount] = useState<number>(0);
    const [edit, setEdit] = useState<any>();
    const [showRubric, setShowRubric] = useState<boolean>(false);
    const { rubricSettings } = useSelector((state: RootStore) => state.rubricSettings)

    useEffect(() => {
        dispatch(GetApplyRubricCategory())
        dispatch(getRubricSettings())
    }, [])
    useEffect(() => {
        rubricSettings.add_by !== null && rubricSettings.add_on !== null && setShowRubric(true)
    }, [rubricSettings])

    const fetchEditData = (data: any) => {
        setFormData({ ...data, count });
        setCount(count + 1);
    };
    const onEditMode = (value: boolean) => {
        setEdit({ value, count });
    };

    const nav = <AcademicSidebar assignRubric />;
    return (
        <SubModuleLayout academicMenuActive sideNav={nav}>
            {showRubric ? <AssignRubricTable /> : (
                <Grid container>
                    <Grid item style={{ margin: "150px auto 0" }}>
                        <Typography>Rubric isn't enabled. Please enable rubric to assign rubric.</Typography>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            component={Link}
                            to="/miscellaneous"
                            style={{ width: "auto", marginTop: "16px" }}
                        >
                            Enable Rubric
                        </Button>
                    </Grid>
                </Grid>
            )}
        </SubModuleLayout>
    )
}

export default AssignRubricPage