// ---------------- <START> module import ------------------//
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import FormLayout from "../../Reusable/Layouts/Form.Layout";
import CustomizedTextField from "../../Reusable/Inputs/TextField";
import { DesignationTypeI } from "../../../actions/HumanResource/Designation/DesignationActionTypes";
import {
  AddEnoteCategory,
  UpdateEnoteCategory,
} from "../../../actions/Academics/EnoteCategory/EnoteCategoryAction";
import { EnoteCategoryTypeI } from "../../../actions/Academics/EnoteCategory/EnoteCategoryActionTypes";
// ---------------- <END> module import ------------------//

// ---------------- <START> Interface ------------------//
interface EnoteCategoryFormProps {
  editData: EnoteCategoryTypeI | null;
  onEditMode: (value: boolean) => void;
}
// ---------------- <END> Interface ------------------//

// ---------------- <START> Enote Category Form Component ------------------//
const EnoteCategoryForm = (props: EnoteCategoryFormProps) => {
  const { editData, onEditMode } = props;
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue, reset, errors } = useForm({
    mode: "onChange",
  });

  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [editID, setEditID] = useState<string | null>(null);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);

  const enoteCategoryLoading: boolean = useSelector(
    (state: RootStore) => state.enote_category.loading
  );
  const add_or_update = useSelector((state: RootStore) => state.enote_category.add_or_update)

  useEffect(() => {
    add_or_update && setIsBtnLoading(false)
    setEditMode(false);
    setEditID(null);
  }, [add_or_update])


  useEffect(() => {
    setLoading(enoteCategoryLoading);
  }, [enoteCategoryLoading]);

  useEffect(() => {
    editData != null && handleEditTableData(editData);
  }, [editData]);

  const handleEditTableData = (data: EnoteCategoryTypeI) => {
    setEditMode(true);
    setValue("name", data.name);
    setValue("description", data.description);
    setEditID(data.id);
  };

  const onSubmit = (data: EnoteCategoryTypeI) => {
    setIsBtnLoading(true);
    if (editMode) {
      editID != null && dispatch(UpdateEnoteCategory(editID, data));
    } else {
      dispatch(AddEnoteCategory(data));
    }
    handleReset();
  };

  const handleReset = () => {
    reset();
    setEditMode(false);
    onEditMode(false);
    setEditID(null);
  };

  return (
    <FormLayout
      title="Add Enote Category"
      onSubmit={handleSubmit(onSubmit)}
      editMode={editMode}
      loading={loading}
      onClick={!isBtnLoading && handleReset}
      add_or_update={isBtnLoading}
    >
      <CustomizedTextField
        label="Name"
        placeholder="Label"
        name="name"
        required
        error={errors["name"] ? "Required Field." : ""}
        inputRef={register({ required: true })}
      />

      <CustomizedTextField
        label="Description"
        placeholder="Label"
        name="description"
        multiline
        rows={3}
        required
        error={errors["desc"] ? "Required Field." : ""}
        inputRef={register({ required: true })}
      />
    </FormLayout>
  );
};
// ---------------- <END> Enote Category Form Component ------------------//

export default EnoteCategoryForm;
