import {
  GET_GRADE_FEE_LOADING,
  GET_GRADE_FEE_ERROR,
  GET_GRADE_FEE_SUCCESS,
  FeeDispatchTypes,
  POST_GRADE_FEE_LOADING,
  POST_GRADE_FEE_ERROR,
  POST_GRADE_FEE_SUCCESS,
  FeeTypeI,
  GradeFeesI,
  GET_STUDENT_FEE_LOADING,
  GET_STUDENT_FEE_ERROR,
  GET_STUDENT_FEE_SUCCESS,
  APPLY_FEES_TO_STUDENTS_LOADING,
  APPLY_FEES_TO_STUDENTS_SUCCESS,
  APPLY_FEES_TO_STUDENTS_ERROR,
} from "../../actions/Fees/FeeType/FeeActionTypes";

interface InitialStateI {
  loading: boolean;
  stLoading: boolean;
  gradeFees: GradeFeesI[];
  fee: FeeTypeI[];
  stFee: Array<Object>;
  errors: any;
  recent?: boolean;
  actionPerformed: boolean;
  add_or_update: boolean;
}

const initialState: InitialStateI = {
  loading: false,
  stLoading: false,
  fee: [],
  gradeFees: [],
  stFee: [],
  errors: null,
  recent: false,
  actionPerformed: false,
  add_or_update: false,
};

const FeeReducer = (
  state: InitialStateI = initialState,
  action: FeeDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_GRADE_FEE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_GRADE_FEE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_GRADE_FEE_SUCCESS:
      return {
        ...state,
        loading: false,
        gradeFees: action.payload,
        errors: null,
      };

    case POST_GRADE_FEE_LOADING:
      return {
        ...state,
        loading: true,
        actionPerformed: false,
      };

    case POST_GRADE_FEE_ERROR:
      return {
        ...state,
        loading: false,
        actionPerformed: false,
      };

    case POST_GRADE_FEE_SUCCESS:
      return {
        ...state,
        loading: false,
        actionPerformed: true,
      };

    // Student Fee
    case GET_STUDENT_FEE_LOADING:
      return {
        ...state,
        stLoading: true,
      };

    case GET_STUDENT_FEE_ERROR:
      return {
        ...state,
        stLoading: false,
      };

    case GET_STUDENT_FEE_SUCCESS:
      return {
        ...state,
        stLoading: false,
        stFee: action.payload,
      };

    case APPLY_FEES_TO_STUDENTS_LOADING:
      return {
        ...state,
        loading: true,
        actionPerformed: false,
        add_or_update: false,
      };

    case APPLY_FEES_TO_STUDENTS_ERROR:
      return {
        ...state,
        loading: false,
        actionPerformed: false,
        add_or_update: true,
      };

    case APPLY_FEES_TO_STUDENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        actionPerformed: true,
        add_or_update: true,
      };

    default:
      return state;
  }
};

export default FeeReducer;
