import {
  GET_ENOTE_CATEGORY_LOADING,
  GET_ENOTE_CATEGORY_ERROR,
  GET_ENOTE_CATEGORY_SUCCESS,
  ADD_ENOTE_CATEGORY_LOADING,
  ADD_ENOTE_CATEGORY_ERROR,
  ADD_ENOTE_CATEGORY_SUCCESS,
  DELETE_ENOTE_CATEGORY_LOADING,
  DELETE_ENOTE_CATEGORY_ERROR,
  DELETE_ENOTE_CATEGORY_SUCCESS,
  UPDATE_ENOTE_CATEGORY_LOADING,
  UPDATE_ENOTE_CATEGORY_ERROR,
  UPDATE_ENOTE_CATEGORY_SUCCESS,
  EnoteCategoryTypeI,
  EnoteCategoryDispatchTypes,
} from "../../actions/Academics/EnoteCategory/EnoteCategoryActionTypes";

interface InitialStateI {
  loading: boolean;
  enote_categories: EnoteCategoryTypeI[];
  errors?: any;
  recent?: boolean;
  add_or_update: boolean;
}

const initialState: InitialStateI = {
  loading: false,
  enote_categories: [],
  errors: null,
  recent: false,
  add_or_update: false,
};

const EnoteCategoryReducer = (
  state: InitialStateI = initialState,
  action: EnoteCategoryDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_ENOTE_CATEGORY_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_ENOTE_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_ENOTE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        enote_categories: action.payload,
        errors: null,
        recent: false,
      };

    case ADD_ENOTE_CATEGORY_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case ADD_ENOTE_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        recent: false,
        add_or_update: true,
      };

    case ADD_ENOTE_CATEGORY_SUCCESS:
      return {
        loading: false,
        enote_categories: [...state.enote_categories, action.payload],
        errors: null,
        recent: true,
        add_or_update: true,
      };

    case UPDATE_ENOTE_CATEGORY_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case UPDATE_ENOTE_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        recent: false,
        add_or_update: true,
      };

    case UPDATE_ENOTE_CATEGORY_SUCCESS:
      const current_data: EnoteCategoryTypeI[] = state.enote_categories;
      const index: number = current_data.findIndex(
        (element) => element.id === action.payload.id
      );
      current_data[index] = action.payload;

      return {
        loading: false,
        enote_categories: current_data,
        errors: null,
        recent: true,
        add_or_update: true,
      };

    case DELETE_ENOTE_CATEGORY_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_ENOTE_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_ENOTE_CATEGORY_SUCCESS:
      const new_data: EnoteCategoryTypeI[] = state.enote_categories;
      const item_index = new_data.findIndex(
        (element) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return {
        ...state,
        loading: false,
        enote_categories: new_data,
      };

    default:
      return state;
  }
};

export default EnoteCategoryReducer;
