import { Button } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp, Print } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import ReactToPrint from "react-to-print";
// import XLSX from "xlsx";
import PrintPreview from "./PrintPreview";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import { sortByRollNo } from "../../../rtk/features/exam/marksLedger/marksLedgerSlice";
import PrintPreviewCombined from "./PrintPreviewCombined";

const Header = styled.div`
  width: 100%;
  text-align: center;
  position: relative;

  padding: 10px;
`;

const Logo = styled.div`
  width: 80px;
  height: 80px;

  position: absolute;
  top: 20px;
`;

const Subheader = styled.div`
  padding: 5px 10px;
  font-weight: 500;
`;

const SubheaderRow = styled.div`
  display: flex;

  div {
    flex: 1;
  }
`;

const StudentText = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;

  display: flex;

  span {
    &:nth-child(1) {
      margin-right: 20px;
    }
  }
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 500;

  margin-top: 2px;
  margin-bottom: 5px;
  line-height: 1.1;

  text-transform: uppercase;
  color: #403c80;
`;

const Subtitle = styled.h2`
  font-size: 12px;
  font-weight: 300;
  color: #403c80;
`;

const ReportTitle = styled.h1`
  font-size: 22px;
  font-weight: 500;

  margin-top: 5px;
  margin-bottom: 10px;
  line-height: 1.1;
  text-transform: uppercase;
  color: #403c80;
`;

const ExamTitle = styled.h2`
  font-size: 12px;
  font-weight: 500;

  margin-top: 2px;
  margin-bottom: 10px;
  line-height: 1.1;
  text-transform: uppercase;
  text-decoration: underline;
`;

const Wrapper = styled.div`
  background-color: #fff;
  padding: 16px 11px 11px 16px;
  margin: 10px;
`;

const TableContainer = styled.div`
  overflow: auto;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%);
  border-radius: 4px;
  padding: 0 5px 5px 0;

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 8px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #132e98;
  }
`;

const Table = styled.table`
  width: 100%;
`;

const TableRow = styled.tr``;

const TableHead = styled.th<{ onPrint?: boolean }>`
  border: 1px solid black;
  padding: 0 8px;
  font-weight: 500;
  white-space: nowrap;
  background-color: #ececec;

  display: ${(props) => props.onPrint && "none"};
`;

const TableData = styled.td<{
  main?: boolean;
  onPrint?: boolean;
  textAlign: "left" | "center" | "right";
}>`
  border: 1px solid black;
  padding: 0 4px;
  font-weight: ${(props) => (props.main ? 500 : 400)};
  white-space: nowrap;
  background-color: ${(props) => (props.main ? "#ececec" : "#fff")};
  text-align: ${(props) => props.textAlign};

  display: ${(props) => props.onPrint && "none"};
`;

const Sortable = styled.span<{ sorted: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    display: none;
  }

  &:hover {
    span {
      display: ${(props) => (props.sorted ? "none" : "inline")};
    }
  }
`;

const ActionPanel = styled.div`
  display: flex;
  margin-top: 10px;
  gap: 10px;
`;

const SearchButton = styled(Button)`
  margin: 5px;
  min-width: 120px;
`;

interface GeneralInfoI {
  id: number;
  logo: string;
  school_name: string;
  address: string;
  phone_number: string;
  email: string;
  slogan: string;
}

const CombinedMarksLedger = () => {
  const dispatch = useDispatch();
  const printRef = useRef<HTMLTableElement | null>(null);

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const [printing, setPrinting] = useState<boolean>(false);
  const [checkedStudents, setCheckedStudents] = useState<string[]>([]);

  const { combinedLedger, sorted, searchParams } = useSelector(
    (state: RootStore) => state.marksLedger
  );
  const [generalInfo, setGeneralInfo] = useState<GeneralInfoI | null>(null);

  useEffect(() => {
    const data = localStorage.getItem("hamro_general_info");

    if (data) {
      setGeneralInfo(JSON.parse(data));
    }
  }, []);

  const handleCheckAll = (checked: boolean) => {
    if (checked) {
      if (combinedLedger?.student_marks.length) {
        setCheckedStudents(
          combinedLedger?.student_marks.map((el) => el.student)
        );
      }
    } else {
      setCheckedStudents([]);
    }
  };

  const handleCheckBox = (id: string, checked: boolean) => {
    if (checked) {
      setCheckedStudents([...checkedStudents, id]);
    } else {
      const updateChecked = checkedStudents.filter((el) => el !== id);
      setCheckedStudents(updateChecked);
    }
  };

  const handleBeforePrint = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      setPrinting(true);
      resolve();
    });
  };
  const handleAfterPrint = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      setPrinting(false);
      resolve();
    });
  };

  const returnMarks = (data: { subjectId: string; studentId: string }) => {
    if (combinedLedger) {
      const subject = combinedLedger.exam_subjects.find(
        (el) => el.subject__id === data.subjectId
      );

      const studentMarks = combinedLedger.student_marks.find(
        (el) => el.student === data.studentId
      )?.obtain_subject_marks;

      if (subject && studentMarks) {
        const subjectMarksObtained = studentMarks.find(
          (el) => el.subject_id === data.subjectId
        );

        if (subjectMarksObtained) {
          if (subject.has_practical && subjectMarksObtained.result_type === 1) {
            return (
              <>
                {combinedLedger.exam_info.map((ei, i) => {
                  const targetExam = subjectMarksObtained.terms_wise_marks.find(
                    (tw) => tw.exam_info_id === ei.exam_info_id
                  );
                  if (targetExam) {
                    return (
                      <>
                        <TableData textAlign="right" colSpan={1}>
                          {targetExam.theory_obtain_marks}
                        </TableData>
                        <TableData textAlign="right" colSpan={1}>
                          {targetExam.practical_obtain_marks}
                        </TableData>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <TableData textAlign="right" colSpan={1}>
                          -
                        </TableData>
                        <TableData textAlign="right" colSpan={1}>
                          -
                        </TableData>
                      </>
                    );
                  }
                })}
                <TableData textAlign="right" colSpan={1}>
                  {subjectMarksObtained.theory_obtain_marks || "-"}
                </TableData>
                <TableData textAlign="right" colSpan={1}>
                  {subjectMarksObtained.practical_obtain_marks || "-"}
                </TableData>
                <TableData textAlign="right" colSpan={1}>
                  {Number(subjectMarksObtained.theory_obtain_marks) +
                    Number(subjectMarksObtained.practical_obtain_marks) || 0}
                </TableData>
                <TableData textAlign="right" colSpan={1}>
                  {subjectMarksObtained.theory_obtain_marks || "-"}
                </TableData>
                <TableData textAlign="right" colSpan={1}>
                  {subjectMarksObtained.total_gpa || "-"}
                </TableData>
              </>
            );
          } else if (
            subject.has_practical &&
            subjectMarksObtained.result_type === 0
          ) {
            return (
              <>
                {combinedLedger.exam_info.map((ei, i) => {
                  const targetExam = subjectMarksObtained.terms_wise_marks.find(
                    (tw) => tw.exam_info_id === ei.exam_info_id
                  );
                  if (targetExam) {
                    return (
                      <>
                        <TableData textAlign="right" colSpan={1}>
                          {targetExam.theory_obtain_marks}
                        </TableData>
                        <TableData textAlign="right" colSpan={1}>
                          {targetExam.practical_obtain_marks}
                        </TableData>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <TableData textAlign="right" colSpan={1}>
                          -
                        </TableData>
                        <TableData textAlign="right" colSpan={1}>
                          -
                        </TableData>
                      </>
                    );
                  }
                })}
                <TableData textAlign="right" colSpan={1}>
                  {subjectMarksObtained.theory_obtain_grade || "-"}
                </TableData>
                <TableData textAlign="right" colSpan={1}>
                  {subjectMarksObtained.practical_obtain_grade || "-"}
                </TableData>
                <TableData textAlign="right" colSpan={1}>
                  {subjectMarksObtained.theory_obtain_marks || "-"}
                </TableData>
                <TableData textAlign="right" colSpan={1}>
                  {subjectMarksObtained.total_gpa || "-"}
                </TableData>
              </>
            );
          } else if (
            !subject.has_practical &&
            subjectMarksObtained.result_type === 1
          ) {
            return (
              <>
                {combinedLedger.exam_info.map((ei, i) => {
                  const targetExam = subjectMarksObtained.terms_wise_marks.find(
                    (tw) => tw.exam_info_id === ei.exam_info_id
                  );
                  if (targetExam) {
                    return (
                      <>
                        <TableData textAlign="right" colSpan={1}>
                          {targetExam.theory_obtain_marks}
                        </TableData>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <TableData textAlign="right" colSpan={1}>
                          -
                        </TableData>
                      </>
                    );
                  }
                })}
                <TableData textAlign="right" colSpan={1}>
                  {subjectMarksObtained.theory_obtain_marks || "-"}
                </TableData>
                <TableData textAlign="right" colSpan={1}>
                  {subjectMarksObtained.theory_obtain_grade || "-"}
                </TableData>
                <TableData textAlign="right" colSpan={1}>
                  {subjectMarksObtained.total_gpa || "-"}
                </TableData>
              </>
            );
          } else {
            return (
              <>
                {combinedLedger.exam_info.map((ei, i) => {
                  const targetExam = subjectMarksObtained.terms_wise_marks.find(
                    (tw) => tw.exam_info_id === ei.exam_info_id
                  );
                  if (targetExam) {
                    return (
                      <>
                        <TableData textAlign="right" colSpan={1}>
                          {targetExam.theory_obtain_marks}
                        </TableData>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <TableData textAlign="right" colSpan={1}>
                          -
                        </TableData>
                      </>
                    );
                  }
                })}
                <TableData textAlign="right" colSpan={1}>
                  {subjectMarksObtained.theory_obtain_grade || "-"}
                </TableData>
                <TableData textAlign="right" colSpan={1}>
                  {subjectMarksObtained.total_gpa || "-"}
                </TableData>
              </>
            );
          }
        } else {
          if (subject.has_practical && subject.result_type === 1) {
            return (
              <>
                {combinedLedger.exam_info.map((ei, i) => (
                  <>
                    <TableData textAlign="right" colSpan={1}>
                      -
                    </TableData>
                    <TableData textAlign="right" colSpan={1}>
                      -
                    </TableData>
                  </>
                ))}
                <TableData textAlign="right" colSpan={1}>
                  -
                </TableData>
                <TableData textAlign="right" colSpan={1}>
                  -
                </TableData>
                <TableData textAlign="right" colSpan={1}>
                  -
                </TableData>
                <TableData textAlign="right" colSpan={1}>
                  -
                </TableData>
                <TableData textAlign="right" colSpan={1}>
                  -
                </TableData>
              </>
            );
          } else if (subject.has_practical && subject.result_type === 0) {
            return (
              <>
                {combinedLedger.exam_info.map((ei, i) => (
                  <>
                    <TableData textAlign="right" colSpan={1}>
                      -
                    </TableData>
                    <TableData textAlign="right" colSpan={1}>
                      -
                    </TableData>
                  </>
                ))}
                <TableData textAlign="right" colSpan={1}>
                  -
                </TableData>
                <TableData textAlign="right" colSpan={1}>
                  -
                </TableData>
                <TableData textAlign="right" colSpan={1}>
                  -
                </TableData>
              </>
            );
          } else if (!subject.has_practical && subject.result_type === 1) {
            return (
              <>
                {combinedLedger.exam_info.map((ei, i) => (
                  <>
                    <TableData textAlign="right" colSpan={1}>
                      -
                    </TableData>
                  </>
                ))}
                <TableData textAlign="right" colSpan={1}>
                  -
                </TableData>
                <TableData textAlign="right" colSpan={1}>
                  -
                </TableData>
                <TableData textAlign="right" colSpan={1}>
                  -
                </TableData>
              </>
            );
          } else {
            return (
              <>
                {combinedLedger.exam_info.map((ei, i) => (
                  <>
                    <TableData textAlign="right" colSpan={1}>
                      -
                    </TableData>
                  </>
                ))}
                <TableData textAlign="right" colSpan={1}>
                  -
                </TableData>
                <TableData textAlign="right" colSpan={1}>
                  -
                </TableData>
              </>
            );
          }
        }
      }
    }
    return (
      <>
        <TableData textAlign="right" colSpan={1}>
          -
        </TableData>
      </>
    );
  };

  const handleExportExcel = () => {
    // const SheetNames: string[] = [];
    // const Sheets = {};
    // const tableEl = document.getElementById("export-table")!;
    // const wb = { SheetNames, Sheets };
    // const ws9 = XLSX.utils.table_to_sheet(tableEl, { raw: true });
    // // Get total number of columns
    // // const totalColumns = tableEl.querySelectorAll("th").length;
    // // Hide last columns ~ actions buttons
    // ws9["!cols"] = [];
    // ws9["!cols"][1] = { hidden: true };
    // wb.SheetNames.push("Excel Sheet");
    // wb.Sheets["Excel Sheet"] = ws9;
    // XLSX.writeFile(wb, `Ledger.xlsx`, { cellStyles: true });
  };

  const allChecked =
    checkedStudents.length === combinedLedger?.student_marks.length;

  return (
    <>
      {combinedLedger ? (
        <>
          <Wrapper>
            <ReactToPrint
              trigger={() => (
                <p
                  style={{
                    backgroundColor: "#132E98",
                    color: "white",
                    fontWeight: "bold",
                    borderRadius: "0px",
                    margin: "0 0 5px 0",
                    textAlign: "center",
                    padding: "2px 0px",
                    cursor: "pointer",
                    width: "100%",
                  }}
                >
                  Print Ledger
                </p>
              )}
              content={() => printRef.current}
              onBeforeGetContent={() => handleBeforePrint()}
              onAfterPrint={() => handleAfterPrint()}
              removeAfterPrint={true}
              copyStyles={true}
            />
            <TableContainer>
              <Header>
                <Title>{generalInfo?.school_name || ""}</Title>
                <Subtitle>{generalInfo?.address || ""}</Subtitle>
                <ReportTitle>{searchParams.exam}</ReportTitle>
                <Logo>
                  {generalInfo?.logo ? (
                    <img src={generalInfo.logo} width={80} height={80} />
                  ) : null}
                </Logo>
              </Header>
              <div ref={printRef}>
                <Subheader>
                  <SubheaderRow>
                    <div>
                      <StudentText>
                        <span>Class:</span>
                        <span>
                          {searchParams.grade}{" "}
                          {searchParams.section
                            ? `- ${searchParams.section}`
                            : ""}
                        </span>
                      </StudentText>
                    </div>
                  </SubheaderRow>
                </Subheader>
                <Table id="export-table">
                  {/* Subjects Row */}
                  <TableRow>
                    <TableHead>Sn</TableHead>
                    <TableHead onPrint={printing}>Print</TableHead>
                    <TableHead>Name of Student</TableHead>
                    <TableHead>
                      <Sortable
                        sorted={sorted !== ""}
                        onClick={() => dispatch(sortByRollNo())}
                      >
                        Roll No
                        <span>
                          {sorted === "" ? <KeyboardArrowUp /> : null}
                        </span>
                        {sorted === "asc" ? (
                          <KeyboardArrowUp />
                        ) : sorted === "des" ? (
                          <KeyboardArrowDown />
                        ) : null}
                      </Sortable>
                    </TableHead>

                    {combinedLedger.exam_subjects.map((el, index) => {
                      if (el.has_practical && el.result_type === 1) {
                        return (
                          <TableHead
                            key={index + 1}
                            colSpan={5 + combinedLedger.exam_info.length * 2}
                          >
                            {el.subject__name}
                          </TableHead>
                        );
                      } else if (el.has_practical && el.result_type === 0) {
                        return (
                          <TableHead
                            key={index + 1}
                            colSpan={4 + combinedLedger.exam_info.length * 2}
                          >
                            {el.subject__name}
                          </TableHead>
                        );
                      } else if (!el.has_practical && el.result_type === 1) {
                        return (
                          <TableHead
                            key={index + 1}
                            colSpan={3 + combinedLedger.exam_info.length}
                          >
                            {el.subject__name}
                          </TableHead>
                        );
                      } else {
                        return (
                          <TableHead
                            key={index + 1}
                            colSpan={2 + combinedLedger.exam_info.length}
                          >
                            {el.subject__name}
                          </TableHead>
                        );
                      }
                    })}

                    <TableHead>Total</TableHead>
                    <TableHead>%</TableHead>
                    <TableHead>GPA</TableHead>
                    <TableHead>Div/Grade</TableHead>
                    <TableHead>Rank</TableHead>
                    <TableHead>Grace</TableHead>
                    <TableHead>Att.</TableHead>
                    <TableHead>Remarks</TableHead>
                  </TableRow>

                  {/* Exams Row */}
                  <TableRow>
                    <TableHead></TableHead>
                    <TableHead></TableHead>
                    <TableHead></TableHead>
                    <TableHead></TableHead>

                    {combinedLedger.exam_subjects.map((el, index) => {
                      if (el.has_practical && el.result_type === 1) {
                        return (
                          <>
                            {combinedLedger.exam_info.map((ei, ind) => (
                              <TableHead key={ind + 1} colSpan={2}>
                                {`${ei.exam_id} (${ei.weightage_percentage}%)`}
                              </TableHead>
                            ))}
                            <TableHead key={index + 1} colSpan={5}>
                              Final
                            </TableHead>
                          </>
                        );
                      } else if (el.has_practical && el.result_type === 0) {
                        return (
                          <>
                            {combinedLedger.exam_info.map((ei, ind) => (
                              <TableHead key={ind + 1} colSpan={2}>
                                {`${ei.exam_id} (${ei.weightage_percentage}%)`}
                              </TableHead>
                            ))}
                            <TableHead key={index + 1} colSpan={4}>
                              Final
                            </TableHead>
                          </>
                        );
                      } else if (!el.has_practical && el.result_type === 1) {
                        return (
                          <>
                            {combinedLedger.exam_info.map((ei, ind) => (
                              <TableHead key={ind + 1} colSpan={1}>
                                {`${ei.exam_id} (${ei.weightage_percentage}%)`}
                              </TableHead>
                            ))}
                            <TableHead key={index + 1} colSpan={3}>
                              Final
                            </TableHead>
                          </>
                        );
                      } else {
                        return (
                          <>
                            {combinedLedger.exam_info.map((ei, ind) => (
                              <TableHead key={ind + 1} colSpan={1}>
                                {`${ei.exam_id} (${ei.weightage_percentage}%)`}
                              </TableHead>
                            ))}
                            <TableHead key={index + 1} colSpan={2}>
                              Final
                            </TableHead>
                          </>
                        );
                      }
                    })}

                    <TableHead></TableHead>
                    <TableHead></TableHead>
                    <TableHead></TableHead>
                    <TableHead></TableHead>
                    <TableHead></TableHead>
                    <TableHead></TableHead>
                    <TableHead></TableHead>
                    <TableHead></TableHead>
                  </TableRow>

                  {/* Markings Row */}
                  <TableRow>
                    <TableHead></TableHead>
                    <TableHead onPrint={printing}>
                      <input
                        type="checkbox"
                        checked={allChecked}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleCheckAll(event.target.checked)}
                      />
                    </TableHead>
                    <TableHead></TableHead>
                    <TableHead></TableHead>

                    {combinedLedger.exam_subjects.map((el) => {
                      if (el.has_practical && el.result_type === 1) {
                        return (
                          <>
                            {combinedLedger.exam_info.map((ei, ind) => (
                              <>
                                <TableHead key={ind + 1}>TH</TableHead>
                                <TableHead key={ind + 1}>PR</TableHead>
                              </>
                            ))}
                            <TableHead colSpan={1}>TH</TableHead>
                            <TableHead colSpan={1}>PR</TableHead>
                            <TableHead colSpan={1}>Total</TableHead>
                            <TableHead colSpan={1}>Grade</TableHead>
                            <TableHead colSpan={1}>Point</TableHead>
                          </>
                        );
                      } else if (el.has_practical && el.result_type === 0) {
                        return (
                          <>
                            {combinedLedger.exam_info.map((ei, ind) => (
                              <>
                                <TableHead key={ind + 1}>TH</TableHead>
                                <TableHead key={ind + 1}>PR</TableHead>
                              </>
                            ))}
                            <TableHead colSpan={1}>TH</TableHead>
                            <TableHead colSpan={1}>PR</TableHead>
                            <TableHead colSpan={1}>Total</TableHead>
                            <TableHead colSpan={1}>Point</TableHead>
                          </>
                        );
                      } else if (!el.has_practical && el.result_type === 1) {
                        return (
                          <>
                            {combinedLedger.exam_info.map((ei, ind) => (
                              <>
                                <TableHead key={ind + 1}>TH</TableHead>
                              </>
                            ))}
                            <TableHead colSpan={1}>TH</TableHead>
                            <TableHead colSpan={1}>Grade</TableHead>
                            <TableHead colSpan={1}>Point</TableHead>
                          </>
                        );
                      } else {
                        return (
                          <>
                            {combinedLedger.exam_info.map((ei, ind) => (
                              <>
                                <TableHead key={ind + 1}>TH</TableHead>
                              </>
                            ))}
                            <TableHead>TH</TableHead>
                            <TableHead>Point</TableHead>
                          </>
                        );
                      }
                    })}

                    <TableHead></TableHead>
                    <TableHead></TableHead>
                    <TableHead></TableHead>
                    <TableHead></TableHead>
                    <TableHead></TableHead>
                    <TableHead></TableHead>
                    <TableHead></TableHead>
                    <TableHead></TableHead>
                  </TableRow>

                  {/* Full Marks Row */}
                  <TableRow>
                    <TableHead></TableHead>
                    <TableHead onPrint={printing}></TableHead>
                    <TableHead>Full Marks</TableHead>
                    <TableHead></TableHead>

                    {combinedLedger.exam_subjects.map((el) => {
                      if (el.has_practical && el.result_type === 1) {
                        return (
                          <>
                            {combinedLedger.exam_info.map((ei, ind) => (
                              <>
                                <TableHead key={ind + 1}></TableHead>
                                <TableHead key={ind + 1}></TableHead>
                              </>
                            ))}
                            <TableHead colSpan={1}>
                              {el.full_marks_theory}
                            </TableHead>
                            <TableHead colSpan={1}>
                              {el.full_marks_practical}
                            </TableHead>
                            <TableHead colSpan={1}>
                              {Number(el.full_marks_theory) +
                                Number(el.full_marks_practical) || 0}
                            </TableHead>
                            <TableHead></TableHead>
                            <TableHead></TableHead>
                          </>
                        );
                      } else if (el.has_practical && el.result_type === 0) {
                        return (
                          <>
                            {combinedLedger.exam_info.map((ei, ind) => (
                              <>
                                <TableHead key={ind + 1}></TableHead>
                                <TableHead key={ind + 1}></TableHead>
                              </>
                            ))}
                            <TableHead></TableHead>
                            <TableHead></TableHead>
                            <TableHead></TableHead>
                            <TableHead></TableHead>
                          </>
                        );
                      } else if (!el.has_practical && el.result_type === 1) {
                        return (
                          <>
                            {combinedLedger.exam_info.map((ei, ind) => (
                              <>
                                <TableHead key={ind + 1}></TableHead>
                              </>
                            ))}
                            <TableHead>{el.full_marks_theory}</TableHead>
                            <TableHead></TableHead>
                            <TableHead></TableHead>
                          </>
                        );
                      } else {
                        return (
                          <>
                            {combinedLedger.exam_info.map((ei, ind) => (
                              <>
                                <TableHead key={ind + 1}></TableHead>
                              </>
                            ))}
                            <TableHead></TableHead>
                            <TableHead></TableHead>
                          </>
                        );
                      }
                    })}

                    <TableHead>-</TableHead>
                    <TableHead></TableHead>
                    <TableHead></TableHead>
                    <TableHead></TableHead>
                    <TableHead></TableHead>
                    <TableHead></TableHead>
                    <TableHead></TableHead>
                    <TableHead></TableHead>
                  </TableRow>

                  {/* Pass Marks Row */}
                  <TableRow>
                    <TableHead></TableHead>
                    <TableHead onPrint={printing}></TableHead>
                    <TableHead>Pass Marks</TableHead>
                    <TableHead></TableHead>

                    {combinedLedger.exam_subjects.map((el) => {
                      if (el.has_practical && el.result_type === 1) {
                        return (
                          <>
                            {combinedLedger.exam_info.map((ei, ind) => (
                              <>
                                <TableHead key={ind + 1}></TableHead>
                                <TableHead key={ind + 1}></TableHead>
                              </>
                            ))}
                            <TableHead colSpan={1}>
                              {el.pass_marks_theory}
                            </TableHead>
                            <TableHead colSpan={1}>
                              {el.pass_marks_practical}
                            </TableHead>
                            <TableHead colSpan={1}>
                              {Number(el.pass_marks_theory) +
                                Number(el.pass_marks_practical) || 0}
                            </TableHead>
                            <TableHead></TableHead>
                            <TableHead></TableHead>
                          </>
                        );
                      } else if (el.has_practical && el.result_type === 0) {
                        return (
                          <>
                            {combinedLedger.exam_info.map((ei, ind) => (
                              <>
                                <TableHead key={ind + 1}></TableHead>
                                <TableHead key={ind + 1}></TableHead>
                              </>
                            ))}
                            <TableHead></TableHead>
                            <TableHead></TableHead>
                            <TableHead></TableHead>
                            <TableHead></TableHead>
                          </>
                        );
                      } else if (!el.has_practical && el.result_type === 1) {
                        return (
                          <>
                            {combinedLedger.exam_info.map((ei, ind) => (
                              <>
                                <TableHead key={ind + 1}></TableHead>
                              </>
                            ))}
                            <TableHead>{el.pass_marks_theory}</TableHead>
                            <TableHead></TableHead>
                            <TableHead></TableHead>
                          </>
                        );
                      } else {
                        return (
                          <>
                            {combinedLedger.exam_info.map((ei, ind) => (
                              <>
                                <TableHead key={ind + 1}></TableHead>
                              </>
                            ))}
                            <TableHead></TableHead>
                            <TableHead></TableHead>
                          </>
                        );
                      }
                    })}

                    <TableHead>-</TableHead>
                    <TableHead></TableHead>
                    <TableHead></TableHead>
                    <TableHead></TableHead>
                    <TableHead></TableHead>
                    <TableHead></TableHead>
                    <TableHead></TableHead>
                    <TableHead></TableHead>
                  </TableRow>

                  {combinedLedger.exam_subjects.length
                    ? combinedLedger.student_marks.map((el, index) => {
                        return (
                          <>
                            <TableRow>
                              <TableData textAlign="center" main>
                                {index + 1}
                              </TableData>
                              <TableData
                                textAlign="center"
                                main
                                onPrint={printing}
                              >
                                <input
                                  type="checkbox"
                                  checked={checkedStudents.includes(el.student)}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) =>
                                    handleCheckBox(el.student, e.target.checked)
                                  }
                                />
                              </TableData>
                              <TableData textAlign="left" main>
                                {el.student_first_name +
                                  " " +
                                  el.student_last_name}
                              </TableData>
                              <TableData textAlign="right" main>
                                0
                              </TableData>
                              {combinedLedger.exam_subjects.map((it) => {
                                return returnMarks({
                                  studentId: el.student,
                                  subjectId: it.subject__id,
                                });
                              })}
                              <TableData textAlign="right">
                                {el.total_marks_obtained}
                              </TableData>
                              <TableData textAlign="right">
                                {el.obtain_percentage}
                              </TableData>
                              <TableData textAlign="right">
                                {el.obtain_gpa}
                              </TableData>
                              <TableData textAlign="left">
                                {el.obtain_grade}
                              </TableData>
                              <TableData textAlign="right">-</TableData>
                              <TableData textAlign="right">-</TableData>
                              <TableData textAlign="right">-</TableData>
                              <TableData textAlign="left">Good</TableData>
                            </TableRow>
                          </>
                        );
                      })
                    : null}
                </Table>
              </div>
            </TableContainer>
            <ActionPanel>
              <SearchButton
                color="primary"
                variant="contained"
                startIcon={<Print />}
                onClick={() => setModalOpen(true)}
                disabled={!Boolean(checkedStudents.length)}
              >
                Print Gradesheet
              </SearchButton>

              <SearchButton
                color="primary"
                variant="contained"
                startIcon={<InsertDriveFileIcon />}
                onClick={() => handleExportExcel()}
              >
                Export
              </SearchButton>
            </ActionPanel>
          </Wrapper>
          <PrintPreviewCombined
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            studentId={checkedStudents}
          />
        </>
      ) : null}
    </>
  );
};

export default CombinedMarksLedger;
