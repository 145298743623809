//---------------------------- <START> module imports start ----------------------------------//
import React, { useState, useEffect } from 'react';
import { Grid, Paper, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import { CharacterCertificateSampleData } from './CharacterCertificateSampleData';

// ----------------------------<END> module imports end ----------------------------------//

const style = makeStyles({
  btnRight: {
    color: 'white',
    top: '50%',
    float: 'right',
    position: 'relative',
    transform: 'translateY(-50%)',
  },
  title: {
    fontSize: '17px',
  },
  cardtitle: {
    fontSize: '15px',
  },
  card: {
    // maxWidth: 345,
    padding: 7,
    border: '1px solid #c5d8dd',
    '&:hover': {
      border: '1px solid blue',
      transform: 'scale(1.009)',
    },
  },
  smallbtn: {
    backgroundColor: '#a7a4a444',
    marginLeft: '5px',
    marginRight: '5px',
    '&:hover': {
      backgroundColor: '#64A1C2',
      color: 'white',
    },
  },
  smallbtnActive: {
    backgroundColor: 'blue',
    marginLeft: '5px',
    marginRight: '5px',
    color: 'white',
    '&:hover': {
      backgroundColor: '#64A1C2',
      color: 'white',
    },
  },
});

const BrowseCharacterCertificate: React.FC = () => {
  const [cardID, setCardID] = useState<number | null>(null);
  const [clicked, setClicked] = useState<boolean>(false);
  const [mode, setMode] = useState<string>('landscape');

  const classes = style();
  const history = useHistory();

  useEffect(() => {
    setMode('potrait');
  }, []);

  const handleCardSelect = (val: number) => {
    setCardID(val);
    setClicked(true);
  };

  if (clicked && cardID != null) {
    history.push(`/character-certificate/${cardID}/customize`);
  }

  return (
    <>
      <Paper style={{ margin: '30px', padding: '20px' }}>
        <Grid container spacing={3} justifyContent="space-between">
          <Grid item xs={6} sm={6}>
            <span className={classes.title}>
              Browse Character Certificate Samples, and Start Customizing
            </span>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Button
              variant="contained"
              onClick={() => history.push('/character-certificate-templates')}
            >
              Go Back to My List
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: '10px' }}>
          {CharacterCertificateSampleData.map((item, index) => {
            return (
              <Grid
                item
                xs={6}
                key={index}
                onClick={() => handleCardSelect(item.id)}
              >
                <Card className={classes.card}>
                  <CardActionArea>
                    <img
                      src={item.thumbnail}
                      style={{ height: '450px', width: '100%' }}
                    />
                  </CardActionArea>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Paper>
    </>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//
export default BrowseCharacterCertificate;
