import {
  Button,
  Divider,
  Grid,
  Input,
  InputLabel,
  Paper,
  Typography,
  TextField,
  LinearProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
} from "@material-ui/core";
import { AddCircle, CloudUpload, Send } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import SubModuleLayout from "../../../Reusable/Layouts/SubModuleLayout";
import StudentSidebar from "../../StudentSidebar/StudentSidebar";
import { useStyles } from "../../../Styles/FormStyles";
import { Autocomplete } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { GetClasses } from "../../../../actions/Academics/Class/ClassAction";
import { RootStore } from "../../../../store";
import { useForm } from "react-hook-form";
import { UploadStudentInfo } from "../../../../actions/Student/Student/StudentAction";
import { setSnackbar } from "../../../../actions/SnackbarAction";
import ErrorIcon from "@material-ui/icons/Error";
import HelpIcon from "@material-ui/icons/Help";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import Papa from "papaparse";
import {
  addStudentsAction,
  removeStudentRowAction,
  updateStudentRowAction,
} from "../../../../rtk/features/import/studentImportSlices";
import {
  importStudentData,
  importUserValidation,
} from "../../../../rtk/features/import/userImportApi";
import BackDropLoader from "../../../Reusable/BackDropLoader";
import CustomizedDialogs from "../../../Reusable/Dialogs/DeleteDialog";

const NotValidIcon = styled(ErrorIcon)`
  color: red;

  && {
    width: 20px;
    height: 20px;
  }
`;

const NoValidityIcon = styled(HelpIcon)`
  color: #e6b411;

  && {
    width: 20px;
    height: 20px;
  }
`;

const ValidIcon = styled(CheckCircleIcon)`
  color: #1ce002;

  && {
    width: 20px;
    height: 20px;
  }
`;

const CellInput = styled.input`
  && {
    padding: 5px 10px;
    border-radius: 3px;
    border: 1px solid #000;

    &:focus {
      outline: none;
    }
  }
`;

const RemoveIcon = styled(DeleteIcon)`
  color: red;

  && {
    width: 20px;
    height: 20px;
  }
`;

interface SectionI {
  id: string;
  title: string;
}

interface ClassI {
  id: string;
  title: string;
  sections: SectionI[] | [];
}

function ImportStudentInfo() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const nav = <StudentSidebar studentDetails />;

  const [className, setClassName] = useState<ClassI | null>(null);
  const [section, setSection] = useState<SectionI | null>(null);
  const [sectionChoices, setSectionChoices] = useState<SectionI[] | []>([]);
  const [sectionDisabler, setSectionDisabler] = useState<boolean>(true);
  const [document, setDocument] = useState<any>(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);

  const { register, handleSubmit, errors, setValue, clearErrors } = useForm();
  const { students, loading, valid, imported } = useSelector(
    (state: RootStore) => state.studentImport
  );

  useEffect(() => {
    dispatch(GetClasses());
    dispatch(addStudentsAction([]));
  }, []);

  useEffect(() => {
    if (imported) {
      setConfirmModalOpen(false);
      dispatch(addStudentsAction([]));
      setSection(null);
      setClassName(null);
      setSectionDisabler(true);
    }
  }, [imported]);

  const classState = useSelector((state: RootStore) => state.class);
  const sessionState = useSelector((state: RootStore) => state.session);
  const stduentInfoUploadState: any = useSelector(
    (state: RootStore) => state.student.student_info_upload
  );

  const classChoices: any = classState.classes.map((classItem, index) => ({
    id: classItem.grade,
    title: classItem.grade_name,
    sections: classItem.section.map((sectionItem) => ({
      id: sectionItem.id,
      title: sectionItem.name,
    })),
  }));

  const handleClassChange = (value: ClassI | null) => {
    setClassName(value);
    setSection(null);
    value != null
      ? populateSectionChoices(value.sections)
      : setSectionDisabler(true);
  };

  const handleSectionChange = (value: any | null) => {
    setSection(value);
  };

  const populateSectionChoices = (sections: SectionI[] | []) => {
    setSectionDisabler(false);
    sections.length > 0
      ? setSectionChoices(sections)
      : setSectionDisabler(true);
  };

  const handleImportData = () => {
    if (className) {
      if (className.sections.length) {
        if (section) {
          setConfirmModalOpen(true);
        } else {
          dispatch(setSnackbar(true, "warning", "Please select a section"));
        }
      } else {
        setConfirmModalOpen(true);
      }
    } else {
      dispatch(setSnackbar(true, "warning", "Please select class"));
    }
  };

  const onImportConfirmed = () => {
    const post_data = students.map((item) => ({
      name: item.full_name,
      guardian_name: item.guardian_name,
      relation: item.relation,
      phone_number: item.phone_number,
      student_number: item.student_number,
    }));
    if (className) {
      if (className.sections.length) {
        if (section) {
          dispatch(
            importStudentData({
              grade: className.id,
              section: section.id,
              values: post_data,
            })
          );
        } else {
          dispatch(setSnackbar(true, "warning", "Please select a section"));
        }
      } else {
        dispatch(
          importStudentData({
            grade: className.id,
            section: "",
            values: post_data,
          })
        );
      }
    } else {
      dispatch(setSnackbar(true, "warning", "Please select class"));
    }
  };

  const handleDocument = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target && event.target.files) {
      const csvFile = event.target.files[0];
      if (csvFile) {
        Papa.parse(csvFile, {
          complete: function (result) {
            try {
              const data = result.data;
              data.shift();

              const td: {
                id: number;
                full_name: string;
                guardian_name: string;
                relation: string;
                phone_number: string;
                student_number: string;
                loading: boolean;
                validity: number;
                validationMsg: string;
              }[] = [];

              data.forEach((item, index) => {
                const row = item as Array<string>;
                const rowLen = row.length;

                if (rowLen > 1) {
                  td.push({
                    id: index + 1,
                    full_name: row[0] || "",
                    guardian_name: row[1] || "",
                    relation: row[2] || "",
                    phone_number: row[3] || "",
                    student_number: row[4] || "",
                    loading: false,
                    validity: 1,
                    validationMsg: "Not Validated",
                  });
                }
              });
              dispatch(addStudentsAction(td));
            } catch (err) {
              dispatch(setSnackbar(true, "warning", "Select a valid csv file"));
            }
          },
        });
      } else {
        dispatch(addStudentsAction([]));
      }
    }
  };

  const returnValidityStatus = (status: number, msg: string) => {
    if (status === 1) {
      return (
        <Tooltip title={msg}>
          <NoValidityIcon />
        </Tooltip>
      );
    }
    if (status === 2) {
      return (
        <Tooltip title={msg}>
          <NoValidityIcon />
        </Tooltip>
      );
    }
    if (status === 3) {
      return (
        <Tooltip title={msg}>
          <NotValidIcon />
        </Tooltip>
      );
    }
    return (
      <Tooltip title={msg}>
        <ValidIcon />
      </Tooltip>
    );
  };

  const handleInputChange = (value: string, id: number, field: string) => {
    dispatch(updateStudentRowAction({ id, field, value }));
  };

  const handleDeleteRow = (id: number) => {
    dispatch(removeStudentRowAction(id));
  };

  const validateData = () => {
    const phone_numbers = students.map((item) => item.phone_number);
    dispatch(importUserValidation({ numbers: phone_numbers }));
  };

  return (
    <SubModuleLayout studentMenuActive sideNav={nav}>
      <Paper className={classes.pageContent} elevation={3}>
        {stduentInfoUploadState.loading && (
          <LinearProgress style={{ margin: "-24px -24px 16px -24px" }} />
        )}

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Upload Student Info</Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={3} className={classes.formWrapper}>
                <InputLabel>Select Grade</InputLabel>
                <Autocomplete
                  onChange={(
                    event: React.ChangeEvent<{}>,
                    value: ClassI | null
                  ) => handleClassChange(value)}
                  options={classChoices}
                  value={className}
                  getOptionLabel={(option) => option.title}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Label"
                      name="class_name"
                      variant="outlined"
                      inputRef={register({ required: true })}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={3} className={classes.formWrapper}>
                <InputLabel>Select Section </InputLabel>
                <Autocomplete
                  style={{
                    padding: 0,
                  }}
                  value={section}
                  onChange={(
                    event: React.ChangeEvent<{}>,
                    value: SectionI | null
                  ) => handleSectionChange(value)}
                  options={sectionChoices}
                  getOptionLabel={(option) => option.title}
                  disabled={sectionDisabler}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Label"
                      name="section_name"
                      variant="outlined"
                      inputRef={register}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <input
              accept=".csv"
              id="contained-button-file"
              // multiple
              type="file"
              name="document"
              onChange={handleDocument}
              ref={register({ required: true })}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Please select file in .csv format</InputLabel>
          </Grid>
          <Grid item xs={12}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Validity</TableCell>
                  <TableCell>S.N</TableCell>
                  <TableCell>Full Name</TableCell>
                  <TableCell>Guardian Name</TableCell>
                  <TableCell>Relation</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Student Number</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {students.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      {returnValidityStatus(row.validity, row.validationMsg)}
                    </TableCell>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>
                      <CellInput
                        name="full_name"
                        value={row.full_name}
                        onChange={(e) =>
                          handleInputChange(e.target.value, row.id, "full_name")
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <CellInput
                        name="guardian_name"
                        value={row.guardian_name}
                        onChange={(e) =>
                          handleInputChange(
                            e.target.value,
                            row.id,
                            "guardian_name"
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>{row.relation}</TableCell>
                    <TableCell>
                      <CellInput
                        name="phone_number"
                        value={row.phone_number}
                        type="number"
                        onChange={(e) =>
                          handleInputChange(
                            e.target.value,
                            row.id,
                            "phone_number"
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>{row.student_number}</TableCell>
                    <TableCell>
                      {
                        <IconButton onClick={() => handleDeleteRow(row.id)}>
                          <RemoveIcon />
                        </IconButton>
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<DoneAllIcon />}
              type="submit"
              style={{ marginRight: "15px", marginTop: "10px" }}
              onClick={() => validateData()}
            >
              Validate
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Send />}
              type="submit"
              onClick={() => handleImportData()}
              style={{ marginTop: "10px" }}
              disabled={!valid}
            >
              Send
            </Button>
          </Grid>
        </Grid>
        <label htmlFor="contained-button-file"></label>
        <BackDropLoader open={loading} />
      </Paper>
      <CustomizedDialogs
        open={confirmModalOpen}
        onClose={() => {
          setConfirmModalOpen(false);
        }}
        deleteConfirmed={onImportConfirmed}
        title="Import Data"
        dialogTitle="Are you sure to import these data?"
        okText="Import"
      />
    </SubModuleLayout>
  );
}
export default ImportStudentInfo;
