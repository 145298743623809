// import { SectionTypeI } from "../Section/SectionActionTypes";

export const GET_OPENING_BALANCE_LOADING = "GET_OPENING_BALANCE_LOADING";
export const GET_OPENING_BALANCE_ERROR = "GET_OPENING_BALANCE_ERROR";
export const GET_OPENING_BALANCE_SUCCESS = "GET_OPENING_BALANCE_SUCCESS";

export const ADD_OPENING_BALANCE_LOADING = "ADD_OPENING_BALANCE_LOADING";
export const ADD_OPENING_BALANCE_ERROR = "ADD_OPENING_BALANCE_ERROR";
export const ADD_OPENING_BALANCE_SUCCESS = "ADD_OPENING_BALANCE_SUCCESS";

export const UPDATE_OPENING_BALANCE_LOADING = "UPDATE_OPENING_BALANCE_LOADING";
export const UPDATE_OPENING_BALANCE_ERROR = "UPDATE_OPENING_BALANCE_ERROR";
export const UPDATE_OPENING_BALANCE_SUCCESS = "UPDATE_OPENING_BALANCE_SUCCESS";



export type StudentI = {
    id: string;
    first_name: string;
    last_name: string
}

export type CurrentAcademicI = {
    id: string;
    student: StudentI;
}

export type GetOpeningBalance = {
    id: string;
    current_academic_detail: CurrentAcademicI
    debit: string;
    credit: string;
    remarks: string
};

export type PostOpeningBalance = {
    current_academic: string;
    debit: string;
    credit: string;
    remarks: string
};

//===============================<START>GET CLASS<START>======================//

export interface GetOpeningBalanceLoading {
    type: typeof GET_OPENING_BALANCE_LOADING;
}

export interface GetOpeningBalanceError {
    type: typeof GET_OPENING_BALANCE_ERROR;
}

export interface GetOpeningBalanceSuccess {
    type: typeof GET_OPENING_BALANCE_SUCCESS;
    payload: any;
}

//===============================<END>GET CLASS<END>======================//

//===============================<START>ADD CLASS<START>======================//

export interface AddOpeningBalanceLoading {
    type: typeof ADD_OPENING_BALANCE_LOADING;
}

export interface AddOpeningBalanceError {
    type: typeof ADD_OPENING_BALANCE_ERROR;
}

export interface AddOpeningBalanceSuccess {
    type: typeof ADD_OPENING_BALANCE_SUCCESS;
    payload: any;
}

//===============================<END>ADD CLASS<END>======================//

//===============================<START>UPDATE CLASS<START>======================//

export interface UpdateOpeningBalanceLoading {
    type: typeof UPDATE_OPENING_BALANCE_LOADING;
}

export interface UpdateOpeningBalanceError {
    type: typeof UPDATE_OPENING_BALANCE_ERROR;
}

export interface UpdateOpeningBalanceSuccess {
    type: typeof UPDATE_OPENING_BALANCE_SUCCESS;
    payload: any[];
}

//===============================<END>UPDATE CLASS<END>======================//

//===============================<END>DELETE CLASS<END>======================//

export type OpeningBalanceDispatchTypes =
    | GetOpeningBalanceLoading
    | GetOpeningBalanceError
    | GetOpeningBalanceSuccess
    | AddOpeningBalanceLoading
    | AddOpeningBalanceError
    | AddOpeningBalanceSuccess
    | UpdateOpeningBalanceLoading
    | UpdateOpeningBalanceError
    | UpdateOpeningBalanceSuccess
