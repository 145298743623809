import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  GradeSubjectI,
  InitialStateI,
  SubjectInfoPayloadI,
} from "./gradeSubject";
import {
  getGradeSubjects,
  postGradeSubjectInfo,
  updateGradeSubjectInfo,
} from "./gradeSubjectApi";

const initialState: InitialStateI = {
  loading: false,
  actionPerformed: false,
  gradeSubjects: [],
};

const GradeSubjectSlice = createSlice({
  name: "gradeSubject",
  initialState,
  reducers: {
    resetTable: (state) => {
      state.loading = false;
      state.actionPerformed = false;
      state.gradeSubjects = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGradeSubjects.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(
      getGradeSubjects.fulfilled,
      (state, { payload }: PayloadAction<GradeSubjectI[]>) => {
        state.loading = false;
        state.gradeSubjects = payload;
      }
    );
    builder.addCase(getGradeSubjects.rejected, (state) => {
      state.loading = false;
    });
    //
    //
    builder.addCase(postGradeSubjectInfo.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(
      postGradeSubjectInfo.fulfilled,
      (
        state,
        { payload }: PayloadAction<{ id: string; data: SubjectInfoPayloadI }>
      ) => {
        state.loading = false;
        const updated = state.gradeSubjects.find((el) => el.id === payload.id);

        if (updated) {
          updated.subject_info = { ...payload.data };
        }
      }
    );
    builder.addCase(postGradeSubjectInfo.rejected, (state) => {
      state.loading = false;
    });
    //
    //
    builder.addCase(updateGradeSubjectInfo.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(
      updateGradeSubjectInfo.fulfilled,
      (
        state,
        { payload }: PayloadAction<{ id: string; data: SubjectInfoPayloadI }>
      ) => {
        state.loading = false;
        const updated = state.gradeSubjects.find(
          (el) => el.subject_info?.id === payload.id
        );

        if (updated) {
          updated.subject_info = { ...updated.subject_info, ...payload.data };
        }
      }
    );
    builder.addCase(updateGradeSubjectInfo.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { resetTable } = GradeSubjectSlice.actions;

export default GradeSubjectSlice.reducer;
