import React, { useEffect, useState } from 'react';
import { useSidebar } from '../../Reusable';
import { useSidebarStyles } from '../../Styles/SidebarStyles';
import { WcOutlined } from '@material-ui/icons';
import SidebarTitle from '../../Reusable/Titles/SidebarTitle';
import {
  Badge,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import styled from 'styled-components';
import { Link, useParams } from 'react-router-dom';
import { Notifications } from '@material-ui/icons';
import { URLSearchParams } from 'url';
import { useDispatch } from 'react-redux';
import { GetNotifications } from '../../../actions/HumanResource/Notification/NotificationAction';

interface propsI {
  Designation?: boolean;
  Department?: boolean;
  Staff?: boolean;
  AddStaff?: boolean;
  LeaveType?: boolean;
  Leave?: boolean;
  ApplyLeaveType?: boolean;
  IdCard?: boolean;
  staffAttendance?: boolean;
  staffAttendanceReport?: boolean;
  disabledStaff?: boolean;
  action?: number;
}

const StyledListItem: any = styled(ListItem)`
  border-radius: 8px;
  margin-bottom: 2px;

  &.Mui-selected,
  &.Mui-selected:hover {
    background: #132e98;
    color: white;

    .MuiBadge-badge {
      background: white;
      color: #132e98;
    }
  }
`;

const StyledBadge = styled(Badge)`
  .MuiBadge-badge {
    top: 50%;
    right: -20px;
    transform: scale(1.1) translate(50%, -50%);
  }
`;

const NotificationSidebar = (props: propsI) => {
  const classes = useSidebarStyles();
  const params = useParams();
  const dispatch = useDispatch();

  const [designation] = useState(props.Designation);
  const [department] = useState(props.Department);
  const [staff] = useState(props.Staff);
  const [addStaff] = useState(props.AddStaff);
  const [leaveType] = useState(props.LeaveType);
  const [leave] = useState(props.Leave);
  const [applyLeaveType] = useState(props.ApplyLeaveType);
  const [idCard] = useState(props.IdCard);
  const [staffAttendance] = useState(props.staffAttendance);
  const [staffAttendanceReport] = useState(props.staffAttendanceReport);
  const [disabledStaff] = useState(props.disabledStaff);

  const searchAction = props.action;
  console.log('search', searchAction);

  useEffect(() => {
    dispatch(
      GetNotifications({
        action: searchAction,
      })
    );
  }, [searchAction]);

  const dataSet = [
    { text: 'Designation', url: 'designation', activeStatus: designation },
    { text: 'Department', url: 'department', activeStatus: department },
    // { text: 'Staff ID Card', url: 'staff-id-card', activeStatus: idCard },
  ];

  /*
  NEW_ASSIGNMENT_ADDED = 1
    ASSIGNMENT_UPDATED = 2
    ONLINE_CLASS_LINK_GENERATED = 3
    NEW_LESSON_PLAN = 4
    LESSON_PLAN_UPDATED = 5
    ADDED_STUDENT_ASSIGNMENT_MARKS = 6
    NEW_LEAVE_REQUEST = 7
    NEW_STUDENT_COMPLAINT = 8
    NEW_MEETING_REQUEST = 9
    STUDENT_EXAM_ADD_MARKS = 10
    NEW_STUDENT_ATTENDANCE = 11
    STAFF_ATTENDANCE = 12

    */

  console.log('params', params);

  interface NotiCategoryI {
    action: Number;
    code: string;
    title: string;
  }

  const notiCategoryData: NotiCategoryI[] = [
    {
      action: 1,
      code: 'NEW_ASSIGNMENT_ADDED',
      title: 'New Assignment Added',
    },
    {
      action: 2,
      code: 'ASSIGNMENT_UPDATED',
      title: 'Assignment Updated',
    },
    {
      action: 3,
      code: 'ONLINE_CLASS_LINK_GENERATED',
      title: 'Online Class Link Generated',
    },
    {
      action: 4,
      code: 'NEW_LESSON_PLAN',
      title: 'New Lesson Plan',
    },
    {
      action: 5,
      code: 'LESSON_PLAN_UPDATED',
      title: 'Lesson Plan Updated',
    },
    {
      action: 6,
      code: 'ADDED_STUDENT_ASSIGNMENT_MARKS',
      title: 'Added Student Assignment Marks',
    },
    {
      action: 7,
      code: 'NEW_LEAVE_REQUEST',
      title: 'New Leave Request',
    },
    {
      action: 8,
      code: 'NEW_STUDENT_COMPLAINT',
      title: 'New Student Complaint',
    },
    {
      action: 9,
      code: 'NEW_MEETING_REQUEST',
      title: 'New Meeting Request',
    },
    {
      action: 10,
      code: 'STUDENT_EXAM_ADD_MARKS',
      title: 'Student Exam Add Marks',
    },
    {
      action: 11,
      code: 'NEW_STUDENT_ATTENDANCE',
      title: 'New Student Attendance',
    },
    {
      action: 12,
      code: 'STAFF_ATTENDANCE',
      title: 'Staff Attendance',
    },
  ];

  const { CustomSidebar } = useSidebar(dataSet, 'FINANCE');

  return (
    <div className={classes.Sidebar}>
      <div className={classes.sidebarContent}>
        <SidebarTitle icon={<Notifications />}>Notifications</SidebarTitle>

        <List component="nav" aria-label="main mailbox folders">
          {notiCategoryData.map((notiItem) => (
            <StyledListItem
              key={notiItem.action}
              button
              component={Link}
              to={`/notifications/?action=${notiItem.action}`}
              className={
                notiItem.action === searchAction ? 'Mui-selected' : null
              }
            >
              <ListItemText primary={notiItem.title} />
            </StyledListItem>
          ))}
          {/* <StyledListItem button>
            <ListItemText primary="Online Class" />
          </StyledListItem>
          <StyledListItem button>
            <StyledBadge badgeContent={9} color="primary">
              <ListItemText primary="Lesson Plan" />
            </StyledBadge>
          </StyledListItem>
          <StyledListItem button>
            <ListItemText primary="Leave Requests" />
          </StyledListItem>
          <StyledListItem button>
            <StyledBadge badgeContent={4} color="primary">
              <ListItemText primary="Meeting Requests" />
            </StyledBadge>
          </StyledListItem>
          <StyledListItem button>
            <StyledBadge badgeContent={4} color="primary">
              <ListItemText primary="Attendance" />
            </StyledBadge>
          </StyledListItem> */}
        </List>
      </div>
    </div>
  );
};

export default NotificationSidebar;
