import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Paper, Toolbar, Typography, Button } from "@material-ui/core";
import {
  DashboardOutlined,
  SchoolOutlined,
  LibraryBooksOutlined,
  WcOutlined,
  MenuBookOutlined,
  ReceiptOutlined,
  Settings,
  Assignment,
} from "@material-ui/icons";
import SmsOutlinedIcon from "@material-ui/icons/SmsOutlined";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      opacity: 1,
      flexGrow: 1,
      boxSizing: "border-box",
      "& .MuiButtonBase-root:hover": {
        backgroundColor: "#3FC581",
      },
      "& .settingBtn:hover": {
        backgroundColor: "white",
      },
      "& .settingBtnActive": {
        backgroundColor: "white",
        color: "blue",
      },
    },
    appbar: {
      backgroundColor: "white",
    },
    menubar: {
      display: "flex",
      justifyContent: "space-between",
      minHeight: "48px",
    },
    navbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      flexDirection: "row",
      height: "42.5px",
    },
    activebutton: {
      borderBottom: "2px solid #4D4F5C",
      color: "##132E98 !important",
    },
    navitem: {
      display: "flex",
      alignItems: "center",
      color: "##211E1E",
      textDecoration: "none",
      padding: "0rem",
      height: "42.5px",
      fontSize: "1px",
    },
    classbutton: {
      height: "29px",
      fontSize: "14px",
      backgroundColor: "#3FC581",
      color: "#fff",
      textTransform: "none",
      fontWeight: 600,
      borderRadius: "16px",
      minWidth: "125px",
    },
    endbutton: {
      display: "flex",
      alignItems: "center",
      margin: "0 0 0 1rem",
    },
    classroom: {
      textDecoration: "none",
    },
  })
);

interface ActiveStatusI {
  dashboardMenuActive?: boolean;
  studentMenuActive?: boolean;
  academicMenuActive?: boolean;
  HumanMenuActive?: boolean;
  communicationMenuActive?: boolean;
  examinationMenuActive?: boolean;
  reportMenuActive?: boolean;
  FeesMenuActive?: boolean;
  generalMenuActive?: boolean;
}

const Navbar = (props: ActiveStatusI) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.appbar} elevation={0} square>
        <Toolbar className={classes.menubar}>
          <div className={classes.navbar}>
            <Link
              to="/"
              className={
                props.dashboardMenuActive ? "navitem active" : "navitem"
              }
            >
              <DashboardOutlined />
              &nbsp;Dashboard
            </Link>
            <Link
              to="/student"
              className={props.studentMenuActive ? "navitem active" : "navitem"}
            >
              <SchoolOutlined />
              &nbsp;Student
            </Link>
            <Link
              to="/section"
              className={
                props.academicMenuActive ? "navitem active" : "navitem"
              }
            >
              <LibraryBooksOutlined />
              &nbsp;Academic
            </Link>
            <Link
              to="/designation"
              className={props.HumanMenuActive ? "navitem active" : "navitem"}
            >
              <WcOutlined />
              &nbsp;Human&nbsp;Resource
            </Link>
            <Link
              to="/examination"
              className={
                props.examinationMenuActive ? "navitem active" : "navitem"
              }
            >
              <MenuBookOutlined />
              &nbsp;Examinations
            </Link>
            <Link
              to="/communication"
              className={
                props.communicationMenuActive ? "navitem active" : "navitem"
              }
            >
              <SmsOutlinedIcon />
              &nbsp;Communication
            </Link>
            <Link
              to="/report"
              className={props.reportMenuActive ? "navitem active" : "navitem"}
            >
              <Assignment />
              &nbsp;Reports
            </Link>
            <Link
              to="/#"
              className={props.FeesMenuActive ? "navitem active" : "navitem"}
            >
              <ReceiptOutlined />
              &nbsp;Fees
            </Link>
          </div>
          <div className={classes.endbutton}>
            <Link className={classes.classroom} to="/#">
              <Button className={classes.classbutton} variant="contained">
                Go To Classroom
              </Button>
            </Link>
            <Link to="/session">
              <Button className="settingBtn" disableRipple>
                <Settings
                  className={props.generalMenuActive ? "active1" : ""}
                />
              </Button>
            </Link>
          </div>
        </Toolbar>
      </Paper>
    </div>
  );
};

export default Navbar;
