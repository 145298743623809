import React from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
  Typography,
  Grid,
  Link,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boxHeaderLink: {
      '&:hover': {
        textDecoration: 'none',
        paddingBottom: '2px',
        borderBottom: ' 2px solid',
      },
    },
  })
);

interface BoxHeaderPropsI {
  title: string;
  date: string;
  to: string;
}

const BoardHeader = (props: BoxHeaderPropsI) => {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      style={{
        // marginBottom: "12px",
        // paddingBottom: "12px",
        borderBottom: '1px solid rgba(0,0,0,0.075)',
      }}
    >
      <Grid item>
        <Typography
          variant="h6"
          color="textPrimary"
          style={{ fontWeight: 700 }}
        >
          {props.title}
        </Typography>

        <Typography
          variant="subtitle2"
          style={{ color: '#555', fontWeight: 'normal', marginTop: '2px' }}
        >
          {props.date}
        </Typography>
      </Grid>

      <Grid item>
        <Link
          component={RouterLink}
          variant="subtitle2"
          color="primary"
          to={props.to}
          className={classes.boxHeaderLink}
        >
          View All
        </Link>
      </Grid>
    </Grid>
  );
};

export default BoardHeader;
