// ---------------------------- <START> module imports start ----------------------------------//
import React, { useState, useEffect } from "react";
import {
  Paper,
  TableBody,
  TextField,
  Grid,
  Typography,
  TablePagination,
  LinearProgress,
} from "@material-ui/core";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import { Pagination } from "@material-ui/lab";
import { useTableStyles } from "../../Styles/TableStyles";
import { useTable } from "../../Reusable";
import TableActionButtons from "../../Reusable/Buttons/TableActionButtons";
import ActionButton from "../../Reusable/Buttons/ActionButton";
import { useDispatch, useSelector } from "react-redux";
import {
  GetClassTeachers,
  DeleteClassTeacher,
} from "../../../actions/Academics/ClassTeacher/ClassTeacherAction";
import { ClassTeacherTypeI } from "../../../actions/Academics/ClassTeacher/ClassTeacherActionTypes";
import { RootStore } from "../../../store";
import { DeleteForeverOutlined } from "@material-ui/icons";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import {
  ItemDeleteButton,
  ItemEditButton,
} from "../../Reusable/Buttons/TableButton";
// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//
interface PropsI {
  onEditData: (value: ClassTeacherTypeI) => void;
  edit: { value: boolean };
}

interface HeadCellsI {
  id: string;
  label: string;
}
// ---------------------------- <END> interface ends ----------------------------------//

// ---------------------------- <START> styling starts ----------------------------------//

// ---------------------------- <END> styling ends ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N" },
  { id: "class", label: "Class" },
  { id: "section", label: "Section" },
  { id: "teacher", label: "Teacher" },
  { id: "action", label: "Action" },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const ClassTeacherTable = (props: PropsI) => {
  const { onEditData, edit } = props;
  const classes = useTableStyles();

  // States declaration
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string>("");

  const [tableData, setTableData] = useState<ClassTeacherTypeI[]>([]);
  const [editMode, setEditMode] = useState(false);

  //========================= <START> REDUX HOOKS <START> ==============================//
  const dispatch = useDispatch();
  const classTeacherSelector = useSelector(
    (state: RootStore) => state.class_teacher
  );

  useEffect(() => {
    dispatch(GetClassTeachers());
  }, []);

  useEffect(() => {
    if (classTeacherSelector.actionPerformed) dispatch(GetClassTeachers());
  }, [classTeacherSelector.actionPerformed]);

  useEffect(() => {
    const classTeacherList = classTeacherSelector.classTeachers;

    setLoading(classTeacherSelector.loading);

    setTableData(classTeacherList);
  }, [classTeacherSelector]);

  useEffect(() => {
    edit && setEditMode(edit.value);
  }, [edit]);

  // Retrieving re-usable components from useTable
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  //==================================== <START> EVENT HANDLERS <START> ==========================//
  const handleEditClicked = (data: ClassTeacherTypeI) => {
    onEditData(data);
    setEditMode(true);
  };

  const handleDeleteModal = (value: boolean, id: string) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteClassTeacher(itemId));
    setDeleteModalOpen(false);
  };
  //==================================== <END> EVENT HANDLERS <END> ==========================//

  const getCustomTableRow = (item: ClassTeacherTypeI, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.grade_name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.section_name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {`${item.teacher_first_name} ${item.teacher_last_name}`}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ItemEditButton
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleEditClicked(item)
            }
          />
          <ItemDeleteButton
            disabled={editMode}
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleDeleteModal(true, item.id)
            }
          />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <>
      <Paper className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">Assigned Class Teacher List</Typography>
          </Grid>
        </Grid>
        <TableContainer
          loading={loading}
          headCells={headCells}
          items={tableData}
          getCustomTableRow={getCustomTableRow}
          search_name="teacher_first_name"
          search_param="grade_name"
        />
      </Paper>
      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
      />
    </>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default ClassTeacherTable;
