import {
  GET_STUDENT_DOCUMENT_LOADING,
  GET_STUDENT_DOCUMENT_ERROR,
  GET_STUDENT_DOCUMENT_SUCCESS,
  DocumentDispatchTypes,
  ADD_STUDENT_DOCUMENT_LOADING,
  ADD_STUDENT_DOCUMENT_ERROR,
  ADD_STUDENT_DOCUMENT_SUCCESS,
  DELETE_STUDENT_DOCUMENT_LOADING,
  DELETE_STUDENT_DOCUMENT_ERROR,
  DELETE_STUDENT_DOCUMENT_SUCCESS,
  UPDATE_STUDENT_DOCUMENT_LOADING,
  UPDATE_STUDENT_DOCUMENT_ERROR,
  UPDATE_STUDENT_DOCUMENT_SUCCESS,
  DocumentTypeI,
} from "../../../actions/Student/StudentDocument/StudentDocumentActionTypes";

interface InitialStateI {
  loading: boolean;
  documents: DocumentTypeI[];
}

const initialState: InitialStateI = {
  loading: false,
  documents: [],
};

const DocumentReducer = (
  state: InitialStateI = initialState,
  action: DocumentDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_STUDENT_DOCUMENT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_STUDENT_DOCUMENT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_STUDENT_DOCUMENT_SUCCESS:
      return {
        loading: false,
        documents: action.payload,
      };

    case ADD_STUDENT_DOCUMENT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case ADD_STUDENT_DOCUMENT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case ADD_STUDENT_DOCUMENT_SUCCESS:
      return {
        loading: false,
        documents: [...state.documents, action.payload],
      };

    case UPDATE_STUDENT_DOCUMENT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_STUDENT_DOCUMENT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case UPDATE_STUDENT_DOCUMENT_SUCCESS:
      const current_data: DocumentTypeI[] = state.documents;
      const index: number = current_data.findIndex(
        (element) => element.id === action.payload.id
      );
      current_data[index] = action.payload;

      return {
        loading: false,
        documents: current_data,
      };

    case DELETE_STUDENT_DOCUMENT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_STUDENT_DOCUMENT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_STUDENT_DOCUMENT_SUCCESS:
      const new_data: DocumentTypeI[] = state.documents;
      const item_index = new_data.findIndex(
        (element) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return {
        loading: false,
        documents: new_data,
      };

    default:
      return state;
  }
};

export default DocumentReducer;
