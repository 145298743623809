// ---------------- <START> module import start ------------------//
import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
// ----------------<END> module import end ------------------//

// ----------------<START> interface start ------------------//
interface SidebarTitleProps {
  children: React.ReactNode;
  icon: React.ReactNode;
}
// ----------------<END> interface end ------------------//

//-----------------<start> Styling <start>-----------------------//
const useLocalStyles = makeStyles({
  sidebarTitle: {
    borderBottom: '1px solid rgba(118, 118, 118, 0.25)',
    paddingBottom: '46px',
    marginBottom: '0.5rem',
    fontWeight: 600,
    '& .MuiSvgIcon-root': {
      marginRight: '8px',
      fontSize: '34px',
      verticalAlign: 'bottom',
    },
  },
});
//-----------------<end> Styling <end>-----------------------//

//--------------------- <START> Sidebar Title Component <START> --------------------//
const SidebarTitle = (props: SidebarTitleProps) => {
  const classes = useLocalStyles();
  return (
    <Typography variant="h3" color="primary" className={classes.sidebarTitle}>
      {props.icon}
      {props.children}
    </Typography>
  );
};
//--------------------- <END> Sidebar Title Component <END> --------------------//

export default SidebarTitle;
