import {
    ADD_RUBRIC_SUB_CATEGORY_ERROR,
    ADD_RUBRIC_SUB_CATEGORY_LOADING,
    ADD_RUBRIC_SUB_CATEGORY_SUCCESS,
    GET_RUBRIC_SUB_CATEGORY_ERROR,
    GET_RUBRIC_SUB_CATEGORY_LOADING,
    GET_RUBRIC_SUB_CATEGORY_SUCCESS,
    DELETE_RUBRIC_SUB_CATEGORY_ERROR,
    DELETE_RUBRIC_SUB_CATEGORY_LOADING,
    DELETE_RUBRIC_SUB_CATEGORY_SUCCESS,
    UPDATE_RUBRIC_SUB_CATEGORY_ERROR,
    UPDATE_RUBRIC_SUB_CATEGORY_LOADING,
    UPDATE_RUBRIC_SUB_CATEGORY_SUCCESS,
    RubricSubCategoryDispatchTypes
} from "../../actions/Academics/RubricCategory/RubricSubCategory/RubricSubCategoryActionTypes"
import { RubricCategoryTypeI } from "../../actions/Academics/RubricCategory/RubricCategoryActionTypes";

interface InitialStateI {
    loading: boolean;
    rubric_sub_categories: RubricCategoryTypeI[];
    add_or_update: boolean;
    errors?: any;
    recent?: boolean;
}

const initialState: InitialStateI = {
    loading: false,
    rubric_sub_categories: [],
    add_or_update: false,
    errors: null,
    recent: false,
}

const RubricSubCategoryReducers = (
    state: InitialStateI = initialState,
    action: RubricSubCategoryDispatchTypes
): InitialStateI => {
    switch (action.type) {
        case GET_RUBRIC_SUB_CATEGORY_LOADING:
            return {
                ...state,
                loading: true,
            };

        case GET_RUBRIC_SUB_CATEGORY_ERROR:
            return {
                ...state,
                loading: false,
            };

        case GET_RUBRIC_SUB_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                rubric_sub_categories: action.payload.results,
                errors: null,
                recent: false,
            };

        case ADD_RUBRIC_SUB_CATEGORY_LOADING:
            return {
                ...state,
                loading: true,
                add_or_update: false,
            };

        case ADD_RUBRIC_SUB_CATEGORY_ERROR:
            return {
                ...state,
                loading: false,
                errors: action.payload,
                recent: false,
                add_or_update: true,
            };

        case ADD_RUBRIC_SUB_CATEGORY_SUCCESS:
            return {
                loading: false,
                rubric_sub_categories: [...state.rubric_sub_categories, action.payload],
                errors: null,
                recent: true,
                add_or_update: true,
            };

        case UPDATE_RUBRIC_SUB_CATEGORY_LOADING:
            return {
                ...state,
                loading: true,
                add_or_update: false,
            };

        case UPDATE_RUBRIC_SUB_CATEGORY_ERROR:
            return {
                ...state,
                loading: false,
                errors: action.payload,
                recent: false,
                add_or_update: true,
            };

        case UPDATE_RUBRIC_SUB_CATEGORY_SUCCESS:
            const current_data: RubricCategoryTypeI[] = state.rubric_sub_categories;
            const index: number = current_data.findIndex(
                (element) => element.id === action.payload.id
            );
            current_data[index] = action.payload;

            return {
                loading: false,
                rubric_sub_categories: current_data,
                errors: null,
                recent: true,
                add_or_update: true,
            };

        case DELETE_RUBRIC_SUB_CATEGORY_LOADING:
            return {
                ...state,
                loading: true,
            };

        case DELETE_RUBRIC_SUB_CATEGORY_ERROR:
            return {
                ...state,
                loading: false,
            };

        case DELETE_RUBRIC_SUB_CATEGORY_SUCCESS:
            const new_data: RubricCategoryTypeI[] = state.rubric_sub_categories;
            const item_index = new_data.findIndex(
                (element) => element.id === action.payload.id
            );
            new_data.splice(item_index, 1);

            return {
                ...state,
                loading: false,
                rubric_sub_categories: new_data,
            };

        default:
            return state;
    }
}

export default RubricSubCategoryReducers;