import React from "react";
import { HRsidebar } from "../../components";
import StaffForm from "../../components/HumanResource/Staff/StaffForm";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const AddStaffPage = () => {
  const nav = <HRsidebar AddStaff />;
  return (
    <SubModuleLayout HumanMenuActive sideNav={nav}>
      <StaffForm />
    </SubModuleLayout>
  );
};

export default AddStaffPage;
