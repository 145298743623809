import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import { Button, Grid, InputLabel, TextField } from "@material-ui/core";
import styled from "styled-components";
import { useTable } from "../../Reusable";
import SubjectClassRow from "./SubjectClassRow";
import { Autocomplete } from "@material-ui/lab";
import { useFormStyles } from "../../Styles/FormStyles";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import { GetGrades } from "../../../actions/Academics/Grade/GradeAction";
import { GradeTypeI } from "../../../actions/Academics/Grade/GradeActionTypes";
import { getGradeSubjects } from "../../../rtk/features/exam/subjectGrade/gradeSubjectApi";
import { resetTable } from "../../../rtk/features/exam/subjectGrade/gradeSubjectSlice";
import BackDropLoader from "../../Reusable/BackDropLoader";

const StyledPaper = styled(Paper)`
  margin: 20px;
  padding: 25px 20px;
`;

interface HeadCellsI {
  id: string;
  label: string;
}

const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N" },
  { id: "annoucement_title", label: "Annoucement Title" },
  { id: "notice_date", label: "Notice Date" },
  { id: "published_date", label: "Published Date" },
  { id: "message_to", label: "Message To" },
  { id: "action", label: "Action" },
];

export interface SubjectClassI {
  id: string;
  subject: string;
  subjectType: string;
  hasPractical: boolean;
  creditHour: number;
  full_marks_th: number;
  pass_marks_th: number;
  full_marks_pr: number;
  pass_marks_pr: number;
}

const SubjectClass = () => {
  const classes = useFormStyles();
  const dispatch = useDispatch();
  const {
    TblContainer,
    TblHead,
    StyledTableCell,
    StyledTableRow,
    exportToExcel,
    tablePrint,
  } = useTable(headCells);

  const [selectedGrade, setSelectedGrade] = useState<GradeTypeI | null>(null);

  const gradeSelector = useSelector((state: RootStore) => state.grade);
  const gradeSubjectSelector = useSelector(
    (state: RootStore) => state.gradeSubject
  );

  useEffect(() => {
    dispatch(GetGrades());
    dispatch(resetTable());
  }, [dispatch]);

  const handleGradeChange = (data: GradeTypeI | null) => {
    setSelectedGrade(data);
    dispatch(resetTable());

    if (data) {
      dispatch(getGradeSubjects(data.id));
    } else {
      dispatch(resetTable());
    }
  };

  return (
    <>
      <StyledPaper>
        <Grid item xs={12} sm={6} md={3} className={classes.formWrapper}>
          <Autocomplete
            value={selectedGrade}
            onChange={(event: React.ChangeEvent<{}>, value) =>
              handleGradeChange(value)
            }
            options={gradeSelector.grades}
            getOptionLabel={(option) => option.grade_name}
            renderInput={(params) => (
              <TextField
                {...params}
                name="select_message"
                placeholder="Select Class"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Table aria-label="collapsible table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell />
              <StyledTableCell align="center">Subject</StyledTableCell>
              <StyledTableCell align="center">Result Type</StyledTableCell>
              <StyledTableCell align="center">Subject Type</StyledTableCell>
              <StyledTableCell align="center">Has Practical</StyledTableCell>
              <StyledTableCell align="center">Credit Hour</StyledTableCell>
              <StyledTableCell align="center">
                <span>
                  Full Marks <br />
                  (Theory)
                </span>
              </StyledTableCell>
              <StyledTableCell align="center">
                <span>
                  Pass Marks <br />
                  (Theory)
                </span>
              </StyledTableCell>
              <StyledTableCell align="center">
                <span>
                  Full Marks <br />
                  (Practical)
                </span>
              </StyledTableCell>
              <StyledTableCell align="center">
                <span>
                  Pass Marks <br />
                  (Practical)
                </span>
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {gradeSubjectSelector.gradeSubjects.map((el) => (
              <SubjectClassRow
                key={el.id}
                row={el}
                grade={selectedGrade?.id || null}
              />
            ))}
          </TableBody>
        </Table>
      </StyledPaper>
      <BackDropLoader open={gradeSubjectSelector.loading} />
    </>
  );
};

export default SubjectClass;
