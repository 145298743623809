import {
    Paper
} from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import BackDropLoader from "../../Reusable/BackDropLoader";
import SubModuleLayout from "../../Reusable/Layouts/SubModuleLayout";
import FinanceSidebar from "../FinanceSidebar";
import FeeStatementSearch from "./FeeStatementSearch";
import FeeStatementTable from "./FeeStatementTable";

interface SelectedFeesI {
    id: string;
    title: string;
    isMonthly: boolean;
}

export default function FeeStatementPage() {
    const [fees, setFees] = useState<SelectedFeesI[]>([]);
    const [months, setMonths] = useState<string[]>([]);
    const [student, setStudent] = useState<any>(null);

    const [grade, setGrade] = useState<any | null>(null);
    const [section, setSection] = useState<any | null>(null);
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");

    const [loading, setLoading] = useState<boolean>(false);

    // const [selected, setSelected] = useState<any[]>([]);

    const dispatch = useDispatch();

    const nav = <FinanceSidebar fee_statement />;

    return (

        <SubModuleLayout sideNav={nav}>
            <Paper style={{ margin: "10px" }}>
                <FeeStatementSearch
                    student={student}
                    setStudent={setStudent}
                    grade={grade}
                    setGrade={setGrade}
                    section={section}
                    setSection={setSection}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                />
                <FeeStatementTable
                    grade={grade}
                    section={section}
                    startDate={startDate}
                    endDate={endDate}
                />
            </Paper>
            <BackDropLoader open={loading} />
        </SubModuleLayout>
    );
}