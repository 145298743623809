// -----------------<START> import modules starts----------------------//
import React, { useEffect, useState } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { useFormStyles } from '../../Styles/FormStyles';
import Popups from '../../Reusable/Dialogs/Popups';
import 'nepali-datepicker-reactjs/dist/index.css';
import AddNewButton from '../../Reusable/Buttons/AddNewButton.test';
import Template from './Template';
import { Alert } from '@material-ui/lab';
import SmsTemplateModal from './SmsTemplateModal';
import CustomizedDialogs from '../../Reusable/Dialogs/DeleteDialog';
import { SmsTemplateTypeI } from '../../../actions/Communication/SmsTemplate/SmsTemplateActionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import {
  DeleteSmsTemplate,
  GetSmsTemplates,
} from '../../../actions/Communication/SmsTemplate/SmsTemplateAction';

// -----------------<END> imports module ends -----------------------//

const templatesData: any = [
  {
    id: 1,
    alertText:
      "! Please use [class_name] to represent the class, [student_name] to represent the Student's Name and [marks_data] to represent the Marks, %GPA etc in text.",
    title: 'Result SMS Template',
    message:
      'Dear parents class [class_name] Result is published\nName: [student_name]\n[marks_data]\nThank you',
  },
  {
    id: 2,
    alertText:
      "! Please use [class_name] to represent the class, [student_name] to represent the Student's Name and [marks_data] to represent the Marks, %GPA etc in text.",
    title: 'Annoucement Template',
    message:
      'Dear parents class [class_name] Result is published\nName: [student_name]\n[marks_data]\nThank you',
  },
  {
    id: 3,
    alertText:
      "! Please use [class_name] to represent the class, [student_name] to represent the Student's Name and [marks_data] to represent the Marks, %GPA etc in text.",
    title: 'Annoucement Template',
    message:
      'Dear parents class [class_name] Result is published\nName: [student_name]\n[marks_data]\nThank you',
  },
];

// -----------------<START> Leave Search component starts-----------------------//
const LeaveSearch: React.FC = () => {
  const classes = useFormStyles();

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [templatesList, setTemplatesList] = useState<SmsTemplateTypeI[]>([]);
  const [modalMode, setModalMode] = useState<'Add' | 'Edit'>('Add');
  const [editingRecord, setEditingRecord] = useState<SmsTemplateTypeI | null>(
    null
  );

  // ======================== <START> REACT HOOKS <START> ==================================//
  const dispatch = useDispatch();
  const templateSelector = useSelector(
    (state: RootStore) => state.sms_template
  );

  useEffect(() => {
    dispatch(GetSmsTemplates());
  }, []);

  useEffect(() => {
    setTemplatesList(templateSelector.sms_templates);
  }, [templateSelector]);

  // ======================== <END> REACT HOOKS <END> ==================================//

  const handleClose = () => {
    setItemId(null);
    setEditingRecord(null);
    setOpenPopup(false);
  };

  const handleModalClose = (value: boolean) => {
    setItemId(null);
    setEditingRecord(null);
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteSmsTemplate(itemId));
    setDeleteModalOpen(false);
    setItemId(null);
    setEditingRecord(null);
  };

  const handleDelete = (id: string) => {
    setItemId(id);
    setDeleteModalOpen(true);
  };

  const handleEdit = (record: SmsTemplateTypeI) => {
    setModalMode('Edit');
    setEditingRecord(record);
    setOpenPopup(true);
  };

  const handleOpen = () => {
    setModalMode('Add');
    setOpenPopup(true);
  };

  return (
    <Paper className={classes.pageAttachContent}>
      <Grid container justifyContent="space-between">
        <Grid item xs={6} className={classes.formTitle}>
          <Typography variant="h6">Templates</Typography>
        </Grid>
        <Grid item>
          <AddNewButton title="Request New Leave" onClick={handleOpen}>
            Add New Template
          </AddNewButton>
        </Grid>
      </Grid>

      <Alert
        icon={false}
        severity="info"
        style={{
          display: 'inline-block',
          fontSize: '13px',
          borderLeft: '12px solid rgba(13, 60, 97, 0.75)',
          marginBottom: '15px',
          padding: '3px 15px',
        }}
      >
        ! Please use [class_name] to represent the class, [student_name] to
        represent the Student's Name and [marks_data] to represent the Marks,
        %GPA etc in text.
      </Alert>

      <Grid container spacing={3}>
        {templatesList.map((template: SmsTemplateTypeI, index: number) => (
          <Grid item md={6} key={index}>
            <Template
              item={template}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
            />
          </Grid>
        ))}
      </Grid>

      <Popups
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        onClose={handleClose}
        title="Sms Template"
        maxWidth="md"
      >
        <SmsTemplateModal
          mode={modalMode}
          onClose={handleClose}
          initData={editingRecord}
        />
      </Popups>

      {/* Delete modal dialog */}
      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
      />
    </Paper>
  );
};

//-----------------<ENDS> Leave Search component ends-----------------------//
export default LeaveSearch;
