// -----------------<START> import modules starts----------------------//
import React, { useEffect, useState } from 'react';
import {
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Add, Search, SettingsBackupRestore } from '@material-ui/icons';
import { useFormStyles } from '../../Styles/FormStyles';
import Popups from '../../Reusable/Dialogs/Popups';
import 'nepali-datepicker-reactjs/dist/index.css';
import LeaveRequestModal from './LeaveRequestModal';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  GetLeaves,
  GetSearchedLeaves,
} from '../../../actions/HumanResource/Leave/LeaveAction';
import CustomizedTextField from '../../Reusable/Inputs/TextField';
import CustomizedNepaliDatePicker, {
  RowNepaliDatePicker,
} from '../../Reusable/Inputs/NepaliDatePicker';
import AddNewButton from '../../Reusable/Buttons/AddNewButton.test';
import FormButton from '../../Reusable/Buttons/FormButton';
import CustomizedSelect, { RowSelect } from '../../Reusable/Inputs/Select';
import { RootStore } from '../../../store';
import { GetLeaveTypes } from '../../../actions/HumanResource/LeaveType/LeaveTypeAction';
import { Tupple } from '../../../actions';
import { dateConverterBsToAd } from '../../utils/dateConverter';
import { CUR_NEPALI_DATE } from '../../utils/nepaliDateUtils';
import SearchFormHeaderLayout from '../../Reusable/Layouts/SearchFormHeaderLayout';
import { SubmitButton } from '../../Reusable/Buttons/SearchButton';

// -----------------<END> imports module ends -----------------------//

// -----------------<START> Leave Search component starts-----------------------//
const LeaveSearch: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useFormStyles();
  const { register, handleSubmit, errors } = useForm();

  const [fromDate, setFromDate] = useState<string | null>(CUR_NEPALI_DATE);
  const [toDate, setToDate] = useState<string | null>(CUR_NEPALI_DATE);
  const [leaveType, setLeaveType] = useState<Tupple | null>(null);
  const [openPopup, setOpenPopup] = useState(false);

  const leaveTypeState = useSelector((state: RootStore) => state.leave_type);

  useEffect(() => {
    dispatch(GetLeaveTypes());
  }, []);

  const handleClose = (value: boolean) => {
    setOpenPopup(value);
  };

  const onSubmit = (data: any) => {
    if ((fromDate && toDate) || leaveType) {
      const query: any = {
        leaveType: leaveType ? leaveType.key : '',
        date_first: (fromDate && dateConverterBsToAd(fromDate)) || '',
        date_second: (toDate && dateConverterBsToAd(toDate)) || '',
      };

      dispatch(GetSearchedLeaves(query));
    }
  };

  const LEAVE_TYPE_CHOICES = leaveTypeState.leave_types.map((item) => ({
    key: item.id.toString(),
    value: item.name,
  }));

  const handleReset = () => {
    setFromDate('');
    setToDate('');
    setLeaveType(null);

    const query: any = {
      leaveType: '',
      date_first: '',
      date_second: '',
    };

    dispatch(GetLeaves());
  };

  return (
    <>
      <Paper className={classes.pageAttachContent}>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <SearchFormHeaderLayout
            title="Leave Requests"
            // btnTitle="Request New Leave"
            reset={handleReset}
            // onClick={() => setOpenPopup(true)}
          />

          <Grid container spacing={3}>
            <Grid item xs={3} className={classes.formWrapperRow}>
              <RowNepaliDatePicker
                label="From"
                value={fromDate}
                setValue={setFromDate}
              />
            </Grid>

            <Grid item xs={3} className={classes.formWrapperRow}>
              <RowNepaliDatePicker
                label="To"
                value={toDate}
                setValue={setToDate}
              />
            </Grid>

            <Grid item xs={3} className={classes.formWrapperRow}>
              <RowSelect
                fullWidth
                label="Leave"
                name="leaveType"
                options={LEAVE_TYPE_CHOICES}
                value={leaveType}
                setValue={setLeaveType}
              />
            </Grid>
            <SubmitButton />
          </Grid>
        </form>

        <Popups
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          onClose={handleClose}
          title="Request Leave"
          maxWidth="sm"
        >
          <LeaveRequestModal onClose={handleClose} />
        </Popups>
      </Paper>
    </>
  );
};

//-----------------<ENDS> Leave Search component ends-----------------------//
export default LeaveSearch;
