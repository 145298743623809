import { Button, Grid, makeStyles, Theme } from '@material-ui/core';
import { useForm, SubmitHandler } from 'react-hook-form';
import InputAdornment from '@material-ui/core/InputAdornment';
import PhoneIphoneOutlinedIcon from '@material-ui/icons/PhoneIphoneOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import AuthForm from '../Components/AuthForm';
import AuthBanner from '../Components/AuthBanner';
import AuthInputField from '../Components/AuthInputField';
import {
  GetUsersByPhoneNumber,
  SendOTPDetail,
} from '../../../actions/Auth/Login/LoginAction';
import { RootStore } from '../../../store';
import { useEffect, useState } from 'react';

interface FormValuesI {
  phone: string;
  password: string;
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    form: {
      marginTop: theme.spacing(6),
      maxWidth: 340,
    },
    link: {
      fontWeight: 600,
    },
  };
});

const PasswordRecovery = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValuesI>();

  const [formSubmitted, setFormSubmitted] = useState(false);
  const authenticationState = useSelector(
    (state: RootStore) => state.auth.authentication
  );
  const { loading, error } = authenticationState;

  useEffect(() => {
    if (authenticationState.phone_user && formSubmitted && !Boolean(error)) {
      const tempUser = {
        phone_number: authenticationState.phone_user.phone,
        user: authenticationState.phone_user.user,
      };
      if (
        authenticationState.loading === false &&
        authenticationState.phone_user &&
        authenticationState.otp_detail === null
      ) {
        dispatch(SendOTPDetail(tempUser));
      }
      setFormSubmitted(false);
    }
  }, [authenticationState, formSubmitted]);

  useEffect(() => {
    if (
      authenticationState.loading === false &&
      authenticationState.phone_user &&
      authenticationState.otp_detail
    ) {
      history.push('/authentication/otp-validation');
    }
  }, [authenticationState]);

  const onFormSubmit: SubmitHandler<any> = (data) => {
    dispatch(GetUsersByPhoneNumber(data));
    setFormSubmitted(true);
  };

  const MobileIcon = (
    <InputAdornment position="start">
      <PhoneIphoneOutlinedIcon />
    </InputAdornment>
  );

  return (
    <Grid container>
      <Grid item md={5}>
        <AuthForm
          title="Password Recovery"
          subtitle="Tell us your mobile number so we can send you an OTP."
          className={classes.form}
          onSubmit={handleSubmit(onFormSubmit)}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <AuthInputField
                required
                label="Mobile Number"
                startAdornment={MobileIcon}
                placeholder="Enter Mobile Number"
                register={register}
                name="phone_number"
                error={Boolean(errors.phone)}
                helperText={errors.phone && errors.phone.message}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
              >
                Send Verification Code
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button fullWidth component={Link} to="/login">
                Back to Login
              </Button>
            </Grid>
          </Grid>
        </AuthForm>
      </Grid>

      <Grid item md={7}>
        <AuthBanner
          id={1}
          title="Learn at your own comfort"
          subtitle="We are ready to serve you with our premium learning contents embedded in a cutting edge technology"
        />
      </Grid>
    </Grid>
  );
};

export default PasswordRecovery;
