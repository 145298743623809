export const GET_EXAM_SCHEDULE_LOADING = "GET_EXAM_SCHEDULE_LOADING";
export const GET_EXAM_SCHEDULE_ERROR = "GET_EXAM_SCHEDULE_ERROR";
export const GET_EXAM_SCHEDULE_SUCCESS = "GET_EXAM_SCHEDULE_SUCCESS";

export const GET_EXAM_SUBJECTS_LOADING = "GET_EXAM_SUBJECTS_LOADING";
export const GET_EXAM_SUBJECTS_ERROR = "GET_EXAM_SUBJECTS_ERROR";
export const GET_EXAM_SUBJECTS_SUCCESS = "GET_EXAM_SUBJECTS_SUCCESS";

export const UPDATE_EXAM_SCHEDULE_LOADING = "UPDATE_EXAM_SCHEDULE_LOADING";
export const UPDATE_EXAM_SCHEDULE_ERROR = "UPDATE_EXAM_SCHEDULE_ERROR";
export const UPDATE_EXAM_SCHEDULE_SUCCESS = "UPDATE_EXAM_SCHEDULE_SUCCESS";

export const DELETE_EXAM_SCHEDULE_LOADING = "DELETE_EXAM_SCHEDULE_LOADING";
export const DELETE_EXAM_SCHEDULE_ERROR = "DELETE_EXAM_SCHEDULE_ERROR";
export const DELETE_EXAM_SCHEDULE_SUCCESS = "DELETE_EXAM_SCHEDULE_SUCCESS";


export interface ExamSubjectInfo {
  duration: string;
  exam_date: string;
  exam_info: string;
  exam_info_name: string;
  grade: string;
  grade_name: string;
  id: string;
  start_time: string;
  subject: string;
  subject_name: string;
}

export interface SubjectInfo {
  credit_hour_practical: number;
  credit_hour_theory: number;
  credit_hours: string;
  full_marks_practical: number;
  full_marks_theory: number;
  grade: string;
  has_practical: boolean;
  id: string;
  pass_check: string;
  pass_marks_practical: number;
  pass_marks_theory: number;
  subject: string;
  subject_category: string;
  subject_status: boolean;
  subject_type: string;
}

export interface ExamScheduleTypeI {
  id: string;
  name: string;
  exam_subject_info: ExamSubjectInfo;
  subject_info: SubjectInfo;
}

//===============================<START>GET EXAM SCHEDULE<START>======================//

export interface GetExamScheduleLoading {
  type: typeof GET_EXAM_SCHEDULE_LOADING;
}

export interface GetExamScheduleError {
  type: typeof GET_EXAM_SCHEDULE_ERROR;
}

export interface GetExamScheduleSuccess {
  type: typeof GET_EXAM_SCHEDULE_SUCCESS;
  payload: ExamScheduleTypeI[];
}

//===============================<END>GET EXAM SCHEDULE<END>======================//

//===============================<START>ADD EXAM SCHEDULE<START>======================//

export interface GetExamSubjectsLoading {
  type: typeof GET_EXAM_SUBJECTS_LOADING;
}

export interface GetExamSubjectsError {
  type: typeof GET_EXAM_SUBJECTS_ERROR;
}

export interface GetExamSubjectsSuccess {
  type: typeof GET_EXAM_SUBJECTS_SUCCESS;
  payload: ExamScheduleTypeI[];
}

//===============================<END>ADD EXAM SCHEDULE<END>======================//

//===============================<START>UPDATE EXAM SCHEDULE<START>======================//

export interface UpdateExamScheduleLoading {
  type: typeof UPDATE_EXAM_SCHEDULE_LOADING;
}

export interface UpdateExamScheduleError {
  type: typeof UPDATE_EXAM_SCHEDULE_ERROR;
}

export interface UpdateExamScheduleSuccess {
  type: typeof UPDATE_EXAM_SCHEDULE_SUCCESS;
  payload: ExamScheduleTypeI;
}

//===============================<END>UPDATE EXAM SCHEDULE<END>======================//

//===============================<START>DELETE EXAM SCHEDULE<START>======================//

export interface DeleteExamScheduleLoading {
  type: typeof DELETE_EXAM_SCHEDULE_LOADING;
}

export interface DeleteExamScheduleError {
  type: typeof DELETE_EXAM_SCHEDULE_ERROR;
}

export interface DeleteExamScheduleSuccess {
  type: typeof DELETE_EXAM_SCHEDULE_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE EXAM SCHEDULE<END>======================//

export type ExamScheduleDispatchTypes =
  | GetExamScheduleLoading
  | GetExamScheduleError
  | GetExamScheduleSuccess
  | GetExamSubjectsLoading
  | GetExamSubjectsError
  | GetExamSubjectsSuccess
  | UpdateExamScheduleLoading
  | UpdateExamScheduleError
  | UpdateExamScheduleSuccess
  | DeleteExamScheduleLoading
  | DeleteExamScheduleError
  | DeleteExamScheduleSuccess;
