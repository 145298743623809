import React, { useState, useEffect } from 'react';
import { Paper, TableCell } from '@material-ui/core';
import moment from 'moment';
import { useTableStyles } from '../../Styles/TableStyles';
import { useTable, useTableWithPagination } from '../../Reusable';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import CustomizedDialogs from '../../Reusable/Dialogs/DeleteDialog';
import Popups from '../../Reusable/Dialogs/Popups';
import LeaveRequestView from './LeaveRequestView';
import cx from 'clsx';
import { LeaveTypeI } from '../../../actions/HumanResource/Leave/LeaveActionTypes';
import getFullName from '../../utils/getFullName';
import GetStatus from '../../../common/GetStatus';
import { HeadCellsI } from '../../../actions';
import {
  ItemDeleteButton,
  ItemViewButton,
} from '../../Reusable/Buttons/TableButton';
import { GetLeaveTypes } from '../../../actions/HumanResource/LeaveType/LeaveTypeAction';
import useLoading from '../../../useHook/useLoading';
import {
  DeleteLeave,
  GetLeaves,
  LeaveApproveOrDecline,
} from '../../../actions/Student/StudentLeave/LeaveAction';
import AccessPermission from '../StudentDetail/AccessPermission';

const headCells: HeadCellsI[] = [
  { id: 'sn', label: 'S.N' },
  { id: 'name', label: 'Student Name', align: 'left' },
  { id: 'leave_date', label: 'Leave Date', align: 'left' },
  { id: 'days', label: 'Days', align: 'right' },
  { id: 'applied_date', label: 'Applied Date', align: 'left' },
  { id: 'status', label: 'Status' },
  { id: 'action', label: 'Action' },
];

const LeaveList = () => {
  const classes = useTableStyles();
  const dispatch = useDispatch();

  const [tableData, setTableData] = useState<any>([]);
  const [itemId, setItemId] = useState<string | null>(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [leaveData, setLeaveData] = useState([]);
  const leaveSelector = useSelector((state: RootStore) => state.student_leave);
  const { loading } = useLoading(leaveSelector.loading);

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTableWithPagination(headCells);

  useEffect(() => {
    const params = { perPage: 5, page: 1 };
    dispatch(GetLeaves(params));
  }, []);

  useEffect(() => {
    const tempData = formatData(leaveSelector.leaves);
    console.log('tempData', tempData);
    setTableData(tempData);
  }, [leaveSelector]);

  const formatData = (items: any) => {
    if (items && items.results) {
      return items.results.map((item: any) => {
        const { first_name, last_name } =
          item.student_academic.student.student_user;

        return {
          id: item.id,
          name: first_name + ' ' + last_name,
          student_academic: item.student_academic,
          leave_type: 'Unknown Leave Type',
          // Testing new date utils in student leave list only
          // Please don't replicate this code for now
          // It's in development mode and has many errors

          // leave_from: dateConverterAdToBs(item.leave_from),
          // leave_to: dateConverterAdToBs(item.leave_to),
          // leave_date: dateConverterAdToBs(item.leave_from),
          // days: dateConverterAdToBs(item.leave_to),
          // applied_date: moment(dateConverterAdToBs(item.created_on)).format(
          //   PRIMARY_DATE_FORMAT
          // ),
          leave_from: new Date(item.leave_from).toNepali(),
          leave_to: new Date(item.leave_to).toNepali(),
          leave_date: new Date(item.leave_from).toNepali(),
          days: new Date(item.leave_to).toNepali(),
          applied_date: new Date(item.created_on).toNepali(),
          description: item.reason_for_leave,
          no_of_days: moment(item.leave_to).diff(item.leave_from, 'days') + 1,
          status: item.status,
          remarks: item.remarks,
          photo: '/favicons.png',
        };
      });
    }

    return [];
  };

  const handleViewModal = (item: any) => {
    setLeaveData(item);
    setItemId(item.id);
    setOpenPopup(true);
  };

  const handleClose = (value: boolean) => {
    setOpenPopup(value);
  };

  const onActionConfirmed = (confirmed: boolean, status: string) => {
    const data = {
      id: itemId,
      status,
    };
    confirmed && itemId != null && dispatch(LeaveApproveOrDecline(data));
    setOpenPopup(false);
  };

  const getCustomTableRow = (item: any, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index}
        </StyledTableCell>
        <StyledTableCell align="left" className={classes.cell}>
          {item.name}
        </StyledTableCell>
        <StyledTableCell align="left" className={classes.cell}>
          {item.leave_from} - {item.leave_to}
        </StyledTableCell>
        <StyledTableCell align="right" className={classes.cell}>
          {item.no_of_days}
        </StyledTableCell>
        <StyledTableCell align="left" className={classes.cell}>
          {item.applied_date}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <GetStatus status={item.status} />
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <AccessPermission>
            <ItemViewButton onClick={() => handleViewModal(item)} />
          </AccessPermission>
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  const handlePaginationChange = (page: number) => {
    const params = { page, perPage: 5 };
    dispatch(GetLeaves(params));
  };

  if (leaveSelector.leaves === null) {
    return <></>;
  }

  return (
    <Paper className={cx(classes.root, classes.attachFormTableRoot)}>
      <TableContainer
        label="Student Leaves"
        loading={loading}
        items={tableData}
        headCells={headCells}
        forPagination={leaveSelector.leaves}
        handlePaginationChange={handlePaginationChange}
        getCustomTableRow={getCustomTableRow}
        search_name="name"
      />

      <Popups
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        onClose={handleClose}
        maxWidth="sm"
        title="Student Leave Request"
        colored
      >
        <LeaveRequestView
          onActionConfirmed={onActionConfirmed}
          items={leaveData}
          onClose={handleClose}
        />
      </Popups>
    </Paper>
  );
};

export default LeaveList;
