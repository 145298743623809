//---------------------------- <START> module imports start ----------------------------//
import React, { useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  makeStyles,
  withStyles,
  createStyles,
  Theme,
  Checkbox,
  LinearProgress,
  Typography,
  TextField,
  TableBody,
  TableSortLabel,
  Grid,
  Box,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { useTableStyles } from "../Styles/TableStyles";
import TableActionButtons from "./Buttons/TableActionButtons";
import exportToExcel from "./Table/ExportToExcel";
import tablePrint from "./Table/PrintTable";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../store";
import { GetGeneralInfoData } from "../../actions/GeneralSettings/GeneralInfo/GeneralInfoAction";
//---------------------------- <END> module imports start ------------------------------//

//---------------------------- <START> interface start ------------------------------//

interface EnhancedTableProps {
  hasCheckbox?: boolean;
  numSelected?: number;
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount?: number;
  order?: any;
  orderBy?: any;
  onRequestSort?: any;
}

interface Data {
  sub_status: number;
}

interface HeadCellsProps {
  id: string;
  label: string;
  filter?: boolean;
  align?: "left" | "right" | "center";
}

type Order = "asc" | "desc";

interface TableContainerPropsI {
  items: any;
  headCells: any;
  getCustomTableRow: Function;
  label?: string;
  loading?: boolean;
  disableSearch?: boolean;
  disablePrint?: boolean;
  search_name?: string;
  disablePagination?: boolean;
  rowCount?: number;
  numSelected?: number;
  hasCheckbox?: boolean;
  rowsPerPage?: number;
  hiddenCols?: number[];
  handleSelectAllClicked?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  [x: string]: any;
}

//---------------------------- <END> interface ends ------------------------------//

// ---------------------------- <START> styling start ----------------------------------//
const useStyles = makeStyles((theme) => ({
  table: {
    borderTop: "1px solid #D6D6D6",
    marginTop: "12px",
    "& thead th": {
      fontSize: "13px !important",
      fontWeight: "600",
      color: "#4D4F5C",
      backgroundColor: "#e4e3e3",
      padding: "12px 15px",
    },
    "& tbody td": {
      fontSize: "13px !important",
      fontWeight: "400",
      padding: "5px 5px 5px 5px",
      color: "#6C6D6E",
    },
    "& tbody tr:hover": {
      fontSize: "13px !important",
      backgroundColor: "#fffbf2",
      cursor: "pointer",
    },
  },
  cell: {
    borderRight: "1px solid #D6D6D6",
  },
  headCell: {
    paddingLeft: "5px!important",
  },
}));

// ---------------------------- <END> styling ends ----------------------------------//

// ---------------------------- <START> re-usable component starts ---------------------------//
const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#00000017",
      color: "#211E1E",
      border: "none !important",
    },

    body: {
      border: "none !important",
      fontSize: "12px !important",
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(even)": {
        backgroundColor: "#f7f3f3",
      },
    },
  })
)(TableRow);

const useTable = (headCells: HeadCellsProps[]) => {
  const classes = useStyles();
  const tblClasses = useTableStyles();

  const [documentLabel, setDocumentLabel] = useState<string>("Excel_Sheet");

  // Making Table Container as a reusable
  const TblContainer = (props: any) => {
    return (
      <Table className={classes.table} id="export-table" {...props}>
        {props.children}
      </Table>
    );
  };

  // Making Table Heading as a reusable
  const TblHead = (props: EnhancedTableProps) => {
    const dispatch = useDispatch();

    const {
      hasCheckbox,
      onSelectAllClick,
      numSelected = 0,
      rowCount = 0,
      order,
      orderBy,
      onRequestSort,
    } = props;

    const CheckBox = () => (
      <Checkbox
        color="default"
        style={{ marginRight: "0" }}
        indeterminate={numSelected > 0 && numSelected < rowCount}
        checked={rowCount > 0 && numSelected === rowCount}
        inputProps={{ "aria-label": "select all desserts" }}
        onChange={onSelectAllClick}
      />
    );

    const generalInfoData = useSelector(
      (state: RootStore) => state.general_info?.general_info
    );

    const authData = useSelector((state: RootStore) => state.auth);

    // useEffect(() => {
    //   if (authData.general_info != null) {
    //     dispatch(GetGeneralInfoData(authData.general_info.id));
    //   }
    // }, [authData]);

    return (
      <>
        <div
          style={{
            marginTop: "5px",
            justifyContent: "space-between",
            paddingBottom: "30px",
            display: "none",
          }}
          className="hidden-school-name"
        >
          <div>
            <span style={{ fontSize: "20px" }}>
              <b style={{ textTransform: "uppercase" }}>
                {generalInfoData?.school_name}
              </b>
            </span>
            <br />
            <span>{generalInfoData?.address}</span>
            <br />
            <span>Tel/Fax: {generalInfoData?.phone_number}</span>
            <br />
            <span>Email: {generalInfoData?.email}</span>
            <br />
          </div>
        </div>
        <TableHead>
          <StyledTableRow>
            {headCells.map((element: HeadCellsProps, index: number) => {
              if (index === 0 && hasCheckbox && onSelectAllClick) {
                return (
                  <StyledTableCell
                    className={classes.headCell}
                    align="left"
                    key={element.id}
                  >
                    <CheckBox />
                    {element.label}
                  </StyledTableCell>
                );
              }

              return (
                <StyledTableCell
                  className={classes.cell}
                  align={element.align || "left"}
                  key={element.id}
                  sortDirection={orderBy === element.id ? order : false}
                >
                  {element.filter ? (
                    <TableSortLabel
                      active={orderBy === element.id}
                      direction={orderBy === element.id ? order : "asc"}
                      onClick={(event: React.MouseEvent<unknown>) =>
                        props.onRequestSort(event, element.id)
                      }
                    >
                      {element.label}
                    </TableSortLabel>
                  ) : (
                    element.label
                  )}
                </StyledTableCell>
              );
            })}
          </StyledTableRow>
        </TableHead>
      </>
    );
  };

  const TableContainer = (props: TableContainerPropsI) => {
    const {
      items,
      headCells,
      label,
      getCustomTableRow,
      disablePagination,
      disableSearch,
      disablePrint,
      hasCheckbox,
      rowCount,
      numSelected,
      handleSelectAllClicked,
      rowsPerPage: nRows = 7,
      loading: isLoading = false,
      search_name = "name",
      hiddenCols = [],
    } = props;

    const { TblContainer, TblHead, TblFoot } = useTable(headCells);

    // Modal States
    const [data, setData] = useState(items);
    const [totalRecord, setTotalRecord] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(nRows);
    const [pageCount, setPageCount] = useState(
      Math.ceil(totalRecord / rowsPerPage)
    );
    const [page, setPage] = useState(1);
    const [recordFrom, setRecordFrom] = useState(1);
    const [recordTo, setRecordTo] = useState(nRows);
    const [loading, setLoading] = useState<boolean>(false);

    const [order, setOrder] = useState<Order>("asc");
    const [orderBy, setOrderBy] = useState<keyof Data>("sub_status");

    useEffect(() => {
      label && setDocumentLabel(label);
    }, []);

    useEffect(() => {
      const dataLength = items?.length;
      setLoading(isLoading);

      setTotalRecord(dataLength);
    }, [items]);

    const handlePageChange = (event: unknown, newPage: number) => {
      setPage(newPage);
      setRecordFrom(newPage === 1 ? newPage : (newPage - 1) * rowsPerPage + 1);
      setRecordTo(
        newPage === 1 ? newPage * rowsPerPage : newPage * rowsPerPage
      );
    };

    function handleTableSearch(
      e: React.ChangeEvent<HTMLInputElement>,
      ...rest: any
    ) {
      const query = e.target.value.toUpperCase();
      const search_data = items.filter((data: any) =>
        data[rest[0]].toUpperCase().includes(query)
      );

      setTotalRecord(search_data?.length);
      setPageCount(Math.ceil(search_data?.length / rowsPerPage));
      setPage(1);
      setRecordFrom(1);
      setRecordTo(rowsPerPage);
      setData(search_data);
    }

    const tblFootProps = {
      loading,
      totalRecord,
      page,
      pageCount,
      recordTo,
      recordFrom,
      handlePageChange,
    };

    const handleRequestSort = (
      event: React.MouseEvent<unknown>,
      property: keyof Data
    ) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };

    function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    }

    function getComparator<Key extends keyof any>(
      order: Order,
      orderBy: Key
    ): (
      a: { [key in Key]: number | string },
      b: { [key in Key]: number | string }
    ) => number {
      return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
      const stabilizedThis = array.map(
        (el, index) => [el, index] as [T, number]
      );
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      return stabilizedThis.map((el) => el[0]);
    }

    return (
      <div className={tblClasses.tableWrapper}>
        <div className={tblClasses.tableTop}>
          <Grid container justifyContent="space-between" spacing={2}>
            <Grid item xs={4}>
              {!disableSearch && (
                <TextField
                  variant="outlined"
                  name="search"
                  autoComplete="off"
                  placeholder="Search ..."
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleTableSearch(e, search_name)
                  }
                />
              )}
            </Grid>

            <Grid item>
              {!disablePrint ? (
                <TableActionButtons
                  exportToExcel={() => exportToExcel(documentLabel)}
                  tablePrint={() => tablePrint(documentLabel, hiddenCols)}
                />
              ) : null}
            </Grid>
          </Grid>
        </div>

        <TblContainer>
          <TblHead
            hasCheckbox={hasCheckbox || false}
            rowCount={rowCount}
            numSelected={numSelected}
            onSelectAllClick={handleSelectAllClicked}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          {data?.length ? (
            <TableBody>
              {stableSort(data, getComparator(order, orderBy)).map(
                (item: any, index: number) => getCustomTableRow(item, index)
              )}
            </TableBody>
          ) : (
            <tbody>
              <tr>
                <td
                  colSpan={headCells?.length}
                  style={{
                    height: "10vh",
                    textAlign: "center",
                    background: "rgba(0,0,0,0.04)",
                  }}
                >
                  {/* <ReportProblemOutlined color="error" /> */}
                  <Box fontSize={12} fontWeight={500}>
                    No Data Found
                  </Box>
                </td>
              </tr>
            </tbody>
          )}
        </TblContainer>
        {!disablePagination && <TblFoot {...tblFootProps} />}
      </div>
    );
  };

  // Make table footer reusable
  const TblFoot = (props: any) => {
    const {
      loading,
      totalRecord,
      page,
      pageCount,
      recordTo,
      recordFrom,
      handlePageChange,
    } = props;
    return (
      <>
        {loading && <LinearProgress />}

        <div className={tblClasses.tableBottom}>
          <div className={tblClasses.pageNavigation}>
            <Pagination
              count={pageCount}
              page={page}
              onChange={handlePageChange}
              shape="rounded"
              color="primary"
            />
          </div>

          {totalRecord > 0 ? (
            <Typography>
              Showing {recordFrom} to{" "}
              {recordTo > totalRecord ? totalRecord : recordTo} of {totalRecord}
            </Typography>
          ) : (
            <Typography>No records found</Typography>
          )}
        </div>
      </>
    );
  };

  // returning re-usable components
  return {
    TblHead,
    TblContainer,
    TblFoot,
    StyledTableCell,
    StyledTableRow,
    exportToExcel,
    tablePrint,
    TableContainer,
  };
};
// ---------------------------- <END> re-usable component ends ----------------------------------//

export default useTable;
