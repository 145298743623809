import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_DATES_LOADING,
  GET_DATES_ERROR,
  GET_DATES_SUCCESS,
  GET_RUNNING_MONTH_DATE_LOADING,
  GET_RUNNING_MONTH_DATE_ERROR,
  GET_RUNNING_MONTH_DATE_SUCCESS,
  DatesDispatchTypes,
} from "./DatesActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../SnackbarActionTypes";
import { HeaderConfig } from "../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../config/SERVER_API_URLS";

export const GetDates =
  (id: any) =>
  async (dispatch: Dispatch<DatesDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_DATES_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/get_month_days?month=${id}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_DATES_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_DATES_ERROR,
      });
      console.log(error);
    }
  };

export const GetThisMonthDates =
  (id: any) =>
  async (dispatch: Dispatch<DatesDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_RUNNING_MONTH_DATE_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/get_month_days?month=${id}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_RUNNING_MONTH_DATE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      console.log({ error });
      dispatch({
        type: GET_RUNNING_MONTH_DATE_ERROR,
      });
      console.log(error);
    }
  };
