import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ExaminationSideBar } from "../../components";
import OptSubjectStudentForm from "../../components/Examination/OptSubjectStudents/OptSubjectStudentForm";
import OptSubjectStudentTable from "../../components/Examination/OptSubjectStudents/OptSubjectStudentTable";
import BackDropLoader from "../../components/Reusable/BackDropLoader";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";
import { RootStore } from "../../store";

const OptionalSubjectStudentPage = () => {
  const nav = <ExaminationSideBar assignOptSubjects />;

  const Loading1 = useSelector(
    (state: RootStore) => state.studentOptSubject.loading
  );
  const Loading2 = useSelector((state: RootStore) => state.class.loading);

  return (
    <SubModuleLayout examinationMenuActive sideNav={nav}>
      <OptSubjectStudentForm />
      <OptSubjectStudentTable />
      <BackDropLoader open={Loading1 || Loading2} />
    </SubModuleLayout>
  );
};

export default OptionalSubjectStudentPage;
