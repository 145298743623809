//---------------------<start> module imports starts ----------------------//
import {
  Grid,
  InputLabel,
  Paper,
  TextField,
  Button,
  Divider,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useFormStyles } from "../../Styles/FormStyles";
import { Autocomplete } from "@material-ui/lab";
import { Search } from "@material-ui/icons";
import { RootStore } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { GetClasses } from "../../../actions/Academics/Class/ClassAction";
import { GetSections } from "../../../actions/Academics/Section/SectionAction";
import {
  SearchStudent,
  StudentSearch,
} from "../../../actions/Student/Student/StudentAction";
import { useForm } from "react-hook-form";

// -----------------<end> module imports ends -----------------------//
// -----------------<start> Interfaces <start>-----------------------//
// -----------------<end> Interfaces <end>-----------------------//

// -----------------<starts> Selected Student Form starts-----------------------//
const SelectStudentForm = (props: any) => {
  const classes = useFormStyles();
  const [gradeChoices, setGradeChoices] = useState<any | null>([]);
  const [sectionChoices, setSectionChoices] = useState<any[] | []>([]);
  const [grade, setGrade] = useState<any | null>(null);
  const [section, setSection] = useState<any | null>(null);
  const [sectionDisabler, setSectionDisabler] = useState<boolean>(true);
  const [studentDisabler, setStudentDisabler] = useState<boolean>(true);
  const [studentChoices, setStudentChoices] = useState<any | null>(null);
  const [student, setStudent] = useState<any>(null);
  const [searchStudent, setSearchStudent] = useState<any>(null);

  const { register, handleSubmit, errors, setError, setValue, clearErrors } =
    useForm<any>({
      mode: "onChange",
    });

  const gradeList = useSelector((state: RootStore) => state.class.classes);
  const searchedStudent = useSelector(
    (state: RootStore) => state.student.searched_student
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetSections());
    dispatch(GetClasses());
  }, []);

  useEffect(() => {
    gradeList && setGradeChoices(gradeList);
  }, [gradeList]);

  const handleGradeChange = (value: any | null) => {
    if (value) {
      setGrade(value);
      populateSectionChoices(value.section);
      setStudentDisabler(false);

      dispatch(StudentSearch(value.grade, ""));
    } else {
      setSectionDisabler(true);
      setStudentDisabler(true);
    }
  };

  const populateSectionChoices = (sections: any | []) => {
    setSectionDisabler(false);
    sections.length > 0
      ? setSectionChoices(sections)
      : setSectionDisabler(true);
  };

  useEffect(() => {
    if (section != null && grade != null) {
      dispatch(StudentSearch(grade.grade, section.id));
    }
  }, [section]);

  useEffect(() => {
    if (searchedStudent != null) {
      setStudentChoices(searchedStudent);
    }
  }, [searchedStudent]);

  const handleSectionChange = (value: any) => {
    setSection(value);
    if (value === null) {
      setStudent(null);
    }
    value === null && setStudent(null);
  };

  const handleStudentChage = (value: any) => {
    setStudent(value);
    value != null
      ? clearErrors("student")
      : setError("student", {
        type: "required",
      });
  };

  const handleSearchStudent = (value: any) => {
    value && clearErrors("student");
    setSearchStudent(value);
  };

  const handleSearchBtnClick = () => {
    if (student || searchStudent) {
      student
        ? props.handleSearchClick(student)
        : props.handleSearchClick(searchStudent);
    } else {
      setError("student", {
        type: "required",
      });
    }
  };

  const handleSearchStudentByLetter = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const text = event.target.value;
    dispatch(SearchStudent(text, 1, 10));
  };

  const searchStudentSelector = useSelector(
    (state: RootStore) => state.student.student_search
  );

  return (
    <>
      <Paper className={classes.pageContent}>
        <form
          className={classes.form}
          onSubmit={handleSubmit(handleSearchBtnClick)}
        >
          <Grid container>
            <Grid item xs={12} className={classes.formTitle}>
              <span>Select Student</span>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={3} className={classes.formWrapper}>
              <InputLabel>Class</InputLabel>
              <Autocomplete
                onChange={(event: React.ChangeEvent<{}>, value: any | null) =>
                  handleGradeChange(value)
                }
                options={gradeChoices}
                getOptionLabel={(option) => "Class" + " " + option.grade_name}
                value={grade}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Class"
                    name="class"
                    variant="outlined"
                  />
                )}
              />
              <span className={classes.validationErrorInfo}>
                {errors.class?.type === "required" && "class must be selected"}
              </span>
            </Grid>

            <Grid item xs={3} className={classes.formWrapper}>
              <InputLabel>Section</InputLabel>
              <Autocomplete
                onChange={(event: React.ChangeEvent<{}>, value: any | null) =>
                  handleSectionChange(value)
                }
                options={sectionChoices}
                value={section && section}
                getOptionLabel={(option) => option.name}
                disabled={sectionDisabler}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Section"
                    name="section"
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            <Grid item xs={3} className={classes.formWrapper}>
              <InputLabel>Student</InputLabel>
              <Autocomplete
                onChange={(event: React.ChangeEvent<{}>, value: any | null) =>
                  handleStudentChage(value)
                }
                options={studentChoices}
                disabled={studentDisabler}
                getOptionLabel={(option) =>
                  `${option.student_first_name} ${option.student_last_name}`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Student"
                    name="student"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>

          <Grid container>
            <Grid item xs={9}>
              <Divider style={{ marginBottom: "10px" }} />
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={3} className={classes.formWrapper}>
              <InputLabel>Search Student</InputLabel>
              <Autocomplete
                onChange={(event: React.ChangeEvent<{}>, value: any | null) =>
                  handleSearchStudent(value)
                }
                options={searchStudentSelector}
                getOptionLabel={(option) =>
                  `${option.student_first_name} ${option.student_last_name}`
                }
                renderOption={(option) => (
                  <>
                    <span>
                      {option.student_first_name} {option.student_last_name} -
                      Class {option.grade}
                    </span>
                  </>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search Student"
                    variant="outlined"
                    onChange={handleSearchStudentByLetter}
                  />
                )}
              />
            </Grid>

            <Grid container>
              <Grid item xs={3}>
                <Divider style={{ marginBottom: "10px" }} />
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  startIcon={<Search />}
                >
                  Search
                </Button>
                <span className={classes.validationErrorInfo}>
                  {errors.student?.type === "required" &&
                    "student must be selected"}
                </span>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
};
// -----------------<end> Selected Student form ends-----------------------//
export default SelectStudentForm;
