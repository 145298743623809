import { SectionTypeI } from "../../Academics/Section/SectionActionTypes";

export const GET_ANNOUNCEMENT_LOADING = "GET_ANNOUNCEMENT_LOADING";
export const GET_ANNOUNCEMENT_ERROR = "GET_ANNOUNCEMENT_ERROR";
export const GET_ANNOUNCEMENT_SUCCESS = "GET_ANNOUNCEMENT_SUCCESS";

export const ADD_ANNOUNCEMENT_LOADING = "ADD_ANNOUNCEMENT_LOADING";
export const ADD_ANNOUNCEMENT_ERROR = "ADD_ANNOUNCEMENT_ERROR";
export const ADD_ANNOUNCEMENT_SUCCESS = "ADD_ANNOUNCEMENT_SUCCESS";

export const UPDATE_ANNOUNCEMENT_LOADING = "UPDATE_ANNOUNCEMENT_LOADING";
export const UPDATE_ANNOUNCEMENT_ERROR = "UPDATE_ANNOUNCEMENT_ERROR";
export const UPDATE_ANNOUNCEMENT_SUCCESS = "UPDATE_ANNOUNCEMENT_SUCCESS";

export const DELETE_ANNOUNCEMENT_LOADING = "DELETE_ANNOUNCEMENT_LOADING";
export const DELETE_ANNOUNCEMENT_ERROR = "DELETE_ANNOUNCEMENT_ERROR";
export const DELETE_ANNOUNCEMENT_SUCCESS = "DELETE_ANNOUNCEMENT_SUCCESS";

export const GET_ANNOUNCEMENT_DRAFT_LOADING = "GET_ANNOUNCEMENT_DRAFT_LOADING";
export const GET_ANNOUNCEMENT_DRAFT_ERROR = "GET_ANNOUNCEMENT_DRAFT_ERROR";
export const GET_ANNOUNCEMENT_DRAFT_SUCCESS = "GET_ANNOUNCEMENT_DRAFT_SUCCESS";

export const SAVE_ANNOUNCEMENT_DRAFT_LOADING =
  "SAVE_ANNOUNCEMENT_DRAFT_LOADING";
export const SAVE_ANNOUNCEMENT_DRAFT_ERROR = "SAVE_ANNOUNCEMENT_DRAFT_ERROR";
export const SAVE_ANNOUNCEMENT_DRAFT_SUCCESS =
  "SAVE_ANNOUNCEMENT_DRAFT_SUCCESS";

export const GET_ANNOUNCEMENT_DRAFT_BY_ID_LOADING =
  "GET_ANNOUNCEMENT_DRAFT_BY_ID_LOADING";
export const GET_ANNOUNCEMENT_DRAFT_BY_ID_ERROR =
  "GET_ANNOUNCEMENT_DRAFT_BY_ID_ERROR";
export const GET_ANNOUNCEMENT_DRAFT_BY_ID_SUCCESS =
  "GET_ANNOUNCEMENT_DRAFT_BY_ID_SUCCESS";

export const UPDATE_ANNOUNCEMENT_DRAFT_LOADING =
  "UPDATE_ANNOUNCEMENT_DRAFT_LOADING";
export const UPDATE_ANNOUNCEMENT_DRAFT_ERROR =
  "UPDATE_ANNOUNCEMENT_DRAFT_ERROR";
export const UPDATE_ANNOUNCEMENT_DRAFT_SUCCESS =
  "UPDATE_ANNOUNCEMENT_DRAFT_SUCCESS";

export type AnnouncementTypeI = {
  id: number;
  priority: string;
  priority_display_name: string;
  title: string;
  meta_description: string | null;
  announcement_date: string;
  announcement_time: string;
  announcement_category: string | null;
  announcement_category_name: string;
  featured_image: string | null;
  created_on: string;
  start_time: string;
  end_time: string;
  description: string;
  group: GroupTypeI[];
  grade: string | null;
  grade_name: string;
  section: SectionTypeI[];
  user: UserTypeI[];
  user_group: string | null;
  sent_to_parent: boolean;
  sent_to_student: boolean;
};

type GroupTypeI = {
  id: string;
  name: string;
};

type UserTypeI = {
  id: string;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  general_info: string;
};
//===============================<START>GET ANNOUNCEMENT <START>======================//

export interface GetAnnouncementLoading {
  type: typeof GET_ANNOUNCEMENT_LOADING;
}

export interface GetAnnouncementError {
  type: typeof GET_ANNOUNCEMENT_ERROR;
}

export interface GetAnnouncementSuccess {
  type: typeof GET_ANNOUNCEMENT_SUCCESS;
  payload: {
    current_page: number;
    total: number;
    per_page: number;
    total_pages: number;
    results: AnnouncementTypeI[];
  };
}

//===============================<END>GET ANNOUNCEMENT <END>======================//

//===============================<START>GET ANNOUNCEMENT <START>======================//

export interface GetAnnouncementDraftLoading {
  type: typeof GET_ANNOUNCEMENT_DRAFT_LOADING;
}

export interface GetAnnouncementDraftError {
  type: typeof GET_ANNOUNCEMENT_DRAFT_ERROR;
}

export interface GetAnnouncementDraftSuccess {
  type: typeof GET_ANNOUNCEMENT_DRAFT_SUCCESS;
  payload: {
    current_page: number;
    total: number;
    per_page: number;
    total_pages: number;
    results: AnnouncementTypeI[];
  };
}

//===============================<END>GET ANNOUNCEMENT <END>======================//

//===============================<START>GET ANNOUNCEMENT <START>======================//

export interface GetAnnouncementDraftByIdLoading {
  type: typeof GET_ANNOUNCEMENT_DRAFT_BY_ID_LOADING;
}

export interface GetAnnouncementDraftByIdError {
  type: typeof GET_ANNOUNCEMENT_DRAFT_BY_ID_ERROR;
}

export interface GetAnnouncementDraftByIdSuccess {
  type: typeof GET_ANNOUNCEMENT_DRAFT_BY_ID_SUCCESS;
  payload: AnnouncementTypeI;
}

//===============================<END>GET ANNOUNCEMENT <END>======================//

//===============================<START>GET ANNOUNCEMENT <START>======================//

export interface UpdateAnnouncementDraftLoading {
  type: typeof UPDATE_ANNOUNCEMENT_DRAFT_LOADING;
}

export interface UpdateAnnouncementDraftError {
  type: typeof UPDATE_ANNOUNCEMENT_DRAFT_ERROR;
}

export interface UpdateAnnouncementDraftSuccess {
  type: typeof UPDATE_ANNOUNCEMENT_DRAFT_SUCCESS;
  payload: AnnouncementTypeI;
}

//===============================<END>GET ANNOUNCEMENT <END>======================//

//===============================<START>GET ANNOUNCEMENT <START>======================//

export interface SaveAnnouncementDraftLoading {
  type: typeof SAVE_ANNOUNCEMENT_DRAFT_LOADING;
}

export interface SaveAnnouncementDraftError {
  type: typeof SAVE_ANNOUNCEMENT_DRAFT_ERROR;
}

export interface SaveAnnouncementDraftSuccess {
  type: typeof SAVE_ANNOUNCEMENT_DRAFT_SUCCESS;
  payload: AnnouncementTypeI;
}

//===============================<END>GET ANNOUNCEMENT <END>======================//

//===============================<START>ADD ANNOUNCEMENT <START>======================//

export interface AddAnnouncementLoading {
  type: typeof ADD_ANNOUNCEMENT_LOADING;
}

export interface AddAnnouncementError {
  type: typeof ADD_ANNOUNCEMENT_ERROR;
}

export interface AddAnnouncementSuccess {
  type: typeof ADD_ANNOUNCEMENT_SUCCESS;
}

//===============================<END>ADD ANNOUNCEMENT <END>======================//

//===============================<START>UPDATE ANNOUNCEMENT <START>======================//

export interface UpdateAnnouncementLoading {
  type: typeof UPDATE_ANNOUNCEMENT_LOADING;
}

export interface UpdateAnnouncementError {
  type: typeof UPDATE_ANNOUNCEMENT_ERROR;
}

export interface UpdateAnnouncementSuccess {
  type: typeof UPDATE_ANNOUNCEMENT_SUCCESS;
  payload: AnnouncementTypeI;
}

//===============================<END>UPDATE ANNOUNCEMENT <END>======================//

//===============================<START>DELETE ANNOUNCEMENT <START>======================//

export interface DeleteAnnouncementLoading {
  type: typeof DELETE_ANNOUNCEMENT_LOADING;
}

export interface DeleteAnnouncementError {
  type: typeof DELETE_ANNOUNCEMENT_ERROR;
}

export interface DeleteAnnouncementSuccess {
  type: typeof DELETE_ANNOUNCEMENT_SUCCESS;
  payload: { id: number };
}

//===============================<END>DELETE ANNOUNCEMENT <END>======================//

export type AnnouncementDispatchTypes =
  | GetAnnouncementLoading
  | GetAnnouncementError
  | GetAnnouncementSuccess
  | AddAnnouncementLoading
  | AddAnnouncementError
  | AddAnnouncementSuccess
  | UpdateAnnouncementLoading
  | UpdateAnnouncementError
  | UpdateAnnouncementSuccess
  | DeleteAnnouncementLoading
  | DeleteAnnouncementError
  | DeleteAnnouncementSuccess
  | GetAnnouncementDraftError
  | GetAnnouncementDraftLoading
  | GetAnnouncementDraftSuccess
  | GetAnnouncementDraftByIdError
  | GetAnnouncementDraftByIdLoading
  | GetAnnouncementDraftByIdSuccess
  | SaveAnnouncementDraftError
  | SaveAnnouncementDraftLoading
  | SaveAnnouncementDraftSuccess
  | UpdateAnnouncementDraftError
  | UpdateAnnouncementDraftLoading
  | UpdateAnnouncementDraftSuccess;
