import {
  Button,
  ButtonGroup,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import TemplateStyles from '../../../Reports/Templates/IdCard/TemplateStyles';
import { useTableStyles } from '../../../Styles/TableStyles';
import FeeType from './FeeType';

const FinanceTabContent = (props: any) => {
  const classes = useTableStyles();
  const templateClasses = TemplateStyles();

  const { studentID, info } = props;

  const [invoiceSelected, setInvoiceSelected] = useState<boolean>(true);
  const [statementSelected, setStatementSelected] = useState<boolean>(false);

  const handleInvoiceCLicked = () => {
    setInvoiceSelected(true);
    setStatementSelected(false);
  };

  const handleStatementCLicked = () => {
    setInvoiceSelected(false);
    setStatementSelected(true);
  };

  return (
    <div>
      <Paper className={classes.roottable}>
        <Typography variant="h6">Student Finance</Typography>
        <Grid container justifyContent="center" style={{ marginTop: '15px' }}>
          <ButtonGroup
            color="primary"
            aria-label="outlined primary button group"
          >
            <Button
              className={
                invoiceSelected
                  ? templateClasses.activeTab
                  : templateClasses.nonactiveTab
              }
              onClick={() => handleInvoiceCLicked()}
            >
              Fee Invoices
            </Button>
            <Button
              className={
                statementSelected
                  ? templateClasses.activeTab
                  : templateClasses.nonactiveTab
              }
              onClick={() => handleStatementCLicked()}
            >
              Fee Statements
            </Button>
          </ButtonGroup>
        </Grid>
        {statementSelected ? (
          <FeeType studentID={studentID} info={info} invoiceMode={false} />
        ) : (
          <FeeType studentID={studentID} info={info} invoiceMode={true} />
        )}
      </Paper>
    </div>
  );
};

export default FinanceTabContent;
