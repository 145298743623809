import axios from "axios";
import { Dispatch } from "redux";

import {
  GuardianDispatchTypes,
  GET_GUARDIAN_LOADING,
  GET_GUARDIAN_ERROR,
  GET_GUARDIAN_SUCCESS,
  GUARDIAN_BY_ID_LOADING,
  GUARDIAN_BY_ID_ERROR,
  GUARDIAN_BY_ID_SUCCESS,
  ADD_GUARDIAN_LOADING,
  ADD_GUARDIAN_SUCCESS,
  ADD_GUARDIAN_ERROR,
  DELETE_GUARDIAN_SUCCESS,
  DELETE_GUARDIAN_ERROR,
  DELETE_GUARDIAN_LOADING,
  UPDATE_GUARDIAN_LOADING,
  UPDATE_GUARDIAN_ERROR,
  UPDATE_GUARDIAN_SUCCESS,
  GET_CHILDREN_LOADING,
  GET_CHILDREN_ERROR,
  GET_CHILDREN_SUCCESS,
  UPDATE_GUARDIAN_USERNAME_LOADING,
  UPDATE_GUARDIAN_USERNAME_ERROR,
  UPDATE_GUARDIAN_USERNAME_SUCCESS,
} from "./GuardianActionTypes";
import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

const config = {
  headers: {
    Authorization: "Token 1f132c9ed042d27e682a0cf38df9e188b23248e5",
  },
};

interface RequestDataI {
  id: string;
  name: string;
}

export const GetGuardian =
  (id: number) =>
  async (dispatch: Dispatch<GuardianDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_GUARDIAN_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/guardian_info/?page=${id}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_GUARDIAN_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_GUARDIAN_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Guardian Loading Failed",
        },
      });
    }
  };

export const GetGuardianAll =
  (id: number) =>
  async (dispatch: Dispatch<GuardianDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_GUARDIAN_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/guardian_info/?page=${id}&per_page=900`,
        HeaderConfig()
      );

      dispatch({
        type: GET_GUARDIAN_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_GUARDIAN_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Guardian Loading Failed",
        },
      });
    }
  };

export const GetSearchedGuardian =
  (query: any) =>
  async (dispatch: Dispatch<GuardianDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_GUARDIAN_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/guardian_info/?grade=${query.class}&section=${query.section}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_GUARDIAN_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_GUARDIAN_ERROR,
      });
    }
  };

export const GetGuardianByName =
  (query: any) =>
  async (dispatch: Dispatch<GuardianDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_GUARDIAN_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/guardian_info/?search=${query}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_GUARDIAN_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_GUARDIAN_ERROR,
      });
    }
  };

export const GetGuardianByID =
  (id: any) =>
  async (dispatch: Dispatch<GuardianDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GUARDIAN_BY_ID_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/guardian_info/${id}`,
        HeaderConfig()
      );

      dispatch({
        type: GUARDIAN_BY_ID_SUCCESS,
        payload: res.data,
      });
    } catch (error: any) {
      dispatch({
        type: GUARDIAN_BY_ID_ERROR,
      });

      try {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: error.response.statusText,
          },
        });
      } catch (error) {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "There is an error in the server",
          },
        });
      }
    }
  };

export const AddGuardian =
  (data: any) =>
  async (dispatch: Dispatch<GuardianDispatchTypes | SetSnackBarI | any>) => {
    try {
      dispatch({
        type: ADD_GUARDIAN_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/guardian_info/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_GUARDIAN_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Guardian has been added successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: ADD_GUARDIAN_ERROR,
        payload: error.response && error.response?.data,
      });
    }
  };

export const updateGuardian =
  (id: any, data: any) =>
  async (dispatch: Dispatch<GuardianDispatchTypes | SetSnackBarI | any>) => {
    try {
      dispatch({
        type: UPDATE_GUARDIAN_LOADING,
      });

      const res = await axios.put(
        `${ADMIN_API_URL}/guardian_info/${id}/`,
        data,
        HeaderConfig()
      );
      dispatch({
        type: UPDATE_GUARDIAN_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Guardian Updated Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: UPDATE_GUARDIAN_ERROR,
        payload: error.response && error.response?.data,
      });
    }
  };

export const DeleteGuardian =
  (id: string) =>
  async (dispatch: Dispatch<GuardianDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_GUARDIAN_LOADING,
      });

      const res = await axios.delete(
        `${ADMIN_API_URL}/guardian_info/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_GUARDIAN_SUCCESS,
        payload: { id: id },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Guardian Deleted Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_GUARDIAN_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Guardian Delete Failed",
        },
      });
    }
  };

export const GetChildren =
  (id: string) =>
  async (dispatch: Dispatch<GuardianDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_CHILDREN_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/guardian_info/${id}/get_childrens/`,
        HeaderConfig()
      );

      dispatch({
        type: GET_CHILDREN_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_CHILDREN_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Cannot get children data.",
        },
      });
    }
  };

export const updateGuardianUsername =
  (data: { user: string; updated_username: string }) =>
  async (dispatch: Dispatch<GuardianDispatchTypes | SetSnackBarI | any>) => {
    try {
      dispatch({
        type: UPDATE_GUARDIAN_USERNAME_LOADING,
      });

      await axios.post(
        `${ADMIN_API_URL}/update-username/`,
        data,
        HeaderConfig()
      );
      dispatch({
        type: UPDATE_GUARDIAN_USERNAME_SUCCESS,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Username Updated Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: UPDATE_GUARDIAN_USERNAME_ERROR,
        payload: error.response && error.response?.data,
      });
    }
  };
