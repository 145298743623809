import {
  GET_DEPARTMENT_LOADING,
  GET_DEPARTMENT_ERROR,
  GET_DEPARTMENT_SUCCESS,
  ADD_DEPARTMENT_LOADING,
  ADD_DEPARTMENT_ERROR,
  ADD_DEPARTMENT_SUCCESS,
  DELETE_DEPARTMENT_LOADING,
  DELETE_DEPARTMENT_ERROR,
  DELETE_DEPARTMENT_SUCCESS,
  UPDATE_DEPARTMENT_LOADING,
  UPDATE_DEPARTMENT_ERROR,
  UPDATE_DEPARTMENT_SUCCESS,
  DepartmentTypeI,
  DepartmentDispatchTypes,
} from "../../actions/HumanResource/Department/DepartmentActionTypes";

interface InitialStateI {
  loading: boolean;
  departments: DepartmentTypeI[];
  add_or_update: boolean;
}

const initialState: InitialStateI = {
  loading: false,
  departments: [],
  add_or_update: false,
};

const DepartmentReducer = (
  state: InitialStateI = initialState,
  action: DepartmentDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_DEPARTMENT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_DEPARTMENT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_DEPARTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        departments: action.payload,
      };

    case ADD_DEPARTMENT_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case ADD_DEPARTMENT_ERROR:
      return {
        ...state,
        loading: false,
        add_or_update: true,
      };

    case ADD_DEPARTMENT_SUCCESS:
      return {
        loading: false,
        departments: [...state.departments, action.payload],
        add_or_update: true,
      };

    case UPDATE_DEPARTMENT_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case UPDATE_DEPARTMENT_ERROR:
      return {
        ...state,
        loading: false,
        add_or_update: true,
      };

    case UPDATE_DEPARTMENT_SUCCESS:
      const current_data: DepartmentTypeI[] = state.departments;
      const index: number = current_data.findIndex(
        (element) => element.id === action.payload.id
      );
      current_data[index] = action.payload;

      return {
        loading: false,
        departments: current_data,
        add_or_update: true,
      };

    case DELETE_DEPARTMENT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_DEPARTMENT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_DEPARTMENT_SUCCESS:
      const new_data: DepartmentTypeI[] = state.departments;
      const item_index = new_data.findIndex(
        (element) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return {
        ...state,
        loading: false,
        departments: new_data,
      };

    default:
      return state;
  }
};

export default DepartmentReducer;
