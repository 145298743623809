import React, { useEffect, useState } from "react";
import {
  Paper,
  Grid,
  Button,
  Typography,
  Divider,
  Box,
  InputLabel,
  TextField,
} from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useFormStyles } from "../../Styles/FormStyles";
import { Autocomplete } from "@material-ui/lab";
import { ClassTypeI } from "../../../actions/Academics/Class/ClassActionTypes";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import { SectionTypeI } from "../../../actions/Academics/Section/SectionActionTypes";
import { useForm } from "react-hook-form";
import TimetableTabs from "../TimeTable/TimetableModal/TimeTableTabs/TimetableTabs";
import { GetClasses } from "../../../actions/Academics/Class/ClassAction";
import TimeTablePreview from "./TimeTablePreview";
import NewTimeTablePreview from "./NewTimeTablePreview";

interface ClassChoiceI {
  gradeId: string | null;
  section: string | null;
  classId: string | null;
  hasSection: boolean;
}

const CreateTimeTableForm = () => {
  const classes = useFormStyles();
  const { register, handleSubmit, errors, setValue } = useForm();
  const dispatch = useDispatch();

  const classSelector = useSelector((state: RootStore) => state.class);

  const [classChoice, setClassChoice] = useState<ClassChoiceI>({
    classId: null,
    gradeId: null,
    section: null,
    hasSection: false,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [classChoices, setClassChoices] = useState<ClassTypeI[]>([]);
  const [selectedClass, setSelectedClass] = useState<ClassTypeI | null>(null);

  const [sectionChoices, setSectionChoices] = useState<SectionTypeI[] | []>([]);
  const [sectionDisabler, setSectionDisabler] = useState<boolean>(true);
  const [selectedSection, setSelectedSection] = useState<SectionTypeI | null>(
    null
  );
  const [formData, setFormData] = useState<any>();
  const [day, setDay] = useState<number | null>(null);

  useEffect(() => {
    dispatch(GetClasses());
  }, []);

  useEffect(() => {
    setLoading(classSelector.loading);
    setClassChoices(classSelector.classes);
  }, [classSelector]);

  const handleSectionChoice = (selectedClass: any, selectedSection: any) => {
    setClassChoice({
      ...classChoice,
      gradeId: selectedClass != null ? selectedClass.grade : null,
      section: selectedSection != null ? selectedSection.id : null,
      classId: selectedClass != null ? selectedClass.id : null,
    });
    const form_data = {
      gradeID: selectedClass != null ? selectedClass.grade : null,
      sectionID: selectedSection != null ? selectedSection.id : null,
      grade: selectedClass != null ? selectedClass.grade_name : "",
      section: selectedSection != null ? selectedSection.name : "",
    };
    setFormData(form_data);
  };

  const handleClassChange = (value: ClassTypeI | null) => {
    setSelectedSection(null);
    if (value) {
      setSelectedClass(value);
      if (value.section.length) {
        populateSectionChoices(value.section);
        setClassChoice({
          classId: value.id,
          section: null,
          gradeId: value.grade,
          hasSection: true,
        });
      } else {
        handleSectionChoice(value, null);
        setSectionDisabler(true);
        setClassChoice({
          classId: value.id,
          section: null,
          gradeId: value.grade,
          hasSection: false,
        });
      }
    } else {
      setSelectedClass(null);
      setClassChoice({
        classId: null,
        section: null,
        gradeId: null,
        hasSection: false,
      });
    }
  };

  const populateSectionChoices = (sections: SectionTypeI[] | []) => {
    setSectionDisabler(false);
    sections.length > 0
      ? setSectionChoices(sections)
      : setSectionDisabler(true);
  };

  const handleSectionChange = (value: SectionTypeI | null) => {
    setSelectedSection(value);
    handleSectionChoice(selectedClass, value);
  };

  const onChooseDay = (value: number) => {
    if (value === 0) {
      setDay(7);
    } else {
      setDay(value);
    }
  };

  return (
    <>
      <Paper className={classes.pageContentBox}>
        <Grid container spacing={1} justifyContent="space-between">
          <Button
            variant="outlined"
            color="primary"
            to="/pre-timetable"
            component={Link}
          >
            <ArrowBackIos fontSize="small" /> Pre-TimeTable
          </Button>
        </Grid>
        <Grid container style={{ marginTop: "1rem" }}>
          <Grid item xs={12}>
            <Typography gutterBottom variant="h6">
              Create New TimeTable
            </Typography>
            <Divider />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box component="p" color="#132E98" fontSize={16} fontWeight={500}>
            TimeTable Form
          </Box>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={3} className={classes.formWrapper}>
            <InputLabel>Select Class</InputLabel>
            <Autocomplete
              onChange={(
                event: React.ChangeEvent<{}>,
                value: ClassTypeI | null
              ) => handleClassChange(value)}
              options={classChoices}
              getOptionLabel={(option) => option.grade_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Label"
                  name="class_name"
                  inputRef={register({ required: true })}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={2} className={classes.formWrapper}>
            <InputLabel>
              Select Section
              {!sectionDisabler}
            </InputLabel>
            <Autocomplete
              onChange={(
                event: React.ChangeEvent<{}>,
                value: SectionTypeI | null
              ) => handleSectionChange(value)}
              options={sectionChoices}
              getOptionLabel={(option) => option.name}
              disabled={sectionDisabler}
              value={selectedSection}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Label"
                  name="session_name"
                  inputRef={register}
                  variant="outlined"
                />
              )}
            />
          </Grid>
        </Grid>

        <TimetableTabs onChooseDay={onChooseDay} classChoice={classChoice} />
      </Paper>
      <NewTimeTablePreview />
      {/* <TimeTablePreview searchData={classChoice} /> */}
    </>
  );
};

export default CreateTimeTableForm;
