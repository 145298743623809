import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { ExpensesTypeI } from "../../actions/Fees/Expenses/ExpenseActionTypes";
import ExpensesForm from "../../components/Finance/Expenses/ExpensesForm";
import ExpensesTable from "../../components/Finance/Expenses/ExpensesTable";
import FinanceSidebar from "../../components/Finance/FinanceSidebar";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const IncomePage = () => {
  const nav = <FinanceSidebar Expenses />;

  const [formData, setFormData] = useState<ExpensesTypeI | null>(null);
  const [count, setCount] = useState<number>(0);
  const [edit, setEdit] = useState<any>();

  const fetchEditData = (data: ExpensesTypeI) => {
    setFormData({ ...data, count: count });
    setCount(count + 1);
  };

  const onEditCheck = (value: boolean) => {
    setEdit({ check: value, count: count });
  };

  return (
    <>
      <SubModuleLayout sideNav={nav}>
        <Grid container>
          <Grid item xs={3}>
            <ExpensesForm onEditCheck={onEditCheck} editData={formData} />
          </Grid>
          <Grid item xs={9}>
            <ExpensesTable edit={edit} onEditData={fetchEditData} />
          </Grid>
        </Grid>
      </SubModuleLayout>
    </>
  );
};

export default IncomePage;
