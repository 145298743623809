import {
  GET_ANNOUNCEMENT_TYPE_LOADING,
  GET_ANNOUNCEMENT_TYPE_ERROR,
  GET_ANNOUNCEMENT_TYPE_SUCCESS,
  AnnouncementTypeDispatchTypes,
  ADD_ANNOUNCEMENT_TYPE_LOADING,
  ADD_ANNOUNCEMENT_TYPE_ERROR,
  ADD_ANNOUNCEMENT_TYPE_SUCCESS,
  DELETE_ANNOUNCEMENT_TYPE_LOADING,
  DELETE_ANNOUNCEMENT_TYPE_ERROR,
  DELETE_ANNOUNCEMENT_TYPE_SUCCESS,
  UPDATE_ANNOUNCEMENT_TYPE_LOADING,
  UPDATE_ANNOUNCEMENT_TYPE_ERROR,
  UPDATE_ANNOUNCEMENT_TYPE_SUCCESS,
} from "../../actions/Communication/AnnouncementType/AnnouncementTypeActionTypes";

interface AnnouncementTypeI {
  id: string;
  name: string;
}

interface InitialStateI {
  loading: boolean;
  announcement_types: AnnouncementTypeI[];
  actionPerformed: boolean;
  add_or_update: boolean;
}

const initialState: InitialStateI = {
  loading: false,
  announcement_types: [],
  actionPerformed: false,
  add_or_update: false,
};

const AnnouncementTypeReducer = (
  state: InitialStateI = initialState,
  action: AnnouncementTypeDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_ANNOUNCEMENT_TYPE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_ANNOUNCEMENT_TYPE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_ANNOUNCEMENT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        announcement_types: action.payload,
      };

    case ADD_ANNOUNCEMENT_TYPE_LOADING:
      return {
        ...state,
        loading: true,
        actionPerformed: false,
        add_or_update: false,
      };

    case ADD_ANNOUNCEMENT_TYPE_ERROR:
      return {
        ...state,
        loading: false,
        actionPerformed: false,
        add_or_update: true,
      };

    case ADD_ANNOUNCEMENT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        actionPerformed: true,
        announcement_types: [...state.announcement_types, action.payload],
        add_or_update: true,
      };

    case UPDATE_ANNOUNCEMENT_TYPE_LOADING:
      return {
        ...state,
        loading: true,
        actionPerformed: false,
        add_or_update: false,
      };

    case UPDATE_ANNOUNCEMENT_TYPE_ERROR:
      return {
        ...state,
        loading: false,
        actionPerformed: false,
        add_or_update: true,
      };

    case UPDATE_ANNOUNCEMENT_TYPE_SUCCESS:
      const current_data: AnnouncementTypeI[] = state.announcement_types;
      const index: number = current_data.findIndex(
        (element) => element.id === action.payload.id
      );
      current_data[index] = action.payload;

      return {
        ...state,
        loading: false,
        actionPerformed: true,
        announcement_types: current_data,
        add_or_update: true,
      };

    case DELETE_ANNOUNCEMENT_TYPE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_ANNOUNCEMENT_TYPE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_ANNOUNCEMENT_TYPE_SUCCESS:
      const new_data: AnnouncementTypeI[] = state.announcement_types;
      const item_index = new_data.findIndex(
        (element) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return {
        ...state,
        loading: false,
        announcement_types: new_data,
      };

    default:
      return state;
  }
};

export default AnnouncementTypeReducer;
