import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setSnackbar } from "../../../actions/SnackbarAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

export const getScheduledSms = createAsyncThunk(
  "getScheduledSms",
  async (
    data: { page: number; per_page: number },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.get(
        `${ADMIN_API_URL}/send_sms/?type=scheduled&page=${data.page}&per_page=${data.per_page}`
      );
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(null);
    }
  }
);

export const getSmsLog = createAsyncThunk(
  "getSmsLog",
  async (
    data: {
      page: number;
      per_page: number;
      from_date: string;
      to_date: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.get(
        `${ADMIN_API_URL}/sms_log/?from_date=${data.from_date}&to_date=${data.to_date}&page=${data.page}&per_page=${data.per_page}`
      );
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(null);
    }
  }
);

export const sendSms = createAsyncThunk(
  "sendSms",
  async (data: any, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.post(`${ADMIN_API_URL}/send_sms/`, data);
      dispatch(setSnackbar(true, "success", "Sms Created Successfully"));
      return res.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(null);
    }
  }
);
