import React, { useState, useEffect } from "react";
import { Paper, Typography } from "@material-ui/core";
import { useTable } from "../../Reusable";
import { useTableStyles } from "../../Styles/TableStyles";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import {
  GetLeaveTypes,
  DeleteLeaveType,
} from "../../../actions/HumanResource/LeaveType/LeaveTypeAction";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import { LeaveTypeTypeI } from "../../../actions/HumanResource/LeaveType/LeaveTypeActionTypes";
import { HeadCellsI } from "../../../actions";
import {
  ItemDeleteButton,
  ItemEditButton,
} from "../../Reusable/Buttons/TableButton";

interface LeaveTypeTableProps {
  onEditData: (value: LeaveTypeTypeI) => void;
}

const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N" },
  { id: "name", label: "Leave", align: "left" },
  { id: "leaveAllowed", label: "Leave Allowed", align: "left" },
  { id: "isPaid", label: "Is Paid", align: "left" },
  { id: "isAccumulated", label: "Is Accumulated", align: "left" },
  { id: "minExp", label: "Min Exp Days", align: "left" },
  { id: "action", label: "Action" },
];

const LeaveTypeTable = (props: LeaveTypeTableProps) => {
  const { onEditData } = props;

  const classes = useTableStyles();
  const dispatch = useDispatch();

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  const [tableData, setTableData] = useState<LeaveTypeTypeI[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);

  const leaveTypeState = useSelector((state: RootStore) => state.leave_type);

  useEffect(() => {
    dispatch(GetLeaveTypes());
  }, []);

  useEffect(() => {
    const items: LeaveTypeTypeI[] = leaveTypeState.leave_types.map(
      (leave_type) => ({
        id: leave_type.id,
        name: leave_type.name,
        max_leave: leave_type.max_leave,
        max_leave_per: leave_type.max_leave_per,
        is_accumulated: leave_type.is_accumulated,
        is_paid: leave_type.is_paid,
        min_exp: leave_type.min_exp,
      })
    );

    setLoading(leaveTypeState.loading);
    setTableData(items);
  }, [leaveTypeState]);

  const handleEditClicked = (data: LeaveTypeTypeI) => {
    onEditData(data);
  };

  const handleDeleteModal = (value: boolean, id: string) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteLeaveType(itemId));
    setDeleteModalOpen(false);
  };

  const getCustomTableRow = (item: LeaveTypeTypeI, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index}
        </StyledTableCell>
        <StyledTableCell align="left" className={classes.cell}>
          {item.name}
        </StyledTableCell>
        <StyledTableCell align="left" className={classes.cell}>
          {item.max_leave} days / {item.max_leave_per}
        </StyledTableCell>
        <StyledTableCell align="left" className={classes.cell}>
          {item.is_paid ? "Yes" : "No"}
        </StyledTableCell>
        <StyledTableCell align="left" className={classes.cell}>
          {item.is_accumulated ? "Yes" : "No"}
        </StyledTableCell>
        <StyledTableCell align="left" className={classes.cell}>
          {item.min_exp}
        </StyledTableCell>

        <StyledTableCell align="center" className={classes.cell}>
          <ItemEditButton onClick={() => handleEditClicked(item)} />
          <ItemDeleteButton onClick={() => handleDeleteModal(true, item.id)} />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <Paper className={classes.root}>
      <Typography variant="h6">Leave Type List</Typography>

      <TableContainer
        label="Leave Type List"
        loading={loading}
        items={tableData}
        headCells={headCells}
        getCustomTableRow={getCustomTableRow}
        search_param="name"
      />

      {/* Delete modal dialog */}
      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
      />
    </Paper>
  );
};

export default LeaveTypeTable;
