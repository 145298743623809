import {
  POST_LOGIN_LOADING,
  POST_LOGIN_ERROR,
  POST_LOGIN_SUCCESS,
  GET_USERS_BY_PHONE_LOADING,
  GET_USERS_BY_PHONE_ERROR,
  GET_USERS_BY_PHONE_SUCCESS,
  GET_AUTH_TOKEN,
  LoginDispatchTypes,
  GeneralInfoType,
  UserType,
  SET_USER_BY_PHONE_SUCCESS,
  SEND_OTP_SUCCESS,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_ERROR,
  VERIFY_OTP_LOADING,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_LOADING,
  UPDATE_APP_THEME_LOADING,
  UPDATE_APP_THEME_SUCCESS,
  UPDATE_APP_THEME_ERROR,
  SWITCH_TO_SCHOOL_LOADING,
  SWITCH_TO_SCHOOL_ERROR,
  SWITCH_TO_SCHOOL_SUCCESS,
  SWITCH_BACK_TO_SCHOOL_LOADING,
  SWITCH_BACK_TO_SCHOOL_ERROR,
  SWITCH_BACK_TO_SCHOOL_SUCCESS,
} from '../../actions/Auth/Login/LoginActionTypes';
import {
  LogoutDispatchTypes,
  USER_LOGOUT_SUCCESS,
} from '../../actions/Auth/Logout/LogoutActionTypes';
import { withCookies, Cookies } from 'react-cookie';

export interface PhoneUserI {
  id: number;
  first_name: string;
  last_name: string;
}
export interface PhoneUserII {
  user: string;
  phone: string;
}

export interface OTPDetailI {
  id: number;
  user: number;
  phone_number: string;
  otp: string;
}

type RoleI = 'Administrator' | 'Accountant' | 'Teacher' | 'Parent' | 'Student';

interface InitialStateI {
  loading: boolean;
  general_info: any | null;
  user: any | null;
  authenticated: boolean;
  password_validated: boolean;
  token: string | null;
  refreshToken: string | null;
  role: RoleI | string | null;
  password_updated: boolean;
  authentication: {
    loading: boolean;
    phone_user: null | PhoneUserII;
    otp_detail: OTPDetailI | null;
    otp_status: 'Verified' | 'Invalid' | null;
    error: null | string | boolean;
  };
  theme: 'Standard' | 'StudentAppTheme';
  switch_success: boolean;
  temp_refreshToken: any;
  temp_hamro_token: any;
}

const initialState: InitialStateI = {
  loading: false,
  token: null,
  refreshToken: null,
  role: null,
  password_updated: true,
  general_info: null,
  user: null,
  authenticated: false,
  password_validated: false,
  authentication: {
    loading: false,
    phone_user: null,
    otp_detail: null,
    error: null,
    otp_status: 'Invalid',
  },
  theme: 'Standard',
  switch_success: false,
  temp_hamro_token: null,
  temp_refreshToken: null,
};

const LoginReducer = (
  state: InitialStateI = initialState,
  action: LoginDispatchTypes | LogoutDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case POST_LOGIN_LOADING:
      return {
        ...state,
        loading: true,
      };

    case POST_LOGIN_ERROR:
      return {
        ...state,
        loading: false,
      };

    case POST_LOGIN_SUCCESS:
      let role;

      localStorage.setItem('hamro_token', action.payload.token.access);
      localStorage.setItem('refreshToken', action.payload.token.refresh);
      localStorage.setItem(
        'password_updated',
        JSON.stringify(action.payload.token.password_updated)
      );
      localStorage.setItem(
        'validated',
        String(action.payload.token.password_updated)
      );
      localStorage.setItem('hamro_user', JSON.stringify(action.payload.user));
      localStorage.setItem(
        'hamro_general_info',
        JSON.stringify(action.payload.general_info)
      );
      localStorage.setItem(
        'online_class',
        action.payload.general_info?.online_class_feature
      );
      if (action.payload.user.groups.length == 1) {
        role = action.payload.user.groups[0].name;
        localStorage.setItem('role', role);
      }

      return {
        ...state,
        loading: false,
        token: action.payload.token.access,
        refreshToken: action.payload.token.refresh,
        password_updated: action.payload.token.password_updated,
        general_info: action.payload.general_info,
        user: action.payload.user,
        authenticated: true,
        password_validated: action.payload.token.password_updated,
        role: role,
        authentication: {
          loading: false,
          phone_user: null,
          otp_detail: null,
          error: null,
          otp_status: 'Invalid',
        },
      };

    case GET_AUTH_TOKEN:
      const user = localStorage.getItem('hamro_user');
      const general_info = localStorage.getItem('hamro_general_info');
      return {
        ...state,
        token: localStorage.getItem('hamro_token'),
        refreshToken: localStorage.getItem('refreshToken'),
        user: user != null && JSON.parse(user),
        authenticated: true,
        password_validated:
          localStorage.getItem('validated') === 'true' ? true : false,
        general_info: general_info != null && JSON.parse(general_info),
        role: localStorage.getItem('role'),
      };

    case USER_LOGOUT_SUCCESS:
      localStorage.removeItem('hamro_token');
      localStorage.removeItem('hamro_user');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('password_updated');
      localStorage.removeItem('hamro_general_info');
      localStorage.removeItem('role');
      localStorage.removeItem('validated');
      localStorage.removeItem('temp_hamro_token');
      localStorage.removeItem('temp_refreshToken');
      localStorage.removeItem('temp_hamro_user');
      localStorage.removeItem('temp_hamro_general_info');
      localStorage.removeItem('temp_role');
      localStorage.removeItem('online_class');

      return {
        ...state,
        // token: null,
        token: null,
        refreshToken: null,
        loading: false,
        general_info: null,
        user: null,
        authenticated: false,
        password_validated: false,
        role: null,
      };

    case GET_USERS_BY_PHONE_LOADING:
      return {
        ...state,
        authentication: {
          ...state.authentication,
          loading: true,
          error: false,
          phone_user: null,
          otp_detail: null,
          otp_status: 'Invalid',
        },
      };
    case GET_USERS_BY_PHONE_ERROR:
      return {
        ...state,
        authentication: {
          ...state.authentication,
          loading: false,
          error: true,
          otp_status: null,
          phone_user: null,
          otp_detail: null,
        },
      };
    case GET_USERS_BY_PHONE_SUCCESS:
      return {
        ...state,
        authentication: {
          ...state.authentication,
          loading: false,
          error: false,
          phone_user: action.payload,
          otp_status: null,
        },
      };
    case SET_USER_BY_PHONE_SUCCESS:
      return {
        ...state,
        authentication: {
          ...state.authentication,
          loading: false,
          error: false,
          phone_user: action.payload,
          otp_status: null,
        },
      };
    case SEND_OTP_SUCCESS:
      return {
        ...state,
        authentication: {
          ...state.authentication,
          loading: false,
          error: false,
          otp_detail: action.payload,
          otp_status: null,
        },
      };
    case VERIFY_OTP_LOADING:
      return {
        ...state,
        authentication: {
          ...state.authentication,
          loading: true,
          error: false,
        },
      };
    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        authentication: {
          ...state.authentication,
          loading: false,
          error: false,
          otp_status: 'Verified',
        },
        password_updated: false,
        authenticated: false,
      };
    case VERIFY_OTP_ERROR:
      return {
        ...state,
        authentication: {
          ...state.authentication,
          loading: false,
          error: true,
          otp_status: 'Invalid',
        },
      };

    case CHANGE_PASSWORD_LOADING:
      return {
        ...state,
        authentication: {
          ...state.authentication,
          loading: true,
          error: false,
        },
        password_validated: false,
        authenticated: false,
      };
    case CHANGE_PASSWORD_SUCCESS:
      localStorage.setItem('password_updated', 'true');
      localStorage.setItem('validated', 'true');
      return {
        ...state,
        authentication: {
          ...state.authentication,
          loading: false,
          error: false,
          phone_user: null,
          otp_detail: null,
          otp_status: null,
        },
        password_validated: true,
        authenticated: true,
      };
    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        authentication: {
          ...state.authentication,
          loading: false,
          error: true,
        },
        password_validated: false,
        authenticated: false,
      };
    case UPDATE_APP_THEME_SUCCESS:
      localStorage.setItem('role', action.payload);

      return {
        ...state,
        theme: action.payload,
        role: action.payload,
      };
    case SWITCH_TO_SCHOOL_LOADING:
      return {
        ...state,
        loading: true,
      };

    case SWITCH_TO_SCHOOL_ERROR:
      return {
        ...state,
        loading: false,
      };

    case SWITCH_TO_SCHOOL_SUCCESS:
      const temp_hamro_token = localStorage.getItem('hamro_token');
      const temp_refreshToken = localStorage.getItem('refreshToken');
      const temp_hamro_user = localStorage.getItem('hamro_user');
      const temp_hamro_general_info =
        localStorage.getItem('hamro_general_info');
      if (
        temp_hamro_token &&
        temp_refreshToken &&
        temp_hamro_user &&
        temp_hamro_general_info
      ) {
        localStorage.setItem('temp_hamro_token', temp_hamro_token);
        localStorage.setItem('temp_refreshToken', temp_refreshToken);
        localStorage.setItem('temp_hamro_user', temp_hamro_user);
        localStorage.setItem(
          'temp_hamro_general_info',
          temp_hamro_general_info
        );
      }
      localStorage.setItem('temp_role', 'Organization Admin');

      localStorage.setItem('hamro_token', action.payload.token.access);
      localStorage.setItem('refreshToken', action.payload.token.refresh);
      localStorage.setItem('hamro_user', JSON.stringify(action.payload.user));
      localStorage.setItem(
        'hamro_general_info',
        JSON.stringify(action.payload.general_info)
      );
      if (action.payload.user.groups.length == 1) {
        const role = action.payload.user.groups[0].name;
        localStorage.setItem('role', role);
      }

      return {
        ...state,
        temp_hamro_token: temp_hamro_token,
        temp_refreshToken: temp_refreshToken,
        loading: false,
        switch_success: true,
      };

    case SWITCH_BACK_TO_SCHOOL_SUCCESS:
      const org_acc_token = localStorage.getItem('temp_hamro_token');
      const org_ref_token = localStorage.getItem('temp_refreshToken');
      const org_hamro_user = localStorage.getItem('temp_hamro_user');
      const org_hamro_general_info = localStorage.getItem(
        'temp_hamro_general_info'
      );

      if (
        org_acc_token &&
        org_ref_token &&
        org_hamro_user &&
        org_hamro_general_info
      ) {
        localStorage.setItem('hamro_token', org_acc_token);
        localStorage.setItem('refreshToken', org_ref_token);
        localStorage.setItem('hamro_user', org_hamro_user);
        localStorage.setItem('hamro_general_info', org_hamro_general_info);
        localStorage.setItem('role', 'Organization Admin');

        localStorage.removeItem('temp_hamro_token');
        localStorage.removeItem('temp_refreshToken');
        localStorage.removeItem('temp_hamro_user');
        localStorage.removeItem('temp_hamro_general_info');
        localStorage.removeItem('temp_role');

        return {
          ...state,
          loading: false,
          general_info: JSON.parse(org_hamro_general_info),
          user: JSON.parse(org_hamro_user),
        };
      } else {
        return {
          ...state,
          loading: false,
        };
      }
    default:
      return state;
  }
};

export default LoginReducer;
