import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { ExaminationSideBar } from "../../components";
import AddExam from "../../components/Examination/CreateExam/AddExam";
import ExamList from "../../components/Examination/CreateExam/ExamList";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";
import { ExamTypeI } from "../../actions/Examination/Exam/ExamActionTypes";

export default function Examination() {
  const [formData, setFormData] = useState<ExamTypeI | null>(null);
  const [count, setCount] = useState<number>(0);
  const [edit, setEdit] = useState<any>();

  const fetchEditData = (data: ExamTypeI) => {
    setFormData({ ...data, count: count });
    setCount(count + 1);
  };

  const onEditCheck = (value: boolean) => {
    setEdit({ check: value, count: count });
  };

  const nav = <ExaminationSideBar createExam />;
  return (
    <SubModuleLayout sideNav={nav}>
      <Grid container>
        <Grid item xs={3}>
          <AddExam onEditCheck={onEditCheck} editData={formData} />
        </Grid>
        <Grid item xs={9}>
          <ExamList edit={edit} onEditData={fetchEditData} />
        </Grid>
      </Grid>
    </SubModuleLayout>
  );
}
