import React, { useState } from "react";
import { useSidebar } from "../Reusable";
import { useSidebarStyles } from "../Styles/SidebarStyles";
import { Assignment, ReceiptOutlined } from "@material-ui/icons";
import SidebarTitle from "../Reusable/Titles/SidebarTitle";

interface PropsI {
  id_card?: boolean;
  admit_card?: boolean;
  marksheet?: boolean;
  gradesheet?: boolean;
  character_certificate?: boolean;
  character_certificate_template?: boolean;
  marksheet_template?: boolean;
  id_card_template?: boolean;
  admit_card_template?: boolean;
  report_setting?: boolean;
  marksheet_ledger?: boolean;
}

interface DataSetI {
  text: string;
  url: string;
  activeStatus: boolean;
}

const ReportSideBar = (props: PropsI) => {
  const classes = useSidebarStyles();

  const [id_card] = useState(props.id_card);
  const [admit_card] = useState(props.admit_card);
  const [marksheet] = useState(props.marksheet);
  const [gradesheet] = useState(props.gradesheet);
  const [character_certificate] = useState(props.character_certificate);
  const [marksheet_template] = useState(props.marksheet_template);
  const [id_card_template] = useState(props.id_card_template);
  const [character_certificate_template] = useState(
    props.character_certificate_template
  );
  const [admit_card_template] = useState(props.admit_card_template);
  const [report_setting] = useState(props.report_setting);
  const [marksheet_ledger] = useState(props.marksheet_ledger);

  const dataSet: DataSetI[] = [
    { text: "ID Card", url: "report", activeStatus: id_card ? id_card : false },
    {
      text: "Admit Card",
      url: "admit-card",
      activeStatus: admit_card ? admit_card : false,
    },
    {
      text: "Marksheet",
      url: "marksheet",
      activeStatus: marksheet ? marksheet : false,
    },
    {
      text: "Marksheet Ledger",
      url: "marksheet-ledger",
      activeStatus: marksheet_ledger ? marksheet_ledger : false,
    },
    {
      text: "Gradesheet",
      url: "gradesheet",
      activeStatus: gradesheet ? gradesheet : false,
    },
    {
      text: "Character Certificate",
      url: "character-certificate",
      activeStatus: character_certificate ? character_certificate : false,
    },
    {
      text: "Result Template",
      url: "marksheet-templates",
      activeStatus: marksheet_template ? marksheet_template : false,
    },
    {
      text: "Character Certificate Template",
      url: "character-certificate-templates",
      activeStatus: character_certificate_template
        ? character_certificate_template
        : false,
    },
    {
      text: "ID Card Template",
      url: "id-cards-templates",
      activeStatus: id_card_template ? id_card_template : false,
    },
    {
      text: "Admit Card Template",
      url: "admit-cards-templates",
      activeStatus: admit_card_template ? admit_card_template : false,
    },
    {
      text: "Reports Setting",
      url: "reports-setting",
      activeStatus: report_setting ? report_setting : false,
    },
  ];
  const { CustomSidebar } = useSidebar(dataSet, "FINANCE");
  return (
    <div className={classes.Sidebar}>
      <div className={classes.sidebarContent}>
        <SidebarTitle icon={<ReceiptOutlined />}>Reports</SidebarTitle>
        <CustomSidebar />
      </div>
    </div>
  );
};

export default ReportSideBar;
