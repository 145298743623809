import { Avatar, makeStyles, Typography, Grid } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
  profileImg: {
    width: '68px',
    height: '68px',
    marginRight: 32,
    border: '2px solid #5767a6',
  },
  title: {
    marginTop: '5px',
    color: 'rgba(0,0,0,0.75)',
    fontWeight: 500,
    fontSize: '20px',
  },
  content: {
    maxWidth: 'max-content',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0.2rem 1.5rem 0.2rem 0rem',

    '& .contentTitle': {
      fontSize: 20,
      fontWeight: 500,
    },
  },
  contentTitle: {
    color: 'gray',
    fontSize: 22,
    fontWeight: 600,
  },
});

export interface StaffCardTypeI {
  id: string;
  name: string;
  avatar: string;
  designation: string;
}

interface StaffCardProps {
  staff: StaffCardTypeI;
}

export default function StaffCard(props: StaffCardProps) {
  const { staff } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={0}
        style={{ padding: '0rem 0 1rem 0' }}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item style={{ padding: 0 }}>
          <Grid container direction="column" alignItems="flex-start">
            <Grid item>
              <Avatar
                className={classes.profileImg}
                // alt="Remy Sharp"
                // src={staff.avatar}
                src="/favicon.png"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.content}>
            <Typography
              variant="subtitle2"
              className="contentTitle"
              component="p"
            >
              {staff.name}
            </Typography>
          </div>
          <div className={classes.content}>
            {/* <Typography variant="body2" className="contentTitle" component="p">
              Designation:
            </Typography>
            <Typography variant="body2" component="p">
              {staff.designation}
            </Typography> */}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
