export const GET_SMS_TEMPLATE_LOADING = "GET_SMS_TEMPLATE_LOADING";
export const GET_SMS_TEMPLATE_ERROR = "GET_SMS_TEMPLATE_ERROR";
export const GET_SMS_TEMPLATE_SUCCESS = "GET_SMS_TEMPLATE_SUCCESS";

export const ADD_SMS_TEMPLATE_LOADING = "ADD_SMS_TEMPLATE_LOADING";
export const ADD_SMS_TEMPLATE_ERROR = "ADD_SMS_TEMPLATE_ERROR";
export const ADD_SMS_TEMPLATE_SUCCESS = "ADD_SMS_TEMPLATE_SUCCESS";

export const UPDATE_SMS_TEMPLATE_LOADING = "UPDATE_SMS_TEMPLATE_LOADING";
export const UPDATE_SMS_TEMPLATE_ERROR = "UPDATE_SMS_TEMPLATE_ERROR";
export const UPDATE_SMS_TEMPLATE_SUCCESS = "UPDATE_SMS_TEMPLATE_SUCCESS";

export const DELETE_SMS_TEMPLATE_LOADING = "DELETE_SMS_TEMPLATE_LOADING";
export const DELETE_SMS_TEMPLATE_ERROR = "DELETE_SMS_TEMPLATE_ERROR";
export const DELETE_SMS_TEMPLATE_SUCCESS = "DELETE_SMS_TEMPLATE_SUCCESS";

export type SmsTemplateTypeI = {
  id: string;
  title: string;
  sms_category: string;
  text: string;
};

//===============================<START>GET SMS TEMPLATE <START>======================//

export interface GetSmsTemplateLoading {
  type: typeof GET_SMS_TEMPLATE_LOADING;
}

export interface GetSmsTemplateError {
  type: typeof GET_SMS_TEMPLATE_ERROR;
}

export interface GetSmsTemplateSuccess {
  type: typeof GET_SMS_TEMPLATE_SUCCESS;
  payload: SmsTemplateTypeI[];
}

//===============================<END>GET SMS TEMPLATE <END>======================//

//===============================<START>ADD SMS TEMPLATE <START>======================//

export interface AddSmsTemplateLoading {
  type: typeof ADD_SMS_TEMPLATE_LOADING;
}

export interface AddSmsTemplateError {
  type: typeof ADD_SMS_TEMPLATE_ERROR;
}

export interface AddSmsTemplateSuccess {
  type: typeof ADD_SMS_TEMPLATE_SUCCESS;
  payload: SmsTemplateTypeI;
}

//===============================<END>ADD SMS TEMPLATE <END>======================//

//===============================<START>UPDATE SMS TEMPLATE <START>======================//

export interface UpdateSmsTemplateLoading {
  type: typeof UPDATE_SMS_TEMPLATE_LOADING;
}

export interface UpdateSmsTemplateError {
  type: typeof UPDATE_SMS_TEMPLATE_ERROR;
}

export interface UpdateSmsTemplateSuccess {
  type: typeof UPDATE_SMS_TEMPLATE_SUCCESS;
  payload: SmsTemplateTypeI;
}

//===============================<END>UPDATE SMS TEMPLATE <END>======================//

//===============================<START>DELETE SMS TEMPLATE <START>======================//

export interface DeleteSmsTemplateLoading {
  type: typeof DELETE_SMS_TEMPLATE_LOADING;
}

export interface DeleteSmsTemplateError {
  type: typeof DELETE_SMS_TEMPLATE_ERROR;
}

export interface DeleteSmsTemplateSuccess {
  type: typeof DELETE_SMS_TEMPLATE_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE SMS TEMPLATE <END>======================//

export type SmsTemplateDispatchTypes =
  | GetSmsTemplateLoading
  | GetSmsTemplateError
  | GetSmsTemplateSuccess
  | AddSmsTemplateLoading
  | AddSmsTemplateError
  | AddSmsTemplateSuccess
  | UpdateSmsTemplateLoading
  | UpdateSmsTemplateError
  | UpdateSmsTemplateSuccess
  | DeleteSmsTemplateLoading
  | DeleteSmsTemplateError
  | DeleteSmsTemplateSuccess;
