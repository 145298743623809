import React from "react";
import { HRsidebar } from "../../components";
import StaffForm from "../../components/HumanResource/Staff/StaffForm";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const EditStaffPage = () => {
  const nav = <HRsidebar Staff />;
  return (
    <SubModuleLayout HumanMenuActive sideNav={nav}>
      <StaffForm />
    </SubModuleLayout>
  );
};

export default EditStaffPage;
