import Marksheet1 from "../../../../assets/images/marksheet-templates/marksheet1.png";

export const marksheetSampleData = [
  {
    id: 1,
    code: "ABC-001",
    thumbnail: Marksheet1,
    ape_enable: true,
    grading_system: true,
    watermark: true,
    watermark_position: "T",
    school_logo: false,
    school_logo_position: "L",
    student_photo: false,
    school_photo_position: "R",
    footer_title: "Signatures",
    footer_text_1: "Class Teacher",
    footer_text_2: "Vice Principal",
    footer_text_3: "School Principal",
  },
];

export function getSingleMarksheet(id: string) {
  return marksheetSampleData.find((item) => item.id == parseInt(id));
}
