import axios from 'axios';
import { Dispatch } from 'redux';

import {
  GET_LEAVE_LOADING,
  GET_LEAVE_ERROR,
  GET_LEAVE_SUCCESS,
  LeaveDispatchTypes,
  ADD_LEAVE_LOADING,
  ADD_LEAVE_SUCCESS,
  ADD_LEAVE_ERROR,
  DELETE_LEAVE_SUCCESS,
  DELETE_LEAVE_ERROR,
  DELETE_LEAVE_LOADING,
  UPDATE_LEAVE_LOADING,
  UPDATE_LEAVE_SUCCESS,
  UPDATE_LEAVE_ERROR,
  UPDATE_LEAVE_STATUS_LOADING,
  UPDATE_LEAVE_STATUS_SUCCESS,
  UPDATE_LEAVE_STATUS_ERROR,
  APPROVE_OR_DECLINE_SUCCESS,
  APPROVE_OR_DECLINE_LOADING,
  APPROVE_OR_DECLINE_ERROR,
  GET_TODAY_ON_LEAVE_LOADING,
  GET_TODAY_ON_LEAVE_SUCCESS,
  GET_TODAY_ON_LEAVE_ERROR,
  APPLY_LEAVE_TYPE_LOADING,
  APPLY_LEAVE_TYPE_SUCCESS,
  APPLY_LEAVE_TYPE_ERROR,
} from './LeaveActionTypes';

import { SET_SNACKBAR, SetSnackBarI } from '../../SnackbarActionTypes';
import { HeaderConfig } from '../../Auth/Login/LoginAction';
import { ADMIN_API_URL } from '../../../config/SERVER_API_URLS';

export const GetLeaves =
  (pageNo = 1, perPage = 5) =>
  async (dispatch: Dispatch<LeaveDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_LEAVE_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/staff_leave_request/?page=${pageNo}&per_page=${perPage}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_LEAVE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_LEAVE_ERROR,
      });
    }
  };

export const GetTodayOnLeaves = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_TODAY_ON_LEAVE_LOADING });

    const res = await axios.get(`${ADMIN_API_URL}/leaves`, HeaderConfig());

    dispatch({
      type: GET_TODAY_ON_LEAVE_SUCCESS,
      payload: res.data.todays_leave,
    });
  } catch (error) {
    dispatch({ type: GET_TODAY_ON_LEAVE_ERROR });

    // dispatch({
    //   type: SET_SNACKBAR,
    //   payload: {
    //     snackbarOpen: true,
    //     snackbarType: "error",
    //     snackbarMessage: "Today on Leave Loading Failed",
    //   },
    // });
  }
};

export const GetSearchedLeaves =
  (query: any) =>
  async (dispatch: Dispatch<LeaveDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_LEAVE_LOADING,
      });

      const url = `${ADMIN_API_URL}/staff_leave_request/?from_date=${query.date_first}&to_date=${query.date_second}`;
      const res = await axios.get(url, HeaderConfig());

      dispatch({
        type: GET_LEAVE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_LEAVE_ERROR,
      });
    }
  };

export const LeaveApproveOrDecline =
  ({ id, ...data }: any) =>
  async (dispatch: Dispatch<LeaveDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: APPROVE_OR_DECLINE_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/staff_leave_request/${id}/update_status/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: APPROVE_OR_DECLINE_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'success',
          snackbarMessage: 'Leave status updated Successfully',
        },
      });
    } catch (error) {
      dispatch({
        type: APPROVE_OR_DECLINE_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: 'Leave approve or decline Failed',
        },
      });
    }
  };

export const AddLeave =
  (data: any) =>
  async (dispatch: Dispatch<LeaveDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_LEAVE_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/student_leave/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_LEAVE_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'success',
          snackbarMessage: 'Leave Added Successfully',
        },
      });
    } catch (error: any) {
      dispatch({
        type: ADD_LEAVE_ERROR,
        payload: error.response?.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: 'Leave Add Failed',
        },
      });
    }
  };

export const UpdateLeave =
  (id: string, data: any) =>
  async (dispatch: Dispatch<LeaveDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_LEAVE_LOADING,
      });

      const res = await axios.put(
        `${ADMIN_API_URL}/leave/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_LEAVE_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'success',
          snackbarMessage: 'Leave Updated Successfully',
        },
      });
    } catch (error: any) {
      dispatch({
        type: UPDATE_LEAVE_ERROR,
        payload: error.response?.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: 'Leave Update Failed',
        },
      });
    }
  };

export const UpdateLeaveStatus =
  (id: string, data: any) =>
  async (dispatch: Dispatch<LeaveDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_LEAVE_STATUS_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/staff_leave_request/${id}/update_status/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_LEAVE_STATUS_SUCCESS,
        payload: {
          id: id,
          status: data.status,
          remarks: data.remarks,
        },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'success',
          snackbarMessage: 'Leave Status Updated Successfully',
        },
      });
    } catch (error: any) {
      dispatch({
        type: UPDATE_LEAVE_STATUS_ERROR,
        payload: error.response?.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: 'Leave Status Update Failed',
        },
      });
    }
  };

export const DeleteLeave =
  (id: string) =>
  async (dispatch: Dispatch<LeaveDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_LEAVE_LOADING,
      });

      const res = await axios.delete(
        `${ADMIN_API_URL}/leave/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_LEAVE_SUCCESS,
        payload: { id: id },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'success',
          snackbarMessage: 'Leave Deleted Successfully',
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_LEAVE_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: 'Leave Delete Failed',
        },
      });
    }
  };

export const ApplyLeaveTypeToStaff =
  (data: any) =>
  async (dispatch: Dispatch<LeaveDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: APPLY_LEAVE_TYPE_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/apply_staff_leave/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: APPLY_LEAVE_TYPE_SUCCESS,
        payload: data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'success',
          snackbarMessage: 'Leave Applied to Staffs Successfully',
        },
      });
    } catch (error) {
      dispatch({
        type: APPLY_LEAVE_TYPE_ERROR,
        payload: error,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: "Couldn't apply leave to staffs at the moment",
        },
      });
    }
  };
