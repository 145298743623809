import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  Paper,
  TextField,
} from "@material-ui/core";
import { Cancel, Save } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { GetGrades } from "../../../actions/Academics/Grade/GradeAction";
import { GradeTypeI } from "../../../actions/Academics/Grade/GradeActionTypes";
import { OptSubjectI } from "../../../rtk/features/exam/optSubjects/optSubject";
import { resetFormState } from "../../../rtk/features/exam/optSubjects/optSubjectSlice";
import { getGradeSubjects } from "../../../rtk/features/exam/subjectGrade/gradeSubjectApi";
import { RootStore } from "../../../store";
import { useFormStyles } from "../../Styles/FormStyles";
import { useForm } from "react-hook-form";
import {
  createOptSubjectGroup,
  updateOptSubjectGroup,
} from "../../../rtk/features/exam/optSubjects/optSubjectApi";

const StyledPaper = styled(Paper)`
  margin: 20px 10px 20px 20px;
  padding: 25px 20px;
`;

const Title = styled.h2`
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
`;

const OptionalSubjectForm = () => {
  const formClass = useFormStyles();
  const dispatch = useDispatch();

  const { grades } = useSelector((state: RootStore) => state.grade);
  const { gradeOptSubjects, actionPerformed, editMode, optSubjectGroup } =
    useSelector((state: RootStore) => state.optSubject);

  const [optSubjectsChoice, setOptSubjectsChoice] = useState<OptSubjectI[]>([]);

  const [selectedGrade, setSelectedGrade] = useState<GradeTypeI | null>(null);
  const [title, setTitle] = useState<string>("");
  const [selectedSubjects, setSelectedSubjects] = useState<string[]>([]);

  const { setError, clearErrors, errors } = useForm();

  useEffect(() => {
    dispatch(GetGrades());
    dispatch(resetFormState());
  }, []);

  useEffect(() => {
    setOptSubjectsChoice(gradeOptSubjects);
    if (!editMode) {
      setSelectedSubjects([]);
    }
  }, [gradeOptSubjects]);

  useEffect(() => {
    if (actionPerformed) {
      setSelectedGrade(null);
      setTitle("");
      setSelectedSubjects([]);
    }
  }, [actionPerformed]);

  useEffect(() => {
    if (editMode && optSubjectGroup) {
      setSelectedGrade(
        grades.find((el) => el.id === optSubjectGroup.grade) || null
      );
      setTitle(optSubjectGroup.name);
      dispatch(getGradeSubjects(optSubjectGroup.grade));
      setSelectedSubjects(optSubjectGroup.subject.map((el) => el.id));
    }
    clearErrors("grade");
    clearErrors("subject");
    clearErrors("title");
  }, [editMode]);

  const handleGradeChange = (value: GradeTypeI | null) => {
    setSelectedGrade(value);
    setSelectedSubjects([]);

    if (value) {
      dispatch(getGradeSubjects(value.id));
    } else {
      dispatch(resetFormState());
    }
    clearErrors("grade");
    clearErrors("subject");
  };

  const handleTitleChange = (val: string) => {
    setTitle(val);
    clearErrors("title");
  };

  const handleSubjectsChange = (val: string, checked: boolean) => {
    if (checked) {
      setSelectedSubjects([...selectedSubjects, val]);
    } else {
      const data = selectedSubjects.filter((el) => el !== val);
      setSelectedSubjects(data);
    }
    clearErrors("subject");
  };

  const handleReset = () => {
    dispatch(resetFormState());
    setSelectedGrade(null);
    setTitle("");
    setOptSubjectsChoice([]);
    setSelectedSubjects([]);
  };

  const handleSave = () => {
    let valid = true;

    if (!selectedGrade) {
      setError("grade", { type: "required", message: "Grade is required" });
      valid = false;
    }
    if (!title) {
      setError("title", { type: "required", message: "Title is required" });
      valid = false;
    }
    if (!selectedSubjects.length) {
      setError("subject", {
        type: "required",
        message: "Select at least one subject",
      });
      valid = false;
    }

    if (!valid) {
      return;
    }

    if (valid) {
      if (editMode && optSubjectGroup) {
        dispatch(
          updateOptSubjectGroup({
            id: optSubjectGroup.id,
            data: {
              name: title,
              grade: selectedGrade!.id,
              subject: selectedSubjects,
            },
          })
        );
      } else {
        dispatch(
          createOptSubjectGroup({
            name: title,
            grade: selectedGrade!.id,
            subject: selectedSubjects,
          })
        );
      }
    }
  };

  return (
    <StyledPaper>
      <Title>Add Opt Subject Group</Title>
      <Grid container>
        <Grid item xs={12} className={formClass.formWrapper}>
          <InputLabel>Grade</InputLabel>
          <Autocomplete
            value={selectedGrade}
            onChange={(event: React.ChangeEvent<{}>, value) =>
              handleGradeChange(value)
            }
            options={grades}
            getOptionLabel={(option) => option.grade_name}
            renderInput={(params) => (
              <TextField
                {...params}
                name="select_message"
                placeholder="Select Class"
                variant="outlined"
              />
            )}
          />
          <span className={formClass.validationErrorInfo}>
            {errors.grade && errors.grade.message}
          </span>
        </Grid>
        <Grid item xs={12} className={formClass.formWrapper}>
          <InputLabel>Title</InputLabel>
          <TextField
            variant="outlined"
            name="name"
            placeholder="Title"
            autoComplete="off"
            value={title}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleTitleChange(event.target.value)
            }
          />
          <span className={formClass.validationErrorInfo}>
            {errors.title && errors.title.message}
          </span>
        </Grid>
        <Grid item xs={12} className={formClass.formWrapper}>
          <InputLabel>Select Subject(s)</InputLabel>
          <FormControl
            style={{ maxHeight: 200, overflowY: "auto" }}
            component="fieldset"
            className={formClass.sectionCheckBox}
          >
            <FormGroup>
              {optSubjectsChoice.length ? (
                optSubjectsChoice.map((el, index) => (
                  <FormControlLabel
                    key={index + 1}
                    control={
                      <Checkbox
                        color="primary"
                        checked={selectedSubjects.includes(el.id)}
                        onChange={(e) =>
                          handleSubjectsChange(el.id, e.target.checked)
                        }
                      />
                    }
                    label={el.subject_name}
                  />
                ))
              ) : (
                <i style={{ fontSize: "11px" }}>
                  No Opt. Subjects. Select Grade with Opt. Subjects.
                </i>
              )}
            </FormGroup>
          </FormControl>
          <span className={formClass.validationErrorInfo}>
            {errors.subject && errors.subject.message}
          </span>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        {editMode && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleReset()}
            startIcon={<Cancel />}
            style={{ margin: "10px 10px 0 0" }}
          >
            Reset
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSave()}
          startIcon={<Save />}
          style={{ margin: "10px 10px 0 0" }}
        >
          {editMode ? "Update" : "Create"}
        </Button>
      </Grid>
    </StyledPaper>
  );
};

export default OptionalSubjectForm;
