import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

export const fetchStudentList = createAsyncThunk(
  "fetchStudentList",
  async (
    data: { grade: string; section: string; category: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      let params = `?grade=${data.grade}`;
      if (data.section) {
        params = params + `&section=${data.section}`;
      }
      if (data.category) {
        params = params + `&category=${data.category}`;
      }
      const res = await axios.get(
        `${ADMIN_API_URL}/student-dropdown/${params}`
      );
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
    }
  }
);
