import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, InputLabel, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import SaveIcon from "@material-ui/icons/Save";
import { RootStore } from "../../../../../../store";
import { useStyles } from "../../../../../Styles/FormStyles";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormSaveLoadingButton } from "../../../../../Reusable/Buttons/FormButton";
import Spinner from "../../../../../../common/spinnerSVG/Spinner";
import styled from "styled-components";
import { updateStaffAcademy } from "../../../../../../rtk/features/humanResource/humanResourceThunk";
import { useParams } from "react-router-dom";

const ModalForm = styled.form`
  padding: 0 20px;
  max-width: 480px;
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 11px;
  margin-left: 10px;
`;

interface StaffFormProps {
  highest_degree: string;
  board: string;
  pass_year: string;
}

const AcademicInfoModal = (props: any) => {
  // Custom Styles
  const classes = useStyles();
  const { handleModalClose } = props;
  const { staffID } = useParams<{ staffID: string }>();

  const { loading, actionCompleted, staffDetail } = useSelector(
    (state: RootStore) => state.humanResource
  );

  // Destructuring react-form-hook methods
  const { register, handleSubmit, errors, setValue } = useForm<StaffFormProps>({
    mode: "onChange",
  });

  // Dispatch Variable
  const dispatch = useDispatch();

  useEffect(() => {
    if (actionCompleted) {
      handleModalClose();
    }
  }, [actionCompleted]);

  useEffect(() => {
    if (staffDetail?.staffs_academic_info) {
      setValue(
        "highest_degree",
        staffDetail?.staffs_academic_info.highest_degree || ""
      );
      setValue("board", staffDetail?.staffs_academic_info.university || "");
      setValue(
        "pass_year",
        staffDetail?.staffs_academic_info.passed_year || ""
      );
    }
  }, [staffDetail]);

  // State Declaration

  const onSubmitModal: SubmitHandler<StaffFormProps> = (data) => {
    console.log(data);
    dispatch(
      updateStaffAcademy({
        id: staffID,
        highest_degree: data.highest_degree,
        university: data.board,
        passed_year: data.pass_year,
      })
    );
  };

  return (
    <ModalForm onSubmit={handleSubmit(onSubmitModal)}>
      <Grid container>
        <Grid
          item
          xs={12}
          className={classes.formWrapper}
          style={{ marginBottom: "1rem" }}
        >
          <InputLabel>
            Highest Degree <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <TextField
            variant="outlined"
            name="highest_degree"
            placeholder="Highest Degree"
            inputRef={register({
              required: { value: true, message: "Degree name is required." },
            })}
          />
          <ErrorMessage>{errors.highest_degree?.message}</ErrorMessage>
        </Grid>

        <Grid
          item
          xs={12}
          className={classes.formWrapper}
          style={{ marginBottom: "1rem" }}
        >
          <InputLabel>
            Board University <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <TextField
            variant="outlined"
            name="board"
            placeholder="Board/University"
            inputRef={register({
              required: {
                value: true,
                message: "Board/University is required.",
              },
            })}
          />
          <ErrorMessage>{errors.board?.message}</ErrorMessage>
        </Grid>

        <Grid
          item
          xs={12}
          className={classes.formWrapper}
          style={{ marginBottom: "1rem" }}
        >
          <InputLabel>
            Year Passed <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <TextField
            variant="outlined"
            name="pass_year"
            placeholder="Degree Pass Year"
            inputRef={register({
              required: { value: true, message: "Pass year is required." },
            })}
          />
          <ErrorMessage>{errors.pass_year?.message}</ErrorMessage>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="flex-start"
        style={{ marginBottom: "1em" }}
      >
        <FormSaveLoadingButton
          type="submit"
          icon={loading ? <Spinner /> : <SaveIcon />}
          disabled={loading ? true : false}
          text={loading ? "Saving..." : "Save"}
        />
      </Grid>
    </ModalForm>
  );
};

export default AcademicInfoModal;
