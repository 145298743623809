import { Paper, Grid } from "@material-ui/core";
import React, { useState } from "react";
import { ClassTypeI } from "../../actions/Academics/Class/ClassActionTypes";
import { AcademicSidebar } from "../../components";
import AdminLessonPlanTable from "../../components/Academic/AdminLessonPlan/AdminLessonPlanTable";
import SearchLessonPlan from "../../components/Academic/AdminLessonPlan/SearchLessonPlan";
import ClassLayout from "../../components/ClassRoom/Layout/ClassLayout";
import ClassRoomSidebar from "../../components/ClassRoom/Layout/ClassRoomSidebar";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const AdminLessonPlanPage = () => {
  const [classItem, setClassItem] = useState<ClassTypeI | null>(null);

  const onClassItem = (value: ClassTypeI) => {
    setClassItem(value);
  };

  const nav = <ClassRoomSidebar onClassItem={onClassItem} />;

  return (
    <div
      style={{ marginTop: "110px", paddingRight: "20px", minHeight: "80vh" }}
    >
      <ClassLayout sideNav={nav}>
        <Grid container>
          <Grid item xs={12}>
            <SearchLessonPlan classItem={classItem} />
            <AdminLessonPlanTable />
          </Grid>
        </Grid>
      </ClassLayout>
    </div>
  );
};

export default AdminLessonPlanPage;
