// ---------------- <START> module import ------------------//
import React, { useState, useEffect } from "react";
import { Paper, Typography } from "@material-ui/core";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import { useTable } from "../../Reusable";
import { useTableStyles } from "../../Styles/TableStyles";
import ActionButton from "../../Reusable/Buttons/ActionButton";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import {
  GetDesignations,
  DeleteDesignation,
} from "../../../actions/HumanResource/Designation/DesignationAction";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import { DesignationTypeI } from "../../../actions/HumanResource/Designation/DesignationActionTypes";
import { HeadCellsI } from "../../../actions";
import {
  ItemDeleteButton,
  ItemEditButton,
} from "../../Reusable/Buttons/TableButton";
// ---------------- <END> module import ------------------//

// ---------------- <START> Interface ------------------//
interface DesignationTableProps {
  onEditData: (value: DesignationTypeI) => void;
}

// ---------------- <END> Interface ------------------//

// ---------------- <START> Head Cells ------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N" },
  { id: "name", label: "Designation Name" },
  { id: "action", label: "Action" },
];
// ---------------- <START> Head Cells ------------------//

// ---------------- <START> Designation Table Component ------------------//
const DesignationTable = (props: DesignationTableProps) => {
  const { onEditData } = props;
  const classes = useTableStyles();
  const dispatch = useDispatch();
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  const [tableData, setTableData] = useState<DesignationTypeI[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);

  const designationState = useSelector((state: RootStore) => state.designation);

  useEffect(() => {
    dispatch(GetDesignations());
  }, []);

  useEffect(() => {
    setLoading(designationState.loading);

    const items: DesignationTypeI[] = designationState.designations.map(
      (designation) => ({
        id: designation.id,
        name: designation.name,
      })
    );

    setTableData(items);
  }, [designationState]);

  const handleEditClicked = (data: DesignationTypeI) => {
    onEditData(data);
  };

  const handleDeleteModal = (value: boolean, id: string) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteDesignation(itemId));
    setDeleteModalOpen(false);
  };

  const getCustomTableRow = (item: DesignationTypeI, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ItemEditButton onClick={() => handleEditClicked(item)} />
          <ItemDeleteButton onClick={() => handleDeleteModal(true, item.id)} />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <Paper className={classes.root}>
      <Typography variant="h6">Designation List</Typography>

      <TableContainer
        label="Designation List"
        loading={loading}
        items={tableData}
        headCells={headCells}
        getCustomTableRow={getCustomTableRow}
        search_param="name"
      />

      {/* Delete modal dialog */}
      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
      />
    </Paper>
  );
};
// ---------------- <START> Designation Table Component ------------------//

export default DesignationTable;
