import React from "react";
import { useStyles } from "../Styles/FormStyles";

interface formsValidationCheckupI {
  errors: any | null | undefined;
  inputName: any | null;
  requiredMessage?: string | null;
  maxLengthMessage?: string | null;
  minLengthMessage?: string | null;
  serverSideMessage?: string | null;
}

const formsValidationCheckup = (data: formsValidationCheckupI) => {
  const {
    errors,
    inputName,
    requiredMessage,
    maxLengthMessage,
    minLengthMessage,
    serverSideMessage,
  } = data;
  const classes = useStyles();
  return (
    <span className={classes.validationErrorInfo}>
      {requiredMessage != null
        ? errors[inputName]?.type === "required" && requiredMessage
        : null}
      {maxLengthMessage != null
        ? errors[inputName]?.type === "maxLength" && maxLengthMessage
        : null}
      {minLengthMessage != null
        ? errors[inputName]?.type === "minLength" && minLengthMessage
        : null}
      {serverSideMessage != null || ""
        ? errors[inputName]?.type === "serverSideError" && serverSideMessage
        : null}
    </span>
  );
};

export default formsValidationCheckup;
