import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from '../../actions/SnackbarAction';
import { RootStore } from '../../store';

const PreventedRoute = ({ component: Component, ...rest }: any) => {
  const dispatch = useDispatch();
  const authToken = localStorage.getItem('hamro_token');
  const user = useSelector((state: RootStore) => state.auth.user);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user) {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: {
                  from: props.location.pathname,
                },
              }}
            />
          );
        } else {
          return <Component {...props} />;
        }
      }}
    />
  );
};

export default PreventedRoute;
