import axios from 'axios';
import { Dispatch } from 'redux';

import {
  GET_NOTIFICATION_LOADING,
  GET_NOTIFICATION_ERROR,
  GET_NOTIFICATION_SUCCESS,
  NotificationDispatchTypes,
} from './NotificationActionTypes';

import { SetSnackBarI } from '../../SnackbarActionTypes';
import {
  ADMIN_API_URL,
  TEACHER_API_URL,
} from '../../../config/SERVER_API_URLS';

export const GetNotifications =
  (params?: any): any =>
  async (dispatch: Dispatch<NotificationDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({ type: GET_NOTIFICATION_LOADING });

      let url = `${ADMIN_API_URL}/notifications/`;
      if (params && params.page) url += `?page=${params.page}`;

      if (params && params.page && params.action) {
        url += `&action=${params.action}`;
      } else if (params && params.action) {
        url += `?action=${params.action}`;
      }

      const res = await axios.get(url);

      dispatch({ type: GET_NOTIFICATION_SUCCESS, payload: res.data });
    } catch (error) {
      dispatch({ type: GET_NOTIFICATION_ERROR });
    }
  };

export const GetTeacherNotifications =
  (params?: any): any =>
  async (dispatch: Dispatch<NotificationDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({ type: GET_NOTIFICATION_LOADING });

      let url = `${TEACHER_API_URL}/notifications/`;
      if (params && params.page) url += `?page=${params.page}`;

      if (params && params.page && params.action) {
        url += `&action=${params.action}`;
      } else if (params && params.action) {
        url += `?action=${params.action}`;
      }

      const res = await axios.get(url);

      dispatch({ type: GET_NOTIFICATION_SUCCESS, payload: res.data });
    } catch (error) {
      dispatch({ type: GET_NOTIFICATION_ERROR });
    }
  };
