import {
    Grid,
    InputLabel,
    Paper,
    TextField,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useFormStyles } from "../../Styles/FormStyles";
import { Autocomplete } from "@material-ui/lab";
import { RootStore } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { GetClasses } from "../../../actions/Academics/Class/ClassAction";
import { GetSections } from "../../../actions/Academics/Section/SectionAction";
import { StudentSearch } from "../../../actions/Student/Student/StudentAction";
import { GetMiscFeeTypeWithFilters, GetMiscFeeType } from "../../../actions/Fees/MiscFee/MiscFeeTypeAction";

const MiscStudentSearch = (props: any) => {
    const { grade, setGrade } = props;
    const classes = useFormStyles();
    const [gradeChoices, setGradeChoices] = useState<any | null>([]);
    const [sectionChoices, setSectionChoices] = useState<any[] | []>([]);
    // const [grade, setGrade] = useState<any | null>(null);
    // const [section, setSection] = useState<any | null>(null);
    const [sectionDisabler, setSectionDisabler] = useState<boolean>(true);

    const gradeList = useSelector((state: RootStore) => state.class.classes);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetSections());
        dispatch(GetClasses());
    }, []);

    useEffect(() => {
        gradeList && setGradeChoices(gradeList);
    }, [gradeList]);


    const handleGradeChange = (value: any | null) => {
        if (value) {
            console.log({ value })
            setGrade(value);
            populateSectionChoices(value.section)
            dispatch(StudentSearch(value.grade, ""));
            // props.onGradeSection(value.grade, "")
            dispatch(GetMiscFeeTypeWithFilters(value.grade))
        } else {
            setGrade(null);
            setSectionDisabler(true);
            dispatch(GetMiscFeeType())
        }
    };

    const populateSectionChoices = (sections: any | []) => {
        setSectionDisabler(false);
        sections.length > 0
            ? setSectionChoices(sections)
            : setSectionDisabler(true);
    };

    // useEffect(() => {
    //     if (section != null && grade != null) {
    //         dispatch(StudentSearch(grade.grade, section.id));
    //         props.onGradeSection(grade.grade, section.id)
    //     }
    // }, [section]);


    // const handleSectionChange = (value: any) => {
    //     setSection(value);
    // };

    console.log({ grade })

    return (
        <>
            {/* <Paper className={classes.pageContent}> */}
            <form className={classes.form} >
                {props.title && <Grid container>
                    <Grid item xs={12} className={classes.formTitle}>
                        <span>{props.title}</span>
                    </Grid>
                </Grid>}
                <Grid container spacing={1}>
                    <Grid item xs={8} className={classes.formWrapper}>
                        <InputLabel>Class</InputLabel>
                        <Autocomplete
                            onChange={(event: React.ChangeEvent<{}>, value: any | null) =>
                                handleGradeChange(value)
                            }
                            options={gradeChoices}
                            getOptionLabel={(option) => "Class" + " " + option?.grade_name}
                            value={grade}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Select a Grade"
                                    name="class"
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>

                    {/* <Grid item xs={6} className={classes.formWrapper}>
                        <InputLabel>Section</InputLabel>
                        <Autocomplete
                            onChange={(event: React.ChangeEvent<{}>, value: any | null) =>
                                handleSectionChange(value)
                            }
                            options={sectionChoices}
                            value={section && section}
                            getOptionLabel={(option) => option.name}
                            disabled={sectionDisabler}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Select a Section"
                                    name="section"
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid> */}
                </Grid>
            </form>
        </>
    );
};
// -----------------<end> Selected Student form ends-----------------------//
export default MiscStudentSearch;
