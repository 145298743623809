//---------------------<start> module imports starts ----------------------//
import {
    Grid,
    InputLabel,
    Paper,
    TextField,
    Button,
    Divider,
    CardHeader,
    Avatar,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useFormStyles } from "../../Styles/FormStyles";
import { Autocomplete } from "@material-ui/lab";
import { Search } from "@material-ui/icons";
import { RootStore } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import {
    SearchStudent,
    StudentSearch,
} from "../../../actions/Student/Student/StudentAction";
import { useForm } from "react-hook-form";
import { GetAllDaybook, GetInvoiceDetail } from "../../../actions/Fees/Daybook/DaybookAction";
import defaultBase64 from "../../Student/ParentForm/defaultBase64";
import moment from "moment";

// -----------------<end> module imports ends -----------------------//
// -----------------<start> Interfaces <start>-----------------------//
// -----------------<end> Interfaces <end>-----------------------//

// -----------------<starts> Selected Student Form starts-----------------------//
const BillAdjustmentSearch = (props: any) => {
    const classes = useFormStyles();
    const [gradeChoices, setGradeChoices] = useState<any | null>([]);
    const [sectionChoices, setSectionChoices] = useState<any[] | []>([]);
    const [grade, setGrade] = useState<any | null>(null);
    const [section, setSection] = useState<any | null>(null);
    const [sectionDisabler, setSectionDisabler] = useState<boolean>(true);
    const [studentDisabler, setStudentDisabler] = useState<boolean>(true);
    const [studentChoices, setStudentChoices] = useState<any | null>(null);
    const [student, setStudent] = useState<any>(null);
    const [searchStudent, setSearchStudent] = useState<any>(null);

    const { register, handleSubmit, errors, setError, setValue, clearErrors } =
        useForm<any>({
            mode: "onChange",
        });


    const dayBookState = useSelector((state: RootStore) => state.day_book);

    const gradeList = useSelector((state: RootStore) => state.class.classes);
    const searchedStudent = useSelector(
        (state: RootStore) => state.student.searched_student
    );

    const invoiceData = useSelector((state: RootStore) => state.day_book.invoice_detail);


    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetAllDaybook(1, "", "", "", ""));
    }, []);


    useEffect(() => {
        gradeList && setGradeChoices(gradeList);
    }, [gradeList]);

    const handleGradeChange = (value: any | null) => {
        if (value) {
            setGrade(value);
            populateSectionChoices(value.section);
            setStudentDisabler(false);

            dispatch(StudentSearch(value.grade, ""));
        } else {
            setSectionDisabler(true);
            setStudentDisabler(true);
        }
    };

    const populateSectionChoices = (sections: any | []) => {
        setSectionDisabler(false);
        sections.length > 0
            ? setSectionChoices(sections)
            : setSectionDisabler(true);
    };

    useEffect(() => {
        if (section != null && grade != null) {
            dispatch(StudentSearch(grade.grade, section.id));
        }
    }, [section]);

    useEffect(() => {
        if (dayBookState?.day_books !== []) {
            setStudentChoices(dayBookState?.day_books?.results);
        }
    }, [dayBookState]);

    const handleSectionChange = (value: any) => {
        setSection(value);
        if (value === null) {
            setStudent(null);
        }
        value === null && setStudent(null);
    };

    const handleStudentChage = (value: any) => {
        setStudent(value);
        value != null
            ? clearErrors("student")
            : setError("student", {
                type: "required",
            });
    };

    console.log({ studentChoices })

    const handleSearchStudent = (value: any) => {
        value && clearErrors("student");
        setSearchStudent(value);
    };

    const handleSearchBtnClick = (value: any) => {
        props.handleSearchClick(value)
    };

    const handleSearchStudentByLetter = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const text = event.target.value;
        dispatch(SearchStudent(text, 1, 10));
    };

    const onChangeInvoice = (value: any) => {
        if (value) {
            handleSearchBtnClick(value)
            dispatch(GetInvoiceDetail(value?.receipt_id))
            props.setInvoiceID(value?.receipt_id)
            props.setIsStudentSelected(true)
        } else {
            props.setIsStudentSelected(false)

        }
    }

    // const searchStudentSelector = useSelector(
    //     (state: RootStore) => state.student.student_search
    // );

    return (
        <>
            <Paper className={classes.pageContent}>
                <form
                    className={classes.form}
                    onSubmit={handleSubmit(handleSearchBtnClick)}
                >
                    <Grid container spacing={2} justifyContent="space-between">
                        <Grid item xs={3} className={classes.formWrapper}>
                            <InputLabel>Invoice Number</InputLabel>
                            <Autocomplete
                                onChange={(event: React.ChangeEvent<{}>, value: any | null) => {
                                    onChangeInvoice(value);
                                }
                                }
                                options={studentChoices || []}
                                getOptionLabel={(option) =>
                                    `${option.receipt_id}`
                                }
                                renderOption={(option) => (
                                    <>
                                        <span>
                                            {option.receipt_id}
                                        </span>
                                    </>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Enter Invoice No."
                                        variant="outlined"
                                        onChange={handleSearchStudentByLetter}
                                    />
                                )}
                            />
                            <span style={{ fontSize: '10px', marginTop: '5px' }}>
                                {invoiceData?.invoice_detail[0] && 'Last Updated: ' + moment(invoiceData?.invoice_detail[0]?.modified_on).format("MMMM D YYYY | h:mm:ss A")}
                            </span>
                        </Grid>
                        <Grid item xs={4}>

                            <div style={{ paddingLeft: '70px' }}>
                                {invoiceData?.invoice_detail[0]?.student?.student?.student_user?.first_name &&
                                    invoiceData?.invoice_detail[0]?.student?.student?.student_user?.last_name &&
                                    <CardHeader
                                        avatar={
                                            <Avatar aria-label="recipe">
                                                <img src={invoiceData?.invoice_detail[0]?.student?.student?.student_photo || defaultBase64} />
                                            </Avatar>
                                        }
                                        title={<span style={{ color: '#1f4498' }}>
                                            {invoiceData?.invoice_detail[0]?.student?.student?.student_user?.first_name + " " +
                                                invoiceData?.invoice_detail[0]?.student?.student?.student_user?.last_name}</span>}
                                        subheader={<><span style={{ color: "#7c7c7c" }}>Admn No: </span><span>
                                            {invoiceData?.invoice_detail[0]?.student?.student?.admission_number}</span></>}
                                    />}
                            </div>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </>
    );
};
// -----------------<end> Selected Student form ends-----------------------//
export default BillAdjustmentSearch;
