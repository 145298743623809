// ---------------------------- <START> module imports start ----------------------------------//
import React, { useState, useEffect } from "react";
import { Typography, Paper, Grid, Chip } from "@material-ui/core";
import { useTableStyles } from "../../../Styles/TableStyles";
import { useTable } from "../../../Reusable";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../../store";
import moment from "moment";
import { GetStudentFee } from "../../../../actions/Fees/FeeType/FeeAction";
import CustomizedDialogs from "../../../Reusable/Dialogs/DeleteDialog";
import { createChips } from "../../../Communication/Annoucement/AnnoucementList";
import {
  ItemDeleteButton,
  ItemEditButton,
} from "../../../Reusable/Buttons/TableButton";
import {
  GetFeeInvoice,
  GetFilteredStudentInvoice,
  GetInvoiceHistory,
} from "../../../../actions/Fees/CollectFees/CollectFeesAction";
import { FeeInvoiceI } from "../../../../actions/Fees/CollectFees/CollectFeesActionTypes";
import getMonthName from "../../../utils/getMonthName";
import { useParams } from "react-router";
import { GetStudentSiblings } from "../../../../actions/Student/Student/StudentAction";
import getFullName from "../../../utils/getFullName";
import FeeTable from "./FeeTable";
import CollectFeeStatement from "../../../Finance/CollectFees/CollectFeeStatement";
import {
  GetChildrenFeeInvoice,
  GetChildrenFeePaidInvoice,
} from "../../../../actions/ParentsDash/ChildrenFee/ChildrenFeeAction";
import {
  dateConverterAdToBs,
  dateConverterBsToAd,
} from "../../../utils/dateConverter";
import CustomizedNepaliDatePicker from "../../../Reusable/Inputs/NepaliDatePicker";
import { useFormStyles } from "../../../Styles/FormStyles";
import { CUR_NEPALI_DATE } from "../../../utils/nepaliDateUtils";
import { ResetButton } from "../../../Reusable/Buttons/SearchButton";
// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//

interface HeadCellsI {
  id: string;
  label: string;
}
interface TableDataI {
  id: number;
  is_monthly: boolean;
  name: string;
  amount: number;
  due_date: string;
  count: number;
}
// ---------------------------- <END> interface ends ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "name", label: "Fee Name" },
  { id: "type", label: "Type" },
  { id: "amount", label: "Amount" },
  { id: "date", label: "Due Date" },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const FeeType = (props: any) => {
  const classes = useTableStyles();
  const formClasses = useFormStyles();

  const dispatch = useDispatch();
  const studentSelector = useSelector(
    (state: RootStore) => state.student_profile.student
  );

  const [feeData, setFeeData] = useState<any>([]);
  const [student, setStudent] = useState<any>(null);
  const [feesHead, setFeesHead] = useState<any>([]);
  const [startDate, setStartDate] = useState<any>("");
  const [endDate, setEndDate] = useState<any>("");

  const feeHead = useSelector((state: RootStore) => {
    if (localStorage.getItem("role") === "Administrator") {
      return state.collect_fees?.fee_invoice;
    }
    if (props.value === 0) {
      return state.children_invoice.invoice;
    }
    return state.children_invoice.paid_invoice;
  });

  console.log({ feesHead });

  useEffect(() => {
    if (props.value === 1) {
      setFeesHead({
        paid_invoices: feeHead,
      });
    } else if (props.value === 0) {
      setFeesHead(feeHead);
    } else {
      setFeesHead(feeHead);
    }
  }, [feeHead, props.invoiceMode]);

  useEffect(() => {
    if (props.data) {
      setStudent(props.data);
    } else {
      setStudent(studentSelector);
    }
  }, [studentSelector]);

  console.log({ student });

  useEffect(() => {
    if (student) {
      const academic_id =
        student?.academic_info && student?.academic_info[0]?.id;
      if (
        student?.academic_info &&
        localStorage.getItem("role") === "Administrator"
      ) {
        dispatch(GetFeeInvoice(academic_id));
      } else if (props.value === 0) {
        dispatch(GetChildrenFeeInvoice(academic_id));
      } else if (props.value === 1) {
        dispatch(GetChildrenFeePaidInvoice(academic_id));
      }
    }
  }, [student]);

  useEffect(() => {
    console.log({ feesHead });
    const paidData: any[] = [];
    const dueData: any[] = [];

    if (feesHead?.generated_invoices !== null) {
      const data: any[] = [];
      for (let i = 0; i < feesHead?.generated_invoices?.length; i++) {
        for (
          let j = 0;
          j < feesHead?.generated_invoices[i].invoices?.length;
          j++
        ) {
          dueData.push({
            date: moment(
              feesHead?.generated_invoices[i].invoices[j].created_on
            ).format("YYYY-MM-DD"),
            transaction_type:
              feesHead?.generated_invoices[i].invoices[j].invoice_id,
            fee_head:
              feesHead?.generated_invoices[i].invoices[j].month !== ""
                ? feesHead?.generated_invoices[i].fee_name +
                  " - " +
                  getMonthName(
                    feesHead?.generated_invoices[i].invoices[j].month
                  )
                : feesHead?.generated_invoices[i].fee_name,
            period:
              feesHead?.generated_invoices[i].invoices[j].month !== ""
                ? getMonthName(
                    feesHead?.generated_invoices[i].invoices[j].month
                  )
                : dateConverterAdToBs(
                    feesHead?.generated_invoices[i].invoices[j]
                      .fee_type__due_date
                  ),
            charges: feesHead?.generated_invoices[i].invoices[j].amount,
            fine: "Rs. 0",
            balance: feesHead?.generated_invoices[i].invoices[j].amount,
            status: "Unpaid",
          });
        }
      }
    }

    if (feesHead?.dues !== null) {
      for (let i = 0; i < feesHead?.dues?.length; i++) {
        dueData.push({
          date: moment(feesHead?.dues[i]?.created_on).format("YYYY-MM-DD"),
          transaction_type: feesHead?.dues[i]?.paid_invoice?.receipt,
          fee_head:
            feesHead?.dues[i]?.paid_invoice?.month !== ""
              ? feesHead?.dues[i]?.paid_invoice?.invoice?.fee_type?.name +
                " - " +
                feesHead?.dues[i]?.paid_invoice?.month +
                " (due)"
              : feesHead?.dues[i].invoices?.paid_invoice?.invoice?.fee_type
                  ?.name + " (due)",
          period: getMonthName(feesHead?.dues[i]?.paid_invoice?.invoice?.month),
          charges:
            Number(feesHead?.dues[i]?.paid_invoice?.paid_amount) +
            Number(feesHead?.dues[i]?.due_amount),
          fine: "Rs. 0",
          balance:
            Number(feesHead?.dues[i]?.paid_invoice?.paid_amount) +
            Number(feesHead?.dues[i]?.due_amount),
          status: "Unpaid",
        });
      }
    }

    if (feesHead?.paid_invoices !== null && !props.invoiceMode) {
      for (let i = 0; i < feesHead?.paid_invoices?.length; i++) {
        if (props?.value === 1) {
          paidData.push({
            date: moment(feesHead?.paid_invoices[i]?.created_on).format(
              "YYYY-MM-DD"
            ),
            transaction_type: feesHead?.paid_invoices[i]?.receipt_id,
            charges: feesHead?.paid_invoices[i].paid_amount,
            balance: feesHead?.paid_invoices[i].paid_amount,
            status: "Paid",
          });
        } else {
          paidData.push({
            date: moment(feesHead?.paid_invoices[i]?.created_on).format(
              "YYYY-MM-DD"
            ),
            transaction_type:
              feesHead?.paid_invoices[i]?.receipt__receipt_id ||
              feesHead?.paid_invoices[i]?.receipt_id,
            fee_head:
              feesHead?.paid_invoices[i].invoice__month &&
              feesHead?.paid_invoices[i].invoice__month !== ""
                ? feesHead?.paid_invoices[i].invoice__fee_type__name +
                  " - " +
                  getMonthName(feesHead?.paid_invoices[i].invoice__month)
                : feesHead?.paid_invoices[i].invoice__fee_type__name,
            period: feesHead?.paid_invoices[i].invoice__month
              ? getMonthName(feesHead?.paid_invoices[i].invoice__month)
              : null,
            charges: feesHead?.paid_invoices[i].paid_amount,
            fine: feesHead?.paid_invoices[i].fine_amount
              ? "Rs. " + feesHead?.paid_invoices[i].fine_amount
              : "Rs 0",
            balance: feesHead?.paid_invoices[i].paid_amount,
            status: "Paid",
          });
        }
      }
      // setStatementTableData(paidData)
    }
    console.log({ dueData });
    setFeeData(dueData.concat(paidData));
  }, [feesHead, props.invoiceMode]);

  const handleStartDate = (data: string) => {
    setStartDate(data);
    const academic_id = student?.academic_info && student?.academic_info[0]?.id;
    dispatch(
      GetFilteredStudentInvoice(
        academic_id,
        dateConverterBsToAd(data),
        endDate !== ""
          ? dateConverterBsToAd(endDate)
          : dateConverterBsToAd(CUR_NEPALI_DATE)
      )
    );
  };

  const handleEndDate = (data: string) => {
    setEndDate(data);
    const academic_id = student?.academic_info && student?.academic_info[0]?.id;
    dispatch(
      GetFilteredStudentInvoice(
        academic_id,
        startDate !== ""
          ? dateConverterBsToAd(startDate)
          : dateConverterBsToAd(CUR_NEPALI_DATE),
        dateConverterBsToAd(data)
      )
    );
  };

  const restData = () => {
    const academic_id = student?.academic_info && student?.academic_info[0]?.id;
    dispatch(GetFeeInvoice(academic_id));
    setStartDate("");
    setEndDate("");
  };

  return (
    <>
      <Paper className={classes.roottable}>
        <Typography variant="h6">
          {props.invoiceMode ? "Fee Invoices" : "Fee Statements"}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div>
              <Grid container spacing={2}>
                <Grid item xs={3} className={formClasses.formWrapper}>
                  <CustomizedNepaliDatePicker
                    value={startDate}
                    name="start_date"
                    setValue={handleStartDate}
                    label="Start Date"
                  />
                </Grid>
                <Grid item xs={3} className={formClasses.formWrapper}>
                  <CustomizedNepaliDatePicker
                    value={endDate}
                    name="end_date"
                    setValue={handleEndDate}
                    label="End Date"
                  />
                  <Grid item xs={3} className={formClasses.formWrapper}>
                    <ResetButton
                      onClick={restData}
                      style={{ marginTop: "5px" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>

        <FeeTable
          student={student}
          statementTableData={feeData}
          feesHead={feesHead}
          value={props.value}
          isInvoice={props.invoiceMode}
        />
      </Paper>
    </>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default FeeType;
