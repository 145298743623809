import {
    GET_VOID_ERROR,
    GET_VOID_LOADING,
    GET_VOID_SUCCESS,
    VoidDispatchTypes,
} from "../../actions/Fees/Void/VoidActionTypes";

interface InitialStateI {
    loading: boolean;
    voids: any;
}

const initialState: InitialStateI = {
    loading: false,
    voids: [],
};

const VoidReducer = (
    state: InitialStateI = initialState,
    action: VoidDispatchTypes
): InitialStateI => {
    switch (action.type) {
        case GET_VOID_LOADING:
            return {
                ...state,
                loading: true,
            };

        case GET_VOID_ERROR:
            return {
                ...state,
                loading: false,
            };

        case GET_VOID_SUCCESS:
            return {
                ...state,
                loading: false,
                voids: action.payload,
            };

        default:
            return state;
    }
};

export default VoidReducer;