//---------------------------- <START> module imports start ----------------------------------//
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Paper, Button, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';
import { getSingleMarksheet } from './MarksheetSampleData';
import { RootStore } from '../../../../store';
import ReportCardPopup1 from '../../Marksheet/ReportCardPopup1';
import {
  AddMarksheetTemplate,
  GetSingleMarksheetTemplate,
  UpdateMarksheetTemplate,
} from '../../../../actions/Templates/Marksheet/MarksheetTemplateAction';
// ----------------------------<END> module imports end ----------------------------------//

const style = makeStyles({
  btnRight: {
    color: 'white',
    top: '50%',
    float: 'right',
    position: 'relative',
    transform: 'translateY(-50%)',
  },
  title: {
    fontSize: '17px',
  },
  cardtitle: {
    fontSize: '15px',
  },
  card: {
    padding: 7,
    border: '1px solid #c5d8dd',
    '&:hover': {
      border: '1px solid blue',
      transform: 'scale(1.009)',
    },
  },
  smallbtn: {
    backgroundColor: '#a7a4a444',
    marginLeft: '5px',
    marginRight: '5px',
    '&:hover': {
      backgroundColor: '#64A1C2',
      color: 'white',
    },
  },
  smallbtnActive: {
    backgroundColor: 'blue',
    marginLeft: '5px',
    marginRight: '5px',
    color: 'white',
    '&:hover': {
      backgroundColor: '#64A1C2',
      color: 'white',
    },
  },
});

const CustomizeMarksheet: React.FC = () => {
  const { cardID } = useParams<{ cardID: string }>();
  const [individualData, setIndividualData] = useState<any>();
  const [saved, setSaved] = useState<boolean>(false);
  const [url, setUrl] = useState<string>('');

  const classes = style();
  const history = useHistory();
  const dispatch = useDispatch();

  const marksheet_templates = useSelector(
    (state: RootStore) => state.marksheet_templates
  );

  const marksheet_template = useSelector(
    (state: RootStore) => state.marksheet_templates?.marksheet_template
  );

  useEffect(() => {
    const n = window.location.href.lastIndexOf('/');
    setUrl(window.location.href.substring(n + 1));

    const data = getSingleMarksheet(cardID);
    setIndividualData(data);
  }, []);

  useEffect(() => {
    if (url === 'update') {
      dispatch(GetSingleMarksheetTemplate(cardID));
    }
  }, [url, cardID]);

  useEffect(() => {
    if (marksheet_template != null) {
      setIndividualData(marksheet_template);
    }
  }, [marksheet_template]);

  const handlleSubmit = (finalValues: any) => {
    if (url === 'update') {
      dispatch(UpdateMarksheetTemplate(cardID, finalValues));
    } else {
      dispatch(AddMarksheetTemplate(finalValues));
    }
    setSaved(true);
  };

  const renderSwitch = (param: string) => {
    switch (param) {
      case 'ABC-001':
        return (
          <ReportCardPopup1
            handlleSubmit={handlleSubmit}
            editor={true}
            student=""
            content={individualData}
          />
        );
      default:
        return 'Nothing Found';
    }
  };

  if (saved && marksheet_templates.add_or_update) {
    history.push('/marksheet-templates');
  }

  return (
    <>
      <Paper style={{ margin: '30px', padding: '20px' }}>
        <Grid container spacing={3} justifyContent="space-between">
          <Grid item xs={12} sm={6}>
            <span className={classes.title}>
              {url === 'update' ? 'In Update Mode' : 'In Customization Mode'}
            </span>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              onClick={() => history.push('/marksheet-templates')}
            >
              Go Back to My List
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          justifyContent="space-between"
          style={{ marginTop: '10px' }}
        >
          <Grid item xs={12} sm={12}>
            <div
              id="exportContainer"
              style={{
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                padding: '10px',
              }}
            >
              {cardID && renderSwitch(individualData?.code)}
            </div>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//
export default CustomizeMarksheet;
