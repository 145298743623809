import React from "react";
import { HRsidebar } from "../../../components";
import AttendanceReport from "../../../components/HumanResource/Attendance/AttendanceReport";
import SubModuleLayout from "../../../components/Reusable/Layouts/SubModuleLayout";

const AttendanceTodayPage = () => {
  const nav = <HRsidebar attendanceReport />;
  return (
    <SubModuleLayout HumanMenuActive sideNav={nav}>
      <AttendanceReport />
    </SubModuleLayout>
  );
};

export default AttendanceTodayPage;
