import { useState, useEffect } from 'react';
import {
    Grid,
    TextField,
    InputLabel,
} from "@material-ui/core";
import { useStyles } from '../../../../Styles/FormStyles';


const StaffPhoneNumberForm = (props: any) => {
    const classes = useStyles();
    const [error, setError] = useState(false);

    useEffect(() => {
        if (props.phone) {
            if (props.phone.length < 9 || props.phone.length > 10) {
                setError(true);
            } else {
                setError(false);
            }
        }
    }, [props.phone])

    return (
        <form className={classes.form} style={{ marginBottom: "2rem" }}>
            <Grid container>
                <Grid item md={12} className={classes.formWrapper} style={{ marginLeft: "3rem" }}>
                    <InputLabel>
                        Contact Number <span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <TextField
                        variant="outlined"
                        name="phone"
                        type="number"
                        style={{ width: "70%" }}
                        placeholder="contact number"
                        inputProps={{ min: 0 }}
                        value={props.phone}
                        onChange={(e) => props.setPhoneNumber(e.target.value)}
                    />
                    {error && <p style={{ color: "red" }}>Phone Number must be 9 or 10 digits</p>}
                </Grid>
            </Grid>
        </form >
    )
}

export default StaffPhoneNumberForm