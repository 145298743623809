import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setSnackbar } from "../../../actions/SnackbarAction";
import {
  ADMIN_API_URL,
  TEACHER_API_URL,
} from "../../../config/SERVER_API_URLS";

export const getTimeTableByClass = createAsyncThunk(
  "getTimeTableByClass",
  async (
    { grade_id, section_id }: { grade_id: string; section_id: string | null },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.get(
        `${ADMIN_API_URL}/time-table/get_timetable_by_class/?grade_id=${grade_id}&section_id=${
          section_id || ""
        }`
      );
      return res.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(null);
    }
  }
);

export const getTimeTableByDay = createAsyncThunk(
  "getTimeTableByDay",
  async (
    {
      grade_id,
      section_id,
      day,
    }: { grade_id: string; section_id: string | null; day: number },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.get(
        `${ADMIN_API_URL}/time-table/get_timetable_by_day/?grade_id=${grade_id}&section_id=${
          section_id || ""
        }&day=${day}`
      );
      return res.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(null);
    }
  }
);

export const getTeacherTimeTable = createAsyncThunk(
  "getTeacherTimeTable",
  async (teacher_id: string, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.get(
        `${ADMIN_API_URL}/teacher-timetable/?teacher_id=${teacher_id}`
      );
      return res.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(null);
    }
  }
);

export const getTeacherDashboardTimeTable = createAsyncThunk(
  "getTeacherDashboardTimeTable",
  async (undefined, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.get(`${TEACHER_API_URL}/timetable`);
      return res.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(null);
    }
  }
);

export const updateTimeTable = createAsyncThunk(
  "updateTimeTable",
  async (data: any, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.post(
        `${ADMIN_API_URL}/create-timetable-by-period/`,
        data
      );
      dispatch(setSnackbar(true, "success", "Time table updated"));
      return res.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      dispatch(setSnackbar(true, "error", err.response.data.error[0]));
      return rejectWithValue(null);
    }
  }
);

export const copyTimeTable = createAsyncThunk(
  "copyTimeTable",
  async (
    data: {
      grade_id: string;
      section_id: string | null;
      from_day: number;
      to_days: number[];
      periods: string[];
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.post(
        `${ADMIN_API_URL}/time-table/bulk_copy_timetable/`,
        data
      );
      dispatch(setSnackbar(true, "success", "Time table updated"));
      return res.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      dispatch(setSnackbar(true, "error", "Failed to copy time table"));
      return rejectWithValue(null);
    }
  }
);
