import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_COLLECT_FEES_LOADING,
  GET_COLLECT_FEES_ERROR,
  GET_COLLECT_FEES_SUCCESS,
  COLLECT_FEES_LOADING,
  COLLECT_FEES_ERROR,
  COLLECT_FEES_SUCCESS,
  GET_GENERATE_BILL_LOADING,
  GET_GENERATE_BILL_ERROR,
  GET_GENERATE_BILL_SUCCESS,
  GET_FEE_INVOICE_LOADING,
  GET_FEE_INVOICE_ERROR,
  GET_FEE_INVOICE_SUCCESS,
  GET_INVOICE_RECORD_LOADING,
  GET_INVOICE_RECORD_ERROR,
  GET_INVOICE_RECORD_SUCCESS,
  CollectFeesDispatchTypes,
  CollectFeesRequestI,
  GET_STATEMENT_DETAILS_LOADING,
  GET_STATEMENT_DETAILS_ERROR,
  GET_STATEMENT_DETAILS_SUCCESS,
  UPDATE_PAID_INVOICE_LOADING,
  UPDATE_PAID_INVOICE_ERROR,
  UPDATE_PAID_INVOICE_SUCCESS,
  GET_INVOICE_CHANGES_LOG_LOADING,
  GET_INVOICE_CHANGES_LOG_ERROR,
  GET_INVOICE_CHANGES_LOG_SUCCESS,
  CLOSE_INVOICE_PRINT,
  StatementDetailsI,
} from "./CollectFeesActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";
import { setSnackbar } from "../../SnackbarAction";

export const GetInvoiceHistory =
  (id: any) =>
  async (dispatch: Dispatch<CollectFeesDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_COLLECT_FEES_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/invoice_list/?student=${id}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_COLLECT_FEES_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_COLLECT_FEES_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "Invoice Loading Failed",
      //   },
      // });
    }
  };

export const GetInvoiceHistoryMonthWise =
  (id: any, month: string, from_date: string, to_date: string) =>
  async (dispatch: Dispatch<CollectFeesDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_COLLECT_FEES_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/invoice_list/?student=${id}&month=${month}&from_date=${from_date}&to_date=${to_date}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_COLLECT_FEES_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_COLLECT_FEES_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "Invoice Loading Failed",
      //   },
      // });
    }
  };

export const GetenerateBill =
  (data: any) =>
  async (dispatch: Dispatch<CollectFeesDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_GENERATE_BILL_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/bill_row/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: GET_GENERATE_BILL_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Bill Generated Success",
        },
      });
    } catch (error) {
      dispatch({
        type: GET_GENERATE_BILL_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Bill Generated Failed",
        },
      });
    }
  };

export const GetFeeInvoice =
  (student_id: string) =>
  async (dispatch: Dispatch<CollectFeesDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_FEE_INVOICE_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/invoice_list/?student=${student_id}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_FEE_INVOICE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_FEE_INVOICE_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Invoice Fetching Failed",
        },
      });
    }
  };

export const GetFilteredStudentInvoice =
  (student_id: string, from: string, to: string) =>
  async (dispatch: Dispatch<CollectFeesDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_FEE_INVOICE_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/invoice_list/?student=${student_id}&from_date=${from}&to_date=${to}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_FEE_INVOICE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_FEE_INVOICE_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Invoice Fetching Failed",
        },
      });
    }
  };

export const CollectFee =
  (student_id: string, data: any, func: any) =>
  async (dispatch: Dispatch<CollectFeesDispatchTypes | SetSnackBarI>) => {
    const url = `${ADMIN_API_URL}/paid-invoice/?student=${student_id}`;

    try {
      dispatch({
        type: COLLECT_FEES_LOADING,
      });

      const response = await axios.post(url, data, HeaderConfig());

      dispatch({
        type: COLLECT_FEES_SUCCESS,
        payload: response.data,
      });

      // const res = await axios.get(
      //   `${ADMIN_API_URL}/invoice_list/?student=${student_id}`,
      //   HeaderConfig()
      // );

      // dispatch({
      //   type: GET_FEE_INVOICE_SUCCESS,
      //   payload: res.data,
      // });

      func();

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Fee Payment Done Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: COLLECT_FEES_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Fee Payment Failed",
        },
      });
    }
  };

// export const CollectFee =
//   (
//     student_id: string,
//     payment_id: string,
//     data: CollectFeesRequestI[],
//     send_sms?: boolean
//   ) =>
//   async (dispatch: Dispatch<CollectFeesDispatchTypes | SetSnackBarI>) => {
//     let url = `${ADMIN_API_URL}/paid-invoice/?student=${student_id}&payment_method=${payment_id}`;

//     if (send_sms) {
//       url = `${ADMIN_API_URL}/paid-invoice/?student=${student_id}&payment_method=${payment_id}&send_sms=true`;
//     }

//     try {
//       dispatch({
//         type: COLLECT_FEES_LOADING,
//       });

//       const response = await axios.post(url, data, HeaderConfig());

//       dispatch({
//         type: COLLECT_FEES_SUCCESS,
//         payload: response.data,
//       });

//       const res = await axios.get(
//         `${ADMIN_API_URL}/invoice_list/?student=${student_id}`,
//         HeaderConfig()
//       );

//       dispatch({
//         type: GET_FEE_INVOICE_SUCCESS,
//         payload: res.data,
//       });

//       dispatch({
//         type: SET_SNACKBAR,
//         payload: {
//           snackbarOpen: true,
//           snackbarType: "success",
//           snackbarMessage: "Fee Payment Done Successfully",
//         },
//       });
//     } catch (error) {
//       dispatch({
//         type: COLLECT_FEES_ERROR,
//       });

//       dispatch({
//         type: SET_SNACKBAR,
//         payload: {
//           snackbarOpen: true,
//           snackbarType: "error",
//           snackbarMessage: "Fee Payment Failed",
//         },
//       });
//     }
//   };

export const GetInvoiceRecord =
  (student_id: string) =>
  async (dispatch: Dispatch<CollectFeesDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_INVOICE_RECORD_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/fee-statement/?student=${student_id}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_INVOICE_RECORD_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_INVOICE_RECORD_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Failed Fetching Invoice Record",
        },
      });
    }
  };

export const closeInvoicePrint = () => {
  async (dispatch: any) => {
    dispatch({
      type: CLOSE_INVOICE_PRINT,
    });
  };
};

export const GetStatementDetails =
  (statement_id: string) =>
  async (dispatch: Dispatch<CollectFeesDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_STATEMENT_DETAILS_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/invoice/${statement_id}/`,
        HeaderConfig()
      );

      dispatch({
        type: GET_STATEMENT_DETAILS_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_STATEMENT_DETAILS_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "warning",
          snackbarMessage: "Failed to Fetch Invoice Details",
        },
      });
    }
  };

export const UpdatePaidInvoice =
  (data: { id: string; paid_amount: number }, student_id: string) =>
  async (dispatch: Dispatch<CollectFeesDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_PAID_INVOICE_LOADING,
      });

      await axios.post(
        `${ADMIN_API_URL}/paid-invoice/update/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_PAID_INVOICE_SUCCESS,
      });
      dispatch<any>(
        setSnackbar(true, "success", "Invoice Updated Successfully")
      );
    } catch (error) {
    } finally {
      try {
        dispatch({
          type: GET_INVOICE_RECORD_LOADING,
        });

        const res = await axios.get(
          `${ADMIN_API_URL}/fee-statement/?student=${student_id}`,
          HeaderConfig()
        );

        dispatch({
          type: GET_INVOICE_RECORD_SUCCESS,
          payload: res.data,
        });
      } catch (err) {
        dispatch({
          type: UPDATE_PAID_INVOICE_ERROR,
        });

        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "warning",
            snackbarMessage: "Failed to Update Invoice",
          },
        });
      }
    }
  };

export const GetInvoiceChangesLog =
  (student_id: string) =>
  async (dispatch: Dispatch<CollectFeesDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_INVOICE_CHANGES_LOG_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/logs/paid-invoice/?student=${student_id}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_INVOICE_CHANGES_LOG_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_INVOICE_CHANGES_LOG_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "warning",
          snackbarMessage: "Failed to Fetch Invoice Details",
        },
      });
    }
  };
