import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_RESULT_LOADING,
  GET_RESULT_ERROR,
  GET_RESULT_SUCCESS,
  GENERATE_RESULT_LOADING,
  GENERATE_RESULT_ERROR,
  GENERATE_RESULT_SUCCESS,
  ResultDispatchTypes,
} from "./ResultActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import {
  ADMIN_API_URL,
  TEACHER_API_URL,
} from "../../../config/SERVER_API_URLS";

export const GetResult =
  (exam_info: string, grade: string, section: string) =>
  async (dispatch: Dispatch<ResultDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_RESULT_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/student-result/?exam_info=${exam_info}&grade=${grade}&section=${section}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_RESULT_SUCCESS,
        payload: res.data,
      });
    } catch (error: any) {
      dispatch({
        type: GET_RESULT_ERROR,
      });

      try {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: error.response.statusText,
          },
        });
      } catch (error) {
        // dispatch({
        //   type: SET_SNACKBAR,
        //   payload: {
        //     snackbarOpen: true,
        //     snackbarType: "error",
        //     snackbarMessage: "Result Loading Failed",
        //   },
        // });
      }
    }
  };

export const GetResultT =
  (exam_info: string, grade: string, section: string) =>
  async (dispatch: Dispatch<ResultDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_RESULT_LOADING,
      });

      const res = await axios.get(
        `${TEACHER_API_URL}/student_subject_mark/?exam_info=${exam_info}&grade=${grade}&section=${section}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_RESULT_SUCCESS,
        payload: res.data,
      });
    } catch (error: any) {
      dispatch({
        type: GET_RESULT_ERROR,
      });

      try {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: error.response.statusText,
          },
        });
      } catch (error) {
        // dispatch({
        //   type: SET_SNACKBAR,
        //   payload: {
        //     snackbarOpen: true,
        //     snackbarType: "error",
        //     snackbarMessage: "Result Loading Failed",
        //   },
        // });
      }
    }
  };

export const GenerateResult =
  (
    exam_info: string,
    grade: string,
    section: string,
    data: {
      exam_info: string;
      students: string[];
    }
  ) =>
  async (dispatch: Dispatch<ResultDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GENERATE_RESULT_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/save/student/mark-sheet/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: GENERATE_RESULT_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Result Generated Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: GENERATE_RESULT_ERROR,
      });

      try {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: error.response.statusText,
          },
        });
      } catch (error) {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Failed to Generate Result",
          },
        });
      }
    } finally {
      try {
        dispatch({
          type: GET_RESULT_LOADING,
        });

        const res = await axios.get(
          `${ADMIN_API_URL}/student-result/?exam_info=${exam_info}&grade=${grade}&section=${section}`,
          HeaderConfig()
        );

        dispatch({
          type: GET_RESULT_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: GET_RESULT_ERROR,
        });
      }
    }
  };
