export const GET_EVENTS_LOADING = 'GET_EVENTS_LOADING';
export const GET_EVENTS_ERROR = 'GET_EVENTS_ERROR';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';

export const ADD_EVENTS_LOADING = 'ADD_EVENTS_LOADING';
export const ADD_EVENTS_ERROR = 'ADD_EVENTS_ERROR';
export const ADD_EVENTS_SUCCESS = 'ADD_EVENTS_SUCCESS';

export const UPDATE_EVENTS_LOADING = 'UPDATE_EVENTS_LOADING';
export const UPDATE_EVENTS_ERROR = 'UPDATE_EVENTS_ERROR';
export const UPDATE_EVENTS_SUCCESS = 'UPDATE_EVENTS_SUCCESS';

export const DELETE_EVENTS_LOADING = 'DELETE_EVENTS_LOADING';
export const DELETE_EVENTS_ERROR = 'DELETE_EVENTS_ERROR';
export const DELETE_EVENTS_SUCCESS = 'DELETE_EVENTS_SUCCESS';

export type EventsTypeI = {
  id: string;
  name: string;
  academic_session: string;
  created_by: string;
  description: string;
  event_type: string;
  event_type_name: string;
  from_date: string;
  general_info: string;
  is_holiday: boolean;
  location: string;
  time: string;
  title: string;
  to_date: string;
};

//===============================<START>GET EVENTS<START>======================//

export interface GetEventsLoading {
  type: typeof GET_EVENTS_LOADING;
}

export interface GetEventsError {
  type: typeof GET_EVENTS_ERROR;
}

export interface GetEventsSuccess {
  type: typeof GET_EVENTS_SUCCESS;
  payload: EventsTypeI[];
}

//===============================<END>GET EVENTS<END>======================//

//===============================<START>ADD EVENTS<START>======================//

export interface AddEventsLoading {
  type: typeof ADD_EVENTS_LOADING;
}

export interface AddEventsError {
  type: typeof ADD_EVENTS_ERROR;
  payload: any;
}

export interface AddEventsSuccess {
  type: typeof ADD_EVENTS_SUCCESS;
  payload: EventsTypeI;
}

//===============================<END>ADD EVENTS<END>======================//

//===============================<START>UPDATE EVENTS<START>======================//

export interface UpdateEventsLoading {
  type: typeof UPDATE_EVENTS_LOADING;
}

export interface UpdateEventsError {
  type: typeof UPDATE_EVENTS_ERROR;
  payload: any;
}

export interface UpdateEventsSuccess {
  type: typeof UPDATE_EVENTS_SUCCESS;
  payload: EventsTypeI;
}

//===============================<END>UPDATE EVENTS<END>======================//

//===============================<START>DELETE EVENTS<START>======================//

export interface DeleteEventsLoading {
  type: typeof DELETE_EVENTS_LOADING;
}

export interface DeleteEventsError {
  type: typeof DELETE_EVENTS_ERROR;
}

export interface DeleteEventsSuccess {
  type: typeof DELETE_EVENTS_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE EVENTS<END>======================//

export type EventsDispatchTypes =
  | GetEventsLoading
  | GetEventsError
  | GetEventsSuccess
  | AddEventsLoading
  | AddEventsError
  | AddEventsSuccess
  | UpdateEventsLoading
  | UpdateEventsError
  | UpdateEventsSuccess
  | DeleteEventsLoading
  | DeleteEventsError
  | DeleteEventsSuccess;
