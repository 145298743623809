// -----------------<START> import modules starts----------------------//
import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  InputLabel,
  Link,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { Search, Add } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { useFormStyles } from "../../Styles/FormStyles";
import AddNewButton from "../../Reusable/Buttons/AddNewButton";
import "nepali-datepicker-reactjs/dist/index.css";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import { InputBox } from "../../Reusable/Inputs/Select";
import { ResetButton, SubmitButton } from "../../Reusable/Buttons/SearchButton";
import { Fee_TypeI } from "../../../actions/Fees/FeeType/FeeTypeActionTypes";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import { GetFeeType } from "../../../actions/Fees/FeeType/FeeTypeAction";
import { GetFeeTypeReport } from "../../../actions/Fees/FeeLedger/FeeLedgerAction";

// -----------------<END> imports module ends -----------------------//

// -----------------<start> Styling <start>-----------------------//

// -----------------<end> Styling <end>-----------------------//

// -----------------<start> Interfaces starts-----------------------//

// -----------------<END> Interfaces ends-----------------------//

// -----------------<START> Annoucement search component starts-----------------------//
const FeeTypeReportForm: React.FC = () => {
  const classes = useFormStyles();
  const [feeType, setFeeType] = useState<Fee_TypeI | null>(null);

  const dispatch = useDispatch();
  const feeTypeSelector = useSelector((state: RootStore) => state.fee_type);

  useEffect(() => {
    dispatch(GetFeeType(1))
  }, [])

  const handleSubmit = (e: any) => {
    e.preventDefault();
    feeType ? dispatch(GetFeeTypeReport(feeType.id)) : dispatch(GetFeeTypeReport());
  }

  const handleReset = () => {
    setFeeType(null);
    dispatch(GetFeeTypeReport());
  }


  return (
    <>
      <Paper className={classes.pageContentBox} style={{ marginRight: "25px" }}>
        <form className={classes.form}>
          <Grid container alignItems="center">
            <Grid item xs={9} className={classes.formTitle}>
              <Typography variant="h6">Select Criteria</Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid container spacing={3}>
              <Grid item xs={3} className={classes.formWrapperRow}>
                <InputBox>Fee</InputBox>
                <Autocomplete
                  value={feeType}
                  classes={{
                    input: classes.smallfont,
                    option: classes.smallfont,
                  }}
                  fullWidth
                  onChange={(
                    event: React.ChangeEvent<{}>,
                    value: Fee_TypeI | null
                  ) => setFeeType(value)}
                  options={feeTypeSelector.feeType}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="grade"
                      placeholder="Label"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <SubmitButton style={{ marginTop: "5px" }} onClick={(e: any) => handleSubmit(e)} />
              <ResetButton style={{ marginTop: "5px" }} onClick={() => handleReset()} />
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
};

//-----------------<ENDS> Annoucement search component ends-----------------------//
export default FeeTypeReportForm;
