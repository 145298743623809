//---------------------------- <START> module imports start ----------------------------------//
import React, { useEffect, useState } from 'react';
import { Grid, Typography, Paper, Checkbox, Dialog } from '@material-ui/core';
import { PrintOutlined, Visibility } from '@material-ui/icons';
import { useTableWithPagination } from '../../Reusable';
import { useTableStyles } from '../../Styles/TableStyles';
import IdCardPopup from './IdCardPopup';
import cx from 'clsx';
import ActionButton from '../../Reusable/Buttons/ActionButton';
import { RootStore } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { GetStudentListWithPagination } from '../../../actions/Student/Student/StudentAction';
import getFullName from '../../utils/getFullName';
import {
  GetIdCard,
  GetMultipleIdCard,
} from '../../../actions/Reports/IdCard/IdCardAction';
import formatDate from '../../utils/formatDate';
import FormButton from '../../Reusable/Buttons/FormButton';
import getClassName from '../../utils/getClassName';
import PotraitStudentID from '../NewIDCARDDESIGN/potrait/PotraitStudentID';
import LandScapeStudentID from '../NewIDCARDDESIGN/landscape/LandScapeStudentID';
import PotraitTeacherIDCard from '../NewIDCARDDESIGN/potrait/PotraitTeacherIDCard';
import LandScapeTeacherCard from '../NewIDCARDDESIGN/landscape/LandScapeTeacherCard';
import {
  GetIdCardTemplate,
  GetSingleIdCardTemplate,
} from '../../../actions/Templates/IdCard/IdCardTemplateAction';
import MultipleIdCardPopup from './MultipleIdCardPopup';
// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//
interface TableDataI {
  id: string;
  guardian: string;
  student_name: string;
  roll_no: string;
  adm_no: string;
  address?: string;
  guardian_no: string;
}

interface HeadCellsI {
  id: string;
  label: string;
}

// ---------------------------- <END> interface ends ----------------------------------//

const dummyData: TableDataI[] = [
  {
    id: '1',
    student_name: 'Student 1',
    adm_no: 'A030',
    roll_no: '11',
    address: 'Nakhipot, Lalitpur',
    guardian: 'Ram Bahadur',
    guardian_no: '9876567876',
  },
];

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: 'name', label: 'Student Name' },
  { id: 'adm_no', label: 'Adm No.' },
  { id: 'roll_no', label: 'Roll No' },
  { id: 'guardian', label: 'Guardian' },
  { id: 'phone_no', label: 'Contact' },
  { id: 'action', label: 'Action' },
];

//---------------------------- <START> Student Table Component Starts ---------------------------//
const IdCardList: React.FC = () => {
  const classes = useTableStyles();

  const dispatch = useDispatch();
  // States declaration
  const [loading, setLoading] = useState<boolean>(false);
  const [idCard, setIdCard] = useState<any>();

  const [tableData, setTableData] = useState<any[]>(dummyData);
  const [open, setOpen] = useState(false);
  const [activeID, setActiveID] = useState<any>(null);
  const [openGenerate, setOpenGenerate] = useState(false);

  // Retrieving re-usable components from useTable
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTableWithPagination(headCells);

  const sessionLoading = useSelector(
    (state: RootStore) => state.student.loading
  );
  const studentListData = useSelector((state: RootStore) => state.student);
  const idCardSelector = useSelector((state: RootStore) => state.id_card);
  const my_templates = useSelector(
    (state: RootStore) => state.id_card_templates.id_card_templates
  );

  useEffect(() => {
    dispatch(GetStudentListWithPagination(1));
    dispatch(GetIdCardTemplate());
  }, []);

  useEffect(() => {
    if (my_templates != null) {
      setActiveID(
        my_templates.find(
          (item: any) => item.active === true && item.type === 'Student'
        )
      );
    }
  }, [my_templates]);

  useEffect(() => {
    const idCardData = {
      school_name: idCardSelector.id_card?.id_info?.school_name,
      school_address: idCardSelector.id_card?.id_info?.school_address,
      school_phone: idCardSelector.id_card?.id_info?.school_number,
      student_photo: idCardSelector.id_card?.id_info?.student_photo,
      slogan: idCardSelector.id_card?.id_info?.school_slogan,
      student_name: getFullName(
        idCardSelector.id_card?.id_info?.student_first_name,
        idCardSelector.id_card?.id_info?.student_last_name
      ),
      admission_no: idCardSelector.id_card?.id_info?.admission_number,
      father_name: getFullName(
        idCardSelector.id_card?.id_info?.guardian_first_name,
        idCardSelector.id_card?.id_info?.guardian_last_name
      ),
      address: idCardSelector.id_card?.id_info?.address,
      dob: idCardSelector.id_card?.id_info?.date_of_birth,
      blood_group: idCardSelector.id_card?.id_info?.blood_group,
      phone_number: idCardSelector.id_card?.id_info?.phone_number,
    };

    setIdCard(idCardData);
  }, [idCardSelector]);

  useEffect(() => {
    setLoading(sessionLoading);
  }, [sessionLoading]);

  useEffect(() => {
    const data: any = [];
    if (studentListData.student_list.results != null) {
      studentListData.student_list.results?.map((elem: any, index: number) => {
        data.push({
          key: index + 1,
          id: elem.id,
          academic_id: elem.academic_id,
          student_photo: elem.student_photo,
          name: getFullName(elem.student_first_name, elem.student_last_name),
          adm_no: elem.admission_number,
          class: getClassName(elem.grade, elem.section),
          roll_no: elem.roll_number,
          phone_no: elem.phone_number,
          guardian: getFullName(
            elem.guardian_first_name,
            elem.guardian_last_name
          ),
          guardian_no: elem.guardian_detail?.phone_number,
          address: elem.address,
        });
      });
    }
    setTableData(data);
  }, [studentListData]);

  // Function for handling action icons click event

  const handleClickOpen = (id: string) => {
    if (activeID != null) {
      dispatch(GetIdCard(id));
      setOpen(true);
    }
  };

  const handleClose = (value: boolean) => {
    setOpen(false);
    // setSelectedValue(value);
  };

  const handleCloseGenerate = () => {
    setOpenGenerate(false);
  };

  const [selected, setSelected] = React.useState<string[]>([]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = tableData.map((n) => n.academic_id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleCheckClick = (name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  useEffect(() => {
    if (selected != null) {
      const params = {
        students: selected,
        template: 'ID',
      };
      dispatch(GetMultipleIdCard(params));
    }
  }, [selected]);

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const getCustomTableRow = (item: any, index: number) => {
    const isItemSelected = isSelected(item.academic_id);
    const labelId = `enhanced-table-checkbox-${index}`;
    const cellStyle = cx(classes.cell, classes.cellSm);

    return (
      <StyledTableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={item.id}
        selected={isItemSelected}
      >
        <StyledTableCell className={cellStyle} align="left">
          <Checkbox
            color="default"
            checked={isItemSelected}
            inputProps={{ 'aria-labelledby': labelId }}
            onClick={() => handleCheckClick(item.academic_id)}
          />
          {item.name}
        </StyledTableCell>
        <StyledTableCell className={cellStyle} align="center">
          {item.adm_no}
        </StyledTableCell>
        <StyledTableCell className={cellStyle} align="center">
          {item.roll_no}
        </StyledTableCell>
        <StyledTableCell className={cellStyle} align="center">
          {item.guardian}
        </StyledTableCell>
        <StyledTableCell className={cellStyle} align="center">
          {item.phone_no}
        </StyledTableCell>
        <StyledTableCell className={cellStyle} align="center">
          <ActionButton
            title={
              activeID != null ? 'View' : 'First activate one of the template'
            }
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleClickOpen(item.academic_id)
            }
          >
            <Visibility className={classes.addIcon} />
          </ActionButton>
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  const handlePaginationChange = (paginationIndex: number) => {
    dispatch(GetStudentListWithPagination(paginationIndex));
  };

  const renderSwitch = (param: string) => {
    switch (param) {
      case 'ABC-001':
        return <PotraitStudentID content={activeID} data={idCard} />;
      case 'ABC-002':
        return <PotraitTeacherIDCard content={activeID} data={idCard} />;
      case 'ABC-003':
        return <LandScapeStudentID content={activeID} data={idCard} />;
      case 'ABC-004':
        return <LandScapeTeacherCard content={activeID} data={idCard} />;
      default:
        return 'Nothing Found';
    }
  };

  const handleOpenGenerate = () => {
    setOpenGenerate(true);
    dispatch(GetSingleIdCardTemplate(activeID?.id));
  };

  return (
    <Paper className={classes.rootTableBox}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6">Student list for ID Card</Typography>
        </Grid>
      </Grid>

      <TableContainer
        hasCheckbox
        rowCount={tableData.length}
        handleSelectAllClicked={handleSelectAllClick}
        loading={loading}
        numSelected={selected.length}
        items={tableData}
        headCells={headCells}
        search_name="name"
        getCustomTableRow={getCustomTableRow}
        forPagination={studentListData?.student_list}
        handlePaginationChange={handlePaginationChange}
      />

      <Grid container justifyContent="flex-end">
        <FormButton
          startIcon={<PrintOutlined />}
          style={{ minWidth: '150px', marginTop: '16px' }}
          disabled={activeID != null && selected?.length ? false : true}
          onClick={handleOpenGenerate}
        >
          {activeID != null ? 'Generate' : 'Generate( not activated)'}
        </FormButton>
      </Grid>
      {activeID?.mode === 'potrait' ? (
        <Dialog
          maxWidth="sm"
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={open}
        >
          <IdCardPopup activeID={activeID} idCard={idCard} />
        </Dialog>
      ) : (
        <Dialog
          fullWidth
          maxWidth="sm"
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={open}
        >
          <IdCardPopup activeID={activeID} idCard={idCard} />
        </Dialog>
      )}
      <Dialog
        open={openGenerate}
        onClose={handleCloseGenerate}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
      >
        <MultipleIdCardPopup content={activeID} />
      </Dialog>
    </Paper>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//
export default IdCardList;
