import { Grid, Paper, Checkbox, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import cx from 'clsx';
import { useCheckBoxTable } from '../../Reusable';
import { useTableStyles } from '../../Styles/TableStyles';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import { GetLeaveTypes } from '../../../actions/HumanResource/LeaveType/LeaveTypeAction';
import styled from 'styled-components';

const Title = styled(Typography)`
  &.MuiTypography-root {
    margin-bottom: -4px;
    font-weight: 500;
  }
`;

interface PropsI {
  onLeaveChange: (ids: any[]) => void;
}

interface HeadCellsI {
  id: string;
  label: string;
}

const headCells: HeadCellsI[] = [
  { id: 'leave_type', label: 'Leave Type' },
  { id: 'max_leave', label: 'Max. leave' },
];

const LeaveTypeSelect = (props: PropsI) => {
  const dispatch = useDispatch();
  const classes = useTableStyles();

  const { onLeaveChange } = props;
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useCheckBoxTable(headCells);

  const [selected, setSelected] = useState<string[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);

  const leaveTypeSelector = useSelector((state: RootStore) => state.leave_type);
  const { loading, leave_types: leaveTypes } = leaveTypeSelector;

  useEffect(() => {
    dispatch(GetLeaveTypes());
  }, []);

  useEffect(() => {
    setTableData(leaveTypes);
  }, [leaveTypeSelector]);

  useEffect(() => {
    onLeaveChange(selected);
  }, [selected]);

  const handleCheckClick = (name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = tableData.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const getCustomTableRow = (row: any, index: number) => {
    const isItemSelected = isSelected(row.id);
    const labelId = `enhanced-table-checkbox-${index}`;
    const cellStyle = cx(classes.cell, classes.cellSm);
    return (
      <StyledTableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
        selected={isItemSelected}
      >
        <StyledTableCell className={cellStyle} align="left">
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Checkbox
                color="default"
                checked={isItemSelected}
                inputProps={{ 'aria-labelledby': labelId }}
                onClick={() => handleCheckClick(row.id)}
              />
            </Grid>
            <Grid item>{row.name}</Grid>
          </Grid>
        </StyledTableCell>
        <StyledTableCell className={cellStyle} align="center">
          {row.max_leave}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <Paper className={classes.rootTableBox}>
      <Title variant="subtitle1">Select Leave Type(s)</Title>
      <TableContainer
        hasCheckbox
        handleSelectAllClicked={handleSelectAllClick}
        rowCount={tableData?.length}
        numSelected={selected?.length}
        loading={loading}
        items={tableData}
        headCells={headCells}
        getCustomTableRow={getCustomTableRow}
        hiddenCols={[-1]}
        disablePagination
        disableSearch
        disablePrint
      />
    </Paper>
  );
};

export default LeaveTypeSelect;
