export const GET_TRANSPORT_LOADING = 'GET_TRANSPORT_LOADING';
export const GET_TRANSPORT_ERROR = 'GET_TRANSPORT_ERROR';
export const GET_TRANSPORT_SUCCESS = 'GET_TRANSPORT_SUCCESS';

export const ADD_TRANSPORT_LOADING = 'ADD_TRANSPORT_LOADING';
export const ADD_TRANSPORT_ERROR = 'ADD_TRANSPORT_ERROR';
export const ADD_TRANSPORT_SUCCESS = 'ADD_TRANSPORT_SUCCESS';

export const UPDATE_TRANSPORT_LOADING = 'UPDATE_TRANSPORT_LOADING';
export const UPDATE_TRANSPORT_ERROR = 'UPDATE_TRANSPORT_ERROR';
export const UPDATE_TRANSPORT_SUCCESS = 'UPDATE_TRANSPORT_SUCCESS';

export const DELETE_TRANSPORT_LOADING = 'DELETE_TRANSPORT_LOADING';
export const DELETE_TRANSPORT_ERROR = 'DELETE_TRANSPORT_ERROR';
export const DELETE_TRANSPORT_SUCCESS = 'DELETE_TRANSPORT_SUCCESS';

export const DELETE_TRANSPORT_STUDENT_LOADING =
  'DELETE_TRANSPORT_STUDENT_LOADING';
export const DELETE_TRANSPORT_STUDENT_ERROR = 'DELETE_TRANSPORT_STUDENT_ERROR';
export const DELETE_TRANSPORT_STUDENT_SUCCESS =
  'DELETE_TRANSPORT_STUDENT_SUCCESS';

/*
   "id": "ef4e18e7-5904-4e04-ad49-9a89dae8fe75",
            "vehicle": null,
            "route_name": "12",
            "driver": null,
            "driver_detail": null,
            "driver_assistant": null,
            "driver_assistant_detail": null,
            "student_count": 1
            */

/*\ {
            "id": "a6b4c557-ad60-4e32-b101-c5ab93e4e6b6",
            "vehicle": null,
            "route_name": "112",
            "driver": "ac9d0335-91d5-419e-a851-ad0246325007",
            "driver_detail": {
                "id": "ac9d0335-91d5-419e-a851-ad0246325007",
                "full_name": "Teacher D",
                "designation": {
                    "id": "b30e2097-0944-40a1-9c59-691b555dfb0d",
                    "name": "Teachers"
                },
                "phone": "9866732334",
                "date_of_birth": "1999-04-05"
            },
            "driver_assistant": null,
            "driver_assistant_detail": null,
            "student_count": 0
        },
        */

export interface TransportTypeII {
  id: string;
  vehicle: string | null;
  route_name: string;
  driver: string | null;
  driver_detail: object | null;
  driver_assistant: string | null;
  driver_assistant_detail: string | null;
  student_count: number;
}

export type TransportTypeI = {
  id: string;
  route_from: string;
  route_to: string;
  pickup_time: string;
  drop_time: string;
  fare: number;
  driver: string;
  bus_number: string;
  seat: number;
  count: number;
};

//===============================<START>GET Transport<START>======================//

export interface GetTransportLoading {
  type: typeof GET_TRANSPORT_LOADING;
}

export interface GetTransportError {
  type: typeof GET_TRANSPORT_ERROR;
}

export interface GetTransportSuccess {
  type: typeof GET_TRANSPORT_SUCCESS;
  payload: TransportTypeI[];
}

//===============================<END>GET Transport<END>======================//

//===============================<START>ADD Transport<START>======================//

export interface AddTransportLoading {
  type: typeof ADD_TRANSPORT_LOADING;
}

export interface AddTransportError {
  type: typeof ADD_TRANSPORT_ERROR;
  payload: any;
}

export interface AddTransportSuccess {
  type: typeof ADD_TRANSPORT_SUCCESS;
  payload: any;
}

//===============================<END>ADD Transport<END>======================//

//===============================<START>UPDATE Transport<START>======================//

export interface UpdateTransportLoading {
  type: typeof UPDATE_TRANSPORT_LOADING;
}

export interface UpdateTransportError {
  type: typeof UPDATE_TRANSPORT_ERROR;
  payload: any;
}

export interface UpdateTransportSuccess {
  type: typeof UPDATE_TRANSPORT_SUCCESS;
  payload: any;
}

//===============================<END>UPDATE Transport<END>======================//

//===============================<START>DELETE Transport<START>======================//

export interface DeleteTransportLoading {
  type: typeof DELETE_TRANSPORT_LOADING;
}

export interface DeleteTransportError {
  type: typeof DELETE_TRANSPORT_ERROR;
}

export interface DeleteTransportSuccess {
  type: typeof DELETE_TRANSPORT_SUCCESS;
  payload: { id: string };
}

export interface DeleteTransportStudentLoading {
  type: typeof DELETE_TRANSPORT_STUDENT_LOADING;
}

export interface DeleteTransportStudentError {
  type: typeof DELETE_TRANSPORT_STUDENT_ERROR;
}

export interface DeleteTransportStudentSuccess {
  type: typeof DELETE_TRANSPORT_STUDENT_SUCCESS;
  payload: { id: string };
}
//===============================<END>DELETE Transport<END>======================//

export type TransportDispatchTypes =
  | GetTransportLoading
  | GetTransportError
  | GetTransportSuccess
  | AddTransportLoading
  | AddTransportError
  | AddTransportSuccess
  | UpdateTransportLoading
  | UpdateTransportError
  | UpdateTransportSuccess
  | DeleteTransportLoading
  | DeleteTransportError
  | DeleteTransportSuccess
  | DeleteTransportStudentLoading
  | DeleteTransportStudentError
  | DeleteTransportStudentSuccess;
