import { createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";
import { setSnackbar } from "../../../../actions/SnackbarAction";
import { ADMIN_API_URL } from "../../../../config/SERVER_API_URLS";

export const getOptSubjectGroups = createAsyncThunk(
  "getOptSubjectGroups",
  async (undefined, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.get(`${ADMIN_API_URL}/optional_subject_info`);
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(null);
    }
  }
);

export const createOptSubjectGroup = createAsyncThunk(
  "createOptSubjectGroup",
  async (data: any, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.post(
        `${ADMIN_API_URL}/optional_subject_info/`,
        data
      );
      dispatch(
        setSnackbar(true, "success", "Opt Subject Group Created Successfully")
      );
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      try {
        dispatch(setSnackbar(true, "error", error.response.data[0]));
      } catch (err) {
        dispatch(
          setSnackbar(true, "error", "Failed to create Opt Subject Group")
        );
      }
      return rejectWithValue(null);
    }
  }
);

export const updateOptSubjectGroup = createAsyncThunk(
  "updateOptSubjectGroup",
  async (
    { id, data }: { id: string; data: any },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.put(
        `${ADMIN_API_URL}/optional_subject_info/${id}/`,
        data
      );
      dispatch(
        setSnackbar(true, "success", "Opt Subject Group Updated Successfully")
      );
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      try {
        dispatch(setSnackbar(true, "error", error.response.data[0]));
      } catch (err) {
        dispatch(
          setSnackbar(true, "error", "Failed to update Opt Subject Group")
        );
      }
      return rejectWithValue(null);
    }
  }
);
