import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setSnackbar } from "../../../actions/SnackbarAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

export const getTimePeriods = createAsyncThunk(
  "getTimePeriods",
  async (undefined, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.get(`${ADMIN_API_URL}/periods/`);
      return res.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      dispatch(setSnackbar(true, "error", "Failed to get time periods"));
      return rejectWithValue(null);
    }
  }
);

export const getTimePeriodByGrade = createAsyncThunk(
  "getTimePeriodsByGrade",
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.get(`${ADMIN_API_URL}/periods/?grade_id=${id}`);
      return res.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      dispatch(setSnackbar(true, "error", "Failed to get time periods"));
      return rejectWithValue(null);
    }
  }
);

export const addTimePeriods = createAsyncThunk(
  "addTimePeriods",
  async (data: any, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.post(
        `${ADMIN_API_URL}/periods/bulk_create_periods/`,
        data
      );
      dispatch(setSnackbar(true, "success", "Time Periods added successfully"));
      return res.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      dispatch(setSnackbar(true, "error", err.response.data.error[0]));
      return rejectWithValue(null);
    }
  }
);

export const updateTimePeriods = createAsyncThunk(
  "updateTimePeriods",
  async (data: any, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.post(
        `${ADMIN_API_URL}/periods/bulk_update_periods/`,
        data
      );
      dispatch(setSnackbar(true, "success", "Period Updated successfully"));
      return res.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      dispatch(setSnackbar(true, "error", err.response.data.error[0]));
      return rejectWithValue(null);
    }
  }
);

export const copyTimePeriods = createAsyncThunk(
  "copyTimePeriods",
  async (
    data: { from_grade: string; to_grades: string[]; periods: string[] },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.post(
        `${ADMIN_API_URL}/periods/bulk_copy_periods/`,
        data
      );
      return res.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      dispatch(setSnackbar(true, "error", "Failed to update time periods"));
      return rejectWithValue(null);
    }
  }
);
