import React from "react";
import { NewNavbar } from "../../components";
import { makeStyles } from "@material-ui/core";
import { Grid, Paper, Typography, Button } from "@material-ui/core";
import { useTableStyles } from "../../components/Styles/TableStyles";
import errImg from "../../assets/images/404.webp";
import { Link, useHistory, Redirect } from "react-router-dom";

const useStyles = makeStyles({
  dashboardContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "30px",
    backgroundImage: `url("https://images.unsplash.com/photo-1579548122080-c35fd6820ecb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80")`,
  },
});

const AdminStudentFallback = () => {
  const isAdmin = localStorage.getItem("role") === "Administrator";
  const classes = useStyles();
  const table_classes = useTableStyles();
  const history = useHistory();

  const onDashboard = () => {
    localStorage.setItem("refresh", "false");
    // <Redirect to="/" />;
  };

  return (
    <div className={classes.dashboardContainer}>
      <Paper className={table_classes.rootTableBox}>
        <div style={{ textAlign: "center" }}>
          <img src={errImg} style={{ height: "320px" }} />
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h6">Something Went Wrong!</Typography>
              <p>
                Please try refreshing the page. Even if not working, please
                contact out support team at: 01-4431113.
              </p>
              <a
                onClick={() => {
                  window.location.href = "/";
                }}
              >
                <Button
                  type="submit"
                  color="default"
                  variant="contained"
                  style={{
                    width: "auto",
                    marginTop: "16px",
                    marginRight: "12px",
                  }}
                >
                  Return To Dashboard
                </Button>
              </a>

              <Button
                type="submit"
                color="primary"
                variant="contained"
                onClick={() => window.location.reload()}
                style={{ width: "auto", marginTop: "16px" }}
              >
                Refresh
              </Button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </div>
  );
};

export default AdminStudentFallback;
