// ---------------- <START> module import ------------------//
import React, { useState, useEffect } from "react";
import { Paper, Typography } from "@material-ui/core";
import { useTable } from "../../Reusable";
import { useTableStyles } from "../../Styles/TableStyles";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import {
  GetDesignations,
  DeleteDesignation,
} from "../../../actions/HumanResource/Designation/DesignationAction";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import { DesignationTypeI } from "../../../actions/HumanResource/Designation/DesignationActionTypes";
import { HeadCellsI } from "../../../actions";
import {
  ItemDeleteButton,
  ItemEditButton,
} from "../../Reusable/Buttons/TableButton";
import {
  DeleteEnoteCategory,
  GetEnoteCategorys,
} from "../../../actions/Academics/EnoteCategory/EnoteCategoryAction";
import { EnoteCategoryTypeI } from "../../../actions/Academics/EnoteCategory/EnoteCategoryActionTypes";
// ---------------- <END> module import ------------------//

// ---------------- <START> Interface ------------------//
interface EnoteCategoryTableProps {
  onEditData: (value: EnoteCategoryTypeI) => void;
  edit: { value: boolean };
}

// ---------------- <END> Interface ------------------//

// ---------------- <START> Head Cells ------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N" },
  { id: "name", label: "Category" },
  { id: "action", label: "Action" },
];
// ---------------- <START> Head Cells ------------------//

// ---------------- <START> Enote Category Table Component ------------------//
const EnoteCategoryTable = (props: EnoteCategoryTableProps) => {
  const { onEditData, edit } = props;
  const classes = useTableStyles();
  const dispatch = useDispatch();
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  const [tableData, setTableData] = useState<DesignationTypeI[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);
  const [editMode, setEditMode] = useState(false);

  const assingmentCategoryState = useSelector(
    (state: RootStore) => state.enote_category
  );

  useEffect(() => {
    dispatch(GetEnoteCategorys());
  }, []);

  useEffect(() => {
    edit && setEditMode(edit.value);
  }, [edit]);

  useEffect(() => {
    setLoading(assingmentCategoryState.loading);

    const items: EnoteCategoryTypeI[] =
      assingmentCategoryState.enote_categories.map(
        (item: EnoteCategoryTypeI) => ({
          id: item.id,
          name: item.name,
          description: item.description,
        })
      );

    setTableData(items);
  }, [assingmentCategoryState]);

  const handleEditClicked = (data: EnoteCategoryTypeI) => {
    onEditData(data);
    setEditMode(true);
  };

  // const handleDeleteModal = (value: boolean, id: string) => {
  //   setItemId(id);
  //   setDeleteModalOpen(value);
  // };

  // const handleModalClose = (value: boolean) => {
  //   setDeleteModalOpen(value);
  // };

  // const onDeleteConfirmed = (confirmed: boolean) => {
  //   confirmed && itemId != null && dispatch(DeleteEnoteCategory(itemId));
  //   setDeleteModalOpen(false);
  // };

  const getCustomTableRow = (item: EnoteCategoryTypeI, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ItemEditButton onClick={() => handleEditClicked(item)} />
          {/* <ItemDeleteButton
            disabled={editMode}
            onClick={() => handleDeleteModal(true, item.id)}
          /> */}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <Paper className={classes.root}>
      <Typography variant="h6">Enote Category List</Typography>

      <TableContainer
        label="Enote Category List"
        loading={loading}
        items={tableData}
        headCells={headCells}
        getCustomTableRow={getCustomTableRow}
        search_param="name"
      />

      {/* Delete modal dialog */}
      {/* <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
      /> */}
    </Paper>
  );
};
// ---------------- <START> Enote Category Table Component ------------------//

export default EnoteCategoryTable;
