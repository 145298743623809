import React, { useState } from 'react';
import { Grid, Box, Typography, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import topShapeImg from '../../../assets/images/Authentication/shape_top.svg';
import bottomShapeImg from '../../../assets/images/Authentication/shape_bottom.svg';

const useDotStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      background: 'tomato',
    },
    dot: {
      width: 8,
      height: 8,
      background: 'rgba(255, 255,255, 0.8)',
      borderRadius: '50%',
      display: 'inline-block',
      marginRight: 5,
    },
    active: {
      width: 16,
      background: 'rgba(255, 255, 255, 1)',
      borderRadius: 5,
    },
  };
});

interface BannerDotsPropsI {
  active?: number;
}

const BannerDots = ({ active = 0 }: BannerDotsPropsI) => {
  const classes = useDotStyles();
  const [bannerNum] = useState([1, 2, 3]);

  const getClassName = (index: number) => {
    return clsx(classes.dot, index === active && classes.active);
  };

  return (
    <>
      {bannerNum.map((item, index) => {
        return <Box key={index} className={getClassName(index)} />;
      })}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      background: 'tomato',
    },
    container: {
      position: 'relative',
      color: 'white',
      padding: '1rem',
      width: '100%',
      minHeight: '100vh',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      textAlign: 'center',
      // background: '#132E98',
      background: 'rgb(19,45,150)',
      zIndex: 1,
      backgroundColor:
        'linear-gradient(120deg, rgba(19,45,150,1) 0%, rgba(10,23,76,1) 100%)',

      '&::after, &&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        backgroundImage: `url(${topShapeImg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '30%',
        zIndex: '-1',
      },

      '&::before': {
        transform: 'rotate(180deg)',
        backgroundImage: `url(${bottomShapeImg})`,
      },
    },
    form: {
      marginTop: theme.spacing(3),
    },
    brand: {
      fontSize: 30,
      padding: theme.spacing(4),
      '& span': {
        fontWeight: 600,
      },
    },
    title: {
      fontSize: 34,
      fontWeight: 600,
      marginBottom: theme.spacing(3),
    },
    subtitle: {
      fontSize: 14,
    },
    footer: {
      fontSize: 12,
      padding: theme.spacing(4),
    },
  };
});

interface AuthBannerPropsI {
  id: number;
  title: string;
  subtitle: string;
  titleWide?: boolean;
}

const AuthBanner = (props: AuthBannerPropsI) => {
  const classes = useStyles();
  const { id, title, subtitle, titleWide } = props;

  const contentStyle = { maxWidth: titleWide ? 580 : 460 };

  return (
    <Grid container className={classes.container}>
      <Grid item>
        <Typography variant="h5" className={classes.brand}>
          Hamro<span>Academy</span>
        </Typography>
      </Grid>

      <Grid item style={contentStyle}>
        <Typography variant="h4" gutterBottom className={classes.title}>
          {title}
        </Typography>

        <Typography variant="body1" className={classes.subtitle}>
          {subtitle}
        </Typography>

        <Box marginTop="36px">
          <BannerDots active={id} />
        </Box>
      </Grid>

      <Grid item>
        <Typography variant="body1" className={classes.footer}>
          2021 Hamro Academy. All Rights Reserved.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AuthBanner;
