// ---------------- <START> module import ------------------//
import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  createStyles,
  Paper,
  TableBody,
  TableHead,
  Theme,
  Typography,
} from "@material-ui/core";
import { useTable } from "../../Reusable";
import { useTableStyles } from "../../Styles/TableStyles";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import {
  GetDepartments,
  DeleteDepartment,
} from "../../../actions/HumanResource/Department/DepartmentAction";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import { DepartmentTypeI } from "../../../actions/HumanResource/Department/DepartmentActionTypes";
import { HeadCellsI } from "../../../actions";
import CloseIcon from "@material-ui/icons/Close";
import {
  ItemDeleteButton,
  ItemEditButton,
  ItemPrintButton,
  ItemViewButton,
} from "../../Reusable/Buttons/TableButton";
import useLoading from "../../../useHook/useLoading";
import {
  GetDaybook,
  GetInvoiceDetail,
} from "../../../actions/Fees/Daybook/DaybookAction";
import getFullName from "../../utils/getFullName";
import getFullClassName from "../../utils/getFullClassName";
import { GetGeneralInfoData } from "../../../actions/GeneralSettings/GeneralInfo/GeneralInfoAction";
import { GetFeestatement } from "../../../actions/Fees/FeeStatement/FeestatementAction";
import { GetFeeIncome } from "../../../actions/Fees/FeeIncome/FeeIncomeAction";
import { Redirect } from "react-router";
import ScholarshipReportModal from "./ScholarshipReportModal";
// ---------------- <END> module import ------------------//

// ---------------- <START> Head Cells ------------------//
const headCells: HeadCellsI[] = [
  // { id: "sn", label: "S.N." },
  { id: "fee_header", label: "Scholarship Head" },
  { id: "no_of_students", label: "No. of Students Applied" },
  // { id: "remaining_no_of_students", label: "Remaining No. of Students to Applly" },
  { id: "total_amount_applied", label: "Total Amount Applied" },
  { id: "action", label: "Action" },
];
// ---------------- <START> Head Cells ------------------//

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalHeader: {
      padding: theme.spacing(1, 3),
      color: "#fff",
      backgroundColor: "#5767a4",
      "& .MuiTypography-body1": {},
    },
    modalContainer: {
      padding: theme.spacing(3, 1),
    },
    modalFooter: {
      padding: theme.spacing(0, 0, 4, 0),
      "& .MuiButton-contained": {
        color: "#fff",
        backgroundColor: "#e96c5a",
      },
    },
  })
);

// ---------------- <START> Department Table Component ------------------//
const ScholarshipReportTable = (props: any) => {
  const { fee } = props;

  const classes = useTableStyles();
  const modalClasses = useStyles();

  const dispatch = useDispatch();

  const { StyledTableCell, StyledTableRow, TblContainer, TblHead } =
    useTable(headCells);

  const [tableData, setTableData] = useState<any>();
  const [openDetail, setOpenDetail] = useState(false);

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);
  const departmentState = useSelector((state: RootStore) => state.department);
  const feeIncomeState = useSelector((state: RootStore) => state.fee_income);
  const [openInvoice, setOpenInvoice] = useState(false);

  const [studentID, setStudentID] = useState<string | null>(null);
  const [scholarshipID, setScholarshipID] = useState<string | null>(null);
  const [viewClicked, setViewClicked] = useState(false);

  const generalInfoData = useSelector(
    (state: RootStore) => state.general_info?.general_info
  );
  const authData = useSelector((state: RootStore) => state.auth);

  const scholarshipState = useSelector(
    (state: RootStore) => state.scholarship.scholarship_reports
  );

  useEffect(() => {
    dispatch(GetFeeIncome(1, ""));
  }, []);

  useEffect(() => {
    if (itemId !== null) {
      dispatch(GetInvoiceDetail(itemId));
    }
  }, [itemId]);

  // useEffect(() => {
  //   if (authData.general_info != null) {
  //     dispatch(GetGeneralInfoData(authData.general_info.id));
  //   }
  // }, [authData]);

  useEffect(() => {
    if (scholarshipState !== []) {
      setTableData(scholarshipState);
    }
  }, [scholarshipState]);

  const handleCloseInvoice = () => {
    setOpenInvoice(false);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteDepartment(itemId));
    setDeleteModalOpen(false);
  };

  const handleView = (invoice: string, student: string) => {
    setStudentID(student);
    setScholarshipID(invoice);
    setViewClicked(true);
  };

  const getCustomTableRow = (item: any, index: number) => {
    console.log({ item });
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {item.scholarship_detail?.scholarship_title}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.student_count || 0}
        </StyledTableCell>
        {/* <StyledTableCell align="center" className={classes.cell}>
                    {Number(item.scholarship_detail?.applicable_scholarship) - Number(item.student_count)}
                </StyledTableCell> */}
        <StyledTableCell align="center" className={classes.cell}>
          Rs. {item.total_fee_amount || 0}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {/* <ItemViewButton onClick={() => handleView(item.id, item.student?.id)} /> */}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  const handlePaginationChange = (paginationIndex: number) => {
    dispatch(GetFeeIncome(paginationIndex, fee || ""));
  };

  if (viewClicked) {
    return <Redirect to={`/scholarship-report/${scholarshipID}/`} />;
  }

  const handleCloseDetail = () => {
    setOpenDetail(false);
  };

  const handleViewModal = (id: string) => {
    setOpenDetail(true);
  };

  // console.log('keys', Object.keys(tableData?.grade_report))

  return (
    <div style={{ padding: "15px" }}>
      <Typography variant="h6">Report</Typography>
      {/* <TableContainer
            label="Invoice"
            loading={departmentState.loading}
            items={tableData !== null ? Object.keys(tableData?.grade_report)[0] : []}
            headCells={headCells}
            getCustomTableRow={getCustomTableRow}
            forPagination={feeIncomeState?.fee_income}
            // search_name="name"
            disableSearch
            handlePaginationChange={handlePaginationChange}
        /> */}
      <TblContainer>
        <TblHead />
        <TableBody>
          {fee !== null && (
            <StyledTableRow>
              <StyledTableCell align="center">
                {tableData?.scholarship_detail?.scholarship_title}
              </StyledTableCell>
              <StyledTableCell align="center">
                {tableData?.student_count}
              </StyledTableCell>
              {/* <StyledTableCell align="center">
                {Number(tableData?.scholarship_detail?.applicable_scholarship) -
                  Number(tableData?.student_count)}
              </StyledTableCell> */}
              <StyledTableCell align="center">
                Rs. {tableData?.total_fee_amount || 0}
              </StyledTableCell>
              <StyledTableCell align="center">
                <ItemViewButton onClick={() => handleViewModal(tableData)} />
              </StyledTableCell>
            </StyledTableRow>
          )}
        </TableBody>
      </TblContainer>
      <Dialog
        open={openDetail}
        onClose={handleCloseDetail}
        aria-labelledby="form-dialog-title"
        maxWidth="xl"
        fullWidth
      >
        <div style={{ minWidth: "350px" }}>
          <Grid
            container
            className={modalClasses.modalHeader}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">Scholarship Report</Typography>
            <IconButton onClick={handleCloseDetail}>
              <CloseIcon style={{ color: "#fff" }} />
            </IconButton>
          </Grid>
          <ScholarshipReportModal fee={fee} tableData={tableData} />
        </div>
      </Dialog>
    </div>
  );
};
// ---------------- <START> Department Table Component ------------------//

export default ScholarshipReportTable;
