import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_STUDENT_PROFILE_LOADING,
  GET_STUDENT_PROFILE_ERROR,
  GET_STUDENT_PROFILE_SUCCESS,
  UPDATE_STUDENT_PROFILE_LOADING,
  UPDATE_STUDENT_PROFILE_ERROR,
  UPDATE_STUDENT_PROFILE_SUCCESS,
  StudentProfileDispatchTypes,
} from "./StudentProfileActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { STUDENT_API_URL } from "../../../config/SERVER_API_URLS";

export const GetStudentProfile =
  () =>
    async (dispatch: Dispatch<StudentProfileDispatchTypes | SetSnackBarI>) => {
      try {
        dispatch({
          type: GET_STUDENT_PROFILE_LOADING,
        });

        const res = await axios.get(
          `${STUDENT_API_URL}/profile/`,
          HeaderConfig()
        );

        dispatch({
          type: GET_STUDENT_PROFILE_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: GET_STUDENT_PROFILE_ERROR,
        });
      }
    };

export const UpdateStudentProfile =
  (data: any) =>
    async (dispatch: Dispatch<StudentProfileDispatchTypes | SetSnackBarI>) => {
      try {
        dispatch({
          type: UPDATE_STUDENT_PROFILE_LOADING,
        });

        const res = await axios.put(
          `${STUDENT_API_URL}/profile/`,
          data,
          HeaderConfig()
        );

        dispatch({
          type: UPDATE_STUDENT_PROFILE_SUCCESS,
          payload: res.data,
        });

        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Profile Updated Successfully",
          },
        });
      } catch (error) {
        dispatch({
          type: UPDATE_STUDENT_PROFILE_ERROR,
        });
      }
    };
