import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootStore } from "../../../store";
import BackDropLoader from "../../Reusable/BackDropLoader";
import AddGradesTable from "./AddGradesTable";
import AddMarksAllTable from "./AddMarksAllTable";
import AddMarksForm from "./AddMarksForm";
import AddMarksTable from "./AddMarksTable";

const AddMarks = () => {
  const [marksType, setMarksType] = useState<string>("singleSubject");

  const { loading, markingType } = useSelector(
    (state: RootStore) => state.addMarks
  );

  const returnMarkingTable = () => {
    if (marksType === "singleSubject") {
      if (markingType === 0) {
        return <AddGradesTable />;
      }

      if (markingType === 1) {
        return <AddMarksTable />;
      }

      return <></>;
    } else {
      return <AddMarksAllTable />;
    }
  };
  return (
    <>
      <AddMarksForm addMarksMethod={setMarksType} />
      {returnMarkingTable()}
      <BackDropLoader open={loading} />
    </>
  );
};

export default AddMarks;
