//---------------------<start> module imports starts ----------------------//
import {
    Avatar,
    CardHeader,
    CircularProgress,
    Divider,
    Grid,
    InputLabel,
    Paper,
    TextField,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useFormStyles } from "../../Styles/FormStyles";
import { Autocomplete } from "@material-ui/lab";
import { Search } from "@material-ui/icons";
import { RootStore } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { GetClasses } from "../../../actions/Academics/Class/ClassAction";
import { GetSections } from "../../../actions/Academics/Section/SectionAction";
import { GetStudentByName, SearchStudent, StudentSearch } from "../../../actions/Student/Student/StudentAction";
import { useForm } from "react-hook-form";
import CustomizedTextField from "../../Reusable/Inputs/TextField";
import defaultBase64 from "../../Student/ParentForm/defaultBase64";
import { GetInvoiceHistory } from "../../../actions/Fees/CollectFees/CollectFeesAction";
import getFullClassName from "../../utils/getFullClassName";

// -----------------<end> module imports ends -----------------------//
// -----------------<start> Interfaces <start>-----------------------//

// -----------------<end> Interfaces <end>-----------------------//

// -----------------<starts> Selected Student Form starts-----------------------//
const CollectFeeSearch = (props: any) => {
    const classes = useFormStyles();
    const { student, setStudent } = props;
    const [gradeChoices, setGradeChoices] = useState<any | null>([]);
    const [sectionChoices, setSectionChoices] = useState<any[] | []>([]);
    const [grade, setGrade] = useState<any | null>(null);
    const [section, setSection] = useState<any | null>(null);
    const [sectionDisabler, setSectionDisabler] = useState<boolean>(true);
    const [studentSelectOpen, setStudentSelectOpen] = useState<boolean>(false);
    const [studentChoices, setStudentChoices] = useState<any>([]);
    const gradeList = useSelector((state: RootStore) => state.class.classes);

    const studentList = useSelector(
        (state: RootStore) => state.student
    );

    const studentLoading = studentSelectOpen && studentList.loading === true;

    const feesHead = useSelector(
        (state: RootStore) => state.collect_fees?.collect_fees
    );

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetSections());
        dispatch(GetClasses());
    }, []);

    useEffect(() => {
        gradeList && setGradeChoices(gradeList);
    }, [gradeList]);


    const handleGradeChange = (value: any | null) => {
        if (value) {
            setGrade(value);
            populateSectionChoices(value.section)
            dispatch(StudentSearch(value.grade, ""));
        } else {
            setSectionDisabler(true);
            setGrade(null);
            dispatch(StudentSearch("", ""));

        }
    };

    const populateSectionChoices = (sections: any | []) => {
        setSectionDisabler(false);
        sections.length > 0
            ? setSectionChoices(sections)
            : setSectionDisabler(true);
    };

    useEffect(() => {
        if (section != null && grade != null) {
            dispatch(StudentSearch(grade.grade, section.id));
        }
    }, [section, grade]);

    useEffect(() => {
        if (studentList.searched_student != null) {
            setStudentChoices(studentList.searched_student?.results);
        }
    }, [studentList.searched_student]);

    const handleSectionChange = (value: any) => {
        setSection(value);
    };

    const handleStudentChange = (val: any) => {
        setStudent(val);
    };

    const onStudentInputChange = (event: any, value: any) => {
        dispatch(GetStudentByName(value));
    };

    useEffect(() => {
        if (student !== null) {
            dispatch(GetInvoiceHistory(student?.academic_id))
        }
    }, [student])
    console.log({ studentChoices })

    return (
        <>
            <div style={{ padding: '15px' }}>
                {/* <div style={{ display: "flex", justifyContent: "space-between" }}> */}
                <div>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <div>
                                <form className={classes.form} >
                                    {props.title && <Grid container>
                                        <Grid item xs={12} className={classes.formTitle}>
                                            <span>{props.title}</span>
                                        </Grid>
                                    </Grid>}
                                    <Grid container spacing={2}>
                                        <Grid item xs={3}>
                                            <InputLabel>Class</InputLabel>
                                            <Autocomplete
                                                onChange={(event: React.ChangeEvent<{}>, value: any | null) =>
                                                    handleGradeChange(value)
                                                }
                                                options={gradeChoices}
                                                getOptionLabel={(option) => "Class" + " " + option?.grade_name}
                                                value={grade}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Select a Grade"
                                                        name="class"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </Grid>

                                        <Grid item xs={3} className={classes.formWrapper}>
                                            <InputLabel>Section</InputLabel>
                                            <Autocomplete
                                                onChange={(event: React.ChangeEvent<{}>, value: any | null) =>
                                                    handleSectionChange(value)
                                                }
                                                options={sectionChoices}
                                                value={section && section}
                                                getOptionLabel={(option) => option.name}
                                                disabled={sectionDisabler}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Select a Section"
                                                        name="section"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className={classes.formWrapper}>
                                            <InputLabel>
                                                Student
                                                {/* <span style={{ color: "red" }}>*</span> */}
                                            </InputLabel>
                                            <Autocomplete
                                                onChange={(
                                                    event: React.ChangeEvent<{}>,
                                                    value: any | null
                                                ) => handleStudentChange(value)}
                                                onInputChange={onStudentInputChange}
                                                onOpen={() => {
                                                    setStudentSelectOpen(true);
                                                }}
                                                onClose={() => {
                                                    setStudentSelectOpen(false);
                                                }}
                                                loading={studentLoading}
                                                value={student && student}
                                                options={studentChoices || []}
                                                getOptionLabel={(option: any) =>
                                                    `${option?.student_first_name} ${option?.student_last_name + " - "} ${getFullClassName(option?.grade, option?.section)}`
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Label"
                                                        name="student"
                                                        variant="outlined"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <>
                                                                    {studentLoading ? (
                                                                        <CircularProgress color="inherit" size={20} />
                                                                    ) : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </form>
                            </div>
                        </Grid>
                        <Grid item xs={4}>

                            <div style={{ paddingLeft: '70px' }}>
                                {student?.student_first_name && student?.student_last_name &&
                                    <CardHeader
                                        avatar={
                                            <Avatar aria-label="recipe">
                                                <img src={student?.student_photo || defaultBase64} />
                                            </Avatar>
                                        }
                                        title={<span style={{ color: '#1f4498' }}>{student?.student_first_name + " " + student?.student_last_name}</span>}
                                        subheader={<><span style={{ color: "#7c7c7c" }}>Admn No: </span><span>{student?.admission_number}</span></>}
                                    />}
                            </div>
                        </Grid>
                    </Grid>
                </div>

            </div>
        </>
    );
};
// -----------------<end> Selected Student form ends-----------------------//
export default CollectFeeSearch;
