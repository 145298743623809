// ---------------------------- <START> module imports start ----------------------------------//
import React, { useEffect, useState, useRef } from "react";
import {
  TableBody,
  Grid,
  TableHead,
  makeStyles,
  Theme,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { PrintTableStyles } from "../../Styles/PrintTableStyles";
import { useTablePrint } from "../../Reusable";
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "../../../store";
import { MarkSheetTypeI } from "../../../actions/Examination/MarkSheet/MarkSheetActionTypes";
import { Printd } from "printd";
import "./intimateInvoice.css";
import ReactHtmlParser from "react-html-parser";
import { ToWords } from "to-words";
import {
  TopTagline,
  SchoolInfoContainer,
  SchoolTitle,
  Paragraph,
  ReportType,
  StudentProfileContainer,
  StudentDetailTextWrapper,
  StudentDetailText,
  Spacing,
  Sticky,
  DoubleBorder,
  // PrintNow,
} from "../../Reports/Marksheet/ReportStyles";
import printJS from "print-js";
import ReactToPrint from "react-to-print";
import { dateConverterAdToBs } from "../../utils/dateConverter";
import getFullName from "../../utils/getFullName";
import getFullClassName from "../../utils/getFullClassName";
import { GetReportSetting } from "../../../actions/GeneralSettings/ReportSetting/ReportSettingAction";
import checkImageUrl from "../../utils/checkImageUrl";
import { GetGeneralInfoData } from "../../../actions/GeneralSettings/GeneralInfo/GeneralInfoAction";
import { GetInvoiceDetail } from "../../../actions/Fees/Daybook/DaybookAction";
import getMonthName from "../../utils/getMonthName";
import intoWords from "../../utils/intoWords";
import getGradeFeeAmount from "../../utils/getGradeFeeAmount";
import getClassName from "../../utils/getClassName";
import { CUR_NEPALI_DATE } from "../../utils/nepaliDateUtils";
// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//

interface HeadCellsI {
  id: string;
  label: string;
}
// ---------------------------- <END> interface ends ----------------------------------//

interface HeadCellsI {
  id: string;
  label: string;
}

// -------------------- <START> Table Headings Data ---------------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S. No." },
  { id: "subject", label: "Subjects" },
  { id: "fullMarks", label: "Full Marks" },
  { id: "passMarks", label: "Pass Marks" },
  { id: "th", label: "TH" },
  { id: "pr", label: "PR" },
  { id: "total", label: "Total" },
  { id: "grade_point", label: "Grade Point" },
];
// ---------------------<END> Table Headings Data Ends --------------------------//

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1, 2),
    "& .MuiCardContent-root": {
      padding: theme.spacing(0),
    },
    "& .MuiTypography-h2": {
      fontSize: "3.1rem",
    },
    caption: {
      padding: "0px",
    },
  },
  idImage: {
    width: "80px",
    height: "80px",
  },
  line: {
    borderRight: "1px solid black",
  },
  bottom: {
    marginBottom: theme.spacing(1),
  },
  tableCell: {
    padding: "0px 2px !important",
    border: "1px solid black",
  },
  topWatermark: {
    backgroundImage: `url('https://i.imgur.com/KO5poDX.png')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "70%",
    backgroundPosition: "center",
  },
  centerWatermark: {
    backgroundImage: `url('https://i.imgur.com/KO5poDX.png')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "70%",
    backgroundPosition: "center",
  },
}));

interface propsI {
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  student: string;
  gpa: number;
}

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const IntimateInvoice = (props: any) => {
  const { invoiceData, month, grade, section } = props;
  const classes = PrintTableStyles();

  const localClasses = useStyles();

  // States declaration
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<MarkSheetTypeI[]>([]);
  const [subTotal, setSubTotal] = useState<number>(0);

  const componentRef = useRef<any>();
  const dispatch = useDispatch();

  const schoolState = useSelector(
    (state: RootStore) => state.auth.general_info
  );
  const examState = useSelector((state: RootStore) => state.exam.exam);
  const studentState = useSelector(
    (state: RootStore) => state.student.student_by_id
  );
  const reportSettingState = useSelector(
    (state: RootStore) => state.report_setting.reportsetting
  );

  const generalInfoData = useSelector(
    (state: RootStore) => state.general_info?.general_info
  );

  const authData = useSelector((state: RootStore) => state.auth);

  const marksheetSelector = useSelector((state: RootStore) => state.marksheet);

  useEffect(() => {
    setLoading(marksheetSelector.loading);
    setTableData(marksheetSelector.marksheet);
  }, [marksheetSelector]);

  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
    },
  });

  const getIntimateTotalAmount = (data: any) => {
    let invoice_ = 0;
    let due_ = 0;

    data?.student_invoices?.map((elem: any) => {
      return (invoice_ += Number(elem?.fee_type?.grade_fee_amount?.amount));
    });
    data?.dues?.map((elem: any) => {
      return (due_ += Number(elem?.due_amount));
    });

    const deb = Number(data?.current_academic_opening_balance?.debit) || 0;
    const cre = Number(data?.current_academic_opening_balance?.credit) || 0;

    if (deb > 0 && cre === 0) {
      return invoice_ + due_ - deb;
    }

    if (cre > 0 && deb === 0) {
      return invoice_ + due_ + cre;
    }

    if (cre === 0 && deb === 0) {
      return invoice_ + due_;
    }
  };

  return (
    <>
      <Sticky>
        <>
          <ReactToPrint
            trigger={() => (
              <p
                style={{
                  backgroundColor: "#132E98",
                  color: "white",
                  fontWeight: "bold",
                  borderRadius: "0px",
                  margin: "0px",
                  textAlign: "center",
                  padding: "5px 0px",
                  cursor: "pointer",
                }}
              >
                Print This Out !
              </p>
            )}
            content={() => componentRef.current}
            removeAfterPrint={true}
            copyStyles={true}
          />
          <div
            id="print-element"
            ref={componentRef}
            style={{
              padding: "15px 10px",
              display: "flex",
              flexWrap: "wrap",
              gap: "15px",
              //   height: "297mm",
              //   width: "210mm",
              flexDirection: "row",
              justifyItems: "center",
              justifyContent: "center",
            }}
          >
            {invoiceData?.map((elem: any, index: number) => {
              return (
                <div
                  className="content"
                  style={{
                    width: "140mm",
                    // height: "100mm",
                  }}
                  key={index}
                >
                  <section className="header">
                    <div>
                      <p className="receipt-header">INVOICE</p>
                      <p className="receipt-date">Month: {month?.value}</p>
                      <p className="receipt-date">Date: {CUR_NEPALI_DATE}</p>
                    </div>
                    <div className="school-detail">
                      <div>
                        <img
                          height="50"
                          width="50"
                          src={generalInfoData?.logo}
                        />
                      </div>
                      <div>
                        <p className="school-title">
                          {generalInfoData?.school_name}
                        </p>
                        <p className="school-address">
                          {generalInfoData?.address}
                        </p>
                        <p className="school-address">
                          {generalInfoData?.phone_number}
                        </p>
                      </div>
                    </div>
                  </section>
                  <hr />
                  <section className="student-info">
                    <p>
                      Student Name:
                      <span className="student-data">
                        {getFullName(
                          elem.student?.student_user?.first_name,
                          elem.student?.student_user?.last_name
                        )}
                      </span>
                    </p>
                    <p>
                      Grade:{" "}
                      <span className="student-data">
                        {getFullClassName(grade?.grade_name, section?.name)}
                      </span>
                    </p>
                    <table className="intimate">
                      <tr>
                        <th className="intimate">Fee Head</th>
                        <th className="intimate">Amount</th>
                      </tr>
                      {elem?.current_academic_opening_balance !== null ? (
                        <tr>
                          <td className="intimate">
                            <u>Opening Balance</u>
                          </td>
                          <td className="intimate">
                            {elem?.current_academic_opening_balance?.credit !==
                            "0.00"
                              ? elem?.current_academic_opening_balance?.credit +
                                " Cr."
                              : elem?.current_academic_opening_balance
                                  ?.debit !== "0.00"
                              ? elem?.current_academic_opening_balance?.debit +
                                " Dr."
                              : "-"}
                          </td>
                        </tr>
                      ) : null}
                      {elem.student_invoices?.map((el: any, index: number) => {
                        return (
                          <tr key={index}>
                            <td className="intimate">{el.fee_type?.name}</td>
                            <td className="intimate">
                              Rs. {el.fee_type?.grade_fee_amount?.amount}
                            </td>
                          </tr>
                        );
                      })}
                      {elem.dues?.map((el: any, index: number) => {
                        return (
                          <tr key={index}>
                            <td className="intimate">{el.invoice_id} (due)</td>
                            <td className="intimate">Rs. {el.due_amount}</td>
                          </tr>
                        );
                      })}
                    </table>
                    <p>
                      The sum of Rupees:{" "}
                      <span className="student-data">
                        {elem && toWords?.convert(getIntimateTotalAmount(elem))}{" "}
                        /-
                      </span>
                    </p>
                  </section>
                  <hr />
                  <span>
                    {elem?.current_academic_opening_balance !== null &&
                      (elem?.current_academic_opening_balance?.credit !== "0.00"
                        ? "Opening Balance: " +
                          elem?.current_academic_opening_balance?.credit +
                          " Cr."
                        : elem?.current_academic_opening_balance?.debit !==
                          "0.00"
                        ? "Opening Balance: " +
                          elem?.current_academic_opening_balance?.debit +
                          " Dr."
                        : null)}
                  </span>
                  <section className="footer">
                    <div className="footer-amount">
                      <b>Total Amount: Rs. {getIntimateTotalAmount(elem)}</b>
                      <br />
                    </div>
                    <div className="footer-authorized">
                      <b>
                        <u>Authorized By</u>
                      </b>
                    </div>
                  </section>
                </div>
              );
            })}
          </div>
        </>
      </Sticky>
    </>
  );
};

// ---------------------------- <END> Student Table Component Ends -------------------------------//
export default IntimateInvoice;
