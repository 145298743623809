import { Any } from "@react-spring/types";
import {
  GET_STUDENT_ROUTE_LOADING,
  GET_STUDENT_ROUTE_ERROR,
  GET_STUDENT_ROUTE_SUCCESS,
  StudentRouteDispatchTypes,
  ADD_STUDENT_ROUTE_LOADING,
  ADD_STUDENT_ROUTE_ERROR,
  ADD_STUDENT_ROUTE_SUCCESS,
  DELETE_STUDENT_TO_ROUTE_LOADING,
  DELETE_STUDENT_TO_ROUTE_ERROR,
  DELETE_STUDENT_TO_ROUTE_SUCCESS,
  STUDENT_LIST_LOADING,
  STUDENT_LIST_SUCCESS,
  STUDENT_LIST_ERROR,
  StudentRouteTypeI,
  DELETE_STUDENT_ROUTE_SUCCESS,
} from "../../../actions/Transport/Routes/RoutesModal/StudentRouteActionTypes";

interface InitialStateI {
  loading: boolean;
  student_route: any;
  student_list: any;
}

const initialState: InitialStateI = {
  loading: false,
  student_route: [],
  student_list: null,
};

const StudentRouteReducer = (
  state: InitialStateI = initialState,
  action: StudentRouteDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_STUDENT_ROUTE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_STUDENT_ROUTE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_STUDENT_ROUTE_SUCCESS:
      return {
        ...state,
        loading: false,
        student_route: action.payload,
      };
    case STUDENT_LIST_LOADING:
      return {
        ...state,
        loading: true,
      };

    case STUDENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        student_list: action.payload,
      };

    case STUDENT_LIST_ERROR:
      return {
        ...state,
        loading: false,
      };

    case ADD_STUDENT_ROUTE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case ADD_STUDENT_ROUTE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case ADD_STUDENT_ROUTE_SUCCESS:
      const new_data: any[] = state.student_list?.results;
      const student: any[] = [];
      new_data.map((elem) => {
        if (elem.id !== action.payload.s_id) {
          student.push(elem);
        }
      });
      return {
        ...state,
        loading: false,
        // student_route: [
        //   ...state.student_route?.results,
        //   action.payload.student_list,
        // ],
        // student_list: student,
      };

    case DELETE_STUDENT_TO_ROUTE_SUCCESS:
      const n_data: StudentRouteTypeI[] = state.student_route;
      const i_index = n_data.findIndex(
        (element) => element.student_id === action.payload.id
      );
      n_data.splice(i_index, 1);

      return {
        ...state,
        loading: false,
        student_route: n_data,
      };

    default:
      return state;
  }
};

export default StudentRouteReducer;
