// ---------------------------- <START> module imports start ----------------------------------//
import React, { useEffect, useState, useRef } from 'react';
import {
  TableBody,
  Grid,
  TableHead,
  makeStyles,
  Theme,
  FormControlLabel,
  Checkbox,
  Divider,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { PrintTableStyles } from '../../Styles/PrintTableStyles';
import { useTablePrint } from '../../Reusable';
import { useSelector, useDispatch } from 'react-redux';
import { RootStore } from '../../../store';
import { MarkSheetTypeI } from '../../../actions/Examination/MarkSheet/MarkSheetActionTypes';
import { Printd } from 'printd';
import './invoice.css';
import { ToWords } from 'to-words';
import {
  TopTagline,
  SchoolInfoContainer,
  SchoolTitle,
  Paragraph,
  ReportType,
  StudentProfileContainer,
  StudentDetailTextWrapper,
  StudentDetailText,
  Spacing,
  Sticky,
  DoubleBorder,
  // PrintNow,
} from '../../Reports/Marksheet/ReportStyles';
import printJS from 'print-js';
import ReactToPrint from 'react-to-print';
import { dateConverterAdToBs } from '../../utils/dateConverter';
import getFullName from '../../utils/getFullName';
import getFullClassName from '../../utils/getFullClassName';
import { GetReportSetting } from '../../../actions/GeneralSettings/ReportSetting/ReportSettingAction';
import checkImageUrl from '../../utils/checkImageUrl';
import { GetGeneralInfoData } from '../../../actions/GeneralSettings/GeneralInfo/GeneralInfoAction';
import { GetInvoiceDetail } from '../../../actions/Fees/Daybook/DaybookAction';
import getMonthName from '../../utils/getMonthName';
import intoWords from '../../utils/intoWords';

const FeeHeadModal = (props: any) => {
  const { feeHeadOptions, handleSelectChange, handleFeeHeadChange } = props;

  const classes = PrintTableStyles();

  // States declaration
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<MarkSheetTypeI[]>([]);
  const [feeOne, setFeeOne] = useState<any[]>([]);
  const [feeTwo, setFeeTwo] = useState<any[]>([]);
  const [subTotal, setSubTotal] = useState<number>(0);

  const componentRef = useRef<any>();
  const dispatch = useDispatch();

  const schoolState = useSelector(
    (state: RootStore) => state.auth.general_info
  );
  const examState = useSelector((state: RootStore) => state.exam.exam);
  const studentState = useSelector(
    (state: RootStore) => state.student.student_by_id
  );
  const reportSettingState = useSelector(
    (state: RootStore) => state.report_setting.reportsetting
  );

  const generalInfoData = useSelector(
    (state: RootStore) => state.general_info?.general_info
  );
  const authData = useSelector((state: RootStore) => state.auth);

  const invoiceData = useSelector(
    (state: RootStore) => state.day_book.invoice_detail
  );

  useEffect(() => {
    if (feeHeadOptions?.length > 0) {
      const data1: any[] = [];
      const data2: any[] = [];

      feeHeadOptions?.map((elem: any, index: number) => {
        if (index < 10) {
          data1.push(elem);
        } else {
          data2.push(elem);
        }
      });

      setFeeOne(data1);
      setFeeTwo(data2);
    }
  }, [feeHeadOptions]);

  console.log(feeOne, feeTwo);

  // ============================ <END> EVENT HANDLERS <END> ============================== //
  return (
    <>
      <div
        style={{
          padding: '15px 10px',
          margin: '15px',
          border: '2px solid #bbbded',
        }}
      >
        <Grid container justifyContent="space-between" direction="row">
          <Grid item xs={8}>
            <b style={{ textTransform: 'uppercase' }}>Generated Fee Heads</b>
            <Divider />

            <Grid
              container
              spacing={1}
              justifyContent="space-between"
              direction="row"
            >
              <Grid
                item
                xs={6}
                style={{ marginTop: '20px', paddingLeft: '10px' }}
              >
                {feeOne?.map((elem: any, index: number) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={elem?.selected}
                          onChange={(
                            event: React.ChangeEvent<{}>,
                            value: any
                          ) => {
                            handleSelectChange(elem);
                            handleFeeHeadChange(elem);
                          }}
                          name="1"
                          color="primary"
                        />
                      }
                      label={elem?.fee_name}
                    />
                  );
                })}
              </Grid>
              <Grid item xs={6} style={{ marginTop: '20px' }}>
                {feeTwo?.map((elem: any, index: number) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={false}
                          // onChange={handleChange}
                          name="1"
                          color="primary"
                        />
                      }
                      label={elem.fee_name}
                    />
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <b style={{ textTransform: 'uppercase' }}>
              Transportation Fee Heads
            </b>
            <Divider />

            <Grid item style={{ marginTop: '20px' }}>
              <i>No Transporation Fees has applied yet</i>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

// ---------------------------- <END> Student Table Component Ends -------------------------------//
export default FeeHeadModal;
