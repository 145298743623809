import {
  GET_MARKSHEET_TEMPLATE_LOADING,
  GET_MARKSHEET_TEMPLATE_ERROR,
  GET_MARKSHEET_TEMPLATE_SUCCESS,
  ADD_MARKSHEET_TEMPLATE_LOADING,
  ADD_MARKSHEET_TEMPLATE_ERROR,
  ADD_MARKSHEET_TEMPLATE_SUCCESS,
  ACTIVATE_OR_DEACTIVATE_MARKSHEET_TEMPLATE_LOADING,
  ACTIVATE_OR_DEACTIVATE_MARKSHEET_TEMPLATE_ERROR,
  ACTIVATE_OR_DEACTIVATE_MARKSHEET_TEMPLATE_SUCCESS,
  DELETE_MARKSHEET_TEMPLATE_LOADING,
  DELETE_MARKSHEET_TEMPLATE_ERROR,
  DELETE_MARKSHEET_TEMPLATE_SUCCESS,
  GET_SINGLE_MARKSHEET_TEMPLATE_LOADING,
  GET_SINGLE_MARKSHEET_TEMPLATE_ERROR,
  GET_SINGLE_MARKSHEET_TEMPLATE_SUCCESS,
  UPDATE_MARKSHEET_TEMPLATE_LOADING,
  UPDATE_MARKSHEET_TEMPLATE_ERROR,
  UPDATE_MARKSHEET_TEMPLATE_SUCCESS,
  MarksheetTemplateDispatchTypes,
} from "../../actions/Templates/Marksheet/MarksheetTemplateActionTypes";

interface InitialStateI {
  loading: boolean;
  marksheet_templates: any;
  marksheet_template: any;
  add_or_update: boolean;
}

const initialState: InitialStateI = {
  loading: false,
  marksheet_templates: [],
  marksheet_template: null,
  add_or_update: false,
};

const MarksheetTemplateReducer = (
  state: InitialStateI = initialState,
  action: MarksheetTemplateDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_MARKSHEET_TEMPLATE_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case GET_MARKSHEET_TEMPLATE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_MARKSHEET_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        marksheet_templates: action.payload,
      };

    case GET_SINGLE_MARKSHEET_TEMPLATE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_SINGLE_MARKSHEET_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        marksheet_template: action.payload,
      };

    case GET_SINGLE_MARKSHEET_TEMPLATE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case ADD_MARKSHEET_TEMPLATE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case ADD_MARKSHEET_TEMPLATE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case ADD_MARKSHEET_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        marksheet_templates: [...state.marksheet_templates, action.payload],
        add_or_update: true,
      };

    case UPDATE_MARKSHEET_TEMPLATE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_MARKSHEET_TEMPLATE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case UPDATE_MARKSHEET_TEMPLATE_SUCCESS:
      const _current_data: any = state.marksheet_templates;
      const _index: number = _current_data.findIndex(
        (element: any) => element.id === action.payload.id
      );
      _current_data[_index] = action.payload;

      return {
        ...state,
        loading: false,
        marksheet_templates: _current_data,
        add_or_update: true,
      };

    case ACTIVATE_OR_DEACTIVATE_MARKSHEET_TEMPLATE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case ACTIVATE_OR_DEACTIVATE_MARKSHEET_TEMPLATE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case ACTIVATE_OR_DEACTIVATE_MARKSHEET_TEMPLATE_SUCCESS:
      const current_data: any = state.marksheet_templates;
      const index: number = current_data.findIndex(
        (element: any) => element.id === action.payload.id
      );
      current_data[index] = action.payload;

      return {
        ...state,
        loading: false,
        marksheet_templates: current_data,
      };

    case DELETE_MARKSHEET_TEMPLATE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_MARKSHEET_TEMPLATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case DELETE_MARKSHEET_TEMPLATE_SUCCESS:
      const new_data: any = state.marksheet_templates;
      const item_index = new_data.findIndex(
        (element: any) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return {
        ...state,
        loading: false,
        marksheet_templates: new_data,
      };

    default:
      return state;
  }
};

export default MarksheetTemplateReducer;
