export const GET_TEACHER_LIST_LOADING = "GET_TEACHER_LIST_LOADING";
export const GET_TEACHER_LIST_ERROR = "GET_TEACHER_LIST_ERROR";
export const GET_TEACHER_LIST_SUCCESS = "GET_TEACHER_LIST_SUCCESS";

export interface TeacherListTypeI {
  general_info: number;
  grade: string;
  grade_name: string;
  id: string;
  photo: string;
  section: string;
  section_name: string;
  staff: string;
  subject: string;
  subject_name: string;
  teacher_first_name: string;
  teacher_id: string;
  teacher_last_name: string;
  teacher_user_id: string;
}
//===============================<START>GET Teacher List <START>======================//

export interface GetTeacherListLoading {
  type: typeof GET_TEACHER_LIST_LOADING;
}

export interface GetTeacherListError {
  type: typeof GET_TEACHER_LIST_ERROR;
}

export interface GetTeacherListSuccess {
  type: typeof GET_TEACHER_LIST_SUCCESS;
  payload: TeacherListTypeI[];
}

//===============================<END>GET Teacher List <END>======================//
export type TeacherListDispatchTypes =
  | GetTeacherListLoading
  | GetTeacherListError
  | GetTeacherListSuccess;
