import { Grid, makeStyles } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { FinanceSidebar } from "../../components";
import PayBillTable from "../../components/Finance/CollectFees/PayBillTable";
import SelectedStudentTable from "../../components/Finance/CollectFees/SelectedStudentTable";
import SelectStudentForm from "../../components/Finance/CollectFees/SelectStudentForm";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";
import cx from "clsx";
import { RootStore } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { GetFeeInvoice, GetInvoiceHistory } from "../../actions/Fees/CollectFees/CollectFeesAction";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import StudentDetailCard from "../../components/Finance/CollectFees/StudentDetailCard";
import { SearchedStudentsI } from "../../actions/Student/Student/StudentActionTypes";
import BillChangeLogs from "../../components/Finance/CollectFees/BillChangeLogs";


const useStyles = makeStyles({
  root: {
    position: "relative",
    overflow: "hidden",
  },
  boxBlur: {
    filter: "blur(8px)",
    WebkitFilter: "blur(8px)",
    userSelect: "none",
  },
  blurTextWrapper: {
    position: "absolute",
    top: 0,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    left: 0,
    width: "100%",
    height: "100%",
  },
  blurTextButton: {
    padding: "15px 30px",
    display: "inline-block",
    fontSize: "24px",
    currsor: "pointer",
    color: "#132E98",
    border: "2px #132E98 dotted",
    marginTop: "140px",
  },
  tab: {
    flexGrow: 1,
    padding: "10px 20px",
    "& .MuiBox-root": {
      padding: "5px 0px"
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#132e98"
    }
  }
});

export default function FeeTypePage() {
  const classes = useStyles();
  const [isStudentSelected, setIsStudentSelected] = useState<boolean>(false);
  const [studentID, setStudentID] = useState<string | null>(null);
  const [studentData, setStudentData] = useState<SearchedStudentsI | null>(null);
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const messageBox = (
    <div className={classes.blurTextWrapper}>
      <div className={classes.blurTextButton}>
        Please select the invoice to view the detail.
      </div>
    </div>
  );

  const nav = <FinanceSidebar collect_fees />;
  const dispatch = useDispatch();
  const feesData = useSelector((state: RootStore) => state.collect_fees.detail);
  const handleSearchClick = (student: SearchedStudentsI) => {
    setStudentData(student);
    setStudentID(student.academic_id);
  };

  useEffect(() => {
    if (studentID != null) {
      dispatch(GetFeeInvoice(studentID));
      setIsStudentSelected(true);
    }
  }, [studentID]);
  return (
    <SubModuleLayout sideNav={nav}>
      <Grid container>
        <Grid item xs={12}>
          <SelectStudentForm
            setIsStudentSelected={setIsStudentSelected}
            isStudentSelected={isStudentSelected}
            handleSearchClick={handleSearchClick}
            feesData={feesData}
          />
          <StudentDetailCard studentData={studentData} />
        </Grid>
        <Grid item xs={12} className={classes.root}>
          <div className={cx(!isStudentSelected && classes.boxBlur)}>
            <div className={classes.tab}>
              <AppBar position="static" color="default">
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                  <Tab label="Collect Fees" {...a11yProps(0)} />
                  <Tab label="Fee Invoice Record" {...a11yProps(1)} />
                  <Tab label="Edit Logs" {...a11yProps(2)} />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <PayBillTable
                  studentData={studentData}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <SelectedStudentTable
                  student_id={studentID}
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <BillChangeLogs
                  student_id={studentID}
                />
              </TabPanel>
            </div>
          </div>
          {!isStudentSelected && messageBox}
        </Grid>
      </Grid>
    </SubModuleLayout>
  );
}
