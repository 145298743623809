import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  TableHead,
  TableBody,
  Table,
  Button,
  Divider,
  TableRow,
} from "@material-ui/core";
import { useTableStyles } from "../../Styles/TableStyles";
import { useTableWithPagination } from "../../Reusable";
import { RootStore } from "../../../store";
import { useDispatch, useSelector } from "react-redux";

import BackDropLoader from "../../Reusable/BackDropLoader";
import styled from "styled-components";
import { Edit, Save } from "@material-ui/icons";
import {
  changeFields,
  reloadData,
} from "../../../rtk/features/exam/addMarks/addMarksSlice";
import {
  getMarksEntry,
  postMarksEntry,
} from "../../../rtk/features/exam/addMarks/addMarksApi";

const CellInput = styled.input<{ fullWidth?: boolean; error?: boolean }>`
  && {
    width: ${(props) => (props.fullWidth ? `unset` : `80px`)};
    padding: 5px;
    border-radius: 3px;
    border: ${(props) => (props.error ? `1px solid red` : `1px solid #000`)};
    color: ${(props) => (props.error ? `red` : `#000`)};

    &:focus {
      outline: none;
    }
  }
`;

const ExamDetail = styled.span`
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.8px;
  line-height: 1.6;

  & span {
    font-weight: 500;
  }
`;
// ---------------------------- <END> interface ends ----------------------------------//
// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells = [
  { id: "roll_no", label: "Roll No" },
  { id: "student_name", label: "Student Name" },
  { id: "full_marks", label: "Full Marks" },
  { id: "pass_marks", label: "Pass Marks" },
  { id: "marks", label: "Marks" },
  { id: "remarks", label: "Remarks" },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const AddMarksTable: React.FC = () => {
  const classes = useTableStyles();
  const dispatch = useDispatch();
  const { loading, studentMarks, searchParams, actionPerformed } = useSelector(
    (state: RootStore) => state.addMarks
  );

  const [editMode, setEditMode] = useState<boolean>(false);

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTableWithPagination(headCells);

  useEffect(() => {
    if (actionPerformed) {
      setEditMode(false);

      if (searchParams?.exam && searchParams.grade && searchParams.subject) {
        dispatch(
          getMarksEntry({
            examId: searchParams.exam.id,
            gradeId: searchParams.grade.id,
            sectionId: searchParams.section?.id || "",
            subjectId: searchParams.subject.id,
          })
        );
      }
    }
  }, [actionPerformed]);

  const handleReset = () => {
    if (!editMode) {
      setEditMode(true);
    } else {
      setEditMode(false);
    }
  };

  const handleSaveMarks = () => {
    const data: {
      student_info: string;
      marks_obtained_theory: number | null;
      marks_obtained_practical: number | null;
      remarks: string;
    }[] = [];

    studentMarks.marks.forEach((el) => {
      data.push({
        student_info: el.id,
        marks_obtained_theory: Number(el.marksTheory),
        marks_obtained_practical: Number(el.marksPractical) || null,
        remarks: el.remarks,
      });
    });

    if (searchParams?.exam && searchParams.subject && searchParams.grade) {
      dispatch(
        postMarksEntry({
          examId: searchParams.exam.id,
          subjectId: searchParams.subject.id,
          grade: searchParams.grade.id,
          section: searchParams.section?.id || "",
          is_practical: searchParams.subject.has_practical,
          post_data: data,
        })
      );
    }
  };

  return (
    <Paper className={classes.rootTableBox}>
      <Grid container>
        <Grid item xs={4}>
          <ExamDetail>
            Exam: <span>{searchParams?.exam?.name || "-"}</span>
          </ExamDetail>
        </Grid>
        <Grid item xs={8}>
          <ExamDetail>
            Subject: <span>{searchParams?.subject?.name || "-"}</span>
          </ExamDetail>
        </Grid>
        <Grid item xs={4}>
          <ExamDetail>
            Grade: <span>{searchParams?.grade?.name || "-"}</span>
          </ExamDetail>
        </Grid>
        <Grid item xs={4}>
          <ExamDetail>
            Section: <span>{searchParams?.section?.name || "-"}</span>
          </ExamDetail>
        </Grid>
      </Grid>

      <Divider style={{ margin: "10px 0 15px 0" }} />
      <Grid container>
        <Table>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell rowSpan={2} align="center">
                Roll No
              </StyledTableCell>
              <StyledTableCell rowSpan={2} align="center">
                Student Name
              </StyledTableCell>
              <StyledTableCell colSpan={2} align="center">
                Full Marks
              </StyledTableCell>
              <StyledTableCell colSpan={2} align="center">
                Pass Marks
              </StyledTableCell>
              <StyledTableCell colSpan={2} align="center">
                Marks Obtained
              </StyledTableCell>
              <StyledTableCell rowSpan={2} align="center">
                Remarks
              </StyledTableCell>
            </StyledTableRow>

            <TableRow>
              <StyledTableCell align="center">TH</StyledTableCell>
              <StyledTableCell align="center">PR</StyledTableCell>

              <StyledTableCell align="center">TH</StyledTableCell>
              <StyledTableCell align="center">PR</StyledTableCell>

              <StyledTableCell align="center">TH</StyledTableCell>
              <StyledTableCell align="center">PR</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {studentMarks.marks.map((item, index) => (
              <StyledTableRow key={item.id}>
                <StyledTableCell align="center">{index + 1}</StyledTableCell>
                <StyledTableCell align="center">{item.name}</StyledTableCell>
                <StyledTableCell align="center">
                  {item.fullMarksTheory || "-"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {item.fullMarksPractical || "-"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {item.passMarksTheory || "-"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {item.passMarksPractical || "-"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {editMode ? (
                    <CellInput
                      value={item.marksTheory}
                      maxLength={4}
                      onChange={(e) =>
                        dispatch(
                          changeFields({
                            id: item.id,
                            field: "marksTheory",
                            value: e.target.value,
                          })
                        )
                      }
                    />
                  ) : (
                    item.marksTheory || "--"
                  )}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {editMode ? (
                    <CellInput
                      value={item.marksPractical}
                      maxLength={4}
                      onChange={(e) =>
                        dispatch(
                          changeFields({
                            id: item.id,
                            field: "marksPractical",
                            value: e.target.value,
                          })
                        )
                      }
                      disabled={!studentMarks.has_practical}
                    />
                  ) : (
                    item.marksPractical || "--"
                  )}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {editMode ? (
                    <CellInput
                      fullWidth
                      value={item.remarks}
                      onChange={(e) =>
                        dispatch(
                          changeFields({
                            id: item.id,
                            field: "remarks",
                            value: e.target.value,
                          })
                        )
                      }
                    />
                  ) : (
                    item.remarks || "--"
                  )}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleReset}
            startIcon={<Edit />}
            style={{ margin: "10px 10px 0 0" }}
            disabled={!studentMarks.marks.length}
          >
            {editMode ? "Cancel" : "Edit"}
          </Button>
          {editMode && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSaveMarks()}
              startIcon={<Save />}
              style={{ margin: "10px 10px 0 0" }}
            >
              Save
            </Button>
          )}
        </div>
      </Grid>
      <BackDropLoader open={loading} />
    </Paper>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default AddMarksTable;
