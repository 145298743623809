import React, { useEffect, useState } from "react";
import { useTableStyles } from "../../Styles/TableStyles";
import {
  Paper,
  Table,
  TableBody,
  TableHead,
  TextField,
} from "@material-ui/core";
import styled from "styled-components";
import { useTable } from "../../Reusable";
import { ItemViewButton } from "../../Reusable/Buttons/TableButton";
import { Link, useParams } from "react-router-dom";
import {
  NepaliCalendar,
  getBsDayName,
  getBsDaysInMonth,
  getCurrentBsDate,
} from "../../utils/nepalicalendar";
import {
  CalendarContainer,
  Calendar,
  CalendarHeader,
  CalendarBody,
  CalendarCard,
  selectStyles,
} from "./AttendanceDetailsStyles";

import Select from "react-select";
import { getHolidayEvent } from "../../utils/publicHolidays";
import { useDispatch, useSelector } from "react-redux";
import { getStaffAttendanceReport } from "../../../rtk/features/humanResource/humanResourceThunk";
import { RootStore } from "../../../store";
import { dateConverterBsToAd } from "../../utils/dateConverter";
import moment from "moment";

const TableTitle = styled.h2`
  font-size: 20px;
  font-weight: 500;
  color: #2e2e2e;
`;

const TableSubTitle = styled.h2`
  font-size: 18px;
  font-weight: 500;
  color: #404040;
`;

const TableHeader = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;

  gap: 40px;

  p {
    span {
      font-weight: 500;
    }
  }
`;

const headCells = [
  { id: "sn", label: "S.N" },
  { id: "name", label: "Staff Name" },
  { id: "department", label: "Department" },
  { id: "designation", label: "Designation" },
  { id: "checkinOut", label: "Check In / Check Out" },
  { id: "action", label: "Action" },
];

const orderedMonths = [
  { id: 1, name: "Baisakh" },
  { id: 2, name: "Jestha" },
  { id: 3, name: "Asar" },
  { id: 4, name: "Shrawan" },
  { id: 5, name: "Bhadra" },
  { id: 6, name: "Asoj" },
  { id: 7, name: "Karthik" },
  { id: 8, name: "Mangsir" },
  { id: 9, name: "Poush" },
  { id: 10, name: "Magh" },
  { id: 11, name: "Falgun" },
  { id: 12, name: "Chaitra" },
];

const orderedDays = [
  { id: 1, name: "Sunday" },
  { id: 2, name: "Monday" },
  { id: 3, name: "Tuesday" },
  { id: 4, name: "Wednesday" },
  { id: 5, name: "Thursday" },
  { id: 6, name: "Friday" },
  { id: 7, name: "Saturday" },
];

const returnNepaliDateString = (year: number, month: number, day: number) => {
  return `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(
    2,
    "0"
  )}`;
};

const checkBeforeDate = (nepDate: string) => {
  const providedDate = dateConverterBsToAd(nepDate);
  const today = moment(new Date()).format("YYYY-MM-DD");

  const before = moment(providedDate).isBefore(today);

  return before;
};

const AttendaceDetails = () => {
  const { staffId } = useParams<{ staffId: string }>();
  const tableClasses = useTableStyles();
  const dispatch = useDispatch();

  const { TblContainer, StyledTableCell, StyledTableRow } = useTable(headCells);
  const { loading, staffAttendanceReport } = useSelector(
    (state: RootStore) => state.humanResource
  );

  const [selectedMonth, setSelectedMonth] = useState<{
    label: string;
    value: number;
  } | null>(null);

  const [allDays, setAllDays] = useState<
    {
      id: number;
      year: number;
      month: number;
      day: string;
      date: string;
      notCurrentMonth: boolean;
      holiday: string | null;
    }[]
  >([]);

  useEffect(() => {
    const currentMonth = orderedMonths.find(
      (el) => el.id === getCurrentBsDate().month
    );

    setSelectedMonth({ label: currentMonth!.name, value: currentMonth!.id });
  }, []);

  useEffect(() => {
    if (selectedMonth) {
      const noOfDays = getBsDaysInMonth(
        getCurrentBsDate().year,
        selectedMonth.value
      );

      dispatch(
        getStaffAttendanceReport({
          employee: staffId,
          from_date: `${getCurrentBsDate().year}-${String(
            selectedMonth.value
          ).padStart(2, "0")}-01`,
          to_date: `${getCurrentBsDate().year}-${String(
            selectedMonth.value
          ).padStart(2, "0")}-${noOfDays}`,
        })
      );

      const cards: {
        id: number;
        year: number;
        month: number;
        day: string;
        date: string;
        notCurrentMonth: boolean;
        holiday: string | null;
      }[] = [];

      const initialIndex: number = orderedDays.find(
        (el) =>
          el.name ===
          getBsDayName({
            ...getCurrentBsDate(),
            month: selectedMonth.value,
            day: 1,
          })
      )!.id;

      const finalIndex: number = orderedDays.find(
        (el) =>
          el.name ===
          getBsDayName({
            ...getCurrentBsDate(),
            month: selectedMonth.value,
            day: noOfDays,
          })
      )!.id;

      for (let i = 1; i <= noOfDays; i++) {
        const startingIndex = initialIndex + i - 1;
        const currentIndex =
          startingIndex - Math.floor(startingIndex / 7) * 7 || 7;

        cards.push({
          id: i,
          year: getCurrentBsDate().year,
          month: selectedMonth.value,
          day: orderedDays[currentIndex - 1].name,
          date: String(i),
          notCurrentMonth: false,
          holiday: getHolidayEvent({
            year: getCurrentBsDate().year,
            month: selectedMonth.value,
            day: i,
          }),
        });
      }

      if (initialIndex > 1) {
        for (let i = 1; i <= initialIndex - 1; i++) {
          cards.unshift({
            id: cards.length + i,
            year: getCurrentBsDate().year,
            month: selectedMonth.value,
            day: String(i),
            date: "-",
            notCurrentMonth: true,
            holiday: null,
          });
        }
      }

      if (finalIndex < 7) {
        for (let i = 1; i <= 7 - finalIndex; i++) {
          cards.push({
            id: cards.length + i,
            year: getCurrentBsDate().year,
            month: selectedMonth.value,
            day: String(0),
            date: "-",
            notCurrentMonth: true,
            holiday: null,
          });
        }
      }

      setAllDays([...cards]);
    }
  }, [selectedMonth]);

  const handleMonthChange = (val: any) => {
    setSelectedMonth(val);
  };

  const getAttendanceByDate = (data: {
    year: number;
    month: number;
    day: number;
  }) => {
    if (staffAttendanceReport) {
      const dayFound = staffAttendanceReport.attendance.find(
        (el) =>
          el.date === returnNepaliDateString(data.year, data.month, data.day)
      );

      if (dayFound) {
        return {
          checkIn: dayFound.checkIn,
          checkOut: dayFound.checkout,
          onLeave: dayFound.leave,
          absent: false,
        };
      } else {
        const pastDate = checkBeforeDate(
          returnNepaliDateString(data.year, data.month, data.day)
        );
        if (pastDate) {
          return {
            checkIn: "00:00",
            checkOut: "00:00",
            onLeave: false,
            absent: true,
          };
        }
      }

      return {
        checkIn: "00:00",
        checkOut: "00:00",
        onLeave: false,
        absent: false,
      };
    } else {
      return {
        checkIn: "00:00",
        checkOut: "00:00",
        onLeave: false,
        absent: false,
      };
    }
  };

  return (
    <Paper className={tableClasses.root}>
      <TableTitle>Staff Monthly Attendance Report</TableTitle>
      <TableSubTitle>{staffAttendanceReport?.name || ""}</TableSubTitle>
      <TableHeader>
        <Select
          value={selectedMonth}
          options={orderedMonths.map((el) => ({
            label: el.name,
            value: el.id,
          }))}
          styles={selectStyles}
          placeholder="Select Month"
          onChange={(val) => handleMonthChange(val)}
        />
        <p>
          Shift: <span>General - 10:00 AM to 4:00 PM</span>
        </p>
      </TableHeader>
      <CalendarContainer>
        <Calendar>
          <CalendarHeader>
            {orderedDays.map((el) => (
              <span key={el.id}>{el.name}</span>
            ))}
          </CalendarHeader>
          <CalendarBody>
            {allDays.map((el, index) => {
              if (el.day === "Saturday") {
                return (
                  <CalendarCard
                    key={index + 1}
                    checkIn="00:00 am"
                    checkOut="00:00 pm"
                    day={el.date}
                    dayStatus={{ type: "Week Off" }}
                    notCurrentMonth={el.notCurrentMonth}
                  />
                );
              } else if (el.holiday) {
                return (
                  <CalendarCard
                    key={index + 1}
                    checkIn="00:00 am"
                    checkOut="00:00 pm"
                    day={el.date}
                    dayStatus={{ type: "Public Holiday", event: el.holiday }}
                    notCurrentMonth={el.notCurrentMonth}
                  />
                );
              } else if (el.notCurrentMonth) {
                return (
                  <CalendarCard
                    key={index + 1}
                    checkIn="00:00 am"
                    checkOut="00:00 pm"
                    day={el.date}
                    dayStatus={null}
                    notCurrentMonth={el.notCurrentMonth}
                  />
                );
              } else if (
                getAttendanceByDate({
                  year: el.year,
                  month: el.month,
                  day: Number(el.date),
                }).onLeave
              ) {
                return (
                  <CalendarCard
                    key={index + 1}
                    checkIn="00:00 am"
                    checkOut="00:00 pm"
                    day={el.date}
                    dayStatus={{ type: "Leave" }}
                    notCurrentMonth={el.notCurrentMonth}
                  />
                );
              } else if (
                getAttendanceByDate({
                  year: el.year,
                  month: el.month,
                  day: Number(el.date),
                }).absent
              ) {
                return (
                  <CalendarCard
                    key={index + 1}
                    checkIn="00:00 am"
                    checkOut="00:00 pm"
                    day={el.date}
                    dayStatus={{ type: "Absent" }}
                    notCurrentMonth={el.notCurrentMonth}
                  />
                );
              }
              return (
                <CalendarCard
                  key={index + 1}
                  checkIn={
                    getAttendanceByDate({
                      year: el.year,
                      month: el.month,
                      day: Number(el.date),
                    }).checkIn
                  }
                  checkOut={
                    getAttendanceByDate({
                      year: el.year,
                      month: el.month,
                      day: Number(el.date),
                    }).checkOut
                  }
                  day={el.date}
                  dayStatus={null}
                  notCurrentMonth={el.notCurrentMonth}
                />
              );
            })}
          </CalendarBody>
        </Calendar>
      </CalendarContainer>
    </Paper>
  );
};

export default AttendaceDetails;
