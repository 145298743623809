import {
  GET_SUBJECT_LOADING,
  GET_SUBJECT_ERROR,
  GET_SUBJECT_SUCCESS,
  SubjectDispatchTypes,
  ADD_SUBJECT_LOADING,
  ADD_SUBJECT_ERROR,
  ADD_SUBJECT_SUCCESS,
  DELETE_SUBJECT_LOADING,
  DELETE_SUBJECT_ERROR,
  DELETE_SUBJECT_SUCCESS,
  UPDATE_SUBJECT_LOADING,
  UPDATE_SUBJECT_ERROR,
  UPDATE_SUBJECT_SUCCESS,
  SubjectTypeI,
} from "../../actions/Academics/Subject/SubjectActionTypes";

interface InitialStateI {
  loading: boolean;
  subjects: SubjectTypeI[];
  errors?: any;
  recent?: boolean;
  add_or_update: boolean;
}

const initialState: InitialStateI = {
  loading: false,
  subjects: [],
  recent: false,
  errors: null,
  add_or_update: false,
};

const SubjectReducer = (
  state: InitialStateI = initialState,
  action: SubjectDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_SUBJECT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_SUBJECT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_SUBJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        subjects: action.payload,
        errors: null,
        recent: false,
      };

    case ADD_SUBJECT_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case ADD_SUBJECT_ERROR:
      return {
        ...state,
        loading: false,
        recent: false,
        errors: action.payload,
        add_or_update: true,
      };

    case ADD_SUBJECT_SUCCESS:
      return {
        loading: false,
        subjects: [...state.subjects, action.payload],
        recent: true,
        errors: null,
        add_or_update: true,
      };

    case UPDATE_SUBJECT_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case UPDATE_SUBJECT_ERROR:
      return {
        ...state,
        loading: false,
        recent: false,
        errors: action.payload,
        add_or_update: true,
      };

    case UPDATE_SUBJECT_SUCCESS:
      const current_data: SubjectTypeI[] = state.subjects;
      const index: number = current_data.findIndex(
        (element) => element.id === action.payload.id
      );
      current_data[index] = action.payload;

      return {
        loading: false,
        subjects: current_data,
        recent: true,
        errors: null,
        add_or_update: true,
      };

    case DELETE_SUBJECT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_SUBJECT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_SUBJECT_SUCCESS:
      const new_data: SubjectTypeI[] = state.subjects;
      const item_index = new_data.findIndex(
        (element) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return {
        ...state,
        loading: false,
        subjects: new_data,
      };

    default:
      return state;
  }
};

export default SubjectReducer;
