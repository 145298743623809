import {
    GET_STUDENT_ASSIGNMENT_ERROR,
    GET_STUDENT_ASSIGNMENT_LOADING,
    GET_STUDENT_ASSIGNMENT_SUCCESS,
    AssignmentResponse,
    FeedbackResponse,
    StudentProgressDispatchTypes
} from "../../../actions/Student/StudentProgress/StudentProgressActionTypes"

interface InitialStateI {
    loading: boolean;
    progress: [AssignmentResponse, FeedbackResponse],
}

const initialState: InitialStateI = {
    loading: false,
    progress: [{} as AssignmentResponse, {} as FeedbackResponse],
}

const StudentProgressReducer = (
    state: InitialStateI = initialState,
    action: StudentProgressDispatchTypes
): InitialStateI => {
    switch (action.type) {
        case GET_STUDENT_ASSIGNMENT_LOADING:
            return {
                loading: true,
                progress: [{} as AssignmentResponse, {} as FeedbackResponse]
            }

        case GET_STUDENT_ASSIGNMENT_ERROR:
            return {
                loading: false,
                progress: [{} as AssignmentResponse, {} as FeedbackResponse]
            }

        case GET_STUDENT_ASSIGNMENT_SUCCESS:
            return {
                loading: false,
                progress: action.payload,
            }

        default:
            return state;
    }
}

export default StudentProgressReducer