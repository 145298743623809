import { Clear } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootStore } from "../../../store";
import { useTable, useTableWithPagination } from "../../Reusable";
import { useTableStyles } from "../../Styles/TableStyles";
import { timeFormat } from "../../utils/dateTimeFormat";
import getFullName from "../../utils/getFullName";

interface HeadCellsI {
  id: string;
  label: string;
}

// -------------------- <START> Table Headings Data starts---------------------------//
const headCells: HeadCellsI[] = [
  { id: "student", label: "Student " },
  { id: "class", label: "Class" },
  { id: "section", label: "Section " },
  { id: "join_time", label: "Join Time" },
  // { id: "action", label: "Action" },
];

const ClassRoomLog = (props: any) => {
  const classes = useTableStyles();
  const { teacher, admin } = props;
  const {
    StyledTableCell,
    StyledTableRow,

    TableContainer,
  } = useTableWithPagination(headCells);

  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<any>([]);

  const OnlineClassLog = useSelector(
    (state: RootStore) => state.online_classes
  );

  const teacherLogSelector = useSelector(
    (state: RootStore) => state.teacher_liveClass
  );

  useEffect(() => {
    if (teacher) {
      if (teacherLogSelector?.class_log?.results) {
        const data = teacherLogSelector?.class_log?.results;
        setTableData(data);
        setLoading(teacherLogSelector?.loading);
      }
    } else {
      if (OnlineClassLog?.class_log?.results) {
        const data = OnlineClassLog?.class_log?.results;
        setTableData(data);
        setLoading(OnlineClassLog?.loading);
      }
    }
  }, [OnlineClassLog, teacherLogSelector]);

  // const timeConverter = (date: string) => {
  //   const a = date.split(":");
  //   let hours = parseInt(a[0]);
  //   const minutes = parseInt(a[0]);
  //   const ampm = hours >= 12 ? "pm" : "am";
  //   hours = hours % 12;
  //   const time = `${hours} :  ${minutes} ${ampm}`;
  //   return time;
  // };

  const getCustomTableRow = (item: any) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {getFullName(item.student_first_name, item.student_last_name)}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.threeCell}>
          {item.grade_name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.section_name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.joined_on ? (
            timeFormat(item.joined_on)
          ) : (
            <Clear color="error" />
          )}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <>
      <TableContainer
        label="Student list"
        loading={loading}
        items={tableData}
        disableSearch
        headCells={headCells}
        getCustomTableRow={getCustomTableRow}
        forPagination={OnlineClassLog?.class_log}
      />
    </>
  );
};

export default ClassRoomLog;
