//---------------------------- <START> module imports start ----------------------------------//
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Paper, Button, Box } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { getSingleCard } from './IdCardSampleData';
import PotraitStudentID from '../../NewIDCARDDESIGN/potrait/PotraitStudentID';
import Skeleton from '@material-ui/lab/Skeleton';
import { FormSaveLoadingButton } from '../../../Reusable/Buttons/FormButton';
import {
  AddIdCardTemplate,
  GetSingleIdCardTemplate,
  UpdateIdCardTemplate,
} from '../../../../actions/Templates/IdCard/IdCardTemplateAction';
import { RootStore } from '../../../../store';
import PotraitTeacherIDCard from '../../NewIDCARDDESIGN/potrait/PotraitTeacherIDCard';
import LandScapeTeacherCard from '../../NewIDCARDDESIGN/landscape/LandScapeTeacherCard';
import LandScapeStudentID from '../../NewIDCARDDESIGN/landscape/LandScapeStudentID';
import * as htmlToImage from 'html-to-image';
import LoaderIcon from '../../../Reusable/LoarderIcon';
import Spinner from '../../../../common/spinnerSVG/Spinner';
import SaveIcon from '@material-ui/icons/Save';

// ----------------------------<END> module imports end ----------------------------------//

const CustomizeID: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { cardID } = useParams<{ cardID: string }>();
  const { mode } = useParams<{ mode: string }>();
  const [individualData, setIndividualData] = useState<any>();
  const [saved, setSaved] = useState<boolean>(false);
  const [textColor, setTextColor] = useState<any>();
  const [backgroundColor, setBackgroundColor] = useState<any>();
  const [textColorTemp, setTextColorTemp] = useState<any>();
  const [backgroundColorTemp, setBackgroundColorTemp] = useState<any>();
  const [url, setUrl] = useState<string>('');
  const [screenshot, setScreenshot] = useState<any>(null);
  const [emptyBackground, setEmptyBackground] = useState<boolean>(true);

  const history = useHistory();
  const dispatch = useDispatch();

  const id_card_templates = useSelector(
    (state: RootStore) => state.id_card_templates
  );

  const id_card_template = useSelector(
    (state: RootStore) => state.id_card_templates.id_card_template
  );

  const add_or_update = useSelector(
    (state: RootStore) => state.id_card_templates.add_or_update
  );

  useEffect(() => {
    // if (add_or_update) {
    setIsLoading(false);
    // }
  }, [add_or_update]);

  useEffect(() => {
    if (mode && cardID) {
      const n = window.location.href.lastIndexOf('/');
      setUrl(window.location.href.substring(n + 1));

      const data = getSingleCard(cardID, mode);
      setIndividualData(data);
      setTextColor(data?.text_color);
      setBackgroundColor(data?.back_ground_color);
      setTextColorTemp(data?.text_color);
      setBackgroundColorTemp(data?.back_ground_color);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setEmptyBackground(false);
    }, 2000);
  }, [individualData]);

  useEffect(() => {
    if (url === 'update') {
      dispatch(GetSingleIdCardTemplate(cardID));
    }
  }, [url, cardID]);

  useEffect(() => {
    if (id_card_template != null) {
      setIndividualData(id_card_template);
      setTextColor(id_card_template?.text_color);
      setBackgroundColor(id_card_template?.back_ground_color);
      setTextColorTemp(id_card_template?.text_color);
      setBackgroundColorTemp(id_card_template?.back_ground_color);
    }
  }, [id_card_template]);

  const handleTextColorChange = (key: string, value: string, name: string) => {
    const temp = {
      key,
      value,
      name,
    };
    setTextColor(
      textColor.map((item: any) => (item.key === key ? temp : item))
    );
  };

  const handleBackgroundColorChange = (
    key: string,
    value: string,
    name: string
  ) => {
    const temp = {
      key,
      value,
      name,
    };
    setBackgroundColor(
      backgroundColor.map((item: any) => (item.key === key ? temp : item))
    );
  };

  useEffect(() => {
    if (textColor != textColorTemp) {
      const temp = {
        ...individualData,
        text_color: textColor,
      };
      setIndividualData(temp);
    }
  }, [textColor]);

  useEffect(() => {
    if (backgroundColor != backgroundColorTemp) {
      const temp = {
        ...individualData,
        back_ground_color: backgroundColor,
      };
      setIndividualData(temp);
    }
  }, [backgroundColor]);

  const renderSwitch = (param: string) => {
    switch (param) {
      case 'ABC-001':
        return <PotraitStudentID content={individualData} />;
      case 'ABC-002':
        return <PotraitTeacherIDCard content={individualData} />;
      case 'ABC-003':
        return <LandScapeStudentID content={individualData} />;
      case 'ABC-004':
        return <LandScapeTeacherCard content={individualData} />;
    }
  };

  const handlleSubmit = () => {
    setIsLoading(true);
    const finalValues = {
      ...individualData,
    };
    const node: any = document.getElementById('exportContainer');
    htmlToImage
      .toPng(node)
      .then(function (dataUrl) {
        finalValues.thumbnail = dataUrl;
        setScreenshot(dataUrl);
        return dataUrl;
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
    setTimeout(() => {
      if (url === 'update') {
        dispatch(UpdateIdCardTemplate(cardID, finalValues));
      } else {
        dispatch(AddIdCardTemplate(finalValues));
      }
      setSaved(true);
    }, 4000);
  };

  if (saved && id_card_templates.add_or_update) {
    history.push('/id-cards-templates');
  }

  return (
    <>
      <Paper style={{ margin: '30px', padding: '20px' }}>
        <Grid container spacing={3} justifyContent="space-between">
          <Grid item xs={12} sm={6}>
            <span style={{ fontSize: '18px' }}>
              {url === 'update' ? 'In Update Mode' : 'In Customization Mode'}
            </span>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              onClick={() => history.push('/id-cards-templates')}
            >
              Go Back to My List
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          justifyContent="space-between"
          style={{ marginTop: '10px' }}
        >
          <Grid item xs={6} sm={individualData?.mode === 'potrait' ? 4 : 6}>
            {emptyBackground && individualData != null ? (
              <>
                <Skeleton />
                <Skeleton animation="wave" />
                <Skeleton />
                <Skeleton animation={false} />
                <Skeleton animation="wave" />
                <Skeleton animation={false} />
                <Skeleton animation="wave" />
                <Skeleton />
                <Skeleton animation={false} />
                <Skeleton animation="wave" />
                <Skeleton />
                <Skeleton animation={false} />
                <Skeleton animation="wave" />
                <Skeleton animation={false} />
                <Skeleton animation="wave" />
              </>
            ) : (
              <div
                id="exportContainer"
                style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}
              >
                {cardID && renderSwitch(individualData?.code)}
              </div>
            )}
          </Grid>
          <Grid item xs={6} sm={3} style={{ marginTop: '20px' }}>
            <Box
              fontSize={18}
              fontWeight={500}
              style={{ marginBottom: '20px' }}
            >
              Text Colors
            </Box>
            {textColor?.length > 0 &&
              textColor.map((elem: any, index: number) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginBottom: '10px',
                    }}
                    key={index}
                  >
                    <span
                      style={{
                        fontSize: '12px',
                        marginRight: '8px',
                        marginTop: '5px',
                      }}
                    >
                      {elem.name} - [{elem.value}]
                    </span>
                    <div className="input-color-container">
                      <input
                        type="color"
                        id="input-color"
                        className="input-color"
                        value={elem.value}
                        onChange={(event: any) =>
                          handleTextColorChange(
                            elem.key,
                            event.target.value,
                            elem.name
                          )
                        }
                      />
                    </div>
                  </div>
                );
              })}
            <FormSaveLoadingButton
              onClick={() => handlleSubmit()}
              icon={<SaveIcon />}
              disabled
              text={isLoading ? 'Saving' : 'Save'}
            />
          </Grid>
          <Grid
            item
            xs={6}
            sm={individualData?.mode === 'potrait' ? 4 : 3}
            style={{ marginTop: '20px' }}
          >
            <Box
              fontSize={18}
              fontWeight={500}
              style={{ marginBottom: '20px' }}
            >
              Background
            </Box>
            {backgroundColor?.length > 0 &&
              backgroundColor.map((elem: any, index: number) => {
                return (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: '10px',
                        marginRight: '15px',
                      }}
                      key={index}
                    >
                      <span
                        style={{
                          fontSize: '12px',
                          marginRight: '8px',
                          marginTop: '5px',
                        }}
                      >
                        {elem.name} - [{elem.value}]
                      </span>
                      <div className="input-color-container">
                        <input
                          type="color"
                          id="input-color"
                          className="input-color"
                          value={elem.value}
                          onChange={(event: any) =>
                            handleBackgroundColorChange(
                              elem.key,
                              event.target.value,
                              elem.name
                            )
                          }
                        />
                      </div>
                    </div>
                  </>
                );
              })}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//
export default CustomizeID;
