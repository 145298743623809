import React from "react";
import {
  DeleteForeverOutlined,
  EditOutlined,
  PersonAddDisabled,
  PersonAddOutlined,
  Add,
  Remove,
  VisibilityOutlined,
  PostAddOutlined,
  GetApp,
  Publish,
  FormatListBulleted,
  ExitToApp,
  List,
  SaveOutlined,
  ClearOutlined,
  PrintOutlined,
} from "@material-ui/icons";
import ActionButton from "./ActionButton";
import { useTableStyles } from "../../Styles/TableStyles";

const TableButton = (props: any) => {
  const { title, disabled, ...rest } = props;
  return (
    <ActionButton disabled={props.disabled} title={title} {...rest}>
      {props.children}
    </ActionButton>
  );
};

const ItemViewButton = (props: any) => {
  const classes = useTableStyles();
  return (
    <TableButton title="View" {...props}>
      <VisibilityOutlined className={classes.viewIcon} />
    </TableButton>
  );
};

const ItemPrintButton = (props: any) => {
  const classes = useTableStyles();
  return (
    <TableButton title="Print" {...props}>
      <PrintOutlined className={classes.printIcon} />
    </TableButton>
  );
};

const ItemEditButton = (props: any) => {
  const classes = useTableStyles();
  return (
    <TableButton title="Edit" {...props}>
      <EditOutlined className={props.disabled ? "" : classes.editIcon} />
    </TableButton>
  );
};

const ItemEnableButton = (props: any) => {
  const classes = useTableStyles();
  return (
    <TableButton title="Enable" {...props}>
      <PersonAddOutlined className={classes.deleteIcon} />
    </TableButton>
  );
};

const ItemDisableButton = (props: any) => {
  const classes = useTableStyles();
  return (
    <TableButton title="Disable" {...props}>
      <PersonAddDisabled className={props.disabled ? "" : classes.deleteIcon} />
    </TableButton>
  );
};

const ItemCancelButton = (props: any) => {
  const classes = useTableStyles();
  const { ...rest } = props;
  return (
    <TableButton title="Cancel" {...rest}>
      <ClearOutlined className={props.disabled ? "" : classes.deleteIcon} />
    </TableButton>
  );
};

const ItemDeleteButton = (props: any) => {
  const classes = useTableStyles();
  const { ...rest } = props;
  return (
    <TableButton title={props.title ? props.title : "Delete"} {...rest}>
      <DeleteForeverOutlined
        className={props.disabled ? classes.disabledIcon : classes.deleteIcon}
      />
    </TableButton>
  );
};

const RemoveButton = (props: any) => {
  const classes = useTableStyles();
  return (
    <TableButton title="Remove" {...props}>
      <Remove className={classes.deleteIcon} />
    </TableButton>
  );
};

const ItemAddButton = (props: any) => {
  const classes = useTableStyles();
  const { disabled, ...rest } = props;
  return (
    <TableButton
      title={props.title ? props.title : "Add"}
      disabled={disabled}
      {...rest}
    >
      <Add className={disabled ? "" : classes.addIcon} />
    </TableButton>
  );
};

const PostAddButton = (props: any) => {
  const classes = useTableStyles();
  return (
    <TableButton title="Add" {...props}>
      <PostAddOutlined className={classes.addIcon} />
    </TableButton>
  );
};

const DownloadButton = (props: any) => {
  const classes = useTableStyles();
  return (
    <TableButton title="Download" {...props}>
      <GetApp className={classes.downloadIcon} />
    </TableButton>
  );
};

const ItemSubmitButton = (props: any) => {
  const { disabled } = props;
  const classes = useTableStyles();
  return (
    <TableButton title="Submit" disabled={disabled} {...props}>
      <Publish
        className={disabled ? classes.disabledIcon : classes.downloadIcon}
      />
    </TableButton>
  );
};

const ItemFormList = (props: any) => {
  const classes = useTableStyles();
  return (
    <TableButton title="Form View" {...props}>
      <FormatListBulleted className={classes.listIcon} />
    </TableButton>
  );
};

const ItemGoto = (props: any) => {
  const classes = useTableStyles();
  return (
    <TableButton title="Go to" {...props}>
      <ExitToApp className={classes.editIcon} />
    </TableButton>
  );
};

const ItemLogList = (props: any) => {
  const classes = useTableStyles();
  return (
    <TableButton title="Log List" {...props}>
      <List className={classes.viewIcon} />
    </TableButton>
  );
};

const ItemSaveButton = (props: any) => {
  const classes = useTableStyles();
  return (
    <TableButton title="Save" {...props}>
      <SaveOutlined className={classes.editIcon} />
    </TableButton>
  );
};

export default TableButton;
export {
  ItemEditButton,
  ItemDeleteButton,
  ItemPrintButton,
  ItemViewButton,
  RemoveButton,
  ItemEnableButton,
  ItemDisableButton,
  ItemAddButton,
  PostAddButton,
  DownloadButton,
  ItemSubmitButton,
  ItemFormList,
  ItemGoto,
  ItemLogList,
  ItemSaveButton,
  ItemCancelButton,
};
