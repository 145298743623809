// ---------------------------- <START> module imports start ----------------------------------//
import React, { useState, useEffect } from 'react';
import {
  Paper,
  Box,
  TableBody,
  TextField,
  Grid,
  Typography,
  LinearProgress,
} from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import { Pagination } from '@material-ui/lab';
import { useTableStyles } from '../../Styles/TableStyles';
import { useTable } from '../../Reusable';
import TableActionButtons from '../../Reusable/Buttons/TableActionButtons';
import CustomizedSelect from '../../Reusable/Inputs/Select';
import ActionButton from '../../Reusable/Buttons/ActionButton';
import { useDispatch, useSelector } from 'react-redux';
import { paginationChoices } from '../../../common/json.constant';
import {
  GetSubjectGroups,
  GetSubjectGroupsWithPagination,
  GetPaginatedSubjectGroups,
  DeleteSubjectGroup,
} from '../../../actions/Academics/Subject/SubjectGroupAction';
import { SubjectGroupTypeI } from '../../../actions/Academics/Subject/SubjectGroupActionTypes';
import { DeleteForeverOutlined } from '@material-ui/icons';
import CustomizedDialogs from '../../Reusable/Dialogs/DeleteDialog';
import { RootStore } from '../../../store';
import {
  ItemDeleteButton,
  ItemEditButton,
} from '../../Reusable/Buttons/TableButton';

// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//

interface PropsI {
  onEditData: (value: SubjectGroupTypeI) => void;
  edit: { value: boolean };
}

interface HeadCellsI {
  id: string;
  label: string;
}
// ---------------------------- <END> interface ends ----------------------------------//

// ---------------------------- <START> styling starts ----------------------------------//

// ---------------------------- <END> styling ends ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: 'sn', label: 'S.N' },
  { id: 'title', label: 'Subject Group' },
  { id: 'class_section', label: 'Class Section' },
  { id: 'subject_list', label: 'Subject List' },
  { id: 'action', label: 'Action' },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const SubjectGroupTable = (props: PropsI) => {
  const { onEditData, edit } = props;
  const classes = useTableStyles();
  const dispatch = useDispatch();

  // States declaration
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);

  const [tableData, setTableData] = useState<SubjectGroupTypeI[]>([]);
  const [editMode, setEditMode] = useState(false);

  const [selectedPagination, setSelectedPagination] = useState<any | null>({
    key: '10',
    value: '10',
  });

  //Subject Reducer
  const subjectGroupState = useSelector(
    (state: RootStore) => state.subject_group
  );
  const subjectGroupLoading = useSelector(
    (state: RootStore) => state.subject_group.loading
  );

  //================================ <START> REDUX CYCLE HOOK <START> ============================//

  useEffect(() => {
    dispatch(GetSubjectGroups());
    dispatch(GetSubjectGroupsWithPagination(1));
  }, []);

  useEffect(() => {
    if (selectedPagination != null) {
      dispatch(GetPaginatedSubjectGroups(1, Number(selectedPagination.key)));
    }
  }, [selectedPagination]);

  useEffect(() => {
    setLoading(subjectGroupLoading);
  }, [subjectGroupLoading]);

  useEffect(() => {
    setTableData(subjectGroupState.subject_groups);
  }, [subjectGroupState]);

  useEffect(() => {
    edit && setEditMode(edit.value);
  }, [edit]);

  //================================ <END> REDUX CYCLE HOOK <END> ============================//

  // Retrieving re-usable components from useTable
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  //===============================<START> EVENT HANDLERS <START>===============================//
  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const handleEditClicked = (item: SubjectGroupTypeI) => {
    onEditData(item);
    setEditMode(true);
  };

  const handleDeleteModal = (value: boolean, id: string) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteSubjectGroup(itemId));
    setDeleteModalOpen(false);
  };

  const handlePaginationChange = (paginationIndex: number) => {
    dispatch(
      GetPaginatedSubjectGroups(paginationIndex, selectedPagination?.key)
    );
  };

  const getCustomTableRow = (item: SubjectGroupTypeI, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.threeCell}>
          {index}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.threeCell}>
          {item.name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.twoCell}>
          {item.grade_name}
          {item.section.map((element, index: number) =>
            index === item.section.length - 1
              ? index === 0
                ? ` - ${element.name}`
                : `${element.name}`
              : index === 0
              ? ` - ${element.name}, `
              : `${element.name}, `
          )}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.subject.map((element, index: number) =>
            index === item.subject.length - 1
              ? element.name
              : `${element.name}, `
          )}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.twoCell}>
          <ItemEditButton
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleEditClicked(item)
            }
          />
          <ItemDeleteButton
            disabled={editMode}
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleDeleteModal(true, item.id)
            }
          />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  //===============================<END> EVENT HANDLERS <END>===============================//
  console.log(subjectGroupState);
  return (
    <>
      <Paper className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">Subject Group List</Typography>
            <Grid container justifyContent="flex-end">
              <Box fontSize={10} textAlign="center">
                <CustomizedSelect
                  label="Table Size"
                  name="gender"
                  options={paginationChoices}
                  value={selectedPagination}
                  setValue={setSelectedPagination}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <TableContainer
          loading={loading}
          headCells={headCells}
          items={tableData}
          getCustomTableRow={getCustomTableRow}
          search_param="name"
          forPagination={subjectGroupState?.subject_groups}
          handlePaginationChange={handlePaginationChange}
        />
      </Paper>
      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
      />
    </>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default SubjectGroupTable;
