import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setSnackbar } from "../../../../actions/SnackbarAction";
import { ADMIN_API_URL } from "../../../../config/SERVER_API_URLS";

export const getGradings = createAsyncThunk(
  "getGradings",
  async (undefined, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.get(`${ADMIN_API_URL}/exam-result-grade`);
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateGrading = createAsyncThunk(
  "updateGrading",
  async (
    data: { id: string; post_data: any },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.put(
        `${ADMIN_API_URL}/exam-result-grade/${data.id}/`,
        data.post_data
      );
      dispatch(setSnackbar(true, "success", "Remarks Updated Successfully"));
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      dispatch(setSnackbar(true, "warning", error.response.data.description));
      return rejectWithValue(error.response.data);
    }
  }
);
