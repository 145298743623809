export const GET_BILL_ADJUSTMENT_LOADING = "GET_BILL_ADJUSTMENT_LOADING";
export const GET_BILL_ADJUSTMENT_ERROR = "GET_BILL_ADJUSTMENT_ERROR";
export const GET_BILL_ADJUSTMENT_SUCCESS = "GET_BILL_ADJUSTMENT_SUCCESS";

export const POST_BILL_ADJUSTMENT_LOADING = "POST_BILL_ADJUSTMENT_LOADING";
export const POST_BILL_ADJUSTMENT_ERROR = "POST_BILL_ADJUSTMENT_ERROR";
export const POST_BILL_ADJUSTMENT_SUCCESS = "POST_BILL_ADJUSTMENT_SUCCESS";

export interface GetBillAdjustmentLoading {
    type: typeof GET_BILL_ADJUSTMENT_LOADING;
}

export interface GetBillAdjustmentError {
    type: typeof GET_BILL_ADJUSTMENT_ERROR;
}

export interface GetBillAdjustmentSuccess {
    type: typeof GET_BILL_ADJUSTMENT_SUCCESS;
    payload: any[];
}

export interface PostBillAdjustmentLoading {
    type: typeof POST_BILL_ADJUSTMENT_LOADING;
}

export interface PostBillAdjustmentError {
    type: typeof POST_BILL_ADJUSTMENT_ERROR;
    payload: any;
}

export interface PostBillAdjustmentSuccess {
    type: typeof POST_BILL_ADJUSTMENT_SUCCESS;
}

//===============================<END>Apply Fees to Student<END>======================//

export type BillAdjustmentDispatchTypes =
    | GetBillAdjustmentLoading
    | GetBillAdjustmentError
    | GetBillAdjustmentSuccess
    | PostBillAdjustmentLoading
    | PostBillAdjustmentError
    | PostBillAdjustmentSuccess
