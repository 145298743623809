// ---------------------------- <START> module imports start ----------------------------------//
import React, { useEffect, useState, useRef } from 'react';
import {
  TableBody,
  TextField,
  Grid,
  Typography,
  Paper,
  Checkbox,
  Button,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import { useTableStyles } from '../../Styles/TableStyles';
import TableActionButtons from '../../Reusable/Buttons/TableActionButtons';
import cx from 'clsx';
import ReactToPrint from 'react-to-print';
import Dialog from '@material-ui/core/Dialog';
import { useTable } from '../../Reusable';
import ActionButton from '../../Reusable/Buttons/ActionButton';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import { GetStudentByID } from '../../../actions/Student/Student/StudentAction';
import { ResultTypeI } from '../../../actions/Examination/Result/ResultActionTypes';
import ReportCardPopup1 from '../Marksheet/ReportCardPopup1';
import { GetGrades } from '../../../actions/Examination/Grade/GradeAction';
import {
  GetMarkSheet,
  GetMultipleMarkSheet,
} from '../../../actions/Examination/MarkSheet/MarkSheetAction';
import MultipleMarksheetPopup from './MultipleMarksheetPopup';
import {
  GetMarksheetTemplate,
  GetSingleMarksheetTemplate,
} from '../../../actions/Templates/Marksheet/MarksheetTemplateAction';
import { stubFalse } from 'lodash';
// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//
interface PropsI {
  searchTitle: SearchTitleI | null;
}

export interface SearchTitleI {
  exam_info: string;
  class: string;
  section: string;
  grade: string;
  section_id: string;
}

interface HeadCellsI {
  id: string;
  label: string;
}
// ---------------------------- <END> interface ends ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: 'student_name', label: 'Student Name' },
  { id: 'roll_no', label: 'Roll No.' },
  { id: 'symbol', label: 'Symbol' },
  { id: 'percentage', label: 'Percentage %' },
  { id: 'position', label: 'Position' },
  { id: 'action', label: 'Action' },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const MarksheetTable = (props: any) => {
  const { searchTitle } = props;
  const classes = useTableStyles();
  const [selected, setSelected] = useState<string[]>([]);
  const [multipleMarksheet, setMultipleMarksheet] = useState<any>([]);

  // States declaration
  const [tableData, setTableData] = useState<ResultTypeI[]>([]);
  const [tempTableData, setTempTableData] = useState<ResultTypeI[]>([]);

  const [totalRecord, setTotalRecord] = useState(tempTableData.length);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(
    Math.ceil(totalRecord / rowsPerPage)
  );
  const [page, setPage] = useState(1);

  const [recordFrom, setRecordFrom] = useState(1);
  const [recordTo, setRecordTo] = useState(4);

  const [open, setOpen] = useState(false);
  const [openGenerate, setOpenGenerate] = useState(false);
  const [studentName, setStudentName] = useState<string>('');
  const [activeTemplate, setActiveTemplate] = useState<any>(null);
  const [templateData, setTemplateData] = useState<any>();

  const [gpa, setGpa] = useState<number>(0);

  const componentRef = useRef<any>();

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
    setRecordFrom(newPage === 1 ? newPage : (newPage - 1) * rowsPerPage + 1);
    setRecordTo(newPage === 1 ? newPage * rowsPerPage : newPage * rowsPerPage);
  };

  // Retrieving re-usable components from useTable
  const {
    TblContainer,
    TblHead,
    StyledTableCell,
    StyledTableRow,
    exportToExcel,
    tablePrint,
  } = useTable(headCells);

  //= ===============================<START> REACT HOOKS<START>==============================//
  const dispatch = useDispatch();
  const resultSelector = useSelector((state: RootStore) => state.result);
  const multipleMarksheetState = useSelector(
    (state: RootStore) => state.marksheet.multiple_marksheet
  );
  const marksheet_templates = useSelector(
    (state: RootStore) => state.marksheet_templates?.marksheet_templates
  );
  const marksheet_template = useSelector(
    (state: RootStore) => state.marksheet_templates?.marksheet_template
  );

  useEffect(() => {
    dispatch(GetMarksheetTemplate());
  }, []);

  useEffect(() => {
    if (marksheet_templates != null) {
      setActiveTemplate(marksheet_templates.find((item: any) => item.active));
    }
  }, [marksheet_templates]);

  useEffect(() => {
    if (searchTitle != null && multipleMarksheetState != null) {
      setMultipleMarksheet(multipleMarksheetState);
    }
  }, [multipleMarksheetState]);

  useEffect(() => {
    if (marksheet_template != null) {
      setTemplateData(marksheet_template);
    }
  }, [marksheet_template]);

  useEffect(() => {
    if (searchTitle) {
      dispatch(GetGrades());
      const result = resultSelector.result;
      setTableData(result);
      setTempTableData(result);

      setTotalRecord(result.length);
      setPageCount(Math.ceil(result.length / rowsPerPage));
    }
  }, [resultSelector]);
  //= ===============================<END> REACT HOOKS<END>==============================//

  //= ===============================<START> EVENT HANDLERS<START>==============================//

  // Event function for table searching by name
  const handleTableSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const search_data = tempTableData.filter((data) =>
      data.student_first_name
        .toUpperCase()
        .includes(e.target.value.toUpperCase())
    );
    setTotalRecord(search_data.length);
    setPageCount(Math.ceil(search_data.length / rowsPerPage));
    setPage(1);
    setRecordFrom(1);
    setRecordTo(rowsPerPage);
    setTableData(search_data);
  };
  const handleActionClicked = () => {
    alert('Action Clicked!');
  };

  const handleSelectAllClicked = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      const newSelecteds = tableData.map((n) => n.student_academic);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRowClicked = (item_id: string) => {
    const selectedIndex = selected.indexOf(item_id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item_id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  useEffect(() => {
    if (searchTitle != null) {
      const params = {
        exam_info: searchTitle.exam_info?.id,
        student_academics: selected,
      };
      dispatch(GetMultipleMarkSheet(params));
    }
  }, [selected]);

  const handleClickOpen = (
    gpa: number,
    student_id: string,
    student_academic: string,
    student_name: string
  ) => {
    if (activeTemplate != null) {
      dispatch(GetSingleMarksheetTemplate(activeTemplate?.id));
      setGpa(gpa);
      dispatch(GetStudentByID(student_id));
      setStudentName(student_name);

      if (searchTitle != null) {
        dispatch(GetMarkSheet(student_academic, searchTitle.exam_info?.id));
      }
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseGenerate = () => {
    setOpenGenerate(false);
  };

  //= ===============================<END> EVENT HANDLERS<END>==============================//

  // method to check if a row should be checked
  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const renderSwitch = (param: string) => {
    switch (param) {
      case 'ABC-001':
        return (
          <ReportCardPopup1
            handlleSubmit={null}
            editor={false}
            onClose={null}
            student=""
            content={activeTemplate}
            examInfo={searchTitle?.exam_info}
          />
        );
      default:
        return 'Nothing Found';
    }
  };

  const renderSwitchMultiple = (param: string) => {
    switch (param) {
      case 'ABC-001':
        return (
          <MultipleMarksheetPopup
            multipleMarksheet={multipleMarksheet}
            content={activeTemplate}
            examInfo={searchTitle?.exam_info}
          />
        );
      default:
        return 'Nothing Found';
    }
  };

  const handleOpenGenerate = () => {
    setOpenGenerate(true);
    dispatch(GetSingleMarksheetTemplate(activeTemplate?.id));
  };

  return (
    <>
      <Paper className={classes.rootTableBox}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">
              {searchTitle
                ? `Marksheet For (${searchTitle.class} ${searchTitle.section})`
                : null}
            </Typography>
          </Grid>
        </Grid>
        <div className={classes.tableWrapper}>
          <div className={classes.tableTop}>
            <TextField
              variant="outlined"
              name="search"
              placeholder="Search by Name ..."
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleTableSearch(e)
              }
            />

            <TableActionButtons
              exportToExcel={exportToExcel}
              tablePrint={tablePrint}
            />
          </div>
          <TblContainer>
            <TblHead
              hasCheckbox
              rowCount={tableData.length}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClicked}
            />
            <TableBody>
              {tableData
                .slice(
                  (page - 1) * rowsPerPage,
                  (page - 1) * rowsPerPage + rowsPerPage
                )
                .map((item: ResultTypeI, index: number) => {
                  const isItemSelected = isSelected(item.student_academic);
                  const cellStyle = cx(classes.cell, classes.cellSm);

                  return (
                    <StyledTableRow key={item.student_academic}>
                      <StyledTableCell className={cellStyle}>
                        <Checkbox
                          color="default"
                          checked={isItemSelected}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => handleRowClicked(item.student_academic)}
                        />
                        {item.student_first_name} {item.student_last_name}
                      </StyledTableCell>
                      <StyledTableCell align="center" className={cellStyle}>
                        {item.student_roll_number}
                      </StyledTableCell>
                      <StyledTableCell align="center" className={cellStyle}>
                        {'Symbol'}
                      </StyledTableCell>
                      <StyledTableCell align="center" className={cellStyle}>
                        {item.obtain_percentage}
                      </StyledTableCell>
                      <StyledTableCell align="center" className={cellStyle}>
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell align="center" className={cellStyle}>
                        <ActionButton
                          title={
                            activeTemplate != null
                              ? 'View'
                              : 'First activate one of the template'
                          }
                          onClick={(
                            event: React.MouseEvent<
                              HTMLAnchorElement,
                              MouseEvent
                            >
                          ) =>
                            handleClickOpen(
                              item.obtain_gpa,
                              item.student_id,
                              item.student_academic,
                              `${item.student_first_name} ${item.student_last_name}`
                            )
                          }
                        >
                          <VisibilityIcon className={classes.addIcon} />
                        </ActionButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </TblContainer>
          <div className={classes.tableBottom}>
            {totalRecord > 0 ? (
              <Typography>
                Showing {recordFrom} to{' '}
                {recordTo > totalRecord ? totalRecord : recordTo} of{' '}
                {totalRecord}
              </Typography>
            ) : (
              <Typography>No records found</Typography>
            )}
            <div className={classes.pageNavigation}>
              <Pagination
                count={pageCount}
                page={page}
                onChange={handlePageChange}
                shape="rounded"
                color="primary"
              />
            </div>
          </div>
          <Grid container justifyContent="flex-end">
            <Button
              style={{
                marginTop: '16px',
                width: '120px',
                padding: '8px 0px',
              }}
              variant="contained"
              color="primary"
              startIcon={<PrintOutlinedIcon />}
              disabled={selected?.length ? false : true}
              onClick={handleOpenGenerate}
            >
              {activeTemplate != null ? 'Generate' : 'Generate( not activated)'}
            </Button>
            {/* <Button
              style={{ marginTop: "16px", width: "120px", padding: "8px 0px" }}
              variant="contained"
              color="primary"
              startIcon={<PrintOutlinedIcon />}
              onClick={() => alert(selected)}
            >
              Generate
            </Button> */}
            {/* <ReactToPrint
              trigger={() => (
                <Button
                  style={{
                    marginTop: "16px",
                    width: "120px",
                    padding: "8px 0px",
                  }}
                  variant="contained"
                  color="primary"
                  startIcon={<PrintOutlinedIcon />}
                >
                  Generate
                </Button>
              )}
              content={() => componentRef.current}
              removeAfterPrint={true}
              copyStyles={true}
            /> */}
          </Grid>
          {/* <MultipleMarksheet
            ref={componentRef}
            multipleMarksheet={multipleMarksheet}
          /> */}
        </div>
      </Paper>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
        // className={classes.modalBox}
      >
        {/* <ReportCardPopup1
          onClose={handleClose}
          // onClose={handleClose}
          student={studentName}
          editor={false}
          // gpa={gpa}
        /> */}
        {renderSwitch(activeTemplate?.code)}
      </Dialog>
      <Dialog
        open={openGenerate}
        onClose={handleCloseGenerate}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
        // className={classes.modalBox}
      >
        {renderSwitchMultiple(activeTemplate?.code)}
      </Dialog>
    </>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default MarksheetTable;
