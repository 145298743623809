import {
  Button,
  Chip,
  FormControl,
  InputLabel,
  List,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import { DayCellPropsI } from './NepaliCalendar';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Switch from '@material-ui/core/Switch';
import WifiIcon from '@material-ui/icons/Wifi';

export const StyledContainer = styled(Paper)`
  box-shadow: none !important;
  /* padding: 24px;
  margin: 120px 24px 24px 24px; */
`;

export const StyledTableContainer = styled(TableContainer)`
  margin-top: 8px;
`;

export const StyledTable = styled(Table)``;
export const StyledTableHead = styled(TableHead)``;
export const StyledTableBody = styled(TableBody)``;
export const StyledTableRow = styled(TableRow)``;

export const StyledTableCell = styled(TableCell)`
  position: relative;
  table-layout: fixed;
  width: 200px;
  overflow: hidden;
  word-wrap: break-word;

  &.MuiTableCell-root {
    border: 1px solid rgb(224, 224, 224);
    padding: 8px 8px 6px 8px !important;
    text-align: center;
    width: 105px !important;
    height: 95px !important;
    overflow: hidden;
  }

  &.MuiTableCell-root:last-child {
    color: red;
  }

  &.MuiTableCell-head {
    height: auto !important;
    font-size: 16px !important;
  }

  &.MuiTableCell-body {
    font-size: 18px !important;
    font-weight: 600;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
      cursor: pointer;
    }
  }

  &.MuiTableCell-head {
    font-size: 16px;
  }
`;

export const StyledDayCell = styled(({ holiday, current, ...props }) => (
  <StyledTableCell {...props} />
))<DayCellPropsI>`
  &.MuiTableCell-body,
  &.MuiTableCell-body:hover {
    ${({ holiday }) => holiday === true && `color: red;`}
    ${({ current }) =>
      current === true && `color: white!important;background: #ff5722`}
  }
`;

export const StyledFormControl = styled(FormControl)``;
export const StyledInputLabel = styled(InputLabel)``;
export const StyledSelect = styled(Select)``;
export const StyledMenuItem = styled(MenuItem)``;

export const StyledDaySubTitle = styled(Typography)`
  font-size: 12px;
`;
export const StyledButton = styled(Button)``;
export const StyledChip = styled(Chip)`
  position: absolute;
  top: 50%;
  right: 6px;
  transform: translate(0%, -50%);
`;

export const StyledList = styled(List)``;
export const StyledListItem = styled(ListItem)``;
export const StyledListItemIcon = styled(ListItemIcon)``;
export const StyledListItemSecondaryAction = styled(ListItemSecondaryAction)``;
export const StyledListItemText = styled(ListItemText)``;
export const StyledListSubheader = styled(ListSubheader)``;
export const StyledSwitch = styled(Switch)``;
