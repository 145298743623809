import {
  Grid,
  Paper,
  Checkbox,
  IconButton,
  TableRow,
  TableCell,
  Collapse,
  Typography,
  Table,
  TableHead,
  TableBody,
  Box,
  makeStyles,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import cx from 'clsx';
import { useCheckBoxTable, useTable } from '../../Reusable';
import { useTableStyles } from '../../Styles/TableStyles';
import { SearchedStudentsI } from '../../../actions/Student/Student/StudentActionTypes';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import ScholarshipSearchForm from './ScholarshipSearchForm';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ScholarshipListSelect from './ScholarshipListSelect';
import FeeCategorySelect from './FeeCategorySelect';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Link } from 'react-router-dom';
import AddNewButton from '../../Reusable/Buttons/AddNewButton';
import { Add } from '@material-ui/icons';
import { ItemDeleteButton } from '../../Reusable/Buttons/TableButton';
import getGradeFeeAmount from '../../utils/getGradeFeeAmount';
import AppliedScholarshipsForStudent from './AppliedScholarshipsForStudent';

interface HeadCellsI {
  id: string;
  label: string;
}

interface PropsI {
  onStudentChange: (student_ids: string[]) => void;
  selectedStudent: Array<string>;
  grade: string | null;
  section: string | null;
  setSelectedStudent: (value: any) => void;
  setGrade: (value: any) => void;
  setSection: (value: any) => void;
  handleApplyFees: () => void;
  handleFeeChange: (value: any) => void;
  setFeesScholarship: (value: any) => void;
  setSelected: (value: any) => void;
  setFeeHeads: (value: any) => void;
  feeHeads: string;
  months: Array<string>;
  setMonths: (value: any) => void;
  selected: Array<string>;
  isBtnLoading: boolean;
  setIsBtnLoading: (value: boolean) => void;
}

const headCells: HeadCellsI[] = [
  // { id: "index", label: "S.N" },
  { id: 'student', label: 'Student Name' },
  // { id: "scholorship_head", label: "Count" },
  // { id: "total_fee_before", label: "Fee Before" },
  { id: 'discount', label: 'Discount' },
  { id: 'total_fee_after', label: 'Fee Amount' },
  { id: '', label: '' },
];

const useStyles = makeStyles((theme: any) => ({
  table: {
    borderTop: '1px solid #D6D6D6',
    marginTop: '12px',
    '& thead th': {
      fontSize: '13px !important',
      fontWeight: '600',
      color: '#4D4F5C',
      backgroundColor: '#e4e3e3',
      padding: '12px 15px',
      whiteSpace: 'break-spaces',
    },
    '& tbody td': {
      fontSize: '13px !important',
      fontWeight: '400',
      padding: '5px 5px 5px 5px',
      color: '#6C6D6E',
    },
    '& tbody tr:hover': {
      fontSize: '13px !important',
      backgroundColor: '#fffbf2',
      cursor: 'pointer',
    },
  },
  cell: {
    borderRight: '1px solid #D6D6D6',
  },
  headCell: {
    paddingLeft: '5px!important',
  },
}));

const ScholarshipStudentTable = (props: PropsI) => {
  const {
    onStudentChange,
    handleApplyFees,
    selectedStudent,
    setSelectedStudent,
    grade,
    section,
    setGrade,
    setSection,
    selected,
    setSelected,
    handleFeeChange,
    setFeesScholarship,
    setFeeHeads,
    feeHeads,
    setMonths,
    months,
    isBtnLoading,
    setIsBtnLoading,
  } = props;

  const classes = useTableStyles();
  const tblClasses = useStyles();
  const [tableData, setTableData] = useState<any[]>([]);
  // const [fees, setFees] = useState<any[]>([]);

  const studentSelector = useSelector(
    (state: RootStore) => state.scholarship.scholarship_students
  );

  const applyFeeAction = useSelector(
    (state: RootStore) => state.fee.actionPerformed
  );

  useEffect(() => {
    if (applyFeeAction) {
      setSelectedStudent([]);
    }
  }, [applyFeeAction]);

  useEffect(() => {
    setTableData(studentSelector?.results);
  }, [studentSelector]);

  useEffect(() => {
    onStudentChange(selectedStudent);
  }, [selectedStudent]);

  const handleFeeCatChange = (fees: any[]) => {
    // setFeesScholarship([...fees]);
  };

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useCheckBoxTable(headCells);
  const { TblContainer } = useTable(headCells);

  const handleCheckClick = (name: string) => {
    const selectedIndex = selectedStudent.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedStudent, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedStudent.slice(1));
    } else if (selectedIndex === selectedStudent.length - 1) {
      newSelected = newSelected.concat(selectedStudent.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedStudent.slice(0, selectedIndex),
        selectedStudent.slice(selectedIndex + 1)
      );
    }
    setSelectedStudent(newSelected);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = tableData.map((n) => n.id);
      setSelectedStudent(newSelecteds);
      return;
    }
    setSelectedStudent([]);
  };

  const isSelected = (id: string) => selectedStudent.indexOf(id) !== -1;

  const getCustomTableRow = (row: any, index: number) => {
    const isItemSelected = isSelected(row.id);
    const labelId = `enhanced-table-checkbox-${index}`;
    const cellStyle = cx(classes.cell, classes.cellSm);

    const [open, setOpen] = React.useState(false);

    return (
      <>
        <StyledTableRow
          hover
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={row.academic_id}
          selected={isItemSelected}
        >
          <StyledTableCell className={cellStyle} align="left">
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <Checkbox
                  color="default"
                  checked={isItemSelected}
                  inputProps={{ 'aria-labelledby': labelId }}
                  onClick={() => handleCheckClick(row.id)}
                />
              </Grid>
              <Grid item>
                {row.student?.student_user?.first_name}{' '}
                {row.student?.student_user?.last_name}
              </Grid>
            </Grid>
          </StyledTableCell>
          <StyledTableCell
            className={cellStyle}
            align="center"
          ></StyledTableCell>
          <StyledTableCell
            className={cellStyle}
            align="center"
          ></StyledTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <AppliedScholarshipsForStudent
                data={row.scholarship_applicable_student}
              />
            </Box>
          </Collapse>
        </TableCell>
      </>
    );
  };
  return (
    <Paper
      className={classes.rootCheckBoxTable}
      style={{ marginRight: '5px', marginLeft: '5px' }}
    >
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <ScholarshipSearchForm
            grade={grade}
            setGrade={setGrade}
            section={section}
            setSection={setSection}
            handleApplyFees={handleApplyFees}
            isBtnLoading={isBtnLoading}
            setIsBtnLoading={setIsBtnLoading}
          />
        </Grid>
        <Grid container justifyContent="center">
          <Grid item xs={6}>
            <div style={{ height: '500px', overflowY: 'auto' }}>
              <TableContainer
                hasCheckbox
                handleSelectAllClicked={handleSelectAllClick}
                rowCount={tableData?.length}
                numSelected={selectedStudent?.length}
                loading={false}
                items={grade && tableData}
                headCells={headCells}
                getCustomTableRow={getCustomTableRow}
                hiddenCols={[-1]}
                disablePagination
                disablePrint
                disableSearch
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <FeeCategorySelect
              grade={grade}
              section={section}
              onFeeChange={handleFeeCatChange}
              setFeeHeads={setFeeHeads}
              feeHeads={feeHeads}
              months={months}
              setMonths={setMonths}
            />
          </Grid>
          <Grid item xs={3}>
            <ScholarshipListSelect
              selected={selected}
              setSelected={setSelected}
              onFeeChange={handleFeeChange}
              grade={grade}
              section={section}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ScholarshipStudentTable;
