import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, CardMedia, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { RootStore } from '../../../../store';
import { useIDCardStyles } from '../IDCardStyles';
import checkActiveSignature from '../../../utils/checkActiveSignature';
import checkImageUrl from '../../../utils/checkImageUrl';
import { GetReportSetting } from '../../../../actions/GeneralSettings/ReportSetting/ReportSettingAction';

interface InfoPropsI {
  value: string | number;
  properties: string;
}

const dummyData = {
  photo:
    'https://upload.wikimedia.org/wikipedia/commons/thumb/7/76/MiB.svg/1200px-MiB.svg.png',

  full_name: 'KK Adhikari',
  dob: '2044-04-05',
  blood_group: 'A+',
  address: 'New Baneshwor',
  phone_number: '9811050526',
  designation: 'Head of HR',
  date_of_joining: '2078-02-08',
};

export const StudentInfo = (props: InfoPropsI) => {
  const { value, properties } = props;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '2px 10px',
      }}
    >
      <Box fontSize={14} fontWeight="fontWeightRegular" textAlign="center">
        {properties}
      </Box>
      <Box fontSize={14} fontWeight="fontWeightRegular" textAlign="center">
        {value}
      </Box>
    </div>
  );
};

const LandScapeTeacherCard = (props: any) => {
  const { data } = props;
  console.log({ data });
  const dispatch = useDispatch();

  const classes = useIDCardStyles();
  const [item, setItem] = useState<any>(null);
  const [url, setUrl] = useState<string>('');

  const general_info = useSelector(
    (state: RootStore) => state.auth.general_info
  );

  const report_setting = useSelector(
    (state: RootStore) => state.report_setting?.reportsetting
  );

  useEffect(() => {
    const n = window.location.href.lastIndexOf('/');
    setUrl(window.location.href.substring(n + 1));
    dispatch(GetReportSetting());
  }, []);

  useEffect(() => {
    props.content != null && setItem(props.content);
  }, [props.content]);

  const renderValue = (real: any, fake: any) => {
    console.log(real);

    if (url === 'customize' || url === 'update') {
      return fake;
    } else {
      return real;
    }
  };

  return (
    <>
      <Card
        className={classes.root}
        style={{
          backgroundColor: item?.back_ground_color[1].value,
          position: 'relative',
        }}
      >
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          className={clsx(classes.landContent)}
        >
          <Grid item xs={8}>
            <Grid container alignItems="center">
              <Grid style={{ padding: '0 10px' }} item xs={3}>
                <CardMedia
                  className={classes.logoImage}
                  image={general_info?.logo}
                  title=""
                />
              </Grid>
              <Grid item xs={9}>
                <div style={{ marginLeft: '0.35rem' }}>
                  <Box
                    fontSize={18}
                    fontWeight="fontWeightBold"
                    textAlign="center"
                    className={classes.mainTitle}
                    style={{ color: item?.text_color[0].value }}
                  >
                    {general_info?.school_name}
                  </Box>
                  {/* <Box
                    lineHeight={2}
                    fontSize={12}
                    fontWeight="fontWeightRegular"
                    textAlign="center"
                  >
                    Dhumbarahi, kathmandu, Nepal, +977-01-4412323
                  </Box> */}
                </div>
              </Grid>
            </Grid>
            <div
              style={{
                color: item?.text_color[2].value,
              }}
            >
              <CardContent className={clsx(classes.content, classes.column)}>
                {/* <div
                  className={clsx(classes.cardBg, classes.center)}
                  style={{
                    backgroundImage:
                      "url(https://upload.wikimedia.org/wikipedia/commons/thumb/f/f8/Bavarian_Intl_School_Logo.png/1200px-Bavarian_Intl_School_Logo.png)",
                  }}
                ></div> */}
                <StudentInfo
                  properties="Full Name:"
                  value={renderValue(data?.full_name, dummyData?.full_name)}
                />
                <StudentInfo
                  properties="Address:"
                  value={renderValue(data?.address, dummyData?.address)}
                />
                <StudentInfo
                  properties="Date of Joining:"
                  value={renderValue(
                    data?.date_of_joining,
                    dummyData?.date_of_joining
                  )}
                />
                <StudentInfo
                  properties="Blood Group:"
                  value={renderValue(data?.blood_group, dummyData?.blood_group)}
                />
                <StudentInfo
                  properties="DOB"
                  value={renderValue(data?.dob, dummyData?.dob)}
                />
                <StudentInfo
                  properties="Phone Number"
                  value={renderValue(
                    data?.phone_number,
                    dummyData?.phone_number
                  )}
                />
                {/* <StudentInfo
                  properties="Signature"
                  value={checkActiveSignature(report_setting)}
                /> */}
              </CardContent>
            </div>
          </Grid>
          <Grid container justifyContent="center" item xs={4}>
            <Box
              className={classes.title}
              fontSize={24}
              fontWeight="fontWeightBold"
              textAlign="center"
              mb={1}
              style={{
                backgroundColor: item?.back_ground_color[2].value,
                color: item?.text_color[3].value,
              }}
            >
              {renderValue(data?.designation, dummyData?.designation)}
            </Box>
            <CardMedia
              className={classes.landIdImage}
              image={renderValue(checkImageUrl(data?.photo), dummyData?.photo)}
            />
            {/* <Box
              fontSize={12}
              fontWeight="fontWeightRegular"
              textAlign="center"
              mt={1}
              style={{
                color: item?.text_color[2].value,
              }}
            >
              Employee Id: 1234
            </Box> */}
          </Grid>
        </Grid>
        <div
          className={clsx(classes.bgColor, classes.footer)}
          style={{
            backgroundColor: item?.back_ground_color[0].value,
          }}
        >
          <Box
            fontSize={12}
            fontWeight="fontWeightRegular"
            textAlign="center"
            style={{
              color: item?.text_color[1].value,
            }}
          >
            {general_info?.slogan}
          </Box>
        </div>
      </Card>
    </>
  );
};

export default LandScapeTeacherCard;
