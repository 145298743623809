import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_ANNOUNCEMENT_TYPE_LOADING,
  GET_ANNOUNCEMENT_TYPE_ERROR,
  GET_ANNOUNCEMENT_TYPE_SUCCESS,
  AnnouncementTypeDispatchTypes,
  ADD_ANNOUNCEMENT_TYPE_LOADING,
  ADD_ANNOUNCEMENT_TYPE_SUCCESS,
  ADD_ANNOUNCEMENT_TYPE_ERROR,
  DELETE_ANNOUNCEMENT_TYPE_SUCCESS,
  DELETE_ANNOUNCEMENT_TYPE_ERROR,
  DELETE_ANNOUNCEMENT_TYPE_LOADING,
  UPDATE_ANNOUNCEMENT_TYPE_LOADING,
  UPDATE_ANNOUNCEMENT_TYPE_SUCCESS,
  UPDATE_ANNOUNCEMENT_TYPE_ERROR,
} from "./AnnouncementTypeActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

interface RequestDataI {
  name: string;
}

export const GetAnnouncementTypes =
  () =>
  async (dispatch: Dispatch<AnnouncementTypeDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_ANNOUNCEMENT_TYPE_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/announcement_category/`,
        HeaderConfig()
      );

      dispatch({
        type: GET_ANNOUNCEMENT_TYPE_SUCCESS,
        payload: res.data,
      });
    } catch (error: any) {
      dispatch({
        type: GET_ANNOUNCEMENT_TYPE_ERROR,
      });
      try {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: error.response.statusText,
          },
        });
      } catch (error) {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Server Error",
          },
        });
      }
    }
  };

export const AddAnnouncementType =
  (data: RequestDataI) =>
  async (dispatch: Dispatch<AnnouncementTypeDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_ANNOUNCEMENT_TYPE_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/announcement_category/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_ANNOUNCEMENT_TYPE_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Announcement Category Added Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: ADD_ANNOUNCEMENT_TYPE_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Announcement Category Add Failed",
        },
      });
    }
  };

export const UpdateAnnouncementType =
  (id: string, data: RequestDataI) =>
  async (dispatch: Dispatch<AnnouncementTypeDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_ANNOUNCEMENT_TYPE_LOADING,
      });

      const res = await axios.put(
        `${ADMIN_API_URL}/announcement_category/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_ANNOUNCEMENT_TYPE_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Announcement Category Updated Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: UPDATE_ANNOUNCEMENT_TYPE_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Announcement Category Update Failed",
        },
      });
    }
  };

export const DeleteAnnouncementType =
  (id: string) =>
  async (dispatch: Dispatch<AnnouncementTypeDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_ANNOUNCEMENT_TYPE_LOADING,
      });

      const res = await axios.delete(
        `${ADMIN_API_URL}/announcement_category/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_ANNOUNCEMENT_TYPE_SUCCESS,
        payload: { id: id },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Announcement Category Deleted Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_ANNOUNCEMENT_TYPE_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Announcement Category Delete Failed",
        },
      });
    }
  };
