import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootStore } from "../../../store";
import BackDropLoader from "../../Reusable/BackDropLoader";
import DiscountSelection from "./DiscountSelection";
import FeeDiscount from "./FeeDiscount";
import StudentDiscount from "./StudentDiscount";

const ApplyDiscount = () => {
  const [applyDiscountTo, setApplyDiscountTo] = useState<string>("");

  const FeeDiscountLoading = useSelector(
    (state: RootStore) => state.feeDiscount.loading
  );
  const ClassLoading = useSelector((state: RootStore) => state.class.loading);
  const CategoryLoading = useSelector(
    (state: RootStore) => state.categories.loading
  );
  const StudentLoading = useSelector(
    (state: RootStore) => state.wholeData.loading
  );
  const FeeLoading = useSelector((state: RootStore) => state.fee_type.loading);
  return (
    <>
      <DiscountSelection
        applyDiscountTo={(value) => setApplyDiscountTo(value)}
      />
      {applyDiscountTo === "fee" ? <FeeDiscount /> : <StudentDiscount />}
      <BackDropLoader
        open={
          FeeDiscountLoading ||
          ClassLoading ||
          CategoryLoading ||
          StudentLoading ||
          FeeLoading
        }
      />
    </>
  );
};

export default ApplyDiscount;
