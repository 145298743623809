import React, { useEffect, useState } from 'react';
import {
  TableBody,
  TextField,
  Grid,
  Typography,
  Paper,
  Chip,
  LinearProgress,
} from '@material-ui/core';
import { DeleteForeverOutlined } from '@material-ui/icons';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { useTable } from '../../Reusable';
import { useTableStyles } from '../../Styles/TableStyles';
import StudentMappingSearch from './StudentMappingSearch';
import ActionButton from '../../Reusable/Buttons/ActionButton';
import { AnnouncementTypeI } from '../../../actions/Communication/Announcement/AnnouncementActionTypes';
import { DeleteAnnouncement } from '../../../actions/Communication/Announcement/AnnouncementAction';
import { useDispatch } from 'react-redux';
import CustomizedDialogs from '../../Reusable/Dialogs/DeleteDialog';
import { Pagination } from '@material-ui/lab';
import AccessPermission from '../../Student/StudentDetail/AccessPermission';

export interface DataI {
  data: AnnouncementTypeI;
  message_to: React.ReactNode;
}

interface HeadCellsI {
  id: string;
  label: string;
}

const headCells1: HeadCellsI[] = [
  { id: 'studentId', label: 'Student ID' },
  { id: 'name', label: 'Student Name' },
  { id: 'location', label: 'Location' },
  { id: 'discount', label: 'Discount' },
  { id: 'isOneWay', label: 'Is One Way' },
  { id: 'bus', label: 'Bus' },
  { id: 'active', label: 'Active' },
  { id: 'action', label: 'Action' },
];

const tempData1 = [
  {
    id: '456123789',
    name: 'John Doe',
    location: 'Baneshwor',
    discount: 100,
    isOneWay: true,
    bus: 'BUS456',
    active: true,
  },
  {
    id: '556123789',
    name: 'John Thapa',
    location: 'Kalanki',
    discount: 50,
    isOneWay: false,
    bus: 'BUS456',
    active: false,
  },
  {
    id: '656123789',
    name: 'John Doe',
    location: 'Baneshwor',
    discount: 100,
    isOneWay: true,
    bus: 'BUS456',
    active: true,
  },
  {
    id: '756123789',
    name: 'John Thapa',
    location: 'Kalanki',
    discount: 50,
    isOneWay: false,
    bus: 'BUS456',
    active: false,
  },
];

const StudentMappingList: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useTableStyles();

  const [tableData, setTableData] = useState<DataI[]>([]);
  const [tempTableData, setTempTableData] = useState<DataI[]>([]);
  const [editData, setEditData] = useState<AnnouncementTypeI | null>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<number | null>(null);
  const [item, setItem] = useState<DataI | null>(null);
  const [totalRecord, setTotalRecord] = useState(tempTableData.length);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(
    Math.ceil(totalRecord / rowsPerPage)
  );
  const [page, setPage] = useState(1);
  const [recordFrom, setRecordFrom] = useState(1);
  const [recordTo, setRecordTo] = useState(rowsPerPage);
  const [loading, setLoading] = useState<boolean>(false);

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
    setRecordFrom(newPage === 1 ? newPage : (newPage - 1) * rowsPerPage + 1);
    setRecordTo(newPage === 1 ? newPage * rowsPerPage : newPage * rowsPerPage);
  };

  const {
    TblContainer,
    TblHead,
    StyledTableCell,
    StyledTableRow,
    exportToExcel,
    tablePrint,
  } = useTable(headCells1);

  const handleTableSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const search_data = tempTableData.filter((data) =>
      data.data.title.toUpperCase().includes(e.target.value.toUpperCase())
    );
    setTableData(search_data);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const handleDeleteModal = (value: boolean, id: number) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteAnnouncement(itemId));
    setDeleteModalOpen(false);
  };

  const handleViewModal = (item: DataI) => {
    setItem(item);
    setItemId(item.data.id);
  };

  return (
    <>
      <StudentMappingSearch />

      <Paper className={classes.rootTableBox}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">Student Mapping List</Typography>
          </Grid>
        </Grid>

        <div className={classes.tableWrapper}>
          <div className={classes.tableTop}>
            <TextField
              autoComplete="off"
              autoFocus={false}
              // focused={false}
              variant="outlined"
              name="search"
              placeholder="Search..."
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleTableSearch(e)
              }
            />
          </div>
          <TblContainer>
            <TblHead />
            <TableBody>
              {tempData1
                .slice(
                  (page - 1) * rowsPerPage,
                  (page - 1) * rowsPerPage + rowsPerPage
                )
                .map((item: any, index: number) => (
                  <StyledTableRow key={item.id}>
                    <StyledTableCell align="center" className={classes.cell}>
                      {item.id}
                    </StyledTableCell>
                    <StyledTableCell align="center" className={classes.cell}>
                      {item.name}
                    </StyledTableCell>
                    <StyledTableCell align="center" className={classes.cell}>
                      {item.location}
                    </StyledTableCell>
                    <StyledTableCell align="center" className={classes.cell}>
                      {item.discount}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={classes.cell}
                      style={{ textAlign: 'left', paddingLeft: '15px' }}
                    >
                      {item.isOneWay ? 'Yes' : 'No'}
                    </StyledTableCell>
                    <StyledTableCell align="center" className={classes.cell}>
                      {item.bus}
                    </StyledTableCell>
                    <StyledTableCell align="center" className={classes.cell}>
                      {item.active ? 'Yes' : 'No'}
                    </StyledTableCell>
                    <StyledTableCell align="center" className={classes.cell}>
                      <AccessPermission>
                        <ActionButton title="View">
                          <VisibilityOutlinedIcon
                            className={classes.viewIcon}
                          />
                        </ActionButton>
                        <ActionButton
                          title="Delete"
                          onClick={() => {
                            handleDeleteModal(true, item.data.id);
                          }}
                        >
                          <DeleteForeverOutlined
                            className={classes.deleteIcon}
                          />
                        </ActionButton>
                      </AccessPermission>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </TblContainer>
          {loading ? <LinearProgress /> : null}
          <div className={classes.tableBottom}>
            {totalRecord > 0 ? (
              <Typography>
                Showing {recordFrom} to{' '}
                {recordTo > totalRecord ? totalRecord : recordTo} of{' '}
                {totalRecord}
              </Typography>
            ) : (
              <Typography>No records found</Typography>
            )}
            <div className={classes.pageNavigation}>
              <Pagination
                count={pageCount}
                page={page}
                onChange={handlePageChange}
                shape="rounded"
                color="primary"
              />
            </div>
          </div>
        </div>
      </Paper>

      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
      />
    </>
  );
};

export default StudentMappingList;
