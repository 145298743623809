// ---------------------------- <START> module imports start ----------------------------------//
import React, { useEffect, useState } from "react";
import {
  TableBody,
  Grid,
  Typography,
  Paper,
  LinearProgress,
  TablePagination,
} from "@material-ui/core";

import { useTableStyles } from "../../Styles/TableStyles";
import { useTable } from "../../Reusable";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";

import { getSmsLog } from "../../../rtk/features/sms/smsApi";

// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//

interface HeadCellsI {
  id: string;
  label: string;
}
// ---------------------------- <END> interface ends ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N" },
  { id: "phone_number", label: "Phone Number" },
  { id: "message", label: "Message" },
  { id: "status", label: "Status" },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

interface PropsI {
  date: {
    from_date: string;
    to_date: string;
  };
}

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const SentSmsTable: React.FC<PropsI> = ({ date }) => {
  const classes = useTableStyles();
  const dispatch = useDispatch();
  const { loading, sms_log } = useSelector((state: RootStore) => state.sms);
  // States declaration

  // Retrieving re-usable components from useTable
  const { TblContainer, TblHead, StyledTableCell, StyledTableRow } =
    useTable(headCells);

  useEffect(() => {
    dispatch(
      getSmsLog({
        page: sms_log.current_page,
        per_page: sms_log.per_page,
        from_date: date.from_date,
        to_date: date.to_date,
      })
    );
  }, [date]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    dispatch(
      getSmsLog({
        page: newPage + 1,
        per_page: sms_log.per_page,
        from_date: date.from_date,
        to_date: date.to_date,
      })
    );
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(
      getSmsLog({
        page: 1,
        per_page: parseInt(event.target.value, 10),
        from_date: date.from_date,
        to_date: date.to_date,
      })
    );
  };

  return (
    <>
      <Paper className={classes.rootTableBox}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">Sms Report</Typography>
          </Grid>
        </Grid>
        <div className={classes.tableWrapper}>
          <TblContainer>
            <TblHead />
            <TableBody>
              {sms_log.results.map((item, index) => (
                <StyledTableRow key={item.id}>
                  <StyledTableCell align="center" className={classes.cell}>
                    {index + 1}
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.cell}>
                    {item.numbers[0]}
                  </StyledTableCell>

                  <StyledTableCell align="center" className={classes.cell}>
                    {item.context}
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.cell}>
                    {item.status}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </TblContainer>
          {sms_log.results.length ? (
            <TablePagination
              component="div"
              count={sms_log.total}
              page={sms_log.current_page - 1}
              onPageChange={handleChangePage}
              rowsPerPage={sms_log.per_page}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : null}
          {loading ? <LinearProgress /> : null}
        </div>
      </Paper>
    </>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default SentSmsTable;
