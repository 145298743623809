import { Paper, Tab, Tabs } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useCardStyles } from "../../Styles/CardStyles";
import { TabPanel } from "../../Student/StudentProfile/File/NewTabs";
import DepartmentTab from "./DepartmentTab";
import StaffTab from "./StaffsTab";

function AttendanceDeviceTabs(props: any) {
  const classes = useCardStyles();
  const [value, setValue] = React.useState(0);
  const { staffID } = useParams<{ staffID: string }>();
  const [isTeacher, setIsTeacher] = useState<boolean>(false);

  const dispatch = useDispatch();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Paper className={classes.tabRoot}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        // variant="fullWidth"
      >
        <Tab label="Departments" />
        <Tab label="Staffs" />
      </Tabs>

      <TabPanel value={value} index={0}>
        <DepartmentTab />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <StaffTab />
      </TabPanel>
    </Paper>
  );
}

export default AttendanceDeviceTabs;
