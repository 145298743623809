import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  border-radius: 5px;
  padding: 10px;
  background-color: rgb(232, 244, 253);

  p {
    color: rgb(13, 60, 97);
  }
`;

const SmsTips = () => {
  return (
    <Wrapper>
      <p>Tips</p>
      <p>
        1. Don't Use Special Character(like "&" instead of "and") in SMS text
        Like. If you use this message will not be sent after that symbol.
      </p>
      <p>2. You can send SMS in English & Unicode (Nepali) Language.</p>
      <p>
        3. Please format your message on notepad and send one sample message to
        your own number before sending bulk sms.
      </p>
      <p>
        4. SMS Length : <br />
        For English : 1 SMS = 160 Charecters <br />
        For Morethan 1 <br /> N SMS = N x 153 Characters
      </p>
      <p>
        5. For (Nepali) Unicode SMS: <br />1 SMS = 70 chars <br />
        For more than 1 <br />N SMS = N X 67 Characters
      </p>
      <p>
        Note: If one character in sms is unicode, whole sms becomes unicode.
      </p>
    </Wrapper>
  );
};

export default SmsTips;
