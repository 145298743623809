import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateFeeType,
  UpdateFeeType,
} from "../../../actions/Fees/FeeType/FeeTypeAction";
import { MONTH_CHOICES } from "../../../common/json.constant";
import { FormCheckBox } from "../../Reusable/Inputs/Checkbox";
import CustomizedNepaliDatePicker from "../../Reusable/Inputs/NepaliDatePicker";
import CustomizedSelect from "../../Reusable/Inputs/Select";
import CustomizedTextField from "../../Reusable/Inputs/TextField";
import { useFormStyles } from "../../Styles/FormStyles";
import { CUR_NEPALI_DATE } from "../../utils/nepaliDateUtils";
import FormLayout from "../../Reusable/Layouts/Form.Layout";
import { CusRadioGroup } from "../../Reusable/Inputs/Radio";
import { RootStore } from "../../../store";
import { Checkbox, InputLabel, TextField } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { Autocomplete } from "@material-ui/lab";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { dateConverterAdToBs, dateConverterBsToAd } from "../../utils/dateConverter";

interface EditValuesI {
  id: string;
  name: string;
  fee_type: string;
  description: string;
  billing_period: string;
  due_date: string | null;
  due_day: number | null;
  due_period_type: string | null;
  count: number;
}
interface PropsI {
  editData: EditValuesI | null;
  onEditCheck: (value: boolean) => void;
}

interface DaySelectI {
  key: string;
  value: string;
}

const FeeTypeForm = (props: PropsI) => {
  const classes = useFormStyles();
  const { editData, onEditCheck } = props;

  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [editID, setEditID] = useState<string | null>(null);

  const [selectedDate, setSelectedDate] = useState<DaySelectI | null>(null);
  const [date, setDate] = useState<string>(CUR_NEPALI_DATE);
  const [selectedRadioValue, setSelectedRadioValue] = useState<number>(1);
  const [isMonthly, setIsMonthly] = useState<boolean>(false);
  const [roleChoices, setRoleChoices] = useState<any>([]);
  const [selectedRoleValues, setSelectedRoleValues] = useState<any>(
    []
  );
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    errors,
    setError,
    clearErrors,
  } = useForm<any>({
    mode: "onChange",
  });

  const feeTypeActionSelector = useSelector(
    (state: RootStore) => state.fee_type.action_performed
  );

  const authState = useSelector((state: RootStore) => state.auth);
  const add_or_update = useSelector((state: RootStore) => state.fee_type.add_or_update)

  useEffect(() => {
    if (add_or_update) {
      setIsBtnLoading(false)
      handleReset();
    }
  }, [add_or_update])

  useEffect(() => {
    editData != null && handleEditTableData(editData);
  }, [editData]);

  useEffect(() => {
    if (feeTypeActionSelector) {
      handleReset();
    }
  }, [feeTypeActionSelector]);

  const onFormSubmit = (data: any) => {
    setIsBtnLoading(true);
    const ids: string[] = [];

    selectedRoleValues.map((element: any) => {
      ids.push(element.key);
    });

    const post_request = {
      name: data.name,
      description: data.description,
      fee_type: isMonthly ? 'M' : 'O',
      due_date: isMonthly ? null : dateConverterBsToAd(date),
      // due_day: data.due_day,
      due_day: 1,
      due_period_type: "P",
      billing_period: isMonthly ? ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"] : [],
    };

    editID != null
      ? dispatch(UpdateFeeType(editID, post_request))
      : dispatch(CreateFeeType(post_request));
  };

  const DAY_CHOICES = MONTH_CHOICES
  const DUE_DAY_CHOICES = [
    { key: "1", value: "1" },
    { key: "2", value: "2" },
    { key: "3", value: "3" },
    { key: "4", value: "4" },
    { key: "5", value: "5" },
    { key: "6", value: "6" },
    { key: "7", value: "7" },
  ]

  useEffect(() => {
    const data = DAY_CHOICES;
    if (data.length) {
      const mod: any = [];

      data.map((element: any) => {
        mod.push({
          ...element,
          selected: false,
        });
      });

      setRoleChoices(mod);
    }
  }, [DAY_CHOICES]);


  const dueTypeList = [
    { id: 1, name: "Prior" },
    { id: 2, name: "After" },
  ];

  const handleReset = () => {
    reset();
    onEditCheck(false);
    setEditID(null);
    setEditMode(false);
    setIsMonthly(false);
    setSelectedDate(null);
    setSelectedRadioValue(1);
  };

  const handleEditTableData = (data: EditValuesI) => {
    setEditMode(true);
    setEditID(data.id);
    const tempBillPeriod: any = data?.billing_period
    const billPeriod = tempBillPeriod?.join().split(',')

    const selected__months = DAY_CHOICES.filter((elem) => billPeriod?.includes(elem.key))
    const add__selected: any = [];
    selected__months?.map((elem: any) => add__selected?.push({
      ...elem, selected: true
    }))
    // setSelectedRoleValues(add__selected)
    setValue("name", data.name);
    setValue("description", data.description);
    data.due_date && setDate(dateConverterAdToBs(data.due_date));
    setIsMonthly(data.fee_type === 'M' ? true : false);
    data.due_period_type &&
      setSelectedRadioValue(data.due_period_type === "P" ? 1 : 2);

    if (data.due_day) {
      const val = DUE_DAY_CHOICES.find((item: any) => item.key == data.due_day);

      if (val) {
        setSelectedDate(val);
      }
    }
  };

  const handleDate = (value: any) => {
    setDate(value);
    clearErrors("due_date");
  };

  const onRolesChange = (data: any) => {
    const selected: string[] = [];

    data.map((element: any) => selected.push(element.key));



    const currentOptions: any = roleChoices;
    currentOptions.map((element: any) => {
      if (selected.includes(element.key)) {
        element.selected = true;
      } else {
        element.selected = false;
      }
    });
    setRoleChoices(currentOptions);

    const selectedVal = currentOptions.filter(
      (element: any) => element.selected === true
    );


    setSelectedRoleValues(selectedVal);

    // const ids: string[] = [];

    // selectedVal.map((element: any) => {
    //   ids.push(element.key);
    // });

    setSelectedRoles(selectedVal);
  };

  return (
    <>
      <FormLayout
        title={editMode ? "Edit Fee Type" : "Add Fee Type"}
        onSubmit={handleSubmit(onFormSubmit)}
        editMode={editMode}
        loading={loading}
        onClick={!isBtnLoading && handleReset}
        add_or_update={isBtnLoading}
      >
        <CustomizedTextField
          label="Fee Type Name"
          placeholder="Fee Type Name"
          name="name"
          required
          error={errors["name"] ? "This Field is Required" : ""}
          inputRef={register({ required: true })}
        />
        <FormCheckBox
          onChange={(
            event: React.ChangeEvent<HTMLInputElement>,
            checked: boolean
          ) => setIsMonthly(!isMonthly)}
          checked={isMonthly}
          name="is_monthly"
          label="This is a monthly fee"
        />
        {!isMonthly && (
          <>
            <CustomizedNepaliDatePicker
              label="Due Date"
              value={date}
              name="due_date"
              setValue={handleDate}
              required={!isMonthly}
            />
            <span className={classes.validationErrorInfo}>
              {errors.due_date?.type === "serverSideError" &&
                errors.due_date.message}
            </span>
          </>
        )}
        {/* (
          <>
            <InputLabel>
              Billing Period <span style={{ color: "red" }}>*</span>
            </InputLabel>
            <Autocomplete
              value={selectedRoleValues}
              multiple
              id="checkbox-select-roles"
              options={roleChoices}
              disableCloseOnSelect
              onChange={(
                event: React.ChangeEvent<{}>,
                value: any
              ) => onRolesChange(value)}
              getOptionLabel={(option: any) => option?.value}
              renderOption={(option: any, { selected: boolean }) => (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={option.selected}
                  />
                  {option.value}
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Month(s)"
                />
              )}
            />
            <CusRadioGroup
              label="Due Type"
              required={isMonthly}
              items={dueTypeList}
              value={selectedRadioValue}
              setValue={setSelectedRadioValue}
              onValue

            />
            <CustomizedSelect
              label="Due Day"
              name="due_day"
              required={isMonthly}
              options={DUE_DAY_CHOICES}
              value={selectedDate}
              setValue={setSelectedDate}
              error={errors["due_day"] ? "Required Field" : ""}
              inputRef={register({ required: true })}
            />

          </>
        ) */}

        <CustomizedTextField
          multiline
          rows={3}
          label="Description"
          placeholder="Description"
          name="description"
          error={errors["description"] ? "This Field is Required" : ""}
          inputRef={register({ required: false })}
        />
      </FormLayout>
    </>
  );
};

export default FeeTypeForm;
