export const GET_VOID_LOADING = "GET_VOID_LOADING";
export const GET_VOID_ERROR = "GET_VOID_ERROR";
export const GET_VOID_SUCCESS = "GET_VOID_SUCCESS";

export const GET_INVOICE_DETAIL_LOADING = "GET_INVOICE_DETAIL_LOADING";
export const GET_INVOICE_DETAIL_ERROR = "GET_INVOICE_DETAIL_ERROR";
export const GET_INVOICE_DETAIL_SUCCESS = "GET_INVOICE_DETAIL_SUCCESS";

export interface GetVoidLoading {
    type: typeof GET_VOID_LOADING;
}

export interface GetVoidError {
    type: typeof GET_VOID_ERROR;
}

export interface GetVoidSuccess {
    type: typeof GET_VOID_SUCCESS;
    payload: any;
}


export type VoidDispatchTypes =
    | GetVoidLoading
    | GetVoidError
    | GetVoidSuccess