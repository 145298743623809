import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { MeetingTypeTypeI } from '../../actions/HumanResource/MeetingType/MeetingTypeActionTypes';
import { CommunicationSidebar, HRsidebar } from '../../components';
import MeetingTypeForm from '../../components/HumanResource/MeetingType/MeetingTypeForm';
import MeetingTypeList from '../../components/HumanResource/MeetingType/MeetingTypeList';
import SubModuleLayout from '../../components/Reusable/Layouts/SubModuleLayout';

const MeetingTypePage = () => {
  const [formData, setFormData] = useState<MeetingTypeTypeI | null>(null);
  const fetchEditData = (data: MeetingTypeTypeI) => {
    setFormData(data);
  };
  const nav = <CommunicationSidebar meetingType />;
  return (
    <SubModuleLayout HumanMenuActive sideNav={nav}>
      <Grid container>
        <Grid item xs={3}>
          <MeetingTypeForm editData={formData} />
        </Grid>
        <Grid item xs={9}>
          <MeetingTypeList onEditData={fetchEditData} />
        </Grid>
      </Grid>
    </SubModuleLayout>
  );
};

export default MeetingTypePage;
