export const GET_FEE_INCOME_LOADING = "GET_FEE_INCOME_LOADING";
export const GET_FEE_INCOME_ERROR = "GET_FEE_INCOME_ERROR";
export const GET_FEE_INCOME_SUCCESS = "GET_FEE_INCOME_SUCCESS";

export interface GetFeeIncomeLoading {
    type: typeof GET_FEE_INCOME_LOADING;
}

export interface GetFeeIncomeError {
    type: typeof GET_FEE_INCOME_ERROR;
}

export interface GetFeeIncomeSuccess {
    type: typeof GET_FEE_INCOME_SUCCESS;
    payload: any;
}

export type FeeIncomeDispatchTypes =
    | GetFeeIncomeLoading
    | GetFeeIncomeError
    | GetFeeIncomeSuccess