// import { SectionTypeI } from "../Section/SectionActionTypes";

export const GET_CATEGORY_LOADING = "GET_CATEGORY_LOADING";
export const GET_CATEGORY_ERROR = "GET_CATEGORY_ERROR";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";

export const ADD_CATEGORY_LOADING = "ADD_CATEGORY_LOADING";
export const ADD_CATEGORY_ERROR = "ADD_CATEGORY_ERROR";
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS";

export const UPDATE_CATEGORY_LOADING = "UPDATE_CATEGORY_LOADING";
export const UPDATE_CATEGORY_ERROR = "UPDATE_CATEGORY_ERROR";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";

export const DELETE_CATEGORY_LOADING = "DELETE_CATEGORY_LOADING";
export const DELETE_CATEGORY_ERROR = "DELETE_CATEGORY_ERROR";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";

export type CategoryTypeI = {
  id: string;
  name: string;
  description: string;
  created_by: string;
  general_info_name: string;
};

//===============================<START>GET CLASS<START>======================//

export interface GetCategoryLoading {
  type: typeof GET_CATEGORY_LOADING;
}

export interface GetCategoryError {
  type: typeof GET_CATEGORY_ERROR;
}

export interface GetCategorySuccess {
  type: typeof GET_CATEGORY_SUCCESS;
  payload: CategoryTypeI[];
}

//===============================<END>GET CLASS<END>======================//

//===============================<START>ADD CLASS<START>======================//

export interface AddCategoryLoading {
  type: typeof ADD_CATEGORY_LOADING;
}

export interface AddCategoryError {
  type: typeof ADD_CATEGORY_ERROR;
}

export interface AddCategorySuccess {
  type: typeof ADD_CATEGORY_SUCCESS;
  payload: CategoryTypeI;
}

//===============================<END>ADD CLASS<END>======================//

//===============================<START>UPDATE CLASS<START>======================//

export interface UpdateCategoryLoading {
  type: typeof UPDATE_CATEGORY_LOADING;
}

export interface UpdateCategoryError {
  type: typeof UPDATE_CATEGORY_ERROR;
}

export interface UpdateCategorySuccess {
  type: typeof UPDATE_CATEGORY_SUCCESS;
  payload: CategoryTypeI;
}

//===============================<END>UPDATE CLASS<END>======================//

//===============================<START>DELETE CLASS<START>======================//

export interface DeleteCategoryLoading {
  type: typeof DELETE_CATEGORY_LOADING;
}

export interface DeleteCategoryError {
  type: typeof DELETE_CATEGORY_ERROR;
}

export interface DeleteCategorySuccess {
  type: typeof DELETE_CATEGORY_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE CLASS<END>======================//

export type CategoryDispatchTypes =
  | GetCategoryLoading
  | GetCategoryError
  | GetCategorySuccess
  | AddCategoryLoading
  | AddCategoryError
  | AddCategorySuccess
  | UpdateCategoryLoading
  | UpdateCategoryError
  | UpdateCategorySuccess
  | DeleteCategoryLoading
  | DeleteCategoryError
  | DeleteCategorySuccess;
