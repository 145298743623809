export const GET_LEAVE_LOADING = 'GET_LEAVE_LOADING';
export const GET_LEAVE_ERROR = 'GET_LEAVE_ERROR';
export const GET_LEAVE_SUCCESS = 'GET_LEAVE_SUCCESS';

export const ADD_LEAVE_LOADING = 'ADD_LEAVE_LOADING';
export const ADD_LEAVE_ERROR = 'ADD_LEAVE_ERROR';
export const ADD_LEAVE_SUCCESS = 'ADD_LEAVE_SUCCESS';

export const UPDATE_LEAVE_LOADING = 'UPDATE_LEAVE_LOADING';
export const UPDATE_LEAVE_ERROR = 'UPDATE_LEAVE_ERROR';
export const UPDATE_LEAVE_SUCCESS = 'UPDATE_LEAVE_SUCCESS';

export const UPDATE_LEAVE_STATUS_LOADING = 'UPDATE_LEAVE_STATUS_LOADING';
export const UPDATE_LEAVE_STATUS_ERROR = 'UPDATE_LEAVE_STATUS_ERROR';
export const UPDATE_LEAVE_STATUS_SUCCESS = 'UPDATE_LEAVE_STATUS_SUCCESS';

export const APPROVE_OR_DECLINE_LOADING = 'APPROVE_OR_DECLINE_LOADING';
export const APPROVE_OR_DECLINE_SUCCESS = 'APPROVE_OR_DECLINE_SUCCESS';
export const APPROVE_OR_DECLINE_ERROR = 'APPROVE_OR_DECLINE_ERROR';

export const DELETE_LEAVE_LOADING = 'DELETE_LEAVE_LOADING';
export const DELETE_LEAVE_ERROR = 'DELETE_LEAVE_ERROR';
export const DELETE_LEAVE_SUCCESS = 'DELETE_LEAVE_SUCCESS';

export const GET_TODAY_ON_LEAVE_LOADING = 'GET_TODAY_ON_LEAVE_LOADING';
export const GET_TODAY_ON_LEAVE_ERROR = 'GET_TODAY_ON_LEAVE_ERROR';
export const GET_TODAY_ON_LEAVE_SUCCESS = 'GET_TODAY_ON_LEAVE_SUCCESS';

export const APPLY_LEAVE_TYPE_LOADING = 'APPLY_LEAVE_TYPE_LOADING';
export const APPLY_LEAVE_TYPE_ERROR = 'APPLY_LEAVE_TYPE_ERROR';
export const APPLY_LEAVE_TYPE_SUCCESS = 'APPLY_LEAVE_TYPE_SUCCESS';

export type LeaveTypeI = {
  id: string;
  name: string;
};

export type TodayOnLeaveTypeI = {
  id: string;
  staff_name: string;
  designation: string;
};

export type ApproveOrDeclineType = {
  id: string;
  status: string;
};

//===============================<START>GET LEAVE<START>======================//

export interface GetLeaveLoading {
  type: typeof GET_LEAVE_LOADING;
}

export interface GetLeaveError {
  type: typeof GET_LEAVE_ERROR;
}

export interface GetLeaveSuccess {
  type: typeof GET_LEAVE_SUCCESS;
  payload: any;
}

export interface GetTodayOnLeaveLoading {
  type: typeof GET_TODAY_ON_LEAVE_LOADING;
}

export interface GetTodayOnLeaveError {
  type: typeof GET_TODAY_ON_LEAVE_ERROR;
}

export interface GetTodayOnLeaveSuccess {
  type: typeof GET_TODAY_ON_LEAVE_SUCCESS;
  payload: TodayOnLeaveTypeI[];
}

//===============================<END>GET LEAVE<END>======================//

//===============================<START>ADD LEAVE<START>======================//

export interface AddLeaveLoading {
  type: typeof ADD_LEAVE_LOADING;
}

export interface AddLeaveError {
  type: typeof ADD_LEAVE_ERROR;
  payload: any;
}

export interface AddLeaveSuccess {
  type: typeof ADD_LEAVE_SUCCESS;
  payload: LeaveTypeI;
}

//===============================<END>ADD LEAVE<END>======================//

//===============================<START>UPDATE LEAVE<START>======================//

export interface UpdateLeaveLoading {
  type: typeof UPDATE_LEAVE_LOADING;
}

export interface UpdateLeaveError {
  type: typeof UPDATE_LEAVE_ERROR;
  payload: any;
}

export interface UpdateLeaveSuccess {
  type: typeof UPDATE_LEAVE_SUCCESS;
  payload: LeaveTypeI;
}

//===============================<END>UPDATE LEAVE<END>======================//

//===============================<START>UPDATE LEAVE<START>======================//
export interface UpdateLeaveStatusLoading {
  type: typeof UPDATE_LEAVE_STATUS_LOADING;
}

export interface UpdateLeaveStatusError {
  type: typeof UPDATE_LEAVE_STATUS_ERROR;
}

export interface UpdateLeaveStatusSuccess {
  type: typeof UPDATE_LEAVE_STATUS_SUCCESS;
  payload: any;
}
//===============================<END>UPDATE LEAVE<END>======================//

//===============================<START>DELETE LEAVE<START>======================//

export interface DeleteLeaveLoading {
  type: typeof DELETE_LEAVE_LOADING;
}

export interface DeleteLeaveError {
  type: typeof DELETE_LEAVE_ERROR;
}

export interface DeleteLeaveSuccess {
  type: typeof DELETE_LEAVE_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE LEAVE<END>======================//
export interface ApproveOrDeclineLoading {
  type: typeof APPROVE_OR_DECLINE_LOADING;
}

export interface ApproveOrDeclineError {
  type: typeof APPROVE_OR_DECLINE_ERROR;
}

export interface ApproveOrDeclineSuccess {
  type: typeof APPROVE_OR_DECLINE_SUCCESS;
  payload: ApproveOrDeclineType;
}

export interface ApplyLeaveTypeLoading {
  type: typeof APPLY_LEAVE_TYPE_LOADING;
}

export interface ApplyLeaveTypeError {
  type: typeof APPLY_LEAVE_TYPE_ERROR;
  payload: any;
}

export interface ApplyLeaveTypeSuccess {
  type: typeof APPLY_LEAVE_TYPE_SUCCESS;
  payload: any;
}

export type LeaveDispatchTypes =
  | GetLeaveLoading
  | GetLeaveError
  | GetLeaveSuccess
  | AddLeaveLoading
  | AddLeaveError
  | AddLeaveSuccess
  | UpdateLeaveLoading
  | UpdateLeaveError
  | UpdateLeaveSuccess
  | UpdateLeaveStatusLoading
  | UpdateLeaveStatusError
  | UpdateLeaveStatusSuccess
  | DeleteLeaveLoading
  | DeleteLeaveError
  | DeleteLeaveSuccess
  | ApproveOrDeclineSuccess
  | ApproveOrDeclineError
  | ApproveOrDeclineLoading
  | GetTodayOnLeaveLoading
  | GetTodayOnLeaveError
  | GetTodayOnLeaveSuccess
  | ApplyLeaveTypeLoading
  | ApplyLeaveTypeError
  | ApplyLeaveTypeSuccess;
