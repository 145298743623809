import {
  Checkbox,
  Grid,
  InputLabel,
  makeStyles,
  TextField,
} from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';
import { DiscountTypeI } from '../../../actions/Fees/DiscountType/DiscountActionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import { useForm } from 'react-hook-form';
import {
  AddDiscount,
  GetDiscount,
  UpdateDiscount,
} from '../../../actions/Fees/DiscountType/DiscountAction';
import { GetCategory } from '../../../actions/Student/StudentCategory/CategoryAction';
import FormLayout from '../../Reusable/Layouts/Form.Layout';
import CustomizedTextField from '../../Reusable/Inputs/TextField';
import CustomizedSelect from '../../Reusable/Inputs/Select';
import { CusRadioGroup } from '../../Reusable/Inputs/Radio';
import { useFormStyles } from '../../Styles/FormStyles';
import { ScholarshipType } from '../../../actions/ScholarShipType/ScholarshipActionTypes';
import {
  AddScholarshipType,
  UpdateScholarshipType,
} from '../../../actions/ScholarShipType/ScholarshipAction';
import { GetFeeType } from '../../../actions/Fees/FeeType/FeeTypeAction';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { SET_SNACKBAR } from '../../../actions/SnackbarActionTypes';
interface PropsI {
  editData: ScholarshipType | null;
  onEditCheck: (value: boolean) => void;
}
interface SelectFeeType {
  key: string;
  value: string;
}

interface FormInputI {
  scholarship_title: string;
  fee_type: string;
  applied_month?: Array<string>;
  scholarship_type: string;
  scholarship_in: string | undefined;
  applicable_scholarship: number | undefined;
  description: string;
}

const ScholarshipTypeForm = (props: PropsI) => {
  const { editData, onEditCheck } = props;
  const classes = useFormStyles();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    errors,
    setError,
    clearErrors,
  } = useForm<FormInputI>({
    mode: 'onChange',
  });
  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [editID, setEditID] = useState<string>('');
  const [selectedScholarshipTypeValue, setSelectedScholarshipTypeValue] =
    useState<string>('');
  const [selectedRadioValue, setSelectedRadioValue] = useState<string>('');

  const [feeType, setFeeType] = useState<SelectFeeType | null>(null);
  const [selectedFeeValues, setSelectedFeeValues] = useState<any>([]);
  const [selectedFees, setSelectedFees] = useState<string[]>([]);
  const [feeChoices, setFeeChoices] = useState<any>([]);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);

  const dispatch = useDispatch();
  const discountTypeLoading = useSelector(
    (state: RootStore) => state.discount_type.loading
  );
  const add_or_update = useSelector(
    (state: RootStore) => state.discount_type.add_or_update
  );

  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

  const studentCategorySelector = useSelector(
    (state: RootStore) => state.categories
  );
  const feeTypeSelector = useSelector((state: RootStore) => state.fee_type);

  useEffect(() => {
    dispatch(GetDiscount());
    dispatch(GetFeeType(1));
  }, []);

  useEffect(() => {
    if (add_or_update) {
      setIsBtnLoading(false);
      handleReset();
    }
  }, [add_or_update]);

  //   const CATEGORY_TYPE_CHOICES = studentCategorySelector.categories.map(
  //     (item) => ({
  //       key: item.id.toString(),
  //       value: item.name,
  //     })
  //   );

  // const FEE_TYPE_CHOICES = feeTypeSelector?.feeType?.map((item: any) => ({
  //   key: item.id,
  //   value: item.name,
  // }));
  //Temp commented
  const FEE_TYPE_CHOICES: any = [];

  useEffect(() => {
    setLoading(discountTypeLoading);
  }, [discountTypeLoading]);

  useEffect(() => {
    editData != null && handleEditTableData(editData);
  }, [editData]);

  useEffect(() => {
    if (feeTypeSelector?.feeType?.results !== null) {
      setFeeChoices(feeTypeSelector?.feeType?.results);
    }
  }, [feeTypeSelector]);

  const onFeeChange = (data: any) => {
    const selected: string[] = [];

    data.map((element: any) => selected.push(element.id));

    const currentOptions: any = feeChoices;
    currentOptions.map((element: any) => {
      if (selected.includes(element.id)) {
        element.selected = true;
      } else {
        element.selected = false;
      }
    });
    setFeeChoices(currentOptions);

    const selectedVal = currentOptions.filter(
      (element: any) => element.selected === true
    );

    setSelectedFeeValues(selectedVal);

    const ids: string[] = [];

    selectedVal.map((element: any) => {
      ids.push(element.id);
    });

    setSelectedFees(ids);
  };
  const onFormSubmit = (data: FormInputI) => {
    if (selectedRadioValue === '' || null) {
      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: 'Please select scholarship in',
        },
      });
    } else {
      const feeHeads__ = selectedFeeValues || [];
      const feeHeads__id: any[] = [];

      selectedFeeValues?.map((elem: any, index: number) =>
        feeHeads__id.push(elem?.id)
      );

      const submitData = {
        scholarship_title: data.scholarship_title,
        scholarship_type: selectedScholarshipTypeValue,
        scholarship_in: selectedRadioValue,
        applicable_scholarship: Number(data.applicable_scholarship),
        description: data.description,
      };

      if (editMode && editID) {
        dispatch(UpdateScholarshipType(editID, submitData));
      } else {
        dispatch(AddScholarshipType(submitData));
      }
    }
  };

  const handleEditTableData = (data: any) => {
    console.log('edit', data);

    setEditID(data.id);

    setValue('scholarship_title', data.scholarship_title);
    setValue('applied_month', []);
    setValue('description', data.description);

    setSelectedRadioValue(data.scholarship_in);
    setSelectedScholarshipTypeValue(data.scholarship_in);
    setValue('applicable_scholarship', data.applicable_scholarship);
    setEditMode(true);
  };

  const scholarshipTypeList = [
    { id: 'P', name: 'Partial' },
    { id: 'C', name: 'Complete' },
  ];

  const scholarshipTypeIn = [
    { id: 'P', name: 'Percent%' },
    { id: 'A', name: 'Amount' },
  ];

  const handleReset = () => {
    setEditMode(false);
    reset();
    setFeeType(null);
    setSelectedRadioValue('');
    setEditID('');
    onEditCheck(false);
    setSelectedScholarshipTypeValue('');
    setSelectedFeeValues([]);
  };

  //----------------------ERROR HANDLING---------------------------//
  const [serverErrors, setServerErrors] = useState<any>(null);
  const scholarshipSelector = useSelector(
    (state: RootStore) => state.scholarship
  );
  const errorsData = scholarshipSelector.errors;
  const initialErrorsData = useRef(errorsData);

  useEffect(() => {
    if (initialErrorsData.current === errorsData) {
      initialErrorsData.current = errorsData; // Do not set initial errors
    } else {
      if (errorsData?.error != null) {
        const keys = Object.keys(errorsData?.error);
        keys?.map((elem: any) => {
          setError(elem, {
            type: 'serverSideError',
            message: errorsData.error[elem] && errorsData.error[elem][0],
          });
        });
      }
      setServerErrors(errorsData);
    }
  }, [errorsData]);

  useEffect(() => {
    if (selectedRadioValue) {
      clearErrors('applicable_scholarship');
      clearErrors('scholarship_in');
    }
  }, [selectedRadioValue]);

  useEffect(() => {
    if (selectedScholarshipTypeValue) {
      clearErrors('applicable_scholarship');
      clearErrors('scholarship_type');
    }
  }, [selectedScholarshipTypeValue]);

  useEffect(() => {
    if (scholarshipSelector.recent) {
      handleReset();
    }
  }, [scholarshipSelector]);

  return (
    <>
      <FormLayout
        title={editMode ? 'Edit Scholarship Type' : 'Add Scholarship Type'}
        onSubmit={handleSubmit(onFormSubmit)}
        editMode={editMode}
        loading={loading}
        onClick={!isBtnLoading && handleReset}
        add_or_update={isBtnLoading}
      >
        <Grid container>
          <CustomizedTextField
            label=" Scholarship Header"
            placeholder=" Scholarship Header"
            name="scholarship_title"
            required
            // onChange={clearErrors("scholarship_title")}s
            error={errors['scholarship_title'] ? 'Required Field.' : ''}
            inputRef={register({ required: true })}
          />
          <span className={classes.validationErrorInfo}>
            {errors.scholarship_title?.type === 'serverSideError' &&
              errors.scholarship_title.message}
          </span>
          <CusRadioGroup
            row
            label="Scholarship In"
            // required
            items={scholarshipTypeIn}
            value={selectedRadioValue}
            setValue={setSelectedRadioValue}
            name="scholarship_in"
          />
          <span className={classes.validationErrorInfo}>
            {errors.scholarship_in?.type === 'serverSideError' &&
              errors.scholarship_in.message}
          </span>
          <CustomizedTextField
            label="Scholarship Applicable"
            placeholder="Label"
            name="applicable_scholarship"
            type="number"
            required
            error={errors['applicable_scholarship'] ? 'Required Field.' : ''}
            inputRef={register({ required: true })}
          />
          <span className={classes.validationErrorInfo}>
            {errors.applicable_scholarship?.type === 'serverSideError' &&
              errors.applicable_scholarship.message}
          </span>
          <span className={classes.validationErrorInfo}>
            {errors.applicable_scholarship?.type === 'required' &&
              errors.applicable_scholarship.message}
          </span>

          <CustomizedTextField
            multiline
            rows={3}
            label="Description"
            placeholder="Description"
            name="description"
            inputRef={register}
          />
        </Grid>
      </FormLayout>
    </>
  );
};

export default ScholarshipTypeForm;
