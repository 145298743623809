import React from "react";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Paper,
  Theme,
  createStyles,
} from "@material-ui/core";
import formatDate from "../../../utils/formatDate";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      color: "#696969",
      paddingTop: "10px",
      "& .MuiTableRow-root": {
        height: "30px",
        maxHeight: "30px",
      },
      "&.MuiTableCell-root": {
        width: "50%",
      },
    },
    infobar: {
      marginRight: theme.spacing(4),
      padding: theme.spacing(2),
    },
    roota: {
      marginBottom: "1rem",
    },
  })
);

export default function SecondaryGuardianInfo(props: any) {
  const classes = useStyles();
  const rows = [
    createData("Full Name", props.guardianData?.secondary_guardian?.full_name),
    createData("Address", props.guardianData?.secondary_guardian?.address),
    createData(
      "Phone Number",
      props.guardianData?.secondary_guardian?.phone_number
        ? props.guardianData.secondary_guardian.phone_number
        : "-"
    ),
    createData("Relation", props.guardianData?.secondary_guardian?.relation),
    // createData("Type", props.guardianData?.category_name),
    // // createData("Age", "12 years"),
    // createData("Date of Birth", props.guardianData?.date_of_birth),
    // createData("Admission Date", formatDate(props.guardianData?.created_on)),
    // createData("Medical History", props.guardianData?.medical_history),
  ];

  function createData(name: string, infoData: string) {
    return { name, infoData };
  }

  return (
    <>
      <Paper className={classes.infobar} elevation={1}>
        <TableContainer className={classes.roota}>
          <Typography align="left" variant="h5" component="h3">
            Secondary Guardian Info
          </Typography>
          <Table
            size="small"
            className={classes.table}
            aria-label="simple table"
          >
            <TableBody>
              {props.guardianData?.secondary_guardian != null ? (
                rows.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell
                      className={classes.table}
                      component="th"
                      scope="row"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell align="left">{row.infoData}</TableCell>
                  </TableRow>
                ))
              ) : (
                <p>This guardian don't have secondary guardian.</p>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}
