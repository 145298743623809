// ---------------- <START> module import starts ------------------//
import React, { useState } from "react";
import { useSidebar } from "../../Reusable";
import { useSidebarStyles } from "../../Styles/SidebarStyles";
import { LibraryBooksOutlined } from "@material-ui/icons";
import SidebarTitle from "../../Reusable/Titles/SidebarTitle";
// ---------------- <END> module import ends ------------------//

//---------------------------- <START> interface start ------------------------------//
interface propsI {
  Class?: boolean | undefined;
  subjects?: boolean | undefined;
  subjectsGroup?: boolean | undefined;
  subjectTeacher?: boolean | undefined;
  timeTable?: boolean | undefined;
  assignClassTeacher?: boolean | undefined;
  enoteCategory?: boolean | undefined;
  rubricCategory?: boolean | undefined;
  assignRubric?: boolean | undefined;
}
//---------------------------- <END> interface ends ------------------------------//

// ---------------- <START> Academic Sidebar Component starts ------------------//
const AcademicSidebar = (props: propsI) => {
  const classes = useSidebarStyles();

  // state declarations
  const [Class] = useState(props.Class);
  const [subjects] = useState(props.subjects);
  const [subjectsGroup] = useState(props.subjectsGroup);
  const [subjectTeacher] = useState(props.subjectTeacher);
  const [timeTable] = useState(props.timeTable);
  const [assignClassTeacher] = useState(props.assignClassTeacher);
  const [enoteCategory] = useState(props.enoteCategory);
  const [rubricCategory] = useState(props.rubricCategory);
  const [assignRubric] = useState(props.assignRubric);

  // Sidebar Dataset
  const dataSet = [
    { text: "Class", url: "class", activeStatus: Class },
    { text: "Subjects", url: "subject", activeStatus: subjects },
    {
      text: "Subjects Group",
      url: "subject-group",
      activeStatus: subjectsGroup,
    },
    {
      text: "Subject Teacher",
      url: "subject-teacher",
      activeStatus: subjectTeacher,
    },
    { text: "Timetable", url: "time-table", activeStatus: timeTable },
    {
      text: "Assign Class Teacher",
      url: "assign-class-teacher",
      activeStatus: assignClassTeacher,
    },
    {
      text: "Enote Category",
      url: "enote-category",
      activeStatus: enoteCategory,
    },
    {
      text: "Rubric Category",
      url: "rubric-category",
      activeStatus: rubricCategory,
    },
    {
      text: "Assign Rubric",
      url: "assign-rubric",
      activeStatus: assignRubric,
    },
  ];

  // Retrieving re-usable components from useSidebar
  const { CustomSidebar } = useSidebar(dataSet, "FINANCE");
  return (
    <div className={classes.Sidebar}>
      <div className={classes.sidebarContent}>
        <SidebarTitle icon={<LibraryBooksOutlined />}>Academics</SidebarTitle>
        <CustomSidebar />
      </div>
    </div>
  );
};
// ---------------- <END> Academic Sidebar Component ends ------------------//
export default AcademicSidebar;
