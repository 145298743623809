//---------------------------- <START> module imports start ----------------------------------//
import React, { useState, useEffect } from "react";
import {
  Grid,
  InputLabel,
  TextField,
  Paper,
  Button,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { useFormStyles } from "../../Styles/FormStyles";
import { useForm } from "react-hook-form";
import { Autocomplete } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { AddSms } from "../../../actions/Communication/SMS/SmsAction";

import { RootStore } from "../../../store";
import { GetSmsTemplates } from "../../../actions/Communication/SmsTemplate/SmsTemplateAction";
import styled from "styled-components";
import SmsTarget from "./SmsTarget";
import SmsTips from "./SmsTips";
import { Save, Send } from "@material-ui/icons";
import { setSnackbar } from "../../../actions/SnackbarAction";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import {
  dateConverterAdToBs,
  dateConverterBsToAd,
} from "../../utils/dateConverter";
import moment from "moment";
import { sendSms } from "../../../rtk/features/sms/smsApi";
//----------------------------<END> module imports end ----------------------------------//

//-----------------<start> Interfaces <start>-----------------------//

const StyledPaper = styled(Paper)`
  margin: 20px;
  padding: 25px 20px;
`;

//-----------------<end> Interfaces <end>-----------------------//
const today = moment(new Date()).format("YYYY-MM-DD");

const NewMessage = () => {
  const classes = useFormStyles();

  const [target, setTarget] = useState<any>(null);
  const [sendNow, setSendNow] = useState<boolean>(false);
  const [smsDate, setSmsDate] = useState<string>(dateConverterAdToBs(today));
  const [smsTime, setSmsTime] = useState<string>("10:00");
  const [smsTitle, setSmsTitle] = useState<string>("");
  const [smsDesc, setSmsDesc] = useState<string>("");
  const [smsNumber, setSmsNumber] = useState<string>("");

  // ========================= <START> REACT HOOKS <START> ======================= //
  const dispatch = useDispatch();
  const { loading, actionPerformed } = useSelector(
    (state: RootStore) => state.sms
  );
  const { register, errors, setError, setValue, clearErrors } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if (actionPerformed) {
      setSmsNumber("");
    }
  }, [actionPerformed]);

  // ========================= <END> REACT HOOKS <END> ======================= //

  // ========================= <START> EVENT HANDLERS <START> ======================= //

  const handleTitleChange = (val: string) => {
    clearErrors("title");
    setSmsTitle(val);
  };

  const handleDescChange = (val: string) => {
    clearErrors("description");
    setSmsDesc(val);
  };

  const handleSendSms = () => {
    let post_target: any = {
      user: [],
      group: [],
      grade: null,
      section: [],
      send_to_guardian: false,
      schedule_date: null,
      schedule_time: null,
      title: "",
      description: "",
      sent_to_number: "",
    };
    let valid = true;

    if (target) {
      if (target.target === "groups") {
        if (target.data.length === 0) {
          valid = false;
          dispatch(setSnackbar(true, "warning", "Select groups"));
        } else {
          post_target = { ...post_target, group: target.data };
        }
      }
      if (target.target === "individuals") {
        if (target.data.users.length === 0) {
          valid = false;
          dispatch(setSnackbar(true, "warning", "Select users"));
        } else {
          post_target = {
            ...post_target,
            user: target.data.users,
          };
        }
      }

      if (target.target === "classes") {
        if (target.data.grade === null) {
          valid = false;
          dispatch(setSnackbar(true, "warning", "Select class"));
        } else {
          if (
            target.data.sendTo.parent === false &&
            target.data.sendTo.student === false
          ) {
            valid = false;
            dispatch(
              setSnackbar(
                true,
                "warning",
                "Check targeted groups for the class"
              )
            );
          } else {
            post_target = {
              ...post_target,
              grade: target.data.grade,
              section: target.data.section,
              send_to_guardian: target.data.sendTo.parent,
            };
          }
        }
      }
    } else {
      valid = false;
      dispatch(setSnackbar(true, "warning", "Select groups, users or class"));
    }

    if (valid) {
      if (!smsTitle) {
        setError("title", { type: "required", message: "Title is required" });
      }
      if (!smsDesc) {
        setError("description", {
          type: "required",
          message: "Description is required",
        });
        return;
      }
      post_target = {
        ...post_target,
        title: smsTitle,
        description: smsDesc,
        sent_to_number: smsNumber,
      };
      if (!sendNow) {
        post_target = {
          ...post_target,
          schedule_date: dateConverterBsToAd(smsDate),
          schedule_time: smsTime,
        };
      }
      dispatch(sendSms(post_target));
    }
  };

  // ========================= <END> EVENT HANDLERS <END> ======================= //

  return (
    <>
      <SmsTarget setTarget={(data) => setTarget(data)} />
      <StyledPaper>
        <Grid container>
          <Grid item xs={7}>
            <Grid container>
              <Grid item xs={6} className={classes.formTitle}>
                <span>Compose New Message</span>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={6} className={classes.formWrapper}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      value={sendNow}
                      onChange={() => setSendNow(!sendNow)}
                    />
                  }
                  label={<InputLabel>Send now</InputLabel>}
                  labelPlacement="start"
                  style={{ marginLeft: "0px", flexDirection: "row" }}
                />
              </Grid>
              <Grid item xs={6} />

              {!sendNow && (
                <>
                  <Grid item xs={6} className={classes.formWrapper}>
                    <InputLabel>
                      Date <span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <NepaliDatePicker
                      className={classes.nepaliDateInput}
                      value={smsDate}
                      onChange={(data: string) => setSmsDate(data)}
                      options={{ calenderLocale: "en", valueLocale: "en" }}
                      inputClassName="inputFormControl"
                    />
                    <span className={classes.validationErrorInfo}>
                      {errors.date && errors.date.message}
                    </span>
                  </Grid>
                  <Grid item xs={6} />
                </>
              )}
              {!sendNow && (
                <>
                  <Grid item xs={6} className={classes.formWrapper}>
                    <InputLabel>
                      Time <span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <TextField
                      fullWidth={true}
                      type="time"
                      name="sms_time"
                      variant="outlined"
                      value={smsTime}
                      onChange={(e) => setSmsTime(e.target.value)}
                    />
                    <span className={classes.validationErrorInfo}>
                      {errors.time && errors.time.message}
                    </span>
                  </Grid>
                  <Grid item xs={6} />
                </>
              )}

              <Grid item xs={10} className={classes.formWrapper}>
                <InputLabel>
                  Title <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <TextField
                  size="small"
                  variant="outlined"
                  name="title"
                  placeholder="Message Title"
                  autoComplete="off"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleTitleChange(event.target.value);
                  }}
                />
                <span className={classes.validationErrorInfo}>
                  {errors.title && errors.title.message}
                </span>
              </Grid>

              <Grid item xs={10} className={classes.formWrapper}>
                <InputLabel>
                  Description <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <TextField
                  multiline
                  rows={5}
                  variant="outlined"
                  name="description"
                  placeholder="Message"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleDescChange(event.target.value);
                  }}
                />
                Chars: {smsDesc.length}
                <span className={classes.validationErrorInfo}>
                  {errors.description && errors.description.message}
                </span>
              </Grid>

              <Grid item xs={10} className={classes.formWrapper}>
                <InputLabel>Send to this number as well</InputLabel>
                <TextField
                  size="small"
                  variant="outlined"
                  name="send_to"
                  type="number"
                  placeholder="Send to"
                  autoComplete="off"
                  value={smsNumber}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSmsNumber(event.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button
                color="primary"
                variant="contained"
                startIcon={<Send />}
                onClick={() => handleSendSms()}
                style={{ marginTop: "15px" }}
              >
                Send
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <SmsTips />
          </Grid>
        </Grid>
      </StyledPaper>
    </>
  );
};

export default NewMessage;
