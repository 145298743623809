import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Paper,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetClasses } from "../../../../../actions/Academics/Class/ClassAction";
import { ClassTypeI } from "../../../../../actions/Academics/Class/ClassActionTypes";
import { SectionTypeI } from "../../../../../actions/Academics/Section/SectionActionTypes";
import { RootStore } from "../../../../../store";
import { useFormStyles } from "../../../../Styles/FormStyles";
import { useForm } from "react-hook-form";
import { AnnouncementTypeI } from "../../../../../actions/Communication/Announcement/AnnouncementActionTypes";

//Start Interface
interface PropsI {
  onChange: (value: ClassTabFormI) => void;
  onSubmit: number;
  initData: AnnouncementTypeI | null;
}

export interface ClassTabFormI {
  grade: string | null;
  section: string[];
  gradeLabel: string;
  sectionLabel: string[];
  sent_to_parent: boolean;
  sent_to_student: boolean;
}

interface SectionListI {
  id: string;
  name: string;
  checked: boolean;
}

const ClassTab = (props: PropsI) => {
  const { onChange, onSubmit, initData } = props;
  //State declartation
  const classes = useFormStyles();

  const [grade, setGrade] = useState<ClassTypeI | null>(null);
  const [sectionList, setSectionList] = useState<SectionListI[]>([]);

  const [classChoices, setClassChoices] = useState<ClassTypeI[]>([]);
  const { errors, setError, clearErrors } = useForm();

  const [sendTo, setSendTo] = useState({
    parent: false,
    student: false,
  });
  // ================================= <START> REACT EFFECT <START> ===============================//
  const dispatch = useDispatch();
  const classSelector = useSelector((state: RootStore) => state.class);

  useEffect(() => {
    dispatch(GetClasses());
  }, []);

  useEffect(() => {
    setClassChoices(classSelector.classes);
  }, [classSelector]);

  useEffect(() => {
    if (onSubmit > 1) {
      grade === null &&
        setError("grade", {
          type: "required",
          message: "This field is required",
        });

      if (!sendTo.parent && !sendTo.student) {
        setError("sendTo", {
          type: "required",
          message: "Check one of the boxes",
        });
      }
    }
  }, [onSubmit]);

  useEffect(() => {
    if (initData) {
      if (classChoices.length) {
        const initGrade = classChoices.find(
          (element) => element.grade === initData.grade
        );
        initGrade && setGrade(initGrade);

        if (initData.section.length && initGrade) {
          const data: SectionListI[] = [];
          initGrade.section.map((element) => {
            data.push({
              ...element,
              checked: initData.section.find((value) => value.id === element.id)
                ? true
                : false,
            });
          });
          setSectionList(data);
          const initSection: string[] = [];
          initData.section.map((sec) => {
            initSection.push(sec.id);
          });
          onChange({
            grade: initData.grade,
            section: initSection,
            gradeLabel: "",
            sectionLabel: [],
            sent_to_parent: initData.sent_to_parent,
            sent_to_student: initData.sent_to_student,
          });
        } else if (!initData.section.length && initGrade) {
          const data: SectionListI[] = [];
          initGrade.section.map((element) => {
            data.push({
              ...element,
              checked: false,
            });
          });
          setSectionList(data);
          onChange({
            grade: initData.grade,
            section: [],
            gradeLabel: "",
            sectionLabel: [],
            sent_to_parent: initData.sent_to_parent,
            sent_to_student: initData.sent_to_student,
          });
        }
      }
    }
  }, [initData, classChoices]);

  // ================================= <END> REACT EFFECT <END> ===============================//

  // ================================= <START> EVENT HANDLERS <START> ===============================//

  const handleClassChange = (value: ClassTypeI | null) => {
    setGrade(value);
    onChange({
      grade: value ? value.grade : null,
      section: [],
      gradeLabel: value ? value.grade_name : "",
      sectionLabel: [],
      sent_to_student: sendTo.student,
      sent_to_parent: sendTo.parent,
    });

    if (value) {
      handleCheckBoxes(value.section);
      clearErrors("grade");
    } else {
      setSectionList([]);
      setError("grade", {
        type: "required",
        message: "This field is required",
      });
    }
  };

  const handleCheckBoxes = (value: SectionTypeI[]) => {
    const data: SectionListI[] = [];
    value.map((element) => {
      data.push({ ...element, checked: false });
    });
    setSectionList(data);
  };

  const handleSectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const data: SectionListI[] = [...sectionList];
    const data_index: number = data.findIndex(
      (element) => element.id === event.target.value
    );
    data[data_index] = { ...data[data_index], checked: event.target.checked };
    setSectionList(data);

    if (grade) {
      const sectionList: string[] = [];
      const sectionLabelList: string[] = [];

      const checkedData = data.filter((element) => element.checked === true);
      checkedData.map((element) => {
        sectionList.push(element.id);
        sectionLabelList.push(element.name);
      });

      onChange({
        grade: grade.grade,
        section: sectionList,
        gradeLabel: grade.grade_name,
        sectionLabel: sectionLabelList,
        sent_to_parent: sendTo.parent,
        sent_to_student: sendTo.student,
      });
    }
  };

  const handleSendToChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSendTo({ ...sendTo, [event.target.name]: event.target.checked });
    clearErrors("sendTo");
  };

  useEffect(() => {
    if (grade) {
      const data: SectionListI[] = [...sectionList];

      const sectionsList: string[] = [];
      const sectionLabelList: string[] = [];

      const checkedData = data.filter((element) => element.checked === true);
      checkedData.map((element) => {
        sectionsList.push(element.id);
        sectionLabelList.push(element.name);
      });

      onChange({
        grade: grade.grade,
        section: sectionsList,
        gradeLabel: grade.grade_name,
        sectionLabel: sectionLabelList,
        sent_to_parent: sendTo.parent,
        sent_to_student: sendTo.student,
      });
    }
  }, [sendTo]);
  // ================================= <END> EVENT HANDLERS <END> ===============================//

  return (
    <>
      <Paper className={classes.classContent} elevation={2}>
        <InputLabel>
          Select Class <span style={{ color: "red" }}>*</span>
        </InputLabel>
        <Autocomplete
          value={grade}
          className={classes.form}
          onChange={(event: React.ChangeEvent<{}>, value: ClassTypeI | null) =>
            handleClassChange(value)
          }
          options={classChoices}
          getOptionLabel={(option) => option.grade_name}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Label"
              name="class_name"
              variant="outlined"
            />
          )}
        />
        <span className={classes.validationErrorInfo}>
          {errors.grade && errors.grade.message}
        </span>
        <InputLabel>Select Sections:</InputLabel>
        <div className={classes.selection}>
          {sectionList.length ? (
            <FormControl
              component="fieldset"
              className={classes.sectionCheckBox}
            >
              <FormGroup style={{ marginBottom: "20px" }}>
                {sectionList.map((element: SectionListI) => (
                  <FormControlLabel
                    key={element.id}
                    control={
                      <Checkbox
                        color="primary"
                        value={element.id}
                        onChange={handleSectionChange}
                        name={element.name}
                        checked={element.checked}
                      />
                    }
                    label={"Section " + element.name}
                  />
                ))}
              </FormGroup>
            </FormControl>
          ) : (
            <span className={classes.sectionUnavailableText}>
              ** No records found
            </span>
          )}
        </div>
        <InputLabel>Send to:</InputLabel>
        <div className={classes.selection}>
          <FormControl component="fieldset" className={classes.sectionCheckBox}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    value="parent"
                    onChange={handleSendToChange}
                    name="parent"
                    checked={sendTo.parent}
                  />
                }
                label="Parent"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    value="student"
                    onChange={handleSendToChange}
                    name="student"
                    checked={sendTo.student}
                  />
                }
                label="Student"
              />
            </FormGroup>
          </FormControl>
        </div>
        <span className={classes.validationErrorInfo}>
          {errors.sendTo && errors.sendTo.message}
        </span>
      </Paper>
    </>
  );
};

export default ClassTab;
