import {
  REPORTSETTING_LOADING,
  REPORTSETTING_ERROR,
  REPORTSETTING_SUCCESS,
  ADD_REPORTSETTING_LOADING,
  ADD_REPORTSETTING_ERROR,
  ADD_REPORTSETTING_SUCCESS,
  UPDATE_REPORTSETTING_LOADING,
  UPDATE_REPORTSETTING_ERROR,
  UPDATE_REPORTSETTING_SUCCESS,
  ReportSettingDispatchTypes,
} from "../../actions/GeneralSettings/ReportSetting/ReportSettingActionTypes";

interface InitialStateI {
  loading: boolean;
  reportsetting?: any;
  logo?: any;
  add_or_update: boolean;
}

interface ReportSettingI {
  id: number;
  logo?: any;
  school_name: string;
  address: string;
  phone_number: string;
  email: string;
  slogan: string;
  school_reg_number: string;
}

const initialState: InitialStateI = {
  loading: false,
  reportsetting: null,
  add_or_update: false,
};

const ReportSettingReducer = (
  state: any = initialState,
  action: any
): InitialStateI => {
  switch (action.type) {
    case REPORTSETTING_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case REPORTSETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        reportsetting: action.payload,
      };

    case REPORTSETTING_ERROR:
      return {
        ...state,
        loading: false,
      };

    case ADD_REPORTSETTING_LOADING:
      return {
        ...state,
        loading: true,
        reportsetting: state.reportsetting,
      };

    case ADD_REPORTSETTING_ERROR:
      return {
        ...state,
        loading: false,
        reportsetting: state.reportsetting,
      };

    case ADD_REPORTSETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        reportsetting: [...state.reportsetting, action.payload],
        add_or_update: true,
      };

    case UPDATE_REPORTSETTING_LOADING:
      return {
        ...state,
        loading: true,
        reportsetting: state.reportsetting,
      };

    case UPDATE_REPORTSETTING_ERROR:
      return {
        ...state,
        loading: false,
      };

    case UPDATE_REPORTSETTING_SUCCESS:
      const temp = state.reportsetting.filter(
        (temp: any) => temp.id !== action.payload.id
      );
      return {
        ...state,
        loading: false,
        product_variants: [action.payload, ...temp],
        add_or_update: true,
      };

    default:
      return state;
  }
};

export default ReportSettingReducer;
