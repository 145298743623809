import React from "react";
import { SettingSidebar } from "../../components";
import PrayerForm from "../../components/GeneralSetting/Prayer/PrayerForm";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const PrayerPage = () => {
  const nav = <SettingSidebar prayer />;
  return (
    <SubModuleLayout sideNav={nav}>
      <PrayerForm />
      {/* <ManagePasswordForm /> */}
    </SubModuleLayout>
  );
};

export default PrayerPage;
