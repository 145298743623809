import React, { useEffect, useState } from "react";
import { Button, Grid, InputLabel, Paper, TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useFormStyles } from "../../Styles/FormStyles";
import { RootStore } from "../../../store";
import { GetDates } from "../../../actions/Dates/DatesAction";
import {
  dateConverterAdToBs,
  dateConverterBsToAd,
} from "../../utils/dateConverter";
import { MONTH_CHOICES } from "../../../common/json.constant";
import { RowSelect } from "../../Reusable/Inputs/Select";
import { Tupple } from "../../../actions";
import { GetCheckinCheckout } from "../../../actions/HumanResource/Attendance/AttendanceAction";

interface FormI {
  month_name: string | null;
  children_name: number | null;
}

const ReportSearch = (props: any) => {
  const classes = useFormStyles();

  const { register, errors, setError } = useForm<FormI>();

  const [month, setMonth] = useState<Tupple | null>(null);
  const [children, setChildren] = useState<Tupple | null>(null);
  const [currentMonth, setCurrentMonth] = useState<number | null>(null);
  const [classChoice, setClassChoice] = useState<any | null>(null);
  const [sectionChoice, setSectionChoice] = useState<any | null>(null);
  const [sectionChoices, setSectionChoices] = useState<any | null>(null);

  const dispatch = useDispatch();
  const dateSelector = useSelector(
    (state: RootStore) => state.month_dates.data
  );

  const inputStyle = { marginBottom: "0px" };

  useEffect(() => {
    const utc = new Date().toJSON().slice(0, 10);
    setCurrentMonth(parseInt(dateConverterAdToBs(utc).substring(5, 7)) - 1);
    dispatch(GetDates(parseInt(dateConverterAdToBs(utc).substring(5, 7))));
  }, []);

  useEffect(() => {
    if (currentMonth != null) {
      dispatch(GetDates(currentMonth + 1));
      setMonth(MONTH_CHOICES[currentMonth]);
    }
  }, [currentMonth]);

  useEffect(() => {
    if (dateSelector.dates != null) {
      const searchData = {
        from_date: dateConverterBsToAd(dateSelector.dates[0]),
        to_date: dateConverterBsToAd(
          dateSelector.dates[dateSelector.dates.length - 1]
        ),
      };
      dispatch(GetCheckinCheckout(searchData.from_date, searchData.to_date));
    }
  }, [dateSelector.dates, month]);

  // useEffect(() => {
  //   props.setMonthName(month?.value);
  // }, [month]);

  const handleMonthChange = (value: Tupple) => {
    if (value != null) {
      setMonth(value);
      dispatch(GetDates(value.key));
    } else {
      setMonth(null);
    }
  };

  return (
    <>
      <Paper>
        <Grid container spacing={3}>
          <Grid item xs={3} className={classes.formWrapperRow}>
            <RowSelect
              fullWidth
              label="Month"
              name="month_name"
              options={MONTH_CHOICES}
              value={month}
              setValue={handleMonthChange}
              error={errors["month_name"] && errors["month_name"].message}
              inputRef={register({ required: true })}
              placeholder="Label"
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default ReportSearch;
