import React from 'react'
import { useStyles } from '../Styles/FormStyles'
import { Grid, InputLabel, TextField } from '@material-ui/core'

const ResetPassword = (props: any) => {
    const { password, setPassword, confirmPassword, setConfirmPassword, error, errorMsg } = props;
    const classes = useStyles();

    const handlePasswordChange = (e: any) => setPassword(e.target.value);
    const handleConfirmPasswordChange = (e: any) => setConfirmPassword(e.target.value);

    return (
        <form className={classes.form}>
            <Grid container>
                <Grid item md={12} className={classes.formWrapper} style={{ marginLeft: "3rem" }} justifyContent="space-between">

                    {/* New Password */}
                    <Grid item md={10} className={classes.formWrapper} style={{ marginBottom: "1rem" }}>
                        <InputLabel>
                            New Password <span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <TextField
                            variant="outlined"
                            autoComplete='off'
                            value={password}
                            onChange={handlePasswordChange}
                        />
                        {error && <span style={{ color: "red", fontSize: "12px" }}>{errorMsg}</span>}
                    </Grid>

                    <Grid item md={10} className={classes.formWrapper} style={{ marginBottom: "1rem" }}>
                        <InputLabel>
                            Confirm New Password <span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <TextField
                            variant="outlined"
                            autoComplete='off'
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange} />
                        {error && <span style={{ color: "red", fontSize: "12px" }}>{errorMsg}</span>}
                    </Grid>

                </Grid>
            </Grid>
        </form>
    )
}

export default ResetPassword