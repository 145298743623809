export const GET_STUDENT_ROUTE_LOADING = "GET_STUDENT_ROUTE_LOADING";
export const GET_STUDENT_ROUTE_ERROR = "GET_STUDENT_ROUTE_ERROR";
export const GET_STUDENT_ROUTE_SUCCESS = "GET_STUDENT_ROUTE_SUCCESS";

export const ADD_STUDENT_ROUTE_LOADING = "ADD_STUDENT_ROUTE_LOADING";
export const ADD_STUDENT_ROUTE_ERROR = "ADD_STUDENT_ROUTE_ERROR";
export const ADD_STUDENT_ROUTE_SUCCESS = "ADD_STUDENT_ROUTE_SUCCESS";

export const STUDENT_LIST_LOADING = "STUDENT_LIST_LOADING";
export const STUDENT_LIST_ERROR = "STUDENT_LIST_ERROR";
export const STUDENT_LIST_SUCCESS = "STUDENT_LIST_SUCCESS";

export const DELETE_STUDENT_TO_ROUTE_LOADING =
  "DELETE_STUDENT_TO_ROUTE_LOADING";
export const DELETE_STUDENT_TO_ROUTE_ERROR = "DELETE_STUDENT_TO_ROUTE_ERROR";
export const DELETE_STUDENT_TO_ROUTE_SUCCESS =
  "DELETE_STUDENT_TO_ROUTE_SUCCESS";

export const DELETE_STUDENT_ROUTE_SUCCESS = "DELETE_STUDENT_ROUTE_SUCCESS";

export type StudentRouteTypeI = {
  student_id: string;
  student_user__first_name: string;
  student_user__last_name: string;
  academic_info__grade__name: string | null;
  academic_info__section__name: string | null;
};

//===============================<START>GET StudentRoute<START>======================//

export interface GetStudentRouteLoading {
  type: typeof GET_STUDENT_ROUTE_LOADING;
}

export interface GetStudentRouteError {
  type: typeof GET_STUDENT_ROUTE_ERROR;
}

export interface GetStudentRouteSuccess {
  type: typeof GET_STUDENT_ROUTE_SUCCESS;
  payload: StudentRouteTypeI[];
}

//===============================<END>GET StudentRoute<END>======================//
export interface StudentListLoading {
  type: typeof STUDENT_LIST_LOADING;
}

export interface StudentListError {
  type: typeof STUDENT_LIST_ERROR;
}

export interface StudentListSuccess {
  type: typeof STUDENT_LIST_SUCCESS;
  payload: any;
}
//===============================<START>ADD StudentRoute<START>======================//

export interface AddStudentRouteLoading {
  type: typeof ADD_STUDENT_ROUTE_LOADING;
}

export interface AddStudentRouteError {
  type: typeof ADD_STUDENT_ROUTE_ERROR;
}

export interface AddStudentRouteSuccess {
  type: typeof ADD_STUDENT_ROUTE_SUCCESS;
  payload: { student_list: StudentRouteTypeI; s_id: string };
}

//===============================<START>DELETE StudentRoute<START>======================//

export interface DeleteStudenttoRouteLoading {
  type: typeof DELETE_STUDENT_TO_ROUTE_LOADING;
}

export interface DeleteStudenttoRouteError {
  type: typeof DELETE_STUDENT_TO_ROUTE_ERROR;
}

export interface DeleteStudenttoRouteSuccess {
  type: typeof DELETE_STUDENT_TO_ROUTE_SUCCESS;
  payload: { id: string };
}
export interface DeleteStudentRouteSuccess {
  type: typeof DELETE_STUDENT_ROUTE_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE StudentRoute<END>======================//

export type StudentRouteDispatchTypes =
  | GetStudentRouteLoading
  | GetStudentRouteError
  | GetStudentRouteSuccess
  | StudentListLoading
  | StudentListError
  | StudentListSuccess
  | AddStudentRouteLoading
  | AddStudentRouteError
  | AddStudentRouteSuccess
  | DeleteStudenttoRouteLoading
  | DeleteStudenttoRouteError
  | DeleteStudentRouteSuccess
  | DeleteStudenttoRouteSuccess;
