import {
    GET_DISCOUNT_SUMMARY_ERROR,
    GET_DISCOUNT_SUMMARY_LOADING,
    GET_DISCOUNT_SUMMARY_SUCCESS,
    DiscountSummaryDispatchTypes,
} from "../../actions/Fees/DiscountSummary/DiscountSummaryActionTypes";

interface InitialStateI {
    loading: boolean;
    discount_summary: any;
}

const initialState: InitialStateI = {
    loading: false,
    discount_summary: [],
};

const DiscountSummaryReducer = (
    state: InitialStateI = initialState,
    action: DiscountSummaryDispatchTypes
): InitialStateI => {
    switch (action.type) {
        case GET_DISCOUNT_SUMMARY_LOADING:
            return {
                ...state,
                loading: true,
            };

        case GET_DISCOUNT_SUMMARY_ERROR:
            return {
                ...state,
                loading: false,
            };

        case GET_DISCOUNT_SUMMARY_SUCCESS:
            return {
                ...state,
                loading: false,
                discount_summary: action.payload,
            };


        default:
            return state;
    }
};

export default DiscountSummaryReducer;