import {
  GET_SYSTEM_ROLES_LOADING,
  GET_SYSTEM_ROLES_ERROR,
  GET_SYSTEM_ROLES_SUCCESS,
  ADD_SYSTEM_ROLES_LOADING,
  ADD_SYSTEM_ROLES_SUCCESS,
  ADD_SYSTEM_ROLES_ERROR,
  DELETE_SYSTEM_ROLES_LOADING,
  DELETE_SYSTEM_ROLES_SUCCESS,
  DELETE_SYSTEM_ROLES_ERROR,
  SEND_USER_CREDENTIALS_LOADING,
  SEND_USER_CREDENTIALS_SUCCESS,
  SEND_USER_CREDENTIALS_ERROR,
  SystemRolesTypeI,
  SystemRolesDispatchTypes,
} from "../../actions/HumanResource/StaffRole/StaffRoleActionTypes";

interface InitialStateI {
  loading: boolean;
  system_roles: SystemRolesTypeI[];
  errors: any;
  recently_added: any;
  add_or_update: boolean;
}

const initialState: InitialStateI = {
  loading: false,
  system_roles: [],
  errors: null,
  recently_added: null,
  add_or_update: false,
};

const StaffRolesReducer = (
  state: InitialStateI = initialState,
  action: SystemRolesDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_SYSTEM_ROLES_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_SYSTEM_ROLES_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_SYSTEM_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        system_roles: action.payload,
        errors: null,
      };

    case ADD_SYSTEM_ROLES_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case ADD_SYSTEM_ROLES_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        add_or_update: true,
      };

    case ADD_SYSTEM_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        system_roles: [...state.system_roles, action.payload],
        recently_added: action.payload,
        add_or_update: true,
      };

    case DELETE_SYSTEM_ROLES_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_SYSTEM_ROLES_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_SYSTEM_ROLES_SUCCESS:
      const new_data: SystemRolesTypeI[] = state.system_roles;
      const item_index = new_data.findIndex(
        (element) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return { ...state, loading: false, system_roles: new_data };

    case SEND_USER_CREDENTIALS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case SEND_USER_CREDENTIALS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case SEND_USER_CREDENTIALS_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default StaffRolesReducer;
