import React from 'react';
import { Grid, Box, Typography, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      padding: '4rem 2rem',
      minHeight: '100vh',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#FAFAFC',
    },
    title: {
      fontWeight: 600,
      fontSize: 28,
    },
    subtitle: {
      opacity: '0.7',
    },
  };
});

interface AuthFormI {
  title: string;
  subtitle: React.ReactChild;
  children: React.ReactChild;
  onSubmit: () => void;
  [x: string]: any;
}

const AuthForm = (props: AuthFormI) => {
  const { title, subtitle, onSubmit, children, ...rest } = props;
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item>
        <Box style={{ maxWidth: 320 }}>
          <Typography variant="h5" color="primary" className={classes.title}>
            {title}
          </Typography>

          <Typography variant="body2" className={classes.subtitle}>
            {subtitle}
          </Typography>
        </Box>

        <form onSubmit={onSubmit} {...rest}>
          {children}
        </form>
      </Grid>
    </Grid>
  );
};

export default AuthForm;
