import React from "react";
import { Button, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

const AddNewButton = (props: any) => {
  const { children, title, ...rest } = props;

  return (
    <Tooltip title={title}>
      <Button
        type="button"
        color="primary"
        variant="outlined"
        startIcon={<AddIcon />}
        {...rest}
      >
        {children}
      </Button>
    </Tooltip>
  );
};

export default AddNewButton;
