import { Dispatch } from 'redux';
import { LogoutDispatchTypes, USER_LOGOUT_SUCCESS } from './LogoutActionTypes';
import { SetSnackBarI, SET_SNACKBAR } from '../../SnackbarActionTypes';

export const LogoutAction =
  () => async (dispatch: Dispatch<LogoutDispatchTypes | SetSnackBarI>) => {
    dispatch({ type: USER_LOGOUT_SUCCESS });

    dispatch({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarType: 'success',
        snackbarMessage: 'Logout Successfully',
      },
    });
  };
