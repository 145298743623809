import axios from 'axios';
import { Dispatch } from 'redux';

import {
  GET_ADMIN_STUDENT_LEAVE_LOADING,
  GET_ADMIN_STUDENT_LEAVE_ERROR,
  GET_ADMIN_STUDENT_LEAVE_SUCCESS,
  AdminStudentLeaveDispatchTypes,
  ADD_ADMIN_STUDENT_LEAVE_LOADING,
  ADD_ADMIN_STUDENT_LEAVE_SUCCESS,
  ADD_ADMIN_STUDENT_LEAVE_ERROR,
  DELETE_ADMIN_STUDENT_LEAVE_SUCCESS,
  DELETE_ADMIN_STUDENT_LEAVE_ERROR,
  DELETE_ADMIN_STUDENT_LEAVE_LOADING,
  UPDATE_ADMIN_STUDENT_LEAVE_LOADING,
  UPDATE_ADMIN_STUDENT_LEAVE_SUCCESS,
  UPDATE_ADMIN_STUDENT_LEAVE_ERROR,
  ADMIN_STUDENT_LEAVE_APPROVE_OR_DECLINE_SUCCESS,
  ADMIN_STUDENT_LEAVE_APPROVE_OR_DECLINE_LOADING,
  ADMIN_STUDENT_LEAVE_APPROVE_OR_DECLINE_ERROR,
  GET_STUDENT_TODAY_ON_LEAVE_LOADING,
  GET_STUDENT_TODAY_ON_LEAVE_SUCCESS,
  GET_STUDENT_TODAY_ON_LEAVE_ERROR,
} from './LeaveActionTypes';

import { SET_SNACKBAR, SetSnackBarI } from '../../SnackbarActionTypes';
import { HeaderConfig } from '../../Auth/Login/LoginAction';
import { ADMIN_API_URL } from '../../../config/SERVER_API_URLS';

interface GetLeavesType {
  perPage: number;
  page: number;
  leaveFrom?: string;
  leaveTo?: string;
}

export const GetLeaves =
  (params: GetLeavesType) =>
  async (dispatch: Dispatch<AdminStudentLeaveDispatchTypes | SetSnackBarI>) => {
    console.log('params', params);
    try {
      dispatch({ type: GET_ADMIN_STUDENT_LEAVE_LOADING });

      const baseUrl = `${ADMIN_API_URL}/student_leave`;
      const { perPage, page, leaveFrom = '', leaveTo = '' } = params;

      const paramsUrl = `${baseUrl}/?page=${page}&per_page=${perPage}`;
      const searchUrl = `${paramsUrl}&leave_from=${leaveFrom}&leave_to=${leaveTo}`;
      const res = await axios.get(searchUrl, HeaderConfig());

      dispatch({ type: GET_ADMIN_STUDENT_LEAVE_SUCCESS, payload: res.data });
    } catch (error) {
      dispatch({ type: GET_ADMIN_STUDENT_LEAVE_ERROR });
    }
  };

export const UpdateLeave =
  (id: string, data: any) =>
  async (dispatch: Dispatch<AdminStudentLeaveDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({ type: UPDATE_ADMIN_STUDENT_LEAVE_LOADING });

      const baseUrl = `${ADMIN_API_URL}/student_leave`;

      const res = await axios.put(`${baseUrl}/${id}/`, data, HeaderConfig());

      dispatch({ type: UPDATE_ADMIN_STUDENT_LEAVE_SUCCESS, payload: res.data });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'success',
          snackbarMessage: 'Leave Updated Successfully',
        },
      });
    } catch (error) {
      dispatch({ type: UPDATE_ADMIN_STUDENT_LEAVE_ERROR });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: 'Leave Update Failed',
        },
      });
    }
  };

export const GetTodayOnLeaves = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_STUDENT_TODAY_ON_LEAVE_LOADING });

    const res = await axios.get(`${ADMIN_API_URL}/leaves`, HeaderConfig());

    dispatch({
      type: GET_STUDENT_TODAY_ON_LEAVE_SUCCESS,
      payload: res.data.todays_leave,
    });
  } catch (error) {
    dispatch({ type: GET_STUDENT_TODAY_ON_LEAVE_ERROR });

    dispatch({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarType: 'error',
        snackbarMessage: 'Today on Leave Loading Failed',
      },
    });
  }
};

export const GetSearchedLeaves =
  (query: any) =>
  async (dispatch: Dispatch<AdminStudentLeaveDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_ADMIN_STUDENT_LEAVE_LOADING,
      });

      const url = `${ADMIN_API_URL}/leave/?leave_type=${query.leaveType}&from_date=${query.date_first}&to_date=${query.date_second}`;

      const res = await axios.get(url, HeaderConfig());

      dispatch({
        type: GET_ADMIN_STUDENT_LEAVE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_ADMIN_STUDENT_LEAVE_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: 'Leaves Loading Failed',
        },
      });
    }
  };

export const LeaveApproveOrDecline =
  (data: any) =>
  async (dispatch: Dispatch<AdminStudentLeaveDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADMIN_STUDENT_LEAVE_APPROVE_OR_DECLINE_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/leave/approve_or_decline_leave/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADMIN_STUDENT_LEAVE_APPROVE_OR_DECLINE_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'success',
          snackbarMessage: 'Leave status updated Successfully',
        },
      });
    } catch (error) {
      dispatch({
        type: ADMIN_STUDENT_LEAVE_APPROVE_OR_DECLINE_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: 'Leave approve or decline Failed',
        },
      });
    }
  };

export const AddLeave =
  (data: any) =>
  async (dispatch: Dispatch<AdminStudentLeaveDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_ADMIN_STUDENT_LEAVE_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/leave/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_ADMIN_STUDENT_LEAVE_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'success',
          snackbarMessage: 'Leave Added Successfully',
        },
      });
    } catch (error) {
      dispatch({
        type: ADD_ADMIN_STUDENT_LEAVE_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: 'Leave Add Failed',
        },
      });
    }
  };

export const DeleteLeave =
  (id: string) =>
  async (dispatch: Dispatch<AdminStudentLeaveDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_ADMIN_STUDENT_LEAVE_LOADING,
      });

      const res = await axios.delete(
        `${ADMIN_API_URL}/leave/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_ADMIN_STUDENT_LEAVE_SUCCESS,
        payload: { id: id },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'success',
          snackbarMessage: 'Leave Deleted Successfully',
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_ADMIN_STUDENT_LEAVE_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: 'Leave Delete Failed',
        },
      });
    }
  };
