import { UserType } from "../../Auth/Login/LoginActionTypes";

export const GET_STAFF_DOCUMENT_LOADING = "GET_STAFF_DOCUMENT_LOADING";
export const GET_STAFF_DOCUMENT_ERROR = "GET_STAFF_DOCUMENT_ERROR";
export const GET_STAFF_DOCUMENT_SUCCESS = "GET_STAFF_DOCUMENT_SUCCESS";

export const ADD_STAFF_DOCUMENT_LOADING = "ADD_STAFF_DOCUMENT_LOADING";
export const ADD_STAFF_DOCUMENT_ERROR = "ADD_STAFF_DOCUMENT_ERROR";
export const ADD_STAFF_DOCUMENT_SUCCESS = "ADD_STAFF_DOCUMENT_SUCCESS";

export const DELETE_STAFF_DOCUMENT_LOADING = "DELETE_STAFF_DOCUMENT_LOADING";
export const DELETE_STAFF_DOCUMENT_ERROR = "DELETE_STAFF_DOCUMENT_ERROR";
export const DELETE_STAFF_DOCUMENT_SUCCESS = "DELETE_STAFF_DOCUMENT_SUCCESS";

export type DocumentTypeI = {
  id: string;
  staff: string;
  title: string;
  document: any;
  created_by: string;
};

//===============================<START>GET STAFF<START>======================//
export interface GetStaffDocumentLoading {
  type: typeof GET_STAFF_DOCUMENT_LOADING;
}

export interface GetStaffDocumentError {
  type: typeof GET_STAFF_DOCUMENT_ERROR;
}

export interface GetStaffDocumentSuccess {
  type: typeof GET_STAFF_DOCUMENT_SUCCESS;
  payload: DocumentTypeI[];
}
//===============================<START>ADD CLASS<START>======================//

export interface AddStaffDocumentLoading {
  type: typeof ADD_STAFF_DOCUMENT_LOADING;
}

export interface AddStaffDocumentError {
  type: typeof ADD_STAFF_DOCUMENT_ERROR;
}

export interface AddStaffDocumentSuccess {
  type: typeof ADD_STAFF_DOCUMENT_SUCCESS;
  payload: DocumentTypeI;
}

//===============================<END>ADD CLASS<END>======================//

//===============================<START>DELETE CLASS<START>======================//

export interface DeleteStaffDocumentLoading {
  type: typeof DELETE_STAFF_DOCUMENT_LOADING;
}

export interface DeleteStaffDocumentError {
  type: typeof DELETE_STAFF_DOCUMENT_ERROR;
}

export interface DeleteStaffDocumentSuccess {
  type: typeof DELETE_STAFF_DOCUMENT_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE CLASS<END>======================//

export type DocumentDispatchTypes =
  | GetStaffDocumentLoading
  | GetStaffDocumentError
  | GetStaffDocumentSuccess
  | AddStaffDocumentLoading
  | AddStaffDocumentError
  | AddStaffDocumentSuccess
  | DeleteStaffDocumentLoading
  | DeleteStaffDocumentError
  | DeleteStaffDocumentSuccess;
