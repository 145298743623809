//---------------------------- <START> module imports start ----------------------------------//
import React, { useEffect, useState } from 'react';
import {
  TableBody,
  TextField,
  Grid,
  Typography,
  Paper,
  Checkbox,
  Button,
  LinearProgress,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { FormatColorResetTwoTone, Send, Visibility } from '@material-ui/icons';
import { useTable } from '../../Reusable';
import { useTableStyles } from '../../Styles/TableStyles';
import { Pagination } from '@material-ui/lab';
import ResultModal from './ResultModal';
import cx from 'clsx';
import TableActionButtons from '../../Reusable/Buttons/TableActionButtons';
import ActionButton from '../../Reusable/Buttons/ActionButton';
import { SearchTitleI } from '../../Communication/SendResults/ResultSMS';
import { ResultTypeI } from '../../../actions/Examination/Result/ResultActionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import { GetMarkSheet } from '../../../actions/Examination/MarkSheet/MarkSheetAction';
import MarkSheetModal from '../../Examination/Results/MarkSheetModal';
import Popups from '../../Reusable/Dialogs/Popups';
import GenerateResultModalView from './GenerateResultModal';
import { setSnackbar } from '../../../actions/SnackbarAction';
import { SendResultSms } from '../../../actions/Communication/SendResult/SendResultAction';
//----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//
interface PropsI {
  searchTitle: SearchTitleI | null;
}

interface HeadCellsI {
  id: string;
  label: string;
}
// ---------------------------- <END> interface ends ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: 'student_name', label: 'Student Name' },
  { id: 'roll_no', label: 'Roll No' },
  { id: 'gpa', label: 'GPA' },
  { id: 'percentage', label: 'Percentage' },
  { id: 'student_position', label: 'Position' },
  { id: 'action', label: 'Action' },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const SendResultforClass = (props: PropsI) => {
  const { searchTitle } = props;
  const classes = useTableStyles();

  const [open, setOpen] = React.useState(false);
  const [openPopup, setOpenPopup] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setOpenPopup(false);
  };

  // States declaration
  const [tableData, setTableData] = useState<ResultTypeI[]>([]);
  const [tempTableData, setTempTableData] = useState<ResultTypeI[]>([]);

  const [totalRecord, setTotalRecord] = useState(tempTableData.length);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [pageCount, setPageCount] = useState(
    Math.ceil(totalRecord / rowsPerPage)
  );
  const [page, setPage] = useState(1);

  const [recordFrom, setRecordFrom] = useState(1);
  const [recordTo, setRecordTo] = useState(7);

  const [studentName, setStudentName] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);

  //============================== <START> REACT HOOKS <START> =========================//
  const dispatch = useDispatch();
  const resultSelector = useSelector((state: RootStore) => state.result);

  useEffect(() => {
    const data = resultSelector.result;

    if (searchTitle) {
      // setLoading(resultSelector.loading);

      setTableData(data);
      setTempTableData(data);

      setTotalRecord(data.length);
      setPageCount(Math.ceil(data.length / rowsPerPage));

      setLoading(resultSelector.loading);
    }
  }, [resultSelector, resultSelector.result]);
  //============================== <END> REACT HOOKS <END> =========================//

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
    setRecordFrom(newPage === 1 ? newPage : (newPage - 1) * rowsPerPage + 1);
    setRecordTo(newPage === 1 ? newPage * rowsPerPage : newPage * rowsPerPage);
  };

  // Retrieving re-usable components from useTable
  const {
    TblContainer,
    StyledTableCell,
    StyledTableRow,
    exportToExcel,
    tablePrint,
    TblHead,
  } = useTable(headCells);

  // Event function for table searching by name
  const handleTableSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const search_data = tempTableData.filter((data) =>
      data.student_first_name
        .toUpperCase()
        .includes(e.target.value.toUpperCase())
    );
    setTotalRecord(search_data.length);
    setPageCount(Math.ceil(search_data.length / rowsPerPage));
    setPage(1);
    setRecordFrom(1);
    setRecordTo(rowsPerPage);
    setTableData(search_data);
  };

  const [selected, setSelected] = React.useState<string[]>([]);
  const [studentArr, setStudentArr] = useState<string[]>([]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = tableData.map((n) => String(n.student_academic));
      const newStudArr: string[] = [];
      setSelected(newSelecteds);

      newSelecteds.map((element) => {
        newStudArr.push(element);
      });

      setStudentArr(newStudArr);
      return;
    }
    setSelected([]);
    setStudentArr([]);
  };

  const handleCheckClick = (name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];
    const newStudentArr: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);

    newSelected.map((element) => {
      newStudentArr.push(element);
    });

    setStudentArr(newStudentArr);
  };

  const handleOpenPopup = () => {
    if (searchTitle && studentArr.length) {
      setOpenPopup(true);
    } else {
      dispatch(setSnackbar(true, 'warning', 'Select Students'));
    }
  };

  const handleClickOpen = (student_id: string, student_name: string) => {
    setOpen(true);
    setStudentName(student_name);

    if (searchTitle) {
      dispatch(GetMarkSheet(student_id, searchTitle.exam_info));
    }
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const onActionConfirmed = (val: any) => {
    if (searchTitle) {
      const post_data = {
        exam_info: searchTitle.exam_info,
        ...val,
        student_academic_id: studentArr,
      };
      dispatch(SendResultSms(post_data));
    }
    setOpenPopup(false);
  };
  return (
    <>
      <Paper className={classes.rootTableBox}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          fullWidth
          className={classes.modalBox}
        >
          <div style={{ padding: '20px' }}>
            <MarkSheetModal onClose={handleClose} student={studentName} />
          </div>
        </Dialog>

        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">
              {searchTitle
                ? `Result for Class ${searchTitle.class} ${
                    searchTitle.section && `(${searchTitle.section})`
                  }`
                : 'Result'}
            </Typography>
          </Grid>
        </Grid>
        <div className={classes.tableWrapper}>
          <div className={classes.tableTop}>
            <TextField
              autoComplete="off"
              autoFocus={false}
              variant="outlined"
              name="search"
              placeholder="Search by Name ..."
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleTableSearch(e)
              }
            />

            <TableActionButtons
              exportToExcel={exportToExcel}
              tablePrint={tablePrint}
            />
          </div>
          <TblContainer>
            <TblHead
              hasCheckbox
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={tableData.length}
            />

            <TableBody>
              {tableData
                .slice(
                  (page - 1) * rowsPerPage,
                  (page - 1) * rowsPerPage + rowsPerPage
                )
                .map((row, index) => {
                  const isItemSelected = isSelected(
                    String(row.student_academic)
                  );
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const cellStyle = cx(classes.cell, classes.cellSm);

                  return (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.student_academic}
                      selected={isItemSelected}
                    >
                      <StyledTableCell className={cellStyle} align="left">
                        <Checkbox
                          color="default"
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          onClick={() =>
                            handleCheckClick(String(row.student_academic))
                          }
                        />
                        {row.student_first_name} {row.student_last_name}
                      </StyledTableCell>
                      <StyledTableCell className={cellStyle} align="center">
                        {row.student_roll_number}
                      </StyledTableCell>
                      <StyledTableCell className={cellStyle} align="center">
                        {row.obtain_gpa}
                      </StyledTableCell>
                      <StyledTableCell className={cellStyle} align="center">
                        {row.obtain_percentage}
                      </StyledTableCell>
                      <StyledTableCell className={cellStyle} align="center">
                        {index + 1}
                      </StyledTableCell>

                      <StyledTableCell className={cellStyle} align="center">
                        <ActionButton
                          title="View"
                          onClick={(
                            event: React.MouseEvent<
                              HTMLAnchorElement,
                              MouseEvent
                            >
                          ) =>
                            handleClickOpen(
                              row.student_academic,
                              `${row.student_first_name} ${row.student_last_name}`
                            )
                          }
                        >
                          <Visibility className={classes.addIcon} />
                        </ActionButton>
                        {/* <a className={classes.Btn} onClick={handleClickOpen}>
                          <Visibility className={classes.addIcon} />
                        </a> */}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </TblContainer>
          {loading ? <LinearProgress /> : null}
          <div className={classes.tableBottom}>
            {totalRecord > 0 ? (
              <Typography>
                Showing {recordFrom} to{' '}
                {recordTo > totalRecord ? totalRecord : recordTo} of{' '}
                {totalRecord}
              </Typography>
            ) : (
              <Typography>No records found</Typography>
            )}
            <div className={classes.pageNavigation}>
              <Pagination
                count={pageCount}
                page={page}
                onChange={handlePageChange}
                shape="rounded"
                color="primary"
              />
            </div>
          </div>
        </div>
        <Grid item>
          <Grid
            container
            justifyContent="flex-end"
            spacing={2}
            className={classes.tableBottomButtons}
          >
            {tableData.length ? (
              <Grid item>
                <Button
                  style={{ marginTop: '16px' }}
                  type="submit"
                  color="primary"
                  variant="contained"
                  startIcon={<Visibility />}
                  onClick={() => handleOpenPopup()}
                >
                  Generate
                </Button>
              </Grid>
            ) : (
              ''
            )}

            {/* <Grid item>
              <Button
                style={{ marginTop: "16px" }}
                type="submit"
                color="primary"
                variant="outlined"
                startIcon={<Send />}
                onClick={() => alert(selected)}
              >
                Send
              </Button>
            </Grid> */}
          </Grid>
        </Grid>
      </Paper>
      <Popups
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        onClose={handleClose}
        maxWidth="sm"
      >
        <GenerateResultModalView
          actionConfirmed={(val) => onActionConfirmed(val)}
          studentIds={studentArr}
        />
      </Popups>
    </>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default SendResultforClass;
