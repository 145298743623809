import { Button, Chip, Divider, Grid, Typography } from "@material-ui/core";
import { Send } from "@material-ui/icons";
import React from "react";
import { dateConverterAdToBs } from "../../../utils/dateConverter";
import { ExtendedPostDataI } from "./AddAnnoucementModal";
import parse from "html-react-parser";

interface PropsI {
  actionConfirmed: (value: boolean) => void;
  data: ExtendedPostDataI | null;
}

const ConfirmationModalView = (props: PropsI) => {
  const { actionConfirmed, data } = props;

  const handleClick = (val: boolean) => {
    actionConfirmed(val);
  };

  const getPriority = (val: string) => {
    switch (val) {
      case "L":
        return "Low";

      case "N":
        return "Normal";

      case "H":
        return "High";

      default:
        break;
    }
  };

  const parseHtmlContent = (content: string) => {
    const doc1 = parse(content);
    return doc1;
  };

  const createChips = (
    groups: string[],
    users: string[],
    grade: string,
    sections: string[]
  ) => {
    const getArray = (): string[] => {
      if (groups.length) {
        return groups;
      } else if (users.length) {
        return users;
      } else if (grade !== "") {
        if (sections.length) {
          const sectionArr: string[] = [];
          sections.map((element) => {
            sectionArr.push(`Class ${grade} (${element})`);
          });
          return sectionArr;
        } else {
          return [`Class ${grade}`];
        }
      } else {
        return [];
      }
    };

    return (
      <Grid container spacing={1}>
        {getArray().map((element: string, index: number) => (
          <Grid item key={index + 1}>
            <Chip
              style={{ backgroundColor: "#A1ABD6", color: "white" }}
              size="small"
              label={element}
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <React.Fragment>
      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={4}>
          Title:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {data && data.title}
          </Typography>
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={4}>
          Priority:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {data && getPriority(data.priority)}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={4}>
          Notice Date:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {data && dateConverterAdToBs(data.announcement_date)}
          </Typography>
        </Grid>
      </Grid>
      <Divider style={{ marginBottom: "20px" }} />
      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={4}>
          Sending To:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {data &&
              createChips(
                data.groupLabel,
                data.userLabel,
                data.gradeLabel,
                data.sectionLabel
              )}
          </Typography>
        </Grid>
      </Grid>
      <Divider style={{ marginBottom: "20px" }} />
      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={4}>
          Description:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {data && parseHtmlContent(data.description)}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        style={{ marginTop: "15px", marginBottom: "5px" }}
      >
        <Grid item style={{ marginLeft: "auto" }}>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<Send />}
            onClick={() => handleClick(true)}
          >
            Send
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ConfirmationModalView;
