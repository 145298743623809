import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { SessionForm, SessionTable, SettingSidebar } from "../../components";
import RolesForm from "../../components/GeneralSetting/Roles/RolesForm";
import RolesTable from "../../components/GeneralSetting/Roles/RolesTable";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const RolesPage = () => {
  const [formData, setFormData] = useState<any | null>(null);

  const fetchEditData = (data: any) => {
    setFormData(data);
  };

  const nav = <SettingSidebar roles />;
  return (
    <SubModuleLayout sideNav={nav}>
      <Grid container>
        <Grid item xs={3}>
          <RolesForm />
          {/* <SessionForm editData={formData} /> */}
        </Grid>
        <Grid item xs={9}>
          <RolesTable />
          {/* <SessionTable onEditData={fetchEditData} /> */}
        </Grid>
      </Grid>
    </SubModuleLayout>
  );
};

export default RolesPage;
