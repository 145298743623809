import { v4 as uuidv4 } from "uuid";
import moment, { duration } from "moment";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  addTimePeriods,
  copyTimePeriods,
  getTimePeriodByGrade,
  getTimePeriods,
  updateTimePeriods,
} from "./periodApi";

export interface PeriodsI {
  id: string;
  name: string;
  start_time: string;
  duration?: string;
  end_time: string;
  from_db: boolean;
}

const initialState: {
  periods: PeriodsI[];
  classPeriods: {
    grade: {
      id: string;
      name: string;
      grade_name: string;
    };
    periods: PeriodsI[];
  }[];
  periodsByClass: {
    grade: {
      id: string;
      name: string;
      grade_name: string;
    };
    periods: PeriodsI[];
  } | null;
  actionPerformed: boolean;
  loading: boolean;
} = {
  periods: [],
  classPeriods: [],
  periodsByClass: null,
  actionPerformed: false,
  loading: false,
};

const classPeriod = createSlice({
  name: "classPeriod",
  initialState,
  reducers: {
    addPeriod: (state) => {
      let start_time = "";
      let duration = "";
      let end_time = "";
      if (state.periods.length) {
        start_time = state.periods[state.periods.length - 1].end_time;
        duration = state.periods[state.periods.length - 1].duration || "";

        end_time =
          moment(start_time, "HH:mm")
            .add(parseInt(duration || "0"), "minutes")
            .format("HH:mm") || "";
      }

      state.periods.push({
        id: uuidv4(),
        name: "",
        start_time,
        duration: duration,
        end_time: end_time,
        from_db: false,
      });
      state.actionPerformed = false;
    },
    updatePeriod: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: string;
        field: "name" | "start_time" | "duration";
        value: string;
      }>
    ) => {
      const mIndex = state.periods.findIndex((item) => item.id === payload.id);

      if (mIndex !== -1) {
        if (payload.field === "name") {
          state.periods[mIndex] = {
            ...state.periods[mIndex],
            name: payload.value,
          };
        }
        if (payload.field === "start_time") {
          const startTime = payload.value;
          const duration = state.periods[mIndex].duration;

          const endTime = moment(startTime, "HH:mm")
            .add(parseInt(duration || "0"), "minutes")
            .format("HH:mm");

          state.periods[mIndex] = {
            ...state.periods[mIndex],
            start_time: startTime,
            end_time: endTime,
          };
        }
        if (payload.field === "duration") {
          const regex = /^[0-9]*$/;

          if (regex.test(payload.value) && payload.value.length <= 3) {
            const startTime = state.periods[mIndex].start_time;
            const parsedTime = moment(startTime, "HH:mm")
              .add(parseInt(payload.value), "minutes")
              .format("HH:mm");

            state.periods[mIndex] = {
              ...state.periods[mIndex],
              duration: payload.value,
              end_time: parsedTime,
            };
          }
        }
      }
    },
    deletePeriod: (state, { payload }: PayloadAction<string>) => {
      state.periods = state.periods.filter((item) => item.id !== payload);
    },
    removeAllPeriods: (state) => {
      state.periods = [];
    },
    addPeriodToClass: (state) => {
      if (state.periodsByClass) {
        let start_time = "";
        let duration = "";
        let end_time = "";

        if (state.periodsByClass.periods.length) {
          start_time =
            state.periodsByClass.periods[
              state.periodsByClass.periods.length - 1
            ].end_time;
          duration =
            state.periodsByClass.periods[
              state.periodsByClass.periods.length - 1
            ].duration || "";
          end_time =
            moment(start_time, "HH:mm")
              .add(parseInt(duration || "0"), "minutes")
              .format("HH:mm") || "";
        }

        state.periodsByClass.periods.push({
          id: uuidv4(),
          name: "",
          start_time,
          duration,
          end_time,
          from_db: false,
        });
        state.actionPerformed = false;
      }
    },
    updatePeriodOnClass: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: string;
        field: "name" | "start_time" | "duration";
        value: string;
      }>
    ) => {
      if (state.periodsByClass) {
        const mIndex = state.periodsByClass.periods.findIndex(
          (item) => item.id === payload.id
        );

        if (mIndex !== -1) {
          if (payload.field === "name") {
            state.periodsByClass.periods[mIndex] = {
              ...state.periodsByClass.periods[mIndex],
              name: payload.value,
            };
          }
          if (payload.field === "start_time") {
            const startTime = payload.value;
            const duration = state.periodsByClass.periods[mIndex].duration;

            const endTime = moment(startTime, "HH:mm")
              .add(parseInt(duration || "0"), "minutes")
              .format("HH:mm");

            state.periodsByClass.periods[mIndex] = {
              ...state.periodsByClass.periods[mIndex],
              start_time: startTime,
              end_time: endTime,
            };
          }
          if (payload.field === "duration") {
            const regex = /^[0-9]*$/;

            if (regex.test(payload.value) && payload.value.length <= 3) {
              const startTime = state.periodsByClass.periods[mIndex].start_time;
              const parsedTime = moment(startTime, "HH:mm")
                .add(parseInt(payload.value), "minutes")
                .format("HH:mm");

              state.periodsByClass.periods[mIndex] = {
                ...state.periodsByClass.periods[mIndex],
                duration: payload.value,
                end_time: parsedTime,
              };
            }
          }
        }
      }
    },
    deletePeriodFromClass: (state, { payload }: PayloadAction<string>) => {
      if (state.periodsByClass) {
        state.periodsByClass.periods = state.periodsByClass.periods.filter(
          (item) => item.id !== payload
        );
      }
    },
    resetPeriodState: (state) => {
      state.periods = [];
      state.periodsByClass = null;
      state.actionPerformed = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addTimePeriods.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(addTimePeriods.fulfilled, (state) => {
      state.loading = false;
      state.actionPerformed = true;
      state.periods = [];
    });
    builder.addCase(addTimePeriods.rejected, (state) => {
      state.loading = false;
      state.actionPerformed = false;
    });

    builder.addCase(getTimePeriods.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(
      getTimePeriods.fulfilled,
      (
        state,
        {
          payload,
        }: PayloadAction<
          {
            grade: { id: string; name: string; grade_name: string };
            periods: {
              id: string;
              name: string;
              start_time: string;
              end_time: string;
            }[];
          }[]
        >
      ) => {
        state.loading = false;
        state.classPeriods = payload.map((item) => ({
          grade: item.grade,
          periods: item.periods.map((p) => ({ ...p, from_db: true })),
        }));
      }
    );
    builder.addCase(getTimePeriods.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getTimePeriodByGrade.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(
      getTimePeriodByGrade.fulfilled,
      (
        state,
        {
          payload,
        }: PayloadAction<
          {
            grade: { id: string; name: string; grade_name: string };
            periods: {
              id: string;
              name: string;
              start_time: string;
              end_time: string;
            }[];
          }[]
        >
      ) => {
        state.loading = false;
        if (payload.length) {
          state.periodsByClass = {
            grade: payload[0].grade,
            periods: payload[0].periods.map((p) => {
              const minDiff = moment
                .duration(
                  moment(p.end_time, "HH:mm").diff(
                    moment(p.start_time, "HH:mm")
                  )
                )
                .asMinutes();

              return {
                ...p,
                duration: String(minDiff),
                from_db: true,
              };
            }),
          };
        } else {
          state.periodsByClass = null;
        }
      }
    );
    builder.addCase(getTimePeriodByGrade.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateTimePeriods.pending, (state) => {
      state.actionPerformed = false;
      state.loading = true;
    });
    builder.addCase(updateTimePeriods.fulfilled, (state) => {
      state.actionPerformed = true;
      state.loading = false;
    });
    builder.addCase(updateTimePeriods.rejected, (state) => {
      state.actionPerformed = false;
      state.loading = false;
    });

    builder.addCase(copyTimePeriods.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(copyTimePeriods.fulfilled, (state) => {
      state.loading = false;
      state.actionPerformed = true;
    });
    builder.addCase(copyTimePeriods.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  addPeriod: addPeriodAction,
  updatePeriod: updatePeriodAction,
  deletePeriod: deletePeriodAction,
  removeAllPeriods: removeAllPeriodsAction,
  addPeriodToClass: addPeriodToClassAction,
  updatePeriodOnClass: updatePeriodOnClassAction,
  deletePeriodFromClass: deletePeriodFromClassAction,
  resetPeriodState: resetPeriodStateAction,
} = classPeriod.actions;

export default classPeriod.reducer;
