//---------------------------<START> import modules starts-----------------------//
import React, { useEffect, useState } from 'react';
import { Grid, Paper } from '@material-ui/core';
import { useForm } from 'react-hook-form';
// import { useFormStyles } from "../../Styles/FormStyles";
import { useFormStyles } from '../../../../Styles/FormStyles';
import { useDispatch, useSelector } from 'react-redux';
import { AddLeave } from '../../../../../actions/HumanResource/Leave/LeaveAction';
import { RootStore } from '../../../../../store';
import { GetLeaveTypes } from '../../../../../actions/HumanResource/LeaveType/LeaveTypeAction';
import CustomizedTextField from '../../../../Reusable/Inputs/TextField';
import CustomizedNepaliDatePicker from '../../../../Reusable/Inputs/NepaliDatePicker';
import CustomizedSelect from '../../../../Reusable/Inputs/Select';
import { dateConverterBsToAd } from '../../../../utils/dateConverter';
import { FormSendButon } from '../../../../Reusable/Buttons/FormButton';
import { Tupple } from '../../../../../actions';
import { useParams } from 'react-router-dom';
import { CUR_NEPALI_DATE } from '../../../../utils/nepaliDateUtils';
//-------------------------<END> import modules ends ----------------------------//

//--------------------------<START> Add Annoucement Modal components starts---------------------------------------------------//
const LeaveRequestModal = (props: any) => {
  const params = useParams();
  const classes = useFormStyles();
  const dispatch = useDispatch();

  const [dateFrom, setDateFrom] = useState<any>(CUR_NEPALI_DATE);
  const [dateTo, setDateTo] = useState<any>(CUR_NEPALI_DATE);
  const [leaveType, setLeaveType] = useState<Tupple | null>(null);

  useEffect(() => {
    dispatch(GetLeaveTypes());
  }, []);

  const leaveTypeState = useSelector((state: RootStore) => state.leave_type);
  const studentState = useSelector(
    (state: RootStore) => state.student_profile.student
  );
  const LEAVE_TYPE_CHOICES = leaveTypeState.leave_types.map((item) => ({
    key: item.id.toString(),
    value: item.name,
  }));

  //-------------------<START> React hooks forms starts ----------------------//
  const { register, handleSubmit, errors } = useForm<Tupple>();

  const onSubmit = (data: any) => {
    const tempData = {
      leave_from: dateConverterBsToAd(dateFrom),
      leave_to: dateConverterBsToAd(dateTo),
      reason_for_leave: data.description,
      student_academic: studentState.academic_info[0].id,
    };

    console.log('Fired!', tempData);

    dispatch(AddLeave(tempData));
    props.onClose();
  };
  //------------------------<END> React Hooks form ends -------------------------//
  return (
    <Paper elevation={0}>
      <div className={classes.modalContent}>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid container spacing={1} item xs={12}>
              <Grid item xs={6} className={classes.formWrapper}>
                <CustomizedNepaliDatePicker
                  label="Leave from Date"
                  value={dateFrom}
                  setValue={setDateFrom}
                />
              </Grid>

              <Grid item xs={6} className={classes.formWrapper}>
                <CustomizedNepaliDatePicker
                  label="Leave to Date"
                  value={dateTo}
                  setValue={setDateTo}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} className={classes.formWrapper}>
              <CustomizedTextField
                label="Description"
                placeholder="Label"
                name="description"
                required
                multiline
                rows={4}
                error={errors['description'] ? 'Required Field.' : ''}
                inputRef={register({ required: true })}
              />
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent="flex-end"
            style={{ paddingBottom: '8px' }}
          >
            <FormSendButon />
          </Grid>
        </form>
      </div>
    </Paper>
  );
};
//---------------------------<END> Add Annoucement Modal components end ---------------------------//
export default LeaveRequestModal;
