import React from "react";
import { ReportSideBar } from "../../../../components";
import MyCharacterCertificate from "../../../../components/Reports/Templates/CharacterCertificate/MyCharacterCertificate";
import SubModuleLayout from "../../../../components/Reusable/Layouts/SubModuleLayout";

const CharacterCertificateTemplatePage = () => {
  const nav = <ReportSideBar character_certificate_template />;
  return (
    <SubModuleLayout reportMenuActive sideNav={nav}>
      <MyCharacterCertificate />
    </SubModuleLayout>
  );
};

export default CharacterCertificateTemplatePage;
