import CardImg from "../../../../assets/images/studentPotrait1.png";
import CardImg1 from "../../../../assets/images/studentLandscape1.png";
import CardImg2 from "../../../../assets/images/teacherPotrait1.png";
import CardImg3 from "../../../../assets/images/teacherLandscape1.png";

export const potraitData = [
  {
    id: 1,
    code: "ABC-001",
    thumbnail: CardImg,
    type: "Student",
    mode: "potrait",
    text_color: [
      {
        key: "header",
        value: "#ffffff",
        name: "Header Text",
      },
      {
        key: "footer",
        value: "#ffffff",
        name: "Footer Text",
      },
      {
        key: "body",
        value: "#444444",
        name: "Body Text",
      },
    ],
    back_ground_color: [
      {
        key: "header",
        value: "#3780ce",
        name: "Header Background",
      },
      {
        key: "footer",
        value: "#3780ce",
        name: "Footer Background",
      },
      {
        key: "body",
        value: "#ffffff",
        name: "Body Background",
      },
    ],
  },
  {
    id: 2,
    code: "ABC-002",
    thumbnail: CardImg3,
    type: "Staff",
    mode: "potrait",
    text_color: [
      {
        key: "header",
        value: "#ffff",
        name: "Header Text",
      },
      {
        key: "footer",
        value: "#ffff",
        name: "Footer Text",
      },
      {
        key: "body",
        value: "#444444",
        name: "Body Text",
      },
    ],
    back_ground_color: [
      {
        key: "header",
        value: "#3780ce",
        name: "Header Background",
      },
      {
        key: "footer",
        value: "#3780ce",
        name: "Footer Background",
      },
      {
        key: "body",
        value: "#ffffff",
        name: "Body Background",
      },
    ],
  },
];

export const landscapeData = [
  {
    id: 3,
    code: "ABC-003",
    thumbnail: CardImg1,
    type: "Student",
    mode: "landscape",
    text_color: [
      {
        key: "header",
        value: "#ffffff",
        name: "Header",
      },
      {
        key: "footer",
        value: "#ffffff",
        name: "Footer",
      },
      {
        key: "body",
        value: "#444444",
        name: "Body",
      },
    ],
    back_ground_color: [
      {
        key: "header",
        value: "#3780ce",
        name: "Header",
      },
      {
        key: "footer",
        value: "#3780ce",
        name: "Footer",
      },
      {
        key: "body",
        value: "#ffffff",
        name: "Body",
      },
    ],
  },
  {
    id: 4,
    code: "ABC-004",
    thumbnail: CardImg2,
    type: "Staff",
    mode: "landscape",
    text_color: [
      {
        key: "header",
        value: "#3780ce",
        name: "Header",
      },
      {
        key: "footer",
        value: "#ffffff",
        name: "Footer",
      },
      {
        key: "body",
        value: "#444444",
        name: "Body",
      },
      {
        key: "teacher-text",
        value: "#ffff",
        name: "Designation",
      },
    ],
    back_ground_color: [
      {
        key: "footer",
        value: "#3780ce",
        name: "Footer",
      },
      {
        key: "body",
        value: "#ffff",
        name: "Body",
      },
      {
        key: "teacher-text",
        value: "#444444",
        name: "Designation",
      },
    ],
  },
];

export function getSingleCard(id: string, mode: string) {
  if (mode === "landscape") {
    return landscapeData.find((item) => item.id == parseInt(id));
  } else {
    return potraitData.find((item) => item.id == parseInt(id));
  }
}
