import React from 'react';
import {
  Paper,
  makeStyles,
  createStyles,
  Theme,
  Tooltip,
  Typography,
  Grid,
  Link,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import BoardHeader from '../Calendar/BoardHeader';
import LeaveList from '../../Student/StudentLeave/LeaveList';
import DashboardLeaveRequestList from './DashboardLeaveRequestList';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2.5, 3, 3, 3),
      '& .fc-prev-button': {
        border: 'none',
      },
      '& .fc-next-button': {
        border: 'none',
      },
      '& .fc-toolbar-title': {
        fontSize: '12px',
      },
      '& .fc-direction-ltr .fc-toolbar > * > :not(:first-child)': {
        marginLeft: theme.spacing(0),
      },
      '& .fc .fc-button-primary': {
        color: 'black',
        backgroundColor: 'white',
        borderColor: '#929292',
        borderRadius: '3px',
        margin: '5px',
      },
      '& .fc .fc-button-primary:not(:disabled):active, .fc .fc-button-primary:not(:disabled).fc-button-active':
        {
          backgroundColor: '#132E98',
          boxShadow: 'none',
          borderColor: '#132E98',
        },
      '& .fc .fc-button-primary:focus': {
        boxShadow: 'none',
      },
      '& .fc .fc-daygrid-day.fc-day-today': {
        backgroundColor: '#d7dff5',
        color: '#000',
      },
      '& .fc .fc-daygrid-day-number': {
        fontSize: '11px',
      },
    },
    eventList: {
      display: 'flex',
      overflow: 'hidden',
      alignItems: 'center',
    },
    boxHeaderLink: {
      '&:hover': {
        textDecoration: 'none',
        paddingBottom: '2px',
        borderBottom: ' 2px solid',
      },
    },
  })
);

const EventCalendar = (props: any) => {
  const classes = useStyles();

  const handlePopover = (arg: any) => {
    return (
      <Tooltip
        title={
          <Typography>
            {arg.event.extendedProps.description
              ? arg.event.extendedProps.description
              : arg.event.title}
          </Typography>
        }
        arrow
      >
        <div className={classes.eventList}>
          {arg.timeText && (
            <>
              <div
                className="fc-daygrid-event-dot"
                style={{ borderColor: arg.backgroundColor }}
              />
              <div className="fc-event-time">{arg.timeText}</div>
            </>
          )}
          <div className="fc-event-title-container">
            <div className="fc-event-title fc-sticky">
              {arg.event.title || <>&nbsp;</>}
            </div>
          </div>
        </div>
      </Tooltip>
    );
  };

  const date = new Date();
  const EnglishMonth = date.toLocaleString('default', {
    month: 'long',
    year: 'numeric',
  });

  return (
    <Paper elevation={1}>
      <div className={classes.root}>
        <BoardHeader title="Leave Requests" date={EnglishMonth} to="/leave" />

        <DashboardLeaveRequestList />
      </div>
    </Paper>
  );
};

export default EventCalendar;
