// ---------------------------- <START> Import Module Starts ---------------------------//
import React, { useEffect, useState } from 'react';
import {
  Paper,
  Grid,
  Typography,
  Tooltip,
} from '@material-ui/core';
import {
  Add,
  DeleteForeverOutlined,
  GetApp,
  NoteAdd,
  PictureAsPdfOutlined,
} from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import { useTable } from '../../../Reusable';
import { useTableStyles } from '../../../Styles/TableStyles';
import TableActionButtons from '../../../Reusable/Buttons/TableActionButtons';
import Popups from '../../../Reusable/Dialogs/Popups';
import AddNewButton from '../../../Reusable/Buttons/AddNewButton';
import UploadDocument from './uploadPopup/UploadDocument';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../../store';
import {
  GetDocument,
  DeleteDocument,
} from '../../../../actions/Student/StudentDocument/StudentDocumentAction';
import { DocumentTypeI } from '../../../../actions/Student/StudentDocument/StudentDocumentActionTypes';
import {
  ItemDeleteButton,
  DownloadButton,
} from '../../../Reusable/Buttons/TableButton';
import CustomizedDialogs from '../../../Reusable/Dialogs/DeleteDialog';
import { downloadEmployeeData } from '../../../HumanResource/StaffList/StaffProfile/StaffTabs/StaffDocument';
// ---------------------------- <END> Import Module Ends---------------------------//

// ---------------------------- <START> Interface Starts ----------------------------------//

export interface HeadCellsI {
  id: string;
  label: string;
}

interface PropsI {
  studentID: string;
  item: number;
}
// ---------------------------- <END> Interface Ends ----------------------------------//

// ---------------------------- <START> Table Dummy Starts---------------------------//

// ---------------------------- <START> Table Dummy Ends ---------------------------//

// ---------------------------- <START> Table Headings Data Starts ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: 'file', label: 'File' },
  { id: 'file_name', label: 'File Name' },
  { id: 'action', label: 'Action' },
];
// ---------------------------- <END> Table Headings Ends ---------------------------//

// ---------------------------- <START> Document Table Starts ---------------------------//
const Document = (props: any) => {
  const classes = useTableStyles();
  const dispatch = useDispatch();

  // State Declarations
  const [tableData, setTableData] = useState<DocumentTypeI[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const [openPopup, setOpenPopup] = useState(false);

  const [editData, setEditData] = useState<any>([]);

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);
  // ---------------------------- <END> Pagination States and Functions Ends ---------------------------//

  // Retrieving re-usable components from useTable
  const { StyledTableCell, TableContainer, StyledTableRow } =
    useTable(headCells);

  // Function for handling action icons click event
  const handleActionClicked = () => {
    alert('Action Clicked!');
  };

  const handleClose = (value: boolean) => {
    setOpenPopup(value);
  };

  const documentSelector = useSelector(
    (state: RootStore) => state.student_document
  );

  useEffect(() => {
    props.Documents == true && dispatch(GetDocument(props.studentID));
  }, []);

  useEffect(() => {
    setTableData(documentSelector.documents);
    setLoading(documentSelector.loading);
  }, []);

  const handleUpload = () => {
    setOpenPopup(true);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const handleDeleteModal = (value: boolean, id: string) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteDocument(itemId));
    setDeleteModalOpen(false);
  };

  const getCustomTableRow = (item: any) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {item.document.split('.').pop() == 'pdf' ? (
            <PictureAsPdfOutlined style={{ color: '#c91a69' }} />
          ) : (
            <img src={item.document} alt="" height="30px" width="30px" />
          )}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.title}
        </StyledTableCell>

        <StyledTableCell align="center" className={classes.cell}>
          <DownloadButton
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              downloadEmployeeData(item.document)
            }
          />
          {props.hasDeleteBtn && (
            <ItemDeleteButton
              onClick={(
                event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
              ) => handleDeleteModal(true, item.id)}
            ></ItemDeleteButton>
          )}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <Paper elevation={0} className={classes.roottable}>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="h6">Documents</Typography>
        </Grid>
        {props.hasUpload && (
          <Grid item xs={6}>
            <Grid container justifyContent="flex-end">
              <Tooltip title="Upload New file">
                <AddNewButton
                  variant="outlined"
                  color="secondary"
                  startIcon={<Add />}
                  onClick={handleUpload}
                >
                  Upload New file
                </AddNewButton>
              </Tooltip>
            </Grid>
          </Grid>
        )}
      </Grid>
      <TableContainer
        loading={loading}
        headCells={headCells}
        items={documentSelector.documents}
        getCustomTableRow={getCustomTableRow}
        search_name="title"
      />
      <Popups
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        onClose={handleClose}
        title="Upload a file"
        colored
        scroll="body"
        maxWidth="sm"
      >
        <UploadDocument
          studentID={props.studentID}
          item={documentSelector.documents.length}
          editData={editData}
        />
      </Popups>
      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
        dialogTitle="Are you sure to delete this record?"
        okText="Delete"
      />
    </Paper>
  );
};
// ---------------------------- <END> Document Table ends---------------------------//
export default Document;
