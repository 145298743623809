// ---------------------------- <START> module imports start ----------------------------------//
import React, { useEffect, useState, useRef } from "react";
import {
  TableBody,
  TextField,
  Grid,
  Typography,
  Paper,
  TableHead,
  makeStyles,
  IconButton,
  CardMedia,
  createStyles,
  Theme,
  Button,
  withStyles,
} from "@material-ui/core";
import {
  Close as CloseIcon,
  InsertDriveFileOutlined,
  PrintOutlined,
} from "@material-ui/icons";
import { PrintTableStyles } from "../../Styles/PrintTableStyles";
import { useTablePrint } from "../../Reusable";
import TableActionButtons from "../../Reusable/Buttons/TableActionButtons";
import { useSelector } from "react-redux";
import { RootStore } from "../../../store";
import { MarkSheetTypeI } from "../../../actions/Examination/MarkSheet/MarkSheetActionTypes";
import { Printd } from "printd";
import ReactDOMServer from "react-dom/server";
import ReactHtmlParser from "react-html-parser";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import InputBase from "@material-ui/core/InputBase";
import NativeSelect from "@material-ui/core/NativeSelect";
import { useDispatch } from "react-redux";
import {
  TopTagline,
  SchoolInfoContainer,
  SchoolTitle,
  Paragraph,
  ReportType,
  StudentProfileContainer,
  StudentDetailTextWrapper,
  StudentDetailText,
  Spacing,
  Sticky,
  DoubleBorder,
  // PrintNow,
} from "./ReportStyles";
import GradePointTable from "./GradePointTable";
import ApeTable from "./ApeTable";
import printJS from "print-js";
import ReactToPrint from "react-to-print";
import { dateConverterAdToBs } from "../../utils/dateConverter";
import getFullName from "../../utils/getFullName";
import getFullClassName from "../../utils/getFullClassName";
import EditableInput from "../../Reusable/Inputs/EditableInput";
import { useFormStyles } from "../../Styles/FormStyles";
import {
  FormSaveButton,
  FormSaveLoadingButton,
} from "../../Reusable/Buttons/FormButton";
import { AddMarksheetTemplate } from "../../../actions/Templates/Marksheet/MarksheetTemplateAction";
import defaultBase64 from "../../Student/ParentForm/defaultBase64";
import * as htmlToImage from "html-to-image";
import LoaderIcon from "../../Reusable/LoarderIcon";
import { GetReportSetting } from "../../../actions/GeneralSettings/ReportSetting/ReportSettingAction";
import checkImageUrl from "../../utils/checkImageUrl";
// ----------------------------<END> module imports end ----------------------------------//

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  })
)(InputBase);
// ---------------------------- <START> interface starts ----------------------------------//

interface HeadCellsI {
  id: string;
  label: string;
}

interface CustomizeI {
  ape_enable: boolean;
  footer_title: string;
  footer_text_1: string;
  footer_text_2: string;
  footer_text_3: string;
  thumbnail: string;
  watermark: boolean;
  school_logo: boolean;
  school_photo: boolean;
  watermark_position: string;
  school_logo_position: string;
  school_photo_position: string;
  grading_system: boolean;
  code: string;
}
// ---------------------------- <END> interface ends ----------------------------------//

interface HeadCellsI {
  id: string;
  label: string;
}

// -------------------- <START> Table Headings Data ---------------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S. No." },
  { id: "subject", label: "Subjects" },
  { id: "fullMarks", label: "Full Marks" },
  { id: "passMarks", label: "Pass Marks" },
  { id: "th", label: "TH" },
  { id: "pr", label: "PR" },
  { id: "total", label: "Total" },
  { id: "grade_point", label: "Grade Point" },
];
// ---------------------<END> Table Headings Data Ends --------------------------//

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2, 4),
    "& .MuiCardContent-root": {
      padding: theme.spacing(0),
    },
    "& .MuiTypography-h2": {
      fontSize: "3.1rem",
    },
    caption: {
      padding: "0px",
    },
  },
  idImage: {
    width: "80px",
    height: "80px",
  },
  line: {
    borderRight: "1px solid black",
  },
  bottom: {
    marginBottom: theme.spacing(1),
  },
  tableCell: {
    padding: "0px 5px !important",
    border: "1px solid black",
  },
  topWatermark: {
    backgroundImage: `url('https://i.imgur.com/KO5poDX.png')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "70%",
    backgroundPosition: "center",
  },
  centerWatermark: {
    backgroundImage: `url('https://i.imgur.com/KO5poDX.png')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "70%",
    backgroundPosition: "center",
  },
}));

interface propsI {
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  student: string;
  // gpa: number;
}

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const ReportCardPopup1 = (props: any) => {
  const { onClose, student } = props;
  const classes = PrintTableStyles();
  const formClasses = useFormStyles();
  const localClasses = useStyles();
  const dispatch = useDispatch();

  // States declaration
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<MarkSheetTypeI[]>([]);
  const [customizedData, setCustomizedData] = useState<CustomizeI>({
    ape_enable: false,
    footer_title: "Approval",
    footer_text_1: "Class Teacher",
    footer_text_2: "Exam Coordinator",
    footer_text_3: "The Principal",
    thumbnail: defaultBase64,
    watermark: false,
    school_logo: false,
    school_photo: false,
    watermark_position: "T",
    school_logo_position: "L",
    school_photo_position: "R",
    grading_system: false,
    code: "abc",
  });
  const [state, setState] = React.useState({
    gilad: true,
    jason: false,
    antoine: false,
  });
  const { gilad, jason, antoine } = state;
  const [printMode, setPrintMode] = useState<boolean>(false);
  const [studentState, setStudentState] = useState<any>(null);
  const [item, setItem] = useState<any>(null);
  const [url, setUrl] = useState<string>("");

  const componentRef = useRef<any>();

  const schoolState = useSelector(
    (state: RootStore) => state.auth.general_info
  );
  const examState = useSelector((state: RootStore) => state.exam.exam);
  const studentStateData = useSelector(
    (state: RootStore) => state.student.student_by_id
  );
  const reportSettingState = useSelector(
    (state: RootStore) => state.report_setting.reportsetting
  );
  const add_or_update = useSelector(
    (state: RootStore) => state.marksheet_templates.add_or_update
  );

  useEffect(() => {
    const n = window.location.href.lastIndexOf("/");
    setUrl(window.location.href.substring(n + 1));
    dispatch(GetReportSetting());
  }, []);

  useEffect(() => {
    if (add_or_update) {
      setIsLoading(false);
    }
  }, [add_or_update]);

  useEffect(() => {
    props.content != null && setItem(props.content);
    props.content != null && setCustomizedData(props.content);
  }, [props.content]);

  // Retrieving re-usable components from useTable
  const {
    TblContainer,
    TblHead,
    StyledTableCell,
    StyledTableRow,
    exportToExcel,
    tablePrint,
  } = useTablePrint(headCells);

  useEffect(() => {
    if (props.editor) {
      setStudentState({
        student_user: {
          first_name: "Panjak",
          last_name: "Bhattarai",
        },
        academic_info: [
          {
            roll_number: 12,
            grade_name: 10,
            section_name: "A",
          },
        ],
        date_of_birth: "2005-01-12",
        address: "Shantingar, Baneshwor",
        guardian_detail: {
          guardian_user: {
            first_name: "Hari Prasad",
            last_name: "Bhattarai",
          },
        },
      });
    } else {
      setStudentState(studentStateData);
    }
  }, [studentStateData]);

  // ============================ <START> REACT HOOKS <START> ============================== //
  const marksheetSelector = useSelector((state: RootStore) => state.marksheet);

  useEffect(() => {
    if (props.editor) {
      setTableData([
        {
          exam_subject__subject__credit_hour: "40",
          exam_subject__subject__name: "English",
          exam_subject__full_marks: 100,
          exam_subject__pass_marks: 40,
          marks_obtained_theory: 75,
          grade_point_theory: 3.2,
          grade_theory: "3.2",
          marks_obtained_practical: 0,
          total_marks_obtained: 75,
          grade_point_total: 3.2,
        },
        {
          exam_subject__subject__credit_hour: "40",
          exam_subject__subject__name: "English",
          exam_subject__full_marks: 100,
          exam_subject__pass_marks: 40,
          marks_obtained_theory: 75,
          grade_point_theory: 3.2,
          grade_theory: "3.2",
          marks_obtained_practical: 0,
          total_marks_obtained: 75,
          grade_point_total: 3.2,
        },
        {
          exam_subject__subject__credit_hour: "40",
          exam_subject__subject__name: "English",
          exam_subject__full_marks: 100,
          exam_subject__pass_marks: 40,
          marks_obtained_theory: 75,
          grade_point_theory: 3.2,
          grade_theory: "3.2",
          marks_obtained_practical: 0,
          total_marks_obtained: 75,
          grade_point_total: 3.2,
        },
        {
          exam_subject__subject__credit_hour: "40",
          exam_subject__subject__name: "English",
          exam_subject__full_marks: 100,
          exam_subject__pass_marks: 40,
          marks_obtained_theory: 75,
          grade_point_theory: 3.2,
          grade_theory: "3.2",
          marks_obtained_practical: 0,
          total_marks_obtained: 75,
          grade_point_total: 3.2,
        },
        {
          exam_subject__subject__credit_hour: "40",
          exam_subject__subject__name: "English",
          exam_subject__full_marks: 100,
          exam_subject__pass_marks: 40,
          marks_obtained_theory: 75,
          grade_point_theory: 3.2,
          grade_theory: "3.2",
          marks_obtained_practical: 0,
          total_marks_obtained: 75,
          grade_point_total: 3.2,
        },
        {
          exam_subject__subject__credit_hour: "40",
          exam_subject__subject__name: "English",
          exam_subject__full_marks: 100,
          exam_subject__pass_marks: 40,
          marks_obtained_theory: 75,
          grade_point_theory: 3.2,
          grade_theory: "3.2",
          marks_obtained_practical: 0,
          total_marks_obtained: 75,
          grade_point_total: 3.2,
        },
        {
          exam_subject__subject__credit_hour: "40",
          exam_subject__subject__name: "English",
          exam_subject__full_marks: 100,
          exam_subject__pass_marks: 40,
          marks_obtained_theory: 75,
          grade_point_theory: 3.2,
          grade_theory: "3.2",
          marks_obtained_practical: 0,
          total_marks_obtained: 75,
          grade_point_total: 3.2,
        },
        {
          exam_subject__subject__credit_hour: "40",
          exam_subject__subject__name: "English",
          exam_subject__full_marks: 100,
          exam_subject__pass_marks: 40,
          marks_obtained_theory: 75,
          grade_point_theory: 3.2,
          grade_theory: "3.2",
          marks_obtained_practical: 0,
          total_marks_obtained: 75,
          grade_point_total: 3.2,
        },
        {
          exam_subject__subject__credit_hour: "40",
          exam_subject__subject__name: "English",
          exam_subject__full_marks: 100,
          exam_subject__pass_marks: 40,
          marks_obtained_theory: 75,
          grade_point_theory: 3.2,
          grade_theory: "3.2",
          marks_obtained_practical: 0,
          total_marks_obtained: 75,
          grade_point_total: 3.2,
        },
      ]);
    } else {
      setTableData(marksheetSelector.marksheet);
    }
  }, [marksheetSelector]);

  // ============================ <END> REACT HOOKS <END> ============================== //

  // ============================ <START> EVENT HANDLERS <START> ============================== //

  // Event function for table searching by name
  const handleTableSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    return;
  };

  const handlePrint = (e: any) => {
    e.preventDefault();
    const d = new Printd();
    const modalRoot = document.getElementById("print-element") as HTMLElement;
    d.print(modalRoot);
  };
  // ============================ <END> EVENT HANDLERS <END> ============================== //

  const toHTML = () => {
    const modalRoot: any =
      document.getElementById("marksheet-print")?.innerHTML;
    const styless: any = document.getElementById("marksheet-print")?.style;
    const htmlString = ReactDOMServer.renderToStaticMarkup(modalRoot);
    // console.log({ htmlString });
    // console.log(ReactHtmlParser(htmlString));
    // console.log(ReactHtmlParser(styless));
  };

  const handleFooterText = (event: any) => {
    // console.log(event?.target.value);
    const finalValues = {
      ...customizedData,
      footer_title: event.target.value,
    };
    setCustomizedData(finalValues);
  };

  const handleFooterText1 = (event: any) => {
    // console.log(event?.target.value);
    const finalValues = {
      ...customizedData,
      footer_text_1: event.target.value,
    };
    setCustomizedData(finalValues);
  };

  const handleFooterText2 = (event: any) => {
    // console.log(event?.target.value);
    const finalValues = {
      ...customizedData,
      footer_text_2: event.target.value,
    };
    setCustomizedData(finalValues);
  };

  const handleFooterText3 = (event: any) => {
    // console.log(event?.target.value);
    const finalValues = {
      ...customizedData,
      footer_text_3: event.target.value,
    };
    setCustomizedData(finalValues);
  };
  const handleapeChange = (event: any) => {
    const finalValues = {
      ...customizedData,
      ape_enable: event?.target.checked,
    };
    setCustomizedData(finalValues);
  };
  const handlegradingChange = (event: any) => {
    const finalValues = {
      ...customizedData,
      grading_system: event?.target.checked,
    };
    setCustomizedData(finalValues);
  };
  const handlewatermarkChange = (event: any) => {
    const finalValues = {
      ...customizedData,
      watermark: event?.target.checked,
    };
    setCustomizedData(finalValues);
  };

  const handleChangeSchoolLogo = (event: any) => {
    const finalValues = {
      ...customizedData,
      school_logo_position: event.target.value,
    };
    setCustomizedData(finalValues);
  };

  const handleChangeWatermark = (event: any) => {
    console.log("testtting", event.target.value);
    const finalValues = {
      ...customizedData,
      watermark_position: event.target.value,
    };
    setCustomizedData(finalValues);
  };

  const handlleSubmit = () => {
    setIsLoading(true);
    const data_ = {
      ...customizedData,
      code: item?.code,
    };
    const node: any = document.getElementById("marksheet-print");
    htmlToImage
      .toPng(node)
      .then(function (dataUrl) {
        data_.thumbnail = dataUrl;
        return dataUrl;
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
    setTimeout(() => {
      console.log({ data_ });
      props.handlleSubmit(data_);
    }, 3000);
  };

  const dummyData = {
    footer_text_1: "Class Teacher",
    footer_text_2: "Exam Coordinator",
    footer_text_3: "The Principal",
    footer_title: "Verification / Approval",
  };

  const renderValue = (real: any, fake: any) => {
    if (url === "customize") {
      return fake;
    } else {
      return real;
    }
  };
  console.log(props.examInfo);

  return (
    <>
      {!props.editor && (
        <>
          <Sticky>
            <ReactToPrint
              trigger={() => (
                <p
                  style={{
                    backgroundColor: "#132E98",
                    color: "white",
                    fontWeight: "bold",
                    borderRadius: "0px",
                    margin: "0px",
                    textAlign: "center",
                    padding: "5px 0px",
                    cursor: "pointer",
                  }}
                >
                  Print This Out !
                </p>
              )}
              content={() => componentRef.current}
              removeAfterPrint={true}
              copyStyles={true}
            />
          </Sticky>
        </>
      )}
      <div className={classes.roottable} id="print-element" ref={componentRef}>
        <div
          style={{
            backgroundImage: `url(${
              customizedData.watermark &&
              customizedData.watermark_position === "C" &&
              checkImageUrl(
                reportSettingState && reportSettingState[0]?.watermark
              )
            })`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "300px",
            backgroundPosition: "center",
          }}
        >
          {/* <button onClick={() => toHTML()}>toHTML</button> */}
          <div id="marksheet-print" className="marksheet-print">
            <div
              style={{ margin: "25px", borderStyle: "double", padding: "25px" }}
            >
              {/* <div id="test">manoz acharya</div> */}
              <Spacing>
                <div
                  style={{
                    backgroundImage: `url(${
                      customizedData.watermark &&
                      customizedData.watermark_position === "T" &&
                      checkImageUrl(
                        reportSettingState && reportSettingState[0]?.watermark
                      )
                    })`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "300px",
                    backgroundPosition: "center",
                  }}
                >
                  <Paragraph small left>
                    <b>Reg. No: {schoolState?.school_reg_number}</b>
                  </Paragraph>
                  <SchoolInfoContainer>
                    <Paragraph small>{schoolState?.slogan}</Paragraph>
                    <SchoolTitle>{schoolState?.school_name}</SchoolTitle>
                    <Paragraph medium>{schoolState?.address}</Paragraph>
                    <Paragraph medium>
                      Phone: {schoolState?.phone_number}
                    </Paragraph>
                    <Paragraph medium>Email: {schoolState?.email}</Paragraph>
                    <ReportType>
                      <Paragraph large>Marksheet</Paragraph>
                      <Paragraph large>
                        {props.examInfo?.exam_name}{" "}
                        {props.examInfo?.start_date &&
                          dateConverterAdToBs(props.examInfo?.start_date).slice(
                            0,
                            4
                          )}
                      </Paragraph>
                    </ReportType>
                  </SchoolInfoContainer>
                </div>
                <StudentProfileContainer>
                  <Spacing>
                    <Paragraph medium left underline>
                      Personal/Academic Information:
                    </Paragraph>
                  </Spacing>
                  <Grid container spacing={3} style={{ marginTop: "0px" }}>
                    <Grid container item xs={12} className="nothing">
                      <Grid item xs={6}>
                        <Spacing>
                          <StudentDetailTextWrapper>
                            <StudentDetailText bold>
                              Student Name:
                            </StudentDetailText>
                            <StudentDetailText>
                              {getFullName(
                                studentState?.student_user?.first_name,
                                studentState?.student_user?.last_name
                              )}
                            </StudentDetailText>
                          </StudentDetailTextWrapper>
                          <StudentDetailTextWrapper>
                            <StudentDetailText bold>
                              School ID/Roll No:
                            </StudentDetailText>
                            <StudentDetailText>
                              {studentState?.academic_info[0]?.roll_number}
                            </StudentDetailText>
                          </StudentDetailTextWrapper>
                          <StudentDetailTextWrapper>
                            <StudentDetailText bold>Class:</StudentDetailText>
                            <StudentDetailText>
                              {getFullClassName(
                                studentState?.academic_info[0]?.grade_name,
                                studentState?.academic_info[0]?.section_name
                              )}
                            </StudentDetailText>
                          </StudentDetailTextWrapper>
                        </Spacing>
                      </Grid>
                      <Grid item xs={6}>
                        <Spacing>
                          <StudentDetailTextWrapper>
                            <StudentDetailText bold>
                              Date of Birth:
                            </StudentDetailText>
                            <StudentDetailText>
                              {studentState?.date_of_birth &&
                                dateConverterAdToBs(
                                  studentState?.date_of_birth
                                )}
                            </StudentDetailText>
                          </StudentDetailTextWrapper>
                          <StudentDetailTextWrapper>
                            <StudentDetailText bold>
                              Guardian:
                            </StudentDetailText>
                            <StudentDetailText>
                              {getFullName(
                                studentState?.guardian_detail?.guardian_user
                                  ?.first_name,
                                studentState?.guardian_detail?.guardian_user
                                  ?.last_name
                              )}
                            </StudentDetailText>
                          </StudentDetailTextWrapper>
                          <StudentDetailTextWrapper>
                            <StudentDetailText bold>Address:</StudentDetailText>
                            <StudentDetailText>
                              {studentState?.address}
                            </StudentDetailText>
                          </StudentDetailTextWrapper>
                        </Spacing>
                      </Grid>
                    </Grid>
                  </Grid>
                </StudentProfileContainer>
              </Spacing>
              <div
                className={classes.tableWrapper}
                style={{ marginTop: "5px" }}
              >
                <Spacing>
                  <TblContainer size="small">
                    <caption
                      style={{
                        borderBottom: "1px solid black",
                        borderLeft: "1px solid black",
                        borderRight: "1px solid black",
                        padding: "5px",
                      }}
                    >
                      <StudentDetailTextWrapper
                        style={{ paddingRight: "50px" }}
                      >
                        <StudentDetailText>
                          TH: Theory | PR: Pratical | APE: Academic Performance
                          Evaluation
                        </StudentDetailText>
                        <StudentDetailText bold>
                          {/* Average GPA (%): {props.gpa} (99%) */}
                        </StudentDetailText>
                      </StudentDetailTextWrapper>
                    </caption>
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell
                          rowSpan={2}
                          align="center"
                          className={localClasses.tableCell}
                        >
                          S. No.
                        </StyledTableCell>
                        <StyledTableCell
                          rowSpan={2}
                          align="left"
                          className={localClasses.tableCell}
                        >
                          Subjects
                        </StyledTableCell>
                        <StyledTableCell
                          rowSpan={2}
                          align="center"
                          className={localClasses.tableCell}
                        >
                          Full Marks
                        </StyledTableCell>
                        <StyledTableCell
                          rowSpan={2}
                          align="center"
                          className={localClasses.tableCell}
                        >
                          Pass Marks
                        </StyledTableCell>
                        <StyledTableCell
                          colSpan={2}
                          align="center"
                          className={localClasses.tableCell}
                        >
                          Obtained Marks
                        </StyledTableCell>
                        <StyledTableCell
                          rowSpan={2}
                          align="center"
                          className={localClasses.tableCell}
                        >
                          Total
                        </StyledTableCell>
                        <StyledTableCell
                          rowSpan={2}
                          align="center"
                          className={localClasses.tableCell}
                        >
                          Grade Point
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell
                          align="center"
                          className={localClasses.tableCell}
                        >
                          TH
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          className={localClasses.tableCell}
                        >
                          PR
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableHead>

                    <TableBody>
                      {tableData.map((item: MarkSheetTypeI, index: number) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell
                            align="center"
                            className={classes.cell}
                          >
                            {index + 1}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            className={classes.cell}
                          >
                            {item.exam_subject__subject__name}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            className={classes.cell}
                          >
                            {item.exam_subject__full_marks}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            className={classes.cell}
                          >
                            {item.exam_subject__pass_marks}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            className={classes.cell}
                          >
                            {item.marks_obtained_theory}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            className={classes.cell}
                          >
                            {item.marks_obtained_practical}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            className={classes.cell}
                          >
                            {item.total_marks_obtained}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            className={classes.cell}
                          >
                            {item.grade_point_total}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </TblContainer>
                </Spacing>
                <Grid container spacing={3}>
                  <Grid container item xs={12}>
                    <Grid item xs={6}>
                      <Spacing>
                        {customizedData.grading_system && props.editor && (
                          <GradePointTable editMode={true} />
                        )}
                        {!props.editor && customizedData.grading_system && (
                          <GradePointTable editMode={false} />
                        )}
                      </Spacing>
                    </Grid>
                    <Grid item xs={6}>
                      <Spacing>
                        {customizedData.ape_enable && props.editor && (
                          <ApeTable editMode={true} />
                        )}
                        {!props.editor && customizedData.ape_enable && (
                          <ApeTable editMode={false} />
                        )}
                      </Spacing>
                    </Grid>
                  </Grid>
                </Grid>
                <Spacing>
                  <Paragraph
                    medium
                    left
                    style={{ marginTop: "20px", marginBottom: "40px" }}
                  >
                    {props.editor ? (
                      <input
                        type="text"
                        className="editable"
                        onChange={(event) => handleFooterText(event)}
                        style={{
                          width: "200px",
                          textAlign: "left",
                        }}
                        value={customizedData.footer_title}
                      />
                    ) : (
                      renderValue(
                        customizedData?.footer_title,
                        dummyData?.footer_title
                      )
                    )}
                  </Paragraph>
                  <Grid
                    container
                    spacing={3}
                    style={{
                      marginTop: "10px",
                      marginBottom: "5px",
                    }}
                  >
                    <Grid container item xs={12}>
                      <Grid item xs={4}>
                        <Paragraph medium left overline>
                          {props.editor ? (
                            <input
                              type="text"
                              className="editable"
                              onChange={(event) => handleFooterText1(event)}
                              style={{
                                width: "150px",
                                textAlign: "center",
                                borderTop: "1px solid black",
                              }}
                              value={customizedData.footer_text_1}
                            />
                          ) : (
                            renderValue(
                              customizedData?.footer_text_1,
                              dummyData?.footer_text_1
                            )
                          )}
                        </Paragraph>
                      </Grid>
                      <Grid item xs={4} alignContent="center">
                        <Paragraph medium overline>
                          {props.editor ? (
                            <input
                              type="text"
                              className="editable"
                              onChange={(event) => handleFooterText2(event)}
                              style={{
                                width: "150px",
                                textAlign: "center",
                                borderTop: "1px solid black",
                              }}
                              value={customizedData.footer_text_2}
                            />
                          ) : (
                            renderValue(
                              customizedData?.footer_text_2,
                              dummyData?.footer_text_2
                            )
                          )}
                        </Paragraph>
                      </Grid>
                      <Grid item xs={4}>
                        <Paragraph medium right overline>
                          {props.editor ? (
                            <input
                              type="text"
                              className="editable"
                              onChange={(event) => handleFooterText3(event)}
                              style={{
                                width: "150px",
                                textAlign: "center",
                                borderTop: "1px solid black",
                              }}
                              value={customizedData.footer_text_3}
                            />
                          ) : (
                            renderValue(
                              customizedData.footer_text_3,
                              dummyData?.footer_text_3
                            )
                          )}
                        </Paragraph>
                      </Grid>
                    </Grid>
                  </Grid>
                </Spacing>
              </div>
            </div>
          </div>
        </div>
        {props.editor && (
          <div
            style={{
              border: "2px dotted #445DA7",
              borderRadius: "5px",
              padding: "20px",
              margin: "25px",
            }}
          >
            <Grid container spacing={1}>
              <Grid container item xs={12} spacing={3}>
                <Grid item xs={5} className={formClasses.formWrapper}>
                  <FormControl>
                    <b>Enable / Disable Options</b>
                    <FormGroup
                      style={{ backgroundColor: "white", marginTop: "20px" }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={customizedData.ape_enable}
                            onChange={handleapeChange}
                            name="ape_enable"
                          />
                        }
                        label="APE List"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={customizedData.grading_system}
                            onChange={handlegradingChange}
                            name="grading_system"
                          />
                        }
                        label="Grading System"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={customizedData.watermark}
                            onChange={handlewatermarkChange}
                            name="watermark"
                          />
                        }
                        label="WaterMark"
                      />
                      {/* <FormControlLabel
                      control={
                        <Checkbox
                          // checked={customizedData.watermark}
                          // onChange={handlewatermarkChange}
                          name="School Logo/Student Photo"
                        />
                      }
                      label="School Logo / Student Photo"
                    /> */}
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={4} className={formClasses.formWrapper}>
                  {/* <FormControl>
                  <InputLabel htmlFor="demo-customized-select-native">
                    School Logo Position
                  </InputLabel>
                  <NativeSelect
                    id="demo-customized-select-native"
                    value={customizedData.school_logo_position}
                    onChange={handleChangeSchoolLogo}
                    input={<BootstrapInput />}
                  >
                    <option value="L">Top Left</option>
                    <option value="R">Top Right</option>
                  </NativeSelect>
                </FormControl> */}
                  {customizedData.watermark && (
                    <>
                      <b style={{ marginBottom: "10px" }}>Select Options</b>
                      <FormControl style={{ marginTop: "10px" }}>
                        <InputLabel htmlFor="demo-customized-select-native">
                          WaterMark Positin
                        </InputLabel>
                        <NativeSelect
                          id="demo-customized-select-native"
                          value={customizedData.watermark_position}
                          onChange={handleChangeWatermark}
                          input={<BootstrapInput />}
                        >
                          <option value="T">At Top</option>
                          <option value="C">At Center</option>
                        </NativeSelect>
                      </FormControl>
                    </>
                  )}
                </Grid>
                <Grid item xs={3}></Grid>
              </Grid>
            </Grid>
            <FormSaveLoadingButton
              onClick={() => handlleSubmit()}
              icon={isLoading && <LoaderIcon />}
              disabled={isLoading ? true : false}
              text={isLoading ? "Saving" : "Save"}
            />
          </div>
        )}
      </div>
    </>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default ReportCardPopup1;
