import {
  Button,
  Card,
  CardContent,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import { indigo, yellow } from "@material-ui/core/colors";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HeadCellsI } from "../../../actions";
import { GetAllPreTimeTables } from "../../../actions/Academics/PreTimeTable/PreTimeTableAction";
import { RootStore } from "../../../store";
import { useTable } from "../../Reusable";
import { useTableStyles } from "../../Styles/TableStyles";
import ReactToPrint from "react-to-print";
import { ItemEditButton } from "../../Reusable/Buttons/TableButton";
import Popups from "../../Reusable/Dialogs/Popups";
import EditPreTimeTableForm from "./EditPreTimeTableForm";
import styled from "styled-components";
import { getTimePeriods } from "../../../rtk/features/timetable/periodApi";
import BackDropLoader from "../../Reusable/BackDropLoader";
import { resetPeriodStateAction } from "../../../rtk/features/timetable/periodSlices";
import moment from "moment";

const customColor = [
  "#FFEDD6",
  "#D8EDF7",
  "#FDD9DD",
  "#f48fb1",
  "#ce93d8",
  "#80cbc4",
  "#a5d6a7",
  "#FFEDD6",
  "#D8EDF7",
  "#FDD9DD",
  "#f48fb1",
  "#ce93d8",
  "#80cbc4",
  "#a5d6a7",
];

const PeriodContainer = styled.div``;

const ClassCell = styled.div`
  && {
    padding: 10px;
  }
  width: 180px;
`;

const ClassCellIndex = styled.div`
  && {
    padding: 10px;
  }
  min-width: 120px;
  font-size: 16px;
  font-weight: 500;
`;

const ClassCellEdit = styled.div`
  display: flex;
  align-items: center;
`;

const ClassRow = styled.div`
  &:nth-of-type(odd) {
    background-color: #3d3d3d15;
  }
  min-height: 120px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const RowDivider = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledCard = styled(Card)<{ colorIndex: number }>`
  && {
    width: 100%;
    background-color: ${(props) =>
      props.colorIndex ? customColor[props.colorIndex] : "#FFEDD6"};
  }
`;

const StyledCardContent = styled(CardContent)`
  && {
    padding: 10px;
  }
`;

const CardTitle = styled.h2`
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 8px 0;
`;

const CardText = styled.p`
  font-size: 13px;
  font-weight: 500;
  margin: 5px 0;
`;

interface GradeChoiceI {
  id: number;
  label: string;
  value: string;
}

interface PreTimeTableType {
  class_detail: any;
  classes: string[];
  end_time: string;
  id: string;
  period: string;
  start_time: string;
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      textAlign: "left",
      padding: 4,
      background: yellow[200],
    },
    cardContent: {
      padding: 6,
      paddingBottom: "8px !important",
    },
    title: { fontSize: 15, fontWeight: 600 },
    time: { fontSize: 12 },
    pos: {
      fontSize: 12,
      marginBottom: 0,
      fontWeight: 500,
    },
  };
});

const PreTimeTableList = (props: any) => {
  const { onSelectedClass } = props;
  const dispatch = useDispatch();
  const classes = useTableStyles();
  const componentRef = useRef<any>();

  const [classValue, setClassValue] = useState<string[]>([]);
  const [sortedData, setSortedData] = useState<PreTimeTableType[][]>([]);
  const [tableTitle, setTableTitle] = useState<string>(
    "Pre-TimeTable Schedule For All Class"
  );
  const [selectedClass, setSelectedClass] = useState<any>([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [classEditID, setClassEditID] = useState("");
  const [classTitle, setClassTitle] = useState("");

  const AllPreTimeTableSelector = useSelector(
    (state: RootStore) => state.pre_timeTable.allPreTimeTable
  );
  const classState = useSelector((state: RootStore) => state.class.classes);

  const [editRow, setEditRow] = useState<string | null>(null);
  const { classPeriods, actionPerformed, loading } = useSelector(
    (state: RootStore) => state.period
  );

  const handleSelectedClass = (array: any) => {
    array.map((item: any, index: number) => {
      if (item.length) {
        selectedClass[index].state = true;
      }
    });
    onSelectedClass(selectedClass.filter((item: any) => item.state === true));
  };

  useEffect(() => {
    dispatch(getTimePeriods());
  }, [dispatch, actionPerformed]);

  useEffect(() => {
    if (actionPerformed) {
      setOpenEditModal(false);
    }
  }, [actionPerformed]);

  useEffect(() => {
    const data: any = [];
    AllPreTimeTableSelector?.map((elem: any) => {
      if (elem.classes.length) {
        data.push(elem);
      }
    });

    if (classValue.length) {
      const finalData: any = [];
      for (let j = 0; j < classValue.length; j++) {
        const tempData: any = [];
        for (let i = 0; i < data?.length; i++) {
          if (data[i]?.classes.includes(classValue[j])) {
            tempData.push(data[i]);
          }
        }
        finalData.push(tempData);
      }
      setSortedData(finalData);
      handleSelectedClass(finalData);
    }
  }, [AllPreTimeTableSelector, classValue]);

  const returnCard = ({
    index,
    period,
    startTime,
    endTime,
  }: {
    index: number;
    period: string;
    startTime: string;
    endTime: string;
  }) => {
    return (
      <StyledCard colorIndex={index}>
        <StyledCardContent>
          <CardTitle>{period}</CardTitle>
          <CardText>{moment(startTime, "HH:mm").format("hh:mm A")}</CardText>
          <CardText>{moment(endTime, "HH:mm").format("hh:mm A")}</CardText>
        </StyledCardContent>
      </StyledCard>
    );
  };

  const returnClassPeriods = (gradeId: string) => {
    const classPeriod = classPeriods.find((item) => item.grade.id === gradeId);

    if (classPeriod) {
      return classPeriod;
    }
    return null;
  };

  const handlePeriodEdit = ({
    id,
    name,
  }: {
    id: string | null;
    name: string;
  }) => {
    id && setOpenEditModal(true);
    id && setClassEditID(id);

    setClassTitle(name);
  };

  const handleOpenEditModal = () => {
    dispatch(resetPeriodStateAction());
    setOpenEditModal(false);
  };

  return (
    <Paper className={classes.rootTableBox}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6">{tableTitle}</Typography>
        </Grid>
      </Grid>
      <ReactToPrint
        trigger={() => (
          <p
            style={{
              backgroundColor: "#132E98",
              color: "white",
              fontWeight: "bold",
              borderRadius: "0px",
              margin: "0px",
              textAlign: "center",
              padding: "5px 0px",
              cursor: "pointer",
            }}
          >
            Print This Out !
          </p>
        )}
        content={() => componentRef.current}
        removeAfterPrint={true}
        copyStyles={true}
      />
      <div ref={componentRef}>
        <PeriodContainer>
          {classState.map((item) => {
            return (
              <ClassRow
                key={item.id}
                onMouseEnter={() => setEditRow(String(item.id))}
                onMouseLeave={() => setEditRow(null)}
              >
                <RowDivider>
                  <ClassCellIndex>{item.grade_name}</ClassCellIndex>
                </RowDivider>
                <RowDivider>
                  {returnClassPeriods(item.grade)?.periods.map(
                    (data, index) => {
                      return (
                        <ClassCell key={data.id}>
                          {returnCard({
                            index,
                            period: data.name,
                            startTime: data.start_time,
                            endTime: data.end_time,
                          })}
                        </ClassCell>
                      );
                    }
                  )}
                  {returnClassPeriods(item.grade)
                    ? editRow === String(item.id) && (
                        <ClassCellEdit>
                          <ItemEditButton
                            onClick={() =>
                              handlePeriodEdit({
                                id:
                                  returnClassPeriods(item.grade)?.grade.id ||
                                  null,
                                name:
                                  returnClassPeriods(item.grade)?.grade.name ||
                                  "",
                              })
                            }
                          />
                        </ClassCellEdit>
                      )
                    : null}
                </RowDivider>
              </ClassRow>
            );
          })}
        </PeriodContainer>
      </div>
      <Popups
        openPopup={openEditModal}
        setOpenPopup={setOpenEditModal}
        onClose={handleOpenEditModal}
        maxWidth="md"
        title={`Edit Class ${classTitle} Period`}
        colored
      >
        <EditPreTimeTableForm classEditID={classEditID} />
      </Popups>
    </Paper>
  );
};

export default PreTimeTableList;
