export const GET_STUDENT_ASSIGNMENT_LOADING = "GET_STUDENT_ASSIGNMENT_LOADING";
export const GET_STUDENT_ASSIGNMENT_ERROR = "GET_STUDENT_ASSIGNMENT_ERROR";
export const GET_STUDENT_ASSIGNMENT_SUCCESS = "GET_STUDENT_ASSIGNMENT_SUCCESS";

export interface AssignmentResponse {
    total_submitted_assignments: number;
    late_submission: number;
    submission_on_time: number;
    not_submitted: number;
}

export interface FeedbackResponse {
    approved_feedback: number;
    declined_feedback: number;
    pending_feedback: number;
    total_feedback: number;
}

//===============================<START>GET STUDENT ASSIGNMENT<START>======================//
export interface GetStudentAssignmentLoading {
    type: typeof GET_STUDENT_ASSIGNMENT_LOADING;
}

export interface GetStudentAssignmentError {
    type: typeof GET_STUDENT_ASSIGNMENT_ERROR;
}

export interface GetStudentAssignmentSuccess {
    type: typeof GET_STUDENT_ASSIGNMENT_SUCCESS;
    payload: [AssignmentResponse, FeedbackResponse];
}

//===============================<END>GET STUDENT ASSIGNMENT<END>======================//

export type StudentProgressDispatchTypes =
    | GetStudentAssignmentLoading
    | GetStudentAssignmentError
    | GetStudentAssignmentSuccess;