// ---------------------------- <START> module imports start ----------------------------------//
import React, { useEffect, useState } from "react";
import {
  TableBody,
  Grid,
  Typography,
  Paper,
  LinearProgress,
  TablePagination,
  Tooltip,
  Button,
  withStyles,
  Theme,
} from "@material-ui/core";

import { useTableStyles } from "../../Styles/TableStyles";
import { useTable } from "../../Reusable";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";

import { getScheduledSms } from "../../../rtk/features/sms/smsApi";
import { ScheduledSmsI } from "../../../rtk/features/sms/sms";

// ----------------------------<END> module imports end ----------------------------------//

const CustomTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    fontSize: 12,
  },
}))(Tooltip);

// ---------------------------- <START> interface starts ----------------------------------//

interface HeadCellsI {
  id: string;
  label: string;
}
// ---------------------------- <END> interface ends ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N" },
  { id: "title", label: "Title" },
  { id: "desc", label: "Description" },
  { id: "date", label: "Date" },
  { id: "time", label: "Time" },
  { id: "send_to", label: "Send To" },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const ScheduledSmsTable: React.FC = () => {
  const classes = useTableStyles();
  const dispatch = useDispatch();
  const { loading, scheduled_sms } = useSelector(
    (state: RootStore) => state.sms
  );
  // States declaration

  // Retrieving re-usable components from useTable
  const { TblContainer, TblHead, StyledTableCell, StyledTableRow } =
    useTable(headCells);

  useEffect(() => {
    dispatch(
      getScheduledSms({
        page: scheduled_sms.current_page,
        per_page: scheduled_sms.per_page,
      })
    );
  }, []);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    dispatch(
      getScheduledSms({
        page: newPage + 1,
        per_page: scheduled_sms.per_page,
      })
    );
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(
      getScheduledSms({
        page: 1,
        per_page: parseInt(event.target.value, 10),
      })
    );
  };

  const returnSendTo = (item: ScheduledSmsI) => {
    if (item.group.length) {
      const data_length = item.group.length;
      if (data_length > 3) {
        const first_three = item.group.slice(0, 3);
        const remaining = item.group.slice(3);
        let names_show = "";
        let names_hover = "";
        first_three.forEach((item, index) => {
          if (index > 0) {
            names_show = names_show + `, ${item.name}`;
          } else {
            names_show = names_show + ` ${item.name}`;
          }
        });
        remaining.forEach((item, index) => {
          if (index > 0) {
            names_hover = names_hover + `, ${item.name}`;
          } else {
            names_hover = names_hover + ` ${item.name}`;
          }
        });
        return (
          <>
            {names_show}
            <CustomTooltip title={names_hover}>
              <Button>{`${remaining.length} more`}</Button>
            </CustomTooltip>
          </>
        );
      } else {
        let names = "";
        item.group.forEach((item, index) => {
          if (index > 0) {
            names = names + `, ${item.name}`;
          } else {
            names = names + ` ${item.name}`;
          }
        });
        return names;
      }
    }
    if (item.user.length) {
      const data_length = item.user.length;
      if (data_length > 3) {
        const first_three = item.user.slice(0, 3);
        const remaining = item.user.slice(3);
        let names_show = "";
        let names_hover = "";
        first_three.forEach((item, index) => {
          if (index > 0) {
            names_show = names_show + `, ${item.first_name} ${item.last_name}`;
          } else {
            names_show = names_show + ` ${item.first_name} ${item.last_name}`;
          }
        });
        remaining.forEach((item, index) => {
          if (index > 0) {
            names_hover =
              names_hover + `, ${item.first_name} ${item.last_name}`;
          } else {
            names_hover = names_hover + ` ${item.first_name} ${item.last_name}`;
          }
        });
        return (
          <>
            {names_show}
            <CustomTooltip title={names_hover}>
              <Button>{`${remaining.length} more`}</Button>
            </CustomTooltip>
          </>
        );
      } else {
        let names = "";
        item.user.forEach((item, index) => {
          if (index > 0) {
            names = names + `, ${item.first_name} ${item.last_name}`;
          } else {
            names = names + ` ${item.first_name} ${item.last_name}`;
          }
        });
        return names;
      }
    }
    if (item.grade) {
      let section = "";
      let target = "Students";
      if (item.section.length) {
        item.section.forEach((item, index) => {
          if (index > 0) {
            section = section + `, ${item.name}`;
          } else {
            section = section + `${item.name}`;
          }
        });
      }
      if (item.send_to_guardian) {
        target = target + ", Guardians";
      }
      return `${item.grade.grade_name} - ${section} (${target})`;
    }
    return;
  };

  return (
    <>
      <Paper className={classes.rootTableBox}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">Scheduled Sms</Typography>
          </Grid>
        </Grid>
        <div className={classes.tableWrapper}>
          <TblContainer>
            <TblHead />
            <TableBody>
              {scheduled_sms.results.map((item, index) => (
                <StyledTableRow key={item.id}>
                  <StyledTableCell align="center" className={classes.cell}>
                    {index + 1}
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.cell}>
                    {item.title}
                  </StyledTableCell>

                  <StyledTableCell align="center" className={classes.cell}>
                    {item.description}
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.cell}>
                    {item.schedule_date}
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.cell}>
                    {item.schedule_time}
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.cell}>
                    {returnSendTo(item)}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </TblContainer>
          {scheduled_sms.results.length ? (
            <TablePagination
              component="div"
              count={scheduled_sms.total}
              page={scheduled_sms.current_page - 1}
              onPageChange={handleChangePage}
              rowsPerPage={scheduled_sms.per_page}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : null}
          {loading ? <LinearProgress /> : null}
        </div>
      </Paper>
    </>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default ScheduledSmsTable;
