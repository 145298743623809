import {
  Grid,
  InputLabel,
  TextField,
  Button,
  Switch,
  FormGroup,
  FormControlLabel,
  Typography,
  Paper,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { Autocomplete, Alert } from '@material-ui/lab';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useStyles } from '../../Styles/FormStyles';
import { ImageUpload } from '../../Reusable';
import { useHistory } from 'react-router-dom';
import SecondImageUpload from '../../Reusable/Upload/ImageUpload/SecondImageUpload';
import defaultBase64 from './defaultBase64';
import { Redirect } from 'react-router-dom';
import { RELATION_STATUS_CHOICES } from '../../../common/json.constant';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { RootStore } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  AddGuardian,
  GetGuardianByID,
  GetGuardian,
  updateGuardian,
} from "../../../actions/Student/Guardian/GuardianAction";
import firstWordCapital from "../../utils/firstWordCapital";
import formsValidationCheckup from "../../utils/formsValidationCheckUp";
import CustomBackdrop from "../../Reusable/Inputs/CustomBackdrop";
import { UpdateParentProfile } from "../../../actions/ParentsDash/ParentProfile/ParentProfileAction"
import { UpdateProfilePicture } from "../../../actions/Profile Picture/ProfilePictureAction"
// -----------------------<start> interfaces <start>-------------------//

interface RelationI {
  id: number;
  title: string;
}

interface ParentFormI {
  parent_first_name: string;
  parent_last_name: string;
  parent_address: string;
  parent_phone: number;
  email?: string;
  parent_relation: string;
  occupation?: string;
  secondary_parent_full_name?: string;
  secondary_parent_address?: string;
  secondary_parent_relation?: string;
  secondary_parent_phone?: number;
}

interface Tupple {
  key: string;
  value: string;
}

interface ProfilePic {
  picturePreview: string;
  pictureAsFile: any;
}


// -----------------------<end> interfaces <end>-------------------//

const ParentDetailForm = (props: any) => {
  const classes = useStyles();
  const history = useHistory();
  const [switchStatus, setSwitchStatus] = useState<boolean>(false);
  const [submitClicked, setSubmitClicked] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [relation, setRelation] = useState<Tupple | null>(null);
  const [primaryRelation, setPrimaryRelation] = useState<any | null>(null);
  const [secondaryRelation, setSecondaryRelation] = useState<any | null>(null);
  const { guardianID } = useParams<{ guardianID: string }>();
  const [updated, setUpdated] = useState<boolean>(false);
  const [url, setUrl] = useState<string>('');
  const guardianState = useSelector((state: RootStore) => state.guardians);
  const selectedGuardian: any = guardianState.guardian_by_id;
  const [primaryImage, setPrimaryImage] = useState<ProfilePic>(
    { picturePreview: "", pictureAsFile: null });
  const [secondaryImage, setSecondaryImage] = useState<ProfilePic>(
    { picturePreview: "", pictureAsFile: null }
  );
  const [hasError, setHasError] = useState<string>('');
  const isAdmin = localStorage.getItem('role') === 'Administrator';

  const { register, handleSubmit, errors, setError, setValue, formState } =
    useForm<any>({
      mode: 'onChange',
    });

  const dispatch = useDispatch();
  const loadingCheck = useSelector(
    (state: RootStore) => state.guardians.loading
  );
  const errorsData = useSelector((state: RootStore) => state.guardians.errors);
  const errorMsg = useSelector((state: RootStore) => state.errors.msg);

  useEffect(() => {
    if (isAdmin) {
      dispatch(GetGuardian(1));
      guardianID != undefined && dispatch(GetGuardianByID(guardianID));
      const n = window.location.href.lastIndexOf('/');
      setUrl(window.location.href.substring(n + 1));
    }
  }, []);

  useEffect(() => {
    if (selectedGuardian != null) {
      selectedGuardian?.secondary_guardian != null && setSwitchStatus(true);
      if (guardianID != null) {
        setValue(
          'parent_first_name',
          selectedGuardian?.guardian_user.first_name
        );
        setValue('parent_last_name', selectedGuardian?.guardian_user.last_name);
        setValue('parent_address', selectedGuardian?.address);
        setValue('phone_number', selectedGuardian?.phone_number);
        setValue('email', selectedGuardian?.guardian_user.email);
        setValue('occupation', selectedGuardian?.occupation);
        setPrimaryRelation(
          RELATION_STATUS_CHOICES.find(
            (item: any) => item.key === selectedGuardian?.relation
          )
        );
      }
    }
  }, [selectedGuardian]);

  // For edit
  useEffect(() => {
    if (props.data) {
      setValue('parent_first_name', props.data?.guardian_user?.first_name);
      setValue('parent_last_name', props.data?.guardian_user?.last_name);
      setValue('parent_address', props.data?.address);
      setValue('phone_number', props.data?.phone_number);
      setValue('email', props.data?.guardian_user?.email);
      setValue('occupation', props.data?.occupation);
      setPrimaryRelation(
        RELATION_STATUS_CHOICES.filter(
          (item) => item.key === props.data?.relation
        )[0]
      );
      setValue("parent_last_name", props.data?.guardian_user?.last_name);
      setValue("parent_address", props.data?.address);
      setValue("phone_number", props.data?.phone_number);
      setValue("email", props.data?.guardian_user?.email);
      setValue("occupation", props.data?.occupation);
      setPrimaryRelation(RELATION_STATUS_CHOICES.filter(item => item.key === props.data?.relation)[0]);
      setPrimaryImage({ pictureAsFile: null, picturePreview: props.data?.photo })
    } else {
      setValue('parent_first_name', '');
      setValue('parent_last_name', '');
      setValue('parent_address', '');
      setValue('phone_number', '');
      setValue('email', '');
      setValue('occupation', '');
      setPrimaryRelation(RELATION_STATUS_CHOICES[0]);
      setPrimaryImage({
        pictureAsFile: null,
        picturePreview: ""
      });
    }
  }, [props.data]);

  useEffect(() => {
    if (switchStatus) {
      if (selectedGuardian != null) {
        setValue(
          'secondary_parent_full_name',
          selectedGuardian.secondary_guardian?.full_name
        );
        setValue(
          'secondary_parent_address',
          selectedGuardian?.secondary_guardian?.address
        );
        setValue(
          'secondary_parent_phone',
          selectedGuardian?.secondary_guardian?.phone_number
        );
        setPrimaryImage({
          pictureAsFile: null,
          picturePreview: selectedGuardian?.photo,
        });
        setSecondaryImage({
          pictureAsFile: null,
          picturePreview: selectedGuardian?.secondary_guardian?.photo,
        });

        setSecondaryRelation(
          RELATION_STATUS_CHOICES.find(
            (item: any) =>
              item.key === selectedGuardian?.secondary_guardian?.relation
          )
        );
      }
    }
  }, [switchStatus]);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSwitchStatus(event.target.checked);
  };
  useEffect(() => {
    if (errorsData?.error != null) {
      const keys = Object.keys(errorsData?.error);
      keys.map((elem: any) => {
        setError(elem, {
          type: 'serverSideError',
          message: errorsData.error[elem] && errorsData.error[elem][0],
        });
      });
    }
  }, [errorsData]);

  useEffect(() => {
    if (errors) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      props.setSelectedRadioValue('already_added');
    }
  }, [errors]);

  // const handleImageValue = (element: any) => {
  //   // console.log("element", element);
  //   if (element?.length > 500) {
  //     return element;
  //   } else if (element === null) {
  //     return defaultBase64;
  //   } else if (element?.length < 500) {
  //     return defaultBase64;
  //   }
  // };

  // const handleFormData = (element: any) => {
  //   if (primaryImage?.length > 500) {
  //     // console.log({ element });
  //     element.photo = primaryImage;
  //   }
  //   if (primaryImage === null) {
  //     element.photo = defaultBase64;
  //   }
  // };

  // const handleFormData1 = (element: any) => {
  //   if (secondaryImage?.length > 500) {
  //     element.photo = secondaryImage;
  //   }
  //   if (secondaryImage === null) {
  //     element.photo = defaultBase64;
  //   }
  // };

  // function isEmpty(obj: any) {
  //   for (const key in obj) {
  //     if (obj.hasOwnProperty(key)) return false;
  //   }
  //   return true;
  // }

  const onSubmit = (data: any) => {
    if (isAdmin) {
      const form_data: any = {
        guardian_user: {
          first_name: data.parent_first_name,
          last_name: data.parent_last_name,
          email: data.email,
        },
        address: data.parent_address,
        relation: primaryRelation?.key,
        occupation: data.occupation,
        phone_number: data.phone_number || selectedGuardian?.phone_number,
      };
      // handleFormData(form_data);

      const secondary_data: any = {
        relation: secondaryRelation?.key,
        full_name: data.secondary_parent_full_name,
        address: data.secondary_parent_address,
        phone_number: data.secondary_parent_phone,
      };

      // handleFormData1(secondary_data);

      const with_secondary_data: any = {
        ...form_data,
        secondary_guardian: {
          ...secondary_data,
        },
      };
      setUpdated(true);
      if (guardianID != undefined) {
        const profilePic = new FormData();
        const secondaryPic = new FormData();
        if (primaryImage) {
          profilePic.append("user", selectedGuardian?.guardian_user?.id)
          profilePic.append("photo", primaryImage.pictureAsFile);
          primaryImage.pictureAsFile && dispatch(UpdateProfilePicture(profilePic))
        }
        if (switchStatus) {
          if (secondaryImage) {
            secondaryPic.append("user", selectedGuardian?.secondary_guardian?.id)
            secondaryPic.append("photo", secondaryImage.pictureAsFile);
            secondaryImage.pictureAsFile && dispatch(UpdateProfilePicture(secondaryPic))
          }
          dispatch(updateGuardian(guardianID, with_secondary_data))
        } else {
          primaryImage.pictureAsFile && dispatch(UpdateProfilePicture(profilePic))
          dispatch(updateGuardian(guardianID, form_data))
        }
      } else {
        if (switchStatus) {
          dispatch(AddGuardian(with_secondary_data))
        } else {
          dispatch(AddGuardian(form_data));
        }
      }
    } else {
      const profilePic = new FormData();
      const updateData: any = {
        first_name: data.parent_first_name,
        last_name: data.parent_last_name,
        occupation: data.occupation,
        address: data.parent_address,
        relation: primaryRelation?.key,
      }
      if (primaryImage) {
        profilePic.append("user", selectedGuardian?.guardian_user?.id)
        profilePic.append("photo", primaryImage.pictureAsFile);
        primaryImage.pictureAsFile && dispatch(UpdateProfilePicture(profilePic))
      }
      setUpdated(true);
      dispatch(UpdateParentProfile(updateData));
      history.push('/');
    }
  };

  if (guardianState.add_or_update) {
    if (url === 'student-admission') {
      props.setSelectedRadioValue('already_added');
    } else {
      history.push('/guardian-details');
    }
  }

  return (
    <>
      <div>
        <CustomBackdrop open={loadingCheck} />
        <Grid container>
          <Grid item>
            <Typography variant="h6" style={{ marginBottom: '20px' }}>
              {guardianID != null ? 'Update Guardian' : 'Add Guardian'}
            </Typography>
          </Grid>
        </Grid>
        <form onSubmit={handleSubmit(onSubmit)}>
          {errorsData?.error &&
            Object.keys(errorsData.error).map((elem: any, index: number) => {
              return (
                <div className={classes.serversidemessages} key={index}>
                  <Alert severity="error">
                    {firstWordCapital(errorsData?.error[elem][0])}
                  </Alert>
                </div>
              );
            })}
          <Grid container spacing={3}>
            <Grid item md={6} className={classes.formWrapper}>
              <InputLabel>
                First Name
                <span style={{ color: 'red' }}>*</span>
              </InputLabel>
              <TextField
                variant="outlined"
                name="parent_first_name"
                placeholder="Guardian First Name"
                autoComplete="no"
                // value={data?.guardian_user?.first_name}
                inputRef={register({ required: true })}
              />
              {formsValidationCheckup({
                errors: errors,
                inputName: 'parent_first_name',
                requiredMessage: 'Guardian First Name is Required',
                serverSideMessage:
                  errors.parent_first_name?.type === 'serverSideError' &&
                  errors.parent_first_name.message,
              })}
            </Grid>
            <Grid item md={6} className={classes.formWrapper}>
              <InputLabel>
                Last Name
                <span style={{ color: 'red' }}>*</span>
              </InputLabel>
              <TextField
                variant="outlined"
                name="parent_last_name"
                placeholder="Guardian Last Name"
                // value={data?.guardian_user?.last_name}
                autoComplete="no"
                inputRef={register({ required: true })}
              />
              {formsValidationCheckup({
                errors: errors,
                inputName: 'parent_last_name',
                requiredMessage: 'Guardian Last Name is Required',
                maxLengthMessage: null,
                minLengthMessage: null,
                serverSideMessage:
                  errors.parent_last_name?.type === 'serverSideError' &&
                  errors.parent_last_name.message,
              })}
            </Grid>
            <Grid item md={6} className={classes.formWrapper}>
              <InputLabel>
                Address
                <span style={{ color: 'red' }}>*</span>
              </InputLabel>
              <TextField
                variant="outlined"
                name="parent_address"
                autoComplete="no"
                // value={data?.address}
                placeholder="Guardian Address"
                inputRef={register({ required: true })}
              />
              {formsValidationCheckup({
                errors: errors,
                inputName: 'parent_address',
                requiredMessage: 'Guardian Address is Required',
                maxLengthMessage: null,
                minLengthMessage: null,
                serverSideMessage:
                  errors.parent_address?.type === 'serverSideError' &&
                  errors.parent_address.message,
              })}
            </Grid>
            <Grid item md={3} className={classes.formWrapper}>
              <InputLabel>
                Phone
                <span style={{ color: 'red' }}>*</span>
              </InputLabel>
              <TextField
                variant="outlined"
                autoComplete="no"
                name="phone_number"
                type="number"
                placeholder="Guardian Phone Number"
                disabled={!isAdmin || selectedGuardian != null}
                inputProps={{ min: 0 }}
                inputRef={register({
                  required: true,
                  maxLength: 10,
                  minLength: 9,
                })}
              />
              {formsValidationCheckup({
                errors: errors,
                inputName: 'phone_number',
                requiredMessage: 'Guardian Phone Number is Required',
                maxLengthMessage: 'Characters must be in between (9-10) digits',
                minLengthMessage: 'Characters must be in between (9-10) digits',
                serverSideMessage:
                  errors.phone_number?.type === 'serverSideError' &&
                  errors.phone_number.message,
              })}
            </Grid>
            <Grid item md={3} className={classes.formWrapper}>
              <InputLabel>Email</InputLabel>
              <TextField
                variant="outlined"
                name="email"
                autoComplete="no"
                placeholder="Guardian Email"
                disabled={!isAdmin}
                inputRef={register({
                  required: false,
                  pattern: {
                    value: /^[a-z0-9._%+_]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                    message: 'Enter a valid email (e.g. yourname@gmail.com)',
                  },
                })}
              />
              <span className={classes.validationErrorInfo}>
                {errors.email && errors.email.message}
              </span>
              {formsValidationCheckup({
                errors: errors,
                inputName: 'email',
                requiredMessage: null,
                maxLengthMessage: null,
                minLengthMessage: null,
                serverSideMessage:
                  errors.email?.type === 'serverSideError' &&
                  errors.email.message,
              })}
            </Grid>
            <Grid item md={4} className={classes.formWrapper}>
              <InputLabel>
                Relation
                <span style={{ color: 'red' }}>*</span>
              </InputLabel>
              <Autocomplete
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: Tupple | null
                ) => setPrimaryRelation(value)}
                options={RELATION_STATUS_CHOICES}
                getOptionLabel={(option) => option.value}
                value={primaryRelation}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Relation with Student"
                    variant="outlined"
                    autoComplete="no"
                    name="parent_relation"
                    inputRef={register({
                      required: true,
                    })}
                  />
                )}
              />
              {formsValidationCheckup({
                errors: errors,
                inputName: 'parent_relation',
                requiredMessage: 'Select relation with student',
                maxLengthMessage: null,
                minLengthMessage: null,
                serverSideMessage:
                  errors.parent_relation?.type === 'serverSideError' &&
                  errors.parent_relation.message,
              })}
            </Grid>
            <Grid item md={4} className={classes.formWrapper}>
              <InputLabel>
                Occupation <span style={{ color: 'red' }}>*</span>
              </InputLabel>
              <TextField
                variant="outlined"
                name="occupation"
                autoComplete="no"
                // value={data?.occupation}
                placeholder="Guardian Occupation"
                inputRef={register({
                  required: true,
                })}
              />
              {formsValidationCheckup({
                errors: errors,
                inputName: 'occupation',
                requiredMessage: 'Provide Guardian Occupation',
                maxLengthMessage: null,
                minLengthMessage: null,
                serverSideMessage:
                  errors.occupation?.type === 'serverSideError' &&
                  errors.occupation.message,
              })}
            </Grid>
            <Grid item xs={12} className={classes.formWrapper}>
              <InputLabel>Guardian Photo</InputLabel>
              <ImageUpload
                image={primaryImage.picturePreview}
                setImage={setPrimaryImage}
                id="upload-input2"
              />
            </Grid>
            {isAdmin && (
              <Grid item xs={12} className={classes.formWrapper}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        checked={switchStatus}
                        onChange={handleSwitchChange}
                        name="parentSwitch"
                      />
                    }
                    label="Secondary Guardian"
                  />
                </FormGroup>
              </Grid>
            )}
            {switchStatus === true ? (
              <>
                <Grid item md={6} className={classes.formWrapper}>
                  <InputLabel>
                    Full Name
                    <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <TextField
                    variant="outlined"
                    name="secondary_parent_full_name"
                    placeholder="Secondary Guardian Full Name"
                    inputRef={register({
                      required: 'Enter Secondary Parent Full Name',
                    })}
                  />
                  <span className={classes.validationErrorInfo}>
                    {errors.secondary_parent_full_name &&
                      errors.secondary_parent_full_name.message}
                  </span>
                </Grid>
                <Grid item md={6} className={classes.formWrapper}>
                  <InputLabel>
                    Full Address
                    <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <TextField
                    variant="outlined"
                    name="secondary_parent_address"
                    placeholder="Secondary Guardian Address"
                    inputRef={register({
                      required: 'Enter Secondary Guardian Address',
                    })}
                  />
                  <span className={classes.validationErrorInfo}>
                    {errors.secondary_parent_address &&
                      errors.secondary_parent_address.message}
                  </span>
                </Grid>
                <Grid item md={4} className={classes.formWrapper}>
                  <InputLabel>
                    Relation
                    <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <Autocomplete
                    onChange={(
                      event: React.ChangeEvent<{}>,
                      value: Tupple | null
                    ) => setSecondaryRelation(value)}
                    options={RELATION_STATUS_CHOICES}
                    value={secondaryRelation}
                    getOptionLabel={(option) => option.value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Relation with Student"
                        variant="outlined"
                        name="secondary_parent_relation"
                        inputRef={register({
                          required: 'Select relation with student',
                        })}
                      />
                    )}
                  />
                  <span className={classes.validationErrorInfo}>
                    {errors.secondary_parent_relation &&
                      errors.secondary_parent_relation.message}
                  </span>
                </Grid>
                <Grid item md={4} className={classes.formWrapper}>
                  <InputLabel>
                    Phone
                    <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <TextField
                    variant="outlined"
                    name="secondary_parent_phone"
                    type="number"
                    placeholder="Secondary Guardian Phone Number"
                    inputProps={{ min: 0 }}
                    inputRef={register({
                      required: 'Secondary Guardian Phone number is rerquired',
                      maxLength: 10,
                      minLength: 9,
                    })}
                  />
                  <span className={classes.validationErrorInfo}>
                    {errors.secondary_parent_phone &&
                      errors.secondary_parent_phone.message}
                    {errors.secondary_parent_phone &&
                      errors.secondary_parent_phone.type === 'minLength' && (
                        <span>Phone Number must be (9-10) digits</span>
                      )}
                    {errors.secondary_parent_phone &&
                      errors.secondary_parent_phone.type === 'maxLength' && (
                        <span>Phone Number must be (9-10) digits</span>
                      )}
                  </span>
                </Grid>
                <Grid item xs={12} className={classes.formWrapper}>
                  <InputLabel>Secondary Guardian Photo</InputLabel>
                  <ImageUpload
                    setImage={setSecondaryImage}
                    image={secondaryImage}
                    id="upload-input2"
                  />

                  <span className={classes.validationErrorInfo}></span>
                </Grid>

                <Grid container justifyContent="flex-end">
                  <Grid item xs={3}>
                    <Grid container justifyContent="flex-end">
                      <Button
                        className={classes.submitButton}
                        type="submit"
                        color="primary"
                        variant="contained"
                        startIcon={<SaveIcon />}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid
                container
                justifyContent="flex-end"
                style={{ marginBottom: '2em' }}
              >
                <Grid item xs={3}>
                  <Grid container justifyContent="flex-end">
                    <Button
                      className={classes.submitButton}
                      type="submit"
                      color="primary"
                      variant="contained"
                      startIcon={<SaveIcon />}
                    >
                      {selectedGuardian || props.data ? 'Update' : 'Save'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </form>
      </div>
    </>
  );
};

export default ParentDetailForm;
