import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setSnackbar } from "../../../actions/SnackbarAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

export const getFeeStudents = createAsyncThunk(
  "getFeeStudents",
  async (
    data: {
      fee_head: string;
      month: string;
      grade: string;
      section: string;
      student_category: string;
      applied?: boolean;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const params = `fee_head=${data.fee_head}&month=${data.month}&grade=${data.grade}&section=${data.section}&catgory=${data.student_category}`;
      const res = await axios.get(
        `${ADMIN_API_URL}/update-invoice/single-fee-discount-apply/?applied=${
          data.applied ? `True` : `False`
        }&${params}`
      );
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(null);
    }
  }
);

export const applyFeeStudentDiscount = createAsyncThunk(
  "applyFeeStudentDiscount",
  async (data: any, { dispatch, rejectWithValue }) => {
    try {
      await axios.post(
        `${ADMIN_API_URL}/update-invoice/single-fee-discount-apply/`,
        data
      );
      dispatch(setSnackbar(true, "success", "Fee Discount Applied"));
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(null);
    }
  }
);

export const getStudentFees = createAsyncThunk(
  "getStudentFees",
  async (
    data: {
      student: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.get(
        `${ADMIN_API_URL}/update-invoice/multiple-fee-discount-apply/?student=${data.student}`
      );
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(null);
    }
  }
);

export const applyStudentFeesDiscount = createAsyncThunk(
  "applyStudentFeesDiscount",
  async (data: any, { dispatch, rejectWithValue }) => {
    try {
      await axios.post(
        `${ADMIN_API_URL}/update-invoice/multiple-fee-discount-apply/`,
        data
      );
      dispatch(setSnackbar(true, "success", "Fee Discount Applied"));
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(null);
    }
  }
);

export const returnMonthString = (month: string) => {
  switch (month) {
    case "1":
      return "Baisakh";

    case "2":
      return "Jestha";

    case "3":
      return "Asar";

    case "4":
      return "Shrawan";

    case "5":
      return "Bhadra";

    case "6":
      return "Asoj";

    case "7":
      return "Kartik";

    case "8":
      return "Mangsir";

    case "9":
      return "Poush";

    case "10":
      return "Magh";

    case "11":
      return "Falgun";

    case "12":
      return "Chaitra";

    default:
      return "";
  }
};
