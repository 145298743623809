// ---------------- <START> module import ends ------------------//
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import { Paper, Grid, Typography, Button } from '@material-ui/core';
import { useTable } from '../../Reusable';
import { useTableStyles } from '../../Styles/TableStyles';

import {
  GetEvents,
  DeleteEvent,
  UpdateEvent,
} from '../../../actions/Event/Events/EventsAction';
import CustomizedDialogs from '../../Reusable/Dialogs/DeleteDialog';
import TableButton, {
  ItemDeleteButton,
  ItemEditButton,
  PostAddButton,
  ItemViewButton,
} from '../../Reusable/Buttons/TableButton';
import { dateConverterAdToBs } from '../../utils/dateConverter';
import { fromToConverter } from '../../utils/dateTimeFormat';
import Popups from '../../Reusable/Dialogs/Popups';
import BraftEditor from 'braft-editor';
import styled from 'styled-components';
import EventNoticeForm from './EventNoticeForm';
import { noticeBraftData } from './NoticeMockup';
import EventNoticeInfo from './EventNoticeInfo';
import PeopleIcon from '@material-ui/icons/People';
import ManageEventResult from './ManageEventResult';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import ManageEventParticipants from './ManageEventParticipants';
import { UPDATE_EVENTS_SUCCESS } from '../../../actions/Event/Events/EventsActionTypes';
import AccessPermission from '../../Student/StudentDetail/AccessPermission';
// ---------------- <END> module import ends ------------------//

// ---------------- <START> Interface------------------//
interface PropsI {
  onEditData?: (value: TableDataI) => void;
}

interface TableDataI {
  id: string;
  event_type: string;
  event_type_name: string;
  title: string;
  location: string;
  from_date: string;
  to_date: string;
  time: string;
  is_holiday: boolean;
  description: string;
  general_info: string;
  created_by: string;
  notice?: string;
}

interface HeadCellsI {
  id: string;
  label: string;
  align?: any;
}
// ---------------- <END> Interface------------------//

// -------------------- <START> Table Headings Data ---------------------------//
const headCells: HeadCellsI[] = [
  { id: 'title', label: 'Title', align: 'left' },
  { id: 'event_type', label: 'Type', align: 'left' },
  // { id: 'location', label: 'Location' },
  { id: 'date', label: 'Date' },
  // { id: 'time', label: 'Time' },
  { id: 'is_holiday', label: 'Holiday Status' },
  { id: 'action', label: 'Action' },
];

const StyledBraftEditor = styled(BraftEditor)`
  background-color: ${({ theme }) => theme.palette.common.white};
`;

const StyledPopups = styled(Popups)`
  .MuiDialog-paper {
    background-color: #f5f5f5;
  }
`;

const StyledNoticeInfoContainer = styled.div`
  background: #f4f4f4;
  border-radius: 16px;
  padding: 16px;
`;

const StyledManagePeopleIcon = styled(PeopleIcon)`
  border-radius: 6px;
  padding: 2px;
  color: rgba(17, 143, 207, 1);
  border: 2px solid rgba(17, 143, 207, 1);
`;

const StyledManageGroupAddIcon = styled(GroupAddIcon)`
  border-radius: 6px;
  padding: 2px;
  color: rgba(177, 143, 207, 1);
  border: 2px solid rgba(177, 143, 207, 1);
`;

// ---------------------<END> Table Headings Data --------------------------//

const EventsList = (props: any) => {
  const { onEditData, editCheck } = props;
  const classes = useTableStyles();
  const dispatch = useDispatch();

  // Retrieving re-usable components from useTable
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  const [tableData, setTableData] = useState<TableDataI[]>([]);
  const [itemId, setItemId] = useState('');
  const [loading, setLoading] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [openEventViewPopup, setOpenEventViewPopup] = useState(false);
  const [openEventInfoPopup, setOpenEventInfoPopup] = useState(false);
  const [openManageResultPopup, setOpenManageResultPopup] = useState(false);
  const [openManageParticipantsPopup, setOpenManageParticipantsPopup] =
    useState(false);
  const [notice, setNotice] = useState(noticeBraftData);

  // Events reducer
  const eventsState = useSelector((state: RootStore) => state.events);

  // Fetch data
  useEffect(() => {
    dispatch(GetEvents());
  }, []);

  useEffect(() => {
    editCheck && setEditMode(editCheck.check);
  }, [editCheck]);

  useEffect(() => {
    const items: TableDataI[] = formatData(eventsState.events);

    setTableData(items);
  }, [eventsState]);

  const handleEditClicked = (data: TableDataI) => {
    props.onEditData(data);
    setEditMode(true);
  };

  // Delete methods
  const handleDeleteModal = (value: boolean, id: string) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteEvent(itemId));
    setDeleteModalOpen(false);
  };

  const formatData = (items: any) => {
    return items.map((item: any) => ({
      id: item.id,
      event_type: item.event_type,
      event_type_name: item.event_type_name,
      title: item.title,
      location: item.location,
      from_date: item.from_date,
      to_date: item.to_date,
      // date: fromToConverter(item.from_date, item.to_date), //from date to date
      time: item.time,
      is_holiday: item.is_holiday,
      description: item.description,
      general_info: item.general_info,
      created_by: item.created_by,
      notice: item.notice || noticeBraftData,
    }));
  };

  const getCustomTableRow = (item: TableDataI) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="left" className={classes.cell}>
          {item.title}
        </StyledTableCell>
        <StyledTableCell align="left" className={classes.cell}>
          {item.event_type_name}
        </StyledTableCell>
        {/* <StyledTableCell align="center" className={classes.cell}>
        {item.location}
    </StyledTableCell> */}
        <StyledTableCell align="center" className={classes.cell}>
          {fromToConverter(item.from_date, item.to_date)}
        </StyledTableCell>
        {/* <StyledTableCell align="center" className={classes.cell}>
          {item.time}
        </StyledTableCell> */}
        <StyledTableCell align="center" className={classes.cell}>
          {item.is_holiday ? 'Yes' : 'No'}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <AccessPermission>
            <ItemEditButton onClick={() => handleEditClicked(item)} />
            <ItemDeleteButton
              disabled={editMode}
              onClick={() => handleDeleteModal(true, item.id)}
            />
          </AccessPermission>
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  const handleNoticeOpen = (eventId: string) => {
    setItemId(eventId);
    setOpenEventViewPopup(true);

    const event = tableData.find((item: any) => item.id === eventId);
    if (event) setNotice(event.notice || '');
  };

  const handleNoticeChange = (data: string) => {
    const event = tableData.find((item: any) => item.id === itemId);

    if (event && data) {
      dispatch({
        type: UPDATE_EVENTS_SUCCESS,
        payload: { ...event, notice: data },
      });
    }

    setNotice(data);
  };

  const handleNoticeClose = () => {
    setItemId('');
    setOpenEventViewPopup(false);
  };

  const handleViewNoticeClose = () => {
    setOpenEventInfoPopup(false);
  };

  const handleManageResultClose = () => {
    setOpenManageResultPopup(false);
  };

  const handleManageParticipantsClose = () => {
    setOpenManageParticipantsPopup(false);
  };

  return (
    <>
      <Paper className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">Event List</Typography>
          </Grid>
        </Grid>

        <TableContainer
          loading={loading}
          items={tableData}
          headCells={headCells}
          getCustomTableRow={getCustomTableRow}
          search_name="title"
          rowsPerPage={7}
        />
      </Paper>

      {/* Delete modal dialog */}
      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
      />

      <StyledPopups
        openPopup={openEventViewPopup}
        setOpenPopup={setOpenEventViewPopup}
        onClose={handleNoticeClose}
        maxWidth="xl"
        title="Event Notice"
        colored
      >
        <EventNoticeForm
          notice={notice}
          handleChange={handleNoticeChange}
          handleClose={handleNoticeClose}
        />
      </StyledPopups>

      <Popups
        openPopup={openEventInfoPopup}
        setOpenPopup={setOpenEventInfoPopup}
        onClose={handleViewNoticeClose}
        maxWidth="xl"
        title="Event Information"
        colored
      >
        <EventNoticeInfo notice={notice} />
      </Popups>

      <Popups
        openPopup={openManageResultPopup}
        setOpenPopup={setOpenManageResultPopup}
        onClose={handleManageResultClose}
        maxWidth="sm"
        title="Manage Event Results"
        colored
      >
        <ManageEventResult />
      </Popups>

      <Popups
        openPopup={openManageParticipantsPopup}
        setOpenPopup={setOpenManageParticipantsPopup}
        onClose={handleManageParticipantsClose}
        maxWidth="md"
        title="Manage Event Participants"
        colored
      >
        <ManageEventParticipants />
      </Popups>
    </>
  );
};

export default EventsList;
