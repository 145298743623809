import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { LeaveTypeTypeI } from "../../actions/HumanResource/LeaveType/LeaveTypeActionTypes";
import { HRsidebar } from "../../components";
import LeaveTypeForm from "../../components/HumanResource/LeaveType/LeaveTypeForm";
import LeaveTypeList from "../../components/HumanResource/LeaveType/LeaveTypeList";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const LeaveTypePage = () => {
  const [formData, setFormData] = useState<LeaveTypeTypeI | null>(null);
  const fetchEditData = (data: LeaveTypeTypeI) => {
    setFormData(data);
  };
  const nav = <HRsidebar LeaveType />;
  return (
    <SubModuleLayout HumanMenuActive sideNav={nav}>
      <Grid container>
        <Grid item xs={3}>
          <LeaveTypeForm editData={formData} />
        </Grid>
        <Grid item xs={9}>
          <LeaveTypeList onEditData={fetchEditData} />
        </Grid>
      </Grid>
    </SubModuleLayout>
  );
};

export default LeaveTypePage;
