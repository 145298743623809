import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import PotraitStudentID from "../NewIDCARDDESIGN/potrait/PotraitStudentID";
import LandScapeStudentID from "../NewIDCARDDESIGN/landscape/LandScapeStudentID";
import PotraitTeacherIDCard from "../NewIDCARDDESIGN/potrait/PotraitTeacherIDCard";
import LandScapeTeacherCard from "../NewIDCARDDESIGN/landscape/LandScapeTeacherCard";
import PrintIcon from "@material-ui/icons/Print";
import EmailIcon from "@material-ui/icons/Email";
export interface SimpleDialogProps {
  student: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    content: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      backgroundColor: "#3780ce",
      paddingBottom: "16px!important",
    },
    contentid: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    idImage: {
      width: "200px",
      height: "200px",
    },
    id: {
      display: "flex",
      flexDirection: "column",
    },
    mediumFont: {
      color: "white",
      fontSize: "16px",
    },
    spacing: {
      marginTop: "10px",
    },
  })
);

export default function IdCardPopup(props: any) {
  const { student: item, activeID, idCard } = props;

  const componentRef = useRef<any>();

  const renderSwitch = (param: string) => {
    switch (param) {
      case "ABC-001":
        return <PotraitStudentID content={activeID} data={idCard} />;
      case "ABC-002":
        return <PotraitTeacherIDCard content={activeID} data={idCard} />;
      case "ABC-003":
        return <LandScapeStudentID content={activeID} data={idCard} />;
      case "ABC-004":
        return <LandScapeTeacherCard content={activeID} data={idCard} />;
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          backgroundColor: "#657ff3",
        }}
      >
        <ReactToPrint
          trigger={() => (
            <div
              style={{
                backgroundColor: "#657ff3",
                color: "white",
                fontWeight: "bold",
                borderRadius: "0px",
                margin: "0px",
                textAlign: "center",
                padding: "2px 0px 2px 0px",
                cursor: "pointer",
              }}
            >
              <PrintIcon />
            </div>
          )}
          content={() => componentRef.current}
          removeAfterPrint={true}
          copyStyles={true}
        />
        <ReactToPrint
          trigger={() => (
            <div
              style={{
                backgroundColor: "#657ff3",
                color: "white",
                fontWeight: "bold",
                borderRadius: "0px",
                margin: "0px",
                textAlign: "center",
                padding: "2px 0px 2px 0px",
                cursor: "pointer",
              }}
            >
              <EmailIcon />
            </div>
          )}
          content={() => componentRef.current}
          removeAfterPrint={true}
          copyStyles={true}
        />
      </div>

      <div id="print-element" ref={componentRef}>
        <div className="marksheet-print">{renderSwitch(activeID?.code)}</div>
      </div>
    </>
  );
}
