import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setSnackbar } from "../../../actions/SnackbarAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

export const getDeviceConfigs = createAsyncThunk(
  "attendanceDevice/fetchDeviceConfigs",
  async (unknown, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.get(`${ADMIN_API_URL}/device/attendance/config`);

      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(null);
    }
  }
);

export const createDeviceConfig = createAsyncThunk(
  "attendanceDevice/createDeviceConfig",
  async (
    data: {
      ip_address: string;
      port: string;
      username: string;
      password: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.post(
        `${ADMIN_API_URL}/device/attendance/config/`,
        data
      );

      dispatch(
        setSnackbar(true, "success", "Device Config Created Successfully.")
      );
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(null);
    }
  }
);

export const readDeviceConfig = createAsyncThunk(
  "attendanceDevice/readDeviceConfig",
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.get(
        `${ADMIN_API_URL}/device/attendance/config/?${id}`
      );

      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(null);
    }
  }
);

export const updateDeviceConfig = createAsyncThunk(
  "attendanceDevice/updateDeviceConfig",
  async (
    data: {
      id: string;
      ip_address: string;
      port: string;
      username: string;
      password: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.put(
        `${ADMIN_API_URL}/device/attendance/config/${data.id}/`,
        data
      );
      dispatch(setSnackbar(true, "success", "Config Updated Successfully."));
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(null);
    }
  }
);

export const deleteDeviceConfig = createAsyncThunk(
  "attendanceDevice/removeDeviceConfig",
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      await axios.delete(`${ADMIN_API_URL}/device/attendance/config/${id}`);
      dispatch(setSnackbar(true, "success", "Config Removed Successfully."));
      return { id };
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(null);
    }
  }
);

export const syncDeviceConfig = createAsyncThunk(
  "attendanceDevice/syncDeviceConfig",
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      await axios.get(
        `${ADMIN_API_URL}/device/attendance/config/${id}/sync_data`
      );
      dispatch(
        setSnackbar(
          true,
          "success",
          "Device configuration synced successfully."
        )
      );
      return { id };
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      dispatch(
        setSnackbar(true, "error", "Device configuration synced failed.")
      );
      return rejectWithValue(null);
    }
  }
);

export const testDeviceConfigConnection = createAsyncThunk(
  "attendanceDevice/testDeviceConfigConnection",
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      await axios.get(
        `${ADMIN_API_URL}/device/attendance/config/${id}/test_connection`
      );
      dispatch(setSnackbar(true, "success", "Test connection successful."));
      return { id };
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      dispatch(setSnackbar(true, "error", "Test connection failed."));
      return rejectWithValue(null);
    }
  }
);

export const getAttendanceDevices = createAsyncThunk(
  "attendanceDevice/fetchDevices",
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.get(
        `${ADMIN_API_URL}/device/attendance/devices?config=${id}`
      );

      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(null);
    }
  }
);

export const getSingleAttendanceDevice = createAsyncThunk(
  "attendanceDevice/getSingleDevice",
  async (
    data: {
      config: string;
      device: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.get(
        `${ADMIN_API_URL}/device/attendance/devices/${data.device}/?config=${data.config}`
      );

      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(null);
    }
  }
);

export const createAttendanceDevice = createAsyncThunk(
  "attendanceDevice/createDevice",
  async (
    data: {
      id: string;
      sn: string;
      area: string;
      alias: string;
      ip_address: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.post(
        `${ADMIN_API_URL}/device/attendance/devices/?config=${data.id}/`,
        data
      );

      dispatch(setSnackbar(true, "success", "Device addded successfully."));
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(null);
    }
  }
);

export const syncAttendanceDevice = createAsyncThunk(
  "attendanceDevice/syncAttendanceDevice",
  async (
    data: {
      config: string;
      deviceSn: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.get(
        `${ADMIN_API_URL}/device/attendance/sync-transaction/?config=${data.config}&sn=${data.deviceSn}`
      );
      dispatch(setSnackbar(true, "success", "Device synced successfully."));
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      dispatch(setSnackbar(true, "warning", "Failed to sync device."));
      return rejectWithValue(null);
    }
  }
);

export const getSyncedDepartments = createAsyncThunk(
  "attendanceDevice/fetchSyncedDepartments",
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.get(
        `${ADMIN_API_URL}/device/attendance/synced-departments`
      );

      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(null);
    }
  }
);

export const syncDepartmentsToDevice = createAsyncThunk(
  "attendanceDevice/syncDepartmentsToDevice",
  async (
    data: {
      id: string;
      departments: { departments: string[] };
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.post(
        `${ADMIN_API_URL}/device/attendance/sync-departments/?config=${data.id}`,
        data.departments
      );
      dispatch(
        setSnackbar(true, "success", "Departments synced successfully.")
      );
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(null);
    }
  }
);

export const getEmployeesToSync = createAsyncThunk(
  "attendanceDevice/fetchEmployeesToSync",
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.get(
        `${ADMIN_API_URL}/device/attendance/sync-employee/?config=${id}`
      );

      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(null);
    }
  }
);

export const syncEmployeesToDevice = createAsyncThunk(
  "attendanceDevice/syncEmployeesToDevice",
  async (
    data: {
      config: string;
      device: string;
      syncable_employees: string[];
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.post(
        `${ADMIN_API_URL}/device/attendance/sync-employee/?config=${data.config}`,
        data
      );
      dispatch(setSnackbar(true, "success", "Employees synced successfully."));
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(null);
    }
  }
);

export const getSyncedEmployees = createAsyncThunk(
  "attendanceDevice/fetchSyncedEmployees",
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.get(
        `${ADMIN_API_URL}/device/attendance/sync-employee/?config=${id}`
      );

      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(null);
    }
  }
);
