// ---------------------------- <START> module imports start ----------------------------------//
import React, { useEffect, useState, useRef } from "react";
import {
  TableBody,
  Grid,
  TableHead,
  makeStyles,
  Theme,
  Card,
} from "@material-ui/core";
import { PrintTableStyles } from "../../Styles/PrintTableStyles";
import { useTablePrint } from "../../Reusable";
import { useSelector } from "react-redux";
import { RootStore } from "../../../store";
import { MarkSheetTypeI } from "../../../actions/Examination/MarkSheet/MarkSheetActionTypes";
import { Printd } from "printd";
import printJS from "print-js";
import ReactToPrint from "react-to-print";
import { dateConverterAdToBs } from "../../utils/dateConverter";
import getFullName from "../../utils/getFullName";
import getFullClassName from "../../utils/getFullClassName";
import PotraitTeacherIDCard from "../../Reports/NewIDCARDDESIGN/potrait/PotraitTeacherIDCard";
import LandScapeTeacherCard from "../../Reports/NewIDCARDDESIGN/landscape/LandScapeTeacherCard";
import { Sticky } from "../../Reports/Marksheet/ReportStyles";
import checkImageUrl from "../../utils/checkImageUrl";
// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//

interface HeadCellsI {
  id: string;
  label: string;
}
// ---------------------------- <END> interface ends ----------------------------------//

interface HeadCellsI {
  id: string;
  label: string;
}

// -------------------- <START> Table Headings Data ---------------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S. No." },
  { id: "subject", label: "Subjects" },
  { id: "fullMarks", label: "Full Marks" },
  { id: "passMarks", label: "Pass Marks" },
  { id: "th", label: "TH" },
  { id: "pr", label: "PR" },
  { id: "total", label: "Total" },
  { id: "grade_point", label: "Grade Point" },
];
// ---------------------<END> Table Headings Data Ends --------------------------//

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2, 4),
    "& .MuiCardContent-root": {
      padding: theme.spacing(0),
    },
    "& .MuiTypography-h2": {
      fontSize: "3.1rem",
    },
    caption: {
      padding: "0px",
    },
  },
  idImage: {
    width: "80px",
    height: "80px",
  },
  line: {
    borderRight: "1px solid black",
  },
  bottom: {
    marginBottom: theme.spacing(1),
  },
  tableCell: {
    padding: "0px 5px !important",
    border: "1px solid black",
  },
  topWatermark: {
    backgroundImage: `url('https://i.imgur.com/KO5poDX.png')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "70%",
    backgroundPosition: "center",
  },
  centerWatermark: {
    backgroundImage: `url('https://i.imgur.com/KO5poDX.png')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "70%",
    backgroundPosition: "center",
  },
}));

interface propsI {
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  student: string;
  gpa: number;
}

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const MultipleStaffIdCardPopup = (props: any) => {
  const { onClose, student, content } = props;
  const classes = PrintTableStyles();
  const localClasses = useStyles();

  // States declaration
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<MarkSheetTypeI[]>([]);
  const [printMode, setPrintMode] = useState<boolean>(false);

  const componentRef = useRef<any>();

  const schoolState = useSelector(
    (state: RootStore) => state.auth.general_info
  );
  const examState = useSelector((state: RootStore) => state.exam.exam);
  const studentState = useSelector(
    (state: RootStore) => state.student.student_by_id
  );

  // Retrieving re-usable components from useTable
  const {
    TblContainer,
    TblHead,
    StyledTableCell,
    StyledTableRow,
    exportToExcel,
    tablePrint,
  } = useTablePrint(headCells);

  // ============================ <START> REACT HOOKS <START> ============================== //
  const marksheetSelector = useSelector((state: RootStore) => state.marksheet);
  const idCardSelector = useSelector((state: RootStore) => state.id_card);

  useEffect(() => {
    setLoading(marksheetSelector.loading);
    setTableData(marksheetSelector.marksheet);
  }, [marksheetSelector]);

  // ============================ <END> REACT HOOKS <END> ============================== //

  // ============================ <START> EVENT HANDLERS <START> ============================== //

  // Event function for table searching by name
  const handleTableSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    return;
  };

  const handlePrint = (e: any) => {
    e.preventDefault();
    const d = new Printd();
    const modalRoot = document.getElementById("print-element") as HTMLElement;
    d.print(modalRoot);
  };

  const renderSwitch = (item: any, param: string) => {
    const idCardData = {
      full_name: getFullName(item.first_name, item.last_name),
      dob: item?.date_of_birth,
      blood_group: item?.blood_group_name,
      address: item?.address,
      phone_number: item?.phone,
      designation: item?.designation_name,
      date_of_joining: item?.date_of_joining,
      photo: checkImageUrl(item?.photo),
    };
    switch (param) {
      case "ABC-002":
        return (
          <PotraitTeacherIDCard content={props.content} data={idCardData} />
        );
      case "ABC-004":
        return (
          <LandScapeTeacherCard content={props.content} data={idCardData} />
        );
      default:
        return "Nothing Found";
    }
  };
  // ============================ <END> EVENT HANDLERS <END> ============================== //
  return (
    <>
      <Sticky>
        <ReactToPrint
          trigger={() => (
            <p
              style={{
                backgroundColor: "#132E98",
                color: "white",
                fontWeight: "bold",
                borderRadius: "0px",
                margin: "0px",
                textAlign: "center",
                padding: "5px 0px",
                cursor: "pointer",
              }}
            >
              Print This Out !
            </p>
          )}
          content={() => componentRef.current}
          removeAfterPrint={true}
          copyStyles={true}
        />
      </Sticky>
      <Card id="print-element" ref={componentRef} style={{ padding: "10px" }}>
        {idCardSelector?.staff_id_cards?.map((item: any, index: number) => {
          return (
            <div key={index} style={{ marginBottom: "25px" }}>
              {renderSwitch(item, props?.content?.code)}
            </div>
          );
        })}
      </Card>
    </>
  );
};

// ---------------------------- <END> Student Table Component Ends -------------------------------//
export default MultipleStaffIdCardPopup;
