import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_STUDENT_DOCUMENT_LOADING,
  GET_STUDENT_DOCUMENT_ERROR,
  GET_STUDENT_DOCUMENT_SUCCESS,
  DocumentDispatchTypes,
  ADD_STUDENT_DOCUMENT_LOADING,
  ADD_STUDENT_DOCUMENT_SUCCESS,
  ADD_STUDENT_DOCUMENT_ERROR,
  DELETE_STUDENT_DOCUMENT_SUCCESS,
  DELETE_STUDENT_DOCUMENT_ERROR,
  DELETE_STUDENT_DOCUMENT_LOADING,
  UPDATE_STUDENT_DOCUMENT_LOADING,
  UPDATE_STUDENT_DOCUMENT_SUCCESS,
  UPDATE_STUDENT_DOCUMENT_ERROR,
} from "./StudentDocumentActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

interface RequestDataI {
  id: string;
  student: string;
  title: string;
  document: any;
  created_by: string;
}

export const GetDocument = (studID: string) => async (
  dispatch: Dispatch<DocumentDispatchTypes | SetSnackBarI>
) => {
  try {
    dispatch({
      type: GET_STUDENT_DOCUMENT_LOADING,
    });

    const res = await axios.get(
      `${ADMIN_API_URL}/student_document/?student=${studID}`,
      HeaderConfig()
    );

    dispatch({
      type: GET_STUDENT_DOCUMENT_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_STUDENT_DOCUMENT_ERROR,
    });
  }
};

export const AddDocument = (data: any) => async (
  dispatch: Dispatch<DocumentDispatchTypes | SetSnackBarI>
) => {
  try {
    dispatch({
      type: ADD_STUDENT_DOCUMENT_LOADING,
    });

    const res = await axios.post(
      `${ADMIN_API_URL}/student_document/`,
      data,
      HeaderConfig()
    );
    dispatch({
      type: ADD_STUDENT_DOCUMENT_SUCCESS,
      payload: res.data,
    });

    dispatch({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarType: "success",
        snackbarMessage: "Document Added Successfully",
      },
    });
  } catch (error) {
    dispatch({
      type: ADD_STUDENT_DOCUMENT_ERROR,
    });
  }
};

export const UpdateDocument = (id: number, data: any) => async (
  dispatch: Dispatch<DocumentDispatchTypes | SetSnackBarI>
) => {
  try {
    dispatch({
      type: UPDATE_STUDENT_DOCUMENT_LOADING,
    });

    const res = await axios.patch(
      `${ADMIN_API_URL}/student_document/${id}/`,
      data,
      HeaderConfig()
    );

    dispatch({
      type: UPDATE_STUDENT_DOCUMENT_SUCCESS,
      payload: res.data,
    });

    dispatch({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarType: "success",
        snackbarMessage: "Document Updated Successfully",
      },
    });
  } catch (error) {
    dispatch({
      type: UPDATE_STUDENT_DOCUMENT_ERROR,
    });
  }
};

export const DeleteDocument = (id: string) => async (
  dispatch: Dispatch<DocumentDispatchTypes | SetSnackBarI>
) => {
  try {
    dispatch({
      type: DELETE_STUDENT_DOCUMENT_LOADING,
    });

    const res = await axios.delete(
      `${ADMIN_API_URL}/student_document/${id}/`,
      HeaderConfig()
    );

    dispatch({
      type: DELETE_STUDENT_DOCUMENT_SUCCESS,
      payload: { id: id },
    });

    dispatch({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarType: "success",
        snackbarMessage: "Document Deleted Successfully",
      },
    });
  } catch (error) {
    dispatch({
      type: DELETE_STUDENT_DOCUMENT_ERROR,
    });
  }
};
