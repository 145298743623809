import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_STAFF_PROFILE_LOADING,
  GET_STAFF_PROFILE_ERROR,
  GET_STAFF_PROFILE_SUCCESS,
  GET_TEACHER_ACADEMIC_INFO_LOADING,
  GET_TEACHER_ACADEMIC_INFO_ERROR,
  GET_TEACHER_ACADEMIC_INFO_SUCCESS,
  StaffProfileDispatchTypes,
  UPDATE_STAFF_USERNAME_LOADING,
  UPDATE_STAFF_USERNAME_SUCCESS,
  UPDATE_STAFF_USERNAME_ERROR,
  GET_STAFF_PROFILE_LEAVE_ERROR,
  GET_STAFF_PROFILE_LEAVE_LOADING,
  GET_STAFF_PROFILE_LEAVE_SUCCESS,
} from "./StaffProfileActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

export const GetStaffProfile =
  (id: any) =>
  async (dispatch: Dispatch<StaffProfileDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_STAFF_PROFILE_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/staff/${id}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_STAFF_PROFILE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_STAFF_PROFILE_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "Staffs Loading Failed",
      //   },
      // });
    }
  };

export const GetTeacherAcademicInfo =
  (user_id: string) =>
  async (dispatch: Dispatch<StaffProfileDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_TEACHER_ACADEMIC_INFO_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/teacher-academic-info/?user=${user_id}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_TEACHER_ACADEMIC_INFO_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_TEACHER_ACADEMIC_INFO_ERROR,
      });
    }
  };

// export const GetTeacherTimetable = (id: string) => async (
//   dispatch: Dispatch<StaffProfileDispatchTypes | SetSnackBarI>
// ) => {
//   try {
//     dispatch({
//       type: GET_TEACHER_TIMETABLE_LOADING,
//     });

//     const res = await axios.get(`${ADMIN_API_URL}/staff/${id}`, HeaderConfig());

//     dispatch({
//       type: GET_TEACHER_TIMETABLE_SUCCESS,
//       payload: res.data,
//     });
//   } catch (error) {
//     dispatch({
//       type: GET_TEACHER_TIMETABLE_ERROR,
//     });
//   }
// };

export const updateStaffUsername =
  (data: { user: string; updated_username: string }) =>
  async (
    dispatch: Dispatch<StaffProfileDispatchTypes | SetSnackBarI | any>
  ) => {
    try {
      dispatch({
        type: UPDATE_STAFF_USERNAME_LOADING,
      });

      await axios.post(
        `${ADMIN_API_URL}/update-username/`,
        data,
        HeaderConfig()
      );
      dispatch({
        type: UPDATE_STAFF_USERNAME_SUCCESS,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Username Updated Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: UPDATE_STAFF_USERNAME_ERROR,
        payload: error.response && error.response?.data,
      });
    }
  };

export const GetStaffProfileLeave =
  (id: string) =>
  async (dispatch: Dispatch<StaffProfileDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_STAFF_PROFILE_LEAVE_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/staff/get_staff_leaves/?staff=${id}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_STAFF_PROFILE_LEAVE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_STAFF_PROFILE_LEAVE_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Staff Leave Loading Failed",
        },
      });
    }
  };
