import React, { useState } from "react";
import { useSidebar } from "../Reusable";
import { useSidebarStyles } from "../Styles/SidebarStyles";
import { MenuBookOutlined } from "@material-ui/icons";
import SidebarTitle from "../Reusable/Titles/SidebarTitle";

interface propsI {
  addMarks?: boolean | undefined;
  grades?: boolean | undefined;
  ape?: boolean | undefined;
  ledger?: boolean | undefined;
}

const ResultSidebar = (props: propsI) => {
  const classes = useSidebarStyles();
  const [addMarks] = useState(props.addMarks);
  const [grades] = useState(props.grades);
  const [ape] = useState(props.ape);
  const [ledger] = useState(props.ledger);

  const dataSet = [
    { text: "Add Marks", url: "add-marks", activeStatus: addMarks },
    { text: "Grades", url: "grades", activeStatus: grades },
    { text: "APE Factor", url: "ape-factor", activeStatus: ape },
    { text: "Ledger", url: "ledger", activeStatus: ledger },
  ];
  const { CustomSidebar } = useSidebar(dataSet, "FINANCE");
  return (
    <div className={classes.Sidebar}>
      <div className={classes.sidebarContent}>
        <SidebarTitle icon={<MenuBookOutlined />}>Result</SidebarTitle>
        <CustomSidebar />
      </div>
    </div>
  );
};

export default ResultSidebar;
