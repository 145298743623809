import React from "react";
import { SettingSidebar } from "../../../components";
import SubModuleLayout from "../../../components/Reusable/Layouts/SubModuleLayout";
import AttendanceDeviceList from "../../../components/GeneralSetting/AttendanceDevice/AttendanceDeviceList";

const AttendanceDeviceListPage = () => {
  const nav = <SettingSidebar attendanceDevice />;
  return (
    <SubModuleLayout sideNav={nav}>
      <AttendanceDeviceList />
    </SubModuleLayout>
  );
};

export default AttendanceDeviceListPage;
