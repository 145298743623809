import {
  Divider,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Tooltip,
} from '@material-ui/core';
import React, { ReactNode, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import CustomizedPopUp from '../../Reusable/Dialogs/CustomizedPopUp';
import { useForm } from 'react-hook-form';
import { Autocomplete } from '@material-ui/lab';
import { useFormStyles } from '../../Styles/FormStyles';
import AddNewButton from '../../Reusable/Buttons/AddNewButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  GetPaginatedStudent,
  GetStudentClass,
  SearchStudent,
} from '../../../actions/Student/Student/StudentAction';
import { GetClasses } from '../../../actions/Academics/Class/ClassAction';
import { RootStore } from '../../../store';
import { GetSessions } from '../../../actions/GeneralSettings/Session/SessionAction';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import { Add } from '@material-ui/icons';
import StudentAdmissionModal from './StudentAdmissionModal';
import AccessPermission from './AccessPermission';

// -----------------<start> Styling <start>-----------------------//

// -----------------<end> Styling <end>-----------------------//

// -----------------<start> Interfaces <start>-----------------------//

interface FormI {
  // academic_year: string;
  class_name: string;
  section_name: string;
}

interface DataI {
  // academic_year: string | number | null | undefined;
  class: number | null | undefined;
  section: number | string | null | undefined;
  // rollNumber: string | number | null;
}

// interface AcademicYearI {
//   id: string;
//   year: string;
// }

interface SectionI {
  id: string;
  title: string;
}

interface ClassI {
  id: string;
  title: string;
  sections: SectionI[] | [];
}

interface propsI {
  setClassNameForPagination: (value: string) => void;
  setSectionForPagination: (value: string) => void;
  selectedPagination: 'string';
  searchParam: string;
  className: ClassI | null;
  section: SectionI | null;
  setClassName: (value: ClassI | null) => void;
  setSection: (value: SectionI | null) => void;
  setSearchParam: (value: string) => void;
}

const SearchTimetable = (props: propsI) => {
  const {
    setSearchParam,
    searchParam,
    setClassName,
    className,
    section,
    setSection,
  } = props;
  const classes = useFormStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, errors, clearErrors } = useForm<any>();
  const [sectionChoices, setSectionChoices] = useState<SectionI[] | []>([]);
  const [sectionDisabler, setSectionDisabler] = useState<boolean>(true);
  const [admissionFormOpen, setAdmissionFormOpen] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');

  useEffect(() => {
    dispatch(GetClasses());
    dispatch(GetSessions());
  }, []);

  const authState = useSelector((state: RootStore) => state.auth);
  const classState = useSelector((state: RootStore) => state.class);

  // Get current user permission
  const role = authState.role || 'Administrator';

  const classChoices: any = classState.classes.map((classItem, index) => ({
    id: classItem.grade,
    title: classItem.grade_name,
    sections: classItem.section.map((sectionItem) => ({
      id: sectionItem.id,
      title: sectionItem.name,
    })),
  }));

  const handleClassChange = (value: ClassI | null) => {
    console.log({ value });
    setSearchParam('');
    clearErrors('class_name');
    setClassName(value);
    setSection(null);
    props.setClassNameForPagination(value ? value.id : '');
    value != null
      ? populateSectionChoices(value.sections)
      : setSectionDisabler(true);
    dispatch(
      GetPaginatedStudent(
        1,
        parseInt(props.selectedPagination),
        value ? value.id.toString() : '',
        value && section ? section.id.toString() : ''
      )
    );
  };

  const handleSectionChange = (value: any | null) => {
    setSection(value);
    props.setSectionForPagination(
      className && value ? value.id.toString() : ''
    );
    dispatch(
      GetPaginatedStudent(
        1,
        parseInt(props.selectedPagination),
        className ? className.id.toString() : '',
        className && value ? value.id.toString() : ''
      )
    );
    clearErrors('section_name');
  };

  const populateSectionChoices = (sections: SectionI[] | []) => {
    setSectionDisabler(false);
    sections.length > 0
      ? setSectionChoices(sections)
      : setSectionDisabler(true);
  };

  const onRedirect = () => {
    history.push('/student-admission');
  };

  const handleReset = () => {
    setSearchQuery('');
    setSearchParam('');
    setSection(null);
    setClassName(null);
    clearErrors('class_name');
    dispatch(
      GetPaginatedStudent(1, parseInt(props.selectedPagination), '', '')
    );
    props.setClassNameForPagination('');
    props.setSectionForPagination('');
  };

  const handleSearchStudentByLetter = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setSection(null);
    setClassName(null);
    const text = event.target.value;
    setSearchQuery(text);
    setSearchParam(text);
    dispatch(SearchStudent(text, 1, parseInt(props.selectedPagination)));
    setClassName(null);
    setSection(null);
    setSectionDisabler(true);
  };

  const handleModalClose = (val: boolean) => {
    setAdmissionFormOpen(val);
  };

  console.log('User Permission Role', role);

  return (
    <>
      <form className={classes.form}>
        <Grid container>
          <Grid item xs={9} className={classes.formTitle}>
            <span>Search Students</span>
          </Grid>
          <Grid container item xs={3}>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Tooltip title="Clear Search Query">
                  <IconButton
                    aria-label="delete"
                    style={{ padding: '2px' }}
                    onClick={handleReset}
                  >
                    <SettingsBackupRestoreIcon />
                  </IconButton>
                </Tooltip>
              </Grid>

              <Grid item>
                <AccessPermission administrator>
                  <AddNewButton
                    title="Add New Student"
                    type="button"
                    color="primary"
                    variant="outlined"
                    startIcon={<Add />}
                    onClick={() => setAdmissionFormOpen(true)}
                  >
                    New Admission
                  </AddNewButton>
                </AccessPermission>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <CustomizedPopUp
          title="New Admission"
          open={admissionFormOpen}
          onClose={handleModalClose}
          actionConfirmed={onRedirect}
          dialogTitle="Admission Form for New Student"
          okText={false}
          cancel={false}
        >
          <StudentAdmissionModal handleModalClose={handleModalClose} />
        </CustomizedPopUp>

        <Grid container spacing={3}>
          <Grid item xs={2} className={classes.formWrapper}>
            <InputLabel>
              Select Grade<span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <Autocomplete
              onChange={(event: React.ChangeEvent<{}>, value: ClassI | null) =>
                handleClassChange(value)
              }
              options={classChoices}
              value={className}
              getOptionLabel={(option) => option.title}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Label"
                  name="class_name"
                  variant="outlined"
                  inputRef={register({ required: 'Grade is required.' })}
                />
              )}
            />
            <span className={classes.validationErrorInfo}>
              {errors.class_name?.type === 'required' &&
                errors.class_name.message}
            </span>
          </Grid>

          <Grid item xs={2} className={classes.formWrapper}>
            <InputLabel>
              Select Section{' '}
              {/* {!sectionDisabler && <span style={{ color: "red" }}>*</span>} */}
            </InputLabel>
            <Autocomplete
              style={{
                padding: 0,
              }}
              value={section}
              onChange={(
                event: React.ChangeEvent<{}>,
                value: SectionI | null
              ) => handleSectionChange(value)}
              options={sectionChoices}
              getOptionLabel={(option) => option.title}
              disabled={sectionDisabler}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Label"
                  name="section_name"
                  variant="outlined"
                  inputRef={register({ required: false })}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={1}
            justifyContent="center"
            className={classes.formWrapper}
            style={{ paddingLeft: '50px', paddingTop: '30px' }}
          >
            OR
          </Grid>
          <Grid item xs={3} className={classes.formWrapper}>
            <InputLabel>Search Students By Name</InputLabel>
            <TextField
              variant="outlined"
              onChange={handleSearchStudentByLetter}
              value={searchParam}
            />
          </Grid>
        </Grid>
      </form>
      <Divider style={{ marginBottom: '10px' }} />
    </>
  );
};

export default SearchTimetable;
