import {
  TableCell,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Paper,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootStore } from "../../../store";
import { AttendenceColorBox } from "../../Reusable";
import { useTableStyles } from "../../Styles/TableStyles";
import { dateConverterBsToAd } from "../../utils/dateConverter";
import getDayName from "../../utils/getDayName";
import getFullName from "../../utils/getFullName";

const ReportList = (props: any) => {
  const classes = useTableStyles();
  const [dateCells, setDateCells] = useState<any | []>([]);

  const monthDates = useSelector((state: RootStore) => state.month_dates);
  const MyAttendance = useSelector(
    (state: RootStore) => state.staff_attendance.teacher_attendance
  );

  // set random leaves
  useEffect(() => {
    const tempDateCells = dateCells.map((dateCell: any) =>
      dateCell.attendance_detail.map((attendance: any) => ({
        ...attendance,
        status: "I",
      }))
    );
    setDateCells(tempDateCells);
  }, [MyAttendance]);

  console.log("MyAttendance", MyAttendance, dateCells);

  const checkStatus = (k: number, date: string) => {
    const dateObject = new Date(date);
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const _day = days[dateObject.getDay()];

    const isThere =
      MyAttendance &&
      MyAttendance[k]?.staff_attendance_report.find(
        (elem: any) => elem?.data[0]?.attendance_date == date
      );
    if (_day != "Sat") {
      if (isThere) {
        return "P";
      } else {
        return "x";
      }
    } else {
      return "holiday";
    }
  };

  useEffect(() => {
    if (MyAttendance != null) {
      const monthData = monthDates.data.dates;
      if (monthData != null) {
        const prepared = [];

        for (let i = 0; i < MyAttendance.length; i++) {
          const __temp = [];
          for (let j = 0; j < monthData.length; j++) {
            const convertedDate = dateConverterBsToAd(monthData[j]);
            const checked = monthData[j].substring(8, 10);

            __temp.push({
              status: checkStatus(i, convertedDate),
              date: checked,
              day: getDayName(convertedDate),
            });
          }
          prepared.push({
            student_first_name: getFullName(
              MyAttendance[i].user__first_name,
              MyAttendance[i].user__last_name
            ),
            attendance_detail: __temp,
          });
        }
        setDateCells(prepared);
      }
    }
  }, [MyAttendance]);

  const reportStatusToColor = {
    P: "#51AB67",
    holiday: "#EA5E42",
    I: "#51AB67",
    A: "#B9BCBE",
  };

  return (
    <>
      <Paper>
        {MyAttendance.length ? (
          <>
            <AttendenceColorBox
              monthName={props.monthName}
              childrenName={props.childrenName}
            />
            <div className={classes.tableWrapper}>
              <TableContainer>
                <Table
                  className={classes.table}
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" className={classes.cellDayName}>
                        <span>Staff Name</span>
                      </TableCell>
                      {dateCells[0] &&
                        dateCells[0].attendance_detail.map((column: any) => (
                          <TableCell
                            key={column.id}
                            align="center"
                            className={classes.newcell}
                          >
                            {column.date}
                          </TableCell>
                        ))}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="center"
                        className={classes.cellDayName}
                      ></TableCell>
                      {dateCells[0] &&
                        dateCells[0].attendance_detail.map((column: any) => (
                          <TableCell
                            key={column.id}
                            align="center"
                            className={classes.cellDayName}
                          >
                            <span key={column.id}>{column.day}</span>
                          </TableCell>
                        ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dateCells.map((item: any, index: number) => {
                      return (
                        <TableRow key={index} hover tabIndex={-1}>
                          <TableCell
                            key={index}
                            align="center"
                            className={classes.newcell}
                            style={{ width: "200px" }}
                          >
                            {item.student_first_name}
                          </TableCell>
                          {item.attendance_detail.map(
                            (item: any, index: number) => {
                              return (
                                <TableCell
                                  key={index}
                                  align="center"
                                  className={classes.newcell}
                                  style={{
                                    backgroundColor:
                                      item.status == "P"
                                        ? "#51AB67"
                                        : item.status == "holiday"
                                        ? "#EA5E42"
                                        : item.status == "I"
                                        ? "#FEAF4A"
                                        : "#B9BCBE",
                                    width: "35px",
                                  }}
                                ></TableCell>
                              );
                            }
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </>
        ) : (
          "There is no data to show."
        )}
      </Paper>
    </>
  );
};

export default ReportList;
