import styled from "styled-components";

export const Sheet = styled.div`
  padding: 5px 10px;

  @media print {
    width: 820px;
    margin: 0 auto;
    margin-top: 50px;

    page-break-after: always;
  }
`;

export const SheetWrapper = styled.div`
  position: relative;
  padding: 6px;
  min-width: 720px;

  z-index: 1;
  border: 1px solid #000;
  border-radius: 10px;
`;
// Header ===================================== //
export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  border: 1px solid #000;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom: none;

  padding-bottom: 20px;
`;

export const LogoContainer = styled.div`
  margin-top: 10px;
  width: 90px;
  height: 90px;
  position: relative;

  img {
    position: absolute;
    left: 20px;
    width: 100%;
    height: 100%;

    display: block;
    object-fit: cover;
  }
`;

export const Watermark = styled.img`
  display: block;
  width: 300px;
  height: 300px;

  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  z-index: -1;
  opacity: 0.2;
`;

export const ContactInfo = styled.div`
  padding-top: 10px;
  padding-right: 8px;
  & > span {
    font-size: 12px;
    font-weight: 300;

    font-style: italic;
  }
`;

export const ExamTitle = styled.div`
  border: 1px solid #000;
  border-top: none;
  border-bottom: none;
  text-align: center;

  padding: 5px;
  font-size: 18px;
  font-weight: 400;
`;

export const SchoolInfoDiv = styled.div`
  margin-top: 15px;
  margin-left: 20px;
  flex: 1;

  h2,
  h3,
  h4,
  p {
    text-align: center;
    margin: 2px auto;
    text-transform: uppercase;
  }

  h2 {
    font-size: 20px;
    font-weight: 600;
  }

  h3 {
    font-size: 16px;
    font-weight: 600;
    text-decoration: underline;
  }

  h4 {
    font-size: 16px;
    font-weight: 500;
  }

  p {
    text-transform: unset;
    font-size: 16px;
    font-weight: 400;
  }
`;

// ============================================ //

// Student Info =============================== //
export const StudentInfo = styled.div`
  padding: 5px;

  border-right: 1px solid #000;
  border-left: 1px solid #000;

  display: flex;
  /*  */
  gap: 20px;

  & > div {
    flex-basis: 50%;
    /* flex: 1; */
    /* display: table; */
  }
`;

export const KeyValue = styled.div`
  display: flex;
  gap: 10px;

  font-size: 14px;
  font-weight: 400;
  & > span {
    display: table-cell;
    &:last-child {
      font-weight: 500;
    }
  }
`;
// ============================================ //

// Main ======================================= //
export const Main = styled.div`
  table {
    width: 100%;
    border-collapse: collapse;
    tbody {
      border: 1px solid #000;
    }
    & > * {
      & > tr {
        /* &:first-child {
          & > td {
            padding-top: 5px;
          }
        } */

        & > th,
        td {
          font-size: 13px;
        }

        & > th {
          padding: 5px;
          border: 1px solid #000;
          font-weight: 500;
        }

        & > td {
          /* padding: 0 5px; */
          padding: 1px 5px;
          border-left: 1px solid #000;
          border-right: 1px solid #000;
          /* border: 1px solid #000; */
          /* border-bottom: none; */
          border-top: none;
          font-weight: 400;
        }
      }
    }
  }
`;

export const EmptyRow = styled.tr`
  & > td {
    padding-top: 60px !important;
    /* border: none !important; */

    :first-child {
      border-left: 1px solid #000 !important;
    }

    :last-child {
      border-right: 1px solid #000 !important;
    }
  }
`;

export const BorderedTableData = styled.td<{ align?: string }>`
  border: 1px solid #000 !important;
  text-align: ${(props) => props.align};
`;

export const TableFooter = styled.div`
  width: 100%;
  border-bottom: 1px solid #000;
`;
// ============================================ //

// Footer ===================================== //
export const Footer = styled.div`
  border: 1px solid #000;
  border-top: none;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;

  padding: 20px 15px 5px 15px;

  table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #000;

    & > * {
      & > tr {
        & > th,
        td {
          font-size: 13px;
        }

        & > th {
          padding: 5px;
          /* padding: 2px 5px; */
          font-weight: 500;
          border-bottom: 1px solid #000;

          &:not(:last-child) {
            border-right: 1px solid #000;
          }
        }

        & > td {
          padding: 1px 5px;
          font-weight: 400;

          &:not(:last-child) {
            border-right: 1px solid #000;
          }
        }
      }
    }
  }
`;

export const FooterTop = styled.div`
  display: flex;
  gap: 10px;

  & > div {
    &:first-child {
      flex: 6;
    }
    &:last-child {
      flex: 4;
    }
  }
`;

export const SchoolSeal = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;

  border: 1px solid #000;
  padding-bottom: 30px;

  & > span {
    display: inline-block;

    &::after {
      content: "";
      display: inline-block;
      width: 200%;
      height: 1px;
      background-color: #000;
      margin-bottom: 10px;

      transform: translateX(-20%);
    }
  }
`;

export const FooterBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  margin: 60px 0 10px 0;
  /* margin: 80px 0 30px 0; */

  span {
    font-size: 12px;
    text-transform: uppercase;
    position: relative;

    &::before {
      content: "";
      display: block;
      width: calc(100% + 60px);
      height: 1px;
      background-color: #000;

      position: absolute;
      top: -2px;
      left: 0;

      transform: translateX(calc(-30px));
    }
  }
`;

export const DaysSection = styled.div`
  display: flex;
  justify-content: space-around;

  border: 1px solid #000;
  padding-bottom: 25px;
  margin-bottom: 10px;

  & > span {
    font-weight: 500;
  }
`;

export const DateSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2px;

  & > p {
    font-size: 14px;
    position: absolute;
    bottom: 10px;
    left: 50%;
    white-space: nowrap;

    transform: translateX(-50%);
  }
`;

export const FooterDate = styled.div`
  font-size: 10px;
  font-weight: 500;
`;
//  =========================================== //
