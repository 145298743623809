import { Grid, Paper, Typography, Button, makeStyles, createStyles, Theme, TableContainer, Checkbox } from '@material-ui/core';
import React, { useState, useEffect } from 'react'
import cx from "clsx";
import { useCheckBoxTable } from '../../Reusable';
import { useTableStyles } from "../../Styles/TableStyles";
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import { Fee_TypeI } from '../../../actions/Fees/FeeType/FeeTypeActionTypes';
import { GetFeeType, GetFeeTypeWithClassFilters, GetFeeTypeWithFilters } from '../../../actions/Fees/FeeType/FeeTypeAction';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { GetScholarshipType } from '../../../actions/ScholarShipType/ScholarshipAction';

interface PropsI {
    onFeeChange: (ids: TableDataI[]) => void;
    selected: Array<string>;
    setSelected: (value: any) => void;
    grade: any;
    section: any;
}
interface TableDataI {
    id: string;
    title: string;
    isMonthly: boolean;
}

interface HeadCellsI {
    id: string;
    label: string;
}

const headCells: HeadCellsI[] = [
    // { id: "index", label: "S.N" },
    { id: "scholarship", label: "Scholarship Title" },
];

const ScholarshipListSelect = (props: PropsI) => {
    const { onFeeChange, selected, setSelected, grade, section } = props;
    const classes = useTableStyles();
    // const [selected, setSelected] = React.useState<string[]>([]);

    const [tableData, setTableData] = useState<TableDataI[]>([]);
    //////////////////////////////////////////////////////////////////

    const dispatch = useDispatch();
    const feeTypeSelector = useSelector((state: RootStore) => state.fee_type);

    const applyFeeAction = useSelector((state: RootStore) => state.fee.actionPerformed);

    const scholarshipState = useSelector(
        (state: RootStore) => state.scholarship.scholarship_type
    );

    useEffect(() => {
        if (applyFeeAction) {
            setSelected([])
        }
    }, [applyFeeAction])

    // React Cycle Hooks
    useEffect(() => {
        // dispatch(GetFeeTypeWithClassFilters(1));
        dispatch(GetScholarshipType());
    }, [])

    useEffect(() => {
        dispatch(GetFeeTypeWithClassFilters(1, grade?.grade, section?.id));
    }, [grade, section])

    console.log('aaaa', grade, section)

    useEffect(() => {
        if (scholarshipState) {
            const data = scholarshipState;
            console.log({ data })
            setTableData(data?.map((item: any) => ({
                id: item.id,
                title: item.scholarship_title,
            })))
        }
    }, [scholarshipState]);

    useEffect(() => {
        const selected_fees = tableData.filter((item: TableDataI) => selected.includes(item.id));
        onFeeChange(selected_fees);
    }, [selected])

    const { StyledTableCell, StyledTableRow, TableContainer } =
        useCheckBoxTable(headCells);

    const handleCheckClick = (name: string) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = tableData.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const isSelected = (id: string) => selected.indexOf(id) !== -1;

    const getCustomTableRow = (row: TableDataI, index: number) => {
        const isItemSelected = isSelected(row.id);
        const labelId = `enhanced-table-checkbox-${index}`;
        const cellStyle = cx(classes.cell, classes.cellSm);
        return (
            <StyledTableRow
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row.id}
                selected={isItemSelected}
            >
                <StyledTableCell className={cellStyle} align="left">
                    <Grid container spacing={1} alignItems="center">
                        <Grid item>
                            <Checkbox
                                color="default"
                                checked={isItemSelected}
                                inputProps={{ "aria-labelledby": labelId }}
                                onClick={() => handleCheckClick(row.id)}
                            />
                        </Grid>
                        <Grid item>
                            {row.title}
                        </Grid>
                    </Grid>
                </StyledTableCell>
                {/* <StyledTableCell className={cellStyle} align="center">
                    {row.title}
                </StyledTableCell> */}
            </StyledTableRow>
        );
    };
    const handleAllClick = () => {
        dispatch(GetFeeType(1));
    }

    const handleOneTimeClick = () => {
        dispatch(GetFeeTypeWithFilters("O"));
    }

    const handleMonthlyClick = () => {
        dispatch(GetFeeTypeWithFilters("M"));
    }
    return (
        <Paper className={classes.rootTableBox} style={{ marginRight: "5px" }}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography className={classes.title}>Apply Scholarship</Typography>
                </Grid>

                {/* <ButtonGroup color="primary" aria-label="outlined primary button group">
                    <Button onClick={() => handleAllClick()}>All</Button>
                    <Button onClick={() => handleOneTimeClick()}>Partial</Button>
                    <Button onClick={() => handleMonthlyClick()}>Complete</Button>
                </ButtonGroup> */}
                <Grid item xs={12}>
                    <div style={{ maxHeight: "calc(100vh - 10vh)", overflowY: "auto" }}>
                        <TableContainer
                            hasCheckbox
                            handleSelectAllClicked={handleSelectAllClick}
                            rowCount={tableData?.length}
                            numSelected={selected?.length}
                            loading={false}
                            items={grade !== null && tableData}
                            headCells={headCells}
                            getCustomTableRow={getCustomTableRow}
                            hiddenCols={[-1]}
                            disablePagination
                            disableSearch
                            disablePrint
                        />
                    </div>
                </Grid>

            </Grid>
        </Paper>
    )
}

export default ScholarshipListSelect;
