export const GET_ADMIN_ENOTES_CATEGORIES_LOADING =
  "GET_ADMIN_ENOTES_CATEGORIES_LOADING";
export const GET_ADMIN_ENOTES_CATEGORIES_ERROR =
  "GET_ADMIN_ENOTES_CATEGORIES_ERROR";
export const GET_ADMIN_ENOTES_CATEGORIES_SUCCESS =
  "GET_ADMIN_ENOTES_CATEGORIES_SUCCESS";

export const GET_ADMIN_ENOTES_LOADING = "GET_ADMIN_ENOTES_LOADING";
export const GET_ADMIN_ENOTES_ERROR = "GET_ADMIN_ENOTES_ERROR";
export const GET_ADMIN_ENOTES_SUCCESS = "GET_ADMIN_ENOTES_SUCCESS";

export type AdminEnotesI = {
  id: number;
  grade: string;
  grade_name: string;
  section: string | null;
  section_name: string | null;
  subject: string;
  subject_name: string;
  category: number;
  category_name: string;
  lesson_plan: number;
  meta_description: string;
  enote_description: string;
  teacher: number;
  teacher_first_name: string;
  teacher_last_name: string;
  reference_links: string[];
  enote_documents: EnotesDocsI[];
};

export type AdminEnotesCategoryI = {
  id: number;
  name: string;
  description: string;
};

interface EnotesDocsI {
  id: number;
  enote: number;
  document: string;
}
//===============================<START>GET Teacher Enotes <START>======================//

export interface GetAdminEnotesLoading {
  type: typeof GET_ADMIN_ENOTES_LOADING;
}

export interface GetAdminEnotesError {
  type: typeof GET_ADMIN_ENOTES_ERROR;
}

export interface GetAdminEnotesSuccess {
  type: typeof GET_ADMIN_ENOTES_SUCCESS;
  payload: AdminEnotesI[];
}

//===============================<END>GET Admin Enotes <END>======================//

//===============================<START>GET Admin Enotes Categories <START>======================//

export interface GetAdminEnotesCategoriesLoading {
  type: typeof GET_ADMIN_ENOTES_CATEGORIES_LOADING;
}

export interface GetAdminEnotesCategoriesError {
  type: typeof GET_ADMIN_ENOTES_CATEGORIES_ERROR;
}

export interface GetAdminEnotesCategoriesSuccess {
  type: typeof GET_ADMIN_ENOTES_CATEGORIES_SUCCESS;
  payload: AdminEnotesCategoryI[];
}

//===============================<END>GET Admin Enotes Categories <END>======================//

export type AdminEnotesCategoriesDispatchTypes =
  | GetAdminEnotesCategoriesLoading
  | GetAdminEnotesCategoriesError
  | GetAdminEnotesCategoriesSuccess
  | GetAdminEnotesLoading
  | GetAdminEnotesError
  | GetAdminEnotesSuccess;
