import { ClassTypeI } from "../Class/ClassActionTypes";
import { SectionTypeI } from "../Section/SectionActionTypes";
import { SubjectTypeI } from "./SubjectActionTypes";

export const GET_SUBJECT_GROUP_LOADING = "GET_SUBJECT_GROUP_LOADING";
export const GET_SUBJECT_GROUP_ERROR = "GET_SUBJECT_GROUP_ERROR";
export const GET_SUBJECT_GROUP_SUCCESS = "GET_SUBJECT_GROUP_SUCCESS";

export const GET_SUBJECT_GROUP_BY_CLASS_LOADING =
  "GET_SUBJECT_GROUP_BY_CLASS_LOADING";
export const GET_SUBJECT_GROUP_BY_CLASS_ERROR =
  "GET_SUBJECT_GROUP_BY_CLASS_ERROR";
export const GET_SUBJECT_GROUP_BY_CLASS_SUCCESS =
  "GET_SUBJECT_GROUP_BY_CLASS_SUCCESS";

export const ADD_SUBJECT_GROUP_LOADING = "ADD_SUBJECT_GROUP_LOADING";
export const ADD_SUBJECT_GROUP_ERROR = "ADD_SUBJECT_GROUP_ERROR";
export const ADD_SUBJECT_GROUP_SUCCESS = "ADD_SUBJECT_GROUP_SUCCESS";

export const UPDATE_SUBJECT_GROUP_LOADING = "UPDATE_SUBJECT_GROUP_LOADING";
export const UPDATE_SUBJECT_GROUP_ERROR = "UPDATE_SUBJECT_GROUP_ERROR";
export const UPDATE_SUBJECT_GROUP_SUCCESS = "UPDATE_SUBJECT_GROUP_SUCCESS";

export const DELETE_SUBJECT_GROUP_LOADING = "DELETE_SUBJECT_GROUP_LOADING";
export const DELETE_SUBJECT_GROUP_ERROR = "DELETE_SUBJECT_GROUP_ERROR";
export const DELETE_SUBJECT_GROUP_SUCCESS = "DELETE_SUBJECT_GROUP_SUCCESS";

export type SubjectGroupTypeI = {
  id: string;
  name: string;
  section: SectionTypeI[];
  grade: string;
  grade_name: string;
  subject: SubjectTypeI[];
  description: string;
  created_by: string;
  general_info_name: string;
  count?: number;
};

//===============================<START>GET SUBJECT GROUP<START>======================//

export interface GetSubjectGroupLoading {
  type: typeof GET_SUBJECT_GROUP_LOADING;
}

export interface GetSubjectGroupError {
  type: typeof GET_SUBJECT_GROUP_ERROR;
}

export interface GetSubjectGroupSuccess {
  type: typeof GET_SUBJECT_GROUP_SUCCESS;
  payload: SubjectGroupTypeI[];
}

//===============================<END>GET SUBJECT GROUP<END>======================//

//===============================<START>GET SUBJECT GROUP BY CLASS<START>======================//

export interface GetSubjectGroupByClassLoading {
  type: typeof GET_SUBJECT_GROUP_BY_CLASS_LOADING;
}

export interface GetSubjectGroupByClassError {
  type: typeof GET_SUBJECT_GROUP_BY_CLASS_ERROR;
}

export interface GetSubjectGroupByClassSuccess {
  type: typeof GET_SUBJECT_GROUP_BY_CLASS_SUCCESS;
  payload: SubjectGroupTypeI[];
}

//===============================<END>GET SUBJECT GROUP BY CLASS<END>======================//

//===============================<START>ADD SUBJECT GROUP<START>======================//

export interface AddSubjectGroupLoading {
  type: typeof ADD_SUBJECT_GROUP_LOADING;
}

export interface AddSubjectGroupError {
  type: typeof ADD_SUBJECT_GROUP_ERROR;
  payload: any;
}

export interface AddSubjectGroupSuccess {
  type: typeof ADD_SUBJECT_GROUP_SUCCESS;
  payload: SubjectGroupTypeI;
}

//===============================<END>ADD SUBJECT GROUP<END>======================//

//===============================<START>UPDATE SUBJECT GROUP<START>======================//

export interface UpdateSubjectGroupLoading {
  type: typeof UPDATE_SUBJECT_GROUP_LOADING;
}

export interface UpdateSubjectGroupError {
  type: typeof UPDATE_SUBJECT_GROUP_ERROR;
  payload: any;
}

export interface UpdateSubjectGroupSuccess {
  type: typeof UPDATE_SUBJECT_GROUP_SUCCESS;
  payload: SubjectGroupTypeI;
}

//===============================<END>UPDATE SUBJECT GROUP<END>======================//

//===============================<START>DELETE SUBJECT GROUP<START>======================//

export interface DeleteSubjectGroupLoading {
  type: typeof DELETE_SUBJECT_GROUP_LOADING;
}

export interface DeleteSubjectGroupError {
  type: typeof DELETE_SUBJECT_GROUP_ERROR;
}

export interface DeleteSubjectGroupSuccess {
  type: typeof DELETE_SUBJECT_GROUP_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE SUBJECT GROUP<END>======================//

export type SubjectGroupDispatchTypes =
  | GetSubjectGroupLoading
  | GetSubjectGroupError
  | GetSubjectGroupSuccess
  | GetSubjectGroupByClassLoading
  | GetSubjectGroupByClassError
  | GetSubjectGroupByClassSuccess
  | AddSubjectGroupLoading
  | AddSubjectGroupError
  | AddSubjectGroupSuccess
  | UpdateSubjectGroupLoading
  | UpdateSubjectGroupError
  | UpdateSubjectGroupSuccess
  | DeleteSubjectGroupLoading
  | DeleteSubjectGroupError
  | DeleteSubjectGroupSuccess;
