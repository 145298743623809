import {
  Avatar,
  Button,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Clear, Done } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { MEETING_CHOICES } from '../../../common/json.constant';
import StudentCard from './StudentCard';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import { GetStudentComplaintById } from '../../../actions/Student/StudentComplaint/StudentCompaintAction';
import checkImageUrl from '../../utils/checkImageUrl';
import getFullName from '../../utils/getFullName';
import { PRIMARY_DATE_FORMAT } from '../../../common/constant';
import { dateConverterAdToBs } from '../../utils/dateConverter';
import moment from 'moment';

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
  profileImg: {
    width: '60px',
    height: '60px',
    alignItems: 'center',
    margin: '5px 20px',
    border: '2px solid #5767a6',
  },
  title: {
    marginTop: '5px',
    color: 'rgba(0,0,0,0.75)',
    fontWeight: 500,
    fontSize: '20px',
  },
  content: {
    maxWidth: '250px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0.2rem 1.5rem 0.2rem 0rem',
  },
  contentTitle: {
    color: 'gray',
  },
});

const ComplainView = (props: any) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { viewData } = props;

  const singleComplain = useSelector(
    (state: RootStore) => state.student_complaint.student_complaint_student
  );

  console.log(singleComplain);

  useEffect(() => {
    if (props.viewData != null) {
      dispatch(GetStudentComplaintById(props.viewData.id));
    }
  }, [props.viewData]);

  return (
    <>
      <div className={classes.root}>
        <Grid
          container
          spacing={3}
          style={{ padding: '1rem 0' }}
          justifyContent="space-between"
        >
          <Grid item xs={3} style={{ padding: 0 }}>
            <Grid container direction="column" alignItems="center">
              <Grid item>
                <Avatar
                  className={classes.profileImg}
                  alt={props.viewData?.student_info?.first_name}
                  src={checkImageUrl(
                    props.viewData?.student_info?.student_photo
                  )}
                />
              </Grid>
              <Grid>
                <Typography
                  className={classes.title}
                  align="center"
                  gutterBottom
                  variant="h4"
                  component="h3"
                >
                  {getFullName(
                    viewData?.student_info?.first_name,
                    viewData?.student_info?.last_name
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <div className={classes.content}>
              <Typography
                variant="body2"
                className="contentTitle"
                component="p"
              >
                Class:
              </Typography>
              <Typography variant="body2" component="p">
                {viewData?.student_info?.grade +
                  ' (' +
                  viewData?.student_info?.section +
                  ')'}
              </Typography>
            </div>
            <div className={classes.content}>
              <Typography
                variant="body2"
                className="contentTitle"
                component="p"
              >
                Roll No.:
              </Typography>
              <Typography variant="body2" component="p">
                {viewData?.student_info?.roll_number}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className={classes.content}>
              <Typography
                variant="body2"
                className="contentTitle"
                component="p"
              >
                Guardian:
              </Typography>
              <Typography variant="body2" component="p">
                {singleComplain?.guardian}
              </Typography>
            </div>
            {/* <div className={classes.content}>
              <Typography
                variant="body2"
                className="contentTitle"
                component="p"
              >
                Phone No.:
              </Typography>
              <Typography variant="body2" component="p">
                {props.studentData?.guardian_phone_number}
              </Typography>
            </div> */}
          </Grid>
        </Grid>
      </div>
      <Divider style={{ marginBottom: '20px' }} />
      <Grid container style={{ marginBottom: '10px' }}>
        <Grid item xs={4}>
          Feedbacker Name:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {getFullName(
              viewData?.complainer.first_name,
              viewData?.complainer?.last_name
            )}
          </Typography>
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: '10px' }}>
        <Grid item xs={4}>
          Feedback Date:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {dateConverterAdToBs(
              moment(singleComplain?.created_on).format(PRIMARY_DATE_FORMAT)
            )}
            {/* {new Date(singleComplain?.created_on).toNepali()} */}
          </Typography>
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: '10px' }}>
        <Grid item xs={4}>
          Feedback Mode:
        </Grid>
        <Grid item xs={8}>
          {singleComplain?.feedbackMode}
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: '10px' }}>
        <Grid item xs={4}>
          Description:
        </Grid>
        <Grid item xs={8}>
          {singleComplain?.description}
        </Grid>
      </Grid>
    </>
  );
};

export default ComplainView;
