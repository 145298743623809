// ---------------- <START> module import ------------------//
import React, { useState, useEffect } from "react";
import { Dialog, Paper, Typography } from "@material-ui/core";
import { useTable } from "../../Reusable";
import { useTableStyles } from "../../Styles/TableStyles";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import {
  GetDepartments,
  DeleteDepartment,
} from "../../../actions/HumanResource/Department/DepartmentAction";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import { DepartmentTypeI } from "../../../actions/HumanResource/Department/DepartmentActionTypes";
import { HeadCellsI } from "../../../actions";
import {
  ItemDeleteButton,
  ItemEditButton,
  ItemPrintButton,
  ItemViewButton,
} from "../../Reusable/Buttons/TableButton";
import useLoading from "../../../useHook/useLoading";
import {
  GetDaybook,
  GetInvoiceDetail,
} from "../../../actions/Fees/Daybook/DaybookAction";
import getFullName from "../../utils/getFullName";
import getFullClassName from "../../utils/getFullClassName";
import FeeInvoice from "../CollectFees/FeeInvoice";
import { GetGeneralInfoData } from "../../../actions/GeneralSettings/GeneralInfo/GeneralInfoAction";
// ---------------- <END> module import ------------------//

// ---------------- <START> Interface ------------------//

// ---------------- <END> Interface ------------------//

// ---------------- <START> Head Cells ------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N." },
  { id: "issue_date", label: "Issue Date" },
  { id: "invoice_no", label: "Receipt No" },
  { id: "student_name", label: "Student Name" },
  { id: "class", label: "Class" },
  { id: "adjusted_date", label: "Adjusted Date" },
  { id: "adjusted_amount", label: "Adjusted Amount" },
];
// ---------------- <START> Head Cells ------------------//

// ---------------- <START> Department Table Component ------------------//
const VoidReportTable = () => {
  const classes = useTableStyles();
  const dispatch = useDispatch();

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  const [tableData, setTableData] = useState<DepartmentTypeI[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);
  const departmentState = useSelector((state: RootStore) => state.department);
  const dayBookState = useSelector((state: RootStore) => state.day_book);
  const [openInvoice, setOpenInvoice] = useState(false);

  const generalInfoData = useSelector(
    (state: RootStore) => state.general_info?.general_info
  );
  const authData = useSelector((state: RootStore) => state.auth);

  useEffect(() => {
    // dispatch(GetDaybook(1));
  }, []);

  useEffect(() => {
    if (itemId !== null) {
      dispatch(GetInvoiceDetail(itemId));
    }
  }, [itemId]);

  // useEffect(() => {
  //     if (authData.general_info != null) {
  //         dispatch(GetGeneralInfoData(authData.general_info.id));
  //     }
  // }, [authData]);

  useEffect(() => {
    if (dayBookState?.day_books !== []) {
      setTableData(dayBookState?.day_books);
    }
  }, [dayBookState]);

  const handleCloseInvoice = () => {
    setOpenInvoice(false);
  };

  const handleViewModal = (id: string) => {
    setItemId(id);
    setOpenInvoice(true);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteDepartment(itemId));
    setDeleteModalOpen(false);
  };

  const getCustomTableRow = (item: any, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.issued_date}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.receipt_id}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {getFullName(
            item.student?.student?.student_user?.first_name,
            item.student?.student?.student_user?.last_name
          )}
        </StyledTableCell>{" "}
        <StyledTableCell align="center" className={classes.cell}>
          {getFullClassName(
            item.student?.grade?.name,
            item.student?.section?.name
          )}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.issued_date}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {"Rs. " + item.total_amount_to_pay}
        </StyledTableCell>
        {/* <StyledTableCell align="center" className={classes.cell}>
                    {'Rs. ' + item.discount}
                </StyledTableCell> */}
      </StyledTableRow>
    );
  };

  return (
    <div style={{ padding: "15px" }}>
      <Typography variant="h6">Adjusted Invoice List</Typography>
      <TableContainer
        label="Invoice"
        loading={departmentState.loading}
        items={tableData}
        headCells={headCells}
        getCustomTableRow={getCustomTableRow}
      />
      <Dialog
        open={openInvoice}
        onClose={handleCloseInvoice}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
        // className={classes.modalBox}
      >
        <FeeInvoice
          generalInfoData={generalInfoData}
          student=""
          invoiceID={itemId}
        />
      </Dialog>
    </div>
  );
};
// ---------------- <START> Department Table Component ------------------//

export default VoidReportTable;
