import { Grid, InputLabel, makeStyles } from '@material-ui/core';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import { useFormStyles } from '../../Styles/FormStyles';
import { useDispatch, useSelector } from 'react-redux';
import { GetClasses } from '../../../actions/Academics/Class/ClassAction';
import { ClassTypeI } from '../../../actions/Academics/Class/ClassActionTypes';
import { RootStore } from '../../../store';
import { SectionTypeI } from '../../../actions/Academics/Section/SectionActionTypes';
import { StudentComplaintTypeI } from '../../../actions/Student/StudentComplaint/StudentComplaintActionTypes';
import {
  AddStudentComplaint,
  GetStudentComplaint,
  UpdateStudentComplaint,
} from '../../../actions/Student/StudentComplaint/StudentCompaintAction';
import { StudentSearch } from '../../../actions/Student/Student/StudentAction';
import { checkedIcon, icon } from '../../Styles/ReusableStyles';
import FormLayout from '../../Reusable/Layouts/Form.Layout';
import CustomizedTextField from '../../Reusable/Inputs/TextField';
import CustomizedSelect from '../../Reusable/Inputs/Select';
import { CusRadioGroup } from '../../Reusable/Inputs/Radio';
import getFullName from '../../utils/getFullName';
import { GetTeachingClass } from '../../../actions/TeacherDash/TeachingClass/TeachingClassAction';
import TeacherClassReducer from '../../../reducers/TeacherDash/TeacherClass/TeacherClassReducer';
import { GetTeacherClassSection } from '../../../actions/TeacherDash/TeacherClass/TeacherClassAction';
import { TeachingClassTypeI } from '../../../actions/TeacherDash/TeachingClass/TeachingClassActionTypes';

// -----------------<start> Styling <start>-----------------------//

// -----------------<end> Styling <end>-----------------------//

// -----------------<start> Interfaces <start>-----------------------//

// -----------------<end> Interfaces <end>-----------------------//

// -----------------<start> States <start>-----------------------//

// -----------------<end> States <end>-----------------------//
interface PropsI {
  editData: StudentComplaintTypeI | null;
  onEditMode: (value: boolean) => void;
}

interface FormI {
  student: string | null;
  class: string | null;
  feedbackMode: string | null;
  description: string;
}

interface FeedbackModeTypeI {
  id: number;
  type: string;
}

const FEEDBACK_MODE = [
  {
    id: 1,
    value: 'Positive',
  },
  {
    id: 2,
    value: 'Neutral',
  },
  {
    id: 3,
    value: 'Negative',
  },
];

const StudentComplaintForm = (props: PropsI) => {
  const { editData, onEditMode } = props;
  const classes = useFormStyles();

  const { register, handleSubmit, errors, setValue, reset } = useForm<FormI>({
    mode: 'onChange',
  });
  //State declaration
  const [editID, setEditID] = useState<string | null>(null);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  // const [studentLoading, setStudentLoading] = useState<boolean>(false);

  const [selectedstudent, setSelectedStudent] = useState<any>(null);
  const [studentChoices, setStudentChoices] = useState<any[]>([]);

  const [classChoices, setClassChoices] = useState<ClassTypeI[]>([]);
  const [selectedClass, setSelectedClass] = useState<ClassTypeI | null>(null);

  const [sectionList, setSectionList] = useState<SectionTypeI[]>([]);
  const [selectedRadioValue, setSelectedRadioValue] = useState<string | null>(
    null
  );
  const [filteredStudent, setFilteredStudent] = useState<any>(null);
  const [editInfo, setEditInfo] = useState<StudentComplaintTypeI | null>(null);
  const [feedbackMode, setFeedbackMode] = useState<FeedbackModeTypeI | null>(
    null
  );
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);

  //============================== <START> REDUX HOOK <START> ===========================//
  const dispatch = useDispatch();
  const classSelector = useSelector((state: RootStore) => state.class);
  const studentSelector = useSelector((state: RootStore) => state.student);
  const feedbackLoading = useSelector(
    (state: RootStore) => state.student_complaint.loading
  );
  const add_or_update = useSelector(
    (state: RootStore) => state.student_complaint.add_or_update
  );

  useEffect(() => {
    if (add_or_update) {
      setIsBtnLoading(false);
      handleReset();
    }
  }, [add_or_update]);

  useEffect(() => {
    dispatch(GetClasses());
    dispatch(GetStudentComplaint());
  }, []);

  useEffect(() => {
    if (selectedClass?.grade && selectedRadioValue != null) {
      dispatch(StudentSearch(selectedClass?.grade, selectedRadioValue));
    } else if (selectedClass?.grade) {
      dispatch(StudentSearch(selectedClass.grade));
    }
  }, [selectedRadioValue, selectedClass?.grade]);

  useEffect(() => {
    setLoading(feedbackLoading);
  }, [feedbackLoading]);

  useEffect(() => {
    if (editData != null) {
      setEditInfo(editData);
    }
  }, [editData]);

  useEffect(() => {
    if (editInfo != null) {
      handleEditTableData(editInfo);
    }
  }, [editInfo, studentSelector?.searched_student]);

  useEffect(() => {
    const student = studentSelector?.searched_student;
    setStudentChoices(student);
  }, [studentSelector]);

  const STUDENT_CHOICES: any = [];
  console.log('studentSelector', studentSelector);

  // const STUDENT_CHOICES = studentSelector?.searched_student?.map((item: any) => ({
  //   id: item.id,
  //   value: getFullName(item.student_first_name, item.student_last_name),
  //   student: item.id,
  //   academic_id: item.academic_id,
  // }));

  const sectionTypeList = sectionList?.map(
    (element: SectionTypeI, index: number) => ({
      id: element.id,
      name: element.name,
    })
  );

  const handleSectionChange = (val: any) => {
    if (val != null) {
      setSelectedRadioValue(val);
    }
  };

  const CLASS_CHOICES = classSelector?.classes?.map((item: any) => ({
    id: item.id.toString(),
    value: item.grade_name,
    section: item.section,
    grade: item.grade,
    grade_name: item.grade_name,
    created_by: item.created_by,
    general_info_name: item.general_info_name,
  }));

  //============================== <END> REDUX HOOK <END> ===========================//

  //============================== <START> EVENT HANDLERS <START> ===========================//

  const handleClassChange = (value: ClassTypeI | null) => {
    value != null ? populateSectionRadio(value.section) : setSectionList([]);
    setSelectedClass(value);
  };

  const populateSectionRadio = (value: SectionTypeI[]) => {
    value != null ? setSectionList(value) : setSectionList([]);
    setSelectedRadioValue(null);
  };

  const handleStudentChange = (value: StudentComplaintTypeI | null) => {
    setSelectedStudent(value);
  };

  const handleFeedbackModeChange = (value: FeedbackModeTypeI | null) => {
    setFeedbackMode(value);
  };

  const onSubmit = (data: FormI) => {
    setIsBtnLoading(true);
    if (selectedstudent != null && selectedClass != null) {
      // const makeSubmission = (callback: () => void) => {
      const studentComplaintData = {
        grade: selectedClass.grade,
        section: selectedRadioValue,
        student: selectedstudent.academic_id,
        // TODO: Add feedback to dispatch data
        // feedbackMode: data?.feedbackMode,
        description: data.description,
      };
      console.log({ studentComplaintData });
      if (editMode) {
        editID != null &&
          dispatch(UpdateStudentComplaint(editID, studentComplaintData));
      } else {
        dispatch(AddStudentComplaint(studentComplaintData));
      }
    }
  };

  const handleEditTableData = (data: StudentComplaintTypeI) => {
    setEditMode(true);
    setEditID(data.id);

    const new_class = CLASS_CHOICES.find(
      (element) => element.grade_name === data?.student_info?.grade
    );

    const new_section = new_class?.section?.find(
      (elem: SectionTypeI) => elem.name === data?.student_info?.section
    );

    const new_student = STUDENT_CHOICES?.find(
      (element: any) => element.academic_id === data?.student
    );

    new_student && setSelectedStudent(new_student);
    new_class && setSelectedClass(new_class);
    new_class && setSectionList(new_class.section);
    handleSectionChange(new_section.id);
    setValue('description', data.description);
  };

  const handleReset = () => {
    setEditMode(false);
    setEditID(null);
    setSelectedStudent(null);
    setSelectedClass(null);
    setSelectedRadioValue(null);
    setSectionList([]);
    setFeedbackMode(null);
    reset();
    onEditMode(false);
    setEditInfo(null);
  };

  //validation and error handling
  const studentComplaintState = useSelector(
    (state: RootStore) => state.student_complaint
  );

  useEffect(() => {
    studentComplaintState?.recent && handleReset();
  }, [studentComplaintState?.recent]);

  return (
    <FormLayout
      title={editMode ? 'Edit Feedback' : 'Add Feedback'}
      onSubmit={handleSubmit(onSubmit)}
      editMode={editMode}
      loading={loading}
      onClick={!isBtnLoading && handleReset}
      add_or_update={isBtnLoading}
    >
      <Grid item xs={12} className={classes.formWrapper}>
        <CustomizedSelect
          label="Select Class"
          required
          name="class"
          options={CLASS_CHOICES}
          value={selectedClass}
          setValue={handleClassChange}
          error={errors['class'] ? 'Please select Class' : ''}
          inputRef={register({ required: true })}
        />
      </Grid>
      <Grid item xs={12} className={classes.formWrapper}>
        <InputLabel>
          Section
          {/* <span style={{ color: "red" }}>*</span> */}
        </InputLabel>
        {sectionList && sectionList.length ? (
          <>
            <CusRadioGroup
              items={sectionTypeList}
              value={selectedRadioValue}
              setValue={handleSectionChange}
              // onValue={true}
            />
          </>
        ) : (
          <>
            <span className={classes.sectionUnavailableText}>
              ** No records found
            </span>
          </>
        )}
      </Grid>
      <Grid item xs={12} className={classes.formWrapper}>
        <CustomizedSelect
          label="Select Student"
          name="student"
          options={STUDENT_CHOICES}
          value={selectedstudent}
          setValue={handleStudentChange}
          required
          error={errors['student'] ? 'Student must be selected' : ''}
          inputRef={register({ required: true })}
        />
      </Grid>
      <Grid item xs={12} className={classes.formWrapper}>
        <CustomizedSelect
          label="Feedback Mode"
          name="feedbackMode"
          options={FEEDBACK_MODE}
          value={feedbackMode}
          setValue={handleFeedbackModeChange}
          required
          error={errors['feedbackMode'] ? 'Feedback Mode must be selected' : ''}
          inputRef={register({ required: true })}
        />
      </Grid>
      <Grid item xs={12} className={classes.formWrapper}>
        <CustomizedTextField
          label="Description"
          placeholder="description"
          name="description"
          multiline
          rows={4}
          required
          error={errors['description'] ? 'Required Field.' : ''}
          inputRef={register({ required: true })}
        />
      </Grid>
    </FormLayout>
  );
};

export default StudentComplaintForm;
