import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getGradeSubjects } from "../subjectGrade/gradeSubjectApi";
import { initialStateI, OptSubjectGroupI, OptSubjectI } from "./optSubject";
import {
  createOptSubjectGroup,
  getOptSubjectGroups,
  updateOptSubjectGroup,
} from "./optSubjectApi";

const initialState: initialStateI = {
  loading: false,
  actionPerformed: false,
  optSubjectGroups: [],
  gradeOptSubjects: [],
  optSubjectGroup: null,
  editMode: false,
};

const OptSubjectSlice = createSlice({
  name: "optSubject",
  initialState,
  reducers: {
    handleEditOptSubjectGroup: (
      state,
      { payload }: PayloadAction<OptSubjectGroupI>
    ) => {
      state.editMode = true;
      state.optSubjectGroup = payload;
    },
    resetFormState: (state) => {
      state.loading = false;
      state.gradeOptSubjects = [];
      state.optSubjectGroup = null;
      state.editMode = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOptSubjectGroups.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(
      getOptSubjectGroups.fulfilled,
      (state, { payload }: PayloadAction<OptSubjectGroupI[]>) => {
        state.loading = false;
        state.optSubjectGroups = payload;
      }
    );
    builder.addCase(getOptSubjectGroups.rejected, (state) => {
      state.loading = false;
      state.optSubjectGroups = [];
    });

    builder.addCase(getGradeSubjects.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getGradeSubjects.fulfilled,
      (
        state,
        {
          payload,
        }: PayloadAction<
          { id: string; name: string; subject_info: OptSubjectI | null }[]
        >
      ) => {
        const data = payload.filter(
          (el) =>
            el.subject_info !== null && el.subject_info.subject_type === "Opt"
        ) as {
          id: string;
          name: string;
          subject_info: OptSubjectI;
        }[];

        state.gradeOptSubjects = data.map((el) => el.subject_info);
        state.loading = false;
      }
    );
    builder.addCase(getGradeSubjects.rejected, (state) => {
      state.loading = false;
      state.gradeOptSubjects = [];
    });

    builder.addCase(createOptSubjectGroup.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(createOptSubjectGroup.fulfilled, (state) => {
      state.loading = false;
      state.actionPerformed = true;
    });
    builder.addCase(createOptSubjectGroup.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateOptSubjectGroup.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(updateOptSubjectGroup.fulfilled, (state) => {
      state.loading = false;
      state.actionPerformed = true;
      state.editMode = false;
      state.optSubjectGroup = null;
    });
    builder.addCase(updateOptSubjectGroup.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { handleEditOptSubjectGroup, resetFormState } =
  OptSubjectSlice.actions;

export default OptSubjectSlice.reducer;
