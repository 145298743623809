import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "@material-ui/core";
import styled from "styled-components";
import ReactToPrint from "react-to-print";
import { MarksLedgerI } from "../../../rtk/features/exam/marksLedger/marksLedger";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import MarkSheetSingle from "./MarksheetSingle";
import { GetGrades } from "../../../actions/Examination/Grade/GradeAction";
import GradeSheetSingleFinal from "./GradeSheetSingleFinal";

const Container = styled.div`
  padding: 20px;
`;

interface GeneralInfoI {
  id: number;
  logo: string;
  school_name: string;
  address: string;
  phone_number: string;
  email: string;
  slogan: string;
}

interface DialogProps {
  open: "gradesheet" | "marksheet" | false;
  onClose: () => void;
  studentId: string[];
}

const PrintPreview = (props: DialogProps) => {
  const dispatch = useDispatch();
  const { onClose, open, studentId } = props;
  const printRef = useRef<HTMLDivElement | null>(null);

  const [marksheets, setMarksheets] = useState<MarksLedgerI[]>([]);

  const { ledger } = useSelector((state: RootStore) => state.marksLedger);
  const { grades } = useSelector((state: RootStore) => state.exam_grade);

  const { general_info } = useSelector(
    (state: RootStore) => state.general_info
  );

  useEffect(() => {
    dispatch(GetGrades());
  }, []);

  useEffect(() => {
    if (ledger) {
      const result = ledger.marksLedger.filter((el) =>
        studentId.includes(el.student_academic__id)
      );
      console.log(result);
      setMarksheets(result);
    }
  }, [studentId]);

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="print-dialog"
      open={open === "gradesheet" || open === "marksheet"}
      maxWidth="lg"
    >
      <Container>
        <ReactToPrint
          trigger={() => (
            <p
              style={{
                backgroundColor: "#132E98",
                color: "white",
                fontWeight: "bold",
                borderRadius: "0px",
                margin: "0px",
                textAlign: "center",
                padding: "5px 0px",
                cursor: "pointer",
              }}
            >
              Print This Out !
            </p>
          )}
          content={() => printRef.current}
          removeAfterPrint={true}
          copyStyles={true}
        />
        <div ref={printRef}>
          {ledger && open === "gradesheet"
            ? marksheets.map((el, index) => (
                <GradeSheetSingleFinal
                  key={index + 1}
                  schoolInfo={{
                    name: general_info.school_name,
                    address: general_info.address,
                    phone: general_info.phone_number,
                    logo: general_info.logo || "",
                  }}
                  gradeInfo={{ grade: ledger.grade_info, totalStudents: 0 }}
                  subjects={ledger.examSubjects}
                  marksheet={el}
                  grades={grades}
                  examName={ledger.examInfo.exam_name}
                />
              ))
            : ledger && open === "marksheet"
            ? marksheets.map((el, index) => (
                <MarkSheetSingle
                  key={index + 1}
                  schoolInfo={{
                    name: general_info.school_name,
                    address: general_info.address,
                    phone: general_info.phone_number,
                    logo: general_info.logo || "",
                  }}
                  gradeInfo={{ grade: "", totalStudents: 0 }}
                  subjects={ledger.examSubjects}
                  marksheet={el}
                  grades={grades}
                  examName={ledger.examInfo.exam_name}
                />
              ))
            : null}
        </div>
      </Container>
    </Dialog>
  );
};

export default PrintPreview;
