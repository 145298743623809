import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_FEE_TYPE_ERROR,
  GET_FEE_TYPE_LOADING,
  GET_FEE_TYPE_SUCCESS,
  GET_ALL_FEE_TYPE_ERROR,
  GET_ALL_FEE_TYPE_LOADING,
  GET_ALL_FEE_TYPE_SUCCESS,
  CREATE_FEE_TYPE_ERROR,
  CREATE_FEE_TYPE_LOADING,
  CREATE_FEE_TYPE_SUCCESS,
  UPDATE_FEE_TYPE_ERROR,
  UPDATE_FEE_TYPE_LOADING,
  UPDATE_FEE_TYPE_SUCCESS,
  DELETE_FEE_TYPE_ERROR,
  DELETE_FEE_TYPE_LOADING,
  DELETE_FEE_TYPE_SUCCESS,
  FeeTypeDispatchTypes,
} from "./FeeTypeActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

interface RequestDataI {
  name: string;
  fee_type: string;
  due_date: string | null;
  due_day: number | null;
  due_period_type: string | null;
  description: string;
  billing_period: Array<string>;
}

export const GetFeeType =
  (id: number) =>
  async (dispatch: Dispatch<FeeTypeDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_FEE_TYPE_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/fee_type/?page=${id}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_FEE_TYPE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_FEE_TYPE_ERROR,
      });
    }
  };

export const GetAllFeeType =
  (id: number) =>
  async (dispatch: Dispatch<FeeTypeDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_FEE_TYPE_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/fee_type/?page=${id}&per_page=100`,
        HeaderConfig()
      );

      dispatch({
        type: GET_FEE_TYPE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_FEE_TYPE_ERROR,
      });
    }
  };

export const GetFeeTypeList =
  () => async (dispatch: Dispatch<FeeTypeDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_ALL_FEE_TYPE_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/fee_type/?per_page=1000`,
        HeaderConfig()
      );

      dispatch({
        type: GET_ALL_FEE_TYPE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_FEE_TYPE_ERROR,
      });
    }
  };

export const GetFeeTypeWithClassFilters =
  (id: number, grade: string, section: string) =>
  async (dispatch: Dispatch<FeeTypeDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_FEE_TYPE_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/fee_type/?page=${id}&grade=${grade}&section=${section}&per_page=100`,
        HeaderConfig()
      );

      dispatch({
        type: GET_FEE_TYPE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_FEE_TYPE_ERROR,
      });
    }
  };

export const GetFeeTypeWithFilters =
  (type: string) =>
  async (dispatch: Dispatch<FeeTypeDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_FEE_TYPE_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/fee_type/?page=1&fee_type=${type}&per_page=100`,
        HeaderConfig()
      );

      dispatch({
        type: GET_FEE_TYPE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_FEE_TYPE_ERROR,
      });
    }
  };

export const CreateFeeType =
  (data: RequestDataI) =>
  async (dispatch: Dispatch<FeeTypeDispatchTypes | SetSnackBarI>) => {
    dispatch({
      type: CREATE_FEE_TYPE_LOADING,
    });

    try {
      const res = await axios.post(
        `${ADMIN_API_URL}/fee_type/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: CREATE_FEE_TYPE_SUCCESS,
        payload: res.data,
      });

      console.log("reeeeeeees", res.data);

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Fee Type Added Successfully",
        },
      });
    } catch (err: any) {
      console.log({ err });
      dispatch({
        type: CREATE_FEE_TYPE_ERROR,
        payload: err.response && err.response?.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Fee Type Add Failed",
        },
      });
    } finally {
      try {
        dispatch({
          type: GET_FEE_TYPE_LOADING,
        });

        const res = await axios.get(
          `${ADMIN_API_URL}/fee_type/?page=1`,
          HeaderConfig()
        );

        dispatch({
          type: GET_FEE_TYPE_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: GET_FEE_TYPE_ERROR,
        });
      }
    }
  };

export const UpdateFeeType =
  (id: string, data: RequestDataI) =>
  async (dispatch: Dispatch<FeeTypeDispatchTypes | SetSnackBarI>) => {
    dispatch({
      type: UPDATE_FEE_TYPE_LOADING,
    });

    try {
      const res = await axios.put(
        `${ADMIN_API_URL}/fee_type/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_FEE_TYPE_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Fee Type Updated Successfully",
        },
      });
    } catch (err: any) {
      console.log({ err });
      dispatch({
        type: UPDATE_FEE_TYPE_ERROR,
        payload: err.response && err.response?.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Fee Type Update Failed",
        },
      });
    } finally {
      try {
        dispatch({
          type: GET_FEE_TYPE_LOADING,
        });

        const res = await axios.get(
          `${ADMIN_API_URL}/fee_type/?page=1`,
          HeaderConfig()
        );

        dispatch({
          type: GET_FEE_TYPE_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: GET_FEE_TYPE_ERROR,
        });
      }
    }
  };

export const DeleteFeeType =
  (id: string) =>
  async (dispatch: Dispatch<FeeTypeDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_FEE_TYPE_LOADING,
      });

      const res = await axios.delete(
        `${ADMIN_API_URL}/fee_type/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_FEE_TYPE_SUCCESS,
        payload: { id: id },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Fee Type Deleted Successfully",
        },
      });
    } catch (error) {
      console.log({ error });
      dispatch({
        type: DELETE_FEE_TYPE_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Fee Type Delete Failed",
        },
      });
    }
  };
