import { createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";
import { setSnackbar } from "../../../../actions/SnackbarAction";
import { ADMIN_API_URL } from "../../../../config/SERVER_API_URLS";

export const getOptSubjectStudent = createAsyncThunk(
  "getOptSubjectStudent",
  async (
    { grade, section }: { grade: string; section: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.get(
        `${ADMIN_API_URL}/student_optional_subjects/?grade=${grade}&section=${section}`
      );
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(null);
    }
  }
);

export const postOptSubjectStudent = createAsyncThunk(
  "postOptSubjectStudent",
  async (
    { grade, section, data }: { grade: string; section: string; data: any[] },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.post(
        `${ADMIN_API_URL}/student_optional_subjects/?grade=${grade}&section=${section}`,
        data
      );
      dispatch(setSnackbar(true, "success", "Subjects Updated Successfully"));
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      dispatch(setSnackbar(true, "error", "Failed to update subjects"));
      return rejectWithValue(null);
    }
  }
);
