// ---------------------------- <START> module imports start ----------------------------------//
import React, { useState, useEffect } from "react";
import { Avatar, CardHeader, Grid, Paper, TableBody, TableHead, Typography } from "@material-ui/core";
import { useTableStyles } from "../../Styles/TableStyles";
import { useTable } from "../../Reusable";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import { FineTypeI } from "../../../actions/Fees/FineType/FineActionTypes";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import { DeleteFine, GetFine } from "../../../actions/Fees/FineType/FineAction";
import getMonthName from "../../utils/getMonthName";
import { AnyAction } from "redux";
import { useParams } from "react-router";
import { GetInvoiceHistory } from "../../../actions/Fees/CollectFees/CollectFeesAction";
import SubModuleLayout from "../../Reusable/Layouts/SubModuleLayout";
import { GetStudentByID } from "../../../actions/Student/Student/StudentAction";
import ScholarshipSidebar from "../ScholarshipSidebar";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Redirect } from "react-router";

// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//
interface PropsI {
    onEditData: (value: FineTypeI) => void;
    editCheck: { check: boolean; count: number };
}

interface HeadCellsI {
    id: string;
    label: string;
}
// ---------------------------- <END> interface ends ----------------------------------//

// ---------------------------- <START> styling starts ----------------------------------//

// ---------------------------- <END> styling ends ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
    { id: "sn", label: "S.N." },
    { id: "date", label: "Date" },
    { id: "transaction_type", label: "Transaction Type" },
    { id: "fee_head", label: "Fees Head" },
    { id: "period", label: "Period" },
    { id: "charges", label: "Charges" },
    { id: "fine", label: "Fine" },
    { id: "balance", label: "Balance" },
    { id: "status", label: "Status" },

];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> fine Type Table Component Starts ---------------------------//
const ScholarshipReportDetail = (props: any) => {

    // const { statementTableData, feesHead } = props;

    const classes = useTableStyles();
    // payment method reducer
    const fineTypeState = useSelector((state: RootStore) => state.fine_type);
    const { scholarshipID } = useParams<{ scholarshipID: string }>();

    // Modal States
    const [loading, setLoading] = useState<boolean>(false);
    const [backClicked, setBackClicked] = useState<boolean>(false);

    const [editMode, setEditMode] = useState(false);
    const [tableData, setTableData] = useState<FineTypeI[]>([]);
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [itemId, setItemId] = useState<string>("");
    const [totalPaid, setTotalPaid] = useState<number>(0)
    const [statementTableData, setStatementTableData] = useState<any>([]);

    const dispatch = useDispatch();

    const feesHead = useSelector(
        (state: RootStore) => state.collect_fees?.collect_fees
    );
    const studentState = useSelector((state: RootStore) => state.student?.student_by_id);


    const nav = <ScholarshipSidebar scholarship_report />;


    console.log({ studentState })

    useEffect(() => {
        if (scholarshipID != null) {
            dispatch(GetInvoiceHistory(scholarshipID))
        }
    }, [scholarshipID]);

    useEffect(() => {
        const secondaryData: any[] = []

        if (feesHead?.paid_invoices !== null) {
            for (let i = 0; i < feesHead?.paid_invoices.length; i++) {
                secondaryData.push({
                    date: '2022-01-20',
                    transaction_type: 'Invoice',
                    fee_head: feesHead?.paid_invoices[i].invoice__month && feesHead?.paid_invoices[i].invoice__month !== "" ? feesHead?.paid_invoices[i].invoice__fee_type__name + " - " + getMonthName(feesHead?.paid_invoices[i].invoice__month) : feesHead?.paid_invoices[i].invoice__fee_type__name,
                    period: feesHead?.paid_invoices[i].invoice__month ? getMonthName(feesHead?.paid_invoices[i].invoice__month) : null,
                    charges: feesHead?.paid_invoices[i].paid_amount,
                    fine: null,
                    balance: feesHead?.paid_invoices[i].paid_amount,
                    status: 'Paid'
                })
            }
            setStatementTableData(secondaryData)
        }
    }, [feesHead])

    useEffect(() => {
        if (statementTableData !== null) {
            const paids = statementTableData.filter((elem: any) => elem.status === 'Paid')
            let count = 0
            paids?.map((elem: any) => { count += Number(elem.balance) });
            setTotalPaid(count)
        }
    }, [statementTableData])

    useEffect(() => {
        const dataLength = fineTypeState.fine.length;

        setLoading(fineTypeState.loading);
        // setTableData(fineTypeState.fine);
    }, [fineTypeState]);

    // States declaration

    // -----------------<END> Pagination states and functions <END>-----------------------//

    // Retrieving re-usable components from useTable
    const {
        TblContainer,
        TblHead,
        StyledTableCell,
        StyledTableRow,
        exportToExcel,
        tablePrint,
    } = useTable(headCells);

    // Function for handling action icons click event

    // const handleEditClicked = (data: FineTypeI) => {
    //     onEditData(data);
    //     setEditMode(true);
    // };
    const handleModalClose = (value: boolean) => {
        setDeleteModalOpen(value);
    };

    const handleDeleteModal = (value: boolean, id: string) => {
        setItemId(id);
        setDeleteModalOpen(value);
    };

    const onDeleteConfirmed = (confirmed: boolean) => {
        confirmed && itemId != null && dispatch(DeleteFine(itemId));
        setDeleteModalOpen(false);
    };

    console.log({ studentState })

    const handleBack = () => {
        setBackClicked(true)
    }

    const getCustomTableRow = (item: any, index: number) => {
        return (
            <>
                <StyledTableRow key={item.id}>
                    <StyledTableCell align="center" className={classes.cell}>
                        {index}
                    </StyledTableCell>
                    <StyledTableCell align="center" className={classes.cell}>
                        {item.date}
                    </StyledTableCell>
                    <StyledTableCell align="center" className={classes.cell}>
                        {item.transaction_type}
                    </StyledTableCell>
                    <StyledTableCell align="center" className={classes.cell}>
                        {item.fee_head}
                    </StyledTableCell>
                    <StyledTableCell align="center" className={classes.cell}>
                        {item.period}
                    </StyledTableCell>
                    <StyledTableCell align="center" className={classes.cell}>
                        {item.charges}
                    </StyledTableCell>
                    <StyledTableCell align="center" className={classes.cell}>
                        {item.fine}
                    </StyledTableCell>
                    <StyledTableCell align="center" className={classes.cell}>
                        {item.balance}
                    </StyledTableCell>
                    <StyledTableCell align="center" className={classes.cell}>
                        <b style={{ color: item.status === 'Paid' ? 'green' : 'red' }}>{item.status}</b>
                    </StyledTableCell>
                </StyledTableRow>
            </>
        );
    };

    if (backClicked) {
        return <Redirect to={`/scholarship-report/`} />;
    }

    return (
        <SubModuleLayout sideNav={nav}>
            <Paper style={{ margin: "10px" }}>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Typography style={{ paddingLeft: '15px', paddingTop: '20px' }} variant="h4">
                            <b>
                                <a href="#"><ArrowBackIosIcon style={{ paddingTop: '10px' }} onClick={() => handleBack()} /></a>
                                <span>Scholarship Report Details</span>
                            </b>
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                    </Grid>
                </Grid>
                <div style={{ padding: "0px 15px" }}>
                    <div style={{ paddingBottom: '40px', maxHeight: "calc(100vh - 60vh)", overflowY: "auto" }}>
                        <TblContainer size="small">
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell
                                        align="center"
                                        className={classes.cell}
                                    >
                                        S.N.
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="left"
                                        className={classes.cell}
                                    >
                                        Student Name
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="left"
                                        className={classes.cell}
                                    >
                                        Scholarship Head
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="left"
                                        className={classes.cell}
                                    >
                                        Fee Before Apply
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="left"
                                        className={classes.cell}
                                    >
                                        Discount
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="left"
                                        className={classes.cell}
                                    >
                                        Fee After Apply
                                    </StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {statementTableData.map((item: any, index: number) => (
                                    <StyledTableRow key={item.id}>
                                        <StyledTableCell align="center" className={classes.cell}>
                                            {index + 1}
                                        </StyledTableCell>
                                        <StyledTableCell align="left" className={classes.cell}>
                                            {item.date}
                                        </StyledTableCell>
                                        <StyledTableCell align="left" className={classes.cell}>
                                            {item.transaction_type}
                                        </StyledTableCell>
                                        <StyledTableCell align="left" className={classes.cell}>
                                            {item.fee_head}
                                        </StyledTableCell>
                                        <StyledTableCell align="left" className={classes.cell}>
                                            {item.period}
                                        </StyledTableCell>
                                        <StyledTableCell align="left" className={classes.cell}>
                                            {item.charges}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </TblContainer>
                    </div>
                </div>
            </Paper>
        </SubModuleLayout>
    );
};

export default ScholarshipReportDetail;