import {
  Button,
  Divider,
  Grid,
  Input,
  InputLabel,
  Paper,
  Typography,
  TextField,
  LinearProgress,
} from "@material-ui/core";
import { CloudUpload, Send } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import SubModuleLayout from "../../../Reusable/Layouts/SubModuleLayout";
import StudentSidebar from "../../StudentSidebar/StudentSidebar";
import { useStyles } from "../../../Styles/FormStyles";
import { Autocomplete } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { GetClasses } from "../../../../actions/Academics/Class/ClassAction";
import { RootStore } from "../../../../store";
import { useForm } from "react-hook-form";
import { SendStudentCredentialSms } from "../../../../actions/Student/Student/StudentAction";
import { setSnackbar } from "../../../../actions/SnackbarAction";
import BackDropLoader from "../../../Reusable/BackDropLoader";

interface ExcelI {
  student_file?: File;
}

interface SectionI {
  id: string;
  title: string;
}

interface ClassI {
  id: string;
  title: string;
  sections: SectionI[] | [];
}

function SendStudentCredentials() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const nav = <StudentSidebar studentDetails />;

  const [className, setClassName] = useState<ClassI | null>(null);
  const [section, setSection] = useState<SectionI | null>(null);
  const [sectionChoices, setSectionChoices] = useState<SectionI[] | []>([]);
  const [sectionDisabler, setSectionDisabler] = useState<boolean>(true);

  const { register, handleSubmit, errors, setValue, clearErrors } = useForm();

  useEffect(() => {
    dispatch(GetClasses());
  }, []);

  const classState = useSelector((state: RootStore) => state.class);
  const sessionState = useSelector((state: RootStore) => state.session);
  const stduentInfoUploadState: any = useSelector(
    (state: RootStore) => state.student.student_info_upload
  );
  const loading = useSelector((state: RootStore) => state.student.loading);

  const classChoices: any = classState.classes.map((classItem, index) => ({
    id: classItem.grade,
    title: classItem.grade_name,
    sections: classItem.section.map((sectionItem) => ({
      id: sectionItem.id,
      title: sectionItem.name,
    })),
  }));

  const handleClassChange = (value: ClassI | null) => {
    setClassName(value);
    setSection(null);
    value != null
      ? populateSectionChoices(value.sections)
      : setSectionDisabler(true);
  };

  const handleSectionChange = (value: any | null) => {
    setSection(value);
  };

  const populateSectionChoices = (sections: SectionI[] | []) => {
    setSectionDisabler(false);
    sections.length > 0
      ? setSectionChoices(sections)
      : setSectionDisabler(true);
  };

  const onSubmit = (data: any) => {
    if (className) {
      dispatch(SendStudentCredentialSms(className.id, section?.id));
    }
  };

  return (
    <SubModuleLayout studentMenuActive sideNav={nav}>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <Paper className={classes.pageContent} elevation={3}>
          {stduentInfoUploadState.loading && (
            <LinearProgress style={{ margin: "-24px -24px 16px -24px" }} />
          )}

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">
                Send Student Credentials Via. Sms
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={3} className={classes.formWrapper}>
                  <InputLabel>Select Grade</InputLabel>
                  <Autocomplete
                    onChange={(
                      event: React.ChangeEvent<{}>,
                      value: ClassI | null
                    ) => handleClassChange(value)}
                    options={classChoices}
                    value={className}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Label"
                        name="class_name"
                        variant="outlined"
                        inputRef={register({ required: true })}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={3} className={classes.formWrapper}>
                  <InputLabel>Select Section </InputLabel>
                  <Autocomplete
                    style={{
                      padding: 0,
                    }}
                    value={section}
                    onChange={(
                      event: React.ChangeEvent<{}>,
                      value: SectionI | null
                    ) => handleSectionChange(value)}
                    options={sectionChoices}
                    getOptionLabel={(option) => option.title}
                    disabled={sectionDisabler}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Label"
                        name="section_name"
                        variant="outlined"
                        inputRef={register}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Send />}
                type="submit"
              >
                Send
              </Button>
            </Grid>
          </Grid>
          <label htmlFor="contained-button-file"></label>
        </Paper>
      </form>
      <BackDropLoader open={loading} />
    </SubModuleLayout>
  );
}
export default SendStudentCredentials;
