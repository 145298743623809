import React from "react";
import { HRsidebar } from "../../../components";
import AttendanceToday from "../../../components/HumanResource/Attendance/AttendanceToday";
import SubModuleLayout from "../../../components/Reusable/Layouts/SubModuleLayout";

const AttendanceTodayPage = () => {
  const nav = <HRsidebar attendance />;
  return (
    <SubModuleLayout HumanMenuActive sideNav={nav}>
      <AttendanceToday />
    </SubModuleLayout>
  );
};

export default AttendanceTodayPage;
