import React from 'react';
import styled from 'styled-components';

const OutterButton = styled.button`
    width: 100%;
    padding: 1.25em 1em;
    margin-top: 1em;
    background-color: #fff;
    color: #000;
    border: 1px solid #ddd;
    outline: none;
`

const InnerButton = styled.button`
    width: max-content;
    padding: 4px 12px;
    margin-left: 1em;
    background-color: ${props => props.color};
    color: #fff;
    border: none;
    border-radius: 1.5em;
    outline: none;
`

const ProgressButton = (props: any) => {
    const color = props.data.status === "Very Good" ? "#3fc581" : props.data.status === "Good" ? "#39b375" : "red"
    return (
        <OutterButton>{props.data.title} - {props.data.value}<InnerButton color={color}>{props.data.status}</InnerButton></OutterButton>
    )
}

export default ProgressButton