import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  LinearProgress,
  Paper,
} from "@material-ui/core";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useCardStyles } from "../../Styles/CardStyles";
import AddNewButton from "../../Reusable/Buttons/AddNewButton";
import { Add, DeleteForeverOutlined } from "@material-ui/icons";
import ActionButton from "../../Reusable/Buttons/ActionButton";
import CustomizedPopUp from "../../Reusable/Dialogs/CustomizedPopUp";
import SelectDepartmentsModal from "./SelectDepartmentsModal";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import { GetDepartments } from "../../../actions/HumanResource/Department/DepartmentAction";
import { getSyncedDepartments } from "../../../rtk/features/attendanceDevice/attendanceDeviceThunk";
import { useParams } from "react-router-dom";

const HeadSection = styled.div`
  text-align: right;
  margin-bottom: 8px;
`;

const TableCap = styled.div`
  max-height: 50vh;
`;

const NoDataCell = styled.td`
  padding: 4rem 0;
  background-color: #f2f2f2;
  text-align: center;
`;

const DeleteIcon = styled(DeleteForeverOutlined)`
  && {
    color: #e96c5a;
  }
`;

export default function DepartmentTab() {
  const { deviceId, configId } = useParams<{
    deviceId: string;
    configId: string;
  }>();
  const classes = useCardStyles();
  const dispatch = useDispatch();

  const [departmentModal, toggleDepartmentModal] = useState<boolean>(false);
  const [confirmModal, toggleConfirmModal] = useState<boolean>(false);

  const { loading, syncedDepartments } = useSelector(
    (state: RootStore) => state.attendanceDevice
  );

  useEffect(() => {
    dispatch(GetDepartments());
    dispatch(getSyncedDepartments(configId));
  }, []);

  const handleRemoveClick = (id: string) => {
    toggleConfirmModal(true);
  };

  const onDeleteConfirmed = () => {
    //
  };

  return (
    <>
      <Paper
        className={classes.infobar}
        elevation={0}
        style={{ paddingTop: "0" }}
      >
        <HeadSection>
          <AddNewButton
            title="Add Departments to Device"
            type="button"
            color="primary"
            variant="outlined"
            startIcon={<Add />}
            onClick={() => {
              toggleDepartmentModal(true);
            }}
          >
            Add Departments
          </AddNewButton>
        </HeadSection>
        <TableContainer className={classes.roota}>
          <TableCap>
            <Table
              size="small"
              className={classes.table}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>S.N</TableCell>
                  <TableCell>Department Name</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {syncedDepartments.length ? (
                  syncedDepartments.map((row, index) => (
                    <TableRow key={index + 1}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>
                        {row.department_device_id__device_id !== null
                          ? "Synced"
                          : "Not Synced"}
                      </TableCell>
                      <TableCell>
                        <ActionButton
                          title="Remove Department"
                          onClick={() => handleRemoveClick(String(row.id))}
                        >
                          <DeleteIcon />
                        </ActionButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <NoDataCell colSpan={5}>
                      {loading ? "Loading..." : "No Deparments added."}
                    </NoDataCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableCap>
        </TableContainer>
      </Paper>
      <CustomizedPopUp
        title="Add Departments"
        open={departmentModal}
        onClose={() => toggleDepartmentModal(false)}
        actionConfirmed={() => {
          //
        }}
        maxWidth={600}
        dialogTitle="Select Departments to Add to Device"
        okText={false}
        cancel={false}
      >
        <SelectDepartmentsModal
          handleModalClose={() => toggleDepartmentModal(false)}
        />
      </CustomizedPopUp>
      <CustomizedDialogs
        open={confirmModal}
        onClose={() => toggleConfirmModal(false)}
        deleteConfirmed={onDeleteConfirmed}
      />
    </>
  );
}
