// ---------------- <START> module import ------------------//
import React, { useState, useEffect } from "react";
import { Paper, Typography } from "@material-ui/core";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import { useTable } from "../../Reusable";
import { useTableStyles } from "../../Styles/TableStyles";
import ActionButton from "../../Reusable/Buttons/ActionButton";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import { HeadCellsI } from "../../../actions";
import {
  ItemDeleteButton,
  ItemEditButton,
} from "../../Reusable/Buttons/TableButton";
import { APETypeI } from "../../../actions/Examination/APE/APEActionTypes";
import { DeleteAPE, GetAPEs } from "../../../actions/Examination/APE/APEAction";
// ---------------- <END> module import ------------------//
// ---------------- <START> Head Cells ------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N" },
  { id: "name", label: "APE Factor" },
  { id: "action", label: "Action" },
];
// ---------------- <START> Head Cells ------------------//
// ---------------- <START> Designation Table Component ------------------//
const APETable = (props: any) => {
  const { onEditData } = props;
  const classes = useTableStyles();
  const dispatch = useDispatch();
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  const [tableData, setTableData] = useState<APETypeI[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);

  const apeState = useSelector((state: RootStore) => state.apes);

  useEffect(() => {
    dispatch(GetAPEs());
  }, []);

  useEffect(() => {
    setLoading(apeState.loading);

    const items: APETypeI[] = apeState.apes.map((ape) => ({
      id: ape.id,
      title: ape.title,
    }));

    setTableData(items);
  }, [apeState]);

  const handleEditClicked = (data: APETypeI) => {
    onEditData(data);
  };

  const handleDeleteModal = (value: boolean, id: string) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteAPE(itemId));
    setDeleteModalOpen(false);
  };

  const getCustomTableRow = (item: APETypeI, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.title}
        </StyledTableCell>

        <StyledTableCell align="center" className={classes.cell}>
          <ItemEditButton onClick={() => handleEditClicked(item)} />
          <ItemDeleteButton onClick={() => handleDeleteModal(true, item.id)} />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <Paper className={classes.root}>
      <Typography variant="h6">APE Factor List</Typography>

      <TableContainer
        label="APE List"
        loading={loading}
        items={tableData}
        headCells={headCells}
        getCustomTableRow={getCustomTableRow}
        disableSearch
      />

      {/* Delete modal dialog */}
      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
      />
    </Paper>
  );
};
// ---------------- <START> Designation Table Component ------------------//

export default APETable;
