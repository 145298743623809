import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_SCHOOLS_LOADING,
  GET_SCHOOLS_ERROR,
  GET_SCHOOLS_SUCCESS,
  GET_ALL_SCHOOLS_LOADING,
  GET_ALL_SCHOOLS_ERROR,
  GET_ALL_SCHOOLS_SUCCESS,
  GET_SCHOOLS_BY_ID_LOADING,
  GET_SCHOOLS_BY_ID_ERROR,
  GET_SCHOOLS_BY_ID_SUCCESS,
  SchoolsDispatchTypes,
  ADD_SCHOOLS_LOADING,
  ADD_SCHOOLS_SUCCESS,
  ADD_SCHOOLS_ERROR,
  DELETE_SCHOOLS_SUCCESS,
  DELETE_SCHOOLS_ERROR,
  DELETE_SCHOOLS_LOADING,
  UPDATE_SCHOOLS_LOADING,
  UPDATE_SCHOOLS_SUCCESS,
  UPDATE_SCHOOLS_ERROR,
  RequestDataI,
} from "./SchoolActionTypes";

import {
  SWITCH_TO_SCHOOL_LOADING,
  SWITCH_TO_SCHOOL_ERROR,
  SWITCH_TO_SCHOOL_SUCCESS,
  SWITCH_BACK_TO_SCHOOL_LOADING,
  SWITCH_BACK_TO_SCHOOL_ERROR,
  SWITCH_BACK_TO_SCHOOL_SUCCESS,
  GET_AUTH_TOKEN,
} from "../../Auth/Login/LoginActionTypes";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { csrftoken } from "../../Auth/Login/LoginAction";
// import { HeaderConfig } from "../../Auth/Login/LoginAction";

const HeaderConfig = () => {
  return {
    headers: {
      Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjQ5MDYyMDEyLCJqdGkiOiJlNmIzMjNhNGM1Nzk0YjYxYjg2Y2NmNWY5NzVlYmEzZCIsInVzZXJfaWQiOiIwYzQ4MzBiMC04NWFkLTQzNjQtYmYyZS1kMzdhYmQ1N2EyNGQiLCJvcmdhbml6YXRpb24iOjEsImdyb3VwcyI6WyJPcmdhbml6YXRpb24gQWRtaW4iXSwiZmNtX3Rva2VuIjpudWxsLCJwYXNzd29yZF91cGRhdGVkIjp0cnVlLCJjbGFzc190ZWFjaGVyIjpmYWxzZX0.WlIerTgBGLcTaRFog0sEzyoE_QSPn9Hn2WQCkpILwGI`,
      "X-CSRFToken": csrftoken,
      "Content-Type": "application/json",
    },
  };
};

export const GetSchools =
  (id: number) =>
  async (dispatch: Dispatch<SchoolsDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_SCHOOLS_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/organization/general_info/?page=${id}&per_page=9`,
        HeaderConfig()
      );

      dispatch({
        type: GET_SCHOOLS_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_SCHOOLS_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Schools Loading Failed",
        },
      });
    }
  };

export const GetAllSchools =
  (id: number) =>
  async (dispatch: Dispatch<SchoolsDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_ALL_SCHOOLS_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/organization/general_info/?page=${id}&per_page=500`,
        HeaderConfig()
      );

      dispatch({
        type: GET_ALL_SCHOOLS_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_SCHOOLS_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Schools Loading Failed",
        },
      });
    }
  };

export const GetSchoolById =
  () => async (dispatch: Dispatch<SchoolsDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_SCHOOLS_BY_ID_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/organization/general_info`,
        HeaderConfig()
      );

      dispatch({
        type: GET_SCHOOLS_BY_ID_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_SCHOOLS_BY_ID_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Schools Loading Failed",
        },
      });
    }
  };

export const AddSchools =
  (data: any) =>
  async (dispatch: Dispatch<SchoolsDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_SCHOOLS_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/organization/general_info/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_SCHOOLS_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "School Added Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: ADD_SCHOOLS_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "School Add Failed",
        },
      });
    }
  };

export const UpdateSchool =
  (id: number, data: any) =>
  async (dispatch: Dispatch<SchoolsDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_SCHOOLS_LOADING,
      });

      const res = await axios.put(
        `${ADMIN_API_URL}/organization/general_info/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_SCHOOLS_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "School Updated Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: UPDATE_SCHOOLS_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "School Update Failed",
        },
      });
    }
  };

export const DeleteSchool =
  (id: number) =>
  async (dispatch: Dispatch<SchoolsDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_SCHOOLS_LOADING,
      });

      await axios.delete(
        `${ADMIN_API_URL}/organization/general_info/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_SCHOOLS_SUCCESS,
        payload: { id: id },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "School Deleted Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_SCHOOLS_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "School Delete Failed",
        },
      });
    }
  };

export const SwitchToSchool =
  (data: any) => async (dispatch: Dispatch<any | SetSnackBarI>) => {
    try {
      dispatch({
        type: SWITCH_TO_SCHOOL_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/user/token/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: SWITCH_TO_SCHOOL_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: SWITCH_TO_SCHOOL_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Switching To Failed",
        },
      });
    } finally {
      dispatch({
        type: GET_AUTH_TOKEN,
      });
    }
  };

export const SwitchBackToSchool =
  () => async (dispatch: Dispatch<any | SetSnackBarI>) => {
    dispatch({
      type: SWITCH_BACK_TO_SCHOOL_SUCCESS,
    });
  };
