//---------------------------- <START> module imports start ----------------------------------//
import React, { useEffect, useState } from 'react';
import { Grid, Typography, Paper, Tooltip } from '@material-ui/core';
import DashImg from '../../../../assets/images/ParentDash.jpg';
import FormButton from '../../../Reusable/Buttons/FormButton';
import { useSelector, useDispatch } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { RootStore } from '../../../../store';
import DeleteForeverOutlined from '@material-ui/icons/DeleteForeverOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import ConfirmDialog from '../../../Reusable/Dialogs/ConfirmDialog';
import {
  ActivateOrDeactivateAdmitCardTemplate,
  DeleteAdmitCardTemplate,
  GetAdmitCardTemplate,
} from '../../../../actions/Templates/AdmitCard/AdmitCardTemplateAction';
import checkImageUrl from '../../../utils/checkImageUrl';
import TemplateStyles from '../IdCard/TemplateStyles';
// ----------------------------<END> module imports end ----------------------------------//

const MyAdmitCards: React.FC = () => {
  const [isThere, setIsThere] = useState(false);
  const [landscapeTemplateData, setLandscapeTemplateData] = useState<any>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [cardID, setCardID] = useState<string | null>(null);
  const [editMode, setEditMode] = useState(false);

  const classes = TemplateStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const my_templates = useSelector(
    (state: RootStore) => state.admit_card_templates
  );

  useEffect(() => {
    if (my_templates != null) {
      setLandscapeTemplateData(
        my_templates.admit_card_templates.filter(
          (item: any) => item.mode === 'landscape'
        )
      );
      my_templates.admit_card_templates.length > 0
        ? setIsThere(true)
        : setIsThere(false);
    }
  }, [my_templates]);

  useEffect(() => {
    dispatch(GetAdmitCardTemplate());
  }, []);

  const onClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    cardID != null && dispatch(DeleteAdmitCardTemplate(cardID));
    setOpen(false);
  };

  const handleEdit = (id: string) => {
    id != null && setCardID(id);
    setEditMode(true);
  };

  const handleDeleteConfirm = (id: string) => {
    if (id != null) {
      setCardID(id);
      setOpen(true);
    }
  };

  const handleActivate = (id: string, active: boolean) => {
    if (!active) {
      id != null && dispatch(ActivateOrDeactivateAdmitCardTemplate(id));
    }
  };

  if (editMode && cardID != null) {
    history.push(`/admit-card/${cardID}/update`);
  }

  return (
    <>
      <Paper style={{ margin: '8px 16px', padding: '20px' }}>
        {!isThere ? (
          <>
            <div style={{ textAlign: 'center' }}>
              <img src={DashImg} style={{ height: '320px' }} />
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    Opps! You've not created any Admit Card templates yet.
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <Grid container justifyContent="center">
              <Link
                to="/browse-admit-card-templates"
                style={{ textDecoration: 'none' }}
              >
                <FormButton style={{ minWidth: '150px', marginTop: '16px' }}>
                  Start Creating
                </FormButton>
              </Link>
            </Grid>
          </>
        ) : (
          <>
            <Grid container spacing={3} justifyContent="space-between">
              <Grid item xs={6} sm={3}>
                <span className={classes.title}>Your Templates</span>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Link
                  to="/browse-admit-card-templates"
                  style={{ textDecoration: 'none' }}
                >
                  <FormButton className={classes.btnRight}>Add New</FormButton>
                </Link>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              {landscapeTemplateData.length
                ? landscapeTemplateData.map((item: any, index: number) => {
                    return (
                      <Grid item xs={6} key={index}>
                        <div className={classes.ImgContainer}>
                          <Card
                            className={classes.card}
                            style={{
                              border: item.active ? '3px solid #5ECD68' : '',
                              padding: '4px',
                            }}
                          >
                            <CardActionArea>
                              <img
                                src={checkImageUrl(item.thumbnail)}
                                style={{ height: '300px', width: '100%' }}
                              />
                            </CardActionArea>
                          </Card>
                          <div className={classes.ImgActions}>
                            <div className={classes.actions}>
                              <Tooltip
                                title="Activate"
                                className={classes.tooltip}
                                onClick={() =>
                                  handleActivate(item.id, item.active)
                                }
                              >
                                <ExitToAppOutlinedIcon
                                  className={classes.activeicon}
                                  style={{ color: 'white' }}
                                />
                              </Tooltip>
                              <Tooltip
                                title="Edit"
                                className={classes.tooltip}
                                onClick={() => handleEdit(item.id)}
                              >
                                <EditOutlinedIcon
                                  className={classes.editicon}
                                  style={{ color: 'white' }}
                                />
                              </Tooltip>
                              <Tooltip
                                title="Remove"
                                className={classes.tooltip}
                                onClick={() => handleDeleteConfirm(item.id)}
                              >
                                <DeleteForeverOutlined
                                  className={classes.deleteicon}
                                  style={{ color: 'white' }}
                                />
                              </Tooltip>
                              <br />
                              {item.active && (
                                <b
                                  style={{
                                    fontSize: '12px',
                                    color: 'white',
                                    textDecoration: 'underline',
                                    textUnderlinePosition: 'under',
                                  }}
                                >
                                  This is currently active.
                                </b>
                              )}
                            </div>
                          </div>
                        </div>
                      </Grid>
                    );
                  })
                : ''}
            </Grid>
          </>
        )}
      </Paper>
      <ConfirmDialog
        actionConfirmed={handleDelete}
        onClose={onClose}
        open={open}
        title="Are you sure want to delete this template?"
        description="It will get removed from your template list but you can always add again to your template page."
        cancelText="CANCEL"
        agreeText="DELETE"
      />
    </>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//
export default MyAdmitCards;
