import { Paper } from "@material-ui/core";
import React from "react";
import AdmissionForm from "./AdmissionForm";
import ReceiptForm from "./ReceiptForm";
import styled from "styled-components";
import InvoiceForm from "./InvoiceForm";
import RubricForm from "./RubricForm";

const StyledPaper = styled(Paper)`
  margin: 20px;
  padding: 20px;
`;

const PaperTitle = styled.h2`
  color: rgb(77, 79, 92);
  padding: 0 15px;
  font-size: 30px;
`;

const Miscellaneous = () => {
  return (
    <div>
      <AdmissionForm />
      <StyledPaper>
        <PaperTitle>Finance Settings</PaperTitle>
        <ReceiptForm />
        <InvoiceForm />
      </StyledPaper>
      <RubricForm />
    </div>
  );
};

export default Miscellaneous;
