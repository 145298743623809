import React, { useEffect, useState } from 'react';
import {
  TableBody,
  TextField,
  Grid,
  Typography,
  Paper,
  Chip,
  LinearProgress,
} from '@material-ui/core';
import { DeleteForeverOutlined } from '@material-ui/icons';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { useTable } from '../../Reusable';
import { useTableStyles } from '../../Styles/TableStyles';
import ActionButton from '../../Reusable/Buttons/ActionButton';
import { AnnouncementTypeI } from '../../../actions/Communication/Announcement/AnnouncementActionTypes';
import { DeleteAnnouncement } from '../../../actions/Communication/Announcement/AnnouncementAction';
import { useDispatch } from 'react-redux';
import Popups from '../../Reusable/Dialogs/Popups';
import { AddAnnoucModalPage } from '../../../pages';
import CustomizedDialogs from '../../Reusable/Dialogs/DeleteDialog';
import { Pagination } from '@material-ui/lab';
import AccessPermission from '../../Student/StudentDetail/AccessPermission';
import StudentMappingForm from './StudentMappingForm';
import StudentMappingFormSearch from './StudentMappingFormSearch';

export interface DataI {
  data: AnnouncementTypeI;
  message_to: React.ReactNode;
}

interface HeadCellsI {
  id: string;
  label: string;
}

const headCells1: HeadCellsI[] = [
  { id: 'studentId', label: 'Student ID' },
  { id: 'name', label: 'Student Name' },
  { id: 'location', label: 'Location' },
  { id: 'discount', label: 'Discount' },
  { id: 'isOneWay', label: 'Is One Way' },
  { id: 'bus', label: 'Bus' },
  { id: 'active', label: 'Active' },
  { id: 'action', label: 'Action' },
];

const tempData1 = [
  {
    id: '456123789',
    name: 'John Doe',
    location: 'Baneshwor',
    discount: 100,
    isOneWay: true,
    bus: 'BUS456',
    active: true,
  },
  {
    id: '556123789',
    name: 'John Thapa',
    location: 'Kalanki',
    discount: 50,
    isOneWay: false,
    bus: 'BUS456',
    active: false,
  },
  {
    id: '656123789',
    name: 'John Doe',
    location: 'Baneshwor',
    discount: 100,
    isOneWay: true,
    bus: 'BUS456',
    active: true,
  },
  {
    id: '756123789',
    name: 'John Thapa',
    location: 'Kalanki',
    discount: 50,
    isOneWay: false,
    bus: 'BUS456',
    active: false,
  },
];

const StudentMappingFormList: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useTableStyles();

  const [tableData, setTableData] = useState<DataI[]>([]);
  const [tempTableData, setTempTableData] = useState<DataI[]>([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [editData, setEditData] = useState<AnnouncementTypeI | null>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<number | null>(null);
  const [item, setItem] = useState<DataI | null>(null);
  const [totalRecord, setTotalRecord] = useState(tempTableData.length);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(
    Math.ceil(totalRecord / rowsPerPage)
  );
  const [page, setPage] = useState(1);
  const [recordFrom, setRecordFrom] = useState(1);
  const [recordTo, setRecordTo] = useState(rowsPerPage);
  const [loading, setLoading] = useState<boolean>(false);

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
    setRecordFrom(newPage === 1 ? newPage : (newPage - 1) * rowsPerPage + 1);
    setRecordTo(newPage === 1 ? newPage * rowsPerPage : newPage * rowsPerPage);
  };

  const {
    TblContainer,
    TblHead,
    StyledTableCell,
    StyledTableRow,
    exportToExcel,
    tablePrint,
  } = useTable(headCells1);

  const handleTableSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const search_data = tempTableData.filter((data) =>
      data.data.title.toUpperCase().includes(e.target.value.toUpperCase())
    );
    setTableData(search_data);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const handleDeleteModal = (value: boolean, id: number) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteAnnouncement(itemId));
    setDeleteModalOpen(false);
  };

  const handleClose = (value: boolean) => {
    setOpenPopup(value);
  };

  const handleViewModal = (item: DataI) => {
    setItem(item);
    setItemId(item.data.id);
  };

  return (
    <>
      <StudentMappingFormSearch />

      <StudentMappingForm
        classEditID="4e87709b-d0ec-4a27-bc88-ec0975e8004c
"
      />

      <Popups
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        onClose={handleClose}
      >
        <AddAnnoucModalPage
          onClose={handleClose}
          mode="edit"
          initialData={editData}
        />
      </Popups>

      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
      />
    </>
  );
};

export default StudentMappingFormList;
