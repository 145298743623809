// ---------------------------- <START> module imports start ----------------------------------//
import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  InputLabel,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";

import { useTableStyles } from "../../Styles/TableStyles";
import { Autocomplete } from "@material-ui/lab";
import { useFormStyles } from "../../Styles/FormStyles";
import { GridCol1, GridCol4, GridContainer } from "../../../core-ui/Grid/Grid";
import AddNewButton from "../../Reusable/Buttons/AddNewButton";
import { Add, Save } from "@material-ui/icons";
import { ItemDeleteButton } from "../../Reusable/Buttons/TableButton";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";

import {
  addNewSection,
  removeSection,
  modifySection,
  clearSection,
  initiateSections,
  clearTargetClass,
} from "../../../rtk/features/class/ClassSlice";
import { setSnackbar } from "../../../actions/SnackbarAction";
import {
  createClassSection,
  getClassSectionByGrade,
  updateClassSection,
} from "../../../rtk/features/class/classActions";
import BackDropLoader from "../../Reusable/BackDropLoader";

const HorizontalLine = styled.div`
  height: 1px;
  background-color: rgba(0, 0, 0, 0.12);

  margin: 10px 0;
`;

const SectionContainer = styled.div``;

const SectionTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    color: #5e5e5e;
  }
`;

const SectionMain = styled.div`
  margin-top: 10px;
`;

const GridRow = styled.div`
  display: flex;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SwitchLabel = styled.span`
  font-size: 10px;
  color: #5e5e5e;
`;

const RowContent = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
`;

const StyledTextField = styled(TextField)`
  && {
    width: 100%;
    > * {
      margin: 0;
    }
    input {
      padding: 8px 16px;
    }
  }
`;

const SaveButton = styled(Button)`
  background-color: #132e98;
  color: #fff;

  &:hover {
    background-color: #0d206a;
  }
`;

const CancelButton = styled(Button)`
  background-color: #e0e0e0;
  color: #000;

  &:hover {
    background-color: #d5d5d5;
  }
`;

interface GradeType {
  value: string;
  label: string;
}

const ClassTable = () => {
  const tableClasses = useTableStyles();
  const dispatch = useDispatch();

  const { grades, classes, targetClass, loading, action } = useSelector(
    (state: RootStore) => state.newClass
  );

  const [selectedGrade, setSelectedGrade] = useState<GradeType | null>(null);
  const [switchStatus, toggleSwitchStatus] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      dispatch(clearTargetClass());
    };
  }, []);

  useEffect(() => {
    if (targetClass) {
      targetClass.sections.length
        ? toggleSwitchStatus(true)
        : toggleSwitchStatus(false);
    } else {
      toggleSwitchStatus(false);
    }
  }, [targetClass]);

  const handleGradeChange = (val: GradeType | null) => {
    setSelectedGrade(val);
    if (val) {
      dispatch(getClassSectionByGrade(val.value));
    } else {
      dispatch(clearTargetClass());
    }
  };

  const handleSwitchChange = () => {
    if (switchStatus) {
      dispatch(clearSection());
    } else {
      dispatch(initiateSections());
    }
  };

  const handleAddNewSection = () => {
    dispatch(addNewSection());
  };

  const handleCancelClick = () => {
    setSelectedGrade(null);
    dispatch(clearTargetClass());
  };

  const handleSaveClick = () => {
    let error = false;

    if (targetClass && selectedGrade) {
      targetClass.sections.map((el) => {
        if (el.name === "") {
          error = true;
        }
      });

      if (!error) {
        const postData = {
          grade: selectedGrade.value,
          sections: targetClass.sections.map((el) => {
            if (el.isNew) {
              return {
                name: el.name,
              };
            } else {
              return {
                id: el.id,
                name: el.name,
              };
            }
          }),
        };

        if (action === "create") {
          dispatch(createClassSection(postData));
        } else {
          dispatch(updateClassSection(postData));
        }
      } else {
        dispatch(setSnackbar(true, "warning", "Section name cannot be empty."));
      }
    } else {
      dispatch(setSnackbar(true, "warning", "Grade not selected."));
    }
  };
  //================================<END>LIFECYCLE HOOKS<END>=================================//

  return (
    <Paper className={tableClasses.root}>
      <GridContainer>
        <GridCol4>
          <Typography variant="h4">Manage Class</Typography>
        </GridCol4>
        <GridCol1>
          <InputLabel>
            Select Grade
            <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <Autocomplete
            value={selectedGrade}
            onChange={(event: React.ChangeEvent<{}>, value: GradeType | null) =>
              handleGradeChange(value)
            }
            options={grades}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                name="grade"
                placeholder="Grade"
                variant="outlined"
              />
            )}
          />
        </GridCol1>
      </GridContainer>

      <HorizontalLine />

      <SectionContainer>
        <SectionTop>
          <p>Edit Sections</p>
          <AddNewButton
            title="Add New Section"
            type="button"
            color="primary"
            variant="outlined"
            startIcon={<Add />}
            onClick={() => handleAddNewSection()}
            disabled={!switchStatus}
          >
            Add Section
          </AddNewButton>
        </SectionTop>
        <SectionMain>
          <GridRow>
            <SwitchContainer>
              <Switch
                disabled={targetClass ? false : true}
                checked={switchStatus}
                onClick={() => handleSwitchChange()}
              />
              <SwitchLabel>Has Section</SwitchLabel>
            </SwitchContainer>
          </GridRow>
          {targetClass?.sections.map((el, index) => (
            <GridRow key={index + 1}>
              <RowContent>
                <StyledTextField
                  name="grade"
                  placeholder="Enter Section Name"
                  variant="outlined"
                  value={el.name}
                  onChange={(e) =>
                    dispatch(
                      modifySection({
                        sectionId: el.id,
                        value: e.target.value,
                      })
                    )
                  }
                />
                <ItemDeleteButton
                  onClick={() => dispatch(removeSection(el.id))}
                />
              </RowContent>
            </GridRow>
          ))}
          <GridRow>
            <RowContent>
              <SaveButton
                variant="contained"
                startIcon={<Save />}
                disabled={targetClass ? false : true}
                onClick={handleSaveClick}
              >
                Save
              </SaveButton>
              <CancelButton
                variant="contained"
                disabled={targetClass ? false : true}
                onClick={handleCancelClick}
              >
                Cancel
              </CancelButton>
            </RowContent>
          </GridRow>
        </SectionMain>
      </SectionContainer>
      <BackDropLoader open={loading} />
    </Paper>
  );
};

export default ClassTable;
