import { Grid } from "@material-ui/core";
import React from "react";
import { AcademicSidebar } from "../../components";
import CreateTimeTableForm from "../../components/Academic/CreateTimeTable/CreateTimeTableForm";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const CreateTimeTablePage = () => {
  const nav = <AcademicSidebar timeTable />;
  return (
    <SubModuleLayout sideNav={nav}>
      <Grid item xs={12}>
        <CreateTimeTableForm />
      </Grid>
    </SubModuleLayout>
  );
};

export default CreateTimeTablePage;
