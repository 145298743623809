import { SystemRolesTypeI } from "../../HumanResource/StaffRole/StaffRoleActionTypes";

export const POST_LOGIN_LOADING = "POST_LOGIN_LOADING";
export const POST_LOGIN_ERROR = "POST_LOGIN_ERROR";
export const POST_LOGIN_SUCCESS = "POST_LOGIN_SUCCESS";

export const GET_USERS_BY_PHONE_LOADING = "GET_USERS_BY_PHONE_LOADING";
export const GET_USERS_BY_PHONE_ERROR = "GET_USERS_BY_PHONE_ERROR";
export const GET_USERS_BY_PHONE_SUCCESS = "GET_USERS_BY_PHONE_SUCCESS";

export const SET_USER_BY_PHONE_SUCCESS = "SET_USER_BY_PHONE_SUCCESS";

export const GET_AUTH_TOKEN = "GET_AUTH_TOKEN";

export const VERIFY_OTP = "VERIFY_OTP";

export const SEND_OTP_LOADING = "SEND_OTP_LOADING";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const SEND_OTP_ERROR = "SEND_OTP_ERROR";

export const VERIFY_OTP_LOADING = "VERIFY_OTP_LOADING";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_ERROR = "VERIFY_OTP_ERROR";

export const CHANGE_PASSWORD_LOADING = "CHANGE_PASSWORD_LOADING";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";

export const UPDATE_APP_THEME_LOADING = "UPDATE_APP_THEME_LOADING";
export const UPDATE_APP_THEME_SUCCESS = "UPDATE_APP_THEME_SUCCESS";
export const UPDATE_APP_THEME_ERROR = "UPDATE_APP_THEME_ERROR";

export const SWITCH_TO_SCHOOL_LOADING = "SWITCH_TO_SCHOOL_LOADING";
export const SWITCH_TO_SCHOOL_ERROR = "SWITCH_TO_SCHOOL_ERROR";
export const SWITCH_TO_SCHOOL_SUCCESS = "SWITCH_TO_SCHOOL_SUCCESS";

export const SWITCH_BACK_TO_SCHOOL_LOADING = "SWITCH_BACK_TO_SCHOOL_LOADING";
export const SWITCH_BACK_TO_SCHOOL_ERROR = "SWITCH_BACK_TO_SCHOOL_ERROR";
export const SWITCH_BACK_TO_SCHOOL_SUCCESS = "SWITCH_BACK_TO_SCHOOL_SUCCESS";

export type GeneralInfoType = {
  id: string;
  school_name: string;
  address: string;
  phone_number: string;
  email: string;
  logo: any;
  school_reg_number: string;
  slogan: string;
};

export type UserType = {
  id: string;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  groups: SystemRolesTypeI[];
};

export type TokenType = {
  access: string;
  refresh: string;
  access_expires_on: string;
  refresh_expires_on: string;
  password_updated: boolean;
};

export type LoginPayloadType = {
  token: TokenType;
  general_info: any;
  user: any;
};

export interface LoginLoading {
  type: typeof POST_LOGIN_LOADING;
}

export interface LoginError {
  type: typeof POST_LOGIN_ERROR;
}

export interface LoginSuccess {
  type: typeof POST_LOGIN_SUCCESS;
  payload: any;
}

export interface GetUsersByPhoneLoading {
  type: typeof GET_USERS_BY_PHONE_LOADING;
}

export interface GetUsersByPhoneError {
  type: typeof GET_USERS_BY_PHONE_ERROR;
}

export interface GetUsersByPhoneSuccess {
  type: typeof GET_USERS_BY_PHONE_SUCCESS;
  payload: any;
}

export interface VerifyOTPLoading {
  type: typeof VERIFY_OTP_LOADING;
}

export interface VerifyOTPError {
  type: typeof VERIFY_OTP_ERROR;
}

export interface VerifyOTPSuccess {
  type: typeof VERIFY_OTP_SUCCESS;
  payload: any;
}

export interface SendOTPLoading {
  type: typeof SEND_OTP_LOADING;
}

export interface SendOTPError {
  type: typeof SEND_OTP_ERROR;
}

export interface SendOTPSuccess {
  type: typeof SEND_OTP_SUCCESS;
  payload: any;
}

export interface ChangePasswordLoading {
  type: typeof CHANGE_PASSWORD_LOADING;
}

export interface ChangePasswordError {
  type: typeof CHANGE_PASSWORD_ERROR;
}

export interface ChangePasswordSuccess {
  type: typeof CHANGE_PASSWORD_SUCCESS;
  payload: any;
}

export interface UpdateAppThemeLoading {
  type: typeof UPDATE_APP_THEME_LOADING;
}

export interface UpdateAppThemeError {
  type: typeof UPDATE_APP_THEME_ERROR;
}

export interface UpdateAppThemeSuccess {
  type: typeof UPDATE_APP_THEME_SUCCESS;
  payload: any;
}

export interface SetUserByPhoneSuccess {
  type: typeof SET_USER_BY_PHONE_SUCCESS;
  payload: any;
}

export interface GetAuthToken {
  type: typeof GET_AUTH_TOKEN;
}

export interface VerifyOTPI {
  type: typeof VERIFY_OTP;
  payload: any;
}

//SCHOOL SWITCH TO & BACK TO
export interface SwitchToSchoolsLoading {
  type: typeof SWITCH_TO_SCHOOL_LOADING;
}

export interface SwitchToSchoolsError {
  type: typeof SWITCH_TO_SCHOOL_ERROR;
}

export interface SwitchToSchoolsSuccess {
  type: typeof SWITCH_TO_SCHOOL_SUCCESS;
  payload: any;
}

export interface SwitchBackToSchoolsLoading {
  type: typeof SWITCH_BACK_TO_SCHOOL_LOADING;
}

export interface SwitchBackToSchoolsError {
  type: typeof SWITCH_BACK_TO_SCHOOL_ERROR;
}

export interface SwitchBackToSchoolsSuccess {
  type: typeof SWITCH_BACK_TO_SCHOOL_SUCCESS;
}
export type LoginDispatchTypes =
  | LoginLoading
  | LoginError
  | LoginSuccess
  | GetUsersByPhoneLoading
  | GetUsersByPhoneError
  | GetUsersByPhoneSuccess
  | SetUserByPhoneSuccess
  | VerifyOTPI
  | VerifyOTPLoading
  | VerifyOTPError
  | VerifyOTPSuccess
  | SendOTPLoading
  | SendOTPError
  | SendOTPSuccess
  | ChangePasswordLoading
  | ChangePasswordError
  | ChangePasswordSuccess
  | UpdateAppThemeLoading
  | UpdateAppThemeError
  | UpdateAppThemeSuccess
  | GetAuthToken
  | SwitchToSchoolsLoading
  | SwitchToSchoolsError
  | SwitchToSchoolsSuccess
  | SwitchBackToSchoolsLoading
  | SwitchBackToSchoolsError
  | SwitchBackToSchoolsSuccess;
