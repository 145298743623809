// -----------------<START> import modules starts----------------------------------------------//
import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Tabs,
  Tab,
  Typography,
  Box,
  Button,
} from '@material-ui/core';
import Info from './Info';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GetStudentProfile } from '../../../../actions/Student/StudentProfile/StudentProfileAction';
import { RootStore } from '../../../../store';
import ParentsInfo from './ParentsInfo';
import SiblingsInfo from './SIblings/SiblingsInfo';
import OtherInfo from './OtherInfo';
import LeaveTable from './LeaveTab/LeaveTable';
import Document from './Document';
import FeeType from './FeeType';
import StaffRoles from '../../../HumanResource/StaffList/StaffProfile/StaffTabs/StaffRoles';
import SelectedStudentTable from '../../../Finance/CollectFees/SelectedStudentTable';
import MyProgress from './MyProgress/MyProgress';
import Activities from './Activities';
import Explore from './Explore/Explore';
import Rubric from './RubricTab/RubricTab'
import FinanceTabContent from './FinanceTabContent';
import AccessPermission from '../../StudentDetail/AccessPermission';

//------------------------<END> import module ends---------------------------------------------------------------//

//-----------------<START> style starts--------------------------------------------------------//
export const useTabStyles = makeStyles({
  root: {
    // marginTop: "28px",
    paddingLeft: '16px',
    '& .MuiTabs-root': {
      position: 'relative',
      '& .MuiTabs-flexContainer': {
        marginTop: '0.5rem',
        backgroundColor: 'white',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
      },
      '& .MuiTabs-indicator': {
        display: 'none',
      },

      /*
      position: absolute;
    content: '';
    bottom: 2px;
    left: 2px;
    right: 2px;
    height: 2px;
    background: #1d4698;
    */
      '& .MuiButtonBase-root.Mui-selected::before': {
        content: "''",
        position: 'absolute',
        bottom: 2,
        left: 2,
        right: 2,
        height: 2,
        background: '#1d4698',
      },
      '& .MuiTabScrollButton-root': {
        position: 'absolute',
        transform: 'scale(0.9)',
        height: '40px',
        borderRadius: '50%',
        background: '#fefefe',
        opacity: '0.98',
        top: '5px',
        zIndex: 99,
        left: '-1px',

        '&:last-child': {
          left: 'unset',
          right: '-4px',
        },

        '&.Mui-disabled': {
          opacity: 0,
          display: 'none',
        },
      },
    },
    '& .MuiSvgIcon-root': {
      fontSize: 18,
    },
    '& .MuiTab-labelIcon': {
      minHeight: '40px',
      paddingTop: '6px!important',
    },
    '& .MuiTab-root': {
      fontSize: '14px',
      textTransform: 'none',
      minWidth: '80px',
      maxWidth: '200px',
      height: '30px',
      letterSpacing: '0.01071em',
    },
    '& .MuiTabs-scroller.MuiTabs-scrollable': {
      borderBottom: '1px solid #ddd',
    },
    '& > .MuiBox-root': {
      // padding: '10px 0px 0px 0px',
    },
  },
});

//----------------------<END> styled ends ---------------------------------------------------//
// ----------------------<STARTS> interface starts -------------------------------------------------------//
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
//------------------------<ENDS> interface ends -------------------------------------------------------//

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ paddingTop: 10 }}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
// -----------------<START> Time table tabs component starts---------------------//
export default function NewTabs(props: any) {
  const classes = useTabStyles();
  const [value, setValue] = React.useState(0);
  const history = useHistory();

  const { studentID } = useParams<{ studentID: string }>();
  const [studentData, setStudentData] = useState([]);
  const [studentAcademicId, setStudentAcademicId] = useState<string | null>(
    null
  );

  const dispatch = useDispatch();

  useEffect(() => {
    studentID != undefined && dispatch(GetStudentProfile(studentID));
  }, []);

  const authState = useSelector((state: RootStore) => state.auth);
  const studentState = useSelector((state: RootStore) => state.student_profile);
  const { role = 'Administrator' } = authState;

  useEffect(() => {
    if (studentState != null) {
      setStudentData(studentState.student);
    }
  }, [studentState]);

  useEffect(() => {
    if (studentState.student) {
      if (studentState.student.academic_info) {
        const academic_id = studentState.student.academic_info[0].id;
        setStudentAcademicId(academic_id);
      }
    }
  }, [studentState.student]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      {/* <Button variant="contained" onClick={() => history.push("/student")}>
        Go Back
      </Button> */}
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      // variant="fullWidth"
      >
        <Tab label="Explore" value={0} />
        <Tab label="Personal Info" value={1} />
        {/* <Tab label="Parents" value={2} />
        <Tab label="Siblings" value={3} /> */}
        <Tab label="My Progress" value={4} />
        {role === 'Administrator' && <Tab label="Activities" value={5} />}
        <Tab label="Other" value={6} />
        <Tab label="Leave" value={7} />
        <Tab label="Documents" value={8} />
        <Tab label="Finance" value={9} />
        <Tab label="Rubric" value={10} />
        {/* <Tab label="Fee Statements" /> */}
        <Tab label="System Role" value={11} />
      </Tabs>

      <TabPanel value={value} index={0}>
        <Explore />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Info studentData={studentData} />
      </TabPanel>
      {/* <TabPanel value={value} index={2}>
        <ParentsInfo />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <SiblingsInfo />
      </TabPanel> */}
      <TabPanel value={value} index={4}>
        <MyProgress />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Activities />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <OtherInfo />
      </TabPanel>
      <TabPanel value={value} index={7}>
        <LeaveTable studentID={studentID} />
      </TabPanel>
      <TabPanel value={value} index={8}>
        <Document
          hasUpload={true}
          hasDeleteBtn={true}
          Documents={true}
          studentID={studentID}
        />
      </TabPanel>
      <TabPanel value={value} index={9}>
        <FinanceTabContent studentID={studentID} info={studentData} />
      </TabPanel>
      {/* <TabPanel value={value} index={9}>
        <SelectedStudentTable student_id={studentAcademicId} editDisabled={true} />
      </TabPanel> */}
      <TabPanel value={value} index={10}>
        <Rubric />
      </TabPanel>
      <TabPanel value={value} index={11}>
        <StaffRoles user="student" />
      </TabPanel>
    </div>
  );
}
//---------------<END> Routes tabs component ends ----------------------------//
