import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { PeriodsI } from "./periodSlices";
import {
  TimeTableByDayI,
  TimeTableByClassI,
  TimeTableByClassPayloadI,
  TimeTableByDayPayloadI,
  TeacherTimeTableI,
  TeacherTimeTablePayloadI,
  TeacherClassSubjectsI,
  TimeTableDayI,
} from "./timetable";
import {
  copyTimeTable,
  getTeacherDashboardTimeTable,
  getTeacherTimeTable,
  getTimeTableByClass,
  getTimeTableByDay,
  updateTimeTable,
} from "./timetableApi";

const initialState: {
  timetableByClass: TimeTableByClassI | null;
  timeTableByDay: TimeTableByDayI | null;
  timeTableByDayInit: TimeTableByDayI | null;
  teacherTimetable: TeacherTimeTableI[];
  teacherDashboardTimetable: TeacherTimeTableI[];
  teacherClassSubjects: TeacherClassSubjectsI[];
  loading: boolean;
  actionPerformed: boolean;
} = {
  timetableByClass: null,
  timeTableByDay: null,
  timeTableByDayInit: null,
  teacherTimetable: [],
  teacherDashboardTimetable: [],
  teacherClassSubjects: [],
  loading: false,
  actionPerformed: false,
};

const timetableSlice = createSlice({
  name: "timeTable",
  initialState,
  reducers: {
    updateTimetableByDay: (
      state,
      {
        payload,
      }: PayloadAction<{
        period: PeriodsI;
        subjectTeacherId: string | null;
        field: "subject" | "teacher" | "classType";
        class_type_value: number | null;
        subject_value: { id: string; name: string } | null;
        teacher_value: {
          id: string;
          first_name: string;
          last_name: string;
        } | null;
        delete?: boolean;
      }>
    ) => {
      if (state.timeTableByDay) {
        const targetPeriod = state.timeTableByDay.timetable.find(
          (el) => el.period.id === payload.period.id
        );

        if (targetPeriod) {
          if (payload.subjectTeacherId === null) {
            targetPeriod.subject_teacher.push({
              id: uuidv4(),
              class_type: 1,
              teacher: null,
              subject: null,
            });
          } else {
            if (payload.delete) {
              targetPeriod.subject_teacher =
                targetPeriod.subject_teacher.filter(
                  (res) => res.id !== payload.subjectTeacherId
                );
            } else {
              const targetSubjectTeacher = targetPeriod.subject_teacher.find(
                (el) => el.id === payload.subjectTeacherId
              );

              if (targetSubjectTeacher) {
                if (payload.field === "classType") {
                  targetSubjectTeacher.class_type = payload.class_type_value;
                }
                if (payload.field === "subject") {
                  targetSubjectTeacher.subject = payload.subject_value;
                  targetSubjectTeacher.teacher = null;
                }
                if (payload.field === "teacher") {
                  targetSubjectTeacher.teacher = payload.teacher_value;
                }
              }
            }
          }
        } else {
          state.timeTableByDay.timetable.push({
            period: payload.period,
            subject_teacher: [
              {
                id: uuidv4(),
                class_type: 1,
                subject:
                  payload.field === "subject" ? payload.subject_value : null,
                teacher: null,
              },
            ],
          });
        }
      }
    },
    resetState: (state) => {
      state.actionPerformed = false;
      state.timetableByClass = null;
      state.timeTableByDay = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTimeTableByClass.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(
      getTimeTableByClass.fulfilled,
      (state, { payload }: PayloadAction<TimeTableByClassPayloadI>) => {
        const timetable = payload.timetable;
        const result: TimeTableDayI[] = [];

        timetable.forEach((item) => {
          const currentDay = item.day;
          const currentPeriod = item.period_info;
          const currentSubjectTeachers = item.subject_teacher.map((st) => ({
            id: uuidv4(),
            class_type: st.class_type,
            subject: st.subject,
            teacher: st.teacher,
          }));

          const mIndex = result.findIndex((res) => res.day === item.day);

          if (mIndex !== -1) {
            result[mIndex].periods.push({
              period: currentPeriod,
              subject_teacher: currentSubjectTeachers,
            });
          } else {
            result.push({
              day: currentDay,
              periods: [
                {
                  period: currentPeriod,
                  subject_teacher: currentSubjectTeachers,
                },
              ],
            });
          }
        });

        state.timetableByClass = {
          grade: payload.grade,
          section: payload.section,
          timeTable: result,
        };
        state.loading = false;
      }
    );
    builder.addCase(getTimeTableByClass.rejected, (state) => {
      state.loading = false;
      state.timetableByClass = null;
    });

    builder.addCase(getTimeTableByDay.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(
      getTimeTableByDay.fulfilled,
      (state, { payload }: PayloadAction<TimeTableByDayPayloadI>) => {
        const result = {
          grade: payload.grade,
          section: payload.section,
          day: payload.day,
          timetable: payload.timetable
            ? payload.timetable.map((res) => ({
                period: res.period,
                subject_teacher: res.subject_teacher.map((st) => ({
                  id: uuidv4(),
                  class_type: st.class_type,
                  teacher: st.teacher,
                  subject: st.subject,
                })),
              }))
            : [],
        };

        state.loading = false;

        state.timeTableByDay = result;
        state.timeTableByDayInit = result;
      }
    );
    builder.addCase(getTimeTableByDay.rejected, (state) => {
      state.loading = false;
      state.timeTableByDay = null;
    });

    builder.addCase(updateTimeTable.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(updateTimeTable.fulfilled, (state) => {
      state.loading = false;
      state.actionPerformed = true;
    });
    builder.addCase(updateTimeTable.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(copyTimeTable.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(copyTimeTable.fulfilled, (state) => {
      state.loading = false;
      state.actionPerformed = true;
    });
    builder.addCase(copyTimeTable.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getTeacherTimeTable.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getTeacherTimeTable.fulfilled,
      (state, { payload }: PayloadAction<TeacherTimeTablePayloadI[]>) => {
        const timetable: TeacherTimeTableI[] = [];
        payload.forEach((item) => {
          const mIndex = timetable.findIndex(
            (elem) =>
              elem.classes.grade === item.classes.grade &&
              elem.classes.section?.id === item.classes.section?.id
          );
          if (mIndex !== -1) {
            timetable[mIndex].timetable.push({
              id: item.id,
              day: item.day,
              subject: item.subject,
              period: item.period,
            });
          } else {
            timetable.push({
              classes: item.classes,
              timetable: [
                {
                  id: item.id,
                  day: item.day,
                  subject: item.subject,
                  period: item.period,
                },
              ],
            });
          }
        });
        state.teacherTimetable = timetable;
        state.loading = false;
      }
    );
    builder.addCase(getTeacherTimeTable.rejected, (state) => {
      state.loading = false;
      state.teacherTimetable = [];
    });

    builder.addCase(getTeacherDashboardTimeTable.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(
      getTeacherDashboardTimeTable.fulfilled,
      (state, { payload }: PayloadAction<TeacherTimeTablePayloadI[]>) => {
        const timetable: TeacherTimeTableI[] = [];
        payload.forEach((item) => {
          const mIndex = timetable.findIndex(
            (elem) =>
              elem.classes.grade === item.classes.grade &&
              elem.classes.section?.id === item.classes.section?.id
          );
          if (mIndex !== -1) {
            timetable[mIndex].timetable.push({
              id: item.id,
              day: item.day,
              subject: item.subject,
              period: item.period,
            });
          } else {
            timetable.push({
              classes: item.classes,
              timetable: [
                {
                  id: item.id,
                  day: item.day,
                  subject: item.subject,
                  period: item.period,
                },
              ],
            });
          }
        });
        state.teacherDashboardTimetable = timetable;
        state.teacherClassSubjects = payload;
        state.loading = false;
      }
    );
    builder.addCase(getTeacherDashboardTimeTable.rejected, (state) => {
      state.loading = false;
      state.teacherDashboardTimetable = [];
      state.teacherClassSubjects = [];
    });
  },
});

export const {
  updateTimetableByDay: updateTimetableByDayAction,
  resetState: timeTableResetState,
} = timetableSlice.actions;

export default timetableSlice.reducer;
