import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_DASHBOARD_DATA_LOADING,
  GET_DASHBOARD_DATA_ERROR,
  GET_DASHBOARD_DATA_SUCCESS,
  DashboardDataDispatchTypes,
} from "./DashboardActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../SnackbarActionTypes";
import { HeaderConfig } from "../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../config/SERVER_API_URLS";

export const GetDashboardData = () => async (
  dispatch: Dispatch<DashboardDataDispatchTypes | SetSnackBarI>
) => {
  try {
    dispatch({
      type: GET_DASHBOARD_DATA_LOADING,
    });

    const res = await axios.get(`${ADMIN_API_URL}/dashboard/`, HeaderConfig());

    dispatch({
      type: GET_DASHBOARD_DATA_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_DASHBOARD_DATA_ERROR,
    });
  }
};
