import { Button, Grid, IconButton, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  AddEvent,
  UpdateEvent,
  DeleteEvent,
  GetEvents,
} from '../../../actions/Event/Events/EventsAction';
import { RootStore } from '../../../store';
import { FormCheckBox as Checkbox } from '../../../components/Reusable/Inputs/Checkbox';
import NepaliDatePicker from '../../../components/Reusable/Inputs/NepaliDatePicker';
import Select from '../../../components/Reusable/Inputs/Select';
import TextField from '../../../components/Reusable/Inputs/TextField';
import {
  dateConverterAdToBs,
  dateConverterBsToAd,
} from '../../../components/utils/dateConverter';
import { Box } from '@material-ui/core';
import { GetEventTypes } from '../../../actions/Event/EventType/EventTypeAction';
import { CUR_NEPALI_DATE as nepaliDate } from '../../../components/utils/nepaliDateUtils';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import WifiIcon from '@material-ui/icons/Wifi';
import {
  ItemDeleteButton,
  ItemEditButton,
} from '../../../components/Reusable/Buttons/TableButton';
import { fromToConverter } from '../../../components/utils/dateTimeFormat';
import { useTable } from '../../../components/Reusable';
import NepaliDate from 'nepali-date-converter';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface EventTypeI {
  eventType: object;
  title: string;
  description: string;
  fromDate: string;
  toDate: string;
  time: string;
  color: string;
  location: string;
  isHoliday: boolean;
}

interface EventCalendarDayInfoProps {
  date: any;
  onEdit: boolean;
  event: any;
  handleEventClick: any;
  handleDayClick: any;
  eventsByDate: any;
  isAdmin?: boolean;
  onClose: () => void;
}

interface HeadCellsI {
  id: string;
  label: string;
  align?: any;
}

interface TableDataI {
  id: string;
  event_type: string;
  event_type_name: string;
  title: string;
  location: string;
  from_date: string;
  to_date: string;
  time: string;
  is_holiday: boolean;
  description: string;
  general_info: string;
  created_by: string;
  notice?: string;
}

const headCells: HeadCellsI[] = [
  { id: 'title', label: 'Title', align: 'left' },
  { id: 'event_type', label: 'Type', align: 'left' },
  // { id: 'location', label: 'Location' },
  { id: 'date', label: 'Date' },
  // { id: 'time', label: 'Time' },
  { id: 'is_holiday', label: 'Holiday Status' },
  { id: 'action', label: 'Action' },
];

const EventItemContainer = styled(Box)`
  padding-left: 8px;
  border-left: 3px solid #4f5f9e;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-bottom: 8px;
`;

const EventCalendarDayInfo = (props: EventCalendarDayInfoProps) => {
  const dispatch = useDispatch();
  const {
    date: currentDate,
    onClose: handleClose,
    onEdit,
    event,
    isAdmin,
  } = props;
  const { register, handleSubmit, errors, setValue } = useForm<EventTypeI>();

  const [eventType, setEventType] = useState<any>(null);
  const [fromDate, setFromDate] = useState(nepaliDate);
  const [toDate, setToDate] = useState(nepaliDate);
  const [holiday, setHoliday] = useState(false);
  const [tableData, setTableData] = useState<TableDataI[]>([]);

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  useEffect(() => {
    setFromDate(currentDate);
    setToDate(currentDate);
  }, [currentDate]);

  const eventTypeState = useSelector((state: RootStore) => state.event_type);
  const eventsState = useSelector((state: RootStore) => state.events);
  const { loading } = eventsState;

  const EVENT_TYPE_CHOICES = eventTypeState.event_types.map((item: any) => ({
    key: item.id.toString(),
    value: item.event_type,
  }));

  useEffect(() => {
    if (onEdit) {
      setEventType(
        EVENT_TYPE_CHOICES.find((item: any) => item.key === event.event_type)
      );
      setValue('title', event.title);
      setValue('location', event.location);
      setHoliday(event.is_holiday);
      setFromDate(dateConverterAdToBs(event.from_date));
      setToDate(dateConverterAdToBs(event.to_date));
      setValue('time', event.time);
      setValue('description', event.description);
    }
  }, [onEdit]);

  useEffect(() => {
    setTableData(props.eventsByDate);
  }, [props.eventsByDate, props.event]);

  const handleHolidayCheck = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setHoliday(checked);
  };

  const onSubmit = (data: EventTypeI) => {
    const sendData = {
      location: data.location,
      time: data.time,
      description: data.description,
      is_holiday: holiday,
      title: data.title,
      from_date: dateConverterBsToAd(fromDate),
      to_date: dateConverterBsToAd(toDate),
      event_type: eventType.key,
    };

    handleClose();

    if (onEdit) {
      dispatch(UpdateEvent(event.id, sendData));
    } else {
      dispatch(AddEvent(sendData));
    }
  };

  const handleDelete = () => {
    if (onEdit && event && event.id) {
      dispatch(DeleteEvent(event.id));
    }
    handleClose();
  };

  const getCustomTableRow = (item: TableDataI) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="left">{item.title}</StyledTableCell>
        <StyledTableCell align="left">{item.event_type_name}</StyledTableCell>
        {/* <StyledTableCell align="center">
        {item.location}
    </StyledTableCell> */}
        <StyledTableCell align="center">
          {fromToConverter(item.from_date, item.to_date)}
        </StyledTableCell>
        {/* <StyledTableCell align="center">
          {item.time}
        </StyledTableCell> */}
        <StyledTableCell align="center">
          {item.is_holiday ? 'Yes' : 'No'}
        </StyledTableCell>
        <StyledTableCell align="center">
          {isAdmin && (
            <ItemEditButton
              onClick={() =>
                props.handleEventClick(new NepaliDate(props.date), item)
              }
            />
          )}
          {/* <ItemDeleteButton disabled={onEdit} onClick={handleDelete} /> */}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  const formatData = (items: any) => {
    return items.map((item: any) => ({
      id: item.id,
      event_type: item.event_type,
      event_type_name: item.event_type_name,
      title: item.title,
      location: item.location,
      from_date: item.from_date,
      to_date: item.to_date,
      // date: fromToConverter(item.from_date, item.to_date), //from date to date
      time: item.time,
      is_holiday: item.is_holiday,
      description: item.description,
      general_info: item.general_info,
      created_by: item.created_by,
    }));
  };

  return (
    <div style={{ paddingBottom: 16 }}>
      <Grid
        container
        justifyContent="space-between"
        style={{ paddingBottom: 8 }}
      >
        <Grid item>
          <Typography variant="h6">Event List</Typography>
        </Grid>

        {isAdmin && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                console.log('props', props.date);
                props.handleDayClick(new NepaliDate(props.date));
              }}
            >
              Add Event
            </Button>
          </Grid>
        )}
      </Grid>

      <TableContainer
        loading={loading}
        items={tableData}
        headCells={headCells}
        getCustomTableRow={getCustomTableRow}
        search_name="title"
        disablePrint
        disableSearch
        disablePagination
        rowsPerPage={7}
      />
    </div>
  );
};

export default EventCalendarDayInfo;
