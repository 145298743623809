import { Paper, Table, TableBody, TableHead } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { getOptSubjectGroups } from "../../../rtk/features/exam/optSubjects/optSubjectApi";
import { handleEditOptSubjectGroup } from "../../../rtk/features/exam/optSubjects/optSubjectSlice";
import { RootStore } from "../../../store";
import { useTable } from "../../Reusable";
import { ItemEditButton } from "../../Reusable/Buttons/TableButton";

const StyledPaper = styled(Paper)`
  margin: 20px;
  padding: 25px 20px;
`;

const Title = styled.h2`
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
`;

const headCells = [
  { id: "sn", label: "S.N" },
  { id: "subject_group", label: "Subject Group" },
  { id: "subjects", label: "Subjects" },
  { id: "action", label: "Action" },
];

const OptionalSubjectTable = () => {
  const dispatch = useDispatch();
  const { optSubjectGroups, actionPerformed, editMode } = useSelector(
    (state: RootStore) => state.optSubject
  );
  const { StyledTableCell, StyledTableRow } = useTable(headCells);

  useEffect(() => {
    dispatch(getOptSubjectGroups());
  }, []);

  useEffect(() => {
    if (actionPerformed) {
      dispatch(getOptSubjectGroups());
    }
  }, [actionPerformed]);

  return (
    <StyledPaper>
      <Title>Optional Subjects</Title>

      <Table>
        <TableHead>
          <StyledTableRow>
            {headCells.map((el, index) => (
              <StyledTableCell key={index + 1} align="center">
                {el.label}
              </StyledTableCell>
            ))}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {optSubjectGroups.map((el, index) => (
            <StyledTableRow key={index + 1}>
              <StyledTableCell align="center">{index + 1}</StyledTableCell>
              <StyledTableCell align="center">
                {el.name} - {el.grade_name}
              </StyledTableCell>
              <StyledTableCell align="center">
                {el.subject.map((it, ind) => {
                  if (ind === el.subject.length - 1) {
                    return `${it.subject_name}`;
                  } else {
                    return `${it.subject_name}, `;
                  }
                })}
              </StyledTableCell>
              <StyledTableCell align="center">
                <ItemEditButton
                  disabled={editMode}
                  onClick={(
                    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                  ) => dispatch(handleEditOptSubjectGroup(el))}
                />
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </StyledPaper>
  );
};

export default OptionalSubjectTable;
