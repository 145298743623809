import React from 'react';
import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';

const StyledFullScreen = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: white;
`;

const LoaderPage: React.FC = () => {
  return (
    <StyledFullScreen>
      <img src="./favicon.png" height="80px" />
    </StyledFullScreen>
  );
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        marginBottom: '-100px',
      }}
    >
      <CircularProgress />
    </div>
  );
};

export default LoaderPage;
