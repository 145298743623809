//---------------------------- <START> module imports start ----------------------------------//
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Paper, Button, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useHistory, useParams } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import { landscapeData, getSingleCard } from './AdmitCardSampleData';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  FormSaveButton,
  CancelButton,
  FormSaveLoadingButton,
} from '../../../Reusable/Buttons/FormButton';
import {
  AddIdCardTemplate,
  GetSingleIdCardTemplate,
  UpdateIdCardTemplate,
} from '../../../../actions/Templates/IdCard/IdCardTemplateAction';
import { RootStore } from '../../../../store';
import html2canvas from 'html2canvas';
import * as htmlToImage from 'html-to-image';
import AdmitCardSample1 from '../../AdmitCardDesign/AdmitCardSample1';
import AdmitCardSample2 from '../../AdmitCardDesign/AdmitCardSample2';
import {
  AddAdmitCardTemplate,
  GetSingleAdmitCardTemplate,
  UpdateAdmitCardTemplate,
} from '../../../../actions/Templates/AdmitCard/AdmitCardTemplateAction';
import LoaderIcon from '../../../Reusable/LoarderIcon';
// ----------------------------<END> module imports end ----------------------------------//

const style = makeStyles({
  btnRight: {
    color: 'white',
    top: '50%',
    float: 'right',
    position: 'relative',
    transform: 'translateY(-50%)',
  },
  title: {
    fontSize: '17px',
  },
  cardtitle: {
    fontSize: '15px',
  },
  card: {
    // maxWidth: 345,
    padding: 7,
    border: '1px solid #c5d8dd',
    '&:hover': {
      border: '1px solid blue',
      transform: 'scale(1.009)',
    },
  },
  smallbtn: {
    backgroundColor: '#a7a4a444',
    marginLeft: '5px',
    marginRight: '5px',
    '&:hover': {
      backgroundColor: '#64A1C2',
      color: 'white',
    },
  },
  smallbtnActive: {
    backgroundColor: 'blue',
    marginLeft: '5px',
    marginRight: '5px',
    color: 'white',
    '&:hover': {
      backgroundColor: '#64A1C2',
      color: 'white',
    },
  },
});

const CustomizeAdmitCard: React.FC = () => {
  const [landscape, setLandscape] = useState(false);
  const [clicked, setClicked] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { cardID } = useParams<{ cardID: string }>();
  const { mode } = useParams<{ mode: string }>();
  const [individualData, setIndividualData] = useState<any>();
  const [saved, setSaved] = useState<boolean>(false);
  const [textColor, setTextColor] = useState<any>();
  const [backgroundColor, setBackgroundColor] = useState<any>();
  const [textColorTemp, setTextColorTemp] = useState<any>();
  const [backgroundColorTemp, setBackgroundColorTemp] = useState<any>();
  const [url, setUrl] = useState<string>('');
  const [screenshot, setScreenshot] = useState<any>(null);
  const [emptyBackground, setEmptyBackground] = useState<boolean>(true);

  const classes = style();
  const history = useHistory();
  const dispatch = useDispatch();

  const admit_card_templates = useSelector(
    (state: RootStore) => state.admit_card_templates
  );

  const admit_card_template = useSelector(
    (state: RootStore) => state.admit_card_templates.admit_card_template
  );

  const add_or_update = useSelector(
    (state: RootStore) => state.admit_card_templates.add_or_update
  );

  useEffect(() => {
    if (add_or_update) {
      setIsLoading(false);
    }
  }, [add_or_update]);

  useEffect(() => {
    if (cardID) {
      const n = window.location.href.lastIndexOf('/');
      setUrl(window.location.href.substring(n + 1));

      const data = getSingleCard(cardID);
      setIndividualData(data);
      setTextColor(data?.text_color);
      setBackgroundColor(data?.back_ground_color);
      setTextColorTemp(data?.text_color);
      setBackgroundColorTemp(data?.back_ground_color);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setEmptyBackground(false);
    }, 2000);
  }, [individualData]);

  useEffect(() => {
    if (url === 'update') {
      dispatch(GetSingleAdmitCardTemplate(cardID));
    }
  }, [url, cardID]);

  useEffect(() => {
    if (admit_card_template != null) {
      setIndividualData(admit_card_template);
      setTextColor(admit_card_template?.text_color);
      setBackgroundColor(admit_card_template?.back_ground_color);
      setTextColorTemp(admit_card_template?.text_color);
      setBackgroundColorTemp(admit_card_template?.back_ground_color);
    }
  }, [admit_card_template]);

  const handleTextColorChange = (key: string, value: string, name: string) => {
    const temp = {
      key,
      value,
      name,
    };
    setTextColor(
      textColor.map((item: any) => (item.key === key ? temp : item))
    );
  };

  const handleBackgroundColorChange = (
    key: string,
    value: string,
    name: string
  ) => {
    const temp = {
      key,
      value,
      name,
    };
    setBackgroundColor(
      backgroundColor.map((item: any) => (item.key === key ? temp : item))
    );
  };

  useEffect(() => {
    if (textColor != textColorTemp) {
      const temp = {
        ...individualData,
        text_color: textColor,
      };
      setIndividualData(temp);
    }
  }, [textColor]);

  useEffect(() => {
    if (backgroundColor != backgroundColorTemp) {
      const temp = {
        ...individualData,
        back_ground_color: backgroundColor,
      };
      setIndividualData(temp);
    }
  }, [backgroundColor]);

  const renderSwitch = (param: string) => {
    switch (param) {
      case 'ABC-003':
        return <AdmitCardSample1 content={individualData} />;
      case 'ABC-004':
        return <AdmitCardSample2 content={individualData} />;
    }
  };

  const handlleSubmit = () => {
    setIsLoading(true);

    const finalValues = {
      ...individualData,
    };
    const node: any = document.getElementById('exportContainer');
    htmlToImage
      .toPng(node)
      .then(function (dataUrl) {
        finalValues.thumbnail = dataUrl;
        setScreenshot(dataUrl);
        return dataUrl;
        const img = new Image();
        img.src = dataUrl;
        // node.appendChild(img);
        // const base64_ = convertBase64(img);
        // setScreenshot(dataUrl);
        // const temp = individualData;
        // temp[0].text_color[0].thumbnail = dataUrl;
        // setIndividualData(temp);
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
    setTimeout(() => {
      if (url === 'update') {
        dispatch(UpdateAdmitCardTemplate(cardID, finalValues));
      } else {
        dispatch(AddAdmitCardTemplate(finalValues));
      }
      setSaved(true);
    }, 4000);
  };

  const exportAsPicture = () => {
    // const data: any = document.getElementById("exportContainer");
    // html2canvas(data).then(function (canvas) {
    //   const value = document.body.appendChild(canvas);

    // const convertBase64 = (file: any) => {
    //   return new Promise((resolve, reject) => {
    //     const fileReader = new FileReader();
    //     fileReader.readAsText(file);

    //     fileReader.onload = () => {
    //       resolve(fileReader.result);
    //       return fileReader.result as string;
    //     };
    //   });
    // };
    // });
    const node: any = document.getElementById('exportContainer');
    // node.crossOrigin = "Anonymous";
    htmlToImage
      .toPng(node)
      .then(function (dataUrl) {
        setScreenshot(dataUrl);
        return dataUrl;
        const img = new Image();
        img.src = dataUrl;
        // node.appendChild(img);
        // const base64_ = convertBase64(img);
        // setScreenshot(dataUrl);
        // const temp = individualData;
        // temp[0].text_color[0].thumbnail = dataUrl;
        // setIndividualData(temp);
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  };

  const saveAs = (blob: any, fileName: any) => {
    const elem: any = window.document.createElement('a');
    elem.href = blob;
    elem.download = fileName;
    elem.style = 'display:none;';
    (document.body || document.documentElement).appendChild(elem);
    if (typeof elem.click === 'function') {
      elem.click();
    } else {
      elem.target = '_blank';
      elem.dispatchEvent(
        new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
        })
      );
    }
    URL.revokeObjectURL(elem.href);
    elem.remove();
  };

  if (saved && admit_card_templates.add_or_update) {
    history.push('/admit-cards-templates');
  }

  return (
    <>
      <Paper style={{ margin: '30px', padding: '20px' }}>
        <Grid container spacing={3} justifyContent="space-between">
          <Grid item xs={12} sm={6}>
            <span className={classes.title}>
              {url === 'update' ? 'In Update Mode' : 'In Customization Mode'}
            </span>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              onClick={() => history.push('/id-cards-templates')}
            >
              Go Back to My List
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          justifyContent="space-between"
          style={{ marginTop: '10px' }}
        >
          <Grid item xs={6} sm={individualData?.mode === 'potrait' ? 4 : 6}>
            {emptyBackground && individualData != null ? (
              <>
                <Skeleton />
                <Skeleton animation="wave" />
                <Skeleton />
                <Skeleton animation={false} />
                <Skeleton animation="wave" />
                <Skeleton animation={false} />
                <Skeleton animation="wave" />
                <Skeleton />
                <Skeleton animation={false} />
                <Skeleton animation="wave" />
                <Skeleton />
                <Skeleton animation={false} />
                <Skeleton animation="wave" />
                <Skeleton animation={false} />
                <Skeleton animation="wave" />
              </>
            ) : (
              <div
                id="exportContainer"
                style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}
              >
                {cardID && renderSwitch(individualData?.code)}
              </div>
            )}
            {/* {screenshot && screenshot} */}
          </Grid>
          {/* <Grid item xs={6} sm={1}></Grid> */}
          <Grid item xs={6} sm={3} style={{ marginTop: '20px' }}>
            <Box
              fontSize={18}
              fontWeight={500}
              style={{ marginBottom: '20px' }}
            >
              Text Colors
            </Box>
            {textColor?.length > 0 &&
              textColor.map((elem: any, index: number) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginBottom: '10px',
                    }}
                    key={index}
                  >
                    <span
                      style={{
                        fontSize: '12px',
                        marginRight: '8px',
                        marginTop: '5px',
                      }}
                    >
                      {elem.name} - [{elem.value}]
                    </span>
                    <div className="input-color-container">
                      <input
                        type="color"
                        id="input-color"
                        // style={{ width: "80px" }}
                        className="input-color"
                        value={elem.value}
                        onChange={(event: any) =>
                          handleTextColorChange(
                            elem.key,
                            event.target.value,
                            elem.name
                          )
                        }
                      />
                    </div>
                  </div>
                );
              })}
            <FormSaveLoadingButton
              onClick={() => handlleSubmit()}
              icon={isLoading && <LoaderIcon />}
              // disabled={isLoading ? true : false}
              disabled
              text={isLoading ? 'Saving' : 'Save'}
            />
          </Grid>
          <Grid
            item
            xs={6}
            sm={individualData?.mode === 'potrait' ? 4 : 3}
            style={{ marginTop: '20px' }}
          >
            <Box
              fontSize={18}
              fontWeight={500}
              style={{ marginBottom: '20px' }}
            >
              Background
            </Box>
            {backgroundColor?.length > 0 &&
              backgroundColor.map((elem: any, index: number) => {
                return (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: '10px',
                        marginRight: '15px',
                      }}
                      key={index}
                    >
                      <span
                        style={{
                          fontSize: '12px',
                          marginRight: '8px',
                          marginTop: '5px',
                        }}
                      >
                        {elem.name} - [{elem.value}]
                      </span>
                      <div className="input-color-container">
                        <input
                          type="color"
                          id="input-color"
                          className="input-color"
                          value={elem.value}
                          onChange={(event: any) =>
                            handleBackgroundColorChange(
                              elem.key,
                              event.target.value,
                              elem.name
                            )
                          }
                        />
                      </div>
                    </div>
                  </>
                );
              })}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//
export default CustomizeAdmitCard;
