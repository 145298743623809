import React, { useEffect, useState } from 'react';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import { ItemDeleteButton } from '../../Reusable/Buttons/TableButton';
import { timeFormat, fromToConverter } from '../../utils/dateTimeFormat';
import { GetEvents } from '../../../actions/Event/Events/EventsAction';
import { GetEventTypes } from '../../../actions/Event/EventType/EventTypeAction';

const useStyles = makeStyles({
  // ".MuiTimelineItem-root": {
  //   padding: 0,
  // },
  root: {
    paddingBottom: 0,
    paddingLeft: 0,
    '&  .MuiTimelineItem-missingOppositeContent': {
      '&::before': {
        flex: 0,
        padding: 0,
      },
      marginBottom: 0,
    },

    '& .MuiTimelineItem-root': {
      minHeight: 'auto',
      padding: 0,
    },
  },
});

interface EventI {
  academic_session: string;
  created_by: string;
  description: string;
  event_type: string;
  event_type_name: string;
  from_date: string;
  general_info: string;
  id: string;
  is_holiday: boolean;
  location: string;
  time: string;
  title: string;
  to_date: string;
}

export default function DashboardEventList() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [event, setEvent] = useState<EventI[]>([]);

  // Events reducer
  const eventsState = useSelector((state: RootStore) => state.events.events);

  useEffect(() => {
    dispatch(GetEvents());
    dispatch(GetEventTypes());
  }, []);

  useEffect(() => {
    if (eventsState.length) {
      setEvent(eventsState);
    }
  }, [eventsState]);

  return (
    <>
      <div style={{ maxHeight: 250, overflow: 'auto' }}>
        {event
          ? event.map((item: EventI, index: number) => (
              <Timeline key={item.id} align="left" className={classes.root}>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot
                      style={{
                        backgroundColor: index % 2 == 0 ? '#2979ff' : '#ff5722',
                        boxShadow: 'none',
                      }}
                    />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Grid container>
                      <Typography
                        align="left"
                        variant="subtitle2"
                        style={{ fontWeight: 500, marginBottom: '2px' }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        align="right"
                        variant="subtitle2"
                        style={{ fontWeight: 500, marginBottom: '2px' }}
                      >
                        {`${item.time ? `at ${timeFormat(item.time)}` : ''}`}
                      </Typography>
                    </Grid>
                    <Typography
                      variant="body1"
                      style={{
                        color: '#555',
                      }}
                    >
                      {fromToConverter(item.from_date, item.to_date)}
                    </Typography>

                    <Typography
                      variant="body1"
                      style={{
                        color: '#555',
                        borderBottom: '1px solid rgba(0,0,0,0.1)',
                        // paddingBottom: "8px",
                        width: '100%',
                      }}
                    >
                      {item.description}
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              </Timeline>
            ))
          : ''}
      </div>
    </>
  );
}
