import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";

import {
  AddStudentHouse,
  UpdateStudentHouse,
} from "../../../actions/Student/StudentHouse/StudentHouseAction";
import FormLayout from "../../Reusable/Layouts/Form.Layout";
import CustomizedTextField from "../../Reusable/Inputs/TextField";

interface FormDataI {
  id: any;
  name: string;
}

interface PropsI {
  editData: FormDataI | null;
}

const StudentHouseForm = (props: PropsI) => {
  const { editData } = props;

  //= ===================================<START> REACT HOOK FORM <START>==========================//
  const { register, handleSubmit, setValue, reset, errors } = useForm({
    mode: "onChange",
  });
  //==================================<START>REDUX REDUCER<START>=============================//

  //= =============================<START> Component States <START>=================================//
  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [editID, setEditID] = useState<string | null>(null);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  //= =============================<END> Component States <END>=================================//

  //==================================<START>REDUX REDUCER<START>=============================//
  const dispatch = useDispatch();
  const studentHouseLoading = useSelector(
    (state: RootStore) => state.student_house.loading
  );
  const add_or_update = useSelector((state: RootStore) => state.student_house.add_or_update)
  //==================================<END>REDUX REDUCER<END>=============================//

  const onFormSubmit = (data: FormDataI) => {
    setIsBtnLoading(true);
    const studentHouseData = {
      ...data,
      house_name: data.name,
    };
    if (editMode) {
      editID != null && dispatch(UpdateStudentHouse(editID, studentHouseData));
    } else {
      dispatch(AddStudentHouse(studentHouseData));
    }
  };

  //===================================<START>CYCLE HOOKS<START>===========================//

  useEffect(() => {
    if (add_or_update) {
      setIsBtnLoading(false)
      handleReset();
    }
  }, [add_or_update])

  useEffect(() => {
    setLoading(studentHouseLoading);
  }, [studentHouseLoading]);

  useEffect(() => {
    editData != null && handleEditTableData(editData);
  }, [editData]);
  //===================================<END>CYCLE HOOKS<END>===========================//

  //= =============================<START> Event Handlers <START>=================================//
  const handleEditTableData = (data: FormDataI) => {
    setEditMode(true);
    setValue("name", data.name);
    setEditID(data.id);
  };
  //= =============================<END> Event Handlers <END>=================================//

  const handleReset = () => {
    setEditMode(false);
    reset();
    setEditID(null);
  };

  return (
    <>
      <FormLayout
        title="Student House"
        onSubmit={handleSubmit(onFormSubmit)}
        editMode={editMode}
        loading={loading}
        onClick={!isBtnLoading && handleReset}
        add_or_update={isBtnLoading}
      >
        <CustomizedTextField
          label="Student House Name"
          placeholder="Student House Name"
          name="name"
          required
          error={errors["name"] ? "Required Field." : ""}
          inputRef={register({ required: true })}
        />
      </FormLayout>
    </>
  );
};

export default StudentHouseForm;
