import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { StudentHouseForm, StudentHouseTable } from '../../components';
import SubModuleLayout from '../../components/Reusable/Layouts/SubModuleLayout';
import AccessPermission from '../../components/Student/StudentDetail/AccessPermission';
// import StudentHouseForm from "../../components/Student/StudentHouse/StudentHouseForm";
// import StudentHouseTable from "../../components/Student/StudentHouse/StudentHouseTable";
import StudentSidebar from '../../components/Student/StudentSidebar/StudentSidebar';
import { breadcrumb } from './Student.Breadcrumbs';

interface EditDataI {
  id: string;
  name: string;
  count?: number;
}

const StudentHousePage = () => {
  const [formData, setFormData] = useState<EditDataI | null>(null);
  const [count, setCount] = useState<number>(0);

  const fetchEditData = (data: EditDataI) => {
    setFormData({ ...data, count: count });
    setCount(count + 1);
  };
  const nav = <StudentSidebar studentHouse />;
  return (
    <SubModuleLayout module={breadcrumb} sideNav={nav}>
      <Grid container>
        <AccessPermission>
          <Grid item xs={3}>
            <StudentHouseForm editData={formData} />
          </Grid>
        </AccessPermission>

        <Grid item xs={9}>
          <StudentHouseTable onEditData={fetchEditData} />
        </Grid>
      </Grid>
    </SubModuleLayout>
  );
};

export default StudentHousePage;
