import axios from "axios";
import { Dispatch } from "redux";

import {
  REPORTSETTING_LOADING,
  REPORTSETTING_ERROR,
  REPORTSETTING_SUCCESS,
  ADD_REPORTSETTING_LOADING,
  ADD_REPORTSETTING_ERROR,
  ADD_REPORTSETTING_SUCCESS,
  UPDATE_REPORTSETTING_LOADING,
  UPDATE_REPORTSETTING_ERROR,
  UPDATE_REPORTSETTING_SUCCESS,
  ReportSettingDispatchTypes,
} from "./ReportSettingActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

interface RequestDataI {
  logo?: any;
  school_name: string;
  address: string;
  phone_number: string;
  email: string;
  slogan: string;
  school_reg_number: string;
}

export const GetReportSetting =
  () =>
  async (dispatch: Dispatch<ReportSettingDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: REPORTSETTING_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/report-setting/`,
        HeaderConfig()
      );
      dispatch({
        type: REPORTSETTING_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: REPORTSETTING_ERROR,
      });
    }
  };

export const AddReportSetting =
  (data: any) =>
  async (dispatch: Dispatch<ReportSettingDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_REPORTSETTING_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/report-setting/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_REPORTSETTING_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Report Setting Saved Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: ADD_REPORTSETTING_ERROR,
      });
    }
  };

export const UpdateReportSetting =
  (id: string | null, data: any) =>
  async (dispatch: Dispatch<ReportSettingDispatchTypes | SetSnackBarI>) => {
    // const formData = {};
    // for (const [key, prop] of data) {
    //   formData[key] = prop;
    // }
    // console.log({ formData });
    try {
      dispatch({
        type: UPDATE_REPORTSETTING_LOADING,
      });

      const res = await axios.put(
        `${ADMIN_API_URL}/report-setting/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_REPORTSETTING_SUCCESS,
        payload: res?.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Report Setting Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: UPDATE_REPORTSETTING_ERROR,
      });
    } finally {
      try {
        dispatch({
          type: REPORTSETTING_LOADING,
        });

        const res = await axios.get(
          `${ADMIN_API_URL}/report-setting/`,
          HeaderConfig()
        );
        dispatch({
          type: REPORTSETTING_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: REPORTSETTING_ERROR,
        });
      }
    }
  };
