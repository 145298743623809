import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { FinanceSidebar, DueReportForm, DueReportList } from "../../components";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const DueReportPage = () => {
    const nav = <FinanceSidebar due_report />;
    return (
        <SubModuleLayout sideNav={nav}>
            <Grid container>
                <Grid item xs={12}>
                    <DueReportForm />
                </Grid>
                <Grid item xs={12}>
                    <DueReportList />
                </Grid>
            </Grid>
        </SubModuleLayout>
    );
};

export default DueReportPage;
