import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, InputLabel, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import SaveIcon from "@material-ui/icons/Save";
import { RootStore } from "../../../../../../store";
import { useStyles } from "../../../../../Styles/FormStyles";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormSaveLoadingButton } from "../../../../../Reusable/Buttons/FormButton";
import Spinner from "../../../../../../common/spinnerSVG/Spinner";
import styled from "styled-components";
import { GetDepartments } from "../../../../../../actions/HumanResource/Department/DepartmentAction";
import { GetDesignations } from "../../../../../../actions/HumanResource/Designation/DesignationAction";
import CustomizedNepaliDatePicker from "../../../../../Reusable/Inputs/NepaliDatePicker";
import {
  dateConverterAdToBs,
  dateConverterBsToAd,
} from "../../../../../utils/dateConverter";
import { updateStaffFaculty } from "../../../../../../rtk/features/humanResource/humanResourceThunk";
import { useParams } from "react-router-dom";

const ModalForm = styled.form`
  padding: 0 20px;
  max-width: 480px;
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 11px;
  margin-left: 10px;
`;

interface StaffFormProps {
  department: { label: string; value: string } | null;
  designation: { label: string; value: string } | null;
  joining_date: string;
  level: string;
}

const FacultyInfoModal = (props: any) => {
  // Custom Styles
  const classes = useStyles();
  const { handleModalClose } = props;
  const { staffID } = useParams<{ staffID: string }>();

  const designationSelector = useSelector(
    (state: RootStore) => state.designation
  );
  const departmentSelector = useSelector(
    (state: RootStore) => state.department
  );

  const { loading, actionCompleted, staffDetail } = useSelector(
    (state: RootStore) => state.humanResource
  );

  // Destructuring react-form-hook methods
  const { register, handleSubmit, errors, control, setValue } =
    useForm<StaffFormProps>({
      mode: "onChange",
    });

  // Dispatch Variable
  const dispatch = useDispatch();

  // State Declaration
  const [joiningDate, setJoiningDate] = useState<string | null>(null);

  useEffect(() => {
    dispatch(GetDepartments());
    dispatch(GetDesignations());
  }, []);

  useEffect(() => {
    if (actionCompleted) {
      handleModalClose();
    }
  }, [actionCompleted]);

  useEffect(() => {
    if (staffDetail?.staff_faculty_info) {
      setValue("level", staffDetail.staff_faculty_info.level || "");
      setValue(
        "department",
        staffDetail.staff_faculty_info.department
          ? {
              label: staffDetail.staff_faculty_info.department.name,
              value: staffDetail.staff_faculty_info.department.id,
            }
          : null
      );
      setValue(
        "designation",
        staffDetail.staff_faculty_info.designation
          ? {
              label: staffDetail.staff_faculty_info.designation.name,
              value: staffDetail.staff_faculty_info.designation.id,
            }
          : null
      );
      if (staffDetail.staff_faculty_info.date_of_joining) {
        const enDate = dateConverterAdToBs(
          staffDetail.staff_faculty_info.date_of_joining
        );
        setJoiningDate(enDate);
      }
    }
  }, [staffDetail]);

  const onSubmitModal: SubmitHandler<StaffFormProps> = (data) => {
    dispatch(
      updateStaffFaculty({
        department: data.department!.value,
        designation: data.designation!.value,
        date_of_joining: joiningDate ? dateConverterBsToAd(joiningDate) : null,
        level: data.level,
        id: staffID,
      })
    );
  };

  return (
    <ModalForm onSubmit={handleSubmit(onSubmitModal)}>
      <Grid container>
        <Grid
          item
          xs={12}
          className={classes.formWrapper}
          style={{ marginBottom: "1rem" }}
        >
          <InputLabel>
            Department <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <Controller
            name="department"
            control={control}
            rules={{
              required: { value: true, message: "Department is required" },
            }}
            render={({ onChange }) => (
              <Autocomplete
                onChange={(_, data) => onChange(data)}
                options={departmentSelector.departments.map((el) => ({
                  label: el.name,
                  value: el.id,
                }))}
                defaultValue={
                  staffDetail?.staff_faculty_info?.department
                    ? {
                        label: staffDetail.staff_faculty_info.department.name,
                        value: staffDetail.staff_faculty_info.department.id,
                      }
                    : null
                }
                getOptionLabel={(option) => option.label}
                openOnFocus={true}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="department"
                    placeholder="Select Department"
                    variant="outlined"
                  />
                )}
              />
            )}
          />
          <ErrorMessage>{errors.department?.message}</ErrorMessage>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.formWrapper}
          style={{ marginBottom: "1rem" }}
        >
          <InputLabel>
            Designation <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <Controller
            name="designation"
            control={control}
            rules={{
              required: { value: true, message: "Designation is required" },
            }}
            render={({ onChange }) => (
              <Autocomplete
                onChange={(_, data) => onChange(data)}
                options={designationSelector.designations.map((el) => ({
                  label: el.name,
                  value: el.id,
                }))}
                defaultValue={
                  staffDetail?.staff_faculty_info?.designation
                    ? {
                        label: staffDetail.staff_faculty_info.designation.name,
                        value: staffDetail.staff_faculty_info.designation.id,
                      }
                    : null
                }
                getOptionLabel={(option) => option.label}
                openOnFocus={true}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="designation"
                    placeholder="Select Designation"
                    variant="outlined"
                  />
                )}
              />
            )}
          />
          <ErrorMessage>{errors.designation?.message}</ErrorMessage>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.formWrapper}
          style={{ marginBottom: "1rem" }}
        >
          <InputLabel>Joining Date</InputLabel>
          <CustomizedNepaliDatePicker
            label=""
            value={joiningDate}
            setValue={(val: any) => setJoiningDate(val)}
          />
        </Grid>

        <Grid
          item
          xs={12}
          className={classes.formWrapper}
          style={{ marginBottom: "1rem" }}
        >
          <InputLabel>Level</InputLabel>
          <TextField
            variant="outlined"
            name="level"
            placeholder="Level"
            inputRef={register}
          />
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="flex-start"
        style={{ marginBottom: "1em" }}
      >
        <FormSaveLoadingButton
          type="submit"
          icon={loading ? <Spinner /> : <SaveIcon />}
          disabled={loading ? true : false}
          text={loading ? "Saving..." : "Save"}
        />
      </Grid>
    </ModalForm>
  );
};

export default FacultyInfoModal;
