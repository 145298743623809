import React, { useEffect, useState } from "react";
import { useTableStyles } from "../../Styles/TableStyles";
import {
  Paper,
  Table,
  TableBody,
  TableHead,
  TextField,
} from "@material-ui/core";
import styled from "styled-components";
import { useTable } from "../../Reusable";
import { ItemViewButton } from "../../Reusable/Buttons/TableButton";
import { Link } from "react-router-dom";
import {
  NepaliCalendar,
  getBsDayName,
  getBsDaysInMonth,
  getCurrentBsDate,
} from "../../utils/nepalicalendar";
import Select from "react-select";
import { selectStyles } from "./AttendanceDetailsStyles";
import {
  ReportContainer,
  ReportRight,
  ReportLeft,
  ReportTable,
  ReportHeader,
  ReportBody,
  ReportCard,
  HeaderInfo,
  StaffNameList,
} from "./AttendanceReportStyles";
import { useDispatch, useSelector } from "react-redux";
import { getAttendanceReport } from "../../../rtk/features/humanResource/humanResourceThunk";
import { RootStore } from "../../../store";
import { dateConverterBsToAd } from "../../utils/dateConverter";
import moment from "moment";
import { getHolidayEvent } from "../../utils/publicHolidays";
const TableTitle = styled.h2`
  font-size: 20px;
  font-weight: 500;
  color: #2e2e2e;
`;

const TableHeader = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;

  gap: 40px;

  p {
    span {
      font-weight: 500;
    }
  }
`;

const SearchInput = styled(TextField)`
  && {
    .MuiOutlinedInput-root {
      margin: 0;
    }
    input {
      padding: 10px 16px;
      background-color: #f2f2f2;
    }
  }
`;

const headCells = [
  { id: "sn", label: "S.N" },
  { id: "name", label: "Staff Name" },
  { id: "department", label: "Department" },
  { id: "designation", label: "Designation" },
  { id: "checkinOut", label: "Check In / Check Out" },
  { id: "action", label: "Action" },
];

const orderedDays = [
  { id: 1, name: "Sunday" },
  { id: 2, name: "Monday" },
  { id: 3, name: "Tuesday" },
  { id: 4, name: "Wednesday" },
  { id: 5, name: "Thursday" },
  { id: 6, name: "Friday" },
  { id: 7, name: "Saturday" },
];

const orderedMonths = [
  { id: 1, name: "Baisakh" },
  { id: 2, name: "Jestha" },
  { id: 3, name: "Asar" },
  { id: 4, name: "Shrawan" },
  { id: 5, name: "Bhadra" },
  { id: 6, name: "Asoj" },
  { id: 7, name: "Karthik" },
  { id: 8, name: "Mangsir" },
  { id: 9, name: "Poush" },
  { id: 10, name: "Magh" },
  { id: 11, name: "Falgun" },
  { id: 12, name: "Chaitra" },
];

const returnNepaliDateString = (year: number, month: number, day: number) => {
  return `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(
    2,
    "0"
  )}`;
};

const checkBeforeDate = (nepDate: string) => {
  const providedDate = dateConverterBsToAd(nepDate);
  const today = moment(new Date()).format("YYYY-MM-DD");

  const before = moment(providedDate).isBefore(today);

  return before;
};

const AttendanceReport = () => {
  const tableClasses = useTableStyles();
  const dispatch = useDispatch();

  const { TblContainer, StyledTableCell, StyledTableRow } = useTable(headCells);

  const [searchName, setSearchName] = useState<string>("");

  const [selectedMonth, setSelectedMonth] = useState<{
    label: string;
    value: number;
  } | null>(null);

  const [allDays, setAllDays] = useState<
    {
      id: number;
      day: string;
      date: number;
      month: number;
      year: number;
      holiday: string | null;
    }[]
  >([]);

  const [employeesList, setEmployeesList] = useState<
    { id: string; name: string }[]
  >([]);

  const { loading, attendanceReport } = useSelector(
    (state: RootStore) => state.humanResource
  );

  useEffect(() => {
    const currentMonth = orderedMonths.find(
      (el) => el.id === getCurrentBsDate().month
    );

    setSelectedMonth({ label: currentMonth!.name, value: currentMonth!.id });
  }, []);

  useEffect(() => {
    if (selectedMonth) {
      const noOfDays = getBsDaysInMonth(
        getCurrentBsDate().year,
        selectedMonth.value
      );

      dispatch(
        getAttendanceReport({
          from_date: `${getCurrentBsDate().year}-${String(
            selectedMonth.value
          ).padStart(2, "0")}-01`,
          to_date: `${getCurrentBsDate().year}-${String(
            selectedMonth.value
          ).padStart(2, "0")}-${noOfDays}`,
        })
      );

      const cards: {
        id: number;
        day: string;
        date: number;
        month: number;
        year: number;
        holiday: string | null;
      }[] = [];

      const initialIndex: number = orderedDays.find(
        (el) =>
          el.name ===
          getBsDayName({
            year: getCurrentBsDate().year,
            month: selectedMonth.value,
            day: 1,
          })
      )!.id;

      for (let i = 1; i <= noOfDays; i++) {
        const startingIndex = initialIndex + i - 1;
        const currentIndex =
          startingIndex - Math.floor(startingIndex / 7) * 7 || 7;

        cards.push({
          id: i,
          day: orderedDays[currentIndex - 1].name,
          date: i,
          month: selectedMonth.value,
          year: getCurrentBsDate().year,
          holiday: getHolidayEvent({
            year: getCurrentBsDate().year,
            month: selectedMonth.value,
            day: i,
          }),
        });
      }

      setAllDays(cards);
    }
  }, [selectedMonth]);

  useEffect(() => {
    if (attendanceReport.length) {
      setEmployeesList(
        attendanceReport.map((el) => ({ id: el.employeeId, name: el.name }))
      );
    }
  }, [attendanceReport]);

  const getAttendanceDetails = ({
    empId,
    date,
  }: {
    empId: string;
    date: string;
  }) => {
    const emp = attendanceReport.find((el) => el.employeeId === empId);
    if (emp) {
      const record = emp.attendance.find((el) => el.date === date);

      if (record) {
        return {
          checkIn: record.checkIn,
          checkOut: record.checkout,
          onLeave: record.leave,
          absent: false,
        };
      } else {
        const pastDate = checkBeforeDate(date);

        if (pastDate) {
          return {
            checkIn: "00:00",
            checkOut: "00:00",
            onLeave: false,
            absent: true,
          };
        }
      }

      return {
        checkIn: "00:00",
        checkOut: "00:00",
        onLeave: false,
        absent: false,
      };
    }
    return {
      checkIn: "00:00",
      checkOut: "00:00",
      onLeave: false,
      absent: false,
    };
  };

  const handleMonthChange = (val: any) => {
    setSelectedMonth(val);
  };

  return (
    <Paper className={tableClasses.root}>
      <TableTitle>Staff Attendance Today</TableTitle>
      <TableHeader>
        <Select
          value={selectedMonth}
          options={orderedMonths.map((el) => ({
            label: el.name,
            value: el.id,
          }))}
          styles={selectStyles}
          placeholder="Select Month"
          onChange={(val) => handleMonthChange(val)}
        />
        <SearchInput
          variant="outlined"
          placeholder="Search By Name"
          value={searchName}
          onChange={(e) => setSearchName(e.target.value)}
        />
        <p>
          Shift: <span>General - 10:00 AM to 4:00 PM</span>
        </p>
      </TableHeader>

      <ReportContainer>
        <ReportRight>
          <HeaderInfo>
            <span>Month Name</span>
          </HeaderInfo>
          <StaffNameList>
            {employeesList
              .filter((el) =>
                el.name.toLowerCase().startsWith(searchName.toLowerCase())
              )
              .map((el) => (
                <li key={el.id}>
                  <Link to={`/attendance-report/${el.id}`}>{el.name}</Link>
                </li>
              ))}
          </StaffNameList>
        </ReportRight>
        <ReportLeft>
          <ReportTable>
            <ReportHeader>
              {allDays.map((el) => (
                <span key={el.id}>
                  {el.day} {el.id}
                </span>
              ))}
            </ReportHeader>
            <ReportBody>
              {employeesList
                .filter((el) =>
                  el.name.toLowerCase().startsWith(searchName.toLowerCase())
                )
                .map((emp) => {
                  return (
                    <div key={emp.id}>
                      {allDays.map((el, index) => {
                        if (el.day === "Saturday") {
                          return (
                            <ReportCard
                              key={index + 1}
                              checkIn="-"
                              checkOut="-"
                              dayStatus={{ type: "Week Off" }}
                            />
                          );
                        } else if (el.holiday) {
                          return (
                            <ReportCard
                              key={index + 1}
                              checkIn="-"
                              checkOut="-"
                              dayStatus={{
                                type: "Public Holiday",
                                event: el.holiday,
                              }}
                            />
                          );
                        } else if (
                          getAttendanceDetails({
                            empId: emp.id,
                            date: returnNepaliDateString(
                              el.year,
                              el.month,
                              el.date
                            ),
                          }).onLeave
                        ) {
                          return (
                            <ReportCard
                              key={index + 1}
                              checkIn="-"
                              checkOut="-"
                              dayStatus={{
                                type: "Leave",
                              }}
                            />
                          );
                        } else if (
                          getAttendanceDetails({
                            empId: emp.id,
                            date: returnNepaliDateString(
                              el.year,
                              el.month,
                              el.date
                            ),
                          }).absent
                        ) {
                          return (
                            <ReportCard
                              key={index + 1}
                              checkIn="-"
                              checkOut="-"
                              dayStatus={{
                                type: "Absent",
                              }}
                            />
                          );
                        } else {
                          return (
                            <ReportCard
                              key={index + 1}
                              checkIn={
                                getAttendanceDetails({
                                  empId: emp.id,
                                  date: returnNepaliDateString(
                                    el.year,
                                    el.month,
                                    el.date
                                  ),
                                }).checkIn
                              }
                              checkOut={
                                getAttendanceDetails({
                                  empId: emp.id,
                                  date: returnNepaliDateString(
                                    el.year,
                                    el.month,
                                    el.date
                                  ),
                                }).checkOut
                              }
                              dayStatus={null}
                            />
                          );
                        }
                      })}
                    </div>
                  );
                })}
            </ReportBody>
          </ReportTable>
        </ReportLeft>
      </ReportContainer>
    </Paper>
  );
};

export default AttendanceReport;
