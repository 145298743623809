// -----------------<START> import modules starts----------------------//
import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { useFormStyles } from '../../Styles/FormStyles';
import Popups from '../../Reusable/Dialogs/Popups';
import 'nepali-datepicker-reactjs/dist/index.css';
import LeaveRequestModal from './LeaveRequestModal';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { GetSearchedLeaves } from '../../../actions/HumanResource/Leave/LeaveAction';
import CustomizedNepaliDatePicker from '../../Reusable/Inputs/NepaliDatePicker';
import FormButton from '../../Reusable/Buttons/FormButton';
import { dateConverterBsToAd } from '../../utils/dateConverter';
import { CUR_NEPALI_DATE } from '../../utils/nepaliDateUtils';
import { SettingsBackupRestore } from '@material-ui/icons';
import { GetLeaves } from '../../../actions/Student/StudentLeave/LeaveAction';
import styled from 'styled-components';
import AddIcon from '@material-ui/icons/Add';

// -----------------<END> imports module ends -----------------------//

const StyledButton = styled(Button)`
  width: max-content !important;
`;

// -----------------<START> Leave Search component starts-----------------------//
const LeaveSearch = () => {
  const dispatch = useDispatch();
  const classes = useFormStyles();
  const { register, handleSubmit, errors } = useForm();

  const [fromDate, setFromDate] = useState<string | null>(CUR_NEPALI_DATE);
  const [toDate, setToDate] = useState<string | null>(CUR_NEPALI_DATE);
  const [openPopup, setOpenPopup] = useState(false);

  const onSubmit = (data: any) => {
    const params = {
      perPage: 5,
      page: 1,
      leaveFrom: (fromDate && dateConverterBsToAd(fromDate)) || '',
      leaveTo: (toDate && dateConverterBsToAd(toDate)) || '',
    };

    console.log('query', params);
    dispatch(GetLeaves(params));

    // dispatch(GetSearchedLeaves(query));
  };

  const handleClose = (value: boolean) => {
    setOpenPopup(value);
  };

  const handleReset = () => {
    setFromDate(CUR_NEPALI_DATE);
    setToDate(CUR_NEPALI_DATE);
    const params = { perPage: 5, page: 1 };
    dispatch(GetLeaves(params));
  };

  return (
    <Paper className={classes.pageAttachContent}>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <Grid container justifyContent="space-between">
          <Grid item className={classes.formTitle} md={6}>
            <Typography variant="h6" style={{ fontWeight: 500, fontSize: 18 }}>
              Student Leave Requests
            </Typography>
          </Grid>

          <Grid item container spacing={2} md={6} justifyContent="flex-end">
            <Grid item>
              <Tooltip title="Clear Search Query">
                <IconButton
                  aria-label="delete"
                  style={{ padding: '2px' }}
                  onClick={handleReset}
                >
                  <SettingsBackupRestore />
                </IconButton>
              </Tooltip>
            </Grid>

            {/* <Grid item>
              <StyledButton
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
              >
                Create Student Leave
              </StyledButton>
            </Grid> */}
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={3} className={classes.formWrapper}>
            <CustomizedNepaliDatePicker
              label="From"
              value={fromDate}
              setValue={setFromDate}
            />
          </Grid>

          <Grid item xs={3} className={classes.formWrapper}>
            <CustomizedNepaliDatePicker
              label="To"
              value={toDate}
              setValue={setToDate}
            />
          </Grid>

          <Grid
            item
            xs={3}
            className={classes.formWrapper}
            style={{ justifyContent: 'flex-end' }}
          >
            <FormButton
              startIcon={<Search />}
              className={classes.searchBoxButton}
            >
              Search
            </FormButton>
          </Grid>
        </Grid>
      </form>

      <Popups
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        onClose={handleClose}
        title="Request Leave"
        maxWidth="sm"
      >
        <LeaveRequestModal onClose={handleClose} />
      </Popups>
    </Paper>
  );
};

//-----------------<ENDS> Leave Search component ends-----------------------//
export default LeaveSearch;
