// ---------------------------- <START> module imports start ----------------------------------//
import React, { useEffect, useState } from "react";
import { TableBody, makeStyles, Theme } from "@material-ui/core";
import { PrintTableStyles } from "../../Styles/PrintTableStyles";
import { useTablePrint } from "../../Reusable";
import { MarkSheetTypeI } from "../../../actions/Examination/MarkSheet/MarkSheetActionTypes";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//

interface HeadCellsI {
  id: string;
  label: string;
}
// ---------------------------- <END> interface ends ----------------------------------//

interface HeadCellsI {
  id: string;
  label: string;
}

// -------------------- <START> Table Headings Data ---------------------------//
const headCells: HeadCellsI[] = [
  { id: "ape_bases", label: "APE Bases" },
  { id: "excellent", label: "Excellent" },
  { id: "good", label: "Good" },
  { id: "poor", label: "Poor" },
];
// ---------------------<END> Table Headings Data Ends --------------------------//

const useStyles = makeStyles((theme: Theme) => ({
  tableCell: {
    padding: "0px 5px !important",
    borderBottom: "1px solid #D6D6D6",
    borderRight: "1px solid #D6D6D6",
  },
}));

interface propsI {
  editMode: boolean;
}

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const ApeTable = (props: propsI) => {
  const classes = PrintTableStyles();

  // States declaration
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<MarkSheetTypeI[]>([]);

  // Retrieving re-usable components from useTable
  const {
    TblContainer,
    TblHead,
    StyledTableCell,
    StyledTableRow,
    exportToExcel,
    tablePrint,
  } = useTablePrint(headCells);

  // ============================ <START> REACT HOOKS <START> ============================== //

  const dummyData = [
    {
      ape_bases: "Discipline",
      excellent: true,
      good: "",
      poor: "",
    },
    {
      ape_bases: "Unifrom",
      excellent: "",
      good: true,
      poor: "",
    },
    {
      ape_bases: "Hygiene",
      excellent: true,
      good: "",
      poor: "",
    },
    {
      ape_bases: "H/W Submit",
      excellent: "",
      good: "",
      poor: true,
    },
    {
      ape_bases: "Class Behaviour",
      excellent: "",
      good: "",
      poor: true,
    },
    {
      ape_bases: "ECA Participate",
      excellent: "",
      good: true,
      poor: "",
    },
    {
      ape_bases: "Punctuality",
      excellent: true,
      good: "",
      poor: "",
    },
    {
      ape_bases: "Class Interaction",
      excellent: true,
      good: "",
      poor: "",
    },
    {
      ape_bases: "Creativity",
      excellent: "",
      good: true,
      poor: "",
    },
  ];
  // ============================ <END> REACT HOOKS <END> ============================== //

  // ============================ <START> EVENT HANDLERS <START> ============================== //

  // Event function for table searching by name
  const handleTableSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    return;
  };

  // ============================ <END> EVENT HANDLERS <END> ============================== //

  return (
    <>
      <div className={classes.tableWrapper} style={{ marginTop: "15px" }}>
        <TblContainer size="small">
          <TblHead />
          <TableBody>
            {dummyData.map((item: any, index: number) => (
              <StyledTableRow key={index}>
                <StyledTableCell align="left" className={classes.cell}>
                  <b>{item.ape_bases}</b>
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  className={classes.cell}
                  style={{ textAlign: "center" }}
                >
                  {item.excellent ? (
                    <CheckCircleOutlineOutlinedIcon
                      style={{ fontSize: "12px" }}
                    />
                  ) : (
                    ""
                  )}
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  className={classes.cell}
                  style={{ textAlign: "center" }}
                >
                  {item.good ? (
                    <CheckCircleOutlineOutlinedIcon
                      style={{ fontSize: "12px" }}
                    />
                  ) : (
                    ""
                  )}
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  className={classes.cell}
                  style={{ textAlign: "center" }}
                >
                  {item.poor ? (
                    <CheckCircleOutlineOutlinedIcon
                      style={{ fontSize: "12px" }}
                    />
                  ) : (
                    ""
                  )}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </TblContainer>
      </div>
    </>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default ApeTable;
