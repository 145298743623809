import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { DiscountTypeI } from "../../actions/Fees/DiscountType/DiscountActionTypes";
import { FinanceSidebar } from "../../components";
import DiscountTypeForm from "../../components/Finance/DiscountType/DiscountTypeForm";
import DiscountTypeTable from "../../components/Finance/DiscountType/DiscountTypeTable";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const DiscountTypePage = () => {
  const [formData, setFormData] = useState<DiscountTypeI | null>(null);
  const [count, setCount] = useState<number>(0);
  const [edit, setEdit] = useState<any>();

  const fetchEditData = (data: DiscountTypeI) => {
    setFormData({ ...data, count: count });
    setCount(count + 1);
  };

  const onEditCheck = (value: boolean) => {
    setEdit({ check: value, count: count });
  };

  const nav = <FinanceSidebar discount_type />;
  return (
    <SubModuleLayout FeesMenuActive sideNav={nav}>
      <Grid container>
        <Grid item xs={3}>
          <DiscountTypeForm onEditCheck={onEditCheck} editData={formData} />
        </Grid>
        <Grid item xs={9}>
          <DiscountTypeTable editCheck={edit} onEditData={fetchEditData} />
        </Grid>
      </Grid>
    </SubModuleLayout>
  );
};

export default DiscountTypePage;
