import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_STUDENT_HOUSE_LOADING,
  GET_STUDENT_HOUSE_ERROR,
  GET_STUDENT_HOUSE_SUCCESS,
  StudentHouseDispatchTypes,
  ADD_STUDENT_HOUSE_LOADING,
  ADD_STUDENT_HOUSE_SUCCESS,
  ADD_STUDENT_HOUSE_ERROR,
  DELETE_STUDENT_HOUSE_SUCCESS,
  DELETE_STUDENT_HOUSE_ERROR,
  DELETE_STUDENT_HOUSE_LOADING,
  UPDATE_STUDENT_HOUSE_LOADING,
  UPDATE_STUDENT_HOUSE_SUCCESS,
  UPDATE_STUDENT_HOUSE_ERROR,
} from "./StudentHouseActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

interface RequestDataI {
  id: string;
  name: string;
}

export const GetStudentHouses = () => async (
  dispatch: Dispatch<StudentHouseDispatchTypes | SetSnackBarI>
) => {
  try {
    dispatch({
      type: GET_STUDENT_HOUSE_LOADING,
    });

    const res = await axios.get(`${ADMIN_API_URL}/house/`, HeaderConfig());

    dispatch({
      type: GET_STUDENT_HOUSE_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_STUDENT_HOUSE_ERROR,
    });

    dispatch({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarType: "error",
        snackbarMessage: "Student House Loading Failed",
      },
    });
  }
};
export const AddStudentHouse = (data: RequestDataI) => async (
  dispatch: Dispatch<StudentHouseDispatchTypes | SetSnackBarI>
) => {
  try {
    dispatch({
      type: ADD_STUDENT_HOUSE_LOADING,
    });

    const res = await axios.post(
      `${ADMIN_API_URL}/house/`,
      data,
      HeaderConfig()
    );

    dispatch({
      type: ADD_STUDENT_HOUSE_SUCCESS,
      payload: res.data,
    });

    dispatch({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarType: "success",
        snackbarMessage: "Student House Added Successfully",
      },
    });
  } catch (error) {
    dispatch({
      type: ADD_STUDENT_HOUSE_ERROR,
    });

    dispatch({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarType: "error",
        snackbarMessage: "Student House Add Failed",
      },
    });
  }
};

export const UpdateStudentHouse = (id: string, data: RequestDataI) => async (
  dispatch: Dispatch<StudentHouseDispatchTypes | SetSnackBarI>
) => {
  try {
    dispatch({
      type: UPDATE_STUDENT_HOUSE_LOADING,
    });

    const res = await axios.put(
      `${ADMIN_API_URL}/house/${id}/`,
      data,
      HeaderConfig()
    );

    dispatch({
      type: UPDATE_STUDENT_HOUSE_SUCCESS,
      payload: res.data,
    });

    dispatch({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarType: "success",
        snackbarMessage: "Student House Updated Successfully",
      },
    });
  } catch (error) {
    dispatch({
      type: UPDATE_STUDENT_HOUSE_ERROR,
    });

    dispatch({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarType: "error",
        snackbarMessage: "Student House Update Failed",
      },
    });
  }
};

export const DeleteStudentHouse = (id: string) => async (
  dispatch: Dispatch<StudentHouseDispatchTypes | SetSnackBarI>
) => {
  try {
    dispatch({
      type: DELETE_STUDENT_HOUSE_LOADING,
    });

    const res = await axios.delete(
      `${ADMIN_API_URL}/house/${id}/`,
      HeaderConfig()
    );

    dispatch({
      type: DELETE_STUDENT_HOUSE_SUCCESS,
      payload: { id: id },
    });

    dispatch({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarType: "success",
        snackbarMessage: "Student House Deleted Successfully",
      },
    });
  } catch (error) {
    dispatch({
      type: DELETE_STUDENT_HOUSE_ERROR,
    });

    dispatch({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarType: "error",
        snackbarMessage: "Student House Delete Failed",
      },
    });
  }
};
