import {
  GET_LOCATION_LOADING,
  GET_LOCATION_ERROR,
  GET_LOCATION_SUCCESS,
  LocationDispatchTypes,
  ADD_LOCATION_LOADING,
  ADD_LOCATION_ERROR,
  ADD_LOCATION_SUCCESS,
  DELETE_LOCATION_LOADING,
  DELETE_LOCATION_ERROR,
  DELETE_LOCATION_SUCCESS,
  UPDATE_LOCATION_LOADING,
  UPDATE_LOCATION_ERROR,
  UPDATE_LOCATION_SUCCESS,
  LocationTypeI,
} from '../../actions/Transport/Location/LocationActionTypes';

interface InitialStateI {
  loading: boolean;
  locations: any;
  error: any;
  recent?: boolean;
  add_or_update: boolean;
}

const initialState: InitialStateI = {
  loading: false,
  locations: null,
  error: null,
  recent: false,
  add_or_update: false,
};

const LocationReducer = (
  state: InitialStateI = initialState,
  action: LocationDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_LOCATION_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_LOCATION_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        locations: action.payload,
        error: null,
        recent: false,
      };

    case ADD_LOCATION_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case ADD_LOCATION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        recent: false,
        add_or_update: true,
      };

    case ADD_LOCATION_SUCCESS:
      return {
        loading: false,
        locations: [...state.locations.results, action.payload],
        error: null,
        recent: true,
        add_or_update: true,
      };

    case UPDATE_LOCATION_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case UPDATE_LOCATION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        recent: false,
        add_or_update: true,
      };

    case UPDATE_LOCATION_SUCCESS:
      const updateResults = state.locations.results.map((el: LocationTypeI) => {
        if (el.id === action.payload.id) {
          return action.payload;
        }
        return el;
      });

      return {
        loading: false,
        locations: { ...state.locations, results: updateResults },
        error: null,
        recent: true,
        add_or_update: true,
      };

    case DELETE_LOCATION_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_LOCATION_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_LOCATION_SUCCESS:
      const results = state.locations.results.filter(
        (el: LocationTypeI) => el.id !== action.payload.id
      );

      return {
        ...state,
        loading: false,
        locations: { ...state.locations, results: results },
        error: null,
      };

    default:
      return state;
  }
};

export default LocationReducer;
