// ---------------- <START> module import ------------------//
import React, { useState, useEffect } from "react";
import { Button, Dialog, Paper, Typography } from "@material-ui/core";
import { useTable, useTableWithPagination } from "../../Reusable";
import { useTableStyles } from "../../Styles/TableStyles";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import {
  GetDepartments,
  DeleteDepartment,
} from "../../../actions/HumanResource/Department/DepartmentAction";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import { DepartmentTypeI } from "../../../actions/HumanResource/Department/DepartmentActionTypes";
import { HeadCellsI } from "../../../actions";
import {
  ItemDeleteButton,
  ItemEditButton,
  ItemPrintButton,
  ItemViewButton,
} from "../../Reusable/Buttons/TableButton";
import {
  GetDaybook,
  GetInvoiceDetail,
} from "../../../actions/Fees/Daybook/DaybookAction";
import getFullName from "../../utils/getFullName";
import getFullClassName from "../../utils/getFullClassName";
import FeeInvoice from "../CollectFees/FeeInvoice";
import { GetGeneralInfoData } from "../../../actions/GeneralSettings/GeneralInfo/GeneralInfoAction";
import { dateConverterAdToBs } from "../../utils/dateConverter";
import { GetIntimationSlip } from "../../../actions/Fees/IntimationSlip/IntimationSlipAction";
import IntimateInvoice from "./IntimateInvoice";

// ---------------- <START> Head Cells ------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N." },
  // { id: "invoice_no", label: "Invoice No" },
  { id: "student_name", label: "Student Name" },
  { id: "class", label: "Class" },
  { id: "fee_amount", label: "Fee Amount" },
];
// ---------------- <START> Head Cells ------------------//

// ---------------- <START> Department Table Component ------------------//
const IntimateFeeTable = (props: any) => {
  const { grade, section, startDate, endDate, month } = props;

  const classes = useTableStyles();
  const dispatch = useDispatch();

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTableWithPagination(headCells);

  const [tableData, setTableData] = useState<DepartmentTypeI[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);
  const departmentState = useSelector((state: RootStore) => state.department);
  const intimateState = useSelector(
    (state: RootStore) => state.intimation_slip
  );
  const loading = useSelector(
    (state: RootStore) => state.intimation_slip.loading
  );
  const [openInvoice, setOpenInvoice] = useState(false);

  const generalInfoData = useSelector(
    (state: RootStore) => state.general_info?.general_info
  );
  const authData = useSelector((state: RootStore) => state.auth);

  useEffect(() => {
    dispatch(GetDaybook(1, "", "", "", ""));
  }, []);

  useEffect(() => {
    if (itemId !== null) {
      dispatch(GetInvoiceDetail(itemId));
    }
  }, [itemId]);

  // useEffect(() => {
  //   if (authData.general_info != null) {
  //     dispatch(GetGeneralInfoData(authData.general_info.id));
  //   }
  // }, [authData]);

  useEffect(() => {
    if (intimateState?.intimation_slips !== []) {
      setTableData(
        intimateState?.intimation_slips?.results?.filter(
          (elem: any) => elem.student_invoices?.length > 0
        )
      );
    }
  }, [intimateState]);

  const handleCloseInvoice = () => {
    setOpenInvoice(false);
  };

  const handleViewModal = (id: string) => {
    setOpenInvoice(true);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteDepartment(itemId));
    setDeleteModalOpen(false);
  };

  const handlePaginationChange = (paginationIndex: number) => {
    dispatch(
      GetIntimationSlip(
        paginationIndex,
        grade?.grade || "",
        section?.id || "",
        month?.key || "",
        startDate || "",
        endDate || ""
      )
    );
  };

  const getIntimateTotalAmount = (data: any) => {
    let invoice_ = 0;
    let due_ = 0;

    data?.student_invoices?.map((elem: any) => {
      return (invoice_ += Number(elem?.fee_type?.grade_fee_amount?.amount));
    });
    data?.dues?.map((elem: any) => {
      return (due_ += Number(elem?.due_amount));
    });

    return invoice_ + due_;
  };

  const getCustomTableRow = (item: any, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index}
        </StyledTableCell>
        {/* <StyledTableCell align="center" className={classes.cell}>
          {item.invoice_id}
        </StyledTableCell> */}
        <StyledTableCell align="center" className={classes.cell}>
          {getFullName(
            item.student?.student_user?.first_name,
            item.student?.student_user?.last_name
          )}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {getFullClassName(grade?.grade_name, section?.name)}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          Rs. {getIntimateTotalAmount(item)}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <div style={{ padding: "15px" }}>
      <Typography variant="h6">Slip List</Typography>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenInvoice(true)}
          disabled={tableData?.length > 0 ? false : true}
        >
          Print Slip
        </Button>
      </div>
      <TableContainer
        label="Invoice"
        loading={loading}
        items={tableData}
        headCells={headCells}
        getCustomTableRow={getCustomTableRow}
        handlePaginationChange={handlePaginationChange}
        forPagination={intimateState?.intimation_slips || []}
        disableSearch
        disablePrint
      />
      <Dialog
        open={openInvoice}
        onClose={handleCloseInvoice}
        // aria-labelledby="form-dialog-title"
        // maxWidth="sm"
        // fullWidth
        // className={classes.modalBox}
      >
        <IntimateInvoice
          invoiceData={tableData}
          month={month}
          grade={grade}
          section={section}
        />
      </Dialog>
    </div>
  );
};
// ---------------- <START> Department Table Component ------------------//

export default IntimateFeeTable;
