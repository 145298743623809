export const GET_INTIMATION_SLIP_LOADING = "GET_INTIMATION_SLIP_LOADING";
export const GET_INTIMATION_SLIP_ERROR = "GET_INTIMATION_SLIP_ERROR";
export const GET_INTIMATION_SLIP_SUCCESS = "GET_INTIMATION_SLIP_SUCCESS";

export const GET_INVOICE_DETAIL_LOADING = "GET_INVOICE_DETAIL_LOADING";
export const GET_INVOICE_DETAIL_ERROR = "GET_INVOICE_DETAIL_ERROR";
export const GET_INVOICE_DETAIL_SUCCESS = "GET_INVOICE_DETAIL_SUCCESS";

export const CLEAR_INTIMATION_SLIP = "CLEAR_INTIMATION_SLIP";

export interface ClearIntimationSlip {
  type: typeof CLEAR_INTIMATION_SLIP;
}
export interface GetIntimationSlipLoading {
  type: typeof GET_INTIMATION_SLIP_LOADING;
}

export interface GetIntimationSlipError {
  type: typeof GET_INTIMATION_SLIP_ERROR;
}

export interface GetIntimationSlipSuccess {
  type: typeof GET_INTIMATION_SLIP_SUCCESS;
  payload: any;
}

export interface GetInvoiceDetailLoading {
  type: typeof GET_INVOICE_DETAIL_LOADING;
}

export interface GetInvoiceDetailError {
  type: typeof GET_INVOICE_DETAIL_ERROR;
}

export interface GetInvoiceDetailSuccess {
  type: typeof GET_INVOICE_DETAIL_SUCCESS;
  payload: any;
}

export type IntimationSlipDispatchTypes =
  | GetIntimationSlipLoading
  | GetIntimationSlipError
  | GetIntimationSlipSuccess
  | GetInvoiceDetailLoading
  | GetInvoiceDetailError
  | GetInvoiceDetailSuccess
  | ClearIntimationSlip;
