import {
  GET_APE_LOADING,
  GET_APE_ERROR,
  GET_APE_SUCCESS,
  ADD_APE_LOADING,
  ADD_APE_ERROR,
  ADD_APE_SUCCESS,
  DELETE_APE_LOADING,
  DELETE_APE_ERROR,
  DELETE_APE_SUCCESS,
  UPDATE_APE_LOADING,
  UPDATE_APE_ERROR,
  UPDATE_APE_SUCCESS,
  APETypeI,
  APEDispatchTypes,
} from "../../actions/Examination/APE/APEActionTypes";

interface InitialStateI {
  loading: boolean;
  apes: APETypeI[];
  errors: any;
  recent?: boolean;
  add_or_update: boolean;
}

const initialState: InitialStateI = {
  loading: false,
  apes: [],
  errors: null,
  recent: false,
  add_or_update: false,
};

const APEreducer = (
  state: InitialStateI = initialState,
  action: APEDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_APE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_APE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_APE_SUCCESS:
      return {
        ...state,
        loading: false,
        apes: action.payload,
        errors: null,
        recent: false,
      };

    case ADD_APE_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case ADD_APE_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        add_or_update: true,
      };

    case ADD_APE_SUCCESS:
      return {
        loading: false,
        apes: [...state.apes, action.payload],
        errors: null,
        recent: true,
        add_or_update: true,
      };

    case UPDATE_APE_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case UPDATE_APE_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        add_or_update: true,
      };

    case UPDATE_APE_SUCCESS:
      const current_data: APETypeI[] = state.apes;
      const index: number = current_data.findIndex(
        (element) => element.id === action.payload.id
      );
      current_data[index] = action.payload;

      return {
        loading: false,
        apes: current_data,
        errors: null,
        recent: true,
        add_or_update: true,
      };

    case DELETE_APE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_APE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_APE_SUCCESS:
      const new_data: APETypeI[] = state.apes;
      const item_index = new_data.findIndex(
        (element) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return {
        ...state,
        loading: false,
        apes: new_data,
        errors: null,
      };

    default:
      return state;
  }
};

export default APEreducer;
