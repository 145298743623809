import React, { useState } from "react";
import { ReportSideBar } from "../../components";
import ReportSettingForm from "../../components/Reports/Setting/ReportSettingForm";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const ReportSettingPage = () => {
  const nav = <ReportSideBar report_setting />;

  return (
    <SubModuleLayout reportMenuActive sideNav={nav}>
      <ReportSettingForm />
    </SubModuleLayout>
  );
};
export default ReportSettingPage;
