import React from "react";
import { IdCardList, ReportSideBar, SearchIdCard } from "../../components";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

export default function ReportPage() {
  const nav = <ReportSideBar id_card />;
  return (
    <SubModuleLayout reportMenuActive sideNav={nav}>
      <SearchIdCard />
      <IdCardList />
    </SubModuleLayout>
  );
}
