import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setSnackbar } from "../../../actions/SnackbarAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

export const importUserValidation = createAsyncThunk(
  "importUserValidation",
  async (data: { numbers: string[] }, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.post(`${ADMIN_API_URL}/validate-user/`, data);
      return res.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(null);
    }
  }
);

export const importStudentData = createAsyncThunk(
  "importStudentData",
  async (
    data: { grade: string; section: string; values: any[] },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.post(
        `${ADMIN_API_URL}/upload-students/?grade=${data.grade}&section=${data.section}`,
        data.values
      );
      dispatch(
        setSnackbar(true, "success", "Students data imported successfully")
      );
      return res.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(null);
    }
  }
);

export const importStaffData = createAsyncThunk(
  "importStaffData",
  async (data: { values: any[] }, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.post(
        `${ADMIN_API_URL}/upload_staffs/`,
        data.values
      );
      dispatch(setSnackbar(true, "success", "Staffs imported successfully"));
      return res.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(null);
    }
  }
);
