import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_CHILDREN_FEE_INVOICE_ERROR,
  GET_CHILDREN_FEE_INVOICE_LOADING,
  GET_CHILDREN_FEE_INVOICE_SUCCESS,
  GET_CHILDREN_FEE_PAID_INVOICE_LOADING,
  GET_CHILDREN_FEE_PAID_INVOICE_ERROR,
  GET_CHILDREN_FEE_PAID_INVOICE_SUCCESS,
  ChildrenFeeDispatchTypes,
} from "./ChildrenFeeActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { PARENT_API_URL } from "../../../config/SERVER_API_URLS";

export const GetChildrenFeeInvoice =
  (id: string) =>
  async (dispatch: Dispatch<ChildrenFeeDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_CHILDREN_FEE_INVOICE_LOADING,
      });

      const res = await axios.get(
        `${PARENT_API_URL}/generated_invoices/?student=${id}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_CHILDREN_FEE_INVOICE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_CHILDREN_FEE_INVOICE_ERROR,
      });
    }
  };

export const GetChildrenFeePaidInvoice =
  (id: string) =>
  async (dispatch: Dispatch<ChildrenFeeDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_CHILDREN_FEE_PAID_INVOICE_LOADING,
      });

      const res = await axios.get(
        `${PARENT_API_URL}/paid_invoices/?student=${id}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_CHILDREN_FEE_PAID_INVOICE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_CHILDREN_FEE_PAID_INVOICE_ERROR,
      });
    }
  };
