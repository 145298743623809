import axios from "axios";
import { Dispatch } from "redux";
import {
    GET_OPENING_BALANCE_LOADING,
    GET_OPENING_BALANCE_ERROR,
    GET_OPENING_BALANCE_SUCCESS,
    ADD_OPENING_BALANCE_LOADING,
    ADD_OPENING_BALANCE_ERROR,
    ADD_OPENING_BALANCE_SUCCESS,
    OpeningBalanceDispatchTypes,
} from "./OpeningBalanceActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

export interface ApplyFeeRequestI {
    students: string[];
    months: string[];
    fee_type: string;
}

export const GetOpeningBalance =
    (grade: string, section: string) => async (dispatch: Dispatch<OpeningBalanceDispatchTypes | SetSnackBarI>) => {
        try {
            dispatch({
                type: GET_OPENING_BALANCE_LOADING,
            });

            const res = await axios.get(
                `${ADMIN_API_URL}/opening_balance/?grade=${grade}&section=${section}`,
                HeaderConfig()
            );

            dispatch({
                type: GET_OPENING_BALANCE_SUCCESS,
                payload: res.data,
            });
        } catch (error) {
            dispatch({
                type: GET_OPENING_BALANCE_ERROR,
            });
        }
    };

export const addOpeningBalance =
    (data: any, grade: any, section: any) => async (dispatch: Dispatch<OpeningBalanceDispatchTypes | SetSnackBarI>) => {
        try {
            dispatch({
                type: ADD_OPENING_BALANCE_LOADING,
            });

            const res = await axios.post(
                `${ADMIN_API_URL}/opening_balance/?grade=${grade?.grade || null}&section=${section?.id || null}`, data,
                HeaderConfig()
            );

            dispatch({
                type: ADD_OPENING_BALANCE_SUCCESS,
                payload: res.data,
            });
            dispatch({
                type: SET_SNACKBAR,
                payload: {
                    snackbarOpen: true,
                    snackbarType: "success",
                    snackbarMessage: "Opening balance(s) updated successfully",
                },
            });
        } catch (error) {
            dispatch({
                type: ADD_OPENING_BALANCE_ERROR,
            });
        } finally {
            try {
                dispatch({
                    type: GET_OPENING_BALANCE_LOADING,
                });

                const res = await axios.get(
                    `${ADMIN_API_URL}/opening_balance/?grade=${grade?.grade || null}&section=${section?.id || null}`,
                    HeaderConfig()
                );

                dispatch({
                    type: GET_OPENING_BALANCE_SUCCESS,
                    payload: res.data,
                });
            } catch (error) {
                dispatch({
                    type: GET_OPENING_BALANCE_ERROR,
                });
            }
        }
    };