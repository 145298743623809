export const GET_DAY_BOOK_LOADING = "GET_DAY_BOOK_LOADING";
export const GET_DAY_BOOK_ERROR = "GET_DAY_BOOK_ERROR";
export const GET_DAY_BOOK_SUCCESS = "GET_DAY_BOOK_SUCCESS";

export const GET_INVOICE_DETAIL_LOADING = "GET_INVOICE_DETAIL_LOADING";
export const GET_INVOICE_DETAIL_ERROR = "GET_INVOICE_DETAIL_ERROR";
export const GET_INVOICE_DETAIL_SUCCESS = "GET_INVOICE_DETAIL_SUCCESS";

export const CLEAR_INVOICE = "CLEAR_INVOICE";

export interface ClearInvoice {
    type: typeof CLEAR_INVOICE;
}
export interface GetDaybookLoading {
    type: typeof GET_DAY_BOOK_LOADING;
}

export interface GetDaybookError {
    type: typeof GET_DAY_BOOK_ERROR;
}

export interface GetDaybookSuccess {
    type: typeof GET_DAY_BOOK_SUCCESS;
    payload: any;
}

export interface GetInvoiceDetailLoading {
    type: typeof GET_INVOICE_DETAIL_LOADING;
}

export interface GetInvoiceDetailError {
    type: typeof GET_INVOICE_DETAIL_ERROR;
}

export interface GetInvoiceDetailSuccess {
    type: typeof GET_INVOICE_DETAIL_SUCCESS;
    payload: any;
}

export type DaybookDispatchTypes =
    | GetDaybookLoading
    | GetDaybookError
    | GetDaybookSuccess
    | GetInvoiceDetailLoading
    | GetInvoiceDetailError
    | GetInvoiceDetailSuccess
    | ClearInvoice