export const GET_NOTIFICATION_LOADING = 'GET_NOTIFICATION_LOADING';
export const GET_NOTIFICATION_ERROR = 'GET_NOTIFICATION_ERROR';
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';

export const GET_TEACHER_NOTIFICATION_LOADING =
  'GET_TEACHER_NOTIFICATION_LOADING';
export const GET_TEACHER_NOTIFICATION_ERROR = 'GET_TEACHER_NOTIFICATION_ERROR';
export const GET_TEACHER_NOTIFICATION_SUCCESS =
  'GET_TEACHER_NOTIFICATION_SUCCESS';

// Notification Type
export type NotificationTypeI = {
  id: string;
  created_on: string;
  modified_on: string;
  objectid: string;
  action: number;
  text: string;
  general_info: number;
  created_by: any;
  modified_by: any;
  user: string;
  grade: any;
  section: any;
  content_type: number;
  academic_session: any;
  group: string[];
  seen_by: string[];
  read_by: string[];
};

export type NotificationTypeII = {
  links: {
    next: string | null;
    previous: string | null;
  };
  current_page: number;
  total: number;
  per_page: number;
  total_pages: number;
  results: NotificationTypeI[];
};

export type TeacherNotificationTypeI = {
  id: string;
  content_type: any;
  objectid: any;
  action: number;
  action_description: string;
  text: string;
  created_by: any;
  general_info: number;
  academic_session: any;
};

// Get Notification
export interface GetNotificationLoading {
  type: typeof GET_NOTIFICATION_LOADING;
}

export interface GetNotificationError {
  type: typeof GET_NOTIFICATION_ERROR;
}

export interface GetNotificationSuccess {
  type: typeof GET_NOTIFICATION_SUCCESS;
  payload: NotificationTypeII;
}

// Get Teacher Notification
export interface GetTeacherNotificationLoading {
  type: typeof GET_TEACHER_NOTIFICATION_LOADING;
}

export interface GetTeacherNotificationError {
  type: typeof GET_TEACHER_NOTIFICATION_ERROR;
}

export interface GetTeacherNotificationSuccess {
  type: typeof GET_TEACHER_NOTIFICATION_SUCCESS;
  payload: TeacherNotificationTypeI[];
}

// Export all types
export type NotificationDispatchTypes =
  | GetNotificationLoading
  | GetNotificationError
  | GetNotificationSuccess
  | GetTeacherNotificationLoading
  | GetTeacherNotificationError
  | GetTeacherNotificationSuccess;
