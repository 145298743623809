import React, { useState } from 'react';
import {
  Button,
  Grid,
  InputLabel,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Search, Add } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { useFormStyles } from '../../Styles/FormStyles';
import AddNewButton from '../../Reusable/Buttons/AddNewButton';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

const selectBus: any = [
  { id: 1, title: 'Bus 4501AK', value: '1' },
  { id: 2, title: 'Bus 9501JK', value: '2' },
  { id: 3, title: 'Bus 6701DK', value: '3' },
];

const StudentMappingSearch = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useFormStyles();

  const { errors, setError, clearErrors } = useForm();

  const onSearchClicked = () => {
    console.log('Search fired!');
  };

  return (
    <Paper className={classes.pageContentBox}>
      <form className={classes.form}>
        <Grid container>
          <Grid item xs={9} className={classes.formTitle}>
            <Typography variant="h6">Search Options</Typography>
          </Grid>
          <Grid container item xs={3}>
            <Grid
              container
              justifyContent="flex-end"
              style={{ marginLeft: '40px' }}
            >
              <a onClick={() => history.push('/student-mapping-form')}>
                <AddNewButton
                  title="Add New Student Bus Stop Mapping"
                  type="button"
                  color="primary"
                  variant="outlined"
                  startIcon={<Add />}
                >
                  New Bus Stop Mapping
                </AddNewButton>
              </a>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={3} className={classes.formWrapper}>
              <InputLabel>Bus</InputLabel>
              <Autocomplete
                options={selectBus}
                getOptionLabel={(option: any) => option.title}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="select_message"
                    placeholder="Bus Number"
                    variant="outlined"
                  />
                )}
              />
              <span className={classes.validationErrorInfo}>
                {errors?.message_to?.message}
              </span>
            </Grid>
            <Grid item xs={3} className={classes.formWrapper}>
              <Grid container justifyContent="center">
                <Button
                  className={classes.searchBoxButton}
                  color="primary"
                  variant="contained"
                  startIcon={<Search />}
                  onClick={onSearchClicked}
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default StudentMappingSearch;
