import { Paper, Grid, Button, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useStyles } from '../../Styles/FormStyles';
import parse from 'html-react-parser';
import BraftEditor from 'braft-editor';
import { EditOutlined } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  AddTac,
  GetTac,
} from '../../../actions/GeneralSettings/TermsandConditions/TACAction';
import { RootStore } from '../../../store';
import ReactHtmlParser from 'react-html-parser';
import {
  getTermsConditions,
  updateTermsConditions,
} from '../../../rtk/features/termsconditions/termsconditionsApi';

const TermsConditionForm = () => {
  const classes = useStyles();
  const [editorState, setEditorState] = useState<any>(null);
  const [editMode, setEditMode] = useState(false);
  const dispatch = useDispatch();
  const { loading, actionPerformed, termsConditions } = useSelector(
    (state: RootStore) => state.termsConditions
  );

  const { register, handleSubmit, errors } = useForm<any>({
    mode: 'onChange',
  });

  useEffect(() => {
    dispatch(getTermsConditions());
  }, []);

  useEffect(() => {
    if (actionPerformed) {
      dispatch(getTermsConditions());
    }
  }, [actionPerformed]);

  useEffect(() => {
    if (termsConditions) {
      const htmlContent = termsConditions.tac_field;
      setEditorState(BraftEditor.createEditorState(htmlContent));
    }
  }, [termsConditions]);

  const onSubmit = () => {
    const editorData = editorState.toHTML();
    setEditMode(false);
    if (editorData) {
      dispatch(
        updateTermsConditions({
          tac_field: editorData,
        })
      );
    }
  };

  const handleEditor = (data: any) => {
    setEditorState(data);
  };

  const parseHtmlContent = (content: string) => {
    const doc1 = parse(content);
    return doc1;
  };
  return (
    <Paper className={classes.pageContent}>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={6} className={classes.formTitle}>
            <span>Terms and Conditions</span>
          </Grid>
          <Grid item xs={6}>
            <Grid container justifyContent="flex-end">
              <Button
                startIcon={<EditOutlined />}
                color="primary"
                variant="outlined"
                onClick={() => setEditMode(true)}
              >
                Edit
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {!editMode ? (
            <Grid item xs={12}>
              <Typography variant="body1" align="left" color="primary">
                Terms and Conditions:
              </Typography>
              <span>
                {editorState && ReactHtmlParser(editorState.toHTML())}
              </span>
            </Grid>
          ) : (
            <div
              style={{
                marginTop: '10px',
                marginBottom: '24px',
                border: '1px solid #ccc',
              }}
            >
              <BraftEditor
                language="en"
                value={editorState}
                onChange={(data) => handleEditor(data)}
              />
            </div>
          )}
        </Grid>
        {editMode && (
          <Grid container justifyContent="flex-end">
            <Button type="submit" fullWidth color="primary" variant="contained">
              Save
            </Button>
          </Grid>
        )}
      </form>
    </Paper>
  );
};

export default TermsConditionForm;
