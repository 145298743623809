import React, { useEffect, useState } from "react";
import { useTableStyles } from "../../Styles/TableStyles";
import {
  Paper,
  Table,
  TableBody,
  TableHead,
  TextField,
} from "@material-ui/core";
import styled from "styled-components";
import { useTable } from "../../Reusable";
import { ItemViewButton } from "../../Reusable/Buttons/TableButton";
import { Link, useHistory } from "react-router-dom";
import {
  NepaliCalendar,
  getBsDayName,
  getBsDaysInMonth,
  getCurrentBsDate,
} from "../../utils/nepalicalendar";
import { useDispatch, useSelector } from "react-redux";
import { getAttendanceToday } from "../../../rtk/features/humanResource/humanResourceThunk";
import { RootStore } from "../../../store";
import moment from "moment";
import { dateConverterAdToBs } from "../../utils/dateConverter";

const TableTitle = styled.h2`
  font-size: 20px;
  font-weight: 500;
  color: #2e2e2e;
`;

const TableHeader = styled.div`
  margin-bottom: 8px;
`;

const SearchInput = styled(TextField)`
  && {
    input {
      padding: 10px 16px;
      background-color: #00000017;
    }
  }
`;

const headCells = [
  { id: "sn", label: "S.N" },
  { id: "name", label: "Staff Name" },
  { id: "department", label: "Department" },
  { id: "designation", label: "Designation" },
  { id: "checkinOut", label: "Check In / Check Out" },
  { id: "action", label: "Action" },
];

const today = moment(new Date()).format("YYYY-MM-DD");
const todayBs = dateConverterAdToBs(today);

const AttendanceToday = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const tableClasses = useTableStyles();

  const { TblContainer, StyledTableCell, StyledTableRow } = useTable(headCells);

  const [searchName, setSearchName] = useState<string>("");

  const { loading, attendanceReport } = useSelector(
    (state: RootStore) => state.humanResource
  );

  useEffect(() => {
    dispatch(getAttendanceToday());
  }, []);

  return (
    <Paper className={tableClasses.root}>
      <TableTitle>Staff Attendance Today</TableTitle>
      <TableHeader>
        <SearchInput
          variant="outlined"
          placeholder="Search By Name"
          value={searchName}
          onChange={(e) => setSearchName(e.target.value)}
        />
      </TableHeader>
      <Table>
        <TableHead>
          <StyledTableRow>
            {headCells.map((el) => (
              <StyledTableCell key={el.id}>{el.label}</StyledTableCell>
            ))}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {attendanceReport
            .filter((el) =>
              el.name.toLowerCase().startsWith(searchName.toLowerCase())
            )
            .map((el, index) => (
              <StyledTableRow key={el.employeeId}>
                <StyledTableCell>{index + 1}</StyledTableCell>
                <StyledTableCell>{el.name}</StyledTableCell>
                <StyledTableCell>{el.department}</StyledTableCell>
                <StyledTableCell>{el.designation}</StyledTableCell>
                <StyledTableCell>
                  {el.attendance.find((atd) => atd.date === todayBs)
                    ? el.attendance.find((atd) => atd.date === todayBs)?.leave
                      ? "On Leave"
                      : el.attendance.find((atd) => atd.date === todayBs)
                          ?.checkIn ||
                        "00:00" +
                          " / " +
                          el.attendance.find((atd) => atd.date === todayBs)
                            ?.checkout ||
                        "00:00"
                    : "00:00 / 00:00"}
                </StyledTableCell>
                <StyledTableCell>
                  <ItemViewButton
                    component={Link}
                    to={`/attendance-report/${el.employeeId}`}
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default AttendanceToday;
