import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_INCOME_TYPE_LOADING,
  GET_INCOME_TYPE_ERROR,
  GET_INCOME_TYPE_SUCCESS,
  ADD_INCOME_TYPE_LOADING,
  ADD_INCOME_TYPE_SUCCESS,
  ADD_INCOME_TYPE_ERROR,
  DELETE_INCOME_TYPE_SUCCESS,
  DELETE_INCOME_TYPE_ERROR,
  DELETE_INCOME_TYPE_LOADING,
  UPDATE_INCOME_TYPE_LOADING,
  UPDATE_INCOME_TYPE_SUCCESS,
  UPDATE_INCOME_TYPE_ERROR,
  GET_INCOME_DETAILS_LOADING,
  GET_INCOME_DETAILS_ERROR,
  GET_INCOME_DETAILS_SUCCESS,
  ADD_INCOME_DETAILS_LOADING,
  ADD_INCOME_DETAILS_SUCCESS,
  ADD_INCOME_DETAILS_ERROR,
  DELETE_INCOME_DETAILS_SUCCESS,
  DELETE_INCOME_DETAILS_ERROR,
  DELETE_INCOME_DETAILS_LOADING,
  UPDATE_INCOME_DETAILS_LOADING,
  UPDATE_INCOME_DETAILS_SUCCESS,
  UPDATE_INCOME_DETAILS_ERROR,
  IncomeDispatchTypes,
} from "./IncomeActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

interface RequestDataI {
  id: string;
  name: string;
}

interface RequestDataII {
  id: string;
  income_type: number;
  income_type_name: string;
  name: string;
  invoice: string;
  income_date: string;
  amount: string;
  document: any;
  description: string;
  academic_session: number;
  created_by: number;
  created_on: string;
}

export const GetIncomeType =
  () => async (dispatch: Dispatch<IncomeDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_INCOME_TYPE_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/income_type/`,
        HeaderConfig()
      );

      dispatch({
        type: GET_INCOME_TYPE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_INCOME_TYPE_ERROR,
      });
    }
  };

export const AddIncomeType =
  (data: RequestDataI) =>
  async (dispatch: Dispatch<IncomeDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_INCOME_TYPE_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/income_type/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_INCOME_TYPE_SUCCESS,
        payload: res.data,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "success",
      //     snackbarMessage: "Income Type Added Successfully",
      //   },
      // });
    } catch (error) {
      dispatch({
        type: ADD_INCOME_TYPE_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Income Type Add Failed",
        },
      });
    }
  };

export const UpdateIncomeType =
  (id: string, data: RequestDataI) =>
  async (dispatch: Dispatch<IncomeDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_INCOME_TYPE_LOADING,
      });

      const res = await axios.put(
        `${ADMIN_API_URL}/income_type/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_INCOME_TYPE_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Income Type Updated Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: UPDATE_INCOME_TYPE_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Income Type Update Failed",
        },
      });
    }
  };

export const DeleteIncomeType =
  (id: string) =>
  async (dispatch: Dispatch<IncomeDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_INCOME_TYPE_LOADING,
      });

      const res = await axios.delete(
        `${ADMIN_API_URL}/income_type/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_INCOME_TYPE_SUCCESS,
        payload: { id: id },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Income Type Deleted Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_INCOME_TYPE_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Income Type Delete Failed",
        },
      });
    }
  };

export const GetIncomeDetails =
  () => async (dispatch: Dispatch<IncomeDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_INCOME_DETAILS_LOADING,
      });

      const res = await axios.get(`${ADMIN_API_URL}/income/`, HeaderConfig());

      dispatch({
        type: GET_INCOME_DETAILS_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_INCOME_DETAILS_ERROR,
      });
    }
  };

export const AddIncomeDetails =
  (data: any) =>
  async (dispatch: Dispatch<IncomeDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_INCOME_DETAILS_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/income/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_INCOME_DETAILS_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Income Added Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: ADD_INCOME_DETAILS_ERROR,
        payload: error.response && error.response.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Income Add Failed",
        },
      });
    }
  };

export const UpdateIncomeDetails =
  (id: string, data: any) =>
  async (dispatch: Dispatch<IncomeDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_INCOME_DETAILS_LOADING,
      });

      const res = await axios.put(
        `${ADMIN_API_URL}/income/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_INCOME_DETAILS_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Income Updated Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: UPDATE_INCOME_DETAILS_ERROR,
        payload: error.response && error.response.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Income Update Failed",
        },
      });
    }
  };

export const DeleteIncomeDetails =
  (id: string) =>
  async (dispatch: Dispatch<IncomeDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_INCOME_DETAILS_LOADING,
      });

      const res = await axios.delete(
        `${ADMIN_API_URL}/income/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_INCOME_DETAILS_SUCCESS,
        payload: { id: id },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Income Deleted Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_INCOME_DETAILS_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Income Delete Failed",
        },
      });
    }
  };
