// -----------------<START> import modules starts----------------------//
import React from 'react';
import { Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import { Add, SettingsBackupRestore } from '@material-ui/icons';
import { useFormStyles } from '../../Styles/FormStyles';
import 'nepali-datepicker-reactjs/dist/index.css';
import AddNewButton from '../../Reusable/Buttons/AddNewButton.test';
// -----------------<END> imports module ends -----------------------//

// -----------------<START> Manage Form Header Component starts-----------------------//
const SearchFormHeaderLayout = (props: any) => {
  const classes = useFormStyles();
  const { title, btnTitle, reset, onClick } = props;

  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={6} className={classes.formTitle}>
        <Typography variant="h6" style={{ fontSize: '1.15rem' }}>
          {title}
        </Typography>
      </Grid>

      <Grid container item xs={6}>
        <Grid
          container
          justifyContent="flex-end"
          alignItems="flex-start"
          spacing={2}
        >
          {reset && (
            <Grid item>
              <Tooltip title="Clear Search Query">
                <IconButton
                  aria-label="delete"
                  style={{ padding: '2px' }}
                  onClick={reset}
                >
                  <SettingsBackupRestore />
                </IconButton>
              </Tooltip>
            </Grid>
          )}

          {onClick && (
            <Grid item>
              <AddNewButton
                title="Request New Leave"
                type="button"
                color="primary"
                variant="outlined"
                startIcon={<Add />}
                onClick={onClick}
              >
                {btnTitle}
              </AddNewButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

//-----------------<ENDS> Manage Form Header Component ends-----------------------//
export default SearchFormHeaderLayout;
