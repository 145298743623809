import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_BUS_USER_LOADING,
  GET_BUS_USER_ERROR,
  GET_BUS_USER_SUCCESS,
  BusUserDispatchTypes,
  ADD_BUS_USER_LOADING,
  ADD_BUS_USER_SUCCESS,
  ADD_BUS_USER_ERROR,
  DELETE_BUS_USER_SUCCESS,
  DELETE_BUS_USER_ERROR,
  DELETE_BUS_USER_LOADING,
  UPDATE_BUS_USER_LOADING,
  UPDATE_BUS_USER_SUCCESS,
  UPDATE_BUS_USER_ERROR,
} from "./BusUserActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

interface RequestDataI {
  student_id: string;
  student_user__first_name: string;
  student_user__last_name: string;
  academic_info__grade__name: string | null;
  academic_info__section__name: string | null;
  transportation_detail_id: string;
  transportation_detail__driver: string;
  transportation_detail__route_from: string;
  transportation_detail__route_to: string;
  transportation_detail__bus_number: string;
}

export const GetBusUser =
  () => async (dispatch: Dispatch<BusUserDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_BUS_USER_LOADING,
      });

      const res = await axios.get(`${ADMIN_API_URL}/bus-user/`, HeaderConfig());

      dispatch({
        type: GET_BUS_USER_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_BUS_USER_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "Bus User Loading Failed",
      //   },
      // });
    }
  };

export const SearchBusUser =
  (grade: number | string, section: number | string, route: number | string) =>
  async (dispatch: Dispatch<BusUserDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_BUS_USER_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/bus-user/?grade=${grade}&section=${section}&route=${route}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_BUS_USER_SUCCESS,
        payload: res.data,
      });
      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Bus User Searched",
        },
      });
    } catch (error) {
      dispatch({
        type: GET_BUS_USER_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "Bus User Loading Failed",
      //   },
      // });
    }
  };

export const AddBusUser =
  (data: RequestDataI) =>
  async (dispatch: Dispatch<BusUserDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_BUS_USER_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/bus-user/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_BUS_USER_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Bus User Added Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: ADD_BUS_USER_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Bus User Add Failed",
        },
      });
    }
  };

export const UpdateBusUser =
  (id: string, data: RequestDataI) =>
  async (dispatch: Dispatch<BusUserDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_BUS_USER_LOADING,
      });

      const res = await axios.put(
        `${ADMIN_API_URL}/bus-user/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_BUS_USER_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Bus User Updated Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: UPDATE_BUS_USER_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Bus User Update Failed",
        },
      });
    }
  };

export const DeleteBusUser =
  (id: string) =>
  async (dispatch: Dispatch<BusUserDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_BUS_USER_LOADING,
      });

      const res = await axios.delete(
        `${ADMIN_API_URL}/bus-user/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_BUS_USER_SUCCESS,
        payload: { id: id },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Bus User Deleted Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_BUS_USER_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Bus User Delete Failed",
        },
      });
    }
  };
