import React, { useState } from 'react';
import { useSidebar } from '../../Reusable';
import { useSidebarStyles } from '../../Styles/SidebarStyles';
import { DonutLargeOutlined, MenuBookOutlined } from '@material-ui/icons';
import SidebarTitle from '../../Reusable/Titles/SidebarTitle';

const EventSidebar = (props: any) => {
  const classes = useSidebarStyles();
  const [events] = useState(props.events);
  const [eventType] = useState(props.eventType);
  const [eventCalendar] = useState(props.eventCalendar);
  const [competition] = useState(props.competition);

  const dataSet = [
    { text: 'Event Type', url: 'event', activeStatus: eventType },
    {
      text: 'Events',
      url: 'events',
      activeStatus: events,
    },
    // {
    //   text: 'ECA Event',
    //   url: 'competition',
    //   activeStatus: competition,
    // },
    // {
    //   text: 'Event Calendar',
    //   url: 'calendar',
    //   activeStatus: eventCalendar,
    // },
  ];
  const { CustomSidebar } = useSidebar(dataSet, 'FINANCE');
  return (
    <div className={classes.Sidebar}>
      <div className={classes.sidebarContent}>
        <SidebarTitle icon={<DonutLargeOutlined />}>Event</SidebarTitle>
        <CustomSidebar />
      </div>
    </div>
  );
};

export default EventSidebar;
