import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { DepartmentTypeI } from "../../actions/HumanResource/Department/DepartmentActionTypes";
import { DepartmentForm, DepartmentTable, HRsidebar } from "../../components";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const DepartmentPage = () => {
  const [formData, setFormData] = useState<DepartmentTypeI | null>(null);
  const [count, setCount] = useState<number>(0);

  const fetchEditData = (data: DepartmentTypeI) => {
    setFormData({ ...data, count: count });
    setCount(count + 1);
  };
  const nav = <HRsidebar Department />;
  return (
    <SubModuleLayout HumanMenuActive sideNav={nav}>
      <Grid container>
        <Grid item xs={3}>
          <DepartmentForm editData={formData} />
        </Grid>
        <Grid item xs={9}>
          <DepartmentTable onEditData={fetchEditData} />
        </Grid>
      </Grid>
    </SubModuleLayout>
  );
};

export default DepartmentPage;
