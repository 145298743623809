export const GET_POST_LOADING = "GET_POST_LOADING";
export const GET_POST_ERROR = "GET_POST_ERROR";
export const GET_POST_SUCCESS = "GET_POST_SUCCESS";

export type PostType = {
  userId: number;
  id: number;
  title: string;
  body: string;
};

export interface GetPostLoading {
  type: typeof GET_POST_LOADING;
}

export interface GetPostError {
  type: typeof GET_POST_ERROR;
}

export interface GetPostSuccess {
  type: typeof GET_POST_SUCCESS;
  payload: PostType[];
}

export type PostDispatchTypes = GetPostLoading | GetPostError | GetPostSuccess;
