// -----------------<start> modules imports starts-----------------------//
import { Button, Divider, Grid } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTable } from '../../../Reusable';
import { ItemDeleteButton } from '../../../Reusable/Buttons/TableButton';
import TextField from '../../../Reusable/Inputs/TextField';
import RoutesTabs from './RoutesTabs/RoutesTabs';
// -----------------<ends> modules imports ends-----------------------//

interface HeadCellsI {
  id: string;
  label: string;
}

const headCells: HeadCellsI[] = [
  { id: 'sn', label: 'S.N.' },
  { id: 'place', label: 'Place Name' },
  { id: 'pickup', label: 'Pickup Time' },
  { id: 'drop', label: 'Drop Time' },
  { id: 'action', label: 'Action' },
];

const tableData = [
  { id: '01', place: 'Bafal', pickup: '09:00 AM', drop: '04: 00 PM' },
  { id: '02', place: 'Kalanki', pickup: '09:10 AM', drop: '04: 10 PM' },
  { id: '03', place: 'Teku', pickup: '09:20 AM', drop: '04: 20 PM' },
  { id: '04', place: 'School', pickup: '09:30 AM', drop: '04: 30 PM' },
];

// -----------------<start> Routes Modal Components starts-----------------------//
function BusStopModal(props: any) {
  const { handleSubmit, register } = useForm();

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  const onSubmit = (data: any) => {
    console.log('Fired!', data);
  };

  const getCustomTableRow = (item: any, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center">{item.id}</StyledTableCell>
        <StyledTableCell align="center">{item.place}</StyledTableCell>
        <StyledTableCell align="center">{item.pickup}</StyledTableCell>
        <StyledTableCell align="center">{item.drop}</StyledTableCell>
        <StyledTableCell align="center">
          <ItemDeleteButton
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              console.log('Fired!')
            }
          />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <TextField
              label="Bus Stop"
              placeholder="Place"
              name="place"
              inputRef={register({ required: true })}
            />
          </Grid>

          <Grid item>
            <TextField
              label="Pickup"
              placeholder="Pickup Time"
              name="pickup"
              type="time"
              inputRef={register({ required: true })}
            />
          </Grid>

          <Grid item>
            <TextField
              label="Drop"
              placeholder="Drop Time"
              name="drop"
              type="time"
              inputRef={register({ required: true })}
            />
          </Grid>

          <Grid item>
            <Button
              style={{ marginBottom: 15 }}
              type="submit"
              variant="contained"
              color="primary"
              size="large"
            >
              Add Bus Stop
            </Button>
          </Grid>
        </Grid>
      </form>

      <Divider />

      <TableContainer
        items={tableData}
        headCells={headCells}
        getCustomTableRow={getCustomTableRow}
        search_name="place"
        rowsPerPage={5}
      />
    </>
  );
}
// -----------------<ends> Routes Modal Components ends -----------------------//
export default BusStopModal;
