import React from "react";
import { Paper } from "@material-ui/core";
import ParentDetailForm from "../ParentForm/ParentDetailsForm";
import { useStyles } from "../../Styles/FormStyles";

const AddGuardian = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.pageContent} elevation={3}>
      <ParentDetailForm />
    </Paper>
  );
};

export default AddGuardian;
