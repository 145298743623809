//-------------------<Start> module imports starts ------------------//
import React, { useState } from 'react';
import SubModuleLayout from '../../components/Reusable/Layouts/SubModuleLayout';
import RoutesForm from '../../components/Transport/TestRoutes/RoutesForm';
import { Grid } from '@material-ui/core';
import RoutesList from '../../components/Transport/TestRoutes/RoutesList';
import TransportSidebar from '../../components/Transport/TransportSidebar';
import { TransportTypeI } from '../../actions/Transport/Routes/TransportActionTypes';
// -----------------<ends> modules imports ends-----------------------//
// -----------------<start>Routes page component starts-----------------------//
function TestRoutesPage() {
  const [formData, setFormData] = useState<TransportTypeI | null>(null);
  const [count, setCount] = useState<number>(0);
  const [edit, setEdit] = useState<any>();

  const fetchEditData = (data: TransportTypeI) => {
    setFormData({ ...data, count: count });
    setCount(count + 1);
  };

  const onEditCheck = (data: any) => {
    setEdit({ check: data, count: count });
  };

  const nav = <TransportSidebar testRoutes />;
  return (
    <SubModuleLayout sideNav={nav}>
      <Grid container>
        <Grid item xs={3}>
          <RoutesForm onEditCheck={onEditCheck} editData={formData} />
        </Grid>
        <Grid item xs={9}>
          <RoutesList edit={edit} onEditData={fetchEditData} />
        </Grid>
      </Grid>
    </SubModuleLayout>
  );
}
// -----------------<end> Route Page components ends-----------------------//
export default TestRoutesPage;
