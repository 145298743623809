import React from "react";
import { Avatar, Grid } from "@material-ui/core";

interface UserAvatarProps {
  name: string | undefined;
  src: string;
  size?: "sm";
}

const avatarStyle = { width: "30px", height: "30px" };
const avatarStyleSm = { width: "25px", height: "25px" };

const UserAvatar = (props: UserAvatarProps) => {
  return (
    <Grid container spacing={1} alignItems="center">
      <Avatar
        src={props.src}
        // style={props.size === "sm" ? avatarStyleSm : avatarStyle}
        style={avatarStyleSm}
      />
      <Grid item>{props.name}</Grid>
    </Grid>
  );
};

export default UserAvatar;
