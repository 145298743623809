// ---------------------------- <START> module imports start ----------------------------------//
import React, { useState, useEffect } from "react";
import { Avatar, CardHeader, Grid, makeStyles, Table, Paper, createStyles, TableBody, TableHead, Theme, Typography } from "@material-ui/core";
import { useTableStyles } from "../../Styles/TableStyles";
import { useTable } from "../../Reusable";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import { FineTypeI } from "../../../actions/Fees/FineType/FineActionTypes";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import { DeleteFine, GetFine } from "../../../actions/Fees/FineType/FineAction";
import {
    ItemDeleteButton,
    ItemEditButton,
} from "../../Reusable/Buttons/TableButton";
import getMonthName from "../../utils/getMonthName";
import { AnyAction } from "redux";
import { useParams } from "react-router";
import { GetInvoiceHistory } from "../../../actions/Fees/CollectFees/CollectFeesAction";
import SubModuleLayout from "../../Reusable/Layouts/SubModuleLayout";
import { GetStudentByID } from "../../../actions/Student/Student/StudentAction";
import defaultBase64 from "../../Student/ParentForm/defaultBase64";
import getGradeFeeAmount from "../../utils/getGradeFeeAmount";

interface HeadCellsI {
    id: string;
    label: string;
}
const headCells: HeadCellsI[] = [];

const useDataGridStyles = makeStyles((theme: any) =>
    createStyles({
        table: {
            "& th": {
                backgroundColor: "#e3e3e3",
            },
            "& .MuiTableCell-sizeSmall": {
                padding: "2px 2px 0px 2px",
                borderBottom: '0px',
            },
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: "#fff",
        },
    })
);
const AppliedScholarshipsForStudent = (props: any) => {
    const { data } = props;
    const classes = useTableStyles();
    const tableCss = useDataGridStyles()
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [tableData, setTableData] = useState<any>([])

    const handleModalClose = (value: boolean) => {
        setDeleteModalOpen(value);
    };

    const onDeleteConfirmed = (confirmed: boolean) => {
        setDeleteModalOpen(false);
    };

    useEffect(() => {
        if (data !== null) {
            const tempData: any[] = [];
            data.map((elem: any) => {
                if (elem.applied_months?.length > 0) {
                    {
                        elem.applied_months?.map((item: any) => {
                            tempData.push({
                                id: elem.id,
                                fee_type: elem?.fee_type[0]?.name +
                                    getMonthName(item.toString()),
                                scholarship_title: elem?.scholarship_type_detail?.scholarship_title,
                                amount: getGradeFeeAmount(elem?.student_academic?.grade?.id, elem?.fee_type[0]?.grade_fee_amount)
                            })
                        })
                    }
                } else {
                    tempData.push({
                        id: elem.id,
                        fee_type: elem?.fee_type[0]?.name,
                        scholarship_title: elem?.scholarship_type_detail?.scholarship_title,
                        amount: getGradeFeeAmount(elem?.student_academic?.grade?.id, elem?.fee_type[0]?.grade_fee_amount)
                    })
                }
            })
            setTableData(tempData)
        }
    }, [data])

    const {
        TblContainer,
        TblHead,
        StyledTableCell,
        StyledTableRow,
        exportToExcel,
        tablePrint,
    } = useTable(headCells);

    const handleDeleteModal = () => {
        setDeleteModalOpen(true);
    }

    return (
        <div>
            {data?.length > 0 &&
                <>
                    <Table stickyHeader className={tableCss.table} size="small">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell
                                    align="center"
                                    className={classes.cell}
                                >
                                    Fee Type
                                </StyledTableCell>
                                <StyledTableCell
                                    align="left"
                                    className={classes.cell}
                                >
                                    Scholarship
                                </StyledTableCell>
                                <StyledTableCell
                                    align="left"
                                    className={classes.cell}
                                >
                                    Amount
                                </StyledTableCell>
                                <StyledTableCell
                                    align="left"
                                    className={classes.cell}
                                >
                                    Action
                                </StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {tableData.map((item: any, index: number) => (
                                <StyledTableRow key={index}>
                                    <StyledTableCell align="center" className={classes.cell}>
                                        {item.fee_type}
                                    </StyledTableCell>
                                    <StyledTableCell align="left" className={classes.cell}>
                                        {item.scholarship_title}
                                    </StyledTableCell>
                                    <StyledTableCell align="left" className={classes.cell}>
                                        Rs. {item.amount}
                                    </StyledTableCell>
                                    <StyledTableCell align="left" className={classes.cell}>
                                        <ItemDeleteButton
                                            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
                                                handleDeleteModal()
                                            }
                                        />
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <CustomizedDialogs
                        open={deleteModalOpen}
                        onClose={handleModalClose}
                        deleteConfirmed={onDeleteConfirmed}
                        dialogTitle="Are you sure to delete this record?"
                        okText="Delete"
                    />
                </>
            }
        </div>
    );
};
// ---------------------------- <END> fine Type Component Ends -------------------------------//

export default AppliedScholarshipsForStudent;
