import {
  Button,
  Divider,
  Grid,
  TextField,
  Input,
  TableBody,
  IconButton,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useParams } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import Iframe from 'react-iframe';
import { useTable } from './';
import AddNewButton from './Buttons/AddNewButton';
import { useStyles } from '../Styles/FormStyles';
import { useTableStyles } from '../Styles/TableStyles';
import Popups from './Dialogs/Popups';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../actions/SnackbarAction';
import checkEmptyField from '../utils/checkEmptyField';

// Document Table Head Data
const headCells: any[] = [
  { id: 'sn', label: 'S.N' },
  { id: 'select_document', label: 'Select' },
  { id: 'document_name', label: 'Document Name' },
  { id: 'reset_button', label: 'Reset' },
  { id: 'preview', label: 'Preview' },
  { id: 'remove_icon', label: 'Remove' },
];

const DocumentsUploader = (props: any) => {
  // Custom Styles
  const classes = useStyles();
  const table_classes = useTableStyles();
  const dispatch = useDispatch();

  // Destructuring reusable table component
  const { TblContainer, TblHead, StyledTableCell, StyledTableRow } =
    useTable(headCells);

  const [documents, setDocuments] = useState<null | any>([]);
  const [viewID, SetViewID] = useState<any | null>(null);
  const [openPopup, setOpenPopup] = useState(false);

  const [docName, setDocName] = useState<any>([{ name: '' }]);

  // Document preview modal
  const handleClose = (value: boolean) => {
    setOpenPopup(value);
  };

  useEffect(() => {
    // console.log({ documents });
    props.setValue(documents);
  }, [documents]);

  // Reset document row
  const handleResetDocument = (index: number) => {
    // console.log("index", index);
    documents[index].name = '';
    documents[index].title = '';
    documents[index].document = '';
    setDocuments([...documents]);
  };

  // Document Upload
  const handleDocumetUpload = (value: any, index: number) => {
    const fileTypes = ['jpg', 'png', 'jpeg', 'pdf', 'docx', 'doc'];
    // const fileType = value[0]?.name.split(".")[1];
    const fileType = value[0]?.name.split('.').pop();

    const convertBase64 = (file: any) => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
          resolve(fileReader.result);
          documents[index].document = fileReader.result as string;
        };
      });
    };

    if (fileTypes.includes(fileType)) {
      documents[index].name = value[0].name;
      setDocName([...docName, { name: value[0].name }]);
      convertBase64(value[0]);
    }
  };

  const handleDocPush = () => {
    setDocuments(
      documents.concat({
        document: '',
        name: '',
        title: '',
      })
    );
    // setDocuments([...documents]);
  };

  const showSnackbar = () => {
    dispatch(setSnackbar(true, 'warning', 'Fields cannot be empty'));
  };

  // Add Document Row
  const handleAddDocument = () => {
    checkEmptyField(
      documents,
      () => handleDocPush(),
      () => showSnackbar()
    );

    // documents.push({
    //   document: "",
    //   name: "",
    //   title: "",
    // });
    // setDocuments([...documents]);
  };

  // Delete Document Row
  const handleDeleteDocument = (index: number) => {
    documents.splice(index, 1);
    setDocuments([...documents]);
  };

  // While clicking preview on document
  const handleView = (id: any) => {
    setOpenPopup(true);
    SetViewID(id);
  };

  // Add up document name to selected document
  const handleDocumentName = (event: any, index: number) => {
    const tempData: any = documents;
    tempData[index].title = event.target.value;
    setDocuments(tempData);
  };

  // console.log("donuments", documents);

  return (
    <Grid container>
      <>
        <Grid container>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} className={classes.formTitle}>
            <span>Documents</span>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.formWrapper}>
          <Grid container justifyContent="flex-start">
            <AddNewButton
              title="Add New Admission"
              type="button"
              color="secondary"
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={handleAddDocument}
            >
              Add Documents
            </AddNewButton>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={7}>
            <TblContainer>
              {documents.length > 0 && <TblHead />}
              <TableBody>
                {documents.map((item: any, index: number) => (
                  <StyledTableRow key={item.id}>
                    <StyledTableCell
                      align="center"
                      className={table_classes.cell}
                    >
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={table_classes.cell}
                    >
                      <Grid container spacing={1} alignItems="center">
                        <Input
                          className={classes.input}
                          id={`id${index}`}
                          type="file"
                          name={`document_${index + 1}`}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            handleDocumetUpload(event.target.files, index);
                          }}
                        />
                        <label htmlFor={`id${index}`}>
                          <Button
                            className={classes.browseButton}
                            color="primary"
                            component="span"
                          >
                            {/* {docName[index].name != ""
                              ? docName[index].name
                              : "Browse"} */}
                            {documents[index].name != ''
                              ? documents[index].name
                              : 'Browse'}
                          </Button>
                        </label>
                      </Grid>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={table_classes.cell}
                    >
                      <TextField
                        variant="outlined"
                        placeholder="Document Name"
                        // autoFocus={documents[index].title && true}
                        onChange={(event) => {
                          handleDocumentName(event, index);
                        }}
                        defaultValue={documents[index].title}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={table_classes.cell}
                    >
                      <AddNewButton
                        title="Clear This Document"
                        type="button"
                        color="secondary"
                        variant="outlined"
                        onClick={() => {
                          handleResetDocument(index);
                        }}
                      >
                        Reset
                      </AddNewButton>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={table_classes.cell}
                    >
                      <IconButton
                        disabled={!documents[index].name ? true : false}
                        onClick={() => handleView(index)}
                        style={{
                          color: !documents[index].name ? '' : '#37a0d4',
                        }}
                      >
                        <VisibilityOutlinedIcon />
                      </IconButton>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={table_classes.cell}
                    >
                      <DeleteForeverIcon
                        onClick={() => {
                          handleDeleteDocument(index);
                        }}
                        style={{ color: '#db4e53' }}
                      />
                    </StyledTableCell>
                    <Popups
                      openPopup={openPopup}
                      setOpenPopup={setOpenPopup}
                      onClose={handleClose}
                      title={documents[viewID]?.title}
                      colored
                      scroll="body"
                      maxWidth="xl"
                    >
                      <Iframe
                        url={documents?.length && documents[viewID]?.document}
                        position="relative"
                        className={classes.iframe}
                        height="100%"
                        display="block"
                        width="1050px"
                        allowFullScreen={false}
                      />
                    </Popups>
                  </StyledTableRow>
                ))}
              </TableBody>
            </TblContainer>
          </Grid>
        </Grid>
        <br />
        <br />
      </>
    </Grid>
  );
};

export default DocumentsUploader;
