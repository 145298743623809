import axios from "axios";
import { Dispatch } from "redux";

import {
  EMAIL_SETTING_LOADING,
  EMAIL_SETTING_ERROR,
  EMAIL_SETTING_SUCCESS,
  ADD_EMAIL_SETTING_LOADING,
  ADD_EMAIL_SETTING_ERROR,
  ADD_EMAIL_SETTING_SUCCESS,
  UPDATE_EMAIL_SETTING_LOADING,
  UPDATE_EMAIL_SETTING_ERROR,
  UPDATE_EMAIL_SETTING_SUCCESS,
  EmailSettingDispatchTypes,
} from "./EmailSettingActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

interface RequestDataI {
  smtp_host: string;
  smtp_email: string;
  smtp_password: string;
  smtp_port: number | string;
  smtp_display_name: string;
  smtp_use_tls?: boolean | string;
}

export const GetEmailSettingData =
  () =>
  async (dispatch: Dispatch<EmailSettingDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: EMAIL_SETTING_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/email_setting/`,
        HeaderConfig()
      );

      dispatch({
        type: EMAIL_SETTING_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: EMAIL_SETTING_ERROR,
      });
    }
  };

export const AddEmailSetting =
  (data: RequestDataI) =>
  async (dispatch: Dispatch<EmailSettingDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_EMAIL_SETTING_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/email_setting/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_EMAIL_SETTING_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Email Setting Saved Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: ADD_EMAIL_SETTING_ERROR,
      });
    }
  };

export const UpdateEmailSetting =
  (id: string | null, data: RequestDataI) =>
  async (dispatch: Dispatch<EmailSettingDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_EMAIL_SETTING_LOADING,
      });

      const res = await axios.put(
        `${ADMIN_API_URL}/email_setting/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_EMAIL_SETTING_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Email Setting Saved Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: UPDATE_EMAIL_SETTING_ERROR,
      });
    }
  };
