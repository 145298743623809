import React, { useState, useEffect } from "react";
import { Grid, Paper, Box, InputLabel, TextField } from "@material-ui/core";
import { Redirect, useHistory } from "react-router-dom";
import { useTableStyles } from "../../Styles/TableStyles";
import { useTableWithPagination } from "../../Reusable";
import DisableDialog from "../../Reusable/Dialogs/DisableDialog";
import getNewFullName from "../../utils/getNewFullName";
import { RootStore } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import {
  GetStudentList,
  GetStudentListWithPagination,
  DisableStudent,
  GetPaginatedStudent,
  SearchStudent,
} from "../../../actions/Student/Student/StudentAction";
import {
  ItemEditButton,
  ItemViewButton,
  ItemDisableButton,
} from "../../Reusable/Buttons/TableButton";
import { GetGuardianAll } from "../../../actions/Student/Guardian/GuardianAction";
import checkImageUrl from "../../utils/checkImageUrl";
import defaultPlaceholder from "../../../assets/images/defaultPlaceholder";
import UserAvatar from "../../Reusable/Profile/UserAvatar";
import SearchTimetable from "./SearchTimetable";
import CustomizedSelect from "../../Reusable/Inputs/Select";
import { paginationChoices, Tupple } from "../../../common/json.constant";
import { useStyles } from "../../Styles/FormStyles";
import { useForm } from "react-hook-form";
import AccessPermission from "./AccessPermission";
import { MdPanoramaPhotosphereSelect } from "react-icons/md";

interface TableDataI {
  id: string;
  student_photo: string;
  student_name: string;
  adm_no: string;
  class: string;
  roll_no: string;
  phone_no?: string;
  guardian: string;
  guardian_no: string;
  occupation?: string;
  guardian_image: string;
}

interface HeadCellsI {
  id: string;
  label: string;
  filter?: boolean;
}

interface SectionI {
  id: string;
  title: string;
}

interface ClassI {
  id: string;
  title: string;
  sections: SectionI[] | [];
}

// ---------------------------- <END> interface ends ----------------------------------//
// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N" },
  { id: "student_name", label: "Student Name", filter: true },
  { id: "adm_no", label: "Adm No." },
  { id: "class", label: "Class" },
  { id: "roll_no", label: "Roll No" },
  // { id: "phone_no", label: "Phone No." },
  { id: "guardian", label: "Guardian" },
  { id: "guardian_no", label: "Guardian's Phone" },
  { id: "action", label: "Action" },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const StudentTable: React.FC = () => {
  const classes = useTableStyles();
  const styles = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  // Destructuring react-form-hook methods
  const { register, errors } = useForm<any>();

  // States declaration
  const [tableData, setTableData] = useState<TableDataI[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [academicYearForPagination, setAcademicYearForPagination] =
    useState<string>("");
  const [classNameForPagination, setClassNameForPagination] =
    useState<string>("");
  const [sectionForPagination, setSectionForPagination] = useState<string>("");
  const [studentUpdateClicked, setStudentUpdateClicked] = useState(false);
  const [studentViewClicked, setStudentViewClicked] = useState(false);
  const [studentID, setStudentID] = useState<string | null>(null);
  const [className, setClassName] = useState<ClassI | null>(null);
  const [section, setSection] = useState<SectionI | null>(null);
  const [selectedPagination, setSelectedPagination] = useState<any | null>({
    key: "10",
    value: "10",
  });
  const [remarks, setRemarks] = useState<string | null>(null);
  const [searchParam, setSearchParam] = useState<string>("");

  // Modal States
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);

  // Retrieving re-usable components from useTable
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTableWithPagination(headCells);

  const sessionLoading = useSelector(
    (state: RootStore) => state.student.loading
  );

  const studentListData = useSelector((state: RootStore) => state.student);
  // console.log("studentListData", studentListData)

  const authState = useSelector((state: RootStore) => state.auth);

  useEffect(() => {
    dispatch(GetStudentListWithPagination(1));
    dispatch(GetGuardianAll(1));
  }, []);

  useEffect(() => {
    if (selectedPagination !== null && searchParam !== (null || "")) {
      dispatch(SearchStudent(searchParam, 1, selectedPagination?.key));
    } else if (selectedPagination !== null) {
      dispatch(
        GetPaginatedStudent(
          1,
          Number(selectedPagination.key),
          classNameForPagination,
          sectionForPagination
        )
      );
    }
  }, [selectedPagination]);

  useEffect(() => {
    setLoading(sessionLoading);
  }, [sessionLoading]);

  const getClassName = (grade: string, section: string) => {
    if (section != null) {
      return grade + "(" + section + ")";
    } else {
      return grade;
    }
  };

  // console.log("student data", tableData);

  useEffect(() => {
    const all_students = studentListData.student_list.results;
    const searched_students = studentListData.student_search?.results;
    let param = [];
    // console.log(all_students, searched_students)

    if (all_students != null) {
      param = all_students;
    } else {
      param = searched_students;
    }
    const data: any = [];
    param?.map((elem: any) => {
      data.push({
        id: elem.id,
        student_photo:
          elem.student_photo != null
            ? checkImageUrl(elem.student_photo)
            : defaultPlaceholder,
        student_name: getNewFullName(
          elem.student_first_name,
          elem.student_last_name
        ),
        adm_no: elem.admission_number,
        class: getClassName(elem.grade, elem.section),
        roll_no: elem.roll_number,
        phone_no: elem.phone_number,
        guardian_image: elem.guardian_detail?.photo
          ? elem.guardian_detail.photo
          : defaultPlaceholder,
        guardian: getNewFullName(
          elem.guardian_first_name,
          elem.guardian_last_name
        ),
        guardian_no: elem.guardian_phone_number,
      });
    });
    setTableData(data);
  }, [studentListData]);

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const handleDeleteModal = (value: boolean, id: string) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    dispatch(DisableStudent(itemId, { remarks }));
    setRemarks(null);
    setDeleteModalOpen(false);
  };

  const handleUpdateView = (id: string) => {
    setStudentID(id);
    setStudentUpdateClicked(true);
  };

  const handleStudentView = (id: string) => {
    setStudentID(id);
    setStudentViewClicked(true);
  };

  const handlePaginationChange = (paginationIndex: number) => {
    if (searchParam !== (null || "")) {
      dispatch(
        SearchStudent(searchParam, paginationIndex, selectedPagination?.key)
      );
    } else if (className?.id !== "" || section?.id !== "") {
      dispatch(
        GetPaginatedStudent(
          paginationIndex,
          selectedPagination?.key,
          className?.id || "",
          section?.id || ""
        )
      );
    } else {
      console.log("333");

      dispatch(
        GetPaginatedStudent(
          paginationIndex,
          selectedPagination?.key,
          classNameForPagination,
          sectionForPagination
        )
      );
    }
  };

  if (studentUpdateClicked) {
    return <Redirect to={`/student/edit/${studentID}`} />;
  }

  if (studentViewClicked) {
    return <Redirect to={`/student/profile/${studentID}`} />;
  }

  console.log({ studentListData });

  const getCustomTableRow = (item: TableDataI, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <UserAvatar name={item.student_name} src={item.student_photo} />
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.adm_no}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.class}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.roll_no}
        </StyledTableCell>
        {/* <StyledTableCell align="center" className={classes.cell}>
          {item.phone_no}
        </StyledTableCell> */}
        <StyledTableCell align="center" className={classes.cell}>
          <UserAvatar name={item.guardian} src={item.guardian_image} />
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.guardian_no}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ItemViewButton onClick={() => handleStudentView(item.id)} />
          <AccessPermission>
            <ItemEditButton onClick={() => handleUpdateView(item.id)} />
            <ItemDisableButton
              onClick={() => handleDeleteModal(true, item.id)}
            />
          </AccessPermission>
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  const handleImport = () => {
    history.push("/import-student-info");
  };

  const handleSendSms = () => {
    history.push("/student-credentials");
  };

  return (
    <Paper className={classes.rootTableBox}>
      <div style={{ paddingBottom: "8px" }}>
        <SearchTimetable
          setClassNameForPagination={setClassNameForPagination}
          setSectionForPagination={setSectionForPagination}
          selectedPagination={selectedPagination?.key}
          searchParam={searchParam}
          setSearchParam={setSearchParam}
          className={className}
          setClassName={setClassName}
          section={section}
          setSection={setSection}
        />
      </div>
      <Grid container justifyContent="flex-end">
        <Box fontSize={10} textAlign="center">
          <CustomizedSelect
            label="Table Size"
            name="gender"
            options={paginationChoices}
            value={selectedPagination}
            setValue={(value: { key: string; value: string } | null) =>
              setSelectedPagination(value || { key: "10", value: "10" })
            }
          />
        </Box>
      </Grid>
      <TableContainer
        label="Student list"
        loading={loading}
        items={tableData}
        headCells={headCells}
        getCustomTableRow={getCustomTableRow}
        search_name="student_name"
        search_param="student_name"
        forPagination={studentListData?.student_list}
        handlePaginationChange={handlePaginationChange}
        onImport={handleImport}
        onSendSms={handleSendSms}
        disableSearch
      />

      <DisableDialog
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
      >
        <Grid container justifyContent="center">
          <Grid className={styles.formWrapper} style={{ marginBottom: "2rem" }}>
            <InputLabel>Remarks</InputLabel>
            <TextField
              variant="outlined"
              multiline
              rows={4}
              name="remarks"
              placeholder="Remarks"
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
            />
          </Grid>
        </Grid>
      </DisableDialog>
    </Paper>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default StudentTable;
