export const REPORTSETTING_LOADING = "REPORTSETTING_LOADING";
export const REPORTSETTING_ERROR = "REPORTSETTING_ERROR";
export const REPORTSETTING_SUCCESS = "REPORTSETTING_SUCCESS";

export const ADD_REPORTSETTING_LOADING = "ADD_REPORTSETTING_LOADING";
export const ADD_REPORTSETTING_ERROR = "ADD_REPORTSETTING_ERROR";
export const ADD_REPORTSETTING_SUCCESS = "ADD_REPORTSETTING_SUCCESS";

export const UPDATE_REPORTSETTING_LOADING = "UPDATE_REPORTSETTING_LOADING";
export const UPDATE_REPORTSETTING_ERROR = "UPDATE_REPORTSETTING_ERROR";
export const UPDATE_REPORTSETTING_SUCCESS = "UPDATE_REPORTSETTING_SUCCESS";

export type ReportSettingI = {
  id: string;
  logo: string;
  school_name: string;
  address: string;
  phone_number: string;
  email: string;
  slogan: string;
  school_reg_number: string;
};

//===============================<START>GET REPORTSETTING<START>======================//

export interface ReportSettingLoading {
  type: typeof REPORTSETTING_LOADING;
}

export interface ReportSettingError {
  type: typeof REPORTSETTING_ERROR;
}

export interface ReportSettingSuccess {
  type: typeof REPORTSETTING_SUCCESS;
  payload: any;
}

//===============================<END>GET REPORTSETTING<END>======================//

//===============================<START>ADD REPORTSETTING<START>======================//

export interface AddReportSettingLoading {
  type: typeof ADD_REPORTSETTING_LOADING;
}

export interface AddReportSettingError {
  type: typeof ADD_REPORTSETTING_ERROR;
}

export interface AddReportSettingSuccess {
  type: typeof ADD_REPORTSETTING_SUCCESS;
  payload: any;
}

//===============================<END>ADD REPORTSETTING<END>======================//

//===============================<START>UPDATE REPORTSETTING<START>======================//

export interface UpdateReportSettingLoading {
  type: typeof UPDATE_REPORTSETTING_LOADING;
}

export interface UpdateReportSettingError {
  type: typeof UPDATE_REPORTSETTING_ERROR;
}

export interface UpdateReportSettingSuccess {
  type: typeof UPDATE_REPORTSETTING_SUCCESS;
  payload: any[];
}

//===============================<END>UPDATE REPORTSETTING<END>======================//

//===============================<END>GET REPORTSETTING<END>======================//

export type ReportSettingDispatchTypes =
  | ReportSettingLoading
  | ReportSettingError
  | ReportSettingSuccess
  | AddReportSettingLoading
  | AddReportSettingError
  | AddReportSettingSuccess
  | UpdateReportSettingLoading
  | UpdateReportSettingError
  | UpdateReportSettingSuccess;
