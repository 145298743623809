import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getAdmissionSettings,
  postAdmissionSettings,
  updateAdmissionSettings,
} from "./admissionSettingsApi";

export interface AdmissionSettingsI {
  id: string;
  auto_generate: boolean;
  number_of_digit: number;
  prefix: string;
  number_start_from: number;
}

const initialState: {
  loading: boolean;
  admissionSettings: AdmissionSettingsI | null;
} = {
  loading: false,
  admissionSettings: null,
};

const admissionSettingsSlice = createSlice({
  name: "admissionSettings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAdmissionSettings.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getAdmissionSettings.fulfilled,
      (state, { payload }: PayloadAction<AdmissionSettingsI>) => {
        state.loading = false;
        state.admissionSettings = payload;
      }
    );
    builder.addCase(getAdmissionSettings.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(postAdmissionSettings.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      postAdmissionSettings.fulfilled,
      (state, { payload }: PayloadAction<AdmissionSettingsI>) => {
        state.loading = false;
        state.admissionSettings = payload;
      }
    );
    builder.addCase(postAdmissionSettings.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateAdmissionSettings.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      updateAdmissionSettings.fulfilled,
      (state, { payload }: PayloadAction<AdmissionSettingsI>) => {
        state.loading = false;
        state.admissionSettings = payload;
      }
    );
    builder.addCase(updateAdmissionSettings.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default admissionSettingsSlice.reducer;
