import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Paper,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetDesignations } from "../../../../../actions/HumanResource/Designation/DesignationAction";
import { RootStore } from "../../../../../store";
import { useFormStyles } from "../../../../Styles/FormStyles";
import { useForm } from "react-hook-form";
import { AnnouncementTypeI } from "../../../../../actions/Communication/Announcement/AnnouncementActionTypes";
import { GetSystemRoles } from "../../../../../actions/HumanResource/StaffRole/StaffRolesAction";

interface PropsI {
  onChange: (value: GroupTabFormI) => void;
  onSubmit: number;
  initData: AnnouncementTypeI | null;
}

export interface GroupTabFormI {
  group: string[];
  groupLabel: string[];
}

interface DesignationBoxI {
  id: string;
  name: string;
  checked: boolean;
}

const GroupTab = (props: PropsI) => {
  const { onChange, onSubmit, initData } = props;
  const classes = useFormStyles();
  const [designationBoxes, setDesgnationBoxes] = useState<DesignationBoxI[]>(
    []
  );

  // ============================== <START> REACT HOOKS <START> ============================//
  const { errors, setError, clearErrors } = useForm();
  const dispatch = useDispatch();
  const roleSelector = useSelector((state: RootStore) => state.staff_roles);

  useEffect(() => {
    dispatch(GetSystemRoles());
  }, []);

  useEffect(() => {
    const data = roleSelector.system_roles;
    const boxData: DesignationBoxI[] = [];

    data.map((element) => boxData.push({ ...element, checked: false }));

    setDesgnationBoxes(boxData);
  }, [roleSelector]);

  useEffect(() => {
    if (onSubmit > 1) {
      const checked = designationBoxes.filter(
        (element) => element.checked === true
      );
      !checked.length &&
        setError("group", {
          type: "required",
          message: "Minimum of one box must be checked",
        });
    }
  }, [onSubmit]);

  useEffect(() => {
    const data = roleSelector.system_roles;

    if (initData && data.length) {
      if (initData.group.length) {
        const initGroup: string[] = [];
        const checkedData: DesignationBoxI[] = [];

        initData.group.map((element: any) => {
          initGroup.push(element.id);
        });

        data.map((element) => {
          checkedData.push({
            ...element,
            checked: initGroup.includes(element.id) ? true : false,
          });
        });

        setDesgnationBoxes(checkedData);
        onChange({ group: initGroup, groupLabel: [] });
      }
    }
  }, [initData, roleSelector]);
  // ============================== <END> REACT HOOKS <END> ============================//

  // ============================== <START> EVENT HANDLERS <START> ============================//

  const handleDesignationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const data: DesignationBoxI[] = [...designationBoxes];
    const data_index: number = data.findIndex(
      (element) => element.id === event.target.value
    );
    data[data_index] = { ...data[data_index], checked: event.target.checked };
    setDesgnationBoxes(data);

    const designationList: string[] = [];
    const designationLabelList: string[] = [];

    const checkedData = data.filter((element) => element.checked === true);
    checkedData.map((element) => {
      designationList.push(element.id);
      designationLabelList.push(element.name);
    });

    checkedData.length
      ? clearErrors("group")
      : setError("group", {
          type: "required",
          message: "Minimum of one box must be checked",
        });

    onChange({ group: designationList, groupLabel: designationLabelList });
  };
  // ============================== <END> EVENT HANDLERS <END> ============================//

  return (
    <>
      <Paper className={classes.classContent} elevation={2}>
        <InputLabel>Message to:</InputLabel>
        <div className={classes.selection}>
          {designationBoxes.length ? (
            <FormControl
              component="fieldset"
              className={classes.sectionCheckBox}
            >
              <FormGroup>
                {designationBoxes.map((element: DesignationBoxI) => (
                  <FormControlLabel
                    key={element.id}
                    control={
                      <Checkbox
                        color="primary"
                        value={element.id}
                        onChange={handleDesignationChange}
                        name={element.name}
                        checked={element.checked}
                      />
                    }
                    label={element.name}
                  />
                ))}
              </FormGroup>
            </FormControl>
          ) : (
            <span className={classes.sectionUnavailableText}>
              ** No records found
            </span>
          )}
        </div>
        <span className={classes.validationErrorInfo}>
          {errors.group && errors.group.message}
        </span>
      </Paper>
    </>
  );
};

export default GroupTab;
