import {
  GET_NOTIFICATION_LOADING,
  GET_NOTIFICATION_ERROR,
  GET_NOTIFICATION_SUCCESS,
  NotificationTypeI,
  NotificationTypeII,
  NotificationDispatchTypes,
  TeacherNotificationTypeI,
  GET_TEACHER_NOTIFICATION_LOADING,
  GET_TEACHER_NOTIFICATION_SUCCESS,
  GET_TEACHER_NOTIFICATION_ERROR,
} from '../../actions/HumanResource/Notification/NotificationActionTypes';

interface InitialStateI {
  loading: boolean;
  notifications: NotificationTypeII | null;
  error: boolean | null;
  teacher: {
    loading: boolean;
    notifications: TeacherNotificationTypeI[];
    error: boolean | null;
  };
}

const initialState: InitialStateI = {
  loading: false,
  notifications: null,
  teacher: {
    loading: false,
    notifications: [],
    error: null,
  },
  error: null,
};

const NotificationReducer = (
  state: InitialStateI = initialState,
  action: NotificationDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_NOTIFICATION_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        notifications: action.payload,
      };
    case GET_NOTIFICATION_ERROR:
      return {
        ...state,
        notifications: null,
        loading: false,
        error: true,
      };
    case GET_TEACHER_NOTIFICATION_LOADING:
      return {
        ...state,
        teacher: {
          ...state.teacher,
          loading: true,
          error: false,
          notifications: [],
        },
      };
    case GET_TEACHER_NOTIFICATION_SUCCESS:
      return {
        ...state,
        teacher: {
          ...state.teacher,
          loading: false,
          error: false,
          notifications: action.payload,
        },
      };
    case GET_TEACHER_NOTIFICATION_ERROR:
      return {
        ...state,
        teacher: {
          ...state.teacher,
          loading: false,
          error: true,
          notifications: [],
        },
      };
    default:
      return state;
  }
};

export default NotificationReducer;
