import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setSnackbar } from "../../../actions/SnackbarAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

export const getAdmissionSettings = createAsyncThunk(
  "getAdmissionSettings",
  async (undefined, { dispatch }) => {
    try {
      const res = await axios.get(`${ADMIN_API_URL}/admission-number-setting`);
      return res.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      dispatch(setSnackbar(true, "error", "Failed to get admission settings"));
    }
  }
);

export const postAdmissionSettings = createAsyncThunk(
  "postAdmissionSettings",
  async (
    data: {
      prefix: string;
      number_start_from: number;
      number_of_digit: number;
      auto_generate: boolean;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.post(
        `${ADMIN_API_URL}/admission-number-setting/`,
        data
      );

      dispatch(setSnackbar(true, "success", "Admission settings added"));

      return res.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      dispatch(setSnackbar(true, "error", "Failed to post admission settings"));
      return rejectWithValue(err.response);
    }
  }
);

export const updateAdmissionSettings = createAsyncThunk(
  "updateAdmissionSettings",
  async (
    data: {
      id: string;
      data: {
        prefix: string;
        number_start_from: number;
        number_of_digit: number;
        auto_generate: boolean;
      };
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.put(
        `${ADMIN_API_URL}/admission-number-setting/${data.id}/`,
        data.data
      );

      dispatch(setSnackbar(true, "success", "Admission settings updated"));

      return res.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      dispatch(
        setSnackbar(true, "error", "Failed to update admission settings")
      );
      return rejectWithValue(err.response);
    }
  }
);
