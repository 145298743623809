import {
  GET_TIME_TABLE_LOADING,
  GET_TIME_TABLE_ERROR,
  GET_TIME_TABLE_SUCCESS,
  GET_TIME_TABLE_BY_DAY_LOADING,
  GET_TIME_TABLE_BY_DAY_ERROR,
  GET_TIME_TABLE_BY_DAY_SUCCESS,
  TimeTableDispatchTypes,
  ADD_TIME_TABLE_LOADING,
  ADD_TIME_TABLE_ERROR,
  ADD_TIME_TABLE_SUCCESS,
  DELETE_TIME_TABLE_LOADING,
  DELETE_TIME_TABLE_ERROR,
  DELETE_TIME_TABLE_SUCCESS,
  UPDATE_TIME_TABLE_LOADING,
  UPDATE_TIME_TABLE_ERROR,
  UPDATE_TIME_TABLE_SUCCESS,
  TimeTableTypeI,
} from "../../actions/Academics/TimeTable/TimeTableActionTypes";

interface InitialStateI {
  loading: boolean;
  timeTables: TimeTableTypeI[];
  dailyRoutine: TimeTableTypeI[];
  actionPerformed: boolean;
}

const initialState: InitialStateI = {
  loading: false,
  timeTables: [],
  dailyRoutine: [],
  actionPerformed: false
};

const TimeTableReducer = (
  state: InitialStateI = initialState,
  action: TimeTableDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_TIME_TABLE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_TIME_TABLE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_TIME_TABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        timeTables: action.payload,
      };

    case GET_TIME_TABLE_BY_DAY_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_TIME_TABLE_BY_DAY_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_TIME_TABLE_BY_DAY_SUCCESS:
      const daily_routine = action.payload.timeTable.filter(
        (element) => element.day === action.payload.day
      );
      return {
        ...state,
        loading: false,
        dailyRoutine: daily_routine,
      };

    case UPDATE_TIME_TABLE_LOADING:
      return {
        ...state,
        loading: true,
        actionPerformed: false
      };

    case UPDATE_TIME_TABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        actionPerformed: true
      };

    case UPDATE_TIME_TABLE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_TIME_TABLE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_TIME_TABLE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_TIME_TABLE_SUCCESS:
      const new_data: TimeTableTypeI[] = state.timeTables;
      const item_index = new_data.findIndex(
        (element) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return {
        ...state,
        loading: false,
        timeTables: new_data,
      };

    default:
      return state;
  }
};

export default TimeTableReducer;
