import { makeStyles } from "@material-ui/core";

const TemplateStyles = makeStyles({
  btnRight: {
    color: "white",
    top: "50%",
    float: "right",
    position: "relative",
    transform: "translateY(-50%)",
  },
  title: {
    fontSize: "20px",
  },
  card: {
    padding: 7,
    border: "1px solid #c5d8dd",
    "&:hover": {
      border: "1px solid blue",
      transform: "scale(1.009)",
    },
  },
  smallbtn: {
    backgroundColor: "#a7a4a444",
    marginLeft: "5px",
    marginRight: "5px",
    "&:hover": {
      backgroundColor: "#64A1C2",
      color: "white",
    },
  },
  smallbtnActive: {
    backgroundColor: "blue",
    marginLeft: "5px",
    marginRight: "5px",
    color: "white",
    "&:hover": {
      backgroundColor: "#64A1C2",
      color: "white",
    },
  },
  ImgContainer: {
    position: "relative",
    "&:hover > div:hover": {
      cursor: "pointer",
      opacity: "0.8",
    },
  },
  ImgActions: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: "100%",
    width: "100%",
    opacity: 0,
    transition: ".5s ease",
    backgroundColor: "#4a76b4",
  },
  actions: {
    color: "white",
    fontSize: "20px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
  deleteicon: {
    border: "2px solid #ff2200",
    borderRadius: "6px",
    padding: "2px",
  },
  editicon: {
    border: "2px solid #0436ff",
    borderRadius: "6px",
    padding: "2px",
  },
  activeicon: {
    border: "2px solid #00ffdd",
    borderRadius: "6px",
    padding: "2px",
  },
  tooltip: {
    marginRight: "5px",
    marginLeft: "5px",
  },
  activeTab: {
    backgroundColor: "#829ec2",
    color: "white",
    '&:hover': {
      backgroundColor: '#5984b7'
    }
  },
  nonactiveTab: {
    "&:hover": {
      color: "#5984b7",
    },
  },
});

export default TemplateStyles;