import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  FeeStudentPayloadI,
  StudentFeesI,
  StudentFeesPayloadI,
} from "../feeDiscount/feeDiscount";
import { getFeeStudents, getStudentFees } from "../feeDiscount/feeDiscountApi";
import { AppliedFeeStudentsI, AppliedStudentFeesI } from "./appliedFee";
import { deleteAppliedStudentFee } from "./appliedFeeApi";

interface InitialStateI {
  loading: boolean;
  actionPerformed: boolean;
  appliedFeeStudents: AppliedFeeStudentsI[];
  appliedStudentFees: AppliedStudentFeesI[];
  searchParams: {
    fee_type: string;
    month: { id: string; name: string } | null;
  } | null;
}

const initialState: InitialStateI = {
  loading: false,
  actionPerformed: false,
  appliedFeeStudents: [],
  appliedStudentFees: [],
  searchParams: null,
};

const appliedFeeSlice = createSlice({
  name: "appliedFee",
  initialState,
  reducers: {
    setSearchParams: (
      state,
      {
        payload,
      }: PayloadAction<{
        fee_type: string;
        month: { id: string; name: string } | null;
      }>
    ) => {
      state.searchParams = {
        fee_type: payload.fee_type,
        month: payload.month,
      };
    },
    resetState: (state) => {
      state.actionPerformed = false;
      state.loading = false;
      state.appliedFeeStudents = [];
      state.appliedStudentFees = [];
      state.searchParams = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStudentFees.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(
      getStudentFees.fulfilled,
      (state, { payload }: PayloadAction<StudentFeesPayloadI[]>) => {
        state.loading = false;
        const data: AppliedStudentFeesI[] = [];
        payload.forEach((el) => {
          data.push({
            id: el.id,
            fee_head: el.fee_type.name,
            month: el.month,
            fee_amount: el.fee_type.grade_fee_amount.amount,
          });
        });
        state.appliedStudentFees = [...data];
      }
    );
    builder.addCase(getStudentFees.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getFeeStudents.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(
      getFeeStudents.fulfilled,
      (state, { payload }: PayloadAction<FeeStudentPayloadI>) => {
        state.loading = false;
        const data: AppliedFeeStudentsI[] = [];
        payload.students.forEach((el) => {
          data.push({
            id: el.id,
            admission_number: el.student.student_user.username,
            student_name:
              el.student.student_user.first_name +
              " " +
              el.student.student_user.last_name,
            student_category: el.student.student_category?.name || "--",
            fee_head_id: payload.fee_detail.id,
            fee_head: payload.fee_detail.name,
            fee_amount: payload.fee_detail.grade_fee_amount.amount,
          });
        });
        state.appliedFeeStudents = [...data];
      }
    );
    builder.addCase(getFeeStudents.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteAppliedStudentFee.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(
      deleteAppliedStudentFee.fulfilled,
      (state, { payload }: PayloadAction<{ id: string | null }>) => {
        state.loading = false;
        state.actionPerformed = true;

        if (payload.id) {
          state.appliedStudentFees = state.appliedStudentFees.filter(
            (el) => el.id !== payload.id
          );
        }
      }
    );
    builder.addCase(deleteAppliedStudentFee.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { resetState, setSearchParams } = appliedFeeSlice.actions;

export default appliedFeeSlice.reducer;
