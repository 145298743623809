export const GET_PARENT_PROFILE_LOADING = "GET_PARENT_PROFILE_LOADING";
export const GET_PARENT_PROFILE_ERROR = "GET_PARENT_PROFILE_ERROR";
export const GET_PARENT_PROFILE_SUCCESS = "GET_PARENT_PROFILE_SUCCESS";
export const UPDATE_PARENT_PROFILE_LOADING = "UPDATE_PARENT_PROFILE_LOADING";
export const UPDATE_PARENT_PROFILE_ERROR = "UPDATE_PARENT_PROFILE_ERROR";
export const UPDATE_PARENT_PROFILE_SUCCESS = "UPDATE_PARENT_PROFILE_SUCCESS";

//===============================<START>GET PARENT_PROFILE<START>======================//

export interface GetProfileLoading {
  type: typeof GET_PARENT_PROFILE_LOADING;
}

export interface GetProfileError {
  type: typeof GET_PARENT_PROFILE_ERROR;
}

export interface GetProfileSuccess {
  type: typeof GET_PARENT_PROFILE_SUCCESS;
  payload: any;
}

//===============================<END>GET PARENT_PROFILE<END>======================//

//===============================<START>UPDATE PARENT_PROFILE<START>======================//

export interface UpdateProfileLoading {
  type: typeof UPDATE_PARENT_PROFILE_LOADING;
}

export interface UpdateProfileError {
  type: typeof UPDATE_PARENT_PROFILE_ERROR;
}

export interface UpdateProfileSuccess {
  type: typeof UPDATE_PARENT_PROFILE_SUCCESS;
  payload: any;
}

//===============================<END>UPDATE PARENT_PROFILE<END>======================//

export type ProfileDispatchTypes =
  | GetProfileLoading
  | GetProfileError
  | GetProfileSuccess
  | UpdateProfileLoading
  | UpdateProfileError
  | UpdateProfileSuccess;
