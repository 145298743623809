import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  LinearProgress,
  Paper,
} from "@material-ui/core";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useCardStyles } from "../../Styles/CardStyles";
import AddNewButton from "../../Reusable/Buttons/AddNewButton";
import { Add, DeleteForeverOutlined } from "@material-ui/icons";
import ActionButton from "../../Reusable/Buttons/ActionButton";
import CustomizedPopUp from "../../Reusable/Dialogs/CustomizedPopUp";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import { GetAllTeachers } from "../../../actions/HumanResource/Staff/StaffAction";
import SelectStaffsModal from "./SelectStaffsModal";
import { getEmployeesToSync } from "../../../rtk/features/attendanceDevice/attendanceDeviceThunk";

const HeadSection = styled.div`
  text-align: right;
  margin-bottom: 8px;
`;

const NoDataCell = styled.td`
  padding: 4rem 0;
  background-color: #f2f2f2;
  text-align: center;
`;

const DeleteIcon = styled(DeleteForeverOutlined)`
  && {
    color: #e96c5a;
  }
`;

export default function StaffTab() {
  const classes = useCardStyles();
  const dispatch = useDispatch();

  const [staffModal, toggleStaffModal] = useState<boolean>(false);
  const [confirmModal, toggleConfirmModal] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getEmployeesToSync("ba710b5e-afbd-4fd4-ade2-ba3e283f81a0"));
  }, []);

  const { loading, actionPerformed, syncedEmployees } = useSelector(
    (state: RootStore) => state.attendanceDevice
  );

  const handleRemoveClick = (id: string) => {
    toggleConfirmModal(true);
  };

  const onDeleteConfirmed = () => {
    //
  };

  return (
    <>
      <Paper
        className={classes.infobar}
        elevation={0}
        style={{ paddingTop: "0" }}
      >
        <HeadSection>
          <AddNewButton
            title="Add Staffs to Device"
            type="button"
            color="primary"
            variant="outlined"
            startIcon={<Add />}
            onClick={() => {
              toggleStaffModal(true);
            }}
          >
            Add Staffs
          </AddNewButton>
        </HeadSection>
        <TableContainer className={classes.roota}>
          <Table
            size="small"
            className={classes.table}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell>S.N</TableCell>
                <TableCell>Staff Name</TableCell>
                <TableCell>Department</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {syncedEmployees.length ? (
                syncedEmployees.map((row, index) => (
                  <TableRow key={index + 1}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      {row.user__first_name} {row.user__last_name}
                    </TableCell>
                    <TableCell>
                      {row.staff_faculty_info__department__name}
                    </TableCell>
                    <TableCell>
                      <ActionButton
                        title="Remove Staff"
                        onClick={() => handleRemoveClick(String(row.id))}
                      >
                        <DeleteIcon />
                      </ActionButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <NoDataCell colSpan={5}>
                    {loading ? "Loading..." : "No Staffs added."}
                  </NoDataCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <CustomizedPopUp
        title="Add Staffs"
        open={staffModal}
        onClose={() => toggleStaffModal(false)}
        actionConfirmed={() => {
          //
        }}
        maxWidth={900}
        dialogTitle="Select Staffs to Add to Device"
        okText={false}
        cancel={false}
      >
        <SelectStaffsModal handleModalClose={() => toggleStaffModal(false)} />
      </CustomizedPopUp>
      <CustomizedDialogs
        open={confirmModal}
        onClose={() => toggleConfirmModal(false)}
        deleteConfirmed={onDeleteConfirmed}
      />
    </>
  );
}
