export const GET_CLASS_TEACHER_LOADING = "GET_CLASS_TEACHER_LOADING";
export const GET_CLASS_TEACHER_ERROR = "GET_CLASS_TEACHER_ERROR";
export const GET_CLASS_TEACHER_SUCCESS = "GET_CLASS_TEACHER_SUCCESS";

export const ADD_CLASS_TEACHER_LOADING = "ADD_CLASS_TEACHER_LOADING";
export const ADD_CLASS_TEACHER_ERROR = "ADD_CLASS_TEACHER_ERROR";
export const ADD_CLASS_TEACHER_SUCCESS = "ADD_CLASS_TEACHER_SUCCESS";

export const UPDATE_CLASS_TEACHER_LOADING = "UPDATE_CLASS_TEACHER_LOADING";
export const UPDATE_CLASS_TEACHER_ERROR = "UPDATE_CLASS_TEACHER_ERROR";
export const UPDATE_CLASS_TEACHER_SUCCESS = "UPDATE_CLASS_TEACHER_SUCCESS";

export const DELETE_CLASS_TEACHER_LOADING = "DELETE_CLASS_TEACHER_LOADING";
export const DELETE_CLASS_TEACHER_ERROR = "DELETE_CLASS_TEACHER_ERROR";
export const DELETE_CLASS_TEACHER_SUCCESS = "DELETE_CLASS_TEACHER_SUCCESS";

export type ClassTeacherTypeI = {
  id: string;
  section: string;
  grade: string;
  section_name: string;
  grade_name: string;
  teacher: number;
  teacher_first_name: string;
  teacher_last_name: string;
  created_by: string;
  general_info_name: string;
  count?: number;
};

//===============================<START>GET CLASS TEACHER<START>======================//

export interface GetClassTeacherLoading {
  type: typeof GET_CLASS_TEACHER_LOADING;
}

export interface GetClassTeacherError {
  type: typeof GET_CLASS_TEACHER_ERROR;
}

export interface GetClassTeacherSuccess {
  type: typeof GET_CLASS_TEACHER_SUCCESS;
  payload: ClassTeacherTypeI[];
}

//===============================<END>GET CLASS TEACHER<END>======================//

//===============================<START>ADD CLASS TEACHER<START>======================//

export interface AddClassTeacherLoading {
  type: typeof ADD_CLASS_TEACHER_LOADING;
}

export interface AddClassTeacherError {
  type: typeof ADD_CLASS_TEACHER_ERROR;
}

export interface AddClassTeacherSuccess {
  type: typeof ADD_CLASS_TEACHER_SUCCESS;
  payload: ClassTeacherTypeI;
}

//===============================<END>ADD CLASS TEACHER<END>======================//

//===============================<START>UPDATE CLASS TEACHER<START>======================//

export interface UpdateClassTeacherLoading {
  type: typeof UPDATE_CLASS_TEACHER_LOADING;
}

export interface UpdateClassTeacherError {
  type: typeof UPDATE_CLASS_TEACHER_ERROR;
}

export interface UpdateClassTeacherSuccess {
  type: typeof UPDATE_CLASS_TEACHER_SUCCESS;
  payload: ClassTeacherTypeI;
}

//===============================<END>UPDATE CLASS TEACHER<END>======================//

//===============================<START>DELETE CLASS TEACHER<START>======================//

export interface DeleteClassTeacherLoading {
  type: typeof DELETE_CLASS_TEACHER_LOADING;
}

export interface DeleteClassTeacherError {
  type: typeof DELETE_CLASS_TEACHER_ERROR;
}

export interface DeleteClassTeacherSuccess {
  type: typeof DELETE_CLASS_TEACHER_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE CLASS TEACHER<END>======================//

export type ClassTeacherDispatchTypes =
  | GetClassTeacherLoading
  | GetClassTeacherError
  | GetClassTeacherSuccess
  | AddClassTeacherLoading
  | AddClassTeacherError
  | AddClassTeacherSuccess
  | UpdateClassTeacherLoading
  | UpdateClassTeacherError
  | UpdateClassTeacherSuccess
  | DeleteClassTeacherLoading
  | DeleteClassTeacherError
  | DeleteClassTeacherSuccess;
