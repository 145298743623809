import { Dispatch } from "redux";

import { SetSnackBarI, SET_SNACKBAR } from "./SnackbarActionTypes";

export const setSnackbar = (
  snackbarOpen: boolean,
  snackbarType: "warning" | "success" | "error" | "info" = "success",
  snackbarMessage = ""
) => (dispatch: Dispatch<SetSnackBarI>) => {
  dispatch({
    type: SET_SNACKBAR,
    payload: {
      snackbarOpen: snackbarOpen,
      snackbarType: snackbarType,
      snackbarMessage: snackbarMessage,
    },
  });
};
