import {
  GET_FEE_INVOICE_LOADING,
  GET_FEE_INVOICE_ERROR,
  GET_FEE_INVOICE_SUCCESS,
  GET_INVOICE_RECORD_LOADING,
  GET_INVOICE_RECORD_ERROR,
  GET_INVOICE_RECORD_SUCCESS,
  GET_STATEMENT_DETAILS_LOADING,
  GET_STATEMENT_DETAILS_ERROR,
  GET_STATEMENT_DETAILS_SUCCESS,
  GET_INVOICE_CHANGES_LOG_LOADING,
  GET_INVOICE_CHANGES_LOG_ERROR,
  GET_INVOICE_CHANGES_LOG_SUCCESS,
  GET_COLLECT_FEES_LOADING,
  GET_COLLECT_FEES_ERROR,
  GET_COLLECT_FEES_SUCCESS,
  CLOSE_INVOICE_PRINT,
  InvoiceRecordI,
  CollectFeesDispatchTypes,
  FeeInvoiceI,
  StatementDetailsI,
  InvoiceChangeLogI,
  CollectFeesPayloadI,
  COLLECT_FEES_LOADING,
  COLLECT_FEES_SUCCESS,
  COLLECT_FEES_ERROR,
} from "../../actions/Fees/CollectFees/CollectFeesActionTypes";

interface InitialStateI {
  loading: boolean;
  detail: any[];
  fee_type: any[];
  fee_invoice: any;
  invoice_record: InvoiceRecordI | null;
  statement_details: StatementDetailsI | null;
  changes_log: InvoiceChangeLogI[];
  collected_fee: any | null;
  fee_collected: boolean;
  collect_fees: any;
  add_or_update: boolean;
}

const initialState: InitialStateI = {
  loading: false,
  detail: [],
  fee_type: [],
  fee_invoice: [],
  invoice_record: null,
  statement_details: null,
  changes_log: [],
  collected_fee: null,
  fee_collected: false,
  collect_fees: null,
  add_or_update: false,
};

const CollectFeesReducer = (
  state: InitialStateI = initialState,
  action: CollectFeesDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_FEE_INVOICE_LOADING:
      return {
        ...state,
        fee_collected: false,
        loading: true,
      };

    case GET_FEE_INVOICE_ERROR:
      return {
        ...state,
        fee_collected: false,
        loading: false,
      };

    case GET_FEE_INVOICE_SUCCESS:
      return {
        ...state,
        fee_collected: false,
        loading: false,
        fee_invoice: action.payload,
      };

    case GET_COLLECT_FEES_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_COLLECT_FEES_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_COLLECT_FEES_SUCCESS:
      return {
        ...state,
        loading: false,
        collect_fees: action.payload,
      };

    case GET_INVOICE_RECORD_LOADING:
      return {
        ...state,
        fee_collected: false,
        loading: true,
      };

    case GET_INVOICE_RECORD_SUCCESS:
      return {
        ...state,
        fee_collected: false,
        loading: false,
        invoice_record: action.payload,
      };

    case GET_INVOICE_RECORD_ERROR:
      return {
        ...state,
        fee_collected: false,
        loading: false,
      };

    case GET_STATEMENT_DETAILS_LOADING:
      return {
        ...state,
        fee_collected: false,
        loading: true,
      };

    case GET_STATEMENT_DETAILS_SUCCESS:
      return {
        ...state,
        fee_collected: false,
        loading: false,
        statement_details: action.payload,
      };

    case GET_STATEMENT_DETAILS_ERROR:
      return {
        ...state,
        fee_collected: false,
        loading: false,
        statement_details: null,
      };

    case GET_INVOICE_CHANGES_LOG_LOADING:
      return {
        ...state,
        fee_collected: false,
        loading: true,
      };

    case GET_INVOICE_CHANGES_LOG_SUCCESS:
      return {
        ...state,
        fee_collected: false,
        loading: false,
        changes_log: action.payload,
      };

    case GET_INVOICE_CHANGES_LOG_ERROR:
      return {
        ...state,
        fee_collected: false,
        loading: false,
        statement_details: null,
      };

    case COLLECT_FEES_LOADING:
      return {
        ...state,
        loading: true,
        fee_collected: false,
        collected_fee: null,
        add_or_update: false,
      };

    case COLLECT_FEES_SUCCESS:
      return {
        ...state,
        loading: false,
        fee_collected: true,
        collected_fee: action.payload[0],
        add_or_update: true,
      };

    case COLLECT_FEES_ERROR:
      return {
        ...state,
        loading: false,
        fee_collected: false,
        collected_fee: null,
        add_or_update: true,
      };

    case CLOSE_INVOICE_PRINT:
      return {
        ...state,
        loading: false,
        detail: [],
        fee_type: [],
        fee_invoice: [],
        invoice_record: null,
        statement_details: null,
        changes_log: [],
        collected_fee: null,
        fee_collected: false,
        collect_fees: null,
        add_or_update: false,
      };

    default:
      return state;
  }
};

export default CollectFeesReducer;
