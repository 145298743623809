export const GET_IDCARD_TEMPLATE_LOADING = "GET_IDCARD_TEMPLATE_LOADING";
export const GET_IDCARD_TEMPLATE_ERROR = "GET_IDCARD_TEMPLATE_ERROR";
export const GET_IDCARD_TEMPLATE_SUCCESS = "GET_IDCARD_TEMPLATE_SUCCESS";

export const ADD_IDCARD_TEMPLATE_LOADING = "ADD_IDCARD_TEMPLATE_LOADING";
export const ADD_IDCARD_TEMPLATE_ERROR = "ADD_IDCARD_TEMPLATE_ERROR";
export const ADD_IDCARD_TEMPLATE_SUCCESS = "ADD_IDCARD_TEMPLATE_SUCCESS";

export const ACTIVATE_OR_DEACTIVATE_IDCARD_TEMPLATE_LOADING =
  "ACTIVATE_OR_DEACTIVATE_IDCARD_TEMPLATE_LOADING";
export const ACTIVATE_OR_DEACTIVATE_IDCARD_TEMPLATE_ERROR =
  "ACTIVATE_OR_DEACTIVATE_IDCARD_TEMPLATE_ERROR";
export const ACTIVATE_OR_DEACTIVATE_IDCARD_TEMPLATE_SUCCESS =
  "ACTIVATE_OR_DEACTIVATE_IDCARD_TEMPLATE_SUCCESS";

export const GET_SINGLE_IDCARD_TEMPLATE_LOADING =
  "GET_SINGLE_IDCARD_TEMPLATE_LOADING";
export const GET_SINGLE_IDCARD_TEMPLATE_ERROR =
  "GET_SINGLE_IDCARD_TEMPLATE_ERROR";
export const GET_SINGLE_IDCARD_TEMPLATE_SUCCESS =
  "GET_SINGLE_IDCARD_TEMPLATE_SUCCESS";

export const UPDATE_IDCARD_TEMPLATE_LOADING = "UPDATE_IDCARD_TEMPLATE_LOADING";
export const UPDATE_IDCARD_TEMPLATE_ERROR = "UPDATE_IDCARD_TEMPLATE_ERROR";
export const UPDATE_IDCARD_TEMPLATE_SUCCESS = "UPDATE_IDCARD_TEMPLATE_SUCCESS";

export const DELETE_IDCARD_TEMPLATE_LOADING = "DELETE_IDCARD_TEMPLATE_LOADING";
export const DELETE_IDCARD_TEMPLATE_ERROR = "DELETE_IDCARD_TEMPLATE_ERROR";
export const DELETE_IDCARD_TEMPLATE_SUCCESS = "DELETE_IDCARD_TEMPLATE_SUCCESS";

export type ColorI = {
  key: string;
  value: string;
  name: string;
};

export type TemplateDataI = {
  id: string;
  code: string;
  thumbnail: string;
  type: string;
  text_color: ColorI[];
  back_ground_color: ColorI[];
};

//===============================<START>GET CLASS<START>======================//

export interface GetIdCardTemplateLoading {
  type: typeof GET_IDCARD_TEMPLATE_LOADING;
}

export interface GetIdCardTemplateError {
  type: typeof GET_IDCARD_TEMPLATE_ERROR;
}

export interface GetIdCardTemplateSuccess {
  type: typeof GET_IDCARD_TEMPLATE_SUCCESS;
  payload: TemplateDataI[];
}

//===============================<END>GET CLASS<END>======================//

//===============================<START>GET CLASS<START>======================//

export interface GetSingleIdCardTemplateLoading {
  type: typeof GET_SINGLE_IDCARD_TEMPLATE_LOADING;
}

export interface GetSingleIdCardTemplateError {
  type: typeof GET_SINGLE_IDCARD_TEMPLATE_ERROR;
}

export interface GetSingleIdCardTemplateSuccess {
  type: typeof GET_SINGLE_IDCARD_TEMPLATE_SUCCESS;
  payload: any;
}

//===============================<END>GET CLASS<END>======================//

//===============================<START>GET CLASS<START>======================//

export interface UpdateIdCardTemplateLoading {
  type: typeof UPDATE_IDCARD_TEMPLATE_LOADING;
}

export interface UpdateIdCardTemplateError {
  type: typeof UPDATE_IDCARD_TEMPLATE_ERROR;
}

export interface UpdateIdCardTemplateSuccess {
  type: typeof UPDATE_IDCARD_TEMPLATE_SUCCESS;
  payload: any;
}

//===============================<END>GET CLASS<END>======================//

//===============================<START>ADD CLASS<START>======================//

export interface AddIdCardTemplateLoading {
  type: typeof ADD_IDCARD_TEMPLATE_LOADING;
}

export interface AddIdCardTemplateError {
  type: typeof ADD_IDCARD_TEMPLATE_ERROR;
}

export interface AddIdCardTemplateSuccess {
  type: typeof ADD_IDCARD_TEMPLATE_SUCCESS;
  payload: TemplateDataI;
}

//===============================<END>ADD CLASS<END>======================//

//===============================<START>UPDATE CLASS<START>======================//

export interface ActivateOrDeactivateIdCardTemplateLoading {
  type: typeof ACTIVATE_OR_DEACTIVATE_IDCARD_TEMPLATE_LOADING;
}

export interface ActivateOrDeactivateIdCardTemplateError {
  type: typeof ACTIVATE_OR_DEACTIVATE_IDCARD_TEMPLATE_ERROR;
}

export interface ActivateOrDeactivateIdCardTemplateSuccess {
  type: typeof ACTIVATE_OR_DEACTIVATE_IDCARD_TEMPLATE_SUCCESS;
  payload: any;
}

//===============================<END>UPDATE CLASS<END>======================//

//===============================<START>GET CLASS<START>======================//

export interface DeleteIdCardTemplateLoading {
  type: typeof DELETE_IDCARD_TEMPLATE_LOADING;
}

export interface DeleteIdCardTemplateError {
  type: typeof DELETE_IDCARD_TEMPLATE_ERROR;
}

export interface DeleteIdCardTemplateSuccess {
  type: typeof DELETE_IDCARD_TEMPLATE_SUCCESS;
  payload: any;
}

//===============================<END>GET CLASS<END>======================//

export type IdCardTemplateDispatchTypes =
  | GetIdCardTemplateLoading
  | GetIdCardTemplateError
  | GetIdCardTemplateSuccess
  | GetSingleIdCardTemplateLoading
  | GetSingleIdCardTemplateError
  | GetSingleIdCardTemplateSuccess
  | AddIdCardTemplateLoading
  | AddIdCardTemplateError
  | AddIdCardTemplateSuccess
  | ActivateOrDeactivateIdCardTemplateLoading
  | ActivateOrDeactivateIdCardTemplateError
  | ActivateOrDeactivateIdCardTemplateSuccess
  | DeleteIdCardTemplateLoading
  | DeleteIdCardTemplateError
  | DeleteIdCardTemplateSuccess
  | UpdateIdCardTemplateLoading
  | UpdateIdCardTemplateError
  | UpdateIdCardTemplateSuccess;
