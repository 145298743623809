import { UserType } from "../../Auth/Login/LoginActionTypes";

export const GET_VIEW_CHILD_LOADING = "GET_VIEW_CHILD_LOADING";
export const GET_VIEW_CHILD_ERROR = "GET_VIEW_CHILD_ERROR";
export const GET_VIEW_CHILD_SUCCESS = "GET_VIEW_CHILD_SUCCESS";

export const GET_VIEW_CHILDREN_LOADING = "GET_VIEW_CHILDREN_LOADING";
export const GET_VIEW_CHILDREN_ERROR = "GET_VIEW_CHILDREN_ERROR";
export const GET_VIEW_CHILDREN_SUCCESS = "GET_VIEW_CHILDREN_SUCCESS";

export const GET_CHILDREN_ACADEMIC_LOADING = "GET_CHILDREN_ACADEMIC_LOADING";
export const GET_CHILDREN_ACADEMIC_ERROR = "GET_CHILDREN_ACADEMIC_ERROR";
export const GET_CHILDREN_ACADEMIC_SUCCESS = "GET_CHILDREN_ACADEMIC_SUCCESS";

export const UPDATE_CHILDREN_PASSWORD_LOADING =
  "UPDATE_CHILDREN_PASSWORD_LOADING";
export const UPDATE_CHILDREN_PASSWORD_ERROR = "UPDATE_CHILDREN_PASSWORD_ERROR";
export const UPDATE_CHILDREN_PASSWORD_SUCCESS =
  "UPDATE_CHILDREN_PASSWORD_SUCCESS";

export interface ChildrenAcademicTypeI {
  id: string;
  student: number;
  student_first_name: string;
  student_last_name: string;
  grade: number;
  grade_name: string;
  section: number;
  section_name: string;
  roll_number: null;
  house: number;
  last_school_name: string;
  last_percentage: string;
  last_grade: number;
  sports: string | null;
  academic_session: number;
}

export interface ViewChildrenPropsI {
  id: number;
  student_first_name: string;
  student_last_name: string;
}

export interface ViewAcademicInfo {
  id: number;
  roll_number: number | null;
  last_school_name: string;
  last_percentage: string;
  last_grade: number;
  sports: string | null;
  grade: number;
  grade_name: string;
  section: string | null;
  house: number;
  house_name: string;
  academic_session: number;
  student: number;
}

export interface ViewDocument {
  id: number;
  student: number;
  title: string;
  document: string;
  created_by: string;
}

export interface ViewChildDetailProps {
  id: number;
  student_photo: string;
  admission_number: number;
  student_user: UserType;
  address: string;
  student_category: number;
  religion: string;
  religion_display: string;
  phone_number: string | null;
  date_of_birth: string;
  gender: string;
  blood_group: string;
  blood_group_display: string;
  medical_history: string;
  transportation_detail: number | null;
  disable: boolean;
  student_document: ViewDocument[];
  academic_info: ViewAcademicInfo[];
}

//===============================<START>GET STUDENT_HOUSE<START>======================//

export interface GetViewChildLoading {
  type: typeof GET_VIEW_CHILD_LOADING;
}

export interface GetViewChildError {
  type: typeof GET_VIEW_CHILD_ERROR;
}

export interface GetViewChildSuccess {
  type: typeof GET_VIEW_CHILD_SUCCESS;
  payload: ViewChildrenPropsI[];
}

//=================================================//

//===============================<START>GET VIEW_CHILDREN<START>======================//
export interface GetViewChildrenLoading {
  type: typeof GET_VIEW_CHILDREN_LOADING;
}

export interface GetViewChildrenError {
  type: typeof GET_VIEW_CHILDREN_ERROR;
}

export interface GetViewChildrenSuccess {
  type: typeof GET_VIEW_CHILDREN_SUCCESS;
  payload: ViewChildDetailProps;
}

//===============================<END>GET VIEW_CHILDREN<END>======================//

//===============================<START>GET STUDENT_ACADEMIC<START>======================//

export interface GetChildrenAcademicLoading {
  type: typeof GET_CHILDREN_ACADEMIC_LOADING;
}

export interface GetChildrenAcademicError {
  type: typeof GET_CHILDREN_ACADEMIC_ERROR;
}

export interface GetChildrenAcademicSuccess {
  type: typeof GET_CHILDREN_ACADEMIC_SUCCESS;
  payload: ChildrenAcademicTypeI[];
}

//===============================<END>GET STUDENT_ACADEMIC<END>======================//

//===============================<START>UPDATE_CHILDREN_PASSWORD<START>======================//

export interface UpdateChildrenPasswordLoading {
  type: typeof UPDATE_CHILDREN_PASSWORD_LOADING;
}

export interface UpdateChildrenPasswordError {
  type: typeof UPDATE_CHILDREN_PASSWORD_ERROR;
}

export interface UpdateChildrenPasswordSuccess {
  type: typeof UPDATE_CHILDREN_PASSWORD_SUCCESS;
}

//===============================<END>UPDATE_CHILDREN_PASSWORD<END>======================//

export type ViewChildDispatchTypes =
  | GetViewChildLoading
  | GetViewChildError
  | GetViewChildSuccess
  | GetViewChildrenLoading
  | GetViewChildrenError
  | GetViewChildrenSuccess
  | GetChildrenAcademicLoading
  | GetChildrenAcademicError
  | GetChildrenAcademicSuccess
  | UpdateChildrenPasswordLoading
  | UpdateChildrenPasswordError
  | UpdateChildrenPasswordSuccess;
