import axios from "axios";
import { Dispatch } from "redux";
import {
  GET_LESSON_PLAN_LOADING,
  GET_LESSON_PLAN_ERROR,
  GET_LESSON_PLAN_SUCCESS,
  LessonPlanDispatchTypes,
} from "./AdminLessonPlanActionTypes";
import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

export const GetAdminLessonPlan =
  (classID: number, subject: number) =>
  async (dispatch: Dispatch<LessonPlanDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_LESSON_PLAN_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/lesson_plan/?academic_class=${classID}&subject=${subject}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_LESSON_PLAN_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_LESSON_PLAN_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "Lesson Plan Loading Failed",
      //   },
      // });
    }
  };
