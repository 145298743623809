import {
  GET_STUDENT_HOUSE_LOADING,
  GET_STUDENT_HOUSE_ERROR,
  GET_STUDENT_HOUSE_SUCCESS,
  ADD_STUDENT_HOUSE_LOADING,
  ADD_STUDENT_HOUSE_ERROR,
  ADD_STUDENT_HOUSE_SUCCESS,
  DELETE_STUDENT_HOUSE_LOADING,
  DELETE_STUDENT_HOUSE_ERROR,
  DELETE_STUDENT_HOUSE_SUCCESS,
  UPDATE_STUDENT_HOUSE_LOADING,
  UPDATE_STUDENT_HOUSE_ERROR,
  UPDATE_STUDENT_HOUSE_SUCCESS,
  StudentHouseTypeI,
  StudentHouseDispatchTypes,
} from "../../actions/Student/StudentHouse/StudentHouseActionTypes";

interface InitialStateI {
  loading: boolean;
  student_houses: StudentHouseTypeI[];
  add_or_update: boolean;
}

const initialState: InitialStateI = {
  loading: false,
  student_houses: [],
  add_or_update: false,
};

const DepartmentReducer = (
  state: InitialStateI = initialState,
  action: StudentHouseDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_STUDENT_HOUSE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_STUDENT_HOUSE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_STUDENT_HOUSE_SUCCESS:
      return {
        ...state,
        loading: false,
        student_houses: action.payload,
      };

    case ADD_STUDENT_HOUSE_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case ADD_STUDENT_HOUSE_ERROR:
      return {
        ...state,
        loading: false,
        add_or_update: true,
      };

    case ADD_STUDENT_HOUSE_SUCCESS:
      return {
        loading: false,
        student_houses: [...state.student_houses, action.payload],
        add_or_update: true,
      };

    case UPDATE_STUDENT_HOUSE_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case UPDATE_STUDENT_HOUSE_ERROR:
      return {
        ...state,
        loading: false,
        add_or_update: true,
      };

    case UPDATE_STUDENT_HOUSE_SUCCESS:
      const current_data: StudentHouseTypeI[] = state.student_houses;
      const index: number = current_data.findIndex(
        (element) => element.id === action.payload.id
      );
      current_data[index] = action.payload;

      return {
        loading: false,
        student_houses: current_data,
        add_or_update: true,
      };

    case DELETE_STUDENT_HOUSE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_STUDENT_HOUSE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_STUDENT_HOUSE_SUCCESS:
      const new_data: StudentHouseTypeI[] = state.student_houses;
      const item_index = new_data.findIndex(
        (element) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return {
        ...state,
        loading: false,
        student_houses: new_data,
      };

    default:
      return state;
  }
};

export default DepartmentReducer;
