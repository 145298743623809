import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { useStyles } from "../../Styles/FormStyles";
import { FormSaveLoadingButton } from "../../Reusable/Buttons/FormButton";
import Spinner from "../../../common/spinnerSVG/Spinner";
import styled from "styled-components";
import { RootStore } from "../../../store";
import { useCardStyles } from "../../Styles/CardStyles";
import { syncDepartmentsToDevice } from "../../../rtk/features/attendanceDevice/attendanceDeviceThunk";
import { useParams } from "react-router-dom";

const ModalBody = styled.div`
  padding: 0 20px;
  min-width: 480px;
  max-width: 600px;
`;

const NoDataCell = styled.td`
  padding: 4rem 0;
  background-color: #f2f2f2;
  text-align: center;
`;

const SelectDepartmentsModal = (props: any) => {
  const { deviceId, configId } = useParams<{
    deviceId: string;
    configId: string;
  }>();
  const classes = useCardStyles();

  // Custom Styles
  const { handleModalClose } = props;

  // Dispatch Variable
  const dispatch = useDispatch();

  // State Declaration
  const [checkedRows, setCheckedRows] = useState<string[]>([]);

  const { loading, departments } = useSelector(
    (state: RootStore) => state.department
  );

  const handleCheckAll = () => {
    if (allChecked) {
      setCheckedRows([]);
    } else {
      setCheckedRows(departments.map((el) => el.id));
    }
  };

  const handleCheckRow = (id: string) => {
    const currentRows = [...checkedRows];

    if (currentRows.includes(id)) {
      const newRows = currentRows.filter((el) => el !== id);
      setCheckedRows(newRows);
    } else {
      currentRows.push(id);
      setCheckedRows(currentRows);
    }
  };

  const handleSyncClick = () => {
    if (checkedRows.length) {
      dispatch(
        syncDepartmentsToDevice({
          id: configId,
          departments: { departments: checkedRows },
        })
      );
    }
  };

  const allChecked = departments.length
    ? departments.length === checkedRows.length
    : false;

  return (
    <ModalBody>
      <Grid container>
        <TableContainer className={classes.roota}>
          <Table
            size="small"
            className={classes.table}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox checked={allChecked} onClick={handleCheckAll} />
                </TableCell>
                <TableCell>S.N</TableCell>
                <TableCell>Department Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {departments.length ? (
                departments.map((row, index) => (
                  <TableRow key={index + 1}>
                    <TableCell>
                      <Checkbox
                        onChange={() => handleCheckRow(row.id)}
                        checked={checkedRows.includes(row.id)}
                      />
                    </TableCell>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{row.name}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <NoDataCell colSpan={5}>
                    {loading ? "Loading..." : "No Deparments added."}
                  </NoDataCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid
        container
        justifyContent="flex-start"
        style={{ marginBottom: "1em" }}
      >
        <FormSaveLoadingButton
          type="submit"
          icon={false ? <Spinner /> : <SaveIcon />}
          disabled={!checkedRows.length ? true : false}
          text={false ? "Saving..." : "Save to Device"}
          onClick={handleSyncClick}
        />
      </Grid>
    </ModalBody>
  );
};

export default SelectDepartmentsModal;
