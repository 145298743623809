import React from "react";
import { makeStyles } from "@material-ui/core";
import NCard from "./NCard";
import Img from "../../../images/boy.png";
import Boy from "../../../images/boy.png";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
});

const NumberCard = (props: any) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <NCard
        text="Students"
        num={props.data?.students_count}
        profile={Boy}
        border="8px solid #3FC581"
      />
      <NCard
        text="Teachers"
        num={props.data?.teachers_count}
        profile={Img}
        border="8px solid #E96C5A"
      />
      <NCard
        text="Staffs"
        num={props.data?.staffs_count}
        profile={Img}
        border="8px solid #7C78E3"
      />
    </div>
  );
};

export default NumberCard;
