// ---------------- <START> module import ------------------//
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import {
  AddEventType,
  UpdateEventType,
} from "../../../actions/Event/EventType/EventTypeAction";
import FormLayout from "../../Reusable/Layouts/Form.Layout";
import CustomizedTextField from "../../Reusable/Inputs/TextField";
import { useFormStyles } from "../../Styles/FormStyles";
// ---------------- <END> module import ------------------//

// ---------------- <START> Interface------------------//
interface FormDataI {
  id: string;
  name: string;
}

interface PropsI {
  editData: FormDataI | null;
  onEditCheck: (value: boolean) => void;
}
// ---------------- <END> Interface------------------//

// ---------------- <START> Event Type Component ------------------//
const EventTypeForm = (props: PropsI) => {
  const { editData, onEditCheck } = props;
  const dispatch = useDispatch();
  const classes = useFormStyles();

  const { register, handleSubmit, setValue, reset, errors } = useForm({
    mode: "onChange",
  });

  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [editID, setEditID] = useState<string>("");
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);

  const event_typeLoading = useSelector(
    (state: RootStore) => state.event_type.loading
  );
  const add_or_update = useSelector((state: RootStore) => state.event_type.add_or_update)

  useEffect(() => {
    if (add_or_update) {
      setIsBtnLoading(false)
      handleReset();
    }
  }, [add_or_update])

  useEffect(() => {
    setLoading(event_typeLoading);
  }, [event_typeLoading]);

  useEffect(() => {
    editData != null && handleEditTableData(editData);
  }, [editData]);

  const onFormSubmit = (data: FormDataI) => {
    setIsBtnLoading(true);
    if (editMode) {
      editID != null && dispatch(UpdateEventType(editID, data));
    } else {
      dispatch(AddEventType(data));
      reset();
    }
    onEditCheck(false);
  };

  const handleEditTableData = (data: FormDataI) => {
    setEditMode(true);
    setValue("event_type", data.name);
    setEditID(data.id);
  };

  const handleReset = () => {
    reset();
    setEditMode(false);
    onEditCheck(false);
  };

  return (
    <>
      <FormLayout
        title={editMode ? "Edit Event Type" : "Add Event Type"}
        onSubmit={handleSubmit(onFormSubmit)}
        editMode={editMode}
        loading={loading}
        onClick={!isBtnLoading && handleReset}
        add_or_update={isBtnLoading}
      >
        <CustomizedTextField
          label="Event Type Name"
          placeholder="Event Type Name"
          name="event_type"
          required
          error={errors["event_type"] ? "Required Field." : ""}
          inputRef={register({ required: true })}
        />
        <span className={classes.validationErrorInfo}>
          {errors.route_to?.type === "serverSideError" &&
            errors.route_to.message}
        </span>
      </FormLayout>
    </>
  );
};
// ---------------- <END> Event Type Component ------------------//

export default EventTypeForm;
