import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { Link as RouterLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { IconButton, InputAdornment, InputLabel } from '@material-ui/core';

interface State {
  password: string;
  showPassword: boolean;
}

interface LoginFormI {
  username: string;
  password: string;
}

const PasswordField = (props: any) => {
  const { disableForget = true, setValue, label = 'Password', ...rest } = props;

  const { register } = useForm<LoginFormI>();
  const [values, setValues] = React.useState<State>({
    password: '',
    showPassword: false,
  });

  useEffect(() => {
    setValue(values.password);
  }, [values]);

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleChange = (event: any) => {
    setValues({ ...values, password: event.target.value });
  };

  return (
    <React.Fragment>
      <Grid container justifyContent="space-between">
        <Grid item>
          <InputLabel>
            {label}
            <span style={{ color: 'red' }}>*</span>
          </InputLabel>
        </Grid>
        {!disableForget && (
          <Grid item>
            <Link
              color="inherit"
              variant="caption"
              component={RouterLink}
              to="/authentication/password-recovery"
            >
              Forgot password?
            </Link>
          </Grid>
        )}
      </Grid>

      <TextField
        variant="filled"
        margin="dense"
        required
        fullWidth
        type={values.showPassword ? 'text' : 'password'}
        autoComplete="current-password"
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {values.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...rest}
      />
    </React.Fragment>
  );
};

export default PasswordField;
