import {
    GET_FEE_INCOME_ERROR,
    GET_FEE_INCOME_LOADING,
    GET_FEE_INCOME_SUCCESS,
    FeeIncomeDispatchTypes,
} from "../../actions/Fees/FeeIncome/FeeIncomeActionTypes";

interface InitialStateI {
    loading: boolean;
    fee_income: any;
}

const initialState: InitialStateI = {
    loading: false,
    fee_income: [],
};

const FeeIncomeReducer = (
    state: InitialStateI = initialState,
    action: FeeIncomeDispatchTypes
): InitialStateI => {
    switch (action.type) {
        case GET_FEE_INCOME_LOADING:
            return {
                ...state,
                loading: true,
            };

        case GET_FEE_INCOME_ERROR:
            return {
                ...state,
                loading: false,
            };

        case GET_FEE_INCOME_SUCCESS:
            return {
                ...state,
                loading: false,
                fee_income: action.payload,
            };


        default:
            return state;
    }
};

export default FeeIncomeReducer;