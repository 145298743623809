import React from 'react';
import { Button, IconButton, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  InsertDriveFileOutlined,
  Send as SendIcon,
  SettingsBackupRestore as SettingsBackupRestoreIcon,
} from '@material-ui/icons';
import spinner from '../../../common/spinnerSVG/Spinner';

const btnStyles = makeStyles((theme: any) => ({
  root: {
    // padding: '8px 0px',
    paddingTop: 7,
    paddingBottom: 7,
    '&:disabled': {
      backgroundColor: '#1f4498 !important',
      opacity: '0.6',
    },
  },
}));

const FormButton = (props: any) => {
  const classes = btnStyles();
  return (
    <Button
      color="primary"
      variant={props.variant ? props.variant : 'contained'}
      disabled={props.loading}
      // type={props.type ? props.type : "submit"}
      type="submit"
      // style={{ width: "120px" }}
      className={classes.root}
      {...props}
    >
      {props.children}
    </Button>
  );
};

const FormCreateButton = (props: any) => {
  return (
    <FormButton startIcon={<AddIcon />} {...props}>
      Create
    </FormButton>
  );
};

const FormDraftButton = (props: any) => {
  return (
    <FormButton startIcon={<InsertDriveFileOutlined />} {...props}>
      Draft
    </FormButton>
  );
};

const FormEditButton = (props: any) => {
  return (
    <FormButton style={{ width: '90px' }} startIcon={<EditIcon />} {...props}>
      Update
    </FormButton>
  );
};

const FormSaveButton = (props: any) => {
  return (
    <FormButton startIcon={<SaveIcon />} type="submit" {...props}>
      Save
    </FormButton>
  );
};

const FormSaveLoadingButton = (props: any) => {
  return (
    <FormButton
      startIcon={props.icon ? props.icon : <SaveIcon />}
      type="submit"
      {...props}
    >
      {props.text ? props.text : 'Save'}
    </FormButton>
  );
};

const FormSendButon = (props: any) => {
  return (
    <FormButton
      startIcon={<SendIcon />}
      style={{ minWidth: '150px' }}
      {...props}
    >
      Send Request
    </FormButton>
  );
};

const FormResetButton = (props: any) => {
  return (
    <Tooltip title="Clear All Data">
      <FormButton
        type="button"
        variant="outlined"
        style={{ marginLeft: '5px', width: props.fullwidth ? '100%' : '' }}
        onClick={props.onClick}
      >
        {props?.title ? props.title : 'Reset'}
      </FormButton>
    </Tooltip>
  );
};

const CancelButton = (props: any) => {
  return (
    <Button color="default" variant="contained" component={Link} {...props}>
      Cancel
    </Button>
  );
};

const FormRefreshButton = (props: any) => {
  return (
    <Tooltip title="Clear Search Query">
      <IconButton aria-label="delete" style={{ padding: '2px' }} {...props}>
        <SettingsBackupRestoreIcon />
      </IconButton>
    </Tooltip>
  );
};

export default FormButton;
export {
  FormCreateButton,
  FormEditButton,
  FormSaveButton,
  CancelButton,
  FormSendButon,
  FormResetButton,
  FormRefreshButton,
  FormSaveLoadingButton,
  FormDraftButton,
};
