import { IconButton, InputLabel, Tooltip, Grid } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { AddIncomeTypeI } from "../../../actions/Fees/Income/IncomeActionTypes";
import { RootStore } from "../../../store";
import CustomizedNepaliDatePicker from "../../Reusable/Inputs/NepaliDatePicker";
import CustomizedSelect from "../../Reusable/Inputs/Select";
import CustomizedTextField from "../../Reusable/Inputs/TextField";
import FormLayout from "../../Reusable/Layouts/Form.Layout";
import { CloudUpload } from "@material-ui/icons";
import {
  dateConverterAdToBs,
  dateConverterBsToAd,
} from "../../utils/dateConverter";
import { AddExpensesTypeI } from "../../../actions/Fees/Expenses/ExpenseActionTypes";
import {
  AddExpensesDetails,
  GetExpensesType,
  UpdateExpensesDetails,
} from "../../../actions/Fees/Expenses/ExpenseAction";
import { useFormStyles } from "../../Styles/FormStyles";
import { CUR_NEPALI_DATE } from "../../utils/nepaliDateUtils";

interface PropsI {
  editData: AddExpensesTypeI | null;
  onEditCheck: (value: boolean) => void;
}

interface SelectIncomeType {
  key: string;
  value: string;
}

const AddExpensesForm = (props: PropsI) => {
  const { editData, onEditCheck } = props;
  const classes = useFormStyles();
  const dispatch = useDispatch();

  //States Declaration===============//
  const [expensesType, setExpensesType] = useState<any>(null);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [editID, setEditID] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [docName, setDocName] = useState<string | null>("");
  const [document1Name, setDocument1Name] = useState<any | null>(null);
  const [date, setDate] = useState<string>(CUR_NEPALI_DATE);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);

  const expensesLoading = useSelector(
    (state: RootStore) => state.expense_type.loading
  );
  const add_or_update = useSelector(
    (state: RootStore) => state.expense_type.add_or_update
  );

  useEffect(() => {
    if (add_or_update) {
      setIsBtnLoading(false);
      handleReset();
    }
  }, [add_or_update]);

  useEffect(() => {
    dispatch(GetExpensesType());
  }, []);

  const AddExpensesSelector = useSelector(
    (state: RootStore) => state.expense_type.expense_type
  );

  const { register, handleSubmit, setValue, setError, reset, errors } =
    useForm<AddExpensesTypeI>({
      mode: "onChange",
    });

  const EXPENSES_TYPE_CHOICES = AddExpensesSelector.map((item) => ({
    key: item.id.toString(),
    value: item.name,
  }));

  //===================================<START>CYCLE HOOKS<START>===========================//

  useEffect(() => {
    setLoading(expensesLoading);
  }, [expensesLoading]);

  useEffect(() => {
    editData != null && handleEditTableData(editData);
  }, [editData]);

  //===================================<END>CYCLE HOOKS<END>===========================//

  const handleFileUpload = ({ target }: any) => {
    setSelectedFile(target.files[0]);
  };

  const handleEditTableData = (data: AddExpensesTypeI) => {
    const documentName = data.documents
      ? data.documents.split("/").pop()
      : "Browse";
    setEditMode(true);
    setEditID(data.id);

    setValue("name", data.name);
    setValue("amount", data.amount);
    setValue("description", data.description);
    data.documents && setSelectedFile(data.documents);
    setDocName(documentName);
    setExpensesType(
      EXPENSES_TYPE_CHOICES.find((item) => item.key == data.name) ||
        EXPENSES_TYPE_CHOICES[0]
    );
    setDate(dateConverterAdToBs(data.expense_date));
  };

  const onFormSubmit = (data: AddIncomeTypeI) => {
    setIsBtnLoading(true);
    if (expensesType) {
      const formData = new FormData();
      selectedFile
        ? !selectedFile[5] && formData.append("documents", selectedFile)
        : null;
      !selectedFile && formData.append("documents", "");

      formData.append("expense_type_name", expensesType?.value);
      formData.append("expense_type", expensesType?.key);
      formData.append("expense_date", dateConverterBsToAd(date));
      formData.append("name", data.name);
      formData.append("amount", data.amount);
      formData.append("description", data.description);

      if (editMode && editID) {
        dispatch(UpdateExpensesDetails(editID, formData));
      } else {
        dispatch(AddExpensesDetails(formData));
      }
    }
  };

  const handleReset = () => {
    reset();
    setEditMode(false);
    setExpensesType(null);
    setSelectedFile(null);
    setEditID("");
    onEditCheck(false);
  };

  //----------------------ERROR HANDLING---------------------------//
  const [serverErrors, setServerErrors] = useState<any>(null);
  const expenseSelector = useSelector((state: RootStore) => state.expense_type);
  const errorsData = expenseSelector.errors;
  const initialErrorsData = useRef(errorsData);

  useEffect(() => {
    if (initialErrorsData.current === errorsData) {
      initialErrorsData.current = errorsData; // Do not set initial errors
    } else {
      if (errorsData?.error != null) {
        const keys = Object.keys(errorsData?.error);
        keys.map((elem: any) => {
          setError(elem, {
            type: "serverSideError",
            message: errorsData.error[elem] && errorsData.error[elem][0],
          });
        });
      }
      setServerErrors(errorsData);
    }
  }, [errorsData]);

  useEffect(() => {
    if (expenseSelector.recent) {
      handleReset();
    }
  }, [expenseSelector.recent]);

  return (
    <>
      <FormLayout
        title={editMode ? "Edit Expense" : "Add Expense"}
        onSubmit={handleSubmit(onFormSubmit)}
        editMode={editMode}
        loading={loading}
        onClick={!isBtnLoading && handleReset}
        add_or_update={isBtnLoading}
      >
        <CustomizedSelect
          label="Expense Type"
          required
          options={EXPENSES_TYPE_CHOICES}
          value={expensesType}
          setValue={setExpensesType}
          error={errors["expense_type"] ? "Expense Type must be selected" : ""}
          inputRef={register({ required: true })}
        />
        <CustomizedTextField
          label="Name"
          placeholder="Name"
          name="name"
          required
          error={errors["name"] ? "Required Field." : ""}
          inputRef={register({ required: true })}
        />
        <CustomizedNepaliDatePicker
          label="Expense Date"
          value={date}
          setValue={setDate}
        />
        <CustomizedTextField
          label="Amount"
          placeholder="Amount"
          name="amount"
          error={errors["amount"] ? "Required Field." : ""}
          inputRef={register({ required: true })}
        />
        <span className={classes.validationErrorInfo}>
          {errors.amount?.type === "serverSideError" && errors.amount.message}
        </span>
        <Grid container>
          <Grid item xs={12}>
            <InputLabel>Choose file</InputLabel>

            <input id="faceImage" type="file" onChange={handleFileUpload} />
            <Tooltip title="Select File">
              <label htmlFor="faceImage">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <CloudUpload fontSize="large" />
                </IconButton>
              </label>
            </Tooltip>
            <label>
              {selectedFile != null || undefined
                ? !selectedFile[5]
                  ? selectedFile.name
                  : selectedFile.substring(selectedFile.lastIndexOf("/") + 1)
                : "Select File"}
            </label>
          </Grid>
        </Grid>

        <CustomizedTextField
          label="Description"
          placeholder="Description"
          name="description"
          required
          multiline
          rows={4}
          error={errors["description"] ? "Required Field." : ""}
          inputRef={register({ required: true })}
        />
      </FormLayout>
    </>
  );
};

export default AddExpensesForm;
