import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  InputLabel,
  TextField,
  TableHead,
  TableBody,
  Table,
  Button,
} from "@material-ui/core";
import { useTableStyles } from "../../Styles/TableStyles";
import { useTableWithPagination } from "../../Reusable";
import { RootStore } from "../../../store";
import { useDispatch, useSelector } from "react-redux";

import SearchStudentsForm from "./SearchStudentsForm";
import BackDropLoader from "../../Reusable/BackDropLoader";
import styled from "styled-components";
import { Edit, Save } from "@material-ui/icons";
import {
  assignAlphabeticallyAction,
  changeRollNumberAction,
} from "../../../rtk/features/student/studentRollNumSlices";
import { updateRollNum } from "../../../rtk/features/student/rollNumApi";
import AccessPermission from "../StudentDetail/AccessPermission";

const CellInput = styled.input<{ error?: boolean }>`
  && {
    width: 80px;
    padding: 5px;
    border-radius: 3px;
    border: ${(props) => (props.error ? `1px solid red` : `1px solid #000`)};
    color: ${(props) => (props.error ? `red` : `#000`)};

    &:focus {
      outline: none;
    }
  }
`;
// ---------------------------- <END> interface ends ----------------------------------//
// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells = [
  { id: "sn", label: "S.N" },
  { id: "student_name", label: "Student Name" },
  { id: "class", label: "Class" },
  { id: "roll_no", label: "Roll No" },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const StudentRollNumTable: React.FC = () => {
  const classes = useTableStyles();
  const dispatch = useDispatch();
  const { loading, students, actionPerformed } = useSelector(
    (state: RootStore) => state.rollNum
  );
  const [editMode, setEditMode] = useState<boolean>(false);
  const [errorFound, setErrorFound] = useState<boolean>(false);

  useEffect(() => {
    if (actionPerformed) {
      setEditMode(false);
    }
  }, [actionPerformed]);

  useEffect(() => {
    if (students.length) {
      if (students.find((el) => el.error)) {
        setErrorFound(true);
      } else {
        setErrorFound(false);
      }
    } else {
      setErrorFound(false);
    }
  }, [students]);

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTableWithPagination(headCells);

  const handleSaveRollNum = () => {
    const post_data = students.map((item) => ({
      student: item.academic_id,
      roll_no: item.roll_number,
    }));
    dispatch(updateRollNum(post_data));
  };

  return (
    <Paper className={classes.rootTableBox}>
      <div style={{ paddingBottom: "8px" }}>
        <SearchStudentsForm />
      </div>
      <Grid container>
        <AccessPermission>
          <Button
            variant="contained"
            color="primary"
            disabled={!editMode}
            style={{ marginBottom: "10px" }}
            onClick={() => dispatch(assignAlphabeticallyAction())}
          >
            Assign Alphabetically
          </Button>
        </AccessPermission>
        <Table>
          <TableHead>
            <StyledTableRow>
              {headCells.map((item) => (
                <StyledTableCell key={item.id}>{item.label}</StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {students.map((item, index) => (
              <StyledTableRow key={item.academic_id}>
                <StyledTableCell>{index + 1}</StyledTableCell>
                <StyledTableCell>{`${item.student_first_name} ${item.student_last_name}`}</StyledTableCell>
                <StyledTableCell>{`${item.grade} ${
                  item.section || ""
                }`}</StyledTableCell>
                <StyledTableCell>
                  {editMode ? (
                    <CellInput
                      value={item.roll_number || ""}
                      maxLength={3}
                      onChange={(e) =>
                        dispatch(
                          changeRollNumberAction({
                            id: item.academic_id,
                            value: e.target.value,
                          })
                        )
                      }
                      error={item.error}
                    />
                  ) : (
                    item.roll_number || "--"
                  )}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        <AccessPermission>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setEditMode(!editMode)}
            startIcon={<Edit />}
            style={{ margin: "10px 10px 0 0" }}
          >
            {editMode ? "Cancel" : "Edit"}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSaveRollNum()}
            startIcon={<Save />}
            style={{ margin: "10px 10px 0 0" }}
            disabled={!editMode || errorFound}
          >
            Save
          </Button>
        </AccessPermission>
      </Grid>
      <BackDropLoader open={loading} />
    </Paper>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default StudentRollNumTable;
