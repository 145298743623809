//---------------------<start> module imports starts ----------------------//
import {
  Avatar,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  InputLabel,
  Paper,
  TextField,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useFormStyles } from "../../Styles/FormStyles";
import { Autocomplete } from "@material-ui/lab";
import { Search } from "@material-ui/icons";
import { RootStore } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { GetDates } from "../../../actions/Dates/DatesAction";
import { GetClasses } from "../../../actions/Academics/Class/ClassAction";
import { GetSections } from "../../../actions/Academics/Section/SectionAction";
import {
  GetStudentByName,
  SearchStudent,
  StudentSearch,
} from "../../../actions/Student/Student/StudentAction";
import { useForm } from "react-hook-form";
import CustomizedTextField from "../../Reusable/Inputs/TextField";
import defaultBase64 from "../../Student/ParentForm/defaultBase64";
import { GetInvoiceHistory } from "../../../actions/Fees/CollectFees/CollectFeesAction";
import CustomizedNepaliDatePicker from "../../Reusable/Inputs/NepaliDatePicker";
import { ResetButton, SubmitButton } from "../../Reusable/Buttons/SearchButton";
import { GetDaybook } from "../../../actions/Fees/Daybook/DaybookAction";
import {
  dateConverterAdToBs,
  dateConverterBsToAd,
} from "../../utils/dateConverter";
import { MONTH_CHOICES } from "../../../common/json.constant";
import CustomizedSelect from "../../Reusable/Inputs/Select";
import {
  ClearIntimationSlip,
  GetIntimationSlip,
} from "../../../actions/Fees/IntimationSlip/IntimationSlipAction";

// -----------------<end> module imports ends -----------------------//
// -----------------<start> Interfaces <start>-----------------------//

// -----------------<end> Interfaces <end>-----------------------//

// -----------------<starts> Selected Student Form starts-----------------------//
const IntimateFeeSearch = (props: any) => {
  const {
    grade,
    setGrade,
    section,
    setSection,
    month,
    setMonth,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
  } = props;

  const classes = useFormStyles();
  const { student, setStudent } = props;
  const [gradeChoices, setGradeChoices] = useState<any | null>([]);
  const [sectionChoices, setSectionChoices] = useState<any[] | []>([]);
  const [sectionDisabler, setSectionDisabler] = useState<boolean>(true);
  const [studentSelectOpen, setStudentSelectOpen] = useState<boolean>(false);
  const [studentChoices, setStudentChoices] = useState<any>([]);
  const gradeList = useSelector((state: RootStore) => state.class.classes);
  const [monthName, setMonthName] = useState<any | null>(null);
  const [currentMonth, setCurrentMonth] = useState<number | null>(null);

  const studentList = useSelector((state: RootStore) => state.student);

  const studentLoading = studentSelectOpen && studentList.loading === true;

  const feesHead = useSelector(
    (state: RootStore) => state.collect_fees?.collect_fees
  );

  const dateSelector = useSelector(
    (state: RootStore) => state.month_dates.data
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const utc = new Date().toJSON().slice(0, 10);
    setCurrentMonth(parseInt(dateConverterAdToBs(utc).substring(5, 7)) - 1);
    dispatch(GetDates(parseInt(dateConverterAdToBs(utc).substring(5, 7))));
  }, []);

  useEffect(() => {
    if (currentMonth != null) {
      dispatch(GetDates(currentMonth + 1));
      setMonth(MONTH_CHOICES[currentMonth]);
    }
  }, [currentMonth]);

  useEffect(() => {
    dispatch(GetSections());
    dispatch(GetClasses());
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    errors,
    setError,
    clearErrors,
  } = useForm<any>({
    mode: "onChange",
  });

  useEffect(() => {
    gradeList && setGradeChoices(gradeList);
  }, [gradeList]);

  const handleMonthChange = (value: any) => {
    console.log({ value });
    if (value != null) {
      setMonth(value);
      dispatch(GetDates(value.key));
    } else {
      setMonth(null);
    }
  };

  const handleGradeChange = (value: any | null) => {
    if (value) {
      setGrade(value);
      populateSectionChoices(value.section);
      dispatch(StudentSearch(value.grade, ""));
    } else {
      setSectionDisabler(true);
    }
  };

  const populateSectionChoices = (sections: any | []) => {
    setSectionDisabler(false);
    sections.length > 0
      ? setSectionChoices(sections)
      : setSectionDisabler(true);
  };

  useEffect(() => {
    if (section != null && grade != null) {
      dispatch(StudentSearch(grade.grade, section.id));
    }
  }, [section, grade]);

  useEffect(() => {
    if (studentList.searched_student != null) {
      setStudentChoices(studentList.searched_student);
    }
  }, [studentList.searched_student]);

  console.log({ studentChoices });

  const handleSectionChange = (value: any) => {
    setSection(value);
  };

  const handleStudentChange = (val: any) => {
    if (val != null) {
      setStudent(val);
    }
  };

  const onStudentInputChange = (event: any, value: any) => {
    console.log(value);
    dispatch(GetStudentByName(value));
  };

  useEffect(() => {
    if (student !== null) {
      dispatch(GetInvoiceHistory(student?.academic_id));
    }
  }, [student]);

  const handleStartDate = (data: string) => {
    setStartDate(data);
  };

  const handleEndDate = (data: string) => {
    setEndDate(data);
  };

  const onSubmit = () => {
    console.log(grade, section, month);
    if (grade !== ("" || null) && month.key !== ("" || null)) {
      dispatch(
        GetIntimationSlip(
          1,
          grade?.grade || "",
          section?.id || "",
          month?.key || "",
          startDate || "",
          endDate || ""
        )
      );
    }
  };

  const restData = () => {
    dispatch(ClearIntimationSlip());
    setGrade(null);
    setSection(null);
    setStartDate("");
    setEndDate("");
    setMonth("");
  };

  useEffect(() => {
    console.log({ dateSelector });
    if (dateSelector.dates != null) {
      const from_date = dateConverterBsToAd(dateSelector.dates[0]);
      const to_date = dateConverterBsToAd(
        dateSelector.dates[dateSelector.dates.length - 1]
      );
      setStartDate(from_date);
      setEndDate(to_date);
    }
  }, [dateSelector.dates, month]);

  return (
    <>
      <div style={{ padding: "15px" }}>
        {/* <div style={{ display: "flex", justifyContent: "space-between" }}> */}
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div>
                <form
                  className={classes.form}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  {props.title && (
                    <Grid container>
                      <Grid item xs={12} className={classes.formTitle}>
                        <span>{props.title}</span>
                      </Grid>
                    </Grid>
                  )}
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      <InputLabel>Class</InputLabel>
                      <Autocomplete
                        onChange={(
                          event: React.ChangeEvent<{}>,
                          value: any | null
                        ) => handleGradeChange(value)}
                        options={gradeChoices}
                        getOptionLabel={(option) =>
                          "Class" + " " + option.grade_name
                        }
                        value={grade}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select a Grade"
                            name="class"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={2} className={classes.formWrapper}>
                      <InputLabel>Section</InputLabel>
                      <Autocomplete
                        onChange={(
                          event: React.ChangeEvent<{}>,
                          value: any | null
                        ) => handleSectionChange(value)}
                        options={sectionChoices}
                        value={section && section}
                        getOptionLabel={(option) => option.name}
                        disabled={sectionDisabler}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select a Section"
                            name="section"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={2} className={classes.formWrapper}>
                      {/* <InputLabel>Month</InputLabel>
                      <Autocomplete
                        onChange={(
                          event: React.ChangeEvent<{}>,
                          value: any | null
                        ) => handleMonthChange(value)}
                        options={MONTH_CHOICES || []}
                        value={month && month}
                        getOptionLabel={(option) => option.vaue}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Month"
                            name="month"
                            variant="outlined"
                          />
                        )}
                      /> */}
                      <CustomizedSelect
                        label="Month"
                        name="month"
                        options={MONTH_CHOICES}
                        value={month}
                        setValue={handleMonthChange}
                      />
                    </Grid>
                    {/* <Grid item xs={2} className={classes.formWrapper}>
                      <CustomizedNepaliDatePicker
                        value={startDate}
                        name="start_date"
                        setValue={handleStartDate}
                        label="Start Date"
                      />
                    </Grid>
                    <Grid item xs={2} className={classes.formWrapper}>
                      <CustomizedNepaliDatePicker
                        value={endDate}
                        name="end_date"
                        setValue={handleEndDate}
                        label="End Date"
                      />
                    </Grid> */}
                    <SubmitButton style={{ marginTop: "5px" }} />
                    <ResetButton
                      onClick={restData}
                      style={{ marginTop: "5px" }}
                    />
                  </Grid>
                </form>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};
// -----------------<end> Selected Student form ends-----------------------//
export default IntimateFeeSearch;
