export const GENERAL_INFO_LOADING = "GENERAL_INFO_LOADING";
export const GENERAL_INFO_ERROR = "GENERAL_INFO_ERROR";
export const GENERAL_INFO_SUCCESS = "GENERAL_INFO_SUCCESS";

export const GET_USERS_BY_ROLE_LOADING = "GET_USERS_BY_ROLE_LOADING";
export const GET_USERS_BY_ROLE_ERROR = "GET_USERS_BY_ROLE_ERROR";
export const GET_USERS_BY_ROLE_SUCCESS = "GET_USERS_BY_ROLE_SUCCESS";

export const ADD_GENERAL_INFO_LOADING = "ADD_GENERAL_INFO_LOADING";
export const ADD_GENERAL_INFO_ERROR = "ADD_GENERAL_INFO_ERROR";
export const ADD_GENERAL_INFO_SUCCESS = "ADD_GENERAL_INFO_SUCCESS";

export const UPDATE_GENERAL_INFO_LOADING = "UPDATE_GENERAL_INFO_LOADING";
export const UPDATE_GENERAL_INFO_ERROR = "UPDATE_GENERAL_INFO_ERROR";
export const UPDATE_GENERAL_INFO_SUCCESS = "UPDATE_GENERAL_INFO_SUCCESS";

export type GeneralInfoType = {
  id: string;
  logo: string;
  school_name: string;
  address: string;
  phone_number: string;
  email: string;
  slogan: string;
  school_reg_number: string;
};

export type UsersByRoleI = {
  id: string;
  first_name: string;
  last_name: string;
};

//===============================<START>GET GENERAL_INFO<START>======================//

export interface GeneralInfoLoading {
  type: typeof GENERAL_INFO_LOADING;
}

export interface GeneralInfoError {
  type: typeof GENERAL_INFO_ERROR;
}

export interface GeneralInfoSuccess {
  type: typeof GENERAL_INFO_SUCCESS;
  payload: any;
}

//===============================<END>GET GENERAL_INFO<END>======================//

//===============================<START>GET USERS_BY_ROLE<START>======================//

export interface GetUsersByRoleLoading {
  type: typeof GET_USERS_BY_ROLE_LOADING;
}

export interface GetUsersByRoleError {
  type: typeof GET_USERS_BY_ROLE_ERROR;
}

export interface GetUsersByRoleSuccess {
  type: typeof GET_USERS_BY_ROLE_SUCCESS;
  payload: UsersByRoleI[];
}

//===============================<END>GET GET_USERS_BY_ROLE<END>======================//

//===============================<START>ADD GENERAL_INFO<START>======================//

export interface AddGeneralInfoLoading {
  type: typeof ADD_GENERAL_INFO_LOADING;
}

export interface AddGeneralInfoError {
  type: typeof ADD_GENERAL_INFO_ERROR;
}

export interface AddGeneralInfoSuccess {
  type: typeof ADD_GENERAL_INFO_SUCCESS;
  payload: any;
}

//===============================<END>ADD GENERAL_INFO<END>======================//

//===============================<START>UPDATE GENERAL_INFO<START>======================//

export interface UpdateGeneralInfoLoading {
  type: typeof UPDATE_GENERAL_INFO_LOADING;
}

export interface UpdateGeneralInfoError {
  type: typeof UPDATE_GENERAL_INFO_ERROR;
}

export interface UpdateGeneralInfoSuccess {
  type: typeof UPDATE_GENERAL_INFO_SUCCESS;
  payload: any[];
}

//===============================<END>UPDATE GENERAL_INFO<END>======================//

//===============================<END>GET GENERAL_INFO<END>======================//

export type GeneralInfoDispatchTypes =
  | GeneralInfoLoading
  | GeneralInfoError
  | GeneralInfoSuccess
  | GetUsersByRoleLoading
  | GetUsersByRoleSuccess
  | GetUsersByRoleError
  | AddGeneralInfoLoading
  | AddGeneralInfoError
  | AddGeneralInfoSuccess
  | UpdateGeneralInfoLoading
  | UpdateGeneralInfoError
  | UpdateGeneralInfoSuccess;
