import { Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetIncomeType,
  DeleteIncomeType,
} from "../../../actions/Fees/Income/IncomeAction";
import { IncomeTypeI } from "../../../actions/Fees/Income/IncomeActionTypes";
import { RootStore } from "../../../store";
import { useTable } from "../../Reusable";
import {
  ItemDeleteButton,
  ItemEditButton,
} from "../../Reusable/Buttons/TableButton";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import { useTableStyles } from "../../Styles/TableStyles";

interface HeadCellsI {
  id: string;
  label: string;
}

interface PropsI {
  onEditData: (value: IncomeTypeI) => void;
  edit: { check: boolean };
}

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "name", label: "Income Type" },
  { id: "action", label: "Action" },
];

const IncomeTable = (props: PropsI) => {
  const classes = useTableStyles();
  const { onEditData, edit } = props;
  const dispatch = useDispatch();

  //States============================================//
  const [tableData, setTableData] = useState<IncomeTypeI[] | []>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string>("");
  const [editMode, setEditMode] = useState<boolean>(false);

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);
  //==================================================//

  //===============================REDUX / Dispatch / useEffect ==================================//
  const incomeTypeSelector = useSelector(
    (state: RootStore) => state.income_type
  );

  useEffect(() => {
    dispatch(GetIncomeType());
  }, []);

  useEffect(() => {
    if (incomeTypeSelector.income_type) {
      const data = incomeTypeSelector.income_type;
      setTableData(data);
      setLoading(incomeTypeSelector.loading);
    }
  }, [incomeTypeSelector]);

  useEffect(() => {
    edit && setEditMode(edit.check);
  }, [edit]);

  //Functions=============================//
  const handleEditClicked = (data: IncomeTypeI) => {
    onEditData(data);
    setEditMode(true);
  };
  const handleDeleteModal = (value: boolean, id: string) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };
  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };
  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteIncomeType(itemId));
    setDeleteModalOpen(false);
  };
  //===========================================================//

  //Getting table Data ===================================================//
  const getCustomTableRow = (item: IncomeTypeI) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell
          align="center"
          className={classes.cell}
          style={{ textAlign: "left", paddingLeft: "15px" }}
        >
          {item.name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.twoCell}>
          <ItemEditButton
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleEditClicked(item)
            }
          />
          <ItemDeleteButton
            disabled={editMode}
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleDeleteModal(true, item.id)
            }
          />
        </StyledTableCell>
      </StyledTableRow>
    );
  };
  //=================================================================================//

  return (
    <>
      <Paper className={classes.root}>
        <Typography variant="h6">Income Type List</Typography>
        <TableContainer
          loading={loading}
          items={tableData}
          headCells={headCells}
          getCustomTableRow={getCustomTableRow}
          rowsPerPage={7}
        />
      </Paper>
      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
        dialogTitle="Are you sure to delete this record?"
        okText="Delete"
      />
    </>
  );
};

export default IncomeTable;
