import React, { useState, useRef, useEffect } from "react";
import {
  Grid,
  Paper,
  TableBody,
  TableHead,
  Typography,
  createStyles,
  Table,
  Theme,
  makeStyles,
  Chip,
} from "@material-ui/core";
import { useTable } from "../../../Reusable";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { GetInvoiceHistory } from "../../../../actions/Fees/CollectFees/CollectFeesAction";
import { useTableStyles } from "../../../Styles/TableStyles";
import { RootStore } from "../../../../store";
import TableActionButtons from "../../../Reusable/Buttons/TableActionButtons";
import { dateConverterAdToBs } from "../../../utils/dateConverter";

interface HeadCellsI {
  id: string;
  label: string;
}

const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N." },
  { id: "date", label: "Date" },
  // { id: "transaction_type", label: "Transaction Type" },
  { id: "fee_head", label: "Fees Head" },
  { id: "period", label: "Period" },
  { id: "charges", label: "Charges" },
  { id: "fine", label: "Fine" },
  { id: "balance", label: "Balance" },
  { id: "status", label: "Status" },
];

const useDataGridStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      "& th": {
        backgroundColor: "#e3e3e3",
      },
      "& .MuiTableCell-sizeSmall": {
        padding: "2px 2px 0px 2px",
        borderBottom: "0px",
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    blue: {
      backgroundColor: "#1f4498",
      color: "white",
    },
  })
);

const FeeTable = (props: any) => {
  const { statementTableData, feesHead, student, value, isInvoice } = props;
  const classes = useTableStyles();
  // const dispatch = useDispatch();
  const { studentID } = useParams<{ studentID: string }>();
  const tableCss = useDataGridStyles();
  const componentRef = useRef<any>();

  const [statementData, setStatementData] = useState<any>([]);
  const [totalPaid, setTotalPaid] = useState<number>(0);
  const [totalBalance, setTotalBalance] = useState<number>(0);

  const {
    TblContainer,
    TblHead,
    StyledTableCell,
    StyledTableRow,
    exportToExcel,
    tablePrint,
  } = useTable(headCells);

  useEffect(() => {
    if (statementData !== null) {
      const paids = statementData.filter((elem: any) => elem.status === "Paid");
      let count = 0;
      paids?.map((elem: any) => {
        count += Number(elem.balance);
      });
      setTotalPaid(count);

      const unpaids = statementData.filter(
        (elem: any) => elem.status === "Unpaid"
      );
      let counts = 0;
      unpaids?.map((elem: any) => {
        counts += Number(elem.balance);
      });
      setTotalBalance(counts);
    }
  }, [statementData]);

  // useEffect(() => {
  //     dispatch(GetInvoiceHistory(studentID));
  // }, [studentID])

  useEffect(() => {
    if (statementTableData !== null) {
      setStatementData(statementTableData);
    }
  }, [statementTableData]);

  return (
    <>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <TableActionButtons
            exportToExcel={exportToExcel}
            tablePrint={tablePrint}
          />
        </Grid>
      </Grid>
      {!props.isInvoice && (
        <Grid
          container
          style={{
            margin: "20px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <b style={{ paddingRight: "10px" }}>Opening Balance:</b>
            Debit: Rs. {feesHead?.opening_balance?.debit || 0} | Credit: Rs.{" "}
            {feesHead?.opening_balance?.credit || 0}
          </div>
        </Grid>
      )}
      <div
        id="export-table"
        ref={componentRef}
        style={{ paddingBottom: "40px", padding: "15px 0px" }}
      >
        <div style={{ maxHeight: "calc(100vh - 120px)", overflowY: "auto" }}>
          <Table
            stickyHeader
            className={tableCss.table}
            aria-label="sticky table"
          >
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="center" className={classes.cell}>
                  S.N.
                </StyledTableCell>
                <StyledTableCell align="left" className={classes.cell}>
                  Date
                </StyledTableCell>
                {/* <StyledTableCell
                                    align="left"
                                    className={classes.cell}
                                >
                                    Transaction Type
                                </StyledTableCell> */}
                {value !== 0 && (
                  <StyledTableCell align="left" className={classes.cell}>
                    Fee Head
                  </StyledTableCell>
                )}
                {value !== 0 && (
                  <StyledTableCell align="left" className={classes.cell}>
                    Period
                  </StyledTableCell>
                )}
                <StyledTableCell align="left" className={classes.cell}>
                  Charges
                </StyledTableCell>
                {value !== 0 && (
                  <StyledTableCell align="left" className={classes.cell}>
                    Fine
                  </StyledTableCell>
                )}
                <StyledTableCell align="left" className={classes.cell}>
                  Balance
                </StyledTableCell>
                {!props.isInvoice && (
                  <StyledTableCell align="left" className={classes.cell}>
                    Status
                  </StyledTableCell>
                )}
              </StyledTableRow>
            </TableHead>
            {student !== null && (
              <>
                <TableBody>
                  {statementData.map((item: any, index: number) => (
                    <StyledTableRow key={item.id}>
                      <StyledTableCell align="center" className={classes.cell}>
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell align="left" className={classes.cell}>
                        {dateConverterAdToBs(item.date)}
                      </StyledTableCell>
                      {/* <StyledTableCell align="left" className={classes.cell}>
                                            {item.transaction_type}
                                        </StyledTableCell> */}
                      {value !== 0 && (
                        <StyledTableCell align="left" className={classes.cell}>
                          {item.fee_head}
                        </StyledTableCell>
                      )}
                      {value !== 0 && (
                        <StyledTableCell align="left" className={classes.cell}>
                          {item.period}
                        </StyledTableCell>
                      )}
                      <StyledTableCell align="left" className={classes.cell}>
                        {item.charges}
                      </StyledTableCell>
                      {value !== 0 && (
                        <StyledTableCell align="left" className={classes.cell}>
                          {item.fine}
                        </StyledTableCell>
                      )}
                      <StyledTableCell align="left" className={classes.cell}>
                        {item.balance}
                      </StyledTableCell>
                      {!props.isInvoice && (
                        <StyledTableCell align="left" className={classes.cell}>
                          <b
                            style={{
                              color: item.status === "Paid" ? "green" : "red",
                            }}
                          >
                            {item.status}
                          </b>
                        </StyledTableCell>
                      )}
                    </StyledTableRow>
                  ))}
                </TableBody>
                {!props.isInvoice && (
                  <>
                    <StyledTableRow style={{ backgroundColor: "#e3e3e3" }}>
                      <StyledTableCell
                        align="left"
                        className={classes.cell}
                        colSpan={3}
                      >
                        Total Paid Amount
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        Rs.{totalPaid} /-
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"
                        className={classes.cell}
                      ></StyledTableCell>
                      <StyledTableCell
                        align="left"
                        className={classes.cell}
                      ></StyledTableCell>
                      {value !== 0 && (
                        <>
                          <StyledTableCell
                            align="left"
                            className={classes.cell}
                          ></StyledTableCell>
                          <StyledTableCell
                            align="left"
                            className={classes.cell}
                          ></StyledTableCell>
                          <StyledTableCell
                            align="left"
                            className={classes.cell}
                          ></StyledTableCell>
                        </>
                      )}
                    </StyledTableRow>
                    <StyledTableRow style={{ backgroundColor: "#e3e3e3" }}>
                      <StyledTableCell
                        align="left"
                        className={classes.cell}
                        colSpan={3}
                      >
                        Discount
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        Rs.{feesHead?.total_discount} /-
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"
                        className={classes.cell}
                      ></StyledTableCell>
                      <StyledTableCell
                        align="left"
                        className={classes.cell}
                      ></StyledTableCell>
                      {value !== 0 && (
                        <>
                          <StyledTableCell
                            align="left"
                            className={classes.cell}
                          ></StyledTableCell>
                          <StyledTableCell
                            align="left"
                            className={classes.cell}
                          ></StyledTableCell>
                          <StyledTableCell
                            align="left"
                            className={classes.cell}
                          ></StyledTableCell>
                        </>
                      )}
                    </StyledTableRow>
                    <StyledTableRow style={{ backgroundColor: "#e3e3e3" }}>
                      <StyledTableCell
                        align="left"
                        className={classes.cell}
                        colSpan={3}
                      >
                        Balance
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        Rs.{totalBalance} /-
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"
                        className={classes.cell}
                      ></StyledTableCell>
                      <StyledTableCell
                        align="left"
                        className={classes.cell}
                      ></StyledTableCell>
                      {value !== 0 && (
                        <>
                          <StyledTableCell
                            align="left"
                            className={classes.cell}
                          ></StyledTableCell>
                          <StyledTableCell
                            align="left"
                            className={classes.cell}
                          ></StyledTableCell>
                          <StyledTableCell
                            align="left"
                            className={classes.cell}
                          ></StyledTableCell>
                        </>
                      )}
                    </StyledTableRow>
                  </>
                )}
              </>
            )}
          </Table>
        </div>
      </div>
    </>
  );
};

export default FeeTable;
