import {
  GET_EXAM_SCHEDULE_LOADING,
  GET_EXAM_SCHEDULE_ERROR,
  GET_EXAM_SCHEDULE_SUCCESS,
  ExamScheduleDispatchTypes,
  GET_EXAM_SUBJECTS_LOADING,
  GET_EXAM_SUBJECTS_ERROR,
  GET_EXAM_SUBJECTS_SUCCESS,
  DELETE_EXAM_SCHEDULE_LOADING,
  DELETE_EXAM_SCHEDULE_ERROR,
  DELETE_EXAM_SCHEDULE_SUCCESS,
  UPDATE_EXAM_SCHEDULE_LOADING,
  UPDATE_EXAM_SCHEDULE_ERROR,
  UPDATE_EXAM_SCHEDULE_SUCCESS,
  ExamScheduleTypeI,
} from "../../actions/Examination/ExamSchedule/ExamScheduleActionTypes";

interface InitialStateI {
  loading: boolean;
  exam_schedules: ExamScheduleTypeI[];
  exam_subjects: ExamScheduleTypeI[];
  actionPerformed: boolean;
}

const initialState: InitialStateI = {
  loading: false,
  exam_schedules: [],
  exam_subjects: [],
  actionPerformed: false
};

const ExamScheduleReducer = (
  state: InitialStateI = initialState,
  action: ExamScheduleDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_EXAM_SCHEDULE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_EXAM_SCHEDULE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_EXAM_SCHEDULE_SUCCESS:
      return {
        ...state,
        loading: false,
        exam_schedules: action.payload,
      };

    case GET_EXAM_SUBJECTS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_EXAM_SUBJECTS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_EXAM_SUBJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        exam_subjects: action.payload,
      };

    case UPDATE_EXAM_SCHEDULE_LOADING:
      return {
        ...state,
        loading: true,
        actionPerformed: false
      };

    case UPDATE_EXAM_SCHEDULE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case UPDATE_EXAM_SCHEDULE_SUCCESS:
      return {
        ...state,
        loading: false,
        actionPerformed: true
      };

    case DELETE_EXAM_SCHEDULE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_EXAM_SCHEDULE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_EXAM_SCHEDULE_SUCCESS:
      // const new_data: ExamScheduleTypeI[] = state.exam_schedules;
      // const item_index = new_data.findIndex(
      //   (element) => element.id === action.payload.id
      // );
      // new_data.splice(item_index, 1);

      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default ExamScheduleReducer;
