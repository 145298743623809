import {
  Grid,
  Paper,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  Checkbox,
  TableHead,
  TableBody,
  TextField,
  Button,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import cx from "clsx";
import { useCheckBoxTable, useTable } from "../../Reusable";
import { useTableStyles } from "../../Styles/TableStyles";
import { SearchedStudentsI } from "../../../actions/Student/Student/StudentActionTypes";
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "../../../store";
import EditIcon from "@material-ui/icons/Edit";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import StudentSearchForm from "./StudentSearchForm";
import { addOpeningBalance } from "../../../actions/Fees/OpeningBalance/OpeningBalanceAction";
import getFullName from "../../utils/getFullName";
import { SET_SNACKBAR } from "../../../actions/SnackbarActionTypes";
import {
  FormResetButton,
  FormSaveLoadingButton,
} from "../../Reusable/Buttons/FormButton";
import Spinner from "../../../common/spinnerSVG/Spinner";
import SaveIcon from "@material-ui/icons/Save";
import getNewFullName from "../../utils/getNewFullName";
import TableAnalyticsCard from "../../../common/Cards/TableAnalytics";

interface HeadCellsI {
  id: string;
  label: string;
}

interface PropsI {
  onStudentChange: (student_ids: string[]) => void;
  title?: string;
}

const headCells: HeadCellsI[] = [
  { id: "index", label: "S.N" },
  { id: "student", label: "Student Name" },
  { id: "opening_balance", label: "Opening Balance" },
  { id: "dr", label: "Dr." },
  { id: "cr", label: "Cr." },
  { id: "remarks", label: "Remarks" },
];
const useStyles = makeStyles(() => ({
  tableCell: {
    padding: "0px 5px !important",
    borderBottom: "1px solid #D6D6D6",
    borderRight: "1px solid #D6D6D6",
  },
}));

const OpeningBalanceTable = (props: any) => {
  const { onStudentChange, grade, setGrade, section, setSection } = props;

  const classes = useTableStyles();
  const [selected, setSelected] = React.useState<string[]>([]);
  const [tableData, setTableData] = useState<SearchedStudentsI[]>([]);
  const [totalDebit, setTotalDebit] = useState<number>(0);
  const [totalCredit, setTotalCredit] = useState<number>(0);
  const [allData, setAllData] = useState<any[]>([]);
  const [editRowIndex, setEditRowIndex] = useState<number | null>();
  const [editRowPosition, setEditRowPosition] = useState<number | null>();
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const [submitClicked, setSubmitClicked] = useState<boolean>(false);

  const openingBalanceSelector = useSelector(
    (state: RootStore) => state.opening_balance?.opening_balance
  );

  const applyFeeAction = useSelector(
    (state: RootStore) => state.fee.actionPerformed
  );

  const localClasses = useStyles();

  const [editMode, setEditMode] = useState<boolean>(false);
  const add_or_update = useSelector(
    (state: RootStore) => state.opening_balance?.add_or_update
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (applyFeeAction) {
      setSelected([]);
    }
  }, [applyFeeAction]);

  useEffect(() => {
    if (add_or_update) {
      setIsBtnLoading(false);
    }
  }, [add_or_update]);

  useEffect(() => {
    if (openingBalanceSelector !== null) {
      const data: any = [];
      openingBalanceSelector?.map((elem: any) =>
        data.push({
          id: elem?.id,
          student: getNewFullName(
            elem?.student?.first_name,
            elem?.student?.last_name
          ),
          debit: elem?.current_academic_opening_balance?.debit,
          credit: elem?.current_academic_opening_balance?.credit,
          remarks: elem?.current_academic_opening_balance?.remarks,
          opened: null,
        })
      );
      setTableData(data);
    }
  }, [openingBalanceSelector]);

  useEffect(() => {
    onStudentChange(selected);
  }, [selected]);

  const handleCellClick = (e: any, index: number, position: number) => {
    setEditRowIndex(index);
    setEditRowPosition(position);
  };

  const onTextFieldChange = (e: any) => {
    if (e.keyCode === 13) {
      setEditRowIndex(null);
      setEditRowPosition(null);
    }
  };

  const {
    TblContainer,
    TblHead,
    StyledTableCell,
    StyledTableRow,
    exportToExcel,
    tablePrint,
  } = useTable(headCells);

  const handleCheckClick = (name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = tableData.map((n) => n.academic_id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  let updatedData: any[] = [];

  const handleInputChange = (
    field_name: string,
    value: string,
    academic_id: string
  ) => {
    const tempData: any[] = updatedData.length ? updatedData : tableData;
    const updated: any[] = [];

    if (field_name === "dr") {
      tempData.map((element: any) => {
        element?.id === academic_id
          ? updated.push({
              ...element,
              debit: value,
              credit: "0",
            })
          : updated.push(element);
      });
    } else if (field_name === "cr") {
      tempData.map((element: any) => {
        element?.id === academic_id
          ? updated.push({
              ...element,
              debit: "0",
              credit: value,
            })
          : updated.push(element);
      });
    } else {
      tempData.map((element: any) => {
        element?.id === academic_id
          ? updated.push({
              ...element,
              remarks: value,
            })
          : updated.push(element);
      });
    }

    updatedData = updated;
    // setAllData(updated)
  };

  const handleEditClick = () => {
    if (grade?.section?.length > 0 && section === null) {
      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "You Must Select Section",
        },
      });
    } else {
      setEditMode(true);
      updatedData = [];
    }
  };

  const handleSaveClick = () => {
    if (grade?.section?.length > 0 && section === null) {
      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "You Must Select Section",
        },
      });
    } else {
      // setEditMode(false);
      updatedData.length && setTableData(updatedData);
      const post_data: any[] = [];
      updatedData.map((element) => {
        post_data.push({
          current_academic: element?.id,
          remarks: element.remarks ? element.remarks : "",
          debit: element.debit ? element.debit : 0,
          credit: element.credit ? element.credit : 0,
        });
      });
      if (updatedData.length) {
        setSubmitClicked(true);
        setIsBtnLoading(true);
        dispatch(addOpeningBalance(post_data, grade, section));
      }
    }
  };

  useEffect(() => {
    if (submitClicked && !isBtnLoading) {
      setEditMode(false);
    }
  }, [submitClicked, isBtnLoading]);

  useEffect(() => {
    const drTotal = tableData?.reduce((accumulator: any, currentValue: any) => {
      return accumulator + Number(currentValue.debit || 0);
    }, 0);
    setTotalDebit(drTotal);

    const crTotal = tableData?.reduce((accumulator: any, currentValue: any) => {
      return accumulator + Number(currentValue.credit || 0);
    }, 0);
    setTotalCredit(crTotal);
  }, [tableData]);

  return (
    <Paper
      className={classes.rootCheckBoxTable}
      style={{ marginRight: "5px", marginLeft: "5px" }}
    >
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <StudentSearchForm
            setEditMode={setEditMode}
            title={props.title || ""}
            setGrade={setGrade}
            setSection={setSection}
            grade={grade}
            section={section}
          />
        </Grid>
        {grade || section ? (
          <Grid container spacing={2}>
            <Grid item md={6}>
              <TableAnalyticsCard
                item={{
                  icon: LocalAtmIcon,
                  title: "Total Debit",
                  color: "#E6B160",
                  total: `${totalDebit} /-`,
                  tagline: "Amount in NPR",
                }}
              />
            </Grid>
            <Grid item md={6}>
              <TableAnalyticsCard
                item={{
                  icon: LocalAtmIcon,
                  title: "Total Credit",
                  color: "#43a047",
                  total: `${totalCredit} /-`,
                  tagline: "Amount in NPR",
                }}
              />
            </Grid>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <div style={{ maxHeight: "calc(100vh - 10vh)", overflowY: "auto" }}>
            <TblContainer>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell
                    rowSpan={2}
                    align="center"
                    className={localClasses.tableCell}
                  >
                    S. No.
                  </StyledTableCell>
                  <StyledTableCell
                    rowSpan={2}
                    align="left"
                    className={localClasses.tableCell}
                  >
                    Student Name
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={2}
                    align="center"
                    className={localClasses.tableCell}
                  >
                    Opening Balance
                  </StyledTableCell>
                  <StyledTableCell
                    rowSpan={2}
                    align="center"
                    className={localClasses.tableCell}
                    width="250"
                  >
                    Remarks
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell
                    align="center"
                    className={localClasses.tableCell}
                    width="200"
                  >
                    Dr.
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    className={localClasses.tableCell}
                    width="200"
                  >
                    Cr.
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {grade &&
                  tableData.map((row: any, index: number) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center" className={classes.cell}>
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell align="left" className={classes.cell}>
                        {row.student}
                      </StyledTableCell>
                      {editMode ? (
                        <StyledTableCell
                          align="center"
                          className={classes.cell}
                        >
                          <TextField
                            type="number"
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handleInputChange(
                                "dr",
                                event.target.value,
                                row?.id
                              )
                            }
                            id={String(row?.id)}
                            defaultValue={Number(row?.debit)}
                            variant="outlined"
                          />
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell
                          align="center"
                          className={classes.cell}
                        >
                          {row?.debit || "0.00"}
                        </StyledTableCell>
                      )}
                      {editMode ? (
                        <StyledTableCell
                          align="center"
                          className={classes.cell}
                        >
                          <TextField
                            type="number"
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handleInputChange(
                                "cr",
                                event.target.value,
                                row?.id
                              )
                            }
                            id={String(row?.id)}
                            defaultValue={Number(row?.credit)}
                            variant="outlined"
                            // disabled={allData[index]?.debit === ("0.00" ? false : true}
                          />
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell
                          align="center"
                          className={classes.cell}
                        >
                          {row?.credit || "0.00"}
                        </StyledTableCell>
                      )}
                      {editMode ? (
                        <StyledTableCell
                          align="center"
                          className={classes.cell}
                        >
                          <TextField
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handleInputChange(
                                "remarks",
                                event.target.value,
                                row?.id
                              )
                            }
                            id={String(row?.id)}
                            defaultValue={row?.remarks}
                            variant="outlined"
                          />
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell
                          align="center"
                          className={classes.cell}
                        >
                          {row?.remarks || "-"}
                        </StyledTableCell>
                      )}
                    </StyledTableRow>
                  ))}
              </TableBody>
            </TblContainer>
          </div>
        </Grid>
      </Grid>
      {tableData.length ? (
        <Grid container justifyContent="flex-end">
          <Grid item xs={3}>
            {!editMode ? (
              <Button
                onClick={handleEditClick}
                className={classes.addMarksButton}
                variant="contained"
                color="primary"
                startIcon={<EditIcon />}
              >
                Edit
              </Button>
            ) : (
              <>
                <FormSaveLoadingButton
                  onClick={handleSaveClick}
                  icon={isBtnLoading ? <Spinner /> : <SaveIcon />}
                  disabled={isBtnLoading ? true : false}
                  text={isBtnLoading ? "Saving..." : "Save"}
                />
                <FormResetButton
                  onClick={() => setEditMode(false)}
                  title="Cancel"
                />
              </>
            )}
          </Grid>
        </Grid>
      ) : null}
    </Paper>
  );
};

export default OpeningBalanceTable;
