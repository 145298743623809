export const GET_FINE_LOADING = "GET_FINE_LOADING";
export const GET_FINE_ERROR = "GET_FINE_ERROR";
export const GET_FINE_SUCCESS = "GET_FINE_SUCCESS";

export const ADD_FINE_LOADING = "ADD_FINE_LOADING";
export const ADD_FINE_ERROR = "ADD_FINE_ERROR";
export const ADD_FINE_SUCCESS = "ADD_FINE_SUCCESS";

export const UPDATE_FINE_LOADING = "UPDATE_FINE_LOADING";
export const UPDATE_FINE_ERROR = "UPDATE_FINE_ERROR";
export const UPDATE_FINE_SUCCESS = "UPDATE_FINE_SUCCESS";

export const DELETE_FINE_LOADING = "DELETE_FINE_LOADING";
export const DELETE_FINE_ERROR = "DELETE_FINE_ERROR";
export const DELETE_FINE_SUCCESS = "DELETE_FINE_SUCCESS";

export type FineTypeI = {
  id: string;
  name: string;
  fine_in: string;
  fine_applicable: number;
  count: number;
};

//===============================<START>GET FINE<START>======================//

export interface GetFineLoading {
  type: typeof GET_FINE_LOADING;
}

export interface GetFineError {
  type: typeof GET_FINE_ERROR;
}

export interface GetFineSuccess {
  type: typeof GET_FINE_SUCCESS;
  payload: FineTypeI[];
}

//===============================<END>GET FINE<END>======================//

//===============================<START>ADD FINE<START>======================//

export interface AddFineLoading {
  type: typeof ADD_FINE_LOADING;
}

export interface AddFineError {
  type: typeof ADD_FINE_ERROR;
  payload: any;
}

export interface AddFineSuccess {
  type: typeof ADD_FINE_SUCCESS;
  payload: FineTypeI;
}

//===============================<END>ADD FINE<END>======================//

//===============================<START>UPDATE FINE<START>======================//

export interface UpdateFineLoading {
  type: typeof UPDATE_FINE_LOADING;
}

export interface UpdateFineError {
  type: typeof UPDATE_FINE_ERROR;
  payload: any;
}

export interface UpdateFineSuccess {
  type: typeof UPDATE_FINE_SUCCESS;
  payload: FineTypeI;
}

//===============================<END>UPDATE FINE<END>======================//

//===============================<START>DELETE FINE<START>======================//

export interface DeleteFineLoading {
  type: typeof DELETE_FINE_LOADING;
}

export interface DeleteFineError {
  type: typeof DELETE_FINE_ERROR;
}

export interface DeleteFineSuccess {
  type: typeof DELETE_FINE_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE FINE<END>======================//

export type FineDispatchTypes =
  | GetFineLoading
  | GetFineError
  | GetFineSuccess
  | AddFineLoading
  | AddFineError
  | AddFineSuccess
  | UpdateFineLoading
  | UpdateFineError
  | UpdateFineSuccess
  | DeleteFineLoading
  | DeleteFineError
  | DeleteFineSuccess;
