import React, { useEffect, useRef, useState } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useLocation } from "react-router";
import moment from "moment";
import BreadCrumbs from "../Reusable/BreadCrumbs";
import { RootStore } from "../../store";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  schoolNameHeader: {
    padding: "8px",
  },
  boxContainer: {
    padding: "0 .2rem",
  },
  boxContainerModule: {
    padding: "0px .5rem",
  },
  dateText: {
    color: "#132E98",
    padding: "5px 10px",
    borderRadius: "0px",
    backgroundColor: "#F2F3F6",
    fontWeight: "bold",
    fontSize: "12px",
  },
  schoolTitle: {
    color: "#132E98",
    fontSize: "1.8rem",
    fontWeight: 500,
    marginBottom: "6px",
  },
  buttonWidth: {
    minWidth: "100%",
  },
});

function HeaderInfo(props: any) {
  const classes = useStyles();
  const location = useLocation();

  const [currentDate, setCurrentDate] = useState<string>(
    moment().format("MMMM D YYYY | h:mm:ss A")
  );

  const generalInfoState = useSelector(
    (state: RootStore) => state.auth.general_info
  );

  useEffect(() => {
    const dateInterval = setInterval(() => {
      const date = moment().format("MMMM D YYYY | h:mm:ss A");
      setCurrentDate(date);
    }, 1000);
    return () => clearInterval(dateInterval);
  }, []);

  return (
    <Box
      width="100%"
      style={{
        marginBottom: "15px",
      }}
    >
      <Box
        width="100%" // bgcolor="white"
        borderRadius="4px"
        p={1.5}
        my={0.5}
        className={
          ["/", "/classroom"].includes(location.pathname)
            ? classes.boxContainer
            : classes.boxContainerModule
        }
      >
        <Grid
          container
          alignItems="center"
          className={classes.schoolNameHeader}
        >
          <Grid item>
            {["/", "/classroom"].includes(location.pathname) && (
              <Typography variant="h2" className={classes.schoolTitle}>
                {generalInfoState?.school_name || "XYZ Secondary School"}
              </Typography>
            )}

            <Typography
              variant="body2"
              style={{
                color: "rgba(0,0,0,0.65)",
                fontWeight: 500,
              }}
            >
              {currentDate}
            </Typography>
          </Grid>
          <Grid item sm />
          <Grid item>
            <BreadCrumbs />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default HeaderInfo;
