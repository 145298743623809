import React, { useState } from "react";
import { useSidebar } from "../Reusable";
import { useSidebarStyles } from "../Styles/SidebarStyles";
import { QueueOutlined, ReceiptOutlined } from "@material-ui/icons";
import SidebarTitle from "../Reusable/Titles/SidebarTitle";

interface PropsI {
  scholarship_type?: boolean;
  apply_scholarship?: boolean;
  scholarship_report?: boolean;
}

interface DataSetI {
  text: string;
  url: string;
  activeStatus: boolean | undefined;
}

const ScholarshipSidebar = (props: PropsI) => {
  const classes = useSidebarStyles();

  const [scholarship_type] = useState(props.scholarship_type);
  const [apply_scholarship] = useState(props.apply_scholarship);
  const [scholarship_report] = useState(props.scholarship_report);


  const dataSet: DataSetI[] = [
    {
      text: "Scholarship Type",
      url: "scholarship-type",
      activeStatus: scholarship_type,
    },
    {
      text: "Apply Scholarship",
      url: "apply-scholarship",
      activeStatus: apply_scholarship,
    },
    {
      text: "Scholarship Report",
      url: "scholarship-report",
      activeStatus: scholarship_report,
    },
  ];
  const { CustomSidebar } = useSidebar(dataSet, "FINANCE");
  return (
    <div className={classes.Sidebar}>
      <div className={classes.sidebarContent}>
        <SidebarTitle icon={<QueueOutlined />}>Scholarship</SidebarTitle>
        <CustomSidebar />
      </div>
    </div>
  );
};

export default ScholarshipSidebar;
