export const GET_MEETING_TYPE_LOADING = "GET_MEETING_TYPE_LOADING";
export const GET_MEETING_TYPE_ERROR = "GET_MEETING_TYPE_ERROR";
export const GET_MEETING_TYPE_SUCCESS = "GET_MEETING_TYPE_SUCCESS";

export const ADD_MEETING_TYPE_LOADING = "ADD_MEETING_TYPE_LOADING";
export const ADD_MEETING_TYPE_ERROR = "ADD_MEETING_TYPE_ERROR";
export const ADD_MEETING_TYPE_SUCCESS = "ADD_MEETING_TYPE_SUCCESS";

export const UPDATE_MEETING_TYPE_LOADING = "UPDATE_MEETING_TYPE_LOADING";
export const UPDATE_MEETING_TYPE_ERROR = "UPDATE_MEETING_TYPE_ERROR";
export const UPDATE_MEETING_TYPE_SUCCESS = "UPDATE_MEETING_TYPE_SUCCESS";

export const DELETE_MEETING_TYPE_LOADING = "DELETE_MEETING_TYPE_LOADING";
export const DELETE_MEETING_TYPE_ERROR = "DELETE_MEETING_TYPE_ERROR";
export const DELETE_MEETING_TYPE_SUCCESS = "DELETE_MEETING_TYPE_SUCCESS";

export type MeetingTypeTypeI = {
  id: string;
  name: string;
};

//===============================<START>GET MEETING_TYPE<START>======================//

export interface GetMeetingTypeLoading {
  type: typeof GET_MEETING_TYPE_LOADING;
}

export interface GetMeetingTypeError {
  type: typeof GET_MEETING_TYPE_ERROR;
}

export interface GetMeetingTypeSuccess {
  type: typeof GET_MEETING_TYPE_SUCCESS;
  payload: MeetingTypeTypeI[];
}

//===============================<END>GET MEETING_TYPE<END>======================//

//===============================<START>ADD MEETING_TYPE<START>======================//

export interface AddMeetingTypeLoading {
  type: typeof ADD_MEETING_TYPE_LOADING;
}

export interface AddMeetingTypeError {
  type: typeof ADD_MEETING_TYPE_ERROR;
}

export interface AddMeetingTypeSuccess {
  type: typeof ADD_MEETING_TYPE_SUCCESS;
  payload: MeetingTypeTypeI;
}

//===============================<END>ADD MEETING_TYPE<END>======================//

//===============================<START>UPDATE MEETING_TYPE<START>======================//

export interface UpdateMeetingTypeLoading {
  type: typeof UPDATE_MEETING_TYPE_LOADING;
}

export interface UpdateMeetingTypeError {
  type: typeof UPDATE_MEETING_TYPE_ERROR;
}

export interface UpdateMeetingTypeSuccess {
  type: typeof UPDATE_MEETING_TYPE_SUCCESS;
  payload: MeetingTypeTypeI;
}

//===============================<END>UPDATE MEETING_TYPE<END>======================//

//===============================<START>DELETE MEETING_TYPE<START>======================//

export interface DeleteMeetingTypeLoading {
  type: typeof DELETE_MEETING_TYPE_LOADING;
}

export interface DeleteMeetingTypeError {
  type: typeof DELETE_MEETING_TYPE_ERROR;
}

export interface DeleteMeetingTypeSuccess {
  type: typeof DELETE_MEETING_TYPE_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE MEETING_TYPE<END>======================//

export type MeetingTypeDispatchTypes =
  | GetMeetingTypeLoading
  | GetMeetingTypeError
  | GetMeetingTypeSuccess
  | AddMeetingTypeLoading
  | AddMeetingTypeError
  | AddMeetingTypeSuccess
  | UpdateMeetingTypeLoading
  | UpdateMeetingTypeError
  | UpdateMeetingTypeSuccess
  | DeleteMeetingTypeLoading
  | DeleteMeetingTypeError
  | DeleteMeetingTypeSuccess;
