// ---------------- <START> module import ------------------//
import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import FormLayout from "../../Reusable/Layouts/Form.Layout";
import CustomizedTextField from "../../Reusable/Inputs/TextField";
import { AddAPE, UpdateAPE } from "../../../actions/Examination/APE/APEAction";
import { useFormStyles } from "../../Styles/FormStyles";
// ---------------- <END> module import ------------------//

// ---------------- <START> Interface ------------------//
// ---------------- <END> Interface ------------------//

// ---------------- <START> Designation Form Component ------------------//
const APEform = (props: any) => {
  const { editData } = props;
  const classes = useFormStyles();
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue, reset, errors, setError } = useForm(
    {
      mode: "onChange",
    }
  );

  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [editID, setEditID] = useState<string | null>(null);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);

  const apeLoading: boolean = useSelector(
    (state: RootStore) => state.apes.loading
  );
  const apeState = useSelector((state: RootStore) => state.apes);

  const errorsData = useSelector((state: RootStore) => state.apes.errors);
  const initialErrorsData = useRef(errorsData);

  const add_or_update = useSelector((state: RootStore) => state.apes.add_or_update)

  useEffect(() => {
    if (add_or_update) {
      setIsBtnLoading(false)
      handleReset();
    }
  }, [add_or_update])

  useEffect(() => {
    console.log({ errorsData });
    if (initialErrorsData.current === errorsData) {
      initialErrorsData.current = errorsData; // Do not set initial errors
    } else {
      if (errorsData?.error != null) {
        const keys = Object.keys(errorsData?.error);
        keys.map((elem: any) => {
          setError(elem, {
            type: "serverSideError",
            message: errorsData.error[elem] && errorsData.error[elem],
          });
        });
      }
    }
  }, [errorsData]);

  useEffect(() => {
    if (apeState?.recent) {
      handleReset();
    }
  }, [apeState]);
  useEffect(() => {
    setLoading(apeLoading);
  }, [apeLoading]);

  useEffect(() => {
    editData != null && handleEditTableData(editData);
  }, [editData]);

  const handleEditTableData = (data: any) => {
    setEditMode(true);
    setValue("title", data.title);
    setEditID(data.id);
  };

  const onSubmit = (data: any) => {
    setIsBtnLoading(true);
    if (editMode) {
      editID != null && dispatch(UpdateAPE(editID, data));
    } else {
      dispatch(AddAPE(data));
    }
  };

  const handleReset = () => {
    reset();
    setEditMode(false);
    setEditID(null);
  };

  return (
    <FormLayout
      title="Add Adapted Physical Education (APE) Factor"
      onSubmit={handleSubmit(onSubmit)}
      editMode={editMode}
      loading={loading}
      onClick={!isBtnLoading && handleReset}
      add_or_update={isBtnLoading}
    >
      <CustomizedTextField
        label="Title"
        placeholder="Label"
        name="title"
        required
        error={errors["name"] ? "Required Field." : ""}
        inputRef={register({ required: true })}
      />
      <span className={classes.validationErrorInfo}>
        {errors.title?.type === "serverSideError" && errors.title.message}
      </span>
    </FormLayout>
  );
};
// ---------------- <END> Designation Form Component ------------------//

export default APEform;
