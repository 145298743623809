import {
  Grid,
  InputLabel,
  TextField,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import { Autocomplete } from "@material-ui/lab";
import { useForm } from "react-hook-form";
import FormControl from "@material-ui/core/FormControl";
import { useFormStyles } from "../../Styles/FormStyles";
import { useDispatch, useSelector } from "react-redux";
import { GetClasses } from "../../../actions/Academics/Class/ClassAction";
import { ClassTypeI } from "../../../actions/Academics/Class/ClassActionTypes";
import { RootStore } from "../../../store";
import { SectionTypeI } from "../../../actions/Academics/Section/SectionActionTypes";
import {
  AddClassTeacher,
  UpdateClassTeacher,
} from "../../../actions/Academics/ClassTeacher/ClassTeacherAction";
import { ClassTeacherTypeI } from "../../../actions/Academics/ClassTeacher/ClassTeacherActionTypes";
import {
  GetStaffs,
  GetTeachersByName,
} from "../../../actions/HumanResource/Staff/StaffAction";
import { StaffTypeI } from "../../../actions/HumanResource/Staff/StaffActionTypes";
import { checkedIcon, icon } from "../../Styles/ReusableStyles";
import FormLayout from "../../Reusable/Layouts/Form.Layout";
import { CusRadioGroup } from "../../Reusable/Inputs/Radio";
// -----------------<start> Styling <start>-----------------------//

// -----------------<end> States <end>-----------------------//
interface PropsI {
  editData: ClassTeacherTypeI | null;
  onEditMode: (value: boolean) => void;
}

interface FormI {
  teacher: string | null;
  class: string | null;
}

interface TeacherI {
  id: string;
  first_name: string;
  last_name: string;
}

interface SectionI {
  id: string;
  title: string;
}

const AssignClassTeacher = (props: PropsI) => {
  const { editData, onEditMode } = props;
  const classes = useFormStyles();
  const { register, handleSubmit, errors, setError, clearErrors, setValue } =
    useForm<any>();
  //State declaration
  const [editID, setEditID] = useState<string>("");
  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [classLoading, setclassLoading] = useState<boolean>(false);
  const [teacherLoading, setTeacherLoading] = useState<boolean>(false);

  const [selectedteacher, setSelectedTeacher] = useState<StaffTypeI | null>(
    null
  );
  const [teacherChoices, setTeacherChoices] = useState<any>([]);
  const [classChoices, setClassChoices] = useState<ClassTypeI[]>([]);
  const [selectedClass, setSelectedClass] = useState<ClassTypeI | null>(null);
  const [selectedRadioValue, setSelectedRadioValue] = useState<string | null>(
    null
  );
  const [sectionList, setSectionList] = useState<SectionTypeI[]>([]);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);

  //============================== <START> REDUX HOOK <START> ===========================//
  const dispatch = useDispatch();
  const classSelector = useSelector((state: RootStore) => state.class);
  const staffSelector = useSelector((state: RootStore) => state.staff);
  const { actionPerformed, add_or_update } = useSelector(
    (state: RootStore) => state.class_teacher
  );

  useEffect(() => {
    if (add_or_update) {
      setIsBtnLoading(false)
      handleReset();
    }
  }, [add_or_update])

  useEffect(() => {
    dispatch(GetClasses());
    dispatch(GetTeachersByName(""));
  }, []);

  useEffect(() => {
    if (actionPerformed) {
      handleReset();
    }
  }, [actionPerformed]);

  useEffect(() => {
    editData != null && handleEditTableData(editData);
  }, [editData]);

  useEffect(() => {
    setClassChoices(classSelector.classes);
    setclassLoading(classSelector.loading);
  }, [classSelector]);

  useEffect(() => {
    if (staffSelector.teachers.results != null) {
      const teachers = staffSelector.teachers.results;
      setTeacherChoices(teachers);
      setTeacherLoading(staffSelector.loading);
    }
  }, [staffSelector.teachers.results]);

  //============================== <END> REDUX HOOK <END> ===========================//

  //============================== <START> EVENT HANDLERS <START> ===========================//

  const handleClassChange = (value: ClassTypeI | null) => {
    value != null ? populateSectionRadio(value.section) : setSectionList([]);
    setSelectedClass(value);
    clearErrors("error");
  };

  const populateSectionRadio = (value: SectionTypeI[]) => {
    setSectionList(value);
    value.length && setSelectedRadioValue(value[0].id);
    !value.length && setSelectedRadioValue(null);
  };

  const onSubmit = (data: FormI) => {
    setIsBtnLoading(true);
    if (selectedteacher != null && selectedClass != null) {
      const makeSubmission = (callback: () => void) => {
        editID == ""
          ? dispatch(
            AddClassTeacher({
              grade: selectedClass.grade,
              section: selectedRadioValue ? selectedRadioValue : "",
              teacher: selectedteacher.id,
            })
          )
          : dispatch(
            UpdateClassTeacher(editID, {
              grade: selectedClass.grade,
              section: selectedRadioValue ? selectedRadioValue : "",
              teacher: selectedteacher.id,
            })
          );

        callback();
      };

      makeSubmission(() => {
        // handleReset();
      });
    }
  };

  const handleEditTableData = (data: ClassTeacherTypeI) => {
    setEditMode(true);
    setEditID(data.id);

    const new_class = classChoices.find(
      (element) => element.grade === data.grade
    );

    const new_teacher = teacherChoices.find(
      (element: any) => element.id === data.teacher.toString()
    );

    new_class && setSelectedClass(new_class);

    new_class && setSectionList(new_class.section);

    setSelectedRadioValue(data.section);

    new_teacher && setSelectedTeacher(new_teacher);
  };

  const handleReset = () => {
    setEditMode(false);
    setEditID("");
    setSelectedTeacher(null);
    setSelectedClass(null);
    setSelectedRadioValue(null);
    setSectionList([]);
    onEditMode(false);
  };

  const handleSelectTeacher = (value: StaffTypeI | null) => {
    setSelectedTeacher(value);
    clearErrors("teacher");
  };
  const handleSelectValue = (value: any) => {
    clearErrors("error");
    setSelectedRadioValue(value);
  };
  //----------------------ERROR HANDLING---------------------------//
  const classTeacherSelector = useSelector(
    (state: RootStore) => state.class_teacher
  );
  const errorsData = classTeacherSelector.errors;

  useEffect(() => {
    if (classTeacherSelector?.recent) {
      handleReset();
    }
  }, [classTeacherSelector]);

  const onTeacherChange = (event: any, value: any) => {
    dispatch(GetTeachersByName(value));
  };

  return (
    <FormLayout
      title="Assign Class Teacher"
      onSubmit={handleSubmit(onSubmit)}
      editMode={editMode}
      loading={loading}
      onClick={!isBtnLoading && handleReset}
      add_or_update={isBtnLoading}
    >
      <Grid item xs={12} className={classes.formWrapper}>
        <InputLabel>
          Select Teacher
          <span style={{ color: "red" }}>*</span>
        </InputLabel>
        <Autocomplete
          value={selectedteacher}
          // filterSelectedOptions
          onChange={(event: React.ChangeEvent<{}>, value: any | null) =>
            handleSelectTeacher(value)
          }
          onInputChange={onTeacherChange}
          options={teacherChoices}
          getOptionLabel={(option) =>
            `${option.user.first_name} ${option.user.last_name}`
          }
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select"
              name="teacher"
              // onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              //   dispatch(GetTeachersByName(event.target.value))
              // }
              inputRef={register({ required: true })}
              variant="outlined"
            />
          )}
        />
        <span className={classes.validationErrorInfo}>
          {errors.teacher?.type === "required" && "Please select teacher"}
        </span>
        <span className={classes.validationErrorInfo}>
          {errors?.error?.type === "serverSideError" && errors.error?.message}
        </span>
      </Grid>

      <Grid item xs={12} className={classes.formWrapper}>
        <InputLabel>
          Select Class
          <span style={{ color: "red" }}>*</span>
        </InputLabel>
        <Autocomplete
          value={selectedClass}
          onChange={(event: React.ChangeEvent<{}>, value: ClassTypeI | null) =>
            handleClassChange(value)
          }
          options={classChoices}
          getOptionLabel={(option) => option.grade_name}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select"
              name="class"
              inputRef={register({ required: "Please select Class" })}
              variant="outlined"
            />
          )}
        />
        <span className={classes.validationErrorInfo}>
          {errors.class && errors.class.message}
        </span>
      </Grid>

      <Grid item xs={12} className={classes.formWrapper}>
        <InputLabel>
          Section
          <span style={{ color: "red" }}>*</span>
        </InputLabel>
        <FormControl component="fieldset">
          {sectionList && sectionList.length ? (
            <CusRadioGroup
              items={sectionList}
              value={selectedRadioValue}
              setValue={handleSelectValue}
            />
          ) : (
            <span className={classes.sectionUnavailableText}>
              ** No records found
            </span>
          )}
        </FormControl>
      </Grid>
    </FormLayout>
  );
};

export default AssignClassTeacher;
