import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setSnackbar } from "../../../../actions/SnackbarAction";
import { ADMIN_API_URL } from "../../../../config/SERVER_API_URLS";

export const getGradeSubjects = createAsyncThunk(
  "getGradeSubjects",
  async (gradeId: string, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.get(
        `${ADMIN_API_URL}/subject_info/?grade=${gradeId}`
      );
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(null);
    }
  }
);

export const postGradeSubjectInfo = createAsyncThunk(
  "postGradeSubjectInfo",
  async (
    data: { row_id: string; grade_id: string; post: any },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.post(
        `${ADMIN_API_URL}/subject_info/?grade=${data.grade_id}/`,
        data.post
      );
      dispatch(
        setSnackbar(true, "success", "Subject Info Updated Successfully")
      );
      return { id: data.row_id, data: res.data };
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      dispatch(setSnackbar(true, "error", "Failed to update subject info"));
      return rejectWithValue(null);
    }
  }
);

export const updateGradeSubjectInfo = createAsyncThunk(
  "updateGradeSubjectInfo",
  async (data: { id: string; post: any }, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.put(
        `${ADMIN_API_URL}/subject_info/${data.id}/`,
        data.post
      );
      dispatch(
        setSnackbar(true, "success", "Subject Info Updated Successfully")
      );
      return { id: data.id, data: res.data };
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      dispatch(setSnackbar(true, "error", "Failed to update subject info"));
      return rejectWithValue(null);
    }
  }
);
