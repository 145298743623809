import React from 'react'
import ActivityCard from '../../../Reusable/ActivityCard'
import {
    createStyles,
    Grid,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Theme,
    Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: theme.spacing(2),
            maxHeight: "70vh",
        },
        cardContainer: {
            display: "flex",
            justifyContent: "space-between",
        },
        activityContainer: {
            marginTop: "2.5em",
        },
        activity: {
            margin: 0,
            padding: 0,
        },
        divider: {
            height: "1px",
            border: "none",
            backgroundColor: "#dedede",
            marginTop: "0.5em",
            marginBottom: "1em",
        }
    })
)

const DUMMY_DATA = [
    {
        id: 1,
        data: "Testi has been disabled for doing bad stuff.",
        date: "3/1/2022",
    },
    {
        id: 2,
        data: "Testi has been enabled.",
        date: "3/5/2022",
    },
    {
        id: 3,
        data: "Testi has taken leave due to personal reasons.",
        date: "3/8/2022",
    },
]

const subValue = "activities"

const Activities = () => {
    const classes = useStyles();
    return (
        <Paper className={classes.container} elevation={1}>
            <div className={classes.cardContainer}>
                <ActivityCard data={{ value: 7, subValue, time: "Today" }} />
                <ActivityCard data={{ value: 10, subValue, time: "This week" }} />
                <ActivityCard data={{ value: 30, subValue, time: "This month" }} />
            </div>
            <div className={classes.activityContainer}>
                {DUMMY_DATA.map(item => (
                    <>
                        <p key={item.id} className={classes.activity}>{item.data}{item.date}</p>
                        <hr className={classes.divider} />
                    </>
                ))}
            </div>
        </Paper>
    )
}

export default Activities