import { Button, Grid, InputLabel, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetClasses } from "../../../actions/Academics/Class/ClassAction";
import { ClassTypeI } from "../../../actions/Academics/Class/ClassActionTypes";
import { RootStore } from "../../../store";
import FullWidthFormLayout from "../../Reusable/Layouts/FullWidthForm.layout";
import { useFormStyles } from "../../Styles/FormStyles";
import GetAppIcon from "@material-ui/icons/GetApp";
import { setSnackbar } from "../../../actions/SnackbarAction";
import { getStudentsByAllSections } from "../../../rtk/features/student/sectionTransfer/sectionTransferApi";

interface SectionI {
  id: string;
  name: string;
  value: string;
}

const SectionTransferForm = () => {
  const classes = useFormStyles();
  const dispatch = useDispatch();

  const classSelector = useSelector((state: RootStore) => state.class);

  const [sections, setSections] = useState<SectionI[]>([]);

  const [selectedClass, setSelectedClass] = useState<ClassTypeI | null>(null);
  const [selectedFirstSection, setSelectedFirstSection] =
    useState<SectionI | null>(null);
  const [selectedSecondSection, setSelectedSecondSection] =
    useState<SectionI | null>(null);

  useEffect(() => {
    dispatch(GetClasses());
  }, []);

  const handleClassChange = (value: ClassTypeI | null) => {
    setSelectedClass(value);
    setSelectedFirstSection(null);
    setSelectedSecondSection(null);

    if (value) {
      if (value.section.length) {
        const sectionChoice: SectionI[] = value.section.map((el, index) => ({
          id: el.id,
          name: el.name,
          value: el.id,
        }));
        setSections(
          [{ id: "1", name: "Unassigned", value: "null" }].concat(sectionChoice)
        );
      } else {
        setSections([]);
      }
    } else {
      setSections([]);
    }
  };

  const handleLoadStudents = () => {
    if (selectedFirstSection && selectedSecondSection) {
      if (selectedFirstSection.id !== selectedSecondSection.id) {
        dispatch(
          getStudentsByAllSections({
            grade: selectedClass!.grade,
            firstSection: {
              id: selectedFirstSection.value,
              name: selectedFirstSection.name,
            },
            secondSection: {
              id: selectedSecondSection.value,
              name: selectedSecondSection.name,
            },
          })
        );
      } else {
        dispatch(
          setSnackbar(true, "warning", "Please select two different sections")
        );
      }
    }
  };

  return (
    <>
      <FullWidthFormLayout>
        <Grid container>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                className={classes.formWrapper}
              >
                <InputLabel>Class </InputLabel>
                <Autocomplete
                  classes={{
                    input: classes.smallfont,
                    option: classes.smallfont,
                  }}
                  value={selectedClass}
                  fullWidth
                  onChange={(
                    event: React.ChangeEvent<{}>,
                    value: ClassTypeI | null
                  ) => handleClassChange(value)}
                  options={classSelector.classes}
                  getOptionLabel={(option) => option.grade_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Grade"
                      name="class_name"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              className={classes.formWrapper}
            >
              <InputLabel>Select First Section</InputLabel>
              <Autocomplete
                classes={{
                  input: classes.smallfont,
                  option: classes.smallfont,
                }}
                value={selectedFirstSection}
                fullWidth
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: SectionI | null
                ) => setSelectedFirstSection(value)}
                options={sections}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Grade"
                    name="class_name"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              className={classes.formWrapper}
            >
              <InputLabel>Select Second Section</InputLabel>
              <Autocomplete
                classes={{
                  input: classes.smallfont,
                  option: classes.smallfont,
                }}
                value={selectedSecondSection}
                fullWidth
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: SectionI | null
                ) => setSelectedSecondSection(value)}
                options={sections}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Grade"
                    name="class_name"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleLoadStudents()}
            startIcon={<GetAppIcon />}
            style={{ margin: "10px 0 0 0", width: "max-content" }}
            disabled={!selectedFirstSection || !selectedSecondSection}
          >
            Load Students
          </Button>
        </Grid>
      </FullWidthFormLayout>
    </>
  );
};

export default SectionTransferForm;
