import React from "react";
import styled from "styled-components";

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;

  @media (max-width: 1080px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const GridCol1 = styled.div`
  grid-column: span 1;
`;

export const GridCol2 = styled.div`
  grid-column: span 2;

  @media (max-width: 600px) {
    grid-column: span 1;
  }
`;

export const GridCol3 = styled.div`
  grid-column: span 3;

  @media (max-width: 900px) {
    grid-column: span 2;
  }

  @media (max-width: 600px) {
    grid-column: span 1;
  }
`;

export const GridCol4 = styled.div`
  grid-column: span 4;

  @media (max-width: 1080px) {
    grid-column: span 3;
  }

  @media (max-width: 900px) {
    grid-column: span 2;
  }

  @media (max-width: 600px) {
    grid-column: span 1;
  }
`;
