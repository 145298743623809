import React from 'react';
import { Grid } from '@material-ui/core';
import { HRsidebar } from '../../components';
import SubModuleLayout from '../../components/Reusable/Layouts/SubModuleLayout';
import LeaveSearch from '../../components/Student/StudentLeave/LeaveSearch';
import LeaveList from '../../components/Student/StudentLeave/LeaveList';
import OnLeaveList from '../../components/Student/StudentLeave/OnLeaveList';
import StudentSidebar from '../../components/Student/StudentSidebar/StudentSidebar';
import { breadcrumb } from './Student.Breadcrumbs';

const LeavePage = () => {
  const nav = <StudentSidebar studentLeave />;
  return (
    <SubModuleLayout module={breadcrumb} sideNav={nav}>
      <Grid container>
        <Grid item xs={12}>
          <LeaveSearch />
          <LeaveList />
        </Grid>
        {/* <Grid item xs={3}>
          <OnLeaveList />
        </Grid> */}
      </Grid>
    </SubModuleLayout>
  );
};
export default LeavePage;
