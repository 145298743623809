import axios from "axios";
import { Dispatch } from "redux";

import {
  POST_LOGIN_LOADING,
  POST_LOGIN_ERROR,
  POST_LOGIN_SUCCESS,
  LoginDispatchTypes,
  GET_AUTH_TOKEN,
  GET_USERS_BY_PHONE_LOADING,
  GET_USERS_BY_PHONE_ERROR,
  GET_USERS_BY_PHONE_SUCCESS,
  SET_USER_BY_PHONE_SUCCESS,
  VERIFY_OTP,
  VERIFY_OTP_LOADING,
  VERIFY_OTP_ERROR,
  VERIFY_OTP_SUCCESS,
  SEND_OTP_LOADING,
  SEND_OTP_ERROR,
  SEND_OTP_SUCCESS,
  CHANGE_PASSWORD_LOADING,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_SUCCESS,
  UPDATE_APP_THEME_LOADING,
  UPDATE_APP_THEME_SUCCESS,
  UPDATE_APP_THEME_ERROR,
} from "./LoginActionTypes";

import { SetSnackBarI, SET_SNACKBAR } from "../../SnackbarActionTypes";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";
import Store from "../../../store";
import { LogoutAction } from "../Logout/LogoutAction";
import {
  LogoutDispatchTypes,
  USER_LOGOUT_SUCCESS,
} from "../Logout/LogoutActionTypes";

interface LoginParamI {
  username: string;
  password: string;
}

function getCookie(name: string) {
  if (!document.cookie) {
    return null;
  }

  const xsrfCookies = document.cookie
    .split(";")
    .map((c) => c.trim())
    .filter((c) => c.startsWith(name + "="));

  if (xsrfCookies.length === 0) {
    return null;
  }
  // console.log("CSRF TOKEN::", decodeURIComponent(xsrfCookies[0].split("=")[1]));
  return decodeURIComponent(xsrfCookies[0].split("=")[1]);
}

export const csrftoken = getCookie("csrftoken");

export const LoginConfig = () => {
  return {
    headers: {
      // "X-CSRFToken": csrftoken,
      "Content-Type": "application/json",
      // "Referrer-Policy": "no-referrer"
    },
  };
};

export interface RoleI {
  name:
    | "Administrator"
    | "Student"
    | "Teacher"
    | "Parent"
    | "Organization Admin";
}

export const UpdateAppTheme =
  (name: RoleI) => (dispatch: Dispatch<LoginDispatchTypes>) => {
    dispatch({
      type: UPDATE_APP_THEME_SUCCESS,
      payload: name,
    });
  };

export const LoginAction =
  (data: LoginParamI) =>
  async (dispatch: Dispatch<LoginDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: POST_LOGIN_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/token/`,
        data,
        LoginConfig()
      );

      dispatch({
        type: POST_LOGIN_SUCCESS,
        payload: res.data,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "success",
      //     snackbarMessage: "Logged In Successfully",
      //   },
      // });
    } catch (error) {
      dispatch({
        type: POST_LOGIN_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Authentication Failed",
        },
      });
    }
  };

export const useAxiosInterceptors = async () => {
  const ERROR_CODE = "token_not_valid";

  // Verify access token
  const hamro_token = localStorage.getItem("hamro_token");
  await axios
    .post(`${ADMIN_API_URL}/token/verify/`, { token: hamro_token })
    .catch((err: any) => {
      if (err?.response?.data?.error?.code === ERROR_CODE) {
        Store.dispatch(LogoutAction());
      }
    });

  // Request interceptor to add the auth token header to requests
  axios.interceptors.request.use(
    (config) => {
      const accessToken = localStorage.getItem("hamro_token");
      if (accessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  // Response interceptor to refresh token on receiving token expired error
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      const originalRequest = error.config;
      const refreshToken = localStorage.getItem("refreshToken");
      const token = localStorage.getItem("hamro_token");

      if (
        refreshToken &&
        (error.response.status === 403 || error.response.status === 401) &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;

        // console.log('Fired!', 'error', error);
        return axios
          .post(`${ADMIN_API_URL}/token/refresh/`, {
            refresh: refreshToken,
          })
          .then((res) => {
            if (res.status === 200) {
              localStorage.setItem("hamro_token", res.data.access);
              return axios(originalRequest);
            }
          })
          .catch((err) => {
            console.log("This is error", err);
            if (err.response.data.error.code === ERROR_CODE) {
              Store.dispatch(LogoutAction());
              return axios(originalRequest);
            }
            if (err.response.data.code === ERROR_CODE) {
              Store.dispatch(LogoutAction());
              return axios(originalRequest);
            }
          });
      }
      // else if (!refreshToken || !token) {
      //   Store.dispatch(LogoutAction());
      // }

      return Promise.reject(error);
    }
  );
};

export const GetToken =
  () => async (dispatch: Dispatch<LoginDispatchTypes>) => {
    dispatch({
      type: GET_AUTH_TOKEN,
    });
  };

export const HeaderConfig = () => {
  const token: string | null = localStorage.getItem("hamro_token");
  if (token) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        // "X-CSRFToken": csrftoken,
        "Content-Type": "application/json",
      },
    };
  }

  return {
    headers: {
      Authorization: `Bearer `,
    },
  };
};

export const HeaderConfigForDelete = () => {
  const token = localStorage.getItem("hamro_token");

  if (token) {
    return {
      Authorization: `Bearer ${token}`,
      // "X-CSRFToken": csrftoken,
    };
  }

  return {
    Authorization: `Bearer `,
  };
};

export const GetUsersByPhoneNumber =
  (data: { phone_number: string }) =>
  async (dispatch: Dispatch<LoginDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_USERS_BY_PHONE_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/phone_number/users/`,
        data
      );

      dispatch({
        type: GET_USERS_BY_PHONE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      console.log("error", error);
      dispatch({
        type: GET_USERS_BY_PHONE_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "We couldn't find a user. Please try again!",
        },
      });
    }
  };

export const SetPhoneUser =
  (data: { phone_number: string; phone_user: string }) =>
  (dispatch: Dispatch<LoginDispatchTypes | SetSnackBarI>) => {
    dispatch({
      type: SET_USER_BY_PHONE_SUCCESS,
      payload: {
        phone_number: data.phone_number,
        phone_user_id: data.phone_user,
      },
    });
  };

export const SendOTPDetail =
  (data: { phone_number: string; user: string }) =>
  async (dispatch: Dispatch<LoginDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: SEND_OTP_LOADING,
      });

      const res = await axios.post(`${ADMIN_API_URL}/send/otp/`, data);

      dispatch({
        type: SEND_OTP_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "OTP is sent successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: SEND_OTP_ERROR,
      });
    }
  };

export const VerifyOTP =
  (data: { otp_id: number; otp: string }) =>
  async (dispatch: Dispatch<LoginDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: VERIFY_OTP_LOADING,
      });

      const res = await axios.post(`${ADMIN_API_URL}/verify/OTP/`, data);

      dispatch({
        type: VERIFY_OTP_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "OTP is Success",
        },
      });
    } catch (error) {
      dispatch({
        type: VERIFY_OTP_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Invalid OTP",
        },
      });
    }
  };

export const ChangePassword =
  (credentials: { user: string; password1: string; password2: string }) =>
  async (dispatch: Dispatch<LoginDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: CHANGE_PASSWORD_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/change_password/`,
        credentials
      );

      dispatch({
        type: CHANGE_PASSWORD_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Your password has been changed successfully.",
        },
      });
    } catch (error) {
      dispatch({
        type: CHANGE_PASSWORD_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Unable to change your password",
        },
      });
    }
  };

export const CreatePassword =
  (credentials: { user: string; password1: string; password2: String }) =>
  async (
    dispatch: Dispatch<LoginDispatchTypes | SetSnackBarI | LogoutDispatchTypes>
  ) => {
    try {
      dispatch({
        type: CHANGE_PASSWORD_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/change_password/`,
        credentials
      );

      dispatch({
        type: CHANGE_PASSWORD_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Your password is changed successfully.",
        },
      });
    } catch (error) {
      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Unable to change your password",
        },
      });

      dispatch({
        type: CHANGE_PASSWORD_ERROR,
      });

      dispatch({
        type: USER_LOGOUT_SUCCESS,
      });
    }
  };
