import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_EXAM_LOADING,
  GET_EXAM_ERROR,
  GET_EXAM_SUCCESS,
  GET_EXAM_BY_ID_LOADING,
  GET_EXAM_BY_ID_ERROR,
  GET_EXAM_BY_ID_SUCCESS,
  ExamDispatchTypes,
  ADD_EXAM_LOADING,
  ADD_EXAM_SUCCESS,
  ADD_EXAM_ERROR,
  DELETE_EXAM_SUCCESS,
  DELETE_EXAM_ERROR,
  DELETE_EXAM_LOADING,
  UPDATE_EXAM_LOADING,
  UPDATE_EXAM_SUCCESS,
  UPDATE_EXAM_ERROR,
} from "./ExamActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";
import { AnyRecord } from "dns";

interface RequestDataI {
  exam_name: string;
  start_date: string;
  end_date: string;
}

export const GetExams =
  () => async (dispatch: Dispatch<ExamDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_EXAM_LOADING,
      });

      const res = await axios.get(`${ADMIN_API_URL}/exam/`, HeaderConfig());

      dispatch({
        type: GET_EXAM_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_EXAM_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "Exams Loading Failed",
      //   },
      // });
    }
  };

export const GetExamsById =
  (id: string) =>
  async (dispatch: Dispatch<ExamDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_EXAM_BY_ID_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/exam/${id}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_EXAM_BY_ID_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_EXAM_BY_ID_ERROR,
      });
    }
  };

export const AddExam =
  (data: RequestDataI) =>
  async (dispatch: Dispatch<ExamDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_EXAM_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/exam/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_EXAM_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Exam Added Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: ADD_EXAM_ERROR,
        payload: error?.response?.data,
      });

      const err_exists = error?.response?.data?.error;

      if (err_exists) {
        const err_msg = err_exists[Object.keys(err_exists)[0]];

        err_msg != undefined &&
          dispatch({
            type: SET_SNACKBAR,
            payload: {
              snackbarOpen: true,
              snackbarType: "error",
              snackbarMessage: err_msg,
            },
          });
      } else {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Exam Update Failed",
          },
        });
      }
    }
  };

export const UpdateExam =
  (id: string, data: RequestDataI) =>
  async (dispatch: Dispatch<ExamDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_EXAM_LOADING,
      });

      const res = await axios.put(
        `${ADMIN_API_URL}/exam/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_EXAM_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Exam Updated Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: UPDATE_EXAM_ERROR,
        payload: error?.response?.data,
      });

      const err_exists = error?.response?.data?.error;

      if (err_exists) {
        const err_msg = err_exists[Object.keys(err_exists)[0]][0];

        err_msg != undefined &&
          dispatch({
            type: SET_SNACKBAR,
            payload: {
              snackbarOpen: true,
              snackbarType: "error",
              snackbarMessage: err_msg,
            },
          });
      } else {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Exam Update Failed",
          },
        });
      }
    }
  };

export const DeleteExam =
  (id: string) =>
  async (dispatch: Dispatch<ExamDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_EXAM_LOADING,
      });

      const res = await axios.delete(
        `${ADMIN_API_URL}/exam/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_EXAM_SUCCESS,
        payload: { id: id.toString() },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Exam Deleted Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_EXAM_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Exam Delete Failed",
        },
      });
    }
  };
