export const GET_INCOME_TYPE_LOADING = "GET_INCOME_TYPE_LOADING";
export const GET_INCOME_TYPE_ERROR = "GET_INCOME_TYPE_ERROR";
export const GET_INCOME_TYPE_SUCCESS = "GET_INCOME_TYPE_SUCCESS";

export const ADD_INCOME_TYPE_LOADING = "ADD_INCOME_TYPE_LOADING";
export const ADD_INCOME_TYPE_ERROR = "ADD_INCOME_TYPE_ERROR";
export const ADD_INCOME_TYPE_SUCCESS = "ADD_INCOME_TYPE_SUCCESS";

export const UPDATE_INCOME_TYPE_LOADING = "UPDATE_INCOME_TYPE_LOADING";
export const UPDATE_INCOME_TYPE_ERROR = "UPDATE_INCOME_TYPE_ERROR";
export const UPDATE_INCOME_TYPE_SUCCESS = "UPDATE_INCOME_TYPE_SUCCESS";

export const DELETE_INCOME_TYPE_LOADING = "DELETE_INCOME_TYPE_LOADING";
export const DELETE_INCOME_TYPE_ERROR = "DELETE_INCOME_TYPE_ERROR";
export const DELETE_INCOME_TYPE_SUCCESS = "DELETE_INCOME_TYPE_SUCCESS";

export const GET_INCOME_DETAILS_LOADING = "GET_INCOME_DETAILS_LOADING";
export const GET_INCOME_DETAILS_ERROR = "GET_INCOME_DETAILS_ERROR";
export const GET_INCOME_DETAILS_SUCCESS = "GET_INCOME_DETAILS_SUCCESS";

export const ADD_INCOME_DETAILS_LOADING = "ADD_INCOME_DETAILS_LOADING";
export const ADD_INCOME_DETAILS_ERROR = "ADD_INCOME_DETAILS_ERROR";
export const ADD_INCOME_DETAILS_SUCCESS = "ADD_INCOME_DETAILS_SUCCESS";

export const UPDATE_INCOME_DETAILS_LOADING = "UPDATE_INCOME_DETAILS_LOADING";
export const UPDATE_INCOME_DETAILS_ERROR = "UPDATE_INCOME_DETAILS_ERROR";
export const UPDATE_INCOME_DETAILS_SUCCESS = "UPDATE_INCOME_DETAILS_SUCCESS";

export const DELETE_INCOME_DETAILS_LOADING = "DELETE_INCOME_DETAILS_LOADING";
export const DELETE_INCOME_DETAILS_ERROR = "DELETE_INCOME_DETAILS_ERROR";
export const DELETE_INCOME_DETAILS_SUCCESS = "DELETE_INCOME_DETAILS_SUCCESS";

export type IncomeTypeI = {
  id: string;
  name: string;
  count?: number;
  description: string;
};

export type AddIncomeTypeI = {
  id: string;
  count?: number;
  income_type: string | number;
  income_type_name: string;
  name: string;
  invoice: string;
  income_date: string;
  amount: string;
  document: any;
  description: string;
  academic_session: number;
  created_by: number;
  created_on: string;
};

//===============================<START>GET INCOME<START>======================//

export interface GetIncomeTypeLoading {
  type: typeof GET_INCOME_TYPE_LOADING;
}

export interface GetIncomeTypeError {
  type: typeof GET_INCOME_TYPE_ERROR;
}

export interface GetIncomeTypeSuccess {
  type: typeof GET_INCOME_TYPE_SUCCESS;
  payload: IncomeTypeI[];
}

//===============================<END>GET INCOME<END>======================//

//===============================<START>ADD INCOME<START>======================//

export interface AddIncomeTypeLoading {
  type: typeof ADD_INCOME_TYPE_LOADING;
}

export interface AddIncomeTypeError {
  type: typeof ADD_INCOME_TYPE_ERROR;
}

export interface AddIncomeTypeSuccess {
  type: typeof ADD_INCOME_TYPE_SUCCESS;
  payload: IncomeTypeI;
}

//===============================<END>ADD INCOME<END>======================//

//===============================<START>UPDATE INCOME<START>======================//

export interface UpdateIncomeTypeLoading {
  type: typeof UPDATE_INCOME_TYPE_LOADING;
}

export interface UpdateIncomeTypeError {
  type: typeof UPDATE_INCOME_TYPE_ERROR;
}

export interface UpdateIncomeTypeSuccess {
  type: typeof UPDATE_INCOME_TYPE_SUCCESS;
  payload: IncomeTypeI;
}

//===============================<END>UPDATE INCOME<END>======================//

//===============================<START>DELETE INCOME<START>======================//

export interface DeleteIncomeTypeLoading {
  type: typeof DELETE_INCOME_TYPE_LOADING;
}

export interface DeleteIncomeTypeError {
  type: typeof DELETE_INCOME_TYPE_ERROR;
}

export interface DeleteIncomeTypeSuccess {
  type: typeof DELETE_INCOME_TYPE_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE INCOME<END>======================//

//===============================<START>GET INCOME<START>======================//

export interface GetIncomeDetailsLoading {
  type: typeof GET_INCOME_DETAILS_LOADING;
}

export interface GetIncomeDetailsError {
  type: typeof GET_INCOME_DETAILS_ERROR;
}

export interface GetIncomeDetailsSuccess {
  type: typeof GET_INCOME_DETAILS_SUCCESS;
  payload: AddIncomeTypeI[];
}

//===============================<END>GET INCOME<END>======================//

//===============================<START>ADD INCOME<START>======================//

export interface AddIncomeDetailsLoading {
  type: typeof ADD_INCOME_DETAILS_LOADING;
}

export interface AddIncomeDetailsError {
  type: typeof ADD_INCOME_DETAILS_ERROR;
  payload: any;
}

export interface AddIncomeDetailsSuccess {
  type: typeof ADD_INCOME_DETAILS_SUCCESS;
  payload: AddIncomeTypeI;
}

//===============================<END>ADD INCOME<END>======================//

//===============================<START>UPDATE INCOME<START>======================//

export interface UpdateIncomeDetailsLoading {
  type: typeof UPDATE_INCOME_DETAILS_LOADING;
}

export interface UpdateIncomeDetailsError {
  type: typeof UPDATE_INCOME_DETAILS_ERROR;
  payload: any;
}

export interface UpdateIncomeDetailsSuccess {
  type: typeof UPDATE_INCOME_DETAILS_SUCCESS;
  payload: AddIncomeTypeI;
}

//===============================<END>UPDATE INCOME<END>======================//

//===============================<START>DELETE INCOME<START>======================//

export interface DeleteIncomeDetailsLoading {
  type: typeof DELETE_INCOME_DETAILS_LOADING;
}

export interface DeleteIncomeDetailsError {
  type: typeof DELETE_INCOME_DETAILS_ERROR;
}

export interface DeleteIncomeDetailsSuccess {
  type: typeof DELETE_INCOME_DETAILS_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE INCOME<END>======================//

export type IncomeDispatchTypes =
  | GetIncomeTypeLoading
  | GetIncomeTypeError
  | GetIncomeTypeSuccess
  | AddIncomeTypeLoading
  | AddIncomeTypeError
  | AddIncomeTypeSuccess
  | UpdateIncomeTypeLoading
  | UpdateIncomeTypeError
  | UpdateIncomeTypeSuccess
  | DeleteIncomeTypeLoading
  | DeleteIncomeTypeError
  | DeleteIncomeTypeSuccess
  | GetIncomeDetailsLoading
  | GetIncomeDetailsError
  | GetIncomeDetailsSuccess
  | AddIncomeDetailsLoading
  | AddIncomeDetailsError
  | AddIncomeDetailsSuccess
  | UpdateIncomeDetailsLoading
  | UpdateIncomeDetailsError
  | UpdateIncomeDetailsSuccess
  | DeleteIncomeDetailsLoading
  | DeleteIncomeDetailsError
  | DeleteIncomeDetailsSuccess;
