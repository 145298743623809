import React, { useState, useEffect, useRef } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { useStyles as useStyles_ } from '../../Styles/FormStyles';

import { Grid, TextField } from '@material-ui/core';
import { useForm } from 'react-hook-form';

interface PropsI {
  open: boolean;
  onClose: (value: boolean) => void;
  deleteConfirmed: (value: boolean) => void;
  dialogTitle?: string;
  okText?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalHeader: {
      padding: theme.spacing(1, 3),
      color: '#fff',
      backgroundColor: '#5767a4',
      '& .MuiTypography-body1': {},
    },
    modalContainer: {
      padding: theme.spacing(1, 1, 2, 1),
    },
    modalFooter: {
      padding: theme.spacing(3, 0, 4, 0),
      '& .MuiButton-contained': {
        color: '#fff',
        backgroundColor: '#e96c5a',
      },
    },
  })
);

const BulkDeleteConfirm = (props: PropsI) => {
  const classes = useStyles();
  const classes_ = useStyles_();
  const { open, onClose, deleteConfirmed } = props;
  const [okText, setokText] = useState<boolean>(true);
  const [input, setInput] = useState('');
  const { register, handleSubmit, errors, setError } = useForm({
    mode: 'onChange',
  });

  const handleClose = () => {
    onClose(false);
  };

  const handleDisableConfirmed = (data: any) => {
    // if (input === "delete") {
    //   // deleteConfirmed(true);
    //   setokText(false);
    // } else {
    //   setokText(true);
    // }
    const input_value = data.input_value;
    if (input_value != 'DELETE') {
      setError('input_value', {
        type: 'manual',
        message: "The input value doesn't match as expected",
      });
    } else {
      setokText(false);
    }
  };
  const handleDeleteConfirm = () => {
    deleteConfirmed(true);
    setokText(true);
  };

  const handleSave = (e: any) => {
    e.preventDefault();
    setokText(false);
  };
  // any state variable(s) included in here will trigger the effect to run

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="xl"
    >
      <div style={{ minWidth: '350px' }}>
        <Grid
          container
          className={classes.modalHeader}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography>Delete Record</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon style={{ color: '#fff' }} />
          </IconButton>
        </Grid>
        {okText ? (
          <>
            <Grid
              container
              className={classes.modalContainer}
              justifyContent="center"
            >
              <Typography>
                Are you sure to delete this record?
                {/* {props.dialogTitle
                ? props.dialogTitle
                : "Are you sure to delete this record?"} */}
              </Typography>
            </Grid>
            <form onSubmit={handleSubmit(handleDisableConfirmed)}>
              <Grid
                container
                justifyContent="center"
                // className={classes_.formWrapper}
                style={{ margin: '0px 20px', width: '80%' }}
              >
                {/* <input
                  type="text"
                  placeholder="delete"
                  style={{ height: "30px" }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setInput(event.target.value);
                    console.log(event.target.value);
                  }}
                  value={input}
                  required
                /> */}
                <TextField
                  variant="outlined"
                  name="input_value"
                  placeholder="Type DELETE"
                  autoComplete="off"
                  inputRef={register({
                    required: 'This is required field',
                    maxLength: { value: 6, message: 'max length exceeded' },
                  })}
                />
                <span className={classes_.validationErrorInfo}>
                  {errors.input_value && errors.input_value.message}
                </span>
              </Grid>
              <Grid
                container
                className={classes.modalFooter}
                justifyContent="space-around"
              >
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  // onClick={handleDisableConfirmed}
                  variant="contained"
                  color="secondary"
                  type="submit"
                >
                  Continue
                </Button>

                {/* <Button onClick={handleDeleteConfirmed} variant="contained">
            {props.okText ? props.okText : "Delete"}
          </Button> */}
              </Grid>
            </form>
          </>
        ) : (
          <>
            <Grid
              container
              className={classes.modalContainer}
              justifyContent="center"
            >
              <Typography>
                Are you sure to delete this record?
                {/* {props.dialogTitle
                ? props.dialogTitle
                : "Are you sure to delete this record?"} */}
              </Typography>
            </Grid>
            <Grid
              container
              className={classes.modalFooter}
              justifyContent="space-around"
            >
              <Button onClick={handleClose} variant="outlined" color="primary">
                Cancel
              </Button>

              <Button onClick={handleDeleteConfirm} variant="contained">
                Delete
              </Button>

              {/* <Button onClick={handleDeleteConfirmed} variant="contained">
            {props.okText ? props.okText : "Delete"}
          </Button> */}
            </Grid>
          </>
        )}
      </div>
    </Dialog>
  );
};

export default BulkDeleteConfirm;
