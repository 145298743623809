import axios from 'axios';
import { Dispatch } from 'redux';

import {
  ADD_VEHICLE_ERROR,
  ADD_VEHICLE_LOADING,
  ADD_VEHICLE_SUCCESS,
  DELETE_VEHICLE_ERROR,
  DELETE_VEHICLE_LOADING,
  DELETE_VEHICLE_SUCCESS,
  GET_VEHICLE_ERROR,
  GET_VEHICLE_LOADING,
  GET_VEHICLE_SUCCESS,
  UPDATE_VEHICLE_ERROR,
  UPDATE_VEHICLE_LOADING,
  UPDATE_VEHICLE_SUCCESS,
  VehicleDispatchTypes,
} from './VehiclesActionTypes';

import { SET_SNACKBAR, SetSnackBarI } from '../../SnackbarActionTypes';
import { HeaderConfig } from '../../Auth/Login/LoginAction';
import { ADMIN_API_URL } from '../../../config/SERVER_API_URLS';

interface RequestDataI {
  id: string;
  vehicle_no: string;
  seat_capacity: number;
  vehicle_brand: string;
  vehicle_model: string;
  manufactured_year: number;
  max_speed: number;
}

export const GetVehicle =
  () => async (dispatch: Dispatch<VehicleDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_VEHICLE_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/vehicle-info/`,
        HeaderConfig()
      );

      dispatch({
        type: GET_VEHICLE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_VEHICLE_ERROR,
      });
    }
  };

export const AddVehicle =
  (data: RequestDataI) =>
  async (dispatch: Dispatch<VehicleDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_VEHICLE_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/vehicle-info/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_VEHICLE_SUCCESS,
        payload: res.data,
      });
      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'success',
          snackbarMessage: 'Vehicle Added Successfully',
        },
      });
    } catch (error: any) {
      dispatch({
        type: ADD_VEHICLE_ERROR,
        payload: error.response && error.response?.data,
      });
      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: 'Adding Vehicle Failed',
        },
      });
    }
  };

export const UpdateVehicle =
  (id: string, data: RequestDataI) =>
  async (dispatch: Dispatch<VehicleDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_VEHICLE_LOADING,
      });

      // TODO: Change url once available
      const res = await axios.put(
        `${ADMIN_API_URL}/vehicle-info/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_VEHICLE_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'success',
          snackbarMessage: 'Vehicle Updated Successfully',
        },
      });
    } catch (error: any) {
      dispatch({
        type: UPDATE_VEHICLE_ERROR,
        payload: error.response && error.response?.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: 'Vehicle Update Failed',
        },
      });
    }
  };

export const DeleteVehicle =
  (id: string) =>
  async (dispatch: Dispatch<VehicleDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_VEHICLE_LOADING,
      });

      const res = await axios.delete(
        `${ADMIN_API_URL}/vehicle-info/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_VEHICLE_SUCCESS,
        payload: { id: id },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'success',
          snackbarMessage: 'Vehicle Deleted Successfully',
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_VEHICLE_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: 'Vehicle Delete Failed',
        },
      });
    }
  };
