import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { AcademicSidebar, Header } from "../../components";
import AssignClassTeacherForm from "../../components/Academic/AssignClassTeacher/AssignClassTeacherForm";
import AssignClassTeacherTable from "../../components/Academic/AssignClassTeacher/AssignClassTeacherTable";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";
import { ClassTeacherTypeI } from "../../actions/Academics/ClassTeacher/ClassTeacherActionTypes";

const ClassPage = () => {
  const [formData, setFormData] = useState<ClassTeacherTypeI | null>(null);
  const [count, setCount] = useState<number | undefined>(0);
  const [edit, setEdit] = useState<any>();

  const fetchEditData = (data: ClassTeacherTypeI) => {
    setFormData({ ...data, count: count });
    count && setCount(count + 1);
  };

  const onEditMode = (value: boolean) => {
    setEdit({ value, count });
  };

  const nav = <AcademicSidebar assignClassTeacher />;
  return (
    <SubModuleLayout academicMenuActive sideNav={nav}>
      <Grid container>
        <Grid item xs={3}>
          <AssignClassTeacherForm editData={formData} onEditMode={onEditMode} />
        </Grid>
        <Grid item xs={9}>
          <AssignClassTeacherTable onEditData={fetchEditData} edit={edit} />
        </Grid>
      </Grid>
    </SubModuleLayout>
  );
};
export default ClassPage;
