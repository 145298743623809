// ---------------------------- <START> module imports start ----------------------------------//
import React, { useRef } from "react";
import { Card } from "@material-ui/core";
import { useSelector } from "react-redux";
import { RootStore } from "../../../store";
import { Sticky } from "../Marksheet/ReportStyles";
import ReactToPrint from "react-to-print";
import getFullName from "../../utils/getFullName";
import AdmitCardSample1 from "../AdmitCardDesign/AdmitCardSample1";
import AdmitCardSample2 from "../AdmitCardDesign/AdmitCardSample2";
// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const MultipleAdmitCardPopup = (props: any) => {
  const componentRef = useRef<any>();

  // ============================ <START> REACT HOOKS <START> ============================== //
  const idCardSelector = useSelector((state: RootStore) => state.id_card);

  const renderSwitch = (item: any, param: string) => {
    const idCardData = {
      school_name: item.school_name,
      school_address: item.school_address,
      school_phone: item.school_number,
      student_photo: item.student_photo,
      slogan: item.school_slogan,
      student_name: getFullName(
        item.student_first_name,
        item.student_last_name
      ),
      admission_no: item.admission_number,
      father_name: getFullName(
        item.guardian_first_name,
        item.guardian_last_name
      ),
      address: item.address,
      dob: item.date_of_birth,
      blood_group: item.blood_group,
      phone_number: item.phone_number,
    };
    switch (param) {
      case "ABC-003":
        return <AdmitCardSample1 content={props.content} data={idCardData} />;
      case "ABC-004":
        return <AdmitCardSample2 content={props.content} data={idCardData} />;
      default:
        return "Nothing Found";
    }
  };
  // ============================ <END> EVENT HANDLERS <END> ============================== //
  return (
    <>
      <Sticky>
        <ReactToPrint
          trigger={() => (
            <p
              style={{
                backgroundColor: "#132E98",
                color: "white",
                fontWeight: "bold",
                borderRadius: "0px",
                margin: "0px",
                textAlign: "center",
                padding: "5px 0px",
                cursor: "pointer",
              }}
            >
              Print This Out !
            </p>
          )}
          content={() => componentRef.current}
          removeAfterPrint={true}
          copyStyles={true}
        />
      </Sticky>
      <Card id="print-element" ref={componentRef} style={{ padding: "10px" }}>
        {idCardSelector?.id_cards?.student_info?.map(
          (item: any, index: number) => {
            return (
              <div key={index} style={{ marginBottom: "25px" }}>
                {renderSwitch(item, props?.content?.code)}
              </div>
            );
          }
        )}
      </Card>
    </>
  );
};

// ---------------------------- <END> Student Table Component Ends -------------------------------//
export default MultipleAdmitCardPopup;
