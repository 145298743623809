// ---------------------------- <START> module imports start ----------------------------------//
import React, { useEffect, useState } from "react";
import {
  TableBody,
  TextField,
  Grid,
  Typography,
  Paper,
  Chip,
  LinearProgress,
} from "@material-ui/core";
import { DeleteForeverOutlined } from "@material-ui/icons";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";

import { useTable } from "../../Reusable";
import { useTableStyles } from "../../Styles/TableStyles";
import AnnoucementSearch from "./AnnoucementSearch";
import TableActionButtons from "../../Reusable/Buttons/TableActionButtons";
import ActionButton from "../../Reusable/Buttons/ActionButton";
import { AnnouncementTypeI } from "../../../actions/Communication/Announcement/AnnouncementActionTypes";
import {
  DeleteAnnouncement,
  GetAnnouncements,
} from "../../../actions/Communication/Announcement/AnnouncementAction";
import { RootStore } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import Popups from "../../Reusable/Dialogs/Popups";
import { AddAnnoucModalPage } from "../../../pages";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import { dateConverterAdToBs } from "../../utils/dateConverter";
import { Pagination } from "@material-ui/lab";
import AccessPermission from "../../Student/StudentDetail/AccessPermission";
import AnnouncementViewModal from "./AnnouncementViewModal";
// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//
export interface DataI {
  data: AnnouncementTypeI;
  message_to: React.ReactNode;
}

interface HeadCellsI {
  id: string;
  label: string;
}

//---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N" },
  { id: "annoucement_category", label: "Annoucement Category" },
  { id: "annoucement_title", label: "Annoucement Title" },
  { id: "published_date", label: "Published Date" },
  { id: "message_to", label: "Message To" },
  { id: "action", label: "Action" },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

export const createChips = (data: string[]) => {
  return (
    <Grid container spacing={1}>
      {data.map((element, index) => (
        <Grid item key={index + 1}>
          <Chip
            style={{ backgroundColor: "#A1ABD6", color: "white" }}
            size="small"
            label={element}
          />
        </Grid>
      ))}
    </Grid>
  );
};

// ---------------------------- <START> Annoucement Table Component Starts ---------------------------//
const AnnoucementList: React.FC = () => {
  const classes = useTableStyles();

  // States declaration
  const [tableData, setTableData] = useState<DataI[]>([]);
  const [tempTableData, setTempTableData] = useState<DataI[]>([]);

  const [openPopup, setOpenPopup] = useState(false);
  const [editData, setEditData] = useState<AnnouncementTypeI | null>(null);

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<number | null>(null);

  const [openViewModalPopup, setOpenViewModalPopup] = useState(false);
  const [item, setItem] = useState<DataI | null>(null);

  const [totalRecord, setTotalRecord] = useState(tempTableData.length);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(
    Math.ceil(totalRecord / rowsPerPage)
  );
  const [page, setPage] = useState(1);

  const [recordFrom, setRecordFrom] = useState(1);
  const [recordTo, setRecordTo] = useState(rowsPerPage);

  const [loading, setLoading] = useState<boolean>(false);

  //=============================== <START> REACT HOOKS <START> =================================//

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
    setRecordFrom(newPage === 1 ? newPage : (newPage - 1) * rowsPerPage + 1);
    setRecordTo(newPage === 1 ? newPage * rowsPerPage : newPage * rowsPerPage);
  };

  const dispatch = useDispatch();
  const announcementSelector = useSelector(
    (state: RootStore) => state.announcement
  );

  useEffect(() => {
    dispatch(GetAnnouncements("", "", ""));
  }, []);

  useEffect(() => {
    const data: AnnouncementTypeI[] = announcementSelector.announcements;
    const modData: DataI[] = [];

    data.map((element) => {
      if (element.group.length) {
        const groups: string[] = [];
        element.group.map((group) => {
          groups.push(group.name);
        });
        modData.push({
          data: { ...element },
          message_to: createChips(groups),
        });
      } else if (element.user.length) {
        const users: string[] = [];
        element.user.map((user) => {
          users.push(`${user.first_name} ${user.last_name}`);
        });
        modData.push({
          data: { ...element },
          message_to: createChips(users),
        });
      } else if (element.grade) {
        const grade: string[] = [];
        element.section.length
          ? element.section.map((section) => {
              grade.push(`Class ${element.grade_name} (${section.name})`);
            })
          : grade.push(`Class ${element.grade_name}`);

        modData.push({
          data: { ...element },
          message_to: createChips(grade),
        });
      } else {
        modData.push({
          data: { ...element },
          message_to: createChips([]),
        });
      }
    });

    setTableData(modData);
    setTempTableData(modData);
    setTotalRecord(modData.length);
    setPageCount(Math.ceil(modData.length / rowsPerPage));

    setLoading(announcementSelector.loading);
  }, [announcementSelector]);

  //=============================== <END> REACT HOOKS <END> =================================//

  //=============================== <START> EVENT HANDLERS <START> =================================//

  // Retrieving re-usable components from useTable
  const {
    TblContainer,
    TblHead,
    StyledTableCell,
    StyledTableRow,
    exportToExcel,
    tablePrint,
  } = useTable(headCells);

  // Event function for table searching by name
  const handleTableSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const search_data = tempTableData.filter((data) =>
      data.data.title.toUpperCase().includes(e.target.value.toUpperCase())
    );
    setTableData(search_data);
  };

  // Function for handling action icons click event
  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const handleDeleteModal = (value: boolean, id: number) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteAnnouncement(itemId));
    setDeleteModalOpen(false);
  };

  const handleClose = (value: boolean) => {
    setOpenPopup(value);
  };

  // Modal View Popup
  const handleViewModal = (item: DataI) => {
    setItem(item);
    setItemId(item.data.id);
    setOpenViewModalPopup(true);
  };

  const handleViewModalClose = (value: boolean) => {
    setOpenViewModalPopup(value);
  };

  const onActionConfirmed = (confirmed: boolean) => {
    setOpenViewModalPopup(!confirmed);
  };

  //=============================== <END> EVENT HANDLERS <END> =================================//

  return (
    <>
      <AnnoucementSearch />

      <Paper className={classes.rootTableBox}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">Annoucement List</Typography>
          </Grid>
        </Grid>
        <div className={classes.tableWrapper}>
          <div className={classes.tableTop}>
            <TextField
              autoComplete="off"
              autoFocus={false}
              // focused={false}
              variant="outlined"
              name="search"
              placeholder="Search..."
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleTableSearch(e)
              }
            />
            {/* <TableActionButtons
              exportToExcel={exportToExcel}
              tablePrint={tablePrint}
            /> */}
          </div>
          <TblContainer>
            <TblHead />
            <TableBody>
              {tableData
                .slice(
                  (page - 1) * rowsPerPage,
                  (page - 1) * rowsPerPage + rowsPerPage
                )
                .map((item: DataI, index: number) => (
                  <StyledTableRow key={item.data.id}>
                    <StyledTableCell align="center" className={classes.cell}>
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell align="center" className={classes.cell}>
                      {item.data.announcement_category_name}
                    </StyledTableCell>
                    <StyledTableCell align="center" className={classes.cell}>
                      {item.data.title}
                    </StyledTableCell>
                    <StyledTableCell align="center" className={classes.cell}>
                      {item.data.announcement_date
                        ? dateConverterAdToBs(item.data.announcement_date)
                        : dateConverterAdToBs(item.data.created_on)}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={classes.cell}
                      style={{ textAlign: "left", paddingLeft: "15px" }}
                    >
                      {item.message_to}
                    </StyledTableCell>
                    <StyledTableCell align="center" className={classes.cell}>
                      <AccessPermission>
                        <ActionButton
                          title="View"
                          onClick={(
                            event: React.MouseEvent<
                              HTMLAnchorElement,
                              MouseEvent
                            >
                          ) => handleViewModal(item)}
                        >
                          <VisibilityOutlinedIcon
                            className={classes.viewIcon}
                          />
                        </ActionButton>
                        <ActionButton
                          title="Delete"
                          onClick={() => {
                            handleDeleteModal(true, item.data.id);
                          }}
                        >
                          <DeleteForeverOutlined
                            className={classes.deleteIcon}
                          />
                        </ActionButton>
                        {/* <a className={classes.Btn} onClick={handleActionClicked}>
                      <EditOutlined className={classes.editIcon} />
                    </a>
                    <a className={classes.Btn} onClick={handleActionClicked}>
                      <DeleteForeverOutlined className={classes.deleteIcon} />
                    </a> */}
                      </AccessPermission>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </TblContainer>
          {loading ? <LinearProgress /> : null}
          <div className={classes.tableBottom}>
            {totalRecord > 0 ? (
              <Typography>
                Showing {recordFrom} to{" "}
                {recordTo > totalRecord ? totalRecord : recordTo} of{" "}
                {totalRecord}
              </Typography>
            ) : (
              <Typography>No records found</Typography>
            )}
            <div className={classes.pageNavigation}>
              <Pagination
                count={pageCount}
                page={page}
                onChange={handlePageChange}
                shape="rounded"
                color="primary"
              />
            </div>
          </div>
        </div>
      </Paper>
      <Popups
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        onClose={handleClose}
      >
        <AddAnnoucModalPage
          onClose={handleClose}
          mode="edit"
          initialData={editData}
        />
      </Popups>
      <Popups
        openPopup={openViewModalPopup}
        setOpenPopup={setOpenViewModalPopup}
        onClose={handleViewModalClose}
        maxWidth="md"
      >
        <AnnouncementViewModal item={item} />
      </Popups>
      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
      />
    </>
  );
};
// ---------------------------- <END> Annoucement Table Component Ends -------------------------------//

export default AnnoucementList;
