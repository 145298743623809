import {
  GET_IDCARD_TEMPLATE_LOADING,
  GET_IDCARD_TEMPLATE_ERROR,
  GET_IDCARD_TEMPLATE_SUCCESS,
  ADD_IDCARD_TEMPLATE_LOADING,
  ADD_IDCARD_TEMPLATE_ERROR,
  ADD_IDCARD_TEMPLATE_SUCCESS,
  ACTIVATE_OR_DEACTIVATE_IDCARD_TEMPLATE_LOADING,
  ACTIVATE_OR_DEACTIVATE_IDCARD_TEMPLATE_ERROR,
  ACTIVATE_OR_DEACTIVATE_IDCARD_TEMPLATE_SUCCESS,
  DELETE_IDCARD_TEMPLATE_LOADING,
  DELETE_IDCARD_TEMPLATE_ERROR,
  DELETE_IDCARD_TEMPLATE_SUCCESS,
  GET_SINGLE_IDCARD_TEMPLATE_LOADING,
  GET_SINGLE_IDCARD_TEMPLATE_ERROR,
  GET_SINGLE_IDCARD_TEMPLATE_SUCCESS,
  UPDATE_IDCARD_TEMPLATE_LOADING,
  UPDATE_IDCARD_TEMPLATE_ERROR,
  UPDATE_IDCARD_TEMPLATE_SUCCESS,
  IdCardTemplateDispatchTypes,
} from "../../actions/Templates/IdCard/IdCardTemplateActionTypes";

interface InitialStateI {
  loading: boolean;
  id_card_templates: any;
  id_card_template: any;
  add_or_update: boolean;
}

const initialState: InitialStateI = {
  loading: false,
  id_card_templates: [],
  id_card_template: null,
  add_or_update: false,
};

const IdCardTemplateReducer = (
  state: InitialStateI = initialState,
  action: IdCardTemplateDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_IDCARD_TEMPLATE_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case GET_IDCARD_TEMPLATE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_IDCARD_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        id_card_templates: action.payload,
      };

    case GET_SINGLE_IDCARD_TEMPLATE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_SINGLE_IDCARD_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        id_card_template: action.payload,
      };

    case GET_SINGLE_IDCARD_TEMPLATE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case ADD_IDCARD_TEMPLATE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case ADD_IDCARD_TEMPLATE_ERROR:
      return {
        ...state,
        loading: false,
        add_or_update: false
      };

    case ADD_IDCARD_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        id_card_templates: [...state.id_card_templates, action.payload],
        add_or_update: true,
      };

    case UPDATE_IDCARD_TEMPLATE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_IDCARD_TEMPLATE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case UPDATE_IDCARD_TEMPLATE_SUCCESS:
      const _current_data: any = state.id_card_templates;
      const _index: number = _current_data.findIndex(
        (element: any) => element.id === action.payload.id
      );
      _current_data[_index] = action.payload;

      return {
        ...state,
        loading: false,
        id_card_templates: _current_data,
        add_or_update: true,
      };

    case ACTIVATE_OR_DEACTIVATE_IDCARD_TEMPLATE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case ACTIVATE_OR_DEACTIVATE_IDCARD_TEMPLATE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case ACTIVATE_OR_DEACTIVATE_IDCARD_TEMPLATE_SUCCESS:
      const current_data: any = state.id_card_templates;
      const index: number = current_data.findIndex(
        (element: any) => element.id === action.payload.id
      );
      current_data[index] = action.payload;

      return {
        ...state,
        loading: false,
        id_card_templates: current_data,
      };

    case DELETE_IDCARD_TEMPLATE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_IDCARD_TEMPLATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case DELETE_IDCARD_TEMPLATE_SUCCESS:
      const new_data: any = state.id_card_templates;
      const item_index = new_data.findIndex(
        (element: any) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return {
        ...state,
        loading: false,
        id_card_templates: new_data,
      };

    default:
      return state;
  }
};

export default IdCardTemplateReducer;
