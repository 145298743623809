import {
  makeStyles,
  Theme,
  createStyles,
  Grid,
  Chip,
  List,
  ListItem,
  Typography,
  Divider,
  Button,
} from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import React from "react";
import { downloadEmployeeData } from "../../HumanResource/StaffList/StaffProfile/StaffTabs/StaffDocument";
import { DownloadButton } from "../../Reusable/Buttons/TableButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chipLink: {
      textDecoration: "none",
    },
  })
);

interface PropsI {
  item: any | null;
}

const ViewExpenseDetails = (props: PropsI) => {
  const { item } = props;
  const classes = useStyles();

  const createLinkChips = (arr: string[]) => {
    return (
      <Grid container spacing={1}>
        {arr.map((element: string, index: number) => (
          <Grid item key={index + 1}>
            <a
              href={element}
              target="_blank"
              className={classes.chipLink}
              rel="noreferrer"
            >
              <Chip
                style={{
                  backgroundColor: "#A1ABD6",
                  color: "white",
                  cursor: "pointer",
                }}
                size="small"
                label={element}
              />
            </a>
          </Grid>
        ))}
      </Grid>
    );
  };

  const getDocName = (name: string) => {
    const target = name.split("/").pop();
    return target;
  };

  const listDocuments = (docs: string) => {
    return (
      <List>
        <ListItem>
          <DownloadButton
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              downloadEmployeeData(docs)
            }
          />{" "}
          {getDocName(docs)}
        </ListItem>
      </List>
    );
  };

  return (
    <>
      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={4}>
          Expense Type:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {item?.expense_type_name}
          </Typography>
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={4}>
          Name:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {item?.name}
          </Typography>
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={4}>
          Expense Date:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {item?.expense_date}
          </Typography>
        </Grid>
      </Grid>
      <Divider style={{ marginBottom: "20px" }} />
      <Grid container style={{ marginBottom: "10px" }}>
        <Grid item xs={4}>
          Description:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {item?.description}
          </Typography>
        </Grid>
      </Grid>
      {item?.documents && (
        <>
          <Divider style={{ marginBottom: "20px" }} />
          <Grid container style={{ marginBottom: "10px" }}>
            <Grid style={{ marginTop: "14px" }} item xs={4}>
              <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
                File:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
                {item?.documents && listDocuments(item.documents)}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default ViewExpenseDetails;
