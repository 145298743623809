export const GET_LEAVE_TYPE_LOADING = "GET_LEAVE_TYPE_LOADING";
export const GET_LEAVE_TYPE_ERROR = "GET_LEAVE_TYPE_ERROR";
export const GET_LEAVE_TYPE_SUCCESS = "GET_LEAVE_TYPE_SUCCESS";

export const ADD_LEAVE_TYPE_LOADING = "ADD_LEAVE_TYPE_LOADING";
export const ADD_LEAVE_TYPE_ERROR = "ADD_LEAVE_TYPE_ERROR";
export const ADD_LEAVE_TYPE_SUCCESS = "ADD_LEAVE_TYPE_SUCCESS";

export const UPDATE_LEAVE_TYPE_LOADING = "UPDATE_LEAVE_TYPE_LOADING";
export const UPDATE_LEAVE_TYPE_ERROR = "UPDATE_LEAVE_TYPE_ERROR";
export const UPDATE_LEAVE_TYPE_SUCCESS = "UPDATE_LEAVE_TYPE_SUCCESS";

export const DELETE_LEAVE_TYPE_LOADING = "DELETE_LEAVE_TYPE_LOADING";
export const DELETE_LEAVE_TYPE_ERROR = "DELETE_LEAVE_TYPE_ERROR";
export const DELETE_LEAVE_TYPE_SUCCESS = "DELETE_LEAVE_TYPE_SUCCESS";

export type LeaveTypeTypeI = {
  id: string;
  name: string;
  max_leave: number;
  max_leave_per: string;
  is_accumulated: boolean;
  is_paid: boolean;
  min_exp: number;
};

//===============================<START>GET LEAVE_TYPE<START>======================//

export interface GetLeaveTypeLoading {
  type: typeof GET_LEAVE_TYPE_LOADING;
}

export interface GetLeaveTypeError {
  type: typeof GET_LEAVE_TYPE_ERROR;
}

export interface GetLeaveTypeSuccess {
  type: typeof GET_LEAVE_TYPE_SUCCESS;
  payload: LeaveTypeTypeI[];
}

//===============================<END>GET LEAVE_TYPE<END>======================//

//===============================<START>ADD LEAVE_TYPE<START>======================//

export interface AddLeaveTypeLoading {
  type: typeof ADD_LEAVE_TYPE_LOADING;
}

export interface AddLeaveTypeError {
  type: typeof ADD_LEAVE_TYPE_ERROR;
}

export interface AddLeaveTypeSuccess {
  type: typeof ADD_LEAVE_TYPE_SUCCESS;
  payload: LeaveTypeTypeI;
}

//===============================<END>ADD LEAVE_TYPE<END>======================//

//===============================<START>UPDATE LEAVE_TYPE<START>======================//

export interface UpdateLeaveTypeLoading {
  type: typeof UPDATE_LEAVE_TYPE_LOADING;
}

export interface UpdateLeaveTypeError {
  type: typeof UPDATE_LEAVE_TYPE_ERROR;
}

export interface UpdateLeaveTypeSuccess {
  type: typeof UPDATE_LEAVE_TYPE_SUCCESS;
  payload: LeaveTypeTypeI;
}

//===============================<END>UPDATE LEAVE_TYPE<END>======================//

//===============================<START>DELETE LEAVE_TYPE<START>======================//

export interface DeleteLeaveTypeLoading {
  type: typeof DELETE_LEAVE_TYPE_LOADING;
}

export interface DeleteLeaveTypeError {
  type: typeof DELETE_LEAVE_TYPE_ERROR;
}

export interface DeleteLeaveTypeSuccess {
  type: typeof DELETE_LEAVE_TYPE_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE LEAVE_TYPE<END>======================//

export type LeaveTypeDispatchTypes =
  | GetLeaveTypeLoading
  | GetLeaveTypeError
  | GetLeaveTypeSuccess
  | AddLeaveTypeLoading
  | AddLeaveTypeError
  | AddLeaveTypeSuccess
  | UpdateLeaveTypeLoading
  | UpdateLeaveTypeError
  | UpdateLeaveTypeSuccess
  | DeleteLeaveTypeLoading
  | DeleteLeaveTypeError
  | DeleteLeaveTypeSuccess;
