import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  GetStudentsByAllSectionPayloadI,
  initialStateI,
  SectionStudentI,
} from "./sectionTransfer";
import {
  getStudentsByAllSections,
  transferSections,
} from "./sectionTransferApi";

const initialState: initialStateI = {
  loading: false,
  actionPerformed: false,
  grade: "",
  sections: {
    first: { id: "", name: "" },
    second: { id: "", name: "" },
  },
  firstSection: [],
  secondSection: [],
};

const SectionTransferSlice = createSlice({
  name: "sectionTransfer",
  initialState,
  reducers: {
    transferToRight: (
      state,
      { payload }: PayloadAction<{ students: string[] }>
    ) => {
      const staged = state.firstSection
        .filter((el) => payload.students.includes(el.id))
        .map((el) => ({
          ...el,
          changed: true,
          final: state.sections.second.id,
        }));
      state.secondSection = staged.concat(state.secondSection);
      state.firstSection = state.firstSection.filter(
        (el) => !payload.students.includes(el.id)
      );
    },
    transferToLeft: (
      state,
      { payload }: PayloadAction<{ students: string[] }>
    ) => {
      const staged = state.secondSection
        .filter((el) => payload.students.includes(el.id))
        .map((el) => ({
          ...el,
          changed: true,
          final: state.sections.first.id,
        }));
      state.firstSection = staged.concat(state.firstSection);
      state.secondSection = state.secondSection.filter(
        (el) => !payload.students.includes(el.id)
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStudentsByAllSections.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(
      getStudentsByAllSections.fulfilled,
      (
        state,
        {
          payload,
        }: PayloadAction<{
          res: GetStudentsByAllSectionPayloadI;
          grade: string;
          firstSection: { id: string; name: string };
          secondSection: { id: string; name: string };
        }>
      ) => {
        const dataFirst = payload.res[payload.firstSection.id] || undefined;
        const dataSecond = payload.res[payload.secondSection.id] || undefined;

        if (dataFirst) {
          const firstData: SectionStudentI[] = dataFirst.map((el) => ({
            id: el.academic_id,
            name: `${el.student_first_name} ${el.student_last_name}`,
            init: el.section_id,
            final: "",
            changed: false,
          }));

          state.firstSection = firstData;
        } else {
          state.firstSection = [];
        }
        if (dataSecond) {
          const secondData: SectionStudentI[] = dataSecond.map((el) => ({
            id: el.academic_id,
            name: `${el.student_first_name} ${el.student_last_name}`,
            init: el.section_id,
            final: "",
            changed: false,
          }));

          state.secondSection = secondData;
        } else {
          state.secondSection = [];
        }

        state.grade = payload.grade;
        state.sections = {
          first: payload.firstSection,
          second: payload.secondSection,
        };

        state.loading = false;
      }
    );
    builder.addCase(getStudentsByAllSections.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(transferSections.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(transferSections.fulfilled, (state) => {
      state.loading = false;
      state.actionPerformed = true;
    });
    builder.addCase(transferSections.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { transferToLeft, transferToRight } = SectionTransferSlice.actions;

export default SectionTransferSlice.reducer;
