import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { dateConverterBsToAd } from "../../../../components/utils/dateConverter";
import {
  ExamScheduleI,
  ExamSchedulePayloadI,
  InitialStateI,
} from "./examSchedule";
import { getExamSchedule, updateExamSchedule } from "./examScheduleApi";

const initialState: InitialStateI = {
  loading: false,
  actionPerformed: false,
  examSchedule: [],
};

const examScheduleSlice = createSlice({
  name: "ExamSchedule",
  initialState,
  reducers: {
    changeData: (
      state,
      { payload }: PayloadAction<{ id: string; field: string; value: string }>
    ) => {
      const { id, field, value } = payload;
      const target = state.examSchedule.find((el) => el.id === id);

      if (target) {
        if (field === "date") {
          target.exam_subject_info.date = dateConverterBsToAd(value);

          state.examSchedule.sort((b, a) => {
            return (
              new Date(b.exam_subject_info.date).getTime() -
              new Date(a.exam_subject_info.date).getTime()
            );
          });
        }
        if (field === "time") {
          target.exam_subject_info.time = value;
        }
        if (field === "duration") {
          const re = /^[0-9]*\.?[0-9]*$/;
          if (value === "" || re.test(value)) {
            target.exam_subject_info.duration = value;
          }
        }
        if (field === "full_marks_theory") {
          const re = /^[0-9]*\.?[0-9]*$/;
          if (value === "" || re.test(value)) {
            target.exam_subject_info.full_marks_theory = value;
          }
        }
        if (field === "full_marks_practical") {
          const re = /^[0-9]*\.?[0-9]*$/;
          if (value === "" || re.test(value)) {
            target.exam_subject_info.full_marks_practical = value;
          }
        }
        if (field === "pass_marks_theory") {
          const re = /^[0-9]*\.?[0-9]*$/;
          if (value === "" || re.test(value)) {
            target.exam_subject_info.pass_marks_theory = value;
          }
        }
        if (field === "pass_marks_practical") {
          const re = /^[0-9]*\.?[0-9]*$/;
          if (value === "" || re.test(value)) {
            target.exam_subject_info.pass_marks_practical = value;
          }
        }
      }
    },
    presetDate: (state, { payload }: PayloadAction<string>) => {
      state.examSchedule = state.examSchedule.map((el) => ({
        ...el,
        exam_subject_info: {
          ...el.exam_subject_info,
          date: el.exam_subject_info.date || payload,
        },
      }));
    },
    resetData: (state) => {
      state.loading = false;
      state.examSchedule = [];
      state.actionPerformed = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getExamSchedule.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(
      getExamSchedule.fulfilled,
      (state, { payload }: PayloadAction<ExamSchedulePayloadI[]>) => {
        state.loading = false;
        const data: ExamScheduleI[] = [];
        payload.forEach((el) => {
          data.push({
            id: el.id,
            subject: el.name,
            exam_subject_info: {
              id: el.exam_subject_info?.id || undefined,
              result_type: el.subject_info?.result_type || 0,
              date: el.exam_subject_info?.exam_date || "",
              time: el.exam_subject_info?.start_time || "",
              duration: el.exam_subject_info?.duration || "",
              full_marks_theory: el.exam_subject_info
                ? el.exam_subject_info.full_marks_theory
                  ? String(el.exam_subject_info.full_marks_theory)
                  : ""
                : "",
              pass_marks_theory: el.exam_subject_info
                ? el.exam_subject_info.pass_marks_theory
                  ? String(el.exam_subject_info.pass_marks_theory)
                  : ""
                : "",
              has_practical: el.subject_info?.has_practical || false,
              full_marks_practical: el.exam_subject_info?.full_marks_practical
                ? String(el.exam_subject_info.full_marks_practical)
                : "",
              pass_marks_practical: el.exam_subject_info?.pass_marks_practical
                ? String(el.exam_subject_info.pass_marks_practical)
                : "",
            },
          });
        });
        state.examSchedule = data;
      }
    );
    builder.addCase(getExamSchedule.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateExamSchedule.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(updateExamSchedule.fulfilled, (state) => {
      state.loading = false;
      state.actionPerformed = true;
    });
    builder.addCase(updateExamSchedule.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  changeData: changeExamScheduleData,
  presetDate,
  resetData,
} = examScheduleSlice.actions;

export default examScheduleSlice.reducer;
