import React from "react";
import { CommunicationSidebar } from "../../components";
import AnnoucementList from "../../components/Communication/Annoucement/AnnoucementList";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const AnnoucementPage = () => {
  const nav = <CommunicationSidebar announcement />;
  return (
    <SubModuleLayout communicationMenuActive sideNav={nav}>
      <AnnoucementList />
    </SubModuleLayout>
  );
};

export default AnnoucementPage;
