import { produce } from "immer";

import {
  GET_PRE_TIME_TABLE_LOADING,
  GET_PRE_TIME_TABLE_ERROR,
  GET_PRE_TIME_TABLE_SUCCESS,
  ADD_PRE_TIME_TABLE_LOADING,
  ADD_PRE_TIME_TABLE_ERROR,
  ADD_PRE_TIME_TABLE_SUCCESS,
  UPDATE_PRE_TIME_TABLE_LOADING,
  UPDATE_PRE_TIME_TABLE_ERROR,
  UPDATE_PRE_TIME_TABLE_SUCCESS,
  DELETE_PRE_TIME_TABLE_LOADING,
  DELETE_PRE_TIME_TABLE_ERROR,
  DELETE_PRE_TIME_TABLE_SUCCESS,
  PreTimeTableDispatchTypes,
  GET_ALL_PRE_TIME_TABLE_LOADING,
  GET_ALL_PRE_TIME_TABLE_ERROR,
  GET_ALL_PRE_TIME_TABLE_SUCCESS,
  ADD_PERIOD,
  UPDATE_PERIOD,
  DELETE_PERIOD,
} from "../../actions/Academics/PreTimeTable/PreTimeTableActionTypes";

interface InitialStateI {
  loading: boolean;
  preTimeTables: any;
  actionPerformed: boolean;
  allPreTimeTable?: any;
  periods: { id: string; period: string; startTime: string; endTime: string }[];
}

const initialState: InitialStateI = {
  loading: false,
  preTimeTables: [],
  actionPerformed: false,
  allPreTimeTable: [],
  periods: [],
};

const PreTimeTableReducer = (
  state: InitialStateI = initialState,
  action: PreTimeTableDispatchTypes
): InitialStateI =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_PRE_TIME_TABLE_LOADING:
        return {
          ...state,
          loading: true,
        };

      case GET_PRE_TIME_TABLE_ERROR:
        return {
          ...state,
          loading: false,
        };

      case GET_PRE_TIME_TABLE_SUCCESS:
        return {
          ...state,
          loading: false,
          preTimeTables: action.payload,
        };

      case GET_ALL_PRE_TIME_TABLE_LOADING:
        return {
          ...state,
          loading: true,
        };

      case GET_ALL_PRE_TIME_TABLE_ERROR:
        return {
          ...state,
          loading: false,
        };

      case GET_ALL_PRE_TIME_TABLE_SUCCESS:
        return {
          ...state,
          loading: false,
          allPreTimeTable: action.payload,
        };

      case ADD_PRE_TIME_TABLE_LOADING:
        return {
          ...state,
          loading: true,
        };

      case ADD_PRE_TIME_TABLE_SUCCESS:
        return {
          ...state,
          loading: false,
          actionPerformed: true,
          preTimeTables: [...state.preTimeTables, action.payload],
        };

      case ADD_PRE_TIME_TABLE_ERROR:
        return {
          ...state,
          loading: false,
        };

      case UPDATE_PRE_TIME_TABLE_LOADING:
        return {
          ...state,
          loading: true,
          actionPerformed: false,
        };

      case UPDATE_PRE_TIME_TABLE_SUCCESS:
        const current_data: any = state.preTimeTables;
        const index: number = current_data.findIndex(
          (element: any) => element.id === action.payload.id
        );
        current_data[index] = action.payload;

        return {
          ...state,
          loading: false,
          preTimeTables: current_data,
          actionPerformed: true,
        };

      case UPDATE_PRE_TIME_TABLE_ERROR:
        return {
          ...state,
          loading: false,
        };

      case DELETE_PRE_TIME_TABLE_LOADING:
        return {
          ...state,
          loading: true,
        };

      case DELETE_PRE_TIME_TABLE_ERROR:
        return {
          ...state,
          loading: false,
        };

      case DELETE_PRE_TIME_TABLE_SUCCESS:
        const new_data: any = state.preTimeTables;
        const item_index = new_data.findIndex(
          (element: any) => element.id === action.payload.id
        );
        new_data.splice(item_index, 1);

        return {
          ...state,
          loading: false,
          preTimeTables: new_data,
        };

      default:
        break;
    }
  });

export default PreTimeTableReducer;
