export const GET_RESULT_LOADING = "GET_RESULT_LOADING";
export const GET_RESULT_ERROR = "GET_RESULT_ERROR";
export const GET_RESULT_SUCCESS = "GET_RESULT_SUCCESS";

export const GENERATE_RESULT_LOADING = "GENERATE_RESULT_LOADING";
export const GENERATE_RESULT_ERROR = "GENERATE_RESULT_ERROR";
export const GENERATE_RESULT_SUCCESS = "GENERATE_RESULT_SUCCESS";

export type ResultTypeI = {
  student_id: string;
  student_academic: string;
  student_first_name: string;
  student_last_name: string;
  student_roll_number: string;
  obtain_gpa: number;
  obtain_grade: string;
  obtain_percentage: number;
};

//===============================<START>GET RESULTS<START>======================//

export interface GetResultLoading {
  type: typeof GET_RESULT_LOADING;
}

export interface GetResultError {
  type: typeof GET_RESULT_ERROR;
}

export interface GetResultSuccess {
  type: typeof GET_RESULT_SUCCESS;
  payload: ResultTypeI[];
}

//===============================<END>GET RESULTS<END>======================//

//===============================<START>GENERATE RESULT<START>======================//

export interface GenerateResultLoading {
  type: typeof GENERATE_RESULT_LOADING;
}

export interface GenerateResultError {
  type: typeof GENERATE_RESULT_ERROR;
}

export interface GenerateResultSuccess {
  type: typeof GENERATE_RESULT_SUCCESS;
  payload: { message: string };
}

//===============================<END>GENERATE RESULT<END>======================//

export type ResultDispatchTypes =
  | GetResultLoading
  | GetResultError
  | GetResultSuccess
  | GenerateResultLoading
  | GenerateResultError
  | GenerateResultSuccess;
