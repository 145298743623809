// ---------------------------- <START> module imports start ----------------------------------//
import React, { useEffect, useState } from "react";
import { Typography, Paper } from "@material-ui/core";
import {
  EditOutlined,
  DeleteForeverOutlined,
  FormatListBulleted,
} from "@material-ui/icons";
import { useTableStyles } from "../../Styles/TableStyles";
import { useTable } from "../../Reusable";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import ActionButton from "../../Reusable/Buttons/ActionButton";
import Popups from "../../Reusable/Dialogs/Popups";
import RoutesModal from "./RoutesModal/RoutesModal";
import { TransportTypeI } from "../../../actions/Transport/Routes/TransportActionTypes";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteTransport,
  GetTransport,
} from "../../../actions/Transport/Routes/TransportAction";
import { RootStore } from "../../../store";
import {
  ItemAddButton,
  ItemDeleteButton,
  ItemEditButton,
  ItemFormList,
} from "../../Reusable/Buttons/TableButton";
import getFullName from "../../utils/getFullName";
import BusStopModal from "./RoutesModal/BusStopModal";

// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//
interface PropsI {
  onEditData: (value: TransportTypeI) => void;
  edit: { check: boolean; count: number };
}

interface HeadCellsI {
  id: string;
  label: string;
}
// ---------------------------- <END> interface ends ----------------------------------//
// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "routes_name", label: "Route Name" },
  { id: "driver_name", label: "Driver" },
  { id: "assistant_name", label: "Assistant" },
  { id: "bus_no", label: "Bus No." },
  { id: "fare", label: "Fare" },
  { id: "action", label: "Action" },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Route List Component Starts ---------------------------//
const RoutesList = (props: PropsI) => {
  const { onEditData, edit } = props;

  const classes = useTableStyles();

  // States declaration
  const [tableData, setTableData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [routeId, setrouteId] = useState<string>("");

  const [SeatCapacity, setSeatCapacity] = useState<number | null>();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [busStopPopup, setBusStopPopup] = useState(false);

  // Retrieving re-usable components from useTable
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  // Function for handling action icons click event
  const handleAddId = (id: string, seat: number) => {
    setOpenPopup(true);
    setrouteId(id);
    setSeatCapacity(seat);
  };

  // Modal States
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string>("");

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const handleDeleteModal = (value: boolean, id: string) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteTransport(itemId));
    setDeleteModalOpen(false);
  };
  // reusable popup

  const handleClose = (value: boolean) => {
    setOpenPopup(value);
  };

  const handleEditClicked = (data: TransportTypeI) => {
    setEditMode(true);
    onEditData(data);
  };

  //============================ <START> CYCLE HOOKS <START> =======================//
  const dispatch = useDispatch();
  const transportSelector = useSelector((state: RootStore) => state.transport);

  useEffect(() => {
    dispatch(GetTransport());
  }, []);

  useEffect(() => {
    setEditMode(edit?.check);
  }, [edit]);
  console.log(transportSelector);

  useEffect(() => {
    const data = transportSelector.transport?.results;

    setLoading(transportSelector.loading);
    setTableData(data);
  }, [transportSelector]);

  const handleBusStopClose = () => {
    setBusStopPopup(false);
  };

  const getCustomTableRow = (item: any) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {item.route_from + " - " + item.route_to}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.driver_detail?.full_name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {/* {item.driver} */}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.bus_number}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.fare}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ItemFormList
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleAddId(item.id, item.seat)
            }
          />
          <ItemEditButton
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleEditClicked(item)
            }
          />

          {/* <ItemAddButton
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              setBusStopPopup(true)
            }
          /> */}
          <ItemDeleteButton
            disabled={editMode}
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleDeleteModal(true, item.id)
            }
          />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <>
      <Paper className={classes.root}>
        <Typography variant="h6">Routes List</Typography>
        <TableContainer
          loading={loading}
          items={tableData}
          headCells={headCells}
          getCustomTableRow={getCustomTableRow}
          search_name="route_from"
          rowsPerPage={10}
        />
      </Paper>
      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
        dialogTitle="Are you sure to delete this record?"
        okText="Delete"
      />
      <Popups
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        onClose={handleClose}
        // fullWidth
        maxWidth="md"
        title="Student List"
        colored
      >
        <RoutesModal SeatCapacity={SeatCapacity} routeId={routeId} />
      </Popups>

      <Popups
        openPopup={busStopPopup}
        setOpenPopup={setBusStopPopup}
        onClose={handleBusStopClose}
        maxWidth="md"
        title="Bus Stops"
        colored
      >
        <BusStopModal SeatCapacity={SeatCapacity} routeId={routeId} />
      </Popups>
    </>
  );
};
// ---------------------------- <END> Route List Component Ends -------------------------------//

export default RoutesList;
