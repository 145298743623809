import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import { SchoolOutlined } from "@material-ui/icons";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    position: "relative",
    width: "100%",
    minWidth: 180,
    overflow: "hidden",
    "& .MuiCardContent-root": {
      paddingBottom: "4px",
    },
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
    color: "rgba(0,0,0,0.5)",
    fontWeight: 600,
    textTransform: "uppercase",
  },
  pos: {},
  btn: {
    "& span": {
      textTransform: "capitalize",
      fontSize: "13px",
      fontWeight: 600,
    },
  },

  icon: {
    color: "white",
    position: "absolute",
    top: "-25px",
    right: "-25px",
    background: "#43a047",
    borderRadius: "47%",
    padding: "35px 35px 10px 15px",
    zIndex: 999,
    "& svg": {
      fontSize: "2rem",
    },
  },
});

const UserCard = (props: any) => {
  const classes = useStyles();
  const student = props.item.student ? props.item.student : 0;
  const absent = props.item.absent ? props.item.absent : 0;

  const Icon = props.item.icon;
  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          {props.item.title}
        </Typography>
        <Typography
          variant="h3"
          component="h2"
          style={{ fontWeight: 600, marginBottom: 4 }}
        >
          {student - absent}
          <span
            style={{
              color: "rgba(0,0,0,0.5)",
              fontSize: "13px",
              fontWeight: 500,
            }}
          >
            /{student}
          </span>
        </Typography>
        <Typography variant="body1" component="p">
          Absent {props.item.title}: {props.item.absent}
        </Typography>

        <div
          className={classes.icon}
          style={{ backgroundColor: props.item.color }}
        >
          <Icon />
        </div>
      </CardContent>

      <CardActions>
        <Button
          size="small"
          color="primary"
          style={{ fontSize: "bold" }}
          className={classes.btn}
          component={Link}
          to={props.item.to}
        >
          View All
        </Button>
      </CardActions>
    </Card>
  );
};

export default function UsersCard(props: any) {
  const { data } = props;
  return (
    <Grid container spacing={2}>
      <Grid item md={4}>
        <UserCard
          item={{
            icon: SchoolOutlined,
            title: "Students",
            color: "#43a047",
            absent: data?.data?.student_absent_count,
            student: data?.data?.students_count,
            to: "/student",
          }}
        />
      </Grid>

      <Grid item md={4}>
        <UserCard
          item={{
            icon: PermIdentityIcon,
            title: "Teachers",
            color: "#ff5722",
            absent: data?.data?.teacher_absent_count,
            student: data?.data?.teachers_count,
            to: "/staff",
          }}
        />
      </Grid>

      <Grid item md={4}>
        <UserCard
          item={{
            icon: PeopleOutlineIcon,
            title: "Staffs",
            color: "#3f51b5",
            absent: data?.data?.staff_absent_count,
            student: data?.data?.staffs_count,
            to: "/staff",
          }}
        />
      </Grid>
    </Grid>
  );
}
