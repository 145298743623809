// ---------------------------- <START> module imports start ----------------------------------//
import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Paper,
  Box,
  Switch,
  Checkbox,
} from "@material-ui/core";
import { Link, useHistory, useParams } from "react-router-dom";
import { useTableStyles } from "../../Styles/TableStyles";
import { useTableWithPagination } from "../../Reusable";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import {
  DeleteStaff,
  GetPaginatedStaffs,
  GetStaffs,
} from "../../../actions/HumanResource/Staff/StaffAction";
import { HeadCellsI } from "../../../actions";
import {
  ItemDeleteButton,
  ItemEditButton,
  ItemViewButton,
} from "../../Reusable/Buttons/TableButton";
import getFullName from "../../utils/getFullName";
import useLoading from "../../../useHook/useLoading";
import UserAvatar from "../../Reusable/Profile/UserAvatar";
import CustomizedSelect from "../../Reusable/Inputs/Select";
import { paginationChoices } from "../../../common/json.constant";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ClearIcon from "@material-ui/icons/Clear";
import { PostCheckinCheckout } from "../../../actions/HumanResource/Attendance/AttendanceAction";
import moment from "moment";
// ----------------------------<END> module imports end ----------------------------------//

// -------------- <START> interface starts --------------------//
interface TableDataI {
  id: string;
  staff_photo: string;
  staff_name: string;
  designation: string;
  department: string;
  phone_no: string;
}

// -------------- <END> interface ends --------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N" },
  { id: "staff_name", label: "Staff Name", filter: true },
  { id: "designation", label: "Designation" },
  { id: "department", label: "Department" },
  { id: "checkin", label: "Checkin Time" },
  { id: "status", label: "Attendance" },
  { id: "checkout", label: "Checkout Time" },
];

// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const StaffList = (props: any) => {
  const { inputDate } = props;
  const classes = useTableStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  // States declaration
  const [tableData, setTableData] = useState<TableDataI[]>([]);

  // Modal States
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);

  const [staffID, setStaffData] = useState([]);

  // Retrieving re-usable components from useTable
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTableWithPagination(headCells);

  // Staff reducer
  const staffState = useSelector((state: RootStore) => state.staff);
  const { loading } = useLoading(staffState.loading);
  const [selectedPagination, setSelectedPagination] = useState<any | null>({
    key: "10",
    value: "10",
  });

  const staffAttendanceState = useSelector(
    (state: RootStore) => state.staff_attendance?.teacher_attendance
  );

  useEffect(() => {
    if (selectedPagination != null) {
      dispatch(GetPaginatedStaffs(1, Number(selectedPagination.key)));
    }
  }, [selectedPagination]);

  useEffect(() => {
    if (staffAttendanceState.length > 0) {
      const data: any[] = [];
      staffAttendanceState.map((elem: any, index: number) => {
        data.push({
          staff_user: elem.user__id,
          staff_name: elem.user__first_name,
          designation: "-",
          department: "-",
          checkin:
            elem.staff_attendance_report &&
            elem.staff_attendance_report[0]?.data[0]?.attendance_time,
          status: "-",
          checkout:
            elem.staff_attendance_report &&
            elem.staff_attendance_report[0]?.data[1]?.attendance_time,
        });
      });
      setTableData(data);
    }
  }, [staffAttendanceState]);

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const handleDeleteModal = (id: string) => {
    setItemId(id);
    setDeleteModalOpen(true);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteStaff(itemId));
    setDeleteModalOpen(false);
  };

  const handlePaginationChange = (paginationIndex: number) => {
    dispatch(GetPaginatedStaffs(paginationIndex, selectedPagination?.key));
  };

  const handleImport = () => {
    history.push("/staff-import");
  };

  const handleCheckin = (event: any, id: string, type: string) => {
    console.log({ id });
    if (event.target.checked) {
      const finalValue = {
        attendance_type: type === "checkin" ? "CI" : "CO",
        attendance_date: inputDate,
        location: {
          isAdmin: true,
        },
        staff_user: id,
      };

      dispatch(PostCheckinCheckout(finalValue, inputDate));
    }
  };

  const getCustomTableRow = (item: any, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.staff_name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.designation}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.department}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.checkin ? (
            moment("2020-10-10T" + item.checkin).format("hh:mm:ss a")
          ) : (
            <Switch
              color="primary"
              name="checkin"
              onChange={(event) =>
                handleCheckin(event, item.staff_user, "checkin")
              }
            />
          )}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.checkin ? (
            <CheckCircleIcon style={{ color: "green" }} />
          ) : (
            <ClearIcon />
          )}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.checkout ? (
            moment("2020-10-10T" + item.checkout).format("hh:mm:ss a")
          ) : (
            <Switch
              color="primary"
              name="checkout"
              onChange={(event) =>
                handleCheckin(event, item.staff_user, "checkout")
              }
              disabled={item.checkin ? false : true}
            />
          )}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <Paper className={classes.rootTableBox}>
      <Typography variant="h6">Staff Attendance List</Typography>
      <TableContainer
        label="Staff Attendance"
        loading={loading}
        items={tableData}
        headCells={headCells}
        forPagination={staffState?.staffs}
        handlePaginationChange={handlePaginationChange}
        getCustomTableRow={getCustomTableRow}
        search_name="staff_name"
        disablePagination
      />

      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
      />
    </Paper>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default StaffList;
