import React, { useEffect, useState } from "react";
import {
  TableBody,
  TextField,
  Grid,
  Typography,
  Paper,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { Link } from "react-router-dom";
import { useTableStyles } from "../../Styles/TableStyles";
import { useTable } from "../../Reusable";
import TableActionButtons from "../../Reusable/Buttons/TableActionButtons";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import ActionButton from "../../Reusable/Buttons/ActionButton";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import { GetBusUser } from "../../../actions/Transport/BusUser/BusUserAction";
import getFullName from "../../utils/getFullName";
import { DeleteStudentFromTransport } from "../../../actions/Transport/Routes/TransportAction";
import { Remove } from "@material-ui/icons";
import { RemoveButton } from "../../Reusable/Buttons/TableButton";
import SearchRoute from "./SearchRoute";

interface TableDataI {
  id: string;
  student_name: string;
  student_photo: string;
  class: string;
  section: string;
  route: string;
  bus_no: number;
  driver_name: string;
  transportation_id: string;
}

interface PropsI {
  searchTitle: SearchDataI | null;
}

interface SearchDataI {
  grade: string;
  section: string;
}

interface HeadCellsI {
  id: string;
  label: string;
}

const headCells: HeadCellsI[] = [
  { id: "student_name", label: "Student Name" },
  { id: "class", label: "Class" },
  { id: "route", label: "Route" },
  { id: "bus_no", label: "Bus No." },
  { id: "driver_name", label: "Driver's Name" },
  // { id: "active", label: "Active" },
];

const BusUserList = (props: PropsI) => {
  const classes = useTableStyles();
  const { searchTitle } = props;

  // States declaration
  const [tableData, setTableData] = useState<TableDataI[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  // Retrieving re-usable components from useTable
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  // Function for handling action icons click event

  const handleStudentId = (transport_id: string, id: string) => {
    dispatch(DeleteStudentFromTransport(transport_id, id));
  };

  //============================ <START> CYCLE HOOKS <START> =======================//
  const dispatch = useDispatch();
  const busUserListSelector = useSelector((state: RootStore) => state.bus_user);

  useEffect(() => {
    dispatch(GetBusUser());
  }, []);

  useEffect(() => {
    const data: any = [];
    if (busUserListSelector.bus_user != null) {
      busUserListSelector.bus_user.map((elem: any, index: number) => {
        data.push({
          key: elem.id,
          id: elem.student_id,
          class: elem?.student?.grade?.grade_name,
          section: elem?.student?.section?.name,
          student_name: getFullName(
            elem?.student?.student?.student_user?.first_name,
            elem?.student?.student?.student_user?.last_name
          ),
          route:
            elem?.transport_info?.route_from +
            " - " +
            elem?.transport_info?.route_to,
          bus_no: elem?.transport_info?.bus_number,
          driver_name: elem?.transport_info?.driver_detail?.full_name,
          // transportation_id: elem?.transportation_detail__id,
        });
      });
    }
    setLoading(busUserListSelector.loading);
    setTableData(data);
  }, [busUserListSelector]);

  const getCustomTableRow = (item: TableDataI) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {item.student_name}
        </StyledTableCell>

        <StyledTableCell align="center" className={classes.cell}>
          {item.class} {item.section ? `(${item.section})` : ""}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.route}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.bus_no}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.driver_name}
        </StyledTableCell>
        {/* <StyledTableCell align="center" className={classes.cell}>
          <RemoveButton
            onClick={() => handleStudentId(item.transportation_id, item.id)}
          />
        </StyledTableCell> */}
      </StyledTableRow>
    );
  };

  return (
    <>
      <Paper className={classes.rootTableBox}>
        <Typography variant="h6">
          {searchTitle?.grade
            ? `Bus User Students For Class ${searchTitle.grade} ${searchTitle.section}`
            : "Bus User Students"}
        </Typography>
        <TableContainer
          loading={loading}
          items={tableData}
          headCells={headCells}
          getCustomTableRow={getCustomTableRow}
          search_name="student_name"
        // rowsPerPage={7}
        />
      </Paper>
    </>
  );
};

export default BusUserList;
