//---------------------------- <START> module imports start ----------------------------------//
import React, { useEffect, useState } from 'react';
import { Grid, Typography, Paper, Button, Tooltip } from '@material-ui/core';
import DashImg from '../../../../assets/images/ParentDash.jpg';
import FormButton from '../../../Reusable/Buttons/FormButton';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { RootStore } from '../../../../store';
import {
  GetIdCardTemplate,
  ActivateOrDeactivateIdCardTemplate,
  DeleteIdCardTemplate,
} from '../../../../actions/Templates/IdCard/IdCardTemplateAction';
import DeleteForeverOutlined from '@material-ui/icons/DeleteForeverOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import ConfirmDialog from '../../../Reusable/Dialogs/ConfirmDialog';
import {
  ActivateOrDeactivateMarksheetTemplate,
  DeleteMarksheetTemplate,
  GetMarksheetTemplate,
} from '../../../../actions/Templates/Marksheet/MarksheetTemplateAction';
import checkImageUrl from '../../../utils/checkImageUrl';
// ----------------------------<END> module imports end ----------------------------------//

const style = makeStyles({
  btnRight: {
    color: 'white',
    top: '50%',
    float: 'right',
    position: 'relative',
    transform: 'translateY(-50%)',
  },
  title: {
    fontSize: '20px',
  },
  card: {
    // maxWidth: 345,
    padding: 7,
    border: '1px solid #c5d8dd',
    '&:hover': {
      border: '1px solid blue',
      transform: 'scale(1.009)',
    },
  },
  smallbtn: {
    backgroundColor: '#a7a4a444',
    marginLeft: '5px',
    marginRight: '5px',
    '&:hover': {
      backgroundColor: '#64A1C2',
      color: 'white',
    },
  },
  smallbtnActive: {
    backgroundColor: 'blue',
    marginLeft: '5px',
    marginRight: '5px',
    color: 'white',
    '&:hover': {
      backgroundColor: '#64A1C2',
      color: 'white',
    },
  },
  ImgContainer: {
    position: 'relative',
    '&:hover > div:hover': {
      cursor: 'pointer',
      // "&.ImgActions": {
      // "&:hover": {
      opacity: '0.8',
      // },
      // },
    },
  },
  ImgActions: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: '100%',
    width: '100%',
    opacity: 0,
    transition: '.5s ease',
    backgroundColor: '#4a76b4',
  },
  actions: {
    color: 'white',
    fontSize: '20px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
  deleteicon: {
    border: '2px solid #ff2200',
    borderRadius: '6px',
    padding: '2px',
  },
  editicon: {
    border: '2px solid #0436ff',
    borderRadius: '6px',
    padding: '2px',
  },
  activeicon: {
    border: '2px solid #00ffdd',
    borderRadius: '6px',
    padding: '2px',
  },
  tooltip: {
    marginRight: '5px',
    marginLeft: '5px',
  },
});

const MyMarksheet: React.FC = () => {
  const [isThere, setIsThere] = useState(true);
  const [landscape, setLandscape] = useState(false);
  const [landscapeTemplateData, setLandscapeTemplateData] = useState<any>([]);
  const [potraitTemplateData, setPotraitTemplateData] = useState<any>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [cardID, setCardID] = useState<string | null>(null);
  const [editMode, setEditMode] = useState(false);
  const [myTemplates, setMyTemplates] = useState([]);
  const [mode, setMode] = useState('potrait');

  const classes = style();
  const history = useHistory();
  const dispatch = useDispatch();

  const my_templates = useSelector(
    (state: RootStore) => state.marksheet_templates
  );

  useEffect(() => {
    dispatch(GetMarksheetTemplate());
  }, []);

  useEffect(() => {
    if (my_templates != null) {
      my_templates.marksheet_templates.length > 0
        ? setMyTemplates(my_templates.marksheet_templates)
        : setIsThere(false);
    }
  }, [my_templates]);

  const handlePotraitClicked = () => {
    setLandscape(false);
    setMode('potrait');
  };

  const handleLandscapeClick = () => {
    setLandscape(true);
    setMode('landscape');
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    cardID != null && dispatch(DeleteMarksheetTemplate(cardID));
    setOpen(false);
  };

  const handleEdit = (id: string) => {
    id != null && setCardID(id);
    setEditMode(true);
  };

  const handleDeleteConfirm = (id: string) => {
    if (id != null) {
      setCardID(id);
      setOpen(true);
    }
  };

  const handleActivate = (id: string, active: boolean) => {
    if (!active) {
      id != null && dispatch(ActivateOrDeactivateMarksheetTemplate(id));
    }
  };

  if (editMode && cardID != null) {
    history.push(`/marksheet/${cardID}/update`);
  }

  return (
    <>
      <Paper style={{ margin: '30px', padding: '20px' }}>
        {!myTemplates.length ? (
          <>
            <div style={{ textAlign: 'center' }}>
              <img src={DashImg} style={{ height: '320px' }} />
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    Opps! You've not created any Marksheet templates yet.
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <Grid container justifyContent="center">
              <Link
                to="/browse-marksheet-templates"
                style={{ textDecoration: 'none' }}
              >
                <FormButton style={{ minWidth: '150px', marginTop: '16px' }}>
                  Start Creating
                </FormButton>
              </Link>
            </Grid>
          </>
        ) : (
          <>
            <Grid container spacing={3} justifyContent="space-between">
              <Grid item xs={6} sm={3}>
                <span className={classes.title}>Your Result Templates</span>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Link
                  to="/browse-marksheet-templates"
                  style={{ textDecoration: 'none' }}
                >
                  <FormButton className={classes.btnRight}>Add New</FormButton>
                </Link>
              </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: '10px' }}>
              {myTemplates.map((item: any, index: number) => {
                return (
                  <Grid item xs={6} key={index}>
                    <div className={classes.ImgContainer}>
                      <Card
                        className={classes.card}
                        style={{
                          border: item.active ? '3px solid #5ECD68' : '',
                          padding: '4px',
                        }}
                      >
                        <CardActionArea>
                          <img
                            src={checkImageUrl(item.thumbnail)}
                            style={{ height: '300px', width: '100%' }}
                          />
                        </CardActionArea>
                      </Card>
                      <div className={classes.ImgActions}>
                        <div className={classes.actions}>
                          <Tooltip
                            title="Activate"
                            className={classes.tooltip}
                            onClick={() => handleActivate(item.id, item.active)}
                          >
                            <ExitToAppOutlinedIcon
                              className={classes.activeicon}
                              style={{ color: 'white' }}
                            />
                          </Tooltip>
                          <Tooltip
                            title="Edit"
                            className={classes.tooltip}
                            onClick={() => handleEdit(item.id)}
                          >
                            <EditOutlinedIcon
                              className={classes.editicon}
                              style={{ color: 'white' }}
                            />
                          </Tooltip>
                          <Tooltip
                            title="Remove"
                            className={classes.tooltip}
                            onClick={() => handleDeleteConfirm(item.id)}
                          >
                            <DeleteForeverOutlined
                              className={classes.deleteicon}
                              style={{ color: 'white' }}
                            />
                          </Tooltip>
                          <br />
                          {item.active && (
                            <b
                              style={{
                                fontSize: '12px',
                                color: 'white',
                                textDecoration: 'underline',
                                textUnderlinePosition: 'under',
                              }}
                            >
                              This is currently active.
                            </b>
                          )}
                        </div>
                      </div>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </>
        )}
      </Paper>
      <ConfirmDialog
        actionConfirmed={handleDelete}
        onClose={onClose}
        open={open}
        title="Are you sure want to delete this template?"
        description="It will get removed from your template list but you can always add again to your template page."
        cancelText="CANCEL"
        agreeText="DELETE"
      />
    </>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//
export default MyMarksheet;
