import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import APEform from "../../components/Result/APE/APEform";
import APETable from "../../components/Result/APE/APEtable";
import { ResultSidebar } from "../../components";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const APEpage = () => {
  const [formData, setFormData] = useState<any | null>(null);
  const [count, setCount] = useState<number>(0);

  const fetchEditData = (data: any) => {
    setFormData({ ...data, count: count });
  };
  const nav = <ResultSidebar ape />;
  return (
    <SubModuleLayout HumanMenuActive sideNav={nav}>
      <Grid container>
        <Grid item xs={3}>
          <APEform editData={formData} />
        </Grid>
        <Grid item xs={9}>
          <APETable onEditData={fetchEditData} />
        </Grid>
      </Grid>
    </SubModuleLayout>
  );
};

export default APEpage;
