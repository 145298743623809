// ---------------------------- <START> module imports start ----------------------------------//
import React, { useEffect, useState } from "react";
import { Box, Grid, List, ListItem, ListItemText, Tooltip, Typography } from "@material-ui/core";
import { useTableStyles } from "../../../../Styles/TableStyles";
import { useTable } from "../../../../Reusable";
import { StudentRouteTypeI } from "../../../../../actions/Transport/Routes/RoutesModal/StudentRouteActionTypes";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../../../store";
import {
  AddStudentRoute,
  DeleteStudenttoRoute,
  GetStudentRoute,
} from "../../../../../actions/Transport/Routes/RoutesModal/StudentRouteAction";
import getFullName from "../../../../utils/getFullName";
import { ItemEditButton, RemoveButton } from "../../../../Reusable/Buttons/TableButton";
import getFullClassName from "../../../../utils/getFullClassName";
import { MONTH_CHOICES } from "../../../../../common/json.constant";
import { Tupple } from "../../../../../actions";
import TransportMonthModal from "./TransportMonthModal";

// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//

interface HeadCellsI {
  id: string;
  label: string;
}
// ---------------------------- <END> interface ends ----------------------------------//

// -----------------<start> table dummy data ends -----------------------//
// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "student_user__first_name", label: "Student Name" },
  { id: "academic_info__grade__name", label: "Class" },
  { id: "months", label: "Months" },
  { id: "action", label: "Action" },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Route List Component Starts ---------------------------//
const StudentList = (props: any) => {
  const classes = useTableStyles();
  const { onTableData } = props;

  // States declaration
  const [tableData, setTableData] = useState<StudentRouteTypeI[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [confirmDialog, setConfirmDialog] = useState<boolean>(false);
  const [appliedTransport, setAppliedTransport] = useState<Array<string>>([])
  const [studentID, setStudentID] = useState<string>("");
  const [paginationIndex, setPaginationIndex] = useState<number>(1);

  // Retrieving re-usable components from useTable
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  const dispatch = useDispatch();
  const studentRouteSelector = useSelector(
    (state: RootStore) => state.student_route
  );

  useEffect(() => {
    dispatch(GetStudentRoute(props.routeId, "", "", 1));
  }, []);

  useEffect(() => {
    const data = studentRouteSelector.student_route;

    setLoading(studentRouteSelector.loading);
    setTableData(data);
    onTableData(data);
  }, [studentRouteSelector]);

  const handleTransportStudentRemove = (id: string) => {
    dispatch(DeleteStudenttoRoute(props.routeId, id));
  };

  const handleTransportStudentUpdate = (id: string, transport: Array<string>) => {
    setStudentID(id)
    setAppliedTransport(transport)
    setConfirmDialog(true)
    // dispatch(AddStudentRoute("", null, 1, "", ""));
  };

  const handleDialogClose = (value: boolean) => {
    setConfirmDialog(value);
  };

  const onConfirmed = (confirmed: boolean) => {
    console.log('abc')
  };

  const ToolTipSubjectViewComponent = (props: any) => {
    const { items } = props;
    return (
      <Grid direction="column" container>
        <Grid>
          <div>
            <List dense>
              {items.map((item: any, index: number) => {
                const single = MONTH_CHOICES.find((mKey: Tupple) => mKey.key === item)
                return (
                  <ListItem key={index}>
                    <ListItemText
                      primary={
                        <Typography variant="subtitle2">{single?.value}</Typography>
                      }
                    />
                  </ListItem>
                )
              })}
            </List>
          </div>
        </Grid>
      </Grid>
    );
  };

  const getCustomTableRow = (item: any) => {
    return (
      <StyledTableRow key={item.student_id}>
        <StyledTableCell align="center" className={classes.cell}>
          {getFullName(
            item.student?.student?.student_user?.first_name,
            item.student?.student?.student_user?.last_name
          )}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {getFullClassName(
            item.student?.grade?.name,
            item.student?.section?.name
          )}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <Tooltip
            arrow
            title={
              item.applied_months?.length ? (
                <ToolTipSubjectViewComponent items={item.applied_months} />
              ) : (
                ''
              )
            }
          >
            <div>
              {`${item.applied_months && item.applied_months[0] ? MONTH_CHOICES.find((mKey: Tupple) => mKey.key === item.applied_months[0])?.value : 'N/A'} ${item.applied_months?.length ? `+${item.applied_months?.length - 1} more` : ''
                }`}
            </div>
          </Tooltip>

        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ItemEditButton onClick={() => handleTransportStudentUpdate(item.student?.id, item.applied_months)} />
          <RemoveButton onClick={() => handleTransportStudentRemove(item.student_id)} />
        </StyledTableCell>
      </StyledTableRow>
    );
  }

  return (
    <>
      <Box
        className={classes.rootTableBox}
        style={{ margin: 0, padding: "0px 0px 16px 0" }}
      >
        <TableContainer
          loading={loading}
          items={tableData}
          headCells={headCells}
          getCustomTableRow={getCustomTableRow}
          search_name="student_user__first_name"
          rowsPerPage={7}
        />
        <TransportMonthModal
          open={confirmDialog}
          onClose={handleDialogClose}
          deleteConfirmed={onConfirmed}
          transport={props.routeId}
          student={studentID}
          appliedTransport={appliedTransport}
          paginationIndex={1}
          grade=""
          section=""
        />
      </Box>
    </>
  );
};
// ---------------------------- <END> Route List Component Ends -------------------------------//

export default StudentList;
