import React, { useEffect, useRef, useState } from 'react';
import { useFormStyles } from '../../Styles/FormStyles';
import { VehicleTypeI } from '../../../actions/Transport/Vehicles/VehiclesActionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import { useForm } from 'react-hook-form';
import FormLayout from '../../Reusable/Layouts/Form.Layout';
import CustomizedTextField from '../../Reusable/Inputs/TextField';
import { Alert } from '@material-ui/lab';
import firstWordCapital from '../../utils/firstWordCapital';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  AddVehicle,
  GetVehicle,
  UpdateVehicle,
} from '../../../actions/Transport/Vehicles/VehiclesAction';
import { InputLabel } from '@material-ui/core';

interface PropsI {
  editData: VehicleTypeI | null;
  onEditCheck: (value: boolean) => void;
}

interface FormDataI {
  id: string;
  vehicle_no: string;
  seat_capacity: number;
  vehicle_brand: string;
  vehicle_model: string;
  manufactured_year: number;
  max_speed: number;
}

const VehiclesForm = (props: PropsI) => {
  const dispatch = useDispatch();
  const classes = useFormStyles();

  const { editData, onEditCheck } = props;

  const [editID, setEditID] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [manufacturedYear, setManufacturedYear] = useState<Date | null>(null);

  const vehicleState = useSelector((state: RootStore) => state.vehicle);
  const { add_or_update, loading } = vehicleState;

  // const add_or_update = useSelector(
  //   (state: RootStore) => state.vehicle.add_or_update
  // );

  // const { loading } = useSelector((state: RootStore) => state.vehicle);

  useEffect(() => {
    if (add_or_update) {
      setIsBtnLoading(false);
      handleReset();
    }
  }, [add_or_update]);

  useEffect(() => {
    editData != null && handleEditTableData(editData);
  }, [editData]);

  const {
    register,
    handleSubmit,
    errors,
    setError,
    clearErrors,
    setValue,
    reset,
  } = useForm({
    mode: 'onChange',
  });

  const onFormSubmit = (data: FormDataI) => {
    setIsBtnLoading(true);
    const finalValues: FormDataI = {
      ...data,
      manufactured_year:
        manufacturedYear === null ? 2022 : manufacturedYear?.getFullYear(),
    };
    if (editMode) {
      editID != null && dispatch(UpdateVehicle(editID, finalValues));
    } else {
      dispatch(AddVehicle(finalValues));
    }
    dispatch(GetVehicle());
  };

  const handleEditTableData = (data: VehicleTypeI) => {
    setEditMode(true);
    setEditID(data.id);
    setValue('vehicle_no', data.vehicle_no);
    setValue('vehicle_brand', data.vehicle_brand);
    setValue('vehicle_model', data.vehicle_model);
    setManufacturedYear(new Date(data.manufactured_year));
    setValue('max_speed', data.max_speed);
    setValue('seat_capacity', data.seat_capacity);
  };

  const handleReset = () => {
    reset();
    setEditMode(false);
    setEditID('');
    onEditCheck(false);
  };

  const [serverErrors, setServerErrors] = useState<any>(null);
  const transportSelector = useSelector((state: RootStore) => state.transport);
  const errorsData = transportSelector.error;
  const initialErrorsData = useRef(errorsData);

  useEffect(() => {
    if (initialErrorsData.current === errorsData) {
      initialErrorsData.current = errorsData; // Do not set initial errors
    } else {
      if (errorsData?.error != null) {
        const keys = Object.keys(errorsData?.error);
        keys.map((elem: any) => {
          setError(elem, {
            type: 'serverSideError',
            message: errorsData.error[elem] && errorsData.error[elem][0],
          });
        });
      }
      setServerErrors(errorsData);
    }
  }, [errorsData]);

  useEffect(() => {
    if (errorsData == null) {
      setServerErrors(null);
    } else if (serverErrors?.error) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [errorsData]);

  useEffect(() => {
    if (transportSelector.recent) {
      handleReset();
    }
  }, [transportSelector]);

  return (
    <>
      <FormLayout
        title="Add Bus"
        onSubmit={handleSubmit(onFormSubmit)}
        editMode={editMode}
        loading={loading}
        onClick={!isBtnLoading && handleReset}
        add_or_update={isBtnLoading}
      >
        {serverErrors &&
          serverErrors?.error &&
          Object.keys(serverErrors.error)
            .filter((elem) => elem === 'error')
            .map((elem: any, index: number) => {
              return (
                <div className={classes.serversidemessages} key={index}>
                  <Alert severity="error">
                    {firstWordCapital(serverErrors?.error[elem][0])}
                  </Alert>
                </div>
              );
            })}
        <CustomizedTextField
          label="Bus Number"
          placeholder="Bus Number"
          name="vehicle_no"
          type="number"
          error={errors['vehicle_no'] ? 'Required Field.' : ''}
          inputRef={register({ required: true })}
        />
        <span className={classes.validationErrorInfo}>
          {errors.vehicle_no?.type === 'serverSideError' &&
            errors.vehicle_no.message}
        </span>
        <CustomizedTextField
          label="Brand"
          placeholder="Bus Brand"
          name="vehicle_brand"
          error={errors['vehicle_brand'] ? 'Required Field.' : ''}
          inputRef={register({ required: true })}
        />
        <span className={classes.validationErrorInfo}>
          {errors.vehicle_brand?.type === 'serverSideError' &&
            errors.vehicle_brand.message}
        </span>
        <CustomizedTextField
          label="Model"
          placeholder="Bus Model"
          name="vehicle_model"
          error={errors['vehicle_model'] ? 'Required Field.' : ''}
          inputRef={register({ required: true })}
        />
        <span className={classes.validationErrorInfo}>
          {errors.vehicle_model?.type === 'serverSideError' &&
            errors.vehicle_model.message}
        </span>

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            views={['year']}
            label="Manufactured Year"
            name="manufactured_year"
            value={manufacturedYear}
            style={{ display: 'block', marginBottom: '10px' }}
            onChange={(newValue) => {
              setManufacturedYear(newValue);
            }}
          />
        </MuiPickersUtilsProvider>
        <span className={classes.validationErrorInfo}>
          {/* {errorsData?.error?.to_date?.error} */}
          {errors.manufactured_year?.type === 'serverSideError' &&
            errors.manufactured_year.message}
        </span>
        <CustomizedTextField
          label="Max Speed (km/hr)"
          placeholder="Maximum Speed"
          name="max_speed"
          type="number"
          error={errors['max_speed'] ? 'Required Field.' : ''}
          inputRef={register({ required: true })}
        />
        <span className={classes.validationErrorInfo}>
          {errors.max_speed?.type === 'serverSideError' &&
            errors.max_speed.message}
        </span>
        <CustomizedTextField
          label="Seat Capacity"
          placeholder="Capacity"
          name="seat_capacity"
          type="number"
          error={errors['seat_capacity'] ? 'Required Field.' : ''}
          inputRef={register({ required: true })}
        />
        <span className={classes.validationErrorInfo}>
          {errors.seat_capacity?.type === 'serverSideError' &&
            errors.seat_capacity.message}
        </span>
      </FormLayout>
    </>
  );
};

export default VehiclesForm;
