export const GET_ANNOUNCEMENT_TYPE_LOADING = "GET_ANNOUNCEMENT_TYPE_LOADING";
export const GET_ANNOUNCEMENT_TYPE_ERROR = "GET_ANNOUNCEMENT_TYPE_ERROR";
export const GET_ANNOUNCEMENT_TYPE_SUCCESS = "GET_ANNOUNCEMENT_TYPE_SUCCESS";

export const ADD_ANNOUNCEMENT_TYPE_LOADING = "ADD_ANNOUNCEMENT_TYPE_LOADING";
export const ADD_ANNOUNCEMENT_TYPE_ERROR = "ADD_ANNOUNCEMENT_TYPE_ERROR";
export const ADD_ANNOUNCEMENT_TYPE_SUCCESS = "ADD_ANNOUNCEMENT_TYPE_SUCCESS";

export const UPDATE_ANNOUNCEMENT_TYPE_LOADING = "UPDATE_ANNOUNCEMENT_TYPE_LOADING";
export const UPDATE_ANNOUNCEMENT_TYPE_ERROR = "UPDATE_ANNOUNCEMENT_TYPE_ERROR";
export const UPDATE_ANNOUNCEMENT_TYPE_SUCCESS = "UPDATE_ANNOUNCEMENT_TYPE_SUCCESS";

export const DELETE_ANNOUNCEMENT_TYPE_LOADING = "DELETE_ANNOUNCEMENT_TYPE_LOADING";
export const DELETE_ANNOUNCEMENT_TYPE_ERROR = "DELETE_ANNOUNCEMENT_TYPE_ERROR";
export const DELETE_ANNOUNCEMENT_TYPE_SUCCESS = "DELETE_ANNOUNCEMENT_TYPE_SUCCESS";

export type AnnouncementTypeI = {
    id: string;
    name: string
};
//===============================<START>GET ANNOUNCEMENT <START>======================//

export interface GetAnnouncementTypeLoading {
    type: typeof GET_ANNOUNCEMENT_TYPE_LOADING;
}

export interface GetAnnouncementTypeError {
    type: typeof GET_ANNOUNCEMENT_TYPE_ERROR;
}

export interface GetAnnouncementTypeSuccess {
    type: typeof GET_ANNOUNCEMENT_TYPE_SUCCESS;
    payload: AnnouncementTypeI[];
}

//===============================<END>GET ANNOUNCEMENT <END>======================//

//===============================<START>ADD ANNOUNCEMENT <START>======================//

export interface AddAnnouncementTypeLoading {
    type: typeof ADD_ANNOUNCEMENT_TYPE_LOADING;
}

export interface AddAnnouncementTypeError {
    type: typeof ADD_ANNOUNCEMENT_TYPE_ERROR;
}

export interface AddAnnouncementTypeSuccess {
    type: typeof ADD_ANNOUNCEMENT_TYPE_SUCCESS;
    payload: AnnouncementTypeI;
}

//===============================<END>ADD ANNOUNCEMENT <END>======================//

//===============================<START>UPDATE ANNOUNCEMENT <START>======================//

export interface UpdateAnnouncementTypeLoading {
    type: typeof UPDATE_ANNOUNCEMENT_TYPE_LOADING;
}

export interface UpdateAnnouncementTypeError {
    type: typeof UPDATE_ANNOUNCEMENT_TYPE_ERROR;
}

export interface UpdateAnnouncementTypeSuccess {
    type: typeof UPDATE_ANNOUNCEMENT_TYPE_SUCCESS;
    payload: AnnouncementTypeI;
}

//===============================<END>UPDATE ANNOUNCEMENT <END>======================//

//===============================<START>DELETE ANNOUNCEMENT <START>======================//

export interface DeleteAnnouncementTypeLoading {
    type: typeof DELETE_ANNOUNCEMENT_TYPE_LOADING;
}

export interface DeleteAnnouncementTypeError {
    type: typeof DELETE_ANNOUNCEMENT_TYPE_ERROR;
}

export interface DeleteAnnouncementTypeSuccess {
    type: typeof DELETE_ANNOUNCEMENT_TYPE_SUCCESS;
    payload: { id: string };
}

//===============================<END>DELETE ANNOUNCEMENT <END>======================//

export type AnnouncementTypeDispatchTypes =
    | GetAnnouncementTypeLoading
    | GetAnnouncementTypeError
    | GetAnnouncementTypeSuccess
    | AddAnnouncementTypeLoading
    | AddAnnouncementTypeError
    | AddAnnouncementTypeSuccess
    | UpdateAnnouncementTypeLoading
    | UpdateAnnouncementTypeError
    | UpdateAnnouncementTypeSuccess
    | DeleteAnnouncementTypeLoading
    | DeleteAnnouncementTypeError
    | DeleteAnnouncementTypeSuccess;
