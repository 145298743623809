import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  FeeStudentPayloadI,
  FeeStudentsI,
  StudentFeesI,
  StudentFeesPayloadI,
} from "./feeDiscount";
import {
  applyFeeStudentDiscount,
  applyStudentFeesDiscount,
  getFeeStudents,
  getStudentFees,
} from "./feeDiscountApi";

interface InitialStateI {
  loading: boolean;
  actionPerformed: boolean;
  feeStudents: FeeStudentsI[];
  studentFees: StudentFeesI[];
  searchParams: {
    fee_type: string | null;
    month: string | null;
    grade: string | null;
    section: string | null;
    category: string | null;
    student: string | null;
  } | null;
}

const initialState: InitialStateI = {
  loading: false,
  actionPerformed: false,
  feeStudents: [],
  studentFees: [],
  searchParams: null,
};

const feeDiscountSlice = createSlice({
  name: "feeDiscount",
  initialState,
  reducers: {
    changeFeeStudentData: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: string;
        field: "percent" | "amount" | "remarks";
        value: string;
      }>
    ) => {
      const data = state.feeStudents.find((el) => el.id === payload.id);
      const re = /^[0-9]*\.?[0-9]*$/;
      if (data) {
        if (payload.field === "remarks") {
          data.remarks = payload.value as string;
        }
        if (payload.field === "percent") {
          if (payload.value === "" || re.test(payload.value)) {
            data.discount_per = payload.value;
            data.fee_amount_final = String(
              Number(data.fee_amount) -
                Number(Number(payload.value) / 100) * Number(data.fee_amount)
            );
            data.discount_amt = String(
              (Number(payload.value) / 100) * Number(data.fee_amount) || ""
            );
          }
        }
        if (payload.field === "amount") {
          if (payload.value === "" || re.test(payload.value)) {
            data.discount_amt = payload.value;
            data.fee_amount_final = String(
              Number(data.fee_amount) - Number(payload.value)
            );
            data.discount_per = String(
              100 -
                ((Number(data.fee_amount) - Number(payload.value)) /
                  Number(data.fee_amount)) *
                  100 || ""
            );
          }
        }

        if (Number(data.fee_amount) < Number(data.discount_amt)) {
          data.error = true;
        } else {
          data.error = false;
        }
      }
    },
    changeStudentFeesData: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: string;
        field: "percent" | "amount" | "remarks";
        value: string;
      }>
    ) => {
      const data = state.studentFees.find((el) => el.id === payload.id);
      const re = /^[0-9]*\.?[0-9]*$/;
      if (data) {
        if (payload.field === "remarks") {
          data.remarks = payload.value as string;
        }
        if (payload.field === "percent") {
          if (payload.value === "" || re.test(payload.value)) {
            data.discount_per = payload.value;
            data.fee_amount_final = String(
              Number(data.fee_amount) -
                Number(Number(payload.value) / 100) * Number(data.fee_amount)
            );
            data.discount_amt = String(
              (Number(payload.value) / 100) * Number(data.fee_amount) || ""
            );
          }
        }
        if (payload.field === "amount") {
          if (payload.value === "" || re.test(payload.value)) {
            data.discount_amt = payload.value;
            data.fee_amount_final = String(
              Number(data.fee_amount) - Number(payload.value)
            );
            data.discount_per = String(
              100 -
                ((Number(data.fee_amount) - Number(payload.value)) /
                  Number(data.fee_amount)) *
                  100 || ""
            );
          }
        }

        if (Number(data.fee_amount) < Number(data.discount_amt)) {
          data.error = true;
        } else {
          data.error = false;
        }
      }
    },
    setSearchParams: (
      state,
      {
        payload,
      }: PayloadAction<{
        fee_type: string | null;
        month: string | null;
        grade: string | null;
        section: string | null;
        category: string | null;
        student: string | null;
      }>
    ) => {
      state.searchParams = {
        ...payload,
      };
    },
    resetState: (state) => {
      state.actionPerformed = false;
      state.loading = false;
      state.studentFees = [];
      state.feeStudents = [];
      state.searchParams = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFeeStudents.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(
      getFeeStudents.fulfilled,
      (state, { payload }: PayloadAction<FeeStudentPayloadI>) => {
        state.loading = false;
        const data: FeeStudentsI[] = [];
        payload.students.forEach((el) => {
          const fee_amount = Number(
            payload.fee_detail.grade_fee_amount?.amount
          );
          const fee_discount = Number(el.student_invoice_discount?.amount || 0);
          const fee_discount_per = (fee_discount / fee_amount) * 100;
          data.push({
            id: el.id,
            admission_no: el.student.student_user.username,
            student_name:
              el.student.student_user.first_name +
              " " +
              el.student.student_user.last_name,
            student_category: el.student.student_category?.name || "--",
            fee_id: payload.fee_detail.id,
            fee_amount: String(fee_amount),
            discount_amt: String(fee_discount || ""),
            discount_per: String(fee_discount_per || ""),
            fee_amount_final: String(fee_amount - fee_discount),
            remarks: el.student_invoice_discount?.remarks || "",
            error: false,
          });
        });
        state.feeStudents = [...data];
      }
    );
    builder.addCase(getFeeStudents.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(applyFeeStudentDiscount.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(applyFeeStudentDiscount.fulfilled, (state) => {
      state.loading = false;
      state.actionPerformed = true;
    });
    builder.addCase(applyFeeStudentDiscount.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getStudentFees.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(
      getStudentFees.fulfilled,
      (state, { payload }: PayloadAction<StudentFeesPayloadI[]>) => {
        state.loading = false;
        const data: StudentFeesI[] = [];

        payload.forEach((el, index) => {
          const fee_amount = Number(el.fee_type.grade_fee_amount.amount);
          const fee_discount = Number(el.student_invoice_discount?.amount || 0);
          const fee_discount_per = (fee_discount / fee_amount) * 100;
          data.push({
            id: String(index + 1),
            student_id: el.student,
            fee_head_id: el.fee_type.id,
            fee_head: el.fee_type.name,
            month: el.month,
            fee_amount: String(fee_amount),
            discount_per: String(fee_discount_per || ""),
            discount_amt: String(fee_discount || ""),
            fee_amount_final: String(fee_amount - fee_discount),
            remarks: el.student_invoice_discount?.remarks || "",
            error: false,
          });
        });
        state.studentFees = [...data];
      }
    );
    builder.addCase(getStudentFees.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(applyStudentFeesDiscount.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(applyStudentFeesDiscount.fulfilled, (state) => {
      state.loading = false;
      state.actionPerformed = true;
    });
    builder.addCase(applyStudentFeesDiscount.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  changeFeeStudentData,
  changeStudentFeesData,
  setSearchParams: setSearchParamsAction,
  resetState: resetStateAction,
} = feeDiscountSlice.actions;

export default feeDiscountSlice.reducer;
