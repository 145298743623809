import React, { useState } from "react";
import { ReportSideBar } from "../../components";
import AdmitCardList from "../../components/Reports/AdmitCard/AdmitCardList";
import AdmitCardSearch from "../../components/Reports/AdmitCard/AdmitCardSearch";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const AdmitCardPage = () => {
  const nav = <ReportSideBar admit_card />;
  const [exam, setExam] = useState();

  const passExamInfo = (value: any) => {
    setExam(value);
  };
  return (
    <SubModuleLayout reportMenuActive sideNav={nav}>
      <AdmitCardSearch passExamInfo={passExamInfo} />
      <AdmitCardList examType={exam} />
    </SubModuleLayout>
  );
};
export default AdmitCardPage;
