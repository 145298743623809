export const GET_MEETING_LOADING = 'GET_MEETING_LOADING';
export const GET_MEETING_ERROR = 'GET_MEETING_ERROR';
export const GET_MEETING_SUCCESS = 'GET_MEETING_SUCCESS';

export const ADD_MEETING_LOADING = 'ADD_MEETING_LOADING';
export const ADD_MEETING_ERROR = 'ADD_MEETING_ERROR';
export const ADD_MEETING_SUCCESS = 'ADD_MEETING_SUCCESS';

export const UPDATE_MEETING_LOADING = 'UPDATE_MEETING_LOADING';
export const UPDATE_MEETING_ERROR = 'UPDATE_MEETING_ERROR';
export const UPDATE_MEETING_SUCCESS = 'UPDATE_MEETING_SUCCESS';

export const APPROVE_OR_DECLINE_LOADING = 'APPROVE_OR_DECLINE_LOADING';
export const APPROVE_OR_DECLINE_SUCCESS = 'APPROVE_OR_DECLINE_SUCCESS';
export const APPROVE_OR_DECLINE_ERROR = 'APPROVE_OR_DECLINE_ERROR';

export const DELETE_MEETING_LOADING = 'DELETE_MEETING_LOADING';
export const DELETE_MEETING_ERROR = 'DELETE_MEETING_ERROR';
export const DELETE_MEETING_SUCCESS = 'DELETE_MEETING_SUCCESS';

export const GET_TODAY_MEETING_LOADING = 'GET_TODAY_MEETING_LOADING';
export const GET_TODAY_MEETING_ERROR = 'GET_TODAY_MEETING_ERROR';
export const GET_TODAY_MEETING_SUCCESS = 'GET_TODAY_MEETING_SUCCESS';

export type MeetingTypeI = {
  id: string;
  name: string;
  meeting_type: any;
  created_on: string;
  meet_date: string;
  description: string;
  status: string;
};

export type ApproveOrDeclineType = {
  id: string;
  status: string;
};

//===============================<START>GET MEETING<START>======================//

export interface GetMeetingLoading {
  type: typeof GET_MEETING_LOADING;
}

export interface GetMeetingError {
  type: typeof GET_MEETING_ERROR;
}

export interface GetMeetingSuccess {
  type: typeof GET_MEETING_SUCCESS;
  payload: MeetingTypeI[];
}

export interface GetTodayMeetingLoading {
  type: typeof GET_TODAY_MEETING_LOADING;
}

export interface GetTodayMeetingError {
  type: typeof GET_TODAY_MEETING_ERROR;
}

export interface GetTodayMeetingSuccess {
  type: typeof GET_TODAY_MEETING_SUCCESS;
  payload: any;
}

//===============================<END>GET MEETING<END>======================//

//===============================<START>ADD MEETING<START>======================//

export interface AddMeetingLoading {
  type: typeof ADD_MEETING_LOADING;
}

export interface AddMeetingError {
  type: typeof ADD_MEETING_ERROR;
  payload: any;
}

export interface AddMeetingSuccess {
  type: typeof ADD_MEETING_SUCCESS;
  payload: MeetingTypeI;
}

//===============================<END>ADD MEETING<END>======================//

//===============================<START>UPDATE MEETING<START>======================//

export interface UpdateMeetingLoading {
  type: typeof UPDATE_MEETING_LOADING;
}

export interface UpdateMeetingError {
  type: typeof UPDATE_MEETING_ERROR;
  payload: any;
}

export interface UpdateMeetingSuccess {
  type: typeof UPDATE_MEETING_SUCCESS;
  payload: MeetingTypeI;
}

//===============================<END>UPDATE MEETING<END>======================//

//===============================<START>DELETE MEETING<START>======================//

export interface DeleteMeetingLoading {
  type: typeof DELETE_MEETING_LOADING;
}

export interface DeleteMeetingError {
  type: typeof DELETE_MEETING_ERROR;
}

export interface DeleteMeetingSuccess {
  type: typeof DELETE_MEETING_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE MEETING<END>======================//
export interface ApproveOrDeclineLoading {
  type: typeof APPROVE_OR_DECLINE_LOADING;
}

export interface ApproveOrDeclineError {
  type: typeof APPROVE_OR_DECLINE_ERROR;
}

export interface ApproveOrDeclineSuccess {
  type: typeof APPROVE_OR_DECLINE_SUCCESS;
  payload: ApproveOrDeclineType;
}

export type MeetingDispatchTypes =
  | GetMeetingLoading
  | GetMeetingError
  | GetMeetingSuccess
  | AddMeetingLoading
  | AddMeetingError
  | AddMeetingSuccess
  | UpdateMeetingLoading
  | UpdateMeetingError
  | UpdateMeetingSuccess
  | DeleteMeetingLoading
  | DeleteMeetingError
  | DeleteMeetingSuccess
  | ApproveOrDeclineSuccess
  | ApproveOrDeclineError
  | ApproveOrDeclineLoading
  | GetTodayMeetingLoading
  | GetTodayMeetingError
  | GetTodayMeetingSuccess;
