import {
  GET_INCOME_TYPE_LOADING,
  GET_INCOME_TYPE_ERROR,
  GET_INCOME_TYPE_SUCCESS,
  ADD_INCOME_TYPE_LOADING,
  ADD_INCOME_TYPE_ERROR,
  ADD_INCOME_TYPE_SUCCESS,
  DELETE_INCOME_TYPE_LOADING,
  DELETE_INCOME_TYPE_ERROR,
  DELETE_INCOME_TYPE_SUCCESS,
  UPDATE_INCOME_TYPE_LOADING,
  UPDATE_INCOME_TYPE_ERROR,
  UPDATE_INCOME_TYPE_SUCCESS,
  GET_INCOME_DETAILS_LOADING,
  GET_INCOME_DETAILS_ERROR,
  GET_INCOME_DETAILS_SUCCESS,
  ADD_INCOME_DETAILS_LOADING,
  ADD_INCOME_DETAILS_ERROR,
  ADD_INCOME_DETAILS_SUCCESS,
  DELETE_INCOME_DETAILS_LOADING,
  DELETE_INCOME_DETAILS_ERROR,
  DELETE_INCOME_DETAILS_SUCCESS,
  UPDATE_INCOME_DETAILS_LOADING,
  UPDATE_INCOME_DETAILS_ERROR,
  UPDATE_INCOME_DETAILS_SUCCESS,
  IncomeDispatchTypes,
  IncomeTypeI,
  AddIncomeTypeI,
} from "../../actions/Fees/Income/IncomeActionTypes";

interface InitialStateI {
  loading: boolean;
  income_type: IncomeTypeI[];
  add_income: AddIncomeTypeI[];
  recent: boolean;
  errors: any;
  add_or_update: boolean;
}

const initialState: InitialStateI = {
  loading: false,
  income_type: [],
  add_income: [],
  recent: false,
  errors: null,
  add_or_update: false,
};

const IncomeTypeReducer = (
  state: InitialStateI = initialState,
  action: IncomeDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_INCOME_TYPE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_INCOME_TYPE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_INCOME_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        income_type: action.payload,
      };

    case ADD_INCOME_TYPE_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case ADD_INCOME_TYPE_ERROR:
      return {
        ...state,
        loading: false,
        add_or_update: true,
      };

    case ADD_INCOME_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        income_type: [...state.income_type, action.payload],
        add_or_update: true,
      };

    case UPDATE_INCOME_TYPE_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case UPDATE_INCOME_TYPE_ERROR:
      return {
        ...state,
        loading: false,
        add_or_update: true,
      };

    case UPDATE_INCOME_TYPE_SUCCESS:
      const current_data: IncomeTypeI[] = state.income_type;
      const index: number = current_data.findIndex(
        (element) => element.id === action.payload.id
      );
      current_data[index] = action.payload;

      return {
        ...state,
        loading: false,
        income_type: current_data,
        add_or_update: true,
      };

    case DELETE_INCOME_TYPE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_INCOME_TYPE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_INCOME_TYPE_SUCCESS:
      const new_data: IncomeTypeI[] = state.income_type;
      const item_index = new_data.findIndex(
        (element) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return { ...state, loading: false, income_type: new_data };

    case GET_INCOME_DETAILS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_INCOME_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_INCOME_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        add_income: action.payload,
        recent: false,
      };

    case ADD_INCOME_DETAILS_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case ADD_INCOME_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        recent: false,
        add_or_update: true,
      };

    case ADD_INCOME_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        add_income: [...state.add_income, action.payload],
        errors: null,
        recent: true,
        add_or_update: true,
      };

    case UPDATE_INCOME_DETAILS_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case UPDATE_INCOME_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        recent: false,
        errors: action.payload,
        add_or_update: true,
      };

    case UPDATE_INCOME_DETAILS_SUCCESS:
      const current_add_data: AddIncomeTypeI[] = state.add_income;
      const i: number = current_add_data.findIndex(
        (element) => element.id === action.payload.id
      );
      current_add_data[i] = action.payload;

      return {
        ...state,
        loading: false,
        add_income: current_add_data,
        recent: true,
        errors: null,
        add_or_update: true,
      };

    case DELETE_INCOME_DETAILS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_INCOME_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_INCOME_DETAILS_SUCCESS:
      const n_data: AddIncomeTypeI[] = state.add_income;
      const i_index = n_data.findIndex(
        (element) => element.id === action.payload.id
      );
      n_data.splice(i_index, 1);

      return { ...state, loading: false, add_income: n_data };

    default:
      return state;
  }
};

export default IncomeTypeReducer;
