import React, { useState } from "react";
import SubjectClass from "../../components/Examination/SubjectClass/SubjectClass";
import { ExaminationSideBar } from "../../components";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const SubjectClassPage = () => {
  const nav = <ExaminationSideBar subjectClass />;

  return (
    <SubModuleLayout examinationMenuActive sideNav={nav}>
      <SubjectClass />
    </SubModuleLayout>
  );
};

export default SubjectClassPage;
