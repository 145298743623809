import React, { useEffect, useState } from 'react';
import { Button, Grid, InputLabel, Paper, Typography } from '@material-ui/core';
import BraftEditor from 'braft-editor';
import { useForm } from 'react-hook-form';
import { useStyles } from '../../Styles/FormStyles';
import { EditOutlined } from '@material-ui/icons';
import ReactHtmlParser from 'react-html-parser';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import {
  getPrayer,
  updatePrayer,
} from '../../../rtk/features/prayer/prayerApi';

const PrayerForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [editorState, setEditorState] = useState<any>(null);
  const [editMode, setEditMode] = useState(false);

  const { loading, actionPerformed, prayer } = useSelector(
    (state: RootStore) => state.prayer
  );

  const { register, handleSubmit, errors } = useForm<any>({
    mode: 'onChange',
  });

  useEffect(() => {
    dispatch(getPrayer());
  }, []);

  useEffect(() => {
    if (actionPerformed) {
      dispatch(getPrayer());
    }
  }, [actionPerformed]);

  useEffect(() => {
    if (prayer) {
      const htmlContent = prayer.prayer_field;
      setEditorState(BraftEditor.createEditorState(htmlContent));
    }
  }, [prayer]);

  const onSubmit = () => {
    setEditMode(false);
    const postData = editorState.toHTML();
    if (postData) {
      dispatch(updatePrayer({ prayer_field: postData }));
    }
  };

  const handleEditor = (data: any) => {
    setEditorState(data);
  };

  return (
    <Paper className={classes.pageContent}>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={6} className={classes.formTitle}>
            <span>Prayer</span>
          </Grid>
          <Grid item xs={6}>
            <Grid container justifyContent="flex-end">
              <Button
                disabled={editMode}
                startIcon={<EditOutlined />}
                color="primary"
                variant="outlined"
                onClick={() => setEditMode(true)}
              >
                Edit
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          {!editMode ? (
            <Grid item xs={12}>
              <Typography variant="body1" align="left" color="primary">
                Prayer:
              </Typography>
              <span>
                {editorState && ReactHtmlParser(editorState.toHTML())}
              </span>
            </Grid>
          ) : (
            <div
              style={{
                marginTop: '10px',
                marginBottom: '24px',
                border: '1px solid #ccc',
              }}
            >
              <BraftEditor
                language="en"
                value={editorState}
                onChange={(data) => handleEditor(data)}
              />
            </div>
          )}
        </Grid>
        {editMode && (
          <Grid container justifyContent="flex-end">
            <Button type="submit" fullWidth color="primary" variant="contained">
              Save
            </Button>
          </Grid>
        )}
      </form>
    </Paper>
  );
};

export default PrayerForm;
