import {
  GET_POST_LOADING,
  GET_POST_ERROR,
  GET_POST_SUCCESS,
  PostDispatchTypes,
} from "../actions/SampleActionTypes";
// import { setSnackbar } from "../actions/SnackbarAction";

interface InitialStateI {
  loading: boolean;
  posts?: any;
}

const initialState: InitialStateI = {
  loading: false,
};

const SampleReducer = (
  state: InitialStateI = initialState,
  action: PostDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_POST_LOADING:
      return {
        loading: true,
      };

    case GET_POST_ERROR:
      return {
        loading: false,
      };

    case GET_POST_SUCCESS:
      return {
        loading: false,
        posts: action.payload,
      };

    default:
      return state;
  }
};

export default SampleReducer;
