import {
  Avatar,
  makeStyles,
  Typography,
  Grid,
  Button,
  Divider,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
  root: {
    display: "flex",
    padding: 12,
  },
  profileImg: {
    width: "100px",
    height: "100px",
    // marginRight: 1,
    border: "2px solid #5767a6",
  },
  title: {
    marginTop: "5px",
    color: "rgba(0,0,0,0.75)",
    fontWeight: 500,
    fontSize: "20px",
  },
  content: {
    maxWidth: "250px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0.2rem 0.5rem 0.2rem 0rem",
  },
  contentTitle: {
    color: "gray",
  },
});

export default function GuardianViewModal(props: any) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={2}>
          <Grid container direction="column">
            <Avatar
              className={classes.profileImg}
              alt=""
              src={props.item.guardian_photo}
            />
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.content}>
            <Typography
              variant="subtitle1"
              className="contentTitle"
              component="p"
            >
              <strong>Primary Guardian</strong>
            </Typography>
          </div>
          <div className={classes.content}>
            <Typography variant="body2" className="contentTitle" component="p">
              Name:
            </Typography>
            <Typography variant="body2" component="p">
              {props.item.guardian_name}
            </Typography>
          </div>
          <div className={classes.content}>
            <Typography variant="body2" className="contentTitle" component="p">
              Address:
            </Typography>
            <Typography variant="body2" component="p">
              {props.item.address}
            </Typography>
          </div>
          <div className={classes.content}>
            <Typography variant="body2" className="contentTitle" component="p">
              Phone:
            </Typography>
            <Typography variant="body2" component="p">
              {props.item.phone}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          <Grid container direction="column">
            <Grid item>
              <Avatar
                className={classes.profileImg}
                alt=""
                src={props.item.secondaryGuardian_photo}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.content}>
            <Typography
              variant="subtitle1"
              className="contentTitle"
              component="p"
            >
              <strong>Secondary Guardian</strong>
            </Typography>
          </div>
          <div className={classes.content}>
            <Typography variant="body2" className="contentTitle" component="p">
              Name:
            </Typography>
            <Typography variant="body2" component="p">
              {props.item.secondaryGuardian_name}
            </Typography>
          </div>
          <div className={classes.content}>
            <Typography variant="body2" className="contentTitle" component="p">
              Address:
            </Typography>
            <Typography variant="body2" component="p">
              {props.item.secondaryGuardian_address}
            </Typography>
          </div>
          <div className={classes.content}>
            <Typography variant="body2" className="contentTitle" component="p">
              Phone:
            </Typography>
            <Typography variant="body2" component="p">
              {props.item.secondaryGuardian_Phone}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
