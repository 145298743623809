export const GET_SCHOOLS_LOADING = "GET_SCHOOLS_LOADING";
export const GET_SCHOOLS_ERROR = "GET_SCHOOLS_ERROR";
export const GET_SCHOOLS_SUCCESS = "GET_SCHOOLS_SUCCESS";

export const GET_ALL_SCHOOLS_LOADING = "GET_ALL_SCHOOLS_LOADING";
export const GET_ALL_SCHOOLS_ERROR = "GET_ALL_SCHOOLS_ERROR";
export const GET_ALL_SCHOOLS_SUCCESS = "GET_ALL_SCHOOLS_SUCCESS";

export const GET_SCHOOLS_BY_ID_LOADING = "GET_SCHOOLS_BY_ID_LOADING";
export const GET_SCHOOLS_BY_ID_ERROR = "GET_SCHOOLS_BY_ID_ERROR";
export const GET_SCHOOLS_BY_ID_SUCCESS = "GET_SCHOOLS_BY_ID_SUCCESS";

export const ADD_SCHOOLS_LOADING = "ADD_SCHOOLS_LOADING";
export const ADD_SCHOOLS_ERROR = "ADD_SCHOOLS_ERROR";
export const ADD_SCHOOLS_SUCCESS = "ADD_SCHOOLS_SUCCESS";

export const UPDATE_SCHOOLS_LOADING = "UPDATE_SCHOOLS_LOADING";
export const UPDATE_SCHOOLS_ERROR = "UPDATE_SCHOOLS_ERROR";
export const UPDATE_SCHOOLS_SUCCESS = "UPDATE_SCHOOLS_SUCCESS";

export const DELETE_SCHOOLS_LOADING = "DELETE_SCHOOLS_LOADING";
export const DELETE_SCHOOLS_ERROR = "DELETE_SCHOOLS_ERROR";
export const DELETE_SCHOOLS_SUCCESS = "DELETE_SCHOOLS_SUCCESS";

export const SWITCH_TO_SCHOOL_LOADING = "SWITCH_TO_SCHOOL_LOADING";
export const SWITCH_TO_SCHOOL_ERROR = "SWITCH_TO_SCHOOL_ERROR";
export const SWITCH_TO_SCHOOL_SUCCESS = "SWITCH_TO_SCHOOL_SUCCESS";

export const SWITCH_BACK_TO_SCHOOL_LOADING = "SWITCH_BACK_TO_SCHOOL_LOADING";
export const SWITCH_BACK_TO_SCHOOL_ERROR = "SWITCH_BACK_TO_SCHOOL_ERROR";
export const SWITCH_BACK_TO_SCHOOL_SUCCESS = "SWITCH_BACK_TO_SCHOOL_SUCCESS";

export type RequestDataI = {
  user_info: {
    full_name: string;
    phone: number;
    email: string;
  };
  school_name: string;
  phone_number: number;
  email: string;
  school_reg_number: string;
};

export type SchoolListI = {
  id: number;
  logo: string;
  school_name: string;
  address: string;
  phone_number: number;
  email: string;
  slogan: string;
  school_reg_number: string;
  organization: number;
  total_students?: number;
  total_staffs?: number;
  total_teachers?: number;
  administrator_user?: string;
};

//===============================<START>GET SCHOOL<START>======================//

export interface GetSchoolsLoading {
  type: typeof GET_SCHOOLS_LOADING;
}

export interface GetSchoolsError {
  type: typeof GET_SCHOOLS_ERROR;
}

export interface GetSchoolsSuccess {
  type: typeof GET_SCHOOLS_SUCCESS;
  payload: any;
}

export interface GetAllSchoolsLoading {
  type: typeof GET_ALL_SCHOOLS_LOADING;
}

export interface GetAllSchoolsError {
  type: typeof GET_ALL_SCHOOLS_ERROR;
}

export interface GetAllSchoolsSuccess {
  type: typeof GET_ALL_SCHOOLS_SUCCESS;
  payload: any;
}

export interface GetSchoolsByIdLoading {
  type: typeof GET_SCHOOLS_BY_ID_LOADING;
}

export interface GetSchoolsByIdError {
  type: typeof GET_SCHOOLS_BY_ID_ERROR;
}

export interface GetSchoolsByIdSuccess {
  type: typeof GET_SCHOOLS_BY_ID_SUCCESS;
  payload: any;
}

//===============================<END>GET SCHOOL<END>======================//

//===============================<START>ADD SCHOOL<START>======================//

export interface AddSchoolsLoading {
  type: typeof ADD_SCHOOLS_LOADING;
}

export interface AddSchoolsError {
  type: typeof ADD_SCHOOLS_ERROR;
}

export interface AddSchoolsSuccess {
  type: typeof ADD_SCHOOLS_SUCCESS;
  payload: SchoolListI;
}

//===============================<END>ADD SCHOOL<END>======================//

//===============================<START>UPDATE SCHOOL<START>======================//

export interface UpdateSchoolsLoading {
  type: typeof UPDATE_SCHOOLS_LOADING;
}

export interface UpdateSchoolsError {
  type: typeof UPDATE_SCHOOLS_ERROR;
}

export interface UpdateSchoolsSuccess {
  type: typeof UPDATE_SCHOOLS_SUCCESS;
  payload: SchoolListI;
}

//===============================<END>UPDATE SCHOOL<END>======================//

//===============================<START>DELETE SCHOOL<START>======================//

export interface DeleteSchoolsLoading {
  type: typeof DELETE_SCHOOLS_LOADING;
}

export interface DeleteSchoolsError {
  type: typeof DELETE_SCHOOLS_ERROR;
}

export interface DeleteSchoolsSuccess {
  type: typeof DELETE_SCHOOLS_SUCCESS;
  payload: { id: number };
}

//===============================<END>DELETE SCHOOL<END>======================//

export interface SwitchToSchoolsLoading {
  type: typeof SWITCH_TO_SCHOOL_LOADING;
}

export interface SwitchToSchoolsError {
  type: typeof SWITCH_TO_SCHOOL_ERROR;
}

export interface SwitchToSchoolsSuccess {
  type: typeof SWITCH_TO_SCHOOL_SUCCESS;
  payload: any;
}

export interface SwitchBackToSchoolsLoading {
  type: typeof SWITCH_BACK_TO_SCHOOL_LOADING;
}

export interface SwitchBackToSchoolsError {
  type: typeof SWITCH_BACK_TO_SCHOOL_ERROR;
}

export interface SwitchBackToSchoolsSuccess {
  type: typeof SWITCH_BACK_TO_SCHOOL_SUCCESS;
}

export type SchoolsDispatchTypes =
  | GetSchoolsLoading
  | GetSchoolsError
  | GetSchoolsSuccess
  | GetAllSchoolsLoading
  | GetAllSchoolsError
  | GetAllSchoolsSuccess
  | GetSchoolsByIdLoading
  | GetSchoolsByIdError
  | GetSchoolsByIdSuccess
  | AddSchoolsLoading
  | AddSchoolsError
  | AddSchoolsSuccess
  | UpdateSchoolsLoading
  | UpdateSchoolsError
  | UpdateSchoolsSuccess
  | DeleteSchoolsLoading
  | DeleteSchoolsError
  | DeleteSchoolsSuccess
  | SwitchToSchoolsLoading
  | SwitchToSchoolsError
  | SwitchToSchoolsSuccess
  | SwitchBackToSchoolsLoading
  | SwitchBackToSchoolsError
  | SwitchBackToSchoolsSuccess;
