import {
  GET_RESULT_LOADING,
  GET_RESULT_ERROR,
  GET_RESULT_SUCCESS,
  GENERATE_RESULT_LOADING,
  GENERATE_RESULT_ERROR,
  GENERATE_RESULT_SUCCESS,
  ResultDispatchTypes,
  ResultTypeI,
} from "../../actions/Examination/Result/ResultActionTypes";

interface InitialStateI {
  loading: boolean;
  result: ResultTypeI[];
}

const initialState: InitialStateI = {
  loading: false,
  result: [],
};

const ResultReducer = (
  state: InitialStateI = initialState,
  action: ResultDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_RESULT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_RESULT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_RESULT_SUCCESS:
      return {
        loading: false,
        result: action.payload,
      };

    case GENERATE_RESULT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GENERATE_RESULT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GENERATE_RESULT_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default ResultReducer;
