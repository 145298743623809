import { Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { HeadCellsI } from "../../../actions";
import { AdminLessonPlanTypeI } from "../../../actions/Academics/AdminLessonPlan/AdminLessonPlanActionTypes";
import { GetTeachingStatus } from "../../../common/GetStatus";
import { RootStore } from "../../../store";
import { useTable } from "../../Reusable";
import { ItemViewButton } from "../../Reusable/Buttons/TableButton";
import Popups from "../../Reusable/Dialogs/Popups";
import { useTableStyles } from "../../Styles/TableStyles";
// import LessonPlanView from "../../../dashboard/TeacherDashboard/pages/LessonPlan/Components/LessonPlanView";

const headCells: HeadCellsI[] = [
  { id: "unit_title", label: "Unit Title" },
  { id: "sub_unit_title", label: "Sub Unit Title" },
  { id: "lesson_title", label: "Lesson Title" },
  { id: "status", label: "Status" },
  { id: "start_date", label: "Start Date" },
  { id: "end_date", label: "End Date" },
  { id: "actions", label: "Actions" },
];

const AdminLessonPlanTable = () => {
  const classes = useTableStyles();

  // Modal States
  const [tableData, setTableData] = useState<AdminLessonPlanTypeI[]>([
    // {
    //   id: 1,
    //   name: "A LETTER TO GOD",
    //   start_date: "2022-06-01",
    //   end_date: "2022-06-04",
    //   due_date: "",
    //   completion_date: null,
    //   unit_title: "FIRST FLIGHT- Unit 1",
    //   sub_unit: 1,
    //   sub_unit_name: "",
    //   created_by: 1,
    //   general_info: 1,
    // },
    // {
    //   id: 2,
    //   name: "DUST OF SNOW",
    //   start_date: "2022-06-05",
    //   end_date: "2022-06-07",
    //   due_date: "",
    //   completion_date: null,
    //   unit_title: "Unit -1. Poem",
    //   sub_unit: 1,
    //   sub_unit_name: "",
    //   created_by: 1,
    //   general_info: 1,
    // },
    // {
    //   id: 3,
    //   name: "FIRE AND ICE",
    //   start_date: "2022-06-05",
    //   end_date: "2022-06-06",
    //   due_date: "",
    //   completion_date: null,
    //   unit_title: "Unit -1. Poem",
    //   sub_unit: 1,
    //   sub_unit_name: "",
    //   created_by: 1,
    //   general_info: 1,
    // },
  ]);
  const [loading, setLoading] = useState<boolean>(false);
  const [item, setItem] = useState<any>();
  const [index, setIndex] = useState<number>();
  const [openViewModalPopup, setOpenViewModalPopup] = useState(false);

  // Retrieving re-usable components from useTable
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  const LessonPlanSelector = useSelector(
    (state: RootStore) => state.admin_lessonPlan
  );

  useEffect(() => {
    const items = LessonPlanSelector.lesson_plan;
    // setTableData(items);
    setLoading(LessonPlanSelector.loading);
  }, [LessonPlanSelector]);

  const getCustomTableRow = (item: AdminLessonPlanTypeI, index: number) => {
    return (
      <StyledTableRow key={index} hover tabIndex={-1}>
        <StyledTableCell align="center" className={classes.newcell}>
          {item.unit_title}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.newcell}>
          {item.sub_unit_name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.newcell}>
          {item.name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.newcell}>
          <GetTeachingStatus
            status={item.completion_date ? "completed" : "remaining"}
          />
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.newcell}>
          {item.start_date}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.newcell}>
          {item.end_date}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.newcell}>
          <ItemViewButton
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleViewClicked(item, index)
            }
          />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  const handleViewClicked = (data: any, index: number) => {
    setItem(data);
    setIndex(index + 1);
    setOpenViewModalPopup(true);
  };

  const handleViewModalClose = (value: boolean) => {
    setOpenViewModalPopup(value);
  };

  return (
    <>
      <Paper style={{ padding: "1rem" }}>
        <Typography variant="h6">Lesson Plan List</Typography>
        <TableContainer
          loading={loading}
          items={tableData}
          disableSearch
          headCells={headCells}
          getCustomTableRow={getCustomTableRow}
          rowsPerPage={7}
        />
      </Paper>
      <Popups
        openPopup={openViewModalPopup}
        setOpenPopup={setOpenViewModalPopup}
        onClose={handleViewModalClose}
        maxWidth="xl"
        fullWidth={true}
        title="View Lesson Plan"
        colored
      >
        {/* <LessonPlanView item={item} indexN={index} /> */}
      </Popups>
    </>
  );
};

export default AdminLessonPlanTable;
