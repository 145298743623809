import {
    GET_OPENING_BALANCE_LOADING,
    GET_OPENING_BALANCE_ERROR,
    GET_OPENING_BALANCE_SUCCESS,
    ADD_OPENING_BALANCE_LOADING,
    ADD_OPENING_BALANCE_ERROR,
    ADD_OPENING_BALANCE_SUCCESS,
    OpeningBalanceDispatchTypes,
} from "../../actions/Fees/OpeningBalance/OpeningBalanceActionTypes";

interface InitialStateI {
    loading: boolean;
    opening_balance: any;
    recent: boolean;
    errors: any;
    add_or_update: boolean;
}

const initialState: InitialStateI = {
    loading: false,
    opening_balance: [],
    recent: false,
    errors: null,
    add_or_update: false

};

const OpeningBalanceReducer = (
    state: InitialStateI = initialState,
    action: OpeningBalanceDispatchTypes
): InitialStateI => {
    switch (action.type) {
        case GET_OPENING_BALANCE_LOADING:
            return {
                ...state,
                loading: true,
            };

        case GET_OPENING_BALANCE_ERROR:
            return {
                ...state,
                loading: false,
            };

        case GET_OPENING_BALANCE_SUCCESS:
            return {
                ...state,
                loading: false,
                opening_balance: action.payload,
            };

        case ADD_OPENING_BALANCE_LOADING:
            return {
                ...state,
                loading: true,
                add_or_update: false,
            };

        case ADD_OPENING_BALANCE_ERROR:
            return {
                ...state,
                loading: false,
                add_or_update: true,
            };

        case ADD_OPENING_BALANCE_SUCCESS:
            return {
                ...state,
                loading: false,
                opening_balance: [...state.opening_balance, action.payload.results],
                add_or_update: true,
            };

        default:
            return state;
    }
};

export default OpeningBalanceReducer;
