// ---------------- <START> module import ------------------//
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import { useForm } from "react-hook-form";
import "nepali-datepicker-reactjs/dist/index.css";
import { Grid, InputAdornment } from "@material-ui/core";
import { LocationOnOutlined } from "@material-ui/icons";
import { useFormStyles } from "../../Styles/FormStyles";
import {
  AddEvent,
  UpdateEvent,
} from "../../../actions/Event/Events/EventsAction";
import { GetEventTypes } from "../../../actions/Event/EventType/EventTypeAction";
import FormLayout from "../../Reusable/Layouts/Form.Layout";
import CustomizedTextField from "../../Reusable/Inputs/TextField";
import CustomizedSelect from "../../Reusable/Inputs/Select";
import CustomizedNepaliDatePicker from "../../Reusable/Inputs/NepaliDatePicker";
import CusCheckbox, { FormCheckBox } from "../../Reusable/Inputs/Checkbox";
import {
  dateConverterAdToBs,
  dateConverterBsToAd,
} from "../../utils/dateConverter";
import { CUR_NEPALI_DATE } from "../../utils/nepaliDateUtils";

// ---------------- <END> module import ------------------//

// ---------------- <START> Interface------------------//
interface Tupple {
  key: string;
  value: string;
}
// ---------------- <END> Interface------------------//

// ---------------- <START> Events Component starts ------------------//
const EventsForm = (props: any) => {
  const { editData, onEditCheck } = props;
  const classes = useFormStyles();
  const dispatch = useDispatch();

  const [fromDate, setFromDate] = useState<string | null>(CUR_NEPALI_DATE);
  const [toDate, setToDate] = useState<string | null>(CUR_NEPALI_DATE);
  const [eventType, setEventType] = useState<Tupple | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [editID, setEditID] = useState<string>("");
  const [holiday, setHoliday] = useState<boolean>(false);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    setError,
    clearErrors,
    errors,
  } = useForm<any>({
    mode: "onChange",
  });

  // Fetch data
  useEffect(() => {
    dispatch(GetEventTypes());
  }, []);

  useEffect(() => {
    editData != null && handleEditTableData(editData);
  }, [props.editData]);

  // Fetch from redux
  const eventTypeState = useSelector((state: RootStore) => state.event_type);
  const eventsLoading = useSelector((state: RootStore) => state.events.loading);
  const add_or_update = useSelector((state: RootStore) => state.events.add_or_update)

  useEffect(() => {
    if (add_or_update) {
      setIsBtnLoading(false)
      handleReset();
    }
  }, [add_or_update])

  useEffect(() => {
    setLoading(eventsLoading);
  }, [eventsLoading]);

  const EVENT_TYPE_CHOICES = eventTypeState.event_types.map((item: any) => ({
    key: item.id.toString(),
    value: item.event_type,
  }));

  // handle form update
  const handleEditTableData = (data: any) => {
    const selectedEventType: any = eventTypeState.event_types.find(
      (event_type) => event_type.id == data.event_type
    );

    if (selectedEventType) {
      setEditMode(true);
      setValue("title", data.title);
      setValue("location", data.location);
      setValue("date", data.date);
      setValue("time", data.time);
      setHoliday(data.is_holiday);
      setValue("description", data.description);
      setEventType(
        EVENT_TYPE_CHOICES.find((item) => item.key == data.event_type) ||
        EVENT_TYPE_CHOICES[0]
      );
      setFromDate(dateConverterAdToBs(data.from_date));
      setToDate(dateConverterAdToBs(data.to_date));
      setEditID(data.id);
    }
  };

  // handle form submit
  const onSubmit = (data: any) => {
    setIsBtnLoading(true);
    if (eventType && fromDate && toDate) {
      const newData = {
        // ...data,
        location: data.location ? data.location : null,
        time: data.time ? data.time : null,
        description: data.description,
        is_holiday: data.is_holiday,
        title: data.title,
        from_date: dateConverterBsToAd(fromDate),
        to_date: dateConverterBsToAd(toDate),
        event_type: eventType.key,
      };

      if (editMode && editID) {
        dispatch(UpdateEvent(editID, newData));
        // setEditMode(false);
      } else {
        dispatch(AddEvent(newData));
      }

      // reset();
      // setDate(null);
      // setEventType(null);
      // setHoliday(false);
      // onEditCheck(false);
    }
  };
  const handleReset = () => {
    reset();
    setEditID("");
    setEventType(null);
    setFromDate(null);
    setToDate(null);
    setEditMode(false);
    setHoliday(false);
    onEditCheck(false);
  };

  const handleFromDate = (value: any) => {
    setFromDate(value);
    clearErrors("from_date");
  };

  const handleToDate = (value: any) => {
    setToDate(value);
    clearErrors("to_date");
  };

  //--------------------------ERROR HANDLING-------------------------------//

  const eventSelector = useSelector((state: RootStore) => state.events);
  const errorsData = eventSelector.errors;
  const initialErrorsData = useRef(errorsData);
  const [serverErrors, setServerErrors] = useState<any>(null);

  useEffect(() => {
    if (initialErrorsData.current === errorsData) {
      initialErrorsData.current = errorsData; // Do not set initial errors
    } else {
      if (errorsData?.error != null) {
        const keys = Object.keys(errorsData?.error);
        keys.map((elem: any) => {
          setError(elem, {
            type: "serverSideError",
            message: errorsData.error[elem] && errorsData.error[elem][0],
          });
        });
      }
      setServerErrors(errorsData);
    }
  }, [errorsData]);

  useEffect(() => {
    if (eventSelector.recent == true) {
      handleReset();
    }
  }, [eventSelector]);

  return (
    <>
      <FormLayout
        title={editMode ? "Edit Events" : "Add Events"}
        onSubmit={handleSubmit(onSubmit)}
        editMode={editMode}
        loading={loading}
        onClick={!isBtnLoading && handleReset}
        add_or_update={isBtnLoading}
      >
        <CustomizedSelect
          label="Event Type"
          name="event_type"
          options={EVENT_TYPE_CHOICES}
          value={eventType}
          setValue={setEventType}
          required
          error={errors["event_type"] ? "Event Type must be selected" : ""}
          inputRef={register({ required: true })}
        />
        <CustomizedTextField
          label="Title"
          placeholder="Title"
          name="title"
          required
          error={errors["title"] ? "Required Field." : ""}
          inputRef={register({ required: true })}
        />
        <FormCheckBox
          onChange={(
            event: React.ChangeEvent<HTMLInputElement>,
            checked: boolean
          ) => setHoliday(checked)}
          label="Is this on Holiday?"
          checked={holiday}
          name="is_holiday"
          inputRef={register({ required: false })}
        />
        {!holiday ? (
          <CustomizedTextField
            label="Location"
            placeholder="Location"
            name="location"
            required={!Boolean(holiday)}
            error={errors["location"] ? "Required Field." : ""}
            inputRef={register}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <LocationOnOutlined />
                </InputAdornment>
              ),
            }}
          />
        ) : null}
        <CustomizedNepaliDatePicker
          label="From Date"
          value={fromDate}
          setValue={handleFromDate}
          name="from_date"
          required
        />
        <span className={classes.validationErrorInfo}>
          {/* {errorsData?.error?.from_date?.error} */}
          {errors.from_date?.type === "serverSideError" &&
            errors.from_date.message}
        </span>
        <CustomizedNepaliDatePicker
          label="To Date"
          value={toDate}
          setValue={handleToDate}
          name="to_date"
          required
        />
        <span className={classes.validationErrorInfo}>
          {/* {errorsData?.error?.to_date?.error} */}
          {errors.to_date?.type === "serverSideError" && errors.to_date.message}
        </span>
        {!holiday ? (
          <CustomizedTextField
            label="Time"
            type="time"
            name="time"
            id="time"
            required={!Boolean(holiday)}
            InputLabelProps={{
              shrink: true,
            }}
            error={errors["time"] ? "Required Field." : ""}
            inputRef={register}
          />
        ) : null}
        <CustomizedTextField
          multiline
          rows={3}
          label="Description"
          placeholder="Description"
          name="description"
          required
          error={errors["description"] ? "Required Field." : ""}
          inputRef={register({ required: true })}
        />
      </FormLayout>
    </>
  );
};
// ---------------- <END> Events Component ends ------------------//
export default EventsForm;
