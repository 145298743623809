export const GET_VEHICLE_LOADING = "GET_VEHICLE_LOADING";
export const GET_VEHICLE_ERROR = "GET_VEHICLE_ERROR";
export const GET_VEHICLE_SUCCESS = "GET_VEHICLE_SUCCESS";

export const ADD_VEHICLE_LOADING = "ADD_VEHICLE_LOADING";
export const ADD_VEHICLE_ERROR = "ADD_VEHICLE_ERROR";
export const ADD_VEHICLE_SUCCESS = "ADD_VEHICLE_SUCCESS";

export const UPDATE_VEHICLE_LOADING = "UPDATE_VEHICLE_LOADING";
export const UPDATE_VEHICLE_ERROR = "UPDATE_VEHICLE_ERROR";
export const UPDATE_VEHICLE_SUCCESS = "UPDATE_VEHICLE_SUCCESS";

export const DELETE_VEHICLE_LOADING = "DELETE_VEHICLE_LOADING";
export const DELETE_VEHICLE_ERROR = "DELETE_VEHICLE_ERROR";
export const DELETE_VEHICLE_SUCCESS = "DELETE_VEHICLE_SUCCESS";

export type VehicleTypeI = {
  id: string;
  vehicle_no: string;
  seat_capacity: number;
  vehicle_brand: string;
  vehicle_model: string;
  manufactured_year: Date;
  max_speed: number;
};

//===============================<START>GET Vehicle<START>======================//

export interface GetVehicleLoading {
  type: typeof GET_VEHICLE_LOADING;
}

export interface GetVehicleError {
  type: typeof GET_VEHICLE_ERROR;
}

export interface GetVehicleSuccess {
  type: typeof GET_VEHICLE_SUCCESS;
  payload: VehicleTypeI[];
}

//===============================<END>GET Vehicle<END>======================//

//===============================<START>ADD Vehicle<START>======================//

export interface AddVehicleLoading {
  type: typeof ADD_VEHICLE_LOADING;
}

export interface AddVehicleError {
  type: typeof ADD_VEHICLE_ERROR;
  payload: any;
}

export interface AddVehicleSuccess {
  type: typeof ADD_VEHICLE_SUCCESS;
  payload: VehicleTypeI;
}

//===============================<END>ADD Vehicle<END>======================//

//===============================<START>UPDATE Vehicle<START>======================//

export interface UpdateVehicleLoading {
  type: typeof UPDATE_VEHICLE_LOADING;
}

export interface UpdateVehicleError {
  type: typeof UPDATE_VEHICLE_ERROR;
  payload: any;
}

export interface UpdateVehicleSuccess {
  type: typeof UPDATE_VEHICLE_SUCCESS;
  payload: VehicleTypeI;
}

//===============================<END>UPDATE Vehicle<END>======================//

//===============================<START>DELETE Vehicle<START>======================//

export interface DeleteVehicleLoading {
  type: typeof DELETE_VEHICLE_LOADING;
}

export interface DeleteVehicleError {
  type: typeof DELETE_VEHICLE_ERROR;
}

export interface DeleteVehicleSuccess {
  type: typeof DELETE_VEHICLE_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE VEHICLE<END>======================//

export type VehicleDispatchTypes =
  | GetVehicleLoading
  | GetVehicleError
  | GetVehicleSuccess
  | AddVehicleLoading
  | AddVehicleError
  | AddVehicleSuccess
  | UpdateVehicleLoading
  | UpdateVehicleError
  | UpdateVehicleSuccess
  | DeleteVehicleLoading
  | DeleteVehicleError
  | DeleteVehicleSuccess