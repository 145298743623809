export const GET_FEE_STATEMENT_LOADING = "GET_FEE_STATEMENT_LOADING";
export const GET_FEE_STATEMENT_ERROR = "GET_FEE_STATEMENT_ERROR";
export const GET_FEE_STATEMENT_SUCCESS = "GET_FEE_STATEMENT_SUCCESS";

export const GET_INVOICE_DETAIL_LOADING = "GET_INVOICE_DETAIL_LOADING";
export const GET_INVOICE_DETAIL_ERROR = "GET_INVOICE_DETAIL_ERROR";
export const GET_INVOICE_DETAIL_SUCCESS = "GET_INVOICE_DETAIL_SUCCESS";

export interface GetFeestatementLoading {
    type: typeof GET_FEE_STATEMENT_LOADING;
}

export interface GetFeestatementError {
    type: typeof GET_FEE_STATEMENT_ERROR;
}

export interface GetFeestatementSuccess {
    type: typeof GET_FEE_STATEMENT_SUCCESS;
    payload: any;
}

export interface GetInvoiceDetailLoading {
    type: typeof GET_INVOICE_DETAIL_LOADING;
}

export interface GetInvoiceDetailError {
    type: typeof GET_INVOICE_DETAIL_ERROR;
}

export interface GetInvoiceDetailSuccess {
    type: typeof GET_INVOICE_DETAIL_SUCCESS;
    payload: any;
}

export type FeestatementDispatchTypes =
    | GetFeestatementLoading
    | GetFeestatementError
    | GetFeestatementSuccess
    | GetInvoiceDetailLoading
    | GetInvoiceDetailError
    | GetInvoiceDetailSuccess