import React from 'react';
import {
  Paper,
  makeStyles,
  createStyles,
  Theme,
  Tooltip,
  Typography,
  Grid,
  Link,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import BoardHeader from '../Calendar/BoardHeader';
import LeaveList from '../../Student/StudentLeave/LeaveList';
import DashboardEventList from './DashboardEventList';
import NepaliDate from 'nepali-date-converter';
import { CUR_NEPALI_DATE } from '../../utils/nepaliDateUtils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2.5, 3, 2.5, 3),
    },
    eventList: {
      display: 'flex',
      overflow: 'hidden',
      alignItems: 'center',
    },
    boxHeaderLink: {
      '&:hover': {
        textDecoration: 'none',
        paddingBottom: '2px',
        borderBottom: ' 2px solid',
      },
    },
  })
);

const EventContainer = (props: any) => {
  const classes = useStyles();

  const date5 = new NepaliDate(CUR_NEPALI_DATE).toJsDate();

  const date = new Date();
  const EnglishMonth = date.toLocaleString('default', {
    month: 'long',
    year: 'numeric',
  });

  return (
    <Paper elevation={1}>
      <div className={classes.root}>
        <BoardHeader title="Events" date={EnglishMonth} to="/events" />
        <DashboardEventList />
      </div>
    </Paper>
  );
};

export default EventContainer;
