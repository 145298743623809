export const GET_CHARACTER_CERTIFICATE_TEMPLATE_LOADING =
  "GET_CHARACTER_CERTIFICATE_TEMPLATE_LOADING";
export const GET_CHARACTER_CERTIFICATE_TEMPLATE_ERROR =
  "GET_CHARACTER_CERTIFICATE_TEMPLATE_ERROR";
export const GET_CHARACTER_CERTIFICATE_TEMPLATE_SUCCESS =
  "GET_CHARACTER_CERTIFICATE_TEMPLATE_SUCCESS";

export const ADD_CHARACTER_CERTIFICATE_TEMPLATE_LOADING =
  "ADD_CHARACTER_CERTIFICATE_TEMPLATE_LOADING";
export const ADD_CHARACTER_CERTIFICATE_TEMPLATE_ERROR =
  "ADD_CHARACTER_CERTIFICATE_TEMPLATE_ERROR";
export const ADD_CHARACTER_CERTIFICATE_TEMPLATE_SUCCESS =
  "ADD_CHARACTER_CERTIFICATE_TEMPLATE_SUCCESS";

export const ACTIVATE_OR_DEACTIVATE_CHARACTER_CERTIFICATE_TEMPLATE_LOADING =
  "ACTIVATE_OR_DEACTIVATE_CHARACTER_CERTIFICATE_TEMPLATE_LOADING";
export const ACTIVATE_OR_DEACTIVATE_CHARACTER_CERTIFICATE_TEMPLATE_ERROR =
  "ACTIVATE_OR_DEACTIVATE_CHARACTER_CERTIFICATE_TEMPLATE_ERROR";
export const ACTIVATE_OR_DEACTIVATE_CHARACTER_CERTIFICATE_TEMPLATE_SUCCESS =
  "ACTIVATE_OR_DEACTIVATE_CHARACTER_CERTIFICATE_TEMPLATE_SUCCESS";

export const GET_SINGLE_CHARACTER_CERTIFICATE_TEMPLATE_LOADING =
  "GET_SINGLE_CHARACTER_CERTIFICATE_TEMPLATE_LOADING";
export const GET_SINGLE_CHARACTER_CERTIFICATE_TEMPLATE_ERROR =
  "GET_SINGLE_CHARACTER_CERTIFICATE_TEMPLATE_ERROR";
export const GET_SINGLE_CHARACTER_CERTIFICATE_TEMPLATE_SUCCESS =
  "GET_SINGLE_CHARACTER_CERTIFICATE_TEMPLATE_SUCCESS";

export const UPDATE_CHARACTER_CERTIFICATE_TEMPLATE_LOADING =
  "UPDATE_CHARACTER_CERTIFICATE_TEMPLATE_LOADING";
export const UPDATE_CHARACTER_CERTIFICATE_TEMPLATE_ERROR =
  "UPDATE_CHARACTER_CERTIFICATE_TEMPLATE_ERROR";
export const UPDATE_CHARACTER_CERTIFICATE_TEMPLATE_SUCCESS =
  "UPDATE_CHARACTER_CERTIFICATE_TEMPLATE_SUCCESS";

export const DELETE_CHARACTER_CERTIFICATE_TEMPLATE_LOADING =
  "DELETE_CHARACTER_CERTIFICATE_TEMPLATE_LOADING";
export const DELETE_CHARACTER_CERTIFICATE_TEMPLATE_ERROR =
  "DELETE_CHARACTER_CERTIFICATE_TEMPLATE_ERROR";
export const DELETE_CHARACTER_CERTIFICATE_TEMPLATE_SUCCESS =
  "DELETE_CHARACTER_CERTIFICATE_TEMPLATE_SUCCESS";

export type ColorI = {
  key: string;
  value: string;
  name: string;
};

export type TemplateDataI = {
  ape_enable: boolean;
  footer_title: string;
  footer_text_1: string;
  footer_text_2: string;
  footer_text_3: string;
  thumbnail: string;
  watermark: boolean;
  school_logo: boolean;
  school_photo: boolean;
  watermark_position: string;
  school_logo_position: string;
  school_photo_position: string;
  grading_system: boolean;
};

//===============================<START>GET CLASS<START>======================//

export interface GetCharacterCertificateTemplateLoading {
  type: typeof GET_CHARACTER_CERTIFICATE_TEMPLATE_LOADING;
}

export interface GetCharacterCertificateTemplateError {
  type: typeof GET_CHARACTER_CERTIFICATE_TEMPLATE_ERROR;
}

export interface GetCharacterCertificateTemplateSuccess {
  type: typeof GET_CHARACTER_CERTIFICATE_TEMPLATE_SUCCESS;
  payload: TemplateDataI[];
}

//===============================<END>GET CLASS<END>======================//

//===============================<START>GET CLASS<START>======================//

export interface GetSingleCharacterCertificateTemplateLoading {
  type: typeof GET_SINGLE_CHARACTER_CERTIFICATE_TEMPLATE_LOADING;
}

export interface GetSingleCharacterCertificateTemplateError {
  type: typeof GET_SINGLE_CHARACTER_CERTIFICATE_TEMPLATE_ERROR;
}

export interface GetSingleCharacterCertificateTemplateSuccess {
  type: typeof GET_SINGLE_CHARACTER_CERTIFICATE_TEMPLATE_SUCCESS;
  payload: any;
}

//===============================<END>GET CLASS<END>======================//

//===============================<START>GET CLASS<START>======================//

export interface UpdateCharacterCertificateTemplateLoading {
  type: typeof UPDATE_CHARACTER_CERTIFICATE_TEMPLATE_LOADING;
}

export interface UpdateCharacterCertificateTemplateError {
  type: typeof UPDATE_CHARACTER_CERTIFICATE_TEMPLATE_ERROR;
}

export interface UpdateCharacterCertificateTemplateSuccess {
  type: typeof UPDATE_CHARACTER_CERTIFICATE_TEMPLATE_SUCCESS;
  payload: any;
}

//===============================<END>GET CLASS<END>======================//

//===============================<START>ADD CLASS<START>======================//

export interface AddCharacterCertificateTemplateLoading {
  type: typeof ADD_CHARACTER_CERTIFICATE_TEMPLATE_LOADING;
}

export interface AddCharacterCertificateTemplateError {
  type: typeof ADD_CHARACTER_CERTIFICATE_TEMPLATE_ERROR;
}

export interface AddCharacterCertificateTemplateSuccess {
  type: typeof ADD_CHARACTER_CERTIFICATE_TEMPLATE_SUCCESS;
  payload: TemplateDataI;
}

//===============================<END>ADD CLASS<END>======================//

//===============================<START>UPDATE CLASS<START>======================//

export interface ActivateOrDeactivateCharacterCertificateTemplateLoading {
  type: typeof ACTIVATE_OR_DEACTIVATE_CHARACTER_CERTIFICATE_TEMPLATE_LOADING;
}

export interface ActivateOrDeactivateCharacterCertificateTemplateError {
  type: typeof ACTIVATE_OR_DEACTIVATE_CHARACTER_CERTIFICATE_TEMPLATE_ERROR;
}

export interface ActivateOrDeactivateCharacterCertificateTemplateSuccess {
  type: typeof ACTIVATE_OR_DEACTIVATE_CHARACTER_CERTIFICATE_TEMPLATE_SUCCESS;
  payload: any;
}

//===============================<END>UPDATE CLASS<END>======================//

//===============================<START>GET CLASS<START>======================//

export interface DeleteCharacterCertificateTemplateLoading {
  type: typeof DELETE_CHARACTER_CERTIFICATE_TEMPLATE_LOADING;
}

export interface DeleteCharacterCertificateTemplateError {
  type: typeof DELETE_CHARACTER_CERTIFICATE_TEMPLATE_ERROR;
}

export interface DeleteCharacterCertificateTemplateSuccess {
  type: typeof DELETE_CHARACTER_CERTIFICATE_TEMPLATE_SUCCESS;
  payload: any;
}

//===============================<END>GET CLASS<END>======================//

export type CharacterCertificateTemplateDispatchTypes =
  | GetCharacterCertificateTemplateLoading
  | GetCharacterCertificateTemplateError
  | GetCharacterCertificateTemplateSuccess
  | GetSingleCharacterCertificateTemplateLoading
  | GetSingleCharacterCertificateTemplateError
  | GetSingleCharacterCertificateTemplateSuccess
  | AddCharacterCertificateTemplateLoading
  | AddCharacterCertificateTemplateError
  | AddCharacterCertificateTemplateSuccess
  | ActivateOrDeactivateCharacterCertificateTemplateLoading
  | ActivateOrDeactivateCharacterCertificateTemplateError
  | ActivateOrDeactivateCharacterCertificateTemplateSuccess
  | DeleteCharacterCertificateTemplateLoading
  | DeleteCharacterCertificateTemplateError
  | DeleteCharacterCertificateTemplateSuccess
  | UpdateCharacterCertificateTemplateLoading
  | UpdateCharacterCertificateTemplateError
  | UpdateCharacterCertificateTemplateSuccess;
