const MultipleImageUpdate = (obj: object) => {
  if (obj && Object.keys(obj).length > 0) {
    const finalValues = {};
    Object.keys(obj).map((key, index) => {
      if (obj[key]?.length > 500) {
        // const updateFinalValues = {
        //   key: obj[key],
        // };
        finalValues[key] = obj[key];
      } else if (obj[key] == null) {
        const updateFinalValues = {
          key: null,
        };
        finalValues[key] = "";
      } else if (obj[key]?.length < 500) {
        const updateFinalValues = {};
      }
    });
    return finalValues;
  }
  // for (let i = 0; i < Object.keys(obj).length; i++) {
  // if (image?.length > 500) {
  //     const finalValues = {
  //       ...form_data,
  //       student_photo: image,
  //     };
  //     const updateFinalValues = {
  //       ...form_data,
  //       student_photo: image,
  //     };
  //     delete updateFinalValues["student_document"];
  //   } else if (image == null) {
  //     const finalValues = {
  //       ...form_data,
  //       student_photo: null,
  //     };
  //     const updateFinalValues = {
  //       ...form_data,
  //       student_photo: null,
  //     };
  //     delete updateFinalValues["student_document"];

  //   } else if (image?.length < 500) {
  //     const finalValues = {
  //       ...form_data,
  //     };
  //     const updateFinalValues = {
  //       ...form_data,
  //     };
  //     delete updateFinalValues["student_document"];

  //   }
  // }
  // return true;
  //   }
};

export { MultipleImageUpdate };
