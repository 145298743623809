import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_SUBJECT_LOADING,
  GET_SUBJECT_ERROR,
  GET_SUBJECT_SUCCESS,
  SubjectDispatchTypes,
  ADD_SUBJECT_LOADING,
  ADD_SUBJECT_SUCCESS,
  ADD_SUBJECT_ERROR,
  DELETE_SUBJECT_SUCCESS,
  DELETE_SUBJECT_ERROR,
  DELETE_SUBJECT_LOADING,
  UPDATE_SUBJECT_LOADING,
  UPDATE_SUBJECT_SUCCESS,
  UPDATE_SUBJECT_ERROR,
} from "./SubjectActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";
import { HeaderConfig } from "../../Auth/Login/LoginAction";

interface RequestDataI {
  name: string;
  credit_hour: number;
  subject_code: string;
  subject_type: string;
  is_optional: boolean;
}

export const GetSubjects =
  () => async (dispatch: Dispatch<SubjectDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_SUBJECT_LOADING,
      });

      const res = await axios.get(`${ADMIN_API_URL}/subject/`, HeaderConfig());

      dispatch({
        type: GET_SUBJECT_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_SUBJECT_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "Subjects Loading Failed",
      //   },
      // });
    }
  };

export const GetSubjectsWithPagination =
  (id: number) =>
  async (dispatch: Dispatch<SubjectDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_SUBJECT_LOADING,
      });
      const res = await axios.get(
        `${ADMIN_API_URL}/subject/?page=${id}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_SUBJECT_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_SUBJECT_ERROR,
      });
    }
  };

export const GetPaginatedSubjects =
  (id: number, highest: number) =>
  async (dispatch: Dispatch<SubjectDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_SUBJECT_LOADING,
      });
      const res = await axios.get(
        `${ADMIN_API_URL}/subject/?page=${id}&per_page=${highest}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_SUBJECT_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_SUBJECT_ERROR,
      });
    }
  };

export const GetSubjectsByClass =
  (grade: string) =>
  async (dispatch: Dispatch<SubjectDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_SUBJECT_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/subjects/?grade=${grade}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_SUBJECT_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_SUBJECT_ERROR,
      });
    }
  };

export const GetSubjectsByTeacher =
  (grade: string, teacher: string) =>
  async (dispatch: Dispatch<SubjectDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_SUBJECT_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/subjects/?grade=${grade}&teacher=${teacher}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_SUBJECT_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_SUBJECT_ERROR,
      });
    }
  };

export const AddSubject =
  (data: RequestDataI) =>
  async (dispatch: Dispatch<SubjectDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_SUBJECT_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/subject/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_SUBJECT_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Subject Added Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: ADD_SUBJECT_ERROR,
        payload: error.response && error.response.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: error.response.data.detail,
        },
      });
    }
  };

export const UpdateSubject =
  (id: string, data: RequestDataI) =>
  async (dispatch: Dispatch<SubjectDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_SUBJECT_LOADING,
      });

      const res = await axios.put(
        `${ADMIN_API_URL}/subject/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_SUBJECT_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Subject Updated Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: UPDATE_SUBJECT_ERROR,
        payload: error.response && error.response.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Subject Update Failed",
        },
      });
    }
  };

export const DeleteSubject =
  (id: string) =>
  async (dispatch: Dispatch<SubjectDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_SUBJECT_LOADING,
      });

      const res = await axios.delete(
        `${ADMIN_API_URL}/subject/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_SUBJECT_SUCCESS,
        payload: { id: id },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Subject Deleted Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_SUBJECT_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Subject Delete Failed",
        },
      });
    }
  };
