import React from "react";
import { FinanceSidebar } from "../../components";
import ApplyDiscount from "../../components/Finance/Discount/ApplyDiscount";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const DiscountPage = () => {
  const nav = <FinanceSidebar discount />;
  return (
    <SubModuleLayout FeesMenuActive sideNav={nav}>
      <ApplyDiscount />
    </SubModuleLayout>
  );
};

export default DiscountPage;
