// @ts-ignore
export const NepaliCalendar: any = NepaliFunctions;

export const getCurrentBsDate = (): {
  year: number;
  month: number;
  day: number;
} => NepaliCalendar.GetCurrentBsDate();

export const getBsDaysInMonth = (year: number, month: number): number =>
  NepaliCalendar.GetDaysInBsMonth(year, month);

export const getBsDayName = ({
  year,
  month,
  day,
}: {
  year: number;
  month: number;
  day: number;
}): string => NepaliCalendar.GetBsFullDay({ year, month, day });
