import React from "react";
import { Button, makeStyles, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

interface DialogI {
  children: React.ReactNode;
  onClick?: boolean;
  title?: string;
  variant?: string;
  color?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const AddNewButton = (props: any) => {
  const { children, onClick, title, variant, color, disabled, ...rest } = props;
  const classes = useStyles();

  return (
    <Tooltip title={title}>
      <Button
        type="button"
        color={color}
        variant={variant}
        // startIcon={<AddIcon />}
        onClick={onClick}
        disabled={disabled}
        {...rest}
      >
        {children}
      </Button>
    </Tooltip>
  );
};

export default AddNewButton;
