import {
  GET_EVENTS_LOADING,
  GET_EVENTS_ERROR,
  GET_EVENTS_SUCCESS,
  ADD_EVENTS_LOADING,
  ADD_EVENTS_ERROR,
  ADD_EVENTS_SUCCESS,
  DELETE_EVENTS_LOADING,
  DELETE_EVENTS_ERROR,
  DELETE_EVENTS_SUCCESS,
  UPDATE_EVENTS_LOADING,
  UPDATE_EVENTS_ERROR,
  UPDATE_EVENTS_SUCCESS,
  EventsTypeI,
  EventsDispatchTypes,
} from "../../actions/Event/Events/EventsActionTypes";

interface InitialStateI {
  loading: boolean;
  events: any;
  errors: any;
  recent?: boolean;
  add_or_update: boolean;
}

const initialState: InitialStateI = {
  loading: false,
  events: [],
  errors: null,
  recent: false,
  add_or_update: false,
};

const EventsReducer = (
  state: InitialStateI = initialState,
  action: EventsDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_EVENTS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_EVENTS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        events: action.payload,
        errors: null,
      };

    case ADD_EVENTS_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case ADD_EVENTS_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        add_or_update: true,
      };

    case ADD_EVENTS_SUCCESS:
      return {
        loading: false,
        events: [...state.events, action.payload],
        errors: null,
        recent: true,
        add_or_update: true,
      };

    case UPDATE_EVENTS_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case UPDATE_EVENTS_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        add_or_update: true,
      };

    case UPDATE_EVENTS_SUCCESS:
      const current_data: EventsTypeI[] = state.events;
      const index: number = current_data.findIndex(
        (element) => element.id === action.payload.id
      );
      current_data[index] = action.payload;

      return {
        loading: false,
        events: current_data,
        errors: null,
        recent: true,
        add_or_update: true,
      };

    case DELETE_EVENTS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_EVENTS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_EVENTS_SUCCESS:
      const new_data: EventsTypeI[] = state.events;
      const item_index = new_data.findIndex(
        (element) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return {
        ...state,
        loading: false,
        events: new_data,
        errors: null,
      };

    default:
      return state;
  }
};

export default EventsReducer;
