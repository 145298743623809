import { useSelector } from "react-redux";
import ActivityCard from "../../../../Reusable/ActivityCard";
import ProgressButton from "../../../../Reusable/Buttons/ProgressButton";
import { RootStore } from "../../../../../store"
import { LinearProgress } from "@material-ui/core";

const Feedback = () => {
    const { loading, progress } = useSelector((state: RootStore) => state.student_progress)
    const isEmpty = Object.keys(progress[1]).length === 0;

    return (
        <>
            {loading && (
                <LinearProgress style={{ margin: "-14px -24px 24px -24px" }} />
            )}
            <div style={{ display: "flex", gap: "15px" }}>
                {!isEmpty && <>
                    <ActivityCard data={{ value: progress[1].total_feedback, subValue: "Feedbacks", time: "Total" }} />
                    <ActivityCard data={{ value: progress[1].approved_feedback, subValue: "Approved", time: "Feedback" }} />
                    <ActivityCard data={{ value: progress[1].declined_feedback, subValue: "Declined", time: "Feedback" }} />
                    <ActivityCard data={{ value: progress[1].pending_feedback, subValue: "Pending", time: "Feedback" }} /></>}
            </div>
            <ProgressButton data={{ title: "Overall Feedback Score", value: "50%", status: "Bad" }} />
        </>
    )
}

export default Feedback