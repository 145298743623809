import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setSnackbar } from "../../../actions/SnackbarAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

export const getStaffs = createAsyncThunk(
  "humanResource/getStaffs",
  async ({ page }: { page?: number }, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.get(
        `${ADMIN_API_URL}/get-staff-list/?page=${page || 1}&per_page=10`
      );

      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(null);
    }
  }
);

export const getStaffDetails = createAsyncThunk(
  "humanResource/staffDetails",
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.get(`${ADMIN_API_URL}/staff-details/${id}`);

      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(null);
    }
  }
);

export const getStaffLeaveInfo = createAsyncThunk(
  "humanResource/staffLeaveInfo",
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.get(`${ADMIN_API_URL}/leave_info/?staff=${id}`);
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(null);
    }
  }
);

export const createStaff = createAsyncThunk(
  "humanResource/createStaff",
  async (
    data: {
      full_name: string;
      gender: string;
      phone_number: string;
      staff_type: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.post(`${ADMIN_API_URL}/create-staff/`, data);
      dispatch(setSnackbar(true, "success", "Staff added successfully."));
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(null);
    }
  }
);

export const updateStaffFaculty = createAsyncThunk(
  "humanResource/updateStaffFaculty",
  async (
    data: {
      id: string;
      department: string;
      designation: string;
      date_of_joining: string | null;
      level: string | null;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.post(
        `${ADMIN_API_URL}/staff-faculty-info/?staff=${data.id}`,
        data
      );
      dispatch(setSnackbar(true, "success", "Staff updated successfully."));
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(null);
    }
  }
);

export const updateStaffAcademy = createAsyncThunk(
  "humanResource/updateStaffAcademy",
  async (
    data: {
      id: string;
      university: string | null;
      passed_year: string | null;
      highest_degree: string | null;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.post(
        `${ADMIN_API_URL}/staff-academic-info/?staff=${data.id}`,
        data
      );
      dispatch(setSnackbar(true, "success", "Staff updated successfully."));
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(null);
    }
  }
);

export const updateStaffPersonal = createAsyncThunk(
  "humanResource/updateStaffPersonal",
  async (
    data: {
      id: string;
      gender: string;
      phone: string;
      address: string;
      alter_phone: string | null;
      date_of_birth: string | null;
      marital_status: string | null;
      religion: string | null;
      blood_group: string | null;
      citizenship_number: string;
      cast: string | null;
      father_name: string | null;
      mother_name: string | null;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.post(
        `${ADMIN_API_URL}/staff-personal-info/?staff=${data.id}`,
        data
      );
      dispatch(setSnackbar(true, "success", "Staff updated successfully."));
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(null);
    }
  }
);

export const updateStaffOtherInfo = createAsyncThunk(
  "humanResource/updateStaffOtherInfo",
  async (
    data: {
      id: string;
      license_no: string | null;
      insurance_no: string | null;
      pf_account_no: string | null;
      bank_name: string | null;
      bank_account_no: string | null;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.post(
        `${ADMIN_API_URL}/staff-other-info/?staff=${data.id}`,
        data
      );
      dispatch(setSnackbar(true, "success", "Staff updated successfully."));
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(null);
    }
  }
);

export const getAttendanceToday = createAsyncThunk(
  "humanResource/getAttendanceToday",
  async (unknown, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.get(
        `${ADMIN_API_URL}/device/attendance/emp-check-in-out/`
      );
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      dispatch(setSnackbar(true, "warning", "Failed to fetch attendance."));
      return rejectWithValue(null);
    }
  }
);

export const getAttendanceReport = createAsyncThunk(
  "humanResource/getAttendanceReport",
  async (
    data: {
      from_date: string;
      to_date: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.get(
        `${ADMIN_API_URL}/device/attendance/emp-check-in-out/?from_date=${data.from_date}&to_date=${data.to_date}`
      );
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      dispatch(
        setSnackbar(true, "warning", "Failed to fetch attendance report.")
      );
      return rejectWithValue(null);
    }
  }
);

export const getStaffAttendanceReport = createAsyncThunk(
  "humanResource/getStaffAttendanceReport",
  async (
    data: {
      employee: string;
      from_date: string;
      to_date: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.get(
        `${ADMIN_API_URL}/device/attendance/emp-check-in-out/?employee=${data.employee}&from_date=${data.from_date}&to_date=${data.to_date}`
      );
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      dispatch(
        setSnackbar(true, "warning", "Failed to fetch staff attendance report.")
      );
      return rejectWithValue(null);
    }
  }
);

export const returnNepaliDateString = (
  year: number,
  month: number,
  day: number
) => {
  return `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(
    2,
    "0"
  )}`;
};
