import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Paper,
  Theme,
  createStyles,
} from "@material-ui/core";
import { useParams, useHistory } from 'react-router-dom';
import formatDate from "../../../utils/formatDate";
import { dateConverterAdToBs } from "../../../utils/dateConverter";
import ActionButton from '../../../Reusable/Buttons/ActionButton';
import BulkDeleteConfirm from "../../../Reusable/Dialogs/BulkDeleteConfirm";
import { BulkDeleteStudent, GetDisabledStudentList } from "../../../../actions/Student/Student/StudentAction";
import { RootStore } from "../../../../store";
import StudentDashLeaveReducer from "../../../../reducers/StudentDash/StudentDashLeaveReducer";
import CustomizedPopUp from '../../../Reusable/Dialogs/CustomizedPopUp';
import ResetPassword from '../../../Reusable/ResetPassword';
import { UpdateChildrenPassword } from "../../../../actions/ParentsDash/ViewChild/ViewChildAction";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      color: "#696969",
      paddingTop: "10px",
      "& .MuiTableRow-root": {
        height: "30px",
        maxHeight: "30px",
      },
      "&.MuiTableCell-root": {
        width: "50%",
      },
    },
    infobar: {
      padding: theme.spacing(2),
    },
    roota: {
      marginBottom: "1rem",
    },
  })
);

export default function Info(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { studentID } = useParams<{ studentID: string }>();

  // States
  const [itemId, setItemId] = useState<string | null>(null);
  const [deleteStudentModalOpen, setDeleteStudentModalOpen] = useState<boolean>(false);
  const [student, setStudent] = useState<any>([]);
  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");

  // UseEffect
  useEffect(() => {
    if (localStorage.getItem("role") === "Administrator") {
      dispatch(GetDisabledStudentList())
    }
  }, [])
  const disabledStudents = useSelector((state: RootStore) => state.student.disabled_student_list);

  useEffect(() => {
    const disabledStudent = disabledStudents.filter((item: any) => item.id === studentID);
    setStudent(disabledStudent);
  }, [disabledStudents])

  const rows = [
    createData("Email Address", props.studentData?.student_user?.email),
    createData("Blood Group", props.studentData?.blood_group_display),
    createData("Gender", props.studentData?.gender),
    createData(
      "Phone Number",
      props.studentData?.phone_number ? props.studentData?.phone_number : "-"
    ),
    createData("Religion", props.studentData?.religion),
    createData("Type", props.studentData?.category_name),
    createData(
      "Admission Date",
      props.studentData?.created_on &&
      dateConverterAdToBs(formatDate(props.studentData?.created_on))
    ),
    createData("Medical History", props.studentData?.medical_history),
  ];

  function createData(name: string, infoData: string) {
    return { name, infoData };
  }

  // Delete Action
  const handleDeleteStudentModal = (value: boolean, id: string) => {
    setItemId(id);
    setDeleteStudentModalOpen(true);
  }

  const handleDeleteStudentModalClose = () => {
    setDeleteStudentModalOpen(false);
  };

  const onStudentDeleteConfirmed = (confirmed: boolean) => {
    const finalValues = {
      id: itemId,
    };
    dispatch(BulkDeleteStudent(finalValues));
    setDeleteStudentModalOpen(false);
    history.push("/student");
  };

  // Reset Action
  const handleResetPasswordModal = () => {
    setResetPasswordModalOpen(true);
  }
  const handleResetPasswordModalClose = () => {
    setResetPasswordModalOpen(false);
  }
  const onSubmit = () => {
    if (password === "" || confirmPassword === "") {
      setError(true);
      setErrorMsg("Password cannot be empty.")
    } else if (password !== confirmPassword) {
      setError(true);
      setErrorMsg("Passwords must match")
    } else if (password === confirmPassword) {
      setError(false);
      const data = {
        'user': props.studentData.student_user.id,
        'password1': password,
        'password2': confirmPassword
      }
      dispatch(UpdateChildrenPassword(data))
      setPassword("");
      setConfirmPassword("");
      setResetPasswordModalOpen(false);
    }
  }

  return (
    <>
      <Paper className={classes.infobar} elevation={1}>
        <TableContainer className={classes.roota}>
          <Typography align="left" variant="h5" component="h3">
            Student Info
          </Typography>
          <Table
            size="small"
            className={classes.table}
            aria-label="simple table"
          >
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.name}>
                  <TableCell
                    className={classes.table}
                    component="th"
                    scope="row"
                  >
                    {row.name}
                  </TableCell>
                  <TableCell align="left">{row.infoData}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {localStorage.getItem("role") === "Parent" && <ActionButton title="Reset Password" onClick={handleResetPasswordModal} style={{ backgroundColor: "blue", fontSize: "16px", borderRadius: "0px", padding: "10px", color: "white", marginTop: "1em" }}>Reset Password</ActionButton>}

          {student.length === 1 && <ActionButton title="Delete student" onClick={() => handleDeleteStudentModal(true, studentID)} style={{ backgroundColor: "blue", fontSize: "16px", borderRadius: "0px", padding: "10px", color: "white", marginTop: "1em" }}>Delete</ActionButton>}

        </TableContainer>
      </Paper>
      <BulkDeleteConfirm
        open={deleteStudentModalOpen}
        onClose={handleDeleteStudentModalClose}
        deleteConfirmed={onStudentDeleteConfirmed}
      />
      <CustomizedPopUp
        title="Reset Password"
        open={resetPasswordModalOpen}
        onClose={handleResetPasswordModalClose}
        actionConfirmed={onSubmit}
        dialogTitle="Reset Password"
        okText="Submit"
      >
        <ResetPassword
          setPassword={setPassword}
          setConfirmPassword={setConfirmPassword}
          password={password}
          confirmPassword={confirmPassword}
          error={error}
          errorMsg={errorMsg}
        />
      </CustomizedPopUp>
    </>
  );
}
