// ---------------------------- <START> module imports start ----------------------------------//
import React, { useState, useEffect } from 'react';
import {
  TableBody,
  TextField,
  Grid,
  Typography,
  Paper,
  LinearProgress,
} from '@material-ui/core';
import {
  VisibilityOutlined,
  EditOutlined,
  DeleteForeverOutlined,
  PrintOutlined,
} from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import { useTableStyles } from '../../Styles/TableStyles';
import { useTable } from '../../Reusable';
import TableActionButtons from '../../Reusable/Buttons/TableActionButtons';
import CustomizedDialogs from '../../Reusable/Dialogs/DeleteDialog';
import ActionButton from '../../Reusable/Buttons/ActionButton';
import Popups from '../../Reusable/Dialogs/Popups';
import {
  FeeTypeReportI,
  MonthlyFeeReportI,
} from '../../../actions/Fees/FeeLedger/FeeLedgerActionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import { GetMonthlyFeeReport } from '../../../actions/Fees/FeeLedger/FeeLedgerAction';
// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//
interface HeadCellsI {
  id: string;
  label: string;
}
// ---------------------------- <END> interface ends ----------------------------------//

// ---------------------------- <START> styling starts ----------------------------------//

// ---------------------------- <END> styling ends ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: 'month', label: 'Month' },
  { id: 'student', label: 'No of Students' },
  { id: 'amount', label: 'Total Amount' },
  { id: 'due', label: 'Due Amount' },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const MonthlyFeeReportList: React.FC = () => {
  const classes = useTableStyles();

  // States declaration
  const [tableData, setTableData] = useState<MonthlyFeeReportI[]>([]);

  // Retrieving re-usable components from useTable
  const {
    TblContainer,
    TblHead,
    StyledTableCell,
    StyledTableRow,
    exportToExcel,
    tablePrint,
  } = useTable(headCells);

  // Event function for table searching by name
  // Modal States
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch();
  const feeReportSelector = useSelector((state: RootStore) => state.fee_ledger);

  useEffect(() => {
    dispatch(GetMonthlyFeeReport());
  }, []);

  useEffect(() => {
    const data = feeReportSelector.monthlyFee_report;
    setTableData(data);

    setLoading(feeReportSelector.loading);
  }, [feeReportSelector]);

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && console.log('item_id: ', itemId);
    setDeleteModalOpen(false);
  };
  const [openPopup, setOpenPopup] = useState(false);
  const handleClose = (value: boolean) => {
    setOpenPopup(value);
  };

  return (
    <>
      <Paper className={classes.rootTableBox}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">Monthly Fee Report</Typography>
          </Grid>
        </Grid>
        <div className={classes.tableWrapper}>
          <Grid container justifyContent="flex-end">
            <div className={classes.tableTop}>
              <TableActionButtons
                exportToExcel={exportToExcel}
                tablePrint={tablePrint}
              />
            </div>
          </Grid>
          <TblContainer>
            <TblHead />
            <TableBody>
              {tableData.map((item: MonthlyFeeReportI, index: number) => (
                <StyledTableRow key={index}>
                  <StyledTableCell align="center" className={classes.cell}>
                    {item.month}
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.cell}>
                    {item.student}
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.cell}>
                    Rs. {item.amount}
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.cell}>
                    Rs. {item.due_amount}
                  </StyledTableCell>
                  {/* <StyledTableCell align="center" className={classes.cell}>
                                            <ActionButton
                                                title="View"
                                                onClick={() => setOpenPopup(true)}
                                            >
                                                <VisibilityOutlined className={classes.viewIcon} />
                                            </ActionButton>
                                        </StyledTableCell> */}
                </StyledTableRow>
              ))}
            </TableBody>
          </TblContainer>
          {loading ? <LinearProgress /> : null}
        </div>
      </Paper>

      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
        dialogTitle="Are you sure to delete this record?"
        okText="Delete"
      />

      <Popups
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        title="Subject Marks of Amar Karki"
        colored
      >
        <h1>nothing nothing</h1>
      </Popups>
    </>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default MonthlyFeeReportList;
