import React from "react";
import { InputLabel, TextField, Box } from "@material-ui/core";
import { useFormStyles } from "../../Styles/FormStyles";
import { Autocomplete } from "@material-ui/lab";
import clsx from "clsx";

const InputBox = (props: any) => {
  const { children, width } = props;
  return (
    <Box
      mr={1}
      fontSize={13}
      color="text.primary"
      width={width ? width : "auto"}
    >
      {children}
    </Box>
  );
};

const RowSelect = (props: any) => {
  const {
    label,
    name,
    placeholder,
    required,
    error,
    disabled,
    fullWidth,
    ...rest
  } = props;
  const classes = useFormStyles();

  return (
    <>
      <InputBox>
        {label} {required && <span style={{ color: "red" }}>*</span>}
      </InputBox>
      <Autocomplete
        classes={{
          input: classes.smallfont,
          option: classes.smallfont,
        }}
        onChange={(event: React.ChangeEvent<{}>, value: any) => {
          props.setValue(value);
        }}
        value={props.value}
        options={props.options}
        disabled={disabled}
        fullWidth={fullWidth}
        multiple={props.multiple || false}
        // openOnFocus={true}
        // getOptionLabel={(option) => option.value?.toLowerCase()}
        getOptionLabel={(option) => option.value}
        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            value={props.value}
            error={!props.value && error}
            helperText={!props.value && error}
            // placeholder={placeholder}
            variant="outlined"
            {...rest}
            placeholder="Label"
          />
        )}
      />
    </>
  );
};

const CustomizedSelect = (props: any) => {
  const {
    label,
    name,
    placeholder = "Label",
    required,
    error,
    disabled,
    direction,
    fullWidth,
    ...rest
  } = props;
  const classes = useFormStyles();

  return (
    <Box
      className={clsx(
        props.size === "medium" ? classes.formWrapperMd : classes.formWrapper
      )}
    >
      <InputLabel>
        {label}
        {required && <span style={{ color: "red" }}>*</span>}
      </InputLabel>

      <Autocomplete
        onChange={(event: React.ChangeEvent<{}>, value: any) => {
          props.setValue(value);
          props.clearErrors && props?.clearErrors(name)
        }}
        value={props.value}
        options={props.options}
        disabled={disabled}
        fullWidth={fullWidth}
        // openOnFocus={true}
        // getOptionLabel={(option) => option.value?.toLowerCase()}
        getOptionLabel={(option) => option.value}
        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            value={props.value}
            error={Boolean(!props.value && error)}
            helperText={!props.value && error}
            variant="outlined"
            {...rest}
            placeholder={props.placeholder ? props.placeholder : "Label"}
          />
        )}
      />
    </Box>
  );
};

export default CustomizedSelect;
export { RowSelect, InputBox };
