// ---------------------------- <START> module imports start ----------------------------------//
import React, { useEffect, useState } from "react";
import { Grid, Typography, Paper, Chip, Dialog } from "@material-ui/core";
import {
  VisibilityOutlined,
  PrintOutlined,
  SearchOutlined,
  SendOutlined,
} from "@material-ui/icons";
import { useTableStyles } from "../../Styles/TableStyles";
import { useTable } from "../../Reusable";
import TableActionButtons from "../../Reusable/Buttons/TableActionButtons";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import ActionButton from "../../Reusable/Buttons/ActionButton";
import FeeLedgerForm from "./FeeLedgerFrom";
import Popups from "../../Reusable/Dialogs/Popups";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import {
  GetFeeLedgerByDate,
  GetFeeLedgerReport,
} from "../../../actions/Fees/FeeLedger/FeeLedgerAction";
import {
  dateConverterAdToBs,
  dateConverterBsToAd,
} from "../../utils/dateConverter";
import { FeeLedgerTypeI } from "../../../actions/Fees/FeeLedger/FeeLedgerActionTypes";
import InvoiceModal from "./FeeLedgerModal/InvoiceModal";
import InvoiceTable from "./FeeLedgerModal/InvoiceTable";
// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//
interface HeadCellsI {
  id: string;
  label: string;
}
// ---------------------------- <END> interface ends ----------------------------------//

// ---------------------------- <START> styling starts ----------------------------------//

// ---------------------------- <END> styling ends ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "date", label: "Date" },
  { id: "student_name", label: "Student Name" },
  { id: "class", label: "Class" },
  { id: "total", label: "Total" },
  { id: "due", label: "Due Amount" },
  { id: "action", label: "Actions" },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const FeeLedgerList: React.FC = () => {
  const classes = useTableStyles();

  // States declaration
  const [tableData, setTableData] = useState<FeeLedgerTypeI[]>([]);

  // Retrieving re-usable components from useTable
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  // Function for handling action icons click event
  // Modal States
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<number | null>(null);

  const [printOpen, setPrintOpen] = useState<boolean>(false);

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    setDeleteModalOpen(false);
  };
  const [openPopup, setOpenPopup] = useState(false);
  const handleClose = (value: boolean) => {
    setOpenPopup(value);
  };

  const [state, setState] = useState({
    name: "",
    class: "",
  });
  // Modal States
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch();
  const feeLedgerState = useSelector((state: RootStore) => state.fee_ledger);

  useEffect(() => {
    dispatch(GetFeeLedgerReport());
  }, []);

  useEffect(() => {
    const data = feeLedgerState.fee_ledger;
    setLoading(feeLedgerState.loading);
    setTableData(data);
  }, [feeLedgerState]);

  const handleViewDetails = (
    sID: string,
    ledger_date: string,
    student_info: { student_name: string; class: string }
  ) => {
    setState({
      name: student_info.student_name,
      class: student_info.class,
    });
    dispatch(GetFeeLedgerByDate(sID, ledger_date));
    setOpenPopup(true);
  };

  const handlePrintInvoice = (sID: string, ledger_date: string) => {
    dispatch(GetFeeLedgerByDate(sID, ledger_date));
    setPrintOpen(true);
  };

  const getCustomTableRow = (item: FeeLedgerTypeI) => {
    return (
      <StyledTableRow key={item.student_id}>
        <StyledTableCell align="center" className={classes.cell}>
          {dateConverterAdToBs(item.created_on)}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {/* <Grid container spacing={1} alignItems="center"> */}
          {/* <Grid item>
              <img
              className={classes.circleImageAvatar}
              src={item.student_photo}
            />
            </Grid> */}
          <Grid item>
            {item.student_first_name} {item.student_last_name}
          </Grid>
          {/* </Grid> */}
        </StyledTableCell>

        <StyledTableCell align="center" className={classes.cell}>
          {item.grade} {item.section ? `${item.section}` : null}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          Rs. {item.total_paid}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          Rs. {item.due_amount}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ActionButton
            title="View Details"
            onClick={() =>
              handleViewDetails(item.student_id, item.created_on, {
                student_name:
                  item.student_first_name + " " + item.student_last_name,
                class: item.grade + " " + item.section,
              })
            }
          >
            <VisibilityOutlined className={classes.viewIcon} />
          </ActionButton>
          <ActionButton
            title="Print Invoice"
            onClick={() => handlePrintInvoice(item.student_id, item.created_on)}
          >
            <PrintOutlined className={classes.printIcon} />
          </ActionButton>
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <>
      <FeeLedgerForm />
      <Paper className={classes.rootTableBox}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">Invoice List</Typography>
          </Grid>
        </Grid>
        <TableContainer
          loading={loading}
          items={tableData}
          headCells={headCells}
          getCustomTableRow={getCustomTableRow}
          search_name="student_name"
          rowsPerPage={7}
        />
      </Paper>

      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
        dialogTitle="Are you sure to delete this record?"
        okText="Delete"
      />

      <Popups
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        colored
      >
        <InvoiceTable studentInfo={state} />
      </Popups>

      <Dialog
        open={printOpen}
        onClose={() => setPrintOpen(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        fullWidth
      >
        <InvoiceModal onClose={() => setPrintOpen(false)} editor={false} />
      </Dialog>
    </>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default FeeLedgerList;
