//function to format date and time

import { dateConverterAdToBs } from "./dateConverter";

const dateTimeFormat = (date) => {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var seconds = date.getSeconds();

  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;
  var strTime = hours + ":" + minutes + ":" + seconds + " " + ampm;
  return (
    date.getMonth() +
    1 +
    "/" +
    date.getDate() +
    "/" +
    date.getFullYear() +
    "  " +
    strTime
  );
};

const timeFormat = (time) => {
  const new_time = time.split(":");
  var hours = new_time[0];
  const minutes = new_time[1];

  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  var strTime = hours + ":" + minutes + ampm;
  return strTime;
};

const fromToConverter = (from, to) => {
  const fromDate = dateConverterAdToBs(from).split("-");
  const toDate = dateConverterAdToBs(to).split("-");
  const newFrom = `${fromDate[1]}-${fromDate[2]}`;
  const newTo = `${toDate[1]}-${toDate[2]}`;
  if (from == to) {
    return newFrom;
  } else {
    return newFrom + "/" + newTo;
  }
};

const fromToDaysConverter = (from, to) => {
  const fromDate = dateConverterAdToBs(from).split("-");
  const toDate = dateConverterAdToBs(to).split("-");
  const newFrom = `${fromDate[2]}`;
  const newTo = `${toDate[2]}`;
  if (from == to) {
    return newFrom;
  } else {
    return newFrom + "-" + newTo;
  }
};

export { timeFormat, fromToConverter, fromToDaysConverter };

export default dateTimeFormat;
