import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect, Link as RouterLink, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  ChangePassword,
  LoginAction,
  UpdateAppTheme,
} from '../../actions/Auth/Login/LoginAction';
import { RootStore } from '../../store';
import { SchoolOutlined, Visibility, VisibilityOff } from '@material-ui/icons';
import { IconButton, InputAdornment, InputLabel } from '@material-ui/core';

import AuthenticationLayout from '../Reusable/Layouts/AuthenticationLayout';
import { LogoutAction } from '../../actions/Auth/Logout/LogoutAction';
import { setSnackbar } from '../../actions/SnackbarAction';

const useStyles = makeStyles((theme) => ({
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .MuiFormControlLabel-root': {
      marginLeft: '-5px !important',
    },
  },
  avatar: {
    fontSize: '45px',
    color: theme.palette.primary.main,
    verticalAlign: 'bottom',
    marginRight: theme.spacing(1),
  },
  form: {
    padding: '50px 40px 30px 40px',
    width: '100%', // Fix IE 11 issue.
    backgroundColor: 'white',
  },
  submit: {
    padding: '12px 0 !important',
  },
  title: {
    fontSize: '2rem',
    flexGrow: 1,
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  checkbox: {
    marginLeft: '-6px',
  },
}));
interface State {
  password: string;
  password1: string;
  showPassword: boolean;
  showPassword1: boolean;
}

interface LoginFormI {
  password: string;
  password1: string;
}

const PasswordSetup = ({ ...props }: any) => {
  const { location } = props;
  const { user_id } = useParams<{ user_id: string }>();

  const classes = useStyles();
  const [redirectLogin, setRedirectLogin] = useState<boolean>(false);
  const [redirect, setRedirect] = useState<boolean>(false);
  const dispatch = useDispatch();
  const authState = useSelector((state: RootStore) => state.auth.authenticated);
  const userState = useSelector((state: RootStore) => state.auth.user);
  const validationState = useSelector(
    (state: RootStore) => state.auth.password_validated
  );
  const loadingState = useSelector((state: RootStore) => state.auth.loading);
  const { register, handleSubmit, setError, errors } = useForm<LoginFormI>();

  const [values, setValues] = React.useState<State>({
    password: '',
    password1: '',
    showPassword: false,
    showPassword1: false,
  });

  // useEffect(() => {
  //     dispatch(setSnackbar(true, "warning", "New users are kindly requested to setup a new password."))
  // }, [])

  useEffect(() => {
    if (!loadingState) {
      if (!validationState) {
        if (userState?.id !== user_id) {
          dispatch(LogoutAction());
          setRedirectLogin(true);
        }
      }
    }
  }, [userState]);

  useEffect(() => {
    if (authState && validationState) {
      setRedirect(true);
    }
  }, [authState, validationState]);

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleClickShowPassword1 = () => {
    setValues({ ...values, showPassword1: !values.showPassword1 });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleMouseDownPassword1 = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const onSubmit = (data: LoginFormI) => {
    if (data.password.length > 5) {
      if (data.password !== data.password1) {
        dispatch(setSnackbar(true, 'warning', 'Passwords did not match'));
      } else {
        const post_data = {
          user: user_id,
          password1: data.password,
          password2: data.password1,
        };
        dispatch(ChangePassword(post_data));
      }
    } else {
      dispatch(
        setSnackbar(
          true,
          'warning',
          'Password must be at least 6 characters long'
        )
      );
    }
  };

  if (redirectLogin) {
    return <Redirect to="/login" />;
  }

  if (redirect) {
    return <Redirect to="/" />;
  }

  const handleRedirectLogin = () => {
    dispatch(LogoutAction());
    setRedirectLogin(true);
  };

  return (
    <React.Fragment>
      <AuthenticationLayout title="Setup Password" width={480}>
        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel>User: {userState && userState.username}</InputLabel>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <InputLabel>Password</InputLabel>
                </Grid>
              </Grid>

              <TextField
                variant="filled"
                margin="dense"
                required
                fullWidth
                name="password"
                // label="Password"
                type={values.showPassword ? 'text' : 'password'}
                id="password"
                inputRef={register}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <InputLabel>Confirm Password</InputLabel>
                </Grid>
              </Grid>

              <TextField
                variant="filled"
                margin="dense"
                required
                fullWidth
                name="password1"
                // label="Password"
                type={values.showPassword1 ? 'text' : 'password'}
                id="password1"
                inputRef={register}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword1}
                        onMouseDown={handleMouseDownPassword1}
                      >
                        {values.showPassword1 ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Proceed
              </Button>
            </Grid>
          </Grid>
        </form>
        <Grid item xs={12}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleRedirectLogin}
          >
            Go Back
          </Button>
        </Grid>
      </AuthenticationLayout>
    </React.Fragment>
  );
};

export default PasswordSetup;
