// ---------------------------- <START> module imports start ----------------------------------//
import React, { useState, useEffect } from "react";
import { Grid, Typography, Paper, Tooltip } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { useTableStyles } from "../../Styles/TableStyles";
import { useTableWithPagination } from "../../Reusable";
import { Redirect } from "react-router-dom";
import { NoteAdd } from "@material-ui/icons";
import GuardianSearch from "./GuardianSearch";
import { RootStore } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import {
  GetGuardian,
  DeleteGuardian,
} from "../../../actions/Student/Guardian/GuardianAction";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import defaultBase64 from "../ParentForm/defaultBase64";
import {
  ItemDeleteButton,
  ItemEditButton,
  ItemViewButton,
} from "../../Reusable/Buttons/TableButton";
import UserAvatar from "../../Reusable/Profile/UserAvatar";
import Popups from "../../Reusable/Dialogs/Popups";
import GuardianViewModal from "./GuardianViewModal";
import { set } from "date-fns";
import defaultPlaceholder from "../../../assets/images/defaultPlaceholder";
import checkImageUrl from "../../utils/checkImageUrl";
import getFullName from "../../utils/getFullName";
import AccessPermission from "../StudentDetail/AccessPermission";

// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//
interface TableDataI {
  id: number;
  guardian_photo: string;
  guardian_name: string;
  address: string;
  phone: number;
  secondaryGuardian_name: string;
  secondaryGuardian_photo: string;
  secondaryGuardian_address: string;
  secondaryGuardian_Phone: number;
}

interface HeadCellsI {
  id: string;
  label: string;
}
// ---------------------------- <END> interface ends ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N" },
  { id: "guardian_name", label: "Guardian Name" },
  { id: "address", label: "Address" },
  { id: "phone", label: "Phone" },
  { id: "secondaryGuardian_name", label: "Secondary Name" },
  { id: "secondaryGuardian_address", label: "Secondary Address" },
  { id: "secondaryGuardian_phone", label: "Secondary Phone" },
  { id: "action", label: "Action" },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Guardian List Component Starts ---------------------------//
const GuardianList: React.FC = () => {
  const classes = useTableStyles();
  const history = useHistory();

  // States declaration
  const [tableData, setTableData] = useState<TableDataI[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [guardianUpdateClicked, setGuardianUpdateClicked] = useState(false);
  const [guardianID, setGuardianID] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  // const [parent, setParents] = useState<any>([]);
  const [parentViewClicked, setParentViewClicked] = useState(false);
  // Delete record modal
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<number | null>(null);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetGuardian(1));
  }, []);
  const guardianLoading = useSelector(
    (state: RootStore) => state.guardians.loading
  );
  useEffect(() => {
    setLoading(guardianLoading);
  }, [guardianLoading]);
  const guardianData = useSelector((state: RootStore) => state.guardians);

  useEffect(() => {
    if (guardianData.guardians?.results != null) {
      const data: any = [];
      guardianData.guardians?.results.map((elem: any, index: number) => {
        data.push({
          id: elem.id,
          guardian_photo: elem?.photo
            ? checkImageUrl(elem?.photo)
            : defaultPlaceholder,
          guardian_name: getFullName(
            elem.guardian_user?.first_name,
            elem.guardian_user?.last_name
          ),
          address: elem?.address,
          phone: elem.phone_number,
          secondaryGuardian_photo:
            elem.secondary_full_name != null
              ? elem.secondary_photo
                ? checkImageUrl(elem.secondary_photo)
                : defaultPlaceholder
              : null,
          secondaryGuardian_name: elem.secondary_full_name,
          secondaryGuardian_address: elem.secondary_address,
          secondaryGuardian_Phone: elem.secondary_phone_number,
        });
      });
      setTableData(data);
    }
  }, [guardianData]);
  // Retrieving re-usable components from useTable
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTableWithPagination(headCells);

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteGuardian(itemId.toString()));
    setDeleteModalOpen(false);
  };

  const handleUpdateView = (id: any) => {
    setGuardianID(id);
    setGuardianUpdateClicked(true);
  };

  const handleDeleteModal = (value: boolean, id: number) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };

  if (guardianUpdateClicked) {
    return <Redirect to={`/guardian/edit/${guardianID}`} />;
  }

  if (parentViewClicked) {
    return <Redirect to={`guardian-details/profile/${guardianID}`} />;
  }

  const handleView = (item: any) => {
    setGuardianID(item.id);
    // setParents(item);
    // setOpenPopup(true);
    setParentViewClicked(true);
  };

  const handleClose = (value: boolean) => {
    setOpenPopup(value);
  };

  const getCustomTableRow = (item: TableDataI, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <UserAvatar name={item.guardian_name} src={item.guardian_photo} />
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.address}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.phone}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.secondaryGuardian_name && (
            <UserAvatar
              name={item.secondaryGuardian_name}
              src={item.secondaryGuardian_photo}
            />
          )}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.secondaryGuardian_address}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.secondaryGuardian_Phone}
        </StyledTableCell>{" "}
        <StyledTableCell align="center" className={classes.cell}>
          <ItemViewButton onClick={() => handleView(item)} />
          <AccessPermission>
            <ItemEditButton onClick={() => handleUpdateView(item.id)} />
            <ItemDeleteButton
              onClick={() => handleDeleteModal(true, item.id)}
            />
          </AccessPermission>
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  const handlePaginationChange = (paginationIndex: number) => {
    dispatch(GetGuardian(paginationIndex));
  };

  const handleImport = () => {
    history.push("/import-student-info");
  };

  return (
    <>
      <GuardianSearch />
      <Paper className={classes.rootTableBox}>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h6">Guardian Lists</Typography>
          </Grid>
        </Grid>

        <TableContainer
          label="Guardian list"
          loading={loading}
          items={tableData}
          headCells={headCells}
          getCustomTableRow={getCustomTableRow}
          search_name="guardian_name"
          forPagination={guardianData?.guardians || []}
          handlePaginationChange={handlePaginationChange}
          onImport={handleImport}
          disableSearch
        />
        <Popups
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          onClose={handleClose}
          title="Guardian Details"
          colored
          scroll="body"
          maxWidth="md"
          fullWidth
        >
          <GuardianViewModal item={parent} />
        </Popups>
        <CustomizedDialogs
          open={deleteModalOpen}
          onClose={handleModalClose}
          deleteConfirmed={onDeleteConfirmed}
        />
      </Paper>
    </>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default GuardianList;
