import React from 'react';
import {
  Button,
  Content,
  Header,
  ListText,
  ProgressBar,
  StyledStudentCard,
  Text,
  Title,
} from './styles';

export default function ExploreCard({ children, ...restProps }: any) {
  return <StyledStudentCard {...restProps}>{children}</StyledStudentCard>;
}

ExploreCard.Content = function ExploreCardContent({
  children,
  ...restProps
}: any) {
  return <Content {...restProps}>{children}</Content>;
};

ExploreCard.Header = function ExploreCardHeader({
  children,
  ...restProps
}: any) {
  return <Header {...restProps}>{children}</Header>;
};

ExploreCard.Title = function ExploreCardTitle({ children, ...restProps }: any) {
  return <Title {...restProps}>{children}</Title>;
};

ExploreCard.Button = function ExploreCardButton({
  children,
  ...restProps
}: any) {
  return <Button {...restProps}>{children}</Button>;
};

ExploreCard.Text = function ExploreCardText({ children, ...restProps }: any) {
  return <Text {...restProps}>{children}</Text>;
};

ExploreCard.ListText = function ExploreCardListText({
  children,
  ...restProps
}: any) {
  return <ListText {...restProps}>{children}</ListText>;
};

ExploreCard.ProgressBar = function ExploreCardProgressBar({
  children,
  ...restProps
}: any) {
  return <ProgressBar {...restProps}>{children}</ProgressBar>;
};
