import React, { useState } from "react";
import { useSidebarStyles } from "../Styles/SidebarStyles";
import { QueueOutlined, ReceiptOutlined } from "@material-ui/icons";
import SidebarTitle from "../Reusable/Titles/SidebarTitle";
import { useSelector } from "react-redux";
import { RootStore } from "../../store";
import { PageRoleAccess } from "../utils/rolesConfig";
import useSidebarFinance from "../Reusable/useSidebarFinance";
import { useSidebar } from "../Reusable";

interface PropsI {
  collect_fees?: boolean;
  fee_statement?: boolean;
  fee_income?: boolean;
  discount_summary?: boolean;
  day_book?: boolean;
  fee_ledger?: boolean;
  apply_fee?: boolean;
  fee_type?: boolean;
  opening_balance?: boolean;
  misc_fee?: boolean;
  fees?: boolean;
  due_report?: boolean;
  due_alert?: boolean;
  void_report?: boolean;
  discount_type?: boolean;
  payment_method?: boolean;
  fine_type?: boolean;
  fee_type_report?: boolean;
  monthly_report?: boolean;
  Income?: boolean | undefined;
  Expenses?: boolean | undefined;
  AddIncome?: boolean | undefined;
  AddExpenses?: boolean | undefined;
  bill_adjustment?: boolean | undefined;
  discount?: boolean | undefined;
  applied_fees?: boolean | undefined;
  intimation_slip?: boolean | undefined;
}

interface DataSetI {
  text: string;
  url: string;
  activeStatus: boolean | undefined;
  permission?: string;
}

const FinanceSidebar = (props: PropsI) => {
  const classes = useSidebarStyles();

  const [collect_fees] = useState(props.collect_fees);
  const [day_book] = useState(props.day_book);
  const [fee_ledger] = useState(props.fee_ledger);
  const [discount_summary] = useState(props.discount_summary);
  const [apply_fee] = useState(props.apply_fee);
  const [opening_balance] = useState(props.opening_balance);
  const [misc_fee] = useState(props.misc_fee);
  const [fee_income] = useState(props.fee_income);
  const [fee_type] = useState(props.fee_type);
  const [fees] = useState(props.fees);
  const [due_report] = useState(props.due_report);
  const [due_alert] = useState(props.due_alert);
  const [void_report] = useState(props.void_report);
  const [fine_type] = useState(props.fine_type);
  const [discount_type] = useState(props.discount_type);
  const [payment_method] = useState(props.payment_method);
  const [fee_type_report] = useState(props.fee_type_report);
  const [monthly_report] = useState(props.monthly_report);
  const [income] = useState(props.Income);
  const [expenses] = useState(props.Expenses);
  const [addIncome] = useState(props.AddIncome);
  const [addExpenses] = useState(props.AddExpenses);
  const [fee_statement] = useState(props.fee_statement);
  const [bill_adjustment] = useState(props.bill_adjustment);
  const [discount] = useState(props.discount);
  const [applied_fees] = useState(props.applied_fees);
  const [intimation_slip] = useState(props.intimation_slip);

  const authState = useSelector((state: RootStore) => state.auth);

  const dataSet: DataSetI[] = [
    {
      text: "Fee Head",
      url: "fee-type",
      activeStatus: fee_type,
      permission: "fee_type",
    },
    {
      text: "Fee Configuration",
      url: "fees",
      activeStatus: fees,
      permission: "fee_config",
    },
    {
      text: "Apply Fee",
      url: "apply-fee",
      activeStatus: apply_fee,
      permission: "apply_fee",
    },
    {
      text: "Applied Fee",
      url: "applied-fees",
      activeStatus: applied_fees,
      permission: "applied_fees",
    },
    {
      text: "Apply Discount",
      url: "apply-discount",
      activeStatus: discount,
      permission: "discount",
    },
    {
      text: "Opening Balance",
      url: "opening-balance",
      activeStatus: opening_balance,
      permission: "opening_balance",
    },
    {
      text: "Intimation Slip",
      url: "intimation-slip",
      activeStatus: intimation_slip,
      permission: "intimation_slip",
    },
    {
      text: "Collect Fees",
      url: "collect-fees",
      activeStatus: collect_fees,
      permission: "collect_fee",
    },

    {
      text: "Fine Type",
      url: "fine-type",
      activeStatus: fine_type,
      permission: "fine_type",
    },
    {
      text: "Miscellaneous Fee",
      url: "miscellaneous-fee",
      activeStatus: misc_fee,
      permission: "misc_fee",
    },
    {
      text: "Bill Adjustment",
      url: "bill-adjustment",
      activeStatus: bill_adjustment,
      permission: "bill_adjustment",
    },
    // {
    //   text: "Bill Adjustment",
    //   url: "miscellaneous-fee",
    //   activeStatus: misc_fee,
    //   permission: "misc_fee",
    // },
    // {
    //   text: "Discount Type",
    //   url: "discount-type",
    //   activeStatus: discount_type,
    //   permission: "discount_type",
    // },
    {
      text: "Payment Method",
      url: "payment-method",
      activeStatus: payment_method,
      permission: "payment_method",
    },
    // {
    //   text: "Income Type",
    //   url: "income",
    //   activeStatus: income,
    //   permission: "income_type",
    // },
    // {
    //   text: "Add Income",
    //   url: "add-income",
    //   activeStatus: addIncome,
    //   permission: "add_income",
    // },
    // {
    //   text: "Expense Type",
    //   url: "expenses",
    //   activeStatus: expenses,
    //   permission: "expense_type",
    // },
    // {
    //   text: "Add Expense",
    //   url: "add-expenses",
    //   activeStatus: addExpenses,
    //   permission: "add_expense",
    // },
  ];

  const secondaryDataSet: DataSetI[] = [
    {
      text: "Day Book",
      url: "day-book",
      activeStatus: day_book,
      permission: "day_book",
    },
    {
      text: "Fee Statement",
      url: "fee-statement",
      activeStatus: fee_statement,
      permission: "fee_statement",
    },
    // {
    //   text: 'Due Report',
    //   url: 'due-report',
    //   activeStatus: due_report,
    //   permission: 'due_report',
    // },
    {
      text: "Due Alert",
      url: "due-alert",
      activeStatus: due_alert,
      permission: "due_alert",
    },
    {
      text: "Void Report",
      url: "void-report",
      activeStatus: void_report,
      permission: "void_report",
    },
    // {
    //   text: "Void Report",
    //   url: "void-report",
    //   activeStatus: void_report,
    //   permission: "void_report",
    // },
    // {
    //   text: "Fee Type Report",
    //   url: "fee-type-report",
    //   activeStatus: fee_type_report,
    //   permission: "fee_type_report",
    // },
    // {
    //   text: "Monthly Report",
    //   url: "monthly-report",
    //   activeStatus: monthly_report,
    //   permission: "monthly_report",
    // },
    // {
    //   text: 'Discount Summary',
    //   url: 'discount-summary',
    //   activeStatus: discount_summary,
    //   permission: 'discount_summary',
    // },
  ];

  // const { CustomSidebarWithPermission } = useSidebarFinance(
  //   dataSet,
  //   "FINANCE",
  //   secondaryDataSet
  // );
  const mergedDataSet = [...dataSet, ...secondaryDataSet];
  const { CustomSidebar } = useSidebar(mergedDataSet, "FINANCE");

  return (
    <div className={classes.Sidebar}>
      <div className={classes.sidebarContent}>
        <SidebarTitle icon={<QueueOutlined />}>Finance</SidebarTitle>
        <CustomSidebar />
      </div>
    </div>
  );
};

export default FinanceSidebar;
