import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { ScholarshipSidebar } from "../../components";
import ScholarshipTypeForm from "../../components/Scholarship/ScholarshipType/ScholarshipTypeForm";
import ScholarshipTypeTable from "../../components/Scholarship/ScholarshipType/ScholarshipTypeTable";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";
import { ScholarshipType } from "../../actions/ScholarShipType/ScholarshipActionTypes";

const ScholarshipTypePage = () => {
  const [formData, setFormData] = useState<ScholarshipType | null>(null);
  const [count, setCount] = useState<number>(0);
  const [edit, setEdit] = useState<any>();

  const fetchEditData = (data: ScholarshipType) => {
    setFormData({ ...data, count: count });
    setCount(count + 1);
  };

  const onEditCheck = (value: boolean) => {
    setEdit({ check: value, count: count });
  };

  const nav = <ScholarshipSidebar scholarship_type />;
  return (
    <SubModuleLayout sideNav={nav}>
      <Grid container>
        <Grid item xs={3}>
          <ScholarshipTypeForm onEditCheck={onEditCheck} editData={formData} />
        </Grid>
        <Grid item xs={9}>
          <ScholarshipTypeTable editCheck={edit} onEditData={fetchEditData} />
        </Grid>
      </Grid>
    </SubModuleLayout>
  );
};

export default ScholarshipTypePage;
