import React, { useEffect } from "react";
import { StudentTable } from "../../components";
import StudentSidebar from "../../components/Student/StudentSidebar/StudentSidebar";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";
import { breadcrumb } from "./Student.Breadcrumbs";
import { resetStudentState } from "../../actions/Student/Student/StudentAction";
import { useDispatch } from "react-redux";

const StudentListPage = () => {
  const nav = <StudentSidebar studentDetails />;
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetStudentState())
  },[])

  return (
    <SubModuleLayout module={breadcrumb} sideNav={nav}>
      <StudentTable />
    </SubModuleLayout>
  );
};

export default StudentListPage;
