import { SetSnackBarI, SET_SNACKBAR } from "../actions/SnackbarActionTypes";

interface InitialStateI {
  snackbarOpen: boolean;
  snackbarType: "success" | "info" | "warning" | "error";
  snackbarMessage: string;
}

const initialState: InitialStateI = {
  snackbarOpen: false,
  snackbarType: "success",
  snackbarMessage: "",
};

const SnackBarReducer = (
  state: InitialStateI = initialState,
  action: SetSnackBarI
): InitialStateI => {
  switch (action.type) {
    case SET_SNACKBAR:
      const { snackbarOpen, snackbarType, snackbarMessage } = action.payload;
      return {
        snackbarOpen: snackbarOpen,
        snackbarType: snackbarType,
        snackbarMessage: snackbarMessage,
      };

    default:
      return state;
  }
};

export default SnackBarReducer;
