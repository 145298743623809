/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/prop-types */
// ---------------------------- <START> module imports start ----------------------------------//
import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useTableStyles } from "../../Styles/TableStyles";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import { FineTypeI } from "../../../actions/Fees/FineType/FineActionTypes";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import { DeleteFine, GetFine } from "../../../actions/Fees/FineType/FineAction";
import VisibilityIcon from "@material-ui/icons/Visibility";
import HelpOutlineSharpIcon from "@material-ui/icons/HelpOutlineSharp";
import Dialog from "@material-ui/core/Dialog";
import SaveIcon from "@material-ui/icons/Save";
import cx from "clsx";
import {
  ItemDeleteButton,
  ItemEditButton,
} from "../../Reusable/Buttons/TableButton";
import { Autocomplete, TreeItem, TreeView } from "@material-ui/lab";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import Label from "@material-ui/icons/Label";
import { makeStyles } from "@material-ui/core/styles";
import LocalAtmOutlinedIcon from "@material-ui/icons/LocalAtmOutlined";
import CustomizedSelect from "../../Reusable/Inputs/Select";
import {
  paymentMethodChoices,
  sendSmsChoices,
  Tupple,
} from "../../../common/json.constant";
import { FormCheckBox } from "../../Reusable/Inputs/Checkbox";
import { GetPaymentMethod } from "../../../actions/Fees/PaymentMethod/PaymentMethodAction";
import CustomizedNepaliDatePicker from "../../Reusable/Inputs/NepaliDatePicker";
import { useForm } from "react-hook-form";
import formsValidationCheckup from "../../utils/formsValidationCheckUp";
import { dateConverterBsToAd } from "../../utils/dateConverter";
import {
  closeInvoicePrint,
  CollectFee,
} from "../../../actions/Fees/CollectFees/CollectFeesAction";
import FeeInvoice from "./FeeInvoice";
import { GetGeneralInfoData } from "../../../actions/GeneralSettings/GeneralInfo/GeneralInfoAction";
import { SET_SNACKBAR } from "../../../actions/SnackbarActionTypes";
import useBorderedTable from "../../Reusable/useBorderedTable";
import { LeakAddTwoTone } from "@material-ui/icons";
import DisableDialog from "../../Reusable/Dialogs/DisableDialog";
import CustomizedTextField from "../../Reusable/Inputs/TextField";
import {
  FormResetButton,
  FormSaveLoadingButton,
} from "../../Reusable/Buttons/FormButton";
import Spinner from "../../../common/spinnerSVG/Spinner";
import { useFormStyles } from "../../Styles/FormStyles";
import { CUR_NEPALI_DATE } from "../../utils/nepaliDateUtils";
import Khalti from "../../DigitalPayment/Khalti/Khalti";
// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//
interface PropsI {
  onEditData: (value: FineTypeI) => void;
  editCheck: { check: boolean; count: number };
}

interface HeadCellsI {
  id: string;
  label: string;
  width?: string;
}
// ---------------------------- <END> interface ends ----------------------------------//

// ---------------------------- <START> styling starts ----------------------------------//

// ---------------------------- <END> styling ends ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N." },
  { id: "fee_head", label: "Fees Head" },
  { id: "period", label: "Period" },
  { id: "charges", label: "Charges" },
  { id: "fine_type", label: "Fine Type" },
  { id: "fine_amount", label: "Fine Amount" },
  { id: "balance", label: "Balance" },
  { id: "paid", label: "Paid" },
];

const miscHeadCells: HeadCellsI[] = [
  { id: "misc_name", label: "Misc Fee Name" },
  { id: "misc_amount", label: "Misc Amount" },
];

const dueHeadCells: HeadCellsI[] = [
  { id: "due_name", label: "Due Fee Name" },
  { id: "period", label: "Period" },
  { id: "charges", label: "Charges" },
  { id: "receipt", label: "Previous Receipt" },
  { id: "due_amount", label: "Due Amount" },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

const useTreeItemStyles = makeStyles((theme: any) => ({
  root: {
    color: theme.palette.text.secondary,
    "&:hover > $content": {
      backgroundColor: "#e4e3e3",
    },
    "&:focus > $content, &$selected > $content": {
      backgroundColor: `#e4e3e3`,
      color: "var(--tree-view-color)",
    },
    "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label":
      {
        backgroundColor: "#e4e3e3",
      },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    "& $content": {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: "inherit",
    color: "inherit",
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: "inherit",
    flexGrow: 1,
  },
}));

function StyledTreeItem(props: any) {
  const classes = useTreeItemStyles();

  const {
    labelText,
    labelIcon: LabelIcon,
    labelInfo,
    color,
    bgColor,
    ...other
  } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LabelIcon color="inherit" className={classes.labelIcon} />
          <Typography variant="body2" className={classes.labelIcon}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        background: "#e4e3e3",
      }}
      {...other}
    />
  );
}

// ---------------------------- <START> fine Type Table Component Starts ---------------------------//
const CollectFeesList = (props: any) => {
  const classesTree = useTreeItemStyles();
  const classesForm = useFormStyles();

  const {
    selected,
    setStudent,
    feeHeadRows,
    setFeeHeadRows,
    student,
    setSelected,
    miscRows,
    setMiscRows,
    setPendingRows,
    pendingRows,
    feeHeadOptions,
    setFeeHeadOptions,
    feesHead,
  } = props;

  const classes = useTableStyles();
  // payment method reducer
  const dispatch = useDispatch();

  const { register, handleSubmit, setValue, reset, errors, clearErrors } =
    useForm();

  // Modal States
  const [loading, setLoading] = useState<boolean>(false);
  const [submitConfirm, setSubmitConfirm] = useState<boolean>(false);
  const [confirmDialog, setConfirmDialog] = useState<boolean>(false);
  const [paymentMethod, setPaymentMethod] = useState<string>("");
  const [sendSms, setSendSms] = useState<string>("");
  const [customPhoneValue, setCustomPhoneValue] = useState<string>("");
  const [customPhoneNumber, setCustomPhoneNumber] = useState<boolean>(false);
  const [smsNotification, setSmsNotification] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string>("");
  const [payment, setPayment] = useState<any>(null);
  const [paymentChoices, setPaymentChoices] = useState<any>([]);
  const [totalPayable, setTotalPayable] = useState<number>(0);
  const [tempTotalPayable, setTempTotalPayable] = useState<number>(0);
  const [totalPaying, setTotalPaying] = useState<number>(0);
  const [dueAmt, setDueAmt] = useState<number>(0);
  const [crAmt, setCrAmt] = useState<number>(0);
  const [advanceAmount, setAdvanceAmount] = useState<number>(0);
  const [discountAmt, setDiscountAmt] = useState<number>(0);
  const [subTotal, setSubTotal] = useState<number>(0);
  const [issueDate, setIssueDate] = useState<string | null>(CUR_NEPALI_DATE);
  const [onEditPaying, setOnEditPaying] = useState(false);
  const [onEditDiscount, setOnEditDiscount] = useState(false);
  const [openInvoice, setOpenInvoice] = useState(false);
  const [fineOptions, setFineOptions] = useState<any>([]);
  const [selectedMiscArray, setSelectedMisArray] = useState<any>([]);
  const [selectedPendingArray, setSelectedPendingArray] = useState<any>([]);
  const [fromOB, setFromOB] = useState<boolean>(false);
  const [remarks, setRemarks] = useState<string>("");
  const [invoiceID, setInvoiceID] = useState<string | null>("");
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const [selectedMisc, setSelectedMisc] = React.useState<string[]>([]);
  const [selectedPending, setSelectedPending] = React.useState<string[]>([]);
  const [collectedFee, setCollectedFee] = React.useState<any>(null);

  const [footerTable, setFooterTable] = useState<any>([
    {
      name: "Opening Balance",
      dr: 0.0,
      cr: 0.0,
    },

    {
      name: "Discount",
      value: 0,
    },
    {
      name: "Total Payable",
      value: totalPayable,
    },
    {
      name: "Paying Amount",
      value: 0,
    },
    {
      name: "Due Balance",
      value: 0,
    },
  ]);

  const openingBalance = useSelector(
    (state: RootStore) => state.collect_fees?.collect_fees?.opening_balance
  );

  const miscFees = useSelector(
    (state: RootStore) => state.collect_fees?.collect_fees
  );

  const isCollected = useSelector(
    (state: RootStore) => state.collect_fees?.fee_collected
  );

  const collectedFees = useSelector(
    (state: RootStore) => state.collect_fees?.collected_fee
  );

  const paymentMethodState = useSelector(
    (state: RootStore) => state.payment_method?.payment_method
  );

  const fineTypeState = useSelector((state: RootStore) => state.fine_type);

  const authData = useSelector((state: RootStore) => state.auth);

  const generalInfoData = useSelector(
    (state: RootStore) => state.general_info?.general_info
  );

  const add_or_update = useSelector(
    (state: RootStore) => state.collect_fees.add_or_update
  );

  useEffect(() => {
    if (collectedFees !== null) {
      setCollectedFee(collectedFees);
    }
  }, [collectedFees]);

  useEffect(() => {
    dispatch(GetFine());
    dispatch(GetPaymentMethod());
    setCollectedFee(null);
  }, []);

  // useEffect(() => {
  //   if (authData.general_info != null) {
  //     dispatch(GetGeneralInfoData(authData.general_info.id));
  //   }
  // }, [authData]);

  useEffect(() => {
    if (paymentMethodState !== null) {
      const data: any = [];
      paymentMethodState.map((elem: any) => {
        data.push({
          key: elem.id,
          value: elem.name,
        });
      });
      setPaymentChoices(data);
    }
  }, [paymentMethodState]);

  useEffect(() => {
    if (fineTypeState !== null) {
      setFineOptions(fineTypeState.fine);
    }
  }, [fineTypeState]);

  useEffect(() => {
    if (add_or_update) {
      setIsBtnLoading(false);
    }
  }, [add_or_update]);

  // -----------------<END> Pagination states and functions <END>-----------------------//

  // Retrieving re-usable components from useTable
  const { TableContainer, StyledTableCell, StyledTableRow } =
    useBorderedTable(headCells);

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const handleDeleteModal = (value: boolean, id: string) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };

  const handleFineTypeChange = (value: any, id: string) => {
    const findFine_ = fineOptions?.find((elem: any) => elem.id === value);
    const feeHeadRows_ = feeHeadRows;
    const temp = feeHeadRows_?.map((elem: any) =>
      elem.invoice_id === id
        ? {
            ...elem,
            fine: value,
            fine_amount:
              findFine_?.fine_in === "P"
                ? (
                    (Number(findFine_?.fine_applicable) / 100) *
                    Number(elem.charges)
                  ).toFixed(2)
                : findFine_?.fine_applicable,
            amount: !findFine_
              ? elem.charges
              : findFine_?.fine_in === "P"
              ? (
                  Number(elem.charges) +
                  (Number(findFine_?.fine_applicable) / 100) *
                    Number(elem.charges)
                ).toFixed(2)
              : Number(elem.charges) + Number(findFine_?.fine_applicable),
            paid: !findFine_
              ? elem.charges
              : findFine_?.fine_in === "P"
              ? (
                  Number(elem.charges) +
                  (Number(findFine_?.fine_applicable) / 100) *
                    Number(elem.charges)
                ).toFixed(2)
              : Number(elem.charges) + Number(findFine_?.fine_applicable),
          }
        : elem
    );
    setFeeHeadRows([...temp]);
  };

  const handlePaidColChange = (value: any, id: string) => {
    const feeHeadRows_ = feeHeadRows;
    const temp = feeHeadRows_?.map((elem: any) =>
      elem.invoice_id === id
        ? {
            ...elem,
            paid: value,
          }
        : elem
    );
    setFeeHeadRows([...temp]);
  };

  const handleFeeHeadRowRemove = (id: string) => {
    const data__ = feeHeadRows;
    const select_data = selected;
    const filtered__ = data__.filter((elem: any) => elem.invoice_id !== id);
    const select_filter = select_data.filter(
      (elem: any) => elem.invoice_id !== id
    );
    setFeeHeadRows(filtered__);
    setSelected(select_filter);

    //for selected options on FeeHead
    const temp: string[] = [];

    select_filter?.map((element: any) => temp.push(element.invoice_id));

    const currentOptions: any = feeHeadOptions;
    currentOptions.map((element: any) => {
      if (temp.includes(element.invoice_id)) {
        element.selected = true;
      } else {
        element.selected = false;
      }
    });
    setFeeHeadOptions(currentOptions);

    const selectedVal = currentOptions.filter(
      (element: any) => element.selected === true
    );

    setSelected(selectedVal);
  };

  const ToolTipScholarship = (props: any) => {
    const { items } = props;

    return (
      <Grid direction="column" container>
        <Grid>
          <div style={{ padding: "6px" }}>
            Scholarship Name: {items[0]?.scholarship_type__scholarship_title}
            <br />
            Type:{" "}
            {items[0]?.scholarship_type__scholarship_in === "P"
              ? "percentage"
              : "amount"}
            <br />
            Value: {items[0]?.scholarship_type__applicable_scholarship}
          </div>
        </Grid>
      </Grid>
    );
  };

  const getCustomTableRow = (item: any, index: number) => {
    return (
      <StyledTableRow key={index}>
        <StyledTableCell align="center" className={classes.cell}>
          {index}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.fee_name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.fee_type_due_date !== "" ? item.fee_type_due_date : "Monthly"}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.charges}
          {item.discount_amount > 0 && (
            <span style={{ fontSize: "10px", paddingLeft: "5px" }}>
              (Disc.Amt: {item.discount_amount})
            </span>
          )}
          <br />
          {item.applied_scholarships?.length > 0 && (
            <Tooltip
              arrow
              title={<ToolTipScholarship items={item.applied_scholarships} />}
            >
              <span style={{ color: "green" }}>
                {item.actual_amount_to_pay}
                <span>
                  <VisibilityIcon
                    style={{
                      fontSize: "20px",
                      marginLeft: "5px",
                      marginBottom: "-5px",
                    }}
                  />
                </span>
              </span>
            </Tooltip>
          )}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <select
            className="custom-select"
            name={index.toString()}
            id={index.toString()}
            value={item.fine}
            onChange={(event: any) =>
              handleFineTypeChange(event.target.value, item?.invoice_id)
            }
          >
            <option value="" key={index}>
              --select--
            </option>
            {fineOptions?.map((elem: any, index: number) => {
              return (
                <option value={elem.id} key={index}>
                  {elem.name}
                </option>
              );
            })}
          </select>
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.fine_amount}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.amount}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <Grid style={{ display: "flex", justifyContent: "space-between" }}>
            <Grid style={{ margin: "auto" }}>
              <input
                name={index.toString()}
                id={index.toString()}
                style={{ width: "80px" }}
                type="number"
                defaultValue={item.paid}
                className="custom-input"
                onBlur={(event: any) =>
                  handlePaidColChange(event.target.value, item?.invoice_id)
                }
              />
            </Grid>
            <Grid style={{ margin: "auto" }}>
              <ItemDeleteButton
                onClick={() => handleFeeHeadRowRemove(item?.invoice_id)}
              />
            </Grid>
          </Grid>
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  const handleSelectAllClickMisc = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      const newSelecteds = miscRows.map((n: any) => n.id);
      setSelectedMisc(newSelecteds);
      setSelectedMisArray(miscRows);
      return;
    }
    setSelectedMisc([]);
    setSelectedMisArray([]);
  };

  const handleSelectAllClickPending = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      const newSelecteds = pendingRows.map((n: any) => n.id);
      setSelectedPending(newSelecteds);
      setSelectedPendingArray(pendingRows);
      return;
    }
    setSelectedPending([]);
    setSelectedPendingArray([]);
  };

  const handleCheckClickMisc = (name: string) => {
    const selectedIndex = selectedMisc.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedMisc, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedMisc.slice(1));
    } else if (selectedIndex === selectedMisc.length - 1) {
      newSelected = newSelected.concat(selectedMisc.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedMisc.slice(0, selectedIndex),
        selectedMisc.slice(selectedIndex + 1)
      );
    }
    setSelectedMisc(newSelected);

    let selectedMiscInvoices: any = [];

    if (miscRows?.length > 0) {
      const tempIDs: any[] = newSelected;
      const new_datum = miscRows.filter((item: any) =>
        tempIDs.includes(item.id)
      );
      selectedMiscInvoices = new_datum;
    } else {
      selectedMiscInvoices = [];
    }
    setSelectedMisArray(selectedMiscInvoices);
  };

  const handleCheckClickPending = (name: string) => {
    const selectedIndex = selectedPending.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedPending, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedPending.slice(1));
    } else if (selectedIndex === selectedPending.length - 1) {
      newSelected = newSelected.concat(selectedPending.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedPending.slice(0, selectedIndex),
        selectedPending.slice(selectedIndex + 1)
      );
    }
    setSelectedPending(newSelected);

    let selectedPendingInvoices: any = [];

    if (pendingRows?.length > 0) {
      const tempIDs: any[] = newSelected;
      const new_datum = pendingRows.filter((item: any) =>
        tempIDs.includes(item.id)
      );
      selectedPendingInvoices = new_datum;
    } else {
      selectedPendingInvoices = [];
    }
    setSelectedPendingArray(selectedPendingInvoices);
  };

  const isSelected = (name: string) => selectedMisc.indexOf(name) !== -1;
  const isSelectedPending = (name: string) =>
    selectedPending.indexOf(name) !== -1;

  const getMiscCustomTableRow = (item: any, index: number) => {
    const isItemSelected = isSelected(item.id);
    const labelId = `enhanced-table-checkbox-${index}`;
    const cellStyle = cx(classes.cell, classes.cellSm);

    return (
      <StyledTableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={item.id}
        aria-checked={isItemSelected}
        selected={isItemSelected}
      >
        <StyledTableCell align="left" className={cellStyle}>
          <Grid container spacing={1} alignItems="center">
            <Grid item style={{ marginLeft: "-5px" }}>
              <Checkbox
                color="default"
                checked={isItemSelected}
                inputProps={{ "aria-labelledby": labelId }}
                onClick={() => handleCheckClickMisc(item.id)}
              />
            </Grid>
            <Grid item>{item.fee_type__name}</Grid>
          </Grid>
        </StyledTableCell>
        <StyledTableCell align="center" className={cellStyle}>
          {item.fee_type__amount}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  const getPendingCustomTableRow = (item: any, index: number) => {
    const isItemSelected = isSelectedPending(item.id);
    const labelId = `enhanced-table-checkbox-${index}`;
    const cellStyle = cx(classes.cell, classes.cellSm);

    return (
      <StyledTableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={item.id}
        aria-checked={isItemSelected}
        selected={isItemSelected}
      >
        <StyledTableCell align="left" className={cellStyle}>
          <Grid container spacing={1} alignItems="center">
            <Grid item style={{ marginLeft: "-5px" }}>
              <Checkbox
                color="default"
                checked={isItemSelected}
                inputProps={{ "aria-labelledby": labelId }}
                onClick={() => handleCheckClickPending(item.id)}
              />
            </Grid>
            <Grid item>{item.due_name}</Grid>
          </Grid>
        </StyledTableCell>
        <StyledTableCell align="center" className={cellStyle}>
          {item.period}
        </StyledTableCell>
        <StyledTableCell align="center" className={cellStyle}>
          Rs. {item.charges}
        </StyledTableCell>
        <StyledTableCell align="center" className={cellStyle}>
          {item.receipt}
        </StyledTableCell>
        <StyledTableCell align="center" className={cellStyle}>
          Rs. {item.due_amount}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  useEffect(() => {
    if (openingBalance !== null) {
      const data = footerTable;
      data[0].dr = openingBalance?.debit || 0.0;
      data[0].cr = openingBalance?.credit || 0.0;
      setFooterTable(data);
    }
  }, [openingBalance, selected]);

  useEffect(() => {
    if (totalPaying > totalPayable) {
      setAdvanceAmount(totalPaying - totalPayable);
    }
  }, [totalPaying]);

  useEffect(() => {
    // if (feeHeadRows !== null) {
    const data = footerTable;
    let count = 0;
    let count_paying = 0;

    feeHeadRows?.map((elem: any) => {
      count += Number(elem.amount);
    });
    feeHeadRows?.map((elem: any) => {
      count_paying += Number(elem.paid);
    });

    let misc_count = 0;
    selectedMiscArray?.map((elem: any) => {
      misc_count += Number(elem.fee_type__amount);
    });

    let pending_count = 0;
    selectedPendingArray?.map((elem: any) => {
      pending_count += Number(elem.due_amount);
    });

    if (fromOB) {
      if (data[0].cr > 0) {
        if (count + misc_count + pending_count < crAmt) {
          setTotalPayable(0);
          setTempTotalPayable(0);
          setTotalPaying(0);
        } else {
          setTotalPayable(
            count + misc_count + pending_count + Number(crAmt) - discountAmt
          );
          setTempTotalPayable(
            count + misc_count + pending_count + Number(crAmt) - discountAmt
          );
          setTotalPaying(
            count_paying +
              misc_count +
              pending_count +
              Number(crAmt) -
              discountAmt
          );
        }
      }
      if (data[0].dr > 0) {
        if (count + misc_count + pending_count < data[0].dr) {
          setTotalPayable(0);
          setTempTotalPayable(0);
          setTotalPaying(0);
        } else {
          // count -= Number(data[0].dr)
          setTotalPayable(
            count +
              misc_count +
              pending_count -
              Number(data[0].dr) -
              discountAmt
          );
          setTempTotalPayable(
            count +
              misc_count +
              pending_count -
              Number(data[0].dr) -
              discountAmt
          );
          setTotalPaying(
            count_paying +
              misc_count +
              pending_count -
              Number(data[0].dr) -
              discountAmt
          );
        }
      }
    } else {
      setTotalPayable(count + misc_count + pending_count - discountAmt);
      setTempTotalPayable(count + misc_count + pending_count - discountAmt);
      setTotalPaying(count_paying + misc_count + pending_count - discountAmt);
    }
  }, [
    feeHeadRows,
    selectedMiscArray,
    selectedPendingArray,
    fromOB,
    crAmt,
    discountAmt,
  ]);

  useEffect(() => {
    setFooterTable([
      {
        name: "Opening Balance",
        dr: 0.0,
        cr: 0.0,
      },

      {
        name: "Discount",
        value: 0,
      },
      {
        name: "Total Payable",
        value: totalPayable,
      },
      {
        name: "Paying Amount",
        value: 0,
      },
      {
        name: "Due Balance",
        value: 0,
      },
    ]);
    if (student === null) {
      setFeeHeadRows([]);
      setSelected([]);
      setPendingRows([]);
      setMiscRows([]);
    }
  }, [student]);

  const clearData = () => {
    setStudent(null);
    setFeeHeadRows([]);
    setSelected([]);
    setPendingRows([]);
    setMiscRows([]);
    setSendSms("");
    setSmsNotification(false);
    setPayment(null);
    setTotalPayable(0);
    setTempTotalPayable(0);
    setTotalPaying(0);
    setDueAmt(0);
    setDiscountAmt(0);
    setConfirmDialog(false);
    setCustomPhoneNumber(false);
    setCustomPhoneValue("");
    setRemarks("");
    setFooterTable([
      {
        name: "Opening Balance",
        dr: 0.0,
        cr: 0.0,
      },

      {
        name: "Discount",
        value: 0,
      },
      {
        name: "Total Payable",
        value: totalPayable,
      },
      {
        name: "Paying Amount",
        value: 0,
      },
      {
        name: "Due Balance",
        value: 0,
      },
    ]);
  };

  useEffect(() => {
    if (!(selected?.length > 0)) {
      // setTotalPaying(0)
      setDueAmt(0);
      setDiscountAmt(0);
      setTotalPaying(0);
      setTotalPayable(0);
      setTempTotalPayable(0);
    }
  }, [selected]);

  const handleCustomPhoneNumber = (event: any) => {
    setCustomPhoneValue(event.target.value);
  };

  const onCollectFees = () => {
    const paidInvoices: any = [];
    feeHeadRows?.map((elem: any, index: number) => {
      paidInvoices.push({
        invoice: elem.invoice_id,
        paid_amount: Number(elem.paid),
        applied_scholarship: [],
        fines: elem.fine ? [elem.fine] : [],
      });
    });

    //Misc fee logic
    const miscInvoices: any = [];
    let selectedMiscInvoices: any = [];

    if (miscRows?.length > 0) {
      const tempIDs: any[] = selectedMisc;
      const new_datum = miscRows.filter((item: any) =>
        tempIDs.includes(item.id)
      );
      selectedMiscInvoices = new_datum;
    } else {
      selectedMiscInvoices = [];
    }

    selectedMiscInvoices?.map((elem: any, index: number) => {
      miscInvoices.push({
        invoice: elem.id,
        paid_amount: elem.fee_type__amount,
      });
    });

    //Due Fee logics
    const pendingInvoices: any = [];
    let selectedPendingInvoices: any = [];

    if (pendingRows?.length > 0) {
      const tempIDs: any[] = selectedPending;
      const new_datum = pendingRows.filter((item: any) =>
        tempIDs.includes(item.id)
      );
      selectedPendingInvoices = new_datum;
    } else {
      selectedPendingInvoices = [];
    }

    selectedPendingInvoices?.map((elem: any, index: number) => {
      pendingInvoices.push({
        invoice: elem.id,
        paid_amount: Number(elem.due_amount),
        applied_scholarship: [],
        fines: [],
      });
    });

    const post_data = {
      payment_method: payment?.key,
      issued_date: dateConverterBsToAd(issueDate),
      discount: Number(discountAmt) || 0,
      credit_paid: Number(crAmt) || 0,
      student_paid_invoice: paidInvoices || [],
      miscellaneous_invoice: miscInvoices || [],
      dues: pendingInvoices || [],
      total_paid_amount: totalPaying,
      adjust_from_opening_balance: fromOB,
      advance_amount: advanceAmount || 0,
      send_sms: customPhoneNumber,
      number: customPhoneNumber ? customPhoneValue : null,
      receipt_id: feesHead?.receipt_id,
      remarks,
    };
    if (customPhoneNumber && customPhoneValue.length < 9) {
      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Please add valid phone number",
        },
      });
    } else {
      setIsBtnLoading(true);
      dispatch(CollectFee(student?.academic_id, post_data, clearData));
      setConfirmDialog(false);
    }
  };

  const onShowPopover = () => {
    if (payment === null) {
      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Please select any of payment mode",
        },
      });
    } else {
      setConfirmDialog(true);
    }
  };

  const handleDialogClose = (value: boolean) => {
    setConfirmDialog(value);
  };

  // useEffect(() => {
  //     if(subTotal !== 0) {
  //         let count = 0
  //         if (footerTable[0].cr > 0) { count -= Number(footerTable[0].cr) }
  //         if (footerTable[0].dr > 0) { count += Number(footerTable[0].dr) }
  //         console.log({ count })
  //     }
  // },[subTotal])

  const handleIssueDate = (date: string) => {
    setIssueDate(date);
  };

  const onEditPayingAmount = (e: any) => {
    setOnEditPaying(true);
  };

  const onTextFieldChange = (e: any) => {
    if (e.keyCode === 13) {
      setOnEditPaying(false);
    }
  };

  const onEditDiscountAmount = (e: any) => {
    setOnEditDiscount(true);
  };

  const onDiscountTextFieldChange = (e: any) => {
    if (e.keyCode === 13) {
      setOnEditDiscount(false);
    }
  };

  const handlePayingChange = (e: any) => {
    setTotalPaying(e.target.value);
  };

  const handleCreditChange = (e: any) => {
    setCrAmt(e.target.value);
  };

  const handleRemarksChange = (e: any) => {
    setRemarks(e.target.value);
  };

  const handleDiscountChange = (e: any) => {
    setDiscountAmt(e.target.value);
    const temp = Number(tempTotalPayable) - Number(e.target.value || 0);
    setTotalPayable(temp);
    setTotalPaying(temp);
  };

  useEffect(() => {
    // if (totalPaying > 0) {
    // const due = totalPaying - totalPayable + Number(discountAmt);
    const due = totalPayable - totalPaying;

    setDueAmt(Math.abs(due));
    // }
  }, [totalPaying, discountAmt]);

  const handleCloseInvoice = () => {
    setOpenInvoice(false);
  };

  const handleOpenInvoice = () => {
    setOpenInvoice(true);
  };

  useEffect(() => {
    setInvoiceID(collectedFee?.receipt || "");
  }, [collectedFee]);

  useEffect(() => {
    if (invoiceID !== "") {
      handleOpenInvoice();
    } else {
      handleCloseInvoice();
    }
  }, [invoiceID]);

  return (
    <>
      <div style={{ padding: "0px 15px 20px 15px" }}>
        <Grid container>
          {/* <Grid item xs={3} style={{ marginTop: '3px' }}>
            <span>Invoice Number: #4546446</span>
          </Grid> */}
          <Grid item xs={4} style={{ marginTop: "-13px" }}>
            <>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ paddingTop: "15px" }}>Receipt Issue Date: </div>
                <div style={{ width: "250px" }}>
                  <CustomizedNepaliDatePicker
                    value={issueDate}
                    name="issue_date"
                    setValue={handleIssueDate}
                  />
                </div>
              </div>
            </>
          </Grid>
        </Grid>
        <div style={{ maxHeight: "calc(100vh - 55vh)", overflowY: "auto" }}>
          <TableContainer
            loading={loading}
            items={feeHeadRows}
            headCells={headCells}
            getCustomTableRow={getCustomTableRow}
            disableSearch
            disablePrint
            disablePagination
            rowsPerPage={100}
          />
        </div>

        <TreeView
          defaultExpanded={["1"]}
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultExpandIcon={<ArrowRightIcon />}
          defaultEndIcon={<div style={{ width: 30 }} />}
          style={{ background: "none" }}
        >
          <StyledTreeItem
            nodeId="11"
            labelText={`Pending Due (${pendingRows?.length || 0})`}
            labelIcon={LocalAtmOutlinedIcon}
          >
            <TableContainer
              hasCheckbox
              loading={loading}
              items={pendingRows}
              rowCount={pendingRows?.length}
              headCells={dueHeadCells}
              numSelected={selectedPending?.length}
              handleSelectAllClicked={handleSelectAllClickPending}
              getCustomTableRow={getPendingCustomTableRow}
              disableSearch
              disablePrint
              disablePagination
            />
          </StyledTreeItem>
        </TreeView>
        <TreeView
          defaultExpanded={["1"]}
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultExpandIcon={<ArrowRightIcon />}
          defaultEndIcon={<div style={{ width: 30 }} />}
          style={{ background: "none", marginTop: "5px" }}
        >
          <StyledTreeItem
            nodeId="2"
            labelText={`Add Miscellaneous Fee (${miscRows?.length || 0})`}
            labelIcon={LocalAtmOutlinedIcon}
          >
            <TableContainer
              hasCheckbox
              loading={loading}
              items={miscRows}
              rowCount={miscRows?.length}
              headCells={miscHeadCells}
              numSelected={selectedMisc?.length}
              handleSelectAllClicked={handleSelectAllClickMisc}
              getCustomTableRow={getMiscCustomTableRow}
              disableSearch
              disablePrint
              disablePagination
            />
          </StyledTreeItem>
        </TreeView>
        {student !== null && (
          <Table>
            <TableBody>
              {footerTable.map((row: any, index: number) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {index === 0 && (Number(row.dr) > 0 || Number(row.cr) > 0) && (
                      <>
                        {row.name}{" "}
                        <FormControlLabel
                          style={{ marginLeft: "-2px" }}
                          control={
                            <Checkbox
                              color="primary"
                              size="medium"
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                                checked: boolean
                              ) => setFromOB(!fromOB)}
                              checked={fromOB}
                              name="ob"
                            />
                          }
                          label="If checked, will affect to total paying amount"
                          labelPlacement="end"
                        />
                      </>
                    )}
                    {index !== 0 && row.name}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="right">
                    {index === 0 &&
                      (Number(row.dr) > 0 || Number(row.cr) > 0) &&
                      "Dr. " + row.dr}
                  </TableCell>
                  <TableCell
                    style={{ width: 160 }}
                    align="right"
                    onClick={(e) =>
                      index === 3
                        ? onEditPayingAmount(e)
                        : index === 1
                        ? onEditDiscountAmount(e)
                        : ""
                    }
                  >
                    {index === 0 &&
                      (Number(row.dr) > 0 || Number(row.cr) > 0) &&
                      "Cr. " + row.cr}
                    <br />
                    {index === 0 && fromOB && parseInt(row.cr) > 0 && (
                      <>
                        <span>
                          <input
                            style={{ width: "90px" }}
                            className="custom-input"
                            type="number"
                            value={crAmt}
                            placeholder="Cr. Amt."
                            onChange={(e) => handleCreditChange(e)}
                          />
                        </span>
                      </>
                    )}
                    {index === 1 && (
                      <>
                        <span>
                          <input
                            style={{ width: "120px" }}
                            className="custom-input"
                            type="number"
                            onKeyDown={(e) => onDiscountTextFieldChange(e)}
                            value={discountAmt}
                            onChange={(e) => handleDiscountChange(e)}
                            // defaultValue="1000"
                          />
                        </span>
                      </>
                    )}
                    {index === 2 && "Rs. " + totalPayable}
                    {index === 3 && (
                      <>
                        <span>
                          <input
                            style={{ width: "120px" }}
                            className="custom-input"
                            type="number"
                            value={totalPaying}
                            onChange={(e) => handlePayingChange(e)}
                          />
                        </span>
                      </>
                    )}
                    {index === 4 && (
                      <>
                        Rs.{Math.abs(dueAmt)}
                        <br />
                      </>
                    )}
                    {fromOB && index === 4 && totalPayable < totalPaying && (
                      <b style={{ fontSize: "10px", color: "#60AC54" }}>
                        (will adjust to Debit)
                      </b>
                    )}
                    {fromOB && index === 4 && totalPayable > totalPaying && (
                      <b style={{ fontSize: "10px", color: "#F15125" }}>
                        (will adjust to Credit)
                      </b>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        <div style={{ marginTop: "30px" }}>
          <Grid container>
            <Grid item xs={4}>
              <CustomizedSelect
                label="Payment Mode"
                name="payment_method"
                options={paymentChoices}
                value={payment}
                setValue={setPayment}
                style={{ width: "200px" }}
                inputRef={register({ required: true })}
                clearErrors={clearErrors}
              />
              {formsValidationCheckup({
                errors: errors,
                inputName: "payment_method",
                requiredMessage: "Payment Mode is Required",
              })}
            </Grid>

            <Grid item xs={2} style={{ marginTop: "20px" }}>
              {/* <Khalti /> */}
            </Grid>
            <Grid item xs={2} style={{ marginTop: "30px" }}>
              <FormCheckBox
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>,
                  checked: boolean
                ) => setSmsNotification(!smsNotification)}
                checked={smsNotification}
                name="smsNotification"
                label="Send SMS Notification ?"
                // inputRef={register({ required: true })}
              />
              {smsNotification && (
                <FormCheckBox
                  onChange={(
                    event: React.ChangeEvent<HTMLInputElement>,
                    checked: boolean
                  ) => setCustomPhoneNumber(!customPhoneNumber)}
                  checked={customPhoneNumber}
                  name="customPhoneNumber"
                  label="Custom Phone Number ?"
                  inputRef={register({ required: false })}
                />
              )}
            </Grid>
            <Grid item xs={3} style={{ marginTop: "25px" }}>
              {customPhoneNumber && (
                // <CustomizedSelect
                //     label="Send SMS To"
                //     name="smsto"
                //     options={sendSmsChoices}
                //     value={sendSms}
                //     setValue={setSendSms}
                //     style={{ width: '200px' }}
                //     inputRef={register({ required: true })}
                //     clearErrors={clearErrors}
                // />
                <CustomizedTextField
                  label="Phone Number"
                  placeholder="Phone Number"
                  name="sms_number"
                  onChange={(event: any) => handleCustomPhoneNumber(event)}
                  // required
                  // error={errors["name"] ? "This Field is Required" : ""}
                  inputRef={register({ required: false })}
                />
              )}
              {formsValidationCheckup({
                errors: smsNotification ? errors : false,
                inputName: "smsto",
                requiredMessage: "SMS To is Required",
              })}
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <CustomizedTextField
              label="Narration"
              placeholder="narration"
              name="narration"
              onChange={(event: any) => handleRemarksChange(event)}
              inputRef={register({ required: false })}
              value={remarks}
              multiline
              rows={2}
            />
          </Grid>
        </div>
        <FormSaveLoadingButton
          onClick={onShowPopover}
          icon={isBtnLoading ? <Spinner /> : <SaveIcon />}
          disabled={
            isBtnLoading
              ? true
              : feeHeadRows?.length > 0 ||
                selectedMisc?.length > 0 ||
                selectedPending?.length > 0
              ? false
              : true
          }
          text={isBtnLoading ? "Collecting..." : "Collect"}
        />
        <FormResetButton onClick={() => clearData()} />
      </div>
      <DisableDialog
        open={confirmDialog}
        onClose={handleDialogClose}
        deleteConfirmed={onCollectFees}
        dialogTitle="Are you sure to make changes. Add Carefully!"
        title="Colle Fees"
        okText="Save"
      />
      <Dialog
        open={openInvoice}
        onClose={handleCloseInvoice}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <FeeInvoice
          generalInfoData={generalInfoData}
          student={student}
          invoiceID={invoiceID}
        />
      </Dialog>
    </>
  );
};
// ---------------------------- <END> fine Type ComponWent Ends -------------------------------//

export default CollectFeesList;
