import {
  Button,
  Grid,
  IconButton,
  Paper,
  Popover,
  Table,
  TableBody,
  TableHead,
  Checkbox,
  TableCell,
} from "@material-ui/core";
import { Delete, Save } from "@material-ui/icons";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { RootStore } from "../../../store";
import { useTableWithPagination } from "../../Reusable";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { deleteAppliedStudentFee } from "../../../rtk/features/appliedFee/appliedFeeApi";
import { returnMonthString } from "../../../rtk/features/feeDiscount/feeDiscountApi";

const Wrapper = styled(Paper)`
  background-color: #fff;
  padding: 16px;
  margin: 10px;
`;

const PopOverContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0 15px 0 5px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SaveButton = styled(Button)`
  margin-top: 20px;
  width: 120px;
`;

const headCells = [
  { id: "fee", label: "Fee Head" },
  { id: "fee_amount", label: "Fee Amount" },
  { id: "action", label: "Action" },
];

const AppliedStudentFees = () => {
  const dispatch = useDispatch();

  const [checkedRows, setCheckedRows] = useState<string[]>([]);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [delId, setDelId] = useState<string | null>(null);

  const { StyledTableCell, StyledTableRow } = useTableWithPagination(headCells);

  const { appliedStudentFees } = useSelector(
    (state: RootStore) => state.appliedFees
  );

  const allChecked = appliedStudentFees.length
    ? checkedRows.length === appliedStudentFees.length
    : false;

  const handleCheckAll = () => {
    if (allChecked) {
      setCheckedRows([]);
    } else {
      const check = appliedStudentFees.map((el) => el.id);
      setCheckedRows([...check]);
    }
  };

  const handleCheckRow = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const checked = [...checkedRows];
      checked.push(e.target.value);
      setCheckedRows(checked);
    } else {
      const checked = checkedRows.filter((el) => el !== e.target.value);
      setCheckedRows([...checked]);
    }
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    setAnchorEl(event.currentTarget);
    setDelId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setDelId(null);
  };

  const handleConfirmClick = () => {
    setAnchorEl(null);
    if (delId) {
      dispatch(
        deleteAppliedStudentFee({
          invoice_id: delId,
          student: null,
          fee_type: null,
          month: null,
        })
      );
      setDelId(null);
    }
  };

  const handleCancelClick = () => {
    setAnchorEl(null);
    setDelId(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Wrapper>
      <Table>
        <TableHead>
          <StyledTableRow>
            {/* <StyledTableCell align="center">
              <Checkbox
                indeterminate={!allChecked && Boolean(checkedRows.length)}
                onChange={handleCheckAll}
                checked={allChecked}
              />
            </StyledTableCell> */}
            {headCells.map((el) => (
              <StyledTableCell key={el.id} align="center">
                {el.label}
              </StyledTableCell>
            ))}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {appliedStudentFees.map((item) => (
            <StyledTableRow key={item.id}>
              {/* <StyledTableCell align="center">
                <Checkbox
                  value={item.id}
                  checked={checkedRows.includes(item.id)}
                  onChange={handleCheckRow}
                />
              </StyledTableCell> */}
              <StyledTableCell align="center">{`${item.fee_head} ${
                item.month ? " - " + returnMonthString(item.month) : ""
              }`}</StyledTableCell>
              <StyledTableCell align="center">
                {item.fee_amount}
              </StyledTableCell>
              <StyledTableCell align="center">
                <IconButton
                  aria-describedby={id}
                  component="span"
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                    handleClick(e, item.id)
                  }
                >
                  <Delete style={{ color: "red" }} />
                </IconButton>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      {/* <Grid container>
        <SaveButton
          variant="contained"
          color="primary"
          startIcon={<Delete />}
          disabled={!Boolean(checkedRows.length)}
        >
          Delete
        </SaveButton>
      </Grid> */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <PopOverContainer>
          <IconWrapper>
            <IconButton onClick={handleConfirmClick}>
              <CheckCircleIcon style={{ color: "green" }} />
            </IconButton>
            <span>Confirm</span>
          </IconWrapper>
          <IconWrapper>
            <IconButton onClick={handleCancelClick}>
              <CancelIcon style={{ color: "orangered" }} />
            </IconButton>
            <span>Cancel</span>
          </IconWrapper>
        </PopOverContainer>
      </Popover>
    </Wrapper>
  );
};

export default AppliedStudentFees;
