import React, { useState } from "react";
import { HRsidebar } from "../../components";
import DateSearch from "../../components/HumanResource/StaffAttendance/DateSearch";
import StaffList from "../../components/HumanResource/StaffAttendance/StaffList";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const StaffAttendancePage = () => {
  const nav = <HRsidebar staffAttendance />;
  const [inputDate, setInputDate] = useState<string>("");
  return (
    <SubModuleLayout studentMenuActive sideNav={nav}>
      <DateSearch setInputDate={setInputDate} />
      <StaffList inputDate={inputDate} />
    </SubModuleLayout>
  );
};

export default StaffAttendancePage;
