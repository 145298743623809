//---------------------<start> module imports starts ----------------------//
import {
    Button,
    Grid,
    InputLabel,
    Paper,
    TextField,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useFormStyles } from "../../Styles/FormStyles";
import { Autocomplete } from "@material-ui/lab";
import { Search } from "@material-ui/icons";
import { RootStore } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { GetClasses } from "../../../actions/Academics/Class/ClassAction";
import { GetSections } from "../../../actions/Academics/Section/SectionAction";
import { SearchStudent, StudentSearch } from "../../../actions/Student/Student/StudentAction";
import { useForm } from "react-hook-form";
import { GetScholarshipStudentList } from "../../../actions/ScholarShipType/ScholarshipAction";
import { FormSaveLoadingButton } from "../../Reusable/Buttons/FormButton";
import LoaderIcon from "../../Reusable/LoarderIcon";
import SaveIcon from "@material-ui/icons/Save";
import Spinner from "../../../common/spinnerSVG/Spinner";


// -----------------<starts> Selected Student Form starts-----------------------//
const ScholarshipSearchForm = (props: any) => {
    const { handleApplyFees, grade, setGrade, section, setSection, setIsBtnLoading, isBtnLoading } = props;
    const classes = useFormStyles();
    const [gradeChoices, setGradeChoices] = useState<any | null>([]);
    const [sectionChoices, setSectionChoices] = useState<any[] | []>([]);
    const [sectionDisabler, setSectionDisabler] = useState<boolean>(true);

    const gradeList = useSelector((state: RootStore) => state.class.classes);

    const add_or_update = useSelector((state: RootStore) => state.scholarship.add_or_update)

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetSections());
        dispatch(GetClasses());
    }, []);

    useEffect(() => {
        gradeList && setGradeChoices(gradeList);
    }, [gradeList]);

    useEffect(() => {
        if (add_or_update) {
            setIsBtnLoading(false);
        }
    }, [add_or_update]);

    const handleGradeChange = (value: any | null) => {
        if (value) {
            setGrade(value);
            populateSectionChoices(value.section)
            dispatch(GetScholarshipStudentList(value.grade, ""));
        } else {
            setGrade(null)
            setSectionDisabler(true);
        }
    };


    const populateSectionChoices = (sections: any | []) => {
        setSectionDisabler(false);
        sections.length > 0
            ? setSectionChoices(sections)
            : setSectionDisabler(true);
    };

    useEffect(() => {
        if (section != null && grade != null) {
            dispatch(GetScholarshipStudentList(grade.grade, section.id));
        }
    }, [section]);


    const handleSectionChange = (value: any) => {
        setSection(value);
    };


    return (
        <>
            {/* <Paper className={classes.pageContent}> */}
            {/* <form className={classes.form}> */}
            {props.title && <Grid container>
                <Grid item xs={12} className={classes.formTitle}>
                    <span>{props.title}</span>
                </Grid>
            </Grid>}
            <Grid container spacing={3}>
                <Grid item xs={2} className={classes.formWrapper}>
                    <InputLabel>Class</InputLabel>
                    <Autocomplete
                        onChange={(event: React.ChangeEvent<{}>, value: any | null) =>
                            handleGradeChange(value)
                        }
                        options={gradeChoices}
                        getOptionLabel={(option) => "Class" + " " + option.grade_name}
                        value={grade}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Select a Grade"
                                name="class"
                                variant="outlined"
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={2} className={classes.formWrapper}>
                    <InputLabel>Section</InputLabel>
                    <Autocomplete
                        onChange={(event: React.ChangeEvent<{}>, value: any | null) =>
                            handleSectionChange(value)
                        }
                        options={sectionChoices}
                        value={section && section}
                        getOptionLabel={(option) => option.name}
                        disabled={sectionDisabler}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Select a Section"
                                name="section"
                                variant="outlined"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                </Grid>
                <Grid item xs={2} className={classes.formWrapper}>
                    <FormSaveLoadingButton
                        style={{ marginTop: '20px' }}
                        onClick={handleApplyFees}
                        icon={isBtnLoading ? <Spinner /> : <SaveIcon />}
                        disabled={isBtnLoading ? true : false}
                        text={isBtnLoading ? "Applying..." : "Apply"}
                    />
                </Grid>
            </Grid>
            {/* </form> */}
            {/* </Paper> */}
        </>
    );
};
// -----------------<end> Selected Student form ends-----------------------//
export default ScholarshipSearchForm;
