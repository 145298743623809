import React, { useState, useEffect } from "react";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
// import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import ClearIcon from "@material-ui/icons/Clear";
import { useFormStyles } from "../../Styles/FormStyles";
import AddNewButton from "../../Reusable/Buttons/AddNewButton";
import { useDispatch, useSelector } from "react-redux";
import {
  AddSession,
  UpdateSession,
} from "../../../actions/GeneralSettings/Session/SessionAction";
import { RootStore } from "../../../store";
import { SessionTypeI } from "../../../actions/GeneralSettings/Session/SessionActionTypes";
import FormLayout from "../../Reusable/Layouts/Form.Layout";
import { useForm } from "react-hook-form";
import CustomizedTextField from "../../Reusable/Inputs/TextField";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import CustomizedNepaliDatePicker from "../../Reusable/Inputs/NepaliDatePicker";
import {
  dateConverterAdToBs,
  dateConverterBsToAd,
} from "../../utils/dateConverter";
import { Grid } from "@material-ui/core";
import moment from "moment";
// import "nepali-datepicker-reactjs/dist/index.css";

// -----------------<start> Styling <start>-----------------------//

// -----------------<end> Styling <end>-----------------------//

// -----------------<start> Interfaces <start>-----------------------//

interface PropsI {
  editData: SessionTypeI | null;
}

// -----------------<end> Interfaces <end>-----------------------//

// -----------------<start> States <start>-----------------------//

// -----------------<end> States <end>-----------------------//

const SessionForm = (props: PropsI) => {
  const { editData } = props;
  const classes = useFormStyles();

  const today = moment(new Date()).format("YYYY-MM-DD");

  //==================================<START>STATES DECLARATION<START>=============================//

  const [loading, setLoading] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [editID, setEditID] = useState<string | null>(null);

  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);

  const { register, handleSubmit, setValue, reset, errors } = useForm();

  //==================================<END>STATES DECLARATION<END>=============================//

  //==================================<START>REDUX REDUCER<START>=============================//

  const dispatch = useDispatch();
  const sessionLoading = useSelector(
    (state: RootStore) => state.session.loading
  );
  const add_or_update = useSelector((state: RootStore) => state.session.add_or_update)

  //==================================<END>REDUX REDUCER<END>=============================//

  //=================================<START>CYCLE HOOKS<START>==================================//

  useEffect(() => {
    if (add_or_update) {
      setIsBtnLoading(false)
      handleReset();
    }
  }, [add_or_update])

  useEffect(() => {
    editData != null && handleFormInitialize(editData);
  }, [editData]);

  useEffect(() => {
    setLoading(sessionLoading);
  }, [sessionLoading]);

  //=================================<END>CYCLE HOOKS<END>==================================//

  //====================================<START> EVENT HANDLERS<START>==============================//

  const handleFormInitialize = (data: SessionTypeI) => {
    setEditMode(true);
    setValue("name", data.name);
    setEditID(data.id);
    setFromDate(dateConverterAdToBs(data.from_date));
    setToDate(dateConverterAdToBs(data.to_date));
  };

  const onSubmit = (data: SessionTypeI) => {
    setIsBtnLoading(true);
    const new_data = {
      ...data,
      name: data.name,
      from_date: fromDate ? dateConverterBsToAd(fromDate) : today,
      to_date: toDate ? dateConverterBsToAd(toDate) : today,
    };
    if (editMode) {
      editID != null && dispatch(UpdateSession(editID, new_data));
    } else {
      dispatch(AddSession(new_data));
    }
  };

  const handleFromDate = (date: string) => {
    setFromDate(date);
  };

  const handleToDate = (date: string) => {
    setToDate(date);
  };

  const handleReset = () => {
    reset();
    setFromDate("");
    setToDate("");
    setEditMode(false);
  }
  //====================================<END> EVENT HANDLERS<END>==============================//

  return (
    <>
      <FormLayout
        title={editMode ? "Edit Session" : "Add Session"}
        onSubmit={handleSubmit(onSubmit)}
        editMode={editMode}
        loading={loading}
        onClick={!isBtnLoading && handleReset}
        add_or_update={isBtnLoading}
      >
        <CustomizedTextField
          label="Session Name"
          placeholder="Session Name"
          name="name"
          required
          error={errors["name"] ? "Required Field." : ""}
          inputRef={register({ required: true })}
        />
        <CustomizedNepaliDatePicker
          label="From Date"
          value={fromDate}
          name="from_date"
          setValue={handleFromDate}
          required
        />
        <CustomizedNepaliDatePicker
          label="To Date"
          value={toDate}
          name="to_date"
          setValue={handleToDate}
          required
        />
      </FormLayout>
    </>
  );
};

export default SessionForm;
