import {
  GET_TERMS_CONDITIONS_LOADING,
  GET_TERMS_CONDITIONS_ERROR,
  GET_TERMS_CONDITIONS_SUCCESS,
  TacDispatchTypes,
  ADD_TERMS_CONDITIONS_LOADING,
  ADD_TERMS_CONDITIONS_SUCCESS,
  ADD_TERMS_CONDITIONS_ERROR,
} from "../../actions/GeneralSettings/TermsandConditions/TACActionTypes";

interface InitialStateI {
  loading: boolean;
  terms: any;
}

const initialState: InitialStateI = {
  loading: false,
  terms: null,
};

const TacReducer = (
  state: InitialStateI = initialState,
  action: TacDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_TERMS_CONDITIONS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_TERMS_CONDITIONS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_TERMS_CONDITIONS_SUCCESS:
      return {
        loading: false,
        terms: action.payload,
      };

    case ADD_TERMS_CONDITIONS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case ADD_TERMS_CONDITIONS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case ADD_TERMS_CONDITIONS_SUCCESS:
      return {
        loading: false,
        terms: [...state.terms, action.payload],
      };

    default:
      return state;
  }
};

export default TacReducer;
