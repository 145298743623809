import React from "react";
import StudentSidebar from "../../components/Student/StudentSidebar/StudentSidebar";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";
import { breadcrumb } from "./Student.Breadcrumbs";
import SectionTransferForm from "../../components/Student/SectionTransfer/SectionTransferForm";
import SectionTransferTable from "../../components/Student/SectionTransfer/SectionTransferTable";

const StudentRollNumPage = () => {
  const nav = <StudentSidebar sectionTransfer />;
  return (
    <SubModuleLayout module={breadcrumb} sideNav={nav}>
      <SectionTransferForm />
      <SectionTransferTable />
    </SubModuleLayout>
  );
};

export default StudentRollNumPage;
