import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ExamSchedulePayloadI } from "../examSchedule/examSchedule";
import { getExamSchedule } from "../examSchedule/examScheduleApi";
import {
  ExamSubjectsI,
  GetMarksPayloadI,
  InitialStateI,
  StudentAllSubjectMarksI,
  StudentAllSubjectMarksPayloadI,
  StudentGradeI,
  StudentMarksI,
} from "./addMarks";
import {
  getAllSubjectsMarksEntry,
  getMarksEntry,
  postAllSubjectsMarksEntry,
  postMarksEntry,
} from "./addMarksApi";

const initialState: InitialStateI = {
  loading: false,
  actionPerformed: false,
  markingType: null,
  studentGrades: {
    has_practical: false,
    grades: [],
  },
  studentMarks: {
    has_practical: false,
    marks: [],
  },
  studentAllSubjectMarks: [],
  subjects: [],
  searchParams: null,
};

const addMarksSlice = createSlice({
  name: "AddMarks",
  initialState,
  reducers: {
    changeFields: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: string;
        field:
          | "gradeTheory"
          | "gradePractical"
          | "marksTheory"
          | "marksPractical"
          | "remarks";
        value: string;
      }>
    ) => {
      const { id, field, value } = payload;
      const targetMarks = state.studentMarks.marks.find((el) => el.id === id);
      const targetGrade = state.studentGrades.grades.find((el) => el.id === id);

      if (targetGrade) {
        if (field === "gradeTheory") {
          targetGrade.gradeTheory = value;
        }

        if (field === "gradePractical") {
          targetGrade.gradePractical = value;
        }
      }

      if (targetMarks) {
        if (field === "marksTheory") {
          const re = /^[0-9]*\.?[0-9]*$/;

          if (value === "" || re.test(value)) {
            targetMarks.marksTheory = value;
          }
        }

        if (field === "marksPractical") {
          const re = /^[0-9]*\.?[0-9]*$/;

          if (value === "" || re.test(value)) {
            targetMarks.marksPractical = value;
          }
        }

        if (field === "remarks") {
          targetMarks.remarks = value;
        }
      }
    },
    handleSubjectMarksChange: (
      state,
      {
        payload,
      }: PayloadAction<{
        studentId: string;
        subjectId: string;
        field: "gradeTH" | "gradePR" | "marksTH" | "marksPR" | "attd";
        value: string;
      }>
    ) => {
      const { studentId, subjectId, field, value } = payload;

      const student = state.studentAllSubjectMarks.find(
        (el) => el.student_id === studentId
      );

      if (student) student.changed = true;

      const te = /^[0-9]*$/;
      if (field === "attd") {
        if (value === "" || te.test(value)) {
          student!.attendance = Number(value);
        }
      }

      const studentSubjects = student?.subjects;

      if (studentSubjects) {
        const target = studentSubjects.find(
          (el) => el.exam_subject_id === subjectId
        );

        if (target) {
          const re = /^[0-9]*\.?[0-9]*$/;

          if (field === "marksTH") {
            if (value === "" || re.test(value)) {
              target.marks_obtained_theory = value;
            }
          }

          if (field === "marksPR") {
            if (value === "" || re.test(value)) {
              target.marks_obtained_practical = value;
            }
          }

          if (field === "gradeTH") {
            target.grade_obtained_theory = value;
          }

          if (field === "gradePR") {
            target.grade_obtained_practical = value;
          }
        }
      }
    },
    setSearchParams: (
      state,
      {
        payload,
      }: PayloadAction<{
        exam: { id: string; name: string } | null;
        grade: { id: string; name: string } | null;
        section: { id: string; name: string } | null;
        subject: { id: string; name: string; has_practical: boolean } | null;
      }>
    ) => {
      state.searchParams = { ...payload };
    },
    reloadData: (state) => {
      state.markingType = null;
      state.studentMarks = { has_practical: false, marks: [] };
      state.studentGrades = { has_practical: false, grades: [] };
      state.searchParams = null;
      state.studentAllSubjectMarks = [];
      state.subjects = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getExamSchedule.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getExamSchedule.fulfilled,
      (state, { payload }: PayloadAction<ExamSchedulePayloadI[]>) => {
        const data: ExamSubjectsI[] = [];

        const filtered = payload.filter(
          (el) => el.subject_info !== null && el.exam_subject_info !== null
        );

        filtered.forEach((el) => {
          data.push({
            id: el.exam_subject_info!.id,
            subjectInfoId: el.subject_info!.id,
            subject: el.name,
            subject_type: el.subject_info!.subject_type,
            has_practical: el.subject_info!.has_practical,
            full_marks_theory: el.exam_subject_info?.full_marks_theory || 0,
            full_marks_practical:
              el.exam_subject_info?.full_marks_practical || null,
            pass_marks_theory: el.exam_subject_info?.pass_marks_theory || 0,
            pass_marks_practical:
              el.exam_subject_info?.pass_marks_practical || null,
            result_type: el.subject_info?.result_type || 0,
          });
        });

        state.subjects = data;
        state.loading = false;
      }
    );
    builder.addCase(getExamSchedule.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getMarksEntry.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(getMarksEntry.fulfilled, (state, { payload }) => {
      const gradesData: StudentGradeI[] = [];
      const marksData: StudentMarksI[] = [];

      if (payload.subject_marking_type === 0) {
        const res = payload as GetMarksPayloadI<0>;

        res.student_marks.forEach((el) => {
          gradesData.push({
            id: el.student_id,
            roll_no: el.roll_number ? String(el.roll_number) : "--",
            name: el.student_full_name,
            gradeTheory: el.grade_obtained_theory,
            gradePractical: el.grade_obtained_practical,
            remarks: el.remarks || "",
          });
        });
      }

      if (payload.subject_marking_type === 1) {
        const res = payload as GetMarksPayloadI<1>;
        res.student_marks.forEach((el) => {
          marksData.push({
            id: el.student_id,
            roll_no: el.roll_number ? String(el.roll_number) : "--",
            name: el.student_full_name,
            fullMarksTheory: el.full_marks_theory
              ? String(el.full_marks_theory)
              : "--",
            fullMarksPractical: el.full_marks_practical
              ? String(el.full_marks_practical)
              : "--",
            passMarksTheory: el.pass_marks_theory
              ? String(el.pass_marks_theory)
              : "--",
            passMarksPractical: el.pass_marks_practical
              ? String(el.pass_marks_practical)
              : "--",
            marksTheory: el.marks_obtained_theory,
            marksPractical: el.marks_obtained_practical,
            remarks: el.remarks ? String(el.remarks) : "",
          });
        });
      }

      state.markingType = payload.subject_marking_type;
      state.studentMarks = {
        has_practical: payload.has_practical,
        marks: marksData,
      };
      state.studentGrades = {
        has_practical: payload.has_practical,
        grades: gradesData,
      };
      state.loading = false;
    });
    builder.addCase(getMarksEntry.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(postMarksEntry.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(postMarksEntry.fulfilled, (state) => {
      state.loading = false;
      state.actionPerformed = true;
    });
    builder.addCase(postMarksEntry.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getAllSubjectsMarksEntry.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(
      getAllSubjectsMarksEntry.fulfilled,
      (state, { payload }: PayloadAction<StudentAllSubjectMarksPayloadI[]>) => {
        const data: StudentAllSubjectMarksI[] = [];

        payload.forEach((el) => {
          const attendance = Number(el.subjects[0]?.attendance) || 0;
          const subjectInfo = el.subjects.map((sub) => ({
            ...sub,
            marks_obtained_theory: sub.marks_obtained_theory || "0",
            marks_obtained_practical: sub.marks_obtained_practical || "0",
          }));

          data.push({
            ...el,
            subjects: subjectInfo,
            attendance,
            changed: false,
            optSubjects: el.optional_subjects.map((it) => it.optional_subjects),
          });
        });

        state.studentAllSubjectMarks = data;
        state.loading = false;
      }
    );
    builder.addCase(getAllSubjectsMarksEntry.rejected, (state) => {
      state.loading = false;
      state.studentAllSubjectMarks = [];
    });

    builder.addCase(postAllSubjectsMarksEntry.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(postAllSubjectsMarksEntry.fulfilled, (state) => {
      state.loading = false;
      state.actionPerformed = true;
    });
    builder.addCase(postAllSubjectsMarksEntry.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  changeFields,
  reloadData,
  setSearchParams,
  handleSubjectMarksChange,
} = addMarksSlice.actions;

export default addMarksSlice.reducer;
