import { Grid, Paper, Typography, Button, Checkbox } from '@material-ui/core';
import React, { useState, useEffect } from 'react'
import cx from "clsx";
import { useCheckBoxTable } from '../../Reusable';
import { useTableStyles } from "../../Styles/TableStyles";
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import { GetFeeType, GetFeeTypeWithFilters } from '../../../actions/Fees/FeeType/FeeTypeAction';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import getMonthName from '../../utils/getMonthName';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
interface TableDataI {
    id: string;
    title: string;
    isMonthly?: boolean;
    applied_month: Array<string>
}
interface PropsI {
    onFeeChange: (ids: TableDataI[]) => void;
    grade: any;
    section: any;
    setFeeHeads: (data: any) => void;
    feeHeads: string;
    months: Array<string>;
    setMonths: (data: any) => void
}


interface HeadCellsI {
    id: string;
    label: string;
}

const headCells: HeadCellsI[] = [
    // { id: "index", label: "S.N" },
    { id: "fee", label: "Fee Title" },
];

const useCustomStyles = makeStyles((theme) => ({
    root: {
        '&: hover': {
            backgroundColor: '#118FCF',
        }
    },
    active: {
        backgroundColor: '#118FCF',
        color: 'white',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}));

const FeeCategorySelect = (props: PropsI) => {
    const { onFeeChange, grade, section, setFeeHeads, feeHeads, months, setMonths } = props;

    const classes = useTableStyles();
    const customClasses = useCustomStyles();


    const [tableData, setTableData] = useState<any[]>([]);

    // const [value, setValue] = React.useState('');
    const [allMonths, setAllMonths] = React.useState(false);


    const handleChange = (event: any) => {
        setAllMonths(false)
        setMonths([])
        setFeeHeads(event.target.value);
    };

    const dispatch = useDispatch();
    const feeTypeSelector = useSelector((state: RootStore) => state.fee_type);

    const applyFeeAction = useSelector((state: RootStore) => state.fee.actionPerformed);

    useEffect(() => {
        if (applyFeeAction) {
            setMonths([])
        }
    }, [applyFeeAction])

    // React Cycle Hooks
    useEffect(() => {
        dispatch(GetFeeType(1));
        setMonths([])
    }, [])

    useEffect(() => {
        setFeeHeads('')
    }, [grade, section])

    useEffect(() => {
        const data = feeTypeSelector.feeType;

        setTableData(data?.results?.map((item: any) => ({
            id: item.id,
            title: item.name,
            applied_month: item.billing_period
            // isMonthly: item.is_monthly
        })))
    }, [feeTypeSelector])


    useEffect(() => {
        const selected_fees = tableData?.filter((item: TableDataI) => months?.includes(item.id));
        onFeeChange(selected_fees);
    }, [months])

    const { StyledTableCell, StyledTableRow, TableContainer } =
        useCheckBoxTable(headCells);

    const handleCheckClick = (name: string) => {
        const selectedIndex = months?.indexOf(name);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(months, name);

        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(months.slice(1));
        } else if (selectedIndex === months.length - 1) {
            newSelected = newSelected.concat(months.slice(0, -1));
        }
        else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                months.slice(0, selectedIndex),
                months.slice(selectedIndex + 1)
            );
        }

        setMonths(newSelected);
    };


    const handleSelectAllClick = () => {
        setAllMonths(!allMonths)
    };

    useEffect(() => {
        if (allMonths) {
            const singleFee__ = tableData.find(elem => elem.id === feeHeads)
            const newSelecteds = singleFee__?.applied_month?.map((data: string | number) => data);
            setMonths(newSelecteds);
        } else {
            setMonths([]);
        }
    }, [allMonths])

    const isSelected = (id: string) => months?.indexOf(id) !== -1;

    const handleAllClick = () => {
        dispatch(GetFeeType(1));
    }

    const handleOneTimeClick = () => {
        dispatch(GetFeeTypeWithFilters("O"));
    }

    const handleMonthlyClick = () => {
        dispatch(GetFeeTypeWithFilters("M"));
    }

    return (
        <Paper className={classes.rootTableBox} style={{ marginRight: "5px" }}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography className={classes.title}>Fee Categories</Typography>
                </Grid>
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                    <Button onClick={() => handleAllClick()}>All</Button>
                    <Button onClick={() => handleOneTimeClick()}>One Time</Button>
                    <Button onClick={() => handleMonthlyClick()}>Monthly</Button>
                </ButtonGroup>
                <Grid item xs={12}>
                    <div style={{ maxHeight: "calc(100vh - 10vh)", overflowY: "auto" }}>
                        {grade !== null &&
                            <FormControl style={{ marginTop: '10px' }}>
                                <RadioGroup aria-label="gender" name="gender1" value={feeHeads} onChange={handleChange}>
                                    {tableData?.map((elem: any, index: number) => {
                                        return (
                                            <>
                                                <FormControlLabel key={index} value={elem.id} control={<Radio style={{ padding: '0px 5px 0px 10px', marginTop: '-5px' }} />} label={elem.title} style={{ marginTop: '5px' }} />
                                                {feeHeads === elem.id && elem.applied_month?.length > 0 && <>
                                                    <Chip
                                                        avatar={<Avatar><CheckCircleOutlinedIcon className={allMonths ? customClasses.active : ""} /></Avatar>}
                                                        label={allMonths ? 'UnCheck All' : 'Check All'}
                                                        onClick={handleSelectAllClick}
                                                        variant="outlined"
                                                        style={{ width: '65%', marginBottom: '10px', marginLeft: '35px' }}
                                                        className={allMonths ? customClasses.root : ""}
                                                    />
                                                    <Grid container style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '35px' }}>
                                                        {elem.applied_month?.map((data: any, index: number) => {
                                                            const isItemSelected = isSelected(data);
                                                            return (
                                                                <>
                                                                    <Grid item key={index} xs={6}>
                                                                        <FormControlLabel
                                                                            control={<Checkbox checked={isItemSelected} onChange={() => handleCheckClick(data)} name="gilad" style={{
                                                                                marginTop: '-10px'
                                                                            }} />}
                                                                            label={getMonthName(data.toString())}
                                                                        />
                                                                    </Grid>
                                                                </>
                                                            )
                                                        })}
                                                    </Grid>
                                                </>
                                                }
                                            </>
                                        )
                                    })}
                                </RadioGroup>
                            </FormControl>
                        }
                    </div>
                </Grid>

            </Grid>
        </Paper >
    )
}

export default FeeCategorySelect;
