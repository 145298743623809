import React from "react";
import StudentSidebar from "../../components/Student/StudentSidebar/StudentSidebar";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";
import { breadcrumb } from "./Student.Breadcrumbs";
import StudentRollNumTable from "../../components/Student/StudentRollNum/StudentRollNumTable";

const StudentRollNumPage = () => {
  const nav = <StudentSidebar rollNum />;
  return (
    <SubModuleLayout module={breadcrumb} sideNav={nav}>
      <StudentRollNumTable />
    </SubModuleLayout>
  );
};

export default StudentRollNumPage;
