import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { SettingSidebar, SessionForm, SessionTable } from "../../components";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";
import { SessionTypeI } from "../../actions/GeneralSettings/Session/SessionActionTypes";

const Session = () => {
  const [formData, setFormData] = useState<SessionTypeI | null>(null);

  const fetchEditData = (data: SessionTypeI) => {
    setFormData(data);
  };

  const nav = <SettingSidebar session />;
  return (
    <SubModuleLayout sideNav={nav}>
      <Grid container>
        <Grid item xs={3}>
          <SessionForm editData={formData} />
        </Grid>
        <Grid item xs={9}>
          <SessionTable onEditData={fetchEditData} />
        </Grid>
      </Grid>
    </SubModuleLayout>
  );
};
export default Session;
