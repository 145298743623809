//---------------------------- <START> module imports start ----------------------------------//
import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Paper,
  Button,
  Tooltip,
  ButtonGroup,
} from '@material-ui/core';
import DashImg from '../../../../assets/images/ParentDash.jpg';
import FormButton from '../../../Reusable/Buttons/FormButton';
import { useSelector, useDispatch } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { RootStore } from '../../../../store';
import {
  GetIdCardTemplate,
  ActivateOrDeactivateIdCardTemplate,
  DeleteIdCardTemplate,
} from '../../../../actions/Templates/IdCard/IdCardTemplateAction';
import DeleteForeverOutlined from '@material-ui/icons/DeleteForeverOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import ConfirmDialog from '../../../Reusable/Dialogs/ConfirmDialog';
import checkImageUrl from '../../../utils/checkImageUrl';
import TemplateStyles from './TemplateStyles';
// ----------------------------<END> module imports end ----------------------------------//

const MyIdCards: React.FC = () => {
  const [isThere, setIsThere] = useState(false);
  const [landscape, setLandscape] = useState(false);
  const [landscapeTemplateData, setLandscapeTemplateData] = useState<any>([]);
  const [potraitTemplateData, setPotraitTemplateData] = useState<any>([]);
  const [tempLandscapeTemplateData, setTempLandscapeTemplateData] =
    useState<any>([]);
  const [tempPotraitTemplateData, setTempPotraitTemplateData] = useState<any>(
    []
  );
  const [open, setOpen] = useState<boolean>(false);
  const [studentMode, setStudentMode] = useState<boolean>(false);
  const [staffMode, setStaffMode] = useState<boolean>(false);
  const [cardID, setCardID] = useState<string | null>(null);
  const [editMode, setEditMode] = useState(false);
  const [mode, setMode] = useState('potrait');

  const classes = TemplateStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const my_templates = useSelector(
    (state: RootStore) => state.id_card_templates
  );

  useEffect(() => {
    if (my_templates != null) {
      setLandscapeTemplateData(
        my_templates.id_card_templates.filter(
          (item: any) => item.mode === 'landscape'
        )
      );
      setPotraitTemplateData(
        my_templates.id_card_templates.filter(
          (item: any) => item.mode === 'potrait'
        )
      );
      setTempLandscapeTemplateData(
        my_templates.id_card_templates.filter(
          (item: any) => item.mode === 'landscape'
        )
      );
      setTempPotraitTemplateData(
        my_templates.id_card_templates.filter(
          (item: any) => item.mode === 'potrait'
        )
      );
      my_templates.id_card_templates.length > 0
        ? setIsThere(true)
        : setIsThere(false);
    }
  }, [my_templates]);

  const handleAllClick = () => {
    setStudentMode(false);
    setStaffMode(false);
    setLandscapeTemplateData(tempLandscapeTemplateData);
    setPotraitTemplateData(tempPotraitTemplateData);
  };

  const handleStudentClick = () => {
    setStudentMode(true);
    setStaffMode(false);
  };

  const handleStaffClick = () => {
    setStudentMode(false);
    setStaffMode(true);
  };

  useEffect(() => {
    if (studentMode) {
      if (landscape) {
        setLandscapeTemplateData(
          tempLandscapeTemplateData.filter(
            (item: any) => item.type === 'Student'
          )
        );
      } else {
        setPotraitTemplateData(
          tempPotraitTemplateData.filter((item: any) => item.type === 'Student')
        );
      }
    } else if (staffMode) {
      if (landscape) {
        setLandscapeTemplateData(
          tempLandscapeTemplateData.filter((item: any) => item.type === 'Staff')
        );
      } else {
        setPotraitTemplateData(
          tempPotraitTemplateData.filter((item: any) => item.type === 'Staff')
        );
      }
    }
  }, [studentMode, staffMode]);

  const handlePotraitClicked = () => {
    setLandscape(false);
    setMode('potrait');
  };

  const handleLandscapeClick = () => {
    setLandscape(true);
    setMode('landscape');
  };

  useEffect(() => {
    dispatch(GetIdCardTemplate());
  }, []);

  const onClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    cardID != null && dispatch(DeleteIdCardTemplate(cardID));
    setOpen(false);
  };

  const handleEdit = (id: string) => {
    id != null && setCardID(id);
    setEditMode(true);
  };

  const handleDeleteConfirm = (id: string) => {
    if (id != null) {
      setCardID(id);
      setOpen(true);
    }
  };

  const handleActivate = (id: string, active: boolean) => {
    if (!active) {
      id != null && dispatch(ActivateOrDeactivateIdCardTemplate(id));
    }
  };

  if (editMode && cardID != null) {
    history.push(`/id-card/${cardID}/${mode}/update`);
  }

  return (
    <>
      <Paper style={{ margin: '8px 16px', padding: '20px' }}>
        {!isThere ? (
          <>
            <div style={{ textAlign: 'center' }}>
              <img src={DashImg} style={{ height: '320px' }} />
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    Opps! You've not created any ID Card templates yet.
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <Grid container justifyContent="center">
              <Link
                to="/browse-id-card-templates"
                style={{ textDecoration: 'none' }}
              >
                <FormButton style={{ minWidth: '150px', marginTop: '16px' }}>
                  Start Creating
                </FormButton>
              </Link>
            </Grid>
          </>
        ) : (
          <>
            <Grid container spacing={3} justifyContent="space-between">
              <Grid item xs={6} sm={3}>
                <span className={classes.title}>Your Templates</span>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Link
                  to="/browse-id-card-templates"
                  style={{ textDecoration: 'none' }}
                >
                  <FormButton className={classes.btnRight}>Add New</FormButton>
                </Link>
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <Button
                size="small"
                color="primary"
                className={
                  landscape ? classes.smallbtn : classes.smallbtnActive
                }
                onClick={handlePotraitClicked}
              >
                Potrait
              </Button>
              <Button
                size="small"
                color="primary"
                className={
                  landscape ? classes.smallbtnActive : classes.smallbtn
                }
                onClick={handleLandscapeClick}
              >
                Landscape
              </Button>
            </Grid>
            <Grid
              container
              justifyContent="center"
              style={{ marginTop: '15px' }}
            >
              <ButtonGroup aria-label="outlined primary button group">
                <Button
                  className={
                    !staffMode && !studentMode
                      ? classes.activeTab
                      : classes.nonactiveTab
                  }
                  onClick={handleAllClick}
                >
                  All
                </Button>
                <Button
                  className={
                    studentMode ? classes.activeTab : classes.nonactiveTab
                  }
                  onClick={handleStudentClick}
                >
                  Student
                </Button>
                <Button
                  className={
                    staffMode ? classes.activeTab : classes.nonactiveTab
                  }
                  onClick={handleStaffClick}
                >
                  Staff
                </Button>
              </ButtonGroup>
            </Grid>
            {!landscape ? (
              <Grid container spacing={3} style={{ marginTop: '10px' }}>
                {potraitTemplateData.map((item: any, index: number) => {
                  return (
                    <Grid item xs={4} key={index}>
                      <div className={classes.ImgContainer}>
                        <Card
                          className={classes.card}
                          style={{
                            border: item.active ? '3px solid #5ECD68' : '',
                            padding: '4px',
                          }}
                        >
                          <CardActionArea>
                            <img
                              src={checkImageUrl(item.thumbnail)}
                              style={{ height: '450px', width: '100%' }}
                            />
                          </CardActionArea>
                        </Card>
                        <div className={classes.ImgActions}>
                          <div className={classes.actions}>
                            <Tooltip
                              title="Activate"
                              className={classes.tooltip}
                              onClick={() =>
                                handleActivate(item.id, item.active)
                              }
                            >
                              <ExitToAppOutlinedIcon
                                className={classes.activeicon}
                                style={{ color: 'white' }}
                              />
                            </Tooltip>
                            <Tooltip
                              title="Edit"
                              className={classes.tooltip}
                              onClick={() => handleEdit(item.id)}
                            >
                              <EditOutlinedIcon
                                className={classes.editicon}
                                style={{ color: 'white' }}
                              />
                            </Tooltip>
                            <Tooltip
                              title="Remove"
                              className={classes.tooltip}
                              onClick={() => handleDeleteConfirm(item.id)}
                            >
                              <DeleteForeverOutlined
                                className={classes.deleteicon}
                                style={{ color: 'white' }}
                              />
                            </Tooltip>
                            <br />
                            {item.active && (
                              <b
                                style={{
                                  fontSize: '12px',
                                  color: 'white',
                                  textDecoration: 'underline',
                                  textUnderlinePosition: 'under',
                                }}
                              >
                                This is currently active.
                              </b>
                            )}
                          </div>
                        </div>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Grid container spacing={3} style={{ marginTop: '10px' }}>
                {landscapeTemplateData.length ? (
                  landscapeTemplateData.map((item: any, index: number) => {
                    return (
                      <Grid item xs={6} key={index}>
                        <div className={classes.ImgContainer}>
                          <Card
                            className={classes.card}
                            style={{
                              border: item.active ? '3px solid #5ECD68' : '',
                              padding: '4px',
                            }}
                          >
                            <CardActionArea>
                              <img
                                src={checkImageUrl(item.thumbnail)}
                                style={{ height: '300px', width: '100%' }}
                              />
                            </CardActionArea>
                          </Card>
                          <div className={classes.ImgActions}>
                            <div className={classes.actions}>
                              <Tooltip
                                title="Activate"
                                className={classes.tooltip}
                                onClick={() =>
                                  handleActivate(item.id, item.active)
                                }
                              >
                                <ExitToAppOutlinedIcon
                                  className={classes.activeicon}
                                  style={{ color: 'white' }}
                                />
                              </Tooltip>
                              <Tooltip
                                title="Edit"
                                className={classes.tooltip}
                                onClick={() => handleEdit(item.id)}
                              >
                                <EditOutlinedIcon
                                  className={classes.editicon}
                                  style={{ color: 'white' }}
                                />
                              </Tooltip>
                              <Tooltip
                                title="Remove"
                                className={classes.tooltip}
                                onClick={() => handleDeleteConfirm(item.id)}
                              >
                                <DeleteForeverOutlined
                                  className={classes.deleteicon}
                                  style={{ color: 'white' }}
                                />
                              </Tooltip>
                              <br />
                              {item.active && (
                                <b
                                  style={{
                                    fontSize: '12px',
                                    color: 'white',
                                    textDecoration: 'underline',
                                    textUnderlinePosition: 'under',
                                  }}
                                >
                                  This is currently active.
                                </b>
                              )}
                            </div>
                          </div>
                        </div>
                      </Grid>
                    );
                  })
                ) : (
                  <Grid container justifyContent="center">
                    <Typography
                      variant="body1"
                      component="span"
                      align="center"
                      style={{ marginTop: '50px' }}
                    >
                      Opps! No any templates are made in landscape mode.
                    </Typography>
                  </Grid>
                )}
              </Grid>
            )}
          </>
        )}
      </Paper>
      <ConfirmDialog
        actionConfirmed={handleDelete}
        onClose={onClose}
        open={open}
        title="Are you sure want to delete this template?"
        description="It will get removed from your template list but you can always add again to your template page."
        cancelText="CANCEL"
        agreeText="DELETE"
      />
    </>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//
export default MyIdCards;
function state(state: any, arg1: (RootStore: unknown) => any) {
  throw new Error('Function not implemented.');
}
