// ---------------------------- <START> module imports start ----------------------------------//
import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Paper,
  Box,
  InputLabel,
  TextField,
} from "@material-ui/core";
import { Link, useHistory, useParams } from "react-router-dom";
import { useTableStyles } from "../../Styles/TableStyles";
import { useTableWithPagination } from "../../Reusable";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import {
  DisableStaff,
  GetPaginatedStaffs,
  GetDisabledStaffs,
} from "../../../actions/HumanResource/Staff/StaffAction";
import { HeadCellsI } from "../../../actions";
import {
  ItemDeleteButton,
  ItemDisableButton,
  ItemEditButton,
  ItemEnableButton,
  ItemViewButton,
} from "../../Reusable/Buttons/TableButton";
// import getFullName from '../../utils/getFullName';
import getNewFullName from "../../utils/getNewFullName";
import useLoading from "../../../useHook/useLoading";
import UserAvatar from "../../Reusable/Profile/UserAvatar";
import CustomizedSelect from "../../Reusable/Inputs/Select";
import { paginationChoices } from "../../../common/json.constant";
import StaffSearch from "./StaffSearch";
import DisableDialog from "../../Reusable/Dialogs/DisableDialog";
import { useStyles } from "../../Styles/FormStyles";
import { setSnackbar } from "../../../actions/SnackbarAction";
import { DisabledStudentsType } from "../../../actions/HumanResource/Staff/StaffActionTypes";
// ----------------------------<END> module imports end ----------------------------------//

// -------------- <START> interface starts --------------------//
interface TableDataI {
  id: string;
  staff_photo: string;
  staff_name: string;
  designation: string;
  department: string;
  phone_no: string;
}

// -------------- <END> interface ends --------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N" },
  { id: "staff_name", label: "Staff Name" },
  { id: "designation", label: "Designation" },
  { id: "email", label: "Email" },
  { id: "action", label: "Action" },
];

// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const DisabledStaffList: React.FC = () => {
  const classes = useTableStyles();
  const styles = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();

  const [enableModalOpen, setEnableModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);

  // Retrieving re-usable components from useTable
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTableWithPagination(headCells);

  // Staff reducer
  const { disabled_staffs, loading, add_or_update } = useSelector(
    (state: RootStore) => state.staff
  );

  const [selectedPagination, setSelectedPagination] = useState<{
    key: string;
    value: string;
  }>({
    key: "10",
    value: "10",
  });

  useEffect(() => {
    if (selectedPagination != null) {
      dispatch(
        GetDisabledStaffs({ per_page: Number(selectedPagination.key), page: 1 })
      );
    }
  }, [selectedPagination]);

  useEffect(() => {
    if (add_or_update) {
      dispatch(
        GetDisabledStaffs({
          per_page: disabled_staffs.per_page,
          page: disabled_staffs.current_page,
        })
      );
    }
  }, [add_or_update]);

  const handleModalClose = (value: boolean) => {
    setEnableModalOpen(value);
  };

  const handleEnableModal = (id: string) => {
    setItemId(id);
    setEnableModalOpen(true);
  };

  const onRestoreConfirmed = (confirmed: boolean) => {
    if (confirmed && itemId) {
      dispatch(DisableStaff({ staff: itemId, remarks: "Restored" }));
      setEnableModalOpen(false);
    }
  };

  const handlePaginationChange = (paginationIndex: number) => {
    dispatch(
      GetDisabledStaffs({
        per_page: Number(selectedPagination.key),
        page: paginationIndex,
      })
    );
  };

  const handleImport = () => {
    history.push("/staff-import");
  };

  const getCustomTableRow = (item: DisabledStudentsType, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {`${item.user.first_name} ${item.user.last_name}`}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.designation ? item.designation.name : "--"}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.user.email || "--"}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ItemViewButton component={Link} to={`staff/profile/${item.id}`} />
          <ItemEnableButton onClick={() => handleEnableModal(item.id)} />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <Paper className={classes.rootTableBox}>
      <Typography variant="h6">Disabled Staff List</Typography>
      <Grid container justify="flex-end">
        <Box fontSize={10} textAlign="center">
          <CustomizedSelect
            label="Table Size"
            name="gender"
            options={paginationChoices}
            value={selectedPagination}
            setValue={(value: { key: string; value: string } | null) =>
              setSelectedPagination(value || { key: "10", value: "10" })
            }
          />
        </Box>
      </Grid>
      <TableContainer
        label="Staff List"
        loading={loading}
        items={disabled_staffs.results}
        headCells={headCells}
        forPagination={disabled_staffs}
        handlePaginationChange={handlePaginationChange}
        getCustomTableRow={getCustomTableRow}
        onImport={handleImport}
        disableSearch
      />
      <DisableDialog
        open={enableModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onRestoreConfirmed}
        dialogTitle="Are you sure to restore this record?"
        okText="Restore"
        title="Restore Staff"
      />
    </Paper>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default DisabledStaffList;
