import React, { useState, useEffect } from 'react';
import {
  Card,
  createStyles,
  makeStyles,
  Theme,
  CardContent,
  Box,
  CardMedia,
  Divider,
  Grid,
} from '@material-ui/core';
import clsx from 'clsx';
import { useIDCardStyles } from '../IDCardStyles';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../../store';
import checkActiveSignature from '../../../utils/checkActiveSignature';
import { GetReportSetting } from '../../../../actions/GeneralSettings/ReportSetting/ReportSettingAction';
import checkImageUrl from '../../../utils/checkImageUrl';
interface InfoPropsI {
  value: string | number;
  properties: string;
}

const dummyData = {
  school_name: 'St.Xavier Secondary School',
  school_address: 'Maitighar, kathmandu, Nepal',
  school_phone: '+977-01-4412323',
  slogan: 'We aim for excellence',
  student_name: 'K.K Adhikari',
  student_photo:
    'https://upload.wikimedia.org/wikipedia/commons/thumb/7/76/MiB.svg/1200px-MiB.svg.png',
  admission_no: '000045',
  father_name: 'M.B. Adhikari',
  address: 'Nepal',
  dob: '2048-03-12',
  blood_group: 'B+',
  phone_number: '1234567890',
};

export const StudentInfo = (props: InfoPropsI) => {
  const { value, properties } = props;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '2px 10px',
      }}
    >
      <Box fontSize={14} fontWeight="fontWeightRegular" textAlign="center">
        {properties}
      </Box>
      <Box fontSize={14} fontWeight="fontWeightRegular" textAlign="center">
        {value}
      </Box>
    </div>
  );
};

const PotraitStudentID = (props: any) => {
  const { data } = props;
  const dispatch = useDispatch();

  const classes = useIDCardStyles();

  const [item, setItem] = useState<any>(null);
  const [url, setUrl] = useState<string>('');

  const general_info = useSelector(
    (state: RootStore) => state.auth?.general_info
  );
  const report_setting = useSelector(
    (state: RootStore) => state.report_setting?.reportsetting
  );

  useEffect(() => {
    const n = window.location.href.lastIndexOf('/');
    setUrl(window.location.href.substring(n + 1));
    dispatch(GetReportSetting());
  }, []);

  useEffect(() => {
    props.content != null && setItem(props.content);
  }, [props.content]);

  const renderValue = (real: any, fake: any) => {
    if (url === 'customize' || url === 'update') {
      return fake;
    } else {
      return real;
    }
  };

  return (
    <>
      <Card
        style={{
          backgroundColor: item?.back_ground_color[2].value,
          maxWidth: '330px',
        }}
      >
        <Grid
          container
          style={{
            minHeight: '200px',
            backgroundColor: item?.back_ground_color[0].value,
            padding: '10px',
          }}
          spacing={1}
        >
          <Grid item xs={3}>
            {/* <img
              className={classes.logo}
              src={general_info?.logo}
              height="80px"
            /> */}
            <CardMedia
              className={classes.logoImage}
              image={general_info?.logo}
              title=""
            />
          </Grid>
          <Grid item xs={9}>
            <div>
              <Box
                style={{ color: item?.text_color[0].value }}
                fontSize={18}
                fontWeight="fontWeightBold"
                textAlign="center"
              >
                {general_info?.school_name}
              </Box>
              <Box
                style={{ color: item?.text_color[0].value }}
                fontSize={12}
                fontWeight="fontWeightRegular"
                textAlign="center"
              >
                {general_info?.slogan}
              </Box>
            </div>
          </Grid>
        </Grid>

        <CardMedia
          className={classes.idImage}
          image={renderValue(
            checkImageUrl(data?.student_photo),
            dummyData?.student_photo
          )}
          title=""
        />
        <div style={{ color: item?.text_color[2].value }}>
          <Box fontSize={18} textAlign="center" fontWeight={500}>
            {renderValue(data?.student_name, dummyData?.student_name)}
          </Box>
          <CardContent className={clsx(classes.content, classes.column)}>
            <StudentInfo
              properties="Admission No.:"
              value={renderValue(data?.admission_no, dummyData?.admission_no)}
            />
            <StudentInfo
              properties="Guardian's Name:"
              value={renderValue(data?.father_name, dummyData?.father_name)}
            />
            <StudentInfo
              properties="Address:"
              value={renderValue(data?.address, dummyData?.address)}
            />
            <StudentInfo
              properties="DOB / B.Group"
              value={renderValue(
                data?.dob + ' / ' + data?.blood_group,
                dummyData?.dob + ' / ' + dummyData?.blood_group
              )}
            />
            <StudentInfo
              properties="Phone Number"
              value={renderValue(data?.phone_number, dummyData?.phone_number)}
            />
          </CardContent>
        </div>
        <Divider />
        <div style={{ marginTop: '5px', padding: '0 20px' }}>
          <Grid container justifyContent="flex-end">
            <img
              src={checkActiveSignature(report_setting)}
              alt=""
              height="20px"
              width="50px"
            />
          </Grid>
          <Box
            fontSize={14}
            textAlign="right"
            style={{ color: item?.text_color[2].value }}
          >
            Principal
          </Box>
        </div>

        <div
          style={{
            backgroundColor: item?.back_ground_color[1].value,
            padding: '10px',
          }}
        >
          <Box
            style={{ color: item?.text_color[1].value }}
            fontSize={12}
            fontWeight="fontWeightRegular"
            textAlign="center"
          >
            {general_info?.address},{general_info?.phone_number}
          </Box>
        </div>
      </Card>
    </>
  );
};

export default PotraitStudentID;
