import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "@material-ui/core";
import styled from "styled-components";
import ReactToPrint from "react-to-print";
import { CombinedStudentMarksI } from "../../../rtk/features/exam/marksLedger/marksLedger";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import { GetGrades } from "../../../actions/Examination/Grade/GradeAction";
import GradeSheetCombined from "./GradeSheetCombined";

const Container = styled.div`
  padding: 20px;
`;

interface GeneralInfoI {
  id: number;
  logo: string;
  school_name: string;
  address: string;
  phone_number: string;
  email: string;
  slogan: string;
}

interface DialogProps {
  open: boolean;
  onClose: () => void;
  studentId: string[];
}

const PrintPreviewCombined = (props: DialogProps) => {
  const dispatch = useDispatch();
  const { onClose, open, studentId } = props;
  const printRef = useRef<HTMLDivElement | null>(null);

  const [marksheets, setMarksheets] = useState<CombinedStudentMarksI[]>([]);

  const { ledger, combinedLedger } = useSelector(
    (state: RootStore) => state.marksLedger
  );
  const { grades } = useSelector((state: RootStore) => state.exam_grade);

  const { general_info } = useSelector(
    (state: RootStore) => state.general_info
  );

  useEffect(() => {
    dispatch(GetGrades());
  }, []);

  useEffect(() => {
    if (combinedLedger) {
      const result = combinedLedger.student_marks.filter((el) =>
        studentId.includes(el.student)
      );

      setMarksheets(result);
    }
  }, [studentId]);

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="print-dialog"
      open={open}
      maxWidth="lg"
    >
      <Container>
        <ReactToPrint
          trigger={() => (
            <p
              style={{
                backgroundColor: "#132E98",
                color: "white",
                fontWeight: "bold",
                borderRadius: "0px",
                margin: "0px",
                textAlign: "center",
                padding: "5px 0px",
                cursor: "pointer",
              }}
            >
              Print This Out !
            </p>
          )}
          content={() => printRef.current}
          removeAfterPrint={true}
          copyStyles={true}
        />
        <div ref={printRef}>
          {combinedLedger &&
            marksheets.map((el, index) => (
              <GradeSheetCombined
                key={index + 1}
                schoolInfo={{
                  name: general_info.school_name,
                  address: general_info.address,
                  phone: general_info.phone_number,
                  logo: general_info.logo || "",
                }}
                gradeInfo={{ grade: "", totalStudents: 0 }}
                subjects={combinedLedger.exam_subjects.map((es) => ({
                  id: es.subject__id,
                  name: es.subject__name,
                  hasPractical: es.has_practical,
                  markingType: es.result_type,
                  creditHour: es.credit_hour,
                }))}
                exams={combinedLedger.exam_info.map((ei) => ({
                  id: ei.exam_info_id,
                  name: ei.exam_id,
                  weightage: ei.weightage_percentage,
                }))}
                marksheet={el}
                grades={grades}
              />
            ))}
        </div>
      </Container>
    </Dialog>
  );
};

export default PrintPreviewCombined;
