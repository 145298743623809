import React from "react";
import { PageNotFound } from "../../components";

interface ErrorPageProps {
  fullPage?: boolean;
}

const ErrorPage = ({ fullPage }: ErrorPageProps) => (
  <div style={fullPage ? { marginTop: "-110px" } : {}}>
    <PageNotFound />
  </div>
);

export default ErrorPage;

const ErrorFullPage = () => {
  return <ErrorPage fullPage />;
};

export { ErrorFullPage };
