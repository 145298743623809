export const GET_RESULT_LEDGER_LOADING = "GET_RESULT_LEDGER_LOADING";
export const GET_RESULT_LEDGER_ERROR = "GET_RESULT_LEDGER_ERROR";
export const GET_RESULT_LEDGER_SUCCESS = "GET_RESULT_LEDGER_SUCCESS";

//===============================<START>GET RESULT_LEDGER<START>======================//

export interface ResultLedgerLoading {
  type: typeof GET_RESULT_LEDGER_LOADING;
}

export interface ResultLedgerError {
  type: typeof GET_RESULT_LEDGER_ERROR;
}

export interface ResultLedgerSuccess {
  type: typeof GET_RESULT_LEDGER_SUCCESS;
  payload: any;
}

//===============================<END>DELETE RESULT_LEDGER<END>======================//

export type ResultLedgerDispatchTypes =
  | ResultLedgerLoading
  | ResultLedgerError
  | ResultLedgerSuccess;
