import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
} from "@material-ui/core";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useCardStyles } from "../../Styles/CardStyles";
import { DeleteForeverOutlined, VisibilityOutlined } from "@material-ui/icons";
import ActionButton from "../../Reusable/Buttons/ActionButton";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import { GetDepartments } from "../../../actions/HumanResource/Department/DepartmentAction";
import {
  getAttendanceDevices,
  syncAttendanceDevice,
} from "../../../rtk/features/attendanceDevice/attendanceDeviceThunk";
import { useHistory } from "react-router-dom";
import SyncIcon from "@material-ui/icons/Sync";
import BackDropLoader from "../../Reusable/BackDropLoader";

const Wrapper = styled.div`
  margin-top: 16px;
  max-width: 600px;
`;

const TableCap = styled.div`
  max-height: 50vh;
`;

const NoDataCell = styled.td`
  padding: 4rem 0;
  background-color: #f2f2f2;
  text-align: center;
`;

const DeleteIcon = styled(DeleteForeverOutlined)`
  && {
    color: #e96c5a;
  }
`;

const ViewIcon = styled(VisibilityOutlined)`
  && {
    color: #3fc581;
  }
`;

export default function DevicesListTab({ id }: { id: string | null }) {
  const classes = useCardStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [confirmModal, toggleConfirmModal] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      dispatch(getAttendanceDevices(id));
    }
  }, [id]);

  const { loading, attendanceDevicesList } = useSelector(
    (state: RootStore) => state.attendanceDevice
  );

  const handleRemoveClick = (id: string) => {
    toggleConfirmModal(true);
  };

  const handleSyncClick = (sn: string) => {
    dispatch(syncAttendanceDevice({ config: id!, deviceSn: sn }));
  };

  const onDeleteConfirmed = () => {
    //
  };

  return (
    <>
      <Wrapper>
        <TableContainer className={classes.roota}>
          <TableCap>
            <Table
              size="small"
              className={classes.table}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>S.N</TableCell>
                  <TableCell>Device Name</TableCell>
                  <TableCell>Serial No</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {attendanceDevicesList.length ? (
                  attendanceDevicesList.map((row, index) => (
                    <TableRow key={index + 1}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{row.alias}</TableCell>
                      <TableCell>{row.sn}</TableCell>
                      <TableCell>
                        <ActionButton
                          title="View Device"
                          onClick={() =>
                            history.push(`/attendance-device/${id}/${row.id}`)
                          }
                        >
                          <ViewIcon />
                        </ActionButton>
                        <ActionButton
                          title="Sync Device"
                          onClick={() => handleSyncClick(row.sn)}
                        >
                          <SyncIcon />
                        </ActionButton>
                        <ActionButton
                          title="Remove Device"
                          onClick={() => handleRemoveClick(String(row.id))}
                        >
                          <DeleteIcon />
                        </ActionButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <NoDataCell colSpan={5}>
                      {loading ? "Loading..." : "No Devices added."}
                    </NoDataCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableCap>
        </TableContainer>
      </Wrapper>

      <CustomizedDialogs
        open={confirmModal}
        onClose={() => toggleConfirmModal(false)}
        deleteConfirmed={onDeleteConfirmed}
      />
    </>
  );
}
