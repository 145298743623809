import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getPrayer, updatePrayer } from "./prayerApi";

interface InitialStateI {
  loading: boolean;
  prayer: {
    id: string;
    prayer_field: string;
  } | null;
  actionPerformed: boolean;
}

const initialState: InitialStateI = {
  loading: false,
  prayer: null,
  actionPerformed: false,
};

const prayerSlice = createSlice({
  name: "prayer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPrayer.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(
      getPrayer.fulfilled,
      (
        state,
        { payload }: PayloadAction<{ id: string; prayer_field: string }>
      ) => {
        state.loading = false;
        if (Object.keys(payload).length) {
          state.prayer = payload;
        } else {
          state.prayer = null;
        }
      }
    );
    builder.addCase(getPrayer.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updatePrayer.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(updatePrayer.fulfilled, (state) => {
      state.loading = false;
      state.actionPerformed = true;
    });
    builder.addCase(updatePrayer.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default prayerSlice.reducer;
