import {
    Paper
} from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ScholarshipSidebar } from "../../components";
import BackDropLoader from "../../components/Reusable/BackDropLoader";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";
import ScholarshipReportSearch from "../../components/Scholarship/ScholarshipReport/ScholarshipReportSearch";
import ScholarshipReportTable from "../../components/Scholarship/ScholarshipReport/ScholarshipReportTable";
// import FeeIncomeSearch from "./FeeIncomeSearch";
// import FeeIncomeTable from "./FeeIncomeTable";

export default function ScholarshipReportPage() {
    const [student, setStudent] = useState<any>(null);
    const [fee, setFee] = useState<any>(null);

    const [loading, setLoading] = useState<boolean>(false);

    // const [selected, setSelected] = useState<any[]>([]);

    const dispatch = useDispatch();

    const nav = <ScholarshipSidebar scholarship_report />;

    return (

        <SubModuleLayout sideNav={nav}>
            <Paper style={{ margin: "10px" }}>
                <ScholarshipReportSearch student={student} setStudent={setStudent} fee={fee} setFee={setFee} />
                <ScholarshipReportTable fee={fee} setFee={setFee} />
            </Paper>
            <BackDropLoader open={loading} />
        </SubModuleLayout>
    );
}