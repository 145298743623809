import {
  GET_BUS_USER_LOADING,
  GET_BUS_USER_ERROR,
  GET_BUS_USER_SUCCESS,
  BusUserDispatchTypes,
  ADD_BUS_USER_LOADING,
  ADD_BUS_USER_ERROR,
  ADD_BUS_USER_SUCCESS,
  DELETE_BUS_USER_LOADING,
  DELETE_BUS_USER_ERROR,
  DELETE_BUS_USER_SUCCESS,
  UPDATE_BUS_USER_LOADING,
  UPDATE_BUS_USER_ERROR,
  UPDATE_BUS_USER_SUCCESS,
  BusUserTypeI,
} from "../../actions/Transport/BusUser/BusUserActionTypes";
import {
  DELETE_TRANSPORT_STUDENT_SUCCESS,
  TransportDispatchTypes,
} from "../../actions/Transport/Routes/TransportActionTypes";

interface InitialStateI {
  loading: boolean;
  bus_user: BusUserTypeI[];
}

const initialState: InitialStateI = {
  loading: false,
  bus_user: [],
};

const BusUserReducer = (
  state: InitialStateI = initialState,
  action: BusUserDispatchTypes | TransportDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_BUS_USER_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_BUS_USER_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_BUS_USER_SUCCESS:
      return {
        loading: false,
        bus_user: action.payload,
      };

    case ADD_BUS_USER_LOADING:
      return {
        ...state,
        loading: true,
      };

    case ADD_BUS_USER_ERROR:
      return {
        ...state,
        loading: false,
      };

    case ADD_BUS_USER_SUCCESS:
      return {
        loading: false,
        bus_user: [...state.bus_user, action.payload],
      };

    case UPDATE_BUS_USER_LOADING:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_BUS_USER_ERROR:
      return {
        ...state,
        loading: false,
      };

    case UPDATE_BUS_USER_SUCCESS:
      const current_data: BusUserTypeI[] = state.bus_user;
      const index: number = current_data.findIndex(
        (element) => element.student_id === action.payload.student_id
      );
      current_data[index] = action.payload;

      return {
        loading: false,
        bus_user: current_data,
      };

    case DELETE_BUS_USER_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_BUS_USER_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_BUS_USER_SUCCESS:
      const new_data: BusUserTypeI[] = state.bus_user;
      const item_index = new_data.findIndex(
        (element) => element.student_id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return {
        loading: false,
        bus_user: new_data,
      };

    case DELETE_TRANSPORT_STUDENT_SUCCESS:
      const n_data: BusUserTypeI[] = state.bus_user;
      const i_index = n_data.findIndex(
        (element) => element.student_id === action.payload.id
      );
      n_data.splice(i_index, 1);

      return {
        loading: false,
        bus_user: n_data,
      };

    default:
      return state;
  }
};

export default BusUserReducer;
