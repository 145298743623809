// ---------------- <START> module import ends ------------------//
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import {
  Paper,
  Grid,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Box,
  AppBar,
  Tabs,
  Tab,
  Switch,
  InputLabel,
  IconButton,
} from '@material-ui/core';
import { useTable } from '../../Reusable';
import { useTableStyles } from '../../Styles/TableStyles';
import SearchIcon from '@material-ui/icons/Search';

import {
  GetEvents,
  DeleteEvent,
} from '../../../actions/Event/Events/EventsAction';
import CustomizedDialogs from '../../Reusable/Dialogs/DeleteDialog';
import {
  ItemDeleteButton,
  ItemEditButton,
  PostAddButton,
  ItemViewButton,
} from '../../Reusable/Buttons/TableButton';
import { dateConverterAdToBs } from '../../utils/dateConverter';
import { fromToConverter } from '../../utils/dateTimeFormat';
import Popups from '../../Reusable/Dialogs/Popups';
import BraftEditor from 'braft-editor';
import styled from 'styled-components';
import EventNoticeForm from './EventNoticeForm';
import { noticeBraftData } from './NoticeMockup';
import { Autocomplete } from '@material-ui/lab';
import { useFormStyles } from '../../Styles/FormStyles';
import { ClassTypeI } from '../../../actions/Academics/Class/ClassActionTypes';
import { SectionTypeI } from '../../../actions/Academics/Section/SectionActionTypes';
import { useForm } from 'react-hook-form';
import { GetClasses } from '../../../actions/Academics/Class/ClassAction';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

interface ManageEventParticipantsProps {
  [x: string]: any;
}

const headCells: any = [
  { id: 'sn', label: 'S.N' },
  { id: 'name', label: 'Participant Name' },
  { id: 'class', label: 'Class' },
  { id: 'address', label: 'Address' },
  { id: 'action', label: 'Action' },
];

const tableData = [
  {
    id: '1',
    name: 'Aashish Maharjan',
    class: '10(C)',
    address: 'Bafal, Kathmandu',
  },
  {
    id: '2',
    name: 'Manoj Acharya',
    class: '7(A)',
    address: 'Pokhara, Nepal',
  },
  {
    id: '3',
    name: 'Lumus Shah',
    class: '8(D)',
    address: 'Bafal, Kathmandu',
  },
  {
    id: '4',
    name: 'Aashish Maharjan',
    class: '10(C)',
    address: 'Pokhara, Nepal',
  },
  {
    id: '5',
    name: 'Manoj Acharya',
    class: '7(A)',
    address: 'Bafal, Kathmandu',
  },
  {
    id: '6',
    name: 'Lumus Shah',
    class: '8(D)',
    address: 'Argakhachi, Nepal',
  },
  {
    id: '7',
    name: 'Aashish Maharjan',
    class: '10(C)',
    address: 'Bafal, Kathmandu',
  },
  {
    id: '8',
    name: 'Manoj Acharya',
    class: '7(A)',
    address: 'Pokhara, Nepal',
  },
  {
    id: '9',
    name: 'Lumus Shah',
    class: '8(D)',
    address: 'Bafal, Kathmandu',
  },
  {
    id: '10',
    name: 'Aashish Maharjan',
    class: '10(C)',
    address: 'Pokhara, Nepal',
  },
  {
    id: '11',
    name: 'Manoj Acharya',
    class: '7(A)',
    address: 'Bafal, Kathmandu',
  },
  {
    id: '12',
    name: 'Lumus Shah',
    class: '8(D)',
    address: 'Argakhachi, Nepal',
  },
];

const StyledBox = styled(Box)`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 16px;
`;

const StyledIconButton = styled(({ children, ...rest }) => (
  <IconButton {...rest}>{children}</IconButton>
))`
  font-size: 16px;
  border-radius: 6px;
  padding: 2px;
  color: ${(props) => props.theme.palette.primary.light};
  border: 2px solid ${(props) => props.theme.palette.primary.light};
  margin: 0px 4px;

  ${(props) =>
    props.success &&
    `color: ${props.theme.palette.success.light}; 
     border-color: ${props.theme.palette.success.light};
    `}

  ${(props) =>
    props.error &&
    `color: ${props.theme.palette.error.light}; 
     border-color: ${props.theme.palette.error.light};
    `}
`;

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const ManageEventParticipants = (props: ManageEventParticipantsProps) => {
  const [tabValue, setTabValue] = React.useState(0);
  const dispatch = useDispatch();
  const classes = useFormStyles();
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  const { register, handleSubmit, errors, setValue } = useForm();

  const [selectedClass, setSelectedClass] = useState<ClassTypeI | null>(null);
  const [classChoices, setClassChoices] = useState<ClassTypeI[]>([]);
  const [selectedSection, setSelectedSection] = useState<SectionTypeI | null>(
    null
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [sectionChoices, setSectionChoices] = useState<SectionTypeI[] | []>([]);
  const [sectionDisabler, setSectionDisabler] = useState<boolean>(true);

  const classSelector = useSelector((state: RootStore) => state.class);
  const classLoading = useSelector((state: RootStore) => state.class.loading);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (!classLoading) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [classLoading]);

  useEffect(() => {
    dispatch(GetClasses());
  }, []);

  useEffect(() => {
    setClassChoices(classSelector.classes);
  }, [classSelector]);

  const handleClassChange = (value: ClassTypeI | null) => {
    setSelectedClass(value);
    setSelectedSection(null);
    value != null
      ? populateSectionChoices(value.section)
      : setSectionDisabler(true);
  };

  const populateSectionChoices = (sections: SectionTypeI[] | []) => {
    setSectionDisabler(false);
    sections.length > 0
      ? setSectionChoices(sections)
      : setSectionDisabler(true);
  };

  const getCustomTableRow = (item: any) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center">{item.id}</StyledTableCell>
        <StyledTableCell align="center">{item.name}</StyledTableCell>
        <StyledTableCell align="center">{item.class}</StyledTableCell>
        <StyledTableCell align="center">{item.address}</StyledTableCell>
        <StyledTableCell align="center">
          <StyledIconButton aria-label="Decline" size="small" error>
            <CloseIcon fontSize="inherit" />
          </StyledIconButton>

          <StyledIconButton aria-label="Approve" size="small" success>
            <CheckIcon fontSize="inherit" />
          </StyledIconButton>
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <React.Fragment>
      <AppBar position="static" color="default" elevation={0}>
        <Tabs value={tabValue} onChange={handleChange}>
          <Tab label="Events Participants" />
          <Tab label="Participants Filter" />
          <Tab label="Participants Request" />
        </Tabs>
      </AppBar>
      <TabPanel value={tabValue} index={0}>
        <TableContainer
          items={tableData}
          headCells={headCells}
          getCustomTableRow={getCustomTableRow}
          search_name="name"
          rowsPerPage={7}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Grid container spacing={3}>
          <Grid item xs={3} className={classes.formWrapper}>
            <InputLabel>Select Class</InputLabel>
            <Autocomplete
              onChange={(
                event: React.ChangeEvent<{}>,
                value: ClassTypeI | null
              ) => handleClassChange(value)}
              options={classChoices}
              getOptionLabel={(option) => option.grade_name}
              value={selectedClass}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Label"
                  name="class_name"
                  inputRef={register({ required: true })}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formWrapper}>
            <InputLabel>Select Section</InputLabel>
            <Autocomplete
              value={selectedSection}
              onChange={(
                event: React.ChangeEvent<{}>,
                value: SectionTypeI | null
              ) => setSelectedSection(value)}
              options={sectionChoices}
              getOptionLabel={(option) => option.name}
              disabled={sectionDisabler}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Label"
                  name="session_name"
                  inputRef={register}
                  variant="outlined"
                />
              )}
            />
          </Grid>

          <Grid item xs={3} className={classes.formWrapper}>
            <Button
              className={classes.searchBoxButton}
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<SearchIcon />}
            >
              Search
            </Button>
          </Grid>
        </Grid>

        <TableContainer
          items={tableData}
          headCells={headCells}
          getCustomTableRow={getCustomTableRow}
          search_name="name"
          rowsPerPage={7}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <TableContainer
          items={tableData}
          headCells={headCells}
          getCustomTableRow={getCustomTableRow}
          search_name="name"
          rowsPerPage={7}
        />
      </TabPanel>
    </React.Fragment>
  );
};

export default ManageEventParticipants;
