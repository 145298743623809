import {
  makeStyles,
  Typography,
  Paper,
  Theme,
  createStyles,
} from "@material-ui/core";
import { Pie } from "react-chartjs-2";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
      maxHeight: "72vh",
    },
  })
)

const data = {
  labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  datasets: [
    {
      label: 'Rubric data',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    },
  ],
};
const options = {
  responsive: true,
  maintainAspectRatio: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Chart.js Bar Chart",
    },
  },
}

const RubricTab = () => {
  const classes = useStyles();
  return (
    <Paper className={classes.container} elevation={1}>
      {/* <Typography align="left" variant="h5" component="h3" style={{ marginBottom: "1em" }}>
        Rubric Info
      </Typography> */}
      <Pie data={data} options={options} />
    </Paper>
  )
}

export default RubricTab