export const GET_DASHBOARD_DATA_LOADING = "GET_DASHBOARD_DATA_LOADING";
export const GET_DASHBOARD_DATA_ERROR = "GET_DASHBOARD_DATA_ERROR";
export const GET_DASHBOARD_DATA_SUCCESS = "GET_DASHBOARD_DATA_SUCCESS";

//===============================<START>GET DASHBOARD_DATA<START>======================//

export interface GetDashboardDataLoading {
  type: typeof GET_DASHBOARD_DATA_LOADING;
}

export interface GetDashboardDataError {
  type: typeof GET_DASHBOARD_DATA_ERROR;
}

export interface GetDashboardDataSuccess {
  type: typeof GET_DASHBOARD_DATA_SUCCESS;
  payload: any;
}

//===============================<END>DELETE DASHBOARD_DATA<END>======================//

export type DashboardDataDispatchTypes =
  | GetDashboardDataLoading
  | GetDashboardDataError
  | GetDashboardDataSuccess;
