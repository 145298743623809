import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_EXAM_GRADE_LOADING,
  GET_EXAM_GRADE_ERROR,
  GET_EXAM_GRADE_SUCCESS,
  GradeDispatchTypes,
  ADD_EXAM_GRADE_LOADING,
  ADD_EXAM_GRADE_SUCCESS,
  ADD_EXAM_GRADE_ERROR,
  DELETE_EXAM_GRADE_SUCCESS,
  DELETE_EXAM_GRADE_ERROR,
  DELETE_EXAM_GRADE_LOADING,
  UPDATE_EXAM_GRADE_LOADING,
  UPDATE_EXAM_GRADE_SUCCESS,
  UPDATE_EXAM_GRADE_ERROR,
} from "./GradeActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

interface RequestDataI {
  grade_name: string;
  marks_from: number;
  marks_to: number;
  grade_point: number;
}

export const GetGrades = () => async (
  dispatch: Dispatch<GradeDispatchTypes | SetSnackBarI>
) => {
  try {
    dispatch({
      type: GET_EXAM_GRADE_LOADING,
    });

    const res = await axios.get(
      `${ADMIN_API_URL}/exam-result-grade/`,
      HeaderConfig()
    );

    dispatch({
      type: GET_EXAM_GRADE_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_EXAM_GRADE_ERROR,
    });

    // dispatch({
    //   type: SET_SNACKBAR,
    //   payload: {
    //     snackbarOpen: true,
    //     snackbarType: "error",
    //     snackbarMessage: "Grades Loading Failed",
    //   },
    // });
  }
};

export const AddGrade = (data: RequestDataI) => async (
  dispatch: Dispatch<GradeDispatchTypes | SetSnackBarI>
) => {
  try {
    dispatch({
      type: ADD_EXAM_GRADE_LOADING,
    });

    const res = await axios.post(
      `${ADMIN_API_URL}/exam-result-grade/`,
      data,
      HeaderConfig()
    );

    dispatch({
      type: ADD_EXAM_GRADE_SUCCESS,
      payload: res.data,
    });

    dispatch({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarType: "success",
        snackbarMessage: "Grade Added Successfully",
      },
    });
  } catch (error) {
    dispatch({
      type: ADD_EXAM_GRADE_ERROR,
    });

    dispatch({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarType: "error",
        snackbarMessage: "Grade Add Failed",
      },
    });
  }
};

export const UpdateGrade = (id: number, data: RequestDataI) => async (
  dispatch: Dispatch<GradeDispatchTypes | SetSnackBarI>
) => {
  try {
    dispatch({
      type: UPDATE_EXAM_GRADE_LOADING,
    });

    const res = await axios.put(
      `${ADMIN_API_URL}/exam-result-grade/${id}/`,
      data,
      HeaderConfig()
    );

    dispatch({
      type: UPDATE_EXAM_GRADE_SUCCESS,
      payload: res.data,
    });

    dispatch({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarType: "success",
        snackbarMessage: "Grade Updated Successfully",
      },
    });
  } catch (error) {
    dispatch({
      type: UPDATE_EXAM_GRADE_ERROR,
    });

    dispatch({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarType: "error",
        snackbarMessage: "Grade Update Failed",
      },
    });
  }
};

export const DeleteGrade = (id: number) => async (
  dispatch: Dispatch<GradeDispatchTypes | SetSnackBarI>
) => {
  try {
    dispatch({
      type: DELETE_EXAM_GRADE_LOADING,
    });

    const res = await axios.delete(
      `${ADMIN_API_URL}/exam-result-grade/${id}/`,
      HeaderConfig()
    );

    dispatch({
      type: DELETE_EXAM_GRADE_SUCCESS,
      payload: { id: id },
    });

    dispatch({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarType: "success",
        snackbarMessage: "Grade Deleted Successfully",
      },
    });
  } catch (error) {
    dispatch({
      type: DELETE_EXAM_GRADE_ERROR,
    });

    dispatch({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarType: "error",
        snackbarMessage: "Grade Delete Failed",
      },
    });
  }
};
