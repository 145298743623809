export const GET_SYLLABUS_LOADING = "GET_SYLLABUS_LOADING";
export const GET_SYLLABUS_ERROR = "GET_SYLLABUS_ERROR";
export const GET_SYLLABUS_SUCCESS = "GET_SYLLABUS_SUCCESS";

export const GET_SUB_UNIT_LOADING = "GET_SUB_UNIT_LOADING";
export const GET_SUB_UNIT_ERROR = "GET_SUB_UNIT_ERROR";
export const GET_SUB_UNIT_SUCCESS = "GET_SUB_UNIT_SUCCESS";

export type AdminSyllabusTypeI = {
  id: number;
  name: string;
  subject: string;
  academic_class: number;
  teaching_hours: string;
  general_info: number;
};

export type AdminSubUnitTypeI = {
  id: number;
  unit: AdminSyllabusTypeI;
  sub_unit: number;
  title: string;
  completed: boolean;
};

//===============================<START>GET SYLLABUS<START>======================//

export interface GetSyllabusLoading {
  type: typeof GET_SYLLABUS_LOADING;
}

export interface GetSyllabusError {
  type: typeof GET_SYLLABUS_ERROR;
}

export interface GetSyllabusSuccess {
  type: typeof GET_SYLLABUS_SUCCESS;
  payload: AdminSyllabusTypeI[];
}

//===============================<END>GET SYLLABUS<END>======================//

//===============================<START>GET SUB_UNIT<START>======================//

export interface GetSubUnitLoading {
  type: typeof GET_SUB_UNIT_LOADING;
}

export interface GetSubUnitError {
  type: typeof GET_SUB_UNIT_ERROR;
}

export interface GetSubUnitSuccess {
  type: typeof GET_SUB_UNIT_SUCCESS;
  payload: AdminSubUnitTypeI[];
}

//===============================<END>GET SUB_UNIT<END>======================//

export type SyllabusDispatchTypes =
  | GetSyllabusLoading
  | GetSyllabusError
  | GetSyllabusSuccess
  | GetSubUnitLoading
  | GetSubUnitError
  | GetSubUnitSuccess;
