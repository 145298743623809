import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import EmailIcon from "@material-ui/icons/Email";
import AdmitCardSample1 from "../AdmitCardDesign/AdmitCardSample1";
import AdmitCardSample2 from "../AdmitCardDesign/AdmitCardSample2";

export interface SimpleDialogProps {
  student: any;
}

export default function AdmitCardPopup(props: any) {
  const { activeID, idCard, examType } = props;

  const componentRef = useRef<any>();

  const renderSwitch = (param: string) => {
    switch (param) {
      case "ABC-003":
        return (
          <AdmitCardSample1
            content={activeID}
            data={idCard}
            examType={examType}
          />
        );
      case "ABC-004":
        return (
          <AdmitCardSample2
            content={activeID}
            data={idCard}
            examType={examType}
          />
        );
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          backgroundColor: "#657ff3",
        }}
      >
        <ReactToPrint
          trigger={() => (
            <div
              style={{
                backgroundColor: "#657ff3",
                color: "white",
                fontWeight: "bold",
                borderRadius: "0px",
                margin: "0px",
                textAlign: "center",
                padding: "2px 0px 2px 0px",
                cursor: "pointer",
              }}
            >
              <PrintIcon />
            </div>
          )}
          content={() => componentRef.current}
          removeAfterPrint={true}
          copyStyles={true}
        />
        <ReactToPrint
          trigger={() => (
            <div
              style={{
                backgroundColor: "#657ff3",
                color: "white",
                fontWeight: "bold",
                borderRadius: "0px",
                margin: "0px",
                textAlign: "center",
                padding: "2px 0px 2px 0px",
                cursor: "pointer",
              }}
            >
              <EmailIcon />
            </div>
          )}
          content={() => componentRef.current}
          removeAfterPrint={true}
          copyStyles={true}
        />
      </div>
      <div id="print-element" ref={componentRef}>
        <div className="marksheet-print">{renderSwitch(activeID?.code)}</div>
      </div>
    </>
  );
}
