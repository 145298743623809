import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Badge,
  Divider,
  Grid,
  IconButton,
  Menu,
  Typography,
  Button,
  Box,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Notifications } from '@material-ui/icons';
import NotificationContent from './NotificationContent';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { GetNotifications } from '../../../actions/HumanResource/Notification/NotificationAction';
import { RootStore } from '../../../store';
import Notification from './Notification.styles';
import InfiniteScroll from 'react-infinite-scroll-component';
import { notificationData } from './Notification.data';
import moment from 'moment';

export interface NotificationTypeI {
  id: number;
  avatar: string;
  name: string;
  message: string;
  time: string;
  hasRead: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popoverMenu: {
      overflow: 'initial',
      marginTop: '48px',
      '& .MuiMenu-paper': {
        width: '380px!important',
        boxShadow:
          'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) -20px 20px 40px -4px',
        maxHeight: 'calc(100vh - 60px)',
      },
      '&  ul': { paddingTop: '0px!important', paddingBottom: '0px!important' },
      '& > .MuiPaper-root.MuiMenu-paper.MuiPopover-paper': {
        overflow: 'initial',
        position: 'relative',
        boxShadow: '1px 1px 25px -4px rgb(0 0 0 / 17%)',

        '&::before': {
          content: "''",
          position: 'absolute',
          top: '0px',
          left: 'calc(50% - 4px)',
          width: '30px',
          height: '30px',
          background: 'white',
          transform: 'rotate(45deg) translateX(-50%)',
          zIndex: '999999',
        },
      },
    },
    popoverMenu2: {
      overflow: 'initial',
      marginTop: '48px',
      '& .MuiMenu-paper': { width: '380px!important', marginTop: '28px' },
      '&  ul': { paddingTop: '0px!important', paddingBottom: '0px!important' },
      '& > .MuiPaper-root.MuiMenu-paper.MuiPopover-paper': {
        overflow: 'initial',
        position: 'relative',
        boxShadow: '1px 1px 25px -4px rgb(0 0 0 / 17%)',

        '&::before': {
          content: "''",
          position: 'absolute',
          top: '0px',
          left: 'calc(50% - 4px)',
          width: '30px',
          height: '30px',
          background: 'white',
          transform: 'rotate(45deg) translateX(-50%)',
          zIndex: '999999',
        },
      },
    },
    link: {
      color: '#132e98',
      textDecoration: 'none',

      '&:hover': {
        textDecoration: 'underline',
      },
    },
  })
);

const StyledButton = styled((props: any) => <Button {...props} />)`
  background: rgba(0, 0, 0, 0);

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }

  & .MuiButton-label {
    font-size: 15px;
    text-transform: initial;
    font-weight: 600;
    color: #132e98;
    opacity: 0.9;
  }
`;

const NotificationPopover = () => {
  return (
    <NotificationPopoverWrapper>
      <IconButton color="inherit">
        <Notifications />
      </IconButton>
    </NotificationPopoverWrapper>
  );
};

const NotificationPopoverWrapper = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [notifications, setNotifications] = useState<any>(null);

  const notificationState = useSelector(
    (state: RootStore) => state.notification
  );
  const { loading, error } = notificationState;

  const updateNotification = (notifications: any) => {
    const tempNotifications = notifications.reduce(formatNotification, {});
    setNotifications(tempNotifications);
  };

  useEffect(() => {
    const { notifications } = notificationState;
    if (notifications) {
      updateNotification(notifications.results.slice(0, 5));
    }
  }, [notificationState]);

  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
    dispatch(GetNotifications());
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div onClick={handleClick}>{props.children}</div>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={props.student ? classes.popoverMenu2 : classes.popoverMenu}
        elevation={1}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          style={{
            padding: '12px 16px',
            borderBottom: '1px dotted rgba(0,0,0,0.05)',
          }}
        >
          <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
            Notifications
          </Typography>
        </Grid>

        {loading ? (
          <Typography style={{ padding: '8px 12px', display: 'block' }}>
            Loading...
          </Typography>
        ) : error ? (
          <Typography style={{ padding: '8px 12px', display: 'block' }}>
            Something went wrong!
          </Typography>
        ) : (
          <Box>
            <Notification.Wrapper>
              {notifications &&
                Object.keys(notifications).map((notification: any) => {
                  return (
                    <Box key={notification}>
                      <Typography
                        variant="button"
                        style={{ padding: '8px 12px', display: 'block' }}
                      >
                        {notification}
                      </Typography>
                      <NotificationContent
                        notifications={notifications[notification]}
                      />
                    </Box>
                  );
                })}
            </Notification.Wrapper>

            <div
              style={{ padding: 8, borderTop: '1px dotted rgba(0,0,0,0.05)' }}
            >
              <StyledButton
                component={Link}
                to="/notifications"
                variant="contained"
                fullWidth
              >
                See All
              </StyledButton>
            </div>
          </Box>
        )}
      </Menu>
    </div>
  );
};

export default NotificationPopover;
export { NotificationPopoverWrapper };

export const formatNotification = (total: any, curNotification: any) => {
  const tempDate = moment(curNotification.created_on).format('YYYY-MM-DD');
  if (total[tempDate]) {
    total[tempDate] = [...total[tempDate], curNotification];
    return total;
  } else {
    total[tempDate] = [curNotification];
    return total;
  }
};
