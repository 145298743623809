export const GET_DEPARTMENT_LOADING = "GET_DEPARTMENT_LOADING";
export const GET_DEPARTMENT_ERROR = "GET_DEPARTMENT_ERROR";
export const GET_DEPARTMENT_SUCCESS = "GET_DEPARTMENT_SUCCESS";

export const ADD_DEPARTMENT_LOADING = "ADD_DEPARTMENT_LOADING";
export const ADD_DEPARTMENT_ERROR = "ADD_DEPARTMENT_ERROR";
export const ADD_DEPARTMENT_SUCCESS = "ADD_DEPARTMENT_SUCCESS";

export const UPDATE_DEPARTMENT_LOADING = "UPDATE_DEPARTMENT_LOADING";
export const UPDATE_DEPARTMENT_ERROR = "UPDATE_DEPARTMENT_ERROR";
export const UPDATE_DEPARTMENT_SUCCESS = "UPDATE_DEPARTMENT_SUCCESS";

export const DELETE_DEPARTMENT_LOADING = "DELETE_DEPARTMENT_LOADING";
export const DELETE_DEPARTMENT_ERROR = "DELETE_DEPARTMENT_ERROR";
export const DELETE_DEPARTMENT_SUCCESS = "DELETE_DEPARTMENT_SUCCESS";

export type DepartmentTypeI = {
  id: string;
  name: string;
  count?: number;
};

//===============================<START>GET DEPARTMENT<START>======================//

export interface GetDepartmentLoading {
  type: typeof GET_DEPARTMENT_LOADING;
}

export interface GetDepartmentError {
  type: typeof GET_DEPARTMENT_ERROR;
}

export interface GetDepartmentSuccess {
  type: typeof GET_DEPARTMENT_SUCCESS;
  payload: DepartmentTypeI[];
}

//===============================<END>GET DEPARTMENT<END>======================//

//===============================<START>ADD DEPARTMENT<START>======================//

export interface AddDepartmentLoading {
  type: typeof ADD_DEPARTMENT_LOADING;
}

export interface AddDepartmentError {
  type: typeof ADD_DEPARTMENT_ERROR;
}

export interface AddDepartmentSuccess {
  type: typeof ADD_DEPARTMENT_SUCCESS;
  payload: DepartmentTypeI;
}

//===============================<END>ADD DEPARTMENT<END>======================//

//===============================<START>UPDATE DEPARTMENT<START>======================//

export interface UpdateDepartmentLoading {
  type: typeof UPDATE_DEPARTMENT_LOADING;
}

export interface UpdateDepartmentError {
  type: typeof UPDATE_DEPARTMENT_ERROR;
}

export interface UpdateDepartmentSuccess {
  type: typeof UPDATE_DEPARTMENT_SUCCESS;
  payload: DepartmentTypeI;
}

//===============================<END>UPDATE DEPARTMENT<END>======================//

//===============================<START>DELETE DEPARTMENT<START>======================//

export interface DeleteDepartmentLoading {
  type: typeof DELETE_DEPARTMENT_LOADING;
}

export interface DeleteDepartmentError {
  type: typeof DELETE_DEPARTMENT_ERROR;
}

export interface DeleteDepartmentSuccess {
  type: typeof DELETE_DEPARTMENT_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE DEPARTMENT<END>======================//

export type DepartmentDispatchTypes =
  | GetDepartmentLoading
  | GetDepartmentError
  | GetDepartmentSuccess
  | AddDepartmentLoading
  | AddDepartmentError
  | AddDepartmentSuccess
  | UpdateDepartmentLoading
  | UpdateDepartmentError
  | UpdateDepartmentSuccess
  | DeleteDepartmentLoading
  | DeleteDepartmentError
  | DeleteDepartmentSuccess;
