import React from "react";
import { FinanceSidebar } from "../../components";
import FeeLedgerList from "../../components/Finance/FeeLedger/FeeLedgerList";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const FeeLedgerPage = () => {
  const nav = <FinanceSidebar fee_ledger />;
  return (
    <SubModuleLayout FeesMenuActive sideNav={nav}>
      <FeeLedgerList />
    </SubModuleLayout>
  );
};

export default FeeLedgerPage;
