import { Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetIncomeType,
  DeleteIncomeType,
  GetIncomeDetails,
  DeleteIncomeDetails,
} from "../../../actions/Fees/Income/IncomeAction";
import {
  AddIncomeTypeI,
  IncomeTypeI,
} from "../../../actions/Fees/Income/IncomeActionTypes";
import { RootStore } from "../../../store";
import { useTable } from "../../Reusable";
import {
  ItemDeleteButton,
  ItemEditButton,
  ItemViewButton,
} from "../../Reusable/Buttons/TableButton";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import Popups from "../../Reusable/Dialogs/Popups";
import { useTableStyles } from "../../Styles/TableStyles";
import { dateConverterAdToBs } from "../../utils/dateConverter";
import ViewIncomeDetails from "./ViewIncomeDetails";

interface HeadCellsI {
  id: string;
  label: string;
}

interface PropsI {
  onEditData: (value: AddIncomeTypeI) => void;
  edit: { check: boolean };
}

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "incomeType", label: "Income Type" },
  { id: "name", label: "Name" },
  { id: "invoice", label: "Invoice" },
  { id: "amount", label: "Amount" },
  { id: "date", label: "Date" },
  { id: "action", label: "Action" },
];

const AddIncomeTable = (props: PropsI) => {
  const classes = useTableStyles();
  const { onEditData, edit } = props;
  const dispatch = useDispatch();

  //States============================================//
  const [tableData, setTableData] = useState<AddIncomeTypeI[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string>("");
  const [item, setItem] = useState<any>();
  const [openViewModalPopup, setOpenViewModalPopup] = useState(false);
  const [editMode, setEditMode] = useState<boolean>(false);

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);
  //==================================================//

  //===============================REDUX / Dispatch / useEffect ==================================//
  const incomeDetailSelector = useSelector(
    (state: RootStore) => state.income_type
  );

  useEffect(() => {
    dispatch(GetIncomeDetails());
  }, []);

  useEffect(() => {
    if (incomeDetailSelector.add_income) {
      const data = incomeDetailSelector.add_income;
      setTableData(data);
      setLoading(incomeDetailSelector.loading);
    }
  }, [incomeDetailSelector]);

  useEffect(() => {
    edit && setEditMode(edit.check);
  }, [edit]);

  //Functions=============================//
  const handleEditClicked = (data: AddIncomeTypeI) => {
    onEditData(data);
    setEditMode(true);
  };
  const handleDeleteModal = (value: boolean, id: string) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };
  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };
  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteIncomeDetails(itemId));
    setDeleteModalOpen(false);
  };

  const handleViewClicked = (data: any) => {
    setItem(data);
    setOpenViewModalPopup(true);
  };
  const handleViewModalClose = (value: boolean) => {
    setOpenViewModalPopup(value);
  };
  //===========================================================//

  //Getting table Data ===================================================//
  const getCustomTableRow = (item: AddIncomeTypeI) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {item.income_type_name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.invoice}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.amount}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {dateConverterAdToBs(item.income_date)}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.twoCell}>
          <ItemViewButton
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleViewClicked(item)
            }
          />
          <ItemEditButton
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleEditClicked(item)
            }
          />
          <ItemDeleteButton
            disabled={editMode}
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleDeleteModal(true, item.id)
            }
          />
        </StyledTableCell>
      </StyledTableRow>
    );
  };
  //=================================================================================//

  return (
    <>
      <Paper className={classes.root}>
        <Typography variant="h6">Income List</Typography>
        <TableContainer
          loading={loading}
          items={tableData}
          headCells={headCells}
          getCustomTableRow={getCustomTableRow}
          rowsPerPage={7}
        />
      </Paper>
      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
        dialogTitle="Are you sure to delete this record?"
        okText="Delete"
      />
      <Popups
        openPopup={openViewModalPopup}
        setOpenPopup={setOpenViewModalPopup}
        onClose={handleViewModalClose}
        maxWidth="md"
        fullWidth={true}
        title="View Income Details"
        colored
      >
        <ViewIncomeDetails item={item} />
      </Popups>
    </>
  );
};

export default AddIncomeTable;
