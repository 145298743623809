import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { GradeTable, GradeForm, AcademicSidebar } from "../../components";
import { GradeTypeI } from "../../actions/Academics/Grade/GradeActionTypes";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

function GradePage() {
  const [formData, setFormData] = useState<GradeTypeI | null>(null);
  const [edit, setEdit] = useState<any>();
  const [count, setCount] = useState<number | undefined>(0);

  const fetchEditData = (data: GradeTypeI) => {
    setFormData({ ...data, count });
    count && setCount(count + 1);
  };

  const onEditMode = (value: boolean) => {
    setEdit({ value, count });
  };

  const nav = <AcademicSidebar grade />;
  return (
    <SubModuleLayout academicMenuActive sideNav={nav}>
      <Grid container>
        <Grid item xs={3}>
          <GradeForm onEditMode={onEditMode} editData={formData} />
        </Grid>
        <Grid item xs={9}>
          <GradeTable edit={edit} onEditData={fetchEditData} />
        </Grid>
      </Grid>
    </SubModuleLayout>
  );
}

export default GradePage;
