import { Button, Grid, InputLabel, Paper, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import GetAppIcon from "@material-ui/icons/GetApp";
import styled from "styled-components";

import { useFormStyles } from "../../Styles/FormStyles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import { ClassTypeI } from "../../../actions/Academics/Class/ClassActionTypes";
import { SectionTypeI } from "../../../actions/Academics/Section/SectionActionTypes";
import { setSnackbar } from "../../../actions/SnackbarAction";
import { ExamTypeI } from "../../../actions/Examination/Exam/ExamActionTypes";
import { GetExams } from "../../../actions/Examination/Exam/ExamAction";
import { GetClasses } from "../../../actions/Academics/Class/ClassAction";
import { getExamSchedule } from "../../../rtk/features/exam/examSchedule/examScheduleApi";
import {
  generateGradeResult,
  getCombinedMarksLedger,
  getMarksLedger,
} from "../../../rtk/features/exam/marksLedger/marksLedgerApi";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import { GetGeneralInfoData } from "../../../actions/GeneralSettings/GeneralInfo/GeneralInfoAction";
import {
  resetLedgerState,
  setSearchParams,
} from "../../../rtk/features/exam/marksLedger/marksLedgerSlice";
import ReactSelect from "react-select";
import CloseIcon from "@material-ui/icons/Close";
import { selectStyles } from "./styles";

const Wrapper = styled(Paper)`
  background-color: #fff;
  padding: 16px;
  margin: 10px;
`;

const SearchButton = styled(Button)`
  margin: 5px;
  width: 120px;
`;

const ExamWrapper = styled.div`
  display: flex;
  width: max-content;
  max-height: 180px;
  flex-direction: column;
  gap: 20px;

  flex-wrap: wrap;

  margin: 20px 0;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    gap: 20px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: stretch;
  gap: 10px;

  & > input {
    width: 120px;
    padding: 8px 10px;
    background-color: #f2f2f2;

    font-size: 14px;
    font-weight: 400;

    &:focus {
      outline: none;
    }
  }

  & > button {
    border: none;
    cursor: pointer;
    color: #fff;
    background-color: #132e98;
    border-radius: 2px;
    transition: all 0.2s ease;

    &:hover {
      background-color: #0d206a;
    }

    &:focus {
      outline: none;
    }

    & > span {
      display: flex;
      align-items: center;

      & > svg {
        width: 22px;
        height: 22px;
      }
    }
  }
`;

type Props = {
  onLedgerTypeChange: (val: string) => void;
};

const LedgerForm: React.FC<Props> = (props) => {
  const classes = useFormStyles();
  const dispatch = useDispatch();
  const [resultFor, setResultFor] = React.useState<string>("single");

  const [selectedExam, setSelectedExam] = useState<ExamTypeI | null>(null);

  const [selectedClass, setSelectedClass] = useState<ClassTypeI | null>(null);

  const [sectionChoice, setSectionChoice] = useState<SectionTypeI[]>([]);
  const [selectedSection, setSelectedSection] = useState<SectionTypeI | null>(
    null
  );
  const [sectionDisabled, setSectionDisabled] = useState<boolean>(true);

  // const [modalOpen, setModalOpen] = useState<boolean>(false);

  //   Redux Store Selector
  const { exams } = useSelector((state: RootStore) => state.exam);
  const ClassSelector = useSelector((state: RootStore) => state.class);

  const [examOptions, setExamOptions] = useState<ExamTypeI[]>([]);

  const [selectedExamOptions, setSelectedExamOptions] = useState<
    {
      id: string;
      name: string;
      weightage: string;
    }[]
  >([]);

  /////////////////////////////////////////////////////////////////////

  useEffect(() => {
    dispatch(GetExams());
    dispatch(GetClasses());
  }, []);

  useEffect(() => {
    setExamOptions(exams);
  }, [exams]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(resetLedgerState());

    setResultFor((event.target as HTMLInputElement).value);
    props.onLedgerTypeChange((event.target as HTMLInputElement).value);

    if ((event.target as HTMLInputElement).value === "combined") {
      setSelectedExam(null);
    }
  };

  const handleClassChange = (value: ClassTypeI | null) => {
    setSelectedClass(value);
    setSelectedSection(null);

    if (value) {
      if (value.section.length) {
        setSectionChoice(value.section);
        setSectionDisabled(false);
      } else {
        setSectionChoice([]);
        setSectionDisabled(true);
      }
    } else {
      setSectionChoice([]);
      setSectionDisabled(true);
    }
  };

  const handleSectionChange = (value: SectionTypeI | null) => {
    setSelectedSection(value);
  };

  const handleExamChoice = (value: { label: string; value: string }) => {
    if (!selectedExamOptions.find((el) => el.id === value.value)) {
      setSelectedExamOptions([
        ...selectedExamOptions,
        { id: value.value, name: value.label, weightage: "" },
      ]);
    }
  };

  const handleSearch = () => {
    if (resultFor === "combined") {
      if (!selectedClass) {
        dispatch(setSnackbar(true, "warning", "Select Grade"));
        return;
      }

      if (!selectedExamOptions.length) {
        dispatch(setSnackbar(true, "warning", "Select Exams"));
        return;
      }

      if (selectedExamOptions.length) {
        const totalWeightage = selectedExamOptions.reduce(
          (a, b) => Number(b.weightage) + a,
          0
        );

        if (totalWeightage !== 100) {
          dispatch(
            setSnackbar(true, "warning", "Total weightage must add up to 100")
          );
          return;
        }

        dispatch(
          getCombinedMarksLedger({
            combine_exam_name: "Combined",
            grade: selectedClass.grade,
            section: selectedSection?.id || "",
            exam_weightage: selectedExamOptions.map((el) => ({
              exam_info: el.id,
              weightage_percentage: Number(el.weightage),
            })),
          })
        );
      }
    } else {
      if (!selectedExam) {
        dispatch(setSnackbar(true, "warning", "Select Exam"));
        return;
      }
      if (!selectedClass) {
        dispatch(setSnackbar(true, "warning", "Select Grade"));
        return;
      }

      dispatch(
        getMarksLedger({
          examId: selectedExam.id,
          gradeId: selectedClass.grade,
          sectionId: selectedSection?.id || "",
        })
      );
      dispatch(
        setSearchParams({
          exam: selectedExam.exam_name,
          grade: selectedClass.grade_name,
          section: selectedSection?.name || "",
        })
      );
    }
  };

  const handleWeightageChange = ({ id, val }: { id: string; val: string }) => {
    const re = /^[0-9]*\.?[0-9]*$/;

    if (val === "" || re.test(val)) {
      const rep = [...selectedExamOptions];
      const target = rep.find((el) => el.id === id);

      if (target) {
        target.weightage = val;
        setSelectedExamOptions([...rep]);
      }
    }
  };

  return (
    <>
      <Wrapper>
        <Grid container>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Exam Result</FormLabel>
              <RadioGroup
                aria-label="exam_result"
                name="exam_result"
                value={resultFor}
                onChange={handleChange}
                row
              >
                <FormControlLabel
                  value="single"
                  control={<Radio />}
                  label="Single Examination"
                />
                <FormControlLabel
                  value="combined"
                  control={<Radio />}
                  label="Combined Examinations"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              className={classes.formWrapper}
            >
              <InputLabel>Class</InputLabel>
              <Autocomplete
                value={selectedClass}
                onChange={(event: React.ChangeEvent<{}>, value) =>
                  handleClassChange(value)
                }
                options={ClassSelector.classes}
                getOptionLabel={(option) => option.grade_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="select_message"
                    placeholder="Select Class"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              className={classes.formWrapper}
            >
              <InputLabel>Section</InputLabel>
              <Autocomplete
                value={selectedSection}
                onChange={(event: React.ChangeEvent<{}>, value) =>
                  handleSectionChange(value)
                }
                options={sectionChoice}
                getOptionLabel={(option) => option.name}
                disabled={sectionDisabled}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="select_message"
                    placeholder="Select Section"
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            {resultFor === "single" ? (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                className={classes.formWrapper}
              >
                <InputLabel>Exam</InputLabel>
                <Autocomplete
                  value={selectedExam}
                  onChange={(event: React.ChangeEvent<{}>, value) =>
                    setSelectedExam(value)
                  }
                  options={exams}
                  getOptionLabel={(option) => option.exam_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="exam"
                      placeholder="Select Exam"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                className={classes.formWrapper}
              >
                <InputLabel>Combined Exams</InputLabel>
                <ReactSelect
                  value={null}
                  options={examOptions
                    .map((el) => ({
                      label: el.exam_name,
                      value: el.id,
                    }))
                    .filter(
                      (i) =>
                        !selectedExamOptions.map((j) => j.id).includes(i.value)
                    )}
                  onChange={(val) =>
                    handleExamChoice(val as { label: string; value: string })
                  }
                  closeMenuOnSelect={false}
                  placeholder="Select Exams"
                  styles={selectStyles}
                />
              </Grid>
            )}
          </Grid>

          {resultFor === "combined" && (
            <Grid container spacing={1}>
              <Grid item xs={12} className={classes.formWrapper}>
                <ExamWrapper>
                  {selectedExamOptions.length ? (
                    selectedExamOptions.map((el, index) => (
                      <div key={index + 1}>
                        <span>{el.name}</span>
                        <InputWrapper>
                          <input
                            placeholder="Weightage (%)"
                            value={el.weightage}
                            onChange={(e) =>
                              handleWeightageChange({
                                id: el.id,
                                val: e.target.value,
                              })
                            }
                          />
                          <button
                            onClick={() =>
                              setSelectedExamOptions(
                                selectedExamOptions.filter(
                                  (i) => i.id !== el.id
                                )
                              )
                            }
                          >
                            <span>
                              <CloseIcon />
                            </span>
                          </button>
                        </InputWrapper>
                      </div>
                    ))
                  ) : (
                    <span>* No exams selected.</span>
                  )}
                </ExamWrapper>
              </Grid>
            </Grid>
          )}

          <Grid container spacing={1}>
            <SearchButton
              color="primary"
              variant="contained"
              onClick={() => handleSearch()}
              startIcon={<GetAppIcon />}
            >
              Load
            </SearchButton>
          </Grid>
        </Grid>
      </Wrapper>
    </>
  );
};

export default LedgerForm;
