import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  AddIncomeType,
  UpdateIncomeType,
} from "../../../actions/Fees/Income/IncomeAction";
import { IncomeTypeI } from "../../../actions/Fees/Income/IncomeActionTypes";
import { RootStore } from "../../../store";
import CustomizedTextField from "../../Reusable/Inputs/TextField";
import FormLayout from "../../Reusable/Layouts/Form.Layout";

interface PropsI {
  editData: IncomeTypeI | null;
  onEditCheck: (value: boolean) => void;
}

const IncomeForm = (props: PropsI) => {
  const { editData, onEditCheck } = props;
  const dispatch = useDispatch();

  //States Declaration===============//
  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [editID, setEditID] = useState<string>("");
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);

  const incomeLoading = useSelector(
    (state: RootStore) => state.income_type.loading
  );
  const add_or_update = useSelector((state: RootStore) => state.income_type.add_or_update)

  const { register, handleSubmit, setValue, reset, errors } =
    useForm<IncomeTypeI>({
      mode: "onChange",
    });

  //===================================<START>CYCLE HOOKS<START>===========================//

  useEffect(() => {
    if (add_or_update) {
      setIsBtnLoading(false)
      handleReset();
    }
  }, [add_or_update])

  useEffect(() => {
    setLoading(incomeLoading);
  }, [incomeLoading]);

  useEffect(() => {
    editData != null && handleEditTableData(editData);
  }, [editData]);

  //===================================<END>CYCLE HOOKS<END>===========================//

  const handleEditTableData = (data: IncomeTypeI) => {
    setEditMode(true);
    setValue("name", data.name);
    setValue("description", data.description);
    setEditID(data.id);
  };

  const onFormSubmit = (data: IncomeTypeI) => {
    setIsBtnLoading(true);
    if (editMode) {
      editID != null && dispatch(UpdateIncomeType(editID, data));
    } else {
      dispatch(AddIncomeType(data));
    }
  };

  const handleReset = () => {
    reset();
    setEditMode(false);
    setEditID("");
    onEditCheck(false);
  };

  return (
    <>
      <FormLayout
        title={editMode ? "Edit Income Type" : "Add Income Type"}
        onSubmit={handleSubmit(onFormSubmit)}
        editMode={editMode}
        loading={loading}
        onClick={!isBtnLoading && handleReset}
        add_or_update={isBtnLoading}
      >
        <CustomizedTextField
          label="Income Type"
          placeholder="Income Type"
          name="name"
          required
          error={errors["name"] ? "Required Field." : ""}
          inputRef={register({ required: true })}
        />
        <CustomizedTextField
          label="Description"
          placeholder="Description"
          name="description"
          required
          multiline
          rows={4}
          error={errors["description"] ? "Required Field." : ""}
          inputRef={register({ required: true })}
        />
      </FormLayout>
    </>
  );
};

export default IncomeForm;
