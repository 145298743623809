import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_APE_LOADING,
  GET_APE_ERROR,
  GET_APE_SUCCESS,
  APEDispatchTypes,
  ADD_APE_LOADING,
  ADD_APE_SUCCESS,
  ADD_APE_ERROR,
  DELETE_APE_SUCCESS,
  DELETE_APE_ERROR,
  DELETE_APE_LOADING,
  UPDATE_APE_LOADING,
  UPDATE_APE_SUCCESS,
  UPDATE_APE_ERROR,
} from "./APEActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

interface RequestDataI {
  id: string;
  name: string;
}

export const GetAPEs =
  () => async (dispatch: Dispatch<APEDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_APE_LOADING,
      });

      const res = await axios.get(`${ADMIN_API_URL}/ape_base/`, HeaderConfig());

      dispatch({
        type: GET_APE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_APE_ERROR,
      });
    }
  };

export const AddAPE =
  (data: RequestDataI) =>
  async (dispatch: Dispatch<APEDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_APE_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/ape_base/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_APE_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "APE Added Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: ADD_APE_ERROR,
        payload: error?.response?.data,
      });
    }
  };

export const UpdateAPE =
  (id: string, data: RequestDataI) =>
  async (dispatch: Dispatch<APEDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_APE_LOADING,
      });

      const res = await axios.put(
        `${ADMIN_API_URL}/ape_base/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_APE_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "APE Updated Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: UPDATE_APE_ERROR,
        payload: error?.response?.data,
      });
    }
  };

export const DeleteAPE =
  (id: string) =>
  async (dispatch: Dispatch<APEDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_APE_LOADING,
      });

      const res = await axios.delete(
        `${ADMIN_API_URL}/ape_base/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_APE_SUCCESS,
        payload: { id: id },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "APE Deleted Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_APE_ERROR,
      });
    }
  };
