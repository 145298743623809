import {
  GET_SESSION_LOADING,
  GET_SESSION_ERROR,
  GET_SESSION_SUCCESS,
  SessionDispatchTypes,
  ADD_SESSION_LOADING,
  ADD_SESSION_ERROR,
  ADD_SESSION_SUCCESS,
  DELETE_SESSION_LOADING,
  DELETE_SESSION_ERROR,
  DELETE_SESSION_SUCCESS,
  UPDATE_SESSION_LOADING,
  UPDATE_SESSION_ERROR,
  UPDATE_SESSION_SUCCESS,
  UPDATE_SESSION_STATUS_LOADING,
  UPDATE_SESSION_STATUS_SUCCESS,
  UPDATE_SESSION_STATUS_ERROR,
  SessionTypeI,
} from "../../actions/GeneralSettings/Session/SessionActionTypes";

interface InitialStateI {
  loading: boolean;
  sessions: SessionTypeI[];
  add_or_update: boolean;
}

const initialState: InitialStateI = {
  loading: false,
  sessions: [],
  add_or_update: false,
};

const SessionReducer = (
  state: InitialStateI = initialState,
  action: SessionDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_SESSION_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_SESSION_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_SESSION_SUCCESS:
      return {
        ...state,
        loading: false,
        sessions: action.payload,
      };

    case ADD_SESSION_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case ADD_SESSION_ERROR:
      return {
        ...state,
        loading: false,
        add_or_update: true,
      };

    case ADD_SESSION_SUCCESS:
      return {
        loading: false,
        sessions: [...state.sessions, action.payload],
        add_or_update: true,
      };

    case UPDATE_SESSION_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case UPDATE_SESSION_ERROR:
      return {
        ...state,
        loading: false,
        add_or_update: true,
      };

    case UPDATE_SESSION_SUCCESS:
      const current_data: SessionTypeI[] = state.sessions;
      const index: number = current_data.findIndex(
        (element) => element.id === action.payload.id
      );
      current_data[index] = action.payload;

      return {
        loading: false,
        sessions: current_data,
        add_or_update: true,
      };

    case UPDATE_SESSION_STATUS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_SESSION_STATUS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case UPDATE_SESSION_STATUS_SUCCESS:
      const old_data: SessionTypeI[] = state.sessions;
      const j: number = old_data.findIndex(
        (element) => element.status === true
      );
      const i: number = old_data.findIndex(
        (element) => element.id === action.payload.id
      );

      old_data[j] = { ...old_data[j], status: false };
      old_data[i] = action.payload;

      return {
        ...state,
        loading: false,
        sessions: old_data,
      };

    case DELETE_SESSION_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_SESSION_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_SESSION_SUCCESS:
      const new_data: SessionTypeI[] = state.sessions;
      const item_index = new_data.findIndex(
        (element) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return {
        ...state,
        loading: false,
        sessions: new_data,
      };

    default:
      return state;
  }
};

export default SessionReducer;
