import React from 'react';
import styled from 'styled-components';
import { Box } from '@material-ui/core';

// Styled components
const Wrapper = styled(Box)`
  max-height: 440px;
  overflow-y: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

// Compound components
export default function Notification() {
  return <React.Fragment></React.Fragment>;
}

Notification.Wrapper = function NotificationWrapper({
  children,
  ...restProps
}: any) {
  return <Wrapper {...restProps}>{children}</Wrapper>;
};
