//---------------------<start> module imports starts ----------------------//
import {
    Avatar,
    CardHeader,
    CircularProgress,
    Divider,
    Grid,
    InputLabel,
    Paper,
    TextField,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useFormStyles } from "../../Styles/FormStyles";
import { Autocomplete } from "@material-ui/lab";
import { Search } from "@material-ui/icons";
import { RootStore } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { GetClasses } from "../../../actions/Academics/Class/ClassAction";
import { GetSections } from "../../../actions/Academics/Section/SectionAction";
import { GetStudentByName, SearchStudent, StudentSearch } from "../../../actions/Student/Student/StudentAction";
import { useForm } from "react-hook-form";
import CustomizedTextField from "../../Reusable/Inputs/TextField";
import defaultBase64 from "../../Student/ParentForm/defaultBase64";
import { GetInvoiceHistory } from "../../../actions/Fees/CollectFees/CollectFeesAction";
import CustomizedNepaliDatePicker from "../../Reusable/Inputs/NepaliDatePicker";
import { ResetButton, SubmitButton } from "../../Reusable/Buttons/SearchButton";
import { GetFeeType } from "../../../actions/Fees/FeeType/FeeTypeAction";
import { Fee_TypeI } from "../../../actions/Fees/FeeType/FeeTypeActionTypes";
import { GetFeeIncome } from "../../../actions/Fees/FeeIncome/FeeIncomeAction";
import { GetScholarshipType, GetScholarshipReportList } from "../../../actions/ScholarShipType/ScholarshipAction";

// -----------------<end> module imports ends -----------------------//
// -----------------<start> Interfaces <start>-----------------------//

// -----------------<end> Interfaces <end>-----------------------//

// -----------------<starts> Selected Student Form starts-----------------------//
const ScholarshipReportSearch = (props: any) => {
    const classes = useFormStyles();
    const { student, fee, setFee } = props;
    const [gradeChoices, setGradeChoices] = useState<any | null>([]);
    const [sectionChoices, setSectionChoices] = useState<any[] | []>([]);
    const [feeChoices, setFeeChoices] = useState<any[] | []>([]);
    const [grade, setGrade] = useState<any | null>(null);
    const [section, setSection] = useState<any | null>(null);
    const [sectionDisabler, setSectionDisabler] = useState<boolean>(true);
    const [studentSelectOpen, setStudentSelectOpen] = useState<boolean>(false);
    const [studentChoices, setStudentChoices] = useState<any>([]);
    const gradeList = useSelector((state: RootStore) => state.class.classes);
    const feeTypeSelector = useSelector((state: RootStore) => state.fee_type);

    const studentList = useSelector(
        (state: RootStore) => state.student
    );

    const feesHead = useSelector(
        (state: RootStore) => state.collect_fees?.collect_fees
    );

    const scholarshipState = useSelector(
        (state: RootStore) => state.scholarship.scholarship_type
    );

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetFeeType(1));
        dispatch(GetScholarshipType());
    }, []);

    useEffect(() => {
        const data = scholarshipState;
        setFeeChoices(data?.map((item: any) => ({
            id: item.id,
            title: item.scholarship_title,
        })))
    }, [scholarshipState])

    useEffect(() => {
        gradeList && setGradeChoices(gradeList);
    }, [gradeList]);

    console.log({ gradeChoices })

    const handleFeeChange = (value: any | null) => {
        console.log({ value })
        setFee(value);
        dispatch(GetScholarshipReportList(value?.id || ""))
    };

    useEffect(() => {
        if (section != null && grade != null) {
            dispatch(StudentSearch(grade.grade, section.id));
        }
    }, [section, grade]);

    useEffect(() => {
        if (studentList.searched_student != null) {
            setStudentChoices(studentList.searched_student);
        }
    }, [studentList.searched_student]);

    console.log({ studentChoices })

    useEffect(() => {
        if (student !== null) {
            dispatch(GetInvoiceHistory(student?.academic_id))
        }
    }, [student])
    return (
        <>
            <div style={{ padding: '15px' }}>
                {/* <div style={{ display: "flex", justifyContent: "space-between" }}> */}
                <div>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div>
                                <form className={classes.form} >
                                    {props.title && <Grid container>
                                        <Grid item xs={12} className={classes.formTitle}>
                                            <span>{props.title}</span>
                                        </Grid>
                                    </Grid>}
                                    <Grid container spacing={2}>
                                        <Grid item xs={3}>
                                            <InputLabel>Scholarship Header</InputLabel>
                                            <Autocomplete
                                                onChange={(event: React.ChangeEvent<{}>, value: any | null) =>
                                                    handleFeeChange(value)
                                                }
                                                options={feeChoices}
                                                getOptionLabel={(option) => option.title}
                                                value={fee}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="--select--"
                                                        name="fee"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        {/* <SubmitButton style={{ marginTop: "17px" }} />
                                        <ResetButton style={{ marginTop: "17px" }} /> */}
                                    </Grid>
                                </form>
                            </div>
                        </Grid>
                    </Grid>
                </div>

            </div>
        </>
    );
};
// -----------------<end> Selected Student form ends-----------------------//
export default ScholarshipReportSearch;
