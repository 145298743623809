import React from 'react';
import { Chip } from '@material-ui/core';
import { useTableStyles } from '../components/Styles/TableStyles';

interface GetStatusProps {
  status: string;
}

const GetStatus = (props: GetStatusProps) => {
  const { status } = props;
  const classes = useTableStyles();

  let cx, label;

  if (status == 'P') {
    cx = classes.chipGrey;
    label = 'Pending';
  } else if (status == 'A') {
    cx = classes.chipGreen;
    label = 'Approved';
  } else {
    cx = classes.chipRed;
    label = 'Declined';
  }

  return <Chip className={cx} label={label} size="small" />;
};

const GetTeachingStatus = (props: GetStatusProps) => {
  const classes = useTableStyles();
  const { status } = props;

  let cx, label;

  if (status == 'completed') {
    cx = classes.chipGreen;
    label = 'Completed';
  } else {
    cx = classes.chipRed;
    label = 'Remaining';
  }
  return <Chip className={cx} label={label} size="small" />;
};

export default GetStatus;
export { GetTeachingStatus };
