import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../../../store";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  LinearProgress,
  Paper,
} from "@material-ui/core";
import styled from "styled-components";
import { useEffect } from "react";
import { GetStaffProfileLeave } from "../../../../../actions/HumanResource/StaffProfile/StaffProfileAction";
import { StaffLeaveInfoType } from "../../../../../actions/HumanResource/StaffProfile/StaffProfileActionTypes";
import { getStaffLeaveInfo } from "../../../../../rtk/features/humanResource/humanResourceThunk";
import { resetStaffLeaveInfo } from "../../../../../rtk/features/humanResource/humanResourceSlice";
import { useCardStyles } from "../../../../Styles/CardStyles";

const NoDataCell = styled.td`
  padding: 4rem 0;
  background-color: #f2f2f2;
  text-align: center;
`;

export default function StaffLeaveInfo(props: any) {
  const classes = useCardStyles();

  const dispatch = useDispatch();
  const { loading, staffDetail, staffLeaveInfo } = useSelector(
    (state: RootStore) => state.humanResource
  );

  useEffect(() => {
    return () => {
      dispatch(resetStaffLeaveInfo());
    };
  }, []);

  useEffect(() => {
    staffDetail && dispatch(getStaffLeaveInfo(staffDetail.id));
  }, [staffDetail]);

  return (
    <>
      <Paper className={classes.infobar} elevation={0}>
        {/* {loading && <LinearProgress />} */}
        <TableContainer className={classes.roota}>
          <Table
            size="small"
            className={classes.table}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Leave Name</TableCell>
                <TableCell>Leave Allowed</TableCell>
                <TableCell>Leave Taken</TableCell>
                <TableCell>Is Accumulated</TableCell>
                <TableCell>Is Paid</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {staffLeaveInfo.length ? (
                staffLeaveInfo.map((row, index) => (
                  <TableRow key={index + 1}>
                    <TableCell>{row.leave_type.name}</TableCell>
                    <TableCell>
                      {row.leave_type.max_leave} days /{" "}
                      {row.leave_type.max_leave_per}
                    </TableCell>
                    <TableCell>{row.days}</TableCell>
                    <TableCell>
                      {row.leave_type.is_accumulated ? "Yes" : "No"}
                    </TableCell>
                    <TableCell>
                      {row.leave_type.is_paid ? "Yes" : "No"}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <NoDataCell colSpan={5}>
                    {loading ? "Loading..." : "No Leave Applied for the staff."}
                  </NoDataCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}
