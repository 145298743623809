import {
  STUDENT_ADMISSION_LOADING,
  STUDENT_ADMISSION_SUCCESS,
  STUDENT_ADMISSION_ERROR,
  STUDENT_LIST_LOADING,
  STUDENT_LIST_SUCCESS,
  STUDENT_LIST_ERROR,
  STUDENT_BY_ID_LOADING,
  STUDENT_BY_ID_ERROR,
  STUDENT_BY_ID_SUCCESS,
  STUDENT_UPDATE_LOADING,
  STUDENT_UPDATE_ERROR,
  STUDENT_UPDATE_SUCCESS,
  STUDENT_DISABLE_LOADING,
  STUDENT_DISABLE_ERROR,
  STUDENT_DISABLE_SUCCESS,
  DISABLED_STUDENT_LIST_LOADING,
  DISABLED_STUDENT_LIST_SUCCESS,
  DISABLED_STUDENT_LIST_ERROR,
  STUDENT_ENABLE_LOADING,
  STUDENT_ENABLE_ERROR,
  STUDENT_ENABLE_SUCCESS,
  STUDENT_BULK_DELETE_LOADING,
  STUDENT_BULK_DELETE_ERROR,
  STUDENT_BULK_DELETE_SUCCESS,
  STUDENT_SEARCH_LOADING,
  STUDENT_SEARCH_ERROR,
  STUDENT_SEARCH_SUCCESS,
  GET_STUDENT_BY_CLASS_LOADING,
  GET_STUDENT_BY_CLASS_ERROR,
  GET_STUDENT_BY_CLASS_SUCCESS,
  SEARCH_STUDENT_LOADING,
  SEARCH_STUDENT_ERROR,
  SEARCH_STUDENT_SUCCESS,
  StudentsByClassI,
  SearchedStudentsI,
  UPLOAD_STUDENT_INFO_LOADING,
  UPLOAD_STUDENT_INFO_SUCCESS,
  UPLOAD_STUDENT_INFO_ERROR,
  SEND_STUDENT_CREDENTIALS_LOADING,
  SEND_STUDENT_CREDENTIALS_SUCCESS,
  SEND_STUDENT_CREDENTIALS_ERROR,
  STUDENT_SIBLINGS_LOADING,
  STUDENT_SIBLINGS_ERROR,
  STUDENT_SIBLINGS_SUCCESS,
  RESET_ALL_STATE,

} from "../../actions/Student/Student/StudentActionTypes";

interface InitialStateI {
  loading: boolean;
  student_list?: any;
  disabled_student_list?: any;
  student_by_id?: any;
  searched_student: any;
  errors: any;
  add_or_update?: boolean;
  student_by_class: StudentsByClassI[];
  student_search: any;
  student_info_upload: any;
  student_siblings: any;
}

const initialState: InitialStateI = {
  loading: false,
  student_list: [],
  disabled_student_list: [],
  student_by_id: null,
  searched_student: [],
  errors: null,
  add_or_update: false,
  student_by_class: [],
  student_search: [],
  student_info_upload: {
    loading: false,
    data: null,
    error: null,
  },
  student_siblings: [],
};

const StudentReducer = (
  state: InitialStateI = initialState,
  action: any
): InitialStateI => {
  switch (action.type) {
    case RESET_ALL_STATE:
      return{
        loading: false,
        student_list: [],
        disabled_student_list: [],
        student_by_id: null,
        searched_student: [],
        errors: null,
        add_or_update: false,
        student_by_class: [],
        student_search: [],
        student_info_upload: {
          loading: false,
          data: null,
          error: null,
        },
        student_siblings: [],
      }
    //STUDET LIST
    case STUDENT_LIST_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
        errors: null,
      };

    case STUDENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        student_list: action.payload,
        add_or_update: false,
        errors: null,
        student_search: [],
      };

    case STUDENT_LIST_ERROR:
      return {
        ...state,
        loading: false,
        add_or_update: true,
      };

    //STUDET LIST
    case STUDENT_ADMISSION_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case STUDENT_ADMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        student_list: [...state.student_list.results, action.payload],
        add_or_update: true,
        student_search: [],
      };

    case STUDENT_ADMISSION_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        add_or_update: false,
      };

    //STUDET BY ID
    case STUDENT_BY_ID_LOADING:
      return {
        ...state,
        loading: true,
      };

    case STUDENT_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        student_by_id: action.payload,
      };

    case STUDENT_BY_ID_ERROR:
      return {
        ...state,
        loading: false,
      };

    //FILTERED STUDET LIST
    case STUDENT_SEARCH_LOADING:
      return {
        ...state,
        loading: true,
      };

    case STUDENT_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        searched_student: action.payload,
      };

    case STUDENT_SEARCH_ERROR:
      return {
        ...state,
        loading: false,
      };

    // STUDENT UPDATE
    case STUDENT_UPDATE_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case STUDENT_UPDATE_SUCCESS:
      const current_data: any = state.student_list.results;
      const index: number = current_data.findIndex(
        (element: any) => element.id === action.payload.id
      );
      current_data[index] = action.payload;
      return {
        ...state,
        loading: false,
        student_list: current_data,
        add_or_update: true,
      };

    case STUDENT_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        add_or_update: false,
      };

    //DISABLE STUDENT
    case STUDENT_DISABLE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case STUDENT_DISABLE_SUCCESS:
      const new_data: any = state.student_list;
      const item_index = new_data.results.findIndex(
        (element: any) => element.id === action.payload.id
      );
      new_data.results.splice(item_index, 1);

      return {
        ...state,
        loading: false,
        student_list: new_data,
      };

    case STUDENT_DISABLE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DISABLED_STUDENT_LIST_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DISABLED_STUDENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        disabled_student_list: action.payload,
      };

    case DISABLED_STUDENT_LIST_ERROR:
      return {
        ...state,
        loading: false,
      };

    //ENABLE STUDENT
    case STUDENT_ENABLE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case STUDENT_ENABLE_SUCCESS:
      const new_data_: any = state.disabled_student_list;
      const item_index_ = new_data_.findIndex(
        (element: any) => element.id === action.payload.id
      );
      new_data_.splice(item_index_, 1);

      return {
        ...state,
        loading: false,
        disabled_student_list: new_data_,
      };

    case STUDENT_ENABLE_ERROR:
      return {
        ...state,
        loading: false,
      };

    //STUDENT BULK DELETE
    case STUDENT_BULK_DELETE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case STUDENT_BULK_DELETE_SUCCESS:
      const new_data__: any = state.disabled_student_list;
      const data = new_data__.filter(
        (item: any) => !action.payload.data.id.includes(item.id)
      );
      return {
        ...state,
        loading: false,
        disabled_student_list: data,
      };

    case STUDENT_BULK_DELETE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_STUDENT_BY_CLASS_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
        errors: null,
      };

    case GET_STUDENT_BY_CLASS_SUCCESS:
      return {
        ...state,
        loading: false,
        student_by_class: action.payload.results,
        add_or_update: false,
        errors: null,
      };

    case GET_STUDENT_BY_CLASS_ERROR:
      return {
        ...state,
        loading: false,
        add_or_update: false,
      };

    case SEARCH_STUDENT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case SEARCH_STUDENT_SUCCESS:
      return {
        ...state,
        loading: false,
        student_search: action.payload,
        student_list: action.payload,
      };

    case SEARCH_STUDENT_ERROR:
      return {
        ...state,
        loading: false,
      };
    case UPLOAD_STUDENT_INFO_LOADING:
      return {
        ...state,
        student_info_upload: {
          ...state.student_info_upload,
          loading: true,
        },
      };

    case UPLOAD_STUDENT_INFO_SUCCESS:
      return {
        ...state,
        student_info_upload: {
          ...state.student_info_upload,
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case UPLOAD_STUDENT_INFO_ERROR:
      return {
        ...state,
        student_info_upload: {
          ...state.student_info_upload,
          loading: false,
          data: null,
          error: true,
        },
      };

    case SEND_STUDENT_CREDENTIALS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case SEND_STUDENT_CREDENTIALS_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case SEND_STUDENT_CREDENTIALS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case STUDENT_SIBLINGS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case STUDENT_SIBLINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        student_siblings: action.payload,
      };

    case STUDENT_SIBLINGS_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default StudentReducer;
