import React from "react";
import { DataI } from "./AnnoucementList";
import styled from "styled-components";
import { dateConverterAdToBs } from "../../utils/dateConverter";
import HtmlParser from "react-html-parser";
import { Divider } from "@material-ui/core";

const Row = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const Cell = styled.div`
  flex: 1;
  font-size: 16px;
  font-weight: 400;
`;

const LineBreak = styled(Divider)`
  margin: 5px 0;
`;

interface Props {
  item: DataI | null;
}

const AnnouncementViewModal = ({ item }: Props) => {
  return (
    <div>
      <Row>
        <Cell>Title: {item?.data.title || ""}</Cell>
        <Cell>Category: {item?.data.announcement_category_name || ""}</Cell>
      </Row>
      <Row>
        <Cell>
          Annoucement Date:{" "}
          {item?.data.announcement_date
            ? dateConverterAdToBs(item?.data.announcement_date)
            : dateConverterAdToBs(item?.data.created_on)}
        </Cell>
      </Row>
      <LineBreak />
      <Row>
        <Cell>Sent To: {item?.message_to || <></>}</Cell>
      </Row>
      <LineBreak />
      {item?.data.featured_image && (
        <Row>
          <Cell>
            Featured Image
            <span>
              <img src={item.data.featured_image} width={150} height={150} />
            </span>
          </Cell>
        </Row>
      )}
      <LineBreak />
      <Row>
        <Cell>
          Description: <span>{HtmlParser(item?.data.description || "")}</span>
        </Cell>
      </Row>
    </div>
  );
};

export default AnnouncementViewModal;
