import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_CLASS_LOADING,
  GET_CLASS_ERROR,
  GET_CLASS_SUCCESS,
  ClassDispatchTypes,
  ADD_CLASS_LOADING,
  ADD_CLASS_SUCCESS,
  ADD_CLASS_ERROR,
  DELETE_CLASS_SUCCESS,
  DELETE_CLASS_ERROR,
  DELETE_CLASS_LOADING,
  UPDATE_CLASS_LOADING,
  UPDATE_CLASS_SUCCESS,
  UPDATE_CLASS_ERROR,
  CLASS_STUDENTS_COUNT_LOADING,
  CLASS_STUDENTS_COUNT_ERROR,
  CLASS_STUDENTS_COUNT_SUCCESS,
} from "./ClassActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

interface RequestDataI {
  section: string[];
  grade: string;
}

export const GetClasses =
  () => async (dispatch: Dispatch<ClassDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_CLASS_LOADING,
      });

      const res = await axios.get(`${ADMIN_API_URL}/class/`, HeaderConfig());

      dispatch({
        type: GET_CLASS_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_CLASS_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "Classes Loading Failed",
      //   },
      // });
    }
  };

export const AddClass =
  (data: RequestDataI) =>
  async (dispatch: Dispatch<ClassDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_CLASS_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/class/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_CLASS_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Class Added Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: ADD_CLASS_ERROR,
        payload: error.response && error.response.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Class Add Failed",
        },
      });
    } finally {
      try {
        dispatch({
          type: GET_CLASS_LOADING,
        });

        const res = await axios.get(`${ADMIN_API_URL}/class/`, HeaderConfig());

        dispatch({
          type: GET_CLASS_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: GET_CLASS_ERROR,
        });
      }
    }
  };

export const UpdateClass =
  (id: string, data: RequestDataI) =>
  async (dispatch: Dispatch<ClassDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_CLASS_LOADING,
      });

      const res = await axios.put(
        `${ADMIN_API_URL}/class/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_CLASS_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Class Updated Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: UPDATE_CLASS_ERROR,
        payload: error.response && error.response.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Class Update Failed",
        },
      });
    } finally {
      try {
        dispatch({
          type: GET_CLASS_LOADING,
        });

        const res = await axios.get(`${ADMIN_API_URL}/class/`, HeaderConfig());

        dispatch({
          type: GET_CLASS_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: GET_CLASS_ERROR,
        });
      }
    }
  };

export const DeleteClass =
  (id: string) =>
  async (dispatch: Dispatch<ClassDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_CLASS_LOADING,
      });

      const res = await axios.delete(
        `${ADMIN_API_URL}/class/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_CLASS_SUCCESS,
        payload: { id: id.toString() },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Class Deleted Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_CLASS_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Class Delete Failed",
        },
      });
    }
  };

export const GetClassStudentCount =
  (id: string) =>
  async (dispatch: Dispatch<ClassDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: CLASS_STUDENTS_COUNT_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/class/get_class_student/?class=${id}`,
        HeaderConfig()
      );

      dispatch({
        type: CLASS_STUDENTS_COUNT_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: CLASS_STUDENTS_COUNT_ERROR,
      });
    }
  };
