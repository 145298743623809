// ---------------------------- <START> module imports start ----------------------------------//
import React, { useEffect, useState, useRef } from "react";
import {
  TableBody,
  TextField,
  Grid,
  Typography,
  Paper,
  TableHead,
  makeStyles,
  IconButton,
  CardMedia,
  createStyles,
  Theme,
  Button,
  withStyles,
} from "@material-ui/core";
import {
  Close as CloseIcon,
  InsertDriveFileOutlined,
  PrintOutlined,
} from "@material-ui/icons";
import { PrintTableStyles } from "../../Styles/PrintTableStyles";
import { useTablePrint } from "../../Reusable";
import TableActionButtons from "../../Reusable/Buttons/TableActionButtons";
import { useSelector } from "react-redux";
import { RootStore } from "../../../store";
import { MarkSheetTypeI } from "../../../actions/Examination/MarkSheet/MarkSheetActionTypes";
import { Printd } from "printd";
import ReactDOMServer from "react-dom/server";
import ReactHtmlParser from "react-html-parser";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import InputBase from "@material-ui/core/InputBase";
import NativeSelect from "@material-ui/core/NativeSelect";
import { useDispatch } from "react-redux";
import {
  SchoolInfoContainer,
  SchoolInfoContainerLogo,
  SchoolTitleBig,
  Paragraph,
  ReportType,
  Spacing,
  Sticky,
  ParagraphHighHeight,
  // PrintNow,
} from "../Marksheet/ReportStyles";
import ReactToPrint from "react-to-print";
import { dateConverterAdToBs } from "../../utils/dateConverter";
import { useFormStyles } from "../../Styles/FormStyles";
import { FormSaveLoadingButton } from "../../Reusable/Buttons/FormButton";
import defaultBase64 from "../../Student/ParentForm/defaultBase64";
import * as htmlToImage from "html-to-image";
import checkImageUrl from "../../utils/checkImageUrl";
import { GetReportSetting } from "../../../actions/GeneralSettings/ReportSetting/ReportSettingAction";
import LoaderIcon from "../../Reusable/LoarderIcon";
// ----------------------------<END> module imports end ----------------------------------//

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  })
)(InputBase);

// ---------------------------- <START> interface starts ----------------------------------//

interface HeadCellsI {
  id: string;
  label: string;
}

interface CustomizeI {
  footer_text_1: string;
  footer_text_2: string;
  footer_text_3: string;
  thumbnail: string;
  watermark: boolean;
  school_logo: boolean;
  school_logo_position: string;
  code: string;
}
// ---------------------------- <END> interface ends ----------------------------------//
// ---------------------<END> Table Headings Data Ends --------------------------//

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2, 4),
    "& .MuiCardContent-root": {
      padding: theme.spacing(0),
    },
    "& .MuiTypography-h2": {
      fontSize: "3.1rem",
    },
    caption: {
      padding: "0px",
    },
  },
  idImage: {
    width: "80px",
    height: "80px",
  },
  line: {
    borderRight: "1px solid black",
  },
  bottom: {
    marginBottom: theme.spacing(1),
  },
  tableCell: {
    padding: "0px 5px !important",
    border: "1px solid black",
  },
  topWatermark: {
    backgroundImage: `url('https://i.imgur.com/KO5poDX.png')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "70%",
    backgroundPosition: "center",
  },
  centerWatermark: {
    backgroundImage: `url('https://i.imgur.com/KO5poDX.png')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "70%",
    backgroundPosition: "center",
  },
}));

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const CharacterCertificatePopup1 = (props: any) => {
  const classes = PrintTableStyles();
  const formClasses = useFormStyles();
  const dispatch = useDispatch();

  // States declaration
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [customizedData, setCustomizedData] = useState<CustomizeI>({
    footer_text_1: "Class Teacher",
    footer_text_2: "Exam Coordinator",
    footer_text_3: "The Principal",
    thumbnail: defaultBase64,
    watermark: false,
    school_logo: false,
    school_logo_position: "L",
    code: "abc",
  });
  const [item, setItem] = useState<any>(null);
  const [url, setUrl] = useState<string>("");

  const componentRef = useRef<any>();

  const schoolState = useSelector(
    (state: RootStore) => state.auth.general_info
  );
  const reportSettingState = useSelector(
    (state: RootStore) => state.report_setting.reportsetting
  );
  const examState = useSelector((state: RootStore) => state.exam.exam);
  const studentStateData = useSelector(
    (state: RootStore) => state.student.student_by_id
  );
  const add_or_update = useSelector(
    (state: RootStore) => state.character_certificate_templates.add_or_update
  );

  useEffect(() => {
    const n = window.location.href.lastIndexOf("/");
    setUrl(window.location.href.substring(n + 1));
    dispatch(GetReportSetting());
  }, []);

  useEffect(() => {
    if (add_or_update) {
      setIsLoading(false);
    }
  }, [add_or_update]);

  useEffect(() => {
    props.content != null && setItem(props.content);
    props.content != null && setCustomizedData(props.content);
  }, [props.content]);

  // ============================ <END> EVENT HANDLERS <END> ============================== //

  const handleFooterText = (event: any) => {
    const finalValues = {
      ...customizedData,
      footer_title: event.target.value,
    };
    setCustomizedData(finalValues);
  };

  const handleFooterText1 = (event: any) => {
    const finalValues = {
      ...customizedData,
      footer_text_1: event.target.value,
    };
    setCustomizedData(finalValues);
  };

  const handleFooterText2 = (event: any) => {
    const finalValues = {
      ...customizedData,
      footer_text_2: event.target.value,
    };
    setCustomizedData(finalValues);
  };

  const handleFooterText3 = (event: any) => {
    const finalValues = {
      ...customizedData,
      footer_text_3: event.target.value,
    };
    setCustomizedData(finalValues);
  };
  const handlewatermarkChange = (event: any) => {
    const finalValues = {
      ...customizedData,
      watermark: event?.target.checked,
    };
    setCustomizedData(finalValues);
  };

  const handleSchoolLogoChange = (event: any) => {
    const finalValues = {
      ...customizedData,
      school_logo: event?.target.checked,
      school_logo_position: "L",
    };
    setCustomizedData(finalValues);
  };

  const handleSchoolLogoPosition = (event: any) => {
    const finalValues = {
      ...customizedData,
      school_logo_position: event.target.value,
    };
    setCustomizedData(finalValues);
  };

  const handlleSubmit = () => {
    setIsLoading(true);
    const data_ = {
      ...customizedData,
      code: item?.code,
    };
    const node: any = document.getElementById("marksheet-print");
    htmlToImage
      .toPng(node)
      .then(function (dataUrl) {
        data_.thumbnail = dataUrl;
        return dataUrl;
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
    setTimeout(() => {
      props.handlleSubmit(data_);
    }, 3000);
  };

  const dummyData = {
    footer_text_1: "Class Teacher",
    footer_text_2: "Exam Coordinator",
    footer_text_3: "The Principal",
    footer_title: "Verification / Approval",
  };

  const renderValue = (real: any, fake: any) => {
    if (url === "customize") {
      return fake;
    } else {
      return real;
    }
  };

  return (
    <>
      {(props.single || props.multiple) && (
        <>
          <Sticky>
            <ReactToPrint
              trigger={() => (
                <p
                  style={{
                    backgroundColor: "#132E98",
                    color: "white",
                    fontWeight: "bold",
                    borderRadius: "0px",
                    margin: "0px",
                    textAlign: "center",
                    padding: "5px 0px",
                    cursor: "pointer",
                  }}
                >
                  Print This Out !
                </p>
              )}
              content={() => componentRef.current}
              removeAfterPrint={true}
              copyStyles={true}
            />
          </Sticky>
        </>
      )}
      <div className={classes.roottable} id="print-element" ref={componentRef}>
        <div
          style={{
            backgroundImage: `url(${
              customizedData.watermark &&
              checkImageUrl(reportSettingState[0]?.watermark)
            })`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "300px",
            backgroundPosition: "center",
          }}
        >
          <div id="marksheet-print" className="marksheet-print">
            <div
              style={{ margin: "25px", borderStyle: "double", padding: "25px" }}
            >
              {/* <div id="test">manoz acharya</div> */}
              <Spacing>
                <div>
                  <Paragraph small left>
                    <b>Reg. No: {schoolState?.school_reg_number}</b>
                  </Paragraph>
                  <SchoolInfoContainer>
                    <Paragraph large>{schoolState?.slogan}</Paragraph>
                    <SchoolTitleBig>{schoolState?.school_name}</SchoolTitleBig>
                    {!customizedData?.school_logo && (
                      <>
                        <Paragraph medium>{schoolState?.address}</Paragraph>
                        <Paragraph medium>
                          Phone: {schoolState?.phone_number}
                        </Paragraph>
                        <Paragraph medium>
                          Email: {schoolState?.email}
                        </Paragraph>
                        <ReportType>
                          <Paragraph>
                            CHARACTER | TRANSFER CERTIFICATE
                          </Paragraph>
                          <Paragraph large italic>
                            {examState?.exam_name}{" "}
                            {examState?.start_date &&
                              dateConverterAdToBs(examState?.start_date).slice(
                                0,
                                4
                              )}
                          </Paragraph>
                        </ReportType>
                      </>
                    )}
                  </SchoolInfoContainer>

                  {customizedData?.school_logo && (
                    <SchoolInfoContainerLogo>
                      {customizedData?.school_logo_position === "L" ? (
                        <div>
                          <img
                            src={checkImageUrl(schoolState?.logo)}
                            height="120px"
                            width="120px"
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            maxHeight: "125px",
                            maxWidth: "110px",
                            minHeight: "125px",
                            minWidth: "110px",
                            border: props.editor ? "1px solid black" : "",
                          }}
                        >
                          {!props.editor && (
                            <img
                              src={checkImageUrl(props.data?.student_photo)}
                              height="125"
                              width="110"
                            />
                          )}
                        </div>
                      )}
                      <div>
                        <Paragraph medium>{schoolState?.address}</Paragraph>
                        <Paragraph medium>
                          Phone: {schoolState?.phone_number}
                        </Paragraph>
                        <Paragraph medium>
                          Email: {schoolState?.email}
                        </Paragraph>
                        <ReportType>
                          <Paragraph large>
                            CHARACTER | TRANSFER CERTIFICATE
                          </Paragraph>
                          <Paragraph large italic>
                            {examState?.exam_name}{" "}
                            {examState?.start_date &&
                              dateConverterAdToBs(examState?.start_date).slice(
                                0,
                                4
                              )}
                          </Paragraph>
                        </ReportType>
                      </div>
                      {customizedData?.school_logo_position === "R" ? (
                        <div>
                          <img
                            src={checkImageUrl(schoolState?.logo)}
                            height="120px"
                            width="120px"
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            maxHeight: "125px",
                            maxWidth: "110px",
                            minHeight: "125px",
                            minWidth: "110px",
                            border: props.editor ? "1px solid black" : "",
                          }}
                        >
                          {!props.editor && (
                            <img
                              src={checkImageUrl(props.data?.student_photo)}
                              height="125"
                              width="110"
                            />
                          )}
                        </div>
                      )}
                    </SchoolInfoContainerLogo>
                  )}
                </div>
              </Spacing>
              {props.editor ? (
                <>
                  <div style={{ margin: "30px 10px" }}>
                    <ParagraphHighHeight>
                      This is to certify that
                      ................................................
                      Son/Daughter of
                      .........................................................
                      resident of
                      ............................................................
                      has successfully completed
                      ................................ at Himalayan College of
                      Engineering with excellent result. His/Her overall
                      performance on the class is highly appreciable. According
                      to the birth certificate, His/Her date of birth is
                      ............................ B.S.
                    </ParagraphHighHeight>
                    <b style={{ fontSize: "16px" }}>
                      We wish every success in your future life.
                    </b>
                  </div>
                </>
              ) : (
                <>
                  <div style={{ margin: "30px 10px" }}>
                    <ParagraphHighHeight>
                      This is to certify that
                      <span
                        style={{
                          borderBottom: "1px dotted black",
                          margin: "0px 15px",
                          padding: "0px 5px",
                        }}
                      >
                        {props.data?.student_name}
                      </span>{" "}
                      Son/Daughter of
                      <span
                        style={{
                          borderBottom: "1px dotted black",
                          margin: "0px 15px",
                          padding: "0px 5px",
                        }}
                      >
                        {props.data?.father_name}
                      </span>
                      resident of
                      <span
                        style={{
                          borderBottom: "1px dotted black",
                          margin: "0px 15px",
                          padding: "0px 5px",
                        }}
                      >
                        {props.data?.address}
                      </span>
                      has successfully completed
                      <span
                        style={{
                          borderBottom: "1px dotted black",
                          margin: "0px 15px",
                          padding: "0px 5px",
                        }}
                      >
                        {"Grade " + props.gradeInfo?.grade_name}
                      </span>
                      at{" "}
                      <span
                        style={{
                          borderBottom: "1px dotted black",
                          margin: "0px 15px",
                          padding: "0px 5px",
                        }}
                      >
                        {props.data?.school_name}
                      </span>{" "}
                      with excellent result. His/Her overall performance on the
                      class is highly appreciable. According to the birth
                      certificate, His/Her date of birth is
                      <span
                        style={{
                          borderBottom: "1px dotted black",
                          margin: "0px 15px",
                          padding: "0px 5px",
                        }}
                      >
                        {props.data?.dob &&
                          dateConverterAdToBs(props.data?.dob)}
                      </span>{" "}
                      B.S.
                    </ParagraphHighHeight>
                    <b style={{ fontSize: "16px" }}>
                      We wish every success in your future life.
                    </b>
                  </div>
                </>
              )}

              <div
                className={classes.tableWrapper}
                style={{ marginTop: "5px" }}
              >
                <Spacing>
                  <Grid
                    container
                    spacing={3}
                    style={{
                      marginTop: "10px",
                      marginBottom: "5px",
                    }}
                  >
                    <Grid container item xs={12}>
                      <Grid item xs={4}>
                        <Paragraph medium left overline>
                          {props.editor ? (
                            <input
                              type="text"
                              className="editable"
                              onChange={(event) => handleFooterText1(event)}
                              style={{
                                width: "150px",
                                textAlign: "center",
                                borderTop: "1px solid black",
                              }}
                              value={customizedData.footer_text_1}
                            />
                          ) : (
                            renderValue(
                              customizedData?.footer_text_1,
                              dummyData?.footer_text_1
                            )
                          )}
                        </Paragraph>
                      </Grid>
                      <Grid item xs={4} alignContent="center">
                        <Paragraph medium overline>
                          {props.editor ? (
                            <input
                              type="text"
                              className="editable"
                              onChange={(event) => handleFooterText2(event)}
                              style={{
                                width: "150px",
                                textAlign: "center",
                                borderTop: "1px solid black",
                              }}
                              value={customizedData.footer_text_2}
                            />
                          ) : (
                            renderValue(
                              customizedData?.footer_text_2,
                              dummyData?.footer_text_2
                            )
                          )}
                        </Paragraph>
                      </Grid>
                      <Grid item xs={4}>
                        <Paragraph medium right overline>
                          {props.editor ? (
                            <input
                              type="text"
                              className="editable"
                              onChange={(event) => handleFooterText3(event)}
                              style={{
                                width: "150px",
                                textAlign: "center",
                                borderTop: "1px solid black",
                              }}
                              value={customizedData.footer_text_3}
                            />
                          ) : (
                            renderValue(
                              customizedData.footer_text_3,
                              dummyData?.footer_text_3
                            )
                          )}
                        </Paragraph>
                      </Grid>
                    </Grid>
                  </Grid>
                </Spacing>
              </div>
            </div>
          </div>
        </div>
        {props.editor && (
          <div
            style={{
              border: "2px dotted #445DA7",
              borderRadius: "5px",
              padding: "20px",
              margin: "25px",
            }}
          >
            <Grid container spacing={1}>
              <Grid container item xs={12} spacing={3}>
                <Grid item xs={5} className={formClasses.formWrapper}>
                  <FormControl>
                    <b>Enable / Disable Options</b>
                    <FormGroup
                      style={{ backgroundColor: "white", marginTop: "20px" }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={customizedData.watermark}
                            onChange={handlewatermarkChange}
                            name="watermark"
                          />
                        }
                        label="Watermark"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={customizedData.school_logo}
                            onChange={handleSchoolLogoChange}
                            name="school_logo"
                          />
                        }
                        label="School Logo"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={4} className={formClasses.formWrapper}>
                  {customizedData.school_logo && (
                    <>
                      <b style={{ marginBottom: "10px" }}>Select Options</b>
                      <FormControl style={{ marginTop: "10px" }}>
                        <InputLabel htmlFor="demo-customized-select-native">
                          School Logo Position
                        </InputLabel>
                        <NativeSelect
                          id="demo-customized-select-native"
                          value={customizedData.school_logo_position}
                          onChange={handleSchoolLogoPosition}
                          input={<BootstrapInput />}
                        >
                          <option value="L">Top Left</option>
                          <option value="R">Top Right</option>
                        </NativeSelect>
                      </FormControl>
                    </>
                  )}
                </Grid>
                <Grid item xs={3}></Grid>
              </Grid>
            </Grid>
            <FormSaveLoadingButton
              onClick={() => handlleSubmit()}
              icon={isLoading && <LoaderIcon />}
              disabled={isLoading ? true : false}
              text={isLoading ? "Saving" : "Save"}
            />
          </div>
        )}
      </div>
    </>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default CharacterCertificatePopup1;
