import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_ENOTE_CATEGORY_LOADING,
  GET_ENOTE_CATEGORY_ERROR,
  GET_ENOTE_CATEGORY_SUCCESS,
  EnoteCategoryDispatchTypes,
  ADD_ENOTE_CATEGORY_LOADING,
  ADD_ENOTE_CATEGORY_SUCCESS,
  ADD_ENOTE_CATEGORY_ERROR,
  DELETE_ENOTE_CATEGORY_SUCCESS,
  DELETE_ENOTE_CATEGORY_ERROR,
  DELETE_ENOTE_CATEGORY_LOADING,
  UPDATE_ENOTE_CATEGORY_LOADING,
  UPDATE_ENOTE_CATEGORY_SUCCESS,
  UPDATE_ENOTE_CATEGORY_ERROR,
} from "./EnoteCategoryActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

interface RequestDataI {
  id: string;
  name: string;
}

export const GetEnoteCategorys =
  () =>
  async (dispatch: Dispatch<EnoteCategoryDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_ENOTE_CATEGORY_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/e_note_category/`,
        HeaderConfig()
      );

      dispatch({
        type: GET_ENOTE_CATEGORY_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_ENOTE_CATEGORY_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "Enote Categories Loading Failed",
      //   },
      // });
    }
  };

export const AddEnoteCategory =
  (data: RequestDataI) =>
  async (dispatch: Dispatch<EnoteCategoryDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_ENOTE_CATEGORY_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/e_note_category/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_ENOTE_CATEGORY_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Enote Category Added Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: ADD_ENOTE_CATEGORY_ERROR,
        payload: error.response && error.response.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Enote Category Add Failed",
        },
      });
    }
  };

export const UpdateEnoteCategory =
  (id: string, data: RequestDataI) =>
  async (dispatch: Dispatch<EnoteCategoryDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_ENOTE_CATEGORY_LOADING,
      });

      const res = await axios.put(
        `${ADMIN_API_URL}/e_note_category/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_ENOTE_CATEGORY_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Enote Category Updated Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: UPDATE_ENOTE_CATEGORY_ERROR,
        payload: error.response && error.response.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Enote Category Update Failed",
        },
      });
    }
  };

export const DeleteEnoteCategory =
  (id: string) =>
  async (dispatch: Dispatch<EnoteCategoryDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_ENOTE_CATEGORY_LOADING,
      });

      const res = await axios.delete(
        `${ADMIN_API_URL}/e_note_category/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_ENOTE_CATEGORY_SUCCESS,
        payload: { id: id },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Enote Category Deleted Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_ENOTE_CATEGORY_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Enote Category Delete Failed",
        },
      });
    }
  };
