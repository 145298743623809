import React from "react";
import { HRsidebar } from "../../../components";
import AttendanceDetails from "../../../components/HumanResource/Attendance/AttendanceDetails";
import SubModuleLayout from "../../../components/Reusable/Layouts/SubModuleLayout";

const AttendanceDetailsPage = () => {
  const nav = <HRsidebar attendance />;
  return (
    <SubModuleLayout HumanMenuActive sideNav={nav}>
      <AttendanceDetails />
    </SubModuleLayout>
  );
};

export default AttendanceDetailsPage;
