export const GET_TIME_TABLE_LOADING = "GET_TIME_TABLE_LOADING";
export const GET_TIME_TABLE_ERROR = "GET_TIME_TABLE_ERROR";
export const GET_TIME_TABLE_SUCCESS = "GET_TIME_TABLE_SUCCESS";

export const GET_TIME_TABLE_BY_DAY_LOADING = "GET_TIME_TABLE_BY_DAY_LOADING";
export const GET_TIME_TABLE_BY_DAY_ERROR = "GET_TIME_TABLE_BY_DAY_ERROR";
export const GET_TIME_TABLE_BY_DAY_SUCCESS = "GET_TIME_TABLE_BY_DAY_SUCCESS";

export const ADD_TIME_TABLE_LOADING = "ADD_TIME_TABLE_LOADING";
export const ADD_TIME_TABLE_ERROR = "ADD_TIME_TABLE_ERROR";
export const ADD_TIME_TABLE_SUCCESS = "ADD_TIME_TABLE_SUCCESS";

export const UPDATE_TIME_TABLE_LOADING = "UPDATE_TIME_TABLE_LOADING";
export const UPDATE_TIME_TABLE_ERROR = "UPDATE_TIME_TABLE_ERROR";
export const UPDATE_TIME_TABLE_SUCCESS = "UPDATE_TIME_TABLE_SUCCESS";

export const DELETE_TIME_TABLE_LOADING = "DELETE_TIME_TABLE_LOADING";
export const DELETE_TIME_TABLE_ERROR = "DELETE_TIME_TABLE_ERROR";
export const DELETE_TIME_TABLE_SUCCESS = "DELETE_TIME_TABLE_SUCCESS";

export type TimeTableTypeI = {
  id: string;
  section: string;
  section_name: string;
  grade: string;
  grade_name: string;
  subject: string;
  subject_name: string;
  teacher: string;
  teacher_full_name: string;
  start_time: string;
  end_time: string;
  day: number;
  created_by: string;
  general_info_name: string;
};

export interface TimeTableByDayI {
  day: number;
  timeTable: TimeTableTypeI[];
}

//===============================<START>GET TIME TABLE<START>======================//

export interface GetTimeTableLoading {
  type: typeof GET_TIME_TABLE_LOADING;
}

export interface GetTimeTableError {
  type: typeof GET_TIME_TABLE_ERROR;
}

export interface GetTimeTableSuccess {
  type: typeof GET_TIME_TABLE_SUCCESS;
  payload: TimeTableTypeI[];
}

//===============================<END>GET TIME TABLE<END>======================//

//===============================<START>GET TIME TABLE BY DAY <START>======================//

export interface GetTimeTableByDayLoading {
  type: typeof GET_TIME_TABLE_BY_DAY_LOADING;
}

export interface GetTimeTableByDayError {
  type: typeof GET_TIME_TABLE_BY_DAY_ERROR;
}

export interface GetTimeTableByDaySuccess {
  type: typeof GET_TIME_TABLE_BY_DAY_SUCCESS;
  payload: TimeTableByDayI;
}

//===============================<END>GET TIME TABLE BY DAY <END>======================//

//===============================<START>ADD TIME TABLE<START>======================//

export interface AddTimeTableLoading {
  type: typeof ADD_TIME_TABLE_LOADING;
}

export interface AddTimeTableError {
  type: typeof ADD_TIME_TABLE_ERROR;
}

export interface AddTimeTableSuccess {
  type: typeof ADD_TIME_TABLE_SUCCESS;
  payload: TimeTableTypeI;
}

//===============================<END>ADD TIME TABLE<END>======================//

//===============================<START>UPDATE TIME TABLE<START>======================//

export interface UpdateTimeTableLoading {
  type: typeof UPDATE_TIME_TABLE_LOADING;
}

export interface UpdateTimeTableError {
  type: typeof UPDATE_TIME_TABLE_ERROR;
}

export interface UpdateTimeTableSuccess {
  type: typeof UPDATE_TIME_TABLE_SUCCESS;
  payload: TimeTableTypeI;
}

//===============================<END>UPDATE TIME TABLE<END>======================//

//===============================<START>DELETE TIME TABLE<START>======================//

export interface DeleteTimeTableLoading {
  type: typeof DELETE_TIME_TABLE_LOADING;
}

export interface DeleteTimeTableError {
  type: typeof DELETE_TIME_TABLE_ERROR;
}

export interface DeleteTimeTableSuccess {
  type: typeof DELETE_TIME_TABLE_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE TIME TABLE<END>======================//

export type TimeTableDispatchTypes =
  | GetTimeTableLoading
  | GetTimeTableError
  | GetTimeTableSuccess
  | GetTimeTableByDayLoading
  | GetTimeTableByDayError
  | GetTimeTableByDaySuccess
  | AddTimeTableLoading
  | AddTimeTableError
  | AddTimeTableSuccess
  | UpdateTimeTableLoading
  | UpdateTimeTableError
  | UpdateTimeTableSuccess
  | DeleteTimeTableLoading
  | DeleteTimeTableError
  | DeleteTimeTableSuccess;
