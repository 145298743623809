import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import Popups from '../../Reusable/Dialogs/Popups';
import BraftEditor from 'braft-editor';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { SET_SNACKBAR } from '../../../actions/SnackbarActionTypes';

const StyledBraftEditor = styled(BraftEditor)`
  background-color: ${({ theme }) => theme.palette.common.white};
`;

interface EventNoticeFormProps {
  notice: string;
  handleChange: (data: string) => void;
  handleClose: () => void;
}

const EventNoticeForm = (props: EventNoticeFormProps) => {
  const { notice, handleChange, handleClose } = props;
  const dispatch = useDispatch();

  const [editorState, setEditorState] = useState<any>();

  useEffect(() => {
    setEditorState(BraftEditor.createEditorState(notice));
  }, []);

  const handleEditorChange = (data: any) => {
    setEditorState(data);
  };

  const handleSubmit = () => {
    handleChange(editorState.toHTML());
    handleClose();

    dispatch({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarType: 'success',
        snackbarMessage: 'Event notice submitted successfully',
      },
    });
  };

  return (
    <React.Fragment>
      <StyledBraftEditor
        language="en"
        value={editorState}
        onChange={handleEditorChange}
      />

      <Button
        size="medium"
        variant="contained"
        color="primary"
        onClick={handleSubmit}
      >
        Submit Notice
      </Button>
    </React.Fragment>
  );
};

export default EventNoticeForm;
