import {
  GET_FEE_LEDGER_REPORT_LOADING,
  GET_FEE_LEDGER_REPORT_ERROR,
  GET_FEE_LEDGER_REPORT_SUCCESS,
  GET_DUE_FEE_REPORT_LOADING,
  GET_DUE_FEE_REPORT_ERROR,
  GET_DUE_FEE_REPORT_SUCCESS,
  GET_FEE_TYPE_REPORT_LOADING,
  GET_FEE_TYPE_REPORT_ERROR,
  GET_FEE_TYPE_REPORT_SUCCESS,
  GET_MONTHLY_FEE_REPORT_LOADING,
  GET_MONTHLY_FEE_REPORT_ERROR,
  GET_MONTHLY_FEE_REPORT_SUCCESS,
  GET_FEE_LEDGER_BY_DATE_LOADING,
  GET_FEE_LEDGER_BY_DATE_ERROR,
  GET_FEE_LEDGER_BY_DATE_SUCCESS,
  FeeLedgerDispatchTypes,
  FeeLedgerByDateI,
  FeeTypeReportI,
  FeeLedgerTypeI,
  MonthlyFeeReportI,
  LedgerDetailPayloadI
} from "../../actions/Fees/FeeLedger/FeeLedgerActionTypes";

interface InitialStateI {
  loading: boolean;
  fee_ledger: FeeLedgerTypeI[];
  due_fee: FeeLedgerTypeI[];
  feeType_report: FeeTypeReportI[];
  monthlyFee_report: MonthlyFeeReportI[];
  feeLedgerByDate: LedgerDetailPayloadI | null;
}

const initialState: InitialStateI = {
  loading: false,
  fee_ledger: [],
  due_fee: [],
  feeType_report: [],
  monthlyFee_report: [],
  feeLedgerByDate: null
};

const FeeLedgerReducer = (
  state: InitialStateI = initialState,
  action: FeeLedgerDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_FEE_LEDGER_REPORT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_FEE_LEDGER_REPORT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_FEE_LEDGER_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        fee_ledger: action.payload,
      };

    case GET_DUE_FEE_REPORT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_DUE_FEE_REPORT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_DUE_FEE_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        due_fee: action.payload,
      };

    case GET_FEE_TYPE_REPORT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_FEE_TYPE_REPORT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_FEE_TYPE_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        feeType_report: action.payload
      };

    case GET_MONTHLY_FEE_REPORT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_MONTHLY_FEE_REPORT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_MONTHLY_FEE_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        monthlyFee_report: action.payload
      };

    case GET_FEE_LEDGER_BY_DATE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_FEE_LEDGER_BY_DATE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_FEE_LEDGER_BY_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        feeLedgerByDate: action.payload
      };

    default:
      return state;
  }
};

export default FeeLedgerReducer;
