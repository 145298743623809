import React from 'react';
import { Grid } from '@material-ui/core';
import { HRsidebar } from '../../components';
import SubModuleLayout from '../../components/Reusable/Layouts/SubModuleLayout';
import LeaveList from '../../components/HumanResource/Leave/LeaveList';
import LeaveSearch from '../../components/HumanResource/Leave/LeaveSearch';
import OnLeaveList from '../../components/HumanResource/Leave/OnLeaveList';

const LeavePage = () => {
  const nav = <HRsidebar Leave />;
  return (
    <SubModuleLayout studentMenuActive sideNav={nav}>
      <Grid container>
        <Grid item xs={12}>
          <LeaveSearch />
          <LeaveList />
        </Grid>
        {/* Remove until new api arrives */}
        {/* <Grid item xs={3}>
          <OnLeaveList />
        </Grid> */}
      </Grid>
    </SubModuleLayout>
  );
};
export default LeavePage;
