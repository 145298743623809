import React from "react";
import { SettingSidebar } from "../../components";
import ManagePasswordForm from "../../components/GeneralSetting/ManagePasswordForm";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const ManagePasswordPage = () => {
  const nav = <SettingSidebar managePassword />;
  return (
    <SubModuleLayout sideNav={nav}>
      <ManagePasswordForm />
    </SubModuleLayout>
  );
};

export default ManagePasswordPage;
