import React, { useEffect, useState } from 'react';
import {
  Button,
  FormHelperText,
  Grid,
  makeStyles,
  Theme,
  InputLabel,
} from '@material-ui/core';
import { useForm, SubmitHandler } from 'react-hook-form';
import ReactInputVerificationCode from 'react-input-verification-code';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import AuthForm from '../Components/AuthForm';
import AuthBanner from '../Components/AuthBanner';
import { RootStore } from '../../../store';
import styled from 'styled-components';
import { VerifyOTP } from '../../../actions/Auth/Login/LoginAction';

interface FormValuesI {
  phone: string;
  password: string;
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    form: {
      marginTop: theme.spacing(6),
      maxWidth: 340,
    },
    link: {
      fontWeight: 600,
    },
  };
});

const StyledInputLabel = styled(InputLabel)`
  color: #222;
  margin-bottom: 16px;
  font-weight: 500;
`;

const OtpValidation = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, handleSubmit } = useForm<FormValuesI>();

  const [otp, setOtp] = useState('');
  const [err, setErr] = useState('');

  const authenticationState = useSelector(
    (state: RootStore) => state.auth.authentication
  );

  useEffect(() => {
    const { loading, error } = authenticationState;
    const { phone_user, otp_detail, otp_status } = authenticationState;

    if (!loading && !error) {
      if (phone_user && otp_detail && otp_status === 'Verified') {
        history.push('/authentication/password-reset');
      }
    }
  }, [authenticationState]);

  if (!authenticationState.phone_user || !authenticationState.otp_detail) {
    history.push('/authentication/password-recovery');
  }

  const onFormSubmit: SubmitHandler<FormValuesI> = (data) => {
    if (
      otp &&
      !otp.includes('·') &&
      authenticationState &&
      authenticationState.otp_detail
    ) {
      dispatch(
        VerifyOTP({
          otp_id: authenticationState.otp_detail.id,
          otp: otp,
        })
      );
    } else {
      setErr('Please enter your one time verification code.');
    }
  };

  const handleOTPChange = (value: string) => {
    setOtp(value);
    !value.includes('·') && setErr('');
  };

  const subtitle = (
    <span>
      A one-time password has been sent to{' '}
      <b>
        {authenticationState.phone_user
          ? authenticationState.phone_user.phone
          : ''}
      </b>
    </span>
  );

  return (
    <Grid container>
      <Grid item md={5}>
        <AuthForm
          title="OTP Validation"
          subtitle={subtitle}
          className={classes.form}
          onSubmit={handleSubmit(onFormSubmit)}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} style={{ marginBottom: 16 }}>
              <StyledInputLabel error={Boolean(err)} required>
                Verification Code
              </StyledInputLabel>

              <ReactInputVerificationCode
                onChange={handleOTPChange}
                value={otp}
              />

              {err && (
                <FormHelperText error variant="outlined">
                  {err}
                </FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
              >
                Send
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button fullWidth component={Link} to="/login">
                Back to Login
              </Button>
            </Grid>
          </Grid>
        </AuthForm>
      </Grid>

      <Grid item md={7}>
        <AuthBanner
          id={1}
          title="Learn at your own comfort"
          subtitle="We are ready to serve you with our premium learning contents embedded in a cutting edge technology"
        />
      </Grid>
    </Grid>
  );
};

export default OtpValidation;
