import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableHead,
} from "@material-ui/core";
import { Save } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { setSnackbar } from "../../../actions/SnackbarAction";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import ListIcon from "@material-ui/icons/List";
import {
  applyStudentFeesDiscount,
  getStudentFees,
  returnMonthString,
} from "../../../rtk/features/feeDiscount/feeDiscountApi";
import { changeStudentFeesData } from "../../../rtk/features/feeDiscount/feeDiscountSlice";
import { RootStore } from "../../../store";
import { useTableWithPagination } from "../../Reusable";
import TableAnalyticsCard from "../../../common/Cards/TableAnalytics";

const Wrapper = styled(Paper)`
  background-color: #fff;
  padding: 16px;
  margin: 10px;
`;

const SaveButton = styled(Button)`
  margin-top: 20px;
  width: 120px;
`;

const CellInput = styled.input<{ fullWidth?: boolean; error?: boolean }>`
  && {
    width: ${(props) => (props.fullWidth ? "80%" : "90px")};
    padding: 5px;
    border-radius: 3px;
    border: ${(props) => (props.error ? `1px solid red` : `1px solid #000`)};
    color: ${(props) => (props.error ? `red` : `#000`)};

    &:focus {
      outline: none;
    }
  }
`;

const headCells = [
  { id: "fee_head", label: "Fee Head" },
  { id: "fee_amount", label: "Fee Amount" },
  { id: "discount_per", label: "Discount (%)" },
  { id: "discount_amt", label: "Discount (Amt)" },
  {
    id: "fee_amount_final",
    label: (
      <span>
        Fee Amount <br />
        (Discount Applied)
      </span>
    ),
  },
  { id: "remarks", label: "Remarks" },
];

const StudentDiscount = () => {
  const dispatch = useDispatch();

  const [saveDisabled, setSaveDisabled] = useState<boolean>(false);

  const [totalDiscount, setTotalDiscount] = useState<number>(0);
  const [totalDiscountFee, setTotalDiscountFee] = useState<number>(0);

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTableWithPagination(headCells);

  const { studentFees, actionPerformed, searchParams } = useSelector(
    (state: RootStore) => state.feeDiscount
  );

  useEffect(() => {
    if (studentFees.length) {
      if (studentFees.find((el) => el.error)) {
        setSaveDisabled(true);
      } else {
        setSaveDisabled(false);
      }
    } else {
      setSaveDisabled(false);
    }
  }, [studentFees]);

  useEffect(() => {
    if (actionPerformed) {
      if (searchParams) {
        if (searchParams.student) {
          dispatch(getStudentFees({ student: searchParams.student }));
        }
      }
    }
  }, [actionPerformed]);

  const handleApplyClick = () => {
    const updated = studentFees.filter((el) => el.discount_amt !== "");
    if (updated.length > 0) {
      const post_data = {
        student: updated[0].student_id,
        fee_types: updated.map((el) => ({
          fee_type: el.fee_head_id,
          month: el.month,
          amount: el.discount_amt,
          remarks: el.remarks,
        })),
      };
      dispatch(applyStudentFeesDiscount(post_data));
    } else {
      dispatch(setSnackbar(true, "warning", "No discount applied to any fees"));
    }
  };

  useEffect(() => {
    const totalAmount = studentFees?.reduce(
      (accumulator: any, currentValue: any) => {
        return accumulator + Number(currentValue.discount_amt || 0);
      },
      0
    );
    setTotalDiscount(totalAmount);

    const discountApplied = studentFees?.filter(
      (elem: any) => Number(elem.discount_per) > 0
    );
    setTotalDiscountFee(discountApplied?.length);
  }, [studentFees]);

  return (
    <Wrapper>
      {studentFees?.length > 0 && (
        <Grid container spacing={2} style={{ marginBottom: "10px" }}>
          <Grid item md={6}>
            <TableAnalyticsCard
              item={{
                icon: LocalOfferIcon,
                title: "Total Discount",
                color: "#E6B160",
                total: `${totalDiscount} /-`,
                tagline: "Amount in NPR",
              }}
            />
          </Grid>
          <Grid item md={6}>
            <TableAnalyticsCard
              item={{
                icon: ListIcon,
                title: "No. of Fee",
                color: "#43a047",
                total: totalDiscountFee,
                tagline: "Where discount applied",
              }}
            />
          </Grid>
        </Grid>
      )}
      <Table>
        <TableHead>
          <StyledTableRow>
            {headCells.map((el) => (
              <StyledTableCell key={el.id} align="center">
                {el.label}
              </StyledTableCell>
            ))}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {studentFees.map((item) => (
            <StyledTableRow key={item.id}>
              <StyledTableCell align="center">{`${item.fee_head} ${
                item.month ? " - " + returnMonthString(item.month) : ""
              }`}</StyledTableCell>
              <StyledTableCell align="center">
                {item.fee_amount}
              </StyledTableCell>
              <StyledTableCell align="center">
                <CellInput
                  value={item.discount_per || ""}
                  onChange={(e) =>
                    dispatch(
                      changeStudentFeesData({
                        id: item.id,
                        field: "percent",
                        value: e.target.value,
                      })
                    )
                  }
                  error={item.error}
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <CellInput
                  value={item.discount_amt || ""}
                  onChange={(e) =>
                    dispatch(
                      changeStudentFeesData({
                        id: item.id,
                        field: "amount",
                        value: e.target.value,
                      })
                    )
                  }
                  error={item.error}
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                {item.fee_amount_final}
              </StyledTableCell>
              <StyledTableCell align="center">
                <CellInput
                  fullWidth
                  value={item.remarks}
                  onChange={(e) =>
                    dispatch(
                      changeStudentFeesData({
                        id: item.id,
                        field: "remarks",
                        value: e.target.value,
                      })
                    )
                  }
                />
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      <Grid container>
        <SaveButton
          variant="contained"
          color="primary"
          startIcon={<Save />}
          onClick={handleApplyClick}
          disabled={saveDisabled}
        >
          Save
        </SaveButton>
      </Grid>
    </Wrapper>
  );
};

export default StudentDiscount;
