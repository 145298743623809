import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_SCHOLARSHIP_TYPE_LOADING,
  GET_SCHOLARSHIP_TYPE_ERROR,
  GET_SCHOLARSHIP_TYPE_SUCCESS,
  GET_SCHOLARSHIP_STUDENT_LOADING,
  GET_SCHOLARSHIP_STUDENT_ERROR,
  GET_SCHOLARSHIP_STUDENT_SUCCESS,
  GET_SCHOLARSHIP_REPORT_LOADING,
  GET_SCHOLARSHIP_REPORT_ERROR,
  GET_SCHOLARSHIP_REPORT_SUCCESS,
  ADD_SCHOLARSHIP_TYPE_LOADING,
  ADD_SCHOLARSHIP_TYPE_SUCCESS,
  ADD_SCHOLARSHIP_TYPE_ERROR,
  DELETE_SCHOLARSHIP_TYPE_SUCCESS,
  DELETE_SCHOLARSHIP_TYPE_ERROR,
  DELETE_SCHOLARSHIP_TYPE_LOADING,
  UPDATE_SCHOLARSHIP_TYPE_LOADING,
  UPDATE_SCHOLARSHIP_TYPE_SUCCESS,
  UPDATE_SCHOLARSHIP_TYPE_ERROR,
  APPLY_SCHOLARSHIP_LOADING,
  APPLY_SCHOLARSHIP_ERROR,
  APPLY_SCHOLARSHIP_SUCCESS,
  REMOVE_SCHOLARSHIP_LOADING,
  REMOVE_SCHOLARSHIP_ERROR,
  REMOVE_SCHOLARSHIP_SUCCESS,
  GET_SCHOLARSHIP_LOADING,
  GET_SCHOLARSHIP_ERROR,
  GET_SCHOLARSHIP_SUCCESS,
  ScholarshipDispatchTypes,
  ScholarshipType,
  ApplyScholarShipI,
  GET_SCHOLARSHIP_BY_STUDENT_LOADING,
  GET_SCHOLARSHIP_BY_STUDENT_ERROR,
  GET_SCHOLARSHIP_BY_STUDENT_SUCCESS,
} from "./ScholarshipActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../SnackbarActionTypes";
import { HeaderConfig } from "../Auth/Login/LoginAction";
import { CategoryTypeI } from "../Student/StudentCategory/CategoryActionTypes";
import { ADMIN_API_URL } from "../../config/SERVER_API_URLS";

export type RequestDataI = {
  scholarship_title: string;
  fee_type: string;
  scholarship_type: string;
  scholarship_in?: string | undefined;
  applicable_scholarship?: number | undefined;
  description: string;
};

export const GetScholarshipType =
  () => async (dispatch: Dispatch<ScholarshipDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_SCHOLARSHIP_TYPE_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/scholarship_type/`,
        HeaderConfig()
      );

      dispatch({
        type: GET_SCHOLARSHIP_TYPE_SUCCESS,
        payload: res.data.results,
      });
    } catch (error) {
      dispatch({
        type: GET_SCHOLARSHIP_TYPE_ERROR,
      });
    }
  };

export const AddScholarshipType =
  (data: any) =>
  async (dispatch: Dispatch<ScholarshipDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_SCHOLARSHIP_TYPE_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/scholarship_type/`,
        data,
        HeaderConfig()
      );
      dispatch({
        type: ADD_SCHOLARSHIP_TYPE_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Scholarship Type Added Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: ADD_SCHOLARSHIP_TYPE_ERROR,
        payload: error.response && error.response?.data,
      });
    } finally {
      try {
        dispatch({
          type: GET_SCHOLARSHIP_TYPE_LOADING,
        });

        const res = await axios.get(
          `${ADMIN_API_URL}/scholarship_type/`,
          HeaderConfig()
        );

        dispatch({
          type: GET_SCHOLARSHIP_TYPE_SUCCESS,
          payload: res.data.results,
        });
      } catch (error) {
        dispatch({
          type: GET_SCHOLARSHIP_TYPE_ERROR,
        });
      }
    }
  };

export const UpdateScholarshipType =
  (id: string, data: any) =>
  async (dispatch: Dispatch<ScholarshipDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_SCHOLARSHIP_TYPE_LOADING,
      });

      const res = await axios.put(
        `${ADMIN_API_URL}/scholarship_type/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_SCHOLARSHIP_TYPE_SUCCESS,
        payload: res.data,
      });
      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Scholarship Type Updated Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: UPDATE_SCHOLARSHIP_TYPE_ERROR,
        payload: error.response && error.response?.data,
      });
      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Scholarship Type Update Failed",
        },
      });
    } finally {
      try {
        dispatch({
          type: GET_SCHOLARSHIP_TYPE_LOADING,
        });

        const res = await axios.get(
          `${ADMIN_API_URL}/scholarship_type/`,
          HeaderConfig()
        );

        dispatch({
          type: GET_SCHOLARSHIP_TYPE_SUCCESS,
          payload: res.data.results,
        });
      } catch (error) {
        dispatch({
          type: GET_SCHOLARSHIP_TYPE_ERROR,
        });
      }
    }
  };

export const DeleteScholarshipType =
  (id: string) =>
  async (dispatch: Dispatch<ScholarshipDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_SCHOLARSHIP_TYPE_LOADING,
      });

      const res = await axios.delete(
        `${ADMIN_API_URL}/scholarship_type/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_SCHOLARSHIP_TYPE_SUCCESS,
        payload: { id: id },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Scholarship Type Deleted Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_SCHOLARSHIP_TYPE_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Scholarship Type Delete Failed",
        },
      });
    } finally {
      try {
        dispatch({
          type: GET_SCHOLARSHIP_TYPE_LOADING,
        });

        const res = await axios.get(
          `${ADMIN_API_URL}/scholarship_type/`,
          HeaderConfig()
        );

        dispatch({
          type: GET_SCHOLARSHIP_TYPE_SUCCESS,
          payload: res.data.results,
        });
      } catch (error) {
        dispatch({
          type: GET_SCHOLARSHIP_TYPE_ERROR,
        });
      }
    }
  };

export const GetScholarshipStudentList =
  (grade: string, section: string) =>
  async (dispatch: Dispatch<ScholarshipDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_SCHOLARSHIP_STUDENT_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/scholarship-student-list/?grade=${grade}&section=${section}&per_page=1000`,
        HeaderConfig()
      );

      dispatch({
        type: GET_SCHOLARSHIP_STUDENT_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_SCHOLARSHIP_STUDENT_ERROR,
      });
    }
  };

export const GetScholarshipReportList =
  (scholarship: string) =>
  async (dispatch: Dispatch<ScholarshipDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_SCHOLARSHIP_REPORT_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/report/scholarship-type?scholarship_type=${scholarship}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_SCHOLARSHIP_REPORT_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      console.log("error", error);
      dispatch({
        type: GET_SCHOLARSHIP_REPORT_ERROR,
      });
    }
  };

export const ApplyScholarShip =
  (data: any, reset: any) =>
  async (dispatch: Dispatch<ScholarshipDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: APPLY_SCHOLARSHIP_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/scholarship-student-list/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: APPLY_SCHOLARSHIP_SUCCESS,
        payload: res.data,
      });

      reset();

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Scholarship Applied Successfully",
        },
      });
    } catch (error) {
      console.log("error", error);
      dispatch({
        type: APPLY_SCHOLARSHIP_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Scholarship Applied Failed",
        },
      });
    }
  };

export const RemoveScholarShip =
  (data: ApplyScholarShipI) =>
  async (dispatch: Dispatch<ScholarshipDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: REMOVE_SCHOLARSHIP_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/scholarship_type/remove_student/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: REMOVE_SCHOLARSHIP_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Student Removed Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: REMOVE_SCHOLARSHIP_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Student Removed Failed",
        },
      });
    }
  };

export const GetScholarshipByStudent =
  (student_id: string, fee_type: string) =>
  async (dispatch: Dispatch<ScholarshipDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_SCHOLARSHIP_BY_STUDENT_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/scholarship_type/?student=${student_id}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_SCHOLARSHIP_BY_STUDENT_SUCCESS,
        payload: res.data.results,
      });
    } catch (error) {
      dispatch({
        type: GET_SCHOLARSHIP_BY_STUDENT_ERROR,
      });
    }
  };
