export const GET_ADMIN_STUDENT_LEAVE_LOADING =
  'GET_ADMIN_STUDENT_LEAVE_LOADING';
export const GET_ADMIN_STUDENT_LEAVE_ERROR = 'GET_ADMIN_STUDENT_LEAVE_ERROR';
export const GET_ADMIN_STUDENT_LEAVE_SUCCESS =
  'GET_ADMIN_STUDENT_LEAVE_SUCCESS';

export const ADD_ADMIN_STUDENT_LEAVE_LOADING =
  'ADD_ADMIN_STUDENT_LEAVE_LOADING';
export const ADD_ADMIN_STUDENT_LEAVE_ERROR = 'ADD_ADMIN_STUDENT_LEAVE_ERROR';
export const ADD_ADMIN_STUDENT_LEAVE_SUCCESS =
  'ADD_ADMIN_STUDENT_LEAVE_SUCCESS';

export const UPDATE_ADMIN_STUDENT_LEAVE_LOADING =
  'UPDATE_ADMIN_STUDENT_LEAVE_LOADING';
export const UPDATE_ADMIN_STUDENT_LEAVE_ERROR =
  'UPDATE_ADMIN_STUDENT_LEAVE_ERROR';
export const UPDATE_ADMIN_STUDENT_LEAVE_SUCCESS =
  'UPDATE_ADMIN_STUDENT_LEAVE_SUCCESS';

export const ADMIN_STUDENT_LEAVE_APPROVE_OR_DECLINE_LOADING =
  'ADMIN_STUDENT_LEAVE_APPROVE_OR_DECLINE_LOADING';
export const ADMIN_STUDENT_LEAVE_APPROVE_OR_DECLINE_SUCCESS =
  'ADMIN_STUDENT_LEAVE_APPROVE_OR_DECLINE_SUCCESS';
export const ADMIN_STUDENT_LEAVE_APPROVE_OR_DECLINE_ERROR =
  'ADMIN_STUDENT_LEAVE_APPROVE_OR_DECLINE_ERROR';

export const DELETE_ADMIN_STUDENT_LEAVE_LOADING =
  'DELETE_ADMIN_STUDENT_LEAVE_LOADING';
export const DELETE_ADMIN_STUDENT_LEAVE_ERROR =
  'DELETE_ADMIN_STUDENT_LEAVE_ERROR';
export const DELETE_ADMIN_STUDENT_LEAVE_SUCCESS =
  'DELETE_ADMIN_STUDENT_LEAVE_SUCCESS';

export const GET_STUDENT_TODAY_ON_LEAVE_LOADING =
  'GET_STUDENT_TODAY_ON_LEAVE_LOADING';
export const GET_STUDENT_TODAY_ON_LEAVE_ERROR =
  'GET_STUDENT_TODAY_ON_LEAVE_ERROR';
export const GET_STUDENT_TODAY_ON_LEAVE_SUCCESS =
  'GET_STUDENT_TODAY_ON_LEAVE_SUCCESS';

export type AdminStudentLeaveTypeI = {
  id: string;
  photo: null | string;
  leave_type: string;
  status: 'A' | 'D' | 'P';
  status_name: 'Approved' | 'Declined' | 'Pending';
  leave_name: string;
  leave_from: string;
  leave_to: string;
  applied_date: string;
  general_info: number;
  reason_for_leave: string;
  no_of_days: string;
  first_name: string;
  last_name: string;
  grade: string;
  section: string;
  designation: any;
  roll_number: string;
};

export type AdminTodayOnStudentLeaveTypeI = {
  id: string;
  staff_name: string;
  designation: string;
};

export type AdminStudentLeaveApproveOrDeclineType = {
  id: string;
  status: 'A' | 'D' | 'P';
};

export interface GetAdminStudentLeaveLoading {
  type: typeof GET_ADMIN_STUDENT_LEAVE_LOADING;
}

export interface GetAdminStudentLeaveError {
  type: typeof GET_ADMIN_STUDENT_LEAVE_ERROR;
}

export interface GetAdminStudentLeaveSuccess {
  type: typeof GET_ADMIN_STUDENT_LEAVE_SUCCESS;
  payload: AdminStudentLeaveTypeI[];
}

export interface GetAdminTodayOnStudentLeaveLoading {
  type: typeof GET_STUDENT_TODAY_ON_LEAVE_LOADING;
}

export interface GetAdminTodayOnStudentLeaveError {
  type: typeof GET_STUDENT_TODAY_ON_LEAVE_ERROR;
}

export interface GetAdminTodayOnStudentLeaveSuccess {
  type: typeof GET_STUDENT_TODAY_ON_LEAVE_SUCCESS;
  payload: AdminTodayOnStudentLeaveTypeI[];
}

export interface AddAdminStudentLeaveLoading {
  type: typeof ADD_ADMIN_STUDENT_LEAVE_LOADING;
}

export interface AddAdminStudentLeaveError {
  type: typeof ADD_ADMIN_STUDENT_LEAVE_ERROR;
}

export interface AddAdminStudentLeaveSuccess {
  type: typeof ADD_ADMIN_STUDENT_LEAVE_SUCCESS;
  payload: AdminStudentLeaveTypeI;
}

export interface UpdateAdminStudentLeaveLoading {
  type: typeof UPDATE_ADMIN_STUDENT_LEAVE_LOADING;
}

export interface UpdateAdminStudentLeaveError {
  type: typeof UPDATE_ADMIN_STUDENT_LEAVE_ERROR;
}

export interface UpdateAdminStudentLeaveSuccess {
  type: typeof UPDATE_ADMIN_STUDENT_LEAVE_SUCCESS;
  payload: AdminStudentLeaveTypeI;
}

export interface DeleteAdminStudentLeaveLoading {
  type: typeof DELETE_ADMIN_STUDENT_LEAVE_LOADING;
}

export interface DeleteAdminStudentLeaveError {
  type: typeof DELETE_ADMIN_STUDENT_LEAVE_ERROR;
}

export interface DeleteAdminStudentLeaveSuccess {
  type: typeof DELETE_ADMIN_STUDENT_LEAVE_SUCCESS;
  payload: { id: string };
}

export interface AdminStudentLeaveApproveOrDeclineLoading {
  type: typeof ADMIN_STUDENT_LEAVE_APPROVE_OR_DECLINE_LOADING;
}

export interface AdminStudentLeaveApproveOrDeclineError {
  type: typeof ADMIN_STUDENT_LEAVE_APPROVE_OR_DECLINE_ERROR;
}

export interface AdminStudentLeaveApproveOrDeclineSuccess {
  type: typeof ADMIN_STUDENT_LEAVE_APPROVE_OR_DECLINE_SUCCESS;
  payload: AdminStudentLeaveApproveOrDeclineType;
}

export type AdminStudentLeaveDispatchTypes =
  | GetAdminStudentLeaveLoading
  | GetAdminStudentLeaveError
  | GetAdminStudentLeaveSuccess
  | AddAdminStudentLeaveLoading
  | AddAdminStudentLeaveError
  | AddAdminStudentLeaveSuccess
  | UpdateAdminStudentLeaveLoading
  | UpdateAdminStudentLeaveError
  | UpdateAdminStudentLeaveSuccess
  | DeleteAdminStudentLeaveLoading
  | DeleteAdminStudentLeaveError
  | DeleteAdminStudentLeaveSuccess
  | AdminStudentLeaveApproveOrDeclineSuccess
  | AdminStudentLeaveApproveOrDeclineError
  | AdminStudentLeaveApproveOrDeclineLoading
  | GetAdminTodayOnStudentLeaveLoading
  | GetAdminTodayOnStudentLeaveError
  | GetAdminTodayOnStudentLeaveSuccess;
