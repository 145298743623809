import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setSnackbar } from "../../../../actions/SnackbarAction";
import { ADMIN_API_URL } from "../../../../config/SERVER_API_URLS";

export const getStudentsByAllSections = createAsyncThunk(
  "getStudentsByAllSections",
  async (
    {
      grade,
      firstSection,
      secondSection,
    }: {
      grade: string;
      firstSection: { id: string; name: string };
      secondSection: { id: string; name: string };
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.get(
        `${ADMIN_API_URL}/student_info/get_all_students_section_transfer/?grade=${grade}`
      );
      return { res: res.data, grade, firstSection, secondSection };
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(null);
    }
  }
);

export const transferSections = createAsyncThunk(
  "transferSections",
  async (
    {
      grade,
      data,
    }: {
      grade: string;
      data: any;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.post(
        `${ADMIN_API_URL}/change_section/?grade=${grade}`,
        data
      );
      dispatch(
        setSnackbar(true, "success", "Successfully transferred sections")
      );
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      dispatch(setSnackbar(true, "error", "Failed to transfer sections "));
      return rejectWithValue(null);
    }
  }
);
