// ---------------------------- <START> module imports start ----------------------------------//
import React, { useState, useEffect } from "react";
import { Paper, Typography } from "@material-ui/core";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import { useTableStyles } from "../../Styles/TableStyles";
import { useTable } from "../../Reusable";
import ActionButton from "../../Reusable/Buttons/ActionButton";
import { PaymentMethodTypeI } from "../../../actions/Fees/PaymentMethod/PaymentMethodActionTypes";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import {
  DeletePaymentMethod,
  GetPaymentMethod,
} from "../../../actions/Fees/PaymentMethod/PaymentMethodAction";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import {
  ItemDeleteButton,
  ItemEditButton,
} from "../../Reusable/Buttons/TableButton";
// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//
interface PropsI {
  onEditData: (value: PaymentMethodTypeI) => void;
  editCheck: { check: boolean; count: number };
}

interface HeadCellsI {
  id: string;
  label: string;
}
// ---------------------------- <END> interface ends ----------------------------------//

// ---------------------------- <START> styling starts ----------------------------------//

// ---------------------------- <END> styling ends ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "name", label: "Name" },
  { id: "action", label: "Action" },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> payment method Table Component Starts ---------------------------//
const PaymentMethodList = (props: PropsI) => {
  const classes = useTableStyles();
  const { onEditData, editCheck } = props;

  // payment method reducer
  const dispatch = useDispatch();
  const paymentMethodState = useSelector(
    (state: RootStore) => state.payment_method
  );

  // Modal States
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string>("");
  // States declaration
  const [tableData, setTableData] = useState<PaymentMethodTypeI[]>([]);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    editCheck && setEditMode(editCheck.check);
  }, [editCheck]);

  useEffect(() => {
    dispatch(GetPaymentMethod());
  }, []);

  useEffect(() => {
    const dataLength = paymentMethodState.payment_method.length;

    setLoading(paymentMethodState.loading);
    setTableData(paymentMethodState.payment_method);
  }, [paymentMethodState]);

  // -----------------<START> Pagination states and functions <START>-----------------------//

  // -----------------<END> Pagination states and functions <END>-----------------------//

  // Retrieving re-usable components from useTable
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  // Function for handling action icons click event
  const handleEditClicked = (data: PaymentMethodTypeI) => {
    onEditData(data);
    setEditMode(true);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const handleDeleteModal = (value: boolean, id: string) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeletePaymentMethod(itemId));
    setDeleteModalOpen(false);
  };

  const getCustomTableRow = (item: PaymentMethodTypeI) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {item.name}
        </StyledTableCell>

        <StyledTableCell align="center" className={classes.cell}>
          <ItemEditButton
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleEditClicked(item)
            }
          />
          <ItemDeleteButton
            disabled={editMode}
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleDeleteModal(true, item.id)
            }
          />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <Paper className={classes.root}>
      <Typography variant="h6">Payment Method List</Typography>

      <TableContainer
        loading={loading}
        items={tableData}
        headCells={headCells}
        getCustomTableRow={getCustomTableRow}
        disableSearch
      />

      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
      />
    </Paper>
  );
};
// ---------------------------- <END> payment method Component Ends -------------------------------//

export default PaymentMethodList;
