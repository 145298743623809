import {
  GET_MEETING_LOADING,
  GET_MEETING_ERROR,
  GET_MEETING_SUCCESS,
  ADD_MEETING_LOADING,
  ADD_MEETING_ERROR,
  ADD_MEETING_SUCCESS,
  DELETE_MEETING_LOADING,
  DELETE_MEETING_ERROR,
  DELETE_MEETING_SUCCESS,
  UPDATE_MEETING_LOADING,
  UPDATE_MEETING_ERROR,
  UPDATE_MEETING_SUCCESS,
  MeetingTypeI,
  MeetingDispatchTypes,
  APPROVE_OR_DECLINE_SUCCESS,
  APPROVE_OR_DECLINE_LOADING,
  APPROVE_OR_DECLINE_ERROR,
  GET_TODAY_MEETING_LOADING,
  GET_TODAY_MEETING_ERROR,
  GET_TODAY_MEETING_SUCCESS,
} from "../../actions/HumanResource/Meeting/MeetingActionTypes";

interface InitialStateI {
  loading: boolean;
  mettings: MeetingTypeI[];
  loadingTodayMeeting: boolean;
  todayMeetings: any;
  errors: any;
}

const initialState: InitialStateI = {
  loading: false,
  mettings: [],
  loadingTodayMeeting: false,
  todayMeetings: [],
  errors: null,
};

const MeetingReducer = (
  state: InitialStateI = initialState,
  action: MeetingDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_MEETING_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };

    case GET_MEETING_ERROR:
      return {
        ...state,
        loading: false,
        errors: null,
      };

    case GET_MEETING_SUCCESS:
      return {
        ...state,
        loading: false,
        mettings: action.payload,
        errors: null,
      };

    case ADD_MEETING_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };

    case ADD_MEETING_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case ADD_MEETING_SUCCESS:
      return {
        ...state,
        loading: false,
        mettings: [...state.mettings, action.payload],
        errors: null,
      };

    case UPDATE_MEETING_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };

    case UPDATE_MEETING_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case UPDATE_MEETING_SUCCESS:
      const current_data: MeetingTypeI[] = state.mettings;
      const index: number = current_data.findIndex(
        (element) => element.id === action.payload.id
      );
      current_data[index] = action.payload;

      return {
        ...state,
        loading: false,
        mettings: current_data,
        errors: null,
      };

    case DELETE_MEETING_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };

    case DELETE_MEETING_ERROR:
      return {
        ...state,
        loading: false,
        errors: null,
      };

    case DELETE_MEETING_SUCCESS:
      const new_data: MeetingTypeI[] = state.mettings;
      const item_index = new_data.findIndex(
        (element) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return {
        ...state,
        loading: false,
        mettings: new_data,
        errors: null,
      };
    case APPROVE_OR_DECLINE_SUCCESS:
      const temp_data = state.mettings;
      const indexNum: number = temp_data.findIndex(
        (element) => element.id === action.payload.id
      );
      const temp_meeting = {
        ...temp_data[indexNum],
        status: action.payload.status,
      };
      temp_data[indexNum] = temp_meeting;

      return {
        ...state,
        loading: false,
        mettings: temp_data,
        errors: null,
      };
    case APPROVE_OR_DECLINE_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case APPROVE_OR_DECLINE_ERROR:
      return {
        ...state,
        loading: false,
        errors: null,
      };
    // Today's Meeting
    case GET_TODAY_MEETING_LOADING:
      return {
        ...state,
        loadingTodayMeeting: true,
        errors: null,
      };
    case GET_TODAY_MEETING_ERROR:
      return {
        ...state,
        loadingTodayMeeting: false,
        errors: null,
      };
    case GET_TODAY_MEETING_SUCCESS:
      return {
        ...state,
        loadingTodayMeeting: false,
        todayMeetings: action.payload,
        errors: null,
      };
    default:
      return state;
  }
};

export default MeetingReducer;
