import React from "react";
import { SettingSidebar } from "../../components";
import TermsConditionForm from "../../components/GeneralSetting/Terms/TermsConditionForm";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const TermsandConditionsPage = () => {
  const nav = <SettingSidebar termsandconditions />;
  return (
    <SubModuleLayout sideNav={nav}>
      <TermsConditionForm />
    </SubModuleLayout>
  );
};

export default TermsandConditionsPage;
