// ---------------- <START> module import ------------------//
import React, { useState, useEffect } from "react";
import { Checkbox, Dialog, Grid, Paper, Typography } from "@material-ui/core";
import { useTable, useTableWithPagination } from "../../Reusable";
import { useTableStyles } from "../../Styles/TableStyles";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import cx from "clsx";
import {
  GetDepartments,
  DeleteDepartment,
} from "../../../actions/HumanResource/Department/DepartmentAction";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import { DepartmentTypeI } from "../../../actions/HumanResource/Department/DepartmentActionTypes";
import { HeadCellsI } from "../../../actions";
import {
  ItemDeleteButton,
  ItemEditButton,
  ItemPrintButton,
  ItemViewButton,
} from "../../Reusable/Buttons/TableButton";
import useLoading from "../../../useHook/useLoading";
import {
  GetDaybook,
  GetInvoiceDetail,
} from "../../../actions/Fees/Daybook/DaybookAction";
import getFullName from "../../utils/getFullName";
import getFullClassName from "../../utils/getFullClassName";
import FeeInvoice from "../CollectFees/FeeInvoice";
import { GetGeneralInfoData } from "../../../actions/GeneralSettings/GeneralInfo/GeneralInfoAction";
import {
  GetDueAlerts,
  GetDueReports,
  SendDueAlert,
} from "../../../actions/Fees/DueReports/DueReportsAction";
import { dateConverterBsToAd } from "../../utils/dateConverter";
import { SendButton } from "../../Reusable/Buttons/SearchButton";
import DueAlertDialog from "./DueAlertDialog";
import DueAlertDialogSingle from "./DueAlertDialogSingle";
import AddToHomeScreenIcon from "@material-ui/icons/AddToHomeScreen";

// ---------------- <END> module import ------------------//

// ---------------- <START> Head Cells ------------------//
const headCells: HeadCellsI[] = [
  { id: "student_name", label: "Student Name" },
  { id: "class", label: "Class" },
  { id: "action", label: "Action" },
];
// ---------------- <START> Head Cells ------------------//

// ---------------- <START> Department Table Component ------------------//
const DueAlertTable = (props: any) => {
  const classes = useTableStyles();
  const dispatch = useDispatch();
  const {
    grade,
    section,
    startDate,
    endDate,
    month,
    fee,
    selected,
    setSelected,
  } = props;
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);
  const [tableData, setTableData] = useState<DepartmentTypeI[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);
  const departmentState = useSelector((state: RootStore) => state.department);
  const dueReportState = useSelector((state: RootStore) => state.due_report);
  const [openModal, setOpenModal] = useState(false);
  const [openModalSingle, setOpenModalSingle] = useState(false);
  const [studentID, setStudentID] = useState<string>("");

  const generalInfoData = useSelector(
    (state: RootStore) => state.general_info?.general_info
  );
  const authData = useSelector((state: RootStore) => state.auth);

  useEffect(() => {
    if (itemId !== null) {
      dispatch(GetInvoiceDetail(itemId));
    }
  }, [itemId]);

  // useEffect(() => {
  //     if (authData.general_info != null) {
  //         dispatch(GetGeneralInfoData(authData.general_info.id));
  //     }
  // }, [authData]);

  useEffect(() => {
    if (dueReportState?.due_alert !== []) {
      setTableData(dueReportState?.due_alert?.invoices);
    }
  }, [dueReportState]);

  const handleViewModal = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleViewModalSingle = (value: string) => {
    setStudentID(value);
    setOpenModalSingle(true);
  };

  const handleModalCloseSingle = () => {
    setOpenModalSingle(false);
  };

  const onModalConfirm = (value: string, sms: boolean, noti: boolean) => {
    console.log(value, sms, noti);
    const finalValues = {
      students: selected,
      message: value,
      sms_alert: sms,
      push_notification: noti,
    };
    dispatch(
      SendDueAlert(
        fee?.id,
        grade?.grade || "",
        section?.id || "",
        Number(month?.key),
        handleModalClose,
        finalValues
      )
    );
  };

  const onModalConfirmSingle = (value: string, sms: boolean, noti: boolean) => {
    const finalValues = {
      students: [studentID],
      message: value,
      sms_alert: sms,
      push_notification: noti,
    };
    dispatch(
      SendDueAlert(
        fee?.id,
        grade?.grade || "",
        section?.id || "",
        Number(month?.key),
        handleModalCloseSingle,
        finalValues
      )
    );
  };

  const handlePaginationChange = (paginationIndex: number) => {
    dispatch(
      GetDueReports(
        paginationIndex,
        grade?.grade || "",
        section?.id || "",
        (startDate && dateConverterBsToAd(startDate)) || "",
        (endDate && dateConverterBsToAd(endDate)) || ""
      )
    );
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = tableData.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const handleCheckClick = (name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const getCustomTableRow = (item: any, index: number) => {
    const isItemSelected = isSelected(item.id);
    const cellStyle = cx(classes.cell, classes.cellSm);

    return (
      <StyledTableRow key={item.id} selected={isItemSelected}>
        <StyledTableCell align="left" className={cellStyle}>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Checkbox
                color="default"
                checked={isItemSelected}
                onClick={() => handleCheckClick(item.id)}
              />
            </Grid>
            <Grid item>
              {getFullName(
                item.student?.student?.student_user?.first_name,
                item.student?.student?.student_user?.last_name
              )}
            </Grid>
          </Grid>
        </StyledTableCell>
        <StyledTableCell align="center" className={cellStyle}>
          {getFullClassName(
            item.student?.grade?.name,
            item.student?.section?.name
          )}
        </StyledTableCell>
        <StyledTableCell align="center" className={cellStyle}>
          <AddToHomeScreenIcon onClick={() => handleViewModalSingle(item.id)} />
        </StyledTableCell>
      </StyledTableRow>
    );
  };
  console.log({ tableData });

  return (
    <div style={{ padding: "15px" }}>
      <Typography variant="h6">Due List</Typography>
      <SendButton
        onClick={() => handleViewModal()}
        title="Send Alert"
        style={{ marginTop: "5px" }}
        type="button"
        disabled={selected?.length > 0 ? false : true}
      />

      <TableContainer
        label="Due List"
        items={tableData}
        headCells={headCells}
        handleSelectAllClicked={handleSelectAllClick}
        rowCount={tableData?.length}
        numSelected={selected?.length}
        hasCheckbox
        getCustomTableRow={getCustomTableRow}
        disableSearch
        disablePrint
        disabledPagination
      />
      <DueAlertDialog
        open={openModal}
        onClose={handleModalClose}
        deleteConfirmed={onModalConfirm}
        fee={fee}
        month={month}
      />
      <DueAlertDialogSingle
        open={openModalSingle}
        onClose={handleModalCloseSingle}
        deleteConfirmed={onModalConfirmSingle}
        fee={fee}
        month={month}
      />
    </div>
  );
};
// ---------------- <START> Department Table Component ------------------//

export default DueAlertTable;
