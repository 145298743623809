import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_STUDENT_PROFILE_LOADING,
  GET_STUDENT_PROFILE_ERROR,
  GET_STUDENT_PROFILE_SUCCESS,
  GET_STUDENT_LEAVE_REQUEST_HISTORY_LOADING,
  GET_STUDENT_LEAVE_REQUEST_HISTORY_ERROR,
  GET_STUDENT_LEAVE_REQUEST_HISTORY_SUCCESS,
  SEND_USER_CREDENTIALS_LOADING,
  SEND_USER_CREDENTIALS_ERROR,
  SEND_USER_CREDENTIALS_SUCCESS,
  StudentProfileDispatchTypes,
} from "./StudentProfileActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import {
  ADMIN_API_URL,
  STUDENT_API_URL,
} from "../../../config/SERVER_API_URLS";

interface RequestDataI {
  user: string | null;
  number: string;
  password: string;
}

export const GetStudentProfile =
  (id: any) =>
  async (dispatch: Dispatch<StudentProfileDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_STUDENT_PROFILE_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/student_info/${id}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_STUDENT_PROFILE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_STUDENT_PROFILE_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Student Profile Loading Failed",
        },
      });
    }
  };

export const GetStudentLeaveHistory =
  (id: any) =>
  async (dispatch: Dispatch<StudentProfileDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_STUDENT_LEAVE_REQUEST_HISTORY_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/student_info/student_leave_history/?student=${id}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_STUDENT_LEAVE_REQUEST_HISTORY_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_STUDENT_LEAVE_REQUEST_HISTORY_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Student Profile Loading Failed",
        },
      });
    }
  };

// Send user credentials via SMS
export const SendUserCredentials =
  (data: RequestDataI) =>
  async (dispatch: Dispatch<StudentProfileDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: SEND_USER_CREDENTIALS_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/send-login-credentials/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: SEND_USER_CREDENTIALS_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "User credentials sent successfully.",
        },
      });
    } catch (error) {
      dispatch({
        type: SEND_USER_CREDENTIALS_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Cannot send user credentials",
        },
      });
    }
  };

// Get student Profile
export const GetStudentProfile2 =
  () =>
  async (dispatch: Dispatch<StudentProfileDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_STUDENT_PROFILE_LOADING,
      });

      const res = await axios.get(
        `${STUDENT_API_URL}/profile/`,
        HeaderConfig()
      );

      dispatch({
        type: GET_STUDENT_PROFILE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_STUDENT_PROFILE_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Student Profile Loading Failed",
        },
      });
    }
  };
