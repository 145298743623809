// ---------------- <START> module import start ------------------//
import React from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import { FileCopyOutlined, PrintOutlined } from '@material-ui/icons';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import SendIcon from '@material-ui/icons/Send';
import AccessPermission from '../../Student/StudentDetail/AccessPermission';
// ----------------<END> module import end ------------------//

// ----------------<START> interface start ------------------//
interface TableActionButtonsProps {
  exportToExcel: Function;
  tablePrint: Function;
  onImport?: any;
  onSendSms?: any;
}
// ----------------<END> interface end ------------------//

//-----------------<start> Styling <start>-----------------------//
const useLocalStyles = makeStyles({
  tableActionButtons: {
    borderRadius: '30px',
    display: 'flex',
    justifyContent: 'flex-end',
    overflow: 'hidden',
  },
  line: {
    borderRight: '1px solid #132E98',
  },
  iconButton: {
    cursor: 'pointer',
    color: '#132E98',
    padding: '5px 10px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontWeight: 500,
    '& .MuiSvgIcon-root': {
      fontSize: '20px',
    },
    '&:hover': {
      opacity: '0.7',
    },
  },
});
//-----------------<end> Styling <end>-----------------------//

//--------------------- <START> Table Action Button Component <START> --------------------//
const TableActionButtons = (props: TableActionButtonsProps) => {
  const classes = useLocalStyles();
  return (
    <div className={classes.tableActionButtons}>
      <AccessPermission>
        {props.onSendSms && (
          <div className={classes.iconButton} onClick={props.onSendSms}>
            <SendIcon />
            Send Credentials Via. Sms
          </div>
        )}
        {props.onImport && (
          <div className={classes.iconButton} onClick={props.onImport}>
            <GetAppIcon />
            Import Excel
          </div>
        )}
      </AccessPermission>

      <div
        className={classes.iconButton}
        onClick={(event) => props.exportToExcel()}
      >
        <PublishIcon />
        Export Excel
      </div>

      <div
        className={classes.iconButton}
        onClick={(event) => props.tablePrint()}
      >
        <PrintOutlined />
        Print
      </div>
    </div>
  );
};
//--------------------- <END> Table Action Button Component <END> --------------------//

export default TableActionButtons;
