import {
  Checkbox,
  InputLabel,
  Tab,
  Tabs,
  TextField,
  Grid,
  Button,
  Dialog,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { TabPanel } from "../../Student/StudentProfile/File/NewTabs";
import CollectFeesList from "./CollectFeesList";
import CollectFeeStatement from "./CollectFeeStatement";
import { makeStyles } from "@material-ui/core/styles";
import { RootStore } from "../../../store";
import { useSelector } from "react-redux";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { Autocomplete } from "@material-ui/lab";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { useStyles as useStyless } from "../../Styles/FormStyles";
import { forInRight } from "lodash";
import getMonthName from "../../utils/getMonthName";
import moment from "moment";
import FeeHeadModal from "./FeeHeadModal";

const useStyles = makeStyles((theme) => ({
  formControl: {
    padding: "2px 10px",
    minWidth: 300,
    maxWidth: 300,
    border: "1px solid #d8d7d7",
    borderRadius: "5px",
    background: "#d8d7d7",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const CollectFeeTabContent = (props: any) => {
  const { student, setStudent } = props;
  const [selected, setSelected] = useState<any[]>([]);
  const classess = useStyless();

  const [value, setValue] = useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const [feeHeadOptions, setFeeHeadOptions] = useState<any>(null);
  const [feeHeadRows, setFeeHeadRows] = useState<any>(null);

  const [statementTableData, setStatementTableData] = useState<any[]>([]);
  const [miscRows, setMiscRows] = useState<any[]>([]);
  const [pendingRows, setPendingRows] = useState<any[]>([]);
  const [openDialog, setOpenDialog] = useState(false);

  const feesHead = useSelector(
    (state: RootStore) => state.collect_fees?.collect_fees
  );

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleFeeHeadChange = (datum: any) => {
    let toConcat: any = [];
    if (feeHeadRows?.length > 0) {
      feeHeadRows?.map((elem: any) => {
        const tempIDs: any[] = feeHeadRows?.map((data: any) => data.invoice_id);
        const new_datum = datum.filter(
          (item: any) => !tempIDs.includes(item.invoice_id)
        );
        // const isThere = datum?.find((item: any) => item.value === elem.id);
        // console.log('isThere', isThere);
        toConcat = new_datum;
      });
    } else {
      toConcat = [datum[0]];
    }
    const concated = feeHeadRows ? feeHeadRows.concat(toConcat) : toConcat;
    setFeeHeadRows(concated);

    const ids: string[] = datum?.map((data: any) => data.invoice_id);

    const final = concated?.filter((item: any) =>
      ids.includes(item.invoice_id)
    );
    setFeeHeadRows(final);
  };

  useEffect(() => {
    const paidData: any[] = [];
    const dueData: any[] = [];

    if (feesHead?.generated_invoices !== null) {
      const data: any[] = [];
      for (let i = 0; i < feesHead?.generated_invoices.length; i++) {
        for (
          let j = 0;
          j < feesHead?.generated_invoices[i].invoices.length;
          j++
        ) {
          data.push({
            fee_type_id: feesHead?.generated_invoices[i].fee_type_id,
            fee_name:
              feesHead?.generated_invoices[i].invoices[j].month !== ""
                ? feesHead?.generated_invoices[i].fee_name +
                  " - " +
                  getMonthName(
                    feesHead?.generated_invoices[i].invoices[j].month
                  )
                : feesHead?.generated_invoices[i].fee_name,
            invoice_id: feesHead?.generated_invoices[i].invoices[j].id,
            fee_type_due_date:
              feesHead?.generated_invoices[i].invoices[j].fee_type__due_date,
            month: getMonthName(
              feesHead?.generated_invoices[i].invoices[j].month
            ),
            charges: feesHead?.generated_invoices[i].invoices[j].amount,
            discount_amount:
              feesHead?.generated_invoices[i].invoices[j].discount_amount,
            selected: false,
            amount: feesHead?.generated_invoices[i].invoices[j].amount,
            paid: feesHead?.generated_invoices[i].invoices[j].amount,
            actual_amount_to_pay:
              feesHead?.generated_invoices[i].invoices[j].actual_amount_to_pay,
            applied_scholarships:
              feesHead?.generated_invoices[i].applicable_scholarships,
          });
          dueData.push({
            date: moment(
              feesHead?.generated_invoices[i].invoices[j].created_on
            ).format("YYYY-MM-DD"),
            transaction_type:
              feesHead?.generated_invoices[i].invoices[j].invoice_id,
            fee_head:
              feesHead?.generated_invoices[i].invoices[j].month !== ""
                ? feesHead?.generated_invoices[i].fee_name +
                  " - " +
                  getMonthName(
                    feesHead?.generated_invoices[i].invoices[j].month
                  )
                : feesHead?.generated_invoices[i].fee_name,
            period:
              feesHead?.generated_invoices[i].invoices[j].month !== ""
                ? getMonthName(
                    feesHead?.generated_invoices[i].invoices[j].month
                  )
                : feesHead?.generated_invoices[i].invoices[j]
                    .fee_type__due_date,
            charges: feesHead?.generated_invoices[i].invoices[j].amount,
            discount_amount:
              feesHead?.generated_invoices[i].invoices[j].discount_amount,
            fine: "Rs. 0",
            balance: feesHead?.generated_invoices[i].invoices[j].amount,
            status: "Unpaid",
          });
        }
      }
      // setFeeHeadOptions(feesHead?.generated_invoices)
      setFeeHeadOptions(data);
    }

    if (feesHead?.miscellaneous_fee !== null) {
      setMiscRows(feesHead?.miscellaneous_fee);
    }

    if (feesHead?.dues !== null) {
      const data: any[] = [];
      for (let i = 0; i < feesHead?.dues.length; i++) {
        data.push({
          id: feesHead?.dues[i]?.id,
          due_name: feesHead?.dues[i]?.paid_invoice?.invoice?.fee_type?.name,
          period: getMonthName(feesHead?.dues[i]?.paid_invoice?.invoice?.month),
          charges:
            feesHead?.dues[i]?.due_amount +
            feesHead?.dues[i]?.paid_invoice?.paid_amount,
          receipt: feesHead?.dues[i]?.paid_invoice?.receipt,
          due_amount: feesHead?.dues[i]?.due_amount,
        });
        dueData.push({
          date: moment(feesHead?.dues[i]?.created_on).format("YYYY-MM-DD"),
          transaction_type: feesHead?.dues[i]?.paid_invoice?.receipt,
          fee_head:
            feesHead?.dues[i]?.paid_invoice?.month !== null
              ? feesHead?.dues[i]?.paid_invoice?.invoice?.fee_type?.name +
                " - " +
                feesHead?.dues[i]?.paid_invoice?.month +
                " (due)"
              : feesHead?.dues[i]?.paid_invoice?.invoice?.fee_type?.name +
                " (due)",
          period: getMonthName(feesHead?.dues[i]?.paid_invoice?.invoice?.month),
          // charges: Number(feesHead?.dues[i]?.paid_invoice?.paid_amount) + Number(feesHead?.dues[i]?.due_amount),
          charges: Number(feesHead?.dues[i]?.due_amount),
          fine: "Rs. 0",
          balance: Number(feesHead?.dues[i]?.due_amount),
          status: "Unpaid",
        });
      }
      setPendingRows(data);
    }

    if (feesHead?.paid_invoices !== null) {
      for (let i = 0; i < feesHead?.paid_invoices.length; i++) {
        paidData.push({
          date: moment(feesHead?.paid_invoices[i]?.created_on).format(
            "YYYY-MM-DD"
          ),
          transaction_type: feesHead?.paid_invoices[i]?.receipt__receipt_id,
          fee_head:
            feesHead?.paid_invoices[i].invoice__month &&
            feesHead?.paid_invoices[i].invoice__month !== ""
              ? feesHead?.paid_invoices[i].invoice__fee_type__name +
                " - " +
                getMonthName(feesHead?.paid_invoices[i].invoice__month)
              : feesHead?.paid_invoices[i].invoice__fee_type__name,
          period: feesHead?.paid_invoices[i].invoice__month
            ? getMonthName(feesHead?.paid_invoices[i].invoice__month)
            : null,
          charges:
            feesHead?.paid_invoices[i].fine_amount > 0
              ? feesHead?.paid_invoices[i].paid_amount -
                feesHead?.paid_invoices[i].fine_amount
              : feesHead?.paid_invoices[i].paid_amount,
          fine: "Rs. " + (feesHead?.paid_invoices[i].fine_amount || 0),
          balance: feesHead?.paid_invoices[i].paid_amount,
          status: "Paid",
        });
      }
      // setStatementTableData(paidData)
    }
    setStatementTableData(dueData.concat(paidData));
  }, [feesHead]);

  // useEffect(() => {
  //     setFeeHeadRows(selected)

  //     // const data: any = [];
  //     // selected?.map((elem: any, index: number) => {
  //     //     data.push({
  //     //         id: elem.fee_type_id,
  //     //         fee_head: elem.fee_name,
  //     //         period: 1,
  //     //         charges: 1,
  //     //         balance: 1
  //     //     })
  //     // })
  //     setstatementTableData(selected)
  // }, [selected])

  const handleSelectChange = (data: any) => {
    // console.log('event', event);
    // const { value } = event.target;
    // const data: any = [];
    // for (let i = 0, l = value?.length; i < l; i += 1) {
    //     if (value[i].selected) {
    //         data.push(value[i].data);
    //     }
    // }
    // setSelected(data);
    const temp: string[] = [];

    data?.map((element: any) => temp.push(element.invoice_id));

    const currentOptions: any = feeHeadOptions;
    currentOptions.map((element: any) => {
      if (temp.includes(element.invoice_id)) {
        element.selected = true;
      } else {
        element.selected = false;
      }
    });
    setFeeHeadOptions(currentOptions);

    const selectedVal = currentOptions.filter(
      (element: any) => element.selected === true
    );

    setSelected(selectedVal);
  };

  // const handleChangeMultiple = (event: any) => {
  //     const { options } = event.target;
  //     const value = [];
  //     for (let i = 0, l = options?.length; i < l; i += 1) {
  //         if (options[i].selected) {
  //             value.push(options[i].value);
  //         }
  //     }
  //     setFeeHeadOptions(value);
  // };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };


  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0px 15px",
        }}
      >
        <div>
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="disabled tabs example"
          >
            <Tab
              label="Collect Fees"
              style={{ background: value === 0 ? "#f2f2f2" : "" }}
            />
            <Tab
              label="Statement"
              style={{ background: value === 1 ? "#f2f2f2" : "" }}
            />
          </Tabs>
        </div>
        <div style={{ width: "300px" }}>
          {/* <Select
                            labelId="demo-mutiple-checkbox-label"
                            id="demo-mutiple-checkbox"
                            multiple
                            value={selected}
                            onChange={handleSelectChange}
                            input={<Input />}
                            renderValue={(selected: any) => selected.join(', ')}
                            MenuProps={MenuProps}
                            placeholder="Select Fee Head(s)"
                        >
                            {feeHeadOptions?.map((elem: any, index: number) => (
                                <MenuItem key={index} value={elem.fee_type_id}>
                                    <Checkbox checked={elem.selected} />
                                    <ListItemText primary={elem.fee_name} />
                                </MenuItem>
                            ))}
                        </Select> */}
          <Grid className={classess.formWrapper}>
            <Autocomplete
              value={selected}
              multiple
              options={(student !== null && feeHeadOptions) || []}
              limitTags={1}
              disableCloseOnSelect
              onChange={(event: React.ChangeEvent<{}>, value: any) => {
                handleSelectChange(value);
                handleFeeHeadChange(value);
              }}
              getOptionLabel={(option: any) => option?.fee_name}
              renderOption={(option: any) => (
                <>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={option?.selected}
                  />
                  {option?.fee_name}
                </>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Fee Heads"
                />
              )}
            />
            <span
              style={{
                marginLeft: "2px",
                fontSize: "11px",
                fontVariant: "traditional",
              }}
            >
              {selected?.length > 0
                ? selected?.length +
                  " fee head(s) are selected" +
                  " " +
                  "out of " +
                  feeHeadOptions.length
                : "No fee heads(s) are selected"}
            </span>
          </Grid>
          {/* <Button
                        style={{ marginLeft: '75px' }}
                        variant="outlined"
                        color="primary"
                        onClick={() => setOpenDialog(true)}
                    >
                        Click For Fee Head(s)
                    </Button> */}
        </div>
      </div>

      <TabPanel value={0} index={value}>
        <CollectFeesList
          miscRows={miscRows}
          setSelected={setSelected}
          selected={selected}
          feeHeadRows={feeHeadRows}
          setFeeHeadRows={setFeeHeadRows}
          student={student}
          feeHeadOptions={feeHeadOptions}
          setFeeHeadOptions={setFeeHeadOptions}
          pendingRows={pendingRows}
          setMiscRows={setMiscRows}
          setPendingRows={setPendingRows}
          setStudent={setStudent}
          feesHead={feesHead}
        />
      </TabPanel>
      <TabPanel value={1} index={value}>
        <CollectFeeStatement
          student={student}
          statementTableData={statementTableData}
          feesHead={feesHead}
        />
      </TabPanel>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <FeeHeadModal
          feeHeadOptions={feeHeadOptions}
          handleFeeHeadChange={handleFeeHeadChange}
          handleSelectChange={handleSelectChange}
        />
      </Dialog>
    </>
  );
};

export default CollectFeeTabContent;
