import axios from "axios";
import { Dispatch } from "redux";

import {
  DocumentDispatchTypes,
  GET_STAFF_DOCUMENT_LOADING,
  GET_STAFF_DOCUMENT_ERROR,
  GET_STAFF_DOCUMENT_SUCCESS,
  ADD_STAFF_DOCUMENT_LOADING,
  ADD_STAFF_DOCUMENT_SUCCESS,
  ADD_STAFF_DOCUMENT_ERROR,
  DELETE_STAFF_DOCUMENT_SUCCESS,
  DELETE_STAFF_DOCUMENT_ERROR,
  DELETE_STAFF_DOCUMENT_LOADING,
} from "./StaffDocumentActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

interface RequestDataI {
  id: string;
  staff: string;
  title: string;
  document: any;
  created_by: string;
}

export const GetStaffDocument = (staffID: string) => async (
  dispatch: Dispatch<DocumentDispatchTypes | SetSnackBarI>
) => {
  try {
    dispatch({
      type: GET_STAFF_DOCUMENT_LOADING,
    });

    const res = await axios.get(
      `${ADMIN_API_URL}/staff_document/?staff=${staffID}`,
      HeaderConfig()
    );

    dispatch({
      type: GET_STAFF_DOCUMENT_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_STAFF_DOCUMENT_ERROR,
    });

    // dispatch({
    //   type: SET_SNACKBAR,
    //   payload: {
    //     snackbarOpen: true,
    //     snackbarType: "error",
    //     snackbarMessage: "Document Loading Failed",
    //   },
    // });
  }
};

export const AddStaffDocument = (data: any) => async (
  dispatch: Dispatch<DocumentDispatchTypes | SetSnackBarI>
) => {
  try {
    dispatch({
      type: ADD_STAFF_DOCUMENT_LOADING,
    });

    const res = await axios.post(
      `${ADMIN_API_URL}/staff_document/`,
      data,
      HeaderConfig()
    );
    dispatch({
      type: ADD_STAFF_DOCUMENT_SUCCESS,
      payload: res.data,
    });

    dispatch({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarType: "success",
        snackbarMessage: "Document Added Successfully",
      },
    });
  } catch (error) {
    dispatch({
      type: ADD_STAFF_DOCUMENT_ERROR,
    });

    dispatch({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarType: "error",
        snackbarMessage: "Document Add Failed",
      },
    });
  }
};

export const DeleteStaffDocument = (id: string) => async (
  dispatch: Dispatch<DocumentDispatchTypes | SetSnackBarI>
) => {
  try {
    dispatch({
      type: DELETE_STAFF_DOCUMENT_LOADING,
    });

    const res = await axios.delete(
      `${ADMIN_API_URL}/staff_document/${id}/`,
      HeaderConfig()
    );

    dispatch({
      type: DELETE_STAFF_DOCUMENT_SUCCESS,
      payload: { id: id },
    });

    dispatch({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarType: "success",
        snackbarMessage: "Document Deleted Successfully",
      },
    });
  } catch (error) {
    dispatch({
      type: DELETE_STAFF_DOCUMENT_ERROR,
    });

    dispatch({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarType: "error",
        snackbarMessage: "Document Delete Failed",
      },
    });
  }
};
