import React from "react";
import { FinanceSidebar, MonthlyFeeReportList } from "../../components";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const MonthlyFeeReport = () => {
    const nav = <FinanceSidebar monthly_report />;
    return (
        <SubModuleLayout FeesMenuActive sideNav={nav}>
            <MonthlyFeeReportList />
        </SubModuleLayout>
    );
};

export default MonthlyFeeReport;
