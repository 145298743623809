import {
  GET_EXPENSES_TYPE_LOADING,
  GET_EXPENSES_TYPE_ERROR,
  GET_EXPENSES_TYPE_SUCCESS,
  ADD_EXPENSES_TYPE_LOADING,
  ADD_EXPENSES_TYPE_ERROR,
  ADD_EXPENSES_TYPE_SUCCESS,
  DELETE_EXPENSES_TYPE_LOADING,
  DELETE_EXPENSES_TYPE_ERROR,
  DELETE_EXPENSES_TYPE_SUCCESS,
  UPDATE_EXPENSES_TYPE_LOADING,
  UPDATE_EXPENSES_TYPE_ERROR,
  UPDATE_EXPENSES_TYPE_SUCCESS,
  GET_EXPENSES_DETAILS_LOADING,
  GET_EXPENSES_DETAILS_ERROR,
  GET_EXPENSES_DETAILS_SUCCESS,
  ADD_EXPENSES_DETAILS_LOADING,
  ADD_EXPENSES_DETAILS_ERROR,
  ADD_EXPENSES_DETAILS_SUCCESS,
  DELETE_EXPENSES_DETAILS_LOADING,
  DELETE_EXPENSES_DETAILS_ERROR,
  DELETE_EXPENSES_DETAILS_SUCCESS,
  UPDATE_EXPENSES_DETAILS_LOADING,
  UPDATE_EXPENSES_DETAILS_ERROR,
  UPDATE_EXPENSES_DETAILS_SUCCESS,
  ExpensesDispatchTypes,
  ExpensesTypeI,
  AddExpensesTypeI,
} from "../../actions/Fees/Expenses/ExpenseActionTypes";

interface InitialStateI {
  loading: boolean;
  expense_type: ExpensesTypeI[];
  add_expense: AddExpensesTypeI[];
  errors: any;
  recent: boolean;
  add_or_update: boolean;
}

const initialState: InitialStateI = {
  loading: false,
  expense_type: [],
  add_expense: [],
  errors: null,
  recent: false,
  add_or_update: false,
};

const ExpensesTypeReducer = (
  state: InitialStateI = initialState,
  action: ExpensesDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_EXPENSES_TYPE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_EXPENSES_TYPE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_EXPENSES_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        expense_type: action.payload,
      };

    case ADD_EXPENSES_TYPE_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case ADD_EXPENSES_TYPE_ERROR:
      return {
        ...state,
        loading: false,
        add_or_update: true,
      };

    case ADD_EXPENSES_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        expense_type: [...state.expense_type, action.payload],
        add_or_update: true,
      };

    case UPDATE_EXPENSES_TYPE_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case UPDATE_EXPENSES_TYPE_ERROR:
      return {
        ...state,
        loading: false,
        add_or_update: true,
      };

    case UPDATE_EXPENSES_TYPE_SUCCESS:
      const current_data: ExpensesTypeI[] = state.expense_type;
      const index: number = current_data.findIndex(
        (element) => element.id === action.payload.id
      );
      current_data[index] = action.payload;

      return {
        ...state,
        loading: false,
        expense_type: current_data,
        add_or_update: true,
      };

    case DELETE_EXPENSES_TYPE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_EXPENSES_TYPE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_EXPENSES_TYPE_SUCCESS:
      const new_data: ExpensesTypeI[] = state.expense_type;
      const item_index = new_data.findIndex(
        (element) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return { ...state, loading: false, expense_type: new_data };

    case GET_EXPENSES_DETAILS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_EXPENSES_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        errors: null,
      };

    case GET_EXPENSES_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        add_expense: action.payload,
        recent: false,
      };

    case ADD_EXPENSES_DETAILS_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case ADD_EXPENSES_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        recent: false,
        add_or_update: true,
      };

    case ADD_EXPENSES_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        add_expense: [...state.add_expense, action.payload],
        recent: true,
        add_or_update: true,
      };

    case UPDATE_EXPENSES_DETAILS_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case UPDATE_EXPENSES_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        recent: false,
        add_or_update: true,
      };

    case UPDATE_EXPENSES_DETAILS_SUCCESS:
      const current_add_data: AddExpensesTypeI[] = state.add_expense;
      const i: number = current_add_data.findIndex(
        (element) => element.id === action.payload.id
      );
      current_add_data[i] = action.payload;

      return {
        ...state,
        loading: false,
        add_expense: current_add_data,
        recent: true,
        add_or_update: true,
      };

    case DELETE_EXPENSES_DETAILS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_EXPENSES_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_EXPENSES_DETAILS_SUCCESS:
      const n_data: AddExpensesTypeI[] = state.add_expense;
      const i_index = n_data.findIndex(
        (element) => element.id === action.payload.id
      );
      n_data.splice(i_index, 1);

      return { ...state, loading: false, add_expense: n_data };

    default:
      return state;
  }
};

export default ExpensesTypeReducer;
