import React from 'react'
import styled from "styled-components";

const Card = styled.div`
    background-color: #eceef7;
    width: 31%;
    padding: 10px 15px;
    color: #fff;
`

const Value = styled.p`
    font-size: 4em;
    margin: 0;
    padding: 0;
    color: #132e98;
`

const SmallText = styled.p`
    font-size: 1.5em;
    margin: 0;
    color: #333;
`

const SmallestText = styled.p`
    font-size: 1em;
    margin: 0;
    color: #8f8f8f;
`

const ActivityCard = (props: any) => {
    return (
        <Card>
            <Value>{props.data.value}</Value>
            <SmallText>{props.data.subValue}</SmallText>
            <SmallestText>{props.data.time}</SmallestText>
        </Card>
    )
}

export default ActivityCard