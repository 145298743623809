function multipleBrandsConfig() {
  const origin = new URL(window.location.origin);
  const host_name = origin.hostname;
  // console.log(host_name);
  const without_prefix = host_name.replace("www.", "").replace('.com', "")
  const final = without_prefix.replace(/^[^.]+\./g, "");
  // console.log(final)
  // console.log(without_prefix)
  return final;
}

export default multipleBrandsConfig;