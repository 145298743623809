import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { importStaffData, importUserValidation } from "./userImportApi";

interface StaffImportI {
  id: number;
  name: string;
  phone_number: string;
  validity: number;
  validationMsg: string;
}

interface InitialStateI {
  staffs: StaffImportI[];
  loading: boolean;
  valid: boolean;
  imported: boolean;
}

const initialState: InitialStateI = {
  staffs: [],
  loading: false,
  valid: false,
  imported: false,
};

const staffImportSlice = createSlice({
  name: "staffImport",
  initialState,
  reducers: {
    addStaff: (state, { payload }: PayloadAction<StaffImportI[]>) => {
      state.valid = false;
      state.staffs = [...payload];
    },
    updateStaffRow: (
      state,
      { payload }: PayloadAction<{ id: number; field: string; value: string }>
    ) => {
      const mIndex = state.staffs.findIndex((item) => item.id === payload.id);
      if (mIndex !== -1) {
        switch (payload.field) {
          case "name":
            state.staffs[mIndex] = {
              ...state.staffs[mIndex],
              name: payload.value,
            };
            break;

          case "phone_number":
            state.staffs[mIndex] = {
              ...state.staffs[mIndex],
              phone_number: payload.value,
            };
            break;

          default:
            break;
        }
      }
    },
    removeStaffRow: (state, { payload }: PayloadAction<number>) => {
      state.staffs = state.staffs.filter((item) => item.id !== payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(importUserValidation.pending, (state) => {
      state.loading = true;
      state.imported = false;
    });
    builder.addCase(
      importUserValidation.fulfilled,
      (
        state,
        {
          payload,
        }: PayloadAction<
          { number: string; message: string; can_proceed: true }[]
        >
      ) => {
        state.loading = false;
        state.valid = true;
        state.staffs.forEach((item) => {
          const found = payload.find(
            (elem) => elem.number === item.phone_number
          );
          if (found) {
            item.validationMsg = found.message;
            if (found.can_proceed) {
              item.validity = 2;
            } else {
              state.valid = false;
              item.validity = 3;
            }
          } else {
            item.validity = 4;
            item.validationMsg = "Valid";
          }
        });
      }
    );
    builder.addCase(importUserValidation.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(importStaffData.pending, (state) => {
      state.loading = true;
      state.imported = false;
    });
    builder.addCase(importStaffData.fulfilled, (state) => {
      state.loading = false;
      state.imported = true;
    });
    builder.addCase(importStaffData.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  addStaff: addStaffAction,
  updateStaffRow: updateStaffRowAction,
  removeStaffRow: removeStaffRowAction,
} = staffImportSlice.actions;

export default staffImportSlice.reducer;
