import {
    GET_FEE_STATEMENT_ERROR,
    GET_FEE_STATEMENT_LOADING,
    GET_FEE_STATEMENT_SUCCESS,
    FeestatementDispatchTypes,
} from "../../actions/Fees/FeeStatement/FeestatementActionTypes";

interface InitialStateI {
    loading: boolean;
    fee_statement: any;
    invoice_detail: any
}

const initialState: InitialStateI = {
    loading: false,
    fee_statement: [],
    invoice_detail: null
};

const FeestatementReducer = (
    state: InitialStateI = initialState,
    action: FeestatementDispatchTypes
): InitialStateI => {
    switch (action.type) {
        case GET_FEE_STATEMENT_LOADING:
            return {
                ...state,
                loading: true,
            };

        case GET_FEE_STATEMENT_ERROR:
            return {
                ...state,
                loading: false,
            };

        case GET_FEE_STATEMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                fee_statement: action.payload,
            };


        default:
            return state;
    }
};

export default FeestatementReducer;