import CardImg1 from "../../../../assets/images/admitcard1.png";
import CardImg2 from "../../../../assets/images/admitcard2.png";

export const landscapeData = [
  {
    id: 3,
    code: "ABC-003",
    thumbnail: CardImg1,
    type: "Student",
    mode: "landscape",
    text_color: [
      {
        key: "header",
        value: "#ffffff",
        name: "Header",
      },
      {
        key: "footer",
        value: "#ffffff",
        name: "Footer",
      },
      {
        key: "body",
        value: "#444444",
        name: "Body",
      },
    ],
    back_ground_color: [
      {
        key: "header",
        value: "#3780ce",
        name: "Header",
      },
      {
        key: "footer",
        value: "#3780ce",
        name: "Footer",
      },
      {
        key: "body",
        value: "#ffffff",
        name: "Body",
      },
    ],
  },
  {
    id: 4,
    code: "ABC-004",
    thumbnail: CardImg2,
    type: "Staff",
    mode: "landscape",
    text_color: [
      {
        key: "header",
        value: "#3780ce",
        name: "Header",
      },
      {
        key: "footer",
        value: "#ffffff",
        name: "Footer",
      },
      {
        key: "body",
        value: "#444444",
        name: "Body",
      },
      {
        key: "teacher-text",
        value: "#ffff",
        name: "Designation",
      },
    ],
    back_ground_color: [
      {
        key: "footer",
        value: "#3780ce",
        name: "Footer",
      },
      {
        key: "body",
        value: "#ffff",
        name: "Body",
      },
      {
        key: "teacher-text",
        value: "#444444",
        name: "Designation",
      },
    ],
  },
];

export function getSingleCard(id: string) {
  return landscapeData.find((item) => item.id == parseInt(id));
}
