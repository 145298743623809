import { Button, Divider, Grid } from "@material-ui/core";
import { Cancel, Print, Save } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import XLSX from "xlsx";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import { handleSubjectMarksChange } from "../../../rtk/features/exam/addMarks/addMarksSlice";
import {
  getAllSubjectsMarksEntry,
  postAllSubjectsMarksEntry,
} from "../../../rtk/features/exam/addMarks/addMarksApi";

const Wrapper = styled.div`
  background-color: #fff;
  padding: 16px 11px 11px 16px;
  margin: 10px;
`;

const ExamDetail = styled.span`
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.8px;
  line-height: 1.6;

  & span {
    font-weight: 500;
  }
`;

const TableContainer = styled.div`
  overflow: auto;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%);
  /* border-radius: 4px; */
  padding: 0 5px 5px 0;

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 8px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #132e98;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableRow = styled.tr``;

const TableHead = styled.th<{ main?: boolean }>`
  border: 1px solid black;
  padding: 0 8px;
  font-weight: 500;
  white-space: nowrap;
  background-color: #ececec;

  min-width: ${(props) => props.main && "200px"};
`;

const TableData = styled.td<{
  main?: boolean;
  onPrint?: boolean;
  textAlign: "left" | "center" | "right";
}>`
  min-width: 50px;
  height: 35px;
  border: 1px solid black;
  font-weight: ${(props) => (props.main ? 500 : 400)};
  background-color: ${(props) => (props.main ? "#ececec" : "#fff")};
  text-align: ${(props) => props.textAlign};

  display: ${(props) => props.onPrint && "none"};
`;

const CellInput = styled.input`
  width: 100%;
  height: 100%;
  border-radius: none;
  text-align: center;
  border: none;

  &:focus {
    outline: 1px solid #000;
  }
  &:disabled {
    background-color: #dadada;
  }
`;

const CellSelect = styled.select`
  min-width: 75px;
  width: 100%;
  height: 100%;
  border-radius: none;
  appearance: auto;
  cursor: pointer;

  &:focus {
    outline: 1px solid #000;
  }
  &:disabled {
    background-color: #dadada;
  }
`;

const ActionPanel = styled.div`
  display: flex;
  margin-top: 10px;
  gap: 10px;
`;

const SearchButton = styled(Button)`
  margin: 5px;
  min-width: 120px;
`;

const AddMarksAllTable = () => {
  const printRef = useRef<HTMLTableElement | null>(null);
  const dispatch = useDispatch();

  const [editMode, setEditMode] = useState<boolean>(false);
  const { subjects, studentAllSubjectMarks, searchParams, actionPerformed } =
    useSelector((state: RootStore) => state.addMarks);
  const { gradings } = useSelector((state: RootStore) => state.marksGrading);

  useEffect(() => {
    if (actionPerformed) {
      setEditMode(false);

      if (searchParams?.exam && searchParams.grade) {
        dispatch(
          getAllSubjectsMarksEntry({
            examId: searchParams.exam.id,
            gradeId: searchParams.grade.id,
            sectionId: searchParams.section?.id || "",
          })
        );
      }
    }
  }, [actionPerformed]);

  const handleTableEdit = () => {
    if (!editMode) {
      setEditMode(true);
    } else {
      const postData: {
        student_id: string;
        attendance: number;
        student_marks:
          | {
              exam_subject_info: string;
              theory_obtained_marks?: number;
              practical_obtained_marks?: number | null;
              theory_obtained_grade?: string;
              practical_obtained_grade?: string;
            }[];
      }[] = [];

      studentAllSubjectMarks
        .filter((el) => el.changed)
        .forEach((el) => {
          const student = {
            student_id: el.student_id,
            attendance: Number(el.attendance) || 0,
            student_marks: el.subjects.map((item) => {
              if (item.result_type === 1) {
                return {
                  exam_subject_info: item.exam_subject_id,
                  theory_obtained_marks:
                    Number(item.marks_obtained_theory) || 0,
                  practical_obtained_marks:
                    Number(item.marks_obtained_practical) || null,
                };
              } else {
                return {
                  exam_subject_info: item.exam_subject_id,
                  theory_obtained_grade: item.grade_obtained_theory,
                  practical_obtained_grade: item.grade_obtained_practical,
                };
              }
            }),
          };

          postData.push(student);
        });

      if (searchParams?.exam && searchParams.grade) {
        console.log("post: ", postData);
        dispatch(
          postAllSubjectsMarksEntry({
            examId: searchParams.exam.id,
            grade: searchParams.grade.id,
            post_data: postData,
          })
        );
      }
    }
  };

  const returnMarks = (data: { subjectId: string; studentId: string }) => {
    const subject = subjects.find((el) => el.id === data.subjectId);

    const targetStudent = studentAllSubjectMarks.find(
      (el) => el.student_id === data.studentId
    );

    const studentMarks = targetStudent?.subjects;

    if (targetStudent && subject && studentMarks) {
      const subjectMarksObtained = studentMarks.find(
        (el) => el.exam_subject_id === subject.id
      );

      if (subjectMarksObtained) {
        if (subject.has_practical && subject.result_type === 1) {
          return (
            <>
              <TableData textAlign="center" colSpan={1}>
                {editMode ? (
                  <CellInput
                    value={subjectMarksObtained.marks_obtained_theory}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      dispatch(
                        handleSubjectMarksChange({
                          studentId: data.studentId,
                          subjectId: data.subjectId,
                          field: "marksTH",
                          value: event.target.value,
                        })
                      )
                    }
                    disabled={
                      subject.subject_type === "Opt"
                        ? !targetStudent.optSubjects.includes(
                            subject.subjectInfoId
                          )
                        : false
                    }
                  />
                ) : (
                  <span>
                    {subjectMarksObtained.marks_obtained_theory || "-"}
                  </span>
                )}
              </TableData>
              <TableData textAlign="center" colSpan={1}>
                {editMode ? (
                  <CellInput
                    value={subjectMarksObtained.marks_obtained_practical}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      dispatch(
                        handleSubjectMarksChange({
                          studentId: data.studentId,
                          subjectId: data.subjectId,
                          field: "marksPR",
                          value: event.target.value,
                        })
                      )
                    }
                    disabled={
                      subject.subject_type === "Opt"
                        ? !targetStudent.optSubjects.includes(
                            subject.subjectInfoId
                          )
                        : false
                    }
                  />
                ) : (
                  <span>
                    {subjectMarksObtained.marks_obtained_practical || "-"}
                  </span>
                )}
              </TableData>
              <TableData textAlign="center" colSpan={1}>
                {Number(
                  subjectMarksObtained.marks_obtained_theory
                    ? subjectMarksObtained.marks_obtained_theory
                    : 0
                ) +
                  Number(
                    subjectMarksObtained.marks_obtained_practical
                      ? subjectMarksObtained.marks_obtained_practical
                      : 0
                  )}
              </TableData>
            </>
          );
        } else if (subject.has_practical && subject.result_type === 0) {
          return (
            <>
              <TableData textAlign="center" colSpan={1}>
                {editMode ? (
                  <CellSelect
                    value={
                      subjectMarksObtained.grade_obtained_theory ||
                      gradings.find((el) => el.grade_name === "NG")?.id ||
                      ""
                    }
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                      dispatch(
                        handleSubjectMarksChange({
                          studentId: data.studentId,
                          subjectId: data.subjectId,
                          field: "gradeTH",
                          value: event.target.value,
                        })
                      )
                    }
                    disabled={
                      subject.subject_type === "Opt"
                        ? !targetStudent.optSubjects.includes(
                            subject.subjectInfoId
                          )
                        : false
                    }
                  >
                    {gradings.map((el) => (
                      <option key={el.id} value={el.id}>
                        {el.grade_name}
                      </option>
                    ))}
                  </CellSelect>
                ) : (
                  <span>
                    {gradings.find(
                      (el) =>
                        el.id === subjectMarksObtained.grade_obtained_theory
                    )?.grade_name || "NG"}
                  </span>
                )}
              </TableData>
              <TableData textAlign="center" colSpan={1}>
                {editMode ? (
                  <CellSelect
                    value={
                      subjectMarksObtained.grade_obtained_practical ||
                      gradings.find((el) => el.grade_name === "NG")?.id ||
                      ""
                    }
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                      dispatch(
                        handleSubjectMarksChange({
                          studentId: data.studentId,
                          subjectId: data.subjectId,
                          field: "gradePR",
                          value: event.target.value,
                        })
                      )
                    }
                    disabled={
                      subject.subject_type === "Opt"
                        ? !targetStudent.optSubjects.includes(
                            subject.subjectInfoId
                          )
                        : false
                    }
                  >
                    {gradings.map((el) => (
                      <option key={el.id} value={el.id}>
                        {el.grade_name}
                      </option>
                    ))}
                  </CellSelect>
                ) : (
                  <span>
                    {gradings.find(
                      (el) =>
                        el.id === subjectMarksObtained.grade_obtained_practical
                    )?.grade_name || "NG"}
                  </span>
                )}
              </TableData>
            </>
          );
        } else if (!subject.has_practical && subject.result_type === 1) {
          return (
            <>
              <TableData textAlign="center" colSpan={1}>
                {editMode ? (
                  <CellInput
                    value={subjectMarksObtained.marks_obtained_theory}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      dispatch(
                        handleSubjectMarksChange({
                          studentId: data.studentId,
                          subjectId: data.subjectId,
                          field: "marksTH",
                          value: event.target.value,
                        })
                      )
                    }
                    disabled={
                      subject.subject_type === "Opt"
                        ? !targetStudent.optSubjects.includes(
                            subject.subjectInfoId
                          )
                        : false
                    }
                  />
                ) : (
                  <span>
                    {subjectMarksObtained.marks_obtained_theory || "-"}
                  </span>
                )}
              </TableData>
            </>
          );
        } else {
          return (
            <>
              <TableData textAlign="center" colSpan={1}>
                {editMode ? (
                  <CellSelect
                    value={
                      subjectMarksObtained.grade_obtained_theory ||
                      gradings.find((el) => el.grade_name === "NG")?.id ||
                      ""
                    }
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                      dispatch(
                        handleSubjectMarksChange({
                          studentId: data.studentId,
                          subjectId: data.subjectId,
                          field: "gradeTH",
                          value: event.target.value,
                        })
                      )
                    }
                    disabled={
                      subject.subject_type === "Opt"
                        ? !targetStudent.optSubjects.includes(
                            subject.subjectInfoId
                          )
                        : false
                    }
                  >
                    {gradings.map((el) => (
                      <option key={el.id} value={el.id}>
                        {el.grade_name}
                      </option>
                    ))}
                  </CellSelect>
                ) : (
                  <span>
                    {gradings.find(
                      (el) =>
                        el.id === subjectMarksObtained.grade_obtained_theory
                    )?.grade_name || "NG"}
                  </span>
                )}
              </TableData>
            </>
          );
        }
      } else {
        if (subject.has_practical && subject.result_type === 1) {
          return (
            <>
              <TableData textAlign="center" colSpan={1}>
                -
              </TableData>
              <TableData textAlign="center" colSpan={1}>
                -
              </TableData>
              <TableData textAlign="center" colSpan={1}>
                -
              </TableData>
            </>
          );
        } else if (subject.has_practical && subject.result_type === 0) {
          return (
            <>
              <TableData textAlign="center" colSpan={1}>
                -
              </TableData>
              <TableData textAlign="center" colSpan={1}>
                -
              </TableData>
            </>
          );
        } else {
          return (
            <>
              <TableData textAlign="center" colSpan={1}>
                -
              </TableData>
            </>
          );
        }
      }
    }
    return (
      <>
        <TableData textAlign="center" colSpan={1}>
          -
        </TableData>
      </>
    );
  };

  return (
    <Wrapper>
      <Grid container>
        <Grid item xs={4}>
          <ExamDetail>
            Exam: <span>{searchParams?.exam?.name || "-"}</span>
          </ExamDetail>
        </Grid>
        <Grid item xs={8}>
          <ExamDetail></ExamDetail>
        </Grid>
        <Grid item xs={4}>
          <ExamDetail>
            Grade: <span>{searchParams?.grade?.name || "-"}</span>
          </ExamDetail>
        </Grid>
        <Grid item xs={4}>
          <ExamDetail>
            Section: <span>{searchParams?.section?.name || "-"}</span>
          </ExamDetail>
        </Grid>
      </Grid>

      <Divider style={{ margin: "10px 0 15px 0" }} />
      <TableContainer>
        <Table ref={printRef} id="export-table">
          <TableRow>
            <TableHead>Name of Student</TableHead>
            <TableHead>Roll No</TableHead>
            <TableHead>Attd</TableHead>

            {subjects.length ? (
              subjects.map((el, index) => {
                if (el.has_practical && el.result_type === 1) {
                  return (
                    <TableHead key={index + 1} colSpan={3}>
                      {el.subject}
                    </TableHead>
                  );
                } else if (el.has_practical && el.result_type === 0) {
                  return (
                    <TableHead key={index + 1} colSpan={2}>
                      {el.subject}
                    </TableHead>
                  );
                } else {
                  return <TableHead key={index + 1}>{el.subject}</TableHead>;
                }
              })
            ) : (
              <TableHead>Subjects</TableHead>
            )}
          </TableRow>

          <TableRow>
            <TableHead></TableHead>
            <TableHead></TableHead>
            <TableHead></TableHead>

            {subjects.length ? (
              subjects.map((el) => {
                if (el.has_practical && el.result_type === 1) {
                  return (
                    <>
                      <TableHead>TH</TableHead>
                      <TableHead>PR</TableHead>
                      <TableHead>Total</TableHead>
                    </>
                  );
                } else if (el.has_practical && el.result_type === 0) {
                  return (
                    <>
                      <TableHead>TH</TableHead>
                      <TableHead>PR</TableHead>
                    </>
                  );
                } else {
                  return (
                    <>
                      <TableHead>TH</TableHead>
                    </>
                  );
                }
              })
            ) : (
              <TableHead>TH</TableHead>
            )}
          </TableRow>

          <TableRow>
            <TableHead>Full Marks</TableHead>
            <TableHead></TableHead>
            <TableHead></TableHead>

            {subjects.length ? (
              subjects.map((el) => {
                if (el.has_practical && el.result_type === 1) {
                  return (
                    <>
                      <TableHead colSpan={1}>{el.full_marks_theory}</TableHead>
                      <TableHead colSpan={1}>
                        {el.full_marks_practical || "-"}
                      </TableHead>
                      <TableHead colSpan={1}>
                        {el.full_marks_theory +
                          (el.full_marks_practical
                            ? el.full_marks_practical
                            : 0)}
                      </TableHead>
                    </>
                  );
                } else if (el.has_practical && el.result_type === 0) {
                  return (
                    <>
                      <TableHead>-</TableHead>
                      <TableHead>-</TableHead>
                    </>
                  );
                } else if (!el.has_practical && el.result_type === 1) {
                  return (
                    <>
                      <TableHead>{el.full_marks_theory}</TableHead>
                    </>
                  );
                } else {
                  return (
                    <>
                      <TableHead>-</TableHead>
                    </>
                  );
                }
              })
            ) : (
              <TableHead>-</TableHead>
            )}
          </TableRow>

          <TableRow>
            <TableHead>Pass Marks</TableHead>
            <TableHead></TableHead>
            <TableHead></TableHead>

            {subjects.length ? (
              subjects.map((el) => {
                if (el.has_practical && el.result_type === 1) {
                  return (
                    <>
                      <TableHead colSpan={1}>{el.pass_marks_theory}</TableHead>
                      <TableHead colSpan={1}>
                        {el.pass_marks_practical || "-"}
                      </TableHead>
                      <TableHead colSpan={1}>
                        {el.pass_marks_theory +
                          (el.pass_marks_practical
                            ? el.pass_marks_practical
                            : 0)}
                      </TableHead>
                    </>
                  );
                } else if (el.has_practical && el.result_type === 0) {
                  return (
                    <>
                      <TableHead>-</TableHead>
                      <TableHead>-</TableHead>
                    </>
                  );
                } else if (!el.has_practical && el.result_type === 1) {
                  return (
                    <>
                      <TableHead>{el.pass_marks_theory}</TableHead>
                    </>
                  );
                } else {
                  return (
                    <>
                      <TableHead>-</TableHead>
                    </>
                  );
                }
              })
            ) : (
              <TableHead>-</TableHead>
            )}
          </TableRow>

          {subjects.length
            ? studentAllSubjectMarks.map((el) => {
                return (
                  <TableRow key={el.student_id}>
                    <TableHead main>{el.student_full_name}</TableHead>
                    <TableHead>{el.roll_number || "-"}</TableHead>
                    <TableData textAlign="center">
                      {editMode ? (
                        <CellInput
                          value={el.attendance}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            dispatch(
                              handleSubjectMarksChange({
                                studentId: el.student_id,
                                subjectId: "",
                                field: "attd",
                                value: event.target.value,
                              })
                            )
                          }
                        />
                      ) : (
                        <span>{el.attendance}</span>
                      )}
                    </TableData>
                    {subjects.map((subject) => {
                      return returnMarks({
                        studentId: el.student_id,
                        subjectId: subject.id,
                      });
                    })}
                  </TableRow>
                );
              })
            : null}
        </Table>
      </TableContainer>
      <ActionPanel>
        {editMode ? (
          <SearchButton
            color="primary"
            variant="contained"
            startIcon={<Cancel />}
            onClick={() => setEditMode(false)}
          >
            Cancel
          </SearchButton>
        ) : null}
        <SearchButton
          color="primary"
          variant="contained"
          startIcon={<Save />}
          onClick={handleTableEdit}
          disabled={!studentAllSubjectMarks[0]?.subjects.length}
        >
          {editMode ? "Save" : "Edit"}
        </SearchButton>
      </ActionPanel>
    </Wrapper>
  );
};

export default AddMarksAllTable;
