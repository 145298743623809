import React from "react";
import { Grid } from "@material-ui/core";
import { Header, StudentAdmissionForm, StudentTable } from "../../components";
import StudentSidebar from "../../components/Student/StudentSidebar/StudentSidebar";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";
import { breadcrumb } from "./Student.Breadcrumbs";
import { PageRoleAccess } from "../../components/utils/rolesConfig";
import { Redirect } from "react-router";
import { useSelector } from "react-redux";
import { RootStore } from "../../store";

const StudentListPage = () => {
  const nav = <StudentSidebar studentAdmission />;

  const authState = useSelector((state: RootStore) => state.auth);

  if (!PageRoleAccess(authState?.role, "STUDENT", "student_admission"))
    return <Redirect to="/" />;

  return (
    <SubModuleLayout module={breadcrumb} sideNav={nav}>
      <StudentAdmissionForm />
    </SubModuleLayout>
  );
};

export default StudentListPage;
