import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { Clear, Done } from '@material-ui/icons';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { PRIMARY_DATE_FORMAT } from '../../../common/constant';
import StaffCard, {
  StaffCardTypeI,
} from '../../Reusable/Dialogs/StaffCard.Modal';
import checkImageUrl from '../../utils/checkImageUrl';
import CustomizedTextField from '../../Reusable/Inputs/TextField';
import { useStyles } from '../../Styles/FormStyles';
import { CUR_NEPALI_DATE } from '../../utils/nepaliDateUtils';
import { useForm } from 'react-hook-form';
import {
  GetLeaves,
  UpdateLeave,
  UpdateLeaveStatus,
} from '../../../actions/HumanResource/Leave/LeaveAction';
import { useDispatch } from 'react-redux';
import GetStatus from '../../../common/GetStatus';
import { Alert } from '@material-ui/lab';
import { LeaveButton } from '../../Student/StudentLeave/LeaveRequestView';

const LeaveRequestView = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { register, handleSubmit, watch } = useForm();

  const [remarksActive, setRemarksActive] = useState(false);
  const [adminRemarks, setAdminRemarks] = useState('');
  const [adminResponse, setAdminResponse] = useState<string>('');
  const [isLeaveLatest, setIsLeaveLatest] = useState(true);
  const [staff, setStaff] = useState<any>(null);

  const { onActionConfirmed: handleSubmitProps, items } = props;

  console.log('remarks', items);

  // const handleClick = (status: string) => {
  //   props.onActionConfirmed(true, status);
  // };

  const handleClick = (status: string) => {
    setRemarksActive(true);
    setAdminResponse(status);
  };

  useEffect(() => {
    const item: any = {
      name: props.items.name,
      avatar: '',
      id: props.items.id,
      designation: props.items.designation || <em>N/A</em>,
    };

    setStaff(item);
  }, []);

  useEffect(() => {
    const difference = moment(props.items.applied_date).diff(
      CUR_NEPALI_DATE,
      'days'
    );
    setIsLeaveLatest(difference >= 0);
  }, []);

  const handleClose = () => {
    setRemarksActive(false);
    setAdminRemarks('');
    setAdminResponse('');
  };

  const handleModalClose = () => {
    props.onClose();
  };

  const handleContinue = () => {
    handleSubmitProps(true, adminResponse, adminRemarks);
  };

  const onSubmit = (data: any) => {
    const tempData = {
      status: data.accepted ? 'A' : 'D',
      remarks: data.remarks,
    };
    dispatch(UpdateLeaveStatus(items.id, tempData));
    handleModalClose();
  };

  const hasAccepeted = watch('accepted');

  return (
    <React.Fragment>
      {staff && <StaffCard staff={staff} />}
      <Divider style={{ marginBottom: '20px' }} />
      <Grid container style={{ marginBottom: '10px' }}>
        <Grid item xs={4}>
          Apply Date:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {moment(props.items.applied_date).format(PRIMARY_DATE_FORMAT)}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: '10px' }}>
        <Grid item xs={4}>
          Leave Date:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {moment(props.items.leave_date).format(PRIMARY_DATE_FORMAT)} -{' '}
            {moment(props.items.days).format(PRIMARY_DATE_FORMAT)} (
            <strong>
              {moment(props.items.days).diff(props.items.leave_date, 'days')}{' '}
              days
            </strong>
            )
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: '10px' }}>
        <Grid item xs={4}>
          Status:
        </Grid>

        <Grid item xs={8}>
          {/* {props.items.status === 'P' ? 'Pending' : 'Approved'} */}
          <GetStatus status={props.items.status} />
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: '10px' }}>
        <Grid item xs={4}>
          Reason:
        </Grid>

        <Grid item xs={8}>
          {props.items.description || 'No Description'}
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: '10px' }}>
        <Grid item xs={4}>
          Remarks:
        </Grid>

        <Grid item xs={8}>
          {props.items.remarks || <em>No remarks yet</em>}
        </Grid>
      </Grid>

      {props.items.status === 'P' && isLeaveLatest === false && (
        <Alert icon={false} severity="error">
          You can not respond to previous leave yet now.
        </Alert>
      )}

      {props.items.status === 'P' && isLeaveLatest && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container style={{ marginBottom: '10px' }}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  name="accepted"
                  inputRef={register({ required: false })}
                />
              }
              label="I have accepted this leave request."
            />
          </Grid>

          <Grid container style={{ marginBottom: '10px' }}>
            <TextField
              multiline
              variant="outlined"
              fullWidth
              rows={2}
              placeholder="Remarks"
              name="remarks"
              inputRef={register({ required: true })}
            />
          </Grid>

          <Grid
            container
            style={{ marginBottom: 8, marginTop: 12, gap: 16 }}
            justifyContent="flex-end"
          >
            {hasAccepeted === true && (
              <LeaveButton variant="contained" color="primary" type="submit">
                Accept Leave Request
              </LeaveButton>
            )}

            {hasAccepeted === false && (
              <LeaveButton variant="contained" color="secondary" type="submit">
                Decline Leave Request
              </LeaveButton>
            )}

            <LeaveButton
              variant="contained"
              color="default"
              onClick={handleModalClose}
            >
              Cancel
            </LeaveButton>
          </Grid>
        </form>
      )}

      {/* {remarksActive && (
        <Box className={classes.formWrapper} style={{ padding: '8px 0' }}>
          <CustomizedTextField
            placeholder={`Please add remarks before ${
              adminResponse === 'A' ? 'accepting' : 'declining'
            }`}
            variant="outlined"
            name="remarks"
            multiline
            rows={2}
            onChange={(event: React.ChangeEvent<any>) =>
              setAdminRemarks(event.target.value)
            }
          />
        </Box>
      )}

      {remarksActive && (
        <Grid
          container
          spacing={2}
          style={{ marginTop: '15px', marginBottom: '5px' }}
        >
          <Grid item style={{ marginLeft: 'auto' }}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Clear />}
              style={{ backgroundColor: '#E96C5A' }}
              onClick={handleClose}
            >
              Close
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Done />}
              style={{ backgroundColor: '#3FC581' }}
              onClick={handleContinue}
              disabled={adminRemarks.length === 0}
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      )}

      {isLeaveLatest && remarksActive === false && (
        <Grid
          container
          spacing={2}
          style={{ marginTop: '15px', marginBottom: '5px' }}
        >
          <Grid item style={{ marginLeft: 'auto' }}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Clear />}
              style={{ backgroundColor: '#E96C5A' }}
              onClick={() => handleClick('D')}
            >
              Declined
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Done />}
              style={{ backgroundColor: '#3FC581' }}
              onClick={() => handleClick('A')}
            >
              Approved
            </Button>
          </Grid>
        </Grid>
      )} */}
    </React.Fragment>
  );
};

export default LeaveRequestView;
