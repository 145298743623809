import {
  Avatar,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import StudentSidebar from "../../StudentSidebar/StudentSidebar";
import { useParams } from "react-router-dom";
import { RootStore } from "../../../../store";
import { GetStudentProfile } from "../../../../actions/Student/StudentProfile/StudentProfileAction";
import getFullName from '../../../utils/getFullName'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(0, 2),
      backgroundColor: "#F2F3F6",
    },
    roota: {
      marginBottom: "1rem",
    },
    parentImg: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      height: "100%",
      padding: "1rem 0",
    },
    table: {
      color: "#696969",
      "& .MuiTableRow-root": {
        height: "30px",
        maxHeight: "30px",
      },
      "&.MuiTableCell-root": {
        width: "50%",
      },
    },
    infobar: {
      padding: theme.spacing(2),
    },
  })
);

export default function ParentsInfo() {
  const classes = useStyles();
  const nav = <StudentSidebar studentDetails />;

  const { studentID } = useParams<{ studentID: string }>();
  const [studentData, setStudentData] = useState<any>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    studentID != undefined && dispatch(GetStudentProfile(studentID));
  }, []);

  const studentState = useSelector((state: RootStore) => state.student_profile);

  useEffect(() => {
    if (studentState != null) {
      setStudentData(studentState.student);
    }
  }, [studentState]);

  const rows = [
    createData(
      studentData?.guardian_detail?.relation + "Parent's Name",
      getFullName(studentData?.guardian_detail?.guardian_user?.first_name, studentData?.guardian_detail?.guardian_user?.last_name)
    ),
    createData("Address", studentData?.guardian_detail?.address),
    createData(
      "Email Address",
      studentData?.guardian_detail?.guardian_user?.email
    ),
    createData("Phone Number", studentData?.guardian_detail?.phone_number),
    createData("Occupation", studentData?.guardian_detail?.occupation),
    // createData(
    //   studentData?.guardian_detail?.secondary_guardian?.relation + "'s Name",
    //   studentData?.guardian_detail?.secondary_guardian?.full_name
    // ),
    // createData(
    //   "Phone Number",
    //   studentData?.guardian_detail?.secondary_guardian?.phone_number
    // ),
  ];

  const rows1 = [
    createData(
      studentData?.guardian_detail?.secondary_guardian?.relation + "'s Name",
      studentData?.guardian_detail?.secondary_guardian?.full_name
    ),
    createData(
      "Phone Number",
      studentData?.guardian_detail?.secondary_guardian?.phone_number
    ),
  ];

  function createData(name: string, infoData: string) {
    return { name, infoData };
  }

  return (
    <>
      <Paper className={classes.infobar} elevation={1}>
        <TableContainer className={classes.roota}>
          <Typography align="left" variant="h5" component="h3">
            Parents / Guardians Info
          </Typography>
          <Grid container>
            <Grid item md={2}>
              <Paper elevation={0} className={classes.parentImg}>
                <Avatar src={studentData?.guardian_detail?.photo} />
                {/* <Avatar /> */}
              </Paper>
            </Grid>
            <Grid item md={10}>
              <Table
                size="small"
                className={classes.table}
                aria-label="simple table"
              >
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell
                        className={classes.table}
                        component="th"
                        scope="row"
                      >
                        {row.name}
                      </TableCell>
                      <TableCell align="left">{row.infoData}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
          {studentData?.guardian_detail?.secondary_guardian != null ? (
            <>
              <hr />
              <Grid container>
                <Grid item md={2}>
                  <Paper elevation={0} className={classes.parentImg}>
                    <Avatar
                      src={
                        studentData?.guardian_detail?.secondary_guardian?.photo
                      }
                    />
                  </Paper>
                </Grid>
                <Grid item md={10}>
                  <Table
                    size="small"
                    className={classes.table}
                    aria-label="simple table"
                  >
                    <TableBody>
                      {rows1.map((row) => (
                        <TableRow key={row.name}>
                          <TableCell
                            className={classes.table}
                            component="th"
                            scope="row"
                          >
                            {row.name}
                          </TableCell>
                          <TableCell align="left">{row.infoData}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </>
          ) : null}
        </TableContainer>
      </Paper>
    </>
  );
}
