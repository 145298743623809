import {
  GENERAL_INFO_LOADING,
  GENERAL_INFO_ERROR,
  GENERAL_INFO_SUCCESS,
  ADD_GENERAL_INFO_LOADING,
  ADD_GENERAL_INFO_ERROR,
  ADD_GENERAL_INFO_SUCCESS,
  UPDATE_GENERAL_INFO_LOADING,
  UPDATE_GENERAL_INFO_ERROR,
  UPDATE_GENERAL_INFO_SUCCESS,
  GeneralInfoDispatchTypes,
  GET_USERS_BY_ROLE_ERROR,
  GET_USERS_BY_ROLE_SUCCESS,
  GET_USERS_BY_ROLE_LOADING,
  UsersByRoleI,
} from "../../actions/GeneralSettings/GeneralInfo/GeneralInfoActionTypes";

interface InitialStateI {
  loading: boolean;
  general_info?: any;
  logo?: any;
  usersByRole: UsersByRoleI[];
}

interface GeneralInfoI {
  id: number;
  logo?: any;
  school_name: string;
  address: string;
  phone_number: string;
  email: string;
  slogan: string;
  school_reg_number: string;
}

const initialState: InitialStateI = {
  loading: false,
  general_info: null,
  logo: null,
  usersByRole: [],
};

const GeneralInfoReducer = (
  state: any = initialState,
  action: any
): InitialStateI => {
  switch (action.type) {
    case GENERAL_INFO_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GENERAL_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        general_info: action.payload,
        logo: action.payload.logo,
      };

    case GENERAL_INFO_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_USERS_BY_ROLE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_USERS_BY_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        usersByRole: action.payload,
      };

    case GET_USERS_BY_ROLE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case ADD_GENERAL_INFO_LOADING:
      return {
        ...state,
        loading: true,
        general_info: state.general_info,
      };

    case ADD_GENERAL_INFO_ERROR:
      return {
        ...state,
        loading: false,
        general_info: state.general_info,
      };

    case ADD_GENERAL_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        general_info: [...state.general_info, action.payload],
      };

    case UPDATE_GENERAL_INFO_LOADING:
      return {
        ...state,
        loading: true,
        general_info: state.general_info,
      };

    case UPDATE_GENERAL_INFO_ERROR:
      return {
        ...state,
        loading: false,
      };

    case UPDATE_GENERAL_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        logo: action.payload.logo,
        general_info: { ...state.general_info, ...action.payload },
      };

    default:
      return state;
  }
};

export default GeneralInfoReducer;
