import {
  GET_DATES_LOADING,
  GET_DATES_ERROR,
  GET_DATES_SUCCESS,
  GET_RUNNING_MONTH_DATE_LOADING,
  GET_RUNNING_MONTH_DATE_ERROR,
  GET_RUNNING_MONTH_DATE_SUCCESS,
  DatesDispatchTypes,
} from "../../actions/Dates/DatesActionTypes";

const initialState: any = {
  loading: false,
  data: [],
  this_month_dates: [],
};

const DatesReducer = (
  state: any = initialState,
  action: DatesDispatchTypes
): any => {
  switch (action.type) {
    case GET_DATES_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_DATES_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_DATES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case GET_RUNNING_MONTH_DATE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_RUNNING_MONTH_DATE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_RUNNING_MONTH_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        this_month_dates: action.payload,
      };

    default:
      return state;
  }
};

export default DatesReducer;
