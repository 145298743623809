import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { AddSystemRoles } from "../../../actions/HumanResource/StaffRole/StaffRolesAction";
import { RootStore } from "../../../store";
import CustomizedSelect from "../../Reusable/Inputs/Select";
import FormLayout from "../../Reusable/Layouts/Form.Layout";
import { useFormStyles } from "../../Styles/FormStyles";

const RolesForm = () => {
  const classes = useFormStyles();
  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [roles, setRoles] = React.useState<any>(null);
  const [isBtnLoading, setIsBtnLoading] = React.useState<boolean>(false);

  const { register, handleSubmit, setError, errors, clearErrors } = useForm();

  const rolesSelector = useSelector((state: RootStore) => state.staff_roles);

  const errorsData = useSelector(
    (state: RootStore) => state.staff_roles.errors
  );

  const add_or_update = useSelector((state: RootStore) => state.staff_roles.add_or_update)

  React.useEffect(() => {
    add_or_update && setIsBtnLoading(false)
  }, [add_or_update]);

  React.useEffect(() => {
    if (errorsData != null) {
      setError("name", {
        type: "serverSideError",
        message: errorsData.error,
      });
    }
  }, [errorsData]);

  React.useEffect(() => {
    const rolesLoading = rolesSelector.loading;
    setLoading(rolesLoading);
    rolesSelector.recently_added && setRoles(null);
  }, [rolesSelector]);

  const ROLE_TYPE_CHOICES = [
    { id: 1, value: "Administrator" },
    { id: 2, value: "Teacher" },
    { id: 3, value: "Parent" },
    { id: 4, value: "Student" },
    { id: 5, value: "Accountant" },
  ];

  const onSubmit = (data: any) => {
    setIsBtnLoading(true);
    const new_data = {
      name: roles.value,
    };
    new_data && dispatch(AddSystemRoles(new_data));
  };

  const handleRoleChange = (value: any) => {
    setRoles(value);
    clearErrors("name");
  };

  return (
    <>
      <FormLayout
        title="Add Roles"
        onSubmit={handleSubmit(onSubmit)}
        loading={loading}
        add_or_update={isBtnLoading}
      >
        <CustomizedSelect
          label="Select Roles"
          name="name"
          required
          options={ROLE_TYPE_CHOICES}
          value={roles}
          setValue={handleRoleChange}
          inputRef={register({ required: "Role must be selected" })}
        />
        <span className={classes.validationErrorInfo}>
          {errors.name?.type === "required" && errors.name.message}
        </span>
        <span className={classes.validationErrorInfo}>
          {errors.name?.type === "serverSideError" && errors.name.message}
        </span>
      </FormLayout>
    </>
  );
};

export default RolesForm;
