export const GET_CHECKIN_CHECKOUT_LOADING = "GET_CHECKIN_CHECKOUT_LOADING";
export const GET_CHECKIN_CHECKOUT_ERROR = "GET_CHECKIN_CHECKOUT_ERROR";
export const GET_CHECKIN_CHECKOUT_SUCCESS = "GET_CHECKIN_CHECKOUT_SUCCESS";

export const POST_CHECKIN_CHECKOUT_LOADING = "POST_CHECKIN_CHECKOUT_LOADING";
export const POST_CHECKIN_CHECKOUT_ERROR = "POST_CHECKIN_CHECKOUT_ERROR";
export const POST_CHECKIN_CHECKOUT_SUCCESS = "POST_CHECKIN_CHECKOUT_SUCCESS";

export type GetCheckinCheckoutTypeI = {
  student_first_name: string;
  student_last_name: string;
  student_academic: number;
  roll_number: number;
  status: "A" | "P" | "I" | null;
};

//===============================<START>GET CLASS ATTENDANCE<START>======================//

export interface GetCheckinCheckoutLoading {
  type: typeof GET_CHECKIN_CHECKOUT_LOADING;
}

export interface GetCheckinCheckoutError {
  type: typeof GET_CHECKIN_CHECKOUT_ERROR;
}

export interface GetCheckinCheckoutSuccess {
  type: typeof GET_CHECKIN_CHECKOUT_SUCCESS;
  payload: any;
}

export interface PostCheckinCheckoutLoading {
  type: typeof POST_CHECKIN_CHECKOUT_LOADING;
}

export interface PostCheckinCheckoutError {
  type: typeof POST_CHECKIN_CHECKOUT_ERROR;
}

export interface PostCheckinCheckoutSuccess {
  type: typeof POST_CHECKIN_CHECKOUT_SUCCESS;
  payload: any;
}
//===============================<END>GET CLASS ATTENDANCE<END>======================//

//===============================<END>POST CLASS ATTENDANCE<END>======================//

export type CheckinCheckoutDispatchTypes =
  | GetCheckinCheckoutLoading
  | GetCheckinCheckoutError
  | GetCheckinCheckoutSuccess
  | PostCheckinCheckoutLoading
  | PostCheckinCheckoutError
  | PostCheckinCheckoutSuccess;
