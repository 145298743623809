import {
  InvoiceDiscountAppliedI,
  InvoiceFineAppliedI,
  InvoiceScholarshipAppliedI
} from "../CollectFees/CollectFeesActionTypes";

export const GET_FEE_LEDGER_REPORT_LOADING = "GET_FEE_LEDGER_REPORT_LOADING";
export const GET_FEE_LEDGER_REPORT_ERROR = "GET_FEE_LEDGER_REPORT_ERROR";
export const GET_FEE_LEDGER_REPORT_SUCCESS = "GET_FEE_LEDGER_REPORT_SUCCESS";

export const GET_FEE_LEDGER_BY_DATE_LOADING = "GET_FEE_LEDGER_BY_DATE_LOADING";
export const GET_FEE_LEDGER_BY_DATE_ERROR = "GET_FEE_LEDGER_BY_DATE_ERROR";
export const GET_FEE_LEDGER_BY_DATE_SUCCESS = "GET_FEE_LEDGER_BY_DATE_SUCCESS";

export const GET_DUE_FEE_REPORT_LOADING = "GET_DUE_FEE_REPORT_LOADING";
export const GET_DUE_FEE_REPORT_ERROR = "GET_DUE_FEE_REPORT_ERROR";
export const GET_DUE_FEE_REPORT_SUCCESS = "GET_DUE_FEE_REPORT_SUCCESS";

export const GET_FEE_TYPE_REPORT_LOADING = "GET_FEE_TYPE_REPORT_LOADING";
export const GET_FEE_TYPE_REPORT_ERROR = "GET_FEE_TYPE_REPORT_ERROR";
export const GET_FEE_TYPE_REPORT_SUCCESS = "GET_FEE_TYPE_REPORT_SUCCESS";

export const GET_MONTHLY_FEE_REPORT_LOADING = "GET_MONTHLY_FEE_REPORT_LOADING";
export const GET_MONTHLY_FEE_REPORT_ERROR = "GET_MONTHLY_FEE_REPORT_ERROR";
export const GET_MONTHLY_FEE_REPORT_SUCCESS = "GET_MONTHLY_FEE_REPORT_SUCCESS";

export type FeeLedgerTypeI = {
  student_id: string;
  student_first_name: string;
  student_last_name: string;
  grade: string;
  section: string | null;
  created_on: string;
  total_paid: number;
  due_amount: number;
};

export type FeeTypeReportI = {
  fee_type_id: string;
  fee_type: string;
  student: string;
  amount: number;
  due_amount: number;
}

export type MonthlyFeeReportI = {
  month: string;
  amount: number;
  student: number;
  due_amount: number;
}

export type FeeLedgerByDateI = {
  id: string;
  invoice: string;
  month: string | null;
  fee_type: string;
  amount_to_pay: number;
  receipt_id: string;
  payment_method: string;
  paid_amount: number;
  due_amount: number;
  invoice_discount_applied: InvoiceDiscountAppliedI[];
  invoice_fine_applied: InvoiceFineAppliedI[];
  invoice_scholarship_applied: InvoiceScholarshipAppliedI[],
  advance_amount: 0.0,
  created_on: string,
}

export type LedgerStudentInfoI = {
  roll_number: number;
  student_first_name: string;
  student_last_name: string;
  dob: string;
  guardian_first_name: string;
  guardian_last_name: string;
  address: string;
  grade_name: string;
  section_name: string;
}

export interface LedgerDetailPayloadI {
  invoice_detail: FeeLedgerByDateI[];
  student_info: LedgerStudentInfoI;
}

//===============================<START>GET FEE LEDGER REPORT<START>======================//

export interface GetFeeLedgerReportLoading {
  type: typeof GET_FEE_LEDGER_REPORT_LOADING;
}

export interface GetFeeLedgerReportError {
  type: typeof GET_FEE_LEDGER_REPORT_ERROR;
}

export interface GetFeeLedgerReportSuccess {
  type: typeof GET_FEE_LEDGER_REPORT_SUCCESS;
  payload: FeeLedgerTypeI[];
}

//===============================<END>GET FEE LEDGER REPORT<END>======================//

//===============================<START>GET DUE FEE REPORT<START>======================//

export interface GetDueFeeReportLoading {
  type: typeof GET_DUE_FEE_REPORT_LOADING;
}

export interface GetDueFeeReportError {
  type: typeof GET_DUE_FEE_REPORT_ERROR;
}

export interface GetDueFeeReportSuccess {
  type: typeof GET_DUE_FEE_REPORT_SUCCESS;
  payload: FeeLedgerTypeI[];
}

//===============================<END>GET DUE FEE REPORT<END>======================//

//===============================<START>GET FEE TYPE REPORT<START>======================//

export interface GetFeeTypeReportLoading {
  type: typeof GET_FEE_TYPE_REPORT_LOADING;
}

export interface GetFeeTypeReportError {
  type: typeof GET_FEE_TYPE_REPORT_ERROR;
}

export interface GetFeeTypeReportSuccess {
  type: typeof GET_FEE_TYPE_REPORT_SUCCESS;
  payload: FeeTypeReportI[];
}

//===============================<END>GET FEE TYPE REPORT<END>======================//

//===============================<START>GET MONTHLY FEE REPORT<START>======================//

export interface GetMonthlyFeeReportLoading {
  type: typeof GET_MONTHLY_FEE_REPORT_LOADING;
}

export interface GetMonthlyFeeReportError {
  type: typeof GET_MONTHLY_FEE_REPORT_ERROR;
}

export interface GetMonthlyFeeReportSuccess {
  type: typeof GET_MONTHLY_FEE_REPORT_SUCCESS;
  payload: MonthlyFeeReportI[];
}

//===============================<END>GET MONTHLY FEE REPORT<END>======================//

//===============================<START>GET FEE LEDGER BY DATE<START>======================//

export interface GetFeeLedgerByDateLoading {
  type: typeof GET_FEE_LEDGER_BY_DATE_LOADING;
}

export interface GetFeeLedgerByDateError {
  type: typeof GET_FEE_LEDGER_BY_DATE_ERROR;
}

export interface GetFeeLedgerByDateSuccess {
  type: typeof GET_FEE_LEDGER_BY_DATE_SUCCESS;
  payload: LedgerDetailPayloadI;
}

//===============================<END>GET FEE LEDGER BY DATE<END>======================//

export type FeeLedgerDispatchTypes =
  | GetFeeLedgerReportLoading
  | GetFeeLedgerReportError
  | GetFeeLedgerReportSuccess
  | GetDueFeeReportLoading
  | GetDueFeeReportError
  | GetDueFeeReportSuccess
  | GetFeeTypeReportLoading
  | GetFeeTypeReportError
  | GetFeeTypeReportSuccess
  | GetMonthlyFeeReportLoading
  | GetMonthlyFeeReportError
  | GetMonthlyFeeReportSuccess
  | GetFeeLedgerByDateLoading
  | GetFeeLedgerByDateError
  | GetFeeLedgerByDateSuccess