import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { GetPreTimeTables } from "../../actions/Academics/PreTimeTable/PreTimeTableAction";
import { GetTimeTables } from "../../actions/Academics/TimeTable/TimeTableAction";
import { AcademicSidebar } from "../../components";
import NewTimeTablePreview from "../../components/Academic/CreateTimeTable/NewTimeTablePreview";
import PreTimeTableList from "../../components/Academic/PreTimeTable/PreTimeTableList";
import SearchTimetable from "../../components/Academic/TimeTable/SearchTimeTable";
import TimetableSchedule from "../../components/Academic/TimeTable/TimetableSchedule";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";
import { getTimePeriodByGrade } from "../../rtk/features/timetable/periodApi";
import { getTimeTableByClass } from "../../rtk/features/timetable/timetableApi";

const TimetablePage = () => {
  const dispatch = useDispatch();
  const nav = <AcademicSidebar timeTable />;
  const [formData, setFormData] = useState<{
    classId: string | null;
    gradeId: string | null;
    section: string | null;
    hasSection: boolean;
  }>({ classId: null, gradeId: null, section: null, hasSection: false });

  const fetchSearchData = (value: {
    classId: string | null;
    gradeId: string | null;
    section: string | null;
    hasSection: boolean;
  }) => {
    setFormData(value);
    if (value.gradeId) {
      dispatch(getTimePeriodByGrade(value.gradeId));
      dispatch(
        getTimeTableByClass({
          grade_id: value.gradeId,
          section_id: value.section || null,
        })
      );
    }
  };

  return (
    <SubModuleLayout sideNav={nav}>
      <Grid item xs={12}>
        <SearchTimetable searchData={fetchSearchData} />
        <NewTimeTablePreview />
      </Grid>
    </SubModuleLayout>
  );
};

export default TimetablePage;
