import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  TableHead,
  TableBody,
  Table,
  Button,
  Divider,
  TableRow,
} from "@material-ui/core";
import { useTableStyles } from "../../Styles/TableStyles";
import { useTableWithPagination } from "../../Reusable";
import { RootStore } from "../../../store";
import { useDispatch, useSelector } from "react-redux";

import BackDropLoader from "../../Reusable/BackDropLoader";
import styled from "styled-components";
import { Edit, Save } from "@material-ui/icons";
import {
  changeFields,
  reloadData,
} from "../../../rtk/features/exam/addMarks/addMarksSlice";
import {
  getMarksEntry,
  postMarksEntry,
} from "../../../rtk/features/exam/addMarks/addMarksApi";
import { getGradings } from "../../../rtk/features/exam/grading/gradingApi";

const CellInput = styled.input<{ fullWidth?: boolean; error?: boolean }>`
  && {
    width: ${(props) => (props.fullWidth ? `unset` : `80px`)};
    padding: 5px;
    border-radius: 3px;
    border: ${(props) => (props.error ? `1px solid red` : `1px solid #000`)};
    color: ${(props) => (props.error ? `red` : `#000`)};

    &:focus {
      outline: none;
    }
  }
`;

const CellSelect = styled.select<{ fullWidth?: boolean; error?: boolean }>`
  && {
    width: ${(props) => (props.fullWidth ? `120px` : "60px")};
    padding: 5px 10px 5px 6px;
    border-radius: 3px;
    border: ${(props) => (props.error ? `1px solid red` : `1px solid #000`)};
    color: ${(props) => (props.error ? `red` : `#000`)};
    cursor: pointer;
    appearance: auto;

    &:focus {
      border: 1px solid #3354d4;
      outline: none;
    }

    &:hover {
      border: 1px solid #3354d4;
    }

    &:disabled {
      background-color: #d1d1d1;
      border: 1px solid #000;
      color: #7e7e7e;
      cursor: default;
    }
  }
`;

const ExamDetail = styled.span`
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.8px;
  line-height: 1.6;

  & span {
    font-weight: 500;
  }
`;
// ---------------------------- <END> interface ends ----------------------------------//
// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells = [
  { id: "roll_no", label: "Roll No" },
  { id: "student_name", label: "Student Name" },
  { id: "grade", label: "Grade" },
  { id: "remarks", label: "Remarks" },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const AddGradesTable: React.FC = () => {
  const classes = useTableStyles();
  const dispatch = useDispatch();
  const { loading, studentGrades, searchParams, actionPerformed } = useSelector(
    (state: RootStore) => state.addMarks
  );

  const [editMode, setEditMode] = useState<boolean>(false);

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTableWithPagination(headCells);

  const { gradings } = useSelector((state: RootStore) => state.marksGrading);

  useEffect(() => {
    dispatch(getGradings());
  }, []);

  useEffect(() => {
    if (actionPerformed) {
      setEditMode(false);

      if (searchParams?.exam && searchParams.grade && searchParams.subject) {
        dispatch(
          getMarksEntry({
            examId: searchParams.exam.id,
            gradeId: searchParams.grade.id,
            sectionId: searchParams.section?.id || "",
            subjectId: searchParams.subject.id,
          })
        );
      }
    }
  }, [actionPerformed]);

  const handleReset = () => {
    if (!editMode) {
      setEditMode(true);
    } else {
      setEditMode(false);
    }
  };

  const handleSaveMarks = () => {
    const data: {
      student_info: string;
      grade_obtained_theory: string;
      grade_obtained_practical: string;
      remarks: string;
    }[] = [];

    studentGrades.grades.forEach((el) => {
      data.push({
        student_info: el.id,
        grade_obtained_theory: el.gradeTheory || "",
        grade_obtained_practical: el.gradePractical || "",
        remarks: el.remarks,
      });
    });

    if (searchParams?.exam && searchParams.subject && searchParams.grade) {
      dispatch(
        postMarksEntry({
          examId: searchParams.exam.id,
          subjectId: searchParams.subject.id,
          grade: searchParams.grade.id,
          section: searchParams.section?.id || "",
          is_practical: searchParams.subject.has_practical,
          post_data: data,
        })
      );
    }
  };

  return (
    <Paper className={classes.rootTableBox}>
      <Grid container>
        <Grid item xs={4}>
          <ExamDetail>
            Exam: <span>{searchParams?.exam?.name || "-"}</span>
          </ExamDetail>
        </Grid>
        <Grid item xs={8}>
          <ExamDetail>
            Subject: <span>{searchParams?.subject?.name || "-"}</span>
          </ExamDetail>
        </Grid>
        <Grid item xs={4}>
          <ExamDetail>
            Grade: <span>{searchParams?.grade?.name || "-"}</span>
          </ExamDetail>
        </Grid>
        <Grid item xs={4}>
          <ExamDetail>
            Section: <span>{searchParams?.section?.name || "-"}</span>
          </ExamDetail>
        </Grid>
      </Grid>

      <Divider style={{ margin: "10px 0 15px 0" }} />
      <Grid container>
        <Table>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell align="center">Roll No</StyledTableCell>
              <StyledTableCell align="center">Student Name</StyledTableCell>
              <StyledTableCell align="center">
                Grade Obtained (TH)
              </StyledTableCell>
              <StyledTableCell align="center">
                Grade Obtained (PR)
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {studentGrades.grades.map((item, index) => (
              <StyledTableRow key={item.id}>
                <StyledTableCell align="center">{index + 1}</StyledTableCell>
                <StyledTableCell align="center">{item.name}</StyledTableCell>
                <StyledTableCell align="center">
                  {editMode ? (
                    <CellSelect
                      value={
                        item.gradeTheory ||
                        gradings.find((el) => el.grade_name === "NG")?.id ||
                        ""
                      }
                      onChange={(e) =>
                        dispatch(
                          changeFields({
                            id: item.id,
                            field: "gradeTheory",
                            value: e.target.value,
                          })
                        )
                      }
                    >
                      {gradings.map((el) => (
                        <option key={el.id} value={el.id}>
                          {el.grade_name}
                        </option>
                      ))}
                    </CellSelect>
                  ) : (
                    gradings.find((el) => el.id === item.gradeTheory)
                      ?.grade_name || "NG"
                  )}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {editMode ? (
                    <CellSelect
                      value={
                        item.gradePractical ||
                        gradings.find((el) => el.grade_name === "NG")?.id ||
                        ""
                      }
                      onChange={(e) =>
                        dispatch(
                          changeFields({
                            id: item.id,
                            field: "gradePractical",
                            value: e.target.value,
                          })
                        )
                      }
                      disabled={!studentGrades.has_practical}
                    >
                      {gradings.map((el) => (
                        <option key={el.id} value={el.id}>
                          {el.grade_name}
                        </option>
                      ))}
                    </CellSelect>
                  ) : (
                    gradings.find((el) => el.id === item.gradePractical)
                      ?.grade_name || "NG"
                  )}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleReset}
            startIcon={<Edit />}
            style={{ margin: "10px 10px 0 0" }}
            disabled={!studentGrades.grades.length}
          >
            {editMode ? "Cancel" : "Edit"}
          </Button>
          {editMode && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSaveMarks()}
              startIcon={<Save />}
              style={{ margin: "10px 10px 0 0" }}
            >
              Save
            </Button>
          )}
        </div>
      </Grid>
      <BackDropLoader open={loading} />
    </Paper>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default AddGradesTable;
