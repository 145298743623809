import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getRollNum, updateRollNum } from "./rollNumApi";

interface StudentsI {
  academic_id: string;
  student_first_name: string;
  student_last_name: string;
  grade: string;
  section: string | null;
  roll_number: string | null;
  error?: boolean;
}

interface InitialStateI {
  loading: boolean;
  actionPerformed: boolean;
  students: StudentsI[];
}

const initialState: InitialStateI = {
  loading: false,
  actionPerformed: false,
  students: [],
};

const rollNumSlice = createSlice({
  name: "rollNum",
  initialState,
  reducers: {
    assignAlphabetically: (state) => {
      state.students.forEach((item, index) => {
        item.roll_number = String(index + 1);
      });
    },
    changeRollNumber: (
      state,
      { payload }: PayloadAction<{ id: string; value: string }>
    ) => {
      const record = state.students.find(
        (item) => item.academic_id === payload.id
      );
      const re = /^[0-9\b]+$/;
      if (record) {
        if (payload.value === "" || re.test(payload.value)) {
          record.roll_number = payload.value;
        }

        if (record.roll_number) {
          if (Number(record.roll_number) <= 0) {
            record.error = true;
            return;
          }
          if (Number(record.roll_number) > state.students.length) {
            record.error = true;
            return;
          }

          const matching = state.students.filter(
            (el) => el.roll_number == record.roll_number
          );

          if (matching.length > 1) {
            record.error = true;
            return;
          }
        }

        record.error = false;
      }
    },
    emptyRollNumTable: (state) => {
      state.students = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRollNum.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(
      getRollNum.fulfilled,
      (state, { payload }: PayloadAction<{ results: StudentsI[] }>) => {
        state.loading = false;
        state.students = payload.results;
      }
    );
    builder.addCase(getRollNum.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateRollNum.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(updateRollNum.fulfilled, (state) => {
      state.loading = false;
      state.actionPerformed = true;
    });
    builder.addCase(updateRollNum.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  assignAlphabetically: assignAlphabeticallyAction,
  changeRollNumber: changeRollNumberAction,
  emptyRollNumTable: emptyRollNumTableAction,
} = rollNumSlice.actions;

export default rollNumSlice.reducer;
