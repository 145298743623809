import {
  GET_STUDENT_PROFILE_LOADING,
  GET_STUDENT_PROFILE_ERROR,
  GET_STUDENT_PROFILE_SUCCESS,
  GET_STUDENT_LEAVE_REQUEST_HISTORY_LOADING,
  GET_STUDENT_LEAVE_REQUEST_HISTORY_ERROR,
  GET_STUDENT_LEAVE_REQUEST_HISTORY_SUCCESS,
  StudentProfileDispatchTypes,
} from '../../../actions/Student/StudentProfile/StudentProfileActionTypes';

const initialState: any = {
  loading: false,
  student: [],
  student_leave_history: [],
  student_roles: [],
};

const StudentProfileReducer = (
  state: any = initialState,
  action: StudentProfileDispatchTypes
): any => {
  switch (action.type) {
    case GET_STUDENT_PROFILE_LOADING:
      return {
        ...state,
        loading: true,
        student: [],
      };

    case GET_STUDENT_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        student: [],
      };

    case GET_STUDENT_PROFILE_SUCCESS:
      return {
        loading: false,
        student: action.payload,
        student_roles: action.payload.student_user.groups,
      };

    case GET_STUDENT_LEAVE_REQUEST_HISTORY_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_STUDENT_LEAVE_REQUEST_HISTORY_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_STUDENT_LEAVE_REQUEST_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        student_leave_history: action.payload,
      };

    default:
      return state;
  }
};

export default StudentProfileReducer;
