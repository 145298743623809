import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_STUDENT_COMPLAINT_LOADING,
  GET_STUDENT_COMPLAINT_ERROR,
  GET_STUDENT_COMPLAINT_SUCCESS,
  StudentComplaintDispatchTypes,
  ADD_STUDENT_COMPLAINT_LOADING,
  ADD_STUDENT_COMPLAINT_SUCCESS,
  ADD_STUDENT_COMPLAINT_ERROR,
  DELETE_STUDENT_COMPLAINT_SUCCESS,
  DELETE_STUDENT_COMPLAINT_ERROR,
  DELETE_STUDENT_COMPLAINT_LOADING,
  UPDATE_STUDENT_COMPLAINT_LOADING,
  UPDATE_STUDENT_COMPLAINT_SUCCESS,
  UPDATE_STUDENT_COMPLAINT_ERROR,
  GET_STUDENT_COMPLAINT_BY_ID_LOADING,
  GET_STUDENT_COMPLAINT_BY_ID_SUCCESS,
  GET_STUDENT_COMPLAINT_BY_ID_ERROR,
} from "./StudentComplaintActionTypes";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";

interface RequestDataI {
  grade: string;
  section: string | null;
  student: string;
  description: string;
}

export const GetStudentComplaint =
  () =>
  async (dispatch: Dispatch<StudentComplaintDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_STUDENT_COMPLAINT_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/student_complain/`,
        HeaderConfig()
      );

      dispatch({
        type: GET_STUDENT_COMPLAINT_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_STUDENT_COMPLAINT_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Student Complaint Loading Failed",
        },
      });
    }
  };

export const GetStudentComplaintById =
  (id: any) =>
  async (dispatch: Dispatch<StudentComplaintDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_STUDENT_COMPLAINT_BY_ID_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/student_complain/get_complain/?id=${id}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_STUDENT_COMPLAINT_BY_ID_SUCCESS,
        payload: res.data,
      });
      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "success",
      //     snackbarMessage: "Students Complaint Loaded",
      //   },
      // });
    } catch (error) {
      dispatch({
        type: GET_STUDENT_COMPLAINT_BY_ID_ERROR,
      });
    }
  };

export const AddStudentComplaint =
  (data: RequestDataI) =>
  async (dispatch: Dispatch<StudentComplaintDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_STUDENT_COMPLAINT_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/student_complain/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_STUDENT_COMPLAINT_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Student Complaint Added Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: ADD_STUDENT_COMPLAINT_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Student Complaint Add Failed",
        },
      });
    } finally {
      try {
        dispatch({
          type: GET_STUDENT_COMPLAINT_LOADING,
        });

        const res = await axios.get(
          `${ADMIN_API_URL}/student_complain/`,
          HeaderConfig()
        );

        dispatch({
          type: GET_STUDENT_COMPLAINT_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: GET_STUDENT_COMPLAINT_ERROR,
        });
      }
    }
  };

export const UpdateStudentComplaint =
  (id: string, data: RequestDataI) =>
  async (dispatch: Dispatch<StudentComplaintDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_STUDENT_COMPLAINT_LOADING,
      });

      const res = await axios.put(
        `${ADMIN_API_URL}/student_complain/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_STUDENT_COMPLAINT_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Student Complaint Updated Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: UPDATE_STUDENT_COMPLAINT_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Student Complaint Update Failed",
        },
      });
    } finally {
      try {
        dispatch({
          type: GET_STUDENT_COMPLAINT_LOADING,
        });

        const res = await axios.get(
          `${ADMIN_API_URL}/student_complain/`,
          HeaderConfig()
        );

        dispatch({
          type: GET_STUDENT_COMPLAINT_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: GET_STUDENT_COMPLAINT_ERROR,
        });
      }
    }
  };

export const DeleteStudentComplaint =
  (id: string) =>
  async (dispatch: Dispatch<StudentComplaintDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_STUDENT_COMPLAINT_LOADING,
      });

      const res = await axios.delete(
        `${ADMIN_API_URL}/student_complain/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_STUDENT_COMPLAINT_SUCCESS,
        payload: { id: id },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Student Complaint Deleted Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_STUDENT_COMPLAINT_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Student Complaint Delete Failed",
        },
      });
    }
  };
