import React, { useEffect, useRef, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import { useFormStyles } from '../../Styles/FormStyles';
import FormLayout from '../../Reusable/Layouts/Form.Layout';
import CustomizedTextField from '../../Reusable/Inputs/TextField';
import firstWordCapital from '../../utils/firstWordCapital';
import {
  LocationTypeI,
  LocationFormDataTypeI,
} from '../../../actions/Transport/Location/LocationActionTypes';
import {
  AddLocation,
  UpdateLocation,
} from '../../../actions/Transport/Location/LocationAction';

interface PropsI {
  editData?: LocationTypeI;
  onEditCheck: (value: boolean) => void;
}

function LocationForm(props: PropsI) {
  const dispatch = useDispatch();
  const classes = useFormStyles();

  const { editData, onEditCheck } = props;
  const { register, handleSubmit, errors, setError, setValue, reset } =
    useForm();

  const [editID, setEditID] = useState<string>('');
  const [editMode, setEditMode] = useState<boolean>(false);
  const [serverErrors, setServerErrors] = useState<any>(null);

  useEffect(() => {
    editData !== undefined &&
      editData !== null &&
      handleEditTableData(editData);
  }, [editData]);

  const handleReset = () => {
    reset();
    setEditMode(false);
    setEditID('');
    onEditCheck(false);
  };

  const onFormSubmit = (data: LocationFormDataTypeI) => {
    const finalValues = data;

    if (editMode) {
      editID != null && dispatch(UpdateLocation(editID, finalValues));
    } else {
      dispatch(AddLocation(finalValues));
    }
    handleReset();
  };

  const handleEditTableData = (data: LocationTypeI) => {
    setEditMode(true);
    setEditID(data.id);
    setValue('name', data.name);
    setValue('one_way_cost', data.one_way_cost);
    setValue('two_way_cost', data.two_way_cost);
    setValue('pick_up_time', data.pick_up_time);
    setValue('drop_time', data.drop_time);
    setValue('longitude', data.longitude);
    setValue('latitude', data.latitude);
  };

  const transportSelector = useSelector((state: RootStore) => state.transport);
  const errorsData = transportSelector.error;
  const initialErrorsData = useRef(errorsData);

  useEffect(() => {
    if (initialErrorsData.current === errorsData) {
      initialErrorsData.current = errorsData; // Do not set initial errors
    } else {
      if (errorsData?.error != null) {
        const keys = Object.keys(errorsData?.error);
        keys.map((elem: any) => {
          setError(elem, {
            type: 'serverSideError',
            message: errorsData.error[elem] && errorsData.error[elem][0],
          });
        });
      }
      setServerErrors(errorsData);
    }
  }, [errorsData]);

  useEffect(() => {
    if (errorsData == null) {
      setServerErrors(null);
    } else if (serverErrors?.error) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [errorsData]);

  useEffect(() => {
    if (transportSelector.recent) {
      handleReset();
    }
  }, [transportSelector]);

  return (
    <FormLayout
      title="Add Bus Stop Location"
      onSubmit={handleSubmit(onFormSubmit)}
      editMode={editMode}
      loading={false}
      onClick={handleReset}
      add_or_update={false}
    >
      {serverErrors &&
        serverErrors?.error &&
        Object.keys(serverErrors.error)
          .filter((elem) => elem === 'error')
          .map((elem: any, index: number) => {
            return (
              <div className={classes.serversidemessages} key={index}>
                <Alert severity="error">
                  {firstWordCapital(serverErrors?.error[elem][0])}
                </Alert>
              </div>
            );
          })}

      <CustomizedTextField
        label="Location Name"
        placeholder="Location Name"
        name="name"
        error={errors['name'] ? 'Required Field.' : ''}
        inputRef={register({ required: true })}
      />
      <span className={classes.validationErrorInfo}>
        {errors.name?.type === 'serverSideError' && errors.name.message}
      </span>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CustomizedTextField
            label="One Way Cost"
            placeholder="Route Fare"
            name="one_way_cost"
            error={errors['one_way_cost'] ? 'Required Field.' : ''}
            inputRef={register({ required: true })}
          />
          <span className={classes.validationErrorInfo}>
            {errors.one_way_cost?.type === 'serverSideError' &&
              errors.one_way_cost.message}
          </span>
        </Grid>

        <Grid item xs={6}>
          <CustomizedTextField
            label="Two Way Cost"
            placeholder="Route Fare"
            name="two_way_cost"
            error={errors['two_way_cost'] ? 'Required Field.' : ''}
            inputRef={register({ required: true })}
          />
          <span className={classes.validationErrorInfo}>
            {errors.two_way_cost?.type === 'serverSideError' &&
              errors.two_way_cost.message}
          </span>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CustomizedTextField
            label="Pickup Time"
            placeholder="Pickup Time"
            name="pick_up_time"
            error={errors['pick_up_time'] ? 'Required Field.' : ''}
            inputRef={register({ required: true })}
            type="time"
          />
          <span className={classes.validationErrorInfo}>
            {errors.pick_up_time?.type === 'serverSideError' &&
              errors.pick_up_time.message}
          </span>
        </Grid>

        <Grid item xs={6}>
          <CustomizedTextField
            label="Drop Time"
            placeholder="Drop Time"
            name="drop_time"
            error={errors['drop_time'] ? 'Required Field.' : ''}
            inputRef={register({ required: true })}
            type="time"
          />
          <span className={classes.validationErrorInfo}>
            {errors.drop_time?.type === 'serverSideError' &&
              errors.drop_time.message}
          </span>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CustomizedTextField
            label="Longitude"
            placeholder="Longitude"
            name="longitude"
            error={errors['longitude'] ? 'Required Field.' : ''}
            inputRef={register({ required: true })}
          />
          <span className={classes.validationErrorInfo}>
            {errors.longitude?.type === 'serverSideError' &&
              errors.longitude.message}
          </span>
        </Grid>

        <Grid item xs={6}>
          <CustomizedTextField
            label="Latitude"
            placeholder="Latitude"
            name="latitude"
            error={errors['latitude'] ? 'Required Field.' : ''}
            inputRef={register({ required: true })}
          />
          <span className={classes.validationErrorInfo}>
            {errors.latitude?.type === 'serverSideError' &&
              errors.latitude.message}
          </span>
        </Grid>
      </Grid>
    </FormLayout>
  );
}
export default LocationForm;
