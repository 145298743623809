export const GET_TERMS_CONDITIONS_LOADING = "GET_TERMS_CONDITIONS_LOADING";
export const GET_TERMS_CONDITIONS_ERROR = "GET_TERMS_CONDITIONS_ERROR";
export const GET_TERMS_CONDITIONS_SUCCESS = "GET_TERMS_CONDITIONS_SUCCESS";

export const ADD_TERMS_CONDITIONS_LOADING = "ADD_TERMS_CONDITIONS_LOADING";
export const ADD_TERMS_CONDITIONS_ERROR = "ADD_TERMS_CONDITIONS_ERROR";
export const ADD_TERMS_CONDITIONS_SUCCESS = "ADD_TERMS_CONDITIONS_SUCCESS";

export type TacTypes = {
  id: string;
  tac_field: string;
};

export interface GetTacLoading {
  type: typeof GET_TERMS_CONDITIONS_LOADING;
}

export interface GetTacError {
  type: typeof GET_TERMS_CONDITIONS_ERROR;
}

export interface GetTacSuccess {
  type: typeof GET_TERMS_CONDITIONS_SUCCESS;
  payload: TacTypes;
}

//===============================<END>GET SESSION<END>======================//

//===============================<START>ADD SESSION<START>======================//

export interface AddTacLoading {
  type: typeof ADD_TERMS_CONDITIONS_LOADING;
}

export interface AddTacError {
  type: typeof ADD_TERMS_CONDITIONS_ERROR;
}

export interface AddTacSuccess {
  type: typeof ADD_TERMS_CONDITIONS_SUCCESS;
  payload: TacTypes;
}

export type TacDispatchTypes =
  | GetTacLoading
  | GetTacError
  | GetTacSuccess
  | AddTacLoading
  | AddTacError
  | AddTacSuccess;
