import { SITE_URL } from "../../config/SERVER_API_URLS";

const checkImageUrl = (data: string) => {
  const usingObjectAssign = Object.assign([], data);
  if (usingObjectAssign[0] != "h") {
    const modified_data = SITE_URL + data;
    return modified_data;
  } else {
    return data;
  }
};

export default checkImageUrl;
