import React, { useState } from "react";
import { BoxUpload, ImagePreview } from "./style";
import ImageIcon from "../../../../assets/images/image-icon.png";
import CloseIcon from "../../../../assets/images/CloseIcon.svg";

const UploadImageFile = (props: any) => {
  const { image, setImage, onChange } = props;
  //   const [image, setImage] = useState<FileReader | null | any>(null);
  const [imagePreview, setImagePreview] = useState<FileReader | null | any>();

  const handleImageUpload = (e: any) => {
    if (e.target.files && e.target.files) {
      setImage(e.target.files[0]);
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function (e) {
        setImagePreview(e.target?.result as string);
      };
    }
  };
  // console.log("imagepreview", imagePreview?.length);
  // console.log("image", image?.length);

  return (
    <>
      <BoxUpload>
        <div className="image-upload">
          {image == null ? (
            <>
              <label htmlFor="upload-input">
                <img
                  src={ImageIcon}
                  draggable={"false"}
                  style={{ width: 100, height: 100 }}
                />
                <p style={{ color: "#444", marginTop: "20px" }}>
                  Click to select image
                </p>
              </label>

              <input
                id="upload-input"
                type="file"
                accept=".jpg,.jpeg,.gif,.png,.mov"
                onChange={handleImageUpload}
              />
            </>
          ) : (
            <ImagePreview>
              <img
                className="close-icon"
                src={CloseIcon}
                alt="CloseIcon"
                onClick={() => {
                  setImage(null);
                }}
              />
              <img
                id="uploaded-image"
                src={imagePreview === undefined ? image : imagePreview}
                alt="uploaded-img"
              />
            </ImagePreview>
          )}
        </div>
      </BoxUpload>
    </>
  );
};

export default UploadImageFile;
