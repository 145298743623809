import axios from "axios";
import { Dispatch } from "redux";

import {
    GET_FEE_INCOME_ERROR,
    GET_FEE_INCOME_LOADING,
    GET_FEE_INCOME_SUCCESS,
    FeeIncomeDispatchTypes,
} from "./FeeIncomeActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";


export const GetFeeIncome =
    (id: number, fee: string) =>
        async (dispatch: Dispatch<FeeIncomeDispatchTypes | SetSnackBarI>) => {
            try {
                dispatch({
                    type: GET_FEE_INCOME_LOADING,
                });

                const res = await axios.get(
                    `${ADMIN_API_URL}/report/fee-income/?page=${id}&fee_header=${fee}`,
                    HeaderConfig()
                );

                dispatch({
                    type: GET_FEE_INCOME_SUCCESS,
                    payload: res.data,
                });
            } catch (error) {
                dispatch({
                    type: GET_FEE_INCOME_ERROR,
                });
            }
        };