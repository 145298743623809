// import { ClassTypeI } from "../Class/ClassActionTypes";
// import { SubjectTypeI } from "./SubjectActionTypes";
import { SectionTypeI } from "../../Academics/Section/SectionActionTypes";
export const GET_STUDENT_COMPLAINT_LOADING = "GET_STUDENT_COMPLAINT_LOADING";
export const GET_STUDENT_COMPLAINT_ERROR = "GET_STUDENT_COMPLAINT_ERROR";
export const GET_STUDENT_COMPLAINT_SUCCESS = "GET_STUDENT_COMPLAINT_SUCCESS";

export const GET_STUDENT_COMPLAINT_BY_ID_LOADING =
  "GET_STUDENT_COMPLAINT_BY_ID_LOADING";
export const GET_STUDENT_COMPLAINT_BY_ID_ERROR =
  "GET_STUDENT_COMPLAINT_BY_ID_ERROR";
export const GET_STUDENT_COMPLAINT_BY_ID_SUCCESS =
  "GET_STUDENT_COMPLAINT_BY_ID_SUCCESS";

export const ADD_STUDENT_COMPLAINT_LOADING = "ADD_STUDENT_COMPLAINT_LOADING";
export const ADD_STUDENT_COMPLAINT_ERROR = "ADD_STUDENT_COMPLAINT_ERROR";
export const ADD_STUDENT_COMPLAINT_SUCCESS = "ADD_STUDENT_COMPLAINT_SUCCESS";

export const UPDATE_STUDENT_COMPLAINT_LOADING =
  "UPDATE_STUDENT_COMPLAINT_LOADING";
export const UPDATE_STUDENT_COMPLAINT_ERROR = "UPDATE_STUDENT_COMPLAINT_ERROR";
export const UPDATE_STUDENT_COMPLAINT_SUCCESS =
  "UPDATE_STUDENT_COMPLAINT_SUCCESS";

export const DELETE_STUDENT_COMPLAINT_LOADING =
  "DELETE_STUDENT_COMPLAINT_LOADING";
export const DELETE_STUDENT_COMPLAINT_ERROR = "DELETE_STUDENT_COMPLAINT_ERROR";
export const DELETE_STUDENT_COMPLAINT_SUCCESS =
  "DELETE_STUDENT_COMPLAINT_SUCCESS";

  export type ComplainerI = {
    id: number;
    username: string;
    first_name: string;
    last_name: string;
    email: string
  }

  export type StudentI = {
    id: number;
    grade: string;
    section: string;
    first_name: string;
    last_name: string;
    roll_number?: number
  }

export type StudentComplaintTypeI = {
  id: string;
  academic_id: string;
  complainer: ComplainerI;
  complainer_first_name: string;
  complainer_last_name: string;
  student_info: StudentI;
  student: string;
  student_name: string;
  grade: string;
  grade_name: string;
  section: string;
  section_name: string;
  class_name: string;
  description: string;
  status: string;
  general_info: string;
  created_by: string;
  created_on: string;
  modified_on: string;
  date: null;
  count?: number;
};

//===============================<START>GET STUDENT COMPLAIN<START>======================//

export interface GetStudentComplaintLoading {
  type: typeof GET_STUDENT_COMPLAINT_LOADING;
}

export interface GetStudentComplaintError {
  type: typeof GET_STUDENT_COMPLAINT_ERROR;
}

export interface GetStudentComplaintSuccess {
  type: typeof GET_STUDENT_COMPLAINT_SUCCESS;
  payload: StudentComplaintTypeI[];
}

//===============================<END>GET STUDENT COMPLAIN<END>======================//

//===============================<START>GET STUDENT COMPLAIN<START>======================//

export interface GetStudentComplaintByIdLoading {
  type: typeof GET_STUDENT_COMPLAINT_BY_ID_LOADING;
}

export interface GetStudentComplaintByIdError {
  type: typeof GET_STUDENT_COMPLAINT_BY_ID_ERROR;
}

export interface GetStudentComplaintByIdSuccess {
  type: typeof GET_STUDENT_COMPLAINT_BY_ID_SUCCESS;
  payload: StudentComplaintTypeI[];
}

//===============================<END>GET STUDENT COMPLAIN<END>======================//

//===============================<START>ADD STUDENT COMPLAIN<START>======================//

export interface AddStudentComplaintLoading {
  type: typeof ADD_STUDENT_COMPLAINT_LOADING;
}

export interface AddStudentComplaintError {
  type: typeof ADD_STUDENT_COMPLAINT_ERROR;
}

export interface AddStudentComplaintSuccess {
  type: typeof ADD_STUDENT_COMPLAINT_SUCCESS;
  payload: StudentComplaintTypeI;
}

//===============================<END>ADD STUDENT COMPLAIN<END>======================//

//===============================<START>UPDATE STUDENT COMPLAIN<START>======================//

export interface UpdateStudentComplaintLoading {
  type: typeof UPDATE_STUDENT_COMPLAINT_LOADING;
}

export interface UpdateStudentComplaintError {
  type: typeof UPDATE_STUDENT_COMPLAINT_ERROR;
}

export interface UpdateStudentComplaintSuccess {
  type: typeof UPDATE_STUDENT_COMPLAINT_SUCCESS;
  payload: StudentComplaintTypeI;
}

//===============================<END>UPDATE STUDENT COMPLAIN<END>======================//

//===============================<START>DELETE STUDENT COMPLAIN<START>======================//

export interface DeleteStudentComplaintLoading {
  type: typeof DELETE_STUDENT_COMPLAINT_LOADING;
}

export interface DeleteStudentComplaintError {
  type: typeof DELETE_STUDENT_COMPLAINT_ERROR;
}

export interface DeleteStudentComplaintSuccess {
  type: typeof DELETE_STUDENT_COMPLAINT_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE STUDENT COMPLAIN<END>======================//

export type StudentComplaintDispatchTypes =
  | GetStudentComplaintLoading
  | GetStudentComplaintError
  | GetStudentComplaintSuccess
  | AddStudentComplaintLoading
  | AddStudentComplaintError
  | AddStudentComplaintSuccess
  | UpdateStudentComplaintLoading
  | UpdateStudentComplaintError
  | UpdateStudentComplaintSuccess
  | DeleteStudentComplaintLoading
  | DeleteStudentComplaintError
  | DeleteStudentComplaintSuccess
  | GetStudentComplaintByIdLoading
  | GetStudentComplaintByIdError
  | GetStudentComplaintByIdSuccess;
