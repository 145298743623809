import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setSnackbar } from "../../../../actions/SnackbarAction";
import { ADMIN_API_URL } from "../../../../config/SERVER_API_URLS";

export const getMarksEntry = createAsyncThunk(
  "getMarksEntry",
  async (
    data: {
      examId: string;
      gradeId: string;
      sectionId: string;
      subjectId: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.get(
        `${ADMIN_API_URL}/exam-marks/?grade=${data.gradeId}&section=${data.sectionId}&exam_subject=${data.subjectId}`
      );
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(null);
    }
  }
);

export const getAllSubjectsMarksEntry = createAsyncThunk(
  "getAllSubjectsMarksEntry",
  async (
    data: {
      examId: string;
      gradeId: string;
      sectionId: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.get(
        `${ADMIN_API_URL}/exam-marks/get_bulk_students_subject_marks/?exam_info=${data.examId}&grade=${data.gradeId}&section=${data.sectionId}`
      );
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(null);
    }
  }
);

export const postMarksEntry = createAsyncThunk(
  "postMarksEntry",
  async (
    data: {
      examId: string;
      subjectId: string;
      post_data: any;
      grade: string;
      section: string;
      is_practical: boolean;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.post(
        `${ADMIN_API_URL}/exam-marks/save_bulk_student_marks_obtained/?exam_info=${data.examId}&exam_subject=${data.subjectId}&grade=${data.grade}&section=${data.section}&is_practical=${data.is_practical}`,
        data.post_data
      );
      dispatch(setSnackbar(true, "success", "Marks Updated Successfully"));
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      let msg;
      try {
        msg = error.response.data[0];
      } catch (err) {
        msg = "Failed to update marks";
      }
      dispatch(setSnackbar(true, "error", msg));
      return rejectWithValue(null);
    }
  }
);

export const postAllSubjectsMarksEntry = createAsyncThunk(
  "postAllSubjectsMarksEntry",
  async (
    data: {
      examId: string;
      post_data: any;
      grade: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.post(
        `${ADMIN_API_URL}/exam-marks/save_bulk_subject_student_marks_obtained/?exam_info=${data.examId}&grade=${data.grade}`,
        data.post_data
      );
      dispatch(setSnackbar(true, "success", "Marks Updated Successfully"));
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      let msg;
      try {
        msg = error.response.data[0];
      } catch (err) {
        msg = "Failed to update marks";
      }
      dispatch(setSnackbar(true, "error", msg));
      return rejectWithValue(null);
    }
  }
);
