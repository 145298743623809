import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { ResultSidebar } from "../../components";
import GradesForm from "../../components/Result/Grades/GradesForm";
import GradesTable from "../../components/Result/Grades/GradesTable";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";
import { GradeTypeI } from "../../actions/Examination/Grade/GradeActionTypes";

const ExamGroupPage = () => {
  const nav = <ResultSidebar grades />;
  return (
    <SubModuleLayout examinationMenuActive sideNav={nav}>
      <GradesTable />
    </SubModuleLayout>
  );
};

export default ExamGroupPage;
