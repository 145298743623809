import {
  Button,
  Grid,
  InputLabel,
  Paper,
  TextField,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';
import { GetClasses } from '../../../actions/Academics/Class/ClassAction';
import { useForm } from 'react-hook-form';
import {
  GetSearchedStudentList,
  GetStudentListWithPagination,
} from '../../../actions/Student/Student/StudentAction';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete } from '@material-ui/lab';
import { useFormStyles } from '../../Styles/FormStyles';
import { RootStore } from '../../../store';

// -----------------<start> Styling <start>-----------------------//

// -----------------<end> Styling <end>-----------------------//

// -----------------<start> Interfaces <start>-----------------------//

interface FormI {
  class: string | null;
  section: string | null;
}

interface DataI {
  academic_year: string;
  class: string | null | undefined;
  section: string | null | undefined;
}

interface SectionI {
  id: string;
  title: string;
}

interface ClassI {
  id: string;
  title: string;
  sections: SectionI[] | [];
}
// -----------------<end> Interfaces <end>-----------------------//
// -----------------<start> States <start>-----------------------//
// -----------------<end> States <end>-----------------------//

const ResultSMS = () => {
  const classes = useFormStyles();
  const { register, handleSubmit, errors, setValue } = useForm<FormI>();
  const [className, setClassName] = useState<ClassI | null>(null);
  const [section, setSection] = useState<SectionI | null>(null);
  const [sectionChoices, setSectionChoices] = useState<SectionI[] | []>([]);
  const [sectionDisabler, setSectionDisabler] = useState<boolean>(true);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetClasses());
  }, []);

  const classState = useSelector((state: RootStore) => state.class);

  const classChoices: ClassI[] = classState.classes.map((classItem, index) => ({
    id: classItem.grade,
    title: classItem.grade_name,
    sections: classItem.section.map((sectionItem) => ({
      id: sectionItem.id,
      title: sectionItem.name,
    })),
  }));

  const handleClassChange = (value: ClassI | null) => {
    setClassName(value);
    setSection(null);
    value != null
      ? populateSectionChoices(value.sections)
      : setSectionDisabler(true);
  };

  const populateSectionChoices = (sections: SectionI[] | []) => {
    setSectionDisabler(false);
    sections.length > 0
      ? setSectionChoices(sections)
      : setSectionDisabler(true);
  };

  const onSubmit = (data: FormI) => {
    const form_data: DataI = {
      academic_year: '',
      class: className?.id,
      section: section?.id,
    };
    dispatch(GetSearchedStudentList(form_data));
  };
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: false,
    checkedC: false,
    checkedD: true,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleReset = () => {
    setSection(null);
    setClassName(null);
    dispatch(GetStudentListWithPagination(1));
  };

  return (
    <>
      <Paper className={classes.pageContentBox}>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={9} className={classes.formTitle}>
              <span>Search for Student ID Card</span>
            </Grid>
            <Grid container item xs={3}>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                  <Tooltip title="Clear Search Query">
                    <IconButton
                      aria-label="delete"
                      style={{ padding: '2px' }}
                      onClick={handleReset}
                    >
                      <SettingsBackupRestoreIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={3} className={classes.formWrapper}>
              <InputLabel>Select Class</InputLabel>
              <Autocomplete
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: ClassI | null
                ) => handleClassChange(value)}
                options={classChoices}
                value={className}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Label"
                    name="class_name"
                    inputRef={register({ required: false })}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={3} className={classes.formWrapper}>
              <InputLabel>Select Section </InputLabel>
              <Autocomplete
                value={section}
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: SectionI | null
                ) => setSection(value)}
                options={sectionChoices}
                getOptionLabel={(option) => option.title}
                disabled={sectionDisabler}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Label"
                    name="session_name"
                    inputRef={register({ required: false })}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={3} className={classes.formWrapper}>
              <Grid container justifyContent="center">
                <Button
                  className={classes.searchBoxButton}
                  type="submit"
                  color="primary"
                  variant="contained"
                  startIcon={<SearchIcon />}
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
};

export default ResultSMS;
