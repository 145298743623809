//function to format date and time

import { Tupple } from "../../actions";
import { MONTH_CHOICES } from "../../common/json.constant";

const getMonthName = (value: string) => {
    if (value) {
        const month = MONTH_CHOICES.find((elem: Tupple) => elem.key === value)
        return `${month?.value}`
    } else {
        return ''
    }
};
export default getMonthName;
