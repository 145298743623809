// ---------------------------- <START> module imports start ----------------------------------//
import React, { useState, useEffect } from 'react';
import { Paper, Typography, TableCell } from '@material-ui/core';
import { useTableStyles } from '../../Styles/TableStyles';
import { useTable } from '../../Reusable';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import CustomizedDialogs from '../../Reusable/Dialogs/DeleteDialog';
import Popups from '../../Reusable/Dialogs/Popups';
import { StudentComplaintTypeI } from '../../../actions/Student/StudentComplaint/StudentComplaintActionTypes';
import {
  GetStudentComplaint,
  DeleteStudentComplaint,
} from '../../../actions/Student/StudentComplaint/StudentCompaintAction';
import ComplainView from './ComplainView';
import {
  ItemDeleteButton,
  ItemEditButton,
  ItemViewButton,
} from '../../Reusable/Buttons/TableButton';
import { dateConverterAdToBs } from '../../utils/dateConverter';
import { setSnackbar } from '../../../actions/SnackbarAction';
import getFullName from '../../utils/getFullName';
import getClassName from '../../utils/getClassName';
import AccessPermission from '../StudentDetail/AccessPermission';

// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//
interface PropsI {
  onEditData: (value: StudentComplaintTypeI) => void;
  editValue: { value: boolean; count: number };
}

interface HeadCellsI {
  id: string;
  label: string;
}
// ---------------------------- <END> interface ends ----------------------------------//

// ---------------------------- <START> styling starts ----------------------------------//

// ---------------------------- <END> styling ends ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: 'sn', label: 'S.N' },
  { id: 'complainer', label: 'Feedbacker' },
  { id: 'student', label: 'Student' },
  { id: 'class', label: 'Class' },
  { id: 'date', label: 'Date' },
  // { id: "status", label: "Status" },
  { id: 'description', label: 'Description' },
  { id: 'action', label: 'Action' },
];

// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const StudentComplaintTable = (props: PropsI) => {
  const { onEditData, editValue } = props;
  const classes = useTableStyles();

  // States declaration
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);
  const [viewData, setViewData] = useState<any[] | null>(null);
  const [editMode, setEditMode] = useState(false);
  const [tableData, setTableData] = useState<StudentComplaintTypeI[]>([]);

  //========================= <START> REDUX HOOKS <START> ==============================//
  const dispatch = useDispatch();
  const studentComplaintState = useSelector(
    (state: RootStore) => state.student_complaint
  );
  const studentComplaintLoading = useSelector(
    (state: RootStore) => state.student_complaint.loading
  );

  useEffect(() => {
    editValue && setEditMode(editValue.value);
  }, [editValue]);

  useEffect(() => {
    dispatch(GetStudentComplaint());
  }, []);

  useEffect(() => {
    setLoading(studentComplaintLoading);
  }, [studentComplaintLoading]);

  useEffect(() => {
    const studentComplaintList = studentComplaintState?.student_complaint;
    if (studentComplaintList != null) {
      setTableData(studentComplaintList);
    }
  }, [studentComplaintState]);

  //========================= <END> REDUX HOOKS <END> ==============================//

  // Retrieving re-usable components from useTable
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  //==================================== <START> EVENT HANDLERS <START> ==========================//
  const handleEditClicked = (item: StudentComplaintTypeI) => {
    onEditData(item);
    setEditMode(true);
  };

  const handleDeleteModal = (value: boolean, id: string) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteStudentComplaint(itemId));
    setDeleteModalOpen(false);
    setTimeout(() => {
      dispatch(GetStudentComplaint());
    }, 100);
  };

  const [openPopup, setOpenPopup] = useState(false);

  const handleClose = (value: boolean) => {
    setOpenPopup(value);
  };

  const handleComplainView = (item: any) => {
    setOpenPopup(true);
    setViewData(item);
  };

  //==================================== <END> EVENT HANDLERS <END> ==========================//
  const getCustomTableRow = (item: StudentComplaintTypeI, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {getFullName(item.complainer?.first_name, item.complainer?.last_name)}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {getFullName(
            item.student_info?.first_name,
            item.student_info?.last_name
          )}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {getClassName(item.student_info?.grade, item.student_info?.section)}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {/* {dateConverterAdToBs(item.created_on)} */}
          {new Date(item.created_on)?.toNepali()}
        </StyledTableCell>
        {/* <StyledTableCell align="center" className={classes.cell}>
          {item.status}
        </StyledTableCell> */}
        <StyledTableCell align="left" className={classes.cell}>
          {item.description.slice(0, 20)}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.threeCell}>
          <AccessPermission>
            <ItemViewButton onClick={() => handleComplainView(item)} />
            <ItemEditButton
              onClick={(
                event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
              ) => handleEditClicked(item)}
            />
            <ItemDeleteButton
              disabled={editMode}
              onClick={(
                event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
              ) => handleDeleteModal(true, item.id)}
            />
          </AccessPermission>
        </StyledTableCell>
      </StyledTableRow>
    );
  };
  return (
    <Paper className={classes.root}>
      <Typography variant="h6">Feedback List</Typography>

      <TableContainer
        loading={loading}
        items={tableData}
        headCells={headCells}
        getCustomTableRow={getCustomTableRow}
        search_name="student_name"
        search_param="complainer"
      />
      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
      />
      <Popups
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        title="Student Feedback"
        colored
      >
        <ComplainView viewData={viewData} />
      </Popups>
    </Paper>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default StudentComplaintTable;
