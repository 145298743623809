// ---------------- <START> module import ends ------------------//
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import {
  Paper,
  Grid,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { useTable } from '../../Reusable';
import { useTableStyles } from '../../Styles/TableStyles';

import {
  GetEvents,
  DeleteEvent,
} from '../../../actions/Event/Events/EventsAction';
import CustomizedDialogs from '../../Reusable/Dialogs/DeleteDialog';
import {
  ItemDeleteButton,
  ItemEditButton,
  PostAddButton,
  ItemViewButton,
} from '../../Reusable/Buttons/TableButton';
import { dateConverterAdToBs } from '../../utils/dateConverter';
import { fromToConverter } from '../../utils/dateTimeFormat';
import Popups from '../../Reusable/Dialogs/Popups';
import BraftEditor from 'braft-editor';
import styled from 'styled-components';
import EventNoticeForm from './EventNoticeForm';
import { noticeBraftData } from './NoticeMockup';

const StyledNoticeInfoContainer = styled.div`
  margin-bottom: 24px;
`;

const StyledTableContainer = styled(({ children, ...rest }: any) => (
  <TableContainer {...rest}>{children}</TableContainer>
))`
  max-width: 100%;
  margin-bottom: 24px;
`;

interface EventNoticeInfoProps {
  notice: string;
}

const data = {
  id: '1',
  name: 'Name of Event',
  type: 'Type of Event',
  location: 'Location of Event',
};

const EventNoticeInfo = (props: EventNoticeInfoProps) => {
  const { notice } = props;
  return (
    <React.Fragment>
      <StyledNoticeInfoContainer dangerouslySetInnerHTML={{ __html: notice }} />
      <StyledTableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell component="th">Event Name</TableCell>
              <TableCell align="right">{data.name}</TableCell>
              <TableCell component="th">Event Type</TableCell>
              <TableCell align="right">{data.type}</TableCell>{' '}
            </TableRow>

            <TableRow>
              <TableCell component="th">Status</TableCell>
              <TableCell align="right">{data.type}</TableCell>
              <TableCell component="th">Location</TableCell>
              <TableCell align="right">{data.type}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th">From</TableCell>
              <TableCell align="right">{data.type}</TableCell>
              <TableCell component="th">To</TableCell>
              <TableCell align="right">{data.type}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th">Time</TableCell>
              <TableCell align="right">{data.type}</TableCell>
              <TableCell component="th">Description</TableCell>
              <TableCell align="right">{data.type}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </StyledTableContainer>

      <StyledTableContainer component={Paper} style={{ maxWidth: 320 }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell component="th">Total Participants</TableCell>
              <TableCell align="right">1,000</TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th">Event Attendant</TableCell>
              <TableCell align="right">890</TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th">Event Not Attendant</TableCell>
              <TableCell align="right">110</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </StyledTableContainer>

      <StyledTableContainer component={Paper} style={{ maxWidth: 320 }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell component="th">1st Person</TableCell>
              <TableCell align="right">Aashish Maharjan</TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th">2nd Person</TableCell>
              <TableCell align="right">Lumus Shah</TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th">3rd Person</TableCell>
              <TableCell align="right">Manoj Adhikari</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </StyledTableContainer>
    </React.Fragment>
  );
};

export default EventNoticeInfo;
