import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { capitalize } from 'lodash';
import {
  pageContent,
  pageContentBox,
  form,
  formRow,
  validationErrorInfo,
  searchBoxButton,
  classContent,
  smsContent,
  annoucementContent,
  modalContent,
  formTitle,
  nepaliDateInputs,
  formWrappers,
  formWrap,
  uploadButton,
  selectExp,
  display,
} from './ReusableStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageContent: {
      margin: theme.spacing(1, 2, 5),
      padding: theme.spacing(3),
      '& .MuiSvgIcon-root': {
        width: '16px',
        height: '1em',
      },
      '& .MuiButton-containedPrimary': {
        width: '116px',
      },
      ...pageContent,
    },
    pageContentBox: {
      margin: theme.spacing(1, 3, 3, 3),
      padding: theme.spacing(3),
      '& .MuiAutocomplete-inputRoot': {
        padding: '0 30px 0 0 !important',
      },
      ...pageContentBox,
    },

    form: {
      '& .MuiFormControl-root': {
        width: '100%',
        margin: theme.spacing(1, 1, 0, 0),
      },
      '& .MuiInputLabel-root': {
        fontSize: '14px',
        color: '#000',
        opacity: '0.7',
      },

      '& .MuiButton-text': {
        width: '100%',
        borderRadius: '4px',
        padding: 6,
      },
      '& .MuiDivider-root': {
        height: '2px',
        margin: theme.spacing(2, 0, 2, 0),
      },
      ...form,
    },
    formRow: {
      alignItems: 'center',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      '& .MuiInputLabel-animated': {
        marginTop: '15px',
        marginRight: '55px',
      },
      '& .MuiTypography-body1': {
        fontSize: '13px',
      },
    },
    formWrapper: {
      flexDirection: 'column',
      ...formWrappers,
    },
    formWrap: {
      ...formWrap,
      flexDirection: 'column',
    },

    formMainTitle: {
      margin: theme.spacing(0, 0, 4, 0),
    },
    formTitle: {
      fontWeight: 500,
      fontSize: '20px',
      marginBottom: theme.spacing(3),
      '& span': {
        color: 'rgb(77, 79, 92)',
      },
    },
    input: {
      display: 'none',
    },
    uploadButton: {
      ...uploadButton,
    },
    validationErrorInfo: {
      marginLeft: theme.spacing(1.2),
      '&:first-letter': {
        textTransform: 'capitalize',
      },
      ...validationErrorInfo,
    },
    radioGroupRow: {
      width: '80%',
      margin: theme.spacing(0, 0, 2, 1),
    },
    submitButton: {
      marginTop: theme.spacing(5),
      width: '70%',
    },
    browseButton: {
      marginTop: '16px !important',
      border: '1px solid',
      borderColor: 'primary',
    },
    formAlert: {
      fontSize: '13px',
      borderLeft: '12px solid rgba(13, 60, 97, 0.75)',
      marginBottom: '15px',
      padding: '3px 15px',
    },
    textArea: {
      margin: '0px !important',
      '& .MuiOutlinedInput-multiline': {
        padding: '5px !important',
      },
    },
    searchButton: {
      margin: theme.spacing(1, 1, 0.4, 1),
      width: '100%',
      height: '38px',
    },
    // Search Box component button style
    searchBoxButton: {
      ...searchBoxButton,
    },
    uploadbutton: {
      margin: theme.spacing(1),
      textTransform: 'capitalize',
    },
    studentAdmissionDivider: {
      margin: '0px',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    nepaliDateInput: {
      zIndex: 'auto',
      padding: theme.spacing(1, 2),
      margin: theme.spacing(1),
      ...nepaliDateInputs,
    },
    iframe: {
      margin: 'auto',
      height: '90vh',
      objectFit: 'cover',
      // overflowX: "auto",
    },
    serversidemessages: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
      marginBottom: theme.spacing(3),
    },
  })
);

export const useFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageContent: {
      margin: theme.spacing(1, 2, 3, 2),
      padding: theme.spacing(2),

      '& .MuiSvgIcon-root': {
        // width: "16px",
        height: '1em',
      },
      ...pageContent,
    },
    pageContentBox: {
      margin: theme.spacing(1, 2),
      padding: theme.spacing(3),
      ...pageContentBox,
    },
    pageAttachContent: {
      margin: theme.spacing(1, 2, -3, 2),
      padding: theme.spacing(3),
      borderBottom: '1px solid #D6D6D6',
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
      '& .MuiAutocomplete-inputRoot': {
        padding: '0 30px 0 0 !important',
      },
      '& input::placeholder': {
        fontSize: '13px',
      },
      '& .MuiButton-containedPrimary': {
        width: '116px',
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
          border: '1px solid #132E98',
        },
        '&:hover fieldset': {
          borderColor: '#132E98 !important',
        },
      },
    },
    classContent: {
      margin: theme.spacing(0),
      padding: theme.spacing(2),
      ...classContent,
    },
    smsContent: {
      // paddingRight: theme.spacing(3),
      // padding: theme.spacing(2),
      ...smsContent,
    },
    annoucementContent: {
      margin: theme.spacing(0, 3, 0, 0),
      paddingRight: theme.spacing(3),
      ...annoucementContent,
    },
    modalContent: {
      ...modalContent,
    },
    selection: {
      ...display,
      flexDirection: 'column',
    },
    buttonsave: {
      padding: '1rem',
    },
    examContent: {
      margin: theme.spacing(4, 0, 3, 3),
      padding: theme.spacing(3),
      '& .MuiInputBase-input': {
        fontSize: '0.9rem',
      },
      '& .MuiButton-containedPrimary': {
        padding: '6px',
      },
    },
    form: {
      // "& .MuiOutlinedInput-root": {
      //   margin: theme.spacing(1, 0, 0.3, 0),
      // },
      '& .MuiFormControl-root': {
        width: '100%',
      },
      '& .MuiInputLabel-root': {
        fontSize: '14px',
        color: '#000',
        opacity: 1,
      },

      '& .MuiButton-contained': {
        // boxSizing: 'border-box',
        width: '116px',
        // marginBottom: theme.spacing(3),
      },

      '& .MuiInputBase-multiline': {
        padding: theme.spacing(1.5, 0.5),
      },
      '& .MuiInputBase-inputMultiline': {
        padding: theme.spacing(1, 1.5),
      },
      '& .MuiFormHelperText-root': {
        color: '#f44336',
      },
      ...form,
    },
    formControl: {
      width: '100%',
      margin: '8px 0px 20px 0px',
      paddingBottom: '15px',
    },
    formWrapper: {
      marginBottom: '10px',
      flexDirection: 'column',
      ...formWrappers,
    },
    formWrapperRow: {
      // marginBottom: "10px",
      flexDirection: 'row',
      alignItems: 'center',
      ...formWrappers,
    },
    formWrapperMd: {
      ...formWrappers,
      marginBottom: '10px',
      flexDirection: 'column',
      '& .MuiOutlinedInput-input': {
        padding: '12px !important',
        paddingLeft: '16px !important',
      },
    },
    formRow: {
      marginBottom: theme.spacing(2),
      flexDirection: 'row',
      '& .MuiInputLabel-animated': {
        marginTop: '3px',
        marginRight: '55px',
      },
      ...formRow,
    },
    validationErrorInfo: {
      marginLeft: theme.spacing(1.2),
      '&:first-letter': {
        textTransform: 'capitalize',
      },
      ...validationErrorInfo,
    },
    formTitle: {
      fontSize: '16px',
      marginBottom: theme.spacing(2),
      ...formTitle,
    },
    sectionCheckBox: {
      '& .MuiFormGroup-root': {
        marginTop: theme.spacing(1),
      },
      '& .MuiCheckbox-root': {
        padding: theme.spacing(0, 1, 0.5, 1),
      },
      '& .MuiTypography-root': {
        marginBottom: theme.spacing(0.5),
        fontSize: '14px',
        color: '#6C6D6E',
      },
    },
    sectionUnavailableText: {
      margin: theme.spacing(1, 1, 3, 1),
      fontSize: '12px',
      color: '#6C6D6E',
    },
    nepaliDateInput: {
      zIndex: 'auto',

      padding: theme.spacing(1, 2),
      margin: theme.spacing(1),
      ...nepaliDateInputs,
    },
    nepaliDate: {
      position: 'relative',

      padding: theme.spacing(1, 2),
      margin: theme.spacing(1),
      ...nepaliDateInputs,
    },
    formProgress: {
      margin: theme.spacing(3, -3, -3, -3),
    },
    radioGroup: {
      margin: theme.spacing(1, 0, 2.5, 0),
      '& .MuiRadio-root': {
        padding: theme.spacing(0),
        margin: '0px 9px',
      },
      '& .MuiTypography-body1': {
        fontSize: '14px',
      },
    },
    chips: {
      ...display,
      flexWrap: 'wrap',
      flexDirection: 'row',
      margin: 0,
    },
    chip: {
      ...display,
      flexDirection: 'row',
      margin: theme.spacing(0.5, 0, 0, 0.5),
    },
    // Search Box component button style
    searchBoxButton: {
      ...searchBoxButton,
    },
    searchBoxButton1: {
      marginTop: '22px',
      paddingTop: 7,
      paddingBottom: 7,
    },
    selectExp: {
      ...selectExp,
      flexWrap: 'wrap',
      padding: theme.spacing(0.5),
    },
    alignFull: {
      background: '#f58634',
      padding: '8px 5px',
    },
    alignAnother: {
      background: '#ffcc29',
      padding: '8px 5px',
    },
    scrollNotice: {
      maxHeight: '400px',
      overflowX: 'hidden',
      overflowY: 'auto',
      padding: '8px 0 0 8px',
      marginLeft: '-24px',
      marginRight: '-16px',
    },
    serversidemessages: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
      marginBottom: theme.spacing(3),
    },
    smallfont: {
      fontSize: '12px',
    },
    submitButton: {
      backgroundColor: '#3FC581',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#3FC581',
      },
    },
    resetButton: {
      padding: '4px',
      background: 'rgba(0,0,0,0.05)',
    },
    closeBtn: {
      padding: '8px',
      marginTop: '15px',
      marginRight: '15px',
      // height: "24px",
    },
  })
);
