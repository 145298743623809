import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_ID_CARD_LOADING,
  GET_ID_CARD_ERROR,
  GET_ID_CARD_SUCCESS,
  GET_MULTIPLE_ID_CARD_LOADING,
  GET_MULTIPLE_ID_CARD_ERROR,
  GET_MULTIPLE_ID_CARD_SUCCESS,
  GET_MULTIPLE_STAFF_ID_CARD_LOADING,
  GET_MULTIPLE_STAFF_ID_CARD_ERROR,
  GET_MULTIPLE_STAFF_ID_CARD_SUCCESS,
  IdCardDispatchTypes,
} from "./IdCardActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

interface RequestDataI {
  id: string;
  name: string;
}

export const GetIdCard =
  (id: string) =>
  async (dispatch: Dispatch<IdCardDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_ID_CARD_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/student_info/get_id_card/?student=${id}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_ID_CARD_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_ID_CARD_ERROR,
      });
    }
  };

export const GetMultipleIdCard =
  (data: any) =>
  async (dispatch: Dispatch<IdCardDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_MULTIPLE_ID_CARD_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/bulk/student-info/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: GET_MULTIPLE_ID_CARD_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_MULTIPLE_ID_CARD_ERROR,
      });
    }
  };

export const GetMultipleStaffIdCard =
  (data: any) =>
  async (dispatch: Dispatch<IdCardDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_MULTIPLE_STAFF_ID_CARD_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/bulk/staff-info/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: GET_MULTIPLE_STAFF_ID_CARD_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_MULTIPLE_STAFF_ID_CARD_ERROR,
      });
    }
  };
