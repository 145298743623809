import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { PaymentMethodTypeI } from "../../actions/Fees/PaymentMethod/PaymentMethodActionTypes";
import { FinanceSidebar } from "../../components";
import PaymentMethodForm from "../../components/Finance/PaymentMethod/PaymentMethodForm";
import PaymentMethodList from "../../components/Finance/PaymentMethod/PaymentMethodList";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const PaymentMethodPage = () => {
  const [formData, setFormData] = useState<PaymentMethodTypeI | null>(null);
  const [count, setCount] = useState<number>(0);
  const [edit, setEdit] = useState<any>();

  const fetchEditData = (data: PaymentMethodTypeI) => {
    setFormData({ ...data, count: count });
    setCount(count + 1);
  };

  const onEditCheck = (value: boolean) => {
    setEdit({ check: value, count: count });
  };

  const nav = <FinanceSidebar payment_method />;
  return (
    <SubModuleLayout FeesMenuActive sideNav={nav}>
      <Grid container>
        <Grid item xs={3}>
          <PaymentMethodForm onEditCheck={onEditCheck} editData={formData} />
        </Grid>
        <Grid item xs={9}>
          <PaymentMethodList editCheck={edit} onEditData={fetchEditData} />
        </Grid>
      </Grid>
    </SubModuleLayout>
  );
};

export default PaymentMethodPage;
