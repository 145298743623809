export const GET_FEE_TYPE_LOADING = "GET_FEE_TYPE_LOADING";
export const GET_FEE_TYPE_ERROR = "GET_FEE_TYPE_ERROR";
export const GET_FEE_TYPE_SUCCESS = "GET_FEE_TYPE_SUCCESS";

export const GET_ALL_FEE_TYPE_LOADING = "GET_ALL_FEE_TYPE_LOADING";
export const GET_ALL_FEE_TYPE_ERROR = "GET_ALL_FEE_TYPE_ERROR";
export const GET_ALL_FEE_TYPE_SUCCESS = "GET_ALL_FEE_TYPE_SUCCESS";

export const CREATE_FEE_TYPE_LOADING = "CREATE_FEE_TYPE_LOADING";
export const CREATE_FEE_TYPE_ERROR = "CREATE_FEE_TYPE_ERROR";
export const CREATE_FEE_TYPE_SUCCESS = "CREATE_FEE_TYPE_SUCCESS";

export const UPDATE_FEE_TYPE_LOADING = "UPDATE_FEE_TYPE_LOADING";
export const UPDATE_FEE_TYPE_ERROR = "UPDATE_FEE_TYPE_ERROR";
export const UPDATE_FEE_TYPE_SUCCESS = "UPDATE_FEE_TYPE_SUCCESS";

export const DELETE_FEE_TYPE_LOADING = "DELETE_FEE_TYPE_LOADING";
export const DELETE_FEE_TYPE_ERROR = "DELETE_FEE_TYPE_ERROR";
export const DELETE_FEE_TYPE_SUCCESS = "DELETE_FEE_TYPE_SUCCESS";

export type Fee_TypeI = {
  id: string;
  applied: boolean;
  name: string;
  fee_type: string;
  due_date: string | null;
  due_day: number | null;
  due_period_type: string | null;
  description: string;
  billing_period: any;
};

//===============================<START>GET Fee Type<START>======================//

export interface GetFeeTypeLoading {
  type: typeof GET_FEE_TYPE_LOADING;
}

export interface GetFeeTypeError {
  type: typeof GET_FEE_TYPE_ERROR;
}

export interface GetFeeTypeSuccess {
  type: typeof GET_FEE_TYPE_SUCCESS;
  payload: any;
}

//===============================<END>GET Fee Type<END>======================//

//===============================<START>GET Fee Type<START>======================//

export interface GetAllFeeTypeLoading {
  type: typeof GET_ALL_FEE_TYPE_LOADING;
}

export interface GetAllFeeTypeError {
  type: typeof GET_ALL_FEE_TYPE_ERROR;
}

export interface GetAllFeeTypeSuccess {
  type: typeof GET_ALL_FEE_TYPE_SUCCESS;
  payload: {
    results: {
      id: string;
      name: string;
      fee_type: "M" | "0";
    }[];
  };
}

//===============================<END>GET Fee Type<END>======================//

//===============================<START>Create Fee Type<START>======================//

export interface CreateFeeTypeLoading {
  type: typeof CREATE_FEE_TYPE_LOADING;
}

export interface CreateFeeTypeError {
  type: typeof CREATE_FEE_TYPE_ERROR;
  payload: any;
}

export interface CreateFeeTypeSuccess {
  type: typeof CREATE_FEE_TYPE_SUCCESS;
  payload: Fee_TypeI;
}

//===============================<END>Create Fee Type<END>======================//

//===============================<START>Update Fee Type<START>======================//

export interface UpdateFeeTypeLoading {
  type: typeof UPDATE_FEE_TYPE_LOADING;
}

export interface UpdateFeeTypeError {
  type: typeof UPDATE_FEE_TYPE_ERROR;
  payload: any;
}

export interface UpdateFeeTypeSuccess {
  type: typeof UPDATE_FEE_TYPE_SUCCESS;
  payload: Fee_TypeI;
}

//===============================<END>Update Fee Type<END>======================//

//===============================<START>Delete Fee Type<START>======================//

export interface DeleteFeeTypeLoading {
  type: typeof DELETE_FEE_TYPE_LOADING;
}

export interface DeleteFeeTypeError {
  type: typeof DELETE_FEE_TYPE_ERROR;
}

export interface DeleteFeeTypeSuccess {
  type: typeof DELETE_FEE_TYPE_SUCCESS;
  payload: { id: string };
}

//===============================<END>Delete Fee Type<END>======================//

export type FeeTypeDispatchTypes =
  | GetFeeTypeLoading
  | GetFeeTypeError
  | GetFeeTypeSuccess
  | GetAllFeeTypeLoading
  | GetAllFeeTypeError
  | GetAllFeeTypeSuccess
  | CreateFeeTypeError
  | CreateFeeTypeLoading
  | CreateFeeTypeSuccess
  | UpdateFeeTypeError
  | UpdateFeeTypeLoading
  | UpdateFeeTypeSuccess
  | DeleteFeeTypeError
  | DeleteFeeTypeLoading
  | DeleteFeeTypeSuccess;
