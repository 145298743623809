import {
  GET_ADMIN_ENOTES_CATEGORIES_LOADING,
  GET_ADMIN_ENOTES_CATEGORIES_ERROR,
  GET_ADMIN_ENOTES_CATEGORIES_SUCCESS,
  GET_ADMIN_ENOTES_LOADING,
  GET_ADMIN_ENOTES_ERROR,
  GET_ADMIN_ENOTES_SUCCESS,
  AdminEnotesCategoryI,
  AdminEnotesI,
  AdminEnotesCategoriesDispatchTypes,
} from "../../actions/Classroom/Enotes/AdminEnotesActionTypes";

interface InitialStateI {
  loading: boolean;
  enotes: AdminEnotesI[];
  enotes_categories: AdminEnotesCategoryI[];
}

const initialState: InitialStateI = {
  loading: false,
  enotes: [],
  enotes_categories: [],
};

const AdminEnotesReducer = (
  state: InitialStateI = initialState,
  action: AdminEnotesCategoriesDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_ADMIN_ENOTES_CATEGORIES_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_ADMIN_ENOTES_CATEGORIES_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_ADMIN_ENOTES_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        enotes_categories: action.payload,
      };

    case GET_ADMIN_ENOTES_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_ADMIN_ENOTES_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_ADMIN_ENOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        enotes: action.payload,
      };

    default:
      return state;
  }
};

export default AdminEnotesReducer;
