// -----------------<start> modules imports starts-----------------------//
import { Grid } from "@material-ui/core";
import React from "react";
import RoutesTabs from "./RoutesTabs/RoutesTabs";
// -----------------<ends> modules imports ends-----------------------//

// -----------------<start> Routes Modal Components starts-----------------------//
function RoutesModal(props: any) {
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <RoutesTabs
            SeatCapacity={props.SeatCapacity}
            routeId={props.routeId}
          />
        </Grid>
      </Grid>
    </>
  );
}
// -----------------<ends> Routes Modal Components ends -----------------------//
export default RoutesModal;
