import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import SaveIcon from "@material-ui/icons/Save";
import { RootStore } from "../../../store";
import { useStyles } from "../../Styles/FormStyles";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormSaveLoadingButton } from "../../Reusable/Buttons/FormButton";
import Spinner from "../../../common/spinnerSVG/Spinner";
import styled from "styled-components";
import {
  createStaff,
  getStaffs,
} from "../../../rtk/features/humanResource/humanResourceThunk";
import { createAttendanceDevice } from "../../../rtk/features/attendanceDevice/attendanceDeviceThunk";

const ModalForm = styled.form`
  margin-top: 16px;
  max-width: 600px;
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 11px;
  margin-left: 10px;
`;

interface StaffFormProps {
  name: string;
  serial_no: string;
  ip_address: string;
  area_id: string;
}

const AddDeviceModal = ({ id }: { id: string | null }) => {
  // Custom Styles
  const classes = useStyles();

  // Destructuring react-form-hook methods
  const { register, handleSubmit, errors, setError, clearErrors, control } =
    useForm<StaffFormProps>({
      mode: "onChange",
    });

  // Dispatch Variable
  const dispatch = useDispatch();

  // State Declaration

  const onSubmitModal: SubmitHandler<StaffFormProps> = (data) => {
    if (id) {
      dispatch(
        createAttendanceDevice({
          id,
          alias: data.name,
          sn: data.serial_no,
          ip_address: data.ip_address,
          area: data.area_id,
        })
      );
    }
  };

  return (
    <ModalForm onSubmit={handleSubmit(onSubmitModal)}>
      <Grid container>
        <Grid
          item
          xs={12}
          className={classes.formWrapper}
          style={{ marginBottom: "1rem" }}
        >
          <InputLabel>
            Device Name <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <TextField
            variant="outlined"
            name="name"
            placeholder="Device Name"
            inputRef={register({
              required: { value: true, message: "Device name is required." },
            })}
          />
          <ErrorMessage>{errors.name?.message}</ErrorMessage>
        </Grid>

        <Grid
          item
          xs={12}
          className={classes.formWrapper}
          style={{ marginBottom: "1rem" }}
        >
          <InputLabel>
            Serial Number <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <TextField
            variant="outlined"
            name="serial_no"
            placeholder="Serial Number"
            inputRef={register({
              required: { value: true, message: "Serial Number is required." },
            })}
          />
          <ErrorMessage>{errors.serial_no?.message}</ErrorMessage>
        </Grid>

        <Grid
          item
          xs={12}
          className={classes.formWrapper}
          style={{ marginBottom: "1rem" }}
        >
          <InputLabel>
            IP Address <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <TextField
            variant="outlined"
            name="ip_address"
            placeholder="IP Address"
            inputRef={register({
              required: { value: true, message: "IP Adrress is required." },
            })}
          />
          <ErrorMessage>{errors.ip_address?.message}</ErrorMessage>
        </Grid>

        <Grid
          item
          xs={12}
          className={classes.formWrapper}
          style={{ marginBottom: "1rem" }}
        >
          <InputLabel>
            Area ID <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <TextField
            variant="outlined"
            name="area_id"
            placeholder="Area ID"
            inputRef={register({
              required: { value: true, message: "Area ID is required." },
            })}
          />
          <ErrorMessage>{errors.area_id?.message}</ErrorMessage>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="flex-start"
        style={{ marginBottom: "1em" }}
      >
        <FormSaveLoadingButton
          type="submit"
          icon={false ? <Spinner /> : <SaveIcon />}
          disabled={false ? true : false}
          text={false ? "Saving..." : "Save"}
        />
      </Grid>
    </ModalForm>
  );
};

export default AddDeviceModal;
