import {
  GET_SCHOOLS_LOADING,
  GET_SCHOOLS_ERROR,
  GET_SCHOOLS_SUCCESS,
  GET_ALL_SCHOOLS_LOADING,
  GET_ALL_SCHOOLS_ERROR,
  GET_ALL_SCHOOLS_SUCCESS,
  GET_SCHOOLS_BY_ID_LOADING,
  GET_SCHOOLS_BY_ID_ERROR,
  GET_SCHOOLS_BY_ID_SUCCESS,
  SchoolsDispatchTypes,
  ADD_SCHOOLS_LOADING,
  ADD_SCHOOLS_SUCCESS,
  ADD_SCHOOLS_ERROR,
  DELETE_SCHOOLS_SUCCESS,
  DELETE_SCHOOLS_ERROR,
  DELETE_SCHOOLS_LOADING,
  UPDATE_SCHOOLS_LOADING,
  UPDATE_SCHOOLS_SUCCESS,
  UPDATE_SCHOOLS_ERROR,
  SWITCH_TO_SCHOOL_LOADING,
  SWITCH_TO_SCHOOL_ERROR,
  SWITCH_TO_SCHOOL_SUCCESS,
  SWITCH_BACK_TO_SCHOOL_LOADING,
  SWITCH_BACK_TO_SCHOOL_ERROR,
  SWITCH_BACK_TO_SCHOOL_SUCCESS,
  SchoolListI,
} from "../../actions/SuperAdmin/School/SchoolActionTypes";

interface InitialStateI {
  loading: boolean;
  schools: any;
  school: SchoolListI | null;
  add_or_update: boolean;
  all_schools: any;
}

const initialState: InitialStateI = {
  loading: false,
  schools: [],
  school: null,
  add_or_update: false,
  all_schools: [],
};

const SchoolReducer = (
  state: InitialStateI = initialState,
  action: SchoolsDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_SCHOOLS_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case GET_SCHOOLS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_SCHOOLS_SUCCESS:
      return {
        ...state,
        loading: false,
        schools: action.payload,
      };
    case GET_ALL_SCHOOLS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_SCHOOLS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_ALL_SCHOOLS_SUCCESS:
      return {
        ...state,
        loading: false,
        all_schools: action.payload,
      };
    case GET_SCHOOLS_BY_ID_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_SCHOOLS_BY_ID_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_SCHOOLS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        schools: action.payload,
      };

    case ADD_SCHOOLS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case ADD_SCHOOLS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case ADD_SCHOOLS_SUCCESS:
      return {
        ...state,
        loading: false,
        schools: [...state.schools.results, action.payload],
        add_or_update: true,
      };

    case UPDATE_SCHOOLS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_SCHOOLS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_SCHOOLS_SUCCESS:
      const current_data: any = state.schools.results;
      const index: number = current_data.findIndex(
        (element: any) => element.id === action.payload.id
      );
      current_data[index] = action.payload;

      return {
        ...state,
        loading: false,
        schools: current_data,
        add_or_update: true,
      };

    case DELETE_SCHOOLS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_SCHOOLS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_SCHOOLS_SUCCESS:
      const new_data: any = state.schools;
      const item_index = new_data.findIndex(
        (element: any) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return {
        ...state,
        loading: false,
        schools: new_data,
      };

    default:
      return state;
  }
};

export default SchoolReducer;
