import {
  GET_CLASS_LOADING,
  GET_CLASS_ERROR,
  GET_CLASS_SUCCESS,
  ClassDispatchTypes,
  ADD_CLASS_LOADING,
  ADD_CLASS_ERROR,
  ADD_CLASS_SUCCESS,
  DELETE_CLASS_LOADING,
  DELETE_CLASS_ERROR,
  DELETE_CLASS_SUCCESS,
  UPDATE_CLASS_LOADING,
  UPDATE_CLASS_ERROR,
  UPDATE_CLASS_SUCCESS,
  ClassTypeI,
  CLASS_STUDENTS_COUNT_LOADING,
  CLASS_STUDENTS_COUNT_ERROR,
  CLASS_STUDENTS_COUNT_SUCCESS,
} from "../../actions/Academics/Class/ClassActionTypes";

interface InitialStateI {
  loading: boolean;
  classes: ClassTypeI[];
  recent?: boolean;
  errors?: any;
  add_or_update: boolean;
  student_count: {
    [key: string]: number;
  } | null;
}

const initialState: InitialStateI = {
  loading: false,
  classes: [],
  errors: null,
  recent: false,
  add_or_update: false,
  student_count: null,
};

const ClassReducer = (
  state: InitialStateI = initialState,
  action: ClassDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_CLASS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_CLASS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_CLASS_SUCCESS:
      return {
        ...state,
        loading: false,
        classes: action.payload,
        recent: false,
        errors: null,
      };

    case ADD_CLASS_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case ADD_CLASS_ERROR:
      return {
        ...state,
        loading: false,
        recent: false,
        errors: action.payload,
        add_or_update: true,
      };

    case ADD_CLASS_SUCCESS:
      return {
        ...state,
        loading: false,
        classes: [...state.classes, action.payload],
        errors: null,
        recent: true,
        add_or_update: true,
      };

    case UPDATE_CLASS_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case UPDATE_CLASS_ERROR:
      return {
        ...state,
        loading: false,
        recent: false,
        errors: action.payload,
        add_or_update: true,
      };

    case UPDATE_CLASS_SUCCESS:
      const current_data: ClassTypeI[] = state.classes;
      const index: number = current_data.findIndex(
        (element) => element.id === action.payload.id
      );
      current_data[index] = action.payload;

      return {
        ...state,
        loading: false,
        classes: current_data,
        errors: null,
        recent: true,
        add_or_update: true,
      };

    case DELETE_CLASS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_CLASS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_CLASS_SUCCESS:
      const new_data: ClassTypeI[] = state.classes;
      const item_index = new_data.findIndex(
        (element) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return {
        ...state,
        loading: false,
        classes: new_data,
      };

    case CLASS_STUDENTS_COUNT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case CLASS_STUDENTS_COUNT_ERROR:
      return {
        ...state,
        loading: false,
        student_count: null,
      };

    case CLASS_STUDENTS_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        student_count: action.payload,
      };

    default:
      return state;
  }
};

export default ClassReducer;
