import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Grid, IconButton, Menu, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { DirectionsBusOutlined, Note } from '@material-ui/icons';
import { IoIosKeypad } from 'react-icons/io';
import SettingsIcon from '@material-ui/icons/Settings';
import { SmsOutlined } from '@material-ui/icons';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import { GiTeacher } from 'react-icons/gi';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popoverMenu: {
      overflow: 'initial',
      marginTop: '48px',
      '& .MuiMenu-paper': { width: '350px!important' },
      '&  ul': { paddingTop: '0px!important', paddingBottom: '0px!important' },
      '& > .MuiPaper-root.MuiMenu-paper.MuiPopover-paper': {
        overflow: 'initial',
        position: 'relative',
        boxShadow: '1px 1px 25px -4px rgb(0 0 0 / 17%)',
      },

      '& .MuiIconButton-root': {
        borderRadius: '8px',
      },
    },
    link: {
      color: '#1976d2',
      textDecoration: 'none',

      '&:hover': {
        textDecoration: 'underline',
      },
    },
  })
);

const AdminQuickLinksI = [
  {
    name: 'Transportation',
    icon: DirectionsBusOutlined,
    href: '/routes',
    desc: 'Schedule Your Route And Time',
  },
  {
    name: 'Scholarship',
    icon: MonetizationOnOutlinedIcon,
    href: '/scholarship-type',
    desc: 'Enlish Students For Available Scholarships',
  },
  {
    name: 'Finance',
    icon: MonetizationOnOutlinedIcon,
    href: '/collect-fees',
    desc: 'Manage Expenditure And Fees',
  },
  {
    name: 'Settings',
    icon: SettingsIcon,
    href: '/session',
    desc: 'Change Your Settings',
  },
  {
    name: 'Annoucements',
    icon: SmsOutlined,
    href: '/communication',
    desc: 'Notices For Notable Events',
  },
];

const TeacherQuickLinks = [
  {
    name: 'Live Classes',
    icon: GiTeacher,
    href: '/online-classes',
    desc: 'Live Classes',
  },
  {
    name: 'Enote',
    icon: Note,
    href: '/enote',
    desc: 'Enote',
  },
];

const StyledContainer = styled(Box)`
  padding: 16px;
`;

const StyledTitleHead = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledTitle = styled(Typography)`
  font-weight: 500;
  opacity: 0.65;

  &.MuiTypography-gutterBottom {
    margin-bottom: 8px;
  }
`;

const StyledItemContainer = styled(Link)`
  color: inherit;
  display: flex;
  margin-bottom: 16px;
  text-decoration: none;
  transition: 0.3s all ease;

  &:hover {
    opacity: 0.6;
  }

  &:last-child {
    margin-bottom: 0px;
  }
`;

const StyledIconContainer = styled(Box)`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(25, 25, 255, 0.2);
  margin-right: 8px;
  color: dodgerblue;
`;

const StyledTextContainer = styled(Box)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledItemTitle = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
`;

const StyledItemDescription = styled(Typography)`
  opacity: 0.6;
`;

const QuickLinksPopover = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<any>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const getUserQuickLinks = (role: string | null) => {
    if (role === 'Administrator') {
      return AdminQuickLinksI;
    } else if (role === 'Teacher') {
      return TeacherQuickLinks;
    }

    return [];
  };

  const role = localStorage.getItem('role');

  return (
    <div>
      <IconButton color="inherit" onClick={handleClick}>
        <IoIosKeypad />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.popoverMenu}
        elevation={1}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <StyledContainer onClick={handleClose}>
          {role === 'Administrator' && (
            <>
              <StyledTitleHead>
                <StyledTitle
                  color="textSecondary"
                  variant="subtitle1"
                  gutterBottom
                >
                  Switch To
                </StyledTitle>

                <IconButton size="small" onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </StyledTitleHead>

              <StyledItemContainer to="/library">
                <StyledIconContainer>
                  <SettingsIcon />
                </StyledIconContainer>

                <StyledTextContainer>
                  <StyledItemTitle>Hamro Library</StyledItemTitle>
                </StyledTextContainer>
              </StyledItemContainer>
            </>
          )}

          <StyledTitle color="textSecondary" variant="subtitle1" gutterBottom>
            Discover
          </StyledTitle>

          <Box>
            {getUserQuickLinks(role).map((link: any) => {
              const ItemIcon = link.icon;
              return (
                <StyledItemContainer to={link.href} key={link.href}>
                  <StyledIconContainer>
                    <ItemIcon />
                  </StyledIconContainer>

                  <StyledTextContainer>
                    <StyledItemTitle>{link.name}</StyledItemTitle>
                    <StyledItemDescription>{link.desc}</StyledItemDescription>
                  </StyledTextContainer>
                </StyledItemContainer>
              );
            })}
          </Box>
        </StyledContainer>
      </Menu>
    </div>
  );
};

export default QuickLinksPopover;
