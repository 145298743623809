// ---------------------------- <START> module imports start ----------------------------------//
import React, { useState, useEffect } from 'react';
import { Paper, TableCell } from '@material-ui/core';
import { useTableStyles } from '../../Styles/TableStyles';
import { useTable } from '../../Reusable';
import cx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import CustomizedDialogs from '../../Reusable/Dialogs/DeleteDialog';
import Popups from '../../Reusable/Dialogs/Popups';
import MeetingRequestView from './MeetingRequestView';

import {
  DeleteMeeting,
  GetMeetings,
  MeetingApproveOrDecline,
} from '../../../actions/HumanResource/Meeting/MeetingAction';
import { MeetingTypeI } from '../../../actions/HumanResource/Meeting/MeetingActionTypes';
import GetStatus from '../../../common/GetStatus';
import formatDate from '../../utils/formatDate';
import { HeadCellsI } from '../../../actions';
import {
  ItemDeleteButton,
  ItemViewButton,
} from '../../Reusable/Buttons/TableButton';
import getFullName from '../../utils/getFullName';
import { PRIMARY_DATE_FORMAT } from '../../../common/constant';
import { dateConverterAdToBs } from '../../utils/dateConverter';
import { GetMeetingTypes } from '../../../actions/HumanResource/MeetingType/MeetingTypeAction';
// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: 'sn', label: 'S.N' },
  // { id: 'name', label: 'Staff Name' },
  { id: 'meeting_type', label: 'Meeting Type' },
  { id: 'applied_date', label: 'Applied Date' },
  { id: 'meet_date', label: 'Meet Date' },
  { id: 'status', label: 'Status' },
  { id: 'action', label: 'Action' },
];

// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const MeetingList = () => {
  const classes = useTableStyles();
  const dispatch = useDispatch();
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  // States declaration
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [tableData, setTableData] = useState<MeetingTypeI[]>([]);
  const [item, setItem] = useState([]);

  //========================= <START> REDUX HOOKS <START> ==============================//
  const meetingSelector = useSelector((state: RootStore) => state.meetings);

  useEffect(() => {
    dispatch(GetMeetingTypes());
    dispatch(GetMeetings());
  }, []);

  useEffect(() => {
    // Development purpose only
    // setLoading(true);
    // setTimeout(() => {
    //   const items = tempData;
    //   setLoading(false);
    //   setTableData(items);
    // }, 2000);

    const items = formatData(meetingSelector.mettings);
    setLoading(meetingSelector.loading);
    setTableData(items);
  }, [meetingSelector]);

  const formatData = (items: any) => {
    return items.map((item: any) => ({
      id: item.id,
      name: getFullName(item.staff_first_name, item.staff_last_name),
      meeting_type: item.meeting_type.name,
      applied_date: dateConverterAdToBs(item.created_on),
      meet_date: dateConverterAdToBs(item.meet_date),
      description: item.description,
      designation: item.designation,
      status: item.status,
    }));
  };

  const handleDeleteModal = (id: string) => {
    setItemId(id);
    setDeleteModalOpen(true);
  };

  const handleViewModal = (item: any) => {
    setItem(item);
    setItemId(item.id);
    setOpenPopup(true);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteMeeting(itemId));
    setDeleteModalOpen(false);
  };

  const onActionConfirmed = (confirmed: boolean, status: string) => {
    const data = {
      id: itemId,
      status: status,
    };
    confirmed && itemId != null && dispatch(MeetingApproveOrDecline(data));
    setOpenPopup(false);
  };

  const handleClose = (value: boolean) => {
    setOpenPopup(value);
  };

  const getCustomTableRow = (item: any, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index}
        </StyledTableCell>
        {/* <StyledTableCell align="center" className={classes.cell}>
          {item.name}
        </StyledTableCell> */}
        <StyledTableCell align="center" className={classes.cell}>
          {item.meeting_type}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {formatDate(item.applied_date)}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {formatDate(item.meet_date)}
        </StyledTableCell>
        <StyledTableCell align="left" className={classes.cell}>
          <GetStatus status={item.status} />
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ItemViewButton onClick={() => handleViewModal(item)} />
          <ItemDeleteButton
            disabled={item.status !== 'P'}
            onClick={() => handleDeleteModal(item.id)}
          />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <Paper className={cx(classes.root, classes.attachFormTableRoot)}>
      <TableContainer
        label="Meeting List"
        loading={loading}
        items={tableData}
        headCells={headCells}
        rowsPerPage={4}
        getCustomTableRow={getCustomTableRow}
        search_param="name"
      />

      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
      />
      <Popups
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="sm"
        title="Meeting Request"
        colored
      >
        <MeetingRequestView
          onActionConfirmed={onActionConfirmed}
          meeting={item}
        />
      </Popups>
    </Paper>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default MeetingList;
