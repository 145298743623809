import {
  GET_MEETING_TYPE_LOADING,
  GET_MEETING_TYPE_ERROR,
  GET_MEETING_TYPE_SUCCESS,
  ADD_MEETING_TYPE_LOADING,
  ADD_MEETING_TYPE_ERROR,
  ADD_MEETING_TYPE_SUCCESS,
  DELETE_MEETING_TYPE_LOADING,
  DELETE_MEETING_TYPE_ERROR,
  DELETE_MEETING_TYPE_SUCCESS,
  UPDATE_MEETING_TYPE_LOADING,
  UPDATE_MEETING_TYPE_ERROR,
  UPDATE_MEETING_TYPE_SUCCESS,
  MeetingTypeTypeI,
  MeetingTypeDispatchTypes,
} from "../../actions/HumanResource/MeetingType/MeetingTypeActionTypes";

interface InitialStateI {
  loading: boolean;
  meeting_types: MeetingTypeTypeI[];
  add_or_update: boolean;
}

const initialState: InitialStateI = {
  loading: false,
  meeting_types: [],
  add_or_update: false,
};

const MeetingTypeReducer = (
  state: InitialStateI = initialState,
  action: MeetingTypeDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_MEETING_TYPE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_MEETING_TYPE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_MEETING_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        meeting_types: action.payload,
      };

    case ADD_MEETING_TYPE_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case ADD_MEETING_TYPE_ERROR:
      return {
        ...state,
        loading: false,
        add_or_update: true,
      };

    case ADD_MEETING_TYPE_SUCCESS:
      return {
        loading: false,
        meeting_types: [...state.meeting_types, action.payload],
        add_or_update: true,
      };

    case UPDATE_MEETING_TYPE_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case UPDATE_MEETING_TYPE_ERROR:
      return {
        ...state,
        loading: false,
        add_or_update: true,
      };

    case UPDATE_MEETING_TYPE_SUCCESS:
      const current_data: MeetingTypeTypeI[] = state.meeting_types;
      const index: number = current_data.findIndex(
        (element) => element.id === action.payload.id
      );
      current_data[index] = action.payload;

      return {
        loading: false,
        meeting_types: current_data,
        add_or_update: true,
      };

    case DELETE_MEETING_TYPE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_MEETING_TYPE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_MEETING_TYPE_SUCCESS:
      const new_data: MeetingTypeTypeI[] = state.meeting_types;
      const item_index = new_data.findIndex(
        (element) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return {
        ...state,
        loading: false,
        meeting_types: new_data,
      };

    default:
      return state;
  }
};

export default MeetingTypeReducer;
