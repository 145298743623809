import React, { useState } from "react";
import AppliedStudentFees from "./AppliedStudentFees";
import AppliedFeeStudents from "./AppliedFeeStudents";
import SearchAppliedFees from "./SearchAppliedFees";
import { useSelector } from "react-redux";
import { RootStore } from "../../../store";
import BackDropLoader from "../../Reusable/BackDropLoader";

const AppliedFees = () => {
  const [applyDiscountTo, setApplyDiscountTo] = useState<string>("");

  const AppliedFeeLoading = useSelector(
    (state: RootStore) => state.appliedFees.loading
  );
  const ClassLoading = useSelector((state: RootStore) => state.class.loading);
  const CategoryLoading = useSelector(
    (state: RootStore) => state.categories.loading
  );
  const StudentLoading = useSelector(
    (state: RootStore) => state.wholeData.loading
  );
  const FeeLoading = useSelector((state: RootStore) => state.fee_type.loading);

  return (
    <>
      <SearchAppliedFees
        applyDiscountTo={(value) => setApplyDiscountTo(value)}
      />
      {applyDiscountTo === "fee" ? (
        <AppliedFeeStudents />
      ) : (
        <AppliedStudentFees />
      )}
      <BackDropLoader
        open={
          AppliedFeeLoading ||
          ClassLoading ||
          CategoryLoading ||
          StudentLoading ||
          FeeLoading
        }
      />
    </>
  );
};

export default AppliedFees;
