import { Paper, Grid } from "@material-ui/core";
import React, { useState } from "react";
import { ClassTypeI } from "../../actions/Academics/Class/ClassActionTypes";
import ClassRoomTabs from "../../components/ClassRoom/Components/ClassRoomTabs";
import TeacherAvatar from "../../components/ClassRoom/Components/TeacherAvatar";
import TeacherRoomTabs from "../../components/ClassRoom/Components/TeacherRoomTabs";
import ClassLayout from "../../components/ClassRoom/Layout/ClassLayout";
import ClassRoomSidebar from "../../components/ClassRoom/Layout/ClassRoomSidebar";

const TeacherRoomPage = () => {
  const [classItem, setClassItem] = useState<ClassTypeI | null>(null);

  const onClassItem = (value: ClassTypeI) => {
    setClassItem(value);
  };

  const nav = <ClassRoomSidebar onClassItem={onClassItem} />;
  return (
    <>
      <Paper
        style={{
          marginTop: "110px",
          padding: "20px",
          minHeight: "80vh",
        }}
      >
        <ClassLayout sideNav={nav}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {/* <TeacherAvatar classItem={classItem} /> */}
              <TeacherRoomTabs teacher classItem={classItem} />
            </Grid>
          </Grid>
        </ClassLayout>
      </Paper>
    </>
  );
};

export default TeacherRoomPage;
