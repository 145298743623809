import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Paper } from "@material-ui/core";
import { useFormStyles } from "../../Styles/FormStyles";
import { RowSelect } from "../../Reusable/Inputs/Select";
import { useForm } from "react-hook-form";
import { GetClasses } from "../../../actions/Academics/Class/ClassAction";
import { RootStore } from "../../../store";
import { ClassTypeI } from "../../../actions/Academics/Class/ClassActionTypes";
import { Tupple } from "../../../actions";
import { MONTH_CHOICES } from "../../../common/json.constant";
import { GetDates } from "../../../actions/Dates/DatesAction";
import {
  dateConverterAdToBs,
  dateConverterBsToAd,
} from "../../utils/dateConverter";

interface FormI {
  month_name: string | null;
  grade: string;
  section: string;
}

interface SectionI {
  id: string;
  name: string;
}

const SearchForm = (props: any) => {
  const classes = useFormStyles();
  const { register, errors, setError, clearErrors } = useForm<FormI>();
  const dispatch = useDispatch();

  // --------------------State declaration start--------------------//
  const [gradeChoices, setGradeChoices] = useState<ClassTypeI[]>([]);
  const [selectedGrade, setSelectedGrade] = useState<ClassTypeI | null>(null);
  const [sectionChoices, setSectionChoices] = useState<SectionI[] | []>([]);
  const [selectedSection, setSelectedSection] = useState<SectionI | null>(null);
  const [sectionDisabler, setSectionDisabler] = useState<boolean>(true);
  const [month, setMonth] = useState<Tupple | null>(null);
  const [currentMonth, setCurrentMonth] = useState<number | null>(null);
  // --------------------State declaration end--------------------//

  // -------------------------Selector start-------------------------//
  const classState = useSelector((state: RootStore) => state.class);
  // -------------------------Selector end-------------------------//

  // -------------------------UseEffect start-------------------------//
  useEffect(() => {
    dispatch(GetClasses());
  }, []);

  useEffect(() => {
    const temp: any = classState.classes.map((classItem) => ({
      ...classItem,
      value: classItem.grade_name,
      section: classItem.section.map((sectionItem) => ({
        ...sectionItem,
        value: sectionItem.name,
      })),
    }));
    setGradeChoices(temp);
  }, [classState.classes]);

  useEffect(() => {
    const utc = new Date().toJSON().slice(0, 10);
    setCurrentMonth(parseInt(dateConverterAdToBs(utc).substring(5, 7)) - 1);
    dispatch(GetDates(parseInt(dateConverterAdToBs(utc).substring(5, 7))));
  }, []);

  useEffect(() => {
    if (currentMonth != null) {
      dispatch(GetDates(currentMonth + 1));
      setMonth(MONTH_CHOICES[currentMonth]);
    }
  }, [currentMonth]);
  // -------------------------UseEffect end-------------------------//

  const handleGradeChange = (value: ClassTypeI | null) => {
    clearErrors("grade");
    setSelectedGrade(value);
    setSelectedSection(null);
    value != null
      ? populateSectionChoices(value.section)
      : setSectionDisabler(true);
  };

  const handleSectionChange = (value: any | null) => {
    setSelectedSection(value);
    clearErrors("section");
  };

  const populateSectionChoices = (sections: SectionI[] | []) => {
    setSectionDisabler(false);
    sections.length > 0
      ? setSectionChoices(sections)
      : setSectionDisabler(true);
  };

  const handleMonthChange = (value: Tupple) => {
    if (value != null) {
      setMonth(value);
      dispatch(GetDates(value.key));
    } else {
      setMonth(null);
    }
  };

  return (
    <>
      <Paper>
        <Grid container spacing={3}>
          <Grid item xs={3} className={classes.formWrapperRow}>
            <RowSelect
              fullWidth
              label="Grade"
              name="grade"
              options={gradeChoices}
              value={selectedGrade}
              setValue={handleGradeChange}
              error={errors["grade"] && errors["grade"].message}
              inputRef={register({ required: true })}
              placeholder="Label"
            />
          </Grid>
          <Grid item xs={3} className={classes.formWrapperRow}>
            <RowSelect
              fullWidth
              label="Section"
              name="section"
              options={sectionChoices}
              value={selectedSection}
              setValue={handleSectionChange}
              error={errors["section"] && errors["section"].message}
              inputRef={register({ required: true })}
              placeholder="Label"
            />
          </Grid>
          <Grid item xs={3} className={classes.formWrapperRow}>
            <RowSelect
              fullWidth
              label="Month"
              name="month_name"
              options={MONTH_CHOICES}
              value={month}
              setValue={handleMonthChange}
              error={errors["month_name"] && errors["month_name"].message}
              inputRef={register({ required: true })}
              placeholder="Label"
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default SearchForm;
