import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_CLASS_TEACHER_LOADING,
  GET_CLASS_TEACHER_ERROR,
  GET_CLASS_TEACHER_SUCCESS,
  ClassTeacherDispatchTypes,
  ADD_CLASS_TEACHER_LOADING,
  ADD_CLASS_TEACHER_SUCCESS,
  ADD_CLASS_TEACHER_ERROR,
  DELETE_CLASS_TEACHER_SUCCESS,
  DELETE_CLASS_TEACHER_ERROR,
  DELETE_CLASS_TEACHER_LOADING,
  UPDATE_CLASS_TEACHER_LOADING,
  UPDATE_CLASS_TEACHER_SUCCESS,
  UPDATE_CLASS_TEACHER_ERROR,
} from "./ClassTeacherActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { StringChain } from "lodash";

interface RequestDataI {
  grade: string;
  section: string;
  teacher: string;
}

export const GetClassTeachers =
  () =>
  async (dispatch: Dispatch<ClassTeacherDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_CLASS_TEACHER_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/class_teacher/`,
        HeaderConfig()
      );

      dispatch({
        type: GET_CLASS_TEACHER_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_CLASS_TEACHER_ERROR,
      });
    }
  };

export const AddClassTeacher =
  (data: RequestDataI) =>
  async (dispatch: Dispatch<ClassTeacherDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_CLASS_TEACHER_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/class_teacher/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_CLASS_TEACHER_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Class Teacher Added Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: ADD_CLASS_TEACHER_ERROR,
        payload: error.response && error.response.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: error.response.data.error[0],
        },
      });
    }
  };

export const UpdateClassTeacher =
  (id: string, data: RequestDataI) =>
  async (dispatch: Dispatch<ClassTeacherDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_CLASS_TEACHER_LOADING,
      });

      const res = await axios.put(
        `${ADMIN_API_URL}/class_teacher/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_CLASS_TEACHER_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Class Teacher Updated Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: UPDATE_CLASS_TEACHER_ERROR,
        payload: error.response && error.response.data,
      });
      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: error.response.data.error[0],
        },
      });
    }
  };

export const DeleteClassTeacher =
  (id: string) =>
  async (dispatch: Dispatch<ClassTeacherDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_CLASS_TEACHER_LOADING,
      });

      const res = await axios.delete(
        `${ADMIN_API_URL}/class_teacher/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_CLASS_TEACHER_SUCCESS,
        payload: { id: id },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Class Teacher Deleted Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_CLASS_TEACHER_ERROR,
      });
    }
  };
