// ---------------- <START> module import ------------------//
import React, { useState, useEffect } from "react";
import { Paper, Typography } from "@material-ui/core";
import { useTable } from "../../Reusable";
import { useTableStyles } from "../../Styles/TableStyles";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import {
  GetDepartments,
  DeleteDepartment,
} from "../../../actions/HumanResource/Department/DepartmentAction";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import { DepartmentTypeI } from "../../../actions/HumanResource/Department/DepartmentActionTypes";
import { HeadCellsI } from "../../../actions";
import {
  ItemDeleteButton,
  ItemEditButton,
} from "../../Reusable/Buttons/TableButton";
import useLoading from "../../../useHook/useLoading";
// ---------------- <END> module import ------------------//

// ---------------- <START> Interface ------------------//
interface DepartmentTableProps {
  onEditData: (value: DepartmentTypeI) => void;
}

// ---------------- <END> Interface ------------------//

// ---------------- <START> Head Cells ------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N" },
  { id: "name", label: "Department Name" },
  { id: "action", label: "Action" },
];
// ---------------- <START> Head Cells ------------------//

// ---------------- <START> Department Table Component ------------------//
const DepartmentTable = (props: DepartmentTableProps) => {
  const { onEditData } = props;

  const classes = useTableStyles();
  const dispatch = useDispatch();

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  const [tableData, setTableData] = useState<DepartmentTypeI[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);
  const departmentState = useSelector((state: RootStore) => state.department);

  useEffect(() => {
    dispatch(GetDepartments());
  }, []);

  useEffect(() => {
    const items = departmentState.departments.map((item: any) => ({
      id: item.id,
      name: item.name,
    }));
    setTableData(items);
  }, [departmentState]);

  const handleEditClicked = (data: DepartmentTypeI) => {
    onEditData(data);
  };

  const handleDeleteModal = (value: boolean, id: string) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteDepartment(itemId));
    setDeleteModalOpen(false);
  };

  const getCustomTableRow = (item: DepartmentTypeI, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ItemEditButton onClick={() => handleEditClicked(item)} />
          <ItemDeleteButton onClick={() => handleDeleteModal(true, item.id)} />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <Paper className={classes.root}>
      <Typography variant="h6">Department List</Typography>

      <TableContainer
        label="Department"
        loading={departmentState.loading}
        items={tableData}
        headCells={headCells}
        getCustomTableRow={getCustomTableRow}
        search_param="name"
      />

      {/* Delete modal dialog */}
      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
      />
    </Paper>
  );
};
// ---------------- <START> Department Table Component ------------------//

export default DepartmentTable;
