import React, { useState } from "react";
import { BusUserList, SearchRoute } from "../../components";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";
import TransportSidebar from "../../components/Transport/TransportSidebar";

interface SearchDataI {
  grade: string;
  section: string;
}

export default function BusUserPage() {
  const [searchParam, setSearchParam] = useState<SearchDataI | null>(null);

  const handleSearch = (data: SearchDataI) => {
    setSearchParam(data);
  };

  const nav = <TransportSidebar bus_user />;
  return (
    <SubModuleLayout sideNav={nav}>
      {/* <SearchRoute onSearch={handleSearch} /> */}
      <BusUserList searchTitle={searchParam} />
    </SubModuleLayout>
  );
}
