import React, { useState } from 'react';
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import ClassLayout from '../../components/ClassRoom/Layout/ClassLayout';
import ClassRoomSidebar from '../../components/ClassRoom/Layout/ClassRoomSidebar';
import { useStyles } from '../../components/Styles/FormStyles';
import ClassRoomTabs from '../../components/ClassRoom/Components/ClassRoomTabs';
import TeacherRoom from '../../components/ClassRoom/Components/TeacherRoom';
import { ClassTypeI } from '../../actions/Academics/Class/ClassActionTypes';
import { SectionTypeI } from '../../actions/Academics/Section/SectionActionTypes';
import SectionField from '../../components/ClassRoom/Components/SectionField';
import DashImg from '../../assets/images/ParentDash.jpg';
import { ArrowBack } from '@material-ui/icons';
import { Link } from 'react-router-dom';

const gpsTracking = () => {
  const [classItem, setClassItem] = useState<ClassTypeI | null>(null);

  const onClassItem = (value: ClassTypeI) => {
    setClassItem(value);
  };

  const nav = <ClassRoomSidebar onClassItem={onClassItem} />;

  return (
    <div style={{ marginTop: '110px', minHeight: '80vh' }}>
      <Grid container>
        <Grid item xs justifyContent="flex-end">
          <Typography
            variant="h2"
            style={{ marginLeft: '40px', marginTop: '50px', color: 'black' }}
          >
            You must install GPS Tracking device on your vehicle.
          </Typography>
          <Typography
            variant="h3"
            style={{ marginLeft: '40px', marginTop: '20px', color: 'black' }}
          >
            Track as you go!
          </Typography>
        </Grid>
        <Grid item xs>
          <img src="https://image.made-in-china.com/202f0j00jMofAzysiCcn/4G-Mini-GPS-Tracking-Device-Cut-off-Engine-Ota-Function-Cut-off-Engine-Waterproof-IP67-Motorcycle-GPS-GT48-KH-.jpg" />
        </Grid>
      </Grid>
    </div>
  );
};

export default gpsTracking;
