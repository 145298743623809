export const GET_ID_CARD_LOADING = "GET_ID_CARD_LOADING";
export const GET_ID_CARD_ERROR = "GET_ID_CARD_ERROR";
export const GET_ID_CARD_SUCCESS = "GET_ID_CARD_SUCCESS";

export const GET_MULTIPLE_ID_CARD_LOADING = "GET_MULTIPLE_ID_CARD_LOADING";
export const GET_MULTIPLE_ID_CARD_ERROR = "GET_MULTIPLE_ID_CARD_ERROR";
export const GET_MULTIPLE_ID_CARD_SUCCESS = "GET_MULTIPLE_ID_CARD_SUCCESS";

export const GET_MULTIPLE_STAFF_ID_CARD_LOADING =
  "GET_MULTIPLE_STAFF_ID_CARD_LOADING";
export const GET_MULTIPLE_STAFF_ID_CARD_ERROR =
  "GET_MULTIPLE_STAFF_ID_CARD_ERROR";
export const GET_MULTIPLE_STAFF_ID_CARD_SUCCESS =
  "GET_MULTIPLE_STAFF_ID_CARD_SUCCESS";

//===============================<START>GET ID_CARD<START>======================//

export interface GetIdCardLoading {
  type: typeof GET_ID_CARD_LOADING;
}

export interface GetIdCardError {
  type: typeof GET_ID_CARD_ERROR;
}

export interface GetIdCardSuccess {
  type: typeof GET_ID_CARD_SUCCESS;
  payload: any;
}

export interface GetMultipleIdCardLoading {
  type: typeof GET_MULTIPLE_ID_CARD_LOADING;
}

export interface GetMultipleIdCardError {
  type: typeof GET_MULTIPLE_ID_CARD_ERROR;
}

export interface GetMultipleIdCardSuccess {
  type: typeof GET_MULTIPLE_ID_CARD_SUCCESS;
  payload: any;
}

export interface GetMultipleStaffIdCardLoading {
  type: typeof GET_MULTIPLE_STAFF_ID_CARD_LOADING;
}

export interface GetMultipleStaffIdCardError {
  type: typeof GET_MULTIPLE_STAFF_ID_CARD_ERROR;
}

export interface GetMultipleStaffIdCardSuccess {
  type: typeof GET_MULTIPLE_STAFF_ID_CARD_SUCCESS;
  payload: any;
}

//===============================<END>GET ID_CARD<END>======================//

//===============================<END>DELETE ID_CARD<END>======================//

export type IdCardDispatchTypes =
  | GetIdCardLoading
  | GetIdCardError
  | GetIdCardSuccess
  | GetMultipleIdCardLoading
  | GetMultipleIdCardError
  | GetMultipleIdCardSuccess
  | GetMultipleStaffIdCardLoading
  | GetMultipleStaffIdCardError
  | GetMultipleStaffIdCardSuccess;
