export const GET_GUARDIAN_LOADING = "GET_GUARDIAN_LOADING";
export const GET_GUARDIAN_ERROR = "GET_GUARDIAN_ERROR";
export const GET_GUARDIAN_SUCCESS = "GET_GUARDIAN_SUCCESS";

export const GUARDIAN_BY_ID_LOADING = "GUARDIAN_BY_ID_LOADING";
export const GUARDIAN_BY_ID_ERROR = "GUARDIAN_BY_ID_ERROR";
export const GUARDIAN_BY_ID_SUCCESS = "GUARDIAN_BY_ID_SUCCESS";

export const ADD_GUARDIAN_LOADING = "ADD_GUARDIAN_LOADING";
export const ADD_GUARDIAN_ERROR = "ADD_GUARDIAN_ERROR";
export const ADD_GUARDIAN_SUCCESS = "ADD_GUARDIAN_SUCCESS";

export const UPDATE_GUARDIAN_LOADING = "UPDATE_GUARDIAN_LOADING";
export const UPDATE_GUARDIAN_ERROR = "UPDATE_GUARDIAN_ERROR";
export const UPDATE_GUARDIAN_SUCCESS = "UPDATE_GUARDIAN_SUCCESS";

export const DELETE_GUARDIAN_LOADING = "DELETE_GUARDIAN_LOADING";
export const DELETE_GUARDIAN_ERROR = "DELETE_GUARDIAN_ERROR";
export const DELETE_GUARDIAN_SUCCESS = "DELETE_GUARDIAN_SUCCESS";

export const GET_CHILDREN_LOADING = "GET_CHILDREN_LOADING";
export const GET_CHILDREN_ERROR = "GET_CHILDREN_ERROR";
export const GET_CHILDREN_SUCCESS = "GET_CHILDREN_SUCCESS";

export const UPDATE_GUARDIAN_USERNAME_LOADING =
  "UPDATE_GUARDIAN_USERNAME_LOADING";
export const UPDATE_GUARDIAN_USERNAME_SUCCESS =
  "UPDATE_GUARDIAN_USERNAME_SUCCESS";
export const UPDATE_GUARDIAN_USERNAME_ERROR = "UPDATE_GUARDIAN_USERNAME_ERROR";

interface GuardianI {
  id: string;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  general_info: string;
}
interface SecondaryGuardianI {
  id: string;
  relation: string;
  full_name: string;
  address: string;
  phone_number: string;
}
export type GuardianTypeI = {
  id: string;
  guardian_user: GuardianI[];
  address: string;
  relation: string;
  photo: string;
  phone_number: string;
  occupation: string;
  secondary_guardian: SecondaryGuardianI[];
  general_info: string;
  created_by: number;
};

//===============================<START>GET DEPARTMENT<START>======================//

export interface GetGuardianLoading {
  type: typeof GET_GUARDIAN_LOADING;
}

export interface GetGuardianError {
  type: typeof GET_GUARDIAN_ERROR;
}

export interface GetGuardianSuccess {
  type: typeof GET_GUARDIAN_SUCCESS;
  payload: any;
}

//===============================<END>GET DEPARTMENT<END>======================//

//===============================<START>STUDENT LIST<START>======================//

export interface GuardianByIdLoading {
  type: typeof GUARDIAN_BY_ID_LOADING;
}

export interface GuardianByIdError {
  type: typeof GUARDIAN_BY_ID_ERROR;
}

export interface GuardianByIdSuccess {
  type: typeof GUARDIAN_BY_ID_SUCCESS;
  payload: any;
}

//===============================<END>STUDENT LIST<END>======================//

//===============================<START>ADD DEPARTMENT<START>======================//

export interface AddGuardianLoading {
  type: typeof ADD_GUARDIAN_LOADING;
}

export interface AddGuardianError {
  type: typeof ADD_GUARDIAN_ERROR;
  payload: any;
}

export interface AddGuardianSuccess {
  type: typeof ADD_GUARDIAN_SUCCESS;
  payload: any;
}

//===============================<END>ADD DEPARTMENT<END>======================//

//===============================<START>UPDATE DEPARTMENT<START>======================//

export interface UpdateGuardianLoading {
  type: typeof UPDATE_GUARDIAN_LOADING;
}

export interface UpdateGuardianError {
  type: typeof UPDATE_GUARDIAN_ERROR;
  payload: any;
}

export interface UpdateGuardianSuccess {
  type: typeof UPDATE_GUARDIAN_SUCCESS;
  payload: GuardianTypeI;
}

//===============================<END>UPDATE DEPARTMENT<END>======================//

//===============================<START>DELETE DEPARTMENT<START>======================//

export interface DeleteGuardianLoading {
  type: typeof DELETE_GUARDIAN_LOADING;
}

export interface DeleteGuardianError {
  type: typeof DELETE_GUARDIAN_ERROR;
}

export interface DeleteGuardianSuccess {
  type: typeof DELETE_GUARDIAN_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE DEPARTMENT<END>======================//

//===============================<START>GET CHILDREN<START>======================//

export interface GetChildrenLoading {
  type: typeof GET_CHILDREN_LOADING;
}

export interface GetChildrenError {
  type: typeof GET_CHILDREN_ERROR;
}

export interface GetChildrenSuccess {
  type: typeof GET_CHILDREN_SUCCESS;
  payload: any;
}

//===============================<END>GET CHILDREN<END>======================//

export interface UpdateGuardianUsernameLoading {
  type: typeof UPDATE_GUARDIAN_USERNAME_LOADING;
}

export interface UpdateGuardianUsernameError {
  type: typeof UPDATE_GUARDIAN_USERNAME_ERROR;
}

export interface UpdateGuardianUsernameSuccess {
  type: typeof UPDATE_GUARDIAN_USERNAME_SUCCESS;
}

export type GuardianDispatchTypes =
  | GetGuardianLoading
  | GetGuardianError
  | GetGuardianSuccess
  | AddGuardianLoading
  | AddGuardianError
  | AddGuardianSuccess
  | UpdateGuardianLoading
  | UpdateGuardianError
  | UpdateGuardianSuccess
  | DeleteGuardianLoading
  | DeleteGuardianError
  | DeleteGuardianSuccess
  | GuardianByIdLoading
  | GuardianByIdError
  | GuardianByIdSuccess
  | GetChildrenLoading
  | GetChildrenError
  | GetChildrenSuccess
  | UpdateGuardianUsernameLoading
  | UpdateGuardianUsernameSuccess
  | UpdateGuardianUsernameError;
