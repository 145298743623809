import React, { useState, useEffect } from "react";
import { BoxUpload, ImagePreview } from "./style";
import ImageIcon from "../../../../assets/images/image-icon.png";
import CloseIcon from "../../../../assets/images/CloseIcon.svg";

const UploadImage = (props: any) => {
  const { image, setImage, id } = props;
  console.log("img", image)

  const handleImageUpload = (e: any) => {
    if (e.target.files && e.target.files[0]) {
      // const reader = new FileReader();
      // reader.readAsDataURL(e.target.files[0]);
      // reader.onload = function (e) {
      //   setImage(e.target?.result as string);
      // };
      setImage(
        {
          picturePreview: URL.createObjectURL(e.target.files[0]),
          pictureAsFile: e.target.files[0]
        }
      );
    }
  };

  return (
    <>
      <BoxUpload>
        <div className="image-upload">
          {image === "" ? (
            <>
              <label htmlFor={id}>
                <img
                  src={ImageIcon}
                  draggable={"false"}
                  style={{ width: 100, height: 100 }}
                />
                <p style={{ color: "#444", marginTop: "20px" }}>
                  Click to select image
                </p>
              </label>

              <input
                id={id}
                type="file"
                accept=".jpg,.jpeg,.gif,.png,.mov, .pdf"
                onChange={handleImageUpload}
              />
            </>
          ) : (
            <ImagePreview>
              <img
                className="close-icon"
                src={CloseIcon}
                alt="CloseIcon"
                onClick={() => {
                  setImage({ picturePreview: "", pictureAsFile: null });
                }}
              />
              <img
                id="uploaded-image"
                src={image}
                draggable={true}
                alt="uploaded-img"
              />
            </ImagePreview>
          )}
        </div>
      </BoxUpload>
    </>
  );
};

export default UploadImage;
