export const EMAIL_SETTING_LOADING = "EMAIL_SETTING_LOADING";
export const EMAIL_SETTING_ERROR = "EMAIL_SETTING_ERROR";
export const EMAIL_SETTING_SUCCESS = "EMAIL_SETTING_SUCCESS";

export const ADD_EMAIL_SETTING_LOADING = "ADD_EMAIL_SETTING_LOADING";
export const ADD_EMAIL_SETTING_ERROR = "ADD_EMAIL_SETTING_ERROR";
export const ADD_EMAIL_SETTING_SUCCESS = "ADD_EMAIL_SETTING_SUCCESS";

export const UPDATE_EMAIL_SETTING_LOADING = "UPDATE_EMAIL_SETTING_LOADING";
export const UPDATE_EMAIL_SETTING_ERROR = "UPDATE_EMAIL_SETTING_ERROR";
export const UPDATE_EMAIL_SETTING_SUCCESS = "UPDATE_EMAIL_SETTING_SUCCESS";

export type EmailSettingType = {
  id: string;
  smtp_host: string;
  smtp_email: string;
  smtp_password: string;
  smtp_port: number | string;
  smtp_display_name: string;
  smtp_use_tls?: boolean | string;
};

//===============================<START>GET EMAIL_SETTING<START>======================//

export interface EmailSettingLoading {
  type: typeof EMAIL_SETTING_LOADING;
}

export interface EmailSettingError {
  type: typeof EMAIL_SETTING_ERROR;
}

export interface EmailSettingSuccess {
  type: typeof EMAIL_SETTING_SUCCESS;
  payload: EmailSettingType[];
}

//===============================<END>GET EMAIL_SETTING<END>======================//

//===============================<START>ADD EMAIL_SETTING<START>======================//

export interface AddEmailSettingLoading {
  type: typeof ADD_EMAIL_SETTING_LOADING;
}

export interface AddEmailSettingError {
  type: typeof ADD_EMAIL_SETTING_ERROR;
}

export interface AddEmailSettingSuccess {
  type: typeof ADD_EMAIL_SETTING_SUCCESS;
  payload: EmailSettingType[];
}

//===============================<END>ADD EMAIL_SETTING<END>======================//

//===============================<START>UPDATE EMAIL_SETTING<START>======================//

export interface UpdateEmailSettingLoading {
  type: typeof UPDATE_EMAIL_SETTING_LOADING;
}

export interface UpdateEmailSettingError {
  type: typeof UPDATE_EMAIL_SETTING_ERROR;
}

export interface UpdateEmailSettingSuccess {
  type: typeof UPDATE_EMAIL_SETTING_SUCCESS;
  payload: EmailSettingType[];
}

//===============================<END>UPDATE EMAIL_SETTING<END>======================//

//===============================<END>GET EMAIL_SETTING<END>======================//

export type EmailSettingDispatchTypes =
  | EmailSettingLoading
  | EmailSettingError
  | EmailSettingSuccess
  | AddEmailSettingLoading
  | AddEmailSettingError
  | AddEmailSettingSuccess
  | UpdateEmailSettingLoading
  | UpdateEmailSettingError
  | UpdateEmailSettingSuccess;
