import axios from 'axios';
import { Dispatch } from 'redux';

import {
  GET_EVENTS_LOADING,
  GET_EVENTS_ERROR,
  GET_EVENTS_SUCCESS,
  EventsDispatchTypes,
  ADD_EVENTS_LOADING,
  ADD_EVENTS_SUCCESS,
  ADD_EVENTS_ERROR,
  DELETE_EVENTS_SUCCESS,
  DELETE_EVENTS_ERROR,
  DELETE_EVENTS_LOADING,
  UPDATE_EVENTS_LOADING,
  UPDATE_EVENTS_SUCCESS,
  UPDATE_EVENTS_ERROR,
} from './EventsActionTypes';

import { SET_SNACKBAR, SetSnackBarI } from '../../SnackbarActionTypes';
import { HeaderConfig } from '../../Auth/Login/LoginAction';
import { ADMIN_API_URL } from '../../../config/SERVER_API_URLS';

interface RequestDataI {
  id: string;
  name: string;
}

interface GetEventsParamsI {
  fromDate: string;
  toDate: string;
}

export const GetEvents =
  (params?: GetEventsParamsI) =>
  async (dispatch: Dispatch<EventsDispatchTypes | SetSnackBarI>) => {
    console.log('params', params);
    try {
      dispatch({
        type: GET_EVENTS_LOADING,
      });

      let url = `${ADMIN_API_URL}/events/`;

      if (params) url += `?fromDate=${params.fromDate}&toDate=${params.toDate}`;

      const res = await axios.get(url, HeaderConfig());

      dispatch({
        type: GET_EVENTS_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_EVENTS_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "Eventss Loading Failed",
      //   },
      // });
    }
  };
export const AddEvent =
  (data: any) =>
  async (dispatch: Dispatch<EventsDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_EVENTS_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/events/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_EVENTS_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'success',
          snackbarMessage: 'Events Added Successfully',
        },
      });
    } catch (error: any) {
      dispatch({
        type: ADD_EVENTS_ERROR,
        payload: error.response && error.response?.data,
      });
    } finally {
      try {
        dispatch({
          type: GET_EVENTS_LOADING,
        });

        const res = await axios.get(`${ADMIN_API_URL}/events/`, HeaderConfig());

        dispatch({
          type: GET_EVENTS_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: GET_EVENTS_ERROR,
        });
      }
    }
  };

export const UpdateEvent =
  (id: string, data: any) =>
  async (dispatch: Dispatch<EventsDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_EVENTS_LOADING,
      });

      const res = await axios.put(
        `${ADMIN_API_URL}/events/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_EVENTS_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'success',
          snackbarMessage: 'Events Updated Successfully',
        },
      });
    } catch (error: any) {
      dispatch({
        type: UPDATE_EVENTS_ERROR,
        payload: error.response && error.response?.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: 'Events Update Failed',
        },
      });
    } finally {
      try {
        dispatch({
          type: GET_EVENTS_LOADING,
        });

        const res = await axios.get(`${ADMIN_API_URL}/events/`, HeaderConfig());

        dispatch({
          type: GET_EVENTS_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: GET_EVENTS_ERROR,
        });
      }
    }
  };

export const DeleteEvent =
  (id: string) =>
  async (dispatch: Dispatch<EventsDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_EVENTS_LOADING,
      });

      const res = await axios.delete(
        `${ADMIN_API_URL}/events/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_EVENTS_SUCCESS,
        payload: { id: id },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'success',
          snackbarMessage: 'Events Deleted Successfully',
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_EVENTS_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: 'Events Delete Failed',
        },
      });
    }
  };
