export const USER_LOGOUT_LOADING = "USER_LOGOUT_LOADING";
export const USER_LOGOUT_ERROR = "USER_LOGOUT_ERROR";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";

export interface LogoutLoading {
  type: typeof USER_LOGOUT_LOADING;
}

export interface LogoutError {
  type: typeof USER_LOGOUT_ERROR;
}

export interface LogoutSuccess {
  type: typeof USER_LOGOUT_SUCCESS;
}

export type LogoutDispatchTypes = LogoutLoading | LogoutError | LogoutSuccess;
