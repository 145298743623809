import {
  Box,
  Card,
  CardContent,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';

export const StyledStudentCard = styled(Card)`
  width: 100%;
  background-color: #eceef7;
`;

export const Content = styled(CardContent)`
  padding: 8px !important;
`;

export const Header = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px 4px 0px !important;
`;

export const Title = styled(Typography)`
  font-size: 16px;
  color: #132e98;

  & span {
    font-size: 2rem;
  }
`;

export const ListText = styled(Typography)`
  font-size: 14px;
  line-height: 1.8;

  & span {
    margin-left: 16px;
  }
`;

export const ProgressBar = styled(Box)`
  height: 30px;
  width: 100px;
  position: relative;
  top: 0;
  left: 0;
  padding: 0px;
  background: ${(props: any) =>
    props.progressColor === 'blue'
      ? `repeating-linear-gradient(
    45deg,
    #6072bc,
    #6072bc 10px,
    #465298 10px,
    #465298 20px
  )`
      : `repeating-linear-gradient(
    45deg,
    #bc6060,
    #bc6060 10px,
    #984646 10px,
    #984646 20px
  )`};
`;

export const Button = styled(({ children, ...restProps }: any) => (
  <IconButton {...restProps}>{children}</IconButton>
))``;

export const Text = styled(({ children, ...restProps }: any) => (
  <Typography {...restProps} variant="h6" component="h2">
    {children}
  </Typography>
))`
  font-weight: 400;
  font-size: 1.1rem;
`;
