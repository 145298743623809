import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  DeviceConfigI,
  EmployeesToSyncI,
  InitialStateI,
} from "./attendanceDevice";
import {
  createDeviceConfig,
  deleteDeviceConfig,
  getAttendanceDevices,
  getDeviceConfigs,
  getEmployeesToSync,
  getSingleAttendanceDevice,
  getSyncedDepartments,
  readDeviceConfig,
  syncAttendanceDevice,
  syncDepartmentsToDevice,
  syncDeviceConfig,
  testDeviceConfigConnection,
  updateDeviceConfig,
} from "./attendanceDeviceThunk";

const initialState: InitialStateI = {
  loading: false,
  actionPerformed: false,
  deviceConfigs: [],
  deviceConfig: null,
  attendanceDevicesList: [],
  attendanceDevice: null,
  syncedDepartments: [],
  employeesToSync: [],
  syncedEmployees: [],
};

const AttendanceDeviceSlice = createSlice({
  name: "attendanceDevice",
  initialState,
  reducers: {
    resetAttDeviceState: (state) => {
      state.loading = false;
      state.actionPerformed = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDeviceConfigs.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(getDeviceConfigs.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.deviceConfigs = payload;
    });
    builder.addCase(getDeviceConfigs.rejected, (state) => {
      state.loading = false;
      state.deviceConfigs = [];
    });
    /////////////////////////////////////////////////////////////////////
    builder.addCase(createDeviceConfig.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(createDeviceConfig.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.actionPerformed = true;
    });
    builder.addCase(createDeviceConfig.rejected, (state) => {
      state.loading = false;
    });
    /////////////////////////////////////////////////////////////////////
    builder.addCase(readDeviceConfig.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(readDeviceConfig.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.deviceConfig = payload;
    });
    builder.addCase(readDeviceConfig.rejected, (state) => {
      state.loading = false;
      state.deviceConfig = null;
    });
    /////////////////////////////////////////////////////////////////////
    builder.addCase(updateDeviceConfig.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(
      updateDeviceConfig.fulfilled,
      (state, { payload }: PayloadAction<DeviceConfigI>) => {
        state.loading = false;
        state.actionPerformed = true;
        state.deviceConfig = { ...state.deviceConfig, ...payload };
      }
    );
    builder.addCase(updateDeviceConfig.rejected, (state) => {
      state.loading = false;
      state.deviceConfig = null;
    });
    /////////////////////////////////////////////////////////////////////
    builder.addCase(deleteDeviceConfig.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(
      deleteDeviceConfig.fulfilled,
      (state, { payload }: PayloadAction<{ id: string }>) => {
        state.loading = false;
        state.deviceConfigs = state.deviceConfigs.filter(
          (el) => el.id !== payload.id
        );
      }
    );
    builder.addCase(deleteDeviceConfig.rejected, (state) => {
      state.loading = false;
    });
    /////////////////////////////////////////////////////////////////////
    builder.addCase(syncAttendanceDevice.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(syncAttendanceDevice.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(syncAttendanceDevice.rejected, (state) => {
      state.loading = false;
    });
    /////////////////////////////////////////////////////////////////////
    builder.addCase(testDeviceConfigConnection.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(testDeviceConfigConnection.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(testDeviceConfigConnection.rejected, (state) => {
      state.loading = false;
    });
    /////////////////////////////////////////////////////////////////////
    builder.addCase(syncDeviceConfig.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(syncDeviceConfig.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(syncDeviceConfig.rejected, (state) => {
      state.loading = false;
    });
    /////////////////////////////////////////////////////////////////////
    builder.addCase(getAttendanceDevices.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(getAttendanceDevices.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.attendanceDevicesList = payload;
    });
    builder.addCase(getAttendanceDevices.rejected, (state) => {
      state.loading = false;
    });
    /////////////////////////////////////////////////////////////////////
    builder.addCase(getSingleAttendanceDevice.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(
      getSingleAttendanceDevice.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.attendanceDevice = payload;
      }
    );
    builder.addCase(getSingleAttendanceDevice.rejected, (state) => {
      state.loading = false;
    });
    /////////////////////////////////////////////////////////////////////
    builder.addCase(getSyncedDepartments.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(getSyncedDepartments.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.syncedDepartments = payload;
    });
    builder.addCase(getSyncedDepartments.rejected, (state) => {
      state.loading = false;
    });
    /////////////////////////////////////////////////////////////////////
    builder.addCase(getEmployeesToSync.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(
      getEmployeesToSync.fulfilled,
      (
        state,
        { payload }: PayloadAction<{ syncable_employees: EmployeesToSyncI[] }>
      ) => {
        state.loading = false;
        state.employeesToSync = payload.syncable_employees.filter(
          (el) => el.employee_device_id__device_id === null
        );
        state.syncedEmployees = payload.syncable_employees.filter(
          (el) => el.employee_device_id__device_id !== null
        );
      }
    );
    builder.addCase(getEmployeesToSync.rejected, (state) => {
      state.loading = false;
    });
    /////////////////////////////////////////////////////////////////////
    builder.addCase(syncDepartmentsToDevice.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(syncDepartmentsToDevice.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.actionPerformed = true;
    });
    builder.addCase(syncDepartmentsToDevice.rejected, (state) => {
      state.loading = false;
    });
    /////////////////////////////////////////////////////////////////////
  },
});

export const { resetAttDeviceState } = AttendanceDeviceSlice.actions;
export default AttendanceDeviceSlice.reducer;
