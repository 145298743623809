import React from "react";
import { ReportSideBar } from "../../../../components";
import CustomizeAdmitCard from "../../../../components/Reports/Templates/AdmitCard/CustomizeAdmitCard";
import SubModuleLayout from "../../../../components/Reusable/Layouts/SubModuleLayout";

const AdmitCardCustomizePage = () => {
  const nav = <ReportSideBar admit_card_template />;
  return (
    <SubModuleLayout reportMenuActive sideNav={nav}>
      <CustomizeAdmitCard />
    </SubModuleLayout>
  );
};

export default AdmitCardCustomizePage;
