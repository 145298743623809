import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootStore } from "../../../store";
import BackDropLoader from "../../Reusable/BackDropLoader";
import LedgerForm from "./LedgerForm";
import TerminalMarksLedger from "./TerminalMarksLedger";
import CombinedMarksLedger from "./CombinedLedger";

const ResultLedger = () => {
  const { loading } = useSelector((state: RootStore) => state.marksLedger);
  const [ledgerType, setLedgerType] = useState<string>("single");

  return (
    <div>
      <LedgerForm onLedgerTypeChange={setLedgerType} />
      {ledgerType === "single" ? (
        <TerminalMarksLedger />
      ) : ledgerType === "combined" ? (
        <CombinedMarksLedger />
      ) : null}
      <BackDropLoader open={loading} />
    </div>
  );
};

export default ResultLedger;
