import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import SubModuleLayout from '../../components/Reusable/Layouts/SubModuleLayout';
import StudentCategoryForm from '../../components/Student/StudentCategories/StudentCategoryForm';
import StudentCategoryTable from '../../components/Student/StudentCategories/StudentCategoryTable';
import AccessPermission from '../../components/Student/StudentDetail/AccessPermission';
import StudentSidebar from '../../components/Student/StudentSidebar/StudentSidebar';
import { breadcrumb } from './Student.Breadcrumbs';

interface EditDataI {
  id: string;
  name: string;
  count: number;
}

const StudentCategoryPage = () => {
  const [formData, setFormData] = useState<EditDataI | null>(null);
  const [count, setCount] = useState<number>(0);

  const fetchEditData = (data: EditDataI) => {
    setFormData({ ...data, count: count });
    setCount(count + 1);
  };
  const nav = <StudentSidebar studentCategories />;
  return (
    <SubModuleLayout module={breadcrumb} sideNav={nav}>
      <Grid container>
        <AccessPermission>
          <Grid item xs={3}>
            <StudentCategoryForm editData={formData} />
          </Grid>
        </AccessPermission>
        <Grid item xs={9}>
          <StudentCategoryTable onEditData={fetchEditData} />
        </Grid>
      </Grid>
    </SubModuleLayout>
  );
};

export default StudentCategoryPage;
