//function to format date and time

const getFullName = (fname: string, lname: string) => {
  if (fname || lname) {
    return fname + " " + lname;
  }
  return "";
  // return "Unknown";
};
export default getFullName;
