import { StylesConfig } from "react-select";

export const selectStyles: StylesConfig = {
  input: (provided, state) => ({
    ...provided,
    fontFamily: "inherit",
    fontSize: "13px",
    fontWeight: "400",
    color: "#1F1F1F",
    padding: 0,
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontFamily: "inherit",
    fontSize: "13px",
    fontWeight: "400",
    color: "#9A9A9A",
  }),
  option: (provided, state) => ({
    padding: "10px",
    fontFamily: "inherit",
    fontSize: "13px",
    fontWeight: "400",
    backgroundColor: state.isSelected ? "#DDDDDD" : "#fff",
    color: "#1F1F1F",

    "&:hover": {
      cursor: "pointer",
      color: "#1F1F1F",
      backgroundColor: "#f2f2f2",
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    borderRadius: "5px",
    border: "none",
  }),
  menuList: (provided, state) => ({
    ...provided,
    padding: "8px 0",
    backgroundColor: "#fff",
    borderRadius: "5px",
  }),
  container: (provided, state) => ({
    ...provided,
    width: "100%",
    outline: "none",
    display: "block",
    marginTop: "8px",
    padding: 0,
  }),
  valueContainer: (provided, state) => ({
    ...provided,

    "&:hover": {
      cursor: "text",
    },
  }),
  control: (provided, state) => ({
    ...provided,
    padding: 0,
    fontFamily: "inherit",
    fontSize: "13px",
    fontWeight: "400",
    borderRadius: 5,
    backgroundColor: "#F2F2F2",
    border: state.isFocused ? "1px solid transparent" : "1px solid transparent",
    boxShadow: "none",
    "&:hover": {
      border: state.isFocused
        ? "1px solid transparent"
        : "1px solid transparent",
    },
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    fontFamily: "inherit",
    fontSize: "13px",
    color: "#1F1F1F",
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none",
  }),
};
