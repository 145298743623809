import {
  Grid,
  Paper,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  TableContainer,
  Checkbox,
  Button,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import cx from 'clsx';
import { useCheckBoxTable } from '../../Reusable';
import { useTableStyles } from '../../Styles/TableStyles';
import { SearchedStudentsI } from '../../../actions/Student/Student/StudentActionTypes';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import { StudentSearchForm } from '../..';
import MiscStudentSearch from './MisStudentSearch';
import SaveIcon from '@material-ui/icons/Save';
import { FormSaveLoadingButton } from '../../Reusable/Buttons/FormButton';
import Spinner from '../../../common/spinnerSVG/Spinner';

interface HeadCellsI {
  id: string;
  label: string;
}

interface PropsI {
  onStudentChange: (student_ids: string[]) => void;
  onApplyFee: () => void;
  grade: any;
  isBtnLoading: boolean;
  setIsBtnLoading: (data: boolean) => void;
}

const headCells: HeadCellsI[] = [{ id: 'student', label: 'Student Name' }];

const MiscStudentTable = (props: PropsI) => {
  const { onStudentChange, onApplyFee, grade, isBtnLoading, setIsBtnLoading } =
    props;

  const classes = useTableStyles();
  const [selected, setSelected] = React.useState<string[]>([]);

  const [tableData, setTableData] = useState<SearchedStudentsI[]>([]);

  const studentSelector = useSelector(
    (state: RootStore) => state.student.searched_student
  );

  const applyFeeAction = useSelector(
    (state: RootStore) => state.fee.actionPerformed
  );

  const add_or_update = useSelector(
    (state: RootStore) => state.misc_fee_type.add_or_update
  );

  useEffect(() => {
    if (applyFeeAction) {
      setSelected([]);
    }
  }, [applyFeeAction]);

  useEffect(() => {
    if (add_or_update) {
      setIsBtnLoading(false);
    }
  }, [add_or_update]);

  useEffect(() => {
    setTableData(studentSelector?.results);
  }, [studentSelector]);

  useEffect(() => {
    onStudentChange(selected);
  }, [selected]);

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useCheckBoxTable(headCells);

  const handleCheckClick = (name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = tableData.map((n) => n.academic_id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const getCustomTableRow = (row: SearchedStudentsI, index: number) => {
    const isItemSelected = isSelected(row.academic_id);
    const labelId = `enhanced-table-checkbox-${index}`;
    const cellStyle = cx(classes.cell, classes.cellSm);
    return (
      <StyledTableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.academic_id}
        selected={isItemSelected}
      >
        <StyledTableCell className={cellStyle} align="left">
          <Checkbox
            color="default"
            checked={isItemSelected}
            inputProps={{ 'aria-labelledby': labelId }}
            onClick={() => handleCheckClick(row.academic_id)}
          />
          {row.student_first_name} {row.student_last_name}
        </StyledTableCell>
        {/* <StyledTableCell className={cellStyle} align="center">
                    "-"
                </StyledTableCell> */}
      </StyledTableRow>
    );
  };

  return (
    <Paper
      className={classes.rootCheckBoxTable}
      style={{ marginRight: '10px', marginLeft: '10px' }}
    >
      <Grid container justifyContent="center">
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="flex-end"
            style={{ marginTop: '5px' }}
          >
            <FormSaveLoadingButton
              onClick={onApplyFee}
              icon={isBtnLoading ? <Spinner /> : <SaveIcon />}
              disabled={isBtnLoading ? true : false}
              text={isBtnLoading ? 'Applying...' : 'Apply'}
            />
          </Grid>
          <div style={{ maxHeight: '500px', overflow: 'auto' }}>
            <TableContainer
              hasCheckbox
              handleSelectAllClicked={handleSelectAllClick}
              rowCount={tableData?.length}
              numSelected={selected?.length}
              loading={false}
              items={grade === null ? [] : tableData}
              headCells={headCells}
              getCustomTableRow={getCustomTableRow}
              hiddenCols={[-1]}
              disablePagination
              disablePrint
              disableSearch
            />
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default MiscStudentTable;
