import React from "react";
import { IdCardList, ReportSideBar, SearchIdCard } from "../../components";
import LedgerTable from "../../components/Reports/ResultLedger/LedgerTable";
import SearchResult from "../../components/Reports/ResultLedger/SearchResult";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

export default function ResultLedgerPage() {
  const nav = <ReportSideBar marksheet_ledger />;
  return (
    <SubModuleLayout reportMenuActive sideNav={nav}>
      <SearchResult />
      <LedgerTable />
    </SubModuleLayout>
  );
}
