import {
  GET_LEAVE_TYPE_LOADING,
  GET_LEAVE_TYPE_ERROR,
  GET_LEAVE_TYPE_SUCCESS,
  ADD_LEAVE_TYPE_LOADING,
  ADD_LEAVE_TYPE_ERROR,
  ADD_LEAVE_TYPE_SUCCESS,
  DELETE_LEAVE_TYPE_LOADING,
  DELETE_LEAVE_TYPE_ERROR,
  DELETE_LEAVE_TYPE_SUCCESS,
  UPDATE_LEAVE_TYPE_LOADING,
  UPDATE_LEAVE_TYPE_ERROR,
  UPDATE_LEAVE_TYPE_SUCCESS,
  LeaveTypeTypeI,
  LeaveTypeDispatchTypes,
} from "../../actions/HumanResource/LeaveType/LeaveTypeActionTypes";

interface InitialStateI {
  loading: boolean;
  leave_types: LeaveTypeTypeI[];
  errors: any;
  add_or_update: boolean;
}

const initialState: InitialStateI = {
  loading: false,
  leave_types: [],
  errors: null,
  add_or_update: false,
};

const LeaveTypeReducer = (
  state: InitialStateI = initialState,
  action: LeaveTypeDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_LEAVE_TYPE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_LEAVE_TYPE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_LEAVE_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        leave_types: action.payload,
        errors: null,
      };

    case ADD_LEAVE_TYPE_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case ADD_LEAVE_TYPE_ERROR:
      return {
        ...state,
        loading: false,
        add_or_update: true,
      };

    case ADD_LEAVE_TYPE_SUCCESS:
      return {
        loading: false,
        leave_types: [...state.leave_types, action.payload],
        errors: null,
        add_or_update: true,
      };

    case UPDATE_LEAVE_TYPE_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case UPDATE_LEAVE_TYPE_ERROR:
      return {
        ...state,
        loading: false,
        add_or_update: true,
      };

    case UPDATE_LEAVE_TYPE_SUCCESS:
      const current_data: LeaveTypeTypeI[] = state.leave_types;
      const index: number = current_data.findIndex(
        (element) => element.id === action.payload.id
      );
      current_data[index] = action.payload;

      return {
        loading: false,
        leave_types: current_data,
        errors: null,
        add_or_update: true,
      };

    case DELETE_LEAVE_TYPE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_LEAVE_TYPE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_LEAVE_TYPE_SUCCESS:
      const new_data: LeaveTypeTypeI[] = state.leave_types;
      const item_index = new_data.findIndex(
        (element) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return {
        ...state,
        loading: false,
        leave_types: new_data,
        errors: null,
      };

    default:
      return state;
  }
};

export default LeaveTypeReducer;
