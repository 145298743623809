// ---------------------------- <START> module imports start ----------------------------------//
import React, { useEffect, useState } from 'react';
import { Grid, Typography, Paper, Dialog, Checkbox } from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import { useTableStyles } from '../../Styles/TableStyles';
import { useTableWithPagination } from '../../Reusable';
import CustomizedDialogs from '../../Reusable/Dialogs/DeleteDialog';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import { PrintOutlined, Visibility } from '@material-ui/icons';
import {
  DeleteStaff,
  GetStaffByID,
  GetStaffs,
} from '../../../actions/HumanResource/Staff/StaffAction';
import { HeadCellsI } from '../../../actions';
import {
  ItemDeleteButton,
  ItemEditButton,
  ItemViewButton,
} from '../../Reusable/Buttons/TableButton';
import getFullName from '../../utils/getFullName';
import useLoading from '../../../useHook/useLoading';
import UserAvatar from '../../Reusable/Profile/UserAvatar';
import IdCardPopup from '../../Reports/IDCard/IdCardPopup';
import MultipleIdCardPopup from '../../Reports/IDCard/MultipleIdCardPopup';
import PotraitStudentID from '../../Reports/NewIDCARDDESIGN/potrait/PotraitStudentID';
import PotraitTeacherIDCard from '../../Reports/NewIDCARDDESIGN/potrait/PotraitTeacherIDCard';
import LandScapeStudentID from '../../Reports/NewIDCARDDESIGN/landscape/LandScapeStudentID';
import LandScapeTeacherCard from '../../Reports/NewIDCARDDESIGN/landscape/LandScapeTeacherCard';
import ActionButton from '../../Reusable/Buttons/ActionButton';
import cx from 'clsx';
import {
  GetIdCard,
  GetMultipleStaffIdCard,
} from '../../../actions/Reports/IdCard/IdCardAction';
import {
  GetIdCardTemplate,
  GetSingleIdCardTemplate,
} from '../../../actions/Templates/IdCard/IdCardTemplateAction';
import MultipleStaffIdCardPopup from './MultipleStaffIdCardPopup';
import FormButton from '../../Reusable/Buttons/FormButton';
// ----------------------------<END> module imports end ----------------------------------//

// -------------- <START> interface starts --------------------//
interface TableDataI {
  id: string;
  staff_photo: string;
  staff_name: string;
  designation: string;
  department: string;
  phone_no: string;
  academic_id: string;
}

// -------------- <END> interface ends --------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: 'student_name', label: 'Staff Name', filter: true },
  { id: 'designation', label: 'Designation' },
  { id: 'department', label: 'Department' },
  { id: 'phone_no', label: 'Phone No.' },
  { id: 'action', label: 'Action' },
];

// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const StaffIdCardList: React.FC = () => {
  const classes = useTableStyles();
  const dispatch = useDispatch();

  // States declaration
  const [tableData, setTableData] = useState<TableDataI[]>([]);

  // Modal States
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);

  const [staffID, setStaffData] = useState([]);
  const [open, setOpen] = useState(false);
  const [activeID, setActiveID] = useState<any>(null);
  const [openGenerate, setOpenGenerate] = useState(false);
  const [idCard, setIdCard] = useState<any>();
  // Retrieving re-usable components from useTable
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTableWithPagination(headCells);

  // Staff reducer
  const staffState = useSelector((state: RootStore) => state.staff);
  const { loading } = useLoading(staffState.loading);
  const idCardSelector = useSelector((state: RootStore) => state.id_card);
  const my_templates = useSelector(
    (state: RootStore) => state.id_card_templates.id_card_templates
  );

  // Fetch data
  useEffect(() => {
    dispatch(GetStaffs(1));
    dispatch(GetIdCardTemplate());
  }, []);

  useEffect(() => {
    if (my_templates != null) {
      setActiveID(
        my_templates.find(
          (item: any) => item.active === true && item.type === 'Staff'
        )
      );
    }
  }, [my_templates]);

  useEffect(() => {
    if (staffState.staffs.results != null) {
      const items = staffState.staffs.results.map((staff: any) => {
        return {
          id: staff.id.toString(),
          staff_photo: staff.photo,
          academic_id: staff.academic_id,
          staff_name: getFullName(staff.user.first_name, staff.user.last_name),
          designation: staff.designation_name || '-',
          department:
            (staff.staffs_academic_info &&
              staff.staffs_academic_info.department_name) ||
            '-',
          phone_no: staff.phone || '-',
        };
      });

      setTableData(items);
    }
  }, [staffState]);

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const handleDeleteModal = (id: string) => {
    setItemId(id);
    setDeleteModalOpen(true);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteStaff(itemId));
    setDeleteModalOpen(false);
  };

  const handlePaginationChange = (paginationIndex: number) => {
    dispatch(GetStaffs(paginationIndex));
  };

  const handleClickOpen = (id: string) => {
    console.log({ id });
    if (activeID != null) {
      dispatch(GetStaffByID(id));
      setOpen(true);
    }
  };

  const handleClose = (value: boolean) => {
    setOpen(false);
    // setSelectedValue(value);
  };

  const handleCloseGenerate = () => {
    setOpenGenerate(false);
  };

  const handleOpenGenerate = () => {
    setOpenGenerate(true);
    dispatch(GetSingleIdCardTemplate(activeID?.id));
  };

  const [selected, setSelected] = React.useState<string[]>([]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = tableData.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleCheckClick = (name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  useEffect(() => {
    if (selected != null) {
      const params = {
        staffs: selected,
        template: 'ID',
      };
      dispatch(GetMultipleStaffIdCard(params));
    }
  }, [selected]);

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  useEffect(() => {
    const idCardData = {
      full_name: getFullName(
        staffState?.singlestaff?.user.first_name,
        staffState?.singlestaff?.user.last_name
      ),
      dob: staffState?.singlestaff?.date_of_birth,
      blood_group: staffState?.singlestaff?.blood_group_name,
      address: staffState?.singlestaff?.address,
      phone_number: staffState?.singlestaff?.phone,
      designation: staffState?.singlestaff?.designation_name,
      date_of_joining: staffState?.singlestaff?.date_of_joining,
      photo: staffState?.singlestaff?.photo,
    };

    setIdCard(idCardData);
  }, [staffState?.singlestaff]);
  const renderSwitch = (param: string) => {
    switch (param) {
      case 'ABC-002':
        return <PotraitTeacherIDCard content={activeID} data={idCard} />;
      case 'ABC-004':
        return <LandScapeTeacherCard content={activeID} data={idCard} />;
      default:
        return 'Nothing Found';
    }
  };

  const getCustomTableRow = (item: any, index: number) => {
    const isItemSelected = isSelected(item.id);
    const labelId = `enhanced-table-checkbox-${index}`;
    const cellStyle = cx(classes.cell, classes.cellSm);
    return (
      <StyledTableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={item.id}
        selected={isItemSelected}
      >
        <StyledTableCell align="left" className={cellStyle}>
          <Checkbox
            color="default"
            checked={isItemSelected}
            inputProps={{ 'aria-labelledby': labelId }}
            onClick={() => handleCheckClick(item.id)}
          />
          {item.staff_name}
          {/* <UserAvatar name={item.staff_name} src={item.staff_photo} /> */}
        </StyledTableCell>
        <StyledTableCell align="center" className={cellStyle}>
          {item.designation}
        </StyledTableCell>
        <StyledTableCell align="center" className={cellStyle}>
          {item.department}
        </StyledTableCell>
        <StyledTableCell align="center" className={cellStyle}>
          {item.phone_no}
        </StyledTableCell>
        <StyledTableCell align="center" className={cellStyle}>
          <ActionButton
            title={
              activeID != null ? 'View' : 'First activate one of the template'
            }
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleClickOpen(item.id)
            }
          >
            <Visibility className={classes.addIcon} />
          </ActionButton>
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <>
      <Paper className={classes.rootTableBox}>
        <Typography variant="h6">Staff ID Cards</Typography>
        <TableContainer
          hasCheckbox
          rowCount={tableData.length}
          numSelected={selected.length}
          handleSelectAllClicked={handleSelectAllClick}
          label="Staff ID Cards"
          loading={loading}
          items={tableData}
          headCells={headCells}
          forPagination={staffState?.staffs}
          handlePaginationChange={handlePaginationChange}
          getCustomTableRow={getCustomTableRow}
          search_name="staff_name"
        />
        <Grid container justifyContent="flex-end">
          <FormButton
            startIcon={<PrintOutlined />}
            style={{ minWidth: '150px', marginTop: '16px' }}
            disabled={selected?.length ? false : true}
            onClick={handleOpenGenerate}
          >
            {activeID != null ? 'Generate' : 'Generate( not activated)'}
          </FormButton>
        </Grid>

        <CustomizedDialogs
          open={deleteModalOpen}
          onClose={handleModalClose}
          deleteConfirmed={onDeleteConfirmed}
        />
      </Paper>
      {activeID?.mode === 'potrait' ? (
        <Dialog
          maxWidth="sm"
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={open}
        >
          <IdCardPopup activeID={activeID} idCard={idCard} />
        </Dialog>
      ) : (
        <Dialog
          fullWidth
          maxWidth="sm"
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={open}
        >
          <IdCardPopup activeID={activeID} idCard={idCard} />
        </Dialog>
      )}
      <Dialog
        open={openGenerate}
        onClose={handleCloseGenerate}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        // className={classes.modalBox}
      >
        <MultipleIdCardPopup content={activeID} />
      </Dialog>
      <Dialog
        open={openGenerate}
        onClose={handleCloseGenerate}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        // className={classes.modalBox}
      >
        <MultipleStaffIdCardPopup content={activeID} />
      </Dialog>
    </>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default StaffIdCardList;
