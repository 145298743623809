import React from "react";
import { SettingSidebar } from "../../../components";
import SubModuleLayout from "../../../components/Reusable/Layouts/SubModuleLayout";
import AttendanceDeviceInfo from "../../../components/GeneralSetting/AttendanceDevice/AttendanceDeviceInfo";

const AttendanceDeviceInfoPage = () => {
  const nav = <SettingSidebar attendanceDevice />;
  return (
    <SubModuleLayout sideNav={nav}>
      <AttendanceDeviceInfo />
    </SubModuleLayout>
  );
};

export default AttendanceDeviceInfoPage;
