import {
  GET_ADMIN_ASSIGNMENT_LOADING,
  GET_ADMIN_ASSIGNMENT_ERROR,
  GET_ADMIN_ASSIGNMENT_SUCCESS,
  AdminAssignmentDispatchTypes,
  AdminAssignmentTypeI,
} from "../../actions/Classroom/AdminAssignment/AdminAssignmentActionTypes";

interface InitialStateI {
  loading: boolean;
  assignments: AdminAssignmentTypeI[];
}

const initialState: any = {
  loading: false,
  assignments: [],
};

const AdminAssignmentReducer = (
  state: any = initialState,
  action: AdminAssignmentDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_ADMIN_ASSIGNMENT_LOADING:
      return {
        ...state,
        loading: true,
        assignment: null,
      };

    case GET_ADMIN_ASSIGNMENT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_ADMIN_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        assignments: action.payload,
      };

    default:
      return state;
  }
};

export default AdminAssignmentReducer;
