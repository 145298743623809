import {
  Grid,
  Paper,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  TableContainer,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import cx from "clsx";
import { useCheckBoxTable } from "../../Reusable";
import { useTableStyles } from "../../Styles/TableStyles";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import {
  DeleteMiscFeeType,
  GetMiscFeeType,
} from "../../../actions/Fees/MiscFee/MiscFeeTypeAction";
import MiscStudentSearch from "./MisStudentSearch";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import { ItemDeleteButton } from "../../Reusable/Buttons/TableButton";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // maxWidth: 300,
    // minWidth: 280,
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #DEDFE0",
  },
  parentDiv: {
    display: "flex",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
}));

interface PropsI {
  onFeeChange: (ids: string[]) => void;
  onGradeSection: (grade: string, section: string) => void;
  grade: any;
  setGrade: (value: any) => void;
}

interface TableDataI {
  id: string;
  title: string;
}

interface HeadCellsI {
  id: string;
  label: string;
}

const headCells: HeadCellsI[] = [
  { id: "index", label: "S.N" },
  { id: "month", label: "Month" },
];

const MiscFeeList = (props: PropsI) => {
  const { onFeeChange, onGradeSection, grade, setGrade } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [selected, setSelected] = useState<string[]>([]);
  const [listData, setListData] = useState<any[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);

  const applyFeeAction = useSelector(
    (state: RootStore) => state.fee.actionPerformed
  );
  const [checked, setChecked] = React.useState([0]);

  const miscFeeTypeActionSelector = useSelector(
    (state: RootStore) => state.misc_fee_type.feeType
  );

  const miscFeeTypeActionState = useSelector(
    (state: RootStore) => state.misc_fee_type
  );

  useEffect(() => {
    dispatch(GetMiscFeeType());
  }, []);

  useEffect(() => {
    if (miscFeeTypeActionSelector !== null) {
      const data: any = [];
      miscFeeTypeActionSelector.map((elem: any) => {
        data.push({
          ...elem,
          checked: false,
        });
      });
      setListData(data);
    }
  }, [miscFeeTypeActionState, miscFeeTypeActionSelector]);

  const handleDeleteModal = (id: any) => {
    setItemId(id);

    setDeleteModalOpen(true);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(false);
  };

  const onDeleteConfirmed = () => {
    itemId != null && dispatch(DeleteMiscFeeType(itemId));
    setDeleteModalOpen(false);
  };

  useEffect(() => {
    onFeeChange(selected);
  }, [selected]);

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useCheckBoxTable(headCells);

  const handleCheckClick = (name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const handleChange = (checked: any, elem: any) => {
    const temp = listData;
    const final = temp.map((el: any) =>
      el.id === elem.id ? { ...el, checked: !el.checked } : el
    );
    setListData(final);
  };

  console.log({ listData });

  useEffect(() => {
    if (listData !== null) {
      const filtered = listData.filter((elem) => elem.checked === true);
      const data: Array<string> = [];
      filtered.map((elem) => data.push(elem.id));
      onFeeChange(data);
    }
  }, [listData]);

  return (
    <div>
      <div style={{ maxHeight: "500px", overflow: "auto" }}>
        <MiscStudentSearch
          onGradeSection={onGradeSection}
          grade={grade}
          setGrade={setGrade}
        />
        <List className={classes.root}>
          {listData.length > 0 ? (
            listData?.map((elem: any, index: number) => {
              const labelId = `checkbox-list-label-${index}`;
              return (
                <ListItem key={index} role={undefined} dense button>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={elem.checked}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                      onChange={(event) =>
                        handleChange(event.target.checked, elem)
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={elem.name + ` - Rs. ${elem.amount}`}
                  />
                  <ItemDeleteButton
                    onClick={() => handleDeleteModal(elem.id)}
                  />
                </ListItem>
              );
            })
          ) : (
            <div style={{ paddingLeft: "15px" }}>
              <p>No Miscellaneous Fee Found.</p>
              <p>Try different class.</p>
            </div>
          )}
        </List>
        <CustomizedDialogs
          open={deleteModalOpen}
          onClose={handleModalClose}
          deleteConfirmed={onDeleteConfirmed}
        />
      </div>
    </div>
  );
};

export default MiscFeeList;
