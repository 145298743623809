import {
  GET_SECTION_LOADING,
  GET_SECTION_ERROR,
  GET_SECTION_SUCCESS,
  SectionDispatchTypes,
  ADD_SECTION_LOADING,
  ADD_SECTION_ERROR,
  ADD_SECTION_SUCCESS,
  DELETE_SECTION_LOADING,
  DELETE_SECTION_ERROR,
  DELETE_SECTION_SUCCESS,
  UPDATE_SECTION_LOADING,
  UPDATE_SECTION_ERROR,
  UPDATE_SECTION_SUCCESS,
  SectionTypeI,
} from "../../actions/Academics/Section/SectionActionTypes";

interface InitialStateI {
  loading: boolean;
  sections: SectionTypeI[];
  recent?: boolean;
  errors?: any;
  add_or_update: boolean;
}

const initialState: InitialStateI = {
  loading: false,
  sections: [],
  recent: false,
  errors: null,
  add_or_update: false,
};

const SectionReducer = (
  state: InitialStateI = initialState,
  action: SectionDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_SECTION_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_SECTION_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_SECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        sections: action.payload,
        recent: false,
        errors: null,
      };

    case ADD_SECTION_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case ADD_SECTION_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        add_or_update: true,
      };

    case ADD_SECTION_SUCCESS:
      return {
        loading: false,
        sections: [...state.sections, action.payload],
        recent: true,
        errors: null,
        add_or_update: true,
      };

    case UPDATE_SECTION_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case UPDATE_SECTION_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        add_or_update: true,
      };

    case UPDATE_SECTION_SUCCESS:
      const current_data: SectionTypeI[] = state.sections;
      const index: number = current_data.findIndex(
        (element) => element.id === action.payload.id
      );
      current_data[index] = action.payload;

      return {
        loading: false,
        sections: current_data,
        recent: true,
        errors: null,
        add_or_update: true,
      };

    case DELETE_SECTION_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_SECTION_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_SECTION_SUCCESS:
      const new_data: SectionTypeI[] = state.sections;
      const item_index = new_data.findIndex(
        (element) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return {
        ...state,
        loading: false,
        sections: new_data,
      };

    default:
      return state;
  }
};

export default SectionReducer;
