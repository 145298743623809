import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { AcademicSidebar } from "../../components";
import PreTimeTableForm from "../../components/Academic/PreTimeTable/PreTimeTableForm";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";
import PreTimeTableList from "../../components/Academic/PreTimeTable/PreTimeTableList";

const PreTimeTablePage = () => {
  const [classes, setClasses] = useState([]);

  const nav = <AcademicSidebar timeTable />;

  const onSelectedClass = (classes: any) => {
    setClasses(classes);
  };

  return (
    <SubModuleLayout sideNav={nav}>
      <Grid item xs={12}>
        <PreTimeTableForm />
        <PreTimeTableList onSelectedClass={onSelectedClass} />
      </Grid>
    </SubModuleLayout>
  );
};

export default PreTimeTablePage;
