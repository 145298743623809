import styled from "styled-components";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import ScheduleIcon from "@material-ui/icons/Schedule";

import { StylesConfig } from "react-select";

export const selectStyles: StylesConfig = {
  container: (provided, state) => ({
    ...provided,
    outline: "none",
    display: "block",
    minWidth: "240px",
  }),
  control: (provided, state) => ({
    ...provided,
    outline: "none",
    backgroundColor: "#F2F2F2",
    border: state.isFocused ? "1px solid #e8ebed" : "1px solid #F2F2F2",
    boxShadow: "none",
    "&:hover": {
      border: state.isFocused ? "1px solid #e8ebed" : "1px solid #F2F2F2",
    },
  }),
  input: (provided, state) => ({
    ...provided,
  }),
};

export const CalendarContainer = styled.div``;

export const Calendar = styled.div``;

export const CalendarHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  background-color: #00000017;
  padding: 8px 0;

  span {
    text-align: center;
  }
`;

export const CalendarBody = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
`;

const Card = styled.div`
  position: relative;
  padding: 8px;
  padding-top: 12px;
  border: 1px solid #e2e2e2;

  min-height: 180px;

  & > p {
    display: flex;
    align-items: center;
    gap: 4px;

    font-size: 13px;
    font-weight: 500;
    color: #555555;

    margin: 0;
  }
`;

const CardDayNumber = styled.span`
  font-size: 24px;
  font-weight: 500;

  position: absolute;
  top: 12px;
  right: 8px;
`;

const CardDayStatus = styled.span<{
  holiday: "Public Holiday" | "Week Off" | "Absent" | "Leave" | null;
}>`
  position: absolute;
  bottom: 0;
  left: 0;

  display: block;

  padding: 8px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  background-color: ${(props) =>
    props.holiday === "Public Holiday"
      ? "#F3E5F6"
      : props.holiday === "Week Off"
      ? "#E4F2FD"
      : props.holiday === "Absent"
      ? "#FBE9E7"
      : props.holiday === "Leave"
      ? "#FFF2DF"
      : ""};
  color: ${(props) =>
    props.holiday === "Public Holiday"
      ? "#763885"
      : props.holiday === "Week Off"
      ? "#3583C1"
      : props.holiday === "Absent"
      ? "#CD6363"
      : props.holiday === "Leave"
      ? "#CA863D"
      : ""};
  width: 100%;
`;

const CheckInIcon = styled(ArrowRightAltIcon)`
  /* font-size: 12px; */
  color: #d7a971;
`;

const CheckOutIcon = styled(ArrowRightAltIcon)`
  transform: rotate(180deg);
  color: #d7a971;
`;

const ClockIcon = styled(ScheduleIcon)`
  color: #d7a971;
`;

type CalendarCardProps = {
  checkIn: string | null;
  checkOut: string | null;
  day: string | null;
  notCurrentMonth: boolean;
  dayStatus: {
    type: "Public Holiday" | "Week Off" | "Absent" | "Leave";
    event?: string;
  } | null;
};

export const CalendarCard = (props: CalendarCardProps) => {
  const { checkIn, checkOut, day, dayStatus, notCurrentMonth } = props;
  return (
    <Card>
      <CardDayNumber>{day}</CardDayNumber>

      {!notCurrentMonth && (
        <>
          <p>
            <CheckInIcon /> <span>{checkIn}</span>
          </p>
          <p>
            <CheckOutIcon /> <span>{checkOut}</span>
          </p>
          <p>
            <ClockIcon /> <span>0 hrs</span>
          </p>
        </>
      )}
      {dayStatus && (
        <CardDayStatus holiday={dayStatus ? dayStatus.type : null}>
          {dayStatus !== null
            ? dayStatus.type === "Public Holiday"
              ? dayStatus.event
              : dayStatus.type
            : ""}
        </CardDayStatus>
      )}
    </Card>
  );
};
