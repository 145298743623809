import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { Autocomplete } from "@material-ui/lab";
import { useFormStyles } from "../../Styles/FormStyles";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import { ExamTypeI } from "../../../actions/Examination/Exam/ExamActionTypes";
import { GetExams } from "../../../actions/Examination/Exam/ExamAction";
import FullWidthFormLayout from "../../Reusable/Layouts/FullWidthForm.layout";
import { GetGrades } from "../../../actions/Academics/Grade/GradeAction";
import { resetData } from "../../../rtk/features/exam/examSchedule/examScheduleSlice";
import { ClassTypeI } from "../../../actions/Academics/Class/ClassActionTypes";
import { SectionTypeI } from "../../../actions/Academics/Section/SectionActionTypes";
import { GetClasses } from "../../../actions/Academics/Class/ClassAction";
import { Search } from "@material-ui/icons";
import { getExamSchedule } from "../../../rtk/features/exam/examSchedule/examScheduleApi";
import { ExamSubjectsI } from "../../../rtk/features/exam/addMarks/addMarks";
import {
  getAllSubjectsMarksEntry,
  getMarksEntry,
} from "../../../rtk/features/exam/addMarks/addMarksApi";
import {
  reloadData,
  setSearchParams,
} from "../../../rtk/features/exam/addMarks/addMarksSlice";
import { getGradings } from "../../../rtk/features/exam/grading/gradingApi";

interface Props {
  addMarksMethod: (val: string) => void;
}

const AddMarksForm = (props: Props) => {
  const classes = useFormStyles();

  const { addMarksMethod } = props;
  ////////////////// State declarations ///////////////////////////////////////////

  const [addMarksOn, setAddMarksOn] = useState<string>("singleSubject");

  const [selectedExam, setSelectedExam] = useState<ExamTypeI | null>(null);

  const [selectedClass, setSelectedClass] = useState<ClassTypeI | null>(null);
  const [selectedSection, setSelectedSection] = useState<SectionTypeI | null>(
    null
  );

  const [subjectChoices, setSubjectChoices] = useState<ExamSubjectsI[]>([]);

  const [selectedSubject, setSelectedSubject] = useState<ExamSubjectsI | null>(
    null
  );

  const [subjectDisabled, setSubjectDisabled] = useState<boolean>(true);
  const [searchDisabled, setSearchDisabled] = useState<boolean>(true);

  ////////////////////////////////////////////////////////////////////////////////

  //======================================== <START> REACT HOOKS <START> ============================//

  const dispatch = useDispatch();

  const classSelector = useSelector((state: RootStore) => state.class);
  const examSelector = useSelector((state: RootStore) => state.exam);

  const { subjects } = useSelector((state: RootStore) => state.addMarks);

  const [sections, setSections] = useState<SectionTypeI[]>([]);

  useEffect(() => {
    dispatch(GetClasses());
    dispatch(GetExams());
    dispatch(reloadData());
  }, []);

  useEffect(() => {
    setSubjectChoices(subjects);
  }, [subjects]);

  useEffect(() => {
    addMarksMethod(addMarksOn);
  }, [addMarksOn]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddMarksOn((event.target as HTMLInputElement).value);

    setSelectedExam(null);
    setSelectedClass(null);
    setSelectedSection(null);
    setSubjectDisabled(true);
    setSearchDisabled(true);
    setSelectedSubject(null);
    setSubjectChoices([]);
    dispatch(resetData());
    dispatch(reloadData());
  };

  const handleExamChange = (value: ExamTypeI | null) => {
    setSelectedExam(value);
    setSelectedClass(null);
    setSelectedSection(null);
    setSubjectDisabled(true);
    setSearchDisabled(true);
    setSelectedSubject(null);
    setSubjectChoices([]);
    dispatch(resetData());
    dispatch(reloadData());
  };

  const handleClassChange = (value: ClassTypeI | null) => {
    dispatch(reloadData());
    setSelectedClass(value);
    setSelectedSection(null);
    setSelectedSubject(null);
    setSubjectChoices([]);

    if (value) {
      if (value.section.length) {
        setSections(value.section);
        setSubjectDisabled(true);
      } else {
        setSubjectDisabled(false);
        if (value && selectedExam) {
          dispatch(
            getExamSchedule({ gradeId: value.grade, examId: selectedExam.id })
          );
          if (addMarksOn === "wholeSubjects") {
            setSearchDisabled(false);
          }
        }
      }
    } else {
      setSubjectDisabled(true);
      setSearchDisabled(true);
      setSections([]);
    }
  };

  const handleSectionChange = (value: SectionTypeI | null) => {
    dispatch(reloadData());
    setSelectedSection(value);
    setSelectedSubject(null);
    setSubjectChoices([]);

    if (value) {
      setSubjectDisabled(false);
      if (value && selectedClass && selectedExam) {
        dispatch(
          getExamSchedule({
            gradeId: selectedClass.grade,
            examId: selectedExam.id,
            sectionId: value.id,
          })
        );
        if (addMarksOn === "wholeSubjects") {
          setSearchDisabled(false);
        }
      }
    } else {
      setSubjectDisabled(true);
      setSearchDisabled(true);
    }
  };

  const handleSubjectChange = (value: ExamSubjectsI | null) => {
    setSelectedSubject(value);

    if (value) {
      setSearchDisabled(false);
    } else {
      setSearchDisabled(false);
    }
  };

  const handleSearch = () => {
    if (addMarksOn === "singleSubject") {
      dispatch(
        getMarksEntry({
          examId: selectedExam!.id,
          gradeId: selectedClass!.grade,
          sectionId: selectedSection?.id || "",
          subjectId: selectedSubject!.id,
        })
      );
    } else {
      dispatch(getGradings());
      dispatch(
        getAllSubjectsMarksEntry({
          examId: selectedExam!.id,
          gradeId: selectedClass!.grade,
          sectionId: selectedSection?.id || "",
        })
      );
    }
    dispatch(
      setSearchParams({
        exam: selectedExam
          ? {
              id: selectedExam.id,
              name: selectedExam.exam_name,
            }
          : null,
        grade: selectedClass
          ? { id: selectedClass.grade, name: selectedClass.grade_name }
          : null,
        section: selectedSection
          ? { id: selectedSection.id, name: selectedSection.name }
          : null,
        subject: selectedSubject
          ? {
              id: selectedSubject.id,
              name: selectedSubject.subject,
              has_practical: selectedSubject.has_practical,
            }
          : null,
      })
    );
  };

  //======================================== <END> REACT HOOKS <END> ============================//

  return (
    <>
      <FullWidthFormLayout title="Add Marks">
        <Grid container>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Choose Method</FormLabel>
              <RadioGroup
                aria-label="add_marks"
                name="add_marks"
                value={addMarksOn}
                onChange={handleChange}
                row
              >
                <FormControlLabel
                  value="singleSubject"
                  control={<Radio />}
                  label="Single Subject"
                />
                <FormControlLabel
                  value="wholeSubjects"
                  control={<Radio />}
                  label="Whole Subjects"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                className={classes.formWrapper}
              >
                <InputLabel>Exam </InputLabel>
                <Autocomplete
                  classes={{
                    input: classes.smallfont,
                    option: classes.smallfont,
                  }}
                  fullWidth
                  value={selectedExam}
                  onChange={(
                    event: React.ChangeEvent<{}>,
                    value: ExamTypeI | null
                  ) => handleExamChange(value)}
                  options={examSelector.exams}
                  getOptionLabel={(option) => option.exam_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Exam"
                      name="exam_type"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                className={classes.formWrapper}
              >
                <InputLabel>Class </InputLabel>
                <Autocomplete
                  classes={{
                    input: classes.smallfont,
                    option: classes.smallfont,
                  }}
                  value={selectedClass}
                  fullWidth
                  onChange={(
                    event: React.ChangeEvent<{}>,
                    value: ClassTypeI | null
                  ) => handleClassChange(value)}
                  disabled={!Boolean(selectedExam)}
                  options={classSelector.classes}
                  getOptionLabel={(option) => option.grade_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Grade"
                      name="class_name"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                className={classes.formWrapper}
              >
                <InputLabel>Section </InputLabel>
                <Autocomplete
                  classes={{
                    input: classes.smallfont,
                    option: classes.smallfont,
                  }}
                  value={selectedSection}
                  fullWidth
                  onChange={(
                    event: React.ChangeEvent<{}>,
                    value: SectionTypeI | null
                  ) => handleSectionChange(value)}
                  disabled={!Boolean(selectedClass?.section.length)}
                  options={sections}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Section"
                      name="class_name"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          {addMarksOn === "singleSubject" ? (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  className={classes.formWrapper}
                >
                  <InputLabel>Subject </InputLabel>
                  <Autocomplete
                    classes={{
                      input: classes.smallfont,
                      option: classes.smallfont,
                    }}
                    value={selectedSubject}
                    fullWidth
                    onChange={(event: React.ChangeEvent<{}>, value) =>
                      handleSubjectChange(value)
                    }
                    disabled={subjectDisabled}
                    options={subjectChoices}
                    getOptionLabel={(option) => option.subject}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Subject"
                        name="class_name"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <Grid container>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleSearch()}
                startIcon={<Search />}
                style={{ margin: "10px 10px 0 0" }}
                disabled={searchDisabled}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </FullWidthFormLayout>
    </>
  );
};

export default AddMarksForm;
