import { Button, Grid, IconButton, Tooltip } from "@material-ui/core";
import { Search, SettingsBackupRestore } from "@material-ui/icons";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { GetAdminLessonPlan } from "../../../actions/Academics/AdminLessonPlan/AdminLessonPlanAction";
import { GetClasses } from "../../../actions/Academics/Class/ClassAction";
import { ClassTypeI } from "../../../actions/Academics/Class/ClassActionTypes";
import { SectionTypeI } from "../../../actions/Academics/Section/SectionActionTypes";
import { GetSubjects } from "../../../actions/Academics/Subject/SubjectAction";
import { GetTeacherList } from "../../../actions/Classroom/TeacherList/TeacherListAction";
import { TeacherListTypeI } from "../../../actions/Classroom/TeacherList/TeacherListActionTypes";
import { RootStore } from "../../../store";
import { FormResetButton } from "../../Reusable/Buttons/FormButton";
import CustomizedSelect, { RowSelect } from "../../Reusable/Inputs/Select";
import FullWidthFormLayout from "../../Reusable/Layouts/FullWidthForm.layout";
import { useFormStyles } from "../../Styles/FormStyles";
import getFullName from "../../utils/getFullName";

const TEACHER_CHOICES = [{ id: 1, value: "Reuben Thapa" }];

const SearchLessonPlan = (props: any) => {
  const classes = useFormStyles();
  const dispatch = useDispatch();
  const { classItem } = props;

  const { register, handleSubmit, errors } = useForm<any>();

  //STATES HANDLEER
  const [selectedTeacher, setSelectedTeacher] = useState<any | null>(null);
  const [selectedSection, setSelectedSection] =
    useState<SectionTypeI | null>(null);
  const [selectedSubject, setSelectedSubject] = useState<any>(null);

  //REDUX MANAGEMENT
  const subjectSelector = useSelector((state: RootStore) => state.subject);
  const teacherSelector = useSelector(
    (state: RootStore) => state.teacher_lists.teacher_list
  );

  React.useEffect(() => {
    dispatch(GetSubjects());
  }, []);

  const handleSubjectChange = (value: any) => {
    setSelectedSubject(value);
  };

  const handleTeacherChange = (value: any | null) => {
    value != null && setSelectedTeacher(value);
  };

  const handleFilter = () => {
    setSelectedTeacher(null);
    setSelectedSubject(null);
  };

  const onSubmit = (data: any) => {
    classItem &&
      selectedSubject &&
      dispatch(GetAdminLessonPlan(classItem.id, selectedSubject.id));
  };

  const SECTION_CHOICES = classItem?.section?.map((item: SectionTypeI) => ({
    key: item.id.toString(),
    value: item.name,
    id: item.id,
  }));

  const SUBJECT_CHOICES = subjectSelector?.subjects.map((item) => ({
    id: item.id,
    value: item.name,
  }));

  const TEACHER_CHOICES = teacherSelector?.map((item: TeacherListTypeI) => ({
    key: item.id.toString(),
    value: getFullName(item.teacher_first_name, item.teacher_last_name),
    id: item.teacher_user_id,
  }));

  const handleSectionChange = (value: SectionTypeI) => {
    if (value) {
      setSelectedSection(value);
      // dispatch(GetTeacherList(classItem.grade, value.id));
    }
  };

  return (
    <>
      <FullWidthFormLayout
        noMargin
        onSubmit={handleSubmit(onSubmit)}
        title="Search Teacher's Lesson Plan"
      >
        <Grid container spacing={2}>
          <Grid item xs={3} className={classes.formWrapperRow}>
            <RowSelect
              fullWidth
              label="Section"
              name="section"
              options={SECTION_CHOICES}
              value={selectedSection}
              placeholder="Label"
              setValue={handleSectionChange}
            // inputRef={register({ required: true })}
            />
          </Grid>
          <Grid item xs={3} className={classes.formWrapperRow}>
            <RowSelect
              label="Teacher"
              name="teacher"
              fullWidth
              options={TEACHER_CHOICES}
              value={selectedTeacher}
              placeholder="Label"
              setValue={setSelectedTeacher}
            // inputRef={register({ required: true })}
            />
          </Grid>
          <Grid item xs={3} className={classes.formWrapperRow}>
            <RowSelect
              label="Subject"
              name="subject_name"
              fullWidth
              options={SUBJECT_CHOICES}
              value={selectedSubject}
              placeholder="Label"
              setValue={handleSubjectChange}
              inputRef={register({ required: true })}
              error={errors["subject_name"] ? "Subject must be selected" : ""}
            />
          </Grid>
          <Grid
            style={{ maxWidth: "14%" }}
            item
            xs={2}
            className={classes.formWrapperRow}
          >
            <Button
              style={{
                marginTop: "0px",
                backgroundColor: "#3FC581",
                color: "#fff",
              }}
              type="submit"
              variant="contained"
              startIcon={<Search />}
            >
              Search
            </Button>
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="Clear Search Query">
              <IconButton
                aria-label="delete"
                style={{
                  padding: "4px",
                  marginTop: "5px",
                  background: "rgba(0,0,0,0.05)",
                }}
              // onClick={handleReset}
              >
                <SettingsBackupRestore />
              </IconButton>
            </Tooltip>
          </Grid>
          {/* <Grid item xs={2} style={{ marginTop: "23px" }}>
            <FormResetButton fullwidth onClick={handleFilter} />
          </Grid> */}
        </Grid>
      </FullWidthFormLayout>
    </>
  );
};

export default SearchLessonPlan;
