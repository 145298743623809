import {
  GET_ID_CARD_LOADING,
  GET_ID_CARD_ERROR,
  GET_ID_CARD_SUCCESS,
  GET_MULTIPLE_ID_CARD_LOADING,
  GET_MULTIPLE_ID_CARD_ERROR,
  GET_MULTIPLE_ID_CARD_SUCCESS,
  GET_MULTIPLE_STAFF_ID_CARD_LOADING,
  GET_MULTIPLE_STAFF_ID_CARD_ERROR,
  GET_MULTIPLE_STAFF_ID_CARD_SUCCESS,
  IdCardDispatchTypes,
} from "../../actions/Reports/IdCard/IdCardActionTypes";

interface InitialStateI {
  loading: boolean;
  id_card: any;
  id_cards: any;
  staff_id_cards: any;
}

const initialState: InitialStateI = {
  loading: false,
  id_card: [],
  id_cards: [],
  staff_id_cards: [],
};

const IdCardReducer = (
  state: InitialStateI = initialState,
  action: IdCardDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_ID_CARD_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_ID_CARD_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_ID_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        id_card: action.payload,
      };
    case GET_MULTIPLE_ID_CARD_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_MULTIPLE_ID_CARD_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_MULTIPLE_ID_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        id_cards: action.payload,
      };
    case GET_MULTIPLE_STAFF_ID_CARD_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_MULTIPLE_STAFF_ID_CARD_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_MULTIPLE_STAFF_ID_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        staff_id_cards: action.payload,
      };
    default:
      return state;
  }
};

export default IdCardReducer;
