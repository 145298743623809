export const SET_SNACKBAR = "SET_SNACKBAR";

export type SnackBarType = {
  snackbarOpen: boolean;
  snackbarType: "warning" | "success" | "error" | "info";
  snackbarMessage: string;
};

export interface SetSnackBarI {
  type: typeof SET_SNACKBAR;
  payload: SnackBarType;
}
