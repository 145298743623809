import { Paper } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";
import { FeeCategorySelect, StudentSelectTable } from "../..";
import { ApplyFeeToStudents } from "../../../actions/Fees/FeeType/FeeAction";
import { setSnackbar } from "../../../actions/SnackbarAction";
import { RootStore } from "../../../store";
import BackDropLoader from "../../Reusable/BackDropLoader";
import SubModuleLayout from "../../Reusable/Layouts/SubModuleLayout";
import FinanceSidebar from "../FinanceSidebar";
import IntimateFeeSearch from "./IntimateFeeSearch";
import IntimateFeeTable from "./IntimateFeeTable";

interface SelectedFeesI {
  id: string;
  title: string;
  isMonthly: boolean;
}

export default function IntimateFeePage() {
  const [fees, setFees] = useState<SelectedFeesI[]>([]);
  const [student, setStudent] = useState<any>(null);
  const [month, setMonth] = useState<any | null>(null);
  const [grade, setGrade] = useState<any | null>(null);
  const [section, setSection] = useState<any | null>(null);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);

  // const [selected, setSelected] = useState<any[]>([]);

  const dispatch = useDispatch();

  const nav = <FinanceSidebar intimation_slip />;
  console.log(grade, section);

  return (
    <SubModuleLayout sideNav={nav}>
      <Paper style={{ margin: "10px" }}>
        <IntimateFeeSearch
          student={student}
          setStudent={setStudent}
          grade={grade}
          setGrade={setGrade}
          section={section}
          setSection={setSection}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          month={month}
          setMonth={setMonth}
        />
        <IntimateFeeTable
          grade={grade}
          section={section}
          startDate={startDate}
          endDate={endDate}
          month={month}
        />
      </Paper>
      <BackDropLoader open={loading} />
    </SubModuleLayout>
  );
}
