export const GET_MARKSHEET_LOADING = "GET_MARKSHEET_LOADING";
export const GET_MARKSHEET_ERROR = "GET_MARKSHEET_ERROR";
export const GET_MARKSHEET_SUCCESS = "GET_MARKSHEET_SUCCESS";

export const GET_MULTIPLE_MARKSHEET_LOADING = "GET_MULTIPLE_MARKSHEET_LOADING";
export const GET_MULTIPLE_MARKSHEET_ERROR = "GET_MULTIPLE_MARKSHEET_ERROR";
export const GET_MULTIPLE_MARKSHEET_SUCCESS = "GET_MULTIPLE_MARKSHEET_SUCCESS";

export type MarkSheetTypeI = {
  exam_subject__subject__credit_hour: string;
  exam_subject__subject__name: string;
  exam_subject__full_marks: number;
  exam_subject__pass_marks: number;
  marks_obtained_theory: number;
  grade_point_theory: number;
  grade_theory: string;
  marks_obtained_practical: number;
  total_marks_obtained: number;
  grade_point_total: number;
};

export type MultipleMarksheetRequestParamsI = {
  exam_info: string;
  student_academics: any;
};

//===============================<START>GET MARKSHEET<START>======================//

export interface GetMarkSheetLoading {
  type: typeof GET_MARKSHEET_LOADING;
}

export interface GetMarkSheetError {
  type: typeof GET_MARKSHEET_ERROR;
}

export interface GetMarkSheetSuccess {
  type: typeof GET_MARKSHEET_SUCCESS;
  payload: MarkSheetTypeI[];
}

//===============================<END>GET MARKSHEET<END>======================//

//===============================<START>GET MULTIPLE MARKSHEET<START>======================//

export interface GetMultipleMarkSheetLoading {
  type: typeof GET_MULTIPLE_MARKSHEET_LOADING;
}

export interface GetMultipleMarkSheetError {
  type: typeof GET_MULTIPLE_MARKSHEET_ERROR;
}

export interface GetMultipleMarkSheetSuccess {
  type: typeof GET_MULTIPLE_MARKSHEET_SUCCESS;
  payload: MarkSheetTypeI[];
}

//===============================<END>GET MULTIPLE MARKSHEET<END>======================//

export type MarkSheetDispatchTypes =
  | GetMarkSheetLoading
  | GetMarkSheetError
  | GetMarkSheetSuccess
  | GetMultipleMarkSheetLoading
  | GetMultipleMarkSheetError
  | GetMultipleMarkSheetSuccess;
