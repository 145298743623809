import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import FullWidthFormLayout from "../../Reusable/Layouts/FullWidthForm.layout";
import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import { Save } from "@material-ui/icons";
import {
  transferToLeft,
  transferToRight,
} from "../../../rtk/features/student/sectionTransfer/sectionTransferSlice";
import {
  getStudentsByAllSections,
  transferSections,
} from "../../../rtk/features/student/sectionTransfer/sectionTransferApi";
import { setSnackbar } from "../../../actions/SnackbarAction";
import BackDropLoader from "../../Reusable/BackDropLoader";

const Container = styled.div`
  width: max-content;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: stretch;
  gap: 10px;
`;

const TableTitle = styled.div`
  text-align: center;
  padding: 5px 0;
  font-size: 16px;
  font-weight: 500;
  background-color: #132e98;
  color: #fff;
`;

const TransferListContainer = styled.div`
  width: 480px;
  height: 500px;
  box-shadow: 2px 2px 10px -5px #000;

  overflow: auto;
`;

const CenterDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TransferButton = styled(IconButton)`
  color: #132e98;
`;

const TblHead = styled.thead`
  background-color: #e4e3e3;
  color: #4d4f5c;
`;

const TblBody = styled.tbody`
  tr {
    &:nth-child(even) {
      background-color: #f7f3f3;
    }
  }
`;

const TblRow = styled.tr``;

const TblHeadData = styled.td`
  padding: 5px;
  font-size: 16px;
  font-weight: 500;
`;

const TblData = styled.td`
  padding: 2px 5px;
  font-size: 14px;
`;

const CellInput = styled.input`
  cursor: pointer;
  margin-left: 10px;
`;

const Footer = styled.div`
  text-align: center;
  padding: 20px 0;
`;

const SectionTransferTable = () => {
  const dispatch = useDispatch();
  const { firstSection, secondSection, sections, grade, loading } = useSelector(
    (state: RootStore) => state.sectionTransfer
  );

  const [selectedFirstTableStudent, setSelectedFirstTableStudent] = useState<
    string[]
  >([]);
  const [selectedSecondTableStudent, setSelectedSecondTableStudent] = useState<
    string[]
  >([]);

  const { actionPerformed } = useSelector(
    (state: RootStore) => state.sectionTransfer
  );

  useEffect(() => {
    if (actionPerformed && grade && sections.first.id && sections.second.id) {
      dispatch(
        getStudentsByAllSections({
          grade: grade,
          firstSection: sections.first,
          secondSection: sections.second,
        })
      );
    }
  }, [actionPerformed]);

  const handleFirstTableAllCheck = (val: boolean) => {
    if (val) {
      setSelectedFirstTableStudent(firstSection.map((el) => el.id));
    } else {
      setSelectedFirstTableStudent([]);
    }
  };

  const handleSecondTableAllCheck = (val: boolean) => {
    if (val) {
      setSelectedSecondTableStudent(secondSection.map((el) => el.id));
    } else {
      setSelectedSecondTableStudent([]);
    }
  };

  const handleFirstTableCheck = ({
    id,
    checked,
  }: {
    id: string;
    checked: boolean;
  }) => {
    if (checked) {
      setSelectedFirstTableStudent([...selectedFirstTableStudent, id]);
    } else {
      const data = selectedFirstTableStudent.filter((el) => el !== id);
      setSelectedFirstTableStudent(data);
    }
  };

  const handleSecondTableCheck = ({
    id,
    checked,
  }: {
    id: string;
    checked: boolean;
  }) => {
    if (checked) {
      setSelectedSecondTableStudent([...selectedSecondTableStudent, id]);
    } else {
      const data = selectedSecondTableStudent.filter((el) => el !== id);
      setSelectedSecondTableStudent(data);
    }
  };

  const handleTransferToRight = () => {
    if (selectedFirstTableStudent.length) {
      dispatch(transferToRight({ students: selectedFirstTableStudent }));
      setSelectedFirstTableStudent([]);
    }
  };

  const handleTransferToLeft = () => {
    if (selectedSecondTableStudent.length) {
      dispatch(transferToLeft({ students: selectedSecondTableStudent }));
      setSelectedSecondTableStudent([]);
    }
  };

  const handleSaveTransfer = () => {
    const changeOnFirst = secondSection.filter(
      (el) => el.changed && el.init !== el.final
    );
    const changeOnSecond = firstSection.filter(
      (el) => el.changed && el.init !== el.final
    );

    if (!changeOnFirst.length && !changeOnSecond.length) {
      dispatch(setSnackbar(true, "warning", "No changes made to be saved"));
    } else {
      const post = [
        {
          from: sections.first.id,
          to: sections.second.id,
          students: changeOnFirst.map((el) => el.id),
        },
        {
          from: sections.second.id,
          to: sections.first.id,
          students: changeOnSecond.map((el) => el.id),
        },
      ];

      if (grade) {
        dispatch(transferSections({ grade: grade, data: post }));
      } else {
        dispatch(
          setSnackbar(
            true,
            "warning",
            "Some error occurred. Please re-select the sections"
          )
        );
      }
    }
  };

  const leftAllChecked = selectedFirstTableStudent.length
    ? firstSection.length === selectedFirstTableStudent.length
    : false;
  const rightAllChecked = selectedSecondTableStudent.length
    ? secondSection.length === selectedSecondTableStudent.length
    : false;

  return (
    <>
      <FullWidthFormLayout>
        <Container>
          <Wrapper>
            <div>
              <TableTitle>
                Section -{" "}
                {sections.first.name === "null"
                  ? "Unassigned"
                  : sections.first.name}
              </TableTitle>
              <TransferListContainer>
                <Table>
                  <TblHead>
                    <TblRow>
                      <TblHeadData>
                        <CellInput
                          type="checkbox"
                          checked={leftAllChecked}
                          onChange={(e) =>
                            handleFirstTableAllCheck(e.target.checked)
                          }
                        />
                      </TblHeadData>
                      <TblHeadData>S.N.</TblHeadData>
                      <TblHeadData>Student</TblHeadData>
                    </TblRow>
                  </TblHead>
                  <TblBody>
                    {firstSection.map((el, index) => (
                      <TblRow key={index + 1}>
                        <TblData>
                          <CellInput
                            type="checkbox"
                            checked={selectedFirstTableStudent.includes(el.id)}
                            onChange={(e) =>
                              handleFirstTableCheck({
                                id: el.id,
                                checked: e.target.checked,
                              })
                            }
                          />
                        </TblData>
                        <TblData>{index + 1}</TblData>
                        <TblData>{el.name}</TblData>
                      </TblRow>
                    ))}
                  </TblBody>
                </Table>
              </TransferListContainer>
            </div>

            <CenterDiv>
              <TransferButton
                onClick={handleTransferToRight}
                disabled={!selectedFirstTableStudent.length}
              >
                <ArrowForwardIosIcon />
              </TransferButton>
              <TransferButton
                onClick={handleTransferToLeft}
                disabled={!selectedSecondTableStudent.length}
              >
                <ArrowBackIosIcon />
              </TransferButton>
            </CenterDiv>

            <div>
              <TableTitle>
                {" "}
                Section -{" "}
                {sections.second.name === "null"
                  ? "Unassigned"
                  : sections.second.name}
              </TableTitle>
              <TransferListContainer>
                <Table>
                  <TblHead>
                    <TblRow>
                      <TblHeadData>
                        <CellInput
                          type="checkbox"
                          checked={rightAllChecked}
                          onChange={(e) =>
                            handleSecondTableAllCheck(e.target.checked)
                          }
                        />
                      </TblHeadData>
                      <TblHeadData>S.N.</TblHeadData>
                      <TblHeadData>Student</TblHeadData>
                    </TblRow>
                  </TblHead>
                  <TblBody>
                    {secondSection.map((el, index) => (
                      <TblRow key={index + 1}>
                        <TblData>
                          <CellInput
                            type="checkbox"
                            checked={selectedSecondTableStudent.includes(el.id)}
                            onChange={(e) =>
                              handleSecondTableCheck({
                                id: el.id,
                                checked: e.target.checked,
                              })
                            }
                          />
                        </TblData>
                        <TblData>{index + 1}</TblData>
                        <TblData>{el.name}</TblData>
                      </TblRow>
                    ))}
                  </TblBody>
                </Table>
              </TransferListContainer>
            </div>
          </Wrapper>
          <Footer>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSaveTransfer()}
              startIcon={<Save />}
            >
              Save
            </Button>
          </Footer>
        </Container>
        <BackDropLoader open={loading} />
      </FullWidthFormLayout>
    </>
  );
};

export default SectionTransferTable;
