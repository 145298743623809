export const GET_PRE_TIME_TABLE_LOADING = "GET_PRE_TIME_TABLE_LOADING";
export const GET_PRE_TIME_TABLE_ERROR = "GET_PRE_TIME_TABLE_ERROR";
export const GET_PRE_TIME_TABLE_SUCCESS = "GET_PRE_TIME_TABLE_SUCCESS";

export const GET_ALL_PRE_TIME_TABLE_LOADING = "GET_ALL_PRE_TIME_TABLE_LOADING";
export const GET_ALL_PRE_TIME_TABLE_ERROR = "GET_ALL_PRE_TIME_TABLE_ERROR";
export const GET_ALL_PRE_TIME_TABLE_SUCCESS = "GET_ALL_PRE_TIME_TABLE_SUCCESS";

export const ADD_PRE_TIME_TABLE_LOADING = "ADD_PRE_TIME_TABLE_LOADING";
export const ADD_PRE_TIME_TABLE_ERROR = "ADD_PRE_TIME_TABLE_ERROR";
export const ADD_PRE_TIME_TABLE_SUCCESS = "ADD_PRE_TIME_TABLE_SUCCESS";

export const UPDATE_PRE_TIME_TABLE_LOADING = "UPDATE_PRE_TIME_TABLE_LOADING";
export const UPDATE_PRE_TIME_TABLE_ERROR = "UPDATE_PRE_TIME_TABLE_ERROR";
export const UPDATE_PRE_TIME_TABLE_SUCCESS = "UPDATE_PRE_TIME_TABLE_SUCCESS";

export const DELETE_PRE_TIME_TABLE_LOADING = "DELETE_PRE_TIME_TABLE_LOADING";
export const DELETE_PRE_TIME_TABLE_ERROR = "DELETE_PRE_TIME_TABLE_ERROR";
export const DELETE_PRE_TIME_TABLE_SUCCESS = "DELETE_PRE_TIME_TABLE_SUCCESS";

export const ADD_PERIOD = "ADD_PERIOD";
export const UPDATE_PERIOD = "UPDATE_PERIOD";
export const DELETE_PERIOD = "DELETE_PERIOD";

export interface AddPeriod {
  type: typeof ADD_PERIOD;
  payload: { id: string; period: string; startTime: string; endTime: string };
}

export interface UpdatePeriod {
  type: typeof UPDATE_PERIOD;
  payload: { id: string; field: string; value: string };
}

export interface DeletePeriod {
  type: typeof DELETE_PERIOD;
  payload: string;
}

export interface GetAllPreTimeTableLoading {
  type: typeof GET_ALL_PRE_TIME_TABLE_LOADING;
}

export interface GetAllPreTimeTableError {
  type: typeof GET_ALL_PRE_TIME_TABLE_ERROR;
}

export interface GetAllPreTimeTableSuccess {
  type: typeof GET_ALL_PRE_TIME_TABLE_SUCCESS;
  payload: any;
}

//===============================<START>GET TIME TABLE<START>======================//

export interface GetPreTimeTableLoading {
  type: typeof GET_PRE_TIME_TABLE_LOADING;
}

export interface GetPreTimeTableError {
  type: typeof GET_PRE_TIME_TABLE_ERROR;
}

export interface GetPreTimeTableSuccess {
  type: typeof GET_PRE_TIME_TABLE_SUCCESS;
  payload: any;
}
//===============================<END>GET TIME TABLE<END>======================//

//===============================<START>Add TIME TABLE<START>======================//

export interface AddPreTimeTableLoading {
  type: typeof ADD_PRE_TIME_TABLE_LOADING;
}

export interface AddPreTimeTableError {
  type: typeof ADD_PRE_TIME_TABLE_ERROR;
}

export interface AddPreTimeTableSuccess {
  type: typeof ADD_PRE_TIME_TABLE_SUCCESS;
  payload: any;
}
//===============================<END>Add TIME TABLE<END>======================//

//===============================<START>Update TIME TABLE<START>======================//

export interface UpdatePreTimeTableLoading {
  type: typeof UPDATE_PRE_TIME_TABLE_LOADING;
}
export interface UpdatePreTimeTableError {
  type: typeof UPDATE_PRE_TIME_TABLE_ERROR;
}
export interface UpdatePreTimeTableSuccess {
  type: typeof UPDATE_PRE_TIME_TABLE_SUCCESS;
  payload: any;
}
//===============================<END>Update TIME TABLE<END>======================//

//===============================<START>Delete TIME TABLE<START>======================//

export interface DeletePreTimeTableLoading {
  type: typeof DELETE_PRE_TIME_TABLE_LOADING;
}
export interface DeletePreTimeTableError {
  type: typeof DELETE_PRE_TIME_TABLE_ERROR;
}
export interface DeletePreTimeTableSuccess {
  type: typeof DELETE_PRE_TIME_TABLE_SUCCESS;
  payload: { id: string };
}
//===============================<END>Delete TIME TABLE<END>======================//

export type PreTimeTableDispatchTypes =
  | GetPreTimeTableLoading
  | GetPreTimeTableError
  | GetPreTimeTableSuccess
  | AddPreTimeTableLoading
  | AddPreTimeTableError
  | AddPreTimeTableSuccess
  | UpdatePreTimeTableLoading
  | UpdatePreTimeTableError
  | UpdatePreTimeTableSuccess
  | DeletePreTimeTableLoading
  | DeletePreTimeTableError
  | DeletePreTimeTableSuccess
  | GetAllPreTimeTableLoading
  | GetAllPreTimeTableError
  | GetAllPreTimeTableSuccess
  | AddPeriod
  | UpdatePeriod
  | DeletePeriod;
