// -----------------<START> import modules starts----------------------------------------------//
import React from "react";
import { makeStyles, Tabs, Tab, Typography, Box } from "@material-ui/core";
import StudentList from "./StudentList";
import AddStudent from "./AddStudent";
//------------------------<END> import module ends---------------------------------------------------------------//

//-----------------<START> style starts--------------------------------------------------------//
const useStyles = makeStyles({
  root: {
    paddingRight: "10px",
    "& .MuiTab-root": {
      padding: 0,
      maxWidth: "10px !important",
    },
    "& .MuiTabs-flexContainer": {
      borderBottom: "1px solid #ddd",
    },
    "& .MuiBox-root": {
      padding: "10px 0px 0px 0px",
    },
  },
});

//----------------------<END> styled ends ---------------------------------------------------//
// ----------------------<STARTS> interface starts -------------------------------------------------------//
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
//------------------------<ENDS> interface ends -------------------------------------------------------//

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
// -----------------<START> Time table tabs component starts---------------------//
export default function RoutesTabs(props: any) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [studentData, setStudentData] = React.useState<any>(null);
  const [staffData, setStaffData] = React.useState<any>(null);


  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const onTableData = (data: any) => {
    setStudentData(data);
  };
  const onStaffTableData = (data: any) => {
    setStaffData(data);
  };


  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab label="Student List" />
        <Tab label="Add Students" />
        {/* <Tab label="Staff List" />
        <Tab label="Add Staff" /> */}
      </Tabs>
      <TabPanel value={value} index={0}>
        <StudentList routeId={props.routeId} onTableData={onTableData} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AddStudent
          SeatCapacity={props.SeatCapacity}
          routeId={props.routeId}
          studentData={studentData}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        {/* <StudentList routeId={props.routeId} onTableData={onStaffTableData} /> */}
      </TabPanel>
      <TabPanel value={value} index={3}>
        {/* <AddStudent
          SeatCapacity={props.SeatCapacity}
          routeId={props.routeId}
          studentData={staffData}
        /> */}
      </TabPanel>
    </div>
  );
}