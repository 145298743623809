import React, { useState } from "react";
import {
  CommunicationSidebar,
  ResultSMS,
  SendResultforClass,
} from "../../components";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";
import { SearchTitleI } from "../../components/Communication/SendResults/ResultSMS";

export default function SendResults() {
  const [searchTitle, setSearchTitle] = useState<SearchTitleI | null>(null);
  const nav = <CommunicationSidebar sendResults />;

  const handleSearch = (data: SearchTitleI) => {
    setSearchTitle(data);
  };
  return (
    <SubModuleLayout communicationMenuActive sideNav={nav}>
      <ResultSMS onSearch={handleSearch} />
      <SendResultforClass searchTitle={searchTitle} />
    </SubModuleLayout>
  );
}
