import axios from "axios";
import { Dispatch } from "redux";

import {
  SMS_CONFIG_LOADING,
  SMS_CONFIG_ERROR,
  SMS_CONFIG_SUCCESS,
  ADD_SMS_CONFIG_LOADING,
  ADD_SMS_CONFIG_ERROR,
  ADD_SMS_CONFIG_SUCCESS,
  UPDATE_SMS_CONFIG_LOADING,
  UPDATE_SMS_CONFIG_ERROR,
  UPDATE_SMS_CONFIG_SUCCESS,
  SmsConfigDispatchTypes,
} from "./SmsConfigActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

interface RequestDataI {
  sms_api_host: string;
  sms_api_key: string;
}

const config = {
  headers: {
    Authorization: "Token 52fb577274b983329de8deddf74359cc48495e68",
  },
};

export const GetSmsConfigData =
  () => async (dispatch: Dispatch<SmsConfigDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: SMS_CONFIG_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/sms_config/`,
        HeaderConfig()
      );

      dispatch({
        type: SMS_CONFIG_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: SMS_CONFIG_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "SMS Configuration Loading Failed",
      //   },
      // });
    }
  };

export const AddSmsConfig =
  (data: RequestDataI) =>
  async (dispatch: Dispatch<SmsConfigDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_SMS_CONFIG_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/sms_config/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_SMS_CONFIG_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "SMS Configuration Saved Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: ADD_SMS_CONFIG_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "SMS Configuration Saving Failed",
        },
      });
    }
  };

export const UpdateSmsConfig =
  (id: number | null, data: RequestDataI) =>
  async (dispatch: Dispatch<SmsConfigDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_SMS_CONFIG_LOADING,
      });

      const res = await axios.put(
        `${ADMIN_API_URL}/sms_config/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_SMS_CONFIG_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "SMS Configuration Saved Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: UPDATE_SMS_CONFIG_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "SMS Configuration Saving Failed",
        },
      });
    }
  };
