import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { useScrollPosition } from '../utils/useScrollPosition';
import { useHistory } from 'react-router';

const useStyles = makeStyles({
  schoolNameHeader: {
    padding: '8px',
  },
  boxContainer: {
    border: '1px solid #d3cfcf',
    '& .MuiButton-root': {
      boxSizing: 'border-box',
      borderRadius: '2px',
      boxShadow: '0px',
      padding: '8px 18px',
    },
  },
  dateText: {
    color: '#132E98',
    padding: '5px 10px',
    borderRadius: '0px',
    backgroundColor: '#F2F3F6',
    fontWeight: 'bold',
    fontSize: '12px',
  },
  schoolTitle: {
    color: '#132E98',
    fontSize: '18px',
  },
  buttonWidth: {
    minWidth: '100%',
  },
});

const Index = (props: any) => {
  const classes = useStyles();
  const history = useHistory();

  const [margin, setMargin] = useState('0px');

  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (currPos.y < -10) {
        setMargin('-65px');
      } else {
        setMargin('0px');
      }
    },
    [margin]
  );

  return (
    <>
      <Card
        variant="outlined"
        className={classes.boxContainer}
        style={{
          marginBottom: '15px',
          marginRight: '30px',
          position: 'fixed',
          marginTop: margin,
          transition: 'all 500ms ease-in-out',
        }}
      >
        <CardContent style={{ paddingLeft: '18px' }}>
          <Typography style={{ marginBottom: '10px' }} variant="h4">
            Quick Links
          </Typography>
          <Grid container spacing={2}>
            <Grid item md={4}>
              <Button
                className={classes.buttonWidth}
                disableElevation
                variant="contained"
                color="secondary"
                // startIcon={<DeleteIcon />}
                onClick={() => history.push('/student')}
              >
                Students
              </Button>
            </Grid>
            <Grid item md={4}>
              <Button
                className={classes.buttonWidth}
                disableElevation
                variant="contained"
                color="secondary"
                onClick={() => history.push('/staff')}
                // startIcon={<DeleteIcon />}
              >
                Staffs
              </Button>
            </Grid>
            <Grid item md={4}>
              <Button
                className={classes.buttonWidth}
                disableElevation
                variant="contained"
                color="secondary"
                onClick={() => history.push('/time-table')}
                // startIcon={<DeleteIcon />}
              >
                Timetable
              </Button>
            </Grid>
            <Grid item md={4}>
              <Button
                className={classes.buttonWidth}
                disableElevation
                variant="contained"
                color="secondary"
                onClick={() => history.push('/exam-schedule')}

                // startIcon={<DeleteIcon />}
              >
                Exam Schedule
              </Button>
            </Grid>
            <Grid item md={4}>
              <Button
                className={classes.buttonWidth}
                onClick={() => history.push('/student-leave')}
                disableElevation
                variant="contained"
                color="secondary"
                // startIcon={<DeleteIcon />}
              >
                Student Leave
              </Button>
            </Grid>
            <Grid item md={4}>
              <Button
                className={classes.buttonWidth}
                onClick={() => history.push('/leave')}
                disableElevation
                variant="contained"
                color="secondary"
                // startIcon={<DeleteIcon />}
              >
                Staff Leave
              </Button>
            </Grid>
            <Grid item md={4}>
              <Button
                className={classes.buttonWidth}
                onClick={() => history.push('/bus-user')}
                disableElevation
                variant="contained"
                color="secondary"
                // startIcon={<DeleteIcon />}
              >
                Bus User
              </Button>
            </Grid>
            <Grid item md={4}>
              <Button
                className={classes.buttonWidth}
                onClick={() => history.push('/income')}
                disableElevation
                variant="contained"
                color="secondary"
                // startIcon={<DeleteIcon />}
              >
                Finance
              </Button>
            </Grid>
            <Grid item md={4}>
              <Button
                className={classes.buttonWidth}
                onClick={() => history.push('/classroom/syllabus')}
                disableElevation
                variant="contained"
                color="secondary"
                // startIcon={<DeleteIcon />}
              >
                Syllabus
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default Index;
