// ---------------------------- <START> module imports start ----------------------------------//
import React, { useEffect, useState } from 'react';
import { Typography, Paper } from '@material-ui/core';
import {
  EditOutlined,
  DeleteForeverOutlined,
  FormatListBulleted,
} from '@material-ui/icons';
import { useTableStyles } from '../../Styles/TableStyles';
import { useTable } from '../../Reusable';
import CustomizedDialogs from '../../Reusable/Dialogs/DeleteDialog';
import ActionButton from '../../Reusable/Buttons/ActionButton';
import Popups from '../../Reusable/Dialogs/Popups';
import { VehicleTypeI } from '../../../actions/Transport/Vehicles/VehiclesActionTypes';
import { useDispatch, useSelector } from 'react-redux';
import {
  GetVehicle,
  DeleteVehicle,
} from '../../../actions/Transport/Vehicles/VehiclesAction';
import { RootStore } from '../../../store';
import {
  ItemDeleteButton,
  ItemEditButton,
  ItemFormList,
} from '../../Reusable/Buttons/TableButton';

// ----------------------------<END> module imports end ----------------------------------//
// ---------------------------- <START> interface starts ----------------------------------//
interface PropsI {
  onEditData: (value: VehicleTypeI) => void;
  edit: { check: boolean };
}

interface HeadCellsI {
  id: string;
  label: string;
}
// ---------------------------- <END> interface ends ----------------------------------//
// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: 'vehicle_no', label: 'Vehicle Number' },
  { id: 'vehicle_brand', label: 'Brand' },
  { id: 'model', label: 'Model' },
  { id: 'manufactured_year', label: 'Manufactured Year' },
  { id: 'max_speed', label: 'Max Speed (km/hr)' },
  { id: 'seat_capacity', label: 'Seat Capacity' },
  { id: 'action', label: 'Action' },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Vehicle List Component Starts ---------------------------//
const VehiclesList = (props: PropsI) => {
  const { onEditData, edit } = props;

  const classes = useTableStyles();

  // States declaration
  const [tableData, setTableData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [routeId, setrouteId] = useState<string>('');
  // const [SeatCapacity, setSeatCapacity] = useState<number | null>();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [openPopup, setOpenPopup] = useState(false);

  // Retrieving re-usable components from useTable
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  // Function for handling action icons click event
  const handleAddId = (id: string, seat: number) => {
    setOpenPopup(true);
    setrouteId(id);
    // setSeatCapacity(seat);
  };

  // Modal States
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string>('');

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const handleDeleteModal = (value: boolean, id: string) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteVehicle(itemId));
    setDeleteModalOpen(false);
  };
  // reusable popup

  const handleClose = (value: boolean) => {
    setOpenPopup(value);
  };

  const handleEditClicked = (data: VehicleTypeI) => {
    setEditMode(true);
    onEditData(data);
  };

  //============================ <START> CYCLE HOOKS <START> =======================//
  const dispatch = useDispatch();
  const vehicleSelector = useSelector((state: RootStore) => state.vehicle);

  useEffect(() => {
    dispatch(GetVehicle());
  }, []);

  useEffect(() => {
    setEditMode(edit?.check);
  }, [edit]);
  console.log(vehicleSelector);

  useEffect(() => {
    const data = vehicleSelector.vehicle;
    setLoading(vehicleSelector.loading);
    setTableData(data);
  }, [vehicleSelector]);

  const getCustomTableRow = (item: any) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {item.vehicle_no}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.vehicle_brand}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.vehicle_model}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.manufactured_year}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.max_speed}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.seat_capacity}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {/* <ItemFormList
                        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
                            handleAddId(item.id, item.seat_capacity)
                        }
                    /> */}
          <ItemEditButton
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleEditClicked(item)
            }
          />
          <ItemDeleteButton
            disabled={editMode}
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleDeleteModal(true, item.id)
            }
          />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <>
      <Paper className={classes.root}>
        <Typography variant="h6">Vehicles List</Typography>
        <TableContainer
          loading={loading}
          items={tableData}
          headCells={headCells}
          getCustomTableRow={getCustomTableRow}
          rowsPerPage={10}
          disableSearch
        />
      </Paper>
      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
        dialogTitle="Are you sure to delete this record?"
        okText="Delete"
      />
      <Popups
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        onClose={handleClose}
        // fullWidth
        maxWidth="md"
        title="Student List"
        colored
      >
        {/* <RoutesModal SeatCapacity={SeatCapacity} routeId={routeId} /> */}
      </Popups>
    </>
  );
};

export default VehiclesList;
