import {
  SMS_CONFIG_LOADING,
  SMS_CONFIG_SUCCESS,
  SMS_CONFIG_ERROR,
  ADD_SMS_CONFIG_LOADING,
  ADD_SMS_CONFIG_ERROR,
  ADD_SMS_CONFIG_SUCCESS,
  UPDATE_SMS_CONFIG_LOADING,
  UPDATE_SMS_CONFIG_ERROR,
  UPDATE_SMS_CONFIG_SUCCESS,
} from "../../actions/GeneralSettings/SmsConfig/SmsConfigActionTypes";

interface SmsConfigI {
  id: number;
  sms_api_host: string;
  sms_api_key: string;
}

interface InitialStateI {
  loading: boolean;
  sms_config: SmsConfigI[];
}

const initialState: InitialStateI = {
  loading: false,
  sms_config: [],
};

const EmailSettingReducer = (
  state: InitialStateI = initialState,
  action: any
): InitialStateI => {
  switch (action.type) {
    case SMS_CONFIG_LOADING:
      return {
        loading: true,
        sms_config: state.sms_config,
      };

    case SMS_CONFIG_SUCCESS:
      return {
        loading: false,
        sms_config: action.payload,
      };

    case SMS_CONFIG_ERROR:
      return {
        loading: false,
        sms_config: state.sms_config,
      };
    case ADD_SMS_CONFIG_LOADING:
      return {
        loading: true,
        sms_config: state.sms_config,
      };

    case ADD_SMS_CONFIG_ERROR:
      return {
        loading: false,
        sms_config: state.sms_config,
      };

    case ADD_SMS_CONFIG_SUCCESS:
      return {
        loading: false,
        sms_config: [...state.sms_config, action.payload],
      };

    case UPDATE_SMS_CONFIG_LOADING:
      return {
        loading: true,
        sms_config: state.sms_config,
      };

    case UPDATE_SMS_CONFIG_ERROR:
      return {
        loading: false,
        sms_config: state.sms_config,
      };

    case UPDATE_SMS_CONFIG_SUCCESS:
      const current_data: SmsConfigI[] = state.sms_config;
      const updated_data: SmsConfigI[] = [];

      current_data.map((element: SmsConfigI) => {
        element.id === action.payload.id
          ? updated_data.push({
              id: action.payload.id,
              sms_api_host: action.payload.sms_api_host,
              sms_api_key: action.payload.sms_api_key,
            })
          : updated_data.push(element);
      });

      return {
        loading: false,
        sms_config: updated_data,
      };

    default:
      return state;
  }
};

export default EmailSettingReducer;
