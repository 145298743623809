import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { AddIncomeTypeI } from "../../actions/Fees/Income/IncomeActionTypes";
import AddIncomeTestForm from "../../components/Finance/AddIncome/AddIncomeTestForm";
import AddIncomeTable from "../../components/Finance/AddIncome/AddIncomeTable";
import FinanceSidebar from "../../components/Finance/FinanceSidebar";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const AddIncomePage = () => {
  const nav = <FinanceSidebar AddIncome />;

  const [formData, setFormData] = useState<AddIncomeTypeI | null>(null);
  const [count, setCount] = useState<number>(0);
  const [edit, setEdit] = useState<any>();

  const fetchEditData = (data: AddIncomeTypeI) => {
    setFormData({ ...data, count: count });
    setCount(count + 1);
  };

  const onEditCheck = (value: boolean) => {
    setEdit({ check: value, count: count });
  };

  return (
    <>
      <SubModuleLayout sideNav={nav}>
        <Grid container>
          <Grid item xs={3}>
            <AddIncomeTestForm onEditCheck={onEditCheck} editData={formData} />
          </Grid>
          <Grid item xs={9}>
            <AddIncomeTable edit={edit} onEditData={fetchEditData} />
          </Grid>
        </Grid>
      </SubModuleLayout>
    </>
  );
};

export default AddIncomePage;
