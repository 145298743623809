import React from "react";
import { CommunicationSidebar } from "../../components";
import SmsTemplate from "../../components/Communication/SmsTemplate/SmsTemplate";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

export default function SmsLogPage() {
  const nav = <CommunicationSidebar smsTemplates />;
  return (
    <SubModuleLayout communicationMenuActive sideNav={nav}>
      <SmsTemplate />
    </SubModuleLayout>
  );
}
