import React, { useEffect, useLayoutEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { StylesProvider, ThemeProvider } from "@material-ui/core";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import store from "./store";
import {
  StudentListTable,
  StudentProfile,
  NewNavbar,
  ImportStudentInfo,
  StaffProfile,
} from "./components";
import {
  GeneralSetting,
  EmailSettingPage,
  SMSTemplatePage,
  SessionPage,
  StudentListPage,
  StudentAdmissionPage,
  AddStaffPage,
  DepartmentPage,
  DesignationPage,
  ClassPage,
  SubjectPage,
  SubjectGroupPage,
  SectionPage,
  AddMarksPage,
  ExamSchedulePage,
  ExaminationPage,
  AssignClassTeacherPage,
  GradePage,
  CommunicationPage,
  AnnoucementPage,
  AddAnnoucModalPage,
  SmsTemplatePage,
  TimetablePage,
  SendResults,
  MarksheetPage,
  ReportPage,
  AdmitCardPage,
  GradesheetPage,
  StudentHousePage,
  DisabledStudentListPage,
  ErrorPage,
  StudentCategoryPage,
  Grade,
  FeesPage,
  FineTypePage,
  PaymentMethodPage,
  FeeLedgerPage,
  CollectFeesPage,
  StaffListPage,
  FeeTypeReport,
  EventsPage,
  BusUserPage,
  RoutesPage,
  VehiclePage,
  EventTypePage,
  Dashboard,
  GuardianDetailPage,
  MeetingTypePage,
  MeetingPage,
  StudentLeavePage,
  GuardianAddPage,
  RolesPage,
  IncomePage,
  AddIncomePage,
  ExpensePage,
  AnnouncementTypePage,
  ApplyFeePage,
  MonthlyFeeReportPage,
  AnnouncementFormPage,
  StudentAttendanceReportPage,
  AttendanceDeviceListPage,
  AttendanceDeviceInfoPage,
  AttendanceTodayPage,
  AttendanceReportPage,
  AttendanceDetailsPage,
} from "./pages";
import TimeTableList from "./components/Dashboard/TimeTableList/TimetableList";
import SmsLogPage from "./pages/Communication/SmsLogPage";
import { CustomAlert } from "./components/utils/SnackBarAlert";
import { useDispatch, useSelector } from "react-redux";
import {
  GetToken,
  useAxiosInterceptors,
} from "./actions/Auth/Login/LoginAction";
import PrivateRoute from "./components/utils/PrivateRoute";
import DiscountTypePage from "./pages/Finance/DiscountTypePage";
import theme from "./common/Global.css";
import StudentComplaintPage from "./pages/Student/StudentComplaintPage";
import Sidebar from "./components/NewSideBar/Sidebar";
import EditStaffPage from "./pages/HumanResource/EditStaffPage";
import LeaveTypePage from "./pages/HumanResource/LeaveTypePage";
import LeavePage from "./pages/HumanResource/LeavePage";
import studentImage from "./components/Student/StudentAdmission/studentImage";
// Parent Dashboard
import { RootStore } from "./store";
import LoaderPage from "./pages/LoaderPage";
// Student Dashboard
import EnoteCategoryPage from "./pages/Academic/EnoteCategoryPage";
import RubricCategoryPage from "./pages/Academic/RubricCategoryPage";
import AssignRubricPage from "./pages/Academic/AssignRubricPage";
import ChooseRole from "./components/Auth/ChooseRole";
import GuardianProfile from "./components/Student/GuardianDetail/GuardianProfile/GuardianProfile";
import NotificationsPage from "./components/Header/NotificationPopover/NotificationPage";
import AdminSyllabusPage from "./pages/ClassRoom/AdminSyllabusPage";
import AdminLessonPlanPage from "./pages/ClassRoom/AdminLessonPlanPage";
import ClassRoomPage from "./pages/ClassRoom/ClassRoomPage";
import EnotesPage from "./pages/ClassRoom/EnotesPage";
import AssignmentPage from "./pages/ClassRoom/AssignmentPage";
import TeacherRoomPage from "./pages/ClassRoom/TeacherRoomPage";
import IdCardTemplatePage from "./pages/Reports/Templates/IdCard/IdCardTemplatePage";
import TemplateBrowsePage from "./pages/Reports/Templates/IdCard/TemplateBrowsePage";
import CustomizePage from "./pages/Reports/Templates/IdCard/CustomizePage";
import AddExpensePage from "./pages/Finance/AddExpensePage";
import FeeTypePage from "./pages/Finance/FeeTypePage";
import ScholarshipTypePage from "./pages/Scholarship/ScholarshipTypePage";
import MarksheetTemplatePage from "./pages/Reports/Templates/Marksheet/MarksheetTemplatePage";
import BrowseMarksheetPage from "./pages/Reports/Templates/Marksheet/BrowseMarksheetPage";
import CustomizeMarksheetPage from "./pages/Reports/Templates/Marksheet/CustomizeMarksheetPage";
import AdmitCardTemplatePage from "./pages/Reports/Templates/AdmitCard/AdmitCardTemplatePage";
import AdmitCardTemplateBrowsePage from "./pages/Reports/Templates/AdmitCard/AdmitCardTemplateBrowsePage";
import AdmitCardCustomixePage from "./pages/Reports/Templates/AdmitCard/AdmitCardCustomizePage";
import ReportSettingPage from "./pages/Reports/ReportSettingPage";
import PasswordSetup from "./components/Auth/PasswordSetup";
import "./index.css";
import CharacterCertificatePage from "./pages/Reports/CharacterCertificatePage";
import CharacterCertificateTemplatePage from "./pages/Reports/Templates/CharacterCertificate/CharacterCertificateTemplatePage";
import BrowseCharacterCertificatePage from "./pages/Reports/Templates/CharacterCertificate/BrowseCharacterCertificatePage";
import CustomizeCharacterCertificatePage from "./pages/Reports/Templates/CharacterCertificate/CustomizeCharacterCertificatePage";
import ManagePasswordPage from "./pages/GeneralSetting/ManagePasswordPage";
import StaffIdCardListPage from "./pages/HumanResource/StaffIdCardListPage";
import SendStudentCredentials from "./components/Student/StudentDetail/ImportStudentInfo/SendStudentCredentials";
import StaffExcelImport from "./components/HumanResource/Staff/StaffExcelImport";
import StaffAttendancePage from "./pages/HumanResource/StaffAttendancePage";
import StaffAttendanceReportPage from "./pages/HumanResource/StaffAttendanceReportPage";
import ResultLedgerPage from "./pages/Reports/ResultLedgerPage";
import Library from "./components/library/Library";
import gpsTracking from "./components/library/gpsTracking";
import APEpage from "./pages/Result/APEpage";
import AccountantNavbar from "./components/Navbar/AccountantNavbar";
import PreTimeTablePage from "./pages/Academic/PreTimeTablePage";
import CreateTimeTablePage from "./pages/Academic/CreateTimeTablePage";
import PrayerPage from "./pages/GeneralSetting/PrayerPage";
import TermsandConditionsPage from "./pages/GeneralSetting/TermsandConditionsPage";
import checkBrands from "./config/checkBrands";
import hamroacademy_favicon from "./assets/images/favicon.png";
import nakshatraacademy_favicon from "./assets/images/favicon-nakshtra.jpeg";
import OpeningBalancePage from "./components/Finance/OpeningBalance/OpeningBalancePage";
import MiscFeePage from "./components/Finance/Misc/MiscFeePage";
import CollectFeePage from "./components/Finance/CollectFees/CollectFeePage";
import DaybookPage from "./components/Finance/Daybook/DaybookPage";
import FeeStatementPage from "./components/Finance/FeeStatement/FeeStatementPage";
import FeeIncomePage from "./components/Finance/FeeIncome/FeeIncomePage";
import DueReportPage from "./components/Finance/DueReport/DueReportPage";
import VoidReportPage from "./components/Finance/VoidReport/VoidReportPage";
import DiscountSummaryPage from "./components/Finance/DiscountSummary/DiscountSummaryPage";
import FeeStatementDetail from "./components/Finance/FeeStatement/FeeStatementDetail";
import ScholarshipApplyPage from "./pages/Scholarship/ScholarshipApplyPage";
import ScholarshipReportPage from "./pages/Scholarship/ScholarshipReportPage";
import ScholarshipReportDetail from "./components/Scholarship/ScholarshipReport/ScholarshipReportDetial";
import BillAdjustmentPage from "./components/Finance/BillAdjustment/BillAdjustmentPage";
import VoidPage from "./components/Finance/Void/VoidPage";
import MiscellaneousPage from "./pages/GeneralSetting/MiscellaneousPage";
import ApplyLeaveTypePage from "./pages/HumanResource/ApplyLeaveTypePage";
import CompetitionPage from "./pages/Event/CompetitionPage";
import VoidDetail from "./components/Finance/Void/VoidDetail";
import LoginForm from "./components/Auth/Login/Login";
import PasswordRecovery from "./components/Auth/PasswordRecovery/PasswordRecovery";
import OtpValidation from "./components/Auth/OtpValidation/OtpValidation";
import ChangePassword from "./components/Auth/ChangePassword/ChangePassword";
import PreventedRoute from "./components/utils/PreventedRoute";
import StudentRollNumberPage from "./pages/Student/StudentRollNumberPage";
import DiscountPage from "./pages/Finance/DiscountPage";
import AppliedFeesPage from "./pages/Finance/AppliedFeesPage";
import "./components/utils/dateUtils";
import Fallback from "./common/FallBack/AdminStudentFallBack";
import { ErrorBoundary } from "react-error-boundary";
import DisabledStaffListPage from "./pages/HumanResource/DisabledStaffPage";
import TestRoutesPage from "./pages/Transport/TestRoutesPage";
import DueAlertPage from "./components/Finance/DueReport/DueAlertPage";
import SubjectClassPage from "./pages/Examinations/SubjectClassPage";
import IntimateFeePage from "./components/Finance/IntimateFee/IntimateFeePage";
import StudentBusStopMappingPage from "./pages/Transport/StudentBusStopMapping";
import StudentMappingFormPage from "./pages/Transport/StudentMappingFormPage";
import LocationPage from "./pages/Transport/LocationPage";
import BusWiseDetailPage from "./pages/Transport/BusWiseDetailPage";
import { GetGeneralInfoData } from "./actions/GeneralSettings/GeneralInfo/GeneralInfoAction";
import LedgerPage from "./pages/Result/LedgerPage";
import OptionalSubjectPage from "./pages/Examinations/OptionalSubjectPage";
import OptSubjectStudentPage from "./pages/Examinations/OptSubjectStudentPage";
import SectionTransferPage from "./pages/Student/SectionTransferPage";
import NewClassPage from "./pages/Academic/NewClassPage";
import NewSubjectTeacher from "./pages/Academic/SubjectTeacher";

function App() {
  const dispatch = useDispatch();
  const [permissions, setPermissions] = useState<string[] | null>(null);
  const [loaderVisible, setLoaderVisible] = useState<boolean>(true);
  const [appTheme, setAppTheme] = useState(theme);
  const [toggleMenu, setToggleMenu] = useState(false);

  const authState = useSelector((state: RootStore) => state.auth);
  const authToken = localStorage.getItem("hamro_token");

  // Authentications
  useLayoutEffect(() => {
    setAppTheme(theme);
    dispatch(GetToken());
    useAxiosInterceptors();

    const favicon: any = document.getElementById("favicon");
    if (favicon !== null) {
      if (checkBrands("nakshatraacademy")) {
        favicon.href = nakshatraacademy_favicon;
        document.title = "Nakshatra Academy";
      } else {
        favicon.href = hamroacademy_favicon;
        document.title = "Hamro Academy";
      }
    }
  }, []);

  useEffect(() => {
    if (authState && authState.user && authState.user.groups) {
      setPermissions(authState.user.groups.map((item: any) => item.name));
    }
    authState?.general_info?.id &&
      dispatch(GetGeneralInfoData(authState?.general_info?.id));
  }, [authState]);

  // Render routes based on roles
  const SmartRoute = () => {
    // return (
    //   <Switch>
    //     <Route exact path="/" component={ClassPage} />
    //     <Route exact path="/newClass" component={NewClassPage} />
    //     <Route exact path="/newSubjectTeacher" component={NewSubjectTeacher} />
    //   </Switch>
    // );
    const permission = localStorage.getItem("role");

    if (permission === "Administrator") {
      return <DashboardRoute />;
    } else {
      return <ErrorPage />;
    }
  };

  // If everything is okay remove loader
  if (permissions) {
    window.setTimeout(() => setLoaderVisible(false), 1000);
  }

  // Display loader if authenticaAccountDashboardAppted
  if (authToken && loaderVisible) {
    return <LoaderPage />;
  }

  return (
    <Provider store={store}>
      <StylesProvider injectFirst>
        <ThemeProvider theme={appTheme}>
          <StyledThemeProvider theme={appTheme}>
            <Switch>
              <Route exact path="/login" component={LoginForm} />
              {/* <Route exact path="/test/login" component={LoginForm} /> */}
              <Route
                exact
                path="/confirm-password/:user_id"
                component={PasswordSetup}
              />
              <Route path="/authentication" component={AuthenticationRoute} />
              <PrivateRoute exact path="/choose-role" component={ChooseRole} />
              <PrivateRoute
                exact
                path="/notifications"
                component={NotificationsPage}
              />
              <PrivateRoute path="/" component={SmartRoute} />
            </Switch>
            <CustomAlert />
          </StyledThemeProvider>
        </ThemeProvider>
      </StylesProvider>
    </Provider>
  );
}

const AuthenticationRoute = () => {
  const AuthErrorPage = () => {
    return (
      <div style={{ marginTop: "-100px" }}>
        <ErrorPage />
      </div>
    );
  };

  return (
    <Switch>
      <PreventedRoute
        exact
        path="/authentication/password-recovery"
        component={PasswordRecovery}
      />
      <PreventedRoute
        exact
        path="/authentication/otp-validation"
        component={OtpValidation}
      />
      <PreventedRoute
        exact
        path="/authentication/password-reset"
        component={ChangePassword}
      />
      <Route exact component={AuthErrorPage} />
    </Switch>
  );
};

const DashboardRoute = () => {
  const isAdmin = localStorage.getItem("role") === "Administrator";
  const dispatch = useDispatch();
  const errorHandler = (error: any, errorInfo: any) => {
    // console.log(error, errorInfo);
  };

  return (
    <React.Fragment>
      <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
        {isAdmin ? <NewNavbar /> : <AccountantNavbar />}

        {/* <NewNavbar /> */}
        <Switch>
          {/* Dashboard */}
          <PrivateRoute exact path="/" component={Dashboard} />

          {/* Student */}
          <PrivateRoute exact path="/student" component={StudentListPage} />
          <PrivateRoute
            exact
            path="/student-admission"
            component={StudentAdmissionPage}
          />
          <PrivateRoute
            exact
            path="/student/edit/:studentID"
            component={StudentAdmissionPage}
          />
          <PrivateRoute
            exact
            path="/guardian-details"
            component={GuardianDetailPage}
          />
          <PrivateRoute
            exact
            path="/add-guardian"
            component={GuardianAddPage}
          />
          <PrivateRoute
            exact
            path="/guardian/edit/:guardianID"
            component={GuardianAddPage}
          />
          <PrivateRoute
            exact
            path="/guardian-details/profile/:guardianID"
            component={GuardianProfile}
          />
          <PrivateRoute
            exact
            path="/disabled-student"
            component={DisabledStudentListPage}
          />
          <PrivateRoute
            exact
            path="/import-student-info"
            component={ImportStudentInfo}
          />
          <PrivateRoute
            exact
            path="/student-attendance-report"
            component={StudentAttendanceReportPage}
          />

          {/* Student Profile */}
          <PrivateRoute
            exact
            path="/student/profile/:studentID"
            component={StudentProfile}
          />

          <PrivateRoute
            exact
            path="/student-categories"
            component={StudentCategoryPage}
          />
          {/* <PrivateRoute exact path="/bulk-delete" component={BulkDeletePage} /> */}
          <PrivateRoute
            exact
            path="/student-house"
            component={StudentHousePage}
          />
          <PrivateRoute
            exact
            path="/student-list-table"
            component={StudentListTable}
          />

          <PrivateRoute
            exact
            path="/student-complaint"
            component={StudentComplaintPage}
          />
          <PrivateRoute
            exact
            path="/student-leave"
            component={StudentLeavePage}
          />

          {/* academics */}
          {/* <PrivateRoute exact path="/section" component={SectionPage} /> */}
          <PrivateRoute exact path="/class" component={NewClassPage} />
          <PrivateRoute exact path="/subject" component={SubjectPage} />

          <PrivateRoute
            exact
            path="/subject-group"
            component={SubjectGroupPage}
          />
          <PrivateRoute
            exact
            path="/assign-class-teacher"
            component={AssignClassTeacherPage}
          />
          <PrivateRoute exact path="/time-table" component={TimetablePage} />
          <PrivateRoute
            exact
            path="/pre-timetable"
            component={PreTimeTablePage}
          />
          <PrivateRoute
            exact
            path="/create-timetable"
            component={CreateTimeTablePage}
          />
          {/* <PrivateRoute exact path="/grade" component={Grade} /> */}
          <PrivateRoute
            exact
            path="/enote-category"
            component={EnoteCategoryPage}
          />
          <PrivateRoute
            exact
            path="/rubric-category"
            component={RubricCategoryPage}
          />
          <PrivateRoute
            exact
            path="/assign-rubric"
            component={AssignRubricPage}
          />

          <PrivateRoute
            exact
            path="/classroom/online-class"
            component={ClassRoomPage}
          />
          <PrivateRoute exact path="/classroom/enotes" component={EnotesPage} />
          <PrivateRoute
            exact
            path="/classroom/teacher"
            component={TeacherRoomPage}
          />
          <PrivateRoute
            exact
            path="/classroom/assignment"
            component={AssignmentPage}
          />
          <PrivateRoute
            exact
            path="/classroom/syllabus"
            component={AdminSyllabusPage}
          />
          <PrivateRoute
            exact
            path="/classroom/lesson-plan"
            component={AdminLessonPlanPage}
          />

          {/* general setting */}
          <PrivateRoute
            exact
            path="/general-setting"
            component={GeneralSetting}
          />
          <PrivateRoute exact path="/prayer" component={PrayerPage} />
          <PrivateRoute
            exact
            path="/termsandconditions"
            component={TermsandConditionsPage}
          />
          <PrivateRoute
            exact
            path="/miscellaneous"
            component={MiscellaneousPage}
          />

          <PrivateRoute exact path="/session" component={SessionPage} />
          <PrivateRoute
            exact
            path="/manage-password"
            component={ManagePasswordPage}
          />
          <PrivateRoute exact path="/roles" component={RolesPage} />
          <PrivateRoute
            exact
            path="/email-setting"
            component={EmailSettingPage}
          />
          <PrivateRoute
            exact
            path="/sms-template"
            component={SMSTemplatePage}
          />

          {/* Human Resource  */}
          <PrivateRoute exact path="/department" component={DepartmentPage} />
          <PrivateRoute exact path="/designation" component={DesignationPage} />
          <PrivateRoute exact path="/staff" component={StaffListPage} />
          <PrivateRoute
            exact
            path="/staff-attendance"
            component={StaffAttendancePage}
          />
          <PrivateRoute
            exact
            path="/staff-attendance-report"
            component={StaffAttendanceReportPage}
          />
          <PrivateRoute exact path="/add-staff" component={AddStaffPage} />
          <PrivateRoute
            exact
            path="/staff/edit/:id"
            component={EditStaffPage}
          />
          <PrivateRoute exact path="/meeting" component={MeetingPage} />
          <PrivateRoute
            exact
            path="/meeting-type"
            component={MeetingTypePage}
          />
          <PrivateRoute exact path="/leave-type" component={LeaveTypePage} />
          <PrivateRoute
            exact
            path="/apply-leave-type"
            component={ApplyLeaveTypePage}
          />
          <PrivateRoute exact path="/leave" component={LeavePage} />
          <PrivateRoute
            exact
            path="/staff-id-card"
            component={StaffIdCardListPage}
          />
          <PrivateRoute
            exact
            path="/staff-import"
            component={StaffExcelImport}
          />

          {/* Examniation */}
          <PrivateRoute exact path="/examination" component={ExaminationPage} />
          <PrivateRoute
            exact
            path="/exam-schedule"
            component={ExamSchedulePage}
          />
          <PrivateRoute exact path="/ape-factor" component={APEpage} />

          <PrivateRoute exact path="/add-marks" component={AddMarksPage} />
          <PrivateRoute exact path="/grades" component={GradePage} />
          <PrivateRoute exact path="/timetable" component={TimeTableList} />
          <PrivateRoute
            exact
            path="/staff/profile/:staffID"
            component={StaffProfile}
          />

          {/* Communincation */}
          <PrivateRoute
            exact
            path="/communication"
            component={AnnoucementPage}
          />
          <PrivateRoute
            exact
            path="/add-annoucement"
            component={AddAnnoucModalPage}
          />
          <PrivateRoute exact path="/sms" component={CommunicationPage} />
          <PrivateRoute exact path="/sms-log" component={SmsLogPage} />
          <PrivateRoute
            exact
            path="/sms-templates"
            component={SmsTemplatePage}
          />
          <PrivateRoute exact path="/send-results" component={SendResults} />
          <PrivateRoute
            exact
            path="/announcement-type"
            component={AnnouncementTypePage}
          />
          <PrivateRoute
            exact
            path="/announcement-form"
            component={AnnouncementFormPage}
          />
          {/* <PrivateRoute
            exact
            path="/announcement-draft"
            component={AnnouncementDraftListPage}
          /> */}
          {/* <PrivateRoute
            exact
            path="/announcement-draft/:id"
            component={AnnouncementDraftPage}
          /> */}

          {/* Reports */}
          <PrivateRoute exact path="/report" component={ReportPage} />
          <PrivateRoute exact path="/marksheets" component={MarksheetPage} />
          <PrivateRoute exact path="/admit-card" component={AdmitCardPage} />
          <PrivateRoute
            exact
            path="/character-certificate"
            component={CharacterCertificatePage}
          />
          <PrivateRoute exact path="/marksheet" component={MarksheetPage} />
          <PrivateRoute exact path="/gradesheet" component={GradesheetPage} />

          <PrivateRoute
            exact
            path="/id-cards-templates"
            component={IdCardTemplatePage}
          />
          <PrivateRoute
            exact
            path="/browse-id-card-templates"
            component={TemplateBrowsePage}
          />
          <PrivateRoute
            exact
            path="/id-card/:cardID/:mode/customize"
            component={CustomizePage}
          />
          <PrivateRoute
            exact
            path="/id-card/:cardID/:mode/update"
            component={CustomizePage}
          />

          <PrivateRoute
            exact
            path="/admit-cards-templates"
            component={AdmitCardTemplatePage}
          />
          <PrivateRoute
            exact
            path="/browse-admit-card-templates"
            component={AdmitCardTemplateBrowsePage}
          />
          <PrivateRoute
            exact
            path="/admit-card/:cardID/customize"
            component={AdmitCardCustomixePage}
          />
          <PrivateRoute
            exact
            path="/admit-card/:cardID/update"
            component={AdmitCardCustomixePage}
          />

          <PrivateRoute
            exact
            path="/marksheet-templates"
            component={MarksheetTemplatePage}
          />
          <PrivateRoute
            exact
            path="/browse-marksheet-templates"
            component={BrowseMarksheetPage}
          />
          <PrivateRoute
            exact
            path="/marksheet/:cardID/customize"
            component={CustomizeMarksheetPage}
          />
          <PrivateRoute
            exact
            path="/marksheet/:cardID/update"
            component={CustomizeMarksheetPage}
          />
          <PrivateRoute
            exact
            path="/character-certificate-templates"
            component={CharacterCertificateTemplatePage}
          />
          <PrivateRoute
            exact
            path="/browse-character-certificate-templates"
            component={BrowseCharacterCertificatePage}
          />
          <PrivateRoute
            exact
            path="/character-certificate/:cardID/customize"
            component={CustomizeCharacterCertificatePage}
          />
          <PrivateRoute
            exact
            path="/character-certificate/:cardID/update"
            component={CustomizeCharacterCertificatePage}
          />
          <PrivateRoute
            exact
            path="/marksheet-ledger"
            component={ResultLedgerPage}
          />
          <PrivateRoute
            exact
            path="/reports-setting"
            component={ReportSettingPage}
          />

          {/* finance  */}
          <PrivateRoute exact path="/income" component={IncomePage} />
          <PrivateRoute exact path="/expenses" component={ExpensePage} />
          <PrivateRoute exact path="/add-income" component={AddIncomePage} />
          <PrivateRoute exact path="/add-expenses" component={AddExpensePage} />
          <PrivateRoute exact path="/apply-fee" component={ApplyFeePage} />
          <PrivateRoute
            exact
            path="/opening-balance"
            component={OpeningBalancePage}
          />
          <PrivateRoute
            exact
            path="/miscellaneous-fee"
            component={MiscFeePage}
          />
          <PrivateRoute
            exact
            path="/bill-adjustment"
            component={BillAdjustmentPage}
          />

          {/* fees */}
          <PrivateRoute exact path="/fees" component={FeesPage} />
          <PrivateRoute exact path="/fee-type" component={FeeTypePage} />
          <PrivateRoute exact path="/fine-type" component={FineTypePage} />
          <PrivateRoute exact path="/collect-fees" component={CollectFeePage} />
          <PrivateRoute
            exactApplyFeePage
            path="/discount-type"
            component={DiscountTypePage}
          />
          <PrivateRoute
            exact
            path="/payment-method"
            component={PaymentMethodPage}
          />
          <PrivateRoute exact path="/fee-ledger" component={FeeLedgerPage} />
          <PrivateRoute
            exact
            path="/collect-fees"
            component={CollectFeesPage}
          />
          <PrivateRoute exact path="/day-book" component={DaybookPage} />
          <PrivateRoute
            exact
            path="/intimation-slip"
            component={IntimateFeePage}
          />

          <PrivateRoute exact path="/void-report" component={VoidPage} />
          <PrivateRoute
            exact
            path="/fee-statement"
            component={FeeStatementPage}
          />
          <PrivateRoute
            exact
            path="/fee-statement/:invoiceID/:studentID"
            component={FeeStatementDetail}
          />
          <PrivateRoute
            exact
            path="/void-detail/:invoiceID"
            component={VoidDetail}
          />
          <PrivateRoute exact path="/fee-income" component={FeeIncomePage} />
          <PrivateRoute
            exact
            path="/fee-type-report"
            component={FeeTypeReport}
          />
          <PrivateRoute exact path="/void-report" component={VoidReportPage} />
          <PrivateRoute
            exact
            path="/discount-summary"
            component={DiscountSummaryPage}
          />

          {/* events */}
          <PrivateRoute exact path="/event" component={EventTypePage} />
          <PrivateRoute exact path="/events" component={EventsPage} />
          <PrivateRoute exact path="/competition" component={CompetitionPage} />

          {/* transport */}
          <PrivateRoute exact path="/vehicles" component={VehiclePage} />
          <PrivateRoute exact path="/routes" component={RoutesPage} />
          <PrivateRoute exact path="/location" component={LocationPage} />
          <PrivateRoute exact path="/test-routes" component={TestRoutesPage} />
          <PrivateRoute exact path="/bus-user" component={BusUserPage} />
          <PrivateRoute
            exact
            path="/student-bus-stop-mapping"
            component={StudentBusStopMappingPage}
          />
          <PrivateRoute
            exact
            path="/student-mapping-form"
            component={StudentMappingFormPage}
          />
          <PrivateRoute
            exact
            path="/bus-wise-details"
            component={BusWiseDetailPage}
          />

          <PrivateRoute exact path="/sidebar" component={Sidebar} />
          <PrivateRoute exact path="/studentupload" component={studentImage} />

          {/* Scholarship */}
          <PrivateRoute
            exact
            path="/scholarship-type"
            component={ScholarshipTypePage}
          />
          <PrivateRoute
            exact
            path="/apply-scholarship"
            component={ScholarshipApplyPage}
          />
          <PrivateRoute
            exact
            path="/scholarship-report"
            component={ScholarshipReportPage}
          />
          <PrivateRoute
            exact
            path="/scholarship-report/:scholarshipID"
            component={ScholarshipReportDetail}
          />
          <PrivateRoute exact path="/due-report" component={DueReportPage} />
          <PrivateRoute exact path="/due-alert" component={DueAlertPage} />

          <PrivateRoute
            exact
            path="/monthly-report"
            component={MonthlyFeeReportPage}
          />
          <PrivateRoute
            exact
            path="/student-credentials"
            component={SendStudentCredentials}
          />
          <PrivateRoute exact path="/library" component={Library} />
          {/* <PrivateRoute exact path="/library" component={Library} /> */}

          <PrivateRoute
            exact
            path="/live-bus-location"
            component={gpsTracking}
          />
          <PrivateRoute
            exact
            path="/student-roll-num"
            component={StudentRollNumberPage}
          />
          <PrivateRoute exact path="/apply-discount" component={DiscountPage} />
          <PrivateRoute
            exact
            path="/applied-fees"
            component={AppliedFeesPage}
          />
          <PrivateRoute
            exact
            path="/disabled-staffs"
            component={DisabledStaffListPage}
          />
          <PrivateRoute
            exact
            path="/subject-class"
            component={SubjectClassPage}
          />
          <PrivateRoute exact path="/ledger" component={LedgerPage} />
          <PrivateRoute
            exact
            path="/opt-subjects"
            component={OptionalSubjectPage}
          />
          <PrivateRoute
            exact
            path="/assign-opt-subjects"
            component={OptSubjectStudentPage}
          />
          <PrivateRoute
            exact
            path="/section-transfer"
            component={SectionTransferPage}
          />
          <PrivateRoute
            exact
            path="/subject-teacher"
            component={NewSubjectTeacher}
          />
          <PrivateRoute
            exact
            path="/attendance-device"
            component={AttendanceDeviceListPage}
          />
          <PrivateRoute
            exact
            path="/attendance-device/:configId/:deviceId"
            component={AttendanceDeviceInfoPage}
          />
          <PrivateRoute
            exact
            path="/attendance-today"
            component={AttendanceTodayPage}
          />
          <PrivateRoute
            exact
            path="/attendance-report"
            component={AttendanceReportPage}
          />
          <PrivateRoute
            exact
            path="/attendance-report/:staffId"
            component={AttendanceDetailsPage}
          />

          {/* Error */}
          <PrivateRoute component={ErrorPage} />
        </Switch>

        {/* <Footer /> */}
      </ErrorBoundary>
    </React.Fragment>
  );
};

export default App;
