import {
  GET_STAFF_PROFILE_LOADING,
  GET_STAFF_PROFILE_ERROR,
  GET_STAFF_PROFILE_SUCCESS,
  GET_TEACHER_ACADEMIC_INFO_LOADING,
  GET_TEACHER_ACADEMIC_INFO_ERROR,
  GET_TEACHER_ACADEMIC_INFO_SUCCESS,
  GET_TEACHER_TIMETABLE_LOADING,
  GET_TEACHER_TIMETABLE_ERROR,
  GET_TEACHER_TIMETABLE_SUCCESS,
  StaffProfileTypeI,
  AcademicInfoClassI,
  AcademicInfoSubjectI,
  StaffProfileDispatchTypes,
  UPDATE_STAFF_USERNAME_LOADING,
  UPDATE_STAFF_USERNAME_SUCCESS,
  UPDATE_STAFF_USERNAME_ERROR,
  GET_STAFF_PROFILE_LEAVE_ERROR,
  GET_STAFF_PROFILE_LEAVE_LOADING,
  GET_STAFF_PROFILE_LEAVE_SUCCESS,
  StaffLeaveInfoType,
} from "../../actions/HumanResource/StaffProfile/StaffProfileActionTypes";
import {
  SET_SYSTEM_ROLES_SUCCESS,
  SystemRolesDispatchTypes,
  SystemRolesTypeI,
} from "../../actions/HumanResource/StaffRole/StaffRoleActionTypes";

interface InitialStateI {
  loading: boolean;
  staff_profile: StaffProfileTypeI | null;
  staff_roles: SystemRolesTypeI[];
  teacher_classes: AcademicInfoClassI[];
  teacher_subjects: AcademicInfoSubjectI[];
  teacher_timetable: any[];
  actionPerformed: boolean;
  staff_leave: StaffLeaveInfoType[];
}

const initialState: InitialStateI = {
  loading: false,
  staff_profile: null,
  staff_roles: [],
  teacher_classes: [],
  teacher_subjects: [],
  teacher_timetable: [],
  actionPerformed: false,
  staff_leave: [],
};

const StaffProfileReducer = (
  state: InitialStateI = initialState,
  action: StaffProfileDispatchTypes | SystemRolesDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_STAFF_PROFILE_LOADING:
      return {
        ...state,
        loading: true,
        actionPerformed: false,
      };

    case GET_STAFF_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_STAFF_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        staff_profile: action.payload,
        staff_roles: action.payload.user.groups,
      };

    case GET_TEACHER_ACADEMIC_INFO_LOADING:
      return {
        ...state,
        loading: true,
        teacher_classes: [],
        teacher_subjects: [],
      };

    case GET_TEACHER_ACADEMIC_INFO_ERROR:
      return {
        ...state,
        loading: false,
        teacher_classes: [],
        teacher_subjects: [],
      };

    case GET_TEACHER_ACADEMIC_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        teacher_classes: action.payload.academic_class,
        teacher_subjects: action.payload.subjects,
      };

    case GET_TEACHER_TIMETABLE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_TEACHER_TIMETABLE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_TEACHER_TIMETABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        teacher_timetable: action.payload,
      };

    case SET_SYSTEM_ROLES_SUCCESS:
      return {
        ...state,
        staff_roles: action.payload,
        actionPerformed: true,
      };

    case UPDATE_STAFF_USERNAME_LOADING:
      return {
        ...state,
        loading: true,
        actionPerformed: false,
      };

    case UPDATE_STAFF_USERNAME_SUCCESS:
      return {
        ...state,
        loading: false,
        actionPerformed: true,
      };

    case UPDATE_STAFF_USERNAME_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_STAFF_PROFILE_LEAVE_LOADING:
      return {
        ...state,
        loading: true,
        actionPerformed: false,
        staff_leave: [],
      };

    case GET_STAFF_PROFILE_LEAVE_ERROR:
      return {
        ...state,
        loading: false,
        staff_leave: [],
      };

    case GET_STAFF_PROFILE_LEAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        staff_leave: action.payload,
      };

    default:
      return state;
  }
};

export default StaffProfileReducer;
