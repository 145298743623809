export const STUDENT_ADMISSION_LOADING = "STUDENT_ADMISSION_LOADING";
export const STUDENT_ADMISSION_ERROR = "STUDENT_ADMISSION_ERROR";
export const STUDENT_ADMISSION_SUCCESS = "STUDENT_ADMISSION_SUCCESS";

export const RESET_ALL_STATE = "RESET_ALL_STATE"

export const STUDENT_UPDATE_LOADING = "STUDENT_UPDATE_LOADING";
export const STUDENT_UPDATE_ERROR = "STUDENT_UPDATE_ERROR";
export const STUDENT_UPDATE_SUCCESS = "STUDENT_UPDATE_SUCCESS";

export const STUDENT_LIST_LOADING = "STUDENT_LIST_LOADING";
export const STUDENT_LIST_ERROR = "STUDENT_LIST_ERROR";
export const STUDENT_LIST_SUCCESS = "STUDENT_LIST_SUCCESS";

export const STUDENT_LIST_CLASS_LOADING = "STUDENT_LIST_LOADING";
export const STUDENT_LIST_CLASS_ERROR = "STUDENT_LIST_ERROR";
export const STUDENT_LIST_CLASS_SUCCESS = "STUDENT_LIST_SUCCESS";

export const STUDENT_BY_ID_LOADING = "STUDENT_BY_ID_LOADING";
export const STUDENT_BY_ID_ERROR = "STUDENT_BY_ID_ERROR";
export const STUDENT_BY_ID_SUCCESS = "STUDENT_BY_ID_SUCCESS";

export const STUDENT_DISABLE_LOADING = "STUDENT_DISABLE_LOADING";
export const STUDENT_DISABLE_ERROR = "STUDENT_DISABLE_ERROR";
export const STUDENT_DISABLE_SUCCESS = "STUDENT_DISABLE_SUCCESS";

export const DISABLED_STUDENT_LIST_LOADING = "DISABLED_STUDENT_LIST_LOADING";
export const DISABLED_STUDENT_LIST_ERROR = "DISABLED_STUDENT_LIST_ERROR";
export const DISABLED_STUDENT_LIST_SUCCESS = "DISABLED_STUDENT_LIST_SUCCESS";

export const STUDENT_ENABLE_LOADING = "STUDENT_ENABLE_LOADING";
export const STUDENT_ENABLE_ERROR = "STUDENT_ENABLE_ERROR";
export const STUDENT_ENABLE_SUCCESS = "STUDENT_ENABLE_SUCCESS";

export const STUDENT_BULK_DELETE_LOADING = "STUDENT_BULK_DELETE_LOADING";
export const STUDENT_BULK_DELETE_ERROR = "STUDENT_BULK_DELETE_ERROR";
export const STUDENT_BULK_DELETE_SUCCESS = "STUDENT_BULK_DELETE_SUCCESS";

export const STUDENT_SEARCH_LOADING = "STUDENT_SEARCH_LOADING";
export const STUDENT_SEARCH_ERROR = "STUDENT_SEARCH_ERROR";
export const STUDENT_SEARCH_SUCCESS = "STUDENT_SEARCH_SUCCESS";

export const STUDENT_SIBLINGS_LOADING = "STUDENT_SIBLINGS_LOADING";
export const STUDENT_SIBLINGS_ERROR = "STUDENT_SIBLINGS_ERROR";
export const STUDENT_SIBLINGS_SUCCESS = "STUDENT_SIBLINGS_SUCCESS";

export const GET_STUDENT_BY_CLASS_LOADING = "GET_STUDENT_BY_CLASS_LOADING";
export const GET_STUDENT_BY_CLASS_ERROR = "GET_STUDENT_BY_CLASS_ERROR";
export const GET_STUDENT_BY_CLASS_SUCCESS = "GET_STUDENT_BY_CLASS_SUCCESS";

export const SEARCH_STUDENT_LOADING = "SEARCH_STUDENT_LOADING";
export const SEARCH_STUDENT_SUCCESS = "SEARCH_STUDENT_SUCCESS";
export const SEARCH_STUDENT_ERROR = "SEARCH_STUDENT_ERROR";

export const UPLOAD_STUDENT_INFO_LOADING = "UPLOAD_STUDENT_INFO_LOADING";
export const UPLOAD_STUDENT_INFO_SUCCESS = "UPLOAD_STUDENT_INFO_SUCCESS";
export const UPLOAD_STUDENT_INFO_ERROR = "UPLOAD_STUDENT_INFO_ERROR";

export const SEND_STUDENT_CREDENTIALS_SUCCESS =
  "SEND_STUDENT_CREDENTIALS_SUCCESS";
export const SEND_STUDENT_CREDENTIALS_ERROR = "SEND_STUDENT_CREDENTIALS_ERROR";
export const SEND_STUDENT_CREDENTIALS_LOADING =
  "SEND_STUDENT_CREDENTIALS_LOADING";
export interface StudentsByClassI {
  id: string;
  admission_number: string;
  academic_id: number;
  student_first_name: string;
  student_last_name: string;
  phone_number: string;
  roll_number: "3";
}

export interface SearchedStudentsI {
  id: string;
  admission_number: string;
  academic_id: string;
  student_first_name: string;
  student_last_name: string;
  date_of_birth: string;
  phone_number: string;
  address: string;
  roll_number: string;
  student_photo: string;
  guardian_first_name: string;
  guardian_last_name: string;
  guardian_phone_number: string;
  grade: string;
  section: string | null;
}

//===============================<START>STUDENT ADMISSION <START>======================//
interface StudentListI {
  id: number;
  student: string;
}
export interface StudentAdmissionLoading {
  type: typeof STUDENT_ADMISSION_LOADING;
}

export interface StudentAdmissionError {
  type: typeof STUDENT_ADMISSION_ERROR;
}

export interface StudentAdmissionSuccess {
  type: typeof STUDENT_ADMISSION_SUCCESS;
  payload: any;
}

//===============================<END>STUDENT ADMISSION<END>======================//

//===============================<START>STUDENT ADMISSION <START>======================//

export interface StudentUpdateLoading {
  type: typeof STUDENT_UPDATE_LOADING;
}

export interface StudentUpdateError {
  type: typeof STUDENT_UPDATE_ERROR;
  payload: any;
}

export interface StudentUpdateSuccess {
  type: typeof STUDENT_UPDATE_SUCCESS;
  payload: any;
}

//===============================<END>STUDENT ADMISSION<END>======================//

//===============================<START>STUDENT LIST<START>======================//

export interface StudentListLoading {
  type: typeof STUDENT_LIST_LOADING;
}

export interface StudentListError {
  type: typeof STUDENT_LIST_ERROR;
}

export interface StudentListSuccess {
  type: typeof STUDENT_LIST_SUCCESS;
  payload: any;
}

//===============================<END>STUDENT LIST<END>======================//
export interface StudentListClassLoading {
  type: typeof STUDENT_LIST_CLASS_LOADING;
}

export interface StudentListClassError {
  type: typeof STUDENT_LIST_CLASS_ERROR;
}

export interface StudentListClassSuccess {
  type: typeof STUDENT_LIST_CLASS_SUCCESS;
  payload: any;
}

//===============================<START>STUDENT LIST<START>======================//

export interface StudentByIdLoading {
  type: typeof STUDENT_BY_ID_LOADING;
}

export interface StudentByIdError {
  type: typeof STUDENT_BY_ID_ERROR;
}

export interface StudentByIdSuccess {
  type: typeof STUDENT_BY_ID_SUCCESS;
  payload: any;
}

//===============================<END>STUDENT LIST<END>======================//

//===============================<START>DISABLE STUDENT <START>======================//

export interface StudentDisableLoading {
  type: typeof STUDENT_DISABLE_LOADING;
}

export interface StudentDisableError {
  type: typeof STUDENT_DISABLE_ERROR;
}

export interface StudentDisableSuccess {
  type: typeof STUDENT_DISABLE_SUCCESS;
  payload: any;
}

//===============================<END>DISABLE STUDENT<END>======================//

//===============================<START>DISABLED STUDENT LIST<START>======================//

export interface DisabledStudentListLoading {
  type: typeof DISABLED_STUDENT_LIST_LOADING;
}

export interface DisabledStudentListError {
  type: typeof DISABLED_STUDENT_LIST_ERROR;
}

export interface DisabledStudentListSuccess {
  type: typeof DISABLED_STUDENT_LIST_SUCCESS;
  payload: any;
}

//===============================<END>DISABLED STUDENT LIST<END>======================//

//===============================<START>DISABLED STUDENT LIST<START>======================//

export interface StudentEnableLoading {
  type: typeof STUDENT_ENABLE_LOADING;
}

export interface StudentEnableError {
  type: typeof STUDENT_ENABLE_ERROR;
}

export interface StudentEnableSuccess {
  type: typeof STUDENT_ENABLE_SUCCESS;
  payload: any;
}

export interface StudentSiblingsLoading {
  type: typeof STUDENT_SIBLINGS_LOADING;
}

export interface StudentSiblingsError {
  type: typeof STUDENT_SIBLINGS_ERROR;
}

export interface StudentSiblingsSuccess {
  type: typeof STUDENT_SIBLINGS_SUCCESS;
  payload: any;
}
//===============================<END>DISABLED STUDENT LIST<END>======================//

//===============================<START>DISABLED STUDENT LIST<START>======================//

export interface StudentBulkDeleteLoading {
  type: typeof STUDENT_BULK_DELETE_LOADING;
}

export interface StudentBulkDeleteError {
  type: typeof STUDENT_BULK_DELETE_ERROR;
}

export interface StudentBulkDeleteSuccess {
  type: typeof STUDENT_BULK_DELETE_SUCCESS;
  payload: any;
}

//===============================<END>DISABLED STUDENT LIST<END>======================//

//===============================<START>STUDENT SEARCH<START>======================//

export interface StudentSearchLoading {
  type: typeof STUDENT_SEARCH_LOADING;
}

export interface StudentSearchError {
  type: typeof STUDENT_SEARCH_ERROR;
}

export interface StudentSearchSuccess {
  type: typeof STUDENT_SEARCH_SUCCESS;
  payload: any;
}

//===============================<END>STUDENT SEARCH<END>======================//

//===============================<START>STUDENT SEARCH<START>======================//

export interface UploadStudentInfoLoading {
  type: typeof UPLOAD_STUDENT_INFO_LOADING;
}

export interface UploadStudentInfoError {
  type: typeof UPLOAD_STUDENT_INFO_ERROR;
}

export interface UploadStudentInfoSuccess {
  type: typeof UPLOAD_STUDENT_INFO_SUCCESS;
  payload: any;
}

//===============================<END>STUDENT SEARCH<END>======================//

//===============================<START> GET STUDENT BY CLASS <START>======================//

export interface GetStudentByClassLoading {
  type: typeof GET_STUDENT_BY_CLASS_LOADING;
}

export interface GetStudentByClassError {
  type: typeof GET_STUDENT_BY_CLASS_ERROR;
}

export interface GetStudentByClassSuccess {
  type: typeof GET_STUDENT_BY_CLASS_SUCCESS;
  payload: any;
}

export interface SearchStudentLoading {
  type: typeof SEARCH_STUDENT_LOADING;
}

export interface SearchStudentError {
  type: typeof SEARCH_STUDENT_ERROR;
}

export interface SearchStudentSuccess {
  type: typeof SEARCH_STUDENT_SUCCESS;
  payload: any;
}

export interface SendStudentCredentialsLoading {
  type: typeof SEND_STUDENT_CREDENTIALS_LOADING;
}

export interface SendStudentCredentialsSuccess {
  type: typeof SEND_STUDENT_CREDENTIALS_SUCCESS;
}

export interface SendStudentCredentialsError {
  type: typeof SEND_STUDENT_CREDENTIALS_ERROR;
}

export interface resetAllStudentData {
  type: typeof RESET_ALL_STATE;
}

//===============================<END> GET STUDENT BY CLASS<END>======================//

export type StudentDispatchTypes =
  | StudentAdmissionLoading
  | StudentAdmissionError
  | StudentAdmissionSuccess
  | StudentListLoading
  | StudentListError
  | StudentListSuccess
  | StudentListClassLoading
  | StudentListClassError
  | StudentListClassSuccess
  | StudentDisableLoading
  | StudentDisableError
  | StudentDisableSuccess
  | DisabledStudentListLoading
  | DisabledStudentListError
  | DisabledStudentListSuccess
  | StudentEnableLoading
  | StudentEnableError
  | StudentEnableSuccess
  | StudentBulkDeleteLoading
  | StudentBulkDeleteError
  | StudentBulkDeleteSuccess
  | StudentUpdateLoading
  | StudentUpdateError
  | StudentUpdateSuccess
  | StudentByIdLoading
  | StudentByIdError
  | StudentByIdSuccess
  | StudentSearchLoading
  | StudentSearchError
  | StudentSearchSuccess
  | GetStudentByClassLoading
  | GetStudentByClassError
  | GetStudentByClassSuccess
  | UploadStudentInfoLoading
  | UploadStudentInfoError
  | UploadStudentInfoSuccess
  | SearchStudentLoading
  | SearchStudentError
  | SearchStudentSuccess
  | SendStudentCredentialsLoading
  | SendStudentCredentialsSuccess
  | SendStudentCredentialsError
  | StudentSiblingsLoading
  | StudentSiblingsError
  | StudentSiblingsSuccess
  | resetAllStudentData;
