import {
  GET_LEAVE_LOADING,
  GET_LEAVE_ERROR,
  GET_LEAVE_SUCCESS,
  ADD_LEAVE_LOADING,
  ADD_LEAVE_ERROR,
  ADD_LEAVE_SUCCESS,
  DELETE_LEAVE_LOADING,
  DELETE_LEAVE_ERROR,
  DELETE_LEAVE_SUCCESS,
  UPDATE_LEAVE_LOADING,
  UPDATE_LEAVE_ERROR,
  UPDATE_LEAVE_SUCCESS,
  UPDATE_LEAVE_STATUS_LOADING,
  UPDATE_LEAVE_STATUS_ERROR,
  UPDATE_LEAVE_STATUS_SUCCESS,
  LeaveTypeI,
  LeaveDispatchTypes,
  APPROVE_OR_DECLINE_SUCCESS,
  APPROVE_OR_DECLINE_LOADING,
  APPROVE_OR_DECLINE_ERROR,
  GET_TODAY_ON_LEAVE_LOADING,
  GET_TODAY_ON_LEAVE_ERROR,
  GET_TODAY_ON_LEAVE_SUCCESS,
  APPLY_LEAVE_TYPE_ERROR,
  APPLY_LEAVE_TYPE_LOADING,
  APPLY_LEAVE_TYPE_SUCCESS,
} from "../../actions/HumanResource/Leave/LeaveActionTypes";

interface InitialStateI {
  loading: boolean;
  loadingOnLeave: boolean;
  leaves: any;
  todayOnLeaves: any;
  errors: any;
  applyLeaveLoading: boolean;
}

const initialState: InitialStateI = {
  loading: false,
  leaves: [],
  loadingOnLeave: false,
  todayOnLeaves: [],
  errors: null,
  applyLeaveLoading: false,
};

const LeaveReducer = (
  state: InitialStateI = initialState,
  action: LeaveDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_LEAVE_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };

    case GET_LEAVE_ERROR:
      return {
        ...state,
        loading: false,
        errors: null,
      };

    case GET_LEAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        leaves: action.payload,
        errors: null,
      };

    case ADD_LEAVE_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };

    case ADD_LEAVE_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case ADD_LEAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        leaves: [...state.leaves, action.payload],
        errors: null,
      };

    case UPDATE_LEAVE_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };

    case UPDATE_LEAVE_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case UPDATE_LEAVE_SUCCESS:
      const current_data: LeaveTypeI[] = state.leaves;
      const index: number = current_data.findIndex(
        (element) => element.id === action.payload.id
      );
      current_data[index] = action.payload;

      return {
        ...state,
        loading: false,
        leaves: current_data,
        errors: null,
      };
    case UPDATE_LEAVE_STATUS_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };

    case UPDATE_LEAVE_STATUS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_LEAVE_STATUS_SUCCESS:
      const tempData = state.leaves;
      const leaveIndex = tempData.results.findIndex(
        (el: any) => el.id === action.payload.id
      );
      tempData.results[leaveIndex] = {
        ...tempData.results[leaveIndex],
        status: action.payload.status,
        remarks: action.payload.remarks,
      };

      return {
        ...state,
        loading: false,
        leaves: tempData,
      };

    case DELETE_LEAVE_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };

    case DELETE_LEAVE_ERROR:
      return {
        ...state,
        loading: false,
        errors: null,
      };

    case DELETE_LEAVE_SUCCESS:
      const new_data: LeaveTypeI[] = state.leaves;
      const item_index = new_data.findIndex(
        (element) => element.id === action.payload.id
      );
      if (item_index !== -1) {
        new_data.splice(item_index, 1);
      }

      return {
        ...state,
        loading: false,
        leaves: new_data,
        errors: null,
      };
    case APPROVE_OR_DECLINE_SUCCESS:
      const temp_data = state.leaves;
      const indexNum: number = temp_data.findIndex(
        (element: any) => element.id === action.payload.id
      );
      const temp_leave = {
        ...temp_data[indexNum],
        status: action.payload.status,
      };
      temp_data[indexNum] = temp_leave;

      return {
        ...state,
        loading: false,
        leaves: temp_data,
        errors: null,
      };
    case APPROVE_OR_DECLINE_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case APPROVE_OR_DECLINE_ERROR:
      return {
        ...state,
        errors: null,
        loading: false,
      };
    // Today on leave
    case GET_TODAY_ON_LEAVE_LOADING:
      return {
        ...state,
        loadingOnLeave: true,
        errors: null,
      };
    case GET_TODAY_ON_LEAVE_ERROR:
      return {
        ...state,
        loadingOnLeave: false,
        errors: null,
      };
    case GET_TODAY_ON_LEAVE_SUCCESS:
      return {
        ...state,
        loadingOnLeave: false,
        todayOnLeaves: action.payload,
        errors: null,
      };
    case APPLY_LEAVE_TYPE_LOADING:
      return {
        ...state,
        applyLeaveLoading: true,
      };
    case APPLY_LEAVE_TYPE_SUCCESS:
      return {
        ...state,
        applyLeaveLoading: false,
      };
    case APPLY_LEAVE_TYPE_ERROR:
      return {
        ...state,
        applyLeaveLoading: false,
      };
    default:
      return state;
  }
};

export default LeaveReducer;
