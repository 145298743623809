import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_INTIMATION_SLIP_ERROR,
  GET_INTIMATION_SLIP_LOADING,
  GET_INTIMATION_SLIP_SUCCESS,
  CLEAR_INTIMATION_SLIP,
  IntimationSlipDispatchTypes,
} from "./IntimationSlipActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

export const GetIntimationSlip =
  (
    id: number,
    grade: string,
    section: string,
    month: string,
    start_date: string,
    end_date: string
  ) =>
  async (dispatch: Dispatch<IntimationSlipDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_INTIMATION_SLIP_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/update-invoice/intimate_invoice/?page=${id}&grade=${grade}&section_id=${section}&month=${month}&from_date=${start_date}&to_date=${end_date}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_INTIMATION_SLIP_SUCCESS,
        payload: res.data,
      });
    } catch (error: any) {
      dispatch({
        type: GET_INTIMATION_SLIP_ERROR,
      });
      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: error?.response.data?.error
            ? error?.response.data?.error[0]
            : "Something went wrong!",
        },
      });
    }
  };

export const ClearIntimationSlip =
  () =>
  async (dispatch: Dispatch<IntimationSlipDispatchTypes | SetSnackBarI>) => {
    dispatch({
      type: CLEAR_INTIMATION_SLIP,
    });
  };
