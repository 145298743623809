import { Button, Grid, InputBase, TextField } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import {
  AddStaffDocument,
  GetStaffDocument,
} from '../../../../../actions/HumanResource/StaffDocument/StaffDocumentAction';
import { DocumentTypeI } from '../../../../../actions/HumanResource/StaffDocument/StaffDocumentActionTypes';
import { useStyles } from '../../../../Styles/FormStyles';

export interface StaffUploadPropsI {
  handleClose: () => void;
}

function StaffUpload(props: StaffUploadPropsI) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { staffID } = useParams<{ staffID: string }>();

  const [document1Name, setDocument1Name] = useState<any | null>(null);
  const [title, setTitle] = useState<string | null>('');
  const [docName, setDocName] = useState<string | null>('');
  const [file, setFile] = useState<any | null>(null);

  const { register, handleSubmit, setValue, reset, errors } =
    useForm<DocumentTypeI>({
      mode: 'onChange',
    });

  const handleDocumetUpload = (value: any) => {
    const fileTypes = ['jpg', 'png', 'jpeg', 'pdf', 'docx', 'doc'];
    const fileType = value[0]?.name.split('.')[1];

    setDocument1Name(value);
    setDocName(value[0].name);
    setFile(value[0]);
    // if (!fileTypes.includes(fileType)) {
    //   setDocument1Name("");
    //   return `please upload a valid file format. (${fileTypes})`;
    // } else if (value[0].size > 2000000000000) {
    //   setDocument1Name("");
    //   return "Photo size must be lower than 200MB";
    // } else {
    //   setDocument1Name(value);
    //   setDocName(value[0].name);
    //   setFile(value[0]);
    //   // documents[index].document = value[0];
    // }
  };

  const handleUploadFile = (elem: any) => {
    const data: any = {
      title: elem.title,
      staff: staffID,
    };
    const finalValue = new FormData();
    finalValue.append('document', file);
    Object.keys(data).map((key) => finalValue.append(key, data[key]));

    dispatch(AddStaffDocument(finalValue));
    reset();
    setFile(null);
    props.handleClose();

    // Avoiding relative file path
    dispatch(GetStaffDocument(staffID));
  };

  return (
    <>
      <Grid container className={classes.formWrapper}>
        <form onSubmit={handleSubmit(handleUploadFile)}>
          <Grid container style={{ display: 'flex' }}>
            <Grid item xs={5}>
              <InputBase
                className={classes.input}
                id={`id${0}`}
                type="file"
                style={{ opacity: 0, position: 'absolute' }}
                // name={`document_${0}`}
                // value={docName}
                name="document"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleDocumetUpload(event.target.files);
                }}
                inputRef={register}
              />
              <label htmlFor={`id${0}`}>
                <Button
                  className={classes.browseButton}
                  color="primary"
                  component="span"
                  style={{ width: '200px' }}
                >
                  {file != null ? docName : 'Browse'}
                </Button>
              </label>
            </Grid>
            <Grid item xs={7}>
              <TextField
                variant="outlined"
                placeholder="Document Name"
                autoFocus={true}
                name="title"
                fullWidth
                style={{ marginTop: '7px' }}
                onChange={(event) => {
                  setTitle(event.target.value);
                }}
                inputRef={register({ required: true })}
              ></TextField>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="flex-end"
            className={classes.searchBoxButton}
          >
            <Button
              startIcon={<CloudUpload />}
              color="primary"
              variant="outlined"
              type="submit"
            >
              Upload
            </Button>
          </Grid>
        </form>
      </Grid>
    </>
  );
}

export default StaffUpload;
