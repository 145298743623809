import { Paper, Tab, Tabs } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { RootStore } from "../../../../store";
import { TabPanel } from "../../../Student/StudentProfile/File/NewTabs";
import { useCardStyles } from "../../../Styles/CardStyles";
import StaffDocument from "./StaffTabs/StaffDocument";
import StaffLeaveInfo from "./StaffTabs/StaffLeaveInfo";
import StaffRoles from "./StaffTabs/StaffRoles";
import StaffTimeTable from "./StaffTabs/StaffTimetable";
import PersonalInfo from "./StaffTabs/PersonalInfo";
import AcademicInfo from "./StaffTabs/AcademicInfo";
import FacultyInfo from "./StaffTabs/FacultyInfo";
import OtherInfo from "./StaffTabs/OtherInfo";

function StaffTabs(props: any) {
  const classes = useCardStyles();
  const [value, setValue] = React.useState(0);
  const { staffID } = useParams<{ staffID: string }>();
  const [staff, setStaff] = useState<any>([]);
  const [isTeacher, setIsTeacher] = useState<boolean>(false);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   staffID != undefined && dispatch(getStaffDetails(staffID));
  // }, []);

  const { staffDetail } = useSelector(
    (state: RootStore) => state.humanResource
  );

  useEffect(() => {
    if (staffDetail != null) {
      setStaff(staffDetail);

      try {
        const groups = staffDetail.user.groups;
        const role = groups.find((item: any) => item.name === "Teacher");

        if (role) {
          setIsTeacher(true);
        } else {
          setIsTeacher(false);
        }
      } catch (err) {
        console.log(err);
      }
    }
  }, [staffDetail]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Paper className={classes.tabRoot}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        // variant="fullWidth"
      >
        {/* <Tab label="Explore" /> */}
        <Tab label="Personal Info" />
        <Tab label="Academic Info" />
        <Tab label="Faculty Info" />
        <Tab label="Other Info" />
        <Tab label="Documents" />
        <Tab label="System Role" />
        <Tab label="Leave Info" />
        {isTeacher && <Tab label="Time Table" />}
      </Tabs>
      {/* <TabPanel value={value} index={0}>
        <StaffExplore />
      </TabPanel> */}
      <TabPanel value={value} index={0}>
        <PersonalInfo staff={staff} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AcademicInfo staff={staff} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <FacultyInfo staff={staff} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <OtherInfo staff={staff} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <StaffDocument hasUpload={true} hasDeleteBtn={true} Documents={true} />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <StaffRoles user="staff" />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <StaffLeaveInfo staff={staff} />
      </TabPanel>
      <TabPanel value={value} index={7}>
        <StaffTimeTable staffId={staffID} />
      </TabPanel>
    </Paper>
  );
}

export default StaffTabs;
