import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import SaveIcon from "@material-ui/icons/Save";
import { RootStore } from "../../../store";
import { useStyles } from "../../Styles/FormStyles";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormSaveLoadingButton } from "../../Reusable/Buttons/FormButton";
import Spinner from "../../../common/spinnerSVG/Spinner";
import styled from "styled-components";
import {
  createStaff,
  getStaffs,
} from "../../../rtk/features/humanResource/humanResourceThunk";
import {
  createDeviceConfig,
  updateDeviceConfig,
} from "../../../rtk/features/attendanceDevice/attendanceDeviceThunk";

const ModalForm = styled.form`
  padding: 0 20px;
  max-width: 480px;
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 11px;
  margin-left: 10px;
`;

interface StaffFormProps {
  username: string;
  password: string;
  ip_address: string;
  port: string;
}

type ComponentProps = {
  handleModalClose: () => void;
  editData: {
    id: string;
    sn: number;
    username: string;
    password: string;
    status: "Online" | "Offline";
    ipAddress: string;
    port: string;
  } | null;
};

const AttendanceConfigModal = (props: ComponentProps) => {
  // Custom Styles
  const classes = useStyles();
  const { handleModalClose, editData } = props;

  // Destructuring react-form-hook methods
  const { register, handleSubmit, errors, setValue } = useForm<StaffFormProps>({
    mode: "onChange",
  });

  // Dispatch Variable
  const dispatch = useDispatch();

  const { loading, actionPerformed } = useSelector(
    (state: RootStore) => state.attendanceDevice
  );

  // State Declaration
  //   const [sync, toggleSync] = useState<boolean>(false);

  useEffect(() => {
    if (actionPerformed) {
      handleModalClose();
    }
  }, [actionPerformed]);

  useEffect(() => {
    if (editData) {
      setValue("username", editData.username);
      setValue("password", editData.password);
      setValue("ip_address", editData.ipAddress);
      setValue("port", editData.port);
    }
  }, [editData]);

  const onSubmitModal: SubmitHandler<StaffFormProps> = (data) => {
    if (editData) {
      dispatch(
        updateDeviceConfig({
          id: editData.id,
          username: data.username,
          password: data.password,
          ip_address: data.ip_address,
          port: data.port,
        })
      );
    } else {
      dispatch(
        createDeviceConfig({
          username: data.username,
          password: data.password,
          ip_address: data.ip_address,
          port: data.port,
        })
      );
    }
  };

  return (
    <ModalForm onSubmit={handleSubmit(onSubmitModal)}>
      <Grid container>
        <Grid
          item
          xs={12}
          className={classes.formWrapper}
          style={{ marginBottom: "1rem" }}
        >
          <InputLabel>
            Username <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <TextField
            variant="outlined"
            name="username"
            placeholder="Username"
            inputRef={register({
              required: { value: true, message: "Username is required." },
            })}
          />
          <ErrorMessage>{errors.username?.message}</ErrorMessage>
        </Grid>

        <Grid
          item
          xs={12}
          className={classes.formWrapper}
          style={{ marginBottom: "1rem" }}
        >
          <InputLabel>
            Password <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <TextField
            variant="outlined"
            name="password"
            placeholder="Password"
            inputRef={register({
              required: { value: true, message: "Password is required." },
            })}
          />
          <ErrorMessage>{errors.password?.message}</ErrorMessage>
        </Grid>

        <Grid
          item
          xs={12}
          className={classes.formWrapper}
          style={{ marginBottom: "1rem" }}
        >
          <InputLabel>
            Network IP Address <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <TextField
            variant="outlined"
            name="ip_address"
            placeholder="Network IP Address"
            inputRef={register({
              required: { value: true, message: "IP Adrress is required." },
            })}
          />
          <ErrorMessage>{errors.ip_address?.message}</ErrorMessage>
        </Grid>

        <Grid
          item
          xs={12}
          className={classes.formWrapper}
          style={{ marginBottom: "1rem" }}
        >
          <InputLabel>
            Port <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <TextField
            variant="outlined"
            name="port"
            type="number"
            placeholder="Port"
            inputRef={register({
              required: { value: true, message: "Port is required." },
            })}
          />
          <ErrorMessage>{errors.port?.message}</ErrorMessage>
        </Grid>
        {/* <Grid
          item
          xs={12}
          className={classes.formWrapper}
          style={{ marginBottom: "1rem" }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={sync}
                onChange={() => toggleSync((prev) => !prev)}
                name="sync"
                color="primary"
              />
            }
            label="Sync Device"
          />
        </Grid> */}
      </Grid>

      <Grid
        container
        justifyContent="flex-start"
        style={{ marginBottom: "1em" }}
      >
        <FormSaveLoadingButton
          type="submit"
          icon={loading ? <Spinner /> : <SaveIcon />}
          disabled={loading ? true : false}
          text={loading ? "Saving..." : "Save"}
        />
      </Grid>
    </ModalForm>
  );
};

export default AttendanceConfigModal;
