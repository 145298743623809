import React from "react";
import { SettingSidebar, Miscellaneous } from "../../components";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const MiscellaneousPage = () => {
  const nav = <SettingSidebar miscellaneous />;
  return (
    <SubModuleLayout sideNav={nav}>
      <Miscellaneous />
    </SubModuleLayout>
  );
};

export default MiscellaneousPage;
