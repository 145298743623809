import { SectionTypeI } from "../Section/SectionActionTypes";

export const GET_CLASS_LOADING = "GET_CLASS_LOADING";
export const GET_CLASS_ERROR = "GET_CLASS_ERROR";
export const GET_CLASS_SUCCESS = "GET_CLASS_SUCCESS";

export const ADD_CLASS_LOADING = "ADD_CLASS_LOADING";
export const ADD_CLASS_ERROR = "ADD_CLASS_ERROR";
export const ADD_CLASS_SUCCESS = "ADD_CLASS_SUCCESS";

export const UPDATE_CLASS_LOADING = "UPDATE_CLASS_LOADING";
export const UPDATE_CLASS_ERROR = "UPDATE_CLASS_ERROR";
export const UPDATE_CLASS_SUCCESS = "UPDATE_CLASS_SUCCESS";

export const DELETE_CLASS_LOADING = "DELETE_CLASS_LOADING";
export const DELETE_CLASS_ERROR = "DELETE_CLASS_ERROR";
export const DELETE_CLASS_SUCCESS = "DELETE_CLASS_SUCCESS";

export const CLASS_STUDENTS_COUNT_LOADING = "CLASS_STUDENTS_COUNT_LOADING";
export const CLASS_STUDENTS_COUNT_SUCCESS = "CLASS_STUDENTS_COUNT_SUCCESS";
export const CLASS_STUDENTS_COUNT_ERROR = "CLASS_STUDENTS_COUNT_ERROR";

export type ClassTypeI = {
  id: string;
  section: SectionTypeI[];
  grade: string;
  grade_name: string;
  created_by: string;
  general_info_name: string;
  value: string;
  count?: number;
};

//===============================<START>GET CLASS<START>======================//

export interface GetClassLoading {
  type: typeof GET_CLASS_LOADING;
}

export interface GetClassError {
  type: typeof GET_CLASS_ERROR;
}

export interface GetClassSuccess {
  type: typeof GET_CLASS_SUCCESS;
  payload: ClassTypeI[];
}

//===============================<END>GET CLASS<END>======================//

//===============================<START>ADD CLASS<START>======================//

export interface AddClassLoading {
  type: typeof ADD_CLASS_LOADING;
}

export interface AddClassError {
  type: typeof ADD_CLASS_ERROR;
  payload: any;
}

export interface AddClassSuccess {
  type: typeof ADD_CLASS_SUCCESS;
  payload: ClassTypeI;
}

//===============================<END>ADD CLASS<END>======================//

//===============================<START>UPDATE CLASS<START>======================//

export interface UpdateClassLoading {
  type: typeof UPDATE_CLASS_LOADING;
}

export interface UpdateClassError {
  type: typeof UPDATE_CLASS_ERROR;
  payload: any;
}

export interface UpdateClassSuccess {
  type: typeof UPDATE_CLASS_SUCCESS;
  payload: ClassTypeI;
}

//===============================<END>UPDATE CLASS<END>======================//

//===============================<START>DELETE CLASS<START>======================//

export interface DeleteClassLoading {
  type: typeof DELETE_CLASS_LOADING;
}

export interface DeleteClassError {
  type: typeof DELETE_CLASS_ERROR;
}

export interface DeleteClassSuccess {
  type: typeof DELETE_CLASS_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE CLASS<END>======================//

export interface ClassStudentsCountLoading {
  type: typeof CLASS_STUDENTS_COUNT_LOADING;
}

export interface ClassStudentsCountError {
  type: typeof CLASS_STUDENTS_COUNT_ERROR;
}

export interface ClassStudentsCountSuccess {
  type: typeof CLASS_STUDENTS_COUNT_SUCCESS;
  payload: {
    [key: string]: number;
  };
}

export type ClassDispatchTypes =
  | GetClassLoading
  | GetClassError
  | GetClassSuccess
  | AddClassLoading
  | AddClassError
  | AddClassSuccess
  | UpdateClassLoading
  | UpdateClassError
  | UpdateClassSuccess
  | DeleteClassLoading
  | DeleteClassError
  | DeleteClassSuccess
  | ClassStudentsCountLoading
  | ClassStudentsCountError
  | ClassStudentsCountSuccess;
