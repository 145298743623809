import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_ADMIN_ENOTES_CATEGORIES_LOADING,
  GET_ADMIN_ENOTES_CATEGORIES_ERROR,
  GET_ADMIN_ENOTES_CATEGORIES_SUCCESS,
  GET_ADMIN_ENOTES_LOADING,
  GET_ADMIN_ENOTES_ERROR,
  GET_ADMIN_ENOTES_SUCCESS,
  AdminEnotesCategoriesDispatchTypes,
} from "./AdminEnotesActionTypes";

import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";
import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";

export const GetAdminEnotesCategories =
  () => async (dispatch: Dispatch<AdminEnotesCategoriesDispatchTypes>) => {
    try {
      dispatch({
        type: GET_ADMIN_ENOTES_CATEGORIES_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/e_note_category/`,
        HeaderConfig()
      );

      dispatch({
        type: GET_ADMIN_ENOTES_CATEGORIES_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_ADMIN_ENOTES_CATEGORIES_ERROR,
      });
    }
  };

export const GetAdminEnotes =
  (grade: string, subject: string, category: number, teacher: string) =>
  async (dispatch: Dispatch<AdminEnotesCategoriesDispatchTypes>) => {
    try {
      dispatch({
        type: GET_ADMIN_ENOTES_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/e_note/?grade=${grade}&subject=${subject}&category=${category}&teacher=${teacher}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_ADMIN_ENOTES_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_ADMIN_ENOTES_ERROR,
      });
    }
  };
