import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setSnackbar } from "../../../actions/SnackbarAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

export const getRubricSettings = createAsyncThunk(
  "getRubricSettings",
  async (undefined, { dispatch }) => {
    try {
      const res = await axios.get(`${ADMIN_API_URL}/rubric-settings`);
      return res.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      dispatch(setSnackbar(true, "error", "Failed to get rubric settings"));
    }
  }
);

export const postRubricSettings = createAsyncThunk(
  "postRubricSettings",
  async (data: any, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.post(`${ADMIN_API_URL}/rubric-settings/`, data)
      dispatch(setSnackbar(true, "success", "Rubric settings updated"));
      return res.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      dispatch(setSnackbar(true, "error", "Failed to update rubric settings"));
      return rejectWithValue(err.response);
    }
  }
)