// import { SectionTypeI } from "../Section/SectionActionTypes";

export const GET_STUDENT_DOCUMENT_LOADING = "GET_STUDENT_DOCUMENT_LOADING";
export const GET_STUDENT_DOCUMENT_ERROR = "GET_STUDENT_DOCUMENT_ERROR";
export const GET_STUDENT_DOCUMENT_SUCCESS = "GET_STUDENT_DOCUMENT_SUCCESS";

export const ADD_STUDENT_DOCUMENT_LOADING = "ADD_STUDENT_DOCUMENT_LOADING";
export const ADD_STUDENT_DOCUMENT_ERROR = "ADD_STUDENT_DOCUMENT_ERROR";
export const ADD_STUDENT_DOCUMENT_SUCCESS = "ADD_STUDENT_DOCUMENT_SUCCESS";

export const UPDATE_STUDENT_DOCUMENT_LOADING =
  "UPDATE_STUDENT_DOCUMENT_LOADING";
export const UPDATE_STUDENT_DOCUMENT_ERROR = "UPDATE_STUDENT_DOCUMENT_ERROR";
export const UPDATE_STUDENT_DOCUMENT_SUCCESS =
  "UPDATE_STUDENT_DOCUMENT_SUCCESS";

export const DELETE_STUDENT_DOCUMENT_LOADING =
  "DELETE_STUDENT_DOCUMENT_LOADING";
export const DELETE_STUDENT_DOCUMENT_ERROR = "DELETE_STUDENT_DOCUMENT_ERROR";
export const DELETE_STUDENT_DOCUMENT_SUCCESS =
  "DELETE_STUDENT_DOCUMENT_SUCCESS";

export type DocumentTypeI = {
  id: string;
  student: string;
  title: string;
  document: any;
  created_by: string;
};

//===============================<START>GET CLASS<START>======================//

export interface GetDocumentLoading {
  type: typeof GET_STUDENT_DOCUMENT_LOADING;
}

export interface GetDocumentError {
  type: typeof GET_STUDENT_DOCUMENT_ERROR;
}

export interface GetDocumentSuccess {
  type: typeof GET_STUDENT_DOCUMENT_SUCCESS;
  payload: DocumentTypeI[];
}

//===============================<END>GET CLASS<END>======================//

//===============================<START>ADD CLASS<START>======================//

export interface AddDocumentLoading {
  type: typeof ADD_STUDENT_DOCUMENT_LOADING;
}

export interface AddDocumentError {
  type: typeof ADD_STUDENT_DOCUMENT_ERROR;
}

export interface AddDocumentSuccess {
  type: typeof ADD_STUDENT_DOCUMENT_SUCCESS;
  payload: DocumentTypeI;
}

//===============================<END>ADD CLASS<END>======================//

//===============================<START>UPDATE CLASS<START>======================//

export interface UpdateDocumentLoading {
  type: typeof UPDATE_STUDENT_DOCUMENT_LOADING;
}

export interface UpdateDocumentError {
  type: typeof UPDATE_STUDENT_DOCUMENT_ERROR;
}

export interface UpdateDocumentSuccess {
  type: typeof UPDATE_STUDENT_DOCUMENT_SUCCESS;
  payload: DocumentTypeI;
}

//===============================<END>UPDATE CLASS<END>======================//

//===============================<START>DELETE CLASS<START>======================//

export interface DeleteDocumentLoading {
  type: typeof DELETE_STUDENT_DOCUMENT_LOADING;
}

export interface DeleteDocumentError {
  type: typeof DELETE_STUDENT_DOCUMENT_ERROR;
}

export interface DeleteDocumentSuccess {
  type: typeof DELETE_STUDENT_DOCUMENT_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE CLASS<END>======================//

export type DocumentDispatchTypes =
  | GetDocumentLoading
  | GetDocumentError
  | GetDocumentSuccess
  | AddDocumentLoading
  | AddDocumentError
  | AddDocumentSuccess
  | UpdateDocumentLoading
  | UpdateDocumentError
  | UpdateDocumentSuccess
  | DeleteDocumentLoading
  | DeleteDocumentError
  | DeleteDocumentSuccess;
