import React, { useState } from "react";
import { Grid, Paper } from "@material-ui/core";
import ClassLayout from "../../components/ClassRoom/Layout/ClassLayout";
import ClassRoomSidebar from "../../components/ClassRoom/Layout/ClassRoomSidebar";
import { useStyles } from "../../components/Styles/FormStyles";
import ClassRoomTabs from "../../components/ClassRoom/Components/ClassRoomTabs";
import TeacherRoom from "../../components/ClassRoom/Components/TeacherRoom";
import { ClassTypeI } from "../../actions/Academics/Class/ClassActionTypes";
import { SectionTypeI } from "../../actions/Academics/Section/SectionActionTypes";
import SectionField from "../../components/ClassRoom/Components/SectionField";

const ClassRoomPage = () => {
  const [classItem, setClassItem] = useState<ClassTypeI | null>(null);

  const onClassItem = (value: ClassTypeI) => {
    setClassItem(value);
  };

  const nav = <ClassRoomSidebar onClassItem={onClassItem} />;

  return (
    <div style={{ marginTop: "110px", minHeight: "80vh" }}>
      <ClassLayout sideNav={nav}>
        <Grid container>
          <Grid item md={8} xs={8}>
            <ClassRoomTabs classItem={classItem} />
          </Grid>
          <Grid item md={4} xs={4}>
            <TeacherRoom classItem={classItem} />
          </Grid>
        </Grid>
      </ClassLayout>
    </div>
  );
};

export default ClassRoomPage;
