import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_ADMIN_ASSIGNMENT_LOADING,
  GET_ADMIN_ASSIGNMENT_ERROR,
  GET_ADMIN_ASSIGNMENT_SUCCESS,
  AdminAssignmentDispatchTypes,
} from "./AdminAssignmentActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

export const GetAdminAssignment =
  (
    grade: string,
    sectionId: string,
    teacherId: string,
    subjectID: string,
    fromDate: string,
    toDate: string
  ) =>
  async (dispatch: Dispatch<AdminAssignmentDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_ADMIN_ASSIGNMENT_LOADING,
      });

      const url = `${ADMIN_API_URL}/student_assignment?teacher=${teacherId}&subject=${subjectID}&start_date=${fromDate}&end_date=${toDate}&section=${sectionId}&grade=${grade}`;
      const res = await axios.get(url, HeaderConfig());

      dispatch({
        type: GET_ADMIN_ASSIGNMENT_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_ADMIN_ASSIGNMENT_ERROR,
      });
    }
  };

export const GetAdminAssignmentByID =
  (id: string) =>
  async (dispatch: Dispatch<AdminAssignmentDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_ADMIN_ASSIGNMENT_LOADING,
      });

      const url = `${ADMIN_API_URL}/assignment/?${id}`;

      const res = await axios.get(url, HeaderConfig());

      dispatch({
        type: GET_ADMIN_ASSIGNMENT_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_ADMIN_ASSIGNMENT_ERROR,
      });
    }
  };
