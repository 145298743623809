import React, { useState } from "react";
import { ResultSidebar } from "../../components";
import AddMarks from "../../components/Result/AddMarks/AddMarks";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const AddMarksPage = () => {
  const nav = <ResultSidebar addMarks />;

  return (
    <SubModuleLayout examinationMenuActive sideNav={nav}>
      <AddMarks />
    </SubModuleLayout>
  );
};

export default AddMarksPage;
