import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_MEETING_LOADING,
  GET_MEETING_ERROR,
  GET_MEETING_SUCCESS,
  MeetingDispatchTypes,
  ADD_MEETING_LOADING,
  ADD_MEETING_SUCCESS,
  ADD_MEETING_ERROR,
  DELETE_MEETING_SUCCESS,
  DELETE_MEETING_ERROR,
  DELETE_MEETING_LOADING,
  UPDATE_MEETING_LOADING,
  UPDATE_MEETING_SUCCESS,
  UPDATE_MEETING_ERROR,
  APPROVE_OR_DECLINE_SUCCESS,
  APPROVE_OR_DECLINE_LOADING,
  APPROVE_OR_DECLINE_ERROR,
  GET_TODAY_MEETING_SUCCESS,
  GET_TODAY_MEETING_ERROR,
  GET_TODAY_MEETING_LOADING,
} from "./MeetingActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

export const GetMeetings =
  () => async (dispatch: Dispatch<MeetingDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_MEETING_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/meeting_request/`,
        HeaderConfig()
      );

      dispatch({
        type: GET_MEETING_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_MEETING_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "Meetings Loading Failed",
      //   },
      // });
    }
  };

export const GetTodayMeetings = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_TODAY_MEETING_LOADING });

    const res = await axios.get(`${ADMIN_API_URL}/meetings`, HeaderConfig());

    dispatch({
      type: GET_TODAY_MEETING_SUCCESS,
      payload: res.data.todays_meeting,
    });
  } catch (error) {
    dispatch({ type: GET_TODAY_MEETING_ERROR });

    // dispatch({
    //   type: SET_SNACKBAR,
    //   payload: {
    //     snackbarOpen: true,
    //     snackbarType: "error",
    //     snackbarMessage: "Today on Meeting Loading Failed",
    //   },
    // });
  }
};

export const GetSearchedMeetings =
  (query: any) =>
  async (dispatch: Dispatch<MeetingDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_MEETING_LOADING,
      });

      const url = `${ADMIN_API_URL}/meeting_request/?meeting_type=${query.meetingType}&from_date=${query.date_first}&to_date=${query.date_second}`;
      const res = await axios.get(url, HeaderConfig());

      dispatch({
        type: GET_MEETING_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_MEETING_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "Meetings Loading Failed",
      //   },
      // });
    }
  };

export const MeetingApproveOrDecline =
  (data: any) =>
  async (dispatch: Dispatch<MeetingDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: APPROVE_OR_DECLINE_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/meeting_request/approve_or_decline/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: APPROVE_OR_DECLINE_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Meeting status updated Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: APPROVE_OR_DECLINE_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Meeting approve or decline Failed",
        },
      });
    }
  };

export const AddMeeting =
  (data: any) =>
  async (dispatch: Dispatch<MeetingDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_MEETING_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/meeting_request/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_MEETING_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Meeting Added Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: ADD_MEETING_ERROR,
        payload: error.response?.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Meeting Add Failed",
        },
      });
    }
  };

export const UpdateMeeting =
  (id: string, data: any) =>
  async (dispatch: Dispatch<MeetingDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_MEETING_LOADING,
      });

      const res = await axios.put(
        `${ADMIN_API_URL}/meeting_request/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_MEETING_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Meeting Updated Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: UPDATE_MEETING_ERROR,
        payload: error.response?.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Meeting Update Failed",
        },
      });
    }
  };

export const DeleteMeeting =
  (id: string) =>
  async (dispatch: Dispatch<MeetingDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_MEETING_LOADING,
      });

      const res = await axios.delete(
        `${ADMIN_API_URL}/meeting_request/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_MEETING_SUCCESS,
        payload: { id: id },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Meeting Deleted Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_MEETING_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Meeting Delete Failed",
        },
      });
    }
  };
