import { makeStyles, Theme, createStyles } from "@material-ui/core";
import {
  root,
  Root,
  rootform,
  rootTableBox,
  fieldset,
  tableIcon,
  actionButton,
  chip,
  primary,
  fieldsetTable,
} from "./ReusableStyles";
export const PrintTableStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      margin: theme.spacing(3, 3, 3, 2),

      "& .MuiTypography-root": {
        marginBottom: theme.spacing(1),
        color: "black",
      },

      "& .MuiOutlinedInput-input": {
        padding: theme.spacing(1.2, 2),
      },
      ...root,
    },
    Root: {
      padding: theme.spacing(3),
      margin: theme.spacing(3, 2),

      "& .MuiTypography-root": {
        marginBottom: theme.spacing(1),
        color: "black",
      },

      "& .MuiOutlinedInput-input": {
        padding: theme.spacing(1.2, 2),
      },
      ...Root,
    },
    rootform: {
      padding: theme.spacing(3),
      margin: theme.spacing(3, 3, 3, 2),

      "& .MuiTypography-root": {
        marginBottom: theme.spacing(1),
        color: "black",
      },

      "& .MuiOutlinedInput-input": {
        padding: theme.spacing(1.2, 2),
      },
      ...rootform,
    },
    rootTableBox: {
      padding: theme.spacing(3),
      margin: theme.spacing(3, 2),

      "& .MuiTypography-root": {
        marginBottom: theme.spacing(1),
        color: "black",
      },

      "& .MuiOutlinedInput-input": {
        padding: theme.spacing(1.2, 2),
      },
      ...rootTableBox,
    },

    tablebutton: {
      marginTop: "10px",
      marginRight: "10px",
      marginLeft: "auto",
    },
    tableBottomButtons: {
      width: "100%!important",
      paddingRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    searchInput: {
      marginTop: "10px",
      "&:hover": {
        borderColor: "#ff0000",
      },
    },
    roottable: {
      // margin: "15px",
      // borderRadius: "6px",
      // borderStyle: "double",
      // padding: "16px 24px",
      ...fieldsetTable,
      "& .MuiTypography-root": {
        marginBottom: theme.spacing(1),
        color: "black",
      },
      "& .MuiOutlinedInput-input": {
        padding: theme.spacing(1.2, 2),
      },
    },
    rootTable: {
      padding: "15px",
      margin: "25px 10px 0px 0px",
      ...fieldsetTable,
      "& .MuiTableCell-sizeSmall": {
        padding: "10px 0px",
      },
    },

    rootAddMarks: {
      margin: theme.spacing(5),
      padding: theme.spacing(3, 1, 2, 1),
      "& .MuiTypography-root": {
        margin: theme.spacing(1, 0, 0, 3),
      },
      "& .MuiOutlinedInput-input": {
        padding: theme.spacing(1.2, 2),
      },
      ...fieldsetTable,
    },

    tableWrapper: {
      "& input::placeholder": {
        fontSize: "1px",
      },
      "& .MuiTableRow-root": {
        height: "20px",
      },
      ...fieldsetTable,
      "& .MuiOutlinedInput-input": {
        padding: theme.spacing(1.2, 2),
      },
    },
    tableWrap: {
      "& input::placeholder": {
        fontSize: "1px",
      },
      "& .MuiTableRow-root": {
        height: "40px",
      },
      ...fieldset,
      "& .MuiOutlinedInput-input": {
        padding: theme.spacing(1.2, 2),
      },
    },

    button: {
      "& .MuiButton-text": {
        padding: "0px",
      },
    },
    viewIcon: {
      ...primary,
      border: "2px solid rgba(17, 143, 207, 1)",
      ...tableIcon,
    },
    addIcon: {
      ...primary,
      border: "2px solid #118FCF",
      ...tableIcon,
    },
    editIcon: {
      color: "#3FC581",
      border: "2px solid rgba(63, 197, 129, 1)",
      ...tableIcon,
    },
    deleteIcon: {
      color: "#E96C5A",
      border: "2px solid rgba(233, 108, 90, 1)",
      ...tableIcon,
    },
    downloadIcon: {
      color: "#3FC581",
      border: "2px solid #3FC581",
      ...tableIcon,
    },
    visibilityIcon: {
      color: "#132E98",
      border: "1px solid #132E98",
      padding: "2px",
      width: "40px",
    },
    printIcon: {
      ...primary,
      border: "2px solid #118FCF",
      ...tableIcon,
    },
    listIcon: {
      ...primary,
      border: "2px solid #118FCF",
      ...tableIcon,
    },
    removeIcon: {
      color: "#E96C5A",
      border: "2px solid rgba(233, 108, 90, 1)",
      ...tableIcon,
    },
    actionButtonItem: {
      ...actionButton,
    },
    actionButtonPay: {
      backgroundColor: "rgba(19, 46, 152, 0.32)",
      ...actionButton,
    },
    Btn: {
      padding: "0 4px",
    },

    circleImageAvatar: {
      borderRadius: "50%",
      height: "35px",
      width: "35px",
      verticalAlign: "middle",
    },
    cell: {
      paddingLeft: "8px!important",
      borderRight: "1px solid black!important",
      borderBottom: "1px solid black!important",
      borderLeft: "1px solid black!important",
      verticalAlign: "middle",
      // paddingTop: '10px !important',
      // paddingBottom: '10px !important',
    },
    threeCell: {
      paddingLeft: "8px!important",
      borderRight: "1px solid black!important",
      verticalAlign: "middle",
      minWidth: "125px",
    },
    twoCell: {
      paddingLeft: "8px!important",
      borderRight: "1px solid black!important",
      verticalAlign: "middle",
      minWidth: "90px",
    },
    cellSm: {
      padding: "5px!important",
    },
    title: {
      paddingRight: "20px",
    },
    activeSession: {
      backgroundColor: "#132E98",
      color: "white",
      padding: theme.spacing(1, 2.4),
    },
    tableBottom: {
      justifyContent: "space-between",
      display: "flex",
      marginTop: "10px",
      "& .MuiTypography-body1": {
        fontSize: "0.7rem",
      },
    },
    pageNavigation: {
      display: "flex",
      border: "1px solid black",
      "& .MuiPaginationItem-rounded": {
        borderRadius: "0px",
        fontSize: "0.7rem",
      },
    },
    tableTop: {
      display: "flex",
      justifyContent: "space-between",
      "& input::placeholder": {
        fontSize: "13px!important",
      },
    },
    addMarksButton: {
      margin: theme.spacing(2, 0),
      width: "70%",
    },
    Button: {
      color: "white",
      border: "1px solid #A1ABD6",
      borderRadius: "20px",
      textTransform: "inherit",
      padding: "5px",
      margin: "2px",
    },
    modalBox: {
      "& .MuiPaper-root": {
        margin: "0px",
        padding: "5px 0 0 0",
      },
    },
    resize: {
      fontSize: 14,
    },
    iconPrint: {
      color: "#132E98",
      padding: "5px 25px 0px 25px",
      cursor: "pointer",
      "&:hover": {
        color: "white",
        background: "#132E98",
      },
    },
    chipRed: {
      ...chip,
      backgroundColor: "rgba(233, 108, 90, 0.5)",
    },
    chipGreen: {
      ...chip,
      backgroundColor: "rgba(63, 197, 129, 0.5)",
    },
    chipBlue: {
      ...chip,
      background: "#a1abd6",
    },
    attachFormTableRoot: {
      margin: "24px 16px 24px 16px",
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
    },
    table: {
      border: "1px solid black",
      marginTop: "12px",
      "& thead th": {
        fontWeight: "600",
        color: "#4D4F5C",
        backgroundColor: "#e4e3e3",
        padding: "12px 15px",
      },
      "& tbody td": {
        fontWeight: "400",
        padding: "5px 10px 5px 4px",
        color: "black",
      },
      "& tbody tr:hover": {
        backgroundColor: "#fffbf2",
        cursor: "pointer",
      },
    },
    cellDayName: {
      fontSize: "12px",
      borderRight: "1px solid black",
    },
    newcell: {
      borderRight: "1px solid black",
    },
    toogle: {
      "& .MuiToggleButton-root.Mui-selected": {
        backgroundColor: "black !important",
      },
    },
    headCell: {
      paddingLeft: "5px!important",
    },
  })
);
