import { Paper } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import BackDropLoader from "../../Reusable/BackDropLoader";
import SubModuleLayout from "../../Reusable/Layouts/SubModuleLayout";
import FinanceSidebar from "../FinanceSidebar";
import CollectFeeSearch from "./CollectFeeSearch";
import CollectFeeTabContent from "./CollectFeeTabContent";

interface SelectedFeesI {
  id: string;
  title: string;
  isMonthly: boolean;
}

export default function CollectFeePage() {
  const [fees, setFees] = useState<SelectedFeesI[]>([]);
  const [months, setMonths] = useState<string[]>([]);
  const [student, setStudent] = useState<any>(null);

  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch();

  const nav = <FinanceSidebar collect_fees />;

  return (
    <SubModuleLayout sideNav={nav}>
      <Paper style={{ margin: "10px" }}>
        <CollectFeeSearch student={student} setStudent={setStudent} />
        <CollectFeeTabContent student={student} setStudent={setStudent} />
      </Paper>
      <BackDropLoader open={loading} />
    </SubModuleLayout>
  );
}
