import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_VIEW_CHILD_LOADING,
  GET_VIEW_CHILD_ERROR,
  GET_VIEW_CHILD_SUCCESS,
  GET_VIEW_CHILDREN_LOADING,
  GET_VIEW_CHILDREN_ERROR,
  GET_VIEW_CHILDREN_SUCCESS,
  GET_CHILDREN_ACADEMIC_LOADING,
  GET_CHILDREN_ACADEMIC_ERROR,
  GET_CHILDREN_ACADEMIC_SUCCESS,
  UPDATE_CHILDREN_PASSWORD_ERROR,
  UPDATE_CHILDREN_PASSWORD_LOADING,
  UPDATE_CHILDREN_PASSWORD_SUCCESS,
  ViewChildDispatchTypes,
} from "./ViewChildActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { PARENT_API_URL, ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

export const GetChildren =
  () => async (dispatch: Dispatch<ViewChildDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_VIEW_CHILD_LOADING,
      });

      const res = await axios.get(
        `${PARENT_API_URL}/childrens/`,
        HeaderConfig()
      );

      dispatch({
        type: GET_VIEW_CHILD_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_VIEW_CHILD_ERROR,
      });
    }
  };

export const GetViewChildData =
  (id: number) =>
  async (dispatch: Dispatch<ViewChildDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_VIEW_CHILDREN_LOADING,
      });

      const res = await axios.get(
        `${PARENT_API_URL}/childrens/?id=${id}`,
        HeaderConfig()
      );
      dispatch({
        type: GET_VIEW_CHILDREN_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_VIEW_CHILDREN_ERROR,
      });
    }
  };

export const GetChildrenWithAcademic =
  () => async (dispatch: Dispatch<ViewChildDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_CHILDREN_ACADEMIC_LOADING,
      });

      const res = await axios.get(
        `${PARENT_API_URL}/student_academic_info/`,
        HeaderConfig()
      );
      dispatch({
        type: GET_CHILDREN_ACADEMIC_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_CHILDREN_ACADEMIC_ERROR,
      });
    }
  };

export const UpdateChildrenPassword =
  (data: any) =>
  async (dispatch: Dispatch<ViewChildDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_CHILDREN_PASSWORD_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/change_password/`,
        data,
        HeaderConfig()
      );
      dispatch({
        type: UPDATE_CHILDREN_PASSWORD_SUCCESS,
      });
      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Password Changed Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: UPDATE_CHILDREN_PASSWORD_ERROR,
      });
    }
  };
