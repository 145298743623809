import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OptSubjectGroupI } from "../optSubjects/optSubject";
import {
  initialStateI,
  OptSubjectStudentI,
  StudentOptSubjectsI,
} from "./optSubjectStudent";
import {
  getOptSubjectStudent,
  postOptSubjectStudent,
} from "./optSubjectStudentApi";

const initialState: initialStateI = {
  loading: false,
  actionPerformed: false,
  studentOptSubject: [],
  optSubjectGroups: [],
  searchParam: null,
};

const OptSubjectStudentSlice = createSlice({
  name: "optSubjectStudent",
  initialState,
  reducers: {
    handleApplyToAllStudent: (
      state,
      { payload }: PayloadAction<{ groupId: string; value: string }>
    ) => {
      state.studentOptSubject.forEach((el) => {
        const targetGroup = el.optional_subjects.find(
          (it) => it.group_id === payload.groupId
        );

        if (targetGroup) {
          targetGroup.value = payload.value;
          el.changed = true;
        }
      });
    },
    handleOptSubjectChange: (
      state,
      {
        payload,
      }: PayloadAction<{
        studentId: string;
        groupId: string;
        value: string | null;
      }>
    ) => {
      const targetStudent = state.studentOptSubject.find(
        (el) => el.id === payload.studentId
      );

      if (targetStudent) {
        const targetGroup = targetStudent.optional_subjects.find(
          (el) => el.group_id === payload.groupId
        );

        if (targetGroup) {
          targetGroup.value = payload.value;
          targetStudent.changed = true;
        }
      }
    },
    setSearchParam: (
      state,
      {
        payload,
      }: PayloadAction<{
        grade: { id: string; name: string };
        section: { id: string; name: string } | null;
      }>
    ) => {
      state.searchParam = { ...payload };
    },
    resetState: (state) => {
      state.studentOptSubject = [];
      state.optSubjectGroups = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOptSubjectStudent.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(
      getOptSubjectStudent.fulfilled,
      (
        state,
        {
          payload,
        }: PayloadAction<{
          students: OptSubjectStudentI[];
          optional_subject_group: OptSubjectGroupI[];
        }>
      ) => {
        const data: StudentOptSubjectsI[] = payload.students.map((el) => ({
          id: el.id,
          first_name: el.first_name,
          last_name: el.last_name,
          changed: false,
          optional_subjects: payload.optional_subject_group.map((it, ind) => {
            const assignedSubjects: string[] = [];
            el.optional_subjects.forEach((j) => {
              assignedSubjects.push(j[0]);
            });
            let val: string | null = null;
            it.subject.every((k) => {
              if (assignedSubjects.includes(k.id)) {
                val = k.id;
                return false;
              }
              return true;
            });
            return {
              opt_subject_group: it.subject.map((i) => ({
                id: i.id,
                name: i.subject_name,
              })),
              group_id: it.id,
              value: val,
            };
          }),
        }));
        state.optSubjectGroups = payload.optional_subject_group;
        state.studentOptSubject = data;
        state.loading = false;
      }
    );
    builder.addCase(getOptSubjectStudent.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(postOptSubjectStudent.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(postOptSubjectStudent.fulfilled, (state) => {
      state.loading = false;
      state.actionPerformed = true;
    });
    builder.addCase(postOptSubjectStudent.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  handleOptSubjectChange,
  handleApplyToAllStudent,
  setSearchParam,
  resetState,
} = OptSubjectStudentSlice.actions;

export default OptSubjectStudentSlice.reducer;
