export const GET_LESSON_PLAN_LOADING = "GET_LESSON_PLAN_LOADING";
export const GET_LESSON_PLAN_ERROR = "GET_LESSON_PLAN_ERROR";
export const GET_LESSON_PLAN_SUCCESS = "GET_LESSON_PLAN_SUCCESS";

export interface AdminLessonPlanTypeI {
  id: number;
  name: string;
  start_date: string;
  end_date: string;
  due_date: string;
  completion_date: string | null;
  unit_title: string;
  sub_unit: number;
  sub_unit_name: string;
  created_by: number;
  general_info: number;
}

//===============================<START>GET LESSON PLAN<START>======================//

export interface GetLessonPlanLoading {
  type: typeof GET_LESSON_PLAN_LOADING;
}

export interface GetLessonPlanError {
  type: typeof GET_LESSON_PLAN_ERROR;
}

export interface GetLessonPlanSuccess {
  type: typeof GET_LESSON_PLAN_SUCCESS;
  payload: AdminLessonPlanTypeI[];
}

//===============================<END>GET LESSON PLAN<END>======================//
export type LessonPlanDispatchTypes =
  | GetLessonPlanLoading
  | GetLessonPlanError
  | GetLessonPlanSuccess;
