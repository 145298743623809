// -----------------<START> import modules starts----------------------//
import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  LinearProgress,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { Search, Add } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { useFormStyles } from "../../Styles/FormStyles";
import Popups from "../../Reusable/Dialogs/Popups";
import { AddAnnoucModalPage } from "../../../pages";
import AddNewButton from "../../Reusable/Buttons/AddNewButton";
import "nepali-datepicker-reactjs/dist/index.css";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { useDispatch, useSelector } from "react-redux";
import { GetDesignations } from "../../../actions/HumanResource/Designation/DesignationAction";
import { GetClasses } from "../../../actions/Academics/Class/ClassAction";
import { RootStore } from "../../../store";
import { DesignationTypeI } from "../../../actions/HumanResource/Designation/DesignationActionTypes";
import { ClassTypeI } from "../../../actions/Academics/Class/ClassActionTypes";
import { SectionTypeI } from "../../../actions/Academics/Section/SectionActionTypes";
import { GetUsersByRole } from "../../../actions/GeneralSettings/GeneralInfo/GeneralInfoAction";
import { useForm } from "react-hook-form";
import { GetAnnouncements } from "../../../actions/Communication/Announcement/AnnouncementAction";
import moment from "moment";
import {
  dateConverterAdToBs,
  dateConverterBsToAd,
} from "../../utils/dateConverter";
import { GetSystemRoles } from "../../../actions/HumanResource/StaffRole/StaffRolesAction";
import { UsersByRoleI } from "../../../actions/GeneralSettings/GeneralInfo/GeneralInfoActionTypes";
import AccessPermission from "../../Student/StudentDetail/AccessPermission";

// -----------------<END> imports module ends -----------------------//

// -----------------<start> Styling <start>-----------------------//

// -----------------<end> Styling <end>-----------------------//

// -----------------<start> Interfaces starts-----------------------//
interface SelectMessageI {
  id: number;
  title: string;
  value: string;
}

// -----------------<END> Interfaces ends-----------------------//

const today = moment(new Date()).format("YYYY-MM-DD");

// -----------------<START> Annoucement search component starts-----------------------//

const AnnoucementSearch: React.FC = () => {
  const history = useHistory();
  const classes = useFormStyles();
  const [fromDate, setFromDate] = useState<string>(dateConverterAdToBs(today));
  const [toDate, setToDate] = useState<string>(dateConverterAdToBs(today));

  const [selectMessage, setSelectMessage] = useState<SelectMessageI | null>(
    null
  );
  const [openPopup, setOpenPopup] = useState(false);

  const [loading, setLoading] = useState<boolean>(false);

  //select message choices
  const selectMessageChoices: SelectMessageI[] = [
    { id: 1, title: "Groups", value: "groups" },
    { id: 2, title: "Individuals", value: "users" },
    { id: 3, title: "Classes", value: "class" },
  ];

  // =============== <START> REACT HOOK <START> ========================//
  const { errors, setError, clearErrors } = useForm();
  const dispatch = useDispatch();

  // =============== <END> REACT HOOK <END> ========================//

  const handleClose = (value: boolean) => {
    setOpenPopup(value);
  };

  const onFromDateChange = (date: string) => {
    clearErrors("from_date");
    setFromDate(date);
  };

  const onToDateChange = (date: string) => {
    clearErrors("to_date");
    setToDate(date);
  };

  const onMessageToChange = (value: SelectMessageI | null) => {
    clearErrors("message_to");
    setSelectMessage(value);
  };

  const onSearchClicked = () => {
    dispatch(
      GetAnnouncements(
        dateConverterBsToAd(fromDate),
        dateConverterBsToAd(toDate),
        selectMessage ? selectMessage.value : ""
      )
    );
  };

  const checkValidityString = (value: string, field_name: string) => {
    if (value === "" || value === null || value.length === 0) {
      setError(field_name, {
        type: "required",
        message: "This field is required",
      });
      return null;
    } else {
      return value;
    }
  };

  return (
    <>
      <Paper className={classes.pageContentBox}>
        <form className={classes.form}>
          <Grid container>
            <Grid item xs={9} className={classes.formTitle}>
              <Typography variant="h6">Search Announcement</Typography>
            </Grid>
            <Grid container item xs={3}>
              <AccessPermission>
                <Grid
                  container
                  justifyContent="flex-end"
                  style={{ marginLeft: "40px" }}
                >
                  <a onClick={() => history.push("/announcement-form")}>
                    <AddNewButton
                      title="Add New Announcement"
                      type="button"
                      color="primary"
                      variant="outlined"
                      startIcon={<Add />}
                    >
                      New Annoucement
                    </AddNewButton>
                  </a>
                </Grid>
              </AccessPermission>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={3} className={classes.formWrapper}>
                <InputLabel>From Date</InputLabel>
                <NepaliDatePicker
                  className={classes.nepaliDateInput}
                  onChange={(data: string) => onFromDateChange(data)}
                  value={fromDate}
                  options={{ calenderLocale: "en", valueLocale: "en" }}
                  inputClassName="inputFormControl"
                />
                <span className={classes.validationErrorInfo}>
                  {errors?.from_date?.message}
                </span>
              </Grid>
              <Grid item xs={3} className={classes.formWrapper}>
                <InputLabel>To Date</InputLabel>
                <NepaliDatePicker
                  className={classes.nepaliDateInput}
                  onChange={(data: string) => onToDateChange(data)}
                  value={toDate}
                  options={{ calenderLocale: "en", valueLocale: "en" }}
                  inputClassName="inputFormControl"
                />
                <span className={classes.validationErrorInfo}>
                  {errors?.to_date?.message}
                </span>
              </Grid>

              <Grid item xs={3} className={classes.formWrapper}>
                <InputLabel>Select Message sent to</InputLabel>
                <Autocomplete
                  value={selectMessage}
                  onChange={(
                    event: React.ChangeEvent<{}>,
                    value: SelectMessageI | null
                  ) => onMessageToChange(value)}
                  options={selectMessageChoices}
                  getOptionLabel={(option) => option.title}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="select_message"
                      placeholder="Students"
                      variant="outlined"
                    />
                  )}
                />
                <span className={classes.validationErrorInfo}>
                  {errors?.message_to?.message}
                </span>
              </Grid>
              <Grid item xs={3} className={classes.formWrapper}>
                <Grid container justifyContent="center">
                  <Button
                    className={classes.searchBoxButton}
                    color="primary"
                    variant="contained"
                    startIcon={<Search />}
                    onClick={onSearchClicked}
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>

        <Popups
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          onClose={handleClose}
        >
          <AddAnnoucModalPage
            onClose={handleClose}
            mode="create"
            initialData={null}
          />
        </Popups>
      </Paper>
      {loading ? (
        <LinearProgress style={{ margin: "-10px 15px 0 15px" }} />
      ) : null}
    </>
  );
};

//-----------------<ENDS> Annoucement search component ends-----------------------//
export default AnnoucementSearch;
