import { CategoryTypeI } from "../../Student/StudentCategory/CategoryActionTypes";

export const GET_DISCOUNT_LOADING = "GET_DISCOUNT_LOADING";
export const GET_DISCOUNT_ERROR = "GET_DISCOUNT_ERROR";
export const GET_DISCOUNT_SUCCESS = "GET_DISCOUNT_SUCCESS";

export const ADD_DISCOUNT_LOADING = "ADD_DISCOUNT_LOADING";
export const ADD_DISCOUNT_ERROR = "ADD_DISCOUNT_ERROR";
export const ADD_DISCOUNT_SUCCESS = "ADD_DISCOUNT_SUCCESS";

export const UPDATE_DISCOUNT_LOADING = "UPDATE_DISCOUNT_LOADING";
export const UPDATE_DISCOUNT_ERROR = "UPDATE_DISCOUNT_ERROR";
export const UPDATE_DISCOUNT_SUCCESS = "UPDATE_DISCOUNT_SUCCESS";

export const DELETE_DISCOUNT_LOADING = "DELETE_DISCOUNT_LOADING";
export const DELETE_DISCOUNT_ERROR = "DELETE_DISCOUNT_ERROR";
export const DELETE_DISCOUNT_SUCCESS = "DELETE_DISCOUNT_SUCCESS";

export type DiscountTypeI = {
  id: string;
  name: string;
  discount_in: string;
  discount_applicable: number;
  count: number;
};

//===============================<START>GET DISCOUNT<START>======================//

export interface GetDiscountLoading {
  type: typeof GET_DISCOUNT_LOADING;
}

export interface GetDiscountError {
  type: typeof GET_DISCOUNT_ERROR;
}

export interface GetDiscountSuccess {
  type: typeof GET_DISCOUNT_SUCCESS;
  payload: DiscountTypeI[];
}

//===============================<END>GET DISCOUNT<END>======================//

//===============================<START>ADD DISCOUNT<START>======================//

export interface AddDiscountLoading {
  type: typeof ADD_DISCOUNT_LOADING;
}

export interface AddDiscountError {
  type: typeof ADD_DISCOUNT_ERROR;
  payload: any;
}

export interface AddDiscountSuccess {
  type: typeof ADD_DISCOUNT_SUCCESS;
  payload: DiscountTypeI;
}

//===============================<END>ADD DISCOUNT<END>======================//

//===============================<START>UPDATE DISCOUNT<START>======================//

export interface UpdateDiscountLoading {
  type: typeof UPDATE_DISCOUNT_LOADING;
}

export interface UpdateDiscountError {
  type: typeof UPDATE_DISCOUNT_ERROR;
  payload: any;
}

export interface UpdateDiscountSuccess {
  type: typeof UPDATE_DISCOUNT_SUCCESS;
  payload: DiscountTypeI;
}

//===============================<END>UPDATE DISCOUNT<END>======================//

//===============================<START>DELETE DISCOUNT<START>======================//

export interface DeleteDiscountLoading {
  type: typeof DELETE_DISCOUNT_LOADING;
}

export interface DeleteDiscountError {
  type: typeof DELETE_DISCOUNT_ERROR;
}

export interface DeleteDiscountSuccess {
  type: typeof DELETE_DISCOUNT_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE DISCOUNT<END>======================//

export type DiscountDispatchTypes =
  | GetDiscountLoading
  | GetDiscountError
  | GetDiscountSuccess
  | AddDiscountLoading
  | AddDiscountError
  | AddDiscountSuccess
  | UpdateDiscountLoading
  | UpdateDiscountError
  | UpdateDiscountSuccess
  | DeleteDiscountLoading
  | DeleteDiscountError
  | DeleteDiscountSuccess;
