import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import ActivityCard from "../../../../Reusable/ActivityCard";
import ProgressButton from "../../../../Reusable/Buttons/ProgressButton";
import { RootStore } from "../../../../../store"
import { GetStudentAssignmentProgress } from "../../../../../actions/Student/StudentProgress/StudentProgressAction"
import { LinearProgress } from "@material-ui/core";

const Assignment = () => {
    const dispatch = useDispatch();
    const { student } = useSelector((state: RootStore) => state.student_profile)
    const { loading, progress } = useSelector((state: RootStore) => state.student_progress)
    const isEmpty = Object.keys(progress[0]).length === 0;

    useEffect(() => {
        student && dispatch(GetStudentAssignmentProgress(student?.academic_info?.[0]?.id))
    }, [student])

    return (
        <>
            {loading && (
                <LinearProgress style={{ margin: "-14px -24px 24px -24px" }} />
            )}
            <div style={{ display: "flex", gap: "15px" }}>
                {!isEmpty && <>
                    <ActivityCard data={{ value: progress[0].total_submitted_assignments, subValue: "Submission", time: "Total" }} />
                    <ActivityCard data={{ value: progress[0].submission_on_time, subValue: "Submitted", time: "On Time" }} />
                    <ActivityCard data={{ value: progress[0].late_submission, subValue: "Submission", time: "Late" }} />
                    <ActivityCard data={{ value: progress[0].not_submitted, subValue: "Not", time: "Submitted" }} />
                </>}
            </div>
            <ProgressButton data={{ title: "Overall Assignment Score", value: "70%", status: "Good" }} />
        </>
    )
}

export default Assignment