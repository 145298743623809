// ---------------- <START> module import ------------------//
import React, { useState, useEffect } from "react";
import { Paper, Grid, Typography } from "@material-ui/core";
import { useTable } from "../../Reusable";
import { useTableStyles } from "../../Styles/TableStyles";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import {
  DeleteEventType,
} from "../../../actions/Event/EventType/EventTypeAction";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import {
  ItemDeleteButton,
  ItemEditButton,
} from "../../Reusable/Buttons/TableButton";
import { GetAnnouncementTypes, DeleteAnnouncementType } from "../../../actions/Communication/AnnouncementType/AnnouncementTypeAction";
// ---------------- <END> module import ------------------//

// ---------------- <START> Interface------------------//
interface TableDataI {
  id: string;
  name: string;
  count: number;
}

interface PropsI {
  onEditData: (value: TableDataI) => void;
  editCheck: { check: boolean; count: number };
}

interface HeadCellsI {
  id: string;
  label: string;
}
// ---------------- <END> Interface------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N" },
  { id: "name", label: "Announcement Category" },
  { id: "action", label: "Action" },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------- <START> Event Type Component  ------------------//
const AnnouncementTypeList = (props: PropsI) => {
  const { onEditData, editCheck } = props;
  const dispatch = useDispatch();
  const classes = useTableStyles();

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  const [tableData, setTableData] = useState<TableDataI[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string>("");
  const [editMode, setEditMode] = useState<boolean>(false);

  const announcementCategorySelector = useSelector((state: RootStore) => state.announcement_type);

  // Fetch data
  useEffect(() => {
    dispatch(GetAnnouncementTypes());
  }, []);

  useEffect(() => {
    const items: TableDataI[] = formatData(announcementCategorySelector.announcement_types);

    setTableData(items);
    setLoading(announcementCategorySelector.loading);
  }, [announcementCategorySelector]);

  useEffect(() => {
    editCheck && setEditMode(editCheck.check);
  }, [editCheck]);

  // Edit record
  const handleEditClicked = (data: TableDataI) => {
    setEditMode(true);
    onEditData(data);
  };

  // Delete record modal
  const handleDeleteModal = (value: boolean, id: string) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteAnnouncementType(itemId));
    setDeleteModalOpen(false);
  };

  const formatData = (items: any) => {
    return items.map((item: any) => ({
      id: item.id,
      name: item.name,
    }));
  };

  const getCustomTableRow = (item: TableDataI, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ItemEditButton onClick={() => handleEditClicked(item)} />
          <ItemDeleteButton
            disabled={editMode}
            onClick={() => handleDeleteModal(true, item.id)}
          />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <>
      <Paper className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">Event Type List</Typography>
          </Grid>
        </Grid>

        <TableContainer
          label="Announcement Category"
          loading={loading}
          items={tableData}
          headCells={headCells}
          getCustomTableRow={getCustomTableRow}
          search_name="name"
          search_param="name"
          rowsPerPage={7}
        />
      </Paper>

      {/* Delete modal dialog */}
      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
      />
    </>
  );
};
// ---------------- <END> Event Type Component  ------------------//

export default AnnouncementTypeList;
