// ---------------- <START> module import ends ------------------//
import React, { useEffect, useRef, useState } from 'react';
import { Grid, InputLabel, TextField } from '@material-ui/core';
import { useFormStyles } from '../../Styles/FormStyles';
import { TransportTypeI } from '../../../actions/Transport/Routes/TransportActionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import { useForm } from 'react-hook-form';
import {
  UpdateTransport,
  AddTransport,
} from '../../../actions/Transport/Routes/TransportAction';
import FormLayout from '../../Reusable/Layouts/Form.Layout';
import CustomizedTextField from '../../Reusable/Inputs/TextField';
import CustomizedSelect from '../../Reusable/Inputs/Select';
import formsValidationCheckup from '../../utils/formsValidationCheckUp';
import { Alert, Autocomplete } from '@material-ui/lab';
import firstWordCapital from '../../utils/firstWordCapital';
import { GetDrivers } from '../../../actions/HumanResource/Staff/StaffAction';
import { inputLabel } from '../../Styles/ReusableStyles';
import { StaffTypeI } from '../../../actions/HumanResource/Staff/StaffActionTypes';
import { GetVehicle } from '../../../actions/Transport/Vehicles/VehiclesAction';

// ---------------- <END> module import ends ------------------//

interface PropsI {
  editData: TransportTypeI | null;
  onEditCheck: (value: boolean) => void;
}

interface FormDataI {
  id: string;
  route_from: string;
  route_to: string;
  pickup_time: string;
  drop_time: string;
  fare: number;
  driver: string;
  bus_number: string;
  seat: number;
}

// ---------------- <START> Routes Form Component starts ------------------//
function RoutesForm(props: PropsI) {
  const classes = useFormStyles();
  const { editData, onEditCheck } = props;

  //= =============================<START> Component States <START>=================================//
  const [editID, setEditID] = useState<string>('');
  const [editMode, setEditMode] = useState<boolean>(false);

  const [selectedDriver, setSelectedDriver] = useState<{
    id: string;
    full_name: string;
  } | null>(null);
  const [selectedAssistant, setSelectedAssistant] = useState<{
    id: string;
    full_name: string;
  } | null>(null);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const [busChoices, setBusChoices] = useState<any[]>([]);
  const [bus, setBus] = useState<any>();
  const [seat, setSeat] = useState<string>('');

  //= =============================<END> Component States <END>=================================//

  const dispatch = useDispatch();
  const transportLoading = useSelector(
    (state: RootStore) => state.transport.loading
  );
  const add_or_update = useSelector(
    (state: RootStore) => state.transport.add_or_update
  );
  const { loading, drivers } = useSelector((state: RootStore) => state.staff);
  const { vehicle } = useSelector((state: RootStore) => state.vehicle);
  useEffect(() => {
    if (add_or_update) {
      setIsBtnLoading(false);
      handleReset();
    }
  }, [add_or_update]);

  useEffect(() => {
    dispatch(GetDrivers());
    dispatch(GetVehicle());
  }, []);

  //   useEffect(() => {
  //     const temp = vehicle.map((item) => {
  //       ...item,
  //       value: item.vehicle_no
  //     })
  //   console.log(temp);
  // }, [vehicle])

  useEffect(() => {
    const temp = vehicle?.map((item: any) => {
      return {
        ...item,
        value: item.vehicle_no,
      };
    });
    setBusChoices(temp);
  }, [vehicle]);

  // Update component prop each time new data is sent
  useEffect(() => {
    editData != null && handleEditTableData(editData);
  }, [editData]);

  //= =============================<START> Event Handlers <START>=================================//

  // using react hook form
  const {
    register,
    handleSubmit,
    errors,
    setError,
    clearErrors,
    setValue,
    reset,
  } = useForm({
    mode: 'onChange',
    // resolver: yupResolver(schema),
  });

  const onFormSubmit = (data: FormDataI) => {
    setIsBtnLoading(true);
    const finalValues = {
      ...data,
      driver: selectedDriver?.id,
      driver_assistant: selectedAssistant?.id,
    };
    // if (editMode) {
    //   editID != null && dispatch(UpdateTransport(editID, finalValues));
    // } else {
    //   dispatch(AddTransport(finalValues));
    // }
  };

  const handleEditTableData = (data: TransportTypeI) => {
    setEditMode(true);
    setEditID(data.id);
    setValue('route_from', data.route_from);
    setValue('route_to', data.route_to);
    setValue('pickup_time', data.pickup_time);
    setValue('drop_time', data.drop_time);
    setValue('fare', data.fare);
    setValue('bus_number', data.bus_number);
    setValue('driver', data.driver);
    setValue('seat', data.seat);
  };

  const handleReset = () => {
    reset();
    setEditMode(false);
    setEditID('');
    onEditCheck(false);
    setSelectedDriver(null);
    setSelectedAssistant(null);
  };

  const handleBus = (value: any) => {
    setBus(value);
    setSeat(value.seat_capacity);
    clearErrors('bus_number');
  };

  const handleSelectDriver = (
    value: { id: string; full_name: string } | null
  ) => {
    setSelectedDriver(value);
    clearErrors('driver');
  };

  const handleSelectAssistant = (
    value: { id: string; full_name: string } | null
  ) => {
    setSelectedAssistant(value);
    clearErrors('driver_assistant');
  };

  //--------------------------------------------ERROR HANDLING-------------------------//
  const [serverErrors, setServerErrors] = useState<any>(null);
  const transportSelector = useSelector((state: RootStore) => state.transport);
  const errorsData = transportSelector.error;
  const initialErrorsData = useRef(errorsData);

  useEffect(() => {
    if (initialErrorsData.current === errorsData) {
      initialErrorsData.current = errorsData; // Do not set initial errors
    } else {
      if (errorsData?.error != null) {
        const keys = Object.keys(errorsData?.error);
        keys.map((elem: any) => {
          setError(elem, {
            type: 'serverSideError',
            message: errorsData.error[elem] && errorsData.error[elem][0],
          });
        });
      }
      setServerErrors(errorsData);
    }
  }, [errorsData]);

  useEffect(() => {
    if (errorsData == null) {
      setServerErrors(null);
    } else if (serverErrors?.error) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [errorsData]);

  useEffect(() => {
    if (transportSelector.recent) {
      handleReset();
    }
  }, [transportSelector]);

  return (
    <>
      <FormLayout
        title="Add Routes"
        onSubmit={handleSubmit(onFormSubmit)}
        editMode={editMode}
        loading={loading}
        onClick={!isBtnLoading && handleReset}
        add_or_update={isBtnLoading}
      >
        {serverErrors &&
          serverErrors?.error &&
          Object.keys(serverErrors.error)
            .filter((elem) => elem === 'error')
            .map((elem: any, index: number) => {
              return (
                <div className={classes.serversidemessages} key={index}>
                  <Alert severity="error">
                    {firstWordCapital(serverErrors?.error[elem][0])}
                  </Alert>
                </div>
              );
            })}

        <Grid container spacing={2}>
          <Grid item xs={6}>
            {/* <CustomizedTextField
              label="Bus No."
              placeholder="Bus No."
              name="bus_number"
              error={errors["bus_number"] ? "Required Field." : ""}
              inputRef={register({ required: true })}
            /> */}
            <CustomizedSelect
              label="Bus No."
              name="bus_number"
              required
              options={busChoices}
              value={bus}
              setValue={handleBus}
              error={errors['bus_number'] ? 'Bus Number must be selected' : ''}
              inputRef={register({ required: true })}
            />
            <span className={classes.validationErrorInfo}>
              {errors.bus_number?.type === 'serverSideError' &&
                errors.bus_number.message}
            </span>
          </Grid>
          <Grid item xs={6}>
            <CustomizedTextField
              label="Seat Capacity"
              placeholder="Seat"
              name="seat"
              disabled
              value={seat}
            />
          </Grid>
        </Grid>

        <Grid container className={classes.formWrapper}>
          <InputLabel>Driver</InputLabel>
          <Autocomplete
            value={selectedDriver}
            // filterSelectedOptions
            onChange={(event: React.ChangeEvent<{}>, value: any | null) =>
              handleSelectDriver(value)
            }
            // onInputChange={onTeacherChange}
            options={drivers.filter(
              (item) => item.id !== selectedAssistant?.id
            )}
            getOptionLabel={(option) => option.full_name}
            renderInput={(params: any) => (
              <TextField
                {...params}
                placeholder="Select"
                name="driver"
                inputRef={register({ required: false })}
                variant="outlined"
              />
            )}
          />
          <span className={classes.validationErrorInfo}>
            {errors.driver?.type === 'required' && 'Please select driver'}
          </span>
          <span className={classes.validationErrorInfo}>
            {errors?.error?.type === 'serverSideError' && errors.error?.message}
          </span>
        </Grid>
        <Grid
          container
          className={classes.formWrapper}
          style={{ padding: '15px 0px' }}
        >
          <InputLabel>Driver Assistant</InputLabel>
          <Autocomplete
            value={selectedAssistant}
            // filterSelectedOptions
            onChange={(event: React.ChangeEvent<{}>, value: any | null) =>
              handleSelectAssistant(value)
            }
            // onInputChange={onTeacherChange}
            options={drivers.filter((item) => item.id !== selectedDriver?.id)}
            getOptionLabel={(option) => option.full_name}
            renderInput={(params: any) => (
              <TextField
                {...params}
                placeholder="Select"
                name="driver"
                inputRef={register({ required: false })}
                variant="outlined"
              />
            )}
          />
          <span className={classes.validationErrorInfo}>
            {errors.driver_assistant?.type === 'required' &&
              'Please select driver assistant'}
          </span>
          <span className={classes.validationErrorInfo}>
            {errors?.error?.type === 'serverSideError' && errors.error?.message}
          </span>
        </Grid>

        <Grid container className={classes.formWrapper}>
          <CustomizedTextField
            label="Route Name"
            placeholder="Route Name"
            name="route_name"
            error={errors['route_name'] ? 'Required Field.' : ''}
            inputRef={register({ required: true })}
          />
        </Grid>

        <Grid
          container
          className={classes.formWrapper}
          style={{ padding: '8px 0px 0 0' }}
        >
          <CustomizedTextField
            label="Route Fare (Monthly)"
            placeholder="Route Fare"
            name="fare"
            error={errors['fare'] ? 'Required Field.' : ''}
            inputRef={register({ required: true })}
          />
          <span className={classes.validationErrorInfo}>
            {errors.fare?.type === 'serverSideError' && errors.fare.message}
          </span>
        </Grid>

        {/* <CustomizedTextField
          label="From"
          placeholder="Route From"
          name="route_from"
          error={errors["route_from"] ? "Required Field." : ""}
          inputRef={register({ required: true })}
        />
        <span className={classes.validationErrorInfo}>
          {errors.route_from?.type === "serverSideError" &&
            errors.route_from.message}
        </span>
        <CustomizedTextField
          label="To"
          placeholder="Route To"
          name="route_to"
          error={errors["route_to"] ? "Required Field." : ""}
          inputRef={register({ required: true })}
        />
        <span className={classes.validationErrorInfo}>
          {errors.route_to?.type === "serverSideError" &&
            errors.route_to.message}
        </span>
        <CustomizedTextField
          label="Pickup Time"
          placeholder="Pickup Time"
          name="pickup_time"
          type="time"
          onChange={() => clearErrors("error")}
          error={errors["pickup_time"] ? "Required Field." : ""}
          inputRef={register({ required: true })}
        />
        <span className={classes.validationErrorInfo}>
          {errors.pickup_time?.type === "serverSideError" &&
            errors.pickup_time.message}
        </span>
        <CustomizedTextField
          label="Drop Time"
          placeholder="Drop Time"
          name="drop_time"
          type="time"
          onChange={() => clearErrors("error")}
          error={errors["drop_time"] ? "Required Field." : ""}
          inputRef={register({ required: true })}
        />
        <span className={classes.validationErrorInfo}>
          {errors.drop_time?.type === "serverSideError" &&
            errors.drop_time.message}
        </span> */}
      </FormLayout>
    </>
  );
}
// ---------------- <END> Routes Form Component ends ------------------//
export default RoutesForm;
