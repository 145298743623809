export const GET_EVENT_TYPE_LOADING = "GET_EVENT_TYPE_LOADING";
export const GET_EVENT_TYPE_ERROR = "GET_EVENT_TYPE_ERROR";
export const GET_EVENT_TYPE_SUCCESS = "GET_EVENT_TYPE_SUCCESS";

export const ADD_EVENT_TYPE_LOADING = "ADD_EVENT_TYPE_LOADING";
export const ADD_EVENT_TYPE_ERROR = "ADD_EVENT_TYPE_ERROR";
export const ADD_EVENT_TYPE_SUCCESS = "ADD_EVENT_TYPE_SUCCESS";

export const UPDATE_EVENT_TYPE_LOADING = "UPDATE_EVENT_TYPE_LOADING";
export const UPDATE_EVENT_TYPE_ERROR = "UPDATE_EVENT_TYPE_ERROR";
export const UPDATE_EVENT_TYPE_SUCCESS = "UPDATE_EVENT_TYPE_SUCCESS";

export const DELETE_EVENT_TYPE_LOADING = "DELETE_EVENT_TYPE_LOADING";
export const DELETE_EVENT_TYPE_ERROR = "DELETE_EVENT_TYPE_ERROR";
export const DELETE_EVENT_TYPE_SUCCESS = "DELETE_EVENT_TYPE_SUCCESS";

export type EventTypeTypeI = {
  id: string;
  name: string;
};

//===============================<START>GET EVENT_TYPE<START>======================//

export interface GetEventTypeLoading {
  type: typeof GET_EVENT_TYPE_LOADING;
}

export interface GetEventTypeError {
  type: typeof GET_EVENT_TYPE_ERROR;
}

export interface GetEventTypeSuccess {
  type: typeof GET_EVENT_TYPE_SUCCESS;
  payload: EventTypeTypeI[];
}

//===============================<END>GET EVENT_TYPE<END>======================//

//===============================<START>ADD EVENT_TYPE<START>======================//

export interface AddEventTypeLoading {
  type: typeof ADD_EVENT_TYPE_LOADING;
}

export interface AddEventTypeError {
  type: typeof ADD_EVENT_TYPE_ERROR;
  payload: any;
}

export interface AddEventTypeSuccess {
  type: typeof ADD_EVENT_TYPE_SUCCESS;
  payload: EventTypeTypeI;
}

//===============================<END>ADD EVENT_TYPE<END>======================//

//===============================<START>UPDATE EVENT_TYPE<START>======================//

export interface UpdateEventTypeLoading {
  type: typeof UPDATE_EVENT_TYPE_LOADING;
}

export interface UpdateEventTypeError {
  type: typeof UPDATE_EVENT_TYPE_ERROR;
  payload: any;
}

export interface UpdateEventTypeSuccess {
  type: typeof UPDATE_EVENT_TYPE_SUCCESS;
  payload: EventTypeTypeI;
}

//===============================<END>UPDATE EVENT_TYPE<END>======================//

//===============================<START>DELETE EVENT_TYPE<START>======================//

export interface DeleteEventTypeLoading {
  type: typeof DELETE_EVENT_TYPE_LOADING;
}

export interface DeleteEventTypeError {
  type: typeof DELETE_EVENT_TYPE_ERROR;
}

export interface DeleteEventTypeSuccess {
  type: typeof DELETE_EVENT_TYPE_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE EVENT_TYPE<END>======================//

export type EventTypeDispatchTypes =
  | GetEventTypeLoading
  | GetEventTypeError
  | GetEventTypeSuccess
  | AddEventTypeLoading
  | AddEventTypeError
  | AddEventTypeSuccess
  | UpdateEventTypeLoading
  | UpdateEventTypeError
  | UpdateEventTypeSuccess
  | DeleteEventTypeLoading
  | DeleteEventTypeError
  | DeleteEventTypeSuccess;
