import React from 'react';
import { Box, Button, Grid, LinearProgress, Paper } from '@material-ui/core';
import { useFormStyles } from '../../Styles/FormStyles';
import { Link } from 'react-router-dom';
import AddNewButton from '../Buttons/AddNewButton';

const FullWidthFormLayout = (props: any) => {
  const classes = useFormStyles();

  return (
    <Paper
      className={props.noMargin ? '' : classes.pageContentBox}
      style={{
        padding: props.noMargin ? '1rem' : '',
        boxShadow: 'rgb(0 0 0 / 6%) 1px 1px 18px 1px',
      }}
    >
      <Box className={classes.form}>
        <Grid container>
          <Grid item xs={9} className={classes.formTitle}>
            <span>{props.title}</span>
          </Grid>
          <Grid item xs={3}>
            <Grid container justifyContent="flex-end">
              {props.extraButton ? (
                <>
                  {props.link ? (
                    <Link to={props.Link} style={{ textDecoration: 'none' }}>
                      <AddNewButton
                        variant="outlined"
                        color="primary"
                        title={props.captionTip}
                      >
                        {props.caption}
                      </AddNewButton>
                    </Link>
                  ) : (
                    <AddNewButton
                      variant="outlined"
                      color="primary"
                      title={props.captionTip}
                      onClick={props.OnClick}
                    >
                      {props.caption}
                    </AddNewButton>
                  )}
                </>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <form className={classes.form} onSubmit={props.onSubmit}>
        {props.children}
      </form>
    </Paper>
  );
};

export default FullWidthFormLayout;
