export const ADD_KHALTI_PAYMENT_LOADING = "ADD_KHALTI_PAYMENT_LOADING";
export const ADD_KHALTI_PAYMENT_ERROR = "ADD_KHALTI_PAYMENT_ERROR";
export const ADD_KHALTI_PAYMENT_SUCCESS = "ADD_KHALTI_PAYMENT_SUCCESS";

export type RequsetKhaltiDataI = {
  student: string;
  receipt_id: string;
  issued_date: string;
  applied_opening_balance: boolean,
  remarks: string;
  payment_method: string;
  invoices: Array<string>;
  miscellaneous_invoices: Array<string>;
  dues: Array<string>
  khalti_token: string,
  khalti_amount: number
};

export interface AddKhaltiPaymentLoading {
  type: typeof ADD_KHALTI_PAYMENT_LOADING;
}

export interface AddKhaltiPaymentError {
  type: typeof ADD_KHALTI_PAYMENT_ERROR;
}

export interface AddKhaltiPaymentSuccess {
  type: typeof ADD_KHALTI_PAYMENT_SUCCESS;
  payload: RequsetKhaltiDataI;
}


export type KhaltiPaymentDispatchTypes =
  | AddKhaltiPaymentLoading
  | AddKhaltiPaymentError
  | AddKhaltiPaymentSuccess