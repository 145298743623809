/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/prop-types */
// ---------------------------- <START> module imports start ----------------------------------//
import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { useTableStyles } from "../../Styles/TableStyles";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import { FineTypeI } from "../../../actions/Fees/FineType/FineActionTypes";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import { DeleteFine, GetFine } from "../../../actions/Fees/FineType/FineAction";
import Dialog from "@material-ui/core/Dialog";
import cx from "clsx";
import {
  ItemCancelButton,
  ItemDeleteButton,
  ItemEditButton,
  ItemSaveButton,
} from "../../Reusable/Buttons/TableButton";
import { Autocomplete, TreeItem, TreeView } from "@material-ui/lab";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import Label from "@material-ui/icons/Label";
import { makeStyles } from "@material-ui/core/styles";
import LocalAtmOutlinedIcon from "@material-ui/icons/LocalAtmOutlined";
import CustomizedSelect from "../../Reusable/Inputs/Select";
import {
  paymentMethodChoices,
  sendSmsChoices,
  Tupple,
} from "../../../common/json.constant";
import { FormCheckBox } from "../../Reusable/Inputs/Checkbox";
import { GetPaymentMethod } from "../../../actions/Fees/PaymentMethod/PaymentMethodAction";
import CustomizedNepaliDatePicker from "../../Reusable/Inputs/NepaliDatePicker";
import { useForm } from "react-hook-form";
import formsValidationCheckup from "../../utils/formsValidationCheckUp";
import { dateConverterBsToAd } from "../../utils/dateConverter";
import { CollectFee } from "../../../actions/Fees/CollectFees/CollectFeesAction";
import FeeInvoice from "./FeeInvoice";
import { GetGeneralInfoData } from "../../../actions/GeneralSettings/GeneralInfo/GeneralInfoAction";
import { SET_SNACKBAR } from "../../../actions/SnackbarActionTypes";
import useBorderedTable from "../../Reusable/useBorderedTable";
import { LeakAddTwoTone } from "@material-ui/icons";
import DisableDialog from "../../Reusable/Dialogs/DisableDialog";
import getMonthName from "../../utils/getMonthName";
import { PostBillAdjustment } from "../../../actions/Fees/BillAdjustment/BillAdjustmentAction";
import getGradeFeeAmount from "../../utils/getGradeFeeAmount";
// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//
interface PropsI {
  onEditData: (value: FineTypeI) => void;
  editCheck: { check: boolean; count: number };
}

interface HeadCellsI {
  id: string;
  label: string;
  width?: string;
}
// ---------------------------- <END> interface ends ----------------------------------//

// ---------------------------- <START> styling starts ----------------------------------//

// ---------------------------- <END> styling ends ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N." },
  { id: "fee_head", label: "Fees Head" },
  { id: "period", label: "Period" },
  { id: "charges", label: "Charges" },
  { id: "fine_type", label: "Fine Type" },
  { id: "fine_amount", label: "Fine Amount" },
  { id: "balance", label: "Balance" },
  { id: "paid", label: "Paid" },
  { id: "action", label: "Action" },
];

const miscHeadCells: HeadCellsI[] = [
  { id: "misc_name", label: "Misc Fee Name" },
  { id: "misc_amount", label: "Misc Amount" },
];

const dueHeadCells: HeadCellsI[] = [
  { id: "due_name", label: "Due Fee Name" },
  { id: "period", label: "Period" },
  { id: "charges", label: "Charges" },
  { id: "receipt", label: "Previous Receipt" },
  { id: "due_amount", label: "Due Amount" },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

const useTreeItemStyles = makeStyles((theme: any) => ({
  root: {
    color: theme.palette.text.secondary,
    "&:hover > $content": {
      backgroundColor: "#e4e3e3",
    },
    "&:focus > $content, &$selected > $content": {
      backgroundColor: `#e4e3e3`,
      color: "var(--tree-view-color)",
    },
    "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label":
      {
        backgroundColor: "#e4e3e3",
      },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    "& $content": {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: "inherit",
    color: "inherit",
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: "inherit",
    flexGrow: 1,
  },
}));

function StyledTreeItem(props: any) {
  const classes = useTreeItemStyles();
  const {
    labelText,
    labelIcon: LabelIcon,
    labelInfo,
    color,
    bgColor,
    ...other
  } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LabelIcon color="inherit" className={classes.labelIcon} />
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        background: "#e4e3e3",
      }}
      classes={{
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
        root: classes.root,
      }}
      {...other}
    />
  );
}

// ---------------------------- <START> fine Type Table Component Starts ---------------------------//
const PayBillTable = (props: any) => {
  const {
    invoiceID,
    selected,
    setStudent,
    feeHeadRows,
    setFeeHeadRows,
    student,
    setSelected,
  } = props;

  const classes = useTableStyles();
  // const { onEditData, editCheck } = props;

  // payment method reducer
  const dispatch = useDispatch();

  const { register, handleSubmit, setValue, reset, errors, clearErrors } =
    useForm();

  // Modal States
  const [loading, setLoading] = useState<boolean>(false);
  const [submitConfirm, setSubmitConfirm] = useState<boolean>(false);
  const [confirmDialog, setConfirmDialog] = useState<boolean>(false);
  const [paymentMethod, setPaymentMethod] = useState<string>("");
  const [sendSms, setSendSms] = useState<string>("");
  const [smsNotification, setSmsNotification] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string>("");
  const [payment, setPayment] = useState<any>(null);
  const [paymentChoices, setPaymentChoices] = useState<any>([]);
  const [totalPayable, setTotalPayable] = useState<number>(0);
  const [tempTotalPayable, setTempTotalPayable] = useState<number>(0);
  const [totalPaying, setTotalPaying] = useState<number>(0);
  const [dueAmt, setDueAmt] = useState<number>(0);
  const [advanceAmount, setAdvanceAmount] = useState<number>(0);
  const [discountAmt, setDiscountAmt] = useState<number>(0);
  const [subTotal, setSubTotal] = useState<number>(0);
  const [editPaidValue, setEditPaidValue] = useState<number>(0);
  const [issueDate, setIssueDate] = useState<string>("2078-07-13");
  const [onEditPaying, setOnEditPaying] = useState(false);
  const [onEditDiscount, setOnEditDiscount] = useState(false);
  const [openInvoice, setOpenInvoice] = useState(true);
  const [fineOptions, setFineOptions] = useState<any>([]);
  const [selectedMiscArray, setSelectedMisArray] = useState<any>([]);
  const [selectedPendingArray, setSelectedPendingArray] = useState<any>([]);
  const [fromOB, setFromOB] = useState<boolean>(true);
  const [selectedMisc, setSelectedMisc] = React.useState<string[]>([]);
  const [feeHeadOptions, setFeeHeadOptions] = useState<any>(null);
  const [selectedPending, setSelectedPending] = React.useState<string[]>([]);
  const [miscRows, setMiscRows] = useState<any[]>([]);
  const [pendingRows, setPendingRows] = useState<any[]>([]);
  const [totalToPayable, setTotalToPayable] = useState<number>(0);
  const [footerTable, setFooterTable] = useState<any>([
    {
      name: "Opening Balance",
      dr: 0.0,
      cr: 0.0,
    },

    {
      name: "Discount",
      value: 0,
    },
    {
      name: "Total Payable",
      value: totalPayable,
    },
    {
      name: "Paying Amount",
      value: 0,
    },
    // {
    //   name: "Due Balance",
    //   value: 0,
    // },
  ]);

  const openingBalance = useSelector(
    (state: RootStore) => state.collect_fees?.collect_fees?.opening_balance
  );

  const miscFees = useSelector(
    (state: RootStore) => state.collect_fees?.collect_fees
  );

  const isCollected = useSelector(
    (state: RootStore) => state.collect_fees?.fee_collected
  );

  const collectedFees = useSelector(
    (state: RootStore) => state.collect_fees?.collected_fee
  );

  const paymentMethodState = useSelector(
    (state: RootStore) => state.payment_method?.payment_method
  );

  const fineTypeState = useSelector((state: RootStore) => state.fine_type);

  const authData = useSelector((state: RootStore) => state.auth);

  const generalInfoData = useSelector(
    (state: RootStore) => state.general_info?.general_info
  );

  const invoiceData = useSelector(
    (state: RootStore) => state.day_book.invoice_detail
  );

  // useEffect(() => {
  //     editCheck && setEditMode(editCheck.check);
  // }, [editCheck]);
  console.log({ invoiceData });

  useEffect(() => {
    dispatch(GetFine());
    dispatch(GetPaymentMethod());
  }, []);

  // useEffect(() => {
  //   if (authData.general_info != null) {
  //     dispatch(GetGeneralInfoData(authData.general_info.id));
  //   }
  // }, [authData]);

  useEffect(() => {
    if (paymentMethodState !== null) {
      const data: any = [];
      paymentMethodState.map((elem: any) => {
        data.push({
          key: elem.id,
          value: elem.name,
        });
      });
      setPaymentChoices(data);
    }
  }, [paymentMethodState]);

  useEffect(() => {
    if (fineTypeState !== null) {
      setFineOptions(fineTypeState.fine);
    }
  }, [fineTypeState]);

  useEffect(() => {
    const paidData: any[] = [];
    const dueData: any[] = [];
    console.log({ invoiceData });

    if (
      invoiceData?.invoice_detail[0] &&
      invoiceData?.invoice_detail[0]?.student_paid_invoice !== null
    ) {
      const data: any[] = [];
      for (
        let i = 0;
        i < invoiceData?.invoice_detail[0]?.student_paid_invoice?.length;
        i++
      ) {
        data.push({
          fee_type_id:
            invoiceData?.invoice_detail[0]?.student_paid_invoice[i]?.fee_type
              ?.id,
          fee_name:
            invoiceData?.invoice_detail[0]?.student_paid_invoice[i]?.invoice
              ?.month !== ""
              ? invoiceData?.invoice_detail[0]?.student_paid_invoice[i]?.invoice
                  ?.fee_type?.name +
                getMonthName(
                  invoiceData?.invoice_detail[0]?.student_paid_invoice[i]
                    ?.invoice?.month
                )
              : invoiceData?.invoice_detail[0]?.student_paid_invoice[i]?.invoice
                  ?.fee_type?.name,
          invoice_id:
            invoiceData?.invoice_detail[0]?.student_paid_invoice[i]?.id,
          fee_type_due_date:
            invoiceData?.invoice_detail[0]?.student_paid_invoice[i]?.invoice
              ?.fee_type?.due_date,
          month: getMonthName(
            invoiceData?.invoice_detail[0]?.student_paid_invoice[i]?.invoice
              ?.month
          ),
          charges: getGradeFeeAmount(
            invoiceData?.invoice_detail[0]?.student?.grade?.id,
            invoiceData?.invoice_detail[0]?.student_paid_invoice[i]?.invoice
              ?.fee_type?.grade_fee_amount
          ),
          selected: false,
          fine: invoiceData?.invoice_detail[0]?.student_paid_invoice[i]
            ?.invoice_fine_applied[0]?.fine?.name,
          fine_amount:
            invoiceData?.invoice_detail[0]?.student_paid_invoice[i]
              ?.invoice_fine_applied[0]?.fine?.fine_in === "P"
              ? (
                  (Number(
                    invoiceData?.invoice_detail[0]?.student_paid_invoice[i]
                      ?.invoice_fine_applied[0]?.fine?.fine_applicable
                  ) /
                    100) *
                  getGradeFeeAmount(
                    invoiceData?.invoice_detail[0]?.student?.grade?.id,
                    invoiceData?.invoice_detail[0]?.student_paid_invoice[i]
                      ?.invoice?.fee_type?.grade_fee_amount
                  )
                ).toFixed(2)
              : invoiceData?.invoice_detail[0]?.student_paid_invoice[i]
                  ?.invoice_fine_applied[0]?.fine?.fine_applicable,
          balances:
            invoiceData?.invoice_detail[0]?.student_paid_invoice[i]
              ?.invoice_fine_applied[0]?.fine?.fine_in === "P"
              ? Number(
                  (
                    (Number(
                      invoiceData?.invoice_detail[0]?.student_paid_invoice[i]
                        ?.invoice_fine_applied[0]?.fine?.fine_applicable
                    ) /
                      100) *
                    getGradeFeeAmount(
                      invoiceData?.invoice_detail[0]?.student?.grade?.id,
                      invoiceData?.invoice_detail[0]?.student_paid_invoice[i]
                        ?.invoice?.fee_type?.grade_fee_amount
                    )
                  ).toFixed(2)
                ) +
                getGradeFeeAmount(
                  invoiceData?.invoice_detail[0]?.student?.grade?.id,
                  invoiceData?.invoice_detail[0]?.student_paid_invoice[i]
                    ?.invoice?.fee_type?.grade_fee_amount
                )
              : Number(
                  invoiceData?.invoice_detail[0]?.student_paid_invoice[i]
                    ?.invoice_fine_applied[0]?.fine?.fine_applicable
                ) +
                  getGradeFeeAmount(
                    invoiceData?.invoice_detail[0]?.student?.grade?.id,
                    invoiceData?.invoice_detail[0]?.student_paid_invoice[i]
                      ?.invoice?.fee_type?.grade_fee_amount
                  ) ||
                getGradeFeeAmount(
                  invoiceData?.invoice_detail[0]?.student?.grade?.id,
                  invoiceData?.invoice_detail[0]?.student_paid_invoice[i]
                    ?.invoice?.fee_type?.grade_fee_amount
                ),
          amount:
            invoiceData?.invoice_detail[0]?.student_paid_invoice[i]
              ?.paid_amount,
          paid: invoiceData?.invoice_detail[0]?.student_paid_invoice[i]
            ?.paid_amount,
          editMode: false,
        });
      }
      // console.log('xxxxx', data)
      setFeeHeadOptions(data);
    }
    if (
      invoiceData?.invoice_detail[0] &&
      invoiceData?.invoice_detail[0]?.student_paid_miscellaneous_invoice !==
        null
    ) {
      setMiscRows(
        invoiceData?.invoice_detail[0]?.student_paid_miscellaneous_invoice
      );
    }
    if (
      invoiceData?.invoice_detail[0] &&
      invoiceData?.invoice_detail[0]?.student_paid_due_invoice !== null
    ) {
      setPendingRows(invoiceData?.invoice_detail[0]?.student_paid_due_invoice);
    }
    setFromOB(
      invoiceData?.invoice_detail &&
        invoiceData?.invoice_detail[0]?.applied_opening_balance
    );
    if (invoiceData && invoiceData?.invoice_detail[0]) {
      let count1 = 0;
      let count2 = 0;
      let count3 = 0;

      const discount =
        invoiceData?.invoice_detail[0]?.discount !== null
          ? Number(invoiceData?.invoice_detail[0]?.discount)
          : 0;

      invoiceData?.invoice_detail[0]?.student_paid_invoice?.map((elem: any) => {
        count1 += Number(elem.paid_amount);
      });
      invoiceData?.invoice_detail[0]?.student_paid_due_invoice?.map(
        (elem: any) => {
          count2 += Number(elem.paid_amount);
        }
      );
      invoiceData?.invoice_detail[0]?.student_paid_miscellaneous_invoice?.map(
        (elem: any) => {
          count3 += Number(elem.paid_amount);
        }
      );
      console.log(count1, count2, count3, discount);

      setTotalToPayable(count1 + count2 + count3 - Number(discount));
    }
  }, [invoiceData]);

  // console.log({ feeHeadOptions });

  // States declaration

  // -----------------<END> Pagination states and functions <END>-----------------------//

  // Retrieving re-usable components from useTable
  const { TableContainer, StyledTableCell, StyledTableRow } =
    useBorderedTable(headCells);

  // Function for handling action icons click event

  // const handleEditClicked = (data: FineTypeI) => {
  //     onEditData(data);
  //     setEditMode(true);
  // };
  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const handleDeleteModal = (value: boolean, id: string) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };

  const handleFineTypeChange = (value: any, id: string) => {
    console.log(id, value);
    const findFine_ = fineOptions?.find((elem: any) => elem.id === value);
    const feeHeadRows_ = feeHeadRows;
    const temp = feeHeadRows_?.map((elem: any) =>
      elem.invoice_id === id
        ? {
            ...elem,
            fine: value,
            fine_amount:
              findFine_?.fine_in === "P"
                ? (
                    (Number(findFine_?.fine_applicable) / 100) *
                    Number(elem.charges)
                  ).toFixed(2)
                : findFine_?.fine_applicable,
            amount: !findFine_
              ? elem.charges
              : findFine_?.fine_in === "P"
              ? (
                  Number(elem.charges) +
                  (Number(findFine_?.fine_applicable) / 100) *
                    Number(elem.charges)
                ).toFixed(2)
              : Number(elem.charges) + Number(findFine_?.fine_applicable),
            paid: !findFine_
              ? elem.charges
              : findFine_?.fine_in === "P"
              ? (
                  Number(elem.charges) +
                  (Number(findFine_?.fine_applicable) / 100) *
                    Number(elem.charges)
                ).toFixed(2)
              : Number(elem.charges) + Number(findFine_?.fine_applicable),
          }
        : elem
    );
    setFeeHeadRows([...temp]);
  };

  const handlePaidColChange = (value: any, id: string) => {
    setEditPaidValue(Number(value));
  };

  const handleFeeHeadRowEdit = (id: string, value: any) => {
    setEditPaidValue(Number(value));
    const data__ = feeHeadOptions;
    const changed = data__?.findIndex((elem: any) => elem.invoice_id === id);
    data__[changed].editMode = true;
    setFeeHeadOptions([...data__]);
  };
  const handleFeeHeadRowSave = (id: string) => {
    const finalValues = {
      paid_invoice: id,
      updated_amount: editPaidValue,
    };
    dispatch(PostBillAdjustment(finalValues, invoiceID));
    console.log(finalValues, invoiceID);
  };
  const handleFeeHeadRowCancel = (id: string) => {
    setEditPaidValue(0);
    const data__ = feeHeadOptions;
    const changed = data__?.findIndex((elem: any) => elem.invoice_id === id);
    data__[changed].editMode = false;
    setFeeHeadOptions([...data__]);
  };
  const getCustomTableRow = (item: any, index: number) => {
    return (
      <StyledTableRow key={index}>
        <StyledTableCell align="center" className={classes.cell}>
          {index}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.fee_name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.fee_type_due_date !== null ? item.fee_type_due_date : "Monthly"}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {"Rs. " + item.charges}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.fine}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.fine_amount && "Rs. " + item.fine_amount}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {"Rs. " + item.balances}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <Grid style={{ display: "flex", justifyContent: "space-between" }}>
            <Grid style={{ margin: "auto" }}>
              {item.editMode ? (
                <input
                  name={index.toString()}
                  id={index.toString()}
                  style={{ width: "120px" }}
                  type="number"
                  defaultValue={editPaidValue > 0 ? editPaidValue : item.paid}
                  className="custom-input"
                  onBlur={(event: any) =>
                    handlePaidColChange(event.target.value, item?.invoice_id)
                  }
                />
              ) : (
                "Rs. " + item.paid
              )}
            </Grid>
          </Grid>
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <Grid style={{ margin: "auto" }}>
            {!item.editMode && (
              <ItemEditButton
                onClick={() =>
                  handleFeeHeadRowEdit(item?.invoice_id, item.paid)
                }
              />
            )}
            {item.editMode && (
              <>
                <ItemSaveButton
                  onClick={() => handleFeeHeadRowSave(item?.invoice_id)}
                />
                <ItemCancelButton
                  onClick={() => handleFeeHeadRowCancel(item?.invoice_id)}
                />
              </>
            )}
          </Grid>
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  const handleSelectAllClickMisc = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      const newSelecteds = miscRows.map((n: any) => n.id);
      setSelectedMisc(newSelecteds);
      setSelectedMisArray(miscRows);
      return;
    }
    setSelectedMisc([]);
    setSelectedMisArray([]);
  };

  const handleSelectAllClickPending = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      const newSelecteds = pendingRows.map((n: any) => n.id);
      setSelectedPending(newSelecteds);
      setSelectedPendingArray(pendingRows);
      return;
    }
    setSelectedPending([]);
    setSelectedPendingArray([]);
  };

  const handleCheckClickMisc = (name: string) => {
    const selectedIndex = selectedMisc.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedMisc, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedMisc.slice(1));
    } else if (selectedIndex === selectedMisc.length - 1) {
      newSelected = newSelected.concat(selectedMisc.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedMisc.slice(0, selectedIndex),
        selectedMisc.slice(selectedIndex + 1)
      );
    }
    setSelectedMisc(newSelected);

    let selectedMiscInvoices: any = [];

    if (miscRows?.length > 0) {
      const tempIDs: any[] = newSelected;
      const new_datum = miscRows.filter((item: any) =>
        tempIDs.includes(item.id)
      );
      selectedMiscInvoices = new_datum;
    } else {
      selectedMiscInvoices = [];
    }
    setSelectedMisArray(selectedMiscInvoices);
  };

  const handleCheckClickPending = (name: string) => {
    const selectedIndex = selectedPending.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedPending, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedPending.slice(1));
    } else if (selectedIndex === selectedPending.length - 1) {
      newSelected = newSelected.concat(selectedPending.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedPending.slice(0, selectedIndex),
        selectedPending.slice(selectedIndex + 1)
      );
    }
    setSelectedPending(newSelected);

    let selectedPendingInvoices: any = [];

    if (pendingRows?.length > 0) {
      const tempIDs: any[] = newSelected;
      const new_datum = pendingRows.filter((item: any) =>
        tempIDs.includes(item.id)
      );
      selectedPendingInvoices = new_datum;
    } else {
      selectedPendingInvoices = [];
    }
    setSelectedPendingArray(selectedPendingInvoices);
  };

  const isSelected = (name: string) => selectedMisc.indexOf(name) !== -1;
  const isSelectedPending = (name: string) =>
    selectedPending.indexOf(name) !== -1;

  const getMiscCustomTableRow = (item: any, index: number) => {
    const isItemSelected = isSelected(item.id);
    const labelId = `enhanced-table-checkbox-${index}`;
    const cellStyle = cx(classes.cell, classes.cellSm);

    return (
      <StyledTableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={item.id}
        aria-checked={isItemSelected}
        selected={isItemSelected}
      >
        <StyledTableCell align="center" className={cellStyle}>
          {item.invoice?.fee_type?.name}
        </StyledTableCell>
        <StyledTableCell align="center" className={cellStyle}>
          {item.paid_amount}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  const getPendingCustomTableRow = (item: any, index: number) => {
    const isItemSelected = isSelectedPending(item.id);
    const labelId = `enhanced-table-checkbox-${index}`;
    const cellStyle = cx(classes.cell, classes.cellSm);

    return (
      <StyledTableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={item.id}
        aria-checked={isItemSelected}
        selected={isItemSelected}
      >
        <StyledTableCell align="center" className={cellStyle}>
          {item.due_invoice?.fee_type_name}
        </StyledTableCell>
        <StyledTableCell align="center" className={cellStyle}>
          {/* {item.period} */}
        </StyledTableCell>
        <StyledTableCell align="center" className={cellStyle}>
          {/* Rs. {item.due_invoice?.due_amount} */}
        </StyledTableCell>
        <StyledTableCell align="center" className={cellStyle}>
          {item.due_invoice?.invoice_id}
        </StyledTableCell>
        <StyledTableCell align="center" className={cellStyle}>
          Rs. {item.due_invoice?.due_amount}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  useEffect(() => {
    if (invoiceData?.opening_balance[0] !== null) {
      const data = footerTable;
      console.log(
        "llllllllll",
        invoiceData?.opening_balance[0]?.debit,
        invoiceData?.opening_balance[0]?.credit
      );

      data[0].dr = invoiceData?.opening_balance[0]?.debit || 0.0;
      data[0].cr = invoiceData?.opening_balance[0]?.credit || 0.0;
      setFooterTable(data);
    }
  }, [invoiceData?.opening_balance]);

  useEffect(() => {
    console.log(totalPaying, totalPayable);
    if (totalPaying > totalPayable) {
      setAdvanceAmount(totalPaying - totalPayable);
    }
  }, [totalPaying]);

  useEffect(() => {
    // if (feeHeadRows !== null) {
    const data = footerTable;
    let count = 0;
    let count_paying = 0;

    feeHeadRows?.map((elem: any) => {
      count += Number(elem.amount);
    });
    feeHeadRows?.map((elem: any) => {
      count_paying += Number(elem.paid);
    });

    let misc_count = 0;
    selectedMiscArray?.map((elem: any) => {
      misc_count += Number(elem.fee_type__amount);
    });

    let pending_count = 0;
    selectedPendingArray?.map((elem: any) => {
      pending_count += Number(elem.due_amount);
    });

    if (fromOB) {
      if (data[0].cr > 0) {
        count += Number(data[0].cr);
      }
      if (data[0].dr > 0) {
        if (count + misc_count + pending_count < data[0].dr) {
          setTotalPayable(0);
          setTempTotalPayable(0);
          setTotalPaying(0);
        } else {
          // count -= Number(data[0].dr)
          setTotalPayable(
            count + misc_count + pending_count - Number(data[0].dr)
          );
          setTempTotalPayable(
            count + misc_count + pending_count - Number(data[0].dr)
          );
          setTotalPaying(
            count_paying + misc_count + pending_count - Number(data[0].dr)
          );
        }
      }
    } else {
      setTotalPayable(count + misc_count + pending_count);
      setTempTotalPayable(count + misc_count + pending_count);
      setTotalPaying(count_paying + misc_count + pending_count);
    }
    // } else {
    //     let misc_count = 0
    //     selectedMiscArray?.map((elem: any) => { misc_count += Number(elem.fee_type__amount) });

    //     let pending_count = 0
    //     selectedPendingArray?.map((elem: any) => { pending_count += Number(elem.due_amount) });

    //     setTotalPayable(misc_count + pending_count)
    //     setTempTotalPayable(misc_count + pending_count)
    //     setTotalPaying(misc_count + pending_count)
    // }
  }, [feeHeadRows, selectedMiscArray, selectedPendingArray, fromOB]);

  useEffect(() => {
    setFooterTable([
      {
        name: "Opening Balance",
        dr: 0.0,
        cr: 0.0,
      },

      {
        name: "Discount",
        value: 0,
      },
      {
        name: "Total Payable",
        value: totalPayable,
      },
      {
        name: "Paying Amount",
        value: 0,
      },
      // {
      //   name: "Due Balance",
      //   value: 0,
      // },
    ]);
    if (student === null) {
      setFeeHeadRows([]);
      setSelected([]);
      setPendingRows([]);
      setMiscRows([]);
    }
  }, [student]);

  const clearData = () => {
    setStudent(null);
    setFeeHeadRows([]);
    setSelected([]);
    setPendingRows([]);
    setMiscRows([]);
    setSendSms("");
    setSmsNotification(false);
    setPayment(null);
    setTotalPayable(0);
    setTempTotalPayable(0);
    setTotalPaying(0);
    setDueAmt(0);
    setDiscountAmt(0);
    setConfirmDialog(false);
    setFooterTable([
      {
        name: "Opening Balance",
        dr: 0.0,
        cr: 0.0,
      },

      {
        name: "Discount",
        value: 0,
      },
      {
        name: "Total Payable",
        value: totalPayable,
      },
      {
        name: "Paying Amount",
        value: 0,
      },
      // {
      //   name: "Due Balance",
      //   value: 0,
      // },
    ]);
  };

  useEffect(() => {
    if (!(selected?.length > 0)) {
      // setTotalPaying(0)
      setDueAmt(0);
      setDiscountAmt(0);
      setTotalPaying(0);
      setTotalPayable(0);
      setTempTotalPayable(0);
    }
  }, [selected]);

  const onCollectFees = () => {
    const paidInvoices: any = [];
    feeHeadRows?.map((elem: any, index: number) => {
      console.log({ elem });
      paidInvoices.push({
        invoice: elem.invoice_id,
        paid_amount: Number(elem.paid),
        applied_scholarship: [],
        fines: elem.fine ? [elem.fine] : [],
      });
    });

    //Misc fee logic
    const miscInvoices: any = [];
    let selectedMiscInvoices: any = [];

    if (miscRows?.length > 0) {
      const tempIDs: any[] = selectedMisc;
      const new_datum = miscRows.filter((item: any) =>
        tempIDs.includes(item.id)
      );
      selectedMiscInvoices = new_datum;
    } else {
      selectedMiscInvoices = [];
    }

    selectedMiscInvoices?.map((elem: any, index: number) => {
      miscInvoices.push({
        invoice: elem.id,
        paid_amount: elem.fee_type__amount,
      });
    });

    //Due Fee logics
    const pendingInvoices: any = [];
    let selectedPendingInvoices: any = [];

    if (pendingRows?.length > 0) {
      const tempIDs: any[] = selectedPending;
      const new_datum = pendingRows.filter((item: any) =>
        tempIDs.includes(item.id)
      );
      selectedPendingInvoices = new_datum;
    } else {
      selectedPendingInvoices = [];
    }

    selectedPendingInvoices?.map((elem: any, index: number) => {
      pendingInvoices.push({
        invoice: elem.id,
        paid_amount: Number(elem.due_amount),
        applied_scholarship: [],
        fines: [],
      });
    });

    const post_data = {
      payment_method: payment?.key,
      issued_date: dateConverterBsToAd(issueDate),
      discount: Number(discountAmt) || 0,
      student_paid_invoice: paidInvoices || [],
      miscellaneous_invoice: miscInvoices || [],
      dues: pendingInvoices || [],
      total_paid_amount: totalPaying,
      adjust_from_opening_balance: fromOB,
      advance_amount: advanceAmount || 0,
    };
    console.log({ post_data });
    // if (payment === null) {
    //     dispatch({
    //         type: SET_SNACKBAR,
    //         payload: {
    //             snackbarOpen: true,
    //             snackbarType: "error",
    //             snackbarMessage: "Please select any of payment mode",
    //         },
    //     });
    // } else {
    dispatch(CollectFee(student?.academic_id, post_data, clearData));
    setConfirmDialog(false);
    // }
  };

  const onShowPopover = () => {
    if (payment === null) {
      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Please select any of payment mode",
        },
      });
    } else {
      setConfirmDialog(true);
    }
  };

  const handleDialogClose = (value: boolean) => {
    setConfirmDialog(value);
  };

  // useEffect(() => {
  //     if(subTotal !== 0) {
  //         let count = 0
  //         if (footerTable[0].cr > 0) { count -= Number(footerTable[0].cr) }
  //         if (footerTable[0].dr > 0) { count += Number(footerTable[0].dr) }
  //         console.log({ count })
  //     }
  // },[subTotal])

  console.log(feeHeadRows, selectedMisc, selectedPending);

  const handleIssueDate = (date: string) => {
    setIssueDate(date);
  };

  const onEditPayingAmount = (e: any) => {
    setOnEditPaying(true);
  };

  const onTextFieldChange = (e: any) => {
    console.log(e.keyCode);
    if (e.keyCode === 13) {
      setOnEditPaying(false);
    }
  };

  const onEditDiscountAmount = (e: any) => {
    setOnEditDiscount(true);
  };

  const onDiscountTextFieldChange = (e: any) => {
    if (e.keyCode === 13) {
      setOnEditDiscount(false);
    }
  };

  const handlePayingChange = (e: any) => {
    console.log(e.target.value);
    setTotalPaying(e.target.value);
  };

  const handleDiscountChange = (e: any) => {
    console.log(e.target.value);
    setDiscountAmt(e.target.value);
    const temp = Number(tempTotalPayable) - Number(e.target.value || 0);
    setTotalPayable(temp);
    setTotalPaying(temp);
  };

  useEffect(() => {
    if (
      invoiceData?.invoice_detail[0]?.paid_amount !== null &&
      totalToPayable > 0
    ) {
      const due =
        Number(invoiceData?.invoice_detail[0]?.paid_amount) -
        Number(totalToPayable);
      setDueAmt(due);
    }
  }, [invoiceData?.invoice_detail, totalToPayable]);

  // useEffect(() => {
  //     if (discountAmt > 0) {
  //         console.log('on the goo', totalPayable)
  //         const temp = Number(totalPayable) - Number(discountAmt)
  //         setTotalPayable(temp)
  //     }
  // }, [discountAmt])

  const handleCloseInvoice = () => {
    setOpenInvoice(false);
  };

  const handleOpenInvoice = () => {
    setOpenInvoice(true);
  };

  useEffect(() => {
    if (isCollected) {
      handleOpenInvoice();
    } else {
      handleCloseInvoice();
    }
  }, [isCollected]);

  console.log({ fromOB });
  return (
    <>
      <Paper style={{ padding: "10px 15px 20px 15px" }}>
        <form>
          <Grid container>
            {/* <Grid item xs={3} style={{ marginTop: "3px" }}>
              <span>Invoice Number: {invoiceID}</span>
            </Grid> */}
            {/* <Grid item xs={4} style={{ marginTop: '-13px' }}>
              <>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ paddingTop: '15px' }}>Issue Date: </div>
                  <div style={{ width: '250px' }}>
                    <CustomizedNepaliDatePicker
                      value={issueDate}
                      name="issue_date"
                      setValue={handleIssueDate}
                    />
                  </div>
                </div>
              </>
            </Grid> */}
          </Grid>
          <div style={{ maxHeight: "calc(100vh - 55vh)", overflowY: "auto" }}>
            <TableContainer
              loading={loading}
              items={feeHeadOptions}
              headCells={headCells}
              getCustomTableRow={getCustomTableRow}
              disableSearch
              disablePrint
              disablePagination
            />
          </div>

          <TreeView
            defaultExpanded={["1"]}
            defaultCollapseIcon={<ArrowDropDownIcon />}
            defaultExpandIcon={<ArrowRightIcon />}
            defaultEndIcon={<div style={{ width: 30 }} />}
            style={{ background: "none" }}
          >
            <StyledTreeItem
              nodeId={pendingRows?.length > 0 ? "1" : "100"}
              labelText={`Paid Dues (${pendingRows?.length || 0})`}
              labelIcon={LocalAtmOutlinedIcon}
            >
              <TableContainer
                hasCheckbox
                loading={loading}
                items={pendingRows}
                rowCount={pendingRows?.length}
                headCells={dueHeadCells}
                numSelected={selectedPending?.length}
                // handleSelectAllClicked={handleSelectAllClickPending}
                getCustomTableRow={getPendingCustomTableRow}
                disableSearch
                disablePrint
                disablePagination
              />
            </StyledTreeItem>
          </TreeView>
          <TreeView
            defaultExpanded={["1"]}
            defaultCollapseIcon={<ArrowDropDownIcon />}
            defaultExpandIcon={<ArrowRightIcon />}
            defaultEndIcon={<div style={{ width: 30 }} />}
            style={{ background: "none", marginTop: "5px" }}
          >
            <StyledTreeItem
              nodeId={miscRows?.length > 0 ? "1" : "10"}
              labelText={`Paid Miscellaneous (${miscRows?.length || 0})`}
              labelIcon={LocalAtmOutlinedIcon}
            >
              <TableContainer
                hasCheckbox
                loading={loading}
                items={miscRows}
                rowCount={miscRows?.length}
                headCells={miscHeadCells}
                numSelected={selectedMisc?.length}
                // handleSelectAllClicked={handleSelectAllClickMisc}
                getCustomTableRow={getMiscCustomTableRow}
                disableSearch
                disablePrint
                disablePagination
              />
            </StyledTreeItem>
          </TreeView>
          {student !== null && (
            <Table>
              <TableBody>
                {footerTable.map((row: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {
                        index === 0 && (
                          // (Number(row.dr) > 0 || Number(row.cr) > 0) && (
                          <>
                            {row.name}{" "}
                            <FormControlLabel
                              style={{ marginLeft: "-2px" }}
                              control={
                                <Checkbox
                                  disabled
                                  color="primary"
                                  size="medium"
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>,
                                    checked: boolean
                                  ) => setFromOB(!fromOB)}
                                  checked={fromOB}
                                  name="ob"
                                />
                              }
                              label="If checked, will affect to total paying amount"
                              labelPlacement="end"
                            />
                          </>
                        )
                        // )}
                      }
                      {index !== 0 && row.name}
                    </TableCell>
                    <TableCell style={{ width: 160 }} align="right">
                      {index === 0 &&
                        (Number(row.dr) > 0 || Number(row.cr) > 0) &&
                        "Dr. " + row.dr}
                    </TableCell>
                    <TableCell
                      style={{ width: 160 }}
                      align="right"
                      onClick={(e) =>
                        index === 3
                          ? onEditPayingAmount(e)
                          : index === 1
                          ? onEditDiscountAmount(e)
                          : ""
                      }
                    >
                      {index === 0 &&
                        (Number(row.dr) > 0 || Number(row.cr) > 0) &&
                        "Cr. " + row.cr}
                      {index === 1 && (
                        <>
                          <span>
                            {/* {onEditDiscount ? <input
                                                    style={{ width: "120px" }}
                                                    type="number"
                                                    onKeyDown={(e) => onDiscountTextFieldChange(e)}
                                                    value={discountAmt}
                                                    onChange={(e) => handleDiscountChange(e)}
                                                // defaultValue="1000"
                                                /> : 'Rs. ' + discountAmt} */}
                            {/* <input
                            style={{ width: "120px" }}
                            className="custom-input"
                            type="number"
                            onKeyDown={(e) => onDiscountTextFieldChange(e)}
                            value={discountAmt}
                            onChange={(e) => handleDiscountChange(e)}
                          // defaultValue="1000"
                          /> */}
                            Rs. {invoiceData?.invoice_detail[0]?.discount || 0}
                          </span>
                        </>
                      )}
                      {index === 2 && "Rs. " + totalToPayable}
                      {index === 3 && (
                        <>
                          <span>
                            {/* <input
                            style={{ width: "120px" }}
                            className="custom-input"
                            type="number"
                            value={totalPaying}
                            onChange={(e) => handlePayingChange(e)}
                          /> */}
                            Rs. {invoiceData?.invoice_detail[0]?.paid_amount}
                          </span>
                        </>
                      )}
                      {index === 4 && (
                        <>
                          Rs.{Math.abs(dueAmt)}
                          <br />
                        </>
                      )}
                      {/* {fromOB &&
                        index === 4 &&
                        Number(
                          totalToPayable
                        ) <
                        Number(
                          invoiceData?.invoice_detail[0]?.paid_amount
                        ) && (
                          <b style={{ fontSize: "10px", color: "#60AC54" }}>
                            (will adjust to Debit)
                          </b>
                        )}
                      {fromOB &&
                        index === 4 &&
                        Number(
                          totalToPayable
                        ) >
                        Number(
                          invoiceData?.invoice_detail[0]?.paid_amount
                        ) && (
                          <b style={{ fontSize: "10px", color: "#F15125" }}>
                            (will adjust to Credit)
                          </b>
                        )} */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          <div style={{ marginTop: "30px" }}>
            <Grid container>
              <Grid item xs={3}>
                <b>Payment Method: </b>
                {invoiceData?.invoice_detail[0]?.payment_method?.name}
              </Grid>
              <Grid item xs={3}>
                <b>SMS Notification: </b>
                Notified
              </Grid>
              <Grid item xs={3}>
                <b>Customer Phone Number: </b>
                YES
              </Grid>
              <Grid item xs={3}>
                <b>Phone Number: </b>
                9811050526
              </Grid>
            </Grid>
          </div>
          {/* <Grid container justifyContent="flex-start" style={{ marginTop: "15px" }}>
            <Button
              onClick={() => onShowPopover()}
              variant="contained"
              color="primary"
              disabled={feeHeadRows?.length > 0 || selectedMisc?.length > 0 || selectedPending?.length > 0 ? false : true}
            >
              Proceed
            </Button>
            <Button
              style={{ marginLeft: '15px' }}
              variant="outlined"
              color="primary"
            >
              Reset
            </Button>
          </Grid> */}
        </form>
      </Paper>
      {/* <DisableDialog
        open={confirmDialog}
        onClose={handleDialogClose}
        deleteConfirmed={onCollectFees}
        dialogTitle="Are you sure to make changes. Add Carefully!"
        title="Colle Fees"
        okText="Save"
      /> */}
      {/* <Dialog
                open={openInvoice}
                onClose={handleCloseInvoice}
                aria-labelledby="form-dialog-title"
                maxWidth="sm"
                fullWidth
            // className={classes.modalBox}
            >
                <FeeInvoice generalInfoData={generalInfoData} student={student} />
            </Dialog> */}
    </>
  );
};
// ---------------------------- <END> fine Type ComponWent Ends -------------------------------//

export default PayBillTable;
