import React from "react";
import { Avatar, Card, CardContent, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

/* Styling ----------------------------------------  */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      margin: theme.spacing(0, 1),
      width: "266px",
      height: "133px",
      borderRadius: theme.spacing(1),
    },
    card: {
      display: "flex",
      alignItems: "center",
      borderBottom: "1px solid #56D9FE21",
      margin: "auto",
      justifyContent: "space-between",
    },
    absent: {
      padding: theme.spacing(1, 3),
      textAlign: "left",
      fontSize: "0.75rem",
    },
    info: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: theme.spacing(2),
      "& .MuiTypography-h3": {
        color: "#222222",
        fontSize: "34px",
      },
    },
    avatar: {
      height: "60px",
      width: "60px",
    },
  })
);
/*-----------------------------END Styling ----------------------------------------  */
//---------------------------- <START> interface starts ----------------------------------//
interface Props {
  text: string;
  num: number;
  profile?: string;
  border?: string | number;
  styles?: React.CSSProperties;
}
//---------------------------- <END> interface ends ----------------------------------//

const NCard: React.FC<Props> = ({
  text,
  num,
  profile,
  border,
  styles,
}: Props) => {
  const classes = useStyles();
  const BStyle: React.CSSProperties = { ...styles, borderLeft: border };

  return (
    <Card style={BStyle} className={classes.root}>
      <div className={classes.card}>
        <Avatar className={classes.avatar}>
          <img
            style={{ height: "100%", objectFit: "contain" }}
            src={profile}
            alt=""
          />
        </Avatar>
        <CardContent className={classes.info}>
          <Typography variant="subtitle1" color="inherit">
            {text}
          </Typography>
          <Typography component="h3" color="initial" variant="h3">
            {num}
          </Typography>
        </CardContent>
      </div>
      <Typography
        className={classes.absent}
        variant="subtitle1"
        color="inherit"
      >
        3 Students absent today
      </Typography>
    </Card>
  );
};

export default NCard;
