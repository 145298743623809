// ---------------------------- <START> module imports start ----------------------------------//
import React, { useState, useEffect } from "react";
import {
    TableBody,
    TextField,
    Grid,
    Typography,
    Paper,
    makeStyles,
    createStyles,
    Theme,
    LinearProgress
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { useTableStyles } from "../../Styles/TableStyles";
import { useTable } from "../../Reusable";
import TableActionButtons from "../../Reusable/Buttons/TableActionButtons";
import getFullName from "../../utils/getFullName";
import tablePrint from "../../Reusable/Table/PrintTable";
import { InvoiceChangeLogI, InvoiceRecordI, InvoiceStatementI } from "../../../actions/Fees/CollectFees/CollectFeesActionTypes";
import { RootStore } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { GetInvoiceChangesLog, GetInvoiceRecord, GetStatementDetails } from "../../../actions/Fees/CollectFees/CollectFeesAction";
import {
    ItemEditButton,
    ItemSaveButton,
    ItemViewButton,
} from "../../Reusable/Buttons/TableButton";
import { dateConverterAdToBs } from "../../utils/dateConverter";
import StatementDetailModal from "./modal/StatementDetails";
// ----------------------------<END> module imports end ----------------------------------//

const customStyles = makeStyles((theme: Theme) => createStyles({
    feeDetailGrid: {
        backgroundColor: "#d4d4d4",
        padding: "10px"
    }
}))

// ---------------------------- <START> interface starts ----------------------------------//
interface HeadCellsI {
    id: string;
    label: string;
}

interface PropsI {
    student_id: string | null;
}

interface FeeAmountRecordI {
    total_amount: number;
    total_paid_amount: number;
    total_dues: number;
    total_advance: number;
    financial_clear_due: number;
    financial_clear_advance: number;
}


// ---------------------------- <END> interface ends ----------------------------------//

// ---------------------------- <START> styling starts ----------------------------------//

// ---------------------------- <END> styling ends ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
    { id: "date", label: "Date" },
    { id: "fee_type", label: "Fee" },
    { id: "prev_amount", label: "Previous Paid Amount" },
    { id: "updated_amount", label: "Updated Paid Amount" },
    { id: "updated_by", label: "Updated By" }
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const BillChangeLogs = (props: PropsI) => {
    const { student_id } = props;
    const classes = useTableStyles();
    const customCssClass = customStyles();


    // States declaration
    const [tableData, setTableData] = useState<InvoiceChangeLogI[]>([]);
    const [feeRecord, setFeeRecord] = useState<FeeAmountRecordI | null>(null);
    const [tempTableData, setTempTableData] = useState<InvoiceChangeLogI[]>([]);

    const [totalRecord, setTotalRecord] = useState(tempTableData.length);
    const [rowsPerPage, setRowsPerPage] = useState(7);
    const [pageCount, setPageCount] = useState(
        Math.ceil(totalRecord / rowsPerPage)
    );
    const [page, setPage] = useState(1);

    const [recordFrom, setRecordFrom] = useState(1);
    const [recordTo, setRecordTo] = useState(7);


    const [loading, setLoading] = useState<boolean>(false);


    const handlePageChange = (event: unknown, newPage: number) => {
        setPage(newPage);
        setRecordFrom(newPage === 1 ? newPage : (newPage - 1) * rowsPerPage + 1);
        setRecordTo(newPage === 1 ? newPage * rowsPerPage : newPage * rowsPerPage);
    };

    // Retrieving re-usable components from useTable
    const {
        TblContainer,
        TblHead,
        StyledTableCell,
        StyledTableRow,
        exportToExcel,
    } = useTable(headCells);

    const dispatch = useDispatch();
    const changesLog = useSelector((state: RootStore) => state.collect_fees);


    useEffect(() => {
        if (student_id) {
            dispatch(GetInvoiceChangesLog(student_id))
        }
    }, [])

    useEffect(() => {
        const data: InvoiceChangeLogI[] = changesLog.changes_log;
        if (data) {
            const dataLength = data.length

            setTableData(data);
            setTempTableData(data);

            setTotalRecord(dataLength);
            setPageCount(Math.ceil(dataLength / rowsPerPage));
        }
        setLoading(changesLog.loading);
    }, [changesLog]);

    return (
        <>
            <Paper className={classes.rootTableTabBox}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h6" style={{ color: "#4D4F5C" }}>
                            Fee Statements
                        </Typography>
                    </Grid>
                </Grid>
                <div className={classes.tableWrapper}>
                    <TblContainer>
                        <TblHead />
                        <TableBody>
                            {tableData
                                .slice(
                                    (page - 1) * rowsPerPage,
                                    (page - 1) * rowsPerPage + rowsPerPage
                                )
                                .map((item: InvoiceChangeLogI) => (
                                    <StyledTableRow key={item.id}>
                                        <StyledTableCell align="center" className={classes.cell}>
                                            {dateConverterAdToBs(item.created_on)}
                                        </StyledTableCell>
                                        <StyledTableCell align="center" className={classes.cell}>
                                            {item.fee_type}
                                        </StyledTableCell>
                                        <StyledTableCell align="center" className={classes.cell}>
                                            {item.previous_amount}
                                        </StyledTableCell>
                                        <StyledTableCell align="center" className={classes.cell}>
                                            {item.updated_amount}
                                        </StyledTableCell>
                                        <StyledTableCell align="center" className={classes.cell}>
                                            {item.updated_by}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                        </TableBody>
                    </TblContainer>
                    {loading ? <LinearProgress /> : null}
                    <div className={classes.tableBottom}>
                        {totalRecord > 0 ? (
                            <Typography>
                                Showing {recordFrom} to{" "}
                                {recordTo > totalRecord ? totalRecord : recordTo} of{" "}
                                {totalRecord}
                            </Typography>
                        ) : (
                            <Typography>No records found</Typography>
                        )}
                        <div className={classes.pageNavigation}>
                            <Pagination
                                count={pageCount}
                                page={page}
                                onChange={handlePageChange}
                                shape="rounded"
                                color="primary"
                            />
                        </div>
                    </div>
                </div>
            </Paper>
        </>
    );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default BillChangeLogs;
