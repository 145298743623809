import React from "react";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";
import GuardianList from "../../components/Student/GuardianDetail/GuardianList";
import StudentSidebar from "../../components/Student/StudentSidebar/StudentSidebar";
import { breadcrumb } from "./Student.Breadcrumbs";

const GuardianDetailPage = () => {
  const nav = <StudentSidebar guardianDetails />;
  return (
    <SubModuleLayout module={breadcrumb} sideNav={nav}>
      <GuardianList />
    </SubModuleLayout>
  );
};
export default GuardianDetailPage;
