import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_PAYMENTMETHOD_LOADING,
  GET_PAYMENTMETHOD_ERROR,
  GET_PAYMENTMETHOD_SUCCESS,
  PaymentMethodDispatchTypes,
  ADD_PAYMENTMETHOD_LOADING,
  ADD_PAYMENTMETHOD_SUCCESS,
  ADD_PAYMENTMETHOD_ERROR,
  DELETE_PAYMENTMETHOD_SUCCESS,
  DELETE_PAYMENTMETHOD_ERROR,
  DELETE_PAYMENTMETHOD_LOADING,
  UPDATE_PAYMENTMETHOD_LOADING,
  UPDATE_PAYMENTMETHOD_SUCCESS,
  UPDATE_PAYMENTMETHOD_ERROR,
} from "./PaymentMethodActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

interface RequestDataI {
  id?: string;
  name: string;
}

export const GetPaymentMethod =
  () =>
  async (dispatch: Dispatch<PaymentMethodDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_PAYMENTMETHOD_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/payment_method/`,
        HeaderConfig()
      );

      dispatch({
        type: GET_PAYMENTMETHOD_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_PAYMENTMETHOD_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Payment Methods Loading Failed",
        },
      });
    }
  };
export const AddPaymentMethod =
  (data: RequestDataI, func: any) =>
  async (dispatch: Dispatch<PaymentMethodDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_PAYMENTMETHOD_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/payment_method/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_PAYMENTMETHOD_SUCCESS,
        payload: res.data,
      });

      func();

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Payement Method Type Added Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: ADD_PAYMENTMETHOD_ERROR,
        payload: error.response && error.response?.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Payment Method Type Add Failed",
        },
      });
    } finally {
      try {
        dispatch({
          type: GET_PAYMENTMETHOD_LOADING,
        });

        const res = await axios.get(
          `${ADMIN_API_URL}/payment_method/`,
          HeaderConfig()
        );

        dispatch({
          type: GET_PAYMENTMETHOD_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: GET_PAYMENTMETHOD_ERROR,
        });
      }
    }
  };

export const UpdatePaymentMethod =
  (id: string, data: RequestDataI, func: any) =>
  async (dispatch: Dispatch<PaymentMethodDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_PAYMENTMETHOD_LOADING,
      });

      const res = await axios.put(
        `${ADMIN_API_URL}/payment_method/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_PAYMENTMETHOD_SUCCESS,
        payload: res.data,
      });

      func();

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Payment Method Type Updated Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: UPDATE_PAYMENTMETHOD_ERROR,
        payload: error.response && error.response?.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Payment Method Type Update Failed",
        },
      });
    } finally {
      try {
        dispatch({
          type: GET_PAYMENTMETHOD_LOADING,
        });

        const res = await axios.get(
          `${ADMIN_API_URL}/payment_method/`,
          HeaderConfig()
        );

        dispatch({
          type: GET_PAYMENTMETHOD_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: GET_PAYMENTMETHOD_ERROR,
        });
      }
    }
  };

export const DeletePaymentMethod =
  (id: string) =>
  async (dispatch: Dispatch<PaymentMethodDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_PAYMENTMETHOD_LOADING,
      });

      const res = await axios.delete(
        `${ADMIN_API_URL}/payment_method/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_PAYMENTMETHOD_SUCCESS,
        payload: { id: id },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Payment Method Type Deleted Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_PAYMENTMETHOD_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Payment Method Type Delete Failed",
        },
      });
    }
  };
