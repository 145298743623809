import React from "react";
import { ReportSideBar } from "../../../../components";
import CustomizeCharacterCertificate from "../../../../components/Reports/Templates/CharacterCertificate/CustomizeCharacterCertificate";
import SubModuleLayout from "../../../../components/Reusable/Layouts/SubModuleLayout";

const CustomizeCharacterCertificatePage = () => {
  const nav = <ReportSideBar character_certificate_template />;
  return (
    <SubModuleLayout reportMenuActive sideNav={nav}>
      <CustomizeCharacterCertificate />
    </SubModuleLayout>
  );
};

export default CustomizeCharacterCertificatePage;
