import React from 'react';
import SubModuleLayout from '../../components/Reusable/Layouts/SubModuleLayout';
import TransportSidebar from '../../components/Transport/TransportSidebar';
import BusWiseDetailList from '../../components/Transport/BusWiseDetail/BusWiseDetailList';

const BusWiseDetailPage = () => {
  const nav = <TransportSidebar busWiseDetail />;
  return (
    <SubModuleLayout sideNav={nav}>
      <BusWiseDetailList />
    </SubModuleLayout>
  );
};

export default BusWiseDetailPage;
