import {
  GET_ONLINE_CLASS_LOADING,
  GET_ONLINE_CLASS_ERROR,
  GET_ONLINE_CLASS_SUCCESS,
  GET_ONLINE_CLASS_LOG_LOADING,
  GET_ONLINE_CLASS_LOG_ERROR,
  GET_ONLINE_CLASS_LOG_SUCCESS,
  OnlineClassDispatchTypes,
  AdminOnlineClassTypeI,
} from "../../actions/Classroom/OnlineClass/OnlineClassActionTypes";

interface InitialStateI {
  loading: boolean;
  online_class: AdminOnlineClassTypeI[];
  class_log: any;
}

const initialState: InitialStateI = {
  loading: false,
  online_class: [],
  class_log: [],
};

const OnlineClassReducer = (
  state: InitialStateI = initialState,
  action: OnlineClassDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_ONLINE_CLASS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_ONLINE_CLASS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_ONLINE_CLASS_SUCCESS:
      return {
        ...state,
        loading: false,
        online_class: action.payload,
      };

    case GET_ONLINE_CLASS_LOG_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_ONLINE_CLASS_LOG_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_ONLINE_CLASS_LOG_SUCCESS:
      return {
        ...state,
        loading: false,
        class_log: action.payload,
      };

    default:
      return state;
  }
};

export default OnlineClassReducer;
