import { Tab, Tabs } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { ParentsInfo } from "../../..";
import {
  GetGuardianByID,
  GetChildren,
} from "../../../../actions/Student/Guardian/GuardianAction";
import { RootStore } from "../../../../store";
import StaffRoles from "../../../HumanResource/StaffList/StaffProfile/StaffTabs/StaffRoles";
import { TabPanel, useTabStyles } from "../../StudentProfile/File/NewTabs";
import ChildrenInfo from "./Children/ChildrenInfo";
import SecondaryGuardianInfo from "./SecondaryGuardianInfo";

const GuardianTab = () => {
  const classes = useTabStyles();
  const [value, setValue] = React.useState(0);

  const dispatch = useDispatch();

  const { guardianID } = useParams<{ guardianID: string }>();
  const [guardianData, setGuardianData] = React.useState([]);
  const [data, setData] = React.useState<any>([]);

  const { actionPerformed, children, guardian_by_id } = useSelector(
    (state: RootStore) => state.guardians
  );

  React.useEffect(() => {
    guardianID != undefined && dispatch(GetGuardianByID(guardianID));
    guardianID != undefined && dispatch(GetChildren(guardianID));
  }, []);

  React.useEffect(() => {
    setGuardianData(guardian_by_id);
    setData(children);
  }, [children, guardian_by_id]);

  useEffect(() => {
    if (actionPerformed) {
      dispatch(GetGuardianByID(guardianID));
    }
  }, [actionPerformed]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        // variant="fullWidth"
      >
        <Tab label="Info" />
        <Tab label="Children" />
        <Tab label="System Role" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <SecondaryGuardianInfo guardianData={guardianData} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ChildrenInfo data={data} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <StaffRoles user="guardian" />
      </TabPanel>
    </div>
  );
};

export default GuardianTab;
