import React from "react";
import { SettingSidebar, EmailSetting } from "../../components";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const EmailSettingPage = () => {
  const nav = <SettingSidebar emailSetting />;
  return (
    <SubModuleLayout sideNav={nav}>
      <EmailSetting />
    </SubModuleLayout>
  );
};

export default EmailSettingPage;
