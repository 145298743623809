import {
  ButtonProps,
  ChipProps,
  FormControlProps,
  InputLabelProps,
  ListItemIconProps,
  ListItemProps,
  ListItemSecondaryActionProps,
  ListItemTextProps,
  ListProps,
  ListSubheaderProps,
  MenuItemProps,
  SelectProps,
  SwitchProps,
  TableBodyProps,
  TableCellProps,
  TableContainerProps,
  TableHeadProps,
  TableProps,
  TableRowProps,
  TypographyProps,
} from '@material-ui/core';
import React from 'react';
import {
  StyledButton,
  StyledChip,
  StyledContainer,
  StyledDaySubTitle,
  StyledFormControl,
  StyledInputLabel,
  StyledMenuItem,
  StyledSelect,
  StyledTable,
  StyledTableBody,
  StyledTableCell,
  StyledTableContainer,
  StyledTableHead,
  StyledTableRow,
  StyledDayCell,
  StyledListItem,
  StyledListItemIcon,
  StyledListItemSecondaryAction,
  StyledListItemText,
  StyledListSubheader,
  StyledSwitch,
  StyledList,
} from './NepaliCalendar.styles';

interface NepaliCalendarProps {
  children: React.ReactNode;
}
export default function NepaliCalendar(props: NepaliCalendarProps) {
  const { children } = props;
  return <StyledContainer>{children}</StyledContainer>;
}

NepaliCalendar.TableContainer = function NepaliCalendarTableContainer(
  props: TableContainerProps
) {
  const { children, ...restProps } = props;
  return <StyledTableContainer {...restProps}>{children}</StyledTableContainer>;
};

NepaliCalendar.Table = function NepaliCalendarTable(props: TableProps) {
  const { children, ...restProps } = props;
  return <StyledTable {...restProps}>{children}</StyledTable>;
};

NepaliCalendar.TableHead = function NepaliCalendarTableHead(
  props: TableHeadProps
) {
  const { children, ...restProps } = props;
  return <StyledTableHead {...restProps}>{children}</StyledTableHead>;
};

NepaliCalendar.TableBody = function NepaliCalendarTableBody(
  props: TableBodyProps
) {
  const { children, ...restProps } = props;
  return <StyledTableBody {...restProps}>{children}</StyledTableBody>;
};

NepaliCalendar.TableRow = function NepaliCalendarTableRow(
  props: TableRowProps
) {
  const { children, ...restProps } = props;
  return <StyledTableRow {...restProps}>{children}</StyledTableRow>;
};

NepaliCalendar.TableCell = function NepaliCalendarTableCell(
  props: TableCellProps
) {
  const { children, ...restProps } = props;
  return <StyledTableCell {...restProps}>{children}</StyledTableCell>;
};

interface DayCellProps {
  holiday: boolean;
  current: boolean;
}

export type DayCellPropsI = DayCellProps & TableCellProps;
NepaliCalendar.DayCell = function NepaliCalendarDayCell(props: DayCellPropsI) {
  const { children, ...restProps } = props;
  return <StyledDayCell {...restProps}>{children}</StyledDayCell>;
};

NepaliCalendar.FormControl = function NepaliCalendarFormControl(
  props: FormControlProps
) {
  const { children, ...restProps } = props;
  return <StyledFormControl {...restProps}>{children}</StyledFormControl>;
};

NepaliCalendar.InputLabel = function NepaliCalendarInputLabel(
  props: InputLabelProps
) {
  const { children, ...restProps } = props;
  return <StyledInputLabel {...restProps}>{children}</StyledInputLabel>;
};

NepaliCalendar.Select = function NepaliCalendarSelect(props: SelectProps) {
  const { children, ...restProps } = props;
  return <StyledSelect {...restProps}>{children}</StyledSelect>;
};

NepaliCalendar.MenuItem = function NepaliCalendarMenuItem(
  props: MenuItemProps
) {
  const { children, button, ...restProps } = props;
  return <StyledMenuItem {...restProps}>{children}</StyledMenuItem>;
};

NepaliCalendar.DaySubTitle = function NepaliCalendarDaySubTitle(
  props: TypographyProps
) {
  const { children, ...restProps } = props;
  return <StyledDaySubTitle {...restProps}>{children}</StyledDaySubTitle>;
};

NepaliCalendar.Button = function NepaliCalendarButton(props: ButtonProps) {
  const { children, ...restProps } = props;
  return <StyledButton {...restProps}>{children}</StyledButton>;
};

NepaliCalendar.Chip = function NepaliCalendarChip(props: ChipProps) {
  const { children, ...restProps } = props;
  return <StyledChip {...restProps}>{children}</StyledChip>;
};

NepaliCalendar.List = function NepaliCalendarList(props: ListProps) {
  const { children, ...restProps } = props;
  return <StyledList {...restProps}>{children}</StyledList>;
};

NepaliCalendar.ListItem = function NepaliCalendarListItem(
  props: ListItemProps & any
) {
  const { children, ...restProps } = props;
  return <StyledListItem {...restProps}>{children}</StyledListItem>;
};

NepaliCalendar.ListItemIcon = function NepaliCalendarListItemIcon(
  props: ListItemIconProps
) {
  const { children, ...restProps } = props;
  return <StyledListItemIcon {...restProps}>{children}</StyledListItemIcon>;
};

NepaliCalendar.ListItemSecondaryAction =
  function NepaliCalendarListItemSecondaryAction(
    props: ListItemSecondaryActionProps
  ) {
    const { children, ...restProps } = props;
    return (
      <StyledListItemSecondaryAction {...restProps}>
        {children}
      </StyledListItemSecondaryAction>
    );
  };

NepaliCalendar.ListItemText = function NepaliCalendarListItemText(
  props: ListItemTextProps
) {
  const { children, ...restProps } = props;
  return <StyledListItemText {...restProps}>{children}</StyledListItemText>;
};

NepaliCalendar.ListSubheader = function NepaliCalendarListSubheader(
  props: ListSubheaderProps
) {
  const { children, ...restProps } = props;
  return <StyledListSubheader {...restProps}>{children}</StyledListSubheader>;
};

NepaliCalendar.Switch = function NepaliCalendarSwitch(props: SwitchProps) {
  const { ...restProps } = props;
  return <StyledSwitch {...restProps} />;
};
