import multipleBrandsConfig from "./multipleBrandsConfig";

function checkBrands(name: string) {
  if (multipleBrandsConfig() === name) {
    return true;
  } else {
    return false;
  }
}

export default checkBrands;
