export const GET_EXPENSES_TYPE_LOADING = "GET_EXPENSES_TYPE_LOADING";
export const GET_EXPENSES_TYPE_ERROR = "GET_EXPENSES_TYPE_ERROR";
export const GET_EXPENSES_TYPE_SUCCESS = "GET_EXPENSES_TYPE_SUCCESS";

export const ADD_EXPENSES_TYPE_LOADING = "ADD_EXPENSES_TYPE_LOADING";
export const ADD_EXPENSES_TYPE_ERROR = "ADD_EXPENSES_TYPE_ERROR";
export const ADD_EXPENSES_TYPE_SUCCESS = "ADD_EXPENSES_TYPE_SUCCESS";

export const UPDATE_EXPENSES_TYPE_LOADING = "UPDATE_EXPENSES_TYPE_LOADING";
export const UPDATE_EXPENSES_TYPE_ERROR = "UPDATE_EXPENSES_TYPE_ERROR";
export const UPDATE_EXPENSES_TYPE_SUCCESS = "UPDATE_EXPENSES_TYPE_SUCCESS";

export const DELETE_EXPENSES_TYPE_LOADING = "DELETE_EXPENSES_TYPE_LOADING";
export const DELETE_EXPENSES_TYPE_ERROR = "DELETE_EXPENSES_TYPE_ERROR";
export const DELETE_EXPENSES_TYPE_SUCCESS = "DELETE_EXPENSES_TYPE_SUCCESS";

export const GET_EXPENSES_DETAILS_LOADING = "GET_EXPENSES_DETAILS_LOADING";
export const GET_EXPENSES_DETAILS_ERROR = "GET_EXPENSES_DETAILS_ERROR";
export const GET_EXPENSES_DETAILS_SUCCESS = "GET_EXPENSES_DETAILS_SUCCESS";

export const ADD_EXPENSES_DETAILS_LOADING = "ADD_EXPENSES_DETAILS_LOADING";
export const ADD_EXPENSES_DETAILS_ERROR = "ADD_EXPENSES_DETAILS_ERROR";
export const ADD_EXPENSES_DETAILS_SUCCESS = "ADD_EXPENSES_DETAILS_SUCCESS";

export const UPDATE_EXPENSES_DETAILS_LOADING =
  "UPDATE_EXPENSES_DETAILS_LOADING";
export const UPDATE_EXPENSES_DETAILS_ERROR = "UPDATE_EXPENSES_DETAILS_ERROR";
export const UPDATE_EXPENSES_DETAILS_SUCCESS =
  "UPDATE_EXPENSES_DETAILS_SUCCESS";

export const DELETE_EXPENSES_DETAILS_LOADING =
  "DELETE_EXPENSES_DETAILS_LOADING";
export const DELETE_EXPENSES_DETAILS_ERROR = "DELETE_EXPENSES_DETAILS_ERROR";
export const DELETE_EXPENSES_DETAILS_SUCCESS =
  "DELETE_EXPENSES_DETAILS_SUCCESS";

export type ExpensesTypeI = {
  id: string;
  name: string;
  count?: number;
  description: string;
};

export type AddExpensesTypeI = {
  id: string;
  expense_type: string | number;
  count?: number;
  expense_type_name: string;
  name: string;
  description: string;
  expense_date: string;
  amount: string;
  documents: any;
  academic_session: number;
  created_by: number;
  created_on: string;
};

//===============================<START>GET INCOME<START>======================//

export interface GetExpensesTypeLoading {
  type: typeof GET_EXPENSES_TYPE_LOADING;
}

export interface GetExpensesTypeError {
  type: typeof GET_EXPENSES_TYPE_ERROR;
}

export interface GetExpensesTypeSuccess {
  type: typeof GET_EXPENSES_TYPE_SUCCESS;
  payload: ExpensesTypeI[];
}
//===============================<END>GET EXPENSES<END>======================//

//===============================<START>ADD EXPENSES<START>======================//

export interface AddExpensesTypeLoading {
  type: typeof ADD_EXPENSES_TYPE_LOADING;
}

export interface AddExpensesTypeError {
  type: typeof ADD_EXPENSES_TYPE_ERROR;
}

export interface AddExpensesTypeSuccess {
  type: typeof ADD_EXPENSES_TYPE_SUCCESS;
  payload: ExpensesTypeI;
}

//===============================<END>ADD EXPENSES<END>======================//

//===============================<START>UPDATE EXPENSES<START>======================//
export interface UpdateExpensesTypeLoading {
  type: typeof UPDATE_EXPENSES_TYPE_LOADING;
}

export interface UpdateExpensesTypeError {
  type: typeof UPDATE_EXPENSES_TYPE_ERROR;
}

export interface UpdateExpensesTypeSuccess {
  type: typeof UPDATE_EXPENSES_TYPE_SUCCESS;
  payload: ExpensesTypeI;
}

//===============================<END>UPDATE EXPENSES<END>======================//

//===============================<START>DELETE EXPENSES<START>======================//

export interface DeleteExpensesTypeLoading {
  type: typeof DELETE_EXPENSES_TYPE_LOADING;
}

export interface DeleteExpensesTypeError {
  type: typeof DELETE_EXPENSES_TYPE_ERROR;
}

export interface DeleteExpensesTypeSuccess {
  type: typeof DELETE_EXPENSES_TYPE_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE EXPENSES<END>======================//
export interface GetExpensesDetailsLoading {
  type: typeof GET_EXPENSES_DETAILS_LOADING;
}

export interface GetExpensesDetailsError {
  type: typeof GET_EXPENSES_DETAILS_ERROR;
}

export interface GetExpensesDetailsSuccess {
  type: typeof GET_EXPENSES_DETAILS_SUCCESS;
  payload: AddExpensesTypeI[];
}

//===============================<END>ADD expenses<END>======================//

export interface AddExpensesDetailsLoading {
  type: typeof ADD_EXPENSES_DETAILS_LOADING;
}

export interface AddExpensesDetailsError {
  type: typeof ADD_EXPENSES_DETAILS_ERROR;
  payload: any;
}

export interface AddExpensesDetailsSuccess {
  type: typeof ADD_EXPENSES_DETAILS_SUCCESS;
  payload: AddExpensesTypeI;
}

//===============================<END>update expenses<END>======================//

export interface UpdateExpensesDetailsLoading {
  type: typeof UPDATE_EXPENSES_DETAILS_LOADING;
}

export interface UpdateExpensesDetailsError {
  type: typeof UPDATE_EXPENSES_DETAILS_ERROR;
  payload: any;
}

export interface UpdateExpensesDetailsSuccess {
  type: typeof UPDATE_EXPENSES_DETAILS_SUCCESS;
  payload: AddExpensesTypeI;
}

//===============================<END>DELETE expenses<END>======================//
export interface DeleteExpensesDetailsLoading {
  type: typeof DELETE_EXPENSES_DETAILS_LOADING;
}

export interface DeleteExpensesDetailsError {
  type: typeof DELETE_EXPENSES_DETAILS_ERROR;
}

export interface DeleteExpensesDetailsSuccess {
  type: typeof DELETE_EXPENSES_DETAILS_SUCCESS;
  payload: { id: string };
}

export type ExpensesDispatchTypes =
  | GetExpensesTypeLoading
  | GetExpensesTypeError
  | GetExpensesTypeSuccess
  | AddExpensesTypeLoading
  | AddExpensesTypeError
  | AddExpensesTypeSuccess
  | UpdateExpensesTypeLoading
  | UpdateExpensesTypeError
  | UpdateExpensesTypeSuccess
  | DeleteExpensesTypeLoading
  | DeleteExpensesTypeError
  | DeleteExpensesTypeSuccess
  | GetExpensesDetailsLoading
  | GetExpensesDetailsError
  | GetExpensesDetailsSuccess
  | AddExpensesDetailsLoading
  | AddExpensesDetailsError
  | AddExpensesDetailsSuccess
  | UpdateExpensesDetailsLoading
  | UpdateExpensesDetailsError
  | UpdateExpensesDetailsSuccess
  | DeleteExpensesDetailsLoading
  | DeleteExpensesDetailsError
  | DeleteExpensesDetailsSuccess;
