// ---------------------------- <START> module imports start ----------------------------------//
import React, { useState, useEffect } from "react";
import {
  Avatar,
  CardHeader,
  Grid,
  Paper,
  TableBody,
  TableHead,
  Typography,
} from "@material-ui/core";
import { useTableStyles } from "../../Styles/TableStyles";
import { useTable } from "../../Reusable";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import { FineTypeI } from "../../../actions/Fees/FineType/FineActionTypes";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import { DeleteFine, GetFine } from "../../../actions/Fees/FineType/FineAction";
import {
  ItemDeleteButton,
  ItemEditButton,
} from "../../Reusable/Buttons/TableButton";
import getMonthName from "../../utils/getMonthName";
import { AnyAction } from "redux";
import { useParams } from "react-router";
import { GetInvoiceHistory } from "../../../actions/Fees/CollectFees/CollectFeesAction";
import SubModuleLayout from "../../Reusable/Layouts/SubModuleLayout";
import FinanceSidebar from "../FinanceSidebar";
import { GetStudentByID } from "../../../actions/Student/Student/StudentAction";
import defaultBase64 from "../../Student/ParentForm/defaultBase64";
import moment from "moment";
// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//
interface PropsI {
  onEditData: (value: FineTypeI) => void;
  editCheck: { check: boolean; count: number };
}

interface HeadCellsI {
  id: string;
  label: string;
}
// ---------------------------- <END> interface ends ----------------------------------//

// ---------------------------- <START> styling starts ----------------------------------//

// ---------------------------- <END> styling ends ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N." },
  { id: "date", label: "Date" },
  { id: "transaction_type", label: "Transaction Type" },
  { id: "fee_head", label: "Fees Head" },
  { id: "period", label: "Period" },
  { id: "charges", label: "Charges" },
  { id: "fine", label: "Fine" },
  { id: "balance", label: "Balance" },
  { id: "status", label: "Status" },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> fine Type Table Component Starts ---------------------------//
const FeeStatementDetail = (props: any) => {
  // const { statementTableData, feesHead } = props;

  const classes = useTableStyles();
  // payment method reducer
  const fineTypeState = useSelector((state: RootStore) => state.fine_type);
  const { studentID } = useParams<{ studentID: string }>();
  const { invoiceID } = useParams<{ invoiceID: string }>();

  // Modal States
  const [loading, setLoading] = useState<boolean>(false);
  const [editMode, setEditMode] = useState(false);
  const [tableData, setTableData] = useState<FineTypeI[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string>("");
  const [totalPaid, setTotalPaid] = useState<number>(0);
  const [totalBalance, setTotalBalance] = useState<number>(0);
  const [statementTableData, setStatementTableData] = useState<any>([]);

  const dispatch = useDispatch();

  const feesHead = useSelector(
    (state: RootStore) => state.collect_fees?.collect_fees
  );
  const studentState = useSelector(
    (state: RootStore) => state.student?.student_by_id
  );

  const nav = <FinanceSidebar fee_statement />;

  console.log({ studentState });

  useEffect(() => {
    if (invoiceID && studentID != null) {
      dispatch(GetInvoiceHistory(invoiceID));
      dispatch(GetStudentByID(studentID));
    }
  }, [studentID, invoiceID]);

  useEffect(() => {
    const secondaryData: any[] = [];
    const dueData: any[] = [];

    if (feesHead?.paid_invoices !== null) {
      for (let i = 0; i < feesHead?.paid_invoices.length; i++) {
        secondaryData.push({
          date: "2022-01-20",
          transaction_type: "Invoice",
          fee_head:
            feesHead?.paid_invoices[i].invoice__month &&
            feesHead?.paid_invoices[i].invoice__month !== ""
              ? feesHead?.paid_invoices[i].invoice__fee_type__name +
                " - " +
                getMonthName(feesHead?.paid_invoices[i].invoice__month)
              : feesHead?.paid_invoices[i].invoice__fee_type__name,
          period: feesHead?.paid_invoices[i].invoice__month
            ? getMonthName(feesHead?.paid_invoices[i].invoice__month)
            : null,
          charges: feesHead?.paid_invoices[i].paid_amount,
          fine: null,
          balance: feesHead?.paid_invoices[i].paid_amount,
          status: "Paid",
        });
      }
      for (let i = 0; i < feesHead?.generated_invoices.length; i++) {
        for (
          let j = 0;
          j < feesHead?.generated_invoices[i].invoices.length;
          j++
        ) {
          dueData.push({
            date: moment(
              feesHead?.generated_invoices[i].invoices[j].created_on
            ).format("YYYY-MM-DD"),
            transaction_type:
              feesHead?.generated_invoices[i].invoices[j].invoice_id,
            fee_head:
              feesHead?.generated_invoices[i].invoices[j].month !== ""
                ? feesHead?.generated_invoices[i].fee_name +
                  " - " +
                  getMonthName(
                    feesHead?.generated_invoices[i].invoices[j].month
                  )
                : feesHead?.generated_invoices[i].fee_name,
            period:
              feesHead?.generated_invoices[i].invoices[j].month !== ""
                ? getMonthName(
                    feesHead?.generated_invoices[i].invoices[j].month
                  )
                : feesHead?.generated_invoices[i].invoices[j]
                    .fee_type__due_date,
            charges: feesHead?.generated_invoices[i].invoices[j].amount,
            discount_amount:
              feesHead?.generated_invoices[i].invoices[j].discount_amount,
            fine: "Rs. 0",
            balance: feesHead?.generated_invoices[i].invoices[j].amount,
            status: "Unpaid",
          });
        }
      }

      if (feesHead?.dues !== null) {
        const data: any[] = [];
        for (let i = 0; i < feesHead?.dues.length; i++) {
          dueData.push({
            date: moment(feesHead?.dues[i]?.created_on).format("YYYY-MM-DD"),
            transaction_type: feesHead?.dues[i]?.paid_invoice?.receipt,
            fee_head:
              feesHead?.dues[i]?.paid_invoice?.month !== null
                ? feesHead?.dues[i]?.paid_invoice?.invoice?.fee_type?.name +
                  " - " +
                  feesHead?.dues[i]?.paid_invoice?.month +
                  " (due)"
                : feesHead?.dues[i]?.paid_invoice?.invoice?.fee_type?.name +
                  " (due)",
            period: getMonthName(
              feesHead?.dues[i]?.paid_invoice?.invoice?.month
            ),
            // charges: Number(feesHead?.dues[i]?.paid_invoice?.paid_amount) + Number(feesHead?.dues[i]?.due_amount),
            charges: Number(feesHead?.dues[i]?.due_amount),
            fine: "Rs. 0",
            balance: Number(feesHead?.dues[i]?.due_amount),
            status: "Unpaid",
          });
        }
      }
      setStatementTableData(dueData.concat(secondaryData));
    }
  }, [feesHead]);

  useEffect(() => {
    if (statementTableData !== null) {
      const paids = statementTableData?.filter(
        (elem: any) => elem.status === "Paid"
      );
      let count = 0;
      paids?.map((elem: any) => {
        count += Number(elem.balance);
      });
      const any_discount = feesHead?.total_discount || 0;
      setTotalPaid(count - any_discount);

      const unpaids = statementTableData?.filter(
        (elem: any) => elem.status === "Unpaid"
      );
      let counts = 0;
      unpaids?.map((elem: any) => {
        counts += Number(elem.balance);
      });
      setTotalBalance(counts);
    }
  }, [statementTableData, feesHead]);

  useEffect(() => {
    const dataLength = fineTypeState.fine.length;

    setLoading(fineTypeState.loading);
    // setTableData(fineTypeState.fine);
  }, [fineTypeState]);

  // States declaration

  // -----------------<END> Pagination states and functions <END>-----------------------//

  // Retrieving re-usable components from useTable
  const {
    TblContainer,
    TblHead,
    StyledTableCell,
    StyledTableRow,
    exportToExcel,
    tablePrint,
  } = useTable(headCells);

  // Function for handling action icons click event

  // const handleEditClicked = (data: FineTypeI) => {
  //     onEditData(data);
  //     setEditMode(true);
  // };
  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const handleDeleteModal = (value: boolean, id: string) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteFine(itemId));
    setDeleteModalOpen(false);
  };

  console.log({ studentState });

  const getCustomTableRow = (item: any, index: number) => {
    return (
      <>
        <StyledTableRow key={item.id}>
          <StyledTableCell align="center" className={classes.cell}>
            {index}
          </StyledTableCell>
          <StyledTableCell align="center" className={classes.cell}>
            {item.date}
          </StyledTableCell>
          <StyledTableCell align="center" className={classes.cell}>
            {item.transaction_type}
          </StyledTableCell>
          <StyledTableCell align="center" className={classes.cell}>
            {item.fee_head}
          </StyledTableCell>
          <StyledTableCell align="center" className={classes.cell}>
            {item.period}
          </StyledTableCell>
          <StyledTableCell align="center" className={classes.cell}>
            {item.charges}
          </StyledTableCell>
          <StyledTableCell align="center" className={classes.cell}>
            {item.fine}
          </StyledTableCell>
          <StyledTableCell align="center" className={classes.cell}>
            {item.balance}
          </StyledTableCell>
          <StyledTableCell align="center" className={classes.cell}>
            <b style={{ color: item.status === "Paid" ? "green" : "red" }}>
              {item.status}
            </b>
          </StyledTableCell>
        </StyledTableRow>
      </>
    );
  };
  return (
    <SubModuleLayout sideNav={nav}>
      <Paper style={{ margin: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography
              style={{ paddingLeft: "15px", paddingTop: "20px" }}
              variant="h4"
            >
              <b>Statement Details</b>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <div style={{ paddingLeft: "70px" }}>
              {studentState?.student_user?.first_name &&
                studentState?.student_user?.last_name && (
                  <CardHeader
                    avatar={
                      <Avatar aria-label="recipe">
                        <img
                          src={studentState?.student_photo || defaultBase64}
                        />
                      </Avatar>
                    }
                    title={
                      <span style={{ color: "#1f4498" }}>
                        {studentState?.student_user?.first_name +
                          " " +
                          studentState?.student_user?.last_name}
                      </span>
                    }
                    subheader={
                      <>
                        <span style={{ color: "#7c7c7c" }}>Admn No: </span>
                        <span>{studentState?.admission_number}</span>
                      </>
                    }
                  />
                )}
            </div>
          </Grid>
        </Grid>
        <div style={{ padding: "0px 15px" }}>
          <Grid
            container
            justifyContent="flex-start"
            style={{ marginTop: "20px" }}
          >
            <b style={{ paddingRight: "10px" }}>Opening Balance:</b>
            Debit: Rs. {feesHead?.opening_balance?.debit || 0}
            <br />
            Credit: Rs. {feesHead?.opening_balance?.credit || 0}
          </Grid>
          <div
            style={{
              paddingBottom: "40px",
              maxHeight: "calc(100vh - 40vh)",
              overflowY: "auto",
            }}
          >
            <TblContainer size="small">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center" className={classes.cell}>
                    S.N.
                  </StyledTableCell>
                  <StyledTableCell align="left" className={classes.cell}>
                    Date
                  </StyledTableCell>
                  <StyledTableCell align="left" className={classes.cell}>
                    Transaction Type
                  </StyledTableCell>
                  <StyledTableCell align="left" className={classes.cell}>
                    Fee Head
                  </StyledTableCell>
                  <StyledTableCell align="left" className={classes.cell}>
                    Period
                  </StyledTableCell>
                  <StyledTableCell align="left" className={classes.cell}>
                    Charges
                  </StyledTableCell>
                  <StyledTableCell align="left" className={classes.cell}>
                    Fine
                  </StyledTableCell>
                  <StyledTableCell align="left" className={classes.cell}>
                    Balance
                  </StyledTableCell>
                  <StyledTableCell align="left" className={classes.cell}>
                    Status
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {statementTableData.map((item: any, index: number) => (
                  <StyledTableRow key={item.id}>
                    <StyledTableCell align="center" className={classes.cell}>
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell align="left" className={classes.cell}>
                      {item.date}
                    </StyledTableCell>
                    <StyledTableCell align="left" className={classes.cell}>
                      {item.transaction_type}
                    </StyledTableCell>
                    <StyledTableCell align="left" className={classes.cell}>
                      {item.fee_head}
                    </StyledTableCell>
                    <StyledTableCell align="left" className={classes.cell}>
                      {item.period}
                    </StyledTableCell>
                    <StyledTableCell align="left" className={classes.cell}>
                      {item.charges}
                    </StyledTableCell>
                    <StyledTableCell align="left" className={classes.cell}>
                      {item.fine}
                    </StyledTableCell>
                    <StyledTableCell align="left" className={classes.cell}>
                      {item.balance}
                    </StyledTableCell>
                    <StyledTableCell align="left" className={classes.cell}>
                      <b
                        style={{
                          color: item.status === "Paid" ? "green" : "red",
                        }}
                      >
                        {item.status}
                      </b>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
                <StyledTableRow>
                  <StyledTableCell
                    align="left"
                    className={classes.cell}
                    colSpan={7}
                  >
                    Total Paid Amount
                  </StyledTableCell>
                  <StyledTableCell align="left" className={classes.cell}>
                    {totalPaid} /-
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    className={classes.cell}
                  ></StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell
                    align="left"
                    className={classes.cell}
                    colSpan={7}
                  >
                    Discount
                  </StyledTableCell>
                  <StyledTableCell align="left" className={classes.cell}>
                    {feesHead?.total_discount || "0"}
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    className={classes.cell}
                  ></StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell
                    align="left"
                    className={classes.cell}
                    colSpan={7}
                  >
                    Balance
                  </StyledTableCell>
                  <StyledTableCell align="left" className={classes.cell}>
                    {totalBalance} /-
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    className={classes.cell}
                  ></StyledTableCell>
                </StyledTableRow>
                {/* <StyledTableRow>
                                    <StyledTableCell align="left" className={classes.cell} colSpan={7}>
                                        <b style={{ color: "#1f4498" }}>Narration Fee Collection for Ananta Chaudhary</b>
                                    </StyledTableCell>
                                    <StyledTableCell align="left" className={classes.cell}>
                                    </StyledTableCell>
                                    <StyledTableCell align="left" className={classes.cell}>
                                    </StyledTableCell>
                                </StyledTableRow> */}
              </TableBody>
            </TblContainer>
          </div>
        </div>
      </Paper>
    </SubModuleLayout>
  );
};
// ---------------------------- <END> fine Type Component Ends -------------------------------//

export default FeeStatementDetail;
