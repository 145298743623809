import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { SubjectGroupTypeI } from "../../actions/Academics/Subject/SubjectGroupActionTypes";
import {
  SubjectGroupForm,
  SubjectGroupTable,
  AcademicSidebar,
} from "../../components";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const SubjectGroupPage = () => {
  const [formData, setFormData] = useState<SubjectGroupTypeI | null>(null);
  const [count, setCount] = useState<number | undefined>(0);
  const [edit, setEdit] = useState<any>();

  const nav = <AcademicSidebar subjectsGroup />;

  const fetchEditData = (data: SubjectGroupTypeI) => {
    setFormData({ ...data, count: count });
    count && setCount(count + 1);
  };

  const onEditMode = (value: boolean) => {
    setEdit({ value, count });
  };

  return (
    <SubModuleLayout academicMenuActive sideNav={nav}>
      <Grid container>
        <Grid item xs={3}>
          <SubjectGroupForm onEditMode={onEditMode} editData={formData} />
        </Grid>
        <Grid item xs={9}>
          <SubjectGroupTable edit={edit} onEditData={fetchEditData} />
        </Grid>
      </Grid>
    </SubModuleLayout>
  );
};
export default SubjectGroupPage;
