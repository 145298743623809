import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Divider, Grid, Paper } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { Bar } from "react-chartjs-2";
import ExploreCard from "../../components/ExploreCard";
import { RootStore } from "../../../../../store";
import getFullName from "../../../../utils/getFullName";
import { GetStudentSiblings } from "../../../../../actions/Student/Student/StudentAction";
import { GetStudentLeaveHistory } from "../../../../../actions/Student/StudentProfile/StudentProfileAction";

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Chart.js Bar Chart",
    },
  },
};

const labels = ["1st", "2nd", "3rd", "4th"];

const activiesData = {
  labels,
  datasets: [
    {
      label: "No. of Activities",
      data: [10, 30, 20, 0],
      backgroundColor: "rgba(132, 99, 255, 0.5)",
    },
  ],
};

const Explore = () => {
  const dispatch = useDispatch();
  const { studentID } = useParams<{ studentID: string }>();
  const [guardianCount, setGuardianCount] = useState<number>();
  const [showParentDetail, setShowParentDetail] = useState<boolean>(false)
  const [showSiblingDetail, setShowSiblingDetail] = useState<boolean>(false)
  const [leaves, setLeaves] = useState<any>({
    1: [],
    2: [],
    3: [],
    4: [],
  });

  const data = {
    labels,
    datasets: [
      {
        label: "No. of Leaves",
        data: [
          leaves[1].length,
          leaves[2].length,
          leaves[3].length,
          leaves[4].length,
        ],
        backgroundColor: "rgba(255, 99, 160, 0.5)",
      },
    ],
  };

  const { loading, student } = useSelector(
    (state: RootStore) => state.student_profile
  );
  const siblingState = useSelector(
    (state: RootStore) => state.student.student_siblings
  );
  console.log(siblingState)
  const { student_leave_history } = useSelector(
    (state: RootStore) => state.student_profile
  );

  useEffect(() => {
    student && student?.guardian_detail?.secondary_guardian
      ? setGuardianCount(2)
      : setGuardianCount(1);
    const academicId = student?.academic_info?.[0]?.id;
    academicId && dispatch(GetStudentLeaveHistory(academicId));
  }, [student]);

  useEffect(() => {
    studentID != undefined && dispatch(GetStudentSiblings(studentID));
    student_leave_history && formatData(student_leave_history?.leaves);
  }, []);

  const formatData = (data: any) => {
    const temp = leaves;
    data?.map((leave: any) => {
      const leaveDate = new Date(leave.leave_from);
      const now = new Date();
      if (
        now.getFullYear() === leaveDate.getFullYear() &&
        now.getMonth() === leaveDate.getMonth()
      ) {
        const day = leaveDate.getDate();
        const index = getIndex(day);
        if (temp[index].length === 0) {
          temp[index].push(leave);
        } else {
          for (let i = 0; i < temp[index].length; i++) {
            if (temp[index][i].id === leave.id) return;
            temp[index].push(leave);
          }
        }
      }
    });
    setLeaves(temp);
  };
  const getIndex = (index: number) => {
    if (index >= 1 && index <= 7) return 1;
    if (index >= 8 && index <= 14) return 2;
    if (index >= 15 && index <= 21) return 3;
    return 4;
  };
  const handleParentButtonClicked = () => {
    setShowParentDetail(!showParentDetail)
  }
  const handleSiblingButtonClicked = () => {
    setShowSiblingDetail(!showSiblingDetail)
  }

  return (
    <Paper elevation={1} style={{ padding: "16px", marginBottom: 16 }}>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid
          container
          spacing={2}
          item
          xs={6}
          style={{ boxSizing: "content-box" }}
        >
          <Grid item xs={12}>
            <ExploreCard variant="outlined">
              <ExploreCard.Content>
                <ExploreCard.Header>
                  <ExploreCard.Title color="textSecondary">
                    <span>{guardianCount}</span>{" "}
                    {guardianCount === 1 ? "Guardian" : "Guardians"}
                  </ExploreCard.Title>

                  <ExploreCard.Button onClick={handleParentButtonClicked}>
                    {showParentDetail ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ExploreCard.Button>
                </ExploreCard.Header>

                {showParentDetail &&
                  <>
                    <ExploreCard.Text gutterBottom>
                      Name: {getFullName(
                        student?.guardian_detail?.guardian_user?.first_name,
                        student?.guardian_detail?.guardian_user?.last_name
                      )}
                    </ExploreCard.Text>
                    <ExploreCard.Text gutterBottom>
                      Address: {student?.guardian_detail?.address || "N/A"}
                    </ExploreCard.Text>
                    <ExploreCard.Text gutterBottom>
                      Phone Number: {student?.guardian_detail?.phone_number || "N/A"}
                    </ExploreCard.Text>
                  </>}
                <Divider light />

                <ExploreCard.Header>
                  <ExploreCard.Title color="textSecondary">
                    <span>{siblingState.length}</span>{" "}
                    {siblingState.length > 1 ? "Siblings" : "Sibling"}
                  </ExploreCard.Title>

                  <ExploreCard.Button onClick={handleSiblingButtonClicked}>
                    {showSiblingDetail ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ExploreCard.Button>
                </ExploreCard.Header>
                {siblingState.length > 0 && showSiblingDetail &&
                  siblingState?.map((sibling: any) => (
                    <>
                      <ExploreCard.Text>
                        Name: {sibling.full_name}
                      </ExploreCard.Text>
                      <ExploreCard.Text>
                        Grade: {sibling.academic_info?.[0].grade || "N/A"}
                      </ExploreCard.Text>
                      <ExploreCard.Text>
                        Roll Number: {sibling.academic_info?.[0].roll_number || "N/A"}
                      </ExploreCard.Text>
                      <Divider color="black" style={{ margin: "10px 0" }} />
                    </>
                  ))}
              </ExploreCard.Content>
            </ExploreCard>
          </Grid>

          <Grid item xs={12}>
            <ExploreCard variant="outlined">
              <ExploreCard.Content>
                <ExploreCard.Title>Weekly Activies</ExploreCard.Title>

                <Bar options={options} data={activiesData} />
              </ExploreCard.Content>
            </ExploreCard>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          item
          xs={6}
          style={{ boxSizing: "content-box" }}
        >
          <Grid item xs={12}>
            <ExploreCard variant="outlined">
              <ExploreCard.Content>
                <ExploreCard.Title>Weekly Leave History</ExploreCard.Title>

                <Bar options={options} data={data} />
              </ExploreCard.Content>
            </ExploreCard>
          </Grid>

          <Grid item xs={12}>
            <ExploreCard variant="outlined">
              <ExploreCard.Content>
                <ExploreCard.Title style={{ marginBottom: "8px" }}>
                  Finance Information (50)
                </ExploreCard.Title>

                <ExploreCard.Header>
                  <ExploreCard.Title color="textSecondary">
                    30 Paid
                  </ExploreCard.Title>

                  <ExploreCard.ProgressBar progressColor="blue" />
                </ExploreCard.Header>

                <ExploreCard.Header>
                  <ExploreCard.Title color="textSecondary">
                    20 Due
                  </ExploreCard.Title>
                  <ExploreCard.ProgressBar />
                </ExploreCard.Header>

                <Divider light style={{ marginTop: 8, marginBottom: 8 }} />

                <ExploreCard.ListText>
                  Fees Applied: Rs. 50,000
                </ExploreCard.ListText>
                <ExploreCard.ListText>
                  Paid Amount: Rs. 34,000
                </ExploreCard.ListText>
                <ExploreCard.ListText>
                  Due Amount: Rs. 16,000
                </ExploreCard.ListText>
              </ExploreCard.Content>
            </ExploreCard>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Explore;
