import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@material-ui/core";
import Sunday from "./Sunday";

interface PropsI {
  modalState: boolean;
  handleClose: (val: false) => void;
  currentDay: number;
  handleApply: (val: DaysI[]) => void;
}

export interface DaysI {
  id: number;
  day: string;
  checked: boolean;
}

const days: DaysI[] = [
  { id: 7, day: "Sunday", checked: false },
  { id: 1, day: "Monday", checked: false },
  { id: 2, day: "Tuesday", checked: false },
  { id: 3, day: "Wednesday", checked: false },
  { id: 4, day: "Thursday", checked: false },
  { id: 5, day: "Friday", checked: false },
];

const SelectDaysDialog = (props: PropsI) => {
  const { modalState, handleClose, currentDay, handleApply } = props;

  const closeDialog = () => {
    handleClose(false);
  };

  const [applyDays, setApplyDays] = React.useState<DaysI[]>(
    days.filter((element: DaysI) => element.id != currentDay)
  );

  const checkDays = (id: number) => {
    setApplyDays(
      applyDays.map((element) =>
        element.id === id ? { ...element, checked: !element.checked } : element
      )
    );
  };

  const onApplyClick = () => {
    const apppliedDays: DaysI[] = applyDays.filter(
      (element) => element.checked
    );
    resetBoxes();
    handleApply(apppliedDays);
  };

  const resetBoxes = () => {
    setApplyDays(applyDays.map((element) => ({ ...element, checked: false })));
  };

  return (
    <>
      <Dialog
        open={modalState}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Select Days to Apply to"}
        </DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <FormGroup>
              {applyDays.map((element: DaysI, index: number) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={element.checked}
                      onChange={() => checkDays(element.id)}
                      name={element.day}
                    />
                  }
                  label={element.day}
                />
              ))}
            </FormGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={onApplyClick} color="primary" autoFocus>
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SelectDaysDialog;
