import {
  Button,
  Grid,
  Input,
  InputBase,
  TableBody,
  TableContainer,
  TextField,
} from '@material-ui/core';
import { Add, CloudUpload } from '@material-ui/icons';
import { useStaticState } from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AddDocument } from '../../../../../actions/Student/StudentDocument/StudentDocumentAction';
import { DocumentTypeI } from '../../../../../actions/Student/StudentDocument/StudentDocumentActionTypes';
import { useTable } from '../../../../Reusable';
import { useStyles } from '../../../../Styles/FormStyles';
import { useTableStyles } from '../../../../Styles/TableStyles';

interface PropsI {
  studentID: number;
  item: number;
}

function UploadDocument(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [document1Name, setDocument1Name] = useState<any | null>(null);
  const [title, setTitle] = useState<string | null>('');
  const [docName, setDocName] = useState<string | null>('');
  const [file, setFile] = useState<any | null>(null);

  const { register, handleSubmit, setValue, reset, errors } =
    useForm<DocumentTypeI>({
      mode: 'onChange',
    });

  const handleDocumetUpload = (value: any) => {
    const fileTypes = ['jpg', 'png', 'jpeg', 'pdf', 'docx', 'doc'];
    const fileType = value[0]?.name.split('.')[1];
    setDocument1Name(value);
    setDocName(value[0].name);
    setFile(value[0]);

    // if (!fileTypes.includes(fileType)) {
    //   setDocument1Name("");
    //   return `please upload a valid file format. (${fileTypes})`;
    // } else if (value[0].size > 2000000000000) {
    //   setDocument1Name("");
    //   return "Photo size must be lower than 200MB";
    // } else {
    //   // documents[index].document = value[0];
    // }
  };

  const handleUploadFile = (elem: any) => {
    const data: any = {
      title: elem.title,
      student: props.studentID,
    };
    const finalValue = new FormData();
    finalValue.append('document', file);
    Object.keys(data).map((key) => finalValue.append(key, data[key]));

    dispatch(AddDocument(finalValue));
    reset();
    setFile(null);
  };

  return (
    <Grid container className={classes.formWrapper}>
      <form onSubmit={handleSubmit(handleUploadFile)}>
        <Grid container style={{ display: 'flex' }}>
          <Grid item xs={5}>
            <InputBase
              className={classes.input}
              id={`id${0}`}
              type="file"
              style={{ opacity: 0, position: 'absolute' }}
              name="document"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleDocumetUpload(event.target.files);
              }}
              inputRef={register}
            />
            <label htmlFor={`id${0}`}>
              <Button
                className={classes.browseButton}
                color="primary"
                component="span"
                style={{ width: '200px' }}
              >
                {file != null ? docName : 'Browse'}
              </Button>
            </label>
          </Grid>
          <Grid item xs={7}>
            <TextField
              variant="outlined"
              placeholder="Document Name"
              autoFocus={true}
              name="title"
              fullWidth
              style={{ marginTop: '7px' }}
              onChange={(event) => {
                setTitle(event.target.value);
              }}
              inputRef={register({ required: true })}
            ></TextField>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="flex-end"
          className={classes.searchBoxButton}
        >
          <Button
            startIcon={<CloudUpload />}
            color="primary"
            variant="outlined"
            type="submit"
          >
            Upload
          </Button>
        </Grid>
      </form>
    </Grid>
  );
}

export default UploadDocument;
