import React, { useEffect, useState } from "react";
import {
  Button,
  createStyles,
  makeStyles,
  Paper,
  Theme,
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import { CommunicationSidebar } from "../../components";
import NewMessage from "../../components/Communication/SMS/NewMessage";
import CommunicationTabs from "../../components/Communication/SMS/SmsTabs/CommunicationTabs";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";
import { TabDataI } from "../../components/Communication/SMS/SmsTabs/CommunicationTabs";
import { useSelector } from "react-redux";
import { RootStore } from "../../store";
import BackDropLoader from "../../components/Reusable/BackDropLoader";

export default function CommunicationPage() {
  const nav = <CommunicationSidebar sms />;

  return (
    <SubModuleLayout communicationMenuActive sideNav={nav}>
      <NewMessage />
    </SubModuleLayout>
  );
}
