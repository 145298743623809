// Import modules, actions, utilities, reusable components, constant variables
import {
  Button,
  Divider,
  FormControlLabel,
  Grid,
  InputLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Input,
  TableBody,
  IconButton,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import React, { useState, useEffect } from 'react';
import { Autocomplete, Alert } from '@material-ui/lab';
import { useForm } from 'react-hook-form';
import { useStyles } from '../../Styles/FormStyles';
import { useTableStyles } from '../../Styles/TableStyles';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ParentDetailForm from '../ParentForm/ParentDetailsForm';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import { ImageUpload } from '../../Reusable';
import { useTable } from '../../Reusable';
import AddNewButton from '../../Reusable/Buttons/AddNewButton';
import AddIcon from '@material-ui/icons/Add';
import Popups from '../../Reusable/Dialogs/Popups';
import formsValidationCheckup from '../../utils/formsValidationCheckUp';
import firstWordCapital from '../../utils/firstWordCapital';
import {
  SELECT_BLOOD_GROUP,
  SELECT_GENDER,
  SELECT_RELIGION,
  SPORT_CHOICES,
} from '../../../common/json.constant';
import { RootStore } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import {
  GetGuardianAll,
  GetGuardianByName,
} from '../../../actions/Student/Guardian/GuardianAction';
import {
  GetStudentListWithPagination,
  GetStudentByID,
  UpdateStudent,
} from '../../../actions/Student/Student/StudentAction';
import { GetStudentProfile } from '../../../actions/Student/StudentProfile/StudentProfileAction';
import { GetClasses } from '../../../actions/Academics/Class/ClassAction';
import { GetSections } from '../../../actions/Academics/Section/SectionAction';
import { GetStudentHouses } from '../../../actions/Student/StudentHouse/StudentHouseAction';
import { GetTransport } from '../../../actions/Transport/Routes/TransportAction';
import { StudentAdmission } from '../../../actions/Student/Student/StudentAction';
import { GetCategory } from '../../../actions/Student/StudentCategory/CategoryAction';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import DocumentsUploader from '../../Reusable/DocumentsUploader';
import { truncateSync } from 'fs';
import CustomizedNepaliDatePicker from '../../Reusable/Inputs/NepaliDatePicker';
import {
  dateConverterAdToBs,
  dateConverterBsToAd,
} from '../../utils/dateConverter';
import debounce from 'lodash/debounce';
import CustomBackdrop from '../../Reusable/Inputs/CustomBackdrop';
import { UpdateStudentProfile } from '../../../actions/StudentDash/StudentProfile/StudentProfileAction';
import getFullName from '../../utils/getFullName';
import { UpdateProfilePicture } from "../../../actions/Profile Picture/ProfilePictureAction"

// Let's create some typescript interface
interface StudentFormI {
  parent: string | number | null;
  admission_no: string;
  first_name: string;
  last_name: string;
  address: string;
  gender: string;
  phone: number;
  email?: string;
  religion?: string;
  blood_group?: string;
  class: string;
  section?: string;
  roll_no?: number;
  last_grade?: string;
  previous_school_name?: string;
  previous_score?: number;
  house?: string;
  sports?: string;
  route?: string;
}

interface FormI {
  parent_id: number | null | undefined;
  first_name: string;
  last_name: string;
  address: string;
  gender_id: number | null | undefined;
  phone: number;
  email?: string;
  religion?: string;
  blood_group?: number | null | undefined;
  class: number | null | undefined;
  section?: number | null | undefined;
  roll_no?: number;
  last_grade?: string;
  previous_school_name?: string;
  previous_score?: number;
  house?: number | null | undefined;
  sports?: number | null | undefined;
  route?: number | null | undefined;
}

interface Tupple {
  key: string;
  value: string;
}

interface GenderI {
  title: string;
  value: number;
}

interface ParentI {
  id: number;
  first_name: string;
  last_name: string;
}

interface BloodGroupI {
  title: string;
  value: number;
}

interface SectionI {
  id: number;
  title: string;
}

interface GradeI {
  id: number;
  title: string;
  sections: SectionI[] | [];
}

interface HouseI {
  id: number;
  title: string;
}

interface SportsI {
  id: number;
  title: string;
}

interface RouteI {
  id: number;
  title: string;
}

interface ImageI {
  student_picture?: File;
}

interface ProfilePic {
  picturePreview: string;
  pictureAsFile: any;
}


// Document Table Head Data
const headCells: any[] = [
  { id: 'sn', label: 'S.N' },
  { id: 'select_document', label: 'Select' },
  { id: 'document_name', label: 'Document Name' },
  { id: 'reset_button', label: 'Reset' },
  { id: 'preview', label: 'Preview' },
  { id: 'remove_icon', label: 'Remove' },
];

const StudentAdmissionForm = (props: any) => {
  // Custom Styles
  const classes = useStyles();
  const table_classes = useTableStyles();
  const history = useHistory();

  // Destructuring react-form-hook methods
  const { register, handleSubmit, errors, setValue, setError, clearErrors } =
    useForm<any>({
      mode: 'onChange',
    });
  // Destructuring reusable table component
  const { TblContainer, TblHead, StyledTableCell, StyledTableRow } =
    useTable(headCells);

  // Create Dispatchable Variable
  const dispatch = useDispatch();

  // Creating some states
  const [gender, setGender] = useState<Tupple | null>(null);
  const [studentCategory, setStudentCategory] = useState<any | null>(null);
  const [studentCategoryChoices, setStudentCategoryChoices] = useState<
    any[] | []
  >([]);
  const [religion, setReligion] = useState<Tupple | null | undefined>(null);
  const [parent, setParent] = useState<any | null>(null);
  const [parentChoices, setParentChoices] = useState<any>([]);
  const [grade, setGrade] = useState<any | null>(null);
  const [gradeChoices, setGradeChoices] = useState<any>([]);
  const [house, setHouse] = useState<any | null>(null);
  const [houseChoices, setHouseChoices] = useState<any[] | []>([]);
  const [dob, setDob] = useState<string | undefined>('');
  const [bloodGroup, setBloodGroup] = useState<Tupple | null>(null);
  const [previousGrade, setPreviousGrade] = useState<any | null>(null);
  const [section, setSection] = useState<any | null>(null);
  const [sectionChoices, setSectionChoices] = useState<any[] | []>([]);
  const [route, setRoute] = useState<any | null>(null);
  const [routesChoices, setRouteChoices] = useState<any[] | []>([]);
  const [sports, setSports] = useState<Tupple | null | undefined>(null);
  const [sectionDisabler, setSectionDisabler] = useState<boolean>(true);
  const [selectedRadioValue, setSelectedRadioValue] =
    useState<string>('already_added');
  const { studentID } = useParams<{ studentID: string }>();
  const [updated, setUpdated] = useState<boolean>(false);

  const [image, setImage] = useState<ProfilePic>({ picturePreview: "", pictureAsFile: null });
  const [documents, setDocuments] = useState<null | any>([]);
  const [viewID, SetViewID] = useState<any | null>(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [today] = useState<any>(new Date());
  const [parentSelectOpen, setParentSelectOpen] = useState<boolean>(false);
  // const [isAdmin, setIsAdmin] = useState<boolean>(false);
  // const [isStudent, setIsStudent] = useState<boolean>(false);
  const isAdmin = localStorage.getItem('role') === 'Administrator';

  const guardianList = useSelector(
    (state: RootStore) => state.guardians.guardians
  );
  let selectedStudent: any;

  useEffect(() => {
    dispatch(GetStudentHouses());
    dispatch(GetCategory());
    let temp = moment(today).format('YYYY-MM-DD');
    temp = dateConverterAdToBs(temp);
    setDob(temp);
  }, []);

  useEffect(() => {
    if (isAdmin) {
      dispatch(GetTransport());
      dispatch(GetGuardianAll(1));
      dispatch(GetSections());
      dispatch(GetClasses());
      studentID && dispatch(GetStudentProfile(studentID));
      // setIsAdmin(true);
    } else {
      selectedStudent = props.student;
    }
  }, []);

  // useEffect(() => {
  //   if (localStorage.getItem("role") === "Student") {
  //     setIsStudent(true);
  //     selectedStudent = props.student;
  //   } else {
  //     setIsAdmin(true);
  //   }
  // }, [])

  //---------- Creating functions to use within this component

  // Removing empty document while submitting form
  const prepareDocumentFiles = (arr: any) => {
    if (arr.length) {
      const refined = arr.filter(
        (element: any) => !Object.keys(element).find((key) => key === 'id')
      );
      return refined;
    }
  };

  // Selecting and extracting required value of particular states from rooReducers

  const parentLoading = parentSelectOpen && guardianList.loading === true;
  const guardianState = useSelector((state: RootStore) => state.guardians);
  const newlyAddedGuardian = useSelector(
    (state: RootStore) => state.guardians.recently_added
  );
  const classList = useSelector((state: RootStore) => state.class.classes);
  const routesList = useSelector(
    (state: RootStore) => state.transport.transport
  );
  const studentCategoryList = useSelector(
    (state: RootStore) => state.categories.categories
  );
  const housesList = useSelector(
    (state: RootStore) => state.student_house.student_houses
  );
  const errorsData = useSelector((state: RootStore) => state.student.errors);
  const errorsGuardianData = useSelector(
    (state: RootStore) => state.guardians.errors
  );

  // When there is server side error while submiting student admission forms
  useEffect(() => {
    if (errorsData?.error != null) {
      const keys = Object.keys(errorsData.error);
      keys.map((elem: any) => {
        setError(elem, {
          type: 'serverSideError',
          message: errorsData.error[elem] && errorsData.error[elem][0],
        });
      });
    }
  }, [errorsData]);

  const loadingCheck = useSelector((state: RootStore) => state.student.loading);

  // useEffect(() => {
  //   if (guardianList.results != null) {
  //     setParentChoices(guardianList.results);
  //   }
  // }, [guardianList.results]);

  useEffect(() => {
    classList && setGradeChoices(classList);
  }, [classList]);

  useEffect(() => {
    if (grade != null) {
      populateSectionChoices(grade.section);
    } else if (grade == null) {
      setSectionDisabler(true);
    }
  }, [grade]);

  useEffect(() => {
    if (sectionChoices != null && studentID != null) {
      setSection(
        sectionChoices.find(
          (item: any) =>
            item.id ===
            (selectedStudent?.academic_info &&
              selectedStudent?.academic_info[0].section)
        )
      );
    }
  }, [sectionChoices]);

  useEffect(() => {
    if (studentCategoryList != null) {
      setStudentCategoryChoices(studentCategoryList);
    }
  }, [studentCategoryList]);

  useEffect(() => {
    housesList && setHouseChoices(housesList);
  }, [housesList]);

  useEffect(() => {
    routesList && setRouteChoices(routesList);
  }, [routesList]);
  // When guardian is added from this component
  useEffect(() => {
    if (newlyAddedGuardian) {
      setParent(newlyAddedGuardian);
    }
  }, [newlyAddedGuardian]);

  const handleGradeChange = (value: any | null) => {
    value != null && setGrade(value);
    setSection(null);
    value != null
      ? populateSectionChoices(value.section)
      : setSectionDisabler(true);
    value == null && setGrade(null);
  };

  const populateSectionChoices = (sections: any | []) => {
    setSectionDisabler(false);
    sections?.length > 0
      ? setSectionChoices(sections)
      : setSectionDisabler(true);
  };

  const onParentInputChange = (event: any, value: any) => {
    dispatch(GetGuardianByName(value));
  };

  const handleParentChange = (val: any) => {
    if (val != null) {
      setParent(val);
      setValue('last_name', val.guardian_user.last_name);
      setValue('address', val.address);
      setValue('phone', val.phone_number);
      setValue('email', val.guardian_user.email);
    }
  };

  const studentState = useSelector((state: RootStore) => {
    if (studentID) {
      return state.student_profile;
    }
  });
  selectedStudent = studentState?.student;
  // Populate initial filled data for input field ( in update case)
  useEffect(() => {
    if (studentID != null && selectedStudent != null) {
      setValue('first_name', selectedStudent?.student_user?.first_name);
      setValue('last_name', selectedStudent?.student_user?.last_name);
      setValue('address', selectedStudent?.address);
      setValue('phone', selectedStudent?.phone_number);
      setValue('email', selectedStudent?.student_user?.email);
      setValue('medical_history', selectedStudent?.medical_history);
      setValue('roll_no', selectedStudent?.academic_info?.[0]?.roll_number);
      setValue(
        'last_school_name',
        selectedStudent?.academic_info?.[0]?.last_school_name
      );
      setValue(
        'last_percentage',
        selectedStudent?.academic_info?.[0]?.last_percentage
      );
      selectedStudent?.date_of_birth &&
        setDob(dateConverterAdToBs(selectedStudent?.date_of_birth));
      setImage({
        pictureAsFile: "",
        picturePreview: selectedStudent?.student_photo || "",
      });
      if (selectedStudent && selectedStudent.guardian_detail) {
        const temp = parentChoices.find(
          (item: any) => item.id === selectedStudent.guardian_detail.id
        );
      }
      // setParent(getFullName(selectedStudent?.guardian_detail?.guardian_user?.first_name, selectedStudent?.guardian_detail?.guardian_user?.last_name));
      setParent(selectedStudent?.guardian_detail);
      setGender(
        SELECT_GENDER.find((item) => item.key === selectedStudent?.gender) ||
        SELECT_GENDER[0]
      );

      setBloodGroup(
        SELECT_BLOOD_GROUP.find(
          (item) => item.key === selectedStudent?.blood_group
        ) || SELECT_BLOOD_GROUP[0]
      );
      const test = gradeChoices.find(
        (item: any) =>
          item.grade ===
          (selectedStudent?.academic_info &&
            selectedStudent?.academic_info?.[0].grade)
      );
      setGrade(test);
      populateSectionChoices(
        gradeChoices?.find(
          (item: any) =>
            item.grade ===
            (selectedStudent?.academic_info &&
              selectedStudent?.academic_info?.[0].grade)
        )?.section
      );
      setSection(
        sectionChoices.find(
          (item: any) =>
            item.section ===
            (selectedStudent?.academic_info &&
              selectedStudent?.academic_info[0].section)
        )
      );
      gradeChoices.find((item: any) => item.grade === selectedStudent?.grade) &&
        setSectionDisabler(false);

      setPreviousGrade(
        gradeChoices.find(
          (item: any) =>
            item.grade ===
            (selectedStudent?.academic_info &&
              selectedStudent?.academic_info?.[0].last_grade)
        )
      );
      setSection(
        sectionChoices.find(
          (item: any) =>
            item.id ===
            (selectedStudent?.academic_info &&
              selectedStudent?.academic_info?.[0].section)
        )
      );
      // setRoute(
      //   routesChoices.find(
      //     (item: any) => item.id === selectedStudent?.transportation_detail
      //   )
      // );
      setReligion(
        SELECT_RELIGION.find((item) => item.key === selectedStudent?.religion)
      );

      // const tempHouse = !isAdmin ? selectedStudent.academic_info?.[0].house.id : selectedStudent.academic_info?.[0].house;
      setHouse(
        houseChoices.find(
          (item: any) => item.id == selectedStudent.academic_info?.[0].house
        )
      );

      setSports(
        SPORT_CHOICES.find(
          (item) => item.key == selectedStudent.academic_info?.[0].sports
        )
      );
      setStudentCategory(
        studentCategoryChoices.find(
          (item: any) => item.id === selectedStudent?.student_category
        )
      );
      if (selectedStudent?.student_document != []) {
        const initial_documents = [];
        for (let i = 0; i < selectedStudent?.student_document?.length; i++) {
          initial_documents.push({
            id: selectedStudent.student_document[i]?.id,
            name: selectedStudent.student_document[i]?.name,
            title: selectedStudent.student_document[i]?.title,
            document: selectedStudent.student_document[i]?.document,
          });
        }
        initial_documents.filter((item) => item.name != null);
        setDocuments(initial_documents.filter((item) => item.name != null));
      }
    } else {
      setValue('first_name', '');
      setValue('last_name', '');
      setValue('address', '');
      setValue('phone', null);
      setValue('email', '');
      setValue('medical_history', '');
      setValue('roll_no', null);
      setValue('last_school_name', '');
      setValue('last_percentage', null);
      setDob('');
      setImage({ picturePreview: "", pictureAsFile: null });
      setParent(null);
      setGender(null);
      setBloodGroup(null);
      setGrade(null);
      populateSectionChoices(
        gradeChoices?.find(
          (item: any) =>
            item.grade ===
            (selectedStudent?.academic_info &&
              selectedStudent?.academic_info?.[0].grade)
        )?.section
      );
      setSection(null);
      gradeChoices.find((item: any) => item.grade === selectedStudent?.grade) &&
        setSectionDisabler(false);
      setPreviousGrade(null);
      setRoute(null);
      setReligion(null);
      setHouse(null);
      setSports(null);
      setStudentCategory(null);
      setDocuments([]);
    }
  }, [selectedStudent]);

  useEffect(() => {
    if (errorsData != null) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [errorsData]);

  const handleDateofBirth = (value: any) => {
    setDob(value?.toString());
    clearErrors('date_of_birth');
  };

  // While submitting the form
  const onSubmit = (data: any) => {
    const formData = new FormData();
    if (!isAdmin && studentID != undefined) {
      const updateData: any = {
        id: selectedStudent.id,
        first_name: data.first_name.trim(),
        last_name: data.last_name.trim(),
        email: data.email.trim(),
        address: data.address.trim(),
        student_category: studentCategory.id,
        religion: religion?.key,
        phone_number: data.phone.trim(),
        date_of_birth: dateConverterBsToAd(dob),
        gender: gender?.key,
        blood_group: bloodGroup?.key,
        medical_history: data.medical_history.trim(),
        academic_info: {
          id: selectedStudent.academic_info[0].id,
          last_school_name: data.last_school_name
            ? data.last_school_name
            : null,
          last_percentage: data.last_percentage ? data.last_percentage : null,
        },
      };
      if (image) {
        formData.append("user", selectedStudent.student_user.id)
        formData.append("photo", image.pictureAsFile);
        image.pictureAsFile && dispatch(UpdateProfilePicture(formData))
      }
      dispatch(UpdateStudentProfile(updateData));
      history.push('/profile');
    } else if (isAdmin) {
      const form_data: any = {
        student_user: {
          first_name: data.first_name.trim(),
          last_name: data.last_name.trim(),
          email: data.email.trim(),
        },
        guardian_detail: parent?.id,
        address: data.address.trim(),
        student_category: studentCategory.id,
        religion: religion?.key,
        phone_number: data.phone.trim(),
        date_of_birth: dateConverterBsToAd(dob),
        blood_group: bloodGroup?.key,
        gender: gender?.key,
        medical_history: data.medical_history.trim(),
        transportation_detail: route?.id,
        academic_info: [
          {
            last_grade: previousGrade?.grade,
            roll_number: parseInt(data.roll_no),
            last_school_name: data.last_school_name
              ? data.last_school_name
              : null,
            last_percentage: data.last_percentage ? data.last_percentage : null,
            sports: sports ? sports.key : null,
            grade: grade?.grade,
            section: section?.id,
            house: house ? house.id : null,
          },
        ],
        student_document: prepareDocumentFiles(documents),
      };
      delete form_data['student_document'];
      if (image) {
        formData.append("user", selectedStudent.student_user.id)
        formData.append("photo", image.pictureAsFile);
      }
      if (studentID != undefined) {
        image.pictureAsFile && dispatch(UpdateProfilePicture(formData))
        dispatch(UpdateStudent(studentID, form_data))
      } else {
        dispatch(StudentAdmission(form_data));
      }
      history.push('/student');
    }
  };

  return (
    <div>
      <Paper className={classes.pageContent} elevation={1}>
        <Grid container>
          <Grid item>
            <Typography variant="h6">
              {selectedStudent ? 'Update Student' : 'New Student Admission'}
            </Typography>
          </Grid>
        </Grid>
        <div className={classes.form}>
          {errorsData?.error &&
            Object.keys(errorsData.error).map((elem: any, index: number) => {
              return (
                <div className={classes.serversidemessages} key={index}>
                  <Alert severity="error">
                    {' '}
                    {firstWordCapital(
                      errorsData.error && errorsData?.error[elem][0]
                    )}
                  </Alert>
                </div>
              );
            })}

          {isAdmin && (
            <Grid container>
              <Grid container>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} className={classes.formTitle}>
                  <span>Guardian Detail</span>
                </Grid>
              </Grid>

              <Grid container alignItems="center">
                <Grid item md={3}>
                  <TextField
                    name="parent"
                    value={getFullName(
                      parent?.guardian_user?.first_name,
                      parent?.guardian_user?.last_name
                    )}
                    disabled
                    variant="outlined"
                  />
                </Grid>
                {/* <Grid item xs={12} className={classes.formWrapper}>
                  <Grid container>
                    <RadioGroup
                      className={classes.radioGroupRow}
                      row
                      value={selectedRadioValue}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setSelectedRadioValue(event.target.value);
                      }}
                    >
                      <Grid item xs={3}>
                        <FormControlLabel
                          value="already_added"
                          control={<Radio color="primary" />}
                          label="Already Added"
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <FormControlLabel
                          value="add_new"
                          control={<Radio color="primary" />}
                          label="Add New"
                        />
                      </Grid>
                    </RadioGroup>
                  </Grid>
                </Grid> */}

                {/* {selectedRadioValue === 'already_added' ? (
                  <Grid item xs={4} className={classes.formWrapper}>
                    <InputLabel>
                      Select Guardian <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <Autocomplete
                      onChange={(
                        event: React.ChangeEvent<{}>,
                        value: Tupple | null
                      ) => handleParentChange(value)}
                      onInputChange={onParentInputChange}
                      onOpen={() => {
                        setParentSelectOpen(true);
                      }}
                      onClose={() => {
                        setParentSelectOpen(false);
                      }}
                      loading={parentLoading}
                      // value={parent && parent}
                      value={parent}
                      options={parentChoices}
                      getOptionLabel={(option: any) =>
                        `${option.guardian_user?.first_name} ${option.guardian_user?.last_name}`
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Label"
                          name="parent"
                          inputRef={register({ required: true })}
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {parentLoading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />

                    <span className={classes.validationErrorInfo}>
                      {errors.parent?.type === 'required' &&
                        'Parent must be selected'}
                    </span>
                  </Grid>
                ) : (
                  <ParentDetailForm
                    setSelectedRadioValue={setSelectedRadioValue}
                    errorsGuardianData={errorsGuardianData}
                    data={studentID ? selectedStudent?.guardian_detail : undefined}
                  />
                )} */}
              </Grid>
            </Grid>
          )}
        </div>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid container>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} className={classes.formTitle}>
                <span>Student Info</span>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} className={classes.formWrapper}>
                <InputLabel>Upload Student Photo</InputLabel>
                <ImageUpload
                  setImage={setImage}
                  image={image?.picturePreview}
                  id="upload-input2"
                />
              </Grid>
              <Grid item md={12} className={classes.formWrapper}>
                <Grid container spacing={3}>
                  <Grid item md={6} className={classes.formWrapper}>
                    <InputLabel>
                      First Name <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <TextField
                      variant="outlined"
                      name="first_name"
                      placeholder="First Name"
                      inputRef={register({
                        required: true,
                      })}
                    />
                    {formsValidationCheckup({
                      errors: errors,
                      inputName: 'first_name',
                      requiredMessage: 'First Name is Required',
                      serverSideMessage:
                        errors.first_name?.type === 'serverSideError' &&
                        errors.first_name.message,
                    })}
                  </Grid>
                  <Grid item md={6} className={classes.formWrapper}>
                    <InputLabel>
                      Last Name <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <TextField
                      variant="outlined"
                      name="last_name"
                      placeholder="Last Name"
                      inputRef={register({ required: true })}
                    />
                    {formsValidationCheckup({
                      errors: errors,
                      inputName: 'last_name',
                      requiredMessage: 'Last Name is Required',
                      serverSideMessage:
                        errors.last_name?.type === 'serverSideError' &&
                        errors.last_name.message,
                    })}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={6} className={classes.formWrapper}>
                <InputLabel>
                  Address Name <span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <TextField
                  variant="outlined"
                  name="address"
                  placeholder="Full Address"
                  inputRef={register({ required: true })}
                />
                {formsValidationCheckup({
                  errors: errors,
                  inputName: 'address',
                  requiredMessage: 'Address is Required',
                  serverSideMessage:
                    errors.address?.type === 'serverSideError' &&
                    errors.address.message,
                })}
              </Grid>
              <Grid item md={3} className={classes.formWrapper}>
                <InputLabel>
                  Contact Number <span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <TextField
                  variant="outlined"
                  name="phone"
                  type="number"
                  placeholder="contact number"
                  inputProps={{ min: 0 }}
                  inputRef={register({
                    required: true,
                    maxLength: 10,
                    minLength: 9,
                  })}
                />
                {formsValidationCheckup({
                  errors: errors,
                  inputName: 'phone',
                  requiredMessage: 'Student Phone Number is Required',
                  maxLengthMessage: 'Characters must in between (9-10) digits',
                  minLengthMessage: 'Characters must in between (9-10) digits',
                  serverSideMessage:
                    errors.phone?.type === 'serverSideError' &&
                    errors.phone.message,
                })}
              </Grid>
              <Grid item md={3} className={classes.formWrapper}>
                <InputLabel>
                  Email{!isAdmin && <span style={{ color: 'red' }}>*</span>}
                </InputLabel>
                <TextField
                  variant="outlined"
                  name="email"
                  placeholder="Email"
                  inputRef={register({
                    required: !isAdmin ? true : false,
                    pattern: {
                      value: /^[a-z0-9._%+_]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                      message: 'Enter a valid email (e.g. yourname@gmail.com)',
                    },
                  })}
                />
                {formsValidationCheckup({
                  errors: errors,
                  inputName: 'email',
                  requiredMessage: 'Student email is required',
                  serverSideMessage:
                    errors.email?.type === 'serverSideError' &&
                    errors.email.message,
                })}
              </Grid>
              <Grid item md={4} className={classes.formWrapper}>
                <CustomizedNepaliDatePicker
                  label="Date of Birth"
                  value={dob}
                  setValue={handleDateofBirth}
                  name="date_of_birth"
                />
                {formsValidationCheckup({
                  errors: errors,
                  inputName: 'date_of_birth',
                  serverSideMessage:
                    errors.date_of_birth?.type === 'serverSideError' &&
                    errors.date_of_birth.message,
                })}
              </Grid>
              <Grid item md={4} className={classes.formWrapper}>
                <InputLabel>
                  Gender <span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <Autocomplete
                  onChange={(
                    event: React.ChangeEvent<{}>,
                    value: Tupple | null
                  ) => setGender(value)}
                  options={SELECT_GENDER}
                  value={gender}
                  getOptionLabel={(option) => option.value}
                  openOnFocus={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Label"
                      name="gender"
                      variant="outlined"
                      inputRef={register({
                        required: true,
                      })}
                    />
                  )}
                />
                {formsValidationCheckup({
                  errors: errors,
                  inputName: 'gender',
                  requiredMessage: 'Gender is required',
                  serverSideMessage:
                    errors.gender?.type === 'serverSideError' &&
                    errors.gender.message,
                })}
              </Grid>
              <Grid item md={4} className={classes.formWrapper}>
                <InputLabel>Blood Group</InputLabel>
                <Autocomplete
                  onChange={(
                    event: React.ChangeEvent<{}>,
                    value: Tupple | null
                  ) => setBloodGroup(value)}
                  options={SELECT_BLOOD_GROUP}
                  value={bloodGroup}
                  getOptionLabel={(option) => option.value}
                  openOnFocus={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Label"
                      variant="outlined"
                      name="blood_group"
                    />
                  )}
                />
                {formsValidationCheckup({
                  errors: errors,
                  inputName: 'blood_group',
                  requiredMessage: 'Blood Group is required',
                  serverSideMessage:
                    errors.blood_group?.type === 'serverSideError' &&
                    errors.blood_group.message,
                })}
              </Grid>
              <Grid item md={4} className={classes.formWrapper}>
                <InputLabel>
                  Student Category <span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <Autocomplete
                  onChange={(event: React.ChangeEvent<{}>, value: any) =>
                    setStudentCategory(value)
                  }
                  options={studentCategoryChoices}
                  value={studentCategory}
                  getOptionLabel={(option) => option.name}
                  openOnFocus={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Label"
                      name="student_category"
                      variant="outlined"
                      inputRef={register({
                        required: true,
                      })}
                    />
                  )}
                />
                {formsValidationCheckup({
                  errors: errors,
                  inputName: 'student_category',
                  requiredMessage: 'Student Category is required',
                  serverSideMessage:
                    errors.student_category?.type === 'serverSideError' &&
                    errors.student_category.message,
                })}
              </Grid>
              <Grid item md={4} className={classes.formWrapper}>
                <InputLabel>Religion</InputLabel>
                <Autocomplete
                  onChange={(
                    event: React.ChangeEvent<{}>,
                    value: Tupple | null
                  ) => setReligion(value)}
                  options={SELECT_RELIGION}
                  value={religion}
                  getOptionLabel={(option) => option.value}
                  openOnFocus={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Label"
                      name="religion"
                      variant="outlined"
                      inputRef={register}
                    />
                  )}
                />
                {formsValidationCheckup({
                  errors: errors,
                  inputName: 'religion',
                  requiredMessage: 'Religion is required',
                  serverSideMessage:
                    errors.religion?.type === 'serverSideError' &&
                    errors.religion.message,
                })}
              </Grid>

              <Grid item xs={12} className={classes.formWrapper}>
                <InputLabel>Medical History (if any)</InputLabel>
                <TextField
                  variant="outlined"
                  multiline
                  rows={4}
                  name="medical_history"
                  placeholder="Medical History"
                  inputRef={register({
                    required: false,
                  })}
                />
                {formsValidationCheckup({
                  errors: errors,
                  inputName: 'medical_history',
                  requiredMessage: 'Medical History is required',
                  serverSideMessage:
                    errors.medical_history?.type === 'serverSideError' &&
                    errors.medical_history.message,
                })}
              </Grid>
            </Grid>
          </Grid>

          {/* {isAdmin && (
            <Grid container>
              <Grid container>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} className={classes.formTitle}>
                  <span>Transportation Details</span>
                </Grid>
              </Grid>
              <Grid container alignItems="center" spacing={3}>
                <Grid item sm={6} className={classes.formWrapper}>
                  <InputLabel>Vehicle Route</InputLabel>
                  <Autocomplete
                    onChange={(event: React.ChangeEvent<{}>, value: any | null) =>
                      setRoute(value)
                    }
                    options={routesChoices}
                    getOptionLabel={(option) =>
                      option.route_from + ' - ' + option.route_to
                    }
                    value={route}
                    openOnFocus={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Vehicle Route"
                        variant="outlined"
                        name="transporation_detail"
                        inputRef={register({ required: false })}
                      />
                    )}
                  />
                  {formsValidationCheckup({
                    errors: errors,
                    inputName: 'transporation_detail',
                    serverSideMessage:
                      errors.transporation_detail?.type === 'serverSideError' &&
                      errors.transporation_detail.message,
                  })}
                </Grid>
              </Grid>
            </Grid>
          )} */}

          <Grid container>
            <Grid container>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} className={classes.formTitle}>
                <span>Academic Info</span>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item md={4} className={classes.formWrapper}>
                <InputLabel>
                  Class <span style={{ color: 'red' }}>*</span>
                </InputLabel>
                {selectedStudent === null || isAdmin ? (
                  <>
                    <Autocomplete
                      onChange={(
                        event: React.ChangeEvent<{}>,
                        value: any | null
                      ) => handleGradeChange(value)}
                      options={gradeChoices}
                      getOptionLabel={(option) =>
                        'Class' + ' ' + option.grade_name
                      }
                      openOnFocus={true}
                      value={grade}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="class"
                          placeholder="Class"
                          variant="outlined"
                          inputRef={register({ required: true })}
                        />
                      )}
                    />
                    {formsValidationCheckup({
                      errors: errors,
                      inputName: 'class',
                      requiredMessage: 'Class is Required',
                      serverSideMessage:
                        errors.class?.type === 'serverSideError' &&
                        errors.class.message,
                    })}
                  </>
                ) : (
                  <>
                    <TextField
                      name="class"
                      placeholder="Class"
                      variant="outlined"
                      value={
                        selectedStudent?.academic_info?.[0]?.grade_name ||
                        selectedStudent?.academic_info?.[0]?.grade?.name
                      }
                      inputRef={register({ required: true })}
                      disabled
                    />
                    {formsValidationCheckup({
                      errors: errors,
                      inputName: 'class',
                      requiredMessage: 'Class is Required',
                      serverSideMessage:
                        errors.class?.type === 'serverSideError' &&
                        errors.class.message,
                    })}
                  </>
                )}
              </Grid>
              <Grid item md={4} className={classes.formWrapper}>
                <InputLabel>
                  Section{' '}
                  {!sectionDisabler && <span style={{ color: 'red' }}>*</span>}
                </InputLabel>
                {selectedStudent === null || isAdmin ? (
                  <>
                    <Autocomplete
                      value={section}
                      onChange={(
                        event: React.ChangeEvent<{}>,
                        value: any | null
                      ) => setSection(value)}
                      options={sectionChoices}
                      getOptionLabel={(option) => option.name}
                      openOnFocus={true}
                      disabled={sectionDisabler}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Section"
                          name="section"
                          variant="outlined"
                          inputRef={register({
                            required: !sectionDisabler,
                          })}
                        />
                      )}
                    />
                    {formsValidationCheckup({
                      errors: errors,
                      inputName: 'section',
                      requiredMessage: 'Section is Required',
                      serverSideMessage:
                        errors.section?.type === 'serverSideError' &&
                        errors.section.message,
                    })}
                  </>
                ) : (
                  <>
                    <TextField
                      value={
                        selectedStudent?.academic_info?.[0]?.section_name ||
                        selectedStudent?.academic_info?.[0]?.section?.name
                      }
                      placeholder="Section"
                      name="section"
                      variant="outlined"
                      disabled
                      inputRef={register({
                        required: !sectionDisabler,
                      })}
                    />
                    {formsValidationCheckup({
                      errors: errors,
                      inputName: 'section',
                      requiredMessage: 'Section is Required',
                      serverSideMessage:
                        errors.section?.type === 'serverSideError' &&
                        errors.section.message,
                    })}
                  </>
                )}
              </Grid>
              <Grid item md={4} className={classes.formWrapper}>
                <InputLabel>Roll No</InputLabel>
                <TextField
                  variant="outlined"
                  name="roll_no"
                  type="number"
                  InputProps={{ inputProps: { min: 1, max: 99 } }}
                  placeholder="Roll No"
                  inputRef={register({ required: false })}
                />
                {formsValidationCheckup({
                  errors: errors,
                  inputName: 'roll_no',
                  serverSideMessage:
                    errors.roll_no?.type === 'serverSideError' &&
                    errors.roll_no.message,
                })}
              </Grid>
              <Grid item md={4} className={classes.formWrapper}>
                <InputLabel>House</InputLabel>
                <Autocomplete
                  onChange={(event: React.ChangeEvent<{}>, value: any | null) =>
                    setHouse(value)
                  }
                  options={houseChoices}
                  value={house}
                  getOptionLabel={(option) => option.name}
                  openOnFocus={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="House"
                      variant="outlined"
                      inputRef={register({ required: false })}
                    />
                  )}
                />
                {formsValidationCheckup({
                  errors: errors,
                  inputName: 'house',
                  serverSideMessage:
                    errors.house?.type === 'serverSideError' &&
                    errors.house.message,
                })}
              </Grid>
              <Grid item md={6} className={classes.formWrapper}>
                <InputLabel>Last School</InputLabel>{' '}
                <TextField
                  variant="outlined"
                  name="last_school_name"
                  placeholder="Last School"
                  inputRef={register({ required: false })}
                />
                {formsValidationCheckup({
                  errors: errors,
                  inputName: 'last_school_name',
                  requiredMessage: 'Last School is required',
                  serverSideMessage:
                    errors.last_school_name?.type === 'serverSideError' &&
                    errors.last_school_name.message,
                })}
              </Grid>
              <Grid item md={3} className={classes.formWrapper}>
                <InputLabel>Previous Class</InputLabel>{' '}
                <Autocomplete
                  onChange={(event: React.ChangeEvent<{}>, value: any | null) =>
                    value != null
                      ? setPreviousGrade(value)
                      : setPreviousGrade(null)
                  }
                  options={gradeChoices}
                  getOptionLabel={(option) => 'Class' + ' ' + option.grade_name}
                  openOnFocus={true}
                  value={previousGrade}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="last_grade"
                      placeholder="Previous Class"
                      variant="outlined"
                      inputRef={register({ required: false })}
                    />
                  )}
                />
                {formsValidationCheckup({
                  errors: errors,
                  inputName: 'last_grade',
                  requiredMessage: 'Last Grade is required',
                  serverSideMessage:
                    errors.last_grade?.type === 'serverSideError' &&
                    errors.last_grade.message,
                })}
              </Grid>
              <Grid item md={3} className={classes.formWrapper}>
                <InputLabel>Previous Percentage (%)</InputLabel>
                <TextField
                  variant="outlined"
                  name="last_percentage"
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 100 } }}
                  placeholder="Previous Percentage"
                  inputRef={register({ required: false })}
                />
                {formsValidationCheckup({
                  errors: errors,
                  inputName: 'last_percentage',
                  requiredMessage: 'Previous Percentage is required',
                  serverSideMessage:
                    errors.last_percentage?.type === 'serverSideError' &&
                    errors.last_percentage.message,
                })}
              </Grid>
              <Grid item md={4} className={classes.formWrapper}>
                <InputLabel>Sport</InputLabel>
                <Autocomplete
                  onChange={(
                    event: React.ChangeEvent<{}>,
                    value: Tupple | null
                  ) => setSports(value)}
                  options={SPORT_CHOICES}
                  value={sports}
                  getOptionLabel={(option) => option.value}
                  openOnFocus={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Sports"
                      variant="outlined"
                      name="sports"
                      inputRef={register({ required: false })}
                    />
                  )}
                />
                {formsValidationCheckup({
                  errors: errors,
                  inputName: 'sports',
                  serverSideMessage:
                    errors.sports?.type === 'serverSideError' &&
                    errors.sports.message,
                })}
              </Grid>
            </Grid>
          </Grid>

          {/* Do not render document uploader in edit  mode */}
          {!studentID && <DocumentsUploader setValue={setDocuments} />}

          <Grid item xs={12} md={6}></Grid>
          <Grid container justifyContent="flex-end">
            <Grid item xs={3}>
              <Grid container justifyContent="flex-end">
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  {studentID != undefined ? 'Update' : 'Save'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
        <CustomBackdrop open={loadingCheck} />
      </Paper>
    </div>
  );
};

export default StudentAdmissionForm;
