import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import SubModuleLayout from '../../components/Reusable/Layouts/SubModuleLayout';
import TransportSidebar from '../../components/Transport/TransportSidebar';
import LocationList from '../../components/Transport/Location/LocationList';
import LocationForm from '../../components/Transport/Location/LocationForm';
import { LocationTypeI } from '../../actions/Transport/Location/LocationActionTypes';

function LocationPage() {
  const [edit, setEdit] = useState(false);
  const [formData, setFormData] = useState<LocationTypeI>();

  const fetchEditData = (data: LocationTypeI) => {
    setFormData(data);
    setEdit(true);
  };

  const onEditCheck = (check: boolean) => {
    setEdit(check);
  };

  const nav = <TransportSidebar location />;
  return (
    <SubModuleLayout sideNav={nav}>
      <Grid container>
        <Grid item xs={3}>
          <LocationForm onEditCheck={onEditCheck} editData={formData} />
        </Grid>
        <Grid item xs={9}>
          <LocationList edit={edit} onEditData={fetchEditData} />
        </Grid>
      </Grid>
    </SubModuleLayout>
  );
}
export default LocationPage;
