// ---------------------------- <START> module imports start ----------------------------------//
import React, { useState, useEffect } from 'react';
import { Paper, TableCell } from '@material-ui/core';
import moment from 'moment';
import { useTableStyles } from '../../Styles/TableStyles';
import { useTable, useTableWithPagination } from '../../Reusable';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import CustomizedDialogs from '../../Reusable/Dialogs/DeleteDialog';
import Popups from '../../Reusable/Dialogs/Popups';
import LeaveRequestView from './LeaveRequestView';
import {
  DeleteLeave,
  GetLeaves,
  LeaveApproveOrDecline,
} from '../../../actions/HumanResource/Leave/LeaveAction';
import { PRIMARY_DATE_FORMAT } from '../../../common/constant';
import cx from 'clsx';
import { LeaveTypeI } from '../../../actions/HumanResource/Leave/LeaveActionTypes';
import getFullName from '../../utils/getFullName';
import GetStatus from '../../../common/GetStatus';
import { HeadCellsI } from '../../../actions';
import {
  ItemDeleteButton,
  ItemViewButton,
} from '../../Reusable/Buttons/TableButton';
import { dateConverterAdToBs } from '../../utils/dateConverter';
import { GetLeaveTypes } from '../../../actions/HumanResource/LeaveType/LeaveTypeAction';
import useLoading from '../../../useHook/useLoading';
// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: 'sn', label: 'S.N' },
  { id: 'name', label: 'Staff Name', filter: true, align: 'left' },
  { id: 'leave_type', label: 'Leave Type', align: 'left' },
  { id: 'leave_date', label: 'Leave Date', align: 'left' },
  { id: 'days', label: 'Days', align: 'right' },
  { id: 'applied_date', label: 'Applied Date', align: 'right', filter: true },
  { id: 'status', label: 'Status', filter: true },
  { id: 'action', label: 'Action' },
];

// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const LeaveList = () => {
  const dispatch = useDispatch();
  const classes = useTableStyles();

  const [tableData, setTableData] = useState<any>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [leaveData, setLeaveData] = useState([]);
  const leaveSelector = useSelector((state: RootStore) => state.leaves);
  const { loading } = useLoading(leaveSelector.loading);
  const [selectedPagination, setSelectedPagination] = useState<any | null>({
    key: '5',
    value: '5',
  });

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTableWithPagination(headCells);

  useEffect(() => {
    dispatch(GetLeaveTypes());
    dispatch(GetLeaves());
  }, []);

  useEffect(() => {
    const items = formatData(leaveSelector.leaves);
    setTableData(items);
  }, [leaveSelector]);

  const formatData = (items: any) => {
    if (items.results) {
      return items.results.map((item: any) => ({
        id: item.id,
        name: item.staff.full_name,
        designation: item.staff.designation?.name,
        leave_type: item.leave_type.name,
        leave_date: dateConverterAdToBs(item.from_date),
        days: dateConverterAdToBs(item.to_date),
        applied_date: dateConverterAdToBs(item.created_on),
        description: item.reason_for_leave,
        status: item.status,
        photo: '',
        remarks: item.remarks,
      }));
    }

    return [];
  };
  //========================= <END> REDUX HOOKS <END> ==============================//

  const handleDeleteModal = (id: string) => {
    setItemId(id);
    setDeleteModalOpen(true);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const handleViewModal = (item: any) => {
    setLeaveData(item);
    setItemId(item.id);
    setOpenPopup(true);
  };

  const handleClose = (value: boolean) => {
    setOpenPopup(value);
    setLeaveData([]);
    setItemId('');
  };

  const onActionConfirmed = (
    confirmed: boolean,
    status: string,
    remarks: string
  ) => {
    const data = {
      id: itemId,
      status,
      remarks,
    };
    confirmed && itemId != null && dispatch(LeaveApproveOrDecline(data));
    setOpenPopup(false);
  };

  console.log('tableData', tableData);

  const getCustomTableRow = (item: any, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index}
        </StyledTableCell>
        <StyledTableCell align="left" className={classes.cell}>
          {item.name}
        </StyledTableCell>
        <StyledTableCell align="left" className={classes.cell}>
          {item.leave_type}
        </StyledTableCell>
        <StyledTableCell align="left" className={classes.cell}>
          {moment(item.leave_date).format(PRIMARY_DATE_FORMAT)} -{' '}
          {moment(item.days).format(PRIMARY_DATE_FORMAT)}
        </StyledTableCell>
        <StyledTableCell align="right" className={classes.cell}>
          {moment(item.days).diff(item.leave_date, 'days') + 1}
        </StyledTableCell>
        <StyledTableCell align="right" className={classes.cell}>
          {moment(item.applied_date).format(PRIMARY_DATE_FORMAT)}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <GetStatus status={item.status} />
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ItemViewButton onClick={() => handleViewModal(item)} />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  const handlePaginationChange = (paginationIndex: number) => {
    dispatch(GetLeaves(paginationIndex, selectedPagination?.key));
  };

  return (
    <Paper className={cx(classes.root, classes.attachFormTableRoot)}>
      <TableContainer
        label="Leave List"
        loading={loading}
        items={tableData}
        headCells={headCells}
        forPagination={leaveSelector?.leaves}
        handlePaginationChange={handlePaginationChange}
        getCustomTableRow={getCustomTableRow}
        search_name="staff_name"
      />

      <Popups
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        onClose={handleClose}
        maxWidth="sm"
        title="Leave Request"
        colored
      >
        <LeaveRequestView
          onActionConfirmed={onActionConfirmed}
          items={leaveData}
          onClose={handleClose}
        />
      </Popups>
    </Paper>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default LeaveList;
