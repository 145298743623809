// ---------------------------- module imports start ----------------------------------//

import {
  Button,
  Grid,
  InputLabel,
  Paper,
  TextField,
  LinearProgress,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import SaveIcon from '@material-ui/icons/Save';
import { useForm } from 'react-hook-form';
import { useStyles } from '../Styles/FormStyles';
import { Autocomplete } from '@material-ui/lab';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import { RootStore } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import {
  GetEmailSettingData,
  AddEmailSetting,
  UpdateEmailSetting,
} from '../../actions/GeneralSettings/EmailSetting/EmailSettingAction';

// ---------------------------- module imports ends ----------------------------------//

// ---------------------------- interface starts ----------------------------------//

interface InputFormI {
  smtp_host: string;
  smtp_email: string;
  smtp_password: string;
  smtp_port: number | string;
  smtp_display_name: string;
  smtp_use_tls: boolean | string;
  showPassword: boolean;
}

interface SmtpTlsI {
  title: string;
  value?: boolean;
}

// ---------------------------- interface ends ----------------------------------//

const smtpTlsChoices: SmtpTlsI[] = [
  { title: 'True', value: true },
  { title: 'False', value: false },
];

const EmailSetting = () => {
  const classes = useStyles();

  // ---- States Declaration ------ //
  const [loading, setLoading] = useState<boolean>(false);
  const [smtpTls, setSmtpTls] = useState<any>(null);
  const [isCreating, SetIsCreating] = useState(false);
  const [isUpdating, SetIsUpdating] = useState(false);
  const [formValues, setFormValues] = useState<InputFormI>({
    smtp_host: '',
    smtp_email: '',
    smtp_password: '',
    smtp_port: '',
    smtp_display_name: '',
    smtp_use_tls: '',
    showPassword: false,
  });

  const dispatch = useDispatch();

  const sessionLoading = useSelector(
    (state: RootStore) => state.email_setting.loading
  );

  const emailSettingData = useSelector(
    (state: RootStore) => state.email_setting
  );

  useEffect(() => {
    dispatch(GetEmailSettingData());
  }, []);

  useEffect(() => {
    setLoading(sessionLoading);
  }, [sessionLoading]);

  useEffect(() => {
    if (emailSettingData.email_setting?.length > 0) {
      handleFormInitialize(emailSettingData.email_setting[0]);
      setSmtpTls(
        smtpTlsChoices.find(
          (elem) => elem.value == emailSettingData.email_setting[0].smtp_use_tls
        )
      );
      SetIsUpdating(true);
      SetIsCreating(false);
    } else {
      SetIsCreating(true);
      SetIsUpdating(false);
    }
  }, [emailSettingData]);

  //Initialize form data
  const handleFormInitialize = (data: InputFormI) => {
    setFormValues({
      ...formValues,
      smtp_host: data.smtp_host,
      smtp_email: data.smtp_email,
      smtp_password: data.smtp_password,
      smtp_port: data.smtp_port,
      smtp_display_name: data.smtp_display_name,
    });
  };

  const handleValueChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setFormValues({ ...formValues, showPassword: !formValues.showPassword });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const { register, handleSubmit, errors } = useForm<InputFormI>({
    mode: 'onChange',
  });

  const onSubmit = (data: InputFormI) => {
    const form_data: any = {
      smtp_host: data.smtp_host,
      smtp_email: data.smtp_email,
      smtp_password: data.smtp_password,
      smtp_port: data.smtp_port,
      smtp_display_name: data.smtp_display_name,
      smtp_use_tls: smtpTls.value,
    };

    //for create case
    if (isCreating) {
      dispatch(AddEmailSetting(form_data));
    }

    //for update case
    if (isUpdating) {
      if (emailSettingData.email_setting[0]) {
        dispatch(
          UpdateEmailSetting(emailSettingData.email_setting[0].id, form_data)
        );
      }
    }
  };

  return (
    <>
      <Paper className={classes.pageContent}>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={12} className={classes.formTitle}>
              <span>SMTP</span>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={4} className={classes.formWrapper}>
              <InputLabel>Smtp host</InputLabel>
              <TextField
                variant="outlined"
                name="smtp_host"
                placeholder="smtp host"
                inputRef={register}
                value={formValues.smtp_host}
                onChange={handleValueChange}
              />
              <span className={classes.validationErrorInfo} />
            </Grid>
            <Grid item xs={4} className={classes.formWrapper}>
              <InputLabel>
                SMTP Email <span style={{ color: 'red' }}>*</span>
              </InputLabel>
              <TextField
                variant="outlined"
                name="smtp_email"
                placeholder="smtp email"
                inputRef={register({
                  required: 'Email is required',
                  pattern: {
                    value: /^[a-z0-9._%+_]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                    message: 'Please enter a valid email',
                  },
                })}
                value={formValues.smtp_email}
                onChange={handleValueChange}
              />
              <span className={classes.validationErrorInfo}>
                {errors.smtp_email && errors.smtp_email.message}
              </span>
            </Grid>

            <Grid item xs={4} className={classes.formWrapper}>
              <InputLabel>SMTP Password </InputLabel>
              <OutlinedInput
                style={{ marginTop: '7px' }}
                type={formValues.showPassword ? 'text' : 'password'}
                name="smtp_password"
                placeholder="smtp password"
                inputRef={register}
                value={formValues.smtp_password}
                onChange={handleValueChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {formValues.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <span className={classes.validationErrorInfo} />
            </Grid>
            <Grid item xs={4} className={classes.formWrapper}>
              <InputLabel>SMTP Port </InputLabel>
              <TextField
                variant="outlined"
                name="smtp_port"
                type="number"
                placeholder="smtp port"
                inputRef={register({ required: true })}
                value={formValues.smtp_port}
                onChange={handleValueChange}
              />
            </Grid>
            <Grid item xs={4} className={classes.formWrapper}>
              <InputLabel>
                SMTP Display Name <span style={{ color: 'red' }}>*</span>
              </InputLabel>
              <TextField
                variant="outlined"
                name="smtp_display_name"
                placeholder="smtp display name"
                inputRef={register({ required: true })}
                value={formValues.smtp_display_name}
                onChange={handleValueChange}
              />
              <span className={classes.validationErrorInfo}>
                {errors.smtp_display_name?.type === 'required' &&
                  'SMTP display name is required'}
                {errors.smtp_display_name && errors.smtp_display_name.message}
              </span>
            </Grid>
            <Grid item xs={4} className={classes.formWrapper}>
              <InputLabel>SMTP use TLS </InputLabel>
              <Autocomplete
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: SmtpTlsI | null
                ) => setSmtpTls(value != null ? value : null)}
                options={smtpTlsChoices}
                getOptionLabel={(option) => option.title}
                value={smtpTls}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Label"
                    name="smtp_use_tls"
                    variant="outlined"
                    inputRef={register({ required: false })}
                  />
                )}
              />
              <span className={classes.validationErrorInfo} />
            </Grid>
            <Grid style={{ margin: '9px' }} container justifyContent="flex-end">
              <Button
                type="submit"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            </Grid>
          </Grid>
          {/* {loading && <LinearProgress className={classes.formProgress} />} */}
        </form>
      </Paper>
    </>
  );
};

export default EmailSetting;
