import {
  makeStyles,
  Theme,
  createStyles,
  Paper,
  Grid,
  TextField,
  InputLabel,
  Button,
  Tooltip,
  IconButton,
  Divider,
} from "@material-ui/core";
import { Search, SettingsBackupRestore } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { GetGrades } from "../../actions/Academics/Grade/GradeAction";
import { GradeTypeI } from "../../actions/Academics/Grade/GradeActionTypes";
import { SectionTypeI } from "../../actions/Academics/Section/SectionActionTypes";
import {
  GetSubjects,
  GetSubjectsByTeacher,
} from "../../actions/Academics/Subject/SubjectAction";
import { SubjectTypeI } from "../../actions/Academics/Subject/SubjectActionTypes";
import {
  GetAdminEnotes,
  GetAdminEnotesCategories,
} from "../../actions/Classroom/Enotes/AdminEnotesAction";
import { AdminEnotesCategoryI } from "../../actions/Classroom/Enotes/AdminEnotesActionTypes";
import { GetTeacherList } from "../../actions/Classroom/TeacherList/TeacherListAction";
import { TeacherListTypeI } from "../../actions/Classroom/TeacherList/TeacherListActionTypes";
import { RootStore } from "../../store";
import CustomizedSelect, { RowSelect } from "../Reusable/Inputs/Select";
import { useFormStyles } from "../Styles/FormStyles";
import formsValidationCheckup from "../utils/formsValidationCheckUp";
import { CUR_NEPALI_DATE } from "../utils/nepaliDateUtils";
import getFullName from '../utils/getFullName';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: "0px",
      padding: theme.spacing(2),
    },
  })
);

const SearchEnote = (props: any) => {
  const classes = useFormStyles();
  const { classItem } = props;
  const classZ = useStyles();
  const dispatch = useDispatch();

  const { register, handleSubmit, errors, reset, setValue } = useForm<any>({
    mode: "onChange",
  });

  const [selectedGrade, setSelectedGrade] = useState();
  const [date, setDate] = useState(CUR_NEPALI_DATE);
  const [selectedSection, setSelectedSection] = useState<SectionTypeI | null>(
    null
  );
  const [sectionChoices, setSectionChoices] = useState<SectionTypeI[]>([]);

  const [selectedCategory, setSelectedCategory] =
    useState<AdminEnotesCategoryI | null>(null);

  const [selectedSubjects, setSelectedSubjects] = useState<SubjectTypeI | null>(
    null
  );

  const [teacherChoices, setTeacherChoices] = useState<TeacherListTypeI[]>([]);
  const [selectedTeacher, setSelectedTeacher] =
    useState<TeacherListTypeI | null>(null);

  useEffect(() => {
    dispatch(GetGrades());
    dispatch(GetAdminEnotesCategories());
    // dispatch(GetSubjects());
  }, []);

  const categorySelector = useSelector(
    (state: RootStore) => state.admin_enotes.enotes_categories
  );

  const teacherSelector = useSelector(
    (state: RootStore) => state.teacher_lists.teacher_list
  );

  const CATEGORY_CHOICES = categorySelector?.map(
    (item: AdminEnotesCategoryI) => ({
      key: item.id.toString(),
      value: item.name,
      id: item.id,
    })
  );

  useEffect(() => {
    setTeacherChoices(teacherSelector);
  }, [teacherSelector]);

  const SUBJECT_CHOICES = teacherSelector?.map((item: TeacherListTypeI) => ({
    value: item.subject_name,
    id: item.subject,
  }));

  const SECTION_CHOICES = classItem?.section?.map((item: SectionTypeI) => ({
    key: item.id.toString(),
    value: item.name,
    id: item.id,
  }));

  const TEACHER_CHOICES = teacherSelector?.map((item: TeacherListTypeI) => ({
    id: item.teacher_id,
    value: getFullName(item.teacher_first_name, item.teacher_last_name)
  }))

  const onSubmit = () => {
    if (selectedSubjects && selectedCategory && selectedTeacher) {
      dispatch(
        GetAdminEnotes(
          classItem.grade,
          selectedSubjects?.id,
          selectedCategory?.id,
          selectedTeacher?.teacher_user_id
        )
      );
    }
    // dispatch(GetChildrenAssignmentList(children.key));
  };

  const handleReset = () => {
    setSelectedSection(null);
    setSelectedTeacher(null)
    setSelectedSubjects(null);
    setSelectedCategory(null);
  };

  useEffect(() => {
    handleReset();
  }, [classItem]);

  const handleSection = (value: any) => {
    setSelectedTeacher(null)
    setSelectedSubjects(null);
    if (value && classItem) {
      setSelectedSection(value);
      dispatch(GetTeacherList(classItem.grade, value.id, "", ""));
    }
  };

  const handleTeacherChange = (value: TeacherListTypeI | null) => {
    const section = selectedSection?.id ? selectedSection?.id : ""
    const subject = selectedSubjects?.id ? selectedSubjects?.id : ""
    if (value) {
      dispatch(GetTeacherList(classItem.grade, section, subject, value?.id));
      setSelectedTeacher(value);
      // dispatch(GetSubjectsByTeacher(classItem.grade, value?.teacher_user_id));
    }
  };

  return (
    <>
      <Paper elevation={1} className={classZ.root}>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <Grid item xs={3} className={classes.formWrapperRow}>
              <RowSelect
                label="Section"
                name="section"
                fullWidth
                options={SECTION_CHOICES}
                value={selectedSection}
                setValue={handleSection}
                error={errors["section"] && true}
                inputRef={register({ required: true })}
              />
              {formsValidationCheckup({
                errors: errors,
                inputName: "section",
                requiredMessage: "You must select Section",
              })}
            </Grid>
            <Grid item xs={3} className={classes.formWrapperRow}>
              <RowSelect
                label="Categories"
                name="category"
                fullWidth
                options={CATEGORY_CHOICES}
                value={selectedCategory}
                setValue={setSelectedCategory}
                error={errors["category"] && true}
                inputRef={register({ required: true })}
              />
              {formsValidationCheckup({
                errors: errors,
                inputName: "category",
                requiredMessage: "You must select Category",
              })}
            </Grid>
            <Grid item xs={3} className={classes.formWrapperRow}>
              <RowSelect
                label="Teacher"
                name="teacher"
                fullWidth
                options={TEACHER_CHOICES}
                value={selectedTeacher}
                setValue={handleTeacherChange}
                error={errors["teacher"] && true}
                inputRef={register({ required: true })}
              />
              {formsValidationCheckup({
                errors: errors,
                inputName: "teacher",
                requiredMessage: "You must select Teacher",
              })}
            </Grid>
            <Grid item xs={3} className={classes.formWrapperRow}>
              <RowSelect
                label="Subject"
                name="subject"
                fullWidth
                options={SUBJECT_CHOICES}
                value={selectedSubjects}
                setValue={setSelectedSubjects}
                error={errors["subject"] && true}
                inputRef={register({ required: true })}
              />
              {formsValidationCheckup({
                errors: errors,
                inputName: "subject",
                requiredMessage: "You must select Subject",
              })}
            </Grid>
            <Grid
              style={{ maxWidth: "14%" }}
              item
              xs={2}
              className={classes.formWrapperRow}
            >
              <Button
                style={{
                  marginTop: "0px",
                  backgroundColor: "#3FC581",
                  color: "#fff",
                }}
                type="submit"
                variant="contained"
                startIcon={<Search />}
              >
                Search
              </Button>
            </Grid>
            <Grid item xs={1}>
              <Tooltip title="Clear Search Query">
                <IconButton
                  aria-label="delete"
                  style={{
                    padding: "4px",
                    marginTop: "0px",
                    background: "rgba(0,0,0,0.05)",
                  }}
                  onClick={handleReset}
                >
                  <SettingsBackupRestore />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </form>
        <Divider style={{ marginTop: "8px" }} />
      </Paper>
    </>
  );
};

export default SearchEnote;
