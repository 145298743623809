import {
  GET_SCHOLARSHIP_TYPE_LOADING,
  GET_SCHOLARSHIP_TYPE_ERROR,
  GET_SCHOLARSHIP_TYPE_SUCCESS,
  GET_SCHOLARSHIP_STUDENT_LOADING,
  GET_SCHOLARSHIP_STUDENT_ERROR,
  GET_SCHOLARSHIP_STUDENT_SUCCESS,
  GET_SCHOLARSHIP_REPORT_LOADING,
  GET_SCHOLARSHIP_REPORT_ERROR,
  GET_SCHOLARSHIP_REPORT_SUCCESS,
  ADD_SCHOLARSHIP_TYPE_LOADING,
  ADD_SCHOLARSHIP_TYPE_SUCCESS,
  ADD_SCHOLARSHIP_TYPE_ERROR,
  DELETE_SCHOLARSHIP_TYPE_SUCCESS,
  DELETE_SCHOLARSHIP_TYPE_ERROR,
  DELETE_SCHOLARSHIP_TYPE_LOADING,
  UPDATE_SCHOLARSHIP_TYPE_LOADING,
  UPDATE_SCHOLARSHIP_TYPE_SUCCESS,
  UPDATE_SCHOLARSHIP_TYPE_ERROR,
  APPLY_SCHOLARSHIP_LOADING,
  APPLY_SCHOLARSHIP_ERROR,
  APPLY_SCHOLARSHIP_SUCCESS,
  REMOVE_SCHOLARSHIP_LOADING,
  REMOVE_SCHOLARSHIP_ERROR,
  REMOVE_SCHOLARSHIP_SUCCESS,
  GET_SCHOLARSHIP_LOADING,
  GET_SCHOLARSHIP_ERROR,
  GET_SCHOLARSHIP_SUCCESS,
  GET_SCHOLARSHIP_BY_STUDENT_LOADING,
  GET_SCHOLARSHIP_BY_STUDENT_ERROR,
  GET_SCHOLARSHIP_BY_STUDENT_SUCCESS,
  ScholarshipDispatchTypes,
  ScholarshipType,
  ApplyScholarShipI,
  GetScholarShipI,
  StudentAcademicI,
  StudentScholarshipI,
} from "../../actions/ScholarShipType/ScholarshipActionTypes";

interface InitialStateI {
  loading: boolean;
  scholarship_type?: any;
  scholarship_list?: any;
  errors: any;
  recent?: boolean;
  student_scholarship: StudentScholarshipI[];
  scholarship_students: any;
  scholarship_reports: any;
  add_or_update: boolean;
}

const initialState: InitialStateI = {
  loading: false,
  scholarship_type: [],
  scholarship_list: [],
  errors: null,
  recent: false,
  student_scholarship: [],
  scholarship_students: [],
  scholarship_reports: [],
  add_or_update: false,
};

const ScholarshipReducer = (
  state: InitialStateI = initialState,
  action: ScholarshipDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_SCHOLARSHIP_TYPE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_SCHOLARSHIP_TYPE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_SCHOLARSHIP_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        scholarship_type: action.payload,
        errors: null,
        recent: false,
      };

    case GET_SCHOLARSHIP_STUDENT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_SCHOLARSHIP_STUDENT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_SCHOLARSHIP_STUDENT_SUCCESS:
      return {
        ...state,
        loading: false,
        scholarship_students: action.payload,
        errors: null,
        recent: false,
      };

    case GET_SCHOLARSHIP_REPORT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_SCHOLARSHIP_REPORT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_SCHOLARSHIP_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        scholarship_reports: action.payload,
        errors: null,
        recent: false,
      };

    case ADD_SCHOLARSHIP_TYPE_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case ADD_SCHOLARSHIP_TYPE_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        add_or_update: true,
      };

    case ADD_SCHOLARSHIP_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        scholarship_type: [...state.scholarship_type, action.payload],
        errors: null,
        recent: true,
        add_or_update: true,
      };

    case UPDATE_SCHOLARSHIP_TYPE_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case UPDATE_SCHOLARSHIP_TYPE_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        add_or_update: true,
      };

    case UPDATE_SCHOLARSHIP_TYPE_SUCCESS:
      const current_data: any = state.scholarship_type;
      const index: number = current_data.findIndex(
        (element: any) => element.id === action.payload.id
      );

      return {
        ...state,
        loading: false,
        scholarship_type: current_data,
        errors: null,
        recent: true,
        add_or_update: true,
      };

    case DELETE_SCHOLARSHIP_TYPE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_SCHOLARSHIP_TYPE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_SCHOLARSHIP_TYPE_SUCCESS:
      const new_data: any = state.scholarship_type;
      const item_index = new_data.findIndex(
        (element: any) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return {
        ...state,
        loading: false,
        scholarship_type: new_data,
        errors: null,
      };

    case GET_SCHOLARSHIP_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_SCHOLARSHIP_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_SCHOLARSHIP_SUCCESS:
      return {
        ...state,
        loading: false,
        scholarship_list: action.payload.results[0].student_academic,
        errors: null,
        recent: false,
      };

    case REMOVE_SCHOLARSHIP_LOADING:
      return {
        ...state,
        loading: true,
      };

    case REMOVE_SCHOLARSHIP_ERROR:
      return {
        ...state,
        loading: false,
      };

    case REMOVE_SCHOLARSHIP_SUCCESS:
      // const n_data: any = state.scholarship_list?.results[0]?.student_academic;
      // console.log("reducer", n_data);
      // const it_index = n_data.findIndex(
      //   (element: any) => element.id === action.payload.student_academic.id
      // );
      // console.log("index", it_index);
      // const final = n_data.filter(
      //   (item: any) => item.id !== action.payload.student_academic.id
      // );
      // console.log("filter", final);
      // n_data.splice(it_index, 1);

      // n_data.map((elem: any) => {
      //   if (elem.student_academic !== action.payload.student_academic) {
      //     n_data.splice(elem, 1);
      //   }
      // });
      return {
        ...state,
        loading: false,
        scholarship_list: action.payload.student_academic,
        errors: null,
      };

    case GET_SCHOLARSHIP_BY_STUDENT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_SCHOLARSHIP_BY_STUDENT_SUCCESS:
      return {
        ...state,
        loading: false,
        student_scholarship: action.payload,
      };

    case GET_SCHOLARSHIP_BY_STUDENT_ERROR:
      return {
        ...state,
        loading: false,
      };
    case APPLY_SCHOLARSHIP_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case APPLY_SCHOLARSHIP_SUCCESS:
      return {
        ...state,
        loading: false,
        add_or_update: true,
      };

    case APPLY_SCHOLARSHIP_ERROR:
      return {
        ...state,
        loading: false,
        add_or_update: true,
      };

    default:
      return state;
  }
};

export default ScholarshipReducer;
