import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { SchoolOutlined } from "@material-ui/icons";
import { useSidebar } from "../../Reusable";
import { useSidebarStyles } from "../../Styles/SidebarStyles";
import SidebarTitle from "../../Reusable/Titles/SidebarTitle";
import StudentHouseTable from "../StudentHouse/StudentHouseTable";
import { useSelector } from "react-redux";
import { RootStore } from "../../../store";

interface propsI {
  studentDetails?: boolean | undefined;
  studentAdmission?: boolean | undefined;
  guardianDetails?: boolean | undefined;
  disabledStudent?: boolean | undefined;
  studentCategories?: boolean | undefined;
  bulkDelete?: boolean | undefined;
  studentHouse?: boolean | undefined;
  studentComplaint?: boolean | undefined;
  studentLeave?: boolean | undefined;
  rollNum?: boolean | undefined;
  sectionTransfer?: boolean | undefined;
  studentAttendance?: boolean | undefined;
}

interface DataSetTypeI {
  text: string;
  url: string;
  activeStatus?: boolean;
  permission: string;
}

const fetchDataSet = (dataSet: DataSetTypeI[], role: string | null) => {
  const forbiddenUrl = [
    "student-roll-num",
    "student-categories",
    "student-house",
    "student-complaint",
    "student-leave",
  ];

  if (role && role === "Accountant") {
    return dataSet.filter((item) => forbiddenUrl.includes(item.url) === false);
  }

  return dataSet;
};

const StudentSidebar = (props: propsI) => {
  const classes = useSidebarStyles();

  const [studentDetails] = useState(props.studentDetails);
  const [studentAdmission] = useState(props.studentAdmission);
  const [guardianDetails] = useState(props.guardianDetails);
  const [disabledStudent] = useState(props.disabledStudent);
  // const [bulkDelete] = useState(props.bulkDelete);
  const [studentCategories] = useState(props.studentCategories);
  const [studentHouse] = useState(props.studentHouse);
  const [studentComplaint] = useState(props.studentComplaint);
  const [studentLeave] = useState(props.studentLeave);
  const [rollNum] = useState(props.rollNum);
  const [sectionTransfer] = useState(props.sectionTransfer);
  const [studentAttendance] = useState(props.studentAttendance);

  const dataSet: DataSetTypeI[] = [
    {
      text: "Student Details",
      url: "student",
      activeStatus: studentDetails,
      permission: "fee_type_report",
    },
    // {
    //   text: "Student Admission",
    //   url: "student-admission",
    //   activeStatus: studentAdmission,
    //   permission: "student_admission",
    // },
    {
      text: "Guardian Details",
      url: "guardian-details",
      activeStatus: guardianDetails,
      permission: "fee_type_report",
    },
    {
      text: "Disabled Student",
      url: "disabled-student",
      activeStatus: disabledStudent,
      permission: "fee_type_report",
    },
    // {
    //   text: "Bulk Delete",
    //   url: "bulk-delete",
    //   activeStatus: bulkDelete,
    //   permission: "fee_type_report",
    // },
    {
      text: "Student Roll Number",
      url: "student-roll-num",
      activeStatus: rollNum,
      permission: "fee_type_report",
    },
    {
      text: "Section Transfer",
      url: "section-transfer",
      activeStatus: sectionTransfer,
      permission: "fee_type_report",
    },
    {
      text: "Student Categories",
      url: "student-categories",
      activeStatus: studentCategories,
      permission: "fee_type_report",
    },
    {
      text: "Student House",
      url: "student-house",
      activeStatus: studentHouse,
      permission: "fee_type_report",
    },
    {
      text: "Student Feedback",
      url: "student-complaint",
      activeStatus: studentComplaint,
      permission: "fee_type_report",
    },
    {
      text: "Student Leave",
      url: "student-leave",
      activeStatus: studentLeave,
      permission: "fee_type_report",
    },
    {
      text: "Student Attendance Report",
      url: "student-attendance-report",
      activeStatus: studentAttendance,
      permission: "fee_type_report",
    },
  ];

  const authState = useSelector((state: RootStore) => state.auth);
  const { role = "Administrator" } = authState;

  const finalDataSet = fetchDataSet(dataSet, role);

  const { CustomSidebarWithPermission } = useSidebar(finalDataSet, "STUDENT");
  return (
    <div className={classes.Sidebar}>
      <div className={classes.sidebarContent}>
        <SidebarTitle icon={<SchoolOutlined />}>Student</SidebarTitle>
        <CustomSidebarWithPermission />
      </div>
    </div>
  );
};

export default StudentSidebar;
