import axios from "axios";
import { Dispatch } from "redux";

import {
    APPLY_RUBRIC_CATEGORY_ERROR,
    APPLY_RUBRIC_CATEGORY_LOADING,
    APPLY_RUBRIC_CATEGORY_SUCCESS,
    GET_APPLY_RUBRIC_CATEGORY_ERROR,
    GET_APPLY_RUBRIC_CATEGORY_LOADING,
    GET_APPLY_RUBRIC_CATEGORY_SUCCESS,
    ApplyPayloadI,
    AssignRubricDispatchTypes
} from './AssignRubricActionTypes'

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";



export const GetApplyRubricCategory =
    () =>
        async (dispatch: Dispatch<AssignRubricDispatchTypes | SetSnackBarI>) => {
            try {
                dispatch({
                    type: GET_APPLY_RUBRIC_CATEGORY_LOADING,
                });

                const res = await axios.get(
                    `${ADMIN_API_URL}/apply-rubric-staff/`,
                    HeaderConfig()
                );

                dispatch({
                    type: GET_APPLY_RUBRIC_CATEGORY_SUCCESS,
                    payload: res.data,
                });
            } catch (error) {
                dispatch({
                    type: GET_APPLY_RUBRIC_CATEGORY_ERROR,
                });
            }
        };

export const ApplyRubricCategory =
    (data: ApplyPayloadI) =>
        async (dispatch: Dispatch<AssignRubricDispatchTypes | SetSnackBarI>) => {
            try {
                dispatch({
                    type: APPLY_RUBRIC_CATEGORY_LOADING,
                });

                const res = await axios.post(
                    `${ADMIN_API_URL}/apply-rubric-staff/`,
                    data,
                    HeaderConfig()
                );

                dispatch({
                    type: APPLY_RUBRIC_CATEGORY_SUCCESS,
                    payload: res.data,
                });

                dispatch({
                    type: SET_SNACKBAR,
                    payload: {
                        snackbarOpen: true,
                        snackbarType: "success",
                        snackbarMessage: "Rubric Category Assigned Successfully",
                    },
                });
            } catch (error: any) {
                dispatch({
                    type: APPLY_RUBRIC_CATEGORY_ERROR,
                    payload: error.response && error.response.data,
                });

                dispatch({
                    type: SET_SNACKBAR,
                    payload: {
                        snackbarOpen: true,
                        snackbarType: "error",
                        snackbarMessage: "Rubric Category Assign Failed",
                    },
                });
            }
        };