import {
  GET_EXAM_GRADE_LOADING,
  GET_EXAM_GRADE_ERROR,
  GET_EXAM_GRADE_SUCCESS,
  GradeDispatchTypes,
  ADD_EXAM_GRADE_LOADING,
  ADD_EXAM_GRADE_ERROR,
  ADD_EXAM_GRADE_SUCCESS,
  DELETE_EXAM_GRADE_LOADING,
  DELETE_EXAM_GRADE_ERROR,
  DELETE_EXAM_GRADE_SUCCESS,
  UPDATE_EXAM_GRADE_LOADING,
  UPDATE_EXAM_GRADE_ERROR,
  UPDATE_EXAM_GRADE_SUCCESS,
  GradeTypeI,
} from "../../actions/Examination/Grade/GradeActionTypes";

interface InitialStateI {
  loading: boolean;
  grades: GradeTypeI[];
  add_or_update: boolean;
}

const initialState: InitialStateI = {
  loading: false,
  grades: [],
  add_or_update: false,
};

const GradeReducer = (
  state: InitialStateI = initialState,
  action: GradeDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_EXAM_GRADE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_EXAM_GRADE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_EXAM_GRADE_SUCCESS:
      return {
        ...state,
        loading: false,
        grades: action.payload,
      };

    case ADD_EXAM_GRADE_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case ADD_EXAM_GRADE_ERROR:
      return {
        ...state,
        loading: false,
        add_or_update: true,
      };

    case ADD_EXAM_GRADE_SUCCESS:
      return {
        loading: false,
        grades: [...state.grades, action.payload],
        add_or_update: true,
      };

    case UPDATE_EXAM_GRADE_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case UPDATE_EXAM_GRADE_ERROR:
      return {
        ...state,
        loading: false,
        add_or_update: true,
      };

    case UPDATE_EXAM_GRADE_SUCCESS:
      const current_data: GradeTypeI[] = state.grades;
      const index: number = current_data.findIndex(
        (element) => element.id === action.payload.id
      );
      current_data[index] = action.payload;

      return {
        loading: false,
        grades: current_data,
        add_or_update: true,
      };

    case DELETE_EXAM_GRADE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_EXAM_GRADE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_EXAM_GRADE_SUCCESS:
      const new_data: GradeTypeI[] = state.grades;
      const item_index = new_data.findIndex(
        (element) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return {
        ...state,
        loading: false,
        grades: new_data,
      };

    default:
      return state;
  }
};

export default GradeReducer;
