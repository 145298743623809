import { combineReducers } from "redux";
import ClassReducer from "./Academics/ClassReducer";
import GradeReducer from "./Academics/GradeReducer";
import SectionReducer from "./Academics/SectionReducer";
import SubjectReducer from "./Academics/SubjectReducer";
import LoginReducer from "./Auth/LoginReducer";
import SessionReducer from "./GeneralSettings/SessionReducer";
import ClassTeacherReducer from "./Academics/ClassTeacherReducer";

import SampleReducer from "./SampleReducer";
import SnackBarReducer from "./SnackBarReducer";
import EmailSettingReducer from "./GeneralSettings/EmailSettingReducer";
import ReportSettingReducer from "./GeneralSettings/ReportSettingReducer";
import SmsConfigReducer from "./GeneralSettings/SmsConfigReducer";
import SubjectGroupReducer from "./Academics/SubjectGroupReducer";
import GeneralInfoReducer from "./GeneralSettings/GeneralInfoReducer";
import TimeTableReducer from "./Academics/TimeTableReducer";

// Student
import StudentHouseReducer from "./Student/StudentHouseReducer";
import StudentProfileReducer from "./Student/StudentProfile/StudentProfileReducer";
import StudentProgressReducer from "./Student/StudentProgress/StudentProgressReducer";

// Human Resource import
import DepartmentReducer from "./HumanResource/DepartmentReducer";
import DesignationReducer from "./HumanResource/DesignationReducer";
import NotificationReducer from "./HumanResource/NotificationReducer";
import StaffReducer from "./HumanResource/StaffReducer";

// Student Module import
import StudentReducer from "./Student/StudentReducer";
import CategoryReducer from "./Student/Category/CategoryReducer";
import GuardianReducer from "./Student/Guardian/GuardianReducer";
import StudentComplainReducer from "./Student/StudentComplain/StudentComplainReducer";

//Guardian Module import
import ExamGradeReducer from "./Examination/GradeReducer";
import ExamReducer from "./Examination/ExamReducer";

// Event Type import
import EventTypeReducer from "./Event/EventTypeReducer";
import EventsReducer from "./Event/EventsReducer";
import ExamScheduleReducer from "./Examination/ExamScheduleReducer";
import MarksReducer from "./Examination/MarksReducer";
import TransportReducer from "./Transport/TransportReducer";
import VehicleReducer from "./Transport/VehicleReducer";
import LeaveTypeReducer from "./HumanResource/LeaveTypeReducer";
import MeetingTypeReducer from "./HumanResource/MeetingTypeReducer";
import MeetingReducer from "./HumanResource/MeetingReducer";
import LeaveReducer from "./HumanResource/LeaveReducer";
import StudentRouteReducer from "./Transport/RoutesModal/StudentRouteReducer";
import ResultReducer from "./Examination/ResultReducer";
import BusUserReducer from "./Transport/BusUserReducer";
import PaymentMethodReducer from "./Fees/PaymentMethodReducer";
import FineReducer from "./Fees/FineReducers";
import DiscountReducer from "./Fees/DiscountReducer";
import FeeReducer from "./Fees/FeeReducer";
import AnnouncementReducer from "./Communication/AnnouncementReducer";
import SmsReducer from "../rtk/features/sms/smsSlices";
import CollectFeesReducer from "./Fees/CollectFeesReducer";
import MarkSheetReducer from "./Examination/MarkSheetReducer";
import DashboardReducer from "./Dashboard/DashboardReducer";
import FeeLedgerReducer from "./Fees/FeeLedgerReducer";
import FeeStatementReducer from "./Fees/FeeStatementReducer";

// Reports reducer import
import IdCardReducer from "./Reports/IdCardReducer";
import SmsTemplateReducer from "./Communication/SmsTemplateReducer";
import DocumentReducer from "./Student/StudentDocument/DocumentReducer";
import StaffProfileReducer from "./HumanResource/StaffProfileReducer";
import StaffDocumentReducer from "./HumanResource/StaffDocumentReducer";
import StaffRolesReducer from "./HumanResource/StaffRoleReducer";

// Teacher Dashboard Reducers
import ExpensesTypeReducer from "./Finance/ExpenseTypeReducer";
import IncomeTypeReducer from "./Finance/IncomeTypeReducer";

// Student Dashboard Reducers
import DatesReducer from "./Dates/DatesReducer";
import EnoteCategoryReducer from "./Academics/EnoteCategoryReducer";
import RubricCategoryReducer from "./Academics/RubricCategoryReducer";
import RubricSubCategoryReducers from "./Academics/RubricSubCategoryReducer";
import AssignRubricReducer from "./Academics/AssignRubricReducer";
import errors from "./errors";

import StudentLeaveReducer from "./Student/StudentLeaveReducer";

import AdminSyllabusReducer from "./Academics/AdminSyllabusReducer";
import AdminLessonPlanReducer from "./Academics/AdminLessonPlanReducer";
import OnlineClassReducer from "./Classroom/OnlineClassReducer";
import AdminEnotesReducer from "./Classroom/EnotesReducer";
import AdminAssignmentReducer from "./Classroom/AdminAssignmentReducer";
import TeacherListReducer from "./Classroom/TeacherListReducer";
import IdCardTemplateReducer from "./Templates/IdCardTemplateReducer";
import AdmitCardTemplateReducer from "./Templates/AdmitCardTemplateReducer";
import MarksheetTemplateReducer from "./Templates/MarksheetTemplateReducer";
import GradeSheetTemplateReducer from "./Templates/GradeSheetTemplateReducer";
import CharacterCertificateTemplateReducer from "./Templates/CharacterCertificateTemplateReducer";
import ScholarshipReducer from "./Scholarship/ScholarshipReducer";
import FeeTypeReducer from "./Fees/FeeTypeReducer";
import AnnouncementTypeReducer from "./Communication/AnnouncementTypeReducer";
import SchoolReducer from "./SuperAdmin/SchoolsReducer";
import AttendanceReducer from "./HumanResource/AttendanceReducer";
import resultLedgerReducer from "./Reports/resultLedger";
import APEreducer from "./Examination/APEreducer";
import PreTimeTableReducer from "./Academics/PreTimeTableReducer";
import TacReducer from "./GeneralSettings/TacReducer";
import MiscFeeTypeReducer from "./Fees/MiscFeeTypeReducer";
import OpeningBalanceReducer from "./Finance/OpeningBalanceReducer";
import DaybookReducer from "./Fees/DaybookReducer";
import DueReportsReducer from "./Fees/DueReports";
import DiscountSummaryReducer from "./Fees/DiscountSummaryReducer";
import FeeIncomeReducer from "./Fees/FeeIncomeReducer";
import VoidReducer from "./Fees/VoidReducer";
import ClassPeriodReducer from "../rtk/features/timetable/periodSlices";
import TimetableReducer from "../rtk/features/timetable/timetableSlices";

import AdmissionSettingsReducer from "../rtk/features/miscellaneous/admissionSettingsSlices";
import InvoiceSettingsReducer from "../rtk/features/miscellaneous/invoiceSlices";
import ReceiptSettingsReducer from "../rtk/features/miscellaneous/receiptSlices";
import RubricSettingsReducer from "../rtk/features/miscellaneous/rubricSettingsSlices";

import PrayerReducer from "../rtk/features/prayer/prayerSlices";
import TermsConditionsReducer from "../rtk/features/termsconditions/termsconditionsSlice";
import StudentImportReducer from "../rtk/features/import/studentImportSlices";
import StaffImportReducer from "../rtk/features/import/staffImportSlices";
import KhaltiReducer from "./Fees/KhaltiReducer";
import RollNumReducer from "../rtk/features/student/studentRollNumSlices";
import FeeDiscountReducer from "../rtk/features/feeDiscount/feeDiscountSlice";
import AppliedFeeReducer from "../rtk/features/appliedFee/appliedFeeSlice";
import WholeDataReducer from "../rtk/features/wholeData/wholeDataSlice";
import GradeSubjectReducer from "../rtk/features/exam/subjectGrade/gradeSubjectSlice";
import ExamSubjectScheduleReducer from "../rtk/features/exam/examSchedule/examScheduleSlice";
import MarksGradingReducer from "../rtk/features/exam/grading/gradingSlice";
import AddMarksReducer from "../rtk/features/exam/addMarks/addMarksSlice";
import IntimationSlipReducer from "./Fees/IntimationSlipReducer";
import LocationReducer from "./Transport/LocationReducer";

import MarksLedgerReducer from "../rtk/features/exam/marksLedger/marksLedgerSlice";
import OptSubjectReducer from "../rtk/features/exam/optSubjects/optSubjectSlice";
import OptSubjectStudentReducer from "../rtk/features/exam/optSubjectStudent/optSubjectStudentSlice";
import sectionTransferReducer from "../rtk/features/student/sectionTransfer/sectionTransferSlice";

import NewClassReducer from "../rtk/features/class/ClassSlice";
import SubjectTeacherReducer from "../rtk/features/subjectTeachers/subjectTeacherSlice";
import HumanResourceReducer from "../rtk/features/humanResource/humanResourceSlice";
import AttendanceDeviceReducer from "../rtk/features/attendanceDevice/attendanceDeviceSlice";

const RootReducer = combineReducers({
  errors: errors,
  sample: SampleReducer,
  snackbar: SnackBarReducer,
  email_setting: EmailSettingReducer,
  report_setting: ReportSettingReducer,
  session: SessionReducer,
  tac: TacReducer,
  sms_config: SmsConfigReducer,
  section: SectionReducer,
  class: ClassReducer,
  grade: GradeReducer,
  subject: SubjectReducer,
  auth: LoginReducer,
  notification: NotificationReducer,
  subject_group: SubjectGroupReducer,
  class_teacher: ClassTeacherReducer,
  month_dates: DatesReducer,
  admin_syllabus: AdminSyllabusReducer,
  admin_lessonPlan: AdminLessonPlanReducer,
  id_card_templates: IdCardTemplateReducer,
  admit_card_templates: AdmitCardTemplateReducer,
  marksheet_templates: MarksheetTemplateReducer,
  gradesheet_templates: GradeSheetTemplateReducer,
  character_certificate_templates: CharacterCertificateTemplateReducer,
  staff_attendance: AttendanceReducer,
  result_ledger: resultLedgerReducer,
  apes: APEreducer,
  //Classroom
  online_classes: OnlineClassReducer,
  admin_enotes: AdminEnotesReducer,
  admin_assignment: AdminAssignmentReducer,
  teacher_lists: TeacherListReducer,

  // Student
  student_leave: StudentLeaveReducer,
  // HumanResource
  humanResource: HumanResourceReducer,
  department: DepartmentReducer,
  designation: DesignationReducer,
  staff: StaffReducer,
  leaves: LeaveReducer,
  leave_type: LeaveTypeReducer,
  meeting_type: MeetingTypeReducer,
  meetings: MeetingReducer,
  staffProfile: StaffProfileReducer,
  staffDocument: StaffDocumentReducer,
  //STUDENT
  general_info: GeneralInfoReducer,
  timeTable: TimeTableReducer,
  pre_timeTable: PreTimeTableReducer,
  student: StudentReducer,
  student_house: StudentHouseReducer,
  student_complaint: StudentComplainReducer,
  students: StudentComplainReducer,
  student_profile: StudentProfileReducer,
  student_progress: StudentProgressReducer,
  student_document: DocumentReducer,
  enote_category: EnoteCategoryReducer,
  rubric_category: RubricCategoryReducer,
  rubric_sub_category: RubricSubCategoryReducers,
  assign_rubric: AssignRubricReducer,

  //GUARDIAN
  guardians: GuardianReducer,
  categories: CategoryReducer,

  exam_grade: ExamGradeReducer,
  exam: ExamReducer,
  exam_schedule: ExamScheduleReducer,
  exam_marks: MarksReducer,
  result: ResultReducer,
  marksheet: MarkSheetReducer,

  // fee type
  payment_method: PaymentMethodReducer,
  fine_type: FineReducer,
  discount_type: DiscountReducer,
  fee: FeeReducer,
  fee_type: FeeTypeReducer,
  misc_fee_type: MiscFeeTypeReducer,
  collect_fees: CollectFeesReducer,
  fee_ledger: FeeLedgerReducer,
  opening_balance: OpeningBalanceReducer,
  day_book: DaybookReducer,
  intimation_slip: IntimationSlipReducer,
  void_report: VoidReducer,
  fee_statement: FeeStatementReducer,
  fee_income: FeeIncomeReducer,
  due_report: DueReportsReducer,
  discount_summary: DiscountSummaryReducer,

  // Reports
  id_card: IdCardReducer,

  // Event Type
  event_type: EventTypeReducer,
  events: EventsReducer,

  //Finance
  expense_type: ExpensesTypeReducer,
  income_type: IncomeTypeReducer,

  transport: TransportReducer,
  location: LocationReducer,
  vehicle: VehicleReducer,
  student_route: StudentRouteReducer,
  bus_user: BusUserReducer,
  announcement: AnnouncementReducer,
  announcement_type: AnnouncementTypeReducer,
  sms: SmsReducer,
  dashboard: DashboardReducer,
  sms_template: SmsTemplateReducer,
  staff_roles: StaffRolesReducer,

  //ScholarShip
  scholarship: ScholarshipReducer,

  /// SUPER ADMIN
  sa_schools: SchoolReducer,

  period: ClassPeriodReducer,
  timetable: TimetableReducer,

  admissionSettings: AdmissionSettingsReducer,
  receiptSettings: ReceiptSettingsReducer,
  invoiceSettings: InvoiceSettingsReducer,
  rubricSettings: RubricSettingsReducer,

  prayer: PrayerReducer,
  termsConditions: TermsConditionsReducer,
  studentImport: StudentImportReducer,
  staffImport: StaffImportReducer,
  khaltiReducer: KhaltiReducer,
  rollNum: RollNumReducer,
  feeDiscount: FeeDiscountReducer,
  appliedFees: AppliedFeeReducer,
  wholeData: WholeDataReducer,
  gradeSubject: GradeSubjectReducer,
  examSubjectSchedule: ExamSubjectScheduleReducer,
  marksGrading: MarksGradingReducer,
  addMarks: AddMarksReducer,
  marksLedger: MarksLedgerReducer,
  optSubject: OptSubjectReducer,
  studentOptSubject: OptSubjectStudentReducer,
  sectionTransfer: sectionTransferReducer,

  newClass: NewClassReducer,
  subjectTeacher: SubjectTeacherReducer,
  attendanceDevice: AttendanceDeviceReducer,
});

export default RootReducer;
