//---------------------------- <START> module imports start ----------------------------------//
import React, { useState, useEffect } from 'react';
import { Grid, Paper, Button, ButtonGroup } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import { landscapeData, potraitData } from './IdCardSampleData';
import TemplateStyles from './TemplateStyles';

// ----------------------------<END> module imports end ----------------------------------//

const BrowseTemplates: React.FC = () => {
  const [landscape, setLandscape] = useState(false);
  const [cardID, setCardID] = useState<number | null>(null);
  const [clicked, setClicked] = useState<boolean>(false);
  const [mode, setMode] = useState<string>('landscape');
  const [studentMode, setStudentMode] = useState<boolean>(false);
  const [staffMode, setStaffMode] = useState<boolean>(false);
  const [landscapeTemplateData, setLandscapeTemplateData] = useState<any>([]);
  const [potraitTemplateData, setPotraitTemplateData] = useState<any>([]);
  const [tempLandscapeTemplateData, setTempLandscapeTemplateData] =
    useState<any>([]);
  const [tempPotraitTemplateData, setTempPotraitTemplateData] = useState<any>(
    []
  );
  const classes = TemplateStyles();
  const history = useHistory();

  useEffect(() => {
    setMode('potrait');
    setLandscapeTemplateData(landscapeData);
    setPotraitTemplateData(potraitData);
    setTempLandscapeTemplateData(landscapeData);
    setTempPotraitTemplateData(potraitData);
  }, []);

  const handlePotraitClicked = () => {
    setLandscape(false);
    setMode('potrait');
  };

  const handleLandscapeClick = () => {
    setLandscape(true);
    setMode('landscape');
  };

  const handleCardSelect = (val: number) => {
    setCardID(val);
    setClicked(true);
  };

  const handleAllClick = () => {
    setStudentMode(false);
    setStaffMode(false);
    setLandscapeTemplateData(landscapeData);
    setPotraitTemplateData(potraitData);
  };

  const handleStudentClick = () => {
    setStudentMode(true);
    setStaffMode(false);
  };

  const handleStaffClick = () => {
    setStudentMode(false);
    setStaffMode(true);
  };

  useEffect(() => {
    if (studentMode) {
      if (landscape) {
        setLandscapeTemplateData(
          tempLandscapeTemplateData.filter(
            (item: any) => item.type === 'Student'
          )
        );
      } else {
        setPotraitTemplateData(
          tempPotraitTemplateData.filter((item: any) => item.type === 'Student')
        );
      }
    } else if (staffMode) {
      if (landscape) {
        setLandscapeTemplateData(
          tempLandscapeTemplateData.filter((item: any) => item.type === 'Staff')
        );
      } else {
        setPotraitTemplateData(
          tempPotraitTemplateData.filter((item: any) => item.type === 'Staff')
        );
      }
    }
  }, [studentMode, staffMode]);

  if (clicked && cardID != null) {
    history.push(`/id-card/${cardID}/${mode}/customize`);
  }

  return (
    <>
      <Paper style={{ margin: '30px', padding: '20px' }}>
        <Grid container spacing={3} justifyContent="space-between">
          <Grid item xs={6} sm={6}>
            <span className={classes.title}>
              Browse ID Card Samples, and Start Customizing
            </span>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Button
              variant="contained"
              onClick={() => history.push('/id-cards-templates')}
            >
              Go Back to My List
            </Button>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Button
            size="small"
            color="primary"
            className={landscape ? classes.smallbtn : classes.smallbtnActive}
            onClick={handlePotraitClicked}
          >
            Potrait
          </Button>
          <Button
            size="small"
            color="primary"
            className={landscape ? classes.smallbtnActive : classes.smallbtn}
            onClick={handleLandscapeClick}
          >
            Landscape
          </Button>
        </Grid>
        <Grid container justifyContent="center" style={{ marginTop: '15px' }}>
          <ButtonGroup aria-label="outlined primary button group">
            <Button
              className={
                !staffMode && !studentMode
                  ? classes.activeTab
                  : classes.nonactiveTab
              }
              onClick={handleAllClick}
            >
              All
            </Button>
            <Button
              className={studentMode ? classes.activeTab : classes.nonactiveTab}
              onClick={handleStudentClick}
            >
              Student
            </Button>
            <Button
              className={staffMode ? classes.activeTab : classes.nonactiveTab}
              onClick={handleStaffClick}
            >
              Staff
            </Button>
          </ButtonGroup>
        </Grid>
        {!landscape ? (
          <>
            <Grid container spacing={3} style={{ marginTop: '10px' }}>
              {potraitTemplateData.map((item: any, index: any) => {
                return (
                  <Grid
                    item
                    xs={4}
                    key={index}
                    onClick={() => handleCardSelect(item.id)}
                  >
                    <Card className={classes.card}>
                      <CardActionArea>
                        <img
                          src={item.thumbnail}
                          style={{ height: '450px', width: '100%' }}
                        />
                      </CardActionArea>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </>
        ) : (
          <Grid container spacing={3} style={{ marginTop: '10px' }}>
            {landscapeTemplateData.map((item: any, index: number) => {
              return (
                <Grid
                  item
                  xs={6}
                  key={index}
                  onClick={() => handleCardSelect(item.id)}
                >
                  <Card className={classes.card}>
                    <CardActionArea>
                      <img
                        src={item.thumbnail}
                        style={{ height: '300px', width: '100%' }}
                      />
                    </CardActionArea>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Paper>
    </>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//
export default BrowseTemplates;
