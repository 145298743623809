export const GET_COLLECT_FEES_LOADING = "GET_COLLECT_FEES_LOADING";
export const GET_COLLECT_FEES_ERROR = "GET_COLLECT_FEES_ERROR";
export const GET_COLLECT_FEES_SUCCESS = "GET_COLLECT_FEES_SUCCESS";

export const GET_GENERATE_BILL_LOADING = "GET_GENERATE_BILL_LOADING";
export const GET_GENERATE_BILL_ERROR = "GET_GENERATE_BILL_ERROR";
export const GET_GENERATE_BILL_SUCCESS = "GET_GENERATE_BILL_SUCCESS";

export const GET_FEE_INVOICE_LOADING = "GET_FEE_INVOICE_LOADING";
export const GET_FEE_INVOICE_SUCCESS = "GET_FEE_INVOICE_SUCCESS";
export const GET_FEE_INVOICE_ERROR = "GET_FEE_INVOICE_ERROR";

export const COLLECT_FEES_LOADING = "COLLECT_FEES_LOADING";
export const COLLECT_FEES_SUCCESS = "COLLECT_FEES_SUCCESS";
export const COLLECT_FEES_ERROR = "COLLECT_FEES_ERROR";

export const GET_INVOICE_RECORD_LOADING = "GET_INVOICE_RECORD_LOADING";
export const GET_INVOICE_RECORD_SUCCESS = "GET_INVOICE_RECORD_SUCCESS";
export const GET_INVOICE_RECORD_ERROR = "GET_INVOICE_RECORD_ERROR";

export const GET_STATEMENT_DETAILS_LOADING = "GET_STATEMENT_DETAILS_LOADING";
export const GET_STATEMENT_DETAILS_SUCCESS = "GET_STATEMENT_DETAILS_SUCCESS";
export const GET_STATEMENT_DETAILS_ERROR = "GET_STATEMENT_DETAILS_ERROR";

export const UPDATE_PAID_INVOICE_LOADING = "UPDATE_PAID_INVOICE_LOADING";
export const UPDATE_PAID_INVOICE_SUCCESS = "UPDATE_PAID_INVOICE_SUCCESS";
export const UPDATE_PAID_INVOICE_ERROR = "UPDATE_PAID_INVOICE_ERROR";

export const GET_INVOICE_CHANGES_LOG_LOADING =
  "GET_INVOICE_CHANGES_LOG_LOADING";

export const GET_INVOICE_CHANGES_LOG_SUCCESS =
  "GET_INVOICE_CHANGES_LOG_SUCCESS";

export const GET_INVOICE_CHANGES_LOG_ERROR = "GET_INVOICE_CHANGES_LOG_ERROR";

export const CLOSE_INVOICE_PRINT = "CLOSE_INVOICE_PRINT";

export interface ApplicableScholarshipI {
  id: string;
  fee_type__id: string;
  scholarship_title: string;
  scholarship_in: string;
  applicable_scholarship: number;
}
export interface CollectFeesRequestI {
  invoice: string;
  paid_amount: number;
  fines: string[];
  applied_scholarship: string[];
  discounts: string[];
}

export interface CollectFeesReceiptDataI {
  date: string;
  fee_title: string;
  paid_amount: number;
  amount: number;
  discount: number;
  fine: number;
  scholarship: number;
}
export interface InvoiceI {
  id: string;
  invoice_id: string;
  student__grade__id: string;
  fee_type__due_date: string;
  fee_type__due_day: string;
  fee_type__due_period_type: string;
  month: string;
  amount: number;
}
export interface FeeInvoiceI {
  fee_type_id: string;
  fee_name: string;
  is_monthly: boolean;
  amount: number;
  student: string;
  invoices: InvoiceI[];
  applicable_scholarships: ApplicableScholarshipI[];
}

export interface InvoiceStatementI {
  id: string;
  receipt_id: string;
  invoice: string;
  invoice__id: string;
  fee_type: string;
  month: string | null;
  payment_method: string | null;
  payment_method_name: string | null;
  amount_to_pay: number;
  paid_amount: number;
  due_amount: number;
  applied_scholarship: null;
  invoice_scholarship_applied: any[];
  fine: string | null;
  fine_info: string | null;
  advance_amount: number;
  discount_amount: number;
  created_on: string;
}
export interface InvoiceRecordI {
  total_invoice_amount: number;
  total_paid_amount: number;
  total_due_amount: number;
  total_advance_amount: number;
  financial_clear_due: number;
  financial_clear_advance: number;
  statement: InvoiceStatementI[];
}

export interface InvoiceDiscountAppliedI {
  id: string;
  discount: {
    id: string;
    name: string;
    discount_in: string;
    discount_applicable: string;
  };
}

export interface InvoiceFineAppliedI {
  id: string;
  fine: {
    id: string;
    name: string;
    fine_in: string;
    fine_applicable: string;
  };
}

export interface InvoiceScholarshipAppliedI {
  scholarship: {
    id: string;
    scholarship_title: string;
    fee_type: string;
    fee_type_detail: {
      id: string;
      name: string;
      is_monthly: boolean;
    };
    scholarship_type: string;
    scholarship_in: string;
    applicable_scholarship: number;
    description: string;
  };
}

export interface StatementDetailsI {
  id: string;
  invoice: string;
  month: string | null;
  receipt_id: string;
  fee_type: string;
  payment_method: string;
  paid_amount: number;
  due_amount: number;
  advance_amount: number;
  amount_to_pay: number;
  invoice_discount_applied: InvoiceDiscountAppliedI[];
  invoice_fine_applied: InvoiceFineAppliedI[];
  invoice_scholarship_applied: InvoiceScholarshipAppliedI[];
  created_on: string;
}

export interface InvoiceChangeLogI {
  id: string;
  previous_amount: number;
  updated_amount: number;
  created_on: string;
  fee_type: string;
  updated_by: string;
}

export interface CollectFeesPayloadI {
  advance_amount: number;
  applied_scholarship: number | null;
  created_on: string;
  discounts: number | null;
  due_amount: number | null;
  fines: number | null;
  id: string;
  invoice: string;
  invoice_discount_applied: any[];
  invoice_fine_applied: any[];
  invoice_scholarship_applied: any[];
  month: any;
  paid_amount: number;
  payment_method: string;
  receipt_id: string;
}

//===============================<START>GET STUDENT LEAVE<START>======================//

export interface GetCollectFeesLoading {
  type: typeof GET_COLLECT_FEES_LOADING;
}

export interface GetCollectFeesError {
  type: typeof GET_COLLECT_FEES_ERROR;
}

export interface GetCollectFeesSuccess {
  type: typeof GET_COLLECT_FEES_SUCCESS;
  payload: any;
}

//===============================<END>GET STUDENT LEAVE<END>======================//

export interface GetGenerateBillLoading {
  type: typeof GET_GENERATE_BILL_LOADING;
}

export interface GetGenerateBillError {
  type: typeof GET_GENERATE_BILL_ERROR;
}

export interface GetGenerateBillSuccess {
  type: typeof GET_GENERATE_BILL_SUCCESS;
  payload: any;
}

// Fee Invoices
export interface GetFeeInvoiceLoading {
  type: typeof GET_FEE_INVOICE_LOADING;
}

export interface GetFeeInvoiceError {
  type: typeof GET_FEE_INVOICE_ERROR;
}

export interface GetFeeInvoiceSuccess {
  type: typeof GET_FEE_INVOICE_SUCCESS;
  payload: any;
}
////////////////////////////////////////////

export interface CollectFeeLoading {
  type: typeof COLLECT_FEES_LOADING;
}

export interface CollectFeeSuccess {
  type: typeof COLLECT_FEES_SUCCESS;
  payload: CollectFeesPayloadI;
}

export interface CollectFeeError {
  type: typeof COLLECT_FEES_ERROR;
}

export interface GetInvoiceRecordLoading {
  type: typeof GET_INVOICE_RECORD_LOADING;
}

export interface GetInvoiceRecordSuccess {
  type: typeof GET_INVOICE_RECORD_SUCCESS;
  payload: InvoiceRecordI;
}

export interface GetInvoiceRecordError {
  type: typeof GET_INVOICE_RECORD_ERROR;
}

export interface GetStatementDetailsLoading {
  type: typeof GET_STATEMENT_DETAILS_LOADING;
}

export interface GetStatementDetailsSuccess {
  type: typeof GET_STATEMENT_DETAILS_SUCCESS;
  payload: StatementDetailsI;
}

export interface GetStatementDetailsError {
  type: typeof GET_STATEMENT_DETAILS_ERROR;
}

export interface UpdatePaidInvoiceLoading {
  type: typeof UPDATE_PAID_INVOICE_LOADING;
}

export interface UpdatePaidInvoiceSuccess {
  type: typeof UPDATE_PAID_INVOICE_SUCCESS;
}

export interface UpdatePaidInvoiceError {
  type: typeof UPDATE_PAID_INVOICE_ERROR;
}

export interface GetInvoiceChangesLogLoading {
  type: typeof GET_INVOICE_CHANGES_LOG_LOADING;
}

export interface GetInvoiceChangesLogSuccess {
  type: typeof GET_INVOICE_CHANGES_LOG_SUCCESS;
  payload: InvoiceChangeLogI[];
}

export interface GetInvoiceChangesLogError {
  type: typeof GET_INVOICE_CHANGES_LOG_ERROR;
}

export interface CloseInvoicePrint {
  type: typeof CLOSE_INVOICE_PRINT;
}

export type CollectFeesDispatchTypes =
  | GetCollectFeesLoading
  | GetCollectFeesError
  | GetCollectFeesSuccess
  | GetGenerateBillLoading
  | GetGenerateBillError
  | GetGenerateBillSuccess
  | GetFeeInvoiceLoading
  | GetFeeInvoiceError
  | GetFeeInvoiceSuccess
  | CollectFeeLoading
  | CollectFeeSuccess
  | CollectFeeError
  | GetInvoiceRecordLoading
  | GetInvoiceRecordSuccess
  | GetInvoiceRecordError
  | GetStatementDetailsLoading
  | GetStatementDetailsSuccess
  | GetStatementDetailsError
  | UpdatePaidInvoiceLoading
  | UpdatePaidInvoiceSuccess
  | UpdatePaidInvoiceError
  | GetInvoiceChangesLogLoading
  | GetInvoiceChangesLogSuccess
  | GetInvoiceChangesLogError
  | CloseInvoicePrint;
