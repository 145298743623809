import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import checkBrands from "../../config/checkBrands";
import { footerRoot } from "../Styles/ReusableStyles";

const useStyles = makeStyles({
  root: {
    position: "absolute",
    marginTop: "40px",
    zIndex: 10,
    ...footerRoot,
  },
});

export default function Footer() {
  const classes = useStyles();
  const curYear = new Date().getFullYear();

  return (
    <div className={classes.root}>
      <Typography align="center" variant="caption" display="block" gutterBottom>
        {curYear}{" "}
        {checkBrands("nakshatraacademy")
          ? "Nakshatra Academy"
          : "Hamro Academy"}
        . All rights reserved
      </Typography>
    </div>
  );
}
