//---------------------------<START> import modules starts-----------------------//
import React, { useEffect, useRef, useState } from 'react';
import { Grid, Paper } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useFormStyles } from '../../Styles/FormStyles';
import { useDispatch, useSelector } from 'react-redux';
import { AddMeeting } from '../../../actions/HumanResource/Meeting/MeetingAction';
import { RootStore } from '../../../store';
import { GetMeetingTypes } from '../../../actions/HumanResource/MeetingType/MeetingTypeAction';
import CustomizedTextField from '../../Reusable/Inputs/TextField';
import CustomizedNepaliDatePicker from '../../Reusable/Inputs/NepaliDatePicker';
import CustomizedSelect from '../../Reusable/Inputs/Select';
import { dateConverterBsToAd } from '../../utils/dateConverter';
import { FormSendButon } from '../../Reusable/Buttons/FormButton';
import { Tupple } from '../../../actions';
import { CUR_NEPALI_DATE } from '../../utils/nepaliDateUtils';
import { isObjectEmpty } from '../../utils/utils';
import { Alert } from '@material-ui/lab';
import firstWordCapital from '../../utils/firstWordCapital';
import formsValidationCheckup from '../../utils/formsValidationCheckUp';
//-------------------------<END> import modules ends ----------------------------//

//--------------------------<START> Add Annoucement Modal components starts---------------------------------------------------//
const MeetingRequestModal = (props: any) => {
  const classes = useFormStyles();
  const dispatch = useDispatch();

  const [date, setDate] = useState<string>(CUR_NEPALI_DATE);
  const [meetingType, setMeetingType] = useState<Tupple | null>(null);
  const [serverErrors, setServerErrors] = useState<any>(null);
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);

  useEffect(() => {
    dispatch(GetMeetingTypes());
  }, []);

  const meetingTypeState = useSelector(
    (state: RootStore) => state.meeting_type
  );
  const meetingState = useSelector((state: RootStore) => state.meetings);
  const errorsData = meetingState.errors;
  const loading = meetingState.loading;
  const initialErrorsData = useRef(errorsData);

  const MEETING_TYPE_CHOICES = meetingTypeState.meeting_types.map((item) => ({
    key: item.id.toString(),
    value: item.name,
  }));

  //-------------------<START> React hooks forms starts ----------------------//
  const { handleSubmit, register, errors, setError, watch, clearErrors } =
    useForm<any>();

  /* Close modal when user has submitted form, if state is not loading,
  has not server errors and has no hook-form errors */
  useEffect(() => {
    if (hasSubmitted && !loading) {
      if (!errorsData && isObjectEmpty(errors)) {
        props.onClose();
      }
    }
  }, [meetingState, hasSubmitted]);

  // Set hook-form error on server error
  useEffect(() => {
    if (initialErrorsData.current === errorsData) {
      initialErrorsData.current = errorsData; // Do not set initial errors
    } else {
      if (errorsData != null && errorsData?.error) {
        const keys = Object.keys(errorsData.error);
        keys.map((elem: any) => {
          setError(elem, {
            type: 'serverSideError',
            message: errorsData.error[elem] && errorsData.error[elem]['error'],
          });
        });
      }
      setServerErrors(errorsData);
    }
  }, [errorsData]);

  const onSubmit = (data: any) => {
    if (data && date && meetingType) {
      const meetingData = {
        meeting_type: meetingType.key,
        meet_date: dateConverterBsToAd(date),
        meet_time: data.time,
        description: data.description,
      };
      dispatch(AddMeeting(meetingData));
      setHasSubmitted(true);
    }
  };

  const handleDate = (value: any) => {
    setDate(value?.toString());
    clearErrors('meet_date');
    clearErrors('error');
  };

  console.log('watch', watch());

  //------------------------<END> React Hooks form ends -------------------------//
  return (
    <Paper elevation={0}>
      <div className={classes.modalContent}>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          {serverErrors &&
            serverErrors?.error &&
            Object.keys(serverErrors.error)
              .filter((elem: any) => elem === 'error')
              .map((elem: any, index: number) => {
                return (
                  <div className={classes.serversidemessages} key={index}>
                    <Alert severity="error">
                      {firstWordCapital(serverErrors?.error[elem][0])}
                    </Alert>
                  </div>
                );
              })}

          <Grid container>
            <Grid item xs={12} className={classes.formWrapper}>
              <CustomizedSelect
                label="Meeting Type"
                name="meeting_type"
                options={MEETING_TYPE_CHOICES}
                value={meetingType}
                setValue={setMeetingType}
                error={errors['leave_type'] && true}
                inputRef={register({ required: true })}
              />
              {formsValidationCheckup({
                errors: errors,
                inputName: 'meeting_type',
                requiredMessage: 'Meeting type must be selected',
                serverSideMessage:
                  errors.meeting_type?.type === 'serverSideError' &&
                  errors.meeting_type.message,
              })}
            </Grid>

            <Grid container spacing={1} item xs={12}>
              <Grid item xs={6} className={classes.formWrapper}>
                <CustomizedNepaliDatePicker
                  label="Meeting Date"
                  value={date}
                  setValue={handleDate}
                  name="meet_date"
                />

                {formsValidationCheckup({
                  errors: errors,
                  inputName: 'meet_date',
                  requiredMessage: 'Meeting date is Required',
                  serverSideMessage:
                    errors.meet_date?.type === 'serverSideError' &&
                    errors.meet_date.message,
                })}
              </Grid>

              <Grid item xs={6} className={classes.formWrapper}>
                <CustomizedTextField
                  label="Time"
                  type="time"
                  name="time"
                  error={errors['time'] && true}
                  inputRef={register({ required: true })}
                />

                {formsValidationCheckup({
                  errors: errors,
                  inputName: 'time',
                  requiredMessage: 'Meeting time is Required',
                  serverSideMessage:
                    errors.time?.type === 'serverSideError' &&
                    errors.time.message,
                })}
              </Grid>
            </Grid>

            <Grid item xs={12} className={classes.formWrapper}>
              <CustomizedTextField
                label="Description"
                placeholder="Label"
                name="description"
                required
                multiline
                rows={4}
                error={errors['description'] && true}
                inputRef={register({ required: true })}
              />

              {formsValidationCheckup({
                errors: errors,
                inputName: 'description',
                requiredMessage: 'Meeting description is Required.',
                serverSideMessage:
                  errors.description?.type === 'serverSideError' &&
                  errors.description.message,
              })}
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent="flex-end"
            style={{ paddingBottom: '8px' }}
          >
            <FormSendButon />
          </Grid>
        </form>
      </div>
    </Paper>
  );
};
//---------------------------<END> Add Annoucement Modal components end ---------------------------//
export default MeetingRequestModal;
