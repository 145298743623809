import {
  Button,
  Checkbox,
  Grid,
  InputLabel,
  Paper,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import styled from "styled-components";

import { useFormStyles } from "../../Styles/FormStyles";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { Search } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { GetClasses } from "../../../actions/Academics/Class/ClassAction";
import { RootStore } from "../../../store";
import { GetCategory } from "../../../actions/Student/StudentCategory/CategoryAction";
import { GetFeeTypeList } from "../../../actions/Fees/FeeType/FeeTypeAction";
import { ClassTypeI } from "../../../actions/Academics/Class/ClassActionTypes";
import { SectionTypeI } from "../../../actions/Academics/Section/SectionActionTypes";
import { CategoryTypeI } from "../../../actions/Student/StudentCategory/CategoryActionTypes";
import { setSnackbar } from "../../../actions/SnackbarAction";
import {
  getFeeStudents,
  getStudentFees,
} from "../../../rtk/features/feeDiscount/feeDiscountApi";
import { resetState } from "../../../rtk/features/appliedFee/appliedFeeSlice";
import { StudentPayloadI } from "../../../rtk/features/wholeData/wholeData";
import { fetchStudentList } from "../../../rtk/features/wholeData/wholeDataApi";
import { setSearchParams } from "../../../rtk/features/appliedFee/appliedFeeSlice";

const Wrapper = styled(Paper)`
  background-color: #fff;
  padding: 16px;
  margin: 10px;
`;

const SearchButton = styled(Button)`
  margin: 5px;
  width: 120px;
`;

interface PropsI {
  applyDiscountTo: (value: string) => void;
}

interface FeeHeadI {
  id: string;
  name: string;
  fee_type: "0" | "M";
}

interface MonthsI {
  id: string;
  name: string;
}

const Months: MonthsI[] = [
  // { id: "0", name: "Whole Year" },
  { id: "1", name: "Baisakh" },
  { id: "2", name: "Jestha" },
  { id: "3", name: "Asar" },
  { id: "4", name: "Shrawan" },
  { id: "5", name: "Bhadra" },
  { id: "6", name: "Asoj" },
  { id: "7", name: "Kartik" },
  { id: "8", name: "Mangsir" },
  { id: "9", name: "Poush" },
  { id: "10", name: "Magh" },
  { id: "11", name: "Falgun" },
  { id: "12", name: "Chaitra" },
];

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SearchAppliedFee: React.FC<PropsI> = ({ applyDiscountTo }) => {
  const classes = useFormStyles();
  const dispatch = useDispatch();
  const [applyTo, setApplyTo] = React.useState<string>("fee");

  const [selectedClass, setSelectedClass] = useState<ClassTypeI | null>(null);

  const [sectionChoice, setSectionChoice] = useState<SectionTypeI[]>([]);
  const [selectedSection, setSelectedSection] = useState<SectionTypeI | null>(
    null
  );
  const [sectionDisabled, setSectionDisabled] = useState<boolean>(true);

  const [selectedStudentCategory, setSelectedStudentCategory] =
    useState<CategoryTypeI | null>(null);

  const [selectedFeeHead, setSelectedFeeHead] = useState<FeeHeadI | null>(null);

  const [selectedMonth, setSelectedMonth] = useState<MonthsI | null>(null);
  const [monthDisabled, setMonthDisabled] = useState<boolean>(true);

  //   Redux Store Selector
  const ClassSelector = useSelector((state: RootStore) => state.class);
  const StudentCategorySelector = useSelector(
    (state: RootStore) => state.categories
  );

  const [studentChoices, setStudentChoices] = useState<StudentPayloadI[]>([]);
  const [selectedStudent, setSelectedStudent] =
    useState<StudentPayloadI | null>(null);

  const FeeSelector = useSelector((state: RootStore) => state.fee_type);
  const WholeData = useSelector((state: RootStore) => state.wholeData);
  /////////////////////////////////////////////////////////////////////

  useEffect(() => {
    dispatch(GetClasses());
    dispatch(GetCategory());
    dispatch(GetFeeTypeList());
  }, [dispatch]);

  useEffect(() => {
    setStudentChoices(WholeData.students);
  }, [WholeData]);

  useEffect(() => {
    applyDiscountTo(applyTo);
    setSelectedClass(null);
    setSelectedSection(null);
    setSectionDisabled(true);
    setSelectedFeeHead(null);
    setSelectedMonth(null);
    setMonthDisabled(true);
    setSelectedStudent(null);
    setStudentChoices([]);
    dispatch(resetState());
  }, [applyTo]);

  useEffect(() => {
    if (selectedClass) {
      dispatch(
        fetchStudentList({
          grade: selectedClass.grade,
          section: selectedSection?.id || "",
          category: selectedStudentCategory?.id || "",
        })
      );
    }
  }, [selectedClass, selectedSection, selectedStudentCategory]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setApplyTo((event.target as HTMLInputElement).value);
  };

  const handleClassChange = (value: ClassTypeI | null) => {
    setSelectedClass(value);
    setSelectedStudent(null);
    setSelectedSection(null);

    if (value) {
      if (value.section.length) {
        setSectionChoice(value.section);
        setSectionDisabled(false);
      } else {
        setSectionChoice([]);
        setSectionDisabled(true);
      }
    } else {
      setStudentChoices([]);
      setSectionChoice([]);
      setSectionDisabled(true);
    }
  };

  const handleSectionChange = (value: SectionTypeI | null) => {
    setSelectedSection(value);
  };

  const handleFeeHeadChange = (value: FeeHeadI | null) => {
    setSelectedFeeHead(value);

    if (value) {
      if (value.fee_type === "M") {
        setMonthDisabled(false);
      } else {
        setSelectedMonth(null);
        setMonthDisabled(true);
      }
    } else {
      setSelectedMonth(null);
      setMonthDisabled(true);
    }
  };

  const handleSearch = () => {
    if (applyTo === "fee") {
      if (!selectedClass) {
        dispatch(setSnackbar(true, "warning", "Select grade"));
        return;
      }
      if (selectedFeeHead) {
        if (selectedFeeHead.fee_type === "M") {
          if (!selectedMonth) {
            dispatch(setSnackbar(true, "warning", "Select Month"));
            return;
          }
        }
      } else {
        dispatch(setSnackbar(true, "warning", "Select fee head"));
        return;
      }
      dispatch(
        setSearchParams({
          fee_type: selectedFeeHead.id,
          month: selectedMonth,
        })
      );
      dispatch(
        getFeeStudents({
          fee_head: selectedFeeHead.id,
          month: selectedMonth?.id || "",
          grade: selectedClass.grade,
          section: selectedSection?.id || "",
          student_category: selectedStudentCategory?.id || "",
          applied: true,
        })
      );
    } else {
      if (selectedStudent) {
        dispatch(getStudentFees({ student: selectedStudent.id }));
      } else {
        dispatch(setSnackbar(true, "warning", "Select Student"));
      }
    }
  };

  return (
    <>
      <Wrapper>
        <Grid container>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Search By</FormLabel>
              <RadioGroup
                aria-label="apply_to"
                name="apply_to"
                value={applyTo}
                onChange={handleChange}
                row
              >
                <FormControlLabel value="fee" control={<Radio />} label="Fee" />
                <FormControlLabel
                  value="student"
                  control={<Radio />}
                  label="Student"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              className={classes.formWrapper}
            >
              <InputLabel>Class</InputLabel>
              <Autocomplete
                value={selectedClass}
                onChange={(event: React.ChangeEvent<{}>, value) =>
                  handleClassChange(value)
                }
                options={ClassSelector.classes}
                getOptionLabel={(option) => option.grade_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="select_message"
                    placeholder="Select Class"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              className={classes.formWrapper}
            >
              <InputLabel>Section</InputLabel>
              <Autocomplete
                value={selectedSection}
                onChange={(event: React.ChangeEvent<{}>, value) =>
                  handleSectionChange(value)
                }
                options={sectionChoice}
                getOptionLabel={(option) => option.name}
                disabled={sectionDisabled}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="select_message"
                    placeholder="Select Section"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              className={classes.formWrapper}
            >
              <InputLabel>Student Category</InputLabel>
              <Autocomplete
                value={selectedStudentCategory}
                onChange={(event: React.ChangeEvent<{}>, value) =>
                  setSelectedStudentCategory(value)
                }
                options={StudentCategorySelector.categories}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="select_message"
                    placeholder="Select Student Category"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </Grid>
          {applyTo === "fee" ? (
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                className={classes.formWrapper}
              >
                <InputLabel>Fee Head</InputLabel>
                <Autocomplete
                  value={selectedFeeHead}
                  onChange={(event: React.ChangeEvent<{}>, value) =>
                    handleFeeHeadChange(value)
                  }
                  options={FeeSelector.feeTypeList}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="select_message"
                      placeholder="Select Fee Head"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                className={classes.formWrapper}
              >
                <InputLabel>Applied Months</InputLabel>
                <Autocomplete
                  value={selectedMonth}
                  onChange={(event: React.ChangeEvent<{}>, value) =>
                    setSelectedMonth(value)
                  }
                  options={Months}
                  getOptionLabel={(option) => option.name}
                  disabled={monthDisabled}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Choose Months"
                    />
                  )}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                className={classes.formWrapper}
              >
                <InputLabel>Student</InputLabel>
                <Autocomplete
                  value={selectedStudent}
                  onChange={(event: React.ChangeEvent<{}>, value) =>
                    setSelectedStudent(value)
                  }
                  options={studentChoices}
                  getOptionLabel={(option) =>
                    `${option.student.student_user.first_name} ${option.student.student_user.last_name}`
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="select_message"
                      placeholder="Select Student"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            </Grid>
          )}

          <Grid container spacing={1}>
            <SearchButton
              color="primary"
              variant="contained"
              startIcon={<Search />}
              onClick={() => handleSearch()}
            >
              Search
            </SearchButton>
          </Grid>
        </Grid>
      </Wrapper>
    </>
  );
};

export default SearchAppliedFee;
