import React, { useState } from "react";
import { SettingSidebar, GeneralInfo } from "../../components";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const GeneralSetting = () => {
  const nav = <SettingSidebar generalInfo />;
  return (
    <SubModuleLayout sideNav={nav}>
      <GeneralInfo />
    </SubModuleLayout>
  );
};

export default GeneralSetting;
