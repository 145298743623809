import React from "react";
import { ReportSideBar } from "../../../../components";
import BrowseMarksheet from "../../../../components/Reports/Templates/Marksheet/BrowseMarksheet";
import SubModuleLayout from "../../../../components/Reusable/Layouts/SubModuleLayout";

const BrowseMarksheetPage = () => {
  const nav = <ReportSideBar marksheet_template />;
  return (
    <SubModuleLayout reportMenuActive sideNav={nav}>
      <BrowseMarksheet />
    </SubModuleLayout>
  );
};

export default BrowseMarksheetPage;
