// ---------------------------- <START> module imports start ----------------------------------//
import React, { useState, useEffect } from "react";
import { Grid, Typography, Paper, Tooltip, Box } from "@material-ui/core";
import { Redirect, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { useTableStyles } from "../../Styles/TableStyles";
import { useTableWithPagination } from "../../Reusable";
import DisableDialog from "../../Reusable/Dialogs/DisableDialog";
import getFullName from "../../utils/getFullName";
import { RootStore } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import {
  GetStudentList,
  GetStudentListWithPagination,
  DisableStudent,
  GetPaginatedStudent,
} from "../../../actions/Student/Student/StudentAction";
import { NoteAdd } from "@material-ui/icons";
import {
  ItemEditButton,
  ItemViewButton,
  ItemDisableButton,
} from "../../Reusable/Buttons/TableButton";
import { GetGuardianAll } from "../../../actions/Student/Guardian/GuardianAction";
import checkImageUrl from "../../utils/checkImageUrl";
import defaultPlaceholder from "../../../assets/images/defaultPlaceholder";
import UserAvatar from "../../Reusable/Profile/UserAvatar";
import CustomizedSelect from "../../Reusable/Inputs/Select";
import { paginationChoices, Tupple } from "../../../common/json.constant";
import { templateSettings } from "lodash";
// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//
interface TableDataI {
  id: string;
  student_photo: string;
  student_name: string;
  adm_no: string;
  class: string;
  roll_no: string;
  phone_no?: string;
  guardian: string;
  guardian_no: string;
  occupation?: string;
  guardian_image: string;
}

interface HeadCellsI {
  id: string;
  label: string;
  filter?: boolean;
}

// ---------------------------- <END> interface ends ----------------------------------//
// ---------------------------- <START> Table Headings Data ----------------------------------//

// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const LedgerTable: React.FC = () => {
  const classes = useTableStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  // States declaration
  const [tableData, setTableData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [academicYearForPagination, setAcademicYearForPagination] =
    useState<string>("");
  const [classNameForPagination, setClassNameForPagination] =
    useState<string>("");
  const [sectionForPagination, setSectionForPagination] = useState<string>("");
  const [studentUpdateClicked, setStudentUpdateClicked] = useState(false);
  const [studentViewClicked, setStudentViewClicked] = useState(false);
  const [studentID, setStudentID] = useState<string | null>(null);
  const [selectedPagination, setSelectedPagination] = useState<any | null>({
    key: "10",
    value: "10",
  });
  const [headCells, setHeadCells] = useState([]);

  // Retrieving re-usable components from useTable
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTableWithPagination(headCells);

  // Modal States
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);

  const sessionLoading = useSelector(
    (state: RootStore) => state.student.loading
  );

  const studentListData = useSelector((state: RootStore) => state.student);
  const ledgerData = useSelector(
    (state: RootStore) => state.result_ledger?.result_ledger
  );

  useEffect(() => {
    dispatch(GetStudentListWithPagination(1));
    dispatch(GetGuardianAll(1));
  }, []);

  useEffect(() => {
    if (selectedPagination != null) {
      dispatch(
        GetPaginatedStudent(
          1,
          Number(selectedPagination.key),
          classNameForPagination,
          sectionForPagination
        )
      );
    }
  }, [selectedPagination]);

  useEffect(() => {
    setLoading(sessionLoading);
  }, [sessionLoading]);

  const getClassName = (grade: string, section: string) => {
    if (section != null) {
      return grade + "(" + section + ")";
    } else {
      return grade;
    }
  };

  useEffect(() => {
    console.log(ledgerData);
    const data: any = [];
    ledgerData?.student_marks?.map((elem: any, index: number) => {
      data.push({
        key: index + 1,
        id: elem.id,
        index: index,
        student_name: getFullName(
          elem.student_first_name,
          elem.student_last_name
        ),
        obtain_subject_marks: elem.obtain_subject_marks,
        total_marks_obtained: elem.total_marks_obtained,
      });
    });
    setTableData(data);
    const temp: any = [
      { id: "sn", label: "S.N" },
      {
        id: "student_name",
        label: "Student Name",
        filter: true,
      },
    ];
    ledgerData?.exam_subjects?.map((elem: any, index: number) => {
      temp.push({ id: elem.id, label: elem.subject__name });
    });
    temp.push({ id: "total_marks", label: "Total Marks", filter: true });
    setHeadCells(temp);
  }, [ledgerData]);

  const checkSubjectMark = (item: any, jIndex: number) => {
    const subjectID =
      ledgerData?.exam_subjects &&
      ledgerData?.exam_subjects[jIndex]?.subject__name;

    const subjectData = item?.obtain_subject_marks?.find(
      (elem: any) => elem.exam_subject__subject__name === subjectID
    );
    console.log(subjectData);

    return subjectData?.total_marks_obtained || "-";
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const handleDeleteModal = (value: boolean, id: string) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    // confirmed && console.log("item_id: ", itemId);
    // dispatch(DisableStudent(itemId));
    setDeleteModalOpen(false);
  };

  const handleUpdateView = (id: string) => {
    setStudentID(id);
    setStudentUpdateClicked(true);
  };

  const handleStudentView = (id: string) => {
    setStudentID(id);
    setStudentViewClicked(true);
  };

  const handlePaginationChange = (paginationIndex: number) => {
    dispatch(
      GetPaginatedStudent(
        paginationIndex,
        selectedPagination?.key,
        classNameForPagination,
        sectionForPagination
      )
    );
  };

  if (studentUpdateClicked) {
    return <Redirect to={`/student/edit/${studentID}`} />;
  }

  if (studentViewClicked) {
    return <Redirect to={`/student/profile/${studentID}`} />;
  }

  const getCustomTableRow = (item: any, index: 0) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {item.index + 1}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.student_name}
        </StyledTableCell>
        {ledgerData?.exam_subjects?.map((elem: any, jIndex: number) => {
          return (
            <StyledTableCell
              align="center"
              className={classes.cell}
              key={jIndex}
            >
              {/* {item.obtain_subject_marks[jIndex]?.marks_obtained_theory} */}
              {checkSubjectMark(item, jIndex)}
            </StyledTableCell>
          );
        })}
        <StyledTableCell align="center" className={classes.cell}>
          {item.total_marks_obtained}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  const handleImport = () => {
    history.push("/import-student-info");
  };
  const handleSendSms = () => {
    history.push("/student-credentials");
  };

  return (
    <Paper className={classes.rootTableBox}>
      <TableContainer
        label="Result"
        loading={loading}
        items={tableData}
        headCells={headCells}
        getCustomTableRow={getCustomTableRow}
        search_name="student_name"
        forPagination={tableData}
        handlePaginationChange={handlePaginationChange}
        disableSearch
        disablePagination
      />
    </Paper>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default LedgerTable;
