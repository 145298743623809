import {
  GET_FEE_TYPE_ERROR,
  GET_FEE_TYPE_LOADING,
  GET_FEE_TYPE_SUCCESS,
  GET_ALL_FEE_TYPE_ERROR,
  GET_ALL_FEE_TYPE_LOADING,
  GET_ALL_FEE_TYPE_SUCCESS,
  CREATE_FEE_TYPE_SUCCESS,
  CREATE_FEE_TYPE_LOADING,
  CREATE_FEE_TYPE_ERROR,
  UPDATE_FEE_TYPE_SUCCESS,
  UPDATE_FEE_TYPE_LOADING,
  UPDATE_FEE_TYPE_ERROR,
  DELETE_FEE_TYPE_SUCCESS,
  DELETE_FEE_TYPE_LOADING,
  DELETE_FEE_TYPE_ERROR,
  FeeTypeDispatchTypes,
  Fee_TypeI,
} from "../../actions/Fees/FeeType/FeeTypeActionTypes";

interface InitialStateI {
  loading: boolean;
  stLoading: boolean;
  feeType: any;
  stFee: Array<Object>;
  errors: any;
  recent?: boolean;
  action_performed: boolean;
  add_or_update: boolean;
  feeTypeList: {
    id: string;
    name: string;
    fee_type: "M" | "0";
  }[];
}

const initialState: InitialStateI = {
  loading: false,
  stLoading: false,
  feeType: [],
  stFee: [],
  errors: null,
  recent: false,
  action_performed: false,
  add_or_update: false,
  feeTypeList: [],
};

const FeeTypeReducer = (
  state: InitialStateI = initialState,
  action: FeeTypeDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_FEE_TYPE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_FEE_TYPE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_FEE_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        feeType: action.payload,
        errors: null,
      };

    case GET_ALL_FEE_TYPE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_FEE_TYPE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_ALL_FEE_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        feeTypeList: action.payload.results,
        errors: null,
      };

    case CREATE_FEE_TYPE_LOADING:
      return {
        ...state,
        loading: true,
        action_performed: false,
        add_or_update: false,
      };

    case CREATE_FEE_TYPE_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        action_performed: false,
        add_or_update: true,
      };

    case CREATE_FEE_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        feeType: [...state.feeType.results, action.payload],
        errors: null,
        recent: true,
        action_performed: true,
        add_or_update: true,
      };

    case UPDATE_FEE_TYPE_LOADING:
      return {
        ...state,
        loading: true,
        action_performed: false,
        add_or_update: false,
      };

    case UPDATE_FEE_TYPE_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        action_performed: false,
        add_or_update: true,
      };

    case UPDATE_FEE_TYPE_SUCCESS:
      const current_data: any = state.feeType.results;
      const index: number = current_data.findIndex(
        (element: any) => element.id === action.payload.id
      );
      current_data[index] = action.payload;

      return {
        ...state,
        loading: false,
        feeType: current_data,
        errors: null,
        recent: true,
        action_performed: true,
        add_or_update: true,
      };

    case DELETE_FEE_TYPE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_FEE_TYPE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_FEE_TYPE_SUCCESS:
      const new_data: any = state.feeType;
      const item_index = new_data.results.findIndex(
        (element: any) => element.id === action.payload.id
      );
      new_data.results.splice(item_index, 1);

      return { ...state, loading: false, feeType: new_data };

    default:
      return state;
  }
};

export default FeeTypeReducer;
