import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setSnackbar } from "../../../actions/SnackbarAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

export const getClassSection = createAsyncThunk(
  "class/getClassSection",
  async (unknown, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.get(`${ADMIN_API_URL}/add-class-section-grade/`);

      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(null);
    }
  }
);

export const getClassSectionByGrade = createAsyncThunk(
  "class/getClassSectionByGrade",
  async (grade: string, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.get(
        `${ADMIN_API_URL}/add-class-section-grade/${grade}`
      );

      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      if (error.response.data.detail === "Not found.") {
        return rejectWithValue({ newData: true, grade });
      }
      return rejectWithValue(null);
    }
  }
);

export const createClassSection = createAsyncThunk(
  "class/createClassSection",
  async (
    postData: {
      grade: string;
      sections: {
        name: string;
      }[];
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.post(
        `${ADMIN_API_URL}/add-class-section-grade/`,
        postData
      );

      dispatch(setSnackbar(true, "success", "Class created successfully."));
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      dispatch(setSnackbar(true, "warning", "Failed to create class."));
      return rejectWithValue(null);
    }
  }
);

export const updateClassSection = createAsyncThunk(
  "class/updateClassSection",
  async (
    postData: {
      grade: string;
      sections: {
        id?: string;
        name: string;
      }[];
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.put(
        `${ADMIN_API_URL}/add-class-section-grade/`,
        postData
      );

      dispatch(setSnackbar(true, "success", "Class updated successfully."));
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      dispatch(setSnackbar(true, "warning", "Failed to update class."));
      return rejectWithValue(null);
    }
  }
);
