//---------------------------<START> import modules starts-----------------------//
import React, { useEffect, useRef, useState } from 'react';
import { Grid, Paper } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useFormStyles } from '../../Styles/FormStyles';
import { useDispatch, useSelector } from 'react-redux';
import { AddLeave } from '../../../actions/HumanResource/Leave/LeaveAction';
import { RootStore } from '../../../store';
import { GetLeaveTypes } from '../../../actions/HumanResource/LeaveType/LeaveTypeAction';
import CustomizedTextField from '../../Reusable/Inputs/TextField';
import CustomizedNepaliDatePicker from '../../Reusable/Inputs/NepaliDatePicker';
import CustomizedSelect from '../../Reusable/Inputs/Select';
import { dateConverterBsToAd } from '../../utils/dateConverter';
import { FormSendButon } from '../../Reusable/Buttons/FormButton';
import { Tupple } from '../../../actions';
import { isObjectEmpty } from '../../utils/utils';
import { CUR_NEPALI_DATE } from '../../utils/nepaliDateUtils';
import { Alert } from '@material-ui/lab';
import firstWordCapital from '../../utils/firstWordCapital';
import formsValidationCheckup from '../../utils/formsValidationCheckUp';
//-------------------------<END> import modules ends ----------------------------//

//--------------------------<START> Add Annoucement Modal components starts---------------------------------------------------//
const LeaveRequestModal = (props: any) => {
  const classes = useFormStyles();
  const dispatch = useDispatch();

  const [dateFrom, setDateFrom] = useState<string>(CUR_NEPALI_DATE);
  const [dateTo, setDateTo] = useState<string>(CUR_NEPALI_DATE);
  const [leaveType, setLeaveType] = useState<Tupple | null>(null);
  const [serverErrors, setServerErrors] = useState<any>(null);
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);

  useEffect(() => {
    dispatch(GetLeaveTypes());
  }, []);

  const leaveTypeState = useSelector((state: RootStore) => state.leave_type);
  const leaveState = useSelector((state: RootStore) => state.leaves);
  const errorsData = leaveState.errors;
  const loading = leaveState.loading;
  const initialErrorsData = useRef(errorsData);

  const LEAVE_TYPE_CHOICES = leaveTypeState.leave_types.map((item) => ({
    key: item.id.toString(),
    value: item.name,
  }));

  //-------------------<START> React hooks forms starts ----------------------//
  const { handleSubmit, register, errors, setError, clearErrors } =
    useForm<any>();

  /* Close modal when user has submitted form, if state is not loading,
  has not server errors and has no hook-form errors */
  useEffect(() => {
    if (hasSubmitted && !loading) {
      if (!errorsData && isObjectEmpty(errors)) {
        props.onClose();
      }
    }
  }, [leaveState, hasSubmitted]);

  // Set hook-form error on server error
  useEffect(() => {
    if (initialErrorsData.current === errorsData) {
      initialErrorsData.current = errorsData; // Do not set initial errors
    } else {
      if (errorsData != null && errorsData?.error) {
        const keys = Object.keys(errorsData.error);
        keys.map((elem: any) => {
          setError(elem, {
            type: 'serverSideError',
            message: errorsData.error[elem] && errorsData.error[elem],
          });
        });
      }
      setServerErrors(errorsData);
    }
  }, [errorsData]);

  const onSubmit = (data: any) => {
    if (data && dateFrom && dateTo && leaveType) {
      const leaveData = {
        leave_type: leaveType.key,
        leave_from: dateConverterBsToAd(dateFrom),
        leave_to: dateConverterBsToAd(dateTo),
        reason_for_leave: data.reason_for_leave,
      };

      dispatch(AddLeave(leaveData));
      setHasSubmitted(true);
    }
  };

  const handleDateFrom = (value: any) => {
    setDateFrom(value?.toString());
    clearErrors('leave_from');
    clearErrors('error');
  };

  const handleDateTo = (value: any) => {
    setDateTo(value?.toString());
    clearErrors('leave_to');
    clearErrors('error');
  };

  //------------------------<END> React Hooks form ends -------------------------//
  return (
    <Paper elevation={0}>
      <div className={classes.modalContent}>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          {serverErrors &&
            serverErrors?.error &&
            Object.keys(serverErrors.error)
              .filter((elem: any) => elem === 'error')
              .map((elem: any, index: number) => {
                return (
                  <div className={classes.serversidemessages} key={index}>
                    <Alert severity="error">
                      {firstWordCapital(serverErrors?.error[elem][0])}
                    </Alert>
                  </div>
                );
              })}

          <Grid container>
            <Grid container>
              <Grid item xs={6} className={classes.formWrapper}>
                <CustomizedSelect
                  label="Leave Type"
                  name="leave_type"
                  options={LEAVE_TYPE_CHOICES}
                  value={leaveType}
                  setValue={setLeaveType}
                  error={errors['leave_type'] && true}
                  inputRef={register({ required: true })}
                  required
                />

                {formsValidationCheckup({
                  errors: errors,
                  inputName: 'leave_type',
                  requiredMessage: 'Leave type must be selected',
                  serverSideMessage:
                    errors.leave_type?.type === 'serverSideError' &&
                    errors.leave_type.message,
                })}
              </Grid>
            </Grid>

            <Grid container spacing={1} item xs={12}>
              <Grid item xs={6} className={classes.formWrapper}>
                <CustomizedNepaliDatePicker
                  label="Leave from Date"
                  value={dateFrom}
                  setValue={handleDateFrom}
                  name="leave_from"
                  required
                />

                {formsValidationCheckup({
                  errors: errors,
                  inputName: 'leave_from',
                  requiredMessage: 'Leave from date is Required',
                  serverSideMessage:
                    errors.leave_from?.type === 'serverSideError' &&
                    errors.leave_from.message,
                })}
              </Grid>

              <Grid item xs={6} className={classes.formWrapper}>
                <CustomizedNepaliDatePicker
                  label="Leave to Date"
                  value={dateTo}
                  setValue={handleDateTo}
                  name="leave_to"
                  required
                />
                {formsValidationCheckup({
                  errors: errors,
                  inputName: 'leave_to',
                  requiredMessage: 'Leave to date is Required',
                  serverSideMessage:
                    errors.leave_to?.type === 'serverSideError' &&
                    errors.leave_to.message,
                })}
              </Grid>
            </Grid>

            <Grid item xs={12} className={classes.formWrapper}>
              <CustomizedTextField
                label="Reason for Leave"
                placeholder="Label"
                name="reason_for_leave"
                required
                multiline
                rows={4}
                error={errors['reason_for_leave'] && true}
                inputRef={register({ required: true })}
              />

              {formsValidationCheckup({
                errors: errors,
                inputName: 'leave_type',
                requiredMessage: 'Required Field.',
                serverSideMessage:
                  errors.leave_type?.type === 'serverSideError' &&
                  errors.leave_type.message,
              })}
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent="flex-end"
            style={{ paddingBottom: '8px' }}
          >
            <FormSendButon />
          </Grid>
        </form>
      </div>
    </Paper>
  );
};
//---------------------------<END> Add Annoucement Modal components end ---------------------------//
export default LeaveRequestModal;
