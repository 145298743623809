import { Box, Button, Divider, Grid, Typography } from '@material-ui/core';
import { Clear, Done } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { PRIMARY_DATE_FORMAT } from '../../../common/constant';
import StaffCard, {
  StaffCardTypeI,
} from '../../Reusable/Dialogs/StaffCard.Modal';
import { CUR_NEPALI_DATE } from '../../utils/nepaliDateUtils';
import CustomizedTextField from '../../Reusable/Inputs/TextField';
import { useStyles } from '../../Styles/FormStyles';
import CustomizedNepaliDatePicker from '../../Reusable/Inputs/NepaliDatePicker';
import { useForm } from 'react-hook-form';

const MeetingRequestView = (props: any) => {
  const classes = useStyles();
  const { onActionConfirmed: handleDataSubmit, items } = props;

  const { handleSubmit, register, errors, setError, clearErrors } = useForm();

  const [remarksActive, setRemarksActive] = useState(false);
  const [adminRemarks, setAdminRemarks] = useState('');
  const [adminResponse, setAdminResponse] = useState<string>('');
  const [isMeetingLatest, setIsMeetingLatest] = useState(true);
  const [meetDate, setMeetDate] = useState(CUR_NEPALI_DATE);
  const [staff, setStaff] = useState<StaffCardTypeI | null>(null);

  const handleClick = (status: string) => {
    // props.onActionConfirmed(true, status);
    setRemarksActive(true);
    setAdminResponse(status);
  };

  useEffect(() => {
    const item: StaffCardTypeI = {
      name: props.meeting.name,
      avatar: '',
      id: props.meeting.staff_id,
      designation: props.meeting.designation || <em>N/A</em>,
    };

    setStaff(item);
  }, []);

  useEffect(() => {
    const difference = moment(props.meeting.applied_date).diff(
      CUR_NEPALI_DATE,
      'days'
    );
    console.log('difference', difference);
    setIsMeetingLatest(difference >= 0);
  }, []);

  const handleClose = () => {
    setRemarksActive(false);
    setAdminRemarks('');
    setAdminResponse('');
  };

  const handleContinue = () => {
    handleDataSubmit(true, adminResponse);
  };

  interface MeetFormTypeI {
    venue?: string;
    date?: string;
    time?: string;
    remarks: string;
  }

  const onSubmit = (data: MeetFormTypeI) => {
    console.log('data', data);

    if (adminResponse === 'A') {
      handleContinue();
    } else {
      handleContinue();
    }
  };

  return (
    <React.Fragment>
      {staff && <StaffCard staff={staff} />}
      <Divider style={{ marginBottom: '20px' }} />
      <Grid container style={{ marginBottom: '10px' }}>
        <Grid item xs={4}>
          Meeting Type:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {props.meeting.meeting_type}
          </Typography>
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: '10px' }}>
        <Grid item xs={4}>
          Apply Date:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {moment(props.meeting.applied_date).format(PRIMARY_DATE_FORMAT)}
          </Typography>
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: '10px' }}>
        <Grid item xs={4}>
          Meeting Date:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
            {moment(props.meeting.meet_date).format(PRIMARY_DATE_FORMAT)}
          </Typography>
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: '10px' }}>
        <Grid item xs={4}>
          Description:
        </Grid>

        <Grid item xs={8}>
          {props.meeting.description || 'No Description'}
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: '10px' }}>
        <Grid item xs={4}>
          Remarks:
        </Grid>

        <Grid item xs={8}>
          Please join the meeting on time with above information. Thank you!
        </Grid>
      </Grid>

      {props.meeting.status === 'P' && isMeetingLatest === false && (
        <Typography style={{ color: 'red', padding: '8px 0' }}>
          *You are late to submit this meeting request.
        </Typography>
      )}

      {props.meeting.status === 'P' && remarksActive && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            {adminResponse === 'A' && (
              <>
                <Grid item xs={12}>
                  <Box className={classes.formWrapper}>
                    <CustomizedTextField
                      label="Venue"
                      placeholder="Enter Venue"
                      variant="outlined"
                      name="vanue"
                      inputRef={register({ required: true })}
                    />
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <Box className={classes.formWrapper}>
                    <CustomizedNepaliDatePicker
                      label="Meeting Date"
                      name="date"
                      setValue={setMeetDate}
                      value={meetDate}
                      required
                    />
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <CustomizedTextField
                    label="Meeting Time"
                    type="time"
                    inputRef={register({ required: true })}
                    name="time"
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <Box className={classes.formWrapper}>
                <CustomizedTextField
                  label="Remarks"
                  placeholder={`Please add remarks before ${
                    adminResponse === 'A' ? 'accepting' : 'declining'
                  }`}
                  variant="outlined"
                  name="remarks"
                  multiline
                  rows={2}
                  inputRef={register({ required: true })}
                />
              </Box>
            </Grid>
          </Grid>
        </form>
      )}

      {remarksActive && (
        <Grid
          container
          spacing={2}
          style={{ marginTop: '15px', marginBottom: '5px' }}
        >
          <Grid item style={{ marginLeft: 'auto' }}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Clear />}
              style={{ backgroundColor: '#E96C5A' }}
              onClick={handleClose}
            >
              Close
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Done />}
              style={{ backgroundColor: '#3FC581' }}
              onClick={handleSubmit(onSubmit)}
              type="submit"
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      )}

      {props.meeting.status === 'P' && remarksActive === false && (
        <Grid
          container
          spacing={2}
          style={{ marginTop: '15px', marginBottom: '5px' }}
        >
          <Grid item style={{ marginLeft: 'auto' }}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Clear />}
              style={{ backgroundColor: '#E96C5A' }}
              onClick={() => handleClick('D')}
            >
              {isMeetingLatest ? 'Declined' : 'Decline Anyway'}
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Done />}
              style={{ backgroundColor: '#3FC581' }}
              onClick={() => handleClick('A')}
            >
              {isMeetingLatest ? 'Approved' : 'Approve Anyway'}
            </Button>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default MeetingRequestView;
