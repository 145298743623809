import React, { useEffect, useState } from 'react';
import { Typography, Paper } from '@material-ui/core';
import { useTableStyles } from '../../Styles/TableStyles';
import { useTable } from '../../Reusable';
import CustomizedDialogs from '../../Reusable/Dialogs/DeleteDialog';
import Popups from '../../Reusable/Dialogs/Popups';
import RoutesModal from './RoutesModal/RoutesModal';
import { TransportTypeI } from '../../../actions/Transport/Routes/TransportActionTypes';
import { useDispatch, useSelector } from 'react-redux';
import {
  DeleteTransport,
  GetTransport,
} from '../../../actions/Transport/Routes/TransportAction';
import { RootStore } from '../../../store';
import {
  ItemAddButton,
  ItemDeleteButton,
  ItemEditButton,
  ItemFormList,
} from '../../Reusable/Buttons/TableButton';
import BusStopModal from './RoutesModal/BusStopModal';

interface PropsI {
  onEditData: (value: TransportTypeI) => void;
  edit: { check: boolean; count: number };
}

interface HeadCellsI {
  id: string;
  label: string;
  align?: 'left' | 'right' | 'center';
}

const headCells: HeadCellsI[] = [
  { id: 'routes_name', label: 'Route Name', align: 'left' },
  { id: 'driver_name', label: 'Driver', align: 'left' },
  // { id: "assistant_name", label: "Assistant" },
  // { id: "pickup", label: "Pickup" },
  // { id: "drop", label: "Drop" },
  { id: 'student', label: 'Student(s)', align: 'left' },
  { id: 'bus_no', label: 'Bus No.', align: 'right' },
  // { id: "fare", label: "Fare" },
  { id: 'action', label: 'Action' },
];

const RoutesList = (props: PropsI) => {
  const { onEditData, edit } = props;

  const classes = useTableStyles();

  // States declaration
  const [tableData, setTableData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [routeId, setrouteId] = useState<string>('');

  const [SeatCapacity, setSeatCapacity] = useState<number | null>();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [busStopPopup, setBusStopPopup] = useState(false);

  // Retrieving re-usable components from useTable
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  // Function for handling action icons click event
  const handleAddId = (id: string, seat: number) => {
    setOpenPopup(true);
    setrouteId(id);
    setSeatCapacity(seat);
  };

  // Modal States
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string>('');

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const handleDeleteModal = (value: boolean, id: string) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteTransport(itemId));
    setDeleteModalOpen(false);
  };
  // reusable popup

  const handleClose = (value: boolean) => {
    setOpenPopup(value);
  };

  const handleEditClicked = (data: TransportTypeI) => {
    setEditMode(true);
    onEditData(data);
  };

  const dispatch = useDispatch();
  const transportSelector = useSelector((state: RootStore) => state.transport);

  console.log('transportSelector', transportSelector, tableData);

  useEffect(() => {
    dispatch(GetTransport());
  }, []);

  useEffect(() => {
    setEditMode(edit?.check);
  }, [edit]);
  console.log(transportSelector);

  useEffect(() => {
    const data = transportSelector.transport?.results;

    setLoading(transportSelector.loading);
    setTableData(data);
  }, [transportSelector]);

  const handleBusStopClose = () => {
    setBusStopPopup(false);
  };

  const getCustomTableRow = (item: any) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="left" className={classes.cell}>
          {item.route_name}
        </StyledTableCell>
        <StyledTableCell align="left" className={classes.cell}>
          {item.driver_detail?.full_name || '-'}
        </StyledTableCell>
        <StyledTableCell align="left" className={classes.cell}>
          {item.student_count}
        </StyledTableCell>
        <StyledTableCell align="right" className={classes.cell}>
          {item.bus_number || '-'}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ItemEditButton
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleEditClicked(item)
            }
          />

          <ItemDeleteButton
            disabled={editMode}
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleDeleteModal(true, item.id)
            }
          />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  const handlePaginationChange = (page: number) => {
    // const params = { page, perPage: 5 };
    // dispatch(GetLocation());
  };

  if (transportSelector.transport === null) {
    return <></>;
  }

  console.log('transportSelector', transportSelector);

  return (
    <>
      <Paper className={classes.root}>
        <Typography variant="h6">Routes List</Typography>

        <TableContainer
          label="Route List"
          loading={loading}
          items={tableData}
          headCells={headCells}
          forPagination={transportSelector.transport}
          handlePaginationChange={handlePaginationChange}
          getCustomTableRow={getCustomTableRow}
          search_name="name"
        />
      </Paper>
      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
        dialogTitle="Are you sure to delete this record?"
        okText="Delete"
      />
      <Popups
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        onClose={handleClose}
        // fullWidth
        maxWidth="md"
        title="Student List"
        colored
      >
        <RoutesModal SeatCapacity={SeatCapacity} routeId={routeId} />
      </Popups>

      <Popups
        openPopup={busStopPopup}
        setOpenPopup={setBusStopPopup}
        onClose={handleBusStopClose}
        maxWidth="md"
        title="Bus Stops"
        colored
      >
        <BusStopModal SeatCapacity={SeatCapacity} routeId={routeId} />
      </Popups>
    </>
  );
};

export default RoutesList;
