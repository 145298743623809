import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_ANNOUNCEMENT_LOADING,
  GET_ANNOUNCEMENT_ERROR,
  GET_ANNOUNCEMENT_SUCCESS,
  AnnouncementDispatchTypes,
  ADD_ANNOUNCEMENT_LOADING,
  ADD_ANNOUNCEMENT_SUCCESS,
  ADD_ANNOUNCEMENT_ERROR,
  DELETE_ANNOUNCEMENT_SUCCESS,
  DELETE_ANNOUNCEMENT_ERROR,
  DELETE_ANNOUNCEMENT_LOADING,
  UPDATE_ANNOUNCEMENT_LOADING,
  UPDATE_ANNOUNCEMENT_SUCCESS,
  UPDATE_ANNOUNCEMENT_ERROR,
  GET_ANNOUNCEMENT_DRAFT_ERROR,
  GET_ANNOUNCEMENT_DRAFT_SUCCESS,
  GET_ANNOUNCEMENT_DRAFT_LOADING,
  GET_ANNOUNCEMENT_DRAFT_BY_ID_ERROR,
  GET_ANNOUNCEMENT_DRAFT_BY_ID_SUCCESS,
  GET_ANNOUNCEMENT_DRAFT_BY_ID_LOADING,
  SAVE_ANNOUNCEMENT_DRAFT_ERROR,
  SAVE_ANNOUNCEMENT_DRAFT_SUCCESS,
  SAVE_ANNOUNCEMENT_DRAFT_LOADING,
  UPDATE_ANNOUNCEMENT_DRAFT_ERROR,
  UPDATE_ANNOUNCEMENT_DRAFT_SUCCESS,
  UPDATE_ANNOUNCEMENT_DRAFT_LOADING,
} from "./AnnouncementActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

interface RequestDataI {
  priority: string;
  title: string;
  announcement_date: string;
  description: string;
  group: string[];
  user: string[];
  grade: string | null;
  section: string[];
}

export const GetAnnouncements =
  (from_date: string, to_date: string, filter_by: string | null) =>
  async (dispatch: Dispatch<AnnouncementDispatchTypes | SetSnackBarI>) => {
    try {
      const url = `${ADMIN_API_URL}/announcement/?draft=false&from_date=${from_date}&to_date=${to_date}&filter_group=${filter_by}`;

      dispatch({
        type: GET_ANNOUNCEMENT_LOADING,
      });

      const res = await axios.get(url, HeaderConfig());

      dispatch({
        type: GET_ANNOUNCEMENT_SUCCESS,
        payload: res.data,
      });
    } catch (error: any) {
      dispatch({
        type: GET_ANNOUNCEMENT_ERROR,
      });
      try {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: error.response.statusText,
          },
        });
      } catch (error) {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Server Error",
          },
        });
      }
    }
  };

export const GetAnnouncementDrafts =
  () =>
  async (dispatch: Dispatch<AnnouncementDispatchTypes | SetSnackBarI>) => {
    try {
      const url = `${ADMIN_API_URL}/announcement/?draft=true`;

      dispatch({
        type: GET_ANNOUNCEMENT_DRAFT_LOADING,
      });

      const res = await axios.get(url, HeaderConfig());

      dispatch({
        type: GET_ANNOUNCEMENT_DRAFT_SUCCESS,
        payload: res.data,
      });
    } catch (error: any) {
      dispatch({
        type: GET_ANNOUNCEMENT_DRAFT_ERROR,
      });
      try {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: error.response.statusText,
          },
        });
      } catch (error) {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Server Error",
          },
        });
      }
    }
  };

export const GetAnnouncementDraftById =
  (id: string) =>
  async (dispatch: Dispatch<AnnouncementDispatchTypes | SetSnackBarI>) => {
    try {
      const url = `${ADMIN_API_URL}/announcement/${id}/?draft=true`;

      dispatch({
        type: GET_ANNOUNCEMENT_DRAFT_BY_ID_LOADING,
      });

      const res = await axios.get(url, HeaderConfig());

      dispatch({
        type: GET_ANNOUNCEMENT_DRAFT_BY_ID_SUCCESS,
        payload: res.data,
      });
    } catch (error: any) {
      dispatch({
        type: GET_ANNOUNCEMENT_DRAFT_BY_ID_ERROR,
      });
      try {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: error.response.statusText,
          },
        });
      } catch (error) {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Server Error",
          },
        });
      }
    }
  };

export const SaveAnnouncementDraft =
  (data: any) =>
  async (dispatch: Dispatch<AnnouncementDispatchTypes | SetSnackBarI>) => {
    try {
      const url = `${ADMIN_API_URL}/announcement/?draft=true`;

      dispatch({
        type: SAVE_ANNOUNCEMENT_DRAFT_LOADING,
      });

      const res = await axios.post(url, data, HeaderConfig());

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Draft Saved",
        },
      });

      dispatch({
        type: SAVE_ANNOUNCEMENT_DRAFT_SUCCESS,
        payload: res.data,
      });
    } catch (error: any) {
      dispatch({
        type: SAVE_ANNOUNCEMENT_DRAFT_ERROR,
      });
      try {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: error.response.statusText,
          },
        });
      } catch (error) {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Server Error",
          },
        });
      }
    }
  };

export const UpdateAnnouncementDraft =
  (id: string, data: any, draft = true) =>
  async (dispatch: Dispatch<AnnouncementDispatchTypes | SetSnackBarI>) => {
    try {
      const url = `${ADMIN_API_URL}/announcement/${id}/?draft=${draft}`;

      dispatch({
        type: UPDATE_ANNOUNCEMENT_DRAFT_LOADING,
      });

      const res = await axios.put(url, data, HeaderConfig());

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Draft Updated",
        },
      });

      dispatch({
        type: UPDATE_ANNOUNCEMENT_DRAFT_SUCCESS,
        payload: res.data,
      });
    } catch (error: any) {
      dispatch({
        type: UPDATE_ANNOUNCEMENT_DRAFT_ERROR,
      });
      try {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: error.response.statusText,
          },
        });
      } catch (error) {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Server Error",
          },
        });
      }
    }
  };

export const AddAnnouncement =
  (data: any) =>
  async (dispatch: Dispatch<AnnouncementDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_ANNOUNCEMENT_LOADING,
      });

      await axios.post(`${ADMIN_API_URL}/announcement/`, data, HeaderConfig());

      dispatch({
        type: ADD_ANNOUNCEMENT_SUCCESS,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Announcement Created Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: ADD_ANNOUNCEMENT_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Failed To Create Announcement",
        },
      });
    }
  };

export const UpdateAnnouncement =
  (id: number, data: RequestDataI) =>
  async (dispatch: Dispatch<AnnouncementDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_ANNOUNCEMENT_LOADING,
      });

      const res = await axios.put(
        `${ADMIN_API_URL}/announcement/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_ANNOUNCEMENT_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Announcement Updated Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: UPDATE_ANNOUNCEMENT_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Announcement Update Failed",
        },
      });
    }
  };

export const DeleteAnnouncement =
  (id: number) =>
  async (dispatch: Dispatch<AnnouncementDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_ANNOUNCEMENT_LOADING,
      });

      const res = await axios.delete(
        `${ADMIN_API_URL}/announcement/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_ANNOUNCEMENT_SUCCESS,
        payload: { id: id },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Announcement Deleted Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_ANNOUNCEMENT_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Announcement Delete Failed",
        },
      });
    }
  };
