import {
    Backdrop,
    Button,
    CircularProgress,
    Grid,
    makeStyles,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";
import { ApplyFeeToStudents } from "../../actions/Fees/FeeType/FeeAction";
import { ApplyScholarShip } from "../../actions/ScholarShipType/ScholarshipAction";
import { setSnackbar } from "../../actions/SnackbarAction";
import { FinanceSidebar, ScholarshipSidebar } from "../../components";
import {
    StudentSearchForm,
    FeeCategorySelect,
    MonthSelect,
    StudentSelectTable,
} from "../../components/index";
import BackDropLoader from "../../components/Reusable/BackDropLoader";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";
import ScholarshipListSelect from "../../components/Scholarship/ApplyScholarShip/ScholarshipListSelect";
import ScholarshipStudentTable from "../../components/Scholarship/ApplyScholarShip/ScholarshipStudentTable";
import {
    ActionRoleAccess,
    PageRoleAccess,
} from "../../components/utils/rolesConfig";
import { RootStore } from "../../store";

interface SelectedFeesI {
    id: string;
    title: string;
    isMonthly: boolean;
}

export default function ScholarshipApplyPage() {
    const [fees, setFees] = useState<any[]>([]);
    const [feesScholarship, setFeesScholarship] = useState<any[]>([]);

    const [months, setMonths] = useState<string[]>([]);
    const [students, setStudents] = useState<string[]>([]);
    const [feeHeads, setFeeHeads] = useState<string>('');

    const [loading, setLoading] = useState<boolean>(false);
    const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
    const [selected, setSelected] = React.useState<string[]>([]);
    const [selectedStudent, setSelectedStudent] = React.useState<string[]>([]);

    const [grade, setGrade] = useState<any | null>(null);
    const [section, setSection] = useState<any | null>(null);

    const dispatch = useDispatch();

    const gradeLoading = useSelector((state: RootStore) => state.class.loading);
    const feeLoading = useSelector((state: RootStore) => state.fee_type.loading);
    const studentLoading = useSelector(
        (state: RootStore) => state.student.loading
    );
    const feeTypeSelector = useSelector((state: RootStore) => state.fee_type?.feeType);

    const authState = useSelector((state: RootStore) => state.auth);

    useEffect(() => {
        if (!gradeLoading && !feeLoading && !studentLoading) {
            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [gradeLoading, feeLoading, studentLoading]);

    const nav = <ScholarshipSidebar apply_scholarship />;

    const handleFeeChange = (fees: any[]) => {
        setFees([...fees]);
    };

    const handleMonthChange = (ids: string[]) => {
        setMonths([...ids]);
    };

    const handleStudentChange = (ids: string[]) => {
        setStudents([...ids]);
    };

    const reset = () => {
        setMonths([])
        setSelected([])
        setSelectedStudent([])
        setGrade(null)
        setSection(null)
        setFeeHeads('')
    }

    const handleApplyFees = () => {
        const selected_fee_head = feeTypeSelector?.results?.find((elem: any) => elem.id === feeHeads)
        if (selected_fee_head?.billing_period?.length > 0 && months.length === 0) {
            dispatch(setSnackbar(true, "warning", "You've selected monthly fee. So select at least one month"));
        }
        else if (fees.length && students.length) {
            setIsBtnLoading(true)
            const post_data: any[] = [];

            fees.map((fee: any) => {
                post_data.push(fee.id);
            });

            const finalValues = {
                scholarships: post_data,
                students,
                fee_heads: [feeHeads],
                applied_months: months?.length > 0 ? months : [],
            }
            dispatch(ApplyScholarShip(finalValues, reset));
        }
        else {
            dispatch(setSnackbar(true, "warning", "Select Student or fee or scholarship"));
        }
    }


    return (
        <SubModuleLayout sideNav={nav}>
            <Grid container>
                <ScholarshipStudentTable
                    selectedStudent={selectedStudent}
                    setSelectedStudent={setSelectedStudent}
                    onStudentChange={handleStudentChange}
                    handleApplyFees={handleApplyFees}
                    grade={grade}
                    setGrade={setGrade}
                    section={section}
                    setSection={setSection}
                    selected={selected}
                    setSelected={setSelected}
                    handleFeeChange={handleFeeChange}
                    setFeesScholarship={setFeesScholarship}
                    setFeeHeads={setFeeHeads}
                    feeHeads={feeHeads}
                    setMonths={setMonths}
                    months={months}
                    setIsBtnLoading={setIsBtnLoading}
                    isBtnLoading={isBtnLoading}
                />
            </Grid>
            <BackDropLoader open={loading} />
        </SubModuleLayout>
    );
}
