import {
  GET_STAFF_LOADING,
  GET_STAFF_ERROR,
  GET_STAFF_SUCCESS,
  GET_DISABLED_STAFF_LOADING,
  GET_DISABLED_STAFF_ERROR,
  GET_DISABLED_STAFF_SUCCESS,
  STAFF_BY_ID_LOADING,
  STAFF_BY_ID_ERROR,
  STAFF_BY_ID_SUCCESS,
  GET_TEACHERS_LOADING,
  GET_TEACHERS_ERROR,
  GET_TEACHERS_SUCCESS,
  GET_ALL_TEACHERS_LOADING,
  GET_ALL_TEACHERS_ERROR,
  GET_ALL_TEACHERS_SUCCESS,
  ADD_STAFF_LOADING,
  ADD_STAFF_ERROR,
  ADD_STAFF_SUCCESS,
  DELETE_STAFF_LOADING,
  DELETE_STAFF_ERROR,
  DELETE_STAFF_SUCCESS,
  DISABLE_STAFF_LOADING,
  DISABLE_STAFF_ERROR,
  DISABLE_STAFF_SUCCESS,
  UPDATE_STAFF_LOADING,
  UPDATE_STAFF_ERROR,
  UPDATE_STAFF_SUCCESS,
  IMPORT_STAFF_LOADING,
  IMPORT_STAFF_ERROR,
  IMPORT_STAFF_SUCCESS,
  GET_DRIVERS_LOADING,
  GET_DRIVERS_ERROR,
  GET_DRIVERS_SUCCESS,
  StaffTypeI,
  StaffDispatchTypes,
  DisabledStaffPayloadType,
} from "../../actions/HumanResource/Staff/StaffActionTypes";

interface InitialStateI {
  loading: boolean;
  staffs: any;
  teachers: any;
  all_teachers: { id: string; first_name: string; last_name: string }[];
  errors: any;
  add_or_update?: boolean;
  singlestaff: any;
  drivers: { id: string; full_name: string }[];
  disabled_staffs: DisabledStaffPayloadType;
}

const initialState: InitialStateI = {
  loading: false,
  staffs: [],
  teachers: [],
  all_teachers: [],
  errors: null,
  add_or_update: false,
  singlestaff: null,
  drivers: [],
  disabled_staffs: {
    current_page: 1,
    per_page: 10,
    total: 0,
    total_pages: 1,
    results: [],
  },
};

const StaffReducer = (
  state: InitialStateI = initialState,
  action: StaffDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_STAFF_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
        add_or_update: false,
      };

    case GET_STAFF_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_STAFF_SUCCESS:
      return {
        ...state,
        loading: false,
        staffs: action.payload,
        errors: null,
      };

    case GET_DISABLED_STAFF_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
        add_or_update: false,
      };

    case GET_DISABLED_STAFF_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_DISABLED_STAFF_SUCCESS:
      return {
        ...state,
        loading: false,
        disabled_staffs: action.payload,
      };

    case STAFF_BY_ID_LOADING:
      return {
        ...state,
        loading: true,
      };

    case STAFF_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        singlestaff: action.payload,
      };

    case STAFF_BY_ID_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_TEACHERS_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
        add_or_update: false,
      };

    case GET_TEACHERS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_TEACHERS_SUCCESS:
      return {
        ...state,
        loading: false,
        teachers: action.payload,
        errors: null,
        add_or_update: false,
      };

    case GET_ALL_TEACHERS_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
        add_or_update: false,
      };

    case GET_ALL_TEACHERS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_ALL_TEACHERS_SUCCESS:
      const data: any = action.payload.results.map((item: any) => ({
        id: item.id,
        first_name: item.user.first_name,
        last_name: item.user.last_name,
      }));
      return {
        ...state,
        loading: false,
        errors: null,
        all_teachers: data,
        add_or_update: false,
      };

    case ADD_STAFF_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case ADD_STAFF_ERROR:
      console.log("Add staff error: ", action.payload);
      return {
        ...state,
        loading: false,
        errors: action.payload,
        add_or_update: false,
      };

    case ADD_STAFF_SUCCESS:
      return {
        ...state,
        loading: false,
        staffs: [...state.staffs.results, action.payload],
        add_or_update: true,
      };

    case UPDATE_STAFF_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case UPDATE_STAFF_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        add_or_update: false,
      };

    case UPDATE_STAFF_SUCCESS:
      const current_data: StaffTypeI[] = state.staffs.results;
      const index: number = current_data.findIndex(
        (element) => element.id === action.payload.id
      );
      current_data[index] = action.payload;

      return {
        ...state,
        loading: false,
        staffs: current_data,
        add_or_update: true,
      };

    case DELETE_STAFF_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_STAFF_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_STAFF_SUCCESS:
      const new_data: StaffTypeI[] = state.staffs.results;
      const item_index = new_data.findIndex(
        (element) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return {
        ...state,
        loading: false,
        staffs: new_data,
      };

    case DISABLE_STAFF_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case DISABLE_STAFF_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DISABLE_STAFF_SUCCESS:
      return {
        ...state,
        loading: false,
        add_or_update: true,
      };

    case IMPORT_STAFF_LOADING:
      return {
        ...state,
        loading: true,
      };

    case IMPORT_STAFF_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case IMPORT_STAFF_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_DRIVERS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_DRIVERS_SUCCESS:
      return {
        ...state,
        loading: false,
        drivers: action.payload,
      };

    case GET_DRIVERS_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default StaffReducer;
