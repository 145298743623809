import React, { useState, useEffect, useRef } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { useStyles as useStyles_ } from '../../Styles/FormStyles';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, TextField } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { Autocomplete } from '@material-ui/lab';
import { MONTH_CHOICES, SELECT_GENDER } from '../../../common/json.constant';
import { Tupple } from '../../../actions';
import { AddStudentRoute } from '../../../actions/Transport/Routes/RoutesModal/StudentRouteAction';

interface PropsI {
  open: boolean;
  onClose: (value: boolean) => void;
  deleteConfirmed: (value: boolean) => void;
  dialogTitle?: string;
  transport?: string;
  student?: string;
  okText?: string;
  appliedTransport?: Array<string> | undefined;
  paginationIndex?: number;
  grade?: string;
  section?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalHeader: {
      padding: theme.spacing(1, 3),
      color: '#fff',
      backgroundColor: '#5767a4',
      '& .MuiTypography-body1': {},
    },
    modalContainer: {
      padding: theme.spacing(1, 1, 2, 1),
    },
    modalFooter: {
      padding: theme.spacing(3, 0, 4, 0),
      '& .MuiButton-contained': {
        color: '#fff',
        backgroundColor: '#e96c5a',
      },
    },
  })
);

const TransportAddConfirm = (props: PropsI) => {
  const classes = useStyles();
  const classes_ = useStyles_();
  const {
    open,
    onClose,
    deleteConfirmed,
    transport,
    student,
    appliedTransport,
    paginationIndex,
    grade,
    section,
  } = props;
  const [okText, setokText] = useState<boolean>(true);
  const [input, setInput] = useState('');
  const { register, handleSubmit, errors, setError } = useForm({
    mode: 'onChange',
  });
  const [months, setMonths] = useState<any | null>(null);
  const [allMonths, setAllMonths] = useState<any | null>(null);

  const dispatch = useDispatch();

  const handleClose = () => {
    onClose(false);
  };

  useEffect(() => {
    if (appliedTransport && appliedTransport?.length > 0) {
      const myArray = MONTH_CHOICES.filter(
        (elem: any) => !appliedTransport?.find((rm) => rm == elem.key)
      );
      setAllMonths(myArray);
    } else {
      setAllMonths(MONTH_CHOICES);
    }
  }, [appliedTransport]);

  const handleDisableConfirmed = (data: any) => {
    const input_value = data.input_value;
    setokText(false);
  };

  const resetData = () => {
    setMonths([]);
    handleClose();
  };
  const handleDeleteConfirm = () => {
    const data__: any[] = [];
    months?.map((elem: Tupple) => data__.push(elem.key));

    const finalValues = {
      transport,
      student,
      months: data__.concat(appliedTransport) || [],
    };

    dispatch(
      AddStudentRoute(
        finalValues,
        resetData,
        transport || '',
        paginationIndex || 1,
        grade || '',
        section || ''
      )
    );
    deleteConfirmed(true);
    setokText(true);
  };

  const handleSave = (e: any) => {
    e.preventDefault();
    setokText(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="xl"
    >
      <div style={{ minWidth: '350px' }}>
        <Grid
          container
          className={classes.modalHeader}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography>Transportation</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon style={{ color: '#fff' }} />
          </IconButton>
        </Grid>
        {okText ? (
          <>
            <Grid
              container
              className={classes.modalContainer}
              justifyContent="center"
            >
              <Typography>Select Month (s)?</Typography>
            </Grid>
            <form onSubmit={handleSubmit(handleDisableConfirmed)}>
              <Grid
                container
                justifyContent="center"
                style={{ margin: '0px 20px', width: '80%' }}
              >
                <Autocomplete
                  style={{ width: '100%' }}
                  onChange={(event: React.ChangeEvent<{}>, value: any | null) =>
                    setMonths(value)
                  }
                  options={allMonths || []}
                  value={months || []}
                  multiple
                  getOptionLabel={(option) => option?.value}
                  openOnFocus={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Month(s)"
                      name="month"
                      variant="outlined"
                    />
                  )}
                />
                <span className={classes_.validationErrorInfo}>
                  {errors.input_value && errors.input_value.message}
                </span>
              </Grid>
              <Grid
                container
                className={classes.modalFooter}
                justifyContent="space-around"
              >
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  color="primary"
                >
                  Cancel
                </Button>
                <Button variant="contained" color="secondary" type="submit">
                  Continue
                </Button>
              </Grid>
            </form>
          </>
        ) : (
          <>
            <Grid
              container
              className={classes.modalContainer}
              justifyContent="center"
            >
              <Typography>Are you sure to apply month?</Typography>
            </Grid>
            <Grid
              container
              className={classes.modalFooter}
              justifyContent="space-around"
            >
              <Button onClick={handleClose} variant="outlined" color="primary">
                Cancel
              </Button>

              <Button onClick={handleDeleteConfirm} variant="contained">
                Confirm
              </Button>
            </Grid>
          </>
        )}
      </div>
    </Dialog>
  );
};

export default TransportAddConfirm;
