import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_PARENT_PROFILE_LOADING,
  GET_PARENT_PROFILE_SUCCESS,
  GET_PARENT_PROFILE_ERROR,
  UPDATE_PARENT_PROFILE_ERROR,
  UPDATE_PARENT_PROFILE_SUCCESS,
  UPDATE_PARENT_PROFILE_LOADING,
  ProfileDispatchTypes,
} from "./ParentProfileActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { PARENT_API_URL } from "../../../config/SERVER_API_URLS";

export const GetGuardianProfile =
  () => async (dispatch: Dispatch<ProfileDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_PARENT_PROFILE_LOADING,
      });

      const res = await axios.get(`${PARENT_API_URL}/profile/`, HeaderConfig());

      dispatch({
        type: GET_PARENT_PROFILE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_PARENT_PROFILE_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Guardian Loading Failed",
        },
      });
    }
  };

export const UpdateParentProfile =
  (data: any) =>
  async (dispatch: Dispatch<ProfileDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_PARENT_PROFILE_LOADING,
      });

      const res = await axios.put(
        `${PARENT_API_URL}/profile/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_PARENT_PROFILE_SUCCESS,
        payload: res.data,
      });
      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Profile Updated Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: UPDATE_PARENT_PROFILE_ERROR,
      });
    }
  };
