// ---------------------------- module imports start ----------------------------------//

import { Button, Grid, InputLabel, Paper, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import SaveIcon from '@material-ui/icons/Save';
import { useForm } from 'react-hook-form';
import { useStyles } from '../../Styles/FormStyles';

import { RootStore } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import {
  getReceiptSettings,
  postReceiptSettings,
} from '../../../rtk/features/miscellaneous/receiptSettingsApi';
import BraftEditor from 'braft-editor';
import {
  getInvoiceSettings,
  postInvoiceSettings,
} from '../../../rtk/features/miscellaneous/invoiceSettingsApi';
import ListView from './ListView';
import CancelIcon from '@material-ui/icons/Cancel';
import AddNewButton from '../../Reusable/Buttons/AddNewButton';

// ---------------------------- module imports ends ----------------------------------//

// ---------------------------- interface starts ----------------------------------//

interface InputFormI {
  prefix: string;
  start_from: string;
}

// ---------------------------- interface ends ----------------------------------//

const InvoiceForm = () => {
  const classes = useStyles();

  // ---- States Declaration ------ //

  const [formValues, setFormValues] = useState<InputFormI>({
    prefix: '',
    start_from: '',
  });

  const [values, setValues] = useState<any[]>([]);
  const [onEditMode, setOnEditMode] = useState<boolean>(false);

  const dispatch = useDispatch();

  const { loading, invoiceSettings } = useSelector(
    (state: RootStore) => state.invoiceSettings
  );

  useEffect(() => {
    dispatch(getInvoiceSettings());
  }, [dispatch]);

  useEffect(() => {
    if (invoiceSettings) {
      setFormValues({
        ...formValues,
        prefix: invoiceSettings.prefix || '',
        start_from: String(invoiceSettings.start_from || 0),
      });
      setValues([
        { name: 'Prefix', value: invoiceSettings.prefix || '' },
        {
          name: 'Receipt Number',
          value: String(invoiceSettings.start_from || 0),
        },
      ]);
    }
  }, [invoiceSettings]);

  const handleValueChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };

  const { register, handleSubmit, errors, setError } = useForm<InputFormI>();

  const onSubmit = (data: InputFormI) => {
    dispatch(
      postInvoiceSettings({
        prefix: data.prefix,
        start_from: Number(data.start_from),
      })
    );
    setOnEditMode(false);
  };

  return (
    <>
      {onEditMode && (
        <Paper className={classes.pageContent}>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Grid item xs={12} className={classes.formTitle}>
                <span>Invoice</span>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={4} className={classes.formWrapper}>
                <InputLabel>
                  Prefix <span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <TextField
                  variant="outlined"
                  name="prefix"
                  placeholder="invoice prefix"
                  inputRef={register({
                    required: 'Invoice prefix is required',
                  })}
                  value={formValues.prefix}
                  onChange={handleValueChange}
                />
                <span className={classes.validationErrorInfo}>
                  {errors.prefix && errors.prefix.message}
                </span>
              </Grid>

              <Grid item xs={4} className={classes.formWrapper}>
                <InputLabel>
                  Invoice Number <span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <TextField
                  variant="outlined"
                  type="number"
                  name="start_from"
                  placeholder="invoice number starting from"
                  inputRef={register({
                    required: {
                      value: true,
                      message: 'Invoice number is required',
                    },
                    min: {
                      value: 1,
                      message: 'Invoice starting number cannot be 0',
                    },
                  })}
                  onChange={handleValueChange}
                  InputProps={{ inputProps: { min: 1, max: 1000000 } }}
                  value={formValues.start_from}
                />
                <span className={classes.validationErrorInfo}>
                  {errors.start_from && errors.start_from.message}
                </span>
              </Grid>

              <Grid
                style={{ margin: '9px', gap: '1rem' }}
                container
                justifyContent="flex-end"
              >
                <AddNewButton
                  title="Cancel"
                  type="button"
                  color="primary"
                  variant="outlined"
                  startIcon={<CancelIcon />}
                  onClick={() => setOnEditMode(false)}
                >
                  Cancel
                </AddNewButton>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      )}
      {!onEditMode && (
        <ListView title="Invoice" setOnEditMode={setOnEditMode} data={values} />
      )}
    </>
  );
};

export default InvoiceForm;
