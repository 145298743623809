import { createTheme, Tab, Tabs, ThemeProvider } from "@material-ui/core";

/* Global Styling ------------------  */

declare module "@material-ui/core/styles/createTheme" {
  interface Theme {
    parentPalette: {
      primary: string;
      secondary: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    parentPalette?: {
      primary?: string;
      secondary?: string;
    };
  }
}

const theme = createTheme({
  /* By passing props------------------  */
  props: {
    MuiTableCell: {
      size: "small",
      // padding: "none",
    },
    MuiTextField: {
      inputProps: {
        style: { fontSize: 14 },
      },
      margin: "none",
    },
    MuiButton: {
      disableRipple: true,
      disableElevation: true,
    },
    MuiButtonBase: {
      disableRipple: true,
    },

    MuiCheckbox: {
      disableRipple: true,
    },
  },
  /* By overiding props------------------  */
  overrides: {
    MuiTableHead: {
      root: {
        padding: 0,
      },
    },
    MuiTableCell: {
      head: {
        fontSize: "13px",
        // padding: '8px 5px 8px 8px !important',
      },
      body: {
        fontSize: "13px !important",
      },
    },
    MuiTab: {
      wrapper: {
        flexDirection: "row",
      },
      textColorSecondary: {
        color: "#000",
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: "13px !important",
      },
    },
    MuiTypography: {
      body1: {
        fontSize: "12px",
      },
      colorTextPrimary: {
        color: "rgba(29, 70, 152, 0.95)",
      },
      colorTextSecondary: {
        color: "#111",
      },
      colorInherit: {
        color: "#696969",
      },
    },
    MuiTooltip: {
      tooltipPlacementBottom: {
        marginTop: "4px !important",
      },
    },
    MuiCheckbox: {
      root: {
        padding: "0px 5px !important",
        "&$checked": {
          "&:hover": {
            backgroundColor: "none",
          },
        },
      },
      colorPrimary: {
        color: "rgba(19, 46, 152, 0.8)",
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: "#F5F5F5",
        borderBottom: "1px solid transparent !important",
        "& input": {
          height: ".9rem!important",
        },
      },
      underline: {
        "&::before": {
          borderBottom: "1px solid transparent !important",
        },
      },
    },
    MuiCard: {
      root: {
        overflow: "auto",
      },
    },
    MuiButton: {
      containedPrimary: {
        color: "#fff !important",
      },
      root: {
        fontSize: "13px",
      },
      label: {
        fontSize: "11px",
      },
      text: {
        fontSize: "11px",
      },
      startIcon: {
        fontSize: "10px",
        color: "inherit",
      },
      iconSizeMedium: {
        fontSize: "10px",
        "& :first-child": {
          fontSize: "14px",
        },
      },
    },
    MuiSvgIcon: {
      fontSizeSmall: {
        fontSize: "12px",
      },
    },
  },

  palette: {
    primary: {
      main: "#132E98",
      light: "#4259b6",
    },
    secondary: {
      main: "#118FCF",
      light: "#449fcd",
    },
  },
  parentPalette: {
    primary: "#1d3557",
  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontFamily: "Poppins, Roboto, sans-serif",
    button: {
      fontSize: "0.75rem",
    },
  },
});

theme.typography.h3 = {
  fontSize: "1.375rem",
  color: "#4D4F5C",
  textAlign: "left",
  "@media (min-width:600px)": {
    fontSize: "1.375rem",
    height: "27px",
  },
};

theme.typography.h5 = {
  fontSize: "1.5rem",
  "@media (min-width:600px)": {
    fontSize: "1.5rem",
  },
};

theme.typography.h4 = {
  fontSize: "20px",
  fontWeight: "normal",
  "@media (min-width:600px)": {
    fontSize: "20px",
  },
};

export default theme;
