import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { RootStore } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import AuthenticationLayout from '../Reusable/Layouts/AuthenticationLayout';
import { UpdateAppTheme } from '../../actions/Auth/Login/LoginAction';

const useStyles = makeStyles((theme) => ({
  form: {
    padding: '50px 40px 30px 40px',
    width: '100%', // Fix IE 11 issue.
    backgroundColor: 'white',
  },
  submit: {
    padding: '12px 0 !important',
  },
}));

const ChooseRole = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const authState = useSelector((state: RootStore) => state.auth);

  const handleRoleClick = (group: any) => {
    dispatch(UpdateAppTheme(group.name));
    history.push('/');
  };

  if (authState.user.groups.length < 2 && localStorage.getItem('role')) {
    history.push('/');
  }

  return (
    <React.Fragment>
      <AuthenticationLayout title="Choose Role">
        <form className={classes.form} style={{ minWidth: '420px' }}>
          <Grid container spacing={2}>
            {authState.user.groups.map((group: any) => (
              <Grid item xs={12} key={group.id}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={() => handleRoleClick(group)}
                >
                  {group.name}
                </Button>
              </Grid>
            ))}
          </Grid>
        </form>
      </AuthenticationLayout>
    </React.Fragment>
  );
};

export default ChooseRole;
