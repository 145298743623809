import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CustomizedPopUp from "../../Reusable/Dialogs/CustomizedPopUp";
import AddNewButton from "../../Reusable/Buttons/AddNewButton";
import { Add, AddCircleOutline, Create } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import AttendanceConfigModal from "./AttendanceConfigModal";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import {
  getDeviceConfigs,
  syncDeviceConfig,
  testDeviceConfigConnection,
} from "../../../rtk/features/attendanceDevice/attendanceDeviceThunk";
import { resetAttDeviceState } from "../../../rtk/features/attendanceDevice/attendanceDeviceSlice";
import AttendanceDevicesModal from "./AttendanceDevicesModal";
import { FormSaveLoadingButton } from "../../Reusable/Buttons/FormButton";
import SyncIcon from "@material-ui/icons/Sync";
import BackDropLoader from "../../Reusable/BackDropLoader";

const NoDevice = styled.div`
  min-height: 240px;
  height: calc(100vh - 240px);
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    a {
      font-size: 28px;
      font-weight: 500;
      opacity: 0.5;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      cursor: pointer;

      svg {
        font-size: 34px;
      }
    }

    span {
      display: block;
      font-size: 14px;
      opacity: 0.4;
      user-select: none;
      text-align: center;
    }
  }
`;

const HeadSection = styled.div`
  margin-bottom: 16px;
  text-align: right;
`;

const Container = styled.div`
  margin: 8px 16px;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;

  @media (max-width: 1080px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Card = styled.div`
  position: relative;
  padding: 16px;
  padding-bottom: 24px;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fff;

  &:hover {
    & > a {
      display: block;
    }
  }

  h3 {
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 16px 0 !important;

    a {
      color: #132e98;
      cursor: pointer;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    color: #696969;
    margin-bottom: 16px;

    & > tbody {
      & > tr {
        border-bottom: 1px solid rgba(224, 224, 224, 1);

        & > th,
        td {
          width: 50%;
          font-size: 13px;
          font-weight: 400;
          padding: 8px 16px;
          text-align: left;
        }

        & > td {
          color: rgba(0, 0, 0, 0.87);
        }
      }
    }
  }
`;

export const StatusText = styled.span<{ status: "Online" | "Offline" }>`
  color: ${(props) => (props.status === "Online" ? "green" : "red")};
  font-weight: 500;
  text-transform: uppercase;
`;

const IconHolder = styled.a`
  position: absolute;
  right: 16px;
  top: 16px;

  display: none;

  color: #118fcf;
  cursor: pointer;

  & > svg {
    font-size: 26px;
    padding: 3px;
    border: 2px solid rgba(17, 143, 207, 1);
    border-radius: 6px;
    transition: all 0.2s ease;

    &:hover {
      opacity: 0.8;
    }
  }
`;

interface CardDataI {
  id: string;
  sn: number;
  username: string;
  password: string;
  status: "Online" | "Offline";
  ipAddress: string;
  port: string;
}

type CardProps = {
  data: CardDataI;
  onClick: (data: CardDataI) => void;
  onViewDevices: (id: string) => void;
  testConnection: (id: string) => void;
  onSync: (id: string) => void;
};

const CardItem = (props: CardProps) => {
  return (
    <Card>
      <h3>
        <a onClick={() => props.onViewDevices(props.data.id)}>
          Device Config {props.data.sn}
        </a>
      </h3>
      <table>
        <tbody>
          <tr>
            <th>Status</th>
            <td>
              <StatusText status={props.data.status}>
                {props.data.status}
              </StatusText>
            </td>
          </tr>
          <tr>
            <th>Username</th>
            <td>{props.data.username || "-"}</td>
          </tr>
          <tr>
            <th>Password</th>
            <td>{props.data.password || "-"}</td>
          </tr>
          <tr>
            <th>IP Address</th>
            <td>{props.data.ipAddress || "-"}</td>
          </tr>
          <tr>
            <th>Port</th>
            <td>{props.data.port || "-"}</td>
          </tr>
        </tbody>
      </table>
      <FormSaveLoadingButton
        icon={<SyncIcon />}
        text={"Test Connection"}
        onClick={() => props.testConnection(props.data.id)}
        style={{ marginRight: "10px" }}
      />
      <FormSaveLoadingButton
        icon={<SyncIcon />}
        text={"Sync to Server"}
        onClick={() => props.onSync(props.data.id)}
      />
      <IconHolder onClick={() => props.onClick(props.data)}>
        <Create />
      </IconHolder>
    </Card>
  );
};

const AttendanceDeviceList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [modalOpen, toggleModalOpen] = useState<boolean>(false);
  const [editing, setEditing] = useState<CardDataI | null>(null);

  const [deviceModal, toggleDeviceModal] = useState<boolean>(false);

  const { deviceConfigs, loading, actionPerformed } = useSelector(
    (state: RootStore) => state.attendanceDevice
  );
  const [viewConfig, setViewConfig] = useState<string | null>(null);

  useEffect(() => {
    dispatch(getDeviceConfigs());
  }, []);

  useEffect(() => {
    if (actionPerformed) {
      setEditing(null);
    }
  }, [actionPerformed]);

  const handleUpdateClick = (data: CardDataI) => {
    dispatch(resetAttDeviceState());
    setEditing(data);
    toggleModalOpen(true);
  };

  const handleViewDevices = (id: string) => {
    toggleDeviceModal(true);
    setViewConfig(id);
  };

  const handleDeviceModalClose = () => {
    toggleDeviceModal(false);
    setViewConfig(null);
  };

  const handleTestConnection = (id: string) => {
    dispatch(testDeviceConfigConnection(id));
  };

  const handleDeviceSync = (id: string) => {
    dispatch(syncDeviceConfig(id));
  };

  return (
    <>
      {deviceConfigs.length ? (
        <Container>
          <HeadSection>
            <AddNewButton
              title="Add New Device"
              type="button"
              color="primary"
              variant="outlined"
              startIcon={<Add />}
              onClick={() => toggleModalOpen(true)}
            >
              Add New Device
            </AddNewButton>
          </HeadSection>
          <Wrapper>
            {deviceConfigs.map((el, index) => (
              <CardItem
                key={index + 1}
                data={{
                  sn: index + 1,
                  id: el.id,
                  username: el.username,
                  password: el.password,
                  port: el.port,
                  status: el.access_token !== null ? "Online" : "Offline",
                  ipAddress: el.ip_address,
                }}
                onClick={(data) => handleUpdateClick(data)}
                onViewDevices={(id) => handleViewDevices(id)}
                testConnection={(id) => handleTestConnection(id)}
                onSync={(id) => handleDeviceSync(id)}
              />
            ))}
          </Wrapper>
        </Container>
      ) : (
        <NoDevice>
          <p>
            {!loading && (
              <a onClick={() => toggleModalOpen(true)}>
                <AddCircleOutline />
                Add Device
              </a>
            )}
            <span>{loading ? "Loading..." : "No devices found"}</span>
          </p>
        </NoDevice>
      )}
      <CustomizedPopUp
        title={editing ? "Update Config Info" : "Config New Device"}
        open={modalOpen}
        onClose={() => toggleModalOpen(false)}
        actionConfirmed={() => {
          //
        }}
        dialogTitle="Device Configuration Form"
        okText={false}
        cancel={false}
      >
        <AttendanceConfigModal
          handleModalClose={() => toggleModalOpen(false)}
          editData={editing}
        />
      </CustomizedPopUp>
      <CustomizedPopUp
        title="Devices Info"
        open={deviceModal}
        onClose={handleDeviceModalClose}
        actionConfirmed={() => {
          //
        }}
        maxWidth="600px"
        dialogTitle=""
        okText={false}
        cancel={false}
      >
        <AttendanceDevicesModal id={viewConfig} />
      </CustomizedPopUp>
      <BackDropLoader open={loading} />
    </>
  );
};

export default AttendanceDeviceList;
