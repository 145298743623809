// ---------------------------- module imports start ----------------------------------//

import {
  Button,
  Grid,
  InputLabel,
  Paper,
  TextField,
  LinearProgress,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import SaveIcon from '@material-ui/icons/Save';
import { useForm } from 'react-hook-form';
import { useStyles } from '../Styles/FormStyles';

import { RootStore } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import {
  GetSmsConfigData,
  AddSmsConfig,
  UpdateSmsConfig,
} from '../../actions/GeneralSettings/SmsConfig/SmsConfigAction';

// ---------------------------- module imports ends ----------------------------------//

// ---------------------------- interface starts ----------------------------------//

interface InputFormI {
  sms_api_host: string;
  sms_api_key: string;
}

// ---------------------------- interface ends ----------------------------------//

const SMSTemplate = () => {
  const classes = useStyles();

  // ---- States Declaration ------ //
  const [loading, setLoading] = useState<boolean>(false);
  const [isCreating, SetIsCreating] = useState(false);
  const [isUpdating, SetIsUpdating] = useState(false);
  const [formValues, setFormValues] = useState<InputFormI>({
    sms_api_host: '',
    sms_api_key: '',
  });

  const dispatch = useDispatch();

  const smsConfigLoading = useSelector(
    (state: RootStore) => state.sms_config.loading
  );

  const smsConfigData = useSelector((state: RootStore) => state.sms_config);

  useEffect(() => {
    dispatch(GetSmsConfigData());
  }, []);

  useEffect(() => {
    setLoading(smsConfigLoading);
  }, [smsConfigLoading]);

  useEffect(() => {
    if (smsConfigData.sms_config?.length > 0) {
      handleFormInitialize(smsConfigData.sms_config[0]);
      SetIsUpdating(true);
      SetIsCreating(false);
    } else {
      SetIsCreating(true);
      SetIsUpdating(false);
    }
  }, [smsConfigData]);

  //Initialize form data
  const handleFormInitialize = (data: InputFormI) => {
    setFormValues({
      ...formValues,
      sms_api_host: data.sms_api_host,
      sms_api_key: data.sms_api_key,
    });
  };

  const handleValueChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setFormValues({ ...formValues, [name]: value });
  };

  const { register, handleSubmit, errors } = useForm<InputFormI>();

  const onSubmit = (data: InputFormI) => {
    const form_data: InputFormI = {
      sms_api_host: data.sms_api_host,
      sms_api_key: data.sms_api_key,
    };
    if (isCreating) {
      dispatch(AddSmsConfig(form_data));
    }

    if (isUpdating) {
      if (smsConfigData.sms_config[0]) {
        dispatch(UpdateSmsConfig(smsConfigData.sms_config[0].id, form_data));
      }
    }
  };

  return (
    <>
      <Paper className={classes.pageContent}>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} className={classes.formTitle}>
              <span>SMS</span>
            </Grid>
            <Grid item xs={7} className={classes.formWrapper}>
              <InputLabel>SMS API Host</InputLabel>
              <TextField
                variant="outlined"
                name="sms_api_host"
                placeholder="sms api host"
                autoComplete="off"
                inputRef={register}
                value={formValues.sms_api_host}
                onChange={handleValueChange}
              />
              <span className={classes.validationErrorInfo} />
            </Grid>
            <Grid item xs={5} className={classes.formWrapper}>
              <InputLabel>SMS API Key </InputLabel>
              <TextField
                variant="outlined"
                name="sms_api_key"
                placeholder="sms api key"
                inputRef={register}
                value={formValues.sms_api_key}
                onChange={handleValueChange}
              />
              <span className={classes.validationErrorInfo} />
            </Grid>
            <Grid style={{ margin: '9px' }} container justifyContent="flex-end">
              <Button
                type="submit"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            </Grid>
          </Grid>
          {/* {loading && <LinearProgress className={classes.formProgress} />} */}
        </form>
      </Paper>
    </>
  );
};

export default SMSTemplate;
