import {
  GET_FINE_LOADING,
  GET_FINE_ERROR,
  GET_FINE_SUCCESS,
  FineDispatchTypes,
  ADD_FINE_LOADING,
  ADD_FINE_ERROR,
  ADD_FINE_SUCCESS,
  DELETE_FINE_LOADING,
  DELETE_FINE_ERROR,
  DELETE_FINE_SUCCESS,
  UPDATE_FINE_LOADING,
  UPDATE_FINE_ERROR,
  UPDATE_FINE_SUCCESS,
  FineTypeI,
} from "../../actions/Fees/FineType/FineActionTypes";

interface InitialStateI {
  loading: boolean;
  fine: FineTypeI[];
  errors: any;
  recent?: boolean;
  add_or_update: boolean;
}

const initialState: InitialStateI = {
  loading: false,
  fine: [],
  errors: null,
  recent: false,
  add_or_update: false,
};

const FineReducer = (
  state: InitialStateI = initialState,
  action: FineDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_FINE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_FINE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_FINE_SUCCESS:
      return {
        ...state,
        loading: false,
        fine: action.payload,
        errors: null,
      };

    case ADD_FINE_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case ADD_FINE_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        recent: false,
        add_or_update: true,
      };

    case ADD_FINE_SUCCESS:
      return {
        loading: false,
        fine: [...state.fine, action.payload],
        errors: null,
        recent: true,
        add_or_update: true,
      };

    case UPDATE_FINE_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case UPDATE_FINE_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        recent: false,
        add_or_update: true,
      };

    case UPDATE_FINE_SUCCESS:
      const current_data: FineTypeI[] = state.fine;
      const index: number = current_data.findIndex(
        (element) => element.id === action.payload.id
      );
      current_data[index] = action.payload;

      return {
        loading: false,
        fine: current_data,
        errors: null,
        recent: true,
        add_or_update: true,
      };

    case DELETE_FINE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_FINE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_FINE_SUCCESS:
      const new_data: FineTypeI[] = state.fine;
      const item_index = new_data.findIndex(
        (element) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return {
        ...state,
        loading: false,
        fine: new_data,
        errors: null,
      };

    default:
      return state;
  }
};

export default FineReducer;
