import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableHead,
} from "@material-ui/core";
import { Save } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { setSnackbar } from "../../../actions/SnackbarAction";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import {
  applyFeeStudentDiscount,
  getFeeStudents,
} from "../../../rtk/features/feeDiscount/feeDiscountApi";
import { changeFeeStudentData } from "../../../rtk/features/feeDiscount/feeDiscountSlice";
import { RootStore } from "../../../store";
import { useTableWithPagination } from "../../Reusable";
import TableAnalyticsCard from "../../../common/Cards/TableAnalytics";

const Wrapper = styled(Paper)`
  background-color: #fff;
  padding: 16px;
  margin: 10px;
`;

const SaveButton = styled(Button)`
  margin-top: 20px;
  width: 120px;
`;

const CellInput = styled.input<{ fullWidth?: boolean; error?: boolean }>`
  && {
    width: ${(props) => (props.fullWidth ? "80%" : "90px")};
    padding: 5px;
    border-radius: 3px;
    border: ${(props) => (props.error ? `1px solid red` : `1px solid #000`)};
    color: ${(props) => (props.error ? `red` : `#000`)};

    &:focus {
      outline: none;
    }
  }
`;

const headCells = [
  { id: "admission_num", label: "Admission No" },
  { id: "student_name", label: "Student Name" },
  { id: "student_category", label: "Student Category" },
  { id: "fee_amount", label: "Fee Amount" },
  { id: "discount_per", label: "Discount (%)" },
  { id: "discount_amt", label: "Discount (Amt)" },
  {
    id: "fee_amount_final",
    label: (
      <span>
        Fee Amount <br />
        (Discount Applied)
      </span>
    ),
  },
  { id: "remarks", label: "Remarks" },
];

const FeeDiscount = () => {
  const dispatch = useDispatch();

  const [saveDisabled, setSaveDisabled] = useState<boolean>(false);

  const [totalDiscount, setTotalDiscount] = useState<number>(0);
  const [totalDiscountStudent, setTotalDiscountStudent] = useState<number>(0);

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTableWithPagination(headCells);

  const { feeStudents, searchParams, actionPerformed } = useSelector(
    (state: RootStore) => state.feeDiscount
  );

  useEffect(() => {
    if (feeStudents.length) {
      if (feeStudents.find((el) => el.error)) {
        setSaveDisabled(true);
      } else {
        setSaveDisabled(false);
      }
    } else {
      setSaveDisabled(false);
    }
  }, [feeStudents]);

  useEffect(() => {
    if (actionPerformed) {
      if (searchParams) {
        if (searchParams.fee_type && searchParams.grade) {
          dispatch(
            getFeeStudents({
              fee_head: searchParams.fee_type,
              month: searchParams?.month || "",
              grade: searchParams.grade,
              section: searchParams?.section || "",
              student_category: searchParams?.category || "",
            })
          );
        }
      }
    }
  }, [actionPerformed]);

  const handleSaveDiscount = () => {
    const updated_data = feeStudents.filter((el) => el.discount_amt !== "");

    if (searchParams && updated_data.length > 0) {
      const post_data = {
        fee_type: searchParams.fee_type,
        month: searchParams.month,
        students: updated_data.map((el) => ({
          id: el.id,
          amount: Number(el.discount_amt),
          remarks: el.remarks,
        })),
      };
      dispatch(applyFeeStudentDiscount(post_data));
    } else {
      dispatch(
        setSnackbar(true, "warning", "No discount applied to any students")
      );
    }
  };

  useEffect(() => {
    const totalAmount = feeStudents?.reduce(
      (accumulator: any, currentValue: any) => {
        return accumulator + Number(currentValue.discount_amt || 0);
      },
      0
    );
    setTotalDiscount(totalAmount);

    const discountApplied = feeStudents?.filter(
      (elem: any) => Number(elem.discount_per) > 0
    );
    setTotalDiscountStudent(discountApplied?.length);
  }, [feeStudents]);

  return (
    <Wrapper>
      {feeStudents?.length > 0 && (
        <Grid container spacing={2} style={{ marginBottom: "10px" }}>
          <Grid item md={6}>
            <TableAnalyticsCard
              item={{
                icon: LocalOfferIcon,
                title: "Total Discount",
                color: "#E6B160",
                total: `${totalDiscount} /-`,
                tagline: "Amount in NPR",
              }}
            />
          </Grid>
          <Grid item md={6}>
            <TableAnalyticsCard
              item={{
                icon: AssignmentIndIcon,
                title: "No. of Students",
                color: "#43a047",
                total: totalDiscountStudent,
                tagline: "Who secured the discount",
              }}
            />
          </Grid>
        </Grid>
      )}
      <Table>
        <TableHead>
          <StyledTableRow>
            {headCells.map((el) => (
              <StyledTableCell key={el.id} align="center">
                {el.label}
              </StyledTableCell>
            ))}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {feeStudents.map((item) => (
            <StyledTableRow key={item.id}>
              <StyledTableCell align="center">
                {item.admission_no}
              </StyledTableCell>
              <StyledTableCell align="center">
                {item.student_name}
              </StyledTableCell>
              <StyledTableCell align="center">
                {item.student_category}
              </StyledTableCell>
              <StyledTableCell align="center">
                {item.fee_amount}
              </StyledTableCell>
              <StyledTableCell align="center">
                <CellInput
                  value={item.discount_per || ""}
                  onChange={(e) =>
                    dispatch(
                      changeFeeStudentData({
                        id: item.id,
                        field: "percent",
                        value: e.target.value,
                      })
                    )
                  }
                  error={item.error}
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <CellInput
                  value={item.discount_amt || ""}
                  onChange={(e) =>
                    dispatch(
                      changeFeeStudentData({
                        id: item.id,
                        field: "amount",
                        value: e.target.value,
                      })
                    )
                  }
                  error={item.error}
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                {item.fee_amount_final}
              </StyledTableCell>
              <StyledTableCell align="center">
                <CellInput
                  fullWidth
                  value={item.remarks}
                  onChange={(e) =>
                    dispatch(
                      changeFeeStudentData({
                        id: item.id,
                        field: "remarks",
                        value: e.target.value,
                      })
                    )
                  }
                />
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      <Grid container>
        <SaveButton
          variant="contained"
          color="primary"
          startIcon={<Save />}
          onClick={handleSaveDiscount}
          disabled={saveDisabled}
        >
          Save
        </SaveButton>
      </Grid>
    </Wrapper>
  );
};

export default FeeDiscount;
