import {
  Button,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  FormControlLabel,
  Grid,
  InputLabel,
  Input,
  Paper,
  TextField,
  Chip,
  Theme,
  useTheme,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { GetUsersByRole } from "../../../../../actions/GeneralSettings/GeneralInfo/GeneralInfoAction";
import { GetDesignations } from "../../../../../actions/HumanResource/Designation/DesignationAction";
import { DesignationTypeI } from "../../../../../actions/HumanResource/Designation/DesignationActionTypes";
import { RootStore } from "../../../../../store";
import { useFormStyles } from "../../../../Styles/FormStyles";
import { AnnouncementTypeI } from "../../../../../actions/Communication/Announcement/AnnouncementActionTypes";

import MultiSelectCheckBox from "../../../../Reusable/Inputs/MultiSelectCheckbox";
import { GetSystemRoles } from "../../../../../actions/HumanResource/StaffRole/StaffRolesAction";

interface PropsI {
  onChange: (value: IndividualTabFormI) => void;
  onSubmit: number;
  initData: AnnouncementTypeI | null;
}

export interface IndividualTabFormI {
  user: string[];
  userLabel: string[];
}

interface UserOptionsI {
  id: string;
  label: string;
  selected: boolean;
}

const IndividualTab = (props: PropsI) => {
  const { onChange, onSubmit, initData } = props;

  const classes = useFormStyles();

  const theme = useTheme();

  const { register, handleSubmit, errors, setError, clearErrors } = useForm();
  const [role, setRole] = useState<DesignationTypeI | null>(null);
  const [roleChoices, setRoleChoices] = useState<DesignationTypeI[]>([]);

  const [userChoices, setUserChoices] = useState<UserOptionsI[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [selectedUserLabels, setSelectedUserLabels] = useState<string[]>([]);
  const [selectedIndividuals, setSelectedIndividuals] = useState<
    UserOptionsI[]
  >([]);

  const [selectedValues, setSelectedValues] = useState<UserOptionsI[]>([]);

  // ======================== <START> REACT HOOKS <START> ======================= //
  const dispatch = useDispatch();
  const designationSelector = useSelector(
    (state: RootStore) => state.staff_roles
  );
  const usersSelector = useSelector(
    (state: RootStore) => state.general_info.usersByRole
  );

  useEffect(() => {
    dispatch(GetSystemRoles());
  }, []);

  useEffect(() => {
    setRoleChoices(designationSelector.system_roles);
  }, [designationSelector]);

  useEffect(() => {
    const options: UserOptionsI[] = [];

    usersSelector.map((element) =>
      options.push({
        id: element.id,
        label: element.first_name + " " + element.last_name,
        selected: false,
      })
    );
    setUserChoices(options);
  }, [usersSelector]);

  useEffect(() => {
    if (onSubmit > 1) {
      !selectedUsers.length &&
        setError("users", {
          type: "required",
          message: "Minimum of one user must be selected",
        });
    }
  }, [onSubmit]);

  useEffect(() => {
    onChange({ user: selectedUsers, userLabel: selectedUserLabels });
  }, [selectedUsers, selectedUserLabels]);

  useEffect(() => {
    selectedValues.length === userChoices.length
      ? setState({ checkedA: true })
      : setState({ checkedA: false });
  }, [selectedValues]);

  useEffect(() => {
    if (selectedIndividuals.length) {
      clearErrors("users");
    }
    setSelectedUsers(selectedIndividuals.map((item) => item.id));
    setSelectedUserLabels(selectedIndividuals.map((item) => item.label));
  }, [selectedIndividuals]);

  // ======================== <END> REACT HOOKS <END> ======================= //
  // ======================== <START> EVENT HANDLERS <START> ======================= //

  const [state, setState] = React.useState({
    checkedA: false,
  });

  const handleRoleChange = (value: DesignationTypeI | null) => {
    setRole(value);
    setSelectedValues([]);
    setSelectedUsers([]);
    setSelectedUserLabels([]);
    setState({ checkedA: false });

    if (value) {
      dispatch(GetUsersByRole(value.id));
    } else {
      setUserChoices([]);
    }
  };

  const handleUsersChoiceChange = (data: UserOptionsI | null) => {
    if (data) {
      const existing: string[] = selectedIndividuals.map(
        (element) => element.id
      );

      if (!existing.includes(data.id)) {
        setSelectedIndividuals(selectedIndividuals.concat(data));
      }
    }
  };

  const handleChipDelete = (item: UserOptionsI) => {
    const rIndex: number = selectedIndividuals.findIndex(
      (element: UserOptionsI) => element.id === item.id
    );

    if (rIndex != -1) {
      const newData = [...selectedIndividuals];
      newData.splice(rIndex, 1);
      setSelectedIndividuals(newData);
    }
  };

  const onDataSearch = (val: string) => {
    if (val) {
      if (role) {
        dispatch(GetUsersByRole(role.id, val));
      }
    }
  };

  const onFocusOut = () => {
    if (role) {
      dispatch(GetUsersByRole(role.id));
    }
  };

  // ======================== <END> EVENT HANDLERS <END> ======================= //
  return (
    <>
      <Paper className={classes.classContent} elevation={2}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <InputLabel htmlFor="filled-age-native-simple">
              Select Role
            </InputLabel>
            <Autocomplete
              className={classes.form}
              onChange={(
                event: React.ChangeEvent<{}>,
                value: DesignationTypeI | null
              ) => handleRoleChange(value)}
              options={roleChoices}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Label"
                  name="class_name"
                  inputRef={register}
                  variant="outlined"
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} className={classes.formWrapper}>
          <InputLabel htmlFor="filled-age-native-simple">
            Select Users
          </InputLabel>
          <Autocomplete
            value={null}
            id="checkbox-select-users"
            options={userChoices}
            onBlur={onFocusOut}
            onChange={(
              event: React.ChangeEvent<{}>,
              value: UserOptionsI | null
            ) => handleUsersChoiceChange(value)}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Search Users"
                onChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) => onDataSearch(event.target.value)}
              />
            )}
          />
          <Grid container>
            {selectedIndividuals.map((element: UserOptionsI, index: number) => (
              <>
                <Grid item xs={6}>
                  <Chip
                    style={{ marginBottom: "2px" }}
                    key={index}
                    variant="outlined"
                    label={element.label}
                    onDelete={() => handleChipDelete(element)}
                    size="small"
                  />
                </Grid>
              </>
            ))}
          </Grid>
          <span className={classes.validationErrorInfo}>
            {errors.users && errors.users.message}
          </span>
        </Grid>
      </Paper>
    </>
  );
};

export default IndividualTab;
