import { StudentAssignmentType } from "../../StudentDash/StudentAssignment/StudentAssignmentActionTypes";

export const GET_ADMIN_ASSIGNMENT_LOADING = "GET_ADMIN_ASSIGNMENT_LOADING";
export const GET_ADMIN_ASSIGNMENT_ERROR = "GET_ADMIN_ASSIGNMENT_ERROR";
export const GET_ADMIN_ASSIGNMENT_SUCCESS = "GET_ADMIN_ASSIGNMENT_SUCCESS";

export interface AdminAssignmentTypeI extends StudentAssignmentType {
  active: boolean;
  can_submit_after_deadline: boolean;
  publish: boolean;
  submitted_students: number;
  total_students: number;
}

//===============================<START>GET Admin ASSIGNMENT <START>======================//

export interface GetAdminAssignmentLoading {
  type: typeof GET_ADMIN_ASSIGNMENT_LOADING;
}

export interface GetAdminAssignmentError {
  type: typeof GET_ADMIN_ASSIGNMENT_ERROR;
}

export interface GetAdminAssignmentSuccess {
  type: typeof GET_ADMIN_ASSIGNMENT_SUCCESS;
  payload: AdminAssignmentTypeI[];
}

//===============================<END>GET Admin ASSIGNMENT <END>======================//
export type AdminAssignmentDispatchTypes =
  | GetAdminAssignmentLoading
  | GetAdminAssignmentError
  | GetAdminAssignmentSuccess;
