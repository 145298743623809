// ---------------------------- <START> module imports start ----------------------------------//
import React, { useState, useEffect } from 'react';
import {
  Paper,
  TableBody,
  TextField,
  Grid,
  Typography,
  TablePagination,
  LinearProgress,
  TableCell,
  Divider,
  Button,
  Chip,
} from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import { PRIMARY_DATE_FORMAT } from '../../../../../common/constant';
import moment from 'moment';
import GetStatus from '../../../../../common/GetStatus';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import { Pagination } from '@material-ui/lab';
// import { useTableStyles } from "../../Styles/TableStyles";
import { useTable, useTableWithPagination } from '../../../../Reusable';
import TableActionButtons from '../../../../Reusable/Buttons/TableActionButtons';
import ActionButton from '../../../../Reusable/Buttons/ActionButton';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import StudentProfileLeaveList from '../../../StudentLeave/StudentProfileLeaveList';

import { useDispatch, useSelector } from 'react-redux';
import {
  GetClassTeachers,
  DeleteClassTeacher,
} from '../../../../../actions/Academics/ClassTeacher/ClassTeacherAction';
// import { ClassTeacherTypeI } from "../../../actions/Academics/ClassTeacher/ClassTeacherActionTypes";
import { DeleteForeverOutlined } from '@material-ui/icons';
import CustomizedDialogs from '../../../../Reusable/Dialogs/DeleteDialog';
import Popups from '../../../../Reusable/Dialogs/Popups';
// import StudentCard from "./StudentCard";
import { useTableStyles } from '../../../../Styles/TableStyles';
import AddNewButton from '../../../../Reusable/Buttons/AddNewButton';
import { useParams } from 'react-router-dom';
import { RootStore } from '../../../../../store';
import { GetStudentLeaveHistory } from '../../../../../actions/Student/StudentProfile/StudentProfileAction';
import LeaveRequestModal from './LeaveRequestModal';
import ActivityCard from '../../../../Reusable/ActivityCard';
// ----------------------------<END> module imports end ----------------------------------//

interface ClassTeacherTypeI {
  [x: string]: any;
}

// ---------------------------- <START> interface starts ----------------------------------//
interface PropsI {
  onEditData: (value: any) => void;
}

interface HeadCellsI {
  id: string;
  label: string;
  align?: any;
}

const subValue = 'Leave';
// ---------------------------- <END> interface ends ----------------------------------//

// ---------------------------- <START> styling starts ----------------------------------//

// ---------------------------- <END> styling ends ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: 'sn', label: 'S.N' },
  { id: 'leave_date', label: 'Leave Date', align: 'left' },
  { id: 'applied_date', label: 'Applied Date', align: 'left' },
  { id: 'status', label: 'Status', align: 'left' },
  // { id: 'action', label: 'Action' },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const LeaveTable = (props: any) => {
  const classes = useTableStyles();

  // States declaration
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<number | null>(null);

  const [tableData, setTableData] = useState<any[]>([]);
  const [tempTableData, setTempTableData] = useState<ClassTeacherTypeI[]>([]);

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  const [approvedNum, setApprovedNum] = useState(0);
  const [rejectedNum, setRejectedNum] = useState(0);
  const [totalNum, setTotalNum] = useState(0);

  //========================= <START> REDUX HOOKS <START> ==============================//
  const dispatch = useDispatch();
  const leave_history = useSelector(
    (state: RootStore) => state.student_profile.student_leave_history
  );

  const studentState = useSelector(
    (state: RootStore) => state.student_profile.student
  );
  const academicId = studentState?.academic_info?.[0]?.id;

  console.log('leave_history', leave_history);
  console.log('academicId', academicId);
  console.log('approvedNum', approvedNum);
  console.log('rejectedNum', rejectedNum);
  console.log('totalNum', totalNum);

  const { studentID } = useParams<{ studentID: string }>();
  const [studentData, setStudentData] = useState([]);

  useEffect(() => {
    academicId != undefined && dispatch(GetStudentLeaveHistory(academicId));
  }, []);

  useEffect(() => {
    if (leave_history) {
      const { approved_leave, rejected_leave, total_applied_leave }: any =
        leave_history;
      setApprovedNum(approved_leave);
      setRejectedNum(rejected_leave);
      setTotalNum(total_applied_leave);
    }
  }, [leave_history]);

  useEffect(() => {
    if (leave_history && leave_history.leaves) {
      // const data: any = [];
      // const dataLength = leave_history?.length;
      // if (leave_history != null) {
      //   leave_history.map((elem: any, index: number) => {
      //     data.push({
      //       key: index + 1,
      //       id: elem.id,
      //       leave_type: elem.leave_name,
      //       leave_from: elem.leave_from,
      //       leave_to: elem.leave_to,
      //       applied_date: elem.applied_date,
      //       statue: elem.status,
      //     });
      //   });
      // }

      const data: any = [];
      leave_history.leaves.map((elem: any, index: number) => {
        data.push({
          key: index + 1,
          id: elem.id,
          leave_date: elem.leave_from,
          leave_from: elem.leave_from,
          leave_to: elem.leave_to,
          applied_date: elem.created_on,
          days: elem.leave_to,
          status: elem.status,
        });
      });
      setTableData(data);
    }
  }, [leave_history]);
  //========================= <END> REDUX HOOKS <END> ==============================//

  const [totalRecord, setTotalRecord] = useState(tempTableData.length);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [pageCount, setPageCount] = useState(
    Math.ceil(totalRecord / rowsPerPage)
  );
  const [page, setPage] = useState(1);

  const [recordFrom, setRecordFrom] = useState(1);
  const [recordTo, setRecordTo] = useState(4);

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
    setRecordFrom(newPage === 1 ? newPage : (newPage - 1) * rowsPerPage + 1);
    setRecordTo(newPage === 1 ? newPage * rowsPerPage : newPage * rowsPerPage);
  };

  const handleDeleteModal = (value: boolean, id: number) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    setDeleteModalOpen(false);
  };

  const [openPopup, setOpenPopup] = useState(false);
  const [closePopup, setClosePopup] = useState(false);
  const handleClose = (value: boolean) => {
    setOpenPopup(value);
  };

  const getCustomTableRow = (item: any, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="left" className={classes.cell}>
          {index}
        </StyledTableCell>
        <StyledTableCell align="left" className={classes.cell}>
          {new Date(item.leave_from).toNepali()} -{' '}
          {new Date(item.leave_to).toNepali()}
        </StyledTableCell>
        <StyledTableCell align="left" className={classes.cell}>
          {new Date(item.applied_date).toNepali()}
        </StyledTableCell>
        <StyledTableCell align="left" className={classes.cell}>
          {item.status}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  //==================================== <END> EVENT HANDLERS <END> ==========================//

  return (
    <>
      <Paper
        elevation={0}
        className={classes.root}
        style={{
          margin: '0px',
          borderTopRightRadius: 0,
          borderTopLeftRadius: 0,
        }}
      >
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h6">Leave Request History</Typography>
          </Grid>
          <Grid container item xs={6}>
            <Grid
              container
              justifyContent="flex-end"
              style={{ marginLeft: '40px' }}
            >
              <a onClick={() => setOpenPopup(true)}>
                <AddNewButton
                  title="Request leave"
                  type="button"
                  color="primary"
                  variant="contained"
                >
                  Request leave
                </AddNewButton>
              </a>
            </Grid>
          </Grid>
        </Grid>

        {/* Add tabs here */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '10px 0',
          }}
        >
          <ActivityCard
            data={{ value: approvedNum, approvedNum, time: 'Rejected' }}
          />
          <ActivityCard
            data={{ value: rejectedNum, rejectedNum, time: 'Approved' }}
          />
          <ActivityCard data={{ value: totalNum, totalNum, time: 'Applied' }} />
        </div>

        <TableContainer
          label="Department"
          items={tableData}
          headCells={headCells}
          getCustomTableRow={getCustomTableRow}
          search_param="name"
        />

        <Popups
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          onClose={handleClose}
          fullWidth
          maxWidth="sm"
          title="Leave Request"
        >
          <LeaveRequestModal onClose={handleClose} />
        </Popups>
      </Paper>

      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
      />
    </>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default LeaveTable;
