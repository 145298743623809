import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { AnnouncementTypeForm, AnnouncementTypeList } from "../../components";
import CommunicationSidebar from "../../components/Communication/CommunicationSidebar/CommunicationSidebar";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

interface EditDataI {
    id: string;
    name: string;
    count: number;
}

export default function AnnouncementTypePage() {
    const [count, setCount] = useState<number>(0);
    const [formData, setFormData] = useState<EditDataI | null>(null);
    const [edit, setEdit] = useState<any>();

    const fetchEditData = (data: EditDataI) => {
        setFormData({ ...data, count: count });
        setCount(count + 1);
    };

    const onEditCheck = (check: boolean) => {
        setEdit({ check: check, count: count });
    };

    const nav = <CommunicationSidebar announcementType />;
    return (
        <SubModuleLayout sideNav={nav}>
            <Grid container>
                <Grid item xs={3}>
                    <AnnouncementTypeForm onEditCheck={onEditCheck} editData={formData} />
                </Grid>
                <Grid item xs={9}>
                    <AnnouncementTypeList editCheck={edit} onEditData={fetchEditData} />
                </Grid>
            </Grid>
        </SubModuleLayout>
    );
}
