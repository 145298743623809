import React, { useState } from "react";
import { ExaminationSideBar } from "../../components";
import ExamSchedule from "../../components/Examination/ExamSchedules/ExamSchedule";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const ExamSchedulePage = () => {
  const nav = <ExaminationSideBar examSchedules />;

  return (
    <SubModuleLayout examinationMenuActive sideNav={nav}>
      <ExamSchedule />
    </SubModuleLayout>
  );
};

export default ExamSchedulePage;
