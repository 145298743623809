import axios from "axios";
import { Dispatch } from "redux";

import {
  StudentDispatchTypes,
  RESET_ALL_STATE,
  STUDENT_ADMISSION_LOADING,
  STUDENT_ADMISSION_SUCCESS,
  STUDENT_ADMISSION_ERROR,
  STUDENT_UPDATE_LOADING,
  STUDENT_UPDATE_ERROR,
  STUDENT_UPDATE_SUCCESS,
  STUDENT_LIST_LOADING,
  STUDENT_LIST_SUCCESS,
  STUDENT_LIST_ERROR,
  STUDENT_LIST_CLASS_LOADING,
  STUDENT_LIST_CLASS_SUCCESS,
  STUDENT_LIST_CLASS_ERROR,
  STUDENT_DISABLE_LOADING,
  STUDENT_DISABLE_ERROR,
  STUDENT_DISABLE_SUCCESS,
  DISABLED_STUDENT_LIST_LOADING,
  DISABLED_STUDENT_LIST_SUCCESS,
  DISABLED_STUDENT_LIST_ERROR,
  STUDENT_ENABLE_LOADING,
  STUDENT_ENABLE_ERROR,
  STUDENT_ENABLE_SUCCESS,
  STUDENT_BULK_DELETE_LOADING,
  STUDENT_BULK_DELETE_ERROR,
  STUDENT_BULK_DELETE_SUCCESS,
  STUDENT_BY_ID_LOADING,
  STUDENT_BY_ID_ERROR,
  STUDENT_BY_ID_SUCCESS,
  STUDENT_SEARCH_LOADING,
  STUDENT_SEARCH_ERROR,
  STUDENT_SEARCH_SUCCESS,
  GET_STUDENT_BY_CLASS_LOADING,
  GET_STUDENT_BY_CLASS_ERROR,
  GET_STUDENT_BY_CLASS_SUCCESS,
  SEARCH_STUDENT_LOADING,
  SEARCH_STUDENT_ERROR,
  SEARCH_STUDENT_SUCCESS,
  UPLOAD_STUDENT_INFO_LOADING,
  UPLOAD_STUDENT_INFO_SUCCESS,
  UPLOAD_STUDENT_INFO_ERROR,
  SEND_STUDENT_CREDENTIALS_SUCCESS,
  SEND_STUDENT_CREDENTIALS_ERROR,
  SEND_STUDENT_CREDENTIALS_LOADING,
  STUDENT_SIBLINGS_LOADING,
  STUDENT_SIBLINGS_ERROR,
  STUDENT_SIBLINGS_SUCCESS,
} from "./StudentActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import {
  HeaderConfig,
  HeaderConfigForDelete,
} from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";
import { setSnackbar } from "../../SnackbarAction";

//Student Admission
export const StudentAdmission =
  (data: any) =>
  async (dispatch: Dispatch<StudentDispatchTypes | SetSnackBarI | any>) => {
    try {
      dispatch({
        type: STUDENT_ADMISSION_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/student_info/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: STUDENT_ADMISSION_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Student Added Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: STUDENT_ADMISSION_ERROR,
        payload: error?.response?.data,
      });
    }
  };

//Student Admission from modal
export const StudentAdmission2 =
  (data: any, func: any) =>
  async (dispatch: Dispatch<StudentDispatchTypes | SetSnackBarI | any>) => {
    try {
      dispatch({
        type: STUDENT_ADMISSION_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/new-admission/`,
        data,
        HeaderConfig()
      );

      console.log(res);

      dispatch({
        type: STUDENT_ADMISSION_SUCCESS,
        payload: res.data,
      });

      func();

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Student Added Successfully",
        },
      });
    } catch (error: any) {
      console.log({ error });
      dispatch({
        type: STUDENT_ADMISSION_ERROR,
        payload: error?.response?.data,
      });
    } finally {
      try {
        dispatch({
          type: STUDENT_LIST_LOADING,
        });

        const res = await axios.get(
          `${ADMIN_API_URL}/student_info/?page=1`,
          HeaderConfig()
        );

        dispatch({
          type: STUDENT_LIST_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: STUDENT_LIST_ERROR,
        });
      }
    }
  };

export const GetStudentByName =
  (query: any) =>
  async (dispatch: Dispatch<StudentDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: STUDENT_SEARCH_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/student_info/?search=${query}`,
        HeaderConfig()
      );

      dispatch({
        type: STUDENT_SEARCH_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: STUDENT_SEARCH_ERROR,
      });
    }
  };

//Student List
export const GetStudentList =
  () => async (dispatch: Dispatch<StudentDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: STUDENT_LIST_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/student_info/`,
        HeaderConfig()
      );

      dispatch({
        type: STUDENT_LIST_SUCCESS,
        payload: res.data.results,
      });
    } catch (error) {
      dispatch({
        type: STUDENT_LIST_ERROR,
      });
    }
  };

export const GetStudentListWithPagination =
  (id: number) =>
  async (dispatch: Dispatch<StudentDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: STUDENT_LIST_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/student_info/?page=${id}`,
        HeaderConfig()
      );

      dispatch({
        type: STUDENT_LIST_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: STUDENT_LIST_ERROR,
      });
    }
  };

export const GetPaginatedStudent =
  (id: number, highest: number, grade: string, section: string) =>
  async (dispatch: Dispatch<StudentDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: STUDENT_LIST_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/student_info/?page=${id}&per_page=${highest}&&grade=${grade}&section=${section}`,
        HeaderConfig()
      );

      dispatch({
        type: STUDENT_LIST_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: STUDENT_LIST_ERROR,
      });
    }
  };

// Search student
export const GetSearchedStudentList =
  (student: any) =>
  async (dispatch: Dispatch<StudentDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: STUDENT_LIST_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/student_info/?academic_session=${student.academic_year}&grade=${student.class}&section=${student.section}`,
        HeaderConfig()
      );

      dispatch({
        type: STUDENT_LIST_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: STUDENT_LIST_ERROR,
      });
    }
  };

// Search student
export const GetSearchedDisabledStudentList =
  (student: any) =>
  async (dispatch: Dispatch<StudentDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DISABLED_STUDENT_LIST_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/student_info/?grade=${student.class}&section=${student.section}&disable=true`,
        HeaderConfig()
      );

      dispatch({
        type: DISABLED_STUDENT_LIST_SUCCESS,
        payload: res.data.results,
      });
      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "success",
      //     snackbarMessage: "Students Loaded",
      //   },
      // });
    } catch (error: any) {
      dispatch({
        type: DISABLED_STUDENT_LIST_ERROR,
      });

      try {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: error.response.statusText,
          },
        });
      } catch (error) {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "There is an error in the server",
          },
        });
      }
    }
  };

//get student by class
export const GetStudentClass =
  (gradeId: string, sectionId: string) =>
  async (dispatch: Dispatch<StudentDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: STUDENT_LIST_CLASS_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/student_info/?grade=${gradeId}&section=${sectionId}`,
        HeaderConfig()
      );

      dispatch({
        type: STUDENT_LIST_CLASS_SUCCESS,
        payload: res.data,
      });
    } catch (error: any) {
      dispatch({
        type: STUDENT_LIST_CLASS_ERROR,
      });

      try {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: error.response.statusText,
          },
        });
      } catch (error) {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "There is an error in the server",
          },
        });
      }
    }
  };

//Get Student By ID
export const GetStudentByID =
  (id: any) =>
  async (dispatch: Dispatch<StudentDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: STUDENT_BY_ID_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/student_info/${id}`,
        HeaderConfig()
      );

      dispatch({
        type: STUDENT_BY_ID_SUCCESS,
        payload: res.data,
      });
    } catch (error: any) {
      dispatch({
        type: STUDENT_BY_ID_ERROR,
      });

      try {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: error.response.statusText,
          },
        });
      } catch (error) {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "There is an error in the server",
          },
        });
      }
    }
  };

//Update Student
export const UpdateStudent =
  (id: any, data: any) =>
  async (dispatch: Dispatch<StudentDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: STUDENT_UPDATE_LOADING,
      });

      const res = axios.put(
        `${ADMIN_API_URL}/student_info/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: STUDENT_UPDATE_SUCCESS,
        payload: (await res).data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Studet Updated Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: STUDENT_UPDATE_ERROR,
        payload: error?.response?.data,
      });
    }
  };

//Student Disable
export const DisableStudent =
  (id: string | null, remarks: any) =>
  async (dispatch: Dispatch<StudentDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: STUDENT_DISABLE_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/student_info/disable_or_enable_student/?student=${id}`,
        remarks,
        HeaderConfig()
      );

      dispatch({
        type: STUDENT_DISABLE_SUCCESS,
        payload: { id: id, remarks: remarks },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Student Disabled Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: STUDENT_DISABLE_ERROR,
      });
      try {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: error.response.statusText,
          },
        });
      } catch (error) {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "There is an error in the server",
          },
        });
      }
    }
  };

//Student List
export const GetDisabledStudentList =
  () => async (dispatch: Dispatch<StudentDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DISABLED_STUDENT_LIST_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/student_info/?disable=true`,
        HeaderConfig()
      );

      dispatch({
        type: DISABLED_STUDENT_LIST_SUCCESS,
        payload: res.data.results,
      });
    } catch (error: any) {
      dispatch({
        type: DISABLED_STUDENT_LIST_ERROR,
      });

      try {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: error.response.statusText,
          },
        });
      } catch (error) {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "There is an error in the server",
          },
        });
      }
    }
  };

//Enable Disable
export const EnableStudent =
  (id: string | null, remarks: any) =>
  async (dispatch: Dispatch<StudentDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: STUDENT_ENABLE_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/student_info/disable_or_enable_student/?student=${id}`,
        remarks,
        HeaderConfig()
      );

      dispatch({
        type: STUDENT_ENABLE_SUCCESS,
        payload: { id: id, remarks: remarks },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Student Enabled Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: STUDENT_ENABLE_ERROR,
      });

      try {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: error.response.statusText,
          },
        });
      } catch (error) {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "There is an error in the server",
          },
        });
      }
    }
  };

//Student Bulk Delete
export const BulkDeleteStudent =
  (data: any) =>
  async (dispatch: Dispatch<StudentDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: STUDENT_BULK_DELETE_LOADING,
      });

      await axios.delete(`${ADMIN_API_URL}/student_info/bulk_delete/`, {
        headers: HeaderConfigForDelete(),
        data: data,
      });

      dispatch({
        type: STUDENT_BULK_DELETE_SUCCESS,
        payload: { data: data },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Bulk Student Deleted Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: STUDENT_BULK_DELETE_ERROR,
      });

      try {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: error.response.statusText,
          },
        });
      } catch (error) {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "There is an error in the server",
          },
        });
      }
    }
  };

export const StudentSearch =
  (grade_id: any, section_id?: any) =>
  async (dispatch: Dispatch<StudentDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: STUDENT_SEARCH_LOADING,
      });

      let url = `${ADMIN_API_URL}/student_info/?grade=${grade_id}&per_page=1000`;

      if (section_id) {
        url = `${ADMIN_API_URL}/student_info/?grade=${grade_id}&section=${section_id}&per_page=1000`;
      }

      const res = await axios.get(url, HeaderConfig());

      dispatch({
        type: STUDENT_SEARCH_SUCCESS,
        payload: res.data,
      });
    } catch (error: any) {
      dispatch({
        type: STUDENT_SEARCH_ERROR,
      });

      try {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: error.response.statusText,
          },
        });
      } catch (error) {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "There is an error in the server",
          },
        });
      }
    }
  };

export const GetStudentByClass =
  (grade: string, section: string) =>
  async (dispatch: Dispatch<StudentDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_STUDENT_BY_CLASS_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/student_info?grade=${grade}&section=${section}&per_page=100`,
        HeaderConfig()
      );

      dispatch({
        type: GET_STUDENT_BY_CLASS_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_STUDENT_BY_CLASS_ERROR,
      });
    }
  };

export const SearchStudent =
  (value: string, page: number, per_page: number) =>
  async (dispatch: Dispatch<StudentDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: SEARCH_STUDENT_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/student_info/?page=${page}&search=${value}&per_page=${per_page}`,
        HeaderConfig()
      );

      dispatch({
        type: SEARCH_STUDENT_SUCCESS,
        payload: res.data,
      });
    } catch (error: any) {
      dispatch({
        type: SEARCH_STUDENT_ERROR,
      });

      try {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: error.response.statusText,
          },
        });
      } catch (error) {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "There is an error in the server",
          },
        });
      }
    }
  };

export const UploadStudentInfo =
  (grade: string, data: any, section?: string) =>
  async (dispatch: Dispatch<StudentDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPLOAD_STUDENT_INFO_LOADING,
      });
      const res = await axios.post(
        `${ADMIN_API_URL}/upload-students/?grade=${grade}&section=${
          section ? section : ""
        }`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPLOAD_STUDENT_INFO_SUCCESS,
        payload: res.data.results,
      });

      dispatch<any>(
        setSnackbar(true, "success", "Student data imported Successfully")
      );
    } catch (error) {
      dispatch({
        type: UPLOAD_STUDENT_INFO_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "There is an error in the server",
        },
      });
    }
  };

export const SendStudentCredentialSms =
  (grade: string, section?: string | null) =>
  async (dispatch: Dispatch<StudentDispatchTypes | SetSnackBarI>) => {
    let url = `${ADMIN_API_URL}/send/authentication-credentials/?grade=${grade}`;

    if (section) {
      url = `${ADMIN_API_URL}/send/authentication-credentials/?grade=${grade}&section=${section}`;
    }
    dispatch({
      type: SEND_STUDENT_CREDENTIALS_LOADING,
    });
    try {
      await axios.post(url, HeaderConfig());

      dispatch({
        type: SEND_STUDENT_CREDENTIALS_SUCCESS,
      });

      dispatch<any>(setSnackbar(true, "success", "Sms sent successfully"));
    } catch (error) {
      dispatch({
        type: SEND_STUDENT_CREDENTIALS_ERROR,
      });
      dispatch<any>(setSnackbar(true, "warning", "Failed to send sms"));
    }
  };

//get student siblings
export const GetStudentSiblings =
  (id: string) =>
  async (dispatch: Dispatch<StudentDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: STUDENT_SIBLINGS_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/student_info/${id}/get_student_siblings`,
        HeaderConfig()
      );

      dispatch({
        type: STUDENT_SIBLINGS_SUCCESS,
        payload: res.data,
      });
    } catch (error: any) {
      dispatch({
        type: STUDENT_SIBLINGS_ERROR,
      });

      try {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: error.response.statusText,
          },
        });
      } catch (error) {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Error on fetching sibling (s)",
          },
        });
      }
    }
  };

  export const resetStudentState =
  () =>
  async (dispatch: Dispatch<StudentDispatchTypes | SetSnackBarI>) => {
      dispatch({
        type: RESET_ALL_STATE,
      });

  };
