import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import { default as Tab } from '@material-ui/core/Tab';
import {
  DashboardOutlined,
  SchoolOutlined,
  LibraryBooksOutlined,
  WcOutlined,
  MenuBookOutlined,
  Assignment,
  SmsOutlined,
  DonutLargeOutlined,
  DirectionsBusOutlined,
  QueueOutlined,
  PaymentOutlined,
  ArrowBack,
  Settings,
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import Header from '../Header/Header';
import {
  Button,
  ClickAwayListener,
  IconButton,
  SvgIconTypeMap,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

interface NavItemTypeI {
  text: string;
  url: string;
  dataSet?: Array<object>;
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
}

export const ADMIN_MAIN_STUDENT_ROUTE = [
  {
    text: 'Student Details',
    url: 'student',
  },
  // {
  //   text: "Student Admission",
  //   url: "student-admission",
  // },
  {
    text: 'Guardian Details',
    url: 'guardian-details',
  },
  {
    text: 'Disabled Student',
    url: 'disabled-student',
  },
  // {
  //   text: "Bulk Delete",
  //   url: "bulk-delete",
  // },
  {
    text: 'Student Roll Number',
    url: 'student-roll-num',
  },
  {
    text: 'Student Categories',
    url: 'student-categories',
  },
  {
    text: 'Student House',
    url: 'student-house',
  },
  {
    text: 'Student Feedback',
    url: 'student-complaint',
  },
  {
    text: 'Student Leave',
    url: 'student-leave',
  },
  {
    text: 'Student Attendance Report',
    url: 'student-attendance-report',
  },
];

export const ADMIN_MAIN_ACADEMIC_ROUTE = [
  { text: 'Section', url: 'section' },
  { text: 'Grade', url: 'grade' },
  { text: 'Class', url: 'class' },
  { text: 'Subjects', url: 'subject' },
  {
    text: 'Subjects Group',
    url: 'subject-group',
  },
  { text: 'Timetable', url: 'time-table' },
  {
    text: 'Assign Class Teacher',
    url: 'assign-class-teacher',
  },
  {
    text: 'Enote Category',
    url: 'enote-category',
  },
];

export const ADMIN_MAIN_HUMAN_RESOURCE_ROUTE = [
  { text: 'Designation', url: 'designation' },
  { text: 'Department', url: 'department' },
  { text: 'Add Staff', url: 'add-staff' },
  { text: 'Staff List', url: 'staff' },
  {
    text: 'Disabled Staffs',
    url: 'disabled-staffs',
  },
  {
    text: 'Take Attendance',
    url: 'staff-attendance',
  },
  {
    text: 'Attendance Report',
    url: 'staff-attendance-report',
  },
  { text: 'Leave Type', url: 'leave-type' },
  { text: 'Leave', url: 'leave' },
  {
    text: 'Apply Leave',
    url: 'apply-leave-type',
  },
  // { text: 'Staff ID Card', url: 'staff-id-card', activeStatus: idCard },
];

export const ADMIN_MAIN_EXAMINATION_ROUTE = [
  { text: 'Create Exam', url: 'examination' },
  {
    text: 'Exam Schedules',
    url: 'exam-schedule',
  },
  { text: 'Subject Class', url: 'subject-class' },
  { text: 'Add Marks', url: 'add-marks' },
  { text: 'Grades', url: 'grades' },
  { text: 'APE Factor', url: 'ape-factor' },
  { text: 'Results', url: 'results' },
];

export const ADMIN_MAIN_COMMUNICATION_ROUTE = [
  { text: 'Announcement', url: 'communication' },
  {
    text: 'Announcement Draft',
    url: 'announcement-draft',
  },
  { text: 'SMS', url: 'sms' },
  {
    text: 'SMS Log',
    url: 'sms-log',
  },
  { text: 'SMS Template', url: 'sms-templates' },
  { text: 'Send Results', url: 'send-results' },
  {
    text: 'Announcement Type',
    url: 'announcement-type',
  },
  { text: 'Meeting Type', url: 'meeting-type' },
  { text: 'Meeting', url: 'meeting' },
];

export const ADMIN_MAIN_REPORT_ROUTE = [
  { text: 'ID Card', url: 'report' },
  {
    text: 'Admit Card',
    url: 'admit-card',
  },
  {
    text: 'Marksheet',
    url: 'marksheet',
  },
  {
    text: 'Marksheet Ledger',
    url: 'marksheet-ledger',
  },
  {
    text: 'Gradesheet',
    url: 'gradesheet',
  },
  {
    text: 'Character Certificate',
    url: 'character-certificate',
  },
  {
    text: 'Result Template',
    url: 'marksheet-templates',
  },
  {
    text: 'Character Certificate Template',
    url: 'character-certificate-templates',
  },
  {
    text: 'ID Card Template',
    url: 'id-cards-templates',
  },
  {
    text: 'Admit Card Template',
    url: 'admit-cards-templates',
  },
  {
    text: 'Reports Setting',
    url: 'reports-setting',
  },
];

export const ADMIN_MAIN_FINANCE_PRIMARY_ROUTE = [
  {
    text: 'Fee Head',
    url: 'fee-type',
  },
  {
    text: 'Fee Configuration',
    url: 'fees',
  },
  {
    text: 'Apply Fee',
    url: 'apply-fee',
  },
  {
    text: 'Applied Fee',
    url: 'applied-fees',
  },
  {
    text: 'Apply Discount',
    url: 'apply-discount',
  },
  {
    text: 'Opening Balance',
    url: 'opening-balance',
  },
  {
    text: 'Collect Fees',
    url: 'collect-fees',
  },
  {
    text: 'Fine Type',
    url: 'fine-type',
  },
  {
    text: 'Miscellaneous Fee',
    url: 'miscellaneous-fee',
  },
  {
    text: 'Bill Adjustment',
    url: 'bill-adjustment',
  },
  // {
  //   text: "Bill Adjustment",
  //   url: "miscellaneous-fee",
  // },
  // {
  //   text: "Discount Type",
  //   url: "discount-type",
  // },
  {
    text: 'Payment Method',
    url: 'payment-method',
  },
  // {
  //   text: "Income Type",
  //   url: "income",
  // },
  // {
  //   text: "Add Income",
  //   url: "add-income",
  // },
  // {
  //   text: "Expense Type",
  //   url: "expenses",
  // },
  // {
  //   text: "Add Expense",
  //   url: "add-expenses",
  // },
];

export const ADMIN_MAIN_FINANCE_SECONDARY_ROUTE = [
  {
    text: 'Day Book',
    url: 'day-book',
  },
  {
    text: 'Fee Statement',
    url: 'fee-statement',
  },
  {
    text: 'Fee Income',
    url: 'fee-income',
  },
  {
    text: 'Due Report',
    url: 'due-report',
  },
  {
    text: 'Due Alert',
    url: 'due-alert',
  },
  {
    text: 'Void Report',
    url: 'void-report',
  },
  // {
  //   text: "Void Report",
  //   url: "void-report",
  // },
  // {
  //   text: "Fee Type Report",
  //   url: "fee-type-report",
  // },
  // {
  //   text: "Monthly Report",
  //   url: "monthly-report",
  // },
  {
    text: 'Discount Summary',
    url: 'discount-summary',
  },
];

export const ADMIN_MAIN_EVENT_ROUTE = [
  { text: 'Event Type', url: 'event' },
  {
    text: 'Events',
    url: 'events',
  },
];

export const ADMIN_MAIN_TRANSPORT_ROUTE = [
  {
    text: 'Vehicles',
    url: 'vehicles',
  },
  {
    text: 'Routes',
    url: 'routes',
  },
  {
    text: 'Test Routes',
    url: 'test-routes',
  },
  {
    text: 'Bus User',
    url: 'bus-user',
  },
];

export const ADMIN_MAIN_SCHOLARSHIP_ROUTE = [
  {
    text: 'Scholarship Type',
    url: 'scholarship-type',
  },
  {
    text: 'Apply Scholarship',
    url: 'apply-scholarship',
  },
  {
    text: 'Scholarship Report',
    url: 'scholarship-report',
  },
];

export const ADMINISTRATOR_MAIN_ROUTE: NavItemTypeI[] = [
  {
    text: 'Dashboard',
    icon: DashboardOutlined,
    url: '/',
  },
  {
    text: 'Student',
    icon: SchoolOutlined,
    url: '/student',
    dataSet: ADMIN_MAIN_STUDENT_ROUTE,
  },
  {
    text: 'Academic',
    icon: LibraryBooksOutlined,
    url: '/section',
    dataSet: ADMIN_MAIN_ACADEMIC_ROUTE,
  },
  {
    text: 'Human Resource',
    icon: WcOutlined,
    url: '/designation',
    dataSet: ADMIN_MAIN_HUMAN_RESOURCE_ROUTE,
  },
  {
    text: 'Examinations',
    icon: MenuBookOutlined,
    url: '/examination',
    dataSet: ADMIN_MAIN_EXAMINATION_ROUTE,
  },
  {
    text: 'Communications',
    icon: SmsOutlined,
    url: '/communication',
    dataSet: ADMIN_MAIN_COMMUNICATION_ROUTE,
  },
  {
    text: 'Reports',
    icon: Assignment,
    url: '/report',
    dataSet: ADMIN_MAIN_REPORT_ROUTE,
  },
  {
    text: 'Finance',
    icon: QueueOutlined,
    url: '/collect-fees',
    dataSet: [
      ...ADMIN_MAIN_FINANCE_PRIMARY_ROUTE,
      ...ADMIN_MAIN_FINANCE_SECONDARY_ROUTE,
    ],
  },
  {
    text: 'Event',
    icon: DonutLargeOutlined,
    url: '/event',
    dataSet: ADMIN_MAIN_EVENT_ROUTE,
  },
  {
    text: 'Transport',
    icon: DirectionsBusOutlined,
    url: '/vehicles',
    dataSet: ADMIN_MAIN_TRANSPORT_ROUTE,
  },
  {
    text: 'Scholarship',
    icon: SchoolOutlined,
    url: '/scholarship-type',
    dataSet: ADMIN_MAIN_SCHOLARSHIP_ROUTE,
  },
];
