import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useIDCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1),
    },
    potraitMode: {
      minWidth: "350px",
      maxWidth: "400px",
      // overflow: "hidden"
    },
    content: {
      minWidth: "100%",
      // alignItems: "center",
      justifyContent: "space-between",
      paddingBottom: "16px!important",
    },
    landContent: {
      padding: "10px",
    },
    column: {
      display: "flex",
      flexDirection: "column",
    },
    rowContent: {
      // minHeight: "28vh",
      display: "flex",
      flexDirection: "row",
    },
    bgColor: {
      backgroundColor: "#3780ce",
    },
    logo: {
      background: "#fff",
      borderRadius: "50%",
      border: "1px solid #fff",
    },
    logoImage: {
      borderRadius: "50%",
      alignItems: "center",
      border: "4px solid #fff",
      display: "flex",
      justifyContent: "center",
      height: "70px",
      objectFit: "contain",
      // width: "75px",
      // paddingTop: "56.25%", // 16:9
    },
    idImage: {
      borderRadius: "50%",
      alignItems: "center",
      border: "4px solid #fff",
      display: "flex",
      justifyContent: "center",
      height: "150px",
      width: "150px",
      margin: "-75px auto 0 auto",
      // paddingTop: "56.25%", // 16:9
    },
    landIdImage: {
      margin: "auto",
      border: "4px solid #fff",
      display: "flex",
      height: "160px",
      width: "160px",
      borderRadius: "12px",
      boxShadow:
        "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    },
    teacherImage: {
      borderRadius: "50%",
      // border: "4px solid #3780ce",
      height: "150px",
      width: "150px",
    },
    teacherBorder: {
      // borderRadius: "20px",
      // borderStyle: "inset",
    },
    mainTitle: {
      textTransform: "uppercase",
      color: "#3780ce",
    },
    title: {
      alignItems: "center",
      width: "160px",
      backgroundColor: "rgba(0, 0, 0, 0.87)",
      color: "#fff",
    },
    cardBg: {
      position: "absolute",
      width: "80px",
      height: "80px",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat ",
      // right: 325,
      // top: 100,
      opacity: 0.35,
    },
    buttomRight: {
      right: 20,
      bottom: 20,
    },
    buttomLeft: {
      left: 20,
      bottom: 20,
    },
    center: {
      top: "40%",
      left: "30%",
    },
    footer: {
      padding: "10px",
    },
  })
);
