export const GET_BUS_USER_LOADING = "GET_BUS_USER_LOADING";
export const GET_BUS_USER_ERROR = "GET_BUS_USER_ERROR";
export const GET_BUS_USER_SUCCESS = "GET_BUS_USER_SUCCESS";

export const ADD_BUS_USER_LOADING = "ADD_BUS_USER_LOADING";
export const ADD_BUS_USER_ERROR = "ADD_BUS_USER_ERROR";
export const ADD_BUS_USER_SUCCESS = "ADD_BUS_USER_SUCCESS";

export const UPDATE_BUS_USER_LOADING = "UPDATE_BUS_USER_LOADING";
export const UPDATE_BUS_USER_ERROR = "UPDATE_BUS_USER_ERROR";
export const UPDATE_BUS_USER_SUCCESS = "UPDATE_BUS_USER_SUCCESS";

export const DELETE_BUS_USER_LOADING = "DELETE_BUS_USER_LOADING";
export const DELETE_BUS_USER_ERROR = "DELETE_BUS_USER_ERROR";
export const DELETE_BUS_USER_SUCCESS = "DELETE_BUS_USER_SUCCESS";

export type BusUserTypeI = {
  student_id: string;
  student_user__first_name: string;
  student_user__last_name: string;
  academic_info__grade__name: string | null;
  academic_info__section__name: string | null;
  transportation_detail_id: string;
  transportation_detail__driver: string;
  transportation_detail__route_from: string;
  transportation_detail__route_to: string;
  transportation_detail__bus_number: string;
};

//===============================<START>GET BusUser<START>======================//

export interface GetBusUserLoading {
  type: typeof GET_BUS_USER_LOADING;
}

export interface GetBusUserError {
  type: typeof GET_BUS_USER_ERROR;
}

export interface GetBusUserSuccess {
  type: typeof GET_BUS_USER_SUCCESS;
  payload: BusUserTypeI[];
}

//===============================<END>GET BusUser<END>======================//

//===============================<START>ADD BusUser<START>======================//

export interface AddBusUserLoading {
  type: typeof ADD_BUS_USER_LOADING;
}

export interface AddBusUserError {
  type: typeof ADD_BUS_USER_ERROR;
}

export interface AddBusUserSuccess {
  type: typeof ADD_BUS_USER_SUCCESS;
  payload: BusUserTypeI;
}

//===============================<END>ADD BusUser<END>======================//

//===============================<START>UPDATE BusUser<START>======================//

export interface UpdateBusUserLoading {
  type: typeof UPDATE_BUS_USER_LOADING;
}

export interface UpdateBusUserError {
  type: typeof UPDATE_BUS_USER_ERROR;
}

export interface UpdateBusUserSuccess {
  type: typeof UPDATE_BUS_USER_SUCCESS;
  payload: BusUserTypeI;
}

//===============================<END>UPDATE BusUser<END>======================//

//===============================<START>DELETE BusUser<START>======================//

export interface DeleteBusUserLoading {
  type: typeof DELETE_BUS_USER_LOADING;
}

export interface DeleteBusUserError {
  type: typeof DELETE_BUS_USER_ERROR;
}

export interface DeleteBusUserSuccess {
  type: typeof DELETE_BUS_USER_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE BusUser<END>======================//

export type BusUserDispatchTypes =
  | GetBusUserLoading
  | GetBusUserError
  | GetBusUserSuccess
  | AddBusUserLoading
  | AddBusUserError
  | AddBusUserSuccess
  | UpdateBusUserLoading
  | UpdateBusUserError
  | UpdateBusUserSuccess
  | DeleteBusUserLoading
  | DeleteBusUserError
  | DeleteBusUserSuccess;
