import React from "react";
import { ReportSideBar } from "../../../../components";
import MyMarksheet from "../../../../components/Reports/Templates/Marksheet/MyMarksheet";
import SubModuleLayout from "../../../../components/Reusable/Layouts/SubModuleLayout";

const MarksheetTemplatePage = () => {
  const nav = <ReportSideBar marksheet_template />;
  return (
    <SubModuleLayout reportMenuActive sideNav={nav}>
      <MyMarksheet />
    </SubModuleLayout>
  );
};

export default MarksheetTemplatePage;
