import {
  GET_GRADESHEET_TEMPLATE_LOADING,
  GET_GRADESHEET_TEMPLATE_ERROR,
  GET_GRADESHEET_TEMPLATE_SUCCESS,
  ADD_GRADESHEET_TEMPLATE_LOADING,
  ADD_GRADESHEET_TEMPLATE_ERROR,
  ADD_GRADESHEET_TEMPLATE_SUCCESS,
  ACTIVATE_OR_DEACTIVATE_GRADESHEET_TEMPLATE_LOADING,
  ACTIVATE_OR_DEACTIVATE_GRADESHEET_TEMPLATE_ERROR,
  ACTIVATE_OR_DEACTIVATE_GRADESHEET_TEMPLATE_SUCCESS,
  DELETE_GRADESHEET_TEMPLATE_LOADING,
  DELETE_GRADESHEET_TEMPLATE_ERROR,
  DELETE_GRADESHEET_TEMPLATE_SUCCESS,
  GET_SINGLE_GRADESHEET_TEMPLATE_LOADING,
  GET_SINGLE_GRADESHEET_TEMPLATE_ERROR,
  GET_SINGLE_GRADESHEET_TEMPLATE_SUCCESS,
  UPDATE_GRADESHEET_TEMPLATE_LOADING,
  UPDATE_GRADESHEET_TEMPLATE_ERROR,
  UPDATE_GRADESHEET_TEMPLATE_SUCCESS,
  GradeSheetTemplateDispatchTypes,
} from "../../actions/Templates/GradeSheet/GradeSheetTemplateActionTypes";

interface InitialStateI {
  loading: boolean;
  gradesheet_templates: any;
  gradesheet_template: any;
  add_or_update: boolean;
}

const initialState: InitialStateI = {
  loading: false,
  gradesheet_templates: [],
  gradesheet_template: null,
  add_or_update: false,
};

const GradeSeetTemplateReducer = (
  state: InitialStateI = initialState,
  action: GradeSheetTemplateDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_GRADESHEET_TEMPLATE_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case GET_GRADESHEET_TEMPLATE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_GRADESHEET_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        gradesheet_templates: action.payload,
      };

    case GET_SINGLE_GRADESHEET_TEMPLATE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_SINGLE_GRADESHEET_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        gradesheet_template: action.payload,
      };

    case GET_SINGLE_GRADESHEET_TEMPLATE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case ADD_GRADESHEET_TEMPLATE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case ADD_GRADESHEET_TEMPLATE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case ADD_GRADESHEET_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        gradesheet_templates: [...state.gradesheet_templates, action.payload],
        add_or_update: true,
      };

    case UPDATE_GRADESHEET_TEMPLATE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_GRADESHEET_TEMPLATE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case UPDATE_GRADESHEET_TEMPLATE_SUCCESS:
      const _current_data: any = state.gradesheet_templates;
      const _index: number = _current_data.findIndex(
        (element: any) => element.id === action.payload.id
      );
      _current_data[_index] = action.payload;

      return {
        ...state,
        loading: false,
        gradesheet_templates: _current_data,
        add_or_update: true,
      };

    case ACTIVATE_OR_DEACTIVATE_GRADESHEET_TEMPLATE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case ACTIVATE_OR_DEACTIVATE_GRADESHEET_TEMPLATE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case ACTIVATE_OR_DEACTIVATE_GRADESHEET_TEMPLATE_SUCCESS:
      const current_data: any = state.gradesheet_templates;
      const index: number = current_data.findIndex(
        (element: any) => element.id === action.payload.id
      );
      current_data[index] = action.payload;

      return {
        ...state,
        loading: false,
        gradesheet_templates: current_data,
      };

    case DELETE_GRADESHEET_TEMPLATE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_GRADESHEET_TEMPLATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case DELETE_GRADESHEET_TEMPLATE_SUCCESS:
      const new_data: any = state.gradesheet_templates;
      const item_index = new_data.findIndex(
        (element: any) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return {
        ...state,
        loading: false,
        gradesheet_templates: new_data,
      };

    default:
      return state;
  }
};

export default GradeSeetTemplateReducer;
