import axios from "axios";
import { Dispatch } from "redux";

import {
  UPDATE_PROFILE_PICTURE_ERROR,
  UPDATE_PROFILE_PICTURE_LOADING,
  UPDATE_PROFILE_PICTURE_SUCCESS,
  ProfilePictureTypes,
} from "./ProfilePictureActionTypes";

import { ADMIN_API_URL } from "../../config/SERVER_API_URLS";
import { SET_SNACKBAR, SetSnackBarI } from "../SnackbarActionTypes";
import { HeaderConfig } from "../Auth/Login/LoginAction";

export const UpdateProfilePicture =
  (data: any) =>
  async (dispatch: Dispatch<ProfilePictureTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_PROFILE_PICTURE_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/update/profile-picture/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_PROFILE_PICTURE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_PROFILE_PICTURE_ERROR,
      });
    }
  };
