import {
    ADD_KHALTI_PAYMENT_LOADING,
    ADD_KHALTI_PAYMENT_SUCCESS,
    ADD_KHALTI_PAYMENT_ERROR,
    RequsetKhaltiDataI,
    KhaltiPaymentDispatchTypes,
} from "../../actions/Fees/DigitalPayment/Khalti/KhaltiActionTypes";

interface InitialStateI {
    loading: boolean;
    khalti_data: any;
}

const initialState: InitialStateI = {
    loading: false,
    khalti_data: null,
};

const KhaltiReducer = (
    state: InitialStateI = initialState,
    action: KhaltiPaymentDispatchTypes
): InitialStateI => {
    switch (action.type) {

        case ADD_KHALTI_PAYMENT_LOADING:
            return {
                ...state,
                loading: true,
            };

        case ADD_KHALTI_PAYMENT_ERROR:
            return {
                ...state,
                loading: false,
            };

        case ADD_KHALTI_PAYMENT_SUCCESS:
            return {
                loading: false,
                khalti_data: action.payload,
            };


        default:
            return state;
    }
};

export default KhaltiReducer;
