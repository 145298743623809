import { Grid, Paper, Tooltip } from '@material-ui/core';
import { Add, PictureAsPdfOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  DeleteStaffDocument,
  GetStaffDocument,
} from '../../../../../actions/HumanResource/StaffDocument/StaffDocumentAction';
import { DocumentTypeI } from '../../../../../actions/HumanResource/StaffDocument/StaffDocumentActionTypes';
import { RootStore } from '../../../../../store';
import { useTable } from '../../../../Reusable';
import AddNewButton from '../../../../Reusable/Buttons/AddNewButton';
import {
  DownloadButton,
  ItemDeleteButton,
} from '../../../../Reusable/Buttons/TableButton';
import CustomizedDialogs from '../../../../Reusable/Dialogs/DeleteDialog';
import Popups from '../../../../Reusable/Dialogs/Popups';
import { HeadCellsI } from '../../../../Student/StudentProfile/File/Document';
import { useTableStyles } from '../../../../Styles/TableStyles';
import StaffUpload from './StaffUpload';
import boy from '../../../../../images/boy.png';
import { AiOutlineFileExclamation } from 'react-icons/ai';

const headCells: HeadCellsI[] = [
  { id: 'file', label: 'File' },
  { id: 'file_name', label: 'File Name' },
  { id: 'action', label: 'Action' },
];

export const downloadEmployeeData = (file: string) => {
  fetch(`${file}`).then((response) => {
    response.blob().then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      const ext = response.url.split('.').pop();
      a.href = url;
      a.download = `file_${Date.now()}.${ext}`;
      a.click();
    });
    // window.location.href = "/";
  });
};

function StaffDocument(props: any) {
  const classes = useTableStyles();
  const dispatch = useDispatch();

  const { staffID } = useParams<{ staffID: string }>();

  // State Declarations
  const [tableData, setTableData] = useState<DocumentTypeI[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const [openPopup, setOpenPopup] = useState(false);

  const [editData, setEditData] = useState<any>([]);

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);

  // Retrieving re-usable components from useTable
  const { StyledTableCell, TableContainer, StyledTableRow } =
    useTable(headCells);

  // Function for handling action icons click event
  const handleActionClicked = () => {
    alert('Action Clicked!');
  };

  const handleClose = () => {
    setOpenPopup(false);
  };

  const StaffDocument = useSelector((state: RootStore) => state.staffDocument);

  useEffect(() => {
    props.Documents == true && dispatch(GetStaffDocument(staffID));
  }, []);

  useEffect(() => {
    const data = StaffDocument.staff_document;

    setTableData(StaffDocument.staff_document);
    setLoading(StaffDocument.loading);
  }, []);

  const handleUpload = () => {
    setOpenPopup(true);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const handleDeleteModal = (value: boolean, id: string) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteStaffDocument(itemId));
    setDeleteModalOpen(false);
  };

  const ImageThumbnail = ({ image }: { image: string }) => {
    const acceptedGroup = ['jpg', 'jpeg', 'png'];

    const get_url_extension = (url: any) => {
      if (url) {
        return url.split(/[#?]/)[0].split('.').pop().trim();
      }
      return '';
    };

    if (acceptedGroup.includes(get_url_extension(image))) {
      return (
        <img
          src={image}
          alt={image}
          height="30px"
          width="30px"
          style={{ objectFit: 'cover' }}
        />
      );
    }
    return <AiOutlineFileExclamation fontSize="24px" />;
  };

  const getCustomTableRow = (item: any) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {/* {item.document.split(".").pop() == "pdf" ? (
            <PictureAsPdfOutlined style={{ color: "#c91a69" }} />
          ) : (
            <img src={item.document} alt="" height="30px" width="30px" />
          )} */}
          <ImageThumbnail image={item.document} />
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.title}
        </StyledTableCell>

        <StyledTableCell align="center" className={classes.cell}>
          <DownloadButton
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              downloadEmployeeData(item.document)
            }
          />
          {props.hasDeleteBtn && (
            <ItemDeleteButton
              onClick={(
                event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
              ) => handleDeleteModal(true, item.id)}
            ></ItemDeleteButton>
          )}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <>
      <Paper elevation={0} className={classes.roottable}>
        <Grid container>
          <Grid item xs={6}></Grid>
          {props.hasUpload && (
            <Grid item xs={6}>
              <Grid container justifyContent="flex-end">
                <Tooltip title="Upload New file">
                  <AddNewButton
                    variant="outlined"
                    color="secondary"
                    startIcon={<Add />}
                    onClick={handleUpload}
                    style={{ marginBottom: '10px' }}
                  >
                    Upload New file
                  </AddNewButton>
                </Tooltip>
              </Grid>
            </Grid>
          )}
        </Grid>
        <TableContainer
          loading={loading}
          headCells={headCells}
          items={
            props.Documents == true
              ? StaffDocument.staff_document
              : props.Documents
          }
          getCustomTableRow={getCustomTableRow}
          search_name="title"
        />
        <Popups
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          onClose={handleClose}
          title="Upload a file"
          colored
          scroll="body"
          maxWidth="sm"
        >
          <StaffUpload handleClose={handleClose} />
        </Popups>
        <CustomizedDialogs
          open={deleteModalOpen}
          onClose={handleModalClose}
          deleteConfirmed={onDeleteConfirmed}
          dialogTitle="Are you sure to delete this record?"
          okText="Delete"
        />
      </Paper>
    </>
  );
}

export default StaffDocument;
