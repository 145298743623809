export const GET_APE_LOADING = "GET_APE_LOADING";
export const GET_APE_ERROR = "GET_APE_ERROR";
export const GET_APE_SUCCESS = "GET_APE_SUCCESS";

export const ADD_APE_LOADING = "ADD_APE_LOADING";
export const ADD_APE_ERROR = "ADD_APE_ERROR";
export const ADD_APE_SUCCESS = "ADD_APE_SUCCESS";

export const UPDATE_APE_LOADING = "UPDATE_APE_LOADING";
export const UPDATE_APE_ERROR = "UPDATE_APE_ERROR";
export const UPDATE_APE_SUCCESS = "UPDATE_APE_SUCCESS";

export const DELETE_APE_LOADING = "DELETE_APE_LOADING";
export const DELETE_APE_ERROR = "DELETE_APE_ERROR";
export const DELETE_APE_SUCCESS = "DELETE_APE_SUCCESS";

export type APETypeI = {
  id: string;
  title: string;
  count?: number;
};

//===============================<START>GET APE<START>======================//

export interface GetAPELoading {
  type: typeof GET_APE_LOADING;
}

export interface GetAPEError {
  type: typeof GET_APE_ERROR;
}

export interface GetAPESuccess {
  type: typeof GET_APE_SUCCESS;
  payload: APETypeI[];
}

//===============================<END>GET APE<END>======================//

//===============================<START>ADD APE<START>======================//

export interface AddAPELoading {
  type: typeof ADD_APE_LOADING;
}

export interface AddAPEError {
  type: typeof ADD_APE_ERROR;
  payload: any;
}

export interface AddAPESuccess {
  type: typeof ADD_APE_SUCCESS;
  payload: APETypeI;
}

//===============================<END>ADD APE<END>======================//

//===============================<START>UPDATE APE<START>======================//

export interface UpdateAPELoading {
  type: typeof UPDATE_APE_LOADING;
}

export interface UpdateAPEError {
  type: typeof UPDATE_APE_ERROR;
  payload: any;
}

export interface UpdateAPESuccess {
  type: typeof UPDATE_APE_SUCCESS;
  payload: APETypeI;
}

//===============================<END>UPDATE APE<END>======================//

//===============================<START>DELETE APE<START>======================//

export interface DeleteAPELoading {
  type: typeof DELETE_APE_LOADING;
}

export interface DeleteAPEError {
  type: typeof DELETE_APE_ERROR;
}

export interface DeleteAPESuccess {
  type: typeof DELETE_APE_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE APE<END>======================//

export type APEDispatchTypes =
  | GetAPELoading
  | GetAPEError
  | GetAPESuccess
  | AddAPELoading
  | AddAPEError
  | AddAPESuccess
  | UpdateAPELoading
  | UpdateAPEError
  | UpdateAPESuccess
  | DeleteAPELoading
  | DeleteAPEError
  | DeleteAPESuccess;
