import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Button, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import getFullName from "../../utils/getFullName";
import moment from "moment";
import { LeaveApproveOrDecline } from "../../../actions/Student/StudentLeave/LeaveAction";
import { GetDashboardData } from "../../../actions/Dashboard/DashboardAction";

const useStyles = makeStyles({
  table: {
    minWidth: "100%",

    "& th, & td": {
      padding: "12px 24px 12px 16px",
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
    },
    "& tbody tr:last-child td ": {
      borderBottom: "none",
    },
  },

  approveBtn: {
    "& span": {
      fontSize: "13px",
    },
  },
});

function createData(name: any, post: any, date: any) {
  return { name, post, date };
}

const rows = [
  createData("Aashish Maharjan", "Teacher | Science", "May 30, 2021"),
  createData("Manoj Aacharya", "Student | Class 9 | Sectino B", "May 30, 2021"),
  createData("Reuben Thapa", "Teacher | Computer", "May 30, 2021"),
  createData("Rakesh Singh", "Teacher | Science", "May 30, 2021"),
  createData("Bill Gates", "Student | Class 9 | Sectino B", "May 30, 2021"),
  createData("Elon Musk", "Teacher | Computer", "May 30, 2021"),
];

export default function BasicTable() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const leaveRequests = useSelector(
    (state: RootStore) => state.dashboard.data.leave_requests
  );

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="Leave Requests List">
        <TableBody>
          {leaveRequests &&
            leaveRequests.length > 0 &&
            leaveRequests
              .filter((row: any) => row.status === "P")
              .map((row: any, index: number) => (
                <TableRow key={index}>
                  <TableCell>
                    <Typography variant="subtitle2" style={{ fontWeight: 500 }}>
                      {getFullName(row.first_name, row.last_name)}
                    </Typography>
                    <Typography variant="body1" style={{ color: "#555" }}>
                      {row.leave_name}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body1" style={{ color: "#555" }}>
                      {"Leave For"}
                    </Typography>
                    <Typography variant="body2" style={{ fontWeight: 500 }}>
                      {moment().format("YYYY-MM-DD")}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      color="primary"
                      style={{
                        textTransform: "capitalize",
                        fontSize: "20px!important",
                      }}
                      className={classes.approveBtn}
                      onClick={() => {
                        dispatch(
                          LeaveApproveOrDecline({ id: row.id, status: "A" })
                        );
                        dispatch(GetDashboardData());
                      }}
                    >
                      {row.status === "P" ? "Approve" : "Approved"}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
