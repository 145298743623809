import {
  Button,
  Divider,
  Grid,
  Input,
  InputLabel,
  Paper,
  Typography,
  TextField,
  LinearProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
} from "@material-ui/core";
import { CloudUpload, Send } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import SubModuleLayout from "../../Reusable/Layouts/SubModuleLayout";
import { HRsidebar } from "../../../components";
import { useStyles } from "../../Styles/FormStyles";
import { Autocomplete } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { GetClasses } from "../../../actions/Academics/Class/ClassAction";
import { RootStore } from "../../../store";
import { useForm } from "react-hook-form";
import { UploadStudentInfo } from "../../../actions/Student/Student/StudentAction";
import { setSnackbar } from "../../../actions/SnackbarAction";
import { GetSystemRoles } from "../../../actions/HumanResource/StaffRole/StaffRolesAction";
import { ImportStaff } from "../../../actions/HumanResource/Staff/StaffAction";
import BackDropLoader from "../../Reusable/BackDropLoader";
import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import ErrorIcon from "@material-ui/icons/Error";
import HelpIcon from "@material-ui/icons/Help";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Papa from "papaparse";
import {
  addStaffAction,
  removeStaffRowAction,
  updateStaffRowAction,
} from "../../../rtk/features/import/staffImportSlices";
import {
  importStaffData,
  importUserValidation,
} from "../../../rtk/features/import/userImportApi";


const NotValidIcon = styled(ErrorIcon)`
  color: red;

  && {
    width: 20px;
    height: 20px;
  }
`;

const NoValidityIcon = styled(HelpIcon)`
  color: #e6b411;

  && {
    width: 20px;
    height: 20px;
  }
`;

const ValidIcon = styled(CheckCircleIcon)`
  color: #1ce002;

  && {
    width: 20px;
    height: 20px;
  }
`;

const CellInput = styled.input`
  && {
    padding: 5px 10px;
    border-radius: 3px;
    border: 1px solid #000;

    &:focus {
      outline: none;
    }
  }
`;

const RemoveIcon = styled(DeleteIcon)`
  color: red;

  && {
    width: 20px;
    height: 20px;
  }
`;

function ImportExcelStaff() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const nav = <HRsidebar Staff />;

  // const [role, setRole] = useState<any>(null);

  const { staffs, loading, valid, imported } = useSelector(
    (state: RootStore) => state.staffImport
  );

  useEffect(() => {
    if (imported) {
      dispatch(addStaffAction([]));
    }
  }, [imported]);

  const handleDocument = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target && event.target.files) {
      const csvFile = event.target.files[0];
      if (csvFile) {
        Papa.parse(csvFile, {
          complete: function (result) {
            try {
              const data = result.data;
              data.shift();

              const td: {
                id: number;
                name: string;
                phone_number: string;
                validity: number;
                validationMsg: string;
              }[] = [];

              data.forEach((item, index) => {
                const row = item as Array<string>;
                const rowLen = row.length;

                if (rowLen > 1) {
                  td.push({
                    id: index + 1,
                    name: row[0] || "",
                    phone_number: row[1] || "",
                    validity: 1,
                    validationMsg: "Not Validated",
                  });
                }
              });
              dispatch(addStaffAction(td));
            } catch (err) {
              dispatch(setSnackbar(true, "warning", "Select a valid csv file"));
            }
          },
        });
      } else {
        dispatch(addStaffAction([]));
      }
    }
  };

  const returnValidityStatus = (status: number, msg: string) => {
    if (status === 1) {
      return (
        <Tooltip title={msg}>
          <NoValidityIcon />
        </Tooltip>
      );
    }
    if (status === 2) {
      return (
        <Tooltip title={msg}>
          <NoValidityIcon />
        </Tooltip>
      );
    }
    if (status === 3) {
      return (
        <Tooltip title={msg}>
          <NotValidIcon />
        </Tooltip>
      );
    }
    return (
      <Tooltip title={msg}>
        <ValidIcon />
      </Tooltip>
    );
  };

  const handleInputChange = (value: string, id: number, field: string) => {
    dispatch(updateStaffRowAction({ id, field, value }));
  };

  const handleDeleteRow = (id: number) => {
    dispatch(removeStaffRowAction(id));
  };

  const validateData = () => {
    const phone_numbers = staffs.map((item) => item.phone_number);
    dispatch(importUserValidation({ numbers: phone_numbers }));
  };

  const handleImportData = () => {
    const post_data = staffs.map((item) => ({
      name: item.name,
      phone_number: item.phone_number,
    }));
    dispatch(importStaffData({ values: post_data }));
  };

  return (
    <SubModuleLayout studentMenuActive sideNav={nav}>
      <Paper className={classes.pageContent} elevation={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Upload Staff Info</Typography>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <input
              accept=".csv"
              id="contained-button-file"
              type="file"
              name="document"
              onChange={handleDocument}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Please select file in .csv format</InputLabel>
          </Grid>
          <Grid item xs={12}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Validity</TableCell>
                  <TableCell>S.N</TableCell>
                  <TableCell>Full Name</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {staffs.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      {returnValidityStatus(row.validity, row.validationMsg)}
                    </TableCell>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>
                      <CellInput
                        name="name"
                        value={row.name}
                        onChange={(e) =>
                          handleInputChange(e.target.value, row.id, "name")
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <CellInput
                        name="phone_number"
                        value={row.phone_number}
                        type="number"
                        onChange={(e) =>
                          handleInputChange(
                            e.target.value,
                            row.id,
                            "phone_number"
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      {
                        <IconButton onClick={() => handleDeleteRow(row.id)}>
                          <RemoveIcon />
                        </IconButton>
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<DoneAllIcon />}
              type="submit"
              style={{ marginRight: "15px", marginTop: "10px" }}
              onClick={() => validateData()}
            >
              Validate
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Send />}
              type="submit"
              onClick={() => handleImportData()}
              style={{ marginTop: "10px" }}
              disabled={!valid}
            >
              Send
            </Button>
          </Grid>
        </Grid>
        <label htmlFor="contained-button-file"></label>
      </Paper>
      <BackDropLoader open={loading} />
    </SubModuleLayout>
  );
}
export default ImportExcelStaff;
