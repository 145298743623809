import {
  Button,
  Grid,
  InputLabel,
  Paper,
  TextField,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { useForm } from 'react-hook-form';
import { Autocomplete } from '@material-ui/lab';
import { useFormStyles } from '../../Styles/FormStyles';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import { GetClasses } from '../../../actions/Academics/Class/ClassAction';
import { GetSessions } from '../../../actions/GeneralSettings/Session/SessionAction';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import {
  GetSearchedDisabledStudentList,
  GetDisabledStudentList,
  GetSearchedStudentList,
} from '../../../actions/Student/Student/StudentAction';

// -----------------<start> Interfaces <start>-----------------------//
interface FormI {
  academic_year: string | null;
  class: number | null;
  section: string | null;
  rollNumber: string | number | null;
}

interface DataI {
  academic_year: string | number | null | undefined;
  class: number | null | undefined;
  section: number | string | null | undefined;
  rollNumber: string | number | null;
}

interface AcademicYearI {
  id: number;
  year: string;
}

interface SectionI {
  id: string;
  title: string;
}

interface ClassI {
  id: string;
  title: string;
  sections: SectionI[] | [];
}

// -----------------<end> Interfaces <end>-----------------------//

const SearchDisabledStudent = () => {
  const classes = useFormStyles();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, setValue } = useForm<FormI>();
  const [academicYear, setAcademicYear] = useState<AcademicYearI | null>(null);
  const [className, setClassName] = useState<ClassI | null>(null);
  const [section, setSection] = useState<SectionI | null>(null);
  const [sectionChoices, setSectionChoices] = useState<SectionI[] | []>([]);
  const [sectionDisabler, setSectionDisabler] = useState<boolean>(true);

  useEffect(() => {
    dispatch(GetClasses());
    dispatch(GetSessions());
  }, []);

  const classState = useSelector((state: RootStore) => state.class);

  const classChoices: ClassI[] = classState.classes.map((classItem, index) => ({
    id: classItem.grade,
    title: classItem.grade_name,
    sections: classItem.section.map((sectionItem) => ({
      id: sectionItem.id,
      title: sectionItem.name,
    })),
  }));

  const handleClassChange = (value: ClassI | null) => {
    setClassName(value);
    setSection(null);
    value != null
      ? populateSectionChoices(value.sections)
      : setSectionDisabler(true);
  };

  const populateSectionChoices = (sections: SectionI[] | []) => {
    setSectionDisabler(false);
    sections.length > 0
      ? setSectionChoices(sections)
      : setSectionDisabler(true);
  };

  const onSubmit = (data: any) => {
    const form_data: any = {
      academic_year: '',
      class: className ? className.id.toString() : '',
      section: className && section ? section.id.toString() : '',
    };

    dispatch(GetSearchedDisabledStudentList(form_data));
  };

  const handleReset = () => {
    setClassName(null);
    setSection(null);
    dispatch(GetDisabledStudentList());
  };

  return (
    <>
      <Paper className={classes.pageContentBox}>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={9} className={classes.formTitle}>
              <span>Search Criteria</span>
            </Grid>
            <Grid container item xs={3}>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                  <Tooltip title="Clear Search Query">
                    <IconButton
                      aria-label="delete"
                      style={{ padding: '2px' }}
                      onClick={handleReset}
                    >
                      <SettingsBackupRestoreIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3} className={classes.formWrapper}>
              <InputLabel>
                Select Grade
                <span style={{ color: 'red' }}>*</span>
              </InputLabel>
              <Autocomplete
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: ClassI | null
                ) => handleClassChange(value)}
                options={classChoices}
                getOptionLabel={(option) => option.title}
                value={className}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Label"
                    name="class_name"
                    inputRef={register({ required: 'Grade is required' })}
                    variant="outlined"
                  />
                )}
              />
              {/* <span className={classes.validationErrorInfo}>
                {errors.class_name?.type === "required" &&
                  errors.class_name.message}
              </span> */}
            </Grid>
            <Grid item xs={3} className={classes.formWrapper}>
              <InputLabel>
                Select Section{' '}
                {!sectionDisabler && <span style={{ color: 'red' }}>*</span>}
              </InputLabel>
              <Autocomplete
                value={section}
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: SectionI | null
                ) => setSection(value)}
                options={sectionChoices}
                getOptionLabel={(option) => option.title}
                disabled={sectionDisabler}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Label"
                    name="session_name"
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            <Grid item xs={2} className={classes.formWrapper}>
              <Grid container justifyContent="center">
                <Button
                  className={classes.searchBoxButton}
                  type="submit"
                  color="primary"
                  variant="contained"
                  startIcon={<SearchIcon />}
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
};

export default SearchDisabledStudent;
