import React, { useState, useEffect } from "react";

const useLoading = (isLoading: boolean) => {
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  return { loading };
};

export default useLoading;
