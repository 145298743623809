import { Theme, makeStyles, createStyles } from "@material-ui/core";
import CircularProgress, {
  CircularProgressProps,
} from "@material-ui/core/CircularProgress";

const useStylesFacebook = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
    },
    bottom: {
      color: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    top: {
      color: "#1a90ff",
      animationDuration: "550ms",
      position: "absolute",
      left: 0,
    },
    circle: {
      strokeLinecap: "round",
      color: 'red'
    },
  })
);

const LoaderIcon = (props: CircularProgressProps) => {
  const classes = useStylesFacebook();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        // className={classes.bottom}
        size={14}
        thickness={3}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={14}
        thickness={3}
        {...props}
      />
    </div>
  );
};

export default LoaderIcon;
