// ---------------- <START> module import ------------------//
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import {
  AddEventType,
  UpdateEventType,
} from "../../../actions/Event/EventType/EventTypeAction";
import FormLayout from "../../Reusable/Layouts/Form.Layout";
import CustomizedTextField from "../../Reusable/Inputs/TextField";
import { useFormStyles } from "../../Styles/FormStyles";
import {
  AddAnnouncementType,
  UpdateAnnouncementType,
} from "../../../actions/Communication/AnnouncementType/AnnouncementTypeAction";
// ---------------- <END> module import ------------------//

// ---------------- <START> Interface------------------//
interface FormDataI {
  id: string;
  name: string;
}

interface PropsI {
  editData: FormDataI | null;
  onEditCheck: (value: boolean) => void;
}
// ---------------- <END> Interface------------------//

// ---------------- <START> Event Type Component ------------------//
const AnnouncementTypeForm = (props: PropsI) => {
  const { editData, onEditCheck } = props;
  const dispatch = useDispatch();
  const classes = useFormStyles();

  const { register, handleSubmit, setValue, reset, errors } = useForm({
    mode: "onChange",
  });

  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [editID, setEditID] = useState<string | null>(null);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false)
  // const announcement_typeLoading = useSelector(
  //   (state: RootStore) => state.announcement_type.loading
  // );
  // useEffect(() => {
  //   setLoading(announcement_typeLoading);
  // }, [announcement_typeLoading]);

  const actionPerformed = useSelector(
    (state: RootStore) => state.announcement_type.actionPerformed
  );
  const add_or_update = useSelector((state: RootStore) => state.announcement_type.add_or_update)

  useEffect(() => {
    if (add_or_update) {
      setIsBtnLoading(false)
      handleReset();
    }
  }, [add_or_update])

  useEffect(() => {
    if (actionPerformed) {
      handleReset();
    }
  }, [actionPerformed]);

  useEffect(() => {
    editData != null && handleEditTableData(editData);
  }, [editData]);

  const onFormSubmit = (data: FormDataI) => {
    setIsBtnLoading(true);
    if (editMode) {
      editID != null && dispatch(UpdateAnnouncementType(editID, data));
    } else {
      dispatch(AddAnnouncementType(data));
    }
    onEditCheck(false);
  };

  const handleEditTableData = (data: FormDataI) => {
    setEditMode(true);
    setValue("name", data.name);
    setEditID(data.id);
  };

  const handleReset = () => {
    reset();
    setEditMode(false);
    onEditCheck(false);
    setEditID(null);
  };

  return (
    <>
      <FormLayout
        title={editMode ? "Edit Announcement Type" : "Add Announcement Type"}
        onSubmit={handleSubmit(onFormSubmit)}
        editMode={editMode}
        loading={loading}
        onClick={!isBtnLoading && handleReset}
        add_or_update={isBtnLoading}
      >
        <CustomizedTextField
          label="Announcement Type Name"
          placeholder="Announcement Type Name"
          name="name"
          required
          error={errors["name"] ? "Required Field." : ""}
          inputRef={register({ required: true })}
        />
        <span className={classes.validationErrorInfo}>
          {errors.route_to?.type === "serverSideError" &&
            errors.route_to.message}
        </span>
      </FormLayout>
    </>
  );
};
// ---------------- <END> Event Type Component ------------------//

export default AnnouncementTypeForm;
