//------------------<START>import modules starts ---------------------------------------------//
import React, { useEffect, useState } from "react";
import { makeStyles, Tabs, Tab, Typography, Box } from "@material-ui/core";
import ClassTab from "./ClassTab";
import GroupTab from "./GroupTab";
import IndividualTab from "./IndividualTab";
import { ClassTabFormI } from "./ClassTab";
import { GroupTabFormI } from "./GroupTab";
import { IndividualTabFormI } from "./IndividualTab";
import { AnnouncementTypeI } from "../../../../../actions/Communication/Announcement/AnnouncementActionTypes";
//----------------------<END> import modules end-------------------------------------------//

//-----------------------------<START> styles starts------------------------------------------//
const useStyles = makeStyles({
  root: {
    width: "300px",
    paddingLeft: "10px",
    paddingRight: "10px",
    "& .MuiTab-root": {
      padding: 0,
      minWidth: "70px !important",
    },
    "& .MuiTabs-flexContainer": {
      borderBottom: "1px solid #ddd",
    },
    "& .MuiBox-root": {
      padding: "10px 0px 0px 0px",
    },
  },
});
//--------------------------<END> styles ends----------------------------------//
//----------------------------<START> interface starts -----------------------//
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface PropsI {
  tabData: (value: TabDataI | null) => void;
  submitClicked: number;
  initData: AnnouncementTypeI | null;
}

export interface TabDataI {
  group: string[];
  groupLabels: string[];
  user: string[];
  userLabels: string[];
  grade: string | null;
  gradeLabel: string;
  section: string[];
  sectionLabel: string[];
  sent_to_parent: boolean;
  sent_to_student: boolean;
}
//----------------------<ENDS> interface ends ---------------------------------//
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
// ------------------------<START> Annoucement Tabs components  starts ----------------------//
const AnnoucementTabs = (props: PropsI) => {
  const { tabData, submitClicked, initData } = props;

  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [tabValue, setTabValue] = useState<TabDataI | null>(null);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    setTabValue(null);
  };

  const handleGroupTabChange = (data: GroupTabFormI) => {
    setTabValue({
      user: [],
      userLabels: [],
      grade: null,
      gradeLabel: "",
      section: [],
      sectionLabel: [],
      group: data.group,
      groupLabels: data.groupLabel,
      sent_to_parent: false,
      sent_to_student: false,
    });
  };

  const handleClassTabChange = (data: ClassTabFormI) => {
    setTabValue({
      group: [],
      groupLabels: [],
      user: [],
      userLabels: [],
      grade: data.grade,
      gradeLabel: data.gradeLabel,
      section: data.section,
      sectionLabel: data.sectionLabel,
      sent_to_parent: data.sent_to_parent,
      sent_to_student: data.sent_to_student,
    });
  };

  const handleIndividualTabChange = (data: IndividualTabFormI) => {
    setTabValue({
      group: [],
      groupLabels: [],
      grade: null,
      gradeLabel: "",
      section: [],
      sectionLabel: [],
      user: data.user,
      userLabels: data.userLabel,
      sent_to_parent: false,
      sent_to_student: false,
    });
  };

  useEffect(() => {
    if (initData) {
      if (initData.group.length) {
        setValue(0);
        return;
      } else if (initData.user.length) {
        setValue(1);
        return;
      } else if (initData.grade) {
        setValue(2);
        return;
      }
    }
  }, [initData]);

  useEffect(() => {
    tabData(tabValue);
  }, [tabValue]);

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Group" />
        <Tab label="Individual" />
        <Tab label="Class" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <GroupTab
          onChange={handleGroupTabChange}
          onSubmit={submitClicked}
          initData={initData}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <IndividualTab
          onSubmit={submitClicked}
          initData={initData}
          onChange={handleIndividualTabChange}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ClassTab
          onChange={handleClassTabChange}
          onSubmit={submitClicked}
          initData={initData}
        />
      </TabPanel>
    </div>
  );
};

export default AnnoucementTabs;
//----------------------<END> Annoucement tabs components ends -----------------------------------------------------//
