import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, CardMedia, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { RootStore } from '../../../../store';
import { useIDCardStyles } from '../IDCardStyles';
import checkActiveSignature from '../../../utils/checkActiveSignature';
import checkImageUrl from '../../../utils/checkImageUrl';
import { GetReportSetting } from '../../../../actions/GeneralSettings/ReportSetting/ReportSettingAction';

interface InfoPropsI {
  value: string | number;
  properties: string;
}

const dummyData = {
  photo:
    'https://upload.wikimedia.org/wikipedia/commons/thumb/7/76/MiB.svg/1200px-MiB.svg.png',

  full_name: 'KK Adhikari',
  dob: '2044-04-05',
  blood_group: 'A+',
  address: 'New Baneshwor',
  phone_number: '9811050526',
  designation: 'Head of HR',
  date_of_joining: '2078-02-08',
};

export const StudentInfo = (props: InfoPropsI) => {
  const { value, properties } = props;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '2px 10px',
      }}
    >
      <Box fontSize={14} fontWeight="fontWeightRegular" textAlign="left">
        {properties}
      </Box>
      <Box fontSize={14} fontWeight="fontWeightRegular" textAlign="right">
        {value}
      </Box>
    </div>
  );
};

const PotraitTeacherIDCard = (props: any) => {
  const { data } = props;
  const dispatch = useDispatch();

  const classes = useIDCardStyles();
  const [item, setItem] = useState<any>(null);
  const [url, setUrl] = useState<string>('');

  const general_info = useSelector(
    (state: RootStore) => state.auth.general_info
  );

  const report_setting = useSelector(
    (state: RootStore) => state.report_setting?.reportsetting
  );

  useEffect(() => {
    const n = window.location.href.lastIndexOf('/');
    setUrl(window.location.href.substring(n + 1));
    dispatch(GetReportSetting());
  }, []);

  useEffect(() => {
    props.content != null && setItem(props.content);
  }, [props.content]);

  const renderValue = (real: any, fake: any) => {
    if (url === 'customize' || url === 'update') {
      return fake;
    } else {
      return real;
    }
  };

  return (
    <>
      <Card
        style={{
          maxWidth: '330px',
        }}
        // className={clsx(classes.potraitMode, classes.root)}
      >
        <Grid
          className={clsx(
            classes.bgColor,
            classes.landContent,
            classes.teacherBorder
          )}
          container
          direction="column"
          justifyContent="center"
          alignContent="center"
          style={{
            backgroundColor: item?.back_ground_color[0].value,
          }}
        >
          <Box
            style={{ color: item?.text_color[0].value }}
            fontSize={24}
            fontWeight="fontWeightBold"
            textAlign="center"
          >
            {renderValue(data?.designation, dummyData?.designation)}
          </Box>
          <Box
            style={{ color: item?.text_color[0].value }}
            fontSize={18}
            fontWeight="fontWeightRegular"
            textAlign="center"
          >
            {renderValue(data?.full_name, dummyData?.full_name)}
          </Box>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          style={{
            backgroundColor: item?.back_ground_color[2].value,
          }}
        >
          <CardMedia
            className={classes.teacherImage}
            image={renderValue(checkImageUrl(data?.photo), dummyData?.photo)}
          />

          <CardContent
            style={{ color: item?.text_color[2].value }}
            className={clsx(classes.content, classes.column)}
          >
            <StudentInfo
              properties="Full Name:"
              value={renderValue(data?.full_name, dummyData?.full_name)}
            />
            <StudentInfo
              properties="Address:"
              value={renderValue(data?.address, dummyData?.address)}
            />
            <StudentInfo
              properties="Date of Joining:"
              value={renderValue(
                data?.date_of_joining,
                dummyData?.date_of_joining
              )}
            />
            <StudentInfo
              properties="Blood Group:"
              value={renderValue(data?.blood_group, dummyData?.blood_group)}
            />
            <StudentInfo
              properties="DOB"
              value={renderValue(data?.dob, dummyData?.dob)}
            />
            <StudentInfo
              properties="Phone Number"
              value={renderValue(data?.phone_number, dummyData?.phone_number)}
            />
          </CardContent>
        </Grid>
        <div
          className={clsx(
            classes.bgColor,
            classes.footer,
            classes.teacherBorder
          )}
          style={{
            backgroundColor: item?.back_ground_color[1].value,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <CardMedia
                className={classes.logoImage}
                image={general_info?.logo}
                title=""
              />
            </Grid>
            <Grid item xs={9}>
              <div>
                <Box
                  style={{ color: item?.text_color[1].value }}
                  fontSize={18}
                  fontWeight="fontWeightBold"
                  textAlign="center"
                >
                  {general_info?.school_name}
                </Box>
                <Box
                  style={{ color: item?.text_color[1].value }}
                  fontSize={11}
                  fontWeight="fontWeightRegular"
                  textAlign="center"
                >
                  {general_info?.slogan}
                </Box>
              </div>
            </Grid>
          </Grid>
        </div>
      </Card>
    </>
  );
};

export default PotraitTeacherIDCard;
