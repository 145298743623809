import { makeStyles, Grid } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
  container: {
    display: "grid",
    gridTemplateColumns: "197px 1fr",
  },
  sidenav: {
    position: "relative",
    // minHeight: "calc(100vh - 180px)",
    height: "auto",
    // width: "235px",
  },
  module: {
    position: "relative",
    height: "100%",
    paddingLeft: "20px"
  },
});

const ClassLayout = (props: any) => {
  const classes = useStyles();
  const { sideNav, children, ...rest } = props;

  return (
    <>
      <Grid container className={classes.container}>
        <Grid item className={classes.sidenav}>
          {sideNav}
        </Grid>
        <Grid item className={classes.module}>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default ClassLayout;
