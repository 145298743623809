import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  TextField,
} from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import { Autocomplete } from "@material-ui/lab";
import { useFormStyles } from "../../Styles/FormStyles";
import { useDispatch, useSelector } from "react-redux";
import { SubjectGroupTypeI } from "../../../actions/Academics/Subject/SubjectGroupActionTypes";
import { GetClasses } from "../../../actions/Academics/Class/ClassAction";
import { GetSubjects } from "../../../actions/Academics/Subject/SubjectAction";
import { RootStore } from "../../../store";
import { ClassTypeI } from "../../../actions/Academics/Class/ClassActionTypes";
import { SectionTypeI } from "../../../actions/Academics/Section/SectionActionTypes";
import { useForm } from "react-hook-form";
import { setSnackbar } from "../../../actions/SnackbarAction";
import {
  AddSubjectGroup,
  UpdateSubjectGroup,
  GetSubjectGroups,
  GetSubjectGroupsWithPagination,
} from "../../../actions/Academics/Subject/SubjectGroupAction";
import FormLayout from "../../Reusable/Layouts/Form.Layout";
import CustomizedTextField from "../../Reusable/Inputs/TextField";

// -----------------<start> Styling <start>-----------------------//
// -----------------<end> Styling <end>-----------------------//

// -----------------<start> Interfaces <start>-----------------------//
interface PropsI {
  editData: SubjectGroupTypeI | null;
  onEditMode: (value: boolean) => void;
}

interface SectionListI {
  id: string;
  name: string;
  checked: boolean;
}

interface SubjectCheckBoxI {
  id: string;
  name: string;
  subject_code: string;
  created_by: string;
  general_info_name: string;
  checked: boolean;
}

interface FormDataI {
  name: string;
  class: string;
  description: string;
}

// -----------------<end> Interfaces <end>-----------------------//

const SubjectGroupForm = (props: PropsI) => {
  const { editData, onEditMode } = props;
  const classes = useFormStyles();

  // -----------------<start> States <start>-----------------------//
  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [classLoading, setClassLoading] = useState<boolean>(false);
  const [subjectLoading, setSubjectLoading] = useState<boolean>(false);

  const [editID, setEditID] = useState<string>("");

  const [grade, setGrade] = useState<ClassTypeI | null>(null);
  const [sectionList, setSectionList] = useState<SectionListI[]>([]);

  const [classChoices, setClassChoices] = useState<ClassTypeI[]>([]);

  const [subjectList, setSubjectList] = useState<SubjectCheckBoxI[]>([]);
  const [tempSubjectList, setTempSubjectList] = useState<SubjectCheckBoxI[]>(
    []
  );
  const [checkedCount, setCheckoutCount] = useState<number>(0);

  const subjectGroupSelector = useSelector(
    (state: RootStore) => state.subject_group
  );
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  // -----------------<end> States <end>-----------------------//

  //============================= <START> REDUX CYCLE HOOKS <START> ==========================//
  const dispatch = useDispatch();
  const subjectGroupLoading = useSelector(
    (state: RootStore) => state.subject_group.loading
  );
  const add_or_update = useSelector(
    (state: RootStore) => state.subject_group.add_or_update
  );

  const classState = useSelector((state: RootStore) => state.class);
  const subjectState = useSelector((state: RootStore) => state.subject);

  useEffect(() => {
    if (add_or_update) {
      setIsBtnLoading(false);
      handleReset();
    }
  }, [add_or_update]);

  useEffect(() => {
    dispatch(GetClasses());
    dispatch(GetSubjects());
  }, []);

  useEffect(() => {
    if (subjectGroupSelector.actionPerformed) {
      handleReset();
      dispatch(GetSubjectGroups());
      dispatch(GetSubjectGroupsWithPagination(1));
    }
  }, [subjectGroupSelector.actionPerformed]);

  useEffect(() => {
    editData != null && handleEditTableData(editData);
  }, [editData]);

  useEffect(() => {
    setLoading(subjectGroupLoading);
  }, [subjectGroupLoading]);

  useEffect(() => {
    const classes = classState.classes;

    setClassLoading(classState.loading);
    setClassChoices(classes);
  }, [classState]);

  useEffect(() => {
    const subjectCheckBoxes: SubjectCheckBoxI[] = [];

    const subjects = subjectState.subjects;

    subjects.map((element) => {
      subjectCheckBoxes.push({ ...element, checked: false });
    });

    setSubjectLoading(subjectState.loading);
    setSubjectList(subjectCheckBoxes);
    setTempSubjectList(subjectCheckBoxes);
  }, [subjectState]);

  //============================= <END> REDUX CYCLE HOOKS <END> ==========================//

  // ---------------------<START> Event Handlers <START>-----------------------//
  const {
    register,
    handleSubmit,
    errors,
    setError,
    clearErrors,
    setValue,
    reset,
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data: FormDataI) => {
    setIsBtnLoading(true);
    const checkedSubjects = tempSubjectList.filter(
      (element) => element.checked === true
    );
    if (grade != null && checkedSubjects.length) {
      const form_sections: string[] = [];
      sectionList.map((element) => {
        element.checked && form_sections.push(element.id);
      });

      const form_subjects: string[] = [];
      tempSubjectList.map((element) => {
        element.checked && form_subjects.push(element.id);
      });

      const submit_data = {
        name: data.name,
        grade: grade.grade,
        section: form_sections,
        subject: form_subjects,
        description: data.description,
      };
      if (editID == "") {
        if (grade.section.length) {
          if (form_sections.length) {
            dispatch(AddSubjectGroup(submit_data));
          } else {
            dispatch(setSnackbar(true, "warning", "No sections selected"));
          }
        } else {
          dispatch(AddSubjectGroup(submit_data));
        }
      } else {
        if (grade.section.length) {
          if (form_sections.length) {
            dispatch(UpdateSubjectGroup(editID, submit_data));
          } else {
            dispatch(setSnackbar(true, "warning", "No sections selected"));
          }
        } else {
          dispatch(UpdateSubjectGroup(editID, submit_data));
        }
      }
    } else {
      dispatch(setSnackbar(true, "warning", "No subjects selected"));
    }
  };

  const handleClassChange = (value: ClassTypeI | null) => {
    setGrade(value);
    value ? handleChangeBox(value.section) : setSectionList([]);
  };

  const handleChangeBox = (value: SectionTypeI[]) => {
    const data: SectionListI[] = [];
    value.map((element) => {
      data.push({ ...element, checked: false });
    });
    setSectionList(data);
  };

  const handleSectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const data: SectionListI[] = [...sectionList];
    const data_index: number = data.findIndex(
      (element) => element.id === event.target.value
    );
    data[data_index] = { ...data[data_index], checked: event.target.checked };
    setSectionList(data);
  };

  const handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const current_data: SubjectCheckBoxI[] = [...subjectList];
    const temp_current_data: SubjectCheckBoxI[] = [...tempSubjectList];
    const index: number = current_data.findIndex(
      (element) => element.id === event.target.value
    );
    const temp_index: number = temp_current_data.findIndex(
      (element) => element.id === event.target.value
    );
    current_data[index] = {
      ...current_data[index],
      checked: event.target.checked,
    };
    temp_current_data[temp_index] = {
      ...temp_current_data[temp_index],
      checked: event.target.checked,
    };

    const onlyChecked_Subject = temp_current_data.filter(
      (element) => element.checked === true
    );
    setCheckoutCount(onlyChecked_Subject ? onlyChecked_Subject.length : 0);
    setSubjectList(current_data);
    setTempSubjectList(temp_current_data);
  };

  const handleEditTableData = (data: SubjectGroupTypeI) => {
    setCheckoutCount(data?.subject?.length);
    setEditMode(true);
    setEditID(data.id);

    setValue("name", data.name);
    setValue("description", data.description);

    //setting grade and sections from table
    const new_grade = classChoices.find(
      (element) => element.grade === data.grade
    );
    if (new_grade) {
      setGrade(new_grade);
      const new_sections: SectionListI[] = [];

      const available_section = new_grade.section;
      available_section.map((element) => {
        data.section.find((sec) => sec.id === element.id)
          ? new_sections.push({
              ...element,
              checked: true,
            })
          : new_sections.push({
              ...element,
              checked: false,
            });
      });

      setSectionList(new_sections);
    }
    ///////////////////////////////////////////////

    //setting subjects from table
    const new_subjects: SubjectCheckBoxI[] = [];
    subjectList.map((element) => {
      data.subject.find((sub) => sub.id === element.id)
        ? new_subjects.push({
            ...element,
            checked: true,
          })
        : new_subjects.push({
            ...element,
            checked: false,
          });
    });

    setSubjectList(new_subjects);
    setTempSubjectList(new_subjects);

    /////////////////////////////////////
  };

  const handleReset = () => {
    reset();
    setCheckoutCount(0);
    setGrade(null);
    setEditID("");
    setSectionList([]);
    onEditMode(false);
    setEditMode(false);
    const default_subjects: SubjectCheckBoxI[] = [];
    subjectList.length &&
      subjectList.map((element) => {
        default_subjects.push({ ...element, checked: false });
      });
    setSubjectList(default_subjects);
  };

  // ---------------------<END> Event Handlers <END>-----------------------//

  //----------------------ERROR HANDLING---------------------------//

  const handleSubjectSearch = (event: any) => {
    const search_data = tempSubjectList.filter((data) => {
      return data.name.toUpperCase().includes(event.target.value.toUpperCase());
    });
    event.target.value
      ? setSubjectList(search_data)
      : setSubjectList(tempSubjectList);
  };

  return (
    <>
      <FormLayout
        title={editMode ? "Edit Subject Group" : "Add Subject Group"}
        onSubmit={handleSubmit(onSubmit)}
        editMode={editMode}
        loading={loading}
        onClick={!isBtnLoading && handleReset}
        add_or_update={isBtnLoading}
      >
        <CustomizedTextField
          label="Subject Group Name"
          placeholder="Subject Group"
          name="name"
          required
          error={errors["name"] ? "Required Field." : ""}
          inputRef={register({ required: true })}
          onChange={() => clearErrors("detail")}
        />
        <span className={classes.validationErrorInfo}>
          {errors.detail?.type === "serverSideError" && errors.detail.message}
        </span>
        <Grid container>
          <Grid item xs={12} className={classes.formWrapper}>
            <InputLabel>
              Select Class
              <span style={{ color: "red" }}>*</span>
            </InputLabel>
            <Autocomplete
              value={grade}
              onChange={(
                event: React.ChangeEvent<{}>,
                value: ClassTypeI | null
              ) => handleClassChange(value)}
              options={classChoices}
              getOptionLabel={(option) => option.grade_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="class"
                  placeholder="Class"
                  variant="outlined"
                  inputRef={register({ required: "Class must be selected" })}
                />
              )}
            />
            <span className={classes.validationErrorInfo}>
              {errors.class && errors.class.message}
            </span>
          </Grid>
          <Grid item xs={12} className={classes.formWrapper}>
            <InputLabel>
              Select Sections
              {/* {grade && <span style={{ color: "red" }}>*</span>} */}
            </InputLabel>
            {sectionList.length ? (
              <FormControl
                component="fieldset"
                className={classes.sectionCheckBox}
              >
                <FormGroup>
                  {sectionList.map((element: SectionListI) => (
                    <FormControlLabel
                      key={element.id}
                      control={
                        <Checkbox
                          color="primary"
                          value={element.id}
                          onChange={handleSectionChange}
                          name={element.name}
                          checked={element.checked}
                        />
                      }
                      label={"Section " + element.name}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            ) : (
              <span className={classes.sectionUnavailableText}>
                ** No records found
              </span>
            )}
            <span className={classes.validationErrorInfo} />
          </Grid>
          <Grid item xs={12} className={classes.formWrapper}>
            <InputLabel>
              Choose Subject(s)
              <span style={{ color: "red" }}>*</span>
            </InputLabel>
            <CustomizedTextField
              placeholder="search subject..."
              onChange={(e: any) => handleSubjectSearch(e)}
            />
            <i style={{ fontSize: "12px" }}>Selected: {checkedCount}</i>
            <FormControl
              style={{ maxHeight: 200, overflowY: "auto" }}
              component="fieldset"
              className={classes.sectionCheckBox}
            >
              <FormGroup>
                {subjectList.map((element: SubjectCheckBoxI) => (
                  <FormControlLabel
                    key={element.id}
                    control={
                      <Checkbox
                        color="primary"
                        value={element.id}
                        onChange={handleSubjectChange}
                        name={element.name}
                        checked={element.checked}
                      />
                    }
                    label={element.name}
                  />
                ))}
              </FormGroup>
            </FormControl>
            <span className={classes.validationErrorInfo}></span>
          </Grid>
          <CustomizedTextField
            label="Description"
            placeholder="Description"
            name="description"
            multiline
            rows={2}
            rowsMax={3}
            error={errors["description"] ? "Required Field." : ""}
            inputRef={register}
          />
        </Grid>
      </FormLayout>
    </>
  );
};

export default SubjectGroupForm;
