export const GET_DESIGNATION_LOADING = "GET_DESIGNATION_LOADING";
export const GET_DESIGNATION_ERROR = "GET_DESIGNATION_ERROR";
export const GET_DESIGNATION_SUCCESS = "GET_DESIGNATION_SUCCESS";

export const ADD_DESIGNATION_LOADING = "ADD_DESIGNATION_LOADING";
export const ADD_DESIGNATION_ERROR = "ADD_DESIGNATION_ERROR";
export const ADD_DESIGNATION_SUCCESS = "ADD_DESIGNATION_SUCCESS";

export const UPDATE_DESIGNATION_LOADING = "UPDATE_DESIGNATION_LOADING";
export const UPDATE_DESIGNATION_ERROR = "UPDATE_DESIGNATION_ERROR";
export const UPDATE_DESIGNATION_SUCCESS = "UPDATE_DESIGNATION_SUCCESS";

export const DELETE_DESIGNATION_LOADING = "DELETE_DESIGNATION_LOADING";
export const DELETE_DESIGNATION_ERROR = "DELETE_DESIGNATION_ERROR";
export const DELETE_DESIGNATION_SUCCESS = "DELETE_DESIGNATION_SUCCESS";

export type DesignationTypeI = {
  id: string;
  name: string;
  count?: number;
};

//===============================<START>GET DESIGNATION<START>======================//

export interface GetDesignationLoading {
  type: typeof GET_DESIGNATION_LOADING;
}

export interface GetDesignationError {
  type: typeof GET_DESIGNATION_ERROR;
}

export interface GetDesignationSuccess {
  type: typeof GET_DESIGNATION_SUCCESS;
  payload: DesignationTypeI[];
}

//===============================<END>GET DESIGNATION<END>======================//

//===============================<START>ADD DESIGNATION<START>======================//

export interface AddDesignationLoading {
  type: typeof ADD_DESIGNATION_LOADING;
}

export interface AddDesignationError {
  type: typeof ADD_DESIGNATION_ERROR;
}

export interface AddDesignationSuccess {
  type: typeof ADD_DESIGNATION_SUCCESS;
  payload: DesignationTypeI;
}

//===============================<END>ADD DESIGNATION<END>======================//

//===============================<START>UPDATE DESIGNATION<START>======================//

export interface UpdateDesignationLoading {
  type: typeof UPDATE_DESIGNATION_LOADING;
}

export interface UpdateDesignationError {
  type: typeof UPDATE_DESIGNATION_ERROR;
}

export interface UpdateDesignationSuccess {
  type: typeof UPDATE_DESIGNATION_SUCCESS;
  payload: DesignationTypeI;
}

//===============================<END>UPDATE DESIGNATION<END>======================//

//===============================<START>DELETE DESIGNATION<START>======================//

export interface DeleteDesignationLoading {
  type: typeof DELETE_DESIGNATION_LOADING;
}

export interface DeleteDesignationError {
  type: typeof DELETE_DESIGNATION_ERROR;
}

export interface DeleteDesignationSuccess {
  type: typeof DELETE_DESIGNATION_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE DESIGNATION<END>======================//

export type DesignationDispatchTypes =
  | GetDesignationLoading
  | GetDesignationError
  | GetDesignationSuccess
  | AddDesignationLoading
  | AddDesignationError
  | AddDesignationSuccess
  | UpdateDesignationLoading
  | UpdateDesignationError
  | UpdateDesignationSuccess
  | DeleteDesignationLoading
  | DeleteDesignationError
  | DeleteDesignationSuccess;
