//---------------------------- <START> module imports start ----------------------------//
import React, { useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem, { ListItemProps } from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Link, useHistory } from "react-router-dom";
import cx from "clsx";
import { Collapse, Slide } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
//---------------------------- <END> module imports start ------------------------------//

//---------------------------- <START> interface start ------------------------------//
interface DatasetI {
  text: string;
  url: string;
  activeStatus?: boolean | undefined;
  dataSet?: Array<object>;
}
//---------------------------- <END> interface ends ------------------------------//

// ---------------------------- <START> styling start ----------------------------------//

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      "& .MuiListItem-button": {
        "&:hover": {
          background: "none",
        },
      },
    },
    Sidebar: {
      marginTop: "100px",
      display: "flex",
      flexDirection: "column",
      position: "relative",
      left: "0px",
      height: "100%",
      backgroundColor: "white",
    },
    sidebarContent: {
      padding: "1rem",
    },
    sidebarTitle: {
      paddingBottom: "1.5rem",
    },
    listSelected: {
      backgroundColor: "#91f25c",
      borderRadius: "0px 20px 20px 0",
      // marginLeft: "-15px",
      // animation: `$myEffect 200ms ${theme.transitions.easing.easeInOut}`,
      "& span": {
        // marginLeft: "15px",
      },
      "&:hover": {
        backgroundColor: "#4cf5ea !important",
      },
    },
    listText: {
      "& .MuiListItemText-primary": {
        fontSize: "1rem",
        color: "#fff",
        fontWeight: 400,
      },
    },
    linkStyle: {
      textDecoration: "none",
      color: "rgba(0, 0, 0, 0.87)",
      "& .activeLink": {
        color: "red",
      },
    },
    "@keyframes myEffect": {
      "0%": {
        opacity: 1,
        transform: "translateX(-200%)",
      },
      "100%": {
        opacity: 1,
        transform: "translateX(0)",
      },
    },
    nestedList: {
      padding: "6px 0px 6px 24px !important",
      // marginLeft: "8px",
      width: "calc(100% - 8px)",
    },
    nestedRoot: {
      "& .MuiCollapse-container.MuiCollapse-entered": {
        transition: "none!important",
      },

      "& *": {
        transition: "none !important",
      },
    },
  })
);
// ---------------------------- <END> styling ends ----------------------------------//

// ---------------------------- <START> re-usable component starts ---------------------------//
const useStudentSidebar = (dataSet: DatasetI[]) => {
  const classes = useStyles();
  const history = useHistory();

  const handleLinkClick = (to: string) => {
    history.push(`/${to}`);
  };

  const SimpleList = (props: { list: DatasetI }) => {
    return (
      <ListItem
        button
        className={cx(props.list.activeStatus && classes.listSelected)}
        onClick={() => handleLinkClick(props.list.url)}
      >
        <ListItemText
          classes={{ root: classes.listText }}
          primary={props.list.text}
        />
      </ListItem>
    );
  };

  const NestedList = (props: { list: DatasetI }) => {
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
      setOpen(!open);
    };

    useEffect(() => {
      props.list.dataSet &&
        props.list.dataSet.map((item: any) => {
          if (!open && item.activeStatus) setOpen(true);
        });
    }, []);

    return (
      <>
        <ListItem button onClick={handleClick}>
          <ListItemText
            primary={props.list.text}
            classes={{ root: classes.listText }}
          />
          {open ? (
            <ExpandLess htmlColor="#fff" />
          ) : (
            <ExpandMore htmlColor="#fff" />
          )}
        </ListItem>

        <Collapse in={open} timeout="auto" unmountOnExit>
          {props.list.dataSet?.map((item: any, index) => {
            return (
              <List component="div" disablePadding key={index}>
                <ListItem
                  button
                  onClick={() => handleLinkClick(item.url)}
                  className={cx(
                    item.activeStatus && classes.listSelected,
                    classes.nestedList
                  )}
                  style={item.activeStatus && { marginLeft: "0px" }}
                >
                  <ListItemText
                    classes={{ root: classes.listText }}
                    primary={item.text}
                  />
                </ListItem>
              </List>
            );
          })}
        </Collapse>
      </>
    );
  };

  const CustomSidebar = () => (
    <List
      className={cx(classes.root, classes.nestedRoot)}
      component="nav"
      aria-label="inner sidebar list"
    >
      {dataSet.map((element: DatasetI, index: number) =>
        element.dataSet ? (
          <NestedList key={index} list={element} />
        ) : (
          <SimpleList key={index} list={element} />
        )
      )}
    </List>
  );

  // returning re-usable <CustomSidebar> components
  return {
    CustomSidebar,
  };
};

// ---------------------------- <END> re-usable component ends ----------------------------------//

export default useStudentSidebar;
