import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_SYSTEM_ROLES_LOADING,
  GET_SYSTEM_ROLES_ERROR,
  GET_SYSTEM_ROLES_SUCCESS,
  ADD_SYSTEM_ROLES_LOADING,
  ADD_SYSTEM_ROLES_ERROR,
  ADD_SYSTEM_ROLES_SUCCESS,
  DELETE_SYSTEM_ROLES_LOADING,
  DELETE_SYSTEM_ROLES_ERROR,
  DELETE_SYSTEM_ROLES_SUCCESS,
  SET_SYSTEM_ROLES_ERROR,
  SET_SYSTEM_ROLES_LOADING,
  SET_SYSTEM_ROLES_SUCCESS,
  SET_USER_PASSWORD_ERROR,
  SET_USER_PASSWORD_LOADING,
  SET_USER_PASSWORD_SUCCESS,
  SEND_USER_CREDENTIALS_ERROR,
  SEND_USER_CREDENTIALS_LOADING,
  SEND_USER_CREDENTIALS_SUCCESS,
  SystemRolesDispatchTypes,
} from "./StaffRoleActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

interface RequestDataI {
  user_id: string;
  groups: string[];
}

interface RequestPasswordDataI {
  user: string;
  password1: string;
  password2: string;
}

export const GetSystemRoles =
  () => async (dispatch: Dispatch<SystemRolesDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_SYSTEM_ROLES_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/user_groups/`,
        HeaderConfig()
      );

      dispatch({
        type: GET_SYSTEM_ROLES_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_SYSTEM_ROLES_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "System Roles Loading Failed",
      //   },
      // });
    }
  };

export const AddSystemRoles =
  (data: any) =>
  async (dispatch: Dispatch<SystemRolesDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_SYSTEM_ROLES_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/user_groups/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_SYSTEM_ROLES_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Roles Added Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: ADD_SYSTEM_ROLES_ERROR,
        payload: error.response && error.response?.data,
      });
    }
  };

export const SetSystemRoles =
  (post_data: RequestDataI) =>
  async (dispatch: Dispatch<SystemRolesDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: SET_SYSTEM_ROLES_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/assign/group/`,
        post_data,
        HeaderConfig()
      );

      dispatch({
        type: SET_SYSTEM_ROLES_SUCCESS,
        payload: res.data.assigned_groups,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Roles Updated Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: SET_SYSTEM_ROLES_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Updating Roles Failed",
        },
      });
    }
  };

export const SetUserPassword =
  (post_data: RequestPasswordDataI) =>
  async (dispatch: Dispatch<SystemRolesDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: SET_USER_PASSWORD_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/change_password/`,
        post_data,
        HeaderConfig()
      );

      dispatch({
        type: SET_USER_PASSWORD_SUCCESS,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Password has been changed successfully.",
        },
      });
    } catch (error) {
      dispatch({
        type: SET_USER_PASSWORD_ERROR,
      });
    }
  };

export const DeleteSystemRoles =
  (id: string) =>
  async (dispatch: Dispatch<SystemRolesDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_SYSTEM_ROLES_LOADING,
      });

      const res = await axios.delete(
        `${ADMIN_API_URL}/user_groups/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_SYSTEM_ROLES_SUCCESS,
        payload: { id: id },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "System Role Deleted Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_SYSTEM_ROLES_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "System Role Delete Failed",
        },
      });
    }
  };

export const SendUserCredentials =
  (id: string, user_type: "Student" | "Guardian" | "Staff") =>
  async (dispatch: Dispatch<SystemRolesDispatchTypes | SetSnackBarI>) => {
    let url = "";

    if (user_type == "Student") {
      url = `${ADMIN_API_URL}/student_credential/?student=${id}`;
    } else if (user_type == "Guardian") {
      url = `${ADMIN_API_URL}/guardian_credential/?guardian=${id}`;
    } else {
      url = `${ADMIN_API_URL}/staff_credential/?staff=${id}`;
    }

    try {
      dispatch({
        type: SEND_USER_CREDENTIALS_LOADING,
      });

      await axios.post(url, HeaderConfig());

      dispatch({
        type: SEND_USER_CREDENTIALS_SUCCESS,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "User Credentails Sent via Sms Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: SEND_USER_CREDENTIALS_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Failed to Send User Credentials",
        },
      });
    }
  };
