import axios from "axios";
import { Dispatch } from "redux";
import {
  GET_ONLINE_CLASS_LOADING,
  GET_ONLINE_CLASS_ERROR,
  GET_ONLINE_CLASS_SUCCESS,
  GET_ONLINE_CLASS_LOG_LOADING,
  GET_ONLINE_CLASS_LOG_ERROR,
  GET_ONLINE_CLASS_LOG_SUCCESS,
  OnlineClassDispatchTypes,
} from "./OnlineClassActionTypes";
import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL, PARENT_API_URL } from "../../../config/SERVER_API_URLS";

export const GetOnlineClass =
  (grade: number, section: number, day?: string) =>
  async (dispatch: Dispatch<OnlineClassDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_ONLINE_CLASS_LOADING,
      });

      let url = `${ADMIN_API_URL}/online_class/?all=true&grade=${grade}&section=${section}`;
      if (day) {
        url = `${ADMIN_API_URL}/online_class/?all=true&grade=${grade}&section=${section}&day=${day}`;
      }
      const res = await axios.get(url, HeaderConfig());

      dispatch({
        type: GET_ONLINE_CLASS_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_ONLINE_CLASS_ERROR,
      });
    }
  };

export const GetRegularOnlineClass =
  (grade: number, section: number, regular?: true) =>
  async (dispatch: Dispatch<OnlineClassDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_ONLINE_CLASS_LOADING,
      });

      let url = `${ADMIN_API_URL}/online_class/?all=true&grade=${grade}&section=${section}`;
      if (regular) {
        url = `${ADMIN_API_URL}/online_class/?grade=${grade}&section=${section}`;
      }
      const res = await axios.get(url, HeaderConfig());

      dispatch({
        type: GET_ONLINE_CLASS_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_ONLINE_CLASS_ERROR,
      });
    }
  };

export const GetOnlineClassLog =
  (id: number) =>
  async (dispatch: Dispatch<OnlineClassDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_ONLINE_CLASS_LOG_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/online_class_attendance/?online_class=${id}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_ONLINE_CLASS_LOG_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_ONLINE_CLASS_LOG_ERROR,
      });
    }
  };

export const GetOnlineClassByTeacher =
  (grade: string, section: string, teacher: string) =>
  async (dispatch: Dispatch<OnlineClassDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_ONLINE_CLASS_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/online_class/?grade=${grade}&section=${section}&teacher=${teacher}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_ONLINE_CLASS_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_ONLINE_CLASS_ERROR,
      });
    }
  };
