// ---------------------------- <START> module imports start ----------------------------------//
import React, { useState, useEffect } from "react";
import { Paper, Typography } from "@material-ui/core";
import { useTableStyles } from "../../Styles/TableStyles";
import { useTable } from "../../Reusable";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import { FineTypeI } from "../../../actions/Fees/FineType/FineActionTypes";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import { DeleteFine, GetFine } from "../../../actions/Fees/FineType/FineAction";
import {
  ItemDeleteButton,
  ItemEditButton,
} from "../../Reusable/Buttons/TableButton";
// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//
interface PropsI {
  onEditData: (value: FineTypeI) => void;
  editCheck: { check: boolean; count: number };
}

interface HeadCellsI {
  id: string;
  label: string;
}
// ---------------------------- <END> interface ends ----------------------------------//

// ---------------------------- <START> styling starts ----------------------------------//

// ---------------------------- <END> styling ends ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "name", label: "Name" },
  { id: "fine_in", label: "Fine In Type" },
  { id: "fine_applicable", label: "Fine Applicable" },
  { id: "action", label: "Action" },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> fine Type Table Component Starts ---------------------------//
const FineTypeList = (props: PropsI) => {
  const classes = useTableStyles();
  const { onEditData, editCheck } = props;

  // payment method reducer
  const dispatch = useDispatch();
  const fineTypeState = useSelector((state: RootStore) => state.fine_type);

  // Modal States
  const [loading, setLoading] = useState<boolean>(false);
  const [editMode, setEditMode] = useState(false);
  const [tableData, setTableData] = useState<FineTypeI[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string>("");

  useEffect(() => {
    editCheck && setEditMode(editCheck.check);
  }, [editCheck]);

  useEffect(() => {
    dispatch(GetFine());
  }, []);

  useEffect(() => {
    const dataLength = fineTypeState.fine.length;

    setLoading(fineTypeState.loading);
    setTableData(fineTypeState.fine);
  }, [fineTypeState]);

  // States declaration

  // -----------------<END> Pagination states and functions <END>-----------------------//

  // Retrieving re-usable components from useTable
  const { TableContainer, StyledTableCell, StyledTableRow } =
    useTable(headCells);

  // Function for handling action icons click event

  const handleEditClicked = (data: FineTypeI) => {
    onEditData(data);
    setEditMode(true);
  };
  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const handleDeleteModal = (value: boolean, id: string) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteFine(itemId));
    setDeleteModalOpen(false);
  };

  const getCustomTableRow = (item: any) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {item.name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.fine_in === "P" ? "Percentage" : "Amount"}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.fine_in === "P"
            ? `${item.fine_applicable}%`
            : `Rs. ${item.fine_applicable}`}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ItemEditButton
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleEditClicked(item)
            }
          />
          <ItemDeleteButton
            disabled={editMode}
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleDeleteModal(true, item.id)
            }
          />
        </StyledTableCell>
      </StyledTableRow>
    );
  };
  return (
    <Paper className={classes.root}>
      <Typography variant="h6">Fine Type List</Typography>

      <TableContainer
        loading={loading}
        items={tableData}
        headCells={headCells}
        getCustomTableRow={getCustomTableRow}
      />

      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
        dialogTitle="Are you sure to delete this record?"
        okText="Delete"
      />
    </Paper>
  );
};
// ---------------------------- <END> fine Type Component Ends -------------------------------//

export default FineTypeList;
