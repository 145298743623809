import checkImageUrl from "./checkImageUrl";

const checkActiveSignature = (data: any) => {
  if (data != null) {
    if (data[0]?.active_signature === "P") {
      return checkImageUrl(data[0]?.principal_sign);
    } else {
      return checkImageUrl(data[0]?.vice_principal_sign);
    }
  }
};

export default checkActiveSignature;
