// ---------------------------- <START> module imports start ----------------------------------//
import React, { useState, useEffect } from "react";
import { Paper, Typography, Grid, Box } from "@material-ui/core";
import { useTableStyles } from "../../Styles/TableStyles";
import { useTable } from "../../Reusable";
import { useDispatch, useSelector } from "react-redux";
import { SubjectTypeI } from "../../../actions/Academics/Subject/SubjectActionTypes";
import {
  GetSubjects,
  DeleteSubject,
  GetSubjectsWithPagination,
  GetPaginatedSubjects,
} from "../../../actions/Academics/Subject/SubjectAction";
import { RootStore } from "../../../store";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import CustomizedSelect from "../../Reusable/Inputs/Select";
import { paginationChoices } from "../../../common/json.constant";
import {
  ItemDeleteButton,
  ItemEditButton,
} from "../../Reusable/Buttons/TableButton";
// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//
interface PropsI {
  onEditData: (value: SubjectTypeI) => void;
  edit: { value: boolean };
}

interface HeadCellsI {
  id: string;
  label: string;
}
// ---------------------------- <END> interface ends ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N" },
  { id: "title", label: "Subject" },
  { id: "subject_code", label: "Subject Code" },
  { id: "action", label: "Action" },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const SubjectTable = (props: PropsI) => {
  const { onEditData, edit } = props;
  const classes = useTableStyles();

  // States declaration
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);

  const [tableData, setTableData] = useState<SubjectTypeI[]>([]);
  const [edtiMode, setEditMode] = useState(false);

  const [selectedPagination, setSelectedPagination] = useState<any | null>({
    key: "10",
    value: "10",
  });

  //Subject Reducer
  const dispatch = useDispatch();
  const subjectState = useSelector((state: RootStore) => state.subject);
  const subjectLoading = useSelector(
    (state: RootStore) => state.subject.loading
  );

  //===============================<START> REDUX CYCLE HOOKS <START>===============================//

  useEffect(() => {
    dispatch(GetSubjects());
    dispatch(GetSubjectsWithPagination(1));
  }, []);

  useEffect(() => {
    if (selectedPagination != null) {
      dispatch(GetPaginatedSubjects(1, Number(selectedPagination.key)));
    }
  }, [selectedPagination]);

  useEffect(() => {
    setLoading(subjectLoading);
  }, [subjectLoading]);

  useEffect(() => {
    subjectState && setTableData(subjectState.subjects);
  }, [subjectState]);

  useEffect(() => {
    edit && setEditMode(edit.value);
  }, [edit]);

  //===============================<END> REDUX CYCLE HOOKS <END>===============================//

  // Retrieving re-usable components from useTable
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  // Function for handling action icons click event
  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const handleEditClicked = (data: SubjectTypeI) => {
    onEditData(data);
    setEditMode(true);
  };

  // const handleDeleteModal = (value: boolean, id: string) => {
  //   setItemId(id);
  //   setDeleteModalOpen(value);
  // };

  // const onDeleteConfirmed = (confirmed: boolean) => {
  //   confirmed && itemId != null && dispatch(DeleteSubject(itemId));
  //   setDeleteModalOpen(false);
  // };

  const handlePaginationChange = (paginationIndex: number) => {
    dispatch(GetPaginatedSubjects(paginationIndex, selectedPagination?.key));
  };

  const getCustomTableRow = (item: SubjectTypeI, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.subject_code}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ItemEditButton
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleEditClicked(item)
            }
          />
          {/* <ItemDeleteButton
            disabled={edtiMode}
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleDeleteModal(true, item.id)
            }
          /> */}
        </StyledTableCell>
      </StyledTableRow>
    );
  };
  return (
    <>
      <Paper className={classes.root}>
        <Typography variant="h6">Subject List</Typography>
        <Grid container justifyContent="flex-end">
          <Box fontSize={10} textAlign="center">
            <CustomizedSelect
              label="Table Size"
              name="gender"
              options={paginationChoices}
              value={selectedPagination}
              setValue={setSelectedPagination}
            />
          </Box>
        </Grid>

        <TableContainer
          loading={loading}
          items={tableData}
          headCells={headCells}
          search_name="name"
          search_param="name"
          getCustomTableRow={getCustomTableRow}
          forPagination={subjectState?.subjects}
          handlePaginationChange={handlePaginationChange}
        />
      </Paper>
      {/* <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
      /> */}
    </>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default SubjectTable;
