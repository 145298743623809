// -----------------<START> import modules starts----------------------//
import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  LinearProgress,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { Search, Add } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { useFormStyles } from '../../../Styles/FormStyles';
import Popups from '../../../Reusable/Dialogs/Popups';
import { AddAnnoucModalPage } from '../../../../pages';
import AddNewButton from '../../../Reusable/Buttons/AddNewButton';
import 'nepali-datepicker-reactjs/dist/index.css';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useDispatch, useSelector } from 'react-redux';
import { GetDesignations } from '../../../../actions/HumanResource/Designation/DesignationAction';
import { GetClasses } from '../../../../actions/Academics/Class/ClassAction';
import { RootStore } from '../../../../store';
import { DesignationTypeI } from '../../../../actions/HumanResource/Designation/DesignationActionTypes';
import { ClassTypeI } from '../../../../actions/Academics/Class/ClassActionTypes';
import { SectionTypeI } from '../../../../actions/Academics/Section/SectionActionTypes';
import { GetUsersByRole } from '../../../../actions/GeneralSettings/GeneralInfo/GeneralInfoAction';
import { useForm } from 'react-hook-form';
import { GetAnnouncements } from '../../../../actions/Communication/Announcement/AnnouncementAction';
import moment from 'moment';
import {
  dateConverterAdToBs,
  dateConverterBsToAd,
} from '../../../utils/dateConverter';
import { GetSystemRoles } from '../../../../actions/HumanResource/StaffRole/StaffRolesAction';
import { UsersByRoleI } from '../../../../actions/GeneralSettings/GeneralInfo/GeneralInfoActionTypes';
import { SystemRolesTypeI } from '../../../../actions/HumanResource/StaffRole/StaffRoleActionTypes';

// -----------------<END> imports module ends -----------------------//

// -----------------<start> Styling <start>-----------------------//

// -----------------<end> Styling <end>-----------------------//

// -----------------<start> Interfaces starts-----------------------//
interface SelectMessageI {
  id: number;
  title: string;
}

// -----------------<END> Interfaces ends-----------------------//

const today = moment(new Date()).format('YYYY-MM-DD');

// -----------------<START> Annoucement search component starts-----------------------//
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface PropsI {
  setTarget: (
    val: {
      target: 'groups' | 'individuals' | 'classes';
      data: any;
    } | null
  ) => void;
}

const AnnoucementDraftTargetForm: React.FC<PropsI> = ({ setTarget }) => {
  const history = useHistory();
  const styleClass = useFormStyles();
  const [fromDate, setFromDate] = useState<string | undefined>(
    dateConverterAdToBs(today)
  );
  const [toDate, setToDate] = useState<string | undefined>(
    dateConverterAdToBs(today)
  );

  const [selectMessage, setSelectMessage] = useState<SelectMessageI | null>(
    null
  );
  const [openPopup, setOpenPopup] = useState(false);

  const [selectedDesignations, setSelectedDesignations] = useState<
    SystemRolesTypeI[]
  >([]);

  const [selectedClass, setSelectedClass] = useState<ClassTypeI | null>(null);

  const [sectionChoices, setSectionChoices] = useState<SectionTypeI[]>([]);
  const [selectedSectionValues, setSelectedSectionValues] = useState<
    SectionTypeI[]
  >([]);
  const [selectedSections, setSelectedSections] = useState<string[]>([]);

  const [selectedRole, setSelectedRole] = useState<DesignationTypeI | null>(
    null
  );
  const [selectedUsers, setSelectedUsers] = useState<UsersByRoleI[]>([]);
  const [selectedIndividuals, setSelectedIndividuals] = useState<string[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const [sendTo, setSendTo] = useState({
    parent: false,
    student: false,
  });

  //select message choices
  const selectMessageChoices: SelectMessageI[] = [
    { id: 1, title: 'Groups' },
    { id: 2, title: 'Individuals' },
    { id: 3, title: 'Classes' },
  ];

  // =============== <START> REACT HOOK <START> ========================//
  const { errors, setError, clearErrors } = useForm();
  const dispatch = useDispatch();
  const { system_roles } = useSelector((state: RootStore) => state.staff_roles);
  const { classes } = useSelector((state: RootStore) => state.class);
  const { usersByRole } = useSelector((state: RootStore) => state.general_info);

  const designationLoading = useSelector(
    (state: RootStore) => state.staff_roles.loading
  );
  const classLoading = useSelector((state: RootStore) => state.class.loading);
  const usersLoading = useSelector(
    (state: RootStore) => state.general_info.loading
  );
  const { announcementDraft } = useSelector(
    (state: RootStore) => state.announcement
  );

  const [counter, setCounter] = useState<number>(0);

  useEffect(() => {
    if (announcementDraft) {
      if (counter === 0) {
        if (announcementDraft.group.length) {
          setSelectMessage(
            selectMessageChoices.find((item) => item.id === 1) || null
          );

          if (system_roles.length) {
            setSelectedDesignations(
              system_roles.filter((item) =>
                announcementDraft.group.map((e) => e.id).includes(item.id)
              )
            );
            setCounter(counter + 1);
          }
        } else if (announcementDraft.grade) {
          setSelectMessage(
            selectMessageChoices.find((item) => item.id === 3) || null
          );

          const initClass = classes.find(
            (item) => item.grade === announcementDraft.grade
          );

          if (initClass) {
            setSelectedClass(initClass);
            setSectionChoices(initClass.section);

            if (sectionChoices.length) {
              setSelectedSectionValues(
                sectionChoices.filter((item) =>
                  announcementDraft.section.map((e) => e.id).includes(item.id)
                )
              );

              setSendTo({
                parent: announcementDraft.sent_to_parent,
                student: announcementDraft.sent_to_student,
              });
              setCounter(counter + 1);
            }
          }
        } else if (announcementDraft.user.length) {
          setSelectMessage(
            selectMessageChoices.find((item) => item.id === 2) || null
          );
          setSelectedRole(
            system_roles.find(
              (item) => item.id === announcementDraft.user_group
            ) || null
          );
          if (selectedRole) {
            if (usersByRole.length) {
              setSelectedUsers(
                usersByRole.filter((item) =>
                  announcementDraft.user.map((e) => e.id).includes(item.id)
                )
              );
              setCounter(counter + 1);
            }
          }
        }
      }
    }
  }, [announcementDraft, system_roles, usersByRole, classes, sectionChoices]);

  useEffect(() => {
    dispatch(GetSystemRoles());
    dispatch(GetClasses());
  }, []);

  useEffect(() => {
    if (!designationLoading && !classLoading && !usersLoading) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [designationLoading, classLoading, usersLoading]);

  useEffect(() => {
    switch (selectMessage?.title) {
      case 'Groups':
        setSelectedClass(null);
        setSelectedRole(null);
        break;

      case 'Individuals':
        setSelectedClass(null);
        break;

      case 'Classes':
        setSelectedRole(null);
        break;

      default:
        setSelectedClass(null);
        setSelectedRole(null);

        break;
    }
  }, [selectMessage]);

  useEffect(() => {
    if (selectedUsers.length) {
      clearErrors('user');
    }
    setSelectedIndividuals(selectedUsers.map((item) => item.id));
  }, [selectedUsers]);

  useEffect(() => {
    if (selectedRole) {
      dispatch(GetUsersByRole(selectedRole.id));
    }
  }, [selectedRole]);

  useEffect(() => {
    if (selectMessage) {
      if (selectMessage.id === 1) {
        setTarget({
          target: 'groups',
          data: selectedDesignations.map((item) => item.id),
        });
      } else if (selectMessage.id === 2) {
        if (selectedRole) {
          setTarget({
            target: 'individuals',
            data: {
              user_group: selectedRole.id,
              users: selectedUsers.map((item) => item.id),
            },
          });
        } else {
          setTarget({
            target: 'individuals',
            data: {
              user_group: null,
              users: [],
            },
          });
        }
      } else if (selectMessage.id === 3) {
        if (selectedClass) {
          setTarget({
            target: 'classes',
            data: {
              grade: selectedClass.grade,
              section: selectedSectionValues.map((item) => item.id),
              sendTo,
            },
          });
        } else {
          setTarget({
            target: 'classes',
            data: {
              grade: null,
              section: [],
              sendTo,
            },
          });
        }
      }
    } else {
      setTarget(null);
    }
  }, [
    selectedUsers,
    selectedClass,
    selectedSections,
    selectedDesignations,
    selectMessage,
    sendTo,
  ]);

  // =============== <END> REACT HOOK <END> ========================//

  const handleClose = (value: boolean) => {
    setOpenPopup(value);
  };

  const onMessageToChange = (value: SelectMessageI | null) => {
    clearErrors('message_to');
    setSelectMessage(value);

    setSelectedDesignations([]);
    setSelectedUsers([]);

    setSelectedRole(null);
    setSelectedClass(null);
    setSelectedSectionValues([]);
    setSendTo({
      parent: false,
      student: false,
    });
  };

  const handleClassChange = (value: ClassTypeI | null) => {
    setSelectedClass(value);
    setSelectedSectionValues([]);
    setSelectedSections([]);

    value && setSectionChoices(value.section);
    clearErrors('grade');
  };

  const handleRoleChange = (value: DesignationTypeI | null) => {
    setSelectedRole(value);
    setSelectedUsers([]);
    setSelectedIndividuals([]);
    value && dispatch(GetUsersByRole(value.id));
    clearErrors('role');
  };

  const onDesignationsChange = (value: SystemRolesTypeI[]) => {
    setSelectedDesignations(value);
    clearErrors('designation');
  };

  const onUsersChange = (value: UsersByRoleI[]) => {
    setSelectedUsers(value);
    clearErrors('users');
  };

  const onSectionsChange = (value: SectionTypeI[]) => {
    const secArr: string[] = [];
    value.map((element: SectionTypeI) => {
      secArr.push(element.id);
    });
    setSelectedSectionValues(value);
    setSelectedSections(secArr);
  };

  const handleSendToChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSendTo({ ...sendTo, [event.target.name]: event.target.checked });
    clearErrors('sendTo');
  };

  const onFormReset = () => {
    setSelectedClass(null);
    setSelectedDesignations([]);
    setSelectedIndividuals([]);
    setSelectedSections([]);
    setSelectedUsers([]);
    setSelectedRole(null);
    setSelectMessage(null);
  };

  return (
    <>
      <Paper className={styleClass.pageContentBox}>
        <form className={styleClass.form}>
          <Grid container>
            <Grid container spacing={3}>
              <Grid item xs={3} className={styleClass.formWrapper}>
                <InputLabel>Send Announcement To</InputLabel>
                <Autocomplete
                  value={selectMessage}
                  onChange={(
                    event: React.ChangeEvent<{}>,
                    value: SelectMessageI | null
                  ) => onMessageToChange(value)}
                  options={selectMessageChoices}
                  getOptionLabel={(option) => option.title}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="select_message"
                      placeholder="Select target"
                      variant="outlined"
                    />
                  )}
                />
                <span className={styleClass.validationErrorInfo}>
                  {errors?.message_to?.message}
                </span>
              </Grid>
              {selectMessage?.id === 1 && (
                <Grid item xs={3} className={styleClass.formWrapper}>
                  <InputLabel>Groups</InputLabel>
                  <Autocomplete
                    value={selectedDesignations}
                    multiple
                    limitTags={2}
                    id="checkbox-select-groups"
                    options={system_roles}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    onChange={(
                      event: React.ChangeEvent<{}>,
                      value: SystemRolesTypeI[]
                    ) => onDesignationsChange(value)}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Groups"
                      />
                    )}
                  />
                  <span className={styleClass.validationErrorInfo}>
                    {errors?.designation?.message}
                  </span>
                </Grid>
              )}
              {selectMessage?.id === 3 && (
                <Grid item xs={3} className={styleClass.formWrapper}>
                  <InputLabel>Classes</InputLabel>
                  <Autocomplete
                    value={selectedClass}
                    onChange={(
                      event: React.ChangeEvent<{}>,
                      value: ClassTypeI | null
                    ) => handleClassChange(value)}
                    options={classes}
                    getOptionLabel={(option) => option.grade_name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="classes"
                        placeholder="Classes"
                        variant="outlined"
                      />
                    )}
                  />
                  <span className={styleClass.validationErrorInfo}>
                    {errors?.grade?.message}
                  </span>
                </Grid>
              )}
              {selectedClass && (
                <Grid item xs={3} className={styleClass.formWrapper}>
                  <InputLabel>Sections</InputLabel>
                  <Autocomplete
                    value={selectedSectionValues}
                    multiple
                    id="checkbox-select-sections"
                    options={sectionChoices}
                    disableCloseOnSelect
                    onChange={(
                      event: React.ChangeEvent<{}>,
                      value: SectionTypeI[]
                    ) => onSectionsChange(value)}
                    getOptionLabel={(option) => option.name}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Sections"
                      />
                    )}
                  />
                  <span className={styleClass.validationErrorInfo}>
                    {errors?.section?.message}
                  </span>
                </Grid>
              )}
              {selectedClass && (
                <Grid item xs={3} className={styleClass.formWrapper}>
                  <InputLabel>Send to:</InputLabel>
                  <div className={styleClass.selection}>
                    <FormControl
                      component="fieldset"
                      className={styleClass.sectionCheckBox}
                    >
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              value="parent"
                              onChange={handleSendToChange}
                              name="parent"
                              checked={sendTo.parent}
                            />
                          }
                          label="Parent"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              value="student"
                              onChange={handleSendToChange}
                              name="student"
                              checked={sendTo.student}
                            />
                          }
                          label="Student"
                        />
                      </FormGroup>
                    </FormControl>
                  </div>
                  <span className={styleClass.validationErrorInfo}>
                    {errors.sendTo && errors.sendTo.message}
                  </span>
                </Grid>
              )}
              {selectMessage?.id === 2 && (
                <Grid item xs={3} className={styleClass.formWrapper}>
                  <InputLabel>Roles</InputLabel>
                  <Autocomplete
                    value={selectedRole}
                    onChange={(
                      event: React.ChangeEvent<{}>,
                      value: DesignationTypeI | null
                    ) => handleRoleChange(value)}
                    options={system_roles.map((item) => ({
                      id: item.id,
                      name: item.name,
                    }))}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="roles"
                        placeholder="Roles"
                        variant="outlined"
                      />
                    )}
                  />
                  <span className={styleClass.validationErrorInfo}>
                    {errors?.role?.message}
                  </span>
                </Grid>
              )}
              {selectedRole && (
                <Grid item xs={3} className={styleClass.formWrapper}>
                  <InputLabel>Users</InputLabel>
                  <Autocomplete
                    value={selectedUsers}
                    multiple
                    limitTags={2}
                    id="checkbox-select-users"
                    options={usersByRole}
                    disableCloseOnSelect
                    onChange={(
                      event: React.ChangeEvent<{}>,
                      value: UsersByRoleI[]
                    ) => onUsersChange(value)}
                    getOptionLabel={(option) =>
                      option.first_name + ' ' + option.last_name
                    }
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.first_name + ' ' + option.last_name}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Search Users"
                      />
                    )}
                  />
                  <span className={styleClass.validationErrorInfo}>
                    {errors?.user?.message}
                  </span>
                </Grid>
              )}
              <Grid item xs={1} className={styleClass.formWrapper}>
                <Grid container justifyContent="center">
                  <Button
                    className={styleClass.searchBoxButton}
                    color="primary"
                    variant="contained"
                    startIcon={<Search />}
                    onClick={onFormReset}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>

        <Popups
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          onClose={handleClose}
        >
          <AddAnnoucModalPage
            onClose={handleClose}
            mode="create"
            initialData={null}
          />
        </Popups>
      </Paper>
      {loading ? (
        <LinearProgress style={{ margin: '-10px 15px 0 15px' }} />
      ) : null}
    </>
  );
};

//-----------------<ENDS> Annoucement search component ends-----------------------//
export default AnnoucementDraftTargetForm;
