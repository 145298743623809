import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getRubricSettings, postRubricSettings } from "./rubricSettingsApi"

const initialState: {
    loading: boolean;
    rubricSettings: {
        add_by: string | null;
        add_on: string | null;
    }
    updated: boolean;
} = {
    loading: false,
    rubricSettings: {
        add_by: null,
        add_on: null
    },
    updated: false,
}

const rubricSlice = createSlice({
    name: "rubricSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getRubricSettings.pending, (state) => {
            state.loading = true;
            state.updated = false;
        })
        builder.addCase(
            getRubricSettings.fulfilled,
            (
                state,
                {
                    payload,
                }: PayloadAction<{ add_by: string | null; add_on: string | null }>
            ) => {
                state.loading = false;
                state.rubricSettings = payload;
                state.updated = false;
            }
        )
        builder.addCase(getRubricSettings.rejected, (state) => {
            state.loading = false;
            state.rubricSettings = {
                add_by: null,
                add_on: null
            };
            state.updated = false;
        })

        builder.addCase(postRubricSettings.pending, (state) => {
            state.loading = true;
            state.updated = false;
        });
        builder.addCase(
            postRubricSettings.fulfilled,
            (
                state,
                { payload }: PayloadAction<{ add_by: string | null; add_on: string | null }>
            ) => {
                state.loading = false;
                state.rubricSettings = { ...state.rubricSettings, ...payload };
                state.updated = true;
            }
        );
        builder.addCase(postRubricSettings.rejected, (state) => {
            state.loading = false;
            state.updated = false;
        });
    }
})

export default rubricSlice.reducer