import { useState } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;

  gap: 24px;
`;

const Info = styled.span`
  font-size: 12px;
  font-weight: 300;

  color: #222222;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const LimitSelect = styled.select`
  padding: 0.5rem 1rem;
  min-width: 7.5rem;

  outline: none;
  background-color: #fff;

  font-size: 14px;
  font-weight: 300;
  color: #212529;

  border-radius: 2px;
  border: 1px solid #b2b2b2;

  transition: all 0.2s ease;
`;

const Seperator = styled.div`
  flex: 1;
  min-width: 8rem;
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  gap: 1rem;

  flex-wrap: wrap;
`;

const PageBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
`;

const PageButton = styled.a<{ active: boolean }>`
  min-width: 32px;
  padding: 8px;

  font-size: 12px;
  font-weight: 400;
  color: ${(props) => (props.active ? "#fff" : "#222222")};

  background-color: ${(props) => (props.active ? "#132e98" : "#fff")};

  text-align: center;

  border-radius: 2px;

  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    filter: brightness(90%);
  }
`;

type PaginationProps = {
  loading: boolean;
  onPageLimitChange: (val: string) => void;
  onPageChange: (val: string) => void;
  totalRecords: number;
};

const Pagination = (props: PaginationProps) => {
  const { onPageChange, onPageLimitChange, totalRecords } = props;

  const [currentPage, setCurrentPage] = useState<string>("1");

  const pageButtons: JSX.Element[] = [];

  const [pageLimit, setPageLimit] = useState<string>("10");

  const totalPages: number = Math.ceil(totalRecords / parseInt(pageLimit));

  for (let index = 1; index <= totalPages; index++) {
    pageButtons.push(
      <PageButton
        key={index}
        active={currentPage === String(index)}
        onClick={() => handlePageChange(String(index))}
      >
        {index}
      </PageButton>
    );
  }

  const handlePageLimitChange = (val: string) => {
    setCurrentPage("1");
    setPageLimit(val);

    onPageLimitChange(val);
  };

  const handlePageChange = (val: string) => {
    setCurrentPage(val);

    if (currentPage !== val) {
      onPageChange(val);
    }
  };

  return (
    <Wrapper>
      <Left>
        <Info>Show per page:</Info>
        <LimitSelect
          onChange={(e) => handlePageLimitChange(e.target.value)}
          value={pageLimit}
          disabled={totalRecords === 0 || props.loading}
        >
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </LimitSelect>
      </Left>
      <Seperator />
      <Right>
        {totalRecords ? (
          <Info>
            {(parseInt(currentPage) - 1) * parseInt(pageLimit) + 1} -{" "}
            {parseInt(currentPage) * parseInt(pageLimit) > totalRecords
              ? totalRecords
              : parseInt(currentPage) * parseInt(pageLimit)}{" "}
            of {totalRecords} items
          </Info>
        ) : (
          <Info>No records found</Info>
        )}
        <PageBtnWrapper>{pageButtons}</PageBtnWrapper>
      </Right>
    </Wrapper>
  );
};

export default Pagination;
