import axios from "axios";
import { Dispatch } from "redux";

import {
    GET_RUBRIC_CATEGORY_LOADING,
    GET_RUBRIC_CATEGORY_ERROR,
    GET_RUBRIC_CATEGORY_SUCCESS,
    RubricCategoryDispatchTypes,
    ADD_RUBRIC_CATEGORY_LOADING,
    ADD_RUBRIC_CATEGORY_SUCCESS,
    ADD_RUBRIC_CATEGORY_ERROR,
    DELETE_RUBRIC_CATEGORY_SUCCESS,
    DELETE_RUBRIC_CATEGORY_ERROR,
    DELETE_RUBRIC_CATEGORY_LOADING,
    UPDATE_RUBRIC_CATEGORY_LOADING,
    UPDATE_RUBRIC_CATEGORY_SUCCESS,
    UPDATE_RUBRIC_CATEGORY_ERROR,
} from "./RubricCategoryActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

interface RequestDataI {
    name: string;
}

export const GetRubricCategory =
    () =>
        async (dispatch: Dispatch<RubricCategoryDispatchTypes | SetSnackBarI>) => {
            try {
                dispatch({
                    type: GET_RUBRIC_CATEGORY_LOADING,
                });

                const res = await axios.get(
                    `${ADMIN_API_URL}/rubric-category/`,
                    HeaderConfig()
                );

                dispatch({
                    type: GET_RUBRIC_CATEGORY_SUCCESS,
                    payload: res.data,
                });
            } catch (error) {
                dispatch({
                    type: GET_RUBRIC_CATEGORY_ERROR,
                });
            }
        };

export const AddRubricCategory =
    (data: RequestDataI) =>
        async (dispatch: Dispatch<RubricCategoryDispatchTypes | SetSnackBarI>) => {
            try {
                dispatch({
                    type: ADD_RUBRIC_CATEGORY_LOADING,
                });

                const res = await axios.post(
                    `${ADMIN_API_URL}/rubric-category/`,
                    data,
                    HeaderConfig()
                );

                dispatch({
                    type: ADD_RUBRIC_CATEGORY_SUCCESS,
                    payload: res.data,
                });

                dispatch({
                    type: SET_SNACKBAR,
                    payload: {
                        snackbarOpen: true,
                        snackbarType: "success",
                        snackbarMessage: "Rubric Category Added Successfully",
                    },
                });
            } catch (error: any) {
                dispatch({
                    type: ADD_RUBRIC_CATEGORY_ERROR,
                    payload: error.response && error.response.data,
                });

                dispatch({
                    type: SET_SNACKBAR,
                    payload: {
                        snackbarOpen: true,
                        snackbarType: "error",
                        snackbarMessage: "Rubric Category Add Failed",
                    },
                });
            }
        };

export const UpdateRubricCategory =
    (id: string, data: RequestDataI) =>
        async (dispatch: Dispatch<RubricCategoryDispatchTypes | SetSnackBarI>) => {
            try {
                dispatch({
                    type: UPDATE_RUBRIC_CATEGORY_LOADING,
                });

                const res = await axios.put(
                    `${ADMIN_API_URL}/rubric-category/${id}/`,
                    data,
                    HeaderConfig()
                );

                dispatch({
                    type: UPDATE_RUBRIC_CATEGORY_SUCCESS,
                    payload: res.data,
                });

                dispatch({
                    type: SET_SNACKBAR,
                    payload: {
                        snackbarOpen: true,
                        snackbarType: "success",
                        snackbarMessage: "Rubric Category Updated Successfully",
                    },
                });
            } catch (error: any) {
                dispatch({
                    type: UPDATE_RUBRIC_CATEGORY_ERROR,
                    payload: error.response && error.response.data,
                });

                dispatch({
                    type: SET_SNACKBAR,
                    payload: {
                        snackbarOpen: true,
                        snackbarType: "error",
                        snackbarMessage: "Rubric Category Update Failed",
                    },
                });
            }
        };

export const DeleteRubricCategory =
    (id: string) =>
        async (dispatch: Dispatch<RubricCategoryDispatchTypes | SetSnackBarI>) => {
            try {
                dispatch({
                    type: DELETE_RUBRIC_CATEGORY_LOADING,
                });

                const res = await axios.delete(
                    `${ADMIN_API_URL}/rubric-category/${id}/`,
                    HeaderConfig()
                );

                dispatch({
                    type: DELETE_RUBRIC_CATEGORY_SUCCESS,
                    payload: { id: id },
                });

                dispatch({
                    type: SET_SNACKBAR,
                    payload: {
                        snackbarOpen: true,
                        snackbarType: "success",
                        snackbarMessage: "Rubric Category Deleted Successfully",
                    },
                });
            } catch (error) {
                dispatch({
                    type: DELETE_RUBRIC_CATEGORY_ERROR,
                });

                dispatch({
                    type: SET_SNACKBAR,
                    payload: {
                        snackbarOpen: true,
                        snackbarType: "error",
                        snackbarMessage: "Rubric Category Delete Failed",
                    },
                });
            }
        };
