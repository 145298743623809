export const GET_MISC_FEE_TYPE_LOADING = "GET_MISC_FEE_TYPE_LOADING";
export const GET_MISC_FEE_TYPE_ERROR = "GET_MISC_FEE_TYPE_ERROR";
export const GET_MISC_FEE_TYPE_SUCCESS = "GET_MISC_FEE_TYPE_SUCCESS";

export const CREATE_MISC_FEE_TYPE_LOADING = "CREATE_MISC_FEE_TYPE_LOADING";
export const CREATE_MISC_FEE_TYPE_ERROR = "CREATE_MISC_FEE_TYPE_ERROR";
export const CREATE_MISC_FEE_TYPE_SUCCESS = "CREATE_MISC_FEE_TYPE_SUCCESS";

export const UPDATE_MISC_FEE_TYPE_LOADING = "UPDATE_MISC_FEE_TYPE_LOADING";
export const UPDATE_MISC_FEE_TYPE_ERROR = "UPDATE_MISC_FEE_TYPE_ERROR";
export const UPDATE_MISC_FEE_TYPE_SUCCESS = "UPDATE_MISC_FEE_TYPE_SUCCESS";

export const DELETE_MISC_FEE_TYPE_LOADING = "DELETE_MISC_FEE_TYPE_LOADING";
export const DELETE_MISC_FEE_TYPE_ERROR = "DELETE_MISC_FEE_TYPE_ERROR";
export const DELETE_MISC_FEE_TYPE_SUCCESS = "DELETE_MISC_FEE_TYPE_SUCCESS";

export const APPLY_MISC_FEE_TYPE_LOADING = "APPLY_MISC_FEE_TYPE_LOADING";
export const APPLY_MISC_FEE_TYPE_ERROR = "APPLY_MISC_FEE_TYPE_ERROR";
export const APPLY_MISC_FEE_TYPE_SUCCESS = "APPLY_MISC_FEE_TYPE_SUCCESS";

export type MiscFeeTypeI = {
    id: string;
    name: string;
    amount: number;
    grades: Array<string>;
    apply_to_all_grades: boolean;
};

//===============================<START>GET Fee Type<START>======================//

export interface GetMiscFeeTypeLoading {
    type: typeof GET_MISC_FEE_TYPE_LOADING;
}

export interface GetMiscFeeTypeError {
    type: typeof GET_MISC_FEE_TYPE_ERROR;
}

export interface GetMiscFeeTypeSuccess {
    type: typeof GET_MISC_FEE_TYPE_SUCCESS;
    payload: any;
}

//===============================<END>GET Fee Type<END>======================//

//===============================<START>Create Fee Type<START>======================//

export interface CreateMiscFeeTypeLoading {
    type: typeof CREATE_MISC_FEE_TYPE_LOADING;
}

export interface CreateMiscFeeTypeError {
    type: typeof CREATE_MISC_FEE_TYPE_ERROR;
    payload: any;
}

export interface CreateMiscFeeTypeSuccess {
    type: typeof CREATE_MISC_FEE_TYPE_SUCCESS;
    payload: MiscFeeTypeI;
}

//===============================<END>Create Fee Type<END>======================//

//===============================<START>Update Fee Type<START>======================//

export interface UpdateMiscFeeTypeLoading {
    type: typeof UPDATE_MISC_FEE_TYPE_LOADING;
}

export interface UpdateMiscFeeTypeError {
    type: typeof UPDATE_MISC_FEE_TYPE_ERROR;
    payload: any;
}

export interface UpdateMiscFeeTypeSuccess {
    type: typeof UPDATE_MISC_FEE_TYPE_SUCCESS;
    payload: MiscFeeTypeI;
}

//===============================<END>Update Fee Type<END>======================//

//===============================<START>Delete Fee Type<START>======================//

export interface DeleteMiscFeeTypeLoading {
    type: typeof DELETE_MISC_FEE_TYPE_LOADING;
}

export interface DeleteMiscFeeTypeError {
    type: typeof DELETE_MISC_FEE_TYPE_ERROR;
}

export interface DeleteMiscFeeTypeSuccess {
    type: typeof DELETE_MISC_FEE_TYPE_SUCCESS;
    payload: { id: string };
}

export interface ApplyMiscFeeTypeLoading {
    type: typeof APPLY_MISC_FEE_TYPE_LOADING;
}

export interface ApplyMiscFeeTypeError {
    type: typeof APPLY_MISC_FEE_TYPE_ERROR;
}

export interface ApplyMiscFeeTypeSuccess {
    type: typeof APPLY_MISC_FEE_TYPE_SUCCESS;
    payload: { id: string };
}
//===============================<END>Delete Fee Type<END>======================//

export type MiscFeeTypeDispatchTypes =
    | GetMiscFeeTypeLoading
    | GetMiscFeeTypeError
    | GetMiscFeeTypeSuccess
    | CreateMiscFeeTypeError
    | CreateMiscFeeTypeLoading
    | CreateMiscFeeTypeSuccess
    | UpdateMiscFeeTypeError
    | UpdateMiscFeeTypeLoading
    | UpdateMiscFeeTypeSuccess
    | DeleteMiscFeeTypeError
    | DeleteMiscFeeTypeLoading
    | DeleteMiscFeeTypeSuccess
    | ApplyMiscFeeTypeError
    | ApplyMiscFeeTypeLoading
    | ApplyMiscFeeTypeSuccess