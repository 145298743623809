import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import EventsForm from '../../components/Event/Competition/EventsForm';
import EventsList from '../../components/Event/Competition/EventsList';
import EventSidebar from '../../components/Event/EventSidebar/EventSideBar';
import SubModuleLayout from '../../components/Reusable/Layouts/SubModuleLayout';

interface EditDataI {
  id: number;
  name: string;
  count: number;
}

export default function CompetitionPage() {
  const [count, setCount] = useState<number>(1);
  const [formData, setFormData] = useState<EditDataI | null>(null);
  const [edit, setEdit] = useState<any>();

  const fetchEditData = (data: EditDataI) => {
    setFormData({ ...data, count: count });
    setCount(count + 1);
  };

  const nav = <EventSidebar competition />;
  return (
    <SubModuleLayout sideNav={nav}>
      <EventsList editCheck={edit} onEditData={fetchEditData} />
    </SubModuleLayout>
  );
}
