export const GET_DISCOUNT_SUMMARY_LOADING = "GET_DISCOUNT_SUMMARY_LOADING";
export const GET_DISCOUNT_SUMMARY_ERROR = "GET_DISCOUNT_SUMMARY_ERROR";
export const GET_DISCOUNT_SUMMARY_SUCCESS = "GET_DISCOUNT_SUMMARY_SUCCESS";

export interface GetDiscountSummaryLoading {
    type: typeof GET_DISCOUNT_SUMMARY_LOADING;
}

export interface GetDiscountSummaryError {
    type: typeof GET_DISCOUNT_SUMMARY_ERROR;
}

export interface GetDiscountSummarySuccess {
    type: typeof GET_DISCOUNT_SUMMARY_SUCCESS;
    payload: any;
}

export type DiscountSummaryDispatchTypes =
    | GetDiscountSummaryLoading
    | GetDiscountSummaryError
    | GetDiscountSummarySuccess