import React, { useEffect, useState } from 'react';
import {
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Button,
  IconButton,
  InputLabel,
  Typography,
} from '@material-ui/core';
import { Send } from '@material-ui/icons';
import { Close } from '@material-ui/icons';
import AddAnnoucementModal from '../../components/Communication/Annoucement/AddAnnoucement/AddAnnoucementModal';
import AnnoucementTabs, {
  TabDataI,
} from '../../components/Communication/Annoucement/AddAnnoucement/AnnoucementTabs/AnnoucementTabs';
import { AnnouncementTypeI } from '../../actions/Communication/Announcement/AnnouncementActionTypes';
import { useSelector } from 'react-redux';
import { RootStore } from '../../store';
import BackDropLoader from '../../components/Reusable/BackDropLoader';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      margin: theme.spacing(3),
      '& .MuiButton-containedPrimary': {
        width: '116px',
      },
    },

    buttonsave: {
      padding: '1rem',
    },
  })
);
interface PropsI {
  onClose: (value: boolean) => void;
  mode: 'create' | 'edit';
  initialData: AnnouncementTypeI | null;
}

export interface TabToFormI {
  id: number;
  tabData: TabDataI | null;
}

const AddAnnoucModalPage = (props: PropsI) => {
  const classes = useStyles();
  const { onClose, mode, initialData } = props;

  const [tabData, setTabData] = useState<TabDataI | null>(null);
  const [tabToForm, setTabtoForm] = useState<TabToFormI | null>(null);
  const [checkTab, setCheckTab] = useState(1);
  const [count, setCount] = useState(1);

  const [loading, setLoading] = useState<boolean>(false);

  const designationLoading = useSelector(
    (state: RootStore) => state.staff_roles.loading
  );
  const classLoading = useSelector((state: RootStore) => state.class.loading);
  const usersLoading = useSelector(
    (state: RootStore) => state.general_info.loading
  );
  const announcementCategoryLoading = useSelector(
    (state: RootStore) => state.announcement_type.loading
  );

  useEffect(() => {
    if (
      !designationLoading &&
      !classLoading &&
      !usersLoading &&
      !announcementCategoryLoading
    ) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [
    designationLoading,
    classLoading,
    usersLoading,
    announcementCategoryLoading,
  ]);

  const handleModalClose = (val: boolean) => {
    onClose(val);
  };

  const handleAnnouncementSubmit = () => {
    tabData
      ? setTabtoForm({ id: count, tabData: { ...tabData } })
      : setTabtoForm({
          id: count,
          tabData: null,
        });
    setCount(count + 1);
    setCheckTab(checkTab + 1);
  };

  const fetchTabData = (value: TabDataI | null) => {
    setTabData(value);
  };

  return (
    <>
      <Grid container spacing={0}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h6" style={{ color: '#4D4F5C' }}>
                  {mode === 'create' ? 'Add Announcement' : 'Edit Announcement'}
                </Typography>
              </Grid>

              {/* <Grid item>
                <IconButton
                  aria-label="close"
                  onClick={handleModalClose}
                  // onClick={onClose}
                >
                  <Close />
                </IconButton>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={12}>
          <Paper className={classes.root} elevation={0}>
            <div>
              <AddAnnoucementModal
                submitClicked={tabToForm}
                initData={initialData}
                closeModal={handleModalClose}
              />
            </div>

            <div>
              <AnnoucementTabs
                tabData={fetchTabData}
                submitClicked={checkTab}
                initData={initialData}
              />
              <Grid
                className={classes.buttonsave}
                container
                justifyContent="flex-end"
              >
                <Grid item xs={5}>
                  <Grid container justifyContent="flex-end">
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      startIcon={<Send />}
                      onClick={handleAnnouncementSubmit}
                    >
                      Preview
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Grid>
      </Grid>
      <BackDropLoader open={loading} />
    </>
  );
};
export default AddAnnoucModalPage;
