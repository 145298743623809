import React, { useState } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import styled from 'styled-components/macro';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: '100%',
      // paddingRight: '12px',
      backgroundColor: theme.palette.background.paper,
    },
    inline: {
      display: 'inline',
      color: 'rgba(0,0,0,0.75)',
      fontSize: '0.85rem',
    },
  })
);

const StyledListItem = styled(({ hasRead, ...props }: any) => (
  <ListItem {...props} />
))`
  transition: background ease 0.2s;
  border-right: 3px solid tomato;
  margin-bottom: 6px;
  border-radius: 3px;
  background: ${(props) =>
    props.hasRead ? 'transparent' : 'rgba(255, 0, 0, 0.075)'};
`;

const NotificationItem = (props: any) => {
  const classes = useStyles();
  const { notification } = props;

  const hasRead = true,
    name = 'John Doe',
    avatar = '/favicon.png',
    text = 'Hello world!',
    time = '2hrs ago';

  // return <p>This is notification, {JSON.stringify(notification)}</p>;

  return (
    <StyledListItem
      alignItems="flex-start"
      hasRead={hasRead}
      // onClick={() => handleHasRead(id, hasRead)}
    >
      {/* <ListItemAvatar>
        <Avatar alt={name} src={avatar} />
      </ListItemAvatar> */}
      <ListItemText
        primary={
          <>
            <Typography variant="subtitle2" style={{ fontWeight: 400 }}>
              {/* {notification.text} */}
              {notification.text.split('by ')[0]}
              <strong style={{ fontWeight: 600 }}>
                {notification.text.split('by ')[1]}
              </strong>{' '}
            </Typography>
            <Typography style={{ display: 'flex', alignItems: 'center' }}>
              <AccessTimeIcon style={{ fontSize: 14, marginRight: 4 }} />
              {moment(notification.created_on).fromNow()}
            </Typography>
          </>
        }
      />
    </StyledListItem>
  );
};

export default function NotificationContent(props: any) {
  const classes = useStyles();
  const { notifications } = props;

  return (
    <List className={classes.root}>
      {notifications.length > 0 &&
        notifications.map((notification: any, index: number) => (
          <NotificationItem key={index} notification={notification} />
        ))}
    </List>
  );
}
