import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    position: "relative",
    width: "100%",
    minWidth: 180,
    overflow: "hidden",
    "& .MuiCardContent-root": {
      paddingBottom: "4px",
    },
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
    color: "rgba(0,0,0,0.5)",
    fontWeight: 600,
    textTransform: "uppercase",
  },
  pos: {},
  btn: {
    "& span": {
      textTransform: "capitalize",
      fontSize: "13px",
      fontWeight: 600,
    },
  },

  icon: {
    color: "white",
    position: "absolute",
    top: "-25px",
    right: "-25px",
    background: "#43a047",
    borderRadius: "47%",
    padding: "35px 35px 10px 15px",
    zIndex: 999,
    "& svg": {
      fontSize: "2rem",
    },
  },
});

const TableAnalyticsCard = (props: any) => {
  const classes = useStyles();
  const { total, tagline, title, color } = props?.item;

  const Icon = props.item.icon;
  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          {title}
        </Typography>
        <Typography
          variant="h3"
          component="h2"
          style={{ fontWeight: 600, marginBottom: 4 }}
        >
          {total}
        </Typography>
        <Typography variant="body1" component="p">
          {tagline}
        </Typography>

        <div className={classes.icon} style={{ backgroundColor: color }}>
          <Icon />
        </div>
      </CardContent>
    </Card>
  );
};

export default TableAnalyticsCard;
