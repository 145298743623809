import {
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableHead,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useTable } from "../../Reusable";
import FullWidthFormLayout from "../../Reusable/Layouts/FullWidthForm.layout";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import {
  handleApplyToAllStudent,
  handleOptSubjectChange,
  resetState,
} from "../../../rtk/features/exam/optSubjectStudent/optSubjectStudentSlice";
import { Cancel, Save } from "@material-ui/icons";
import {
  getOptSubjectStudent,
  postOptSubjectStudent,
} from "../../../rtk/features/exam/optSubjectStudent/optSubjectStudentApi";
import { useFormStyles } from "../../Styles/FormStyles";
import {
  OptSubjectGroupI,
  OptSubjectI,
} from "../../../rtk/features/exam/optSubjects/optSubject";

const SelectWrapper = styled.span`
  display: flex;
  justify-content: center;
`;

const TableSelect = styled(Autocomplete)`
  min-width: 250px;
`;

const ClassDetail = styled.span`
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.8px;
  line-height: 1.6;

  & span {
    font-weight: 500;
  }
`;

const Heading = styled.span`
  border-bottom: 2px solid #000;
`;

const headCells = [
  { id: "sn", label: "S.N" },
  { id: "subject_group", label: "Subject Group" },
  { id: "subjects", label: "Subjects" },
  { id: "action", label: "Action" },
];

const OptSubjectStudentTable = () => {
  const classes = useFormStyles();
  const dispatch = useDispatch();
  const { StyledTableCell, StyledTableRow } = useTable(headCells);

  const { optSubjectGroups, studentOptSubject, searchParam } = useSelector(
    (state: RootStore) => state.studentOptSubject
  );

  const [selectedSubjectGroup, setSelectedSubjectGroup] =
    useState<OptSubjectGroupI | null>(null);

  const [optSubjectChoice, setOptSubjectChoice] = useState<OptSubjectI[]>([]);
  const [selectedSubject, setSelectedSubject] = useState<OptSubjectI | null>(
    null
  );

  const handleSubjectGroupChange = (val: OptSubjectGroupI | null) => {
    setSelectedSubjectGroup(val);
    setSelectedSubject(null);

    if (val) {
      setOptSubjectChoice(val.subject);
    }
  };

  useEffect(() => {
    dispatch(resetState());
  }, []);

  const handleReset = () => {
    if (searchParam) {
      dispatch(
        getOptSubjectStudent({
          grade: searchParam.grade.id,
          section: searchParam.section?.id || "",
        })
      );
    }
  };

  const handleApplySubjects = (val: OptSubjectI | null) => {
    setSelectedSubject(val);
    if (selectedSubjectGroup && val) {
      dispatch(
        handleApplyToAllStudent({
          groupId: selectedSubjectGroup.id,
          value: val.id,
        })
      );
    }
  };

  const handleSave = () => {
    const data = studentOptSubject
      .filter((el) => el.changed)
      .map((el) => ({
        student: el.id,
        optional_subjects: el.optional_subjects
          .filter((i) => i.value)
          .map((j) => j.value),
      }));

    if (searchParam) {
      dispatch(
        postOptSubjectStudent({
          grade: searchParam.grade.id,
          section: searchParam.section?.id || "",
          data,
        })
      );
    }
  };

  return (
    <FullWidthFormLayout>
      <Grid container>
        <Grid item xs={4}>
          <ClassDetail>
            Class:{" "}
            <span>
              {searchParam
                ? `${searchParam.grade.name} ${searchParam.section?.name || ""}`
                : "-"}
            </span>
          </ClassDetail>
        </Grid>
      </Grid>
      <Divider style={{ margin: "10px 0 15px 0" }} />
      {optSubjectGroups.length ? (
        <Grid container>
          <Grid item xs={12}>
            <Heading>Assign On All Students By Subject Group:</Heading>
          </Grid>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              className={classes.formWrapper}
            >
              <Autocomplete
                value={selectedSubjectGroup}
                onChange={(event: React.ChangeEvent<{}>, value) =>
                  handleSubjectGroupChange(value)
                }
                options={optSubjectGroups}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Opt. Subject Group"
                    name="class_name"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              className={classes.formWrapper}
            >
              <Autocomplete
                value={selectedSubject}
                onChange={(event: React.ChangeEvent<{}>, value) =>
                  handleApplySubjects(value)
                }
                options={optSubjectChoice}
                getOptionLabel={(option) => option.subject_name}
                disabled={!selectedSubjectGroup}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Opt. Subject"
                    name="class_name"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      <Table>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell align="left">Roll No.</StyledTableCell>
            <StyledTableCell align="center">Student</StyledTableCell>
            {optSubjectGroups.length ? (
              optSubjectGroups.map((el, index) => (
                <StyledTableCell align="center" key={index + 1}>
                  {el.name}
                </StyledTableCell>
              ))
            ) : (
              <StyledTableCell align="center">
                Optional Subject I
              </StyledTableCell>
            )}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {studentOptSubject.map((el, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell align="left">{index + 1}</StyledTableCell>
              <StyledTableCell align="center">
                {el.first_name} {el.last_name}
              </StyledTableCell>
              {el.optional_subjects.map((it, ind) => (
                <StyledTableCell align="center" key={ind + 1}>
                  <SelectWrapper>
                    <TableSelect
                      value={
                        it.opt_subject_group.find((e) => e.id === it.value) ||
                        null
                      }
                      onChange={(
                        event: React.ChangeEvent<{}>,
                        value: { id: string; name: string } | null
                      ) =>
                        dispatch(
                          handleOptSubjectChange({
                            studentId: el.id,
                            groupId: it.group_id,
                            value: value?.id || null,
                          })
                        )
                      }
                      options={it.opt_subject_group}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Subject"
                          name="class_name"
                          variant="outlined"
                        />
                      )}
                    />
                  </SelectWrapper>
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      {studentOptSubject.length ? (
        <Grid container>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSave()}
            startIcon={<Save />}
            style={{ margin: "10px 0 0 0" }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleReset()}
            startIcon={<Cancel />}
            style={{ margin: "10px 0 0 15px" }}
          >
            Cancel
          </Button>
        </Grid>
      ) : null}
    </FullWidthFormLayout>
  );
};

export default OptSubjectStudentTable;
