import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { Close } from '@material-ui/icons';
import cx from 'clsx';

interface PopupsI {
  children: React.ReactNode;
  openPopup: boolean;
  setOpenPopup: (value: boolean) => void;
  onClose: (value: boolean) => void;
  maxWidth?: any;
  colored?: boolean;
  // All other props
  [rest: string]: any;
}

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    overflowY: 'hidden',
    height: 'auto',
  },
  colHeader: {
    padding: '4px 0px 4px 24px',
    background: 'rgb(79 95 158)',
    marginBottom: '8px',
    '& *': {
      color: 'white!important',
    },
  },
  header: {
    padding: '8px 0px 0px 24px',
  },
  title: { color: '#4D4F5C', fontSize: '20px' },
  closeBtn: {
    marginRight: '12px',
  },
}));

function Popups(props: PopupsI) {
  const {
    children,
    openPopup,
    maxWidth,
    setOpenPopup,
    onClose,
    colored,
    ...rest
  } = props;

  const classes = useStyles();
  const handleClose = () => {
    onClose(false);
  };

  const PopupTitle = () => {
    return (
      <Grid
        container
        className={cx(true ? classes.colHeader : classes.header)} // Set every header as colored
      >
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              {rest.title && (
                <Typography variant="h6" className={classes.title}>
                  {rest.title}
                </Typography>
              )}
            </Grid>

            <Grid item>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                className={classes.closeBtn}
              >
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Dialog
      open={openPopup}
      maxWidth={props.maxWidth ? props.maxWidth : 'xl'}
      fullWidth
      className={classes.dialogWrapper}
      onClose={handleClose}
      {...rest}
    >
      <PopupTitle />
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}

export default Popups;
