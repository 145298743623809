import axios from "axios";
import { Dispatch } from "redux";

import {
  GENERAL_INFO_LOADING,
  GENERAL_INFO_ERROR,
  GENERAL_INFO_SUCCESS,
  ADD_GENERAL_INFO_LOADING,
  ADD_GENERAL_INFO_ERROR,
  ADD_GENERAL_INFO_SUCCESS,
  UPDATE_GENERAL_INFO_LOADING,
  UPDATE_GENERAL_INFO_ERROR,
  UPDATE_GENERAL_INFO_SUCCESS,
  GeneralInfoDispatchTypes,
  GET_USERS_BY_ROLE_ERROR,
  GET_USERS_BY_ROLE_SUCCESS,
  GET_USERS_BY_ROLE_LOADING,
} from "./GeneralInfoActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

interface RequestDataI {
  logo?: any;
  school_name: string;
  address: string;
  phone_number: string;
  email: string;
  slogan: string;
  school_reg_number: string;
}

export const GetGeneralInfoData =
  (id: string) =>
  async (dispatch: Dispatch<GeneralInfoDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GENERAL_INFO_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/general-info/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: GENERAL_INFO_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GENERAL_INFO_ERROR,
      });
    }
  };

export const GetUsersByRole =
  (designation: string, search = "", birthday = "") =>
  async (dispatch: Dispatch<GeneralInfoDispatchTypes | SetSnackBarI>) => {
    const URL = `${ADMIN_API_URL}/group-users/?group=${designation}&search=${search}&birthday=${birthday}`;
    try {
      dispatch({
        type: GET_USERS_BY_ROLE_LOADING,
      });

      const res = await axios.get(URL, HeaderConfig());

      dispatch({
        type: GET_USERS_BY_ROLE_SUCCESS,
        payload: res.data.results,
      });
    } catch (error) {
      dispatch({
        type: GET_USERS_BY_ROLE_ERROR,
      });
    }
  };

export const AddGeneralInfo =
  (data: any) =>
  async (dispatch: Dispatch<GeneralInfoDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_GENERAL_INFO_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/general-info/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_GENERAL_INFO_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "General Info Saved Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: ADD_GENERAL_INFO_ERROR,
      });
    }
  };

export const UpdateGeneralInfo =
  (id: string | null, data: any) =>
  async (dispatch: Dispatch<GeneralInfoDispatchTypes | SetSnackBarI>) => {
    // const formData = {};
    // for (const [key, prop] of data) {
    //   formData[key] = prop;
    // }
    // console.log({ formData });
    try {
      dispatch({
        type: UPDATE_GENERAL_INFO_LOADING,
      });

      const res = await axios.put(
        `${ADMIN_API_URL}/general-info/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_GENERAL_INFO_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "General Info Saved Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: UPDATE_GENERAL_INFO_ERROR,
      });
    }
  };
