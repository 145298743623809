import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { Fee_TypeI } from "../../actions/Fees/FeeType/FeeTypeActionTypes";
import { FinanceSidebar, FeesForm, FeesTable } from "../../components";
import FeeTypeForm from "../../components/Finance/FeeType/FeeTypeForm";
import FeeTypeTable from "../../components/Finance/FeeType/FeeTypeTable";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";
import { useSelector } from "react-redux";
import { RootStore } from "../../store";
import { PageRoleAccess } from "../../components/utils/rolesConfig";
import { Redirect } from "react-router";

interface EditDataI {
  id: string;
  name: string;
  is_monthly: boolean;
  description: string;
  due_date: string | null;
  due_day: number | null;
  due_period_type: string | null;
  count: number;
}

const FeeTypePage = () => {
  const [formData, setFormData] = useState<any | null>(null);
  const [count, setCount] = useState<number>(0);
  const [edit, setEdit] = useState<any>();

  const authState = useSelector((state: RootStore) => state.auth);

  const fetchEditData = (data: Fee_TypeI) => {
    setFormData({ ...data, count: count });
    setCount(count + 1);
  };

  const onEditCheck = (value: boolean) => {
    setEdit({ check: value, count: count });
  };

  console.log({ formData })

  const nav = <FinanceSidebar fee_type />;

  if (!PageRoleAccess(authState?.role, "FINANCE", "fee_type"))
    return <Redirect to="/" />;

  return (
    <SubModuleLayout sideNav={nav}>
      <Grid container>
        <Grid item xs={4}>
          <FeeTypeForm onEditCheck={onEditCheck} editData={formData} />
        </Grid>
        <Grid item xs={8}>
          <FeeTypeTable editCheck={edit} onEditData={fetchEditData} />
        </Grid>
      </Grid>
    </SubModuleLayout>
  );
};

export default FeeTypePage;
