// ---------------------------- <START> module imports start ----------------------------------//
import React, { useState, useEffect } from "react";
import {
  TableBody,
  TextField,
  Grid,
  Typography,
  Paper,
  makeStyles,
  createStyles,
  Theme,
  LinearProgress
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { useTableStyles } from "../../Styles/TableStyles";
import { useTable } from "../../Reusable";
import TableActionButtons from "../../Reusable/Buttons/TableActionButtons";
import getFullName from "../../utils/getFullName";
import tablePrint from "../../Reusable/Table/PrintTable";
import { InvoiceRecordI, InvoiceStatementI } from "../../../actions/Fees/CollectFees/CollectFeesActionTypes";
import { RootStore } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { GetInvoiceRecord, GetStatementDetails, UpdatePaidInvoice } from "../../../actions/Fees/CollectFees/CollectFeesAction";
import {
  ItemEditButton,
  ItemSaveButton,
  ItemViewButton,
} from "../../Reusable/Buttons/TableButton";
import { dateConverterAdToBs } from "../../utils/dateConverter";
import StatementDetailModal from "./modal/StatementDetails";
import BackDropLoader from "../../Reusable/BackDropLoader";
// ----------------------------<END> module imports end ----------------------------------//

const customStyles = makeStyles((theme: Theme) => createStyles({
  feeDetailGrid: {
    backgroundColor: "#d4d4d4",
    padding: "10px"
  }
}))

// ---------------------------- <START> interface starts ----------------------------------//
interface HeadCellsI {
  id: string;
  label: string;
}

interface PropsI {
  student_id: string | null;
  editDisabled?: boolean;
}

interface FeeAmountRecordI {
  total_amount: number;
  total_paid_amount: number;
  total_dues: number;
  total_advance: number;
  financial_clear_due: number;
  financial_clear_advance: number;
}


// ---------------------------- <END> interface ends ----------------------------------//

// ---------------------------- <START> styling starts ----------------------------------//

// ---------------------------- <END> styling ends ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "date", label: "Date" },
  { id: "fee_type", label: "Fee" },
  { id: "amount", label: "Fee Amount" },
  { id: "paid_amount", label: "Paid Amount" },
  { id: "due_amount", label: "Due Amount" },
  { id: "advance_amount", label: "Advance Amount" },
  { id: "payment_type", label: "Payment Type" },
  { id: "actions", label: "Actions" },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const SelectedStudentTable = (props: PropsI) => {
  const { student_id, editDisabled } = props;
  const classes = useTableStyles();
  const customCssClass = customStyles();


  // States declaration
  const [tableData, setTableData] = useState<InvoiceStatementI[]>([]);
  const [feeRecord, setFeeRecord] = useState<FeeAmountRecordI | null>(null);
  const [tempTableData, setTempTableData] = useState<InvoiceStatementI[]>([]);

  const [totalRecord, setTotalRecord] = useState(tempTableData.length);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [pageCount, setPageCount] = useState(
    Math.ceil(totalRecord / rowsPerPage)
  );
  const [page, setPage] = useState(1);

  const [recordFrom, setRecordFrom] = useState(1);
  const [recordTo, setRecordTo] = useState(7);

  const [detailModalOpen, setDetailModalOpen] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const [tableEditRowId, setTableEditRowId] = useState<string | null>(null);

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
    setRecordFrom(newPage === 1 ? newPage : (newPage - 1) * rowsPerPage + 1);
    setRecordTo(newPage === 1 ? newPage * rowsPerPage : newPage * rowsPerPage);
  };

  // Retrieving re-usable components from useTable
  const {
    TblContainer,
    TblHead,
    StyledTableCell,
    StyledTableRow,
    exportToExcel,
  } = useTable(headCells);

  const dispatch = useDispatch();
  const invoiceRecord = useSelector((state: RootStore) => state.collect_fees);


  useEffect(() => {
    if (student_id) {
      dispatch(GetInvoiceRecord(student_id))
    }
  }, [])

  console.log("this", invoiceRecord?.statement_details)

  useEffect(() => {
    const data: InvoiceRecordI | null = invoiceRecord.invoice_record;
    if (data) {
      const dataLength = data.statement.length

      setFeeRecord({
        total_amount: data.total_invoice_amount,
        total_paid_amount: data.total_paid_amount,
        total_dues: data.total_due_amount,
        total_advance: data.total_advance_amount,
        financial_clear_due: data.financial_clear_due,
        financial_clear_advance: data.financial_clear_advance
      })
      setTableData(data.statement);
      setTempTableData(data.statement);

      setTotalRecord(dataLength);
      setPageCount(Math.ceil(dataLength / rowsPerPage));
    }
    setLoading(invoiceRecord.loading);
  }, [invoiceRecord]);

  const handleViewDetails = (id: string) => {
    dispatch(GetStatementDetails(id));
    setDetailModalOpen(true);
  }

  const handleRowEdit = (id: string) => {
    setTableEditRowId(id);
  }

  const handleRowSave = (id: string, paid: number) => {
    setTableEditRowId(null);

    if (student_id) {
      dispatch(UpdatePaidInvoice({ id: id, paid_amount: paid }, student_id))
    }
  }

  const handlePaidAmountChange = (id: string, paid: string) => {
    const table_data = [...tableData];
    const mIndex: number = table_data.findIndex(item => item.id === id);

    if (mIndex !== -1) {
      table_data[mIndex].paid_amount = Number(paid)
    }

    setTableData(table_data);
  }

  return (
    <>
      <Paper className={classes.rootTableTabBox}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" style={{ color: "#4D4F5C" }}>
              Fee Statements
            </Typography>
          </Grid>
        </Grid>
        <div className={classes.tableWrapper}>
          <Grid container className={customCssClass.feeDetailGrid}>
            <Grid item xs={3}>
              <span>Total Amount: Rs {feeRecord ? feeRecord.total_amount : 0}</span>
            </Grid>
            <Grid item xs={3}>
              <span>Total Paid: Rs {feeRecord ? feeRecord.total_paid_amount : 0}</span>
            </Grid>
            <Grid item xs={3}>
              <span>Total Dues: Rs {feeRecord ? feeRecord.total_dues : 0}</span>
            </Grid>
            <Grid item xs={3}>
              <span>Total Advance: Rs {feeRecord ? feeRecord.total_advance : 0}</span>
            </Grid>
          </Grid>
          <TblContainer>
            <TblHead />
            <TableBody>
              {tableData
                .slice(
                  (page - 1) * rowsPerPage,
                  (page - 1) * rowsPerPage + rowsPerPage
                )
                .map((item: InvoiceStatementI) => (
                  <StyledTableRow key={item.id}>
                    <StyledTableCell align="center" className={classes.cell}>
                      {dateConverterAdToBs(item.created_on)}
                    </StyledTableCell>
                    <StyledTableCell align="center" className={classes.cell}>
                      {item.fee_type} {item.month}
                    </StyledTableCell>
                    <StyledTableCell align="center" className={classes.cell}>
                      {item.amount_to_pay}
                    </StyledTableCell>
                    <StyledTableCell align="center" className={classes.cell}>
                      {tableEditRowId === item.id ? (
                        <TextField
                          variant="outlined"
                          defaultValue={item.paid_amount}
                          type="number"
                          style={{ width: "150px" }}
                          onBlur={(event: any) =>
                            handlePaidAmountChange(item.id, event.target.value)
                          }
                        />
                      ) :
                        item.paid_amount}
                    </StyledTableCell>
                    <StyledTableCell align="center" className={classes.cell}>
                      {item.due_amount}
                    </StyledTableCell>
                    <StyledTableCell align="center" className={classes.cell}>
                      {item.advance_amount}
                    </StyledTableCell>
                    <StyledTableCell align="center" className={classes.cell}>
                      {item.payment_method_name}
                    </StyledTableCell>
                    <StyledTableCell align="center" className={classes.cell}>
                      <ItemViewButton disabled={tableEditRowId} onClick={() => handleViewDetails(item.id)} />
                      {
                        !editDisabled ?
                          tableEditRowId === item.id ?
                            <ItemSaveButton onClick={() => handleRowSave(item.id, item.paid_amount)} />
                            : <ItemEditButton disabled={tableEditRowId} onClick={() => handleRowEdit(item.id)} />
                          : null
                      }
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </TblContainer>
          {loading ? <LinearProgress /> : null}
          <div className={classes.tableBottom}>
            {totalRecord > 0 ? (
              <Typography>
                Showing {recordFrom} to{" "}
                {recordTo > totalRecord ? totalRecord : recordTo} of{" "}
                {totalRecord}
              </Typography>
            ) : (
              <Typography>No records found</Typography>
            )}
            <div className={classes.pageNavigation}>
              <Pagination
                count={pageCount}
                page={page}
                onChange={handlePageChange}
                shape="rounded"
                color="primary"
              />
            </div>
          </div>
        </div>
      </Paper>

      <StatementDetailModal
        modalState={detailModalOpen}
        handleClose={() => setDetailModalOpen(false)}
      />
      <BackDropLoader open={loading} />
    </>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default SelectedStudentTable;
