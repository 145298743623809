import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import CardLayout from "../../Reusable/Layouts/CardLayout";
import AttendanceDeviceTabs from "./AttendanceDeviceTabs";
import { StatusText } from "./AttendanceDeviceList";
import RouterIcon from "@material-ui/icons/Router";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleAttendanceDevice,
  readDeviceConfig,
} from "../../../rtk/features/attendanceDevice/attendanceDeviceThunk";
import { RootStore } from "../../../store";
import BackDropLoader from "../../Reusable/BackDropLoader";

const AttendanceDeviceInfo = () => {
  const { deviceId, configId } = useParams<{
    deviceId: string;
    configId: string;
  }>();
  const dispatch = useDispatch();

  const { loading, attendanceDevice } = useSelector(
    (state: RootStore) => state.attendanceDevice
  );

  useEffect(() => {
    if (deviceId) {
      dispatch(
        getSingleAttendanceDevice({ config: configId, device: deviceId })
      );
    }
  }, []);

  const headData = [
    {
      id: 2,
      headCells: "Server Status:",
      cellData: <StatusText status="Online">Online</StatusText>,
    },
    {
      id: 3,
      headCells: "Serial Number:",
      cellData: attendanceDevice?.sn || "-",
    },
    {
      id: 4,
      headCells: "IP Address:",
      cellData: attendanceDevice?.ip_address || "-",
    },
    {
      id: 5,
      headCells: "Area ID:",
      cellData: "1",
    },
    {
      id: 5,
      headCells: "Departments:",
      cellData: "9",
    },
    {
      id: 7,
      headCells: "Staffs:",
      cellData: "23",
    },
  ];
  return (
    <>
      <Grid container>
        <Grid item xs={3}>
          <CardLayout
            fullName={attendanceDevice?.alias || "-"}
            headData={headData}
            defaultIcon={<RouterIcon />}
          />
        </Grid>
        <Grid item xs={9}>
          <AttendanceDeviceTabs />
        </Grid>
      </Grid>
      <BackDropLoader open={loading} />
    </>
  );
};

export default AttendanceDeviceInfo;
