import {
  GET_STAFF_DOCUMENT_LOADING,
  GET_STAFF_DOCUMENT_ERROR,
  GET_STAFF_DOCUMENT_SUCCESS,
  ADD_STAFF_DOCUMENT_LOADING,
  ADD_STAFF_DOCUMENT_SUCCESS,
  ADD_STAFF_DOCUMENT_ERROR,
  DELETE_STAFF_DOCUMENT_SUCCESS,
  DELETE_STAFF_DOCUMENT_ERROR,
  DELETE_STAFF_DOCUMENT_LOADING,
  DocumentTypeI,
  DocumentDispatchTypes,
} from "../../actions/HumanResource/StaffDocument/StaffDocumentActionTypes";

interface InitialStateI {
  loading: boolean;
  staff_document: DocumentTypeI[];
}

const initialState: InitialStateI = {
  loading: false,
  staff_document: [],
};

const StaffDocumentReducer = (
  state: InitialStateI = initialState,
  action: DocumentDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_STAFF_DOCUMENT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_STAFF_DOCUMENT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_STAFF_DOCUMENT_SUCCESS:
      return {
        loading: false,
        staff_document: action.payload,
      };

    case ADD_STAFF_DOCUMENT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case ADD_STAFF_DOCUMENT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case ADD_STAFF_DOCUMENT_SUCCESS:
      return {
        loading: false,
        staff_document: [...state.staff_document, action.payload],
      };

    case DELETE_STAFF_DOCUMENT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_STAFF_DOCUMENT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_STAFF_DOCUMENT_SUCCESS:
      const new_data: DocumentTypeI[] = state.staff_document;
      const item_index = new_data.findIndex(
        (element) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return {
        loading: false,
        staff_document: new_data,
      };

    default:
      return state;
  }
};

export default StaffDocumentReducer;
