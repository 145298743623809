import React from "react";
import { ReportSideBar } from "../../../../components";
import MyAdmitCards from "../../../../components/Reports/Templates/AdmitCard/MyAdmitCards";
import SubModuleLayout from "../../../../components/Reusable/Layouts/SubModuleLayout";

const AdmitCardTemplatePage = () => {
  const nav = <ReportSideBar admit_card_template />;
  return (
    <SubModuleLayout reportMenuActive sideNav={nav}>
      <MyAdmitCards />
    </SubModuleLayout>
  );
};

export default AdmitCardTemplatePage;
