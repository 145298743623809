import React, { useState } from 'react';
import {
  makeStyles,
  Theme,
  FormControl,
  InputLabel,
  OutlinedInput,
  withStyles,
  createStyles,
  InputAdornment,
  IconButton,
  FormHelperText,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';

const useStyles = makeStyles((theme: Theme) => {
  return {
    inputLabel: {
      transform: 'translate(-1px, -6px) scale(1)!important',
      fontSize: 14,
      fontWeight: 500,
      color: 'inherit',
      letterSpacing: 'inherit',
    },
  };
});

export const AuthInput = withStyles((theme: Theme) => {
  return createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      fontSize: 14,
    },
  });
})(OutlinedInput);

const AuthInputField = (props: any) => {
  const classes = useStyles();
  const { label, helperText, name, register, required, ...rest } = props;

  return (
    <FormControl variant="outlined" fullWidth style={{ marginBottom: 16 }}>
      {label && (
        <InputLabel
          className={classes.inputLabel}
          required={required}
          error={rest.error}
        >
          {label}
        </InputLabel>
      )}

      <AuthInput
        {...rest}
        required={required}
        name={name}
        inputRef={register({ required: true })}
      />

      {helperText && (
        <FormHelperText error={rest.error} variant="outlined">
          {rest.helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default AuthInputField;

// Password Field
interface AuthPasswordFieldI {
  [x: string]: any;
}

export const AuthPasswordField = (props: AuthPasswordFieldI) => {
  const { ...rest } = props;
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((showPassword) => !showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const PasswordIcon = (
    <InputAdornment position="start">
      <LockOutlinedIcon />
    </InputAdornment>
  );

  const PasswordIconEnd = (
    <InputAdornment position="end">
      <IconButton
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
        edge="end"
      >
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );

  return (
    <AuthInputField
      type={showPassword ? 'text' : 'password'}
      startAdornment={PasswordIcon}
      endAdornment={PasswordIconEnd}
      {...rest}
    />
  );
};
