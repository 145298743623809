import {
    GET_DUE_REPORTS_ERROR,
    GET_DUE_REPORTS_LOADING,
    GET_DUE_REPORTS_SUCCESS,
    GET_DUE_ALERT_ERROR,
    GET_DUE_ALERT_LOADING,
    GET_DUE_ALERT_SUCCESS,
    SEND_DUE_ALERT_ERROR,
    SEND_DUE_ALERT_LOADING,
    SEND_DUE_ALERT_SUCCESS,
    DueReportsDispatchTypes,
} from "../../actions/Fees/DueReports/DueReportsActionTypes";

interface InitialStateI {
    loading: boolean;
    due_reports: any;
    due_alert: any;
}

const initialState: InitialStateI = {
    loading: false,
    due_reports: [],
    due_alert: [],

};

const DueReportsReducer = (
    state: InitialStateI = initialState,
    action: DueReportsDispatchTypes
): InitialStateI => {
    switch (action.type) {
        case GET_DUE_REPORTS_LOADING:
            return {
                ...state,
                loading: true,
            };

        case GET_DUE_REPORTS_ERROR:
            return {
                ...state,
                loading: false,
            };

        case GET_DUE_REPORTS_SUCCESS:
            return {
                ...state,
                loading: false,
                due_reports: action.payload,
            };
        case GET_DUE_ALERT_LOADING:
            return {
                ...state,
                loading: true,
            };

        case GET_DUE_ALERT_ERROR:
            return {
                ...state,
                loading: false,
            };

        case GET_DUE_ALERT_SUCCESS:
            return {
                ...state,
                loading: false,
                due_alert: action.payload,
            };

        case SEND_DUE_ALERT_LOADING:
            return {
                ...state,
                loading: true,
            };

        case SEND_DUE_ALERT_ERROR:
            return {
                ...state,
                loading: false,
            };

        case SEND_DUE_ALERT_SUCCESS:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
};

export default DueReportsReducer;