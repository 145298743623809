import axios from "axios";
import { Dispatch } from "redux";

import {
    GET_DUE_REPORTS_ERROR,
    GET_DUE_REPORTS_LOADING,
    GET_DUE_REPORTS_SUCCESS,
    GET_DUE_ALERT_ERROR,
    GET_DUE_ALERT_LOADING,
    GET_DUE_ALERT_SUCCESS,
    SEND_DUE_ALERT_LOADING,
    SEND_DUE_ALERT_ERROR,
    SEND_DUE_ALERT_SUCCESS,
    DueReportsDispatchTypes,
} from "./DueReportsActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

export const GetDueReports =
    (id: number, grade: string, section: string, start_date: string, end_date: string) =>
        async (dispatch: Dispatch<DueReportsDispatchTypes | SetSnackBarI>) => {
            try {
                dispatch({
                    type: GET_DUE_REPORTS_LOADING,
                });

                const res = await axios.get(
                    `${ADMIN_API_URL}/report/due/?page=${id}&grade=${grade}&section=${section}&from_date=${start_date}&to_date=${end_date}`,
                    HeaderConfig()
                );

                dispatch({
                    type: GET_DUE_REPORTS_SUCCESS,
                    payload: res.data,
                });
            } catch (error) {
                dispatch({
                    type: GET_DUE_REPORTS_ERROR,
                });
            }
        };

export const GetDueAlerts =
    (feeType: string, grade: string, section: string, month: string) =>
        async (dispatch: Dispatch<DueReportsDispatchTypes | SetSnackBarI>) => {
            try {
                dispatch({
                    type: GET_DUE_ALERT_LOADING,
                });

                const res = await axios.get(
                    `${ADMIN_API_URL}/update-invoice/send_due_alert/?grade=${grade}&section=${section}&month=${month}&fee_type=${feeType}`,
                    HeaderConfig()
                );

                dispatch({
                    type: GET_DUE_ALERT_SUCCESS,
                    payload: res.data,
                });
            } catch (error) {
                dispatch({
                    type: GET_DUE_ALERT_ERROR,
                });
            }
        };

export const SendDueAlert =
    (feeType: string, grade: string, section: string, month: number, func: any, data: any) =>
        async (dispatch: Dispatch<DueReportsDispatchTypes | SetSnackBarI>) => {
            try {
                dispatch({
                    type: SEND_DUE_ALERT_LOADING,
                });

                const res = await axios.post(
                    `${ADMIN_API_URL}/update-invoice/send_due_alert/?grade=${grade}&section=${section}&month=${month}&fee_type=${feeType}`,
                    data,
                    HeaderConfig()
                );

                dispatch({
                    type: SEND_DUE_ALERT_SUCCESS,
                    payload: res.data,
                });
                dispatch({
                    type: SET_SNACKBAR,
                    payload: {
                        snackbarOpen: true,
                        snackbarType: "success",
                        snackbarMessage: "Due Alert Sent Successfully",
                    },
                });
                func()
            } catch (error) {
                dispatch({
                    type: SEND_DUE_ALERT_ERROR,
                });
                dispatch({
                    type: SET_SNACKBAR,
                    payload: {
                        snackbarOpen: true,
                        snackbarType: "error",
                        snackbarMessage: "Sending Alert Error",
                    },
                });
            }
        };