export const GET_ENOTE_CATEGORY_LOADING = "GET_ENOTE_CATEGORY_LOADING";
export const GET_ENOTE_CATEGORY_ERROR = "GET_ENOTE_CATEGORY_ERROR";
export const GET_ENOTE_CATEGORY_SUCCESS = "GET_ENOTE_CATEGORY_SUCCESS";

export const ADD_ENOTE_CATEGORY_LOADING = "ADD_ENOTE_CATEGORY_LOADING";
export const ADD_ENOTE_CATEGORY_ERROR = "ADD_ENOTE_CATEGORY_ERROR";
export const ADD_ENOTE_CATEGORY_SUCCESS = "ADD_ENOTE_CATEGORY_SUCCESS";

export const UPDATE_ENOTE_CATEGORY_LOADING = "UPDATE_ENOTE_CATEGORY_LOADING";
export const UPDATE_ENOTE_CATEGORY_ERROR = "UPDATE_ENOTE_CATEGORY_ERROR";
export const UPDATE_ENOTE_CATEGORY_SUCCESS = "UPDATE_ENOTE_CATEGORY_SUCCESS";

export const DELETE_ENOTE_CATEGORY_LOADING = "DELETE_ENOTE_CATEGORY_LOADING";
export const DELETE_ENOTE_CATEGORY_ERROR = "DELETE_ENOTE_CATEGORY_ERROR";
export const DELETE_ENOTE_CATEGORY_SUCCESS = "DELETE_ENOTE_CATEGORY_SUCCESS";

export type EnoteCategoryTypeI = {
  id: string;
  name: string;
  description: string;
  count?: number;
};

//===============================<START>GET ENOTE_CATEGORY<START>======================//

export interface GetEnoteCategoryLoading {
  type: typeof GET_ENOTE_CATEGORY_LOADING;
}

export interface GetEnoteCategoryError {
  type: typeof GET_ENOTE_CATEGORY_ERROR;
}

export interface GetEnoteCategorySuccess {
  type: typeof GET_ENOTE_CATEGORY_SUCCESS;
  payload: EnoteCategoryTypeI[];
}

//===============================<END>GET ENOTE_CATEGORY<END>======================//

//===============================<START>ADD ENOTE_CATEGORY<START>======================//

export interface AddEnoteCategoryLoading {
  type: typeof ADD_ENOTE_CATEGORY_LOADING;
}

export interface AddEnoteCategoryError {
  type: typeof ADD_ENOTE_CATEGORY_ERROR;
  payload: any;
}

export interface AddEnoteCategorySuccess {
  type: typeof ADD_ENOTE_CATEGORY_SUCCESS;
  payload: EnoteCategoryTypeI;
}

//===============================<END>ADD ENOTE_CATEGORY<END>======================//

//===============================<START>UPDATE ENOTE_CATEGORY<START>======================//

export interface UpdateEnoteCategoryLoading {
  type: typeof UPDATE_ENOTE_CATEGORY_LOADING;
}

export interface UpdateEnoteCategoryError {
  type: typeof UPDATE_ENOTE_CATEGORY_ERROR;
  payload: any;
}

export interface UpdateEnoteCategorySuccess {
  type: typeof UPDATE_ENOTE_CATEGORY_SUCCESS;
  payload: EnoteCategoryTypeI;
}

//===============================<END>UPDATE ENOTE_CATEGORY<END>======================//

//===============================<START>DELETE ENOTE_CATEGORY<START>======================//

export interface DeleteEnoteCategoryLoading {
  type: typeof DELETE_ENOTE_CATEGORY_LOADING;
}

export interface DeleteEnoteCategoryError {
  type: typeof DELETE_ENOTE_CATEGORY_ERROR;
}

export interface DeleteEnoteCategorySuccess {
  type: typeof DELETE_ENOTE_CATEGORY_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE ENOTE_CATEGORY<END>======================//

export type EnoteCategoryDispatchTypes =
  | GetEnoteCategoryLoading
  | GetEnoteCategoryError
  | GetEnoteCategorySuccess
  | AddEnoteCategoryLoading
  | AddEnoteCategoryError
  | AddEnoteCategorySuccess
  | UpdateEnoteCategoryLoading
  | UpdateEnoteCategoryError
  | UpdateEnoteCategorySuccess
  | DeleteEnoteCategoryLoading
  | DeleteEnoteCategoryError
  | DeleteEnoteCategorySuccess;
