import React from "react";
import { HRsidebar } from "../../components";
import StaffIdCardList from "../../components/HumanResource/IdCard/StaffIdCardList";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

export default function StaffIdCardListPage() {
  const nav = <HRsidebar IdCard />;
  return (
    <SubModuleLayout reportMenuActive sideNav={nav}>
      <StaffIdCardList />
    </SubModuleLayout>
  );
}
