import React from "react";
import { ReportSideBar } from "../../../../components";
import BrowseAdmitCardTemplates from "../../../../components/Reports/Templates/AdmitCard/BrowseAdmitCardTemplates";
import SubModuleLayout from "../../../../components/Reusable/Layouts/SubModuleLayout";

const AdmitCardTemplateBrowsePage = () => {
  const nav = <ReportSideBar admit_card_template />;
  return (
    <SubModuleLayout reportMenuActive sideNav={nav}>
      <BrowseAdmitCardTemplates />
    </SubModuleLayout>
  );
};

export default AdmitCardTemplateBrowsePage;
