// ---------------------------- <START> module imports start ----------------------------------//
import React, { useState, useEffect } from 'react';
import { Paper, Typography } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import { useTable } from '../../Reusable';
import { useTableStyles } from '../../Styles/TableStyles';
import ActionButton from '../../Reusable/Buttons/ActionButton';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import {
  GetStudentHouses,
  DeleteStudentHouse,
} from '../../../actions/Student/StudentHouse/StudentHouseAction';
import CustomizedDialogs from '../../Reusable/Dialogs/DeleteDialog';
import { String } from 'lodash';
import AccessPermission from '../StudentDetail/AccessPermission';
// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//
interface TableDataI {
  id: string;
  name: string;
}

interface PropsI {
  onEditData: (value: TableDataI) => void;
}

interface HeadCellsI {
  id: string;
  label: string;
}
// ---------------------------- <END> interface ends ----------------------------------//

// ---------------------------- <START> styling starts ----------------------------------//

// ---------------------------- <END> styling ends ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: 'sn', label: 'S.N' },
  { id: 'name', label: 'House Name' },
  // { id: "id", label: "House ID" },
  { id: 'action', label: 'Action' },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const StudentHouseTable = (props: PropsI) => {
  const { onEditData } = props;
  const classes = useTableStyles();

  // States declaration
  const [tableData, setTableData] = useState<TableDataI[]>([]);

  // Modal States
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);

  // Student House reducer
  const dispatch = useDispatch();
  const studentHouse = useSelector((state: RootStore) => state.student_house);

  // Fetch data
  useEffect(() => {
    dispatch(GetStudentHouses());
  }, []);

  useEffect(() => {
    const dataLength = studentHouse.student_houses.length;
    setLoading(studentHouse.loading);

    const items: TableDataI[] = studentHouse.student_houses.map(
      (student_house) => ({
        id: student_house.id,
        name: student_house.name,
      })
    );

    setTableData(items);
  }, [studentHouse]);

  // -----------------<START> Pagination states and functions <START>-----------------------//

  // -----------------<END> Pagination states and functions <END>-----------------------//

  // Retrieving re-usable components from useTable
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  // Function for handling action icons click event
  const handleActionClicked = () => {
    alert('Action Clicked!');
  };

  // Edit record
  const handleEditClicked = (data: TableDataI) => {
    onEditData(data);
  };

  // Delete record modal
  const handleDeleteModal = (value: boolean, id: string) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteStudentHouse(itemId));
    setDeleteModalOpen(false);
  };

  const getCustomTableRow = (item: TableDataI, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.name}
        </StyledTableCell>

        <StyledTableCell align="center" className={classes.cell}>
          <AccessPermission>
            <ActionButton title="Edit" onClick={() => handleEditClicked(item)}>
              <EditOutlinedIcon className={classes.editIcon} />
            </ActionButton>

            <ActionButton
              title="Delete"
              onClick={() => handleDeleteModal(true, item.id)}
            >
              <DeleteForeverOutlinedIcon className={classes.deleteIcon} />
            </ActionButton>
          </AccessPermission>
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <>
      <Paper className={classes.root}>
        <Typography variant="h6">Student House List</Typography>

        <TableContainer
          loading={loading}
          items={tableData}
          headCells={headCells}
          getCustomTableRow={getCustomTableRow}
          search_param="name"
        />
      </Paper>

      {/* Delete modal dialog */}
      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
      />
    </>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default StudentHouseTable;
