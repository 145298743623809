import {
  GET_SMS_TEMPLATE_LOADING,
  GET_SMS_TEMPLATE_ERROR,
  GET_SMS_TEMPLATE_SUCCESS,
  SmsTemplateDispatchTypes,
  ADD_SMS_TEMPLATE_LOADING,
  ADD_SMS_TEMPLATE_ERROR,
  ADD_SMS_TEMPLATE_SUCCESS,
  DELETE_SMS_TEMPLATE_LOADING,
  DELETE_SMS_TEMPLATE_ERROR,
  DELETE_SMS_TEMPLATE_SUCCESS,
  UPDATE_SMS_TEMPLATE_LOADING,
  UPDATE_SMS_TEMPLATE_ERROR,
  UPDATE_SMS_TEMPLATE_SUCCESS,
  SmsTemplateTypeI,
} from "../../actions/Communication/SmsTemplate/SmsTemplateActionTypes";

interface InitialStateI {
  loading: boolean;
  sms_templates: SmsTemplateTypeI[];
}

const initialState: InitialStateI = {
  loading: false,
  sms_templates: [],
};

const SmsTemplateReducer = (
  state: InitialStateI = initialState,
  action: SmsTemplateDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_SMS_TEMPLATE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_SMS_TEMPLATE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_SMS_TEMPLATE_SUCCESS:
      return {
        loading: false,
        sms_templates: action.payload,
      };

    case ADD_SMS_TEMPLATE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case ADD_SMS_TEMPLATE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case ADD_SMS_TEMPLATE_SUCCESS:
      return {
        loading: false,
        sms_templates: [...state.sms_templates, action.payload],
      };

    case UPDATE_SMS_TEMPLATE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_SMS_TEMPLATE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case UPDATE_SMS_TEMPLATE_SUCCESS:
      const current_data: SmsTemplateTypeI[] = state.sms_templates;
      const index: number = current_data.findIndex(
        (element) => element.id === action.payload.id
      );
      current_data[index] = action.payload;

      return {
        loading: false,
        sms_templates: current_data,
      };

    case DELETE_SMS_TEMPLATE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_SMS_TEMPLATE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_SMS_TEMPLATE_SUCCESS:
      const new_data: SmsTemplateTypeI[] = state.sms_templates;
      const item_index = new_data.findIndex(
        (element) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return {
        loading: false,
        sms_templates: new_data,
      };

    default:
      return state;
  }
};

export default SmsTemplateReducer;
