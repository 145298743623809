export const GET_GRADE_LOADING = "GET_GRADE_LOADING";
export const GET_GRADE_ERROR = "GET_GRADE_ERROR";
export const GET_GRADE_SUCCESS = "GET_GRADE_SUCCESS";

export const ADD_GRADE_LOADING = "ADD_GRADE_LOADING";
export const ADD_GRADE_ERROR = "ADD_GRADE_ERROR";
export const ADD_GRADE_SUCCESS = "ADD_GRADE_SUCCESS";

export const UPDATE_GRADE_LOADING = "UPDATE_GRADE_LOADING";
export const UPDATE_GRADE_ERROR = "UPDATE_GRADE_ERROR";
export const UPDATE_GRADE_SUCCESS = "UPDATE_GRADE_SUCCESS";

export const DELETE_GRADE_LOADING = "DELETE_GRADE_LOADING";
export const DELETE_GRADE_ERROR = "DELETE_GRADE_ERROR";
export const DELETE_GRADE_SUCCESS = "DELETE_GRADE_SUCCESS";

export type GradeTypeI = {
  id: string;
  name: number;
  grade_name: string;
  created_by: string;
  general_info_name: string;
  count?: number;
};

//===============================<START>GET Grade<START>======================//

export interface GetGradeLoading {
  type: typeof GET_GRADE_LOADING;
}

export interface GetGradeError {
  type: typeof GET_GRADE_ERROR;
}

export interface GetGradeSuccess {
  type: typeof GET_GRADE_SUCCESS;
  payload: GradeTypeI[];
}

//===============================<END>GET Grade<END>======================//

//===============================<START>ADD Grade<START>======================//

export interface AddGradeLoading {
  type: typeof ADD_GRADE_LOADING;
}

export interface AddGradeError {
  type: typeof ADD_GRADE_ERROR;
  payload: any;
}

export interface AddGradeSuccess {
  type: typeof ADD_GRADE_SUCCESS;
  payload: GradeTypeI;
}

//===============================<END>ADD Grade<END>======================//

//===============================<START>UPDATE Grade<START>======================//

export interface UpdateGradeLoading {
  type: typeof UPDATE_GRADE_LOADING;
}

export interface UpdateGradeError {
  type: typeof UPDATE_GRADE_ERROR;
  payload: any;
}

export interface UpdateGradeSuccess {
  type: typeof UPDATE_GRADE_SUCCESS;
  payload: GradeTypeI;
}

//===============================<END>UPDATE Grade<END>======================//

//===============================<START>DELETE Grade<START>======================//

export interface DeleteGradeLoading {
  type: typeof DELETE_GRADE_LOADING;
}

export interface DeleteGradeError {
  type: typeof DELETE_GRADE_ERROR;
}

export interface DeleteGradeSuccess {
  type: typeof DELETE_GRADE_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE Grade<END>======================//

export type GradeDispatchTypes =
  | GetGradeLoading
  | GetGradeError
  | GetGradeSuccess
  | AddGradeLoading
  | AddGradeError
  | AddGradeSuccess
  | UpdateGradeLoading
  | UpdateGradeError
  | UpdateGradeSuccess
  | DeleteGradeLoading
  | DeleteGradeError
  | DeleteGradeSuccess;
