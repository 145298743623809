import Marksheet1 from "../../../../assets/images/marksheet-templates/marksheet1.png";

export const CharacterCertificateSampleData = [
  {
    id: 1,
    code: "ABC-001",
    thumbnail: Marksheet1,
    // watermark: true,
    // watermark_position: "top",
    school_logo: false,
    school_logo_position: "L",
    // student_photo: false,
    // student_photo_position: "top_right",
    footer_text_1: "Class Teacher",
    footer_text_2: "Vice Principal",
    footer_text_3: "School Principal",
  },
];

export function getSingleCharacterCertificate(id: string) {
  return CharacterCertificateSampleData.find((item) => item.id == parseInt(id));
}
