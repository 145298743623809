// ---------------- <START> module import ------------------//
import React, { useState, useEffect } from "react";
import { Dialog, Paper, Typography } from "@material-ui/core";
import { useTable, useTableWithPagination } from "../../Reusable";
import { useTableStyles } from "../../Styles/TableStyles";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import {
  GetDepartments,
  DeleteDepartment,
} from "../../../actions/HumanResource/Department/DepartmentAction";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import { DepartmentTypeI } from "../../../actions/HumanResource/Department/DepartmentActionTypes";
import { HeadCellsI } from "../../../actions";
import {
  ItemDeleteButton,
  ItemEditButton,
  ItemPrintButton,
  ItemViewButton,
} from "../../Reusable/Buttons/TableButton";
import useLoading from "../../../useHook/useLoading";
import {
  GetDaybook,
  GetInvoiceDetail,
} from "../../../actions/Fees/Daybook/DaybookAction";
import getFullName from "../../utils/getFullName";
import getFullClassName from "../../utils/getFullClassName";
import FeeInvoice from "../CollectFees/FeeInvoice";
import { GetGeneralInfoData } from "../../../actions/GeneralSettings/GeneralInfo/GeneralInfoAction";
import { GetDueReports } from "../../../actions/Fees/DueReports/DueReportsAction";
import { dateConverterBsToAd } from "../../utils/dateConverter";
// ---------------- <END> module import ------------------//

// ---------------- <START> Interface ------------------//

// ---------------- <END> Interface ------------------//

// ---------------- <START> Head Cells ------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N." },
  { id: "student_name", label: "Student Name" },
  { id: "class", label: "Class" },
  { id: "total_amount", label: "Total Amount" },
  { id: "due_amount", label: "Due Amount" },
];
// ---------------- <START> Head Cells ------------------//

// ---------------- <START> Department Table Component ------------------//
const DueReportTable = (props: any) => {
  const classes = useTableStyles();
  const dispatch = useDispatch();

  const { grade, section, startDate, endDate } = props;

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTableWithPagination(headCells);

  const [tableData, setTableData] = useState<DepartmentTypeI[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);
  const departmentState = useSelector((state: RootStore) => state.department);
  const dueReportState = useSelector((state: RootStore) => state.due_report);
  const [openInvoice, setOpenInvoice] = useState(false);

  const generalInfoData = useSelector(
    (state: RootStore) => state.general_info?.general_info
  );
  const authData = useSelector((state: RootStore) => state.auth);

  useEffect(() => {
    dispatch(GetDueReports(1, "", "", "", ""));
  }, []);

  useEffect(() => {
    if (itemId !== null) {
      dispatch(GetInvoiceDetail(itemId));
    }
  }, [itemId]);

  // useEffect(() => {
  //     if (authData.general_info != null) {
  //         dispatch(GetGeneralInfoData(authData.general_info.id));
  //     }
  // }, [authData]);

  useEffect(() => {
    if (dueReportState?.due_reports !== []) {
      setTableData(dueReportState?.due_reports?.results);
    }
  }, [dueReportState]);

  const handleCloseInvoice = () => {
    setOpenInvoice(false);
  };

  const handleViewModal = (id: string) => {
    setItemId(id);
    setOpenInvoice(true);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteDepartment(itemId));
    setDeleteModalOpen(false);
  };

  const handlePaginationChange = (paginationIndex: number) => {
    dispatch(
      GetDueReports(
        paginationIndex,
        grade?.grade || "",
        section?.id || "",
        (startDate && dateConverterBsToAd(startDate)) || "",
        (endDate && dateConverterBsToAd(endDate)) || ""
      )
    );
  };

  const getCustomTableRow = (item: any, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {getFullName(
            item.student?.student_user?.first_name,
            item.student?.student_user?.last_name
          )}
        </StyledTableCell>{" "}
        <StyledTableCell align="center" className={classes.cell}>
          {getFullClassName(item.grade?.name, item.section?.name)}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          Rs. {item.total_amount || 0}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          Rs. {item.total_due || 0}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  console.log({ tableData });

  return (
    <div style={{ padding: "15px" }}>
      <Typography variant="h6">Due List</Typography>
      <TableContainer
        label="Invoice"
        loading={departmentState.loading}
        items={tableData}
        headCells={headCells}
        getCustomTableRow={getCustomTableRow}
        forPagination={dueReportState?.due_reports}
        handlePaginationChange={handlePaginationChange}
        disableSearch
      />
      <Dialog
        open={openInvoice}
        onClose={handleCloseInvoice}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
        // className={classes.modalBox}
      >
        <FeeInvoice
          generalInfoData={generalInfoData}
          student=""
          invoiceID={itemId}
        />
      </Dialog>
    </div>
  );
};
// ---------------- <START> Department Table Component ------------------//

export default DueReportTable;
