import React, { useState } from "react";
import { ReportSideBar } from "../../components";
import CharacterCertificateList from "../../components/Reports/CharacterCertificate/ CharacterCertificateList";
import CharacterCertificateSearch from "../../components/Reports/CharacterCertificate/ CharacterCertificateSearch";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const CharacterCertificatePage = () => {
  const nav = <ReportSideBar character_certificate />;
  const [grade, setGrade] = useState<any>();

  const passGradeInfo = (value: any) => {
    setGrade(value);
  };
  return (
    <SubModuleLayout reportMenuActive sideNav={nav}>
      <CharacterCertificateSearch passGradeInfo={passGradeInfo} />
      <CharacterCertificateList gradeInfo={grade} />
    </SubModuleLayout>
  );
};
export default CharacterCertificatePage;
