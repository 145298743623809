import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { StudentComplaintForm, StudentComplaintTable } from '../../components';
import SubModuleLayout from '../../components/Reusable/Layouts/SubModuleLayout';
import { StudentComplaintTypeI } from '../../actions/Student/StudentComplaint/StudentComplaintActionTypes';
import StudentSidebar from '../../components/Student/StudentSidebar/StudentSidebar';
import { breadcrumb } from './Student.Breadcrumbs';
import AccessPermission from '../../components/Student/StudentDetail/AccessPermission';

const StudentComplaintPage = () => {
  const [formData, setFormData] = useState<StudentComplaintTypeI | null>(null);
  const [count, setCount] = useState<number>(0);
  const [editValue, setEditValue] = useState<any>();
  // const [teacher, setTeacher] = useState(false);

  const fetchEditData = (data: StudentComplaintTypeI) => {
    setFormData({ ...data, count });
    setCount(count + 1);
  };

  const onEditMode = (value: boolean) => {
    setEditValue({ value, count });
  };

  const nav = <StudentSidebar studentComplaint />;
  return (
    <SubModuleLayout module={breadcrumb} sideNav={nav}>
      <Grid container>
        <AccessPermission>
          <Grid item xs={3}>
            <StudentComplaintForm editData={formData} onEditMode={onEditMode} />
          </Grid>
        </AccessPermission>
        <Grid item xs={9}>
          <StudentComplaintTable
            onEditData={fetchEditData}
            editValue={editValue}
          />
        </Grid>
      </Grid>
    </SubModuleLayout>
  );
};
export default StudentComplaintPage;
