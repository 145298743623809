import {
  Button,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Tooltip,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Autocomplete } from "@material-ui/lab";
import { useFormStyles } from "../../Styles/FormStyles";
import AddNewButton from "../../Reusable/Buttons/AddNewButton";
import { useDispatch, useSelector } from "react-redux";
import {
  GetPaginatedStudent,
  GetStudentClass,
} from "../../../actions/Student/Student/StudentAction";
import { RootStore } from "../../../store";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import { Add, Search } from "@material-ui/icons";
import { GetDepartments } from "../../../actions/HumanResource/Department/DepartmentAction";
import { GetDesignations } from "../../../actions/HumanResource/Designation/DesignationAction";
import { DepartmentTypeI } from "../../../actions/HumanResource/Department/DepartmentActionTypes";
import { DesignationTypeI } from "../../../actions/HumanResource/Designation/DesignationActionTypes";

// -----------------<start> Styling <start>-----------------------//

// -----------------<end> Styling <end>-----------------------//

// -----------------<start> Interfaces <start>-----------------------//

interface propsI {
  onAddStaff: () => void;
  searchStaffParams: (data: {
    department_id: string;
    designation_id: string;
    search: string;
  }) => void;
}

// -----------------<end> Interfaces <end>-----------------------//

// -----------------<start> States <start>-----------------------//

// -----------------<end> States <end>-----------------------//

const SearchStaff = (props: propsI) => {
  const { searchStaffParams } = props;
  const classes = useFormStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [searchName, setSearchName] = useState<string>("");

  const [selectedDepartment, setSelectedDepartment] =
    useState<DepartmentTypeI | null>(null);

  const [selectedDesignation, setSelectedDesignation] =
    useState<DesignationTypeI | null>(null);

  const { departments, loading } = useSelector(
    (state: RootStore) => state.department
  );
  const { designations } = useSelector((state: RootStore) => state.designation);

  useEffect(() => {
    dispatch(GetDepartments());
    dispatch(GetDesignations());
  }, []);

  const handleReset = () => {
    setSelectedDepartment(null);
    setSelectedDesignation(null);
    setSearchName("");

    searchStaffParams({
      department_id: "",
      designation_id: "",
      search: "",
    });
  };

  const handleSearchByName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchName(e.target.value);
  };

  const handleSearchClick = () => {
    searchStaffParams({
      department_id: selectedDepartment?.id || "",
      designation_id: selectedDesignation?.id || "",
      search: searchName,
    });
  };

  return (
    <>
      <form className={classes.form}>
        <Grid container>
          <Grid item xs={9} className={classes.formTitle} />
          <Grid container item xs={3}>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Tooltip title="Clear Search Query">
                  <IconButton
                    aria-label="delete"
                    style={{ padding: "2px" }}
                    onClick={handleReset}
                  >
                    <SettingsBackupRestoreIcon />
                  </IconButton>
                </Tooltip>
              </Grid>

              <Grid item>
                <AddNewButton
                  title="Add New Staff"
                  type="button"
                  color="primary"
                  variant="outlined"
                  startIcon={<Add />}
                  onClick={() => props.onAddStaff()}
                >
                  Add New Staff
                </AddNewButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={3} className={classes.formWrapper}>
            <InputLabel>Search Staffs By Name</InputLabel>
            <TextField
              variant="outlined"
              placeholder="Search By Name"
              value={searchName}
              onChange={handleSearchByName}
            />
          </Grid>

          <Grid item xs={3} className={classes.formWrapper}>
            <InputLabel>Designation</InputLabel>
            <Autocomplete
              onChange={(event: React.ChangeEvent<{}>, value) =>
                setSelectedDesignation(value)
              }
              options={designations}
              value={selectedDesignation}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search by designation"
                  variant="outlined"
                />
              )}
            />
          </Grid>

          <Grid item xs={3} className={classes.formWrapper}>
            <InputLabel>Department</InputLabel>
            <Autocomplete
              value={selectedDepartment}
              onChange={(event: React.ChangeEvent<{}>, value) =>
                setSelectedDepartment(value)
              }
              options={departments}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search by department"
                  variant="outlined"
                />
              )}
            />
          </Grid>

          <Grid item xs={3}>
            <Button
              className={classes.searchBoxButton}
              color="primary"
              variant="contained"
              startIcon={<Search />}
              onClick={handleSearchClick}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </form>
      <Divider style={{ marginBottom: "10px" }} />
    </>
  );
};

export default SearchStaff;
