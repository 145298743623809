import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../store";
import { setSnackbar } from "../../actions/SnackbarAction";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    "& .MuiAlert-filledError": {
      backgroundColor: "#ff9800"
    }
  }
}))

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const CustomAlert = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const snackbarOpen = useSelector(
    (state: RootStore) => state.snackbar.snackbarOpen
  );
  const snackbarType = useSelector(
    (state: RootStore) => state.snackbar.snackbarType
  );
  const snackbarMessage = useSelector(
    (state: RootStore) => state.snackbar.snackbarMessage
  );

  const handleAlertClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(setSnackbar(false, snackbarType, snackbarMessage));
  };

  return (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      onClose={handleAlertClose}
      className={classes.root}
    >
      <Alert onClose={handleAlertClose} severity={snackbarType}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
};
