import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Tooltip,
  InputLabel,
  LinearProgress,
  Paper,
  TextField,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import { Autocomplete } from '@material-ui/lab';
import { useFormStyles } from '../../Styles/FormStyles';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import { GetExams } from '../../../actions/Examination/Exam/ExamAction';
import { ExamTypeI } from '../../../actions/Examination/Exam/ExamActionTypes';
import { GetClasses } from '../../../actions/Academics/Class/ClassAction';
import { ClassTypeI } from '../../../actions/Academics/Class/ClassActionTypes';
import { ResultTypeI } from '../../../actions/Examination/Result/ResultActionTypes';
import { SectionTypeI } from '../../../actions/Academics/Section/SectionActionTypes';
import SearchIcon from '@material-ui/icons/Search';
import { GetResult } from '../../../actions/Examination/Result/ResultAction';

// -----------------<start> Styling <start>-----------------------//

// -----------------<end> Styling <end>-----------------------//

// -----------------<start> Interfaces <start>-----------------------//
interface PropsI {
  onSearch: (value: SearchTitleI) => void;
}

export interface SearchTitleI {
  exam_info: string;
  class: string;
  section: string;
}

interface FormI {
  academic_year: string | null;
  class: string | null;
  section: string | null;
}

// -----------------<end> Interfaces <end>-----------------------//

// -----------------<start> States <start>-----------------------//

// -----------------<end> States <end>-----------------------//

const ResultSMS = (props: PropsI) => {
  const { onSearch } = props;
  const classes = useFormStyles();
  const { register, handleSubmit, errors, setValue } = useForm<FormI>();

  //======================== > STATES < ===========================================//

  const [examType, setExamType] = useState<ExamTypeI | null>(null);
  const [examTypeChoices, setExamTypeChoices] = useState<ExamTypeI[]>([]);
  const [selectedClass, setSelectedClass] = useState<ClassTypeI | null>(null);
  const [classChoices, setClassChoices] = useState<ClassTypeI[]>([]);
  const [selectedSection, setSelectedSection] = useState<SectionTypeI | null>(
    null
  );
  const [sectionChoices, setSectionChoices] = useState<SectionTypeI[] | []>([]);
  const [sectionDisabler, setSectionDisabler] = useState<boolean>(true);

  const [loading, setLoading] = useState<boolean>(false);

  //======================================== <START> REACT HOOKS <START> ============================//

  const dispatch = useDispatch();
  const classSelector = useSelector((state: RootStore) => state.class);
  const examSelector = useSelector((state: RootStore) => state.exam);

  const classLoading = useSelector((state: RootStore) => state.class.loading);
  const examLoading = useSelector((state: RootStore) => state.exam.loading);

  const resultSelector = useSelector((state: RootStore) => state.result);

  useEffect(() => {
    if (!classLoading && !examLoading) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [classLoading, examLoading]);

  useEffect(() => {
    dispatch(GetClasses());
    dispatch(GetExams());
  }, []);

  useEffect(() => {
    setClassChoices(classSelector.classes);
  }, [classSelector]);

  useEffect(() => {
    setExamTypeChoices(examSelector.exams);
  }, [examSelector]);

  //======================================== <END> REACT HOOKS <END> ============================//

  //======================================== <START> EVENT HANDLERS <START> ============================//

  const handleClassChange = (value: ClassTypeI | null) => {
    setSelectedClass(value);
    setSelectedSection(null);
    value != null
      ? populateSectionChoices(value.section)
      : setSectionDisabler(true);
  };

  const populateSectionChoices = (sections: SectionTypeI[] | []) => {
    setSectionDisabler(false);
    sections.length > 0
      ? setSectionChoices(sections)
      : setSectionDisabler(true);
  };

  const onSubmit = () => {
    if (examType && selectedClass) {
      dispatch(
        GetResult(
          examType.id,
          selectedClass.grade,
          selectedSection ? selectedSection.id : ''
        )
      );
      onSearch({
        exam_info: examType.id,
        class: selectedClass.grade_name,
        section: selectedSection ? selectedSection.name : '',
      });
    }
  };

  const handleReset = () => {
    setExamType(null);
    setSelectedSection(null);
    setSelectedClass(null);
    console.log('Before', resultSelector);
    resultSelector.result = [];
    console.log('After', resultSelector);
    onSearch({
      exam_info: '',
      class: '',
      section: '',
    });
  };

  //======================================== <END> EVENT HANDLERS <END> ============================//
  return (
    <>
      <Paper className={classes.pageContentBox}>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={9} className={classes.formTitle}>
              <span>Result SMS</span>
            </Grid>
            <Grid container item xs={3}>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                  <Tooltip title="Clear Search Query">
                    <IconButton
                      aria-label="delete"
                      style={{ padding: '2px' }}
                      onClick={handleReset}
                    >
                      <SettingsBackupRestoreIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={3} className={classes.formWrapper}>
              <InputLabel>Exam Type</InputLabel>
              <Autocomplete
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: ExamTypeI | null
                ) => setExamType(value)}
                options={examTypeChoices}
                getOptionLabel={(option) => option.exam_name}
                value={examType}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Label"
                    name="academic_year"
                    inputRef={register({ required: true })}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={3} className={classes.formWrapper}>
              <InputLabel>Select Class</InputLabel>
              <Autocomplete
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: ClassTypeI | null
                ) => handleClassChange(value)}
                options={classChoices}
                getOptionLabel={(option) => option.grade_name}
                value={selectedClass}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Label"
                    name="class_name"
                    inputRef={register({ required: true })}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={3} className={classes.formWrapper}>
              <InputLabel>Select Section</InputLabel>
              <Autocomplete
                value={selectedSection}
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: SectionTypeI | null
                ) => setSelectedSection(value)}
                options={sectionChoices}
                getOptionLabel={(option) => option.name}
                disabled={sectionDisabler}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Label"
                    name="session_name"
                    inputRef={register}
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            <Grid item xs={3} className={classes.formWrapper}>
              <Button
                className={classes.searchBoxButton}
                type="submit"
                color="primary"
                variant="contained"
                startIcon={<SearchIcon />}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
      {loading ? (
        <LinearProgress style={{ margin: '-10px 15px 0 15px' }} />
      ) : null}
    </>
  );
};

export default ResultSMS;
