import { Divider, Grid, InputLabel, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { useFormStyles } from "../../Styles/FormStyles";
import { useDispatch, useSelector } from "react-redux";
import { GetClasses } from "../../../actions/Academics/Class/ClassAction";
import { RootStore } from "../../../store";
import { ClassTypeI } from "../../../actions/Academics/Class/ClassActionTypes";
import { SectionTypeI } from "../../../actions/Academics/Section/SectionActionTypes";
import { getRollNum } from "../../../rtk/features/student/rollNumApi";
import { emptyRollNumTableAction } from "../../../rtk/features/student/studentRollNumSlices";

const SearchStudentsForm = () => {
  const classes = useFormStyles();
  const dispatch = useDispatch();
  const [sectionChoices, setSectionChoices] = useState<SectionTypeI[] | []>([]);
  const [sectionDisabler, setSectionDisabler] = useState<boolean>(true);

  const [selectedClass, setSelectedClass] = useState<ClassTypeI | null>(null);
  const [selectedSection, setSelectedSection] = useState<SectionTypeI | null>(
    null
  );

  const allClasses = useSelector((state: RootStore) => state.class.classes);
  const { actionPerformed } = useSelector((state: RootStore) => state.rollNum);

  useEffect(() => {
    dispatch(GetClasses());
    dispatch(emptyRollNumTableAction());
  }, []);

  useEffect(() => {
    if (actionPerformed) {
      if (selectedClass) {
        dispatch(
          getRollNum({
            grade: selectedClass.grade,
            section: selectedSection?.id || "",
          })
        );
      }
    }
  }, [actionPerformed]);

  const handleClassChange = (value: ClassTypeI | null) => {
    setSelectedClass(value);
    setSelectedSection(null);

    if (value) {
      if (value.section.length) {
        setSectionChoices(value.section);
        setSectionDisabler(false);
        dispatch(emptyRollNumTableAction());
      } else {
        setSectionChoices([]);
        setSectionDisabler(true);
        dispatch(
          getRollNum({
            grade: value.grade,
            section: "",
          })
        );
      }
    } else {
      setSectionChoices([]);
      setSectionDisabler(true);
      dispatch(emptyRollNumTableAction());
    }
  };

  const handleSectionChange = (value: SectionTypeI | null) => {
    setSelectedSection(value);

    if (value) {
      dispatch(
        getRollNum({
          grade: selectedClass?.grade || "",
          section: value?.id || "",
        })
      );
    } else {
      dispatch(emptyRollNumTableAction());
    }
  };

  return (
    <>
      <form className={classes.form}>
        <Grid container>
          <Grid item xs={9} className={classes.formTitle}>
            <span>Search Students</span>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={2} className={classes.formWrapper}>
            <InputLabel>
              Select Grade<span style={{ color: "red" }}>*</span>
            </InputLabel>
            <Autocomplete
              onChange={(event: React.ChangeEvent<{}>, value) =>
                handleClassChange(value)
              }
              options={allClasses}
              value={selectedClass}
              getOptionLabel={(option) => option.grade_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Label"
                  name="class_name"
                  variant="outlined"
                />
              )}
            />
          </Grid>

          <Grid item xs={2} className={classes.formWrapper}>
            <InputLabel>Select Section </InputLabel>
            <Autocomplete
              style={{
                padding: 0,
              }}
              value={selectedSection}
              onChange={(event: React.ChangeEvent<{}>, value) =>
                handleSectionChange(value)
              }
              options={sectionChoices}
              getOptionLabel={(option) => option.name}
              disabled={sectionDisabler}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Label"
                  name="section_name"
                  variant="outlined"
                />
              )}
            />
          </Grid>
        </Grid>
      </form>
      <Divider style={{ marginBottom: "10px" }} />
    </>
  );
};

export default SearchStudentsForm;
