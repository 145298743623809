// ---------------------------- <START> module imports start ----------------------------------//
import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Paper,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTableStyles } from '../../Styles/TableStyles';
import { useTable } from '../../Reusable';
import SearchDisabledStudent from './SearchDisabledStudent';
import DisableDialog from '../../Reusable/Dialogs/DisableDialog';
import CustomizedPopUp from '../../Reusable/Dialogs/CustomizedPopUp';
import getFullName from '../../utils/getFullName';
import { RootStore } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import {
  GetDisabledStudentList,
  EnableStudent,
} from '../../../actions/Student/Student/StudentAction';

import {
  ItemEditButton,
  ItemEnableButton,
  ItemViewButton,
  ItemDeleteButton,
} from '../../Reusable/Buttons/TableButton';
import UserAvatar from '../../Reusable/Profile/UserAvatar';
import defaultPlaceholder from '../../../assets/images/defaultPlaceholder';
import checkImageUrl from '../../utils/checkImageUrl';
import getClassName from '../../utils/getClassName';
import { useStyles } from '../../Styles/FormStyles';
import DisabledStudentModal from './DisabledStudentModal';
import AccessPermission from '../StudentDetail/AccessPermission';

// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//
interface TableDataI {
  key: number;
  id: string;
  student_photo: string;
  student_name: string;
  adm_no: string;
  class: string;
  roll_no: string;
  phone_no?: string;
  guardian: string;
  guardian_no: string;
  occupation?: string;
}

interface HeadCellsI {
  id: string;
  label: string;
}
// ---------------------------- <END> interface ends ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: 'sn', label: 'S.N' },
  { id: 'student_name', label: 'Student Name' },
  { id: 'adm_no', label: 'Adm No.' },
  { id: 'class', label: 'Class' },
  { id: 'roll_no', label: 'Roll No' },
  { id: 'phone_no', label: 'Phone No.' },
  { id: 'guardian', label: 'Guardian' },
  { id: 'guardian_no', label: "Guardian's Phone" },
  { id: 'action', label: 'Action' },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const DisabledStudentList: React.FC = () => {
  const classes = useTableStyles();
  const styles = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  // States declaration
  const [tableData, setTableData] = useState<TableDataI[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [remarks, setRemarks] = useState<string | null>(null);

  // Retrieving re-usable components from useTable
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  // Modal States
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);
  // const [viewModalOpen, setViewModalOpen] = useState<boolean>(false);

  const disabledListLoading = useSelector(
    (state: RootStore) => state.student.loading
  );
  const disabledStudentList = useSelector((state: RootStore) => state.student);

  // useEffect
  useEffect(() => {
    dispatch(GetDisabledStudentList());
  }, []);

  useEffect(() => {
    setLoading(disabledListLoading);
  }, [disabledListLoading]);

  useEffect(() => {
    const data: any = [];
    if (disabledStudentList.disabled_student_list != null) {
      disabledStudentList.disabled_student_list.map(
        (elem: any, index: number) => {
          data.push({
            key: index + 1,
            id: elem.id,
            student_photo: elem?.student_photo
              ? checkImageUrl(elem?.student_photo)
              : defaultPlaceholder,
            student_name: getFullName(
              elem.student_first_name,
              elem.student_last_name
            ),
            adm_no: elem.admission_number,
            // class: elem.academic_info && elem.academic_info[0]?.grade,
            // roll_no: elem.academic_info && elem.academic_info[0]?.roll_number,
            class: getClassName(elem.grade, elem.section),
            roll_no: elem.roll_number,
            phone_no: elem.phone_number || '-',
            guardian: getFullName(
              elem.guardian_first_name,
              elem.guardian_last_name
            ),
            guardian_no: elem.guardian_phone_number || '-',
          });
        }
      );
    }
    setTableData(data);
  }, [disabledStudentList]);

  // // View Action
  // const handleViewModal = (value: boolean, id: string) => {
  //   setItemId(id);
  //   setViewModalOpen(value);
  // }

  // const handleViewModalClose = () => {
  //   setViewModalOpen(false);
  // }

  const onRedirect = (id: string) => {
    history.push(`/student/profile/${id}`);
  };

  // Edit Action

  // Enable Action
  const handleDeleteModal = (value: boolean, id: string) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    dispatch(EnableStudent(itemId, { remarks }));
    setRemarks(null);
    setDeleteModalOpen(false);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const getCustomTableRow = (item: TableDataI, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <UserAvatar name={item.student_name} src={item.student_photo} />
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.adm_no}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.class}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.roll_no || '-'}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.phone_no}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.guardian}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.guardian_no}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <AccessPermission>
            <ItemViewButton onClick={() => onRedirect(item.id)} />
            {/* <ItemEditButton /> */}
            <ItemEnableButton
              onClick={() => handleDeleteModal(true, item.id)}
            />
            {/* <ItemDeleteButton onClick={() => handleDeleteStudentModal(true, item.id)} /> */}
          </AccessPermission>
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <>
      <SearchDisabledStudent />
      <Paper className={classes.rootTableBox}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">Disabled Student List</Typography>
          </Grid>
        </Grid>

        <TableContainer
          label="Disabled Student list"
          loading={loading}
          items={tableData}
          headCells={headCells}
          getCustomTableRow={getCustomTableRow}
          search_param="student_name"
        />

        <DisableDialog
          open={deleteModalOpen}
          onClose={handleModalClose}
          deleteConfirmed={onDeleteConfirmed}
          dialogTitle="Are you sure to enable this record?"
          okText="Enable"
        >
          <Grid container justifyContent="center">
            <Grid
              className={styles.formWrapper}
              style={{ marginBottom: '2rem' }}
            >
              <InputLabel>Remarks</InputLabel>
              <TextField
                variant="outlined"
                multiline
                rows={4}
                name="remarks"
                placeholder="Remarks"
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
              />
            </Grid>
          </Grid>
        </DisableDialog>

        {/* <CustomizedPopUp
          title="Disabled Student"
          open={viewModalOpen}
          onClose={handleViewModalClose}
          actionConfirmed={onRedirect}
          dialogTitle="Disabled Student Info"
          okText="View Full Profile"
        >
          <DisabledStudentModal id={itemId} />
        </CustomizedPopUp > */}
      </Paper>
    </>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default DisabledStudentList;
