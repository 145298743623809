export const GET_DUE_REPORTS_LOADING = "GET_DUE_REPORTS_LOADING";
export const GET_DUE_REPORTS_ERROR = "GET_DUE_REPORTS_ERROR";
export const GET_DUE_REPORTS_SUCCESS = "GET_DUE_REPORTS_SUCCESS";

export const GET_DUE_ALERT_LOADING = "GET_DUE_ALERT_LOADING";
export const GET_DUE_ALERT_ERROR = "GET_DUE_ALERT_ERROR";
export const GET_DUE_ALERT_SUCCESS = "GET_DUE_ALERT_SUCCESS";

export const SEND_DUE_ALERT_LOADING = "SEND_DUE_ALERT_LOADING";
export const SEND_DUE_ALERT_ERROR = "SEND_DUE_ALERT_ERROR";
export const SEND_DUE_ALERT_SUCCESS = "SEND_DUE_ALERT_SUCCESS";

export interface GetDueReportsLoading {
    type: typeof GET_DUE_REPORTS_LOADING;
}

export interface GetDueReportsError {
    type: typeof GET_DUE_REPORTS_ERROR;
}

export interface GetDueReportsSuccess {
    type: typeof GET_DUE_REPORTS_SUCCESS;
    payload: any;
}

export interface GetDueAlertLoading {
    type: typeof GET_DUE_ALERT_LOADING;
}

export interface GetDueAlertError {
    type: typeof GET_DUE_ALERT_ERROR;
}

export interface GetDueAlertSuccess {
    type: typeof GET_DUE_ALERT_SUCCESS;
    payload: any;
}

export interface SendDueAlertLoading {
    type: typeof SEND_DUE_ALERT_LOADING;
}

export interface SendDueAlertError {
    type: typeof SEND_DUE_ALERT_ERROR;
}

export interface SendDueAlertSuccess {
    type: typeof SEND_DUE_ALERT_SUCCESS;
    payload: any;
}

export type DueReportsDispatchTypes =
    | GetDueReportsLoading
    | GetDueReportsError
    | GetDueReportsSuccess
    | GetDueAlertLoading
    | GetDueAlertError
    | GetDueAlertSuccess
    | SendDueAlertLoading
    | SendDueAlertError
    | SendDueAlertSuccess