import axios from "axios";
import { Dispatch } from "redux";
import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import {
  GET_PRE_TIME_TABLE_LOADING,
  GET_PRE_TIME_TABLE_ERROR,
  GET_PRE_TIME_TABLE_SUCCESS,
  ADD_PRE_TIME_TABLE_LOADING,
  ADD_PRE_TIME_TABLE_ERROR,
  ADD_PRE_TIME_TABLE_SUCCESS,
  UPDATE_PRE_TIME_TABLE_LOADING,
  UPDATE_PRE_TIME_TABLE_ERROR,
  UPDATE_PRE_TIME_TABLE_SUCCESS,
  DELETE_PRE_TIME_TABLE_LOADING,
  DELETE_PRE_TIME_TABLE_ERROR,
  DELETE_PRE_TIME_TABLE_SUCCESS,
  PreTimeTableDispatchTypes,
  GET_ALL_PRE_TIME_TABLE_LOADING,
  GET_ALL_PRE_TIME_TABLE_ERROR,
  GET_ALL_PRE_TIME_TABLE_SUCCESS,
  ADD_PERIOD,
  UPDATE_PERIOD,
  DELETE_PERIOD,
} from "./PreTimeTableActionTypes";

interface PostDataI {
  classes: string[];
  period: number | string;
  start_time: string;
  end_time: string;
}

export const AddPeriod =
  (data: { id: string; period: string; startTime: string; endTime: string }) =>
  (dispatch: Dispatch<PreTimeTableDispatchTypes>) => {
    dispatch({
      type: ADD_PERIOD,
      payload: data,
    });
  };

export const UpdatePeriod =
  (data: { id: string; field: string; value: string }) =>
  (dispatch: Dispatch<PreTimeTableDispatchTypes>) => {
    dispatch({
      type: UPDATE_PERIOD,
      payload: data,
    });
  };

export const DeletePeriod =
  (id: string) => (dispatch: Dispatch<PreTimeTableDispatchTypes>) => {
    dispatch({
      type: DELETE_PERIOD,
      payload: id,
    });
  };

export const GetPreTimeTables =
  (classes?: string) =>
  async (dispatch: Dispatch<PreTimeTableDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_PRE_TIME_TABLE_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/pre-academic-timetable/?classes=${classes || ""}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_PRE_TIME_TABLE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_PRE_TIME_TABLE_ERROR,
      });
    }
  };

export const GetAllPreTimeTables =
  () =>
  async (dispatch: Dispatch<PreTimeTableDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_ALL_PRE_TIME_TABLE_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/pre-academic-timetable/`,
        HeaderConfig()
      );

      dispatch({
        type: GET_ALL_PRE_TIME_TABLE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_PRE_TIME_TABLE_ERROR,
      });
    }
  };

export const AddPreTimeTable =
  (data: PostDataI) =>
  async (dispatch: Dispatch<PreTimeTableDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_PRE_TIME_TABLE_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/pre-academic-timetable/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_PRE_TIME_TABLE_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Period Added Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: ADD_PRE_TIME_TABLE_ERROR,
        payload: error.response && error.response.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Period Add Failed",
        },
      });
    }
  };

export const UpdatePreTimeTable =
  (id: string, data: PostDataI) =>
  async (dispatch: Dispatch<PreTimeTableDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_PRE_TIME_TABLE_LOADING,
      });

      const res = await axios.put(
        `${ADMIN_API_URL}/pre-academic-timetable/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_PRE_TIME_TABLE_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Period Updated Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: UPDATE_PRE_TIME_TABLE_ERROR,
        payload: error.response && error.response.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Period Updated Failed",
        },
      });
    }
  };

export const DeletePreTimeTable =
  (id: string, classID?: string) =>
  async (dispatch: Dispatch<PreTimeTableDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_PRE_TIME_TABLE_LOADING,
      });

      const res = await axios.delete(
        `${ADMIN_API_URL}/pre-academic-timetable/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_PRE_TIME_TABLE_SUCCESS,
        payload: { id: id },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Period Deleted Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_PRE_TIME_TABLE_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Period Delete Failed",
        },
      });
    } finally {
      try {
        dispatch({
          type: GET_PRE_TIME_TABLE_LOADING,
        });

        const res = await axios.get(
          `${ADMIN_API_URL}/pre-academic-timetable/?classes=${classID || ""}`,
          HeaderConfig()
        );

        dispatch({
          type: GET_PRE_TIME_TABLE_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: GET_PRE_TIME_TABLE_ERROR,
        });
      }
    }
  };
