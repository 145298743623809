import {
  TableCell,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Paper,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootStore } from "../../../store";
import { AttendenceColorBox } from "../../Reusable";
import { useTableStyles } from "../../Styles/TableStyles";

const MyAttendance = [];

const AttendanceReport = (props: any) => {
  const classes = useTableStyles();
  const [dateCells, setDateCells] = useState<any | []>([]);

  const monthDates = useSelector((state: RootStore) => state.month_dates);
  return (
    <>
      <Paper>
        {MyAttendance.length ? (
          <>
            <AttendenceColorBox
              monthName={props.monthName}
              childrenName={props.childrenName}
            />
            <div className={classes.tableWrapper}>
              <TableContainer>
                <Table
                  className={classes.table}
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" className={classes.cellDayName}>
                        <span>Student Name</span>
                      </TableCell>
                      {dateCells[0] &&
                        dateCells[0].attendance_detail.map((column: any) => (
                          <TableCell
                            key={column.id}
                            align="center"
                            className={classes.newcell}
                          >
                            {column.date}
                          </TableCell>
                        ))}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="center"
                        className={classes.cellDayName}
                      ></TableCell>
                      {dateCells[0] &&
                        dateCells[0].attendance_detail.map((column: any) => (
                          <TableCell
                            key={column.id}
                            align="center"
                            className={classes.cellDayName}
                          >
                            <span key={column.id}>{column.day}</span>
                          </TableCell>
                        ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dateCells.map((item: any, index: number) => {
                      return (
                        <TableRow key={index} hover tabIndex={-1}>
                          <TableCell
                            key={index}
                            align="center"
                            className={classes.newcell}
                            style={{ width: "200px" }}
                          >
                            {item.student_first_name}
                          </TableCell>
                          {item.attendance_detail.map(
                            (item: any, index: number) => {
                              return (
                                <TableCell
                                  key={index}
                                  align="center"
                                  className={classes.newcell}
                                  style={{
                                    backgroundColor:
                                      item.status == "P"
                                        ? "#51AB67"
                                        : item.status == "holiday"
                                        ? "#EA5E42"
                                        : item.status == "I"
                                        ? "#FEAF4A"
                                        : "#B9BCBE",
                                    width: "35px",
                                  }}
                                ></TableCell>
                              );
                            }
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </>
        ) : (
          "There is no data to show."
        )}
      </Paper>
    </>
  );
};

export default AttendanceReport;
