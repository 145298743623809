import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StudentPayloadI } from "./wholeData";
import { fetchStudentList } from "./wholeDataApi";

interface InititalStateI {
  loading: boolean;
  students: StudentPayloadI[];
}

const initialState: InititalStateI = {
  loading: false,
  students: [],
};

const wholeDataSlice = createSlice({
  name: "wholeData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchStudentList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchStudentList.fulfilled,
      (state, { payload }: PayloadAction<StudentPayloadI[]>) => {
        state.loading = false;
        state.students = payload;
      }
    );
    builder.addCase(fetchStudentList.rejected, (state) => {
      state.loading = false;
      state.students = [];
    });
  },
});

export default wholeDataSlice.reducer;
