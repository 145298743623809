import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Grid,
    InputLabel,
    Paper,
    TextField,
    LinearProgress,
    Switch,
    Backdrop,
    CircularProgress,
    Typography,
} from '@material-ui/core';
import FormControl from "@material-ui/core/FormControl";
import { useForm } from 'react-hook-form';
import { useStyles } from '../../Styles/FormStyles';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import ListView from './ListView';
import { FormCheckBox } from "../../Reusable/Inputs/Checkbox"
import { CusRadioGroup } from "../../Reusable/Inputs/Radio"
import AddNewButton from "../../Reusable/Buttons/AddNewButton"
import { getRubricSettings, postRubricSettings } from "../../../rtk/features/miscellaneous/rubricSettingsApi"
import { RootStore } from '../../../store';

interface InputFormI {
    add_on: string | null;
    add_by: string | null;
}

interface Tupple {
    id: string;
    name: string
}

const RADIO_OPTIONS_MODE: Tupple[] = [
    {
        id: 'Teachers',
        name: "Teachers"
    },
    {
        id: 'Class Teacher',
        name: "Class Teacher"
    }
]

const RADIO_OPTIONS_NOTIFICATION: Tupple[] = [
    {
        id: 'Weekly',
        name: "Weekly"
    },
    {
        id: 'Monthly',
        name: "Monthly"
    },
    {
        id: 'Quarterly',
        name: "Quarterly"
    }
]

const RubricForm = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { register, handleSubmit, errors, setError } = useForm<InputFormI>();
    const [onEditMode, setOnEditMode] = useState<boolean>(false);
    const [values, setValues] = useState<any[]>([]);
    const [formValues, setFormValues] = useState<InputFormI>({
        add_by: null,
        add_on: null
    })
    const [moduleStatus, setModuleStatus] = useState<boolean>(false);
    const [selectedModeRadioValue, setSelectedModeRadioValue] = useState<string>(RADIO_OPTIONS_MODE[0].id)
    const [selectedNotificationRadioValue, setSelectedNotificationRadioValue] = useState<string>(RADIO_OPTIONS_NOTIFICATION[0].id)
    const [disableCheckbox, setDisableCheckbox] = useState<boolean>(false)

    const { rubricSettings, updated } = useSelector((state: RootStore) => state.rubricSettings)

    useEffect(() => {
        dispatch(getRubricSettings())
    }, [])

    useEffect(() => {
        updated && dispatch(getRubricSettings())
    }, [updated])

    useEffect(() => {
        if (rubricSettings) {
            let value: any;
            if (rubricSettings.add_by === null && rubricSettings.add_on === null) {
                setDisableCheckbox(false)
                value = [
                    {
                        name: "Rubric Activated",
                        value: "No"
                    },
                ]
            } else {
                setDisableCheckbox(true)
                setModuleStatus(true);
                const add_on = RADIO_OPTIONS_NOTIFICATION.filter((item: Tupple) => item.id === rubricSettings.add_on)[0]?.id
                const add_by = RADIO_OPTIONS_MODE.filter((item: Tupple) => item.id === rubricSettings.add_by)[0]?.id
                setSelectedModeRadioValue(add_by)
                setSelectedNotificationRadioValue(add_on)
                value = [
                    {
                        name: "Rubric Activated",
                        value: "Yes"
                    },
                    {
                        name: "Who can grade rubric",
                        value: rubricSettings.add_by
                    },
                    {
                        name: "How often should notifications be sent",
                        value: rubricSettings.add_on
                    },
                ]
            }
            setFormValues({
                ...formValues,
                add_on: rubricSettings.add_on,
                add_by: rubricSettings.add_by
            })
            setValues(value)
        }
    }, [rubricSettings])

    const handleModuleStatus = () => {
        setModuleStatus(!moduleStatus)
    }
    const handleSelectModeValue = (value: string) => {
        setSelectedModeRadioValue(value)
    }
    const handleSelectNotificationValue = (value: string) => {
        setSelectedNotificationRadioValue(value)
    }
    const onSubmit = () => {
        const add_on = RADIO_OPTIONS_NOTIFICATION.filter((item: Tupple) => item.id === selectedNotificationRadioValue)[0].id
        const add_by = RADIO_OPTIONS_MODE.filter((item: Tupple) => item.id === selectedModeRadioValue)[0].id
        if (moduleStatus) {
            const submitData: InputFormI = {
                add_on,
                add_by
            }
            dispatch(postRubricSettings(submitData))
        }
        setOnEditMode(false)
    };

    return (
        <>
            {onEditMode && <Paper className={classes.pageContent}>
                <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container>
                        <Grid item xs={12} className={classes.formTitle}>
                            <span>Academic Settings</span>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={4} className={classes.formWrapper}>
                            <FormCheckBox
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>,
                                    checked: boolean
                                ) => handleModuleStatus()}
                                checked={moduleStatus}
                                name="module_status"
                                label="Rubric Module Activation"
                                size="medium"
                                disabled={disableCheckbox}
                            />
                        </Grid>
                        <Grid item xs={8} />
                        {moduleStatus &&
                            <>
                                <Grid item xs={4} className={classes.formWrapper}>
                                    <InputLabel>
                                        Who can add rubric grade?
                                    </InputLabel>
                                    <CusRadioGroup
                                        items={RADIO_OPTIONS_MODE}
                                        value={selectedModeRadioValue}
                                        setValue={handleSelectModeValue}
                                    />
                                </Grid>
                                <Grid item xs={4} className={classes.formWrapper}>
                                    <InputLabel>
                                        Send Notifications?
                                    </InputLabel>
                                    <CusRadioGroup
                                        items={RADIO_OPTIONS_NOTIFICATION}
                                        value={selectedNotificationRadioValue}
                                        setValue={handleSelectNotificationValue}
                                    />
                                </Grid>
                            </>
                        }
                    </Grid>
                    <Grid>
                        <Typography variant='caption'>Note: Once rubric is activated, it cannot be deactivated.</Typography>
                    </Grid>
                    <Grid
                        style={{ margin: '9px', gap: '1rem' }}
                        container
                        justifyContent="flex-end"
                    >
                        <AddNewButton
                            title="Cancel"
                            type="button"
                            color="primary"
                            variant="outlined"
                            startIcon={<CancelIcon />}
                            onClick={() => setOnEditMode(false)}
                        >
                            Cancel
                        </AddNewButton>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            startIcon={<SaveIcon />}
                        >
                            Update
                        </Button>
                    </Grid>
                </form>
            </Paper>}
            {!onEditMode && <ListView
                title="Academic Settings"
                setOnEditMode={setOnEditMode}
                data={values}
            />}
        </>
    )
}

export default RubricForm