//---------------------<start> module imports starts ----------------------//
import {
    Avatar,
    CardHeader,
    CircularProgress,
    Divider,
    Grid,
    InputLabel,
    Paper,
    TextField,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useFormStyles } from "../../Styles/FormStyles";
import { Autocomplete } from "@material-ui/lab";
import { Search } from "@material-ui/icons";
import { RootStore } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { GetClasses } from "../../../actions/Academics/Class/ClassAction";
import { GetSections } from "../../../actions/Academics/Section/SectionAction";
import { GetStudentByName, SearchStudent, StudentSearch } from "../../../actions/Student/Student/StudentAction";
import { useForm } from "react-hook-form";
import CustomizedTextField from "../../Reusable/Inputs/TextField";
import defaultBase64 from "../../Student/ParentForm/defaultBase64";
import { GetInvoiceHistory } from "../../../actions/Fees/CollectFees/CollectFeesAction";
import CustomizedNepaliDatePicker from "../../Reusable/Inputs/NepaliDatePicker";
import { ResetButton, SubmitButton } from "../../Reusable/Buttons/SearchButton";
import { GetDueAlerts, GetDueReports } from "../../../actions/Fees/DueReports/DueReportsAction";
import { dateConverterBsToAd } from "../../utils/dateConverter";
import { GetFeeType } from "../../../actions/Fees/FeeType/FeeTypeAction";
import { Fee_TypeI } from "../../../actions/Fees/FeeType/FeeTypeActionTypes";
import { Tupple } from "../../../common/json.constant";
import { template } from "lodash";
import { getMonthName } from "../FeeType/FeeTypeTable";
// -----------------<starts> Selected Student Form starts-----------------------//

const DueAlertSearch = (props: any) => {
    const classes = useFormStyles();
    const {
        grade, setGrade, section, setSection, startDate, setStartDate, endDate, setEndDate, student, setStudent,
        month, setMonth, fee, setFee
    } = props;
    const [gradeChoices, setGradeChoices] = useState<any | null>([]);
    const [sectionChoices, setSectionChoices] = useState<any[] | []>([]);
    const [monthChoices, setMonthChoices] = useState<any | []>([]);
    const [sectionDisabler, setSectionDisabler] = useState<boolean>(true);
    const [studentSelectOpen, setStudentSelectOpen] = useState<boolean>(false);
    const [studentChoices, setStudentChoices] = useState<any>([]);
    const gradeList = useSelector((state: RootStore) => state.class.classes);
    const [feeChoices, setFeeChoices] = useState<any[] | []>([]);

    const studentList = useSelector(
        (state: RootStore) => state.student
    );

    const feeTypeSelector = useSelector((state: RootStore) => state.fee_type);


    const dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        setValue,
        reset,
        errors,
        setError,
        clearErrors,
    } = useForm<any>({
        mode: "onChange",
    });

    useEffect(() => {
        dispatch(GetSections());
        dispatch(GetClasses());
        dispatch(GetFeeType(1));
    }, []);

    useEffect(() => {
        const data = feeTypeSelector.feeType;
        setFeeChoices(data?.results?.map((item: Fee_TypeI) => ({
            id: item.id,
            title: item.name,
            months: item.billing_period
        })))
    }, [feeTypeSelector])

    useEffect(() => {
        const temp: any = []
        if (fee?.months?.length > 0) {
            fee?.months.map((elem: any) => {
                temp.push({
                    key: elem,
                    value: getMonthName(elem)
                })
            })
        }
        setMonthChoices(temp)
    }, [fee])

    useEffect(() => {
        gradeList && setGradeChoices(gradeList);
    }, [gradeList]);

    console.log({ gradeChoices })

    const handleGradeChange = (value: any | null) => {
        if (value) {
            setGrade(value);
            populateSectionChoices(value.section)
            dispatch(StudentSearch(value.grade, ""));
        } else {
            setSectionDisabler(true);
        }
    };

    const populateSectionChoices = (sections: any | []) => {
        setSectionDisabler(false);
        sections.length > 0
            ? setSectionChoices(sections)
            : setSectionDisabler(true);
    };

    useEffect(() => {
        if (section != null && grade != null) {
            dispatch(StudentSearch(grade.grade, section.id));
        }
    }, [section, grade]);

    useEffect(() => {
        if (studentList.searched_student != null) {
            setStudentChoices(studentList.searched_student);
        }
    }, [studentList.searched_student]);

    console.log({ studentChoices })

    const handleSectionChange = (value: any) => {
        setSection(value);
    };

    const handleStudentChange = (val: any) => {
        if (val != null) {
            setStudent(val);
        }
    };

    const handleFeeChange = (value: any | null) => {
        setFee(value);

    };

    const handleMonthChange = (value: any | null) => {
        setMonth(value);

    };

    const onStudentInputChange = (event: any, value: any) => {
        console.log(value)
        dispatch(GetStudentByName(value));
    };

    useEffect(() => {
        if (student !== null) {
            dispatch(GetInvoiceHistory(student?.academic_id))
        }
    }, [student])

    const onSubmit = () => {
        // console.log(fee?.id,
        //     grade?.grade || "",
        //     section?.id || "",
        //     month?.key)
        dispatch(GetDueAlerts(fee?.id,
            grade?.grade || "",
            section?.id || "",
            month?.key))
        // dispatch(
        //     GetDueAlerts(
        //         1,
        //         grade?.grade || "",
        //         section?.id || "",
        //         startDate && dateConverterBsToAd(startDate) || "",
        //         endDate && dateConverterBsToAd(endDate) || ""
        //     )
        // );
    }

    const restData = () => {
        dispatch(GetDueReports(
            1,
            "",
            "",
            "",
            ""
        ));
        setGrade(null)
        setSection(null)
        setStartDate("")
        setEndDate("")
    }

    console.log({ fee })


    return (
        <>
            <div style={{ padding: '15px' }}>
                {/* <div style={{ display: "flex", justifyContent: "space-between" }}> */}
                <div>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div>
                                <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                                    {props.title && <Grid container>
                                        <Grid item xs={12} className={classes.formTitle}>
                                            <span>{props.title}</span>
                                        </Grid>
                                    </Grid>}
                                    <Grid container spacing={2}>
                                        <Grid item xs={3}>
                                            <InputLabel>Fee Header</InputLabel>
                                            <Autocomplete
                                                onChange={(event: React.ChangeEvent<{}>, value: any | null) =>
                                                    handleFeeChange(value)
                                                }
                                                options={feeChoices || []}
                                                getOptionLabel={(option) => option?.title}
                                                value={fee}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="--select--"
                                                        name="fee"
                                                        variant="outlined"
                                                        inputRef={register({ required: true })}

                                                    />
                                                )}
                                            />
                                        </Grid>
                                        {fee?.months?.length > 0 &&
                                            <Grid item xs={2}>
                                                <InputLabel>Month</InputLabel>
                                                <Autocomplete
                                                    onChange={(event: React.ChangeEvent<{}>, value: any | null) =>
                                                        handleMonthChange(value)
                                                    }
                                                    options={monthChoices || []}
                                                    getOptionLabel={(option) => option?.value}
                                                    value={month}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            placeholder="--select--"
                                                            name="fee"
                                                            variant="outlined"
                                                            inputRef={register({ required: true })}

                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        }
                                        <Grid item xs={2}>
                                            <InputLabel>Class</InputLabel>
                                            <Autocomplete
                                                onChange={(event: React.ChangeEvent<{}>, value: any | null) =>
                                                    handleGradeChange(value)
                                                }
                                                options={gradeChoices}
                                                getOptionLabel={(option) => "Class" + " " + option.grade_name}
                                                value={grade}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Select a Grade"
                                                        name="class"
                                                        variant="outlined"
                                                        inputRef={register({ required: true })}
                                                    />
                                                )}
                                            />
                                        </Grid>

                                        <Grid item xs={2} className={classes.formWrapper}>
                                            <InputLabel>Section</InputLabel>
                                            <Autocomplete
                                                onChange={(event: React.ChangeEvent<{}>, value: any | null) =>
                                                    handleSectionChange(value)
                                                }
                                                options={sectionChoices}
                                                value={section && section}
                                                getOptionLabel={(option) => option.name}
                                                disabled={sectionDisabler}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Select a Section"
                                                        name="section"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <SubmitButton style={{ marginTop: "5px" }} />
                                        {/* <ResetButton onClick={restData} style={{ marginTop: "5px" }} /> */}
                                    </Grid>
                                </form>
                            </div>
                        </Grid>
                    </Grid>
                </div>

            </div>
        </>
    );
};
// -----------------<end> Selected Student form ends-----------------------//
export default DueAlertSearch;
