import React from "react";
import { ReportSideBar } from "../../../../components";
import CustomizeID from "../../../../components/Reports/Templates/IdCard/CustomizeID";
import SubModuleLayout from "../../../../components/Reusable/Layouts/SubModuleLayout";

const CustomizePage = () => {
  const nav = <ReportSideBar id_card_template />;
  return (
    <SubModuleLayout reportMenuActive sideNav={nav}>
      <CustomizeID />
    </SubModuleLayout>
  );
};

export default CustomizePage;
