import axios from "axios";
import { Dispatch } from "redux";
import {
  GET_CATEGORY_LOADING,
  GET_CATEGORY_ERROR,
  GET_CATEGORY_SUCCESS,
  CategoryDispatchTypes,
  ADD_CATEGORY_LOADING,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_ERROR,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_ERROR,
  DELETE_CATEGORY_LOADING,
  UPDATE_CATEGORY_LOADING,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_ERROR,
} from "./CategoryActionTypes";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";

interface RequestDataI {
  id: string;
  name: string;
}

export const GetCategory =
  () => async (dispatch: Dispatch<CategoryDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_CATEGORY_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/student_category/`,
        HeaderConfig()
      );

      dispatch({
        type: GET_CATEGORY_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_CATEGORY_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Categories Loading Failed",
        },
      });
    }
  };

export const AddCategory =
  (data: RequestDataI) =>
  async (dispatch: Dispatch<CategoryDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_CATEGORY_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/student_category/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_CATEGORY_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Category Added Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: ADD_CATEGORY_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Category Add Failed",
        },
      });
    }
  };

export const UpdateCategory =
  (id: string, data: RequestDataI) =>
  async (dispatch: Dispatch<CategoryDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_CATEGORY_LOADING,
      });

      const res = await axios.put(
        `${ADMIN_API_URL}/student_category/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_CATEGORY_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Category Updated Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: UPDATE_CATEGORY_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Category Update Failed",
        },
      });
    }
  };

export const DeleteCategory =
  (id: string) =>
  async (dispatch: Dispatch<CategoryDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_CATEGORY_LOADING,
      });

      const res = await axios.delete(
        `${ADMIN_API_URL}/student_category/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_CATEGORY_SUCCESS,
        payload: { id: id },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Category Deleted Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_CATEGORY_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "This category has already been used somewhere.",
        },
      });
    }
  };
