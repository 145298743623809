import {
  Paper,
  Grid,
  Typography,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { GetCheckinCheckout } from '../../../actions/HumanResource/Attendance/AttendanceAction';
import { RootStore } from '../../../store';
import { ResetButton, SubmitButton } from '../../Reusable/Buttons/SearchButton';
import CustomizedNepaliDatePicker from '../../Reusable/Inputs/NepaliDatePicker';
import CustomizedSelect, { RowSelect } from '../../Reusable/Inputs/Select';
import { useFormStyles } from '../../Styles/FormStyles';
import {
  dateConverterAdToBs,
  dateConverterBsToAd,
} from '../../utils/dateConverter';

const DateSearch = (props: any) => {
  const { setInputDate } = props;
  const classes = useFormStyles();
  const { handleSubmit } = useForm();
  const dispatch = useDispatch();

  const today = moment(new Date()).format('YYYY-MM-DD');

  const [date, setDate] = useState<string>(dateConverterAdToBs(today));
  const [tableData, setTableData] = useState<any>([]);

  const staffAttendanceState = useSelector(
    (state: RootStore) => state.staff_attendance?.teacher_attendance
  );

  useEffect(() => {
    today && dispatch(GetCheckinCheckout(today, today));
    setInputDate(today);
  }, []);

  const handleDate = (date: string) => {
    setDate(date);
    const converted = dateConverterBsToAd(date);
    converted && dispatch(GetCheckinCheckout(converted, converted));
    setInputDate(converted);
  };

  const handleReset = () => {
    setDate(dateConverterAdToBs(today));
    today && dispatch(GetCheckinCheckout(today, today));
  };

  return (
    <>
      <Paper className={classes.pageContentBox}>
        <form className={classes.form}>
          <Grid container justifyContent="space-between">
            <Grid item xs={6} className={classes.formTitle}>
              <Typography variant="h6">Staff Search</Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3} className={classes.formWrapperRow}>
              <CustomizedNepaliDatePicker
                label="Date"
                value={date}
                name="date"
                setValue={handleDate}
              />
            </Grid>
            <ResetButton onClick={handleReset} />
          </Grid>
        </form>
      </Paper>
    </>
  );
};

export default DateSearch;
