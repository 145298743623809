export const GET_SCHOLARSHIP_TYPE_LOADING = "GET_SCHOLARSHIP_TYPE_LOADING";
export const GET_SCHOLARSHIP_TYPE_ERROR = "GET_SCHOLARSHIP_TYPE_ERROR";
export const GET_SCHOLARSHIP_TYPE_SUCCESS = "GET_SCHOLARSHIP_TYPE_SUCCESS";

export const GET_SCHOLARSHIP_STUDENT_LOADING =
  "GET_SCHOLARSHIP_STUDENT_LOADING";
export const GET_SCHOLARSHIP_STUDENT_ERROR = "GET_SCHOLARSHIP_STUDENT_ERROR";
export const GET_SCHOLARSHIP_STUDENT_SUCCESS =
  "GET_SCHOLARSHIP_STUDENT_SUCCESS";

export const GET_SCHOLARSHIP_REPORT_LOADING = "GET_SCHOLARSHIP_REPORT_LOADING";
export const GET_SCHOLARSHIP_REPORT_ERROR = "GET_SCHOLARSHIP_REPORT_ERROR";
export const GET_SCHOLARSHIP_REPORT_SUCCESS = "GET_SCHOLARSHIP_REPORT_SUCCESS";

export const ADD_SCHOLARSHIP_TYPE_LOADING = "ADD_SCHOLARSHIP_TYPE_LOADING";
export const ADD_SCHOLARSHIP_TYPE_ERROR = "ADD_SCHOLARSHIP_TYPE_ERROR";
export const ADD_SCHOLARSHIP_TYPE_SUCCESS = "ADD_SCHOLARSHIP_TYPE_SUCCESS";

export const UPDATE_SCHOLARSHIP_TYPE_LOADING =
  "UPDATE_SCHOLARSHIP_TYPE_LOADING";
export const UPDATE_SCHOLARSHIP_TYPE_ERROR = "UPDATE_SCHOLARSHIP_TYPE_ERROR";
export const UPDATE_SCHOLARSHIP_TYPE_SUCCESS =
  "UPDATE_SCHOLARSHIP_TYPE_SUCCESS";

export const DELETE_SCHOLARSHIP_TYPE_LOADING =
  "DELETE_SCHOLARSHIP_TYPE_LOADING";
export const DELETE_SCHOLARSHIP_TYPE_ERROR = "DELETE_SCHOLARSHIP_TYPE_ERROR";
export const DELETE_SCHOLARSHIP_TYPE_SUCCESS =
  "DELETE_SCHOLARSHIP_TYPE_SUCCESS";

export const APPLY_SCHOLARSHIP_LOADING = "APPLY_SCHOLARSHIP_LOADING";
export const APPLY_SCHOLARSHIP_ERROR = "APPLY_SCHOLARSHIP_ERROR";
export const APPLY_SCHOLARSHIP_SUCCESS = "APPLY_SCHOLARSHIP_SUCCESS";

export const REMOVE_SCHOLARSHIP_LOADING = "REMOVE_SCHOLARSHIP_LOADING";
export const REMOVE_SCHOLARSHIP_ERROR = "REMOVE_SCHOLARSHIP_ERROR";
export const REMOVE_SCHOLARSHIP_SUCCESS = "REMOVE_SCHOLARSHIP_SUCCESS";

export const GET_SCHOLARSHIP_LOADING = "GET_SCHOLARSHIP_LOADING";
export const GET_SCHOLARSHIP_ERROR = "GET_SCHOLARSHIP_ERROR";
export const GET_SCHOLARSHIP_SUCCESS = "GET_SCHOLARSHIP_SUCCESS";

export const GET_SCHOLARSHIP_BY_STUDENT_LOADING =
  "GET_SCHOLARSHIP_BY_STUDENT_LOADING";
export const GET_SCHOLARSHIP_BY_STUDENT_ERROR =
  "GET_SCHOLARSHIP_BY_STUDENT_ERROR";
export const GET_SCHOLARSHIP_BY_STUDENT_SUCCESS =
  "GET_SCHOLARSHIP_BY_STUDENT_SUCCESS";

export type FeeTypeII = {
  id: string;
  name: string;
  is_monthly: boolean;
  months: string[] | number[];
};

export type ApplyScholarShipI = {
  student_academic: any;
  scholarship_type: any;
};

export type StudentAcademicI = {
  id: string;
  full_name: string;
  roll_number: number;
  grade: string;
  section: number;
};

export type GetScholarShipI = {
  student_academic: StudentAcademicI[];
  scholarship_type: ScholarshipType;
};

export type ScholarshipType = {
  id: string;
  scholarship_title: string;
  fee_type: number;
  scholarship_type: string;
  scholarship_in: string;
  applicable_scholarship: number;
  fee_type_detail?: FeeTypeII;
  description: string;
  general_info: number;
  count?: number;
};

interface FeeDetailI {
  id: string;
  name: string;
  is_monthly: boolean;
  months: string[] | null;
}
export interface StudentScholarshipI {
  id: string;
  scholarship_title: string;
  fee_type: string;
  scholarship_type: string;
  scholarship_in: string;
  applicable_scholarship: number;
  description: string;
  general_info: number;
  fee_type_detail: FeeDetailI;
}

//===============================<START>GET Scholarship Type<START>======================//

export interface GetScholarShipTypeLoading {
  type: typeof GET_SCHOLARSHIP_TYPE_LOADING;
}

export interface GetScholarShipTypeError {
  type: typeof GET_SCHOLARSHIP_TYPE_ERROR;
}

export interface GetScholarShipTypeSuccess {
  type: typeof GET_SCHOLARSHIP_TYPE_SUCCESS;
  payload: any;
}

export interface GetScholarShipStudentLoading {
  type: typeof GET_SCHOLARSHIP_STUDENT_LOADING;
}

export interface GetScholarShipStudentError {
  type: typeof GET_SCHOLARSHIP_STUDENT_ERROR;
}

export interface GetScholarShipStudentSuccess {
  type: typeof GET_SCHOLARSHIP_STUDENT_SUCCESS;
  payload: any;
}

export interface GetScholarShipReportLoading {
  type: typeof GET_SCHOLARSHIP_REPORT_LOADING;
}

export interface GetScholarShipReportError {
  type: typeof GET_SCHOLARSHIP_REPORT_ERROR;
}

export interface GetScholarShipReportSuccess {
  type: typeof GET_SCHOLARSHIP_REPORT_SUCCESS;
  payload: any;
}

//===============================<END>GET Scholarship Type<END>======================//

//===============================<START>Add Scholarship Type<START>======================//

export interface AddScholarShipTypeLoading {
  type: typeof ADD_SCHOLARSHIP_TYPE_LOADING;
}

export interface AddScholarShipTypeError {
  type: typeof ADD_SCHOLARSHIP_TYPE_ERROR;
  payload: any;
}

export interface AddScholarShipTypeSuccess {
  type: typeof ADD_SCHOLARSHIP_TYPE_SUCCESS;
  payload: any;
}

//===============================<END>Add Scholarship Type<END>======================//

//===============================<START>Update Scholarship Type<START>======================//

export interface UpdateScholarShipTypeLoading {
  type: typeof UPDATE_SCHOLARSHIP_TYPE_LOADING;
}

export interface UpdateScholarShipTypeError {
  type: typeof UPDATE_SCHOLARSHIP_TYPE_ERROR;
  payload: any;
}

export interface UpdateScholarShipTypeSuccess {
  type: typeof UPDATE_SCHOLARSHIP_TYPE_SUCCESS;
  payload: any;
}

//===============================<END>Update Scholarship Type<END>======================//

//===============================<START>Delete Scholarship Type<START>======================//

export interface DeleteScholarShipTypeLoading {
  type: typeof DELETE_SCHOLARSHIP_TYPE_LOADING;
}

export interface DeleteScholarShipTypeError {
  type: typeof DELETE_SCHOLARSHIP_TYPE_ERROR;
}

export interface DeleteScholarShipTypeSuccess {
  type: typeof DELETE_SCHOLARSHIP_TYPE_SUCCESS;
  payload: { id: string };
}

//===============================<END>Delete Scholarship Type<END>======================//

//===============================<START>GET Scholarship <START>======================//

export interface GetScholarShipLoading {
  type: typeof GET_SCHOLARSHIP_LOADING;
}

export interface GetScholarShipError {
  type: typeof GET_SCHOLARSHIP_ERROR;
}

export interface GetScholarShipSuccess {
  type: typeof GET_SCHOLARSHIP_SUCCESS;
  payload: any;
}

//===============================<END>GET Scholarship <END>======================//

//===============================<START>Apply Scholarship Type<START>======================//

export interface ApplyScholarShipLoading {
  type: typeof APPLY_SCHOLARSHIP_LOADING;
}

export interface ApplyScholarShipError {
  type: typeof APPLY_SCHOLARSHIP_ERROR;
}

export interface ApplyScholarShipSuccess {
  type: typeof APPLY_SCHOLARSHIP_SUCCESS;
  payload: ApplyScholarShipI;
}

//===============================<END>GET Scholarship Type<END>======================//

//===============================<START>Remove Scholarship Type<START>======================//

export interface RemoveScholarShipLoading {
  type: typeof REMOVE_SCHOLARSHIP_LOADING;
}

export interface RemoveScholarShipError {
  type: typeof REMOVE_SCHOLARSHIP_ERROR;
}

export interface RemoveScholarShipSuccess {
  type: typeof REMOVE_SCHOLARSHIP_SUCCESS;
  payload: any;
}
//===============================<END>GET Scholarship Type<END>======================//

//===============================<START>GET Student Scholarship<START>======================//

export interface GetStudentScholarShipLoading {
  type: typeof GET_SCHOLARSHIP_BY_STUDENT_LOADING;
}

export interface GetStudentScholarShipError {
  type: typeof GET_SCHOLARSHIP_BY_STUDENT_ERROR;
}

export interface GetStudentScholarShipSuccess {
  type: typeof GET_SCHOLARSHIP_BY_STUDENT_SUCCESS;
  payload: StudentScholarshipI[];
}

//===============================<END>GET Student Scholarship<END>======================//

export type ScholarshipDispatchTypes =
  | GetScholarShipTypeLoading
  | GetScholarShipTypeError
  | GetScholarShipTypeSuccess
  | GetScholarShipReportLoading
  | GetScholarShipReportError
  | GetScholarShipReportSuccess
  | GetScholarShipStudentLoading
  | GetScholarShipStudentError
  | GetScholarShipStudentSuccess
  | AddScholarShipTypeLoading
  | AddScholarShipTypeError
  | AddScholarShipTypeSuccess
  | UpdateScholarShipTypeLoading
  | UpdateScholarShipTypeError
  | UpdateScholarShipTypeSuccess
  | DeleteScholarShipTypeLoading
  | DeleteScholarShipTypeError
  | DeleteScholarShipTypeSuccess
  | GetScholarShipLoading
  | GetScholarShipError
  | GetScholarShipSuccess
  | ApplyScholarShipLoading
  | ApplyScholarShipError
  | ApplyScholarShipSuccess
  | RemoveScholarShipLoading
  | RemoveScholarShipError
  | RemoveScholarShipSuccess
  | GetStudentScholarShipLoading
  | GetStudentScholarShipError
  | GetStudentScholarShipSuccess;
