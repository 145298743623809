//---------------------------<START> import modules starts-----------------------//
import React, { useEffect, useState } from 'react';
import { Grid, Paper } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useFormStyles } from '../../Styles/FormStyles';
import { useDispatch, useSelector } from 'react-redux';
import { AddLeave } from '../../../actions/HumanResource/Leave/LeaveAction';
import { RootStore } from '../../../store';
import { GetLeaveTypes } from '../../../actions/HumanResource/LeaveType/LeaveTypeAction';
import CustomizedTextField from '../../Reusable/Inputs/TextField';
import CustomizedNepaliDatePicker from '../../Reusable/Inputs/NepaliDatePicker';
import CustomizedSelect from '../../Reusable/Inputs/Select';
import { dateConverterBsToAd } from '../../utils/dateConverter';
import { FormSendButon } from '../../Reusable/Buttons/FormButton';
import { Tupple } from '../../../actions';
//-------------------------<END> import modules ends ----------------------------//

//--------------------------<START> Add Annoucement Modal components starts---------------------------------------------------//
const LeaveRequestModal = (props: any) => {
  const classes = useFormStyles();
  const dispatch = useDispatch();

  const [dateFrom, setDateFrom] = useState<any>('');
  const [dateTo, setDateTo] = useState<any>('');
  const [leaveType, setLeaveType] = useState<Tupple | null>(null);

  useEffect(() => {
    dispatch(GetLeaveTypes());
  }, []);

  const leaveTypeState = useSelector((state: RootStore) => state.leave_type);
  const LEAVE_TYPE_CHOICES = leaveTypeState.leave_types.map((item) => ({
    key: item.id.toString(),
    value: item.name,
  }));

  //-------------------<START> React hooks forms starts ----------------------//
  const { register, handleSubmit, errors } = useForm<Tupple>();

  const onSubmit = (data: any) => {
    if (data && dateFrom && dateTo && leaveType) {
      const leaveData = {
        leave_type: leaveType.key,
        leave_from: dateConverterBsToAd(dateFrom),
        leave_to: dateConverterBsToAd(dateTo),
        reason_for_leave: data.description,
      };

      dispatch(AddLeave(leaveData));
    }
    props.onClose();
  };
  //------------------------<END> React Hooks form ends -------------------------//
  return (
    <Paper elevation={0}>
      <div className={classes.modalContent}>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid container>
              <Grid item xs={6} className={classes.formWrapper}>
                <CustomizedSelect
                  label="Leave Type"
                  name="leave_type"
                  options={LEAVE_TYPE_CHOICES}
                  value={leaveType}
                  setValue={setLeaveType}
                  error={
                    errors['leave_type'] ? 'Leave type must be selected' : ''
                  }
                  inputRef={register({ required: true })}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1} item xs={12}>
              <Grid item xs={6} className={classes.formWrapper}>
                <CustomizedNepaliDatePicker
                  label="Leave from Date"
                  value={dateFrom}
                  setValue={setDateFrom}
                />
              </Grid>

              <Grid item xs={6} className={classes.formWrapper}>
                <CustomizedNepaliDatePicker
                  label="Leave to Date"
                  value={dateTo}
                  setValue={setDateTo}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} className={classes.formWrapper}>
              <CustomizedTextField
                label="Description"
                placeholder="Label"
                name="description"
                required
                multiline
                rows={4}
                error={errors['description'] ? 'Required Field.' : ''}
                inputRef={register({ required: true })}
              />
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent="flex-end"
            style={{ paddingBottom: '8px' }}
          >
            <FormSendButon />
          </Grid>
        </form>
      </div>
    </Paper>
  );
};
//---------------------------<END> Add Annoucement Modal components end ---------------------------//
export default LeaveRequestModal;
