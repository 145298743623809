import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import { useFormStyles } from "../../Styles/FormStyles";
import { Autocomplete } from "@material-ui/lab";
import styled from "styled-components";
import { Save, Search } from "@material-ui/icons";
import { useTable } from "../../Reusable";
import { SubjectClassI } from "./SubjectClass";
import { GradeSubjectI } from "../../../rtk/features/exam/subjectGrade/gradeSubject";
import { useForm } from "react-hook-form";
import {
  postGradeSubjectInfo,
  updateGradeSubjectInfo,
} from "../../../rtk/features/exam/subjectGrade/gradeSubjectApi";
import { useDispatch } from "react-redux";

const SearchButton = styled(Button)`
  margin: 5px;
  width: 120px;
`;

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

interface HeadCellsI {
  id: string;
  label: string;
}

const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N" },
  { id: "annoucement_title", label: "Annoucement Title" },
  { id: "notice_date", label: "Notice Date" },
  { id: "published_date", label: "Published Date" },
  { id: "message_to", label: "Message To" },
  { id: "action", label: "Action" },
];

interface FormDataI {
  subject_type: string;
  result_type: string;
  has_practical: string;
  subject_category: string;
  pass_check: string;
  subject_status: string;
  credit_hour_theory: string | number | null;
  credit_hour_practical: string | number | null;
  full_marks_theory: string | number | null;
  pass_marks_theory: string | number | null;
  full_marks_practical: string | number | null;
  pass_marks_practical: string | number | null;
}

const SubjectClassRow = (props: {
  row: GradeSubjectI;
  grade: string | null;
}) => {
  const formClass = useFormStyles();
  const dispatch = useDispatch();
  const { row, grade } = props;
  const [open, setOpen] = React.useState(false);

  const { errors, setError, clearErrors } = useForm();

  const [formData, setFormData] = useState<FormDataI>({
    subject_type: "",
    result_type: "",
    has_practical: "",
    subject_category: "",
    pass_check: "",
    subject_status: "",
    credit_hour_theory: null,
    credit_hour_practical: null,
    full_marks_theory: null,
    pass_marks_theory: null,
    full_marks_practical: null,
    pass_marks_practical: null,
  });

  const classes = useRowStyles();

  const {
    TblContainer,
    TblHead,
    StyledTableCell,
    StyledTableRow,
    exportToExcel,
    tablePrint,
  } = useTable(headCells);

  useEffect(() => {
    const { subject_info } = row;
    if (subject_info) {
      setFormData({
        ...formData,
        ...subject_info,
        result_type: subject_info.result_type === 1 ? "marks" : "grades",
        has_practical: subject_info.has_practical ? "yes" : "no",
        subject_status: subject_info.subject_status ? "yes" : "no",
      });
    }
    setOpen(false);
  }, [row]);

  useEffect(() => {
    setOpen(false);
  }, [grade]);

  const re = /^[0-9]*\.?[0-9]*$/;

  const handleSubjectTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormData({
      ...formData,
      subject_type: (event.target as HTMLInputElement).value,
    });
    clearErrors("subject_type");
  };

  const handleResultInChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      result_type: (event.target as HTMLInputElement).value,
    });
    clearErrors("result_type");
  };

  const handlePracticalChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormData({
      ...formData,
      has_practical: event.target.value,
      pass_check: "",
      credit_hour_practical: null,
      full_marks_practical: null,
      pass_marks_practical: null,
    });
    clearErrors("has_practical");
  };

  const handleSubjectStatusChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormData({
      ...formData,
      subject_status: event.target.value,
    });
    clearErrors("subject_status");
  };

  const handleSubjectCategoryChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormData({
      ...formData,
      subject_category: event.target.value,
    });
    clearErrors("subject_category");
  };

  const handlePassCheckChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormData({
      ...formData,
      pass_check: event.target.value,
    });
    clearErrors("pass_check");
  };

  const handleCreditTheoryChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value === "" || re.test(event.target.value)) {
    }
    setFormData({
      ...formData,
      credit_hour_theory: event.target.value,
    });
    clearErrors("credit_hour_theory");
  };

  const handleCreditPracticalChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value === "" || re.test(event.target.value)) {
    }

    setFormData({
      ...formData,
      credit_hour_practical: event.target.value,
    });
    clearErrors("credit_hour_practical");
  };

  const handleFullMarksTheoryChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value === "" || re.test(event.target.value)) {
      setFormData({
        ...formData,
        full_marks_theory: event.target.value,
      });
      clearErrors("full_marks_theory");
    }
  };

  const handlePassMarksTheoryChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value === "" || re.test(event.target.value)) {
      setFormData({
        ...formData,
        pass_marks_theory: event.target.value,
      });
      clearErrors("pass_marks_theory");
    }
  };

  const handleFullMarksPracticalChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value === "" || re.test(event.target.value)) {
      setFormData({
        ...formData,
        full_marks_practical: event.target.value,
      });
      clearErrors("full_marks_practical");
    }
  };

  const handlePassMarksPracticalChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value === "" || re.test(event.target.value)) {
      setFormData({
        ...formData,
        pass_marks_practical: event.target.value,
      });
      clearErrors("pass_marks_practical");
    }
  };

  const handleSubmit = () => {
    let valid = true;

    if (!formData.subject_type) {
      setError("subject_type", {
        type: "required",
        message: "Select Subject Type",
      });
      valid = false;
    }

    if (!formData.result_type) {
      setError("result_type", {
        type: "required",
        message: "Select Result Type",
      });
      valid = false;
    }

    if (formData.result_type === "marks") {
      if (!formData.full_marks_theory) {
        setError("full_marks_theory", {
          type: "required",
          message: "Enter Full Marks (Theory)",
        });
        valid = false;
      }

      if (!formData.pass_marks_theory) {
        setError("pass_marks_theory", {
          type: "required",
          message: "Enter Pass Marks (Theory)",
        });
        valid = false;
      }
    }

    if (!formData.subject_category) {
      setError("subject_category", {
        type: "required",
        message: "Select Subject Category",
      });
      valid = false;
    }

    if (!formData.subject_status) {
      setError("subject_status", {
        type: "required",
        message: "Select Subject Status",
      });
      valid = false;
    }

    if (!formData.has_practical) {
      setError("has_practical", {
        type: "required",
        message: "Select Practical Allow",
      });
      valid = false;
    }

    if (!formData.credit_hour_theory) {
      setError("credit_hour_theory", {
        type: "required",
        message: "Enter Credit Hour",
      });
      valid = false;
    }

    if (formData.has_practical === "yes") {
      if (!formData.pass_check) {
        setError("pass_check", {
          type: "required",
          message: "Select Pass Check Type",
        });
        valid = false;
      }

      if (!formData.credit_hour_practical) {
        setError("credit_hour_practical", {
          type: "required",
          message: "Enter Credit Hour",
        });
        valid = false;
      }

      if (formData.result_type === "marks") {
        if (!formData.full_marks_practical) {
          setError("full_marks_practical", {
            type: "required",
            message: "Enter Full Marks (Practical)",
          });
          valid = false;
        }

        if (!formData.pass_marks_practical) {
          setError("pass_marks_practical", {
            type: "required",
            message: "Enter Pass Marks (Practical)",
          });
          valid = false;
        }
      }
    }

    if (valid) {
      const post_data = {
        ...formData,
        result_type: formData.result_type === "grades" ? 0 : 1,
        has_practical: formData.has_practical === "yes" ? 1 : 0,
        subject_status: formData.subject_status === "yes" ? 1 : 0,
        credit_hour_theory: Number(formData.credit_hour_theory),
        credit_hour_practical: Number(formData.credit_hour_practical),
        full_marks_theory: Number(formData.full_marks_theory),
        pass_marks_theory: Number(formData.pass_marks_theory),
        full_marks_practical: Number(formData.full_marks_practical),
        pass_marks_practical: Number(formData.pass_marks_practical),
      };
      if (row.subject_info) {
        dispatch(
          updateGradeSubjectInfo({ id: row.subject_info.id, post: post_data })
        );
      } else {
        if (grade) {
          dispatch(
            postGradeSubjectInfo({
              row_id: row.id,
              grade_id: grade,
              post: { ...post_data, subject: row.id, grade: grade },
            })
          );
        }
      }
    }
  };

  return (
    <React.Fragment>
      <StyledTableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center">{row.name}</TableCell>
        <TableCell align="center">
          {row.subject_info?.result_type === 1 ? "Marks" : "Grades"}
        </TableCell>
        <TableCell align="center">
          {row.subject_info?.subject_type || "--"}
        </TableCell>
        <TableCell align="center">
          {row.subject_info
            ? row.subject_info.has_practical
              ? "Yes"
              : "No"
            : "--"}
        </TableCell>
        <TableCell align="center">
          {row.subject_info?.credit_hour_theory || "--"}
        </TableCell>
        <TableCell align="center">
          {row.subject_info?.result_type === 1
            ? row.subject_info?.full_marks_theory || "--"
            : "--"}
        </TableCell>
        <TableCell align="center">
          {row.subject_info?.result_type === 1
            ? row.subject_info?.pass_marks_theory || "--"
            : "--"}
        </TableCell>
        <TableCell align="center">
          {row.subject_info?.result_type === 1
            ? row.subject_info?.full_marks_practical || "--"
            : "--"}
        </TableCell>
        <TableCell align="center">
          {row.subject_info?.result_type === 1
            ? row.subject_info?.pass_marks_practical || "--"
            : "--"}
        </TableCell>
      </StyledTableRow>
      <StyledTableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Subject Details
              </Typography>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  className={formClass.formWrapper}
                >
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Result Type</FormLabel>
                    <RadioGroup
                      aria-label="subjectType"
                      name="subjectType"
                      value={formData.result_type}
                      onChange={handleResultInChange}
                      row
                    >
                      <FormControlLabel
                        value="grades"
                        control={<Radio />}
                        label="Grades"
                      />
                      <FormControlLabel
                        value="marks"
                        control={<Radio />}
                        label="Marks"
                      />
                    </RadioGroup>
                  </FormControl>
                  <span className={formClass.validationErrorInfo}>
                    {errors.subject_type && errors.subject_type.message}
                  </span>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  className={formClass.formWrapper}
                >
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Subject Type</FormLabel>
                    <RadioGroup
                      aria-label="subjectType"
                      name="subjectType"
                      value={formData.subject_type}
                      onChange={handleSubjectTypeChange}
                      row
                    >
                      <FormControlLabel
                        value="Comp"
                        control={<Radio />}
                        label="Compulsary"
                      />
                      <FormControlLabel
                        value="Opt"
                        control={<Radio />}
                        label="Optional"
                      />
                    </RadioGroup>
                  </FormControl>
                  <span className={formClass.validationErrorInfo}>
                    {errors.subject_type && errors.subject_type.message}
                  </span>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  className={formClass.formWrapper}
                >
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Practical Allow</FormLabel>
                    <RadioGroup
                      aria-label="practical"
                      name="practicalAllow"
                      value={formData.has_practical}
                      onChange={handlePracticalChange}
                      row
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                  <span className={formClass.validationErrorInfo}>
                    {errors.has_practical && errors.has_practical.message}
                  </span>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  className={formClass.formWrapper}
                >
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Subject Category</FormLabel>
                    <RadioGroup
                      aria-label="apply_to"
                      name="apply_to"
                      value={formData.subject_category}
                      onChange={handleSubjectCategoryChange}
                      row
                    >
                      <FormControlLabel
                        value="Major"
                        control={<Radio />}
                        label="Major"
                      />
                      <FormControlLabel
                        value="Minor"
                        control={<Radio />}
                        label="Minor"
                      />
                    </RadioGroup>
                  </FormControl>
                  <span className={formClass.validationErrorInfo}>
                    {errors.subject_category && errors.subject_category.message}
                  </span>
                </Grid>
                {formData.has_practical === "yes" ? (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    className={formClass.formWrapper}
                  >
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Pass Check</FormLabel>
                      <RadioGroup
                        aria-label="apply_to"
                        name="apply_to"
                        value={formData.pass_check}
                        onChange={handlePassCheckChange}
                        row
                      >
                        <FormControlLabel
                          value="In partial"
                          control={<Radio />}
                          label="In Partial"
                        />
                        <FormControlLabel
                          value="In total"
                          control={<Radio />}
                          label="In Total"
                        />
                      </RadioGroup>
                    </FormControl>
                    <span className={formClass.validationErrorInfo}>
                      {errors.pass_check && errors.pass_check.message}
                    </span>
                  </Grid>
                ) : null}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  className={formClass.formWrapper}
                >
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Subject Status</FormLabel>
                    <RadioGroup
                      aria-label="apply_to"
                      name="apply_to"
                      value={formData.subject_status}
                      onChange={handleSubjectStatusChange}
                      row
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Active"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="Inactive"
                      />
                    </RadioGroup>
                  </FormControl>
                  <span className={formClass.validationErrorInfo}>
                    {errors.subject_status && errors.subject_status.message}
                  </span>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      {formData.has_practical === "yes" ? (
                        <>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={3}
                            className={formClass.formWrapper}
                          >
                            <InputLabel>Credit Hours (Th)</InputLabel>
                            <TextField
                              variant="outlined"
                              name="title"
                              placeholder="Title"
                              autoComplete="off"
                              value={formData.credit_hour_theory}
                              onChange={handleCreditTheoryChange}
                            />
                            <span className={formClass.validationErrorInfo}>
                              {errors.credit_hour_theory &&
                                errors.credit_hour_theory.message}
                            </span>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={3}
                            className={formClass.formWrapper}
                          >
                            <InputLabel>Credit Hours (Pr)</InputLabel>
                            <TextField
                              variant="outlined"
                              name="title"
                              placeholder="Title"
                              autoComplete="off"
                              value={formData.credit_hour_practical}
                              onChange={handleCreditPracticalChange}
                            />
                            <span className={formClass.validationErrorInfo}>
                              {errors.credit_hour_practical &&
                                errors.credit_hour_practical.message}
                            </span>
                          </Grid>
                        </>
                      ) : (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          className={formClass.formWrapper}
                        >
                          <InputLabel>Credit Hours</InputLabel>
                          <TextField
                            variant="outlined"
                            name="title"
                            placeholder="Title"
                            autoComplete="off"
                            value={formData.credit_hour_theory}
                            onChange={handleCreditTheoryChange}
                          />
                          <span className={formClass.validationErrorInfo}>
                            {errors.credit_hour_theory &&
                              errors.credit_hour_theory.message}
                          </span>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>

                  {formData.result_type === "marks" ? (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        className={formClass.formWrapper}
                      >
                        <InputLabel>Full Marks (Th)</InputLabel>
                        <TextField
                          variant="outlined"
                          name="title"
                          placeholder="Title"
                          autoComplete="off"
                          value={formData.full_marks_theory}
                          onChange={handleFullMarksTheoryChange}
                        />
                        <span className={formClass.validationErrorInfo}>
                          {errors.full_marks_theory &&
                            errors.full_marks_theory.message}
                        </span>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        className={formClass.formWrapper}
                      >
                        <InputLabel>Pass Marks (Th)</InputLabel>
                        <TextField
                          variant="outlined"
                          name="title"
                          placeholder="Title"
                          autoComplete="off"
                          value={formData.pass_marks_theory}
                          onChange={handlePassMarksTheoryChange}
                        />
                        <span className={formClass.validationErrorInfo}>
                          {errors.pass_marks_theory &&
                            errors.pass_marks_theory.message}
                        </span>
                      </Grid>
                      {formData.has_practical === "yes" ? (
                        <>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={3}
                            className={formClass.formWrapper}
                          >
                            <InputLabel>Full Marks (Pr)</InputLabel>
                            <TextField
                              variant="outlined"
                              name="title"
                              placeholder="Title"
                              autoComplete="off"
                              value={formData.full_marks_practical}
                              onChange={handleFullMarksPracticalChange}
                            />
                            <span className={formClass.validationErrorInfo}>
                              {errors.full_marks_practical &&
                                errors.full_marks_practical.message}
                            </span>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={3}
                            className={formClass.formWrapper}
                          >
                            <InputLabel>Pass Marks (Pr)</InputLabel>
                            <TextField
                              variant="outlined"
                              name="title"
                              placeholder="Title"
                              autoComplete="off"
                              value={formData.pass_marks_practical}
                              onChange={handlePassMarksPracticalChange}
                            />
                            <span className={formClass.validationErrorInfo}>
                              {errors.pass_marks_practical &&
                                errors.pass_marks_practical.message}
                            </span>
                          </Grid>
                        </>
                      ) : null}
                    </>
                  ) : null}
                </Grid>

                <Grid container spacing={1}>
                  <SearchButton
                    color="primary"
                    variant="contained"
                    startIcon={<Save />}
                    onClick={handleSubmit}
                  >
                    Save
                  </SearchButton>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </StyledTableRow>
    </React.Fragment>
  );
};

export default SubjectClassRow;
