import React from "react";
import { FormControlLabel, Checkbox, Grid } from "@material-ui/core";
import classes from "*.module.css";
import { useFormStyles } from "../../Styles/FormStyles";

const CusCheckbox = (props: any) => {
  const classes = useFormStyles();
  const { label, ...rest } = props;
  return (
    <FormControlLabel
      labelPlacement="end"
      control={<Checkbox color="primary" size="small" {...rest} />}
      label={label}
    />
  );
};

const FormCheckBox = (props: any) => {
  const classes = useFormStyles();
  const { label, ...rest } = props;
  return (
    <Grid item xs={12} className={classes.formWrapper}>
      <FormControlLabel
        labelPlacement="end"
        control={<Checkbox color="primary" size={props.size ? props.size : "small"} {...rest} />}
        label={label}
      />
    </Grid>
  );
};

export default CusCheckbox;
export { FormCheckBox };
