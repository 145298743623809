import {
  ADD_VEHICLE_ERROR,
  ADD_VEHICLE_LOADING,
  ADD_VEHICLE_SUCCESS,
  DELETE_VEHICLE_ERROR,
  DELETE_VEHICLE_LOADING,
  DELETE_VEHICLE_SUCCESS,
  GET_VEHICLE_ERROR,
  GET_VEHICLE_LOADING,
  GET_VEHICLE_SUCCESS,
  UPDATE_VEHICLE_ERROR,
  UPDATE_VEHICLE_LOADING,
  UPDATE_VEHICLE_SUCCESS,
  VehicleDispatchTypes,
  VehicleTypeI,
} from "../../actions/Transport/Vehicles/VehiclesActionTypes";

interface InitialStateI {
  loading: boolean;
  vehicle: any;
  error?: any;
  recent?: boolean;
  add_or_update: boolean;
}

const initialState: InitialStateI = {
  loading: false,
  vehicle: [],
  error: null,
  recent: false,
  add_or_update: false,
};

const VehicleReducer = (
  state: InitialStateI = initialState,
  action: VehicleDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_VEHICLE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_VEHICLE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_VEHICLE_SUCCESS:
      return {
        ...state,
        loading: false,
        vehicle: action.payload,
        error: null,
        recent: false,
      };

    case ADD_VEHICLE_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case ADD_VEHICLE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        recent: false,
        add_or_update: true,
      };

    case ADD_VEHICLE_SUCCESS:
      return {
        loading: false,
        vehicle: [...state.vehicle, action.payload],
        error: null,
        recent: true,
        add_or_update: true,
      };

    case UPDATE_VEHICLE_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case UPDATE_VEHICLE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        recent: false,
        add_or_update: true,
      };

    case UPDATE_VEHICLE_SUCCESS:
      const current_data: VehicleTypeI[] = state.vehicle;
      const index: number = current_data.findIndex(
        (element) => element.id === action.payload.id
      );
      current_data[index] = action.payload;

      return {
        loading: false,
        vehicle: current_data,
        error: null,
        recent: true,
        add_or_update: true,
      };

    case DELETE_VEHICLE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_VEHICLE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_VEHICLE_SUCCESS:
      const new_data: VehicleTypeI[] = state.vehicle;
      const item_index = new_data.findIndex(
        (element) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return {
        ...state,
        loading: false,
        vehicle: new_data,
        error: null,
      };

    default:
      return state;
  }
};

export default VehicleReducer;
