export const GET_TEACHER_PROFILE_LOADING = "GET_TEACHER_PROFILE_LOADING";
export const GET_TEACHER_PROFILE_ERROR = "GET_TEACHER_PROFILE_ERROR";
export const GET_TEACHER_PROFILE_SUCCESS = "GET_TEACHER_PROFILE_SUCCESS";

export const UPDATE_TEACHER_PROFILE_LOADING = "UPDATE_TEACHER_PROFILE_LOADING";
export const UPDATE_TEACHER_PROFILE_ERROR = "UPDATE_TEACHER_PROFILE_ERROR";
export const UPDATE_TEACHER_PROFILE_SUCCESS = "UPDATE_TEACHER_PROFILE_SUCCESS";

export const GET_DEPARTMENT_LOADING = "GET_DEPARTMENT_LOADING";
export const GET_DEPARTMENT_ERROR = "GET_DEPARTMENT_ERROR";
export const GET_DEPARTMENT_SUCCESS = "GET_DEPARTMENT_SUCCESS";

export const GET_DESIGNATION_LOADING = "GET_DESIGNATION_LOADING";
export const GET_DESIGNATION_ERROR = "GET_DESIGNATION_ERROR";
export const GET_DESIGNATION_SUCCESS = "GET_DESIGNATION_SUCCESS";

type DepartmentTypeI = {
  id: string;
  name: string;
  count?: number;
};
type DesignationTypeI = {
  id: string;
  name: string;
  count?: number;
};


//===============================<START>GET STUDENT_HOUSE<START>======================//

export interface GetTeacherProfileLoading {
  type: typeof GET_TEACHER_PROFILE_LOADING;
}

export interface GetTeacherProfileError {
  type: typeof GET_TEACHER_PROFILE_ERROR;
}

export interface GetTeacherProfileSuccess {
  type: typeof GET_TEACHER_PROFILE_SUCCESS;
  payload: any;
}

export interface UpdateTeacherProfileLoading {
  type: typeof UPDATE_TEACHER_PROFILE_LOADING;
}

export interface UpdateTeacherProfileError {
  type: typeof UPDATE_TEACHER_PROFILE_ERROR;
}

export interface UpdateTeacherProfileSuccess {
  type: typeof UPDATE_TEACHER_PROFILE_SUCCESS;
  payload: any;
}

export interface GetDepartmentLoading {
  type: typeof GET_DEPARTMENT_LOADING;
}

export interface GetDepartmentError {
  type: typeof GET_DEPARTMENT_ERROR;
}

export interface GetDepartmentSuccess {
  type: typeof GET_DEPARTMENT_SUCCESS;
  payload: DepartmentTypeI[];
}

export interface GetDesignationLoading {
  type: typeof GET_DESIGNATION_LOADING;
}

export interface GetDesignationError {
  type: typeof GET_DESIGNATION_ERROR;
}

export interface GetDesignationSuccess {
  type: typeof GET_DESIGNATION_SUCCESS;
  payload: DesignationTypeI[];
}

//===============================<END>GET STUDENT_HOUSE<END>======================//

//===============================<END>DELETE STUDENT_HOUSE<END>======================//

export type TeacherProfileDispatchTypes =
  | GetTeacherProfileLoading
  | GetTeacherProfileError
  | GetTeacherProfileSuccess
  | UpdateTeacherProfileLoading
  | UpdateTeacherProfileError
  | UpdateTeacherProfileSuccess
  | GetDepartmentLoading
  | GetDepartmentError
  | GetDepartmentSuccess
  | GetDesignationLoading
  | GetDesignationError
  | GetDesignationSuccess;
