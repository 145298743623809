export const GET_GRADE_FEE_LOADING = "GET_GRADE_FEE_LOADING";
export const GET_GRADE_FEE_ERROR = "GET_GRADE_FEE_ERROR";
export const GET_GRADE_FEE_SUCCESS = "GET_GRADE_FEE_SUCCESS";

export const POST_GRADE_FEE_LOADING = "POST_GRADE_FEE_LOADING";
export const POST_GRADE_FEE_ERROR = "POST_GRADE_FEE_ERROR";
export const POST_GRADE_FEE_SUCCESS = "POST_GRADE_FEE_SUCCESS";

// Student Fee
export const GET_STUDENT_FEE_LOADING = "GET_STUDENT_FEE_LOADING";
export const GET_STUDENT_FEE_ERROR = "GET_STUDENT_FEE_ERROR";
export const GET_STUDENT_FEE_SUCCESS = "GET_STUDENT_FEE_SUCCESS";

export const APPLY_FEES_TO_STUDENTS_LOADING = "APPLY_FEES_TO_STUDENTS_LOADING";
export const APPLY_FEES_TO_STUDENTS_ERROR = "APPLY_FEES_TO_STUDENTS_ERROR";
export const APPLY_FEES_TO_STUDENTS_SUCCESS = "APPLY_FEES_TO_STUDENTS_SUCCESS";

export interface GradeFeePostRequestI {
  id: string | null;
  fee: string;
  grade: string;
  amount: number;
}

interface AppliesToI {
  id: number;
  name: string;
  grade_name: string;
}
export interface GradeFeeMetaI {
  id: string;
  fee__id: string;
  fee__name: string;
  amount: number;
}

export type GradeFeesI = {
  grade_id: string;
  grade_name: string;
  fees: GradeFeeMetaI[];
};

interface FeeTypeInfoI {
  id: number;
  name: string;
  is_monthly: boolean;
  months: string[];
}

export type FeeTypeI = {
  id: string;
  fee_type: number;
  fee_type_info: FeeTypeInfoI;
  is_monthly: boolean;
  amount: number;
  due_date: string;
  due_day: number;
  due_period_type: string;
  applies_to: any;
  applies_to_info: AppliesToI[];
  description: string;
};

//===============================<START>GET DEPARTMENT<START>======================//

export interface GetGradeFeeLoading {
  type: typeof GET_GRADE_FEE_LOADING;
}

export interface GetGradeFeeError {
  type: typeof GET_GRADE_FEE_ERROR;
}

export interface GetGradeFeeSuccess {
  type: typeof GET_GRADE_FEE_SUCCESS;
  payload: GradeFeesI[];
}

export interface GetStudentFeeLoading {
  type: typeof GET_STUDENT_FEE_LOADING;
}

export interface GetStudentFeeError {
  type: typeof GET_STUDENT_FEE_ERROR;
}

export interface GetStudentFeeSuccess {
  type: typeof GET_STUDENT_FEE_SUCCESS;
  payload: FeeTypeI[];
}

//===============================<END>GET DEPARTMENT<END>======================//

//===============================<START>ADD DEPARTMENT<START>======================//

export interface PostGradeFeeLoading {
  type: typeof POST_GRADE_FEE_LOADING;
}

export interface PostGradeFeeError {
  type: typeof POST_GRADE_FEE_ERROR;
  payload: any;
}

export interface PostGradeFeeSuccess {
  type: typeof POST_GRADE_FEE_SUCCESS;
}

//===============================<END>ADD DEPARTMENT<END>======================//

//===============================<START>Apply Fees to Student<START>======================//

export interface ApplyFeeToStudentsLoading {
  type: typeof APPLY_FEES_TO_STUDENTS_LOADING;
}

export interface ApplyFeeToStudentsError {
  type: typeof APPLY_FEES_TO_STUDENTS_ERROR;
}

export interface ApplyFeeToStudentsSuccess {
  type: typeof APPLY_FEES_TO_STUDENTS_SUCCESS;
}

//===============================<END>Apply Fees to Student<END>======================//

export type FeeDispatchTypes =
  | GetGradeFeeLoading
  | GetGradeFeeError
  | GetGradeFeeSuccess
  | GetStudentFeeLoading
  | GetStudentFeeError
  | GetStudentFeeSuccess
  | PostGradeFeeLoading
  | PostGradeFeeError
  | PostGradeFeeSuccess
  | ApplyFeeToStudentsLoading
  | ApplyFeeToStudentsError
  | ApplyFeeToStudentsSuccess;
