import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_STUDENT_ROUTE_LOADING,
  GET_STUDENT_ROUTE_ERROR,
  GET_STUDENT_ROUTE_SUCCESS,
  StudentRouteDispatchTypes,
  ADD_STUDENT_ROUTE_LOADING,
  ADD_STUDENT_ROUTE_SUCCESS,
  ADD_STUDENT_ROUTE_ERROR,
  STUDENT_LIST_LOADING,
  STUDENT_LIST_SUCCESS,
  STUDENT_LIST_ERROR,
  DELETE_STUDENT_ROUTE_SUCCESS,
  DELETE_STUDENT_TO_ROUTE_SUCCESS,
  DELETE_STUDENT_TO_ROUTE_ERROR,
  DELETE_STUDENT_TO_ROUTE_LOADING,
} from "./StudentRouteActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../../SnackbarActionTypes";
import { HeaderConfig } from "../../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../../config/SERVER_API_URLS";

interface RequestDataI {
  student_id: string;
  student_user__first_name: string;
  student_user__last_name: string;
  academic_info__grade__name: string | null;
  academic_info__section__name: string | null;
}

export const GetStudentRoute =
  (id: string, grade: string, section: string, page: number) =>
  async (dispatch: Dispatch<StudentRouteDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_STUDENT_ROUTE_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/transport_info/get_student/?transport=${id}&grade=${grade}&section=${section}&page=${page}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_STUDENT_ROUTE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_STUDENT_ROUTE_ERROR,
      });
    }
  };

export const GetStudentTransportList =
  (page: number, gradeId: string, sectionId: string) =>
  async (dispatch: Dispatch<StudentRouteDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: STUDENT_LIST_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/transport_info/get_students/?grade=${gradeId}&section=${sectionId}&page=${page}`,
        HeaderConfig()
      );

      dispatch({
        type: STUDENT_LIST_SUCCESS,
        payload: res.data,
      });
      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "success",
      //     snackbarMessage: "Students Loaded",
      //   },
      // });
    } catch (error: any) {
      dispatch({
        type: STUDENT_LIST_ERROR,
      });

      try {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: error.response.statusText,
          },
        });
      } catch (error) {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "There is an error in the server",
          },
        });
      }
    }
  };

export const AddStudentRoute =
  (
    data: any,
    reset: any,
    transport: string,
    page: number,
    grade: string,
    section: string
  ) =>
  async (dispatch: Dispatch<StudentRouteDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_STUDENT_ROUTE_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/transport_info/add_student/`,
        data,
        HeaderConfig()
      );
      reset();

      dispatch({
        type: ADD_STUDENT_ROUTE_SUCCESS,
        payload: { student_list: res.data, s_id: data?.student },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Student Added Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: ADD_STUDENT_ROUTE_ERROR,
      });
      console.log({ error });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage:
            error.response.data?.error && error.response.data?.error[0],
        },
      });
    } finally {
      try {
        dispatch({
          type: GET_STUDENT_ROUTE_LOADING,
        });

        const res = await axios.get(
          `${ADMIN_API_URL}/transport_info/get_student/?transport=${transport}&grade=""&section=""&page=${page}`,
          HeaderConfig()
        );

        dispatch({
          type: GET_STUDENT_ROUTE_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: GET_STUDENT_ROUTE_ERROR,
        });
      }
    }
  };

export const DeleteStudenttoRoute =
  (transportid: string, studentid: string) =>
  async (dispatch: Dispatch<StudentRouteDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_STUDENT_TO_ROUTE_LOADING,
      });

      const res = await axios.delete(
        `${ADMIN_API_URL}/transport_info/delete_student?transport=${transportid}&student=${studentid}`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_STUDENT_TO_ROUTE_SUCCESS,
        payload: { id: studentid },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Student Route Deleted Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_STUDENT_TO_ROUTE_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Student RouteDelete Failed",
        },
      });
    }
  };
