// -----------------<start> modules imports starts-----------------------//
import {
  Button,
  Grid,
  InputLabel,
  Paper,
  TextField,
  IconButton,
  Tooltip,
  Divider,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Autocomplete } from '@material-ui/lab';
import { useFormStyles } from '../../Styles/FormStyles';
import AddNewButton from '../../Reusable/Buttons/AddNewButton';
import { GetClasses } from '../../../actions/Academics/Class/ClassAction';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import { Add } from '@material-ui/icons';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import {
  GetGuardian,
  GetGuardianByName,
  GetSearchedGuardian,
} from '../../../actions/Student/Guardian/GuardianAction';
import AccessPermission from '../StudentDetail/AccessPermission';
// -----------------<end> modules imports ends-----------------------//

// -----------------<start> Interfaces <start>-----------------------//

interface FormI {
  student: string | null;
  class_name: number | null;
  section_name: string | null;
  rollNumber: string | number | null;
}

interface DataI {
  class: string | null | undefined;
  section: number | string | null | undefined;
}

interface SectionI {
  id: string;
  title: string;
}

interface ClassI {
  id: string;
  title: string;
  sections: SectionI[] | [];
}
interface StudentI {
  id: number;
  name: string;
}

// -----------------<end> Interfaces <end>-----------------------//

// -----------------------------<Start> Guardian Search Component starts --------------------//

const GuardianSearch = () => {
  const classes = useFormStyles();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, clearErrors } = useForm<FormI>();
  const [student, setStudent] = useState<StudentI | null>(null);
  const [className, setClassName] = useState<ClassI | null>(null);
  const [section, setSection] = useState<SectionI | null>(null);
  const [sectionChoices, setSectionChoices] = useState<SectionI[] | []>([]);
  const [sectionDisabler, setSectionDisabler] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>('');

  // select choices
  const studentChoices: StudentI[] = [
    { id: 1, name: 'Saphal Adhikari' },
    { id: 2, name: 'Ritu Kafle' },
    { id: 3, name: 'Sandesh Dawadi' },
  ];

  useEffect(() => {
    dispatch(GetClasses());
  }, []);

  const classState = useSelector((state: RootStore) => state.class);
  const classChoices: ClassI[] = classState.classes.map((classItem) => ({
    id: classItem.grade,
    title: classItem.grade_name,
    sections: classItem.section.map((sectionItem: any) => ({
      id: sectionItem.id,
      title: sectionItem.name,
    })),
  }));

  const handleClassChange = (value: ClassI | null) => {
    clearErrors('class_name');
    setClassName(value);
    setSection(null);
    value != null
      ? populateSectionChoices(value.sections)
      : setSectionDisabler(true);
  };

  const handleSectionChange = (value: any | null) => {
    setSection(value);
    clearErrors('section_name');
  };

  const populateSectionChoices = (sections: SectionI[] | []) => {
    setSectionDisabler(false);
    sections.length > 0
      ? setSectionChoices(sections)
      : setSectionDisabler(true);
  };

  const onSearch = () => {
    const form_data: DataI = {
      class: className?.id,
      section: section ? section.id : '',
    };
    dispatch(GetSearchedGuardian(form_data));
  };

  const handleReset = () => {
    setSection(null);
    setClassName(null);
    setSearchQuery('');
    clearErrors('class_name');
    dispatch(GetGuardian(1));
  };

  const handleSearchGuardianByLetter = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const text = event.target.value;
    setSearchQuery(text);
    dispatch(GetGuardianByName(text));
    setClassName(null);
    setSection(null);
    setSectionDisabler(true);
  };

  return (
    <>
      <Paper className={classes.pageContentBox}>
        <form className={classes.form} onSubmit={handleSubmit(onSearch)}>
          <Grid container>
            <Grid item xs={9} className={classes.formTitle}>
              <span>Search Guardian</span>
            </Grid>
            <Grid container item xs={3}>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                  <Tooltip title="Clear Search Query">
                    <IconButton
                      aria-label="delete"
                      style={{ padding: '2px' }}
                      onClick={handleReset}
                    >
                      <SettingsBackupRestoreIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>

                <Grid item>
                  <AccessPermission>
                    <Link to="/add-guardian" style={{ textDecoration: 'none' }}>
                      <AddNewButton
                        title="Add New Announcement"
                        type="button"
                        color="primary"
                        variant="outlined"
                        startIcon={<Add />}
                      >
                        New Guardian
                      </AddNewButton>
                    </Link>
                  </AccessPermission>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={2} className={classes.formWrapper}>
              <InputLabel>
                Select Class
                <span style={{ color: 'red' }}>*</span>
              </InputLabel>
              <Autocomplete
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: ClassI | null
                ) => handleClassChange(value)}
                options={classChoices}
                value={className}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Label"
                    name="class_name"
                    inputRef={register({ required: 'Class is required' })}
                    variant="outlined"
                  />
                )}
              />
              <span className={classes.validationErrorInfo}>
                {errors.class_name?.type === 'required' &&
                  errors.class_name.message}
              </span>
            </Grid>
            <Grid item xs={2} className={classes.formWrapper}>
              <InputLabel>
                Select Section{' '}
                {/* {!sectionDisabler && <span style={{ color: "red" }}>*</span>} */}
              </InputLabel>
              <Autocomplete
                style={{
                  padding: 0,
                }}
                value={section}
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: SectionI | null
                ) => handleSectionChange(value)}
                options={sectionChoices}
                getOptionLabel={(option) => option.title}
                disabled={sectionDisabler}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Label"
                    name="section_name"
                    inputRef={register({ required: false })}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={2} className={classes.formWrapper}>
              <Grid container justifyContent="flex-end">
                <Button
                  className={classes.searchBoxButton}
                  type="submit"
                  color="primary"
                  variant="contained"
                  startIcon={<SearchIcon />}
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Divider style={{ marginBottom: '10px' }} />
          <Grid container>
            <Grid item xs={3} className={classes.formWrapper}>
              <InputLabel>Search Guardians By Name</InputLabel>
              <TextField
                variant="outlined"
                onChange={handleSearchGuardianByLetter}
                value={searchQuery}
              />
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
};
//----------------------------------------<End> Guardian Search Component ends --------------------------//
export default GuardianSearch;
