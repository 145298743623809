import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_SUBJECT_GROUP_LOADING,
  GET_SUBJECT_GROUP_ERROR,
  GET_SUBJECT_GROUP_SUCCESS,
  GET_SUBJECT_GROUP_BY_CLASS_LOADING,
  GET_SUBJECT_GROUP_BY_CLASS_ERROR,
  GET_SUBJECT_GROUP_BY_CLASS_SUCCESS,
  SubjectGroupDispatchTypes,
  ADD_SUBJECT_GROUP_LOADING,
  ADD_SUBJECT_GROUP_SUCCESS,
  ADD_SUBJECT_GROUP_ERROR,
  DELETE_SUBJECT_GROUP_SUCCESS,
  DELETE_SUBJECT_GROUP_ERROR,
  DELETE_SUBJECT_GROUP_LOADING,
  UPDATE_SUBJECT_GROUP_LOADING,
  UPDATE_SUBJECT_GROUP_SUCCESS,
  UPDATE_SUBJECT_GROUP_ERROR,
} from "./SubjectGroupActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ErrorRounded } from "@material-ui/icons";

interface RequestDataI {
  name: string;
  grade: string;
  section: string[];
  subject: string[];
  description: string;
}

export const GetSubjectGroups =
  () =>
  async (dispatch: Dispatch<SubjectGroupDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_SUBJECT_GROUP_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/subject_group/`,
        HeaderConfig()
      );

      dispatch({
        type: GET_SUBJECT_GROUP_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_SUBJECT_GROUP_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "SubjectGroups Loading Failed",
      //   },
      // });
    }
  };

export const GetSubjectGroupsWithPagination =
  (id: number) =>
  async (dispatch: Dispatch<SubjectGroupDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_SUBJECT_GROUP_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/subject_group/?page=${id}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_SUBJECT_GROUP_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_SUBJECT_GROUP_ERROR,
      });
    }
  };

export const GetPaginatedSubjectGroups =
  (id: number, highest: number) =>
  async (dispatch: Dispatch<SubjectGroupDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_SUBJECT_GROUP_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/subject_group/?page=${id}&per_page=${highest}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_SUBJECT_GROUP_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_SUBJECT_GROUP_ERROR,
      });
    }
  };

export const GetSubjectGroupsByClass =
  (grade: string, section: string | null) =>
  async (dispatch: Dispatch<SubjectGroupDispatchTypes | SetSnackBarI>) => {
    let url = `${ADMIN_API_URL}/subject_group/?grade=${grade}`;
    if (section) {
      url = `${ADMIN_API_URL}/subject_group/?grade=${grade}&section=${section}`;
    }

    try {
      dispatch({
        type: GET_SUBJECT_GROUP_BY_CLASS_LOADING,
      });

      const res = await axios.get(url, HeaderConfig());

      dispatch({
        type: GET_SUBJECT_GROUP_BY_CLASS_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_SUBJECT_GROUP_BY_CLASS_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "SubjectGroups Loading Failed",
      //   },
      // });
    }
  };

export const AddSubjectGroup =
  (data: RequestDataI) =>
  async (dispatch: Dispatch<SubjectGroupDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_SUBJECT_GROUP_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/subject_group/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_SUBJECT_GROUP_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Subject Group Added Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: ADD_SUBJECT_GROUP_ERROR,
        payload: error.response && error.response.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: error.response.data.error[0],
        },
      });
    }
  };

export const UpdateSubjectGroup =
  (id: string, data: RequestDataI) =>
  async (dispatch: Dispatch<SubjectGroupDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_SUBJECT_GROUP_LOADING,
      });

      const res = await axios.put(
        `${ADMIN_API_URL}/subject_group/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_SUBJECT_GROUP_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Subject Group Updated Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: UPDATE_SUBJECT_GROUP_ERROR,
        payload: error.response && error.response.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: error.response.data.error[0],
        },
      });
    }
  };

export const DeleteSubjectGroup =
  (id: string) =>
  async (dispatch: Dispatch<SubjectGroupDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_SUBJECT_GROUP_LOADING,
      });

      const res = await axios.delete(
        `${ADMIN_API_URL}/subject_group/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_SUBJECT_GROUP_SUCCESS,
        payload: { id: id },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Subject Group Deleted Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_SUBJECT_GROUP_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Subject Group Delete Failed",
        },
      });
    }
  };
