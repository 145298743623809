import React, { useState, useEffect, useRef } from "react";
import { useFormStyles } from "../../Styles/FormStyles";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import { FineTypeI } from "../../../actions/Fees/FineType/FineActionTypes";
import { AddFine, UpdateFine } from "../../../actions/Fees/FineType/FineAction";
import { useForm } from "react-hook-form";
import FormLayout from "../../Reusable/Layouts/Form.Layout";
import CustomizedTextField from "../../Reusable/Inputs/TextField";
import CustomizedSelect from "../../Reusable/Inputs/Select";
import { CusRadioGroup } from "../../Reusable/Inputs/Radio";
// -----------------<start> Styling <start>-----------------------//
// -----------------<end> Styling <end>-----------------------//

// -----------------<start> Interfaces <start>-----------------------//

// -----------------<end> Interfaces <end>-----------------------//

// -----------------<start> States <start>-----------------------//

// -----------------<end> States <end>-----------------------//
interface PropsI {
  editData: FineTypeI | null;
  onEditCheck: (value: boolean) => void;
}

interface FormInputI {
  id: string;
  name: string;
  fine_in: string;
  fine_applicable: string;
}

const FineTypeForm = (props: PropsI) => {
  const classes = useFormStyles();
  const { editData, onEditCheck } = props;

  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [editID, setEditID] = useState<string>("");
  const [selectedRadioValue, setSelectedRadioValue] = useState<string>("");
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);

  const dispatch = useDispatch();
  const fineTypeLoading = useSelector(
    (state: RootStore) => state.fine_type.loading
  );
  const add_or_update = useSelector((state: RootStore) => state.fine_type.add_or_update)

  useEffect(() => {
    if (add_or_update) {
      setIsBtnLoading(false)
      handleReset();
    }
  }, [add_or_update])

  useEffect(() => {
    setLoading(fineTypeLoading);
  }, [fineTypeLoading]);

  useEffect(() => {
    editData != null && handleEditTableData(editData);
  }, [editData]);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    errors,
    setError,
    clearErrors,
  } = useForm<FormInputI>({
    mode: "onChange",
  });

  const onFormSubmit = (data: FormInputI) => {
    setIsBtnLoading(true);
    const submitData = {
      ...data,
      name: data.name,
      fine_in: selectedRadioValue,
      fine_applicable: data.fine_applicable,
    };

    editID != ''
      ? dispatch(UpdateFine(editID, submitData))
      : dispatch(AddFine(submitData));
  };

  const handleEditTableData = (data: FineTypeI) => {
    setEditMode(true);
    setEditID(data.id);

    setValue("name", data.name);
    setValue("fine_applicable", data.fine_applicable);

    setSelectedRadioValue(data.fine_in);
  };

  const fineTypeList = [
    { id: "P", name: "Percent%" },
    { id: "A", name: "Amount" },
  ];

  const handleReset = () => {
    reset();
    setEditMode(false);
    setSelectedRadioValue("");
    setEditID("");
    onEditCheck(false);
  };

  //----------------------ERROR HANDLING---------------------------//
  const [serverErrors, setServerErrors] = useState<any>(null);
  const fineSelector = useSelector((state: RootStore) => state.fine_type);
  const errorsData = fineSelector.errors;
  const initialErrorsData = useRef(errorsData);

  useEffect(() => {
    if (initialErrorsData.current === errorsData) {
      initialErrorsData.current = errorsData; // Do not set initial errors
    } else {
      if (errorsData?.error != null) {
        const keys = Object.keys(errorsData?.error);
        keys.map((elem: any) => {
          setError(elem, {
            type: "serverSideError",
            message: errorsData.error[elem] && errorsData.error[elem][0],
          });
        });
      }
      setServerErrors(errorsData);
    }
  }, [errorsData]);

  useEffect(() => {
    selectedRadioValue && clearErrors("fine_in");
  }, [selectedRadioValue]);

  useEffect(() => {
    if (fineSelector?.recent == true) {
      handleReset();
    }
  }, [fineSelector.recent]);

  return (
    <>
      <FormLayout
        title={editMode ? "Edit Fine Type" : "Add Fine Type"}
        onSubmit={handleSubmit(onFormSubmit)}
        editMode={editMode}
        loading={loading}
        onClick={!isBtnLoading && handleReset}
        add_or_update={isBtnLoading}
      >
        <CustomizedTextField
          label=" Fine Type"
          placeholder="Fine Type"
          name="name"
          required
          error={errors["name"] ? "Required Field." : ""}
          inputRef={register({ required: true })}
        />

        <CusRadioGroup
          row
          label="Fine In"
          required
          items={fineTypeList}
          value={selectedRadioValue}
          setValue={setSelectedRadioValue}
          name="fine_in"
        />
        <span className={classes.validationErrorInfo}>
          {errors.fine_in?.type === "serverSideError" && errors.fine_in.message}
        </span>
        <CustomizedTextField
          label="Fine Applicable"
          placeholder="Fine Applicable"
          name="fine_applicable"
          required
          error={errors["fine_applicable"] ? "Required Field." : ""}
          inputRef={register({ required: true })}
        />
        <span className={classes.validationErrorInfo}>
          {errors.fine_applicable?.type === "serverSideError" &&
            errors.fine_applicable.message}
        </span>
      </FormLayout>
    </>
  );
};

export default FineTypeForm;
