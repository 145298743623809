import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { Autocomplete } from "@material-ui/lab";
import { useFormStyles } from "../../Styles/FormStyles";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import { ExamTypeI } from "../../../actions/Examination/Exam/ExamActionTypes";
import { GetExams } from "../../../actions/Examination/Exam/ExamAction";
import FullWidthFormLayout from "../../Reusable/Layouts/FullWidthForm.layout";
import CustomizedSelect, {
  InputBox,
  RowSelect,
} from "../../Reusable/Inputs/Select";
import { GradeTypeI } from "../../../actions/Academics/Grade/GradeActionTypes";
import { GetGrades } from "../../../actions/Academics/Grade/GradeAction";
import { getExamSchedule } from "../../../rtk/features/exam/examSchedule/examScheduleApi";
import { resetData } from "../../../rtk/features/exam/examSchedule/examScheduleSlice";

// -----------------<start> Styling <start>-----------------------//

// -----------------<end> Styling <end>-----------------------//

// -----------------<start> Interfaces <start>-----------------------//

interface PropsI {
  actionType: (data: string) => void;
  examParams: (data: {
    examId: ExamTypeI | null;
    gradeId: GradeTypeI | null;
  }) => void;
}

// -----------------<end> Interfaces <end>-----------------------//

const SearchExamSchedule = (props: PropsI) => {
  const { actionType, examParams } = props;
  const classes = useFormStyles();

  ////////////////// State declarations ///////////////////////////////////////////
  const [action, setAction] = useState<string>("update");

  const [selectedExam, setSelectedExam] = useState<ExamTypeI | null>(null);
  const [selectedGrade, setSelectedGrade] = useState<GradeTypeI | null>(null);
  ////////////////////////////////////////////////////////////////////////////////

  //======================================== <START> REACT HOOKS <START> ============================//

  const dispatch = useDispatch();

  const { actionPerformed } = useSelector(
    (state: RootStore) => state.examSubjectSchedule
  );

  const gradeSelector = useSelector((state: RootStore) => state.grade);
  const examSelector = useSelector((state: RootStore) => state.exam);

  useEffect(() => {
    dispatch(GetGrades());
    dispatch(GetExams());
    dispatch(resetData());
  }, []);

  useEffect(() => {
    if (actionPerformed && selectedGrade && selectedExam) {
      dispatch(
        getExamSchedule({ examId: selectedExam.id, gradeId: selectedGrade.id })
      );
    }
  }, [actionPerformed]);

  useEffect(() => {
    examParams({
      gradeId: selectedGrade || null,
      examId: selectedExam || null,
    });
  }, [selectedExam, selectedGrade]);

  useEffect(() => {
    actionType(action);
  }, [action]);

  const handleExamChange = (value: ExamTypeI | null) => {
    setSelectedExam(value);
    setSelectedGrade(null);
    dispatch(resetData());
  };

  const handleGradeChange = (value: GradeTypeI | null) => {
    setSelectedGrade(value);

    if (value) {
      dispatch(
        getExamSchedule({ examId: selectedExam!.id, gradeId: value.id })
      );
    } else {
      dispatch(resetData());
    }
  };

  //======================================== <END> REACT HOOKS <END> ============================//

  return (
    <>
      <FullWidthFormLayout title="Search Exam Schedule">
        <Grid container spacing={2}>
          <Grid item xs={3} className={classes.formWrapperRow}>
            <InputBox>Exam </InputBox>
            <Autocomplete
              classes={{
                input: classes.smallfont,
                option: classes.smallfont,
              }}
              fullWidth
              value={selectedExam}
              onChange={(
                event: React.ChangeEvent<{}>,
                value: ExamTypeI | null
              ) => handleExamChange(value)}
              options={examSelector.exams}
              getOptionLabel={(option) => option.exam_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Exam"
                  name="exam_type"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formWrapperRow}>
            <InputBox>Class </InputBox>
            <Autocomplete
              classes={{
                input: classes.smallfont,
                option: classes.smallfont,
              }}
              value={selectedGrade}
              fullWidth
              onChange={(
                event: React.ChangeEvent<{}>,
                value: GradeTypeI | null
              ) => handleGradeChange(value)}
              disabled={!Boolean(selectedExam)}
              options={gradeSelector.grades}
              getOptionLabel={(option) => option.grade_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Grade"
                  name="class_name"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              {/* <FormLabel component="legend">Action</FormLabel> */}
              <RadioGroup
                aria-label="apply_to"
                name="apply_to"
                value={action}
                onChange={(e) => setAction(e.target.value)}
                row
              >
                <FormControlLabel
                  value="update"
                  control={<Radio />}
                  label="Update"
                />
                <FormControlLabel
                  value="print"
                  control={<Radio />}
                  label="Print"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </FullWidthFormLayout>
    </>
  );
};

export default SearchExamSchedule;
