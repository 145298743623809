import React, { useEffect, useState } from 'react';
import {
  AddCategory,
  UpdateCategory,
} from '../../../actions/Student/StudentCategory/CategoryAction';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import { useForm } from 'react-hook-form';
import FormLayout from '../../Reusable/Layouts/Form.Layout';
import CustomizedTextField from '../../Reusable/Inputs/TextField';

interface FormDataI {
  id: string;
  name: string;
}

interface PropsI {
  editData: FormDataI | null;
}

const StudentCategoryForm = (props: PropsI) => {
  const { editData } = props;

  //= ===================================<START> REACT HOOK FORM <START>==========================//
  const { register, handleSubmit, setValue, reset, errors } = useForm({
    mode: 'onChange',
  });
  //==================================<START>REDUX REDUCER<START>=============================//

  //= =============================<START> Component States <START>=================================//
  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [editID, setEditID] = useState<string | null>(null);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  //= =============================<END> Component States <END>=================================//

  //==================================<START>REDUX REDUCER<START>=============================//
  const dispatch = useDispatch();
  const categoryLoading = useSelector(
    (state: RootStore) => state.categories.loading
  );
  const add_or_update = useSelector(
    (state: RootStore) => state.categories.add_or_update
  );
  //==================================<END>REDUX REDUCER<END>=============================//

  const onFormSubmit = (data: FormDataI) => {
    setIsBtnLoading(true);
    if (editMode) {
      editID != null && dispatch(UpdateCategory(editID, data));
    } else {
      dispatch(AddCategory(data));
    }
  };

  //===================================<START>CYCLE HOOKS<START>===========================//

  useEffect(() => {
    if (add_or_update) {
      setIsBtnLoading(false);
      handleReset();
    }
  }, [add_or_update]);

  useEffect(() => {
    setLoading(categoryLoading);
  }, [categoryLoading]);

  useEffect(() => {
    editData != null && handleEditTableData(editData);
  }, [editData]);
  //===================================<END>CYCLE HOOKS<END>===========================//

  //= =============================<START> Event Handlers <START>=================================//
  const handleEditTableData = (data: FormDataI) => {
    setEditMode(true);
    setValue('name', data.name);
    setEditID(data.id);
  };

  const handleReset = () => {
    reset();
    setEditMode(false);
    setEditID(null);
  };
  //= =============================<END> Event Handlers <END>=================================//

  return (
    <>
      <FormLayout
        title="Add Category"
        onSubmit={handleSubmit(onFormSubmit)}
        editMode={editMode}
        loading={loading}
        onClick={!isBtnLoading && handleReset}
        add_or_update={isBtnLoading}
      >
        <CustomizedTextField
          label="Category Name"
          placeholder="Category Name"
          name="name"
          required
          error={errors['name'] ? 'Required Field.' : ''}
          inputRef={register({ required: true })}
        />
      </FormLayout>
    </>
  );
};

export default StudentCategoryForm;
