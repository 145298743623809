import React from 'react';
import styled from 'styled-components';

const Layout = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MonthName = styled.div`
  font-weight: bold;
`;
const ColorBox = styled.div``;

const LeaveColor = styled.span`
  background-color: #fff456;
  height: 27px;
  width: 27px;
  display: inline-block;
  margin-right: 2px;
  margin-left: 10px;
`;

const LeaveText = styled.span`
  font-weight: 400;
  color: #c4bc49;
  position: relative;
  top: -10%;
  font-size: 12px;
`;

const PresentColor = styled.span`
  background-color: #51ab67;
  height: 27px;
  width: 27px;
  display: inline-block;
  margin-right: 2px;
  margin-left: 10px;
`;

const AbsentColor = styled.span`
  background-color: #b9bcbe;
  height: 27px;
  width: 27px;
  display: inline-block;
  margin-right: 2px;
  margin-left: 10px;
`;

const HolidayColor = styled.span`
  background-color: #ea5e42;
  height: 27px;
  width: 27px;
  display: inline-block;
  margin-right: 2px;
  margin-left: 10px;
`;

const HoldidayText = styled.span`
  font-weight: 400;
  color: #ea5e42;
  font-size: 12px;
  position: relative;
  top: -10%;
`;

const PresentText = styled.span`
  font-weight: 400;
  color: #51ab67;
  position: relative;
  top: -10%;
  font-size: 12px;
`;

const AbsentText = styled.span`
  font-weight: 400;
  color: #b9bcbe;
  font-size: 12px;
  position: relative;
  top: -10%;
`;

const AttendenceColorBox = (props: any) => {
  return (
    <Layout>
      <MonthName>
        {props.childrenName && props.monthName ? (
          <>
            {props.childrenName} : The Month of {props.monthName}
          </>
        ) : (
          ''
        )}
      </MonthName>

      <ColorBox>
        <LeaveColor></LeaveColor>
        <LeaveText>Leave</LeaveText>
        <PresentColor></PresentColor>
        <PresentText>Present</PresentText>
        <AbsentColor></AbsentColor>
        <AbsentText>Absent</AbsentText>
        <HolidayColor></HolidayColor>
        <HoldidayText>Holiday</HoldidayText>
      </ColorBox>
    </Layout>
  );
};

export default AttendenceColorBox;
