import { Button, IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  AddEvent,
  UpdateEvent,
  DeleteEvent,
} from '../../../actions/Event/Events/EventsAction';
import { RootStore } from '../../../store';
import { FormCheckBox as Checkbox } from '../../../components/Reusable/Inputs/Checkbox';
import NepaliDatePicker from '../../../components/Reusable/Inputs/NepaliDatePicker';
import Select from '../../../components/Reusable/Inputs/Select';
import TextField from '../../../components/Reusable/Inputs/TextField';
import {
  dateConverterAdToBs,
  dateConverterBsToAd,
} from '../../../components/utils/dateConverter';
import { Box } from '@material-ui/core';
import { GetEventTypes } from '../../../actions/Event/EventType/EventTypeAction';
import { CUR_NEPALI_DATE as nepaliDate } from '../../../components/utils/nepaliDateUtils';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

interface EventTypeI {
  eventType: object;
  title: string;
  description: string;
  fromDate: string;
  toDate: string;
  time: string;
  color: string;
  location: string;
  isHoliday: boolean;
}

interface EventCalendarFormProps {
  date: string;
  onEdit: boolean;
  event: any;
  onClose: () => void;
}

const EventCalendarForm = (props: EventCalendarFormProps) => {
  const dispatch = useDispatch();
  const { date: currentDate, onClose: handleClose, onEdit, event } = props;
  const { register, handleSubmit, errors, setValue } = useForm<EventTypeI>();

  const [eventType, setEventType] = useState<any>(null);
  const [fromDate, setFromDate] = useState(nepaliDate);
  const [toDate, setToDate] = useState(nepaliDate);
  const [holiday, setHoliday] = useState(false);

  useEffect(() => {
    setFromDate(currentDate);
    setToDate(currentDate);
  }, [currentDate]);

  const eventTypeState = useSelector((state: RootStore) => state.event_type);

  const EVENT_TYPE_CHOICES = eventTypeState.event_types.map((item: any) => ({
    key: item.id.toString(),
    value: item.event_type,
  }));

  useEffect(() => {
    if (onEdit) {
      setEventType(
        EVENT_TYPE_CHOICES.find((item: any) => item.key === event.event_type)
      );
      setValue('title', event.title);
      setValue('location', event.location);
      setHoliday(event.is_holiday);
      setFromDate(dateConverterAdToBs(event.from_date));
      setToDate(dateConverterAdToBs(event.to_date));
      setValue('time', event.time);
      setValue('description', event.description);
    }
  }, [onEdit]);

  const handleHolidayCheck = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setHoliday(checked);
  };

  const onSubmit = (data: EventTypeI) => {
    const sendData = {
      location: data.location,
      time: data.time,
      description: data.description,
      is_holiday: holiday,
      title: data.title,
      from_date: dateConverterBsToAd(fromDate),
      to_date: dateConverterBsToAd(toDate),
      event_type: eventType.key,
    };

    handleClose();

    if (onEdit) {
      dispatch(UpdateEvent(event.id, sendData));
    } else {
      dispatch(AddEvent(sendData));
    }
  };

  const handleDelete = () => {
    if (onEdit && event && event.id) {
      dispatch(DeleteEvent(event.id));
    }
    handleClose();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Select
        label="Event Type"
        name="eventType"
        options={EVENT_TYPE_CHOICES}
        value={eventType}
        setValue={setEventType}
        required
        error={errors['eventType'] ? 'Event Type must be selected' : ''}
        inputRef={register({ required: true })}
      />

      <TextField
        label="Title"
        placeholder="Title"
        name="title"
        required
        error={errors['title'] ? 'Please enter event title.' : ''}
        inputRef={register({ required: true })}
      />

      <TextField
        label="Location"
        placeholder="Location"
        name="location"
        required
        error={errors['location'] ? 'Please enter event location.' : ''}
        inputRef={register({ required: true })}
      />

      <Checkbox
        onChange={handleHolidayCheck}
        label="Is this on a Holiday?"
        checked={holiday}
        name="isHoliday"
        inputRef={register({ required: false })}
      />

      <Box pb="12px" pt="4px">
        <NepaliDatePicker
          label="Start Date"
          value={fromDate}
          setValue={setFromDate}
          name="fromDate"
          required
        />
      </Box>

      <Box pb="12px">
        <NepaliDatePicker
          label="End Date"
          value={toDate}
          setValue={setToDate}
          name="toDate"
          required
        />
      </Box>

      <TextField
        label="Time"
        placeholder="Time"
        name="time"
        type="time"
        required
        InputLabelProps={{
          shrink: true,
        }}
        error={errors['time'] ? 'Please enter event time.' : ''}
        inputRef={register({ required: true })}
      />

      <TextField
        label="Description"
        placeholder="Description"
        name="description"
        multiline
        rows={2}
        required
        error={errors['description'] ? 'Please enter event description.' : ''}
        inputRef={register({ required: true })}
      />

      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Button variant="contained" color="primary" type="submit">
          {onEdit ? 'Update Event' : 'Submit Event'}
        </Button>

        {onEdit && (
          <IconButton onClick={handleDelete}>
            <DeleteForeverIcon />
          </IconButton>
        )}
      </Box>
    </form>
  );
};

export default EventCalendarForm;
