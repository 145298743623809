// -----------------<START> import modules starts----------------------//
import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  InputLabel,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { Search, Add } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { useFormStyles } from '../../Styles/FormStyles';
import AddNewButton from '../../Reusable/Buttons/AddNewButton';
import 'nepali-datepicker-reactjs/dist/index.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import {
  dateConverterAdToBs,
  dateConverterBsToAd,
} from '../../utils/dateConverter';
import moment from 'moment';
import ScheduledSmsTable from './ScheduledSmsTable';
import SentSmsTable from './SentSmsTable';

const today = moment(new Date()).format('YYYY-MM-DD');

const SearchSmsLogTable: React.FC = () => {
  const classes = useFormStyles();
  const history = useHistory();

  const [smsStatus, setSmsStatus] = useState<{ id: number; value: string }>({
    id: 1,
    value: 'Sent',
  });
  const [fromDate, setFromDate] = useState<string>(dateConverterAdToBs(today));
  const [toDate, setToDate] = useState<string>(dateConverterAdToBs(today));

  // =============== <START> REACT HOOK <START> ========================//

  return (
    <>
      <Paper className={classes.pageContentBox}>
        <form className={classes.form}>
          <Grid container>
            <Grid item xs={9} className={classes.formTitle}>
              <Typography variant="h6">Search Sms</Typography>
            </Grid>
            <Grid container item xs={3}>
              <Grid
                container
                justifyContent="flex-end"
                style={{ marginLeft: '40px' }}
              >
                <a onClick={() => history.push('/sms')}>
                  <AddNewButton
                    title="Send New Sms"
                    type="button"
                    color="primary"
                    variant="outlined"
                    startIcon={<Add />}
                  >
                    New Sms
                  </AddNewButton>
                </a>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={3} className={classes.formWrapper}>
                <InputLabel>Sms Status</InputLabel>
                <Autocomplete
                  value={smsStatus}
                  id="checkbox-select-via"
                  options={[
                    { id: 1, value: 'Sent' },
                    { id: 2, value: 'Scheduled For Later' },
                  ]}
                  onChange={(
                    event: React.ChangeEvent<{}>,
                    value: { id: number; value: string } | null
                  ) => setSmsStatus(value || { id: 1, value: 'Sent' })}
                  getOptionLabel={(option) => option.value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Message Via"
                    />
                  )}
                />
              </Grid>

              {smsStatus.id === 1 && (
                <>
                  <Grid item xs={9} />
                  <Grid container spacing={2}>
                    <Grid item xs={3} className={classes.formWrapper}>
                      <InputLabel>
                        From Date <span style={{ color: 'red' }}>*</span>
                      </InputLabel>
                      <NepaliDatePicker
                        className={classes.nepaliDateInput}
                        value={fromDate}
                        onChange={(data: string) => setFromDate(data)}
                        options={{ calenderLocale: 'en', valueLocale: 'en' }}
                        inputClassName="inputFormControl"
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.formWrapper}>
                      <InputLabel>
                        To Date <span style={{ color: 'red' }}>*</span>
                      </InputLabel>
                      <NepaliDatePicker
                        className={classes.nepaliDateInput}
                        value={toDate}
                        onChange={(data: string) => setToDate(data)}
                        options={{ calenderLocale: 'en', valueLocale: 'en' }}
                        inputClassName="inputFormControl"
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </form>
      </Paper>
      {smsStatus.id === 1 ? (
        <SentSmsTable
          date={{
            from_date: dateConverterBsToAd(fromDate),
            to_date: dateConverterBsToAd(toDate),
          }}
        />
      ) : (
        <ScheduledSmsTable />
      )}
    </>
  );
};

//-----------------<ENDS> Annoucement search component ends-----------------------//
export default SearchSmsLogTable;
