export const GET_DATES_LOADING = "GET_DATES_LOADING";
export const GET_DATES_ERROR = "GET_DATES_ERROR";
export const GET_DATES_SUCCESS = "GET_DATES_SUCCESS";

export const GET_RUNNING_MONTH_DATE_LOADING = "GET_RUNNING_MONTH_DATE_LOADING";
export const GET_RUNNING_MONTH_DATE_ERROR = "GET_RUNNING_MONTH_DATE_ERROR";
export const GET_RUNNING_MONTH_DATE_SUCCESS = "GET_RUNNING_MONTH_DATE_SUCCESS";

//===============================<START>GET DATES<START>======================//

export interface GetDatesLoading {
  type: typeof GET_DATES_LOADING;
}

export interface GetDatesError {
  type: typeof GET_DATES_ERROR;
}

export interface GetDatesSuccess {
  type: typeof GET_DATES_SUCCESS;
  payload: any;
}

//===============================<START>GET RUNNING MONTH DATES<START>======================//

export interface GetRunningMonthDatesLoading {
  type: typeof GET_RUNNING_MONTH_DATE_LOADING;
}

export interface GetRunningMonthDatesError {
  type: typeof GET_RUNNING_MONTH_DATE_ERROR;
}

export interface GetRunningMonthDatesSuccess {
  type: typeof GET_RUNNING_MONTH_DATE_SUCCESS;
  payload: any;
}

//===============================<END>DELETE DATES<END>======================//

export type DatesDispatchTypes =
  | GetDatesLoading
  | GetDatesError
  | GetDatesSuccess
  | GetRunningMonthDatesLoading
  | GetRunningMonthDatesError
  | GetRunningMonthDatesSuccess;
