import React, { useRef } from 'react';
import {
  InputLabel,
  TextField,
  Box,
  makeStyles,
  createStyles,
  ClickAwayListener,
  Theme,
} from '@material-ui/core';
import { useFormStyles, useStyles } from '../../Styles/FormStyles';
import 'nepali-datepicker-reactjs/dist/index.css';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import './DatePicker.css';
import EventIcon from '@material-ui/icons/Event';
import clsx from 'clsx';

export const useDateStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      position: 'relative',
    },

    icon: {
      position: 'absolute',
      color: '#333',
      fontSize: 20,
      top: '50%',
      transform: 'translateY(-7px)',
      right: 4,
      width: '20px !important',
      height: '20px !important',
    },
    smallRoot: {
      width: '100%',
      '& input': {
        fontFamily: 'inherit',
        fontSize: '12px',
        fontWeight: '400',
        width: '100%',
        margin: '8px 0px 2.4px',
        padding: '8px 16px 8px 16px',
        borderRadius: '4px',
        outline: 'none',
        borderWidth: '1px',
        borderColor: 'rgba(0, 0, 0, 0.23)',
        background: 'rgba(0,0,0,0.05) !important',

        '&:focus': {
          borderColor: '#132E98 !important',
        },
      },
    },
    root: {
      '& input': {
        fontFamily: 'inherit',
        fontSize: '14px',
        fontWeight: '400',
        width: '100%',
        height: '34px',
        margin: '8px 0px 2.4px',
        padding: '8px 16px 8px 16px',
        borderRadius: '4px',
        outline: 'none',
        borderWidth: '1px',
        borderColor: 'rgba(0, 0, 0, 0.23)',
        background: 'rgba(0, 0, 0, 0.05) !important',

        '&:focus': {
          borderColor: '#132E98 !important',
        },
      },

      // "& .calender": {
      //   zIndex: 999999,
      //   position: "relative",
      //   left: "-100px !important",
      // },
    },
    rootError: {
      '& input': {
        borderColor: 'rgba(255, 0, 0, 0.99)',
      },
    },
  })
);

const CustomizedNepaliDatePicker = (props: any) => {
  const { label, name, placeholder, required, error, ...rest } = props;
  const classes = useStyles();
  const classesX = useDateStyles();

  // const message = errors && errors[name]?.message;

  return (
    <Box className={clsx(classes.formWrapper, classesX.wrapper)}>
      <div style={{ position: 'relative' }}>
        <InputLabel>
          {label}
          {required && <span style={{ color: 'red' }}>*</span>}
        </InputLabel>

        <div style={{ position: 'relative' }}>
          <NepaliDatePicker
            options={{ calenderLocale: 'en', valueLocale: 'en' }}
            className={
              error ? clsx(classesX.root, classesX.rootError) : classesX.root
            }
            value={props.value}
            onChange={(value) => props.setValue(value)}
            {...rest}
          />

          <EventIcon className={classesX.icon} />
        </div>
      </div>

      {error && (
        <span
          className={classes.validationErrorInfo}
          style={{ marginTop: '4px' }}
        >
          {error}
        </span>
      )}
    </Box>
  );
};

const RowNepaliDatePicker = (props: any) => {
  const { label, name, placeholder, required, error, width, ...rest } = props;
  const classes = useStyles();
  const classesX = useDateStyles();
  const classesY = useFormStyles();

  // const message = errors && errors[name]?.message;

  return (
    <>
      <div className={classesY.formWrapperRow} style={{ position: 'relative' }}>
        <Box fontSize={12} mr={0.5} color="text.primary" width={width}>
          {label}
        </Box>
        <NepaliDatePicker
          options={{ calenderLocale: 'en', valueLocale: 'en' }}
          className={
            error
              ? clsx(classesX.smallRoot, classesX.rootError)
              : classesX.smallRoot
          }
          value={props.value}
          onChange={(value) => props.setValue(value)}
          {...rest}
        />

        <EventIcon className={classesX.icon} />

        {error && (
          <span
            className={classes.validationErrorInfo}
            style={{ marginTop: '4px' }}
          >
            {error}
          </span>
        )}
      </div>
    </>
  );
};

export default CustomizedNepaliDatePicker;
export { RowNepaliDatePicker };
