// ---------------- <START> module import starts ------------------//
import React, { useState } from "react";
import { useSidebar } from "../../Reusable";
import { useSidebarStyles } from "../../Styles/SidebarStyles";
import { SmsOutlined } from "@material-ui/icons";
import SidebarTitle from "../../Reusable/Titles/SidebarTitle";
// ---------------- <END> module import ends ------------------//

//---------------------------- <START> interface start ------------------------------//
interface propsI {
  announcement?: boolean | undefined;
  announcementDraft?: boolean | undefined;
  sms?: boolean | undefined;
  smsLog?: boolean | undefined;
  smsTemplates?: boolean | undefined;
  sendResults?: boolean | undefined;
  announcementType?: boolean | undefined;
  meetingType?: boolean | undefined;
  meeting?: boolean | undefined;
}
//---------------------------- <END> interface ends ------------------------------//

// ---------------- <START> Communication Sidebar Component starts ------------------//
const CommunicationSidebar = (props: propsI) => {
  const classes = useSidebarStyles();

  const [announcement] = useState(props.announcement);
  const [announcementDraft] = useState(props.announcementDraft);
  const [sms] = useState(props.sms);
  const [smsLog] = useState(props.smsLog);
  const [smsTemplates] = useState(props.smsTemplates);
  const [sendResults] = useState(props.sendResults);
  const [announcementType] = useState(props.announcementType);
  const [meetingType] = useState(props.meetingType);
  const [meeting] = useState(props.meeting);

  const dataSet = [
    { text: "Announcement", url: "communication", activeStatus: announcement },
    // {
    //   text: "Announcement Draft",
    //   url: "announcement-draft",
    //   activeStatus: announcementDraft,
    // },
    { text: "SMS", url: "sms", activeStatus: sms },
    {
      text: "SMS Log",
      url: "sms-log",
      activeStatus: smsLog,
    },
    // { text: "SMS Template", url: "sms-templates", activeStatus: smsTemplates },
    { text: "Send Results", url: "send-results", activeStatus: sendResults },
    {
      text: "Announcement Type",
      url: "announcement-type",
      activeStatus: announcementType,
    },
    { text: "Meeting Type", url: "meeting-type", activeStatus: meetingType },
    { text: "Meeting", url: "meeting", activeStatus: meeting },
  ];
  const { CustomSidebar } = useSidebar(dataSet, "FINANCE");
  return (
    <div className={classes.Sidebar}>
      <div className={classes.sidebarContent}>
        <SidebarTitle icon={<SmsOutlined />}>Commns</SidebarTitle>
        <CustomSidebar />
      </div>
    </div>
  );
};

export default CommunicationSidebar;
// ---------------- <END> Communication Sidebar Component ends ------------------//
