import {
  Button,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableHead,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import { useTable } from "../../Reusable";
import "nepali-datepicker-reactjs/dist/index.css";
import { RootStore } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  dateConverterAdToBs,
  dateConverterBsToAd,
} from "../../utils/dateConverter";
import { GradeTypeI } from "../../../actions/Academics/Grade/GradeActionTypes";
import styled from "styled-components";
import { ExamTypeI } from "../../../actions/Examination/Exam/ExamActionTypes";
import { GetGeneralInfoData } from "../../../actions/GeneralSettings/GeneralInfo/GeneralInfoAction";
import ReactToPrint from "react-to-print";
import { returnNepaliDateString, timeConvertor } from "./ExamSchedule";

//= ====================== <START> Styles <START> ===================================//

const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 40px;
  position: relative;
`;

const Logo = styled.img`
  position: absolute;
  top: 0;
  left: 0;
`;

const SchoolName = styled.h2`
  font-size: 28px;
`;

const SchoolAddress = styled.h5`
  font-size: 16px;
`;

const ScheduleTitle = styled.h3`
  font-size: 22px;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      position: "absolute",
      top: 50,
    },
    container: {
      padding: theme.spacing(2),

      "& .MuiFormControl-root": {
        width: "95%",
        margin: theme.spacing(1, 1, 0.4, 1),
      },
      "& .MuiInputLabel-root": {
        marginLeft: theme.spacing(1),
        fontSize: "14px",
        color: "#000",
        opacity: "0.7",
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          border: "1px solid #132E98",
        },
        "&:hover fieldset": {
          borderColor: "#132E98 !important",
        },
      },
      "& .MuiOutlinedInput-input": {
        padding: theme.spacing(1.2, 2),
      },
      "& .MuiAutocomplete-inputRoot": {
        padding: theme.spacing(0, 1),
      },
      "& .MuiButton-text": {
        width: "100%",
        margin: theme.spacing(1),
      },
    },
    cell: {
      borderRight: "1px solid #D6D6D6",
    },
    containedButton: {
      width: "90%",
      margin: theme.spacing(2, 0),
    },
    Btn: {
      padding: "2px",
    },
    deleteIcon: {
      color: "#E96C5A",
      padding: "2px",
      marginLeft: "15px",
    },
    examLabel: {
      fontSize: "16px",
      fontWeight: "bold",
    },
    tableSection: {
      "& .MuiOutlinedInput-input": {
        padding: theme.spacing(1.5, 2),
      },
      "& .MuiAutocomplete-inputRoot": {
        padding: "2px",
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    wrapper: {
      padding: theme.spacing(1),
      margin: theme.spacing(1, 2, 3),
    },
  })
);

//= ====================== <END> Styles <END> ===================================//

interface HeadCellsI {
  id: string;
  label: string;
}

//= ====================== <START> Interfaces <START> ===================================//

//= ====================== <END> Interfaces <END> ===================================//

const headCells: HeadCellsI[] = [
  { id: "subject", label: "Subject" },
  { id: "date", label: "Date" },
  { id: "start_time", label: "Start Time" },
  { id: "duration", label: "Duration (Hrs)" },
  { id: "full_marks", label: "Full Marks" },
  { id: "pass_marks", label: "Pass Marks" },
];

const ExamSchedulePrint = (props: {
  gradeId: GradeTypeI | null;
  examId: ExamTypeI | null;
}) => {
  const { gradeId, examId } = props;

  const dispatch = useDispatch();

  const printRef = useRef<HTMLDivElement | null>(null);

  const classes = useStyles();
  const { TblContainer, TblHead, StyledTableCell, StyledTableRow } =
    useTable(headCells);

  const { examSchedule } = useSelector(
    (state: RootStore) => state.examSubjectSchedule
  );

  const { general_info } = useSelector((state: RootStore) => state.auth);
  const generalInfo = useSelector(
    (state: RootStore) => state.general_info.general_info
  );

  // useEffect(() => {
  //   if (general_info) {
  //     dispatch(GetGeneralInfoData(general_info.id));
  //   }
  // }, [general_info]);

  console.log("generalInfo: ", general_info);

  return (
    <Paper className={classes.wrapper}>
      <ReactToPrint
        trigger={() => (
          <p
            style={{
              backgroundColor: "#132E98",
              color: "white",
              fontWeight: "bold",
              borderRadius: "0px",
              margin: "0px",
              textAlign: "center",
              padding: "5px 0px",
              cursor: "pointer",
            }}
          >
            Print This Out !
          </p>
        )}
        content={() => printRef.current}
        removeAfterPrint={true}
        copyStyles={true}
      />
      <div id="print-element" ref={printRef} className={classes.container}>
        {generalInfo && (
          <TitleWrapper>
            <SchoolName>{generalInfo.school_name || ""}</SchoolName>
            <SchoolAddress>{generalInfo.address || ""}</SchoolAddress>
            <ScheduleTitle>Exam Schedule</ScheduleTitle>
            <Logo height="120" width="120" src={generalInfo.logo} />
          </TitleWrapper>
        )}

        <Grid container>
          <Grid item xs={12}>
            <Divider style={{ margin: "8px 0px 20px 0px" }} />
          </Grid>
        </Grid>

        <Grid container style={{ marginBottom: "10px" }}>
          <Grid item xs={3}>
            <span>Exam: {examId?.exam_name || ""}</span>
            <span className={classes.examLabel}>&nbsp;</span>
          </Grid>
          <Grid item xs={3}>
            <span>Class: {gradeId?.grade_name || ""}</span>
            <span className={classes.examLabel}>&nbsp;</span>
          </Grid>
        </Grid>

        <Grid container className={classes.tableSection}>
          <Table>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="center">Subject</StyledTableCell>
                <StyledTableCell align="center">Date</StyledTableCell>
                <StyledTableCell align="center">Time</StyledTableCell>
                <StyledTableCell align="center">Duration (Hrs)</StyledTableCell>
                <StyledTableCell align="center">
                  <span>
                    Full Marks <br />
                    (Theory)
                  </span>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <span>
                    Pass Marks <br />
                    (Theory)
                  </span>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <span>
                    Full Marks <br />
                    (Practical)
                  </span>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <span>
                    Pass Marks <br />
                    (Practical)
                  </span>
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {examSchedule.map((item, index) => (
                <StyledTableRow key={item.id}>
                  <StyledTableCell align="center" className={classes.cell}>
                    {item.subject}
                  </StyledTableCell>

                  <StyledTableCell align="center" className={classes.cell}>
                    {item.exam_subject_info.date
                      ? returnNepaliDateString(
                          dateConverterAdToBs(item.exam_subject_info.date)
                        )
                      : "--"}
                  </StyledTableCell>

                  <StyledTableCell align="center" className={classes.cell}>
                    {item.exam_subject_info.time
                      ? timeConvertor(item.exam_subject_info.time)
                      : "--"}
                  </StyledTableCell>

                  <StyledTableCell align="center" className={classes.cell}>
                    {item.exam_subject_info.duration || "--"}
                  </StyledTableCell>

                  <StyledTableCell align="center" className={classes.cell}>
                    {item.exam_subject_info.full_marks_theory}
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.cell}>
                    {item.exam_subject_info.pass_marks_theory}
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.cell}>
                    {item.exam_subject_info.full_marks_practical || "-"}
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.cell}>
                    {item.exam_subject_info.pass_marks_practical || "-"}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </div>
    </Paper>
  );
};

export default ExamSchedulePrint;
