import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setSnackbar } from "../../../actions/SnackbarAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

export const getReceiptSettings = createAsyncThunk(
  "getReceiptSettings",
  async (undefined, { dispatch }) => {
    try {
      const res = await axios.get(`${ADMIN_API_URL}/receipt-naming-code`);
      return res.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      dispatch(setSnackbar(true, "error", "Failed to get receipt settings"));
    }
  }
);

export const postReceiptSettings = createAsyncThunk(
  "postReceiptSettings",
  async (data: any, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.post(
        `${ADMIN_API_URL}/receipt-naming-code/`,
        data
      );

      dispatch(setSnackbar(true, "success", "Receipt settings updated"));

      return res.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      dispatch(setSnackbar(true, "error", "Failed to update receipt settings"));
      return rejectWithValue(err.response);
    }
  }
);
