import axios from 'axios';
import { Dispatch } from 'redux';

import {
  GET_LOCATION_LOADING,
  GET_LOCATION_ERROR,
  GET_LOCATION_SUCCESS,
  LocationDispatchTypes,
  ADD_LOCATION_LOADING,
  ADD_LOCATION_SUCCESS,
  ADD_LOCATION_ERROR,
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_ERROR,
  DELETE_LOCATION_LOADING,
  UPDATE_LOCATION_LOADING,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_LOCATION_ERROR,
  LocationFormDataTypeI,
} from './LocationActionTypes';

import { SET_SNACKBAR, SetSnackBarI } from '../../SnackbarActionTypes';
import { HeaderConfig } from '../../Auth/Login/LoginAction';
import { ADMIN_API_URL } from '../../../config/SERVER_API_URLS';

interface RequestDataI {
  id: string;
  route_from: string;
  route_to: string;
  pickup_time: string;
  drop_time: string;
  fare: number;
  driver?: string;
  assistant?: string;
  bus_number: string;
  seat: number;
}

export const GetLocation =
  () => async (dispatch: Dispatch<LocationDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_LOCATION_LOADING,
      });

      const res = await axios.get(`${ADMIN_API_URL}/location/`, HeaderConfig());

      dispatch({
        type: GET_LOCATION_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_LOCATION_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "Location Loading Failed",
      //   },
      // });
    }
  };

export const AddLocation =
  (data: LocationFormDataTypeI) =>
  async (dispatch: Dispatch<LocationDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_LOCATION_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/location/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_LOCATION_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'success',
          snackbarMessage: 'Location Added Successfully',
        },
      });
    } catch (error: any) {
      dispatch({
        type: ADD_LOCATION_ERROR,
        payload: error.response && error.response?.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: 'Location Add Failed',
        },
      });
    } finally {
      try {
        dispatch({
          type: GET_LOCATION_LOADING,
        });

        const res = await axios.get(
          `${ADMIN_API_URL}/location/`,
          HeaderConfig()
        );

        dispatch({
          type: GET_LOCATION_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: GET_LOCATION_ERROR,
        });
      }
    }
  };

export const UpdateLocation =
  (id: string, data: LocationFormDataTypeI) =>
  async (dispatch: Dispatch<LocationDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_LOCATION_LOADING,
      });

      const res = await axios.put(
        `${ADMIN_API_URL}/location/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_LOCATION_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'success',
          snackbarMessage: 'Location Updated Successfully',
        },
      });
    } catch (error: any) {
      dispatch({
        type: UPDATE_LOCATION_ERROR,
        payload: error.response && error.response?.data,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "Location Update Failed",
      //   },
      // });
    }
  };

export const DeleteLocation =
  (id: string) =>
  async (dispatch: Dispatch<LocationDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_LOCATION_LOADING,
      });

      const res = await axios.delete(
        `${ADMIN_API_URL}/location/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_LOCATION_SUCCESS,
        payload: { id: id },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'success',
          snackbarMessage: 'Location Deleted Successfully',
        },
      });
    } catch (error) {
      console.log('error', error);
      dispatch({
        type: DELETE_LOCATION_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: 'Location Delete Failed',
        },
      });
    }
  };
