import { Button, Grid, InputLabel, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { Autocomplete } from "@material-ui/lab";
import { useFormStyles } from "../../Styles/FormStyles";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import FullWidthFormLayout from "../../Reusable/Layouts/FullWidthForm.layout";
import { ClassTypeI } from "../../../actions/Academics/Class/ClassActionTypes";
import { SectionTypeI } from "../../../actions/Academics/Section/SectionActionTypes";
import { GetClasses } from "../../../actions/Academics/Class/ClassAction";
import { Search } from "@material-ui/icons";
import { getOptSubjectGroups } from "../../../rtk/features/exam/optSubjects/optSubjectApi";
import { getOptSubjectStudent } from "../../../rtk/features/exam/optSubjectStudent/optSubjectStudentApi";
import { setSearchParam } from "../../../rtk/features/exam/optSubjectStudent/optSubjectStudentSlice";

const OptSubjectStudentForm = () => {
  const classes = useFormStyles();

  ////////////////// State declarations ///////////////////////////////////////////

  const [selectedClass, setSelectedClass] = useState<ClassTypeI | null>(null);
  const [selectedSection, setSelectedSection] = useState<SectionTypeI | null>(
    null
  );

  const [searchDisabled, setSearchDisabled] = useState<boolean>(true);

  ////////////////////////////////////////////////////////////////////////////////

  //======================================== <START> REACT HOOKS <START> ============================//

  const dispatch = useDispatch();

  const { actionPerformed, searchParam } = useSelector(
    (state: RootStore) => state.studentOptSubject
  );
  const classSelector = useSelector((state: RootStore) => state.class);

  const [sections, setSections] = useState<SectionTypeI[]>([]);

  useEffect(() => {
    dispatch(GetClasses());
  }, []);

  useEffect(() => {
    if (actionPerformed && searchParam) {
      dispatch(
        getOptSubjectStudent({
          grade: searchParam.grade.id,
          section: searchParam.section?.id || "",
        })
      );
    }
  }, [actionPerformed]);

  const handleClassChange = (value: ClassTypeI | null) => {
    setSelectedClass(value);
    setSelectedSection(null);

    if (value) {
      if (value.section.length) {
        setSections(value.section);
        setSearchDisabled(true);
      } else {
        setSections([]);
        setSearchDisabled(false);
      }
    } else {
      setSearchDisabled(true);
      setSections([]);
    }
  };

  const handleSectionChange = (value: SectionTypeI | null) => {
    setSelectedSection(value);

    if (value) {
      setSearchDisabled(false);
    } else {
      setSearchDisabled(true);
    }
  };

  const handleSearch = () => {
    dispatch(
      setSearchParam({
        grade: {
          id: selectedClass!.grade,
          name: selectedClass!.grade_name,
        },
        section: selectedSection
          ? {
              id: selectedSection.id,
              name: selectedSection.name,
            }
          : null,
      })
    );
    dispatch(
      getOptSubjectStudent({
        grade: selectedClass!.grade,
        section: selectedSection?.id || "",
      })
    );
  };

  //======================================== <END> REACT HOOKS <END> ============================//

  return (
    <>
      <FullWidthFormLayout>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            className={classes.formWrapper}
          >
            <InputLabel>Class </InputLabel>
            <Autocomplete
              classes={{
                input: classes.smallfont,
                option: classes.smallfont,
              }}
              value={selectedClass}
              fullWidth
              onChange={(
                event: React.ChangeEvent<{}>,
                value: ClassTypeI | null
              ) => handleClassChange(value)}
              options={classSelector.classes}
              getOptionLabel={(option) => option.grade_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Grade"
                  name="class_name"
                  variant="outlined"
                />
              )}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            className={classes.formWrapper}
          >
            <InputLabel>Section </InputLabel>
            <Autocomplete
              classes={{
                input: classes.smallfont,
                option: classes.smallfont,
              }}
              value={selectedSection}
              fullWidth
              onChange={(
                event: React.ChangeEvent<{}>,
                value: SectionTypeI | null
              ) => handleSectionChange(value)}
              disabled={!Boolean(selectedClass?.section.length)}
              options={sections}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Section"
                  name="class_name"
                  variant="outlined"
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSearch()}
            startIcon={<Search />}
            style={{ margin: "10px 10px 0 0" }}
            disabled={searchDisabled}
          >
            Search
          </Button>
        </Grid>
      </FullWidthFormLayout>
    </>
  );
};

export default OptSubjectStudentForm;
