import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Typography } from "@material-ui/core";
import { RootStore } from "../../../../store";
import { useTable } from "../../../Reusable";
import { useTableStyles } from "../../../Styles/TableStyles";
import { HeadCellsI } from "../../../../actions";
import { RubricCategoryTypeI } from "../../../../actions/Academics/RubricCategory/RubricCategoryActionTypes";
import {
    ItemDeleteButton,
    ItemEditButton,
} from "../../../Reusable/Buttons/TableButton";
import CustomizedDialogs from "../../../Reusable/Dialogs/DeleteDialog";
import { GetRubricSubCategory, DeleteRubricSubCategory } from "../../../../actions/Academics/RubricCategory/RubricSubCategory/RubricSubCategoryAction";

// ---------------- <START> Interface ------------------//
interface RubricCategoryTableProps {
    onEditData: (value: RubricCategoryTypeI) => void;
    edit: { value: boolean };
    id: string | null;
}
// ---------------- <END> Interface ------------------//
// ---------------- <START> Head Cells ------------------//
const headCells: HeadCellsI[] = [
    { id: "sn", label: "S.N" },
    { id: "name", label: "Category" },
    { id: "action", label: "Action" },
];
// ---------------- <START> Head Cells ------------------//

const RubricSubCategoryTable = (props: RubricCategoryTableProps) => {
    const { onEditData, edit, id } = props;
    const classes = useTableStyles();
    const dispatch = useDispatch();
    const { StyledTableCell, StyledTableRow, TableContainer } =
        useTable(headCells);

    const [tableData, setTableData] = useState<RubricCategoryTypeI[]>([]);
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [itemId, setItemId] = useState<string | null>(null);
    const [editMode, setEditMode] = useState(false);

    const { loading, rubric_sub_categories } = useSelector((state: RootStore) => state.rubric_sub_category)

    useEffect(() => {
        id && dispatch(GetRubricSubCategory(id));
    }, [id]);
    useEffect(() => {
        edit && setEditMode(edit.value);
    }, [edit]);
    useEffect(() => {
        setTableData(rubric_sub_categories);
    }, [rubric_sub_categories]);

    //    Update Action
    const handleEditClicked = (data: RubricCategoryTypeI) => {
        onEditData(data);
        setEditMode(true);
    };

    // Delete Action
    const handleDeleteModal = (value: boolean, id: string) => {
        setItemId(id);
        setDeleteModalOpen(value);
    };
    const handleModalClose = (value: boolean) => {
        setDeleteModalOpen(value);
    };
    const onDeleteConfirmed = (confirmed: boolean) => {
        confirmed && itemId != null && dispatch(DeleteRubricSubCategory(itemId));
        setDeleteModalOpen(false);
    };

    const getCustomTableRow = (item: RubricCategoryTypeI, index: number) => {
        return (
            <StyledTableRow key={item.id}>
                <StyledTableCell align="center" className={classes.cell}>
                    {index}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.cell}>
                    {item.name}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.cell}>
                    <ItemEditButton onClick={() => handleEditClicked(item)} />
                    <ItemDeleteButton
                        disabled={editMode}
                        onClick={() => handleDeleteModal(true, item.id)}
                    />
                </StyledTableCell>
            </StyledTableRow>
        );
    };


    return (
        <Paper className={classes.root}>
            <Typography variant="h6">Rubric Category List</Typography>

            <TableContainer
                label="Rubric Sub-Category List"
                loading={loading}
                items={tableData}
                headCells={headCells}
                getCustomTableRow={getCustomTableRow}
                search_param="name"
            />
            <CustomizedDialogs
                open={deleteModalOpen}
                onClose={handleModalClose}
                deleteConfirmed={onDeleteConfirmed}
            />
        </Paper>
    )
}

export default RubricSubCategoryTable