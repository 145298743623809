// ---------------------------- module imports start ----------------------------------//

import {
  Button,
  Grid,
  InputLabel,
  Paper,
  TextField,
  LinearProgress,
  Switch,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import SaveIcon from '@material-ui/icons/Save';
import { useForm } from 'react-hook-form';
import { useStyles } from '../../Styles/FormStyles';
import { Autocomplete } from '@material-ui/lab';

import { RootStore } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAdmissionSettings,
  postAdmissionSettings,
  updateAdmissionSettings,
} from '../../../rtk/features/miscellaneous/admissionSettingsApi';
import ListView from './ListView';
import CancelIcon from '@material-ui/icons/Cancel';
import AddNewButton from '../../Reusable/Buttons/AddNewButton';

// ---------------------------- module imports ends ----------------------------------//

// ---------------------------- interface starts ----------------------------------//

interface InputFormI {
  admission_prefix: string;
  admission_number: string;
  admission_digit: number;
  auto_number: boolean;
}

interface DigitChoiceI {
  id: string;
  value: number;
}

// ---------------------------- interface ends ----------------------------------//

const digitChoices: DigitChoiceI[] = [
  { id: '4', value: 4 },
  { id: '5', value: 5 },
];

const AdmissionForm = () => {
  const classes = useStyles();

  // ---- States Declaration ------ //
  const [admissionDigit, setAdmissionDigit] = useState<{
    id: string;
    value: number;
  } | null>(null);

  const [formValues, setFormValues] = useState<InputFormI>({
    admission_prefix: '',
    admission_number: '',
    admission_digit: 4,
    auto_number: false,
  });

  const [values, setValues] = useState<any[]>([]);

  const [onEditMode, setOnEditMode] = useState<boolean>(false);

  const dispatch = useDispatch();

  const { loading, admissionSettings } = useSelector(
    (state: RootStore) => state.admissionSettings
  );

  useEffect(() => {
    dispatch(getAdmissionSettings());
  }, [dispatch]);

  useEffect(() => {
    if (admissionSettings) {
      setFormValues({
        ...formValues,
        admission_prefix: admissionSettings.prefix,
        admission_number: String(admissionSettings.number_start_from),
        admission_digit: admissionSettings.number_of_digit,
        auto_number: admissionSettings.auto_generate,
      });
      setValues([
        { name: 'Admission Prefix', value: admissionSettings.prefix },
        {
          name: 'Admission Number',
          value: String(admissionSettings.number_start_from),
        },
        { name: 'Admission Digit', value: admissionSettings.number_of_digit },
      ]);
      const dC = digitChoices.find(
        (item) => item.value === admissionSettings.number_of_digit
      );

      if (dC) {
        setAdmissionDigit(dC);
      } else {
        setAdmissionDigit(null);
      }
    }
  }, [admissionSettings]);

  const handleValueChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };

  const handleToggler = () => {
    setFormValues({
      ...formValues,
      auto_number: !formValues.auto_number,
    });
  };

  const { register, handleSubmit, errors, setError } = useForm<InputFormI>();

  const onSubmit = (data: InputFormI) => {
    let postData: {
      prefix: string;
      number_start_from: number;
      number_of_digit: number;
      auto_generate: boolean;
    } = {
      auto_generate: false,
      number_of_digit: 4,
      prefix: '',
      number_start_from: 1,
    };

    if (formValues.auto_number) {
      postData = {
        auto_generate: true,
        prefix: data.admission_prefix,
        number_of_digit: Number(formValues.admission_digit),
        number_start_from: Number(formValues.admission_number),
      };
    } else {
      if (data.admission_number.length > Number(data.admission_digit)) {
        setError('admission_number', {
          type: 'validate',
          message: 'Invalid admission starting number',
        });
      } else {
        postData = {
          auto_generate: false,
          prefix: data.admission_prefix,
          number_of_digit: Number(data.admission_digit),
          number_start_from: Number(data.admission_number),
        };
      }
    }

    if (admissionSettings?.id) {
      dispatch(
        updateAdmissionSettings({
          id: admissionSettings?.id || '',
          data: postData,
        })
      );
    } else {
      dispatch(postAdmissionSettings(postData));
    }
    setOnEditMode(false);
  };

  return (
    <>
      {onEditMode && (
        <Paper className={classes.pageContent}>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Grid item xs={12} className={classes.formTitle}>
                <span>Admission Settings</span>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={4} className={classes.formWrapper}>
                <InputLabel>
                  Admission Prefix <span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <TextField
                  variant="outlined"
                  name="admission_prefix"
                  placeholder="admission prefix"
                  inputRef={register({
                    required: 'Admission prefix is required',
                  })}
                  value={formValues.admission_prefix}
                  onChange={handleValueChange}
                />
                <span className={classes.validationErrorInfo}>
                  {errors.admission_prefix && errors.admission_prefix.message}
                </span>
              </Grid>

              <Grid item xs={8} />

              <Grid item xs={4} className={classes.formWrapper}>
                <InputLabel>Admission Number Auto Generation</InputLabel>
                <Grid container alignItems="center">
                  <Grid item>Disable</Grid>
                  <Grid item>
                    <Switch
                      checked={formValues.auto_number}
                      onChange={handleToggler}
                      name="autoGenerate"
                    />
                  </Grid>
                  <Grid item>Enable</Grid>
                </Grid>

                <span className={classes.validationErrorInfo} />
              </Grid>

              <Grid item xs={8} />

              <Grid item xs={4} className={classes.formWrapper}>
                <InputLabel>
                  Admission Number Digits{' '}
                  {!formValues.auto_number && (
                    <span style={{ color: 'red' }}>*</span>
                  )}
                </InputLabel>
                <Autocomplete
                  onChange={(
                    event: React.ChangeEvent<{}>,
                    value: DigitChoiceI | null
                  ) => setAdmissionDigit(value != null ? value : null)}
                  options={digitChoices}
                  getOptionLabel={(option) => option.id}
                  value={admissionDigit}
                  disabled={formValues.auto_number}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Admission Number Digits"
                      name="admission_digit"
                      variant="outlined"
                      inputRef={register({
                        required: {
                          value: !formValues.auto_number,
                          message: 'Admission number digit is required',
                        },
                      })}
                    />
                  )}
                />
                <span className={classes.validationErrorInfo}>
                  {errors.admission_digit && errors.admission_digit.message}
                </span>
              </Grid>

              <Grid item xs={4} className={classes.formWrapper}>
                <InputLabel>
                  Admission Number{' '}
                  {!formValues.auto_number && (
                    <span style={{ color: 'red' }}>*</span>
                  )}
                </InputLabel>
                <TextField
                  variant="outlined"
                  type="number"
                  name="admission_number"
                  placeholder="admission number starting from"
                  inputRef={register({
                    required: {
                      value: !formValues.auto_number,
                      message: 'Starting ddmission number is required',
                    },
                  })}
                  onChange={handleValueChange}
                  value={formValues.admission_number}
                  InputProps={{ inputProps: { min: 1, max: 1000000 } }}

                  disabled={formValues.auto_number}
                />
                <span className={classes.validationErrorInfo}>
                  {errors.admission_number && errors.admission_number.message}
                </span>
              </Grid>

              <Grid
                style={{ margin: '9px', gap: '1rem' }}
                container
                justifyContent="flex-end"
              >
                <AddNewButton
                  title="Cancel"
                  type="button"
                  color="primary"
                  variant="outlined"
                  startIcon={<CancelIcon />}
                  onClick={() => setOnEditMode(false)}
                >
                  Cancel
                </AddNewButton>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      )}
      {!onEditMode && (
        <ListView
          title="Admission Settings"
          setOnEditMode={setOnEditMode}
          data={values}
        />
      )}
    </>
  );
};

export default AdmissionForm;
