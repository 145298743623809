import axios from "axios";
import { Dispatch } from "redux";

import {
    GET_VOID_ERROR,
    GET_VOID_LOADING,
    GET_VOID_SUCCESS,
    VoidDispatchTypes,
} from "./VoidActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

export const GetVoids =
    (id: number, grade: string, section: string, start_date: string, end_date: string) =>
        async (dispatch: Dispatch<VoidDispatchTypes | SetSnackBarI>) => {
            try {
                dispatch({
                    type: GET_VOID_LOADING,
                });

                const res = await axios.get(
                    `${ADMIN_API_URL}/report/bill_adjustment/?page=${id}&grade=${grade}&section=${section}&from_date=${start_date}&to_date=${end_date}`,
                    HeaderConfig()
                );

                dispatch({
                    type: GET_VOID_SUCCESS,
                    payload: res.data,
                });
            } catch (error) {
                dispatch({
                    type: GET_VOID_ERROR,
                });
            }
        };