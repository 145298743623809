import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setSnackbar } from "../../../actions/SnackbarAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

export const getTermsConditions = createAsyncThunk(
  "getTermsConditions",
  async (undefined, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.get(`${ADMIN_API_URL}/tac`);
      return res.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      dispatch(
        setSnackbar(true, "error", "Failed to get terms and conditions")
      );
      return rejectWithValue(null);
    }
  }
);

export const updateTermsConditions = createAsyncThunk(
  "updateTermsConditions",
  async (data: any, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.post(`${ADMIN_API_URL}/tac/`, data);
      dispatch(setSnackbar(true, "success", "Terms and Conditions updated"));
      return res.data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      dispatch(
        setSnackbar(true, "error", "Failed to update terms and conditions")
      );
      return rejectWithValue(null);
    }
  }
);
