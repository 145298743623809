import React, { useEffect, useState } from "react";
import { makeStyles, Grid, Box } from "@material-ui/core";
import LeaveRequestTable from "../../components/Dashboard/LeaveRequest/LeaveRequestTable";
import FeeCollectionChart from "../../components/Dashboard/Chart/FeeCollectionChart";
import EventContainer from "../../components/Dashboard/Events/EventTable";
import UsersCard from "../../components/Dashboard/NumberCard/UsersCard";
import HeaderInfo from "../../components/Dashboard/HeaderInfo";
import CalendarEvents from "./Calendar/Calendar";
import { useDispatch, useSelector } from "react-redux";
import { GetDashboardData } from "../../actions/Dashboard/DashboardAction";
import { RootStore } from "../../store";
import styled from "styled-components";
import { GetEvents } from "../../actions/Event/Events/EventsAction";
import { GetEventTypes } from "../../actions/Event/EventType/EventTypeAction";

const Container = styled(Box)`
  padding: 1px 8px;
  background-color: #f2f3f6;
`;

const Dashboard = (props: any) => {
  const dispatch = useDispatch();
  const [reloaded, setReloaded] = useState(false);

  useEffect(() => {
    dispatch(GetDashboardData());
    const refresh_ = localStorage.getItem("refresh");
    if (refresh_ === "false") {
      localStorage.setItem("refresh", "true");
      window.location.reload();
    }
  }, []);

  const data = useSelector((state: RootStore) => state.dashboard);

  return (
    <Container>
      <div style={{ margin: "120px 14px" }}>
        <HeaderInfo />

        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <Grid container direction="column" spacing={2}>
              <Grid item md={12}>
                <UsersCard data={data} />
              </Grid>

              <Grid item md={12}>
                <CalendarEvents />
              </Grid>

              {/* <Grid item md={12}>
              <FeeCollectionChart />
            </Grid> */}
            </Grid>
          </Grid>

          <Grid item xs={12} md={5}>
            <Grid container direction="column" spacing={2}>
              <Grid item md={12}>
                <EventContainer data={data} />
              </Grid>

              <Grid item md={12}>
                <LeaveRequestTable data={data} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default Dashboard;
