import React from "react";
import { DisabledStudentList } from "../../components";
import StudentSidebar from "../../components/Student/StudentSidebar/StudentSidebar";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";
import { breadcrumb } from "./Student.Breadcrumbs";

const DisabledStudentListPage = () => {
  const nav = <StudentSidebar disabledStudent />;
  return (
    <SubModuleLayout module={breadcrumb} sideNav={nav}>
      <DisabledStudentList />
    </SubModuleLayout>
  );
};

export default DisabledStudentListPage;
