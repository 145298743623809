import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@material-ui/core";
import { useCardStyles } from "../../../../Styles/CardStyles";
import { dateConverterAdToBs } from "../../../../utils/dateConverter";
import CreateIcon from "@material-ui/icons/Create";
import styled from "styled-components";
import CustomizedPopUp from "../../../../Reusable/Dialogs/CustomizedPopUp";
import AcademicInfoModal from "./Forms/AcademicInfoModal";
import FacultyInfoModal from "./Forms/FacultyInfoModal";
import { useSelector } from "react-redux";
import { RootStore } from "../../../../../store";

const Wrapper = styled.div`
  position: relative;
`;

const IconHolder = styled.span`
  position: absolute;
  right: 48px;
  top: 0;

  color: #118fcf;
  cursor: pointer;

  & > svg {
    font-size: 32px;
    padding: 4px;
    border: 2px solid rgba(17, 143, 207, 1);
    border-radius: 6px;
    transition: all 0.2s ease;

    &:hover {
      opacity: 0.8;
    }
  }
`;

export default function FacultyInfo(props: any) {
  const classes = useCardStyles();
  const [staffFormOpen, toggleStaffForm] = useState<boolean>(false);

  const { loading, staffDetail } = useSelector(
    (state: RootStore) => state.humanResource
  );

  const rows = [
    createData(
      "Department:",
      staffDetail?.staff_faculty_info?.department.name || "-"
    ),
    createData(
      "Designation:",
      staffDetail?.staff_faculty_info?.designation.name || "-"
    ),
    createData(
      "Joining Date:",
      staffDetail?.staff_faculty_info?.date_of_joining || "-"
    ),
    createData("Level:", staffDetail?.staff_faculty_info?.level || "-"),
  ];

  function createData(name: string, infoData: string) {
    return { name, infoData };
  }

  const handleStaffModalClose = (value: boolean) => {
    toggleStaffForm(value);
  };

  const onRedirect = () => {
    //
  };

  return (
    <Wrapper>
      <IconHolder onClick={() => toggleStaffForm(true)}>
        <CreateIcon />
      </IconHolder>
      <Paper className={classes.infobar} elevation={0}>
        <TableContainer className={classes.roota}>
          <Table
            size="small"
            className={classes.table}
            aria-label="simple table"
          >
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.name}>
                  <TableCell
                    className={classes.table}
                    component="th"
                    scope="row"
                  >
                    {row.name}
                  </TableCell>
                  <TableCell align="left">{row.infoData}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <CustomizedPopUp
        title="Staff Faculty Info"
        open={staffFormOpen}
        onClose={handleStaffModalClose}
        actionConfirmed={onRedirect}
        dialogTitle="Staff Faculty Info Form"
        okText={false}
        cancel={false}
      >
        <FacultyInfoModal handleModalClose={handleStaffModalClose} />
      </CustomizedPopUp>
    </Wrapper>
  );
}
