import { Box, makeStyles, Theme, Tabs, Tab } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetOnlineClass } from "../../../actions/Classroom/OnlineClass/OnlineClassAction";
import { AdminOnlineClassTypeI } from "../../../actions/Classroom/OnlineClass/OnlineClassActionTypes";
import {
  GetStudentDashboardData,
  GetStudentDashboardDays,
} from "../../../actions/StudentDash/Dashboard/StudentDashboardAction";
import {
  GetTeacherDashboard,
  GetTeacherDashboardDays,
} from "../../../actions/TeacherDash/Dasbhoard/TeacherDashboardAction";
import { DashboardOnlineClassI } from "../../../actions/TeacherDash/Dasbhoard/TeacherDashboardActionTypes";
import TeacherLiveClassesWidget from "../../../dashboard/TeacherDashboard/components/Widget/TeacherLiveClassesWidget";
import { RootStore } from "../../../store";
import ClassRoomTable from "./ClassRoomTable";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ width: "100%" }}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: 300,
    "& .MuiButtonBase-root": {
      minWidth: "100% !important",
    },
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default function RegularClassTabs(props: any) {
  const { classItem, selectedSection } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);

  const onlineClassSelector = useSelector(
    (state: RootStore) => state.online_classes.online_class
  );

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(GetOnlineClass(classItem?.grade, selectedSection?.id, "7"));
  }, []);

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        indicatorColor="primary"
        textColor="primary"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        <Tab
          onClick={() =>
            dispatch(GetOnlineClass(classItem?.grade, selectedSection?.id, "7"))
          }
          label="Sunday"
          {...a11yProps(0)}
        />
        <Tab
          onClick={() =>
            dispatch(GetOnlineClass(classItem?.grade, selectedSection.id, "1"))
          }
          label="Monday"
          {...a11yProps(1)}
        />
        <Tab
          onClick={() =>
            dispatch(GetOnlineClass(classItem?.grade, selectedSection.id, "2"))
          }
          label="Tuesday"
          {...a11yProps(2)}
        />
        <Tab
          onClick={() =>
            dispatch(GetOnlineClass(classItem?.grade, selectedSection.id, "3"))
          }
          label="Wednesday"
          {...a11yProps(3)}
        />
        <Tab
          onClick={() =>
            dispatch(GetOnlineClass(classItem?.grade, selectedSection.id, "4"))
          }
          label="Thursday"
          {...a11yProps(4)}
        />
        <Tab
          onClick={() =>
            dispatch(GetOnlineClass(classItem?.grade, selectedSection.id, "5"))
          }
          label="Friday"
          {...a11yProps(5)}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <ClassRoomTable space onlineClass={onlineClassSelector} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ClassRoomTable space onlineClass={onlineClassSelector} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ClassRoomTable space onlineClass={onlineClassSelector} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ClassRoomTable space onlineClass={onlineClassSelector} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <ClassRoomTable space onlineClass={onlineClassSelector} />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <ClassRoomTable space onlineClass={onlineClassSelector} />
      </TabPanel>
    </div>
  );
}
