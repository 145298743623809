//---------------------------<START> import modules starts-----------------------//
import React, { useEffect, useState } from "react";
import { Grid, InputLabel, TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useFormStyles } from "../../../Styles/FormStyles";
import { Autocomplete } from "@material-ui/lab";
import "nepali-datepicker-reactjs/dist/index.css";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import { useDispatch, useSelector } from "react-redux";
import {
  AddAnnouncement,
  UpdateAnnouncement,
} from "../../../../actions/Communication/Announcement/AnnouncementAction";
import { AnnouncementTypeI } from "../../../../actions/Communication/Announcement/AnnouncementActionTypes";
import { AnnouncementTypeI as AnnouncementCategoryI } from "../../../../actions/Communication/AnnouncementType/AnnouncementTypeActionTypes";
import { TabToFormI } from "../../../../pages/Communication/AddAnnoucModalPage";
import Popups from "../../../Reusable/Dialogs/Popups";
import ConfirmationModalView from "./ConfirmationModal";
import {
  dateConverterAdToBs,
  dateConverterBsToAd,
} from "../../../utils/dateConverter";
import moment from "moment";
import { GetAnnouncementTypes } from "../../../../actions/Communication/AnnouncementType/AnnouncementTypeAction";
import { RootStore } from "../../../../store";
import BraftEditor from "braft-editor";

//-------------------------<END> import modules ends ----------------------------//

//--------------------<START> interface starts------------------------------------------------//
interface PropsI {
  submitClicked: TabToFormI | null;
  initData: AnnouncementTypeI | null;
  closeModal: (value: boolean) => void;
}

interface SelectPriorityI {
  id: number;
  value: string;
  title: string;
}

export interface TabDataI {
  id: number;
  group: number[];
  user: number[];
  grade: number | null;
  section: number[];
}

interface FormDataI {
  priority: string;
  announcement_type: string | null;
  title: string;
  announcement_date: string | any;
  description: string;
}

export interface PostDataI {
  priority: string;
  title: string;
  announcement_date: string;
  description: string;
  group: string[];
  user: string[];
  grade: string | null;
  section: string[];
  sent_to_parent: boolean;
  sent_to_student: boolean;
}

export interface ExtendedPostDataI {
  priority: string;
  title: string;
  announcement_date: string;
  description: string;
  group: string[];
  user: string[];
  grade: string | null;
  sent_to_parent: boolean;
  sent_to_student: boolean;
  gradeLabel: string;
  section: string[];
  sectionLabel: string[];
  groupLabel: string[];
  userLabel: string[];
}
//----------------<END> interface ends--------------------------------------------------//

const getKeyByValue = (obj: FormDataI, val: string) => {
  return Object.keys(obj).filter(
    (key) => obj[key] === val || obj[key] === null
  );
};

const today = moment(new Date()).format("YYYY-MM-DD");

//--------------------------<START> Add Annoucement Modal components starts---------------------------------------------------//
const AddAnnoucementModal = (props: PropsI) => {
  const { submitClicked, initData, closeModal } = props;
  const classes = useFormStyles();

  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [announcementType, setAnnouncementType] =
    useState<AnnouncementCategoryI | null>(null);
  const [confirmationData, setConfirmationData] = useState<PostDataI | null>(
    null
  );
  const [confirmationModalData, setConfirmationModalData] =
    useState<ExtendedPostDataI | null>(null);
  const [priority, setPriority] = useState<SelectPriorityI | null>(null);
  const [count, setCount] = useState(0);
  const [date, setDate] = useState<string | any>(dateConverterAdToBs(today));
  const [editRec, setEditRec] = useState<null | number>(null);
  const [formData, setFormData] = useState<FormDataI>({
    priority: "",
    announcement_type: null,
    title: "",
    announcement_date: "",
    description: "",
  });
  // Select choices
  const selectPriorityChoices: SelectPriorityI[] = [
    { id: 1, value: "L", title: "Low" },
    { id: 2, value: "N", title: "Normal" },
    { id: 3, value: "H", title: "High" },
  ];
  //-------------------<START> React hooks forms starts ----------------------//
  const dispatch = useDispatch();
  const { register, errors, setError, setValue, clearErrors } = useForm({
    mode: "onChange",
  });

  const announcementTypeSelector = useSelector(
    (state: RootStore) => state.announcement_type.announcement_types
  );
  const actionPerformed = useSelector(
    (state: RootStore) => state.announcement.actionPerformed
  );

  const handleFormChange = (
    field_name: string,
    field_value: any,
    priority_choice: SelectPriorityI | null,
    announcement_category: AnnouncementCategoryI | null
  ) => {
    if (field_name === "priority") {
      priority_choice
        ? clearErrors("priority")
        : setError("priority", {
            type: "required",
            message: "This field is required",
          });
    }
    field_name === "priority" && setPriority(priority_choice);
    field_name === "priority" &&
      setFormData({
        ...formData,
        priority: priority_choice ? priority_choice.value : "",
      });
    if (field_name === "announcement_type") {
      announcement_category
        ? clearErrors("announcement_type")
        : setError("announcement_type", {
            type: "required",
            message: "This field is required",
          });
    }
    field_name === "announcement_type" &&
      setAnnouncementType(announcement_category);
    field_name === "announcement_type" &&
      setFormData({
        ...formData,
        announcement_type: announcement_category
          ? announcement_category.id
          : "",
      });
    field_name === "title" && setFormData({ ...formData, title: field_value });
    field_name === "description" &&
      setFormData({ ...formData, description: field_value.toHTML() });
  };

  useEffect(() => {
    dispatch(GetAnnouncementTypes());
  }, []);

  useEffect(() => {
    if (actionPerformed) {
      handleReset();
    }
  }, [actionPerformed]);

  useEffect(() => {
    if (date) {
      setFormData({
        ...formData,
        announcement_date: dateConverterBsToAd(date),
      });
      clearErrors("announcement_date");
    }
  }, [date]);

  const handleConfirmModalClose = () => {
    setOpenConfirmModal(false);
  };

  const onActionConfirmed = (confirmed: boolean) => {
    confirmationData && dispatch(AddAnnouncement(confirmationData));
    setOpenConfirmModal(!confirmed);
  };

  const handleReset = () => {
    setValue("title", "");
    setValue("description", "");
    setValue("start_time", "");
    setValue("end_time", "");

    setFormData({
      ...formData,
      priority: "",
      announcement_type: null,
      title: "",
      description: "",
    });
    setPriority(null);
    setAnnouncementType(null);
    closeModal(false);
  };

  //------------------------<END> React Hooks form ends -------------------------//
  return (
    <>
      <div className={classes.annoucementContent}>
        <form className={classes.form}>
          <Grid container>
            <Grid item xs={4} className={classes.formWrapper}>
              <InputLabel>
                Priority <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <Autocomplete
                value={priority}
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: SelectPriorityI | null
                ) => handleFormChange("priority", "", value, null)}
                options={selectPriorityChoices}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Normal"
                    variant="outlined"
                  />
                )}
              />
              <span className={classes.validationErrorInfo}>
                {errors.priority && errors.priority.message}
              </span>
            </Grid>
            <Grid item xs={8}></Grid>
            <Grid item xs={4} className={classes.formWrapper}>
              <InputLabel>
                Announcement Category <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <Autocomplete
                value={announcementType}
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: AnnouncementCategoryI | null
                ) => handleFormChange("announcement_type", "", null, value)}
                options={announcementTypeSelector}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Normal"
                    variant="outlined"
                  />
                )}
              />
              <span className={classes.validationErrorInfo}>
                {errors.announcement_type && errors.announcement_type.message}
              </span>
            </Grid>
            <Grid item xs={8}></Grid>
            <Grid item xs={6} className={classes.formWrapper}>
              <InputLabel>
                Title <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <TextField
                variant="outlined"
                name="title"
                placeholder="Title"
                autoComplete="off"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleFormChange("title", event.target.value, null, null);
                }}
                inputRef={register({ required: "This field is required" })}
              />
              <span className={classes.validationErrorInfo}>
                {errors.title && errors.title.message}
              </span>
            </Grid>
            <Grid container spacing={1} item xs={12}>
              <Grid item xs={6} className={classes.formWrapper}>
                <InputLabel>
                  Date <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <NepaliDatePicker
                  className={classes.nepaliDateInput}
                  onChange={(data: string) => setDate(data)}
                  value={date}
                  options={{ calenderLocale: "en", valueLocale: "en" }}
                  inputClassName="inputFormControl"
                />
                <span className={classes.validationErrorInfo}>
                  {errors.announcement_date && errors.announcement_date.message}
                </span>
              </Grid>
            </Grid>

            <Grid item xs={12} className={classes.formWrapper}>
              <InputLabel>
                Description <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "24px",
                  border: "1px solid #ccc",
                }}
              >
                <BraftEditor
                  language="en"
                  // value={editorState}
                  onChange={(data: any) => {
                    handleFormChange("description", data, null, null);
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
      <Popups
        openPopup={openConfirmModal}
        setOpenPopup={setOpenConfirmModal}
        onClose={handleConfirmModalClose}
        fullWidth={true}
        maxWidth="lg"
        title="Confirm Announcement"
        colored
      >
        <ConfirmationModalView
          actionConfirmed={onActionConfirmed}
          data={confirmationModalData}
        />
      </Popups>
    </>
  );
};
//---------------------------<END> Add Annoucement Modal components end ---------------------------//
export default AddAnnoucementModal;
