import { Button, Grid, InputLabel, Paper, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ChangePassword } from "../../actions/Auth/Login/LoginAction";
import { setSnackbar } from "../../actions/SnackbarAction";
import errors from "../../reducers/errors";
import { RootStore } from "../../store";
import PasswordField from "../Reusable/Inputs/PasswordField";
import { useStyles } from "../Styles/FormStyles";

interface LoginFormI {
  new_password: string;
  confirm_password: string;
}

const ManagePasswordForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");

  const { register, handleSubmit, errors } = useForm<any>({
    mode: "onChange",
  });

  const authenticationSelector = useSelector(
    (state: RootStore) => state.auth.user
  );

  const onSubmit = (data: LoginFormI) => {
    if (password !== confirmedPassword) {
      dispatch(setSnackbar(true, "warning", "Passwords did not match"));
    } else {
      const post_data = {
        user: authenticationSelector.id,
        password1: password,
        password2: confirmedPassword,
      };
      console.log("final", post_data);
      dispatch(ChangePassword(post_data));
    }
  };

  return (
    <>
      <Paper className={classes.pageContent}>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={12} className={classes.formTitle}>
              <span>Change Password</span>
            </Grid>
          </Grid>
          {/* <Grid container spacing={3}>
            <Grid item xs={6}>
              <PasswordField
                name="old_password"
                label="Current Password"
                setValue={setOldPassword}
                autoComplate="off"
              />
            </Grid>
          </Grid> */}
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <PasswordField
                name="new_password"
                label="New Password"
                setValue={setPassword}
                autoComplate="off"
              />
            </Grid>

            <Grid item xs={6}>
              <PasswordField
                name="confirm_password"
                label="Confirm Password"
                setValue={setConfirmedPassword}
                autoComplate="off"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                style={{ padding: "12px 0 !important" }}
              >
                Proceed
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
};

export default ManagePasswordForm;
