export const GET_STUDENT_PROFILE_LOADING = "GET_STUDENT_PROFILE_LOADING";
export const GET_STUDENT_PROFILE_ERROR = "GET_STUDENT_PROFILE_ERROR";
export const GET_STUDENT_PROFILE_SUCCESS = "GET_STUDENT_PROFILE_SUCCESS";

export const GET_STUDENT_LEAVE_REQUEST_HISTORY_LOADING =
  "GET_STUDENT_LEAVE_REQUEST_HISTORY_LOADING";
export const GET_STUDENT_LEAVE_REQUEST_HISTORY_ERROR =
  "GET_STUDENT_LEAVE_REQUEST_HISTORY_ERROR";
export const GET_STUDENT_LEAVE_REQUEST_HISTORY_SUCCESS =
  "GET_STUDENT_LEAVE_REQUEST_HISTORY_SUCCESS";

export const SEND_USER_CREDENTIALS_LOADING = "SEND_USER_CREDENTIALS_LOADING";
export const SEND_USER_CREDENTIALS_ERROR = "SEND_USER_CREDENTIALS_ERROR";
export const SEND_USER_CREDENTIALS_SUCCESS = "SEND_USER_CREDENTIALS_SUCCESS";

//===============================<START>GET STUDENT PROFILE<START>======================//
export interface GetStudentProfileLoading {
  type: typeof GET_STUDENT_PROFILE_LOADING;
}

export interface GetStudentProfileError {
  type: typeof GET_STUDENT_PROFILE_ERROR;
}

export interface GetStudentProfileSuccess {
  type: typeof GET_STUDENT_PROFILE_SUCCESS;
  payload: any;
}

//===============================<END>GET STUDENT PROFILE<END>======================//

//===============================<START>GET student Leave Request History<START>======================//

export interface GetStudentLeaveRequestHistoryLoading {
  type: typeof GET_STUDENT_LEAVE_REQUEST_HISTORY_LOADING;
}

export interface GetStudentLeaveRequestHistoryError {
  type: typeof GET_STUDENT_LEAVE_REQUEST_HISTORY_ERROR;
}

export interface GetStudentLeaveRequestHistorySuccess {
  type: typeof GET_STUDENT_LEAVE_REQUEST_HISTORY_SUCCESS;
  payload: any;
}

//===============================<END>GET student Leave Request History<END>======================//

//===============================<START>SEND USER CREDENTIALS<START>======================//
export interface SendUserCredentialsLoading {
  type: typeof SEND_USER_CREDENTIALS_LOADING;
}

export interface SendUserCredentialsError {
  type: typeof SEND_USER_CREDENTIALS_ERROR;
}

export interface SendUserCredentialsSuccess {
  type: typeof SEND_USER_CREDENTIALS_SUCCESS;
  payload: any;
}

//===============================<END>SEND USER CREDENTIALS<END>======================//

export type StudentProfileDispatchTypes =
  | GetStudentProfileLoading
  | GetStudentProfileError
  | GetStudentProfileSuccess
  | GetStudentLeaveRequestHistoryLoading
  | GetStudentLeaveRequestHistoryError
  | GetStudentLeaveRequestHistorySuccess
  | SendUserCredentialsLoading
  | SendUserCredentialsError
  | SendUserCredentialsSuccess;
