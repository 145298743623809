import React from 'react';
import {
  makeStyles,
  Button,
  Tooltip,
  IconButton,
  Grid,
} from '@material-ui/core';
import { Search, SettingsBackupRestore } from '@material-ui/icons';
import { useFormStyles } from '../../Styles/FormStyles';
import FormButton from './FormButton';
import AddToHomeScreenIcon from '@material-ui/icons/AddToHomeScreen';

const SearchButton = (props: any) => {
  const classes = useFormStyles();
  return (
    <FormButton
      color="primary"
      className={classes.searchBoxButton}
      startIcon={<Search />}
      {...props}
    >
      Search
    </FormButton>
  );
};

const SubmitButton = (props: any) => {
  const classes = useFormStyles();
  return (
    <Grid item className={classes.formWrapperRow}>
      <FormButton
        className={classes.submitButton}
        type="submit"
        startIcon={props.icon ? props.icon : <Search />}
        {...props}
      >
        {props.title ? props.title : 'Search'}
      </FormButton>
    </Grid>
  );
};

const SendButton = (props: any) => {
  const classes = useFormStyles();
  return (
    <Grid item className={classes.formWrapperRow}>
      <FormButton
        className={classes.submitButton}
        startIcon={props.icon ? props.icon : <AddToHomeScreenIcon />}
        {...props}
      >
        {props.title ? props.title : 'Send'}
      </FormButton>
    </Grid>
  );
};

const ResetButton = (props: any) => {
  const classes = useFormStyles();
  return (
    <Grid
      style={{ maxWidth: '5%' }}
      item
      xs={1}
      className={classes.formWrapperRow}
    >
      <Tooltip title="Clear Search Query">
        <IconButton
          aria-label="delete"
          className={classes.resetButton}
          {...props}
        >
          <SettingsBackupRestore />
        </IconButton>
      </Tooltip>
    </Grid>
  );
};

export default SearchButton;
export { SubmitButton, ResetButton, SendButton };
