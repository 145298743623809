// ---------------------------- <START> module imports start ----------------------------------//
import React, { useState, useEffect } from "react";
import {
  Paper,
  TableBody,
  TextField,
  Grid,
  Typography,
  TableRow,
  Button,
  LinearProgress,
} from "@material-ui/core";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import { useTable } from "../../Reusable";
import { useTableStyles } from "../../Styles/TableStyles";
import CustomizedPopUp from "../../Reusable/Dialogs/CustomizedPopUp";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../../actions/SnackbarAction";
import ActionButton from "../../Reusable/Buttons/ActionButton";
import {
  DeleteSession,
  GetSessions,
  UpdateSessionStatus,
} from "../../../actions/GeneralSettings/Session/SessionAction";
import { RootStore } from "../../../store";
import { SessionTypeI } from "../../../actions/GeneralSettings/Session/SessionActionTypes";
import { dateConverterAdToBs } from "../../utils/dateConverter";
// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//
interface PropsI {
  onEditData: (value: SessionTypeI) => void;
}

interface HeadCellsI {
  id: string;
  label: string;
}
// ---------------------------- <END> interface ends ----------------------------------//

// ---------------------------- <START> styling starts ----------------------------------//

// ---------------------------- <END> styling ends ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: any = [
  {
    id: "name",
    label: "Session Name",
    children: [
      { id: "session_from", label: "From" },
      { id: "session_to", label: "To" },
    ],
  },
  { id: "session_from", label: "From" },
  { id: "session_to", label: "To" },
  // { id: "action", label: "Action" },
  { id: "session_active", label: "Status" },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const SessionTable = (props: PropsI) => {
  const { onEditData } = props;
  const classes = useTableStyles();
  const dispatch = useDispatch();

  // States declaration
  const [loading, setLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [statusModalOpen, setStatusModalOpen] = useState<boolean>(false);

  const [tableData, setTableData] = useState<SessionTypeI[]>([]);
  const [tempTableData, setTempTableData] = useState<SessionTypeI[]>([]);
  const [recordID, setRecordID] = useState<string | null>(null);
  const [hoveredRow, setHoveredRow] = useState<string | null>(null);

  const sessionSelector = useSelector((state: RootStore) => state.session);

  useEffect(() => {
    dispatch(GetSessions());
  }, []);

  useEffect(() => {
    setLoading(sessionSelector.loading);

    setTableData(sessionSelector.sessions);
    setTempTableData(sessionSelector.sessions);
  }, [sessionSelector]);

  // Retrieving re-usable components from useTable
  const {
    TblContainer,
    TblHead,
    StyledTableCell,
    StyledTableRow,
    TableContainer,
  } = useTable(headCells);

  // Event function for table searching by name
  const handleTableSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const search_data = tempTableData.filter((data) =>
      data.session_name.toUpperCase().includes(e.target.value.toUpperCase())
    );
    setTableData(search_data);
  };

  // Function for handling action icons click event
  const handleModalClose = (val: boolean) => {
    setModalOpen(val);
    setRecordID(null);
  };

  const handleStatusModalClose = (val: boolean) => {
    setStatusModalOpen(val);
    setRecordID(null);
  };

  const handleRowHover = (id: string) => {
    setHoveredRow(id);
  };

  const activateSession = (id: string) => {
    setRecordID(id);
    setStatusModalOpen(true);
  };

  const handleActivateSession = () => {
    recordID && dispatch(UpdateSessionStatus(recordID));

    setStatusModalOpen(false);
    setRecordID(null);
  };

  const handleEditClicked = (data: SessionTypeI) => {
    onEditData(data);
  };

  const handleDeleteClicked = (session_id: string) => {
    setRecordID(session_id);
    setModalOpen(true);
  };

  const handleDeleteSession = () => {
    recordID != null && dispatch(DeleteSession(recordID));
    setModalOpen(false);
    setRecordID(null);
  };

  const getCustomTableRow = (item: SessionTypeI) => {
    return (
      <TableRow
        key={item.id}
        onMouseOver={(
          event: React.MouseEvent<HTMLTableRowElement, MouseEvent>
        ) => handleRowHover(item.id)}
        onMouseOut={(
          event: React.MouseEvent<HTMLTableRowElement, MouseEvent>
        ) => setHoveredRow(null)}
      >
        <StyledTableCell align="center" className={classes.cell}>
          {item.name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {dateConverterAdToBs(item.from_date)}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {dateConverterAdToBs(item.to_date)}
        </StyledTableCell>
        {/* <StyledTableCell align="center" className={classes.cell}> */}
        {/* <ActionButton title="Edit" onClick={handleActionClicked}>
          <EditOutlinedIcon className={classes.editIcon} />
        </ActionButton>

        <ActionButton title="Delete" onClick={handleActionClicked}>
          <DeleteForeverOutlinedIcon
            className={classes.deleteIcon}
          />
        </ActionButton> */}
        {/* <a
            className={classes.Btn}
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleEditClicked(item)
            }
          >
            <EditOutlinedIcon className={classes.editIcon} />
          </a> */}
        {/* <a
            className={classes.Btn}
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleDeleteClicked(item.id)
            }
          >
            <DeleteForeverOutlinedIcon className={classes.deleteIcon} />
          </a> */}
        {/* </StyledTableCell> */}
        <StyledTableCell
          align="center"
          style={{ width: "200px !important" }}
          className={classes.cell}
        >
          {item.status ? (
            <span className={classes.activeSession}>Active</span>
          ) : (
            item.id === hoveredRow && (
              <Button
                color="primary"
                variant="outlined"
                size="small"
                onClick={(
                  event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => activateSession(item.id)}
              >
                Activate
              </Button>
            )
          )}
        </StyledTableCell>
      </TableRow>
    );
  };

  return (
    <>
      <Paper className={classes.root}>
        <Typography variant="h6">Session List</Typography>
        <TableContainer
          loading={loading}
          headCells={headCells}
          items={tableData}
          getCustomTableRow={getCustomTableRow}
          hiddenCols={[4, 5]}
        />
      </Paper>
      <CustomizedPopUp
        title="Activate Session"
        open={statusModalOpen}
        onClose={handleStatusModalClose}
        actionConfirmed={handleActivateSession}
        dialogTitle="Are you sure to deactivate current session and activate this session?"
        okText="Confirm"
      />
      <CustomizedPopUp
        title="Delete Session"
        open={modalOpen}
        onClose={handleModalClose}
        actionConfirmed={handleDeleteSession}
        dialogTitle="Are you sure to delete this session?"
        okText="Delete"
      />
    </>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default SessionTable;
