import React, { useState, useEffect } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Grid, TextField, InputLabel } from '@material-ui/core';
import { useStyles } from '../../Styles/FormStyles';
import { FormCheckBox } from '../../Reusable/Inputs/Checkbox';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../store';

interface PropsI {
    open: boolean;
    fee: any;
    month: any;
    onClose: (value: boolean) => void;
    deleteConfirmed: (value: string, sms: boolean, noti: boolean) => void;
    dialogTitle?: string;
    okText?: string;
    title?: string;
}

const useStylesComponent = makeStyles((theme: Theme) =>
    createStyles({
        modalHeader: {
            padding: theme.spacing(1, 3),
            color: '#fff',
            backgroundColor: '#5767a4',
            '& .MuiTypography-body1': {},
        },
        modalContainer: {
            padding: theme.spacing(3, 1),
        },
        modalFooter: {
            padding: theme.spacing(0, 0, 4, 0),
            '& .MuiButton-contained': {
                color: '#fff',
                backgroundColor: '#1e4396',
            },
        },
    })
);

const DueAlertDialog = (props: PropsI) => {
    const classes = useStylesComponent();
    const formClasses = useStyles()
    const { open, onClose, deleteConfirmed, fee, month } = props;
    const [defaultValue, setDefaultValue] = useState<string>("")
    const [sms, setSms] = useState<boolean>(false)
    const [pushNoti, setPushNoti] = useState<boolean>(false)

    const dueReportState = useSelector((state: RootStore) => state.due_report);


    const handleClose = () => {
        onClose(false);
    };

    const handleDeleteConfirmed = () => {
        deleteConfirmed(defaultValue, sms, pushNoti);
    };

    const handleAlertValueChange = (value: string) => {
        setDefaultValue(value)
    }

    const handleSmsChange = () => {
        setSms(!sms)
    }

    const handlePushNotiChange = () => {
        setPushNoti(!pushNoti)
    }

    useEffect(() => {
        setDefaultValue(`You have a due of Rs. ${dueReportState?.due_alert?.grade_fee_amount?.amount} regarding the fee ${fee?.title}`)
    }, [fee, month])

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <div style={{ minWidth: '350px' }}>
                <Grid
                    container
                    className={classes.modalHeader}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography>{props.title ? props.title : 'Send Due Alert(s)'}</Typography>
                    <IconButton onClick={handleClose}>
                        <CloseIcon style={{ color: '#fff' }} />
                    </IconButton>
                </Grid>
                <Grid
                    container
                    className={classes.modalContainer}
                    justifyContent="center"
                >
                    <Typography style={{ padding: '10px 30px' }}>
                        {month !== null ?
                            `Send the Due Alert for a Fee ${fee?.title} for a Month of ${month?.value}`
                            :
                            `Send the Due Alert for a Fee ${fee?.title}`}
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{ padding: '10px 30px' }}>

                    <InputLabel style={{ paddingBottom: '10px' }}>Alert Style</InputLabel>
                    <TextField
                        variant="outlined"
                        multiline
                        rows={3}
                        defaultValue={defaultValue}
                        fullWidth
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleAlertValueChange(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} style={{ padding: '10px 30px' }}>
                    <FormCheckBox
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                            checked: boolean
                        ) => setSms(!sms)}
                        checked={sms}
                        name="sms"
                        label="SMS Notification"
                    />
                    <FormCheckBox
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                            checked: boolean
                        ) => setPushNoti(!pushNoti)}
                        checked={pushNoti}
                        name="push"
                        label="Push Notification"
                    />
                </Grid>
                <Grid
                    container
                    className={classes.modalFooter}
                    justifyContent="space-around"
                >
                    <Button onClick={handleClose} variant="outlined" color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteConfirmed} variant="contained" color='primary'>
                        {props.okText ? props.okText : 'Send'}
                    </Button>
                </Grid>
            </div>
        </Dialog>
    );
};

export default DueAlertDialog;
