import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import SubModuleLayout from '../../components/Reusable/Layouts/SubModuleLayout';
import VehiclesForm from '../../components/Transport/Vehicles/VehiclesForm';
import VehiclesList from '../../components/Transport/Vehicles/VehiclesList';
import TransportSidebar from '../../components/Transport/TransportSidebar';
import { VehicleTypeI } from '../../actions/Transport/Vehicles/VehiclesActionTypes';

/*
 * Convert all vehicle name to bus detail as much as possible
 * Bus - Bus No., Brand, Model, Manufactured Year, Max Speed, Seat Capacity
 *
 * ADD BUS
 * GET BUS
 * UPDATE BUS
 * DELETE BUS
 * SEARCH BUS
 */

const VehiclePage = () => {
  const nav = <TransportSidebar vehicles />;

  const [formData, setFormData] = useState<VehicleTypeI | null>(null);
  const [edit, setEdit] = useState<any>();

  const fetchEditData = (data: VehicleTypeI) => {
    setFormData({ ...data });
  };

  const onEditCheck = (data: any) => {
    console.log('OnEditCheck', data);
    setEdit({ check: data });
  };

  return (
    <SubModuleLayout sideNav={nav}>
      <Grid container>
        <Grid item xs={3}>
          <VehiclesForm onEditCheck={onEditCheck} editData={formData} />
        </Grid>
        <Grid item xs={9}>
          <VehiclesList edit={edit} onEditData={fetchEditData} />
        </Grid>
      </Grid>
    </SubModuleLayout>
  );
};

export default VehiclePage;
