import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
    CreateFeeType,
    UpdateFeeType,
} from "../../../actions/Fees/FeeType/FeeTypeAction";
import { Fee_TypeI } from "../../../actions/Fees/FeeType/FeeTypeActionTypes";
import { MONTH_CHOICES } from "../../../common/json.constant";
import { FormCheckBox } from "../../Reusable/Inputs/Checkbox";
import CustomizedNepaliDatePicker from "../../Reusable/Inputs/NepaliDatePicker";
import CustomizedSelect from "../../Reusable/Inputs/Select";
import CustomizedTextField from "../../Reusable/Inputs/TextField";
import { useFormStyles } from "../../Styles/FormStyles";
import { CUR_NEPALI_DATE } from "../../utils/nepaliDateUtils";
import FormLayout from "../../Reusable/Layouts/Form.Layout";
import { CusRadioGroup } from "../../Reusable/Inputs/Radio";
import { RootStore } from "../../../store";
import { ActionRoleAccess } from "../../utils/rolesConfig";
import { Checkbox, InputLabel, TextField } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { Autocomplete } from "@material-ui/lab";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { CreateMiscFeeType, UpdateMiscFeeType } from "../../../actions/Fees/MiscFee/MiscFeeTypeAction";

interface EditValuesI {
    id: string;
    name: string;
    fee_type: string;
    description: string;
    billing_period: string;
    due_date: string | null;
    due_day: number | null;
    due_period_type: string | null;
    count: number;
}
interface PropsI {
    editData: EditValuesI | null;
    onEditCheck: (value: boolean) => void;
    setGrade: any;
}

interface DaySelectI {
    key: string;
    value: string;
}

const MiscFeeForm = (props: PropsI) => {
    const classes = useFormStyles();
    const { editData, onEditCheck, setGrade } = props;

    const [editMode, setEditMode] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [editID, setEditID] = useState<string | null>(null);

    const [selectedDate, setSelectedDate] = useState<DaySelectI | null>(null);
    const [date, setDate] = useState<string>(CUR_NEPALI_DATE);
    const [selectedRadioValue, setSelectedRadioValue] = useState<number>(1);
    const [isMonthly, setIsMonthly] = useState<boolean>(false);
    const [roleChoices, setRoleChoices] = useState<any>([]);
    const [selectedRoleValues, setSelectedRoleValues] = useState<any>(
        []
    );
    const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
    const [forAll, setForAll] = useState<boolean>(false)
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const [gradeChoices, setGradeChoices] = useState<any | null>([]);
    const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);

    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        errors,
        setError,
        clearErrors,
    } = useForm<any>({
        mode: "onChange",
    });

    const feeTypeActionSelector = useSelector(
        (state: RootStore) => state.fee_type.action_performed
    );

    const gradeList = useSelector((state: RootStore) => state.class.classes);


    const authState = useSelector((state: RootStore) => state.auth);
    const add_or_update = useSelector((state: RootStore) => state.misc_fee_type.add_or_update)

    useEffect(() => {
        if (add_or_update) {
            setIsBtnLoading(false)
            handleReset();
        }
    }, [add_or_update])

    useEffect(() => {
        gradeList && setGradeChoices(gradeList);
    }, [gradeList]);

    useEffect(() => {
        editData != null && handleEditTableData(editData);
    }, [editData]);

    useEffect(() => {
        if (feeTypeActionSelector) {
            handleReset();
        }
    }, [feeTypeActionSelector]);

    const resetData = () => {
        setGrade(null)
        reset();
        setForAll(false);
        setSelectedRoleValues([])
    }

    const onFormSubmit = (data: any) => {
        setIsBtnLoading(true);
        const ids: string[] = [];

        selectedRoleValues.map((element: any) => {
            ids.push(element.grade);
        });
        const post_request = {
            name: data.name,
            amount: parseInt(data.amount),
            grades: forAll ? [] : ids,
            apply_to_all_grades: forAll
        };

        console.log({ post_request })
        // editID != null
        //     ? dispatch(UpdateMiscFeeType(editID, post_request))
        dispatch(CreateMiscFeeType(post_request, resetData()));
    };

    const DAY_CHOICES = MONTH_CHOICES
    const DUE_DAY_CHOICES = [
        { key: "1", value: "1" },
        { key: "2", value: "2" },
        { key: "3", value: "3" },
        { key: "4", value: "4" },
        { key: "5", value: "5" },
        { key: "6", value: "6" },
        { key: "7", value: "7" },
    ]
    console.log({ gradeChoices })

    useEffect(() => {
        const data = gradeChoices;
        if (data.length) {
            const mod: any = [];

            data.map((element: any) => {
                mod.push({
                    ...element,
                    selected: false,
                });
            });

            setRoleChoices(mod);
        }
    }, [gradeChoices]);

    console.log({ roleChoices })


    const dueTypeList = [
        { id: 1, name: "Prior" },
        { id: 2, name: "After" },
    ];

    const handleReset = () => {
        reset();
        onEditCheck(false);
        setEditID(null);
        setEditMode(false);
        setIsMonthly(false);
        setSelectedDate(null);
        setSelectedRadioValue(1);
    };

    const handleEditTableData = (data: EditValuesI) => {
        console.log('entered.', data)
        setEditMode(true);
        setEditID(data.id);
        const tempBillPeriod: any = data.billing_period
        const billPeriod = tempBillPeriod.join().split(',')

        const selected__months = DAY_CHOICES.filter((elem) => billPeriod.includes(elem.key))
        console.log({ selected__months })
        const add__selected: any = [];
        selected__months.map((elem: any) => add__selected.push({
            ...elem, selected: true
        }))
        console.log({ add__selected })
        setSelectedRoleValues(add__selected)
        setValue("name", data.name);
        setValue("description", data.description);
        data.due_date && setDate(data.due_date);
        setIsMonthly(data.fee_type === 'M' ? true : false);
        data.due_period_type &&
            setSelectedRadioValue(data.due_period_type === "P" ? 1 : 2);

        if (data.due_day) {
            const val = DUE_DAY_CHOICES.find((item: any) => item.key == data.due_day);

            if (val) {
                setSelectedDate(val);
            }
        }
    };

    const handleDate = (value: any) => {
        setDate(value);
        clearErrors("due_date");
    };

    const onRolesChange = (data: any) => {
        const selected: string[] = [];

        data.map((element: any) => selected.push(element.grade));

        const currentOptions: any = roleChoices;
        currentOptions.map((element: any) => {
            if (selected.includes(element.grade)) {
                element.selected = true;
            } else {
                element.selected = false;
            }
        });
        setRoleChoices(currentOptions);

        const selectedVal = currentOptions.filter(
            (element: any) => element.selected === true
        );

        console.log({ selectedVal })

        setSelectedRoleValues(selectedVal);

        // const ids: string[] = [];

        // selectedVal.map((element: any) => {
        //   ids.push(element.key);
        // });

        setSelectedRoles(selectedVal);
    };

    console.log({ selectedRoles })


    return (
        <>
            <FormLayout
                title={editMode ? "Edit Miscellaneous Type" : "Add Miscellaneous Fee"}
                onSubmit={handleSubmit(onFormSubmit)}
                editMode={editMode}
                loading={loading}
                onClick={!isBtnLoading && handleReset}
                add_or_update={isBtnLoading}
            >
                <CustomizedTextField
                    label="Fee Name"
                    placeholder="Fee Name"
                    name="name"
                    required
                    error={errors["name"] ? "This Field is Required" : ""}
                    inputRef={register({ required: true })}
                />
                <CustomizedTextField
                    label="Amount"
                    placeholder="amount"
                    name="amount"
                    required
                    type="number"
                    error={errors["amount"] ? "This Field is Required" : ""}
                    inputRef={register({ required: true })}
                />
                <FormCheckBox
                    onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                        checked: boolean
                    ) => setForAll(!forAll)}
                    checked={forAll}
                    name="for_all"
                    label="Applied For All Class"
                />
                {!forAll && <><InputLabel style={{ marginTop: '15px' }}>
                    Class <span style={{ color: "red" }} >*</span>
                </InputLabel>
                    <Autocomplete
                        value={selectedRoleValues}
                        multiple
                        id="checkbox-select-roles"
                        options={roleChoices}
                        disableCloseOnSelect
                        onChange={(
                            event: React.ChangeEvent<{}>,
                            value: any
                        ) => onRolesChange(value)}
                        getOptionLabel={(option: any) => option?.grade_name}
                        renderOption={(option: any, { selected: boolean }) => (
                            <React.Fragment>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={option.selected}
                                />
                                {option.grade_name}
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Class(s)"
                            />
                        )}
                    />
                </>}
                {/* <InputLabel>
                    Section <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <Autocomplete
                    value={selectedRoleValues}
                    multiple
                    id="checkbox-select-roles"
                    options={roleChoices}
                    disableCloseOnSelect
                    onChange={(
                        event: React.ChangeEvent<{}>,
                        value: any
                    ) => onRolesChange(value)}
                    getOptionLabel={(option: any) => option?.value}
                    renderOption={(option: any, { selected: boolean }) => (
                        <React.Fragment>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={option.selected}
                            />
                            {option.value}
                        </React.Fragment>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Month(s)"
                        />
                    )}
                /> */}
            </FormLayout>
        </>
    );
};

export default MiscFeeForm;