import React, { useState, useEffect, useRef } from "react";

import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../../actions/SnackbarAction";
import { useFormStyles } from "../../Styles/FormStyles";
import { ExamTypeI } from "../../../actions/Examination/Exam/ExamActionTypes";
import {
  AddExam,
  UpdateExam,
} from "../../../actions/Examination/Exam/ExamAction";
import { RootStore } from "../../../store";
import FormLayout from "../../Reusable/Layouts/Form.Layout";
import CustomizedTextField from "../../Reusable/Inputs/TextField";
import CustomizedNepaliDatePicker from "../../Reusable/Inputs/NepaliDatePicker";
import {
  dateConverterAdToBs,
  dateConverterBsToAd,
} from "../../utils/dateConverter";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import moment from "moment";
import { CUR_NEPALI_DATE } from "../../utils/nepaliDateUtils";
import { FormCheckBox } from "../../Reusable/Inputs/Checkbox";
import { Checkbox, FormControlLabel, InputLabel } from "@material-ui/core";
import CheckBox from "@material-ui/icons/CheckBox";

//= =============================<START>  Interface <START>=================================//

interface PropsI {
  editData: ExamTypeI | null;
  onEditCheck: (value: boolean) => void;
}

interface FormDataI {
  exam_name: string;
  weightage: string;
  note: string;
  start_date: string;
  end_date: string;
  result_publish_date: string;
  result_publish_time: string | null;
}
//= =============================<END> Interface <END>=================================//

const SectionForm = (props: PropsI) => {
  const [startDate, setStartDate] = useState<string>(CUR_NEPALI_DATE);
  const [endDate, setEndDate] = useState<string>(CUR_NEPALI_DATE);
  const { editData, onEditCheck } = props;
  const classes = useFormStyles();
  const [publishDate, setPublishDate] = useState<string>(CUR_NEPALI_DATE);
  const [has_weightage, setHas_weightage] = useState<boolean>(false);
  const [weightage, setWeightage] = useState<string>("");

  const today = moment(new Date()).format("YYYY-MM-DD");

  //= =============================<START> Component States <START>=================================//
  const [loading, setLoading] = useState<boolean>(false);
  const [editID, setEditID] = useState<string | null>(null);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);

  //= =============================<END> Component States <END>=================================//

  //= =============================<START> Component States <START>=================================//
  //= =============================<END> Component States <END>=================================//
  const dispatch = useDispatch();
  const examLoading = useSelector((state: RootStore) => state.exam.loading);
  const add_or_update = useSelector(
    (state: RootStore) => state.exam.add_or_update
  );

  // Update component prop each time new data is sent
  useEffect(() => {
    editData != null && handleEditTableData(editData);
  }, [editData]);

  useEffect(() => {
    setLoading(examLoading);
  }, [examLoading]);

  useEffect(() => {
    if (add_or_update) {
      setIsBtnLoading(false);
      handleReset();
    }
  }, [add_or_update]);
  //= =============================<START> Event Handlers <START>=================================//
  const re = /^[0-9]*\.?[0-9]*$/;
  // using react hook form
  const {
    register,
    handleSubmit,
    errors,
    setError,
    clearErrors,
    setValue,
    reset,
  } = useForm<FormDataI>({
    mode: "onChange",
    // resolver: yupResolver(schema),
  });

  const onFormSubmit = (data: FormDataI) => {
    if (has_weightage && weightage === "") {
      setError("weightage", { type: "required" });
      return;
    }
    setIsBtnLoading(true);

    if (editMode == true && editID != null) {
      const submission_data = {
        ...data,
        start_date: startDate ? dateConverterBsToAd(startDate) : today,
        end_date: endDate ? dateConverterBsToAd(endDate) : today,
        result_publish_date: publishDate
          ? dateConverterBsToAd(publishDate)
          : endDate || today,
        has_weightage: has_weightage,
        weightage: weightage || null,
      };
      dispatch(UpdateExam(editID, submission_data));
    } else if (editMode == false) {
      const submission_data = {
        ...data,
        start_date: startDate ? dateConverterBsToAd(startDate) : today,
        end_date: endDate ? dateConverterBsToAd(endDate) : today,
      };
      dispatch(AddExam(submission_data));
    }
  };

  const handleEditTableData = (data: ExamTypeI) => {
    setEditMode(true);
    setEditID(data.id);
    setValue("exam_name", data.exam_name);
    setStartDate(dateConverterAdToBs(data.start_date));
    setEndDate(dateConverterAdToBs(data.end_date));
    setHas_weightage(data.has_weightage);
    setWeightage(data.weightage ? String(data.weightage) : "");
  };

  const handleStartDate = (data: any) => {
    clearErrors("start_date");
    setStartDate(data);
  };

  const handleEndDate = (data: any) => {
    clearErrors("end_date");
    setEndDate(data);
  };

  const handleReset = () => {
    reset();
    setStartDate(CUR_NEPALI_DATE);
    setEndDate(CUR_NEPALI_DATE);
    setEditMode(false);
    setEditID(null);
    onEditCheck(false);
    setHas_weightage(false);
  };

  //= =============================<END> Event Handlers <END>=================================//

  //--------------------------ERROR HANDLING-------------------------------//

  const examSelector = useSelector((state: RootStore) => state.exam);
  const errorsData = examSelector.errors;
  const initialErrorsData = useRef(errorsData);
  const [serverErrors, setServerErrors] = useState<any>(null);

  useEffect(() => {
    if (initialErrorsData.current === errorsData) {
      initialErrorsData.current = errorsData; // Do not set initial errors
    } else {
      if (errorsData != null && errorsData?.error) {
        const keys = Object.keys(errorsData.error);
        keys.map((elem: any) => {
          setError(elem, {
            type: "serverSideError",
            message: errorsData.error[elem] && errorsData.error[elem][0],
          });
        });
      }
      setServerErrors(errorsData);
    }
  }, [errorsData]);

  useEffect(() => {
    if (examSelector.recent == true) {
      handleReset();
    }
  }, [examSelector]);

  return (
    <>
      <FormLayout
        title={editMode ? "Edit Exam" : "Add Exam"}
        onSubmit={handleSubmit(onFormSubmit)}
        editMode={editMode}
        loading={loading}
        onClick={!isBtnLoading && handleReset}
        add_or_update={isBtnLoading}
      >
        <CustomizedTextField
          label="Name"
          placeholder="Name"
          name="exam_name"
          required
          error={errors["exam_name"] ? "Required Field." : ""}
          inputRef={register({ required: true })}
        />
        <CustomizedNepaliDatePicker
          label="Start Date"
          required
          value={startDate || ""}
          setValue={handleStartDate}
        />
        <span className={classes.validationErrorInfo}>
          {errors.start_date?.type === "serverSideError" &&
            errors.start_date.message}
        </span>
        <CustomizedNepaliDatePicker
          label="End Date"
          required
          value={endDate || ""}
          setValue={handleEndDate}
        />
        <span className={classes.validationErrorInfo}>
          {errors.end_date?.type === "serverSideError" &&
            errors.end_date.message}
        </span>
      </FormLayout>
    </>
  );
};

export default SectionForm;
