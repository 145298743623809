import GET_ERRORS from "../actions/Errors/ErrorsActionTypes";

const initialState = {
  msg: null,
  status: null,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case GET_ERRORS:
      return {
        msg: action.payload.msg,
        status: action.payload.status,
      };
    default:
      return state;
  }
}
