import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Grid,
} from '@material-ui/core';
import clsx from 'clsx';
import { useAdmitCardStyles } from './AdmitCardStyles';
import { useDispatch, useSelector } from 'react-redux';
import { GetReportSetting } from '../../../actions/GeneralSettings/ReportSetting/ReportSettingAction';
import { RootStore } from '../../../store';
import checkActiveSignature from '../../utils/checkActiveSignature';
import checkImageUrl from '../../utils/checkImageUrl';
import { dateConverterAdToBs } from '../../utils/dateConverter';

interface InfoPropsI {
  value: string | number;
  properties: string;
}

const dummyData = {
  school_name: 'St.Xavier Secondary School',
  school_address: 'Maitighar, kathmandu, Nepal',
  school_phone: '+977-01-4412323',
  slogan: 'We aim for excellence',
  student_name: 'K.K Adhikari',
  student_photo:
    'https://www.pngitem.com/pimgs/m/334-3341344_mens-dress-png-men-icon-png-transparent-png.png',
  admission_no: '000045',
  father_name: 'M.B. Adhikari',
  address: 'Nepal',
  dob: '2048-03-12',
  blood_group: 'B+',
  phone_number: '1234567890',
};

export const StudentInfo = (props: InfoPropsI) => {
  const { value, properties } = props;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '2px 10px',
      }}
    >
      <Box fontSize={14} fontWeight="fontWeightRegular" textAlign="center">
        {properties}
      </Box>
      <Box fontSize={14} fontWeight="fontWeightRegular" textAlign="center">
        {value}
      </Box>
    </div>
  );
};

const AdmitCardSample1 = (props: any) => {
  const { data } = props;
  const dispatch = useDispatch();

  const [item, setItem] = useState<any>(null);

  const classes = useAdmitCardStyles();

  const general_info = useSelector(
    (state: RootStore) => state.auth?.general_info
  );

  const report_setting = useSelector(
    (state: RootStore) => state.report_setting?.reportsetting
  );

  useEffect(() => {
    props.content != null && setItem(props.content);
  }, [props.content]);

  const [url, setUrl] = useState<string>('');

  useEffect(() => {
    const n = window.location.href.lastIndexOf('/');
    setUrl(window.location.href.substring(n + 1));
    dispatch(GetReportSetting());
  }, []);

  const renderValue = (real: any, fake: any) => {
    if (url === 'customize' || url === 'update') {
      return fake;
    } else {
      return real;
    }
  };

  return (
    <>
      <Card
        className={classes.root}
        style={{
          backgroundColor: item?.back_ground_color[2].value,
        }}
      >
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          className={clsx(classes.bgColor, classes.landContent)}
          style={{
            backgroundColor: item?.back_ground_color[0].value,
          }}
        >
          <Grid item xs={2}>
            <CardMedia
              className={classes.logoImage}
              image={general_info?.logo}
              title=""
            />
          </Grid>
          <Grid item xs={10}>
            <div>
              <Box
                style={{
                  color: item?.text_color[0].value,
                }}
                fontSize={18}
                fontWeight="fontWeightBold"
                textAlign="center"
              >
                {general_info?.school_name}
              </Box>
              <Box
                lineHeight={2}
                style={{
                  color: item?.text_color[0].value,
                }}
                fontSize={12}
                fontWeight="fontWeightRegular"
                textAlign="center"
              >
                {general_info?.address},{general_info?.phone_number}
              </Box>
              <Box
                style={{
                  color: item?.text_color[0].value,
                }}
                fontSize={13}
                fontWeight="fontWeightBold"
                textAlign="center"
              >
                <span style={{ border: '1px solid white', padding: '2px 8px' }}>
                  {props.examType?.exam_name
                    ? props.examType.exam_name
                    : 'FIRST TERMINAL'}
                  {props.examType?.start_date
                    ? dateConverterAdToBs(props.examType?.start_date).slice(
                        0,
                        4
                      )
                    : '2078'}
                </span>
              </Box>
            </div>
          </Grid>
        </Grid>
        <Box mx={2}>
          <Grid
            container
            justifyContent="space-between"
            alignContent="center"
            alignItems="center"
          >
            <Grid item xs={4}>
              <CardMedia
                className={classes.landIdImage}
                image={renderValue(
                  checkImageUrl(data?.student_photo),
                  dummyData?.student_photo
                )}
              />
            </Grid>
            <Grid item xs={8}>
              <CardContent
                className={clsx(classes.content, classes.column)}
                style={{
                  color: item?.text_color[2].value,
                }}
              >
                <StudentInfo
                  properties="Student Name:"
                  value={renderValue(
                    data?.student_name,
                    dummyData?.student_name
                  )}
                />
                <StudentInfo
                  properties="Admission No.:"
                  value={renderValue(
                    data?.admission_no,
                    dummyData?.admission_no
                  )}
                />
                <StudentInfo
                  properties="Guardian's Name:"
                  value={renderValue(data?.father_name, dummyData?.father_name)}
                />
                <StudentInfo
                  properties="Address:"
                  value={renderValue(data?.address, dummyData?.address)}
                />
                <StudentInfo
                  properties="DOB / B.Group"
                  value={renderValue(
                    data?.dob + ' / ' + data?.blood_group,
                    dummyData?.dob + ' / ' + dummyData?.blood_group
                  )}
                />
                <StudentInfo
                  properties="Phone Number"
                  value={renderValue(
                    data?.phone_number,
                    dummyData?.phone_number
                  )}
                />
                <div style={{ marginTop: '5px' }}>
                  <Grid container justifyContent="flex-end">
                    <img
                      src={checkActiveSignature(report_setting)}
                      alt=""
                      height="40px"
                      width="100px"
                    />
                  </Grid>
                  <Box
                    fontSize={14}
                    textAlign="right"
                    style={{ paddingRight: '15px' }}
                  >
                    Signature
                  </Box>
                </div>
              </CardContent>
            </Grid>
          </Grid>
        </Box>

        <div
          className={clsx(classes.bgColor, classes.footer)}
          style={{
            backgroundColor: item?.back_ground_color[1].value,
          }}
        >
          <Box
            style={{
              color: item?.text_color[1].value,
            }}
            fontSize={12}
            fontWeight="fontWeightRegular"
            textAlign="center"
          >
            {general_info?.slogan}
          </Box>
        </div>
      </Card>
    </>
  );
};

export default AdmitCardSample1;
