export const GET_ADMITCARD_TEMPLATE_LOADING = "GET_ADMITCARD_TEMPLATE_LOADING";
export const GET_ADMITCARD_TEMPLATE_ERROR = "GET_ADMITCARD_TEMPLATE_ERROR";
export const GET_ADMITCARD_TEMPLATE_SUCCESS = "GET_ADMITCARD_TEMPLATE_SUCCESS";

export const ADD_ADMITCARD_TEMPLATE_LOADING = "ADD_ADMITCARD_TEMPLATE_LOADING";
export const ADD_ADMITCARD_TEMPLATE_ERROR = "ADD_ADMITCARD_TEMPLATE_ERROR";
export const ADD_ADMITCARD_TEMPLATE_SUCCESS = "ADD_ADMITCARD_TEMPLATE_SUCCESS";

export const ACTIVATE_OR_DEACTIVATE_ADMITCARD_TEMPLATE_LOADING =
  "ACTIVATE_OR_DEACTIVATE_ADMITCARD_TEMPLATE_LOADING";
export const ACTIVATE_OR_DEACTIVATE_ADMITCARD_TEMPLATE_ERROR =
  "ACTIVATE_OR_DEACTIVATE_ADMITCARD_TEMPLATE_ERROR";
export const ACTIVATE_OR_DEACTIVATE_ADMITCARD_TEMPLATE_SUCCESS =
  "ACTIVATE_OR_DEACTIVATE_ADMITCARD_TEMPLATE_SUCCESS";

export const GET_SINGLE_ADMITCARD_TEMPLATE_LOADING =
  "GET_SINGLE_ADMITCARD_TEMPLATE_LOADING";
export const GET_SINGLE_ADMITCARD_TEMPLATE_ERROR =
  "GET_SINGLE_ADMITCARD_TEMPLATE_ERROR";
export const GET_SINGLE_ADMITCARD_TEMPLATE_SUCCESS =
  "GET_SINGLE_ADMITCARD_TEMPLATE_SUCCESS";

export const UPDATE_ADMITCARD_TEMPLATE_LOADING =
  "UPDATE_ADMITCARD_TEMPLATE_LOADING";
export const UPDATE_ADMITCARD_TEMPLATE_ERROR =
  "UPDATE_ADMITCARD_TEMPLATE_ERROR";
export const UPDATE_ADMITCARD_TEMPLATE_SUCCESS =
  "UPDATE_ADMITCARD_TEMPLATE_SUCCESS";

export const DELETE_ADMITCARD_TEMPLATE_LOADING =
  "DELETE_ADMITCARD_TEMPLATE_LOADING";
export const DELETE_ADMITCARD_TEMPLATE_ERROR =
  "DELETE_ADMITCARD_TEMPLATE_ERROR";
export const DELETE_ADMITCARD_TEMPLATE_SUCCESS =
  "DELETE_ADMITCARD_TEMPLATE_SUCCESS";

export type ColorI = {
  key: string;
  value: string;
  name: string;
};

export type TemplateDataI = {
  id: string;
  code: string;
  thumbnail: string;
  type: string;
  text_color: ColorI[];
  back_ground_color: ColorI[];
};

//===============================<START>GET CLASS<START>======================//

export interface GetAdmitCardTemplateLoading {
  type: typeof GET_ADMITCARD_TEMPLATE_LOADING;
}

export interface GetAdmitCardTemplateError {
  type: typeof GET_ADMITCARD_TEMPLATE_ERROR;
}

export interface GetAdmitCardTemplateSuccess {
  type: typeof GET_ADMITCARD_TEMPLATE_SUCCESS;
  payload: TemplateDataI[];
}

//===============================<END>GET CLASS<END>======================//

//===============================<START>GET CLASS<START>======================//

export interface GetSingleAdmitCardTemplateLoading {
  type: typeof GET_SINGLE_ADMITCARD_TEMPLATE_LOADING;
}

export interface GetSingleAdmitCardTemplateError {
  type: typeof GET_SINGLE_ADMITCARD_TEMPLATE_ERROR;
}

export interface GetSingleAdmitCardTemplateSuccess {
  type: typeof GET_SINGLE_ADMITCARD_TEMPLATE_SUCCESS;
  payload: any;
}

//===============================<END>GET CLASS<END>======================//

//===============================<START>GET CLASS<START>======================//

export interface UpdateAdmitCardTemplateLoading {
  type: typeof UPDATE_ADMITCARD_TEMPLATE_LOADING;
}

export interface UpdateAdmitCardTemplateError {
  type: typeof UPDATE_ADMITCARD_TEMPLATE_ERROR;
}

export interface UpdateAdmitCardTemplateSuccess {
  type: typeof UPDATE_ADMITCARD_TEMPLATE_SUCCESS;
  payload: any;
}

//===============================<END>GET CLASS<END>======================//

//===============================<START>ADD CLASS<START>======================//

export interface AddAdmitCardTemplateLoading {
  type: typeof ADD_ADMITCARD_TEMPLATE_LOADING;
}

export interface AddAdmitCardTemplateError {
  type: typeof ADD_ADMITCARD_TEMPLATE_ERROR;
}

export interface AddAdmitCardTemplateSuccess {
  type: typeof ADD_ADMITCARD_TEMPLATE_SUCCESS;
  payload: TemplateDataI;
}

//===============================<END>ADD CLASS<END>======================//

//===============================<START>UPDATE CLASS<START>======================//

export interface ActivateOrDeactivateAdmitCardTemplateLoading {
  type: typeof ACTIVATE_OR_DEACTIVATE_ADMITCARD_TEMPLATE_LOADING;
}

export interface ActivateOrDeactivateAdmitCardTemplateError {
  type: typeof ACTIVATE_OR_DEACTIVATE_ADMITCARD_TEMPLATE_ERROR;
}

export interface ActivateOrDeactivateAdmitCardTemplateSuccess {
  type: typeof ACTIVATE_OR_DEACTIVATE_ADMITCARD_TEMPLATE_SUCCESS;
  payload: any;
}

//===============================<END>UPDATE CLASS<END>======================//

//===============================<START>GET CLASS<START>======================//

export interface DeleteAdmitCardTemplateLoading {
  type: typeof DELETE_ADMITCARD_TEMPLATE_LOADING;
}

export interface DeleteAdmitCardTemplateError {
  type: typeof DELETE_ADMITCARD_TEMPLATE_ERROR;
}

export interface DeleteAdmitCardTemplateSuccess {
  type: typeof DELETE_ADMITCARD_TEMPLATE_SUCCESS;
  payload: any;
}

//===============================<END>GET CLASS<END>======================//

export type AdmitCardTemplateDispatchTypes =
  | GetAdmitCardTemplateLoading
  | GetAdmitCardTemplateError
  | GetAdmitCardTemplateSuccess
  | GetSingleAdmitCardTemplateLoading
  | GetSingleAdmitCardTemplateError
  | GetSingleAdmitCardTemplateSuccess
  | AddAdmitCardTemplateLoading
  | AddAdmitCardTemplateError
  | AddAdmitCardTemplateSuccess
  | ActivateOrDeactivateAdmitCardTemplateLoading
  | ActivateOrDeactivateAdmitCardTemplateError
  | ActivateOrDeactivateAdmitCardTemplateSuccess
  | DeleteAdmitCardTemplateLoading
  | DeleteAdmitCardTemplateError
  | DeleteAdmitCardTemplateSuccess
  | UpdateAdmitCardTemplateLoading
  | UpdateAdmitCardTemplateError
  | UpdateAdmitCardTemplateSuccess;
