// ---------------- <START> module import start ------------------//
import React, { useState, useEffect } from "react";
import { Paper, Grid, Typography, Table, TableBody } from "@material-ui/core";
import { useTable } from "../../Reusable";
import { useTableStyles } from "../../Styles/TableStyles";

import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import {
  ItemEditButton,
  ItemSaveButton,
  ItemCancelButton,
} from "../../Reusable/Buttons/TableButton";
import {
  getGradings,
  updateGrading,
} from "../../../rtk/features/exam/grading/gradingApi";
import styled from "styled-components";
import { GradingI } from "../../../rtk/features/exam/grading/grading";
import BackDropLoader from "../../Reusable/BackDropLoader";

// ---------------- <END> module import ends ------------------//

// ---------------------------- <START> interface starts ----------------------------------//

const CellInput = styled.input<{ fullWidth?: boolean }>`
  && {
    width: ${(props) => (props.fullWidth ? "80%" : "90px")};
    padding: 5px;
    border-radius: 3px;
    border: 1px solid #000;
    color: #000;

    &:focus {
      outline: none;
    }
  }
`;

interface HeadCellsI {
  id: string;
  label: string;
}
// ---------------------------- <END> interface ends ----------------------------------//

// ---------------------------- <START> Table Headings Data starts ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N" },
  { id: "grade_name", label: "Grade" },
  { id: "marks_from_to", label: "Marks Obtained" },
  { id: "grade_point", label: "Grade Point" },
  { id: "remarks", label: "Remarks" },
  { id: "action", label: "Action" },
];
// ---------------- <END> Table Headings Data ends ------------------//

// ---------------- <START> Grades Table Component starts ------------------//
const GradesTable = () => {
  const classes = useTableStyles();
  const [editId, setEditId] = useState<string | null>(null);
  const [remarks, setRemarks] = useState<string>("");

  // states declarations

  //================================= <START> CYCLE HOOKS <START> ==============================//

  const dispatch = useDispatch();
  const { loading, actionPerformed, gradings } = useSelector(
    (state: RootStore) => state.marksGrading
  );

  useEffect(() => {
    dispatch(getGradings());
  }, []);

  useEffect(() => {
    if (actionPerformed) {
      setEditId(null);
    }
  }, [actionPerformed]);

  //================================= <END> CYCLE HOOKS <END> ==============================//

  // Retrieving re-usable components from useTable
  const { TblHead, StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  //================================= <START> EVENT HANDLERS <START> ==============================//

  const handleEdit = (el: GradingI) => {
    setEditId(el.id);
    setRemarks(el.description);
  };

  const handleSave = () => {
    if (editId) {
      dispatch(
        updateGrading({ id: editId, post_data: { description: remarks } })
      );
    }
  };
  //================================= <END> EVENT HANDLERS <END> ==============================//

  return (
    <>
      <Paper className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">Grades List</Typography>
          </Grid>
          <Table>
            <TblHead />
            <TableBody>
              {gradings.map((el, index) => (
                <StyledTableRow key={el.id}>
                  <StyledTableCell align="center">{index + 1}</StyledTableCell>
                  <StyledTableCell align="center">
                    {el.grade_name}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {`${el.marks_from}-${el.marks_to}`}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {el.grade_point}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {editId === el.id ? (
                      <CellInput
                        fullWidth
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                      />
                    ) : (
                      <>{el.description}</>
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {editId === el.id ? (
                      <>
                        <ItemSaveButton onClick={handleSave} />
                        <ItemCancelButton onClick={() => setEditId(null)} />
                      </>
                    ) : (
                      <ItemEditButton
                        onClick={() => handleEdit(el)}
                        disabled={Boolean(editId)}
                      />
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Paper>
      <BackDropLoader open={loading} />
    </>
  );
};
// ---------------------------- <ENDS> Grades Table Components ends---------------------------//
export default GradesTable;
