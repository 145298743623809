import { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import RubricSubCategoryForm from './RubricSubCategoryForm'
import RubricSubCategoryTable from './RubricSubCategoryTable'
import { RubricCategoryTypeI } from "../../../../actions/Academics/RubricCategory/RubricCategoryActionTypes"

interface PropsI {
    id: string | null;
}

const RubricSubCategoryPage = ({ id }: PropsI) => {
    const [formData, setFormData] = useState<RubricCategoryTypeI | null>(null)
    const [count, setCount] = useState<number | undefined>(0);
    const [edit, setEdit] = useState<any>();

    const fetchEditData = (data: RubricCategoryTypeI) => {
        setFormData({ ...data, count });
        count && setCount(count + 1);
    };

    const onEditMode = (value: boolean) => {
        setEdit({ value, count });
    };

    return (
        <Grid container>
            <Grid item xs={3}>
                <RubricSubCategoryForm editData={formData} onEditMode={onEditMode} id={id} />
            </Grid>
            <Grid item xs={9}>
                <RubricSubCategoryTable onEditData={fetchEditData} edit={edit} id={id} />
            </Grid>
        </Grid>
    )
}

export default RubricSubCategoryPage