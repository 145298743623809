import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import SaveIcon from "@material-ui/icons/Save";
import formsValidationCheckup from "../../utils/formsValidationCheckUp";
import { RootStore } from "../../../store";
import { GetClasses } from "../../../actions/Academics/Class/ClassAction";
import { GetSections } from "../../../actions/Academics/Section/SectionAction";
import { StudentAdmission2 } from "../../../actions/Student/Student/StudentAction";
import { useStyles } from "../../Styles/FormStyles";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormSaveLoadingButton } from "../../Reusable/Buttons/FormButton";
import Spinner from "../../../common/spinnerSVG/Spinner";
import styled from "styled-components";
import {
  createStaff,
  getStaffs,
} from "../../../rtk/features/humanResource/humanResourceThunk";

const ModalForm = styled.form`
  padding: 0 20px;
  max-width: 480px;
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 11px;
  margin-left: 10px;
`;

interface StaffFormProps {
  name: string;
  gender: { label: string; value: string } | null;
  phone: string;
}

const StaffMainModal = (props: any) => {
  // Custom Styles
  const classes = useStyles();
  const { handleModalClose } = props;

  // Destructuring react-form-hook methods
  const { register, handleSubmit, errors, setError, clearErrors, control } =
    useForm<StaffFormProps>({
      mode: "onChange",
    });

  // Dispatch Variable
  const dispatch = useDispatch();

  // State Declaration
  const [staffType, setStaffType] = useState<string>("Teacher");

  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);

  const { loading, actionCompleted } = useSelector(
    (state: RootStore) => state.humanResource
  );

  useEffect(() => {
    if (actionCompleted) {
      handleModalClose();
      dispatch(getStaffs({ page: 1 }));
    }
  }, [actionCompleted]);

  const add_or_update = useSelector(
    (state: RootStore) => state.student?.add_or_update
  );

  useEffect(() => {
    if (add_or_update) {
      setIsBtnLoading(false);
    }
  }, [add_or_update]);

  const onSubmitModal: SubmitHandler<StaffFormProps> = (data) => {
    dispatch(
      createStaff({
        full_name: data.name,
        gender: data.gender!.value,
        phone_number: data.phone,
        staff_type: staffType,
      })
    );
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStaffType((event.target as HTMLInputElement).value);
  };

  return (
    <ModalForm onSubmit={handleSubmit(onSubmitModal)}>
      <Grid container>
        <Grid
          item
          xs={12}
          className={classes.formWrapper}
          style={{ marginBottom: "1rem" }}
        >
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="teaching_staff"
              name="staff_type"
              value={staffType}
              onChange={handleRadioChange}
              row
            >
              <FormControlLabel
                value="Teacher"
                control={<Radio />}
                label="Teaching Staff"
              />
              <FormControlLabel
                value="Administrator"
                control={<Radio />}
                label="Admin Staff"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          className={classes.formWrapper}
          style={{ marginBottom: "1rem" }}
        >
          <InputLabel>
            Staff Name <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <TextField
            variant="outlined"
            name="name"
            placeholder="Staff Name"
            inputRef={register({
              required: { value: true, message: "Staff name is required." },
            })}
          />
          <ErrorMessage>{errors.name?.message}</ErrorMessage>
        </Grid>

        <Grid
          item
          xs={12}
          className={classes.formWrapper}
          style={{ marginBottom: "1rem" }}
        >
          <InputLabel>
            Gender <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <Controller
            name="gender"
            control={control}
            defaultValue={null}
            rules={{
              required: { value: true, message: "Gender is required." },
            }}
            render={({ onChange }) => (
              <Autocomplete
                onChange={(_, data) => onChange(data)}
                options={[
                  { value: "M", label: "Male" },
                  { value: "F", label: "Female" },
                  { value: "O", label: "Others" },
                ]}
                getOptionLabel={(option) => option.label}
                openOnFocus={true}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="gender"
                    placeholder="Select Gender"
                    variant="outlined"
                  />
                )}
              />
            )}
          />
          <ErrorMessage>{errors.gender?.message}</ErrorMessage>
        </Grid>

        <Grid
          item
          xs={12}
          className={classes.formWrapper}
          style={{ marginBottom: "1rem" }}
        >
          <InputLabel>
            Phone Number <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <TextField
            variant="outlined"
            name="phone"
            placeholder="Phone Number"
            inputRef={register({
              required: { value: true, message: "Phone number is required." },
            })}
          />
          <ErrorMessage>{errors.phone?.message}</ErrorMessage>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="flex-start"
        style={{ marginBottom: "1em" }}
      >
        <FormSaveLoadingButton
          type="submit"
          icon={isBtnLoading ? <Spinner /> : <SaveIcon />}
          disabled={isBtnLoading ? true : false}
          text={isBtnLoading ? "Saving..." : "Save"}
        />
      </Grid>
    </ModalForm>
  );
};

export default StaffMainModal;
