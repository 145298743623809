import React, { useState, useEffect } from 'react';
import {
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
} from '@material-ui/core';
import StudentSidebar from '../StudentSidebar/StudentSidebar';
import { useDispatch, useSelector } from 'react-redux';
import SubModuleLayout from '../../Reusable/Layouts/SubModuleLayout';
import { useParams } from 'react-router-dom';
import { RootStore } from '../../../store';
import { GetStudentProfile } from '../../../actions/Student/StudentProfile/StudentProfileAction';
import NewTabs from './File/NewTabs';
import CardLayout from '../../Reusable/Layouts/CardLayout';
import getFullName from '../../utils/getFullName';
import getFullClassName from '../../utils/getFullClassName';

const StudentProfile: React.FC = () => {
  const nav = <StudentSidebar studentDetails />;
  const { studentID } = useParams<{ studentID: string }>();
  const dispatch = useDispatch();

  useEffect(() => {
    studentID != undefined && dispatch(GetStudentProfile(studentID));
  }, []);

  const studentState = useSelector(
    (state: RootStore) => state.student_profile.student
  );

  const headData = [
    { id: 2, headCells: 'Adm No.:', cellData: studentState?.admission_number },
    {
      id: 3,
      headCells: 'Class:',
      cellData:
        studentState?.academic_info &&
        getFullClassName(
          studentState?.academic_info[0]?.grade_name,
          studentState?.academic_info[0]?.section_name
        ),
    },
    {
      id: 4,
      headCells: 'Roll No:',
      cellData:
        studentState?.academic_info &&
        studentState.academic_info[0]?.roll_number
          ? studentState?.academic_info &&
            studentState.academic_info[0]?.roll_number
          : '-',
    },
    { id: 5, headCells: 'Address:', cellData: studentState?.address },
    {
      id: 6,
      headCells: 'Guardian:',
      cellData: getFullName(
        studentState?.guardian_detail?.guardian_user.first_name,
        studentState?.guardian_detail?.guardian_user.last_name
      ),
    },
    {
      id: 7,
      headCells: 'Phone No.:',
      cellData: studentState?.phone_number ? studentState?.phone_number : '-',
    },
  ];

  return (
    <>
      <SubModuleLayout studentDetails sideNav={nav}>
        <Grid container style={{ flexWrap: 'nowrap' }}>
          <Grid item style={{ flexBasis: '320px', marginTop: '-1.5rem' }}>
            <CardLayout
              fullName={getFullName(
                studentState?.student_user?.first_name,
                studentState?.student_user?.last_name
              )}
              imgUrl={studentState?.student_photo}
              headData={headData}
            />
          </Grid>

          <Grid
            item
            style={{ width: 'calc(100% - 320px)', marginLeft: '-16px' }}
          >
            <NewTabs />
          </Grid>
        </Grid>
      </SubModuleLayout>
    </>
  );
};
export default StudentProfile;
