import React, { useState, useEffect } from 'react'
import Child from './Child';
import { Typography } from '@material-ui/core'

const ChildrenInfo = (props: any) => {
    console.log(props.data);

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "2em", margin: "2em 0", height: "65vh", overflowY: "scroll" }}>
            <Typography align="left" variant="h5" component="h3">
                Children Info
            </Typography>
            {props.data.length > 0 && props.data.map((child: any) =>
                <Child key={child.id} data={child} />
                // <h1 key={child.id}>Hello World</h1>
            )}
        </div>
    )
}

export default ChildrenInfo