import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Grid,
    InputLabel,
    TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import SaveIcon from "@material-ui/icons/Save";
import formsValidationCheckup from '../../utils/formsValidationCheckUp';
import { RootStore } from "../../../store";
import { GetClasses } from "../../../actions/Academics/Class/ClassAction";
import { GetSections } from "../../../actions/Academics/Section/SectionAction";
import { StudentAdmission2 } from "../../../actions/Student/Student/StudentAction"
import { useStyles } from "../../Styles/FormStyles";
import { useForm } from "react-hook-form";
import { FormSaveLoadingButton } from '../../Reusable/Buttons/FormButton';
import Spinner from '../../../common/spinnerSVG/Spinner';

const StudentAdmissionModal = (props: any) => {
    // Custom Styles
    const classes = useStyles();
    const { handleModalClose } = props;

    // Destructuring react-form-hook methods
    const { register, handleSubmit, errors, setValue, setError, clearErrors } =
        useForm<any>({
            mode: "onChange",
        });

    // Dispatch Variable
    const dispatch = useDispatch();

    // State Declaration
    const [grade, setGrade] = useState<any | null>(null);
    const [gradeChoices, setGradeChoices] = useState<any>([]);
    const [section, setSection] = useState<any | null>(null);
    const [sectionChoices, setSectionChoices] = useState<any[] | []>([]);
    const [sectionDisabler, setSectionDisabler] = useState<boolean>(true);
    const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false)

    useEffect(() => {
        dispatch(GetClasses());
        dispatch(GetSections());
    }, []);

    const add_or_update = useSelector((state: RootStore) => state.student?.add_or_update);

    const classList = useSelector((state: RootStore) => state.class.classes);
    useEffect(() => {
        classList && setGradeChoices(classList);
    }, [classList]);


    useEffect(() => {
        if (add_or_update) {
            setIsBtnLoading(false);
        }
    }, [add_or_update]);

    useEffect(() => {
        if (grade != null) {
            populateSectionChoices(grade.section);
        } else if (grade == null) {
            setSectionDisabler(true);
        }
    }, [grade]);

    const handleGradeChange = (value: any | null) => {
        clearErrors('grade')
        value != null && setGrade(value);
        setSection(null);
        value != null
            ? populateSectionChoices(value.section)
            : setSectionDisabler(true);
        value == null && setGrade(null);
    };

    const populateSectionChoices = (sections: any | []) => {
        clearErrors('section')
        setSectionDisabler(false);
        sections?.length > 0
            ? setSectionChoices(sections)
            : setSectionDisabler(true);
    };

    const onSubmitModal = (data: any) => {
        const submit_data = {
            ...data,
            grade: grade?.grade,
            section: section?.id || null,
        }
        console.log({ submit_data })
        setIsBtnLoading(true);
        dispatch(StudentAdmission2(submit_data, handleModalClose));
    }

    return (
        <form className={classes.form} onSubmit={handleSubmit(onSubmitModal)}>
            <Grid container>
                <Grid item md={12} className={classes.formWrapper} style={{ marginLeft: "3rem" }} justifyContent="space-between">
                    <Grid item md={10} className={classes.formWrapper} style={{ marginBottom: "1rem" }}>
                        <InputLabel>
                            Student Name <span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <TextField
                            variant="outlined"
                            name="student_name"
                            placeholder="Student Name"
                            inputRef={register({
                                required: true,
                            })}
                        />
                        {formsValidationCheckup({
                            errors: errors,
                            inputName: "student_name",
                            requiredMessage: "Student Name is Required",
                            serverSideMessage:
                                errors.first_name?.type === "serverSideError" &&
                                errors.first_name.message,
                        })}
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item md={5} className={classes.formWrapper} style={{ marginBottom: "1rem" }}>
                            <InputLabel>
                                Class <span style={{ color: "red" }}>*</span>
                            </InputLabel>
                            <Autocomplete
                                onChange={(event: React.ChangeEvent<{}>, value: any | null) =>
                                    handleGradeChange(value)
                                }
                                options={gradeChoices}
                                getOptionLabel={(option) => "Class" + " " + option.grade_name}
                                openOnFocus={true}
                                value={grade}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="grade"
                                        placeholder="Class"
                                        variant="outlined"
                                        inputRef={register({ required: true })}
                                    />
                                )}
                            />
                            {formsValidationCheckup({
                                errors: errors,
                                inputName: "grade",
                                requiredMessage: "Class is Required",
                                serverSideMessage:
                                    errors.class?.type === "serverSideError" &&
                                    errors.class.message,
                            })}
                        </Grid>
                        <Grid item md={5} className={classes.formWrapper} style={{ marginBottom: "1rem" }}>
                            <InputLabel>
                                Section{" "}
                                {!sectionDisabler && <span style={{ color: "red" }}>*</span>}
                            </InputLabel>
                            <Autocomplete
                                value={section}
                                onChange={(event: React.ChangeEvent<{}>, value: any | null) =>
                                    setSection(value)
                                }
                                options={sectionChoices}
                                getOptionLabel={(option) => option.name}
                                openOnFocus={true}
                                disabled={sectionDisabler}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Section"
                                        name="section"
                                        variant="outlined"
                                        inputRef={register({
                                            required: !sectionDisabler,
                                        })}
                                    />
                                )}
                            />
                            {formsValidationCheckup({
                                errors: errors,
                                inputName: "section",
                                requiredMessage: "Section is Required",
                                serverSideMessage:
                                    errors.section?.type === "serverSideError" &&
                                    errors.section.message,
                            })}
                        </Grid>
                    </Grid>
                    <Grid item md={10} className={classes.formWrapper} style={{ marginBottom: "1rem" }}>
                        <InputLabel>
                            Guardian Name <span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <TextField
                            variant="outlined"
                            name="guardian_name"
                            placeholder="Guardian Name"
                            inputRef={register({
                                required: true,
                            })}
                        />
                        {formsValidationCheckup({
                            errors: errors,
                            inputName: "guardian_name",
                            requiredMessage: "Guardian Name is Required",
                            serverSideMessage:
                                errors.first_name?.type === "serverSideError" &&
                                errors.first_name.message,
                        })}
                    </Grid>
                    <Grid item md={10} className={classes.formWrapper} style={{ marginBottom: "1rem" }}>
                        <InputLabel>
                            Guardian Phone Number
                            <span style={{ color: "red" }}>*</span>
                        </InputLabel>
                        <TextField
                            variant="outlined"
                            autoComplete="no"
                            name="guardian_number"
                            type="number"
                            placeholder="Guardian Phone Number"
                            inputProps={{ min: 0 }}
                            inputRef={register({
                                required: true,
                                maxLength: 10,
                                minLength: 9,
                            })}
                        />
                        {formsValidationCheckup({
                            errors: errors,
                            inputName: "guardian_number",
                            requiredMessage: "Guardian Phone Number is Required",
                            maxLengthMessage: "Characters must be in between (9-10) digits",
                            minLengthMessage: "Characters must be in between (9-10) digits",
                            serverSideMessage:
                                errors.phone_number?.type === "serverSideError" &&
                                errors.phone_number.message,
                        })}
                    </Grid>
                    <Grid container justifyContent='flex-start' style={{ marginBottom: "1em" }}>
                        <FormSaveLoadingButton
                            type="submit"
                            icon={isBtnLoading ? <Spinner /> : <SaveIcon />}
                            disabled={isBtnLoading ? true : false}
                            text={isBtnLoading ? "Saving..." : "Save"}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </form >
    )
}

export default StudentAdmissionModal