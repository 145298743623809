import {
  Grid,
  Paper,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  TableContainer,
  Checkbox,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import cx from 'clsx';
import { useCheckBoxTable } from '../../Reusable';
import { useTableStyles } from '../../Styles/TableStyles';
import { SearchedStudentsI } from '../../../actions/Student/Student/StudentActionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import { StudentSearchForm } from '../..';
import StaffSearchForm from './StaffSearchForm';
import { GetStaffs } from '../../../actions/HumanResource/Staff/StaffAction';
import styled from 'styled-components';
interface HeadCellsI {
  id: string;
  label: string;
  align?: 'left' | 'right' | 'center';
}

interface PropsI {
  onStaffChange: (student_ids: string[]) => void;
  handleApplyLeave: () => void;
  setIsBtnLoading: (data: boolean) => void;
  isBtnLoading: boolean;
}

const headCells: HeadCellsI[] = [
  { id: 'index', label: 'S.N' },
  { id: 'staff', label: 'Staff Name', align: 'left' },
  { id: 'designation', label: 'Designation', align: 'left' },
  { id: 'phone', label: 'Phone', align: 'left' },
  { id: 'address', label: 'Address', align: 'left' },
];

const Title = styled(Typography)`
  &.MuiTypography-root {
    margin-bottom: -4px;
    font-weight: 500;
  }
`;

const StaffSelectTable = (props: PropsI) => {
  const dispatch = useDispatch();
  const classes = useTableStyles();

  const { onStaffChange, handleApplyLeave, isBtnLoading, setIsBtnLoading } =
    props;
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useCheckBoxTable(headCells);

  const [selected, setSelected] = React.useState<string[]>([]);
  const [grade, setGrade] = useState<any | null>(null);
  const [tableData, setTableData] = useState<any[]>([]);

  const staffSelector = useSelector((state: RootStore) => state.staff);
  const { loading, staffs, errors } = staffSelector;

  useEffect(() => {
    dispatch(GetStaffs(1));
  }, []);

  useEffect(() => {
    if (!(staffs && staffs.results && staffs.results.length > 0)) return;

    const items = staffs.results.map((item: any) => {
      const { id, user, designation_name, phone, address } = item;
      const { first_name, last_name } = user;
      const staff = first_name + ' ' + last_name;
      const designation = designation_name || '-';

      return {
        id,
        staff,
        designation,
        phone,
        address,
      };
    });
    setTableData(items);
  }, [staffs.results]);

  useEffect(() => {
    onStaffChange(selected);
  }, [selected]);

  const handleCheckClick = (name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = tableData.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const getCustomTableRow = (row: any, index: number) => {
    const isItemSelected = isSelected(row.id);
    const labelId = `enhanced-table-checkbox-${index}`;
    const cellStyle = cx(classes.cell, classes.cellSm);
    return (
      <StyledTableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
        selected={isItemSelected}
      >
        <StyledTableCell className={cellStyle} align="left">
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Checkbox
                color="default"
                checked={isItemSelected}
                inputProps={{ 'aria-labelledby': labelId }}
                onClick={() => handleCheckClick(row.id)}
              />
            </Grid>
            <Grid item>{index + 1}</Grid>
          </Grid>
        </StyledTableCell>
        <StyledTableCell className={cellStyle} align="left">
          {row.staff}
        </StyledTableCell>
        <StyledTableCell className={cellStyle} align="left">
          {row.designation}
        </StyledTableCell>
        <StyledTableCell className={cellStyle} align="left">
          {row.phone}
        </StyledTableCell>
        <StyledTableCell className={cellStyle} align="left">
          {row.address}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <Paper
      className={classes.rootCheckBoxTable}
      style={{
        marginRight: '5px',
        marginLeft: '5px',
        maxHeight: 'calc(100vh - 150px)',
        overflowY: 'scroll',
      }}
    >
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <StaffSearchForm
            grade={grade}
            setGrade={setGrade}
            handleApplyLeave={handleApplyLeave}
            isBtnLoading={isBtnLoading}
          />

          <Title variant="subtitle1">Select Staff(s) to Apply Leave Type</Title>
        </Grid>

        <Grid item xs={12}>
          <TableContainer
            hasCheckbox
            handleSelectAllClicked={handleSelectAllClick}
            rowCount={tableData.length}
            numSelected={selected.length}
            loading={loading}
            items={tableData}
            headCells={headCells}
            getCustomTableRow={getCustomTableRow}
            hiddenCols={[-1]}
            disablePrint
            disableSearch
            disablePagination
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default StaffSelectTable;
