// ---------------------------- <START> module imports start ----------------------------------//
import React, { useEffect, useState, useRef } from 'react';
import {
  TableBody,
  Grid,
  TableHead,
  makeStyles,
  createStyles,
  Theme,
  withStyles,
} from '@material-ui/core';
import { PrintTableStyles } from '../../../Styles/PrintTableStyles';
import { useTablePrint } from '../../../Reusable';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../../store';
import { MarkSheetTypeI } from '../../../../actions/Examination/MarkSheet/MarkSheetActionTypes';
import { Printd } from 'printd';
import ReactDOMServer from 'react-dom/server';
import ReactHtmlParser from 'react-html-parser';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import NativeSelect from '@material-ui/core/NativeSelect';
import { useDispatch } from 'react-redux';
import {
  SchoolInfoContainer,
  SchoolTitle,
  Paragraph,
  ReportType,
  StudentProfileContainer,
  StudentDetailTextWrapper,
  StudentDetailText,
  Spacing,
  Sticky,
} from '../../../Reports/Marksheet/ReportStyles';
import ReactToPrint from 'react-to-print';
import { dateConverterAdToBs } from '../../../utils/dateConverter';
import getFullName from '../../../utils/getFullName';
import getFullClassName from '../../../utils/getFullClassName';
import { useFormStyles } from '../../../Styles/FormStyles';
import { FormSaveButton } from '../../../Reusable/Buttons/FormButton';
import defaultBase64 from '../../../Student/ParentForm/defaultBase64';
import { SearchedStudentsI } from '../../../../actions/Student/Student/StudentActionTypes';
import {
  CollectFeesReceiptDataI,
  CollectFeesRequestI,
} from '../../../../actions/Fees/CollectFees/CollectFeesActionTypes';
import { ToWords } from 'to-words';
import { FeeLedgerByDateI } from '../../../../actions/Fees/FeeLedger/FeeLedgerActionTypes';
import BackDropLoader from '../../../Reusable/BackDropLoader';
import {
  getDiscountAmount,
  getFineAmount,
  getScholarshipAmount,
} from '../../CollectFees/modal/StatementDetails';
// ----------------------------<END> module imports end ----------------------------------//

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  })
)(InputBase);
// ---------------------------- <START> interface starts ----------------------------------//

interface HeadCellsI {
  id: string;
  label: string;
}

interface CustomizeI {
  ape_enable: boolean;
  footer_title: string;
  footer_text_1: string;
  footer_text_2: string;
  footer_text_3: string;
  thumbnail: string;
  watermark: boolean;
  school_logo: boolean;
  school_photo: boolean;
  watermark_position: string;
  school_logo_position: string;
  school_photo_position: string;
  grading_system: boolean;
}
// ---------------------------- <END> interface ends ----------------------------------//

interface HeadCellsI {
  id: string;
  label: string;
}

// -------------------- <START> Table Headings Data ---------------------------//
const headCells: HeadCellsI[] = [
  { id: 'sn', label: 'S. No.' },
  { id: 'subject', label: 'Subjects' },
  { id: 'fullMarks', label: 'Full Marks' },
  { id: 'passMarks', label: 'Pass Marks' },
  { id: 'th', label: 'TH' },
  { id: 'pr', label: 'PR' },
  { id: 'total', label: 'Total' },
  { id: 'grade_point', label: 'Grade Point' },
];
// ---------------------<END> Table Headings Data Ends --------------------------//

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2, 4),
    '& .MuiCardContent-root': {
      padding: theme.spacing(0),
    },
    '& .MuiTypography-h2': {
      fontSize: '3.1rem',
    },
    caption: {
      padding: '0px',
    },
  },
  idImage: {
    width: '80px',
    height: '80px',
  },
  line: {
    borderRight: '1px solid black',
  },
  bottom: {
    marginBottom: theme.spacing(1),
  },
  tableCell: {
    padding: '0px 5px !important',
    border: '1px solid black',
  },
  topWatermark: {
    backgroundImage: `url('https://i.imgur.com/KO5poDX.png')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '70%',
    backgroundPosition: 'center',
  },
  centerWatermark: {
    backgroundImage: `url('https://i.imgur.com/KO5poDX.png')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '70%',
    backgroundPosition: 'center',
  },
}));

interface PropsI {
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  editor: boolean;
}

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const InvoiceModal = (props: PropsI) => {
  const { onClose, editor } = props;
  const classes = PrintTableStyles();
  const formClasses = useFormStyles();
  const localClasses = useStyles();

  const [totalAmtWords, setTotalAmtWords] = useState<string>('');

  const [tableData, setTableData] = useState<FeeLedgerByDateI[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const ledgerSelector = useSelector((state: RootStore) => state.fee_ledger);

  // States declaration
  const [customizedData, setCustomizedData] = useState<CustomizeI>({
    ape_enable: false,
    footer_title: 'Approval',
    footer_text_1: 'Class Teacher',
    footer_text_2: 'Exam Coordinator',
    footer_text_3: 'The Principal',
    thumbnail: defaultBase64,
    watermark: false,
    school_logo: false,
    school_photo: false,
    watermark_position: 'T',
    school_logo_position: 'L',
    school_photo_position: 'R',
    grading_system: false,
  });

  const [printMode, setPrintMode] = useState<boolean>(false);

  const [feeState, setFeeState] = useState({
    subtotal: 0,
    scholarshipAmount: 0,
    discountAmount: 0,
    fineAmount: 0,
    payingAmount: 0,
    paidAmount: 0,
  });
  const [studentState, setStudentState] = useState({
    name: '',
    dob: '',
    class: '',
    roll_no: '',
    address: '',
    guardian: '',
  });

  const componentRef = useRef<any>();

  useEffect(() => {
    setLoading(ledgerSelector.loading);
    const data = ledgerSelector.feeLedgerByDate;

    if (data) {
      setTableData(data.invoice_detail);

      const subtotal = data.invoice_detail.reduce(
        (a, b) => a + b.amount_to_pay,
        0
      );
      const scholarshipAmt = data.invoice_detail.reduce(
        (a, b) =>
          a +
          getScholarshipAmount(b.invoice_scholarship_applied, b.amount_to_pay),
        0
      );
      const discountAmt = data.invoice_detail.reduce(
        (a, b) =>
          a + getDiscountAmount(b.invoice_discount_applied, b.amount_to_pay),
        0
      );
      const fineAmt = data.invoice_detail.reduce(
        (a, b) => a + getFineAmount(b.invoice_fine_applied, b.amount_to_pay),
        0
      );
      const payingAmt = subtotal + fineAmt - scholarshipAmt - discountAmt;
      const paidAmt = data.invoice_detail.reduce(
        (a, b) => a + b.paid_amount,
        0
      );

      setFeeState({
        subtotal: subtotal,
        scholarshipAmount: scholarshipAmt,
        discountAmount: discountAmt,
        fineAmount: fineAmt,
        payingAmount: payingAmt,
        paidAmount: paidAmt,
      });

      const toWords = new ToWords({
        localeCode: 'en-IN',
        converterOptions: {
          currency: true,
          ignoreDecimal: false,
          ignoreZeroCurrency: false,
        },
      });

      setTotalAmtWords(toWords.convert(payingAmt));

      setStudentState({
        name:
          data.student_info.student_first_name +
          ' ' +
          data.student_info.student_last_name,
        dob: data.student_info.dob,
        class:
          data.student_info.grade_name + ' ' + data.student_info.section_name,
        roll_no: data.student_info.roll_number + ' ',
        address: data.student_info.address,
        guardian:
          data.student_info.guardian_first_name +
          ' ' +
          data.student_info.guardian_last_name,
      });
    }
  }, [ledgerSelector]);

  const schoolState = useSelector(
    (state: RootStore) => state.auth.general_info
  );
  const examState = useSelector((state: RootStore) => state.exam.exam);

  // Retrieving re-usable components from useTable
  const {
    TblContainer,
    TblHead,
    StyledTableCell,
    StyledTableRow,
    exportToExcel,
    tablePrint,
  } = useTablePrint(headCells);

  // ============================ <START> REACT HOOKS <START> ============================== //
  const marksheetSelector = useSelector((state: RootStore) => state.marksheet);

  // ============================ <END> REACT HOOKS <END> ============================== //

  // ============================ <START> EVENT HANDLERS <START> ============================== //

  // Event function for table searching by name
  const handleTableSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    return;
  };

  const handlePrint = (e: any) => {
    e.preventDefault();
    const d = new Printd();
    const modalRoot = document.getElementById('print-element') as HTMLElement;
    d.print(modalRoot);
  };
  // ============================ <END> EVENT HANDLERS <END> ============================== //

  const toHTML = () => {
    const modalRoot: any =
      document.getElementById('marksheet-print')?.innerHTML;
    const styless: any = document.getElementById('marksheet-print')?.style;
    const htmlString = ReactDOMServer.renderToStaticMarkup(modalRoot);
  };

  const handleFooterText = (event: any) => {
    const finalValues = {
      ...customizedData,
      footer_title: event.target.value,
    };
    setCustomizedData(finalValues);
  };

  const handleFooterText1 = (event: any) => {
    const finalValues = {
      ...customizedData,
      footer_text_1: event.target.value,
    };
    setCustomizedData(finalValues);
  };

  const handleFooterText2 = (event: any) => {
    const finalValues = {
      ...customizedData,
      footer_text_2: event.target.value,
    };
    setCustomizedData(finalValues);
  };

  const handleFooterText3 = (event: any) => {
    const finalValues = {
      ...customizedData,
      footer_text_3: event.target.value,
    };
    setCustomizedData(finalValues);
  };
  const handleapeChange = (event: any) => {
    const finalValues = {
      ...customizedData,
      ape_enable: event?.target.checked,
    };
    setCustomizedData(finalValues);
  };
  const handlegradingChange = (event: any) => {
    const finalValues = {
      ...customizedData,
      grading_system: event?.target.checked,
    };
    setCustomizedData(finalValues);
  };
  const handlewatermarkChange = (event: any) => {
    const finalValues = {
      ...customizedData,
      watermark: event?.target.checked,
    };
    setCustomizedData(finalValues);
  };

  const handleChangeSchoolLogo = (event: any) => {
    const finalValues = {
      ...customizedData,
      school_logo_position: event.target.value,
    };
    setCustomizedData(finalValues);
  };

  const handleChangeWatermark = (event: any) => {
    const finalValues = {
      ...customizedData,
      watermark_position: event.target.value,
    };
    setCustomizedData(finalValues);
  };

  console.log('schoolState', schoolState);

  return (
    <>
      {!editor && (
        <>
          <Sticky>
            <ReactToPrint
              trigger={() => (
                <p
                  style={{
                    backgroundColor: '#132E98',
                    color: 'white',
                    fontWeight: 'bold',
                    borderRadius: '0px',
                    margin: '0px',
                    textAlign: 'center',
                    padding: '5px 0px',
                    cursor: 'pointer',
                  }}
                >
                  Print This Out !
                </p>
              )}
              content={() => componentRef.current}
              removeAfterPrint={true}
              copyStyles={true}
            />
          </Sticky>
        </>
      )}
      <div className={classes.roottable} id="print-element" ref={componentRef}>
        <div
          className={
            customizedData.watermark
              ? customizedData.watermark_position === 'C'
                ? localClasses.topWatermark
                : ''
              : ''
          }
        >
          {/* <button onClick={() => toHTML()}>toHTML</button> */}
          <div id="marksheet-print" className="marksheet-print">
            <div
              style={{ margin: '25px', borderStyle: 'double', padding: '25px' }}
            >
              {/* <div id="test">manoz acharya</div> */}
              <Spacing>
                <div
                  className={
                    customizedData.watermark
                      ? customizedData.watermark_position === 'T'
                        ? localClasses.topWatermark
                        : ''
                      : ''
                  }
                >
                  <Paragraph small left>
                    <b>Reg. No: {schoolState?.school_reg_number}</b>
                  </Paragraph>
                  <Paragraph small left>
                    <b>Receipt No: </b>
                  </Paragraph>
                  <Grid
                    container
                    justifyContent="center"
                    alignContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={4}>
                      <img
                        style={{
                          //   borderRadius: "50%",
                          objectFit: 'contain',
                          margin: 'auto',
                          width: '100%',
                        }}
                        src={schoolState?.logo}
                        // width="180"
                        height="150"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <SchoolInfoContainer>
                        <Paragraph small>{schoolState?.slogan}</Paragraph>
                        <SchoolTitle>{schoolState?.school_name}</SchoolTitle>
                        <Paragraph medium>{schoolState?.address}</Paragraph>
                        <Paragraph medium>
                          Phone: {schoolState?.phone_number}
                        </Paragraph>
                        <Paragraph medium>
                          Email: {schoolState?.email}
                        </Paragraph>
                        <ReportType>
                          <Paragraph large>Fee Receipt</Paragraph>
                          <Paragraph large>
                            {examState?.exam_name}{' '}
                            {examState?.start_date &&
                              dateConverterAdToBs(examState?.start_date).slice(
                                0,
                                4
                              )}
                          </Paragraph>
                        </ReportType>
                      </SchoolInfoContainer>
                    </Grid>
                    <Grid item xs={4}></Grid>
                  </Grid>
                </div>
                <StudentProfileContainer>
                  <Spacing>
                    <Paragraph medium left underline>
                      Personal/Academic Information:
                    </Paragraph>
                  </Spacing>
                  <Grid container spacing={3} style={{ marginTop: '0px' }}>
                    <Grid container item xs={12} className="nothing">
                      <Grid item xs={6}>
                        <Spacing>
                          <StudentDetailTextWrapper>
                            <StudentDetailText bold>
                              Student Name:
                            </StudentDetailText>
                            <StudentDetailText>
                              {studentState.name}
                            </StudentDetailText>
                          </StudentDetailTextWrapper>
                          <StudentDetailTextWrapper>
                            <StudentDetailText bold>
                              School ID/Roll No:
                            </StudentDetailText>
                            <StudentDetailText>
                              {studentState.roll_no}
                            </StudentDetailText>
                          </StudentDetailTextWrapper>
                          <StudentDetailTextWrapper>
                            <StudentDetailText bold>Class:</StudentDetailText>
                            <StudentDetailText>
                              {studentState.class}
                            </StudentDetailText>
                          </StudentDetailTextWrapper>
                        </Spacing>
                      </Grid>
                      <Grid item xs={6}>
                        <Spacing>
                          <StudentDetailTextWrapper>
                            <StudentDetailText bold>
                              Date of Birth:
                            </StudentDetailText>
                            <StudentDetailText>
                              {studentState.dob}
                            </StudentDetailText>
                          </StudentDetailTextWrapper>
                          <StudentDetailTextWrapper>
                            <StudentDetailText bold>
                              Guardian:
                            </StudentDetailText>
                            <StudentDetailText>
                              {studentState.guardian}
                            </StudentDetailText>
                          </StudentDetailTextWrapper>
                          <StudentDetailTextWrapper>
                            <StudentDetailText bold>Address:</StudentDetailText>
                            <StudentDetailText>
                              {studentState.address}
                            </StudentDetailText>
                          </StudentDetailTextWrapper>
                        </Spacing>
                      </Grid>
                    </Grid>
                  </Grid>
                </StudentProfileContainer>
              </Spacing>
              <div
                className={classes.tableWrapper}
                style={{ marginTop: '5px' }}
              >
                <Spacing>
                  <TblContainer size="small">
                    <caption
                      style={{
                        borderBottom: '1px solid black',
                        borderLeft: '1px solid black',
                        borderRight: '1px solid black',
                        padding: '5px',
                      }}
                    >
                      <StudentDetailTextWrapper style={{ paddingLeft: '10px' }}>
                        <StudentDetailText></StudentDetailText>
                        <StudentDetailText>
                          Subtotal:{' '}
                          <span style={{ paddingRight: '20px' }}>
                            Rs. {feeState.subtotal}
                          </span>
                        </StudentDetailText>
                      </StudentDetailTextWrapper>
                      {/* <StudentDetailTextWrapper style={{ paddingLeft: "10px" }}>
                                                <StudentDetailText></StudentDetailText>
                                                <StudentDetailText>
                                                    Scholarship:{" "}
                                                    <span style={{ paddingRight: "20px" }}>
                                                        Rs. {feeState.scholarshipAmount}
                                                    </span>
                                                </StudentDetailText>
                                            </StudentDetailTextWrapper> */}
                      <StudentDetailTextWrapper style={{ paddingLeft: '10px' }}>
                        <StudentDetailText></StudentDetailText>
                        <StudentDetailText>
                          Discount:{' '}
                          <span style={{ paddingRight: '20px' }}>
                            Rs. {feeState.scholarshipAmount}
                          </span>
                        </StudentDetailText>
                      </StudentDetailTextWrapper>
                      <StudentDetailTextWrapper style={{ paddingLeft: '10px' }}>
                        <StudentDetailText></StudentDetailText>
                        <StudentDetailText>
                          Fine:{' '}
                          <span style={{ paddingRight: '20px' }}>
                            Rs. {feeState.fineAmount}
                          </span>
                        </StudentDetailText>
                      </StudentDetailTextWrapper>
                      <StudentDetailTextWrapper style={{ paddingLeft: '10px' }}>
                        <StudentDetailText>
                          Amount in Words:{' '}
                          <span style={{ paddingRight: '20px' }}>
                            {totalAmtWords}
                          </span>
                        </StudentDetailText>
                        <StudentDetailText>
                          Grand Total:{' '}
                          <span style={{ paddingRight: '20px' }}>
                            Rs. {feeState.payingAmount}
                          </span>
                        </StudentDetailText>
                      </StudentDetailTextWrapper>
                    </caption>
                    <caption
                      style={{
                        borderBottom: '1px solid black',
                        borderLeft: '1px solid black',
                        borderRight: '1px solid black',
                        padding: '5px',
                      }}
                    >
                      <StudentDetailTextWrapper
                        style={{
                          justifyContent: 'space-between',
                          paddingLeft: '10px',
                        }}
                      >
                        <StudentDetailText>Paid By: Cash</StudentDetailText>
                        <StudentDetailText>
                          Paid Amount: <span>Rs {feeState.paidAmount}</span>
                        </StudentDetailText>
                      </StudentDetailTextWrapper>
                      <StudentDetailTextWrapper
                        style={{
                          justifyContent: 'space-between',
                          paddingLeft: '10px',
                        }}
                      >
                        <StudentDetailText></StudentDetailText>
                        <StudentDetailText>
                          Balance:{' '}
                          <span>
                            Rs{' '}
                            {feeState.payingAmount - feeState.paidAmount < 0
                              ? `${Math.abs(
                                  feeState.payingAmount - feeState.paidAmount
                                )} (Adv.)`
                              : feeState.payingAmount - feeState.paidAmount > 0
                              ? `${
                                  feeState.payingAmount - feeState.paidAmount
                                } (Due)`
                              : 0}
                          </span>
                        </StudentDetailText>
                      </StudentDetailTextWrapper>
                    </caption>
                    <caption
                      style={{
                        borderBottom: '1px solid black',
                        borderLeft: '1px solid black',
                        borderRight: '1px solid black',
                        padding: '0',
                      }}
                    >
                      <StudentDetailTextWrapper
                        style={{ justifyContent: 'center' }}
                      >
                        <StudentDetailText>
                          All above mentioned Amount once paid are non
                          refundable in any case
                        </StudentDetailText>
                      </StudentDetailTextWrapper>
                    </caption>
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell
                          rowSpan={2}
                          align="center"
                          className={localClasses.tableCell}
                        >
                          Date
                        </StyledTableCell>
                        <StyledTableCell
                          rowSpan={2}
                          align="left"
                          className={localClasses.tableCell}
                        >
                          Particulars
                        </StyledTableCell>
                        <StyledTableCell
                          rowSpan={2}
                          align="center"
                          className={localClasses.tableCell}
                        >
                          Amount
                        </StyledTableCell>
                        {/* <StyledTableCell
                                                    rowSpan={2}
                                                    align="center"
                                                    className={localClasses.tableCell}
                                                >
                                                    Credits
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    colSpan={2}
                                                    align="center"
                                                    className={localClasses.tableCell}
                                                >
                                                    Balance
                                                </StyledTableCell> */}
                      </StyledTableRow>
                    </TableHead>

                    <TableBody>
                      {tableData.map(
                        (item: FeeLedgerByDateI, index: number) => (
                          <StyledTableRow key={index + 1}>
                            <StyledTableCell
                              align="center"
                              className={classes.cell}
                            >
                              {dateConverterAdToBs(item.created_on)}
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              className={classes.cell}
                              width="500px"
                            >
                              {item.fee_type} {item.month}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              className={classes.cell}
                            >
                              {item.amount_to_pay}
                            </StyledTableCell>
                          </StyledTableRow>
                        )
                      )}
                    </TableBody>
                  </TblContainer>
                </Spacing>
                {/* <Grid container spacing={3}>
                                    <Grid container item xs={12}>
                                        <Grid item xs={6}>
                                            <Spacing>
                                                {customizedData.grading_system && props.editor && (
                                                    <GradePointTable editMode={true} />
                                                )}
                                                {!props.editor && <GradePointTable editMode={false} />}
                                            </Spacing>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Spacing>
                                                {customizedData.ape_enable && props.editor && (
                                                    <ApeTable editMode={true} />
                                                )}
                                                {!props.editor && <ApeTable editMode={false} />}
                                            </Spacing>
                                        </Grid>
                                    </Grid>
                                </Grid> */}
                <Spacing>
                  <Paragraph
                    medium
                    left
                    style={{ marginTop: '20px', marginBottom: '40px' }}
                  >
                    {props.editor ? (
                      <input
                        type="text"
                        className="editable"
                        onChange={(event) => handleFooterText(event)}
                        style={{
                          width: '200px',
                          textAlign: 'left',
                        }}
                        value={customizedData.footer_title}
                      />
                    ) : (
                      'Verification / Approval:'
                    )}
                  </Paragraph>
                  <Grid
                    container
                    spacing={3}
                    style={{
                      marginTop: '10px',
                      marginBottom: '5px',
                    }}
                  >
                    <Grid container item xs={12}>
                      {/* <Grid item xs={4}>
                                                <Paragraph medium left overline>
                                                    {props.editor ? (
                                                        <input
                                                            type="text"
                                                            className="editable"
                                                            onChange={(event) => handleFooterText1(event)}
                                                            style={{
                                                                width: "150px",
                                                                textAlign: "center",
                                                                borderTop: "1px solid black",
                                                            }}
                                                            value={customizedData.footer_text_1}
                                                        />
                                                    ) : (
                                                        "Class Teacher"
                                                    )}
                                                </Paragraph>
                                            </Grid>
                                            <Grid item xs={4} alignContent="center">
                                                <Paragraph medium overline>
                                                    {props.editor ? (
                                                        <input
                                                            type="text"
                                                            className="editable"
                                                            onChange={(event) => handleFooterText2(event)}
                                                            style={{
                                                                width: "150px",
                                                                textAlign: "center",
                                                                borderTop: "1px solid black",
                                                            }}
                                                            value={customizedData.footer_text_2}
                                                        />
                                                    ) : (
                                                        "Exam Coordinator"
                                                    )}
                                                </Paragraph>
                                            </Grid> */}
                      <Grid item xs={4}>
                        <Grid container justifyContent="flex-start">
                          <Paragraph medium right overline>
                            {props.editor ? (
                              <input
                                type="text"
                                className="editable"
                                onChange={(event) => handleFooterText3(event)}
                                style={{
                                  width: '150px',
                                  textAlign: 'center',
                                  borderTop: '1px solid black',
                                }}
                                value={customizedData.footer_text_3}
                              />
                            ) : (
                              'The Principlal'
                            )}
                          </Paragraph>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Spacing>
              </div>
            </div>
          </div>
        </div>
        {props.editor && (
          <div
            style={{
              border: '2px dotted #445DA7',
              borderRadius: '5px',
              padding: '20px',
              margin: '25px',
            }}
          >
            <Grid container spacing={1}>
              <Grid container item xs={12} spacing={3}>
                <Grid item xs={5} className={formClasses.formWrapper}>
                  <FormControl>
                    <b>Enable / Disable Options</b>
                    <FormGroup
                      style={{ backgroundColor: 'white', marginTop: '20px' }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={customizedData.ape_enable}
                            onChange={handleapeChange}
                            name="ape_enable"
                          />
                        }
                        label="APE List"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={customizedData.grading_system}
                            onChange={handlegradingChange}
                            name="grading_system"
                          />
                        }
                        label="Grading System"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={customizedData.watermark}
                            onChange={handlewatermarkChange}
                            name="watermark"
                          />
                        }
                        label="WaterMark"
                      />
                      {/* <FormControlLabel
                      control={
                        <Checkbox
                          // checked={customizedData.watermark}
                          // onChange={handlewatermarkChange}
                          name="School Logo/Student Photo"
                        />
                      }
                      label="School Logo / Student Photo"
                    /> */}
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={4} className={formClasses.formWrapper}>
                  <b style={{ marginBottom: '10px' }}>Select Options</b>
                  {/* <FormControl>
                  <InputLabel htmlFor="demo-customized-select-native">
                    School Logo Position
                  </InputLabel>
                  <NativeSelect
                    id="demo-customized-select-native"
                    value={customizedData.school_logo_position}
                    onChange={handleChangeSchoolLogo}
                    input={<BootstrapInput />}
                  >
                    <option value="L">Top Left</option>
                    <option value="R">Top Right</option>
                  </NativeSelect>
                </FormControl> */}
                  {customizedData.watermark && (
                    <FormControl style={{ marginTop: '10px' }}>
                      <InputLabel htmlFor="demo-customized-select-native">
                        WaterMark Positin
                      </InputLabel>
                      <NativeSelect
                        id="demo-customized-select-native"
                        value={customizedData.watermark_position}
                        onChange={handleChangeWatermark}
                        input={<BootstrapInput />}
                      >
                        <option value="T">At Top</option>
                        <option value="C">At Center</option>
                      </NativeSelect>
                    </FormControl>
                  )}
                </Grid>
                <Grid item xs={3}></Grid>
              </Grid>
            </Grid>
            {/* <FormSaveButton
                            style={{ marginTop: "20px" }}
                            onClick={() => handlleSubmit()}
                        >
                            Add
                        </FormSaveButton> */}
          </div>
        )}
      </div>
      <BackDropLoader open={loading} />
    </>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default InvoiceModal;
