import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import SectionForm from "../../components/Academic/Section/SectionForm";
import { SectionTable, AcademicSidebar, Header } from "../../components";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";
import { SectionTypeI } from "../../actions/Academics/Section/SectionActionTypes";

function SectionPage() {
  const [formData, setFormData] = useState<any | null>(null);
  const [count, setCount] = useState<number | undefined>(0);
  const [edit, setEdit] = useState<any>();

  const fetchEditData = (data: any) => {
    setFormData({ ...data, count: count });
    count && setCount(count + 1);
  };

  const onEditMode = (value: boolean) => {
    setEdit({ value, count });
  };

  const nav = <AcademicSidebar section />;

  return (
    <SubModuleLayout sideNav={nav}>
      <Grid container>
        <Grid item xs={3}>
          <SectionForm editData={formData} onEditMode={onEditMode} />
        </Grid>
        <Grid item xs={9}>
          <SectionTable onEditData={fetchEditData} edit={edit} />
        </Grid>
      </Grid>
    </SubModuleLayout>
  );
}

export default SectionPage;
