export const GET_MARKS_LOADING = "GET_MARKS_LOADING";
export const GET_MARKS_ERROR = "GET_MARKS_ERROR";
export const GET_MARKS_SUCCESS = "GET_MARKS_SUCCESS";

export const ADD_MARKS_LOADING = "ADD_MARKS_LOADING";
export const ADD_MARKS_ERROR = "ADD_MARKS_ERROR";
export const ADD_MARKS_SUCCESS = "ADD_MARKS_SUCCESS";

export const UPDATE_MARKS_LOADING = "UPDATE_MARKS_LOADING";
export const UPDATE_MARKS_ERROR = "UPDATE_MARKS_ERROR";
export const UPDATE_MARKS_SUCCESS = "UPDATE_MARKS_SUCCESS";

export const DELETE_MARKS_LOADING = "DELETE_MARKS_LOADING";
export const DELETE_MARKS_ERROR = "DELETE_MARKS_ERROR";
export const DELETE_MARKS_SUCCESS = "DELETE_MARKS_SUCCESS";

export type MarksTypeI = {
  student_id: string;
  roll_number: number;
  student_full_name: string;
  full_marks: string;
  pass_marks: string;
  marks_obtained: string;
  remarks: string | null;
};

//===============================<START>GET MARKS<START>======================//

export interface GetMarksLoading {
  type: typeof GET_MARKS_LOADING;
}

export interface GetMarksError {
  type: typeof GET_MARKS_ERROR;
}

export interface GetMarksSuccess {
  type: typeof GET_MARKS_SUCCESS;
  payload: MarksTypeI[];
}

//===============================<END>GET MARKS<END>======================//

//===============================<START>ADD MARKS<START>======================//

export interface AddMarksLoading {
  type: typeof ADD_MARKS_LOADING;
}

export interface AddMarksError {
  type: typeof ADD_MARKS_ERROR;
}

export interface AddMarksSuccess {
  type: typeof ADD_MARKS_SUCCESS;
  payload: MarksTypeI;
}

//===============================<END>ADD MARKS<END>======================//

//===============================<START>UPDATE MARKS<START>======================//

export interface UpdateMarksLoading {
  type: typeof UPDATE_MARKS_LOADING;
}

export interface UpdateMarksError {
  type: typeof UPDATE_MARKS_ERROR;
}

export interface UpdateMarksSuccess {
  type: typeof UPDATE_MARKS_SUCCESS;
  payload: MarksTypeI;
}

//===============================<END>UPDATE MARKS<END>======================//

//===============================<START>DELETE MARKS<START>======================//

export interface DeleteMarksLoading {
  type: typeof DELETE_MARKS_LOADING;
}

export interface DeleteMarksError {
  type: typeof DELETE_MARKS_ERROR;
}

export interface DeleteMarksSuccess {
  type: typeof DELETE_MARKS_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE MARKS<END>======================//

export type MarksDispatchTypes =
  | GetMarksLoading
  | GetMarksError
  | GetMarksSuccess
  | AddMarksLoading
  | AddMarksError
  | AddMarksSuccess
  | UpdateMarksLoading
  | UpdateMarksError
  | UpdateMarksSuccess
  | DeleteMarksLoading
  | DeleteMarksError
  | DeleteMarksSuccess;
