// -----------------<START> import modules starts----------------------//
import React, { useEffect, useState } from 'react';
import { Button, Grid, TextField, InputLabel } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { statusChoices } from '../../../common/json.constant';
import { Search } from '@material-ui/icons';
import { useFormStyles } from '../../Styles/FormStyles';
import 'nepali-datepicker-reactjs/dist/index.css';
import { ClassTypeI } from '../../../actions/Academics/Class/ClassActionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import { GetClasses } from '../../../actions/Academics/Class/ClassAction';
import {
  GetDueFeeReport,
  GetFeeLedgerReport,
} from '../../../actions/Fees/FeeLedger/FeeLedgerAction';
import {
  dateConverterAdToBs,
  dateConverterBsToAd,
} from '../../utils/dateConverter';
import FullWidthFormLayout from '../../Reusable/Layouts/FullWidthForm.layout';
import CustomizedSelect, { RowSelect } from '../../Reusable/Inputs/Select';
import CustomizedNepaliDatePicker, {
  RowNepaliDatePicker,
} from '../../Reusable/Inputs/NepaliDatePicker';
import { FormResetButton } from '../../Reusable/Buttons/FormButton';
import { ResetButton, SubmitButton } from '../../Reusable/Buttons/SearchButton';
import { CUR_NEPALI_DATE } from '../../utils/nepaliDateUtils';
import { useForm } from 'react-hook-form';
// -----------------<END> imports module ends -----------------------//

// -----------------<start> Styling <start>-----------------------//

// -----------------<end> Styling <end>-----------------------//

// -----------------<start> Interfaces starts-----------------------//

interface Tupple {
  key: string;
  value: string;
}

// -----------------<END> Interfaces ends-----------------------//

// -----------------<START> Fee Ledger Form component starts-----------------------//
const FeeLedgerForm: React.FC = () => {
  const classes = useFormStyles();

  const [gradeChoices, setGradeChoices] = useState<any | null>([]);
  const [sectionChoices, setSectionChoices] = useState<any[] | []>([]);
  const [grade, setGrade] = useState<any | null>(null);
  const [section, setSection] = useState<any | null>(null);
  const [sectionDisabler, setSectionDisabler] = useState<boolean>(true);

  const [toDate, setToDate] = useState<string>(CUR_NEPALI_DATE);
  const [fromDate, setFromDate] = useState<string>(CUR_NEPALI_DATE);

  const { register, handleSubmit, reset, errors, setError, clearErrors } =
    useForm<any>({
      mode: 'onChange',
    });

  const dispatch = useDispatch();
  const classSelector = useSelector((state: RootStore) => state.class);

  useEffect(() => {
    dispatch(GetClasses());
  }, []);

  useEffect(() => {
    const data = classSelector.classes;
    setGradeChoices(data);
  }, [classSelector]);

  const handleFromDate = (date: string) => {
    setFromDate(date);
  };

  const handleToDate = (date: string) => {
    setToDate(date);
  };

  const handleSearch = () => {
    const grade_id = grade ? grade.id : null;
    const section_id = section ? section.id : '';
    const from_date = dateConverterBsToAd(fromDate);
    const to_date = dateConverterBsToAd(toDate);

    if (grade_id) {
      dispatch(GetFeeLedgerReport(grade_id, section_id, from_date, to_date));
    } else {
      setError('grade', {
        type: 'required',
        message: 'Grade must be selected',
      });
    }
  };

  const handleReset = () => {
    setGrade(null);
    setSection(null);
    dispatch(GetFeeLedgerReport());
  };

  const handleGradeChange = (value: any | null) => {
    if (value) {
      setGrade(value);
      populateSectionChoices(value.section);
      clearErrors('grade');
    } else {
      setGrade(null);
      setSection(null);
      setSectionDisabler(true);
    }
  };

  const populateSectionChoices = (sections: any | []) => {
    if (sections.length) {
      setSectionDisabler(false);
      setSectionChoices(sections);
    } else {
      setSection(null);
      setSectionDisabler(true);
    }
  };

  return (
    <>
      <FullWidthFormLayout
        title="Search Invoice"
        onSubmit={handleSubmit(handleSearch)}
      >
        <Grid container spacing={1}>
          <Grid item xs={3} className={classes.formWrapper}>
            <InputLabel>Class</InputLabel>
            <Autocomplete
              onChange={(event: React.ChangeEvent<{}>, value: any | null) =>
                handleGradeChange(value)
              }
              options={gradeChoices}
              getOptionLabel={(option) => 'Class' + ' ' + option.grade_name}
              value={grade}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Class"
                  name="grade"
                  variant="outlined"
                />
              )}
            />
            <span className={classes.validationErrorInfo}>
              {errors.grade && errors.grade.message}
            </span>
          </Grid>
          <Grid item xs={3} className={classes.formWrapper}>
            <InputLabel>Section</InputLabel>
            <Autocomplete
              onChange={(event: React.ChangeEvent<{}>, value: any | null) =>
                setSection(value)
              }
              options={sectionChoices}
              value={section}
              getOptionLabel={(option) => option.name}
              disabled={sectionDisabler}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Section"
                  name="section"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formWrapper}>
            <CustomizedNepaliDatePicker
              label="From Date"
              value={fromDate}
              name="from_date"
              setValue={handleFromDate}
            />
          </Grid>
          <Grid item xs={3} className={classes.formWrapper}>
            <CustomizedNepaliDatePicker
              label="To Date"
              value={toDate}
              name="to_date"
              setValue={handleToDate}
            />
          </Grid>
          <SubmitButton style={{ marginTop: '5px' }} />
          <ResetButton style={{ marginTop: '5px' }} onClick={handleReset} />
        </Grid>
      </FullWidthFormLayout>
    </>
  );
};

//-----------------<ENDS> Fee Ledger form component ends-----------------------//
export default FeeLedgerForm;
