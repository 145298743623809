import { useState } from 'react'
import StudentSidebar from "../../components/Student/StudentSidebar/StudentSidebar";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";
import { breadcrumb } from "./Student.Breadcrumbs";
import { AttendanceReportSearch, AttendanceReportChart } from "../../components"

import { Tupple } from "../../actions";

const StudentAttendanceReportPage = () => {
    const [monthName, setMonthName] = useState<Tupple | null>(null);
    const nav = <StudentSidebar studentAttendance />;
    return (
        <SubModuleLayout module={breadcrumb} sideNav={nav}>
            <div style={{ margin: "0px 15px" }}>
                <AttendanceReportSearch
                    setMonthName={setMonthName}
                />
                <AttendanceReportChart
                    monthName={monthName}
                />
            </div>
        </SubModuleLayout>
    )
}

export default StudentAttendanceReportPage