import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { AcademicSidebar } from "../../components";
import ClassTable from "../../components/Academic/ClassNew/ClassForm";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const NewSubjectTeacher = () => {
  const nav = <AcademicSidebar subjectTeacher />;
  return (
    <SubModuleLayout academicMenuActive sideNav={nav}>
      <ClassTable />
    </SubModuleLayout>
  );
};

export default NewSubjectTeacher;
