// ---------------------------- <START> module imports start ----------------------------------//
import React, { useEffect, useState, useRef } from "react";
import {
  TableBody,
  Grid,
  TableHead,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { PrintTableStyles } from "../../Styles/PrintTableStyles";
import { useTablePrint } from "../../Reusable";
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "../../../store";
import { MarkSheetTypeI } from "../../../actions/Examination/MarkSheet/MarkSheetActionTypes";
import { Printd } from "printd";
import {
  TopTagline,
  SchoolInfoContainer,
  SchoolTitle,
  Paragraph,
  ReportType,
  StudentProfileContainer,
  StudentDetailTextWrapper,
  StudentDetailText,
  Spacing,
  Sticky,
  DoubleBorder,
  // PrintNow,
} from "../Marksheet/ReportStyles";
import GradePointTable from "../Marksheet/GradePointTable";
import ApeTable from "../Marksheet/ApeTable";
import printJS from "print-js";
import ReactToPrint from "react-to-print";
import { dateConverterAdToBs } from "../../utils/dateConverter";
import getFullName from "../../utils/getFullName";
import getFullClassName from "../../utils/getFullClassName";
import { GetReportSetting } from "../../../actions/GeneralSettings/ReportSetting/ReportSettingAction";
import checkImageUrl from "../../utils/checkImageUrl";
// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//

interface HeadCellsI {
  id: string;
  label: string;
}
// ---------------------------- <END> interface ends ----------------------------------//

interface HeadCellsI {
  id: string;
  label: string;
}

// -------------------- <START> Table Headings Data ---------------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S. No." },
  { id: "subject", label: "Subjects" },
  { id: "fullMarks", label: "Full Marks" },
  { id: "passMarks", label: "Pass Marks" },
  { id: "th", label: "TH" },
  { id: "pr", label: "PR" },
  { id: "total", label: "Total" },
  { id: "grade_point", label: "Grade Point" },
];
// ---------------------<END> Table Headings Data Ends --------------------------//

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2, 4),
    "& .MuiCardContent-root": {
      padding: theme.spacing(0),
    },
    "& .MuiTypography-h2": {
      fontSize: "3.1rem",
    },
    caption: {
      padding: "0px",
    },
  },
  idImage: {
    width: "80px",
    height: "80px",
  },
  line: {
    borderRight: "1px solid black",
  },
  bottom: {
    marginBottom: theme.spacing(1),
  },
  tableCell: {
    padding: "0px 5px !important",
    border: "1px solid black",
  },
  topWatermark: {
    backgroundImage: `url('https://i.imgur.com/KO5poDX.png')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "70%",
    backgroundPosition: "center",
  },
  centerWatermark: {
    backgroundImage: `url('https://i.imgur.com/KO5poDX.png')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "70%",
    backgroundPosition: "center",
  },
}));

interface propsI {
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  student: string;
  gpa: number;
}

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const MultipleGradeSheetPopup = (props: any) => {
  const { onClose, student, content } = props;
  const classes = PrintTableStyles();
  const localClasses = useStyles();
  const dispatch = useDispatch();

  // States declaration
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<MarkSheetTypeI[]>([]);
  const [printMode, setPrintMode] = useState<boolean>(false);

  const componentRef = useRef<any>();

  const schoolState = useSelector(
    (state: RootStore) => state.auth.general_info
  );
  const examState = useSelector((state: RootStore) => state.exam.exam);
  const studentState = useSelector(
    (state: RootStore) => state.student.student_by_id
  );
  const reportSettingState = useSelector(
    (state: RootStore) => state.report_setting.reportsetting
  );

  // Retrieving re-usable components from useTable
  const {
    TblContainer,
    TblHead,
    StyledTableCell,
    StyledTableRow,
    exportToExcel,
    tablePrint,
  } = useTablePrint(headCells);

  // ============================ <START> REACT HOOKS <START> ============================== //
  const marksheetSelector = useSelector((state: RootStore) => state.marksheet);

  useEffect(() => {
    setLoading(marksheetSelector.loading);
    setTableData(marksheetSelector.marksheet);
  }, [marksheetSelector]);

  useEffect(() => {
    dispatch(GetReportSetting());
  }, []);

  // ============================ <END> REACT HOOKS <END> ============================== //

  // ============================ <START> EVENT HANDLERS <START> ============================== //

  // Event function for table searching by name
  const handleTableSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    return;
  };

  const handlePrint = (e: any) => {
    e.preventDefault();
    const d = new Printd();
    const modalRoot = document.getElementById("print-element") as HTMLElement;
    d.print(modalRoot);
  };
  // ============================ <END> EVENT HANDLERS <END> ============================== //
  return (
    <>
      <Sticky>
        <ReactToPrint
          trigger={() => (
            <p
              style={{
                backgroundColor: "#132E98",
                color: "white",
                fontWeight: "bold",
                borderRadius: "0px",
                margin: "0px",
                textAlign: "center",
                padding: "5px 0px",
                cursor: "pointer",
              }}
            >
              Print This Out !
            </p>
          )}
          content={() => componentRef.current}
          removeAfterPrint={true}
          copyStyles={true}
        />
      </Sticky>
      <div className={classes.roottable} id="print-element" ref={componentRef}>
        <div
          style={{
            backgroundImage: `url(${content.watermark &&
              content.watermark_position === "C" &&
              checkImageUrl(
                reportSettingState && reportSettingState[0]?.watermark
              )
              })`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "300px",
            backgroundPosition: "center",
          }}
        >
          {/* Single Marksheet Starts */}
          {props.multipleMarksheet &&
            props.multipleMarksheet.map((elem: any, index: number) => {
              return (
                <>
                  <div className="marksheet-print">
                    <div
                      style={{
                        margin: "15px",
                        borderStyle: "double",
                        padding: "25px",
                      }}
                      key={index}
                    >
                      <Spacing>
                        <div
                          style={{
                            backgroundImage: `url(${content.watermark &&
                              content.watermark_position === "T" &&
                              checkImageUrl(
                                reportSettingState &&
                                reportSettingState[0]?.watermark
                              )
                              })`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "300px",
                            backgroundPosition: "center",
                          }}
                        >
                          <Paragraph small left>
                            <b>Reg. No: {schoolState?.school_reg_number}</b>
                          </Paragraph>
                          <SchoolInfoContainer>
                            <Paragraph small>{schoolState?.slogan}</Paragraph>
                            <SchoolTitle>
                              {schoolState?.school_name}
                            </SchoolTitle>
                            <Paragraph medium>{schoolState?.address}</Paragraph>
                            <Paragraph medium>
                              Phone: {schoolState?.phone_number}
                            </Paragraph>
                            <Paragraph medium>
                              Email: {schoolState?.email}
                            </Paragraph>
                            <ReportType>
                              <Paragraph large>GradeSheet</Paragraph>
                              <Paragraph large>
                                {examState?.exam_name}{" "}
                                {examState?.start_date &&
                                  dateConverterAdToBs(
                                    examState?.start_date
                                  ).slice(0, 4)}
                              </Paragraph>
                            </ReportType>
                          </SchoolInfoContainer>
                        </div>
                        <StudentProfileContainer>
                          <Spacing>
                            <Paragraph medium left underline>
                              Personal/Academic Information:
                            </Paragraph>
                          </Spacing>
                          <Grid
                            container
                            spacing={3}
                            style={{ marginTop: "0px" }}
                          >
                            <Grid container item xs={12} className="nothing">
                              <Grid item xs={6}>
                                <Spacing>
                                  <StudentDetailTextWrapper>
                                    <StudentDetailText bold>
                                      Student Name:
                                    </StudentDetailText>
                                    <StudentDetailText>
                                      {getFullName(
                                        elem.first_name,
                                        elem.last_name
                                      )}
                                    </StudentDetailText>
                                  </StudentDetailTextWrapper>
                                  <StudentDetailTextWrapper>
                                    <StudentDetailText bold>
                                      School ID/Roll No:
                                    </StudentDetailText>
                                    <StudentDetailText>
                                      {elem.roll_no}
                                    </StudentDetailText>
                                  </StudentDetailTextWrapper>
                                  <StudentDetailTextWrapper>
                                    <StudentDetailText bold>
                                      Class:
                                    </StudentDetailText>
                                    <StudentDetailText>
                                      {getFullClassName(
                                        elem.grade,
                                        elem.section
                                      )}
                                    </StudentDetailText>
                                  </StudentDetailTextWrapper>
                                </Spacing>
                              </Grid>
                              <Grid item xs={6}>
                                <Spacing>
                                  <StudentDetailTextWrapper>
                                    <StudentDetailText bold>
                                      Date of Birth:
                                    </StudentDetailText>
                                    <StudentDetailText>
                                      {elem.dob}
                                    </StudentDetailText>
                                  </StudentDetailTextWrapper>
                                  <StudentDetailTextWrapper>
                                    <StudentDetailText bold>
                                      Guardian:
                                    </StudentDetailText>
                                    <StudentDetailText>
                                      {getFullName(
                                        elem.guardian_first_name,
                                        elem.guardian_last_name
                                      )}
                                    </StudentDetailText>
                                  </StudentDetailTextWrapper>
                                  <StudentDetailTextWrapper>
                                    <StudentDetailText bold>
                                      Address:
                                    </StudentDetailText>
                                    <StudentDetailText>
                                      {elem.address}
                                    </StudentDetailText>
                                  </StudentDetailTextWrapper>
                                </Spacing>
                              </Grid>
                            </Grid>
                          </Grid>
                        </StudentProfileContainer>
                      </Spacing>
                      <div
                        className={classes.tableWrapper}
                        style={{ marginTop: "5px" }}
                      >
                        <Spacing>
                          <TblContainer size="small">
                            <caption
                              style={{
                                borderBottom: "1px solid black",
                                borderLeft: "1px solid black",
                                borderRight: "1px solid black",
                                padding: "5px",
                              }}
                            >
                              <StudentDetailTextWrapper
                                style={{ paddingRight: "50px" }}
                              >
                                <StudentDetailText>
                                  TH: Theory | PR: Pratical | APE: Academic
                                  Performance Evaluation
                                </StudentDetailText>
                                <StudentDetailText bold>
                                  Average: {elem.obtain_gpa + " GPA"} (
                                  {elem.obtain_percentage + "%"})
                                </StudentDetailText>
                              </StudentDetailTextWrapper>
                            </caption>
                            <TableHead>
                              <StyledTableRow>
                                <StyledTableCell
                                  rowSpan={2}
                                  align="center"
                                  className={localClasses.tableCell}
                                >
                                  S. No.
                                </StyledTableCell>
                                <StyledTableCell
                                  rowSpan={2}
                                  align="left"
                                  className={localClasses.tableCell}
                                >
                                  Subjects
                                </StyledTableCell>
                                <StyledTableCell
                                  rowSpan={2}
                                  align="center"
                                  className={localClasses.tableCell}
                                >
                                  Full Marks
                                </StyledTableCell>
                                <StyledTableCell
                                  rowSpan={2}
                                  align="center"
                                  className={localClasses.tableCell}
                                >
                                  Pass Marks
                                </StyledTableCell>
                                <StyledTableCell
                                  colSpan={2}
                                  align="center"
                                  className={localClasses.tableCell}
                                >
                                  Obtained Marks
                                </StyledTableCell>
                                <StyledTableCell
                                  rowSpan={2}
                                  align="center"
                                  className={localClasses.tableCell}
                                >
                                  Total
                                </StyledTableCell>
                                <StyledTableCell
                                  rowSpan={2}
                                  align="center"
                                  className={localClasses.tableCell}
                                >
                                  Grade Point
                                </StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow>
                                <StyledTableCell
                                  align="center"
                                  className={localClasses.tableCell}
                                >
                                  TH
                                </StyledTableCell>
                                <StyledTableCell
                                  align="center"
                                  className={localClasses.tableCell}
                                >
                                  PR
                                </StyledTableCell>
                              </StyledTableRow>
                            </TableHead>

                            <TableBody>
                              {elem.obtain_subject_marks.map(
                                (item: MarkSheetTypeI, index: number) => (
                                  <StyledTableRow key={index}>
                                    <StyledTableCell
                                      align="center"
                                      className={classes.cell}
                                    >
                                      {index + 1}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      className={classes.cell}
                                    >
                                      {item.exam_subject__subject__name}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="center"
                                      className={classes.cell}
                                    >
                                      {item.exam_subject__full_marks}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="center"
                                      className={classes.cell}
                                    >
                                      {item.exam_subject__pass_marks}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="center"
                                      className={classes.cell}
                                    >
                                      {item.marks_obtained_theory}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="center"
                                      className={classes.cell}
                                    >
                                      {item.marks_obtained_practical}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="center"
                                      className={classes.cell}
                                    >
                                      {item.total_marks_obtained}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="center"
                                      className={classes.cell}
                                    >
                                      {item.grade_point_total}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                )
                              )}
                            </TableBody>
                          </TblContainer>
                        </Spacing>
                        <Grid container spacing={3}>
                          <Grid container item xs={12}>
                            {content?.grading_system && (
                              <Grid item xs={6}>
                                <Spacing>
                                  <GradePointTable editMode={false} />
                                </Spacing>
                              </Grid>
                            )}
                            {content?.ape_enable && (
                              <Grid item xs={6}>
                                <Spacing>
                                  <ApeTable editMode={false} />
                                </Spacing>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                        <Spacing>
                          <Paragraph
                            medium
                            left
                            style={{ marginTop: "20px", marginBottom: "40px" }}
                          >
                            {content?.footer_title}
                          </Paragraph>
                          <Grid
                            container
                            spacing={3}
                            style={{
                              marginTop: "10px",
                              marginBottom: "5px",
                            }}
                          >
                            <Grid container item xs={12}>
                              <Grid item xs={4}>
                                <Paragraph medium left overline>
                                  {content?.footer_text_1}
                                </Paragraph>
                              </Grid>
                              <Grid item xs={4}>
                                <Paragraph medium overline>
                                  {content?.footer_text_2}
                                </Paragraph>
                              </Grid>
                              <Grid item xs={4}>
                                <Paragraph medium right overline>
                                  {content?.footer_text_3}
                                </Paragraph>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Spacing>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          {/* Single Marksheet Ends */}
        </div>
      </div>
    </>
  );
};

// ---------------------------- <END> Student Table Component Ends -------------------------------//
export default MultipleGradeSheetPopup;
