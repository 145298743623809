import {
    Paper
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";
import { FeeCategorySelect, StudentSelectTable } from "../..";
import { ApplyFeeToStudents } from "../../../actions/Fees/FeeType/FeeAction";
import { setSnackbar } from "../../../actions/SnackbarAction";
import { RootStore } from "../../../store";
import BackDropLoader from "../../Reusable/BackDropLoader";
import SubModuleLayout from "../../Reusable/Layouts/SubModuleLayout";
import FinanceSidebar from "../FinanceSidebar";
import DueAlertSearch from "./DueAlertSearch";
import DueAlertTable from "./DueAlertTable";
import DaybookSearch from "./DueReportSearch";
import DaybookTable from "./DueReportTable";

interface SelectedFeesI {
    id: string;
    title: string;
    isMonthly: boolean;
}

export default function DueAlertPage() {
    const [fee, setFee] = useState<SelectedFeesI[]>([]);
    const [month, setMonth] = useState<any>();
    const [student, setStudent] = useState<any>(null);
    const [grade, setGrade] = useState<any | null>(null);
    const [section, setSection] = useState<any | null>(null);
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [selected, setSelected] = useState<Array<string>>([]);


    const dispatch = useDispatch();

    const nav = <FinanceSidebar due_alert />;

    return (

        <SubModuleLayout sideNav={nav}>
            <Paper style={{ margin: "10px" }}>
                <DueAlertSearch
                    student={student}
                    setStudent={setStudent}
                    grade={grade}
                    setGrade={setGrade}
                    section={section}
                    setSection={setSection}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    fee={fee}
                    setFee={setFee}
                    month={month}
                    setMonth={setMonth}
                />
                <DueAlertTable
                    grade={grade}
                    section={section}
                    startDate={startDate}
                    endDate={endDate}
                    fee={fee}
                    setFee={setFee}
                    month={month}
                    setMonth={setMonth}
                    setSelected={setSelected}
                    selected={selected}
                />
            </Paper>
            <BackDropLoader open={loading} />
        </SubModuleLayout>
    );
}