//---------------------------- <START> module imports start ----------------------------//
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useHistory } from 'react-router-dom';
import cx from 'clsx';
import { Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { RootStore } from '../../store';
import { PageRoleAccess } from '../utils/rolesConfig';
//---------------------------- <END> module imports start ------------------------------//

//---------------------------- <START> interface start ------------------------------//
interface DatasetI {
  text: string;
  url: string;
  activeStatus?: boolean;
  dataSet?: Array<object>;
  permission?: string;
}
//---------------------------- <END> interface ends ------------------------------//

// ---------------------------- <START> styling start ----------------------------------//

const useStyles = makeStyles({
  Sidebar: {
    marginTop: '100px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    left: '0px',
    // width: "230px",
    // height: '100vh',
    // borderRight: '1px solid #d3cfcf',
    backgroundColor: 'white',
  },
  sidebarContent: {
    padding: '1rem',
  },
  sidebarTitle: {
    paddingBottom: '1.5rem',
  },
  listSelected: {
    color: 'white',
    backgroundColor: '#132E98',
    borderRadius: '2px',
    '&:hover': {
      color: 'white',
      backgroundColor: '#132E98',
    },
  },
  listText: {
    '& .MuiListItemText-primary': {
      fontSize: '0.9rem',
    },
  },
  linkStyle: {
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    '& .activeLink': {
      color: 'red',
    },
  },
  listStyle: {
    padding: '7px',
    borderBottom: '1px solid rgba(0,0,0,0.1)',
  },
  nestedList: {
    padding: '6px 0px 6px 24px !important',
    marginLeft: '8px',
    width: 'calc(100% - 8px)',
  },
  nestedRoot: {
    '& .MuiCollapse-container.MuiCollapse-entered': {
      transition: 'none!important',
      // marginTop: "4px",
    },

    '& *': {
      transition: 'none !important',
    },
  },
});

// ---------------------------- <END> styling ends ----------------------------------//

// ---------------------------- <START> re-usable component starts ---------------------------//
const useSidebar = (dataSet: DatasetI[], module: string) => {
  const classes = useStyles();
  const history = useHistory();

  const authState = useSelector((state: RootStore) => state.auth);

  const handleLinkClick = (to: string) => {
    history.push(`/${to}`);
  };

  // const CustomSidebar = () => (
  //   <List component="nav" aria-label="inner sidebar list">
  //     {dataSet.map((element: DatasetI, index: number) => (
  //       <ListItem
  //         button
  //         className={cx(element.activeStatus && classes.listSelected)}
  //         key={index}
  //         onClick={() => handleLinkClick(element.url)}
  //       >
  //         <ListItemText
  //           classes={{ root: classes.listText }}
  //           primary={element.text}
  //         />
  //       </ListItem>
  //     ))}
  //   </List>
  // );

  const NestedList = (props: { list: DatasetI }) => {
    const [open, setOpen] = React.useState(false);
    const [isActive, setIsActive] = React.useState(false);

    const handleClick = () => {
      setOpen(!open);
    };

    useEffect(() => {
      props.list.dataSet &&
        props.list.dataSet.map((item: any) => {
          if (!open && item.activeStatus) {
            setOpen(true);
            setIsActive(true);
          }
        });
    }, []);

    return (
      <>
        <ListItem
          button
          onClick={handleClick}
          className={cx(classes.listStyle, isActive && classes.listSelected)}
        >
          <ListItemText
            primary={props.list.text}
            classes={{ root: classes.listText }}
          />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={open} timeout="auto" unmountOnExit>
          {props.list.dataSet?.map((item: any, index) => {
            return (
              <List component="div" disablePadding key={index}>
                <ListItem
                  button
                  onClick={() => handleLinkClick(item.url)}
                  className={cx(
                    item.activeStatus && classes.listSelected,
                    classes.nestedList
                  )}
                  style={
                    item.activeStatus && {
                      backgroundColor: 'rgba(0,0,0,0.1)',
                      color: '#222',
                    }
                  }
                >
                  <ListItemText
                    classes={{ root: classes.listText }}
                    primary={item.text}
                  />
                </ListItem>
              </List>
            );
          })}
        </Collapse>
      </>
    );
  };

  const SimpleList = (props: {
    list: DatasetI;
    authState: any;
    module: string;
  }) => {
    return (
      <>
        {PageRoleAccess(
          authState?.role,
          props.module,
          props.list.permission
        ) ? (
          <ListItem
            button
            className={cx(
              classes.listStyle,
              props.list.activeStatus && classes.listSelected
            )}
            onClick={() => handleLinkClick(props.list.url)}
          >
            <ListItemText
              classes={{ root: classes.listText }}
              primary={props.list.text}
            />
          </ListItem>
        ) : null}
      </>
    );
  };

  const CustomSidebar = () => (
    <List
      component="nav"
      aria-label="inner sidebar list"
      className={classes.nestedRoot}
    >
      {dataSet.map((element: DatasetI, index: number) =>
        element.dataSet ? (
          <NestedList key={index} list={element} />
        ) : (
          <SimpleList
            key={index}
            list={element}
            authState="Accountant"
            module="FINANCE"
          />
        )
      )}
    </List>
  );

  const CustomSidebarWithPermission = () => {
    return (
      <List
        component="nav"
        aria-label="inner sidebar list"
        className={classes.nestedRoot}
      >
        <>
          {dataSet.map((element: DatasetI, index: number) =>
            element.dataSet ? (
              <NestedList key={index} list={element} />
            ) : (
              <SimpleList
                key={index}
                list={element}
                authState={authState}
                module={module}
              />
            )
          )}
        </>
      </List>
    );
  };

  return {
    CustomSidebar,
    CustomSidebarWithPermission,
  };
};

// ---------------------------- <END> re-usable component ends ----------------------------------//

export default useSidebar;
