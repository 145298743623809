import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GradingI, InitialState } from "./grading";
import { getGradings, updateGrading } from "./gradingApi";

const initialState: InitialState = {
  loading: false,
  actionPerformed: false,
  gradings: [],
};

const gradingSlice = createSlice({
  name: "Gradings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getGradings.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(
      getGradings.fulfilled,
      (state, { payload }: PayloadAction<GradingI[]>) => {
        state.loading = false;
        state.gradings = payload;
      }
    );
    builder.addCase(getGradings.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateGrading.pending, (state) => {
      state.loading = true;
      state.actionPerformed = false;
    });
    builder.addCase(
      updateGrading.fulfilled,
      (state, { payload }: PayloadAction<GradingI>) => {
        state.loading = false;

        const data = state.gradings.find((el) => el.id === payload.id);

        if (data) {
          data.description = payload.description;
        }
        state.actionPerformed = true;
      }
    );
    builder.addCase(updateGrading.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default gradingSlice.reducer;
