import axios from "axios";
import { Dispatch } from "redux";
import {
  GET_TEACHER_LIST_LOADING,
  GET_TEACHER_LIST_ERROR,
  GET_TEACHER_LIST_SUCCESS,
  TeacherListDispatchTypes,
} from "./TeacherListActionTypes";
import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL, PARENT_API_URL } from "../../../config/SERVER_API_URLS";

export const GetTeacherList =
  (grade: string, section: string, subject: string, teacher: string) =>
  async (dispatch: Dispatch<TeacherListDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_TEACHER_LIST_LOADING,
      });
      const url = `${ADMIN_API_URL}/teacher_list/?grade=${grade}&teacher=${teacher}&subject=${subject}&section=${section}`;

      const res = await axios.get(url, HeaderConfig());

      dispatch({
        type: GET_TEACHER_LIST_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_TEACHER_LIST_ERROR,
      });
    }
  };
