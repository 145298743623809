import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getInvoiceSettings, postInvoiceSettings } from "./invoiceSettingsApi";

const initialState: {
  loading: boolean;
  invoiceSettings: {
    prefix: string | null;
    start_from: number;
  } | null;
} = {
  loading: false,
  invoiceSettings: null,
};

const invoiceSlice = createSlice({
  name: "invoiceSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getInvoiceSettings.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getInvoiceSettings.fulfilled,
      (
        state,
        {
          payload,
        }: PayloadAction<{ prefix: string | null; start_from: number }>
      ) => {
        state.loading = false;
        state.invoiceSettings = payload;
      }
    );
    builder.addCase(getInvoiceSettings.rejected, (state) => {
      state.loading = false;
      state.invoiceSettings = null;
    });

    builder.addCase(postInvoiceSettings.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      postInvoiceSettings.fulfilled,
      (
        state,
        { payload }: PayloadAction<{ prefix: string; start_from: number }>
      ) => {
        state.loading = false;
        state.invoiceSettings = { ...state.invoiceSettings, ...payload };
      }
    );
    builder.addCase(postInvoiceSettings.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default invoiceSlice.reducer;
