export const SMS_CONFIG_LOADING = "SMS_CONFIG_LOADING";
export const SMS_CONFIG_ERROR = "SMS_CONFIG_ERROR";
export const SMS_CONFIG_SUCCESS = "SMS_CONFIG_SUCCESS";

export const ADD_SMS_CONFIG_LOADING = "ADD_SMS_CONFIG_LOADING";
export const ADD_SMS_CONFIG_ERROR = "ADD_SMS_CONFIG_ERROR";
export const ADD_SMS_CONFIG_SUCCESS = "ADD_SMS_CONFIG_SUCCESS";

export const UPDATE_SMS_CONFIG_LOADING = "UPDATE_SMS_CONFIG_LOADING";
export const UPDATE_SMS_CONFIG_ERROR = "UPDATE_SMS_CONFIG_ERROR";
export const UPDATE_SMS_CONFIG_SUCCESS = "UPDATE_SMS_CONFIG_SUCCESS";

export type SmsConfigType = {
  id: number;
  sms_api_host: string;
  sms_api_key: string;
};

//===============================<START>GET SMS_CONFIG<START>======================//

export interface SmsConfigLoading {
  type: typeof SMS_CONFIG_LOADING;
}

export interface SmsConfigError {
  type: typeof SMS_CONFIG_ERROR;
}

export interface SmsConfigSuccess {
  type: typeof SMS_CONFIG_SUCCESS;
  payload: SmsConfigType[];
}

//===============================<END>GET SMS_CONFIG<END>======================//

//===============================<START>ADD SMS_CONFIG<START>======================//

export interface AddSmsConfigLoading {
  type: typeof ADD_SMS_CONFIG_LOADING;
}

export interface AddSmsConfigError {
  type: typeof ADD_SMS_CONFIG_ERROR;
}

export interface AddSmsConfigSuccess {
  type: typeof ADD_SMS_CONFIG_SUCCESS;
  payload: SmsConfigType[];
}

//===============================<END>ADD SMS_CONFIG<END>======================//

//===============================<START>UPDATE SMS_CONFIG<START>======================//

export interface UpdateSmsConfigLoading {
  type: typeof UPDATE_SMS_CONFIG_LOADING;
}

export interface UpdateSmsConfigError {
  type: typeof UPDATE_SMS_CONFIG_ERROR;
}

export interface UpdateSmsConfigSuccess {
  type: typeof UPDATE_SMS_CONFIG_SUCCESS;
  payload: SmsConfigType[];
}

//===============================<END>UPDATE SMS_CONFIG<END>======================//

//===============================<END>GET SMS_CONFIG<END>======================//

export type SmsConfigDispatchTypes =
  | SmsConfigLoading
  | SmsConfigError
  | SmsConfigSuccess
  | AddSmsConfigLoading
  | AddSmsConfigError
  | AddSmsConfigSuccess
  | UpdateSmsConfigLoading
  | UpdateSmsConfigError
  | UpdateSmsConfigSuccess;
