//function to format date and time

const getFullClassName = (class_: string | null, section_: string | null) => {
  if (!class_ && !section_) {
    return "Unknown";
  } else if (class_ && section_) {
    return class_ + " (" + section_ + ")";
  } else if (class_ && !section_) {
    return class_;
  } else {
    return "Unknown";
  }
};
export default getFullClassName;
