import React from "react";
import { FinanceSidebar, FeeTypeReportList } from "../../components";
import FeeLedgerList from "../../components/Finance/FeeLedger/FeeLedgerList";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const FeeTypeReport = () => {
  const nav = <FinanceSidebar fee_type_report />;
  return (
    <SubModuleLayout FeesMenuActive sideNav={nav}>
      <FeeTypeReportList />
    </SubModuleLayout>
  );
};

export default FeeTypeReport;
