import React, { useState } from "react";
import { useSidebar } from "../../Reusable";
import { useSidebarStyles } from "../../Styles/SidebarStyles";
import { MenuBookOutlined } from "@material-ui/icons";
import SidebarTitle from "../../Reusable/Titles/SidebarTitle";

interface propsI {
  createExam?: boolean | undefined;
  examSchedules?: boolean | undefined;
  subjectClass?: boolean | undefined;
  optionalSubjects?: boolean | undefined;
  assignOptSubjects?: boolean | undefined;
}

const ExaminationSidebar = (props: propsI) => {
  const classes = useSidebarStyles();
  const [createExam] = useState(props.createExam);
  const [examSchedules] = useState(props.examSchedules);
  const [subjectClass] = useState(props.subjectClass);
  const [optionalSubjects] = useState(props.optionalSubjects);
  const [assignOptSubjects] = useState(props.assignOptSubjects);

  const dataSet = [
    { text: "Create Exam", url: "examination", activeStatus: createExam },
    { text: "Subject Class", url: "subject-class", activeStatus: subjectClass },
    {
      text: "Exam Schedules",
      url: "exam-schedule",
      activeStatus: examSchedules,
    },
    {
      text: "Optional Subjects",
      url: "opt-subjects",
      activeStatus: optionalSubjects,
    },
    {
      text: "Assign Optional Subjects",
      url: "assign-opt-subjects",
      activeStatus: assignOptSubjects,
    },
  ];
  const { CustomSidebar } = useSidebar(dataSet, "FINANCE");
  return (
    <div className={classes.Sidebar}>
      <div className={classes.sidebarContent}>
        <SidebarTitle icon={<MenuBookOutlined />}>Examination</SidebarTitle>
        <CustomSidebar />
      </div>
    </div>
  );
};

export default ExaminationSidebar;
