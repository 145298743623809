import React, { useState } from "react";
import {
  ReportSideBar,
  GradesheetForm,
  GradesheetTable,
} from "../../components";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

interface SearchTitleI {
  class: string;
  section: string;
}

const GradesheetPage = () => {
  const [searchData, setSearchData] = useState<SearchTitleI | null>(null);
  const nav = <ReportSideBar gradesheet />;

  const searchGradesheet = (data: SearchTitleI) => {
    setSearchData(data);
  };

  return (
    <SubModuleLayout reportMenuActive sideNav={nav}>
      <GradesheetForm onSearch={searchGradesheet} />
      <GradesheetTable searchTitle={searchData} />
    </SubModuleLayout>
  );
};

export default GradesheetPage;
