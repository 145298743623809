import NepaliDate from "nepali-date-converter";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ExamScheduleTable } from "../..";
import { GradeTypeI } from "../../../actions/Academics/Grade/GradeActionTypes";
import { ExamTypeI } from "../../../actions/Examination/Exam/ExamActionTypes";
import { RootStore } from "../../../store";
import BackDropLoader from "../../Reusable/BackDropLoader";
import ExamGroupScheduleModal from "./ExamGroupScheduleModal";
import SearchExamSchedule from "./SearchExamSchedule";

export const returnNepaliDateString = (nepDate: string) => {
  const indices = nepDate.split("-");
  const year = parseInt(indices[0]);
  const month = parseInt(indices[1]) - 1;
  const day = parseInt(indices[2]);

  const date1 = new NepaliDate(year, month, day);
  return date1.format("ddd, DD MMMM YYYY");
};

export const timeConvertor = (timeStr: string) => {
  const mod = timeStr.slice(0, 5);
  let time: any = mod
    .toString()
    .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [mod];

  if (time.length > 1) {
    time = time.slice(1);
    time[5] = +time[0] < 12 ? " AM" : " PM";
    time[0] = +time[0] % 12 || 12;
  }
  return time.join("");
};

const ExamSchedule = () => {
  const [action, setAction] = useState<string>("update");
  const [examGrade, setExamGrade] = useState<{
    examId: ExamTypeI | null;
    gradeId: GradeTypeI | null;
  }>({ examId: null, gradeId: null });

  const { loading } = useSelector(
    (state: RootStore) => state.examSubjectSchedule
  );

  return (
    <>
      <SearchExamSchedule
        actionType={(data) => setAction(data)}
        examParams={(data) => setExamGrade({ ...data })}
      />
      {action === "update" ? (
        <ExamGroupScheduleModal {...examGrade} />
      ) : (
        <ExamScheduleTable {...examGrade} />
      )}
      <BackDropLoader open={loading} />
    </>
  );
};

export default ExamSchedule;
