import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_TEACHER_PROFILE_LOADING,
  GET_TEACHER_PROFILE_ERROR,
  GET_TEACHER_PROFILE_SUCCESS,
  UPDATE_TEACHER_PROFILE_LOADING,
  UPDATE_TEACHER_PROFILE_ERROR,
  UPDATE_TEACHER_PROFILE_SUCCESS,
  GET_DEPARTMENT_LOADING,
  GET_DEPARTMENT_ERROR,
  GET_DEPARTMENT_SUCCESS,
  GET_DESIGNATION_LOADING,
  GET_DESIGNATION_ERROR,
  GET_DESIGNATION_SUCCESS,
  TeacherProfileDispatchTypes,
} from "./TeacherProfileActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { TEACHER_API_URL } from "../../../config/SERVER_API_URLS";

export const GetTeacherProfile =
  (id: number) =>
    async (dispatch: Dispatch<TeacherProfileDispatchTypes | SetSnackBarI>) => {
      try {
        dispatch({
          type: GET_TEACHER_PROFILE_LOADING,
        });

        const res = await axios.get(
          `${TEACHER_API_URL}/profile/${id}/`,
          HeaderConfig()
        );

        dispatch({
          type: GET_TEACHER_PROFILE_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: GET_TEACHER_PROFILE_ERROR,
        });

        // dispatch({
        //   type: SET_SNACKBAR,
        //   payload: {
        //     snackbarOpen: true,
        //     snackbarType: "error",
        //     snackbarMessage: error?.response.data.error.detail,
        //   },
        // });
      }
    };

export const UpdateTeacherProfile = (id: number, data: any) =>
  async (dispatch: Dispatch<TeacherProfileDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_TEACHER_PROFILE_LOADING,
      });

      const res = await axios.patch(
        `${TEACHER_API_URL}/profile/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_TEACHER_PROFILE_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Profile Updated Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: UPDATE_TEACHER_PROFILE_ERROR,
      });
    }
  }

export const GetDepartmentStaff = () =>
  async (dispatch: Dispatch<TeacherProfileDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_DEPARTMENT_LOADING,
      });

      const res = await axios.get(
        `${TEACHER_API_URL}/department/`,
        HeaderConfig()
      );

      dispatch({
        type: GET_DEPARTMENT_SUCCESS,
        payload: res.data,
      });

    } catch (error) {
      dispatch({
        type: GET_DEPARTMENT_ERROR,
      });
    }
  }

export const GetDesignationStaff = () =>
  async (dispatch: Dispatch<TeacherProfileDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_DESIGNATION_LOADING,
      });

      const res = await axios.get(
        `${TEACHER_API_URL}/designation/`,
        HeaderConfig()
      );

      dispatch({
        type: GET_DESIGNATION_SUCCESS,
        payload: res.data,
      });

    } catch (error) {
      dispatch({
        type: GET_DESIGNATION_ERROR,
      });
    }
  }