export const GET_SECTION_LOADING = "GET_SECTION_LOADING";
export const GET_SECTION_ERROR = "GET_SECTION_ERROR";
export const GET_SECTION_SUCCESS = "GET_SECTION_SUCCESS";

export const ADD_SECTION_LOADING = "ADD_SECTION_LOADING";
export const ADD_SECTION_ERROR = "ADD_SECTION_ERROR";
export const ADD_SECTION_SUCCESS = "ADD_SECTION_SUCCESS";

export const UPDATE_SECTION_LOADING = "UPDATE_SECTION_LOADING";
export const UPDATE_SECTION_ERROR = "UPDATE_SECTION_ERROR";
export const UPDATE_SECTION_SUCCESS = "UPDATE_SECTION_SUCCESS";

export const DELETE_SECTION_LOADING = "DELETE_SECTION_LOADING";
export const DELETE_SECTION_ERROR = "DELETE_SECTION_ERROR";
export const DELETE_SECTION_SUCCESS = "DELETE_SECTION_SUCCESS";

export type SectionTypeI = {
  id: string;
  name: string;
  created_by?: string;
  general_info_name?: string;
  count?: number;
};

//===============================<START>GET SECTION<START>======================//

export interface GetSectionLoading {
  type: typeof GET_SECTION_LOADING;
}

export interface GetSectionError {
  type: typeof GET_SECTION_ERROR;
}

export interface GetSectionSuccess {
  type: typeof GET_SECTION_SUCCESS;
  payload: SectionTypeI[];
}

//===============================<END>GET SECTION<END>======================//

//===============================<START>ADD SECTION<START>======================//

export interface AddSectionLoading {
  type: typeof ADD_SECTION_LOADING;
}

export interface AddSectionError {
  type: typeof ADD_SECTION_ERROR;
  payload: any;
}

export interface AddSectionSuccess {
  type: typeof ADD_SECTION_SUCCESS;
  payload: SectionTypeI;
}

//===============================<END>ADD SECTION<END>======================//

//===============================<START>UPDATE SECTION<START>======================//

export interface UpdateSectionLoading {
  type: typeof UPDATE_SECTION_LOADING;
}

export interface UpdateSectionError {
  type: typeof UPDATE_SECTION_ERROR;
  payload: any;
}

export interface UpdateSectionSuccess {
  type: typeof UPDATE_SECTION_SUCCESS;
  payload: SectionTypeI;
}

//===============================<END>UPDATE SECTION<END>======================//

//===============================<START>DELETE SECTION<START>======================//

export interface DeleteSectionLoading {
  type: typeof DELETE_SECTION_LOADING;
}

export interface DeleteSectionError {
  type: typeof DELETE_SECTION_ERROR;
}

export interface DeleteSectionSuccess {
  type: typeof DELETE_SECTION_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE SECTION<END>======================//

export type SectionDispatchTypes =
  | GetSectionLoading
  | GetSectionError
  | GetSectionSuccess
  | AddSectionLoading
  | AddSectionError
  | AddSectionSuccess
  | UpdateSectionLoading
  | UpdateSectionError
  | UpdateSectionSuccess
  | DeleteSectionLoading
  | DeleteSectionError
  | DeleteSectionSuccess;
