/* eslint-disable no-nested-ternary */
import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Link, { LinkProps } from "@material-ui/core/Link";
import ListItem from "@material-ui/core/ListItem";
import Collapse from "@material-ui/core/Collapse";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Route, MemoryRouter, useLocation } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { Omit } from "@material-ui/types";
import { NavigateNext } from "@material-ui/icons";

interface ListItemLinkProps extends LinkProps {
  to: string;
  open?: boolean;
}

const breadcrumbNameMap: { [key: string]: string } = {
  "/inbox": "Inbox",
  "/inbox/important": "Important",
  "/trash": "Trash",
  "/spam": "Spam",
  "/drafts": "Drafts",
};

function ListItemLink(props: Omit<ListItemLinkProps, "ref">) {
  const { to, open, ...other } = props;
  const primary = breadcrumbNameMap[to];

  return (
    <li>
      <ListItem button component={RouterLink} to={to} {...other}>
        <ListItemText primary={primary} />
        {open != null ? open ? <ExpandLess /> : <ExpandMore /> : null}
      </ListItem>
    </li>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      "& nav > .MuiBreadcrumbs-ol": {
        justifyContent: "flex-start",
        "& > li > a": {
          fontWeight: 500,
          fontSize: "16px",
        },
      },
    },
    lists: {
      backgroundColor: theme.palette.background.paper,
      marginTop: theme.spacing(1),
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  })
);

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => (
  <Link {...props} component={RouterLink as any} />
);

export default function BreadCrumbs(props: any) {
  const classes = useStyles();
  const location = useLocation();
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  return (
    <div className={classes.root}>
      <Route>
        {() => {
          const pathnames = location.pathname
            .split("/")
            .filter((x) => x)
            .slice(0, 2);

          return (
            <Breadcrumbs
              separator={<NavigateNext fontSize="small" />}
              aria-label="breadcrumb"
            >
              <LinkRouter
                color="textSecondary"
                style={{ fontSize: "12px" }}
                to="/"
              >
                Dashboard
              </LinkRouter>
              {props.module && (
                <LinkRouter
                  color="textSecondary"
                  style={{ fontSize: "12px", textTransform: "capitalize" }}
                  to={props.module.to}
                >
                  {props.module.name}
                </LinkRouter>
              )}
              {pathnames.map((value, index) => {
                const last = index === pathnames.length - 1;
                const to = `/${pathnames.slice(0, index + 1).join("/")}`;

                return last ? (
                  <Typography
                    color="textSecondary"
                    variant="caption"
                    key={to}
                    style={{ fontSize: "12px", textTransform: "capitalize" }}
                  >
                    {pathnames[index]}
                  </Typography>
                ) : (
                  <LinkRouter
                    style={{ fontSize: "12px", textTransform: "capitalize" }}
                    color="textSecondary"
                    to={to}
                    key={to}
                  >
                    {pathnames[index]}
                  </LinkRouter>
                );
                // return (
                //   <LinkRouter color="inherit" to={to} key={index}>
                //     <span style={{ textTransform: "capitalize" }}>
                //       {pathnames[index]}
                //     </span>
                //   </LinkRouter>
                // );
              })}
            </Breadcrumbs>
          );
        }}
      </Route>
    </div>
  );
}
