// ---------------------------- <START> module imports start ----------------------------------//
import React, { useEffect, useState } from 'react';
import {
  TableBody,
  TextField,
  Box,
  LinearProgress,
  Grid,
  Divider,
  TableRow,
  TableCell,
  Typography,
} from '@material-ui/core';
import { useTableStyles } from '../../../Styles/TableStyles';
import { useTable } from '../../../Reusable';
import TableActionButtons from '../../../Reusable/Buttons/TableActionButtons';
import {
  FeeLedgerByDateI,
  FeeLedgerTypeI,
} from '../../../../actions/Fees/FeeLedger/FeeLedgerActionTypes';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../../store';
import { dateConverterAdToBs } from '../../../utils/dateConverter';
import {
  getDiscountAmount,
  getFineAmount,
  getScholarshipAmount,
} from '../../CollectFees/modal/StatementDetails';
// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//
interface PropsI {
  studentInfo: { name: string; class: string };
}

interface HeadCellsI {
  id: string;
  label: string;
}
// ---------------------------- <END> interface ends ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: 'date', label: 'Date' },
  { id: 'fee_type', label: 'Fee Type' },
  { id: 'fee_amount', label: 'Fee Amount' },
  { id: 'scholarship', label: 'Scholarship Amount' },
  { id: 'fine', label: 'Fine Amount' },
  { id: 'paying_amount', label: 'Paying Amount' },
  { id: 'paid', label: 'Paid' },
  { id: 'balance', label: 'Balance/Due' },
  { id: 'advance', label: 'Advance Amount' },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Invoice Table Table Component Starts ---------------------------//
const InvoiceTable = (props: PropsI) => {
  const { studentInfo } = props;
  const classes = useTableStyles();

  // States declaration
  const [tableData, setTableData] = useState<FeeLedgerByDateI[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [state, setState] = useState({
    grandTotal: 0,
    discount: 0,
    fine: 0,
    totalPaying: 0,
    dues: 0,
    advance: 0,
  });

  const ledgerSelector = useSelector((state: RootStore) => state.fee_ledger);

  useEffect(() => {
    setLoading(ledgerSelector.loading);
    const data = ledgerSelector.feeLedgerByDate;

    if (data) {
      setTableData(data.invoice_detail);

      let fine = 0;
      let discount = 0;

      data.invoice_detail.map((item) => {
        fine =
          fine + getFineAmount(item.invoice_fine_applied, item.amount_to_pay);
        discount =
          discount +
          getScholarshipAmount(
            item.invoice_scholarship_applied,
            item.amount_to_pay
          );
      });

      setState({
        ...state,
        grandTotal:
          data.invoice_detail.reduce((a, b) => a + b.amount_to_pay, 0) +
          fine -
          discount,
        totalPaying: data.invoice_detail.reduce((a, b) => a + b.paid_amount, 0),
        fine: fine,
        discount: discount,
        dues: data.invoice_detail.reduce((a, b) => a + b.due_amount, 0),
        advance: data.invoice_detail.reduce((a, b) => a + b.advance_amount, 0),
      });
    }
  }, [ledgerSelector]);

  // Retrieving re-usable components from useTable
  const {
    TblContainer,
    TblHead,
    StyledTableCell,
    StyledTableRow,
    exportToExcel,
    tablePrint,
  } = useTable(headCells);

  // Event function for table searching by name
  // const handleTableSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const search_data = tab.filter((data) =>
  //     data.fee_type.toUpperCase().includes(e.target.value.toUpperCase())
  //   );
  //   setTableData(search_data);

  //   setTableData(search_data);
  // };

  return (
    <>
      <Box
        className={classes.rootTableBox}
        style={{ margin: 0, padding: '0px 0px 16px 0' }}
      >
        <div className={classes.tableWrapper}>
          <div className={classes.tableTop}>
            {/* <TextField
              variant="outlined"
              name="search"
              placeholder="Search..."
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleTableSearch(e)
              }
            /> */}

            {/* <TableActionButtons
              exportToExcel={exportToExcel}
              tablePrint={tablePrint}
            /> */}
            <Grid container>
              <Grid item xs={5}>
                Student: {studentInfo.name}
              </Grid>
              <Grid item xs={7}>
                Class: {studentInfo.class}
              </Grid>
            </Grid>
          </div>
          <Divider style={{ margin: '10px 0px' }} />
          {loading && <LinearProgress style={{ marginBottom: '-15px' }} />}
          <TblContainer>
            <TblHead />
            <TableBody>
              {tableData.map((item: FeeLedgerByDateI) => (
                <StyledTableRow key={item.id}>
                  <StyledTableCell align="center" className={classes.cell}>
                    {dateConverterAdToBs(item.created_on)}
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.cell}>
                    {item.fee_type} {item.month}
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.cell}>
                    Rs. {item.amount_to_pay}
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.cell}>
                    Rs.{' '}
                    {getScholarshipAmount(
                      item.invoice_scholarship_applied,
                      item.amount_to_pay
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.cell}>
                    Rs.{' '}
                    {getFineAmount(
                      item.invoice_fine_applied,
                      item.amount_to_pay
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.cell}>
                    Rs.{' '}
                    {item.amount_to_pay +
                      getFineAmount(
                        item.invoice_fine_applied,
                        item.amount_to_pay
                      ) -
                      getScholarshipAmount(
                        item.invoice_scholarship_applied,
                        item.amount_to_pay
                      ) -
                      getDiscountAmount(
                        item.invoice_discount_applied,
                        item.amount_to_pay
                      )}
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.cell}>
                    Rs. {item.paid_amount}
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.cell}>
                    Rs. {item.due_amount}
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.cell}>
                    Rs. {item.advance_amount}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </TblContainer>
          <Grid
            container
            justifyContent="flex-end"
            style={{ margin: '30px 0' }}
          >
            <TableRow>
              <TableCell rowSpan={1} />
              <TableCell colSpan={1}>
                <div className={classes.title}>
                  <Typography style={{ color: '#6C6D6E' }}>
                    Grand Total
                  </Typography>
                </div>
                <div>
                  <Typography style={{ fontWeight: 'bold' }}>
                    Rs. {state.grandTotal}
                  </Typography>
                </div>
              </TableCell>
              <TableCell rowSpan={1} />
              <TableCell colSpan={1}>
                <div className={classes.title}>
                  <Typography style={{ color: '#6C6D6E' }}>
                    Total Paid Amount
                  </Typography>
                </div>
                <div>
                  <Typography style={{ fontWeight: 'bold' }}>
                    Rs. {state.totalPaying}
                  </Typography>
                </div>
              </TableCell>
              <TableCell rowSpan={1} />
              <TableCell colSpan={1}>
                <div className={classes.title}>
                  <Typography style={{ color: '#6C6D6E' }}>Balance</Typography>
                </div>
                <div>
                  <Typography style={{ fontWeight: 'bold' }}>
                    Rs.{' '}
                    {state.dues - state.advance > 0
                      ? `${state.dues - state.advance} (Due)`
                      : state.dues - state.advance < 0
                      ? `${Math.abs(state.dues - state.advance)} (Adv.)`
                      : 0}
                  </Typography>
                </div>
              </TableCell>
            </TableRow>
          </Grid>
        </div>
      </Box>
    </>
  );
};
// ---------------------------- <END> Invoice Table Component Ends -------------------------------//

export default InvoiceTable;
