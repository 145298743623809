import React, { useState, useEffect } from "react";
import { Controller, useForm, SubmitHandler } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import {
  AddLeaveType,
  UpdateLeaveType,
} from "../../../actions/HumanResource/LeaveType/LeaveTypeAction";
import FormLayout from "../../Reusable/Layouts/Form.Layout";
import CustomizedTextField from "../../Reusable/Inputs/TextField";
import { LeaveTypeTypeI } from "../../../actions/HumanResource/LeaveType/LeaveTypeActionTypes";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import CustomizedSelect from "../../Reusable/Inputs/Select";
import { useStyles } from "../../Styles/FormStyles";
import { Autocomplete } from "@material-ui/lab";
import styled from "styled-components";

const ErrorMessage = styled.span`
  color: red;
  font-size: 11px;
  margin-left: 10px;
`;
interface LeaveTypeFormProps {
  editData: LeaveTypeTypeI | null;
}

interface FormFields {
  name: string;
  max_leave: number;
  min_exp_days: number;
}

const LeaveTypeForm = (props: LeaveTypeFormProps) => {
  const { editData } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const { register, handleSubmit, setValue, reset, errors, control } =
    useForm<FormFields>({
      mode: "onChange",
    });

  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [editID, setEditID] = useState<string | null>(null);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);

  const [leaveCountedIn, setLeaveCountedIn] = useState<string>("Month");
  const [leaveAccumulated, toggleLeaveAccumulated] = useState<boolean>(false);
  const [leavePaid, toggleLeavePaid] = useState<boolean>(false);

  const leaveTypeLoading: boolean = useSelector(
    (state: RootStore) => state.leave_type.loading
  );

  const add_or_update = useSelector(
    (state: RootStore) => state.leave_type.add_or_update
  );

  useEffect(() => {
    if (add_or_update) {
      setIsBtnLoading(false);
      handleReset();
    }
  }, [add_or_update]);

  useEffect(() => {
    setLoading(leaveTypeLoading);
  }, [leaveTypeLoading]);

  useEffect(() => {
    editData != null && handleEditTableData(editData);
  }, [editData]);

  const handleEditTableData = (data: LeaveTypeTypeI) => {
    setEditID(data.id);
    setEditMode(true);
    setValue("name", data.name);
    setValue("max_leave", data.max_leave);
    setValue("min_exp_days", data.min_exp);
    toggleLeavePaid(data.is_paid);
    toggleLeaveAccumulated(data.is_accumulated);
    setLeaveCountedIn(data.max_leave_per);
  };

  const onSubmit: SubmitHandler<FormFields> = (data) => {
    const postData = {
      name: data.name,
      max_leave: Number(data.max_leave),
      max_leave_per: leaveCountedIn,
      min_exp: Number(data.min_exp_days || 0),
      is_paid: leavePaid,
      is_accumulated: leaveAccumulated,
    };

    if (editMode) {
      editID != null && dispatch(UpdateLeaveType(editID, postData));
    } else {
      dispatch(AddLeaveType(postData));
    }
    // setIsBtnLoading(true);
    // if (editMode) {
    //   editID != null && dispatch(UpdateLeaveType(editID, data));
    // } else {
    //   dispatch(AddLeaveType(data));
    // }
  };

  const handleReset = () => {
    reset();
    setEditID("");
    setEditMode(false);
    setLeaveCountedIn("Month");
    toggleLeavePaid(false);
    toggleLeaveAccumulated(false);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLeaveCountedIn(event.target.value);
  };

  return (
    <FormLayout
      title="Add Leave Type"
      onSubmit={handleSubmit(onSubmit)}
      editMode={editMode}
      loading={loading}
      onClick={!isBtnLoading && handleReset}
      add_or_update={isBtnLoading}
    >
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          className={classes.formWrapper}
          style={{ marginBottom: "0.5rem" }}
        >
          <InputLabel>
            Leave Name <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <TextField
            variant="outlined"
            name="name"
            placeholder="Leave Name"
            inputRef={register({
              required: { value: true, message: "Staff name is required." },
            })}
          />
          <ErrorMessage>{errors.name?.message}</ErrorMessage>
        </Grid>
        <Grid item xs={12} className={classes.formWrapper}>
          <InputLabel>
            Leave Counted In <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="leave_counted_in"
              name="leave_counted_in"
              value={leaveCountedIn}
              onChange={handleRadioChange}
              row
            >
              <FormControlLabel
                value="Month"
                control={<Radio />}
                label="Month"
              />
              <FormControlLabel value="Year" control={<Radio />} label="Year" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.formWrapper}
          style={{ marginBottom: "0.5rem" }}
        >
          <InputLabel>
            Max Leave Days <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <TextField
            variant="outlined"
            name="max_leave"
            type="number"
            placeholder="Max Leave Days"
            inputRef={register({
              required: { value: true, message: "Max leave days is required." },
            })}
          />
          <ErrorMessage>{errors.max_leave?.message}</ErrorMessage>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.formWrapper}
          style={{ marginBottom: "0.5rem" }}
        >
          <InputLabel>Min Exp Days</InputLabel>
          <TextField
            variant="outlined"
            name="min_exp_days"
            type="number"
            placeholder="Min Experience days"
            inputRef={register}
          />
          <ErrorMessage>{errors.min_exp_days?.message}</ErrorMessage>
        </Grid>
        <Grid item xs={12} className={classes.formWrapper}>
          <FormControl
            style={{ maxHeight: 200, overflowY: "auto" }}
            component="fieldset"
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    onChange={() => toggleLeavePaid((prev) => !prev)}
                    name="is_accumulated"
                    checked={leavePaid}
                  />
                }
                label="Is Paid Leave"
              />
            </FormGroup>
          </FormControl>
          <span className={classes.validationErrorInfo}></span>
        </Grid>
        <Grid item xs={12} className={classes.formWrapper}>
          <FormControl
            style={{ maxHeight: 200, overflowY: "auto" }}
            component="fieldset"
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    onChange={() => toggleLeaveAccumulated((prev) => !prev)}
                    name="is_accumulated"
                    checked={leaveAccumulated}
                  />
                }
                label="Is Accumulated"
              />
            </FormGroup>
          </FormControl>
          <span className={classes.validationErrorInfo}></span>
        </Grid>
      </Grid>
    </FormLayout>
  );
};

export default LeaveTypeForm;
