import React from "react";
import { SettingSidebar, SMSTemplate } from "../../components";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const SSMSTemplatePage = () => {
  const nav = <SettingSidebar SMSTemplate />;
  return (
    <SubModuleLayout sideNav={nav}>
      <SMSTemplate />
    </SubModuleLayout>
  );
};
export default SSMSTemplatePage;
