import { Paper, Typography } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { HeadCellsI } from "../../../actions";
import { SystemRolesTypeI } from "../../../actions/HumanResource/StaffRole/StaffRoleActionTypes";
import {
  DeleteSystemRoles,
  GetSystemRoles,
} from "../../../actions/HumanResource/StaffRole/StaffRolesAction";
import { RootStore } from "../../../store";
import { useTable } from "../../Reusable";
import { ItemDeleteButton } from "../../Reusable/Buttons/TableButton";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import { useTableStyles } from "../../Styles/TableStyles";

const headCells: HeadCellsI[] = [
  { id: "name", label: "Roles" },
  // { id: "action", label: "Action" },
];

const RolesTable = () => {
  const classes = useTableStyles();
  const dispatch = useDispatch();

  // Retrieving re-usable components from useTable
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [tableData, setTableData] = React.useState<any>([]);

  const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false);
  const [itemId, setItemId] = React.useState<string | null>(null);

  const rolesSelector = useSelector((state: RootStore) => state.staff_roles);

  React.useEffect(() => {
    dispatch(GetSystemRoles());
  }, []);

  React.useEffect(() => {
    const items = rolesSelector.system_roles;
    setTableData(items);
  }, [rolesSelector]);

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const handleDeleteModal = (value: boolean, id: string) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteSystemRoles(itemId));
    setDeleteModalOpen(false);
  };

  const getCustomTableRow = (item: SystemRolesTypeI) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {item.name}
        </StyledTableCell>
        {/* <StyledTableCell align="center" className={classes.twoCell}>
          <ItemDeleteButton
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleDeleteModal(true, item.id)
            }
          />
        </StyledTableCell> */}
      </StyledTableRow>
    );
  };

  return (
    <>
      <Paper className={classes.root}>
        <Typography variant="h6">Roles List</Typography>
        <TableContainer
          loading={loading}
          items={tableData}
          headCells={headCells}
          getCustomTableRow={getCustomTableRow}
          search_name="name"
          rowsPerPage={7}
        />
      </Paper>
      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
        dialogTitle="Are you sure to delete this record?"
        okText="Delete"
      />
    </>
  );
};

export default RolesTable;
