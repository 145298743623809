import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Typography, Paper } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTableStyles } from '../../Styles/TableStyles';
import { useTableWithPagination } from '../../Reusable';
import CustomizedDialogs from '../../Reusable/Dialogs/DeleteDialog';
import { RootStore } from '../../../store';
import {
  ItemDeleteButton,
  ItemEditButton,
} from '../../Reusable/Buttons/TableButton';
import { LocationTypeI } from '../../../actions/Transport/Location/LocationActionTypes';
import {
  DeleteLocation,
  GetLocation,
} from '../../../actions/Transport/Location/LocationAction';
import { HeadCellsI } from '../../../actions';

interface PropsI {
  onEditData: (value: LocationTypeI) => void;
  edit: boolean;
}

const headCells: HeadCellsI[] = [
  { id: 'location', label: 'Location', align: 'left' },
  { id: 'oneWay', label: 'One Way (Rs.)' },
  { id: 'twoWay', label: 'Two Way (Rs.)' },
  { id: 'pickup', label: 'Pickup' },
  { id: 'drop', label: 'Drop' },
  { id: 'action', label: 'Action' },
];

const LocationList = (props: PropsI) => {
  const { onEditData, edit } = props;

  const dispatch = useDispatch();
  const classes = useTableStyles();

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTableWithPagination(headCells);

  const [tableData, setTableData] = useState<LocationTypeI[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string>('');

  useEffect(() => {
    dispatch(GetLocation());
  }, []);

  useEffect(() => {
    setEditMode(edit);
  }, [edit]);

  const locationsState = useSelector((state: RootStore) => state.location);

  useEffect(() => {
    const data = locationsState.locations?.results;

    setLoading(locationsState.loading);
    setTableData(data);
  }, [locationsState]);

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const handleDeleteModal = (value: boolean, id: string) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteLocation(itemId));
    setDeleteModalOpen(false);
  };

  const handleEditClicked = (data: LocationTypeI) => {
    setEditMode(true);
    onEditData(data);
  };

  const getCustomTableRow = (item: LocationTypeI) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="left" className={classes.cell}>
          {item.name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.one_way_cost}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.two_way_cost}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {moment(item.pick_up_time, 'hh:mm:ss').format('hh:mm A')}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {moment(item.drop_time, 'hh:mm:ss').format('hh:mm A')}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ItemEditButton onClick={() => handleEditClicked(item)} />
          <ItemDeleteButton
            disabled={editMode}
            onClick={() => handleDeleteModal(true, item.id)}
          />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  const handlePaginationChange = (page: number) => {
    const params = { page, perPage: 5 };
    dispatch(GetLocation());
  };

  if (locationsState.locations === null) {
    return <></>;
  }

  return (
    <Paper className={classes.root}>
      <Typography variant="h6">Location List</Typography>
      <TableContainer
        label="Location List"
        loading={loading}
        items={tableData}
        headCells={headCells}
        forPagination={locationsState.locations}
        handlePaginationChange={handlePaginationChange}
        getCustomTableRow={getCustomTableRow}
        search_name="name"
      />

      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
        dialogTitle="Are you sure to delete this record?"
        okText="Delete"
      />
    </Paper>
  );
};

export default LocationList;
