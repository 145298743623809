import {
  GET_DESIGNATION_LOADING,
  GET_DESIGNATION_ERROR,
  GET_DESIGNATION_SUCCESS,
  ADD_DESIGNATION_LOADING,
  ADD_DESIGNATION_ERROR,
  ADD_DESIGNATION_SUCCESS,
  DELETE_DESIGNATION_LOADING,
  DELETE_DESIGNATION_ERROR,
  DELETE_DESIGNATION_SUCCESS,
  UPDATE_DESIGNATION_LOADING,
  UPDATE_DESIGNATION_ERROR,
  UPDATE_DESIGNATION_SUCCESS,
  DesignationTypeI,
  DesignationDispatchTypes,
} from "../../actions/HumanResource/Designation/DesignationActionTypes";

interface InitialStateI {
  loading: boolean;
  designations: DesignationTypeI[];
  add_or_update: boolean;
}

const initialState: InitialStateI = {
  loading: false,
  designations: [],
  add_or_update: false,
};

const DesignationReducer = (
  state: InitialStateI = initialState,
  action: DesignationDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_DESIGNATION_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_DESIGNATION_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_DESIGNATION_SUCCESS:
      return {
        ...state,
        loading: false,
        designations: action.payload,
      };

    case ADD_DESIGNATION_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case ADD_DESIGNATION_ERROR:
      return {
        ...state,
        loading: false,
        add_or_update: true,
      };

    case ADD_DESIGNATION_SUCCESS:
      return {
        loading: false,
        designations: [...state.designations, action.payload],
        add_or_update: true,
      };

    case UPDATE_DESIGNATION_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case UPDATE_DESIGNATION_ERROR:
      return {
        ...state,
        loading: false,
        add_or_update: true,
      };

    case UPDATE_DESIGNATION_SUCCESS:
      const current_data: DesignationTypeI[] = state.designations;
      const index: number = current_data.findIndex(
        (element) => element.id === action.payload.id
      );
      current_data[index] = action.payload;

      return {
        loading: false,
        designations: current_data,
        add_or_update: true,
      };

    case DELETE_DESIGNATION_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_DESIGNATION_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_DESIGNATION_SUCCESS:
      const new_data: DesignationTypeI[] = state.designations;
      const item_index = new_data.findIndex(
        (element) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return {
        ...state,
        loading: false,
        designations: new_data,
      };

    default:
      return state;
  }
};

export default DesignationReducer;
