import axios from 'axios';
import { Dispatch } from 'redux';

import {
  GET_FEE_LEDGER_REPORT_LOADING,
  GET_FEE_LEDGER_REPORT_SUCCESS,
  GET_FEE_LEDGER_REPORT_ERROR,
  GET_DUE_FEE_REPORT_LOADING,
  GET_DUE_FEE_REPORT_SUCCESS,
  GET_DUE_FEE_REPORT_ERROR,
  GET_FEE_TYPE_REPORT_LOADING,
  GET_FEE_TYPE_REPORT_SUCCESS,
  GET_FEE_TYPE_REPORT_ERROR,
  GET_MONTHLY_FEE_REPORT_LOADING,
  GET_MONTHLY_FEE_REPORT_SUCCESS,
  GET_MONTHLY_FEE_REPORT_ERROR,
  GET_FEE_LEDGER_BY_DATE_LOADING,
  GET_FEE_LEDGER_BY_DATE_SUCCESS,
  GET_FEE_LEDGER_BY_DATE_ERROR,
  FeeLedgerDispatchTypes,
} from './FeeLedgerActionTypes';

import { SET_SNACKBAR, SetSnackBarI } from '../../SnackbarActionTypes';
import { HeaderConfig } from '../../Auth/Login/LoginAction';
import { ADMIN_API_URL } from '../../../config/SERVER_API_URLS';
import { setSnackbar } from '../../SnackbarAction';

export const GetFeeLedgerReport =
  (grade?: string, section?: string, from_date?: string, to_date?: string) =>
  async (dispatch: Dispatch<FeeLedgerDispatchTypes | SetSnackBarI>) => {
    let url = `${ADMIN_API_URL}/fee-ledger/`;
    if (grade && from_date && to_date) {
      url = `${ADMIN_API_URL}/fee-ledger/?grade=${grade}&section=${section}&from_date=${from_date}&to_date=${to_date}`;
    }

    try {
      dispatch({
        type: GET_FEE_LEDGER_REPORT_LOADING,
      });

      const res = await axios.get(url, HeaderConfig());

      dispatch({
        type: GET_FEE_LEDGER_REPORT_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_FEE_LEDGER_REPORT_ERROR,
      });
    }
  };

export const GetDueFeeReport =
  (id: number, grade?: string, section?: string) =>
  async (dispatch: Dispatch<FeeLedgerDispatchTypes | SetSnackBarI>) => {
    const url = `${ADMIN_API_URL}/fee-ledger/?page=${id}&grade=${grade}&section=${section}&due=true`;
    try {
      dispatch({
        type: GET_DUE_FEE_REPORT_LOADING,
      });

      const res = await axios.get(url, HeaderConfig());

      dispatch({
        type: GET_DUE_FEE_REPORT_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_DUE_FEE_REPORT_ERROR,
      });
    }
  };

export const GetFeeTypeReport =
  (fee_type?: string) =>
  async (dispatch: Dispatch<FeeLedgerDispatchTypes | SetSnackBarI>) => {
    let url = `${ADMIN_API_URL}/report/fee-type`;
    if (fee_type) {
      url = `${ADMIN_API_URL}/report/fee-type/?fee_type=${fee_type}`;
    }
    try {
      dispatch({
        type: GET_FEE_TYPE_REPORT_LOADING,
      });

      const res = await axios.get(url, HeaderConfig());

      dispatch({
        type: GET_FEE_TYPE_REPORT_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_FEE_TYPE_REPORT_ERROR,
      });
    }
  };

export const GetMonthlyFeeReport =
  () => async (dispatch: Dispatch<FeeLedgerDispatchTypes | SetSnackBarI>) => {
    const url = `${ADMIN_API_URL}/report/fee-monthly`;
    try {
      dispatch({
        type: GET_MONTHLY_FEE_REPORT_LOADING,
      });

      const res = await axios.get(url, HeaderConfig());

      dispatch({
        type: GET_MONTHLY_FEE_REPORT_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_MONTHLY_FEE_REPORT_ERROR,
      });
    }
  };

export const SendDueSms =
  (student_id: string) => async (dispatch: Dispatch<SetSnackBarI>) => {
    try {
      await axios.post(
        `${ADMIN_API_URL}/alert/due/fee/?student=${student_id}`,
        HeaderConfig()
      );
      dispatch<any>(setSnackbar(true, 'success', 'Sms Sent Successfully'));
    } catch (error) {
      dispatch<any>(
        setSnackbar(true, 'error', 'Sms could not be sent at the moment.')
      );
    }
  };

export const GetFeeLedgerByDate =
  (student_id: string, date: string) =>
  async (dispatch: Dispatch<FeeLedgerDispatchTypes | SetSnackBarI>) => {
    const url = `${ADMIN_API_URL}/fee-ledger/?student=${student_id}&ledger_on=${date}`;
    try {
      dispatch({
        type: GET_FEE_LEDGER_BY_DATE_LOADING,
      });

      const res = await axios.get(url, HeaderConfig());

      dispatch({
        type: GET_FEE_LEDGER_BY_DATE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_FEE_LEDGER_BY_DATE_ERROR,
      });
    }
  };
