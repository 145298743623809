//-----------------<start> import module starts -----------------------//
import {
  Button,
  Grid,
  InputLabel,
  LinearProgress,
  Paper,
  TextField,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Search, Add } from '@material-ui/icons';
import { useForm } from 'react-hook-form';
import { Autocomplete } from '@material-ui/lab';
import { useFormStyles } from '../../Styles/FormStyles';
import Popups from '../../Reusable/Dialogs/Popups';
import TimetableModal from './TimetableModal/TimetableModal';
import AddNewButton from '../../Reusable/Buttons/AddNewButton';
import { SearchButton } from '../..';
import { GetClasses } from '../../../actions/Academics/Class/ClassAction';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import { ClassTypeI } from '../../../actions/Academics/Class/ClassActionTypes';
import { SectionTypeI } from '../../../actions/Academics/Section/SectionActionTypes';
import { useHistory } from 'react-router-dom';
import { timeTableResetState } from '../../../rtk/features/timetable/timetableSlices';
import BackDropLoader from '../../Reusable/BackDropLoader';
import { resetPeriodStateAction } from '../../../rtk/features/timetable/periodSlices';
//-----------------<start> import module ends -----------------------//

//-----------------<start> Interfaces starts-----------------------//
interface PropsI {
  searchData: (value: {
    classId: string | null;
    gradeId: string | null;
    section: string | null;
    hasSection: boolean;
  }) => void;
  // resetTable: (value: boolean) => void;
}

// -----------------<end> Interfaces <end>-----------------------//

//-----------------<start> Search timetable component <start>-----------------------//

const SearchTimetable = (props: PropsI) => {
  const { searchData } = props;
  const classes = useFormStyles();
  const history = useHistory();

  //-----------------<START> States starts-----------------------//
  const [loading, setLoading] = useState<boolean>(false);

  const [selectedClass, setSelectedClass] = useState<ClassTypeI | null>(null);
  const [classChoices, setClassChoices] = useState<ClassTypeI[]>([]);
  const [selectedSection, setSelectedSection] = useState<SectionTypeI | null>(
    null
  );
  const [sectionChoices, setSectionChoices] = useState<SectionTypeI[] | []>([]);
  const [sectionDisabler, setSectionDisabler] = useState<boolean>(true);

  const [btnDisabler, setBtnDisabler] = useState(true);
  //-----------------<END> States ends-----------------------//

  //================================ <START> REDUX CYCLE HOOKS <START> ============================//
  const dispatch = useDispatch();
  const classSelector = useSelector((state: RootStore) => state.class);
  const timeTableLoading = useSelector(
    (state: RootStore) => state.timetable.loading
  );

  useEffect(() => {
    dispatch(GetClasses());
    dispatch(timeTableResetState());
    dispatch(resetPeriodStateAction());
  }, []);

  useEffect(() => {
    setLoading(classSelector.loading);
    setClassChoices(classSelector.classes);
  }, [classSelector]);
  //================================ <END> REDUX CYCLE HOOKS <END> ============================//

  // ------------------<START> Select Choices starts ---------------------//
  // ------------------<ENDs> Select Choices endss ---------------------//

  //---------------------<START> Event Handlers starts-----------------------//
  const handleClassChange = (value: ClassTypeI | null) => {
    setSelectedClass(value);
    setSelectedSection(null);
    if (value) {
      if (value.section.length) {
        populateSectionChoices(value.section);
        setBtnDisabler(true);
      } else {
        setSectionDisabler(true);
        setBtnDisabler(false);
      }
    } else {
      setBtnDisabler(true);
    }
  };

  const populateSectionChoices = (sections: SectionTypeI[] | []) => {
    setSectionDisabler(false);
    sections.length > 0
      ? setSectionChoices(sections)
      : setSectionDisabler(true);
  };

  const handleSectionChange = (value: SectionTypeI | null) => {
    setSelectedSection(value);
    if (value) {
      setBtnDisabler(false);
    } else {
      setBtnDisabler(true);
    }
  };
  //---------------------<END> Event Handlers ends-----------------------//

  // ----------------------<Start> React Hook form starts -----------------------//

  // ----------------------<end> React Hook form ends -----------------------//

  const handleSearch = () => {
    searchData({
      classId: selectedClass?.id || null,
      gradeId: selectedClass?.grade || null,
      section: selectedSection?.id || null,
      hasSection: selectedClass?.section.length ? true : false,
    });
  };
  return (
    <>
      <Paper className={classes.pageContentBox}>
        {loading && (
          <LinearProgress style={{ margin: '-24px -24px 24px -24px' }} />
        )}

        <Grid
          container
          spacing={1}
          justifyContent="space-between"
          direction="row"
        >
          <Grid item xs={6} className={classes.formTitle}>
            <span>Search Timetable</span>
          </Grid>

          <Grid item>
            <a onClick={() => history.push('/pre-timetable')}>
              <AddNewButton
                title="Edit or Add Subjects TimeTable"
                type="button"
                color="primary"
                variant="outlined"
                startIcon={<Add />}
                style={{ width: '100%' }}
              >
                Edit/Add Subjects to Timetable
              </AddNewButton>
            </a>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={3} className={classes.formWrapper}>
            <InputLabel>Select Class</InputLabel>
            <Autocomplete
              onChange={(
                event: React.ChangeEvent<{}>,
                value: ClassTypeI | null
              ) => handleClassChange(value)}
              options={classChoices}
              getOptionLabel={(option) => option.grade_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Label"
                  name="class_name"
                  variant="outlined"
                />
              )}
            />
          </Grid>

          <Grid item xs={2} className={classes.formWrapper}>
            <InputLabel>
              Select Section
              {!sectionDisabler}
            </InputLabel>
            <Autocomplete
              onChange={(
                event: React.ChangeEvent<{}>,
                value: SectionTypeI | null
              ) => handleSectionChange(value)}
              options={sectionChoices}
              value={selectedSection}
              getOptionLabel={(option) => option.name}
              disabled={sectionDisabler}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Label"
                  name="session_name"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={3} className={classes.formWrapper}>
            <SearchButton
              disabled={btnDisabler}
              onClick={() => handleSearch()}
              style={{ marginTop: '20px' }}
            />
          </Grid>
        </Grid>
      </Paper>
      <BackDropLoader open={timeTableLoading} />
    </>
  );
};
// -----------------<End> Search timetable ends ----------------------//

export default SearchTimetable;
