export const GET_MARKSHEET_TEMPLATE_LOADING = "GET_MARKSHEET_TEMPLATE_LOADING";
export const GET_MARKSHEET_TEMPLATE_ERROR = "GET_MARKSHEET_TEMPLATE_ERROR";
export const GET_MARKSHEET_TEMPLATE_SUCCESS = "GET_MARKSHEET_TEMPLATE_SUCCESS";

export const ADD_MARKSHEET_TEMPLATE_LOADING = "ADD_MARKSHEET_TEMPLATE_LOADING";
export const ADD_MARKSHEET_TEMPLATE_ERROR = "ADD_MARKSHEET_TEMPLATE_ERROR";
export const ADD_MARKSHEET_TEMPLATE_SUCCESS = "ADD_MARKSHEET_TEMPLATE_SUCCESS";

export const ACTIVATE_OR_DEACTIVATE_MARKSHEET_TEMPLATE_LOADING =
  "ACTIVATE_OR_DEACTIVATE_MARKSHEET_TEMPLATE_LOADING";
export const ACTIVATE_OR_DEACTIVATE_MARKSHEET_TEMPLATE_ERROR =
  "ACTIVATE_OR_DEACTIVATE_MARKSHEET_TEMPLATE_ERROR";
export const ACTIVATE_OR_DEACTIVATE_MARKSHEET_TEMPLATE_SUCCESS =
  "ACTIVATE_OR_DEACTIVATE_MARKSHEET_TEMPLATE_SUCCESS";

export const GET_SINGLE_MARKSHEET_TEMPLATE_LOADING =
  "GET_SINGLE_MARKSHEET_TEMPLATE_LOADING";
export const GET_SINGLE_MARKSHEET_TEMPLATE_ERROR =
  "GET_SINGLE_MARKSHEET_TEMPLATE_ERROR";
export const GET_SINGLE_MARKSHEET_TEMPLATE_SUCCESS =
  "GET_SINGLE_MARKSHEET_TEMPLATE_SUCCESS";

export const UPDATE_MARKSHEET_TEMPLATE_LOADING =
  "UPDATE_MARKSHEET_TEMPLATE_LOADING";
export const UPDATE_MARKSHEET_TEMPLATE_ERROR =
  "UPDATE_MARKSHEET_TEMPLATE_ERROR";
export const UPDATE_MARKSHEET_TEMPLATE_SUCCESS =
  "UPDATE_MARKSHEET_TEMPLATE_SUCCESS";

export const DELETE_MARKSHEET_TEMPLATE_LOADING =
  "DELETE_MARKSHEET_TEMPLATE_LOADING";
export const DELETE_MARKSHEET_TEMPLATE_ERROR =
  "DELETE_MARKSHEET_TEMPLATE_ERROR";
export const DELETE_MARKSHEET_TEMPLATE_SUCCESS =
  "DELETE_MARKSHEET_TEMPLATE_SUCCESS";

export type ColorI = {
  key: string;
  value: string;
  name: string;
};

export type TemplateDataI = {
  ape_enable: boolean;
  footer_title: string;
  footer_text_1: string;
  footer_text_2: string;
  footer_text_3: string;
  thumbnail: string;
  watermark: boolean;
  school_logo: boolean;
  school_photo: boolean;
  watermark_position: string;
  school_logo_position: string;
  school_photo_position: string;
  grading_system: boolean;
};

//===============================<START>GET CLASS<START>======================//

export interface GetMarksheetTemplateLoading {
  type: typeof GET_MARKSHEET_TEMPLATE_LOADING;
}

export interface GetMarksheetTemplateError {
  type: typeof GET_MARKSHEET_TEMPLATE_ERROR;
}

export interface GetMarksheetTemplateSuccess {
  type: typeof GET_MARKSHEET_TEMPLATE_SUCCESS;
  payload: TemplateDataI[];
}

//===============================<END>GET CLASS<END>======================//

//===============================<START>GET CLASS<START>======================//

export interface GetSingleMarksheetTemplateLoading {
  type: typeof GET_SINGLE_MARKSHEET_TEMPLATE_LOADING;
}

export interface GetSingleMarksheetTemplateError {
  type: typeof GET_SINGLE_MARKSHEET_TEMPLATE_ERROR;
}

export interface GetSingleMarksheetTemplateSuccess {
  type: typeof GET_SINGLE_MARKSHEET_TEMPLATE_SUCCESS;
  payload: any;
}

//===============================<END>GET CLASS<END>======================//

//===============================<START>GET CLASS<START>======================//

export interface UpdateMarksheetTemplateLoading {
  type: typeof UPDATE_MARKSHEET_TEMPLATE_LOADING;
}

export interface UpdateMarksheetTemplateError {
  type: typeof UPDATE_MARKSHEET_TEMPLATE_ERROR;
}

export interface UpdateMarksheetTemplateSuccess {
  type: typeof UPDATE_MARKSHEET_TEMPLATE_SUCCESS;
  payload: any;
}

//===============================<END>GET CLASS<END>======================//

//===============================<START>ADD CLASS<START>======================//

export interface AddMarksheetTemplateLoading {
  type: typeof ADD_MARKSHEET_TEMPLATE_LOADING;
}

export interface AddMarksheetTemplateError {
  type: typeof ADD_MARKSHEET_TEMPLATE_ERROR;
}

export interface AddMarksheetTemplateSuccess {
  type: typeof ADD_MARKSHEET_TEMPLATE_SUCCESS;
  payload: TemplateDataI;
}

//===============================<END>ADD CLASS<END>======================//

//===============================<START>UPDATE CLASS<START>======================//

export interface ActivateOrDeactivateMarksheetTemplateLoading {
  type: typeof ACTIVATE_OR_DEACTIVATE_MARKSHEET_TEMPLATE_LOADING;
}

export interface ActivateOrDeactivateMarksheetTemplateError {
  type: typeof ACTIVATE_OR_DEACTIVATE_MARKSHEET_TEMPLATE_ERROR;
}

export interface ActivateOrDeactivateMarksheetTemplateSuccess {
  type: typeof ACTIVATE_OR_DEACTIVATE_MARKSHEET_TEMPLATE_SUCCESS;
  payload: any;
}

//===============================<END>UPDATE CLASS<END>======================//

//===============================<START>GET CLASS<START>======================//

export interface DeleteMarksheetTemplateLoading {
  type: typeof DELETE_MARKSHEET_TEMPLATE_LOADING;
}

export interface DeleteMarksheetTemplateError {
  type: typeof DELETE_MARKSHEET_TEMPLATE_ERROR;
}

export interface DeleteMarksheetTemplateSuccess {
  type: typeof DELETE_MARKSHEET_TEMPLATE_SUCCESS;
  payload: any;
}

//===============================<END>GET CLASS<END>======================//

export type MarksheetTemplateDispatchTypes =
  | GetMarksheetTemplateLoading
  | GetMarksheetTemplateError
  | GetMarksheetTemplateSuccess
  | GetSingleMarksheetTemplateLoading
  | GetSingleMarksheetTemplateError
  | GetSingleMarksheetTemplateSuccess
  | AddMarksheetTemplateLoading
  | AddMarksheetTemplateError
  | AddMarksheetTemplateSuccess
  | ActivateOrDeactivateMarksheetTemplateLoading
  | ActivateOrDeactivateMarksheetTemplateError
  | ActivateOrDeactivateMarksheetTemplateSuccess
  | DeleteMarksheetTemplateLoading
  | DeleteMarksheetTemplateError
  | DeleteMarksheetTemplateSuccess
  | UpdateMarksheetTemplateLoading
  | UpdateMarksheetTemplateError
  | UpdateMarksheetTemplateSuccess;
