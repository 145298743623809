import {
  GET_PAYMENTMETHOD_LOADING,
  GET_PAYMENTMETHOD_ERROR,
  GET_PAYMENTMETHOD_SUCCESS,
  PaymentMethodDispatchTypes,
  ADD_PAYMENTMETHOD_LOADING,
  ADD_PAYMENTMETHOD_ERROR,
  ADD_PAYMENTMETHOD_SUCCESS,
  DELETE_PAYMENTMETHOD_LOADING,
  DELETE_PAYMENTMETHOD_ERROR,
  DELETE_PAYMENTMETHOD_SUCCESS,
  UPDATE_PAYMENTMETHOD_LOADING,
  UPDATE_PAYMENTMETHOD_ERROR,
  UPDATE_PAYMENTMETHOD_SUCCESS,
  PaymentMethodTypeI,
} from "../../actions/Fees/PaymentMethod/PaymentMethodActionTypes";

interface InitialStateI {
  loading: boolean;
  payment_method: PaymentMethodTypeI[];
  errors: any;
  recent?: boolean;
  add_or_update: boolean;
}

const initialState: InitialStateI = {
  loading: false,
  payment_method: [],
  errors: null,
  recent: false,
  add_or_update: false,
};

const PaymentMethodReducer = (
  state: InitialStateI = initialState,
  action: PaymentMethodDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_PAYMENTMETHOD_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_PAYMENTMETHOD_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_PAYMENTMETHOD_SUCCESS:
      return {
        ...state,
        loading: false,
        payment_method: action.payload,
        errors: null,
      };

    case ADD_PAYMENTMETHOD_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case ADD_PAYMENTMETHOD_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        add_or_update: true,
      };

    case ADD_PAYMENTMETHOD_SUCCESS:
      return {
        loading: false,
        payment_method: [...state.payment_method, action.payload],
        errors: null,
        recent: true,
        add_or_update: true,
      };

    case UPDATE_PAYMENTMETHOD_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case UPDATE_PAYMENTMETHOD_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        add_or_update: true,
      };

    case UPDATE_PAYMENTMETHOD_SUCCESS:
      const current_data: PaymentMethodTypeI[] = state.payment_method;
      const index: number = current_data.findIndex(
        (element) => element.id === action.payload.id
      );
      current_data[index] = action.payload;

      return {
        loading: false,
        payment_method: current_data,
        errors: null,
        recent: true,
        add_or_update: true,
      };

    case DELETE_PAYMENTMETHOD_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_PAYMENTMETHOD_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_PAYMENTMETHOD_SUCCESS:
      const new_data: PaymentMethodTypeI[] = state.payment_method;
      const item_index = new_data.findIndex(
        (element) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return {
        ...state,
        loading: false,
        payment_method: new_data,
        errors: null,
      };

    default:
      return state;
  }
};

export default PaymentMethodReducer;
