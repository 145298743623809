import axios from "axios";
import { Dispatch } from "redux";
import {
    GET_BILL_ADJUSTMENT_ERROR,
    GET_BILL_ADJUSTMENT_LOADING,
    GET_BILL_ADJUSTMENT_SUCCESS,
    POST_BILL_ADJUSTMENT_ERROR,
    POST_BILL_ADJUSTMENT_LOADING,
    POST_BILL_ADJUSTMENT_SUCCESS,
    BillAdjustmentDispatchTypes,
} from "./BillAdjustmentActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";
import { GET_INVOICE_DETAIL_LOADING, GET_INVOICE_DETAIL_SUCCESS } from "../FeeStatement/FeestatementActionTypes";
import { GET_INVOICE_DETAIL_ERROR } from "../Daybook/DaybookActionTypes";

interface RequestDataI {
    paid_invoice: string;
    updated_amount: number;
}

export const GetBillAdjustment =
    (id: number) =>
        async (dispatch: Dispatch<BillAdjustmentDispatchTypes | SetSnackBarI>) => {
            try {
                dispatch({
                    type: GET_BILL_ADJUSTMENT_LOADING,
                });

                const res = await axios.get(
                    `${ADMIN_API_URL}/BILL_ADJUSTMENT/?page=${id}`,
                    HeaderConfig()
                );

                dispatch({
                    type: GET_BILL_ADJUSTMENT_SUCCESS,
                    payload: res.data,
                });
            } catch (error) {
                dispatch({
                    type: GET_BILL_ADJUSTMENT_ERROR,
                });
            }
        };

export const PostBillAdjustment =
    (data: RequestDataI, invoiceID: string) =>
        async (dispatch: Dispatch<any>) => {
            dispatch({
                type: POST_BILL_ADJUSTMENT_LOADING,
            });

            try {
                const res = await axios.post(
                    `${ADMIN_API_URL}/report/bill_adjustment/`,
                    data,
                    HeaderConfig()
                );

                dispatch({
                    type: POST_BILL_ADJUSTMENT_SUCCESS,
                    payload: res.data,
                });

                dispatch({
                    type: SET_SNACKBAR,
                    payload: {
                        snackbarOpen: true,
                        snackbarType: "success",
                        snackbarMessage: "Bill Invoice Adjusted Successfully",
                    },
                });
            } catch (err: any) {
                dispatch({
                    type: POST_BILL_ADJUSTMENT_ERROR,
                    payload: err.response && err.response?.data,
                });

                dispatch({
                    type: SET_SNACKBAR,
                    payload: {
                        snackbarOpen: true,
                        snackbarType: "error",
                        snackbarMessage: "Fee Type Add Failed",
                    },
                });
            }
            finally {
                try {
                    dispatch({
                        type: GET_INVOICE_DETAIL_LOADING,
                    });

                    const res = await axios.get(
                        `${ADMIN_API_URL}/report/invoice-detail/?receipt=${invoiceID}`,
                        HeaderConfig()
                    );

                    dispatch({
                        type: GET_INVOICE_DETAIL_SUCCESS,
                        payload: res.data,
                    });
                } catch (error) {
                    dispatch({
                        type: GET_INVOICE_DETAIL_ERROR,
                    });
                }
            }
        };