// ---------------------------- <START> module imports start ----------------------------------//
import React, { useState, useEffect } from "react";
import { Paper, Typography } from "@material-ui/core";
import { useTableStyles } from "../../Styles/TableStyles";
import { useTable } from "../../Reusable";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import { DiscountTypeI } from "../../../actions/Fees/DiscountType/DiscountActionTypes";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import {
  DeleteDiscount,
  GetDiscount,
} from "../../../actions/Fees/DiscountType/DiscountAction";
import {
  ItemDeleteButton,
  ItemEditButton,
} from "../../Reusable/Buttons/TableButton";
// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//
interface PropsI {
  onEditData: (value: DiscountTypeI) => void;
  editCheck: { check: boolean; count: number };
}
interface HeadCellsI {
  id: string;
  label: string;
}
// ---------------------------- <END> interface ends ----------------------------------//

// ---------------------------- <START> styling starts ----------------------------------//

// ---------------------------- <END> styling ends ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "name", label: "Name" },
  { id: "discount_in", label: "Percent%" },
  { id: "discount_applicable", label: "Amount" },
  { id: "action", label: "Action" },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Discount Type Table Component Starts ---------------------------//
const DiscountTypeTable = (props: PropsI) => {
  const classes = useTableStyles();
  const { onEditData, editCheck } = props;

  const dispatch = useDispatch();
  const discountTypeState = useSelector(
    (state: RootStore) => state.discount_type
  );

  // States declaration
  const [tableData, setTableData] = useState<DiscountTypeI[]>([]);

  // Modal States
  const [loading, setLoading] = useState<boolean>(false);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    editCheck && setEditMode(editCheck.check);
  }, [editCheck]);

  useEffect(() => {
    dispatch(GetDiscount());
  }, []);

  useEffect(() => {
    setLoading(discountTypeState.loading);
    setTableData(discountTypeState.discount);
  }, [discountTypeState]);

  // console.log("object", tableData);

  // -----------------<START> Pagination states and functions <START>-----------------------//

  // -----------------<END> Pagination states and functions <END>-----------------------//

  // Retrieving re-usable components from useTable
  const { TableContainer, StyledTableCell, StyledTableRow } =
    useTable(headCells);

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string>("");

  const handleEditClicked = (data: DiscountTypeI) => {
    onEditData(data);
    setEditMode(true);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const handleDeleteModal = (value: boolean, id: string) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteDiscount(itemId));
    setDeleteModalOpen(false);
  };
  const getCustomTableRow = (item: DiscountTypeI) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {item.name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.discount_in}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.discount_applicable}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ItemEditButton
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleEditClicked(item)
            }
          />
          <ItemDeleteButton
            disabled={editMode}
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleDeleteModal(true, item.id)
            }
          />
        </StyledTableCell>
      </StyledTableRow>
    );
  };
  return (
    <Paper className={classes.root}>
      <Typography variant="h6">Discount Type List</Typography>
      <TableContainer
        loading={loading}
        items={tableData}
        headCells={headCells}
        getCustomTableRow={getCustomTableRow}
      />

      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
        dialogTitle="Are you sure to delete this record?"
        okText="Delete"
      />
    </Paper>
  );
};
// ---------------------------- <END> Discount Type Component Ends -------------------------------//

export default DiscountTypeTable;
