import moment from 'moment';
import NepaliDate from 'nepali-date-converter';

/*
 * This global date methods are available under Date object
 * For now, we can convert ad to bs and vice versa
 * using global date object.
 */

declare global {
  interface Date {
    toNepali: () => string;
    toEnglish: (d1: string) => string;
  }

  interface String {
    trunc: (limit: number) => string;
  }
}

Date.prototype.toNepali = function () {
  const dateBs = new NepaliDate(this).format('YYYY-MM-DD');
  return dateBs;
};

// Nepali to English Format
Date.prototype.toEnglish = function (d1: string) {
  const tempDateAd = new NepaliDate(d1).toJsDate();
  const dateAd = moment(tempDateAd).format('YYYY-MM-DD');
  return dateAd;
};

// Custom string method
// Don't use unless it is comment removed from here
String.prototype.trunc = function (limit) {
  const text = this.toString();

  if (text.length > limit) {
    for (let i = limit; i > 0; i--) {
      if (
        text.charAt(i) === ' ' &&
        (text.charAt(i - 1) != ',' ||
          text.charAt(i - 1) != '.' ||
          text.charAt(i - 1) != ';')
      ) {
        return text.substring(0, i) + '...';
      }
    }
    return text.substring(0, limit) + '...';
  } else return text;
};

export {};
