// ---------------- <START> module import ------------------//
import React, { useState, useEffect } from "react";
import { Dialog, Paper, Typography } from "@material-ui/core";
import { useTable } from "../../Reusable";
import { useTableStyles } from "../../Styles/TableStyles";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import {
  GetDepartments,
  DeleteDepartment,
} from "../../../actions/HumanResource/Department/DepartmentAction";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import { DepartmentTypeI } from "../../../actions/HumanResource/Department/DepartmentActionTypes";
import { HeadCellsI } from "../../../actions";
import {
  ItemDeleteButton,
  ItemEditButton,
  ItemPrintButton,
  ItemViewButton,
} from "../../Reusable/Buttons/TableButton";
import useLoading from "../../../useHook/useLoading";
import {
  GetDaybook,
  GetInvoiceDetail,
} from "../../../actions/Fees/Daybook/DaybookAction";
import getFullName from "../../utils/getFullName";
import getFullClassName from "../../utils/getFullClassName";
import FeeInvoice from "../CollectFees/FeeInvoice";
import { GetGeneralInfoData } from "../../../actions/GeneralSettings/GeneralInfo/GeneralInfoAction";
import { GetFeestatement } from "../../../actions/Fees/FeeStatement/FeestatementAction";
import { GetFeeIncome } from "../../../actions/Fees/FeeIncome/FeeIncomeAction";
// ---------------- <END> module import ------------------//

// ---------------- <START> Interface ------------------//

// ---------------- <END> Interface ------------------//

// ---------------- <START> Head Cells ------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N." },
  { id: "fee_header", label: "Fee Header" },
  { id: "no_of_students", label: "No. of Students" },
  { id: "amount_paid", label: "Total Amount Paid" },
  { id: "due_amount", label: "Due Amount" },
];
// ---------------- <START> Head Cells ------------------//

// ---------------- <START> Department Table Component ------------------//
const FeeIncomeTable = (props: any) => {
  const { fee } = props;

  const classes = useTableStyles();
  const dispatch = useDispatch();

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  const [tableData, setTableData] = useState<DepartmentTypeI[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);
  const departmentState = useSelector((state: RootStore) => state.department);
  const feeIncomeState = useSelector((state: RootStore) => state.fee_income);
  const [openInvoice, setOpenInvoice] = useState(false);

  const generalInfoData = useSelector(
    (state: RootStore) => state.general_info?.general_info
  );
  const authData = useSelector((state: RootStore) => state.auth);

  useEffect(() => {
    dispatch(GetFeeIncome(1, ""));
  }, []);

  useEffect(() => {
    if (itemId !== null) {
      dispatch(GetInvoiceDetail(itemId));
    }
  }, [itemId]);

  // useEffect(() => {
  //     if (authData.general_info != null) {
  //         dispatch(GetGeneralInfoData(authData.general_info.id));
  //     }
  // }, [authData]);

  useEffect(() => {
    if (feeIncomeState?.fee_income !== []) {
      setTableData(feeIncomeState?.fee_income);
    }
  }, [feeIncomeState]);

  const handleCloseInvoice = () => {
    setOpenInvoice(false);
  };

  const handleViewModal = (id: string) => {
    setItemId(id);
    setOpenInvoice(true);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteDepartment(itemId));
    setDeleteModalOpen(false);
  };

  const getCustomTableRow = (item: any, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.total_student_count || 0}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          Rs. {item.total_amount || 0}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          Rs. {item.total_paid || 0}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  const handlePaginationChange = (paginationIndex: number) => {
    dispatch(GetFeeIncome(paginationIndex, fee || ""));
  };

  return (
    <div style={{ padding: "15px" }}>
      <Typography variant="h6">Fee Income List</Typography>
      <TableContainer
        label="Invoice"
        loading={departmentState.loading}
        items={tableData}
        headCells={headCells}
        getCustomTableRow={getCustomTableRow}
        forPagination={feeIncomeState?.fee_income}
        // search_name="name"
        disableSearch
        handlePaginationChange={handlePaginationChange}
      />
      <Dialog
        open={openInvoice}
        onClose={handleCloseInvoice}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
        // className={classes.modalBox}
      >
        <FeeInvoice
          generalInfoData={generalInfoData}
          student=""
          invoiceID={itemId}
        />
      </Dialog>
    </div>
  );
};
// ---------------- <START> Department Table Component ------------------//

export default FeeIncomeTable;
