export const SELECT_RELIGION = {
  H: "Hinduisim",
  M: "Muslim",
  B: "Buddhism",
  C: "Christianity",
  O: "Others",
};

export const SELECT_GENDER = {
  M: "Male",
  F: "Female",
  O: "Others",
};

export const SELECT_BLOOD_GROUP = {
  1: "A+",
  2: "B+",
  3: "O+",
  4: "A-",
  5: "B-",
  6: "O-",
  7: "AB+",
  8: "AB-",
};

export const RELATION_STATUS_CHOICES = {
  F: "Father",
  M: "Mother",
  B: "Brother",
  S: "Sister",
  R: "Relative",
};

export const CATEGORY_CHOICES = {
  G: "General",
  O: "OBC",
  S: "Special",
  P: "Physically Challenged",
};

export const CONTRACT_TYPE = {
  F: "Full Time",
  P: "Part Time",
};

export const MEETING_CHOICES = {
  P: "Pending",
  A: "Approved",
  D: "Declined",
};

export const SUBJECT_TYPE_CHOICES = {
  1: "Theory",
  2: "Theory",
};

export const PRIMARY_DATE_FORMAT = "YYYY-MM-DD";
