import {
  GET_INTIMATION_SLIP_ERROR,
  GET_INTIMATION_SLIP_LOADING,
  GET_INTIMATION_SLIP_SUCCESS,
  CLEAR_INTIMATION_SLIP,
  IntimationSlipDispatchTypes,
} from "../../actions/Fees/IntimationSlip/IntimationSlipActionTypes";

interface InitialStateI {
  loading: boolean;
  intimation_slips: any;
  invoice_detail: any;
}

const initialState: InitialStateI = {
  loading: false,
  intimation_slips: [],
  invoice_detail: null,
};

const IntimationSlipReducer = (
  state: InitialStateI = initialState,
  action: IntimationSlipDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case CLEAR_INTIMATION_SLIP:
      return {
        loading: false,
        intimation_slips: null,
        invoice_detail: null,
      };
    case GET_INTIMATION_SLIP_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_INTIMATION_SLIP_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_INTIMATION_SLIP_SUCCESS:
      return {
        ...state,
        loading: false,
        intimation_slips: action.payload,
      };

    default:
      return state;
  }
};

export default IntimationSlipReducer;
