export const GET_APPLY_RUBRIC_CATEGORY_LOADING = "GET_APPLY_RUBRIC_CATEGORY_LOADING";
export const GET_APPLY_RUBRIC_CATEGORY_ERROR = "GET_APPLY_RUBRIC_CATEGORY_ERROR";
export const GET_APPLY_RUBRIC_CATEGORY_SUCCESS = "GET_APPLY_RUBRIC_CATEGORY_SUCCESS";

export const APPLY_RUBRIC_CATEGORY_LOADING = "APPLY_RUBRIC_CATEGORY_LOADING";
export const APPLY_RUBRIC_CATEGORY_ERROR = "APPLY_RUBRIC_CATEGORY_ERROR";
export const APPLY_RUBRIC_CATEGORY_SUCCESS = "APPLY_RUBRIC_CATEGORY_SUCCESS";

export interface ApplyPayloadI {
    staff: string;
    applicable_rubric_category: string[];
}
export interface IDNameI {
    id: string;
    name: string;

}
interface StaffI {
    date_of_birth: string;
    full_name: string;
    id: string;
    phone: string;
    designation: IDNameI;
}
export interface RubricStaffI {
    id: string;
    applicable_rubric_category: IDNameI[];
    staff: StaffI;
}
interface GetRubricStaff {
    current_page: number;
    links: any;
    per_page: number;
    total: number;
    total_pages: number;
    results: RubricStaffI[]
}

//===============================<START>APPLY RUBRIC CATEGORY<START>======================//

export interface ApplyRubricCategoryLoading {
    type: typeof APPLY_RUBRIC_CATEGORY_LOADING;
}

export interface ApplyRubricCategoryError {
    type: typeof APPLY_RUBRIC_CATEGORY_ERROR;
    payload: any;
}

export interface ApplyRubricCategorySuccess {
    type: typeof APPLY_RUBRIC_CATEGORY_SUCCESS;
    payload: ApplyPayloadI;
}

//===============================<END>APPLY RUBRIC CATEGORY<END>======================//

//===============================<START>GET APPLICABLE RUBRIC CATEGORY<START>======================//

export interface GetApplyRubricCategoryLoading {
    type: typeof GET_APPLY_RUBRIC_CATEGORY_LOADING;
}

export interface GetApplyRubricCategoryError {
    type: typeof GET_APPLY_RUBRIC_CATEGORY_ERROR;
}

export interface GetApplyRubricCategorySuccess {
    type: typeof GET_APPLY_RUBRIC_CATEGORY_SUCCESS;
    payload: GetRubricStaff;
}

//===============================<END>GET APPLICABLE RUBRIC CATEGORY<END>======================//

export type AssignRubricDispatchTypes =
    | ApplyRubricCategoryLoading
    | ApplyRubricCategoryError
    | ApplyRubricCategorySuccess
    | GetApplyRubricCategoryLoading
    | GetApplyRubricCategoryError
    | GetApplyRubricCategorySuccess;