import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { FinanceSidebar, FeesTable } from "../../components";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../store";
import { Redirect } from "react-router";
import { PageRoleAccess } from "../../components/utils/rolesConfig";

export default function FeesPage() {
  const authState = useSelector((state: RootStore) => state.auth);

  const nav = <FinanceSidebar fees />;

  if (!PageRoleAccess(authState?.role, "FINANCE", "fee_config"))
    return <Redirect to="/" />;

  return (
    <SubModuleLayout sideNav={nav}>
      <Grid container>
        <Grid item xs={12}>
          <FeesTable />
        </Grid>
      </Grid>
    </SubModuleLayout>
  );
}
