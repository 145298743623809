import React, { useState } from "react";
import { Tupple } from "../../actions";
import { HRsidebar } from "../../components";
import DateSearch from "../../components/HumanResource/StaffAttendance/DateSearch";
import ReportList from "../../components/HumanResource/StaffAttendance/ReportList";
import ReportSearch from "../../components/HumanResource/StaffAttendance/ReportSearch";
import StaffList from "../../components/HumanResource/StaffAttendance/StaffList";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const StaffAttendanceReportPage = () => {
  const nav = <HRsidebar staffAttendanceReport />;
  const [inputDate, setInputDate] = useState<string>("");
  const [monthName, setMonthName] = useState<Tupple | null>(null);
  const [childrenName, setChildrenName] = useState<Tupple | null>(null);
  return (
    <SubModuleLayout studentMenuActive sideNav={nav}>
      <div style={{ margin: "0px 15px" }}>
        <ReportSearch
          setMonthName={setMonthName}
          setChildrenName={setChildrenName}
        />
        <ReportList
          inputDate={inputDate}
          monthName={monthName}
          childrenName={childrenName}
        />
      </div>
    </SubModuleLayout>
  );
};

export default StaffAttendanceReportPage;
