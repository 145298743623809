import React from "react";
import { CommunicationSidebar } from "../../components";
import SearchSmsLogTable from "../../components/Communication/SmsLog/SearchSmsLog";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

export default function SmsLogPage() {
  const nav = <CommunicationSidebar smsLog />;
  return (
    <SubModuleLayout communicationMenuActive sideNav={nav}>
      <SearchSmsLogTable />
    </SubModuleLayout>
  );
}
