import { useDispatch } from "react-redux";
import { setSnackbar } from "../../actions/SnackbarAction";

const checkEmptyField = (
  data: any,
  handledocPush: () => void,
  showSnackbar: () => void
) => {
  const checkEmptyRow = (callback: (value: boolean) => void) => {
    let isEmpty = false;
    data.map((element: any) => {
      if (element.title === "") {
        isEmpty = true;
        return;
      }
    });
    callback(isEmpty);
  };

  checkEmptyRow((foundEmpty) => {
    if (foundEmpty) {
      // dispatch(setSnackbar(true, "warning", "Fields cannot be empty"));
      showSnackbar();
    } else {
      handledocPush();
    }
  });
};

export default checkEmptyField;
