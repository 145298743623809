//--------------------------<START> import modules start -----------------------------------//
import { Button, Paper, Typography } from "@material-ui/core";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useStyles } from "../Styles/FormStyles";
import PageNotFoundImage from "../../assets/images/ErrorPage.jpg";
//-------------------<END> import modules ends-----------------------------------//

//--------------------------<START> Admit Card Search starts----------------------//
const PageNotFound = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <>
      <Paper
        className={classes.pageContentBox}
        style={{ textAlign: "center", padding: "6rem 0", marginTop: "120px" }}
      >
        <div>
          <img src={PageNotFoundImage} style={{ height: "280px" }} />
        </div>

        {/* <Typography variant="h1">
          <strong>404</strong>
        </Typography>

        <Typography variant="h4" gutterBottom>
          Theres NOTHING here...
        </Typography>
        <Typography variant="body2" gutterBottom>
          ...maybe the page youre looking for is not found or never existed.
        </Typography> */}

        <Button
          type="submit"
          color="default"
          variant="contained"
          component={Link}
          to="/"
          style={{ width: "auto", marginTop: "16px", marginRight: "12px" }}
        >
          Return To Dashboard
        </Button>

        <Button
          type="submit"
          color="primary"
          variant="contained"
          onClick={() => history.goBack()}
          style={{ width: "auto", marginTop: "16px" }}
        >
          Go Back
        </Button>
      </Paper>
    </>
  );
};
// ----------------------------<END> Admit Card Search ends -----------------------------//
export default PageNotFound;
