//---------------------<start> module imports starts ----------------------//
import {
    Grid,
    InputLabel,
    Paper,
    TextField,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useFormStyles } from "../../Styles/FormStyles";
import { Autocomplete } from "@material-ui/lab";
import { Search } from "@material-ui/icons";
import { useForm } from "react-hook-form";
import { RootStore } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { GetClasses } from "../../../actions/Academics/Class/ClassAction";
import { GetSections } from "../../../actions/Academics/Section/SectionAction";
import { GetOpeningBalance } from "../../../actions/Fees/OpeningBalance/OpeningBalanceAction";
// -----------------<end> module imports ends -----------------------//

const StudentSearchForm = (props: any) => {
    const { title, setGrade, grade, setSection, section, setEditMode } = props;
    const classes = useFormStyles();

    const [gradeChoices, setGradeChoices] = useState<any | null>([]);
    const [sectionChoices, setSectionChoices] = useState<any[] | []>([]);
    const [sectionDisabler, setSectionDisabler] = useState<boolean>(true);

    const gradeList = useSelector((state: RootStore) => state.class.classes);


    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetSections());
        dispatch(GetClasses());
    }, []);

    useEffect(() => {
        gradeList && setGradeChoices(gradeList);
    }, [gradeList]);

    const { register, handleSubmit, errors, setValue, setError, clearErrors } =
        useForm<any>({
            mode: "onChange",
        });

    const handleGradeChange = (value: any | null) => {
        setEditMode(false);
        value != null && setGrade(value);
        value != null
            && dispatch(GetOpeningBalance(value.grade, ""))
        setSection(null);
        value != null
            ? populateSectionChoices(value.section)
            : setSectionDisabler(true);
        value == null && setGrade(null);
    };

    const populateSectionChoices = (sections: any | []) => {
        setSectionDisabler(false);
        sections.length > 0
            ? setSectionChoices(sections)
            : setSectionDisabler(true);
    };


    useEffect(() => {
        // if (grade === null) {
        // }
    }, [grade]);

    // useEffect(() => {
    //     if (section != null && grade != null) {
    //         dispatch(GetOpeningBalance(grade.grade, section.id));
    //     }
    // }, [section]);

    const handleSectionChange = (value: any) => {
        setEditMode(false);
        dispatch(GetOpeningBalance(grade?.grade || "", value?.id || ""));
        setSection(value);
    };

    return (
        <>
            {/* <Paper className={classes.pageContent}> */}
            <form className={classes.form} >
                {title && <Grid container>
                    <Grid item xs={12} className={classes.formTitle}>
                        <span>{title}</span>
                    </Grid>
                </Grid>}
                <Grid container spacing={3}>
                    <Grid item xs={3} className={classes.formWrapper}>
                        <InputLabel>Class</InputLabel>
                        <Autocomplete
                            onChange={(event: React.ChangeEvent<{}>, value: any | null) =>
                                handleGradeChange(value)
                            }
                            options={gradeChoices}
                            getOptionLabel={(option) => "Class" + " " + option.grade_name}
                            value={grade}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Select a Grade"
                                    name="class"
                                    variant="outlined"
                                    inputRef={register({ required: true })}
                                />
                            )}
                        />
                    </Grid>

                    {grade && <Grid item xs={3} className={classes.formWrapper}>
                        <InputLabel>Section</InputLabel>
                        <Autocomplete
                            onChange={(event: React.ChangeEvent<{}>, value: any | null) =>
                                handleSectionChange(value)
                            }
                            options={sectionChoices}
                            getOptionLabel={(option) => option.name}
                            // disabled={sectionDisabler}
                            value={section}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Select a Section"
                                    name="section"
                                    variant="outlined"
                                    inputRef={register({
                                        required: !sectionDisabler,
                                    })}
                                />
                            )}
                        />
                    </Grid>
                    }
                </Grid>
            </form>
            {/* </Paper> */}
        </>
    );
};
// -----------------<end> Selected Student form ends-----------------------//
export default StudentSearchForm;
