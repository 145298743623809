import NepaliDate from "nepali-date-converter";
import moment from "moment";

export const dateConverterAdToBs = (dateInAd: any) => {
  const dateAd = dateInAd && dateInAd.split("T")[0];

  const dateUnits = dateAd?.split("-");

  const newDateAd = new Date(
    parseInt(dateUnits[0]),
    parseInt(dateUnits[1]) - 1,
    parseInt(dateUnits[2])
  );

  const nepDate = new NepaliDate(newDateAd);
  return nepDate.format("YYYY-MM-DD");
};

export const dateConverterBsToAd = (dateInAd: any) => {
  const date5 = new NepaliDate(dateInAd).toJsDate();

  const year = date5.getFullYear();
  const day = date5.getDate();
  const month = date5.getMonth();

  const date = `${year}-${month + 1}-${day}`;
  return moment(date).format("YYYY-MM-DD");
};
