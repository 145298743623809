export const UPDATE_PROFILE_PICTURE_LOADING = "UPDATE_PROFILE_PICTURE_LOADING";
export const UPDATE_PROFILE_PICTURE_ERROR = "UPDATE_PROFILE_PICTURE_ERROR";
export const UPDATE_PROFILE_PICTURE_SUCCESS = "UPDATE_PROFILE_PICTURE_SUCCESS";

export type ProfilePic = {
    user: string;
    photo: string;
}

export interface UpdateProfilePictureLoading {
    type: typeof UPDATE_PROFILE_PICTURE_LOADING;
}

export interface UpdateProfilePictureError {
    type: typeof UPDATE_PROFILE_PICTURE_ERROR;
}

export interface UpdateProfilePictureSuccess {
    type: typeof UPDATE_PROFILE_PICTURE_SUCCESS;
    payload: ProfilePic;
}

export type ProfilePictureTypes =
    | UpdateProfilePictureLoading
    | UpdateProfilePictureError
    | UpdateProfilePictureSuccess;