import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Theme,
  useTheme,
  withStyles,
  Paper,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  Grid,
  TextField,
  Avatar,
  Button,
  List,
  Badge,
  createStyles,
} from '@material-ui/core';
import { RootStore } from '../../../store';
import { AdminOnlineClassTypeI } from '../../../actions/Classroom/OnlineClass/OnlineClassActionTypes';
import { useDispatch, useSelector } from 'react-redux';
import SectionField from './SectionField';
import ClassRoomTable from './ClassRoomTable';
import { Autocomplete } from '@material-ui/lab';
import { useFormStyles } from '../../Styles/FormStyles';
import { ArrowBack } from '@material-ui/icons';
import { SectionTypeI } from '../../../actions/Academics/Section/SectionActionTypes';
import { GetOnlineClassByTeacher } from '../../../actions/Classroom/OnlineClass/OnlineClassAction';
import { GetTeacherList } from '../../../actions/Classroom/TeacherList/TeacherListAction';
import { TeacherListTypeI } from '../../../actions/Classroom/TeacherList/TeacherListActionTypes';
import checkImageUrl from '../../utils/checkImageUrl';
import { ListItemLink } from '../Layout/ClassRoomSidebar';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

interface StyledTabsProps {
  value: number;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
    },
    Troot: {
      display: 'flex',
    },
    teacherBar: {
      display: 'flex',
      flexDirection: 'row',
    },
    primaryHover: {
      width: '100%',
      '&:hover': {
        borderRadius: '50%',
        color: '#FFFFFF',
      },
      '&.MuiListItem-gutters': {
        paddingLeft: '8px',
        paddingRight: '8px',
      },
      '&.MuiListItem-root.Mui-selected': {
        borderRadius: '50%',
        color: '#FFFFFF',
        background: 'transparent',
      },
    },
  })
);

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: '$ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  })
)(Badge);

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 100,
      width: '100%',
      backgroundColor: '#635ee7',
    },
  },
})((props: any) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />
));

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function TeacherRoomTabs(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const classX = useFormStyles();
  const theme = useTheme();
  const { classItem } = props;

  const [value, setValue] = React.useState(0);
  const [selectedIndex, setSelectedIndex] = React.useState<number>(0);
  const [selectedSection, setSelectedSection] = useState<any>(null);
  const [sectionChoices, setSectionChoices] = useState<any>([]);
  const [teacherList, setTeacherList] = useState<TeacherListTypeI[]>([]);
  const [selectedTeacher, setSelectedTeacher] =
    useState<TeacherListTypeI | null>(null);
  const [onlineClass, setOnlineClass] = useState<AdminOnlineClassTypeI[]>([]);

  const teacherSelector = useSelector(
    (state: RootStore) => state.teacher_lists.teacher_list
  );
  const onlineClassSelector = useSelector(
    (state: RootStore) => state.online_classes.online_class
  );

  useEffect(() => {
    if (teacherSelector) {
      setTeacherList(teacherSelector);
    }
  }, [teacherSelector]);

  useEffect(() => {
    if (classItem) {
      setSectionChoices(classItem.section);
    }
    setSelectedSection(null);
  }, [classItem]);

  useEffect(() => {
    handleSectionChange(sectionChoices[0]);
  }, [sectionChoices]);

  useEffect(() => {
    if (selectedIndex === 0 && teacherList?.length) {
      handleListItemClick(0, teacherList[0]);
    }
  }, [selectedIndex, teacherList]);

  useEffect(() => {
    if (onlineClassSelector) {
      setOnlineClass(onlineClassSelector);
    }
  }, [onlineClassSelector]);

  useEffect(() => {
    if (classItem) {
      setSectionChoices(classItem.section);
    }
  }, [classItem, selectedSection]);

  useEffect(() => {
    if (sectionChoices && selectedSection == undefined) {
      setSelectedSection(sectionChoices[0]);
    }
  }, [sectionChoices]);

  const handleSectionChange = (value: SectionTypeI | null) => {
    if (value) {
      setSelectedSection(value);
      // dispatch(GetTeacherList(classItem.grade, value?.id));
    }
  };

  const handleListItemClick = (index: number, teacher: TeacherListTypeI) => {
    setSelectedIndex(index);
    if (teacher && classItem && selectedSection) {
      dispatch(
        GetOnlineClassByTeacher(
          classItem.grade,
          selectedSection.id,
          teacher.teacher_user_id
        )
      );
    }
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <div className={classes.Troot}>
        <List component="div" className={classes.teacherBar}>
          {teacherList &&
            teacherList.map((teacher, index) => {
              return (
                <>
                  <Grid
                    container
                    style={{ margin: '0 12px' }}
                    justifyContent="center"
                    direction="column"
                  >
                    <ListItemLink
                      key={teacher.id}
                      selected={selectedIndex === index}
                      onClick={(event) => handleListItemClick(index, teacher)}
                      className={classes.primaryHover}
                    >
                      <StyledBadge
                        overlap="circle"
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        variant={selectedIndex === index ? 'dot' : 'standard'}
                      >
                        <Avatar
                          alt="Remy Sharp"
                          src={checkImageUrl(teacher.photo)}
                        />
                      </StyledBadge>
                    </ListItemLink>
                    <Grid container justifyContent="center" direction="column">
                      <Box fontSize={11} textAlign="center">
                        {teacher.teacher_first_name}
                      </Box>
                      <Box fontSize={11} textAlign="center">
                        {teacher.teacher_last_name}
                      </Box>
                    </Grid>
                  </Grid>
                </>
              );
            })}
        </List>
        <Button
          style={{ alignContent: 'flex-end', marginLeft: 'auto' }}
          onClick={() => history.back()}
          color="primary"
        >
          <ArrowBack /> Go Back
        </Button>
      </div>
      <Paper elevation={0} className={classes.root}>
        <AppBar elevation={1} position="static" color="inherit">
          <StyledTabs value={value} onChange={handleChange}>
            <Tab label="All Classes" {...a11yProps(0)} />
            <Grid
              container
              justifyContent="flex-end"
              direction="row"
              alignItems="center"
              style={{ padding: '0 10px' }}
            >
              <Box mr={1} color="text.secondary" textAlign="left">
                Section
              </Box>
              <Grid
                style={{ width: '100px' }}
                item
                className={classX.formWrapper}
              >
                <Autocomplete
                  classes={{
                    input: classX.smallfont,
                    option: classX.smallfont,
                  }}
                  onChange={(event: React.ChangeEvent<{}>, value: any | null) =>
                    setSelectedSection(value)
                  }
                  options={sectionChoices}
                  value={selectedSection}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Choose Section"
                      name="section"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </StyledTabs>
        </AppBar>
        <TabPanel value={value} index={0} dir={theme.direction}>
          <ClassRoomTable space onlineClass={onlineClass} />
        </TabPanel>
      </Paper>
    </>
  );
}
