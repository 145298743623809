import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { Clear, Done } from '@material-ui/icons';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { PRIMARY_DATE_FORMAT } from '../../../common/constant';
import StaffCard from '../../Reusable/Dialogs/StudentCard.Modal';
import checkImageUrl from '../../utils/checkImageUrl';
import { useStyles } from '../../Styles/FormStyles';
import CustomizedTextField from '../../Reusable/Inputs/TextField';
import { dateConverterBsToAd } from '../../utils/dateConverter';
import { CUR_NEPALI_DATE } from '../../utils/nepaliDateUtils';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { UpdateLeave } from '../../../actions/Student/StudentLeave/LeaveAction';
import styled from 'styled-components/macro';
import { Alert } from '@material-ui/lab';
import GetStatus from '../../../common/GetStatus';

{
  /* <Leave.Button>Accept Leave Request</Leave.Button>
<Leave.Button>Decline Leave Request</Leave.Button>
<Leave.Button>Cancel</Leave.Button> */
}

export const LeaveButton = styled(Button)`
  &.MuiButton-containedPrimary {
    background-color: ${({ theme }) => theme.palette.success.main};
    &:hover {
      background-color: ${({ theme }) => theme.palette.success.dark};
    }
  }

  &.MuiButton-containedSecondary {
    background-color: ${({ theme }) => theme.palette.error.main};
    &:hover {
      background-color: ${({ theme }) => theme.palette.error.dark};
    }
  }
`;

const LeaveRequestView = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { register, handleSubmit, watch } = useForm();

  const { onActionConfirmed: handleSubmitProp, items } = props;
  const {
    id,
    name,
    photo,
    applied_date,
    leave_date,
    days,
    description,
    status,
    remarks,
  } = items;

  const [remarksActive, setRemarksActive] = useState(false);
  const [adminRemarks, setAdminRemarks] = useState('');
  const [adminResponse, setAdminResponse] = useState<string>('');
  const [isLeaveLatest, setIsLeaveLatest] = useState(true);
  const [staff, setStaff] = useState<any | null>(null);

  useEffect(() => {
    const item: any = {
      name: name,
      avatar: photo,
      id: id,
    };

    setStaff(item);
  }, []);

  useEffect(() => {
    const difference = moment(applied_date).diff(CUR_NEPALI_DATE, 'days');
    setIsLeaveLatest(difference >= 0);
  }, []);

  const handleClick = (status: string) => {
    setRemarksActive(true);
    setAdminResponse(status);
  };

  const handleClose = () => {
    setRemarksActive(false);
    setAdminRemarks('');
    setAdminResponse('');
  };

  const handleContinue = () => {
    handleSubmitProp(true, adminResponse);
  };

  const handleModalClose = () => {
    props.onClose();
  };

  const onSubmit = (data: any) => {
    const tempData = {
      id: items.id,
      leave_from: dateConverterBsToAd(items.leave_from),
      leave_to: dateConverterBsToAd(items.leave_to),
      reason_for_leave: items.description,
      student_academic: items.student_academic.id,
      remarks: data.remarks,
      status: data.accepted ? 'A' : 'D',
    };

    dispatch(UpdateLeave(items.id, tempData));
    handleModalClose();
  };

  const hasAccepeted = watch('accepted');

  return (
    <React.Fragment>
      {staff && <StaffCard staff={staff} />}
      <Divider style={{ marginBottom: '20px' }} />

      <Grid container style={{ marginBottom: '10px' }}>
        <Grid item xs={4}>
          Applied Date:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="subtitle2" style={{ lineHeight: 1 }}>
            {moment(applied_date).format(PRIMARY_DATE_FORMAT)}
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: '10px' }}>
        <Grid item xs={4}>
          Leave Date:
        </Grid>

        <Grid item xs={8}>
          <Typography variant="subtitle2" style={{ lineHeight: 1 }}>
            {moment(leave_date).format(PRIMARY_DATE_FORMAT)} -{' '}
            {moment(days).format(PRIMARY_DATE_FORMAT)} (
            <strong>{moment(days).diff(leave_date, 'days') + 1} days</strong>)
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: '10px' }}>
        <Grid item xs={4}>
          Status:
        </Grid>

        <Grid item xs={8}>
          {/* {status === 'P' && 'Pending'}
          {status === 'A' && 'Approved'}
          {status === 'D' && 'Declined'} */}
          <GetStatus status={status} />
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: '10px' }}>
        <Grid item xs={4}>
          Reason:
        </Grid>

        <Grid item xs={8}>
          {description || 'No Description'}
        </Grid>
      </Grid>

      {remarks && (
        <Grid container style={{ marginBottom: '10px' }}>
          <Grid item xs={4}>
            Remarks:
          </Grid>

          <Grid item xs={8}>
            {remarks}
          </Grid>
        </Grid>
      )}

      {status === 'P' && isLeaveLatest === false && (
        <Alert icon={false} severity="error">
          You can not respond to previous leave yet now.
        </Alert>
      )}

      {status === 'P' && isLeaveLatest && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container style={{ marginBottom: '10px' }}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  name="accepted"
                  inputRef={register({ required: false })}
                />
              }
              label="I have accepted this leave request."
            />
          </Grid>

          <Grid container style={{ marginBottom: '10px' }}>
            <TextField
              multiline
              variant="outlined"
              fullWidth
              rows={2}
              placeholder="Remarks"
              name="remarks"
              inputRef={register({ required: true })}
            />
          </Grid>

          <Grid
            container
            style={{ marginBottom: 8, marginTop: 12, gap: 16 }}
            justifyContent="flex-end"
          >
            {hasAccepeted === true && (
              <LeaveButton variant="contained" color="primary" type="submit">
                Accept Leave Request
              </LeaveButton>
            )}

            {hasAccepeted === false && (
              <LeaveButton variant="contained" color="secondary" type="submit">
                Decline Leave Request
              </LeaveButton>
            )}

            <LeaveButton
              variant="contained"
              color="default"
              onClick={handleModalClose}
            >
              Cancel
            </LeaveButton>
          </Grid>
        </form>
      )}
      {/* {remarksActive && (
        <Box className={classes.formWrapper} style={{ padding: '8px 0' }}>
          <CustomizedTextField
            placeholder={`Please add remarks before ${
              adminResponse === 'A' ? 'accepting' : 'declining'
            }`}
            variant="outlined"
            name="remarks"
            multiline
            rows={2}
            onChange={(event: React.ChangeEvent<any>) =>
              setAdminRemarks(event.target.value)
            }
          />
        </Box>
      )}

      {remarksActive && (
        <Grid
          container
          spacing={2}
          style={{ marginTop: '15px', marginBottom: '5px' }}
        >
          <Grid item style={{ marginLeft: 'auto' }}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Clear />}
              style={{ backgroundColor: '#E96C5A' }}
              onClick={handleClose}
            >
              Close
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Done />}
              style={{ backgroundColor: '#3FC581' }}
              onClick={handleContinue}
              disabled={adminRemarks.length === 0}
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      )}

      {isLeaveLatest && remarksActive === false && (
        <Grid
          container
          spacing={2}
          style={{ marginTop: '15px', marginBottom: '5px' }}
        >
          <Grid item style={{ marginLeft: 'auto' }}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Clear />}
              style={{ backgroundColor: '#E96C5A' }}
              onClick={() => handleClick('D')}
            >
              Declined
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Done />}
              style={{ backgroundColor: '#3FC581' }}
              onClick={() => handleClick('A')}
            >
              Approved
            </Button>
          </Grid>
        </Grid>
      )} */}
    </React.Fragment>
  );
};

export default LeaveRequestView;
