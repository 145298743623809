import axios from "axios";
import { Dispatch } from "redux";

import {
    GET_RUBRIC_SUB_CATEGORY_LOADING,
    GET_RUBRIC_SUB_CATEGORY_ERROR,
    GET_RUBRIC_SUB_CATEGORY_SUCCESS,
    RubricSubCategoryDispatchTypes,
    ADD_RUBRIC_SUB_CATEGORY_LOADING,
    ADD_RUBRIC_SUB_CATEGORY_SUCCESS,
    ADD_RUBRIC_SUB_CATEGORY_ERROR,
    DELETE_RUBRIC_SUB_CATEGORY_SUCCESS,
    DELETE_RUBRIC_SUB_CATEGORY_ERROR,
    DELETE_RUBRIC_SUB_CATEGORY_LOADING,
    UPDATE_RUBRIC_SUB_CATEGORY_LOADING,
    UPDATE_RUBRIC_SUB_CATEGORY_SUCCESS,
    UPDATE_RUBRIC_SUB_CATEGORY_ERROR,
} from "./RubricSubCategoryActionTypes"

import { SET_SNACKBAR, SetSnackBarI } from "../../../SnackbarActionTypes";
import { HeaderConfig } from "../../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../../config/SERVER_API_URLS";


export const GetRubricSubCategory =
    (id?: string) =>
        async (dispatch: Dispatch<RubricSubCategoryDispatchTypes | SetSnackBarI>) => {
            try {
                dispatch({
                    type: GET_RUBRIC_SUB_CATEGORY_LOADING,
                });
                let url = `${ADMIN_API_URL}/rubric-sub-category/`;
                if (id) {
                    url = `${ADMIN_API_URL}/rubric-sub-category/?category=${id}`
                }
                const res = await axios.get(url, HeaderConfig()
                );

                dispatch({
                    type: GET_RUBRIC_SUB_CATEGORY_SUCCESS,
                    payload: res.data,
                });
            } catch (error) {
                dispatch({
                    type: GET_RUBRIC_SUB_CATEGORY_ERROR,
                });

                // dispatch({
                //   type: SET_SNACKBAR,
                //   payload: {
                //     snackbarOpen: true,
                //     snackbarType: "error",
                //     snackbarMessage: "Rubric Sub-Categories Loading Failed",
                //   },
                // });
            }
        };

export const AddRubricSubCategory =
    (data: any) =>
        async (dispatch: Dispatch<RubricSubCategoryDispatchTypes | SetSnackBarI>) => {
            try {
                dispatch({
                    type: ADD_RUBRIC_SUB_CATEGORY_LOADING,
                });

                const res = await axios.post(
                    `${ADMIN_API_URL}/rubric-sub-category/`,
                    data,
                    HeaderConfig()
                );

                dispatch({
                    type: ADD_RUBRIC_SUB_CATEGORY_SUCCESS,
                    payload: res.data,
                });

                dispatch({
                    type: SET_SNACKBAR,
                    payload: {
                        snackbarOpen: true,
                        snackbarType: "success",
                        snackbarMessage: "Rubric Sub-Category Added Successfully",
                    },
                });
            } catch (error: any) {
                dispatch({
                    type: ADD_RUBRIC_SUB_CATEGORY_ERROR,
                    payload: error.response && error.response.data,
                });

                dispatch({
                    type: SET_SNACKBAR,
                    payload: {
                        snackbarOpen: true,
                        snackbarType: "error",
                        snackbarMessage: "Rubric Sub-Category Add Failed",
                    },
                });
            }
        };

export const UpdateRubricSubCategory =
    (id: string, data: any) =>
        async (dispatch: Dispatch<RubricSubCategoryDispatchTypes | SetSnackBarI>) => {
            try {
                dispatch({
                    type: UPDATE_RUBRIC_SUB_CATEGORY_LOADING,
                });

                const res = await axios.put(
                    `${ADMIN_API_URL}/rubric-sub-category/${id}/`,
                    data,
                    HeaderConfig()
                );

                dispatch({
                    type: UPDATE_RUBRIC_SUB_CATEGORY_SUCCESS,
                    payload: res.data,
                });

                dispatch({
                    type: SET_SNACKBAR,
                    payload: {
                        snackbarOpen: true,
                        snackbarType: "success",
                        snackbarMessage: "Rubric Sub-Category Updated Successfully",
                    },
                });
            } catch (error: any) {
                dispatch({
                    type: UPDATE_RUBRIC_SUB_CATEGORY_ERROR,
                    payload: error.response && error.response.data,
                });

                dispatch({
                    type: SET_SNACKBAR,
                    payload: {
                        snackbarOpen: true,
                        snackbarType: "error",
                        snackbarMessage: "Rubric Sub-Category Update Failed",
                    },
                });
            }
        };

export const DeleteRubricSubCategory =
    (id: string) =>
        async (dispatch: Dispatch<RubricSubCategoryDispatchTypes | SetSnackBarI>) => {
            try {
                dispatch({
                    type: DELETE_RUBRIC_SUB_CATEGORY_LOADING,
                });

                const res = await axios.delete(
                    `${ADMIN_API_URL}/rubric-sub-category/${id}/`,
                    HeaderConfig()
                );

                dispatch({
                    type: DELETE_RUBRIC_SUB_CATEGORY_SUCCESS,
                    payload: { id: id },
                });

                dispatch({
                    type: SET_SNACKBAR,
                    payload: {
                        snackbarOpen: true,
                        snackbarType: "success",
                        snackbarMessage: "Rubric Sub-Category Deleted Successfully",
                    },
                });
            } catch (error) {
                dispatch({
                    type: DELETE_RUBRIC_SUB_CATEGORY_ERROR,
                });

                dispatch({
                    type: SET_SNACKBAR,
                    payload: {
                        snackbarOpen: true,
                        snackbarType: "error",
                        snackbarMessage: "Rubric Sub-Category Delete Failed",
                    },
                });
            }
        };