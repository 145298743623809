import {
  GET_SUBJECT_GROUP_LOADING,
  GET_SUBJECT_GROUP_ERROR,
  GET_SUBJECT_GROUP_SUCCESS,
  GET_SUBJECT_GROUP_BY_CLASS_LOADING,
  GET_SUBJECT_GROUP_BY_CLASS_ERROR,
  GET_SUBJECT_GROUP_BY_CLASS_SUCCESS,
  SubjectGroupDispatchTypes,
  ADD_SUBJECT_GROUP_LOADING,
  ADD_SUBJECT_GROUP_ERROR,
  ADD_SUBJECT_GROUP_SUCCESS,
  DELETE_SUBJECT_GROUP_LOADING,
  DELETE_SUBJECT_GROUP_ERROR,
  DELETE_SUBJECT_GROUP_SUCCESS,
  UPDATE_SUBJECT_GROUP_LOADING,
  UPDATE_SUBJECT_GROUP_ERROR,
  UPDATE_SUBJECT_GROUP_SUCCESS,
  SubjectGroupTypeI,
} from "../../actions/Academics/Subject/SubjectGroupActionTypes";

interface InitialStateI {
  loading: boolean;
  subject_groups: SubjectGroupTypeI[];
  subjectGroupByClass: SubjectGroupTypeI | null;
  actionPerformed: boolean;
  errors?: any;
  recent?: boolean;
  add_or_update: boolean;
}

const initialState: InitialStateI = {
  loading: false,
  subject_groups: [],
  subjectGroupByClass: null,
  errors: null,
  recent: false,
  actionPerformed: false,
  add_or_update: false,
};

const SubjectGroupReducer = (
  state: InitialStateI = initialState,
  action: SubjectGroupDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_SUBJECT_GROUP_LOADING:
      return {
        ...state,
        loading: true,
        actionPerformed: false,
      };

    case GET_SUBJECT_GROUP_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_SUBJECT_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        subject_groups: action.payload,
        errors: null,
        recent: false,
      };

    case GET_SUBJECT_GROUP_BY_CLASS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_SUBJECT_GROUP_BY_CLASS_ERROR:
      return {
        ...state,
        loading: false,
        subjectGroupByClass: null,
      };

    case GET_SUBJECT_GROUP_BY_CLASS_SUCCESS:
      return {
        ...state,
        loading: false,
        subject_groups: action.payload,
        subjectGroupByClass: action.payload[0],
      };

    case ADD_SUBJECT_GROUP_LOADING:
      return {
        ...state,
        loading: true,
        actionPerformed: false,
        add_or_update: false,
      };

    case ADD_SUBJECT_GROUP_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        recent: false,
        add_or_update: true,
      };

    case ADD_SUBJECT_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        subject_groups: [...state.subject_groups, action.payload],
        errors: null,
        recent: true,
        actionPerformed: true,
        add_or_update: true,
      };

    case UPDATE_SUBJECT_GROUP_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
        actionPerformed: false,
      };

    case UPDATE_SUBJECT_GROUP_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        recent: false,
        add_or_update: true,
      };

    case UPDATE_SUBJECT_GROUP_SUCCESS:
      const current_data: SubjectGroupTypeI[] = state.subject_groups;
      const index: number = current_data.findIndex(
        (element) => element.id === action.payload.id
      );
      current_data[index] = action.payload;

      return {
        ...state,
        loading: false,
        subject_groups: current_data,
        errors: null,
        recent: true,
        add_or_update: true,
        actionPerformed: true,
      };

    case DELETE_SUBJECT_GROUP_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_SUBJECT_GROUP_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_SUBJECT_GROUP_SUCCESS:
      const new_data: SubjectGroupTypeI[] = state.subject_groups;
      const item_index = new_data.findIndex(
        (element) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return {
        ...state,
        loading: false,
        subject_groups: new_data,
      };

    default:
      return state;
  }
};

export default SubjectGroupReducer;
