import React from "react";
import { HRsidebar } from "../../components";
import DisabledStaffList from "../../components/HumanResource/StaffList/DisabledStaffList";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const DisabledStaffListPage = () => {
  const nav = <HRsidebar disabledStaff />;
  return (
    <SubModuleLayout studentMenuActive sideNav={nav}>
      <DisabledStaffList />
    </SubModuleLayout>
  );
};

export default DisabledStaffListPage;
