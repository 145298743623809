import React from "react";
import { HRsidebar, StaffList } from "../../components";
import StaffSearch from "../../components/HumanResource/StaffList/StaffSearch";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const StaffListPage = () => {
  const nav = <HRsidebar Staff />;
  return (
    <SubModuleLayout studentMenuActive sideNav={nav}>
      {/* Staff Search removed as it has no api */}
      {/* <StaffSearch /> */}
      <StaffList />
    </SubModuleLayout>
  );
};

export default StaffListPage;
