import { UserType } from "./../../Auth/Login/LoginActionTypes";

export const GET_STAFF_LOADING = "GET_STAFF_LOADING";
export const GET_STAFF_ERROR = "GET_STAFF_ERROR";
export const GET_STAFF_SUCCESS = "GET_STAFF_SUCCESS";

export const STAFF_BY_ID_LOADING = "STAFF_BY_ID_LOADING";
export const STAFF_BY_ID_ERROR = "STAFF_BY_ID_ERROR";
export const STAFF_BY_ID_SUCCESS = "STAFF_BY_ID_SUCCESS";

export const GET_TEACHERS_LOADING = "GET_TEACHERS_LOADING";
export const GET_TEACHERS_ERROR = "GET_TEACHERS_ERROR";
export const GET_TEACHERS_SUCCESS = "GET_TEACHERS_SUCCESS";

export const GET_ALL_TEACHERS_LOADING = "GET_ALL_TEACHERS_LOADING";
export const GET_ALL_TEACHERS_ERROR = "GET_ALL_TEACHERS_ERROR";
export const GET_ALL_TEACHERS_SUCCESS = "GET_ALL_TEACHERS_SUCCESS";

export const ADD_STAFF_LOADING = "ADD_STAFF_LOADING";
export const ADD_STAFF_ERROR = "ADD_STAFF_ERROR";
export const ADD_STAFF_SUCCESS = "ADD_STAFF_SUCCESS";

export const UPDATE_STAFF_LOADING = "UPDATE_STAFF_LOADING";
export const UPDATE_STAFF_ERROR = "UPDATE_STAFF_ERROR";
export const UPDATE_STAFF_SUCCESS = "UPDATE_STAFF_SUCCESS";

export const DELETE_STAFF_LOADING = "DELETE_STAFF_LOADING";
export const DELETE_STAFF_ERROR = "DELETE_STAFF_ERROR";
export const DELETE_STAFF_SUCCESS = "DELETE_STAFF_SUCCESS";

export const DISABLE_STAFF_LOADING = "DISABLE_STAFF_LOADING";
export const DISABLE_STAFF_ERROR = "DISABLE_STAFF_ERROR";
export const DISABLE_STAFF_SUCCESS = "DISABLE_STAFF_SUCCESS";

export const IMPORT_STAFF_LOADING = "IMPORT_STAFF_LOADING";
export const IMPORT_STAFF_ERROR = "IMPORT_STAFF_ERROR";
export const IMPORT_STAFF_SUCCESS = "IMPORT_STAFF_SUCCESS";

export const GET_DRIVERS_LOADING = "GET_DRIVERS_LOADING";
export const GET_DRIVERS_SUCCESS = "GET_DRIVERS_SUCCESS";
export const GET_DRIVERS_ERROR = "GET_DRIVERS_ERROR";

export const GET_DISABLED_STAFF_LOADING = "GET_DISABLED_STAFF_LOADING";
export const GET_DISABLED_STAFF_ERROR = "GET_DISABLED_STAFF_ERROR";
export const GET_DISABLED_STAFF_SUCCESS = "GET_DISABLED_STAFF_SUCCESS";

export type StaffTypeI = {
  id: string;
  user: UserType;
  designation_name: string;
};

export type DisabledStudentsType = {
  id: string;
  user: {
    id: string;
    username: string;
    first_name: string;
    last_name: string;
    email: string;
    groups: {
      id: string;
      name: string;
    }[];
  };
  designation: {
    id: string;
    name: string;
  } | null;
};

export type DisabledStaffPayloadType = {
  current_page: number;
  total: number;
  total_pages: number;
  per_page: number;
  results: DisabledStudentsType[];
};

//===============================<START>GET STAFF<START>======================//

export interface GetStaffLoading {
  type: typeof GET_STAFF_LOADING;
}

export interface GetStaffError {
  type: typeof GET_STAFF_ERROR;
}

export interface GetStaffSuccess {
  type: typeof GET_STAFF_SUCCESS;
  payload: any;
}

export interface GetStaffByIdLoading {
  type: typeof STAFF_BY_ID_LOADING;
}

export interface GetStaffByIdError {
  type: typeof STAFF_BY_ID_ERROR;
}

export interface GetStaffByIdSuccess {
  type: typeof STAFF_BY_ID_SUCCESS;
  payload: any;
}

export interface GetDisabledStaffLoading {
  type: typeof GET_DISABLED_STAFF_LOADING;
}

export interface GetDisabledStaffError {
  type: typeof GET_DISABLED_STAFF_ERROR;
}

export interface GetDisabledStaffSuccess {
  type: typeof GET_DISABLED_STAFF_SUCCESS;
  payload: DisabledStaffPayloadType;
}

//===============================<END>GET STAFF<END>======================//

//===============================<START>GET TEACHERS<START>======================//

export interface GetTeachersLoading {
  type: typeof GET_TEACHERS_LOADING;
}

export interface GetTeachersError {
  type: typeof GET_TEACHERS_ERROR;
}

export interface GetTeachersSuccess {
  type: typeof GET_TEACHERS_SUCCESS;
  payload: any;
}

//===============================<END>GET TEACHERS<END>======================//

//===============================<START>GET ALL TEACHERS<START>======================//

export interface GetAllTeachersLoading {
  type: typeof GET_ALL_TEACHERS_LOADING;
}

export interface GetAllTeachersError {
  type: typeof GET_ALL_TEACHERS_ERROR;
}

export interface GetAllTeachersSuccess {
  type: typeof GET_ALL_TEACHERS_SUCCESS;
  payload: any;
}

//===============================<END>GET aLL TEACHERS<END>======================//

//===============================<START>ADD STAFF<START>======================//

export interface AddStaffLoading {
  type: typeof ADD_STAFF_LOADING;
}

export interface AddStaffError {
  type: typeof ADD_STAFF_ERROR;
  payload: any;
}

export interface AddStaffSuccess {
  type: typeof ADD_STAFF_SUCCESS;
  payload: any;
}

//===============================<END>ADD STAFF<END>======================//

//===============================<START>UPDATE STAFF<START>======================//

export interface UpdateStaffLoading {
  type: typeof UPDATE_STAFF_LOADING;
}

export interface UpdateStaffError {
  type: typeof UPDATE_STAFF_ERROR;
  payload: any;
}

export interface UpdateStaffSuccess {
  type: typeof UPDATE_STAFF_SUCCESS;
  payload: any;
}

//===============================<END>UPDATE STAFF<END>======================//

//===============================<START>DELETE STAFF<START>======================//

export interface DeleteStaffLoading {
  type: typeof DELETE_STAFF_LOADING;
}

export interface DeleteStaffError {
  type: typeof DELETE_STAFF_ERROR;
}

export interface DeleteStaffSuccess {
  type: typeof DELETE_STAFF_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE STAFF<END>======================//

//===============================<START>DELETE STAFF<START>======================//

export interface DisableStaffLoading {
  type: typeof DISABLE_STAFF_LOADING;
}

export interface DisableStaffError {
  type: typeof DISABLE_STAFF_ERROR;
}

export interface DisableStaffSuccess {
  type: typeof DISABLE_STAFF_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE STAFF<END>======================//

//===============================<START>IMPORT STAFF<START>======================//

export interface ImportStaffLoading {
  type: typeof IMPORT_STAFF_LOADING;
}

export interface ImportStaffError {
  type: typeof IMPORT_STAFF_ERROR;
}

export interface ImportStaffSuccess {
  type: typeof IMPORT_STAFF_SUCCESS;
}

//===============================<END>IMPORT STAFF<END>======================//

//===============================<START>GET DRIVERs<START>======================//

export interface GetDriversLoading {
  type: typeof GET_DRIVERS_LOADING;
}

export interface GetDriversError {
  type: typeof GET_DRIVERS_ERROR;
}

export interface GetDriversSuccess {
  type: typeof GET_DRIVERS_SUCCESS;
  payload: { id: string; full_name: string }[];
}

//===============================<END>GET DRIVERS<END>======================//

export type StaffDispatchTypes =
  | GetStaffLoading
  | GetStaffError
  | GetStaffSuccess
  | GetStaffByIdLoading
  | GetStaffByIdError
  | GetStaffByIdSuccess
  | GetTeachersLoading
  | GetTeachersError
  | GetTeachersSuccess
  | GetAllTeachersLoading
  | GetAllTeachersError
  | GetAllTeachersSuccess
  | AddStaffLoading
  | AddStaffError
  | AddStaffSuccess
  | UpdateStaffLoading
  | UpdateStaffError
  | UpdateStaffSuccess
  | DeleteStaffLoading
  | DeleteStaffError
  | DeleteStaffSuccess
  | DisableStaffLoading
  | DisableStaffError
  | DisableStaffSuccess
  | ImportStaffLoading
  | ImportStaffError
  | ImportStaffSuccess
  | GetDriversLoading
  | GetDriversSuccess
  | GetDriversError
  | GetDisabledStaffLoading
  | GetDisabledStaffError
  | GetDisabledStaffSuccess;
