import React from 'react'
import {
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    Paper,
    Theme,
    createStyles,
    Grid,
    Button,
} from "@material-ui/core";
import ActionButton from '../../Reusable/Buttons/ActionButton'
import SaveIcon from "@material-ui/icons/Save";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            color: "#696969",
            paddingTop: "10px",
            "& .MuiTableRow-root": {
                height: "30px",
                maxHeight: "30px",
            },
            "&.MuiTableCell-root": {
                width: "50%",
            },
        },
        infobar: {
            padding: theme.spacing(2),
            margin: "1rem 2rem"
        },
        roota: {
            marginBottom: "1rem",
        },
    })
);

const ListView = (props: any) => {
    const classes = useStyles();
    const { setOnEditMode, title, data } = props;

    return (
        <Paper className={classes.infobar} elevation={1}>
            <TableContainer className={classes.roota}>
                <Grid container alignItems='center' justifyContent='space-between'>
                    <Typography align="left" variant="h5" component="h3">
                        {title}
                    </Typography>
                    <Button
                        type="button"
                        color="primary"
                        variant="contained"
                        startIcon={<SaveIcon />}
                        onClick={() => setOnEditMode(true)}
                    >
                        Edit
                    </Button>
                </Grid>
                <Table
                    size="small"
                    className={classes.table}
                    aria-label="simple table"
                >
                    <TableBody>
                        {data.map((row: any) => (
                            <TableRow key={row.name}>
                                <TableCell
                                    className={classes.table}
                                    component="th"
                                    scope="row"
                                >
                                    {row.name}
                                </TableCell>
                                <TableCell align="left">{row.value}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )
}

export default ListView