import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import FormLayout from "../../Reusable/Layouts/Form.Layout";
import CustomizedTextField from "../../Reusable/Inputs/TextField";
import { RootStore } from "../../../store";
import {
    AddRubricCategory,
    UpdateRubricCategory
} from "../../../actions/Academics/RubricCategory/RubricCategoryAction"
import { RubricCategoryTypeI } from "../../../actions/Academics/RubricCategory/RubricCategoryActionTypes"

// ---------------- <START> Interface ------------------//
interface RubricCategoryFormProps {
    editData: RubricCategoryTypeI | null;
    onEditMode: (value: boolean) => void;
}
// ---------------- <END> Interface ------------------//

const RubricCategoryForm = (props: RubricCategoryFormProps) => {
    const { editData, onEditMode } = props;
    const dispatch = useDispatch();
    const { register, handleSubmit, setValue, reset, errors } = useForm({
        mode: "onChange",
    });

    const [editMode, setEditMode] = useState<boolean>(false);
    const [editID, setEditID] = useState<string | null>(null);
    const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);

    const { loading, add_or_update } = useSelector((state: RootStore) => state.rubric_category);

    useEffect(() => {
        add_or_update && setIsBtnLoading(false)
        setEditMode(false);
        setEditID(null);
    }, [add_or_update])

    useEffect(() => {
        editData != null && handleEditTableData(editData);
    }, [editData]);

    const handleEditTableData = (data: RubricCategoryTypeI) => {
        setEditMode(true);
        setValue("name", data.name);
        setEditID(data.id);
    };

    const onSubmit = (data: RubricCategoryTypeI) => {
        setIsBtnLoading(true);
        if (editMode) {
            editID != null && dispatch(UpdateRubricCategory(editID, data));
        } else {
            dispatch(AddRubricCategory(data));
        }
        handleReset();
    };

    const handleReset = () => {
        reset();
        setEditMode(false);
        onEditMode(false);
        setEditID(null);
    };


    return (
        <>
            <FormLayout
                title="Add Rubric Category"
                onSubmit={handleSubmit(onSubmit)}
                editMode={editMode}
                loading={loading}
                onClick={!isBtnLoading && handleReset}
                add_or_update={isBtnLoading}
            >
                <CustomizedTextField
                    label="Name"
                    placeholder="Label"
                    name="name"
                    required
                    error={errors["name"] ? "Required Field." : ""}
                    inputRef={register({ required: true })}
                />
            </FormLayout>
        </>
    )
}

export default RubricCategoryForm