import axios from "axios";
import { Dispatch } from "redux";

import {
    GET_STUDENT_ASSIGNMENT_ERROR,
    GET_STUDENT_ASSIGNMENT_LOADING,
    GET_STUDENT_ASSIGNMENT_SUCCESS,
    StudentProgressDispatchTypes,
} from "./StudentProgressActionTypes"

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

export const GetStudentAssignmentProgress =
    (id: string) =>
        async (dispatch: Dispatch<StudentProgressDispatchTypes | SetSnackBarI>) => {
            try {
                dispatch({
                    type: GET_STUDENT_ASSIGNMENT_LOADING,
                })
                const res = await axios.get(
                    `${ADMIN_API_URL}/student-progress/?student_academic=${id}`,
                    HeaderConfig()
                )
                dispatch({
                    type: GET_STUDENT_ASSIGNMENT_SUCCESS,
                    payload: res.data,
                })
            } catch (error) {
                dispatch({
                    type: GET_STUDENT_ASSIGNMENT_ERROR,
                })
                dispatch({
                    type: SET_SNACKBAR,
                    payload: {
                        snackbarOpen: true,
                        snackbarType: "error",
                        snackbarMessage: "Student Assignment Loading Failed",
                    },
                });
            }
        }
