// ---------------------------- <START> module imports start ----------------------------------//
import React, { useEffect, useState, useRef } from "react";
import {
  TableBody,
  Grid,
  TableHead,
  makeStyles,
  Theme,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { PrintTableStyles } from "../../Styles/PrintTableStyles";
import { useTablePrint } from "../../Reusable";
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "../../../store";
import { MarkSheetTypeI } from "../../../actions/Examination/MarkSheet/MarkSheetActionTypes";
import { Printd } from "printd";
import "./invoice.css";
import ReactHtmlParser from "react-html-parser";
import { ToWords } from "to-words";
import {
  TopTagline,
  SchoolInfoContainer,
  SchoolTitle,
  Paragraph,
  ReportType,
  StudentProfileContainer,
  StudentDetailTextWrapper,
  StudentDetailText,
  Spacing,
  Sticky,
  DoubleBorder,
  // PrintNow,
} from "../../Reports/Marksheet/ReportStyles";
import printJS from "print-js";
import ReactToPrint from "react-to-print";
import { dateConverterAdToBs } from "../../utils/dateConverter";
import getFullName from "../../utils/getFullName";
import getFullClassName from "../../utils/getFullClassName";
import { GetReportSetting } from "../../../actions/GeneralSettings/ReportSetting/ReportSettingAction";
import checkImageUrl from "../../utils/checkImageUrl";
import { GetGeneralInfoData } from "../../../actions/GeneralSettings/GeneralInfo/GeneralInfoAction";
import { GetInvoiceDetail } from "../../../actions/Fees/Daybook/DaybookAction";
import getMonthName from "../../utils/getMonthName";
import intoWords from "../../utils/intoWords";
import getGradeFeeAmount from "../../utils/getGradeFeeAmount";
import getClassName from "../../utils/getClassName";
// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//

interface HeadCellsI {
  id: string;
  label: string;
}
// ---------------------------- <END> interface ends ----------------------------------//

interface HeadCellsI {
  id: string;
  label: string;
}

// -------------------- <START> Table Headings Data ---------------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S. No." },
  { id: "subject", label: "Subjects" },
  { id: "fullMarks", label: "Full Marks" },
  { id: "passMarks", label: "Pass Marks" },
  { id: "th", label: "TH" },
  { id: "pr", label: "PR" },
  { id: "total", label: "Total" },
  { id: "grade_point", label: "Grade Point" },
];
// ---------------------<END> Table Headings Data Ends --------------------------//

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1, 2),
    "& .MuiCardContent-root": {
      padding: theme.spacing(0),
    },
    "& .MuiTypography-h2": {
      fontSize: "3.1rem",
    },
    caption: {
      padding: "0px",
    },
  },
  idImage: {
    width: "80px",
    height: "80px",
  },
  line: {
    borderRight: "1px solid black",
  },
  bottom: {
    marginBottom: theme.spacing(1),
  },
  tableCell: {
    padding: "0px 2px !important",
    border: "1px solid black",
  },
  topWatermark: {
    backgroundImage: `url('https://i.imgur.com/KO5poDX.png')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "70%",
    backgroundPosition: "center",
  },
  centerWatermark: {
    backgroundImage: `url('https://i.imgur.com/KO5poDX.png')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "70%",
    backgroundPosition: "center",
  },
}));

interface propsI {
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  student: string;
  gpa: number;
}

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const FeeInvoice = (props: any) => {
  const { student, invoiceID } = props;
  const classes = PrintTableStyles();

  const localClasses = useStyles();

  // States declaration
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<MarkSheetTypeI[]>([]);
  const [subTotal, setSubTotal] = useState<number>(0);

  const componentRef = useRef<any>();
  const dispatch = useDispatch();

  const schoolState = useSelector(
    (state: RootStore) => state.auth.general_info
  );
  const examState = useSelector((state: RootStore) => state.exam.exam);
  const studentState = useSelector(
    (state: RootStore) => state.student.student_by_id
  );
  const reportSettingState = useSelector(
    (state: RootStore) => state.report_setting.reportsetting
  );

  const generalInfoData = useSelector(
    (state: RootStore) => state.general_info?.general_info
  );
  const authData = useSelector((state: RootStore) => state.auth);

  const invoiceData = useSelector(
    (state: RootStore) => state.day_book.invoice_detail
  );

  useEffect(() => {
    dispatch(GetReportSetting());
  }, []);

  useEffect(() => {
    if (invoiceData && invoiceData[0] !== null) {
      let count1 = 0;
      let count2 = 0;
      let count3 = 0;

      const discount =
        invoiceData?.invoice_detail[0]?.discount !== null
          ? Number(invoiceData?.invoice_detail[0]?.discount)
          : 0;

      invoiceData?.invoice_detail[0]?.student_paid_invoice?.map((elem: any) => {
        count1 += Number(elem.paid_amount);
      });
      invoiceData?.invoice_detail[0]?.student_paid_due_invoice?.map(
        (elem: any) => {
          count2 += Number(elem.paid_amount);
        }
      );
      invoiceData?.invoice_detail[0]?.student_paid_miscellaneous_invoice?.map(
        (elem: any) => {
          count3 += Number(elem.paid_amount);
        }
      );

      setSubTotal(count1 + count2 + count3 - Number(discount));
    }
  }, [invoiceData]);

  // useEffect(() => {
  //   if (authData.general_info != null) {
  //     dispatch(GetGeneralInfoData(authData.general_info.id));
  //   }
  // }, [authData]);

  useEffect(() => {
    if (invoiceID != null) {
      dispatch(GetInvoiceDetail(invoiceID));
    }
  }, [invoiceID]);

  // Retrieving re-usable components from useTable
  const {
    TblContainer,
    TblHead,
    StyledTableCell,
    StyledTableRow,
    exportToExcel,
    tablePrint,
  } = useTablePrint(headCells);

  // ============================ <START> REACT HOOKS <START> ============================== //
  const marksheetSelector = useSelector((state: RootStore) => state.marksheet);

  useEffect(() => {
    setLoading(marksheetSelector.loading);
    setTableData(marksheetSelector.marksheet);
  }, [marksheetSelector]);

  // ============================ <END> REACT HOOKS <END> ============================== //

  // ============================ <START> EVENT HANDLERS <START> ============================== //

  // Event function for table searching by name
  const handleTableSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    return;
  };

  const handlePrint = (e: any) => {
    e.preventDefault();
    const d = new Printd();
    const modalRoot = document.getElementById("print-element") as HTMLElement;
    d.print(modalRoot);
  };

  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
    },
  });

  function checkAdvanced() {
    const sub_total = subTotal;
    const paid_amount = Number(invoiceData?.invoice_detail[0]?.paid_amount);
    const dr = Number(invoiceData?.opening_balance[0]?.debit);
    const cr = Number(invoiceData?.opening_balance[0]?.credit);

    if (paid_amount === 0) {
      return null;
    }
    if (paid_amount > sub_total) {
      const value = paid_amount - sub_total;
      return "Receipt Advanced: " + value;
    }

    if (paid_amount < sub_total) {
      const value = paid_amount - sub_total;
      return "Receipt Due: " + Math.abs(value);
    }
    // if (dr > 0 && cr === 0 && paid_amount > 0) {
    //     const value = 0;
    // }
  }
  // ============================ <END> EVENT HANDLERS <END> ============================== //
  return (
    <>
      {invoiceData &&
        invoiceData?.invoice_detail &&
        invoiceData?.invoice_detail[0] && (
          // <Sticky>
          <>
            <ReactToPrint
              trigger={() => (
                <p
                  style={{
                    backgroundColor: "#132E98",
                    color: "white",
                    fontWeight: "bold",
                    borderRadius: "0px",
                    margin: "0px",
                    textAlign: "center",
                    padding: "5px 0px",
                    cursor: "pointer",
                  }}
                >
                  Print This Out !
                </p>
              )}
              content={() => componentRef.current}
              removeAfterPrint={true}
              copyStyles={true}
            />
            <div
              id="print-element"
              ref={componentRef}
              style={{
                padding: "15px 10px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>PAN: 242323</div>
                <div>Regd: {generalInfoData?.school_reg_number}</div>
              </div>
              <div
                style={{
                  marginTop: "5px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <img height="110" width="110" src={generalInfoData?.logo} />{" "}
                </div>
                <div>
                  <span style={{ fontSize: "20px" }}>
                    <b style={{ textTransform: "uppercase" }}>
                      {generalInfoData?.school_name}
                    </b>
                  </span>
                  <br />
                  <span>{generalInfoData?.address}</span>
                  <br />
                  <span>Tel/Fax: {generalInfoData?.phone_number}</span>
                  <br />
                  <span>Email: {generalInfoData?.email}</span>
                  <br />
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "#242221",
                  color: "white",
                  padding: "2px 3px",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    Bill No: {invoiceData?.invoice_detail[0]?.receipt_id}
                  </div>
                  <div>
                    Invoice Date:{" "}
                    {dateConverterAdToBs(
                      invoiceData?.invoice_detail[0]?.issued_date
                    )}
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "3px", display: "flex" }}>
                <b>Name: </b>{" "}
                {getFullName(
                  invoiceData?.invoice_detail[0]?.student?.student?.student_user
                    ?.first_name,
                  invoiceData?.invoice_detail[0]?.student?.student?.student_user
                    ?.last_name
                )}
                <br />
                <b style={{ paddingLeft: "10px" }}>Grade: </b>{" "}
                {getClassName(
                  invoiceData?.invoice_detail[0]?.student?.grade?.name,
                  invoiceData?.invoice_detail[0]?.student?.section?.name
                )}
                <br />
                <b style={{ paddingLeft: "10px" }}>Roll No: </b>{" "}
                {invoiceData?.invoice_detail[0]?.student?.roll_number}
                <br />
                <b style={{ paddingLeft: "10px" }}>Phone Number: </b>{" "}
                {invoiceData?.invoice_detail[0]?.student?.student?.phone_number}
              </div>
              <div style={{ marginTop: "5px" }}>
                <table id="customers">
                  <tr>
                    <th>S.N.</th>
                    <th>Fee Description</th>
                    <th>Fee Amount</th>
                    <th>Fine</th>
                    <th>Line Total</th>
                    <th>Paid Amount</th>
                  </tr>
                  {invoiceData?.invoice_detail[0]?.student_paid_invoice?.map(
                    (elem: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            {elem.invoice?.month !== ""
                              ? elem.invoice?.fee_type?.name +
                                " - " +
                                getMonthName(elem?.invoice?.month)
                              : elem.invoice?.fee_type?.fee_name}
                          </td>
                          <td>
                            Rs.{" "}
                            {getGradeFeeAmount(
                              invoiceData?.invoice_detail[0]?.student?.grade
                                ?.id,
                              elem.invoice?.fee_type?.grade_fee_amount
                            )}
                          </td>
                          <td>{elem.invoice_fine_applied[0]?.fine?.name}</td>
                          <td>Rs. {elem.paid_amount}</td>
                          <td>Rs. {elem.paid_amount}</td>
                        </tr>
                      );
                    }
                  )}
                  {invoiceData &&
                    invoiceData?.invoice_detail &&
                    invoiceData?.invoice_detail[0]?.student_paid_due_invoice?.map(
                      (elem: any, index: number) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{elem.due_invoice?.fee_type_name} (due)</td>
                            <td></td>
                            <td> </td>
                            <td>Rs. {elem.paid_amount}</td>
                            <td>Rs. {elem.paid_amount}</td>
                          </tr>
                        );
                      }
                    )}

                  {invoiceData &&
                    invoiceData?.invoice_detail &&
                    invoiceData?.invoice_detail[0]
                      ?.student_paid_miscellaneous_invoice?.length > 0 &&
                    invoiceData?.invoice_detail[0]?.student_paid_miscellaneous_invoice?.map(
                      (elem: any, index: number) => {
                        return (
                          <>
                            <tr>
                              <td colSpan={6}>
                                <b>Miscellaneous Fee(s)</b>
                              </td>
                            </tr>
                            <tr key={index}>
                              <td></td>
                              <td colSpan={3}>
                                {elem.invoice?.fee_type?.name}
                              </td>
                              <td> </td>
                              <td>Rs. {elem.paid_amount}</td>
                            </tr>
                          </>
                        );
                      }
                    )}
                  <tr>
                    <td colSpan={4}></td>
                    <td>Discount</td>
                    <td>Rs. {invoiceData?.invoice_detail[0]?.discount | 0}</td>
                  </tr>
                  <tr>
                    <td colSpan={4}></td>
                    <td>Sub Total</td>
                    <td>Rs. {subTotal}</td>
                  </tr>
                  {invoiceData?.invoice_detail[0]?.paid_amount === "0.00" && (
                    <tr>
                      <td colSpan={4}>
                        {" "}
                        <FormControlLabel
                          style={{ margin: "0px", padding: "0px" }}
                          control={
                            <Checkbox
                              color="default"
                              checked
                              style={{
                                color:
                                  invoiceData?.invoice_detail[0]
                                    ?.paid_amount === "0.00"
                                    ? "#4DA23F"
                                    : "",
                              }}
                            />
                          }
                          label=""
                        />{" "}
                        Opening Balance
                      </td>
                      <td>Dr.</td>
                      <td>Cr.</td>
                    </tr>
                  )}
                  {invoiceData?.invoice_detail[0]?.paid_amount === "0.00" && (
                    <tr>
                      <td colSpan={4}></td>
                      <td>Rs. {invoiceData?.opening_balance[0]?.debit || 0}</td>
                      <td>
                        Rs. {invoiceData?.opening_balance[0]?.credit || 0}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td colSpan={4}>
                      Total Paid (
                      {invoiceData?.invoice_detail[0]?.payment_method?.name})
                    </td>
                    <td>Rs. {invoiceData?.invoice_detail[0]?.paid_amount}</td>
                    <td>{checkAdvanced()}</td>
                  </tr>
                </table>
                {invoiceData?.invoice_detail[0]?.paid_amount !== "0.00" && (
                  <span style={{ fontSize: "13px" }}>
                    <i>
                      {toWords.convert(
                        Number(invoiceData?.invoice_detail[0]?.paid_amount)
                      )}{" "}
                      /-{" "}
                    </i>
                  </span>
                )}
                <br />
                <span style={{ fontSize: "13px" }}>
                  <u>Narration:</u>
                  <i>{invoiceData?.invoice_detail[0]?.remarks}</i>
                </span>
                <div
                  style={{
                    marginTop: "5px",
                    marginBottom: "3px",
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>{ReactHtmlParser(invoiceData?.footer_text)}</div>
                    <div>
                      <span style={{ textDecoration: "overline" }}>
                        Bill Verified By
                      </span>
                    </div>
                  </div>
                </div>
                <Grid
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <i>{generalInfoData?.slogan}</i>
                </Grid>
              </div>
            </div>
          </>
          // </Sticky>
        )}
    </>
  );
};

// ---------------------------- <END> Student Table Component Ends -------------------------------//
export default FeeInvoice;
