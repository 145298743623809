export const GET_STUDENT_PROFILE_LOADING = "GET_STUDENT_PROFILE_LOADING";
export const GET_STUDENT_PROFILE_ERROR = "GET_STUDENT_PROFILE_ERROR";
export const GET_STUDENT_PROFILE_SUCCESS = "GET_STUDENT_PROFILE_SUCCESS";

export const UPDATE_STUDENT_PROFILE_LOADING = "UPDATE_STUDENT_PROFILE_LOADING";
export const UPDATE_STUDENT_PROFILE_ERROR = "UPDATE_STUDENT_PROFILE_ERROR";
export const UPDATE_STUDENT_PROFILE_SUCCESS = "UPDATE_STUDENT_PROFILE_SUCCESS";

//===============================<START>GET STUDENT_HOUSE<START>======================//

export interface GetStudentProfileLoading {
  type: typeof GET_STUDENT_PROFILE_LOADING;
}

export interface GetStudentProfileError {
  type: typeof GET_STUDENT_PROFILE_ERROR;
}

export interface GetStudentProfileSuccess {
  type: typeof GET_STUDENT_PROFILE_SUCCESS;
  payload: any;
}

export interface UpdateStudentProfileLoading {
  type: typeof UPDATE_STUDENT_PROFILE_LOADING;
}

export interface UpdateStudentProfileError {
  type: typeof UPDATE_STUDENT_PROFILE_ERROR;
}

export interface UpdateStudentProfileSuccess {
  type: typeof UPDATE_STUDENT_PROFILE_SUCCESS;
  payload: any;
}

//===============================<END>GET STUDENT_HOUSE<END>======================//

//===============================<END>DELETE STUDENT_HOUSE<END>======================//

export type StudentProfileDispatchTypes =
  | GetStudentProfileLoading
  | GetStudentProfileError
  | GetStudentProfileSuccess
  | UpdateStudentProfileLoading
  | UpdateStudentProfileError
  | UpdateStudentProfileSuccess;
