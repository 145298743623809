import {
  GET_RESULT_LEDGER_ERROR,
  GET_RESULT_LEDGER_LOADING,
  GET_RESULT_LEDGER_SUCCESS,
} from "../../actions/Reports/IdCard/ResultLedger/ResultLedgerActionTypes";

interface InitialStateI {
  loading: boolean;
  result_ledger: any;
}

const initialState: InitialStateI = {
  loading: false,
  result_ledger: [],
};

const resultLedgerReducer = (
  state: InitialStateI = initialState,
  action: any
): InitialStateI => {
  switch (action.type) {
    case GET_RESULT_LEDGER_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_RESULT_LEDGER_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_RESULT_LEDGER_SUCCESS:
      return {
        ...state,
        loading: false,
        result_ledger: action.payload,
      };
    default:
      return state;
  }
};

export default resultLedgerReducer;
