import { Button, Grid, makeStyles, Theme } from '@material-ui/core';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import AuthForm from '../Components/AuthForm';
import AuthBanner from '../Components/AuthBanner';
import { RootStore } from '../../../store';
import { AuthPasswordField } from '../Components/AuthInputField';
import { ChangePassword } from '../../../actions/Auth/Login/LoginAction';
import { useEffect, useState } from 'react';

interface FormValuesI {
  password1: string;
  password2: string;
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    form: {
      marginTop: theme.spacing(6),
      maxWidth: 340,
    },
    link: {
      fontWeight: 600,
    },
  };
});

const ChangeAuthPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormValuesI>();
  const classes = useStyles();

  const authenticationState = useSelector(
    (state: RootStore) => state.auth.authentication
  );
  const authState = useSelector((state: RootStore) => state.auth);

  const onFormSubmit: SubmitHandler<FormValuesI> = (data) => {
    if (authenticationState && authenticationState.otp_detail) {
      dispatch(
        ChangePassword({
          user: authenticationState.otp_detail.user.toString(),
          password1: data.password1,
          password2: data.password2,
        })
      );
    }
  };

  useEffect(() => {
    const { loading, error } = authenticationState;
    const { phone_user, otp_detail, otp_status } = authenticationState;

    if (
      (!loading && !error && !phone_user) ||
      !otp_detail ||
      otp_status === 'Invalid'
    ) {
      history.push('/authentication/otp-validation');
    }
  }, [authenticationState]);

  useEffect(() => {
    const { password_validated, authenticated } = authState;
    if (password_validated && authenticated) {
      history.push('/');
    }
  }, [authState]);

  return (
    <Grid container>
      <Grid item md={5}>
        <AuthForm
          title="Change Password"
          subtitle="Enter valid data below"
          className={classes.form}
          onSubmit={handleSubmit(onFormSubmit)}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <AuthPasswordField
                required
                label="New Password"
                placeholder="Enter Your New Password"
                error={Boolean(errors.password1)}
                helperText={errors.password1 && errors.password1.message}
                name="password1"
                register={register}
              />
            </Grid>

            <Grid item xs={12}>
              <AuthPasswordField
                required
                label="Confirm Password"
                placeholder="Re-Enter Your Password"
                error={Boolean(errors.password2)}
                helperText={
                  errors.password2 &&
                  (errors.password2.type === 'validate'
                    ? 'Passwords are mismatched.'
                    : errors.password2.message)
                }
                name="password2"
                register={register}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
              >
                Change
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button fullWidth component={RouterLink} to="/login">
                Back to Login
              </Button>
            </Grid>
          </Grid>
        </AuthForm>
      </Grid>

      <Grid item md={7}>
        <AuthBanner
          id={2}
          titleWide
          title="Closing gap between teachers, students &amp; parents"
          subtitle="We are ready to serve you with our premium learning contents embedded in a cutting edge technology"
        />
      </Grid>
    </Grid>
  );
};

export default ChangeAuthPassword;
