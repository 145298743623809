import { Avatar, Grid, Paper } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { GetOnlineClassLog } from "../../../actions/Classroom/OnlineClass/OnlineClassAction";
import { AdminOnlineClassTypeI } from "../../../actions/Classroom/OnlineClass/OnlineClassActionTypes";
import { URL } from "../../../config/SERVER_API_URLS";
import { RootStore } from "../../../store";
import { useTable, useTableWithPagination } from "../../Reusable";
import { ItemGoto, ItemLogList } from "../../Reusable/Buttons/TableButton";
import Popups from "../../Reusable/Dialogs/Popups";
import { useTableStyles } from "../../Styles/TableStyles";
import { countdownTimer, countdownToday } from "../../utils/countdownTimer";
import getFullName from "../../utils/getFullName";
import ClassRoomLog from "./ClassRoomLog";

interface HeadCellsI {
  id: string;
  label: string;
}

interface UserI {
  name: string;
}

// -------------------- <START> Table Headings Data starts---------------------------//
const headCells: HeadCellsI[] = [
  { id: "title", label: "Title" },
  { id: "date", label: "Date " },
  { id: "teacher_name", label: "Teacher Name " },
  { id: "status", label: "Status " },
  { id: "time", label: "Time " },
  { id: "action", label: "Action" },
];

const ClassRoomTable = (props: any) => {
  const classes = useTableStyles();
  const dispatch = useDispatch();

  const { onlineClass, today, space } = props;

  const [loading, setLoading] = useState<boolean>(false);

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<number | null>(null);
  const [openViewModalPopup, setOpenViewModalPopup] = useState(false);
  const [user, setUser] = useState<UserI | null>(null);

  // States declaration
  const [liveclass, setLiveClass] = useState<any>([]);
  const [editMode, setEditMode] = useState(false);

  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  const handleViewClicked = (id: number) => {
    dispatch(GetOnlineClassLog(id));
    setOpenViewModalPopup(true);
  };
  const handleViewModalClose = (value: boolean) => {
    setOpenViewModalPopup(value);
  };

  const auth = useSelector((state: RootStore) => state.auth);

  useEffect(() => {
    if (auth) {
      setUser({ name: auth.user.first_name + " " + auth.user.last_name });
    }
  }, [auth]);

  const createMeet = (link: string) => {

    if (isNaN(parseInt(link))) {
      window.open(link)
    } else {
      window.open(`${URL}online-class.html?mid=${link}`);
      if (user?.name && auth.general_info?.logo) {
        localStorage.setItem("user", user.name);
        localStorage.setItem("user_photo", auth.general_info?.logo);
        localStorage.setItem("home_url", window.location.origin);
      }
    }
  };

  const getCustomTableRow = (item: any) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {item.title}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.class_date ? item.class_date : "Regular Class"}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.threeCell}>
          {getFullName(item.teacher_first_name, item.teacher_last_name)}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.is_completed
            ? "completed"
            : item.is_upcoming
              ? "Upcoming"
              : "Running"}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.timeRemain ? (
            `${item.timeRemain.days > 0
              ? item.timeRemain.days + " " + "days"
              : ""
            } ${item.timeRemain.hours > 0
              ? item.timeRemain.hours + " " + "hours"
              : ""
            }  ${item.timeRemain.minutes} minutes remaining`
          ) : (
            <span>Time's up</span>
          )}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ItemGoto title="Join class" onClick={() => createMeet(item.link)} />
          <ItemLogList
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleViewClicked(item.id)
            }
          />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <div style={{ padding: space ? "0 1rem" : "" }}>
      <TableContainer
        label="Student list"
        loading={loading}
        items={onlineClass}
        disableSearch
        headCells={headCells}
        getCustomTableRow={getCustomTableRow}
      // forPagination={liveclass}
      />
      <Popups
        openPopup={openViewModalPopup}
        setOpenPopup={setOpenViewModalPopup}
        onClose={handleViewModalClose}
        maxWidth="md"
        fullWidth={true}
        title="Online Class Log"
        colored
      >
        <ClassRoomLog admin />
      </Popups>
    </div>
  );
};

export default ClassRoomTable;
