import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useSidebarStyles = makeStyles((theme: Theme) =>
  createStyles({
    Sidebar: {
      marginTop: "10px",
      display: "flex",
      flexDirection: "column",
      // position: "fixed",
      // left: "0px",
      // minWidth: "200px",
      borderRight: "1px solid #e3e1e1",
      backgroundColor: "white",
      zIndex: 10,
      // overflow ~ scrollable nav
      //height: "calc(100vh - 100px)", // Subtract 100px for {header + nav} height
      overflowY: "auto",
      borderRadius: "4px",
      maxHeight: "calc(100vh - 170px)",
    },
    SidebarFull: {
      marginTop: "-38px",
      display: "flex",
      flexDirection: "column",
      // position: "fixed",
      // left: "0px",
      // minWidth: "200px",
      borderRight: "1px solid #e3e1e1",
      backgroundColor: "white",
      zIndex: 10,
      // overflow ~ scrollable nav
      height: "auto", // Subtract 100px for {header + nav} height
      overflowY: "auto",
      borderRadius: "4px",
      maxHeight: "calc(100vh - 130px)",
    },
    sidebarContent: {
      padding: "1rem",
      "& .MuiListItem-gutters": {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
      "& .MuiListItemText-primary": {
        fontSize: "14px",
      },
      "& .MuiListItemText-root": {
        margin: 0,
      },
    },
    sidebarTitleIcon: {
      marginRight: "8px",
      fontSize: "34px",
      verticalAlign: "bottom",
    },
    sidebarTitle: {
      marginBottom: "1.5rem",
      "& .MuiSvgIcon-root": {
        marginRight: "8px",
        fontSize: "34px",
        verticalAlign: "bottom",
      },
    },
  })
);
