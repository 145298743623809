import { Grid, Paper } from "@material-ui/core";
import React, { useState } from "react";
import { ClassTypeI } from "../../actions/Academics/Class/ClassActionTypes";
import AdminEnoteCard from "../../components/ClassRoom/AdminEnoteCard";
import AdminEnoteTable from "../../components/ClassRoom/AdminEnoteTable";
import ClassLayout from "../../components/ClassRoom/Layout/ClassLayout";
import ClassRoomSidebar from "../../components/ClassRoom/Layout/ClassRoomSidebar";
import SearchEnote from "../../components/ClassRoom/SearchEnote";

const EnotesPage = () => {
  const [classItem, setClassItem] = useState<ClassTypeI | null>(null);

  const onClassItem = (value: ClassTypeI) => {
    setClassItem(value);
  };

  const nav = <ClassRoomSidebar onClassItem={onClassItem} />;

  return (
    <div
      style={{ marginTop: "110px", paddingRight: "20px", minHeight: "80vh" }}
    >
      <ClassLayout sideNav={nav}>
        <Grid container>
          <Grid item xs={12}>
            <SearchEnote classItem={classItem} />
            {/* <AdminEnoteCard classItem={classItem} /> */}
            <AdminEnoteTable />
          </Grid>
        </Grid>
      </ClassLayout>
    </div>
  );
};

export default EnotesPage;
