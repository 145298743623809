// ---------------- <START> module import ends ------------------//
import React, { useState, useEffect, useRef } from "react";
import "nepali-datepicker-reactjs/dist/index.css";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import { useForm } from "react-hook-form";
import { GetGrades } from "../../../actions/Academics/Grade/GradeAction";
import { Grid, Paper } from "@material-ui/core";
import CustomizedSelect, { RowSelect } from "../../Reusable/Inputs/Select";
import { Fee_TypeI } from "../../../actions/Fees/FeeType/FeeTypeActionTypes";
import { GetFeeType } from "../../../actions/Fees/FeeType/FeeTypeAction";
import FullWidthFormLayout from "../../Reusable/Layouts/FullWidthForm.layout";
import { ResetButton, SubmitButton } from "../../Reusable/Buttons/SearchButton";

// ---------------- <END> module import ends ------------------//
interface PropsI {
  onApplyFilter: (value: any) => void;
}

interface ChoicesI {
  key: string;
  value: string;
}

// ---------------- <START> Grades Form Component starts ------------------//
function FeesForm(props: PropsI) {
  const { onApplyFilter } = props;

  const [selectedFeeType, setSelectedFeeType] = useState<ChoicesI | null>(null);
  const [selectedGrade, setSelectedGrade] = useState<ChoicesI | null>(null);


  const {
    handleSubmit,
  } = useForm<any>({
    mode: "onChange",
  });

  const dispatch = useDispatch();

  const feeTypeSelector = useSelector((state: RootStore) => state.fee_type)
  const gradeSelector = useSelector((state: RootStore) => state.grade);

  const CLASS_CHOICES = gradeSelector.grades.map((item) => ({
    key: item.id,
    value: item.grade_name,
  }));


  const FEE_TYPE_CHOICES = feeTypeSelector.feeType.map((item: Fee_TypeI) => ({
    key: item.id,
    value: item.name,
  }))

  useEffect(() => {
    dispatch(GetGrades());
    dispatch(GetFeeType(1));
  }, []);


  const onFormSubmit = () => console.log("hello world");
  const handleReset = () => console.log("hello world");

  //--------------------------ERROR HANDLING-------------------------------//




  return (
    <>
      <FullWidthFormLayout
        title="Apply Filters"
        onSubmit={handleSubmit(onFormSubmit)}
      >
        <Grid container spacing={2}>
          <Grid item xs={3} >
            <CustomizedSelect
              label="Fee Type"
              name="fee_type"
              required
              options={FEE_TYPE_CHOICES}
              value={selectedFeeType}
              setValue={setSelectedFeeType}
            />
          </Grid>
          <Grid item xs={3}>
            <CustomizedSelect
              label="Classes"
              name="classes"
              required
              options={CLASS_CHOICES}
              value={selectedGrade}
              setValue={setSelectedGrade}
            />
          </Grid>
          <SubmitButton style={{ marginTop: "5px" }} />
          <ResetButton style={{ marginTop: "5px" }} onClick={handleReset} />
        </Grid>
      </FullWidthFormLayout>
    </>
  );
}
// ---------------- <END> Grades Form Component ends ------------------//
export default FeesForm;
