import {
  GET_ADMIN_STUDENT_LEAVE_LOADING,
  GET_ADMIN_STUDENT_LEAVE_ERROR,
  GET_ADMIN_STUDENT_LEAVE_SUCCESS,
  AdminStudentLeaveTypeI,
  AdminTodayOnStudentLeaveTypeI,
  ADMIN_STUDENT_LEAVE_APPROVE_OR_DECLINE_SUCCESS,
  ADMIN_STUDENT_LEAVE_APPROVE_OR_DECLINE_LOADING,
  ADMIN_STUDENT_LEAVE_APPROVE_OR_DECLINE_ERROR,
  GET_STUDENT_TODAY_ON_LEAVE_LOADING,
  GET_STUDENT_TODAY_ON_LEAVE_ERROR,
  GET_STUDENT_TODAY_ON_LEAVE_SUCCESS,
  AdminStudentLeaveDispatchTypes,
  UPDATE_ADMIN_STUDENT_LEAVE_LOADING,
  UPDATE_ADMIN_STUDENT_LEAVE_SUCCESS,
  UPDATE_ADMIN_STUDENT_LEAVE_ERROR,
} from '../../actions/Student/StudentLeave/LeaveActionTypes';

interface InitialStateI {
  loading: boolean;
  loadingOnLeave: boolean;
  leaves: any;
  todayOnLeaves: any;
}

const initialState: InitialStateI = {
  loading: false,
  leaves: null,
  loadingOnLeave: false,
  todayOnLeaves: [],
};

const StudentLeaveReducer = (
  state: InitialStateI = initialState,
  action: AdminStudentLeaveDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_ADMIN_STUDENT_LEAVE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_ADMIN_STUDENT_LEAVE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_ADMIN_STUDENT_LEAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        leaves: action.payload,
      };
    case UPDATE_ADMIN_STUDENT_LEAVE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_ADMIN_STUDENT_LEAVE_SUCCESS:
      const tempData = state.leaves.results;
      const index = tempData.findIndex(
        (leave: any) => leave.id === action.payload.id
      );
      tempData[index] = action.payload;

      return {
        ...state,
        loading: false,
        leaves: { ...state.leaves, results: tempData },
      };
    case UPDATE_ADMIN_STUDENT_LEAVE_ERROR:
      return {
        ...state,
        loading: false,
      };
    // case ADMIN_STUDENT_LEAVE_APPROVE_OR_DECLINE_SUCCESS:
    //   const temp_data = state.leaves;
    //   const indexNum: number = temp_data.findIndex(
    //     (element) => element.id === action.payload.id
    //   );
    //   const temp_leave = {
    //     ...temp_data[indexNum],
    //     status: action.payload.status,
    //   };
    //   temp_data[indexNum] = temp_leave;

    //   return {
    //     ...state,
    //     loading: false,
    //     leaves: temp_data,
    //   };
    case ADMIN_STUDENT_LEAVE_APPROVE_OR_DECLINE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ADMIN_STUDENT_LEAVE_APPROVE_OR_DECLINE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case GET_STUDENT_TODAY_ON_LEAVE_LOADING:
      return {
        ...state,
        loadingOnLeave: true,
      };
    case GET_STUDENT_TODAY_ON_LEAVE_ERROR:
      return {
        ...state,
        loadingOnLeave: false,
      };
    case GET_STUDENT_TODAY_ON_LEAVE_SUCCESS:
      return {
        ...state,
        loadingOnLeave: false,
        todayOnLeaves: action.payload,
      };
    default:
      return state;
  }
};

export default StudentLeaveReducer;
