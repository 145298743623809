import classes from "*.module.css";
import { Grid, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { HRsidebar } from "../../..";
import { GetStaffProfile } from "../../../../actions/HumanResource/StaffProfile/StaffProfileAction";
import { RootStore } from "../../../../store";
import CardLayout from "../../../Reusable/Layouts/CardLayout";
import SubModuleLayout from "../../../Reusable/Layouts/SubModuleLayout";
import { useCardStyles } from "../../../Styles/CardStyles";
import { dateConverterAdToBs } from "../../../utils/dateConverter";
import getFullName from "../../../utils/getFullName";
import StaffTabs from "./StaffTabs";
import { getStaffDetails } from "../../../../rtk/features/humanResource/humanResourceThunk";

const returnGenderString = (val: string) => {
  switch (val) {
    case "M":
      return "Male";

    case "F":
      return "Female";

    case "O":
      return "Others";

    default:
      return "-";
  }
};

function StaffProfile() {
  const classes = useCardStyles();
  const nav = <HRsidebar Staff />;
  const dispatch = useDispatch();
  const { staffID } = useParams<{ staffID: string }>();
  const [staff, setStaff] = useState<any>([]);

  const { loading, staffDetail, actionCompleted } = useSelector(
    (state: RootStore) => state.humanResource
  );

  useEffect(() => {
    staffID != undefined && dispatch(getStaffDetails(staffID));
  }, []);

  useEffect(() => {
    if (actionCompleted) {
      staffID != undefined && dispatch(getStaffDetails(staffID));
    }
  }, [actionCompleted]);

  const staffList = useSelector(
    (state: RootStore) => state.staffProfile.staff_profile
  );

  useEffect(() => {
    if (staffList != null) {
      setStaff(staffList);
    }
  }, [staffList]);

  const headData = [
    {
      id: 2,
      headCells: "Gender:",
      cellData: staffDetail?.gender
        ? returnGenderString(staffDetail.gender)
        : "-",
    },
    {
      id: 3,
      headCells: "Department:",
      cellData: staffDetail?.staff_faculty_info?.department.name || "-",
    },
    {
      id: 3,
      headCells: "Designation:",
      cellData: staffDetail?.staff_faculty_info?.designation.name || "-",
    },
    {
      id: 4,
      headCells: "Address:",
      cellData: staffDetail?.address || "-",
    },
    {
      id: 5,
      headCells: "Phone:",
      cellData: staffDetail?.phone || "-",
    },
    {
      id: 6,
      headCells: "Date of Joining:",
      cellData: staffDetail?.staff_faculty_info?.date_of_joining
        ? dateConverterAdToBs(staffDetail?.staff_faculty_info?.date_of_joining)
        : "-",
    },
  ];

  return (
    <>
      <SubModuleLayout studentMenuActive sideNav={nav}>
        <Grid container>
          <Grid item xs={3}>
            <CardLayout
              fullName={getFullName(
                staffDetail?.user.first_name || "-",
                staffDetail?.user.last_name || "-"
              )}
              headData={headData}
              imgUrl={staffDetail?.photo}
            />
          </Grid>
          <Grid item xs={9}>
            <StaffTabs />
          </Grid>
        </Grid>
      </SubModuleLayout>
    </>
  );
}

export default StaffProfile;
