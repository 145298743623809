// ---------------------------- <START> module imports start ----------------------------------//
import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Paper,
  Box,
  InputLabel,
  TextField,
} from "@material-ui/core";
import { Link, useHistory, useParams } from "react-router-dom";
import { useTableStyles } from "../../Styles/TableStyles";
import { useTableWithPagination } from "../../Reusable";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import {
  DeleteStaff,
  DisableStaff,
  GetPaginatedStaffs,
  GetStaffs,
} from "../../../actions/HumanResource/Staff/StaffAction";
import { HeadCellsI } from "../../../actions";
import {
  ItemDeleteButton,
  ItemDisableButton,
  ItemEditButton,
  ItemViewButton,
} from "../../Reusable/Buttons/TableButton";
// import getFullName from '../../utils/getFullName';
import getNewFullName from "../../utils/getNewFullName";
import UserAvatar from "../../Reusable/Profile/UserAvatar";
import StaffSearch from "./StaffSearch";
import DisableDialog from "../../Reusable/Dialogs/DisableDialog";
import { useStyles } from "../../Styles/FormStyles";
import { setSnackbar } from "../../../actions/SnackbarAction";
import CustomizedPopUp from "../../Reusable/Dialogs/CustomizedPopUp";
import StaffMainModal from "./StaffMainModal";
import { getStaffs } from "../../../rtk/features/humanResource/humanResourceThunk";
import { HrStaffListI } from "../../../rtk/features/humanResource/humanResource";
import Pagination from "../../Reusable/Pagination";
// ----------------------------<END> module imports end ----------------------------------//

// -------------- <START> interface starts --------------------//
interface TableDataI {
  id: string;
  staff_photo: string;
  staff_name: string;
  designation: string;
  department: string;
  phone_no: string;
}

// -------------- <END> interface ends --------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N" },
  { id: "student_name", label: "Staff Name" },
  { id: "designation", label: "Designation" },
  { id: "department", label: "Department" },
  { id: "phone_no", label: "Phone No." },
  { id: "action", label: "Action" },
];

// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const StaffList: React.FC = () => {
  const classes = useTableStyles();
  const styles = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();

  const [searchParams, setSearchParams] = useState<{
    page: string;
    limit: string;
    name: string;
    department: string;
    designation: string;
  }>({
    page: "1",
    limit: "10",
    name: "",
    designation: "",
    department: "",
  });

  // States declaration
  const [staffFormOpen, toggleStaffForm] = useState<boolean>(false);

  // Modal States
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);

  const [remarks, setRemarks] = useState<string>("");

  // Retrieving re-usable components from useTable
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTableWithPagination(headCells);

  // Staff reducer
  const { loading, staffs } = useSelector((state: RootStore) => state.staff);

  useEffect(() => {
    dispatch(
      GetPaginatedStaffs(
        searchParams.page,
        searchParams.limit,
        searchParams.department,
        searchParams.designation,
        searchParams.name
      )
    );
  }, [searchParams]);

  // Staff Modal
  const handleStaffModalClose = (value: boolean) => {
    toggleStaffForm(value);
    setRemarks("");
  };

  const onRedirect = () => {
    //
  };
  // /////////////////////////////////////////////////////////

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
    setRemarks("");
  };

  const handleDeleteModal = (id: string) => {
    setItemId(id);
    setDeleteModalOpen(true);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    if (confirmed && itemId) {
      if (remarks) {
        dispatch(DisableStaff({ staff: itemId, remarks }));
        setDeleteModalOpen(false);
      } else {
        dispatch(setSnackbar(true, "warning", "Please add a remarks"));
      }
    }
  };

  const handlePaginationChange = (paginationIndex: number) => {
    dispatch(getStaffs({ page: paginationIndex }));
  };

  const handleImport = () => {
    history.push("/staff-import");
  };

  const handleStaffSearch = (data: {
    department_id: string;
    designation_id: string;
    search: string;
  }) => {
    setSearchParams({
      ...searchParams,
      department: data.department_id,
      designation: data.designation_id,
      name: data.search,
    });
  };

  const getCustomTableRow = (item: HrStaffListI, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <UserAvatar
            name={item.user.first_name + " " + item.user.last_name}
            src={item.photo}
          />
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.designation || "-"}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.department || "-"}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.phone}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ItemViewButton component={Link} to={`staff/profile/${item.id}`} />
          {/* <Link to={`staff/edit/${item.id}`}>
            <ItemEditButton />
          </Link> */}

          <ItemDisableButton onClick={() => handleDeleteModal(item.id)} />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  return (
    <Paper className={classes.rootTableBox}>
      <Typography variant="h6">Staff List</Typography>
      <div>
        <StaffSearch
          searchStaffParams={(data) => handleStaffSearch(data)}
          onAddStaff={() => toggleStaffForm(true)}
        />
      </div>
      <TableContainer
        label="Staff List"
        loading={loading}
        items={staffs.results}
        headCells={headCells}
        forPagination={staffs}
        handlePaginationChange={handlePaginationChange}
        getCustomTableRow={getCustomTableRow}
        onImport={handleImport}
        disableSearch
        disablePagination
      />
      <Pagination
        loading={loading}
        totalRecords={staffs.total || 0}
        onPageChange={(val) => setSearchParams({ ...searchParams, page: val })}
        onPageLimitChange={(val) =>
          setSearchParams({ ...searchParams, page: "1", limit: val })
        }
      />

      <DisableDialog
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
      >
        <Grid container justifyContent="center">
          <Grid className={styles.formWrapper} style={{ marginBottom: "2rem" }}>
            <InputLabel>Remarks</InputLabel>
            <TextField
              variant="outlined"
              multiline
              rows={4}
              name="remarks"
              placeholder="Remarks"
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
            />
          </Grid>
        </Grid>
      </DisableDialog>
      <CustomizedPopUp
        title="Add New Staff"
        open={staffFormOpen}
        onClose={handleStaffModalClose}
        actionConfirmed={onRedirect}
        dialogTitle="Staff Add Form"
        okText={false}
        cancel={false}
      >
        <StaffMainModal handleModalClose={handleStaffModalClose} />
      </CustomizedPopUp>
    </Paper>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default StaffList;
