export const GENERATE_RESULT_SMS_LOADING = "GENERATE_RESULT_SMS_LOADING";
export const GENERATE_RESULT_SMS_ERROR = "GENERATE_RESULT_SMS_ERROR";
export const GENERATE_RESULT_SMS_SUCCESS = "GENERATE_RESULT_SMS_SUCCESS";

export const SEND_RESULT_SMS_LOADING = "SEND_RESULT_SMS_LOADING";
export const SEND_RESULT_SMS_ERROR = "SEND_RESULT_SMS_ERROR";
export const SEND_RESULT_SMS_SUCCESS = "SEND_RESULT_SMS_SUCCESS";

export type GenerateResultSmsPayload = {
  message: string;
};

export type SendResultSmsPayload = {
  message: string;
};

//===============================<START>GET SMS_LOG <START>======================//

export interface GenerateResultSmsLoading {
  type: typeof GENERATE_RESULT_SMS_LOADING;
}

export interface GenerateResultSmsError {
  type: typeof GENERATE_RESULT_SMS_ERROR;
}

export interface GenerateResultSmsSuccess {
  type: typeof GENERATE_RESULT_SMS_SUCCESS;
  payload: GenerateResultSmsPayload;
}

export interface SendResultSmsLoading {
  type: typeof SEND_RESULT_SMS_LOADING;
}

export interface SendResultSmsError {
  type: typeof SEND_RESULT_SMS_ERROR;
}

export interface SendResultSmsSuccess {
  type: typeof SEND_RESULT_SMS_SUCCESS;
  payload: SendResultSmsPayload;
}

//===============================<END>GET SMS_LOG <END>======================//

export type GenerateResultSmsDispatchTypes =
  | GenerateResultSmsLoading
  | GenerateResultSmsError
  | GenerateResultSmsSuccess
  | SendResultSmsLoading
  | SendResultSmsError
  | SendResultSmsSuccess;
