import { useState, useEffect } from "react";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputLabel,
    TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import SaveIcon from "@material-ui/icons/Save";
import { RootStore } from "../../../store";
import { useStyles } from "../../Styles/FormStyles";
import { useFormStyles } from "../../Styles/FormStyles";
import { RubricCategoryTypeI } from "../../../actions/Academics/RubricCategory/RubricCategoryActionTypes"
import { ApplyRubricCategory } from "../../../actions/Academics/AssignRubric/AssignRubricAction"
import { ApplyPayloadI, RubricStaffI } from "../../../actions/Academics/AssignRubric/AssignRubricActionTypes"
import { FormSaveLoadingButton } from '../../Reusable/Buttons/FormButton';
import Spinner from '../../../common/spinnerSVG/Spinner';
import { GetAllTeachers } from "../../../actions/HumanResource/Staff/StaffAction"

interface PropsI {
    editMode: boolean;
    data: RubricStaffI | null;
}
interface RubricCheckBoxI {
    id: string;
    name: string;
    count?: number;
    checked: boolean;
}
interface StaffI {
    id: string;
    first_name: string;
    last_name: string;
}


const AssignRubricModal = (props: PropsI) => {
    const classes = useStyles();
    const styles = useFormStyles();
    const dispatch = useDispatch()
    const [rubric, setRubric] = useState<RubricCheckBoxI[]>([]);
    const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false)
    const [selectedStaff, setSelectedStaff] = useState<StaffI | null>(null)
    // Destructuring react-form-hook methods
    const { register, handleSubmit, errors, setValue, setError, clearErrors } =
        useForm<any>({
            mode: "onChange",
        });
    const { rubric_categories } = useSelector((state: RootStore) => state.rubric_category)
    const { add_or_update } = useSelector((state: RootStore) => state.assign_rubric)
    const { all_teachers } = useSelector((state: RootStore) => state.staff)
    useEffect(() => {
        dispatch(GetAllTeachers(""))
        // populateModal()
    }, [])

    useEffect(() => {
        if (add_or_update && !selectedStaff) {
            setIsBtnLoading(false);
        }
    }, [add_or_update, selectedStaff]);

    useEffect(() => {
        populateModal()
    }, [props]);

    const populateModal = () => {
        const rubricCheckBox: RubricCheckBoxI[] = [];
        rubric_categories.length &&
            rubric_categories.map((element) => {
                rubricCheckBox.push({
                    ...element,
                    checked: false,
                });
            });
        setRubric(rubricCheckBox);
        if (props.editMode) {
            if (!props.data) {
                return;
            }
            const [first_name, last_name] = props.data.staff.full_name.split(" ")
            setSelectedStaff({ id: props?.data?.id, first_name, last_name })
            const length = props.data.applicable_rubric_category && props.data.applicable_rubric_category.length
            const tempRubric: RubricCheckBoxI[] = rubricCheckBox.map((item: RubricCheckBoxI) => {
                for (let i = 0; i < length; i++) {
                    if (item.id === props.data?.applicable_rubric_category[i].id) {
                        item.checked = true;
                    }
                }
                return item;
            })
            setRubric(tempRubric)
        }
    }
    const handleStaffChange = (value: StaffI | null) => {
        setSelectedStaff(value)
    }
    const returnDisability = (id: string): boolean => {
        // if (studentCount && editMode) {
        //     return studentCount[id] > 0;
        // }
        return false;
    };
    const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const rubricCheckBox: RubricCheckBoxI[] = [...rubric];
        const index: number = rubricCheckBox.findIndex(
            (element) => element.id === event.target.value
        );
        rubricCheckBox[index] = {
            ...rubricCheckBox[index],
            checked: event.target.checked,
        };
        setRubric(rubricCheckBox);
    };
    const onSubmitModal = () => {
        const checkedRubric: string[] = rubric.filter((item) => item.checked).map((temp) => temp.id)
        const submitData: ApplyPayloadI = {
            staff: selectedStaff?.id ? selectedStaff.id : "",
            applicable_rubric_category: checkedRubric
        }
        dispatch(ApplyRubricCategory(submitData))
    }

    return (
        <form className={classes.form} onSubmit={handleSubmit(onSubmitModal)}>
            <Grid container>
                <Grid item md={12} className={classes.formWrapper} style={{ marginLeft: "2rem" }}>
                    <InputLabel>Select Rubric</InputLabel>
                    <Autocomplete
                        value={selectedStaff}
                        disabled={props.editMode}
                        options={all_teachers}
                        getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                        onChange={(
                            event: React.ChangeEvent<{}>,
                            value: {
                                id: string;
                                first_name: string;
                                last_name: string;
                            } | null
                        ) => {
                            handleStaffChange(value);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Select Staff"
                                name="staff"
                                variant="outlined"
                            />
                        )}
                    />
                    {rubric_categories.length > 0 ? (
                        <FormControl
                            component="fieldset"
                            className={styles.sectionCheckBox}
                        >
                            <FormGroup>
                                {rubric.map((element: RubricCheckBoxI) => (
                                    <FormControlLabel
                                        key={element.id}
                                        control={
                                            <Checkbox
                                                color="primary"
                                                value={element.id}
                                                onChange={handleCheckBoxChange}
                                                name={element.name}
                                                checked={element.checked}
                                                disabled={returnDisability(element.id)}
                                            />
                                        }
                                        label={element.name}
                                    />
                                ))}
                            </FormGroup>
                        </FormControl>
                    ) : (
                        <span className={styles.sectionUnavailableText}>
                            No Records Found
                        </span>
                    )}
                    <span className={classes.validationErrorInfo} />
                    <Grid container justifyContent='flex-start' style={{ marginBlock: "1em" }}>
                        <FormSaveLoadingButton
                            type="submit"
                            icon={isBtnLoading ? <Spinner /> : <SaveIcon />}
                            disabled={isBtnLoading ? true : false}
                            text={isBtnLoading ? "Assigning..." : "Assign"}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}

export default AssignRubricModal