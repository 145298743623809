import {
  Button,
  Checkbox,
  Chip,
  Grid,
  Paper,
  TableBody,
  TextField,
  Tooltip,
  Typography,
  InputLabel,
} from "@material-ui/core";
import { Add, EditOutlined, Save } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import CachedIcon from "@material-ui/icons/Cached";
import {
  DeleteStaffDocument,
  GetStaffDocument,
} from "../../../../../actions/HumanResource/StaffDocument/StaffDocumentAction";
import { DocumentTypeI } from "../../../../../actions/HumanResource/StaffDocument/StaffDocumentActionTypes";
import { RootStore } from "../../../../../store";
import { useTable } from "../../../../Reusable";
import ActionButton from "../../../../Reusable/Buttons/ActionButton";
import AddNewButton from "../../../../Reusable/Buttons/AddNewButton";
import {
  DownloadButton,
  ItemDeleteButton,
} from "../../../../Reusable/Buttons/TableButton";
import CustomizedDialogs from "../../../../Reusable/Dialogs/DeleteDialog";
import CustomizedPopUp from "../../../../Reusable/Dialogs/CustomizedPopUp";
import Popups from "../../../../Reusable/Dialogs/Popups";
import { HeadCellsI } from "../../../../Student/StudentProfile/File/Document";
import { useTableStyles } from "../../../../Styles/TableStyles";
import StaffUpload from "./StaffUpload";
import { SystemRolesTypeI } from "../../../../../actions/HumanResource/StaffRole/StaffRoleActionTypes";
import {
  GetSystemRoles,
  SetSystemRoles,
  SetUserPassword,
} from "../../../../../actions/HumanResource/StaffRole/StaffRolesAction";
import { setSnackbar } from "../../../../../actions/SnackbarAction";
import CustomBackdrop from "../../../../Reusable/Inputs/CustomBackdrop";
import formsValidationCheckup from "../../../../utils/formsValidationCheckUp";
import StaffPhoneNumberForm from "./StaffPhoneNumberForm";
import { SendUserCredentials } from "../../../../../actions/Student/StudentProfile/StudentProfileAction";
import { updateGuardianUsername } from "../../../../../actions/Student/Guardian/GuardianAction";
import { updateStaffUsername } from "../../../../../actions/HumanResource/StaffProfile/StaffProfileAction";

interface SelectOptionI {
  data: SystemRolesTypeI;
  selected: boolean;
}

const headCells: HeadCellsI[] = [
  { id: "file", label: "File" },
  { id: "file_name", label: "File Name" },
  { id: "action", label: "Action" },
];

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function genPassword() {
  let result = "";
  const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < 9; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function StaffRoles(props: any) {
  const classes = useTableStyles();
  const dispatch = useDispatch();
  const { user } = props;

  const { staffID } = useParams<{ staffID: string }>();

  // State Declarations
  const [username, setUserName] = useState<string>("");
  const [userId, setUserId] = useState<string | null>(null);

  const [roleChoices, setRoleChoices] = useState<SelectOptionI[]>([]);
  const [userRoles, setUserRoles] = useState<SystemRolesTypeI[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [selectedRoleValues, setSelectedRoleValues] = useState<SelectOptionI[]>(
    []
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const [editData, setEditData] = useState<any>([]);
  const [roleEditMode, setRoleEditMode] = useState(false);

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);
  const [password, setPassword] = useState<any>(null);
  const [isChangePassword, setIsChangePassword] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<any>("");
  const [editUsername, setEditUsername] = useState<boolean>(false);

  // Retrieving re-usable components from useTable

  const {
    TblContainer,
    StyledTableCell,
    StyledTableRow,
    exportToExcel,
    tablePrint,
  } = useTable(headCells);

  const handleClose = () => {
    setOpenPopup(false);
  };

  const staffSelector = useSelector((state: RootStore) => state.staffProfile);
  const rolesSelector = useSelector((state: RootStore) => state.staff_roles);
  const guardianSelector = useSelector((state: RootStore) => state.guardians);
  const studentState = useSelector((state: RootStore) => state.student_profile);
  const { staffDetail } = useSelector(
    (state: RootStore) => state.humanResource
  );

  useEffect(() => {
    HandlePhoneNumber();
  }, [staffSelector || guardianSelector || studentState]);

  useEffect(() => {
    dispatch(GetSystemRoles());
  }, []);

  useEffect(() => {
    if (guardianSelector.actionPerformed || staffSelector.actionPerformed) {
      setEditUsername(false);
    }
  }, [guardianSelector.actionPerformed, staffSelector.actionPerformed]);

  useEffect(() => {
    const handleData = (roles: any) => {
      setUserRoles(roles.length ? roles : []);
      if (roles.length) {
        const ids: string[] = [];
        roles.map((element: { id: string }) => {
          ids.push(element.id);
        });
        setSelectedRoles(ids);
      }
    };

    if (user === "staff") {
      const data = staffSelector.staff_profile;
      const roles = staffDetail?.user.groups || [];
      setUserName(staffDetail?.user?.username || "");
      setUserId(staffDetail?.user.id || null);
      handleData(roles);
    }
    setLoading(staffSelector.loading);
  }, []);

  useEffect(() => {
    const data = rolesSelector.system_roles;
    if (data.length) {
      const mod: SelectOptionI[] = [];

      data.map((element) => {
        mod.push({
          data: { ...element },
          selected: false,
        });
      });

      setRoleChoices(mod);
    }
    setLoading(rolesSelector.loading);
  }, [rolesSelector]);

  useEffect(() => {
    if (staffDetail?.user.groups.length && rolesSelector.system_roles.length) {
      const data = rolesSelector.system_roles;
      const mod: SelectOptionI[] = [];

      if (data.length) {
        data.map((element) => {
          mod.push({
            data: { ...element },
            selected: false,
          });
        });
      }
      const selected: string[] = [];
      if (user === "staff") {
        staffDetail.user.groups.map((element) => selected.push(element.id));
      }

      const currentOptions: SelectOptionI[] = mod;
      currentOptions.map((element) => {
        if (selected.includes(element.data.id)) {
          element.selected = true;
        } else {
          element.selected = false;
        }
      });
      setRoleChoices(currentOptions);

      const selectedVal = currentOptions.filter(
        (element) => element.selected === true
      );

      setSelectedRoleValues(selectedVal);
    }
  }, [staffDetail, rolesSelector]);

  const createChips = (roles: SystemRolesTypeI[]) => {
    return (
      <Grid container spacing={1}>
        {roles.map((element: SystemRolesTypeI, index: number) => (
          <Grid item key={index + 1}>
            <Chip
              style={{ backgroundColor: "#A1ABD6", color: "white" }}
              size="small"
              label={element.name}
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  const HandlePhoneNumber = () => {
    if (user === "staff") {
      setPhoneNumber((staffSelector as any)?.staff_profile?.phone);
    } else if (user === "student") {
      console.log(studentState);
      setPhoneNumber(studentState?.student?.guardian_detail?.phone_number);
    } else {
      setPhoneNumber(guardianSelector?.guardian_by_id?.phone_number);
    }
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteStaffDocument(itemId));
    setDeleteModalOpen(false);
  };

  const onRolesChange = (data: SelectOptionI[]) => {
    const selected: string[] = [];

    data.map((element) => selected.push(element.data.id));

    const currentOptions: SelectOptionI[] = roleChoices;
    currentOptions.map((element) => {
      if (selected.includes(element.data.id)) {
        element.selected = true;
      } else {
        element.selected = false;
      }
    });
    setRoleChoices(currentOptions);

    const selectedVal = currentOptions.filter(
      (element) => element.selected === true
    );

    setSelectedRoleValues(selectedVal);

    const ids: string[] = [];

    selectedVal.map((element) => {
      ids.push(element.data.id);
    });

    setSelectedRoles(ids);
  };

  const onRoleEdit = () => {
    setRoleEditMode(true);
  };

  const onRoleSave = () => {
    setRoleEditMode(false);
    if (userId) {
      dispatch(SetSystemRoles({ user_id: userId, groups: selectedRoles }));
      setUserRoles(
        rolesSelector.system_roles.filter((el) => selectedRoles.includes(el.id))
      );
    }
  };

  const onRegeneratePassword = () => {
    const new_password = genPassword();
    setPassword(new_password);
  };

  const handlePasswordChange = (e: React.ChangeEvent<any>): void => {
    setPassword(e.target.value);
  };

  const onChangePassword = () => {
    userId &&
      dispatch(
        SetUserPassword({
          user: userId,
          password1: password,
          password2: password,
        })
      );
    setIsChangePassword(false);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const onSend = () => {
    const data = {
      user: userId,
      number: phoneNumber,
      password: password,
    };
    dispatch(SendUserCredentials(data));
    setOpenModal(false);
  };

  const onUpdateUsername = () => {
    if (user == "guardian") {
      const id = guardianSelector.guardian_by_id.guardian_user.id;
      if (id) {
        dispatch(
          updateGuardianUsername({ user: id, updated_username: username })
        );
      }
    } else if (user == "staff") {
      const id = staffDetail?.user.id;
      if (id) {
        dispatch(updateStaffUsername({ user: id, updated_username: username }));
      }
    }
  };

  const onDiscardUsername = () => {
    if (user === "guardian") {
      const data = guardianSelector.guardian_by_id;
      setUserName(data ? data.guardian_user.username : "");
    } else if (user === "staff") {
      setUserName(staffDetail?.user?.username || "");
    }
    setEditUsername(false);
  };

  return (
    <>
      <Paper elevation={0} className={classes.roottable}>
        <Grid container>
          <Grid item xs={6}></Grid>
        </Grid>
        <TblContainer>
          <TableBody>
            <StyledTableRow>
              <StyledTableCell align="center" className={classes.cell}>
                <Typography variant="subtitle1">Username</Typography>
              </StyledTableCell>
              <StyledTableCell align="center" className={classes.cell}>
                {editUsername ? (
                  <TextField
                    variant="outlined"
                    name="username"
                    value={username}
                    onChange={(e) => {
                      setUserName(e.target.value);
                    }}
                    autoFocus
                  />
                ) : (
                  <Typography variant="subtitle2">{username}</Typography>
                )}
              </StyledTableCell>
              <StyledTableCell align="center" className={classes.cell}>
                {editUsername ? (
                  <Grid container>
                    <Grid item>
                      <ActionButton
                        title="Save Username"
                        onClick={onUpdateUsername}
                      >
                        <Save className={classes.editIcon} />
                      </ActionButton>
                    </Grid>
                    <Grid item>
                      <ActionButton
                        title="Discard"
                        onClick={(
                          event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                        ) => onDiscardUsername()}
                      >
                        <CachedIcon className={classes.editIcon} />
                      </ActionButton>
                    </Grid>
                  </Grid>
                ) : (
                  user !== "student" && (
                    <ActionButton
                      title="Change Username"
                      onClick={() => setEditUsername(true)}
                    >
                      <EditOutlined className={classes.editIcon} />
                    </ActionButton>
                  )
                )}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell align="center" className={classes.cell}>
                <Typography variant="subtitle1">Role</Typography>
              </StyledTableCell>
              {!roleEditMode ? (
                <StyledTableCell align="center" className={classes.cell}>
                  {createChips(userRoles)}
                </StyledTableCell>
              ) : (
                <StyledTableCell align="center" className={classes.cell}>
                  <Autocomplete
                    value={selectedRoleValues}
                    multiple
                    id="checkbox-select-roles"
                    options={roleChoices}
                    disableCloseOnSelect
                    onChange={(
                      event: React.ChangeEvent<{}>,
                      value: SelectOptionI[]
                    ) => onRolesChange(value)}
                    getOptionLabel={(option) => option.data.name}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={option.selected}
                        />
                        {option.data.name}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Roles"
                      />
                    )}
                  />
                </StyledTableCell>
              )}
              {user === "student" && (
                <StyledTableCell
                  align="center"
                  className={classes.cell}
                ></StyledTableCell>
              )}
              {user !== "student" && (
                <StyledTableCell align="center" className={classes.cell}>
                  {!roleEditMode ? (
                    user !== "student" && (
                      <ActionButton
                        title="Edit Roles"
                        onClick={(
                          event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                        ) => onRoleEdit()}
                      >
                        <EditOutlined className={classes.editIcon} />
                      </ActionButton>
                    )
                  ) : (
                    <ActionButton
                      title="Save Roles"
                      onClick={(
                        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                      ) => onRoleSave()}
                    >
                      <SaveOutlinedIcon className={classes.printIcon} />
                    </ActionButton>
                  )}
                </StyledTableCell>
              )}
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell align="center" className={classes.cell}>
                <Typography variant="subtitle1">Password</Typography>
              </StyledTableCell>
              <StyledTableCell align="center" className={classes.cell}>
                {isChangePassword ? (
                  <TextField
                    variant="outlined"
                    name="password"
                    placeholder="Enter new password"
                    value={password}
                    onChange={handlePasswordChange}
                    autoFocus
                  />
                ) : (
                  <Typography variant="subtitle2">
                    Change user password
                  </Typography>
                )}
              </StyledTableCell>
              <StyledTableCell align="center" className={classes.cell}>
                {isChangePassword ? (
                  <Grid container>
                    <Grid item>
                      <ActionButton
                        title="Save New Password"
                        onClick={onChangePassword}
                      >
                        <Save className={classes.editIcon} />
                      </ActionButton>
                    </Grid>
                    <Grid item>
                      <ActionButton
                        title="Generate Random Password"
                        onClick={(
                          event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                        ) => onRegeneratePassword()}
                      >
                        <CachedIcon className={classes.editIcon} />
                      </ActionButton>
                    </Grid>
                  </Grid>
                ) : (
                  <ActionButton
                    title="Change Password"
                    onClick={() => setIsChangePassword(true)}
                  >
                    <EditOutlined className={classes.editIcon} />
                  </ActionButton>
                )}
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
          <Button
            variant="contained"
            style={{ marginTop: "20px" }}
            onClick={() => {
              HandlePhoneNumber();
              setOpenModal(true);
            }}
          >
            Send User Credentials
          </Button>
        </TblContainer>
        <Popups
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          onClose={handleClose}
          title="Upload a file"
          colored
          scroll="body"
          maxWidth="sm"
        >
          <StaffUpload handleClose={handleClose} />
        </Popups>
        <CustomizedDialogs
          open={deleteModalOpen}
          onClose={handleModalClose}
          deleteConfirmed={onDeleteConfirmed}
          dialogTitle="Are you sure to delete this record?"
          okText="Delete"
        />
        {openModal && (
          <CustomizedPopUp
            title="Send User Credential"
            open={openModal}
            onClose={closeModal}
            actionConfirmed={onSend}
            dialogTitle="User Details"
            okText="Send"
          >
            <StaffPhoneNumberForm
              user={user}
              phone={phoneNumber}
              setPhoneNumber={setPhoneNumber}
            />
          </CustomizedPopUp>
        )}
      </Paper>
      <CustomBackdrop open={guardianSelector.loading} />
    </>
  );
}

export default StaffRoles;
