import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import {
  createClassSection,
  getClassSection,
  getClassSectionByGrade,
  updateClassSection,
} from "./classActions";

interface GradeType {
  value: string;
  label: string;
}

interface SectionType {
  id: string;
  name: string;
}

export interface ClassType {
  id: string;
  grade: string;
  grade_name: string;
  section: SectionType[];
}

interface initialStateType {
  loading: boolean;
  grades: GradeType[];
  classes: ClassType[];
  targetClass: {
    grade: string;
    sections: {
      id: string;
      isNew: boolean;
      name: string;
    }[];
  } | null;
  action: "create" | "update";
  todos: todoType[];
}

interface todoType {
  userId: number;
  id: number;
  title: string;
  completed: boolean;
}

const initialState: initialStateType = {
  grades: [
    {
      value: "ECD",
      label: "ECD",
    },
    {
      value: "play-group",
      label: "Playgroup",
    },
    {
      value: "nursery",
      label: "Nursery",
    },
    {
      value: "KG",
      label: "KG",
    },
    {
      value: "lkg",
      label: "LKG",
    },
    {
      value: "ukg",
      label: "UKG",
    },
    {
      value: "1",
      label: "One",
    },
    {
      value: "2",
      label: "Two",
    },
    {
      value: "3",
      label: "Three",
    },
    {
      value: "4",
      label: "Four",
    },
    {
      value: "5",
      label: "Five",
    },
    {
      value: "6",
      label: "Six",
    },
    {
      value: "7",
      label: "Seven",
    },
    {
      value: "8",
      label: "Eight",
    },
    {
      value: "9",
      label: "Nine",
    },
    {
      value: "10",
      label: "Ten",
    },
    {
      value: "11",
      label: "Eleven",
    },
    {
      value: "12",
      label: "Twelve",
    },
  ],
  classes: [],
  targetClass: null,
  todos: [],
  loading: false,
  action: "create",
};

export const fetchTodos = createAsyncThunk("todo/fetch", async () => {
  try {
    const res = await axios.get("https://jsonplaceholder.typicode.com/todos");
    return res.data;
  } catch (error) {
    return;
  }
});

const NewClassSlice = createSlice({
  name: "newClass",
  initialState,
  reducers: {
    clearTargetClass: (state) => {
      state.targetClass = null;
    },
    initiateSections: (state) => {
      if (state.targetClass) {
        state.targetClass.sections = [{ id: uuidv4(), name: "", isNew: true }];
      }
    },
    addNewSection: (state) => {
      state.targetClass?.sections.push({ id: uuidv4(), name: "", isNew: true });
    },
    removeSection: (state, { payload }: PayloadAction<string>) => {
      const targetClass = state.targetClass;

      if (targetClass && targetClass.sections.length > 1) {
        const mIndex = targetClass.sections.findIndex(
          (el) => el.id === payload
        );

        if (mIndex !== -1) {
          targetClass.sections.splice(mIndex, 1);
        }
      }
    },
    modifySection: (
      state,
      { payload }: PayloadAction<{ sectionId: string; value: string }>
    ) => {
      const targetClass = state.targetClass;

      if (targetClass) {
        const targetSection = targetClass.sections.find(
          (el) => el.id === payload.sectionId
        );
        if (targetSection) {
          targetSection.name = payload.value;
        }
      }
    },
    clearSection: (state) => {
      const targetClass = state.targetClass;

      if (targetClass) {
        targetClass.sections = [];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getClassSection.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getClassSection.fulfilled,
      (state, { payload }: PayloadAction<ClassType[]>) => {
        state.loading = false;
        state.classes = payload;
      }
    );
    builder.addCase(getClassSection.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getClassSectionByGrade.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getClassSectionByGrade.fulfilled,
      (state, { payload }: PayloadAction<ClassType>) => {
        state.loading = false;
        state.action = "update";
        state.targetClass = {
          grade: payload.grade_name,
          sections: payload.section.map((el) => ({
            id: el.id,
            name: el.name,
            isNew: false,
          })),
        };
      }
    );
    builder.addCase(
      getClassSectionByGrade.rejected,
      (state, { payload }: any) => {
        state.loading = false;

        if (payload.newData) {
          state.action = "create";
          state.targetClass = {
            grade: payload.grade,
            sections: [],
          };
        }
      }
    );

    builder.addCase(createClassSection.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createClassSection.fulfilled, (state) => {
      state.loading = false;
      state.action = "update";
    });
    builder.addCase(createClassSection.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateClassSection.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateClassSection.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateClassSection.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  clearTargetClass,
  initiateSections,
  addNewSection,
  removeSection,
  modifySection,
  clearSection,
} = NewClassSlice.actions;
export default NewClassSlice.reducer;
