import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_MARKSHEET_LOADING,
  GET_MARKSHEET_ERROR,
  GET_MARKSHEET_SUCCESS,
  MarkSheetDispatchTypes,
  GET_MULTIPLE_MARKSHEET_LOADING,
  GET_MULTIPLE_MARKSHEET_ERROR,
  GET_MULTIPLE_MARKSHEET_SUCCESS,
  MultipleMarksheetRequestParamsI,
} from "./MarkSheetActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import {
  HeaderConfig,
  HeaderConfigForDelete,
} from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";
import { Console } from "console";

export const GetMarkSheet =
  (student: string, exam_info: string) =>
  async (dispatch: Dispatch<MarkSheetDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_MARKSHEET_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/student/mark-sheet/?student=${student}&exam_info=${exam_info}`,
        HeaderConfig()
      );

      dispatch({
        type: GET_MARKSHEET_SUCCESS,
        payload: res.data.student_marks,
      });
    } catch (error) {
      dispatch({
        type: GET_MARKSHEET_ERROR,
      });
    }
  };

export const GetMultipleMarkSheet =
  (data: MultipleMarksheetRequestParamsI) =>
  async (dispatch: Dispatch<MarkSheetDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_MULTIPLE_MARKSHEET_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/student/multiple_marksheet/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: GET_MULTIPLE_MARKSHEET_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_MULTIPLE_MARKSHEET_ERROR,
      });
    }
  };
