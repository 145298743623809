// ---------------- <START> module import start ------------------//
import React from 'react';
import { Typography, CardContent, Card, Grid } from '@material-ui/core';
import {
  ItemDeleteButton,
  ItemEditButton,
} from '../../Reusable/Buttons/TableButton';
import { SmsTemplateTypeI } from '../../../actions/Communication/SmsTemplate/SmsTemplateActionTypes';
// ----------------<END> module import end ------------------//

// -----------------<start> Interfaces <start>-----------------------//
interface PropsI {
  item: SmsTemplateTypeI;
  handleEdit: (record: SmsTemplateTypeI) => void;
  handleDelete: (id: string) => void;
}
// -----------------<end> Interfaces <end>-----------------------//

// ------------------- <START> Template Component Starts -------------------//
const Template = (props: PropsI) => {
  const { item, handleEdit, handleDelete } = props;
  return (
    <Card variant="outlined">
      <CardContent style={{ paddingBottom: '16px' }}>
        <Typography variant="h4" gutterBottom>
          <strong>{item.title}</strong>
        </Typography>

        <Typography variant="body2" component="p" gutterBottom>
          {item.text}
        </Typography>

        <Grid container justifyContent="flex-end">
          <Grid item>
            <ItemEditButton
              onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleEdit(item);
              }}
            />
          </Grid>

          <Grid item>
            <ItemDeleteButton
              onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleDelete(item.id);
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
// ------------------- <EMD> Template Component Starts -------------------//

export default Template;
