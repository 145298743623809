import {
  GET_DISCOUNT_LOADING,
  GET_DISCOUNT_ERROR,
  GET_DISCOUNT_SUCCESS,
  DiscountDispatchTypes,
  ADD_DISCOUNT_LOADING,
  ADD_DISCOUNT_ERROR,
  ADD_DISCOUNT_SUCCESS,
  DELETE_DISCOUNT_LOADING,
  DELETE_DISCOUNT_ERROR,
  DELETE_DISCOUNT_SUCCESS,
  UPDATE_DISCOUNT_LOADING,
  UPDATE_DISCOUNT_ERROR,
  UPDATE_DISCOUNT_SUCCESS,
  DiscountTypeI,
} from "../../actions/Fees/DiscountType/DiscountActionTypes";

interface InitialStateI {
  loading: boolean;
  discount: DiscountTypeI[];
  errors: any;
  add_or_update: boolean;
}

const initialState: InitialStateI = {
  loading: false,
  discount: [],
  errors: null,
  add_or_update: false,
};

const DiscountReducer = (
  state: InitialStateI = initialState,
  action: DiscountDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_DISCOUNT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_DISCOUNT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_DISCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        discount: action.payload,
        errors: null,
      };

    case ADD_DISCOUNT_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case ADD_DISCOUNT_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        add_or_update: true,
      };

    case ADD_DISCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        discount: [...state.discount, action.payload],
        errors: null,
        add_or_update: true,
      };

    case UPDATE_DISCOUNT_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case UPDATE_DISCOUNT_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        add_or_update: true,
      };

    case UPDATE_DISCOUNT_SUCCESS:
      const current_data: DiscountTypeI[] = state.discount;
      const index: number = current_data.findIndex(
        (element) => element.id === action.payload.id
      );
      current_data[index] = action.payload;

      return {
        ...state,
        loading: false,
        discount: current_data,
        errors: null,
        add_or_update: true,
      };

    case DELETE_DISCOUNT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_DISCOUNT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_DISCOUNT_SUCCESS:
      const new_data: DiscountTypeI[] = state.discount;
      const item_index = new_data.findIndex(
        (element) => element.id === action.payload.id
      );
      new_data.splice(item_index, 1);

      return {
        ...state,
        loading: false,
        discount: new_data,
        errors: null,
      };

    default:
      return state;
  }
};

export default DiscountReducer;
