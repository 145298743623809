import axios from "axios";
import { Dispatch } from "redux";

import {
    GET_FEE_STATEMENT_ERROR,
    GET_FEE_STATEMENT_LOADING,
    GET_FEE_STATEMENT_SUCCESS,
    GET_INVOICE_DETAIL_ERROR,
    GET_INVOICE_DETAIL_LOADING,
    GET_INVOICE_DETAIL_SUCCESS,
    FeestatementDispatchTypes,
} from "./FeestatementActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

export const GetFeestatement =
    (id: number, grade: string, section: string, start_date: string, end_date: string) =>
        async (dispatch: Dispatch<FeestatementDispatchTypes | SetSnackBarI>) => {
            try {
                dispatch({
                    type: GET_FEE_STATEMENT_LOADING,
                });

                const res = await axios.get(
                    `${ADMIN_API_URL}/report/fee-statement/?page=${id}&grade=${grade}&section=${section}&from_date=${start_date}&to_date=${end_date}`,
                    HeaderConfig()
                );

                dispatch({
                    type: GET_FEE_STATEMENT_SUCCESS,
                    payload: res.data,
                });
            } catch (error) {
                dispatch({
                    type: GET_FEE_STATEMENT_ERROR,
                });
            }
        };

export const GetInvoiceDetail =
    (id: string) =>
        async (dispatch: Dispatch<FeestatementDispatchTypes | SetSnackBarI>) => {
            try {
                dispatch({
                    type: GET_INVOICE_DETAIL_LOADING,
                });

                const res = await axios.get(
                    `${ADMIN_API_URL}/report/invoice-detail/?receipt=${id}`,
                    HeaderConfig()
                );

                dispatch({
                    type: GET_INVOICE_DETAIL_SUCCESS,
                    payload: res.data,
                });
            } catch (error) {
                dispatch({
                    type: GET_INVOICE_DETAIL_ERROR,
                });
            }
        };