import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom";
import { RootStore } from '../../store';
import { Grid, Typography, Button } from "@material-ui/core";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";
import { AcademicSidebar } from "../../components";
import RubricCategoryForm from "../../components/Academic/RubricCategory/RubricCategoryForm"
import RubricCategoryTable from "../../components/Academic/RubricCategory/RubricCategoryTable"
import { RubricCategoryTypeI } from "../../actions/Academics/RubricCategory/RubricCategoryActionTypes"
import { getRubricSettings } from '../../rtk/features/miscellaneous/rubricSettingsApi';

const RubricCategoryPage = () => {
    const dispatch = useDispatch()
    const [formData, setFormData] = useState<RubricCategoryTypeI | null>(null)
    const [showRubric, setShowRubric] = useState<boolean>(false);
    const [count, setCount] = useState<number | undefined>(0);
    const [edit, setEdit] = useState<any>();
    const { rubricSettings } = useSelector((state: RootStore) => state.rubricSettings)

    useEffect(() => {
        dispatch(getRubricSettings())
    }, [])
    useEffect(() => {
        rubricSettings.add_by !== null && rubricSettings.add_on !== null && setShowRubric(true)
    }, [rubricSettings])

    const fetchEditData = (data: RubricCategoryTypeI) => {
        setFormData({ ...data, count });
        count && setCount(count + 1);
    };

    const onEditMode = (value: boolean) => {
        setEdit({ value, count });
    };

    const nav = <AcademicSidebar rubricCategory />;
    return (
        <SubModuleLayout academicMenuActive sideNav={nav}>
            <Grid container>
                {showRubric ? (
                    <>
                        <Grid item xs={3}>
                            <RubricCategoryForm editData={formData} onEditMode={onEditMode} />
                        </Grid>
                        <Grid item xs={9}>
                            <RubricCategoryTable onEditData={fetchEditData} edit={edit} />
                        </Grid> </>
                ) : (
                    <Grid item style={{ margin: "150px auto 0" }}>
                        <Typography>Rubric isn't enabled. Please enable rubric to add rubric category.</Typography>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            component={Link}
                            to="/miscellaneous"
                            style={{ width: "auto", marginTop: "16px" }}
                        >
                            Enable Rubric
                        </Button>
                    </Grid>
                )}

            </Grid>
        </SubModuleLayout>
    )
}

export default RubricCategoryPage