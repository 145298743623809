import { Paper, Tab, Tabs } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useCardStyles } from "../../Styles/CardStyles";
import { TabPanel } from "../../Student/StudentProfile/File/NewTabs";
import styled from "styled-components";
import AddDeviceModal from "./AddDeviceTab";
import DevicesListTab from "./DevicesListTab";

const Wrapper = styled.div`
  width: 600px;
  padding: 8px 24px;

  & .MuiTabs-flexContainer {
    border-bottom: 1px solid #ddd;
  }
`;

function AttendanceDevicesModal({ id }: { id: string | null }) {
  const classes = useCardStyles();
  const [value, setValue] = React.useState(0);
  const { staffID } = useParams<{ staffID: string }>();
  const [isTeacher, setIsTeacher] = useState<boolean>(false);

  const dispatch = useDispatch();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Wrapper>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        // variant="fullWidth"
      >
        <Tab label="Devices List" />
        <Tab label="Add New Device" />
      </Tabs>

      <TabPanel value={value} index={0}>
        <DevicesListTab id={id} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AddDeviceModal id={id} />
      </TabPanel>
    </Wrapper>
  );
}

export default AttendanceDevicesModal;
