import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_MEETING_TYPE_LOADING,
  GET_MEETING_TYPE_ERROR,
  GET_MEETING_TYPE_SUCCESS,
  MeetingTypeDispatchTypes,
  ADD_MEETING_TYPE_LOADING,
  ADD_MEETING_TYPE_SUCCESS,
  ADD_MEETING_TYPE_ERROR,
  DELETE_MEETING_TYPE_SUCCESS,
  DELETE_MEETING_TYPE_ERROR,
  DELETE_MEETING_TYPE_LOADING,
  UPDATE_MEETING_TYPE_LOADING,
  UPDATE_MEETING_TYPE_SUCCESS,
  UPDATE_MEETING_TYPE_ERROR,
} from "./MeetingTypeActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

interface RequestDataI {
  id: string;
  name: string;
}

export const GetMeetingTypes =
  () => async (dispatch: Dispatch<MeetingTypeDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_MEETING_TYPE_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/meeting_type/`,
        HeaderConfig()
      );

      dispatch({
        type: GET_MEETING_TYPE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_MEETING_TYPE_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "MeetingTypes Loading Failed",
      //   },
      // });
    }
  };
export const AddMeetingType =
  (data: RequestDataI) =>
  async (dispatch: Dispatch<MeetingTypeDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_MEETING_TYPE_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/meeting_type/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_MEETING_TYPE_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Meeting Type Added Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: ADD_MEETING_TYPE_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Meeting Type Add Failed",
        },
      });
    }
  };

export const UpdateMeetingType =
  (id: string, data: RequestDataI) =>
  async (dispatch: Dispatch<MeetingTypeDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_MEETING_TYPE_LOADING,
      });

      const res = await axios.put(
        `${ADMIN_API_URL}/meeting_type/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_MEETING_TYPE_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Meeting Type Updated Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: UPDATE_MEETING_TYPE_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Meeting Type Update Failed",
        },
      });
    }
  };

export const DeleteMeetingType =
  (id: string) =>
  async (dispatch: Dispatch<MeetingTypeDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_MEETING_TYPE_LOADING,
      });

      const res = await axios.delete(
        `${ADMIN_API_URL}/meeting_type/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_MEETING_TYPE_SUCCESS,
        payload: { id: id },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Meeting Type Deleted Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_MEETING_TYPE_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Meeting Type Delete Failed",
        },
      });
    }
  };
