import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_EVENT_TYPE_LOADING,
  GET_EVENT_TYPE_ERROR,
  GET_EVENT_TYPE_SUCCESS,
  EventTypeDispatchTypes,
  ADD_EVENT_TYPE_LOADING,
  ADD_EVENT_TYPE_SUCCESS,
  ADD_EVENT_TYPE_ERROR,
  DELETE_EVENT_TYPE_SUCCESS,
  DELETE_EVENT_TYPE_ERROR,
  DELETE_EVENT_TYPE_LOADING,
  UPDATE_EVENT_TYPE_LOADING,
  UPDATE_EVENT_TYPE_SUCCESS,
  UPDATE_EVENT_TYPE_ERROR,
} from "./EventTypeActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

interface RequestDataI {
  id: string;
  name: string;
}

export const GetEventTypes =
  () => async (dispatch: Dispatch<EventTypeDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_EVENT_TYPE_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/event_type/`,
        HeaderConfig()
      );

      dispatch({
        type: GET_EVENT_TYPE_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_EVENT_TYPE_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "EventTypes Loading Failed",
      //   },
      // });
    }
  };
export const AddEventType =
  (data: RequestDataI) =>
  async (dispatch: Dispatch<EventTypeDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_EVENT_TYPE_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/event_type/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_EVENT_TYPE_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "EventType Added Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: ADD_EVENT_TYPE_ERROR,
        payload: error.response && error.response?.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "EventType Add Failed",
        },
      });
    }
  };

export const UpdateEventType =
  (id: string, data: RequestDataI) =>
  async (dispatch: Dispatch<EventTypeDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_EVENT_TYPE_LOADING,
      });

      const res = await axios.put(
        `${ADMIN_API_URL}/event_type/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_EVENT_TYPE_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "EventType Updated Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: UPDATE_EVENT_TYPE_ERROR,
        payload: error.response && error.response?.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "EventType Update Failed",
        },
      });
    }
  };

export const DeleteEventType =
  (id: string) =>
  async (dispatch: Dispatch<EventTypeDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_EVENT_TYPE_LOADING,
      });

      const res = await axios.delete(
        `${ADMIN_API_URL}/event_type/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_EVENT_TYPE_SUCCESS,
        payload: { id: id },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "EventType Deleted Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_EVENT_TYPE_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "EventType Delete Failed",
        },
      });
    }
  };
