// ---------------------------- <START> module imports start ----------------------------------//
import React, { useState, useEffect } from "react";
import { Grid, List, ListItem, ListItemText, Paper, Tooltip, Typography } from "@material-ui/core";
import { useTableStyles } from "../../Styles/TableStyles";
import { useTable, useTableWithPagination } from "../../Reusable";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import { DiscountTypeI } from "../../../actions/Fees/DiscountType/DiscountActionTypes";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import {
  DeleteDiscount,
  GetDiscount,
} from "../../../actions/Fees/DiscountType/DiscountAction";
import {
  ItemDeleteButton,
  ItemEditButton,
} from "../../Reusable/Buttons/TableButton";
import { ScholarshipType } from "../../../actions/ScholarShipType/ScholarshipActionTypes";
import {
  DeleteScholarshipType,
  GetScholarshipType,
} from "../../../actions/ScholarShipType/ScholarshipAction";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//
interface PropsI {
  onEditData: (value: ScholarshipType) => void;
  editCheck: { check: boolean; count: number };
}
interface HeadCellsI {
  id: string;
  label: string;
}
// ---------------------------- <END> interface ends ----------------------------------//

// ---------------------------- <START> styling starts ----------------------------------//

// ---------------------------- <END> styling ends ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N." },
  { id: "title", label: "Scholarship Head" },
  { id: "fee_type", label: "Fee Head" },
  { id: "scholarship_in", label: "Scholarship In" },
  { id: "scholarship_applicable", label: "Amount" },
  { id: "action", label: "Action" },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

// ---------------------------- <START> Discount Type Table Component Starts ---------------------------//
const ScholarshipTypeTable = (props: PropsI) => {
  const newclasses = useStyles();
  const classes = useTableStyles();
  const { onEditData, editCheck } = props;

  const dispatch = useDispatch();

  const scholarshipState = useSelector(
    (state: RootStore) => state.scholarship.scholarship_type
  );

  // States declaration
  const [tableData, setTableData] = useState<ScholarshipType[]>([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  // Modal States
  const [loading, setLoading] = useState<boolean>(false);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    editCheck && setEditMode(editCheck.check);
  }, [editCheck]);

  useEffect(() => {
    dispatch(GetScholarshipType());
  }, []);

  useEffect(() => {
    if (scholarshipState) {
      setLoading(scholarshipState.loading);
      const data = scholarshipState;
      setTableData(data);
    }
  }, [scholarshipState]);

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  // console.log("object", tableData);

  // -----------------<START> Pagination states and functions <START>-----------------------//

  // -----------------<END> Pagination states and functions <END>-----------------------//

  // Retrieving re-usable components from useTable
  const { TableContainer, StyledTableCell, StyledTableRow } =
    useTable(headCells);

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string>("");

  const handleEditClicked = (data: ScholarshipType) => {
    onEditData(data);
    setEditMode(true);
  };

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const handleDeleteModal = (value: boolean, id: string) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteScholarshipType(itemId));
    setDeleteModalOpen(false);
  };

  const ToolTipSubjectViewComponent = (props: any) => {
    const { items } = props;

    return (
      <Grid direction="column" container>
        <Grid>
          <div>
            <List dense>
              {items.map((item: any, index: number) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle2">{item.name}</Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
      </Grid>
    );
  };
  const getCustomTableRow = (item: any, index: number) => {
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell align="center" className={classes.cell}>
          {index}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.scholarship_title}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.fee_type_detail?.length > 0 ?
            <Tooltip
              arrow
              title={
                item.fee_type_detail?.length ? (
                  <ToolTipSubjectViewComponent items={item.fee_type_detail} />
                ) : (
                  ''
                )
              }
            >
              <div>
                {`${item.fee_type_detail[0] ? item.fee_type_detail[0]?.name : 'N/A'} ${item.fee_type_detail?.length ? `+${item.fee_type_detail?.length - 1} more` : ''
                  }`}
              </div>
            </Tooltip>
            : 'None'}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.scholarship_in === "P" ? "Percent" : "Amount"}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          {item.applicable_scholarship}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.cell}>
          <ItemEditButton
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleEditClicked(item)
            }
          />
          <ItemDeleteButton
            disabled={editMode}
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleDeleteModal(true, item.id)
            }
          />
        </StyledTableCell>
      </StyledTableRow>
    );
  };
  return (
    <Paper className={classes.root}>
      <Typography variant="h6">Scholarship Type List</Typography>
      <TableContainer
        loading={loading}
        items={tableData}
        headCells={headCells}
        getCustomTableRow={getCustomTableRow}
        // forPagination={scholarshipState}
        search_name="scholarship_title"
      />

      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
        dialogTitle="Are you sure to delete this record?"
        okText="Delete"
      />
    </Paper>
  );
};
// ---------------------------- <END> Discount Type Component Ends -------------------------------//

export default ScholarshipTypeTable;
