import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AttendanceEmployeeDetailPayloadI,
  AttendanceReportI,
  AttendanceReportPayloadI,
  HrStaffDetailI,
  HrStaffListI,
  InitialState,
} from "./humanResource";
import {
  createStaff,
  getStaffDetails,
  getStaffs,
  getStaffLeaveInfo,
  updateStaffAcademy,
  updateStaffFaculty,
  updateStaffOtherInfo,
  updateStaffPersonal,
  getAttendanceToday,
  returnNepaliDateString,
  getStaffAttendanceReport,
  getAttendanceReport,
} from "./humanResourceThunk";

const initialState: InitialState = {
  loading: false,
  actionCompleted: false,
  staffDetail: null,
  staffLeaveInfo: [],
  hrStaffs: {
    current_page: 1,
    total: 0,
    per_page: 10,
    total_pages: 0,
    results: [],
  },
  attendanceReport: [],
  staffAttendanceReport: null,
};

const serializeAttendanceReport = (data: AttendanceReportPayloadI[]) => {
  const result: AttendanceReportI[] = [];
  data.forEach((el) => {
    const existingEmployee = result.find(
      (res) => res.employeeId === el.employee_detail.id
    );
    if (existingEmployee) {
      const existingDay = existingEmployee.attendance.find(
        (eE) =>
          eE.date ===
          returnNepaliDateString(
            el.nepali_punch_time.year,
            el.nepali_punch_time.month,
            el.nepali_punch_time.day
          )
      );

      if (existingDay) {
        if (el.punch_state === "check_in") {
          existingDay.checkIn = el.nepali_punch_time.time;
        }

        if (el.punch_state === "check_out") {
          existingDay.checkout = el.nepali_punch_time.time;
        }
      } else {
        existingEmployee.attendance.push({
          date: returnNepaliDateString(
            el.nepali_punch_time.year,
            el.nepali_punch_time.month,
            el.nepali_punch_time.day
          ),
          checkIn:
            el.punch_state === "check_in" ? el.nepali_punch_time.time : "00:00",
          checkout:
            el.punch_state === "check_out"
              ? el.nepali_punch_time.time
              : "00:00",
          leave: el.on_leave.find(
            (lv) =>
              lv.year === el.nepali_punch_time.year &&
              lv.month === el.nepali_punch_time.month &&
              lv.day === el.nepali_punch_time.day
          )
            ? true
            : false,
        });
      }
    } else {
      result.push({
        employeeId: el.employee_detail.id,
        name:
          el.employee_detail.user.first_name +
          " " +
          el.employee_detail.user.last_name,
        department:
          el.employee_detail.staff_faculty_info?.department.name || "-",
        designation:
          el.employee_detail.staff_faculty_info?.designation.name || "-",
        attendance: [
          {
            date: returnNepaliDateString(
              el.nepali_punch_time.year,
              el.nepali_punch_time.month,
              el.nepali_punch_time.day
            ),
            checkIn:
              el.punch_state === "check_in"
                ? el.nepali_punch_time.time
                : "00:00",
            checkout:
              el.punch_state === "check_out"
                ? el.nepali_punch_time.time
                : "00:00",
            leave: el.on_leave.find(
              (lv) =>
                lv.year === el.nepali_punch_time.year &&
                lv.month === el.nepali_punch_time.month &&
                lv.day === el.nepali_punch_time.day
            )
              ? true
              : false,
          },
        ].concat(
          el.on_leave.map((lv) => ({
            date: returnNepaliDateString(lv.year, lv.month, lv.day),
            checkIn: "00:00",
            checkout: "00:00",
            leave: true,
          }))
        ),
      });
    }
  });
  return result;
};

const humanResourceSlice = createSlice({
  name: "humanResource",
  initialState,
  reducers: {
    resetStaffLeaveInfo: (state) => {
      state.staffLeaveInfo = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createStaff.pending, (state) => {
      state.loading = true;
      state.actionCompleted = false;
    });
    builder.addCase(createStaff.fulfilled, (state) => {
      state.loading = false;
      state.actionCompleted = true;
    });
    builder.addCase(createStaff.rejected, (state) => {
      state.loading = false;
    });
    // /////////////////////////////////////////////////////

    builder.addCase(getStaffDetails.pending, (state) => {
      state.loading = true;
      state.actionCompleted = false;
    });
    builder.addCase(
      getStaffDetails.fulfilled,
      (state, { payload }: PayloadAction<HrStaffDetailI>) => {
        state.loading = false;
        state.staffDetail = payload;
      }
    );
    builder.addCase(getStaffDetails.rejected, (state) => {
      state.loading = false;
      state.staffDetail = null;
    });
    // /////////////////////////////////////////////////////
    builder.addCase(getStaffs.pending, (state) => {
      state.loading = true;
      state.actionCompleted = false;
    });
    builder.addCase(
      getStaffs.fulfilled,
      (
        state,
        {
          payload,
        }: PayloadAction<{
          current_page: number;
          total: number;
          per_page: number;
          total_pages: number;
          results: HrStaffListI[];
        }>
      ) => {
        state.loading = false;
        state.hrStaffs = { ...payload };
      }
    );
    builder.addCase(getStaffs.rejected, (state) => {
      state.loading = false;
    });
    // /////////////////////////////////////////////////////
    builder.addCase(getStaffLeaveInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getStaffLeaveInfo.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.staffLeaveInfo = payload;
    });
    builder.addCase(getStaffLeaveInfo.rejected, (state) => {
      state.loading = false;
    });
    // /////////////////////////////////////////////////////
    builder.addCase(updateStaffAcademy.pending, (state) => {
      state.loading = false;
      state.actionCompleted = false;
    });
    builder.addCase(updateStaffAcademy.fulfilled, (state) => {
      state.loading = false;
      state.actionCompleted = true;
    });
    builder.addCase(updateStaffAcademy.rejected, (state) => {
      state.loading = false;
    });
    // /////////////////////////////////////////////////////
    builder.addCase(updateStaffPersonal.pending, (state) => {
      state.loading = false;
      state.actionCompleted = false;
    });
    builder.addCase(updateStaffPersonal.fulfilled, (state) => {
      state.loading = false;
      state.actionCompleted = true;
    });
    builder.addCase(updateStaffPersonal.rejected, (state) => {
      state.loading = false;
    });
    // /////////////////////////////////////////////////////
    builder.addCase(updateStaffFaculty.pending, (state) => {
      state.loading = false;
      state.actionCompleted = false;
    });
    builder.addCase(updateStaffFaculty.fulfilled, (state) => {
      state.loading = false;
      state.actionCompleted = true;
    });
    builder.addCase(updateStaffFaculty.rejected, (state) => {
      state.loading = false;
    });
    // /////////////////////////////////////////////////////
    builder.addCase(updateStaffOtherInfo.pending, (state) => {
      state.loading = false;
      state.actionCompleted = false;
    });
    builder.addCase(updateStaffOtherInfo.fulfilled, (state) => {
      state.loading = false;
      state.actionCompleted = true;
    });
    builder.addCase(updateStaffOtherInfo.rejected, (state) => {
      state.loading = false;
    });
    // /////////////////////////////////////////////////////
    builder.addCase(getAttendanceToday.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(
      getAttendanceToday.fulfilled,
      (state, { payload }: PayloadAction<AttendanceReportPayloadI[]>) => {
        state.attendanceReport = [...serializeAttendanceReport(payload)];
        state.loading = false;
      }
    );
    builder.addCase(getAttendanceToday.rejected, (state) => {
      state.loading = false;
    });
    // /////////////////////////////////////////////////////
    builder.addCase(getAttendanceReport.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(
      getAttendanceReport.fulfilled,
      (state, { payload }: PayloadAction<AttendanceReportPayloadI[]>) => {
        state.attendanceReport = [...serializeAttendanceReport(payload)];
        state.loading = false;
      }
    );
    builder.addCase(getAttendanceReport.rejected, (state) => {
      state.loading = false;
    });
    // /////////////////////////////////////////////////////
    builder.addCase(getStaffAttendanceReport.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(
      getStaffAttendanceReport.fulfilled,
      (state, { payload }: PayloadAction<AttendanceEmployeeDetailPayloadI>) => {
        const attendance: {
          date: string;
          checkIn: string | null;
          checkout: string | null;
          leave: boolean;
        }[] = [];

        payload.on_leave.on_leave.forEach((lv) => {
          attendance.push({
            date: returnNepaliDateString(lv.year, lv.month, lv.day),
            checkIn: "00:00",
            checkout: "00:00",
            leave: true,
          });
        });

        payload.attendance.forEach((el) => {
          const existingDay = attendance.find(
            (atd) =>
              atd.date ===
              returnNepaliDateString(
                el.nepali_punch_time.year,
                el.nepali_punch_time.month,
                el.nepali_punch_time.day
              )
          );

          if (existingDay) {
            if (el.punch_state === "check_in") {
              existingDay.checkIn = el.nepali_punch_time.time;
            }

            if (el.punch_state === "check_out") {
              existingDay.checkout = el.nepali_punch_time.time;
            }
          } else {
            attendance.push({
              date: returnNepaliDateString(
                el.nepali_punch_time.year,
                el.nepali_punch_time.month,
                el.nepali_punch_time.day
              ),
              checkIn:
                el.punch_state === "check_in"
                  ? el.nepali_punch_time.time
                  : "00:00",
              checkout:
                el.punch_state === "check_out"
                  ? el.nepali_punch_time.time
                  : "00:00",
              leave: payload.on_leave.on_leave.find(
                (lv) =>
                  lv.year === el.nepali_punch_time.year &&
                  lv.month === el.nepali_punch_time.month &&
                  lv.day === el.nepali_punch_time.day
              )
                ? true
                : false,
            });
          }
        });

        state.staffAttendanceReport = {
          employeeId: payload.employee_detail.id,
          name:
            payload.employee_detail.user.first_name +
            " " +
            payload.employee_detail.user.last_name,
          department:
            payload.employee_detail.staff_faculty_info?.department.name || "-",
          designation:
            payload.employee_detail.staff_faculty_info?.designation.name || "-",
          attendance: attendance,
        };
        state.loading = false;
      }
    );
    builder.addCase(getStaffAttendanceReport.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { resetStaffLeaveInfo } = humanResourceSlice.actions;
export default humanResourceSlice.reducer;
