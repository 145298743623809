// ---------------------------- <START> module imports start ----------------------------------//
import React, { useState, useEffect } from "react";
import {
  TableBody,
  TextField,
  Grid,
  Typography,
  Paper,
  LinearProgress,
} from "@material-ui/core";
import {
  VisibilityOutlined,
  EditOutlined,
  DeleteForeverOutlined,
  PrintOutlined,
} from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import { Link } from "react-router-dom";
import { useTableStyles } from "../../Styles/TableStyles";
import { useTable } from "../../Reusable";
import TableActionButtons from "../../Reusable/Buttons/TableActionButtons";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import ActionButton from "../../Reusable/Buttons/ActionButton";
import FeeTypeReportForm from "./FeeTypeReportForm";
import Popups from "../../Reusable/Dialogs/Popups";
import { FeeTypeReportI } from "../../../actions/Fees/FeeLedger/FeeLedgerActionTypes";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import { GetFeeTypeReport } from "../../../actions/Fees/FeeLedger/FeeLedgerAction";
// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//
interface HeadCellsI {
  id: string;
  label: string;
}
// ---------------------------- <END> interface ends ----------------------------------//

// ---------------------------- <START> styling starts ----------------------------------//

// ---------------------------- <END> styling ends ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "fee_type", label: "Fee Type" },
  { id: "student", label: "No of Students" },
  { id: "amount", label: "Total Amount" },
  { id: "due", label: "Due Amount" },
];
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> Student Table Component Starts ---------------------------//
const FeeTypeReportList: React.FC = () => {
  const classes = useTableStyles();

  // States declaration
  const [tableData, setTableData] = useState<FeeTypeReportI[]>([]);
  const [tempTableData, setTempTableData] = useState<FeeTypeReportI[]>([]);

  const [totalRecord, setTotalRecord] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);

  const [recordFrom, setRecordFrom] = useState(1);
  const [recordTo, setRecordTo] = useState(7);

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
    setRecordFrom(newPage === 1 ? newPage : (newPage - 1) * rowsPerPage + 1);
    setRecordTo(newPage === 1 ? newPage * rowsPerPage : newPage * rowsPerPage);
  };

  // Retrieving re-usable components from useTable
  const {
    TblContainer,
    TblHead,
    StyledTableCell,
    StyledTableRow,
    exportToExcel,
    tablePrint,
  } = useTable(headCells);

  // Event function for table searching by name
  const handleTableSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const search_data = tempTableData.filter((data) =>
      data.fee_type.toUpperCase().includes(e.target.value.toUpperCase())
    );
    setTotalRecord(search_data.length);
    setPageCount(Math.ceil(search_data.length / rowsPerPage));
    setPage(1);
    setRecordFrom(1);
    setRecordTo(rowsPerPage);
    setTableData(search_data);
  };

  // Function for handling action icons click event
  const handleActionClicked = () => {
    alert("Action Clicked!");
  };
  // Modal States
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<number | null>(null);

  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch();
  const feeReportSelector = useSelector((state: RootStore) => state.fee_ledger);

  useEffect(() => {
    dispatch(GetFeeTypeReport());
  }, []);

  useEffect(() => {
    const data = feeReportSelector.feeType_report;
    setTableData(data);
    setTempTableData(data);

    setTotalRecord(data.length);
    setPageCount(data.length / rowsPerPage);

    setLoading(feeReportSelector.loading);
  }, [feeReportSelector]);

  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    setDeleteModalOpen(false);
  };
  const [openPopup, setOpenPopup] = useState(false);
  const handleClose = (value: boolean) => {
    setOpenPopup(value);
  };

  return (
    <>
      <FeeTypeReportForm />
      <Paper className={classes.rootTableBox}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">Fee Type Report List</Typography>
          </Grid>
        </Grid>
        <div className={classes.tableWrapper}>
          <div className={classes.tableTop}>
            <TextField
              variant="outlined"
              name="search"
              placeholder="Search by Name ..."
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleTableSearch(e)
              }
            />

            <TableActionButtons
              exportToExcel={exportToExcel}
              tablePrint={tablePrint}
            />
          </div>
          <TblContainer>
            <TblHead />
            <TableBody>
              {tableData
                .slice(
                  (page - 1) * rowsPerPage,
                  (page - 1) * rowsPerPage + rowsPerPage
                )
                .map((item: FeeTypeReportI) => (
                  <StyledTableRow key={item.fee_type_id}>
                    <StyledTableCell align="center" className={classes.cell}>
                      {item.fee_type}
                    </StyledTableCell>
                    <StyledTableCell align="center" className={classes.cell}>
                      {item.student}
                    </StyledTableCell>
                    <StyledTableCell align="center" className={classes.cell}>
                      Rs. {item.amount}
                    </StyledTableCell>
                    <StyledTableCell align="center" className={classes.cell}>
                      Rs. {item.due_amount}
                    </StyledTableCell>
                    {/* <StyledTableCell align="center" className={classes.cell}>
                      <ActionButton
                        title="View"
                        onClick={() => setOpenPopup(true)}
                      >
                        <VisibilityOutlined className={classes.viewIcon} />
                      </ActionButton>
                    </StyledTableCell> */}
                  </StyledTableRow>
                ))}
            </TableBody>
          </TblContainer>
          {loading ? <LinearProgress /> : null}
          <div className={classes.tableBottom}>
            {totalRecord > 0 ? (
              <Typography>
                Showing {recordFrom} to{" "}
                {recordTo > totalRecord ? totalRecord : recordTo} of{" "}
                {totalRecord}
              </Typography>
            ) : (
              <Typography>No records found</Typography>
            )}
            <div className={classes.pageNavigation}>
              <Pagination
                count={pageCount}
                page={page}
                onChange={handlePageChange}
                shape="rounded"
                color="primary"
              />
            </div>
          </div>
        </div>
      </Paper>

      <CustomizedDialogs
        open={deleteModalOpen}
        onClose={handleModalClose}
        deleteConfirmed={onDeleteConfirmed}
        dialogTitle="Are you sure to delete this record?"
        okText="Delete"
      />

      <Popups
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        title="Subject Marks of Amar Karki"
        colored
      >
        <h1>nothing nothing</h1>
      </Popups>
    </>
  );
};
// ---------------------------- <END> Student Table Component Ends -------------------------------//

export default FeeTypeReportList;
