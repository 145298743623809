// -----------------<START> import modules starts----------------------------------------------//
import React, { useEffect } from "react";
import { makeStyles, Tabs, Tab, Typography, Box } from "@material-ui/core";
import Sunday from "./Sunday";
//------------------------<END> import module ends---------------------------------------------------------------//

//-----------------<START> style starts--------------------------------------------------------//
const useStyles = makeStyles({
  root: {
    paddingRight: "10px",
    "& .MuiTab-root": {
      padding: 0,
      // minWidth: "100px !important",
    },
    "& .MuiTabs-flexContainer": {
      borderBottom: "1px solid #ddd",
    },
    "& .MuiBox-root": {
      padding: "10px 0px 0px 0px",
    },
  },
});

//----------------------<END> styled ends ---------------------------------------------------//
// ----------------------<STARTS> interface starts -------------------------------------------------------//
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface PropsI {
  classChoice: {
    gradeId: string | null;
    section: string | null;
    classId: string | null;
    hasSection: boolean;
  };
  onChooseDay: (value: number) => void;
}
//------------------------<ENDS> interface ends -------------------------------------------------------//

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
// -----------------<START> Time table tabs component starts---------------------//
export default function TimetableTabs(props: PropsI) {
  const { classChoice, onChooseDay } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    onChooseDay(value || 0);
  }, [value]);

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab label="Sunday" />
        <Tab label="Monday" />
        <Tab label="Tuesday" />
        <Tab label="Wednesday" />
        <Tab label="Thursday" />
        <Tab label="Friday" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Sunday tab_day={7} classChoice={classChoice} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Sunday tab_day={1} classChoice={classChoice} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Sunday tab_day={2} classChoice={classChoice} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Sunday tab_day={3} classChoice={classChoice} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Sunday tab_day={4} classChoice={classChoice} />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Sunday tab_day={5} classChoice={classChoice} />
      </TabPanel>
    </div>
  );
}
//---------------<END> Time table tabs component ends ----------------------------//
