import { Grid, makeStyles } from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';
import { DiscountTypeI } from '../../../actions/Fees/DiscountType/DiscountActionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../../store';
import { useForm } from 'react-hook-form';
import {
  AddDiscount,
  GetDiscount,
  UpdateDiscount,
} from '../../../actions/Fees/DiscountType/DiscountAction';
import { GetCategory } from '../../../actions/Student/StudentCategory/CategoryAction';
import FormLayout from '../../Reusable/Layouts/Form.Layout';
import CustomizedTextField from '../../Reusable/Inputs/TextField';
import CustomizedSelect from '../../Reusable/Inputs/Select';
import { CusRadioGroup } from '../../Reusable/Inputs/Radio';
import { useFormStyles } from '../../Styles/FormStyles';

interface PropsI {
  editData: DiscountTypeI | null;
  onEditCheck: (value: boolean) => void;
}

interface SelectCategoryType {
  key: string;
  value: string;
}

interface FormInputI {
  name: string;
  discount_in: string;
  discount_applicable: number;
}

const DiscountTypeForm = (props: PropsI) => {
  const { editData, onEditCheck } = props;
  const classes = useFormStyles();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    errors,
    setError,
    clearErrors,
  } = useForm<FormInputI>({
    mode: 'onChange',
  });
  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [editID, setEditID] = useState<string>('');
  const [selectedRadioValue, setSelectedRadioValue] = useState<string>('');
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);

  const dispatch = useDispatch();
  const discountTypeLoading = useSelector(
    (state: RootStore) => state.discount_type.loading
  );

  const studentCategorySelector = useSelector(
    (state: RootStore) => state.categories
  );

  const add_or_update = useSelector(
    (state: RootStore) => state.discount_type.add_or_update
  );

  useEffect(() => {
    if (add_or_update) {
      setIsBtnLoading(false);
      handleReset();
    }
  }, [add_or_update]);

  useEffect(() => {
    dispatch(GetDiscount());
  }, []);

  useEffect(() => {
    setLoading(discountTypeLoading);
  }, [discountTypeLoading]);

  useEffect(() => {
    editData != null && handleEditTableData(editData);
  }, [editData]);

  const onFormSubmit = (data: FormInputI) => {
    setIsBtnLoading(true);
    const submitData = {
      ...data,
      name: data.name,
      discount_in: selectedRadioValue,
      discount_applicable: data.discount_applicable,
    };
    if (editMode && editID) {
      dispatch(UpdateDiscount(editID, submitData));
    } else {
      dispatch(AddDiscount(submitData));
    }
  };

  const handleEditTableData = (data: DiscountTypeI) => {
    setEditMode(true);
    setEditID(data.id);

    setValue('name', data.name);
    setValue('discount_applicable', data.discount_applicable);
    setSelectedRadioValue(data.discount_in);
  };

  const discountTypelList = [
    { id: 'P', name: 'Percent%' },
    { id: 'A', name: 'Amount' },
  ];

  const handleReset = () => {
    setEditMode(false);
    reset();
    setSelectedRadioValue('');
    setEditID('');
    onEditCheck(false);
  };

  //----------------------ERROR HANDLING---------------------------//
  const [serverErrors, setServerErrors] = useState<any>(null);
  const discountSelector = useSelector(
    (state: RootStore) => state.discount_type
  );
  const errorsData = discountSelector.errors;
  const initialErrorsData = useRef(errorsData);

  useEffect(() => {
    if (initialErrorsData.current === errorsData) {
      initialErrorsData.current = errorsData; // Do not set initial errors
    } else {
      if (errorsData?.error != null) {
        const keys = Object.keys(errorsData?.error);
        keys.map((elem: any) => {
          setError(elem, {
            type: 'serverSideError',
            message: errorsData.error[elem] && errorsData.error[elem][0],
          });
        });
      }
      setServerErrors(errorsData);
    }
  }, [errorsData]);

  useEffect(() => {
    selectedRadioValue && clearErrors('discount_in');
  }, [selectedRadioValue]);

  useEffect(() => {
    if (discountSelector.add_or_update) {
      handleReset();
    }
  }, [discountSelector.add_or_update]);

  return (
    <>
      <FormLayout
        title={editMode ? 'Edit Discount Type' : 'Add Discount Type'}
        onSubmit={handleSubmit(onFormSubmit)}
        editMode={editMode}
        loading={loading}
        onClick={!isBtnLoading && handleReset}
        add_or_update={isBtnLoading}
      >
        <Grid container>
          <CustomizedTextField
            label=" Discount type"
            placeholder=" Discount type"
            name="name"
            required
            error={errors['name'] ? 'Required Field.' : ''}
            inputRef={register({ required: true })}
          />
          <CusRadioGroup
            row
            label="Discount In"
            required
            items={discountTypelList}
            value={selectedRadioValue}
            setValue={setSelectedRadioValue}
            name="discount_in"
          />
          <span className={classes.validationErrorInfo}>
            {errors.discount_in?.type === 'serverSideError' &&
              errors.discount_in.message}
          </span>
          <CustomizedTextField
            label="Discount Applicable"
            placeholder="Discount Applicable"
            name="discount_applicable"
            required
            error={errors['discount_applicable'] ? 'Required Field.' : ''}
            inputRef={register({ required: true })}
          />
          <span className={classes.validationErrorInfo}>
            {errors.discount_applicable?.type === 'serverSideError' &&
              errors.discount_applicable.message}
          </span>
        </Grid>
      </FormLayout>
    </>
  );
};

export default DiscountTypeForm;
