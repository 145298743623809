import axios from "axios";
import { Dispatch } from "redux";

import {
  GET_SESSION_LOADING,
  GET_SESSION_ERROR,
  GET_SESSION_SUCCESS,
  SessionDispatchTypes,
  ADD_SESSION_LOADING,
  ADD_SESSION_SUCCESS,
  ADD_SESSION_ERROR,
  DELETE_SESSION_SUCCESS,
  DELETE_SESSION_ERROR,
  DELETE_SESSION_LOADING,
  UPDATE_SESSION_LOADING,
  UPDATE_SESSION_SUCCESS,
  UPDATE_SESSION_ERROR,
  UPDATE_SESSION_STATUS_LOADING,
  UPDATE_SESSION_STATUS_SUCCESS,
  UPDATE_SESSION_STATUS_ERROR,
} from "./SessionActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

interface RequestDataI {
  // session_name: string;
  from_date: string;
  to_date: string;
  name: string;
}

export const GetSessions =
  () => async (dispatch: Dispatch<SessionDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_SESSION_LOADING,
      });

      const res = await axios.get(`${ADMIN_API_URL}/session/`, HeaderConfig());

      dispatch({
        type: GET_SESSION_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_SESSION_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "Sessions Loading Failed",
      //   },
      // });
    }
  };

export const AddSession =
  (data: RequestDataI) =>
  async (dispatch: Dispatch<SessionDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_SESSION_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/session/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_SESSION_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Session Added Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: ADD_SESSION_ERROR,
      });

      const err_message =
        error?.response?.data?.error?.non_field_errors &&
        error.response.data.error.non_field_errors[0];

      if (err_message) {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: err_message,
          },
        });
      } else {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Session Add Failed",
          },
        });
      }
    }
  };

export const UpdateSession =
  (id: string, data: RequestDataI) =>
  async (dispatch: Dispatch<SessionDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_SESSION_LOADING,
      });

      const res = await axios.put(
        `${ADMIN_API_URL}/session/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_SESSION_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Session Updated Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: UPDATE_SESSION_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Session Update Failed",
        },
      });
    }
  };

export const UpdateSessionStatus =
  (id: string) =>
  async (dispatch: Dispatch<SessionDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_SESSION_STATUS_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/session/active_or_inactive_session/?session=${id}`,
        null,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_SESSION_STATUS_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Session Status Updated Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: UPDATE_SESSION_STATUS_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Session Status Update Failed",
        },
      });
    }
  };

export const DeleteSession =
  (id: string) =>
  async (dispatch: Dispatch<SessionDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_SESSION_LOADING,
      });

      const res = await axios.delete(
        `${ADMIN_API_URL}/session/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_SESSION_SUCCESS,
        payload: { id: id },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Session Deleted Successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_SESSION_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Session Delete Failed",
        },
      });
    }
  };
