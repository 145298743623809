import {
  GET_MARKSHEET_LOADING,
  GET_MARKSHEET_ERROR,
  GET_MARKSHEET_SUCCESS,
  GET_MULTIPLE_MARKSHEET_LOADING,
  GET_MULTIPLE_MARKSHEET_ERROR,
  GET_MULTIPLE_MARKSHEET_SUCCESS,
  MarkSheetDispatchTypes,
  MarkSheetTypeI,
} from "../../actions/Examination/MarkSheet/MarkSheetActionTypes";

interface InitialStateI {
  loading: boolean;
  marksheet: MarkSheetTypeI[];
  multiple_marksheet: any;
}

const initialState: InitialStateI = {
  loading: false,
  marksheet: [],
  multiple_marksheet: [],
};

const MarkSheetReducer = (
  state: InitialStateI = initialState,
  action: MarkSheetDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_MARKSHEET_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_MARKSHEET_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_MARKSHEET_SUCCESS:
      return {
        ...state,
        loading: false,
        marksheet: action.payload,
      };

    case GET_MULTIPLE_MARKSHEET_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_MULTIPLE_MARKSHEET_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_MULTIPLE_MARKSHEET_SUCCESS:
      return {
        ...state,
        loading: false,
        multiple_marksheet: action.payload,
      };

    default:
      return state;
  }
};

export default MarkSheetReducer;
