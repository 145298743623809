import {
  GET_TRANSPORT_LOADING,
  GET_TRANSPORT_ERROR,
  GET_TRANSPORT_SUCCESS,
  TransportDispatchTypes,
  ADD_TRANSPORT_LOADING,
  ADD_TRANSPORT_ERROR,
  ADD_TRANSPORT_SUCCESS,
  DELETE_TRANSPORT_LOADING,
  DELETE_TRANSPORT_ERROR,
  DELETE_TRANSPORT_SUCCESS,
  DELETE_TRANSPORT_STUDENT_LOADING,
  DELETE_TRANSPORT_STUDENT_ERROR,
  DELETE_TRANSPORT_STUDENT_SUCCESS,
  UPDATE_TRANSPORT_LOADING,
  UPDATE_TRANSPORT_ERROR,
  UPDATE_TRANSPORT_SUCCESS,
  TransportTypeI,
  TransportTypeII,
} from '../../actions/Transport/Routes/TransportActionTypes';

interface InitialStateI {
  loading: boolean;
  transport: any;
  error: any;
  recent?: boolean;
  add_or_update: boolean;
}

const initialState: InitialStateI = {
  loading: false,
  transport: null,
  error: null,
  recent: false,
  add_or_update: false,
};

const TransportReducer = (
  state: InitialStateI = initialState,
  action: TransportDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_TRANSPORT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_TRANSPORT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_TRANSPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        transport: action.payload,
        error: null,
        recent: false,
      };

    case ADD_TRANSPORT_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case ADD_TRANSPORT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        recent: false,
        add_or_update: true,
      };

    case ADD_TRANSPORT_SUCCESS:
      return {
        loading: false,
        transport: [...state.transport.results, action.payload],
        error: null,
        recent: true,
        add_or_update: true,
      };

    case UPDATE_TRANSPORT_LOADING:
      return {
        ...state,
        loading: true,
        add_or_update: false,
      };

    case UPDATE_TRANSPORT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        recent: false,
        add_or_update: true,
      };

    case UPDATE_TRANSPORT_SUCCESS:
      const updateResults = state.transport.results.map(
        (el: TransportTypeII) => {
          if (el.id === action.payload.id) {
            return action.payload;
          }
          return el;
        }
      );

      return {
        loading: false,
        transport: { ...state.transport, results: updateResults },
        error: null,
        recent: true,
        add_or_update: true,
      };

    case DELETE_TRANSPORT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case DELETE_TRANSPORT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_TRANSPORT_SUCCESS:
      const results = state.transport.results.filter(
        (el: TransportTypeII) => el.id !== action.payload.id
      );

      return {
        ...state,
        loading: false,
        transport: { ...state.transport, results },
        error: null,
      };

    default:
      return state;
  }
};

export default TransportReducer;
