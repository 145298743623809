import React from "react";
import { InputLabel, TextField, Box } from "@material-ui/core";
import { useFormStyles } from "../../Styles/FormStyles";
import clsx from "clsx";

const CustomizedTextField = (props: any) => {
  const {
    label,
    name,
    type,
    placeholder,
    required,
    error,
    // onChange,
    ...rest
  } = props;
  const classes = useFormStyles();

  return (
    <Box
      className={clsx(
        props.size === "medium" ? classes.formWrapperMd : classes.formWrapper
      )}
    >
      <InputLabel>
        {label}
        {required && <span style={{ color: "red" }}>*</span>}
      </InputLabel>

      <TextField
        variant="outlined"
        name={name}
        type={type}
        placeholder={placeholder}
        error={Boolean(error)}
        helperText={error}
        {...rest}
      />
    </Box>
  );
};

export default CustomizedTextField;
