import {
  GET_TEACHER_LIST_LOADING,
  GET_TEACHER_LIST_ERROR,
  GET_TEACHER_LIST_SUCCESS,
  TeacherListDispatchTypes,
  TeacherListTypeI,
} from "../../actions/Classroom/TeacherList/TeacherListActionTypes";

interface InitialStateI {
  loading: boolean;
  teacher_list: TeacherListTypeI[];
}

const initialState: InitialStateI = {
  loading: false,
  teacher_list: [],
};

const TeacherListReducer = (
  state: InitialStateI = initialState,
  action: TeacherListDispatchTypes
): InitialStateI => {
  switch (action.type) {
    case GET_TEACHER_LIST_LOADING:
      return {
        ...state,
        loading: true,
        teacher_list: [],
      };

    case GET_TEACHER_LIST_ERROR:
      return {
        ...state,
        loading: false,
        teacher_list: [],
      };

    case GET_TEACHER_LIST_SUCCESS:
      const test = action.payload.filter(
        (item: TeacherListTypeI) => item.staff !== null || item.subject !== null
      );
      return {
        ...state,
        loading: false,
        teacher_list: test,
      };

    default:
      return state;
  }
};

export default TeacherListReducer;
