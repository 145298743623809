export const holidays = [
  {
    year: 2080,
    months: [
      {
        month: 1,
        days: [
          {
            day: 1,
            eventName: "Nepali New Year",
          },
          {
            day: 9,
            eventName: "Eid Ul Fitr",
          },
          {
            day: 18,
            eventName: "Labour Day",
          },
          {
            day: 22,
            eventName: "Buddha Jayanti",
          },
        ],
      },
      {
        month: 2,
        days: [
          {
            day: 15,
            eventName: "Republic Day",
          },
        ],
      },
      {
        month: 5,
        days: [
          {
            day: 7,
            eventName: "Gaura Parwa",
          },
          {
            day: 14,
            eventName: "Janai Purnima / Rakshya Bandhan",
          },
          {
            day: 20,
            eventName: "Shree Krishna Janmasthami",
          },
        ],
      },
      {
        month: 6,
        days: [
          {
            day: 1,
            eventName: "Haritalika Teej Vrata",
          },
          {
            day: 3,
            eventName: "Sambidhan Diwas",
          },
          {
            day: 28,
            eventName: "Ghatasthapana",
          },
        ],
      },
      {
        month: 7,
        days: [
          {
            day: 4,
            eventName: "Fulpati",
          },
          {
            day: 5,
            eventName: "Maha Astami",
          },
          {
            day: 6,
            eventName: "Maha Nawami",
          },
          {
            day: 7,
            eventName: "Bijaya Dashami",
          },
          {
            day: 8,
            eventName: "Papakunsa Ekadashi",
          },
          {
            day: 9,
            eventName: "Dashain Holiday",
          },
          {
            day: 25,
            eventName: "Kaag Tihar",
          },
          {
            day: 26,
            eventName: "Laxmi Pooja",
          },
          {
            day: 27,
            eventName: "Sombare Aunshi",
          },
          {
            day: 28,
            eventName: "Gobardhan Pooja",
          },
          {
            day: 29,
            eventName: "Bhai Tika",
          },
          {
            day: 30,
            eventName: "Tihar Holiday",
          },
        ],
      },
      {
        month: 8,
        days: [
          {
            day: 3,
            eventName: "Chhath Parwa",
          },
          {
            day: 11,
            eventName: "Guru Nanak Jayanti",
          },
          {
            day: 17,
            eventName: "Disabled Persons' Day",
          },
        ],
      },
      {
        month: 9,
        days: [
          {
            day: 9,
            eventName: "Christmas",
          },
          {
            day: 10,
            eventName: "Udhauli Parwa",
          },
          {
            day: 15,
            eventName: "Tamu Lhosar",
          },
          {
            day: 27,
            eventName: "Prithivi Jayanti",
          },
        ],
      },
      {
        month: 10,
        days: [
          {
            day: 1,
            eventName: "Maghe Sankranti",
          },
          {
            day: 27,
            eventName: "Sonam Lhosar",
          },
        ],
      },
      {
        month: 11,
        days: [
          {
            day: 1,
            eventName: "Janayuddha Diwas",
          },
          {
            day: 2,
            eventName: "Saraswati Pooja",
          },
          {
            day: 7,
            eventName: "Prajatantra Diwas",
          },
          {
            day: 25,
            eventName: "Maha Sivaratri Brata",
          },
          {
            day: 28,
            eventName: "Gyalpo Lhosar",
          },
        ],
      },
      {
        month: 12,
        days: [
          {
            day: 11,
            eventName: "Holi (Pahad)",
          },
          {
            day: 12,
            eventName: "Holi (Terai)",
          },
          {
            day: 26,
            eventName: "Ghode Jaatra",
          },
        ],
      },
    ],
  },
];

export const getHolidayEvent = ({
  year,
  month,
  day,
}: {
  year: number;
  month: number;
  day: number;
}) => {
  const currentYear = holidays.find((el) => el.year === year);

  if (currentYear) {
    const currentMonth = currentYear.months.find((el) => el.month === month);

    if (currentMonth) {
      const currentDay = currentMonth.days.find((el) => el.day === day);

      if (currentDay) {
        return currentDay.eventName;
      }
    }
  }
  return null;
};
