export interface Tupple {
  key: string;
  value: string;
}

export const SELECT_RELIGION: Tupple[] = [
  {
    key: "H",
    value: "Hinduisim",
  },
  {
    key: "M",
    value: "Muslim",
  },
  {
    key: "B",
    value: "Buddhism",
  },
  {
    key: "C",
    value: "Christianity",
  },
  {
    key: "O",
    value: "Others",
  },
];

export const SELECT_BLOOD_GROUP: Tupple[] = [
  {
    key: "1",
    value: "A+",
  },
  {
    key: "2",
    value: "B+",
  },
  {
    key: "3",
    value: "O+",
  },
  {
    key: "4",
    value: "A-",
  },
  {
    key: "5",
    value: "B-",
  },
  {
    key: "6",
    value: "O-",
  },
  {
    key: "7",
    value: "AB+",
  },
  {
    key: "8",
    value: "AB-",
  },
];

export const SELECT_GENDER: Tupple[] = [
  {
    key: "M",
    value: "Male",
  },
  {
    key: "F",
    value: "Female",
  },
  {
    key: "O",
    value: "Others",
  },
];

export const RELATION_STATUS_CHOICES: Tupple[] = [
  {
    key: "F",
    value: "Father",
  },
  {
    key: "M",
    value: "Mother",
  },
  {
    key: "B",
    value: "Brother",
  },
  {
    key: "S",
    value: "Sister",
  },
  {
    key: "R",
    value: "Relative",
  },
];

export const CATEGORY_CHOICES: Tupple[] = [
  {
    key: "G",
    value: "General",
  },
  {
    key: "O",
    value: "OBC",
  },
  {
    key: "S",
    value: "Special",
  },
  {
    key: "P",
    value: "Physically Challenged",
  },
];

export const STUDENT_CATEGORY_CHOICES: Tupple[] = [
  {
    key: "1",
    value: "Janajati",
  },
  {
    key: "2",
    value: "Bipanna",
  },
  {
    key: "3",
    value: "Dalit",
  },
  {
    key: "4",
    value: "Normal",
  },
];

export const CONTRACT_TYPE: Tupple[] = [
  {
    key: "F",
    value: "Full Time",
  },
  {
    key: "P",
    value: "Part Time",
  },
];

export const MEETING_CHOICES: Tupple[] = [
  {
    key: "P",
    value: "Pending",
  },
  {
    key: "A",
    value: "Approved",
  },
  {
    key: "D",
    value: "Declined",
  },
];

export const SPORT_CHOICES: Tupple[] = [
  { key: "B", value: "Basketball" },
  { key: "C", value: "Cricket" },
  { key: "F", value: "Footbal" },
  { key: "V", value: "VolleyBall" },
  { key: "O", value: "Others" },
];

export const statusChoices: Tupple[] = [
  { key: "P", value: "Paid" },
  { key: "D", value: "Due" },
];

export const MONTH_CHOICES: Tupple[] = [
  { key: "1", value: "Baishak" },
  { key: "2", value: "Jestha" },
  { key: "3", value: "Ashad" },
  { key: "4", value: "Shrawan" },
  { key: "5", value: "Bhadra" },
  { key: "6", value: "Ashoj" },
  { key: "7", value: "Kartik" },
  { key: "8", value: "Mangsir" },
  { key: "9", value: "Poush" },
  { key: "10", value: "Magh" },
  { key: "11", value: "Falgun" },
  { key: "12", value: "Chaitra" },
];

export const paginationChoices: Tupple[] = [
  { key: "5000", value: "All" },
  { key: "100", value: "100" },
  { key: "50", value: "50" },
  { key: "10", value: "10" },
  { key: "5", value: "5" },
];

export const APE: Tupple[] = [
  { key: "1", value: "1" },
  { key: "2", value: "2" },
  { key: "3", value: "3" },
];


export const paymentMethodChoices: Tupple[] = [
  { key: "1", value: "Cheque" },
  { key: "2", value: "Cash" },
  { key: "3", value: "Digital Wallet" },
  { key: "4", value: "Bank Transfer" },
];

export const sendSmsChoices: Tupple[] = [
  { key: "1", value: "Guardian" },
  { key: "1", value: "Student" },
  { key: "1", value: "Both" },
];

export const paymentOptions: Tupple[] = [
  { key: "cash", value: "Cash" },
  { key: "khalti", value: "Khalti" },
];

export const digitalPaymentOptions: Tupple[] = [
  { key: "khalti", value: "Khalti" },
];

export const assignmentCategoryOptions: Tupple[] = [
  { key: "HW", value: "Homework" },
  { key: "CW", value: "Classwork" },
  { key: "PR", value: "Project and Research" },
  { key: "CT", value: "Class Test" },
  { key: "WS", value: "Worksheet" },
];

export const submissionTypeOptions: Tupple[] = [
  { key: "Virtual", value: "Virtual" },
  { key: "Physical", value: "Physical" },
]

export const assignmentStatusOptions: Tupple[] = [
  {
    key: "S",
    value: "Submitted",
  },
  {
    key: "U",
    value: "Unsubmitted",
  },
]

export const assignmentGradeOptions: Tupple[] = [
  { key: "Grade", value: "Grade" },
  { key: "Redo", value: "Redo" },
]

export const rubricGradeOptions: Tupple[] = [
  {
    key: "A+",
    value: "5"
  },
  {
    key: "A",
    value: "4"
  },
  {
    key: "B+",
    value: "3"
  },
  {
    key: "C",
    value: "2"
  },
  {
    key: "D",
    value: "1"
  }
]