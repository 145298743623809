// -----------------<starts> module imports starts-----------------------//
import { Avatar, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import React from "react";
import { SearchedStudentsI } from "../../../actions/Student/Student/StudentActionTypes";
import getFullName from "../../utils/getFullName";

// -----------------<end> module imports ends-----------------------//

// -----------------<starts> styling starts-----------------------//
const useStyles = makeStyles({
    root: {
        margin: "10px 20px",
        paddingBottom: "10px"
    },
    profileImg: {
        width: "100px",
        height: "100px",
        alignItems: "center",
        margin: "auto",
        top: "20px",
    },
    title: {
        marginTop: "30px",
    },
    content: {
        display: "flex",
        flexDirection: "row",
        padding: "30px 0px 0px 0px",
    },
    detail: {
        paddingLeft: "10px"
    }
});
// -----------------<end> styling ends-----------------------//

interface PropsI {
    studentData: SearchedStudentsI | null;
}

// -----------------<starts> Student Card Components starts-----------------------//
export default function StudentDetailCard(props: PropsI) {
    const { studentData } = props;
    const classes = useStyles();
    return (
        <>
            {/* <Divider style={{ marginTop: "15px" }} /> */}
            {studentData ?
                <Paper className={classes.root}>
                    <Grid container>
                        <Grid item xs={3}>
                            <Avatar
                                className={classes.profileImg}
                                alt="Remy Sharp"
                                src={studentData.student_photo}
                            />

                            <Typography
                                className={classes.title}
                                align="center"
                                gutterBottom
                                variant="h4"
                                component="h3"
                            >
                                {getFullName(
                                    studentData.student_first_name,
                                    studentData.student_last_name
                                )}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <div className={classes.content}>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Adm No.:
                                </Typography>
                                <Typography variant="body2" component="p" className={classes.detail}>
                                    {studentData.admission_number}
                                </Typography>
                            </div>
                            <div className={classes.content}>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Class:
                                </Typography>
                                <Typography variant="body2" component="p" className={classes.detail}>
                                    {studentData.grade} {studentData.section}
                                </Typography>
                            </div>
                            <div className={classes.content}>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Roll No.:
                                </Typography>
                                <Typography variant="body2" component="p" className={classes.detail}>
                                    {studentData.roll_number}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className={classes.content}>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Address:
                                </Typography>
                                <Typography variant="body2" component="p" className={classes.detail}>
                                    {studentData.address}
                                </Typography>
                            </div>
                            <div className={classes.content}>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Guardian:
                                </Typography>
                                <Typography variant="body2" component="p" className={classes.detail}>
                                    {studentData.guardian_first_name} {studentData.guardian_last_name}
                                </Typography>
                            </div>
                            <div className={classes.content}>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Phone No.:
                                </Typography>
                                <Typography variant="body2" component="p" className={classes.detail}>
                                    {studentData.phone_number}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
                : null}
        </>
    );
}
// -----------------<end> Student Card Components ends--------------------------//
