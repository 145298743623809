import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { SchoolOutlined } from '@material-ui/icons';
import './AuthenticationLayout.css';
import checkBrands from '../../../config/checkBrands';

const useStyles = makeStyles((theme) => ({
  avatar: {
    fontSize: '45px',
    color: theme.palette.primary.main,
    verticalAlign: 'bottom',
    marginRight: theme.spacing(1),
  },
  avatar1: {
    fontSize: '45px',
    color: '#EDD739',
    verticalAlign: 'bottom',
    marginRight: theme.spacing(1),
  },

  title: {
    fontSize: '2rem',
    flexGrow: 1,
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  title1: {
    fontSize: '2rem',
    flexGrow: 1,
    fontWeight: 600,
    color: '#EDD739',
  },
  authBoxContainer: {
    padding: '50px 20px',
    minHeight: '100vh',
    backgroundColor: '#e8e8e8 !important',
    marginBottom: '-55px',
    '& .MuiFilledInput-root': {
      border: '1px solid #e3e3e3!important',
    },
  },
  authBox: {
    marginTop: '55px',
    maxWidth: '500px',
    overflow: 'hidden',
    backgroundColor: 'transparent',
  },
  boxTitle: {
    color: 'white',
    padding: '20px 0',
    textAlign: 'center',
    backgroundColor: '#241398',
  },
  boxTitle1: {
    color: 'white',
    padding: '20px 0',
    textAlign: 'center',
    backgroundColor: '#EDD739',
  },
}));

const AuthenticationLayout = (props: any) => {
  const classes = useStyles();
  const { children, title } = props;

  return (
    <React.Fragment>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.authBoxContainer}
        direction="column"
      >
        <Grid item>
          <Typography
            variant="h3"
            // className={
            //   checkBrands("nakshatraacademy") ? classes.title1 : classes.title
            // }
            className={classes.title}
          >
            <SchoolOutlined
              // className={
              //   checkBrands("nakshatraacademy")
              //     ? classes.avatar1
              //     : classes.avatar
              // }
              className={classes.avatar}
              fontSize="large"
            />
            {checkBrands('nakshatraacademy') ? 'Nakshatra Academy' : 'HAcademy'}
          </Typography>
        </Grid>

        <Grid item>
          <Paper
            variant="outlined"
            className={classes.authBox}
            style={props.width ? { maxWidth: props.width } : {}}
          >
            <Typography
              variant="h6"
              // className={
              //   checkBrands("nakshatraacademy")
              //     ? classes.boxTitle1
              //     : classes.boxTitle
              // }
              className={classes.boxTitle}
            >
              {title}
            </Typography>

            {children}
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AuthenticationLayout;
