import React from 'react'
import {
    Avatar,
    createStyles,
    Grid,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Theme,
    Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: theme.spacing(0, 2),
            backgroundColor: "#F2F3F6",
        },
        roota: {
            marginBottom: "1rem",
        },
        parentImg: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            height: "100%",
            padding: "1rem 0",
        },
        table: {
            color: "#696969",
            "& .MuiTableRow-root": {
                height: "30px",
                maxHeight: "30px",
            },
            "&.MuiTableCell-root": {
                width: "50%",
            },
        },
        infobar: {
            padding: theme.spacing(2),
        },
    })
);

const Child = (props: any) => {
    const classes = useStyles();
    const { data } = props;
    return (
        <Paper className={classes.infobar} elevation={1}>
            <TableContainer className={classes.roota}>
                <Grid item md={10}>
                    <Table
                        size="small"
                        className={classes.table}
                        aria-label="simple table"
                    >
                        <TableBody>
                            <TableRow>
                                <TableCell
                                    className={classes.table}
                                    component="th"
                                    scope="row"
                                >
                                    Full Name
                                </TableCell>
                                <TableCell align="left">
                                    {data.full_name}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    className={classes.table}
                                    component="th"
                                    scope="row"
                                >
                                    Grade
                                </TableCell>
                                <TableCell align="left">
                                    {data.academic_info[0].grade.grade_name}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    className={classes.table}
                                    component="th"
                                    scope="row"
                                >
                                    Section
                                </TableCell>
                                <TableCell align="left">
                                    {props.data.academic_info[0].section?.name}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            </TableContainer>
        </Paper>
    )
}

export default Child