export const GET_RUBRIC_SUB_CATEGORY_LOADING = "GET_RUBRIC_SUB_CATEGORY_LOADING";
export const GET_RUBRIC_SUB_CATEGORY_ERROR = "GET_RUBRIC_SUB_CATEGORY_ERROR";
export const GET_RUBRIC_SUB_CATEGORY_SUCCESS = "GET_RUBRIC_SUB_CATEGORY_SUCCESS";

export const ADD_RUBRIC_SUB_CATEGORY_LOADING = "ADD_RUBRIC_SUB_CATEGORY_LOADING";
export const ADD_RUBRIC_SUB_CATEGORY_ERROR = "ADD_RUBRIC_SUB_CATEGORY_ERROR";
export const ADD_RUBRIC_SUB_CATEGORY_SUCCESS = "ADD_RUBRIC_SUB_CATEGORY_SUCCESS";

export const UPDATE_RUBRIC_SUB_CATEGORY_LOADING = "UPDATE_RUBRIC_SUB_CATEGORY_LOADING";
export const UPDATE_RUBRIC_SUB_CATEGORY_ERROR = "UPDATE_RUBRIC_SUB_CATEGORY_ERROR";
export const UPDATE_RUBRIC_SUB_CATEGORY_SUCCESS = "UPDATE_RUBRIC_SUB_CATEGORY_SUCCESS";

export const DELETE_RUBRIC_SUB_CATEGORY_LOADING = "DELETE_RUBRIC_SUB_CATEGORY_LOADING";
export const DELETE_RUBRIC_SUB_CATEGORY_ERROR = "DELETE_RUBRIC_SUB_CATEGORY_ERROR";
export const DELETE_RUBRIC_SUB_CATEGORY_SUCCESS = "DELETE_RUBRIC_SUB_CATEGORY_SUCCESS";

import { RubricCategoryII } from "../RubricCategoryActionTypes"


//===============================<START>GET RUBRIC SUB CATEGORY<START>======================//

export interface GetRubricSubCategoryLoading {
    type: typeof GET_RUBRIC_SUB_CATEGORY_LOADING;
}

export interface GetRubricSubCategoryError {
    type: typeof GET_RUBRIC_SUB_CATEGORY_ERROR;
}

export interface GetRubricSubCategorySuccess {
    type: typeof GET_RUBRIC_SUB_CATEGORY_SUCCESS;
    payload: RubricCategoryII;
}

//===============================<END>GET RUBRIC SUB CATEGORY<END>======================//

//===============================<START>ADD RUBRIC SUB CATEGORY<START>======================//

export interface AddRubricSubCategoryLoading {
    type: typeof ADD_RUBRIC_SUB_CATEGORY_LOADING;
}

export interface AddRubricSubCategoryError {
    type: typeof ADD_RUBRIC_SUB_CATEGORY_ERROR;
    payload: any;
}

export interface AddRubricSubCategorySuccess {
    type: typeof ADD_RUBRIC_SUB_CATEGORY_SUCCESS;
    payload: any;
}

//===============================<END>ADD RUBRIC SUB CATEGORY<END>======================//

//===============================<START>UPDATE RUBRIC SUB CATEGORY<START>======================//

export interface UpdateRubricSubCategoryLoading {
    type: typeof UPDATE_RUBRIC_SUB_CATEGORY_LOADING;
}

export interface UpdateRubricSubCategoryError {
    type: typeof UPDATE_RUBRIC_SUB_CATEGORY_ERROR;
    payload: any;
}

export interface UpdateRubricSubCategorySuccess {
    type: typeof UPDATE_RUBRIC_SUB_CATEGORY_SUCCESS;
    payload: any;
}

//===============================<END>UPDATE RUBRIC SUB CATEGORY<END>======================//

//===============================<START>DELETE RUBRIC SUB CATEGORY<START>======================//

export interface DeleteRubricSubCategoryLoading {
    type: typeof DELETE_RUBRIC_SUB_CATEGORY_LOADING;
}

export interface DeleteRubricSubCategoryError {
    type: typeof DELETE_RUBRIC_SUB_CATEGORY_ERROR;
}

export interface DeleteRubricSubCategorySuccess {
    type: typeof DELETE_RUBRIC_SUB_CATEGORY_SUCCESS;
    payload: { id: string };
}

//===============================<END>DELETE RUBRIC SUB CATEGORY<END>======================//

export type RubricSubCategoryDispatchTypes =
    | GetRubricSubCategoryLoading
    | GetRubricSubCategoryError
    | GetRubricSubCategorySuccess
    | AddRubricSubCategoryLoading
    | AddRubricSubCategoryError
    | AddRubricSubCategorySuccess
    | UpdateRubricSubCategoryLoading
    | UpdateRubricSubCategoryError
    | UpdateRubricSubCategorySuccess
    | DeleteRubricSubCategoryLoading
    | DeleteRubricSubCategoryError
    | DeleteRubricSubCategorySuccess;