import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import { default as Tab } from '@material-ui/core/Tab';
import {
  DashboardOutlined,
  SchoolOutlined,
  DirectionsBusOutlined,
  QueueOutlined,
  DonutLargeOutlined,
  SmsOutlined,
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import Header from '../Header/Header';
import { SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PaymentIcon from '@material-ui/icons/Payment';
import { FaRupeeSign } from 'react-icons/fa';
import { GiReceiveMoney } from 'react-icons/gi';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '300px',
    marginLeft: '24px',
    marginRight: '24px',
    backgroundColor: theme.palette.background.paper,

    '& .MuiTab-labelIcon': {
      minHeight: '40px',
      paddingTop: '6px!important',
    },
    '& .MuiTab-root': {
      fontSize: '14px',
      textTransform: 'none',
      minWidth: '110px',
      maxWidth: '200px',
      height: '30px',
      letterSpacing: '0.01071em',
    },
    '& .MuiTab-labelIcon .MuiTab-wrapper > *:first-child': {
      marginRight: '4px',
      marginBottom: '0px',
    },
    '& .MuiTabs-flexContainer': {
      // margin: theme.spacing(0, 2),
    },
    '& .MuiTypography-root': {
      color: '#000',
    },
    '& .MuiPaper-elevation4': {
      // boxShadow: "2px 1px 3px 0px rgba(0,0,0,0.1)",
      boxShadow: '1px 1px 30px 6px rgb(0 0 0 / 10%)',
    },
    '& .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.MuiTab-labelIcon':
      {
        overflow: 'visible',
      },
    '& a.Mui-selected.MuiButtonBase-root.MuiTab-labelIcon.MuiTab-root.MuiTab-textColorPrimary::after':
      {
        content: "''",
        position: 'absolute',
        bottom: '-4px',
        left: '0px',
        width: '100%',
        borderBottom: '2px solid',
      },
    '& span.MuiTabs-indicator': {
      display: 'none!important',
    },
  },
  navbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& > .MuiTabs-root': {
      // overflow: "visible",
    },
    '& .MuiTabs-scroller': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  tabs: {
    padding: '0px 22px 0 0',

    '& > .MuiTabScrollButton-root': {
      boxShadow: '-15px 0px 10px 10px rgb(255 255 255)',
      '&:first-child': {
        boxShadow: '15px 0px 10px 10px rgb(255 255 255)',
      },
      width: '30px',
      zIndex: 9,
      '& > *': {
        fontSize: '16px',
      },
    },
  },
}));

export default function AccountantNavbar() {
  const classes = useStyles();
  const [value, setValue] = React.useState<number>(0);
  const [mQuery, setMQuery] = React.useState<any>({
    matches: window.innerWidth > 1750 ? true : false,
  });

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 1750px)');
    mediaQuery.addListener(setMQuery);

    return () => mediaQuery.removeListener(setMQuery);
  }, []);

  const path = location.pathname;

  useEffect(() => {
    // else if (path === '/collect-fees') setValue(22);
    if (path === '/') setValue(0);
    else if (
      path === '/student' ||
      path === '/student-admission' ||
      path === '/disabled-student' ||
      path === '/bulk-delete' ||
      path === '/student-categories' ||
      path === '/student-house' ||
      path === '/parent-details' ||
      path === '/import-student-info' ||
      path === '/studentprofile'
    )
      setValue(1);
    else if (
      path === '/section' ||
      path === '/class' ||
      path === '/subject' ||
      path === '/subject-group' ||
      path === '/timetable' ||
      path === '/assign-class-teacher' ||
      path === '/syllabus'
    )
      setValue(2);
    else if (
      path === '/designation' ||
      path === '/department' ||
      path === '/staff' ||
      path === '/add-staff'
    )
      setValue(3);
    else if (
      path === '/examination' ||
      path === '/exam-schedule' ||
      path === '/add-marks' ||
      path === '/grades' ||
      path === '/results'
    )
      setValue(4);
    else if (
      path === '/report' ||
      path === '/admit-card' ||
      path === '/marksheet' ||
      path === '/gradesheet' ||
      path === '/character-certificate' ||
      path === '/marksheet-certificate' ||
      path === '/gradesheet-certificate' ||
      path === '/id-card-template'
    )
      setValue(6);
    else if (
      path === '/fee-type' ||
      // path === '/collect-fees' ||
      path === '/payment-method'
    )
      setValue(20);
    else if (path === '/collect-fees') setValue(25);
    else if (
      path === '/income' ||
      path === '/add-income' ||
      path === '/expense' ||
      path === '/add-expenses' ||
      path === '/fee-ledger' ||
      path === '/due-fee' ||
      path === '/discount-type' ||
      path === '/fine-type' ||
      path === '/send-due-fee' ||
      path === '/due-report' ||
      path === '/fee-type-report' ||
      path === '/monthly-report'
    )
      setValue(21);
    else if (path === '/events' || path === '/event-type') setValue(16);
    else if (path === '/routes' || path === '/bus-user') setValue(9);
    else if (path === '/scholarship-type' || path === '/apply-scholarship')
      setValue(10);
    // else if (path === '/general-setting') setValue(16);
    else if (path === '/announcement') setValue(17);
  }, [path]);

  const [url, setUrl] = useState(false);
  useEffect(() => {
    const a = window.location.pathname;
    const b = a.split('/');
    if (b.includes('classroom')) {
      setUrl(true);
    } else {
      setUrl(false);
    }
  }, [window.location.pathname]);

  return (
    <div className={classes.root}>
      <AppBar position="fixed" color="inherit">
        <Header />
        <div className={classes.navbar}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs example"
            className={classes.tabs}
          >
            {navItemsData &&
              navItemsData.length > 0 &&
              navItemsData.map((item) => (
                <Tab
                  key={item.id}
                  value={item.id}
                  to={item.href}
                  component={Link}
                  icon={item.icon && <item.icon />}
                  label={item.name}
                />
              ))}
          </Tabs>
        </div>
      </AppBar>
    </div>
  );
}

interface NavItemTypeI {
  id: number;
  name: string;
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  href: string;
}

const navItemsData: NavItemTypeI[] = [
  {
    id: 0,
    name: 'Dashboard',
    icon: DashboardOutlined,
    href: '/',
  },
  {
    id: 1,
    name: 'Student',
    icon: SchoolOutlined,
    href: '/student',
  },
  // {
  //   id: 22,
  //   name: 'Collect Fees',
  //   icon: PaymentIcon,
  //   href: '/collect-fees',
  // },
  {
    id: 20,
    name: 'Finance',
    icon: AccountBalanceIcon,
    href: '/fee-type',
  },
  {
    id: 25,
    name: 'Collect Fees',
    icon: GiReceiveMoney,
    href: '/collect-fees',
  },
  {
    id: 21,
    name: 'Finance Report',
    icon: QueueOutlined,
    href: '/day-book',
  },
  {
    id: 17,
    name: 'Announcement',
    icon: SmsOutlined,
    href: '/announcement',
  },
  {
    id: 16,
    name: 'Events',
    icon: DonutLargeOutlined,
    href: '/events',
  },
  {
    id: 9,
    name: 'Transport',
    icon: DirectionsBusOutlined,
    href: '/routes',
  },
  {
    id: 10,
    name: 'Scholarship',
    icon: SchoolOutlined,
    href: '/scholarship-type',
  },
];
