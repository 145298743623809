import axios from 'axios';
import { Dispatch } from 'redux';

import {
  GET_TRANSPORT_LOADING,
  GET_TRANSPORT_ERROR,
  GET_TRANSPORT_SUCCESS,
  TransportDispatchTypes,
  ADD_TRANSPORT_LOADING,
  ADD_TRANSPORT_SUCCESS,
  ADD_TRANSPORT_ERROR,
  DELETE_TRANSPORT_SUCCESS,
  DELETE_TRANSPORT_ERROR,
  DELETE_TRANSPORT_LOADING,
  DELETE_TRANSPORT_STUDENT_SUCCESS,
  DELETE_TRANSPORT_STUDENT_ERROR,
  DELETE_TRANSPORT_STUDENT_LOADING,
  UPDATE_TRANSPORT_LOADING,
  UPDATE_TRANSPORT_SUCCESS,
  UPDATE_TRANSPORT_ERROR,
} from './TransportActionTypes';

import { SET_SNACKBAR, SetSnackBarI } from '../../SnackbarActionTypes';
import { HeaderConfig } from '../../Auth/Login/LoginAction';
import { ADMIN_API_URL } from '../../../config/SERVER_API_URLS';

interface RequestDataI {
  id: string;
  route_from: string;
  route_to: string;
  pickup_time: string;
  drop_time: string;
  fare: number;
  driver?: string;
  assistant?: string;
  bus_number: string;
  seat: number;
}

export interface TransportRequestDataII {
  id: string;
  route_name: string;
  driver: string;
  driver_assistant: string;
}

export const GetTransport =
  () => async (dispatch: Dispatch<TransportDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GET_TRANSPORT_LOADING,
      });

      const res = await axios.get(
        `${ADMIN_API_URL}/transport_info/`,
        HeaderConfig()
      );

      dispatch({
        type: GET_TRANSPORT_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_TRANSPORT_ERROR,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "Transport Loading Failed",
      //   },
      // });
    }
  };

export const AddTransport =
  (data: any) =>
  async (dispatch: Dispatch<TransportDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: ADD_TRANSPORT_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/transport_info/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: ADD_TRANSPORT_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'success',
          snackbarMessage: 'Transport Added Successfully',
        },
      });
    } catch (error: any) {
      dispatch({
        type: ADD_TRANSPORT_ERROR,
        payload: error.response && error.response?.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: 'Transport Add Failed',
        },
      });
    } finally {
      try {
        dispatch({
          type: GET_TRANSPORT_LOADING,
        });

        const res = await axios.get(
          `${ADMIN_API_URL}/transport_info/`,
          HeaderConfig()
        );

        dispatch({
          type: GET_TRANSPORT_SUCCESS,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: GET_TRANSPORT_ERROR,
        });
      }
    }
  };

export const UpdateTransport =
  (id: string, data: any) =>
  async (dispatch: Dispatch<TransportDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: UPDATE_TRANSPORT_LOADING,
      });

      const res = await axios.put(
        `${ADMIN_API_URL}/transport_info/${id}/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: UPDATE_TRANSPORT_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'success',
          snackbarMessage: 'Transport Updated Successfully',
        },
      });
    } catch (error: any) {
      dispatch({
        type: UPDATE_TRANSPORT_ERROR,
        payload: error.response && error.response?.data,
      });

      // dispatch({
      //   type: SET_SNACKBAR,
      //   payload: {
      //     snackbarOpen: true,
      //     snackbarType: "error",
      //     snackbarMessage: "Transport Update Failed",
      //   },
      // });
    }
  };

export const DeleteTransport =
  (id: string) =>
  async (dispatch: Dispatch<TransportDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_TRANSPORT_LOADING,
      });

      const res = await axios.delete(
        `${ADMIN_API_URL}/transport_info/${id}/`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_TRANSPORT_SUCCESS,
        payload: { id: id },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'success',
          snackbarMessage: 'Transport Deleted Successfully',
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_TRANSPORT_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: 'Transport Delete Failed',
        },
      });
    }
  };

export const DeleteStudentFromTransport =
  (id: string, studId: string) =>
  async (dispatch: Dispatch<TransportDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: DELETE_TRANSPORT_STUDENT_LOADING,
      });

      const res = await axios.delete(
        `${ADMIN_API_URL}/transport_info/delete_student/?transport=${id}&student=${studId}`,
        HeaderConfig()
      );

      dispatch({
        type: DELETE_TRANSPORT_STUDENT_SUCCESS,
        payload: { id: studId },
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'success',
          snackbarMessage: 'Student Deleted Successfully',
        },
      });
    } catch (error) {
      dispatch({
        type: DELETE_TRANSPORT_STUDENT_ERROR,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: 'Student Delete Failed',
        },
      });
    }
  };
