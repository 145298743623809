import { Grid } from '@material-ui/core';
import React from 'react';
import { CommunicationSidebar, HRsidebar } from '../../components';
import SubModuleLayout from '../../components/Reusable/Layouts/SubModuleLayout';
import MeetingList from '../../components/HumanResource/Meeting/MeetingList';
import MeetingSearch from '../../components/HumanResource/Meeting/MeetingSearch';
import OnMeetingList from '../../components/HumanResource/Meeting/OnMeetingList';

const MeetingPage = () => {
  const nav = <CommunicationSidebar meeting />;
  return (
    <SubModuleLayout studentMenuActive sideNav={nav}>
      <Grid container>
        <Grid item xs={12}>
          <MeetingSearch />
          <MeetingList />
        </Grid>
        {/* <Grid item xs={3}>
          <OnMeetingList />
        </Grid> */}
      </Grid>
    </SubModuleLayout>
  );
};
export default MeetingPage;
