export const GET_SESSION_LOADING = "GET_SESSION_LOADING";
export const GET_SESSION_ERROR = "GET_SESSION_ERROR";
export const GET_SESSION_SUCCESS = "GET_SESSION_SUCCESS";

export const ADD_SESSION_LOADING = "ADD_SESSION_LOADING";
export const ADD_SESSION_ERROR = "ADD_SESSION_ERROR";
export const ADD_SESSION_SUCCESS = "ADD_SESSION_SUCCESS";

export const UPDATE_SESSION_LOADING = "UPDATE_SESSION_LOADING";
export const UPDATE_SESSION_ERROR = "UPDATE_SESSION_ERROR";
export const UPDATE_SESSION_SUCCESS = "UPDATE_SESSION_SUCCESS";

export const UPDATE_SESSION_STATUS_LOADING = "UPDATE_SESSION_STATUS_LOADING";
export const UPDATE_SESSION_STATUS_ERROR = "UPDATE_SESSION_STATUS_ERROR";
export const UPDATE_SESSION_STATUS_SUCCESS = "UPDATE_SESSION_STATUS_SUCCESS";

export const DELETE_SESSION_LOADING = "DELETE_SESSION_LOADING";
export const DELETE_SESSION_ERROR = "DELETE_SESSION_ERROR";
export const DELETE_SESSION_SUCCESS = "DELETE_SESSION_SUCCESS";

export type SessionTypeI = {
  id: string;
  session_name: string;
  name: string;
  from_date: string;
  to_date: string;
  status: boolean;
  created_by: string;
  general_info: string;
};

//===============================<START>GET SESSION<START>======================//

export interface GetSessionLoading {
  type: typeof GET_SESSION_LOADING;
}

export interface GetSessionError {
  type: typeof GET_SESSION_ERROR;
}

export interface GetSessionSuccess {
  type: typeof GET_SESSION_SUCCESS;
  payload: SessionTypeI[];
}

//===============================<END>GET SESSION<END>======================//

//===============================<START>ADD SESSION<START>======================//

export interface AddSessionLoading {
  type: typeof ADD_SESSION_LOADING;
}

export interface AddSessionError {
  type: typeof ADD_SESSION_ERROR;
}

export interface AddSessionSuccess {
  type: typeof ADD_SESSION_SUCCESS;
  payload: SessionTypeI;
}

//===============================<END>ADD SESSION<END>======================//

//===============================<START>UPDATE SESSION<START>======================//

export interface UpdateSessionLoading {
  type: typeof UPDATE_SESSION_LOADING;
}

export interface UpdateSessionError {
  type: typeof UPDATE_SESSION_ERROR;
}

export interface UpdateSessionSuccess {
  type: typeof UPDATE_SESSION_SUCCESS;
  payload: SessionTypeI;
}

//===============================<END>UPDATE SESSION<END>======================//

//===============================<START>UPDATE SESSION STATUS<START>======================//

export interface UpdateSessionStatusLoading {
  type: typeof UPDATE_SESSION_STATUS_LOADING;
}

export interface UpdateSessionStatusError {
  type: typeof UPDATE_SESSION_STATUS_ERROR;
}

export interface UpdateSessionStatusSuccess {
  type: typeof UPDATE_SESSION_STATUS_SUCCESS;
  payload: SessionTypeI;
}

//===============================<END>UPDATE SESSION STATUS<END>======================//

//===============================<START>DELETE SESSION<START>======================//

export interface DeleteSessionLoading {
  type: typeof DELETE_SESSION_LOADING;
}

export interface DeleteSessionError {
  type: typeof DELETE_SESSION_ERROR;
}

export interface DeleteSessionSuccess {
  type: typeof DELETE_SESSION_SUCCESS;
  payload: { id: string };
}

//===============================<END>GET SESSION<END>======================//

export type SessionDispatchTypes =
  | GetSessionLoading
  | GetSessionError
  | GetSessionSuccess
  | AddSessionLoading
  | AddSessionError
  | AddSessionSuccess
  | UpdateSessionLoading
  | UpdateSessionError
  | UpdateSessionSuccess
  | UpdateSessionStatusLoading
  | UpdateSessionStatusError
  | UpdateSessionStatusSuccess
  | DeleteSessionLoading
  | DeleteSessionError
  | DeleteSessionSuccess;
