import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  TextField,
  Theme,
  Tooltip,
} from "@material-ui/core";
import {
  Search,
  SettingsBackupRestore,
} from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import { GetTeacherList } from '../../../actions/Classroom/TeacherList/TeacherListAction';
import { TeacherListTypeI } from '../../../actions/Classroom/TeacherList/TeacherListActionTypes';
import { GetClasses } from "../../../actions/Academics/Class/ClassAction";
import { Autocomplete } from "@material-ui/lab";
import { SectionTypeI } from "../../../actions/Academics/Section/SectionActionTypes";
import { useFormStyles } from "../../Styles/FormStyles";
import { SubjectTypeI } from "../../../actions/Academics/Subject/SubjectActionTypes";
import { useForm } from "react-hook-form";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // margin: theme.spacing(2, 0, 2, 4),
      backgroundColor: theme.palette.background.paper,
      "& .MuiTypography-body1": {
        fontSize: "15px",
        textAlign: "left",
      },
    },
  })
);

const AdminSyllabusSelect = (props: any) => {
  const classes = useFormStyles();
  const dispatch = useDispatch();
  const { classItem } = props;
  const { register, handleSubmit, errors, reset, setValue } = useForm<any>({
    mode: "onChange",
  });
  const [sectionChoices, setSectionChoices] = useState<any>([]);
  const [selectedSection, setSelectedSection] = useState<SectionTypeI | null>(null);
  const [subjectChoices, setSubjectChoices] = useState<TeacherListTypeI[]>([]);
  const [selectedSubject, setSelectedSubject] = useState<TeacherListTypeI | null>(null);
  const [flag, setFlag] = useState<boolean>(false);

  const teacherSelector = useSelector((state: RootStore) => state.teacher_lists.teacher_list);

  useEffect(() => {
    if (classItem) {
      setSectionChoices(classItem.section);
      setFlag(true)
    }
  }, [classItem]);

  useEffect(() => {
    setSubjectChoices(teacherSelector);
  }, [teacherSelector]);

  const onSubmit = (data: any) => {
    if (classItem && selectedSubject) {
    }
  };

  const handleSectionChange = (value: SectionTypeI | null) => {
    setSelectedSubject(null);
    if (value && classItem) {
      setSelectedSection(value);
      dispatch(GetTeacherList(classItem.grade, value.id, "", ""));
    }
  };


  return (
    <>
      <Paper style={{ padding: "1rem" }}>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={0}>
            <Grid item xs={6} className={classes.formTitle}>
              <span>Search Syllabus</span>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid
              item
              xs={3}
              className={classes.formWrapperRow}
              style={{ maxWidth: '13%' }}
            >
              <Box color="text.primary" mr={0.5}>
                Section
              </Box>
              <Autocomplete
                classes={{
                  input: classes.smallfont,
                  option: classes.smallfont,
                }}
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: SectionTypeI | null
                ) => handleSectionChange(value)}
                options={sectionChoices}
                value={selectedSection}
                fullWidth
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Label"
                    name="section"
                    inputRef={register({ required: true })}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={4} className={classes.formWrapperRow}>
              <Box mr={1} fontSize={13} color="text.primary">
                Subject
              </Box>
              <Autocomplete
                classes={{
                  input: classes.smallfont,
                  option: classes.smallfont,
                }}
                fullWidth
                onChange={(
                  event: React.ChangeEvent<{}>,
                  value: TeacherListTypeI | null
                ) => setSelectedSubject(value)}
                options={subjectChoices}
                value={selectedSubject}
                getOptionLabel={(option) => option.subject_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Choose Subject"
                    name="subject"
                    inputRef={register({ required: true })}
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            <Grid
              style={{ maxWidth: "14%" }}
              item
              xs={2}
              className={classes.formWrapperRow}
            >
              <Button
                style={{
                  marginTop: "5px",
                  backgroundColor: "#3FC581",
                  color: "#fff",
                }}
                type="submit"
                variant="contained"
                startIcon={<Search />}
              >
                Search
              </Button>
            </Grid>
            <Grid item xs={1}>
              <Tooltip title="Clear Search Query">
                <IconButton
                  aria-label="delete"
                  style={{
                    padding: "4px",
                    marginTop: "5px",
                    background: "rgba(0,0,0,0.05)",
                  }}
                // onClick={handleReset}
                >
                  <SettingsBackupRestore />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
};

export default AdminSyllabusSelect;
