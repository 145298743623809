import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Theme,
  useTheme,
  withStyles,
  Paper,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  Grid,
  TextField,
} from '@material-ui/core';
import { RootStore } from '../../../store';
import { AdminOnlineClassTypeI } from '../../../actions/Classroom/OnlineClass/OnlineClassActionTypes';
import { useDispatch, useSelector } from 'react-redux';
import SectionField from './SectionField';
import ClassRoomTable from './ClassRoomTable';
import { Autocomplete } from '@material-ui/lab';
import {
  GetOnlineClass,
  GetRegularOnlineClass,
} from '../../../actions/Classroom/OnlineClass/OnlineClassAction';
import { useFormStyles } from '../../Styles/FormStyles';
import RegularClassTabs from './RegularClassTabs';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

interface StyledTabsProps {
  value: number;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
  [x: string]: any;
}

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 100,
      width: '100%',
      backgroundColor: '#635ee7',
    },
  },
})((props: any) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />
));

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ClassRoomTabs(props: any) {
  const { classItem } = props;
  const classes = useStyles();
  const classX = useFormStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();

  const [onlineClass, setOnlineClass] = useState<AdminOnlineClassTypeI[]>([
    // {
    //   id: 1,
    //   subject: 1,
    //   title: "English Chapter 1",
    //   teacher_first_name: "John",
    //   teacher_last_name: "Doe",
    //   subject_name: "English",
    //   class_date: "2022-02-11",
    //   start_time: "11:00",
    //   end_time: "12:00",
    //   grade: 1,
    //   section: 1,
    //   academic_session: 1,
    //   link: "some link",
    //   is_completed: false,
    //   is_upcomming: true,
    //   is_ongoing: false,
    // },
    // {
    //   id: 2,
    //   subject: 1,
    //   title: "Opt Maths Trigonometry",
    //   teacher_first_name: "Josh",
    //   teacher_last_name: "Graham",
    //   subject_name: "Opt Maths",
    //   class_date: "2022-02-09",
    //   start_time: "11:00",
    //   end_time: "12:00",
    //   grade: 1,
    //   section: 1,
    //   academic_session: 1,
    //   link: "some link",
    //   is_completed: false,
    //   is_upcomming: true,
    //   is_ongoing: false,
    // },
    // {
    //   id: 3,
    //   subject: 1,
    //   title: "Science Physics",
    //   teacher_first_name: "Louise",
    //   teacher_last_name: "Ellen",
    //   subject_name: "Science",
    //   class_date: "2022-02-12",
    //   start_time: "11:00",
    //   end_time: "12:00",
    //   grade: 1,
    //   section: 1,
    //   academic_session: 1,
    //   link: "some link",
    //   is_completed: false,
    //   is_upcomming: true,
    //   is_ongoing: false,
    // },
  ]);
  const [todayNumber, setTodayNumber] = useState('');
  const [selectedSection, setSelectedSection] = useState<any>(null);
  const [sectionChoices, setSectionChoices] = useState<any>([]);

  const onlineClassSelector = useSelector(
    (state: RootStore) => state.online_classes.online_class
  );

  // useEffect(() => {
  //   if (onlineClassSelector) {
  //     setOnlineClass(onlineClassSelector);
  //   }
  // }, [onlineClassSelector]);

  useEffect(() => {
    if (classItem) {
      setSectionChoices(classItem.section);
    }
  }, [classItem]);

  useEffect(() => {
    const n = new Date().getDay();
    if (selectedSection) {
      dispatch(GetOnlineClass(classItem?.grade, selectedSection?.id));
    }
    if (selectedSection) {
      if (n == 7) {
        setTodayNumber('7');
      } else {
        setTodayNumber(n.toString());
      }
    }
  }, [selectedSection]);

  useEffect(() => {
    if (sectionChoices && selectedSection == undefined) {
      setSelectedSection(sectionChoices[0]);
    }
  }, [sectionChoices]);

  useEffect(() => {
    setSelectedSection(null);
  }, [classItem]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Paper elevation={0} className={classes.root}>
      <AppBar elevation={1} position="static" color="inherit">
        <StyledTabs value={value} onChange={handleChange}>
          <Tab
            onClick={() =>
              dispatch(GetOnlineClass(classItem?.grade, selectedSection?.id))
            }
            label="All Classes"
            {...a11yProps(0)}
          />
          <Tab
            onClick={() =>
              dispatch(
                GetOnlineClass(
                  classItem?.grade,
                  selectedSection?.id,
                  todayNumber
                )
              )
            }
            label="Today Classes"
            {...a11yProps(1)}
          />
          <Tab label="Regular Classes" {...a11yProps(2)} />
          <Grid
            container
            justifyContent="flex-end"
            direction="row"
            alignItems="center"
            style={{ padding: '0 10px' }}
          >
            <Box mr={1} color="text.secondary" textAlign="left">
              Section
            </Box>
            <Grid
              style={{ width: '100px' }}
              item
              className={classX.formWrapper}
            >
              <Autocomplete
                classes={{
                  input: classX.smallfont,
                  option: classX.smallfont,
                }}
                onChange={(event: React.ChangeEvent<{}>, value: any | null) =>
                  setSelectedSection(value)
                }
                options={sectionChoices}
                value={selectedSection}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Choose Section"
                    name="section"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </Grid>
        </StyledTabs>
      </AppBar>
      <TabPanel value={value} index={0} dir={theme.direction}>
        <ClassRoomTable onlineClass={onlineClass} />
      </TabPanel>

      <TabPanel value={value} index={1} dir={theme.direction}>
        <ClassRoomTable onlineClass={onlineClass} />
      </TabPanel>
      <TabPanel value={value} index={2} dir={theme.direction}>
        <RegularClassTabs
          classItem={classItem}
          selectedSection={selectedSection}
        />
      </TabPanel>
    </Paper>
  );
}
