export const GET_RUBRIC_CATEGORY_LOADING = "GET_RUBRIC_CATEGORY_LOADING";
export const GET_RUBRIC_CATEGORY_ERROR = "GET_RUBRIC_CATEGORY_ERROR";
export const GET_RUBRIC_CATEGORY_SUCCESS = "GET_RUBRIC_CATEGORY_SUCCESS";

export const ADD_RUBRIC_CATEGORY_LOADING = "ADD_RUBRIC_CATEGORY_LOADING";
export const ADD_RUBRIC_CATEGORY_ERROR = "ADD_RUBRIC_CATEGORY_ERROR";
export const ADD_RUBRIC_CATEGORY_SUCCESS = "ADD_RUBRIC_CATEGORY_SUCCESS";

export const UPDATE_RUBRIC_CATEGORY_LOADING = "UPDATE_RUBRIC_CATEGORY_LOADING";
export const UPDATE_RUBRIC_CATEGORY_ERROR = "UPDATE_RUBRIC_CATEGORY_ERROR";
export const UPDATE_RUBRIC_CATEGORY_SUCCESS = "UPDATE_RUBRIC_CATEGORY_SUCCESS";

export const DELETE_RUBRIC_CATEGORY_LOADING = "DELETE_RUBRIC_CATEGORY_LOADING";
export const DELETE_RUBRIC_CATEGORY_ERROR = "DELETE_RUBRIC_CATEGORY_ERROR";
export const DELETE_RUBRIC_CATEGORY_SUCCESS = "DELETE_RUBRIC_CATEGORY_SUCCESS";

export interface RubricCategoryTypeI {
  id: string;
  name: string;
  count?: number;
}
export interface RubricCategoryII {
  current_page: number;
  links: any;
  per_page: number;
  results: RubricCategoryTypeI[];
  total: number;
  total_pages: number;
}



//===============================<START>GET RUBRIC CATEGORY<START>======================//

export interface GetRubricCategoryLoading {
  type: typeof GET_RUBRIC_CATEGORY_LOADING;
}

export interface GetRubricCategoryError {
  type: typeof GET_RUBRIC_CATEGORY_ERROR;
}

export interface GetRubricCategorySuccess {
  type: typeof GET_RUBRIC_CATEGORY_SUCCESS;
  payload: RubricCategoryII;
}

//===============================<END>GET RUBRIC CATEGORY<END>======================//

//===============================<START>ADD RUBRIC CATEGORY<START>======================//

export interface AddRubricCategoryLoading {
  type: typeof ADD_RUBRIC_CATEGORY_LOADING;
}

export interface AddRubricCategoryError {
  type: typeof ADD_RUBRIC_CATEGORY_ERROR;
  payload: any;
}

export interface AddRubricCategorySuccess {
  type: typeof ADD_RUBRIC_CATEGORY_SUCCESS;
  payload: RubricCategoryTypeI;
}

//===============================<END>ADD RUBRIC CATEGORY<END>======================//

//===============================<START>UPDATE RUBRIC CATEGORY<START>======================//

export interface UpdateRubricCategoryLoading {
  type: typeof UPDATE_RUBRIC_CATEGORY_LOADING;
}

export interface UpdateRubricCategoryError {
  type: typeof UPDATE_RUBRIC_CATEGORY_ERROR;
  payload: any;
}

export interface UpdateRubricCategorySuccess {
  type: typeof UPDATE_RUBRIC_CATEGORY_SUCCESS;
  payload: RubricCategoryTypeI;
}

//===============================<END>UPDATE RUBRIC CATEGORY<END>======================//

//===============================<START>DELETE RUBRIC CATEGORY<START>======================//

export interface DeleteRubricCategoryLoading {
  type: typeof DELETE_RUBRIC_CATEGORY_LOADING;
}

export interface DeleteRubricCategoryError {
  type: typeof DELETE_RUBRIC_CATEGORY_ERROR;
}

export interface DeleteRubricCategorySuccess {
  type: typeof DELETE_RUBRIC_CATEGORY_SUCCESS;
  payload: { id: string };
}

export type RubricCategoryDispatchTypes =
  | GetRubricCategoryLoading
  | GetRubricCategoryError
  | GetRubricCategorySuccess
  | AddRubricCategoryLoading
  | AddRubricCategoryError
  | AddRubricCategorySuccess
  | UpdateRubricCategoryLoading
  | UpdateRubricCategoryError
  | UpdateRubricCategorySuccess
  | DeleteRubricCategoryLoading
  | DeleteRubricCategoryError
  | DeleteRubricCategorySuccess;
