import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { DesignationTypeI } from "../../actions/HumanResource/Designation/DesignationActionTypes";
import { DesignationForm, DesignationTable, HRsidebar } from "../../components";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const DesignationPage = () => {
  const [formData, setFormData] = useState<DesignationTypeI | null>(null);
  const [count, setCount] = useState<number>(0);

  const fetchEditData = (data: DesignationTypeI) => {
    setFormData({ ...data, count: count });
  };
  const nav = <HRsidebar Designation />;
  return (
    <SubModuleLayout HumanMenuActive sideNav={nav}>
      <Grid container>
        <Grid item xs={3}>
          <DesignationForm editData={formData} />
        </Grid>
        <Grid item xs={9}>
          <DesignationTable onEditData={fetchEditData} />
        </Grid>
      </Grid>
    </SubModuleLayout>
  );
};

export default DesignationPage;
