import { Paper, Typography } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { HeadCellsI } from "../../../actions";
import { GetAdminSubUnit } from "../../../actions/Academics/AdminSyllabus/AdminSyllabusAction";
import { AdminSyllabusTypeI } from "../../../actions/Academics/AdminSyllabus/AdminSyllabusActionTypes";
import { RootStore } from "../../../store";
import { useTable } from "../../Reusable";
import { ItemViewButton } from "../../Reusable/Buttons/TableButton";
import Popups from "../../Reusable/Dialogs/Popups";
import { useTableStyles } from "../../Styles/TableStyles";
// import SyllabusViewUnit from "../../../dashboard/TeacherDashboard/pages/Syllabus/Componets/SyllabusViewUnit";

const headCells: HeadCellsI[] = [
  { id: "unit", label: "S.No." },
  { id: "name", label: "Unit Title" },
  { id: "teaching_hour", label: "Teaching Hour" },
  { id: "actions", label: "Actions" },
];

const AdminSyllabusTable = () => {
  const classes = useTableStyles();

  const [tableData, setTableData] = React.useState<any[]>([
    // {
    //   id: "1",
    //   name: "Our Solar System",
    //   teaching_hours: "4 hours",
    // },
    // {
    //   id: "2",
    //   name: "Animals",
    //   teaching_hours: "5 hours",
    // },
    // {
    //   id: "3",
    //   name: "Plants",
    //   teaching_hours: "5 hours",
    // },
    // {
    //   id: "4",
    //   name: "Evolution",
    //   teaching_hours: "7 hours",
    // },
  ]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [openViewModalPopup, setOpenViewModalPopup] = React.useState(false);
  const [item, setItem] = React.useState<AdminSyllabusTypeI>();
  const [indexN, setIndex] = React.useState<number>();

  // Retrieving re-usable components from useTable
  const { StyledTableCell, StyledTableRow, TableContainer } =
    useTable(headCells);

  const SyllabusSelector = useSelector(
    (state: RootStore) => state.admin_syllabus
  );

  React.useEffect(() => {
    const items = SyllabusSelector.add_unit;
    // items && setTableData(items);
  }, [SyllabusSelector]);

  const getCustomTableRow = (item: any, index: number) => {
    return (
      <StyledTableRow key={index} hover tabIndex={-1}>
        <StyledTableCell align="center" className={classes.newcell}>
          {index + 1}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.newcell}>
          {item.name}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.newcell}>
          {item.teaching_hours}
        </StyledTableCell>
        <StyledTableCell align="center" className={classes.newcell}>
          <ItemViewButton
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
              handleViewClicked(item, index)
            }
          />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  const handleViewModalClose = (value: boolean) => {
    setOpenViewModalPopup(value);
  };

  const handleViewClicked = (data: AdminSyllabusTypeI, index: number) => {
    setItem(data);
    setIndex(index + 1);
    setOpenViewModalPopup(true);
    // dispatch(GetAdminSubUnit(data.academic_class))
  };

  return (
    <>
      <Paper style={{ padding: "1rem" }}>
        <Typography variant="h6">Syllabus List</Typography>
        <TableContainer
          loading={loading}
          disableSearch
          items={tableData}
          headCells={headCells}
          getCustomTableRow={getCustomTableRow}
          rowsPerPage={7}
          search_name="name"
        />
      </Paper>
      <Popups
        openPopup={openViewModalPopup}
        setOpenPopup={setOpenViewModalPopup}
        onClose={handleViewModalClose}
        maxWidth="xs"
        title="View Unit"
        colored
      >
        {/* <SyllabusViewUnit admin={true} item={item} indexN={indexN} /> */}
      </Popups>
    </>
  );
};

export default AdminSyllabusTable;
