//----form Styles and use form styles ---------//

import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const fieldset = {
  "& .MuiOutlinedInput-root": {
    background: "rgba(0,0,0,0.05)",
    margin: "8px 0 2.4px ",
    "&.Mui-focused fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      borderColor: "transparent !important",
    },
    "& fieldset": {
      border: "none",
      fontSize: "12px",
    },
  },
};
export const fieldsetTable = {
  "& .MuiOutlinedInput-root": {
    margin: "8px 0 2.4px ",
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
  "&:hover fieldset": {
    borderColor: "transparent !important",
  },
  "& fieldset": {
    background: "rgba(0,0,0,0.05)",
    border: "none",
    fontSize: "12px",
  },
};
export const autoComplete = {
  "& .MuiAutocomplete-inputRoot": {
    padding: "0 30px 0 0 !important",
  },
};
// export const autocompleteInput = {
//   "& .MuiAutocomplete-input": {
//     fontSize: "14px",
//   },
// };
export const inputPlaceholder = {
  "& input::placeholder": {
    fontSize: "11px",
  },
};
export const formInput = {
  "& .MuiFormControlLabel-root": {
    marginLeft: "-5px !important",
  },
  "& input": {
    border: "none",
  },
  // ...inputPlaceholder,
  "& input::placeholder": {
    fontSize: "12px",
  },
};
// pageContent
export const pageContent = {
  ...autoComplete,
  ...inputPlaceholder,
  ...fieldset,
};

// Page Content Box
export const pageContentBox = {
  ...inputPlaceholder,
  "& .MuiButton-containedPrimary": {
    width: "116px",
  },
  ...fieldset,
};
export const form = {
  ...autoComplete,
  "& .MuiOutlinedInput-input": {
    padding: "8px !important",
    paddingLeft: "16px !important",
  },
  ...fieldset,
};

export const display = {
  display: "flex",
};

export const inputLabel = {
  "& .MuiInputLabel-root": {
    opacity: 1,
    marginLeft: "0px !important",
  },
};
// form Wrapper
export const formWrappers = {
  ...display,
  ...inputLabel,
  ...formInput,
  ...form,
};

export const formWrap = {
  ...display,
  "& .MuiOutlinedInput-root": {
    borderRadius: "4px 0 0 4px",
  },
  ...inputLabel,
};

// form Row
export const formRow = {
  ...display,

  "& .MuiInputLabel-root": {
    opacity: 1,
  },
};

// form title
export const formTitle = {
  "& span": {
    color: "#2E2E2E",
    fontWeight: 500,
  },
};

// validation Error Information
export const validationErrorInfo = {
  fontSize: "12px",
  color: "red",
};

// Nepali Date Input
export const nepaliDateInputs = {
  border: "1px solid #c4c4c4",
  borderRadius: "3px",
  height: "35px",
  width: "100%",
  marginRight: "0px",
  marginLeft: "0px",
  "&:hover": {
    border: "1px solid #132E98",
  },
  "&:focused": {
    border: "1px solid #132E98",
  },
};

// Search Box Button
export const searchBoxButton = {
  marginTop: "20px",
  width: "100%!important",
  padding: "8px 0",
};

export const classContent = {
  height: "300px",
  "& .MuiInputLabel-root": {
    paddingBottom: "10px",
    color: "#000",
  },
  "& .MuiAutocomplete-root": {
    paddingBottom: "15px",
  },
};

export const smsContent = {
  borderRight: "1px solid #ddd",
  ...inputPlaceholder,
  ...fieldset,
};

export const annoucementContent = {
  borderRight: "1px solid #ddd",
  ...inputPlaceholder,
  ...fieldset,
};

export const modalContent = {
  ...inputPlaceholder,
  ...fieldset,
};

export const uploadButton = {
  width: "100%",
  border: "1px solid",
  borderStyle: "dashed",
  borderColor: "#c4c4c4",
};

//---table styles ------------------//

export const tableIcon = {
  borderRadius: "6px",
  padding: "2px",
};

export const actionButton = {
  color: "#132E98",
  border: "1px solid rgba(19, 46, 152, 0.32)",
  padding: "2px",
  minWidth: "40px",
  cursor: "pointer",
  borderRadius: "0px",
};

export const rootStyle = {
  "& .MuiAutocomplete-inputRoot": {
    padding: "0 15px 0 0 !important",
  },
  "& .MuiAutocomplete-input": {
    fontSize: "13px",
    padding: "8px !important",
  },
  ...inputPlaceholder,
  "& .MuiFormControlLabel-label": {
    marginBottom: "0px",
  },
};
export const root = {
  ...rootStyle,
  ...fieldsetTable,
};

export const Root = {
  ...rootStyle,
  ...fieldsetTable,
};

export const rootform = {
  "& .MuiSvgIcon-fontSizeSmall": {
    display: "none",
  },
  "& .MuiSvgIcon-root": {
    width: "16px",
  },
  ...rootStyle,
  ...fieldsetTable,
};

export const rootTableBox = {
  ...autoComplete,
  ...fieldsetTable,
};

export const chip = {
  color: "white",
  height: "22px",
};

export const primary = {
  color: "#118FCF",
};

export const selectExp = {
  ...display,
  justifyContent: "center",
  listStyle: "none",
  margin: 0,
};

export const footerRoot = {
  padding: "10px",
  backgroundColor: "rgba(135, 159, 208, 0.08)",
  color: "#929292",
  width: "100%",
  height: "40px!important",
  left: 0,
  bottom: 0,
  overflow: "hidden",
};

export const checkedIcon = {
  backgroundColor: "rgb(19, 46, 152)",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "rgb(19, 46, 152)",
  },
};

export const icon = {
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: "#f5f8fa",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  "$root.Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: "rgba(206,217,224,.5)",
  },
};
