import { Grid, Paper } from "@material-ui/core";
import React, { useState } from "react";
import { ClassTypeI } from "../../actions/Academics/Class/ClassActionTypes";
import AdminSyllabusSelect from "../../components/Academic/AdminSyllabus/AdminSyllabusSelect";
import AdminSyllabusTable from "../../components/Academic/AdminSyllabus/AdminSyllabusTable";
import ClassLayout from "../../components/ClassRoom/Layout/ClassLayout";
import ClassRoomSidebar from "../../components/ClassRoom/Layout/ClassRoomSidebar";

const SyllabusPage = () => {
  const [classItem, setClassItem] = useState<ClassTypeI | null>(null);

  const onClassItem = (value: ClassTypeI) => {
    setClassItem(value);
  };

  const nav = <ClassRoomSidebar onClassItem={onClassItem} />;

  return (
    <div
      style={{ marginTop: "110px", paddingRight: "20px", minHeight: "80vh" }}
    >
      <ClassLayout sideNav={nav}>
        <Grid container>
          <Grid item xs={12}>
            <AdminSyllabusSelect classItem={classItem} />
            <AdminSyllabusTable />
          </Grid>
        </Grid>
      </ClassLayout>
    </div>
  );
};

export default SyllabusPage;
