import React from "react";
import { FinanceSidebar } from "../../components";
import AppliedFees from "../../components/Finance/AppliedFee/AppliedFees";
import SubModuleLayout from "../../components/Reusable/Layouts/SubModuleLayout";

const AppliedFeesPage = () => {
  const nav = <FinanceSidebar applied_fees />;
  return (
    <SubModuleLayout FeesMenuActive sideNav={nav}>
      <AppliedFees />
    </SubModuleLayout>
  );
};

export default AppliedFeesPage;
