import React, { useContext, useState, useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  InputAdornment,
  InputBase,
  Menu,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Link, Redirect, useHistory } from "react-router-dom";
import UserMenuPopover from "./UserMenuPopover/UserMenuPopover";
import NotificationPopover from "./NotificationPopover/NotificationPopover";
import QuickLinksPopover from "./QuickLinksPopover/QuickLinksPopover";
import { useDispatch, useSelector } from "react-redux";
import { SwitchBackToSchool } from "../../actions/SuperAdmin/School/SchoolAction";
import { RootStore } from "../../store";
import checkBrands from "../../config/checkBrands";
import nakshatraacademy from "../../assets/images/brands/nakshatraacademy.png";
import MenuIcon from "@material-ui/icons/Menu";
import styled from "styled-components";
import { Notifications as NotificationsIcon } from "@material-ui/icons";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import SearchIcon from "@material-ui/icons/Search";
import { alpha } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
// import { teacherDataSet } from '../../dashboard/TeacherDashboard/components/Nav/TeacherSidebar';
import { adminMainNavItems } from "../Navbar/NewNavbar";
import { ADMINISTRATOR_MAIN_ROUTE } from "../Navbar/RouteAdministrator";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { GetNotifications } from "../../actions/HumanResource/Notification/NotificationAction";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      height: "48px!important",
      minHeight: "auto",
      "& .MuiBadge-colorSecondary": {
        backgroundColor: "white",
        color: "#1640B4",
      },

      "& > header > div": {
        minHeight: "45px!important",
      },
    },
    appbar: {
      backgroundColor: "#132E98",
    },
    appbar1: {
      backgroundColor: "#EDD739",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      fontWeight: 700,
    },
    menubar: {
      display: "flex",
      justifyContent: "space-around",
    },
    menu: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    avatar: {
      margin: "12px",
    },
    ava: {
      objectFit: "contain",
      width: "40px",
    },
    link: {
      color: "white",
      textDecoration: "none",
      "& span": { fontWeight: 500 },
    },
    classbutton: {
      // height: "20px !important",
      opacity: 1,
      fontSize: "10px",
      backgroundColor: "#3FC581",
      color: "#fff",
      textTransform: "none",
      fontWeight: 600,
      borderRadius: "4px",
      padding: "6px 10px",
      minHeight: "20px",
      minWidth: "160px",
      marginRight: "16px",
      "& .MuiTab-root": {
        padding: 0,
        minWidth: "120px",
      },
      "&:hover": {
        backgroundColor: "#37a970",
      },
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      marginRight: 8,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
    },
  })
);

const StyledContainer = styled(Box)`
  padding: 16px;
`;

const StyledTitleHead = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledTitle = styled(Typography)`
  font-weight: 500;
  opacity: 0.65;

  &.MuiTypography-gutterBottom {
    margin-bottom: 8px;
  }
`;

const StyledItemContainer = styled(Link)`
  width: 100%;
  color: inherit;
  display: flex;
  margin-bottom: 16px;
  text-decoration: none;
  transition: 0.3s all ease;

  &:hover {
    opacity: 0.6;
  }

  &:last-child {
    margin-bottom: 0px;
  }
`;

const StyledIconContainer = styled(Box)`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(25, 25, 255, 0.2);
  margin-right: 8px;
  color: dodgerblue;
`;

const StyledTextContainer = styled(Box)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledItemTitle = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
`;

const StyledItemDescription = styled(Typography)`
  opacity: 0.6;
`;

const StyledHr = styled.hr`
  width: 2px;
  height: 30px;
  margin: 0px 4px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.1);
  border-color: transparent;
`;

const StyledAutocomplete = styled((props: any) => <Autocomplete {...props} />)`
  & .MuiFormControl-root {
    background: rgba(255, 255, 255, 0.99);
    border-radius: 8px;
    margin: 0 !important;

    & .MuiAutocomplete-inputRoot {
      padding: 0px;
      font-size: 14px;
      padding-left: 6px;

      & .MuiAutocomplete-input {
        width: 280px;
        transition: 0.3s all ease;

        &:focus {
          outline: none;
          border: none;
        }
      }
    }
  }
`;

const NotiHeader = styled(Box)`
  padding: 12px 16px;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.05);
`;

const NotiBody = styled(Box)`
  max-height: 400px;
  min-width: 380px;
  overflow-y: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const NotiFooter = styled(Box)`
  padding: 12px 16px;
  border-top: 1px dotted rgba(0, 0, 0, 0.05);
`;

const NotiItem = styled(Box)`
  padding: 8px 16px;
  border-right: 3px solid tomato;
  margin-bottom: 6px;
`;

const NotiItemSubtitle = styled(Typography)`
  padding: 12px 16px;
  display: block;
`;

const StyledButton = styled((props: any) => <Button {...props} />)`
  background: rgba(0, 0, 0, 0);

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }

  & .MuiButton-label {
    font-size: 15px;
    text-transform: initial;
    font-weight: 600;
    color: #132e98;
    opacity: 0.9;
  }
`;

export const formatNotification = (total: any, curNotification: any) => {
  const tempDate = moment(curNotification.created_on).format("YYYY-MM-DD");
  if (total[tempDate]) {
    total[tempDate] = [...total[tempDate], curNotification];
    return total;
  } else {
    total[tempDate] = [curNotification];
    return total;
  }
};

const Header = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [switched, setSwitched] = useState(false);
  const token = useSelector((state: RootStore) => state.auth);
  const [inFullscreen, setInFullscreen] = useState(false);
  const [notifications, setNotifications] = useState<any>(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSwitchBack = () => {
    dispatch(SwitchBackToSchool());
    setSwitched(true);
  };

  if (switched && !token.temp_hamro_token) {
    history.push("/login");
  }

  const isAdmin = localStorage.getItem("role") === "Administrator";
  const isTeacher = localStorage.getItem("role") === "Teacher";
  const isAccountant = localStorage.getItem("role") === "Accountant";

  const notificationState = useSelector(
    (state: RootStore) => state.notification
  );

  const updateNotification = (notifications: any) => {
    const tempNotifications = notifications.reduce(formatNotification, {});
    setNotifications(tempNotifications);
  };

  useEffect(() => {
    const { notifications } = notificationState;
    if (notifications) {
      updateNotification(notifications.results.slice(0, 5));
    }
  }, [notificationState]);

  const handleOpenFullscreen = () => {
    const elem = document.documentElement;

    if (!document.fullscreenElement && elem.requestFullscreen) {
      elem.requestFullscreen();
      setInFullscreen(true);
    } else {
      setInFullscreen(true);
    }
  };

  const handleCloseFullscreen = () => {
    // If in fullscreen, after exit set fullscreen false
    if (document.fullscreenElement && document.exitFullscreen) {
      document.exitFullscreen();
      setInFullscreen(false);
    } else {
      setInFullscreen(false);
    }
  };

  // const tempDataSet = teacherDataSet.reduce((total: any, c: any) => {
  //   if (c.dataSet) {
  //     total = [...total, ...c.dataSet];
  //   } else {
  //     total = [...total, c];
  //   }
  //   return total;
  // }, []);

  const tempAdminDataSet = ADMINISTRATOR_MAIN_ROUTE.reduce(
    (total: any, c: any) => {
      if (c.dataSet) {
        total = [
          ...total,
          ...c.dataSet.map((dSet: any) => ({ ...dSet, icon: c.icon })),
        ];
      } else {
        total = [...total, c];
      }
      return total;
    },
    []
  );

  const handleNotificationsClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
    dispatch(GetNotifications());
  };

  console.log("notifications", notifications);

  return (
    <div className={classes.root}>
      <AppBar
        // className={
        //   checkBrands("nakshatraacademy") ? classes.appbar1 : classes.appbar
        // }
        className={classes.appbar}
        elevation={1}
      >
        <Toolbar className={classes.menubar}>
          <Typography variant="h5" className={classes.title}>
            <Link to="/" className={classes.link}>
              {checkBrands("nakshatraacademy") ? (
                <>
                  {/* <img
                    src={nakshatraacademy}
                    style={{ height: "40px", width: "100px" }}
                  /> */}
                  <span>Nakshatra</span> Academy
                </>
              ) : (
                <>
                  <span>Hamro</span> Academy
                </>
              )}
            </Link>
          </Typography>

          <div className={classes.menu}>
            {localStorage.getItem("temp_role") === "Organization Admin" && (
              <Button
                style={{ backgroundColor: "white" }}
                onClick={handleSwitchBack}
              >
                Back to Organiazation
              </Button>
            )}

            {isAdmin === true && (
              <>
                <a
                  href="https://pustakalaya.org/"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: "#fff",
                    opacity: "1",
                    padding: "8px 10px",
                    fontSize: "10px",
                    minWidth: "160px",
                    minHeight: "20px",
                    fontWeight: "600",
                    marginRight: "16px",
                    borderRadius: "4px",
                    backgroundColor: "rgb(255, 87, 34)",
                    textDecoration: "none",
                    textAlign: "center",
                  }}
                >
                  E-Pustakalaya
                </a>
                <a
                  href="https://learning.cehrd.edu.np/"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: "#fff",
                    opacity: "1",
                    padding: "8px 10px",
                    fontSize: "10px",
                    minWidth: "160px",
                    minHeight: "20px",
                    fontWeight: "600",
                    marginRight: "16px",
                    borderRadius: "4px",
                    backgroundColor: "rgb(255, 87, 34)",
                    textDecoration: "none",
                    textAlign: "center",
                  }}
                >
                  CEHRD PORTAL
                </a>
                <Button
                  component={Link}
                  to="/classroom/online-class"
                  className={classes.classbutton}
                >
                  Go To Classroom
                </Button>
              </>
            )}

            <div>
              {localStorage.getItem("role") === "Teacher" && (
                <StyledAutocomplete
                  freeSolo
                  clearOnEscape
                  options={[]}
                  getOptionLabel={(option: any) => option.text}
                  renderOption={(option: any) => {
                    const Icon = option.icon;
                    return (
                      <StyledItemContainer to={option.url}>
                        <StyledIconContainer>
                          <Icon />
                        </StyledIconContainer>

                        <StyledTextContainer>
                          <StyledItemTitle>{option.text}</StyledItemTitle>
                          <StyledItemDescription>
                            {option.url}
                          </StyledItemDescription>
                        </StyledTextContainer>
                      </StyledItemContainer>
                    );
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      placeholder="Search..."
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              )}

              {localStorage.getItem("role") === "Administrator" && (
                <StyledAutocomplete
                  freeSolo
                  options={tempAdminDataSet}
                  getOptionLabel={(option: any) => option.text}
                  renderOption={(option: any) => {
                    const Icon = option.icon;
                    return (
                      <StyledItemContainer
                        onClick={(event: React.MouseEvent<{}>) => {
                          // event.stopPropagation();
                        }}
                        to={option.url}
                      >
                        <StyledIconContainer>
                          <Icon />
                        </StyledIconContainer>

                        <StyledTextContainer>
                          <StyledItemTitle>{option.text}</StyledItemTitle>
                          <StyledItemDescription>
                            {option.url}
                          </StyledItemDescription>
                        </StyledTextContainer>
                      </StyledItemContainer>
                    );
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      placeholder="Search HamroAcademy..."
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              )}
            </div>

            {isAccountant ? null : <QuickLinksPopover />}

            {isAdmin ? <NotificationPopover /> : null}

            {/* <Tooltip title="See notifications">
              <IconButton color="inherit" onClick={handleNotificationsClick}>
                <NotificationsIcon />
              </IconButton>
            </Tooltip>

            <Menu
              id="notifications-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              style={{ overflow: 'hidden' }}
            >
              <NotiHeader>
                <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
                  Notifications
                </Typography>
              </NotiHeader>

              <NotiBody>
                {notifications &&
                  Object.keys(notifications).map((notification: any) => {
                    const tempNotifications = notifications[notification];

                    return (
                      <Box key={notification}>
                        <NotiItemSubtitle>{notification}</NotiItemSubtitle>
                        {tempNotifications.map(
                          (tempNoti: any, index: number) => (
                            <NotiItem key={index}>
                              <Typography
                                variant="subtitle2"
                                style={{ fontWeight: 400 }}
                              >
                                {tempNoti.text}
                              </Typography>
                              <Typography
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <AccessTimeIcon
                                  style={{ fontSize: 14, marginRight: 4 }}
                                />
                                {moment(tempNoti.created_on).fromNow()}
                              </Typography>
                            </NotiItem>
                          )
                        )}
                      </Box>
                    );
                  })}
              </NotiBody>

              <NotiFooter>
                <StyledButton
                  component={Link}
                  to="/notifications"
                  variant="contained"
                  fullWidth
                >
                  See All
                </StyledButton>
              </NotiFooter>
            </Menu> */}

            {inFullscreen ? (
              <Tooltip title="Exit fullscreen">
                <IconButton color="inherit" onClick={handleCloseFullscreen}>
                  <FullscreenExitIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="View fullscreen">
                <IconButton color="inherit" onClick={handleOpenFullscreen}>
                  <FullscreenIcon />
                </IconButton>
              </Tooltip>
            )}

            <StyledHr />
            <UserMenuPopover />
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
