export const GET_GRADESHEET_TEMPLATE_LOADING =
  "GET_GRADESHEET_TEMPLATE_LOADING";
export const GET_GRADESHEET_TEMPLATE_ERROR = "GET_GRADESHEET_TEMPLATE_ERROR";
export const GET_GRADESHEET_TEMPLATE_SUCCESS =
  "GET_GRADESHEET_TEMPLATE_SUCCESS";

export const ADD_GRADESHEET_TEMPLATE_LOADING =
  "ADD_GRADESHEET_TEMPLATE_LOADING";
export const ADD_GRADESHEET_TEMPLATE_ERROR = "ADD_GRADESHEET_TEMPLATE_ERROR";
export const ADD_GRADESHEET_TEMPLATE_SUCCESS =
  "ADD_GRADESHEET_TEMPLATE_SUCCESS";

export const ACTIVATE_OR_DEACTIVATE_GRADESHEET_TEMPLATE_LOADING =
  "ACTIVATE_OR_DEACTIVATE_GRADESHEET_TEMPLATE_LOADING";
export const ACTIVATE_OR_DEACTIVATE_GRADESHEET_TEMPLATE_ERROR =
  "ACTIVATE_OR_DEACTIVATE_GRADESHEET_TEMPLATE_ERROR";
export const ACTIVATE_OR_DEACTIVATE_GRADESHEET_TEMPLATE_SUCCESS =
  "ACTIVATE_OR_DEACTIVATE_GRADESHEET_TEMPLATE_SUCCESS";

export const GET_SINGLE_GRADESHEET_TEMPLATE_LOADING =
  "GET_SINGLE_GRADESHEET_TEMPLATE_LOADING";
export const GET_SINGLE_GRADESHEET_TEMPLATE_ERROR =
  "GET_SINGLE_GRADESHEET_TEMPLATE_ERROR";
export const GET_SINGLE_GRADESHEET_TEMPLATE_SUCCESS =
  "GET_SINGLE_GRADESHEET_TEMPLATE_SUCCESS";

export const UPDATE_GRADESHEET_TEMPLATE_LOADING =
  "UPDATE_GRADESHEET_TEMPLATE_LOADING";
export const UPDATE_GRADESHEET_TEMPLATE_ERROR =
  "UPDATE_GRADESHEET_TEMPLATE_ERROR";
export const UPDATE_GRADESHEET_TEMPLATE_SUCCESS =
  "UPDATE_GRADESHEET_TEMPLATE_SUCCESS";

export const DELETE_GRADESHEET_TEMPLATE_LOADING =
  "DELETE_GRADESHEET_TEMPLATE_LOADING";
export const DELETE_GRADESHEET_TEMPLATE_ERROR =
  "DELETE_GRADESHEET_TEMPLATE_ERROR";
export const DELETE_GRADESHEET_TEMPLATE_SUCCESS =
  "DELETE_GRADESHEET_TEMPLATE_SUCCESS";

export type ColorI = {
  key: string;
  value: string;
  name: string;
};

export type TemplateDataI = {
  ape_enable: boolean;
  footer_title: string;
  footer_text_1: string;
  footer_text_2: string;
  footer_text_3: string;
  thumbnail: string;
  watermark: boolean;
  school_logo: boolean;
  school_photo: boolean;
  watermark_position: string;
  school_logo_position: string;
  school_photo_position: string;
  grading_system: boolean;
};

//===============================<START>GET CLASS<START>======================//

export interface GetGradeSheetTemplateLoading {
  type: typeof GET_GRADESHEET_TEMPLATE_LOADING;
}

export interface GetGradeSheetTemplateError {
  type: typeof GET_GRADESHEET_TEMPLATE_ERROR;
}

export interface GetGradeSheetTemplateSuccess {
  type: typeof GET_GRADESHEET_TEMPLATE_SUCCESS;
  payload: TemplateDataI[];
}

//===============================<END>GET CLASS<END>======================//

//===============================<START>GET CLASS<START>======================//

export interface GetSingleGradeSheetTemplateLoading {
  type: typeof GET_SINGLE_GRADESHEET_TEMPLATE_LOADING;
}

export interface GetSingleGradeSheetTemplateError {
  type: typeof GET_SINGLE_GRADESHEET_TEMPLATE_ERROR;
}

export interface GetSingleGradeSheetTemplateSuccess {
  type: typeof GET_SINGLE_GRADESHEET_TEMPLATE_SUCCESS;
  payload: any;
}

//===============================<END>GET CLASS<END>======================//

//===============================<START>GET CLASS<START>======================//

export interface UpdateGradeSheetTemplateLoading {
  type: typeof UPDATE_GRADESHEET_TEMPLATE_LOADING;
}

export interface UpdateGradeSheetTemplateError {
  type: typeof UPDATE_GRADESHEET_TEMPLATE_ERROR;
}

export interface UpdateGradeSheetTemplateSuccess {
  type: typeof UPDATE_GRADESHEET_TEMPLATE_SUCCESS;
  payload: any;
}

//===============================<END>GET CLASS<END>======================//

//===============================<START>ADD CLASS<START>======================//

export interface AddGradeSheetTemplateLoading {
  type: typeof ADD_GRADESHEET_TEMPLATE_LOADING;
}

export interface AddGradeSheetTemplateError {
  type: typeof ADD_GRADESHEET_TEMPLATE_ERROR;
}

export interface AddGradeSheetTemplateSuccess {
  type: typeof ADD_GRADESHEET_TEMPLATE_SUCCESS;
  payload: TemplateDataI;
}

//===============================<END>ADD CLASS<END>======================//

//===============================<START>UPDATE CLASS<START>======================//

export interface ActivateOrDeactivateGradeSheetTemplateLoading {
  type: typeof ACTIVATE_OR_DEACTIVATE_GRADESHEET_TEMPLATE_LOADING;
}

export interface ActivateOrDeactivateGradeSheetTemplateError {
  type: typeof ACTIVATE_OR_DEACTIVATE_GRADESHEET_TEMPLATE_ERROR;
}

export interface ActivateOrDeactivateGradeSheetTemplateSuccess {
  type: typeof ACTIVATE_OR_DEACTIVATE_GRADESHEET_TEMPLATE_SUCCESS;
  payload: any;
}

//===============================<END>UPDATE CLASS<END>======================//

//===============================<START>GET CLASS<START>======================//

export interface DeleteGradeSheetTemplateLoading {
  type: typeof DELETE_GRADESHEET_TEMPLATE_LOADING;
}

export interface DeleteGradeSheetTemplateError {
  type: typeof DELETE_GRADESHEET_TEMPLATE_ERROR;
}

export interface DeleteGradeSheetTemplateSuccess {
  type: typeof DELETE_GRADESHEET_TEMPLATE_SUCCESS;
  payload: any;
}

//===============================<END>GET CLASS<END>======================//

export type GradeSheetTemplateDispatchTypes =
  | GetGradeSheetTemplateLoading
  | GetGradeSheetTemplateError
  | GetGradeSheetTemplateSuccess
  | GetSingleGradeSheetTemplateLoading
  | GetSingleGradeSheetTemplateError
  | GetSingleGradeSheetTemplateSuccess
  | AddGradeSheetTemplateLoading
  | AddGradeSheetTemplateError
  | AddGradeSheetTemplateSuccess
  | ActivateOrDeactivateGradeSheetTemplateLoading
  | ActivateOrDeactivateGradeSheetTemplateError
  | ActivateOrDeactivateGradeSheetTemplateSuccess
  | DeleteGradeSheetTemplateLoading
  | DeleteGradeSheetTemplateError
  | DeleteGradeSheetTemplateSuccess
  | UpdateGradeSheetTemplateLoading
  | UpdateGradeSheetTemplateError
  | UpdateGradeSheetTemplateSuccess;
