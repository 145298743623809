import SaveIcon from "@material-ui/icons/Save";
import { FormSaveLoadingButton } from '../../Reusable/Buttons/FormButton';
import Spinner from '../../../common/spinnerSVG/Spinner'

const StaffSearchForm = (props: any) => {
  const { handleApplyLeave, isBtnLoading } = props;

  return (
    <FormSaveLoadingButton
      onClick={handleApplyLeave}
      style={{ marginBottom: 15 }}
      icon={isBtnLoading ? <Spinner /> : <SaveIcon />}
      disabled={isBtnLoading ? true : false}
      text={isBtnLoading ? "Applying..." : "Apply"}
    >
      Apply Leaves
    </FormSaveLoadingButton>
  );
};
export default StaffSearchForm;
