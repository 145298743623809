import axios from "axios";
import { Dispatch } from "redux";

import {
  GENERATE_RESULT_SMS_LOADING,
  GENERATE_RESULT_SMS_SUCCESS,
  GENERATE_RESULT_SMS_ERROR,
  SEND_RESULT_SMS_LOADING,
  SEND_RESULT_SMS_ERROR,
  SEND_RESULT_SMS_SUCCESS,
  GenerateResultSmsDispatchTypes,
} from "./SendResultActionTypes";

import { SET_SNACKBAR, SetSnackBarI } from "../../SnackbarActionTypes";
import { HeaderConfig } from "../../Auth/Login/LoginAction";
import { ADMIN_API_URL } from "../../../config/SERVER_API_URLS";

interface SmsRequestDataI {
  exam_info: number;
  gpa: boolean;
  percentage: boolean;
  position: boolean;
  all_grades: boolean;
  student_academic_id: number[];
}

interface RequestDataI {
  exam_info: number;
  students: number[];
}

export const GenerateResultSms =
  (data: RequestDataI) =>
  async (dispatch: Dispatch<GenerateResultSmsDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: GENERATE_RESULT_SMS_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/save/student/mark-sheet/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: GENERATE_RESULT_SMS_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Saved Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: GENERATE_RESULT_SMS_ERROR,
      });
      try {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: error.response.statusText,
          },
        });
      } catch (error) {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Server Error",
          },
        });
      }
    }
  };

export const SendResultSms =
  (data: SmsRequestDataI) =>
  async (dispatch: Dispatch<GenerateResultSmsDispatchTypes | SetSnackBarI>) => {
    try {
      dispatch({
        type: SEND_RESULT_SMS_LOADING,
      });

      const res = await axios.post(
        `${ADMIN_API_URL}/send/student/result/`,
        data,
        HeaderConfig()
      );

      dispatch({
        type: SEND_RESULT_SMS_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Sent Successfully",
        },
      });
    } catch (error: any) {
      dispatch({
        type: SEND_RESULT_SMS_ERROR,
      });
      try {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: error.response.statusText,
          },
        });
      } catch (error) {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Server Error",
          },
        });
      }
    }
  };
