// ---------------------------- module imports start ----------------------------------//
import { Grid, InputLabel, Paper } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useStyles } from '../../Styles/FormStyles';
import { RootStore } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import {
  GetReportSetting,
  AddReportSetting,
  UpdateReportSetting,
} from '../../../actions/GeneralSettings/ReportSetting/ReportSettingAction';
import { CusRadioGroupInt } from '../../Reusable/Inputs/Radio';
import { ImageUpload } from '../../Reusable';
import checkImageUrl from '../../utils/checkImageUrl';
import { MultipleImageUpdate } from '../../utils/MultipleImageUpdate';
import { FormSaveLoadingButton } from '../../Reusable/Buttons/FormButton';
import LoaderIcon from '../../Reusable/LoarderIcon';
import { SET_SNACKBAR } from '../../../actions/SnackbarActionTypes';

// ---------------------------- module imports ends ----------------------------------//

// ---------------------------- interface starts ----------------------------------//

interface InputFormI {
  id?: any;
  active_signature?: string;
}

interface resultTypeChoicesI {
  title: string;
  value: string;
}

// ---------------------------- interface ends ----------------------------------//

const ReportSettingForm = () => {
  const classes = useStyles();

  // ---- States Declaration ------ //
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCreating, SetIsCreating] = useState(false);
  const [isUpdating, SetIsUpdating] = useState(false);
  const [watermark, setWatermark] = useState<FileReader | any | null>('');
  const [principal, setPrincipal] = useState<FileReader | any | null>('');
  const [vicePrincipal, setVicePrincipal] = useState<FileReader | any | null>(
    ''
  );
  const [selectedRadioValue, setSelectedRadioValue] = useState<any>('');

  const dispatch = useDispatch();

  const report_setting = useSelector(
    (state: RootStore) => state.report_setting?.reportsetting
  );

  const activeOptions = [
    { id: 1, name: 'Principal' },
    { id: 2, name: 'Vice-Principal' },
  ];

  const add_or_update = useSelector(
    (state: RootStore) => state.report_setting.add_or_update
  );

  useEffect(() => {
    if (add_or_update) {
      setIsLoading(false);
    }
  }, [add_or_update]);

  useEffect(() => {
    dispatch(GetReportSetting());
  }, []);

  useEffect(() => {
    if (report_setting?.length) {
      handleFormInitialize(report_setting[0]);
      SetIsUpdating(true);
      SetIsCreating(false);
    } else {
      SetIsCreating(true);
      SetIsUpdating(false);
    }
  }, [report_setting]);

  //Initializing form data
  const handleFormInitialize = (data: any) => {
    setWatermark(checkImageUrl(data?.watermark));
    setPrincipal(checkImageUrl(data?.principal_sign));
    setVicePrincipal(checkImageUrl(data?.vice_principal_sign));
    setSelectedRadioValue(data?.active_signature === 'V' ? 2 : 1);
  };

  const { register, handleSubmit, errors } = useForm<any>({
    mode: 'onChange',
  });

  const onSubmit = () => {
    if (selectedRadioValue != '') {
      setIsLoading(true);
      //for create case
      if (isCreating) {
        const finalValues: any = {
          active_signature: selectedRadioValue === 1 ? 'P' : 'V',
          watermark: watermark,
          principal_sign: principal,
          vice_principal_sign: vicePrincipal,
        };
        dispatch(AddReportSetting(finalValues));
      }
      //for update case
      if (isUpdating) {
        const reMake: any = MultipleImageUpdate({
          watermark: watermark,
          principal_sign: principal,
          vice_principal_sign: vicePrincipal,
        });
        const reFinal = {
          ...reMake,
          active_signature: selectedRadioValue === 1 ? 'P' : 'V',
        };
        dispatch(UpdateReportSetting(report_setting[0]?.id, reFinal));
      }
    } else {
      dispatch({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: 'Active signature must be selected.',
        },
      });
    }
  };

  return (
    <>
      <Paper className={classes.pageContent}>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={12} className={classes.formTitle}>
              <span>Reports Setting</span>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={4} className={classes.formWrapper}>
              <InputLabel>Upload Watermark</InputLabel>
              <ImageUpload
                image={watermark}
                setImage={setWatermark}
                id="upload-input1"
              />
            </Grid>
            <Grid item xs={4} className={classes.formWrapper}>
              <InputLabel>Upload Principal Signature</InputLabel>
              <ImageUpload
                image={principal}
                setImage={setPrincipal}
                id="upload-input2"
              />
            </Grid>
            <Grid item xs={4} className={classes.formWrapper}>
              <InputLabel>Upload Vice-Principal Signature</InputLabel>
              <ImageUpload
                image={vicePrincipal}
                setImage={setVicePrincipal}
                id="upload-input3"
              />
            </Grid>
            <Grid item xs={4} className={classes.formWrapper}>
              <CusRadioGroupInt
                label="Current Active Signature"
                required
                items={activeOptions}
                value={selectedRadioValue}
                setValue={setSelectedRadioValue}
                onValue={false}
              />
            </Grid>

            <Grid style={{ margin: '9px' }} container justifyContent="flex-end">
              <FormSaveLoadingButton
                type="submit"
                icon={isLoading && <LoaderIcon />}
                disabled={isLoading ? true : false}
                text={isLoading ? 'Saving' : 'Save'}
              />
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
};

export default ReportSettingForm;
