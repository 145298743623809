import React from "react";
import { ReportSideBar } from "../../../../components";
import BrowseCharacterCertificate from "../../../../components/Reports/Templates/CharacterCertificate/BrowseCharacterCertificate";
import SubModuleLayout from "../../../../components/Reusable/Layouts/SubModuleLayout";

const BrowseCharacterCertificatePage = () => {
  const nav = <ReportSideBar character_certificate_template />;
  return (
    <SubModuleLayout reportMenuActive sideNav={nav}>
      <BrowseCharacterCertificate />
    </SubModuleLayout>
  );
};

export default BrowseCharacterCertificatePage;
