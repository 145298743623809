export const GET_SUBJECT_LOADING = "GET_SUBJECT_LOADING";
export const GET_SUBJECT_ERROR = "GET_SUBJECT_ERROR";
export const GET_SUBJECT_SUCCESS = "GET_SUBJECT_SUCCESS";

export const ADD_SUBJECT_LOADING = "ADD_SUBJECT_LOADING";
export const ADD_SUBJECT_ERROR = "ADD_SUBJECT_ERROR";
export const ADD_SUBJECT_SUCCESS = "ADD_SUBJECT_SUCCESS";

export const UPDATE_SUBJECT_LOADING = "UPDATE_SUBJECT_LOADING";
export const UPDATE_SUBJECT_ERROR = "UPDATE_SUBJECT_ERROR";
export const UPDATE_SUBJECT_SUCCESS = "UPDATE_SUBJECT_SUCCESS";

export const DELETE_SUBJECT_LOADING = "DELETE_SUBJECT_LOADING";
export const DELETE_SUBJECT_ERROR = "DELETE_SUBJECT_ERROR";
export const DELETE_SUBJECT_SUCCESS = "DELETE_SUBJECT_SUCCESS";

export type SubjectTypeI = {
  id: string;
  name: string;
  subject_code: string;
  created_by: string;
  general_info_name: string;
  count?: number;
};

//===============================<START>GET SUBJECT<START>======================//

export interface GetSubjectLoading {
  type: typeof GET_SUBJECT_LOADING;
}

export interface GetSubjectError {
  type: typeof GET_SUBJECT_ERROR;
}

export interface GetSubjectSuccess {
  type: typeof GET_SUBJECT_SUCCESS;
  payload: SubjectTypeI[];
}

//===============================<END>GET SUBJECT<END>======================//

//===============================<START>ADD SUBJECT<START>======================//

export interface AddSubjectLoading {
  type: typeof ADD_SUBJECT_LOADING;
}

export interface AddSubjectError {
  type: typeof ADD_SUBJECT_ERROR;
  payload: any;
}

export interface AddSubjectSuccess {
  type: typeof ADD_SUBJECT_SUCCESS;
  payload: SubjectTypeI;
}

//===============================<END>ADD SUBJECT<END>======================//

//===============================<START>UPDATE SUBJECT<START>======================//

export interface UpdateSubjectLoading {
  type: typeof UPDATE_SUBJECT_LOADING;
}

export interface UpdateSubjectError {
  type: typeof UPDATE_SUBJECT_ERROR;
  payload: any;
}

export interface UpdateSubjectSuccess {
  type: typeof UPDATE_SUBJECT_SUCCESS;
  payload: SubjectTypeI;
}

//===============================<END>UPDATE SUBJECT<END>======================//

//===============================<START>DELETE SUBJECT<START>======================//

export interface DeleteSubjectLoading {
  type: typeof DELETE_SUBJECT_LOADING;
}

export interface DeleteSubjectError {
  type: typeof DELETE_SUBJECT_ERROR;
}

export interface DeleteSubjectSuccess {
  type: typeof DELETE_SUBJECT_SUCCESS;
  payload: { id: string };
}

//===============================<END>DELETE SUBJECT<END>======================//

export type SubjectDispatchTypes =
  | GetSubjectLoading
  | GetSubjectError
  | GetSubjectSuccess
  | AddSubjectLoading
  | AddSubjectError
  | AddSubjectSuccess
  | UpdateSubjectLoading
  | UpdateSubjectError
  | UpdateSubjectSuccess
  | DeleteSubjectLoading
  | DeleteSubjectError
  | DeleteSubjectSuccess;
