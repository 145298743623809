// ---------------------------- <START> module imports start ----------------------------------//
import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Paper,
  TableBody,
  TableHead,
  Typography,
  createStyles,
  Table,
  Theme,
  makeStyles,
  Chip,
} from "@material-ui/core";
import { useTableStyles } from "../../Styles/TableStyles";
import { useTable } from "../../Reusable";
import CustomizedDialogs from "../../Reusable/Dialogs/DeleteDialog";
import { FineTypeI } from "../../../actions/Fees/FineType/FineActionTypes";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";
import { DeleteFine, GetFine } from "../../../actions/Fees/FineType/FineAction";
import {
  ItemDeleteButton,
  ItemEditButton,
} from "../../Reusable/Buttons/TableButton";
import getMonthName from "../../utils/getMonthName";
import { AnyAction } from "redux";
import {
  dateConverterAdToBs,
  dateConverterBsToAd,
} from "../../utils/dateConverter";
import { GetStudentSiblings } from "../../../actions/Student/Student/StudentAction";
import {
  GetInvoiceHistory,
  GetInvoiceHistoryMonthWise,
} from "../../../actions/Fees/CollectFees/CollectFeesAction";
import ReactToPrint from "react-to-print";
import getFullName from "../../utils/getFullName";
import { MONTH_CHOICES } from "../../../common/json.constant";
import CustomizedSelect from "../../Reusable/Inputs/Select";
import { GetDates } from "../../../actions/Dates/DatesAction";
// ----------------------------<END> module imports end ----------------------------------//

// ---------------------------- <START> interface starts ----------------------------------//
interface PropsI {
  onEditData: (value: FineTypeI) => void;
  editCheck: { check: boolean; count: number };
}

interface HeadCellsI {
  id: string;
  label: string;
}
// ---------------------------- <END> interface ends ----------------------------------//

// ---------------------------- <START> styling starts ----------------------------------//

// ---------------------------- <END> styling ends ----------------------------------//

// ---------------------------- <START> Table Headings Data ----------------------------------//
const headCells: HeadCellsI[] = [
  { id: "sn", label: "S.N." },
  { id: "date", label: "Date" },
  { id: "transaction_type", label: "Transaction Type" },
  { id: "fee_head", label: "Fees Head" },
  { id: "period", label: "Period" },
  { id: "charges", label: "Charges" },
  { id: "fine", label: "Fine" },
  { id: "balance", label: "Balance" },
  { id: "status", label: "Status" },
];

const useDataGridStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      "& th": {
        backgroundColor: "#e3e3e3",
      },
      "& .MuiTableCell-sizeSmall": {
        padding: "2px 2px 0px 2px",
        borderBottom: "0px",
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    blue: {
      backgroundColor: "#1f4498",
      color: "white",
    },
  })
);
// ---------------------------- <END> Table Headings Data Ends ----------------------------------//

// ---------------------------- <START> fine Type Table Component Starts ---------------------------//
const CollectFeeStatement = (props: any) => {
  const { statementTableData, feesHead, student } = props;

  const classes = useTableStyles();
  const tableCss = useDataGridStyles();

  // const { onEditData, editCheck } = props;

  // payment method reducer
  const dispatch = useDispatch();
  const fineTypeState = useSelector((state: RootStore) => state.fine_type);

  // Modal States
  const [loading, setLoading] = useState<boolean>(false);
  const [editMode, setEditMode] = useState(false);
  const [tableData, setTableData] = useState<FineTypeI[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string>("");
  const [totalPaid, setTotalPaid] = useState<number>(0);
  const [totalBalance, setTotalBalance] = useState<number>(0);
  const [studentSiblings, setStudentSiblings] = useState<any>([]);
  const [statementData, setStatementData] = useState<any>([]);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [month, setMonth] = useState<any | null>(null);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  const student_siblings = useSelector(
    (state: RootStore) => state.student?.student_siblings
  );
  const feesHeadForStatements = useSelector(
    (state: RootStore) => state.collect_fees?.collect_fees
  );

  const dateSelector = useSelector(
    (state: RootStore) => state.month_dates.data
  );

  const componentRef = useRef<any>();

  useEffect(() => {
    setActiveIndex(0);
    setStudentSiblings([]);
  }, []);

  window.onbeforeprint = function () {
    console.log("This will be called before the user prints.");
  };
  window.onafterprint = function () {
    console.log("This will be called after the user prints");
  };

  useEffect(() => {
    if (dateSelector.dates != null) {
      const from_date = dateConverterBsToAd(dateSelector.dates[0]);
      const to_date = dateConverterBsToAd(
        dateSelector.dates[dateSelector.dates.length - 1]
      );
      setStartDate(from_date);
      setEndDate(to_date);
    }
  }, [dateSelector.dates, month]);

  useEffect(() => {
    if (startDate !== "" && endDate !== "") {
      dispatch(
        GetInvoiceHistoryMonthWise(
          student?.academic_id,
          month?.key,
          startDate,
          endDate
        )
      );
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (student !== null) {
      dispatch(GetFine());
      dispatch(GetStudentSiblings(student?.id));
      setActiveIndex(0);
      setStudentSiblings([]);
    }
    {
      setStudentSiblings([]);
    }
  }, [student]);

  useEffect(() => {
    if (student_siblings?.length > 0) {
      setStudentSiblings(student_siblings);
    }
  }, [student_siblings]);

  useEffect(() => {
    if (statementTableData !== null) {
      setStatementData(statementTableData);
    }
  }, [statementTableData]);

  useEffect(() => {
    if (statementData !== null) {
      const paids = statementData.filter((elem: any) => elem.status === "Paid");
      let count = 0;
      paids?.map((elem: any) => {
        count += Number(elem.balance);
      });
      setTotalPaid(count);

      const unpaids = statementData.filter(
        (elem: any) => elem.status === "Unpaid"
      );
      let counts = 0;
      unpaids?.map((elem: any) => {
        counts += Number(elem.balance);
      });
      setTotalBalance(counts);
    }
  }, [statementData]);

  useEffect(() => {
    const dataLength = fineTypeState.fine.length;

    setLoading(fineTypeState.loading);
    // setTableData(fineTypeState.fine);
  }, [fineTypeState]);

  // States declaration

  // -----------------<END> Pagination states and functions <END>-----------------------//

  // Retrieving re-usable components from useTable
  const {
    TblContainer,
    TblHead,
    StyledTableCell,
    StyledTableRow,
    exportToExcel,
    tablePrint,
  } = useTable(headCells);

  // Function for handling action icons click event

  // const handleEditClicked = (data: FineTypeI) => {
  //     onEditData(data);
  //     setEditMode(true);
  // };
  const handleModalClose = (value: boolean) => {
    setDeleteModalOpen(value);
  };

  const handleDeleteModal = (value: boolean, id: string) => {
    setItemId(id);
    setDeleteModalOpen(value);
  };

  const onDeleteConfirmed = (confirmed: boolean) => {
    confirmed && itemId != null && dispatch(DeleteFine(itemId));
    setDeleteModalOpen(false);
  };

  const handleChipActive = (value: number) => {
    setActiveIndex(value);
    value !== 0
      ? dispatch(
          GetInvoiceHistory(
            studentSiblings && studentSiblings[value - 1]?.academic_info[0]?.id
          )
        )
      : dispatch(GetInvoiceHistory(student?.academic_id));
  };

  const handleMonthChange = (value: any) => {
    if (value != null) {
      setMonth(value);
      dispatch(GetDates(value.key));
    } else {
      dispatch(GetInvoiceHistory(student?.academic_id));
      setMonth(null);
    }
  };

  return (
    <div style={{ padding: "0px 17px 0px 0px" }}>
      <section style={{ display: "flex", justifyContent: "flex-end" }}>
        <ReactToPrint
          trigger={() => (
            <p
              style={{
                backgroundColor: "#132E98",
                color: "white",
                fontWeight: "bold",
                borderRadius: "0px",
                margin: "0px",
                textAlign: "center",
                padding: "5px 0px",
                cursor: "pointer",
                width: "200px",
              }}
            >
              Print This Statement
            </p>
          )}
          content={() => componentRef.current}
          removeAfterPrint={true}
          copyStyles={true}
        />
      </section>
      {/* <div id="print-element" ref={componentRef} style={{ padding: '15px 10px', margin: '20px' }}> */}
      <Grid
        container
        style={{
          margin: "20px 0px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ marginLeft: "40px" }}>
          <Grid container>
            <Grid item style={{ marginRight: "10px" }}>
              {student !== null && (
                <Chip
                  variant="default"
                  color="default"
                  label={getFullName(
                    student?.student_first_name,
                    student?.student_last_name
                  )}
                  onClick={() => handleChipActive(0)}
                  style={{
                    backgroundColor: activeIndex === 0 ? "#1f4498" : "",
                    color: activeIndex === 0 ? "white" : "",
                  }}
                />
              )}
            </Grid>
            {studentSiblings?.map((elem: any, index: number) => {
              return (
                <Grid key={index} item style={{ marginRight: "10px" }}>
                  <Chip
                    variant="default"
                    color="default"
                    label={elem.full_name}
                    onClick={() => handleChipActive(index + 1)}
                    style={{
                      backgroundColor:
                        activeIndex === index + 1 ? "#1f4498" : "",
                      color: activeIndex === index + 1 ? "white" : "",
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </div>
        <div>
          <b style={{ paddingRight: "10px" }}>Opening Balance:</b>
          Debit: Rs. {feesHead?.opening_balance?.debit || 0} | Credit: Rs.{" "}
          {feesHead?.opening_balance?.credit || 0}
        </div>
      </Grid>
      {student !== null && (
        <div style={{ width: "180px", paddingLeft: "50px" }}>
          <CustomizedSelect
            label="Month"
            name="month"
            options={MONTH_CHOICES}
            value={month}
            setValue={handleMonthChange}
          />
        </div>
      )}
      <div
        id="print-element"
        ref={componentRef}
        style={{ paddingBottom: "40px", padding: "15px 10px", margin: "20px" }}
      >
        <Table
          stickyHeader
          className={tableCss.table}
          aria-label="sticky table"
        >
          <TableHead>
            <StyledTableRow>
              <StyledTableCell align="center" className={classes.cell}>
                S.N.
              </StyledTableCell>
              <StyledTableCell align="left" className={classes.cell}>
                Date
              </StyledTableCell>
              <StyledTableCell align="left" className={classes.cell}>
                Transaction Type
              </StyledTableCell>
              <StyledTableCell align="left" className={classes.cell}>
                Fee Head
              </StyledTableCell>
              <StyledTableCell align="left" className={classes.cell}>
                Period
              </StyledTableCell>
              <StyledTableCell align="left" className={classes.cell}>
                Charges
              </StyledTableCell>
              <StyledTableCell align="left" className={classes.cell}>
                Fine
              </StyledTableCell>
              <StyledTableCell align="left" className={classes.cell}>
                Balance
              </StyledTableCell>
              <StyledTableCell align="left" className={classes.cell}>
                Status
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          {student !== null && (
            <>
              <TableBody>
                {statementData.map((item: any, index: number) => (
                  <StyledTableRow key={item.id}>
                    <StyledTableCell align="center" className={classes.cell}>
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell align="left" className={classes.cell}>
                      {dateConverterAdToBs(item.date)}
                    </StyledTableCell>
                    <StyledTableCell align="left" className={classes.cell}>
                      {item.transaction_type}
                    </StyledTableCell>
                    <StyledTableCell align="left" className={classes.cell}>
                      {item.fee_head || "Previously Due"}
                    </StyledTableCell>
                    <StyledTableCell align="left" className={classes.cell}>
                      {item.period || "-"}
                    </StyledTableCell>
                    <StyledTableCell align="left" className={classes.cell}>
                      {item.charges}
                    </StyledTableCell>
                    <StyledTableCell align="left" className={classes.cell}>
                      {item.fine || 0}
                    </StyledTableCell>
                    <StyledTableCell align="left" className={classes.cell}>
                      {item.balance}
                    </StyledTableCell>
                    <StyledTableCell align="left" className={classes.cell}>
                      <b
                        style={{
                          color: item.status === "Paid" ? "green" : "red",
                        }}
                      >
                        {item.status}
                      </b>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
              <StyledTableRow style={{ backgroundColor: "#e3e3e3" }}>
                <StyledTableCell
                  align="left"
                  className={classes.cell}
                  colSpan={7}
                >
                  Total Paid Amount
                </StyledTableCell>
                <StyledTableCell align="left" className={classes.cell}>
                  Rs. {totalPaid} /-
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  className={classes.cell}
                ></StyledTableCell>
              </StyledTableRow>
              <StyledTableRow style={{ backgroundColor: "#e3e3e3" }}>
                <StyledTableCell
                  align="left"
                  className={classes.cell}
                  colSpan={7}
                >
                  Discount
                </StyledTableCell>
                <StyledTableCell align="left" className={classes.cell}>
                  Rs. {feesHead?.total_discount}
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  className={classes.cell}
                ></StyledTableCell>
              </StyledTableRow>
              <StyledTableRow style={{ backgroundColor: "#e3e3e3" }}>
                <StyledTableCell
                  align="left"
                  className={classes.cell}
                  colSpan={7}
                >
                  Balance
                </StyledTableCell>
                <StyledTableCell align="left" className={classes.cell}>
                  Rs. {totalBalance} /-
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  className={classes.cell}
                ></StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell
                  align="left"
                  className={classes.cell}
                ></StyledTableCell>
                <StyledTableCell
                  align="left"
                  className={classes.cell}
                ></StyledTableCell>
              </StyledTableRow>
            </>
          )}
        </Table>
      </div>
    </div>
  );
};
// ---------------------------- <END> fine Type Component Ends -------------------------------//

export default CollectFeeStatement;
