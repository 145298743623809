// --------------------------<start> import section <start>------------------------//
import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Button,
  CircularProgress,
  Divider,
  Grid,
  InputLabel,
  Paper,
  TextField,
  Typography,
  Checkbox,
} from "@material-ui/core";
import { Autocomplete, Alert } from "@material-ui/lab";
import { useForm } from "react-hook-form";
import { useStyles } from "../../Styles/FormStyles";
import { useHistory } from "react-router-dom";

import {
  AddStaff,
  GetPaginatedStaffs,
  GetStaffs,
  UpdateStaff,
} from "../../../actions/HumanResource/Staff/StaffAction";
import {
  GetTeacherProfile,
  GetDepartmentStaff,
  GetDesignationStaff,
  UpdateTeacherProfile,
} from "../../../actions/TeacherDash/TeacherProfile/TeacherProfileAction";
import { GetSystemRoles } from "../../../actions/HumanResource/StaffRole/StaffRolesAction";
import "nepali-datepicker-reactjs/dist/index.css";
import { useDispatch, useSelector } from "react-redux";
import { GetDepartments } from "../../../actions/HumanResource/Department/DepartmentAction";
import { Link, Redirect, useParams } from "react-router-dom";
import {
  SELECT_BLOOD_GROUP,
  SELECT_GENDER,
  CONTRACT_TYPE,
  SELECT_RELIGION,
} from "../../../common/json.constant";
import firstWordCapital from "../../utils/firstWordCapital";
import { RootStore } from "../../../store";
import { GetDesignations } from "../../../actions/HumanResource/Designation/DesignationAction";
import CustomizedTextField from "../../Reusable/Inputs/TextField";
import CustomizedNepaliDatePicker from "../../Reusable/Inputs/NepaliDatePicker";
import CustomizedSelect from "../../Reusable/Inputs/Select";
import { ImageUpload } from "../../Reusable";
import {
  CancelButton,
  FormSaveButton,
} from "../../Reusable/Buttons/FormButton";
import useLoading from "../../../useHook/useLoading";
import defaultPlaceholder from "../../../assets/images/defaultPlaceholder";
import formsValidationCheckup from "../../utils/formsValidationCheckUp";
import DocumentsUploader from "../../Reusable/DocumentsUploader";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import {
  dateConverterAdToBs,
  dateConverterBsToAd,
} from "../../utils/dateConverter";
import { SystemRolesTypeI } from "../../../actions/HumanResource/StaffRole/StaffRoleActionTypes";
import { CUR_NEPALI_DATE } from "../../utils/nepaliDateUtils";
import { GetStaffProfile } from "../../../actions/HumanResource/StaffProfile/StaffProfileAction";
import { UpdateProfilePicture } from "../../../actions/Profile Picture/ProfilePictureAction";
// --------------------------<end> import section <end>------------------------//

// --------------------------<start> interfaces <start>-----------------------//

interface StaffFormI {
  first_name: string;
  last_name: string;
  address: string;
  phone: number;
  email: string;
  dob: string;
  gender: string;
  religion: string;
  blood_group: string;
  pan_no: string;
  date_of_joining: string;
  working_days: string;
  highest_degree: string;
  contract: string;
  designation: string;
  department: string;
  experience: string;
}

interface SelectOptionI {
  data: SystemRolesTypeI;
  selected: boolean;
}
interface Tupple {
  key: string;
  value: string;
}

interface ProfilePic {
  picturePreview: string;
  pictureAsFile: any;
}

// --------------------------<end> interfaces <end>-----------------------//

const StaffForm = (props: any) => {
  const { requiredOnly } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const params: any = useParams();
  const { handleSubmit, register, errors, setValue, setError, clearErrors } =
    useForm<any>({
      mode: "onChange",
    });

  // --------------------------<start> component states <start>-----------------------//
  const [onEditMode, setOnEditMode] = useState<boolean>(false);
  const [gender, setGender] = useState<Tupple | null | undefined>(null);
  const [bloodGroup, setBloodGroup] = useState<Tupple | null | undefined>(null);
  const [religion, setReligion] = useState<Tupple | null | undefined>(null);
  const [contractType, setContractType] = useState<Tupple | null | undefined>(
    null
  );
  const [designation, setDesignation] = useState<Tupple | null | undefined>(
    null
  );
  const [department, setDepartment] = useState<Tupple | null | undefined>(null);
  const [dob, setDob] = useState<string | undefined>("");
  const [doj, setDoj] = useState<string | undefined>("");
  const [singleStaff, setSingleStaff] = useState<any>(null);
  const [selectedRoleValues, setSelectedRoleValues] = useState<SelectOptionI[]>(
    []
  );
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [roleChoices, setRoleChoices] = useState<SelectOptionI[]>([]);
  const [image, setImage] = useState<ProfilePic>({
    picturePreview: "",
    pictureAsFile: null,
  });
  const [documents, setDocuments] = useState<null | any>([]);
  const [currentDate] = useState<null | string>(CUR_NEPALI_DATE);
  const [disabled, setDisabled] = useState<boolean>(false);

  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const isAdmin = localStorage.getItem("role") === "Administrator";

  // --------------------------<end> component states <end>-----------------------//

  const prepareDocumentFiles = (arr: any) => {
    if (arr.length) {
      const refined = arr.filter(
        (element: any) => !Object.keys(element).find((key) => key === "id")
      );
      return refined;
    }
  };

  useEffect(() => {
    if (params?.id) {
      setOnEditMode(true);
      if (localStorage.getItem("role") === "Administrator") {
        dispatch(GetStaffProfile(params.id));
      } else if (localStorage.getItem("role") === "Teacher") {
        dispatch(GetTeacherProfile(params.id));
        dispatch(GetDepartmentStaff());
        dispatch(GetDesignationStaff());
      }
    } else {
      setOnEditMode(false);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("role") === "Administrator") {
      dispatch(GetDepartments());
      dispatch(GetDesignations());
    }
  }, []);

  const staff = useSelector(
    (state: RootStore) => state.staffProfile.staff_profile
  );
  // const staff2 = useSelector(
  //   (state: RootStore) => state.teacher_profile.teacher_profile
  // );

  useEffect(() => {
    if (staff) {
      setSingleStaff(staff);
      setDisabled(false);
    } else {
      // setSingleStaff(staff2);
      setDisabled(true);
    }
  }, [staff]);

  useEffect(() => {
    if (errors) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [errors]);

  const handleDateofBirth = (value: any) => {
    setDob(value?.toString());
    clearErrors("date_of_birth");
  };

  const handleDateofJoining = (value: any) => {
    setDoj(value?.toString());
    clearErrors("date_of_joining");
  };

  console.log("staff", singleStaff);
  const onSubmit = (data: any) => {
    const {
      first_name,
      last_name,
      email,
      address,
      phone,
      pan_no,
      working_days,
      experience,
      highest_degree,
    } = data;
    let staffData: any;
    const formData = new FormData();
    if (singleStaff !== null) {
      staffData = {
        user: {
          first_name: first_name.trim(),
          last_name: last_name.trim(),
          email: email.trim(),
          groups: selectedRoles,
        },
        designation: designation?.key,
        address: address.trim(),
        phone: phone.trim(),
        gender: gender?.key,
        religion: religion?.key,
        blood_group: bloodGroup?.key,
        pan_no: pan_no.trim(),
        date_of_birth: dob ? dateConverterBsToAd(dob) : null,
        date_of_joining: doj ? dateConverterBsToAd(doj) : null,
        // photo: image,
        staffs_academic_info: {
          id_: params?.id
            ? singleStaff?.staffs_academic_info?.id || null
            : null,
          contract_type: contractType?.key,
          department: department?.key,
          highest_degree: highest_degree.trim(),
          working_days: working_days ? working_days.trim() : null,
          experience: experience ? experience.trim() : null,
        },
        // Backend team is fixing the issue
        documents: params?.id ? [] : prepareDocumentFiles(documents),
      };
      if (image) {
        formData.append("user", singleStaff.user?.id);
        formData.append("photo", image.pictureAsFile);
      }
    } else {
      staffData = {
        user: {
          first_name: first_name.trim(),
          last_name: last_name.trim(),
          email: email.trim(),
        },
        address: address.trim(),
        phone: phone.trim(),
        date_of_birth: dob ? dateConverterBsToAd(dob) : null,
        religion: religion?.key,
        gender: gender?.key,
        blood_group: bloodGroup?.key,
        blood_group_display: bloodGroup?.value,
        pan_no: pan_no.trim(),
        date_of_joining: doj ? dateConverterBsToAd(doj) : null,
        designation: designation?.key,
        // photo: image,
        staffs_academic_info: {
          id_: params?.id
            ? singleStaff?.staffs_academic_info?.id || null
            : null,
          contract_type: contractType?.key,
          contract_name: contractType?.value,
          department: department?.key,
          department_name: department?.value,
          highest_degree: highest_degree.trim(),
          experience: experience ? experience.trim() : null,
          working_days: working_days ? working_days.trim() : null,
          leave: 1.0,
          general_info: 1,
        },
        // Backend team is fixing the issue
        documents: params?.id ? [] : prepareDocumentFiles(documents),
      };
    }
    if (onEditMode) {
      // Request POST data to server
      image.pictureAsFile && dispatch(UpdateProfilePicture(formData));
      if (isAdmin) {
        dispatch(UpdateStaff(params?.id, staffData));
        history.push("/staff");
      } else {
        dispatch(UpdateTeacherProfile(params?.id, staffData));
        history.push("/profile");
      }
    } else {
      dispatch(AddStaff(staffData));
    }
  };

  useEffect(() => {
    dispatch(GetPaginatedStaffs(1, 500));
    dispatch(GetSystemRoles());
  }, []);

  const departmentState = useSelector((state: RootStore) => state.department);
  const designationState = useSelector((state: RootStore) => state.designation);
  const staffState = useSelector((state: RootStore) => state.staff);
  const { loading } = useLoading(staffState.loading);
  const errorsData = useSelector((state: RootStore) => state.staff.errors);
  const DEPARTMENT_CHOICES = departmentState.departments.map((item) => ({
    key: item?.id?.toString(),
    value: item.name,
  }));
  const rolesSelector = useSelector((state: RootStore) => state.staff_roles);
  const DESIGNATION_CHOICES = designationState.designations.map((item) => ({
    key: item?.id?.toString(),
    value: item.name,
  }));

  // useEffect(() => {
  //   if (errorsData != null && errorsData?.error) {
  //     const keys = Object.keys(errorsData.error);
  //     keys.map((elem: any) => {
  //       setError(elem, {
  //         type: 'serverSideError',
  //         message: errorsData.error[elem] && errorsData.error[elem][0],
  //       });
  //     });
  //   }
  // }, [errorsData]);

  useEffect(() => {
    const data = rolesSelector.system_roles;
    if (data.length) {
      const mod: SelectOptionI[] = [];

      data.map((element) => {
        mod.push({
          data: { ...element },
          selected: false,
        });
      });

      setRoleChoices(mod);
    }
  }, [rolesSelector]);

  // Fetch data
  useEffect(() => {
    if (onEditMode) {
      if (singleStaff != null) {
        const selectedStaff: any = singleStaff;
        const academicInfo = staff
          ? selectedStaff?.staffs_academic_info
          : selectedStaff?.academic_info;
        if (selectedStaff && selectedStaff?.staffs_academic_info === null) {
          const staff_academic_info = {
            working_days: 0,
            experience: 0,
            highest_degree: "bachelor",
          };
          selectedStaff.staffs_academic_info = staff_academic_info;
        }

        if (selectedStaff) {
          setValue("first_name", selectedStaff?.user?.first_name);
          setImage({
            pictureAsFile: null,
            picturePreview: selectedStaff?.photo,
          });
          setValue("last_name", selectedStaff?.user?.last_name);
          setValue("email", selectedStaff?.user?.email);
          setValue("address", selectedStaff?.address);
          setValue("phone", selectedStaff?.phone);
          setValue("pan_no", selectedStaff?.pan_no);
          setValue("working_days", academicInfo?.working_days);
          setValue("experience", academicInfo?.experience);
          setValue("highest_degree", academicInfo?.highest_degree);
          setGender(
            SELECT_GENDER.find((item) => item.key === selectedStaff?.gender)
          );
          setBloodGroup(
            SELECT_BLOOD_GROUP.find(
              (item) => item.key === selectedStaff?.blood_group
            )
          );
          setReligion(
            SELECT_RELIGION.find((item) => item.key === selectedStaff?.religion)
          );

          setContractType(
            CONTRACT_TYPE.find(
              (item) => item.key === academicInfo?.contract_type
            )
          );
          const tempDesignation = staff
            ? selectedStaff?.designation?.toString()
            : selectedStaff?.designation;
          setDesignation(
            DESIGNATION_CHOICES.find((item) => item.key === tempDesignation)
          );
          setDepartment(
            DEPARTMENT_CHOICES.find(
              (item) => item.key === academicInfo?.department
            )
          );
          selectedStaff?.date_of_birth != null &&
            setDob(dateConverterAdToBs(selectedStaff?.date_of_birth));
          selectedStaff?.date_of_joining != null &&
            setDoj(dateConverterAdToBs(selectedStaff?.date_of_joining));
        }
      }
    }
  }, [onEditMode, staffState, singleStaff]);

  const onRolesChange = (data: SelectOptionI[]) => {
    const selected: string[] = [];

    data.map((element) => selected.push(element.data?.id));

    const currentOptions: SelectOptionI[] = roleChoices;
    currentOptions.map((element) => {
      if (selected.includes(element.data?.id)) {
        element.selected = true;
      } else {
        element.selected = false;
      }
    });
    setRoleChoices(currentOptions);

    const selectedVal = currentOptions.filter(
      (element) => element.selected === true
    );

    setSelectedRoleValues(selectedVal);

    const ids: string[] = [];

    selectedVal.map((element) => {
      ids.push(element.data?.id);
    });

    setSelectedRoles(ids);
  };

  if (staffState.add_or_update) {
    history.push("/staff");
  }

  return (
    <div>
      <Grid container>
        <Grid item md={12}>
          <Paper className={classes.pageContent} elevation={1}>
            <Grid container>
              <Grid item className={classes.formMainTitle}>
                <Typography variant="h6">
                  {params.id ? "Edit Staff" : "Add New Staff"}
                </Typography>
              </Grid>
            </Grid>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
              {errorsData?.error &&
                Object.keys(errorsData.error).map(
                  (elem: any, index: number) => {
                    return (
                      <div className={classes.serversidemessages} key={index}>
                        <Alert severity="error">
                          {firstWordCapital(
                            errorsData.error && errorsData?.error[elem][0]
                          )}
                        </Alert>
                      </div>
                    );
                  }
                )}
              <Grid container>
                <Grid container spacing={3}>
                  {!requiredOnly && (
                    <Grid item xs={12} className={classes.formWrapper}>
                      <InputLabel>Upload Photo</InputLabel>
                      <ImageUpload
                        id="upload-photo-file"
                        setImage={setImage}
                        image={image?.picturePreview}
                      />
                      <span className={classes.validationErrorInfo} />
                    </Grid>
                  )}

                  <Grid item md={6} className={classes.formWrapper}>
                    <CustomizedTextField
                      label="First Name"
                      placeholder="Label"
                      name="first_name"
                      required
                      error={errors["first_name"] && true}
                      inputRef={register({ required: true })}
                      // inputProps={{ maxLength: 20 }}
                    />
                    {formsValidationCheckup({
                      errors: errors,
                      inputName: "first_name",
                      requiredMessage: "First Name is Required",
                      serverSideMessage:
                        errors.first_name?.type === "serverSideError" &&
                        errors.first_name.message,
                    })}
                  </Grid>

                  <Grid item md={6} className={classes.formWrapper}>
                    <CustomizedTextField
                      label="Last Name"
                      placeholder="Label"
                      name="last_name"
                      required
                      error={errors["last_name"] && true}
                      inputRef={register({ required: true })}
                      // inputProps={{ maxLength: 20 }}
                    />
                    {formsValidationCheckup({
                      errors: errors,
                      inputName: "last_name",
                      requiredMessage: "Last Name is Required",
                      serverSideMessage:
                        errors.last_name?.type === "serverSideError" &&
                        errors.last_name.message,
                    })}
                  </Grid>

                  <Grid item md={8} className={classes.formWrapper}>
                    <Grid container spacing={3}>
                      <Grid item md={7} className={classes.formWrapper}>
                        <CustomizedTextField
                          label="Full Address"
                          placeholder="Label"
                          name="address"
                          error={errors["address"] && true}
                          inputRef={register({ required: false })}
                          // inputProps={{ maxLength: 30 }}
                        />
                        {formsValidationCheckup({
                          errors: errors,
                          inputName: "address",
                          requiredMessage: "Address is Required",
                          serverSideMessage:
                            errors.address?.type === "serverSideError" &&
                            errors.address.message,
                        })}
                      </Grid>

                      <Grid item md={5} className={classes.formWrapper}>
                        <CustomizedTextField
                          label="Phone"
                          placeholder="Label"
                          name="phone"
                          required
                          error={errors["phone"] && true}
                          inputRef={register({
                            required: true,
                            maxLength: 10,
                            minLength: 10,
                          })}
                          type="number"
                        />
                        {formsValidationCheckup({
                          errors: errors,
                          inputName: "phone",
                          requiredMessage: "Phone Number is Required",
                          maxLengthMessage: "Invalid Number Length",
                          minLengthMessage: "Invalid Number Length",
                          serverSideMessage:
                            errors.phone?.type === "serverSideError" &&
                            errors.phone.message,
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={4} className={classes.formWrapper}>
                    <CustomizedTextField
                      label="Email"
                      name="email"
                      placeholder="Label"
                      inputRef={register({
                        required: false,
                        pattern: {
                          value: /^[a-z0-9._%+_]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                          message:
                            "Enter a valid email (e.g. yourname@gmail.com)",
                        },
                      })}
                    />
                    {formsValidationCheckup({
                      errors: errors,
                      inputName: "email",
                      serverSideMessage:
                        errors.email?.type === "serverSideError" &&
                        errors.email.message,
                    })}
                    <span className={classes.validationErrorInfo}>
                      {errors.email && errors.email.message}
                    </span>
                  </Grid>
                  <Grid item md={3} className={classes.formWrapper}>
                    <CustomizedNepaliDatePicker
                      label="Date of Birth"
                      value={dob}
                      setValue={handleDateofBirth}
                      name="date_of_birth"
                    />
                    {formsValidationCheckup({
                      errors: errors,
                      inputName: "date_of_birth",
                      serverSideMessage:
                        errors.date_of_birth?.type === "serverSideError" &&
                        errors.date_of_birth.message,
                    })}
                  </Grid>
                  <Grid item md={3} className={classes.formWrapper}>
                    <CustomizedSelect
                      label="Gender"
                      name="gender"
                      options={SELECT_GENDER}
                      value={gender}
                      setValue={setGender}
                      error={errors["gender"] ? "Gender must be selected" : ""}
                      inputRef={register({ required: false })}
                    />
                  </Grid>
                  {!requiredOnly && (
                    <Grid item md={3} className={classes.formWrapper}>
                      <CustomizedSelect
                        label="Religion"
                        name="religion"
                        options={SELECT_RELIGION}
                        value={religion}
                        setValue={setReligion}
                        // error={
                        //   errors["religion"] ? "Religion must be selected" : ""
                        // }
                        inputRef={register}
                      />
                    </Grid>
                  )}
                  {!requiredOnly && (
                    <Grid item md={3} className={classes.formWrapper}>
                      <CustomizedSelect
                        label="Blood Group"
                        name="blood_group"
                        options={SELECT_BLOOD_GROUP}
                        value={bloodGroup}
                        setValue={setBloodGroup}
                        // error={
                        //   errors["blood_group"]
                        //     ? "Blood group must be selected"
                        //     : ""
                        // }
                        inputRef={register}
                      />
                    </Grid>
                  )}
                  {!requiredOnly && (
                    <Grid item md={3} className={classes.formWrapper}>
                      <CustomizedTextField
                        label="Pan No"
                        placeholder="Label"
                        name="pan_no"
                        error={errors["pan_no"] && true}
                        inputRef={register({ required: false })}
                      />
                      {formsValidationCheckup({
                        errors: errors,
                        inputName: "pan_no",
                        requiredMessage: "PAN Number is Required",
                        serverSideMessage:
                          errors.pan_no?.type === "serverSideError" &&
                          errors.pan_no.message,
                      })}
                    </Grid>
                  )}
                  <Grid item md={3} className={classes.formWrapper}>
                    <CustomizedNepaliDatePicker
                      label="Date of joining"
                      value={doj}
                      setValue={handleDateofJoining}
                      name="date_of_joining"
                    />
                    {formsValidationCheckup({
                      errors: errors,
                      inputName: "date_of_joining",
                      serverSideMessage:
                        errors.date_of_joining?.type === "serverSideError" &&
                        errors.date_of_joining.message,
                    })}
                  </Grid>
                </Grid>
              </Grid>

              <Grid container>
                <Grid container>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} className={classes.formTitle}>
                    <span>Academic Info</span>
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  {!requiredOnly && (
                    <>
                      <Grid item md={3} className={classes.formWrapper}>
                        <CustomizedSelect
                          label="Contract Type"
                          name="contract_type"
                          options={CONTRACT_TYPE}
                          // required
                          value={contractType}
                          setValue={setContractType}
                          error={
                            errors["contract_type"]
                              ? "Contract type must be selected"
                              : ""
                          }
                          inputRef={register}
                        />
                      </Grid>
                      <Grid item md={3} className={classes.formWrapper}>
                        <CustomizedSelect
                          label="Designation"
                          name="designation"
                          options={DESIGNATION_CHOICES}
                          // required
                          value={designation}
                          setValue={setDesignation}
                          disabled={onEditMode && disabled}
                          error={
                            errors["designation"]
                              ? "Designation must be selected"
                              : ""
                          }
                          inputRef={register}
                        />
                      </Grid>
                      <Grid item md={3} className={classes.formWrapper}>
                        <CustomizedSelect
                          label="Department"
                          name="department"
                          options={DEPARTMENT_CHOICES}
                          // required
                          value={department}
                          setValue={setDepartment}
                          disabled={onEditMode && disabled}
                          error={
                            errors["department"]
                              ? "Department must be selected"
                              : ""
                          }
                          inputRef={register({ required: false })}
                        />
                      </Grid>
                      <Grid item md={3} className={classes.formWrapper}>
                        <CustomizedTextField
                          label="Highest Degree"
                          placeholder="Label"
                          name="highest_degree"
                          error={
                            errors["highest_degree"]
                              ? "Highest Degree must be selected"
                              : ""
                          }
                          inputRef={register({ required: false })}
                        />
                      </Grid>
                      <Grid item md={3} className={classes.formWrapper}>
                        <CustomizedTextField
                          number
                          label="Experience in Years"
                          placeholder="Label"
                          name="experience"
                          type="number"
                          inputRef={register({ required: false })}
                          error={
                            errors["experience"]
                              ? "Experience must be selected"
                              : ""
                          }
                        />
                      </Grid>
                      <Grid item md={3} className={classes.formWrapper}>
                        <CustomizedTextField
                          number
                          label="Working Days"
                          placeholder="Label"
                          name="working_days"
                          type="number"
                          error={
                            errors["working_days"]
                              ? "Working Days must be selected"
                              : ""
                          }
                          inputRef={register({ required: false })}
                        />
                      </Grid>
                    </>
                  )}
                  {!params.id && (
                    <Grid item md={3} className={classes.formWrapper}>
                      <InputLabel>
                        System Roles
                        {/* <span style={{ color: "red" }}>*</span> */}
                      </InputLabel>
                      <Autocomplete
                        value={selectedRoleValues}
                        multiple
                        id="checkbox-select-roles"
                        options={roleChoices}
                        disableCloseOnSelect
                        onChange={(
                          event: React.ChangeEvent<{}>,
                          value: SelectOptionI[]
                        ) => onRolesChange(value)}
                        getOptionLabel={(option) => option.data.name}
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={option.selected}
                            />
                            {option.data.name}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="groups"
                            variant="outlined"
                            placeholder="Label"
                          />
                        )}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>

              {/* Do not render document uploader in edit  mode */}
              {!params.id && <DocumentsUploader setValue={setDocuments} />}

              <Grid container>
                {params.id != undefined && (
                  <Backdrop className={classes.backdrop} open={loading}>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                )}

                <Grid
                  container
                  justifyContent="flex-end"
                  style={{ paddingTop: "24px" }}
                >
                  <Grid item xs={3}>
                    <Grid
                      container
                      justifyContent="flex-end"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item>
                        <CancelButton to="/staff" />
                      </Grid>

                      <Grid item>
                        <FormSaveButton />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default StaffForm;
