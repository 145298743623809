// ---------------------------- <START> module imports start ----------------------------------//
import React, { useState, useEffect } from "react";
import {
  Button,
  InputLabel,
  Paper,
  TextField,
  Typography,
  Chip,
} from "@material-ui/core";
import styled from "styled-components";

import { useTableStyles } from "../../Styles/TableStyles";
import { Autocomplete } from "@material-ui/lab";
import { GridCol1, GridCol4, GridContainer } from "../../../core-ui/Grid/Grid";
import { ItemSaveButton } from "../../Reusable/Buttons/TableButton";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../store";

import { ClassType } from "../../../rtk/features/class/ClassSlice";
import Select, { StylesConfig } from "react-select";
import { getClassSection } from "../../../rtk/features/class/classActions";
import {
  getSubjectTeachers,
  updateSubjectTeachers,
} from "../../../rtk/features/subjectTeachers/subjectTeacherActions";
import { GetAllTeachers } from "../../../actions/HumanResource/Staff/StaffAction";
import {
  changeTeachers,
  clearFields,
} from "../../../rtk/features/subjectTeachers/subjectTeacherSlice";
import BackDropLoader from "../../Reusable/BackDropLoader";
import { Edit } from "@material-ui/icons";

const selectStyles: StylesConfig = {
  container: (provided, state) => ({
    ...provided,
    outline: "none",
    display: "block",
  }),
  control: (provided, state) => ({
    ...provided,
    outline: "none",
    backgroundColor: "#F2F2F2",
    border: state.isFocused ? "1px solid #e8ebed" : "1px solid #F2F2F2",
    boxShadow: "none",
    "&:hover": {
      border: state.isFocused ? "1px solid #e8ebed" : "1px solid #F2F2F2",
    },
  }),
  input: (provided, state) => ({
    ...provided,
  }),
};

const HorizontalLine = styled.div`
  height: 1px;
  background-color: rgba(0, 0, 0, 0.12);

  margin: 10px 0;
`;

const SectionContainer = styled.div``;

const SectionTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    color: #5e5e5e;
  }
`;

const SectionMain = styled.div`
  margin-top: 10px;
`;

const GridRow = styled.div`
  display: flex;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const NoSelectionText = styled.p`
  margin-top: 60px;

  text-align: center;
  text-transform: uppercase;

  font-size: 18px;
  font-weight: 400;

  color: #000;

  opacity: 0.2;

  user-select: none;
`;

const StTable = styled.table`
  border-collapse: collapse;
  margin-bottom: 20px;
`;

const StTHead = styled.thead`
  background-color: #f4f5f8;
`;

const StTBody = styled.tbody`
  tr {
    &:nth-child(even) {
      /* background-color: #f3f3f3; */
    }
  }
`;

const StTRow = styled.tr``;

const StTHeadCell = styled.th`
  text-align: left;

  font-size: 14px;
  font-weight: 400;

  padding: 10px;
`;

const StDataCell = styled.td`
  text-align: left;

  font-size: 14px;
  font-weight: 300;

  padding: 10px;

  border-bottom: 1px solid #e9ecef;

  &:nth-child(1) {
    width: 250px;
  }

  &:nth-child(2) {
    width: 500px;
  }
`;

const RowContent = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
`;

const EditButton = styled(Button)`
  background-color: #132e98;
  color: #fff;

  &:hover {
    background-color: #0d206a;
  }
`;

const TeacherChip = styled(Chip)`
  margin: 2px 0;
  &:not(:last-child) {
    margin-right: 5px;
  }
`;

const ClassTable = () => {
  const dispatch = useDispatch();
  const tableClasses = useTableStyles();

  const { all_teachers } = useSelector((state: RootStore) => state.staff);
  const { classes } = useSelector((state: RootStore) => state.newClass);
  const { subjectTeachers, completed } = useSelector(
    (state: RootStore) => state.subjectTeacher
  );

  const staffLoading = useSelector((state: RootStore) => state.staff.loading);
  const classLoadding = useSelector(
    (state: RootStore) => state.newClass.loading
  );
  const subjectTeacherLoading = useSelector(
    (state: RootStore) => state.subjectTeacher.loading
  );

  const [editable, toggleEditable] = useState<boolean>(false);
  const [selectedClass, setSelectedClass] = useState<ClassType | null>(null);
  const [selectedSection, setSelectedSection] = useState<{
    id: string;
    name: string;
  } | null>(null);

  const [sections, setSections] = useState<{ id: string; name: string }[]>([]);

  useEffect(() => {
    dispatch(getClassSection());
    dispatch(GetAllTeachers(""));
    dispatch(clearFields());

    return () => {
      dispatch(clearFields());
    };
  }, [dispatch]);

  const handleClassChange = (val: ClassType | null) => {
    setSelectedClass(val);
    setSelectedSection(null);
    toggleEditable(false);

    if (val) {
      setSections(val.section);

      if (!val.section.length) {
        dispatch(
          getSubjectTeachers({
            grade: val.grade,
            section: "",
          })
        );
        return;
      } else {
        dispatch(clearFields());
        return;
      }
    } else {
      dispatch(clearFields());
    }
  };

  const handleSectionChange = (val: { id: string; name: string } | null) => {
    setSelectedSection(val);
    toggleEditable(false);

    if (val) {
      dispatch(
        getSubjectTeachers({
          grade: selectedClass!.grade,
          section: val.id,
        })
      );
    } else {
      dispatch(clearFields());
    }
  };

  const handleEditClick = () => {
    toggleEditable(!editable);

    if (editable) {
      dispatch(
        getSubjectTeachers({
          grade: selectedClass!.grade,
          section: selectedSection?.id || "",
        })
      );
    }
  };

  //================================<END>LIFECYCLE HOOKS<END>=================================//

  return (
    <Paper className={tableClasses.root}>
      <GridContainer>
        <GridCol4>
          <Typography variant="h4">Manage Subject Teachers</Typography>
        </GridCol4>
        <GridCol1>
          <InputLabel>
            Grade
            <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <Autocomplete
            value={selectedClass}
            onChange={(event: React.ChangeEvent<{}>, value: ClassType | null) =>
              handleClassChange(value)
            }
            options={classes}
            getOptionLabel={(option) => option.grade_name}
            renderInput={(params) => (
              <TextField
                {...params}
                name="grade"
                placeholder="Select grade"
                variant="outlined"
              />
            )}
          />
        </GridCol1>
        <GridCol1>
          <InputLabel>
            Section
            <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <Autocomplete
            value={selectedSection}
            onChange={(
              event: React.ChangeEvent<{}>,
              value: { id: string; name: string } | null
            ) => handleSectionChange(value)}
            options={sections}
            getOptionLabel={(option) => option.name}
            disabled={!selectedClass?.section.length}
            renderInput={(params) => (
              <TextField
                {...params}
                name="section"
                placeholder="Select section"
                variant="outlined"
              />
            )}
          />
        </GridCol1>
      </GridContainer>

      <HorizontalLine />

      <SectionContainer>
        <SectionTop>
          <p>Edit Subject Teachers</p>
        </SectionTop>
        <SectionMain>
          {subjectTeachers.length ? (
            <>
              <StTable>
                <StTHead>
                  <StTRow>
                    <StTHeadCell>Subject</StTHeadCell>
                    <StTHeadCell>Assigned Teachers</StTHeadCell>
                    <StTHeadCell align="center">Action</StTHeadCell>
                  </StTRow>
                </StTHead>
                <StTBody>
                  {subjectTeachers.map((el, index) => (
                    <StTRow key={index + 1}>
                      <StDataCell>{el.subject.name}</StDataCell>
                      <StDataCell>
                        {editable ? (
                          <Select
                            isMulti
                            value={el.teachers}
                            options={all_teachers.map((el) => ({
                              value: el.id,
                              label: el.first_name + " " + el.last_name,
                            }))}
                            closeMenuOnSelect={false}
                            styles={selectStyles}
                            placeholder="Select Teachers"
                            onChange={(e) =>
                              dispatch(
                                changeTeachers({
                                  subjectId: el.subject.id,
                                  teachers: e,
                                })
                              )
                            }
                          />
                        ) : (
                          el.teachers.map(
                            (
                              val: { value: string; label: string },
                              index: number
                            ) => (
                              <TeacherChip key={index + 1} label={val.label} />
                            )
                          )
                        )}
                      </StDataCell>
                      <StDataCell align="center">
                        <ItemSaveButton
                          disabled={!editable || !el.edited}
                          onClick={() =>
                            dispatch(
                              updateSubjectTeachers({
                                grade: selectedClass!.grade,
                                section: selectedSection?.id || "",
                                subject: el.subject.id,
                                teacher: el.teachers.map(
                                  (val: any) => val.value
                                ),
                              })
                            )
                          }
                        />
                      </StDataCell>
                    </StTRow>
                  ))}
                </StTBody>
              </StTable>
              <GridRow>
                <RowContent>
                  <EditButton
                    variant="contained"
                    startIcon={<Edit />}
                    onClick={handleEditClick}
                  >
                    {editable ? "Cancel" : "Edit"}
                  </EditButton>
                </RowContent>
              </GridRow>
            </>
          ) : (
            <NoSelectionText>
              Select class with subjects to make changes.
            </NoSelectionText>
          )}
        </SectionMain>
      </SectionContainer>
      <BackDropLoader
        open={staffLoading || classLoadding || subjectTeacherLoading}
      />
    </Paper>
  );
};

export default ClassTable;
